import deepmerge from 'deepmerge';
import * as moment from 'moment';
import { keys } from '../../utils';
import {
    ConcreteFormDto,
    ConcreteMappedEntityConcreteFormDto,
    ConcreteRequiredEntityWorkflowAttachmentRequirement,
    CraftDto,
    FormControlDto,
    FormResponseCommand,
    FormResponseDto,
    MissionDocumentationDto,
    RequestersMissionApprovalDto,
    RequiredDocumentation
} from '../api';
import {
    checkFormProgress,
    combineFormResponses,
    FormResponseFormGroup,
    FormResponsesWithStep
} from './forms';

export interface DocumentationResponses {
    formResponses: FormResponseDto[];
}

interface SubmittedTime {
    submittedTime?: string;
}

interface SubmittedTimeAndStep extends SubmittedTime {
    step: string;
}

/**
 * Orders by submitted time with the newest value being first.
 * @param r1 first response
 * @param r2 second response
 */
export function dateOrderBySubmittedTime(r1: SubmittedTime, r2: SubmittedTime) {
    return moment(r2.submittedTime).diff(moment(r1.submittedTime));
}

export interface MaybeRequiringEntity {
    requiringEntityType?: string;
    requiringEntityId?: number;
}

/**
 * A data structure to capture the progress in completing a set of documentation.
 */
export interface Progress {
    progress: number;
    total: number;
}

/**
 * Encapsulates a single related entity and its available documentation.
 */
export interface RelatedEntityRequiredDocumentation
    extends RequiredDocumentation {
    relatedEntityType: string;
    relatedEntityId: number;
    name: string;
}

interface ProgressedMappedEntityForm
    extends ConcreteMappedEntityConcreteFormDto {
    progress: Progress;
    completed: boolean;
}

interface ProgressedRequiredDocumentation {
    forms: ProgressedMappedEntityForm[];
}

/**
 * A grouping of documentation and response by a required entity, including its name
 * for easy display.
 */
export interface RelatedEntityRequiredDocumentationAndResponses
    extends ProgressedRequiredDocumentation {
    relatedEntityType: string;
    relatedEntityId: number;
    name: string;
    formResponses: FormResponseFormGroup;
    attachments: ConcreteRequiredEntityWorkflowAttachmentRequirement[];
}

interface ConcreteMappedEntity<T> {
    entity: T;
}

interface HasArchived {
    archived: boolean;
}

export function toConcreteMappedEntity<T>(
    entity: T
): ConcreteMappedEntity<T & HasArchived> {
    return {
        entity: {
            ...entity,
            archived: false
        }
    };
}

export interface StepEntityRequiredDocumentation {
    [step: string]: RelatedEntityRequiredDocumentation[];
}

export interface StepEntityRequiredDocumentationAndResponses {
    [step: string]: RelatedEntityRequiredDocumentationAndResponses[];
}

export function buildMissionRequiredDocumentation(documentation: {
    [step: string]: RequiredDocumentation;
}): StepEntityRequiredDocumentation {
    return Object.keys(documentation).reduce(
        (acc, step) => ({
            ...acc,
            [step]: [
                {
                    ...documentation[step],
                    relatedEntityId: null,
                    relatedEntityType: null,
                    name: null
                }
            ]
        }),
        {} as StepEntityRequiredDocumentation
    );
}

/**
 * Generates a StepEntityRequiredDocumentation object for all RPA.
 *
 * @param rpaRequiredDocumentation the required documentation by RPA model
 * @param rpas the RPA of interest
 * @param nameFormatter a function to format the RPA into a name
 */
export function buildRpasRequiredDocumentation(
    rpaRequiredDocumentation: {
        [rpaModelId: number]: { [step: string]: RequiredDocumentation };
    },
    rpas: CraftDto[],
    nameFormatter: (rpa: CraftDto) => string
): StepEntityRequiredDocumentation {
    return rpas
        .map(c =>
            buildRpaRequiredDocumentation(
                rpaRequiredDocumentation[c.rpaTypeId],
                c,
                nameFormatter
            )
        )
        .reduce(
            mergeRequiredDocumentation2,
            {} as StepEntityRequiredDocumentation
        );
}

function buildRpaRequiredDocumentation(
    requiredDocumentation: { [step: string]: RequiredDocumentation },
    rpa: CraftDto,
    nameFormatter: (rpa: CraftDto) => string
): StepEntityRequiredDocumentation {
    if (requiredDocumentation == null) {
        return {};
    }
    return Object.keys(requiredDocumentation).reduce(
        (acc, step) => ({
            ...acc,
            [step]: [
                {
                    ...requiredDocumentation[step],
                    name: nameFormatter(rpa),
                    relatedEntityType: 'Craft',
                    relatedEntityId: rpa.id
                }
            ]
        }),
        {} as StepEntityRequiredDocumentation
    );
}

export function mergeRequiredDocumentation(
    items: StepEntityRequiredDocumentation[]
): StepEntityRequiredDocumentation {
    let result = items[0];
    for (const next of items.slice(1)) {
        result = mergeRequiredDocumentation2(result, next);
    }
    return result;
}

/**
 * Merged two sets of required documentation, appending the documentation for each step.
 *
 * @param first a step indexed entity required documentation object
 * @param second a step indexed entity required documentation object to be merged
 */
function mergeRequiredDocumentation2(
    first: StepEntityRequiredDocumentation,
    second: StepEntityRequiredDocumentation
): StepEntityRequiredDocumentation {
    return Object.keys(second).reduce(
        (acc, step) => ({
            ...acc,
            [step]: step in acc ? acc[step].concat(second[step]) : second[step]
        }),
        first
    );
}

export function applyResponsesToRequiredDocumentation(
    documentation: StepEntityRequiredDocumentation,
    formResponses: FormResponseFormGroup,
    requiringEntities: MaybeRequiringEntity[]
): StepEntityRequiredDocumentationAndResponses {
    return Object.keys(documentation).reduce(
        (acc, step) => ({
            ...acc,
            [step]: documentation[step].map(d =>
                applyResponsesToEntityRequiredDocumentation(
                    d,
                    formResponses,
                    step,
                    requiringEntities
                )
            )
        }),
        {} as StepEntityRequiredDocumentationAndResponses
    );
}

function initialiseKeysAsArrays<T>(
    data: { [key: number]: T[] },
    keysToInitialise: number[]
): { [key: number]: T[] } {
    return keysToInitialise.reduce(
        (acc, key) => ({ ...acc, [key]: key in data ? data[key] : [] }),
        data
    );
}

/**
 * Construct a new object that contains the documentation, its responses, and progress status for
 * each piece of documentation.
 * @param doc the required documentation object for this mission entity
 * @param formResponses all of the form responses
 */
export function applyResponsesToEntityRequiredDocumentation(
    doc: RelatedEntityRequiredDocumentation,
    formResponses: FormResponseFormGroup,
    step: string,
    requiringEntities: MaybeRequiringEntity[]
): RelatedEntityRequiredDocumentationAndResponses {
    const forms = doc.forms.filter(
        f =>
            requiringEntities == null ||
            requiringEntities.findIndex(
                r =>
                    r.requiringEntityId === f.requiringEntityId &&
                    r.requiringEntityType === f.requiringEntityType
            ) !== -1
    );

    const filteredFormResponses = initialiseKeysAsArrays(
        filterResponses(
            formResponses,
            doc.relatedEntityType,
            doc.relatedEntityId,
            step,
            requiringEntities
        ),
        forms.map(f => f.entity.formId)
    );

    return {
        ...doc,
        forms: forms.map(f => ({
            ...f,
            ...checkFormProgress(
                f.entity,
                filteredFormResponses[f.entity.formId],
                step
            )
        })),
        formResponses: filteredFormResponses
    };
}

interface RelatedEntity {
    relatedEntityId?: number;
    relatedEntityType?: string;
}

interface WithStep {
    step: string;
}

type RelatedAndRequiringEntity = RelatedEntity &
    MaybeRequiringEntity &
    WithStep;

interface DocumentationLookupWithRelatedAndRequiringEntity {
    [formId: number]: RelatedAndRequiringEntity[];
}

/**
 * A generic function to filter responses by the entity type and ID.
 * @param responses a list of responses
 * @param entityType the entity type to filter by
 * @param entityId the entity id to filter by
 */
export function filterResponses<
    T extends DocumentationLookupWithRelatedAndRequiringEntity
>(
    responses: T,
    entityType: string,
    entityId: number,
    step: string,
    requiringEntities?: MaybeRequiringEntity[]
): T {
    return Object.keys(responses).reduce(
        (acc, formId) => ({
            ...acc,
            [formId]: responses[formId].filter(
                (r: RelatedAndRequiringEntity) =>
                    r.relatedEntityId == entityId &&
                    r.relatedEntityType == entityType &&
                    (requiringEntities == null ||
                        requiringEntities.findIndex(
                            re =>
                                re.requiringEntityId === r.requiringEntityId &&
                                re.requiringEntityType === r.requiringEntityType
                        ) !== -1) &&
                    r.step === step
            )
        }),
        {} as T
    );
}

interface Response extends RelatedEntity {
    submittedTime?: string;
}

/**
 * Update the stored array of responses with a potentially new or updated response. The new
 * response is prepended to the response.
 * @param original an array of responses of the same documentation id
 * @param updatedResponse the updated response
 */
export function updateOrAppendUnsubmittedResponse<T extends Response>(
    original: T[],
    updatedResponse: T
): T[] {
    if (original == null) {
        return [updatedResponse];
    }

    const matches = (r: Response) =>
        r.submittedTime == null &&
        r.relatedEntityId === updatedResponse.relatedEntityId &&
        r.relatedEntityType === updatedResponse.relatedEntityType;

    const found = original.find(matches);
    if (found) {
        return original.map(r => (matches(r) ? updatedResponse : r));
    } else {
        return [updatedResponse].concat(original);
    }
}

export interface DisplayableDocumentation {
    formResponses: FormResponseFormGroup;
    requiredDocumentation: StepEntityRequiredDocumentation;
}

export type DisplayableMissionDocumentation = DisplayableDocumentation;

/**
 * Makes a set of responses into ones that have the relative properties set
 * @param responses the original responses
 * @param relatedEntityType the relatedEntityType to set
 * @param relatedEntityId the relatedEntityId to set
 */
function makeRelated<T extends RelatedEntity>(
    responses: { [step: string]: T[] },
    relatedEntityType: string,
    relatedEntityId: number
): { [step: string]: T[] } {
    return Object.keys(responses).reduce(
        (acc, step) => ({
            ...acc,
            [step]: responses[step].map(r => ({
                ...r,
                relatedEntityType,
                relatedEntityId
            }))
        }),
        {}
    );
}

export function buildDisplayableMissionDocumentation(
    documentation: MissionDocumentationDto,
    approvals: RequestersMissionApprovalDto[],
    rpas: CraftDto[],
    rpaNameFormatter: (rpa: CraftDto) => string
): DisplayableMissionDocumentation {
    const approvalRequiredDocumentation =
        approvals != null && approvals.length > 0
            ? approvals
                  .reduce(
                      (acc, approval) =>
                          documentation.missionApprovalRequiredDocumentation[
                              approval.id
                          ] != null
                              ? acc.concat(approval)
                              : acc,
                      []
                  )
                  .map(approval =>
                      buildMissionRequiredDocumentation(
                          documentation.missionApprovalRequiredDocumentation[
                              approval.id
                          ]
                      )
                  )
            : [];

    const formResponses = combineFormResponses([
        documentation.missionDocumentation.formResponses
    ]);

    const rpaRequiredDocumentation = buildRpasRequiredDocumentation(
        documentation.rpaModelRequiredDocumentation,
        rpas,
        rpaNameFormatter
    );

    const missionRequiredDocumentation = buildMissionRequiredDocumentation(
        documentation.missionRequiredDocumentation
    );

    return {
        formResponses,
        requiredDocumentation: mergeRequiredDocumentation([
            missionRequiredDocumentation,
            rpaRequiredDocumentation,
            ...approvalRequiredDocumentation
        ])
    };
}

/**
 * Find the unsubmitted documentation and return them for submission.
 */
// export function prepareResponses<T extends SubmittedTime>(responses: { [dId: number]: T[] }, step: string) {
//     return {
//         [step]: Object.keys(responses)
//             .reduce((acc, dId) => acc.concat(responses[dId].filter((f: T) => f.submittedTime == null)),
//                 [])
//     }
// }

export function prepareResponses<T extends SubmittedTimeAndStep>(responses: {
    [dId: number]: T[];
}) {
    return Object.keys(responses).reduce((acc, dId) => {
        return deepmerge(
            acc,
            responses[dId]
                .filter((f: T) => f.submittedTime == null)
                .reduce(mergeResponseObject, {})
        );
    }, {});
}

function mergeResponseObject<T extends SubmittedTimeAndStep>(
    partial: { [step: string]: T[] },
    r: T
): { [step: string]: T[] } {
    return {
        ...partial,
        [r.step]: r.step in partial ? partial[r.step].concat(r) : [r]
    };
}

export function isComplete(progress: Progress) {
    return progress.total === progress.progress;
}

/**
 * Calculates whether the required documents for an entity are completed.
 */
export function areRequirementsCompleted(
    requirements: RelatedEntityRequiredDocumentation,
    formResponses: FormResponseFormGroup,
    step: string
) {
    const requiringEntities = findRequiringEntities([
        requirements.checklists ?? [],
        requirements.forms
    ]);

    const filteredFormResponses = filterResponses(
        formResponses,
        requirements.relatedEntityType,
        requirements.relatedEntityId,
        step,
        requiringEntities
    );

    const formsCompleted = requirements.forms.reduce(
        (acc, form) =>
            acc &&
            (!form.required ||
                checkFormProgress(
                    form.entity,
                    filteredFormResponses[form.entity.formId],
                    step
                ).completed),
        true
    );

    return formsCompleted;
}

/**
 * Finds a unique list of requiring entities
 */
export function findRequiringEntities(
    documentation: MaybeRequiringEntity[][]
): MaybeRequiringEntity[] {
    return documentation.reduce(
        (acc, docs) =>
            docs.reduce(
                (acc2, doc) =>
                    acc.findIndex(
                        r =>
                            r.requiringEntityId === doc.requiringEntityId &&
                            r.requiringEntityType === doc.requiringEntityType
                    ) !== -1
                        ? acc2
                        : acc2.concat({
                              requiringEntityId: doc.requiringEntityId,
                              requiringEntityType: doc.requiringEntityType
                          }),
                acc
            ),
        []
    );
}

/**
 * Checks if an array is defined and is of non-zero length
 * @param values array of values
 */
export function existsAndHasValue(values: any[]) {
    return values != null && values.length > 0;
}

/**
 * Create a new document response based on an existing response.
 * @param oldDocument existing response
 * @param requiringEntityType the new requiring entity type
 * @param requiringEntityId the new requiring entity id
 */
export function cleanDocument(
    oldDocument: FormResponseDto,
    requiringEntityType: string,
    requiringEntityId: number
): FormResponseCommand {
    return {
        ...oldDocument,
        requiringEntityType,
        requiringEntityId,
        completed: false,
        submittedTime: null
    };
}

/**
 * Takes a list of old form response values and adds values to the values field for any controls in the
 * new form that were derived from the old one by matching up IDs and derived IDs
 *
 * @param responses The old form responses that may contain prefill values from an older form version
 */
function buildDerivedFormResponses(
    responses: FormResponsesWithStep[],
    form: ConcreteFormDto
) {
    if (form == null) {
        return responses;
    }
    const controls = form.sections.reduce(
        (acc2, s) => acc2.concat(s.controls),
        []
    );
    const matchValueId = (valueKey: string) => {
        const id = parseInt(valueKey, 10);
        const matchedControl = controls.find(
            c =>
                c.derivedFromFormControlId === id ||
                c.derivedFromFormControlIdList.includes(id)
        );
        if (matchedControl != null) {
            return matchedControl.id.toString();
        }
        return valueKey;
    };
    responses.forEach(response =>
        Object.keys(response.values).forEach(key => {
            const matchedKey = matchValueId(key);
            if (matchedKey === key) {
                return;
            }
            response.values = {
                ...response.values,
                [matchedKey]: response.values[key]
            };
        })
    );
    return responses;
}

/**
 * Takes old form responses and checks to see whether any of the values are for controls in the current form
 * Checks against both current control IDs as well as all derived from IDs
 *
 * @param responses the old form responses that could contain prefill values from a previous form version
 * @param form The current form for the mission
 * @returns true if the current form's controls have any current or derived IDs that match the old response values or false if not
 */
function hasDerivedFormResponses(
    responses: FormResponsesWithStep,
    form: ConcreteFormDto
) {
    const controls: FormControlDto[] = form.sections.reduce(
        (acc2, s) => acc2.concat(s.controls),
        []
    );
    const matchedKeys = Object.keys(responses.values).reduce(
        (acc, k) =>
            controls.find(c => {
                const key = parseInt(k, 10);
                if (
                    c.id === key ||
                    c.derivedFromFormControlId === key ||
                    c.derivedFromFormControlIdList.includes(key)
                ) {
                    return true;
                }
                return false;
            })
                ? acc.concat(k)
                : acc,
        []
    );
    return matchedKeys.length > 0;
}

/**
 * Uses the old documentation responses to prefill the new documentation responses
 * @param currentFormResponses the current form responses
 * @param oldFormResponses the form responses from the old mission
 */
export function prefillPreviousDocumentation(
    currentFormResponses: FormResponseFormGroup,
    oldFormResponses: FormResponseFormGroup,
    requiredDocumentation: StepEntityRequiredDocumentation,
    step: string
): {
    formResponses: FormResponseFormGroup;
    prefillErrors: { [formId: number]: boolean };
} {
    const requiredDocs = requiredDocumentation[step];

    const filteredFormResponses = keys(oldFormResponses).reduce(
        (acc, formId) => {
            // formId tends to be a string, so we need to convert it to a number first.
            let formIdNumber;
            try {
                // @ts-ignore - formId is usually a string.
                formIdNumber = parseInt(formId, 10);
            } catch (error) {
                formIdNumber = formId;
            }

            // find the form using these responses
            const requiredForm = requiredDocs.reduce((acc, d) => {
                const form = d.forms.find(
                    f =>
                        f.entity.derivedFromFormId === formIdNumber ||
                        f.entity.formId === formIdNumber
                );
                return form != null ? acc.concat(form.entity) : acc;
            }, [])[0];

            return {
                ...acc,
                // use the matching form's id so the response mapping works later, otherwise use the responses' id.
                [formIdNumber]: buildDerivedFormResponses(
                    oldFormResponses[formId].filter(f => f.step === step),
                    requiredForm
                )
            };
        },
        currentFormResponses
    );

    const findRelatedFormId = (entity: ConcreteFormDto) => {
        if (entity.formId in filteredFormResponses) {
            return entity.formId;
        } else if (entity.derivedFromFormId in filteredFormResponses) {
            return entity.derivedFromFormId;
        } else {
            return null;
        }
    };

    const prefillErrors = requiredDocs.reduce(
        (acc, req) =>
            req.forms
                .filter(
                    f =>
                        findRelatedFormId(f.entity) != null &&
                        filteredFormResponses[findRelatedFormId(f.entity)]
                            .length > 0
                )
                .reduce(
                    (acc2, f) => ({
                        ...acc,
                        [f.entity.formId]:
                            filteredFormResponses[
                                findRelatedFormId(f.entity)
                            ][0].formVersionId !== f.entity.formVersionId &&
                            !hasDerivedFormResponses(
                                filteredFormResponses[
                                    findRelatedFormId(f.entity)
                                ][0],
                                f.entity
                            )
                    }),
                    acc
                ),
        {}
    );

    const formResponses = requiredDocs.reduce(
        (acc, req) =>
            deepmerge(
                acc,
                req.forms
                    .filter(
                        f =>
                            findRelatedFormId(f.entity) != null &&
                            filteredFormResponses[findRelatedFormId(f.entity)]
                                .length > 0
                    )
                    .reduce((forms, f) => {
                        if (
                            filteredFormResponses[
                                findRelatedFormId(f.entity)
                            ][0].formVersionId === f.entity.formVersionId
                        ) {
                            if (f.entity.formId in currentFormResponses) {
                                const currentResponses =
                                    currentFormResponses[f.entity.formId];
                                if (currentResponses.length > 0) {
                                    return {
                                        ...forms,
                                        [f.entity.formId]: currentResponses
                                    };
                                }
                            }
                            return {
                                ...forms,
                                [f.entity.formId]: [
                                    cleanDocument(
                                        filteredFormResponses[
                                            findRelatedFormId(f.entity)
                                        ][0],
                                        f.requiringEntityType,
                                        f.requiringEntityId
                                    )
                                ]
                            };
                        } else if (
                            // If the form isn't of the same version as the old responses,
                            // then check if it has any derived fields that match prefill values
                            hasDerivedFormResponses(
                                filteredFormResponses[
                                    findRelatedFormId(f.entity)
                                ][0],
                                f.entity
                            )
                        ) {
                            const formWithValues: FormResponsesWithStep = {
                                ...filteredFormResponses[
                                    findRelatedFormId(f.entity)
                                ][0],
                                formId: f.entity.formId,
                                formName: f.entity.formName,
                                formVersionId: f.entity.formVersionId,
                                description: f.entity.description,
                                sections: f.entity.sections
                            };
                            return {
                                ...forms,
                                [f.entity.formId]: [
                                    cleanDocument(
                                        formWithValues,
                                        f.requiringEntityType,
                                        f.requiringEntityId
                                    )
                                ]
                            };
                        } else {
                            return forms;
                        }
                    }, {})
            ),
        {}
    );

    return { formResponses, prefillErrors };
}
