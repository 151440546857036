<h4>Pending Invitations</h4>
<form>
    <table class="table">
        <thead>
            <tr>
                <th>Organisation Name</th>
                <th>Roles</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let invite of invites">
                <td>
                    {{ invite.organisationName }}
                </td>
                <td>
                    {{ roleLookup[invite.id] }}
                </td>
                <td>
                    <button
                        class="btn btn-primary btn-xs"
                        type="button"
                        (click)="acceptInvite(invite)"
                    >
                        Accept
                    </button>
                    <button
                        class="btn btn-danger btn-xs"
                        type="button"
                        (click)="ignoreInvite(invite)"
                    >
                        Ignore
                    </button>
                </td>
            </tr>
            <tr *ngIf="invites == null || invites.length === 0">
                <td colspan="3">
                    <div class="alert alert-info" role="alert">
                        You have no pending invitations.
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</form>
