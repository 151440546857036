<enhanced-help componentName="notes" [compact]="true"></enhanced-help>
<div class="container-with-footer">
    <div class="contents">
        <div *ngIf="source.notes" class="pre-wrap" [innerHtml]="source.notes | autolink"></div>
        <empty *ngIf="!source.notes && canEdit" componentName="notes-empty-edit"></empty>
        <empty *ngIf="!source.notes && !canEdit" componentName="notes-empty"></empty>
    </div>
    <div class="action-container">
        <button
            *ngIf="hasEdit"
            [disabled]="!canEdit || working"
            class="btn btn-primary"
            type="button"
            (click)="editNotes()"
        >
            Edit
        </button>
    </div>
</div>
