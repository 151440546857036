import { Injectable } from '@angular/core';
import { FlyFreelyConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { ResultDto } from '../model/api';

@Injectable({
    providedIn: 'root'
})
export class UserLocationService {
    private baseUrl: string;
    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findUserLocation() {
        return this.http.get<ResultDto>(
            `${this.baseUrl}/publicapi/userLocation`
        );
    }
}
