import { BehaviorSubject, Subscription } from 'rxjs';

import { Injectable } from '@angular/core';

/**
 * The Work Tracker simply keeps track of the number of outstanding tokens it has
 * issued as a way of tracking how many tasks are currently running.
 *
 * It can be used directly, or as a scoped service.
 */
@Injectable()
export class WorkTracker {
    private counter: number = 0;
    private outstanding: number[] = [];
    private subject = new BehaviorSubject<boolean>(false);
    observable = this.subject.asObservable();

    private parentTracker: WorkTracker;
    private parentTrackerSubscription: Subscription;

    ngOnDestroy() {
        this.subject.complete();
        this.parentTrackerSubscription?.unsubscribe();
    }

    /**
     * Get the next token.
     */
    private next() {
        const nextCounter = this.counter++;
        this.outstanding.push(nextCounter);

        return nextCounter;
    }

    /**
     * Create a tracker, and return a function that is used to close off the task.
     */
    createTracker(): () => void {
        if (this.parentTracker) {
            return this.parentTracker.createTracker();
        }

        const counter = this.next();

        this.subject.next(true);

        return () => this.destroyToken(counter);
    }

    private destroyToken(token: number) {
        this.outstanding.splice(this.outstanding.indexOf(token), 1);

        if (this.outstanding.length === 0) {
            this.subject.next(false);
        }
    }

    active(): boolean {
        return this.parentTracker == null
            ? this.subject.getValue()
            : this.parentTracker.active();
    }

    /**
     * @deprecated use `observable` instead
     */
    asObservable() {
        return this.subject.asObservable();
    }

    setParentTracker(parentTracker: WorkTracker) {
        // Clean up old parent tracker
        if (this.parentTracker != null) {
            this.parentTrackerSubscription.unsubscribe();
            this.parentTracker = null;
            this.parentTrackerSubscription = null;
            return;
        }

        // Setup new parent tracker if required
        if (parentTracker != null) {
            this.parentTracker = parentTracker;
            this.parentTrackerSubscription = this.parentTracker
                .asObservable()
                .subscribe(working => this.subject.next(working));
        }

    }
}
