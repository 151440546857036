import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    FlyFreelyConstants,
    MissionSummaryDto,
    WidgetMission
} from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatMissionStatusIcon' })
export class FormatMissionStatusIconPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
        private constants: FlyFreelyConstants
    ) {}

    transform(mission: WidgetMission, returnSafeHtml = true) {
        if (mission === null) {
            return null;
        }

        const statusHtml = this.createHtml(mission);

        // if (returnSafeHtml) {
        //    return this.sanitizer.bypassSecurityTrustHtml(statusHtml);
        // } else {
        return statusHtml;
        // }
    }

    createHtml(mission: WidgetMission) {
        const statusColour = this.findStatusColour(mission.status);
        if (mission.isDummy) {
            return `
            <div class="status-icon-block">
                <div class="status-icon">
                    <div>
                        <img src="${this.constants.IMG_URL}/icons/dummy-icon.png" />
                    </div>
                </div>
                <div class="name-with-icon">
                ${mission.name}
                </div>
            </div>`;
        } else {
            return `
            <div class="status-icon-block">
                <div class="status-icon">
                    <div class="status-icon-${statusColour}"></div>
                </div>
                <div class="name-with-icon">
                ${mission.name}
                </div>
            </div>`;
        }
    }

    findStatusColour(status: MissionSummaryDto.Status) {
        switch (status) {
            case MissionSummaryDto.Status.READY_TO_FLY:
            // fallthrough
            case MissionSummaryDto.Status.ACTIVATED:
            // fallthrough
            case MissionSummaryDto.Status.PREPARED:
                return 'green';

            case MissionSummaryDto.Status.ON_SITE:
            // fallthrough
            case MissionSummaryDto.Status.FLYING:
            // fallthrough
            case MissionSummaryDto.Status.DONE_FLYING:
            // fallthrough
            case MissionSummaryDto.Status.AWAITING_SYNC:
                return 'yellow';

            case MissionSummaryDto.Status.DRAFT:
                return 'grey';

            case MissionSummaryDto.Status.CANCELLED:
                return 'red';

            // fallthrough
            case MissionSummaryDto.Status.COMPLETED:
            // fallthrough
            case MissionSummaryDto.Status.FINALISED:
                return 'blue';
        }
    }
}
