import { Component } from '@angular/core';
import {
    CraftDto,
    EquipmentDto,
    observeFormControl
} from '@flyfreely-portal-ui/flyfreely';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MissionEditService } from './mission-edit.service';

@Component({
    selector: 'add-associated-resources',
    template: `
        <ng-container *ngIf="available$ | async as available">
            <h5 *ngIf="available.length > 0">
                Equipment Associated with Mission RPA
            </h5>
            <p *ngIf="available.length > 0">
                Quickly select the equipment commonly used with RPA you've
                selected for this mission
            </p>
            <div *ngFor="let rpa of available">
                <p class="display-label">{{ rpa.rpa.nickname }}</p>
                <button
                    *ngFor="let equipment of rpa.equipmentList"
                    type="button"
                    class="btn btn-sm btn-pill"
                    (click)="addEquipment(equipment.id)"
                >
                    {{ equipment.name }}
                </button>
            </div>
        </ng-container>
    `
})
export class AddAssociatedResources {
    available$: Observable<{ rpa: CraftDto; equipmentList: EquipmentDto[] }[]>;

    constructor(private missionEditService: MissionEditService) {
        this.available$ = combineLatest([
            missionEditService.equipment$,
            missionEditService.rpas$,
            observeFormControl(
                missionEditService.missionForm.controls.craftIds
            ),
            observeFormControl(
                missionEditService.missionForm.controls.equipmentIds
            )
        ]).pipe(
            map(
                ([
                    equipmentList,
                    rpaList,
                    selectedRpaIdList,
                    selectedEquipmentIdList
                ]) =>
                    selectedRpaIdList
                        .map(rpaId => rpaList.find(rpa => rpa.id === rpaId))
                        .filter(rpa => rpa != null)
                        .map(rpa => ({
                            rpa,
                            equipmentList: rpa.associatedEquipmentIdList
                                .filter(
                                    equipmentId =>
                                        !selectedEquipmentIdList.includes(
                                            equipmentId
                                        )
                                )
                                .map(equipmentId =>
                                    equipmentList.find(
                                        eq => eq.id === equipmentId
                                    )
                                )
                                .filter(eq => eq != null)
                        }))
                        .filter(rpa => rpa.equipmentList.length > 0)
            )
        );
    }

    addEquipment(equipmentId: number) {
        this.missionEditService.addEquipment(equipmentId);
    }
}
