<div class="certification-container">
    <ng-container *ngFor="let authorityGroup of authorityGroups">
        <!-- The required class will only show a red tick if the dependent authority type already has details -->
        <!-- Otherwise the authority creation will be guided via toasts -->
        <div
            class="certification-content onboarding-frame"
            [ngClass]="{
                notfinished:
                    !hasAuthority(authorityGroup) &&
                    (requiredByAuthority(authorityGroup) == null ||
                        requiredByAuthority(authorityGroup).authorities ==
                            null ||
                        requiredByAuthority(authorityGroup).authorities
                            .length === 0),
                required:
                    !hasAuthority(authorityGroup) &&
                    requiredByAuthority(authorityGroup) != null &&
                    requiredByAuthority(authorityGroup).authorities.length > 0,
                'not-clickable':
                    authorityGroup.issuingMechanism !== 'SELF_REPORTED' ||
                    !requiredAuthorityAdded(authorityGroup),
                disabled: !requiredAuthorityAdded(authorityGroup),
                'popover-displacement':
                    requiredByAuthority(authorityGroup) != null &&
                    (authorityGroup.authorities == null ||
                        authorityGroup.authorities.length === 0)
            }"
        >
            <div
                *ngIf="
                    requiredByAuthority(authorityGroup) != null &&
                    (authorityGroup.authorities == null ||
                        authorityGroup.authorities.length === 0)
                "
                class="certification-popover"
            >
                {{authorityGroup.abbreviation ?? authorityGroup.name}} is
                required by {{ requiredByAuthority(authorityGroup).name }}
            </div>
            <div
                *ngIf="hasAuthority(authorityGroup)"
                (click)="
                    editAuthority(authorityGroup, authorityGroup.authorities[0])
                "
            >
                <i class="far fa-check-circle indication_topright"></i>
                <h4>
                    {{ authorityGroup.name }}
                    <i
                        *ngIf="authorityGroup.description"
                        class="fas fa-info-circle info-tooltip"
                        [tooltip]="authorityGroup.description"
                        container="certification-content"
                    ></i>
                </h4>
                <h5>Issued by {{ authorityGroup.issuedBy.name }}</h5>
                <p *ngIf="requiredByAuthority(authorityGroup) != null">
                    Required by {{ requiredByAuthority(authorityGroup).name }}
                </p>
                <p>
                    Start Date:
                    {{
                        authorityGroup.authorities[0].startDate
                            ? (authorityGroup.authorities[0].startDate
                              | formatDate)
                            : '-'
                    }}
                </p>
                <p>
                    Expiry:
                    {{
                        authorityGroup.authorities[0].expiryDate
                            ? (authorityGroup.authorities[0].expiryDate
                              | formatDate)
                            : '-'
                    }}
                </p>

                <p
                    *ngIf="
                        authorityGroup.hasIdentifier &&
                        authorityGroup.authorities[0].identifier
                    "
                >
                    {{ authorityGroup.identifierLabel }}:
                    {{ authorityGroup.authorities[0].identifier }}
                </p>

                <p
                    class="help-block pre-wrap"
                    [innerHtml]="authorityGroup.helperText | autolink"
                ></p>
            </div>

            <div
                *ngIf="!hasAuthority(authorityGroup)"
                (click)="addAuthority(authorityGroup)"
            >
                <i class="far fa-check-circle indication_topright"></i>
                <h4>
                    {{ authorityGroup.name }}
                    <i
                        *ngIf="authorityGroup.description"
                        class="fas fa-info-circle info-tooltip"
                        [tooltip]="authorityGroup.description"
                        container="certification-content"
                    ></i>
                </h4>
                <h5>Issued by {{ authorityGroup.issuedBy.name }}</h5>
                <p *ngIf="requiredByAuthority(authorityGroup) != null">
                    Required by {{ requiredByAuthority(authorityGroup).name }}
                </p>
                <p
                    class="help-block pre-wrap"
                    [innerHtml]="authorityGroup.helperText | autolink"
                ></p>
            </div>

            <button
                class="btn sales-button"
                *ngIf="
                    authorityGroup.hasContactSales &&
                    !hasContactedSales(authorityGroup)
                "
                (click)="contactSales(authorityGroup)"
            >
                Contact Sales
            </button>
        </div>
    </ng-container>

    <div class="custom--btn">
        <button (click)="previousScreen()">Back</button>
        <button
            class="next"
            name="organisationcertification_next"
            [disabled]="working || !hasAddedAllRequired"
            (click)="nextScreen()"
        >
            Next
        </button>
        <!-- <button type="organisationcertification_skip" (click)="nextScreen()">
            Skip
        </button> -->
    </div>
</div>
