<div class="d-flex align-items-start" style="margin-bottom: 10px;">
    <div style="margin-right: 1rem;">
        <person-avatar
            [person]="comment.author"
            [managingOrganisationId]="organisationId"
            [width]="25"
            [height]="25"
        >
        </person-avatar>
    </div>
    <div style="flex-grow: 1">
        <div>
            <strong style="margin-right: 10px">{{ comment.author?.firstName }} {{ comment.author?.lastName }}</strong>
            <span>{{ comment.modificationTime | formatDateTime }}</span>
        </div>
        <div style="background: #F8F9FA;">
            <quill-view
                *ngIf="!isEditing"
                [content]="comment?.content"
                format="object"
            ></quill-view>
            <rich-text-editor *ngIf="isEditing"
                              [content]="comment?.content"
                              (saveEdit)="onUpdateComment($event)"
                              (cancelEdit)="onCancelEdit()"
            ></rich-text-editor>
        </div>
        <ng-container *ngIf="allowEdit">
            <span *ngIf="!isEditing" class="text-info" style="cursor: pointer; margin-right: 10px;" (click)="onEdit()">Edit</span>
            <span *ngIf="!isEditing" class="text-danger" style="cursor: pointer;" (click)="onDelete()">Delete</span>
        </ng-container>
    </div>
</div>
