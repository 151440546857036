/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.14-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveOrganisationJurisdiction } from './activeOrganisationJurisdiction';
import { NotificationPreferenceDto } from './notificationPreferenceDto';


/**
 * 
 */
export interface CurrentPersonDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The person\'s first name
     */
    firstName: string;
    /**
     * The person\'s last name
     */
    lastName: string;
    /**
     * The email address used to contact this person
     */
    email: string;
    /**
     * The person\'s phone number, if provided
     */
    phoneNumber?: string;
    /**
     * The person\'s username, if they have one
     */
    username?: string;
    /**
     * The ID of the person\'s personal organisation
     */
    personalOrganisationId: number;
    /**
     * The ID of the person\'s default organisation
     */
    defaultOrganisationId: number;
    /**
     * The person\'s date of birth
     */
    dateOfBirth?: string;
    activeJurisdictions: Array<ActiveOrganisationJurisdiction>;
    /**
     * The type of system user this is
     */
    type?: CurrentPersonDto.Type;
    /**
     * Additional user attributes
     */
    userAttributes: { [key: string]: string; };
    /**
     * ${swagger.Person.disabledNotifications
     */
    disabledNotifications?: Array<NotificationPreferenceDto>;
}
export namespace CurrentPersonDto {
    export type Type = 'USER' | 'SYSTEM_ADMIN';
    export const Type = {
        USER: 'USER' as Type,
        SYSTEM_ADMIN: 'SYSTEM_ADMIN' as Type
    };
}



