import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AddressDialoguesService } from './address-dialogues.service';
import { AddressEditDialogue } from './address-edit/address-edit-dialogue.component';
import { AddressList } from './address-list/address-list.component';

@NgModule({
    imports: [
        CommonModule,
        ModalModule.forChild(),
        LoadingIndicatorModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        ValidationModule,
        TooltipModule,
        FormlyModule,
        FlyFreelyFormlyModule
    ],
    providers: [AddressDialoguesService],
    bootstrap: [],
    declarations: [AddressEditDialogue, AddressList],
    exports: [AddressEditDialogue, AddressList]
})
export class AddressesModule {}
