import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    AirspaceAuthorisationManager,
    mapSourceFiltersForResponse
} from '@flyfreely-portal-ui/airspace';
import {
    AirspaceAuthorisationDto,
    AirspaceAuthorisationService,
    AirspaceCheckCommand,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    ApproversMissionApprovalDto,
    AttachmentHandler,
    BatterySetDto,
    BatterySetService,
    buildDisplayableMissionDocumentation,
    buildMissionRequiredDocumentation,
    calculateCombinedStatus,
    calculateEditableSteps,
    calculateValidatedSteps,
    combineFormResponses,
    CraftDto,
    DEFAULT_MAX_HEIGHT,
    DisplayableMissionDocumentation,
    DisplayableMissionDto,
    DownloadService,
    DO_NOTHING,
    FEATURE_CASA_AUTHORISATION,
    FEATURE_EQUIPMENT,
    FlyFreelyError,
    FlyFreelyLoggingService,
    FormResponseCommand,
    hasFeatureFlag,
    LocationDetailsDto,
    LocationFeatureDto,
    LocationService,
    MaybeRequiringEntity,
    MissionApprovalService,
    MissionApprovalUpdateCommand,
    MissionCrewDetailsDto,
    MissionDocumentationDto,
    MissionReportService,
    MissionService,
    NameValue,
    Organisation,
    PersonDto,
    PersonService,
    PersonsOrganisationDto,
    PreferencesService,
    PrintOption,
    RejectMissionApprovalCommand,
    RequestersMissionApprovalDto,
    RpaTypeDto,
    RpaTypesService,
    RulesetDto,
    SimpleResourceDetails,
    SortieDto,
    StepDescription,
    StepEntityRequiredDocumentation,
    toLookup,
    unique,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaPipe } from '@flyfreely-portal-ui/resource-ui';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Angulartics2 } from 'angulartics2';
import { getOrElse } from 'fp-ts/es6/Option';
import { FeatureCollection } from 'geojson';
import {
    AirspaceCheckService,
    findLocationFlightAreaPolygon
} from 'libs/airspace/src/lib/airspace-check/airspace-check.service';
import { AirspaceDialoguesService } from 'libs/airspace/src/lib/airspace-dialogues.service';
import { ApplyApprovalConditions } from 'libs/approval-conditions/src/lib/apply-approvals/apply-approval-conditions.component';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { DocumentationActions } from 'libs/documentation/src/lib/interfaces';
import { EquipmentDialoguesService } from 'libs/equipment/src/lib/equipment-dialogues.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { getFeatureGroups, toMapFeature } from 'libs/locations/src/lib/helpers';
import { MapSourceFilters } from 'libs/map/src/lib/dynamic-data.service';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { SummaryHeader } from 'libs/missions/src/lib/summary-header/summary-header.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { PersonnelDialoguesService } from 'libs/personnel/src/lib/personnel-dialogues.service';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { missionApprovalFields } from '../fieldsets';
import {
    approvalRequiringEntities,
    ApprovalScreenStatus,
    findActiveApprovals,
    findMissionAirspaceJurisdictionId,
    notamToFeatureCollection
} from '../helpers';
import { MissionApprovalWithAuthority } from '../interfaces';
import { MissionDialoguesService } from '../mission-dialogues.service';

const AIRSPACE_AUTHORISATION_OPT_IN = 'airspace-authorisation-crp-opt-in';

@Component({
    selector: 'mission-approval-v2-dialogue',
    templateUrl: './mission-approval-v2-dialogue.component.html',
    providers: [AirspaceCheckService, AirspaceAuthorisationManager, WorkTracker]
})
export class MissionApprovalV2Dialogue implements OnInit, OnDestroy {
    @Input() missionId: number;
    @Input() organisation: Organisation;
    @Input() approvalId: number;
    @Input() missionApproval: MissionApprovalWithAuthority;
    @Output() approvalChanged = new EventEmitter<void>();

    @ViewChild('approvalConditionsTable', { static: true })
    approvalConditionsTable: ElementRef;

    @ViewChild('map', { read: ElementRef, static: false }) map: ElementRef;

    @ViewChild(SummaryHeader, { static: false }) summaryHeader: SummaryHeader;

    mission: DisplayableMissionDto;
    missionDate: moment.Moment;

    validationFields: FormlyFieldConfig[] = missionApprovalFields;

    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    printOptions: PrintOption[] = [];
    approvalSteps: NameValue[];
    savedApproval: any;
    location: LocationDetailsDto;
    actualLocation: LocationDetailsDto;
    approval: ApproversMissionApprovalDto;
    documentation: DisplayableMissionDocumentation;
    selectedAddConditions: boolean;

    airspaceJurisdictionId: number;

    missionDocumentationSteps: StepDescription[];

    locationFeatures: FeatureGroup[];

    missionStartTime: string;
    missionEndTime: string;
    missionRuleset: RulesetDto;
    mapSourceFilters: MapSourceFilters;
    enabledMapLayerGroups: string[];

    // Holds filtered details of the mission
    missionDetails: {
        rpic: PersonDto;
        additionalCrew: MissionCrewDetailsDto[];
    };

    craftLookup: { [rpaId: number]: CraftDto };
    personLookup: { [personId: number]: PersonDto };
    batterySetLookup: { [batterySetId: number]: BatterySetDto };

    requiringEntities: MaybeRequiringEntity[];
    approvalRequiredDocumentation: {
        [approvalId: number]: StepEntityRequiredDocumentation;
    };

    conditions: FormArray;
    form: FormGroup;

    isCompleted: boolean;

    canFinalise: boolean;

    canUseApprovalConditionsLibrary: boolean;

    canUseEquipment: boolean;

    isApprovalChecklistChanged = false;
    isMissionApprovable = false;
    isMissionApprovalRevokeable = false;
    isPrimaryWorkflowApproval = false;

    uploadError: string;
    status: ApprovalScreenStatus;
    attachmentsHandler: AttachmentHandler;

    documentationActions: DocumentationActions;

    // Variables for airspace checker
    canRequestAutomaticApproval = false;
    airspaceCheckCommand: AirspaceCheckCommand;
    approvedAirspaceAuthorisations: AirspaceAuthorisationDto[];
    enabledAuthorisationTypes: AirspaceAuthorisationDto.AuthorisationType[];
    rpaTypes: RpaTypeDto[];
    checkingAuthorisation: boolean;
    showCrpOptIn: boolean;
    canDoAirspaceCheck: boolean;
    canViewAirspaceDetailsDialogue = false;
    gcdVersion: string;

    showApprovalTabs = false;

    mapData$ = new BehaviorSubject<{ [source: string]: FeatureCollection }>({});

    constructor(
        private modal: BsModalRef<MissionApprovalV2Dialogue>,
        private userService: UserService,
        private missionApprovalService: MissionApprovalService,
        private missionService: MissionService,
        private formatRpaPipe: FormatRpaPipe,
        private batterySetService: BatterySetService,
        private personService: PersonService,
        private personnelDialoguesService: PersonnelDialoguesService,
        private rpaDialoguesService: RpaDialoguesService,
        private rpaTypesService: RpaTypesService,
        private equipmentDialoguesService: EquipmentDialoguesService,
        private missionReportService: MissionReportService,
        private airspaceCheckService: AirspaceCheckService,
        private airspaceAuthorisationService: AirspaceAuthorisationService,
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        private airspaceDialoguesService: AirspaceDialoguesService,
        private preferencesService: PreferencesService,
        private downloadService: DownloadService,
        @Inject(forwardRef(() => MissionDialoguesService))
        private missionDialoguesService: MissionDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private fullScreenService: FullScreenService,
        private locationService: LocationService,
        private angulartics2: Angulartics2,
        private modalService: BsModalService,
        private workTracker: WorkTracker,
        modalOptions: ModalOptions
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        // The reference object for testing if the approval is saved
        this.savedApproval = null;
        airspaceAuthorisationManager.setup([
            AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION
        ]);

        this.approvalSteps = missionApprovalService.getApprovalSteps();

        this.setupDocumentationActions();

        modalOptions.closeInterceptor = () => {
            if (
                this.form.dirty ||
                this.isApprovalChecklistChanged ||
                (this.summaryHeader.message &&
                    this.summaryHeader.message !== '')
            ) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => {
                        if (!!this.summaryHeader.message) {
                            this.modalService.setDismissReason(
                                this.summaryHeader.message
                            );
                        }
                        return Promise.resolve();
                    }
                );
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.airspaceAuthorisationManager.setup(
            [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION],
            this.mission?.id
        );
        this.findIfCanDoAuthorisation();
        this.refreshMission();
        this.refreshPreferences();

        this.missionService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMission());

        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.refreshAirspaceApproval(result);
            });

        this.airspaceAuthorisationService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMission());

        this.airspaceAuthorisationManager.missionAirspaceAuthorisations$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.approvedAirspaceAuthorisations = result;
                this.setupAirspaceOperatingArea();
                this.updateIsMissionApprovable();
            });

        this.airspaceAuthorisationManager.checkingAuthorisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(checking => (this.checkingAuthorisation = checking));

        this.rpaTypesService
            .findRpaTypes(this.mission?.organisationId ?? this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(types => {
                this.rpaTypes = types;
                this.runAirspaceCheck();
            })
            .add(this.workTracker.createTracker());

        // Status of mission approval
        this.status = {
            readOnly: false,
            isPending: false,
            isBeingReviewed: false,
            canAttachFiles: false
        };
        this.attachmentsHandler = this.missionService.attachmentHandler(
            this.missionId
        );
        this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);

        this.canUseApprovalConditionsLibrary =
            this.organisation &&
            this.organisation.featureFlags.indexOf(
                'approvalConditionsLibrary'
            ) !== -1;

        this.canUseEquipment =
            this.organisation &&
            this.organisation.featureFlags.indexOf(FEATURE_EQUIPMENT) !== -1;

        this.setupForm();
    }

    drop(event: CdkDragDrop<any[]>, array: any[]) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(array, event.previousIndex, event.currentIndex);
        }
    }
    private createCondition(condition: string) {
        return new FormControl(condition);
    }

    setupForm() {
        this.conditions = new FormArray([]);
        this.form = new FormGroup({
            conditions: this.conditions,
            messageFromApprover: new FormControl(undefined),
            approversNotes: new FormControl(undefined),
            maximumHeight: new FormControl(undefined)
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.mapData$.complete();
    }

    refreshMission() {
        this.missionService
            .findMission(this.missionId)
            .toPromise()
            .then(mission => {
                this.setupMission(mission);

                if (mission.location == null) {
                    this.locationFeatures = null;
                } else {
                    const { features } = getFeatureGroups(
                        mission.location.features,
                        0
                    );
                    this.locationFeatures = features;

                    this.missionStartTime = this.mission.missionDate;
                    this.missionEndTime = moment(
                        moment(this.mission.missionDate)
                    )
                        .add(mission.missionEstimatedTime, 'seconds')
                        .toISOString();
                    this.missionRuleset =
                        mission.missionWorkflowVersion?.delegatedAuthority?.authorityType?.ruleset;
                }

                if (mission.actualLocationId != null) {
                    this.locationService
                        .findLocation(mission.actualLocationId)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe(location => (this.actualLocation = location))
                        .add(this.workTracker.createTracker());
                }

                this.refreshDocumentation();

                const editableSteps = calculateEditableSteps(
                    this.mission.status,
                    false,
                    this.canFinalise,
                    this.mission.availableActions.canEdit
                );
                const validatedSteps = calculateValidatedSteps(
                    this.mission.status
                );

                this.missionDocumentationSteps =
                    this.missionService.getMissionSteps(
                        editableSteps,
                        validatedSteps
                    );
            });
    }

    private setupMission(mission: DisplayableMissionDto) {
        this.mission = mission;

        this.showApprovalTabs = false;
        // Setting a "feature flag" here since it relies on the mission location
        this.canDoAirspaceCheck =
            this.mission.location?.airspaceJurisdiction
                ?.airspaceCheckSupport ===
            AirspaceJurisdictionDto.AirspaceCheckSupport.AIRSPACE_CHECK;

        this.showApprovalTabs = true;
        this.isPrimaryWorkflowApproval =
            this.mission.missionWorkflowVersion?.delegatedAuthority != null &&
            this.missionApproval?.authority != null &&
            this.mission.missionWorkflowVersion.delegatedAuthority.id ===
                this.missionApproval.authority.id;

        this.missionDate = moment(this.mission.missionDate).tz(
            this.mission.timeZone
        );

        this.craftLookup = this.mission.crafts.reduce(toLookup, {});
        this.personLookup = this.mission.missionCrewDetails
            .map(c => c.person)
            .reduce(toLookup, {});

        this.isCompleted =
            [
                DisplayableMissionDto.Status.COMPLETED,
                DisplayableMissionDto.Status.FINALISED
            ].find(s => s === this.mission.status) != null;

        this.canFinalise = this.mission.availableActions.canFinalise;

        this.refreshBatterySets();

        this.refreshAvailableReports();

        this.findPendingApproval(mission.approvals);

        const rpic = mission.missionCrewDetails.find(
            c => c.role.coreRole === 'PILOT_IN_COMMAND'
        );
        this.missionDetails = {
            rpic: rpic != null ? rpic.person : null,
            additionalCrew: mission.missionCrewDetails.filter(
                c => c.role.coreRole !== 'PILOT_IN_COMMAND'
            )
        };

        this.airspaceJurisdictionId =
            findMissionAirspaceJurisdictionId(mission);
        if (this.canDoAirspaceCheck) {
            this.runAirspaceCheck();
        }

        this.mapData$.next({
            NOTAM: notamToFeatureCollection(this.mission.notams)
        });
    }

    private findPendingApproval(results: RequestersMissionApprovalDto[]) {
        // FIXME this is currently looking for the latest approval, when really we need to just find
        // approvals with appropriate statuses.
        if (
            results.length === 0 ||
            results.find(approval => approval.id === this.approvalId).status ===
                'REVOKED'
        ) {
            this.approval = null;
            this.status.isBeingReviewed = false;
            this.status.isPending = false;
            this.status.readOnly = true;
            this.status.canAttachFiles = false;
            this.updateIsMissionApprovable();
            return;
        }
        const relevantApproval = results.find(
            approval => approval.id === this.approvalId
        );

        if (relevantApproval.status === 'APPROVED') {
            const missionStatus = calculateCombinedStatus(
                this.mission.status,
                findActiveApprovals(this.mission.approvals, true).map(
                    a => a.status
                ),
                this.mission.readyForFinalisation
            );
            if (missionStatus === 'READY_TO_FLY') {
                this.isMissionApprovalRevokeable = true;
            }
        }

        if (
            relevantApproval.status === 'PENDING' ||
            relevantApproval.status === 'BEING_REVIEWED'
        ) {
            const doneWorking = this.workTracker.createTracker();
            this.missionApprovalService
                .findMissionApproval(relevantApproval.id)
                .toPromise()
                .then(
                    (approval: any) => {
                        if (approval.status === 'PENDING') {
                            this.status.isPending = true;
                        } else if (approval.status === 'BEING_REVIEWED') {
                            this.status.isBeingReviewed = true;
                        }
                        this.status.canAttachFiles = true;
                        this.approval = approval;
                        this.form.patchValue({
                            messageFromApprover:
                                this.approval.messageFromApprover,
                            approversNotes: this.approval.approversNotes,
                            maximumHeight: this.approval.maximumHeight
                        });
                        this.approval.conditions.forEach(c =>
                            this.conditions.push(this.createCondition(c))
                        );
                        this.savedApproval = { ...this.approval };
                        this.approval.conditions =
                            this.approval.conditions || [];
                        this.updateIsMissionApprovable();
                        doneWorking();
                    },
                    error => {
                        this.logging.error(error);
                        doneWorking();
                    }
                );
        } else {
            const doneWorking = this.workTracker.createTracker();
            this.missionApprovalService
                .findMissionApproval(relevantApproval.id)
                .toPromise()
                .then(
                    (approval: any) => {
                        this.approval = approval;
                        this.form.patchValue({
                            messageFromApprover:
                                this.approval.messageFromApprover,
                            approversNotes: this.approval.approversNotes,
                            maximumHeight: this.approval.maximumHeight
                        });
                        this.approval.conditions.forEach(c =>
                            this.conditions.push(this.createCondition(c))
                        );
                        this.status.readOnly = true;
                        this.status.canAttachFiles =
                            this.mission.status !== 'FINALISED';
                        doneWorking();
                    },
                    error => {
                        this.logging.error(error);
                    }
                );
        }
    }

    private findIfCanDoAuthorisation() {
        this.enabledAuthorisationTypes =
            hasFeatureFlag(this.organisation, FEATURE_CASA_AUTHORISATION) &&
            hasFeatureFlag(
                this.userService.getPersonalOrganisation(),
                FEATURE_CASA_AUTHORISATION
            )
                ? [
                      AirspaceAuthorisationDto.AuthorisationType
                          .AUS_CASA_AUTHORISATION
                  ]
                : [];
    }

    markApprovalChecklistChanged() {
        this.isApprovalChecklistChanged = true;
        this.updateIsMissionApprovable();
    }

    updateIsMissionApprovable() {
        this.isMissionApprovable =
            this.approval != null &&
            this.approval.mappedApproverChecks['pre-submit'].reduce(
                (acc, check) =>
                    acc &&
                    this.approval.completedChecks.indexOf(check.id) !== -1,
                true
            );
    }

    refreshPreferences() {
        this.preferencesService
            .findPreferencesAsOption(AIRSPACE_AUTHORISATION_OPT_IN, null)
            .pipe(
                map(p => getOrElse(() => ({ show: true }))(p).show),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(show => (this.showCrpOptIn = show))
            .add(this.workTracker.createTracker());
    }

    setupAirspaceOperatingArea() {
        this.approvedAirspaceAuthorisations.map(authorisation => {
            const area = authorisation.operatingArea;
            if (area != null) {
                const geometry = area != null ? <GeoJSON.Polygon>area : null;
                this.addFeature({
                    name: 'Operating Area',
                    type: LocationFeatureDto.Type.FLIGHT_AREA,
                    geometry: geometry
                } as LocationFeatureDto);
            }
        });
    }

    private addFeature(locationFeature: LocationFeatureDto) {
        if (this.locationFeatures == null) {
            this.locationFeatures = [];
        }
        let nextId =
            this.locationFeatures?.reduce(
                (acc, f) => (f.id > acc ? f.id : acc),
                0
            ) + 1;
        if (
            this.locationFeatures.length === 0 ||
            this.locationFeatures.filter(
                fg =>
                    fg?.categories?.findIndex(
                        c => c.id === locationFeature.type
                    ) !== -1
            ).length === 0
        ) {
            this.locationFeatures = this.locationFeatures.concat({
                id: nextId,
                name: locationFeature.name,
                type: 'Polygon',
                canAdd: false,
                categories: [
                    {
                        id: locationFeature.type,
                        name: locationFeature.name
                    }
                ],
                existingFeatures:
                    locationFeature != null
                        ? [toMapFeature(locationFeature, nextId++)]
                        : []
            });
        } else {
            this.locationFeatures = this.locationFeatures.map(fg =>
                fg.categories.findIndex(c => c.id === locationFeature.type) !==
                -1
                    ? {
                          ...fg,
                          existingFeatures: fg.existingFeatures.concat(
                              toMapFeature(locationFeature, nextId++)
                          )
                      }
                    : fg
            );
        }
    }

    refreshAirspaceApproval(airspaceCheck: AirspaceCheckDto) {
        if (
            this.mission?.airspaceAuthorisationList != null &&
            this.mission?.airspaceAuthorisationList.length > 0 &&
            this.mission?.id != null
        ) {
            this.airspaceAuthorisationManager.findAuthorisationsByMissionId(
                this.mission.id
            );
            // FIXME: this needs to be either hard-coded better, or done programatically
            this.gcdVersion =
                airspaceCheck.airspaceDatasetList?.find(
                    s => s.identifier === 'AUS_GCD'
                )?.version ?? null;

            this.updateMapFilters(airspaceCheck);
        }

        this.canRequestAutomaticApproval =
            airspaceCheck.automatedAuthorisationAvailable;

        this.updateMapFilters(airspaceCheck);

        this.updateIsMissionApprovable();
    }

    updateMapFilters(airspaceCheck: AirspaceCheckDto) {
        if (airspaceCheck != null) {
            this.mapSourceFilters = mapSourceFiltersForResponse(airspaceCheck);
            const missionApprovalGcdVersion = this.gcdVersion;
            if (
                missionApprovalGcdVersion != null &&
                this.mapSourceFilters.AUS_GCD
            ) {
                this.mapSourceFilters.AUS_GCD = missionApprovalGcdVersion;
            }
            this.enabledMapLayerGroups = Object.keys(this.mapSourceFilters);
        }
    }

    cancelAirspaceAuthorisation(authorisation: AirspaceAuthorisationDto) {
        this.angulartics2.eventTrack.next({
            action: 'cancel-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        this.commonDialoguesService
            .showConfirmationDialogue(
                'Mission Airspace Authorisation',
                'Do you wish to cancel this airspace authorisation request?',
                'Yes',
                () =>
                    this.airspaceAuthorisationManager
                        .cancelAuthorisation(authorisation.id)
                        .toPromise()
            )
            .then(
                results => {
                    this.approvedAirspaceAuthorisations = null;
                    this.refreshMission();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while cancelling authorisation: ${error.message}`
                    )
            );
    }

    closeAirspaceAuthorisation(authorisation: AirspaceAuthorisationDto) {
        this.angulartics2.eventTrack.next({
            action: 'close-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        this.commonDialoguesService
            .showConfirmationDialogue(
                'Mission Airspace Authorisation',
                'Do you wish to close this airspace authorisation request?',
                'Yes',
                () =>
                    this.airspaceAuthorisationService
                        .closeAuthorisation(authorisation.id)
                        .toPromise()
            )
            .then(
                results => {
                    this.approvedAirspaceAuthorisations = null;
                    this.refreshMission();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while closing authorisation: ${error.message}`
                    )
            );
    }

    private runAirspaceCheck() {
        if (this.mission == null || this.rpaTypes == null) {
            return;
        }
        const location = this.mission.location;
        if (location == null) {
            return;
        }

        const locationPolygon = findLocationFlightAreaPolygon(
            location.features
        );

        if (locationPolygon == null) {
            return;
        }

        const jurisdiction =
            this.mission.missionWorkflowVersion.delegatedAuthority.authorityType
                ?.jurisdiction?.identifier;
        const ruleset =
            this.mission.missionWorkflowVersion.delegatedAuthority.authorityType
                ?.ruleset?.identifier;

        const startTime = this.mission.missionDate;
        const endTime = moment(startTime)
            .add(this.mission.missionEstimatedTime, 'seconds')
            .toISOString();

        this.airspaceCheckCommand = {
            startTime: startTime,
            endTime: endTime,
            jurisdiction: jurisdiction,
            location: locationPolygon,
            rpaList: this.mission.crafts
                .filter(
                    r =>
                        r.rpaTypeId != null &&
                        this.rpaTypes[r.rpaTypeId]?.performanceSpecifications
                            ?.maxTakeOffWeight != null
                )
                .map(r => ({
                    id: r.id,
                    mtow: this.rpaTypes[r.rpaTypeId].performanceSpecifications
                        .maxTakeOffWeight,
                    serialNumber: r.manufacturerSerialNumber
                })),
            ruleset: ruleset,
            nearestFeatureSearch: false,
            nearestFeatureSearchRadiusMeters: 400,
            maximumHeight: this.mission.maximumHeight ?? DEFAULT_MAX_HEIGHT
        };
        this.airspaceCheckService.startSingleCheck(
            this.airspaceCheckCommand,
            this.mission.missionWorkflowVersionId,
            this.mission.location?.airspaceJurisdiction,
            this.mission.location
        );
    }

    showAirspaceAuthorisation() {
        this.angulartics2.eventTrack.next({
            action:
                this.approvedAirspaceAuthorisations == null
                    ? 'show-request-authorisation'
                    : 'view-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        this.showAirspaceAuthorisationModal();
    }

    requestNewAirspaceAuthorisation() {
        this.angulartics2.eventTrack.next({
            action: 'show-request-new-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        this.showAirspaceAuthorisationModal(true);
    }

    showAirspaceAuthorisationModal(requestNew: boolean = false) {
        this.showCrpOptIn = false;
        const modal =
            this.airspaceDialoguesService.showAirspaceAuthorisationDialogue(
                this.airspaceAuthorisationManager,
                this.organisation,
                this.airspaceCheckCommand,
                this.mission,
                this.approval,
                null,
                this.rpaTypes,
                requestNew
            );
        modal.onHidden
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMission());
    }

    private refreshDocumentation() {
        if (!this.mission.id) {
            return;
        }
        const doneWorking = this.workTracker.createTracker();
        this.missionService
            .findDocumentation(this.mission.id)
            .toPromise()
            .then(documentation => {
                this.setupDocumentation(documentation);
                doneWorking();
            });
    }

    private refreshBatterySets() {
        const doneWorking = this.workTracker.createTracker();
        Promise.all(
            this.mission.sorties
                .map((s: any) => s.batterySetId)
                .filter((bsid: number) => bsid !== null)
                .filter(unique)
                .map((bsid: number) =>
                    this.batterySetService
                        .findBatterySet(bsid, this.mission.organisationId)
                        .toPromise()
                )
        ).then((batterySets: any) => {
            this.batterySetLookup = batterySets.reduce(toLookup, {});
            doneWorking();
        });
    }

    private setupDocumentationActions() {
        this.documentationActions = {
            getFormTemplatePrintUrl: (checklistId, step) =>
                this.missionService.getFormTemplatePrintUrl(
                    this.mission.id,
                    checklistId,
                    step,
                    this.mission.organisationId
                ),
            getFormResponsePrintUrl: (formId, step) =>
                this.missionService.getFormResponsePrintUrl(
                    this.missionId,
                    formId,
                    step,
                    this.mission.organisationId
                ),
            storeFormResponse: (
                formId: number,
                step: string,
                response: FormResponseCommand
            ) =>
                this.missionService
                    .updateDocumentation(this.mission.id, {
                        [step]: [response]
                    })
                    .toPromise()
                    .then(documentation =>
                        this.setupDocumentation(documentation)
                    )
        };
    }

    private setupDocumentation(documentation: MissionDocumentationDto) {
        this.documentation = buildDisplayableMissionDocumentation(
            documentation,
            [this.approval],
            this.mission.crafts,
            this.formatRpaPipe.transform
        );

        this.requiringEntities = documentation.missionRequiringEntities.concat(
            approvalRequiringEntities(this.approval)
        );

        this.approvalRequiredDocumentation = Object.keys(
            documentation.missionApprovalRequiredDocumentation
        ).reduce(
            (acc, approvalId) => ({
                ...acc,
                [approvalId]: buildMissionRequiredDocumentation(
                    documentation.missionApprovalRequiredDocumentation[
                        approvalId
                    ]
                )
            }),
            {}
        );
    }

    showPilot(pilot: PersonDto) {
        this.personService
            .findPerson(pilot.id, this.mission.organisationId)
            .subscribe({
                next: person =>
                    this.personnelDialoguesService.showPersonDetailsDialogue(
                        person,
                        this.mission.organisationId,
                        false
                    ),
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error retrieving details for ${pilot.firstName} ${pilot.lastName}: ${error.message}`
                    )
            });
    }

    showSortieDocumentation(flight: SortieDto) {
        if (flight.id == null) {
            const formResponses = combineFormResponses([{}]);

            this.missionDialoguesService.showFlightDocumentationDialogue(
                this.mission,
                flight,
                this.documentation.requiredDocumentation,
                formResponses
            );
        } else {
            this.missionService
                .findSortieDocumentation(this.mission.id, flight.id)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(documentation => {
                    const formResponses = combineFormResponses([
                        documentation ? documentation.formResponses : {}
                    ]);

                    this.missionDialoguesService.showFlightDocumentationDialogue(
                        this.mission,
                        flight,
                        this.documentation.requiredDocumentation,
                        formResponses
                    );
                })
                .add(this.workTracker.createTracker());
        }
    }

    showCraft(craft: CraftDto) {
        this.rpaDialoguesService.showCraftDetails(
            craft.id,
            this.mission?.organisationId ?? this.organisation.id,
            false
        );
    }

    showEquipment(equipment: SimpleResourceDetails) {
        this.equipmentDialoguesService.showEquipmentDetails(
            equipment.id,
            this.organisation as PersonsOrganisationDto,
            false
        );
    }

    deleteCondition(ix: number) {
        this.conditions.removeAt(ix);
    }

    addCondition() {
        this.conditions.push(this.createCondition(''));
        // Set the focus on the last one
        // setTimeout(
        //     () => {
        //         const inputs = this.approvalConditionsTable.nativeElement.find('input');
        //         if (inputs.length > 0) {
        //             inputs[inputs.length - 1].focus();
        //         }
        //     }, 0);
    }

    addConditionsFromLibrary() {
        this.selectedAddConditions = true;

        const modal = this.modalService.show(ApplyApprovalConditions, {
            ...MODAL_OPTIONS,
            class: 'modal-task',
            initialState: {
                authorityOrganisationId:
                    this.mission.missionWorkflowVersion.delegatedAuthority
                        .organisation.id
            }
        });

        modal.content.cancel
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(modal.onHide))
            .subscribe({
                next: () => {
                    modal.hide();
                    this.selectedAddConditions = false;
                },
                error: DO_NOTHING
            });

        modal.content.appliedConditions
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(modal.onHide))
            .subscribe({
                next: condition => {
                    this.conditionsSelected(condition);
                    modal.hide();
                },
                error: DO_NOTHING
            });
    }

    conditionsSelected(conditions: string[]) {
        conditions.forEach(condition =>
            this.conditions.push(this.createCondition(condition))
        );
        this.selectedAddConditions = false;
        this.logging.success(`Added ${conditions.length} conditions`);
    }

    startProgress() {
        this.missionApprovalService
            .startReviewing(this.approval.id)
            .then(
                (results: any) => {
                    this.status.isPending = false;
                    this.status.isBeingReviewed = true;
                    this.logging.info('The mission progress is now started!');
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while starting mission progress: ${error.message}`
                    )
            )
            .finally(this.workTracker.createTracker());
    }

    stopProgress() {
        this.missionApprovalService
            .stopReviewing(this.approval.id)
            .then(
                (results: any) => {
                    this.status.isPending = true;
                    this.status.isBeingReviewed = false;
                    this.logging.info('The mission progress is now stopped!');
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error stopping mission progress: ${error.message}`
                    );
                }
            )
            .finally(this.workTracker.createTracker());
    }

    // reloadMissionPage() {
    //     this.missionDialoguesService.showMissionDetailsThroughState(
    //         this.missionId
    //     );
    // }

    reject() {
        const values = this.form.value;

        const update: RejectMissionApprovalCommand = {
            approversNotes: values.approversNotes,
            message: values.messageFromApprover
        };

        this.commonDialoguesService
            .showConfirmationDialogue(
                'Mission Approval',
                'Do you wish to reject this mission',
                'Reject',
                () =>
                    this.missionApprovalService.rejectApproval(
                        this.approval.id,
                        update
                    )
            )
            .then(
                results => {
                    this.approvalChanged.emit();
                    this.modal.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while rejecting approval: ${error.message}`
                    )
            )
            .finally(this.workTracker.createTracker());
    }

    approve() {
        const values = this.form.value;

        const update: MissionApprovalUpdateCommand = {
            completedChecks: this.approval.completedChecks,
            conditions: values.conditions,
            approversNotes: values.approversNotes,
            messageFromApprover: values.messageFromApprover,
            maximumHeight: values.maximumHeight
        };

        this.commonDialoguesService
            .showConfirmationDialogue(
                'Mission Approval',
                'Do you wish to approve this mission',
                'Approve',
                () =>
                    this.missionApprovalService.approveApproval(
                        this.approval.id,
                        update
                    )
            )
            .then(
                results => {
                    this.form.markAsPristine();
                    this.isApprovalChecklistChanged = false;
                    this.approvalChanged.emit();
                    this.modal.hide();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while submitting approval: ${error.message}`
                    );
                }
            )
            .finally(this.workTracker.createTracker());
    }

    revoke() {
        const update = {
            approversNotes: this.approval.approversNotes
                ? this.approval.approversNotes
                : '',
            messageFromApprover: this.approval.messageFromApprover
                ? this.approval.messageFromApprover
                : ''
        };

        this.commonDialoguesService
            .showConfirmationDialogue(
                'Revoke Mission Approval',
                `Do you wish to revoke the approval for this mission?`,
                'Revoke',
                () =>
                    this.missionApprovalService.revokeApproval(
                        this.approval.id,
                        update
                    )
            )
            .then(
                results => {
                    this.approvalChanged.emit();
                    this.modal.hide();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while revoking approval: ${error.message}`
                    );
                }
            )
            .finally(this.workTracker.createTracker());
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    saveAndClose() {
        this.save().then(() => this.modal.hide());
    }

    close() {
        this.modal.hide();
    }

    save() {
        const doneWorking = this.workTracker.createTracker();

        const values = this.form.value;

        const update: MissionApprovalUpdateCommand = {
            completedChecks: this.approval.completedChecks,
            conditions: values.conditions,
            approversNotes: values.approversNotes,
            messageFromApprover: values.messageFromApprover,
            maximumHeight: values.maximumHeight
        };

        return this.missionApprovalService
            .updateApproval(this.approval.id, update)
            .toPromise()
            .then(
                results => {
                    this.approval = results;
                    this.form.markAsPristine();
                    this.isApprovalChecklistChanged = false;
                    this.approvalChanged.emit();
                    this.logging.success('Approval saved');
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while saving mission approval: ${error.message}`
                    );
                    doneWorking();
                    return Promise.reject(error);
                }
            );
    }

    private refreshAvailableReports() {
        if (this.mission == null) {
            this.printOptions = [];
            return;
        }

        this.missionReportService
            .findAvailableTemplates(
                'MissionReport',
                this.mission.id,
                this.mission?.organisationId ?? this.organisation.id
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(reports => {
                this.printOptions = reports.map(r => ({
                    description: r.description ? r.description : '',
                    name: r.name,
                    print: () => {
                        const doneWorking = this.workTracker.createTracker();
                        this.downloadService
                            .downloadFromUrl(
                                this.missionReportService.getPdfReportUrl(
                                    r.reportTemplateId,
                                    this.mission.id,
                                    this.mission?.organisationId ??
                                        this.organisation.id
                                )
                            )
                            .catch(() => doneWorking())
                            .then(() => doneWorking());
                    }
                }));
            });
    }

    onFullscreenRequested() {
        this.fullScreenService.toggleFullScreen(this.map);
    }

    get formConditions() {
        return this.form.get('conditions') as FormArray;
    }
}
