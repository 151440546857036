import { Injectable } from '@angular/core';
import { AddressDto } from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddressEditDialogue } from './address-edit/address-edit-dialogue.component';

@Injectable()
export class AddressDialoguesService {
    constructor(private modalService: BsModalService) {}

    showAddressEdit(
        organisationId: number,
        address: AddressDto,
        title: string,
        hiddenFields: string[] = [],
        modalClass = 'modal-md'
    ) {
        return this.modalService.show(AddressEditDialogue, {
            ...MODAL_OPTIONS,
            class: modalClass,
            initialState: {
                organisationId,
                address: { ...address },
                title,
                hiddenFields
            }
        });
    }
}
