import {
    Component,
    Input,
    OnInit,
    forwardRef,
    OnDestroy,
    EventEmitter,
    Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { ContactDto, DO_NOTHING } from '@flyfreely-portal-ui/flyfreely';

/**
 * A view component that shows the contents of the FormControl.
 */
@Component({
    selector: 'contact-pill',
    templateUrl: './contact-pill.component.html',
    styleUrls: ['./contact-pill.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactPillView),
            multi: true
        }
    ]
})
export class ContactPillView implements ControlValueAccessor {
    @Input() disabled: boolean;
    @Input() clickable: boolean;
    @Output() removeItem = new EventEmitter<void>();

    onChange: () => void = DO_NOTHING;
    onTouched: () => void = DO_NOTHING;

    data: ContactDto;

    private ngUnsubscribe$ = new Subject<void>();

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    writeValue(data: ContactDto): void {
        this.data = data;
    }

    registerOnChange(fn: () => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }
}
