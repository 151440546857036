import { Injectable } from '@angular/core';
import {
    AdminReferralService,
    downloadCsv,
    FlyFreelyError,
    FlyFreelyLoggingService,
    ReferralReport,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FormatBooleanPipe } from 'libs/ui/src/lib/pipes/generic/formatBoolean';
import * as moment from 'moment-timezone';

function formatDateTime(dateTime: string) {
    if (dateTime === null) {
        return '';
    }
    return moment(dateTime).format('DD/MM/YYYY HH:mm');
}

const fields: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            // First are the values in the highest level of the parent ReferralReport object
            {
                key: 'referralId',
                props: {
                    label: 'ID'
                }
            },
            {
                key: 'firstVisit',
                props: {
                    label: 'First Visit'
                }
            },
            {
                key: 'referralUrl',
                props: {
                    label: 'Referral URL'
                }
            },
            {
                key: 'landingUrl',
                props: {
                    label: 'Landing URL'
                }
            },
            {
                key: 'referralSignupUrl',
                props: {
                    label: 'Signup URL'
                }
            },
            // Next are the person details
            {
                key: 'firstName',
                props: {
                    label: 'First Name'
                }
            },
            {
                key: 'lastName',
                props: {
                    label: 'Last Name'
                }
            },
            {
                key: 'active',
                props: {
                    label: 'Active?'
                }
            },
            {
                key: 'email',
                props: {
                    label: 'Email'
                }
            },
            {
                key: 'phoneNumber',
                props: {
                    label: 'Phone Number'
                }
            },
            {
                key: 'signupTime',
                props: {
                    label: 'Signup Date'
                }
            },
            {
                key: 'onboardingCompleted',
                props: {
                    label: 'Onboarding Completed'
                }
            },
            {
                key: 'lastLoggedIn',
                props: {
                    label: 'Last Logged In'
                }
            },
            {
                key: 'firstMissionReady',
                props: {
                    label: 'First Mission Ready'
                }
            },
            {
                key: 'firstMissionCompleted',
                props: {
                    label: 'First Mission Completed'
                }
            },
            // Next are the organisation details (referralReport.organisations)
            {
                key: 'organisationId',
                props: {
                    label: 'Referring Organisation ID'
                }
            },
            {
                key: 'organisationName',
                props: {
                    label: 'Organisation Name'
                }
            },
            {
                key: 'organisationPersonal',
                props: {
                    label: 'Personal Organisation?'
                }
            },
            {
                key: 'organisationCreationTime',
                props: {
                    label: 'Organisation Creation Time'
                }
            },
            // Finally, the Referral Source data:
            {
                key: 'id',
                props: {
                    label: 'Referral Source ID'
                }
            },
            {
                key: 'name',
                props: {
                    label: 'Referral Source Name'
                }
            },
            {
                key: 'queryString',
                props: {
                    label: 'Referral Source Query String'
                }
            },
            {
                key: 'signupUrl',
                props: {
                    label: 'Referral Source Signup URL'
                }
            },
            {
                key: 'type',
                props: {
                    label: 'Referral Source Type'
                }
            },
            {
                key: 'promoterName',
                props: {
                    label: 'Referral Source Promoter Name'
                }
            }
        ]
    },
    props: {
        label: 'Referrals'
    }
};

@Injectable({
    providedIn: 'root'
})
export class ReferralReportService {
    constructor(
        private commonDialoguesService: CommonDialoguesService,
        private adminReferralService: AdminReferralService,
        private logging: FlyFreelyLoggingService,
        private formatBoolean: FormatBooleanPipe
    ) {}

    showReportDialogue() {
        this.commonDialoguesService.showFormlyDialogue(
            'Please Select a Date Range',
            'Download Referrals',
            true,
            true,
            [
                {
                    key: 'startDate',
                    type: 'date',
                    props: { label: 'Creation Date Start' }
                },
                {
                    key: 'endDate',
                    type: 'date',
                    props: { label: 'Creation Date End' }
                }
            ],
            {
                startDate: null as Date,
                endDate: null as Date
            },
            data =>
                this.adminReferralService
                    .findReferralReportsByDateRange(
                        toTimestamp(data.endDate),
                        toTimestamp(data.startDate)
                    )
                    .toPromise()
                    .then(
                        reports => {
                            this.generateReportCsv(reports);
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while retrieving reports: ${error.message}`
                            );
                        }
                    )
        );
    }

    generateReportCsv(reportsForPrinting: ReferralReport[]) {
        if (reportsForPrinting.length === 0) {
            this.logging.error(null, 'No reports for the given date-range.');
            return;
        }
        const mapped = reportsForPrinting
            .map(referral =>
                referral.organisations.map(org => ({
                    ...referral.person,
                    active: this.formatBoolean.transform(
                        referral.person.active
                    ),
                    signupTime: formatDateTime(referral.person.signupTime),
                    lastLoggedIn: formatDateTime(referral.person.lastLoggedIn),
                    onboardingCompleted: this.formatBoolean.transform(
                        referral.person.onboardingCompleted
                    ),
                    firstMissionReady: formatDateTime(
                        referral.person.firstMissionReady
                    ),
                    firstMissionCompleted: formatDateTime(
                        referral.person.firstMissionCompleted
                    ),

                    id: referral.referralSource
                        ? referral.referralSource.id
                        : '',
                    name: referral.referralSource
                        ? referral.referralSource.name
                        : '',
                    queryString: referral.referralSource
                        ? referral.referralSource.queryString
                        : '',
                    signupUrl: referral.referralSource
                        ? referral.referralSource.signupUrl
                        : '',
                    type: referral.referralSource
                        ? referral.referralSource.type.toString()
                        : '',

                    promoterName: referral.referralSource
                        ? referral.referralSource.promoter
                            ? referral.referralSource.promoter.name
                            : ''
                        : '',

                    firstVisit: formatDateTime(referral.firstVisit),
                    referralUrl: referral.referralUrl,
                    landingUrl: referral.landingUrl,
                    referralSignupUrl: referral.signupUrl,
                    referralId: referral.id,
                    organisationId: org.id,
                    organisationName: org.name,
                    organisationPersonal: this.formatBoolean.transform(
                        org.personalOrganisation
                    ),
                    organisationCreationTime: formatDateTime(org.creationTime)
                }))
            )
            .reduce((acc, referralOrgs) => acc.concat(referralOrgs), []);

        downloadCsv(fields, mapped);
    }
}
