<div class="horizontal-container">
    <div class="pill-container" [ngClass]="{ disabled: disabled }">
        <div class="info-pill" [ngClass]="{ clickable: clickable }">
            <p *ngIf="data.name !== ''" class="pill-label">
                {{ data.name }} {{ data.identifier }}
            </p>
            <p *ngIf="data.value !== ''" class="pill-value">
                <span
                    *ngIf="data.subtype !== '' && data.subtype != null"
                    class="pill-label"
                >
                    {{ data.subtype }}:
                </span>
                {{ data.value }}
            </p>
        </div>
        <button
            type="button"
            class="btn btn-tertiary btn-delete right-buffer"
            tooltip="Delete"
            placement="left"
            [disabled]="disabled"
            (click)="removeItem.emit()"
        >
            <span class="fal fa-trash-alt"></span>
        </button>
    </div>
</div>
