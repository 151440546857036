/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.7-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimplePersonDto } from './simplePersonDto';


/**
 * The ID for correlation with the type column is id+conditionType+entity+entityId
 */
export interface RegisterEntryVerificationActivityDto { 
    /**
     * The ID of the check that this verification has performed. Null if not a stored check.
     */
    authorityConditionId?: number;
    /**
     * The type of check this is
     */
    conditionType: RegisterEntryVerificationActivityDto.ConditionType;
    entity?: string;
    entityId?: number;
    expiryDate?: string;
    /**
     * Notes about the verification
     */
    notes?: string;
    status?: RegisterEntryVerificationActivityDto.Status;
    updatedAt?: string;
    performedBy?: SimplePersonDto;
    /**
     * The ID of the register entry that this verification applies to. Note, this is not the person/RPA, this is their register entry.
     */
    registerEntryId?: number;
    /**
     * The ID of the entity that verifies the check has passed, e.g., the manual verification object, the authority, etc.
     */
    verificationEntityId?: number;
}
export namespace RegisterEntryVerificationActivityDto {
    export type ConditionType = 'ACTION' | 'INTERNAL_LOOKUP' | 'ATTACHMENT_ACKNOWLEDGEMENT' | 'CURRENCY';
    export const ConditionType = {
        ACTION: 'ACTION' as ConditionType,
        INTERNAL_LOOKUP: 'INTERNAL_LOOKUP' as ConditionType,
        ATTACHMENT_ACKNOWLEDGEMENT: 'ATTACHMENT_ACKNOWLEDGEMENT' as ConditionType,
        CURRENCY: 'CURRENCY' as ConditionType
    };
    export type Status = 'PASSED' | 'PENDING' | 'PARTIAL';
    export const Status = {
        PASSED: 'PASSED' as Status,
        PENDING: 'PENDING' as Status,
        PARTIAL: 'PARTIAL' as Status
    };
}



