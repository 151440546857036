import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    AuthorityWorkflowApproverCheck,
    AuthorityWorkflowVersionDto,
    FlyFreelyLoggingService,
    FormService,
    FormSummaryDto,
    MissionApprovalService,
    MissionWorkflowDto,
    MissionWorkflowVersionDto,
    NameId,
    OrganisationAuthorityService,
    OrganisationService,
    RpaTypeWorkflowDto,
    SimpleAuthorityDto,
    StepDescription,
    WorkTracker,
    WorkflowAttachmentRequirement,
    WorkflowHandler,
    WorkflowMappedEntityDetails,
    nextVersion,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { FormatAuthorityPipe } from '@flyfreely-portal-ui/ui';
import { toSimpleAuthority } from 'libs/authorities/src/lib/helpers';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    shareReplay,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { cleanSteps } from '../helpers';

interface AuthorityWorkflowPair {
    authorityId: number;
    workflow: AuthorityWorkflowVersionDto;
}

export interface AuthorityDocumentationForms {
    mappedEntity: WorkflowMappedEntityDetails;
    from: string;
}

function cloneMappedApproverChecks(existing: {
    [step: string]: AuthorityWorkflowApproverCheck[];
}): { [step: string]: AuthorityWorkflowApproverCheck[] } {
    if (existing == null) {
        return null;
    }

    return Object.keys(existing).reduce(
        (acc, step) => ({
            ...acc,
            [step]: existing[step].map(c => ({
                check: c.check,
                description: c.description
            }))
        }),
        {}
    );
}

@Component({
    selector: 'workflow-edit-dialogue',
    templateUrl: './workflow-edit-dialogue.component.html',
    styles: [
        `
            .flex-version-migrate {
                display: flex;
            }

            .select-version {
                width: 100%;
            }

            .action-buttons {
                display: flex;
                align-items: center;
            }
        `
    ]
})
export class WorkflowEditDialogue implements OnInit, OnDestroy {
    @Input() steps: StepDescription[];
    @Input() workflowHandler: WorkflowHandler;
    @Input() workflow: MissionWorkflowDto;
    @Input() hasDelegatedAuthority: boolean;
    @Input() hasName: boolean;
    @Input() hasApproverChecks: boolean;

    currentWorkflow: MissionWorkflowDto | RpaTypeWorkflowDto;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    private forms$ = new ReplaySubject<FormSummaryDto[]>(1);
    private inUseFormId$ = new ReplaySubject<number[]>(1);
    availableForms$ = combineLatest([this.forms$, this.inUseFormId$]).pipe(
        map(([forms, inUseFormIds]) =>
            forms.filter(
                f =>
                    f.organisation?.id === this.organisationId ||
                    inUseFormIds.indexOf(f.id) !== -1
            )
        )
    );
    needMigration$ = this.availableForms$.pipe(
        map(f =>
            f.reduce(
                (needMigration, f) =>
                    needMigration || f.organisation?.id !== this.organisationId,
                false
            )
        )
    );
    private forms: FormSummaryDto[];

    needMigration: boolean;

    organisationId: number;
    isNewWorkflow: boolean;
    versions: MissionWorkflowVersionDto[];
    activeVersionId: number;
    selectedVersionId: number;
    currentVersion: {
        id: number;
        availableActions: any;
        mappedForms: { [step: string]: WorkflowMappedEntityDetails[] };
        mappedAttachmentRequirements: {
            [step: string]: WorkflowAttachmentRequirement[];
        };
        mappedApproverChecks: {
            [step: string]: AuthorityWorkflowApproverCheck[];
        };
        versionNumber: number;
        delegatedAuthorityId: number;
        additionalAuthorityIds: number[];
    };

    primaryAuthorities: NameId[];
    additionalAuthorities: NameId[];
    availableAdditionalAuthorities: NameId[];
    fullPrimaryAuthorities: SimpleAuthorityDto[] = [];

    availableAuthorityLookup: { [authorityId: number]: NameId };

    authorityWorkflowLookup: {
        [authorityId: number]: AuthorityWorkflowVersionDto;
    };
    authorityDocumentation: {
        [step: string]: { forms: AuthorityDocumentationForms[] };
    };
    newAdditionalAuthorityId: number;

    constructor(
        public modal: BsModalRef<WorkflowEditDialogue>,
        private formService: FormService,
        private missionApprovalService: MissionApprovalService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private formatAuthorityPipe: FormatAuthorityPipe,
        private organisationService: OrganisationService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.forms$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(forms => (this.forms = forms));

        this.setupWorkflow(this.workflow);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.forms$.complete();
        this.inUseFormId$.complete();
    }

    filterSteps() {
        return this.steps.filter(
            step => step.editable === false || step.editable === true
        );
    }

    private setupWorkflow(workflow: MissionWorkflowDto) {
        this.currentWorkflow = workflow;
        this.organisationId = this.currentWorkflow.organisationId;

        if (this.currentWorkflow && this.currentWorkflow.id) {
            this.isNewWorkflow = false;
            this.versions = this.currentWorkflow.versions;
            this.activeVersionId = this.currentWorkflow.activeVersionId;
            if (!this.selectedVersionId) {
                this.selectedVersionId = this.currentWorkflow.activeVersionId
                    ? this.currentWorkflow.activeVersionId
                    : null;
            }
        } else {
            this.isNewWorkflow = true;
            this.selectedVersionId = null;
        }

        this.refreshForms();
        this.refreshAuthorities();

        this.changeSelectedVersion(this.selectedVersionId);
    }

    refreshForms() {
        this.formService
            .findForms(this.organisationId)
            .subscribe(forms => {
                this.forms$.next(forms);
            })
            .add(this.workTracker.createTracker());
    }

    refreshAuthorities() {
        const today = moment().format('YYYY-MM-DD');
        const primaryAuthoritySetup = this.missionApprovalService
            .findApprovingAuthorities(this.organisationId, 0, today)
            .pipe(
                tap(authorities => (this.fullPrimaryAuthorities = authorities)),
                map(authorities =>
                    authorities
                        .filter(a => a.authorityType.isPrimary)
                        .map(e => ({
                            id: e.id,
                            name: this.formatAuthorityPipe.transform(e)
                        }))
                ),
                tap(authorities => (this.primaryAuthorities = authorities))
            );

        const additionalAuthoritySetup = combineLatest([
            this.organisationService.findById(
                this.organisationId,
                this.organisationId
            ),
            this.organisationAuthorityService.findAuthorities(
                this.organisationId,
                this.organisationId
            ),
            this.organisationAuthorityService.findSharedAuthorities(
                this.organisationId,
                today
            )
        ]).pipe(
            map(([organisation, authorities, sharedAuthorityGroups]) => {
                const additionalAuthorities = authorities
                    .filter(group => !group.isPrimary && group.hasWorkflow)
                    .map(group =>
                        group.authorities.map(auth => ({
                            id: auth.id,
                            name: this.formatAuthorityPipe.transform(
                                toSimpleAuthority(auth, organisation, group)
                            )
                        }))
                    )
                    .reduce((acc, auths) => [...acc, ...auths], []);

                const sharedAuthorities = sharedAuthorityGroups
                    .filter(group => !group.isPrimary && group.hasWorkflow)
                    .map(group =>
                        group.authorities.map(auth => ({
                            id: auth.id,
                            name: this.formatAuthorityPipe.transform(
                                toSimpleAuthority(
                                    auth,
                                    auth.organisation,
                                    group
                                )
                            )
                        }))
                    )
                    .reduce((acc, auths) => [...acc, ...auths], []);

                this.additionalAuthorities = [
                    ...additionalAuthorities,
                    ...sharedAuthorities
                ];

                return this.additionalAuthorities;
            })
        );

        const combinedAuthorities = combineLatest([
            primaryAuthoritySetup,
            additionalAuthoritySetup
        ]).pipe(takeUntil(this.ngUnsubscribe$), take(1), shareReplay());

        combinedAuthorities.subscribe(
            ([primaryAuthorities, additionalAuthorities]) =>
                (this.availableAuthorityLookup = [
                    ...primaryAuthorities,
                    ...additionalAuthorities
                ].reduce(toLookup, {}))
        );

        combinedAuthorities
            .pipe(
                mergeMap(([primaryAuthorities, additionalAuthorities]) =>
                    combineLatest(
                        [...primaryAuthorities, ...additionalAuthorities].map(
                            a =>
                                this.organisationAuthorityService
                                    .findActiveVersion(
                                        a.id,
                                        this.organisationId
                                    )
                                    .pipe(
                                        map(
                                            workflow =>
                                                ({
                                                    authorityId: a.id,
                                                    workflow
                                                } as AuthorityWorkflowPair)
                                        ),
                                        catchError(() =>
                                            of(null as AuthorityWorkflowPair)
                                        ),
                                        take(1)
                                    )
                        )
                    )
                )
            )
            .subscribe(authorityWorkflows => {
                this.authorityWorkflowLookup = authorityWorkflows
                    .filter(w => w != null)
                    .reduce(
                        (acc, w, ix) => ({
                            ...acc,
                            [w.authorityId]: w.workflow
                        }),
                        {} as {
                            [authorityId: number]: AuthorityWorkflowVersionDto;
                        }
                    );
                this.refreshAuthorityDocumentation();
                this.refreshAvailableAdditionalAuthorities();
            })
            .add(this.workTracker.createTracker());
    }

    refreshAuthorityDocumentation() {
        this.authorityDocumentation = this.steps.reduce(
            (acc, step) => ({
                ...acc,
                [step.value]: {
                    forms: this.getAuthorityForms(step)
                }
            }),
            {}
        );
    }

    getAuthorityForms(step: StepDescription) {
        if (this.availableAuthorityLookup == null) {
            return [];
        }

        const names: AuthorityDocumentationForms[] = [];

        const delegatedAuthorityIds =
            this.currentVersion.delegatedAuthorityId != null
                ? [this.currentVersion.delegatedAuthorityId]
                : [];
        const additionalAuthorityIds =
            this.currentVersion.additionalAuthorityIds != null
                ? this.currentVersion.additionalAuthorityIds
                : [];
        const authorityIds = delegatedAuthorityIds.concat(
            additionalAuthorityIds
        );
        for (const additionalAuthorityId of authorityIds) {
            const additionalAuthority =
                this.authorityWorkflowLookup[additionalAuthorityId];
            if (
                additionalAuthority &&
                additionalAuthority.mappedForms[step.value] &&
                this.availableAuthorityLookup[additionalAuthorityId]
            ) {
                additionalAuthority.mappedForms[step.value].forEach(f => {
                    names.push({
                        mappedEntity: f,
                        from: this.availableAuthorityLookup[
                            additionalAuthorityId
                        ].name
                    });
                });
            }
        }

        return names;
    }

    renameForm(name: string) {
        const doneWorking = this.workTracker.createTracker();
        this.workflowHandler
            .renameWorkflow(this.currentWorkflow.id, { name })
            .then(savedWorkflow => {
                this.currentWorkflow.name = name;
                doneWorking();
            });
    }

    get isWorkflowNameRequiredAndEmpty() {
        return (
            this.hasName &&
            (typeof this.currentWorkflow.name !== 'string' ||
                this.currentWorkflow.name === '')
        );
    }

    onChangeWorkflowName(workflowName: string) {
        if (typeof workflowName === 'string') {
            this.currentWorkflow.name = workflowName;
        }
    }

    changeSelectedVersion(selectedVersionId: any) {
        this.selectedVersionId = selectedVersionId;
        let foundVersion = false;
        let version: MissionWorkflowVersionDto;

        if (this.selectedVersionId) {
            foundVersion = true;
            version = this.versions.find(v => v.id === this.selectedVersionId);
        }

        if (foundVersion) {
            const inUseFormIds = Object.keys(version.mappedForms).reduce(
                (acc, step) =>
                    acc.concat(version.mappedForms[step].map(f => f.id)),
                []
            );
            this.inUseFormId$.next(inUseFormIds);

            this.currentVersion = {
                id: version.id,
                versionNumber: version.versionNumber,
                mappedForms: cleanSteps(this.steps, version.mappedForms),
                mappedAttachmentRequirements: cleanSteps(
                    this.steps,
                    version.mappedAttachmentRequirements,
                    false
                ),
                mappedApproverChecks: cleanSteps(
                    this.steps,
                    // @ts-ignore: these exist on authority workflows
                    version.mappedApproverChecks,
                    false
                ),
                delegatedAuthorityId: version.delegatedAuthorityId,
                additionalAuthorityIds: version.additionalAuthorityIds,
                availableActions: version.availableActions
            };
        } else {
            this.inUseFormId$.next([]);
            this.currentVersion = {
                id: null,
                mappedForms: cleanSteps(this.steps, {}),
                mappedAttachmentRequirements: cleanSteps(this.steps, {}, false),
                mappedApproverChecks: cleanSteps(this.steps, {}, false),
                availableActions: { canEdit: true },
                additionalAuthorityIds: [],
                delegatedAuthorityId: null,
                versionNumber: 1
            };
        }
        this.refreshAvailableAdditionalAuthorities();
    }

    cloneVersion() {
        this.selectedVersionId = null;
        this.currentVersion = {
            ...this.currentVersion,
            id: null,
            availableActions: { canEdit: true },
            versionNumber: nextVersion(this.currentWorkflow.versions),
            mappedApproverChecks: cloneMappedApproverChecks(
                this.currentVersion.mappedApproverChecks
            )
        };
    }

    migrateForms() {
        this.cloneVersion();

        for (const key in this.currentVersion?.mappedForms) {
            if (this.currentVersion?.mappedForms[key].length > 0) {
                this.currentVersion?.mappedForms[key].map((form, index) => {
                    if (this.isFormGlobal(form.id)) {
                        const formCloned = this.forms.find(
                            f => f.derivedFromFormId === form.id
                        );
                        if (formCloned) {
                            this.currentVersion.mappedForms[key][index] = {
                                ...form,
                                id: formCloned.id,
                                name: formCloned.name
                            };
                        } else {
                            const command = {
                                organisationId: this.organisationId,
                                formId: form.id
                            };
                            this.formService
                                .cloneForm(command)
                                .pipe(takeUntil(this.ngUnsubscribe$))
                                .subscribe(cForm => {
                                    this.currentVersion.mappedForms[key][
                                        index
                                    ] = {
                                        ...form,
                                        id: cForm.id,
                                        name: cForm.name
                                    };
                                });
                        }
                    }
                });
            }
        }

        this.save(true);
    }

    isFormGlobal(formId: number) {
        const form = this.forms.find(form => form?.id === formId);
        return !form?.organisation;
    }

    toggleActiveVersion() {
        if (!this.selectedVersionId) {
            return;
        }

        const workflowId = this.currentWorkflow.id;

        const workflowVersionId =
            this.selectedVersionId === this.activeVersionId
                ? null
                : this.selectedVersionId;

        this.workflowHandler
            .changeActiveVersion(workflowId, { workflowVersionId })
            .then(workflow => {
                this.logging.success('Active workflow version changed');
                this.currentWorkflow = workflow;
                this.activeVersionId = workflow.activeVersionId;
            });
    }

    removeAdditionalAuthority(additionalAuthorityId: number) {
        this.currentVersion.additionalAuthorityIds =
            this.currentVersion.additionalAuthorityIds.filter(
                eId => additionalAuthorityId !== eId
            );
        this.refreshAvailableAdditionalAuthorities();
        this.refreshAuthorityDocumentation();
    }

    addAdditionalAuthority() {
        this.currentVersion.additionalAuthorityIds =
            this.currentVersion.additionalAuthorityIds.concat(
                this.newAdditionalAuthorityId
            );
        this.newAdditionalAuthorityId = null;
        this.refreshAvailableAdditionalAuthorities();
        this.refreshAuthorityDocumentation();
    }

    authorityChanged(delegatedAuthority: NameId) {
        const delegatedAuthorityId = delegatedAuthority.id;
        this.currentVersion.delegatedAuthorityId = delegatedAuthorityId;
        this.refreshAuthorityDocumentation();
    }

    isValidAuthority(authorityId: number) {
        const auth = this.fullPrimaryAuthorities.find(
            a => a.id === authorityId
        );
        if (auth == null) {
            return false;
        }
        const valid =
            auth.expiryDate == null ||
            moment(auth.expiryDate).isAfter(moment());
        const notDiscontinued =
            auth.authorityType.discontinueDate == null ||
            moment(auth.authorityType.discontinueDate).isAfter(moment());
        return valid && notDiscontinued;
    }

    authorityStatus(authorityId: number) {
        const auth = this.fullPrimaryAuthorities.find(
            a => a.id === authorityId
        );
        if (auth == null) {
            return '';
        }
        if (
            auth.expiryDate != null &&
            moment(auth.expiryDate).isBefore(moment())
        ) {
            return ' (expired)';
        }
        if (
            auth.authorityType.discontinueDate != null &&
            moment(auth.authorityType.discontinueDate).isBefore(moment())
        ) {
            return ' (discontinued)';
        }
        return '';
    }

    private refreshAvailableAdditionalAuthorities() {
        if (
            this.additionalAuthorities == null ||
            this.currentVersion == null ||
            this.currentVersion.additionalAuthorityIds == null
        ) {
            this.availableAdditionalAuthorities = undefined;
            return;
        }
        this.availableAdditionalAuthorities = this.additionalAuthorities.filter(
            auth =>
                auth.id !== this.currentVersion.delegatedAuthorityId &&
                this.currentVersion.additionalAuthorityIds.indexOf(auth.id) ===
                    -1
        );
    }

    saveAndClose() {
        this.save().then(() => this.modal.hide());
    }

    save(froMigration = false) {
        const workflowId = this.currentWorkflow.id;

        const toSave = {
            delegatedAuthorityId: this.currentVersion.delegatedAuthorityId,
            additionalAuthorityIds: this.currentVersion.additionalAuthorityIds,
            mappedForms: cleanSteps(
                this.steps,
                this.currentVersion.mappedForms
            ),
            mappedAttachmentRequirements: cleanSteps(
                this.steps,
                this.currentVersion.mappedAttachmentRequirements,
                false
            ),
            mappedApproverChecks: cleanSteps(
                this.steps,
                this.currentVersion.mappedApproverChecks,
                false
            ),
            name: this.currentWorkflow.name,
            organisationId: this.organisationId,
            workflowVersionId: this.selectedVersionId
        };

        const doneWorking = this.workTracker.createTracker();
        if (this.isNewWorkflow) {
            return this.workflowHandler.createNewWorkflowVersion(toSave).then(
                (workflow: any) => {
                    this.currentWorkflow = workflow;
                    this.isNewWorkflow = false;
                    this.versions = workflow.versions;
                    this.selectedVersionId =
                        workflow.versions[workflow.versions.length - 1].id;
                    this.logging.success('Workflow created with success!');
                    this.setupWorkflow(workflow);
                    doneWorking();
                },
                error => doneWorking()
            );
        } else {
            return this.workflowHandler
                .updateWorkflowVersion(workflowId, toSave)
                .then(
                    (workflow: any) => {
                        this.versions = workflow.versions;
                        if (!this.selectedVersionId) {
                            this.selectedVersionId =
                                workflow.versions[
                                    workflow.versions.length - 1
                                ].id;

                            if (froMigration) {
                                this.logging.success(
                                    `Migrated And Version created`
                                );
                            } else {
                                this.logging.success('Version created');
                            }
                        } else {
                            let versionIndex = null;

                            this.versions.map((version: any, index: any) => {
                                if (version.id === this.selectedVersionId) {
                                    versionIndex = index + 1;
                                }
                            });

                            this.logging.success(
                                `Version #${versionIndex} updated`
                            );
                        }

                        this.setupWorkflow(workflow);
                        doneWorking();
                    },
                    error => doneWorking()
                );
        }
    }
}
