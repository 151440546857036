import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Optional,
    Output
} from '@angular/core';
import {
    EnhancedHelpDto,
    FlyFreelyError
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    switchMap,
    takeUntil
} from 'rxjs';
import { EnhancedHelpService } from './enhanced-help.service';

/**
 * This directive is added to a button, and will enable/disable the button depending
 * if there is an enhanced help object for the current screen and component.
 *
 * On click, the {@link EnhancedHelpDialogueComponent} will be displayed.
 */
@Directive({
    selector: 'button[showEnhancedHelp]'
})
export class ShowEnhancedHelpDirective {
    /**
     * The screen name to use to locate this enhanced help object.
     */
    @Input() screenName?: string;
    /**
     * The name of the enhanced help object to display.
     */
    @Input()
    set componentName(name: string) {
        this.componentName$.next(name);
    }

    @Input()
    set isDemoHelp(value: boolean) {
        this.isDemoHelp$.next(value);
    }

    @Output() modalClosed = new EventEmitter<void>();

    private componentName$ = new ReplaySubject<string>(1);
    private isDemoHelp$ = new BehaviorSubject<boolean>(false);

    @HostBinding('disabled') disabled: boolean;

    helpObject?: EnhancedHelpDto;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private enhancedHelpService: EnhancedHelpService,
        private angulartics: Angulartics2,
        @Optional() private tooltip: TooltipDirective
    ) {}

    ngOnInit() {
        this.disabled = true;

        if (this.screenName == null) {
            throw new Error(`screenName not specified and can't be inferred`);
        }

        this.componentName$
            .pipe(
                switchMap(componentName =>
                    this.enhancedHelpService.findByScreenAndComponent(
                        this.screenName,
                        componentName,
                        true
                    )
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: results => {
                    this.helpObject =
                        results != null &&
                        ((results.helpText != null &&
                            results.helpText.length > 0) ||
                            (results.supportVideoUrl != null &&
                                results.supportVideoUrl.length > 0))
                            ? results
                            : null;

                    this.disabled = this.helpObject == null;

                    if (this.disabled) {
                        this.modalClosed.emit();
                    }

                    if (this.tooltip != null) {
                        this.tooltip.tooltip = results.helpTitle;
                    }
                },
                error: (error: FlyFreelyError) => {}
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.componentName$.complete();
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.helpObject != null) {
            console.log(this.isDemoHelp$.getValue());
            const modalRef = this.enhancedHelpService.showHelpDialogue(
                this.helpObject,
                this.isDemoHelp$.getValue()
            );

            modalRef.onHidden.subscribe(() => {
                this.modalClosed.emit();
            });

            this.angulartics.eventTrack.next({
                action: 'show-enhanced-help',
                properties: {
                    category: 'help',
                    screen: this.helpObject.screen,
                    component: this.helpObject.component
                }
            });
        }
    }
}
