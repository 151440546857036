import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostBinding
} from '@angular/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { takeUntil, map } from 'rxjs/operators';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation,
    tadaAnimation
} from 'angular-animations';
import { Subject } from 'rxjs';
import { SetupGuideActivityDto } from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { observeImage } from '@flyfreely-portal-ui/flyfreely';
import {
    ActivityCardDto,
    SetupGuideChecklistService
} from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';

enum MenuButtonType {
    HAS_MENU = 'HAS_MENU',
    MENU_ACTIVE = 'MENU_ACTIVE',
    STATIC = 'STATIC',
    COMPLETED = 'COMPLETED'
}

@Component({
    selector: 'activity-card',
    templateUrl: './activity-card.component.html',
    styleUrls: ['../styles.scss'],
    animations: [
        collapseOnLeaveAnimation(),
        expandOnEnterAnimation(),
        tadaAnimation()
    ]
})
export class ActivityCardComponent {
    @Input() activity: ActivityCardDto;
    @Input() isAlertCard: boolean;
    @Output() cardViewed = new EventEmitter<void>();
    @Output() complete = new EventEmitter<void>();

    activityType = SetupGuideActivityDto.Type;
    MenuButtonType = MenuButtonType;
    menuState = MenuButtonType.HAS_MENU;
    autoCompleted = false;
    tada = false;
    callAttention = false;
    hideCard = false;
    canAlert: boolean;
    collapsed = true;
    showConfirmation: boolean;

    thumbnail: string;

    private ngUnsubscribe$ = new Subject<void>();
    working: boolean;

    @HostBinding('@collapseOnLeave') get leave() {
        return true;
    }

    constructor(
        private setupGuideChecklistService: SetupGuideChecklistService,
        private commonDialoguesService: CommonDialoguesService,
        private angulartics2: Angulartics2
    ) {
        this.setupGuideChecklistService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.setupGuideChecklistService.completeCard$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(tasks => {
                if (
                    tasks.includes(
                        this.activity.task !==
                            SetupGuideActivityDto.StepIdentifier.CREATE_MISSION
                            ? this.activity.task
                            : SetupGuideActivityDto.StepIdentifier
                                  .SUBMIT_MISSION
                    )
                ) {
                    this.autoCompleted = true;
                }
            });

        this.setupGuideChecklistService.showConfirmation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => (this.showConfirmation = value ?? true));

        this.checkNotification();
        if (
            this.activity.completed === true &&
            this.activity.new === false &&
            this.canShowTakeMeThere() === false
        ) {
            this.menuState = MenuButtonType.STATIC;
        }

        observeImage(this.activity.videoThumbnail).subscribe(
            loadedImage => (this.thumbnail = loadedImage.src),
            () => (this.thumbnail = null)
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    checkNotification() {
        this.canAlert = this.setupGuideChecklistService.canCardAlert();

        if (this.isAlertCard === true && this.canAlert === true) {
            this.tadaNotification();
        }
    }

    tadaNotification() {
        return new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
            if (
                this.tada !== true &&
                this.menuState === MenuButtonType.HAS_MENU
            ) {
                new Promise(resolve => setTimeout(resolve, 250)).then(
                    () => (this.callAttention = true)
                );
                this.tada = true;
                new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                    this.tada = false;
                    new Promise(resolve => setTimeout(resolve, 250)).then(
                        () => {
                            if (this.callAttention !== false) {
                                this.callAttention = false;
                            }
                        }
                    );
                });
            }
        });
    }

    showCardMenu() {
        // if (this.autoCompleted === true && this.canShowTakeMeBack() === false) {
        //     this.completeCard();
        //     return;
        // } else {
        this.showHideCardActivities();
        return;
        // }
    }

    showHideCardActivities() {
        if (
            this.collapsed === false &&
            this.menuState === MenuButtonType.MENU_ACTIVE
        ) {
            this.menuState = MenuButtonType.HAS_MENU;
        }
        this.collapsed = !this.collapsed;
        this.callAttention = false;
        if (this.activity.new === true) {
            this.activity.new = false;
            this.cardViewed.emit();
        }
        this.angulartics2.eventTrack.next({
            action: 'toggle-card',
            properties: {
                category: 'setup-guide',
                label: this.activity.activityId.toString()
            }
        });
    }

    canShowTakeMeThere() {
        if (
            this.activity.task ===
                SetupGuideActivityDto.StepIdentifier.CREATE_LOCATION ||
            this.activity.task ===
                SetupGuideActivityDto.StepIdentifier.FIELD_APP ||
            this.activity.task ===
                SetupGuideActivityDto.StepIdentifier.CREATE_MISSION
        ) {
            return true;
        }
        if (
            this.activity.task ===
                SetupGuideActivityDto.StepIdentifier.COMPLETE_MISSION ||
            this.activity.task ===
                SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
        ) {
            if (this.activity.stateValue != null) {
                return true;
            }
            return false;
        }
        return false;
    }

    canShowTakeMeBack() {
        return (
            this.activity.task ===
                SetupGuideActivityDto.StepIdentifier.CHECK_ORG &&
            this.setupGuideChecklistService.checkIfOriginalOrg() === false
        );
    }

    onCardHidden() {
        if (this.hideCard === true) {
            this.complete.emit();
        }
    }

    completeCard() {
        if (this.showConfirmation === false) {
            this.markDone();
            return;
        }
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Done',
                `Are you sure you would like to mark this as done?`,
                'Yes',
                () => Promise.resolve(),
                (ask: boolean) => this.markAskAgain(ask)
            )
            .then(() => this.markDone())
            .catch();
    }

    markAskAgain(value: boolean): Promise<boolean> {
        if (value == null) {
            return new Promise(all => null);
        }
        return new Promise(all =>
            this.setupGuideChecklistService.dontShowConfirmationAgain(value)
        );
    }

    markDone() {
        if (this.activity.new === false || this.autoCompleted === true) {
            this.setupGuideChecklistService.markActivityDone(this.activity);
            this.collapsed = true;
            // this.menuState = MenuButtonType.COMPLETED;
            // new Promise(resolve => setTimeout(resolve, 2000))
            //     .then(() => this.hideCard = true);
        } else {
            this.menuState = MenuButtonType.MENU_ACTIVE;
        }
        this.angulartics2.eventTrack.next({
            action: 'mark-done',
            properties: {
                category: 'setup-guide',
                label: this.activity.activityId.toString()
            }
        });
    }

    goToActivity() {
        this.setupGuideChecklistService.getActivityTask(this.activity);
        this.angulartics2.eventTrack.next({
            action: 'action',
            properties: {
                category: 'setup-guide',
                label: this.activity.activityId.toString()
            }
        });
    }

    showVideo() {
        const video = this.setupGuideChecklistService.showVideo(
            this.activity.videoUrl,
            this.activity.videoThumbnail,
            this.activity.title,
            this.activity.activityId,
            this.activity.task
        );
        this.activity.new = false;
        this.cardViewed.emit();
        this.angulartics2.eventTrack.next({
            action: 'video',
            properties: {
                category: 'setup-guide',
                label: this.activity.activityId.toString(),
                videoUrl: this.activity.videoUrl,
                task: this.activity.task
            }
        });
    }
}
