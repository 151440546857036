/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface CreateApiKeyCommand { 
    name: string;
    allOrganisations: boolean;
    allPermissions: boolean;
    organisationIds: Array<number>;
    permissions: Array<CreateApiKeyCommand.Permissions>;
}
export namespace CreateApiKeyCommand {
    export type Permissions = 'MISSIONS_NONE' | 'MISSIONS_READ' | 'MISSIONS_WRITE' | 'PERSONNEL_NONE' | 'PERSONNEL_READ' | 'PERSONNEL_WRITE' | 'RESOURCES_NONE' | 'RESOURCES_READ' | 'RESOURCES_WRITE' | 'ORGANISATION_NONE' | 'ORGANISATION_READ' | 'ORGANISATION_WRITE' | 'PROFILE_NONE' | 'PROFILE_READ' | 'PROFILE_WRITE' | 'FLIGHT_LOGS_NONE' | 'FLIGHT_LOGS_READ' | 'FLIGHT_LOGS_WRITE' | 'FLIGHT_LOGS_UPLOAD';
    export const Permissions = {
        MISSIONS_NONE: 'MISSIONS_NONE' as Permissions,
        MISSIONS_READ: 'MISSIONS_READ' as Permissions,
        MISSIONS_WRITE: 'MISSIONS_WRITE' as Permissions,
        PERSONNEL_NONE: 'PERSONNEL_NONE' as Permissions,
        PERSONNEL_READ: 'PERSONNEL_READ' as Permissions,
        PERSONNEL_WRITE: 'PERSONNEL_WRITE' as Permissions,
        RESOURCES_NONE: 'RESOURCES_NONE' as Permissions,
        RESOURCES_READ: 'RESOURCES_READ' as Permissions,
        RESOURCES_WRITE: 'RESOURCES_WRITE' as Permissions,
        ORGANISATION_NONE: 'ORGANISATION_NONE' as Permissions,
        ORGANISATION_READ: 'ORGANISATION_READ' as Permissions,
        ORGANISATION_WRITE: 'ORGANISATION_WRITE' as Permissions,
        PROFILE_NONE: 'PROFILE_NONE' as Permissions,
        PROFILE_READ: 'PROFILE_READ' as Permissions,
        PROFILE_WRITE: 'PROFILE_WRITE' as Permissions,
        FLIGHT_LOGS_NONE: 'FLIGHT_LOGS_NONE' as Permissions,
        FLIGHT_LOGS_READ: 'FLIGHT_LOGS_READ' as Permissions,
        FLIGHT_LOGS_WRITE: 'FLIGHT_LOGS_WRITE' as Permissions,
        FLIGHT_LOGS_UPLOAD: 'FLIGHT_LOGS_UPLOAD' as Permissions
    };
}



