import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AddressDto,
    AddressService,
    DO_NOTHING,
    FlyFreelyLoggingService,
    ResultDto,
    UpdateAddressCommand,
    UserLocationService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import CountryStates from 'libs/ui/src/lib/data/country-states.json';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

const countryStates = CountryStates as Country[];

export interface CountryAndCode {
    iso: string;
    name: string;
}

export interface Country {
    iso: string;
    name: string;
    phonePrefix: string;
    phonePlaceholder: string;
    states: string[];
}

export interface IpLookupResult {
    country: string;
    countryCode: string;
    city: string;
    region: string;
    regionName: string;
    zip: string;
}

/**
 * Find the country by iso code, so that we can handle the country objects
 * @param code the iso code of the country we are resolving
 * @param countries the list of countries
 */
function findCountryByCode(code: string, countries: Country[]): Country {
    if (code == null) {
        return null;
    }
    const matchableName = code.toUpperCase().trim();
    return countries.find(c => c.iso.toUpperCase() === matchableName);
}

@Component({
    selector: 'address-edit-dialogue',
    templateUrl: './address-edit-dialogue.component.html'
})
export class AddressEditDialogue implements OnInit {
    @Input() address: AddressDto;
    @Input() organisationId: number;
    @Input() title: string;
    @Input() hiddenFields: string[] = [];

    addressForm: FormGroup;

    addressFields: FormlyFieldConfig[];
    options: FormlyFormOptions;

    public countries = countryStates;
    public states: string[] = [];
    public types = [
        { name: 'POSTAL', label: 'Postal', value: 'POSTAL' },
        { label: 'Work', value: 'WORK', name: 'WORK' },
        { label: 'Residential', name: 'RESIDENTIAL', value: 'RESIDENTIAL' }
    ];
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    working = false;

    countryStateLookup = countryStates.reduce(
        (acc, c) => ({ ...acc, [c.iso]: c }),
        {}
    );

    constructor(
        private modal: BsModalRef<AddressEditDialogue>,
        modalOptions: ModalOptions,
        private addressService: AddressService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private userLocationService: UserLocationService,
        private changeDetector: ChangeDetectorRef
    ) {
        modalOptions.closeInterceptor = () =>
            preHide(this.addressForm, this.commonDialoguesService);
    }

    ngOnInit() {
        this.addressForm = new FormGroup({});

        this.buildFormlyFields();

        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngAfterViewInit() {
        this.addressForm
            .get('country')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(country => this.onCountryChanged(country));

        if (this.address != null) {
            this.addressForm.patchValue({
                addressType: this.address.addressType || null,
                suburb: this.address.suburb || null,
                street: this.address.street || null,
                street2: this.address.street2 || null,
                state: this.address.state || null,
                postcode: this.address.postcode || null,
                country: findCountryByCode(this.address.country, countryStates),
                name: this.address.name || null,
                email: this.address.email || null
            });
            if ('phoneNumber' in this.addressForm.controls) {
                this.parsePhoneNumber();
            }
        } else {
            this.getIPAddress();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    buildFormlyFields() {
        this.addressFields = [
            {
                key: 'addressType',
                type: 'ng-select',
                props: {
                    label: 'Address Type',
                    required: true,
                    options: this.types
                }
            },
            {
                key: 'country',
                type: 'country',
                props: {
                    label: 'Country',
                    required: true
                }
            },
            {
                key: 'street',
                type: 'input',
                props: {
                    label: 'Street',
                    required: true
                }
            },
            {
                key: 'street2',
                type: 'input',
                props: {
                    label: 'Street',
                    required: false
                }
            },
            {
                key: 'suburb',
                type: 'input',
                props: {
                    label: 'City/Suburb',
                    required: true
                }
            },
            {
                key: 'state',
                type: 'input',
                props: {
                    label: 'State',
                    required: true,
                    minLength: 1
                },
                hideExpression: 'model.country == null || model.country?.states?.length > 0'
            },
            {
                key: 'state',
                type: 'ng-select',
                props: {
                    label: 'State',
                    required: true,
                    options: this.states.map(s => {
                        return {
                            name: s,
                            label: s,
                            value: s
                        };
                    })
                },
                hideExpression: 'model.country == null || model.country?.states?.length === 0'
            },
            {
                key: 'postcode',
                type: 'input',
                props: {
                    label: 'Postal Code',
                    required: true
                }
            },
            {
                key: 'phoneNumber',
                type: 'phonenumber',
                props: {
                    label: 'Phone Number',
                    required: false,
                    minLength: 10
                }
            },
            {
                key: 'email',
                type: 'input',
                props: {
                    label: 'Email Address',
                    required: false
                }
            },
            {
                key: 'name',
                type: 'input',
                props: {
                    label: 'Name',
                    required: false
                }
            }
        ].filter(f => !this.hiddenFields.includes(f.key));
    }

    onCountryChanged(country: Country) {
        if (country == null || country.states == null) {
            this.states = [];
            return;
        }

        this.states = country.states;
        const index = this.addressFields.findIndex(
            field => field.key === 'state' && field.type === 'ng-select'
        );

        this.addressFields[index].props.options = this.states.map(s => {
            return {
                name: s,
                label: s,
                value: s
            };
        });
        this.changeDetector.detectChanges();
    }

    /**
     * Retrieve form fields
     */
    onSubmit() {
        const doneWorking = this.workTracker.createTracker();
        const form = this.addressForm.value;
        const phoneNumber =
            form.phoneNumber?.replace(/\s/g, '') ?? this.address.phoneNumber;
        const addressType = form.addressType ?? this.address.addressType;

        const forSaving: UpdateAddressCommand = {
            addressType,
            country: form.country.iso ? form.country.iso : this.address.country,
            postcode: form.postcode,
            state: form.state,
            street: form.street,
            street2: form.street2 || '',
            suburb: form.suburb,
            name: form.name,
            email: form.email,
            phoneNumber
        };

        if (this.address != null && this.address.id != null) {
            return this.addressService
                .updateAddress(this.address.id, forSaving)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: results => {
                        this.logging.success('Address has been updated');
                        doneWorking();
                        this.addressForm.markAsPristine();
                        this.modal.hide();
                        return results;
                    },
                    error: error => {
                        doneWorking();
                        return Promise.reject(error);
                    }
                });
        } else {
            return this.addressService
                .createAddress({
                    ...forSaving,
                    organisationId: this.organisationId
                })
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    results => {
                        this.logging.success('Address has been created');
                        doneWorking();
                        this.addressForm.markAsPristine();
                        this.modal.hide();
                        return results;
                    },
                    error => {
                        doneWorking();
                        return Promise.reject(error);
                    }
                );
        }
    }

    hide() {
        this.modal.hide();
    }

    parsePhoneNumber() {
        if (this.address.phoneNumber == null) {
            this.userLocationService
                .findUserLocation()
                .pipe(take(1))
                .subscribe(result => {
                    const country = findCountryByCode(
                        result.value,
                        countryStates
                    );
                    this.addressForm.controls.phoneNumber.patchValue(
                        `+${country.phonePrefix}`
                    );
                });
        } else {
            const number: string = this.address.phoneNumber;
            if (number[0] === '+') {
                this.addressForm.controls.phoneNumber.patchValue(number);
                return;
            }
            this.userLocationService
                .findUserLocation()
                .pipe(take(1))
                .subscribe(result => {
                    const country = findCountryByCode(
                        result.value,
                        countryStates
                    );
                    this.addressForm.controls.phoneNumber.patchValue(
                        `+${country.phonePrefix}${
                            number[0] === '0' ? number.slice(1) : number
                        }`
                    );
                });
        }
    }

    findCountryCode(res: ResultDto) {
        const number: string = this.address?.phoneNumber ?? '';
        const country = findCountryByCode(res.value, countryStates);
        this.addressForm.controls.phoneNumber.patchValue(
            `+${country.phonePrefix}${
                number[0] === '0' ? number.slice(1) : number
            }`
        );
    }

    /**
     * Set class and place holder for selected country
     * @param country
     */
    onIpLookup(result: ResultDto) {
        this.addressForm.patchValue({
            country: findCountryByCode(result.value, countryStates)
            // state: result.regionName,
            // postcode: result.zip
        });
    }

    /**
     * Get IP address information and set the default country according to the response.
     * If API call fails in any case then set US as the default country.
     */
    async getIPAddress() {
        this.userLocationService
            .findUserLocation()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.onIpLookup(result);
                    this.findCountryCode(result);
                },
                err => DO_NOTHING
            );
    }
}
