import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AirworthinessNoticesService } from 'libs/flyfreely/src/lib/services/airworthnessNotices.service';
import { AttachmentHandler, FlyFreelyError, FlyFreelyLoggingService, WorkTracker} from '@flyfreely-portal-ui/flyfreely';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AirworthinessNoticesDialogueService } from '../airworthness-notices.service';
import { AirworthnessNoticesDto } from 'libs/flyfreely/src/lib/model/api/airworthnessNoticeDto';
import { LinkAction } from 'libs/attachments/src/lib/link-manager/link-manager.interface';



@Component({
    selector: 'airworthness-notices-edit-dialogue',
    templateUrl: './airworthness-notices-edit-dialogue.component.html',
    styleUrls: ['./airworthness-notices-edit-dialogue.component.scss']
})
export class AirworthnessNoticesEditDialogue implements OnInit, OnDestroy {
    noticeForm: FormGroup;
    @Input() organisationId: number;
    @Input() notice: AirworthnessNoticesDto | null = null;
    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    neededName: string = "Name is required";
    updateButtonText: string = 'Create';
    attachmentsHandler: AttachmentHandler;
    showAttachments: boolean = false;
    airworthinessId: number;

    private editModeSubject = new BehaviorSubject<string>('Create');
    editMode$ = this.editModeSubject.asObservable();

    get LinkAction() {
        return LinkAction;
    }
    setEditMode(value: string) {
        this.editModeSubject.next(value);
    }
    setAttachmentHandler(id: number) {
        this.AirworthinessNoticesDialogueService.attachmentHandler(id);
    }


    constructor(
        private modal: BsModalRef,
        private airworthinessNoticesService: AirworthinessNoticesService,
        private fb: FormBuilder,
        private logging: FlyFreelyLoggingService,
        private AirworthinessNoticesDialogueService : AirworthinessNoticesDialogueService
    ) {}

    ngOnInit() {
        this.AirworthinessNoticesDialogueService.airworthinessNoticeId$.subscribe(id => this.airworthinessId = id);
        this.noticeForm = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(3)]],
            type: ['', Validators.required],
            applicability: ['', Validators.required],
            issuedBy: [''],
            dueBy: [''],
            description: [''],
          });
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        if (this.notice) {
            this.setEditMode('Update');
            this.attachmentsHandler = this.AirworthinessNoticesDialogueService.attachmentHandler(this.notice.id);
            this.AirworthinessNoticesDialogueService.setAirworthinessNoticeId(this.notice.id);
            this.populateForm(this.notice);
            this.updateButtonText = 'Update';
        }
    }



    populateForm(notice: AirworthnessNoticesDto) {
        this.noticeForm.patchValue({
          name: notice.name,
          type: notice.type,
          applicability: notice.applicability,
          issuedBy: notice.issuedBy,
          dueBy: notice.dueBy,
          description: notice.description,
        });
      }

      updateNotice() {
        if (this.noticeForm.valid) {
            const { name, type, applicability, issuedBy, dueBy, description } = this.noticeForm.value;
            this.airworthinessNoticesService.update(this.airworthinessId, name, type, applicability, issuedBy, dueBy, description)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: (updatedNotice: AirworthnessNoticesDto) => {
                        if (updatedNotice) {
                            this.logging.success(`Successfully notice updated`);
                            
                            // Check if applicability has changed
                            if (this.notice?.applicability !== applicability) {
                                this.AirworthinessNoticesDialogueService.setExpandedGroup(applicability);
                            }
    
                            // Update the selected card to the new applicability group
                            this.AirworthinessNoticesDialogueService.setSelectedCardId(updatedNotice.id);
    
                            this.cancel();
                        } else {
                            this.logging.error(null, 'No notice returned from server');
                        }
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while updating notice: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }
    

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }


    cancel() {
        if (this.noticeForm.valid) {
            const { applicability } = this.noticeForm.value;
            this.AirworthinessNoticesDialogueService.setExpandedGroup(applicability);
        }
        
        this.AirworthinessNoticesDialogueService.setNoticeCreationStatus(true);
    
        if (this.notice) {
            this.AirworthinessNoticesDialogueService.setSelectedCardId(this.notice.id);
            this.AirworthinessNoticesDialogueService.setAirworthinessNoticeId(this.notice.id);
        } else {
            this.AirworthinessNoticesDialogueService.setSelectedCardId(this.airworthinessId);
            this.AirworthinessNoticesDialogueService.setAirworthinessNoticeId(this.airworthinessId); 
        }
    
        this.modal.hide();
    }
    

    createNotice() {
        if (this.noticeForm.valid) {
            const { name, type, applicability, issuedBy, dueBy, description } = this.noticeForm.value;
            this.airworthinessNoticesService.create(this.organisationId, name, type, applicability, issuedBy, dueBy, description)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: (createdNotice: any) => {
                        if (createdNotice) {
                            const noticeId = createdNotice.data.createAirworthinessNotice.id
                            const applicability = createdNotice.data.createAirworthinessNotice.applicability;
                            this.logging.success(`Successfully notice created`);
                            this.AirworthinessNoticesDialogueService.setNoticeCreationStatus(true);
                            // Update AirworthinessNoticesDialogueService
                            this.AirworthinessNoticesDialogueService.setSelectedCardId(noticeId);
                            this.AirworthinessNoticesDialogueService.setAirworthinessNoticeId(noticeId);
                            this.AirworthinessNoticesDialogueService.setExpandedGroup(applicability);
                            this.attachmentsHandler = this.AirworthinessNoticesDialogueService.attachmentHandler(noticeId);
                            this.setEditMode('Update');
                        } else {
                            this.logging.error(null, 'No notice returned from server');
                        }
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while creating notice: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }
}    
