import { Component, Input, Optional } from '@angular/core';
import {
    EnhancedHelpDto,
    FlyFreelyError
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { EnhancedHelpService } from 'libs/enhanced-help/src/lib/enhanced-help.service';
import { take } from 'rxjs/operators';

/**
 * An empty state component. This can be driven by enhanced help by nesting it in a `screenAnalytics` directive,
 * and setting a `componentName` input. Otherwise, any content is transcluded into the component.
 */
@Component({
    selector: 'empty',
    template: `<div
        class="alert horizontal-container wrap fill vertical-align"
        [ngClass]="{
            'alert-info': !disabledState,
            'alert-disabled': disabledState
        }"
        imgPath="/stars@3x.png"
    >
        <div class="fcol-xs-12 fcol-sm-4"></div>
        <div class="content fcol-xs-12 fcol-sm-4">
            <div
                *ngIf="helpText != null"
                [innerHTML]="helpText | autolink"
            ></div>
            <ng-content></ng-content>
        </div>
        <div class="links fcol-xs-12 fcol-sm-4">
            <div>
                <a
                    *ngIf="
                        supportVideoUrl != null && supportVideoUrl?.length > 0
                    "
                    (click)="showVideo()"
                    ><span class="fa fa-clapperboard-play black-text"></span>
                    Video</a
                >
            </div>
            <div>
                <a
                    *ngIf="
                        knowledgeBaseUrl != null && knowledgeBaseUrl?.length > 0
                    "
                    [href]="knowledgeBaseUrl"
                    target="_blank"
                    (click)="trackKnowledgeBase()"
                    ><span class="fas fa-question-circle black-text"></span>
                    Knowledge Base</a
                >
            </div>
        </div>
    </div>`,
    styles: [
        `
            :host {
                display: flex;
                position: relative;
                flex: 1 1 auto;
                min-height: 0;
                min-width: 0;
            }
            div.alert {
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto;
                background-blend-mode: soft-light;
                margin-bottom: 0;
            }
            div.content {
                display: flex;
                flex-direction: column;
                font-size: 1.3rem;
            }
            div.links {
                text-align: right;
                align-self: end;
            }
        `
    ]
})
export class Empty {
    /**
     * The screen name to use to look up the details for this empty component. Falls back on the {@link ScreenAnalyticsDirective}
     */
    @Input()
    screenName?: string;

    @Input()
    componentName?: string;

    /**
     * Used to override specific values from the resolved enhanced help data.
     */
    @Input()
    helpObjectOverride?: EnhancedHelpDto;

    @Input() disabledState: boolean = false;

    copy: string;

    error?: string;

    helpObject: EnhancedHelpDto;

    constructor(
        private enhancedHelpService: EnhancedHelpService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {}

    ngOnInit() {
        this.getHelpObject();
    }

    getHelpObject() {
        if (this.componentName == null) {
            return;
        }

        const screenName =
            this.screenName ?? this.screenAnalytics?.screenAnalytics;

        if (screenName == null) {
            throw new Error(`screenName not specified and can't be inferred`);
        }

        this.enhancedHelpService
            .findByScreenAndComponent(screenName, this.componentName)
            .pipe(take(1))
            .subscribe({
                // This is only a fallback and should actually happen in the backend
                next: results =>
                    (this.helpObject =
                        results.helpText != null && results.helpText.length > 0
                            ? results
                            : null),
                error: (error: FlyFreelyError) => {
                    this.error = error.message;
                }
            });
    }

    showVideo() {
        // Don't log analytics for enhanced help editor override values.
        // TODO: might need to turn off analytics for edit mode as a whole.
        if (this.helpObjectOverride?.supportVideoUrl != null) {
            this.enhancedHelpService.showVideo(
                this.helpObjectOverride.supportVideoUrl,
                this.helpTitle
            );
            return;
        }
        this.screenAnalytics.track('help', 'show-video', {
            component: this.componentName
        });
        this.enhancedHelpService.showVideo(
            this.helpObject.supportVideoUrl,
            this.helpObject.helpTitle
        );
    }

    trackKnowledgeBase() {
        // Don't log analytics for enhanced help editor override values.
        // TODO: might need to turn off analytics for edit mode as a whole.
        if (this.helpObjectOverride?.knowledgeBaseUrl != null) {
            return;
        }
        this.screenAnalytics.track('help', 'show-knowledge-base', {
            component: this.componentName
        });
    }

    get helpTitle() {
        return (
            this.helpObjectOverride?.helpTitle ??
            this.helpObject?.helpTitle ??
            this.componentName
        );
    }

    get helpText() {
        return (
            this.helpObjectOverride?.helpText ??
            this.helpObject?.helpText
        );
    }

    get supportVideoUrl() {
        return (
            this.helpObjectOverride?.supportVideoUrl ??
            this.helpObject?.supportVideoUrl
        );
    }

    get knowledgeBaseUrl() {
        return (
            this.helpObjectOverride?.knowledgeBaseUrl ??
            this.helpObject?.knowledgeBaseUrl
        );
    }
}
