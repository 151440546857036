<div class="container-with-footer">
    <div>
        <ng-container *ngFor="let jurisdiction of inUseJurisdictions">
            <ng-container
                *ngIf="
                    visibleAuthorities[jurisdiction.id] != null &&
                    visibleAuthorities[jurisdiction.id].length > 0
                "
            >
                <h4 class="primary-heading top-heading">
                    {{ jurisdiction.name }}
                </h4>
                <div class="horizontal-container tiled-children">
                    <ng-container
                        *ngFor="
                            let authorityType of visibleAuthorities[
                                jurisdiction.id
                            ]
                        "
                    >
                        <authority-group-edit
                            [attr.id]="authorityType.id"
                            [authorityGroup]="authorityType"
                            [organisation]="organisation"
                            [managingOrganisationId]="managingOrganisationId"
                            [showArchived]="showArchived"
                            [viewOnly]="true"
                            (onShowAuthority)="
                                showAuthority(
                                    $event.authorityType,
                                    $event.authority
                                )
                            "
                        ></authority-group-edit>
                    </ng-container>
                </div>
                <hr />
            </ng-container>
        </ng-container>
        <div
            class="alert alert-info"
            role="alert"
            *ngIf="authorities.length == 0 || allArchived"
        >
            This {{ entityType }} does not have any active associated
            authorities.
        </div>

        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
    <div class="action-container">
        <button
            type="button"
            class="btn btn-primary pull-right"
            *ngIf="canEdit"
            (click)="onEdit()"
            data-cy="organisationAuthorityEditButton"
        >
            Edit
        </button>
    </div>
</div>
