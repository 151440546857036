import { formatDate } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import {
    DisplayableMissionDto,
    FlightConformanceResultDto,
    isApprovalResolved,
    Organisation,
    RequestersMissionApprovalDto
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseAnimation,
    fadeInAnimation,
    fadeOutAnimation
} from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    findActiveMissionApprovals,
    getSimplifiedAuthorityFromApproval
} from '../../helpers';
import { MissionApprovalWithAuthority } from '../../interfaces';
import {
    MissionDetailsService,
    ribbonValues,
    StatusFields
} from '../mission-details.service';

@Component({
    selector: 'mission-details-status',
    templateUrl: './mission-details-status.component.html',
    styleUrls: ['./mission-details-status.scss'],
    animations: [collapseAnimation(), fadeInAnimation(), fadeOutAnimation()]
})
export class MissionDetailsStatus implements OnInit, OnChanges {
    @Input() mission: DisplayableMissionDto;
    @Input() organisation: Organisation;
    @Input() missionApprovals?: MissionApprovalWithAuthority[];
    @Output() openConformanceTab = new EventEmitter<string>();
    @Output() requestCancelApproval =
        new EventEmitter<RequestersMissionApprovalDto>();
    @Output() requestPerformApproval =
        new EventEmitter<RequestersMissionApprovalDto>();

    status: DisplayableMissionDto.Status;
    activeMissionApprovals: MissionApprovalWithAuthority[] = [];
    date: any;
    isDummy: boolean;

    ribbonData: StatusFields;

    details = false;

    draft: boolean;
    submission: boolean;
    approval: boolean;
    launch: boolean;
    operations: boolean;
    documentation: boolean;
    reconciliation: boolean;
    finalRequest: boolean;
    finalised: boolean;

    overflow: boolean = true;
    hidden: boolean = true;
    fade: boolean;
    conformanceRibbon: StatusFields = null;
    conformanceNonResolved = new BehaviorSubject<FlightConformanceResultDto[]>(
        []
    );

    getSimplifiedAuthorityFromApproval = getSimplifiedAuthorityFromApproval;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionDetailsService: MissionDetailsService,
        private angulartics: Angulartics2
    ) {}

    ngOnInit() {
        this.refreshData();

        this.missionDetailsService.flightConformanceData$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(confs => {
                const checkConformance = confs.filter(
                    conf => conf.status === 'SENT'
                );

                this.conformanceNonResolved.next(checkConformance);

                if (checkConformance.length) {
                    this.conformanceRibbon =
                        this.missionDetailsService.getConformanceRibbonData();
                }
            });
    }

    ngOnChanges() {
        this.refreshData();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ribbonAction() {
        this.angulartics.eventTrack.next({ action: 'mission-ribbon-action' });
        this.missionDetailsService.doRibbonAction(
            this.mission,
            this.organisation
        );
    }

    setOverflow(event: any) {
        this.overflow = true;
        this.hidden = false;
    }

    unsetOverflow(event: any) {
        this.overflow = false;
        this.fade = this.details;
        this.hidden = true;
    }

    refreshData() {
        if (this.mission != null) {
            this.status = this.mission.status;
            this.date = this.mission.missionDate
                ? formatDate(this.mission.missionDate, 'd MMMM yyyy', 'En')
                : null;
            this.isDummy = this.mission.isDummy ? this.mission.isDummy : null;
        }
        this.activeMissionApprovals =
            this.missionApprovals != null
                ? findActiveMissionApprovals(this.missionApprovals, true)
                : [];

        this.parseStatus();
    }
    isResolved(status: RequestersMissionApprovalDto.Status) {
        return isApprovalResolved(status);
    }

    formatConformanceAlertText(text: string): string {
        return text.replace(
            '&&count&&',
            String(this.conformanceNonResolved.getValue().length)
        );
    }

    viewConformanceIssue() {
        this.openConformanceTab.emit();
    }

    cancelApproval(approval: RequestersMissionApprovalDto) {
        this.requestCancelApproval.emit(approval);
    }

    performApproval(approval: RequestersMissionApprovalDto) {
        this.requestPerformApproval.emit(approval);
    }

    toggleDetails() {
        this.details = !this.details;
        this.angulartics.eventTrack.next({
            action: this.details
                ? 'mission-ribbon-open'
                : 'mission-ribbon-close'
        });
    }

    parseStatus() {
        if (this.status == null) {
            return;
        }
        this.ribbonData = this.missionDetailsService.getRibbonValues(
            this.status,
            this.mission
        );
        // Apply modifiers for dummy mission
        if (
            this.isDummy &&
            this.status !== DisplayableMissionDto.Status.CANCELLED
        ) {
            const dummyValues = ribbonValues.dummy;
            this.ribbonData = {
                ...this.ribbonData,
                ...dummyValues,
                hasDate: false
            };
        }
    }
}
