/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.11-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaintenanceScheduleAvailableActions } from './maintenanceScheduleAvailableActions';
import { MaintenanceScheduleTriggerDto } from './maintenanceScheduleTriggerDto';
import { MaintenanceScheduleVersionDto } from './maintenanceScheduleVersionDto';


/**
 * A maintenance schedule and its active workflow if applicable
 */
export interface MaintenanceScheduleDto { 
    /**
     * The ID of the workflow
     */
    id: number;
    /**
     * The current active version of the workflow
     */
    activeVersionId?: number;
    /**
     * The organisationId of the owning organisation
     */
    organisationId?: number;
    /**
     * The versions of the workflow
     */
    versions: Array<MaintenanceScheduleVersionDto>;
    availableActions: MaintenanceScheduleAvailableActions;
    archived: boolean;
    /**
     * The organisation name
     */
    organisationName?: string;
    name: string;
    resourceCategory: MaintenanceScheduleDto.ResourceCategory;
    equipmentCategory?: MaintenanceScheduleDto.EquipmentCategory;
    rpaCategory?: MaintenanceScheduleDto.RpaCategory;
    triggerList?: Array<MaintenanceScheduleTriggerDto>;
}
export namespace MaintenanceScheduleDto {
    export type ResourceCategory = 'CRAFT' | 'BATTERY_SET' | 'BATTERY' | 'EQUIPMENT' | 'UNLISTED_RPA';
    export const ResourceCategory = {
        CRAFT: 'CRAFT' as ResourceCategory,
        BATTERY_SET: 'BATTERY_SET' as ResourceCategory,
        BATTERY: 'BATTERY' as ResourceCategory,
        EQUIPMENT: 'EQUIPMENT' as ResourceCategory,
        UNLISTED_RPA: 'UNLISTED_RPA' as ResourceCategory
    };
    export type EquipmentCategory = 'PAYLOAD' | 'BASE_STATION' | 'CONTROLLER' | 'MONITOR' | 'CONTROL_ACCESSORIES' | 'COMMUNICATION_EQUIPMENT' | 'OTHER';
    export const EquipmentCategory = {
        PAYLOAD: 'PAYLOAD' as EquipmentCategory,
        BASE_STATION: 'BASE_STATION' as EquipmentCategory,
        CONTROLLER: 'CONTROLLER' as EquipmentCategory,
        MONITOR: 'MONITOR' as EquipmentCategory,
        CONTROL_ACCESSORIES: 'CONTROL_ACCESSORIES' as EquipmentCategory,
        COMMUNICATION_EQUIPMENT: 'COMMUNICATION_EQUIPMENT' as EquipmentCategory,
        OTHER: 'OTHER' as EquipmentCategory
    };
    export type RpaCategory = 'MULTIROTOR' | 'HELICOPTOR' | 'AEROPLANE' | 'POWERED_LIFT' | 'AIRSHIP' | 'OTHER';
    export const RpaCategory = {
        MULTIROTOR: 'MULTIROTOR' as RpaCategory,
        HELICOPTOR: 'HELICOPTOR' as RpaCategory,
        AEROPLANE: 'AEROPLANE' as RpaCategory,
        POWERED_LIFT: 'POWERED_LIFT' as RpaCategory,
        AIRSHIP: 'AIRSHIP' as RpaCategory,
        OTHER: 'OTHER' as RpaCategory
    };
}


