import { Pipe } from '@angular/core';
import Autolinker from 'autolinker';
import { DomSanitizer } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

@Pipe({ name: 'autolink' })
export class AutolinkPipe {
    constructor(private sanitizer: DomSanitizer) {}

    /**
     * This will bypass the DOM security so that the click interception can occur.
     * @param text the text or HTML to autolink
     */
    transform(text: string) {
        return this.sanitizer.bypassSecurityTrustHtml(
            Autolinker.link(DOMPurify.sanitize(text), {
                newWindow: true,
                replaceFn: function (match) {
                    const tag = match.buildTag();
                    tag.setAttr('onclick', 'event.stopPropagation()');
                    return tag;
                }
            })
        );
    }
}
