import { Component } from '@angular/core';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    defaultKnowledgeBaseUrl,
    KnowledgeBaseService
} from './knowledge-base-link.service';

@Component({
    selector: 'knowledge-base-link',
    template: `
        <a
            class="btn btn-sm"
            [href]="href"
            target="_blank"
            (click)="click()"
            tooltip="Visit our knowledge base"
            ><span class="fas fa-question-circle"></span
        ></a>
    `,
    styles: [
        `
            :host {
                display: flex;
                align-items: center;
            }
        `
    ]
})
export class KnowledgeBaseLink {
    href = defaultKnowledgeBaseUrl;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private screenAnalytics: ScreenAnalyticsDirective,
        private knowledgeBaseService: KnowledgeBaseService
    ) {}

    ngOnInit() {
        this.knowledgeBaseService
            .findKnowledgeBaseUrlByScreen(this.screenAnalytics.screen)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                link =>
                    (this.href =
                        link?.knowledgeBaseUrl ?? defaultKnowledgeBaseUrl)
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    click() {
        this.screenAnalytics.track('help', 'knowledge-base');
    }
}
