import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '@flyfreely-portal-ui/analytics';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { AddressesModule } from 'libs/addresses/src/lib/addresses.module';
import { ApprovalConditionsModule } from 'libs/approval-conditions/src/lib/approval-conditions.module';
import { AuthorityModule } from 'libs/authorities/src/lib/authorities.module';
import { OrganisationAuthorityModule } from 'libs/authorities/src/lib/organisation-authority/organisation-authority.module';
import { BatteryModule } from 'libs/batteries/src/lib/batteries.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { MaintenanceModule } from 'libs/maintenance/src/lib/maintenance.module';
import { MissionObjectivesModule } from 'libs/mission-objectives/src/lib/mission-objectives.module';
import { ReferralsModule } from 'libs/referrals/src/lib/referrals.module';
import { RouterTabModule } from 'libs/router-tab/src/lib/router-tab.module';
import { RpaModule } from 'libs/rpa/src/lib/rpa.module';
import { SubscriptionsModule } from 'libs/subscriptions/src/lib/subscriptions.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { WorkflowModule } from 'libs/workflows/src/lib/workflows.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { FlightConformanceModule } from './flight-conformance/flight-conformance.module';
import { OrganisationAdminDialogue } from './organisation-admin-dialogue.component';
import { OrganisationDefaultSettingsComponent } from './organisation-settings/organisation-settings.component';
import { OrganisationOverview } from './overview/organisation-overview.component';
import { MaintenanceScheduleList } from './resource-maintenance/maintenance-schedule-list/maintenance-schedule-list.component';
import { ResourceMaintenance } from './resource-maintenance/resource-maintenance.component';
import { RpaTypeCustomisationComponent } from './resource-maintenance/rpa-model-customisation/rpa-type-customisation.component';
import { RpaWorkflows } from './resource-maintenance/rpa-workflows.component';
import { OrganisationSubscriptionsModule } from './subscriptions/organisation-subscriptions.module';
import { WorkgroupsSetupComponent } from './workgroups/workgroups-setup.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // ngx-bootstrap
        TooltipModule,
        TabsModule,
        // ngx-formly
        ReactiveFormsModule,
        FormlyModule,
        FormlyBootstrapModule,
        // ng-select
        NgSelectModule,
        ToastrModule,
        ModalModule.forChild(),
        SharedUiModule,
        AddressesModule,
        DocumentationModule,
        BatteryModule,
        WorkflowModule,
        AuthorityModule,
        MissionObjectivesModule,
        ApprovalConditionsModule,
        ReferralsModule,
        OrganisationAuthorityModule,
        SubscriptionsModule,
        RpaModule,
        MaintenanceModule,
        FlightConformanceModule,
        FeaturesModule,
        EnhancedHelpModule,
        AnalyticsModule,
        EditableModule,
        LoadingIndicatorModule,
        ValidationModule,
        ButtonsModule,
        RouterTabModule,
        NgxLoadingModule,
        ResourceUiModule,
        OrganisationSubscriptionsModule,
        FlyFreelyTableModule
    ],
    bootstrap: [],
    declarations: [
        OrganisationOverview,
        OrganisationAdminDialogue,
        RpaTypeCustomisationComponent,
        RpaWorkflows,
        ResourceMaintenance,
        MaintenanceScheduleList,
        WorkgroupsSetupComponent,
        OrganisationDefaultSettingsComponent
    ]
})
export class OrganisationAdminModule {}
