<div
    class="panel widget"
    *ngIf="(authorityExpiries && authorityExpiries.length > 0) || working"
>
    <div class="panel-heading-flex">
        <div class="panel-title no-uppercase">RPA Authority Expiries</div>
        <div class="panel-content"></div>
        <div class="panel-buttons">
            <button
                class="btn btn-inline"
                (click)="showBody = !showBody"
                [tooltip]="showBody ? 'Hide' : 'Show'"
                placement="left"
            >
                <i *ngIf="showBody" class="fa-chevron-up fa no-border"></i>
                <i *ngIf="!showBody" class="fa-chevron-down fa no-border"></i>
            </button>
        </div>
    </div>
    <div *ngIf="showBody" class="panel-body" @expandOnEnter @collapseOnLeave>
        <div
            class="static-table-container no-actions"
            *ngIf="authorityExpiries"
        >
            <static-table
                [availableColumns]="availableColumns"
                [selectedColumns]="selectedColumns"
                [tableData]="authorityExpiries"
                [tableConfig]="tableConfig"
                [borderless]="false"
                [underlinedRows]="true"
                scanability="comfortable"
                (pageChanged)="onTablePageChanged($event)"
                (sortOrderChanged)="updateColumnSorting($event)"
                (itemLimitUpdated)="updateItemLimit($event)"
            >
                <ng-template
                    columnTemplate="status"
                    let-row="row"
                    let-column="column"
                >
                    <span
                        class="status-cell"
                        [ngClass]="{
                            success: row.status === 'OK',
                            warn: row.status === 'UPCOMING',
                            danger: row.status === 'EXPIRED'
                        }"
                        >{{
                            row.status != null
                                ? formatStatuses[row.status]
                                : 'OK'
                        }}</span
                    >
                </ng-template>
            </static-table>
        </div>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
<completed-outstanding-tasks-banner
    *ngIf="(!authorityExpiries || authorityExpiries.length == 0) && !working"
    widgetHeading="Rpa Authority Expiries"
></completed-outstanding-tasks-banner>
