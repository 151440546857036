<flyfreely-sidebar></flyfreely-sidebar>
<dynamic-help-editor *ngIf="showDynamicHelpEdit$ | async"></dynamic-help-editor>
<!-- Since many of the components now get the organisation and its ID from the activated state and workspaceStateService now, wait for these to become available -->
<div
    class="workspace-container fill"
    *ngIf="organisationId && currentOrganisationLoaded"
>
    <div
        *ngIf="newLayout"
        style="position: absolute; left: 50%; top: 10px; z-index: 1000"
    >
        <section-selector
            style="left: -50%; position: relative"
        ></section-selector>
    </div>
    <div style="position: absolute; right: 30px; top: 10px; z-index: 1000">
        <div dropdown>
            <button
                dropdownToggle
                class="btn btn-default"
                style="
                    display: flex;
                    align-items: center;
                    border-radius: 360px;
                    border: 1px solid #343a40;
                    background: #cacad8;
                "
                type="button"
                tooltip="User profile menu"
                placement="left"
                data-cy="userProfileDropdown"
            >
                <profile-picture
                    *ngIf="currentUser"
                    class="right-buffer"
                    style="display: inline-block"
                    [personId]="currentUser.id"
                    [managingOrganisationId]="
                        currentUser.personalOrganisationId
                    "
                    [width]="20"
                    [height]="20"
                    [shadow]="false"
                >
                </profile-picture>
                &nbsp;
                <span class="right-buffer">
                    {{
                        currentUser
                            ? currentUser.firstName + ' ' + currentUser.lastName
                            : ''
                    }}
                </span>
                <span class="caret"></span>
            </button>
            <ul
                class="dropdown-menu dropdown-menu-right"
                *dropdownMenu
                role="menu"
            >
                <li>
                    <a
                        class="nav-link"
                        (click)="showUserProfile()"
                        data-cy="userProfileSelection"
                        ><span class="fa fa-user" aria-hidden="true"></span>
                        Profile</a
                    >
                </li>
                <li *ngIf="canAdminOrganisation">
                    <a class="nav-link" (click)="showOrgAdmin()"
                        ><span class="fa fa-gear" aria-hidden="true"></span>
                        Organisation Admin</a
                    >
                </li>
                <li>
                    <a
                        class="nav-link"
                        (click)="showAllWhatsNew()"
                        angulartics2On="click"
                        angularticsCategory="help"
                        angularticsAction="whats-new-profile-menu"
                        ><span class="fa fa-gift" aria-hidden="true"></span>
                        What's New</a
                    >
                </li>
                <li class="divider dropdown-divider"></li>
                <li>
                    <a class="nav-link" (click)="logout()"
                        ><span
                            class="fa fa-right-from-bracket"
                            aria-hidden="true"
                        ></span>
                        Logout</a
                    >
                </li>
            </ul>
        </div>
    </div>
    <router-outlet></router-outlet>
    <router-outlet name="workspace"></router-outlet>
</div>
<component-bubble *ngIf="showAlternativeSupport"
    ><iframe [src]="alternativeSupportUrl" style="border: 0"></iframe
></component-bubble>
