import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatWrappedArrays' })
export class FormatColumnArrayPipe implements PipeTransform {
    transform(value: string[], length: number, ...args: any[]) {
        if (value == null) {
            return null;
        }

        const wrappedHtml = this.wrappedHtml(value, length);
        return wrappedHtml;
    }

    wrappedHtml(value: string[], length: number): string {
        if (value.length === 0) {
            return '';
        }
        if (value.length <= length) {
            return `${value.join(', ')}`;
        }
        const others = value.length - length;
        const shorter = value.slice(0, length).join(', ');
        return `${shorter}, and ${others} others.`;
    }
}
