import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CardComponent } from './card/card.component';

@NgModule({
    imports: [CommonModule, TooltipModule],
    declarations: [CardComponent],
    exports: [CardComponent]
})
export class CardModule {}
