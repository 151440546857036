import { Injectable } from '@angular/core';
import {
    AutomaticMissionConfigurationDto,
    DO_NOTHING,
    EnhancedHelpDto,
    FEATURE_FLIGHT_LOG_ALERTS,
    FlightLogCollectionService,
    FlightLogCollectionSourceDto,
    hasFeatureFlag,
    MissionService,
    MissionWorkflowService,
    OrganisationAutomaticMissionConfigurationDto,
    PersonService,
    UpdateAutomaticMissionConfigurationCommand,
    UpdateOrganisationAutomaticMissionConfigurationCommand,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { EnhancedHelpService } from 'libs/enhanced-help/src/lib/enhanced-help.service';
import { WorkGroupsService } from 'libs/flyfreely/src/lib/services/workgroups.service';
import {
    combineLatest,
    firstValueFrom,
    Observable,
    ReplaySubject,
    Subject,
    take
} from 'rxjs';
import { shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class FlightLogSettingsService {
    private ngUnsubscribe$ = new Subject<void>();

    flightLogCollectionSourceList$: Observable<FlightLogCollectionSourceDto[]>;

    private organisationIdSource = new ReplaySubject<number>(1);

    canUseAlerts = false;
    organisationId: number;

    constructor(
        private flightLogCollectionService: FlightLogCollectionService,
        private commonDialoguesService: CommonDialoguesService,
        private userService: UserService,
        private enhancedHelpService: EnhancedHelpService,
        private personService: PersonService,
        private missionService: MissionService,
        private missionWorkflowService: MissionWorkflowService,
        private workgroupsSource: WorkGroupsService
    ) {
        this.flightLogCollectionSourceList$ = combineLatest([
            this.organisationIdSource,
            this.flightLogCollectionService.change$.pipe(startWith([undefined]))
        ]).pipe(
            takeUntil(this.ngUnsubscribe$),
            switchMap(([organisationId, _]) =>
                this.flightLogCollectionService.find(organisationId)
            ),
            shareReplay(1)
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setOrganisation(organisationId: number) {
        this.organisationIdSource.next(organisationId);

        this.organisationId = organisationId;

        this.canUseAlerts = pipe(
            this.userService.findOrganisationForUser(this.organisationId),
            map(o => hasFeatureFlag(o, FEATURE_FLIGHT_LOG_ALERTS)),
            getOrElse(() => false)
        );
    }

    showAlertSettings(entry: FlightLogCollectionSourceDto) {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Update Alert Settings',
                'Save',
                true,
                true,
                [
                    {
                        key: `section-alerts`,
                        type: 'section-heading',
                        props: {
                            label: 'Alerts'
                        }
                    },
                    {
                        key: 'sevenDays',
                        type: 'checkbox',
                        props: {
                            label: 'Alert on 7 days without flight logs',
                            required: false
                        }
                    },
                    {
                        key: 'thirtyDays',
                        type: 'checkbox',
                        props: {
                            label: 'Alert on 30 days without flight logs',
                            required: false
                        }
                    },
                    {
                        key: 'sixtyDays',
                        type: 'checkbox',
                        props: {
                            label: 'Alert on 60 days without flight logs',
                            required: false
                        }
                    },
                    {
                        key: 'invalidPassword',
                        type: 'checkbox',
                        props: {
                            label: 'Alert on invalid password',
                            required: false
                        }
                    },
                    {
                        key: `section-notifications`,
                        type: 'section-heading',
                        props: {
                            label: 'Who to notify'
                        }
                    },
                    {
                        key: 'notifyOwner',
                        type: 'checkbox',
                        props: {
                            label: 'Notify organisation owner',
                            required: false
                        }
                    },
                    {
                        key: 'notifyOrganisationAdmins',
                        type: 'checkbox',
                        props: {
                            label: 'Notify organisation admins',
                            required: false
                        }
                    },
                    {
                        key: 'notifyOrganisationCrps',
                        type: 'checkbox',
                        props: {
                            label: 'Notify organisation CRPs',
                            required: false
                        }
                    }
                ],
                entry.alertSettings != null
                    ? {
                          sevenDays:
                              entry.alertSettings.daysSinceLastFile.indexOf(
                                  7
                              ) !== -1,
                          thirtyDays:
                              entry.alertSettings.daysSinceLastFile.indexOf(
                                  30
                              ) !== -1,
                          sixtyDays:
                              entry.alertSettings.daysSinceLastFile.indexOf(
                                  60
                              ) !== -1,
                          invalidPassword: entry.alertSettings.invalidPassword,
                          notifyOrganisationAdmins:
                              entry.alertSettings.notifyOrganisationAdmins,
                          notifyOrganisationCrps:
                              entry.alertSettings.notifyOrganisationCrps,
                          notifyOwner: entry.alertSettings.notifyOwner
                      }
                    : {},
                data => {
                    const daysSinceLastFile = [
                        data.sevenDays ? 7 : undefined,
                        data.thirtyDays ? 30 : undefined,
                        data.sixtyDays ? 60 : undefined
                    ].filter(e => e !== undefined);

                    return firstValueFrom(
                        this.flightLogCollectionService.updateAlertSettings(
                            entry.id,
                            {
                                daysSinceLastFile,
                                notificationRecipientList: [],
                                invalidPassword: data.invalidPassword ?? false,
                                notifyOrganisationAdmins:
                                    data.notifyOrganisationAdmins ?? false,
                                notifyOrganisationCrps:
                                    data.notifyOrganisationCrps ?? false,
                                notifyOwner: data.notifyOwner ?? false
                            }
                        )
                    );
                },
                'modal-task'
            )
            .then(DO_NOTHING)
            .catch(DO_NOTHING);
    }

    showAutomaticMissionConfigurationSettings(
        entry: FlightLogCollectionSourceDto,
        config: AutomaticMissionConfigurationDto
    ) {
        combineLatest([
            this.enhancedHelpService.findByScreen(
                'flight-log-mission-creation-settings'
            ),
            this.personService.findPersonnel(this.organisationId, 'PILOT'),
            this.missionService.findMissionTypes(this.organisationId),
            this.missionWorkflowService.findWorkflows(this.organisationId),
            this.workgroupsSource.findWorkgroups(this.organisationId),
            this.flightLogCollectionService.findOrganisationAutomaticMissionConfiguration(
                this.organisationId
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    help,
                    allPilots,
                    missionTypes,
                    workflows,
                    workgroupList,
                    organisationDefaults
                ]) => {
                    const pilots = allPilots.map(p => ({
                        name: `${p.firstName} ${p.lastName}`,
                        id: p.id
                    }));
                    const helpText =
                        help == null
                            ? {}
                            : help.reduce(
                                  (
                                      acc: { [component: string]: string },
                                      h: EnhancedHelpDto
                                  ) => ({ ...acc, [h.component]: h.helpText }),
                                  {}
                              );
                    this.commonDialoguesService
                        .showFormlyDialogue<
                            Omit<
                                UpdateAutomaticMissionConfigurationCommand,
                                'collectionSourceId'
                            >,
                            AutomaticMissionConfigurationDto
                        >(
                            'Automatic Mission Creation Settings',
                            'Save',
                            true,
                            true,
                            [
                                {
                                    key: 'type',
                                    type: 'ng-select',
                                    props: {
                                        label: 'Type (action)',
                                        required: true,
                                        description: helpText['type'],
                                        labelProp: 'name',
                                        valueProp: 'value',
                                        options: [
                                            {
                                                name: 'Organisation Default',
                                                value: 'ORGANISATION_DEFAULT'
                                            },
                                            {
                                                name: 'Suggest Mission Assignment',
                                                value: 'MISSION_SUGGESTION'
                                            },
                                            {
                                                name: 'Automatic Assignment',
                                                value: 'MISSION_ASSIGNMENT'
                                            },
                                            {
                                                name: 'Automatic Mission Creation',
                                                value: 'MISSION_CREATION'
                                            },
                                            {
                                                name: 'Disabled',
                                                value: 'DISABLED'
                                            }
                                        ]
                                    }
                                },
                                {
                                    key: `section-required`,
                                    type: 'section-heading',
                                    hideExpression:
                                        'model.type === "DISABLED" || model.type === "ORGANISATION_DEFAULT"',
                                    props: {
                                        label: 'Required parameters'
                                    }
                                },
                                {
                                    key: `section-required-info`,
                                    type: 'info',
                                    hideExpression:
                                        'model.type === "DISABLED" || model.type === "ORGANISATION_DEFAULT"',
                                    props: {
                                        message:
                                            helpText['section-required-info']
                                    }
                                },
                                {
                                    key: 'maxDistance',
                                    type: 'input',
                                    hideExpression:
                                        'model.type === "DISABLED" || model.type === "ORGANISATION_DEFAULT"',
                                    props: {
                                        label: 'Maximum distance (m)',
                                        type: 'number',
                                        required: true,
                                        description: helpText['maxDistance']
                                    }
                                },
                                {
                                    key: 'maxTimeDifference',
                                    type: 'duration',
                                    hideExpression:
                                        'model.type === "DISABLED" || model.type === "ORGANISATION_DEFAULT"',
                                    props: {
                                        label: 'Maximum time difference',
                                        required: true,
                                        description:
                                            helpText['maxTimeDifference']
                                    }
                                },
                                {
                                    key: 'requireRpa',
                                    type: 'checkbox',
                                    hideExpression:
                                        'model.type === "DISABLED" || model.type === "ORGANISATION_DEFAULT"',
                                    props: {
                                        label: 'Log requires an RPA',
                                        required: true,
                                        description: helpText['requireRpa']
                                    }
                                },
                                {
                                    key: `section-optional`,
                                    type: 'section-heading',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Optional parameters'
                                    }
                                },
                                {
                                    key: `section-optional-info`,
                                    type: 'info',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        message:
                                            helpText['section-optional-info']
                                    }
                                },
                                {
                                    key: 'useCollectionSourceOwner',
                                    type: 'checkbox',
                                    hideExpression:
                                        'model.type !== "MISSION_ASSIGNMENT" && model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Collection Source Owner is Pilot',
                                        required: true,
                                        description:
                                            helpText['useCollectionSourceOwner']
                                    }
                                },
                                {
                                    key: 'remotePilotId',
                                    type: 'ng-select',
                                    hideExpression:
                                        'model.type !== "MISSION_ASSIGNMENT" && model.type !== "MISSION_CREATION" || model.useCollectionSourceOwner === true',
                                    props: {
                                        label: 'Pilot',
                                        labelProp: 'name',
                                        valueProp: 'id',
                                        options: pilots,
                                        clearable: true,
                                        required: false,
                                        description: helpText['remotePilotId']
                                    }
                                },
                                {
                                    key: 'operationTypeId',
                                    type: 'ng-select',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Mission Type',
                                        labelProp: 'name',
                                        valueProp: 'id',
                                        options: missionTypes,
                                        clearable: true,
                                        required: false,
                                        description: helpText['operationTypeId']
                                    }
                                },
                                {
                                    key: 'missionWorkflowId',
                                    type: 'ng-select',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Workflow',
                                        labelProp: 'name',
                                        valueProp: 'id',
                                        options: workflows,
                                        clearable: true,
                                        required: false,
                                        description:
                                            helpText['missionWorkflowId']
                                    }
                                },
                                {
                                    key: 'workgroupId',
                                    type: 'ng-select',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Workgroup',
                                        labelProp: 'name',
                                        valueProp: 'id',
                                        options: workgroupList,
                                        clearable: true,
                                        required: false,
                                        description: helpText['workgroupId']
                                    }
                                },
                                {
                                    key: 'createFlightOnAssignment',
                                    type: 'checkbox',
                                    hideExpression:
                                        'model.type !== "MISSION_ASSIGNMENT"',
                                    props: {
                                        label: 'Create Flight on Assigned Mission',
                                        required: true,
                                        description:
                                            helpText['createFlightOnAssignment']
                                    }
                                }
                            ],
                            config != null
                                ? {
                                      type: config.type,
                                      maxDistance: config.maxDistance,
                                      maxTimeDifference:
                                          config.maxTimeDifference,
                                      useCollectionSourceOwner:
                                          config.useCollectionSourceOwner,
                                      remotePilotId: config.remotePilot?.id,
                                      operationTypeId: config.operationType?.id,
                                      missionWorkflowId:
                                          config.missionWorkflow?.id,
                                      workgroupId: config.workgroup?.id,
                                      requireRpa: config.requireRpa,
                                      createFlightOnAssignment:
                                          config.createFlightOnAssignment
                                  }
                                : {
                                      type: 'ORGANISATION_DEFAULT',
                                      maxDistance:
                                          organisationDefaults.maxDistance,
                                      maxTimeDifference:
                                          organisationDefaults.maxTimeDifference,
                                      requireRpa:
                                          organisationDefaults.requireRpa,
                                      createFlightOnAssignment:
                                          organisationDefaults.createFlightOnAssignment
                                  },
                            data => {
                                const command: UpdateAutomaticMissionConfigurationCommand =
                                    {
                                        type: data.type,
                                        maxDistance: data.maxDistance ?? 0,
                                        maxTimeDifference:
                                            data.maxTimeDifference ?? 0,
                                        missionWorkflowId:
                                            data.missionWorkflowId,
                                        operationTypeId: data.operationTypeId,
                                        workgroupId: data.workgroupId,
                                        useCollectionSourceOwner:
                                            data.useCollectionSourceOwner ??
                                            false,
                                        remotePilotId:
                                            data.useCollectionSourceOwner ===
                                            false
                                                ? data.remotePilotId
                                                : null,
                                        requireRpa: data.requireRpa ?? true,
                                        createFlightOnAssignment:
                                            data.createFlightOnAssignment ??
                                            false
                                    };
                                return firstValueFrom(
                                    this.flightLogCollectionService.updateAutomaticMissionConfiguration(
                                        entry.id,
                                        command
                                    )
                                );
                            },
                            'modal-task'
                        )
                        .then(DO_NOTHING)
                        .catch(DO_NOTHING);
                }
            );
    }

    showOrganisationAutomaticMissionConfiguration() {
        combineLatest([
            this.enhancedHelpService.findByScreen(
                'flight-log-collection-organisation-settings'
            ),
            this.flightLogCollectionService.findOrganisationAutomaticMissionConfiguration(
                this.organisationId
            ),
            this.missionService.findMissionTypes(this.organisationId),
            this.missionWorkflowService.findWorkflows(this.organisationId),
            this.workgroupsSource.findWorkgroups(this.organisationId)
        ])
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([help, config, missionTypes, workflows, workgroupList]) => {
                    const helpText =
                        help == null
                            ? {}
                            : help.reduce(
                                  (
                                      acc: { [component: string]: string },
                                      h: EnhancedHelpDto
                                  ) => ({ ...acc, [h.component]: h.helpText }),
                                  {}
                              );
                    this.commonDialoguesService
                        .showFormlyDialogue<
                            Omit<
                                UpdateOrganisationAutomaticMissionConfigurationCommand,
                                'organisationId'
                            >,
                            OrganisationAutomaticMissionConfigurationDto
                        >(
                            'Automatic Mission Creation Settings',
                            'Save',
                            true,
                            true,
                            [
                                {
                                    key: 'type',
                                    type: 'ng-select',
                                    props: {
                                        label: 'Type (action)',
                                        required: true,
                                        description: helpText['type'],
                                        labelProp: 'name',
                                        valueProp: 'value',
                                        options: [
                                            {
                                                name: 'Suggest Mission Assignment',
                                                value: 'MISSION_SUGGESTION'
                                            },
                                            {
                                                name: 'Automatic Assignment',
                                                value: 'MISSION_ASSIGNMENT'
                                            },
                                            {
                                                name: 'Automatic Mission Creation',
                                                value: 'MISSION_CREATION'
                                            },
                                            {
                                                name: 'Disabled',
                                                value: 'DISABLED'
                                            }
                                        ]
                                    }
                                },
                                {
                                    key: `section-required`,
                                    type: 'section-heading',
                                    hideExpression: 'model.type === "DISABLED"',
                                    props: {
                                        label: 'Required parameters'
                                    }
                                },
                                {
                                    key: `section-required-info`,
                                    type: 'info',
                                    hideExpression: 'model.type === "DISABLED"',
                                    props: {
                                        message:
                                            helpText['section-required-info']
                                    }
                                },
                                {
                                    key: 'maxDistance',
                                    type: 'input',
                                    hideExpression: 'model.type === "DISABLED"',
                                    props: {
                                        label: 'Maximum distance (m)',
                                        type: 'number',
                                        required: true,
                                        description: helpText['maxDistance']
                                    }
                                },
                                {
                                    key: 'maxTimeDifference',
                                    type: 'duration',
                                    hideExpression: 'model.type === "DISABLED"',
                                    props: {
                                        label: 'Maximum time difference',
                                        required: true,
                                        description:
                                            helpText['maxTimeDifference']
                                    }
                                },
                                {
                                    key: 'requireRpa',
                                    type: 'checkbox',
                                    hideExpression: 'model.type === "DISABLED"',
                                    props: {
                                        label: 'Log requires an RPA',
                                        required: true,
                                        description: helpText['requireRpa']
                                    }
                                },
                                {
                                    key: `section-optional`,
                                    type: 'section-heading',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Optional parameters'
                                    }
                                },
                                {
                                    key: `section-optional-info`,
                                    type: 'info',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        message:
                                            helpText['section-optional-info']
                                    }
                                },
                                {
                                    key: 'useCollectionSourceOwner',
                                    type: 'checkbox',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Collection Source Owner is RPIC',
                                        required: true,
                                        description:
                                            helpText['useCollectionSourceOwner']
                                    }
                                },
                                {
                                    key: 'operationTypeId',
                                    type: 'ng-select',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Mission Type',
                                        labelProp: 'name',
                                        valueProp: 'id',
                                        options: missionTypes,
                                        clearable: true,
                                        required: false,
                                        description: helpText['operationTypeId']
                                    }
                                },
                                {
                                    key: 'missionWorkflowId',
                                    type: 'ng-select',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Workflow',
                                        labelProp: 'name',
                                        valueProp: 'id',
                                        options: workflows,
                                        clearable: true,
                                        required: false,
                                        description:
                                            helpText['missionWorkflowId']
                                    }
                                },

                                {
                                    key: 'workgroupId',
                                    type: 'ng-select',
                                    hideExpression:
                                        'model.type !== "MISSION_CREATION"',
                                    props: {
                                        label: 'Workgroup',
                                        labelProp: 'name',
                                        valueProp: 'id',
                                        options: workgroupList,
                                        clearable: true,
                                        required: false,
                                        description: helpText['workgroupId']
                                    }
                                },
                                {
                                    key: 'createFlightOnAssignment',
                                    type: 'checkbox',
                                    hideExpression:
                                        'model.type !== "MISSION_ASSIGNMENT"',
                                    props: {
                                        label: 'Create Flight on Assigned Mission',
                                        required: true,
                                        description:
                                            helpText['createFlightOnAssignment']
                                    }
                                }
                            ],
                            config != null
                                ? {
                                      type: config.type,
                                      maxDistance: config.maxDistance,
                                      maxTimeDifference:
                                          config.maxTimeDifference,
                                      useCollectionSourceOwner:
                                          config.useCollectionSourceOwner,
                                      operationTypeId: config.operationType?.id,
                                      missionWorkflowId:
                                          config.missionWorkflow?.id,
                                      requireRpa: config.requireRpa,
                                      createFlightOnAssignment:
                                          config.createFlightOnAssignment
                                  }
                                : {
                                      type: 'ORGANISATION_DEFAULT',
                                      requireRpa: true
                                  },
                            data => {
                                const command: UpdateOrganisationAutomaticMissionConfigurationCommand =
                                    {
                                        organisationId: this.organisationId,
                                        type: data.type,
                                        maxDistance: data.maxDistance ?? 0,
                                        maxTimeDifference:
                                            data.maxTimeDifference ?? 0,
                                        missionWorkflowId:
                                            data.missionWorkflowId,
                                        operationTypeId: data.operationTypeId,
                                        useCollectionSourceOwner:
                                            data.useCollectionSourceOwner ??
                                            true,
                                        requireRpa: data.requireRpa ?? true,
                                        createFlightOnAssignment:
                                            data.createFlightOnAssignment ??
                                            false
                                    };
                                return firstValueFrom(
                                    this.flightLogCollectionService.updateOrganisationAutomaticMissionConfiguration(
                                        command
                                    )
                                );
                            },
                            'modal-task'
                        )
                        .then(DO_NOTHING)
                        .catch(DO_NOTHING);
                }
            );
    }
}
