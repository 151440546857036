import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AcceptInviteCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InviteDto,
    InvitesService,
    PersonService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pending-invites',
    templateUrl: './pending-invites.component.html'
})
export class PendingInvites {
    invites: InviteDto[];
    roleLookup: { [inviteId: number]: string };
    userRoles: { [role: string]: string };

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working = false;

    constructor(
        private invitesService: InvitesService,
        private router: Router,
        private userService: UserService,
        private logging: FlyFreelyLoggingService,
        private screenAnalytics: ScreenAnalyticsDirective,
        personService: PersonService,
        private commonDialoguesService: CommonDialoguesService,
        private activatedRoute: ActivatedRoute
    ) {
        this.userRoles = personService.getPersonRoles();
    }

    ngOnInit() {
        this.invitesService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => this.findInvites());

        this.findInvites();
    }

    findInvites() {
        this.invitesService
            .findInvitesForPerson()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                invites => {
                    this.invites = invites;
                    this.roleLookup = this.invites.reduce(
                        (acc, o) => ({
                            ...acc,
                            [o.id]: this.roleDisplayName(o.roles)
                        }),
                        {} as { [inviteId: number]: string }
                    );
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error retrieving person's invites: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    roleDisplayName(roles: InviteDto.Roles[]) {
        const rolesString =
            roles != null
                ? roles
                      .map((role: InviteDto.Roles) => this.userRoles[role])
                      .join(',  ')
                : null;
        return rolesString;
    }

    acceptInvite(invite: InviteDto) {
        const doneWorking = this.workTracker.createTracker();

        this.commonDialoguesService
            .showFormlyDialogue(
                'Accept Invitation',
                'Accept',
                true,
                true,
                [
                    {
                        key: 'allowManaging',
                        type: 'checkbox',
                        defaultValue: false,
                        props: {
                            label: 'Allow Organisation to manage you'
                        }
                    },
                    {
                        key: 'defaultOrganisation',
                        type: 'checkbox',
                        defaultValue: false,
                        props: {
                            label: 'Set Organisation as your default organisation'
                        }
                    }
                ],
                { allowManaging: undefined, defaultOrganisation: undefined },
                (data: AcceptInviteCommand) =>
                    this.invitesService
                        .acceptInvite(invite.id, data)
                        .toPromise()
            )
            .then(result => {
                this.screenAnalytics.track('invite', 'existing-accept');
                this.logging.success(`Successfully accepted invitation`);
                this.userService.refreshUsersOrganisations().add(() =>
                    this.router.navigate(['home'], {
                        queryParamsHandling: 'merge',
                        queryParams: {
                            organisationId: invite.organisationId
                        }
                    })
                );
                doneWorking();
            })
            .catch(() => doneWorking());
    }

    ignoreInvite(invite: InviteDto) {
        this.screenAnalytics.track('invite', 'existing-ignore');

        this.invitesService
            .ignoreInvite(invite.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.logging.success(`Invitation ignored`);
                    this.userService.refreshUsersOrganisations();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error accepting invite: ${error.message}`
                    )
            );
    }
}
