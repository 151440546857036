import { Injectable } from '@angular/core';
import {
    BulkEditOptions,
    BulkImportErrorText,
    ColumnOptions,
    CreateEquipmentCommand,
    CreatePersonOrganisationCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InviteDto,
    InvitesService,
    PersonDto,
    PersonRolesDto,
    PersonService,
    WorkTracker,
    checkRequiredValidity,
    downloadCsv,
    parseCsvByHeadings,
    removeEmptyRows
} from '@flyfreely-portal-ui/flyfreely';
import { OrganisationSubscriptionsService } from '@flyfreely-portal-ui/workspace';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CHECK_EMAIL } from 'libs/bulk-uploads/src/lib/checks';
import { invalidFieldText } from 'libs/bulk-uploads/src/lib/helpers';
import { BulkImportDialogue } from 'libs/bulk-uploads/src/lib/import-dialogue/import-dialogue.component';
import { BulkUploadDialogue } from 'libs/bulk-uploads/src/lib/upload-dialogue/upload-dialogue.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { isPersonalOrganisationSubscription } from 'libs/subscriptions/src/lib/helpers';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Columns } from 'ngx-easy-table';
import { Observable, ReplaySubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ImportPerson {
    firstName: string;
    lastName: string;
    email: string;
    arn: string;
    phoneNumber: string;
}

const template: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            {
                key: 'firstName',
                props: {
                    label: 'First Name'
                }
            },
            {
                key: 'lastName',
                props: {
                    label: 'Last Name'
                }
            },
            {
                key: 'email',
                props: {
                    label: 'Email'
                }
            },
            {
                key: 'arn',
                props: {
                    label: 'ARN'
                }
            },
            {
                key: 'phoneNumber',
                props: {
                    label: 'Phone Number'
                }
            }
        ]
    },
    props: {
        label: 'Bulk_Personnel_Import'
    }
};

const templateData = [
    {
        firstName: 'John',
        lastName: 'Doe',
        email: 'john.doe@isp.com',
        arn: '1234567',
        phoneNumber: '0412 345 678'
    }
];

const columns: Columns[] = [
    { key: 'firstName', title: 'First Name' },
    { key: 'lastName', title: 'Last Name' },
    { key: 'email', title: 'Email' },
    { key: 'arn', title: 'ARN' },
    { key: 'phoneNumber', title: 'Phone Number' }
];

@Injectable()
export class PersonUploadService {
    organisationId: number;
    existingPersonnel: PersonRolesDto[];
    existingInvitees: InviteDto[];

    availableLicences: number;

    uploadDialogue: BsModalRef<BulkUploadDialogue>;
    importDialogue: BsModalRef<BulkImportDialogue>;

    template: CreateEquipmentCommand;
    importedFile: ImportPerson[];
    failedImportText: BulkImportErrorText[] = [];

    workingSubject = new ReplaySubject<boolean>();
    doneImportingSubject = new Subject<void>();
    columnOptionsSubject = new ReplaySubject<ColumnOptions[]>();
    failedImportTextSubject = new ReplaySubject<BulkImportErrorText[]>();
    bulkEditOptionsSubject = new ReplaySubject<BulkEditOptions>();
    bulkEditOptions$ = this.bulkEditOptionsSubject.asObservable();
    columnOptions$ = this.columnOptionsSubject.asObservable();
    failedImportText$ = this.failedImportTextSubject.asObservable();
    doneImporting$ = this.doneImportingSubject.asObservable();
    working$ = this.workingSubject.asObservable();
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private personService: PersonService,
        private organisationSubscriptionsService: OrganisationSubscriptionsService,
        private invitesService: InvitesService,
        private modalService: BsModalService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSubject.next(working));
        this.columnOptionsSubject.next(null);
    }

    ngOnDestroy() {
        this.doneImportingSubject.complete();
        this.workingSubject.complete();
        this.columnOptionsSubject.complete();
        this.failedImportTextSubject.complete();
        this.bulkEditOptionsSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    showBulkUpload(organisationId: number) {
        this.organisationId = organisationId;
        this.uploadDialogue = this.modalService.show(BulkUploadDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                uploadService: this,
                hasTemplate: true,
                entity: 'Personnel'
            }
        });

        forkJoin([
            this.personService.findPersonnel(this.organisationId),
            this.invitesService.findInvites(this.organisationId)
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([personnel, invitees]) => {
                this.existingPersonnel = personnel;
                this.existingInvitees = invitees;
            })
            .add(this.workTracker.createTracker());

        this.organisationSubscriptionsService.currentSubscriptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                licences =>
                    (this.availableLicences =
                        !isPersonalOrganisationSubscription(licences)
                            ? licences.availablePilots ??
                              licences.availablePersonnel ??
                              null
                            : null)
            );
    }

    importFile(file: File) {
        parseCsvByHeadings<ImportPerson>(file, {
            'First Name': 'firstName',
            'Last Name': 'lastName',
            Email: 'email',
            ARN: 'arn',
            'Phone Number': 'phoneNumber'
        }).then(result => {
            // Sometimes the csv parser doesn't filter out empty rows.
            // The filtered variable is a redundancy check to ensure empty rows are ignored.
            const filtered = removeEmptyRows<ImportPerson[]>(result);
            this.importedFile = filtered.map(r => {
                return {
                    ...r,
                    firstName: checkRequiredValidity(r.firstName),
                    lastName: checkRequiredValidity(r.lastName),
                    email: checkRequiredValidity(r.email),
                    arn: checkRequiredValidity(r.arn)
                };
            });
            this.importedFile.forEach((row, i) => this.validateRow(row, i));
            this.showImportDialogue();
            this.uploadDialogue.hide();
        });
    }

    validateRow(row: ImportPerson, rowIndex: number) {
        // Validate each row and add ' *INVALID*' markers to any invalid
        if (row.email !== 'INVALID' && row.email.match(CHECK_EMAIL) == null) {
            this.updateErrorText(
                rowIndex,
                'email',
                `Please enter a valid email address`
            );
            row.email = `${row.email} ${invalidFieldText}`;
        }
        if (
            row.email !== 'INVALID' &&
            this.existingPersonnel.findIndex(p => row.email === p.email) !== -1
        ) {
            this.updateErrorText(
                rowIndex,
                'email',
                `${row.firstName} ${row.lastName} already exists in this organisation.`
            );
            this.updateErrorText(
                rowIndex,
                'firstName',
                `${row.firstName} ${row.lastName} already exists in this organisation.`
            );
            this.updateErrorText(
                rowIndex,
                'lastName',
                `${row.firstName} ${row.lastName} already exists in this organisation.`
            );
            row.email = `${row.email} ${invalidFieldText}`;
            row.firstName =
                row.firstName === 'INVALID'
                    ? 'INVALID'
                    : `${row.firstName} ${invalidFieldText}`;
            row.lastName =
                row.lastName === 'INVALID'
                    ? 'INVALID'
                    : `${row.lastName} ${invalidFieldText}`;
        }
        this.failedImportTextSubject.next(this.failedImportText);

        return row;
    }

    showImportDialogue() {
        this.importDialogue = this.modalService.show(BulkImportDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                uploadService: this,
                data: this.importedFile,
                columns: columns,
                entity: 'Personnel'
            }
        });
    }

    import(selected: number[]) {
        if (
            this.availableLicences != null &&
            selected.length > this.availableLicences
        ) {
            this.logging.warn(
                `Due to your organisation's licence limits, only ${this.availableLicences} imports are allowed.`
            );
            return;
        }
        const data = selected.map(n => this.importedFile[n]);
        /**
         * Validity checks with individual feedback for clarity.
         */
        const failed: number[] = [];
        data.map((e, i) => {
            if (Object.keys(e).filter(key => e[key] === 'INVALID').length > 0) {
                failed.push(i);
            }
        });

        data.forEach((entry, i) => {
            const rowIndex = this.importedFile.findIndex(e => e === entry);
            if (
                Object.keys(entry).filter(key => entry[key] === 'INVALID')
                    .length > 0
            ) {
                return;
            }
            if (
                this.existingPersonnel.findIndex(
                    p => entry.email === p.email
                ) !== -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'email',
                    `${entry.firstName} ${entry.lastName} already exists in this organisation.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
        });

        const validEntries = data.filter(
            (e, i) => failed.includes(i) === false
        );

        if (validEntries.length > 0) {
            const command: CreatePersonOrganisationCommand[] = validEntries.map(
                entry =>
                    ({
                        ...entry,
                        sendNotification: true,
                        organisationId: this.organisationId,
                        roles: [
                            CreatePersonOrganisationCommand.Roles.PILOT,
                            CreatePersonOrganisationCommand.Roles.PILOT_PLANNER,
                            CreatePersonOrganisationCommand.Roles
                                .PILOT_CREW_PLANNER,
                            CreatePersonOrganisationCommand.Roles
                                .PILOT_SUBMITTER
                        ]
                    } as CreatePersonOrganisationCommand)
            );

            let completed: boolean = true;
            forkJoin(
                <Observable<PersonDto>[]>(
                    command.reduce(
                        (acc, c) =>
                            acc.concat(this.personService.createPerson(c)),
                        []
                    )
                )
            )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: () => {
                        if (!completed) {
                            this.importDialogue.content.onFailedRows();
                            this.logging.warn(
                                'Some imported rows have failed, please fix all errors and try again'
                            );
                            this.failedImportTextSubject.next(
                                this.failedImportText
                            );
                            this.failedImportText = [];
                            return;
                        } else {
                            this.logging.success(
                                `Successfully imported ${command.length} ${
                                    command.length === 1 ? 'person' : 'people'
                                }.`
                            );
                            if (failed.length > 0) {
                                this.importDialogue.content.importedRowIndices =
                                    this.importedFile.map((row, i) => {
                                        if (validEntries.includes(row)) {
                                            return i;
                                        }
                                    });
                                this.importedFile = this.importedFile.filter(
                                    e => validEntries.includes(e) === false
                                );
                                this.importDialogue.content.onFailedRows();
                                this.logging.warn(
                                    'Some imported rows have failed, please fix all errors and try again'
                                );
                                this.failedImportTextSubject.next(
                                    this.failedImportText
                                );
                                this.failedImportText = [];
                                this.revokeExistingInvites(command, true);
                            } else {
                                this.revokeExistingInvites(command, false);
                            }
                        }
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error creating personnel: ${error.message}`
                        );
                        completed = false;
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            if (failed.length > 0) {
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
            }
        }
    }

    revokeExistingInvites(
        entries: CreatePersonOrganisationCommand[],
        failed: boolean
    ) {
        const inviteesToRetract = this.existingInvitees.filter(
            i =>
                i.recipientEmail != null &&
                entries.find(e => e.email === i.recipientEmail) != null
        );
        forkJoin(
            inviteesToRetract.reduce(
                (acc, i) =>
                    acc.concat(this.invitesService.retractInvite(i.id, i)),
                []
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    if (failed) {
                        return;
                    }
                    this.importDialogue.hide();
                    this.doneImportingSubject.next();
                },
                (error: FlyFreelyError) => {
                    this.logging.warn(
                        'Some imported personnel may still have pending invites, please check these in the personnel widget once importing is complete'
                    );
                    if (failed) {
                        return;
                    }
                    this.importDialogue.hide();
                    this.doneImportingSubject.next();
                }
            )
            .add(this.workTracker.createTracker());
    }

    downloadTemplate() {
        downloadCsv(template, templateData);
    }

    updateErrorText(rowIndex: number, key: string, text: string) {
        if (
            this.failedImportText.findIndex(
                t => t.key === key && t.rowIndex === rowIndex
            ) !== -1
        ) {
            return;
        } else {
            this.failedImportText.push({
                rowIndex: rowIndex,
                key: key,
                text: text
            });
        }
    }
}
