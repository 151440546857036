<div class="container-fluid top-buffer">
    <ng-container
        *ngIf="isMaintenanceController && overallStatus == 'SUBMITTED_ACTIVITY'"
    >
        <h5 class="section-heading">Flight Testing</h5>
        <div [formGroup]="form">
            <div formGroupName="flightTestGroup">
                <div class="form-group input-content">
                    <div class="col-xs-3 text-right">
                        <label class="display-label">
                            Test Flight required
                            <field-validation-indicator
                                [control]="
                                    flightTestGroup.controls.flightTestRequired
                                "
                                [noun]="'field'"
                                [placement]="'right'"
                            ></field-validation-indicator>
                        </label>
                    </div>
                    <div class="col-xs-6">
                        <div
                            *ngIf="testFlights.length == 0"
                            class="input-group"
                            btnRadioGroup
                            formControlName="flightTestRequired"
                            style="padding-top: 7px"
                        >
                            <label
                                class="btn btn-xs btn-pill"
                                [btnRadio]="true"
                                [disabled]="working"
                                data-cy="maintenanceControllerTestFlightTrue"
                                >Yes</label
                            >
                            <label
                                class="btn btn-xs btn-pill"
                                [btnRadio]="false"
                                [disabled]="working"
                                data-cy="maintenanceControllerTestFlightFalse"
                                >No</label
                            >
                        </div>
                        <div *ngIf="testFlights.length > 0" class="input-group">
                            <label>Yes</label>
                        </div>
                    </div>
                    <div class="col-xs-3"></div>
                    <enhanced-help componentName="flightTestRequired">
                    </enhanced-help>
                </div>
                <div
                    class="form-group input-content"
                    *ngIf="
                        form.controls.flightTestGroup.value.flightTestRequired
                    "
                >
                    <div class="col-xs-3 text-right">Test Flights</div>
                    <div class="col-xs-9">
                        <static-table
                            [availableColumns]="availableColumns"
                            [selectedColumns]="selectedColumns"
                            [tableData]="testFlights"
                            [tableConfig]="tableConfig"
                        >
                        </static-table>

                        <button
                            class="btn btn-default pull-right relative"
                            type="button"
                            (click)="showCreateTestFlight()"
                            [disabled]="
                                working ||
                                !form.controls.flightTestGroup.valid ||
                                !noOutstandingFlightTest
                            "
                            data-cy="createTestFlight"
                        >
                            <div class="circle">
                                <span class="fa fa-plus"></span>
                            </div>
                            Create Test Flight
                            <span
                                class="overlay"
                                [tooltip]="
                                    noOutstandingFlightTest
                                        ? undefined
                                        : 'Complete the existing test flight first'
                                "
                                placement="top"
                            ></span>
                        </button>
                        <button
                            class="btn btn-default pull-right relative"
                            type="button"
                            (click)="showLinkTestFlight()"
                            [disabled]="
                                working ||
                                !form.controls.flightTestGroup.valid ||
                                !noOutstandingFlightTest
                            "
                            data-cy="createTestFlight"
                        >
                            <div class="circle">
                                <span class="fa fa-link"></span>
                            </div>
                            Link Existing Test Flight
                            <span
                                class="overlay"
                                [tooltip]="
                                    noOutstandingFlightTest
                                        ? undefined
                                        : 'Complete the existing test flight first'
                                "
                                placement="top"
                            ></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activity.status == 'COMPLETED'">
        <h5 class="section-heading">Test Flights</h5>
        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <div class="display-label">Test Flights Completed</div>
            </div>
            <div class="col-xs-6">
                {{ activity.flightTestRequired | formatBoolean  | defaultTo }}
            </div>
            <div class="col-xs-3"></div>
        </div>
        <div class="form-group input-content" *ngIf="testFlights.length > 0">
            <div class="col-xs-1"></div>
            <div class="col-xs-11">
                <static-table
                    [availableColumns]="availableColumns"
                    [selectedColumns]="selectedColumns"
                    [tableData]="testFlights"
                    [tableConfig]="tableConfig"
                >
                </static-table>
            </div>
        </div>
    </ng-container>
</div>
