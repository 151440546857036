import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CompleteSortieCommand } from '@flyfreely-portal-ui/flyfreely';
import {
    DisplayableResource,
    MissionCompletionService
} from '../../mission-completion.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissionRecordService } from '../../../mission-record-edit/mission-record.service';

@Component({
    selector: 'assign-to-flight-dialogue',
    template: `
        <div class="input-group" style="min-width: 250px;">
            <ng-select
                [items]="allFlights"
                [(ngModel)]="selectedFlight"
                bindValue="id"
                (click)="$event.stopPropagation()"
            >
                <ng-template ng-option-tmp let-item="item">
                    {{ getFlightLabel(item) }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    {{ getFlightLabel(item) }}
                </ng-template>
            </ng-select>
            <button
                type="button"
                class="btn btn-primary pull-right top-buffer"
                (click)="assignToFlight.emit(selectedFlight)"
                [disabled]="selectedFlight == null"
            >
                Assign
            </button>
        </div>
    `
})
export class AssignToFlightDialogue {
    @Input() historicalMission: boolean;
    @Output() assignToFlight = new EventEmitter<number>();

    allFlights: CompleteSortieCommand[] = [];
    selectedFlight: number;

    availablePersonnel: DisplayableResource[] = [];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionCompletionService: MissionCompletionService,
        private missionRecordService: MissionRecordService
    ) {}

    ngOnInit() {
        this.refreshLists();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshLists() {
        if (this.historicalMission) {
            this.allFlights =
                this.missionRecordService.form.controls.sorties.value ?? [];

            this.missionRecordService.form.controls.sorties.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(flights => (this.allFlights = flights));

            this.missionRecordService.availablePersonnel$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(personnel => (this.availablePersonnel = personnel));
        } else {
            this.allFlights =
                this.missionCompletionService.form.controls.flights.value ?? [];

            this.missionCompletionService.form.controls.flights.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(flights => (this.allFlights = flights));

            this.missionCompletionService.availablePersonnel$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(personnel => (this.availablePersonnel = personnel));
        }
    }

    getFlightLabel(flight: CompleteSortieCommand) {
        const flightNumber = `Flight ${flight.number}`;
        const pilotDetails =
            flight.pilotId != null
                ? `, RP - ${
                      this.availablePersonnel.find(p => p.id === flight.pilotId)
                          .name
                  }`
                : '';
        return flightNumber + pilotDetails;
    }
}
