import {
    ConcreteMappedEntityConcreteFormDto,
    ContactWithLocationDto,
    defaultBuildFormAttachmentUrl,
    DisplayableMissionDto,
    httpParamSerializer,
    MissionCrewDetailsDto,
    NotamDto,
    PersonDto,
    RequestersMissionApprovalDto,
    RequiringEntity,
    SimpleOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import { NOTAM_ENTITIES } from '@flyfreely-portal-ui/ui';
import { circle } from '@turf/circle';
import { FeatureCollection } from 'geojson';
import { toSimpleAuthority } from 'libs/authorities/src/lib/helpers';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import moment from 'moment-timezone';
import { MissionApprovalWithAuthority } from './interfaces';
import { PersonWithRegisterStatus } from './mission-edit-v2/mission-edit.service';

export function approvalRequiringEntities(
    approval: RequestersMissionApprovalDto
): RequiringEntity[] {
    if (
        approval == null ||
        approval.status === RequestersMissionApprovalDto.Status.CANCELLED ||
        approval.status === RequestersMissionApprovalDto.Status.REJECTED
    ) {
        return [];
    }
    return [
        {
            requiringEntityType: 'MissionApproval',
            requiringEntityId: approval.id
        }
    ];
}

export interface ApprovalScreenStatus {
    readOnly: boolean;
    isPending: boolean;
    isBeingReviewed: boolean;
    canAttachFiles: boolean;
}

/**
 * Find the RPIC in the crew list
 * @param missionCrew the crew list
 */
export function findRpic(
    missionCrew: MissionCrewDetailsDto[]
): PersonDto | undefined {
    return missionCrew
        .filter(c => c.role.coreRole === 'PILOT_IN_COMMAND')
        .map(c => c.person)[0];
}

/**
 * Derive the mission jurisdiction.
 * @param mission the full mission object
 */
export function findMissionAirspaceJurisdictionId(
    mission: DisplayableMissionDto
) {
    return mission.missionWorkflowVersion?.delegatedAuthority?.authorityType
        ?.jurisdiction != null
        ? mission.missionWorkflowVersion.delegatedAuthority.authorityType
              .jurisdiction.id
        : mission.location?.airspaceJurisdiction?.id;
}

export const missionRegisterStatusOrder = [
    'ACTIVE',
    'PENDING',
    'EXPIRED',
    'SUSPENDED',
    'NOT_ON_REGISTER',
    'NOT_AVAILABLE'
];

export function sortMissionRegisterStatus(
    a: PersonWithRegisterStatus,
    b: PersonWithRegisterStatus
) {
    if (
        !(
            missionRegisterStatusOrder.includes(a.registerStatus) &&
            missionRegisterStatusOrder.includes(b.registerStatus)
        )
    ) {
        return +1;
    } else if (
        missionRegisterStatusOrder.findIndex(s => s === a.registerStatus) <
        missionRegisterStatusOrder.findIndex(s => s === b.registerStatus)
    ) {
        return -1;
    } else if (
        missionRegisterStatusOrder.findIndex(s => s === a.registerStatus) >
        missionRegisterStatusOrder.findIndex(s => s === b.registerStatus)
    ) {
        return +1;
    } else {
        return 1;
    }
}

export function aerodromesToFeatureGroups(
    aerodromes: ContactWithLocationDto[],
    featureGroupId = 0
): FeatureGroup[] {
    return aerodromes != null
        ? [
              {
                  canAdd: false,
                  id: featureGroupId,
                  name: 'Aerodromes',
                  type: 'Point',
                  existingFeatures: aerodromes.map(a => ({
                      name: a.name,
                      id: `aerodrome-${a.identifier}`,
                      geom: a.location
                  }))
              }
          ]
        : [];
}

export function notamToFeatureCollection(
    notamList: NotamDto[]
): FeatureCollection | null {
    return notamList != null && notamList.length > 0
        ? {
              type: 'FeatureCollection',
              features: notamList.map(a => ({
                  type: 'Feature',
                  geometry:
                      a.geometry != null && a.geometry.type === 'Polygon'
                          ? a.geometry
                          : circle(a.position, a.radius * 1.852).geometry,
                  properties: {
                      id: `notam-${a.id}`,
                      notam_id: a.id,
                      notam_text: a.message,
                      notam_entity: NOTAM_ENTITIES[a.entity] ?? ''
                  }
              }))
          }
        : null;
}

/**
 * Make a form attachement URL resolver for the given form, which
 * may use the approval's authorities, or may use the default resolver.
 *
 * @param form the form to resovle the URLs for
 * @param missionApprovalList the list of approvals
 * @returns undefined (to use the default resolver) or a function to build the URL
 */
export function formAttachmentUrlFactory(
    form: ConcreteMappedEntityConcreteFormDto,
    missionApprovalList: RequestersMissionApprovalDto[] | undefined
): undefined | typeof defaultBuildFormAttachmentUrl {
    if (
        form.requiringEntityType !== 'MissionApproval' ||
        missionApprovalList == null
    ) {
        return undefined;
    }

    const approval = missionApprovalList.find(
        a => a.id === form.requiringEntityId
    );
    if (approval == null) {
        return undefined;
    }

    return (
        managingOrganisationId: number,
        formId: number,
        attachmentId: number,
        attachmentVersionId: number
    ) => {
        const params = httpParamSerializer({
            attachmentVersionId,
            managingOrganisationId
        }).toString();
        return `/webapi/organisationAuthorities/${approval.authorityId}/workflow/${approval.authorityWorkflowVersionId}/forms/${formId}/attachments/${attachmentId}?${params}`;
    };
}

/**
 * Takes a mission approval and converts the authority on the approval to a simple authority.
 * This is mainly used in the templates to convert the approval to a suitable format fot the formatting pipes.
 * @param approval a MissionApprovalWithAuthority object
 * @returns a SimpleAuthority object for the given approval.
 */
export function getSimplifiedAuthorityFromApproval(
    approval: MissionApprovalWithAuthority,
    organisation: SimpleOrganisationDto
) {
    if (
        approval == null ||
        approval.authority == null ||
        approval.authorityType == null
    ) {
        return;
    }
    return toSimpleAuthority(
        approval.authority,
        organisation,
        approval.authorityType
    );
}

export function findActiveApprovals(
    approvals: RequestersMissionApprovalDto[],
    includeDrafts = false
) {
    if (approvals == null || approvals.length === 0) {
        return [];
    }
    const status = RequestersMissionApprovalDto.Status;
    const activeStatuses = [
        status.APPROVED,
        status.ACCEPTED,
        status.PENDING,
        status.BEING_REVIEWED
    ];
    if (includeDrafts) {
        activeStatuses.push(status.DRAFT);
    }
    return approvals.filter(a => activeStatuses.includes(a.status));
}

export function findUnactionedApprovals(
    approvals: RequestersMissionApprovalDto[]
) {
    const status = RequestersMissionApprovalDto.Status;
    const unactionedStatuses = [status.PENDING, status.BEING_REVIEWED];
    return approvals.filter(a => unactionedStatuses.includes(a.status));
}

export function findActiveMissionApprovals(
    approvals: MissionApprovalWithAuthority[],
    includeDrafts = false
) {
    const status = RequestersMissionApprovalDto.Status;
    const activeStatuses = [
        status.APPROVED,
        status.ACCEPTED,
        status.PENDING,
        status.BEING_REVIEWED
    ];
    if (includeDrafts) {
        activeStatuses.push(status.DRAFT);
    }
    return approvals.filter(a => activeStatuses.includes(a.approval.status));
}

/**
 * getMonthRange
 * @param timestamp For example: "2024-12-27T23:45:23.864Z"
 * @returns Returns an object containing the start and end timestamps (in UTC) of the current month (ISO string format).
 * /*
 * Test Example:
 * const testInput = "2024-12-27T23:45:23.864Z";
 * const result = getMonthRange(testInput);
 *
 * Example Result:
 * {
 *   startOfMonth: "2024-12-01T00:00:00.000Z",
 *   endOfMonth:   "2024-12-31T23:59:59.999Z"
 * }
 * */
export function getMonthRange(timestamp: string) {
    const dateMoment = moment.utc(timestamp);

    const startOfMonth = dateMoment.clone().startOf('month').toISOString();
    const endOfMonth = dateMoment.clone().endOf('month').toISOString();

    return {
        startOfMonth,
        endOfMonth
    };
}
