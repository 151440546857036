import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GqlQueryResult, Pagination } from '..';
import { FlyFreelyConstants } from '../../constants';
import {
    AdminPersonDetailsDto,
    CreatePersonOrganisationCommand,
    EmailEventDto,
    PersonDto,
    PersonRolesDto,
    PersonsOrganisationDto,
    PersonStatusDto,
    SimpleOrganisationDto,
    UpdatePersonnelRolesCommand,
    UpdatePersonOrganisationCommand,
    UpdateSystemAdminRolesCommand
} from '../../model/api';

export const PERSON_ROLES: { [role in PersonRolesDto.Roles]: string } = {
    STUDENT: 'Student',
    PLANNER: 'Planner',
    ADMIN: 'Organisation Administrator',
    BILLING_ADMIN: 'Billing Admin',
    PILOT: 'Pilot',
    PILOT_PLANNER: 'Pilot (planning permission)',
    PILOT_SUBMITTER: 'Pilot (submit permission)',
    PILOT_CREW_PLANNER: 'Pilot (able to add crew members)',
    PILOT_INSTANT_MISSION: 'Instant Mission Only Pilot',
    MAINTENANCE_CONTROLLER: 'Maintenance Controller',
    MAINTENANCE_PERSON: 'Maintenance Person',
    MAINTENANCE_PERSON_EXTERNAL: 'External Maintenance Person',
    RCP: 'Chief Remote Pilot',
    OBSERVER: 'Observer',
    READ_ONLY: 'Read Only',
    READ_ONLY_HIDDEN: 'Read Only For Demo (Hidden)',
    FLIGHT_LOG_COLLECTION_ORGANISATION: 'Manage Organisations Flight Logs',
    FLIGHT_LOG_COLLECTION_OWN: 'Manage Own Flight Log Collection'
};

@Injectable({
    providedIn: 'root'
})
export class AdminPersonnelService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(
        pagination: Pagination,
        personType: AdminPersonDetailsDto.Type,
        search?: string
    ) {
        return this.apollo
            .query<{
                findAdminPersonnel: GqlQueryResult<PersonStatusDto>;
            }>({
                query: gql`
                    query find(
                        $page: Int
                        $pageSize: Int
                        $sortFields: [GqlSortField]
                        $filters: [GqlFilterField]
                        $personType: PersonType
                        $search: String
                    ) {
                        findAdminPersonnel(
                            page: $page
                            pageSize: $pageSize
                            sortFields: $sortFields
                            filters: $filters
                            personType: $personType
                            search: $search
                        ) {
                            count
                            results {
                                id
                                firstName
                                lastName
                                email
                                phoneNumber
                                type
                                personalOrganisationId
                                active
                                onboardingCompleted
                                firstMissionReady
                                firstMissionCompleted
                            }
                        }
                    }
                `,
                variables: {
                    ...pagination,
                    personType,
                    search
                }
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findAdminPersonnel)
            );
    }

    createPerson(person: CreatePersonOrganisationCommand) {
        return this.http
            .post<PersonDto>(`${this.baseUrl}/adminapi/personnel`, person)
            .pipe(tap(() => this.changeSource.next()));
    }

    findPerson(personId: number) {
        return this.http.get<AdminPersonDetailsDto>(
            `${this.baseUrl}/adminapi/personnel/${personId}`
        );
    }

    updatePerson(personId: number, person: UpdatePersonOrganisationCommand) {
        return this.http
            .put<PersonDto>(
                `${this.baseUrl}/adminapi/personnel/${personId}`,
                person
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateRoles(
        personId: number,
        personOrganisation: UpdatePersonnelRolesCommand
    ) {
        return this.http
            .put<PersonRolesDto>(
                `${this.baseUrl}/adminapi/personnel/${personId}/roles`,
                personOrganisation
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findPersonInOrganisation(personId: number, organisationId: number) {
        if (!personId || !organisationId) {
            return null;
        }
        return this.http.get<PersonRolesDto>(
            `${this.baseUrl}/adminapi/personnel/${personId}/roles`,
            {
                params: new HttpParams().set(
                    'organisation',
                    organisationId.toString()
                )
            }
        );
    }

    findPersonsOrganisations(personId: number) {
        return this.http.get<PersonsOrganisationDto[]>(
            `${this.baseUrl}/adminapi/personnel/${personId}/organisations`
        );
    }

    updateDefaultOrganisation(personId: number, organisationId: number) {
        return this.http.put(
            `${this.baseUrl}/adminapi/personnel/${personId}/organisations/defaultOrganisation`,
            {
                organisationId
            }
        );
    }

    requestPasswordReset(personId: number) {
        return this.http
            .put(
                `${this.baseUrl}/adminapi/personnel/${personId}/resetlink`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findManagingOrganisations(personId: number) {
        return this.http.get<SimpleOrganisationDto[]>(
            `${this.baseUrl}/adminapi/personnel/${personId}/managedBy`
        );
    }

    addManagingOrganisation(personId: number, organisationId: number) {
        return this.http
            .post<SimpleOrganisationDto[]>(
                `${this.baseUrl}/adminapi/personnel/${personId}/managedBy`,
                { organisationId }
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    removeManagingOrganisation(personId: number, organisationId: number) {
        return this.http.delete<SimpleOrganisationDto[]>(
            `${this.baseUrl}/adminapi/personnel/${personId}/managedBy/${organisationId}`
        );
    }

    findSystemAdminRoles(personId: number) {
        return this.http.get<UpdateSystemAdminRolesCommand.Roles[]>(
            `${this.baseUrl}/adminapi/personnel/${personId}/systemAdminRoles`
        );
    }

    updateSystemAdminRoles(
        personId: number,
        command: UpdateSystemAdminRolesCommand
    ) {
        return this.http.put<UpdateSystemAdminRolesCommand.Roles[]>(
            `${this.baseUrl}/adminapi/personnel/${personId}/systemAdminRoles`,
            command
        );
    }

    findEmailEvents(personId: number) {
        return this.http.get<EmailEventDto[]>(
            `${this.baseUrl}/adminapi/personnel/${personId}/emailEvents`
        );
    }

    /**
     * Returns the enum to display text mapping
     */
    getPersonRoles() {
        return PERSON_ROLES;
    }
}
