/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface TimeOfDayOperatingCategory { 
    id: number;
    type: 'TIME_OF_DAY';
    value?: TimeOfDayOperatingCategory.Value;
}

export namespace TimeOfDayOperatingCategory {
    export type Value = 'DAY' | 'NIGHT' | 'DAY_NIGHT';
    export const Value = {
        DAY: 'DAY' as Value,
        NIGHT: 'NIGHT' as Value,
        DAY_NIGHT: 'DAY_NIGHT' as Value
    };
}


