<ng-container screenAnalytics="user-profile">
    <div class="modal-header no-icon">
        <h3 id="dialog-child-name" class="modal-title">Your Profile</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body inner-container no-footer">
        <tabset
            *ngIf="currentUser$ | async as currentUser"
            vertical="true"
            type="pills"
            class="tab-set-vertical"
        >
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-id-badge tab-title-icon"></i>
                    <span> Your Details </span>
                </ng-template>
                <profile-details
                    class="modal-body-content container-scroll"
                    [currentUser]="currentUser"
                    [canUseNotams]="hasNotamOrganisations"
                >
                </profile-details>
                <version></version>
            </tab>

            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-shield-check tab-title-icon"></i>
                    <span> Privacy </span>
                </ng-template>
                <managing-organisations
                    [person]="currentUser"
                ></managing-organisations>
            </tab>
            <tab *ngIf="hasAirspaceData" [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-database tab-title-icon"></i>
                    <span> Data Privacy </span>
                </ng-template>
                <h4 class="section-heading">Your LAANC Data<span></span></h4>
                <account-airspace-data></account-airspace-data>
            </tab>

            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-credit-card-blank tab-title-icon"></i>
                    <span> Authorities </span>
                </ng-template>
                <organisation-authority-edit
                    [organisationId]="currentUser.personalOrganisationId"
                    [managingOrganisationId]="
                        currentUser.personalOrganisationId
                    "
                >
                </organisation-authority-edit>
            </tab>

            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-notebook tab-title-icon"></i>
                    <span> Flight History & Log Book </span>
                </ng-template>
                <div *ngIf="!editingHistoricalFlights">
                    <profile-flight-history [currentUser]="currentUser">
                    </profile-flight-history>
                </div>
            </tab>

            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-bell tab-title-icon"></i>
                    <span> Notifications </span>
                </ng-template>
                <div class="modal-body-content container-scroll">
                    <enhanced-help
                        componentName="profile-notification-settings"
                        [compact]="true"
                    >
                    </enhanced-help>
                    <profile-marketing-preferences></profile-marketing-preferences>

                    <profile-notification-settings [currentUser]="currentUser">
                    </profile-notification-settings>
                </div>
            </tab>

            <tab *ngIf="hasApiKeyOrganisations" [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-code-simple tab-title-icon"></i>
                    <span> API Keys </span>
                </ng-template>
                <api-keys [currentUser]="currentUser"> </api-keys>
            </tab>
        </tabset>
    </div>
</ng-container>
