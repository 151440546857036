import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FlyFreelyConstants,
    PersonsOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';
import {
    SelectedWidgets,
    WidgetStatus,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { Angulartics2 } from 'angulartics2';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const WELCOME_IDENTIFIER = 'welcome';
const SCREEN_IDENTIFIER = 'welcome-widget';

@Component({
    selector: 'welcome-widget',
    templateUrl: './welcome-widget.component.html',
    styles: [
        `
            .widget-container {
                flex-wrap: wrap;
                justify-content: space-around;
            }
        `
    ]
})
export class WelcomeWidgetComponent implements OnInit, OnDestroy, Widget {
    @Input() organisation: PersonsOrganisationDto;

    widgets: WidgetStatus;
    IMG_URL: string;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        constants: FlyFreelyConstants,
        private sharedService: WorkspaceStateService,
        private angulartics2: Angulartics2
    ) {
        this.IMG_URL = constants.IMG_URL;
    }

    ngOnInit() {
        this.sharedService.availableWidgets$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((widgets: WidgetStatus) => {
                this.widgets = widgets;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    closeWidget() {
        this.angulartics2.eventTrack.next({
            action: 'toggle',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });

        const selectedWidgets: SelectedWidgets = Object.keys(
            this.widgets
        ).reduce(
            (acc, w) => ({ ...acc, [w]: this.widgets[w].selected }),
            {} as SelectedWidgets
        );
        selectedWidgets[WELCOME_IDENTIFIER] = false;

        this.sharedService.changeSelectedWidgets(selectedWidgets);
    }

    onPlayVideo() {
        this.angulartics2.eventTrack.next({
            action: 'play',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    leaveFeedback() {
        console.log('leaving feedback');
    }

    supportChat() {
        console.log('support chat');
    }
}

export const WelcomeWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: WELCOME_IDENTIFIER,
    component: WelcomeWidgetComponent,
    isAvailable: organisation => false,
    isAvailableForUser: organisation => false // organisation != null && hasFeatureFlag(organisation, FEATURE_SETUP_GUIDE)
};
