import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ConcreteMappedEntityConcreteFormDto,
    ControlValues,
    FormControlDto,
    FormDto,
    FormResponseCommand,
    FormSectionDto,
    FormService,
    FormSummaryDto,
    FormVersionDto,
    toConcreteMappedEntity
} from '@flyfreely-portal-ui/flyfreely';
import {
    Entity
} from 'libs/ui/src/lib/entity-list/entity-list.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentationDialoguesService } from '../documentation-dialogues.service';

function cleanSections(sections: FormSectionDto[]) {
    for (let i = 0; i < sections.length; i++) {
        sections[i].id = sections[i].id > 0 ? sections[i].id : -i - 1;
        sections[i].order = i;
        sections[i].repeatingGroup = sections[i].repeatingGroup || false;
        cleanControls(sections[i].controls);
    }
}

function cleanControls(controls: FormControlDto[]) {
    for (let i = 0; i < controls.length; i++) {
        controls[i].order = i;
        if (
            controls[i].displayCondition != null &&
            controls[i].displayCondition.subjectControlId == null
        ) {
            controls[i].displayCondition = null;
        }
    }
}

@Component({
    selector: 'form-catalogue',
    templateUrl: './form-catalogue.component.html',
    host: {
        class: 'container-with-footer'
    }
})
export class FormCatalogue implements OnDestroy {
    @Input() organisationId: number;

    @Input() items: Entity[];
    @Input() working: boolean;
    @Input() type: string;
    @Input() canAdd = true;
    @Input() canClone = false;
    @Input() canArchive = true;
    @Input() isFormList = false;

    selectedForm: FormDto;
    currentVersion: FormVersionDto;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private formService: FormService,
        private documentationDialoguesService: DocumentationDialoguesService,
        public modal: BsModalRef<FormCatalogue>
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    cloneForm(form: FormSummaryDto) {
        const command = {
            formId: form.id,
            organisationId: this.organisationId
        };

        this.formService
            .cloneForm(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(formRet => {
                this.documentationDialoguesService.showFormEdit(
                    formRet,
                    this.organisationId
                );

                this.modal.hide();
            });
    }

    formDetails(form: FormSummaryDto) {
        this.formService
            .findForm(form.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(_form => {
                this.selectedForm = _form;
                this.previewForm();
            });
    }

    previewForm() {
        this.currentVersion = this.selectedForm?.versions.find(
            version => version.id === this.selectedForm.activeVersionId
        );

        cleanSections(this.currentVersion.sections);
        const concreteForm: ConcreteMappedEntityConcreteFormDto = toConcreteMappedEntity(
            {
                formId: this.selectedForm.id,
                formVersionId: this.currentVersion.id,
                description: this.currentVersion.description,
                formName: this.selectedForm.name,
                sections: this.currentVersion.sections,
                archived: false
            }
        );

        let savedData: FormResponseCommand;
        this.documentationDialoguesService
            .showFormInputDialogue(
                concreteForm,
                'Preview form',
                [],
                this.organisationId,
                (response: FormResponseCommand) => {
                    savedData = response;
                    return Promise.resolve();
                }
            )
            .onHide.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                if (savedData != null) {
                    this.documentationDialoguesService.showFormResponseDialogue(
                        [this.buildFormResponse(savedData.values)],
                        'Preview Responses',
                        this.organisationId
                    );
                }
            });
    }

    private buildFormResponse(values: ControlValues) {
        return {
            formId: this.selectedForm.id,
            formVersionId: this.currentVersion.id,
            isLocked: false,
            formName: this.selectedForm.name,
            sections: this.currentVersion.sections,
            values: values,
            completed: false
        };
    }
}
