import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    AirspaceJurisdictionDto,
    CreateLocationCommand,
    defaultTimezone,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LocationFeatureDto,
    LocationService,
    MissionService,
    MissionSummaryDto,
    OperationsSummaryService,
    OrganisationService,
    PersonsOrganisationDto,
    PreferencesService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { center } from '@turf/center';
import { circle } from '@turf/circle';
import { deepEqual } from 'fast-equals';
import { getOrElse } from 'fp-ts/es6/Option';
import { Point } from 'geojson';
import { AirspaceCheckService } from 'libs/airspace/src/lib/airspace-check';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { toMapFeature } from 'libs/locations/src/lib/helpers';
import { AirspaceCheckerParametersWithStartEnd } from 'libs/locations/src/lib/location-edit-v2/airspace-checker/location-airspace-check.component';
import { FlyFreelyMapComponent } from 'libs/map/src/lib/flyfreely-map/flyfreely-map.component';
import {
    FeatureAndGroup,
    FeatureGroup,
    FeaturesAndGroup,
    MapFeature
} from 'libs/map/src/lib/interfaces';
import { pointToLngLat } from 'libs/map/src/lib/utils';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { LngLat } from 'mapbox-gl';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
    delay,
    distinctUntilChanged,
    map,
    shareReplay,
    startWith,
    switchMap,
    take,
    takeUntil
} from 'rxjs/operators';
import { MissionViewService } from '../mission-view.service';
import { MissionViewDataService } from '../mission-view/mission-view-data.service';

const FLIGHT_AREA_FEATURE_GROUP_ID = 0;
const CHECKER_FEATURE_GROUP_ID = 1;
const AIRSPACE_DISCLAIMER = 'mission-view-airspace-disclaimer-acknowledgement';

@Component({
    selector: 'mission-view-map',
    templateUrl: './mission-view-map.component.html',
    styleUrls: ['./mission-view-map.component.scss'],
    providers: [AirspaceCheckService]
})
export class MissionViewMapComponent implements OnInit {
    private featuresSource = new BehaviorSubject<FeatureGroup[]>([]);
    features$ = this.featuresSource.asObservable();

    private featureSelected$ = new BehaviorSubject<boolean>(false);
    private mapClicked$ = new Subject<LngLat>();
    isDrawing = false;
    mapIsInEditMode = false;

    // this can be used to prevent the map zooming to all features, for instance during an airspace check
    // If true it will be set to false after the next time the map emits featuresLoaded
    skipNextFeatureUpdateZoom = false;

    cluster: FeaturesAndGroup;
    selectedLocation: MapFeature;

    flightArea: GeoJSON.Polygon;
    airspaceValue: AirspaceCheckerParametersWithStartEnd;
    checkerAreaRadius = 100;

    missionLocations$: ReturnType<
        typeof OperationsSummaryService.prototype.findMissionLocations
    >;

    selectedMissions: MissionSummaryDto[];

    private ngUnsubscribe$ = new Subject<void>();

    @ViewChild(FlyFreelyMapComponent)
    private map: FlyFreelyMapComponent;

    jurisdictionHasAirspaceChecker = false;
    airspaceJurisdiction: AirspaceJurisdictionDto;
    showAirspaceDisclaimer = false;
    canAddMission = false;

    awaitingFeature = false;

    currentOrganisation?: PersonsOrganisationDto;

    private workTracker = new WorkTracker();
    working = false;

    ignoreClicksOnFeatureGroups = [CHECKER_FEATURE_GROUP_ID];

    constructor(
        private operationsSummaryService: OperationsSummaryService,
        private missionViewDataService: MissionViewDataService,
        private preferencesService: PreferencesService,
        private workspaceStateService: WorkspaceStateService,
        private missionService: MissionService,
        private locationService: LocationService,
        private missionDialoguesService: MissionDialoguesService,
        private organisationService: OrganisationService,
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
        private fullscreenService: FullScreenService,
        public missionViewService: MissionViewService,
        private logging: FlyFreelyLoggingService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        // We assume that the search criteria is updated when the organisation is changed
        this.missionLocations$ = combineLatest([
            this.missionViewDataService.searchCriteria$.pipe(
                distinctUntilChanged(deepEqual)
            ),
            this.missionService.change$.pipe(startWith(undefined))
        ]).pipe(
            switchMap(([searchCriteria]) => {
                const { filters, page, limit, sorting, ...criteria } =
                    searchCriteria;
                return this.operationsSummaryService.findMissionLocations(
                    criteria,
                    filters
                );
            }),
            shareReplay()
        );

        this.missionViewService.permissionsUpdated$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(updated => {
                if (updated) {
                    this.canAddMission = this.missionViewService.canAddMission;
                }
            });

        this.workspaceStateService.currentOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                if (organisation.type === 'organisation_loaded') {
                    this.skipNextFeatureUpdateZoom = false;
                    this.currentOrganisation = organisation.organisation;
                } else {
                    this.currentOrganisation = null;
                }
            });

        combineLatest([
            this.missionViewService.viewMode$,
            this.missionLocations$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([viewMode, locationList]) => {
                this.featuresSource.next([
                    {
                        id: FLIGHT_AREA_FEATURE_GROUP_ID,
                        name: 'Flight Areas',
                        type: 'Point',
                        canAdd: false,
                        existingFeatures:
                            viewMode === 'MAP'
                                ? []
                                : locationList.map(l => ({
                                      id: l.id,
                                      name: l.name,
                                      geom: l.representativePoint
                                  }))
                    },
                    {
                        id: CHECKER_FEATURE_GROUP_ID,
                        name: 'Checker Flight Area',
                        type: 'Polygon',
                        canAdd: false,
                        existingFeatures: []
                    }
                ] as FeatureGroup[]);
            });

        this.mapClicked$
            .pipe(delay(10))
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(coordinates => {
                if (
                    !this.featureSelected$.getValue() &&
                    !this.isDrawing &&
                    !this.mapIsInEditMode
                ) {
                    this.addAirspaceCheckerFlightArea(coordinates);
                } else {
                    this.removeAirspaceCheckerFlightArea();
                    this.flightArea = null;
                }
                this.isDrawing = this.map.draw.getMode() !== 'simple_select';
            });
    }

    ngOnDestroy() {
        this.workTracker.ngOnDestroy();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.featuresSource.complete();
        this.featureSelected$.complete();
        this.mapClicked$.complete();
    }

    addAirspaceCheckerFlightArea(coordinates: LngLat) {
        const point: Point = {
            type: 'Point',
            coordinates: [coordinates.lng, coordinates.lat]
        };
        this.flightArea = circle(point, this.checkerAreaRadius, {
            units: 'meters'
        }).geometry;
        const feature: LocationFeatureDto = {
            type: 'FLIGHT_AREA',
            name: 'Flight Area',
            geometry: this.flightArea
        };
        const mapFeatures = this.featuresSource.getValue();
        const features = mapFeatures.map(fg =>
            fg.id === 1
                ? {
                      ...fg,
                      // override existing feature because we only want one checker flight area
                      existingFeatures: [toMapFeature(feature, -10)]
                  }
                : fg
        );
        this.skipNextFeatureUpdateZoom = true;
        this.featuresSource.next(features);
        this.map.zoomToFeature(toMapFeature(feature, -10), false);
    }

    removeAirspaceCheckerFlightArea() {
        const features = this.featuresSource.getValue().map(fg =>
            fg.id === CHECKER_FEATURE_GROUP_ID
                ? {
                      ...fg,
                      existingFeatures: []
                  }
                : fg
        );
        this.featuresSource.next(features);
    }

    onCheckerParametersChanged(value: AirspaceCheckerParametersWithStartEnd) {
        if (value.radius != this.checkerAreaRadius && this.flightArea != null) {
            this.checkerAreaRadius = value.radius;
            const point = center(this.flightArea);
            this.removeAirspaceCheckerFlightArea();
            this.addAirspaceCheckerFlightArea(
                pointToLngLat(point.geometry.coordinates)
            );
        }
        this.airspaceValue = value;
        this.changeDetector.detectChanges();
    }

    onMeasurementToolSelected() {
        this.isDrawing = true;
    }

    onEditModeChanged(editing: boolean) {
        this.mapIsInEditMode = editing;
    }

    onClusterFeatureSelected(feature: MapFeature) {
        this.featureSelected$.next(true);
        this.selectedLocation = feature;
        this.onFeatureSelected({ groupId: this.cluster.groupId, feature });
    }

    onFeatureSelected(selected: FeatureAndGroup) {
        this.featureSelected$.next(true);
        this.selectedMissions = null;
        this.selectedLocation = null;
        if (
            selected == null ||
            selected.groupId !== FLIGHT_AREA_FEATURE_GROUP_ID
        ) {
            return;
        }
        this.selectedLocation = selected.feature;
        const doneWorking = this.workTracker.createTracker();
        this.operationsSummaryService
            .findMissionsAtLocation(<number>selected.feature.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: missions => {
                    this.selectedMissions = this.preferencesService
                        .showDummyData
                        ? missions
                        : missions.filter(mission => mission.isDummy === false);
                    doneWorking();
                },
                error: (error: FlyFreelyError) =>
                    this.toastr.error(
                        `Error refreshing missions for location: ${error.message}`
                    )
            });
    }

    onMapFeaturesLoaded() {
        if (
            !this.awaitingFeature &&
            !this.mapIsInEditMode &&
            !this.isDrawing &&
            this.selectedLocation == null &&
            this.selectedMissions == null &&
            !this.skipNextFeatureUpdateZoom &&
            this.map != null
        ) {
            this.map.zoomToAllFeatures(true);
        }
    }

    onFeatureClusterSelected(cluster: FeaturesAndGroup) {
        this.featureSelected$.next(true);
        // this.selectedMissions = null;
        this.selectedLocation = null;
        this.cluster = cluster;
        this.changeDetector.detectChanges();
    }

    onFeatureUnselected() {
        this.featureSelected$.next(false);
    }

    onMapClicked(coordinates: LngLat) {
        if (!this.awaitingFeature) {
            return;
        }
        this.mapClicked$.next(coordinates);
    }

    setAwaitingFeature(awaitingFeature: boolean) {
        this.awaitingFeature = awaitingFeature;
    }

    createMissionFromChecker() {
        const featureGroup = this.featuresSource
            .getValue()
            .find(fg => fg.id === 1);
        if (featureGroup?.existingFeatures?.length === 0) {
            return;
        }
        const geom = featureGroup.existingFeatures[0].geom;
        if (geom.type !== 'Polygon') {
            return;
        }

        const name = `Mission ${moment().format('YYYY-MM-DD')}`;

        const features: LocationFeatureDto[] = [
            {
                geometry: geom,
                name: 'Flight Area',
                type: LocationFeatureDto.Type.FLIGHT_AREA
            }
        ];

        const createCommand: CreateLocationCommand = {
            name,
            features,
            organisationId: this.currentOrganisation.id,
            type: CreateLocationCommand.Type.MISSION,
            derivedFromId: null
        };

        const tz = this.airspaceValue.airspaceTimeZone ?? defaultTimezone;

        const t = moment(
            moment(this.airspaceValue.airspaceDate ?? new Date()).format(
                'YYYY-MM-DD[T]HH:mm:ss'
            )
        ).tz(tz);
        const utc = t.toISOString();

        combineLatest([
            this.locationService.createLocation(createCommand),
            this.organisationService.findByIdForUser(
                this.currentOrganisation.id,
                this.currentOrganisation.id
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([location, organisation]) => {
                const mission = this.missionService.newMission(organisation);
                mission.location = location;
                mission.name = createCommand.name;
                mission.missionDate = utc;
                mission.timeZone = this.airspaceValue.airspaceTimeZone;
                mission.missionEstimatedTime =
                    this.airspaceValue.airspaceDuration;
                mission.maximumHeight = this.airspaceValue.airspaceHeight;
                mission.timeZone = location.timeZone;
                mission.lockedFields = {
                    aircraft: false,
                    isDummy: false,
                    missionCrew: false,
                    missionType: false,
                    missionWorkflowVersion: false,
                    timeZone: false
                };
                this.missionDialoguesService.showMissionEditor(
                    mission,
                    organisation
                );
            })
            .add(this.workTracker.createTracker());
    }

    showMission(mission: MissionSummaryDto) {
        this.missionDialoguesService.showMissionDetails(
            mission.id,
            true,
            this.currentOrganisation
        );
    }

    onFullscreenRequested() {
        this.fullscreenService.toggleFullScreen(this.elementRef);
    }

    onUpdateJurisdiction(jurisdiction: AirspaceJurisdictionDto) {
        this.jurisdictionHasAirspaceChecker = jurisdiction?.hasAirspaceChecker;
        this.airspaceJurisdiction = jurisdiction;
        this.showAirspaceDisclaimer = false;
        this.showAirspaceDisclaimerDialogue();
    }

    showAirspaceDisclaimerDialogue() {
        const disclaimer = this.airspaceJurisdiction?.airspaceDisclaimer;
        if (disclaimer == null || disclaimer.length == 0) {
            this.showAirspaceDisclaimer = false;
            return;
        }
        this.preferencesService
            .findPreferencesAsOption(AIRSPACE_DISCLAIMER, null)
            .pipe(
                map(p =>
                    getOrElse(() => ({
                        date: moment(new Date()).subtract(1, 'day').toDate()
                    }))(p)
                ),
                takeUntil(this.ngUnsubscribe$),
                take(1)
            )
            .subscribe({
                next: preferences => {
                    if (preferences == null) {
                        this.showAirspaceDisclaimer = true;
                        return;
                    }
                    const today = moment(new Date()).format('YYYY-MM-DD');
                    const lastViewed = moment(preferences.date).format(
                        'YYYY-MM-DD'
                    );
                    // Only show the disclaimer once per account
                    this.showAirspaceDisclaimer =
                        moment(lastViewed).isBefore(today);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            });
    }

    onAirspaceDisclaimerAcknowledged() {
        this.showAirspaceDisclaimer = false;
        this.preferencesService
            .updatePreferences(AIRSPACE_DISCLAIMER, null, { date: new Date() })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(DO_NOTHING)
            .add(this.workTracker.createTracker());
    }
}
