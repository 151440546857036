import { Pipe, PipeTransform } from '@angular/core';
import * as momentClass from 'moment';
// This is maintly just to satisfy Jest
import * as moment from 'moment';

@Pipe({ name: 'formatDateTime' })
export class FormatDateTimePipe implements PipeTransform {
    transform(dateTimeStr: string | Date | moment.Moment, timeZone?: string): string | null {
        if (!dateTimeStr) {
            return null;
        }
        if (momentClass.isMoment(dateTimeStr)) {
            return moment(dateTimeStr).format('DD/MM/YYYY HH:mm z');
        }

        if (timeZone != null) {
            return moment(dateTimeStr)
                .tz(timeZone)
                .format('DD/MM/YYYY HH:mm z');
        }

        return moment(dateTimeStr).format('DD/MM/YYYY HH:mm');
    }
}
