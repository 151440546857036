<static-table
    *ngIf="rpaLoaded && compatibleRpa != null && compatibleRpa.length > 0"
    [availableColumns]="availableColumns"
    [selectedColumns]="selectedColumns"
    [tableData]="compatibleRpa"
    [tableConfig]="tableConfig"
    [centeredHeadings]="true"
>
</static-table>
<div *ngIf="rpaLoaded && compatibleRpa != null && compatibleRpa.length == 0">
    There are no RPA compatible with this battery set in it's current state.
    Either there are more/fewer batteries in the set than required, or there are
    no RPA that use the type or combination of types of batteries in the set.
</div>
<loading-indicator [isLoading]="working"></loading-indicator>
