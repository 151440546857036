import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlightLogsRoutingModule } from '@flyfreely-portal-ui/flight-logs';
import { MissionRoutingModule } from '@flyfreely-portal-ui/missions';
import { OrganisationAdminRoutingModule } from '@flyfreely-portal-ui/organisation-admin';
import { UserProfileRoutingModule } from '@flyfreely-portal-ui/user-profile';
import { MissionViewSidebarComponent } from '../mission-view-sidebar/mission-view-sidebar.component';
import { MissionViewComponent } from '../mission-view/mission-view.component';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: MissionViewComponent,
                children: [
                    {
                        path: 'mission',
                        loadChildren: () => MissionRoutingModule
                    },
                    {
                        path: 'flightlogs',
                        loadChildren: () => FlightLogsRoutingModule
                    },
                    {
                        path: 'orgadmin',
                        loadChildren: () => OrganisationAdminRoutingModule
                    },
                    {
                        path: 'userprofile',
                        loadChildren: () => UserProfileRoutingModule
                    }
                ]
            },
            {
                path: '',
                component: MissionViewSidebarComponent,
                outlet: 'sidebar'
            }
        ])
    ],
    exports: [RouterModule]
})
export class MissionViewRoutingModule {}
