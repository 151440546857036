import { Injectable } from '@angular/core';
import {
    AddressDto,
    AvailableSubscriptionTiers,
    ChargesDto,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NotFound,
    PlanTier,
    SubscriptionService,
    SubscriptionSetupOutcomeDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { findFirst } from 'fp-ts/es6/Array';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { flow } from 'fp-ts/es6/function';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { AddressTaxDialogue } from 'libs/subscriptions/src/lib/address-tax-setup/address-tax-dialogue.component';
import { OrganisationSubscriptionState } from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
    ObservableNotification,
    ReplaySubject,
    Subject,
    of,
    race,
    throwError
} from 'rxjs';
import {
    catchError,
    dematerialize,
    mapTo,
    materialize,
    shareReplay,
    switchMap,
    takeUntil,
    takeWhile,
    tap
} from 'rxjs/operators';
import { ConfirmPaymentDialogue } from '../confirm-payment/confirm-payment.component';
import { FREE_PLAN } from '../interfaces';
import { PlanSelectionService } from '../plan-selection/plan-selection.service';

const complete = () => takeWhile(() => false);

export interface SubscriptionPlanSelection {
    identifier: string;
    name: string;
    renewalPeriod: 'ANNUAL' | 'MONTHLY' | 'NONE';
    tiers: PlanTier[];
    currency: string;
    customerDescription: string;
    quantityLabel: string;
    featureSetList: string[];
    minimumLicenceCount: number;
}

function findSubscriptionPlans(plans: AvailableSubscriptionTiers[]) {
    const subscriptionPlans = plans.filter(
        p =>
            (p.licencedEntity ===
                AvailableSubscriptionTiers.LicencedEntity.PERSONNEL ||
                p.licencedEntity ===
                    AvailableSubscriptionTiers.LicencedEntity.PILOT) &&
            p.monthlyPlan != null &&
            p.annualPlan != null
    );
    return {
        monthlyPlan: subscriptionPlans.map(p => ({
            identifier: p.monthlyPlan.identifier,
            name: p.name,
            renewalPeriod: 'MONTHLY',
            tiers: p.monthlyPlan.tiers,
            currency: p.monthlyPlan.currency,
            customerDescription: p.customerDescription,
            quantityLabel: p.quantityLabel,
            featureSetList: p.featureFlagList ?? [],
            minimumLicenceCount: p.monthlyPlan.minimumLicenceCount
        })),
        annualPlan: subscriptionPlans.map(p => ({
            identifier: p.annualPlan.identifier,
            name: p.name,
            renewalPeriod: 'ANNUAL',
            tiers: p.annualPlan.tiers,
            currency: p.annualPlan.currency,
            customerDescription: p.customerDescription,
            quantityLabel: p.quantityLabel,
            featureSetList: p.featureFlagList ?? [],
            minimumLicenceCount: p.annualPlan.minimumLicenceCount
        }))
    } as AvailableSubscriptionPlans;
}

function findFreePlan(plans: AvailableSubscriptionTiers[]) {
    return flow(
        findFirst<AvailableSubscriptionTiers>(p => p.identifier === FREE_PLAN),
        map<
            AvailableSubscriptionTiers,
            {
                freePlan: SubscriptionPlanSelection;
            }
        >(p => ({
            freePlan: {
                identifier: FREE_PLAN,
                name: 'Free',
                renewalPeriod: 'NONE',
                currency: null,
                tiers: [
                    {
                        tierLimit: 1,
                        flatAmount: 0,
                        unitAmount: 0
                    }
                ],
                customerDescription: p.customerDescription,
                quantityLabel: p.quantityLabel,
                featureSetList: null,
                minimumLicenceCount: 1
            }
        })),
        getOrElse(() => undefined)
    )(plans);
}

export interface AvailableSubscriptionPlans {
    annualPlan: SubscriptionPlanSelection[];
    monthlyPlan: SubscriptionPlanSelection[];
    freePlan: SubscriptionPlanSelection;
}

@Injectable()
export class PurchaseSubscriptionService extends PlanSelectionService {
    private organisationId: number;
    /**
     * Is there an existing trial subscription?
     */
    private subscriptionId?: number;

    private availablePlansSource = new ReplaySubject<
        AvailableSubscriptionPlans
    >(1);
    availablePlans$ = this.availablePlansSource.asObservable();

    private workingSource = new ReplaySubject<boolean>(1);
    working$ = this.workingSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private subscriptionService: SubscriptionService,
        private modalService: BsModalService,
        private state: OrganisationSubscriptionState,
        private angulartics: Angulartics2,
        private logging: FlyFreelyLoggingService
    ) {
        super();
        this.workTracker
            .observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();

        this.workingSource.next(false);
        this.workingSource.complete();
        this.availablePlansSource.complete();
    }

    setup(organisationId: number, subscriptionId?: number) {
        this.organisationId = organisationId;
        this.subscriptionId = subscriptionId;
        this.refreshPlans();
    }

    private refreshPlans() {
        this.subscriptionService
            .findAvailableSubscriptions(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                plans => {
                    // The API is currently incorrectly returning 0 as the minimum
                    const correctedPlans = plans.map(p => ({
                        ...p,
                        annualPlan:
                            p.annualPlan != null
                                ? {
                                      ...p.annualPlan,
                                      minimumLicenceCount:
                                          p.annualPlan.minimumLicenceCount || 1
                                  }
                                : null,
                        monthlyPlan:
                            p.monthlyPlan != null
                                ? {
                                      ...p.monthlyPlan,
                                      minimumLicenceCount:
                                          p.monthlyPlan.minimumLicenceCount || 1
                                  }
                                : null
                    }));
                    this.availablePlansSource.next({
                        ...findSubscriptionPlans(correctedPlans),
                        ...findFreePlan(correctedPlans)
                    });
                },
                error => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }

    purchaseSubscription(
        subscriptionPlanIdentifier: string,
        licenceCount: number,
        upgradeSubscription = false
    ) {
        const properties = {
            category: 'subscriptions',
            label: subscriptionPlanIdentifier,
            value: licenceCount
        };

        // don't show all the payment dialogues on update
        if (upgradeSubscription) {
            const action = this.subscriptionService
                .updateSubscription(this.subscriptionId, {
                    organisationId: this.organisationId,
                    subscriptionPlanIdentifier,
                    licenceCount
                })
                .pipe(
                    tap(() => this.state.pollForSubscriptionUpgrade()),
                    shareReplay()
                );

            action.subscribe({
                next: result => {
                    this.angulartics.eventTrack.next({
                        action: 'upgrade-plan-checkout',
                        properties
                    });
                },
                error: () =>
                    this.angulartics.eventTrack.next({
                        action: 'upgrade-plan-failed',
                        properties
                    })
            });

            return action.pipe(
                tap(result =>
                    this.state.onNewSubscription(this.subscriptionId)
                ),
                mapTo(undefined)
            );
        }
        const action = this.subscriptionService
            .findBillingAddress(this.organisationId)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                catchError((error: FlyFreelyError) =>
                    error instanceof NotFound
                        ? of({} as AddressDto)
                        : throwError(() => error)
                ),
                switchMap(address => {
                    const modal = this.modalService.show<AddressTaxDialogue>(
                        AddressTaxDialogue,
                        {
                            ...MODAL_OPTIONS,
                            class: 'modal-task',
                            initialState: {
                                address,
                                organisationId: this.organisationId
                            }
                        }
                    );

                    return race(
                        modal.onHidden.pipe(
                            complete(),
                            materialize()
                        ),
                        modal.content.complete.pipe(materialize())
                    );
                }),
                catchError((error: FlyFreelyError, value) =>
                    throwError(() => error)
                ),
                dematerialize<ObservableNotification<AddressDto>>(),
                switchMap(address => {
                    const purchaseModal = this.modalService.show<
                        ConfirmPaymentDialogue
                    >(ConfirmPaymentDialogue, {
                        ...MODAL_OPTIONS,
                        class: 'modal-task',
                        initialState: {
                            address: address,
                            organisationId: this.organisationId,
                            subscriptionId: this.subscriptionId,
                            mode: 'PURCHASE',
                            licenceCount,
                            subscriptionPlanIdentifier
                        }
                    });
                    return race(
                        purchaseModal.onHidden.pipe(
                            complete(),
                            materialize()
                        ),
                        purchaseModal.content.complete.pipe(materialize())
                    );
                }),
                catchError((error: FlyFreelyError, value) =>
                    throwError(() => error)
                ),
                dematerialize<
                    ObservableNotification<SubscriptionSetupOutcomeDto>
                >(),
                shareReplay()
            );

        action
            .subscribe({
                next: result => {
                    this.angulartics.eventTrack.next({
                        action: 'purchase-plan-checkout',
                        properties
                    });
                },
                error: () =>
                    this.angulartics.eventTrack.next({
                        action: 'purchase-plan-failed',
                        properties
                    })
            })
            .add(this.workTracker.createTracker());

        return action.pipe(
            tap(result =>
                this.state.onNewSubscription(result.id ?? this.subscriptionId)
            ),
            mapTo(undefined)
        );
    }

    previewPurchaseSubscription(
        plan: SubscriptionPlanSelection,
        licenceCount: number
    ) {
        if (plan.identifier === FREE_PLAN) {
            return of<ChargesDto>({
                currency: '',
                total: 0,
                lineItems: [],
                subTotal: 0,
                taxAmounts: [],
                status: null
            });
        }
        return this.subscriptionService.previewCharge({
            organisationId: this.organisationId,
            subscriptionPlanIdentifier: plan.identifier,
            licenceCount: licenceCount
        });
    }
}
