<div class="">
    <static-table
      *ngIf="(maintenanceLogs$ | async)?.length > 0"
      [availableColumns]="availableColumns"
      [selectedColumns]="selectedColumns"
      [tableData]="maintenanceLogs$ | async"
      [tableConfig]="tableConfig"
      [hideActionColumn]="true"
      [centeredColumns]="false" 
      [columnSorting]="columnSorting"
      data-cy="maintenanceRequestTable">
      <ng-template>
        <ngx-loading [show]="working"></ngx-loading>
      </ng-template>
      <ng-template
                    columnTemplate="assignee"
                    let-row="row"
                    let-column="column"
                >
                    <div style="width: 150px">
                        <person-avatar
                            [person]="row.assignee"
                            [managingOrganisationId]= organizationId
                        ></person-avatar>
                    </div>
                </ng-template>
    </static-table>
    <empty
      *ngIf=" (maintenanceLogs$ | async)?.length === 0 && selectedCardId != null"
      componentName="no-maintenance-logs"
      >
    </empty>
    <empty
      *ngIf=" (maintenanceLogs$ | async)?.length === 0 && selectedCardId == null"
      componentName="no-notice-selected"
      >
  </empty>
 
</div>
