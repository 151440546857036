<button
    class="btn btn-light"
    type="button"
    (click)="rulerButtonClick()"
    [class.active]="isActive"
    placement="bottom"
    [tooltip]="tooltipMessage"
>
    <i
        class="fal"
        [class.fa-ruler]="buttonType === ToolbarButton.MeasurementLine"
        [class.fa-radar]="buttonType === ToolbarButton.MeasurementRadius"
        [class.disabled]="!enabled"
    ></i>
</button>
