import { AbstractControl, Validators } from '@angular/forms';
import * as deepmerge from 'deepmerge';
import { Observable } from 'rxjs';
import { filter, shareReplay, startWith } from 'rxjs/operators';

import PackageVersion from '../../../../package.json';

/**
 * The current version stamp for the UI
 */
export const uiVersion = PackageVersion.version;

export function range(lowEnd: number, highEnd: number): Array<number> {
    const arr = [];
    let c = highEnd - lowEnd + 1;
    while (c--) {
        arr[c] = highEnd--;
    }
    return arr;
}

export function returnLast(array: Array<any>) {
    if (array.length === 0) {
        return null;
    }
    return array[array.length - 1];
}

/**
 * Used to filter to unique values
 * [].filter(unique);
 */
export function unique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
}

export interface HasId {
    id?: string | number;
}

export interface LookupObject<T> {
    [id: string]: T;
}

/**
 * Used to reduce to a lookup object:
 * [].reduce(toLookup, {})
 */
export function toLookup<T extends HasId>(
    acc: LookupObject<T>,
    val: T
): LookupObject<T> {
    acc[val.id] = val;
    return acc;
}

/**
 * Common function that reduces an input array to a NameId array
 * @param input any array of type that contains a name and id key
 * @returns a NameId array
 */
export function toNameId(input: any[]) {
    return input.map(i => ({
        name: i.name,
        id: i.id
    }));
}

export interface GroupByObject<T> {
    [id: string]: T[] | GroupByObject<T>;
}

/**
 * A function to group objects by a given property. This can be used in an array reduce function
 * either directly, or by currying.
 * @param acc the accumulator which should be initialised as {}
 * @param val the object to add
 * @param propertyName the name of the property to group by
 */
export function groupByProperty<T>(
    acc: GroupByObject<T>,
    val: T,
    ...propertyNames: string[]
): GroupByObject<T> {
    if (propertyNames.length > 1) {
        const propertyValue = val[propertyNames[0]];
        if (propertyValue in acc) {
            acc[propertyValue] = groupByProperty(
                // @ts-ignore - bad type sensing
                acc[propertyValue],
                val,
                ...propertyNames.slice(1)
            );
        } else {
            acc[propertyValue] = groupByProperty(
                {},
                val,
                ...propertyNames.slice(1)
            );
        }
        return acc;
    } else {
        const propertyName = propertyNames[0];
        const propertyValue = val[propertyName];
        if (propertyValue in acc) {
            // @ts-ignore - bad type sensing
            acc[propertyValue] = acc[propertyValue].concat(val);
        } else {
            acc[propertyValue] = [val];
        }
        return acc;
    }
}

export interface HasVersionNumber {
    versionNumber?: number;
}

/**
 * Returns the next version number in the sequence.
 * @param items a list of items that have version numbers
 */
export function nextVersion(items: HasVersionNumber[]) {
    return (
        items
            .map((form: any) => form.versionNumber)
            .filter((v: number) => v != null)
            .reduce((acc: number, v: number) => Math.max(acc, v), 0) + 1
    );
}

/**
 * Loads an unauthenticated image using an observable.
 * @param imagePath the image to load
 */
export function observeImage(imagePath: string): Observable<HTMLImageElement> {
    return new Observable(subscriber => {
        const img = new Image();
        img.src = imagePath;
        img.onload = () => {
            subscriber.next(img);
            subscriber.complete();
        };
        img.onerror = err => {
            subscriber.error(err);
        };
    });
}

/**
 * Copy the properties of an object, omitting those specified.
 */
export function copy<T>(src: T, omit?: string[]): T {
    return Object.keys(src).reduce(
        (acc, key) =>
            omit == null || omit.indexOf(key) === -1
                ? {
                      ...acc,
                      [key]: src[key]
                  }
                : acc,
        {} as T
    );
}

/**
 * A deep copy function.
 * @param src the source option
 */
export function deepCopy<T>(src: T): T {
    return deepmerge<T, T>(src, {});
}

/**
 * This interface is implemented by controllers that support being cancelled
 */
export interface Cancellable {
    cancel: () => void;
}

export function intOrNull(val: string): number {
    if (val == null) {
        return null;
    }
    return parseInt(val, 10);
}

/**
 * Return an array of keys of an object that is of the correct type of the key
 * @param o the object to get the keys of
 */
export function keys<O>(o: O) {
    return Object.keys(o) as (keyof O)[];
}

/**
 * A standard regex pattern based validator for email addresses.
 */
export const emailValidator = Validators.pattern(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

/**
 * Accept an enum value and return a capitalised string for display.
 * This will also split words jouned by underscore and capitalise each word, returning them as a string separated by spaces.
 * @param value Any enum value in all caps with multiple words joined by underscore.
 */
export function capitaliseEnum(value: string) {
    const capitalise = (word: string) => {
        const first = word[0];
        const rest = word.slice(1, word.length);
        return `${first.toUpperCase()}${rest.toLowerCase()}`;
    };
    let displayable;
    if (value.includes('_')) {
        const split = value.split('_');
        split.forEach(word => {
            word = capitalise(word);
        });
        displayable = split.join(' ');
    } else {
        displayable = capitalise(value);
    }
    return displayable;
}

/**
 * This is a standard pagination event object for the ngx-easy-table, used primarily for server-side pagination.
 */
export interface EasyTableEventObject {
    event: string;
    value: {
        limit: number;
        page: number;
    };
}

/**
 * Used to make it clear when a function should no nothing. Helps avoid
 * @typescript-eslint/no-empty-function
 */
export function DO_NOTHING() {
    // do nothing
}

/*
 * An RXJS pipe filter to check for not null.
 * @param val value to check
 */
export function notNullish<T>() {
    return filter<T>(val => val != null);
}

/**
 * Test if this is a promise by seeing if there is a `then` method.
 * @param obj the object that may be a promise
 */
export function isPromise(obj: any): obj is Promise<any> {
    return typeof obj === 'object' && typeof obj.then === 'function';
}

/**
 * Make a abstract form control value observable that includes the current value.
 *
 * This is a shareReplay operator to replay the most recent value to new subscribers,
 * without just receiving the original initial value.
 *
 * @param control the form array, form control, or form group
 * @returns an observable that emits the current value, then every change
 */
export function observeFormControl<T>(
    control: AbstractControl<T>
): Observable<T> {
    return control.valueChanges.pipe(startWith(control.value), shareReplay());
}
