/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface RpaCategoryOperatingCategory { 
    id: number;
    type: 'RPA_CATEGORY';
    value?: RpaCategoryOperatingCategory.Value;
}

export namespace RpaCategoryOperatingCategory {
    export type Value = 'MULTIROTOR' | 'HELICOPTOR' | 'AEROPLANE' | 'POWERED_LIFT' | 'AIRSHIP' | 'OTHER';
    export const Value = {
        MULTIROTOR: 'MULTIROTOR' as Value,
        HELICOPTOR: 'HELICOPTOR' as Value,
        AEROPLANE: 'AEROPLANE' as Value,
        POWERED_LIFT: 'POWERED_LIFT' as Value,
        AIRSHIP: 'AIRSHIP' as Value,
        OTHER: 'OTHER' as Value
    };
}


