<div
    class="container-fluid support-links-container"
    style="padding: 25px 30px 0"
>
    <div class="vertical-container fill contents">
        <img
            [src]="
                IMG_URL +
                '/onboarding/checklist-assets/knowlegebase-graphic.png'
            "
            style="width: 75%; margin: 0 auto 25px"
        />
        <a
            class="btn btn-confirm btn-wide btn-dark-back"
            knowledgeBase="support-links"
        >
            Visit Knowledge Base
        </a>
    </div>
</div>
