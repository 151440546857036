import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PERSON_ROLES } from '@flyfreely-portal-ui/flyfreely';
@Component({
    selector: 'personnel-roles',
    template: `
        <table class="top-buffer" [formGroup]="formGroup">
            <tbody>
                <tr *ngIf="allowStudent">
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="STUDENT"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['STUDENT'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        A student within the organisation.
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="PLANNER"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['PLANNER'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Can plan missions for other people
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="ADMIN"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['ADMIN'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Can manage personnel and RPA, as well as plan missions
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="BILLING_ADMIN"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['BILLING_ADMIN'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        An admin who can manage the organisation and personnel,
                        but no flight related tasks
                    </td>
                </tr>
                <tr *ngIf="allowReadOnly">
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="READ_ONLY"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['READ_ONLY'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Can view everything about an organisation but not modify
                        anything
                    </td>
                </tr>
                <tr *ngIf="allowInstantMission">
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="PILOT_INSTANT_MISSION"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['PILOT_INSTANT_MISSION'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        A pilot who can only execute instant missions, if
                        configured
                    </td>
                </tr>
                <tr *ngIf="allowReadOnlyHidden">
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="READ_ONLY_HIDDEN"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['READ_ONLY_HIDDEN'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Can view everything about an organisation but not modify
                        anything
                    </td>
                </tr>
                <!-- This empty row is for spacing -->
                <tr *ngIf="totalPilots != null">
                    <td>&nbsp;</td>
                    <td></td>
                </tr>
                <tr *ngIf="totalPilots != null">
                    <td></td>
                    <td>
                        There {{ availablePilots == 1 ? 'is' : 'are' }}
                        {{ availablePilots }} out of {{ totalPilots }} Pilot
                        roles remaining
                        {{
                            multipleSubscriptions
                                ? ' in the selected subscription'
                                : ''
                        }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div
                            class="checkbox"
                            [ngClass]="{ disabled: pilotLimitReached }"
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    [disabled]="pilotLimitReached"
                                    formControlName="PILOT"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['PILOT'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block" *ngIf="!pilotLimitReached">
                        Can fly mission but not plan them unless they have
                        another role that allows them to
                    </td>
                    <td class="help-block" *ngIf="pilotLimitReached">
                        No remaining licences for the pilot role in this
                        organisation.
                        <br />Please upgrade your subscription for additional
                        licences.
                    </td>
                </tr>
                <tr *ngIf="showPilotSubtypes">
                    <td class="left-indent">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="PILOT_PLANNER"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['PILOT_PLANNER'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">Can plan own missions</td>
                </tr>
                <tr *ngIf="showPilotSubtypes">
                    <td class="left-indent">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="PILOT_SUBMITTER"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['PILOT_SUBMITTER'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">Can submit own missions</td>
                </tr>
                <tr *ngIf="showPilotSubtypes">
                    <td class="left-indent">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="PILOT_CREW_PLANNER"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['PILOT_CREW_PLANNER'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">Can add crew to their missions</td>
                </tr>
                <tr *ngIf="showPilotSubtypes">
                    <td class="left-indent">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="FLIGHT_LOG_COLLECTION_OWN"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['FLIGHT_LOG_COLLECTION_OWN'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Can manage their own DJI sync accounts
                    </td>
                </tr>
                <tr *ngIf="totalPilots != null">
                    <td>&nbsp;</td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" formControlName="RCP" />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['RCP'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        This person is the Chief Remote Pilot for the
                        organisation
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="OBSERVER"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['OBSERVER'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Crew member who is an observer during missions. Can view
                        missions they are assigned to.
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="MAINTENANCE_CONTROLLER"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['MAINTENANCE_CONTROLLER'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Manages and assigns the work of maintaining equipment to
                        people with the Maintenance Person role
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="MAINTENANCE_PERSON"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['MAINTENANCE_PERSON'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        Performs the maintenance on RPA and other equipment
                    </td>
                </tr>
                <tr *ngIf="allowExternalMaintenancePerson">
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="MAINTENANCE_PERSON_EXTERNAL"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['MAINTENANCE_PERSON_EXTERNAL'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">
                        A person from outside your organisation who needs access
                        to perform maintenance, while not getting access to
                        everything in your organisation.
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="FLIGHT_LOG_COLLECTION_ORGANISATION"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                {{ PERSON_ROLES['FLIGHT_LOG_COLLECTION_ORGANISATION'] }}
                            </label>
                        </div>
                    </td>
                    <td class="help-block">Can manage all sync accounts</td>
                </tr>
            </tbody>
        </table>
        <p class="help-block" *ngIf="showHelp">
            Please select one or more roles for this person within this
            organisation
        </p>
    `
})
export class PersonnelRoles {
    @Input() formGroup: FormGroup;
    @Input() allowStudent = false;
    @Input() allowReadOnly = false;
    @Input() allowInstantMission = false;
    @Input() allowFlightLogCollection = false;
    @Input() allowReadOnlyHidden = false;
    @Input() pilotLimitReached = false;
    @Input() totalPilots: number;
    @Input() availablePilots: number;
    @Input() showHelp = true;
    @Input() multipleSubscriptions = false;
    @Input() allowExternalMaintenancePerson = false;

    showPilotSubtypes = false;
    remainingPilots: number;

    PERSON_ROLES = PERSON_ROLES

    private ngUnsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.formGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(roles => {
                if (roles.PILOT && this.pilotLimitReached) {
                    this.formGroup.patchValue({
                        PILOT: false
                    });
                }
                this.showPilotSubtypes = roles.PILOT && !this.pilotLimitReached;
                if (!roles.PILOT) {
                    this.formGroup.patchValue(
                        {
                            PILOT_CREW_PLANNER: false,
                            PILOT_PLANNER: false,
                            PILOT_SUBMITTER: false,
                            FLIGHT_LOG_COLLECTION_OWN: false // Ensure this is also deselected
                        },
                        { emitEvent: false }
                    );
                }
            });
        this.showPilotSubtypes = this.formGroup.value.PILOT;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
