<div class="modal-body-content container-with-footer">
    <div class="modal-scrollbar">
        <enhanced-help
            componentName="organisation-authority-edit"
            [compact]="true"
        >
        </enhanced-help>
        <div class="vertical-container">
            <h4 class="primary-heading top-heading">Authorities</h4>
            <div
                *ngIf="organisation != null"
                class="form-group"
                style="margin-top: 15px"
            >
                <label class="right-buffer">Authority Owner</label>
                <button
                    type="button"
                    class="btn btn-xs btn-pill"
                    (click)="
                        showOrganisationAuthorities
                            ? (showOrganisationAuthorities = false)
                            : (showOrganisationAuthorities = true)
                    "
                    [ngClass]="{ active: showOrganisationAuthorities }"
                >
                    {{ organisation.name }} Authorities
                </button>
                <button
                    type="button"
                    class="btn btn-xs btn-pill"
                    uib-btn-checkbox
                    (click)="
                        showSharedAuthorities
                            ? (showSharedAuthorities = false)
                            : (showSharedAuthorities = true)
                    "
                    [ngClass]="{ active: showSharedAuthorities }"
                    *ngIf="
                        sharedAuthorities != null &&
                        sharedAuthorities.length > 0
                    "
                >
                    Shared Authorities
                </button>
            </div>
            <div class="form-group">
                <jurisdiction-selection
                    [availableJurisdictions]="availableJurisdictions"
                    (selectJurisdictionId)="onSetJurisdictionId($event)"
                >
                    <button
                        type="button"
                        #jurisdictionPopover="bs-popover"
                        class="btn-circle btn-link"
                        [popover]="popTemplate"
                        container="body"
                        [outsideClick]="true"
                        placement="bottom"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                </jurisdiction-selection>
                <div
                    class="horizontal-container vertical-align contents left-buffer top-buffer bottom-buffer"
                    style="margin-top: 24px"
                >
                    <div
                        class="top-buffer right-buffer"
                        style="margin-top: 0; margin-bottom: 0"
                    >
                        <div mdcFormField>
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [(ngModel)]="showArchived"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="onChangeShowArchived()"
                                    />
                                </div>
                            </div>
                            <label mdcFormFieldLabel
                                >Show archived authorities?</label
                            >
                        </div>
                    </div>
                    <ng-container *ngIf="numberOfArchived > 0 && !showArchived">
                        <div @fadeOutOnLeave>
                            <span class="left-buffer hint-block" @fadeInOnEnter>
                                ({{ numberOfArchived }} discontinued or archived
                                {{
                                    numberOfArchived === 1
                                        ? 'authority'
                                        : 'authorities'
                                }})
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div
                *ngIf="registerSummaries != null"
                class="top-buffer form-group"
            >
                <label class="display-label bottom-buffer"
                    >Authority Status</label
                >
                <authority-expiry-summary
                    [registerExpirySummaries]="registerSummaries"
                    [authorityGroups]="createdAuthorities"
                    [showArchived]="showArchived"
                    (onShowRegister)="
                        showRegister(
                            $event.authorityType,
                            $event.authority,
                            $event.register
                        )
                    "
                    (onShowAuthority)="scrollToAuthority($event)"
                >
                </authority-expiry-summary>
            </div>
            <div
                class="top-buffer form-group"
                *ngIf="availableAuthorities != null"
            >
                <label class="display-label">Add Authority</label>
                <p *ngIf="availableAuthorities.length === 0">
                    No Authorities Available to Add
                </p>
                <authority-group-add
                    *ngIf="availableAuthorities.length !== 0"
                    [working]="working"
                    (createAuthority)="createAuthority($event)"
                ></authority-group-add>
            </div>
        </div>
        <div class="container-scroll top-buffer">
            <div
                *ngIf="
                    showSharedAuthorities &&
                    (hasVisibleSharedAuthorities ||
                        (showArchived &&
                            sharedAuthorities != null &&
                            sharedAuthorities.length > 0))
                "
                class="vertical-container"
            >
                <h4 class="primary-heading">Shared Authorities</h4>
                <table>
                    <tr
                        style="vertical-align: top"
                        *ngFor="let authority of sharedAuthorities"
                    >
                        <ng-container
                            *ngIf="showArchived || !hasExpired(authority)"
                        >
                            <td style="width: 40%" @fadeOutCollapseOnLeave>
                                <div @fadeInExpandOnEnter>
                                    <h4>{{ authority.authorityType.name }}</h4>
                                    <p
                                        *ngIf="
                                            authority.authorityType.issuedBy !=
                                            null
                                        "
                                        class="help-block"
                                    >
                                        Issued by
                                        {{
                                            authority.authorityType.issuedBy
                                                .name
                                        }}
                                    </p>
                                    <div>
                                        <a
                                            (click)="
                                                showAuthority(
                                                    authority.authorityType,
                                                    authority
                                                )
                                            "
                                        >
                                            Start: {{ authority.startDate }}
                                            <span
                                                *ngIf="
                                                    authority.authorityType
                                                        .hasExpiry
                                                "
                                                >Expiry:
                                                {{
                                                    authority.expiryDate != null
                                                        ? authority.expiryDate
                                                        : 'None'
                                                }}
                                            </span>
                                        </a>
                                        <p>
                                            {{
                                                authority.authorityType
                                                    .identifierLabel
                                            }}
                                            {{ authority.identifier }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 20%" @fadeOutCollapseOnLeave>
                                <div @fadeInExpandOnEnter>
                                    Shared by {{ authority.organisation.name }}
                                    <organisation-logo
                                        style="height: 100px"
                                        [showEdit]="false"
                                        [organisation]="authority.organisation"
                                        [managingOrganisationId]="
                                            organisation.id
                                        "
                                    >
                                    </organisation-logo>
                                </div>
                            </td>
                            <td style="width: 40%" @fadeOutCollapseOnLeave>
                                <div @fadeInExpandOnEnter></div>
                            </td>
                        </ng-container>
                    </tr>
                </table>
            </div>
            <ng-container *ngIf="showOrganisationAuthorities">
                <ng-container
                    *ngFor="
                        let jurisdiction of inUseJurisdictions
                            | filterById: currentJurisdictionId
                    "
                >
                    <ng-container
                        *ngIf="
                            visibleAuthorities[jurisdiction.id] != null &&
                            (visibleAuthorities[jurisdiction.id].primary
                                .length > 0 ||
                                visibleAuthorities[jurisdiction.id].secondary
                                    .length > 0)
                        "
                    >
                        <h4
                            class="primary-heading"
                            *ngIf="currentJurisdictionId == null"
                        >
                            {{ jurisdiction.name }}
                        </h4>
                        <ng-container
                            *ngIf="
                                visibleAuthorities[jurisdiction.id].primary
                                    .length > 0
                            "
                        >
                            <h5
                                class="top-buffer secondary-heading"
                                style="margin-top: 36px"
                            >
                                Primary
                            </h5>
                            <div
                                class="horizontal-container tiled-children"
                            >
                                <ng-container
                                    *ngFor="
                                        let authorityType of visibleAuthorities[
                                            jurisdiction.id
                                        ].primary
                                    "
                                >
                                    <authority-group-edit
                                        *ngIf="
                                            isInSelectedJurisdiction(
                                                authorityType
                                            ) &&
                                            isInJurisdiction(
                                                authorityType,
                                                jurisdiction.id
                                            )
                                        "
                                        [attr.id]="authorityType.id"
                                        [authorityGroup]="authorityType"
                                        [linkedAuthorities]="
                                            linkedAuthorities[authorityType.id]
                                        "
                                        [dependentAuthorityTypes]="
                                            authoritiesDependedUpon[
                                                authorityType.id
                                            ]
                                        "
                                        [dependentOnAuthorityGroup]="
                                            findDependentOnAuthorityGroups(
                                                authorityType
                                            )
                                        "
                                        [organisation]="organisation"
                                        [managingOrganisationId]="
                                            managingOrganisationId
                                        "
                                        [showArchived]="showArchived"
                                        (onShowAuthority)="
                                            showAuthority(
                                                $event.authorityType,
                                                $event.authority
                                            )
                                        "
                                        (onCreateAuthority)="
                                            createAuthority(
                                                $event.authorityType,
                                                $event.authority
                                            )
                                        "
                                    ></authority-group-edit>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="
                                visibleAuthorities[jurisdiction.id].secondary
                                    .length > 0
                            "
                        >
                            <h5 class="top-buffer secondary-heading">
                                Secondary
                            </h5>
                            <div
                                class="horizontal-container tiled-children"
                            >
                                <ng-container
                                    *ngFor="
                                        let authorityType of visibleAuthorities[
                                            jurisdiction.id
                                        ].secondary
                                    "
                                >
                                    <authority-group-edit
                                        *ngIf="
                                            isInSelectedJurisdiction(
                                                authorityType
                                            ) &&
                                            isInJurisdiction(
                                                authorityType,
                                                jurisdiction.id
                                            )
                                        "
                                        [attr.id]="authorityType.id"
                                        [authorityGroup]="authorityType"
                                        [organisation]="organisation"
                                        [dependentAuthorityTypes]="
                                            authoritiesDependedUpon[
                                                authorityType.id
                                            ]
                                        "
                                        [linkedAuthorities]="
                                            linkedAuthorities[authorityType.id]
                                        "
                                        [dependentOnAuthorityGroup]="
                                            findDependentOnAuthorityGroups(
                                                authorityType
                                            )
                                        "
                                        [managingOrganisationId]="
                                            managingOrganisationId
                                        "
                                        [showArchived]="showArchived"
                                        (onShowAuthority)="
                                            showAuthority(
                                                $event.authorityType,
                                                $event.authority
                                            )
                                        "
                                        (onLinkAuthority)="
                                            linkAuthority(
                                                $event.authorityType,
                                                $event.authority
                                            )
                                        "
                                        (onCreateAuthority)="
                                            createAuthority(
                                                $event.authorityType,
                                                $event.authority
                                            )
                                        "
                                    ></authority-group-edit>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <div *ngIf="status === 'NO_AUTHORITIES'">
                    <div>
                        <div class="alert alert-info" role="alert">
                            No Authorities
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <loading-indicator [isLoading]="status === 'LOADING'"></loading-indicator>
    <div class="action-container" *ngIf="showDone">
        <button
            type="button"
            class="btn btn-primary pull-right"
            (click)="showList()"
        >
            Done
        </button>
    </div>
</div>

<ng-template #popTemplate>
    <organisation-jurisdictions
        [organisation]="organisation"
        [managingOrganisationId]="managingOrganisationId"
        buttonClasses="btn-xs"
        (saved)="jurisdictionsUpdated($event)"
    >
    </organisation-jurisdictions>
</ng-template>
