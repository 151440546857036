import {
    UpdateRpaTypeWorkflowVersionCommand,
    ChangeActiveRpaTypeWorkflowVersionCommand,
    CreateRpaTypeWorkflowCommand,
    RpaTypeWorkflowDto,
    RenameRpaTypeWorkflowCommand,
    CraftDto
} from '../model/api';
import { WorkflowHandler } from './workflowHandler';
import { getMissionWorkflowSteps } from './missionWorkflow.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class RpaTypeWorkflowService implements WorkflowHandler {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findWorkflows(organisationId: number) {
        return this.http.get<RpaTypeWorkflowDto[]>(
            `${this.baseUrl}/webapi/rpaTypeWorkflows`,
            {
                params: httpParamSerializer({ organisationId })
            }
        );
    }

    changeActiveVersion(
        workflowId: number,
        command: ChangeActiveRpaTypeWorkflowVersionCommand
    ) {
        return this.http
            .put<RpaTypeWorkflowDto>(
                `${this.baseUrl}/webapi/rpaTypeWorkflows/${workflowId}/active`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    renameWorkflow(workflowId: number, command: RenameRpaTypeWorkflowCommand) {
        return this.http
            .put<RpaTypeWorkflowDto>(
                `${this.baseUrl}/webapi/rpaTypeWorkflows/${workflowId}/name`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    updateWorkflowVersion(
        workflowId: number,
        command: UpdateRpaTypeWorkflowVersionCommand
    ) {
        return this.http
            .put<RpaTypeWorkflowDto>(
                `${this.baseUrl}/webapi/rpaTypeWorkflows/${workflowId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    createNewWorkflowVersion(command: CreateRpaTypeWorkflowCommand) {
        return this.http
            .post<RpaTypeWorkflowDto>(
                `${this.baseUrl}/webapi/rpaTypeWorkflows`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    archiveWorkflow(workflowId: number) {
        return this.http
            .put<RpaTypeWorkflowDto>(
                `${this.baseUrl}/webapi/rpaTypeWorkflows/${workflowId}/archive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    unarchiveWorkflow(workflowId: number) {
        return this.http
            .put<RpaTypeWorkflowDto>(
                `${this.baseUrl}/webapi/rpaTypeWorkflows/${workflowId}/unarchive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    getWorkflowSteps() {
        return getMissionWorkflowSteps([
            'pre-departure',
            'pre-mission',
            'pre-flight',
            'flying',
            'post-flight',
            'post-mission'
        ]);
    }
}
