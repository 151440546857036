import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    RiskDto,
    RiskRegisterService,
    PreferencesService,
    WorkTracker,
    hasAnyPermission,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';
import { RiskRegisterDialoguesService } from 'libs/risk-register/src/lib/risk-register-dialogues.service';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import { of, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

export const RISK_REGISTER_WIDGET_IDENTIFIER = 'riskRegister';

@Component({
    selector: 'risk-register-widget',
    templateUrl: './risk-register-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class RiskRegisterWidgetComponent implements OnInit, OnDestroy, Widget {
    @Input() organisation: PersonsOrganisationDto;

    public availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    public working: boolean;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    canEdit: boolean;

    risks: RiskDto[];
    userPreferences: TableSetupUserPreferences;

    public IMG_URL: string;

    constructor(
        private riskRegisterService: RiskRegisterService,
        private riskRegisterDialoguesService: RiskRegisterDialoguesService,
        private preferencesService: PreferencesService,
        private workspaceStateService: WorkspaceStateService,
        private logging: FlyFreelyLoggingService,
        flyfreelyConstants: FlyFreelyConstants
    ) {
        this.IMG_URL = flyfreelyConstants.IMG_URL;
    }

    ngOnInit(): void {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshRisks();
                this.refreshPermissions();
                this.loadPreferences();
            });
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.availableColumns = [
            {
                value: 'riskNumber',
                name: 'Risk Number',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: (risk: RiskDto) => this.editRiskEntry(risk)
            },
            {
                value: 'riskCategory',
                name: 'Risk Category',
                searchable: true,
                selectable: false,
                defaultSelection: true,
                linkFunction: (risk: RiskDto) => this.editRiskEntry(risk)
            },
            {
                value: 'riskDescription',
                name: 'Description',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                linkFunction: (risk: RiskDto) => this.editRiskEntry(risk)
            }
        ];

        this.selectedColumns = null;

        this.columnSorting = null;

        this.tableSearch = null;

        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };

        this.refreshRisks();
        this.refreshPermissions();
        this.loadPreferences();

        this.riskRegisterService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshRisks());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        this.canEdit = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
        );
    }

    private refreshRisks() {
        const doneWorking = this.workTracker.createTracker();

        this.riskRegisterService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                risks => {
                    this.risks = risks;
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error refreshing risk registers: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    newRiskEntry() {
        this.riskRegisterDialoguesService
            .showCreateRiskDialogue(this.organisation.id)
            .then(
                () => this.refreshRisks(),
                () => {
                    return;
                }
            );
    }

    editRiskEntry(risk: RiskDto) {
        this.riskRegisterDialoguesService
            .showEditRiskDialogue(risk, this.canEdit)
            .then(
                () => this.refreshRisks(),
                () => {
                    return;
                }
            );
    }

    loadPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferencesService
            .findPreferences('riskRegisterWidgetTable', this.organisation.id)
            .pipe(
                mergeMap(preferences =>
                    preferences == null
                        ? this.preferencesService
                              .findPreferences(
                                  'riskRegisterWidgetColumns',
                                  this.organisation.id
                              )
                              .pipe(
                                  map(pref => ({
                                      selectedColumns: pref,
                                      columnSorting: null,
                                      tableSearch: null,
                                      itemLimit: this.tableConfig.limit
                                  }))
                              )
                        : of(preferences)
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: preferences => {
                    this.userPreferences = preferences;
                    this.selectedColumns = this.userPreferences.selectedColumns;
                    this.columnSorting = this.userPreferences.columnSorting;
                    this.tableSearch = this.userPreferences.tableSearch;
                    this.tableConfig.limit =
                        this.userPreferences.itemLimit != null
                            ? this.userPreferences.itemLimit
                            : this.tableConfig.limit;
                },
                error: () => {
                    this.selectedColumns = findDefaultColumnSelection(
                        this.availableColumns
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'riskRegisterWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }
}

export const riskRegisterWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: RISK_REGISTER_WIDGET_IDENTIFIER,
    component: RiskRegisterWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(organisation, [
            PersonsOrganisationDto.Permissions.MISSION_LIST,
            PersonsOrganisationDto.Permissions.MISSION_VIEW
        ]) && hasFeatureFlag(organisation, 'riskRegister')
};
