import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
    LoggedInUser,
    NameValue,
    OnboardingService,
    PersonDto,
    SimpleOrganisationDto,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

/**
 * This component is used to display a loading spinner while the user is waiting for their profile to load, and then redirect them to the expected organisation, or onboarding.
 *
 * It is expected to only ever show at the root level of the application.
 *
 * See {@link https://decmech.atlassian.net/wiki/spaces/FLYF/pages/2932277254/Signup+Invite+Demo+and+Onboarding+Behaviour}
 */
@Component({
    template: `<ngx-loading [show]="true"></ngx-loading>`
})
export class LandingComponent {
    currentUser: PersonDto;
    currentOrganisations: SimpleOrganisationDto[];

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private userService: UserService,
        private router: Router,
        private onboardingService: OnboardingService
    ) {}

    ngOnInit() {
        combineLatest([
            this.userService.userChange$.pipe(
                filter<LoggedInUser>(c => c.type === UserStatus.LOGGED_IN)
            ),
            this.onboardingService.findDemoOrganisations()
        ])
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(([userChanges, demoOrganisations]) =>
                this.resolveInitialRedirect(
                    userChanges.currentUser,
                    userChanges.currentUsersOrganisations,
                    demoOrganisations
                )
            );
    }

    private resolveInitialRedirect(
        user: PersonDto,
        organisations: SimpleOrganisationDto[],
        demoOrganisations: NameValue<number>[]
    ) {
        const demoOrganisationIdList = demoOrganisations.map(org => org.value);

        const demoOrganisation = organisations.find(org =>
            demoOrganisationIdList.includes(org.id)
        );

        this.currentUser = user;
        this.currentOrganisations = organisations;

        this.onboardingService.findUserOnboardingStatus().then(onboarded => {
            onboarded
                ? this.redirectToDashboard(
                      this.currentUser,
                      this.currentOrganisations
                  )
                : demoOrganisation != null
                ? this.redirectTo(demoOrganisation.id)
                : this.redirectToOnboarding();
        });
    }

    private redirectToDashboard(
        user: PersonDto,
        organisations: SimpleOrganisationDto[]
    ) {
        if (user.defaultOrganisationId != null) {
            this.redirectTo(user.defaultOrganisationId);
            return;
        }

        const commercialOrganisation = organisations.find(
            org => !org.personalOrganisation
        );
        if (commercialOrganisation != null) {
            this.redirectTo(commercialOrganisation.id);
        } else {
            this.redirectTo(user.personalOrganisationId);
        }
    }

    private redirectTo(organisationId: number) {
        this.router.navigate(['home'], {
            queryParams: { organisationId }
        });
    }

    private redirectToOnboarding() {
        this.router.navigate(['onboarding']);
    }
}
