import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { PilotFlightHistoryEntry } from 'libs/flight-history/src/lib/flight-history-data.service';
import { Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GqlQueryResult, PERSON_ROLES } from '.';
import { FlyFreelyConstants } from '../constants';
import {
    CreatePersonOrganisationCommand,
    FlightConformanceResultDto,
    HistoricalPersonDto,
    PersonDto,
    PersonnelRegisterEntryDetailsDto,
    PersonRolesDto,
    PilotFlightAggregatesDto,
    CurrencyResultDto,
    SortieDto,
    UpdatePersonnelRolesCommand,
    UpdatePersonOrganisationCommand,
    CsvBean,
    PersonsOrganisationDto
} from '../model/api';
import { httpParamSerializer } from './service.helpers';

export interface PersonServiceChanges {
    personnel: PersonRolesDto[];
    historicalPersonnel: HistoricalPersonDto[];
}

export interface FindSortieResult {
    count: number;
    results: SortieDto[];
}

@Injectable({
    providedIn: 'root'
})
export class PersonService {
    currentValues: PersonServiceChanges = {
        personnel: [],
        historicalPersonnel: []
    };

    private baseUrl: string;
    private changeSource = new Subject<void>();
    private valuesSource = new Subject<PersonServiceChanges>();
    change$ = this.changeSource.asObservable();
    values$ = this.valuesSource.asObservable();

    constructor(
        private apollo: Apollo,
        constants: FlyFreelyConstants,
        private http: HttpClient
    ) {
        this.baseUrl = constants.SITE_URL;

        this.values$.subscribe(changes => (this.currentValues = changes));
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    markAsChanged() {
        this.changeSource.next();
    }

    findPersonnel(organisationId: number, role?: PersonRolesDto.Roles) {
        return this.http
            .get<PersonRolesDto[]>(`${this.baseUrl}/webapi/personnel`, {
                params: httpParamSerializer({ organisationId, role })
            })
            .pipe(
                tap(personnel =>
                    this.valuesSource.next({
                        ...this.currentValues,
                        personnel: personnel
                    })
                )
            );
    }

    findPersonnelWithPermission(
        organisationId: number,
        permission: PersonsOrganisationDto.Permissions
    ) {
        return this.http
            .get<PersonRolesDto[]>(`${this.baseUrl}/webapi/personnel`, {
                params: httpParamSerializer({ organisationId, permission })
            })
            .pipe(
                tap(personnel =>
                    this.valuesSource.next({
                        ...this.currentValues,
                        personnel: personnel
                    })
                )
            );
    }

    findHistoricalPersonnel(organisationId: number) {
        return this.http
            .get<HistoricalPersonDto[]>(
                `${this.baseUrl}/webapi/personnel/historical`,
                {
                    params: httpParamSerializer({ organisationId })
                }
            )
            .pipe(
                tap(historicalPersonnel =>
                    this.valuesSource.next({
                        ...this.currentValues,
                        historicalPersonnel: historicalPersonnel
                    })
                )
            );
    }

    findRelatedPersonnel(organisationId: number) {
        return this.http.get<PersonDto[]>(
            `${this.baseUrl}/webapi/personnel/all`,
            {
                params: httpParamSerializer({ organisationId })
            }
        );
    }

    findPerson(personId: number, organisationId: number) {
        return this.http.get<PersonRolesDto>(
            `${this.baseUrl}/webapi/personnel/${personId}`,
            {
                params: httpParamSerializer({ organisationId })
            }
        );
    }

    createPerson(command: CreatePersonOrganisationCommand) {
        return this.http
            .post<PersonDto>(`${this.baseUrl}/webapi/personnel`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    updatePerson(personId: number, command: UpdatePersonOrganisationCommand) {
        return this.http
            .put<PersonDto>(
                `${this.baseUrl}/webapi/personnel/${personId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateRoles(
        personId: number,
        personOrganisation: UpdatePersonnelRolesCommand
    ) {
        return this.http
            .put(
                `${this.baseUrl}/webapi/personnel/${personId}/roles`,
                personOrganisation
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findPilotFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
            conformanceResultStatus?: FlightConformanceResultDto.Status[];
        },
        order: 'ASC' | 'DESC',
        page = 0,
        pageSize = 10
    ) {
        return this.apollo
            .query<{
                findPilotFlightHistory: GqlQueryResult<PilotFlightHistoryEntry>;
            }>({
                query: gql`
                    query find(
                        $criteria: PilotFlightHistoryCriteria
                        $page: Int
                        $pageSize: Int
                        $order: Direction
                    ) {
                        findPilotFlightHistory(
                            criteria: $criteria
                            order: $order
                            page: $page
                            pageSize: $pageSize
                        ) {
                            count
                            results {
                                id
                                type
                                missionId
                                flightNumber
                                numberOfFlights
                                startTime
                                endTime
                                duration
                                visualLineOfSight
                                locationName
                                timeOfDay
                                rpaId
                                rpaType {
                                    id
                                    make
                                    model
                                    rpaCategory
                                    performanceSpecifications {
                                        maxTakeOffWeight
                                    }
                                }
                                conformanceResultList {
                                    status
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    order,
                    page,
                    pageSize
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findPilotFlightHistory)
            );
    }

    exportOrganisationPersonnel(
        organisationId: number,
        includeRoles: boolean,
        authorityTypeId?: number[]
    ) {
        const headers = new HttpHeaders({
            'Content-type': 'text/csv;charset=utf-8',
            Accept: 'text/csv;charset=utf-8'
        });

        return this.http.get(`${this.baseUrl}/webapi/personnel/export`, {
            params: httpParamSerializer({
                organisationId,
                includeRoles,
                authorityTypeId
            }),
            responseType: 'blob',
            headers: headers
        });
    }

    findCurrencyResults(
        personId: number,
        personnelRegisterId: number,
        managingOrganisationId: number
    ) {
        return this.http.get<CurrencyResultDto[]>(
            `${this.baseUrl}/webapi/personnel/${personId}/currency`,
            {
                params: httpParamSerializer({
                    personnelRegisterId,
                    managingOrganisationId
                })
            }
        );
    }

    findFlightStatisticsBrief(
        personId: number,
        managingOrganisationId?: number
    ) {
        return this.http.get<PilotFlightAggregatesDto>(
            `${this.baseUrl}/webapi/personnel/${personId}/history/summary`,
            { params: httpParamSerializer({ managingOrganisationId }) }
        );
    }

    getProfilePhotoUrl(
        personId: number,
        managingOrganisationId: number
    ): string {
        return `/webapi/personnel/${personId}/logo?managingOrganisationId=${managingOrganisationId}`;
    }

    findPersonnelRegisterEntryByPersonId(
        personId: number,
        managingOrganisationId: number,
        at: string
    ) {
        return this.http.get<PersonnelRegisterEntryDetailsDto[]>(
            `${this.baseUrl}/webapi/personnel/${personId}/authorityRegisters`,
            { params: httpParamSerializer({ at, managingOrganisationId }) }
        );
    }

    getPersonRoles() {
        return PERSON_ROLES;
    }
}
