<enhanced-help componentName="rpa-model-customisation" [compact]="true">
</enhanced-help>
<!-- inner-tab-container here is a unique class since this is a modal with nested tab sets -->
<div class="inner-tab-container fill modal-scrollbar">
    <p class="help-block">
        Different mission workflows can be used depending on the RPA selected
        for the mission. Setup the workflows using the RPA Type Workflow
        Library below, and then specify default workflows to be used or assign
        workflows to specific RPA Types.
    </p>
    <h4 class="section-heading">
        <span>Customised RPA Types</span>
        <div></div>
    </h4>
    <div
        class="table-container modal-scrollbar"
        style="margin-right: 1em !important"
    >
        <table
            *ngIf="defaultConfigs != null"
            @fadeInExpandOnEnter
            class="table"
        >
            <thead>
                <tr>
                    <th>RPA Type</th>
                    <th *ngFor="let auth of authorities">{{ auth.name }}</th>
                </tr>
            </thead>

            <tbody>
                <ng-container *ngFor="let category of rpaCategories">
                    <h4 class="section-heading">
                        {{ category.name }}
                    </h4>
                    <tr>
                        <td>
                            <b>{{ category.name }} Default</b>
                        </td>
                        <td *ngFor="let auth of authorities">
                            <editable
                                [confirmationButtons]="true"
                                [cancelOnClickOutside]="false"
                                [initialContext]="
                                    getInitialContext(
                                        category.value,
                                        auth.id,
                                        'workflow'
                                    )
                                "
                                (update)="
                                    changeRpaModelWorkflow(
                                        defaultConfigs,
                                        auth.id,
                                        $event.workflow?.id,
                                        category.value
                                    )
                                "
                            >
                                <ng-template viewMode>
                                    {{
                                        getDisplayText(
                                            category.value,
                                            auth.id,
                                            'workflow',
                                            'workflowConfig',
                                            'No workflow in use'
                                        )
                                    }}
                                </ng-template>
                                <ng-template editMode let-context="context">
                                    <ng-select
                                        appendTo="body"
                                        [items]="workflowSelectionList"
                                        bindLabel="name"
                                        placeholder="RPA Type Workflow"
                                        [clearable]="false"
                                        [(ngModel)]="context.workflow"
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </ng-template>
                            </editable>
                            <editable
                                [confirmationButtons]="true"
                                [cancelOnClickOutside]="false"
                                [initialContext]="
                                    getInitialContext(
                                        category.value,
                                        auth.id,
                                        'maintenanceSchedule'
                                    )
                                "
                                (update)="
                                    changeRpaModelMaintenanceSchedule(
                                        defaultConfigs,
                                        auth.id,
                                        $event.maintenanceSchedule.id,
                                        category.value
                                    )
                                "
                            >
                                <ng-template viewMode>
                                    {{
                                        getDisplayText(
                                            category.value,
                                            auth.id,
                                            'maintenanceSchedule',
                                            'maintenanceSchedule',
                                            'No maintenance schedule in use'
                                        )
                                    }}
                                </ng-template>
                                <ng-template editMode let-context="context">
                                    <ng-select
                                        appendTo="body"
                                        [items]="
                                            rpaModelMaintenanceScheduleSelectionList
                                        "
                                        bindLabel="name"
                                        placeholder="Maintenance Schedules"
                                        [clearable]="false"
                                        [(ngModel)]="
                                            context.maintenanceSchedule
                                        "
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </ng-template>
                            </editable>
                        </td>
                    </tr>
                    <tr *ngFor="let rpaModel of customisedRpaModels">
                        <ng-container
                            *ngIf="rpaModel.model.craftType === category.value"
                        >
                            <td>
                                {{ rpaModel.name }}
                                <span
                                    *ngIf="
                                        rpaModel.model.organisationId != null
                                    "
                                    class="status-block custom"
                                    >Custom</span
                                >
                            </td>
                            <td *ngFor="let auth of authorities">
                                <editable
                                    [confirmationButtons]="true"
                                    [cancelOnClickOutside]="false"
                                    [initialContext]="
                                        getCustomInitialContext(
                                            rpaModel,
                                            category.value,
                                            auth.id,
                                            'workflow'
                                        )
                                    "
                                    (update)="
                                        changeRpaModelWorkflow(
                                            rpaModel,
                                            auth.id,
                                            $event.workflow?.id,
                                            category.value
                                        )
                                    "
                                >
                                    <ng-template viewMode>
                                        {{
                                            getCustomDisplayText(
                                                rpaModel,
                                                category.value,
                                                auth.id,
                                                'workflow',
                                                'workflowConfig',
                                                'Using Default Workflow'
                                            )
                                        }}
                                    </ng-template>
                                    <ng-template editMode let-context="context">
                                        <ng-select
                                            appendTo="body"
                                            [items]="workflowSelectionList"
                                            bindLabel="name"
                                            placeholder="RPA Type Workflow"
                                            [clearable]="false"
                                            [(ngModel)]="context.workflow"
                                        >
                                            <ng-template
                                                ng-option-tmp
                                                let-item="item"
                                            >
                                                {{ item.name }}
                                            </ng-template>
                                            <ng-template
                                                ng-label-tmp
                                                let-item="item"
                                            >
                                                {{ item.name }}
                                            </ng-template>
                                        </ng-select>
                                    </ng-template>
                                </editable>

                                <editable
                                    *ngIf="hasScheduledMaintenance"
                                    [confirmationButtons]="true"
                                    [cancelOnClickOutside]="false"
                                    [initialContext]="
                                        getCustomInitialContext(
                                            rpaModel,
                                            category.value,
                                            auth.id,
                                            'maintenanceSchedule'
                                        )
                                    "
                                    (update)="
                                        changeRpaModelMaintenanceSchedule(
                                            rpaModel,
                                            auth.id,
                                            $event.maintenanceSchedule.id,
                                            category.value
                                        )
                                    "
                                >
                                    <ng-template viewMode>
                                        {{
                                            getCustomDisplayText(
                                                rpaModel,
                                                category.value,
                                                auth.id,
                                                'maintenanceSchedule',
                                                'maintenanceSchedule',
                                                'Using Default Maintenance Schedule'
                                            )
                                        }}
                                    </ng-template>
                                    <ng-template editMode let-context="context">
                                        <ng-select
                                            appendTo="body"
                                            [items]="
                                                rpaModelMaintenanceScheduleSelectionList
                                            "
                                            bindLabel="name"
                                            placeholder="Maintenance Schedules"
                                            [clearable]="false"
                                            [(ngModel)]="
                                                context.maintenanceSchedule
                                            "
                                        >
                                            <ng-template
                                                ng-option-tmp
                                                let-item="item"
                                            >
                                                {{ item.name }}
                                            </ng-template>
                                            <ng-template
                                                ng-label-tmp
                                                let-item="item"
                                            >
                                                {{ item.name }}
                                            </ng-template>
                                        </ng-select>
                                    </ng-template>
                                </editable>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <div class="form-group">
        <label>Customise Additional RPA Type</label>
        <div class="input-group">
            <ng-select
                [items]="availableRpaModels"
                bindLabel="name"
                placeholder="Select an RPA Type"
                groupBy="craftType"
                [clearable]="false"
                [(ngModel)]="selectedRpaModel"
                [searchFn]="rpaSearchFn"
            >
                <ng-template ng-option-tmp let-item="item">
                    {{ item | formatRpaType }}
                    <span
                        *ngIf="item.organisationId != null"
                        class="status-block custom"
                        >Custom</span
                    >
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    {{ item | formatRpaType }}
                    <span
                        *ngIf="item.organisationId != null"
                        class="status-block custom"
                        >Custom</span
                    >
                </ng-template>
            </ng-select>

            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="selectedRpaModel == null"
                    (click)="customiseRpaModel()"
                >
                    Customise
                </button>
            </span>
        </div>
    </div>

    <rpa-workflows [organisation]="organisation"></rpa-workflows>
    <loading-indicator [isLoading]="working"></loading-indicator>
</div>
