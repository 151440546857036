import { Injectable } from '@angular/core';
import {
    BatterySetDto,
    BulkEditOptions,
    BulkImportErrorText,
    ColumnOptions,
    ColumnOptionTypes,
    CompleteSortieCommand,
    CraftDto,
    downloadCsv,
    FlyFreelyLoggingService,
    parseCsvByHeadings,
    removeEmptyRows,
    toTimestamp,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
    CHECK_DECIMAL,
    CHECK_IS_TIME_STRING,
    CHECK_IS_TIME_WITH_COLONS
} from 'libs/bulk-uploads/src/lib/checks';
import {
    convertBatterySetSerialToName,
    convertDurationToTimestamp,
    convertRpaSerialToName,
    convertTimestampToSeconds,
    invalidFieldText,
    parseDuration,
    warningFieldText
} from 'libs/bulk-uploads/src/lib/helpers';
import { BulkImportDialogue } from 'libs/bulk-uploads/src/lib/import-dialogue/import-dialogue.component';
import { BulkUploadDialogue } from 'libs/bulk-uploads/src/lib/upload-dialogue/upload-dialogue.component';
import { DisplayableResource } from 'libs/missions/src/lib/mission-completion/mission-completion.service';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import * as moment from 'moment-timezone';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Columns } from 'ngx-easy-table';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ImportSortie extends CompleteSortieCommand {
    dataIndex: number;
}
interface ImportFlight {
    status: string;
    startTime: string;
    duration: string;
    reason: string;
    rpic: string;
    rpa: string;
    batterySet: string;
}

const template: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            {
                key: 'status',
                props: {
                    label: 'Status'
                }
            },
            {
                key: 'startTime',
                props: {
                    label: 'Start Time'
                }
            },
            {
                key: 'duration',
                props: {
                    label: 'Duration'
                }
            },
            {
                key: 'reason',
                props: {
                    label: 'Reason'
                }
            },
            {
                key: 'rpic',
                props: {
                    label: 'Remote Pilot'
                }
            },
            {
                key: 'rpa',
                props: {
                    label: 'RPA'
                }
            },
            {
                key: 'batterySet',
                props: {
                    label: 'Battery Set'
                }
            }
        ]
    },
    props: {
        label: 'Bulk_Flight_Import'
    }
};

const templateData = [
    {
        status: 'Completed',
        startTime: '11:42',
        duration: '3:26:00',
        reason: 'Aerial photography',
        rpic: 'John Doe',
        rpa: 'RPA 1',
        batterySet: 'Battery 1'
    }
];

const columns: Columns[] = [
    { key: 'status', title: 'Status' },
    { key: 'startTime', title: 'Start Time' },
    { key: 'duration', title: 'Duration (s / HH:MM:SS)' },
    { key: 'reason', title: 'Reason' },
    { key: 'rpic', title: 'Remote Pilot' },
    { key: 'rpa', title: 'RPA' },
    { key: 'batterySet', title: 'Battery Set' }
];

const bulkEditOptions: BulkEditOptions = {
    cannotBulkEdit: ['startTime'],
    uniqueInSet: []
};

@Injectable()
export class FlightUploadService {
    missionDate: string;

    uploadDialogue: BsModalRef<BulkUploadDialogue>;
    importDialogue: BsModalRef<BulkImportDialogue>;

    importedFile: ImportFlight[];
    columnOptions: ColumnOptions[];
    columnOptionsSubject = new ReplaySubject<ColumnOptions[]>();
    failedImportTextSubject = new ReplaySubject<BulkImportErrorText[]>();
    bulkEditOptionsSubject = new ReplaySubject<BulkEditOptions>();
    bulkEditOptions$ = this.bulkEditOptionsSubject.asObservable();
    columnOptions$ = this.columnOptionsSubject.asObservable();
    failedImportText$ = this.failedImportTextSubject.asObservable();
    failedImportText: BulkImportErrorText[] = [];
    rowsToImport: ImportFlight[];
    hasFailedRows = false;

    availableRpas: CraftDto[];
    availablePersonnel: DisplayableResource[];
    availableBatterySets: DisplayableResource[];
    allRpas: CraftDto[];
    allBatterySets: BatterySetDto[];
    number: number;

    workingSubject = new ReplaySubject<boolean>();
    flightsSubject = new Subject<ImportSortie[]>();
    flights$ = this.flightsSubject.asObservable();
    working$ = this.workingSubject.asObservable();
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modalService: BsModalService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSubject.next(working));

        this.bulkEditOptionsSubject.next(bulkEditOptions);
    }

    ngOnDestroy() {
        this.flightsSubject.complete();
        this.workingSubject.complete();
        this.columnOptionsSubject.complete();
        this.failedImportTextSubject.complete();
        this.bulkEditOptionsSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    showBulkUpload(
        date: string,
        availableRpas: Observable<CraftDto[]>,
        availablePersonnel: Observable<DisplayableResource[]>,
        availableBatterySets: Observable<BatterySetDto[]>,
        allRpas: Observable<CraftDto[]>,
        allBatterySets: Observable<BatterySetDto[]>
    ) {
        this.missionDate = date;
        this.updateAvailable(
            availableRpas,
            availablePersonnel,
            availableBatterySets,
            allRpas,
            allBatterySets
        );
        this.uploadDialogue = this.modalService.show(BulkUploadDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                uploadService: this,
                hasTemplate: true,
                entity: 'Flight'
            }
        });
    }

    updateAvailable(
        availableRpas: Observable<CraftDto[]>,
        availablePersonnel: Observable<DisplayableResource[]>,
        availableBatterySets: Observable<BatterySetDto[]>,
        allRpas: Observable<CraftDto[]>,
        allBatterySets: Observable<BatterySetDto[]>
    ) {
        availableRpas
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(r => (this.availableRpas = r));
        allRpas
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(r => (this.allRpas = r));
        availablePersonnel
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(p => (this.availablePersonnel = p));
        availableBatterySets.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
            b =>
                (this.availableBatterySets = b.map(set => ({
                    id: set.id,
                    name: set.name
                })))
        );
        allBatterySets
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(b => (this.allBatterySets = b));
    }

    importFile(file: File) {
        this.importedFile = null;
        parseCsvByHeadings<ImportFlight>(file, {
            Status: 'status',
            'Start Time': 'startTime',
            Duration: 'duration',
            Reason: 'reason',
            'Remote Pilot': 'rpic',
            RPA: 'rpa',
            'Battery Set': 'batterySet'
        }).then(result => {
            // Sometimes the csv parser doesn't filter out empty rows.
            // The filtered variable is a redundancy check to ensure empty rows are ignored.
            const filtered = removeEmptyRows<ImportFlight[]>(result);
            filtered.forEach(row => this.setDefaultValues(row));
            this.importedFile = filtered;
            this.setupColumnOptions();
            this.importedFile.forEach((row, i) => {
                row.duration = this.buildDurationValue(row.duration);
                this.validateRow(row, i, this.columnOptions);
            });
            this.showImportDialogue();
            this.uploadDialogue.hide();
        });
    }

    private buildDurationValue(duration: string) {
        let formattedDuration;
        // Check if the duration format might be MM:ss
        // If it is, add 00: to the start so the parsers work
        if (
            !CHECK_DECIMAL.test(duration) &&
            !CHECK_IS_TIME_WITH_COLONS.test(duration) &&
            CHECK_IS_TIME_STRING.test(duration) &&
            duration.match(CHECK_IS_TIME_STRING)[5] === '' &&
            duration.includes(':') &&
            duration.split(':').length === 2
        ) {
            formattedDuration = ['00']
                .concat(
                    duration.split(':').map(i => (i.length === 2 ? i : `0${i}`))
                )
                .join(':');
        } else {
            formattedDuration = duration;
        }
        return convertDurationToTimestamp(
            this.convertDecimalToHours(formattedDuration)
        );
    }

    private convertDecimalToHours(duration: string) {
        if (duration.match(CHECK_DECIMAL) == null) {
            return duration;
        } else {
            const decimal = (parseFloat(duration) / 3600).toFixed(2);
            return decimal;
        }
    }

    setDefaultValues(row: ImportFlight) {
        if (
            !row.status ||
            (row.status.trim().toLowerCase() !== 'completed' &&
                row.status.trim().toLowerCase() !== 'aborted')
        ) {
            row.status = 'Completed';
        }
        if (row.rpa != null) {
            row.rpa = convertRpaSerialToName(row.rpa, this.allRpas);
        }
        if (row.batterySet != null) {
            row.batterySet = convertBatterySetSerialToName(
                row.batterySet,
                this.allBatterySets
            );
        }
        row.duration = parseDuration(row.duration);
        return row;
    }

    setupColumnOptions() {
        this.columnOptions = [
            {
                columnName: 'status',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: [
                    { name: 'Completed', value: 'Completed' },
                    { name: 'Aborted', value: 'Aborted' }
                ]
            },
            {
                columnName: 'rpa',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: this.availableRpas
                    .map(r => ({ name: r.nickname, value: r.nickname }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
            },
            {
                columnName: 'rpic',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: this.availablePersonnel
                    .map(p => ({ name: p.name, value: p.name }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
            },
            {
                columnName: 'batterySet',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: this.availableBatterySets
                    .map(b => ({ name: b.name, value: b.name }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
            }
        ];
        this.columnOptionsSubject.next(this.columnOptions);
    }

    validateRow(
        row: ImportFlight,
        rowIndex: number,
        columnOptions: ColumnOptions[]
    ) {
        // Validate each row and add ' *INVALID*' markers to any invalid
        if (
            row.status &&
            row.status.trim().toLowerCase() !== 'completed' &&
            row.status.trim().toLowerCase() !== 'aborted'
        ) {
            this.updateErrorText(
                rowIndex,
                'status',
                `The status: "${row.status}" should be either "Completed" or "Aborted".`
            );
            row.status = `${row.status} ${invalidFieldText}`;
        }
        if (row.duration) {
            if (row.duration.match(CHECK_IS_TIME_WITH_COLONS) == null) {
                this.updateErrorText(
                    rowIndex,
                    'duration',
                    `The duration: ${row.duration} is invalid. Please ensure this is in HH:MM:SS format.`
                );
                row.duration = `${row.duration} ${invalidFieldText}`;
            } else if (
                CHECK_IS_TIME_WITH_COLONS.test(row.duration) &&
                convertTimestampToSeconds(row.duration) < 60 &&
                row.status.trim().toLowerCase() !== 'aborted'
            ) {
                this.updateErrorText(
                    rowIndex,
                    'duration',
                    `This duration is abnormally short, which could indicate an aborted flight. Please ensure this flight's status is correct. (Note: This won't prevent you from importing this flight)`
                );
                row.duration = `${row.duration} ${warningFieldText}`;
            }
        }
        if (
            row.startTime &&
            (row.startTime.length > 5 || row.startTime.split(':').length > 2)
        ) {
            this.updateErrorText(
                rowIndex,
                'startTime',
                `The start time: ${row.startTime} is invalid. Please ensure this is in the format of HH:MM`
            );
            row.startTime = `${row.startTime} ${invalidFieldText}`;
        }
        if (
            row.rpa &&
            this.availableRpas.findIndex(
                c => c.nickname.toLowerCase() === row.rpa.trim().toLowerCase()
            ) === -1
        ) {
            this.updateErrorText(
                rowIndex,
                'rpa',
                `${row.rpa} is not an RPA model on this mission.Please ensure this model is correct and has been set up for your organisation`
            );
            row.rpa = `${row.rpa} ${invalidFieldText}`;
        }
        if (
            row.rpic &&
            this.availablePersonnel.findIndex(
                p => p.name.toLowerCase() === row.rpic.trim().toLowerCase()
            ) === -1
        ) {
            this.updateErrorText(
                rowIndex,
                'rpa',
                `${row.rpic} is not a remote pilot on this mission. Please ensure this is correct and that this person has been set up for your /rganisation`
            );
            row.rpic = `${row.rpic} ${invalidFieldText}`;
        }
        if (
            row.batterySet &&
            this.availableBatterySets.findIndex(
                b =>
                    b.name.toLowerCase() === row.batterySet.trim().toLowerCase()
            ) === -1
        ) {
            this.updateErrorText(
                rowIndex,
                'batterySet',
                `${row.batterySet} is not a known battery set.Please ensure this is correct and has been set up for your organisation`
            );
            row.batterySet = `${row.batterySet} ${invalidFieldText}`;
        }
        this.failedImportTextSubject.next(this.failedImportText);

        return row;
    }

    showImportDialogue() {
        this.importDialogue = this.modalService.show(BulkImportDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                uploadService: this,
                data: this.importedFile,
                columns: columns,
                entity: 'Flight'
            }
        });
    }

    import(selected: number[], columnOptions: ColumnOptions[]) {
        const data: ImportFlight[] = selected.map(n => this.importedFile[n]);
        /**
         * Validity checks with individual feedback for clarity.
         */
        const failed: number[] = [];
        data.map((e, i) => {
            if (Object.keys(e).filter(key => e[key] === 'INVALID').length > 0) {
                failed.push(i);
            }
        });

        data.forEach((entry, i) => {
            const rowIndex = this.importedFile.findIndex(e => e === entry);
            if (
                entry.status.length > 0 &&
                entry.status.trim().toLowerCase() !== 'completed' &&
                entry.status.trim().toLowerCase() !== 'aborted'
            ) {
                this.updateErrorText(
                    rowIndex,
                    'status',
                    `The status: "${entry.status}" should be either "Completed" or "Aborted".`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.duration.length > 0 &&
                entry.duration.match(CHECK_IS_TIME_WITH_COLONS) == null
            ) {
                this.updateErrorText(
                    rowIndex,
                    'duration',
                    `The duration: ${entry.duration} is invalid. Please ensure this is in HH:MM:SS format.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.startTime.length > 0 &&
                (entry.startTime.length > 5 ||
                    entry.startTime.split(':').length > 2)
            ) {
                this.updateErrorText(
                    rowIndex,
                    'startTime',
                    `The start time: ${entry.startTime} is invalid. Please ensure this is in the format of HH:MM`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.rpa.length > 0 &&
                this.availableRpas.findIndex(
                    c =>
                        c.nickname.toLowerCase() ===
                        entry.rpa.trim().toLowerCase()
                ) === -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'rpa',
                    `${entry.rpa} is not an RPA model on this mission.Please ensure this model is correct and has been set up for your organisation`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.rpic.length > 0 &&
                this.availablePersonnel.findIndex(
                    p =>
                        p.name.toLowerCase() === entry.rpic.trim().toLowerCase()
                ) === -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'rpa',
                    `${entry.rpic} is not a remote pilot on this mission. Please ensure this is correct and that this person has been set up for your /rganisation`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.batterySet.length > 0 &&
                this.availableBatterySets.findIndex(
                    b =>
                        b.name.toLowerCase() ===
                        entry.batterySet.trim().toLowerCase()
                ) === -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'batterySet',
                    `${entry.batterySet} is not a known battery set.Please ensure this is correct and has been set up for your organisation`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
        });

        const validEntries = data.filter(
            (e, i) => failed.includes(i) === false
        );

        if (validEntries.length > 0) {
            const command: ImportSortie[] = validEntries.map(
                (entry, index) => ({
                    equipmentIds: [],
                    status:
                        entry.status.trim().toUpperCase() === 'ABORTED'
                            ? CompleteSortieCommand.Status.ABORTED
                            : CompleteSortieCommand.Status.COMPLETED,
                    number: null,
                    reason: entry.reason,
                    durationSource: CompleteSortieCommand.DurationSource.MANUAL,
                    manualDuration: convertTimestampToSeconds(entry.duration),
                    manualStartTime: this.parseStartTime(entry.startTime),
                    batterySetId: entry.batterySet
                        ? this.availableBatterySets.find(
                              b =>
                                  b.name.toLowerCase() ===
                                  entry.batterySet.trim().toLowerCase()
                          ).id
                        : null,
                    craftId: entry.rpa
                        ? this.availableRpas.find(
                              c =>
                                  c.nickname.toLowerCase() ===
                                  entry.rpa.trim().toLowerCase()
                          ).id
                        : null,
                    pilotId: entry.rpic
                        ? this.availablePersonnel.find(
                              p =>
                                  p.name.toLowerCase() ===
                                  entry.rpic.trim().toLowerCase()
                          ).id
                        : null,
                    dataIndex: index
                })
            );
            this.flightsSubject.next(command);
            this.rowsToImport = validEntries;
            if (failed.length > 0) {
                this.hasFailedRows = true;
            } else {
                this.hasFailedRows = false;
            }
        }
    }

    hideDialogue(failed: boolean) {
        if (!this.hasFailedRows && !failed) {
            this.importDialogue.hide();
            return;
        }
        if (!failed) {
            this.importDialogue.content.importedRowIndices =
                this.importedFile.map((row, i) => {
                    if (this.rowsToImport.includes(row)) {
                        return i;
                    }
                });
            this.importedFile = this.importedFile.filter(
                e => this.rowsToImport.includes(e) === false
            );
            this.importDialogue.content.onFailedRows();
            this.logging.warn(
                'Some imported rows have failed, please fix all errors and try again'
            );
            this.failedImportTextSubject.next(this.failedImportText);
            this.failedImportText = [];
            return;
        }
        this.rowsToImport = [];
        this.importDialogue.content.onFailedRows();
        this.failedImportTextSubject.next(this.failedImportText);
        this.failedImportText = [];
        return;
    }

    parseStartTime(time: string) {
        if (!time.length) {
            return;
        }
        if (time.indexOf(':') === 1 && time.length === 4) {
            time = `0${time}`;
        }
        const d = toTimestamp(
            moment(this.missionDate)
                .hours(parseInt(time.slice(0, 2), 10))
                .minutes(parseInt(time.slice(3), 10))
                .toDate()
        );
        const date = this.missionDate.toLocaleString().slice(0, 10);
        const parsedTime = `T${time}:00Z`;
        const parsedDate = `${date}${parsedTime}`;
        return d;
    }

    downloadTemplate() {
        downloadCsv(template, templateData);
    }

    updateErrorText(rowIndex: number, key: string, text: string) {
        if (
            this.failedImportText.findIndex(
                t => t.key === key && t.rowIndex === rowIndex
            ) !== -1
        ) {
            return;
        } else {
            this.failedImportText.push({
                rowIndex: rowIndex,
                key: key,
                text: text
            });
        }
    }
}
