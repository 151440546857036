<ng-container [formGroup]="formGroup">
    <div class="horizontal-container">
        <div class="col-xs-6">
            <label class="control-label">Component Type</label>
            <ng-select
                *ngIf="!isRequired"
                [items]="components"
                bindValue="id"
                formControlName="componentId"
                appendTo="body"
                [clearable]="false"
            >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    <div (mouseover)="getAttachmentUrl.emit(item.id)">
                        <span
                            *ngIf="
                                item.componentType.iconFamily ===
                                    'FONT_AWESOME' &&
                                item.componentType.icon != null
                            "
                            [class]="
                                'right-buffer fal fa-' + item.componentType.icon
                            "
                        ></span
                        >{{ item.componentType.name }} {{ item.designation }}
                    </div>
                </ng-template>
            </ng-select>
            <p *ngIf="isRequired">
                <span [class]="findComponentIcon()"></span>&nbsp;{{
                    findComponentName()
                }}
            </p>
        </div>
        <div class="col-xs-6">
            <label class="control-label">Task Type</label>
            <ng-select
                *ngIf="!isRequired"
                [items]="taskTypes"
                bindValue="id"
                formControlName="taskTypeId"
                appendTo="body"
                [clearable]="false"
            >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    <span
                        *ngIf="
                            item.iconFamily === 'FONT_AWESOME' &&
                            item.icon != null
                        "
                        [class]="'right-buffer fal fa-' + item.icon"
                    ></span
                    >{{ item.name }}
                </ng-template>
            </ng-select>
            <p *ngIf="isRequired">
                <span [class]="findTaskIcon()"></span>&nbsp;{{
                    findTaskTypeName()
                }}
            </p>
        </div>
    </div>
    <div
        class="horizontal-container"
        *ngIf="hasSerialNumber || hasFirmwareVersion"
    >
        <div class="col-xs-6" *ngIf="hasSerialNumber">
            <label class="control-label">Serial Number</label>
            <input
                [disableControl]="!isReplacing"
                class="form-control"
                formControlName="serialNumber"
            />
        </div>
        <div class="col-xs-6" *ngIf="hasFirmwareVersion">
            <label class="control-label">Firmware Version</label>
            <input class="form-control" formControlName="firmwareVersion" />
        </div>
    </div>
    <div class="horizontal-container">
        <div class="col-xs-10">
            <label class="control-label">Notes</label>
            <input class="form-control" formControlName="notes" />
        </div>
        <div class="col-xs-3 align-bottom">
            <button
                type="button"
                class="btn btn-primary btn-sm pull-right"
                (click)="saveComponent()"
                [disabled]="formGroup.invalid || formGroup.pristine || working"
                placement="top"
                tooltip="Save"
            >
                Save
            </button>
            <button
                type="button"
                class="btn btn-link btn-sm pull-right"
                (click)="hideForm()"
                [disabled]="working"
                placement="top"
                tooltip="Cancel"
            >
                Cancel
            </button>
        </div>
    </div>
</ng-container>
