import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    BatteryDto,
    BatterySetDto,
    CompletedDocumentationDto,
    CraftDto,
    EasyTableEventObject,
    EquipmentDto,
    getMissionWorkflowSteps,
    PersonDto,
    PersonsOrganisationDto,
    ServiceabilitySignoffDto,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { getOrElse } from 'fp-ts/es6/Option';
import { FlightLogsDialoguesService } from 'libs/flight-logs/src/lib/flight-logs-dialogues.service';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { Columns, Config, DefaultConfig, Event } from 'ngx-easy-table';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    FlightHistoryDataService,
    LoggedFlight
} from '../flight-history-data.service';

enum LogType {
    RPA = 'RPA',
    PERSON = 'PERSON',
    BATTERY = 'BATTERY',
    BATTERY_SET = 'BATTERY_SET',
    EQUIPMENT = 'EQUIPMENT'
}
interface FlightDocumentation {
    [missionId: number]: { [sortieId: number]: CompletedDocumentationDto };
}

interface ServiceabilitySignoffs {
    [missionId: number]: ServiceabilitySignoffDto;
}

const equipmentTableConfig: Columns[] = [
    {
        key: 'date',
        title: 'Date',
        orderEnabled: true,
        orderEventOnly: true
    },
    { key: 'missionId', title: 'Mission', orderEnabled: false },
    { key: 'number', title: 'Flight Number', orderEnabled: false },
    { key: 'duration', title: 'Flight Time', orderEnabled: false }
];

const personTableConfig: Columns[] = [
    {
        key: 'date',
        title: 'Date',
        orderEnabled: true,
        orderEventOnly: true
    },
    { key: 'missionId', title: 'Mission', orderEnabled: false },
    { key: 'number', title: 'Flight Number', orderEnabled: false },
    { key: 'visualLineOfSight', title: 'VLOS Type', orderEnabled: false },
    { key: 'timeOfDay', title: 'Time Type', orderEnabled: false },
    { key: 'duration', title: 'Flight Time', orderEnabled: false }
];

@Component({
    selector: 'logged-flights',
    templateUrl: './logged-flights.component.html',
    styleUrls: ['./logged-flights.component.scss'],
    providers: [FlightHistoryDataService]
})
export class LoggedFlights implements OnChanges {
    @Input() managingOrganisationId: number;
    @Input() person: PersonDto;
    @Input() aircraft: CraftDto;
    @Input() battery: BatteryDto;
    @Input() batterySet: BatterySetDto;
    @Input() equipment: EquipmentDto;

    organisation: PersonsOrganisationDto;

    steps = getMissionWorkflowSteps([]).filter(
        s => ['pre-flight', 'flying', 'post-flight'].indexOf(s.value) !== -1
    );

    logType: LogType;
    returnedLogs: LoggedFlight[];

    historyDocumentation: FlightDocumentation;
    serviceabilitySignoffs: ServiceabilitySignoffs;

    public columns: Columns[] = equipmentTableConfig;
    public configuration: Config;
    public pagination = {
        limit: 10,
        offset: 1,
        count: -1
    };
    sorting: 'ASC' | 'DESC' = 'DESC';

    error = false;

    private workTracker = new WorkTracker();
    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionDialoguesService: MissionDialoguesService,
        private userService: UserService,
        private flightHistoryDataService: FlightHistoryDataService,
        private flightLogDialoguesService: FlightLogsDialoguesService
    ) {
        this.configuration = {
            ...DefaultConfig
        };
    }

    ngOnInit() {
        this.organisation = getOrElse(() => null)(
            this.userService.findOrganisationForUser(
                this.managingOrganisationId
            )
        );

        combineLatest([
            this.workTracker.observable,
            this.flightHistoryDataService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([loading, working]) => {
                this.working = working || loading;
            });

        combineLatest([
            this.flightHistoryDataService.flightHistory$,
            this.flightHistoryDataService.currentPage$,
            this.flightHistoryDataService.totalItems$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([logs, page, total]) => {
                this.returnedLogs = logs;
                this.pagination.offset = page + 1;
                this.pagination.count = total;
                this.pagination = { ...this.pagination };
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('aircraft' in changes) {
            this.logType = LogType.RPA;
            this.refreshAircraftFlightHistory();
        }
        if ('person' in changes) {
            this.logType = LogType.PERSON;
            this.refreshPersonFlightHistory();
            this.columns = personTableConfig;
        }
        if ('battery' in changes) {
            this.logType = LogType.BATTERY;
            this.refreshBatteryFlightHistory();
        }
        if ('batterySet' in changes) {
            this.logType = LogType.BATTERY_SET;
            this.refreshBatterySetFlightHistory();
        }
        if ('equipment' in changes) {
            this.logType = LogType.EQUIPMENT;
            this.refreshEquipmentFlightHistory();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAircraftFlightHistory() {
        if (this.aircraft == null) {
            return;
        }

        this.flightHistoryDataService.findRpaFlightHistory(
            {
                id: this.aircraft.id,
                managingOrganisationId: this.managingOrganisationId
            },
            this.sorting,
            this.pagination.offset - 1,
            this.pagination.limit
        );
    }

    // FIXME should be a pipe
    signoffMessage(signoff: ServiceabilitySignoffDto) {
        if (signoff == null) {
            return;
        }
        const serviceable: string =
            signoff.serviceability ===
            ServiceabilitySignoffDto.Serviceability.SERVICEABLE
                ? 'Serviceable'
                : 'Unserviceable';

        const person: string = signoff.signoffPerson.lastName
            ? `${signoff.signoffPerson.firstName} ${signoff.signoffPerson.lastName}`
            : `${signoff.signoffPerson.firstName}`;

        return `${serviceable} by ${person}`;
    }

    private refreshPersonFlightHistory() {
        if (this.person == null) {
            return;
        }

        this.flightHistoryDataService.findPilotFlightHistory(
            {
                id: this.person.id,
                managingOrganisationId: this.managingOrganisationId
            },
            this.sorting,
            this.pagination.offset - 1,
            this.pagination.limit
        );
    }

    private refreshBatteryFlightHistory() {
        if (this.battery == null) {
            return;
        }

        this.flightHistoryDataService.findBatteryFlightHistory(
            {
                id: this.battery.id,
                managingOrganisationId: this.managingOrganisationId
            },
            this.sorting,
            this.pagination.offset - 1,
            this.pagination.limit
        );
    }

    private refreshBatterySetFlightHistory() {
        if (this.batterySet == null) {
            return;
        }

        this.flightHistoryDataService.findBatterySetFlightHistory(
            {
                id: this.batterySet.id,
                managingOrganisationId: this.managingOrganisationId
            },
            this.sorting,
            this.pagination.offset - 1,
            this.pagination.limit
        );
    }

    private refreshEquipmentFlightHistory() {
        if (this.equipment == null) {
            return;
        }

        this.flightHistoryDataService.findEquipmentFlightHistory(
            {
                id: this.equipment.id,
                managingOrganisationId: this.managingOrganisationId
            },
            this.sorting,
            this.pagination.offset - 1,
            this.pagination.limit
        );
    }

    showMission(missionId: number) {
        this.missionDialoguesService.showMissionDetails(
            missionId,
            false,
            this.organisation
        );
    }

    showFlightLog(flightLogId: number) {
        this.flightLogDialoguesService.showFlightLog(
            flightLogId,
            this.managingOrganisationId,
            null,
            null
        );
    }

    onTableEvent(event: { event: string; value: any }) {
        if (event.event !== 'onClick' && event.event !== Event.onOrder) {
            this.pageChanged(event);
        }
        if (event.event === Event.onOrder) {
            const order = event.value.order === 'asc' ? 'ASC' : 'DESC';
            if (order !== this.sorting) {
                this.sorting = order;
            }
            this.pageChanged(event);
        }
    }

    pageChanged(event: EasyTableEventObject) {
        this.pagination.limit = event.value.limit
            ? event.value.limit
            : this.pagination.limit;
        this.pagination.offset = event.value.page
            ? event.value.page
            : this.pagination.offset;
        this.pagination = { ...this.pagination };
        switch (this.logType) {
            case LogType.RPA:
                return this.refreshAircraftFlightHistory();

            case LogType.BATTERY:
                return this.refreshBatteryFlightHistory();

            case LogType.BATTERY_SET:
                return this.refreshBatterySetFlightHistory();

            case LogType.PERSON:
                return this.refreshPersonFlightHistory();
        }
    }
}
