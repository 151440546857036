<div class="card-container-row">
    <div class="attachment-list" *ngIf="attachmentList?.length">
        <div *ngFor="let attachment of attachmentList" class="attachment-item">
            <span [ngClass]="getFileIcon(attachment.contentType)"></span>
            <a class="text-dark-gray" (click)="showContent(attachment)">{{ attachment.name }}</a>
        </div>
    </div>

    <div class="link-list no-text-break" *ngIf="linkList?.length">
        <div class="link-item" *ngFor="let link of linkList">
            <a class="text-dark-gray " (click)="onLinkClick(link)">
                <i class="fa-solid fa-up-right-from-square color-blue"></i>
                {{ link.title }}
            </a>
            <button
                *ngIf="allowDelete"
                type="button"
                (click)="deleteLink(link.id)"
                class="remove-button bg-transparent border-none"
            >
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>
</div>
