import { Component, Input } from '@angular/core';
import {
    CurrencyResultDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonService,
    WorkTracker,
    operatingConditionsValueLookup
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'personnel-currency',
    templateUrl: './personnel-currency.component.html',
    styleUrls: ['../personnel-registers/styles.scss']
})
export class PersonnelCurrencyComponent {
    @Input() personId: number;
    @Input() managingOrganisationId: number;
    @Input() authorityId: number;

    currencies: CurrencyResultDto[];
    operatingConditions = operatingConditionsValueLookup;

    private ngUnsubscribe$ = new Subject<void>();
    working: boolean;
    private workTracker = new WorkTracker();
    constructor(
        private personService: PersonService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshCurrency();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshCurrency() {
        this.personService
            .findCurrencyResults(
                this.personId,
                this.authorityId,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                currencies => (this.currencies = currencies),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching currency data: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }
}
