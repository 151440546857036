<div class="modal-body">
    <button
        *ngIf="!photo"
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="camera-container">
        <div class="image-container">
            <button
                *ngIf="!photo && multipleCameras"
                type="button"
                class="switch"
                (click)="switchCamera()"
            >
                <span class="fal fa-repeat"></span>
            </button>
            <webcam
                *ngIf="!photo"
                [width]="imgWidth"
                [height]="imgHeight"
                [trigger]="triggerSource"
                (imageCapture)="photoTaken($event)"
                [allowCameraSwitch]="false"
                [switchCamera]="switchCamSource"
                [captureImageData]="true"
                mirrorImage="never"
                (initError)="handleInitError($event)"
            ></webcam>
            <image-cropper
                *ngIf="!outputImage && photo"
                [imageChangedEvent]="photo"
                [imageURL]="imgSrc"
                [maintainAspectRatio]="true"
                [aspectRatio]="1"
                alignImage="center"
                format="jpeg"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
            <img *ngIf="outputImage" class="dispImage" [src]="outputImage" />
        </div>
    </div>
    <p class="error">{{ error }}</p>
    <div class="custom--btn">
        <button
            *ngIf="!photo"
            type="button"
            class="next"
            (click)="takePhoto()"
            [disabled]="error != null"
        >
            Take Photo
        </button>
        <button *ngIf="photo" type="button" class="discard" (click)="close()">
            Discard
        </button>
        <button *ngIf="photo" type="button" class="next" (click)="usePhoto()">
            Save
        </button>
    </div>
</div>
