<ng-container screenAnalytics="location-edit">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-locations.png" />
        </div>
        <h3 class="modal-title">{{ locationId ? 'Edit' : 'Add' }} Location</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="formGroup">
        <div
            class="modal-body horizontal-container max-height no-padding"
            #drawingArea
        >
            <div
                class="map-sidebar container-scroll feature-groups-container"
                [ngClass]="{ expanded: true }"
            >
                <ng-container *ngIf="map != null">
                    <enhanced-help componentName="feature-add" [compact]="true">
                    </enhanced-help>
                    <div
                        class="card"
                        *ngFor="let featureGroup of featureGroups"
                    >
                        <div class="header horizontal-container">
                            <h4 class="fill">{{ featureGroup.name }}</h4>
                            <button
                                type="button"
                                [disabled]="
                                    isEditing ||
                                    isAddingFeature ||
                                    showAirspaceDisclaimer
                                "
                                class="btn btn-circle"
                                (click)="addNewFeature(featureGroup)"
                            >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>
                        <div
                            *ngFor="
                                let feature of featureGroup.existingFeatures
                            "
                            class="horizontal-container"
                            [ngClass]="{
                                hover: feature.id === hoverFeatureId,
                                selected: feature.id === editFeatureId
                            }"
                            (mouseenter)="
                                highlightFeature(featureGroup, feature.id)
                            "
                            (mouseleave)="
                                unhighlightFeature(featureGroup, feature.id)
                            "
                        >
                            <span class="feature-name"
                                ><a (click)="onFeatureNameSelected(feature)">{{
                                    feature.name
                                }}</a></span
                            >
                        </div>
                    </div>
                </ng-container>
            </div>
            <flyfreely-map
                #map
                airspaceCheckResult
                class="fill"
                [organisationId]="organisationId"
                [features]="featureGroups"
                (featuresUpdated)="onFeaturesUpdated($event)"
                (featureSelected)="onFeatureSelected($event)"
                (featureUnselected)="onFeatureUnselected()"
                (geocodeResultSelected)="onGeocodeResultSelected($event)"
                [showEditCompletion]="false"
                [showFeatureSelection]="false"
                (fullscreenRequested)="onFullscreenRequested()"
                (editModeChanged)="onEditModeChanged($event)"
                (featuresLoaded)="onFeaturesLoaded()"
                (featureHover)="onFeatureHover($event)"
                [featurePopupTemplate]="featurePopupTemplate"
                [startTime]="airspaceValue?.startTime"
                [endTime]="airspaceValue?.endTime"
                [ruleset]="airspaceValue?.airspaceRuleset"
                (mapReady)="onMapReady()"
                (jurisdictionChanged)="onMapJurisdictionChanged($event)"
            >
                <ng-template mapSidebar let-map="map">
                    <location-airspace-check
                        [organisationId]="organisationId"
                        [timeZone]="location?.timeZone"
                        [flightArea]="flightArea"
                        [missionValues]="missionValues"
                        [workTracker]="workTracker"
                        [jurisdiction]="map.jurisdictionChanged | async"
                        (checkerParametersChange)="airspaceValue = $event"
                    ></location-airspace-check>
                </ng-template>
            </flyfreely-map>
            <inline-modal
                *ngIf="showAirspaceDisclaimer"
                [show]="showAirspaceDisclaimer"
                [sizeSmall]="true"
                [topSpacing]="true"
                style="max-height: fit-content"
            >
                <airspace-disclaimer
                    screenIdentifier="location-edit"
                    [airspaceJurisdiction]="mapJurisdiction"
                    (done)="onAirspaceDisclaimerAcknowledged()"
                ></airspace-disclaimer>
            </inline-modal>
        </div>

        <div class="modal-footer">
            <div class="horizontal-container fill verical-align">
                <div class="fill form-flex">
                    <label>Name&nbsp;</label>
                    <input
                        type="text"
                        class="form-control"
                        (keyup)="$event.stopPropagation()"
                        formControlName="name"
                    />
                </div>

                <div>
                    <button
                        [disabled]="!hasAnyFeatures"
                        class="btn btn-link"
                        type="button"
                        (click)="downloadKml()"
                        analyticsOn
                        analyticsAction="download-kml"
                    >
                        Download KML
                    </button>
                    <button
                        type="button"
                        class="btn btn-default"
                        ngfSelect
                        [multiple]="false"
                        accept="application/vnd.google-earth.kml\+xml,application/vnd.google-earth.kmz,application/zip,.kml,.kmz"
                        (fileChange)="uploadLocation($event)"
                        placement="top"
                        tooltip="Upload KML, KMZ or Shape files (as a zip)"
                        enhancedHelp="upload-geometry"
                    >
                        Upload Geometry
                    </button>
                    <button
                        *ngIf="missionValues == null"
                        type="button"
                        class="btn btn-default"
                        analyticsOn
                        analyticsAction="create-mission"
                        [disabled]="
                            formGroup.invalid ||
                            !hasRequiredFeatures ||
                            working ||
                            isAddingFeature
                        "
                        (click)="createMission()"
                    >
                        Create Mission
                    </button>

                    <button
                        class="btn btn-primary relative"
                        type="button"
                        (click)="save()"
                        [disabled]="
                            formGroup.invalid ||
                            !hasRequiredFeatures ||
                            working ||
                            isAddingFeature
                        "
                    >
                        Save
                        <!-- Provide contextual tooltips for the disabled states if not working -->
                        <span
                            class="overlay"
                            [tooltip]="
                                formGroup.invalid
                                    ? 'Enter a valid name for this location'
                                    : !hasRequiredFeatures
                                    ? 'Locations require at least one flight area or corridor'
                                    : isAddingFeature
                                    ? 'Please finish adding the current map feature before saving'
                                    : undefined
                            "
                            placement="left"
                        ></span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-container>

<ng-template #featurePopupTemplate>
    <map-marker-toolbar
        *ngIf="selectedFeature"
        [feature]="selectedFeature.feature"
        [featureGroup]="selectedFeatureGroup"
        (onFeatureChange)="onSelectedFeatureUpdated($event)"
        (onFeatureEdit)="onFeatureEdit($event)"
        (onFeatureDelete)="onFeatureDelete($event)"
    ></map-marker-toolbar>
</ng-template>
