import { Pipe, PipeTransform } from '@angular/core';
import { setupStatusFormatter } from '../helpers';
import { airspaceAuthorisationTypeList } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatAuthorisationType' })
export class FormatAirspaceAuthorisationTypePipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor() {
        this.transform = setupStatusFormatter(airspaceAuthorisationTypeList);
    }
}
