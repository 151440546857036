import { Component, Input, SimpleChanges } from '@angular/core';
import {
    buildDisplayCondition,
    buildDisplayValue,
    defaultBuildFormAttachmentUrl,
    ControlDisplayFlags,
    FormResponseDto,
    range,
    readControlValue
} from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'form-response',
    templateUrl: './form-response.component.html'
})
export class FormResponse {
    @Input() organisationId: number;
    @Input() response: FormResponseDto;
    @Input() buildFormAttachmentUrl: typeof defaultBuildFormAttachmentUrl;

    displayValues: any;

    displayConditions: ControlDisplayFlags;

    ngOnChanges(changes: SimpleChanges) {
        if ('response' in changes || 'organisationId' in changes) {
            this.setupValues();
        }
    }

    range(list: any[]) {
        if (list == null) {
            return [];
        }
        return range(0, list.length);
    }

    /**
     * Compute the display values for all of the form data.
     *
     */
    private setupValues() {
        this.displayValues = {};

        if (!this.response) {
            return;
        }

        this.displayConditions = buildDisplayCondition(
            this.response.sections,
            this.response.values
        );

        const computeAttachmentUrl = (
            attachmentId: number,
            attachmentVersionId: number
        ) => {
            const buildFormAttachmentUrl =
                this.buildFormAttachmentUrl ?? defaultBuildFormAttachmentUrl;
            return buildFormAttachmentUrl(
                this.organisationId,
                this.response.formId,
                attachmentId,
                attachmentVersionId
            );
        };

        for (const section of this.response.sections) {
            for (const control of section.controls) {
                if (section.repeatingGroup) {
                    if (this.response.values[control.id] == null) {
                        this.displayValues[control.id] = [];
                    } else {
                        this.displayValues[control.id] = this.response.values[
                            control.id
                        ].map(v =>
                            buildDisplayValue(
                                this.response.formId,
                                control,
                                v,
                                computeAttachmentUrl
                            )
                        );
                    }
                } else {
                    this.displayValues[control.id] = buildDisplayValue(
                        this.response.formId,
                        control,
                        readControlValue(this.response.values, control.id, 0),
                        computeAttachmentUrl
                    );
                }
            }
        }
    }
}
