import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    AirspaceJurisdictionDto,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InviteDto,
    JurisdictionService,
    LoggedInUser,
    NameValue,
    OrganisationService,
    PersonalOnboardingPropertyOptions,
    ProfileService,
    SimpleAirspaceJurisdictionDto,
    UpdatePersonalOnboardingProperties,
    UserLocationService,
    UserService,
    UserStatus,
    OnboardingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Angulartics2 } from 'angulartics2';
import { Country } from 'libs/addresses/src/lib/address-edit/address-edit-dialogue.component';
import CountryStates from 'libs/ui/src/lib/data/country-states.json';
import { basicUserProfileFields } from 'libs/user-profile/src/lib/fieldsets';
import { Subject, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { LogoSelection } from '../../logo-selection';
import { OnboardingDialoguesService } from '../../onboarding-dialogues.service';
import { OnboardingData } from '../../onboarding-screens-data.service';
import { PersonalDetailsDataService } from '../../personal-details-data.service';

const countryStates = CountryStates as Country[];

@Component({
    selector: 'personal-details',
    templateUrl: './personal-details.component.html',
    styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
    formGroup: FormGroup;
    hasCreatedDummyData = false;
    personalDetailsFields: FormlyFieldConfig[] = basicUserProfileFields()
        .filter(f => f.key !== 'arn')
        .map(f => ({
            ...f,
            wrappers: ['inline-form-field'],
            props: {
                ...f.props,
                placeholder: f.props.label,
                description: null
            }
        }));

    person: any;
    profilePhoneNumber: string;
    hasLogo: boolean;
    selectedJurisdiction: SimpleAirspaceJurisdictionDto;
    hasInvite = false;
    canCreateOrganisation = true;
    organisationDomainExists = false;
    hasExistingOrganisations = false;

    jurisdictions: AirspaceJurisdictionDto[];
    unlistedJurisdictions: NameValue[];
    unlistedJurisdictionRequested = false;

    personalPropertyOptions: PersonalOnboardingPropertyOptions = {
        jurisdictions: null,
        companySize: null,
        industry: null
    };

    genericJurisdictionSelected: boolean;

    @ViewChild('logoImage', { static: true }) logoImage: LogoSelection;

    private ngUnsubscribe$: Subject<void> = new Subject();
    private workTracker = new WorkTracker();
    public working = false;

    constructor(
        private fb: FormBuilder,
        private obsds: OnboardingData,
        private jurisdictionService: JurisdictionService,
        private organisationService: OrganisationService,
        private userService: UserService,
        private profileService: ProfileService,
        private onboardingService: OnboardingService,
        private logging: FlyFreelyLoggingService,
        private userLocationService: UserLocationService,
        private onboardingDialoguesService: OnboardingDialoguesService,
        private personalDetailsDataservice: PersonalDetailsDataService,
        private angulartics2: Angulartics2,
        private changeDetector: ChangeDetectorRef
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.formGroup = new FormGroup({
            profileImage: new FormControl(undefined, [Validators.required]),
            accountDetails: new FormGroup({
                firstName: new FormControl(undefined, [Validators.required]),
                lastName: new FormControl(undefined, [Validators.required]),
                phoneNumber: new FormControl(undefined, [
                    Validators.minLength(10)
                ])
            }),
            jurisdictionSetup: new FormGroup({
                jurisdiction: new FormControl([], [Validators.required]),
                unlistedJurisdiction: new FormControl(undefined),
                industryType: new FormControl(undefined, [Validators.required]),
                droneUse: new FormControl(undefined, [Validators.required]),
                companyName: new FormControl(undefined),
                prefillDummyData: new FormControl(true)
            })
        });
        combineLatest([
            this.personalDetailsDataservice.invites$,
            this.personalDetailsDataservice.createOrganisationsResult$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([invites, createOrganisationResults]) => {
                this.hasInvite =
                    invites.filter(i => i.status == InviteDto.Status.PENDING)
                        .length > 0 && this.obsds.hasAcceptedInvite === false;
                this.canCreateOrganisation =
                    createOrganisationResults.canCreateOrganisation;
                this.organisationDomainExists =
                    createOrganisationResults.organisationDomainExists;
                this.hasExistingOrganisations =
                    createOrganisationResults.hasExistingOrganisations;
                if (
                    this.hasInvite ||
                    !this.canCreateOrganisation ||
                    this.organisationDomainExists ||
                    this.hasExistingOrganisations
                ) {
                    const jurisdictionGroup = <FormGroup>(
                        this.formGroup.controls.jurisdictionSetup
                    );
                    jurisdictionGroup.controls.industryType.clearValidators();
                    jurisdictionGroup.controls.industryType.updateValueAndValidity();
                    jurisdictionGroup.controls.droneUse.clearValidators();
                    jurisdictionGroup.controls.droneUse.updateValueAndValidity();
                    jurisdictionGroup.controls.companyName.clearValidators();
                    jurisdictionGroup.controls.companyName.updateValueAndValidity();
                }
            });
    }
    ngOnInit() {
        this.droneUse.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                if (
                    value !== '1' &&
                    !this.hasInvite &&
                    this.canCreateOrganisation &&
                    !this.organisationDomainExists &&
                    !this.hasExistingOrganisations
                ) {
                    this.jurisdictionSetup.controls.companyName.setValidators([
                        Validators.required,
                        Validators.minLength(3)
                    ]);
                } else {
                    this.jurisdictionSetup.controls.companyName.clearValidators();
                    this.jurisdictionSetup.controls.companyName.reset();
                }
                this.formGroup.updateValueAndValidity();
                this.changeDetector.detectChanges();
            });
        this.userService.userChange$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(c => c.type === UserStatus.LOGGED_IN)
            )
            .subscribe((resp: LoggedInUser) => {
                this.profilePhoneNumber = resp.currentUser.phoneNumber ?? '';
                this.person = {
                    ...resp.currentUser,
                    phoneNumber:
                        this.profilePhoneNumber == null ||
                        this.profilePhoneNumber[0] !== '+'
                            ? this.findCountryCode()
                            : this.profilePhoneNumber
                };
                this.loadLogoFromFile(this.person.personalOrganisationId).add(
                    () => {
                        this.formGroup.controls.profileImage.markAsUntouched();

                        this.changeDetector.detectChanges();
                    }
                );
                if (this.person.id) {
                    this.organisationService
                        .findOrganisationsForPerson(this.person.id)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe({
                            next: orgs => {
                                const personalOrg = orgs.find(
                                    org =>
                                        org.id ===
                                        this.person.personalOrganisationId
                                );
                                this.hasLogo = personalOrg.hasLogo ?? false;
                                if (personalOrg.activeJurisdictions) {
                                    this.selectedJurisdiction =
                                        personalOrg.activeJurisdictions[0];

                                    (<FormGroup>(
                                        this.formGroup.controls
                                            .jurisdictionSetup
                                    )).patchValue({
                                        jurisdiction:
                                            personalOrg.activeJurisdictions[0]
                                    });
                                }
                                this.changeDetector.detectChanges();
                            },
                            error: (error: FlyFreelyError) => {
                                this.logging.error(
                                    error,
                                    `Error while finding organisation details for user: ${error.message}`
                                );
                            }
                        });
                }

                this.changeDetector.detectChanges();
            });
        this.hasCreatedDummyData = this.obsds.checkPersonalDummyData();
        if (this.hasCreatedDummyData) {
            (<FormGroup>(
                this.formGroup.controls.jurisdictionSetup
            )).controls.prefillDummyData.disable();
            (<FormGroup>(
                this.formGroup.controls.jurisdictionSetup
            )).controls.prefillDummyData.setValue(true);
        }

        this.refreshData();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshData() {
        forkJoin([
            this.onboardingService.findPersonalPropertyOptions(),
            this.jurisdictionService.findJurisdictions()
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([personalPropertyOptions, jurisdictions]) => {
                    this.unlistedJurisdictions =
                        personalPropertyOptions.jurisdictions;
                    this.personalPropertyOptions = personalPropertyOptions;
                    this.jurisdictions = jurisdictions
                        .filter(j => j.name !== 'Generic / Unlisted')
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .concat(
                            jurisdictions.filter(
                                j => j.name === 'Generic / Unlisted'
                            )
                        );
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while refreshing data: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    loadLogoFromURL(imageUrl: string) {
        return new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                this.formGroup.controls.profileImage.setValue(xhr.response);
                resolve();
            };
            xhr.onerror = () => reject();
            xhr.open('GET', imageUrl);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    loadLogoFromFile(organisationid: number) {
        return this.organisationService
            .getLogo(organisationid)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (logo: Blob) => {
                    if (logo != null) {
                        this.formGroup.controls.profileImage.setValue(logo);
                        this.changeDetector.detectChanges();
                    } else {
                        return this.loadLogoFromURL(
                            'images/onboarding/FF-Onboarding-Commercial-UploadLogo.png'
                        );
                    }
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error fetching organisation logo: ${error.message}`
                    );
                    return this.loadLogoFromURL(
                        'images/onboarding/FF-Onboarding-Commercial-UploadLogo.png'
                    ).then(() => {
                        this.formGroup.controls.profileImage.markAsUntouched();

                        this.changeDetector.detectChanges();
                    });
                }
            });
    }

    onJurisdictionChanged(name: string) {
        this.genericJurisdictionSelected = name === 'Generic / Unlisted';
        if (name !== 'Generic / Unlisted') {
            this.jurisdictionSetup.controls.unlistedJurisdiction.clearValidators();
            this.jurisdictionSetup.controls.unlistedJurisdiction.reset();
        } else {
            this.jurisdictionSetup.controls.unlistedJurisdiction.setValidators(
                Validators.required
            );
        }
        this.jurisdictionSetup.controls.unlistedJurisdiction.updateValueAndValidity();
        this.changeDetector.detectChanges();
    }

    submitUnlistedJurisdiction() {
        const value =
            this.jurisdictionSetup.controls.unlistedJurisdiction.value;
        if (value == null) {
            return;
        }
        this.onboardingService
            .setUnlistedJurisdictions({ jurisdiction: value })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success(`Successfully requested ${value}`);
                    this.unlistedJurisdictionRequested = true;
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error requesting jurisdiction: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());

        this.angulartics2.eventTrack.next({
            action: 'jurisdiction-request',
            properties: {
                category: 'onboarding',
                label: value
            }
        });
    }

    selectImg(selectedElm: any) {
        const imageUrl = selectedElm.attributes.src.value;
        this.hasLogo = true;
        const result = this.loadLogoFromURL(imageUrl);
    }

    next() {
        const { profileImage, accountDetails, jurisdictionSetup } =
            this.formGroup.value;
        const personalPropertyCommand: UpdatePersonalOnboardingProperties = {
            companyName: jurisdictionSetup.companyName,
            companySize: jurisdictionSetup.droneUse,
            industry: jurisdictionSetup.industryType,
            jurisdiction: jurisdictionSetup.unlistedJurisdiction
        };

        this.obsds.setBusinessName(personalPropertyCommand.companyName);

        this.organisationService
            .updateJurisdictions(this.person.personalOrganisationId, [
                jurisdictionSetup.jurisdiction.id
            ])
            .pipe(
                mergeMap(() =>
                    this.profileService.updateDetails({
                        ...accountDetails
                    })
                ),
                mergeMap(() =>
                    this.onboardingService.setPersonalPropertyOptions(
                        personalPropertyCommand
                    )
                ),
                mergeMap(() => {
                    if (!this.formGroup.controls.profileImage.untouched) {
                        return this.organisationService.uploadLogo(
                            profileImage,
                            this.person.personalOrganisationId
                        );
                    } else {
                        return of(null);
                    }
                }),
                mergeMap(() => {
                    if (
                        jurisdictionSetup.prefillDummyData &&
                        !this.obsds.hasCreatedPersonalDummyData
                    ) {
                        return this.onboardingService
                            .createDummyEquipment(
                                this.person.personalOrganisationId
                            )
                            .pipe(
                                catchError(error => of(undefined)),
                                tap(() => this.obsds.createdPersonalDummyData())
                            );
                    }
                    return of(null);
                }),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.obsds.personalDetailsCompleted(
                        jurisdictionSetup.jurisdiction.id
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error saving details: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    showHelp(text: string, title: string) {
        this.onboardingDialoguesService.showHelp(text, title);
    }

    findCountryCode() {
        this.userLocationService
            .findUserLocation()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: res => {
                    const country = countryStates.find(
                        c => c.iso === res.value
                    );
                    this.person = {
                        ...this.person,
                        phoneNumber: `+${country.phonePrefix}${
                            this.profilePhoneNumber[0] === '0'
                                ? this.profilePhoneNumber.slice(1)
                                : this.profilePhoneNumber
                        }`
                    };
                },
                error: DO_NOTHING
            });
    }

    get jurisdictionSetup() {
        return this.formGroup.get('jurisdictionSetup') as FormGroup;
    }

    get droneUse() {
        return this.jurisdictionSetup.get('droneUse') as FormControl;
    }
}
