import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FeatureProperties, MapModes } from '../constants';
import { createBufferFromLine } from '../utils';
import { buildAreaLineFeature } from './draw-area-line';

const DrawAreaLineSelect = { ...MapboxDraw.modes.direct_select };

class AreaLineSelectState {
    dragMoveLocation: any;
    boxSelectStartLocation: any;
    boxSelectElement: any;
    boxSelecting: boolean;
    canBoxSelect: boolean;
    dragMoving: boolean;
    canDragMove: boolean;
    featureId: string;
    selectedCoordPaths: any[];
    feature: any; // DrawFeature for the line

    polygon: any; // DrawFeature for the area
}

/**
 * The select will be initialised with the centre line string and the buffer width, and
 * everything else will just be calculated
 * @param opts
 */
DrawAreaLineSelect.onSetup = function (opts: any) {
    const state: AreaLineSelectState = {
        dragMoveLocation: null,
        boxSelectStartLocation: null,
        boxSelectElement: undefined,
        boxSelecting: false,
        canBoxSelect: false,
        dragMoving: false,
        canDragMove: true,
        featureId: opts.featureId,
        polygon: null,
        feature: null,
        selectedCoordPaths: opts.coordPath ? [opts.coordPath] : []
    };

    this.setActionableState({
        trash: true,
        combineFeatures: false,
        uncombineFeatures: false
    });

    state.feature = this.getFeature(opts.featureId);

    const buffer = createBufferFromLine(
        state.feature.toGeoJSON().geometry,
        state.feature.properties.lineOffsetMeters
    );

    state.polygon = this.newFeature({
        type: 'Feature',
        properties: {
            meta: FeatureProperties.AREA_LINE,
            customDrawMode: MapModes.DRAW_AREA_LINE_SELECT,
            type: 'Polygon',
            parent: state.feature.id
        },
        geometry: {
            type: 'Polygon',
            coordinates: []
        }
    });
    state.polygon.incomingCoords(buffer.geometry.coordinates);
    this.addFeature(state.polygon);
    // state.feature.properties.relatedFeatureIds = [state.polygon.id];

    this.setSelected([state.feature.id]);
    this.setSelectedCoordinates(
        // @ts-ignore: not in the current type
        this.pathsToCoordinates(state.feature.id, state.selectedCoordPaths)
    );

    return state;
};

// @ts-ignore: not in the current type
DrawAreaLineSelect.updateBuffer = function (state: AreaLineSelectState) {
    const lineGeoJson = state.feature.toGeoJSON();

    const buffer = createBufferFromLine(
        lineGeoJson.geometry,
        state.feature.properties.lineOffsetMeters
    );
    if (buffer) {
        state.polygon.incomingCoords(buffer.geometry.coordinates);
    }
};

// @ts-ignore: not in the current type
const oldDragVertex = DrawAreaLineSelect.dragVertex;
// @ts-ignore: not in the current type
DrawAreaLineSelect.dragVertex = function (
    state: AreaLineSelectState,
    e: any,
    delta: any
) {
    oldDragVertex(state, e, delta);
    this.updateBuffer(state);
};

// @ts-ignore: not in the current type
DrawAreaLineSelect.dragFeature = function (
    state: AreaLineSelectState,
    e: any,
    delta: any
) {
    MapboxDraw.lib.moveFeatures(this.getSelected(), delta);
    state.dragMoveLocation = e.lngLat;
    this.updateBuffer(state);
};

// @ts-ignore: not in the current type
DrawAreaLineSelect.fireUpdate = function () {
    const line = this.getSelected()[0].toGeoJSON();
    this.map.fire(MapboxDraw.constants.events.UPDATE, {
        features: [buildAreaLineFeature(line.geometry, line.properties)]
    });
};

export default DrawAreaLineSelect;
