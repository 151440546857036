<ng-container screenAnalytics="personnel-view">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-personnel.png" />
        </div>
        <h3 class="modal-title">
            {{ isEditing !== 'NONE' ? 'Edit' : 'View' }} Personnel
        </h3>
        <p class="modal-title padding">
            {{ person.firstName }} {{ person.lastName }}
        </p>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body inner-container no-footer">
        <tabset
            #personTabs
            vertical="true"
            type="pills"
            class="tab-set-vertical"
        >
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-id-badge tab-title-icon"></i>
                    <span>User Details</span>
                </ng-template>
                <personnel-details
                    class="modal-body-content"
                    *ngIf="isEditing !== 'DETAILS'"
                    [person]="person"
                    [managingOrganisationId]="organisationId"
                    [canEdit]="canEdit"
                    [licences]="licences"
                    (edit)="editDetails()"
                >
                </personnel-details>
                <personnel-edit
                    #personEdit
                    class="modal-body-content"
                    *ngIf="isEditing === 'DETAILS'"
                    [person]="person"
                    [organisationId]="organisationId"
                    [licences]="licences"
                    (cancelled)="stopEditing()"
                    (personUpdated)="onPersonUpdated($event)"
                >
                </personnel-edit>
            </tab>
            <tab *ngIf="hasFlightHistory" [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-share-nodes tab-title-icon"></i>
                    <span>Flight History</span>
                </ng-template>

                <div class="modal-body-content container-with-footer">
                    <div class="vertical-container">
                        <pilot-flight-history-summary
                            [person]="person"
                            [managingOrganisationId]="organisationId"
                        ></pilot-flight-history-summary>

                        <detailed-flight-history
                            class="fill"
                            [currentUser]="person"
                            [canEdit]="canEdit && canManage"
                            [managingOrganisationId]="organisationId"
                        ></detailed-flight-history>
                    </div>
                    <div class="action-container">
                        <print-personnel-reports
                            [person]="person"
                            [managingOrganisationId]="organisationId"
                        >
                        </print-personnel-reports>
                    </div>
                </div>
            </tab>
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-credit-card-blank tab-title-icon"></i>
                    <span>Licenses &amp; Authorities</span>
                </ng-template>
                <organisation-authority-list
                    class="modal-body-content"
                    *ngIf="isEditing !== 'AUTHORITIES'"
                    [organisationId]="person.personalOrganisationId"
                    [managingOrganisationId]="organisationId"
                    [entityType]="'person'"
                    [canEdit]="canEdit && canManage"
                    icon="/icons/icon-personnel.png"
                    (edit)="editAuthority()"
                >
                </organisation-authority-list>
                <organisation-authority-edit
                    *ngIf="isEditing === 'AUTHORITIES'"
                    [organisationId]="person.personalOrganisationId"
                    [managingOrganisationId]="organisationId"
                    icon="/icons/icon-personnel.png"
                    (done)="stopEditing()"
                    [showDone]="true"
                >
                </organisation-authority-edit>
            </tab>
            <tab *ngIf="person && person.roles" [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-address-card tab-title-icon"></i>
                    <span>Registers</span>
                </ng-template>
                <personnel-registers-status
                    class="modal-body-content"
                    [managingOrganisationId]="organisationId"
                    [personId]="person.id"
                    [canEdit]="canEdit"
                    [canAdminOrganisation]="canAdminOrganisation"
                    [missionDetails]="missionDetails"
                    [hasCurrency]="hasCurrency"
                >
                </personnel-registers-status>
            </tab>
        </tabset>
    </div>
</ng-container>
