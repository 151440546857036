/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.45-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UpdateFlightLogCollectionSourceCommand { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The frequency at which files are checked
     */
    collectionFrequency: UpdateFlightLogCollectionSourceCommand.CollectionFrequency;
    /**
     * The password for the account the files are being collected from
     */
    password?: string;
}
export namespace UpdateFlightLogCollectionSourceCommand {
    export type CollectionFrequency = 'NEVER' | 'FIVE_MINUTES' | 'HOURLY' | 'DAILY';
    export const CollectionFrequency = {
        NEVER: 'NEVER' as CollectionFrequency,
        FIVE_MINUTES: 'FIVE_MINUTES' as CollectionFrequency,
        HOURLY: 'HOURLY' as CollectionFrequency,
        DAILY: 'DAILY' as CollectionFrequency
    };
}



