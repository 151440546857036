/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.45-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RegisterEntryVerificationActivityDto } from './registerEntryVerificationActivityDto';
import { SimpleAuthorityDto } from './simpleAuthorityDto';
import { PersonDto } from './personDto';
import { AuthorityRegisterSummaryDto } from './authorityRegisterSummaryDto';
import { AuthorityConditionDto } from './authorityConditionDto';


/**
 * 
 */
export interface PersonnelRegisterEntryDetailsDto { 
    /**
     * The ID of the register entry. There may be multiple entries for a given entity.
     */
    id: number;
    /**
     * The ID of the authority register that this entry is attached to
     */
    authorityRegisterId: number;
    /**
     * The ID of the person that is registered
     */
    personId: number;
    /**
     * The date that this entity ceased bing on the register
     */
    startDate: string;
    /**
     * The date that this entity ceased bing on the register
     */
    endDate?: string;
    person?: PersonDto;
    /**
     * The status calculation source. Either AUTO or an override status.
     */
    statusSource: PersonnelRegisterEntryDetailsDto.StatusSource;
    authorityRegister?: AuthorityRegisterSummaryDto;
    authority?: SimpleAuthorityDto;
    /**
     * The overall computed status of the entry
     */
    status: PersonnelRegisterEntryDetailsDto.Status;
    /**
     * The individual condition check results
     */
    checks: Array<RegisterEntryVerificationActivityDto>;
    conditions?: Array<AuthorityConditionDto>;
}
export namespace PersonnelRegisterEntryDetailsDto {
    export type StatusSource = 'PENDING' | 'ACTIVE' | 'SUSPENDED' | 'EXPIRED' | 'NOT_ON_REGISTER' | 'NOT_AVAILABLE' | 'AUTO';
    export const StatusSource = {
        PENDING: 'PENDING' as StatusSource,
        ACTIVE: 'ACTIVE' as StatusSource,
        SUSPENDED: 'SUSPENDED' as StatusSource,
        EXPIRED: 'EXPIRED' as StatusSource,
        NOT_ON_REGISTER: 'NOT_ON_REGISTER' as StatusSource,
        NOT_AVAILABLE: 'NOT_AVAILABLE' as StatusSource,
        AUTO: 'AUTO' as StatusSource
    };
    export type Status = 'PENDING' | 'ACTIVE' | 'SUSPENDED' | 'EXPIRED' | 'NOT_ON_REGISTER' | 'NOT_AVAILABLE' | 'AUTO';
    export const Status = {
        PENDING: 'PENDING' as Status,
        ACTIVE: 'ACTIVE' as Status,
        SUSPENDED: 'SUSPENDED' as Status,
        EXPIRED: 'EXPIRED' as Status,
        NOT_ON_REGISTER: 'NOT_ON_REGISTER' as Status,
        NOT_AVAILABLE: 'NOT_AVAILABLE' as Status,
        AUTO: 'AUTO' as Status
    };
}



