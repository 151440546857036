<form [formGroup]="formGroup">
    <div class="personal_details_container main_container">
        <div
            formGroupName="accountDetails"
            class="onboarding-frame personaldetail_frameone personaldetail_frame"
            [ngClass]="{
                notfinished: formGroup.controls.accountDetails.invalid
            }"
        >
            <div class="personaldetail_frametwo--container">
                <formly-form
                    style="flex: 1"
                    *ngIf="personalDetailsFields != null"
                    [form]="formGroup.controls.accountDetails"
                    [fields]="personalDetailsFields"
                    [model]="person"
                ></formly-form>
            </div>
        </div>

        <div
            formGroupName="jurisdictionSetup"
            class="onboarding-frame personaldetails_middleframe personaldetail_frame"
            [ngClass]="{
                notfinished: formGroup.controls.jurisdictionSetup.invalid
            }"
        >
            <div class="personaldetail_frame--input">
                <label>Jurisdiction (Country of Operation)</label>
                <div class="horizontal-container bottom-buffer vertical-align">
                    <ng-select
                        style="flex: 1"
                        [items]="jurisdictions"
                        bindLabel="name"
                        placeholder="Jurisdiction"
                        [clearable]="false"
                        [searchable]="true"
                        (change)="onJurisdictionChanged($event.name)"
                        formControlName="jurisdiction"
                    >
                    </ng-select>
                    <field-validation-indicator
                        [valid]="jurisdictionSetup.controls.jurisdiction.valid"
                        [inline]="true"
                        [placement]="'left'"
                    >
                    </field-validation-indicator>
                </div>
                <div *ngIf="genericJurisdictionSelected">
                    <div
                        class="horizontal-container vertical-align bottom-buffer"
                    >
                        <ng-select
                            style="flex: 1"
                            [items]="unlistedJurisdictions"
                            [readonly]="working"
                            bindLabel="name"
                            bindValue="value"
                            placeholder="Select a jurisdiction"
                            [clearable]="false"
                            [searchable]="true"
                            [addTag]="true"
                            formControlName="unlistedJurisdiction"
                        >
                        </ng-select>
                        <action-required
                            *ngIf="genericJurisdictionSelected"
                            [nonCritical]="true"
                            [noPopover]="true"
                            tooltipText="You have selected a jurisdiction that we currently don't have. You will be setup with generic authorities,  workflows and checklists which will enable you to use the system, but may not meet local regulatory requirements. We add jurisdictions based on demand. By selecting your jurisdiction from the list you register your interest and we will let you know when it's ready to go."
                        >
                        </action-required>
                        <field-validation-indicator
                            [valid]="
                                jurisdictionSetup.controls.unlistedJurisdiction
                                    .valid
                            "
                            [inline]="true"
                            [placement]="'left'"
                        >
                        </field-validation-indicator>
                    </div>
                </div>
                <ng-container
                    *ngIf="
                        !hasInvite &&
                        canCreateOrganisation &&
                        !organisationDomainExists &&
                        !hasExistingOrganisations
                    "
                >
                    <label>Industry Type</label>
                    <div class="horizontal-container bottom-buffer">
                        <ng-select
                            style="flex: 1"
                            [items]="personalPropertyOptions.industry"
                            bindLabel="name"
                            bindValue="value"
                            placeholder="Industry Type"
                            [clearable]="false"
                            [searchable]="true"
                            formControlName="industryType"
                        >
                        </ng-select>
                        <field-validation-indicator
                            [valid]="
                                jurisdictionSetup.controls.industryType.valid
                            "
                            [inline]="true"
                            [placement]="'left'"
                        >
                        </field-validation-indicator>
                    </div>
                    <label>Drone Use</label>
                    <div
                        class="horizontal-container bottom-buffer space-between"
                    >
                        <div
                            class="input-group"
                            btnRadioGroup
                            formControlName="droneUse"
                            style="padding-top: 7px"
                        >
                            <ng-container
                                *ngFor="
                                    let size of personalPropertyOptions.companySize
                                "
                            >
                                <label
                                    class="btn btn-sm btn-pill"
                                    [btnRadio]="size.value"
                                    [disabled]="working"
                                >
                                    {{ size.name }}
                                </label>
                            </ng-container>
                        </div>
                        <field-validation-indicator
                            [valid]="droneUse.valid"
                            [inline]="true"
                            [placement]="'left'"
                        >
                        </field-validation-indicator>
                    </div>
                    <ng-container *ngIf="droneUse.value != '1'">
                        <label>Company Name</label>
                        <div class="horizontal-container bottom-buffer">
                            <input
                                type="text"
                                formControlName="companyName"
                                class="form-control"
                                minlength="3"
                                [readonly]="working"
                            />
                            <field-validation-indicator
                                [valid]="
                                    jurisdictionSetup.controls.companyName.valid
                                "
                                [inline]="true"
                                [placement]="'left'"
                            >
                            </field-validation-indicator>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <!--  -->
            <br />
        </div>
        <div
            class="onboarding-frame personaldetail_framethree personaldetail_frame"
            [ngClass]="{ notfinished: formGroup.controls.profileImage.invalid }"
        >
            <div class="row_top">
                <logo-selection
                    formControlName="profileImage"
                    noun="profile picture"
                    [hasLogo]="hasLogo"
                >
                </logo-selection>
            </div>
            <div class="img_seperator">OR CHOOSE</div>
            <div class="row_bottom">
                <img
                    imgPath="/onboarding/img1.jpg"
                    (click)="selectImg(previewImage1)"
                    #previewImage1
                />
                <img
                    imgPath="/onboarding/img2.jpg"
                    (click)="selectImg(previewImage2)"
                    #previewImage2
                />
                <img
                    imgPath="/onboarding/img3.jpg"
                    (click)="selectImg(previewImage3)"
                    #previewImage3
                />
            </div>
        </div>

        <div class="custom--btn">
            <button
                class="next"
                name="personaldetails_next"
                [disabled]="!formGroup.valid || working"
                (click)="next()"
            >
                Next
            </button>
        </div>
    </div>
</form>
