import { Injectable } from '@angular/core';
import {
    ComponentGroupDto,
    ComponentTypeDto,
    CreateMaintenanceScheduleCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    FormService,
    FormSummaryDto,
    MaintenanceTaskTypeDto,
    ResourceTypeComponentsService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const maintenanceScheduleTypes = {
    CRAFT: 'RPA',
    BATTERY: 'Battery',
    BATTERY_SET: 'Battery Set',
    EQUIPMENT: 'Equipment'
};
@Injectable()
export class MaintenanceScheduleDialogueService {
    private ngUnsubscribe$ = new Subject<void>();

    private componentTypesSubject = new ReplaySubject<ComponentTypeDto[]>();
    public componentTypes$ = this.componentTypesSubject.asObservable();

    private componentGroupSubject = new ReplaySubject<ComponentGroupDto[]>();
    public componentGroup$ = this.componentGroupSubject.asObservable();

    private taskTypesSubject = new ReplaySubject<MaintenanceTaskTypeDto[]>();
    public taskTypes$ = this.taskTypesSubject.asObservable();

    private formListSubject = new ReplaySubject<FormSummaryDto[]>();
    formList$ = this.formListSubject.asObservable();

    working: boolean = false;
    public workTracker = new WorkTracker();

    constructor(
        private resourceTypeComponentsService: ResourceTypeComponentsService,
        private formService: FormService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
            });
    }

    ngOnDestroy() {
        this.componentTypesSubject.complete();
        this.componentGroupSubject.complete();
        this.taskTypesSubject.complete();
        this.formListSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setup(
        organisationId: number,
        resourceCategory: CreateMaintenanceScheduleCommand.ResourceCategory
    ) {
        this.findTaskTypes(resourceCategory);
        this.findComponentGroups(resourceCategory);
        this.findComponentTypes(resourceCategory);
        this.findForms(organisationId);
    }

    private findTaskTypes(resourceCategory: string) {
        this.resourceTypeComponentsService
            .findMaintenanceTaskTypes(resourceCategory)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.taskTypesSubject.next(result);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching maintenance task types: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    private findComponentTypes(resourceCategory: string) {
        this.resourceTypeComponentsService
            .findComponentTypes(resourceCategory)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.componentTypesSubject.next(result);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching maintenance task types: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    private findComponentGroups(resourceCategory: string) {
        this.resourceTypeComponentsService
            .findComponentGroups(resourceCategory)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.componentGroupSubject.next(result);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching maintenance task types: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    private findForms(organisationId: number) {
        this.formService
            .findForms(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(forms => this.formListSubject.next(forms))
            .add(this.workTracker.createTracker());
    }
}
