import { Component, OnInit } from '@angular/core';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportingService } from '../reporting.service';

@Component({
    selector: 'flyfreely-portal-ui-report-side-bar',
    templateUrl: './report-side-bar.component.html',
    styleUrls: ['./report-side-bar.component.scss']
})
export class ReportSideBarComponent implements OnInit {
    reports$: Observable<any[]>;

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    working: boolean;

    constructor(private reportingService: ReportingService) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit(): void {
        this.reports$ = this.reportingService.reportList$;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    create() {
        this.reportingService
            .createDashboard('New Blank Dashboard')
            .subscribe(dashboard => {
                this.switchReport(dashboard.id);
            });
    }

    switchReport(dashboardId: string) {
        this.reportingService.switchReport(dashboardId);
    }
}
