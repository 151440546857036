<mgl-map
    *ngIf="isConfigLoaded"
    #map
    class="fill"
    [style]="baseStyle.url || baseStyle.style"
    [pitchWithRotate]="false"
    [dragRotate]="false"
    [bounds]="initialView"
    [center]="initialCentre"
    [zoom]="initialZoom"
    (mapLoad)="onMapLoad($event)"
    (resized)="onResize()"
    logoPosition="bottom-left"
>
    <mgl-marker
        *ngIf="marker != null"
        [lngLat]="marker"
        anchor="bottom"
        [draggable]="markerDraggable || false"
        (markerDragEnd)="markerDragEnd.emit($event)"
        ><ng-container *ngTemplateOutlet="markerTemplate"></ng-container
    ></mgl-marker>

    <mgl-control
        *ngIf="
            !simplifiedMap &&
            (hasMeasurementTools ||
                (mapSidebarDirective != null && isDashboardMap))
        "
        position="top-left"
    >
        <div class="horizontal-container">
            <div
                class="mapbox-dashboard-airspace-container right-buffer"
                [ngClass]="{
                    'map-only': dashboardMode === 'MAP',
                    both: dashboardMode === 'BOTH'
                }"
                *ngIf="mapSidebarDirective != null && isDashboardMap"
            >
                <div
                    class="map-control-container modal-scrollbar"
                    style="width: 250px"
                >
                    <div
                        class="map-control-container"
                        [ngClass]="{ mobile: useMobileLayout }"
                    >
                        <ng-container
                            [ngTemplateOutlet]="mapSidebarDirective.tpl"
                            [ngTemplateOutletContext]="{ map: this }"
                        ></ng-container>
                    </div>
                </div>
            </div>
            <map-toolbar *ngIf="hasMeasurementTools"></map-toolbar>
        </div>
    </mgl-control>

    <ng-container
        *ngFor="let source of airSpaceSources$ | async; trackBy: trackById"
        [ngSwitch]="source.type"
    >
        <ng-container *ngSwitchCase="'vector'">
            <mgl-vector-source
                *ngIf="source.tiles != null"
                [id]="source.id"
                [tiles]="source.tiles"
                [minzoom]="source.minzoom || undefined"
                [maxzoom]="source.maxzoom || undefined"
            >
            </mgl-vector-source>
            <mgl-vector-source
                *ngIf="source.url != null"
                [id]="source.id"
                [url]="source.url"
                [minzoom]="source.minzoom || undefined"
                [maxzoom]="source.maxzoom || undefined"
            >
            </mgl-vector-source>
        </ng-container>
        <ng-container *ngSwitchCase="'geojson'">
            <mgl-geojson-source
                *ngIf="source.data != null"
                [id]="source.id"
                [data]="source.data"
            >
            </mgl-geojson-source>
            {{ '' // This is done so the config semantically makes sense }}
            <mgl-geojson-source
                *ngIf="source.url != null"
                [id]="source.id"
                [data]="source.url"
            >
            </mgl-geojson-source>
        </ng-container>
    </ng-container>

    <mgl-layer
        *ngFor="let layer of airSpaceLayers; trackBy: trackById"
        [id]="layer.id"
        [type]="layer.type"
        [source]="layer.source"
        [sourceLayer]="layer['source-layer']"
        [paint]="layer.paint"
        [minzoom]="layer.minzoom"
        [maxzoom]="layer.maxzoom"
        [layout]="layouts[layer.id]"
        [filter]="layer.filter"
    >
    </mgl-layer>
    <mgl-geojson-source
        *ngFor="let source of featureSources; trackBy: trackById"
        [id]="source.id"
        [data]="source.data"
        [cluster]="source.cluster"
        [clusterMaxZoom]="source.clusterMaxZoom"
        [clusterRadius]="source.clusterRadius"
    >
    </mgl-geojson-source>
    <mgl-layer
        *ngFor="let layer of featureLayers; trackBy: trackById"
        [id]="layer.id"
        [type]="layer.type"
        [source]="layer.source"
        [sourceLayer]="layer['source-layer']"
        [paint]="layer.paint"
        [minzoom]="layer.minzoom"
        [maxzoom]="layer.maxzoom"
        [layout]="layer.layout"
        [filter]="layer.filter"
    >
    </mgl-layer>

    <mgl-image
        *ngFor="let image of images"
        [url]="image.url"
        [id]="image.id"
    ></mgl-image>

    <mgl-popup
        *ngIf="selectedPoint && !isEditing"
        [lngLat]="selectedPoint"
        maxWidth="none"
        className="location-popup"
    >
        <ng-container *ngTemplateOutlet="popupTemplate"></ng-container>
    </mgl-popup>

    <mgl-control
        *ngIf="!simplifiedMap"
        position="top-right"
        ffGeocoder
        [localGeocoder]="localGeocoder"
        (geocoderResult)="onGeocodeResultSelected($event)"
    >
    </mgl-control>
    <mgl-control *ngIf="!simplifiedMap" [position]="sidebarPosition">
        <div class="mapbox-sidepanel-container">
            <div
                class="map-control-container map-jurisdiction-layers-container modal-scrollbar"
            >
                <map-jurisdiction-selector
                    *ngIf="map != null"
                ></map-jurisdiction-selector>
                <map-feature-selector
                    #featuresComponent
                    [featuresTitle]="featuresTitle"
                    [features]="showFeatureSelection ? features : null"
                    [selectedFeatures]="visibleFeatures"
                    [layers]="layerSections"
                    [selectedLayerIds]="selectedAirSpaceLayerIds"
                    (onHideLayer)="onHideLayer($event)"
                    (onShowLayer)="onShowLayer($event)"
                    (onShowFeature)="showFeature($event)"
                    (onHideFeature)="hideFeature($event)"
                    (onActivateFeature)="onActivateFeature($event)"
                >
                </map-feature-selector>
            </div>
            <div
                class="map-control-container"
                [ngClass]="{ mobile: useMobileLayout }"
                *ngIf="mapSidebarDirective != null && !isDashboardMap"
            >
                <ng-container
                    [ngTemplateOutlet]="mapSidebarDirective.tpl"
                    [ngTemplateOutletContext]="{ map: this }"
                ></ng-container>
            </div>
        </div>
    </mgl-control>

    <mgl-control position="bottom-right">
        <div
            class="button-groups button-group-bottom-right"
            [ngClass]="{ 'mobile-view': useMobileLayout }"
        >
            <div *ngIf="!useMobileLayout" class="btn-group" role="group">
                <button
                    type="button"
                    class="btn btn-light"
                    (click)="mapZoomIn()"
                >
                    <i class="fas fa-plus"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-light"
                    (click)="mapZoomOut()"
                >
                    <i class="fas fa-minus"></i>
                </button>
            </div>
            <div *ngIf="!useMobileLayout" class="btn-group" role="group">
                <button
                    *ngFor="let option of baseStyles"
                    type="button"
                    class="btn btn-light"
                    [ngModel]="baseStyle"
                    [btnRadio]="option"
                    (click)="setBaseStyle(option)"
                >
                    {{ option.name }}
                </button>
            </div>
            <div *ngIf="useMobileLayout" class="vertical-container">
                <div
                    *ngIf="showMapRenderOptions"
                    @expandOnEnter
                    @collapseOnLeave
                    class="btn-group-vertical"
                    role="group"
                >
                    <button
                        *ngFor="let option of baseStyles"
                        type="button"
                        class="btn btn-light btn-lg"
                        [ngModel]="baseStyle"
                        [btnRadio]="option"
                        (click)="setBaseStyle(option)"
                    >
                        {{ option.name }}
                    </button>
                </div>
                <button
                    type="button"
                    class="btn btn-light btn-lg"
                    (click)="showMapRenderOptions = !showMapRenderOptions"
                >
                    <span
                        *ngIf="!showMapRenderOptions"
                        class="fa fa-layer-group"
                    ></span>
                    <span *ngIf="showMapRenderOptions">&times;</span>
                </button>
            </div>
            <div *ngIf="isDashboardMap" class="btn-group" role="group">
                <button
                    *ngIf="showSavePreferences"
                    type="button"
                    class="btn btn-light"
                    (click)="saveClick()"
                >
                    <span
                        class="glyphicon glyphicon-cog"
                        aria-hidden="true"
                    ></span>
                    Save
                </button>
                <button
                    *ngIf="isFullScreenSupported && showFullScreen"
                    type="button"
                    class="btn btn-light"
                    (click)="toggleFullScreen()"
                >
                    <i class="fas fa-expand-arrows-alt"></i>
                </button>
            </div>
        </div>
    </mgl-control>

    <mgl-control position="top-left" *ngIf="showEditCompletion && isEditing">
        <div class="button-groups">
            <div>
                <button
                    type="button"
                    class="btn btn-light no-right-border-radius border-right"
                    (click)="doneEditing()"
                >
                    Done
                </button>
                <button
                    type="button"
                    class="btn btn-light no-left-border-radius"
                    (click)="stopEditing()"
                >
                    Cancel
                </button>
            </div>
        </div>
    </mgl-control>

    <mgl-control
        *ngIf="
            (showFullScreen || showSavePreferences) &&
            !isDashboardMap &&
            !simplifiedMap
        "
        position="bottom-left"
    >
        <div class="button-groups">
            <div>
                <button
                    *ngIf="showSavePreferences"
                    type="button"
                    class="btn btn-light"
                    (click)="saveClick()"
                >
                    <span
                        class="glyphicon glyphicon-cog"
                        aria-hidden="true"
                    ></span>
                    Save
                </button>
                <button
                    *ngIf="isFullScreenSupported && showFullScreen"
                    type="button"
                    class="btn btn-light"
                    (click)="toggleFullScreen()"
                >
                    <i class="fas fa-expand-arrows-alt"></i>
                </button>
            </div>
        </div>
    </mgl-control>
</mgl-map>

<ng-template #airspaceLayerListTemplate>
    <div *ngFor="let feature of selectedAirspaceFeatures">
        <div
            *ngIf="feature.properties.renderedTemplate; else hardcodedTemplate"
            [innerHTML]="feature.properties.renderedTemplate"
        ></div>
        <ng-template #hardcodedTemplate>
            <ng-container [ngSwitch]="feature.layer.source">
                <ng-container *ngSwitchCase="'airspace'">
                    <h4>
                        {{
                            feature.properties.icao != null
                                ? feature.properties.icao + ':'
                                : feature.properties.name + ':'
                        }}
                        {{ airSpaceLayerNames[feature.properties.type] }}
                    </h4>
                    <h5 *ngIf="feature.properties.icao != null">
                        {{ feature.properties.name }}
                    </h5>
                    <p>
                        <em>{{ feature.properties.phone }}</em>
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'flyfreely'">
                    <ng-container
                        *ngIf="
                            feature.layer.id === '3nm-movement-controlled' ||
                            feature.layer.id === '3nm-movement-uncontrolled'
                        "
                    >
                        <h4>
                            {{ feature.properties.identifier }}: 3NM from
                            Movement Area
                        </h4>
                        <p>
                            <span class="display-label">Control Type</span>
                            {{
                                feature.layer.id === '3nm-movement-controlled'
                                    ? 'Controlled'
                                    : 'Non-Controlled'
                            }}
                        </p>
                    </ng-container>

                    <ng-container
                        *ngIf="feature.layer.id === '3nm-other-movement-ld'"
                    >
                        <h4>
                            {{ feature.properties.identifier }}: 3NM from ARP
                        </h4>
                        <p>
                            <span class="display-label">Control Type</span>
                            Non-Controlled
                        </p>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            feature.layer.id === 'approach-controlled-ld' ||
                            feature.layer.id === 'approach-uncontrolled-ld'
                        "
                    >
                        <h4>
                            {{ feature.properties.identifier }}: Approach and
                            Departure Path
                        </h4>
                        <p>
                            <span class="display-label">Control Type</span>
                            {{
                                feature.layer.id === 'approach-controlled-ld'
                                    ? 'Controlled'
                                    : 'Non-Controlled'
                            }}
                        </p>
                    </ng-container>

                    <ng-container
                        *ngIf="feature.layer.id === 'movement-area-ld'"
                    >
                        <h4>
                            {{ feature.properties.identifier }}: Movement Area
                        </h4>
                        <p>
                            <span class="display-label">Control Type</span>
                            Controlled
                        </p>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            feature.layer.id === 'danger-areas' ||
                            feature.layer.id === 'danger_areas'
                        "
                    >
                        <h4>Danger Area</h4>
                        <!-- <p><span class="display-label">Lower Limit</span> SFC</p>
                                        <p><span class="display-label">Upper Limit</span> SFC</p> -->
                        <h5>{{ feature.properties.name }}</h5>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            feature.layer.id === 'restricted-areas' ||
                            feature.layer.id === 'restricted_areas'
                        "
                    >
                        <h4>Restricted Area</h4>
                        <!-- <p><span class="display-label">Lower Limit</span> SFC</p>
                                        <p><span class="display-label">Upper Limit</span> SFC</p> -->
                        <h5>{{ feature.properties.name }}</h5>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>
    </div>
</ng-template>

<div
    *ngIf="showScrollOverlay"
    @fadeInOnEnter
    @fadeOutOnLeave
    class="map-scroll-message"
>
    <div></div>
    <p>Use ctrl + scroll to zoom the map</p>
</div>
<map-measurement-interaction></map-measurement-interaction>
