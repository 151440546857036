<div *ngIf="!newTemplate"
    ngfDrop
    multiple="1"
    [fileDropDisabled]="
        !(allowUploads || allowReuploads) || working || disabled
    "
    [(validDrag)]="baseDropValid"
    [(files)]="files"
    [class.invalid-drag]="baseDropValid === false"
    [class.valid-drag]="baseDropValid"
    (filesChange)="filesSelected($event)"
>
    <table
        *ngIf="attachments != null"
        class="table"
        [ngClass]="{ readonly: disabled }"
    >
        <thead *ngIf="!hideHeadings">
            <tr>
                <th>Name</th>
                <th
                    *ngIf="
                        canRequireAcknowledgement && acknowledgements != null
                    "
                >
                    Requires Acknowledgement
                </th>
                <th>Description</th>
                <th class="actions">
                    <a
                        *ngIf="
                            allowDownload &&
                            attachments &&
                            attachments.length > 0 &&
                            !hideHeadings
                        "
                        [downloadUrl]="downloadAllUrl"
                        class="btn-tertiary btn-link"
                        angulartics2On="click"
                        angularticsCategory="attachments"
                        angularticsAction="download-all"
                    >
                        <span class="fal fa-download"></span>
                        Download All
                    </a>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container
                *ngFor="let attachment of attachments; let index = index"
            >
                <tr>
                    <td class="wordwrap">
                        <span *ngIf="attachment.queued">{{
                            attachment.name
                        }}</span>
                        <span
                            *ngIf="
                                attachment.purpose == null && !attachment.queued
                            "
                        >
                            <ng-container *ngIf="attachment.editMode">
                                <input
                                    class="form-control input-sm col-xs-12 e-input-name"
                                    [(ngModel)]="attachment.name"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                            </ng-container>
                            <ng-container *ngIf="!attachment.editMode">
                                <a
                                    *ngIf="allowDownload"
                                    (click)="showContent(attachment)"
                                >
                                    {{ attachment.name }}
                                </a>
                                <p *ngIf="!allowDownload">
                                    {{ attachment.name }}
                                </p>
                                <p class="help-block" *ngIf="attachment.id">
                                    (Updated
                                    {{
                                        attachment.uploadTime | formatDateTime
                                    }})
                                </p>
                            </ng-container>
                        </span>
                        <span
                            *ngIf="
                                attachment.purpose != null &&
                                attachment.id != null &&
                                !attachment.queued
                            "
                        >
                            <a
                                *ngIf="allowDownload"
                                [downloadUrl]="attachment.downloadUrl"
                            >
                                {{ attachment.name || '(Unnamed)' }}
                                <i>{{ purposeLookup[attachment.purpose] }}</i>
                            </a>
                            <p *ngIf="!allowDownload">
                                {{ attachment.name || '(Unnamed)' }}
                                <i>{{ purposeLookup[attachment.purpose] }}</i>
                            </p>
                            <p class="help-block">
                                (Updated
                                {{ attachment.uploadTime | formatDateTime }})
                            </p>
                        </span>
                        <span
                            *ngIf="
                                attachment.purpose != null &&
                                attachment.id == null
                            "
                        >
                            {{ purposeLookup[attachment.purpose] }}
                        </span>
                    </td>
                    <td
                        *ngIf="
                            canRequireAcknowledgement &&
                            acknowledgements != null
                        "
                        class="wordwrap"
                    >
                        <span
                            *ngIf="!allowAcknowledgements && !attachment.queued"
                        >
                            <ng-container *ngIf="attachment.editMode">
                                <input
                                    type="checkbox"
                                    [(ngModel)]="
                                        attachment.requiresAcknowledgement
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                />
                            </ng-container>
                            <ng-container *ngIf="!attachment.editMode">
                                {{
                                    attachment.requiresAcknowledgement
                                        | formatBoolean
                                        | defaultTo
                                }}
                            </ng-container>
                        </span>
                        <!-- Acknowledge the attachment -->
                        <button
                            *ngIf="
                                !attachment.editMode &&
                                allowAcknowledgements &&
                                attachment.requiresAcknowledgement &&
                                !acknowledgements[
                                    attachment.attachmentVersionId
                                ] &&
                                !attachment.queued
                            "
                            type="button"
                            class="btn btn-xs btn-pill btn-primary"
                            (click)="acknowledgeAttachment(attachment)"
                            angulartics2On="click"
                            angularticsCategory="attachments"
                            angularticsAction="acknowledge"
                        >
                            Acknowledge
                        </button>
                        <!-- Attachment already acknowledged -->
                        <a
                            *ngIf="
                                (!attachment.editMode ||
                                    !attachment.reuploadMode) &&
                                allowAcknowledgements &&
                                attachment.requiresAcknowledgement &&
                                acknowledgements[attachment.attachmentVersionId]
                            "
                            class="fas fa-check"
                            #acknowledgement="bs-popover"
                            [popover]="popTemplate"
                            [outsideClick]="true"
                            placement="bottom"
                            container="body"
                        >
                        </a>
                        <ng-template #popTemplate>
                            <p>
                                I hereby acknowledge that I have received the
                                instructions, procedures and data contained in
                                the {{ attachment.name }}.
                            </p>

                            <p>
                                I also understand that the contents of this
                                {{ attachment.name }} have been devised to
                                ensure the safety and standardisation of
                                operations conducted by FlyFreely.
                            </p>

                            <p>
                                I agree to abide by the instructions contained
                                in the {{ attachment.name }} at all times.
                            </p>
                            <p class="help-block">
                                Acknowledged
                                {{
                                    acknowledgements[
                                        attachment.attachmentVersionId
                                    ] | formatDateTime
                                }}
                            </p>
                        </ng-template>
                        <span
                            *ngIf="
                                !attachment.editMode &&
                                allowAcknowledgements &&
                                !attachment.requiresAcknowledgement
                            "
                            >Acknowledgement not required</span
                        >
                    </td>
                    <td class="wordwrap">
                        <span *ngIf="!attachment.queued">
                            <ng-container *ngIf="attachment.editMode">
                                <input
                                    class="form-control input-sm col-xs-12 e-input-description"
                                    [(ngModel)]="attachment.description"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                            </ng-container>
                            <ng-container *ngIf="!attachment.editMode">
                                {{ attachment.description }}
                            </ng-container>
                        </span>
                    </td>
                    <td class="no-wrap actions">
                        <ng-container *ngIf="!disabled">
                            <button
                                *ngIf="
                                    attachment.id &&
                                    allowEdit &&
                                    !attachment.reuploadMode &&
                                    !attachment.queued &&
                                    !attachment.editMode
                                "
                                type="button"
                                class="btn btn-sm btn-tertiary"
                                (click)="attachment.editMode = true"
                                tooltip="Edit Attachment"
                                placement="top"
                                angulartics2On="click"
                                angularticsCategory="attachments"
                                angularticsAction="edit-attachment"
                            >
                                <span class="fal fa-pencil-alt"></span>
                            </button>

                            <button
                                *ngIf="
                                    (allowUploads || allowReuploads) &&
                                    !attachment.reuploadMode &&
                                    !attachment.queued &&
                                    !attachment.editMode
                                "
                                type="button"
                                (click)="attachment.reuploadMode = true"
                                class="btn btn-sm btn-tertiary"
                                tooltip="Upload New Version of the Attachment"
                                placement="top"
                                angulartics2On="click"
                                angularticsCategory="attachments"
                                angularticsAction="upload-new-version"
                            >
                                <span class="fal fa-upload"></span>
                            </button>

                            <button
                                *ngIf="
                                    (allowUploads || allowReuploads) &&
                                    !attachment.reuploadMode &&
                                    !attachment.queued &&
                                    !attachment.editMode
                                "
                                type="button"
                                (click)="deleteAttachment(attachment)"
                                class="btn btn-sm btn-tertiary btn-delete"
                                tooltip="Delete Attachment"
                                placement="top"
                                angulartics2On="click"
                                angularticsCategory="attachments"
                                angularticsAction="delete-attachment"
                            >
                                <span class="fal fa-trash-alt"></span>
                            </button>

                            <button
                                *ngIf="
                                    hasVersionHistory &&
                                    attachment.id &&
                                    !attachment.reuploadMode &&
                                    !attachment.queued &&
                                    !attachment.editMode
                                "
                                type="button"
                                class="btn btn-sm btn-tertiary"
                                (click)="showAttachmentHistory(attachment)"
                                tooltip="View History"
                                placement="top"
                                angulartics2On="click"
                                angularticsCategory="attachments"
                                angularticsAction="attachment-history"
                            >
                                <span class="fal fa-history"></span>
                            </button>

                            <span
                                *ngIf="
                                    (attachment.reuploadMode ||
                                        attachment.id == null) &&
                                    !attachment.queued
                                "
                            >
                                <div class="well" style="text-align: left">
                                    <div class="form-group">
                                        <label>Changes</label
                                        ><textarea
                                            class="form-control"
                                            [(ngModel)]="
                                                attachment.reuploadChanges
                                            "
                                            [disabled]="
                                                attachment.uploadProgress !=
                                                null
                                            "
                                        ></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Updated File</label>
                                        <div>
                                            <button
                                                type="button"
                                                class="btn btn-default btn-sm"
                                                [disabled]="
                                                    attachment.uploadProgress !=
                                                    null
                                                "
                                                ngfSelect
                                                multiple="1"
                                                (fileChange)="
                                                    storeAttachment(
                                                        attachment,
                                                        $event
                                                    )
                                                "
                                            >
                                                Choose File
                                            </button>
                                            {{
                                                uploadedFile
                                                    ? uploadedFile.name
                                                    : ''
                                            }}
                                        </div>
                                    </div>
                                    <button
                                        [disabled]="
                                            attachment.uploadProgress != null ||
                                            !uploadedFile
                                        "
                                        class="btn btn-default btn-sm"
                                        multiple="1"
                                        type="button"
                                        (click)="reuploadAttachment(attachment)"
                                    >
                                        Upload</button
                                    ><button
                                        [disabled]="
                                            attachment.uploadProgress != null
                                        "
                                        class="btn btn-danger btn-sm btn-link"
                                        type="button"
                                        (click)="
                                            hideReuploadAttachment(attachment)
                                        "
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </span>
                            <button
                                class="btn btn-sm btn-primary"
                                type="button"
                                (click)="updateAttachment(attachment, index)"
                                *ngIf="
                                    attachment.id != null &&
                                    !attachment.queued &&
                                    attachment.editMode
                                "
                            >
                                Save
                            </button>
                            <button
                                class="btn btn-sm btn-danger btn-link"
                                type="button"
                                (click)="
                                    attachment.editMode = false;
                                    attachment.reuploadMode = false
                                "
                                *ngIf="
                                    attachment.editMode &&
                                    attachment.id != null &&
                                    !attachment.queued
                                "
                            >
                                Cancel
                            </button>

                            <span *ngIf="attachment.uploadProgress != null"
                                >Uploading
                                {{ attachment.uploadProgress }}%</span
                            >
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="attachment.file != null">
                    <td colspan="4">
                        <p *ngIf="attachment.file">
                            File selected to update:<strong>{{
                                attachment.file.name
                            }}</strong>
                        </p>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot *ngIf="!disabled">
            <tr>
                <td colspan="4">
                    <p class="text-danger" *ngIf="error && error !== ''">
                        {{ error }}
                    </p>
                    <button
                        type="button"
                        class="btn btn-default btn-block"
                        [disabled]="working"
                        *ngIf="allowUploads"
                        ngfSelect
                        multiple="1"
                        [(files)]="files"
                        (filesChange)="filesSelected($event)"
                    >
                        Select or Drop Files Here
                    </button>
                </td>
            </tr>
        </tfoot>
    </table>
    <ngx-loading [show]="working || attachments == null"></ngx-loading>
</div>


<div *ngIf="newTemplate">
    <!-- New Template Code -->
    <div
        ngfDrop
        multiple="1"
        [fileDropDisabled]="!(allowUploads || allowReuploads) || working || disabled"
        [(validDrag)]="baseDropValid"
        [(files)]="files"
        [class.invalid-drag]="baseDropValid === false"
        [class.valid-drag]="baseDropValid"
        (filesChange)="filesSelected($event)"
        class="attachment-container"
    >
        <div class="drag-area mb-16" *ngIf="allowUploads">
            <p>Drag file or</p>
            <button 
                type="button"
                class="browse-button" 
                ngfSelect multiple="1"
                [(files)]="files"
                (filesChange)="filesSelected($event)"
                >
                <i *ngIf="working" class="fa-solid fa-spinner fa-spin-pulse"></i>
                Browse</button>
        </div>


       
    </div>
    <div class="attachment-list" [ngClass]="{'pr-0': allowDelete}">
        <div *ngFor="let attachment of attachments" class="attachment-item">
            <span [ngClass]="getFileIcon(attachment.contentType)"></span>
        
            <a class="text-dark-gray" (click)="showContent(attachment)">{{ attachment.name }}</a>
            <button 
            type="button"
            *ngIf="allowDelete"
             (click)="deleteAttachment(attachment)"
              class="remove-button bg-transparent  border-none"
              >
                <i class="fa-solid fa-xmark "></i>
            </button>
        </div>
    </div>
    <ngx-loading [show]="working || attachments == null"></ngx-loading>
</div>
