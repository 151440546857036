import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ConditionsLibraryService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    CdkDragDrop, // Import CdkDragDrop
    moveItemInArray // Import moveItemInArray
  } from '@angular/cdk/drag-drop';

interface ConditionDto {
    id: number;
    condition: string;
}

interface ConditionLibraryDto {
    id: number;
    name: string;
    organisationId: number;
    conditions: ConditionDto[];
}

@Component({
    selector: 'condition-library-edit',
    templateUrl: './condition-library-edit.component.html'
})
export class ConditionLibraryEditDialogue implements OnInit {
    @Input() conditionsLibraryId: number;
    @Input() organisationId: number;

    conditionLibrary: ConditionLibraryDto;

    form: FormGroup;
    conditions: FormArray;

    isEditingName = false;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private logging: FlyFreelyLoggingService,
        private modal: BsModalRef<ConditionLibraryEditDialogue>,
        modalOptions: ModalOptions,
        private conditionsLibraryService: ConditionsLibraryService,
        commonDialoguesService: CommonDialoguesService
    ) {
        this.conditions = new FormArray([]);
        this.form = new FormGroup({
            name: new FormControl(undefined, [Validators.required]),
            conditions: this.conditions
        });
        modalOptions.closeInterceptor = () =>
            preHide(this.form, commonDialoguesService);
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        if (this.conditionsLibraryId) {
            this.findConditionLibrary();
        }
    }

    private createConditionGroup(c: ConditionDto) {
        return new FormGroup({
            id: new FormControl(c.id),
            condition: new FormControl(c.condition, [Validators.required])
        });
    }

    newCondition() {
        const c: ConditionDto = {
            id: null,
            condition: ''
        };
        this.conditions.push(this.createConditionGroup(c));
    }

    findConditionLibrary() {
        this.conditionsLibraryService
            .findById(this.conditionsLibraryId)
            .subscribe((lib: ConditionLibraryDto) => {
                this.form.patchValue({ name: lib.name });
                this.conditions.clear();
                lib.conditions.forEach(c => {
                    this.conditions.push(this.createConditionGroup(c));
                });
            })
            .add(this.workTracker.createTracker());
    }

    doSave() {
        const values = this.form.value;

        if (this.conditionsLibraryId == null) {
            return this.conditionsLibraryService
                .create({ ...values, organisationId: this.organisationId })
                .pipe(takeUntil(this.ngUnsubscribe$));
        } else {
            return this.conditionsLibraryService
                .update(this.conditionsLibraryId, {
                    ...values
                })
                .pipe(takeUntil(this.ngUnsubscribe$));
        }
    }

    removeCondition(index: number) {
        this.conditions.removeAt(index);
    }

     // Implement the drop method for drag-and-drop functionality
     drop(event: CdkDragDrop<any[]>) {
        // Move the items in the FormArray controls based on drag-drop positions
        moveItemInArray(this.conditions.controls, event.previousIndex, event.currentIndex);
        
        // After reordering, update the FormArray value and mark it as dirty and touched
        this.conditions.updateValueAndValidity();  // Ensure Angular knows about changes
        this.form.markAsDirty();  // Mark form as dirty to allow save
        this.form.markAsTouched();  // Mark form as touched so validation applies
    }

    onEditableModeChange(mode: boolean) {
        this.isEditingName = !mode;
    }

    // Callback for name editable
    updateName = ({ name }: { name: string }) => {
        this.form.patchValue({ name: name });
        this.form.markAsDirty();
        this.form.updateValueAndValidity();
        return Promise.resolve();
    };

    save() {
        const doneWorking = this.workTracker.createTracker();

        this.doSave().subscribe(
            lib => {
                this.logging.success('Conditions library saved');
                this.conditionsLibraryId = lib.id;
                this.form.markAsPristine();
                doneWorking();
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while saving: ${error.message}`
                );
                doneWorking();
            }
        );
    }

    saveAndClose() {
        const doneWorking = this.workTracker.createTracker();

        this.doSave().subscribe(
            lib => {
                this.logging.success('Conditions library saved');
                this.form.markAsPristine();
                this.modal.hide();
                doneWorking();
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while saving: ${error.message}`
                );
                doneWorking();
            }
        );
    }

    cancel() {
        this.modal.hide();
    }

    get conditionsGroup() {
        return this.form.get('jurisdictionSetup') as FormGroup;
    }
}
