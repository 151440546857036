<div class="panel widget equipment">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img [src]="IMG_URL + '/icons/icon-equipment.png'" />
        </div>
        <div class="panel-title">Equipment</div>
        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                *ngIf="canAdd"
                class="btn btn-inline btn-title-action"
                (click)="addEquipment()"
                tooltip="Add equipment"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-equipment"
                data-cy="equipmentListAddButton"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>
    <div class="panel-body">
        <plan-highlight [featureFlags]="equipmentFeature"></plan-highlight>
        <static-table
            *ngIf="equipments?.length > 0"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="equipments"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
            data-cy="equipmentTable"
        >
            <ng-template
                columnTemplate="name"
                let-row="row"
                let-column="column"
            >
                <status-icon
                    [equipment]="row"
                    (nameClicked)="editEquipment(row)"
                ></status-icon>
            </ng-template>
        </static-table>
        <empty
            *ngIf="canAdd && equipments && equipments.length == 0"
            componentName="equipment-empty-editable"
        ></empty>
        <empty
            *ngIf="!canAdd && equipments && equipments.length == 0"
            componentName="equipment-empty"
        ></empty>
        <loading-indicator is-loading="isLoading"></loading-indicator>
    </div>
</div>
