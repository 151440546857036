import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    ConditionsLibraryDto,
    CreateConditionsLibraryCommand,
    UpdateConditionsLibraryCommand
} from '../model/api';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConditionsLibraryService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<ConditionsLibraryDto[]>(
            `${this.baseUrl}/webapi/conditionsLibrary`,
            {
                params: new HttpParams().set(
                    'organisationId',
                    organisationId != null ? organisationId.toString() : ''
                )
            }
        );
    }

    findById(libraryId: number) {
        return this.http.get<ConditionsLibraryDto>(
            `${this.baseUrl}/webapi/conditionsLibrary/${libraryId}`
        );
    }

    create(command: CreateConditionsLibraryCommand) {
        return this.http
            .post<ConditionsLibraryDto>(
                `${this.baseUrl}/webapi/conditionsLibrary`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    update(libraryId: number, command: UpdateConditionsLibraryCommand) {
        return this.http
            .put<ConditionsLibraryDto>(
                `${this.baseUrl}/webapi/conditionsLibrary/${libraryId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    delete(libraryId: number) {
        return this.http
            .delete<void>(
                `${this.baseUrl}/webapi/conditionsLibrary/${libraryId}`
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
