import { Component } from '@angular/core';
import { DashboardService } from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    WidgetStatus,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OutstandingTasksViewService } from '../../outstanding-tasks-view.service';

@Component({
    selector: 'flyfreely-portal-ui-outstanding-tasks-sidebar',
    templateUrl: './outstanding-tasks-sidebar.component.html',
    styleUrls: ['./outstanding-tasks-sidebar.component.scss']
})
export class OutstandingTasksSidebarComponent {
    currentOrganisation: CurrentOrganisation = {
        organisation: null,
        type: null
    };

    selectedWidgets = {
        generalTasks: true,
        operations: true,
        resources: true,
        organisation: true
    };

    visibleSections = {
        generalTasks: false,
        operations: false,
        resources: false,
        organisation: false
    };

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private sharedService: WorkspaceStateService,
        private outstandingViewService: OutstandingTasksViewService,
        public dashboardService: DashboardService
    ) {}

    ngOnInit() {
        this.sharedService.currentOrganisation$
            .pipe(
                filter<CurrentOrganisation>(
                    o => o.type === 'organisation_loaded'
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(organisation => {
                this.currentOrganisation = organisation;
            });

        this.outstandingViewService.availableTestWidgets$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(widgets => {
                if (widgets != null) {
                    this.findVisibleSections(widgets);
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private findVisibleSections(availableWidgets: WidgetStatus) {
        const [
            generalTasksWidgets,
            operationsWidgets,
            resourcesWidgets,
            organisationWidgets
        ] = this.outstandingViewService.splitWidgetsBySection(availableWidgets);
        this.visibleSections = {
            generalTasks: generalTasksWidgets.length > 0,
            operations: operationsWidgets.length > 0,
            resources: resourcesWidgets.length > 0,
            organisation: organisationWidgets.length > 0
        };
    }

    public scrollTo(sectionName: string) {
        this.outstandingViewService.scrollTo(sectionName);
    }

    onMenuChanged() {
        this.outstandingViewService.updateSelectedSections(
            this.selectedWidgets
        );
    }
}
