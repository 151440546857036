import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CraftDto,
    CraftService,
    CreateMaintenanceLogCommand,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceService,
    NameValue,
    PersonsOrganisationDto,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription, forkJoin, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'bulk-maintenance',
    templateUrl: './bulk-maintenance-dialogue.component.html'
})
export class BulkMaintenance implements OnInit, OnDestroy {
    @Input() organisation: PersonsOrganisationDto;

    rpaModels$: Observable<RpaTypeDto[]>;

    form: FormGroup;

    availableRpa: CraftDto[];

    private searchSubscription: Subscription;

    maintenanceLogTypes: NameValue[];

    private workTracker: WorkTracker;
    private workTrackerSubscription: Subscription;
    working: boolean;
    IMG_URL: string;

    constructor(
        private modal: BsModalRef,
        private logging: FlyFreelyLoggingService,
        private craftService: CraftService,
        constants: FlyFreelyConstants,
        private rpaTypesService: RpaTypesService,
        private maintenanceService: MaintenanceService
    ) {
        this.IMG_URL = constants.IMG_URL;
        this.workTracker = new WorkTracker();

        this.maintenanceLogTypes = maintenanceService.getMaintenanceLogTypes();

        this.form = new FormGroup({
            rpaModelId: new FormControl(null, [Validators.required]),
            rpaId: new FormArray([], [Validators.required]),
            maintenanceType: new FormControl(null, [Validators.required]),
            reasonForRequest: new FormControl(null, [Validators.required])
        });
    }

    ngOnInit() {
        this.workTrackerSubscription = this.workTracker
            .asObservable()
            .subscribe(working => (this.working = working));

        this.rpaModels$ = from(
            this.rpaTypesService.findAllRpaTypes().toPromise()
        );
        this.searchSubscription = this.form.controls.rpaModelId.valueChanges
            .pipe(
                mergeMap(rpaModelId =>
                    this.craftService.findCrafts(this.organisation.id, {
                        craftModelId: rpaModelId
                    })
                )
            )
            .subscribe(rpa => {
                this.availableRpa = rpa;
                const formArray = <FormArray>this.form.get('rpaId');
                while (formArray.controls.length > 0) {
                    formArray.removeAt(0);
                }
                rpa.forEach(r => formArray.insert(0, new FormControl(false)));
            });
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
        this.workTrackerSubscription.unsubscribe();
    }

    submit() {
        const values = this.form.value;

        const rpas = this.availableRpa
            .map((r, ix) => (values.rpaId[ix] ? r : null))
            .filter(rId => rId != null);

        const doneWorking = this.workTracker.createTracker();

        forkJoin(
            rpas.map(rpa =>
                this.maintenanceService
                    .createMaintenanceLog({
                        organisationId: this.organisation.id,
                        resourceCategory:
                            CreateMaintenanceLogCommand.ResourceCategory.CRAFT,
                        resourceId: rpa.id,
                        reasonForRequest: values.reasonForRequest,
                        safeToUse: false,
                        maintenanceLogType: values.maintenanceType
                    })
                    .pipe(
                        map(log =>
                            this.maintenanceService.submitMaintenanceLog(log.id)
                        )
                    )
                    .subscribe(
                        () => {
                            this.logging.info(
                                `Create ${rpas.length} maintenance requests`
                            );
                            this.modal.hide();
                            doneWorking();
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                'There was an issue while creating the requests'
                            );
                            doneWorking();
                        }
                    )
            )
        );
    }

    hide() {
        this.modal.hide();
    }
}
