import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FlyFreelyLoggingService,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker,
    rpaTypeFields
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'model-specification',
    templateUrl: './model-specification.component.html'
})
export class ModelSpecification implements OnInit, OnDestroy {
    @Input() rpaType: RpaTypeDto;

    working: boolean = false;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    fields: FormlyFieldConfig[];
    physicalSpecificationFields: FormlyFieldConfig[];
    performanceSpecificationFields: FormlyFieldConfig[];
    otherSpecificationFields: FormlyFieldConfig[];
    manualPurposes: any;
    manualMessage: any;
    craftModelId: number;
    basicSpecifications: { [key: string]: any };

    constructor(
        private rpaTypesService: RpaTypesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        const fields = rpaTypeFields();
        this.fields = fields.fields;
        this.physicalSpecificationFields = fields.physicalSpecificationFields;
        this.performanceSpecificationFields =
            fields.performanceSpecificationFields;
        this.otherSpecificationFields = fields.otherSpecificationFields;
    }

    ngOnInit() {
        this.manualPurposes = this.rpaTypesService.getManualPurposes();

        if (!this.rpaType) {
            this._setRpaType(null);
        } else {
            this._setRpaType(this.rpaType);
            this._loadRpaType(this.rpaType.id);
        }
        this.manualMessage = {};
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    _loadRpaType(rpaTypeId: number) {
        const doneWorking = this.workTracker.createTracker();
        this.craftModelId = rpaTypeId;

        this.rpaTypesService
            .findRpaType(rpaTypeId)
            .toPromise()
            .then(
                (rpaType: any) => {
                    this._setRpaType(rpaType);
                    doneWorking();
                },
                (error: any) => {
                    this.logging.error(error);
                    doneWorking();
                }
            );
    }

    _setRpaType(rpaType: any) {
        this.rpaType = rpaType || {};

        this.basicSpecifications = {
            make: this.rpaType.make,
            model: this.rpaType.model,
            craftType: this.rpaType.craftType,
            propulsionSystem: this.rpaType.propulsionSystem
        };
        this.rpaType.performanceSpecifications =
            this.rpaType.performanceSpecifications || {};
        this.rpaType.physicalSpecifications =
            this.rpaType.physicalSpecifications || {};
        this.rpaType.otherSpecifications =
            this.rpaType.otherSpecifications || {};
    }

    isCurrentType(craftType: any) {
        return craftType === this.rpaType.craftType ? 'selected' : '';
    }

    isNewRpaType() {
        return !this.rpaType || !this.rpaType.id;
    }

    submit() {
        const toSave = {
            make: this.rpaType.make,
            model: this.rpaType.model,
            craftType: this.rpaType.craftType,
            propulsionSystem: this.rpaType.propulsionSystem,
            performanceSpecifications: this.rpaType.performanceSpecifications,
            physicalSpecifications: this.rpaType.physicalSpecifications,
            otherSpecifications: this.rpaType.otherSpecifications
        };

        const doneWorking = this.workTracker.createTracker();
        if (this.isNewRpaType()) {
            this.rpaTypesService
                .createRpaType(toSave)
                .toPromise()
                .then(
                    (savedRpaType: any) => {
                        doneWorking();
                        // this.close({ $value: savedRpaType });
                    },
                    (error: any) => doneWorking()
                );
        } else {
            this.rpaTypesService
                .updateRpaType(this.rpaType.id, toSave)
                .toPromise()
                .then(
                    (savedRpaType: any) => {
                        doneWorking();
                        // this.close({ $value: savedRpaType });
                    },
                    (error: any) => doneWorking()
                );
        }
    }
}
