import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    NotFound,
    ReferralService,
    ReferralSourceSummary,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'referrals',
    templateUrl: './referrals.component.html'
})
export class Referrals implements OnInit, OnDestroy {
    @Input() organisationId: number;

    referralSummaries: ReferralSourceSummary[];

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private referralService: ReferralService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshReferralSource();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshReferralSource() {
        if (!this.organisationId) {
            return;
        }

        this.referralService
            .findPromoterSourcesByOrganisation(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.referralSummaries = result;
                },
                error: (error: FlyFreelyError) => {
                    if (error instanceof NotFound) {
                        this.referralSummaries = null;
                        return;
                    } else {
                        this.logging.error(
                            error,
                            `Error while loading referral: ${error.message}`
                        );
                    }
                }
            })
            .add(this.workTracker.createTracker());
    }
}
