import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Comment, CommentDelete, CommentUpdate } from '../interfaces';

@Component({
    selector: 'ui-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss']
})
export class CommentComponent {
    @Input() allowEdit = true;
    @Input() organisationId!: number;
    @Input() comment!: Comment;
    @Output() updateComment = new EventEmitter<CommentUpdate>();
    @Output() deleteComment = new EventEmitter<CommentDelete>();

    isEditing = false;

    onUpdateComment(content: Object) {
        if (this.comment) {
            this.updateComment.emit({
                commentId: this.comment.id,
                content
            });
        }
        this.isEditing = false;
    }

    onCancelEdit() {
        this.isEditing = false;
    }

    onEdit() {
        this.isEditing = true;
    }

    onDelete() {
        if (this.comment) {
            this.deleteComment.emit({
                commentId: this.comment.id
            });
        }
    }
}
