import { Directive, Input, Optional, SimpleChanges } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { htmlValidator } from 'libs/validation/src/lib/formValidators';

@Directive({
    selector: 'input,textarea'
})
export class FormHtmlValidationDirective {
    @Input() allowHtml?: boolean;

    hasSetValidation = false;
    constructor(
        @Optional()
        private ngControl: NgControl
    ) {}

    ngOnInit() {
        // Not running this on init means several controls are skipped, but allowance has to be made for embedded controls not yet available
        if (this.allowHtml || this.ngControl == null || this.hasSetValidation) {
            return;
        } else {
            this.ngControl?.control?.setValidators(
                Validators.compose([
                    this.ngControl?.control?.validator,
                    htmlValidator()
                ])
            );
            this.hasSetValidation = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // For controls that weren't ready on init of the directive, this will run on changes to hopefully add the validator to them too
        if (this.allowHtml || this.ngControl == null || this.hasSetValidation) {
            return;
        } else {
            this.ngControl?.control?.setValidators(
                Validators.compose([
                    this.ngControl?.control?.validator,
                    htmlValidator()
                ])
            );
            this.hasSetValidation = true;
        }
    }
}
