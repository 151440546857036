/**
 * This module contains a very simple state function for use with rxjs to allow a reactive local state to be used.
 *
 * ```typescript
 *     private lockedFieldsSource = new ReplaySubject<
 *      StateUpdateCommand<LockedMissionFields>
 *  >(1);
 *  lockedFields$ = this.lockedFieldsSource.pipe<LockedMissionFields>(
 *      scan((acc, command) => patchableState(acc, command), {
 *          aircraft: false,
 *          isDummy: false,
 *          missionCrew: false,
 *          missionType: false,
 *          missionWorkflowVersion: false,
 *          timeZone: false
 *      })
 *  );
 * ```
 * @module simple-state
 */

type SetState<T> = {
    type: 'SET';
    data: T;
};

interface PatchState<T> {
    type: 'PATCH';
    data: Partial<T>;
}

interface MutateState<T> {
    type: 'MUTATE';
    fn: (s: T) => T;
}

export function setState<T>(data: T): SetState<T> {
    return {
        type: 'SET',
        data
    };
}

export function patchState<T>(data: T): PatchState<T> {
    return {
        type: 'PATCH',
        data
    };
}

export function mutateState<T>(fn: (s: T) => T): MutateState<T> {
    return {
        type: 'MUTATE',
        fn
    };
}

export type StateUpdateCommand<T> =
    | SetState<T>
    | PatchState<T>
    | MutateState<T>;

/**
 * A utility function for use with rxjs `scan` for making a patchable state.
 * @param acc the accumulated value
 * @param value the patch value
 * @returns the updated state
 */

export function patchableState<T>(acc: T, command: StateUpdateCommand<T>): T {
    if (command.type === 'SET') {
        return command.data;
    }
    if (command.type === 'PATCH') {
        return {
            ...acc,
            ...command.data
        };
    }
    if (command.type === 'MUTATE') {
        return command.fn(acc);
    }
}
