import { Component, Input } from '@angular/core';
import {
    ConditionsLibraryDto,
    ConditionsLibraryService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConditionLibraryDialoguesService } from '../approval-conditions-dialogues.service';

@Component({
    selector: 'condition-library-list',
    templateUrl: './condition-library-list.component.html'
})
export class ConditionLibraryList {
    @Input() organisationId: number;

    conditions: ConditionsLibraryDto[];

    private workTracker: WorkTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private conditionsLibraryService: ConditionsLibraryService,
        private conditionLibraryDialoguesService: ConditionLibraryDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.conditionsLibraryService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.findConditions());

        this.findConditions();
    }

    findConditions() {
        this.conditionsLibraryService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: conditions => {
                    this.conditions = conditions;
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing condition libraries: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    addConditionLibrary() {
        this.conditionLibraryDialoguesService.newConditionLibrary(
            this.organisationId
        );
    }

    editConditionLibrary(condition: ConditionsLibraryDto) {
        this.conditionLibraryDialoguesService.editConditionLibrary(
            condition.id,
            this.organisationId
        );
    }

    deleteConditionLibrary(condition: ConditionsLibraryDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                `Delete ${condition.name}`,
                `Are you sure you wish to delete this condition library: ${condition.name}`,
                'Delete',
                () => Promise.resolve()
            )
            .then(() =>
                this.conditionsLibraryService
                    .delete(condition.id)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe({
                        next: () => {
                            this.logging.success('Condition library deleted');
                        },
                        error: (error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                `Error deleting condition library: ${error.message}`
                            )
                    })
                    .add(this.workTracker.createTracker())
            );
    }
}
