import { Directive, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[routerTab]'
})
export class RouterTabDirective {
    @Input()
    routerTab: string;

    tabRoutes: string[];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private tab: TabDirective,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activateIfRoutedTab();

        this.tab.selectTab
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(t => t === this.tab)
            )
            .subscribe(t => {
                this.router.navigate(['..', this.routerTab],{
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                    relativeTo: this.activatedRoute
                });

            });
    }

    /**
     * Checks to see if the current tab is in the URL. If it is, it will be activated.
     */
    private activateIfRoutedTab() {
        if (this.activatedRoute.snapshot.params.tab === this.routerTab) {
            this.tab.active = true;
        } else {
            this.tab.active = false;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
