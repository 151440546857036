import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
    transform(dateStr: string | Date): string | null {
        if (!dateStr) {
            return null;
        }
        return moment(dateStr).format('DD/MM/YYYY');
    }
}
