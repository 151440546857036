import { Component, Input } from '@angular/core';
import { UploadService } from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'bulk-upload-dialogue',
    templateUrl: './upload-dialogue.component.html'
})
export class BulkUploadDialogue {
    @Input() uploadService: UploadService;
    @Input() hasTemplate: boolean;
    @Input() entity: string;

    file: File;
    baseDropValid: boolean;

    constructor(private modal: BsModalRef) {}

    fileSelected(file: File) {
        this.uploadService.importFile(file);
    }

    downloadTemplate() {
        this.uploadService.downloadTemplate();
    }

    cancel() {
        this.modal.hide();
    }
}
