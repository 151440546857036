import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';
import { AvailableReportDto } from '@flyfreely-portal-ui/flyfreely';
import { httpParamSerializer } from '../service.helpers';

@Injectable({
    providedIn: 'root'
})
export class MissionReportService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findAvailableTemplates(
        reportEventIdentifier: string,
        missionId: number,
        managingOrganisationId: number
    ) {
        return this.http.get<AvailableReportDto[]>(
            `${this.baseUrl}/reportsapi/missions/${missionId}`,
            {
                params: httpParamSerializer({
                    reportEventIdentifier,
                    managingOrganisationId
                })
            }
        );
    }

    getHtmlReportUrl(
        reportTemplateId: number,
        missionId: number,
        managingOrganisationId: number
    ) {
        const params = httpParamSerializer({
            managingOrganisationId,
            format: 'pdf'
        }).toString();

        return `${this.baseUrl}/reportsapi/missions/${missionId}/render/${reportTemplateId}?${params}`;
    }

    getPdfReportUrl(
        reportTemplateId: number,
        missionId: number,
        managingOrganisationId: number
    ) {
        const params = httpParamSerializer({
            managingOrganisationId,
            format: 'pdf'
        }).toString();

        return `${this.baseUrl}/reportsapi/missions/${missionId}/render/${reportTemplateId}?${params}`;
    }

    getMissionListReportUrl(
        organisationId: number,
        orOrganisationId: number,
        authorityIds: number[],
        startTime: string,
        endTime?: string
    ) {
        const params = httpParamSerializer({
            organisationId,
            orOrganisationId,
            authorityIds,
            startTime,
            endTime
        }).toString();

        return `${this.baseUrl}/reportsapi/missions/aggregate?${params}`;
    }

    getFlightReportUrl(
        organisationId: number,
        orOrganisationId: number,
        authorityIds: number[],
        startTime: string,
        endTime?: string,
        withFlightLogs?: boolean
    ) {
        const params = httpParamSerializer({
            organisationId,
            orOrganisationId,
            authorityIds,
            startTime,
            endTime,
            withFlightLogs:
                withFlightLogs != null ? withFlightLogs.toString() : null
        }).toString();

        return `${this.baseUrl}/reportsapi/missions/flightAggregate?${params}`;
    }
}
