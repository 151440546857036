import {
    CreateAddressCommand,
    UpdateAddressCommand,
    AddressDto
} from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findAddressesForOrganisation(organisationId: number) {
        return this.http.get<AddressDto[]>(`${this.baseUrl}/webapi/profiles`, {
            params: new HttpParams().append(
                'organisationId',
                organisationId.toString()
            )
        });
    }

    createAddress(profileDetails: CreateAddressCommand) {
        return this.http
            .post<AddressDto>(`${this.baseUrl}/webapi/profiles`, profileDetails)
            .pipe(tap(() => this.changeSource.next()));
    }

    updateAddress(addressId: number, profileDetails: UpdateAddressCommand) {
        return this.http
            .put<AddressDto>(
                `${this.baseUrl}/webapi/profiles/${addressId}`,
                profileDetails
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteAddress(addressId: number) {
        return this.http.delete<void>(
            `${this.baseUrl}/webapi/profiles/${addressId}`
        );
    }

    findUserAddresses() {
        return this.http.get<AddressDto[]>('/webapi/user/profile');
    }

    findAddressTypes() {
        return {
            RESIDENTIAL: 'Residential',
            WORK: 'Work',
            POSTAL: 'Postal'
        };
    }

    findStates() {
        return {
            ACT: 'ACT',
            QLD: 'QLD',
            NSW: 'NSW',
            VIC: 'VIC',
            WA: 'WA',
            TAS: 'TAS',
            NT: 'NT'
        };
    }
}
