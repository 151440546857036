import { Component } from '@angular/core';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'switch-to-desktop',
    templateUrl: './switch-to-desktop.component.html',
    styleUrls: ['./switch-to-desktop.component.scss']
})
export class SwitchToDesktop {
    constructor(private onboardingScreensDataService: OnboardingData) {}

    next() {
        this.onboardingScreensDataService.acceptMobileWarning();
    }
}
