import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
    selector: 'textarea'
})
export class AutoGrowTextAreasDirective {
    // Enable auto grow by default
    // Auto grow will respect max-height in element styles.
    // Set [autoGrow]="false" in textarea attributes to disable autogrow.
    // Disabling allows styles like "height: auto" to be set relative to the parent div.
    @Input() autoGrow?: boolean = true;
    maxHeightReached: boolean = false;

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        if (this.autoGrow) {
            this.updateGrow();
            this.updateOverflow();
            this.renderer.listen(this.elRef.nativeElement, 'mouseup', () => {
                this.updateOverflow();
            });
        }
    }

    ngAfterContentChecked() {
        if (!this.maxHeightReached && this.autoGrow) {
            this.updateGrow();
            this.updateOverflow();
        }
    }

    updateOverflow() {
        // check either for reaching max height or the textarea being smaller than text on resize and add a scroll bar
        // having overflow always set to auto causes a scrollbar when the element auto-resizes.
        const overflow =
            (this.elRef.nativeElement.style.maxHeight !== '' &&
                this.elRef.nativeElement.style.height >
                    this.elRef.nativeElement.style.maxHeight) ||
            this.elRef.nativeElement.offsetHeight <
                this.elRef.nativeElement.scrollHeight
                ? 'auto'
                : 'hidden';
        this.maxHeightReached = overflow === 'auto' ? true : false;
        this.renderer.setStyle(
            this.elRef.nativeElement,
            'overflow-y',
            overflow
        );
    }

    updateGrow() {
        if (
            this.elRef.nativeElement.offsetHeight <
                this.elRef.nativeElement.scrollHeight &&
            !this.maxHeightReached
        ) {
            this.renderer.setStyle(
                this.elRef.nativeElement,
                'height',
                `${this.elRef.nativeElement.scrollHeight}px`
            );
        }
    }
}
