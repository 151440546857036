import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    BatteryDto,
    BatteryService,
    Cancellable,
    ExclusiveControlService,
    FEATURE_ADDITIONAL_MAINTENANCE,
    FEATURE_BATTERY_HEALTH,
    FEATURE_SCHEDULED_MAINTENANCE,
    findOrganisation,
    hasFeatureFlag,
    Organisation,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { batteryMaintenanceServiceProvider } from '../battery-maintenance.service';
import { ResourceMaintenanceService } from 'libs/maintenance/src/lib/resource-maintenance.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NoteType } from '../batteries-notes/notes-edit/notes-edit.component';

@Component({
    selector: 'battery-details-dialogue',
    templateUrl: './battery-details-dialogue.component.html',
    providers: [ExclusiveControlService, batteryMaintenanceServiceProvider]
})
export class BatteryDetailsDialogue implements OnInit, OnDestroy {
    @Input() organisation: Organisation;
    @Input() battery: BatteryDto;

    @ViewChild('batteryTabs', { static: true }) batteryTabs: TabsetComponent;
    @ViewChild('batteryEdit', { static: false }) batteryEdit: Cancellable;

    isEdit = false;

    hasScheduledMaintenance: boolean;
    hasBatteryHealth = false;

    maintenanceScheduleFeature = [FEATURE_SCHEDULED_MAINTENANCE];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<BatteryDetailsDialogue>,
        private batteryMaintenanceService: ResourceMaintenanceService,
        modalOptions: ModalOptions,
        private userService: UserService,
        private batteryService: BatteryService
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.isEdit) {
                this.batteryEdit.cancel();
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        const maybeOrganisation = findOrganisation(
            this.userService.findUsersOrganisations(),
            this.battery.organisationId
        );

        this.hasScheduledMaintenance = pipe(
            maybeOrganisation,
            map(
                organisation =>
                    hasFeatureFlag(
                        organisation,
                        FEATURE_SCHEDULED_MAINTENANCE
                    ) &&
                    hasFeatureFlag(organisation, FEATURE_ADDITIONAL_MAINTENANCE)
            ),
            getOrElse(() => false)
        );
        this.hasBatteryHealth = pipe(
            maybeOrganisation,
            map(organisation =>
                hasFeatureFlag(organisation, FEATURE_BATTERY_HEALTH)
            ),
            getOrElse(() => false)
        );

        if (this.hasScheduledMaintenance) {
            this.batteryMaintenanceService.setup(this.battery.id);
        }

        this.batteryService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshBattery());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshBattery() {
        this.batteryService
            .findBattery(this.battery.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(battery => {
                this.battery = battery;
            });
    }

    toggleViewEdit() {
        this.isEdit = !this.isEdit;
        this.disableEnableTabs();
    }

    disableEnableTabs() {
        for (let i = 0; i < this.batteryTabs.tabs.length; i++) {
            this.batteryTabs.tabs[i].disabled =
                !this.batteryTabs.tabs[i].disabled;
        }
    }

    batteryUpdated(battery: BatteryDto) {
        this.battery = Object.assign({}, battery);
        this.toggleViewEdit();
    }

    close() {
        this.modal.hide();
    }

    onDeleted() {
        this.modal.hide();
    }

    protected readonly NoteType = NoteType;
}
