import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DO_NOTHING,
    EnhancedHelpDto,
    FlyFreelyConstants,
    httpParamSerializer,
    ScreenHelpDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { EnhancedHelpDialogueComponent } from './enhanced-help-dialogue/enhanced-help-dialogue.component';
import { EnhancedHelpTreeService } from './enhanced-help-tree.service';
import { HelpVideoDialogue } from './help-video-dialogue/help-video-dialogue.component';

export const EVENT_ENHANCED_HELP_ON = 'enhanced-help-on';
export const EVENT_ENHANCED_HELP_OFF = 'enhanced-help-off';

export interface HelpEntry {
    helpTitle: string;
    helpText: string;
    screen: string;
    component: string;
    supportVideoUrl?: string;
    knowledgeBaseUrl?: string;
    alwaysOnUntil?: string;
}

export interface HelpGroup {
    identifier: string;
    name: string;
    components: HelpEntry[];
}

@Injectable({
    providedIn: 'root'
})
export class EnhancedHelpService {
    private baseUrl: string;

    private messages$ = new ReplaySubject<EnhancedHelpDto[]>(1);
    changes$ = new Subject<void>();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private treeService: EnhancedHelpTreeService,
        private modalService: BsModalService
    ) {
        this.baseUrl = constants.SITE_URL;

        this.findAll()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(messages => DO_NOTHING);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.messages$.complete();
        this.changes$.complete();
    }

    findAll() {
        return this.http
            .get<EnhancedHelpDto[]>(`${this.baseUrl}/webapi/enhancedHelp`)
            .pipe(
                tap(messages => {
                    this.messages$.next(messages);
                    this.changes$.next();
                })
            );
    }

    findByScreen(screen: string) {
        return this.http.get<EnhancedHelpDto[]>(
            `${this.baseUrl}/webapi/enhancedHelp`,
            { params: httpParamSerializer({ screen }) }
        );
    }

    /**
     * Returns the enhanced help object and then completes. If there is no help object
     * it will just complete.
     * @param screen screen identifier
     * @param component component identifier
     * @returns observable of the enhanced help object
     */
    findByScreenAndComponent(
        screen: string,
        component: string,
        allowNull?: boolean
    ) {
        const componentObject = this.treeService.findComponent(
            screen,
            component
        );

        return this.messages$.pipe(
            take(1),
            map(
                messages =>
                    messages.find(
                        m => m.screen === screen && m.component === component
                    ) ?? this.lookupDefault(screen, component)
            ),
            filter(message => (allowNull ? allowNull : message != null)),
            map(message =>
                allowNull && message == null
                    ? ({
                          component: componentObject?.identifier ?? component,
                          helpText: componentObject?.defaultText,
                          helpTitle: componentObject?.name ?? component,
                          screen: screen
                      } as EnhancedHelpDto)
                    : ({
                          ...message,
                          helpText:
                              message.helpText ?? componentObject.defaultText
                      } as EnhancedHelpDto)
            )
        );
    }

    findAllScreenHelp() {
        return this.http.get<ScreenHelpDto[]>(
            `${this.baseUrl}/webapi/screenHelp`
        );
    }

    lookupDefault(screen: string, component: string): EnhancedHelpDto {
        const componentObject = this.treeService.findComponent(
            screen,
            component
        );
        if (componentObject == null) {
            return undefined;
        }

        if (componentObject.defaultText != null) {
            return {
                component,
                screen,
                helpTitle: componentObject.name,
                helpText: componentObject.defaultText
            };
        }
    }

    /**
     * Show the video player
     * @param videoUrl the url to the mp4 or embedded player
     * @param title the title for the modal
     */
    showVideo(videoUrl: string, title: string) {
        this.modalService.show(HelpVideoDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                videoUrl,
                title
            }
        });
    }

    showHelpDialogue(helpObject: EnhancedHelpDto, isDemo?: boolean) {
        return this.modalService.show(EnhancedHelpDialogueComponent, {
            ...MODAL_OPTIONS,
            class: 'modal-task-wide',
            initialState: {
                helpObject,
                isDemo
            }
        });
    }
}
