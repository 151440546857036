import { Pipe, PipeTransform } from '@angular/core';
import {
    AirspaceAuthorisationDto,
    OperationAuthorisation
} from '@flyfreely-portal-ui/flyfreely';

const LOOKUP_TABLE = {
    ['']: {
        [OperationAuthorisation.AuthorisationEligibility.NOT_REQUIRED]:
            'Not required',
        [OperationAuthorisation.AuthorisationEligibility.AUTO]: 'Automatic',
        [OperationAuthorisation.AuthorisationEligibility.MANUAL]: 'Manual',
        [OperationAuthorisation.AuthorisationEligibility.NOT_ELIGIBLE]:
            'Not Eligible',
        [OperationAuthorisation.AuthorisationEligibility.UNAVAILABLE]:
            'None Available',
        [OperationAuthorisation.AuthorisationEligibility.UNKNOWN]: 'Unknown'
    },
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: {
        [OperationAuthorisation.AuthorisationEligibility.AUTO]:
            'Automatic Authorization',
        [OperationAuthorisation.AuthorisationEligibility.MANUAL]:
            'Further Coordination'
    }
};

@Pipe({
    name: 'formatAuthorisationEligibility'
})
export class FormatAuthorisationEligibilityPipe implements PipeTransform {
    transform(
        eligibility: OperationAuthorisation.AuthorisationEligibility,
        authorisationType?: AirspaceAuthorisationDto.AuthorisationType
    ): string {
        if (authorisationType == null) {
            return LOOKUP_TABLE[''][eligibility];
        }
        return (
            LOOKUP_TABLE[authorisationType][eligibility] ??
            LOOKUP_TABLE[''][eligibility]
        );
    }
}
