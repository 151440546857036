<ul
    *ngIf="
        currentOrganisation != null &&
        currentOrganisation.type === 'organisation_loaded'
    "
    class="nav flex-column"
>
    <li class="nav-item nav-heading">
        <ul class="nav" *ngIf="widgets">
            <li
                class="nav-item"
                *ngIf="widgets.mapWidget && widgets.mapWidget.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-map-marked fa nav-icon"></span>
                        <span class="fa-map-marked fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('mapWidget')">Map</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.mapWidget.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.welcome && widgets.welcome.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-clipboard-check fa nav-icon"></span>
                        <span class="fa-clipboard-check fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('welcome')">Getting Started</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.welcome.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.missionList && widgets.missionList.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-list-ul fa nav-icon"></span>
                        <span class="fa-list-ul fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('missionList')">Missions</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.missionList.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.rpaList && widgets.rpaList.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-plane fa nav-icon"></span>
                        <span class="fa-plane fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('rpaList')">RPA</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.rpaList.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.personnelList && widgets.personnelList.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-users fa nav-icon"></span>
                        <span class="fa-users fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('personnelList')">Personnel</span>
                    </div>
                    <div class="d-flex vertical-align " mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.personnelList.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.batteryList && widgets.batteryList.available"
            >
                <div class="nav-link widgets-control">      
                    <div>
                        <span class="fa-battery-empty fa nav-icon"></span>
                        <span class="fa-battery-empty fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('batteryList')">Batteries</span>
                    </div>
                    <div class="d-flex vertical-align pull-right" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.batteryList.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="
                    widgets.batterySetList && widgets.batterySetList.available
                "
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-battery-full fa nav-icon"></span>
                        <span class="fa-battery-full fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('batterySetList')">Flight Battery Sets</span>
                    </div>
                    <div class="d-flex vertical-align pull-right" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="
                                        widgets.batterySetList.selected
                                    "
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="
                    widgets.missionApprovalList &&
                    widgets.missionApprovalList.available
                "
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-list-alt fa nav-icon"></span>
                        <span class="fa-list-alt fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('missionApprovalList')">Approvals</span>
                    </div>
                    <div class="d-flex vertical-align pull-right" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="
                                        widgets.missionApprovalList.selected
                                    "
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.maintenance && widgets.maintenance.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-wrench fa nav-icon"></span>
                        <span class="fa-wrench fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('maintenance')">Maintenance</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.maintenance.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.locations && widgets.locations.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-globe fa nav-icon"></span>
                        <span class="fa-globe fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('locations')">Location Templates</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.locations.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.libraries && widgets.libraries.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-folder fa nav-icon"></span>
                        <span class="fa-folder fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('libraries')">Libraries</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.libraries.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="
                    widgets.incidentReports && widgets.incidentReports.available
                "
            >
                <!-- <span
                    class="nav-link"
                    tooltip="Incident Reports"
                    placement="right"
                > -->
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-clipboard-list fa nav-icon"></span>
                        <span class="fa-clipboard-list fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('incidentReports')">Incident Reports</span>
                    </div>
                    <div class="d-flex vertical-align " mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="
                                        widgets.incidentReports.selected
                                    "
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.riskRegister && widgets.riskRegister.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-exclamation-triangle fa nav-icon"></span>
                        <span class="fa-exclamation-triangle fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('riskRegister')">Risk Register</span>
                    </div>
                    <div class="d-flex vertical-align " mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.riskRegister.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="
                    widgets.organisationLinks &&
                    widgets.organisationLinks.available
                "
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-link fa nav-icon"></span>
                        <span class="fa-link fa nav-icon active"></span>
                        <span class="name" (click)="scrollTo('organisationLinks')">Organisation Links</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="
                                        widgets.organisationLinks.selected
                                    "
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li
                class="nav-item"
                *ngIf="widgets.equipment && widgets.equipment.available"
            >
                <div class="nav-link widgets-control">
                    <div>
                        <span class="fa-video fas nav-icon"></span>
                        <span class="fa-video fas nav-icon active"></span>
                        <span class="name" (click)="scrollTo('equipment')">Equipment</span>
                    </div>
                    <div class="d-flex vertical-align" mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="widgets.equipment.selected"
                                    (change)="onMenuChanged()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </li>
</ul>
