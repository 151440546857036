import { Component } from '@angular/core';
import { SetupGuideActivityDto } from '@flyfreely-portal-ui/flyfreely';
import { SetupGuideChecklistService } from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import { Angulartics2 } from 'angulartics2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const SCREEN_IDENTIFIER = 'quick-setup';

@Component({
    selector: 'quick-setup',
    templateUrl: './quick-setup.component.html',
    styles: [
        `
            .time-container {
                display: flex;
                align-items: center;
                margin: 20px 0;
            }
            .clock {
                font-size: 3em;
                color: #007dd5;
                margin-right: 10px;
                margin-left: 10px;
                background: linear-gradient(135deg, #2ac0c1 0%, #007dd5);
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .timing-block {
                display: flex;
                align-items: center;
                font-size: 1.5em;
            }
            .timing-block > span {
                color: #007dd5;
                line-height: 1em;
            }
            .quick-start-text {
                color: #007dd5;
                margin: 0 10px;
            }
            .quick-setup-container {
                display: flex;
                width: 100%;
                margin: 10px 0;
            }

            .start-here-button {
                width: 100%;
                border-radius: 1em;
                height: 8em;
                font-size: 1rem;
                text-transform: uppercase;
            }
            .start-here-button:disabled {
                border-color: #007dd5;
                color: white;
                opacity: 1;
            }
        `
    ]
})
export class QuickSetupComponent {
    types = SetupGuideActivityDto.Type;

    isStartHereActive: boolean;
    working: boolean;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private setupGuideChecklistService: SetupGuideChecklistService,
        private angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        this.setupGuideChecklistService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.setupGuideChecklistService.checklistType$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                types =>
                    (this.isStartHereActive = types.includes(
                        this.types.START_HERE
                    ))
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    changeChecklist(type: SetupGuideActivityDto.Type) {
        this.setupGuideChecklistService.setChecklist(type);
        if (type === SetupGuideActivityDto.Type.START_HERE) {
            this.angulartics2.eventTrack.next({
                action: 'start-here',
                properties: {
                    category: SCREEN_IDENTIFIER,
                    label: SetupGuideActivityDto.Type.START_HERE
                }
            });
        }
    }
}
