import { FlyFreelyConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MissionRoleDto } from '../model/api';
import { Subject } from 'rxjs';
import { httpParamSerializer } from './service.helpers';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MissionRoleService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findMissionRoles(organisationId: number) {
        return this.http
            .get<MissionRoleDto[]>(`${this.baseUrl}/webapi/missionRoles`, {
                params: httpParamSerializer({ organisationId })
            })
            .pipe(
                map((roles: MissionRoleDto[]) =>
                    roles.sort((a, b) => a.order - b.order)
                )
            );
    }
}
