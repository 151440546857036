/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.38-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FormControlDto } from './formControlDto';


/**
 * The section of the form
 */
export interface FormSectionDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The name of this form section
     */
    name: string;
    /**
     * The description for this form section
     */
    description?: string;
    /**
     * The value used to order the sections
     */
    order: number;
    /**
     * Is this section a repeating group (table)
     */
    repeatingGroup: boolean;
    /**
     * How is this form section laid out?
     */
    layout: FormSectionDto.Layout;
    /**
     * The controls in this section
     */
    controls: Array<FormControlDto>;
}
export namespace FormSectionDto {
    export type Layout = 'LINEAR' | 'TABLE' | 'TWELVE_COLUMN';
    export const Layout = {
        LINEAR: 'LINEAR' as Layout,
        TABLE: 'TABLE' as Layout,
        TWELVE_COLUMN: 'TWELVE_COLUMN' as Layout
    };
}


