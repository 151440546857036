import { ModalOptions } from 'ngx-bootstrap/modal';

/**
 * This is the config that is used by all ngx-bootstrap
 */
export const MODAL_OPTIONS: ModalOptions = {
    backdrop: false,
    ignoreBackdropClick: true,
    keyboard: true
};
