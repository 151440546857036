import { Pipe, PipeTransform } from '@angular/core';
import { combinedStatuses } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '../helpers';

@Pipe({ name: 'formatMissionStatus' })
export class FormatMissionStatusPipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor() {
        this.transform = setupStatusFormatter(combinedStatuses);
    }
}
