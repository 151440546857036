<form
    [formGroup]="personForm"
    novalidate
    class="container-with-footer"
    (ngSubmit)="onSubmit()"
>
    <div class="vertical-container container-padding">
        <div
            *ngIf="!isNewPerson && !canEditDetails"
            class="alert alert-info top-buffer"
        >
            This user is not managed by this organisation, so you can't edit
            these details.
        </div>

        <formly-form
            [fields]="fields"
            [model]="originPerson"
            [form]="personDetailsForm"
        >
        </formly-form>

        <h4>Organisation Specific Roles</h4>
        <personnel-roles
            *ngIf="allowStudent != null"
            [formGroup]="personRolesForm"
            [allowStudent]="allowStudent"
            [allowReadOnly]="allowReadOnly"
            [allowInstantMission]="allowInstantMission"
            [allowReadOnlyHidden]="allowReadOnlyHidden"
            [allowFlightLogCollection]="allowFlightLogCollection"
            [allowExternalMaintenancePerson]="allowExternalMaintenancePerson"
            [pilotLimitReached]="!allowPilot"
            [totalPilots]="totalPilots"
            [availablePilots]="availablePilots"
        ></personnel-roles>
    </div>
    <div class="action-container">
        <button
            type="button"
            class="btn btn-default pull-left"
            (click)="cancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary pull-right"
            type="submit"
            [disabled]="personForm.invalid || personForm.pristine || working"
        >
            {{ isNewPerson ? 'Create' : 'Update' }}
        </button>
    </div>
</form>
