import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    ConditionsLibraryDto,
    ConditionsLibraryEntryDto,
    ConditionsLibraryService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'apply-approval-conditions',
    templateUrl: './apply-approval-conditions.component.html'
})
export class ApplyApprovalConditions {
    @Input() authorityOrganisationId: number;
    // mainly used by the webview for styling
    @Input() simplifiedView = false;
    @Output() appliedConditions = new EventEmitter<string[]>();
    @Output() cancel = new EventEmitter<void>();

    selectedConditions: number[] = [];
    allConditions: ConditionsLibraryEntryDto[] = [];

    conditionLibraries: ConditionsLibraryDto[];
    selectedLibraries: number[] = [];

    form: FormGroup;

    private workTracker: WorkTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private conditionsLibraryService: ConditionsLibraryService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.findConditionLibraries();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findConditionLibraries() {
        this.conditionsLibraryService
            .find(this.authorityOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: libraries => {
                    this.conditionLibraries = libraries;
                    libraries.forEach(library => {
                        library.conditions.forEach(c => {
                            this.allConditions.push(c);
                        });
                    });
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading condition libraries: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    toggleSelectAll(library: ConditionsLibraryDto) {
        if (this.selectedLibraries.includes(library.id)) {
            library.conditions.forEach(c => {
                if (!this.selectedConditions.includes(c.id)) {
                    this.selectedConditions = [
                        ...this.selectedConditions,
                        c.id
                    ];
                }
            });
        } else {
            const selected = this.selectedConditions;
            library.conditions.forEach(c => {
                const i = selected.indexOf(c.id);
                if (i !== -1) {
                    this.selectedConditions = selected.splice(i);
                }
            });
            this.selectedConditions = [...selected];
        }
    }

    toggleConditionSelected(
        cond: ConditionsLibraryEntryDto,
        lib: ConditionsLibraryDto
    ) {
        const selected = lib.conditions.filter(c =>
            this.selectedConditions.includes(c.id)
        );
        if (selected.length === 0) {
            this.selectedLibraries = [
                ...this.selectedLibraries.filter(l => l !== lib.id)
            ];
        }
        if (selected.length === lib.conditions.length) {
            this.selectedLibraries = [...this.selectedLibraries, lib.id];
        }
    }

    addConditions() {
        const conditions = this.selectedConditions.map(selectedConditionId => {
            return this.allConditions.find(c => c.id === selectedConditionId)
                .condition;
        });

        this.selectedConditions = [];
        this.selectedLibraries = [];
        this.appliedConditions.emit(conditions);
    }
}
