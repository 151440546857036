<ng-container screenAnalytics="equipment-view" *ngIf="equipment">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-equipment.png" />
        </div>
        <h3 class="modal-title">
            {{ isEditing !== 'NONE' ? 'Edit' : 'View' }} Equipment
        </h3>
        <p class="modal-title padding">{{ equipment.name }}</p>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body inner-container no-footer">
        <tabset
            #staticTabs
            vertical="true"
            type="pills"
            class="tab-set-vertical"
            *ngIf="equipment != null"
        >
            <tab [disabled]="isEditing !== 'NONE'">
                <ng-template tabHeading>
                    <i class="far fa-list-ul tab-title-icon"></i>
                    <span>Details</span>
                </ng-template>
                <equipment-view
                    class="modal-body-content"
                    *ngIf="isEditing !== 'DETAILS'"
                    [organisation]="organisation"
                    [equipment]="equipment"
                    [allowEdit]="true"
                    (edit)="editEquipment()"
                    (deleted)="onEquipmentDeleted()"
                >
                </equipment-view>
                <equipment-edit
                    #equipmentEdit
                    class="modal-body-content"
                    *ngIf="isEditing === 'DETAILS'"
                    [equipment]="equipment"
                    [organisation]="organisation"
                    (cancelled)="onEditCancelled()"
                    (updatedEquipment)="onUpdateEquipment($event)"
                >
                </equipment-edit>
            </tab>
            <tab [disabled]="isEditing !== 'NONE'">
                <ng-template tabHeading>
                    <i class="far fa-memo-pad tab-title-icon"></i>
                    <span>Notes</span>
                </ng-template>
                <notes-view *ngIf="isEditing !== 'NOTES'"
                            class="modal-body-content"
                            [equipment]="equipment"
                            (edit)="editEquipmentNotes()"
                ></notes-view>
                <notes-edit
                    *ngIf="isEditing === 'NOTES'"
                    class="modal-body-content"
                    [equipment]="equipment"
                    (cancelled)="onEditCancelled()"
                >
                </notes-edit>
            </tab>
            <tab [disabled]="isEditing !== 'NONE'">
                <ng-template tabHeading>
                    <i class="far fa-wrench tab-title-icon"></i>
                    <span>Maintenance History</span>
                </ng-template>
                <div class="modal-body-content container-scroll">
                    <plan-highlight
                        [featureFlags]="maintenanceScheduleFeature"
                    ></plan-highlight>
                    <maintenance-schedule-config
                        *ngIf="hasScheduledMaintenance"
                        [resourceId]="equipment.id"
                        [resourceType]="'EQUIPMENT'"
                        [subCategory]="
                            equipment?.equipmentType?.equipmentCategory
                        "
                        [managingOrganisationId]="organisation.id"
                        [maintenanceScheduleAssignment]="
                            equipment.assignedMaintenanceSchedule
                        "
                        [canAssign]="equipment.availableActions.canEdit"
                        [organisationId]="equipment.organisationId"
                    ></maintenance-schedule-config>
                    <!--  <component-service-time
                        [resourceId]="equipment.id"
                        [resourceType]="'EQUIPMENT'"
                        [managingOrganisationId]="organisation.id"
                        [hasScheduledMaintenance]="hasScheduledMaintenance"
                    ></component-service-time> -->
                    <h4 class="section-heading">
                        <span>Maintenance History</span>
                        <div></div>
                    </h4>
                    <maintenance-history
                        [organisationId]="organisation.id"
                        [resourceType]="'EQUIPMENT'"
                        [resourceId]="equipment.id"
                    ></maintenance-history>
                </div>
            </tab>
            <tab [disabled]="isEditing !== 'NONE'">
                <ng-template tabHeading>
                    <i class="far fa-plane tab-title-icon"></i>
                    <span>Flight History</span>
                </ng-template>
                <div class="container-scroll modal-body-content">
                    <flight-history-summary
                        [equipment]="equipment"
                    ></flight-history-summary>
                    <logged-flights
                        [equipment]="equipment"
                        [managingOrganisationId]="organisation.id"
                    ></logged-flights>
                </div>
            </tab>
        </tabset>
    </div>
</ng-container>
