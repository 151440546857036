import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {
    AirspaceCheckCommand,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    DEFAULT_MAX_HEIGHT,
    RuleOutcome,
    RulesetDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { AirspaceCheckService } from 'libs/airspace/src/lib/airspace-check/airspace-check.service';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { LocationAirspaceParameters } from '../location-edit-dialogue.component';
import { AirspaceDialoguesService } from 'libs/airspace/src/lib/airspace-dialogues.service';

interface AirspaceCheckerParametersForm {
    airspaceDate: FormControl<Date>;
    airspaceDuration: FormControl<number>;
    airspaceRuleset: FormControl<RulesetDto>;
    airspaceHeight: FormControl<number>;
    airspaceTimeZone: FormControl<string>;
    radius: FormControl<number>;
}

interface AirspaceCheckerParameters {
    airspaceDate: Date;
    airspaceDuration: number;
    airspaceRuleset: RulesetDto;
    airspaceHeight: number;
    airspaceTimeZone: string;
    radius?: number;
}

export interface AirspaceCheckerParametersWithStartEnd
    extends AirspaceCheckerParameters {
    startTime: string;
    endTime: string;
}

type AirspaceCheckCommandWithoutLocation = Omit<
    AirspaceCheckCommand,
    'location' | 'jurisdiction'
>;

function isInFutureValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        // Allow a 1 minute buffer to ensure the time doesn't immediately invalidate when running the check
        const isInPast = moment(value).isBefore(moment(), 'minutes');

        // return isInPast ? { isInPast: true } : null;
        return null;
    };
}

@Component({
    selector: 'location-airspace-check',
    templateUrl: './location-airspace-check.component.html',
    styleUrls: ['../location-edit-dialogue.scss']
})
export class LocationAirspaceChecker {
    @Input() organisationId: number;
    @Input() timeZone: string;
    @Input() flightArea: GeoJSON.Polygon | GeoJSON.Point;
    @Input() missionValues: LocationAirspaceParameters;
    @Input() workTracker: WorkTracker;
    @Input() jurisdiction?: AirspaceJurisdictionDto;
    @Input() radius: number;
    @Input() emptyStateMessage = 'Draw a flight area to begin';
    @Input() allowMissionCreation = false;
    @Input() toggleCheck = false;
    @Input() isDashboardChecker = false;
    @Input() viewMode: 'MAP' | 'BOTH' | 'NONE' = 'NONE';

    /**
     * The checker parameters have been updated
     */
    @Output() checkerParametersChange =
        new EventEmitter<AirspaceCheckerParametersWithStartEnd>();

    /**
     * The create mission button was pressed
     */
    @Output() createMission = new EventEmitter<void>();

    /**
     * The checker is awaiting a feature to check
     */
    @Output() awaitingFeature = new EventEmitter<boolean>();

    checkerTimeZone: string;
    isInPast = false;

    useSimplified = false;
    showSimplifiedOutcome = false;
    // airspaceCheckCommand: AirspaceCheckCommand;
    airspaceRulesetLookup: RulesetDto[] = [];
    airspaceInitialised = false;
    editingAirspaceParameters = false;

    firstCheck = true;
    showAirspaceChecker = false;

    /**
     * The text of the authorisations available
     */
    authorisationAvailable: string = null;

    // The start time for the checker. This is displayed in the checker's collapsed state.
    checkerTime: string;

    checkerTimeZoneAbbreviation: string;

    airspaceStatus:
        | 'CAN_FLY'
        | 'CAN_FLY_CONDITIONS'
        | 'CANNOT_FLY'
        | 'INCOMPLETE'
        | 'CHECKING'
        | 'IDLE' = 'IDLE';

    // This indicates that we are currently drawing a location to check. This is separate the airspaceStatus so we
    // can revert when the check is cancelled
    isDrawing = false;

    caaAuthorisationStatus:
        | 'AUTHORISATION_REQUIRED'
        | 'AUTHORISATION_NOT_REQUIRED'
        | 'UNKNOWN';
    airspaceResultCounter: number;
    hasFlightArea: boolean;

    // Not sure of the full meaning of this
    canCheckAirspace = true;
    working: boolean;
    private ngUnsubscribe$ = new Subject<void>();

    formGroup = new FormGroup<AirspaceCheckerParametersForm>({
        airspaceDate: new FormControl<Date>(undefined, [isInFutureValidator()]),
        // The airspace checker can't check 24 hours or beyond, so it's enforced via form validators
        airspaceDuration: new FormControl<number>(undefined, [
            Validators.max(82800),
            Validators.min(1)
        ]),
        airspaceRuleset: new FormControl<RulesetDto>(
            undefined,
            Validators.required
        ),
        airspaceHeight: new FormControl<number>(undefined, Validators.required),
        airspaceTimeZone: new FormControl<string>(undefined),
        radius: new FormControl<number>(undefined)
    });

    private flightArea$ = new ReplaySubject<GeoJSON.Polygon | GeoJSON.Point>(1);
    private airspaceCheckCommand$ =
        new ReplaySubject<AirspaceCheckCommandWithoutLocation>(1);

    constructor(
        private airspaceCheckService: AirspaceCheckService,
        private deviceDetectorService: DeviceDetectorService,
        private airspaceDialoguesService: AirspaceDialoguesService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.airspaceCheckService.resultStatus$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(status => {
                if (
                    !this.hasFlightArea &&
                    !this.firstCheck &&
                    this.canCheckAirspace
                ) {
                    this.airspaceStatus = 'INCOMPLETE';
                    this.caaAuthorisationStatus = 'UNKNOWN';
                    return;
                }
                if (status === 'CHECKING' || status === 'INCOMPLETE') {
                    this.showSimplifiedOutcome = this.useSimplified;
                }
                switch (status) {
                    case 'CAN_FLY':
                        this.airspaceStatus = 'CAN_FLY';
                        break;

                    case 'CAN_FLY_CONDITIONS':
                        this.airspaceStatus = 'CAN_FLY_CONDITIONS';
                        break;

                    case 'CANNOT_FLY':
                        this.airspaceStatus = 'CANNOT_FLY';
                        break;

                    case 'CHECKING':
                        this.airspaceStatus = 'CHECKING';
                        this.caaAuthorisationStatus = 'UNKNOWN';
                        break;

                    case 'INCOMPLETE':
                    case 'NO_CHECK':
                        this.airspaceStatus =
                            this.firstCheck && status === 'INCOMPLETE'
                                ? 'IDLE'
                                : 'INCOMPLETE';
                        this.caaAuthorisationStatus = 'UNKNOWN';
                        break;

                    default:
                        this.airspaceStatus = 'IDLE';
                        this.caaAuthorisationStatus = 'UNKNOWN';
                        break;
                }
                // Automatically expand the results on the first check if not on mobile
                if (
                    this.firstCheck &&
                    !(
                        this.deviceDetectorService.isMobile() ||
                        (this.deviceDetectorService.isTablet() &&
                            this.deviceDetectorService.orientation.toLowerCase() ===
                                'portrait')
                    )
                ) {
                    if (
                        status === 'CAN_FLY' ||
                        status === 'CANNOT_FLY' ||
                        status === 'CAN_FLY_CONDITIONS'
                    ) {
                        this.firstCheck = false;
                        this.showAirspaceChecker = true;
                    }
                }
            });

        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                if (result == null) {
                    this.airspaceStatus = this.firstCheck
                        ? 'IDLE'
                        : 'INCOMPLETE';
                    this.showAirspaceChecker = false;
                    this.showSimplifiedOutcome =
                        this.useSimplified &&
                        this.airspaceStatus === 'INCOMPLETE';
                    this.caaAuthorisationStatus = 'UNKNOWN';
                    this.authorisationAvailable = null;
                    return;
                }
                if (
                    this.checkerTimeZone == null ||
                    result.timeZone !== this.checkerTimeZone
                ) {
                    this.checkerTimeZone = result.timeZone;
                    this.formGroup.patchValue({
                        airspaceTimeZone: result.timeZone
                    });
                    if (this.checkerTimeZone != null) {
                        this.updateAirspaceCheckCommand();
                    }
                }

                this.authorisationAvailable =
                    result.availableAutomatedAuthorisationList.length > 0
                        ? 'Authorizations available'
                        : null;
                const status = result.flightStatus;
                if (!this.useSimplified) {
                    const s = AirspaceCheckDto.FlightStatus;
                    switch (status) {
                        case s.CAN_FLY:
                            this.airspaceResultCounter =
                                result.ruleOutcomes.filter(
                                    o => o.outcome === RuleOutcome.Outcome.PASS
                                ).length;
                            break;

                        case s.CAN_FLY_CONDITIONS:
                            this.airspaceResultCounter =
                                result.ruleOutcomes.filter(
                                    o =>
                                        o.outcome ===
                                            RuleOutcome.Outcome.ADVISE ||
                                        o.outcome ===
                                            RuleOutcome.Outcome
                                                .AUTHORISATION_REQUIRED
                                ).length;
                            break;

                        case s.CANNOT_FLY:
                            this.airspaceResultCounter =
                                result.ruleOutcomes.filter(
                                    o => o.outcome === RuleOutcome.Outcome.BLOCK
                                ).length;
                            break;

                        default:
                            this.airspaceResultCounter = 0;
                            break;
                    }
                } else {
                    if (
                        result.availableAutomatedAuthorisationList != null &&
                        result.availableAutomatedAuthorisationList.length > 0
                    ) {
                        if (
                            result.ruleOutcomes.find(
                                o =>
                                    o.outcome != null &&
                                    o.outcome === 'AUTHORISATION_REQUIRED'
                            ) != null
                        ) {
                            this.caaAuthorisationStatus =
                                'AUTHORISATION_REQUIRED';
                        } else {
                            this.caaAuthorisationStatus =
                                'AUTHORISATION_NOT_REQUIRED';
                        }
                    } else {
                        this.caaAuthorisationStatus = 'UNKNOWN';
                    }
                }
                this.changeDetector.markForCheck();
            });

        combineLatest([
            this.airspaceCheckService.ready$,
            this.flightArea$,
            this.airspaceCheckCommand$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([_, flightArea, command]) =>
                this.tryAirspaceCheck(flightArea, command)
            );

        this.flightArea$.next(this.flightArea);
        this.setupAirspaceForm();
    }

    ngOnDestroy() {
        // Ensure the checker results don't persist for this checker  after closing the modal
        this.resetCheckResults();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.flightArea$.complete();
        this.airspaceCheckCommand$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('flightArea' in changes) {
            this.flightArea$.next(this.flightArea);
            this.stopDrawing();
        }
        if ('jurisdiction' in changes) {
            this.airspaceCheckService.airspaceJurisdiction = this.jurisdiction;
            if (
                this.jurisdiction == null ||
                this.jurisdiction.identifier == null ||
                this.jurisdiction.identifier.length === 0
            ) {
                this.formGroup.patchValue({ airspaceRuleset: null });
                this.airspaceRulesetLookup = [];
                this.noValidJurisdiction();
            } else {
                this.airspaceRulesetLookup =
                    this.jurisdiction.rulesetList.filter(
                        r => r.identifier != null && r.identifier.length > 0
                    );
                this.formGroup.patchValue({
                    airspaceRuleset: this.missionValues?.ruleset
                        ? this.airspaceRulesetLookup.find(
                              r => r.identifier === this.missionValues?.ruleset
                          )
                        : this.airspaceRulesetLookup[0]
                });
                this.canCheckAirspace = true;
                this.updateAirspaceCheckCommand();
            }
        }
    }

    /**
     * This is to initialise values into the airspace form. Further updates are handled by "updateAirspaceCheckCommand()"
     */
    setupAirspaceForm() {
        if (this.checkerTimeZone == null) {
            this.checkerTimeZone = this.timeZone ?? moment.tz.guess();
        }

        const now = moment(
            // Ensure the current seconds are reset to zero
            moment(new Date()).format('YYYY-MM-DD HH:mm')
        ).toISOString();

        // If the mission doesn't yet have a ruleset, it means the mission date is still the default and most likely already in the past
        // In that case use the current time instead.
        const missionDate =
            this.missionValues?.ruleset != null &&
            this.missionValues?.date != null
                ? this.missionValues.date
                : now;

        const airspaceDate = moment(missionDate)
            .tz(this.checkerTimeZone)
            .toDate();

        this.isInPast = moment(airspaceDate).isBefore(
            moment(moment(new Date()).format('YYYY-MM-DD HH:mm')).subtract(
                1,
                'minute'
            ),
            'minutes'
        );

        this.formGroup.patchValue({
            airspaceDate: airspaceDate,
            airspaceDuration: this.missionValues?.duration ?? 3600,
            airspaceRuleset:
                this.airspaceRulesetLookup?.find(
                    r => r.identifier === this.missionValues?.ruleset
                ) ?? this.airspaceRulesetLookup
                    ? this.airspaceRulesetLookup[0]
                    : null,
            airspaceHeight: this.missionValues?.height ?? DEFAULT_MAX_HEIGHT,
            airspaceTimeZone: this.checkerTimeZone,
            radius: this.radius
        });
        this.formGroup.markAsPristine();

        combineLatest([
            this.formGroup.controls.airspaceDate.valueChanges.pipe(
                startWith(this.formGroup.value?.airspaceDate)
            ),
            this.formGroup.controls.airspaceTimeZone.valueChanges.pipe(
                startWith(this.formGroup.value?.airspaceTimeZone)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([value, timeZone]) => {
                // const time = moment(value).toISOString();
                // const newValue = moment(
                //     moment(time, true).tz(timeZone, true),
                //     true
                // ).toDate();
                // const newTime = !moment(value).isSame(moment(newValue))
                //     ? newValue
                //     : value;
                // console.log('setting time: ', moment(value), moment(newValue));
                this.formGroup.controls.airspaceDate.setValue(value, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });

                this.isInPast = moment(value).isBefore(
                    moment().subtract(1, 'minute'),
                    'minutes'
                );
            });

        this.updateAirspaceCheckCommand();
    }

    /**
     * Updates the airspace checker command and runs the check.
     * This should be triggered whenever any checker-relevant values change.
     */
    updateAirspaceCheckCommand() {
        if (
            // We can run checks for past dates, but not 24h+ durations, so block those here
            this.formGroup.controls.airspaceDuration.invalid ||
            !this.canCheckAirspace
        ) {
            return;
        }
        const value = this.formGroup.value;
        if (value?.airspaceDate == null) {
            // the date being null means the component is still loading.
            // This function will run again once everything is initialised properly.
            this.airspaceStatus = this.firstCheck ? 'IDLE' : 'INCOMPLETE';
            this.caaAuthorisationStatus = 'UNKNOWN';
            this.checkerParametersChange.emit({
                ...(<AirspaceCheckerParameters>value),
                startTime: null,
                endTime: null
            });
            return;
        }
        const tz =
            this.checkerTimeZone ??
            this.formGroup.value.airspaceTimeZone ??
            this.timeZone ??
            null;
        const t =
            tz != null
                ? moment.tz(
                      moment(value.airspaceDate).format(
                          'YYYY-MM-DD[T]HH:mm:ss'
                      ),
                      tz
                  ) /* .tz(tz) */
                : moment(
                      moment(value.airspaceDate).format('YYYY-MM-DD[T]HH:mm:ss')
                  );
        const startTime = t.toISOString();
        const endTime = moment(startTime)
            .add(value.airspaceDuration, 'seconds')
            .toISOString();
        // console.log(
        //     'checker times: ',
        //     value.airspaceDate,
        //     ', ',
        //     tz,
        //     ', ',
        //     t.toDate(),
        //     t.utc().toISOString()
        // );

        this.checkerParametersChange.emit({
            ...(<AirspaceCheckerParameters>value),
            startTime,
            endTime
        });

        const ruleset = value.airspaceRuleset;
        const altitude = value.airspaceHeight;
        const airspaceCheckCommand: AirspaceCheckCommandWithoutLocation = {
            ruleset: ruleset?.identifier,
            maximumHeight: altitude,
            startTime: startTime,
            endTime: endTime,
            nearestFeatureSearch: false,
            nearestFeatureSearchRadiusMeters: 400,
            rpaList: [] // this.airspaceCheckCommand?.rpaList ?? []
        };

        // Only close the edit mode if the formGroup is valid.
        // The additional logic is because we still want the check to happen if not editing the parameters
        this.editingAirspaceParameters =
            this.editingAirspaceParameters && this.formGroup.invalid
                ? true
                : false;
        this.airspaceInitialised = true;
        this.checkerTime = `${moment(value.airspaceDate).format(
            'D MMM YYYY'
        )} at ${moment(value.airspaceDate).format('HH:mm')} ${moment()
            .tz(tz)
            .format('z')}`;
        this.checkerTimeZoneAbbreviation = `${moment().tz(tz).format('z')}`;

        // Only run the check for an invalid form if not in edit mode
        if (this.formGroup.invalid && this.editingAirspaceParameters) {
            return;
        }
        this.airspaceCheckCommand$.next(airspaceCheckCommand);
    }

    /**
     * Check whether all required parameters for a valid airspace check are available and that a check is possible
     * Handles invalid/missing parameters by resetting the airspace check service and entering the correct error state
     */
    private tryAirspaceCheck(
        flightArea: GeoJSON.Polygon | GeoJSON.Point,
        partialCommand: AirspaceCheckCommandWithoutLocation
    ) {
        if (flightArea == null) {
            this.noValidArea();
            return;
        }
        this.hasFlightArea = true;

        // Next check that the current flight area has a valid jurisdiction that supports airspace checks
        const jurisdiction =
            this.airspaceCheckService.findJurisdictionForFlightArea(flightArea);

        if (
            jurisdiction == null ||
            jurisdiction.airspaceCheckSupport ===
                AirspaceJurisdictionDto.AirspaceCheckSupport.NONE
        ) {
            this.noValidJurisdiction();
            return;
        }
        const jurisdictionIdentifier = jurisdiction?.identifier;
        if (
            jurisdictionIdentifier == null
            // FIXME need to get valid jurisdictions
        ) {
            this.noValidJurisdiction();
            return;
        }

        if (
            jurisdiction.airspaceCheckSupport ===
            AirspaceJurisdictionDto.AirspaceCheckSupport.AUTHORISATION_CHECK
        ) {
            this.useSimplified = true;
        } else {
            this.useSimplified = false;
        }

        const ruleset =
            this.missionValues?.ruleset ??
            this.formGroup.controls.airspaceRuleset.value?.identifier;

        // If there's a valid location and jurisdiction with valid rulesets, do the airspace check
        const airspaceCheckCommand: AirspaceCheckCommand = {
            ...partialCommand,
            location: this.flightArea,
            jurisdiction: jurisdictionIdentifier,
            ruleset: partialCommand?.ruleset ?? ruleset
        };
        this.canCheckAirspace = true;

        this.airspaceCheckService.startSingleCheck(
            airspaceCheckCommand,
            null,
            jurisdiction,
            {
                id: null,
                name: 'Flight Area',
                availableActions: { canDelete: false, canEdit: false },
                type: 'MISSION',
                organisationId: this.organisationId,
                features: [
                    {
                        name: 'Flight Area',
                        type: 'FLIGHT_AREA',
                        geometry: this.flightArea
                    }
                ]
            }
        );
    }

    setTimeToNow() {
        this.formGroup.controls.airspaceDate.setValue(new Date());
        this.formGroup.controls.airspaceDate.markAsPristine();
        this.formGroup.updateValueAndValidity();

        // Update additional time fields
        const tz =
            this.checkerTimeZone ??
            this.formGroup.value.airspaceTimeZone ??
            this.timeZone ??
            null;

        const airspaceDate = moment(this.formGroup.controls.airspaceDate.value)
            .tz(tz)
            .toDate();

        this.checkerTime = `${moment(airspaceDate).format(
            'D MMM YYYY'
        )} at ${moment(airspaceDate).format('HH:mm')} ${moment()
            .tz(tz)
            .format('z')}`;
    }

    airspaceCheckClick() {
        if (
            this.toggleCheck &&
            this.airspaceStatus === 'IDLE' &&
            this.firstCheck
        ) {
            this.startDrawing();
            return;
        }

        if (
            this.canCheckAirspace &&
            this.airspaceStatus !== 'CHECKING' &&
            this.airspaceStatus !== 'IDLE'
        ) {
            this.showAirspaceChecker = !this.showAirspaceChecker;
        } else {
            this.showAirspaceChecker = false;
        }
    }

    startDrawing() {
        // Set the check time to now, but only if it hasn't been changed by the user
        if (!this.formGroup.controls.airspaceDate.dirty) {
            this.setTimeToNow();
        }
        this.isDrawing = true;
        this.awaitingFeature.next(this.isDrawing);
    }

    stopDrawing() {
        this.isDrawing = false;
        this.awaitingFeature.next(this.isDrawing);
    }

    private noValidArea() {
        this.hasFlightArea = false;
        this.resetCheckResults();
    }

    private noValidJurisdiction() {
        this.canCheckAirspace = false;
        this.showAirspaceChecker = false;
        this.resetCheckResults();
    }

    private resetCheckResults() {
        if (
            this.airspaceStatus !== 'INCOMPLETE' &&
            this.airspaceStatus !== 'IDLE' &&
            this.airspaceResultCounter > 0
        ) {
            this.airspaceCheckService.resetCheckResults();
        }
    }

    showAirspaceCheckDetails() {
        this.airspaceDialoguesService.showAirspaceCheckDetailsDialogue(
            this.airspaceCheckService
        );
    }
}
