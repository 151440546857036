import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
    transform<T>(array: T[], field: keyof T): T[] {
        if (!array) {
            return [];
        }
        return array.slice().sort((a, b) => {
            const aField = a[field] as unknown as string;
            const bField = b[field] as unknown as string;
            return aField.localeCompare(bField);
        });
    }
}
