import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-acknowledged-warning',
    template: `
        <div>
            <div class="alert alert-warning" role="alert">
                {{ to.message }}
                <button
                    type="button"
                    class="btn btn-xs btn-primary pull-right"
                    [disabled]="formControl.value === 'true'"
                    (click)="formControl.setValue('true')"
                >
                    {{
                        formControl.value === 'true'
                            ? 'Acknowledged'
                            : 'Acknowledge'
                    }}
                </button>
            </div>
        </div>
    `
})
export class FormlyAcknowledgedWarning extends FieldType {}
