import {
    Component,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Optional,
    Host,
    SkipSelf
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormGroup,
    FormArray
} from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, startWith, distinctUntilChanged } from 'rxjs/operators';

/**
 * Check if a control has a value.
 * @param control the control
 */
function hasValue(control: AbstractControl) {
    if (control instanceof FormArray) {
        return control.length > 0;
    }
    const value = control.value;

    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length > 0;
    }
    return control.value != null;
}

/**
 * This is a simple field validity indicator. It supports both reactive forms,
 * and manually toggling the props.
 */
@Component({
    selector: 'field-validation-indicator',
    templateUrl: './field-validation-indicator.component.html',
    styleUrls: ['./field-validation-indicator.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldValidationIndicator {
    /**
     * A direct reference to the control to be driven off
     * Having this as AbstractControl allows using the common properties shared between FromGroups, FormArrays and FormControls
     */
    @Input() control: AbstractControl;
    /**
     * Try to automatically locate the control using its name. If it is an empty string then the
     * enclosing form group is used.
     */
    @Input() validatorFormControlName: string;

    @Input() valid: boolean;
    @Input() required: boolean = true;
    @Input() inline: boolean = false;
    @Input() disabled = false;

    @Input() hasValue: boolean;

    @Input() placement: string = 'right';
    @Input() noun: string = 'field';

    tooltipLabel: string;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private changeDetector: ChangeDetectorRef,
        @Optional() @Host() @SkipSelf() private parent: ControlContainer
    ) {}

    ngOnInit() {
        if (this.control != null) {
            this.setupControlListener(this.control);
        } else if (this.validatorFormControlName != null) {
            const control =
                this.validatorFormControlName === ''
                    ? this.parent.control
                    : <AbstractControl>(
                          this.parent.control.get(this.validatorFormControlName)
                      );

            if (control == null) {
                throw new Error(
                    `No such control ${this.validatorFormControlName}`
                );
            }
            this.setupControlListener(control);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupControlListener(control: AbstractControl) {
        this.required = this.isRequired(control);
        // this.setStatus(control, control.status);

        combineLatest([
            control.statusChanges.pipe(
                distinctUntilChanged(),
                startWith(control.status)
            ),
            control.valueChanges.pipe(
                distinctUntilChanged(),
                startWith(control.value)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([status, value]) => {
                this.valid = status === 'VALID';
                this.disabled = status === 'DISABLED';
                this.hasValue = hasValue(control);
                this.changeDetector.detectChanges();
            });
    }

    isRequired(field: AbstractControl) {
        if (field.validator) {
            const validator = field.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return field instanceof FormGroup;
    }
}
