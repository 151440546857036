<enhanced-help componentName="mission-workflow-list" [compact]="true">
</enhanced-help>
<div class="container-scroll">
    <div class="horizontal-container">
        <button
            type="button"
            class="btn-circle btn-link"
            (click)="doCreateWorkflow()"
            [tooltip]="'Create new mission workflows'"
            [disabled]="working"
        >
            <span class="fa fa-plus"></span>
        </button>
        <div class="fill" *ngIf="workflows != null">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 30%">Name</th>
                        <th style="width: 20%">Authority Name</th>
                        <th style="width: 15%">Ruleset</th>
                        <th style="width: 15%">Jurisdiction</th>
                        <th>Status</th>
                        <th>
                            <div class="checkbox pull-right" style="margin: 0">
                                <div mdcFormField>
                                    <div mdcSwitch>
                                        <div mdcSwitchThumb>
                                            <input
                                                type="checkbox"
                                                mdcSwitchInput
                                                [(ngModel)]="showArchived"
                                                [ngModelOptions]="{
                                                    standalone: true
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <label mdcFormFieldLabel
                                        >Show archived mission workflows?</label
                                    >
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of workflows">
                        <tr *ngIf="showArchived || !item.archived" class="data">
                            <td>
                                <a (click)="doEditWorkflow(item)">{{
                                    item.name
                                }}</a>
                            </td>
                            <td>
                                {{ item.authorityName }}
                            </td>
                            <td>
                                {{ item.ruleset }}
                            </td>
                            <td>
                                {{ item.jurisdiction }}
                            </td>
                            <td>
                                <span
                                    class="status-block"
                                    [ngClass]="{
                                        active: item.status == 'ACTIVE',
                                        inactive:
                                            item.status == 'PENDING' ||
                                            item.status == 'EXPIRED' ||
                                            item.status == 'UNAVAILABLE' ||
                                            item.status == 'DISABLED'
                                    }"
                                >
                                    {{ displayableStatus(item.status) }}
                                </span>
                            </td>
                            <td class="actions">
                                <button
                                    *ngIf="!item.archived"
                                    type="button"
                                    class="btn btn-tertiary"
                                    (click)="archiveWorkflow(item)"
                                    [disabled]="working"
                                    tooltip="Archive"
                                    placement="left"
                                >
                                    <span class="fal fa-eye-slash"></span>
                                </button>

                                <button
                                    *ngIf="item.archived"
                                    type="button"
                                    class="btn btn-tertiary"
                                    (click)="unarchiveWorkflow(item)"
                                    [disabled]="working"
                                    tooltip="Unarchive"
                                    placement="left"
                                >
                                    <span class="fal fa-eye"></span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
