import { Component, Input } from '@angular/core';
import {
    AircraftRegisterEntryDetailsDto,
    capitaliseEnum,
    PersonnelRegisterEntryDetailsDto
} from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'register-status-display',
    template: `
        <span class="status-block" [ngClass]="status | registerStatusClass">
            {{ displayableStatus(status) }}
        </span>
    `
})
export class RegisterStatusDisplayComponent {
    @Input() status:
        | PersonnelRegisterEntryDetailsDto.Status
        | AircraftRegisterEntryDetailsDto.Status;

    rowPending(
        status:
            | PersonnelRegisterEntryDetailsDto.Status
            | AircraftRegisterEntryDetailsDto.Status
    ) {
        const s = PersonnelRegisterEntryDetailsDto.Status;
        return status !== s.ACTIVE && status !== s.NOT_ON_REGISTER;
    }

    displayableStatus(
        status:
            | PersonnelRegisterEntryDetailsDto.Status
            | AircraftRegisterEntryDetailsDto.Status
    ) {
        return capitaliseEnum(status);
    }
}
