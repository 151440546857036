import {
    Component,
    forwardRef,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import {
    MissionCrewDto,
    MissionDetailsDto,
    MissionRoleDto
} from '@flyfreely-portal-ui/flyfreely';
import {
    ControlValueAccessor,
    Validator,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import {
    MissionEditService,
    PersonWithRegisterStatus
} from '../mission-edit-v2/mission-edit.service';

export const MISSION_PILOT_ROLEV2_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MissionPilotRoleV2),
    multi: true
};

export const MISSION_PILOT_ROLEV2_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MissionPilotRoleV2),
    multi: true
};

@Component({
    selector: '[mission-pilot-role-v2]',
    templateUrl: './mission-pilot-role-v2.component.html',
    providers: [MISSION_PILOT_ROLEV2_ACCESSOR, MISSION_PILOT_ROLEV2_VALIDATOR]
})
export class MissionPilotRoleV2 implements ControlValueAccessor, Validator {
    @Input() pilots: PersonWithRegisterStatus[];
    @Input() roles: MissionRoleDto[];
    @Input() crewLookup: { [id: number]: string };
    @Input() disable: boolean;
    @Input() workflow: number;
    @Input() readonly: boolean;
    @Input() missionDetails: MissionDetailsDto;
    @Input() pilotRegisterDisabled: boolean = true;
    @Input() orgId: number;

    @Output() deleteCrew = new EventEmitter<void>();
    @Output() toggleShowDetails = new EventEmitter<void>();
    @Output() showPersonDetails = new EventEmitter<number>();

    private ngUnsubscribe$ = new Subject<void>();
    selectPersonnelRegisterStatus = new BehaviorSubject<string>('');
    showRpicRegisterDetails = false;

    isPilot = false;
    registerEntriesOnMissionLoading = false;
    isPilotRoleSelected = false;
    selectPerson = true;

    hasRpa = false;

    missionCrew: MissionCrewDto;
    private missionCrew$ = new Subject<MissionCrewDto>();
    onChange = Function.prototype;
    onTouched = Function.prototype;

    constructor(private missionEditService: MissionEditService) {}

    ngOnInit() {
        this.missionEditService.registerEntriesOnMissionLoading$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                loading => (this.registerEntriesOnMissionLoading = loading)
            );
        combineLatest([
            this.missionEditService.registerEntriesOnMission$,
            this.missionCrew$,
            this.missionEditService.missionForm.controls.craftIds.valueChanges.pipe(
                startWith(null as number[])
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([status, crew, rpaIds]) => {
                this.isPilot =
                    status.find(s => s.entityId === crew?.personId) != null;
                this.hasRpa = rpaIds != null && rpaIds.length > 0;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.missionCrew$.complete();
    }

    updateIfPilotRoleSelected() {
        const role = this.roles.find(
            r => r.id != null && r.id === this.missionCrew.missionRoleId
        );
        this.isPilotRoleSelected = role?.coreRole != null;
    }

    writeValue(value: any): void {
        this.missionCrew = value ?? { personId: null, missionRoleId: null };
        this.selectPerson = this.missionCrew.personId == null;
        this.missionCrew$.next(this.missionCrew);
        this.updateIfPilotRoleSelected();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(c: FormControl) {
        const isAllValid =
            this.missionCrew != null &&
            this.missionCrew.missionRoleId != null &&
            this.missionCrew.personId != null;
        return isAllValid
            ? null
            : {
                  length: {
                      valid: isAllValid
                  }
              };
    }

    onUpdate() {
        if (this.missionCrew.missionRoleId != null) {
            this.updateIfPilotRoleSelected();
        }
        // If any field updates, move the person to view mode
        if (this.missionCrew.personId != null) {
            this.selectPerson = false;
        }
        this.onChange(this.missionCrew);
    }

    deleteRow() {
        this.deleteCrew.emit();
    }

    getPilotRegisterStatus(personId: number) {
        return this.pilots?.find(p => p.id === personId)?.registerStatus;
    }

    toggleShowPilotRegisterDetails() {
        this.showRpicRegisterDetails = !this.showRpicRegisterDetails;
        this.toggleShowDetails.emit();
    }
}
