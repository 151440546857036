import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AuthorityRegisterSummaryDto,
    CheckExpiryDto,
    OrganisationAuthorityGroup,
    PersonnelRegisterEntryExpiryDto,
    RpaRegisterEntryExpiryDto
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import {
    AuthorityActionPayload,
    AuthorityExpirySummary,
    RegisterExpirySummary
} from '../../organisation-authority/organisation-authority-edit.service';

@Component({
    selector: 'authority-expiry-summary',
    templateUrl: './authority-expiry-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .alert-success {
                text-align: center;
                font-size: 2rem;
            }
            .alert {
                margin-bottom: 0;
            }

            :host {
                display: block;
                padding: 0 10px;
            }
        `
    ]
})
export class AuthorityExpirySummaryComponent {
    @Input() registerExpirySummaries: RegisterExpirySummary[];
    @Input() authorityGroups: OrganisationAuthorityGroup[];
    @Input() showArchived: boolean;

    @Output() onShowRegister = new EventEmitter<AuthorityActionPayload>();
    @Output() onShowAuthority = new EventEmitter<OrganisationAuthorityGroup>();

    filteredRegisterExpirySummaries: RegisterExpirySummary[];
    filteredAuthorityExpiries: AuthorityExpirySummary[];

    status: CheckExpiryDto.ExpiryStatus;

    currentlyEditingIndex: number;

    ngOnInit() {
        this.refreshAuthoritySummary();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('registerExpirySummaries' in changes || 'showArchived' in changes) {
            this.refreshAuthoritySummary();
            this.refreshSummary();
        }
    }

    private refreshAuthoritySummary() {
        this.filteredAuthorityExpiries = this.authorityGroups
            .filter(
                g =>
                    g.authorities?.length > 0 &&
                    // @ts-ignore - canEnableMission is not in the type at the moment, but is returned from the API and has been for a while
                    g.canEnableMission &&
                    g.authorities.filter(
                        a =>
                            a.expiryDate != null &&
                            (this.showArchived ||
                                (!a.archived && !g.discontinueDate))
                    ).length > 0
            )
            .reduce(
                (acc, g) =>
                    acc.concat(
                        g.authorities
                            ?.filter(
                                a =>
                                    a.expiryDate != null &&
                                    a.expiryStatus !== 'OK'
                            )
                            .map(a => ({
                                authorityName: g.name,
                                authorityGroupId: g.id,
                                authorityId: a.id,
                                overallStatus: a.expiryStatus
                            })) ?? []
                    ),
                []
            );
    }

    private refreshSummary() {
        this.filteredRegisterExpirySummaries = this.registerExpirySummaries.filter(
            r =>
                r.overallStatus !== CheckExpiryDto.ExpiryStatus.OK &&
                r.registerMode !==
                    AuthorityRegisterSummaryDto.RegisterMode.DISABLED
        );
        this.status =
            this.filteredRegisterExpirySummaries.length === 0
                ? CheckExpiryDto.ExpiryStatus.OK
                : CheckExpiryDto.ExpiryStatus.UPCOMING;
    }

    showHideExpiryDetails(index: number) {
        this.currentlyEditingIndex =
            this.currentlyEditingIndex == null ? index : null;
    }

    numberOfEntryUpcoming(
        entry: PersonnelRegisterEntryExpiryDto | RpaRegisterEntryExpiryDto
    ) {
        return entry.checkExpiryList.filter(
            e => e.expiryStatus === CheckExpiryDto.ExpiryStatus.UPCOMING
        ).length;
    }

    numberOfEntryExpired(
        entry: PersonnelRegisterEntryExpiryDto | RpaRegisterEntryExpiryDto
    ) {
        return entry.checkExpiryList.filter(
            e => e.expiryStatus === CheckExpiryDto.ExpiryStatus.EXPIRED
        ).length;
    }

    showRegister(summary: RegisterExpirySummary) {
        const authorityType = this.authorityGroups.find(
            a => a.id === summary.authorityId
        );
        const authority = authorityType?.authorities?.find(
            a => a.registers.find(r => r.id === summary.registerId) != null
        );
        const register = authority?.registers?.find(
            r => r.id === summary.registerId
        );

        this.onShowRegister.emit({
            authority,
            authorityType,
            register
        });
    }

    showAuthority(summary: AuthorityExpirySummary) {
        const authorityType = this.authorityGroups.find(
            g => g.id === summary.authorityGroupId
        );
        const authority = authorityType?.authorities?.find(
            a => a.id === summary.authorityId
        );

        this.onShowAuthority.emit(authorityType);
    }

    getExpiryDetailsText(
        entry: PersonnelRegisterEntryExpiryDto | RpaRegisterEntryExpiryDto
    ) {
        const today = moment().format('YYYY-MM-DD');
        const expiryText = moment(entry.expiryDate).isBefore(today)
            ? 'Expired on'
            : 'Expires';
        return `${this.numberOfEntryUpcoming(
            entry
        )} upcoming & ${this.numberOfEntryExpired(
            entry
        )} expired. ${expiryText} ${moment(entry.expiryDate).format(
            'DD/MM/YYYY'
        )}`;
    }
}
