import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AirspaceAuthorisationDto,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    OperationAuthorisation,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import {
    AirspaceCheckService,
    AirspaceCheckStatus
} from '../../airspace-check';
import { AirspaceAuthorisationManager } from '../airspace-authorisation-manager.service';
import {
    authorisationTypesWithMultipleAuthorisations,
    findLatestAirspaceAuthorisationInList,
    isAuthorisationRequired
} from '../helpers';
import { AirspaceAuthorisationState } from '../interfaces';

export function calculateCancelOrClose(
    authorisations: AirspaceAuthorisationDto[]
) {
    const status = calculateAuthorisationListState(authorisations);
    if (status === 'PENDING' || status === 'PROVISIONAL') {
        return 'CANCEL';
    } else if (status === 'APPROVED' || status === 'DECLARED') {
        return moment().isSameOrBefore(
            calculateAuthorisationStartTime(authorisations)
        )
            ? 'CANCEL'
            : 'CLOSE';
    } else {
        return 'NONE';
    }
}

function calculateAuthorisationStartTime(
    authorisations: AirspaceAuthorisationDto[]
) {
    return authorisations
        .map(authorisation => moment(authorisation.startTime))
        .reduce((previous, current) =>
            previous.isBefore(current) ? previous : current
        );
}

export function calculateAuthorisationListState(
    authorisationList: Array<OperationAuthorisation | AirspaceAuthorisationDto>
) {
    if (
        authorisationList == null ||
        authorisationList.length === 0 ||
        authorisationList.filter(a => a != null).length == 0
    ) {
        return 'UNKNOWN';
    }
    const statuses = authorisationList
        .filter(a => a != null)
        .map(authorisation => authorisation.status);
    const hasCompletedStatus = statuses.includes('COMPLETED');
    // @ts-ignore - type name exists on API
    const hasInvalidStatus = statuses.includes('INVALID');
    // @ts-ignore - type name exists on API
    const hasRescindedStatus = statuses.includes('RESCINDED');
    // @ts-ignore - type name exists on API
    const hasRescindedAwaitingStatus = statuses.includes('RESCINDED_AWAITING');
    const hasRejectedStatus = statuses.includes('REJECTED');
    const hasCancelledStatus = statuses.includes('CANCELLED');
    const hasPendingStatus = statuses.includes('PENDING');
    const hasProvisionalStatus = statuses.includes('PROVISIONAL');
    const hasApprovedStatus = statuses.includes('APPROVED');
    const hasDeclaredStatus = statuses.includes('DECLARED');
    const hasErrorStatus = statuses.includes('ERROR');
    return hasErrorStatus
        ? 'ERROR'
        : hasInvalidStatus
        ? 'INVALID'
        : hasRescindedAwaitingStatus
        ? 'RESCINDED_AWAITING'
        : hasRescindedStatus
        ? 'RESCINDED'
        : hasRejectedStatus
        ? 'REJECTED'
        : hasCancelledStatus
        ? 'CANCELLED'
        : hasPendingStatus
        ? 'PENDING'
        : hasProvisionalStatus
        ? 'PROVISIONAL'
        : hasDeclaredStatus
        ? 'DECLARED'
        : hasCompletedStatus
        ? 'CLOSED'
        : hasApprovedStatus
        ? 'APPROVED'
        : 'ALTERNATIVE';
}

/**
 * Calculate the state the component should be in.
 *
 * If there is an existing authorisation then use that.
 * Next, we defer to the checker status if it is checking or incomplete (can't run).
 *
 * @param airspaceCheckStatus
 * @param airspaceCheckResult
 * @param authorisations
 * @param airspaceAuthorisationsEnabled
 * @param areAuthorisationRequirementsComplete
 * @returns
 */
export function calculateAirspaceApprovalState(
    airspaceCheckStatus: AirspaceCheckStatus,
    airspaceCheckResult: AirspaceCheckDto | undefined,
    authorisations: AirspaceAuthorisationDto[],
    airspaceAuthorisationsEnabled: boolean,
    areAuthorisationRequirementsComplete: boolean
): AirspaceAuthorisationState {
    if (authorisations != null && authorisations.length > 0) {
        // FIXME: this is a hack. Authorisation status needs to be more reliably derived
        const status = calculateAuthorisationListState(authorisations);
        switch (status) {
            case 'DECLARED':
                return 'APPROVED';
            case null:
                return 'UNKNOWN';
            default:
                return status ?? 'UNKNOWN';
        }
    }

    if (airspaceCheckStatus === 'CHECKING') {
        return 'CHECKING';
    }

    if (airspaceCheckStatus === 'INCOMPLETE') {
        return 'ADVISE_INCOMPLETE';
    }

    if (!airspaceAuthorisationsEnabled) {
        switch (airspaceCheckStatus) {
            case 'CAN_FLY':
                return 'PASS';
            case 'CAN_FLY_CONDITIONS':
                return 'ADVISE_NOT_AVAILABLE';
            case 'CANNOT_FLY':
                return 'BLOCKED';
            default:
                return 'UNKNOWN';
        }
    }

    if (airspaceCheckResult?.automatedAuthorisationAvailable) {
        switch (airspaceCheckResult.flightStatus) {
            case 'CAN_FLY':
            case 'CAN_FLY_CONDITIONS':
                return areAuthorisationRequirementsComplete
                    ? 'AUTO_APPROVAL'
                    : 'ADVISE_INCOMPLETE';

            case 'CANNOT_FLY':
                return 'BLOCKED';

            default:
                return 'UNKNOWN';
        }
    } else {
        switch (airspaceCheckResult?.flightStatus) {
            case 'CAN_FLY':
                return 'PASS';

            case 'CAN_FLY_CONDITIONS':
                return 'ADVISE_NOT_AVAILABLE';

            case 'CANNOT_FLY':
                return 'BLOCKED';

            default:
                return 'UNKNOWN';
        }
    }

    return 'ADVISE_NOT_AVAILABLE'; // ???
}

@Component({
    selector: 'airspace-authorisation-status',
    templateUrl: './authorisation-status.component.html',
    styleUrls: ['./authorisation-status.component.scss']
})
export class AirspaceAuthorisationStatus {
    /**
     * These are the authorisation types that will be allowed to be requested, if available.
     */
    @Input()
    enabledAuthorisationTypes: AirspaceAuthorisationDto.AuthorisationType[] = [];
    /**
     * Does the mission have all the required information, such as workflow, RPA, and RP?
     */
    @Input() areAuthorisationRequirementsComplete: boolean = false;
    /**
     * The jurisdiction where the check has taken place. Maybe one day get this
     * from the airspace checker service.
     */
    @Input() jurisdiction: AirspaceJurisdictionDto;
    /**
     * A specific check to see if we have a ruleset/workflow for better messaging.
     */
    @Input() hasRuleset: boolean;
    /**
     * Disabled state
     */
    @Input() disabled: boolean;
    /**
     * Is this a dummy mission, in which case you can not submit an authorisation.
     */
    @Input() isDummy: boolean;
    @Input() isMissionDetailsView: boolean = false;
    @Input() userCanRequestAirspaceAuthorisation: boolean;
    @Input() userCanViewAirspaceAuthorisation: boolean;
    @Input() isAdditionalAuthorityApproval: boolean = false;
    @Output() showAirspaceAuthorisation = new EventEmitter<void>();
    @Output() requestNewAirspaceAuthorisation = new EventEmitter<void>();
    @Output() cancelAirspaceAuthorisation =
        new EventEmitter<AirspaceAuthorisationDto>();
    @Output() closeAirspaceAuthorisation =
        new EventEmitter<AirspaceAuthorisationDto>();

    private ngUnsubscribe$ = new Subject<void>();

    approvedAirspaceAuthorisations: AirspaceAuthorisationDto[];

    checkingAuthorisation: boolean = false;

    cancelOrClose: 'CANCEL' | 'CLOSE' | 'NONE';
    canRequestNew = false;
    airspaceApprovalState: AirspaceAuthorisationState = 'UNKNOWN';
    approvedAirspaceAuthorisations$ = new BehaviorSubject<
        AirspaceAuthorisationDto[]
    >(undefined);
    isOptional = false;
    airspaceCheckCanFly: boolean;
    availableAuthorisationType: AirspaceAuthorisationDto.AuthorisationType;

    jurisdiction$ = new BehaviorSubject<AirspaceJurisdictionDto | undefined>(
        undefined
    );

    userPermissionsUpdated$ = new Subject<void>();

    areAuthorisationRequirementsComplete$ = new BehaviorSubject<boolean>(false);

    constructor(
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        public airspaceCheckService: AirspaceCheckService,
        public workTracker: WorkTracker
    ) {}

    ngOnInit() {
        this.setupAirspaceCheckListeners();
        this.airspaceAuthorisationManager.missionAirspaceAuthorisations$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorisations => {
                this.approvedAirspaceAuthorisations =
                    authorisations != null && authorisations.length > 0
                        ? authorisationTypesWithMultipleAuthorisations.includes(
                              authorisations[0].authorisationType
                          )
                            ? authorisations
                            : [
                                  findLatestAirspaceAuthorisationInList(
                                      authorisations
                                  )
                              ]
                        : authorisations;
                this.updateApprovedAuthorisations(
                    this.approvedAirspaceAuthorisations
                );
            });
        this.airspaceAuthorisationManager.checkingAuthorisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(checking => {
                this.checkingAuthorisation = checking;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        // if ('approvedAirspaceAuthorisations' in changes) {
        //     this.updateApprovedAuthorisations(
        //         this.approvedAirspaceAuthorisations
        //     );
        // }

        if ('jurisdiction' in changes) {
            this.jurisdiction$.next(this.jurisdiction);
        }
        if ('areAuthorisationRequirementsComplete' in changes) {
            this.areAuthorisationRequirementsComplete$.next(
                this.areAuthorisationRequirementsComplete
            );
        }
        if (
            'userCanRequestAirspaceAuthorisation' in changes ||
            'userCanViewAirspaceAuthorisation' in changes
        ) {
            this.userPermissionsUpdated$.next();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    updateApprovedAuthorisations(authorisations: AirspaceAuthorisationDto[]) {
        this.approvedAirspaceAuthorisations$.next(authorisations);
        this.cancelOrClose = calculateCancelOrClose(authorisations);
        // FIXME: this needs to be better derived
        this.canRequestNew =
            this.availableAuthorisationType !==
            AirspaceAuthorisationDto.AuthorisationType.USA_LAANC;
    }

    private setupAirspaceCheckListeners() {
        this.airspaceCheckService.operationFailed$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                if (result === true) {
                    this.airspaceApprovalState = 'OPERATION_FAILED';
                }
            });

        combineLatest([
            this.airspaceCheckService.resultStatus$,
            this.airspaceCheckService.result$.pipe(startWith(undefined)),
            this.jurisdiction$,
            this.areAuthorisationRequirementsComplete$,
            this.approvedAirspaceAuthorisations$,
            this.userPermissionsUpdated$.pipe(startWith(null))
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    status,
                    result,
                    jurisdiction,
                    areAuthorisationRequirementsComplete,
                    approvedAirspaceAuthorisations,
                    statusUpdated
                ]) => {
                    const authorisationSupport =
                        jurisdiction?.airspaceCheckSupport;
                    const airspaceAuthorisationsEnabled =
                        !this.isAdditionalAuthorityApproval &&
                        authorisationSupport != null &&
                        authorisationSupport !==
                            AirspaceJurisdictionDto.AirspaceCheckSupport.NONE;

                    const availableAuthorisationList =
                        (result?.availableAutomatedAuthorisationList != null &&
                            result?.availableAutomatedAuthorisationList.length >
                                0) ||
                        approvedAirspaceAuthorisations != null
                            ? (result?.availableAutomatedAuthorisationList as AirspaceAuthorisationDto.AuthorisationType[]) ?? [
                                  approvedAirspaceAuthorisations[0]
                                      ?.authorisationType
                              ]
                            : [];
                    this.availableAuthorisationType =
                        this.enabledAuthorisationTypes.find(at =>
                            availableAuthorisationList.includes(at)
                        );

                    this.airspaceApprovalState = calculateAirspaceApprovalState(
                        status,
                        result,
                        this.userCanViewAirspaceAuthorisation
                            ? approvedAirspaceAuthorisations
                            : [],
                        airspaceAuthorisationsEnabled,
                        areAuthorisationRequirementsComplete
                    );

                    this.isOptional =
                        result != null
                            ? !isAuthorisationRequired(result)
                            : false;

                    this.airspaceCheckCanFly =
                        status === 'CAN_FLY' || status === 'CAN_FLY_CONDITIONS';
                }
            );
    }

    showAirspaceAuthorisations() {
        this.showAirspaceAuthorisation.emit();
    }

    cancelAuthorisation() {
        if (this.approvedAirspaceAuthorisations.length > 1) {
            // Cancelling multiple authorisations happens from within the authorisation view
            this.showAirspaceAuthorisations();
        } else {
            this.cancelAirspaceAuthorisation.emit(
                this.approvedAirspaceAuthorisations[0]
            );
        }
    }

    closeAuthorisation() {
        if (this.approvedAirspaceAuthorisations.length > 1) {
            // Closing multiple authorisations happens from within the authorisation view
            this.showAirspaceAuthorisations();
        } else {
            this.closeAirspaceAuthorisation.emit(
                this.approvedAirspaceAuthorisations[0]
            );
        }
    }
}
