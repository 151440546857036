<div id="right-layer">
    <div class="horizontal-container">
        <div class="fill">
            <div
                class="btn-group btn-group-toggle btn-group-justified"
                btnRadioGroup
                [(ngModel)]="featureList"
            >
                <label
                    *ngIf="features != null"
                    ngbButtonLabel
                    class="btn btn-default"
                    btnRadio="MARKERS"
                    (click)="manageLayerFilter()"
                >
                    {{ featuresTitle }}
                </label>
                <label
                    ngbButtonLabel
                    class="btn btn-default"
                    btnRadio="LAYERS"
                    (click)="manageLayerFilter()"
                >
                    Layers
                </label>
            </div>
        </div>
    </div>
    <div
        id="collapseBasic"
        class="tree-container border mt-3 p-2"
        [collapse]="isCollapsed"
        [isAnimated]="true"
    >
        <div class="container-scroll">
            <tree-root
                [hidden]="features == null || featureList !== 'MARKERS'"
                #featureTree
                [options]="options"
                [nodes]="featureNodes"
                (activate)="activateTree($event)"
                (focus)="focusTree($event)"
                (blur)="blurTree($event)"
                (select)="onSelect($event)"
                (deselect)="onDeselect($event)"
                (updateData)="onUpdateTree($event)"
            >
                <ng-template #treeNodeTemplate let-node let-index="index">
                    <span>{{ node.data.name }}</span>
                    <span
                        *ngIf="node.data.properties?.color != null"
                        class="fas fa-square left-buffer"
                        [ngStyle]="{ color: node.data.properties.color }"
                    ></span>
                </ng-template>
            </tree-root>

            <tree-root
                [hidden]="featureList !== 'LAYERS'"
                #layerTree
                [options]="options"
                [nodes]="layerNodes"
                (activate)="activateTree($event)"
                (focus)="focusTree($event)"
                (blur)="blurTree($event)"
                (select)="onSelect($event)"
                (deselect)="onDeselect($event)"
                (updateData)="onUpdateTree($event)"
            >
                <ng-template #treeNodeTemplate let-node let-index="index">
                    <span>{{ node.data.name }}</span>
                </ng-template>
            </tree-root>
        </div>
    </div>
</div>
