import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AttachmentDto,
    AttachmentHandler,
    CurrentAttachmentVersionDto,
    FlyFreelyError,
    FlyFreelyLoggingService
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'attachment-history',
    templateUrl: './attachment-history.component.html'
})
export class AttachmentHistory implements OnInit {
    @Input() attachment: CurrentAttachmentVersionDto;
    @Input() attachmentsHandler: AttachmentHandler;
    @Input() organisationId: number;
    @Input() canDownload = false;

    attachmentHistory: AttachmentDto;

    @Output()
    cancel = new EventEmitter<void>();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.findAttachmentHistory();
    }

    findAttachmentHistory() {
        this.attachmentsHandler
            .findAttachmentHistory(this.attachment.id, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                attachment => {
                    this.attachmentHistory = attachment;
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while getting version history: ${error.message}`
                    )
            );
    }

    close() {
        this.modal.hide();
    }
}
