<div class="modal-header">
    <div class="panel-heading-icon">
        <span class="fa fa-exclamation"></span>
    </div>
    <h4 class="modal-title">Not optimised for mobile devices</h4>
</div>
<div class="modal-body">
    <p>
        You are about to enter the FlyFreely Office App, which is currently
        optimised for a desktop or laptop experience.
        <br /><br />
        If you are using a tablet, please make sure to use it in landscape mode.
        <br /><br />
        Using the Office App on mobile devices will mean a limited and
        unoptimised experience.
    </p>
    <img
        class="warning-image"
        imgPath="/onboarding/mobile-assets/Not-Optimised-warning-graphic.png"
    />
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">OK</button>
</div>
