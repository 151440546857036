import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { GraphqlSubscriptionStateService } from './graphql-subscription-state.service';

/**
 * A standalone module to initialise Apollo for use with both http queries and websocket subscriptions.
 * Initialising this way also allows one location for all the common Apollo configuration.
 * Each app that utilises Graph QL can import this module in the app module in place of having an Apollo options factory function.
 */
@NgModule({
    imports: [ApolloModule],
    providers: [
        GraphqlSubscriptionStateService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: () => () => null,
            deps: [GraphqlSubscriptionStateService]
        }
    ],
    exports: [ApolloModule]
})
export class GraphQlModule {
}
