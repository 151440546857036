<ng-container screenAnalytics="maintenance-schedule">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-maintenance.png" />
        </div>
        <h3 class="modal-title">Maintenance Schedule</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
            data-cy="maintenanceDetailsCloseBtn"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form (ngSubmit)="saveAndClose()" [formGroup]="formGroup">
        <div class="modal-body">
            <enhanced-help componentName="screen"></enhanced-help>
            <div class="row" formGroupName="resourceType">
                <div class="col-xs-12 col-md-6 form-group">
                    <label>Resource Category</label>
                    <div
                        *ngIf="isNew === 'NEW'"
                        class="input-group"
                        btnRadioGroup
                        formControlName="resourceCategory"
                        style="padding-top: 7px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            btnRadio="CRAFT"
                            [disabled]="working"
                            >RPA</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            btnRadio="BATTERY"
                            [disabled]="working"
                            >Battery</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            btnRadio="EQUIPMENT"
                            [disabled]="working"
                            >Equipment</label
                        >
                    </div>
                    <div *ngIf="isNew !== 'NEW'">
                        {{
                            maintenanceScheduleTypes[
                                resourceTypeFormGroup.controls.resourceCategory
                                    .value
                            ]
                        }}
                    </div>
                </div>

                <div
                    *ngIf="
                        resourceTypeFormGroup.controls.resourceCategory
                            .value !== 'BATTERY'
                    "
                    class="col-xs-12 col-md-6 form-group"
                >
                    <label
                        >{{
                            maintenanceScheduleTypes[
                                resourceTypeFormGroup.controls.resourceCategory
                                    .value
                            ]
                        }}
                        Category</label
                    >
                    <div>
                        <ng-container
                            *ngIf="subCategoryList$ | async as subCategoryList"
                        >
                            <ng-select
                                *ngIf="
                                    subCategoryList != null && isNew === 'NEW'
                                "
                                [items]="subCategoryList"
                                formControlName="subCategory"
                                bindLabel="name"
                                bindValue="value"
                            ></ng-select>
                            <span *ngIf="isNew !== 'NEW'">{{
                                resourceTypeFormGroup.controls.subCategory.value
                            }}</span>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div *ngIf="isNew === 'NEW'" class="row">
                <div class="col-xs-12">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm pull-right"
                        [disabled]="resourceTypeFormGroup.invalid"
                        (click)="confirmCategory()"
                    >
                        Next
                    </button>
                </div>
            </div>
            <ng-container *ngIf="isNew !== 'NEW'">
                <div class="row">
                    <div class="col-xs-12 col-md-6 form-group">
                        <label>Name</label>
                        <editable
                            *ngIf="isNew === 'SAVED'"
                            [confirmationButtons]="true"
                            [cancelOnClickOutside]="false"
                            [initialContext]="{
                                name: formGroup.controls.name.value
                            }"
                            [updateFn]="updateName"
                        >
                            <ng-template viewMode>
                                {{ formGroup.controls.name.value }}
                            </ng-template>
                            <ng-template
                                editMode
                                let-context="context"
                                let-state="state"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="context.name"
                                    [ngModelOptions]="{ standalone: true }"
                                    editableOnEnter
                                    [readonly]="state.saving"
                                />
                                <div
                                    class="text-danger"
                                    *ngIf="state.error != null"
                                >
                                    {{ state.error }}
                                </div>
                            </ng-template>
                        </editable>
                        <input
                            *ngIf="isNew === 'CATEGORY'"
                            type="text"
                            class="form-control"
                            formControlName="name"
                        />
                    </div>

                    <div class="col-xs-12 col-md-6 form-group">
                        <!-- <label>Version</label>
                    <div class="input-group">
                        <ng-container *ngIf="!isNew">
                            <ng-select
                                name="maintenanceScheduleVersion"
                                placeholder="New Version"
                                [formControl]="versionSelector"
                                bindValue="id"
                                [items]="versions"
                                [clearable]="false"
                                appendTo="body"
                            >
                                <ng-template
                                    ng-option-tmp
                                    let-item="item"
                                    let-index="index"
                                >
                                    <span>
                                        Version {{ item.versionNumber }}
                                        {{
                                            activeVersionId == item.id
                                                ? '(Active)'
                                                : ''
                                        }}
                                    </span>
                                </ng-template>
                                <ng-template
                                    ng-label-tmp
                                    let-item="item"
                                    let-index="index"
                                >
                                    <span>
                                        Version {{ item.versionNumber }}
                                        {{
                                            activeVersionId == item.id
                                                ? '(Active)'
                                                : ''
                                        }}
                                    </span>
                                </ng-template>
                            </ng-select>

                            <span
                                *ngIf="
                                    currentVersion != null
                                "
                                class="input-group-btn"
                            >
                                <button
                                    type="button"
                                    class="btn-tertiary btn-sm"
                                    (click)="cloneVersion()"
                                    [disabled]="
                                        currentVersion.id == null ||
                                        !schedule?.availableActions?.canEdit
                                    "
                                    tooltip="Clone this version"
                                >
                                    <i class="fa-clone fal"></i>
                                </button>

                                <button
                                    type="button"
                                    class="btn-tertiary btn-sm"
                                    tooltip="Make this the active version"
                                    (click)="toggleActiveVersion()"
                                    [disabled]="
                                        !currentVersion.id ||
                                        !schedule?.availableActions?.canEdit
                                    "
                                >
                                    <span
                                        class="fal fa-star"
                                        *ngIf="
                                            currentVersion.id !== activeVersionId
                                        "
                                    >
                                    </span>
                                    <span
                                        class="fa fa-star"
                                        *ngIf="
                                            currentVersion.id === activeVersionId
                                        "
                                    >
                                    </span>
                                </button>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="isNew"
                            ><div>New</div></ng-container
                        >
                    </div> -->
                    </div>
                </div>
                <!-- <div class="row">
                <div class="col-xs-12">
                    
                </div>
            </div> -->
                <div class="row">
                    <div class="col-xs-12">
                        <h4 class="section-heading">
                            <span>Component Maintenance Schedule</span>
                            <div></div>
                        </h4>
                        <enhanced-help
                            componentName="schedule-tasks"
                        ></enhanced-help>
                        <maintenance-schedule-tasks
                            formControlName="taskList"
                        ></maintenance-schedule-tasks>
                    </div>
                </div>
                <!-- <div class="row">
                <div class="col-xs-12">
                    <h4 class="section-heading">
                        <span>On Condition Workflow</span>
                        <div></div>
                    </h4>
                    <workflow-step-details
                        *ngIf="status === 'LOADED'"
                        [disabled]="!currentVersion.availableActions.canEdit"
                        [steps]="steps"
                        [mappedForms]="currentVersion.mappedForms"
                        [mappedAttachmentRequirements]="
                            currentVersion.mappedAttachmentRequirements
                        "
                        [forms]="forms$ | async"
                        [organisationId]="organisationId"
                        [hasApproverChecks]="false"
                    ></workflow-step-details>
                </div>
            </div> -->
                <div class="row">
                    <div class="col-xs-12">
                        <h4 class="section-heading">
                            <span>Maintenance Schedule Triggers</span>
                            <div></div>
                        </h4>
                        <enhanced-help
                            componentName="schedule-triggers"
                        ></enhanced-help>
                        <maintenance-schedule-triggers
                            formControlName="triggerList"
                        ></maintenance-schedule-triggers>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="modal-footer">
            <button
                class="btn btn-default"
                type="button"
                (click)="save()"
                [disabled]="working || formGroup.pristine || isNew === 'NEW'"
            >
                Save
            </button>
            <button
                class="btn btn-primary"
                [disabled]="working || formGroup.pristine || isNew === 'NEW'"
            >
                Save and Close
            </button>
        </div>
    </form>
    <ngx-loading [show]="working || status === 'LOADING'"></ngx-loading>
</ng-container>
