import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { AirworthinessNoticesService } from 'libs/flyfreely/src/lib/services/airworthnessNotices.service';
import {
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    Observable,
    Subject,
    BehaviorSubject,
    combineLatest,
    firstValueFrom
} from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
    AirworthinessNoticeApplicability,
    AirworthnessNoticesDto,
    getApplicabilityDisplayValue,
    getTypeDisplayValue
} from 'libs/flyfreely/src/lib/model/api/airworthnessNoticeDto';
import { MaintenanceDialogues } from '../maintenance-dialogues.service';
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { AirworthinessNoticesDialogueService } from './airworthness-notices.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';

// type GroupedNotices = Record<AirworthinessNoticeApplicability, AirworthnessNoticesDto[]>;
type GroupedNotices = {
    COMPLETED: AirworthnessNoticesDto[];
    APPLICABLE: AirworthnessNoticesDto[];
    NOT_APPLICABLE: AirworthnessNoticesDto[];
    UNKNOWN: AirworthnessNoticesDto[];
};

@Component({
    selector: 'airworthness-notices-dialogue',
    templateUrl: './airworthness-notices-dialogue.component.html',
    styleUrls: ['./airworthness-notices-dialogue.component.scss'],
    animations: [
        trigger('expandCollapse', [
            state(
                'collapsed',
                style({ height: '0px', overflow: 'hidden', opacity: 0 })
            ),
            state('expanded', style({ height: '*', opacity: 1 })),
            transition('collapsed <=> expanded', animate('300ms ease-in-out'))
        ])
    ]
})
export class AirworthnessNoticesDialogue implements OnInit, OnDestroy {
    expandedGroups: { [key: string]: boolean } = {};
    searchMode: boolean = false;
    notices$: Observable<GroupedNotices>;
    hasNotices: boolean = false;
    filteredNotices$: Observable<GroupedNotices>;
    expandedCardIds: Set<number> = new Set<number>();
    selectedCardId: number | null = null;
    selectedGroup: AirworthinessNoticeApplicability | null = null;
    selectedCardId$: Observable<number | null>;
    private selectedNoticeStatus$ = new BehaviorSubject<string>(''); // Track selected notice status
    selectedNoticeStatusObservable$: Observable<string> =
        this.selectedNoticeStatus$.asObservable(); // Expose as Observable
    currentNoticeStatus: string = ''; // New property to hold the current notice status

    @ViewChild('noticesContainer', { static: false })
    scrollContainer: ElementRef;

    @Input() organisationId: number;
    searchTerm: string = '';
    private originalNotices: GroupedNotices = {} as GroupedNotices;
    private searchTermSubject = new BehaviorSubject<string>('');

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    updateSelectedNoticeStatus(newStatus: string) {
        this.selectedNoticeStatus$.next(newStatus); // Change the status in the subject
        this.currentNoticeStatus = newStatus;
    }

    constructor(
        private modal: BsModalRef,
        private maintenanceDialogues: MaintenanceDialogues,
        private airworthinessNoticesService: AirworthinessNoticesService,
        private AirworthinessNoticesDialogueService: AirworthinessNoticesDialogueService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.selectedCardId$ =
            this.AirworthinessNoticesDialogueService.getSelectedCardId();
    }

    ngOnInit() {
        this.AirworthinessNoticesDialogueService.setOrganisationId(
            this.organisationId
        );
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.notices$ = this.airworthinessNoticesService
            .find(this.organisationId)
            .pipe(
                tap(notices =>
                    notices.length > 0
                        ? (this.hasNotices = true)
                        : (this.hasNotices = false)
                ),
                map(notices => this.groupByApplicability(notices)),
                tap(groupedNotices => {
                    this.originalNotices = groupedNotices;
                    this.searchTermSubject.next('');
                    this.setDefaultExpansionState(groupedNotices);
                })
            );

        this.filteredNotices$ = combineLatest([
            this.notices$,
            this.searchTermSubject
        ]).pipe(
            map(([notices, searchTerm]) =>
                this.filterNotices(this.originalNotices, searchTerm)
            )
        );
        // Subscribe to notice creation status changes
        this.AirworthinessNoticesDialogueService.noticeCreationStatus$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                tap(status => {
                    if (status === true) {
                        this.loadNotices(); // Refresh the notices when status is true
                    }
                })
            )
            .subscribe();

        this.AirworthinessNoticesDialogueService.expandedGroup$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                tap(selected => {
                    this.selectedGroup = selected;
                })
            )
            .subscribe();

        this.notices$.subscribe().add(this.workTracker.createTracker());
        this.AirworthinessNoticesDialogueService.updateMaintenanceLogs(
            this.organisationId
        );
    }

    // Load notices
    private loadNotices() {
        this.notices$ = this.airworthinessNoticesService
            .find(this.organisationId)
            .pipe(
                map(notices => this.groupByApplicability(notices)),
                tap(groupedNotices => {
                    this.originalNotices = groupedNotices;
                    this.searchTermSubject.next('');
                    this.setDefaultExpansionState(groupedNotices);
                    // Reapply expanded group and selected card
                    if (this.selectedGroup) {
                        this.toggleGroup(this.selectedGroup); // Ensure the selected group is expanded
                    }
                    if (this.selectedCardId) {
                        this.expandedCardIds.add(this.selectedCardId); // Re-select the card
                    }
                })
            );

        this.filteredNotices$ = combineLatest([
            this.notices$,
            this.searchTermSubject
        ]).pipe(
            map(([notices, searchTerm]) =>
                this.filterNotices(notices, searchTerm)
            ),
            tap(() => {
                // Reapply expansion and selection
                if (this.selectedGroup) {
                    this.toggleGroup(this.selectedGroup);
                }
                if (this.selectedCardId) {
                    this.expandedCardIds.add(this.selectedCardId);
                }
            })
        );
    }

    // Group by applicability and status
    private groupByApplicability(
        notices: AirworthnessNoticesDto[]
    ): GroupedNotices {
        const groupedNotices: GroupedNotices = {
            COMPLETED: [],
            APPLICABLE: [],
            NOT_APPLICABLE: [],
            UNKNOWN: []
        };

        notices.forEach(notice => {
            if (notice.status === 'COMPLETED') {
                groupedNotices.COMPLETED.push(notice); // Add to COMPLETED group
            } else {
                // Incomplete notices go under their respective applicability group
                switch (notice.applicability) {
                    case 'APPLICABLE':
                        groupedNotices.APPLICABLE.push(notice);
                        break;
                    case 'NOT_APPLICABLE':
                        groupedNotices.NOT_APPLICABLE.push(notice);
                        break;
                    case 'UNKNOWN':
                        groupedNotices.UNKNOWN.push(notice);
                        break;
                    default:
                        break;
                }
            }
        });

        // Sort notices within each group by their IDs in descending order
        Object.keys(groupedNotices).forEach(key => {
            groupedNotices[key].sort((a, b) => b.id - a.id);
        });

        return groupedNotices;
    }

    private setDefaultExpansionState(groupedNotices: GroupedNotices) {
        Object.keys(groupedNotices).forEach(key => {
            if (this.selectedGroup && key === this.selectedGroup) {
                this.expandedGroups[key] = true; // Keep the selected group expanded
            } else if (!this.selectedGroup && key === 'APPLICABLE') {
                this.expandedGroups[key] = true; // Default to Applicable if no selection
            } else {
                this.expandedGroups[key] = false; // Collapse other groups, including Completed
            }
        });
    }

    private filterNotices(
        notices: GroupedNotices,
        searchTerm: string
    ): GroupedNotices {
        if (!searchTerm) {
            return notices;
        }

        const filteredNotices: GroupedNotices = {
            COMPLETED: [],
            APPLICABLE: [],
            NOT_APPLICABLE: [],
            UNKNOWN: []
        };

        Object.keys(notices).forEach(key => {
            const filtered = notices[key as keyof GroupedNotices].filter(
                notice =>
                    Object.values(notice).some(
                        value =>
                            typeof value === 'string' &&
                            value
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                    )
            );
            if (filtered.length) {
                filteredNotices[key as keyof GroupedNotices] = filtered;
            }
        });

        return filteredNotices;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    cancel() {
        this.modal.hide();
        this.AirworthinessNoticesDialogueService.clearMaintenanceNoticeLogs();
    }

    toggleExpandCollapse(id: number) {
        if (this.expandedCardIds.has(id)) {
            this.expandedCardIds.delete(id);
        } else {
            this.expandedCardIds.add(id);
        }
    }

    addNotice() {
        this.AirworthinessNoticesDialogueService.setAirworthinessNoticeId(null);
        this.maintenanceDialogues.showEditAirworthnessNotice(
            this.organisationId
        );
    }
    createBulkMaintenance() {
        this.AirworthinessNoticesDialogueService.setSelectedCardId(null);
        this.maintenanceDialogues.showBulkMaintenanceDetails(
            this.organisationId
        );
    }

    linkRequest() {
        this.maintenanceDialogues.showLinkmaintenanceRequest(
            this.organisationId
        );
    }

    searchModeShow() {
        this.searchMode = !this.searchMode;
        if (!this.searchMode) {
            this.searchTerm = ''; // Clear search term when search mode is disabled
            this.searchTermSubject.next('');
        }
    }

    isExpanded(id: number): boolean {
        return this.expandedCardIds.has(id);
    }

    toggleGroup(key: AirworthinessNoticeApplicability): void {
        if (this.searchMode) {
            return;
        }
        this.AirworthinessNoticesDialogueService.setExpandedGroup(key);

        // Only toggle the current group, don't collapse others
        this.expandedGroups[key] = !this.expandedGroups[key];
    }

    isGroupExpanded(key: string): boolean {
        if (this.searchMode) {
            return true;
        }
        return !!this.expandedGroups[key];
    }

    getApplicabilityDisplay(value: string): string {
        return getApplicabilityDisplayValue(value);
    }

    getTypeDisplay(value: string): string {
        return getTypeDisplayValue(value);
    }

    searchNotices() {
        this.searchTermSubject.next(this.searchTerm);
    }

    clearSearch() {
        this.searchTerm = '';
        this.searchTermSubject.next('');
    }

    onNoticeSelected(noticeId: number) {
        this.AirworthinessNoticesDialogueService.setAirworthinessNoticeId(
            noticeId
        );
        this.AirworthinessNoticesDialogueService.setOrganisationId(
            this.organisationId
        );
        this.AirworthinessNoticesDialogueService.updateLinkedMaintenanceLogs();
        // this.AirworthinessNoticesDialogueService.setExpandedGroup(this.selectedGroup)

        const selectedNotice = this.findNoticeById(noticeId);
        if (selectedNotice) {
            this.updateSelectedNoticeStatus(selectedNotice.status); // Update the BehaviorSubject and current status
        }
    }

    onSelectionChange(cardId: number): void {
        const currentSelectedId =
            this.AirworthinessNoticesDialogueService.selectedCardIdValue;

        // If the clicked card is not the currently selected one, select it.
        if (currentSelectedId !== cardId) {
            this.AirworthinessNoticesDialogueService.setSelectedCardId(cardId);
        }
        // No else block needed because we don't want to deselect if the user clicks the already selected card.
    }

    isAnyCardSelected(): boolean {
        return (
            this.AirworthinessNoticesDialogueService.selectedCardIdValue !==
            null
        );
    }

    onEditNotice(noticeId: number) {
        const selectedNotice = this.findNoticeById(noticeId);
        if (selectedNotice) {
            this.maintenanceDialogues.showEditAirworthnessNotice(
                this.organisationId,
                selectedNotice
            );
            this.AirworthinessNoticesDialogueService.setSelectedCardId(
                noticeId
            );
            this.AirworthinessNoticesDialogueService.setExpandedGroup(
                selectedNotice.applicability
            );
        }
    }

    // Function to confirm status change (UPDATED for INCOMPLETE/COMPLETED status)
    confirmStatusChange() {
        const cardId =
            this.AirworthinessNoticesDialogueService.selectedCardIdValue;
        const notice = this.findNoticeById(cardId!);
        // console.log(' this is the confirmStatusChange method', cardId, notice);

        if (notice == null) {
            return;
        }

        const newStatus = this.currentNoticeStatus;
        const checkStatus =
            newStatus === 'COMPLETED' ? 'INCOMPLETE' : 'COMPLETED';
        const message = `Are you sure you want to mark this Airworthiness Notice as ${checkStatus}?`;

        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Change',
                message,
                'Confirm',
                () =>
                    firstValueFrom(
                        this.changeStatus(cardId, newStatus).pipe(
                            tap({
                                error: e => this.logging.error(e)
                            })
                        )
                    )
            )
            .then(() => this.loadNotices());
    }

    // Use the new service method for completing a notice
    private changeStatus(cardId: number, newStatus: string): Observable<any> {
        // console.log('this is the new method to complete a notice', newStatus );
        if (newStatus === 'COMPLETED') {
            return this.airworthinessNoticesService.uncompleteStatus(cardId!);
        } else {
            return this.airworthinessNoticesService.completeStatus(cardId!);
        }
    }

    findNoticeById(noticeId: number): AirworthnessNoticesDto | undefined {
        for (const group of Object.values(this.originalNotices)) {
            const foundNotice = group.find(notice => notice.id === noticeId);
            if (foundNotice) {
                return foundNotice;
            }
        }
        return undefined;
    }
}
