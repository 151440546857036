<div class="modal-header">
    <div class="panel-heading-icon">
        <img imgPath="/icons/icon-personnel.png" />
    </div>
    <h3 class="modal-title">Personnel Reports</h3>
    <button type="button" (click)="cancel()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <tabset>
        <tab [heading]="'Personnel List'">
            <div class="modal-body">
                <p>
                    Download a CSV report of all current personnel in this
                    organisation as listed in the personnel widget. Select an
                    authority below to add columns for that authority to the
                    report.
                </p>
                <div class="checkbox top-buffer bottom-buffer">
                    <label>
                        <input
                            type="checkbox"
                            name="includeRoles"
                            [(ngModel)]="includeRoles"
                            [disabled]="working"
                        />
                        <span class="cr"
                            ><i class="cr-icon glyphicon glyphicon-ok"></i>
                        </span>
                        Include roles
                    </label>
                </div>
                <label>Select authorities (optional)</label>
                <ng-select
                    [(ngModel)]="selectedAuthorityTypes"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="working"
                    [clearable]="true"
                    [items]="authorityTypes"
                    [multiple]="true"
                    placeholder="Select an authority"
                    bindLabel="name"
                    bindValue="id"
                    appendTo="body"
                >
                </ng-select>
            </div>
            <div class="modal-footer">
                <button
                    (click)="downloadPersonnelReport()"
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working"
                    angulartics2On="click"
                    angularticsAction="dashboard-report-personnel"
                >
                    Download
                </button>
            </div>
        </tab>
        <ng-container *ngFor="let printOption of printOptions">
            <tab [heading]="printOption.name">
                <div class="modal-body">
                    <p class="top-buffer">
                        {{ printOption.description }}
                    </p>
                </div>
                <div class="modal-footer">
                    <button
                        (click)="printReport(printOption)"
                        type="button"
                        class="btn btn-primary"
                        [disabled]="working"
                        angulartics2On="click"
                        angularticsAction="dashboard-report-personnel"
                    >
                        <span class="fa fa-print"></span> Print
                        {{ printOption.name }}
                    </button>
                </div>
            </tab>
        </ng-container>
    </tabset>
</div>
