import { Component, Input } from '@angular/core';
import {
    CreateFlightLogCollectionSourceCommand,
    DO_NOTHING,
    FlightLogCollectionService,
    FlightLogCollectionSourceDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonRolesDto,
    PersonService,
    SimplePersonDto,
    UpdateFlightLogCollectionSourceCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FlightLogSettingsService } from './flight-log-settings.service';
import { FlightLogCollectionStatusLookup } from './helpers';
import { FlightLogCollectionSourceWithEnabled } from './interfaces';

function addDjiFields(showPersonalAccountOption: boolean): FormlyFieldConfig[] {
    return [
        {
            key: 'username',
            type: 'input',
            props: {
                label: 'Email address',
                required: true,
                description:
                    'The email address you use to sign into DJI Go/Pilot/Pilot2'
            }
        } as FormlyFieldConfig,
        {
            key: 'password',
            type: 'input',
            props: {
                label: 'Password',
                required: true,
                type: 'password',
                description: 'The password for your DJI sync account'
            }
        }
    ].concat(
        showPersonalAccountOption
            ? [
                  {
                      key: 'personalAccount',
                      type: 'radio',
                      props: {
                          label: 'Personal Account',
                          required: true,
                          description:
                              'Is this your personal DJI sync account, or is it an organisation wide account?',
                          options: [
                              { name: 'Personal Account', value: true },
                              { name: 'Organisation Account', value: false }
                          ]
                      }
                  }
              ]
            : []
    );
}

/**
 * Show the DJI collection source list. It is assumed that if this component is embededd then the user can at least manage their own collection sources.
 */
@Component({
    selector: 'dji-sync-settings',
    template: `<div class="horizontal-container">
        <button
            type="button"
            class="btn-circle btn-link"
            (click)="addDji()"
            [disabled]="working"
        >
            <span class="fa fa-plus"></span>
        </button>
        <div
            *ngIf="djiList$ | async as djiList"
            class="vertical-container fill"
        >
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 20%">Username</th>
                        <th style="width: 10%">Owner</th>
                        <th style="width: 20%">Last Completion Time</th>
                        <th style="width: 20%">Last Completion Message</th>
                        <th style="width: 10%">Enabled</th>
                        <th
                            *ngIf="
                                flightLogSettingsService.canUseAlerts &&
                                canManageAll
                            "
                            style="width: 10%"
                        >
                            Alerts
                        </th>
                        <th
                            style="width: 10%"
                            *ngIf="hasMissionConfig && canManageAll"
                        >
                            Automatic Mission Creation
                        </th>
                        <th style="width: 10%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dji of djiList">
                        <td>{{ dji.username }}</td>
                        <td>
                            <ng-container [ngSwitch]="canManageAll">
                                <ng-container *ngSwitchCase="true">
                                    <editable
                                        [initialContext]="{
                                            id: dji.id,
                                            ownerId: dji.owner?.id
                                        }"
                                        [updateFn]="reassignSource"
                                    >
                                        <ng-template viewMode>
                                            <person-avatar
                                                [person]="dji.owner"
                                                [managingOrganisationId]="
                                                    flightLogSettingsService.organisationId
                                                "
                                                alternativeName="Organisation"
                                                [width]="30"
                                                [height]="30"
                                            >
                                            </person-avatar>
                                        </ng-template>
                                        <ng-template
                                            editMode
                                            let-context="context"
                                            let-state="state"
                                            let-submit="submit"
                                        >
                                            <ng-select
                                                [(ngModel)]="context.ownerId"
                                                [items]="
                                                    syncAssigneeCandidateList
                                                "
                                                bindValue="id"
                                                [readonly]="state.saving"
                                                (change)="submit()"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                >
                                                    <person-avatar
                                                        [person]="item"
                                                        [managingOrganisationId]="
                                                            flightLogSettingsService.organisationId
                                                        "
                                                        [width]="25"
                                                        [height]="25"
                                                    >
                                                    </person-avatar>
                                                </ng-template>
                                                <ng-template
                                                    ng-label-tmp
                                                    let-item="item"
                                                >
                                                    <person-avatar
                                                        [person]="item"
                                                        [managingOrganisationId]="
                                                            flightLogSettingsService.organisationId
                                                        "
                                                        [width]="20"
                                                        [height]="20"
                                                    >
                                                    </person-avatar>
                                                </ng-template>
                                            </ng-select>
                                            <div
                                                *ngIf="state.error != null"
                                                class="text-danger"
                                            >
                                                {{ state.error }}
                                            </div>
                                        </ng-template>
                                    </editable>
                                </ng-container>
                                <ng-container *ngSwitchCase="false">
                                    {{
                                        (dji.owner | formatPerson) ||
                                            'Organisation'
                                    }}
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container
                                *ngIf="dji.lastCompletionTime != null"
                            >
                                {{ dji.lastCompletionTime | formatDateTime }} -
                            </ng-container>
                            {{ flightLogStatus[dji.lastCompletionStatus] }}
                        </td>
                        <td>{{ dji | formatFlightLogCompletionMessage }}</td>
                        <td *ngIf="dji.collectNow">
                            {{
                                dji.collectNowQueue
                                    | formatFlightLogQueuePosition
                            }}
                        </td>
                        <td *ngIf="!dji.collectNow">
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [disabled]="working"
                                        [(ngModel)]="dji.enabled"
                                        (change)="updateDjiEnabled(dji)"
                                    />
                                </div>
                            </div>
                        </td>
                        <td
                            *ngIf="
                                flightLogSettingsService.canUseAlerts &&
                                canManageAll
                            "
                        >
                            <flight-log-alert
                                [alertSettings]="dji.alertSettings"
                                (showSettings)="
                                    flightLogSettingsService.showAlertSettings(
                                        dji
                                    )
                                "
                            ></flight-log-alert>
                        </td>
                        <td *ngIf="hasMissionConfig && canManageAll">
                            <automatic-mission-assignment-settings
                                [entry]="dji"
                            ></automatic-mission-assignment-settings>
                        </td>
                        <td class="actions">
                            <button
                                type="button"
                                class="btn btn-tertiary"
                                (click)="collectNow(dji)"
                                [disabled]="working || dji.collectNow"
                                tooltip="Collect now"
                                placement="left"
                            >
                                <span class="fal fa-sync"></span>
                            </button>
                            <button
                                type="button"
                                class="btn btn-tertiary"
                                (click)="editDji(dji)"
                                [disabled]="working"
                                tooltip="Edit"
                                placement="left"
                            >
                                <span class="fal fa-pencil-alt"></span>
                            </button>
                            <button
                                class="btn btn-tertiary btn-delete"
                                type="button"
                                (click)="deleteDji(dji)"
                                [disabled]="working"
                                tooltip="Delete"
                                placement="left"
                            >
                                <span class="fal fa-trash-alt"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="bottom-buffer fill" *ngIf="djiList.length === 0">
                <empty componentName="empty-dji-accounts">
                    <button
                        type="button"
                        class="btn btn-default top-buffer"
                        (click)="addDji()"
                    >
                        Add DJI Account
                    </button>
                </empty>
            </div>
        </div>
    </div>`
})
export class DjiSyncSettings {
    @Input() hasMissionConfig: boolean = false;
    @Input() canManageAll: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    djiList$: Observable<FlightLogCollectionSourceWithEnabled[]>;

    working: boolean;

    collectionFrequency =
        UpdateFlightLogCollectionSourceCommand.CollectionFrequency;

    flightLogStatus = FlightLogCollectionStatusLookup;

    syncAssigneeCandidateList: SimplePersonDto[];

    constructor(
        public flightLogSettingsService: FlightLogSettingsService,
        private workTracker: WorkTracker,
        private commonDialoguesService: CommonDialoguesService,
        private flightLogCollectionService: FlightLogCollectionService,
        private logging: FlyFreelyLoggingService,
        private personService: PersonService
    ) {
        this.djiList$ =
            this.flightLogSettingsService.flightLogCollectionSourceList$.pipe(
                takeUntil(this.ngUnsubscribe$),
                map(sources =>
                    sources
                        .filter(
                            s =>
                                s.type ===
                                FlightLogCollectionSourceDto.Type.DJI_CLOUD
                        )
                        .map(s => ({
                            ...s,
                            enabled: s.collectionFrequency !== 'NEVER'
                        }))
                )
            );
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        if (this.canManageAll) {
            this.personService
                .findPersonnel(
                    this.flightLogSettingsService.organisationId,
                    PersonRolesDto.Roles.FLIGHT_LOG_COLLECTION_OWN
                )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(list => (this.syncAssigneeCandidateList = list));
        } else {
            this.syncAssigneeCandidateList = [];
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
    // FIXME the new entry isn't showing up
    addDji() {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Create DJI',
                'Save',
                true,
                true,
                addDjiFields(this.canManageAll),
                {
                    username: null as string,
                    password: null as string,
                    personalAccount: false
                },
                data =>
                    firstValueFrom(
                        this.flightLogCollectionService.create({
                            organisationId:
                                this.flightLogSettingsService.organisationId,
                            type: CreateFlightLogCollectionSourceCommand.Type
                                .DJI_CLOUD,
                            collectionFrequency:
                                CreateFlightLogCollectionSourceCommand
                                    .CollectionFrequency.DAILY,
                            username: data.username,
                            password: data.password,
                            personalAccount:
                                !this.canManageAll || data.personalAccount
                        })
                    ),
                'modal-task'
            )
            .then(() => this.logging.success('DJI account setup'))
            .catch(DO_NOTHING);
    }

    collectNow(entry: FlightLogCollectionSourceDto) {
        this.flightLogCollectionService
            .collectNow(entry.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(updated =>
                this.logging.success('DJI collection scheduled')
            )
            .add(this.workTracker.createTracker());
    }

    editDji(entry: FlightLogCollectionSourceDto) {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Update details',
                'Save',
                true,
                true,
                [
                    {
                        key: 'password',
                        type: 'input',
                        props: {
                            label: 'Password',
                            required: false,
                            type: 'password'
                        }
                    },
                    // Allowing to set enabled here and in the table for now
                    {
                        key: 'enabled',
                        type: 'radio',
                        defaultValue: true,
                        props: {
                            label: 'Enabled',
                            required: true,
                            options: [
                                { value: true, name: 'Enabled' },
                                { value: false, name: 'Disabled' }
                            ]
                        }
                    }
                ],
                {
                    password: null as string,
                    enabled:
                        entry.collectionFrequency ===
                        this.collectionFrequency.DAILY
                },
                data =>
                    firstValueFrom(
                        this.flightLogCollectionService.updateSource(
                            {
                                collectionFrequency:
                                    data.enabled === true
                                        ? this.collectionFrequency.DAILY
                                        : this.collectionFrequency.NEVER,
                                id: entry.id,
                                password: data.password ?? null
                            },
                            entry.id
                        )
                    ),
                'modal-task'
            )
            .then(() => this.logging.success('DJI account updated'))
            .catch(DO_NOTHING);
    }

    updateDjiEnabled(entry: FlightLogCollectionSourceWithEnabled) {
        this.flightLogCollectionService
            .updateSource(
                {
                    collectionFrequency: entry.enabled
                        ? this.collectionFrequency.DAILY
                        : this.collectionFrequency.NEVER,
                    id: entry.id,
                    password: null
                },
                entry.id
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () =>
                    this.logging.success(
                        'Flight log collection settings updated'
                    ),
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating flight log collection settings: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    deleteDji(entry: FlightLogCollectionSourceDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Collection',
                'Are you sure you want to delete this collection?',
                'Delete',
                () => Promise.resolve()
            )
            .then(() =>
                this.flightLogCollectionService
                    .deleteSource(entry.id)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(() =>
                        this.logging.success('DJI account deleted')
                    )
                    .add(this.workTracker.createTracker())
            )
            .catch(DO_NOTHING);
    }

    reassignSource = (context: { id: number; ownerId?: number }) =>
        firstValueFrom(
            this.flightLogCollectionService
                .assignFlightLogCollectionSource(context.id, {
                    personId: context.ownerId
                })
                .pipe(takeUntil(this.ngUnsubscribe$))
        );
}
