<div class="container-fluid">
    <div [formGroup]="form">
        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <label class="control-label">
                    Maintenance Type
                    <field-validation-indicator
                        [control]="form.controls.maintenanceType"
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <div
                    class="input-group"
                    btnRadioGroup
                    formControlName="maintenanceType"
                    style="padding-top: 7px"
                >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="DEFECT"
                        [disabled]="working"
                        >Defect</label
                    >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="SCHEDULED"
                        [disabled]="working"
                        >Scheduled</label
                    >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="INSPECTION"
                        [disabled]="working"
                        >Inspection</label
                    >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="CONFIGURATION_CHANGE"
                        [disabled]="working"
                        >Configuration Change</label
                    >
                    <label
                      class="btn btn-xs btn-pill"
                      btnRadio="OTHER"
                      [disabled]="working"
                    >Other</label
                    >
                </div>
            </div>
            <div class="col-xs-3"></div>
        </div>

        <enhanced-help componentName="maintenanceType"> </enhanced-help>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <label class="control-label">
                    Resource Category
                    <field-validation-indicator
                        [control]="form.controls.resourceCategory"
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <div
                    class="input-group"
                    btnRadioGroup
                    formControlName="resourceCategory"
                    style="padding-top: 7px"
                >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="CRAFT"
                        [disabled]="working"
                        >RPA</label
                    >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="BATTERY"
                        [disabled]="working"
                        >Battery</label
                    >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="BATTERY_SET"
                        [disabled]="working"
                        >Battery Set</label
                    >
                    <label
                        class="btn btn-xs btn-pill"
                        btnRadio="EQUIPMENT"
                        [disabled]="working"
                        >Equipment</label
                    >
                </div>
            </div>
            <div class="col-xs-3"></div>
        </div>

        <enhanced-help componentName="resourceCategory"> </enhanced-help>

        <div
            class="form-group input-content"
            *ngIf="form.value.resourceCategory == 'CRAFT'"
        >
            <div class="col-xs-3 text-right">
                <label class="control-label" [ngClass]="{ readonly: working }">
                    RPA
                    <field-validation-indicator
                        [control]= resourceIdvalidity
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <ng-select
                    name="craftIds"
                    [formControlName]="resourceIdControlName"
                    bindValue="id"
                    bindLabel="nickname"
                    placeholder="Select the RPA for repair"
                    [items]="allRpa"
                    [multiple]= isMultipleSelect
                    [searchFn]="rpaSearch"
                    [readonly]="working"
                    [clearable]="false"
                    appendTo="body"
                    data-cy="maintenanceCraftSelection"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <rpa-list-item [item]="item"></rpa-list-item>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        <status-icon
                            [rpa]="item"
                            [clickable]="false"
                        ></status-icon>
                    </ng-template>
                </ng-select>
            </div>

            <div class="col-xs-3"></div>
        </div>

        <enhanced-help
            *ngIf="form.value.resourceCategory == 'CRAFT'"
            componentName="craftIds"
        >
        </enhanced-help>

        <div
            class="form-group input-content"
            *ngIf="form.value.resourceCategory == 'BATTERY'"
        >
            <div class="col-xs-3 text-right">
                <label class="control-label" [ngClass]="{ readonly: working }">
                    Battery
                    <field-validation-indicator
                        [control]= resourceIdvalidity
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <ng-select
                    name="batteryIds"
                    [formControlName]="resourceIdControlName"
                    bindValue="id"
                    placeholder="Select the Battery for repair"
                    [items]="allBatteries"
                    [multiple]= isMultipleSelect
                    [searchFn]="batterySearch"
                    [readonly]="working"
                    [clearable]="false"
                    appendTo="body"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <battery-list-item [item]="item"></battery-list-item>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        <status-icon
                            [battery]="item"
                            [clickable]="false"
                        ></status-icon>
                    </ng-template>
                </ng-select>
            </div>

            <div class="col-xs-3"></div>
        </div>

        <enhanced-help
            *ngIf="form.value.resourceCategory == 'BATTERY'"
            componentName="batteryIds"
        >
        </enhanced-help>

        <div
            class="form-group input-content"
            *ngIf="form.value.resourceCategory == 'BATTERY_SET'"
        >
            <div class="col-xs-3 text-right">
                <label class="control-label" [ngClass]="{ readonly: working }">
                    Battery Set
                    <field-validation-indicator
                        [control]= resourceIdvalidity
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <ng-select
                    name="batterySetIds"
                    [formControlName]="resourceIdControlName"
                    bindValue="id"
                    placeholder="Select the Battery Set for repair"
                    [items]="allBatterySets"
                    [multiple]= isMultipleSelect
                    [searchFn]="batterySetSearch"
                    [readonly]="working"
                    [clearable]="false"
                    appendTo="body"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <battery-set-list-item
                            [item]="item"
                        ></battery-set-list-item>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        <status-icon
                            [batterySet]="item"
                            [clickable]="false"
                        ></status-icon>
                    </ng-template>
                </ng-select>
            </div>

            <div class="col-xs-3"></div>
        </div>

        <enhanced-help
            *ngIf="form.value.resourceCategory == 'BATTERY_SET'"
            componentName="batterySetIds"
        >
        </enhanced-help>

        <div
            class="form-group input-content"
            *ngIf="form.value.resourceCategory == 'EQUIPMENT'"
        >
            <div class="col-xs-3 text-right">
                <label class="control-label" [ngClass]="{ readonly: working }">
                    Equipment
                    <field-validation-indicator
                        [control]= resourceIdvalidity
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <ng-select
                    name="equipmentIds"
                    [formControlName]= "resourceIdControlName"
                    bindValue="id"
                    placeholder="Select the Equipment for repair"
                    [items]="allEquipment"
                    [multiple]= isMultipleSelect
                    [searchFn]="equipmentSearch"
                    [readonly]="working"
                    [clearable]="false"
                    appendTo="body"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <equipment-list-item
                            [item]="item"
                        ></equipment-list-item>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        <status-icon
                            [equipment]="item"
                            [clickable]="false"
                        ></status-icon>
                    </ng-template>
                </ng-select>
            </div>

            <div class="col-xs-3"></div>
        </div>

        <enhanced-help
            *ngIf="form.value.resourceCategory == 'EQUIPMENT'"
            componentName="equipmentIds"
        >
        </enhanced-help>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <label class="control-label" [ngClass]="{ readonly: working }">
                    Reason for Request
                    <field-validation-indicator
                        [control]="form.controls.reasonForRequest"
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <textarea
                    class="form-control"
                    formControlName="reasonForRequest"
                    rows="5"
                    [readonly]="working"
                >
                </textarea>
            </div>
            <div class="col-xs-3"></div>
        </div>

        <enhanced-help componentName="reasonForRequest"> </enhanced-help>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <label class="control-label">
                    Safe to Use
                    <field-validation-indicator
                        [control]="form.controls.safeToUse"
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <div
                    class="input-group"
                    btnRadioGroup
                    formControlName="safeToUse"
                    style="padding-top: 7px"
                >
                    <label
                        data-cy="maintenanceRequestSafeToUseTrue"
                        class="btn btn-xs btn-pill"
                        [btnRadio]="true"
                        [disabled]="working"
                        >Yes</label
                    >
                    <label
                        data-cy="maintenanceRequestSafeToUseFalse"
                        class="btn btn-xs btn-pill"
                        [btnRadio]="false"
                        [disabled]="working"
                        >No</label
                    >
                </div>
            </div>
            <div class="col-xs-3"></div>
        </div>

        <enhanced-help componentName="safeToUse"> </enhanced-help>
    </div>
    <component-service-time
        *ngIf="showInServiceTime && createBulk !== 'bulk'"
        [resourceId]="+form.controls.resourceId.value"
        [resourceType]="form.controls.resourceCategory.value"
        [managingOrganisationId]="
            selectedOrganisationId(
                form.controls.resourceCategory.value,
                form.controls.resourceId.value
            )
        "
        [hasScheduledMaintenance]="showScheduledMaintenance"
        timeInServiceMode="maintenance"
    ></component-service-time>
</div>
