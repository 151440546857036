import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceScheduleTriggerDto } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from 'libs/ui/src/lib/pipes';
import { triggerActivationTypes } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatMaintenanceTriggerType' })
export class FormatMaintenanceTriggerTypePipe implements PipeTransform {
    transform: (value: MaintenanceScheduleTriggerDto.Type) => string;

    constructor() {
        this.transform = setupStatusFormatter(triggerActivationTypes);
    }
}
