import { Component } from '@angular/core';
import {
    FEATURE_OUTSTANDING_TASKS_VIEW,
    PersonsOrganisationDto,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import {
    FormatDatePipe,
    FormatMaintenanceEquipmentCategoryPipe,
    FormatMaintenanceLogTypePipe,
    FormatMaintenanceStatusPipe,
    FormatPersonPipe
} from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { MaintenanceDialogues } from 'libs/maintenance/src/lib/maintenance-dialogues.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetRegistrationWithSection } from '../../../outstanding-tasks-view.service';
import {
    AssignedMaintenanceWidgetLog,
    MaintenanceTaskWidgetDataService,
    UpcomingMaintenanceWidgetLog
} from '../maintenance-data.service';

const ASSIGNED_MAINTENANCE_IDENTIFIER = 'upcomingMaintenanceTaskWidget';

@Component({
    selector: 'assigned-maintenance-task-widget',
    templateUrl: './assigned-maintenance-widget.component.html',
    styleUrls: ['../../widget-styles.scss'],
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()]
})
export class AssignedMaintenanceWidgetComponent {
    organisation: PersonsOrganisationDto;
    showBody = true;

    maintenanceLogs: AssignedMaintenanceWidgetLog[];

    //Static table variables
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;

    working = false;
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private maintenanceDataService: MaintenanceTaskWidgetDataService,
        private workspaceStateService: WorkspaceStateService,
        private maintenanceDialogues: MaintenanceDialogues,
        formatMaintenanceEquipmentCategoryPipe: FormatMaintenanceEquipmentCategoryPipe,
        formatMaintenanceLogTypePipe: FormatMaintenanceLogTypePipe,
        formatMaintenanceStatusPipe: FormatMaintenanceStatusPipe,
        formatPersonPipe: FormatPersonPipe,
        formatDatePipe: FormatDatePipe
    ) {
        this.availableColumns = [
            {
                value: 'resourceCategory',
                name: 'Resource Category',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: c =>
                    formatMaintenanceEquipmentCategoryPipe.transform(c),
                linkFunction: row => this.viewMaintenance(row)
            },
            {
                value: 'name',
                name: 'Resource Name',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.viewMaintenance(row)
            },
            {
                value: 'serialNumber',
                name: 'Serial',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'requestTime',
                name: 'Request Time',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: t => formatDatePipe.transform(t)
            },
            {
                value: 'assignee',
                name: 'Assignee',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'maintenanceType',
                name: 'Type',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: t =>
                    formatMaintenanceLogTypePipe.transform(t)
            },
            {
                value: 'status',
                name: 'Status',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: s => formatMaintenanceStatusPipe.transform(s)
            }
        ];

        this.tableConfig = {
            limit: 3,
            actions: []
        };

        this.selectedColumns = null;

        this.maintenanceDataService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
            });
        this.maintenanceDataService.assignedMaintenanceLogs$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(maintenanceLogs => {
                this.maintenanceLogs = maintenanceLogs;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    viewMaintenance(log: UpcomingMaintenanceWidgetLog) {
        this.maintenanceDialogues.showMaintenanceDetailsModal(
            this.organisation.id,
            log.maintenanceLog.id
        );
    }
}

export const assignedMaintenanceTaskWidgetRegistration: WidgetRegistrationWithSection =
    {
        widgetIdentifier: ASSIGNED_MAINTENANCE_IDENTIFIER,
        section: 'resources',
        component: AssignedMaintenanceWidgetComponent,
        isAvailable: organisation =>
            hasFeatureFlag(organisation, FEATURE_OUTSTANDING_TASKS_VIEW)
    };
