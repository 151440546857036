import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AuthorityFilter,
    FEATURE_FLIGHT_CONFORMANCE,
    FlightConformanceResultDto,
    FlightLogFileDto,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    GqlFilterField,
    GraphQlMissionFilters,
    LocationDto,
    LocationSummary,
    MISSION_TYPES,
    MissionService,
    MissionSummaryDto,
    OrganisationAuthorityService,
    PersonsOrganisationDto,
    PreferencesService,
    SimpleAuthorityDto,
    UserService,
    WidgetMission,
    WorkTracker,
    compareDates,
    hasFeatureFlag,
    observeFormControl
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import {
    FormatAuthorityPipe,
    FormatColumnArrayPipe,
    FormatDateTimePipe,
    FormatMissionStatusPipe,
    findMostPressingConformance,
    flightConformanceParsedStates,
    toConformanceResultStatusArray
} from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { Angulartics2 } from 'angulartics2';
import { deepEqual } from 'fast-equals';
import { getOrElse, mapNullable, map as optionMap } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { toSimpleAuthority } from 'libs/authorities/src/lib/helpers';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';
import { LocationDialoguesService } from 'libs/locations/src/lib/location-dialogues.service';
import { findRpic } from 'libs/missions/src/lib/helpers';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { FormatMissionTypePipe, FormatPersonPipe } from 'libs/ui/src/lib/pipes';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {
    BehaviorSubject,
    Observable,
    Subject,
    asyncScheduler,
    combineLatest,
    forkJoin,
    merge,
    scheduled
} from 'rxjs';
import {
    concatAll,
    map,
    shareReplay,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { MissionViewService } from '../mission-view.service';
import { MissionViewDataService } from './mission-view-data.service';

@Component({
    selector: 'flyfreely-portal-ui-mission-view',
    templateUrl: './mission-view.component.html',
    styleUrls: ['./mission-view.component.scss'],
    providers: [MissionViewDataService]
})
export class MissionViewComponent implements OnInit {
    currentOrganisation: PersonsOrganisationDto;
    lastMissionTotalCheckOrganisationId: number;

    viewMode: 'MAP' | 'LIST' | 'BOTH' = 'BOTH';

    firstLoad = true;
    newFlightLogs = 0;

    showDummyData$: Observable<boolean>;
    showDummyData: boolean;
    locations: LocationSummary[];

    canFilterByAuthority = false;
    canUseCalendar = true;
    canListMissions = true;
    hasFlightLogging = true;
    canReport = true;
    canAddMission = true;
    canEditMission = true;
    canSeeOwnMissions = false;
    canUseFlightConformance = false;
    useFeatureFlaggedColumns = false;

    private preferencesLoaded = false;

    missions: WidgetMission[];

    organisationsMissionFilter: AuthorityFilter;
    missionAuthorityFilter: AuthorityFilter;
    missionAuthorities: SimpleAuthorityDto[];

    missionAuthorityFilters$: Observable<
        { name: string; children: AuthorityFilter[] }[]
    >;
    organisationSelector = new FormControl<{
        name: string;
        children: AuthorityFilter[];
    }>(undefined);
    organisationSelectorValue$: Observable<{
        name: string;
        children: AuthorityFilter[];
    }>;
    authoritySelector: FormControl<AuthorityFilter> = new FormControl(
        undefined
    );

    missionOperationTypeFilters: {
        name: string;
        value: number;
    }[];

    hasMissions = new BehaviorSubject<boolean>(false);
    orgHasMissions = new BehaviorSubject<boolean>(null);
    private organisationUpdated$ = new Subject<void>();

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    tableSorting: ColumnSortPreferences;
    tableSearch: any;
    tableFilters: GqlFilterField[] = [];
    graphQLFilters: GraphQlMissionFilters;
    gqlSorting: ColumnSortPreferences;
    missionStatusFilters: MissionSummaryDto.Status[];
    filterStartTime: string;
    filterEndTime: string;

    userPreferences: TableSetupUserPreferences;

    private missions$ = new Subject<WidgetMission[]>();
    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private missionDialoguesService: MissionDialoguesService,
        private flightLogsService: FlightLogsService,
        private locationDialoguesService: LocationDialoguesService,
        private workspaceStateService: WorkspaceStateService,
        private preferencesService: PreferencesService,
        private userService: UserService,
        private missionService: MissionService,
        private missionViewDataService: MissionViewDataService,
        private missionViewService: MissionViewService,
        private flightLogService: FlightLogsService,
        private toastr: ToastrService,
        private formatAuthority: FormatAuthorityPipe,
        private formatDateTimePipe: FormatDateTimePipe,
        private combinedMissionStatus: FormatMissionStatusPipe,
        private formatColumnArrayPipe: FormatColumnArrayPipe,
        private formatMissionTypePipe: FormatMissionTypePipe,
        private formatPersonPipe: FormatPersonPipe,
        private changeDetector: ChangeDetectorRef,
        private angulartics2: Angulartics2,
        private logging: FlyFreelyLoggingService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.showDummyData$ = this.preferencesService.showDummyData$;
    }

    ngOnInit() {
        combineLatest([
            this.workTracker.observable,
            this.missionViewDataService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([working, loadingTable]) =>
                    (this.working = working || loadingTable)
            );

        this.showDummyData$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(showDummyData => {
                if (this.showDummyData != showDummyData) {
                    this.showDummyData = showDummyData;
                    this.onTableSearch(this.tableSearch);
                }
            });

        this.missionViewDataService.hasMissions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(hasMissions => {
                this.hasMissions.next(hasMissions);
                // Populate orgHasMissions after the first fetch using just the org ID.
                // This is to only show the "add missions to get started" banner if the org itself doesn't have missions.
                if (this.orgHasMissions.getValue() == null) {
                    this.orgHasMissions.next(hasMissions);
                }
            });

        combineLatest([
            this.workspaceStateService.currentOrganisation$,
            scheduled(
                [[undefined], this.missionService.change$],
                asyncScheduler
            ).pipe(concatAll()),
            scheduled(
                [[undefined], this.flightLogService.change$],

                asyncScheduler
            ).pipe(concatAll())
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([organisation, _missionChange, _flightLogChange]) => {
                if (organisation.type === 'organisation_loaded') {
                    // organisationUpdated$ acts like ngUnsubscribe$ for subscriptions that will need to be restarted on org change
                    this.organisationUpdated$.next();
                    this.currentOrganisation = organisation.organisation;
                    this.missions = [];
                    this.missionAuthorityFilter = null;
                    this.refreshPermissions(organisation.organisation);

                    if (this.firstLoad) {
                        this.firstLoad = false;
                        this.setupInitialParameters();
                    } else {
                        this.updateMissionsOnOrganisationChange();
                    }
                } else {
                    console.warn('No organisation loaded');
                    // None visible

                    this.locations = null;
                    this.currentOrganisation = null;
                }
            });

        this.missionViewService.viewMode$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mode => (this.viewMode = mode));

        this.missionViewService.showReportsDialogue$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.showMissionReportsDialogue());

        this.missionViewService.showMissionCalendar$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.showMissionCalendar());

        this.missionViewService.permissionsUpdated$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(updated => {
                if (updated) {
                    this.canFilterByAuthority =
                        this.missionViewService.canFilterByAuthority;
                    this.canUseCalendar =
                        this.missionViewService.canUseCalendar;
                    this.canListMissions =
                        this.missionViewService.canListMissions;
                    this.hasFlightLogging =
                        this.missionViewService.hasFlightLogging;
                    this.canReport = this.missionViewService.canReport;
                    this.canAddMission = this.missionViewService.canAddMission;
                    this.canEditMission =
                        this.missionViewService.canEditMission;
                    this.canSeeOwnMissions =
                        this.missionViewService.canSeeOwnMissions;
                }
            });

        this.organisationSelectorValue$ = observeFormControl<{
            name: string;
            children: AuthorityFilter[];
        }>(this.organisationSelector);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Resets and reloads authority data when the organisation selector value changes.
     */
    private updateMissionsOnOrganisationChange() {
        this.setupMissionAuthorities();
        this.loadPreferences();
        this.organisationsMissionFilter = {
            name: `All Missions`,
            organisationId: this.currentOrganisation.id,
            authorityIds: null,
            orOrganisationId: null,
            filterType: 'ALL_AUTHORITIES'
        };
        this.missionAuthorityFilter = this.organisationsMissionFilter;
    }

    private setupInitialParameters() {
        this.refreshFlightLogs();
        this.missionAuthorityFilter = this.organisationsMissionFilter;
        this.refreshMissions();
        this.watchReturnedMissionValues();
        this.setupMissionAuthorities();
        this.loadPreferences();
        this.lastMissionTotalCheckOrganisationId = this.currentOrganisation.id;
        this.organisationsMissionFilter = {
            name: `All Missions`,
            organisationId: this.currentOrganisation.id,
            authorityIds: null,
            orOrganisationId: null,
            filterType: 'ALL_AUTHORITIES'
        };

        this.missions$
            .pipe(
                tap(missions => {
                    // If there aren't missions returned it may be because of filters, check if the org has any missions at all
                    // If there is an orOrganisationId in the authority filters, use the filter IDs instead to find missions from shared authorities.
                    if (missions.length === 0) {
                        const checkCurrentTotal = () => {
                            this.missionViewDataService.checkMissionTotals(
                                this.missionAuthorityFilter.orOrganisationId !=
                                    null
                                    ? this.missionAuthorityFilter.organisationId
                                    : this.currentOrganisation.id,
                                this.missionAuthorityFilter.orOrganisationId !=
                                    null
                                    ? this.missionAuthorities.map(a => a.id)
                                    : null,
                                this.missionAuthorityFilter.orOrganisationId
                            );
                        };

                        if (
                            this.orgHasMissions.getValue() == null &&
                            this.missionAuthorityFilter.orOrganisationId != null
                        ) {
                            // If the mission total for the org itself without filters hasn't run yet, and
                            // the filters on the widget are using orOrganisation, run the check for the org itself first,
                            // then run it for the current widget settings.
                            this.missionViewDataService
                                .checkMissionTotals(this.currentOrganisation.id)
                                .add(() => checkCurrentTotal());
                        } else {
                            checkCurrentTotal();
                        }
                    }
                })
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missions => {
                this.missions = missions;
            });
    }

    private refreshPermissions(organisation: PersonsOrganisationDto) {
        this.missionViewService.refreshPermissions(organisation);

        this.canUseFlightConformance = pipe(
            this.userService.findOrganisationForUser(
                this.currentOrganisation.id
            ),
            optionMap(o => hasFeatureFlag(o, FEATURE_FLIGHT_CONFORMANCE)),
            getOrElse(() => false)
        );

        // useFeatureFlaggedColumns allows creating alternative table column arrays based on feature flags.
        // To accommodate this the table columns will now only load after permissions have been set.
        // This can be extended based on feature flags to configure columns as needed.
        this.useFeatureFlaggedColumns = this.canUseFlightConformance;

        this.setupTable();
    }

    private updateMissionsList(missions: MissionSummaryDto[]) {
        this.missions$.next(
            missions.map(m => ({
                ...m,
                missionCrewNames: this.missionViewService.findCrew(
                    m.missionCrewDetails
                ),
                rpic: findRpic(m.missionCrewDetails),
                combinedStatus: this.missionService.getCombinedStatus(m),
                convertedDate: moment(m.missionDate).tz(m.timeZone),
                convertedRequestTime: m.missionApproval?.requestTime
                    ? moment(m.missionApproval.requestTime)
                    : null,
                convertedResolutionTime: m.missionApproval?.resolutionTime
                    ? moment(m.missionApproval.resolutionTime)
                    : null
            }))
        );
    }

    refreshMissions() {
        if (
            this.currentOrganisation == null ||
            (!this.canListMissions && !this.canSeeOwnMissions) ||
            this.missionAuthorityFilter == null
        ) {
            return;
        }
        // Re-check mission totals if the organisation id has changed and for shared authorities
        if (
            this.missionAuthorityFilter.organisationId == null ||
            this.missionAuthorityFilter.organisationId !==
                this.currentOrganisation.id
        ) {
            this.lastMissionTotalCheckOrganisationId =
                this.missionAuthorityFilter.organisationId;
        } else if (
            this.lastMissionTotalCheckOrganisationId !==
            this.currentOrganisation.id
        ) {
            this.lastMissionTotalCheckOrganisationId =
                this.currentOrganisation.id;
        }

        this.missionViewDataService.findMissions(
            this.tableConfig?.currentPage ?? 0,
            this.tableConfig.limit ?? 10,
            this.gqlSorting,
            this.tableFilters,
            this.missionAuthorityFilter.organisationId,
            this.graphQLFilters.conformanceResultStatus,
            this.missionAuthorityFilter.orOrganisationId != null &&
                (this.missionAuthorityFilter.authorityIds == null ||
                    this.missionAuthorityFilter.authorityIds.length === 0)
                ? this.missionAuthorities.map(a => a.id)
                : this.missionAuthorityFilter.authorityIds,
            this.missionAuthorityFilter.orOrganisationId,
            this.missionStatusFilters,
            this.filterStartTime,
            this.filterEndTime
        );
    }

    refreshCalendarMissions(startTime: string, endTime: string) {
        if (
            this.currentOrganisation == null ||
            (!this.canListMissions && !this.canSeeOwnMissions) ||
            this.missionAuthorityFilter == null
        ) {
            return;
        }
        // Re-check mission totals if the organisation id has changed and for shared authorities
        if (
            this.missionAuthorityFilter.organisationId == null ||
            this.missionAuthorityFilter.organisationId !==
                this.currentOrganisation.id
        ) {
            this.lastMissionTotalCheckOrganisationId =
                this.missionAuthorityFilter.organisationId;
        } else if (
            this.lastMissionTotalCheckOrganisationId !==
            this.currentOrganisation.id
        ) {
            this.lastMissionTotalCheckOrganisationId =
                this.currentOrganisation.id;
        }

        this.missionViewDataService.findCalendarMissions(
            startTime,
            endTime,
            this.gqlSorting,
            this.tableFilters,
            this.missionAuthorityFilter.organisationId,
            this.graphQLFilters.conformanceResultStatus,
            this.missionAuthorityFilter.orOrganisationId != null &&
                (this.missionAuthorityFilter.authorityIds == null ||
                    this.missionAuthorityFilter.authorityIds.length === 0)
                ? this.missionAuthorities.map(a => a.id)
                : this.missionAuthorityFilter.authorityIds,
            this.missionAuthorityFilter.orOrganisationId,
            this.missionStatusFilters
        );
    }

    private setupMissionAuthorities() {
        const today = moment().format('YYYY-MM-DD');

        this.missionAuthorityFilters$ = combineLatest([
            this.organisationAuthorityService.findAuthorities(
                this.currentOrganisation.id,
                this.currentOrganisation.id
            ),
            this.organisationAuthorityService.findSharedAuthorities(
                this.currentOrganisation.id,
                today
            )
        ]).pipe(
            map(([authorities, sharedAuthorityGroups]) => {
                const organisationAuthorities = authorities
                    .filter(group => group.hasWorkflow)
                    .map(group =>
                        group.authorities.map(auth =>
                            toSimpleAuthority(
                                auth,
                                this.currentOrganisation,
                                group
                            )
                        )
                    )
                    .reduce((acc, auths) => [...acc, ...auths], []);

                const sharedAuthorities = sharedAuthorityGroups
                    .filter(group => group.hasWorkflow)
                    .map(group =>
                        group.authorities.map(auth =>
                            toSimpleAuthority(auth, auth.organisation, group)
                        )
                    )
                    .reduce((acc, auths) => [...acc, ...auths], []);

                this.missionAuthorities = [
                    ...organisationAuthorities,
                    ...sharedAuthorities
                ];
                const missionAuthorityFilters = [
                    {
                        name: `This organisation only`,
                        children: [this.organisationsMissionFilter].concat(
                            this.missionAuthorities.map(a => ({
                                authorityIds: [a.id],
                                name: `Using ${this.formatAuthority.transform(
                                    a
                                )}`,
                                isPrimary: a.authorityType.isPrimary,
                                organisationId: this.currentOrganisation.id,
                                orOrganisationId: null,
                                filterType: 'SINGLE_AUTHORITY'
                            }))
                        ) as AuthorityFilter[]
                    },
                    {
                        name: `This and shared organisations`,
                        children: [
                            {
                                name: `All Missions`,
                                organisationId: null,
                                orOrganisationId: this.currentOrganisation.id,
                                authorityIds: organisationAuthorities.map(
                                    a => a.id
                                ),
                                filterType: 'ALL_AUTHORITIES'
                            } as AuthorityFilter
                        ].concat(
                            organisationAuthorities.map(a => ({
                                authorityIds: [a.id],
                                isPrimary: a.authorityType.isPrimary,
                                name: `Using ${this.formatAuthority.transform(
                                    a
                                )}`,
                                organisationId: null,
                                orOrganisationId: null,
                                filterType: 'SINGLE_AUTHORITY'
                            })) as AuthorityFilter[]
                        )
                    }
                ];
                this.organisationSelector.patchValue(
                    missionAuthorityFilters[0]
                );
                this.authoritySelector.patchValue(
                    missionAuthorityFilters[0].children[0]
                );
                return missionAuthorityFilters;
            }),
            shareReplay(),
            // unsubscribe on either component destroy or when an org changes since this function will be called again.
            takeUntil(merge(this.ngUnsubscribe$, this.organisationUpdated$))
        );
    }

    updateMissionAuthorityFilter() {
        if (this.currentOrganisation == null || !this.preferencesLoaded) {
            return;
        }

        this.preferencesService
            .updatePreferences(
                'missionListWidgetAuthorityFilter',
                this.currentOrganisation.id,
                this.missionAuthorityFilter
            )
            .pipe(
                // unsubscribe on either component destroy or when an org changes since this function will be called again.
                takeUntil(merge(this.ngUnsubscribe$, this.organisationUpdated$))
            )
            .subscribe({
                next: () => this.refreshMissions(),
                error: error =>
                    this.logging.error(
                        error,
                        'Updating mission authority preferences'
                    )
            })
            .add(this.workTracker.createTracker());
    }

    private watchReturnedMissionValues() {
        combineLatest([
            this.missionViewDataService.missions$,
            this.missionViewDataService.currentPage$,
            this.missionViewDataService.totalItems$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([missions, currentPage, totalItems]) => {
                this.updateMissionsList(missions);
                if (this.tableConfig != null) {
                    const currentConfig = this.tableConfig;
                    this.tableConfig = {
                        ...currentConfig,
                        currentPage: currentPage,
                        totalItems: totalItems
                    };
                }
            });
    }

    private refreshFlightLogs() {
        if (!this.canListMissions) {
            return;
        }
        this.flightLogsService
            .findCounts(this.currentOrganisation.id)
            .pipe(
                // unsubscribe on either component destroy or when an org changes since this function will be called again.
                takeUntil(merge(this.ngUnsubscribe$, this.organisationUpdated$))
            )
            .subscribe({
                next: counts => {
                    this.newFlightLogs =
                        counts.byStatus[
                            FlightLogFileDto.AssignmentStatus.UNASSIGNED
                        ];
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(error, 'Refreshing flight log counts')
            })
            .add(this.workTracker.createTracker());
    }

    private refreshMissionTypeFilter() {
        this.missionService
            .findMissionTypes(this.currentOrganisation.id)
            .pipe(
                // unsubscribe on either component destroy or when an org changes since this function will be called again.
                takeUntil(merge(this.ngUnsubscribe$, this.organisationUpdated$))
            )
            .subscribe({
                next: missionTypes => {
                    const missionTypeOptions = missionTypes.map(t => ({
                        value: t.id,
                        name: t.name
                    }));
                    this.availableColumns.find(
                        col => col.value === 'missionType.name'
                    ).searchOptions = missionTypeOptions;
                    this.missionOperationTypeFilters = missionTypeOptions;
                },
                error: error =>
                    this.logging.error(error, 'Refreshing mission types')
            })
            .add(this.workTracker.createTracker());
    }

    private loadPreferences() {
        if (!this.currentOrganisation) {
            return;
        }

        const refreshAuthorityFilter = forkJoin([
            this.missionAuthorityFilters$,
            this.preferencesService.findPreferencesAsOption<AuthorityFilter>(
                'missionListWidgetAuthorityFilter',
                this.currentOrganisation.id
            )
        ]).pipe(
            tap(([filter, preferences]) => {
                const allAuthorityFilters =
                    filter?.reduce(
                        (acc, v) => [...acc, ...v.children],
                        [] as AuthorityFilter[]
                    ) ?? [];
                this.missionAuthorityFilter = pipe(
                    preferences,
                    mapNullable<AuthorityFilter, AuthorityFilter>(selected =>
                        selected?.filterType === 'ALL_AUTHORITIES'
                            ? allAuthorityFilters?.find(
                                  f =>
                                      f.filterType === 'ALL_AUTHORITIES' &&
                                      f.organisationId ===
                                          selected.organisationId &&
                                      f.orOrganisationId ===
                                          selected.orOrganisationId
                              )
                            : allAuthorityFilters.find(f =>
                                  deepEqual(f, selected)
                              )
                    ),
                    getOrElse<AuthorityFilter>(
                        () => this.organisationsMissionFilter ?? null
                    )
                );

                // Find the appropriate mission authority filter objects and assign them in order so the required values are available
                const selectedOrg = filter.find(
                    o =>
                        o.children.filter(m => m == this.missionAuthorityFilter)
                            .length > 0
                );
                this.organisationSelector.patchValue(selectedOrg);
                this.authoritySelector.patchValue(this.missionAuthorityFilter);
                this.setupOrganisationAuthorityListeners();
            })
        );

        const refreshUserPreferences = () => {
            this.preferencesService
                .findPreferences(
                    'missionListWidgetColumns',
                    this.currentOrganisation.id
                )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(pref => {
                    this.userPreferences = {
                        selectedColumns: pref,
                        columnSorting: {
                            column: 'missionDate',
                            ascending: false
                        },
                        tableSearch: this.userPreferences.tableSearch
                    };
                    this.setupTableDefaults();
                });
        };

        return forkJoin([
            this.preferencesService.findPreferencesAsOption<TableSetupUserPreferences>(
                'missionListWidgetTable',
                this.currentOrganisation.id
            ),
            refreshAuthorityFilter
        ])
            .pipe(
                // unsubscribe on either component destroy or when an org changes since this function will be called again.
                takeUntil(merge(this.ngUnsubscribe$, this.organisationUpdated$))
            )
            .subscribe({
                next: ([preferences, _]) => {
                    if (preferences == null) {
                        refreshUserPreferences();
                    } else {
                        this.userPreferences =
                            getOrElse<TableSetupUserPreferences>(() => ({
                                selectedColumns: findDefaultColumnSelection(
                                    this.availableColumns
                                ),
                                columnSorting: null,
                                tableSearch: {
                                    combinedStatus:
                                        'DRAFT|SUBMITTED|READY_TO_FLY|PREPARED|FLYING|ON_SITE|DONE_FLYING|COMPLETED|READY_FOR_FINALISATION'
                                },
                                itemLimit: this.tableConfig.limit
                            }))(
                                pipe(
                                    preferences,
                                    optionMap(
                                        this.missionViewService.fixFilter(
                                            this.availableColumns
                                        )
                                    )
                                )
                            );

                        this.setupTableDefaults();
                    }
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        'Loading mission view preferences'
                    ),
                complete: () => {
                    this.onTableSearch(this.tableSearch);
                    this.preferencesLoaded = true;
                }
            })
            .add(this.workTracker.createTracker());
    }

    updateUserPreferences() {
        if (this.currentOrganisation == null || !this.preferencesLoaded) {
            return;
        }

        this.preferencesService
            .updatePreferences(
                'missionListWidgetTable',
                this.currentOrganisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    private setupTableDefaults() {
        this.selectedColumns = this.userPreferences.selectedColumns;
        this.tableSorting = this.userPreferences.columnSorting;
        this.gqlSorting =
            this.userPreferences.columnSorting == null
                ? null
                : {
                      column: this.missionViewService.parseFilterFieldNames(
                          this.userPreferences.columnSorting.column.toString()
                      ),
                      ascending: this.userPreferences.columnSorting.ascending
                  };
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit =
            this.userPreferences.itemLimit != null
                ? this.userPreferences.itemLimit
                : this.tableConfig.limit;
        this.changeDetector.detectChanges();
    }

    /**
     * The organisation authority control listeners should only be setup after
     * preferences have loaded to prevent invalid data being persisted
     */
    private setupOrganisationAuthorityListeners() {
        // Setup component cascade
        this.organisationSelectorValue$
            .pipe(
                // unsubscribe on either component destroy or when an org changes since this function will be called again.
                takeUntil(merge(this.ngUnsubscribe$, this.organisationUpdated$))
            )
            .subscribe(selectedOrganisation => {
                if (!!selectedOrganisation && !!selectedOrganisation.children) {
                    this.authoritySelector.setValue(
                        selectedOrganisation.children[0]
                    );
                }
            });

        observeFormControl<AuthorityFilter>(this.authoritySelector)
            .pipe(
                // unsubscribe on either component destroy or when an org changes since this function will be called again.
                takeUntil(merge(this.ngUnsubscribe$, this.organisationUpdated$))
            )
            .subscribe(authority => {
                if (!authority) {
                    return;
                }
                this.missionAuthorityFilter = authority;
                this.updateMissionAuthorityFilter();
            });
    }

    private setupTable() {
        const statusFilters = this.missionService.getCombinedStatusFilters();
        this.refreshMissionTypeFilter();

        const standardColumns: TableColumn[] = [
            {
                value: 'organisationName',
                name: 'Organisation',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'uid',
                name: 'UID',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'convertedDate',
                key: 'missionDate',
                name: 'Date/Time',
                searchable: 'daterange',
                selectable: true,
                defaultSelection: true,
                formatterFunction: t => this.formatDateTimePipe.transform(t),
                compareFunction: (a, b) => 0
                // searchFunction: () => () => true
            },
            {
                value: 'locationName',
                name: 'Location',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'combinedStatus',
                name: 'Status',
                searchable: true,
                selectable: true,
                sortable: false,
                defaultSelection: true,
                searchOptions: statusFilters,
                formatterFunction: s => this.combinedMissionStatus.transform(s)
            },
            {
                value: 'convertedRequestTime',
                key: 'missionApproval.requestTime',
                name: 'Request Date',
                searchable: 'daterange',
                selectable: true,
                sortable: false,
                defaultSelection: false,
                formatterFunction: t => this.formatDateTimePipe.transform(t),
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
                // searchFunction: () => () => true
            },
            {
                value: 'convertedResolutionTime',
                key: 'missionApproval.resolutionTime',
                name: 'Approval Date',
                searchable: 'daterange',
                selectable: true,
                sortable: false,
                defaultSelection: false,
                formatterFunction: t => this.formatDateTimePipe.transform(t),
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
                // searchFunction: () => () => true
            },
            {
                value: 'type',
                name: 'Mission Type',
                searchable: 'selection',
                selectable: true,
                sortable: false,
                defaultSelection: true,
                searchOptions: MISSION_TYPES,
                formatterFunction: t => this.formatMissionTypePipe.transform(t)
            },
            {
                value: 'missionType.name',
                name: 'Operation Type',
                searchable: false,
                // TODO: revise after API is updated
                // searchable: 'selection',
                selectable: true,
                sortable: false,
                defaultSelection: true,
                searchOptions: []
            },
            {
                value: 'rpic',
                name: 'RPIC',
                searchable: false,
                // searchable: 'text',
                selectable: false,
                defaultSelection: true,
                formatterFunction: p => this.formatPersonPipe.transform(p)
            },
            {
                value: 'missionCrewNames',
                name: 'Crew',
                searchable: false,
                // searchable: true,
                selectable: true,
                sortable: false,
                defaultSelection: true,
                formatterFunction: a =>
                    this.formatColumnArrayPipe.transform(a, 2)
            },
            {
                value: 'craftNicknames',
                name: 'RPA',
                searchable: true,
                selectable: true,
                sortable: false,
                defaultSelection: true,
                formatterFunction: a =>
                    this.formatColumnArrayPipe.transform(a, 1)
            },
            {
                value: 'missionWorkflowVersion.name',
                key: 'missionWorkflowVersion.name',
                name: 'Workflow',
                searchable: true,
                selectable: true,
                defaultSelection: true
            }
        ];

        const featureFlaggedColumns: TableColumn[] = [
            {
                value: 'flightConformanceResultList',
                name: 'Conformance',
                searchable: true,
                selectable: true,
                sortable: false,
                defaultSelection: true,
                searchOptions: flightConformanceParsedStates
            }
        ];

        this.availableColumns = this.useFeatureFlaggedColumns
            ? [...standardColumns, ...featureFlaggedColumns]
            : standardColumns;
        this.tableConfig = {
            currentPage: 0,
            limit: 10,
            serverPagination: true,
            actions: this.canAddMission
                ? [
                      {
                          action: (item: WidgetMission) =>
                              this.cloneMission(item),
                          icon: 'fal fa-clone',
                          tooltip: 'Clone Mission'
                      }
                  ]
                : [],
            limitSelection: [10, 25, 50, 100]
        };

        this.tableSorting = this.tableSorting ?? null;

        this.tableSearch = this.tableSearch ?? null;

        this.selectedColumns = this.selectedColumns ?? null;

        this.tableConfig.currentPage = 0;

        this.refreshMissions();
        this.refreshFlightLogs();
    }

    showMission(mission: MissionSummaryDto) {
        this.router.navigate(['mission', mission?.id], {
            queryParamsHandling: 'preserve',
            relativeTo: this.activatedRoute
        });
    }

    cloneMission(mission: WidgetMission) {
        this.angulartics2.eventTrack.next({
            action: 'dashboard-mission-clone'
        });
        this.missionService
            .findMission(mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                loadedMission =>
                    this.missionDialoguesService.showMissionEditor(
                        this.missionService.cloneMission(loadedMission),
                        this.currentOrganisation,
                        mission.id
                    ),
                (error: FlyFreelyError) =>
                    this.toastr.error(
                        `Error while fetching mission to clone: ${error.message}`
                    )
            );
    }

    showMissionReportsDialogue() {
        const authorityIds =
            this.missionAuthorityFilter.authorityIds == null ||
            this.missionAuthorityFilter.authorityIds.length === 0
                ? this.missionAuthorities.map(a => a.id)
                : this.missionAuthorityFilter.authorityIds;
        this.missionAuthorityFilters$
            .pipe(take(1))
            .subscribe(missionAuthorityFilters => {
                this.missionDialoguesService.showMissionReportsDialogue(
                    this.missionAuthorityFilter.organisationId,
                    this.missionAuthorityFilter.orOrganisationId,
                    authorityIds,
                    this.canFilterByAuthority,
                    missionAuthorityFilters,
                    this.missionAuthorityFilter
                );
            });
    }

    showMissionCalendar() {
        const dialogue =
            this.missionDialoguesService.showMissionCalendarDialogue(
                this.missionViewDataService.calendarMissions$.pipe(
                    map(missions =>
                        missions.map(m => ({
                            ...m,
                            missionCrewNames: this.missionViewService.findCrew(
                                m.missionCrewDetails
                            ),
                            rpic: findRpic(m.missionCrewDetails),
                            combinedStatus:
                                this.missionService.getCombinedStatus(m),
                            convertedDate: moment(m.missionDate).tz(m.timeZone),
                            convertedRequestTime: m.missionApproval?.requestTime
                                ? moment(m.missionApproval.requestTime)
                                : null,
                            convertedResolutionTime: m.missionApproval
                                ?.resolutionTime
                                ? moment(m.missionApproval.resolutionTime)
                                : null
                        }))
                    )
                )
            );
        dialogue.content.showMission
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(dialogue.onHidden))
            .subscribe(mission => this.showMission(mission));

        dialogue.content.updateMissions
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(dialogue.onHidden))
            .subscribe(({ startTime, endTime }) =>
                this.refreshCalendarMissions(startTime, endTime)
            );
        dialogue.content.refreshMissions();
    }

    showAddLocation() {
        this.angulartics2.eventTrack.next({
            action: 'dashboard-airspace-check',
            properties: {
                category: `airspace-check`
            }
        });
        this.locationDialoguesService.newLocation(
            this.currentOrganisation.id,
            LocationDto.Type.MISSION,
            true
        );
    }

    onTableSearch(search: Record<string, unknown>) {
        this.tableSearch = search;
        this.missionStatusFilters = null;
        // Build table filters with standard fields, filtering out fields that require extra parsing
        const filters: GqlFilterField[] =
            search == null || Object.keys(search).length === 0
                ? []
                : Object.keys(search)
                      .filter(
                          k =>
                              k !== 'missionDate' &&
                              k !== 'missionApproval.requestTime' &&
                              k !== 'missionApproval.resolutionTime' &&
                              k !== 'combinedStatus' &&
                              k !== 'missionType.name' &&
                              k !== 'flightConformanceResultList'
                      )
                      .filter(
                          k =>
                              this.availableColumns.find(c => c.value === k)
                                  .searchable
                      )
                      .filter(k => search[k] != null && search[k] !== '')
                      .map(k => ({
                          field: this.missionViewService.parseFilterFieldNames(
                              k
                          ),
                          filter: search[k]?.toString() ?? ''
                      }));
        if (
            Array.isArray(search?.missionDate) &&
            search.missionDate.length !== 0
        ) {
            this.filterStartTime = search.missionDate[0];
            this.filterEndTime = search.missionDate[1];
        } else {
            this.filterStartTime = null;
            this.filterEndTime = null;
        }
        const missionDateFilters: GqlFilterField[] = this.missionViewService
            .getDateFilterValue(
                search,
                'missionApproval.requestTime',
                'missionApprovalRequestTime'
            )
            .concat(
                this.missionViewService.getDateFilterValue(
                    search,
                    'missionApproval.resolutionTime',
                    'missionApprovalResolutionTime'
                )
            );

        const isDummyFilter: GqlFilterField[] = this.showDummyData
            ? []
            : [
                  {
                      field: 'isDummy',
                      filter: 'false'
                  }
              ];

        const missionType =
            search != null
                ? search[
                      Object.keys(search).find(
                          k => k.toString() === 'missionType.name'
                      )
                  ]
                : null;
        const missionOperationTypeFilter: GqlFilterField[] =
            missionType != null
                ? [
                      {
                          field: 'missionOperationType',
                          filter: this.missionOperationTypeFilters?.find(
                              o => o.value === missionType
                          )?.name
                      }
                  ]
                : [];

        const conformanceResult =
            search != null
                ? search[
                      Object.keys(search).find(
                          k => k.toString() === 'flightConformanceResultList'
                      )
                  ]
                : null;
        this.graphQLFilters = {
            ...this.graphQLFilters,
            conformanceResultStatus: toConformanceResultStatusArray(
                conformanceResult as FlightConformanceResultDto.Status
            )
        };

        this.missionStatusFilters =
            this.missionViewService.updateMissionStatusFilters(search);

        const additionalFilters: GqlFilterField[] = missionDateFilters
            .concat(isDummyFilter)
            .concat(this.missionViewService.filterCombinedStatus(search))
            .concat(missionOperationTypeFilter);

        this.tableFilters = filters.concat(additionalFilters);

        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        if (this.tableConfig != null) {
            this.tableConfig.currentPage = 0;
        }
        // This is needed because the static table uses onPush change detection
        // Whenever a query repeats a previous output there is a chance the table won't update without explicit change detection
        this.changeDetector.detectChanges();
        this.refreshMissions();

        this.updateUserPreferences();
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.refreshMissions();
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            !this.selectedColumns.includes(
                this.userPreferences.columnSorting?.column
            )
        ) {
            this.tableSorting = null;
            this.gqlSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.tableSorting || null
        };
        this.refreshMissions();
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.tableSorting = sorting;
        this.gqlSorting =
            sorting == null
                ? null
                : {
                      column: this.missionViewService.parseFilterFieldNames(
                          sorting.column.toString()
                      ),
                      ascending: sorting.ascending
                  };
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.tableConfig.currentPage = 0;
        this.refreshMissions();
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.refreshMissions();
        this.updateUserPreferences();
    }

    parseConformanceStatus(conformances: FlightConformanceResultDto[]) {
        if (conformances == null || conformances.length === 0) {
            return;
        }
        return findMostPressingConformance(conformances);
    }
}
