import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { Injectable, InjectionToken } from '@angular/core';

/**
 * An injection token to set which frontend is being used.
 * Can be injected in components that only use specific elements or services for specific frontends.
 */
export const FRONTEND_APP = new InjectionToken<Frontend>('frontend');
export type Frontend = 'user' | 'admin' | 'webview' | 'airspace-checker';

/**
 * A customisation of the default date picker config.
 */
@Injectable()
export class FlyFreelyDatepickerConfig extends BsDatepickerConfig {
    dateInputFormat = 'DD/MM/YYYY';
    rangeInputFormat = 'DD/MM/YYYY';
    adaptivePosition = true;
    /* FIXME: currently there is a bug forcing us to split up the datepicker and timepicker into two form controls in places like the mission editor since the datepicker is setting the time to system time. the "initCurrentTime" value below is supposed to prevent this, but it is currently bugged. Once a fix is available and we are on the correct Bootstrap version we need to revert to only using one date field for both the datepicker and the timepicker and add the relevant properties to prevent setting the time to system time here */
    withTimepicker = false;
    initCurrentTime = true;
}

@Injectable()
export class FlyFreelyDaterangepickerConfig extends FlyFreelyDatepickerConfig {
    displayMonths = 2;
}

@Injectable()
export class FlyFreelyTooltipConfig extends TooltipConfig {
    adaptivePosition = false;
    placement = 'left';
    triggers = 'hover focus';
    container = 'body';
    delay = 0;
}

@Injectable()
export class FlyFreelyPopoverConfig extends PopoverConfig {
    adaptivePosition = false;
    container = 'body';
}
