import { Pipe, PipeTransform } from '@angular/core';
import { missionApprovalStatuses } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '../helpers';

@Pipe({ name: 'formatApprovalStatus' })
export class FormatApprovalStatusPipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor() {
        this.transform = setupStatusFormatter(missionApprovalStatuses);
    }
}
