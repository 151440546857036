<ng-container *ngTemplateOutlet="currentView"></ng-container>
<button
    *ngIf="isLocked && message != null && message.length > 0"
    type="button"
    class="btn btn-inline"
    [tooltip]="message"
    triggers="focus hover"
    placement="top"
>
    <span class="fal fa-info-circle"></span>
</button>
