import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    BatteryDto,
    BatteryService,
    BatterySetDto,
    BatterySetService,
    Cancellable,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LockedBatterySetFields,
    PersonsOrganisationDto,
    UpdateBatterySetCommand,
    WorkTracker,
    fromLocalDate,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import * as moment from 'moment';
import { Subject, combineLatest } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'battery-set-edit',
    templateUrl: './battery-set-edit.component.html'
})
export class BatterySetEdit implements OnInit, OnDestroy, Cancellable {
    @Input() currentOrganisation: PersonsOrganisationDto;
    @Input() batterySet: BatterySetDto;

    @Output() updated = new EventEmitter<BatterySetDto>();
    @Output() cancelled = new EventEmitter<void>();

    private ngUnsubscribe$: Subject<void> = new Subject();

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    batterySetForm: FormGroup;
    error: any = null;
    lockedFields: LockedBatterySetFields;

    // Available batteries
    private batteries = new Subject<BatteryDto[]>();
    availableBatteries: BatteryDto[];

    constructor(
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.batterySetForm = new FormGroup({
            name: new FormControl(this.batterySet.name, [Validators.required]),
            assemblyDate: new FormControl(
                this.batterySet.assemblyDate
                    ? fromLocalDate(this.batterySet.assemblyDate)
                    : null,
                [Validators.required]
            ),
            disassemblyDate: new FormControl(
                this.batterySet.disassemblyDate
                    ? fromLocalDate(this.batterySet.disassemblyDate)
                    : null
            ),
            batteries: new FormArray(
                this.batterySet.batteries.map(b => new FormControl(b))
            )
        });

        this.lockedFields = this.batterySet.lockedFields;
        combineLatest([
            this.batteries,
            this.batterySetForm.valueChanges.pipe(
                startWith(this.batterySetForm.value)
            )
        ])
            .pipe(
                map(([batteries, value]) => {
                    const selectedIds = value.batteries.map(
                        (b: BatteryDto) => b.id
                    );
                    const batteryTypeId =
                        value.batteries.length > 0
                            ? value.batteries[0].batteryTypeId
                            : null;
                    return batteries.filter(
                        b =>
                            moment(b.purchaseDate).isSameOrBefore(
                                value.assemblyDate
                            ) &&
                            selectedIds.indexOf(b.id) === -1 &&
                            (batteryTypeId === null ||
                                b.batteryTypeId === batteryTypeId)
                    );
                })
            )
            // This is done because *ngFor | async isn't working as expected
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(batteries => (this.availableBatteries = batteries));

        this.refreshBatteries();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshBatteries() {
        this.batteryService
            .findBatteries(this.currentOrganisation.id, 'SERVICEABLE')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteries => {
                    this.batteries.next(batteries);
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while loading batteries: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    extractId(battery: BatteryDto) {
        return battery.id;
    }

    showBatteryDetails(template: any, battery: BatteryDto) {
        // this.battery = battery;
        // this.modalRef = this._bsModalService.show(template, {
        //     keyboard: false,
        //     class: 'modal-lg'
        // });
        // this.escCount--;
    }

    addBattery(battery: BatteryDto) {
        (<FormArray>this.batterySetForm.controls.batteries).push(
            new FormControl(battery)
        );
        this.batterySetForm.updateValueAndValidity();
    }

    removeBattery(ix: number) {
        (<FormArray>this.batterySetForm.controls.batteries).removeAt(ix);
    }

    submit() {
        if (!this.doTypesMatch()) {
            this.error = 'Batteries that are selected are of different types';
            return;
        }
        const {
            name,
            assemblyDate,
            disassemblyDate,
            batteries
        } = this.batterySetForm.value;

        const payload: UpdateBatterySetCommand = {
            name: name,
            assemblyDate: toLocalDate(assemblyDate),
            disassemblyDate: toLocalDate(disassemblyDate),
            batteryIds: batteries.map(this.extractId)
        };

        this.batterySetService
            .updateBatterySet(this.batterySet.id, payload)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    this.logging.success(`Battery set updated`);
                    this.updated.emit(results);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error occurred while saving battery set: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    doTypesMatch() {
        const batteries: BatteryDto[] = this.batterySet.batteries;
        const batteryResult = batteries.reduce((a, b) => {
            return a.batteryTypeId === b.batteryTypeId ? a : null;
        });
        const dummyResult = batteries.reduce((a, b) => {
            return a.isDummy === b.isDummy ? a : null;
        });
        if (batteryResult && dummyResult) {
            return true;
        } else {
            return false;
        }
    }

    canAddBattery(battery: BatteryDto) {
        const firstBatteryInSet = this.batterySet.batteries[0];
        if (firstBatteryInSet != null) {
            if (
                firstBatteryInSet.batteryTypeId === battery.batteryTypeId &&
                firstBatteryInSet.isDummy === battery.isDummy
            ) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancel() {
        if (this.batterySetForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    cancelEdit() {
        this.cancelled.emit();
    }
}
