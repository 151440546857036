import {
    Component,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    BatteryDto,
    BatteryService,
    BatterySetService,
    CreateBatterySetCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationService,
    PersonsOrganisationDto,
    toLocalDate,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { ModelItem } from 'libs/rpa/src/lib/details/rpa-edit/rpa-edit.component';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BatteryDialoguesService } from '../../battery-dialogues.service';

@Component({
    selector: 'battery-set-add-dialogue',
    templateUrl: './battery-set-add-dialogue.component.html'
})
export class BatterySetAddDialogue implements OnInit, OnDestroy {
    @Input() organisationId: number;

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    batterySetForm: FormGroup;
    searchGroup: FormGroup;
    batteryTypeItems: ModelItem[];

    organisation: PersonsOrganisationDto;

    error: any = null;

    // Available batteries
    private batteries = new Subject<BatteryDto[]>();
    availableBatteries: BatteryDto[];
    displayableBatteries: BatteryDto[];

    constructor(
        private modal: BsModalRef<BatterySetAddDialogue>,
        private logging: FlyFreelyLoggingService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private commonDialoguesService: CommonDialoguesService,
        @Inject(forwardRef(() => BatteryDialoguesService))
        private batteryDialoguesService: BatteryDialoguesService,
        private organisationService: OrganisationService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.batterySetForm = new FormGroup({
            name: new FormControl(undefined, [Validators.required]),
            assemblyDate: new FormControl(undefined, [Validators.required]),
            batteries: new FormArray([], [Validators.required])
        });

        this.searchGroup = new FormGroup({
            search: new FormControl(undefined)
        });
    }

    ngOnInit() {
        this.searchGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(search => {
                this.updateAvailableBatteries();
            });

        combineLatest([this.batteries, this.batterySetForm.valueChanges])
            .pipe(
                map(([batteries, value]) => {
                    const selectedIds = value.batteries.map(
                        (b: BatteryDto) => b.id
                    );
                    const batteryTypeId =
                        value.batteries.length > 0
                            ? value.batteries[0].batteryTypeId
                            : null;
                    return batteries.filter(
                        b =>
                            moment(b.purchaseDate).isSameOrBefore(
                                value.assemblyDate
                            ) &&
                            selectedIds.indexOf(b.id) === -1 &&
                            (batteryTypeId === null ||
                                b.batteryTypeId === batteryTypeId)
                    );
                })
            )
            // This is done because *ngFor | async isn't working as expected
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(batteries => {
                this.availableBatteries = batteries;
                this.displayableBatteries = batteries;
            });
        this.refreshBatteries();
        this.refreshOrganisation();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshOrganisation() {
        this.organisationService
            .findByIdForUser(this.organisationId, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(org => (this.organisation = org))
            .add(this.workTracker.createTracker());
    }

    refreshBatteries() {
        this.batteryService
            .findBatteries(this.organisationId, 'SERVICEABLE')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteries => {
                    this.batteries.next(batteries);
                },
                error: error => this.logging.error(error)
            })
            .add(this.workTracker.createTracker());
    }

    extractId(battery: BatteryDto) {
        return battery.id;
    }

    addBattery(battery: BatteryDto) {
        const available = this.availableBatteries;
        (<FormArray>this.batterySetForm.controls.batteries).push(
            new FormControl(battery)
        );
        this.availableBatteries = available;
        this.updateAvailableBatteries();
    }

    removeBattery(ix: number) {
        const available = this.availableBatteries;
        (<FormArray>this.batterySetForm.controls.batteries).removeAt(ix);
        this.availableBatteries = available;
        this.updateAvailableBatteries();
    }

    updateAvailableBatteries() {
        const searchValue = (
            this.searchGroup.get('search').value ?? ''
        ).toLowerCase();
        const addedBatteries = (<FormArray>(
            this.batterySetForm.controls.batteries
        )).value;
        this.displayableBatteries = this.availableBatteries.filter(
            batt =>
                batt.name.toLowerCase().includes(searchValue) ||
                ((batt.manufacturerSerialNumber ?? '')
                    .toLowerCase()
                    .includes(searchValue) &&
                    !addedBatteries.includes(batt))
        );
    }

    hasSearchValue() {
        return (
            this.searchGroup.controls.search.value &&
            this.searchGroup.controls.search.value.length > 0
        );
    }

    clearSearch() {
        this.searchGroup.controls.search.patchValue('');
    }

    submit() {
        const { name, assemblyDate, batteries } = this.batterySetForm.value;

        const payload: CreateBatterySetCommand = {
            organisationId: this.organisationId,
            name: name,
            assemblyDate: toLocalDate(assemblyDate),
            batteryIds: batteries.map(this.extractId)
        };

        const doneWorking = this.workTracker.createTracker();

        this.batterySetService.createBatterySet(payload).then(
            results => {
                this.logging.success('Battery set created');
                this.batterySetForm.markAsPristine();
                this.modal.hide();
                doneWorking();
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while saving battery set: ${error.message}`
                );
                doneWorking();
            }
        );
    }

    showBatteryDetails(battery: BatteryDto) {
        this.batteryDialoguesService.showBatteryDetailsDialogue(
            battery,
            this.organisation
        );
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancel() {
        if (this.batterySetForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    cancelEdit() {
        this.modal.hide();
    }
}
