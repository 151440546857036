import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
    CurrentPersonDto,
    DashboardService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LoggedInUser,
    NotFound,
    UserService,
    UserStatus,
    WorkTracker,
    OnboardingService
} from '@flyfreely-portal-ui/flyfreely';
import {
    SelectedOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import {
    fadeInExpandOnEnterAnimation,
    fadeOutCollapseOnLeaveAnimation
} from 'angular-animations';
import { NotificationDialoguesService } from 'libs/notifications/src/lib/notifications-dialogues.service';
import { SetupGuideChecklistService } from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    animations: [
        fadeInExpandOnEnterAnimation(),
        fadeOutCollapseOnLeaveAnimation()
    ],
    styleUrls: [`./dashboard.component.scss`]
})
export class DashboardComponent {
    isSystemAdmin = false;
    showDynamicHelpEdit = false;

    showAlternativeSupport = false;

    systemNotice?: string = undefined;

    currentUser?: CurrentPersonDto;
    currentOrganisation: SelectedOrganisation = { type: 'loading' };

    working: boolean = false;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private sharedService: WorkspaceStateService,
        private notificationDialoguesService: NotificationDialoguesService,
        private dashboardService: DashboardService,
        private router: Router,
        private setupGuideChecklistService: SetupGuideChecklistService,
        private deviceDetectorService: DeviceDetectorService,
        private onboardingService: OnboardingService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        combineLatest([
            this.userService.userChange$.pipe(
                filter<LoggedInUser>(c => c.type === UserStatus.LOGGED_IN)
            ),
            this.sharedService.currentOrganisation$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([changes, organisation]) => {
                this.currentUser = changes.currentUser;
                this.currentOrganisation = organisation;
                this.maybeShowMobileWarning();
            });
    }
    showingWidgets$ = this.sharedService.availableWidgets$.pipe(
        takeUntil(this.ngUnsubscribe$),
        map(widgets =>
            this.sharedService.registeredWidgets.registeredWidgets.filter(
                w =>
                    widgets[w.widgetIdentifier].available &&
                    widgets[w.widgetIdentifier].selected
            )
        )
    );

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private maybeShowWhatsNew() {
        if (this.currentOrganisation.type !== 'organisation_loaded') {
            return;
        }

        this.userService
            .findLatestWhatsNew()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: whatsNew => {
                    if (whatsNew.changes.length > 0) {
                        const whatsNewModal =
                            this.notificationDialoguesService.showWhatsNew(
                                whatsNew
                            );

                        whatsNewModal.onHidden
                            .pipe(take(1))
                            .subscribe(() =>
                                this.setupGuideChecklistService.readyToShowStartHere()
                            );
                    } else {
                        this.setupGuideChecklistService.readyToShowStartHere();
                    }
                    this.systemNotice = whatsNew.systemNotice;
                },
                error: (error: FlyFreelyError) => {
                    this.setupGuideChecklistService.readyToShowStartHere();
                    if (error instanceof NotFound) {
                        // do nothing
                    }
                }
            });
    }

    showAllWhatsNew() {
        this.notificationDialoguesService.showAllWhatsNew();
    }

    private maybeShowMobileWarning() {
        // Ensure the mobile warning modal is shown first before checking what's new
        if (
            this.deviceDetectorService.isMobile() ||
            (this.deviceDetectorService.isTablet() &&
                this.deviceDetectorService.orientation.toLowerCase() ===
                    'portrait')
        ) {
            const modal = this.notificationDialoguesService.showMobileWarning();
            modal.onHidden
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(() => this.maybeShowWhatsNew());
        } else {
            this.maybeShowWhatsNew();
        }
    }

    showCreateCommercialOrganisation() {
        if (
            this.currentOrganisation.type === 'no_access' ||
            this.currentOrganisation.type === 'loading'
        ) {
            this.dashboardService.shouldReturnToDashboard(true);
        } else {
            this.dashboardService.shouldReturnToDashboard(
                true,
                this.currentOrganisation.organisation.id
            );
        }
        this.router.navigate(['onboarding']);
    }

    requestAccess() {
        this.onboardingService
            .requestOrganisationAccess()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.logging.success('Organisation access requested');
            });
    }

    logout() {
        this.userService.logout();
    }
}
