import { Pipe, PipeTransform } from '@angular/core';
import { CraftDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'rpaStatusBlock' })
export class RpaStatusBlockPipe implements PipeTransform {
    transform(status: CraftDto.Status) {
        switch (status) {
            case CraftDto.Status.SERVICEABLE:
                return 'active';
            case CraftDto.Status.UNSERVICEABLE:
                return 'inactive';
            case CraftDto.Status.RETIRED:
                return 'disabled';
            case CraftDto.Status.UNDER_MAINTENANCE:
                return 'pending';
            default:
                return `disabled`;
        }
    }
}
