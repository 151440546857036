import MapboxDraw, { MapMouseEvent } from '@mapbox/mapbox-gl-draw';
import { center } from '@turf/center';
import { circle } from '@turf/circle';
import { length } from '@turf/length';
import { FeatureProperties, MapModes } from '../constants';
import { getMeasurementDescriptionForKms } from '../utils';

const DrawMeasurementRadius = { ...MapboxDraw.modes.draw_polygon };

class MeasurementRadiusState {
    polygon: any; // DrawFeature
    line: any; // DrawFeature
    currentVertexPosition: number;
    direction: string;
    centerCoordinates: number[];
    isDragging: boolean;
}

DrawMeasurementRadius.onSetup = function (opts: { featureId?: any }) {
    opts = opts || {};
    const featureId = opts.featureId;
    let polygon, line, currentVertexPosition, centerCoordinates;
    if (featureId) {
        polygon = this.getFeature(featureId);
        line = this.getFeature(polygon.properties.relatedFeatureIds[0]);
        currentVertexPosition = 1;
        centerCoordinates = center(polygon).geometry.coordinates;
    } else {
        currentVertexPosition = 0;
        polygon = this.newFeature({
            type: MapboxDraw.constants.geojsonTypes.FEATURE,
            properties: {
                meta: FeatureProperties.MEASUREMENT_RADIUS
            },
            geometry: {
                type: MapboxDraw.constants.geojsonTypes.POLYGON,
                coordinates: [[]]
            }
        });
        this.addFeature(polygon);
        line = this.newFeature({
            type: 'Feature',
            properties: {
                meta: FeatureProperties.MEASUREMENT_RADIUS_LINE,
                measurement: ``
            },
            geometry: {
                type: 'LineString',
                coordinates: []
            }
        });
        this.addFeature(line);

        polygon.properties.relatedFeatureIds = [line.id];
        line.properties.relatedFeatureIds = [polygon.id];
        this.clearSelectedFeatures();
    }
    return {
        polygon,
        line,
        currentVertexPosition,
        centerCoordinates
    };
};
DrawMeasurementRadius.onDrag = function (
    state: MeasurementRadiusState,
    e: MapMouseEvent
) {
    state.isDragging = true;
    this.onMouseMove(state, e);
};

DrawMeasurementRadius.onMouseUp = function (
    state: MeasurementRadiusState,
    e: { lngLat: { lng: any; lat: any } }
) {
    if (state.isDragging) {
        return this.changeMode(MapModes.SIMPLE_SELECT as MapboxDraw.DrawMode);
    }
};

DrawMeasurementRadius.onMouseMove = function (
    state: MeasurementRadiusState,
    e: { lngLat: { lng: any; lat: any } }
) {
    if (state.currentVertexPosition === 1) {
        // update measurement line
        state.line.updateCoordinate(
            state.currentVertexPosition,
            e.lngLat.lng,
            e.lngLat.lat
        );

        // update circle
        const lineGeoJson = state.line.toGeoJSON();
        const distance = length(lineGeoJson);

        const circleFeature = circle(state.centerCoordinates, distance);
        state.polygon.incomingCoords(circleFeature.geometry.coordinates);
        state.polygon.properties.center = state.centerCoordinates;
        state.polygon.properties.radiusInKm = distance;

        state.line.properties.measurement =
            getMeasurementDescriptionForKms(distance);
    }
};

DrawMeasurementRadius.toDisplayFeatures = function (
    state: MeasurementRadiusState,
    geojson: any,
    display: (arg0: any) => void
) {
    display(geojson);
};

// @ts-ignore: not in the current type
DrawMeasurementRadius.clickAnywhere = function (
    state: MeasurementRadiusState,
    e: { lngLat: { lng: any; lat: any } }
) {
    if (state.currentVertexPosition === 0) {
        state.currentVertexPosition++;
        const center = [e.lngLat.lng, e.lngLat.lat];
        state.centerCoordinates = center;

        state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
        state.line.addCoordinate(1, e.lngLat.lng, e.lngLat.lat);
    } else if (state.currentVertexPosition === 1) {
        return this.changeMode(MapModes.SIMPLE_SELECT);
    }
};
export default DrawMeasurementRadius;
