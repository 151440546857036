import { Injectable, Provider } from '@angular/core';
import {
    AssignMaintenanceScheduleCommand,
    BatteryService,
    MaintenanceScheduleAssignmentDto
} from '@flyfreely-portal-ui/flyfreely';
import { ResourceMaintenanceService } from 'libs/maintenance/src/lib/resource-maintenance.service';
import { Observable } from 'rxjs';

@Injectable()
export class BatteryMaintenanceService /* implements ResourceMaintenanceService */ {
    private batteryId: number;

    constructor(private batteryService: BatteryService) {}

    setup(batteryId: number) {
        this.batteryId = batteryId;
    }

    assign(
        command: AssignMaintenanceScheduleCommand
    ): Observable<MaintenanceScheduleAssignmentDto> {
        return this.batteryService.assignMaintenanceSchedule(this.batteryId, command);
    }
}

/**
 * Provider for the RPA maintenance service. Required because we need to "provide"
 * the base class.
 */
export const batteryMaintenanceServiceProvider: Provider = {
    provide: ResourceMaintenanceService,
    useClass: BatteryMaintenanceService
};
