import { AttachmentHandler } from './attachments';
import {
    FormSummaryDto,
    FormDto,
    ConcreteFormDto,
    UpdateFormVersionCommand,
    CreateFormCommand,
    CloneFormCommand,
    CloneFormVersionCommand,
    FormVersionDto
} from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

/**
 * The form service provides methods for access form structures,
 * and storing data associated with the forms.
 * @ngInject
 */
@Injectable({
    providedIn: 'root'
})
export class FormService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findForms(organisationId: number) {
        return this.http.get<FormSummaryDto[]>(`${this.baseUrl}/webapi/forms`, {
            params: new HttpParams().set(
                'organisationId',
                organisationId != null ? organisationId.toString() : ''
            )
        });
    }

    findForm(formId: number) {
        return this.http.get<FormDto>(`${this.baseUrl}/webapi/forms/${formId}`);
    }

    findConcreteForm(formId: number, managingOrganisationId: number) {
        return this.http.get<ConcreteFormDto>(
            `${this.baseUrl}/webapi/forms/${formId}/concrete`,
            {
                params: new HttpParams().set(
                    'managingOrganisationId',
                    managingOrganisationId != null
                        ? managingOrganisationId.toString()
                        : ''
                )
            }
        );
    }

    findAllForms() {
        return this.http.get<FormSummaryDto[]>(
            `${this.baseUrl}/adminapi/forms`
        );
    }

    updateForm(formId: number, formDetails: UpdateFormVersionCommand) {
        return this.http
            .put<FormDto>(`${this.baseUrl}/webapi/forms/${formId}`, formDetails)
            .pipe(tap(() => this.changeSource.next()));
    }

    renameForm(formId: number, renameFormCommand: any) {
        return this.http
            .put<FormDto>(
                `${this.baseUrl}/webapi/forms/${formId}/name`,
                renameFormCommand
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    changeActiveVersion(formId: number, activeVersionCommand: any) {
        return this.http
            .put<FormDto>(
                `${this.baseUrl}/webapi/forms/${formId}/active`,
                activeVersionCommand
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    changeOwner(formId: number, organisationId: number) {
        return this.http
            .put<FormDto>(`${this.baseUrl}/adminapi/forms/${formId}/owner`, {
                organisationId
            })
            .pipe(tap(() => this.changeSource.next()));
    }

    createForm(formDetails: CreateFormCommand) {
        return this.http
            .post<FormDto>(`${this.baseUrl}/webapi/forms`, formDetails)
            .pipe(tap(() => this.changeSource.next()));
    }

    archiveForm(formId: number) {
        return this.http
            .put<FormDto>(
                `${this.baseUrl}/webapi/forms/${formId}/archive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    unarchiveForm(formId: number) {
        return this.http
            .put<FormDto>(
                `${this.baseUrl}/webapi/forms/${formId}/unarchive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    attachmentHandler(formId: number, managingOrganisationId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/forms/${formId}/attachments`,
            false,
            managingOrganisationId
        );
    }

    cloneForm(command: CloneFormCommand) {
        return this.http
            .post<FormDto>(
                `${this.baseUrl}/webapi/forms/clone`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    cloneFormVersion(formId: number, command: CloneFormVersionCommand) {
        return this.http
            .post<FormVersionDto>(
                `${this.baseUrl}/webapi/forms/${formId}/versions/clone`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
