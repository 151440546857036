import { Directive, Injectable } from '@angular/core';
import { Feature, FeatureCollection } from 'geojson';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FeatureProperties, MapModes } from './constants';
import DrawMeasurementRadius from './draw-modes/draw-measurement-radius';
import DrawMeasurementRadiusSelect from './draw-modes/draw-measurement-radius-select';
import DrawSingleMeasurementLine from './draw-modes/draw-single-measurement-line';
import DrawSingleMeasurementLinePointSelect from './draw-modes/draw-single-measurement-line-point-select';
import DrawSingleMeasurementLineSelect from './draw-modes/draw-single-measurement-line-select';
import {
    ActiveInteractionChangedEventData,
    MapDrawSelectionChangeEvent,
    MapDrawUpdatedEvent,
    MapEventType,
    MapInteractionState,
    MapMouseDownEvent
} from './events';
import { ActiveInteractionChangedMapEvent } from './events/active-interaction-changed-map-event';
import { FlyFreelyMapComponent } from './flyfreely-map/flyfreely-map.component';
import { FeatureAndGroup, MapGeometry } from './interfaces';
import { MapService } from './map.service';
import { getMeasurementProperties } from './utils';

@Directive({ selector: 'map-measurement-interaction' })
export class MapMeasurementInteractionDirective {
    private ngUnsubscribe$ = new Subject<void>();
    private isMeasurementActive = false;

    constructor(
        private mapService: MapService,
        private mapComponent: FlyFreelyMapComponent
    ) {
        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event =>
                        event.type === MapEventType.activeInteractionChanged
                )
            )
            .subscribe((event: ActiveInteractionChangedMapEvent) => {
                this.activeInteractionChanged(event);
            });

        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event => event.type === MapEventType.mapDrawSelectionChanged
                )
            )
            .subscribe((event: MapDrawSelectionChangeEvent) => {
                this.onDrawSelectionChange(event);
            });

        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event =>
                        this.isMeasurementActive &&
                        event.type === MapEventType.mouseDown
                )
            )
            .subscribe((event: MapMouseDownEvent) => {
                this.mouseDownEvent(event);
            });
        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event =>
                        this.isMeasurementActive &&
                        event.type === MapEventType.mapDrawUpdated
                )
            )
            .subscribe((event: MapDrawUpdatedEvent) => {
                this.mapDrawUpdated(event);
            });

            mapComponent.registerDrawModes({
                draw_single_line: DrawSingleMeasurementLine,
                draw_single_line_select: DrawSingleMeasurementLineSelect,
                draw_single_line_point_select: DrawSingleMeasurementLinePointSelect,
                draw_radius: DrawMeasurementRadius,
                draw_radius_select: DrawMeasurementRadiusSelect,
            });
    }

    ngOnDestroy(): any {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private mapDrawUpdated(event: MapDrawUpdatedEvent) {
        const existingFeatureGroup = this.mapComponent.features.filter(g =>
            g.existingFeatures.some(f => f.id === event.payload.features[0].id)
        );
        let groupId = 1;
        if (existingFeatureGroup && existingFeatureGroup.length > 0) {
            groupId = existingFeatureGroup[0].id;
        }
        const featureGroups = event.payload.features.map(f => {
            const group: FeatureAndGroup = {
                groupId: groupId,
                feature: {
                    id: f.id as number,
                    name: f.properties.name,
                    geom: f.geometry as MapGeometry
                }
            };
            return group;
        });
        featureGroups.forEach(fg => this.mapComponent.featuresUpdated.next(fg));
    }

    private mouseDownEvent(event: MapMouseDownEvent) {
        const featureTarget = (<any>event.payload).featureTarget;
        if (
            featureTarget &&
            featureTarget.layer.source === 'mapbox-gl-draw-cold' &&
            featureTarget._geometry.type === 'Point'
        ) {
            // somebody clicked a point of a radius measurement or line measurement
            let parentId = featureTarget.properties.parent;
            if (!parentId) {
                parentId = featureTarget.properties.user_parent;
            }
            if (!parentId) {
                return;
            }
            const parent: Feature = this.mapService.draw.get(parentId);

            const options: {
                [key in FeatureProperties]?: () => ActiveInteractionChangedEventData;
            } = {
                [FeatureProperties.MEASUREMENT_RADIUS]: () => ({
                    interaction: MapInteractionState.MeasurementRadius,
                    featureId: parentId
                }),
                [FeatureProperties.MEASUREMENT_LINE]: () => ({
                    interaction: MapInteractionState.MeasurementLine,
                    featureId: parentId
                }),
                [FeatureProperties.MEASUREMENT_RADIUS_LINE]: () => ({
                    interaction: MapInteractionState.MeasurementRadius,
                    featureId: parent.properties.relatedFeatureIds[0]
                })
            };

            if (parent.properties.meta in options) {
                event.payload.preventDefault();
                this.mapService.mapEvent$.next(
                    new ActiveInteractionChangedMapEvent(
                        options[parent.properties.meta]()
                    )
                );
            }
        }
    }

    private getFeatures(
        allFeatures: FeatureCollection,
        featureSelector: (feature: Feature) => boolean
    ): Feature[] {
        if (!allFeatures) {
            return [];
        }
        const features = allFeatures.features.filter(featureSelector);
        if (features.length > 0) {
            return features;
        }
        return [];
    }

    private activeInteractionChanged(event: ActiveInteractionChangedMapEvent) {
        const selectedFeatures = this.mapService.draw.getSelected();
        let featureId = event.payload.featureId;
        if (event.payload.interaction === MapInteractionState.MeasurementLine) {
            this.isMeasurementActive = true;
            const selectedMeasurements = this.getFeatures(
                selectedFeatures,
                (f: Feature) =>
                    f.properties.meta === FeatureProperties.MEASUREMENT_LINE
            );

            if (!featureId && selectedMeasurements.length > 0) {
                featureId = selectedMeasurements[0].id;
            }
            this.mapService.changeDrawMode(MapModes.DRAW_SINGLE_LINE, {
                featureId
            });
        } else if (
            event.payload.interaction === MapInteractionState.MeasurementRadius
        ) {
            // FIXME: this causes a console error when entering radius measurement mode due to the feature not being a valid GEOJSON.
            this.isMeasurementActive = true;
            const selectedMeasurements = this.getFeatures(
                selectedFeatures,
                (f: Feature) =>
                    f.properties.meta === FeatureProperties.MEASUREMENT_RADIUS
            );

            if (!featureId && selectedMeasurements.length > 0) {
                featureId = selectedMeasurements[0].id;
            }
            this.mapService.changeDrawMode(MapModes.DRAW_RADIUS, {
                featureId
            });
        } else if (this.isMeasurementActive) {
            this.isMeasurementActive = false;
            this.mapService.changeDrawMode(MapModes.SIMPLE_SELECT);
        }
    }

    private onDrawSelectionChange(event: MapDrawSelectionChangeEvent) {
        const { features, points } = event.payload;
        const {
            isMeasurementLabel,
            isMeasurementLine,
            isMeasurementRadius,
            isMeasurementRadiusLine,
            featureId
        } = getMeasurementProperties(features, points);

        if (isMeasurementLine) {
            this.mapService.changeDrawMode(MapModes.DRAW_SINGLE_LINE_SELECT, {
                featureId
            });
        } else if (isMeasurementLabel) {
            this.mapService.changeDrawMode(MapModes.DRAW_SINGLE_LINE, {
                featureId
            });
        } else if (isMeasurementRadius) {
            this.mapService.changeDrawMode(MapModes.DRAW_RADIUS_SELECT, {
                featureId
            });
        } else if (isMeasurementRadiusLine) {
            this.mapService.changeDrawMode(MapModes.DRAW_RADIUS, {
                featureId
            });
        }
    }
}
