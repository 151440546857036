<ng-container
    *ngIf="
        !resultError &&
            !airspaceServiceUnavailable &&
            !authorisationServiceUnavailable;
        else unavailableTemplate
    "
    [ngSwitch]="mode"
>
    <div *ngSwitchCase="'INITIAL'" class="status-layout">
        <div class="airspace-authorisation-container fill">
            <div class="airspace-authorisation-result authorise">
                <div>
                    LAANC Authorisation
                    <div class="authorisation-icon">
                        <span
                            style="font-size: 32px; text-align: center"
                            class="fal fa-radar top-buffer radar-authorise"
                        ></span>
                    </div>
                </div>
            </div>
            <div class="airspace-authorisation-action authorise">
                <div class="authorise-action-content">
                    <p *ngIf="!currentUserCanRequestAuthorisation">
                        LAANC Authorization requests can only be made by the
                        Remote Pilot in Command for this mission.
                        <br />
                        <span *ngIf="!areAuthorisationRequirementsComplete"
                            >Additionally the mission requires a flight area,
                            valid workflow, RPA, the correct dates and a maximum
                            planned height to be eligible for LAANC
                            Authorizations</span
                        >
                    </p>
                    <button
                        *ngIf="currentUserCanRequestAuthorisation"
                        type="button"
                        class="btn btn-default"
                        [disabled]="missionWorking || airspaceWorking"
                        (click)="showDisclaimer()"
                    >
                        Plan a LAANC Authorisation
                    </button>
                </div>
            </div>
            <ngx-loading
                [show]="airspaceWorking && mode == 'INITIAL'"
            ></ngx-loading>
        </div>
    </div>
    <ng-container
        *ngSwitchCase="'PREVIEW'"
        [ngSwitch]="
            airspaceAuthorisationManager.airspaceAuthorisationEnabled$ | async
        "
    >
        <ng-container *ngSwitchCase="true">
            <div
                *ngIf="
                    authorisation$ | async as authorisations;
                    else loadingTemplate
                "
            >
                <div class="operation-errors">
                    <h5>Operation Checks</h5>
                    <show-errors [errors]="authorisations.errors"></show-errors>
                </div>
                <table class="table table-bordered authorisations">
                    <thead>
                        <tr>
                            <th *ngIf="submitOrView == 'VIEW'" class="wordwrap">
                                Authorisation ID
                            </th>
                            <th class="wordwrap">Controlling Authority</th>
                            <th class="wordwrap">Maximum Height</th>
                            <th class="wordwrap">Authorisation Eligibility</th>
                            <th *ngIf="submitOrView == 'VIEW'">Status</th>
                            <th class="wordwrap">Start Time</th>
                            <th class="wordwrap">End Time</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <ng-container
                            *ngFor="let authorisation of authorisations"
                        >
                            <tr>
                                <td *ngIf="submitOrView == 'VIEW'">
                                    {{ authorisation.authorisationId }}
                                </td>
                                <td class="wordwrap">
                                    {{ authorisation.controllingAuthority }}
                                </td>
                                <td>{{ authorisation.maximumHeight }}</td>
                                <td class="wordwrap">
                                    {{
                                        authorisation.authorisationEligibility
                                            | formatAuthorisationEligibility
                                                : authorisations.authorisationType
                                    }}
                                </td>
                                <td
                                    class="wordwrap"
                                    *ngIf="submitOrView == 'VIEW'"
                                >
                                    <div
                                        class="horizontal-container"
                                        [ngClass]="{
                                            'space-between':
                                                authorisation.status ==
                                                'RESCINDED_AWAITING'
                                        }"
                                    >
                                        <div
                                            class="authorisation-status authorisation-table"
                                            [ngClass]="{
                                                'authorisation-pass':
                                                    authorisation.status ==
                                                    'APPROVED',
                                                'authorisation-pending':
                                                    authorisation.status ==
                                                    'PENDING',
                                                'authorisation-fail':
                                                    authorisation.status ==
                                                        'REJECTED' ||
                                                    authorisation.status ==
                                                        'CANCELLED' ||
                                                    authorisation.status ==
                                                        'RESCINDED' ||
                                                    authorisation.status ==
                                                        'RESCINDED_AWAITING',
                                                'authorisation-closed':
                                                    authorisation.status ==
                                                    'COMPLETED'
                                            }"
                                        >
                                            {{
                                                authorisation.status
                                                    | formatAuthorisationStatus
                                            }}
                                        </div>
                                        <button
                                            *ngIf="
                                                authorisation.status ==
                                                'RESCINDED_AWAITING'
                                            "
                                            type="button"
                                            class="btn btn-xs btn-pill btn-primary"
                                            style="width: unset"
                                            [disabled]="
                                                missionWorking ||
                                                airspaceWorking
                                            "
                                            (click)="
                                                acknowledgeRescinded(
                                                    authorisation
                                                )
                                            "
                                        >
                                            Acknowledge
                                        </button>
                                    </div>
                                </td>
                                <td class="wordwrap">
                                    {{
                                        authorisation.startTime
                                            | formatLocalDateTimeWithUTCProtracted
                                                : missionTimeZone
                                    }}
                                </td>
                                <td class="wordwrap">
                                    {{
                                        authorisation.endTime
                                            | formatLocalDateTimeWithUTCProtracted
                                                : missionTimeZone
                                    }}
                                </td>
                                <td class="wordwrap">
                                    <show-errors
                                        *ngIf="
                                            authorisation.authorisationEligibility !==
                                            'UNAVAILABLE'
                                        "
                                        [errors]="authorisation.errors"
                                    ></show-errors>
                                </td>
                            </tr>
                            <ng-container
                                *ngIf="
                                    authorisation.authorisationEligibility ==
                                        'MANUAL' &&
                                    authorisation?.status == 'PENDING'
                                "
                            >
                                <tr>
                                    <td colspan="7">
                                        <div
                                            class="alert alert-warning"
                                            style="
                                                padding: 6px;
                                                font-size: larger;
                                                line-height: normal;
                                            "
                                            role="alert"
                                        >
                                            <p>
                                                This authorization is pending
                                                further coordination. This is a
                                                manual approval process with
                                                commensurate timelines. The
                                                FAA's goal is to respond with a
                                                decision regarding this
                                                authorization within 30 days,
                                                but in some cases a decision may
                                                take up to 90 days.
                                            </p>
                                            <br />
                                            <p>
                                                <strong>Please note:</strong>
                                                While this authorization is
                                                pending please do not follow up
                                                with Air Traffic by phone as
                                                this may result in the request
                                                being denied by the FAA.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    authorisation?.status == 'REJECTED' &&
                                    authorisation?.authorisationEligibility ===
                                        'MANUAL'
                                "
                            >
                                <tr>
                                    <td colspan="7">
                                        <div
                                            class="alert alert-warning"
                                            style="
                                                padding: 6px;
                                                font-size: larger;
                                                line-height: normal;
                                            "
                                            role="alert"
                                        >
                                            <p>
                                                Your request for Further
                                                Coordination was denied by the
                                                controlling facility. The
                                                operation was denied for reasons
                                                that may include any of the
                                                following: altitude, date, time,
                                                or duration of the requested
                                                operation. Re-submitting the
                                                same request through LAANC will
                                                result in a denial.
                                            </p>
                                            <br />
                                            <p>
                                                To continue pursuit of an
                                                authorization for the operation,
                                                consider altering part, or all,
                                                of the request and re-submitting
                                                either through LAANC or through
                                                FAA DroneZone. Re-submission
                                                through LAANC and/or FAA
                                                DroneZone does not guarantee the
                                                operation request will be
                                                approved.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container
                                *ngIf="authorisation.status == 'INVALID'"
                            >
                                <tr>
                                    <td colspan="7">
                                        <div
                                            class="alert alert-danger"
                                            style="
                                                padding: 6px;
                                                font-size: larger;
                                                line-height: normal;
                                            "
                                            role="alert"
                                        >
                                            <p>
                                                This authorization is no longer
                                                valid and must be cancelled by
                                                you.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div
                *ngIf="
                    !resultError &&
                        airspaceAuthorisationManager.airspaceAuthorisationPreview$
                        | async
                "
                class="horizontal-container"
            >
                <em
                    ><a
                        href="https://www.faa.gov/uas/programs_partnerships/uas_data_exchange/privacy_statement/"
                        target="_blank"
                        >FAA LAANC Privacy Statement</a
                    ></em
                >
                <div class="fill"></div>
                <!-- TODO: this might require a new style -->
                <div
                    *ngIf="authorisationUnavailable"
                    class="alert alert-danger footer-alert"
                    style="padding: 6px"
                >
                    Automatic authorization of this flight plan is unavailable
                </div>
                <div
                    *ngIf="
                        submitOrView == 'SUBMIT' &&
                        !authorisationUnavailable &&
                        currentUserCanRequestAuthorisation
                    "
                    class="horizontal-container vertical-align right-buffer"
                    style="padding: 6px"
                >
                    <div
                        class="checkbox"
                        [ngClass]="{
                            readonly:
                                missionWorking ||
                                airspaceWorking ||
                                authorisationUnavailable
                        }"
                    >
                        <label>
                            <input
                                type="checkbox"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="termsAccepted"
                                [disabled]="
                                    missionWorking ||
                                    airspaceWorking ||
                                    authorisationUnavailable
                                "
                            />
                            <span class="cr"
                                ><i class="cr-icon glyphicon glyphicon-ok"></i
                            ></span>
                        </label>
                    </div>
                    Accept FlyFreely&nbsp;
                    <a
                        href="https://flyfreely.io/terms-of-use#jurisdiction-usa"
                        target="_blank"
                        >LAANC Terms of Use</a
                    >
                    &nbsp;and&nbsp;
                    <a
                        href="https://flyfreely.io/privacy-policy"
                        target="_blank"
                        >Privacy Policy</a
                    >
                </div>
                <button
                    *ngIf="
                        submitOrView == 'VIEW' &&
                        currentUserCanViewAuthorisation
                    "
                    type="button"
                    class="btn btn-primary btn-sm right-buffer"
                    style="color: #fff; width: unset"
                    [disabled]="missionWorking || airspaceWorking"
                    (click)="onShowAirspaceAuthorisation.emit(false)"
                >
                    View Authorization
                </button>
                <button
                    *ngIf="currentUserCanRequestAuthorisation"
                    type="button"
                    class="btn btn-primary btn-sm"
                    style="color: #fff; width: unset"
                    [disabled]="
                        missionWorking ||
                        airspaceWorking ||
                        authorisationUnavailable ||
                        missionLineOfSightIneligible ||
                        (submitOrView == 'SUBMIT' && !termsAccepted)
                    "
                    (click)="onShowAirspaceAuthorisation.emit(true)"
                >
                    Prepare{{
                        submitOrView == 'VIEW' ? ' New ' : ' '
                    }}Authorization
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="false">
            Airspace authorization not supported in this jurisdiction
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ngx-loading></ngx-loading>
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #unavailableTemplate>
    <airspace-unavailable-alert
        [show]="airspaceServiceUnavailable"
    ></airspace-unavailable-alert>
    <airspace-unavailable-alert
        [show]="authorisationServiceUnavailable"
        customStatusMessage="LAANC is not currently available."
        [customStatusText]="authorisationUnavailableMessage"
    ></airspace-unavailable-alert>
    <airspace-unavailable-alert
        [show]="
            resultError &&
            !airspaceServiceUnavailable &&
            !authorisationServiceUnavailable
        "
        customStatusMessage="An error occurred while retrieving the airspace authorization."
        [customStatusText]="
            resultError.message != null ? resultError.message : ''
        "
    ></airspace-unavailable-alert>
</ng-template>
<ngx-loading [show]="airspaceWorking && mode != 'INITIAL'"></ngx-loading>
