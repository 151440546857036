import { ActivatedRoute } from '@angular/router';

/**
 * Takes a target URL and adds it to the current URL, preserving the current query params.
 * Returns the URL to be used as-is to navigate to URL.
 *
 * For a URL that has the path only, use the buildTargetedUrl function instead.
 *
 * @param targetUrl The URL to add to the current URL, don't add an initial '/' only adding one for additional paths in the route.
 * @returns A new URL with the targetUrl added to the current URL and all the current query params preserved
 */
export function buildTargetedUrlWithParams(
    targetUrl: string,
    activatedRoute: ActivatedRoute
) {
    const url = `${window.location.pathname}/${targetUrl}`;
    const paramMap = activatedRoute.snapshot.queryParamMap;
    const queryParams = paramMap.keys.map(key => `${key}=${paramMap.get(key)}`);
    return `${url}?${queryParams.join('&')}`;
}

/**
 * Takes a target URL and adds it to the current URL path only.
 * Returns the URL to be used in router navigation for child routes.
 *
 * For a URL that has the query params included, use the buildTargetedUrlWithParams function instead.
 *
 * @param targetUrl The URL to add to the current URL, don't add an initial '/' only adding one for additional paths in the route.
 * @returns A new URL path with the targetUrl added to the current URL
 */
export function buildTargetedUrl(targetUrl: string) {
    return `${window.location.pathname}/${targetUrl}`;
}
