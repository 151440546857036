import { Component, Input, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
    AirspaceCheckCommand,
    InUseMissionWorkflowVersionDto,
    LocationDetailsDto,
    LocationDto,
    LocationService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { LocationDialoguesService } from 'libs/locations/src/lib/location-dialogues.service';
import { LocationAirspaceParameters } from 'libs/locations/src/lib/location-edit-v2/location-edit-dialogue.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissionEditService } from './mission-edit.service';

const SCREEN_IDENTIFIER = 'mission-edit-v2-dialogue';

/**
 * Provides a location selection control with various states.
 */
@Component({
    selector: 'mission-location-selection',
    template: `
        <ng-container *ngIf="state === 'NO_LOCATION'">
            <div class="input-group">
                <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    (click)="addLocation()"
                    [disabled]="working || locked"
                >
                    Draw a Flight Area
                </button>
                <span class="left-buffer right-buffer"> or </span>
                <button
                    class="btn btn-default btn-sm"
                    type="button"
                    (click)="state = 'SELECT'"
                    [disabled]="working || locked"
                    data-cy="chooseLocationTemplate"
                >
                    Choose Location Template
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="state === 'HAS_LOCATION'">
            <div class="input-group">
                <p>
                    {{ location.name }}
                </p>
                <span class="input-group-btn">
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="editLocation()"
                        [disabled]="working || locked"
                    >
                        Edit
                    </button>
                    <button
                        class="btn btn-default"
                        type="button"
                        (click)="selectTemplate()"
                        [disabled]="working || locked"
                    >
                        Choose Location Template
                    </button>
                </span>
            </div>
        </ng-container>
        <ng-container *ngIf="state === 'SELECT'">
            <div class="input-group">
                <ng-select
                    name="locationId"
                    [ngModelOptions]="{ standalone: true }"
                    [ngModel]="null"
                    bindValue="id"
                    bindLabel="name"
                    placeholder="Select a location"
                    [items]="locations"
                    [clearable]="false"
                    appendTo="body"
                    [readonly]="working || locked"
                    (change)="onSelection($event)"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        {{ item.name }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        {{ item.name }}
                    </ng-template>
                </ng-select>
                <span class="input-group-btn">
                    <button
                        class="btn btn-default"
                        type="button"
                        (click)="stopSelecting()"
                        [disabled]="working || locked"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </ng-container>
    `,
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (controlContainer: ControlContainer) =>
                controlContainer,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ]
})
export class MissionLocationSelection {
    @Input() locked: boolean;
    @Input() working: boolean;

    locations: LocationDto[];

    location: LocationDetailsDto;

    workflows: InUseMissionWorkflowVersionDto[];

    ngUnsubscribe$ = new Subject<void>();

    state: 'NO_LOCATION' | 'HAS_LOCATION' | 'SELECT' = 'NO_LOCATION';

    constructor(
        private missionEditService: MissionEditService,
        private locationDialoguesService: LocationDialoguesService,
        private locationService: LocationService,
        private commonDialoguesService: CommonDialoguesService,
        private workTracker: WorkTracker,
        public angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        this.missionEditService.locations$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locations => (this.locations = locations));

        this.missionEditService.missionLocation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(location => {
                this.location = location;
                this.stopSelecting();
            });
        this.missionEditService.missionWorkflows$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(workflows => (this.workflows = workflows));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    onSelection(event: LocationDto) {
        this.locationService
            .findLocation(event.id)
            .subscribe(location => this.changeLocation(location));

        this.angulartics2.eventTrack.next({
            action: 'location-select',
            properties: {
                category: 'mission-edit-v2-dialogue'
            }
        });
    }

    addLocation() {
        const dialogue = this.locationDialoguesService.newLocation(
            this.missionEditService.organisationId,
            LocationDetailsDto.Type.MISSION,
            false,
            {
                name: this.missionEditService.missionForm.value
                    .missionObjectivesGroup.name
            },
            this.getAirspaceParameters()
        );
        dialogue.content.change
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(location => {
                this.missionEditService.setLocation(location);
            });

        dialogue.content.updatedMissionValues
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(values => {
                const formValue = this.missionEditService.missionForm.value;
                this.missionEditService.missionForm.patchValue({
                    missionDate: values.date ?? formValue.missionDate,
                    missionEstimatedTime:
                        values.duration ?? formValue.missionEstimatedTime,
                    maximumHeight: values.height ?? formValue.maximumHeight
                });
            });

        this.angulartics2.eventTrack.next({
            action: 'location-add',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    editLocation() {
        const dialogue =
            this.location.id != null
                ? this.locationDialoguesService.editLocation(
                      this.location.id,
                      this.missionEditService.organisationId,
                      false,
                      LocationDetailsDto.Type.MISSION,
                      this.getAirspaceParameters()
                  )
                : this.locationDialoguesService.newLocation(
                      this.missionEditService.organisationId,
                      LocationDetailsDto.Type.MISSION,
                      false,
                      {
                          ...this.location
                      },
                      this.getAirspaceParameters()
                  );

        dialogue.content.change
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((location: LocationDetailsDto) => {
                this.missionEditService.updateLocation(location);
            });

        dialogue.content.updatedMissionValues
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(values => {
                const formValue = this.missionEditService.missionForm.value;
                this.missionEditService.missionForm.patchValue({
                    missionDate: values.date ?? formValue.missionDate,
                    missionEstimatedTime:
                        values.duration ?? formValue.missionEstimatedTime,
                    maximumHeight: values.height ?? formValue.maximumHeight
                });
            });

        this.angulartics2.eventTrack.next({
            action: 'location-edit',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    changeLocation(location: LocationDetailsDto) {
        if (location?.id === this.location?.id) {
            return;
        }
        if (this.location != null) {
            this.commonDialoguesService
                .showConfirmationDialogue(
                    'Use Location Template',
                    'Your existing mission location will be overwritten. Do you wish to continue?',
                    'Continue',
                    () => Promise.resolve()
                )
                .then(
                    () =>
                        this.missionEditService
                            .updateLocation(location)
                            .add(this.workTracker.createTracker()),
                    () => {
                        return;
                    }
                );
        } else {
            this.missionEditService
                .updateLocation(location)
                .add(this.workTracker.createTracker());
        }
    }

    getAirspaceParameters() {
        const value = this.missionEditService.missionForm.value;
        if (value == null) {
            return null;
        }

        const workflow =
            this.workflows != null
                ? this.workflows.find(
                      w => w.id === value.missionWorkflowVersionId
                  )
                : null;
        const ruleset =
            workflow?.delegatedAuthority?.authorityType.ruleset?.identifier;

        const parameters: LocationAirspaceParameters = {
            date: value.missionDate,
            duration: value.missionEstimatedTime,
            ruleset: ruleset,
            height: value.maximumHeight
        };

        return parameters;
    }

    selectTemplate() {
        this.state = 'SELECT';
    }

    stopSelecting() {
        this.state = this.location != null ? 'HAS_LOCATION' : 'NO_LOCATION';
    }
}
