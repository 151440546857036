<div *ngIf="editMode$ | async as editMode">
    <div class="modal-header no-icon">
        <h3 class="modal-title">{{editMode === "Create" ? "Create" :"Update"}}  Notice</h3>
        <!-- <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link> -->
        <button type="button" (click)="cancel()" aria-label="Close" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form 
            [formGroup]="noticeForm"  
            (ngSubmit)="editMode === 'Create' ? createNotice() : updateNotice()">
        <ngx-loading [show]="working"></ngx-loading>
    <div class="modal-body">
            <div class="mb-32 d-flex w-100 align-center">
                <div class="d-flex w-136 space-6">
                    <field-validation-indicator
                    validatorFormControlName="name"
                    [noun]="'field'"
                    [placement]="'left'"
                    class=""
                    >
                    </field-validation-indicator>
                    <label for="notice">Notice</label>
                </div>
                <div class="w-100">
                    <input type="text" id="notice" formControlName="name" class="form-control corner-curve border-gray" >
                    <validation-messages [control]="noticeForm.controls.name">
                    </validation-messages>
                </div>
            </div>
            <div class="mb-32 d-flex  w-100 align-center">
                <div class="d-flex w-136 space-6 ">
                    <field-validation-indicator
                    class="pos-fix"
                    validatorFormControlName="type"
                    [noun]="'field'"
                    [placement]="'left'"
                    >
                    </field-validation-indicator >
                    <label for="noticeType">Notice Type</label>
                </div>
              <div id="noticeType" class="radio-group d-flex w-100 space-4">
                  <input class="d-none" type="radio" id="airworthinessDirective" formControlName="type" value="AIRWORTHINESS_DIRECTIVE">
                  <label class="form-check-label" for="airworthinessDirective">Airworthiness Directive</label>
        
                  <input class="d-none" type="radio" id="serviceBulletin" formControlName="type" value="SERVICE_BULLETIN">
                  <label class="form-check-label" for="serviceBulletin">Service Bulletin</label>
       
                  <input class="d-none" type="radio" id="engineeringOrder" formControlName="type" value="ENGINEERING_ORDER">
                  <label class="form-check-label" for="engineeringOrder">Engineering Order</label>
         
                  <input class="d-none" type="radio" id="other" formControlName="type" value="OTHER">
                  <label class="form-check-label" for="other">Other</label>
              </div>
              <div *ngIf="noticeForm.get('type').invalid && noticeForm.get('type').touched" class="invalid-feedback">
                Type is required.
              </div>
            </div>
            <div class="mb-32 d-flex w-100 align-center">
                <div class="d-flex w-136 space-6">
                    <field-validation-indicator
                    validatorFormControlName="issuedBy"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="validator"
                    >
                    </field-validation-indicator>
                    <label for="noticeType">Issued By</label>
                </div>
                <div class="w-100">
                    <input type="text" id="issuedBy" formControlName="issuedBy" class="form-control corner-curve border-gray">
                </div>
            </div>
            <div class="mb-32 d-flex w-100 align-center">
                <div class="d-flex w-136 space-6 pos-fix">
                    <field-validation-indicator
                    validatorFormControlName="applicability"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pos-fix"
                    >
                    </field-validation-indicator>
                    <label for="applicability">Applicability</label>
                </div>
                <div  id="applicability" class="radio-group d-flex w-100 space-4">
                  <input class="d-none" type="radio" id="applicable" formControlName="applicability" value="APPLICABLE">
                  <label class="form-check-label" for="applicable">Applicable</label>
           
                  <input class="d-none" type="radio" id="notApplicable" formControlName="applicability" value="NOT_APPLICABLE">
                  <label class="form-check-label" for="notApplicable">Not Applicable</label>
            
                  <input class="d-none" type="radio" id="unknown" formControlName="applicability" value="UNKNOWN">
                  <label class="form-check-label" for="unknown">Unknown</label>
                </div>
     
              <div *ngIf="noticeForm.get('applicability').invalid && noticeForm.get('applicability').touched" class="invalid-feedback">
                Applicability is required.
              </div>
            </div>
            <div class="mb-32 d-flex w-100 align-center">
                <div class="d-flex w-136 space-6">
                    <field-validation-indicator
                    validatorFormControlName="dueBy"
                    [noun]="'field'"
                    [placement]="'left'"
                    class=""
                    >
                    </field-validation-indicator>
                    <label for="dueBy">Due By</label>
                </div>
                <div class="w-100">
                    <input type="text" id="dueBy" formControlName="dueBy" class="form-control corner-curve border-gray" >
                </div>
            </div>
            <div class="mb-16 d-flex align-center">
                <div class="d-flex w-136 space-6 pos-fix">
                    <field-validation-indicator
                    validatorFormControlName="description"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pos-fix"
                    >
                    </field-validation-indicator>
                    <label for="description">Description</label>
                </div>
            </div>
            <div class="w-100 mb-16">
                <textarea id="description" formControlName="description" class="form-control" rows="3"></textarea>
            </div>
            <div class="w-100 mb-16" *ngIf="editMode === 'Update'">
                <span class="text-bold">Links & Attachments</span>
            </div>
            <div class="w-100 mb-16">
                <div class="row" *ngIf="editMode === 'Update'">
                    <div class="col-xs-6">
                    <app-link-manager 
                        [sectionId]="airworthinessId" 
                        [linkAction]="LinkAction.noticeAirworthiness" 
                        [showAddForm]="true"
                        >
                    </app-link-manager>
                    </div>
                    <div class="col-xs-6 d-flex align-center">
                        <attachment-list 
                            [attachmentsHandler]="attachmentsHandler"
                            [allowUploads]="true"
                            [newTemplate]="true"
                            [allowDelete]="true"
                            [allowEdit]="true"
                            permissions>
                        </attachment-list>
                    </div>
                </div>
                
            </div>
            <div class="mb-16 d-flex align-center">
                
            </div>
    
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            [disabled]="working || noticeForm.invalid"
            class="btn btn-primary"
        >
        <i *ngIf="!working" class="fa fa-plus"></i>
        <i *ngIf="working" class="fa-solid fa-spinner fa-spin-pulse"></i>
        {{editMode === "Create" ? "Create" :"Update"}}
        </button>
    </div>
    </form>
</div>
