/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.35-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AirspaceAuthorisationDetails } from './airspaceAuthorisationDetails';
import { AerodromeDetailsDto } from './aerodromeDetailsDto';
import { RuleCodeDto } from './ruleCodeDto';
import { RuleOutcome } from './ruleOutcome';
import { NearestFeatureDto } from './nearestFeatureDto';
import { AirspaceDataset } from './airspaceDataset';
import { AirspaceCheckCondition } from './airspaceCheckCondition';
import { SunsetSunriseResponse } from './sunsetSunriseResponse';


/**
 * Response to an airspace check with relevant properties and additional aerodrome data
 */
export interface AirspaceCheckDto { 
    jurisdiction?: string;
    ruleset?: string;
    sunsetAndRise?: SunsetSunriseResponse;
    /**
     * The result for each individual rule code
     */
    ruleOutcomes?: Array<RuleOutcome>;
    /**
     * This is the overall outcome of the entire check
     */
    flightStatus?: AirspaceCheckDto.FlightStatus;
    serviceName?: string;
    /**
     * The timezone at the centre of the airspace
     */
    timeZone?: string;
    nearestFeatures?: Array<NearestFeatureDto>;
    /**
     * The airspace authorisation services available for this airspace.
     */
    availableAuthorisations: { [key: string]: AirspaceAuthorisationDetails; };
    airspaceDatasetList?: Array<AirspaceDataset>;
    ruleMessages?: { [key: string]: RuleCodeDto; };
    conditionList?: Array<AirspaceCheckCondition>;
    /**
     * The aerodromes mentioned in the rule results
     */
    aerodromes: { [key: string]: AerodromeDetailsDto; };
    automatedAuthorisationAvailable: boolean;
    automatedAuthorisationLayerGroups?: { [key: string]: Array<string>; };
    availableAutomatedAuthorisationList?: Array<AirspaceCheckDto.AvailableAutomatedAuthorisationList>;
}
export namespace AirspaceCheckDto {
    export type FlightStatus = 'CAN_FLY' | 'CAN_FLY_CONDITIONS' | 'CANNOT_FLY' | 'NA' | 'NOT_RUN';
    export const FlightStatus = {
        CAN_FLY: 'CAN_FLY' as FlightStatus,
        CAN_FLY_CONDITIONS: 'CAN_FLY_CONDITIONS' as FlightStatus,
        CANNOT_FLY: 'CANNOT_FLY' as FlightStatus,
        NA: 'NA' as FlightStatus,
        NOT_RUN: 'NOT_RUN' as FlightStatus
    };
    export type AvailableAutomatedAuthorisationList = 'AUS_CASA_AUTHORISATION' | 'AUS_ASA_FIMS' | 'NZL_AIRSHARE' | 'USA_LAANC';
    export const AvailableAutomatedAuthorisationList = {
        AUS_CASA_AUTHORISATION: 'AUS_CASA_AUTHORISATION' as AvailableAutomatedAuthorisationList,
        AUS_ASA_FIMS: 'AUS_ASA_FIMS' as AvailableAutomatedAuthorisationList,
        NZL_AIRSHARE: 'NZL_AIRSHARE' as AvailableAutomatedAuthorisationList,
        USA_LAANC: 'USA_LAANC' as AvailableAutomatedAuthorisationList
    };
}



