import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { SharedUiModule } from '@flyfreely-portal-ui/ui';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {
    OutstandingTasksViewService,
    RegisteredTaskWidgets,
    WidgetRegistrationWithSection
} from './outstanding-tasks-view.service';
import { OutstandingTasksSidebarComponent } from './view/outstanding-tasks-sidebar/outstanding-tasks-sidebar.component';
import { OutstandingTasksViewComponent } from './view/outstanding-tasks-view/outstanding-tasks-view.component';
import { CompletedOutstandingTasksBannerComponent } from './widgets/completed-outstanding-task-banner.component';
import { GenericWidgetComponent } from './widgets/generic/generic.component';
import { MissionsAwaitingApprovalWidgetComponent } from './widgets/operations/missions-awaiting-approval/missions-awaiting-approval.component';
import { MissionsAwaitingFinalisationWidgetComponent } from './widgets/operations/missions-awaiting-finalisation/missions-awaiting-finalisation.component';
import { OutstandingFlightLogsWidgetComponent } from './widgets/operations/outstanding-flight-logs/outstanding-flight-logs.component';
import { UnresolvedIncidentsWidgetComponent } from './widgets/operations/unresolved-incidents/unresolved-incidents-widget.component';
import { OrganisationAuthorityExpiriesWidgetComponent } from './widgets/organisation/authorisation-expiries/organisation-authority-expiries-widget.component';
import { RegisterExpiriesWidgetComponent } from './widgets/organisation/register-expiries/register-expiries-widget.component';
import { AssignedMaintenanceWidgetComponent } from './widgets/resources/assigned-maintenance/assigned-maintenance-widget.component';
import { RpaAuthoritiesTaskWidgetComponent } from './widgets/resources/rpa-authorities/rpa-authorities-widget.component';
import { UpcomingMaintenanceWidgetComponent } from './widgets/resources/upcoming-maintenance/upcoming-maintenance-widget.component';
import { GeneralTasksWidgetComponent } from './widgets/general-task/general-tasks-widget.component';
import { RouterModule } from '@angular/router';
import { WidgetModule } from '@flyfreely-portal-ui/widget';
import { GeneralTaskAddDialogue } from './widgets/general-task/add-task/general-task-add-dialogue.component';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { NgSelectModule } from '@ng-select/ng-select';
import { AnalyticsModule } from '@flyfreely-portal-ui/analytics';
import { GeneralTaskDetailsDialogueComponent } from './widgets/general-task/general-task-details-dialogue/general-task-details-dialogue.component';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { GeneralTaskDetailsComponent } from './widgets/general-task/general-task-details/general-task-details.component';
import { GeneralTaskEditComponent } from './widgets/general-task/general-task-edit/general-task-edit.component';
import { NgxLoadingModule } from 'ngx-loading';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { CommentsModule } from '@flyfreely-portal-ui/comments';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        FormsModule,
        MaterialModule,
        TooltipModule,

        FlyFreelyTableModule,
        LoadingIndicatorModule,
        WidgetModule,
        RouterModule.forChild([]),
        ValidationModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        EditableModule,
        NgSelectModule,
        AnalyticsModule,
        EnhancedHelpModule,
        NgxLoadingModule,
        AttachmentsModule,
        CommentsModule
    ],
    declarations: [
        OutstandingTasksViewComponent,
        OutstandingTasksSidebarComponent,
        GenericWidgetComponent,
        MissionsAwaitingApprovalWidgetComponent,
        MissionsAwaitingFinalisationWidgetComponent,
        OutstandingFlightLogsWidgetComponent,
        RegisterExpiriesWidgetComponent,
        OrganisationAuthorityExpiriesWidgetComponent,
        UnresolvedIncidentsWidgetComponent,
        UpcomingMaintenanceWidgetComponent,
        AssignedMaintenanceWidgetComponent,
        CompletedOutstandingTasksBannerComponent,
        RpaAuthoritiesTaskWidgetComponent,
        GeneralTasksWidgetComponent,
        GeneralTaskDetailsDialogueComponent,
        GeneralTaskDetailsComponent,
        GeneralTaskEditComponent,
        GeneralTaskAddDialogue
    ],
    providers: [OutstandingTasksViewService]
})
export class OutstandingTasksModule {
    static forRoot(
        registeredWidgets: WidgetRegistrationWithSection[]
    ): ModuleWithProviders<any> {
        return {
            ngModule: OutstandingTasksModule,
            providers: [
                {
                    provide: RegisteredTaskWidgets,
                    useValue: new RegisteredTaskWidgets(registeredWidgets)
                }
            ]
        };
    }
}
