import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    BatteryDto,
    BatteryService,
    BatterySetDto,
    BatterySetService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UpdateNotesCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';

export enum NoteType {
    Battery = 'Battery',
    BatterySet = 'BatterySet'
}

@Component({
    selector: 'notes-edit',
    templateUrl: './notes-edit.component.html',
    styleUrls: ['./notes-edit.component.scss']
})
export class NotesEditComponent implements OnInit, OnDestroy {
    @Input() noteType: NoteType;
    @Input() source: BatteryDto | BatterySetDto;

    batteryNotesForm: FormGroup;
    private workTracker = new WorkTracker();
    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    @Output() cancelled = new EventEmitter();

    constructor(
        private commonDialoguesService: CommonDialoguesService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit(): void {
        this.batteryNotesForm = new FormGroup({
            notes: new FormControl(this.source.notes)
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancel() {
        if (this.batteryNotesForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    cancelEdit() {
        this.cancelled.emit(null);
    }

    saveNotes() {
        if (this.batteryNotesForm.invalid) {
            return;
        }
        const values = this.batteryNotesForm.value;
        const payload = {
            id: this.source.id,
            notes: values.notes
        } as UpdateNotesCommand;
        if (this.source.id) {
            this.getSourceByType(this.source.id, payload)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: () => {
                        this.logging.success('Update Notes Successfully');
                        this.batteryNotesForm.markAsPristine();
                        this.cancel();
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error while update Notes: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        }
    }

    private getSourceByType(id: number, payload: UpdateNotesCommand) {
        if (this.noteType === 'Battery') {
            return this.batteryService.updateBatteryNotes(id, payload);
        } else if (this.noteType === 'BatterySet') {
            return this.batterySetService.updateBatterySetNotes(id, payload);
        }
    }
}
