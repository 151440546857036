import { Injectable } from '@angular/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivityVideoPlayerDialogue } from './activity-video/activity-video.component';
import { SetupGuideActivityDto } from '@flyfreely-portal-ui/flyfreely';

@Injectable()
export class ActivityStreamDialoguesService {
    constructor(private modalService: BsModalService) {}

    showVideo(
        videoUrl: string,
        thumbnail: string,
        activityTitle: string,
        stepId: number,
        task: SetupGuideActivityDto.StepIdentifier
    ) {
        return this.modalService.show<ActivityVideoPlayerDialogue>(
            ActivityVideoPlayerDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-dialog modal-md',
                initialState: {
                    videoUrl,
                    thumbnail,
                    activityTitle,
                    task,
                    stepId
                }
            }
        );
    }
}
