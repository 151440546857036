<div class="modal-header">
    <div class="panel-heading-icon">
        <img imgPath="/icons/icon-maintenance.png" />
    </div>
    <h3 class="modal-title">Link Test Flight</h3>
    <!-- <enhanced-help-switch></enhanced-help-switch> -->
    <knowledge-base-link></knowledge-base-link>
    <button
        type="button"
        (click)="close()"
        aria-label="Close"
        class="close"
        data-cy="maintenanceFlightLinkCloseBtn"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="modal-body-content container-scroll" style="position: relative">
        <static-table
            *ngIf="tableConfig && availableColumns"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="missions$ | async"
            [tableConfig]="tableConfig"
            [columnSorting]="tableSorting"
            [tableSearch]="tableSearch"
            (pageChanged)="onTablePageChanged($event)"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (search)="onTableSearch($event)"
            [centeredHeadings]="true"
            data-cy="flightLinkMissionListTable"
        >
            <ng-template
                columnTemplate="name"
                let-row="row"
                let-column="column"
            >
                <mission-status-icon
                    [mission]="row"
                    (nameClicked)="showMission(row)"
                ></mission-status-icon>
            </ng-template>
        </static-table>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
