<div
    *ngIf="availableRpaTypeList != null && !disabled"
    [formGroup]="newCompatibleRpaType"
    class="horizontal-container top-buffer"
>
    <div class="fill">
        <label>Add Compatible RPA Type</label>
        <ng-select
            [items]="availableRpaTypeList"
            [clearable]="false"
            formControlName="rpaTypeId"
            bindLabel="model"
            bindValue="id"
            appendTo="body"
            placeholder="Select RPA Type"
            [readonly]="working"
        >
        </ng-select>
    </div>
    <div class="left-buffer">
        <label>Required Battery Count</label>
        <input
            class="form-control"
            type="number"
            min="0"
            formControlName="count"
            placeholder="How many batteries of this type are required?"
            [readonly]="working"
        />
    </div>
    <div
        class="left-buffer vertical-container"
        style="justify-content: flex-end"
    >
        <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="addRpaType()"
            [disabled]="newCompatibleRpaType.invalid || working"
        >
            Add</button
        >
    </div>
</div>
<div class="top-buffer">
    <static-table
        [availableColumns]="availableColumns"
        [selectedColumns]="selectedColumns"
        [tableData]="associatedRpaTypeList"
        [tableConfig]="tableConfig"
    >
        <ng-template columnTemplate="count" let-row="row" let-column="column">
            <ng-container *ngIf="disabled">{{ row.count }}</ng-container>
            <editable
                *ngIf="!disabled"
                confirmationButtons="true"
                [initialContext]="{ id: row.id, count: row.count }"
                [updateFn]="editRpaType"
            >
                <ng-template viewMode>{{ row.count }}</ng-template>
                <ng-template editMode let-context="context" let-state="state">
                    <input
                        type="number"
                        min="0"
                        [(ngModel)]="context.count"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        editableOnEnter
                        [readonly]="state.saving"
                    />
                    <div class="text-danger" *ngIf="state.error != null">
                        {{ state.error }}
                    </div>
                </ng-template>
            </editable>
        </ng-template>
    </static-table>
</div>
