import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationIdentifierDto,
    OrganisationService,
    PersonDto,
    UpdateOrganisationIdentifierCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'update-air-services',
    templateUrl: './update-air-services-dialogue.component.html'
})
export class UpdateAirServicesDialogue {
    @Input() profile: PersonDto;
    @Input() userAsaIdentifiers: OrganisationIdentifierDto;

    formGroup: FormGroup;

    isCreating: boolean;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;

    constructor(
        private organisationService: OrganisationService,
        private modal: BsModalRef<UpdateAirServicesDialogue>,
        private logging: FlyFreelyLoggingService
    ) {
        this.formGroup = new FormGroup({
            username: new FormControl(undefined, Validators.required),
            password: new FormControl(undefined, Validators.required)
        });
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.isCreating = this.userAsaIdentifiers.identifier == null;

        this.formGroup.patchValue({
            username: this.userAsaIdentifiers.identifier
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    updateDetails() {
        const value = this.formGroup.value;

        const command: UpdateOrganisationIdentifierCommand = {
            identifier: value.username,
            credentials: value.password,
            integration: 'ASA'
        };
        this.organisationService
            .updateOrganisationIdentifiers(
                this.profile.personalOrganisationId,
                command
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => {
                    this.logging.success('Your details have been updated');
                    this.modal.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating details: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    hide() {
        this.modal.hide();
    }
}
