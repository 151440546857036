import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterOutById' })
export class FilterOutById implements PipeTransform {
    transform(
        items: { id: number }[],
        itemsToFilter: { id: number }[] | number[] | null
    ) {
        if (items == null || items.length === 0 || itemsToFilter == null) {
            return items;
        }

        const idsToFilter: number[] = typeof itemsToFilter[0] === 'number' ? itemsToFilter : itemsToFilter.map(item => item.id);

        return items.filter(
            item => idsToFilter.findIndex(id => id === item.id) === -1
        );
    }
}
