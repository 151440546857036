import { FormControl } from '@angular/forms';
import {
    AirspaceAuthorisationDto,
    AirspaceCheckCommand,
    CraftDto,
    PersonDto
} from '@flyfreely-portal-ui/flyfreely';

export interface CraftWithError extends CraftDto {
    missingMtow: boolean;
}

export interface ValidationResultMessages {
    rpas: string;
    aerodrome: string;
    activeCells: string;
    height: string;
    towerHours: string;
    airspace: string;
}

export interface TimeValidationResult {
    passStartRange: boolean;
    passEndRange: boolean;
    passStartDaylight: boolean;
    passEndDaylight: boolean;
    startRangeString: string;
    startDaylightString: string;
    endRangeString: string;
    endDaylightString: string;
    timePastString: string;
}

export interface AdditionalInformationFields {
    isShielded: boolean;
    isCameraInUse: boolean;
    flightPurpose: AdditionalInformationFormValue.FlightPurpose;
    operatorType: string;
    controlZoneRequest: {
        hasCertifiedTransmitter: boolean;
        hasVhfRadioContact: string;
        descriptionOfOperatingArea: string;
        emergencyProcedure: string;
        procedureMeasureAltitude: string;
        otherInformation: string;
    };
}

export interface AdditionalInformationForm {
    isShielded: FormControl<boolean>;
    isCameraInUse: FormControl<boolean>;
    flightPurpose: FormControl<AdditionalInformationFormValue.FlightPurpose>;
    operatorType: FormControl<string>;
    hasCertifiedTransmitter: FormControl<boolean>;
    hasVhfRadioContact: FormControl<string>;
    descriptionOfOperatingArea: FormControl<string>;
    emergencyProcedure: FormControl<string>;
    procedureMeasureAltitude: FormControl<string>;
    otherInformation: FormControl<string>;
    safetyJustification: FormControl<string>;
}

export interface AdditionalInformationFormValue {
    isShielded: boolean;
    isCameraInUse: boolean;
    flightPurpose: AdditionalInformationFormValue.FlightPurpose;
    operatorType: string;
    hasCertifiedTransmitter: boolean;
    hasVhfRadioContact: string;
    descriptionOfOperatingArea: string;
    emergencyProcedure: string;
    procedureMeasureAltitude: string;
    otherInformation: string;
}

export namespace AdditionalInformationFormValue {
    export type FlightPurpose =
        | 'AERIAL_FILMING'
        | 'SURVEILLANCE'
        | 'SURVEY_MAPPING'
        | 'INFRASTRUCTURE_INSPECTION'
        | 'INVENTORY_MANAGEMENT'
        | 'CONSTRUCTION_MONITORING'
        | 'DEMONSTRATION'
        | 'TRAINING'
        | 'UAM_OPERATION'
        | 'FISHING'
        | 'OTHER'
        | 'POLICE'
        | 'CUSTOMS'
        | 'TRAFFIC_SURVEILLANCE'
        | 'SEARCH_AND_RESCUE'
        | 'ENVIRONMENTAL_CONTROL'
        | 'MEDICAL'
        | 'EVACUATIONS'
        | 'FIRE_FIGHTING'
        | 'VIP_SECURITY';
    export const FlightPurpose = {
        AERIAL_FILMING: 'AERIAL_FILMING' as FlightPurpose,
        SURVEILLANCE: 'SURVEILLANCE' as FlightPurpose,
        SURVEY_MAPPING: 'SURVEY_MAPPING' as FlightPurpose,
        INFRASTRUCTURE_INSPECTION: 'INFRASTRUCTURE_INSPECTION' as FlightPurpose,
        INVENTORY_MANAGEMENT: 'INVENTORY_MANAGEMENT' as FlightPurpose,
        CONSTRUCTION_MONITORING: 'CONSTRUCTION_MONITORING' as FlightPurpose,
        DEMONSTRATION: 'DEMONSTRATION' as FlightPurpose,
        TRAINING: 'TRAINING' as FlightPurpose,
        UAM_OPERATION: 'UAM_OPERATION' as FlightPurpose,
        FISHING: 'FISHING' as FlightPurpose,
        OTHER: 'OTHER' as FlightPurpose,
        POLICE: 'POLICE' as FlightPurpose,
        CUSTOMS: 'CUSTOMS' as FlightPurpose,
        TRAFFIC_SURVEILLANCE: 'TRAFFIC_SURVEILLANCE' as FlightPurpose,
        SEARCH_AND_RESCUE: 'SEARCH_AND_RESCUE' as FlightPurpose,
        ENVIRONMENTAL_CONTROL: 'ENVIRONMENTAL_CONTROL' as FlightPurpose,
        MEDICAL: 'MEDICAL' as FlightPurpose,
        EVACUATIONS: 'EVACUATIONS' as FlightPurpose,
        FIRE_FIGHTING: 'FIRE_FIGHTING' as FlightPurpose,
        VIP_SECURITY: 'VIP_SECURITY' as FlightPurpose
    };
}

export interface AuthorisationPersonnelForm {
    rpic: PersonDto;
    crp: PersonDto;
    crpArn: string;
    crpAuthLabel: string;
    rpicArn: string;
    rpicAuthLabel: string;
    rpicPhone: string;
}

export enum CasaFlightProfiles {
    AUTOMATED_GRID = 'AUTOMATED_GRID',
    AUTOMATED_WAYPOINT = 'AUTOMATED_WAYPOINT',
    MANUAL = 'MANUAL'
}

export type AirspaceAuthorisationState =
    | 'CHECKING' // Check in progress
    | 'NO_CHECK' // No checker available
    | 'PASS' // No authorisation required
    | 'AUTO_APPROVAL' // Advise but automatic approval available
    | 'ADVISE_NOT_AVAILABLE' // Advise but there is no approval available
    | 'ADVISE_INCOMPLETE' // Advise the requirements for a check are incomplete
    | 'BLOCKED' // Completely blocked
    | 'APPROVED' // Approved authorisation
    | 'CANCELLED' // Cancelled authorisation
    | 'CLOSED' // Authorisation closed
    | 'UNKNOWN'
    | 'ALTERNATIVE' // Authorisation using alternative URLs, like LAANC
    | 'OPERATION_FAILED' // Check operation failed
    | 'REJECTED' // Request rejected by CAA
    | 'PENDING' // Request is pending a decision by the CAA
    | 'FAILED'
    | 'PROVISIONAL' // Authorisation provisionally approved, but contacting tower on the day is required.
    | 'COMPLETED'
    | 'RESCINDED'
    | 'RESCINDED_AWAITING'
    | 'INVALID'
    | 'DECLARED'
    | 'ERROR'
    | 'TERMINATED';

export interface AlternativeAirspaceAuthorisationRequestCommand {
    missionDetails: AirspaceCheckCommand;
    missionId: number;
    // User ID
    operatorId: number;
    authorisationType: AirspaceAuthorisationDto.AuthorisationType;
    remotePilotPhoneNumber: string;
    remotePilotFirstName: string;
    remotePilotLastName: string;
    remotePilotEmail: string;
    additionalProviderProperties: {
        safetyJustification: string;
    };
}

export interface AuthorisationError {
    code: number;
    message: string;
}
