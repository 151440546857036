<ng-container *ngIf="!isNewForm">
    <label *ngIf="!inlineLabel">
        <strong>{{ labelName }}: </strong></label
    >
    <label *ngIf="inlineLabel" class="inline-label"> {{ labelName }}: </label>
    <span>{{ model }}</span>

    <button
        *ngIf="canEdit"
        type="button"
        class="btn btn-tertiary"
        #pop="bs-popover"
        [popover]="template"
        (click)="openPopover(pop)"
        container="body"
        [popoverTitle]="popoverTitle != null ? popoverTitle : 'Edit Name'"
        [outsideClick]="true"
        placement="right"
    >
        <span
            class="fal fa-pencil-alt"
            tooltip="Change {{ labelName }}"
            placement="top"
        >
        </span>
    </button>
</ng-container>

<ng-container *ngIf="isNewForm">
    <label *ngIf="!inlineLabel">
        <strong>{{ labelName }}: </strong></label
    >
    <label *ngIf="inlineLabel" class="inline-label"> {{ labelName }}: </label>

    <input
        class="form-control"
        name="name"
        [ngModel]="model"
        (ngModelChange)="onChangeModel($event)"
        required
    />
</ng-container>

<ng-template #template>
    <div style="width: 350px">
        <div class="row">
            <div class="col-xs-12">
                <span>{{ labelName }}</span>
            </div>

            <div class="col-xs-9">
                <input class="form-control" [(ngModel)]="newModel" />
            </div>

            <div class="col-xs-3">
                <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    (click)="saveData()"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</ng-template>
