import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FEATURE_GENERIC_TASKS,
    FEATURE_OUTSTANDING_TASKS_VIEW,
    hasAnyPermission,
    hasFeatureFlag,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatDatePipe } from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { GeneralTask } from 'libs/flyfreely/src/lib/services/generalTasks.service';
import { GeneralTasksDialoguesService } from 'libs/outstanding-tasks/src/lib/widgets/general-task/general-tasks-dialogues.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetRegistrationWithSection } from '../../outstanding-tasks-view.service';
import {
    formatGeneralTasksPriority,
    formatGeneralTasksStatuses,
    GeneralTasksDataService
} from './general-tasks-data.service';

const GENERAL_IDENTIFIER = 'generalTasksWidget';

@Component({
    selector: 'general-tasks-widget',
    templateUrl: './general-tasks-widget.component.html',
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()],
    providers: [GeneralTasksDialoguesService],
    styleUrls: ['./general-tasks-widget.component.scss']
})
export class GeneralTasksWidgetComponent implements OnInit, OnDestroy {
    organisation: PersonsOrganisationDto;
    canAddTask: boolean;
    showBody = true;

    generalTasks: GeneralTask[];

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private workspaceStateService: WorkspaceStateService,
        private generalTasksDataService: GeneralTasksDataService,
        private formatDatePipe: FormatDatePipe,
        private generalTasksDialoguesService: GeneralTasksDialoguesService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.availableColumns = [
            {
                value: 'summary',
                name: 'Title',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'description',
                name: 'Details',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'assignee',
                name: 'Assignee',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'reporter',
                name: 'Reporter',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'dueDate',
                name: 'Due Date',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: t => this.formatDatePipe.transform(t)
            },
            {
                value: 'priority',
                name: 'Priority',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'status',
                name: 'Status',
                searchable: false,
                selectable: false,
                defaultSelection: true
            }
        ];

        this.tableConfig = {
            serverPagination: true,
            limit: 3,
            actions: []
        };
        this.generalTasksDataService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshSubscriptions();
                this.refreshTasks();
            });
        this.refreshPermissions();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        this.canAddTask = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.TASK_ADD
        );
    }

    viewElement(data: any) {
        this.generalTasksDialoguesService.showTaskDetailsDialogue(
            data,
            this.organisation
        );
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.refreshTasks();
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        this.refreshTasks();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.refreshTasks();
    }

    refreshTasks() {
        this.generalTasksDataService.findTasks(
            this.tableConfig.currentPage ?? 0,
            this.tableConfig.limit ?? 3,
            this.organisation.id
        );
    }

    refreshSubscriptions() {
        combineLatest([
            this.generalTasksDataService.generalTasks$,
            this.generalTasksDataService.currentPage$,
            this.generalTasksDataService.totalItems$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([tasks, currentPage, totalItems]) => {
                this.generalTasks = tasks;
                if (this.tableConfig != null) {
                    const currentConfig = this.tableConfig;
                    this.tableConfig = {
                        ...currentConfig,
                        currentPage: currentPage,
                        totalItems: totalItems
                    };
                }
            });
        this.generalTasksDataService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshTasks();
            });
    }

    onAddNewTask() {
        this.generalTasksDialoguesService.showAddTaskDialogue(
            this.organisation
        );
    }

    protected readonly formatPriority = formatGeneralTasksPriority;
    protected readonly formatStatuses = formatGeneralTasksStatuses;
}

export const generalTasksWidgetRegistration: WidgetRegistrationWithSection = {
    widgetIdentifier: GENERAL_IDENTIFIER,
    section: 'general',
    component: GeneralTasksWidgetComponent,
    isAvailable: organisation =>
        hasFeatureFlag(organisation, FEATURE_OUTSTANDING_TASKS_VIEW) &&
        hasFeatureFlag(organisation, FEATURE_GENERIC_TASKS)
};
