import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CreateApiKeyCommand,
    PermissionTypes,
    PersonsOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';

enum Status {
    NONE = 'NONE',
    READ = 'READ',
    WRITE = 'WRITE',
    UPLOAD = 'UPLOAD'
}

interface PermissionsArray {
    name: string;
    options: Status[];
    type: PermissionTypes;
    statusNone: boolean;
    statusRead: boolean;
    statusWrite: boolean;
    statusUpload?: boolean;
    permissions: { [status: string]: CreateApiKeyCommand.Permissions };
}

function toPermissions(
    selectedPermission: PermissionsArray
): CreateApiKeyCommand.Permissions[] {
    return (selectedPermission.statusRead
        ? [selectedPermission.permissions[Status.READ]]
        : []
    )
        .concat(
            selectedPermission.statusWrite
                ? [selectedPermission.permissions[Status.WRITE]]
                : []
        )
        .concat(
            selectedPermission.statusUpload
                ? [selectedPermission.permissions[Status.UPLOAD]]
                : []
        );
}

@Injectable()
export class CreateApiKeyFormService {
    public set organisations(organisations: PersonsOrganisationDto[]) {
        organisations.forEach(e => {
            const organisation = this.fb.group({
                id: e.id,
                name: e.name,
                active: true
            });
            (<FormArray>this.apiForm.controls.formOrganisations).push(
                organisation
            );
        });
        this.hasOrganisations = true;
    }

    hasOrganisations = false;

    permissions: PermissionsArray[] = [
        {
            name: 'Missions',
            options: [Status.NONE, Status.READ, Status.WRITE],
            type: PermissionTypes.MISSIONS,
            statusNone: false,
            statusRead: true,
            statusWrite: false,
            permissions: {
                [Status.READ]: CreateApiKeyCommand.Permissions.MISSIONS_READ,
                [Status.WRITE]: CreateApiKeyCommand.Permissions.MISSIONS_WRITE
            }
        },
        {
            name: 'Personnel',
            options: [Status.NONE, Status.READ, Status.WRITE],
            type: PermissionTypes.PERSONNEL,
            statusNone: false,
            statusRead: true,
            statusWrite: false,
            permissions: {
                [Status.READ]: CreateApiKeyCommand.Permissions.PERSONNEL_READ,
                [Status.WRITE]: CreateApiKeyCommand.Permissions.PERSONNEL_WRITE
            }
        },
        {
            name: 'Resources',
            options: [Status.NONE, Status.READ, Status.WRITE],
            type: PermissionTypes.RESOURCES,
            statusNone: false,
            statusRead: true,
            statusWrite: false,
            permissions: {
                [Status.READ]: CreateApiKeyCommand.Permissions.RESOURCES_READ,
                [Status.WRITE]: CreateApiKeyCommand.Permissions.RESOURCES_WRITE
            }
        },
        {
            name: 'Organisation',
            options: [Status.NONE, Status.READ, Status.WRITE],
            type: PermissionTypes.ORGANISATION,
            statusNone: false,
            statusRead: true,
            statusWrite: false,
            permissions: {
                [Status.READ]:
                    CreateApiKeyCommand.Permissions.ORGANISATION_READ,
                [Status.WRITE]:
                    CreateApiKeyCommand.Permissions.ORGANISATION_WRITE
            }
        },
        {
            name: 'Profile',
            options: [Status.NONE, Status.READ, Status.WRITE],
            type: PermissionTypes.PROFILE,
            statusNone: false,
            statusRead: true,
            statusWrite: false,
            permissions: {
                [Status.READ]: CreateApiKeyCommand.Permissions.PROFILE_READ,
                [Status.WRITE]: CreateApiKeyCommand.Permissions.MISSIONS_WRITE
            }
        },
        {
            name: 'Flight Logs',
            options: [Status.NONE, Status.READ, Status.WRITE, Status.UPLOAD],
            type: PermissionTypes.FLIGHT_LOGS,
            statusNone: false,
            statusRead: true,
            statusWrite: false,
            statusUpload: false,
            permissions: {
                [Status.READ]: CreateApiKeyCommand.Permissions.FLIGHT_LOGS_READ,
                [Status.WRITE]:
                    CreateApiKeyCommand.Permissions.FLIGHT_LOGS_WRITE,
                [Status.UPLOAD]:
                    CreateApiKeyCommand.Permissions.FLIGHT_LOGS_UPLOAD
            }
        }
    ];

    apiForm: FormGroup;

    constructor(private fb: FormBuilder) {
        this.setupForm();
    }

    reset() {
        const permissions = this.apiForm.value.formPermissions.map(
            (p: PermissionsArray) => ({
                ...p,
                statusNone: false,
                statusRead: true,
                statusWrite: false
            })
        );
        const organisations = this.apiForm.value.formOrganisations.map(o => ({
            ...o,
            active: true
        }));
        this.apiForm.setValue({
            name: '',
            allOrganisations: true,
            allPermissions: true,
            formOrganisations: organisations,
            formPermissions: permissions
        });
        this.apiForm.updateValueAndValidity();
        this.apiForm.markAsPristine();
    }

    private setupForm() {
        this.apiForm = this.fb.group({
            name: this.fb.control('', Validators.required),
            allOrganisations: this.fb.control(true, Validators.required),
            allPermissions: this.fb.control(true, Validators.required),
            formOrganisations: this.fb.array([]),
            formPermissions: this.fb.array([])
        });
        this.populateForm();
    }

    private populateForm() {
        this.permissions.forEach(e => {
            const permission = this.fb.group({
                name: e.name,
                options: [e.options],
                type: e.type,
                statusNone: e.statusNone,
                statusRead: e.statusRead,
                statusWrite: e.statusWrite,
                statusUpload: e.statusUpload != null ? e.statusUpload : null,
                permissions: e.permissions
            });
            (<FormArray>this.apiForm.controls.formPermissions).push(permission);
        });
    }

    togglePermissions(permission: FormGroup, val: string) {
        if (val === 'None') {
            if (permission.controls.statusUpload.value == null) {
                permission.patchValue({
                    statusNone: true,
                    statusRead: false,
                    statusWrite: false
                });
            } else {
                permission.patchValue({
                    statusNone: true,
                    statusRead: false,
                    statusWrite: false,
                    statusUpload: false
                });
            }
            return;
        }
        if (val === 'Read') {
            permission.controls.statusRead.value === true
                ? permission.patchValue({ statusRead: false })
                : permission.patchValue({ statusRead: true });
        }
        if (val === 'Write') {
            permission.controls.statusWrite.value === true
                ? permission.patchValue({ statusWrite: false })
                : permission.patchValue({ statusWrite: true });
        }
        if (val === 'Upload') {
            permission.controls.statusUpload.value === true
                ? permission.patchValue({ statusUpload: false })
                : permission.patchValue({ statusUpload: true });
        }
        if (
            permission.controls.statusRead.value === false &&
            permission.controls.statusWrite.value === false &&
            permission.controls.statusUpload.value !== true
        ) {
            permission.patchValue({ statusNone: true });
        } else {
            permission.patchValue({ statusNone: false });
        }
    }

    mapPermissions(permissions: PermissionsArray[]) {
        return permissions.reduce(
            (acc, p) => acc.concat(toPermissions(p)),
            [] as CreateApiKeyCommand.Permissions[]
        );
    }

    mapOrganisations(organisations: any[]) {
        return organisations.filter(o => o.active === true).map(o => o.id);
    }
}
