import { Component, Input } from '@angular/core';
import {
    BatteryTypeDto,
    BatteryTypeService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BatteryDialoguesService } from '../../battery-dialogues.service';

@Component({
    selector: 'battery-type-list',
    templateUrl: './battery-type-list.component.html'
})
export class BatteryTypeList {
    @Input() organisation: PersonsOrganisationDto;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    batteryTypes: BatteryTypeDto[];
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;

    constructor(
        private batteryTypeService: BatteryTypeService,
        private batteryDialogues: BatteryDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.availableColumns = [
            {
                value: 'make',
                name: 'Make',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.showBatteryTypeDetails(row)
            },
            {
                value: 'model',
                name: 'Model',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.showBatteryTypeDetails(row)
            },
            {
                value: 'chemistry',
                name: 'Chemistry',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.showBatteryTypeDetails(row)
            }
        ];
        this.selectedColumns = null;
        this.columnSorting = {
            column: 'make',
            ascending: true
        };
        this.tableConfig = {
            limit: 25,
            actions: []
        };
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshBatteryTypes();

        this.batteryTypeService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshBatteryTypes());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshBatteryTypes() {
        this.batteryTypeService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                batteryTypes => (this.batteryTypes = batteryTypes),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while loading battery types: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    showBatteryTypeDetails(batteryType: BatteryTypeDto) {
        this.batteryDialogues.showBatteryTypeEditDialogue(
            batteryType,
            this.organisation
        );
    }

    createBatteryType() {
        this.batteryDialogues.showBatteryTypeEditDialogue(
            { organisationId: this.organisation.id },
            this.organisation
        );
    }

    updateSelectedColumns(selectedColumns: any) {
        this.selectedColumns = selectedColumns;
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
    }
}
