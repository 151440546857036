import { Component, Input } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionApprovalService,
    PersonsOrganisationDto,
    RequestersMissionApprovalDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getSimplifiedAuthorityFromApproval } from '../helpers';
import { MissionApprovalWithAuthority } from '../interfaces';

@Component({
    selector: 'accept-mission-approval-dialogue',
    template: `
        <div class="modal-header no-icon">
            <h3 class="modal-title">Accept Mission Approval</h3>
            <button
                type="button"
                (click)="cancel()"
                aria-label="Close"
                class="close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content inner-container-and-footer">
                <div class="contents">
                    <div class="top-buffer">
                        <label class="display-label">Approval Status</label>
                        <div>
                            {{ approval.status | formatApprovalStatus }}
                        </div>
                    </div>
                    <div
                        *ngIf="missionApproval.authorityType?.ruleset"
                        class="top-buffer"
                    >
                        <label class="display-label">Ruleset:</label>
                        {{
                            missionApproval.authorityType?.ruleset?.name != null
                                ? missionApproval.authorityType?.ruleset?.name
                                : 'None'
                        }}
                    </div>
                    <div class="top-buffer">
                        <label class="display-label">Authority:</label>
                        {{
                            (getSimplifiedAuthorityFromApproval(
                                missionApproval,
                                organisation
                            ) | formatAuthority) || 'None'
                        }}
                    </div>
                    <div class="top-buffer">
                        <label class="display-label">Approved By</label>
                        <div>
                            {{ approval.approvedBy | formatPerson }}&nbsp;
                            {{
                                approval.resolutionTime
                                    | date: 'dd/MM/yyyy H:mm'
                            }}
                        </div>
                    </div>
                    <div class="top-buffer">
                        <label class="display-label">
                            Message From Approver
                        </label>
                        <div>
                            {{ approval.messageFromApprover }}
                        </div>
                    </div>
                    <div class="top-buffer">
                        <label class="display-label">Maximum Height</label>
                        <div>
                            {{ approval.maximumHeight
                            }}<span *ngIf="approval.maximumHeight != null"
                                >&nbsp;ft AGL</span
                            >
                        </div>
                    </div>
                    <div class="top-buffer">
                        <label class="display-label">Conditions</label>
                        <div *ngFor="let condition of approval.conditions">
                            {{ condition }}
                        </div>
                    </div>
                </div>
                <div class="action-container">
                    <button
                        class="btn btn-primary"
                        (click)="acceptApproval()"
                        [disabled]="working"
                    >
                        Accept Approval
                    </button>
                </div>
            </div>
            <ngx-loading [show]="working"></ngx-loading>
        </div>
    `
})
export class AcceptMissionApprovalDialogueComponent {
    @Input() missionApproval: MissionApprovalWithAuthority;
    @Input() organisation: PersonsOrganisationDto;

    approval: RequestersMissionApprovalDto;

    getSimplifiedAuthorityFromApproval = getSimplifiedAuthorityFromApproval;

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private approvalService: MissionApprovalService,
        private logging: FlyFreelyLoggingService,
        private modal: BsModalRef
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.approval = this.missionApproval
            .approval as RequestersMissionApprovalDto;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    acceptApproval() {
        this.approvalService
            .acceptApproval(this.approval.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: approval => {
                    this.logging.success('Approval Accepted');
                    this.cancel();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error accepting approval: ${error.message}`
                    );
                }
            });
    }

    cancel() {
        this.modal.hide();
    }
}
