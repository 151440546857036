import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SetupGuideService, OnboardingService } from '@flyfreely-portal-ui/flyfreely';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ngfModule } from 'angular-file';
import { Angulartics2Module } from 'angulartics2';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { SidebarActivityStreamModule } from 'libs/sidebar-activity-stream/src/lib/sidebar-activity-stream.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLoadingModule } from 'ngx-loading';
import { WebcamModule } from 'ngx-webcam';
import { OrganisationFooter } from './footer/footer-organisation-details/footer-organisation-details.component';
import { PersonalFooter } from './footer/footer-personal-details/footer-personal-details.component';
import { HeaderComponent } from './header/header.component';
import { OnboardingHelpDialogue } from './help-dialogue/help-dialogue.component';
import { LogoSelection } from './logo-selection';
import { OnboardingDialoguesService } from './onboarding-dialogues.service';
import { OnboardingData } from './onboarding-screens-data.service';
import { FieldAppDownload } from './onboarding-screens/field-app-download/field-app-download.component';
import { FlightHistoryComponent } from './onboarding-screens/flight-history/flight-history.component';
import { FlightPreferencesComponent } from './onboarding-screens/flight-preferences/flight-preferences.component';
import { OnboardingScreensComponent } from './onboarding-screens/onboarding-screens.component';
import { OrganisationCertificationComponent } from './onboarding-screens/organisation-certification/organisation-certification.component';
import { OrganisationDetailsComponent } from './onboarding-screens/organisation-details/organisation-details.component';
import { PersonalCertificationComponent } from './onboarding-screens/personal-certification/personal-certification.component';
import { PersonalDetailsCompleteComponent } from './onboarding-screens/personal-details-complete/personal-details-complete.component';
import { PersonalDetailsComponent } from './onboarding-screens/personal-details/personal-details.component';
import { PersonalPrimaryComponent } from './onboarding-screens/personal-primary/personal-primary.component';
import { SetupFinishedComponent } from './onboarding-screens/setup-finished/setup-finished.component';
import { SwitchToDesktop } from './onboarding-screens/switch-to-desktop/switch-to-desktop.component';
import { Onboarding } from './onboarding.component';
import { WebcamDialogue } from './webcam-dialogue/webcam-dialogue.component';
import { WelcomeBlockComponent } from './welcome-block/welcome-block.component';
import { RouterModule } from '@angular/router';

/**
 * The onboarding module is a top level set of screens that are displayed to the user the first time through the application. They
 * deal with collecting required information from the user so that their first use of the application is pleasant.
 *
 * The {@link OnboardingData} service provides coordination about which screen to display.
 * The {@link Onboarding} component is responsible for showing the header, footer, and the {@link OnboardingScreensComponent}.
 * The {@link OnboardingScreensComponent} is responsible for selecting the correct onboarding screen to display.
 * Each screen is responsible for persisting the information it collects, and for allowing forward/backward navigation.
 */
@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forChild(),
        BsDatepickerModule,
        TooltipModule,
        BsDatepickerModule,
        ngfModule,
        SharedUiModule,
        ButtonsModule,
        FormlyModule,
        FormlyBootstrapModule,
        NgxLoadingModule,
        FlyFreelyFormlyModule,
        ValidationModule,
        Angulartics2Module,
        WebcamModule,
        ImageCropperModule,
        EnhancedHelpModule,
        AnalyticsModule,
        SidebarActivityStreamModule,
        RouterModule.forChild([])
    ],
    declarations: [
        Onboarding,
        HeaderComponent,
        WelcomeBlockComponent,
        PersonalFooter,
        OnboardingScreensComponent,
        PersonalDetailsComponent,
        PersonalCertificationComponent,
        PersonalPrimaryComponent,
        FlightHistoryComponent,
        FlightPreferencesComponent,
        PersonalDetailsCompleteComponent,
        OrganisationFooter,
        OrganisationDetailsComponent,
        OrganisationCertificationComponent,
        SetupFinishedComponent,
        LogoSelection,
        OnboardingHelpDialogue,
        WebcamDialogue,
        FieldAppDownload,
        SwitchToDesktop
    ],
    providers: [
        OnboardingService,
        OnboardingData,
        OnboardingDialoguesService,
        SetupGuideService
    ],
    bootstrap: []
})
export class OnboardingModule {}
