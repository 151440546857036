<ng-container screenAnalytics="form-catalogue">
    <div class="modal-header no-icon">
        <h3 class="modal-title">FlyFreely Form Library</h3>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <enhanced-help
            componentName="overview"
            [compact]="true"
            [alwaysActive]="true"
        >
        </enhanced-help>
        <entity-list
            [canAdd]="false"
            [items]="items"
            [working]="working"
            [canClone]="true"
            [canArchive]="false"
            type="Form"
            (edit)="formDetails($event)"
            (clone)="cloneForm($event)"
        >
        </entity-list>
    </div>
</ng-container>
