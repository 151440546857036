import { Component, Input } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    CreateMaintenanceScheduleCommand,
    EQUIPMENT_CATEGORIES,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceScheduleDto,
    MaintenanceScheduleService,
    MaintenanceScheduleTaskCommand,
    MaintenanceScheduleTriggerCommand,
    MaintenanceScheduleVersionDto,
    NameValue,
    RPA_CATEGORIES,
    SimpleMaintenanceScheduleDto,
    StepDescription,
    WorkTracker,
    deepCopy,
    nextVersion,
    observeFormControl
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import { cleanSteps } from 'libs/workflows/src/lib/helpers';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subject, from, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import {
    MaintenanceScheduleDialogueService,
    maintenanceScheduleTypes
} from './maintenance-schedule-dialogue.service';

function findSubCategory(schedule: MaintenanceScheduleDto): string {
    switch (schedule.resourceCategory) {
        case 'CRAFT':
            return schedule.rpaCategory;
        case 'EQUIPMENT':
            return schedule.equipmentCategory;
        default:
            return null;
    }
}

interface ResourceTypeForm {
    resourceCategory: FormControl<CreateMaintenanceScheduleCommand.ResourceCategory>;
    // This is the RPA category/Equipment category, etc
    subCategory: FormControl<string>;
}

interface ResourceFormValue {
    name: string;
    resourceType: {
        resourceCategory: CreateMaintenanceScheduleCommand.ResourceCategory;
        subCategory: string;
    };
    taskList: MaintenanceScheduleTaskCommand[];
    triggerList: MaintenanceScheduleTriggerCommand[];
}

/**
 * Provides maintenance schedule editing, including the creation of
 * new maintenance schedules.
 *
 * The main state is controlled by `isNew` which has 3 values:
 * NEW - for brand new schedules without a resource category
 * CATEGORY - for schedules that have a category and optionally a subcategory set
 * SAVED - for all saved schedules
 *
 * The category and subcategory can only be set in the NEW state. All other details can only be set once in CATEGORY or SAVED.
 */
@Component({
    selector: 'maintenance-schedule-dialogue',
    templateUrl: './maintenance-schedule-dialogue.component.html',
    providers: [MaintenanceScheduleDialogueService]
})
export class MaintenanceScheduleDialogue {
    /**
     * The organisation ID to create this maintenance schedule in
     */
    @Input() organisationId: number;
    /**
     * The maintenance schedule to pre-populate the UI with
     */
    @Input() maintenanceSchedule: SimpleMaintenanceScheduleDto;

    working = false;
    private workTracker = new WorkTracker();
    status: 'LOADING' | 'LOADED' = 'LOADING';
    maintenanceScheduleTypes = maintenanceScheduleTypes;

    private ngUnsubscribe$ = new Subject<void>();

    versionSelector = new FormControl<number>(undefined);

    formGroup = new FormGroup<{
        name: FormControl<string>;
        resourceType: FormGroup<ResourceTypeForm>;
        taskList: FormControl<MaintenanceScheduleTaskCommand[]>;
        triggerList: FormControl<MaintenanceScheduleTriggerCommand[]>;
    }>({
        name: new FormControl<string>(undefined, [Validators.required]),
        resourceType: new FormGroup({
            resourceCategory:
                new FormControl<CreateMaintenanceScheduleCommand.ResourceCategory>(
                    CreateMaintenanceScheduleCommand.ResourceCategory.CRAFT,
                    [Validators.required]
                ),
            subCategory: new FormControl<string>(undefined, [
                Validators.required
            ])
        }),

        // This is correct, the subcomponent deals with the array
        taskList: new FormControl<MaintenanceScheduleTaskCommand[]>(
            [],
            [Validators.required]
        ),
        triggerList: new FormControl<MaintenanceScheduleTriggerCommand[]>(
            [],
            [Validators.required]
        )
    });
    resourceTypeFormGroup = this.formGroup.controls.resourceType;

    subCategoryList$: Observable<NameValue[]>;

    steps: StepDescription[];
    forms$ = this.maintenanceScheduleDialogueService.formList$;
    schedule: MaintenanceScheduleDto;
    versions: MaintenanceScheduleVersionDto[];
    currentVersion: MaintenanceScheduleVersionDto;
    activeVersionId: number;
    isNew: 'NEW' | 'CATEGORY' | 'SAVED';
    scheduleName: string;

    constructor(
        public modal: BsModalRef,
        modalOptions: ModalOptions,
        private logging: FlyFreelyLoggingService,
        private maintenanceScheduleService: MaintenanceScheduleService,
        private maintenanceScheduleDialogueService: MaintenanceScheduleDialogueService,
        private commonDialoguesService: CommonDialoguesService
    ) {
        modalOptions.closeInterceptor = () =>
            preHide(
                this.formGroup as unknown as FormGroup,
                this.commonDialoguesService
            );
    }

    ngOnInit() {
        this.steps = this.maintenanceScheduleService.getWorkflowSteps();

        if (this.maintenanceSchedule != null) {
            this.maintenanceScheduleService
                .findById(this.maintenanceSchedule.id)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(maintenanceSchedule =>
                    this.setupSchedule(maintenanceSchedule)
                );
        } else {
            this.setupSchedule(null);
        }

        this.versionSelector.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe$))
            .subscribe(selectedVersionId => {
                if (this.schedule != null) {
                    this.setupCurrentVersion(
                        this.schedule.versions.find(
                            v => v.id === selectedVersionId
                        )
                    );
                }
            });

        this.subCategoryList$ =
            observeFormControl<CreateMaintenanceScheduleCommand.ResourceCategory>(
                this.resourceTypeFormGroup.controls.resourceCategory
            ).pipe(
                map(resourceCategory => {
                    switch (resourceCategory) {
                        case 'CRAFT':
                            return RPA_CATEGORIES;
                        case 'EQUIPMENT':
                            return EQUIPMENT_CATEGORIES;
                        default:
                            return undefined;
                    }
                })
            );
        // Batteries don't have a sub category, so disable to disable the requirement.
        this.resourceTypeFormGroup.controls.resourceCategory.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(resourceCategory => {
                if (resourceCategory === 'BATTERY') {
                    this.resourceTypeFormGroup.controls.subCategory.disable();
                } else {
                    this.resourceTypeFormGroup.controls.subCategory.enable();
                }
            });
    }

    private setupSchedule(schedule: MaintenanceScheduleDto) {
        if (schedule == null) {
            this.schedule = schedule;
            this.formGroup.patchValue({ name: undefined });
            this.versionSelector.setValue(null);
            this.versions = [];
            this.activeVersionId = null;

            this.setupCurrentVersion(null);

            observeFormControl<CreateMaintenanceScheduleCommand.ResourceCategory>(
                this.resourceTypeFormGroup.controls.resourceCategory
            )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(resourceCategory =>
                    this.maintenanceScheduleDialogueService.setup(
                        this.organisationId,
                        resourceCategory
                    )
                );

            this.isNew = 'NEW';
            this.formGroup.markAsPristine();

            this.status = 'LOADED';
            return;
        }

        this.isNew = 'SAVED';
        this.versions = schedule.versions ?? [];
        this.activeVersionId = schedule.activeVersionId;
        this.formGroup.patchValue({
            name: schedule.name
        });
        this.resourceTypeFormGroup.patchValue({
            resourceCategory: schedule.resourceCategory,
            subCategory: findSubCategory(schedule)
        });
        this.schedule = schedule;

        this.setupCurrentVersion(
            schedule.versions.find(v => v.id === schedule.activeVersionId)
        );

        this.formGroup.patchValue({
            taskList: this.currentVersion.taskList.map(t => ({
                componentGroupId: t.componentGroup?.id,
                taskTypeId: t.taskType?.id,
                cycles: t.cycles,
                inServiceTime: t.inServiceTime,
                calendarTime: t.calendarTime
            })),
            triggerList: this.schedule.triggerList.map(
                t =>
                    ({
                        type: t.type,
                        action: t.action,
                        inServiceTime: t.inServiceTime,
                        cycles: t.cycles,
                        calendarTime: t.calendarTime
                    } as MaintenanceScheduleTriggerCommand)
            )
        });

        this.maintenanceScheduleDialogueService.setup(
            this.organisationId,
            schedule.resourceCategory
        );
        this.formGroup.markAsPristine();

        this.status = 'LOADED';
    }

    private setupCurrentVersion(version: MaintenanceScheduleVersionDto) {
        if (version == null) {
            this.versionSelector.setValue(null);
            this.currentVersion = {
                id: null,
                availableActions: {
                    canEdit: true
                },
                mappedForms: cleanSteps(this.steps, {}),
                mappedAttachmentRequirements: cleanSteps(this.steps, {}, false),
                taskList: [],
                versionNumber: 1
            };

            return;
        }

        this.versionSelector.setValue(version.id);
        this.currentVersion = deepCopy(version);
        this.currentVersion.mappedForms = cleanSteps(
            this.steps,
            version.mappedForms
        );
        this.currentVersion.mappedAttachmentRequirements = cleanSteps(
            this.steps,
            version.mappedAttachmentRequirements,
            false
        );
    }

    changeVersion(currentVersionId: number) {
        this.currentVersion.id = currentVersionId;
        const version =
            this.currentVersion.id != null
                ? this.versions.find(v => v.id === this.currentVersion.id)
                : null;

        if (version != null) {
            this.currentVersion = {
                id: version.id,
                versionNumber: version.versionNumber,
                mappedForms: cleanSteps(this.steps, version.mappedForms),
                mappedAttachmentRequirements: cleanSteps(
                    this.steps,
                    version.mappedAttachmentRequirements,
                    false
                ),
                taskList: version.taskList,
                availableActions: version.availableActions
            };
        } else {
            this.currentVersion = {
                id: null,
                mappedForms: cleanSteps(this.steps, {}),
                mappedAttachmentRequirements: cleanSteps(this.steps, {}, false),
                taskList: [],
                availableActions: { canEdit: true },
                versionNumber: 1
            };
        }
    }

    cloneVersion() {
        const newVersion: MaintenanceScheduleVersionDto = {
            ...this.currentVersion,
            id: null,
            availableActions: { canEdit: true },
            versionNumber: nextVersion(this.versions)
        };
        this.currentVersion = newVersion;
        this.versions.push(this.currentVersion);
        this.versionSelector.patchValue(this.currentVersion.id);
    }

    toggleActiveVersion() {
        if (!this.currentVersion.id) {
            return;
        }

        const scheduleId = this.schedule.id;

        const scheduleVersionId =
            this.currentVersion.id === this.activeVersionId
                ? null
                : this.currentVersion.id;

        this.maintenanceScheduleService
            .changeActiveVersion(scheduleId, {
                maintenanceScheduleVersionId: scheduleVersionId
            })
            .then(schedule => {
                this.logging.success('Active workflow version changed');
                this.schedule = schedule;
                this.activeVersionId = schedule.activeVersionId;
            });
    }

    confirmCategory() {
        this.isNew = 'CATEGORY';
    }

    // Callback for name editable
    updateName = ({ name }: { name: string }) => {
        return this.maintenanceScheduleService
            .updateMaintenanceScheduleName(this.maintenanceSchedule.id, {
                name
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(
                () => this.formGroup.patchValue({ name }),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error updating maintenance schedule: ${error.message}`
                    );
                    return Promise.reject(error);
                }
            )
            .finally(this.workTracker.createTracker());
    };

    doSave() {
        const values = this.formGroup.value as ResourceFormValue;
        if (this.isNew !== 'SAVED') {
            return this.maintenanceScheduleService
                .create({
                    ...this.currentVersion,
                    ...values,
                    resourceCategory: values.resourceType.resourceCategory,
                    rpaCategory:
                        values.resourceType.resourceCategory ===
                        MaintenanceScheduleDto.ResourceCategory.CRAFT
                            ? <any>values.resourceType.subCategory
                            : null,
                    equipmentCategory:
                        values.resourceType.resourceCategory ===
                        MaintenanceScheduleDto.ResourceCategory.EQUIPMENT
                            ? <any>values.resourceType.subCategory
                            : null,
                    inFieldServiceableComponentGroupIds: [],
                    organisationId: this.organisationId
                })
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    tap(savedSchedule => {
                        this.setupSchedule(savedSchedule);
                    })
                );
        } else {
            const triggers: (
                schedule: MaintenanceScheduleDto
            ) => Observable<MaintenanceScheduleDto> = (
                schedule: MaintenanceScheduleDto
            ) =>
                this.formGroup.controls.triggerList.dirty
                    ? this.maintenanceScheduleService.updateMaintenanceScheduleTriggers(
                          schedule.id,
                          { triggerList: values.triggerList }
                      )
                    : of(schedule);

            const update: (
                schedule: MaintenanceScheduleDto
            ) => Observable<MaintenanceScheduleDto> = (
                schedule: MaintenanceScheduleDto
            ) =>
                this.formGroup.controls.taskList.dirty
                    ? this.maintenanceScheduleService.update(schedule.id, {
                          taskList: values.taskList,
                          maintenanceScheduleVersionId: this.currentVersion.id,
                          inFieldServiceableComponentGroupIds: [],
                          mappedAttachmentRequirements:
                              this.currentVersion.mappedAttachmentRequirements,
                          mappedForms: this.currentVersion.mappedForms
                      })
                    : of(schedule);

            const confirm = this.maintenanceScheduleService
                .preview(this.schedule.id, {
                    taskList: values.taskList,
                    triggerList: values.triggerList
                })
                .pipe(
                    switchMap(result =>
                        result.value === 0
                            ? of(true)
                            : from(
                                  this.commonDialoguesService.showConfirmationDialogue(
                                      'Schedule Changes',
                                      `These changes will trigger ${result.value} resources to have their schedule changed (either triggered or cleared).`,
                                      'Continue',
                                      () => Promise.resolve()
                                  )
                              ).pipe(
                                  map(() => true),
                                  catchError(() => of(false))
                              )
                    ),
                    filter(cont => cont)
                );

            return confirm.pipe(
                map(() => this.schedule),
                switchMap(schedule => triggers(schedule)),
                switchMap(schedule => update(schedule)),
                tap(savedSchedule => {
                    savedSchedule = {
                        ...savedSchedule,
                        name: values.name
                    };
                    this.setupSchedule(savedSchedule);
                })
            );
        }
    }

    save() {
        this.doSave()
            .subscribe(
                () => {
                    this.logging.success('Saved');
                    this.formGroup.markAsPristine();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error saving maintenance schedule: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    saveAndClose() {
        this.doSave()
            .subscribe(
                () => {
                    this.logging.success('Saved');
                    this.formGroup.markAsPristine();
                    this.modal.hide();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error saving maintenance schedule: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }
}
