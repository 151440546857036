<div>
    <ng-container *ngIf="history">
        <table class="table">
            <tr>
                <th></th>
                <th>Flights</th>
                <th>Flight Time</th>
            </tr>
            <tr>
                <th>
                    This Organisation
                    <span *ngIf="history.thisOrganisation.dateOfLastMission != null"
                        >(last mission
                        {{
                            history.thisOrganisation.dateOfLastMission | formatDate
                        }})</span
                    >
                </th>
                <td>{{ history.thisOrganisation.numberOfFlights }}</td>
                <td>{{ history.thisOrganisation.flightTime | formatDuration  | defaultTo }}</td>
            </tr>
            <tr>
                <th>Other Organisations</th>
                <td>{{ history.otherOrganisations.numberOfFlights }}</td>
                <td>
                    {{ history.otherOrganisations.flightTime | formatDuration  | defaultTo }}
                </td>
            </tr>
            <tr>
                <th>Historical</th>
                <td>{{ history.historicalFlying.numberOfFlights }}</td>
                <td>
                    {{ history.historicalFlying.flightTime | formatDuration  | defaultTo }}
                </td>
            </tr>

            <tr style="border-top: 1px solid">
                <th>Total</th>
                <td>{{ history.realFlying.numberOfFlights }}</td>
                <td>{{ history.realFlying.flightTime | formatDuration  | defaultTo }}</td>
            </tr>
        </table>
    </ng-container>
</div>
