import { Directive, TemplateRef } from '@angular/core';

/**
 * This directive indicates the template to use when the field is locked.
 */
@Directive({
    selector: '[lockedMode]'
})
export class LockedModeDirective {
    constructor(public tpl: TemplateRef<any>) {}
}
