import { Component, Input } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicPipe } from 'libs/ui/src/lib/pipes/generic/dynamic.pipe';

@Component({
    selector: 'formly-view',
    template: `
        <div>
            <div *ngFor="let field of fields">
                <label>{{ field.props.label }}</label>
                <p>
                    {{ formatprops(model[field.key], field.props.formatter) }}
                </p>
            </div>
        </div>
    `
})
export class FormlyView {
    @Input() model: any;
    @Input() fields: FormlyFieldConfig[];

    constructor(private dynamicPipe: DynamicPipe) {}

    formatprops(model: any, formatter: any, args?: any) {
        const value = this.dynamicPipe.transform(model, formatter, args ?? []);
    }
}
