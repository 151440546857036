<div class="map-marker-toolbar">
    <div
        class="input-group map-marker-categories"
        btnRadioGroup
        (ngModelChange)="onCategoryChange()"
        [(ngModel)]="feature.categoryId"
    >
        <label
            class="btn btn-xs btn-pill"
            [btnRadio]="category.id"
            *ngFor="let category of featureGroup.categories"
            >{{ category.name }}</label
        >
    </div>
    <!-- 
        to prevent a delete or backspace press to propagate to MapService which will delete the feature,
        $event.stopPropagation() is used 
    -->
    <input
        *ngIf="feature"
        class="form-control input-sm fill top-buffer"
        placeholder="Feature Name"
        (keyup)="$event.stopPropagation()"
        [(ngModel)]="feature.name"
        (ngModelChange)="onFeatureNameChange()"
    />

    <div
        *ngIf="feature?.properties?.lineOffsetMeters"
        class="line-offset-container"
    >
        <label for="lineoffset-meters">Line offset (meters)</label>
        <input
            id="lineoffset-meters"
            class="form-control input-sm fill top-buffer"
            type="number"
            min="1"
            (keyup)="$event.stopPropagation()"
            [(ngModel)]="feature.properties.lineOffsetMeters"
            (ngModelChange)="onFeatureOffsetChange()"
        />
    </div>

    <div
        *ngIf="
            allowEditCoordinates &&
            feature &&
            feature.geom &&
            feature.geom.type === 'Point'
        "
    >
        <label for="longitude">Longitude</label>
        <input
            class="form-control input-sm fill top-buffer"
            placeholder="Longitude"
            (keyup)="$event.stopPropagation()"
            [(ngModel)]="feature.geom.coordinates[0]"
            (ngModelChange)="onFeatureCoordinatesChange()"
        />

        <label for="lattitude">Latitude</label>
        <input
            class="form-control input-sm fill top-buffer"
            placeholder="Latitude"
            (keyup)="$event.stopPropagation()"
            [(ngModel)]="feature.geom.coordinates[1]"
            (ngModelChange)="onFeatureCoordinatesChange()"
        />
    </div>

    <div class="">
        <button
            type="button"
            class="btn btn-tertiary"
            tooltip-placement="right"
            tooltip="Edit feature"
            (click)="onFeatureEditClick()"
        >
            <span class="fal fa-pencil-alt"></span>
        </button>
        <button
            type="button"
            class="btn btn-tertiary btn-delete"
            tooltip="Delete feature"
            (click)="onFeatureDeleteClick()"
            placement="right"
        >
            <span class="fal fa-trash-alt"></span>
        </button>
    </div>
</div>
