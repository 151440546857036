<ng-container [formGroup]="formGroup">
    <div class="form-inline d-flex">
        <div class="input-group" *ngIf="showHour">
            <input
                id="hour"
                type="number"
                min="0"
                max="100"
                class="form-control"
                style="min-width: 50px"
                formControlName="hours"
                [ngClass]="inputSize()"
                [readonly]="readonly"
            />

            <span class="input-group-addon" [ngClass]="{ readonly: readonly }"
                >h</span
            >
        </div>

        <div class="input-group" *ngIf="showMinute">
            <input
                id="minutes"
                type="number"
                min="0"
                max="59"
                class="form-control"
                style="min-width: 50px"
                formControlName="minutes"
                [ngClass]="inputSize()"
                [readonly]="readonly"
            />

            <span class="input-group-addon" [ngClass]="{ readonly: readonly }"
                >m</span
            >
        </div>

        <div class="input-group" *ngIf="showSecond">
            <input
                id="seconds"
                type="number"
                min="0"
                max="59"
                class="form-control"
                style="min-width: 50px"
                formControlName="seconds"
                [ngClass]="inputSize()"
                [readonly]="readonly"
            />

            <span class="input-group-addon" [ngClass]="{ readonly: readonly }"
                >s</span
            >
        </div>

        <button
            type="button"
            class="btn btn-tertiary btn-delete"
            (click)="onRemove()"
            [disabled]="readonly"
            *ngIf="clearable"
        >
            <span class="fal fa-trash-alt"></span>
        </button>
    </div>
</ng-container>
