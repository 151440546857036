import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AttachmentHandler,
    CraftDetailsDto,
    CraftService,
    DownloadService,
    FEATURE_WORKGROUPS,
    FlyFreelyLoggingService,
    hasFeatureFlag,
    OrganisationDto,
    OrganisationService,
    PersonsOrganisationDto,
    PrintOption,
    RpaReportService,
    toLocalDate,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'rpa-view',
    templateUrl: './rpa-view.component.html'
})
export class RpaView implements OnInit, OnChanges, OnDestroy {
    @Input() organisation: PersonsOrganisationDto | undefined;
    @Input() managingOrganisationId: number;
    @Input() rpa: CraftDetailsDto;
    @Input() allowEdit: boolean;
    @Output() edit = new EventEmitter<void>();
    @Output() deleted = new EventEmitter<void>();
    @Output() update = new EventEmitter<CraftDetailsDto>();

    workgroups: string;

    owner: OrganisationDto;

    workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    // Does the container allow editing
    canUseNfc: boolean;

    maximumVisualDistance: {
        value: number;
        source: 'TYPE' | 'CUSTOM' | 'NONE';
    };

    // Is editing available
    hasEdit = false;
    hasDelete = false;
    canEdit = true;
    canDelete: boolean;
    canManage: boolean;
    canUseWorkgroups = false;
    attachmentsHandler: AttachmentHandler;

    printOptions: PrintOption[] = [];

    constructor(
        private logging: FlyFreelyLoggingService,
        private craftService: CraftService,
        private organisationService: OrganisationService,
        private commonDialoguesService: CommonDialoguesService,
        private rpaReportService: RpaReportService,
        private downloadService: DownloadService,
        private workspaceStateService: WorkspaceStateService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.canUseNfc = false;
    }

    ngOnInit() {
        this.attachmentsHandler = this.craftService.attachmentHandler(
            this.rpa.id,
            this.managingOrganisationId
        );

        this.refreshPermissions();
        this.workspaceStateService.currentOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(event => {
                if (event.type === 'organisation_loaded') {
                    this.canUseWorkgroups = hasFeatureFlag(
                        this.organisation,
                        FEATURE_WORKGROUPS
                    );
                }
            });
        this.workgroups =
            this.rpa != null
                ? this.rpa.workgroupList
                      .map(workgroup => workgroup.name)
                      .join(', ')
                : null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('rpa' in changes) {
            this.refreshPermissions();
            this.refreshOwner();
            this.refreshAvailableReports();
            this.refreshMaximumVisualDistance();
        }
    }

    ngOnDestroy() {
        if (this.attachmentsHandler != null) {
            this.attachmentsHandler.destroy();
        }
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshMaximumVisualDistance() {
        if (!this.rpa) {
            this.maximumVisualDistance = null;
        }

        if (this.rpa.maximumVisualDistance != null) {
            this.maximumVisualDistance = {
                value: this.rpa.maximumVisualDistance,
                source: 'CUSTOM'
            };
            return;
        }

        if (
            this.rpa?.rpaType?.otherSpecifications
                ?.defaultMaximumVisualDistance != null
        ) {
            this.maximumVisualDistance = {
                value: this.rpa?.rpaType?.otherSpecifications
                    ?.defaultMaximumVisualDistance,
                source: 'TYPE'
            };
            return;
        }

        this.maximumVisualDistance = {
            value: null,
            source: 'NONE'
        };
    }

    private refreshPermissions() {
        if (!this.rpa || !this.rpa.availableActions) {
            this.canDelete = false;
            this.canEdit = false;
            return;
        }
        this.hasEdit = this.rpa.availableActions.hasEdit && this.allowEdit;
        this.hasDelete = this.rpa.availableActions.hasDelete && this.allowEdit;
        this.canEdit = this.rpa.availableActions.canEdit;
        this.canDelete = this.rpa.availableActions.canDelete;
        this.canManage =
            this.rpa.organisationId === this.managingOrganisationId;
    }

    private refreshOwner() {
        if (this.rpa == null) {
            return;
        }

        this.organisationService
            .findById(this.rpa.organisationId, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(owner => (this.owner = owner));
    }

    canMarkRetiredAirCraft() {
        return this.rpa.status !== 'RETIRED';
    }

    canMarkUnderMaintenance() {
        return this.rpa.status !== 'UNDER_MAINTENANCE';
    }

    canMarkActive() {
        return this.rpa.status !== 'SERVICEABLE';
    }

    canMarkGrounded() {
        return this.rpa.status !== 'UNSERVICEABLE';
    }

    canDisposeRpa() {
        return this.rpa.status !== 'DISPOSED';
    }

    markAircraftRetired() {
        this.craftService
            .markAircraftRetire(this.rpa.id, {
                managingOrganisationId: this.managingOrganisationId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(craft => this.updateRpa(craft))
            .add(this.workTracker.createTracker());
    }

    markAircraftUnderMaintenance() {
        this.craftService
            .markAircraftUnderMaintenance(this.rpa.id, {
                managingOrganisationId: this.managingOrganisationId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(craft => this.updateRpa(craft))
            .add(this.workTracker.createTracker());
    }

    markAircraftActive() {
        this.craftService
            .markAircraftActive(this.rpa.id, {
                managingOrganisationId: this.managingOrganisationId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(craft => this.updateRpa(craft))
            .add(this.workTracker.createTracker());
    }

    markAircraftGrounded() {
        this.craftService
            .markAircraftGrounded(this.rpa.id, {
                managingOrganisationId: this.managingOrganisationId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(craft => this.updateRpa(craft))
            .add(this.workTracker.createTracker());
    }

    disposeRpa() {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showFormlyDialogue(
                'Dispose of RPA',
                'Dispose',
                true,
                true,
                [
                    {
                        key: 'disposalDate',
                        type: 'date',
                        props: {
                            label: 'Disposal Date',
                            required: false
                        }
                    }
                ],
                { disposalDate: null as Date },
                data =>
                    this.craftService
                        .disposeRpa(this.rpa.id, {
                            disposalDate: toLocalDate(data.disposalDate)
                        })
                        .toPromise()
            )
            .then(rpa => {
                this.logging.success(`RPA has been marked as disposed`);
                doneWorking();
                this.updateRpa(rpa);
            })
            .catch(() => doneWorking());
    }

    deleteAircraft() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete this aircraft?',
                `Are you sure you wish to delete this aircraft: ${this.rpa.nickname}`,
                'Delete',
                () => this.craftService.deleteCraft(this.rpa.id).toPromise()
            )
            .then(() => this.deleted.emit());
    }

    private updateRpa(rpa: CraftDetailsDto) {
        this.rpa = rpa;
        this.update.emit(rpa);
    }

    private refreshAvailableReports() {
        if (this.rpa == null) {
            this.printOptions = null;
            return;
        }

        this.rpaReportService
            .findAvailableTemplates(
                'CraftReport',
                this.rpa.id,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(reports => {
                this.printOptions = reports.map(r => ({
                    name: r.name,
                    description: r.description ? r.description : '',
                    print: () => {
                        const doneWorking = this.workTracker.createTracker();
                        this.downloadService
                            .downloadFromUrl(
                                this.rpaReportService.getPdfReportUrl(
                                    r.reportTemplateId,
                                    this.rpa.id,
                                    this.managingOrganisationId
                                )
                            )
                            .finally(doneWorking);
                    }
                }));
            })
            .add(this.workTracker.createTracker());
    }

    editRPA() {
        this.edit.emit();
    }

    get hasManageOptions() {
        return (
            this.canMarkRetiredAirCraft() ||
            this.canMarkUnderMaintenance() ||
            this.canMarkActive() ||
            this.canMarkGrounded() ||
            this.canDisposeRpa()
        );
    }
}
