import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    AcknowledgementDto,
    AircraftRegisterEntryDto,
    AttachmentHandler,
    AuthorityConditionDto,
    CreateAircraftRegisterEntryCommand,
    CreatePersonnelRegisterEntryCommand,
    CurrencyResultDto,
    DownloadableAttachmentVersionDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityService,
    PersonService,
    PersonnelRegisterEntryDto,
    Register,
    RegisterEntryVerificationActivityDto,
    RegisterEntryVerificationCommand,
    RpaAuthorityService,
    UiDisplayableCraftAuthorityDto,
    UiDisplayableOrganisationAuthorityDto,
    UpdateAircraftRegisterEntryCommand,
    UpdatePersonnelRegisterEntryCommand,
    WorkTracker,
    operatingConditionsValueLookup,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'organisation-authority-check',
    templateUrl: './organisation-authority-check.component.html'
})
export class OrganisationAuthorityCheck {
    @Input() authorityId: number;
    @Input() registerId: number;
    @Input() managingOrganisationId: number;
    @Input() type: AuthorityConditionDto.ConditionType;
    @Input() details: any;
    @Input() checkName: any;
    @Input() description: string;
    @Input() register: 'PERSONNEL' | 'RPA';
    @Input() acknowledgedAttachment: DownloadableAttachmentVersionDto;
    // Person ID for Personnel register or craft ID for RPA register
    @Input() subjectId: number;
    @Input() checkDetails: RegisterEntryVerificationActivityDto;
    @Input() registerConditionsHandler: Register<
        | CreatePersonnelRegisterEntryCommand
        | CreateAircraftRegisterEntryCommand,
        | UpdatePersonnelRegisterEntryCommand
        | UpdateAircraftRegisterEntryCommand,
        PersonnelRegisterEntryDto | AircraftRegisterEntryDto
    >;

    @Output() onCheckUpdated = new EventEmitter<void>();

    authority:
        | UiDisplayableOrganisationAuthorityDto
        | UiDisplayableCraftAuthorityDto;

    acknowledgementTime: any;

    attachmentHandler: AttachmentHandler;

    currencies: CurrencyResultDto[];
    operatingConditions = operatingConditionsValueLookup;

    acknowledgements: { [attachmentVersionId: number]: string };

    actionForm: FormGroup<{
        passed: FormControl<boolean>;
        expiry: FormControl<Date>;
        notes: FormControl<string>;
    }>;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private rpaAuthorityService: RpaAuthorityService,
        private personService: PersonService,
        private logging: FlyFreelyLoggingService
    ) {
        this.actionForm = new FormGroup({
            passed: new FormControl(undefined),
            expiry: new FormControl(undefined),
            notes: new FormControl(undefined)
        });
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        if (
            this.type ===
            AuthorityConditionDto.ConditionType.ATTACHMENT_ACKNOWLEDGEMENT
        ) {
            this.acknowledgementTime = this.details.acknowledgementTime ?? null;
        }

        if (this.type === AuthorityConditionDto.ConditionType.ACTION) {
            this.actionForm.patchValue({
                passed: this.checkDetails.status === 'PASSED',
                expiry:
                    this.checkDetails.expiryDate != null
                        ? moment(this.checkDetails.expiryDate).toDate()
                        : null,
                notes: this.checkDetails.notes
            });
        }

        if (
            this.authorityId == null &&
            this.type !== AuthorityConditionDto.ConditionType.CURRENCY
        ) {
            return;
        }

        if (
            this.type === AuthorityConditionDto.ConditionType.CURRENCY &&
            this.register === 'PERSONNEL'
        ) {
            this.refreshPilotCurrency();
        } else {
            this.attachmentHandler =
                this.register === 'PERSONNEL'
                    ? this.organisationAuthorityService.attachmentHandler(
                          this.authorityId,
                          this.managingOrganisationId
                      )
                    : this.rpaAuthorityService.attachmentHandler(
                          this.authorityId,
                          this.managingOrganisationId
                      );

            this.refresh();
        }
    }

    ngOnDestroy(): void {
        if (this.attachmentHandler != null) {
            this.attachmentHandler.destroy();
        }
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    verifyAuthority() {
        const doneWorking = this.workTracker.createTracker();
        if (this.register === 'PERSONNEL') {
            const auth = this
                .authority as UiDisplayableOrganisationAuthorityDto;
            this.organisationAuthorityService
                .verifyAuthority(this.authorityId, this.managingOrganisationId)
                .toPromise()
                .then(verification => auth.verifications.push(verification))
                .finally(() => doneWorking());
        } else if (this.register === 'RPA') {
            const auth = this.authority as UiDisplayableCraftAuthorityDto;
            this.rpaAuthorityService
                .verifyAuthority(this.authorityId, this.managingOrganisationId)
                .toPromise()
                .then(verification => auth.verifications.push(verification))
                .finally(() => doneWorking());
        }
    }

    private refresh() {
        if (this.register === 'PERSONNEL') {
            this.organisationAuthorityService
                .findDisplayableAuthority(
                    this.authorityId,
                    this.managingOrganisationId
                )
                .subscribe(authority => {
                    this.authority = authority;
                })
                .add(this.workTracker.createTracker());
        } else {
            this.rpaAuthorityService
                .findDisplayableAuthority(
                    this.authorityId,
                    this.managingOrganisationId
                )
                .subscribe(authority => {
                    this.authority = authority;
                })
                .add(this.workTracker.createTracker());
        }
        this.refreshAcknowledgements();
    }

    refreshPilotCurrency() {
        if (this.details?.authorityId == null) {
            return;
        }
        this.personService
            .findCurrencyResults(
                this.subjectId,
                this.registerId,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                currencies => (this.currencies = currencies),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing currency results: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    refreshAcknowledgements() {
        const doneWorking = this.workTracker.createTracker();
        this.attachmentHandler.findAcknowledgements().then(
            acknowledgements => {
                this.acknowledgements = acknowledgements.reduce(
                    (acc, r: AcknowledgementDto) => ({
                        ...acc,
                        [r.attachmentVersionId]: r.modificationTime
                    }),
                    {}
                );
                doneWorking();
            },
            () => doneWorking()
        );
    }

    saveAction() {
        const values = this.actionForm.value;
        const command: RegisterEntryVerificationCommand = {
            registerEntryId: this.checkDetails.registerEntryId,
            status: values.passed
                ? RegisterEntryVerificationCommand.Status.PASSED
                : RegisterEntryVerificationCommand.Status.PENDING,
            managingOrganisationId: this.managingOrganisationId,
            registerConditionId: this.checkDetails.authorityConditionId,
            expiryDate: toTimestamp(values.expiry),
            notes: values.notes
        };

        this.registerConditionsHandler
            .updateRegisterEntryVerification(
                this.checkDetails.registerEntryId,
                this.checkDetails.authorityConditionId,
                this.authorityId,
                command
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                updated => {
                    this.checkDetails = updated;
                    this.onCheckUpdated.emit();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating condition: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }
}
