import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { TreeModule } from '@circlon/angular-tree-component';
import { AnalyticsModule } from '@flyfreely-portal-ui/analytics';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { ngfModule } from 'angular-file';
import { FlyFreelyVideoModule } from 'libs/flyfreely-video/src/lib/flyfreely-video.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { DynamicHelpEditorComponent } from './dynamic-help-editor/dynamic-help-editor.component';
import { EditEnhancedHelpDirective } from './edit-enhanced-help.directive';
import { EnhancedHelpEditorList } from './enhanced-help-admin/enhanced-help-editor-list.component';
import { EnhancedHelpDynamicText } from './enhanced-help-dynamic-text.directive';
import { EnhancedHelpSwitch } from './enhanced-help-switch/enhanced-help-switch.component';
import { EnhancedHelpTooltip } from './enhanced-help-tooltip.directive';
import { EnhancedHelp } from './enhanced-help.component';
import { FeedbackComponent } from './feedback.component';
import { HelpVideoDialogue } from './help-video-dialogue/help-video-dialogue.component';
import { KnowledgeBaseLink } from './knowledge-base-link.component';
import { KnowledgeBaseLinkDirective } from './knowledge-base-link.directive';
import { StaticHelp } from './static-help.component';
import { EnhancedHelpDialogueComponent } from './enhanced-help-dialogue/enhanced-help-dialogue.component';
import { ShowEnhancedHelpDirective } from './show-enhanced-help.directive';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        TooltipModule,
        SharedUiModule,
        AnalyticsModule,
        MaterialModule,
        FeaturesModule,
        ngfModule,
        TreeModule,
        NgxLoadingModule,
        FlyFreelyVideoModule,
        BsDatepickerModule,
        Angulartics2Module
    ],
    declarations: [
        EnhancedHelp,
        EnhancedHelpEditorList,
        KnowledgeBaseLinkDirective,
        KnowledgeBaseLink,
        FeedbackComponent,
        EnhancedHelpSwitch,
        StaticHelp,
        EnhancedHelpTooltip,
        HelpVideoDialogue,
        EditEnhancedHelpDirective,
        DynamicHelpEditorComponent,
        EnhancedHelpDynamicText,
        EnhancedHelpDialogueComponent,
        ShowEnhancedHelpDirective
    ],
    exports: [
        EnhancedHelp,
        EnhancedHelpEditorList,
        KnowledgeBaseLinkDirective,
        KnowledgeBaseLink,
        FeedbackComponent,
        EnhancedHelpSwitch,
        StaticHelp,
        EnhancedHelpTooltip,
        EditEnhancedHelpDirective,
        DynamicHelpEditorComponent,
        EnhancedHelpDynamicText,
        ShowEnhancedHelpDirective
    ]
})
export class EnhancedHelpModule {}
