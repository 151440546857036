import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    ConcreteMappedEntityConcreteFormDto,
    ControlValues,
    FlyFreelyLoggingService,
    FormControlDto,
    FormResponseCommand,
    FormResponseDto,
    FormSectionDto,
    WorkTracker,
    calculateSectionKey,
    defaultBuildFormAttachmentUrl,
    flattern,
    initialiseResponse,
    prepareForEdit,
    prepareForStorage
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { buildFormFields } from '../helpers';
import { StoreFormResponse } from '../interfaces';

interface CalculatedField {
    id: number;
    repeatingSectionId: string;
    operator: string;
    inputControlIds: number[];
}

function buildCalculatedField(
    section: FormSectionDto,
    field: FormControlDto
): CalculatedField {
    return {
        id: field.id,
        inputControlIds: field.inputControlIds,
        operator: field.config.operator,
        repeatingSectionId: section.repeatingGroup
            ? calculateSectionKey(section)
            : null
    };
}

function findCalculatedFields(sections: FormSectionDto[]): CalculatedField[] {
    return sections.reduce(
        (acc, section) =>
            acc.concat(
                section.controls
                    .filter(c => c.type === 'calculated')
                    .map(c => buildCalculatedField(section, c))
            ),
        []
    );
}

function buildResponseCommand(
    existingCommand: FormResponseDto,
    values: any,
    completed: boolean
): FormResponseCommand {
    return {
        ...existingCommand,
        submittedTime: null,
        completed,
        values: values
    };
}

@Component({
    selector: 'form-input-dialogue',
    templateUrl: './form-input-dialogue.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputDialogue implements OnInit, OnDestroy {
    @Input() form: ConcreteMappedEntityConcreteFormDto;
    @Input() title: string;
    @Input() responses: FormResponseDto[];
    @Input() organisationId: number;
    @Input() storeFormResponse: StoreFormResponse;
    @Input() buildFormAttachmentUrl: typeof defaultBuildFormAttachmentUrl;

    formGroup: FormGroup = new FormGroup({});

    currentForm: FormResponseDto;

    fields: FormlyFieldConfig[];
    options: FormlyFormOptions = {};
    initialData: ControlValues;

    private computedFields: CalculatedField[];

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<FormInputDialogue>,
        private logging: FlyFreelyLoggingService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        // Ensure formgroup subscriptions are ready as soon as values are populated
        // This ensures calculated values are addressed as soon as their operands populate
        this.formGroup.statusChanges
            .pipe(takeUntil(this.ngUnsubscribe$), throttleTime(100))
            // Next tick
            .subscribe(update =>
                setTimeout(() => this.updateCalculatedFields(update))
            );
        this.formGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$), throttleTime(100))
            // Next tick
            .subscribe(update =>
                setTimeout(() => this.updateCalculatedFields(update))
            );

        this.currentForm =
            this.responses.length > 0
                ? this.responses[0]
                : initialiseResponse(this.form);

        // If sections are null, the parsers will fail and if they are just [], the window will be blank.
        if (this.currentForm.sections == null) {
            this.currentForm.sections = this.form.entity?.sections ?? [];
        }

        this.fields = buildFormFields(
            this.currentForm.sections,
            this.currentForm.formId,
            this.organisationId,
            this.buildFormAttachmentUrl
        );
        this.computedFields = findCalculatedFields(this.currentForm.sections);
        this.initialData = prepareForEdit(
            this.currentForm.sections,
            this.currentForm.values
        );

        this.workTracker
            .observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private updateCalculatedFields(data: any) {
        if (this.computedFields == null) {
            this.changeDetector.detectChanges();
            return;
        }
        this.computedFields.forEach(f => this.updateCalculatedField(f));
        this.changeDetector.detectChanges();
    }

    private updateCalculatedField(calc: CalculatedField) {
        if (calc.operator === 'sum') {
            const doCalculation = (
                field: FormlyFieldConfig,
                group: FormGroup
            ) => {
                if (field == null || field.formControl == null) {
                    return;
                }
                const value = calc.inputControlIds
                    .map(cid => parseFloat(group.value[cid]))
                    .filter(v => v != null && !isNaN(v))
                    .reduce((acc, v) => acc + v, 0);
                if (
                    field.formControl.value !== value &&
                    !(isNaN(field.formControl.value) && isNaN(value))
                ) {
                    field.formControl.patchValue(value);
                }
            };

            if (calc.repeatingSectionId == null) {
                const field = this.fields.find(
                    f => f.key === calc.id.toString()
                );
                doCalculation(field, this.formGroup);
            } else {
                const section = this.fields.find(
                    f => f.key === calc.repeatingSectionId
                );
                if (section.fieldGroup == null) {
                    // Not yet ready
                    return;
                }
                section.fieldGroup.forEach(group => {
                    const field = group.fieldGroup.find(
                        f => f.key === calc.id.toString()
                    );
                    doCalculation(field, <FormGroup>group.formControl);
                });
            }
        }
    }

    cancel() {
        this.modal.hide();
    }

    save() {
        const doneWorking = this.workTracker.createTracker();

        return this.storeFormResponse(
            buildResponseCommand(
                this.currentForm,
                prepareForStorage(
                    this.currentForm.sections,
                    flattern(this.fields, this.formGroup.value)
                ),
                false
            )
        ).then(
            result => {
                this.logging.success('Form response saved');
                doneWorking();
                return result;
            },
            error => {
                doneWorking();
                return Promise.reject(error);
            }
        );
    }

    saveAndClose() {
        this.save().then(() => this.modal.hide());
    }

    submit() {
        const doneWorking = this.workTracker.createTracker();

        this.storeFormResponse(
            buildResponseCommand(
                this.currentForm,
                prepareForStorage(
                    this.currentForm.sections,
                    flattern(this.fields, this.formGroup.value)
                ),
                true
            )
        ).then(
            result => {
                this.logging.success('Form response completed');
                doneWorking();
                this.modal.hide();
            },
            error => {
                doneWorking();
                return Promise.reject(error);
            }
        );
    }
}
