<ng-container screenAnalytics="mission-view">
    <div
        *ngIf="viewMode == 'BOTH' || viewMode == 'MAP'"
        style="flex: 1"
        class="relative"
    >
        <mission-view-map> </mission-view-map>
    </div>
    <div
        class="mission-list-container"
        *ngIf="viewMode == 'BOTH' || viewMode == 'LIST'"
    >
        <div
            [ngClass]="{
                'only-list': viewMode == 'LIST'
            }"
        >
            <div
                *ngIf="canFilterByAuthority"
                class="authority-filter-selector-group"
            >
                <label class="no-wrap">Mission Organisation:</label>
                <ng-select
                    class="mission-organisation-select"
                    [items]="missionAuthorityFilters$ | async"
                    [formControl]="organisationSelector"
                    [clearable]="false"
                    appendTo="body"
                >
                    <ng-template
                        ng-option-tmp
                        ng-label-tmp
                        let-item="item"
                        let-index="index"
                        ><span class="ng-option-label" [title]="item.name">
                            {{ item.name }}
                        </span>
                    </ng-template>
                </ng-select>
                <label>Mission Authority:</label>
                <ng-select
                    [items]="(organisationSelectorValue$ | async)?.children"
                    [formControl]="authoritySelector"
                    [clearable]="false"
                    appendTo="body"
                >
                    <ng-template
                        ng-option-tmp
                        ng-label-tmp
                        let-item="item"
                        let-index="index"
                        ><span
                            class="ng-option-label"
                            [title]="
                                item.isPrimary
                                    ? item.name + ' - Primary'
                                    : item.name
                            "
                        >
                            {{ item.name }}
                            <span
                                *ngIf="item.isPrimary"
                                class="status-block inverted custom"
                                >Primary</span
                            >
                        </span>
                    </ng-template>
                </ng-select>
            </div>

            <div class="relative fill vertical-container">
                <static-table
                    *ngIf="
                        missions &&
                        ((hasMissions | async) || (orgHasMissions | async)) &&
                        availableColumns
                    "
                    [availableColumns]="availableColumns"
                    [selectedColumns]="selectedColumns"
                    [tableData]="missions"
                    [tableConfig]="tableConfig"
                    [columnSorting]="tableSorting"
                    [tableSearch]="tableSearch"
                    (pageChanged)="onTablePageChanged($event)"
                    (selectedColumnsChanged)="
                        updateSelectedColumns($event.selectedColumns)
                    "
                    (sortOrderChanged)="updateColumnSorting($event)"
                    (itemLimitUpdated)="updateItemLimit($event)"
                    (search)="onTableSearch($event)"
                    [centeredHeadings]="true"
                    [borderless]="false"
                    data-cy="missionListTable"
                >
                    <ng-template
                        columnTemplate="name"
                        let-row="row"
                        let-column="column"
                    >
                        <mission-status-icon
                            [mission]="row"
                            (nameClicked)="showMission(row)"
                        ></mission-status-icon>
                    </ng-template>
                    <ng-template
                        columnTemplate="flightConformanceResultList"
                        let-row="row"
                        let-column="column"
                    >
                        <ng-container
                            *ngIf="
                                row.flightConformanceResultList != null &&
                                row.flightConformanceResultList.length > 0
                            "
                        >
                            <conformance-status-icon
                                [status]="
                                    parseConformanceStatus(
                                        row.flightConformanceResultList
                                    )
                                "
                            ></conformance-status-icon>
                        </ng-container>
                    </ng-template>
                </static-table>
                <empty
                    *ngIf="
                        canAddMission &&
                        missions &&
                        !(hasMissions | async) &&
                        !(orgHasMissions | async)
                    "
                    class="top-buffer"
                    screenName="mission-view"
                    componentName="missions-empty-editable"
                >
                </empty>
                <empty
                    *ngIf="!canAddMission && missions && missions.length == 0"
                    class="top-buffer"
                    screenName="mission-view"
                    componentName="missions-empty"
                >
                </empty>
            </div>
            <ngx-loading [show]="working"></ngx-loading>
        </div>
    </div>
</ng-container>
<router-outlet></router-outlet>
