import { Component } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    HubSpotNotificationPreference,
    NotificationsService
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationSettingsService } from '../notification-settings.service';

@Component({
    selector: 'profile-marketing-preferences',
    templateUrl: './marketing-preferences.component.html'
})
export class ProfileMarketingPreferences {
    preferences: HubSpotNotificationPreference[];

    working = false;
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private notificationService: NotificationsService,
        private notificationSettingsService: NotificationSettingsService,
        private logging: FlyFreelyLoggingService
    ) {
        this.notificationSettingsService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshMarketingPreferences();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshMarketingPreferences() {
        this.notificationService
            .findMarketingPreferences()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                preferences => (this.preferences = preferences),
                (error: FlyFreelyError) => {
                    this.preferences = [];
                    this.logging.error(
                        error,
                        `Error while refreshing marketing preferences: ${error.message}`
                    );
                }
            )
            .add(this.notificationSettingsService.createWorkTracker());
    }

    updateSubscriptionPreference(preference: HubSpotNotificationPreference) {
        // The switch in the HTML will set the subscribed value to the desired value before this is called, so use that as a flag to call the correct function.
        if (preference.subscribed) {
            this.subscribeToMarketingPreference(preference);
        }
        if (!preference.subscribed) {
            this.unsubscribeFromMarketingPreference(preference);
        }
    }

    subscribeToMarketingPreference(preference: HubSpotNotificationPreference) {
        this.notificationService
            .subscribeToMarketingPreference(preference.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                pref => {
                    this.preferences = this.preferences.map(p =>
                        p.id === pref.id ? pref : p
                    );
                    this.logging.success(`Subscribed to ${preference.name}`);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while updating marketing preferences: ${error.message}`
                    )
            )
            .add(this.notificationSettingsService.createWorkTracker());
    }

    unsubscribeFromMarketingPreference(
        preference: HubSpotNotificationPreference
    ) {
        this.notificationService
            .unsubscribeFromMarketingPreference(preference.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                pref => {
                    this.preferences = this.preferences.map(p =>
                        p.id === pref.id ? pref : p
                    );
                    this.logging.success(
                        `Unsubscribed from ${preference.name}`
                    );
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while updating marketing preferences: ${error.message}`
                    )
            )
            .add(this.notificationSettingsService.createWorkTracker());
    }
}
