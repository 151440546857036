import { Component, Input, OnInit } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    IncidentReportDto,
    IncidentReportsService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IncidentReportDialoguesService } from '../incident-report-dialogues.service';

@Component({
    selector: 'incident-report-list',
    templateUrl: './incident-report-list.component.html'
})
export class IncidentReportList implements OnInit {
    @Input() missionId: number;
    @Input() timeZone: string;
    @Input() organisationId: number;
    @Input() canAdd: boolean;
    incidentReports: IncidentReportDto[];
    cancelledIncidentReports: IncidentReportDto[];

    showCancelled = false;

    working: boolean = false;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private incidentReportsService: IncidentReportsService,
        private incidentReportDialoguesService: IncidentReportDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshIncidentReports();

        this.incidentReportsService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.refreshIncidentReports();
            });
    }

    private refreshIncidentReports() {
        const doneWorking = this.workTracker.createTracker();
        if (this.missionId == null) {
            this.incidentReports = [];
        }

        this.incidentReportsService
            .find({
                organisationId: this.organisationId,
                missionId: this.missionId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                reports => {
                    this.incidentReports = reports.filter(
                        ir => ir.status !== 'NEW' && ir.status !== 'CANCELLED'
                    );
                    this.cancelledIncidentReports = reports.filter(
                        ir => ir.status !== 'NEW' && ir.status === 'CANCELLED'
                    );
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                    doneWorking();
                }
            );
    }

    createIncidentReport() {
        this.incidentReportDialoguesService.showCreateIncidentReport(
            this.missionId
        );
    }
    reviewIncidentReport(report: IncidentReportDto) {
        this.incidentReportDialoguesService.showEditIncidentReport(
            this.missionId,
            report.id,
            report
        );
    }
}
