import { Injectable } from '@angular/core';
import {
    BatteryService,
    BatterySetService,
    CraftDetailsDto,
    CraftService,
    EquipmentService,
    FlightConformancePolicyDto,
    FlightConformanceResultDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionDto,
    PerformanceSpecificationsDto,
    PersonDto,
    PersonService,
    PilotFlightHistoryDto,
    PilotMissionFlightSummary,
    RpaTypeDto,
    ServiceabilitySignoffDto,
    SortieDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// TODO: replace this with the correct API model once/if available
export interface PilotFlightHistoryWithConformance
    extends PilotFlightHistoryDto {
    flights: PilotFlightSummaryWithConformance[];
}

// TODO: replace this with the correct API model once/if available
export interface PilotFlightSummaryWithConformance
    extends PilotMissionFlightSummary {
    conformanceResultList: PilotFlightConformanceResult[];
}

// TODO: replace this with the correct API model once/if available
export interface PilotFlightConformanceResult
    extends FlightConformanceResultDto {
    policy?: FlightConformancePolicyDto;
    pilot?: PersonDto;
    flight?: SortieDto;
}

// TODO: replace this with the correct API model once/if available
export interface FlightHistoryEntry {
    id: number;
    type: 'LOGGED' | 'HISTORICAL' | 'UNASSIGNED';
    missionId: number;
    flightNumber: number;
    numberOfFlights: number;
    startTime: string;
    endTime: string;
    duration: number;
    locationName: string;
    visualLineOfSight: MissionDto.VisualLineOfSight;
    timeOfDay: MissionDto.TimeOfDay;
    rpaId: number;
    rpaType: {
        id: number;
        make: string;
        model: string;
        rpaCategory: RpaTypeDto.RpaCategory;
        performanceSpecifications: PerformanceSpecificationsDto;
    };
}

export interface PilotFlightHistoryEntry extends FlightHistoryEntry {
    conformanceResultList: PilotFlightConformanceResult[];
}

export interface RpaFlightHistoryEntry extends FlightHistoryEntry {
    serviceabilitySignoff: {
        signoffPerson?: {
            firstName: string;
            lastName: string;
        };
        serviceability: ServiceabilitySignoffDto.Serviceability;
    };
}

export type LoggedFlight =
    | FlightHistoryEntry
    | PilotFlightHistoryEntry
    | RpaFlightHistoryEntry;

@Injectable()
export class FlightHistoryDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private pilotHistorySource = new ReplaySubject<PilotFlightHistoryEntry[]>(
        1
    );
    private sortieSource = new ReplaySubject<PilotFlightHistoryEntry[]>(1);
    private flightHistorySource = new ReplaySubject<LoggedFlight[]>(1);
    private allRpaSource = new ReplaySubject<CraftDetailsDto[]>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);

    working$ = this.workingSource.asObservable();
    pilotHistory$ = this.pilotHistorySource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();
    flightHistory$ = this.flightHistorySource.asObservable();

    allRpa: CraftDetailsDto[] = [];

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private personService: PersonService,
        private rpaService: CraftService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private equipmentService: EquipmentService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
        this.workingSource.next(false);

        // combineLatest([this.sortieSource, this.allRpaSource])
        //     .pipe(takeUntil(this.ngUnsubscribe$), debounceTime(500))
        //     .subscribe(([sorties, rpa]) => {
        //         const flights: PilotFlightHistoryWithConformance = {
        //             flights: sorties.map(s => ({
        //                 date:
        //                     s.recordedStartTime ??
        //                     s.manualStartTime ??
        //                     s.startTime,
        //                 duration: s.duration,
        //                 // @ts-ignore - result doesn't match SortieDto shape
        //                 missionId: s.mission?.id,
        //                 number: s.number,
        //                 // @ts-ignore - result doesn't match SortieDto shape
        //                 rpaId: s.rpa?.id,
        //                 sortieId: s.id,
        //                 // @ts-ignore - result doesn't match SortieDto shape
        //                 timeOfDay: s.timeOfDay,
        //                 // @ts-ignore - result doesn't match SortieDto shape
        //                 visualLineOfSight: s.visualLineOfSight,
        //                 // @ts-ignore - result doesn't match SortieDto shape
        //                 locationName: s.mission?.location?.name ?? 'Unknown',
        //                 rpaModel:
        //                     // @ts-ignore - s.rpa doesn't match SortieDto shape
        //                     s.rpa != null
        //                         ? // @ts-ignore - s.rpa doesn't match SortieDto shape
        //                           rpa.find(c => c.id === s.rpa.id)
        //                               ?.craftModel ?? null
        //                         : null,
        //                 conformanceResultList: s.conformanceResultList
        //             }))
        //         };
        //         this.pilotHistorySource.next(flights);
        //         // parse values for table
        //     });
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.pilotHistorySource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.sortieSource.complete();
        this.allRpaSource.complete();
        this.flightHistorySource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findDetailedPilotFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
            conformanceResultStatus?: FlightConformanceResultDto.Status[];
        },
        order: 'ASC' | 'DESC',
        page: number,
        limit: number
    ) {
        this.personService
            .findPilotFlightHistory(
                {
                    id: criteria.id,
                    managingOrganisationId: criteria.managingOrganisationId,
                    conformanceResultStatus: criteria.conformanceResultStatus
                },
                order,
                page,
                limit
            )
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                tap(result => {
                    const onlyUnique = (
                        value: number,
                        index: number,
                        self: number[]
                    ) => {
                        return self.indexOf(value) === index;
                    };

                    const craftIds: number[] = result.results
                        // @ts-ignore - bad type matching
                        .map(r => r.rpa?.id)
                        .filter(v => v != null)
                        .filter(i => this.allRpa.find(c => c?.id === i) == null)
                        .filter(onlyUnique);

                    if (craftIds.length > 0) {
                        let errorMessage: string;
                        this.allRpa = [];
                        craftIds.forEach(id => {
                            this.rpaService
                                .findById(id, criteria.managingOrganisationId)
                                .pipe(takeUntil(this.ngUnsubscribe$))
                                .subscribe(
                                    rpa => {
                                        this.allRpa.push(rpa);
                                        this.allRpaSource.next(this.allRpa);
                                    },
                                    (error: FlyFreelyError) => {
                                        if (
                                            errorMessage == null ||
                                            errorMessage !== error.message
                                        ) {
                                            errorMessage = error.message;
                                            this.logging.error(
                                                error,
                                                `Error fetching RPA: ${error.message}`
                                            );
                                        }
                                    }
                                )
                                .add(this.workTracker.createTracker());
                        });
                    } //
                })
            )
            .subscribe(
                results => {
                    this.pilotHistorySource.next(results.results);
                    this.currentPageSource.next(page);
                    this.totalItemsSource.next(results.count);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }

    findPilotFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
        },
        order: 'ASC' | 'DESC',
        page: number,
        limit: number
    ) {
        this.personService
            .findPilotFlightHistory(
                {
                    id: criteria.id,
                    managingOrganisationId: criteria.managingOrganisationId
                },
                order,
                page,
                limit
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    this.flightHistorySource.next(results.results);
                    this.currentPageSource.next(page);
                    this.totalItemsSource.next(results.count);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }

    findRpaFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
        },
        order: 'ASC' | 'DESC',
        page: number,
        limit: number
    ) {
        this.rpaService
            .findRpaFlightHistory(
                {
                    id: criteria.id,
                    managingOrganisationId: criteria.managingOrganisationId,
                    includeType: ['HISTORICAL', 'LOGGED', 'UNASSIGNED']
                },
                order,
                page,
                limit
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    this.flightHistorySource.next(results.results);
                    this.currentPageSource.next(page);
                    this.totalItemsSource.next(results.count);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }

    findBatteryFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
        },
        order: 'ASC' | 'DESC',
        page: number,
        limit: number
    ) {
        this.batteryService
            .findBatteryFlightHistory(
                {
                    id: criteria.id,
                    managingOrganisationId: criteria.managingOrganisationId,
                    includeType: ['HISTORICAL', 'LOGGED', 'UNASSIGNED']
                },
                order,
                page,
                limit
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    this.flightHistorySource.next(results.results);
                    this.currentPageSource.next(page);
                    this.totalItemsSource.next(results.count);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }

    findBatterySetFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
        },
        order: 'ASC' | 'DESC',
        page: number,
        limit: number
    ) {
        this.batterySetService
            .findBatterySetFlightHistory(
                {
                    id: criteria.id,
                    managingOrganisationId: criteria.managingOrganisationId,
                    includeType: ['HISTORICAL', 'LOGGED', 'UNASSIGNED']
                },
                order,
                page,
                limit
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    this.flightHistorySource.next(results.results);
                    this.currentPageSource.next(page);
                    this.totalItemsSource.next(results.count);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }

    findEquipmentFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
        },
        order: 'ASC' | 'DESC',
        page: number,
        limit: number
    ) {
        this.equipmentService
            .findEquipmentFlightHistory(
                {
                    id: criteria.id,
                    managingOrganisationId: criteria.managingOrganisationId,
                    includeType: ['HISTORICAL', 'LOGGED', 'UNASSIGNED']
                },
                order,
                page,
                limit
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    this.flightHistorySource.next(results.results);
                    this.currentPageSource.next(page);
                    this.totalItemsSource.next(results.count);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }
}
