import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReportSideBarComponent } from '../report-side-bar/report-side-bar.component';
import { ReportViewComponent } from '../report-view/report-view.component';
import {
    FlightLogsRoutingModule
} from '@flyfreely-portal-ui/flight-logs';
import {
    OrganisationAdminModule,
    OrganisationAdminRoutingModule
} from '@flyfreely-portal-ui/organisation-admin';
import {
    UserProfileRoutingModule
} from '@flyfreely-portal-ui/user-profile';
import { FEATURE_DYNAMIC_REPORTING } from '@flyfreely-portal-ui/flyfreely';
import { FeatureFlagGuard } from 'libs/ui/src/lib/featureFlag.guard';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: ReportViewComponent,
                canActivate: [FeatureFlagGuard],
                data: { requiredFeatureFlag: FEATURE_DYNAMIC_REPORTING },
                runGuardsAndResolvers: 'always',
                children: [
                    {
                        path: 'flightlogs',
                        loadChildren: () => FlightLogsRoutingModule
                    },
                    {
                        path: 'orgadmin',
                        loadChildren: () => OrganisationAdminRoutingModule
                    },
                    {
                        path: 'userprofile',
                        loadChildren: () => UserProfileRoutingModule
                    }
                ]
            },
            {
                path: '',
                component: ReportSideBarComponent,
                outlet: 'sidebar'
            }
        ])
    ],
    exports: [RouterModule]
})
export class ReportingRoutingModule {}
