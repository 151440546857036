import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    compareDates,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceLogSummary,
    maintenanceOutcomes,
    MaintenanceService,
    PersonDto,
    UpdateSubmittedMaintenanceLog,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AirworthinessNoticesDialogueService } from '../airworthness-notices.service';
import {
    FormatDateTimePipe,
    FormatMaintenanceLogTypePipe,
    FormatMaintenanceOutcomePipe,
    FormatMaintenanceStatusPipe,
    FormatPersonPipe
} from '@flyfreely-portal-ui/ui';
import { dateRange } from 'libs/flyfreely-table/src/lib/search';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { MaintenanceDialogues } from '../../maintenance-dialogues.service';

@Component({
    selector: 'link-notice-to-maintenance',
    templateUrl: './link-notice-to-maintenance.component.html'
})
export class LinkNoticeToMaintenanceComponent implements OnInit, OnDestroy {
    @Input() organisationId: number;
    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    maintenanceLogs$: Observable<MaintenanceLogSummary[]>;
    airworthinessNoticeId$: Observable<number>;

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;
    airworthinessNoticeId: number;

    selectedMaintenanceLogs: Set<number> = new Set<number>();

    constructor(
        private modal: BsModalRef,
        private AirworthinessNoticesDialogueService: AirworthinessNoticesDialogueService,
        private maintenanceService: MaintenanceService,
        formatDateTimePipe: FormatDateTimePipe,
        maintenanceLogTypePipe: FormatMaintenanceLogTypePipe,
        maintenanceStatusPipe: FormatMaintenanceStatusPipe,
        formatPersonPipe: FormatPersonPipe,
        private logging: FlyFreelyLoggingService,
        maintenanceOutcomePipe: FormatMaintenanceOutcomePipe,
        private maintenanceDialogues: MaintenanceDialogues
    ) {
        const statusFilters =
            this.maintenanceService.getCombinedStatusFilters();

        this.availableColumns = [
            {
                value: 'checkbox',
                name: '',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: () => '' // Empty string for the header
            },
            {
                value: 'resource.name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true,
                linkFunction: (maintenanceLog: any) =>
                    this.updateMaintenanceRequest(maintenanceLog.id)
            },
            {
                value: 'maintenanceLogType',
                name: 'Type',
                searchable: true,
                selectable: false,
                defaultSelection: true,
                searchOptions: this.maintenanceService.getMaintenanceLogTypes(),
                formatterFunction: t => maintenanceLogTypePipe.transform(t)
            },

            {
                value: 'identifier',
                name: 'Identifier',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'requestTime',
                name: 'Requested',
                searchable: 'daterange',
                selectable: false,
                defaultSelection: true,
                formatterFunction: t => formatDateTimePipe.transform(t),
                searchFunction: dateRange,
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
            },
            {
                value: 'status',
                name: 'Status',
                searchable: true,
                selectable: false,
                defaultSelection: true,
                searchOptions: statusFilters,
                defaultSearch: `'${statusFilters[0].value}'`,
                searchFunction: (search: string) => {
                    const criteria = search.split('|');
                    return (status: string) => criteria.indexOf(status) !== -1;
                },
                formatterFunction: s => maintenanceStatusPipe.transform(s)
            },
            {
                value: 'assignee',
                name: 'Assigned To',
                searchable: true,
                selectable: false,
                defaultSelection: true,
                formatterFunction: p => formatPersonPipe.transform(p),
                searchFunction: (text: string) => (person: PersonDto) =>
                    formatPersonPipe
                        .transform(person)
                        .toLowerCase()
                        .includes(text.toLowerCase())
            },
            {
                value: 'outcome',
                name: 'Outcome',
                searchable: true,
                selectable: false,
                defaultSelection: false,
                searchOptions: maintenanceOutcomes,
                formatterFunction: et => maintenanceOutcomePipe.transform(et)
            }
        ];
        this.selectedColumns = null;
        this.columnSorting = null;
        this.tableSearch = null;
        this.tableConfig = {
            limit: 10,
            actions: null,
            limitSelection: [10, 25, 50, 100]
        };
    }

    ngOnInit() {
        this.airworthinessNoticeId$ =
            this.AirworthinessNoticesDialogueService.airworthinessNoticeId$;
        this.airworthinessNoticeId$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.airworthinessNoticeId = result;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while saving draft: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
        this.maintenanceLogs$ =
            this.AirworthinessNoticesDialogueService.maintenanceLog$;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    // Highlighted method for checkbox change handling
    onCheckboxChange(event: any, log: MaintenanceLogSummary) {
        const checked = event.target.checked;

        if (checked) {
            this.selectedMaintenanceLogs.add(log.id);
            this.updateMaintenanceLog(log.id, this.airworthinessNoticeId);
        } else {
            this.selectedMaintenanceLogs.delete(log.id);
            this.updateMaintenanceLog(log.id, null);
        }
    }

    // Highlighted method to update maintenance log
    updateMaintenanceLog(logId: number, airworthinessNoticeId: number | null) {
        const command: UpdateSubmittedMaintenanceLog = {
            maintenanceLogId: logId,
            airworthinessNoticeId: airworthinessNoticeId
        };

        this.maintenanceService
            .updateMaintenanceIdentifierAndWorkInstructionsUrl(logId, command)
            .subscribe({
                next: _ => {
                    this.logging.success(`Successfully update notice link`);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error refreshing organisation details: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    cancel() {
        this.modal.hide();
        this.AirworthinessNoticesDialogueService.updateMaintenanceLogs(
            this.organisationId
        );
        this.AirworthinessNoticesDialogueService.updateLinkedMaintenanceLogs();
    }

    updateMaintenanceRequest(maintenanceLogId: number) {
        this.maintenanceDialogues.showMaintenanceDetails(
            this.organisationId,
            maintenanceLogId
        );
    }
}
