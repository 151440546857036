import { Injectable, ElementRef } from '@angular/core';
import fscreen from 'fscreen';

/**
 * Manages full screen UI functionality
 */
@Injectable()
export class FullScreenService {
    isFullScreen(): boolean {
        return fscreen.fullscreenElement != null;
    }

    isFullScreenSupported(): boolean {
        return fscreen.fullscreenEnabled;
    }

    toggleFullScreen(elementRef: ElementRef): void {
        if (this.isFullScreen()) {
            fscreen.exitFullscreen();
        } else {
            fscreen.requestFullscreen(elementRef.nativeElement);
        }
    }
}
