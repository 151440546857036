import { Component } from '@angular/core';
import {
    FEATURE_OUTSTANDING_TASKS_VIEW,
    MissionService,
    PersonsOrganisationDto,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatDateTimePipe, FormatPersonPipe } from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetRegistrationWithSection } from '../../../outstanding-tasks-view.service';
import {
    MissionOutstandingStatusDataService,
    OutstandingStatusMission
} from '../mission-outstanding-status-data.service';

const WIDGET_IDENTIFIER = 'missionOutstandingStatusTaskWidget';

@Component({
    selector: 'missions-awaiting-finalisation',
    templateUrl: './missions-awaiting-finalisation.component.html',
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()],
    providers: [MissionOutstandingStatusDataService]
})
export class MissionsAwaitingFinalisationWidgetComponent {
    organisation: PersonsOrganisationDto;
    showBody = true;

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    gqlSorting: ColumnSortPreferences;

    missions: OutstandingStatusMission[] = [];

    working = false;
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private missionOutstandingStatusDataService: MissionOutstandingStatusDataService,
        private workspaceStateService: WorkspaceStateService,
        private missionService: MissionService,
        private missionDialoguesService: MissionDialoguesService,
        private formatPersonPipe: FormatPersonPipe,
        private formatDateTimePipe: FormatDateTimePipe
    ) {
        this.availableColumns = [
            {
                value: 'name',
                name: 'Mission',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.viewMission(row)
            },
            {
                value: 'convertedMissionDate',
                name: 'Date',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: t => this.formatDateTimePipe.transform(t)
            },
            {
                value: 'rpic',
                name: 'Pilot',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'rpaNicknames',
                name: 'RPA',
                searchable: false,
                selectable: true,
                sortable: false,
                defaultSelection: true
            },
            {
                value: 'locationName',
                name: 'Location',
                searchable: false,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'workflowName',
                name: 'Worklflow',
                searchable: false,
                selectable: true,
                defaultSelection: false
            }
        ];

        this.selectedColumns = ['rpaNicknames'];

        this.tableConfig = {
            serverPagination: true,
            currentPage: 0,
            limit: 3,
            actions: []
        };

        this.gqlSorting = null;

        this.missionOutstandingStatusDataService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshSubscriptions();
                this.refreshMissions();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshMissions() {
        this.missionOutstandingStatusDataService.findMissions(
            this.tableConfig.currentPage ?? 0,
            this.tableConfig.limit ?? 3,
            this.gqlSorting,
            this.organisation.id,
            'finalisation',
            null,
            null
        );
    }

    refreshSubscriptions() {
        combineLatest([
            this.missionOutstandingStatusDataService.missions$,
            this.missionOutstandingStatusDataService.currentPage$,
            this.missionOutstandingStatusDataService.totalItems$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([missions, currentPage, totalItems]) => {
                this.missions = missions;
                if (this.tableConfig != null) {
                    const currentConfig = this.tableConfig;
                    this.tableConfig = {
                        ...currentConfig,
                        currentPage: currentPage,
                        totalItems: totalItems
                    };
                }
            });

        this.missionService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMissions());
    }

    viewMission(mission: OutstandingStatusMission) {
        this.missionDialoguesService.showMissionDetails(
            mission.id,
            true,
            this.organisation
        );
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.refreshMissions();
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (!this.selectedColumns.includes(this.gqlSorting.column)) {
            this.gqlSorting = null;
        }
        this.refreshMissions();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.gqlSorting =
            sorting == null
                ? null
                : {
                      column: sorting.column.toString(),
                      ascending: sorting.ascending
                  };
        this.tableConfig.currentPage = 0;
        this.refreshMissions();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.refreshMissions();
    }
}

export const missionsAwaitingFinalisationTaskWidgetRegistration: WidgetRegistrationWithSection =
    {
        widgetIdentifier: WIDGET_IDENTIFIER,
        section: 'operations',
        component: MissionsAwaitingFinalisationWidgetComponent,
        isAvailable: organisation =>
            hasFeatureFlag(organisation, FEATURE_OUTSTANDING_TASKS_VIEW)
    };
