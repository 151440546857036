import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    OnInit,
    OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    BatteryService,
    BatterySetDto,
    BatterySetService,
    BatteryTypeDto,
    BatteryTypeService,
    CraftDetailsDto,
    CraftService,
    EquipmentDto,
    EquipmentService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    toLookup,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import {
    FormatEquipmentCategoryPipe,
    FormatResourceStatusPipe
} from '@flyfreely-portal-ui/resource-ui';
import { BatteryDialoguesService } from 'libs/batteries/src/lib/battery-dialogues.service';
import { EquipmentDialoguesService } from 'libs/equipment/src/lib/equipment-dialogues.service';
import {
    batterySetSearch,
    equipmentSearch
} from 'libs/resource-ui/src/lib/searchFunctions';
import { FormatDatePipe } from 'libs/ui/src/lib/pipes';
import { ReplaySubject, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment/moment';

interface BatterySetWithType extends BatterySetDto {
    batteryType?: BatteryTypeDto;
    convertedAssemblyDate: moment.Moment;
    convertedDisassemblyDate: moment.Moment;
}

@Component({
    selector: 'associated-resources',
    templateUrl: './associated-resources.component.html',
    styleUrls: ['./associated-resources.component.scss'],
    host: {
        class: 'relative'
    }
})
export class AssociatedResourcesComponent
    implements OnInit, OnDestroy, OnChanges
{
    @Input() organisation: PersonsOrganisationDto;
    @Input() rpa: CraftDetailsDto;

    private associatedBatterySetSubject = new ReplaySubject<BatterySetDto[]>(1);
    associatedBatterySet$ = this.associatedBatterySetSubject.asObservable();
    associatedBattery$ = new Subject<BatterySetWithType[]>();
    private batteryTypes$ = new Subject<BatteryTypeDto[]>();

    associatedBatterySetColumns: TableColumn[];

    private associatedEquipmentSubject = new ReplaySubject<EquipmentDto[]>(1);
    associatedEquipment$ = this.associatedEquipmentSubject.asObservable();

    associatedEquipmentColumns: TableColumn[];

    associatedBatterySetTableConfig: TableConfig;
    associatedEquipmentTableConfig: TableConfig;

    batterySetSearch = batterySetSearch;
    equipmentSearch = equipmentSearch;

    // addEquipment = new FormControl<number>(undefined);
    // addBatterySet = new FormControl<number>(undefined);
    addEquipment = new FormControl<number | null>(null); // Standard FormControl
    addBatterySet = new FormControl<number | null>(null); // Standard FormControl

    private workTracker = new WorkTracker();
    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    private availableEquipmentSubject = new ReplaySubject<EquipmentDto[]>(1);
    private availableBatterySetSubject = new ReplaySubject<BatterySetDto[]>(1);

    availableEquipmentList$ = combineLatest([
        this.availableEquipmentSubject,
        this.associatedEquipment$.pipe(
            map(equipmentList => equipmentList.map(eq => eq.id))
        )
    ]).pipe(
        map(([equipmentList, associatedEquipmentIds]) =>
            equipmentList.filter(eq => !associatedEquipmentIds.includes(eq.id))
        )
    );

    availableBatterySetList$ = combineLatest([
        this.availableBatterySetSubject,
        this.associatedBatterySet$.pipe(
            map(batterySetList => batterySetList.map(bs => bs.id))
        )
    ]).pipe(
        map(([batterySetList, associatedBatterySetIds]) =>
            batterySetList.filter(
                eq => !associatedBatterySetIds.includes(eq.id)
            )
        )
    );

    constructor(
        formatDatePipe: FormatDatePipe,
        resourceStatusPipe: FormatResourceStatusPipe,
        batteryService: BatteryService,
        private equipmentDialogues: EquipmentDialoguesService,
        private batterySetDialogues: BatteryDialoguesService,
        private batterySetService: BatterySetService,
        private equipmentService: EquipmentService,
        private rpaService: CraftService,
        private logging: FlyFreelyLoggingService,
        formatEquipmentCategoryPipe: FormatEquipmentCategoryPipe,
        private batteryTypeService: BatteryTypeService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.associatedBatterySetColumns = [
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'convertedAssemblyDate',
                key: 'assemblyDate',
                name: 'Assembly Date',
                searchable: 'daterange',
                selectable: false,
                defaultSelection: true,
                formatterFunction: d => formatDatePipe.transform(d)
            },
            {
                value: 'convertedDisassemblyDate',
                key: 'disassemblyDate',
                name: 'Disassembly Date',
                searchable: 'daterange',
                selectable: false,
                defaultSelection: true,
                formatterFunction: d => formatDatePipe.transform(d)
            },
            {
                value: 'batteryType.make',
                name: 'Battery Type Make',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'batteryType.model',
                name: 'Battery Type Model',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'status',
                name: 'Status',
                searchable: 'selection',
                selectable: false,
                defaultSelection: true,
                formatterFunction: s => resourceStatusPipe.transform(s)
            }
        ];

        this.associatedBatterySetTableConfig = {
            actions: [],
            limit: 10
        };

        this.associatedEquipmentColumns = [
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'equipmentCategory',
                name: 'Equipment Category',
                searchable: true,
                selectable: false,
                defaultSelection: true,
                searchOptions: equipmentService.getEquipmentCategories(),
                formatterFunction: formatEquipmentCategoryPipe.transform
            },
            {
                value: 'convertedDisassemblyDate',
                key: 'disassemblyDate',
                name: 'Disassembly Date',
                searchable: 'daterange',
                selectable: false,
                defaultSelection: true,
                formatterFunction: d => formatDatePipe.transform(d)
            },
            {
                value: 'equipmentType.make',
                name: 'Equipment Type Make',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'equipmentType.model',
                name: 'Equipment Type Model',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'status',
                name: 'Status',
                searchable: 'selection',
                selectable: false,
                defaultSelection: true,
                formatterFunction: s => resourceStatusPipe.transform(s)
            }
        ];

        this.associatedEquipmentTableConfig = {
            actions: [],
            limit: 10
        };
    }

    ngOnInit(): void {
        combineLatest([this.associatedBatterySetSubject, this.batteryTypes$])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([batterySets, batteryTypes]) => {
                const batteryTypeLookup = batteryTypes.reduce(toLookup, {});
                this.associatedBattery$.next(
                    batterySets.map(s => ({
                        ...s,
                        batteryType:
                            s.batteries.length === 0
                                ? null
                                : batteryTypeLookup[
                                      s.batteries[0].batteryTypeId
                                  ],
                        convertedAssemblyDate: s.assemblyDate
                            ? moment(s.assemblyDate)
                            : null,
                        convertedDisassemblyDate: s.disassemblyDate
                            ? moment(s.disassemblyDate)
                            : null
                    }))
                );
            });

        this.equipmentService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(equipment =>
                this.availableEquipmentSubject.next(equipment)
            );
        this.refreshBatteryTypes();
        this.refreshBatterySets();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('rpa' in changes) {
            if (this.rpa == null) {
                this.associatedBatterySetSubject.next([]);
                this.associatedEquipmentSubject.next([]);
            } else {
                this.associatedBatterySetSubject.next(
                    this.rpa.associatedBatterySetList
                );
                this.associatedEquipmentSubject.next(
                    this.rpa.associatedEquipmentList
                );
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workTracker.ngOnDestroy();
    }

    private refreshBatteryTypes() {
        const doneWorking = this.workTracker.createTracker();
        firstValueFrom(this.batteryTypeService.find(this.organisation.id)).then(
            batteryTypes => {
                this.batteryTypes$.next(batteryTypes);
                doneWorking();
            }
        );
    }

    private refreshBatterySets() {
        const doneWorking = this.workTracker.createTracker();
        this.batterySetService
            .findBatterySets(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(batteries => {
                doneWorking();
                return this.availableBatterySetSubject.next(batteries);
            });
    }

    associateEquipment() {
        this.rpaService
            .addAssociatedEquipment(this.rpa.id, {
                managingOrganisationId: this.organisation.id,
                resourceId: this.addEquipment.value
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => this.logging.success('Equipment associated'),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while associating equipment: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());

        this.addEquipment.reset();
    }

    removeAssociatedEquipment(equipmentId: number) {
        this.rpaService
            .removeAssociatedEquipment(this.rpa.id, {
                managingOrganisationId: this.organisation.id,
                resourceId: equipmentId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => this.logging.success('Associated equipment removed'),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while removing associated equipment: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    associateBatterySet() {
        this.rpaService
            .addAssociatedBatterySet(this.rpa.id, {
                managingOrganisationId: this.organisation.id,
                resourceId: this.addBatterySet.value
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => this.logging.success('Battery set associated'),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while associating battery set: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());

        this.addBatterySet.reset();
    }

    removeAssociatedBatterySet(batterySetId: number) {
        this.rpaService
            .removeAssociatedBatterySet(this.rpa.id, {
                managingOrganisationId: this.organisation.id,
                resourceId: batterySetId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => this.logging.success('Associated battery set removed'),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while removing associated battery set: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    showBatterySet(batterySet: BatterySetDto) {
        this.batterySetDialogues.showBatterySetDetailsDialogue(
            batterySet,
            this.organisation
        );
    }

    showEquipment(equipment: EquipmentDto) {
        this.equipmentDialogues.showEquipmentDetails(
            equipment.id,
            this.organisation,
            true
        );
    }
}
