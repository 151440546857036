import { Injectable } from '@angular/core';
import {
    AvailableSubscriptionTiers,
    SubscriptionService
} from '@flyfreely-portal-ui/flyfreely';
import { CurrentSubscriptions } from 'libs/subscriptions/src/lib/interfaces';
import {
    CurrentOrganisation,
    OrganisationSubscriptionsService,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import {
    catchError,
    debounceTime,
    filter,
    map,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';

export interface SubscriptionFeatureSetSummary {
    /**
     * The subscription plan's identifier
     */
    identifier: string;
    /**
     * The subscription plan's name
     */
    name: string;
    /**
     * The list of feature flags under this subscription
     */
    features: string[];
}

@Injectable()
export class PlanHighlightService {
    private isTriallingSource = new BehaviorSubject<boolean>(false);
    private subscriptionPlansWithFeaturesSource = new BehaviorSubject<
        SubscriptionFeatureSetSummary[]
    >([]);
    private subscriptionsUpdated$ = new Subject<void>();
    private workingSource = new BehaviorSubject<boolean>(false);

    isTrialling$ = this.isTriallingSource.asObservable();
    subscriptionPlansWithFeatures$ = this.subscriptionPlansWithFeaturesSource.asObservable();
    working$ = this.workingSource.asObservable();

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private subscriptionService: SubscriptionService,
        workspaceStateService: WorkspaceStateService,
        organisationSubscriptionService: OrganisationSubscriptionsService
    ) {
        combineLatest([
            organisationSubscriptionService.currentSubscriptions$,
            workspaceStateService.currentOrganisation$
        ])
            .pipe(
                filter(
                    ([currentSubscription, currentOrganisation]) =>
                        currentOrganisation.type === 'organisation_loaded'
                ),
                tap(() => this.workingSource.next(true)),
                switchMap(([currentSubscription, currentOrganisation]) =>
                    this.subscriptionService
                        .findAvailableSubscriptions(
                            (<CurrentOrganisation>currentOrganisation)
                                .organisation.id
                        )
                        .pipe(map(r => [currentSubscription, r]))
                ),
                catchError(error => {
                    this.workingSource.next(false);
                    throw error;
                }),
                // The debounce here helps prevent going in and out of loading state while the app starts up on a page refresh
                // This is because the subscriptionService.licences$ emits more than once during startup
                debounceTime(2000),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(([currentSubscription, availableSubscriptions]) => {
                this.isTriallingSource.next(
                    (<CurrentSubscriptions>currentSubscription).isTrialing
                );
                this.subscriptionPlansWithFeaturesSource.next(
                    this.findFeatureSets(
                        <AvailableSubscriptionTiers[]>availableSubscriptions
                    )
                );
                this.workingSource.next(false);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.isTriallingSource.complete();
        this.subscriptionPlansWithFeaturesSource.complete();
        this.subscriptionsUpdated$.complete();
        this.workingSource.complete();
    }

    private findFeatureSets(subscriptions: AvailableSubscriptionTiers[]) {
        const availableSubscriptions = subscriptions.filter(
            s => s.annualPlan != null || s.monthlyPlan != null
        );
        return availableSubscriptions.reduce(
            (
                acc: SubscriptionFeatureSetSummary[],
                plan: AvailableSubscriptionTiers
            ) =>
                acc.concat({
                    identifier: plan.identifier,
                    name: plan.name,
                    features: plan.featureFlagList
                }),
            []
        );
    }
}
