import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthorityReportService } from '@flyfreely-portal-ui/flyfreely';
import { NgSelectModule } from '@ng-select/ng-select';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { FormatDatePipe, FormatPersonPipe } from 'libs/ui/src/lib/pipes';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthorityDetailsDialogue } from './authority-details/authority-details-dialogue.component';
import { AuthorityDialoguesService } from './authority-dialogues.service';
import { AuthorityEditDialogue } from './authority-edit/authority-edit-dialogue.component';
import { AuthorityGroupAddComponent } from './authority-group-add/authority-group-add.component';
import { AuthorityGroupEdit } from './authority-group-edit/authority-group-edit.component';
import { AuthorityGroupViewComponent } from './authority-group-view/authority-group-view.component';
import { NominatedPersonnelRow } from './nominated-personnel/nominated-personnel-row.component';
import { NominatedPersonnelDialogue } from './nominated-personnel/nominated-personnel.component';
import { OrganisationAuthorityCheck } from './registers/organisation-authority-check/organisation-authority-check.component';
import { PersonnelRegisterDialogue } from './registers/personnel-register/personnel-register-dialogue.component';
import { RegisterConditions } from './registers/register-conditions/register-conditions.component';
import { RegisterStatusDisplayComponent } from './registers/register-status-display.component';
import { RpaRegisterDialogue } from './registers/rpa-register/rpa-register-dialogue.component';
import { RegistersSideSheet } from './registers/side-sheet/registers-side-sheet.component';
import { SharedResources } from './organisation-relationships/shared-resources.component';
import { ApplyEndorsements } from './authority-edit/apply-endorsements/apply-endorsements.component';
import { CurrencyModule } from 'libs/currency/src/lib/currency.module';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AuthorityExpirySummaryComponent } from './registers/authority-expiry-summary/authority-expiry-summary.component';
import { RegisterSettings } from './registers/register-settings/register-settings.component';
import { SharedRpa } from './organisation-relationships/shared-rpa.component';
import { SharedBatterySets } from './organisation-relationships/shared-battery-sets.component';
import { SharedEquipment } from './organisation-relationships/shared-equipment.component';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';
import { AssociateAuthoritiesDialogueComponent } from './associate-authority/associate-authority.component';
import { CardModule } from '@flyfreely-portal-ui/card';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        PopoverModule,
        TabsModule,
        NgSelectModule,
        SharedUiModule,
        LoadingIndicatorModule,
        TooltipModule,
        ValidationModule,
        AttachmentsModule,
        DragDropModule,
        NgxLoadingModule,
        ButtonsModule,
        CurrencyModule,
        SharedUiModule,
        ResourceUiModule,
        AnalyticsModule,
        EnhancedHelpModule,
        FeaturesModule,
        CardModule
    ],
    providers: [
        AuthorityDialoguesService,
        AuthorityReportService,
        FormatDatePipe,
        FormatPersonPipe
    ],
    bootstrap: [],
    declarations: [
        SharedResources,
        AuthorityEditDialogue,
        AuthorityDetailsDialogue,
        PersonnelRegisterDialogue,
        RpaRegisterDialogue,
        AuthorityGroupEdit,
        NominatedPersonnelDialogue,
        NominatedPersonnelRow,
        OrganisationAuthorityCheck,
        RegisterConditions,
        RegistersSideSheet,
        RegisterSettings,
        AuthorityGroupViewComponent,
        AuthorityGroupAddComponent,
        RegisterStatusDisplayComponent,
        ApplyEndorsements,
        AuthorityExpirySummaryComponent,
        AssociateAuthoritiesDialogueComponent,
        SharedRpa,
        SharedEquipment,
        SharedBatterySets
    ],
    exports: [
        SharedResources,
        AuthorityGroupEdit,
        AuthorityGroupViewComponent,
        AuthorityGroupAddComponent,
        RegisterStatusDisplayComponent,
        AuthorityExpirySummaryComponent
    ]
})
export class AuthorityModule {}
