import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
    CreateLocationCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LocationDetailsDto,
    LocationService,
    MissionService,
    MissionSummaryDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getFeatureGroups } from '../helpers';

@Component({
    selector: 'clone-mission-location',
    templateUrl: './clone-mission-location-dialogue.component.html'
})
export class CloneMissionLocationDialogue {
    @Input() organisationId: number;

    missions: MissionSummaryDto[];
    locationName: string;
    selectedMission: MissionSummaryDto;

    location: LocationDetailsDto;

    locationFeatures: FeatureGroup[];

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    working: boolean;

    @ViewChild('map', { read: ElementRef, static: false }) map: ElementRef;

    constructor(
        private modal: BsModalRef,
        modalOptions: ModalOptions,
        private locationService: LocationService,
        private missionService: MissionService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private fullScreenService: FullScreenService
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.location != null || this.locationName != null) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            }
            return Promise.resolve();
        };

        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshMissions();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshMissions() {
        if (this.organisationId == null) {
            return;
        }
        this.missionService
            .findMissions(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missions => (this.missions = missions))
            .add(this.workTracker.createTracker());
    }

    onMissionSelected() {
        if (this.selectedMission == null) {
            this.location = null;
            return;
        }
        const locationId = this.selectedMission.locationId;
        if (locationId == null) {
            this.location = null;
            return;
        }
        this.locationService
            .findLocation(locationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(location => {
                this.location = location;
                const { features } = getFeatureGroups(location.features, 0);
                this.locationFeatures = features;
            })
            .add(this.workTracker.createTracker());
    }

    createLocationType() {
        const cmd: CreateLocationCommand = {
            derivedFromId: this.location.id,
            features: this.location.features,
            name: this.locationName ?? this.location.name,
            organisationId: this.organisationId,
            type: CreateLocationCommand.Type.TEMPLATE
        };
        this.locationService
            .createLocation(cmd)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.logging.success(`Created a new location template`);
                    this.location = null;
                    this.locationName = null;
                    this.modal.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error creating location type: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    onFullscreenRequested() {
        this.fullScreenService.toggleFullScreen(this.map);
    }

    hide() {
        this.modal.hide();
    }
}
