import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AttachmentHandler,
    CurrentPersonDto,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    hasAnyPermission,
    LoggedInUser,
    PersonRolesDto,
    personSearch,
    PersonService,
    PersonsOrganisationDto,
    UserService,
    UserStatus,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    formatGeneralTasksPriority,
    GeneralTasksDataService
} from 'libs/outstanding-tasks/src/lib/widgets/general-task/general-tasks-data.service';
import {
    CreateTaskCommand,
    TaskPriority
} from 'libs/flyfreely/src/lib/services/generalTasks.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { LinkAction } from 'libs/attachments/src/lib/link-manager/link-manager.interface';
import { AttachmentHandlerService } from 'libs/comments/src/lib/attachment-handler.service';
import {
    Comment,
    CommentDelete,
    CommentUpdate
} from 'libs/comments/src/lib/interfaces';
import { CommentsComponent } from 'libs/comments/src/lib/comments/comments.component';

@Component({
    selector: 'general-task-add-dialogue',
    templateUrl: './general-task-add-dialogue.component.html',
    styleUrls: ['./general-task-add-dialogue.component.css']
})
export class GeneralTaskAddDialogue implements OnInit, OnDestroy {
    @ViewChild(CommentsComponent) uiComments: CommentsComponent;
    @Input() organisation: PersonsOrganisationDto;

    taskId: number;
    attachmentsHandler: AttachmentHandler;
    taskNewForm: FormGroup;
    personList$: Observable<PersonRolesDto[]>;
    comments: Comment[];
    private ngUnsubscribe$ = new Subject<void>();
    currentUser: CurrentPersonDto | undefined;
    working = false;
    personSearch = personSearch;
    priorityOptions = Object.entries(formatGeneralTasksPriority).map(
        ([value, label]) => ({ label, value })
    );

    private workTracker = new WorkTracker();

    constructor(
        private modal: BsModalRef<GeneralTaskAddDialogue>,
        private personService: PersonService,
        private userService: UserService,
        private generalTasksDataService: GeneralTasksDataService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private attachmentHandlerService: AttachmentHandlerService,
        modalOptions: ModalOptions
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.taskNewForm.dirty) {
                return commonDialoguesService.showConfirmationDialogue(
                    'Cancel Create Task',
                    'You have unsaved changes, are you sure you want to cancel?',
                    'Yes',
                    () =>
                        firstValueFrom(
                            this.generalTasksDataService.deleteTask(this.taskId)
                        )
                );
            }
            return Promise.resolve();
        };
    }

    ngOnInit(): void {
        this.taskNewForm = new FormGroup({
            taskName: new FormControl('', Validators.required),
            details: new FormControl('', Validators.required),
            dueDate: new FormControl(),
            taskPriority: new FormControl(
                TaskPriority.MEDIUM,
                Validators.required
            ),
            assignee: new FormControl(null, Validators.required),
            reporter: new FormControl({
                value: null,
                disabled: true
            })
        });
        this.personList$ = this.personService.findPersonnel(
            this.organisation.id
        );

        combineLatest([
            this.userService.userChange$.pipe(
                filter<LoggedInUser>(c => c.type === UserStatus.LOGGED_IN)
            ),
            this.personList$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([changes, personList]) => {
                this.currentUser = changes.currentUser;
                this.taskNewForm.controls.reporter.patchValue(
                    personList.find(person => person.id === this.currentUser.id)
                );
                this.taskNewForm.updateValueAndValidity();
            });

        this.generalTasksDataService
            .newTask(this.organisation.id)
            .subscribe(newTask => {
                this.taskId = newTask.id;
                this.attachmentsHandler =
                    this.generalTasksDataService.attachmentHandler(
                        this.taskId,
                        this.organisation.id
                    );
                this.attachmentHandlerService.attachmentHandler =
                    this.attachmentsHandler;
                this.refreshComments();
                this.taskNewForm.markAsDirty();
            });

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
            });

        this.generalTasksDataService.commentChange$.subscribe(() => {
            this.refreshComments();
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshComments() {
        this.generalTasksDataService
            .findComments(this.taskId)
            .subscribe(res => {
                this.comments = res as unknown as Comment[];
            });
    }

    onCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Cancel Create Task',
                'You have unsaved changes, are you sure you want to cancel?',
                'Yes',
                () =>
                    firstValueFrom(
                        this.generalTasksDataService.deleteTask(this.taskId)
                    )
            )
            .then(
                () => {
                    this.taskNewForm.reset();
                    this.taskNewForm.markAsPristine();
                    this.modal.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error cancelling Create Task: ${error.message}`
                    )
            )
            .catch(DO_NOTHING);
    }

    onAddTask() {
        const value = this.taskNewForm.value;
        const toAdd: CreateTaskCommand = {
            taskId: this.taskId,
            summary: value.taskName,
            description: value.details,
            dueDate: value.dueDate,
            assigneeId: value.assignee,
            priority: value.taskPriority
        };
        this.createComment();
        this.generalTasksDataService.createTask(toAdd).subscribe({
            next: () => {
                this.logging.success(`Successfully create workgroup name`);
                this.taskNewForm.markAsPristine();
                this.modal.hide();
            },
            error: (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while add task: ${error.message}`
                );
            }
        });
    }

    createComment() {
        const commentContent = this.uiComments.richTextEditor.content;
        if (!commentContent) {
            return;
        }
        this.generalTasksDataService
            .createComment(this.taskId, commentContent)
            .subscribe({
                next: () => {
                    this.logging.success(`Successfully create comment`);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while create comment: ${error.message}`
                    );
                }
            });
    }

    readonly LinkAction = LinkAction;
}
