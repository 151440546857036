<div class="horizontal-container center-children">
    <div class="fill right-buffer">
        <status-icon [rpa]="item" [clickable]="false"></status-icon>
    </div>
    <div class="fill vertical-container">
        <div class="wrap-overflow">
            {{ item.rpaType?.make }} {{ item.rpaType?.model }}
        </div>
        <div class="wrap-overflow">SN: {{ item.manufacturerSerialNumber }}</div>
    </div>
</div>
