import {
    Component,
    Inject,
    Input,
    SimpleChanges,
    forwardRef
} from '@angular/core';
import {
    CraftDto,
    DisplayableMissionDto,
    FlyFreelyLoggingService,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { FlightLogWithDuration } from './flight-log-list/flight-log-list-dialogue.component';
import { FlightLogsDialoguesService } from './flight-logs-dialogues.service';

/**
 * This is a readonly flight log list that allows the user to visualise the logs.
 */
@Component({
    selector: 'flight-log-list',
    template: `
        <ul>
            <li *ngFor="let fl of flightLogs">
                <a
                    (click)="showFlightLog(fl)"
                    [innerHtml]="fl | formatFlightLog: rpaLookup[fl.rpaId]"
                ></a>
            </li>
        </ul>
    `
})
export class FlightLogList {
    @Input() flightLogs: FlightLogWithDuration[];
    @Input() rpas: CraftDto[];
    @Input() mission: DisplayableMissionDto;

    rpaLookup = {} as { [rpaId: number]: CraftDto };

    constructor(
        @Inject(forwardRef(() => FlightLogsDialoguesService))
        private flightLogsDialoguesService: FlightLogsDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        if (this.mission == null) {
            this.logging.error('No mission provided');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('rpas' in changes) {
            this.rpaLookup = this.rpas.reduce(toLookup, {});
        }
    }

    showFlightLog(flightLog: FlightLogWithDuration) {
        this.flightLogsDialoguesService.showFlightLog(
            flightLog.id,
            this.mission.organisationId,
            this.mission,
            null
        );
    }
}
