/**
 * FlyFreely API
 *
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthorityActions } from './authorityActions';
import { AuthorityRegisterSummaryDto } from './authorityRegisterSummaryDto';
import { CurrentAttachmentVersionDto } from './currentAttachmentVersionDto';
import { OperatingCategoryValuesDtoEndorsementReference } from './operatingCategoryValuesDtoEndorsementReference';
/**
 * An authority that is applied to an organisation
 */
export interface OrganisationAuthorityDto {
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The type of this authority
     */
    authorityTypeId: number;
    /**
     * The organisation that this authority applies to
     */
    organisationId: number;
    /**
     * When the authority starts taking effect
     */
    startDate: string;
    /**
     * When does the authority expire
     */
    expiryDate?: string;
    /**
     * The identifier, such as a serial number, that this authority has
     */
    identifier?: string;
    /**
     * The actions that can be performed by the current user
     */
    availableActions: AuthorityActions;
    /**
     * ${swagger.Authority.status}
     */
    status: OrganisationAuthorityDto.Status;
    /**
     * Has this authority been archived
     */
    archived: boolean;
    /**
     * The list of documents attached to this authority
     */
    attachments: Array<CurrentAttachmentVersionDto>;
    /**
     * All the registers associated with this authority instance.
     */
    registers: Array<AuthorityRegisterSummaryDto>;
    /**
     * Where the authority type does not require approval, the authority instance can specify its own policy
     */
    requiresApprovalPolicy: OrganisationAuthorityDto.RequiresApprovalPolicy;
    /**
     * Does this authority instance require approval? Derived from the authority type and the authority\'s requires approval policy
     */
    requiresApproval: boolean;
    /**
     * The endorsements that are applied to this authority
     */
    appliedEndorsementList: Array<OperatingCategoryValuesDtoEndorsementReference>;
    /**
     * Is the expiry of the authority coming up
     */
    expiryStatus: OrganisationAuthorityDto.ExpiryStatus;
    /**
     * Is the workflow for the authority setup if required
     */
    workflowStatus: OrganisationAuthorityDto.WorkflowStatus;
    dependentOnAuthorityId?: number;
    /**
     * ${swagger.Authority.expired}
     */
    expired: boolean;
}
export namespace OrganisationAuthorityDto {
    export type Status = 'NEW' | 'RECORDED' | 'DELETED';
    export const Status = {
        NEW: 'NEW' as Status,
        RECORDED: 'RECORDED' as Status,
        DELETED: 'DELETED' as Status
    };
    export type RequiresApprovalPolicy = 'NEVER' | 'ALWAYS';
    export const RequiresApprovalPolicy = {
        NEVER: 'NEVER' as RequiresApprovalPolicy,
        ALWAYS: 'ALWAYS' as RequiresApprovalPolicy
    };
    export type ExpiryStatus = 'OK' | 'UPCOMING' | 'EXPIRED';
    export const ExpiryStatus = {
        OK: 'OK' as ExpiryStatus,
        UPCOMING: 'UPCOMING' as ExpiryStatus,
        EXPIRED: 'EXPIRED' as ExpiryStatus
    };
    export type WorkflowStatus = 'ACTIVE' | 'DISABLED' | 'NOT_REQUIRED';
    export const WorkflowStatus = {
        ACTIVE: 'ACTIVE' as WorkflowStatus,
        DISABLED: 'DISABLED' as WorkflowStatus,
        NOT_REQUIRED: 'NOT_REQUIRED' as WorkflowStatus
    };
}
