import { Component } from '@angular/core';
import {
    AuthorityTypeDto,
    CraftAuthorityDto,
    DO_NOTHING,
    FEATURE_OUTSTANDING_TASKS_VIEW,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    RpaAuthorityService,
    WorkTracker,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatDatePipe } from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetRegistrationWithSection } from '../../../outstanding-tasks-view.service';
import { formatAuthorityExpiryWidgetStatuses } from '../../organisation/authority-data.service';
import {
    RpaAuthoritiesWidgetDataService,
    WidgetRpaAuthority
} from './rpa-authorities-widget-data.service';

const RPA_AUTHORITIES_IDENTIFIER = 'rpaAuthoritiesTaskWidget';

const defaultSort: ColumnSortPreferences = {
    column: 'expiryDate',
    ascending: true
};

@Component({
    selector: 'rpa-authorities-task-widget',
    templateUrl: './rpa-authorities-widget.component.html',
    styleUrls: ['../../widget-styles.scss'],
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()],
    providers: [RpaAuthoritiesWidgetDataService]
})
export class RpaAuthoritiesTaskWidgetComponent {
    organisation: PersonsOrganisationDto;
    showBody = true;

    // Replace below with the data to display in the table
    authorityExpiries: WidgetRpaAuthority[] = null;
    availableAuthorityTypes: AuthorityTypeDto[];

    //Static table variables
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    gqlSorting: ColumnSortPreferences;

    formatStatuses = formatAuthorityExpiryWidgetStatuses;

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private rpaAuthoritiesWidgetDataService: RpaAuthoritiesWidgetDataService,
        private rpaAuthorityService: RpaAuthorityService,
        private workspaceStateService: WorkspaceStateService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private formatDatePipe: FormatDatePipe
    ) {
        this.availableColumns = [
            {
                value: 'authorityTypeName',
                name: 'Authority',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.viewAuthority(row)
            },
            {
                value: 'identifier',
                name: 'Identifier',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'rpaName',
                name: 'RPA',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'startDate',
                name: 'Start Date',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: d => this.formatDatePipe.transform(d)
            },
            {
                value: 'expiryDate',
                name: 'Expiry Date',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: d => this.formatDatePipe.transform(d)
            },
            {
                value: 'status',
                name: 'Status',
                searchable: false,
                selectable: false,
                defaultSelection: true
            }
        ];

        this.selectedColumns = null;

        this.gqlSorting = defaultSort;

        this.tableConfig = {
            serverPagination: true,
            currentPage: 0,
            totalItems: 0,
            limit: 3,
            actions: []
        };

        combineLatest([
            this.workTracker.observable,
            this.rpaAuthoritiesWidgetDataService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([working, serviceWorking]) =>
                    (this.working = working || serviceWorking)
            );
    }

    ngOnInit() {
        this.rpaAuthoritiesWidgetDataService.authorities$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorities => (this.authorityExpiries = authorities));

        combineLatest([
            this.rpaAuthoritiesWidgetDataService.authorities$,
            this.rpaAuthoritiesWidgetDataService.currentPage$,
            this.rpaAuthoritiesWidgetDataService.totalItems$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([authorities, currentPage, totalItems]) => {
                this.authorityExpiries = authorities;
                if (this.tableConfig != null) {
                    const currentConfig = this.tableConfig;
                    this.tableConfig = {
                        ...currentConfig,
                        currentPage: currentPage,
                        totalItems: totalItems
                    };
                }
            });

        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshAvailableAuthorityTypes();
                this.refreshAuthorities();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshAvailableAuthorityTypes() {
        this.rpaAuthorityService
            .findAvailableAuthorityTypes(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: authorityTypes =>
                    (this.availableAuthorityTypes = authorityTypes),
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        'Error refreshing available RPA authority types'
                    )
            })
            .add(this.workTracker.createTracker());
    }

    refreshAuthorities() {
        this.rpaAuthoritiesWidgetDataService.findAuthorities(
            this.tableConfig.currentPage ?? 0,
            this.tableConfig.limit ?? 3,
            this.organisation.id,
            null,
            [
                CraftAuthorityDto.ExpiryStatus.UPCOMING,
                CraftAuthorityDto.ExpiryStatus.EXPIRED
            ],
            this.gqlSorting
        );
    }

    viewAuthority(widgetAuthority: WidgetRpaAuthority) {
        const authorityType = this.availableAuthorityTypes.find(
            a => a.id === widgetAuthority.authorityType.id
        );
        this.rpaAuthorityService
            .findAuthority(this.organisation.id, widgetAuthority.authorityId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: authority => {
                    this.showAuthorityDetails(authority, authorityType);
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        'Error trying to view authority details'
                    )
            })
            .add(this.workTracker.createTracker());
    }

    showAuthorityDetails(
        authority: CraftAuthorityDto,
        authorityType: AuthorityTypeDto
    ) {
        const modal = this.authorityDialoguesService.showAuthorityDetails(
            this.organisation.id,
            authorityType,
            authority,
            null,
            true,
            true
        );

        modal.content.onEditAuthority.subscribe(() =>
            this.showAuthorityEdit(authority, authorityType)
        );

        modal.content.onDeleteAuthority.subscribe(() =>
            this.deleteAuthority(authorityType, authority)
        );

        modal.content.archiveAuthority.subscribe(() =>
            this.archiveAuthority(authorityType, authority)
        );

        modal.content.unarchiveAuthority.subscribe(() =>
            this.unarchiveAuthority(authorityType, authority)
        );
    }

    showAuthorityEdit(
        authority: CraftAuthorityDto,
        authorityType: AuthorityTypeDto
    ) {
        this.authorityDialoguesService.showAuthorityEdit(
            this.organisation.id,
            authorityType,
            authority,
            this.organisation.id,
            this.rpaAuthorityService
        );
    }

    archiveAuthority(authorityType: AuthorityTypeDto, authority: any) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Archive Authority',
                `Do you wish to archive this ${authorityType.name}?`,
                'Archive',
                () =>
                    this.rpaAuthorityService
                        .archiveAuthority(authority.id, this.organisation.id)
                        .toPromise()
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    unarchiveAuthority(authorityType: AuthorityTypeDto, authority: any) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Unarchive Authority',
                `Do you wish to unarchive this ${authorityType.name}?`,
                'Unarchive',
                () =>
                    this.rpaAuthorityService
                        .unarchiveAuthority(authority.id, this.organisation.id)
                        .toPromise()
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    deleteAuthority(
        authorityType: AuthorityTypeDto,
        authority: CraftAuthorityDto
    ) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Authority',
                `Do you wish to delete this ${authorityType.name}?`,
                'Delete',
                () =>
                    this.rpaAuthorityService.deleteAuthority(
                        authority.id,
                        this.organisation.id
                    )
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.refreshAuthorities();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.gqlSorting =
            sorting == null
                ? defaultSort
                : {
                      column: sorting.column.toString(),
                      ascending: sorting.ascending
                  };
        this.tableConfig.currentPage = 0;
        this.refreshAuthorities();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.refreshAuthorities();
    }
}

export const rpaAuthoritiesTaskWidgetRegistration: WidgetRegistrationWithSection =
    {
        widgetIdentifier: RPA_AUTHORITIES_IDENTIFIER,
        section: 'resources',
        component: RpaAuthoritiesTaskWidgetComponent,
        isAvailable: organisation =>
            hasFeatureFlag(organisation, FEATURE_OUTSTANDING_TASKS_VIEW)
    };
