import { Injectable } from '@angular/core';
import {
    FlightConformanceRuleResultDto,
    FlightLogCriteria,
    FlightLogSummaryGQL,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionDto,
    MissionRoleDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { ColumnSortPreferences } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatPersonPipe } from '@flyfreely-portal-ui/ui';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const defaultSort: { field: string; order: 'ASC' | 'DESC' } = {
    field: 'startTime',
    order: 'DESC'
};

export interface OutstandingFlightLogsFlight {
    id: number;
    missionName: string;
    missionId: number;
    flightLogName: string;
    rpaName: string;
    rpaSerialNumber: string;
    pilotName: string;
    startTime: string;
    conformanceResultStatus: string;
}

@Injectable()
export class OutstandingFlightLogsDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private flightLogsSource = new ReplaySubject<OutstandingFlightLogsFlight[]>(
        1
    );
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);
    private hasFlightLogsSource = new ReplaySubject<boolean>(1);

    working$ = this.workingSource.asObservable();
    flightLogs$ = this.flightLogsSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();
    hasFlightLogs$ = this.hasFlightLogsSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightLogsService: FlightLogsService,
        private formatPersonPipe: FormatPersonPipe,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.flightLogsSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.hasFlightLogsSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findFlightLogs(
        page: number,
        limit: number,
        sorting: ColumnSortPreferences,
        filters: FlightLogCriteria,
        organisationId: number
    ) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const sortFields: { field: string; order: 'ASC' | 'DESC' } =
            sorting != null
                ? {
                      field: sorting?.column ?? null,
                      order: sorting?.ascending ? 'ASC' : 'DESC'
                  }
                : defaultSort;
        this.flightLogsService
            .findFlightLogsSummaries(
                organisationId,
                filters,
                sortFields,
                page,
                limit
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: logs => {
                    const flightLogs: OutstandingFlightLogsFlight[] = this.parseFlightLogData(
                        logs.results,
                        page,
                        limit
                    );
                    this.totalItemsSource.next(logs.count);
                    this.currentPageSource.next(page);
                    this.flightLogsSource.next(flightLogs);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    parseFlightLogData(
        flightLogs: FlightLogSummaryGQL[],
        page: number,
        limit: number
    ): OutstandingFlightLogsFlight[] {
        const findPilotName = (mission: MissionDto) => {
            const pilot = mission?.missionCrewDetails?.find(
                p =>
                    p.role.coreRole === MissionRoleDto.CoreRole.PILOT_IN_COMMAND
            );
            return pilot ? this.formatPersonPipe.transform(pilot.person) : null;
        };
        const parseConformanceResults = conformanceResult => {
            if (!conformanceResult) {
                return null;
            }
            const breachType =
                conformanceResult != null && conformanceResult.length
                    ? conformanceResult.ruleResultList[0].ruleVersion?.rule
                          ?.type
                    : null;
            let message: string;
            const type = FlightConformanceRuleResultDto.Type;
            switch (breachType) {
                case type.FLIGHT_AREA:
                    message = 'Pilot left specified flight area';
                    break;

                case type.LANDING_BATTERY_LEVEL:
                    message =
                        'Pilot exceeded the specified minimum battery level';
                    break;

                case type.MAXIMUM_HEIGHT:
                    const height =
                        conformanceResult.ruleResultList[0].comparisonValue;
                    message = `Pilot exceeded the maximum height of ${height}ft`;

                default:
                    message = '';
                    break;
            }
            return breachType != null
                ? conformanceResult.conformanceResultList[0].status
                : null;
        };
        return flightLogs.map((log, i) => ({
            id: log.id,
            missionName: log.mission?.name,
            missionId: log.mission?.id,
            flightLogName: log.fileName,
            rpaName: log.rpa?.nickname,
            rpaSerialNumber: log.rpa?.internalSerialNumber,
            pilotName: findPilotName(log.mission),
            startTime: log.summary?.startTime,
            conformanceResultStatus: parseConformanceResults(
                log.conformanceResult
            )
        }));
    }
}
