<ng-container screenAnalytics="mission-details-v2">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-missions.png" />
        </div>
        <h3 class="modal-title">
            View Mission
            {{ mission?.status == 'COMPLETED' ? 'Record' : 'Plan' }}
        </h3>
        <h5 class="modal-title padding">
            Details
            {{
                mission?.readyForFinalisation && mission?.status !== 'FINALISED'
                    ? '(Awaiting finalisation)'
                    : ''
            }}
        </h5>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <form name="missionDetailsForm" class="relative">
        <ngx-loading [show]="working || modalStatus == 'LOADING'"></ngx-loading>
        <routing-error
            [status]="modalStatus"
            [noun]="'mission'"
            [show]="modalStatus == 'NO_ACCESS' || modalStatus == 'NOT_FOUND'"
        ></routing-error>
        <div
            *ngIf="
                missionDetailsService.organisation$
                    | async as currentOrganisation
            "
            class="modal-body"
            [ngClass]="{ 'no-footer': modalStatus !== 'LOADED' }"
        >
            <ng-container *ngIf="modalStatus == 'LOADED'">
                <div class="modal-content-full-width">
                    <summary-header
                        [mission]="mission"
                        [organisation]="currentOrganisation"
                        [missionApprovals]="missionApprovals"
                        [working]="working"
                    >
                    </summary-header>
                    <div class="mission-details-status">
                        <mission-details-status
                            [mission]="mission"
                            [organisation]="currentOrganisation"
                            [missionApprovals]="missionApprovals"
                            (openConformanceTab)="openConformanceTab()"
                            (cancelApproval)="cancelApproval($event)"
                            (requestPerformApproval)="
                                findApprovalAndApprove($event)
                            "
                            (requestCancelApproval)="cancelApproval($event)"
                            #missionRibbons
                        ></mission-details-status>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-5 mission-info">
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Name:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.name }}
                            </div>
                        </div>
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                UID:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.uid }}
                            </div>
                        </div>
                        <!-- <div class="row top-buffer">
                        <div class="col-xs-12 col-sm-3 display-label">Status:</div>
                        <div class="col-xs-12 col-sm-9">{{ $ctrl.mission.status | formatCombinedMissionStatus }}</div>
                    </div> -->
                        <div
                            class="row top-buffer"
                            *ngIf="
                                mission != null &&
                                mission.missionObjectiveType != null
                            "
                        >
                            <div class="col-xs-12 col-sm-3 display-label">
                                Objective:
                            </div>
                            <div class="col-xs-12 col-sm-9 pre-wrap">
                                {{ mission?.missionObjectiveType?.name }}
                            </div>
                        </div>
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Objective Description:
                            </div>
                            <div class="col-xs-12 col-sm-9 pre-wrap">
                                {{ mission?.missionObjective }}
                            </div>
                        </div>
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Crew Notes:
                            </div>
                            <div class="col-xs-12 col-sm-9 pre-wrap">
                                {{ mission?.crewNotes }}
                            </div>
                        </div>
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Date/Time:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ missionDate | formatDateTime }}
                            </div>
                        </div>
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Time of Day:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.timeOfDay }}
                            </div>
                        </div>
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Visual Line of Sight:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.visualLineOfSight }}
                            </div>
                        </div>
                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Estimated Duration:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{
                                    mission?.missionEstimatedTime
                                        | formatDuration
                                }}
                            </div>
                        </div>

                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Operation Type:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.missionType?.name }}
                            </div>
                        </div>

                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Mission Type:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.type | formatMissionType }}
                            </div>
                        </div>

                        <div
                            class="row top-buffer"
                            *ngIf="missionDetails != null"
                            [ngClass]="{ error: missionDetails.rpic == null }"
                        >
                            <div class="col-xs-12 col-sm-3 display-label">
                                Remote Pilot In Command:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                <div class="wrap">
                                    <a
                                        (click)="showPilot(missionDetails.rpic)"
                                        *ngIf="missionDetails.rpic != null"
                                    >
                                        {{ missionDetails.rpic | formatPerson }}
                                        #
                                        {{
                                            missionDetails.rpic
                                                .activeJurisdictions
                                                | formatJurisdictionAuthorityIdentifier
                                                    : airspaceJurisdictionId
                                        }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row top-buffer"
                            *ngIf="missionDetails != null"
                        >
                            <div class="col-xs-12 col-sm-3 display-label">
                                Additional Crew:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                <div
                                    class="wrap"
                                    *ngIf="
                                        missionDetails.additionalCrew.length > 0
                                    "
                                >
                                    <span
                                        *ngFor="
                                            let crew of missionDetails.additionalCrew;
                                            let last = last
                                        "
                                    >
                                        <a (click)="showPilot(crew.person)">
                                            {{ crew.person | formatPerson }} #{{
                                                crew.person.arn
                                            }}
                                        </a>
                                        ({{ crew.role.name }})
                                        {{ !last ? ',' : '' }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            class="row top-buffer"
                            *ngIf="missionDetails != null"
                        >
                            <div
                                class="col-xs-12 col-sm-3 display-label"
                                [ngClass]="{
                                    error: mission.crafts.length == 0
                                }"
                            >
                                RPA:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                <div
                                    class="wrap"
                                    *ngIf="mission.crafts.length > 0"
                                >
                                    <span
                                        *ngFor="
                                            let craft of mission.crafts;
                                            let last = last
                                        "
                                    >
                                        <a (click)="showCraft(craft)">
                                            {{ craft | formatRpa
                                            }}{{ !last ? ', ' : '' }}
                                        </a>
                                        <span
                                            class="status"
                                            [ngClass]="
                                                craft.status | rpaStatusClass
                                            "
                                        >
                                            {{
                                                craft.status
                                                    | formatResourceStatus
                                            }}
                                        </span>
                                    </span>
                                    <!-- <span>Insurance</span> -->
                                </div>
                            </div>
                        </div>
                        <div
                            class="row top-buffer"
                            *ngIf="missionDetails != null && canUseEquipment"
                        >
                            <div
                                class="col-xs-12 col-sm-3 display-label"
                                [ngClass]="{
                                    error: mission.crafts.length == 0
                                }"
                            >
                                Equipment:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                <div
                                    class="wrap"
                                    *ngIf="mission.equipment.length > 0"
                                >
                                    <span
                                        *ngFor="
                                            let equipment of mission.equipment;
                                            let last = last
                                        "
                                    >
                                        <a (click)="showEquipment(equipment)">
                                            {{ equipment.name
                                            }}{{ !last ? ', ' : '' }}
                                        </a>
                                        <span
                                            class="status"
                                            [ngClass]="
                                                equipment.status
                                                    | equipmentStatusClass
                                            "
                                        >
                                            {{
                                                equipment.status
                                                    | formatResourceStatus
                                            }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row top-buffer"
                            *ngIf="missionDetails != null"
                            [ngClass]="{ error: mission.location == null }"
                        >
                            <div class="col-xs-12 col-sm-3 display-label">
                                Location:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.location?.name }}
                            </div>
                        </div>

                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Planned Max Height:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.maximumHeight }} ft (AGL)
                            </div>
                        </div>

                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Emergency Contacts:
                            </div>
                            <div
                                class="col-xs-12 col-sm-9 pre-wrap"
                                [innerHtml]="mission?.emergencyContacts"
                            ></div>
                        </div>

                        <div class="row top-buffer">
                            <div class="col-xs-12 col-sm-3 display-label">
                                Radio Frequencies:
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                {{ mission?.radioFrequencies }}
                            </div>
                        </div>

                        <!-- <div class="row top-buffer">
                        <div class="col-xs-12 col-sm-3 display-label">Weather:</div>
                        <div
                            class="col-xs-12 col-sm-9"
                            ng-bind-html="$ctrl.mission.weather | formatWeather"></div>
                    </div> -->
                    </div>
                    <div class="col-xs-12 col-md-7">
                        <tabset *ngIf="missionDetails != null" #tabset>
                            <tab heading="Flight Area">
                                <flyfreely-map
                                    *ngIf="
                                        locationFeatures != null &&
                                        tabset.tabs[0].active == true
                                    "
                                    #map
                                    style="height: 500px"
                                    [organisationId]="currentOrganisation.id"
                                    [features]="locationFeatures"
                                    featuresTitle="Flights"
                                    [showFeatureSelection]="true"
                                    [requireCtrlToZoom]="true"
                                    [startTime]="missionStartTime"
                                    [endTime]="missionEndTime"
                                    [ruleset]="missionRuleset"
                                    [mapSourceFilters]="mapSourceFilters"
                                    [enabledLayerGroups]="enabledMapLayerGroups"
                                    [mapData]="mapData$ | async"
                                    [enableLayerGroupsByDefault]="['NOTAM']"
                                    (fullscreenRequested)="
                                        onFullscreenRequested()
                                    "
                                    (featuresLoaded)="
                                        map.zoomToAllFeatures(true)
                                    "
                                ></flyfreely-map>
                                <p *ngIf="locationFeatures == null">
                                    No flight area defined
                                </p>
                            </tab>
                            <tab
                                heading="Mission Log and Flights"
                                *ngIf="
                                    isCompleted || mission.status === 'FLYING'
                                "
                            >
                                <h4 *ngIf="isCompleted">Mission Outcomes</h4>
                                <div class="row top-buffer" *ngIf="isCompleted">
                                    <div
                                        class="col-xs-12 col-sm-2 display-label"
                                    >
                                        Actual Location:
                                    </div>
                                    <div class="col-xs-12 col-sm-2">
                                        {{
                                            actualLocation
                                                ? actualLocation.name
                                                : ''
                                        }}
                                    </div>
                                    <div
                                        class="col-xs-12 col-sm-2 display-label"
                                    >
                                        Actual Date/Time:
                                    </div>
                                    <div class="col-xs-12 col-sm-2">
                                        {{
                                            mission?.actualStartTime
                                                | formatDateTime
                                        }}
                                    </div>
                                    <div
                                        class="col-xs-12 col-sm-2 display-label"
                                    >
                                        Objective Outcome:
                                    </div>
                                    <div class="col-xs-12 col-sm-2">
                                        {{
                                            mission?.outcome
                                                | formatObjectiveOutcome
                                        }}
                                    </div>
                                </div>
                                <div class="row top-buffer" *ngIf="isCompleted">
                                    <div
                                        class="col-xs-12 col-sm-2 display-label"
                                    >
                                        Objective Outcome Details:
                                    </div>
                                    <div class="col-xs-12 col-sm-10">
                                        {{ mission?.objectiveOutcomeNotes }}
                                    </div>
                                </div>
                                <div class="row top-buffer" *ngIf="isCompleted">
                                    <div
                                        class="col-xs-12 col-sm-2 display-label"
                                    >
                                        Mission Journal:
                                    </div>
                                    <div class="col-xs-12 col-sm-10">
                                        {{ mission?.completionMessage }}
                                    </div>
                                </div>

                                <h4>Flights</h4>
                                <div class="table-container">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Duration</th>
                                                <th>Flight Status</th>
                                                <th>Reason</th>
                                                <th>Remote Pilot</th>
                                                <th>RPA</th>
                                                <th>Battery Set</th>
                                                <th class="actions"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr
                                                *ngFor="
                                                    let sortie of missionSorties
                                                "
                                            >
                                                <td>{{ sortie.number }}</td>
                                                <td>
                                                    <ng-container
                                                        [ngSwitch]="
                                                            sortie.durationSource
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngSwitchCase="
                                                                'MANUAL'
                                                            "
                                                        >
                                                            Manual:
                                                            {{
                                                                sortie.duration
                                                                    | formatDuration
                                                                        : ''
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngSwitchCase="
                                                                'TIMER'
                                                            "
                                                        >
                                                            Timer:
                                                            {{
                                                                sortie.duration
                                                                    | formatDuration
                                                                        : ''
                                                            }}
                                                            <em
                                                                >({{
                                                                    sortie.recordedStartTime
                                                                        | formatTime
                                                                }}
                                                                to
                                                                {{
                                                                    sortie.recordedEndTime
                                                                        | formatTime
                                                                }})</em
                                                            >
                                                        </ng-container>
                                                        <ng-container
                                                            *ngSwitchCase="
                                                                'LOGS'
                                                            "
                                                        >
                                                            <span
                                                                [tooltip]="
                                                                    sortie.logTimes
                                                                "
                                                                placement="top"
                                                            >
                                                                Logs:
                                                                {{
                                                                    sortie.duration
                                                                        | formatDuration
                                                                            : ''
                                                                }}
                                                                <em
                                                                    >({{
                                                                        sortie.recordedStartTime
                                                                            | formatTime
                                                                    }}
                                                                    to
                                                                    {{
                                                                        sortie.recordedEndTime
                                                                            | formatTime
                                                                    }})</em
                                                                >
                                                            </span>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td>{{ sortie.status }}</td>
                                                <td>{{ sortie.reason }}</td>
                                                <td>
                                                    {{
                                                        personLookup[
                                                            sortie.pilotId
                                                        ] | formatPerson
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        (craftLookup
                                                            ? craftLookup[
                                                                  sortie.craftId
                                                              ]
                                                            : null
                                                        ) | formatRpa
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        (batterySetLookup
                                                            ? batterySetLookup[
                                                                  sortie
                                                                      .batterySetId
                                                              ]
                                                            : null
                                                        ) | formatBatterySet
                                                    }}
                                                </td>
                                                <td class="actions">
                                                    <button
                                                        type="button"
                                                        class="btn btn-tertiary btn-sm"
                                                        tooltip="View flight documentation"
                                                        [disabled]="working"
                                                        (click)="
                                                            showSortieDocumentation(
                                                                sortie
                                                            )
                                                        "
                                                    >
                                                        <span
                                                            class="fal fa-paperclip"
                                                        ></span>
                                                    </button>
                                                    <button
                                                        *ngIf="
                                                            hasFlightLogging &&
                                                            flightLogsByFlight
                                                        "
                                                        tooltip="Analyse flight log"
                                                        type="button"
                                                        class="btn btn-tertiary btn-sm"
                                                        [disabled]="
                                                            flightLogsByFlight[
                                                                sortie.id
                                                            ] == null
                                                        "
                                                        (click)="
                                                            analyseFlightLog(
                                                                sortie,
                                                                flightLogsByFlight[
                                                                    sortie.id
                                                                ][0]
                                                            )
                                                        "
                                                    >
                                                        <span
                                                            class="fal fa-map"
                                                        ></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h4
                                    *ngIf="
                                        unassignedFlightLogs != null &&
                                        unassignedFlightLogs.length > 0
                                    "
                                >
                                    Flight Logs without Flights
                                </h4>
                                <flight-log-list
                                    *ngIf="
                                        unassignedFlightLogs != null &&
                                        unassignedFlightLogs.length > 0
                                    "
                                    [flightLogs]="unassignedFlightLogs"
                                    [rpas]="mission.crafts"
                                    [mission]="mission"
                                ></flight-log-list>
                            </tab>
                            <tab
                                [heading]="
                                    approvedAirspaceAuthorisations != null &&
                                    approvedAirspaceAuthorisations.length &&
                                    airspaceLoading
                                        ? 'Loading'
                                        : 'Airspace Authorisation'
                                "
                                *ngIf="
                                    userCanViewAirspaceAuthorisation &&
                                    showAirspaceAuthorisationStatus &&
                                    (approvedAirspaceAuthorisations != null ||
                                        airspaceLoading)
                                "
                                [disabled]="
                                    approvedAirspaceAuthorisations == null
                                "
                            >
                                <div class="relative" style="height: 280px">
                                    <airspace-authorisation-status
                                        [checkingAuthorisation]="
                                            working || airspaceLoading
                                        "
                                        [enabledAuthorisationTypes]="
                                            enabledAuthorisationTypes
                                        "
                                        [approvedAirspaceAuthorisations]="
                                            approvedAirspaceAuthorisations
                                        "
                                        [jurisdiction]="
                                            location?.airspaceJurisdiction
                                        "
                                        [isMissionDetailsView]="true"
                                        [userCanViewAirspaceAuthorisation]="
                                            userCanViewAirspaceAuthorisation
                                        "
                                        [userCanRequestAirspaceAuthorisation]="
                                            false
                                        "
                                        (showAirspaceAuthorisation)="
                                            showAirspaceAuthorisation()
                                        "
                                        (cancelAirspaceAuthorisation)="
                                            cancelAirspaceAuthorisation($event)
                                        "
                                        (requestNewAirspaceAuthorisation)="
                                            requestNewAirspaceAuthorisation(
                                                $event
                                            )
                                        "
                                    ></airspace-authorisation-status>
                                </div>
                            </tab>
                            <tab
                                heading="Incident Reports"
                                *ngIf="
                                    (hasIncidentReports &&
                                        mission.status === 'COMPLETED') ||
                                    mission.status === 'FINALISED'
                                "
                            >
                                <plan-highlight
                                    [featureFlags]="[
                                        featureFlags['hasIncidentReports']
                                    ]"
                                ></plan-highlight>
                                <incident-report-list
                                    class="top-buffer container-padding"
                                    [missionId]="mission.id"
                                    [timeZone]="mission.timeZone"
                                    [organisationId]="mission.organisationId"
                                    [canAdd]="true"
                                >
                                </incident-report-list>
                            </tab>
                            <tab
                                heading="Conformance"
                                *ngIf="
                                    currentOrganisation != null &&
                                    mission != null &&
                                    (mission.status === 'COMPLETED' ||
                                        mission.status === 'FINALISED')
                                "
                            >
                                <flight-conformance-details
                                    [mission]="mission"
                                    [organisationId]="currentOrganisation.id"
                                    (refreshRibbon)="refreshRibbon()"
                                ></flight-conformance-details>
                            </tab>
                            <tab
                                heading="Authority Library"
                                *ngIf="
                                    mission.missionWorkflowVersion
                                        ?.delegatedAuthority != null
                                "
                            >
                                <authority-library
                                    class="top-buffer container-scroll"
                                    [organisationId]="mission.organisationId"
                                    [mission]="mission"
                                >
                                </authority-library>
                            </tab>
                            <tab
                                heading="NOTAMs"
                                *ngIf="
                                    canUseNotams ||
                                    (mission.notams != null &&
                                        mission.notams.length > 0)
                                "
                            >
                                <plan-highlight
                                    [featureFlags]="[
                                        featureFlags['canUseNotams']
                                    ]"
                                ></plan-highlight>
                                <div
                                    *ngFor="let notam of mission.notams"
                                    class="top-buffer"
                                >
                                    <notam-details
                                        [notam]="notam"
                                        [listOnly]="true"
                                        [isExpanded]="true"
                                        [disableActions]="true"
                                        [viewNotamDetailModal]="
                                            viewNotamDetailModal
                                        "
                                    ></notam-details>
                                </div>
                            </tab>
                            <!-- <tab
                                *ngIf="
                                    mission?.airspaceAuthorisationList?.length >
                                        0 &&
                                    mission.airspaceAuthorisationList[
                                        mission.airspaceAuthorisationList
                                            .length - 1
                                    ].authorisationType ==
                                        'AUS_CASA_AUTHORISATION'
                                "
                                heading="CASA Authorisation"
                            >
                                <div class="relative" style="height: 280px">
                                    <airspace-authorisation-status
                                        [checkingAuthorisation]="
                                            checkingAuthorisation
                                        "
                                        [canDoAirspaceAuthorisation]="
                                            canDoAirspaceAuthorisation
                                        "
                                        [areAuthorisationRequirementsComplete]="
                                            areAuthorisationRequirementsComplete
                                        "
                                        [airspaceCheckCanFly]="
                                            airspaceCheckCanFly
                                        "
                                        [approvedAirspaceAuthorisation]="
                                            approvedAirspaceAuthorisation
                                        "
                                        [airspaceApprovalState]="
                                            airspaceApprovalState
                                        "
                                        (showAirspaceAuthorisation)="
                                            showAirspaceAuthorisation()
                                        "
                                        (requestNewAirspaceAuthorisation)="
                                            requestNewAirspaceAuthorisation()
                                        "
                                        (cancelAirspaceAuthorisation)="
                                            cancelAirspaceAuthorisation()
                                        "
                                        (closeAirspaceAuthorisation)="
                                            closeAirspaceAuthorisation()
                                        "
                                    ></airspace-authorisation-status>
                                </div>
                            </tab> -->
                        </tabset>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-5 top-buffer">
                        <h4 class="section-heading">
                            <span>Workflow Documentation</span>
                            <div></div>
                        </h4>
                        <p>
                            These are the forms that have been filled in before
                            and after the mission.
                        </p>
                        <ng-container *ngIf="documentation">
                            <documentation-list
                                [organisationId]="currentOrganisation.id"
                                [formResponses]="documentation.formResponses"
                                [requiredDocumentation]="
                                    documentation.requiredDocumentation
                                "
                                [steps]="missionDocumentationSteps"
                                [title]="'Mission'"
                                [requiringEntities]="requiringEntities"
                                [documentationActions]="documentationActions"
                                [attachmentsHandler]="attachmentsHandler"
                                [missionRequiredDocumentation]="
                                    documentation.requiredDocumentation
                                "
                                [missionApprovalList]="mission?.approvals"
                            >
                            </documentation-list>
                        </ng-container>
                    </div>
                    <div class="col-md-7 top-buffer">
                        <h4 class="section-heading">
                            <span>Attached Documents</span>
                            <div></div>
                        </h4>
                        <attachment-list
                            *ngIf="attachmentsHandler"
                            [attachmentsHandler]="attachmentsHandler"
                            [allowUploads]="canEdit"
                            [allowDelete]="false"
                            [allowEdit]="canEdit"
                            [parentPromiseTracker]="workTracker"
                            permissions
                        >
                        </attachment-list>
                    </div>
                    <div class="col-xs-12 top-buffer">
                        <accordion
                            *ngIf="
                                mission != null && mission.approvals.length > 0
                            "
                            class="inline-section"
                            [closeOthers]="true"
                        >
                            <accordion-group
                                (isOpenChange)="
                                    $event ? (opened = true) : (opened = false)
                                "
                            >
                                <div accordion-heading>
                                    <div
                                        class="pull-right"
                                        tooltip="Historical approvals"
                                    >
                                        <span
                                            class="pull-right glyphicon"
                                            [ngClass]="{
                                                'glyphicon-chevron-down':
                                                    opened,
                                                'glyphicon-chevron-right':
                                                    !opened
                                            }"
                                        ></span>
                                    </div>
                                    Approval History
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Approval ID</th>
                                            <th>Submission time</th>
                                            <th>Approval status</th>
                                            <th>Message</th>
                                            <th>Documentation</th>
                                            <th>Conditions</th>
                                            <th style="width: 5%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let approval of mission.approvals
                                            "
                                        >
                                            <td>{{ approval.id }}</td>
                                            <td>
                                                {{
                                                    approval.requestTime
                                                        | formatDateTime
                                                }}
                                                <br />
                                                <span
                                                    ><em>{{
                                                        approval.requestedBy
                                                            | formatPerson
                                                    }}</em></span
                                                >
                                            </td>
                                            <td>
                                                {{
                                                    approval.status
                                                        | formatApprovalStatus
                                                }}
                                                <span
                                                    *ngIf="
                                                        isResolved(
                                                            approval.status
                                                        )
                                                    "
                                                >
                                                    <em>{{
                                                        approval.approvedBy
                                                            | formatPerson
                                                    }}</em>
                                                    {{
                                                        approval.resolutionTime
                                                            | formatDateTime
                                                    }}
                                                </span>
                                            </td>
                                            <td style="white-space: pre-line">
                                                {{
                                                    approval.messageFromApprover
                                                }}
                                                <br />
                                            </td>
                                            <td>
                                                <!--                                    <completed-documentation-->
                                                <!--                                            organisation-id="$ctrl.currentOrganisation.id"-->
                                                <!--                                            form-responses="$ctrl.documentation.formResponses"-->
                                                <!--                                            required-documentation="$ctrl.approvalRequiredDocumentation[approval.id]"-->
                                                <!--                                            steps="$ctrl.approvalSteps"-->
                                                <!--                                            title="Mission Approval"-->
                                                <!--                                            requiring-entities="[{requiringEntityType: 'MissionApproval', requiringEntityId: approval.id}]">-->
                                                <!--                                    </completed-documentation>-->
                                            </td>
                                            <td>
                                                <ol>
                                                    <li
                                                        *ngFor="
                                                            let condition of approval.conditions
                                                        "
                                                    >
                                                        {{ condition }}
                                                    </li>
                                                </ol>
                                            </td>
                                            <td>
                                                <div
                                                    class="horizontal-container"
                                                    style="justify-content: end"
                                                >
                                                    <button
                                                        class="btn btn-primary btn-sm right-buffer"
                                                        type="button"
                                                        *ngIf="
                                                            canPrepareToFly &&
                                                            allowEditSubmit &&
                                                            approval.status ==
                                                                'DRAFT'
                                                        "
                                                        (click)="
                                                            submitForApproval(
                                                                approval
                                                            )
                                                        "
                                                        [disabled]="working"
                                                    >
                                                        Submit
                                                    </button>
                                                    <button
                                                        class="btn btn-default-action btn-sm right-buffer"
                                                        type="button"
                                                        *ngIf="
                                                            canPrepareToFly &&
                                                            allowEditSubmit &&
                                                            (approval.status ==
                                                                'DRAFT' ||
                                                                approval.status ==
                                                                    'PENDING')
                                                        "
                                                        (click)="
                                                            cancelApproval(
                                                                approval
                                                            )
                                                        "
                                                        [disabled]="working"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        class="btn btn-primary btn-sm"
                                                        type="button"
                                                        tooltip="View or perform the mission approval"
                                                        placement="top"
                                                        *ngIf="
                                                            canApprove &&
                                                            (combinedStatus !=
                                                                'DRAFT' ||
                                                                (missionApprovals &&
                                                                    missionApprovals.length >
                                                                        1)) &&
                                                            approval.status !=
                                                                'CANCELLED' &&
                                                            approval.status !=
                                                                'DRAFT'
                                                        "
                                                        [disabled]="working"
                                                        (click)="
                                                            findApprovalAndApprove(
                                                                approval
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            approval.status ==
                                                                'PENDING' ||
                                                            approval.status ==
                                                                'BEING_REVIEWED'
                                                                ? 'Perform'
                                                                : 'View'
                                                        }}
                                                        Approval
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="modal-footer">
            <div *ngIf="missionDetails != null && modalStatus === 'LOADED'">
                <div
                    class="btn-group"
                    *ngIf="printOptions.length > 0"
                    dropdown
                    [dropup]="true"
                >
                    <button
                        class="btn btn-default dropdown-toggle"
                        dropdownToggle
                        type="button"
                        aria-controls="dropdown-container"
                        [disabled]="working"
                    >
                        Reports
                        <span class="caret"></span>
                    </button>
                    <ul
                        class="dropdown-menu dropdown-menu-right modal-dropdown"
                        *dropdownMenu
                        role="menu"
                    >
                        <li *ngFor="let option of printOptions" role="menuitem">
                            <a (click)="option.print()">{{ option.name }}</a>
                        </li>
                    </ul>
                </div>
                <button
                    class="btn btn-default pull-left"
                    type="button"
                    *ngIf="canCancel"
                    (click)="cancelMission()"
                    [disabled]="working || hasPendingApproval()"
                    tooltip="Cancel the mission"
                    placement="top"
                >
                    Cancel Mission
                </button>
                <button
                    class="btn btn-danger pull-left"
                    type="button"
                    *ngIf="canDelete"
                    (click)="deleteMission()"
                    [disabled]="working"
                    tooltip="Delete the mission"
                    placement="top"
                >
                    Delete Mission
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    *ngIf="canUnfinalise && mission.status == 'FINALISED'"
                    [disabled]="working"
                    (click)="unfinaliseMission()"
                >
                    Unfinalise Mission
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    *ngIf="canComplete && mission.status == 'READY_TO_FLY'"
                    [disabled]="working"
                    (click)="openMissionCompletionDialogue()"
                    tooltip="Enter the details of what happened during the mission"
                    placement="top"
                >
                    Create Mission Record
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    *ngIf="
                        canComplete &&
                        mission.status == 'COMPLETED' &&
                        mission.type != 'RETROSPECTIVE'
                    "
                    [disabled]="working"
                    (click)="openMissionCompletionDialogue()"
                    tooltip="Make amendments to the finished mission"
                    placement="top"
                >
                    Edit Mission Record
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    *ngIf="canComplete && mission.type == 'RETROSPECTIVE'"
                    [disabled]="working"
                    (click)="openMissionRecordDialogue()"
                    tooltip="Make amendments to the retrospective mission"
                    placement="top"
                >
                    Edit Mission Record
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    *ngIf="
                        allowEditSubmit &&
                        canEdit &&
                        mission.type != 'RETROSPECTIVE'
                    "
                    [disabled]="working"
                    (click)="editMission()"
                >
                    Edit Mission Plan
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    *ngIf="
                        canApprove &&
                        combinedStatus != 'DRAFT' &&
                        missionApprovals &&
                        activeMissionApprovals &&
                        activeMissionApprovals.length > 1
                    "
                    [disabled]="working"
                    #approvalSelectionPopover="bs-popover"
                    [popover]="approvalSelectionPopTemplate"
                    container=""
                    [outsideClick]="true"
                    [adaptivePosition]="true"
                    placement="top"
                >
                    {{ combinedStatus == 'SUBMITTED' ? 'Perform' : 'View' }}
                    Approval
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    tooltip="View or perform the mission approval"
                    placement="top"
                    *ngIf="
                        canApprove &&
                        combinedStatus != 'DRAFT' &&
                        missionApprovals &&
                        activeMissionApprovals &&
                        activeMissionApprovals.length === 1
                    "
                    [disabled]="working"
                    (click)="approveMission(activeMissionApprovals[0])"
                >
                    {{ combinedStatus == 'SUBMITTED' ? 'Perform' : 'View' }}
                    Approval
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    tooltip="View or perform the mission approval"
                    placement="top"
                    *ngIf="
                        combinedStatus != 'DRAFT' &&
                        canAcceptApproval &&
                        missionApprovals &&
                        missionApprovals.length === 1
                    "
                    [disabled]="working"
                    (click)="acceptApproval(missionApprovals[0])"
                >
                    Accept Approval
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    tooltip="View or perform the mission approval"
                    placement="top"
                    *ngIf="
                        combinedStatus != 'DRAFT' &&
                        canAcceptApproval &&
                        missionApprovals &&
                        missionApprovals.length > 1
                    "
                    [disabled]="working"
                    #approvalSelectionPopover="bs-popover"
                    [popover]="approvalSelectionPopTemplate"
                    container=""
                    [outsideClick]="true"
                    [adaptivePosition]="true"
                    placement="top"
                >
                    Accept Approvals
                </button>
            </div>
        </div>
    </form>
    <inline-modal
        #viewNotamDetailModal
        [sizeSmall]="true"
        style="max-height: fit-content"
    >
    </inline-modal>
</ng-container>

<ng-template #approvalSelectionPopTemplate>
    <mission-approval-selection-popup
        [approvals]="missionApprovals"
        [organisation]="organisation"
        [canApprove]="canApprove"
        [canAcceptApproval]="canAcceptApproval"
        (viewApproval)="approveMission($event)"
        (acceptApproval)="acceptApproval($event)"
        (cancel)="approvalSelectionPopover.hide()"
    ></mission-approval-selection-popup>
</ng-template>
