import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { FeaturesModule } from 'libs/features/src/lib/features.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AdminCurrencyDialoguesService } from './admin-currency-dialogues.service';
import { EditOperatingCategoriesDialogue } from './operating-categories/edit/edit-operating-categories-dialogue.component';
import { CurrencyDialoguesService } from './currency-dialogues.service';
import { CurrencyConfigurationDialogue } from './currency/requirements/currency-configuration.component';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        LoadingIndicatorModule,
        EnhancedHelpModule,
        FeaturesModule,
        AnalyticsModule,
        TooltipModule
    ],
    providers: [AdminCurrencyDialoguesService, CurrencyDialoguesService],
    declarations: [
        EditOperatingCategoriesDialogue,
        CurrencyConfigurationDialogue
    ],
    exports: [EditOperatingCategoriesDialogue, CurrencyConfigurationDialogue]
})
export class CurrencyModule {}
