import { Pipe, PipeTransform } from '@angular/core';
import { FlightConformanceRuleDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatConformanceRuleSettings' })
export class FormatConformanceRuleSettingsPipe implements PipeTransform {
    transform(value: FlightConformanceRuleDto) {
        const defaultStr =
            value.defaultValue != null
                ? `Default value: ${value.defaultValue}`
                : undefined;
        const maxStr =
            value.maximumValue != null
                ? `Maximum value: ${value.maximumValue}`
                : undefined;

        return [defaultStr, maxStr].filter(str => str !== undefined).join(' ');
    }
}
