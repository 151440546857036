import { Component, OnInit, ElementRef } from '@angular/core';
import { collapseAnimation } from 'angular-animations';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'personal-footer',
    templateUrl: './footer-personal-details.component.html',
    styleUrls: ['../footer.scss'],
    animations: [collapseAnimation()]
})
export class PersonalFooter implements OnInit {
    titles: boolean = false;
    tabs = [
        { id: 1, tab: 'PERSONAL_DETAILS' },
        { id: 2, tab: 'PERSONAL_FLIGHT_PREFERENCES' },
        { id: 3, tab: 'PERSONAL_NONPRIMARY' },
        // { id: 4, tab: 'PERSONAL_PRIMARY' },
        { id: 4, tab: 'PERSONAL_SETUP_COMPLETE' }
    ];
    tabId: number;
    isMobile: boolean = false;
    constructor(private obsds: OnboardingData, private el: ElementRef) {}

    ngOnInit() {
        this.obsds.currentScreenAndTab$.subscribe(({ tab }) => {
            const currentTab = this.tabs.find(t => t.tab === tab);
            if (currentTab) {
                this.tabId = currentTab.id;
            }
        });
        this.setMobile();
        window.addEventListener('resize', () => this.setMobile(), true);
    }

    setMobile() {
        if (window.innerWidth <= 767) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }
}
