import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    FEATURE_DRONE_DEPLOY_LOGS,
    FEATURE_SKYDIO_LOGS,
    FEATURE_DJI_LOGS,
    hasFeatureFlag,
    UserService,
    WorkTracker,
    FEATURE_FLIGHT_LOG_AUTOMATIC_MISSION_CONFIGURATION,
    LookupObject,
    PersonsOrganisationDto,
    hasAnyPermission,
    ExclusiveControlService
} from '@flyfreely-portal-ui/flyfreely';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlightLogSettingsService } from './flight-log-settings.service';

@Component({
    templateUrl: './flight-log-settings.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    selector: 'flight-log-settings',
    providers: [WorkTracker, FlightLogSettingsService, ExclusiveControlService]
})
export class FlightLogSettings {
    @Input() organisationId: number;

    working = false;
    hasDroneDeploy = false;
    hasSkydio = false;
    hasDjiLogs = false;
    hasMissionConfig = false;

    canManageAll = false;
    canManageOwn = false;

    featureFlags: LookupObject<string[]> = {};

    private ngUnsubscribe$ = new Subject<void>();

    screenContext: { [key: string]: string | boolean };

    constructor(
        private modal: BsModalRef,
        private flightLogSettingsService: FlightLogSettingsService,
        private workTracker: WorkTracker,
        private userService: UserService,
        private exclusiveControlService: ExclusiveControlService,
        modalOptions: ModalOptions
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.exclusiveControlService.locked) {
                this.exclusiveControlService.requestUnlock();
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.flightLogSettingsService.setOrganisation(this.organisationId);

        this.findPermissions();

        this.screenContext = {
            skydio: this.hasSkydio,
            droneDeploy: this.hasDroneDeploy
        };
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findPermissions() {
        const maybeOrganisation = this.userService.findOrganisationForUser(
            this.organisationId
        );

        this.canManageAll = pipe(
            maybeOrganisation,
            map(o =>
                hasAnyPermission(
                    o,
                    PersonsOrganisationDto.Permissions
                        .FLIGHT_LOG_COLLECTION_MANAGE
                )
            ),
            getOrElse(() => false)
        );

        this.canManageOwn = pipe(
            maybeOrganisation,
            map(o =>
                hasAnyPermission(
                    o,
                    PersonsOrganisationDto.Permissions.FLIGHT_LOG_COLLECTION_OWN
                )
            ),
            getOrElse(() => false)
        );

        this.hasDjiLogs = pipe(
            maybeOrganisation,
            map(o => hasFeatureFlag(o, FEATURE_DJI_LOGS)),
            getOrElse(() => false)
        );
        this.featureFlags['hasDjiLogs'] = [FEATURE_DJI_LOGS];

        this.hasDroneDeploy = pipe(
            maybeOrganisation,
            map(o => hasFeatureFlag(o, FEATURE_DRONE_DEPLOY_LOGS)),
            getOrElse(() => false)
        );
        this.featureFlags['hasDroneDeploy'] = [FEATURE_DRONE_DEPLOY_LOGS];

        this.hasSkydio = pipe(
            maybeOrganisation,
            map(o => hasFeatureFlag(o, FEATURE_SKYDIO_LOGS)),
            getOrElse(() => false)
        );
        this.featureFlags['hasSkydio'] = [FEATURE_SKYDIO_LOGS];

        this.hasMissionConfig = pipe(
            maybeOrganisation,
            map(o =>
                hasFeatureFlag(
                    o,
                    FEATURE_FLIGHT_LOG_AUTOMATIC_MISSION_CONFIGURATION
                )
            ),
            getOrElse(() => false)
        );
    }

    close() {
        this.modal.hide();
    }

    showOrganisationSettings() {
        this.flightLogSettingsService.showOrganisationAutomaticMissionConfiguration();
    }
}
