import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationService,
    PersonDto,
    PersonService,
    PersonsOrganisationDto,
    WorkTracker,
    hasAnyPermission
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'organisation-overview',
    templateUrl: './organisation-overview.component.html'
})
export class OrganisationOverview implements OnChanges {
    @Input() organisation: PersonsOrganisationDto;

    owner: PersonDto;

    canEdit = false;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working = false;

    timeZones = moment.tz.names();

    constructor(
        private personService: PersonService,
        private organisationService: OrganisationService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refreshOwner();
        this.canEdit = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshOwner() {
        if (this.organisation == null) {
            this.owner = null;
            return;
        }
        this.personService
            .findPerson(this.organisation.ownerId, this.organisation.id)
            .subscribe({
                next: person => (this.owner = person),
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing organisation owner: ${error.message}`
                    )
            });
    }

    // Done as an arrow function so it can be used as a callback
    updateName = (value: { name: string }) => {
        if (value == null || value.name == null || value.name.length <= 2) {
            return Promise.reject();
        }
        return firstValueFrom(
            this.organisationService
                .updateOrganisationName(this.organisation.id, {
                    name: value.name
                })
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    tap({
                        next: () =>
                            this.logging.success('Organisation name updated'),
                        error: (error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                'Could not update organisation name'
                            )
                    })
                )
        ).finally(this.workTracker.createTracker());
    };

    // Done as an arrow function so it can be used as a callback
    updateTimeZone = (value: { timeZone: string }) => {
        if (
            value == null ||
            value.timeZone == null ||
            value.timeZone.length <= 2
        ) {
            return Promise.reject();
        }
        return firstValueFrom(
            this.organisationService
                .updateOrganisationTimeZone(this.organisation.id, {
                    timeZone: value.timeZone
                })
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    tap({
                        next: () =>
                            this.logging.success(
                                'Organisation timezone updated'
                            ),
                        error: (error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                'Could not update organisation timezone'
                            )
                    })
                )
        ).finally(this.workTracker.createTracker());
    };
}
