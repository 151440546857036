import { Component } from '@angular/core';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'support-links',
    templateUrl: './support-links.component.html',
    styles: [
        `
            .support-links-container {
                min-width: 300px;
            }

            .btn-wide {
                font-size: 1.3em;
                line-height: 2em;
                border-radius: 1em;
                text-transform: uppercase;
            }
        `
    ]
})
export class SupportLinksComponent {
    IMG_URL: string;

    constructor(constants: FlyFreelyConstants) {
        this.IMG_URL = constants.IMG_URL;
    }
}
