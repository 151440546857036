import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ngfModule } from 'angular-file';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { Formly12Column } from './formly-12-column.component';
import { FormlyAcknowledgedWarning } from './formly-acknowledged-warning.component';
import { FormlyCheck } from './formly-check.component';
import { FormlyCheckbox } from './formly-checkbox.component';
import { FormlyCountrySelect } from './formly-country-select.component';
import { FormlyDate } from './formly-date.component';
import { FormlyDateTime } from './formly-datetime.component';
import { FormlyEditableNgSelect } from './formly-editable-ng-select.component';
import { FormlyWrapperFormField } from './formly-field-wrapper.component';
import { FormlyGraphQlNgSelect } from './formly-graphql-ng-select.component';
import { FormlyHelp } from './formly-help.component';
import { FormlyImage } from './formly-image.component';
import { FormlyInfo } from './formly-info.component';
import { FormlyWrapperInlineFormField } from './formly-inline-field-wrapper.component';
import { FormlyNgSelect } from './formly-ng-select.component';
import { FormlyPhoneNumber } from './formly-phonenumber.component';
import { FormlyRadio } from './formly-radio.component';
import { FormlyRange } from './formly-range.component';
import { FormlySectionHeading } from './formly-section-heading.component';
import { FormlyTable } from './formly-table.component';
import { FormlyTextarea } from './formly-textarea.component';
import { FormlyValue } from './formly-value.component';
import { FormlyWarning } from './formly-warning.component';
import { FormlyDuration } from './formly-duration.component';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        BsDropdownModule,
        TimepickerModule,
        NgSelectModule,
        ngfModule,
        FormlyBootstrapModule,
        ButtonsModule,
        FormlyModule.forChild({
            types: [
                { name: 'section-heading', component: FormlySectionHeading },
                { name: 'image', component: FormlyImage },
                { name: 'warning', component: FormlyWarning },
                {
                    name: 'warning-with-acknowledgement',
                    component: FormlyAcknowledgedWarning
                },
                { name: 'info', component: FormlyInfo },
                { name: 'help', component: FormlyHelp },
                {
                    name: 'value',
                    component: FormlyValue,
                    wrappers: ['form-field']
                },
                {
                    name: 'textarea',
                    component: FormlyTextarea,
                    wrappers: ['form-field']
                },
                {
                    name: 'calculatedValue',
                    component: FormlyValue,
                    wrappers: ['form-field']
                },
                {
                    name: 'check',
                    component: FormlyCheck,
                    wrappers: ['form-field']
                },
                {
                    name: 'checkbox',
                    component: FormlyCheckbox,
                    wrappers: ['form-field']
                },
                {
                    name: 'radio',
                    component: FormlyRadio,
                    wrappers: ['form-field']
                },
                {
                    name: 'range',
                    component: FormlyRange,
                    wrappers: ['form-field']
                },
                {
                    name: 'date',
                    component: FormlyDate,
                    wrappers: ['form-field']
                },
                {
                    name: 'datetime',
                    component: FormlyDateTime,
                    wrappers: ['form-field']
                },
                {
                    name: 'duration',
                    component: FormlyDuration,
                    wrappers: ['form-field']
                },
                {
                    name: 'phonenumber',
                    component: FormlyPhoneNumber,
                    wrappers: ['form-field']
                },
                {
                    name: 'country',
                    component: FormlyCountrySelect,
                    wrappers: ['form-field']
                },
                {
                    name: 'ng-select',
                    component: FormlyNgSelect,
                    wrappers: ['form-field']
                },
                {
                    name: 'editable-select',
                    component: FormlyEditableNgSelect,
                    wrappers: ['form-field']
                },
                {
                    name: 'graphql-select',
                    component: FormlyGraphQlNgSelect,
                    wrappers: ['form-field']
                },
                {
                    name: 'table',
                    component: FormlyTable
                },
                { name: 'twelve-column', component: Formly12Column }
            ],
            wrappers: [
                { name: 'form-field', component: FormlyWrapperFormField },
                {
                    name: 'inline-form-field',
                    component: FormlyWrapperInlineFormField
                }
            ]
        }),
        ValidationModule,
        EnhancedHelpModule
    ],
    providers: [],
    bootstrap: [],
    declarations: [
        FormlySectionHeading,
        FormlyCheckbox,
        FormlyWrapperFormField,
        FormlyImage,
        FormlyWarning,
        FormlyInfo,
        FormlyAcknowledgedWarning,
        FormlyRange,
        FormlyValue,
        FormlyTable,
        FormlyCheck,
        FormlyRadio,
        Formly12Column,
        FormlyDate,
        FormlyDateTime,
        FormlyPhoneNumber,
        FormlyTextarea,
        FormlyCountrySelect,
        FormlyNgSelect,
        FormlyEditableNgSelect,
        FormlyWrapperInlineFormField,
        FormlyGraphQlNgSelect,
        FormlyHelp,
        FormlyDuration
    ],
    exports: []
})
export class FlyFreelyFormlyModule {}
