import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { FlyFreelyError } from './errors';
import { FlyFreelyLoggingService } from './flyfreelyLogging.service';

interface CopyMap {
    [copyId: string]: string;
}

@Injectable({
    providedIn: 'root'
})
export class CopyService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();
    cache: CopyMap;

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private logging: FlyFreelyLoggingService
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    cacheCopy() {
        return this.http
            .get<CopyMap>(`${this.baseUrl}/publicapi/copy`)
            .toPromise()
            .then((allCopy: CopyMap) => {
                this.cache = allCopy;
                return allCopy;
            })
            .catch((error: FlyFreelyError) => this.logging.error(error));
    }

    findCopy(identifier: string) {
        if (this.cache != null) {
            return this.resolveCopy(this.cache[identifier]);
        }

        return this.cacheCopy().then(allCopy =>
            this.resolveCopy(allCopy[identifier])
        );
    }

    private resolveCopy(copy: string) {
        if (copy == null) {
            return Promise.reject();
        }
        return Promise.resolve(copy);
    }

    findCopyAsSafeHtml(identifier: string) {
        return this.findCopy(identifier).then(copy =>
            copy ? copy.toString() : ''
        );
    }
}
