import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AirspaceAuthorisationService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameId,
    RegisterRemotePilotCommand,
    RemotePilotRegistrationOption,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface RegistrationForm {
    organisationId: FormControl<number>;
}

@Component({
    selector: 'remote-pilot-registration',
    templateUrl: './remote-pilot-registration.component.html'
})
export class RemotePilotRegistrationDialogue {
    @Input() authorisationType: RegisterRemotePilotCommand.AuthorisationType;
    @Input() personId: number;
    @Input() remotePilotPhoneNumber?: string;
    @Input() managingOrganisaitonId: number;

    copyText: string = '';
    options: NameId[];

    registrationForm: FormGroup<RegistrationForm>;

    success$ = new Subject<boolean>();

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private airspaceAuthorisationService: AirspaceAuthorisationService,
        private logging: FlyFreelyLoggingService,
        private modal: BsModalRef,
        private angulartics2: Angulartics2
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.registrationForm = new FormGroup({
            organisationId: new FormControl(undefined, Validators.required)
        });
    }

    ngOnInit() {
        this.airspaceAuthorisationService
            .findPilotRegistrationOptions(
                this.authorisationType,
                this.managingOrganisaitonId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.copyText = result.copyText ?? '';
                    this.setupListValues(result.options);
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while checking register options: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.success$.complete();
    }

    setupListValues(pilotOptions: RemotePilotRegistrationOption[]) {
        if (pilotOptions == null) {
            this.options = [];
            return;
        }
        this.options = pilotOptions.map(o => ({
            name: o.name,
            id: o.organisationId == null ? -1 : o.organisationId
        }));
    }

    register() {
        this.angulartics2.eventTrack.next({
            action: 'authorisation-register-pilot',
            properties: {
                category: 'airspace-authorisation'
            }
        });
        const command: RegisterRemotePilotCommand = {
            authorisationType: this.authorisationType,
            organisationId:
                this.organisationId.value === -1
                    ? null
                    : this.organisationId.value,
            managingOrganisationId: this.managingOrganisaitonId,
            personId: this.personId,
            remotePilotPhoneNumber: this.remotePilotPhoneNumber
        };
        this.airspaceAuthorisationService
            .registerPilotForAuthority(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `The remote pilot was successfully registered`
                    );
                    this.success$.next(true);
                    this.close();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error registering pilot: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    close() {
        this.modal.hide();
    }

    get organisationId() {
        return this.registrationForm.get('organisationId');
    }
}
