import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@blox/material';
import { ComponentBubbleModule } from '@flyfreely-portal-ui/component-bubble';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { PageTitle } from '@flyfreely-portal-ui/flyfreely';
import { MissionViewModule } from '@flyfreely-portal-ui/mission-view';
import { NgSelectModule } from '@ng-select/ng-select';
import { Angulartics2Module } from 'angulartics2';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { NotificationModule } from 'libs/notifications/src/lib/notifications.module';
import { SidebarActivityStreamModule } from 'libs/sidebar-activity-stream/src/lib/sidebar-activity-stream.module';
import { OrganisationSubscriptionState } from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SectionSelectorComponent } from './section-selector/section-selector.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { WorkSpaceComponent } from './workspace.component';

/**
 * The workspace module provides the framework for the dashboard.
 *
 * The `WorkspaceStateService` is responsible for coordinating the currently available widgets,
 * selected organisation, and selected widget. Other components subscribe to, and message to
 * this service to implement their functionality.
 *
 * Workspace --> Dashboard -> *Widgets
 *           \-> Sidebar
 *
 * There is a legacy feature where the widget's [organisation] is set, but this is not a proper
 * component lifecycle (no `ngOnChanges`) so the preferred way of getting the organisation
 * is to subscribe to the `WorkspaceStateService` observable.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MaterialModule,
        TooltipModule,
        ValidationModule,
        BsDropdownModule,
        SharedUiModule,
        EnhancedHelpModule,
        AnalyticsModule,
        Angulartics2Module,
        NotificationModule,
        SidebarActivityStreamModule,
        FeaturesModule,
        ComponentBubbleModule,
        MissionViewModule,
        RouterModule.forChild([])
    ],
    providers: [PageTitle, Title, OrganisationSubscriptionState],
    declarations: [
        WorkSpaceComponent,
        SidebarComponent,
        SectionSelectorComponent
    ]
})
export class WorkspaceModule {}
