import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';
import {
    AirspaceJurisdictionDto,
    AuthorityOnboardingDto,
    CreateAirspaceJurisdictionCommand,
    UpdateAirspaceJurisdictionCommand,
    UpdateAuthorityOnboardingConfigCommand
} from '@flyfreely-portal-ui/flyfreely';
import { AttachmentHandler } from '../attachments';
import {
    CreateOperatingCategoryCommand,
    OperatingCategoryValuesDtoObject
} from '../../model/api';
import { OperatingCategoryValuesDtoOperatingCategoryDto } from '../../model/api/operatingCategoryValuesDtoOperatingCategoryDto';

@Injectable({
    providedIn: 'root'
})
export class AdminJurisdictionService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findJurisdictions() {
        return this.http.get<AirspaceJurisdictionDto[]>(
            `${this.baseUrl}/adminapi/jurisdictions`
        );
    }

    findById(jurisdictionId: number) {
        return this.http.get<AirspaceJurisdictionDto>(
            `${this.baseUrl}/adminapi/jurisdictions/${jurisdictionId}`
        );
    }

    create(command: CreateAirspaceJurisdictionCommand) {
        return this.http
            .post<AirspaceJurisdictionDto>(
                `${this.baseUrl}/adminapi/jurisdictions`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    update(jurisdictionId: number, command: UpdateAirspaceJurisdictionCommand) {
        return this.http
            .put<AirspaceJurisdictionDto>(
                `${this.baseUrl}/adminapi/jurisdictions/${jurisdictionId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findJurisdictionOnboarding(jurisdictionId: number, phase: string) {
        return this.http.get<AuthorityOnboardingDto[]>(
            `${this.baseUrl}/adminapi/jurisdictions/${jurisdictionId}/onboarding?phase=${phase}`
        );
    }

    updateJurisdictionOnboarding(
        jurisdictionId: number,
        command: UpdateAuthorityOnboardingConfigCommand
    ) {
        return this.http
            .put<AuthorityOnboardingDto>(
                `${this.baseUrl}/adminapi/jurisdictions/${jurisdictionId}/onboarding`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    attachmentHandler(jurisdictionId: number) {
        return new AttachmentHandler(
            this.http,
            `/adminapi/jurisdictions/${jurisdictionId}/attachments`,
            false,
            null
        );
    }

    findOperatingConditionCategories(jurisdictionId: number) {
        return this.http.get<OperatingCategoryValuesDtoOperatingCategoryDto[]>(
            `${this.baseUrl}/adminapi/jurisdictions/${jurisdictionId}/operatingConditionCategories`
        );
    }

    createOperatingConditionCategories(
        jurisdictionId: number,
        command: CreateOperatingCategoryCommand
    ) {
        return this.http.post<OperatingCategoryValuesDtoOperatingCategoryDto[]>(
            `${this.baseUrl}/adminapi/jurisdictions/${jurisdictionId}/operatingConditionCategories`,
            command
        );
    }

    deleteOperatingConditionCategories(
        jurisdictionId: number,
        operatingConditionId: number
    ) {
        return this.http.delete<
            OperatingCategoryValuesDtoOperatingCategoryDto[]
        >(
            `${this.baseUrl}/adminapi/jurisdictions/${jurisdictionId}/operatingConditionCategories/${operatingConditionId}`
        );
    }
}
