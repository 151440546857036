<enhanced-help componentName="profile-details" [compact]="true">
</enhanced-help>
<div>
    <div>
        <label class="display-label">Name</label>
        <p>
            {{ currentUser | formatPerson }}
        </p>
    </div>
    <div>
        <label class="display-label">Email</label>
        <p>
            {{ currentUser.email }}
        </p>
    </div>
    <div>
        <label class="display-label">Phone number</label>
        <p>
            {{ currentUser.phoneNumber }}
        </p>
    </div>

    <button class="btn btn-link" (click)="updatePersonalDetails()">
        Update Personal Details
    </button>
</div>
<!-- <div>
    <button class="btn btn-link" (click)="manageAccountDetails()">
        Manage Account Details
    </button>
</div> -->

<div *ngIf="canUseNotams && userAsaIdentifiers != null" class="bottom-buffer">
    <label class="display-label">Air Services Australia</label>
    <ng-container *ngIf="userAsaIdentifiers.identifier == null">
        <p>Not currently setup.</p>
        <button class="btn btn-link" (click)="updateAirServices()">
            Setup ASA Details
        </button>
    </ng-container>
    <ng-container *ngIf="userAsaIdentifiers.identifier != null">
        <p>
            <span class="display-label">Username: </span
            >{{ userAsaIdentifiers.identifier }}
        </p>
        <button class="btn btn-link" (click)="updateAirServices()">
            Update ASA Details
        </button>
    </ng-container>
</div>
<address-list
    [organisationId]="currentUser.personalOrganisationId"
></address-list>
<pending-invites></pending-invites>
<loading-indicator [isLoading]="working"></loading-indicator>
