import { Component, Input } from '@angular/core';
import { DisplayableMissionDto } from '@flyfreely-portal-ui/flyfreely';
import { MissionCompletionService } from '../mission-completion/mission-completion.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { MissionOverviewMapDialogue } from './map-dialogue/mission-overview-map.component';
import { MissionFlightLogsDirective } from '../mission-completion/mission-flight-logs.directive';
import { MissionRecordService } from '../mission-record-edit/mission-record.service';

@Component({
    selector: 'mission-overview',
    template: `
        <div class="fill horizontal-container flex-centered">
            <button
                type="button"
                class="btn btn-default btn-sm"
                style="width: 100%;"
                (click)="showMissionOverviewMap()"
            >
                <div
                    class="horizontal-container fill flex-centered vertical-align"
                >
                    <span class="right-buffer">
                        <img
                            class="map-button-icon"
                            imgPath="/icons/icon-missions.png"
                            style="height: 2em"
                        />
                    </span>
                    Mission Map
                </div>
            </button>
        </div>
    `,
    styles: [
        `
            .map-button-icon {
                border-radius: 50%;
                overflow: hidden;
            }
        `
    ]
})
export class MissionOverviewComponent {
    @Input() mission: DisplayableMissionDto;
    @Input() historicalMission: boolean;

    constructor(
        private missionCompletionService: MissionCompletionService,
        private missionRecordService: MissionRecordService,
        private flightLogs: MissionFlightLogsDirective,
        private modalService: BsModalService
    ) {}

    showMissionOverviewMap() {
        return this.modalService.show<MissionOverviewMapDialogue>(
            MissionOverviewMapDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    mission: this.mission,
                    historicalMission: this.historicalMission
                },
                providers: [
                    {
                        provide: MissionCompletionService,
                        useValue: this.missionCompletionService
                    },
                    {
                        provide: MissionRecordService,
                        useValue: this.missionRecordService
                    },
                    {
                        provide: MissionFlightLogsDirective,
                        useValue: this.flightLogs
                    }
                ]
            }
        );
    }
}
