import { Injectable } from '@angular/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditOperatingCategoriesDialogue } from './operating-categories/edit/edit-operating-categories-dialogue.component';

@Injectable()
export class AdminCurrencyDialoguesService {
    constructor(private modalService: BsModalService) {}

    showEditOperatingCategoriesDialogue(airspaceJurisdictionId: number) {
        return this.modalService.show<EditOperatingCategoriesDialogue>(
            EditOperatingCategoriesDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-md',
                initialState: {
                    airspaceJurisdictionId
                }
            }
        );
    }
}
