import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ContactDto,
    ExclusiveControlService
} from '@flyfreely-portal-ui/flyfreely';
import {
    fadeInAnimation,
    fadeInExpandOnEnterAnimation,
    fadeOutAnimation,
    fadeOutCollapseOnLeaveAnimation
} from 'angular-animations';

interface ContactFormModel {
    name: FormControl<string>;
    subtype: FormControl<string>;
    value: FormControl<string>;
}

interface ContactModel {
    name: string;
    subtype?: string;
    value: string;
}

@Component({
    selector: 'contact-add',
    templateUrl: './contact-add.component.html',
    styleUrls: ['../contact-pill.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInAnimation(),
        fadeOutAnimation(),
        fadeInExpandOnEnterAnimation(),
        fadeOutCollapseOnLeaveAnimation()
    ]
})
export class ContactPillAdd implements OnInit {
    @Input() editName: boolean;
    @Input() editSubtype: boolean;
    @Input() editValue: boolean;
    @Input() requireName?: boolean = false;
    @Input() requireSubtype?: boolean = false;
    @Input() requireValue?: boolean = false;
    @Input() nameLabel?: string;
    @Input() subtypeLabel?: string;
    @Input() valueLabel?: string;
    @Input() readonly?: boolean;

    @Output() add = new EventEmitter<ContactDto>();
    @Output() cancel = new EventEmitter<void>();

    inputForm = new FormGroup<ContactFormModel>({
        name: new FormControl<string>(undefined),
        subtype: new FormControl<string>(undefined),
        value: new FormControl<string>(undefined)
    });

    @HostBinding('@fadeOutCollapseOnLeave') get leave() {
        return true;
    }

    constructor(private exclusiveControlService: ExclusiveControlService) {}

    ngOnInit() {
        this.setupInput();
        this.exclusiveControlService.lock(() => true);
    }

    setupInput() {
        if (this.editSubtype) {
            if (this.subtypeLabel == null) {
                this.subtypeLabel = 'Label';
            }
            if (this.requireSubtype) {
                this.inputForm.controls.subtype.setValidators([
                    Validators.required
                ]);
            }
        }
        if (this.editName) {
            if (this.nameLabel == null) {
                this.nameLabel = 'Name';
            }
            if (this.requireName) {
                this.inputForm.controls.name.setValidators([
                    Validators.required
                ]);
            }
        }
        if (this.editValue) {
            if (this.valueLabel == null) {
                this.valueLabel = 'Value';
            }
            if (this.requireValue) {
                this.inputForm.controls.value.setValidators([
                    Validators.required
                ]);
            }
        }
    }

    addItem() {
        this.add.emit({
            ...(<ContactModel>this.inputForm.value),
            type: ContactDto.Type.CUSTOM
        });
        this.exclusiveControlService.requestUnlock();
        this.inputForm.reset();
    }

    cancelAdd() {
        this.cancel.emit();
        this.exclusiveControlService.requestUnlock();
        this.inputForm.reset();
    }
}
