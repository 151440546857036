import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ActiveGridCellDto,
    AirspaceAuthorisationDto,
    AirspaceAuthorisationResponse,
    AirspaceCheckResponse,
    DisplayableMissionDto,
    OperationAuthorisation,
    Organisation,
    PersonDto,
    PersonRolesDto,
    PersonService,
    RpaTypeDto,
    RpaTypesService,
    RuleOutcome,
    SimpleAuthorityDto,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import {
    BehaviorSubject,
    combineLatest,
    startWith,
    Subject,
    takeUntil
} from 'rxjs';
import { AirspaceAuthorisationManager } from '../airspace-authorisation-manager.service';
import { CasaFlightProfiles, CraftWithError } from '../interfaces';
import {
    combineAuthorisationErrors,
    findPilotValues,
    findTimeValues
} from './helpers';
import {
    acknowledgementValidator,
    activeGridCellListValidator,
    airspaceValidator,
    civilTwilightValidator,
    delegatedAuthorityIdentifierValidator,
    endTimeValidator,
    operationAuthorisationsErrorsValidator,
    plannedMaxHeightValidator,
    responseErrorsValidator,
    rpaErrorsValidator,
    rpaListLengthValidator,
    rpicValidator,
    startTimeValidator
} from './validators';

// specialValidationKeys is a list of validation keys with special handlers that shouldn't be
// caught by backup validation
export const specialValidationKeys = ['remotePilotPhoneNumber'];

export const delegatedAuthorityLabel = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_ASA_FIMS]: 'ReOC',
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]: 'ReOC',
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]:
        'Delegated Authority',
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]:
        'Delegated Authority'
};

export const justificationLabel = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_ASA_FIMS]: 'Justification',
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]:
        'Justification',
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]: 'Justification',
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: 'Safety Case'
};

// TODO: this can be API driven, or at least feed from sysadmin
const declarationText = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_ASA_FIMS]: [],
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]: [],
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]: [],
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: [
        'I am authorised to make this application and hold the role of Chief Remote Pilot for the ReOC.',
        'I acknowledge I must not submit an airspace authorisation application for an RPA with a maximum take-off weight of 25kg or more.',
        'I declare that all statements in this application are true and correct in every particular and that I have read and understood all provisions of the Civil Aviation Safety Regulations 1998 which are relevant to this application.'
    ]
};

/**
 * A list of which form fields should be visible for each authorisation type.
 */
export const visibleAuthorisationFieldsByType = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]: [
        'activeGridCellList',
        'authorisationMaxHeight',
        'plannedMaxHeight',
        'flightProfile',
        'civilTwilightStart',
        'civilTwilightEnd',
        'startTime',
        'endTime',
        'endRangeString',
        'rpic',
        'rpicAuthLabel',
        'rpicArn',
        'rpicPhone',
        'crp',
        'crpAuthLabel',
        'crpArn',
        'delegatedAuthority',
        'airspace'
    ],
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]: [
        'plannedMaxHeight',
        'publicMissionName',
        'startTime',
        'endTime',
        'endRangeString',
        'rpic',
        'rpicPhone',
        'additionalFields',
        'rpa',
        'delegatedAuthority'
    ],
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: [
        'authorisationList',
        'plannedMaxHeight',
        'startTime',
        'endTime',
        'endRangeString',
        'rpic',
        'rpicPhone',
        'rpa',
        'delegatedAuthority',
        'safetyJustification',
        'warningsAndBlocks'
    ]
};

/**
 * A list of which form fields are required to have a valid value for each jurisdiction type.
 */
const requiredAuthorisationFieldsByType = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]: [
        'plannedMaxHeight',
        'flightProfile',
        'startTime',
        'endTime',
        'rpic',
        'rpicArn',
        'rpicPhone',
        'crp',
        'crpArn',
        'delegatedAuthority',
        'airspace'
    ],
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]: [
        'plannedMaxHeight',
        'publicMissionName',
        'rpic',
        'rpicPhone',
        'rpicArn',
        'rpicAuthLabel',
        'delegatedAuthority',
        'startTime',
        'endTime'
    ],
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: [
        'startTime',
        'endTime',
        'rpic',
        'rpicPhone',
        // 'rpicAuthLabel',
        // 'rpicArn',
        'rpa',
        'delegatedAuthority'
        // 'declarations'
    ]
};

export const authorisationTypeCanEditMissionInPreview = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_ASA_FIMS]: false,
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]: true,
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]: false,
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: false
};

// FIXME: Find a  way to set these validators dynamically based on the jurisdiction type
// using a single form group instead of swapping out multiple forms
/**
 * A list of the validators to apply to each authorisation form field on top of it being required.
 * These validators can then be combined with the required validator or used by themselves based on the
 * jurisdiction requirements
 */
const fieldValidators = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]: {
        delegatedAuthority: delegatedAuthorityIdentifierValidator,
        rpaList: rpaListLengthValidator
    },
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]: {
        rpaList: rpaListLengthValidator
    },
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: {
        delegatedAuthority: delegatedAuthorityIdentifierValidator,
        rpaList: rpaListLengthValidator
    }
};
const asyncFieldValidators = {
    [AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION]: {
        activeGridCellList: activeGridCellListValidator,
        plannedMaxHeight: plannedMaxHeightValidator,
        civilTwilight: civilTwilightValidator,
        startTime: startTimeValidator,
        endTime: endTimeValidator,
        rpic: rpicValidator,
        airspace: airspaceValidator
    },
    [AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE]: {
        startTime: startTimeValidator,
        endTime: endTimeValidator,
        rpic: rpicValidator
    },
    [AirspaceAuthorisationDto.AuthorisationType.USA_LAANC]: {
        startTime: startTimeValidator,
        endTime: endTimeValidator,
        rpic: rpicValidator
    }
};

export const airspaceAuthorisationFormErrors = {
    activeGridCellList: 'no valid active grid cell list',
    plannedMaxHeight: 'mission needs a planned maximum height',
    flightProfile: 'invalid or missing flight profile',
    rpaList: 'at least one valid RPA is required',

    rpic: 'invalid pilot details',
    rpicPhone: 'pilot phone number is invalid or missing',
    rpicArn: 'invalid or missing pilot licence number',
    rpicAuthLabel: 'Invalid or missing pilot licence authority',
    crp: 'the organisation requires at least one person with the CRP role',
    crpArn: 'invalid or missing CRP licence number',
    crpAuthLabel: 'invalid or missing CRP licence authority',

    delegatedAuthority:
        'the selected workflow has no valid delegated authority',

    airspaceValidation:
        'the airspace is not good to fly for this authorisation type',

    startTime: 'the mission start time is invalid',
    endTime: 'the mission end time is invalid',
    warningsAndBlocks:
        'there are blockers or unaccepted warnings for this request',
    safetyJustification: 'a safety case is required for this request'
};

export interface AirspaceWarningsAndBlocksMessages {
    status: RuleOutcome.Outcome;
    name: string;
    message: string;
    acknowledged: boolean;
    relatedMissionIdList: number[];
}

export interface AirspaceAuthorisationDeclaration {
    acknowledged: boolean;
    text: string;
}

export interface AirspaceWarningsAndBlocksMessagesForm {
    status: FormControl<RuleOutcome.Outcome>;
    name: FormControl<string>;
    message: FormControl<string>;
    acknowledged: FormControl<boolean>;
    relatedMissionIdList: FormControl<number[]>;
}

export interface AirspaceAuthorisationDeclarationForm {
    acknowledged: FormControl<boolean>;
    text: FormControl<string>;
}

export interface AirspaceAuthorisationForm {
    authorisationList: FormControl<OperationAuthorisation[]>;
    publicMissionName: FormControl<string>;
    activeGridCellList: FormControl<ActiveGridCellDto[]>;
    plannedMaxHeight: FormControl<number>;
    // approvedMaxHeight: number; - Not sure if needed
    authorisationMaxHeight: FormControl<number>;
    flightProfile: FormControl<CasaFlightProfiles>;
    rpaList: FormControl<CraftWithError[]>;

    rpic: FormControl<PersonDto>;
    rpicPhone: FormControl<string>;
    rpicArn: FormControl<string>;
    rpicAuthLabel: FormControl<string>;
    crp: FormControl<PersonDto>;
    crpArn: FormControl<string>;
    crpAuthLabel: FormControl<string>;

    delegatedAuthority: FormControl<SimpleAuthorityDto>;

    airspaceValidation: FormControl<string>;

    startTime: FormControl<string>;
    // The validator controls are for async validation and serve the times as ISO strings
    validatorStartTime: FormControl<string>;
    endTime: FormControl<string>;
    validatorEndTime: FormControl<string>;
    startRangeString: FormControl<string>;
    endRangeString: FormControl<string>;
    timeZone: FormControl<string>;
    civilTwilightStart: FormControl<string>;
    validatorCivilTwilightStart: FormControl<string>;
    civilTwilightEnd: FormControl<string>;
    validatorCivilTwilightEnd: FormControl<string>;
    civilTwilight: FormControl<string>;

    authorisationErrors: FormControl<any>;

    // A collection of all airspace check advises and blocks
    // The object keys are the rule codes (RuleOutcome.code)
    warningsAndBlocks: FormArray<
        FormGroup<AirspaceWarningsAndBlocksMessagesForm>
    >;
    declarations: FormArray<FormGroup<AirspaceAuthorisationDeclarationForm>>;
    // A field to track if the authorisation preview still has any errors
    // This will primarily be used to lock the submit button and determine how to display the errors
    hasRemainingValidationErrors: FormControl<boolean>;
    safetyJustification: FormControl<string>;
}

export interface AirspaceAuthorisationFormValue {
    authorisationList: OperationAuthorisation[];
    publicMissionName: string;
    activeGridCellList: ActiveGridCellDto[];
    plannedMaxHeight: number;
    // approvedMaxHeight: number; - Not sure if needed
    authorisationMaxHeight: number;
    flightProfile: CasaFlightProfiles;
    rpaList: CraftWithError[];

    rpic: PersonDto;
    rpicPhone: string;
    rpicArn: string;
    rpicAuthLabel: string;
    crp: PersonDto;
    crpArn: string;
    crpAuthLabel: string;

    delegatedAuthority: SimpleAuthorityDto;

    airspaceValidation: string;

    startTime: string;
    // The validator controls are for async validation and serve the times as ISO strings
    validatorStartTime: string;
    endTime: string;
    validatorEndTime: string;
    startRangeString: string;
    endRangeString: string;
    timeZone: string;
    civilTwilightStart: string;
    validatorCivilTwilightStart: string;
    civilTwilightEnd: string;
    validatorCivilTwilightEnd: string;
    civilTwilight: string;

    authorisationErrors: any;

    // A collection of all airspace check advises and blocks
    // The object keys are the rule codes (RuleOutcome.code)
    warningsAndBlocks: AirspaceWarningsAndBlocksMessages[];
    declarations: AirspaceAuthorisationDeclaration[];
    // A field to track if the authorisation preview still has any errors
    // This will primarily be used to lock the submit button and determine how to display the errors
    hasRemainingValidationErrors: boolean;
    safetyJustification: string;
}

@Injectable()
export class AirspaceAuthorisationPreviewService {
    authorisationForm: FormGroup<AirspaceAuthorisationForm>;

    // FIXME: this is a temporary solution for the validation problem. There should only be one form with dynamic validation.
    casaAuthorisationForm: FormGroup<AirspaceAuthorisationForm>;
    airshareAuthorisationForm: FormGroup<AirspaceAuthorisationForm>;
    laancAuthorisationForm: FormGroup<AirspaceAuthorisationForm>;

    justificationRequired = false;

    private organisation$ = new BehaviorSubject<Organisation>(null);
    private mission$ = new BehaviorSubject<DisplayableMissionDto>(null);
    private rpaTypes$ = new BehaviorSubject<RpaTypeDto[]>(null);
    private airspaceCheckResponse$ = new BehaviorSubject<AirspaceCheckResponse>(
        null
    );
    private people$ = new BehaviorSubject<PersonRolesDto[]>(null);

    private currentAuthorisationTypeSource =
        new BehaviorSubject<AirspaceAuthorisationDto.AuthorisationType>(null);
    currentAuthorisationType$ =
        this.currentAuthorisationTypeSource.asObservable();

    private isPreviewSource = new BehaviorSubject<boolean>(false);
    isPreview$ = this.isPreviewSource.asObservable();

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        private personService: PersonService,
        private userService: UserService,
        private rpaTypesService: RpaTypesService
    ) {
        this.airspaceAuthorisationManager.currentMission$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mission => {
                this.mission$.next(mission);
            });

        combineLatest([
            this.airspaceAuthorisationManager.airspaceCheckResult$,
            this.airspaceAuthorisationManager.availableAirspaceAuthorisations$,
            this.airspaceAuthorisationManager.airspaceAuthorisationPreview$.pipe(
                startWith(null)
            ),
            this.mission$,
            this.rpaTypes$,
            this.people$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    completeCheck,
                    availableAuthorisations,
                    airspaceAuthorisation,
                    mission,
                    rpaTypes,
                    people
                ]) => {
                    this.airspaceCheckResponse$.next(completeCheck.result);
                    this.currentAuthorisationTypeSource.next(
                        availableAuthorisations[0]
                    );
                    this.authorisationForm = null;
                    if (
                        availableAuthorisations[0] ===
                        AirspaceAuthorisationDto.AuthorisationType
                            .AUS_CASA_AUTHORISATION
                    ) {
                        this.authorisationForm = this.casaAuthorisationForm;
                    } else if (
                        availableAuthorisations[0] ===
                        AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE
                    ) {
                        this.authorisationForm = this.airshareAuthorisationForm;
                    } else if (
                        availableAuthorisations[0] ===
                        AirspaceAuthorisationDto.AuthorisationType.USA_LAANC
                    ) {
                        this.authorisationForm = this.laancAuthorisationForm;
                    }
                    if (
                        completeCheck &&
                        mission &&
                        rpaTypes &&
                        people &&
                        this.authorisationForm != null
                    ) {
                        this.setupFormValues(airspaceAuthorisation);
                    }
                }
            );

        // TODO: have one form per jurisdiction with validators pre-set and swap them out
        // I can't get the dynamic validators to work correctly if adding them to controls later,
        // so for now I'm just swapping out form groups based on the jurisdiction.

        this.casaAuthorisationForm = new FormGroup<AirspaceAuthorisationForm>({
            authorisationList: new FormControl(undefined),
            publicMissionName: new FormControl(undefined),
            activeGridCellList: new FormControl(
                undefined,
                [Validators.required],
                [activeGridCellListValidator()]
            ),
            authorisationMaxHeight: new FormControl(undefined),
            plannedMaxHeight: new FormControl(
                undefined,
                [Validators.required],
                [plannedMaxHeightValidator()]
            ),
            flightProfile: new FormControl(undefined, [Validators.required]),
            rpaList: new FormControl(
                undefined,
                Validators.compose([
                    Validators.required,
                    rpaListLengthValidator
                ]),
                [rpaErrorsValidator()]
            ),

            rpic: new FormControl(
                undefined,
                [Validators.required],
                [rpicValidator()]
            ),
            rpicPhone: new FormControl(undefined, [Validators.required]),
            rpicArn: new FormControl(undefined, [Validators.required]),
            rpicAuthLabel: new FormControl(undefined),
            crp: new FormControl(undefined, [Validators.required]),
            crpArn: new FormControl(undefined, [Validators.required]),
            crpAuthLabel: new FormControl(undefined),

            delegatedAuthority: new FormControl(
                undefined,
                Validators.compose([
                    Validators.required,
                    delegatedAuthorityIdentifierValidator
                ])
            ),
            startTime: new FormControl(
                undefined,
                [Validators.required],
                [startTimeValidator()]
            ),
            validatorStartTime: new FormControl(undefined),
            endTime: new FormControl(
                undefined,
                [Validators.required],
                [endTimeValidator()]
            ),
            validatorEndTime: new FormControl(undefined),
            startRangeString: new FormControl(undefined),
            endRangeString: new FormControl(undefined),
            timeZone: new FormControl(undefined),
            civilTwilightStart: new FormControl(undefined),
            validatorCivilTwilightStart: new FormControl(undefined),
            civilTwilightEnd: new FormControl(undefined),
            validatorCivilTwilightEnd: new FormControl(undefined),
            civilTwilight: new FormControl(
                undefined,
                [],
                [civilTwilightValidator()]
            ),

            airspaceValidation: new FormControl(
                undefined,
                [Validators.required],
                [airspaceValidator()]
            ),

            authorisationErrors: new FormControl(undefined),
            warningsAndBlocks: new FormArray([]),
            declarations: new FormArray([]),
            safetyJustification: new FormControl(undefined),
            hasRemainingValidationErrors: new FormControl(undefined)
        });
        this.airshareAuthorisationForm =
            new FormGroup<AirspaceAuthorisationForm>({
                authorisationList: new FormControl(undefined),
                hasRemainingValidationErrors: new FormControl(undefined),
                publicMissionName: new FormControl(undefined, [
                    Validators.required
                ]),
                activeGridCellList: new FormControl(undefined),
                authorisationMaxHeight: new FormControl(undefined),
                plannedMaxHeight: new FormControl(
                    undefined,
                    Validators.required
                ),
                flightProfile: new FormControl(undefined),
                rpaList: new FormControl(
                    undefined,
                    Validators.compose([
                        Validators.required,
                        rpaListLengthValidator
                    ]),
                    [responseErrorsValidator('rpaList')]
                ),

                rpic: new FormControl(
                    undefined,
                    [Validators.required],
                    [
                        responseErrorsValidator('rpic'),
                        responseErrorsValidator('remotePilot')
                        /* rpicValidator() */
                    ]
                ),
                rpicPhone: new FormControl(
                    undefined,
                    [Validators.required],
                    responseErrorsValidator('remotePilotPhoneNumber')
                ),
                rpicArn: new FormControl(undefined),
                rpicAuthLabel: new FormControl(undefined),
                crp: new FormControl(undefined),
                crpArn: new FormControl(undefined),
                crpAuthLabel: new FormControl(undefined),

                delegatedAuthority: new FormControl(undefined, [
                    Validators.required
                ]),
                startTime: new FormControl(
                    undefined,
                    [Validators.required],
                    [startTimeValidator(), responseErrorsValidator('startTime')]
                ),
                validatorStartTime: new FormControl(undefined),
                endTime: new FormControl(
                    undefined,
                    [Validators.required],
                    [endTimeValidator(), responseErrorsValidator('endTime')]
                ),
                validatorEndTime: new FormControl(undefined),
                startRangeString: new FormControl(undefined),
                endRangeString: new FormControl(undefined),
                timeZone: new FormControl(undefined),
                civilTwilightStart: new FormControl(undefined),
                validatorCivilTwilightStart: new FormControl(undefined),
                civilTwilightEnd: new FormControl(undefined),
                validatorCivilTwilightEnd: new FormControl(undefined),
                civilTwilight: new FormControl(undefined),

                airspaceValidation: new FormControl(undefined),

                authorisationErrors: new FormControl(undefined),
                warningsAndBlocks: new FormArray([]),
                declarations: new FormArray([]),
                safetyJustification: new FormControl(undefined)
            });
        this.laancAuthorisationForm = new FormGroup<AirspaceAuthorisationForm>({
            authorisationList: new FormControl(
                undefined,
                operationAuthorisationsErrorsValidator()
            ),
            publicMissionName: new FormControl(undefined),
            activeGridCellList: new FormControl(undefined),
            authorisationMaxHeight: new FormControl(undefined),
            plannedMaxHeight: new FormControl(undefined),
            flightProfile: new FormControl(undefined),
            rpaList: new FormControl(
                undefined,
                /* Validators.compose([
                    Validators.required,
                    rpaListLengthValidator
                ]) */
                [Validators.required],
                [responseErrorsValidator('rpaList')]
            ),

            rpic: new FormControl(
                undefined,
                [Validators.required],
                [
                    responseErrorsValidator('rpic'),
                    responseErrorsValidator('remotePilot'),
                    responseErrorsValidator(
                        'remotePilotPhoneNumber',
                        'rpicPhone'
                    )
                    /* rpicValidator() */
                ]
            ),
            rpicPhone: new FormControl(
                undefined,
                [
                    /* Validators.required */
                ],
                [responseErrorsValidator('remotePilotPhoneNumber', 'rpicPhone')]
            ),
            rpicArn: new FormControl(undefined /* , [Validators.required] */),
            rpicAuthLabel: new FormControl(
                undefined /* , [Validators.required] */
            ),
            crp: new FormControl(
                undefined,
                [],
                [responseErrorsValidator('crp')]
            ),
            crpArn: new FormControl(undefined),
            crpAuthLabel: new FormControl(undefined),

            delegatedAuthority: new FormControl(
                undefined,
                [],
                responseErrorsValidator('delegatedAuthority')
            ),
            startTime: new FormControl(
                undefined,
                [Validators.required],
                [responseErrorsValidator('startTime')]
            ),
            validatorStartTime: new FormControl(undefined),
            endTime: new FormControl(
                undefined,
                [Validators.required],
                [responseErrorsValidator('endTime')]
            ),
            validatorEndTime: new FormControl(undefined),
            startRangeString: new FormControl(undefined),
            endRangeString: new FormControl(undefined),
            timeZone: new FormControl(undefined),
            civilTwilightStart: new FormControl(undefined),
            validatorCivilTwilightStart: new FormControl(undefined),
            civilTwilightEnd: new FormControl(undefined),
            validatorCivilTwilightEnd: new FormControl(undefined),
            civilTwilight: new FormControl(undefined),

            airspaceValidation: new FormControl(undefined),

            authorisationErrors: new FormControl(undefined),
            warningsAndBlocks: new FormArray([]),
            declarations: new FormArray([]),
            safetyJustification: new FormControl(undefined),
            hasRemainingValidationErrors: new FormControl(undefined)
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.currentAuthorisationTypeSource.complete();
        this.isPreviewSource.complete();
        this.airspaceCheckResponse$.complete();
        this.organisation$.complete();
        this.mission$.complete();
        this.rpaTypes$.complete();
        this.people$.complete();
    }

    setInputData(mission: DisplayableMissionDto, organisation: Organisation) {
        this.mission$.next(mission);
        this.organisation$.next(organisation);
        this.rpaTypesService
            .findRpaTypes(organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpaTypes => this.rpaTypes$.next(rpaTypes));
        this.personService
            .findPersonnel(organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(people => this.people$.next(people));
    }

    private setupFormValues(authorisation: AirspaceAuthorisationResponse) {
        this.casaAuthorisationForm.reset();
        this.airshareAuthorisationForm.reset();
        this.laancAuthorisationForm.reset();
        const authorisationType =
            this.currentAuthorisationTypeSource.getValue();
        const checkResponse = this.airspaceCheckResponse$.getValue();
        const mission = this.mission$.getValue();
        const people = this.people$.getValue();
        const currentUser = this.userService.getCurrentUser();
        const rpaTypes = this.rpaTypes$.getValue();

        if (authorisationType == null) {
            return;
        }
        // Get field values
        const {
            rpic,
            rpicPhone,
            rpicArn,
            rpicAuthLabel,
            crp,
            crpArn,
            crpAuthLabel
        } = findPilotValues(mission, people, currentUser);

        const {
            timeZone,
            startTime,
            validatorStart,
            endTime,
            validatorEnd,
            startRangeString,
            endRangeString,
            civilTwilightStart,
            validatorCivilTwilightStart,
            civilTwilightEnd,
            validatorCivilTwilightEnd
        } = findTimeValues(mission, checkResponse);

        const airspaceValidation = authorisation?.errors['airspace']
            ? null
            : 'Airspace clear to fly';

        const delegatedAuthority =
            mission?.missionWorkflowVersion.delegatedAuthority.authorityType
                .ruleset != null
                ? mission?.missionWorkflowVersion.delegatedAuthority
                : null;

        const rpaList = mission.crafts?.map((c, i) => ({
            ...c,
            missingMtow: false
        }));

        const mtowErrors = Object.keys(authorisation?.errors ?? {}).filter(k =>
            k.includes('rpaType.performanceSpecifications.maxTakeOffWeight')
        );
        if (mtowErrors != null && mtowErrors.length > 0) {
            const craftMissingMtowIds = mtowErrors.map(k =>
                parseInt(k.slice(k.indexOf('[') + 1, k.indexOf(']')))
            );
            craftMissingMtowIds.forEach(id => (rpaList[id].missingMtow = true));
        }

        // FIXME: this would add the validators dynamically based on the jurisdiction lists above,
        // meaning we'd be able to use only one form group

        // this.setupFormValidators();

        this.authorisationForm.patchValue({
            // For now all the authorisation errors are combined into one field to simplify validation
            // This could be broken out into separate checks for each authorisation in the list if the UI gets broken out that way.
            authorisationErrors: combineAuthorisationErrors(authorisation),
            authorisationList: authorisation?.authorisationList,

            // Mission operations & location details
            // FIXME need to restore this
            // activeGridCellList: authorisation?.activeGridCellList,
            // authorisationMaxHeight: authorisation?.maximumHeight,
            publicMissionName: mission?.name,
            // Mission operations & location details
            // FIXME: Ensure these values are correct for CASA and Airshare
            activeGridCellList:
                authorisation?.authorisationList[0]?.activeGridCellList,
            authorisationMaxHeight:
                authorisation?.authorisationList[0]?.maximumHeight,
            plannedMaxHeight: mission.maximumHeight,
            flightProfile: undefined,
            rpaList: rpaList.length > 0 ? rpaList : null,

            // Personnel & authorities
            rpicAuthLabel: rpicAuthLabel,
            rpicArn: rpicArn,
            rpicPhone: rpicPhone,
            rpic: rpic,
            crpAuthLabel: crpAuthLabel,
            crpArn: crpArn,
            crp: crp,
            delegatedAuthority: delegatedAuthority,

            // Time values
            validatorStartTime: validatorStart,
            validatorEndTime: validatorEnd,
            validatorCivilTwilightStart: validatorCivilTwilightStart,
            validatorCivilTwilightEnd: validatorCivilTwilightEnd,

            timeZone: timeZone,
            startTime: startTime,
            endTime: endTime,
            startRangeString: startRangeString,
            endRangeString: endRangeString,
            civilTwilightStart: civilTwilightStart,
            civilTwilightEnd: civilTwilightEnd,
            civilTwilight: `${civilTwilightStart} - ${civilTwilightEnd}`,

            airspaceValidation: airspaceValidation,
            safetyJustification: null,

            // hasRemainingValidationErrors doesn't have its own validator
            // The reason being that if this field is true, but the form is valid
            // there is an unmapped error somewhere and it will be shown in the preview footer and
            // the submit button will remain locked
            // allowedKeys is a list of validation keys that shouldn't flag since they are handled differently
            hasRemainingValidationErrors:
                authorisation?.errors != null &&
                Object.keys(authorisation.errors).filter(
                    key => !specialValidationKeys.includes(key)
                ).length > 0
        });

        if (
            this.currentAuthorisationTypeSource.getValue() ===
            AirspaceAuthorisationDto.AuthorisationType.USA_LAANC
        ) {
            this.setupWarningsAndBlocks(authorisation);
            this.setupJustificationRequirements(authorisation);
            // this.setupDeclarationText();
        }

        this.authorisationForm.updateValueAndValidity();

        // this.casaAuthorisationForm.updateValueAndValidity();
        // this.airshareAuthorisationForm.updateValueAndValidity();
    }

    setupJustificationRequirements(
        authorisation: AirspaceAuthorisationResponse
    ) {
        if (authorisation == null) {
            return;
        }
        if (
            authorisation.authorisationList?.length > 0 &&
            authorisation.authorisationList.filter(
                a =>
                    a.authorisationEligibility ===
                    OperationAuthorisation.AuthorisationEligibility.MANUAL
            ).length > 0
        ) {
            this.authorisationForm.controls.safetyJustification.setValidators(
                Validators.required
            );
            this.justificationRequired = true;
        } else {
            this.authorisationForm.controls.safetyJustification.clearValidators();
            this.justificationRequired = false;
        }
        this.authorisationForm.controls.safetyJustification.updateValueAndValidity();
    }

    setupWarningsAndBlocks(authorisation: AirspaceAuthorisationResponse) {
        // first clear out all existing form array data
        while (
            this.authorisationForm.controls.warningsAndBlocks.value.length > 0
        ) {
            this.authorisationForm.controls.warningsAndBlocks.removeAt(0);
        }

        if (authorisation == null) {
            return;
        }

        // create new warnings and blocks
        const checkResponse = this.airspaceCheckResponse$.getValue();
        const authorisationRuleOutcomes =
            authorisation.authorisationRuleOutcomeList;
        if (
            checkResponse == null ||
            (checkResponse.ruleOutcomes == null &&
                authorisationRuleOutcomes == null)
        ) {
            return;
        }
        const authorisationWarnings = authorisationRuleOutcomes.filter(
            r => r.outcome === RuleOutcome.Outcome.ADVISE
        );
        const checkerWarnings = checkResponse?.ruleOutcomes?.filter(
            r => r.outcome === RuleOutcome.Outcome.ADVISE
        );
        const authorisationBlocks = authorisationRuleOutcomes.filter(
            r => r.outcome === RuleOutcome.Outcome.BLOCK
        );
        const checkerBlocks = checkResponse?.ruleOutcomes?.filter(
            r => r.outcome === RuleOutcome.Outcome.BLOCK
        );
        const warningsAndBlocksMessages: AirspaceWarningsAndBlocksMessages[] =
            [];
        authorisationBlocks.forEach(block =>
            warningsAndBlocksMessages.push({
                acknowledged: false,
                status: block.outcome,
                name: block.title,
                message: block.message,
                relatedMissionIdList: block.relatedMissionIdList
            })
        );
        checkerBlocks.forEach(block =>
            warningsAndBlocksMessages.push({
                acknowledged: false,
                status: block.outcome,
                name: checkResponse.ruleMessages[block.code].name,
                message: checkResponse.ruleMessages[block.code].blockMessage,
                relatedMissionIdList: null
            })
        );
        authorisationWarnings.forEach(warning =>
            warningsAndBlocksMessages.push({
                acknowledged: false,
                status: warning.outcome,
                name: warning.title,
                message: warning.message,
                relatedMissionIdList: warning.relatedMissionIdList
            })
        );
        checkerWarnings.forEach(warning =>
            warningsAndBlocksMessages.push({
                acknowledged: false,
                status: warning.outcome,
                name: checkResponse.ruleMessages[warning.code].name,
                message: checkResponse.ruleMessages[warning.code].adviseMessage,
                relatedMissionIdList: null
            })
        );

        // Build the form array
        warningsAndBlocksMessages.forEach(message =>
            this.authorisationForm.controls.warningsAndBlocks.push(
                new FormGroup<AirspaceWarningsAndBlocksMessagesForm>({
                    acknowledged: new FormControl(message.acknowledged, [
                        acknowledgementValidator()
                    ]),
                    status: new FormControl(message.status),
                    name: new FormControl(message.name),
                    message: new FormControl(message.message),
                    relatedMissionIdList: new FormControl(
                        message.relatedMissionIdList
                    )
                })
            )
        );
    }

    setupDeclarationText() {
        // first clear out all existing form array data
        while (this.authorisationForm.controls.declarations.value.length > 0) {
            this.authorisationForm.controls.declarations.removeAt(0);
        }

        // create new warnings and blocks
        const declarations =
            declarationText[this.currentAuthorisationTypeSource.getValue()];
        if (declarations == null || declarations.length === 0) {
            return;
        }

        // Build the form array
        declarations.forEach(declaration =>
            this.authorisationForm.controls.declarations.push(
                new FormGroup<AirspaceAuthorisationDeclarationForm>({
                    acknowledged: new FormControl(false, [
                        acknowledgementValidator()
                    ]),
                    text: new FormControl(declaration)
                })
            )
        );
    }

    private setupFormValidators() {
        const authorisationType =
            this.currentAuthorisationTypeSource.getValue();
        const requiredFields =
            requiredAuthorisationFieldsByType[authorisationType];
        const jurisdictionValidators = fieldValidators[authorisationType];
        const jurisdictionAsyncValidators =
            asyncFieldValidators[authorisationType];

        Object.keys(this.authorisationForm.value).forEach(key => {
            const control = this.authorisationForm.controls[key];
            const isRequired = requiredFields.includes(key);

            if (jurisdictionValidators[key] != null) {
                switch (key) {
                    case 'delegatedAuthority':
                        isRequired
                            ? control.setValidators([
                                  delegatedAuthorityIdentifierValidator(),
                                  Validators.required
                              ])
                            : control.setValidators([
                                  delegatedAuthorityIdentifierValidator()
                              ]);
                        break;
                    case 'rpaList':
                        isRequired
                            ? control.setValidators([
                                  rpaListLengthValidator(),
                                  Validators.required
                              ])
                            : control.setValidators([rpaListLengthValidator()]);
                        break;

                    default:
                        break;
                }
            } else if (isRequired) {
                control.setValidators([Validators.required]);
            }

            if (jurisdictionAsyncValidators[key] != null) {
                switch (key) {
                    case 'activeGridCellList':
                        control.setAsyncValidators([
                            activeGridCellListValidator()
                        ]);
                        break;
                    case 'plannedMaxHeight':
                        control.setAsyncValidators([
                            plannedMaxHeightValidator()
                        ]);
                        break;
                    case 'civilTwilight':
                        this.authorisationForm.controls.civilTwilight.setAsyncValidators(
                            [civilTwilightValidator()]
                        );
                        break;
                    case 'startTime':
                        control.setAsyncValidators([startTimeValidator()]);
                        break;
                    case 'endTime':
                        control.setAsyncValidators([endTimeValidator()]);
                        break;
                    case 'rpic':
                        control.setAsyncValidators([rpicValidator()]);
                        break;
                    case 'airspace':
                        control.setAsyncValidators([airspaceValidator()]);
                        break;

                    default:
                        break;
                }
            }
        });
    }
}
