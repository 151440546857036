import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    ChangeRpaTypeSchematicCommand,
    CreateRpaTypeCommand,
    RpaTypeDto,
    UpdateRpaTypeCommand
} from '../model/api';
import { NameValue } from '../model/ui';
import { AttachmentHandler } from './attachments';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class RpaTypesService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findAllRpaTypes() {
        return this.http.get<RpaTypeDto[]>(`${this.baseUrl}/webapi/rpaTypes`);
    }

    findRpaTypes(organisationId: number) {
        return this.http.get<RpaTypeDto[]>(`${this.baseUrl}/webapi/rpaTypes`, {
            params: httpParamSerializer({ organisationId })
        });
    }

    findRpaType(rpaTypeId: number) {
        return this.http.get<RpaTypeDto>(
            `${this.baseUrl}/webapi/rpaTypes/${rpaTypeId}`
        );
    }

    createRpaType(command: CreateRpaTypeCommand) {
        return this.http
            .post<RpaTypeDto>(`${this.baseUrl}/webapi/rpaTypes`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    updateRpaType(rpaTypeId: number, command: UpdateRpaTypeCommand) {
        return this.http
            .put<RpaTypeDto>(
                `${this.baseUrl}/webapi/rpaTypes/${rpaTypeId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    changeRpaTypeSchematic(
        rpaTypeId: number,
        command: ChangeRpaTypeSchematicCommand
    ) {
        return this.http
            .put<RpaTypeDto>(
                `${this.baseUrl}/webapi/rpaTypes/${rpaTypeId}/schematic`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    attachmentHandler(rpaTypeId: number, managingOrganisationId?: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/rpaTypes/${rpaTypeId}/manuals`,
            false,
            managingOrganisationId
        );
    }

    getSchematicAttachmentUrl(
        resourceSchematicId: number,
        attachmentId: number
    ) {
        if (resourceSchematicId == null || attachmentId == null) {
            return null;
        }
        return `${this.baseUrl}/webapi/resources/resourceSchematics/${resourceSchematicId}/attachments/${attachmentId}`;
    }

    getRpaCategories(): NameValue[] {
        return RPA_CATEGORIES;
    }

    getPropulsionSystems(): NameValue[] {
        return PROPULSION_SYSTEMS;
    }

    getManualPurposes() {
        return [{ value: 'MAINTENANCE', name: 'Maintenance' }];
    }
}

export const RPA_CATEGORIES = [
    {
        value: 'MULTIROTOR',
        name: 'Multi-rotor'
    },
    {
        value: 'HELICOPTOR',
        name: 'Helicoptor'
    },
    {
        value: 'AEROPLANE',
        name: 'Aeroplane'
    },
    {
        value: 'POWERED_LIFT',
        name: 'Powered Lift'
    },
    {
        value: 'AIRSHIP',
        name: 'Airship'
    },
    {
        value: 'OTHER',
        name: 'Other'
    }
] as NameValue[];

export const PROPULSION_SYSTEMS = [
    { name: 'Electric', value: 'ELECTRIC' },
    { name: 'Fuel', value: 'FUEL' },
    { name: 'Other', value: 'OTHER' }
] as NameValue[];

export function rpaTypeFields(disabled = true, systemAdmin = false) {
    return {
        fields: [
            {
                key: 'make',
                type: 'input',
                props: {
                    label: 'Make',
                    required: true,
                    disabled
                }
            },
            {
                key: 'model',
                type: 'input',
                props: {
                    label: 'Model',
                    required: true,
                    disabled
                }
            },
            {
                key: 'craftType',
                type: 'ng-select',
                props: {
                    label: 'RPA Type',
                    required: true,
                    valueProp: 'value',
                    labelProp: 'name',
                    options: RPA_CATEGORIES,
                    disabled
                }
            },
            {
                key: 'propulsionSystem',
                type: 'ng-select',
                props: {
                    label: 'Propulsion',
                    valueProp: 'value',
                    labelProp: 'name',
                    options: PROPULSION_SYSTEMS,
                    required: true,
                    disabled
                }
            },
            {
                key: 'makeIdentifier',
                type: 'input',
                props: {
                    label: 'Make Identifier',
                    description: 'For linking to external systems',
                    disabled: disabled || !systemAdmin
                }
            },
            {
                key: 'modelIdentifier',
                type: 'input',
                props: {
                    label: 'Model Identifier',
                    description: 'For linking to external systems',
                    disabled: disabled || !systemAdmin
                }
            }
        ] as FormlyFieldConfig[],

        physicalSpecificationFields: [
            {
                key: 'length',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Length (mm)',
                    disabled
                }
            },
            {
                key: 'height',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Height (mm)',
                    disabled
                }
            },
            {
                key: 'width',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Width (mm)',
                    disabled
                }
            },
            {
                key: 'diameter',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Diameter (mm)',
                    disabled
                }
            },
            {
                key: 'weight',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Dry Weight (g)',
                    description:
                        'Excluding batteries and removable payloads. Includes integrated payloads.',
                    disabled
                }
            },
            {
                key: 'wingspan',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Wingspan (mm)',
                    disabled
                }
            },
            {
                key: 'wingArea',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Wing Area (cm2)',
                    disabled
                }
            },
            {
                key: 'recoveryMethod',
                type: 'input',
                props: {
                    label: 'Recovery Method',
                    disabled
                }
            },
            {
                key: 'launchMethod',
                type: 'input',
                props: {
                    label: 'Launch Method',
                    disabled
                }
            }
        ] as FormlyFieldConfig[],

        performanceSpecificationFields: [
            {
                key: 'windResistance',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Maximum Operational Wind Speed (m/s)',
                    disabled
                }
            },
            {
                key: 'maxTakeOffWeight',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Maximum Take Off Weight (g)',
                    disabled
                }
            },
            {
                key: 'minTakeOffWeight',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Minimum Take Off Weight (g)',
                    disabled
                }
            },
            {
                key: 'cruisingSpeed',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Cruising Speed (m/s)',
                    disabled
                }
            },
            {
                key: 'maxSpeed',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Maximum Speed (m/s)',
                    disabled
                }
            },
            {
                key: 'endurance',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Endurance at Min TOW (mins)',
                    disabled
                }
            },
            {
                key: 'enduranceMaxTow',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Endurance at Max TOW (mins)',
                    disabled
                }
            },
            {
                key: 'maxAltitude',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Maximum Altitude (m)',
                    disabled
                }
            },
            {
                key: 'maxOperatingTemperature',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Maximum Operating Temperature (C)',
                    disabled
                }
            },
            {
                key: 'minOperatingTemperature',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Minimum Operating Temperature (C)',
                    disabled
                }
            },
            {
                key: 'maxRange',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Maximum Range (m)',
                    disabled
                }
            }
        ] as FormlyFieldConfig[],

        otherSpecificationFields: [
            {
                key: 'communicationsLink',
                type: 'input',
                props: {
                    label: 'Communications Link System',
                    disabled
                }
            },
            {
                key: 'removeControlFrequencies',
                type: 'input',
                props: {
                    label: 'RC Frequencies and Bandwidth',
                    disabled
                }
            },
            {
                key: 'telemetryFrequencies',
                type: 'input',
                props: {
                    label: 'Telemetry/Video Transmission Frequencies',
                    disabled
                }
            },
            {
                key: 'operatingConditions',
                type: 'input',
                props: {
                    label: 'Operating Condition Details',
                    disabled
                }
            },
            {
                key: 'navigationControlSystem',
                type: 'input',
                props: {
                    label: 'Navigation and Control Systems',
                    disabled
                }
            },
            {
                key: 'navigationMethod',
                type: 'input',
                props: {
                    label: 'Navigation method',
                    description: 'GPS, Autopilot, etc',
                    disabled
                }
            },
            {
                key: 'price',
                type: 'input',
                props: {
                    label: 'Price',
                    disabled
                }
            },
            {
                key: 'maximumControlLinkDistance',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Maximum control link distance (m)',
                    disabled
                }
            },
            {
                key: 'defaultMaximumVisualDistance',
                type: 'input',
                props: {
                    label: 'Default maximum visual distance (m)',
                    type: 'number',
                    disabled
                }
            }
        ] as FormlyFieldConfig[]
    };
}
