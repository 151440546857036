<div
    id="shortcutLinkContainer"
    *ngIf="shortcutLinksList?.length > 0 || canEdit"
    class="panel widget utilities"
>
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-org-links.png" />
        </div>
        <div class="panel-title">Organisation Links</div>

        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                *ngIf="canEdit"
                class="btn btn-inline"
                (click)="newShortLink()"
                tooltip="Add a new link"
                placement="left"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>

    <div class="panel-body">
        <div *ngFor="let shortcutLink of shortcutLinksList">
            <div
                *ngIf="shortcutLink.id == currentlyEditting"
                class="form-inline form-group"
            >
                <form [formGroup]="linkForm">
                    <div class="form-group">
                        <label>Name: </label>
                        <input
                            class="form-control"
                            type="text"
                            formControlName="name"
                        />
                    </div>
                    <div class="form-group left-buffer">
                        <label>Link: </label>
                        <input
                            class="form-control"
                            type="text"
                            formControlName="link"
                        />
                    </div>
                    <button
                        class="btn btn-sm btn-primary"
                        type="button"
                        (click)="saveShortCutLink()"
                        [disabled]="working || linkForm.invalid"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn-sm btn-default"
                        type="button"
                        (click)="cancel()"
                    >
                        Cancel
                    </button>
                </form>
            </div>
            <div *ngIf="shortcutLink.id != currentlyEditting">
                <span
                    ><a
                        *ngIf="shortcutLink.link?.length > 0"
                        href="{{ shortcutLink.link }}"
                        target="_blank"
                        >{{ shortcutLink.title || '(No name)' }}</a
                    ></span
                >
                <button
                    *ngIf="canEdit"
                    class="btn btn-tertiary btn-xs"
                    (click)="editLink(shortcutLink)"
                    tooltip="Edit this link"
                    placement="bottom"
                >
                    <span class="fal fa-pencil-alt"></span>
                </button>
                <button
                    *ngIf="canEdit"
                    class="btn btn-tertiary btn-delete btn-xs"
                    (click)="deleteLink(shortcutLink)"
                    tooltip="Delete this link"
                    placement="bottom"
                >
                    <span class="fal fa-trash-alt"></span>
                </button>
            </div>
        </div>
        <div *ngIf="isNew" class="form-inline form-group">
            <form [formGroup]="linkForm">
                <div class="form-group">
                    <label>Name: </label>
                    <input
                        class="form-control"
                        type="text"
                        formControlName="name"
                    />
                </div>

                <div class="form-group left-buffer">
                    <label>Link: </label>
                    <input
                        class="form-control"
                        type="text"
                        formControlName="link"
                    />
                </div>
                <button
                    class="btn btn-sm btn-primary"
                    type="button"
                    (click)="saveShortCutLink()"
                    [disabled]="working || linkForm.invalid"
                >
                    Save
                </button>
                <button
                    class="btn btn-sm btn-default"
                    type="button"
                    (click)="cancel()"
                >
                    Cancel
                </button>
            </form>
        </div>
        <empty
            *ngIf="
                shortcutLinksList && shortcutLinksList.length === 0 && canEdit
            "
            componentName="organisation-links-empty-editable"
        >
        </empty>
        <empty
            *ngIf="
                shortcutLinksList && shortcutLinksList.length === 0 && !canEdit
            "
            componentName="organisation-links-empty"
        >
        </empty>
    </div>
</div>
