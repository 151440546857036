import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    BatteryDto,
    BatteryService,
    BatterySetDto,
    BatterySetService,
    CreateBatterySetCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    toLocalDate,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BatteryDialoguesService } from '../battery-dialogues.service';

@Component({
    selector: 'battery-details',
    templateUrl: './battery-details.component.html'
})
export class BatteryDetails implements OnInit, OnDestroy {
    @Input() organisation: PersonsOrganisationDto;
    @Input() battery: BatteryDto;
    @Output() edit = new EventEmitter<void>();
    @Output() deleted = new EventEmitter<void>();

    hasEdit = false;
    hasDelete = false;
    canEdit: boolean;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    hasBatterySet: boolean = null;

    canUseNfc: boolean = false;

    canDelete: boolean;

    batterySets: BatterySetDto[];

    constructor(
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private commonDialoguesService: CommonDialoguesService,
        private batteryDialoguesService: BatteryDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.batteryService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshBattery());

        this.batterySetService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshBattery());
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('battery' in changes) {
            this.refreshPermissions();
            this.refreshBattery();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        if (this.battery == null) {
            this.canEdit = false;
            this.canDelete = false;
            return;
        }
        this.canEdit = this.battery.availableActions.canEdit;
        this.canDelete = this.battery.availableActions.canDelete;
        this.hasEdit = this.battery.availableActions.hasEdit;
        this.hasDelete = this.battery.availableActions.hasDelete;
    }

    private refreshBattery() {
        forkJoin([
            this.batteryService.findBattery(this.battery.id),
            this.batterySetService.findBatterySets(
                this.organisation.id,
                null,
                this.battery.id
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([battery, batterySets]) => {
                this.battery = battery;
                if (batterySets.length) {
                    this.batterySets = batterySets;
                    this.hasBatterySet = true;
                } else {
                    this.hasBatterySet = false;
                }
            })
            .add(this.workTracker.createTracker());
    }

    batterySetBatteriesCount(batterySet: BatterySetDto, i: number) {
        const comma =
            i === this.batterySets.length - 1
                ? ''
                : i === this.batterySets.length - 2
                ? ' & '
                : ', ';

        if (batterySet.batteries.length > 2) {
            return ` with ${(
                batterySet.batteries.length - 1
            ).toString()} other batteries${comma}`;
        }
        if (batterySet.batteries.length === 2) {
            return ` with one other battery${comma}`;
        }
        return comma;
    }

    showBatterySet(batterySet: BatterySetDto) {
        this.batteryDialoguesService.showBatterySetDetailsDialogue(
            batterySet,
            this.organisation
        );
    }

    canCreateBatterySet() {
        return this.battery.status === BatteryDto.Status.SERVICEABLE;
    }

    createBatterySet() {
        const command: CreateBatterySetCommand = {
            assemblyDate: this.battery.purchaseDate,
            batteryIds: [this.battery.id],
            name: this.battery.name,
            organisationId: this.organisation.id
        };
        const doneWorking = this.workTracker.createTracker();
        this.batterySetService.createBatterySet(command).then(
            () => {
                this.refreshBattery();
                this.logging.success(
                    `Successfully created battery set for ${this.battery.name}`
                );
                doneWorking();
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error creating battery set: ${error.message}`
                );
                doneWorking();
            }
        );
    }

    canRetiredBattery() {
        return (
            this.battery.status === 'UNSERVICEABLE' ||
            this.battery.status === 'SERVICEABLE' ||
            this.battery.status === 'UNDER_MAINTENANCE'
        );
    }

    retireBattery() {
        const doneWorking = this.workTracker.createTracker();
        this.batteryService
            .retireBattery(this.battery.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                battery => {
                    this.battery = battery;
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while marking battery retired: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    canMarkUnderMaintenance() {
        return (
            this.battery.status === 'UNSERVICEABLE' ||
            this.battery.status === 'RETIRED' ||
            this.battery.status === 'SERVICEABLE'
        );
    }

    markBatteryUnderMaintenance() {
        const doneWorking = this.workTracker.createTracker();
        this.batteryService
            .markBatteryUnderMaintenance(this.battery.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                battery => {
                    this.battery = battery;
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while marking battery under maintenance: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    canMarkInTest() {
        return (
            this.battery.status === 'UNSERVICEABLE' ||
            this.battery.status === 'UNDER_MAINTENANCE'
        );
    }

    canDisposeBattery() {
        return this.battery.status !== 'DISPOSED';
    }

    markBatteryInTest() {
        const doneWorking = this.workTracker.createTracker();
        this.batteryService
            .markBatteryInTest(this.battery.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                battery => {
                    this.battery = battery;
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while marking battery serviceable: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    disposeBattery() {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showFormlyDialogue(
                'Dispose of Battery',
                'Dispose',
                true,
                true,
                [
                    {
                        key: 'disposalDate',
                        type: 'date',
                        props: {
                            label: 'Disposal Date',
                            required: false
                        }
                    }
                ],
                { disposalDate: null as Date },
                data =>
                    this.batteryService
                        .disposeBattery(this.battery.id, {
                            disposalDate: toLocalDate(data.disposalDate)
                        })
                        .toPromise()
            )
            .then(battery => {
                this.logging.success(`Battery has been marked as disposed`);
                this.battery = battery;
                doneWorking();
            })
            .catch(() => doneWorking());
    }

    editBattery() {
        this.edit.emit();
    }

    delete() {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Battery',
                'Are you sure you wish to delete this battery?',
                'Delete',
                () => this.batteryService.delete(this.battery.id).toPromise()
            )
            .then(battery => {
                this.logging.success(`Battery has been deleted`);
                this.deleted.next();
                doneWorking();
            })
            .catch(() => doneWorking());
    }

    get hasManageOptions() {
        return (
            this.canRetiredBattery() ||
            this.canMarkUnderMaintenance() ||
            this.canMarkInTest() ||
            this.canDisposeBattery()
        );
    }
}
