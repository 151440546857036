import { SubscriptionPlanSelection } from '../purchase-subscription/purchase-subscription.service';
import { Observable } from 'rxjs';
import { ChargesDto } from '@flyfreely-portal-ui/flyfreely';

/**
 * This service interface provides methods for previewing charges and purchasing
 * a specified plan. It does not hold state.
 */
export abstract class PlanSelectionService {
    readonly purchaseSuccess$: Observable<void>;

    abstract previewPurchaseSubscription(
        plan: SubscriptionPlanSelection,
        licenceCount: number
    ): Observable<ChargesDto>;

    abstract purchaseSubscription(
        subscriptionPlanIdentifier: string,
        licenceCount: number,
        upgradeSubscription?: boolean
    ): Observable<void>;
}
