import { Component, OnInit } from '@angular/core';
import {
    OnboardingData,
    OnboardingScreens,
    OnboardingTab
} from './onboarding-screens-data.service';
import { PersonalDetailsDataService } from './personal-details-data.service';

@Component({
    selector: 'onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    providers: [PersonalDetailsDataService]
})
export class Onboarding implements OnInit {
    title = 'flyfreely';
    activeFooter: OnboardingScreens;
    currentTab: OnboardingTab;

    constructor(
        private obds: OnboardingData,
        private personalDetailsDataService: PersonalDetailsDataService
    ) {}

    ngOnInit() {
        this.obds.currentScreenAndTab$.subscribe(currentValues => {
            this.activeFooter = currentValues.screen;
            this.currentTab = currentValues.tab;
        });
        this.personalDetailsDataService.findPersonalDetailsForUser();
    }
}
