import { Injectable } from '@angular/core';
import { PaymentErrorMessage } from './interfaces';
import {
    CardDeclinedException,
    InvalidOperation,
    FlyFreelyError,
    UnknownError
} from '@flyfreely-portal-ui/flyfreely';

export const paymentErrorCodes: PaymentErrorMessage = {
    card_declined: 'Your card has been declined',
    insufficient_funds: 'Insufficient funds.'
};

export const paymentIntentValues: PaymentErrorMessage = {
    requires_payment_method:
        'A valid payment method is required to complete the transaction.',
    requires_confirmation: 'Your payment is awaiting confirmation.',
    requires_action: 'Further action is required to complete this transaction.',
    processing: 'Processing payment.',
    requires_capture: 'Payment requires capture.',
    canceled: 'Payment cancelled.',
    succeeded: 'Payment Succeeded.'
};

@Injectable()
export class PaymentsService {
    parsePaymentError(
        error:
            | CardDeclinedException
            | InvalidOperation
            | FlyFreelyError
            | UnknownError
    ) {
        if (error instanceof UnknownError) {
            return `There was an error processing your payment: ${error.response.statusText}`;
        }
        if (error instanceof CardDeclinedException) {
            const baseCode = paymentErrorCodes[error.code];
            const additionalInformation =
                error.declineCode != null &&
                paymentErrorCodes[error.declineCode] != null
                    ? `: ${paymentErrorCodes[error.declineCode]}`
                    : '';
            return `${baseCode}${additionalInformation}`;
        } else if (error instanceof InvalidOperation && error.reason != null) {
            // handle other payment errors, or return a generic message. Details will be in the error.code
            return `There was an error processing your payment: ${
                paymentErrorCodes[error.code]
            }`;
        } else {
            return `There was an error processing your payment: ${error.message}`;
        }
    }
}
