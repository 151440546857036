import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    AcceptInviteCommand,
    InviteDto,
    InviteSummaryDto,
    SendInviteCommand
} from '../model/api';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class InvitesService {
    private baseUrl: string;

    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findInvites(organisationId: number) {
        return this.http.get<InviteDto[]>(`${this.baseUrl}/webapi/invites`, {
            params: new HttpParams().set(
                'organisationId',
                organisationId.toString()
            )
        });
    }

    findInvitesForPerson() {
        return this.http.get<InviteDto[]>(
            `${this.baseUrl}/webapi/invites/current`
        );
    }

    verifyInvite(inviteCode: string) {
        return this.http
            .get<InviteDto>(`${this.baseUrl}/webapi/invites/verify`, {
                params: httpParamSerializer({ inviteCode })
            })
            .pipe(tap(() => this.changeSource.next()));
    }

    linkInvite(inviteCode: string) {
        return this.http
            .put<InviteDto>(`${this.baseUrl}/webapi/invites/link`, null, {
                params: httpParamSerializer({ inviteCode })
            })
            .pipe(tap(() => this.changeSource.next()));
    }

    sendInvite(command: SendInviteCommand) {
        return this.http
            .post<InviteDto>(`${this.baseUrl}/webapi/invites`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    retractInvite(inviteId: number, invite?: InviteDto) {
        return this.http
            .put<InviteDto>(
                `${this.baseUrl}/webapi/invites/${inviteId}/retract`,
                invite
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    acceptInvite(inviteId: number, cmd: AcceptInviteCommand) {
        return this.http
            .put<void>(`${this.baseUrl}/webapi/invites/${inviteId}/accept`, cmd)
            .pipe(tap(() => this.changeSource.next()));
    }

    ignoreInvite(inviteId: number) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/invites/${inviteId}/ignore`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findInviteDetails(inviteCode: string) {
        return this.http.get<InviteSummaryDto>(
            `${this.baseUrl}/publicapi/invites`,
            { params: httpParamSerializer({ inviteCode }) }
        );
    }
}
