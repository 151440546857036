import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    ApiKeyDto,
    CreateApiKeyCommand,
    CreateApiKeyResult
} from '../model/api';

export enum PermissionTypes {
    MISSIONS = 'MISSIONS',
    PERSONNEL = 'PERSONNEL',
    RESOURCES = 'RESOURCES',
    ORGANISATION = 'ORGANISATION',
    PROFILE = 'PROFILE',
    FLIGHT_LOGS = 'FLIGHT_LOGS'
}

@Injectable({
    providedIn: 'root'
})
export class ApiKeysService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findKeys() {
        return this.http.get<ApiKeyDto[]>(`${this.baseUrl}/webapi/apiKeys`);
    }

    createKey(command: CreateApiKeyCommand) {
        return this.http
            .post<CreateApiKeyResult>(`${this.baseUrl}/webapi/apiKeys`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    revokeKey(apiKeyId: string) {
        return this.http.put<ApiKeyDto>(
            `${this.baseUrl}/webapi/apiKeys/${apiKeyId}/revoke`,
            null
        );
    }
}
