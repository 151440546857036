import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Directive,
    HostBinding,
    Input,
    OnInit
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { AuthenticatedSrcService } from './authenticated-src.service';

/**
 * This directive is used to set an `<img>` `src` attribute so that it is fetched using the
 * authenticated channel. It is done as a directive so that we have a lifecycle we can use to
 * release the memory, as opposed to a pipe which could otherwise have been used.
 */
@Directive({
    selector: 'img[authenticatedSrc],svg-icon[authenticatedSrc]'
})
export class AuthenticatedSrcDirective implements OnInit {
    @Input()
    authenticatedSrc: string;
    @Input()
    defaultImage: string;

    @Input() cache: boolean = true;

    @Input()
    fallbackUrl: string;

    @HostBinding('src') imgSrc: SafeUrl;

    private resolvedSrc: string;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
        private changeDetector: ChangeDetectorRef,
        private authenticatedSrcService: AuthenticatedSrcService
    ) {}

    ngOnInit() {
        if (this.defaultImage) {
            this.imgSrc = this.defaultImage;
        }

        const imageUrl$ = this.cache
            ? this.authenticatedSrcService.getImageUrl(
                  this.authenticatedSrc,
                  this.fallbackUrl
              )
            : this.fetchImage(this.authenticatedSrc);

        imageUrl$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
            url => {
                this.imgSrc = url || this.fallbackUrl;
                this.changeDetector.markForCheck();
            },
            () => {
                this.imgSrc = this.fallbackUrl || this.defaultImage;
            }
        );
    }

    ngOnDestroy() {
        if (!this.cache && this.resolvedSrc != null) {
            URL.revokeObjectURL(this.resolvedSrc);
        }
    }

    private fetchImage(src: string): Observable<SafeUrl> {
        return this.httpClient.get(src, { responseType: 'blob' }).pipe(
            map(response => URL.createObjectURL(response)),
            map(url => this.domSanitizer.bypassSecurityTrustUrl(url)),
            catchError(() => of(this.fallbackUrl))
        );
    }
}
