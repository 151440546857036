import { forwardRef, Inject, Injectable } from '@angular/core';
import {
    ApproversMissionApprovalDto,
    calculateCombinedStatus,
    CombinedMissionStatus,
    DisplayableMissionDto,
    FlightConformanceResultDto,
    FlightConformanceResultsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionApprovalService,
    MissionDetailsDto,
    MissionService,
    Organisation,
    OrganisationService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { findActiveApprovals, findUnactionedApprovals } from '../helpers';
import { MissionDialoguesService } from '../mission-dialogues.service';

export interface StatusFields {
    class: string;
    label: string;
    text: string;
    buttonText?: string;
    buttonTooltip?: string;
    buttonDisabled: boolean;
    hasDate: boolean;
    hasButton: boolean;
    fieldApp: boolean;
    officeApp: boolean;
    draft: boolean;
    submission: boolean;
    approval: boolean;
    multipleApprovals: boolean;
    launch: boolean;
    operations: boolean;
    documentation: boolean;
    reconciliation: boolean;
    finalRequest: boolean;
    finalised: boolean;
}

const ribbonBooleanDefaults = {
    hasDate: false,
    hasButton: false,
    buttonDisabled: false,
    fieldApp: false,
    officeApp: false,
    draft: false,
    submission: false,
    approval: false,
    multipleApprovals: false,
    launch: false,
    operations: false,
    documentation: false,
    reconciliation: false,
    finalRequest: false,
    finalised: false
};

export const ribbonValues = {
    dummy: {
        class: 'mission-status-dummy',
        label: 'Dummy Mission',
        text: 'This is a dummy mission that will not be flown and will not affect data records.'
    },
    planning: {
        class: 'mission-status-planning',
        label: 'Mission Planning',
        text: 'This mission has been created and is awaiting submission.',
        ...ribbonBooleanDefaults
    },
    submitted: {
        class: 'mission-status-submitted',
        label: 'Mission Plan Submitted',
        text: 'This mission has been created and submitted and is now awaiting approval.',
        buttonText: 'Cancel Approval Submission',
        ...ribbonBooleanDefaults,
        hasButton: true
    },
    submittedMultiple: {
        class: 'mission-status-submitted',
        label: 'Mission Plan Submitted',
        text: 'This mission has been created and submitted and is now awaiting approval.',
        buttonText: 'Mission Approvals',
        ...ribbonBooleanDefaults,
        hasButton: true,
        multipleApprovals: true
    },
    approved: {
        class: 'mission-status-approved',
        label: 'Approved for Mission Launch',
        text: 'This mission has moved from the planning phase and is now awaiting to be executed in the ',
        ...ribbonBooleanDefaults,
        hasDate: true,
        fieldApp: true
    },
    onSite: {
        class: 'mission-status-approved',
        label: 'On Site',
        text: 'The mission crew is on site and the mission is in progress.',
        buttonText: 'Finish Field App Mission',
        ...ribbonBooleanDefaults,
        hasButton: true
    },
    flying: {
        class: 'mission-status-approved',
        label: 'Flying',
        text: 'The mission crew is on site and the mission is in progress.',
        buttonText: 'Finish Field App Mission',
        ...ribbonBooleanDefaults,
        hasButton: true
    },
    awaitingSync: {
        class: 'mission-status-approved',
        label: 'Awaiting Sync from the Field App',
        text: 'The mission is currently awaiting synchronisation from the Field App.',
        ...ribbonBooleanDefaults,
        hasButton: false
    },
    reconciliation: {
        class: 'mission-status-reconciliation',
        label: 'Mission Reconciliation',
        text: 'This mission has moved from the execution phase and is now awaiting reconciliation in the ',
        buttonText: 'Request Finalisation',
        ...ribbonBooleanDefaults,
        hasButton: true,
        officeApp: true
    },
    retrospective: {
        class: 'mission-status-reconciliation',
        label: 'Retrospective Mission',
        text: 'This retrospective mission can be edited and reconciled in the ',
        buttonText: 'Request Finalisation',
        ...ribbonBooleanDefaults,
        hasButton: true,
        officeApp: true
    },
    finalRequest: {
        class: 'mission-status-finalisation-request',
        label: 'Mission Finalisation Requested',
        text: 'This mission has been reconciled and is now awaiting finalisation in the ',
        buttonText: 'Cancel Finalisation Request',
        ...ribbonBooleanDefaults,
        hasButton: true,
        officeApp: true
    },
    finalised: {
        class: 'mission-status-finalised',
        label: 'Mission Finalised',
        text: 'This mission has been planned, executed, reconcilled and finalised.',
        ...ribbonBooleanDefaults
    },
    cancelled: {
        class: 'mission-status-cancelled',
        label: 'Mission Cancelled',
        text: 'This mission has been cancelled.',
        ...ribbonBooleanDefaults
    },
    aborted: {
        class: 'mission-status-aborted',
        label: 'Mission Aborted',
        text: 'This mission has been aborted.',
        ...ribbonBooleanDefaults
    },
    conformance: {
        class: 'mission-status-conformance',
        label: 'Conformance Alert',
        text: 'There is &&count&& incomplete conformance results.',
        ...ribbonBooleanDefaults
    }
};

export interface DataServiceError {
    component: 'ORGANISATION' | 'CONFORMANCE';
    fatal: boolean;
    message: string;
    error: FlyFreelyError;
}

@Injectable()
export class MissionDetailsService {
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    private flightConformanceDataSource = new ReplaySubject<
        FlightConformanceResultDto[]
    >(1);
    flightConformanceData$ = this.flightConformanceDataSource.asObservable();

    private organisationSource = new ReplaySubject<PersonsOrganisationDto>(1);
    organisation$ = this.organisationSource.asObservable();

    private errorSource = new ReplaySubject<DataServiceError>(1);
    error$ = this.errorSource.asObservable();

    private missionId: number;
    private organisationId: number;
    private allConformanceFinalised: boolean;

    constructor(
        private approvalService: MissionApprovalService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private missionService: MissionService,
        @Inject(forwardRef(() => MissionDialoguesService))
        private missionDialoguesService: MissionDialoguesService,
        private missionApprovalService: MissionApprovalService,
        private flightConformanceService: FlightConformanceResultsService,
        private organisationService: OrganisationService
    ) {
        flightConformanceService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshFlightConformance());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.changeSource.complete();
        this.organisationSource.complete();
        this.errorSource.complete();
    }

    setup(missionId: number, organisationId: number) {
        this.missionId = missionId;
        this.organisationId = organisationId;

        this.refreshOrganisation();
        this.refreshFlightConformance();
    }

    private refreshFlightConformance() {
        this.flightConformanceService
            .find(this.missionId, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => {
                const allConformanceFinalised = results.every(
                    r => r.status === 'CANCELLED' || r.status === 'FINALISED'
                );
                if (
                    this.allConformanceFinalised != null &&
                    allConformanceFinalised != this.allConformanceFinalised
                ) {
                    // When all conformance becomes finalised the available actions may change
                    this.notifyMissionChanged();
                }
                this.allConformanceFinalised = allConformanceFinalised;

                this.flightConformanceDataSource.next(results);
            });
    }

    private refreshOrganisation() {
        this.organisationService
            .findByIdForUser(this.organisationId, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: organisation => {
                    this.organisationSource.next(organisation);
                },
                error: (error: FlyFreelyError) =>
                    this.errorSource.next({
                        error,
                        fatal: true,
                        component: 'ORGANISATION',
                        message: `Error finding organisation for mission: ${error.message}`
                    })
            })
            .add(this.workTracker.createTracker());
    }

    notifyMissionChanged() {
        this.changeSource.next();
    }

    getConformanceRibbonData() {
        return {
            ...ribbonValues.conformance
        };
    }

    getRibbonValues(
        status: MissionDetailsDto.Status,
        mission: DisplayableMissionDto
    ): StatusFields {
        const canApprove = mission.availableActions.canApprove;
        const missionStatus = calculateCombinedStatus(
            status,
            findActiveApprovals(mission.approvals, true).map(a => a.status),
            mission.readyForFinalisation
        );

        if (mission.type === 'RETROSPECTIVE') {
            return {
                ...ribbonValues.retrospective,
                hasButton:
                    (mission.availableActions.hasFinalise ||
                        mission.availableActions.canRequestFinalisation) &&
                    mission.status !== 'FINALISED',
                buttonDisabled:
                    mission.availableActions.hasFinalise &&
                    !mission.availableActions.canFinalise,
                buttonTooltip:
                    mission.availableActions.hasFinalise &&
                    !mission.availableActions.canFinalise
                        ? 'The mission can not be finalised until the mission record is completed'
                        : null,
                buttonText:
                    mission.availableActions.hasFinalise === true
                        ? 'Finalise Mission'
                        : ribbonValues.retrospective.buttonText,
                reconciliation: true
            };
        }

        switch (missionStatus) {
            case CombinedMissionStatus.SUBMITTED:
                if (
                    canApprove === true &&
                    mission.approvals != null &&
                    findActiveApprovals(mission.approvals)?.length > 1
                ) {
                    const totalApprovals = findActiveApprovals(
                        mission.approvals
                    ).length;
                    const unactionedApprovals = findUnactionedApprovals(
                        mission.approvals
                    ).length;
                    return {
                        ...ribbonValues.submittedMultiple,
                        submission: true,
                        draft: false,
                        buttonText: `${
                            totalApprovals - unactionedApprovals
                        } of ${totalApprovals} approvals actioned`,
                        buttonTooltip: `${unactionedApprovals} approvals still require attention, click for more details`
                    };
                }
                return {
                    ...ribbonValues.submitted,
                    submission: true,
                    draft: false
                };
            case CombinedMissionStatus.DRAFT:
                const activeApprovals = findActiveApprovals(mission.approvals);
                const unactionedApprovals =
                    findUnactionedApprovals(activeApprovals).length > 0;
                const approvedAndRejectedApprovals =
                    activeApprovals.filter(
                        a => a.status === 'APPROVED' || a.status === 'REJECTED'
                    ).length > 0;
                return {
                    ...ribbonValues.planning,
                    draft: true,
                    submission: false,
                    multipleApprovals:
                        unactionedApprovals || approvedAndRejectedApprovals
                };
            case CombinedMissionStatus.READY_TO_FLY:
                if (
                    mission.approvals != null &&
                    findActiveApprovals(mission.approvals)?.length > 0
                ) {
                    const activeApprovals = findActiveApprovals(
                        mission.approvals
                    );
                    if (
                        canApprove === true &&
                        activeApprovals.length === 1 &&
                        activeApprovals[0].status ===
                            ApproversMissionApprovalDto.Status.APPROVED
                    ) {
                        return {
                            ...ribbonValues.approved,
                            launch: true,
                            hasButton: true,
                            buttonText: 'Revoke Approval'
                        };
                    }
                    if (canApprove === true && activeApprovals.length > 1) {
                        const unactionedApprovals = findUnactionedApprovals(
                            mission.approvals
                        ).length;
                        return {
                            ...ribbonValues.approved,
                            launch: unactionedApprovals === 0,
                            hasButton: true,
                            multipleApprovals: true,
                            buttonText: `${
                                activeApprovals.length - unactionedApprovals
                            } of ${activeApprovals.length} approvals actioned`,
                            buttonTooltip: `${unactionedApprovals} approvals still require attention, click for more details`
                        };
                    }
                    return {
                        ...ribbonValues.approved,
                        launch: true
                    };
                }
                return {
                    ...ribbonValues.approved,
                    label: 'Ready for Mission Launch',
                    hasButton: false,
                    launch: true
                };
            case CombinedMissionStatus.CANCELLED:
                return {
                    ...ribbonValues.cancelled,
                    approval: true
                };
            case CombinedMissionStatus.READY_FOR_FINALISATION:
                return {
                    ...ribbonValues.finalRequest,
                    finalRequest: true
                };
            case CombinedMissionStatus.COMPLETED:
                if (mission.availableActions.hasFinalise) {
                    return {
                        ...ribbonValues.reconciliation,
                        hasButton: true,
                        buttonTooltip: mission.availableActions.canFinalise
                            ? null
                            : 'The mission can not be finalised until the mission record is completed',
                        buttonDisabled: !mission.availableActions.canFinalise,
                        buttonText: 'Finalise Mission',
                        reconciliation: true
                    };
                }

                return {
                    ...ribbonValues.reconciliation,
                    hasButton:
                        mission.availableActions.canFinalise ||
                        mission.availableActions.canRequestFinalisation,
                    buttonText:
                        mission.availableActions.canFinalise === true
                            ? 'Finalise Mission'
                            : ribbonValues.reconciliation.buttonText,
                    reconciliation: true
                };
            case CombinedMissionStatus.FINALISED:
                return {
                    ...ribbonValues.finalised,
                    finalised: true
                };
            case CombinedMissionStatus.ON_SITE:
                return {
                    ...ribbonValues.onSite,
                    operations: true
                };
            case CombinedMissionStatus.FLYING:
                return {
                    ...ribbonValues.flying,
                    operations: true
                };
            case CombinedMissionStatus.AWAITING_SYNC:
                return {
                    ...ribbonValues.awaitingSync,
                    operations: true
                };
            default:
                return;
        }
    }

    doRibbonAction(mission: DisplayableMissionDto, organisation: Organisation) {
        // Change the mission status depending on the current status.
        /* const status = mission.status; */
        const status = calculateCombinedStatus(
            mission.status,
            findActiveApprovals(mission.approvals, true).map(a => a.status),
            mission.readyForFinalisation
        );
        const doneWorking = this.workTracker.createTracker();
        switch (status) {
            case CombinedMissionStatus.SUBMITTED:
                if (findActiveApprovals(mission.approvals, true).length === 1) {
                    const activeApproval = findActiveApprovals(
                        mission.approvals,
                        true
                    )[0];
                    return this.commonDialoguesService
                        .showConfirmationDialogue(
                            'Cancel Mission Approval Request',
                            'Do you want to cancel this mission approval request?',
                            'Cancel Mission Approval Request',
                            () =>
                                firstValueFrom(
                                    this.approvalService.cancelApproval(
                                        activeApproval.id
                                    )
                                )
                        )
                        .then(
                            () => {
                                this.logging.success(
                                    'Mission approval request cancelled'
                                );
                                this.notifyMissionChanged();
                                doneWorking();
                            },
                            () => {
                                doneWorking();
                            }
                        )
                        .catch(() => doneWorking());
                }
            case CombinedMissionStatus.ON_SITE:
            case CombinedMissionStatus.FLYING:
                return this.commonDialoguesService
                    .showConfirmationDialogue(
                        'Finish Field App Mission',
                        'Finishing this mission will prevent the Field App from performing further updates on this mission. Are you sure?',
                        'Finish Mission',
                        () =>
                            /* this.openMissionCompletionDialogue(mission, organisation); */
                            Promise.all([
                                this.openMissionCompletionDialogue(
                                    mission,
                                    organisation
                                )
                            ]).then(() => {
                                return Promise.resolve();
                            })
                    )
                    .then(
                        results => {
                            doneWorking();
                        },
                        error => this.logging.error(error)
                    )
                    .catch(() => doneWorking());

            case CombinedMissionStatus.READY_FOR_FINALISATION:
                return this.commonDialoguesService
                    .showConfirmationDialogue(
                        'Cancel Finalisation Request',
                        'Do you want to cancel the finalisation request for this mission?',
                        'Yes',
                        () =>
                            this.missionService
                                .clearReadyForFinalisation(mission.id)
                                .toPromise()
                    )
                    .then(
                        results => {
                            this.logging.success(
                                'Mission finalisation request cancelled'
                            );
                            this.notifyMissionChanged();
                            doneWorking();
                        },
                        error => this.logging.error(error)
                    )
                    .catch(() => doneWorking());
            case CombinedMissionStatus.COMPLETED:
                if (mission.availableActions.canFinalise === true) {
                    return this.commonDialoguesService
                        .showConfirmationDialogue(
                            'Finalise Mission',
                            'Do you want to finalise this mission? No further editing will be allowed after finalising.',
                            'Yes',
                            () =>
                                this.missionService.finaliseMission(
                                    mission.id,
                                    false
                                )
                        )
                        .then(
                            results => {
                                this.logging.success('Mission finalised');
                                this.notifyMissionChanged();
                                doneWorking();
                            },
                            error => this.logging.error(error)
                        )
                        .catch(() => doneWorking());
                }
                return this.commonDialoguesService
                    .showConfirmationDialogue(
                        'Request Finalisation',
                        'Do you want to request finalisation for this mission?',
                        'Yes',
                        () =>
                            this.missionService
                                .readyForFinalisation(mission.id)
                                .toPromise()
                    )
                    .then(
                        results => {
                            this.logging.success(
                                'Mission finalisation requested'
                            );
                            this.notifyMissionChanged();
                            doneWorking();
                        },
                        error => this.logging.error(error)
                    )
                    .catch(() => doneWorking());

            default:
                doneWorking();
                return Promise.resolve();
        }
    }

    openMissionCompletionDialogue(
        mission: DisplayableMissionDto,
        organisation: Organisation
    ) {
        this.missionDialoguesService.showMissionCompletion(
            mission,
            organisation
        );
    }
}
