import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    ControlTypeDescriptor,
    DisplayCondition,
    FormControlDto,
    NameValue,
    OptionDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlDescription } from '../interfaces';
import { CurrentAttachmentVersionDtoWithDownloadUrl } from '../internal-interfaces';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'; // Import CDK Drag and Drop

interface Comparator {
    value: DisplayCondition.Comparator;
    name: string;
    operands: number;
}

enum WhenToDisplay {
    ALWAYS = 'ALWAYS',
    NEVER = 'NEVER',
    CONDITIONALLY = 'CONDITIONALLY'
}

interface EditableCondition {
    whenToDisplay: WhenToDisplay;
    subjectControl: ControlDescription;
    comparator: Comparator;
    values: string[];
}

function encodeCondition(condition: EditableCondition): DisplayCondition {
    switch (condition.whenToDisplay) {
        case WhenToDisplay.ALWAYS:
            return null;
        case WhenToDisplay.NEVER:
            return {};
        case WhenToDisplay.CONDITIONALLY:
            return {
                subjectControlId: condition.subjectControl.id,
                comparator: condition.comparator.value,
                values: condition.values
            };
    }
}

function isValidComparatorControl(control: FormControlDto) {
    const { type } = control;

    return (
        type === 'number' ||
        type === 'range' ||
        type === 'calculated' ||
        type === 'single-select' ||
        type === 'boolean' ||
        type === 'warning-with-acknowledgement' ||
        type === 'checkbox'
    );
}

function isNumericalControl(control: FormControlDto) {
    const { type } = control;

    if (type === 'number' || type === 'range' || type === 'calculated') {
        return true;
    }
    if (type === 'single-select') {
        return (
            control.options.findIndex(o => isNaN(parseInt(o.value, 10))) === -1
        );
    }

    return false;
}

function getComparators(control: FormControlDto): Comparator[] {
    if (isNumericalControl(control)) {
        return [
            {
                value: DisplayCondition.Comparator.EQUAL,
                name: '=',
                operands: 1
            },
            {
                value: DisplayCondition.Comparator.NOT_EQUAL,
                name: '!=',
                operands: 1
            },
            { value: DisplayCondition.Comparator.GTE, name: '>=', operands: 1 },
            { value: DisplayCondition.Comparator.LTE, name: '<=', operands: 1 },
            { value: DisplayCondition.Comparator.GT, name: '>', operands: 1 },
            { value: DisplayCondition.Comparator.LT, name: '<', operands: 1 },
            {
                value: DisplayCondition.Comparator.BETWEEN,
                name: 'BETWEEN',
                operands: 2
            },
            {
                value: DisplayCondition.Comparator.NOT_BETWEEN,
                name: 'NOT BETWEEN',
                operands: 2
            }
        ];
    }

    return [
        { value: DisplayCondition.Comparator.EQUAL, name: '=', operands: 1 },
        {
            value: DisplayCondition.Comparator.NOT_EQUAL,
            name: '!=',
            operands: 1
        }
    ];
}

export function range(lowEnd: number, highEnd: number): Array<number> {
    const arr = [];
    let c = highEnd - lowEnd + 1;
    while (c--) {
        arr[c] = highEnd--;
    }
    return arr;
}

@Component({
    selector: 'form-control-edit-dialogue',
    templateUrl: './form-control-edit-dialogue.component.html'
})
export class FormControlEditDialogue implements OnInit, OnDestroy {
    @Input() control: FormControlDto;
    @Input() previousControls: ControlDescription[];
    @Input() attachments: CurrentAttachmentVersionDtoWithDownloadUrl[];
    @Output() controlChanged: EventEmitter<FormControlDto> = new EventEmitter();

    controlTypes: ControlTypeDescriptor[];
    operators: NameValue[];
    comparatorControls: ControlDescription[];
    numericalControls: ControlDescription[];
    comparators: Comparator[];

    limitOptions: boolean;
    comparatorOptions: string[];

    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    controlForm: FormGroup;

    constructor(public modal: BsModalRef<FormControlEditDialogue>) {
        this.controlTypes = [
            {
                type: 'text',
                name: 'Text input',
                config: [
                    {
                        name: 'Minimum size',
                        value: 'min'
                    },
                    {
                        name: 'Maximum size',
                        value: 'max'
                    }
                ],
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: true,
                hasInput: true,
                hasConfig: true
            },
            {
                type: 'number',
                name: 'Number input',
                config: [
                    {
                        name: 'Minimum value',
                        value: 'min'
                    },
                    {
                        name: 'Maximum value',
                        value: 'max'
                    }
                ],
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: false,
                hasInput: true,
                hasConfig: true
            },
            {
                type: 'range',
                name: 'Number range input',
                config: [
                    {
                        name: 'Minimum value',
                        value: 'min',
                        validator: Validators.compose([Validators.required])
                    },
                    {
                        name: 'Maximum value',
                        value: 'max',
                        validator: Validators.compose([Validators.required])
                    }
                ],
                configValidator: this.rangeLimiter('min', 'max'),
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: false,
                hasInput: true,
                hasConfig: true
            },
            {
                type: 'textarea',
                name: 'Text area',
                config: [
                    {
                        name: 'Minimum size',
                        value: 'min'
                    },
                    {
                        name: 'Maximum size',
                        value: 'max'
                    }
                ],
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: true,
                hasInput: true,
                hasConfig: true
            },
            {
                type: 'boolean',
                name: 'Yes/No',
                config: [],
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: false,
                hasInput: true,
                hasConfig: false
            },
            {
                type: 'checkbox',
                name: 'Check',
                config: [],
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: false,
                hasInput: true,
                hasConfig: false
            },
            {
                type: 'single-select',
                name: 'Single dropdown selection',
                config: [],
                hasLabel: true,
                hasOptions: true,
                hasPlaceholder: false,
                hasInput: true,
                hasConfig: false
            },
            {
                type: 'info',
                name: 'Informational message',
                config: [],
                hasLabel: false,
                hasOptions: false,
                hasPlaceholder: false,
                hasInput: false,
                hasConfig: false
            },
            {
                type: 'warning',
                name: 'Warning message',
                config: [],
                hasLabel: false,
                hasOptions: false,
                hasPlaceholder: false,
                hasInput: false,
                hasConfig: false
            },
            {
                type: 'warning-with-acknowledgement',
                name: 'Warning message with acknowledgement',
                config: [],
                hasLabel: false,
                hasOptions: false,
                hasPlaceholder: false,
                hasInput: true,
                hasConfig: false
            },
            {
                type: 'image',
                name: 'Image',
                config: [
                    {
                        name: 'Attachment ID',
                        value: 'attachmentId'
                    },
                    {
                        name: 'Attachment Version ID',
                        value: 'attachmentVersionId'
                    }
                ],
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: true,
                hasInput: false,
                hasConfig: false
            },
            {
                type: 'calculated',
                name: 'Calculated',
                config: [
                    {
                        name: 'Operator',
                        value: 'operator'
                    }
                ],
                hasLabel: true,
                hasOptions: false,
                hasPlaceholder: true,
                hasInput: false,
                hasConfig: false
            }
        ];

        this.operators = [
            {
                name: 'Sum',
                value: 'sum'
            }
        ];
    }

    ngOnInit() {
        this.workTracker
            .observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.comparatorControls = this.previousControls.filter(c =>
            isValidComparatorControl(c.control)
        );
        this.numericalControls = this.previousControls.filter(c =>
            isNumericalControl(c.control)
        );

        this.initForm(this.control);

        this.getWhenToDisplay.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                if (this.getSubjectControl.value) {
                    this.comparators = getComparators(
                        this.getSubjectControl.value.control
                    );
                }
                if (value === 'CONDITIONALLY') {
                    this.getSubjectControl.setValidators([Validators.required]);
                    this.getSubjectControl.updateValueAndValidity();
                    this.getComparator.setValidators([Validators.required]);
                    this.getComparator.updateValueAndValidity();
                    this.shouldLimitOptions();
                } else {
                    this.getSubjectControl.setValidators(null);
                    this.getSubjectControl.updateValueAndValidity();
                    this.getComparator.setValidators(null);
                    this.getComparator.updateValueAndValidity();
                }
            });

        this.controlForm.controls.comparator.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((c: Comparator) => {
                if (c == null) {
                    return;
                }
                const conditionValues: FormArray = <FormArray>(
                    this.controlForm.controls.conditionValues
                );
                while (conditionValues.length > c.operands) {
                    conditionValues.removeAt(conditionValues.length - 1);
                }
                while (conditionValues.length < c.operands) {
                    conditionValues.push(
                        new FormControl(undefined, [Validators.required])
                    );
                }
                this.shouldLimitOptions();
            });

        this.controlForm.controls.controlType.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((controlType: ControlTypeDescriptor) => {
                if (controlType && controlType.type === 'calculated') {
                    this.getOperator.setValidators([Validators.required]);
                    this.getOperator.updateValueAndValidity();
                } else {
                    this.getOperator.setValidators(null);
                    this.getOperator.updateValueAndValidity();
                }

                if (controlType.hasLabel) {
                    this.controlForm.controls.label.setValidators([
                        Validators.required
                    ]);
                    this.controlForm.controls.label.updateValueAndValidity();
                } else {
                    this.controlForm.controls.label.setValidators(null);
                    this.controlForm.controls.label.updateValueAndValidity();
                }

                if (this.configGroup.validator != null) {
                    this.configGroup.clearValidators();
                    this.configGroup.updateValueAndValidity();
                }

                for (const control of Object.keys(this.configGroup.controls)) {
                    this.configGroup.removeControl(control);
                }

                if (controlType != null && controlType.hasConfig) {
                    controlType.config.forEach(c =>
                        this.configGroup.addControl(
                            c.value,
                            new FormControl(undefined, c.validator)
                        )
                    );
                    if (controlType.configValidator != null) {
                        this.configGroup.setValidators(
                            controlType.configValidator
                        );
                    }
                    this.configGroup.updateValueAndValidity();
                }
            });

        this.controlForm.controls.subjectControl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((control: ControlDescription) => {
                this.comparators =
                    control == null ? [] : getComparators(control.control);
                this.shouldLimitOptions();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    initForm(control: FormControlDto) {
        // Initialise the control type
        const controlType = this.controlTypes.find(
            ct => ct.type === control.type
        );

        let calculation: {
            operator?: string;
            operands: number[];
        } = {
            operands: []
        };
        let imageAttachment: CurrentAttachmentVersionDtoWithDownloadUrl;
        if (controlType?.type === 'image') {
            if (!control.config || !control.config.attachmentId) {
                imageAttachment = null;
            } else {
                for (const attachment of this.attachments) {
                    if (
                        control.config.attachmentId === attachment.id.toString()
                    ) {
                        imageAttachment = attachment;
                        break;
                    }
                }
            }
        } else if (controlType?.type === 'calculated') {
            if (control.config) {
                calculation = {
                    operator: control.config.operator,
                    operands: control.inputControlIds || []
                };
            }
        }

        let condition: EditableCondition;

        if (
            control.displayCondition === null ||
            control.displayCondition === undefined
        ) {
            this.comparators = [];
            condition = {
                whenToDisplay: WhenToDisplay.ALWAYS,
                subjectControl: null,
                comparator: null,
                values: []
            };
        } else {
            const subjectControl: ControlDescription =
                control.displayCondition.subjectControlId != null
                    ? this.comparatorControls.find(
                          c =>
                              c.id === control.displayCondition.subjectControlId
                      )
                    : null;
            this.comparators = getComparators(subjectControl.control);

            condition = {
                whenToDisplay:
                    control.displayCondition.subjectControlId == null
                        ? WhenToDisplay.NEVER
                        : WhenToDisplay.CONDITIONALLY,
                subjectControl: subjectControl,
                comparator: this.comparators.find(
                    c => c.value === control.displayCondition.comparator
                ),
                values: control.displayCondition.values
            };
        }

        const options = control.options || [];

        this.controlForm = new FormGroup({
            controlType: new FormControl(controlType, [Validators.required]),
            label: new FormControl(control.label),
            placeholder: new FormControl(control.placeholder),
            description: new FormControl(control.description),
            imageAttachment: new FormControl(imageAttachment),
            required: new FormControl(control.required),
            whenToDisplay: new FormControl(condition.whenToDisplay),
            subjectControl: new FormControl(condition.subjectControl),
            comparator: new FormControl(condition.comparator),
            conditionValues: new FormArray(
                condition.values.map(
                    v => new FormControl(v, [Validators.required])
                )
            ),
            operator: new FormControl(calculation.operator),
            operands: new FormArray(
                calculation.operands.map(
                    v => new FormControl(v, [Validators.required])
                )
            ),
            options: new FormArray(options.map(o => this.newOption(o))),
            config: new FormGroup({})
        });
        if (controlType != null && controlType.hasConfig) {
            controlType.config.forEach(c =>
                this.configGroup.addControl(
                    c.value,
                    new FormControl(
                        control.config != null
                            ? control.config[c.value]
                            : undefined,
                        c.validator
                    )
                )
            );
            if (controlType.configValidator != null) {
                this.configGroup.setValidators(controlType.configValidator);
            }
            this.configGroup.updateValueAndValidity();
        }
        if (this.getWhenToDisplay?.value === 'CONDITIONALLY') {
            this.shouldLimitOptions();
        }
    }

    shouldLimitOptions() {
        if (this.getSubjectControl.value == null || !this.getWhenToDisplay) {
            return;
        }
        const controlType = this.getSubjectControl.value;
        if (controlType.control.type === 'single-select') {
            this.comparatorOptions = [];
            controlType.control.options.map((option: OptionDto) => {
                this.comparatorOptions.push(option.value);
            });
            this.limitOptions = true;
        } else if (
            controlType.control.type === 'boolean' ||
            controlType.control.type === 'checkbox'
        ) {
            this.comparatorOptions = ['true', 'false'];
            this.limitOptions = true;
        } else if (controlType.control.type === 'range') {
            const rangeMin = parseInt(controlType.control.config.min, 10);
            const rangeMax = parseInt(controlType.control.config.max, 10);
            const options = range(rangeMin, rangeMax);
            this.comparatorOptions = [];
            options.map(o => this.comparatorOptions.push(o.toString()));
            this.limitOptions = true;
        } else {
            this.limitOptions = false;
        }
    }

    removeOption(ix: number) {
        (<FormArray>this.controlForm.controls.options).removeAt(ix);
    }

        // 2. New method to handle drag-and-drop sorting of options
        dropOption(event: CdkDragDrop<any[]>) {
            // Make sure the move operation affects the actual form controls array
            const optionsFormArray = this.getOptions;
            
            const previous = optionsFormArray.at(event.previousIndex);
            optionsFormArray.removeAt(event.previousIndex);  // Remove from previous index
            optionsFormArray.insert(event.currentIndex, previous);  // Insert at new index
            
            // Mark the form as dirty and touched so Angular knows the form has unsaved changes
            this.controlForm.markAsDirty();
            this.controlForm.markAsTouched();
        }
        

    private newOption(option: OptionDto) {
        return new FormGroup(
            {
                name: new FormControl(option.name, [Validators.required]),
                value: new FormControl(option.value, [Validators.required]),
                group: new FormControl(option.group)
            },
            [Validators.required]
        );
    }

    addOption() {
        (<FormArray>this.controlForm.controls.options).push(
            this.newOption({ name: null, value: null })
        );
    }

    range(max: number) {
        return range(0, max - 1);
    }

    removeOperand(ix: number) {
        (<FormArray>this.controlForm.controls.operands).removeAt(ix);
    }

    addOperand() {
        (<FormArray>this.controlForm.controls.operands).push(
            new FormControl(undefined, [Validators.required])
        );
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    // submit() {
    //     const formValue = this.controlForm.value;
    //     const controlType: ControlTypeDescriptor = formValue.controlType;

    //     let config;
    //     let inputControlIds: number[] = [];
    //     if (controlType && controlType.type === 'image') {
    //         config = {
    //             attachmentId: this.getImageAttachment.value
    //                 ? this.getImageAttachment.value.id.toString()
    //                 : null,
    //             attachmentVersionId: this.getImageAttachment.value
    //                 ? this.getImageAttachment.value.attachmentVersionId.toString()
    //                 : null
    //         };
    //     } else if (controlType && controlType.type === 'calculated') {
    //         config = {
    //             operator: this.getOperator.value
    //         };
    //         inputControlIds = formValue.operands;
    //     } else if (controlType.hasConfig) {
    //         config = formValue.config;
    //     }

    //     this.controlChanged.next({
    //         ...this.control,
    //         label: formValue.label ? formValue.label : '',
    //         description: formValue.description,
    //         placeholder: formValue.placeholder,
    //         required:
    //             controlType && controlType.hasInput
    //                 ? formValue.required || false
    //                 : false,
    //         order: this.control.order,
    //         type: controlType ? controlType.type : null,
    //         options:controlType && controlType.hasOptions? formValue.options: null,
    //         config: this.removeBlanks(controlType, config),
    //         displayCondition: encodeCondition({
    //             comparator: formValue.comparator,
    //             subjectControl: formValue.subjectControl,
    //             values: formValue.conditionValues,
    //             whenToDisplay: formValue.whenToDisplay
    //         }),
    //         inputControlIds: inputControlIds,
    //     });
    //     this.modal.hide();
    // }
    submit() {
        const formValue = this.controlForm.value;
        const controlType: ControlTypeDescriptor = formValue.controlType;
    
        let config;
        let inputControlIds: number[] = [];
        if (controlType && controlType.type === 'image') {
            config = {
                attachmentId: this.getImageAttachment.value
                    ? this.getImageAttachment.value.id.toString()
                    : null,
                attachmentVersionId: this.getImageAttachment.value
                    ? this.getImageAttachment.value.attachmentVersionId.toString()
                    : null
            };
        } else if (controlType && controlType.type === 'calculated') {
            config = {
                operator: this.getOperator.value
            };
            inputControlIds = formValue.operands;
        } else if (controlType.hasConfig) {
            config = formValue.config;
        }
    
        this.controlChanged.next({
            ...this.control,
            label: formValue.label ? formValue.label : '',
            description: formValue.description,
            placeholder: formValue.placeholder,
            required:
                controlType && controlType.hasInput
                    ? formValue.required || false
                    : false,
            order: this.control.order,
            type: controlType ? controlType.type : null,
            options: controlType && controlType.hasOptions ? formValue.options : null, // <-- Ensure reordered options are submitted
            config: this.removeBlanks(controlType, config),
            displayCondition: encodeCondition({
                comparator: formValue.comparator,
                subjectControl: formValue.subjectControl,
                values: formValue.conditionValues,
                whenToDisplay: formValue.whenToDisplay
            }),
            inputControlIds: inputControlIds,
        });
        this.modal.hide();
    }
    

    private removeBlanks(
        controlType: ControlTypeDescriptor,
        config: {
            [key: string]: string;
        }
    ): { [key: string]: string } {
        const newConfig = {};
        if (!this.control || !controlType.type) {
            return config;
        }
        if (!config) {
            return {};
        }
        for (const configItem of controlType.config) {
            if (config[configItem.value] !== '') {
                newConfig[configItem.value] = config[configItem.value];
            }
        }
        return newConfig;
    }

    private rangeLimiter(min: string, max: string) {
        return (control: AbstractControl) => {
            if (control.get(min).value > control.get(max).value) {
                return {
                    config:
                        'Minimum value should be lass than or equal to maximum value'
                };
            }
            return null;
        };
    }

    get configGroup() {
        return <FormGroup>this.controlForm.get('config');
    }

    get getImageAttachment() {
        return this.controlForm.get('imageAttachment');
    }

    get getWhenToDisplay() {
        return this.controlForm.get('whenToDisplay');
    }

    get getSubjectControl() {
        return this.controlForm.get('subjectControl');
    }

    get getComparator() {
        return this.controlForm.get('comparator');
    }

    get getOptions() {
        return this.controlForm.get('options') as FormArray;
    }

    get getOperands() {
        return this.controlForm.get('operands') as FormArray;
    }

    get getOperator() {
        return this.controlForm.get('operator');
    }
}
