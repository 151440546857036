import { Injectable, Optional } from '@angular/core';
import {
    OrganisationSubscriptionDto,
    SubscriptionDetailsDto,
    SubscriptionService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';
import {
    concatMap,
    filter,
    map,
    mergeMap,
    shareReplay,
    switchMap,
    take,
    takeUntil
} from 'rxjs/operators';

export interface SubscriptionAndDetails {
    subscription: OrganisationSubscriptionDto;
    details: SubscriptionDetailsDto;
}

/**
 * Coordinates the subscription state information for the current subscription within the
 * organisation subscription component.
 * FIXME I feel this should be a scoped service, not a root one
 */
@Injectable({
    providedIn: 'root'
})
export class OrganisationSubscriptionState {
    private subscriptionSource = new ReplaySubject<OrganisationSubscriptionDto>(
        1
    );
    subscription$ = this.subscriptionSource.asObservable();

    private availableSubscriptionsSource = new ReplaySubject<
        OrganisationSubscriptionDto[]
    >(1);
    availableSubscriptions$ = this.availableSubscriptionsSource.asObservable();

    private workTracker = new WorkTracker();
    updatePending$ = this.workTracker.observable;

    subscriptionDetails$: Observable<SubscriptionAndDetails>;

    subscriptionUpdated$ = new Subject<void>();

    private defaultActiveSubscription: OrganisationSubscriptionDto;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private subscriptionService: SubscriptionService,
        @Optional() private userService: UserService
    ) {
        this.subscriptionDetails$ = this.subscription$.pipe(
            takeUntil(this.ngUnsubscribe$),
            filter(subscription => subscription?.externalIdentifier != null),
            mergeMap(subscription =>
                subscriptionService
                    .findSubscriptionDetails(subscription.id)
                    .pipe(map(details => ({ subscription, details })))
            ),
            shareReplay(1)
        );
    }

    ngOnDestroy() {
        this.subscriptionSource.complete();
        this.subscriptionUpdated$.complete();
        this.availableSubscriptionsSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refresh(organisationId: number) {
        this.subscriptionService
            .findCurrentSubscriptions(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(subscriptions => {
                this.availableSubscriptionsSource.next(
                    subscriptions.filter(
                        s =>
                            s.status ===
                            OrganisationSubscriptionDto.Status.ACTIVE
                    )
                );
                if (this.defaultActiveSubscription == null) {
                    this.defaultActiveSubscription = subscriptions.filter(
                        s =>
                            s.status ===
                            OrganisationSubscriptionDto.Status.ACTIVE
                    )[0];
                }
                this.change(
                    subscriptions.filter(
                        s =>
                            s.status ===
                            OrganisationSubscriptionDto.Status.ACTIVE
                    )[0]
                );
            });
    }

    change(subscription: OrganisationSubscriptionDto) {
        if (
            subscription == null ||
            subscription.status !== OrganisationSubscriptionDto.Status.ACTIVE
        ) {
            this.subscriptionSource.next(undefined);
            return;
        }
        this.subscriptionSource.next(subscription);
    }

    /**
     * Fetch the new subscription and trigger any relevant UI updates
     */
    onNewSubscription(newSubscriptionId: number) {
        this.subscriptionService
            .findById(newSubscriptionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(subscription => this.onSubscriptionUpdated(subscription))
            .add(this.workTracker.createTracker());
    }

    /**
     * Start refreshing the organisation's subscription state to get the new licence count
     * @param expected the expected number of licences
     */
    pollForLicenceUpdates(expected: number) {
        this.pollForUpdates(
            updated => updated.limits[0].instances === expected
        );
    }

    pollForSubscriptionCancellation() {
        this.pollForUpdates(updated => updated.endTime != null);
    }

    pollForSubscriptionUncancellation() {
        this.pollForUpdates(updated => updated.endTime == null);
    }

    pollForSubscriptionUpgrade() {
        this.subscription$.pipe(take(1)).subscribe(subscription => {
            this.pollForUpdates(
                update =>
                    update.limits[0].instances !==
                        subscription.limits[0].instances ||
                    update.externalPlanIdentifier !==
                        subscription.externalPlanIdentifier
            );
        });
    }

    resetToDefaultSubscription() {
        if (this.defaultActiveSubscription == null) {
            return;
        }
        this.subscriptionSource.next(this.defaultActiveSubscription);
    }

    private pollForUpdates(
        check: (updated: OrganisationSubscriptionDto) => boolean
    ) {
        this.subscription$
            .pipe(
                take(1),
                switchMap(subscription =>
                    timer(0, 1000)
                        .pipe(
                            concatMap(() =>
                                this.subscriptionService.findById(
                                    subscription.id
                                )
                            )
                        )
                        .pipe(filter(check))
                ),
                take(1),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(updated => this.onSubscriptionUpdated(updated))
            .add(this.workTracker.createTracker());
    }

    private onSubscriptionUpdated(updated: OrganisationSubscriptionDto) {
        this.change(updated);
        // trigger a refresh of the workspace licence service
        if (this.userService != null) {
            this.subscriptionUpdated$.next();
            this.userService.refreshUsersOrganisations();
        }
    }
}
