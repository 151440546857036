<enhanced-help componentName="condition-library-list" [compact]="true">
</enhanced-help>

<div class="container-scroll">
    <div class="horizontal-container">
        <button
            type="button"
            class="btn-circle btn-link"
            (click)="addConditionLibrary()"
            [tooltip]="'Create new condition library'"
            [disabled]="working"
        >
            <span class="fa fa-plus"></span>
        </button>
        <div class="fill" *ngIf="conditions != null">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 45%">Name</th>
                        <th style="width: 45%">Condition Count</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let condition of conditions">
                        <tr class="data">
                            <td>
                                <a (click)="editConditionLibrary(condition)">{{
                                    condition.name
                                }}</a>
                            </td>
                            <td>
                                {{ condition.conditions.length }}
                            </td>
                            <td class="actions">
                                <button
                                    type="button"
                                    class="btn btn-tertiary btn-delete"
                                    (click)="deleteConditionLibrary(condition)"
                                    [disabled]="working"
                                    tooltip="Delete"
                                    placement="left"
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
