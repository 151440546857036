import { Component } from '@angular/core';
import {
    EnhancedHelpDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnhancedHelpTreeService } from '../enhanced-help-tree.service';
import { EnhancedHelpService } from '../enhanced-help.service';
import { DynamicHelpEditService } from './dynamic-help-edit.service';

@Component({
    selector: 'dynamic-help-editor',
    templateUrl: './dynamic-help-editor.component.html',
    styleUrls: ['./dynamic-help-editor.styles.scss']
})
export class DynamicHelpEditorComponent {
    items: EnhancedHelpDto[];
    screenItems: EnhancedHelpDto[];
    currentScreenName: string;
    currentScreen: string;
    screenTree = this.enhancedHelpTreeService.screenTree;
    currentComponent: EnhancedHelpDto;
    selectedComponent: EnhancedHelpDto;

    // Flag to override the hover state and keep the overlay expanded
    keepOpen = false;

    alwaysOnUntil: Date;

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private dynamicHelpEditService: DynamicHelpEditService,
        private enhancedHelpTreeService: EnhancedHelpTreeService,
        private enhancedHelpService: EnhancedHelpService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        combineLatest([
            this.dynamicHelpEditService.currentScreen$,
            this.dynamicHelpEditService.recentSelectionList$,
            this.dynamicHelpEditService.currentScreenComponents$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([screen, items, screenItems]) => {
                if (screen != null) {
                    const screenObject = this.screenTree.find(
                        h => h.analyticsIdentifier === screen
                    );
                    this.currentScreenName = screenObject.name;
                    this.currentScreen = screenObject.identifier;
                }
                this.screenItems = screenItems ?? [];
                this.items = items ?? [];
                this.alwaysOnUntil =
                    items[0].alwaysOnUntil == null
                        ? null
                        : moment(items[0].alwaysOnUntil).toDate();
                this.currentComponent = items[0];
                this.selectedComponent = items[0];
                this.keepOpen = false;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    onChangeComponent(component: EnhancedHelpDto) {
        this.dynamicHelpEditService.elementSelected(component.component);
        this.keepOpen = false;
    }

    findComponentType() {
        return this.screenTree
            .find(s => s.identifier === this.currentScreen)
            .components.find(
                c => c.identifier === this.currentComponent.component
            ).type;
    }

    updateComponentHelp() {
        this.currentComponent.alwaysOnUntil =
            this.alwaysOnUntil != null
                ? moment(
                      moment(
                          <Date>this.alwaysOnUntil
                      ) /* .format('YYYY-MM-DD') */
                  ).toISOString()
                : null;
        this.dynamicHelpEditService
            .updateElement(this.currentComponent)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.enhancedHelpService
                    .findAll()
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(
                        () =>
                            this.logging.success(
                                `Help entry for ${this.currentComponent.helpTitle} updated`
                            ),
                        (error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                `Error updating help for ${this.currentComponent.helpTitle}: ${error.message}`
                            )
                    );
            });
        this.keepOpen = false;
    }
}
