import { Component, Input } from '@angular/core';
import {
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameId,
    PersonsOrganisationDto,
    RpaTypeWorkflowDto,
    RpaTypeWorkflowService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ResourceMaintenanceDataService } from './resource-maintenance-data.service';
import { WorkflowDialoguesService } from 'libs/workflows/src/lib/workflow-dialogues.service';

@Component({
    selector: 'rpa-workflows',
    template: ` <h4 class="section-heading">
            <span>RPA Type Workflow Library</span>
            <div></div>
        </h4>
        <enhanced-help componentName="rpa-workflows" [compact]="true">
        </enhanced-help>
        <entity-list
            type="RPA Workflow"
            [items]="workflowList"
            (create)="createWorkflow()"
            (edit)="editWorkflow($event)"
            (archive)="archiveWorkflow($event)"
            (unarchive)="unarchiveWorkflow($event)"
            [working]="working"
        ></entity-list>`
})
export class RpaWorkflows {
    @Input() organisation: PersonsOrganisationDto;

    private ngUnsubscribe$ = new Subject<void>();

    public working: boolean;

    workflowList: NameId[];

    constructor(
        private rpaTypeWorkflowService: RpaTypeWorkflowService,
        private workflowDialoguesService: WorkflowDialoguesService,
        private resourceMaintenanceDataService: ResourceMaintenanceDataService,
        private logging: FlyFreelyLoggingService,
        private workTracker: WorkTracker
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.resourceMaintenanceDataService.craftModelWorkflowList$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(workflowList => (this.workflowList = workflowList));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    createWorkflow() {
        const modal = this.showWorkflowDialogue({
            id: null,
            name: '',
            versions: [],
            organisationId: this.organisation.id,
            availableActions: { canEdit: true },
            archived: false
        });
        return modal.onHide
            .asObservable()
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(DO_NOTHING);
    }

    editWorkflow(workflow: RpaTypeWorkflowDto) {
        const modal =
            this.showWorkflowDialogue(workflow);
        return modal.onHide
            .asObservable()
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(DO_NOTHING);
    }

    private showWorkflowDialogue(workflow: RpaTypeWorkflowDto) {
        return this.workflowDialoguesService.showEditWorkflow(
            this.rpaTypeWorkflowService.getWorkflowSteps(),
            this.rpaTypeWorkflowService,
            workflow,
            false,
            true
        );
    }

    archiveWorkflow(workflow: RpaTypeWorkflowDto) {
        this.rpaTypeWorkflowService
            .archiveWorkflow(workflow.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    workflow.archived = results.archived;
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while archiving workflow: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    unarchiveWorkflow(workflow: RpaTypeWorkflowDto) {
        this.rpaTypeWorkflowService
            .unarchiveWorkflow(workflow.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    workflow.archived = results.archived;
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while unarchiving workflow: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }
}
