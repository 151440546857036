<div *ngIf="showAddForm" class="add-link-form">
    <div *ngIf="mode == 'INITIAL'">
        <button
            class="btn-base bg-transparent color-blue border-none"
            type="button"
            [disabled]="working"
            (click)="mode = 'ADD'"
        >
            <i class="fa fa-link"></i>&nbsp; Add Link
        </button>
    </div>
    <form
        *ngIf="mode !== 'INITIAL'"
        [formGroup]="linkForm"
        (ngSubmit)="saveLink()"
    >
        <div class="mb-16 d-flex w-100 align-center">
            <div class="d-flex w-136 space-6 mr-4">
                <label for="dueBy">Display Name </label>
            </div>
            <div class="w-100">
                <input
                    type="text"
                    id="title"
                    formControlName="title"
                    class="form-control corner-curve border-gray"
                    placeholder="Title"
                    required
                />
            </div>
        </div>
        <div class="mb-16 d-flex w-100 align-center">
            <div class="d-flex w-136 space-6 mr-4">
                <label for="dueBy">Link Address </label>
            </div>
            <div class="w-100">
                <input
                    type="text"
                    id="url"
                    formControlName="url"
                    class="form-control corner-curve border-gray"
                    placeholder="URL"
                    required
                />
            </div>
        </div>
        <div class="d-flex align-end">
            <button
                type="button"
                (click)="cancelEdit()"
                [disabled]="working"
                class="btn-base bg-transparent border-none"
            >
                Cancel
            </button>
            <button
                class="btn-base bg-transparent color-blue border-none"
                type="submit"
                [disabled]="working || linkForm.invalid"
            >
                <i class="fa fa-plus"></i>
                {{ editingLink ? 'Update' : 'Add' }}
            </button>
        </div>
    </form>
</div>

<div class="link-list no-text-break">
    <div class="link-item" *ngFor="let link of links$ | async">
        <a class="text-dark-gray" (click)="onLinkClick(link)">
            <i class="fa-solid fa-up-right-from-square color-blue"></i>
            {{ link.title }}
        </a>
        <button
            *ngIf="allowDelete"
            type="button"
            (click)="deleteLink(link.id)"
            class="remove-button bg-transparent border-none"
        >
            <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</div>
