<card
    [selected]="selected"
    (click)="noticeSelect()"
    [statusName]="applicability"
    [typeName]="type"
    statusStyle="ACTIVE"
>
    <div class="d-flex vertical-align space-between">
        <h4>{{ name }}</h4>
        <div class="card-edit-btn-container">
            <button
                class="circle-btn-icon border-gray bg-transparent"
                (click)="onEditClick($event)"
            >
                <i class="fa fa-pencil"></i>
            </button>
            <button class="circle-btn-icon border-gray bg-transparent">
                <i class="fa-regular fa-trash-can"></i>
            </button>
        </div>
    </div>
    <div class="" [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
        <div *ngIf="issuedBy" class="mb-8">
            <span class="text-light-gray">{{ issuedBy }}</span>
        </div>
        <div *ngIf="description" class="mb-16">
            <p>{{ description }}</p>
        </div>
        <div class="d-flex space-between mb-16">
            <div>
                <h6>Date Created</h6>
                <span>{{ formattedDateCreated }}</span>
            </div>
            <div>
                <h6>Completion Timeframe</h6>
                <span>{{ dueBy }} </span>
            </div>
        </div>
        <div class="mb-16">
            <links-and-attachments-list
                [attachmentList]="attachmentList"
                [linkList]="linkList"
                [downloadUrlFn]="downloadUrlFn"
            ></links-and-attachments-list>
        </div>
    </div>
    <div class="d-flex vertical-align">
        <div class="w-100">
            <div *ngIf="!isExpanded" class="d-flex vertical-align">
                <h6>Completion Timeframe</h6>
                <span>{{ dueBy }}</span>
            </div>
        </div>
        <button
            class="no-text-break bg-transparent color-blue border-none"
            type="button"
            (click)="toggleExpandCollapse()"
        >
            {{ isExpanded ? 'View Less' : 'View more' }}
        </button>
    </div>
</card>
