<enhanced-help componentName="battery-details" [compact]="true"></enhanced-help>
<div class="container-with-footer">
    <div class="contents">
        <div class="data">
            <span class="display-label">Name/Designation</span>
            {{ battery.name }}
        </div>

        <div class="data">
            <span class="display-label">Battery Classification</span>
            {{ battery.isDummy ? 'Dummy Battery' : 'Real Battery' }}
        </div>

        <div class="data">
            <span class="display-label">Manufacturer Serial Number</span>
            {{ battery.manufacturerSerialNumber }}
        </div>

        <div *ngIf="canUseNfc" class="data">
            <span class="display-label">NFC UID</span>
            {{ battery.nfcUid }}
        </div>

        <div class="data">
            <span class="display-label">Purchase Date</span>
            {{ battery.purchaseDate }}
        </div>

        <div class="data">
            <span class="display-label">Initial Flight Time</span>
            {{ battery.initialFlightTime | formatDuration | defaultTo }}
        </div>

        <div class="data">
            <span class="display-label">Initial Cycles</span>
            {{ battery.initialCycles }}
        </div>

        <div class="data">
            <span class="display-label">Battery Type</span>
            {{ battery.batteryTypeMake }} {{ battery.batteryTypeModel }}
        </div>

        <div class="data">
            <span class="display-label">Status</span>
            {{ battery.status | formatResourceStatus }}
        </div>

        <div class="data">
            <span class="display-label">Battery Set</span>
            <ng-container *ngIf="hasBatterySet == true">
                <ng-container
                    *ngFor="let batterySet of batterySets; let i = index"
                >
                    <a (click)="showBatterySet(batterySet)">
                        {{ batterySet.name }} </a
                    >{{ batterySetBatteriesCount(batterySet, i) }}
                </ng-container>
            </ng-container>
            <ng-container *ngIf="hasBatterySet == false && canEdit">
                <button
                    type="button"
                    class="btn btn-primary btn-xs"
                    tooltip="This will create a battery set with the same name and assembly date as this battery"
                    placement="left"
                    [disabled]="!canCreateBatterySet() || working"
                    (click)="createBatterySet()"
                >
                    Create Battery Set
                </button>
                <span *ngIf="!canCreateBatterySet()">
                    Only serviceable batteries can be used to create battery
                    sets.</span
                >
            </ng-container>
            <ng-container *ngIf="hasBatterySet == false && !canEdit">
                <span>This battery is not part of any battery sets</span>
            </ng-container>
        </div>

        <div class="data" *ngIf="battery.status === 'DISPOSED'">
            <span class="display-label">Disposal Date</span>
            {{ battery.disposalDate | formatDate }}
        </div>
    </div>
    <div class="action-container">
        <button
            *ngIf="hasDelete"
            [disabled]="!canDelete || working"
            class="btn btn-danger pull-left"
            type="button"
            (click)="delete()"
        >
            <span class="fal fa-trash-alt"></span>
            Delete
        </button>

        <div
            *ngIf="hasManageOptions && canEdit"
            class="btn-group"
            dropdown
            [dropup]="true"
            [isDisabled]="working"
        >
            <button
                id="button-container"
                dropdownToggle
                type="button"
                class="btn btn-default dropdown-toggle"
                aria-controls="dropdown-container"
                [ngClass]="{ disabled: working }"
            >
                Manage
                <span class="caret"></span>
            </button>
            <ul
                id="dropdown-container"
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="button-container"
            >
                <li role="menuitem" *ngIf="canRetiredBattery()">
                    <a class="dropdown-item" (click)="retireBattery()"
                        >Retire Battery</a
                    >
                </li>
                <li role="menuitem" *ngIf="canMarkUnderMaintenance()">
                    <a
                        class="dropdown-item"
                        (click)="markBatteryUnderMaintenance()"
                        >Mark as Under Maintenance</a
                    >
                </li>
                <li role="menuitem" *ngIf="canMarkInTest()">
                    <a class="dropdown-item" (click)="markBatteryInTest()"
                        >Mark as Serviceable</a
                    >
                </li>
                <li role="menuitem" *ngIf="canDisposeBattery()">
                    <a class="dropdown-item" (click)="disposeBattery()"
                        >Dispose Battery</a
                    >
                </li>
            </ul>
        </div>

        <button
            *ngIf="hasEdit"
            [disabled]="!canEdit || working"
            class="btn btn-primary"
            type="button"
            (click)="editBattery()"
        >
            Edit
        </button>
    </div>
</div>
