import NotamEntities from './notam-entities.json';
import NotamStatuses from './notam-statuses.json';

interface NotamQCode {
    code: string;
    message: string;
}

export const NOTAM_ENTITIES = NotamEntities.reduce(
    (acc, v: NotamQCode) => ({ ...acc, [v.code]: v.message }),
    {}
);
export const NOTAM_STATUSES = NotamStatuses.reduce(
    (acc, v: NotamQCode) => ({ ...acc, [v.code]: v.message }),
    {}
);
