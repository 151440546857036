import { DeviceDetectorService } from 'ngx-device-detector';

export function mobileCss(
    document: Document,
    deviceDetectorService: DeviceDetectorService
) {
    if (deviceDetectorService.isMobile()) {
        document.body.classList.add('mobile');
    }
}
