<div class="form-group top-buffer">
    <label>Name</label>
    <input
        type="text"
        placeholder="Type a name for your new API key"
        formControlName="name"
        class="form-control"
        minlength="3"
    />
</div>
<ng-container *ngIf="apiKeyFormService.hasOrganisations">
    <div class="form-group top-buffer">
        <label>Organisations</label>
        <div
            class="input-group"
            btnRadioGroup
            style="padding-top: 7px"
            formControlName="allOrganisations"
        >
            <label
                class="btn btn-xs btn-pill"
                [btnRadio]="true"
                [disabled]="working"
                >All</label
            >
            <label
                class="btn btn-xs btn-pill"
                [btnRadio]="false"
                [disabled]="working"
                >Selected</label
            >
        </div>
    </div>

    <ng-container
        *ngIf="
            apiKeyFormService.apiForm.controls.allOrganisations.value === false
        "
    >
        <div
            *ngIf="apiKeyFormService.apiForm.controls.formOrganisations != null"
            formArrayName="formOrganisations"
        >
            <div
                class="form-group top-buffer"
                *ngFor="
                    let org of apiKeyFormService.apiForm.controls
                        .formOrganisations.controls;
                    let i = index
                "
                [formGroupName]="i"
            >
                <div class="col-sm-12" btnRadioGroup formControlName="active">
                    <div class="col-sm-2">
                        <label>{{ org.controls.name.value }}</label>
                    </div>
                    <div class="col-sm-10">
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="true"
                            [disabled]="working"
                            >Allowed</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="false"
                            [disabled]="working"
                            >Not Allowed</label
                        >
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<div class="form-group top-buffer">
    <label>Permissions</label>
    <div
        class="input-group"
        btnRadioGroup
        style="padding-top: 7px"
        formControlName="allPermissions"
    >
        <label
            class="btn btn-xs btn-pill"
            [btnRadio]="true"
            [disabled]="working"
            >All</label
        >
        <label
            class="btn btn-xs btn-pill"
            [btnRadio]="false"
            [disabled]="working"
            >Selected</label
        >
    </div>
</div>

<ng-container
    *ngIf="apiKeyFormService.apiForm.controls.allPermissions.value === false"
>
    <div
        *ngIf="apiKeyFormService.apiForm.controls.formPermissions != null"
        formArrayName="formPermissions"
    >
        <div
            class="form-group top-buffer"
            *ngFor="
                let permission of apiKeyFormService.apiForm.controls
                    .formPermissions.controls;
                let j = index
            "
            [formGroupName]="j"
        >
            <div class="col-sm-12">
                <div class="col-sm-2">
                    <label>{{ permission.controls.name.value }}</label>
                </div>
                <div class="col-sm-10">
                    <button
                        type="button"
                        class="btn btn-xs btn-pill"
                        (click)="togglePermissions(permission, 'None')"
                        [ngClass]="{
                            active: permission.controls.statusNone.value == true
                        }"
                    >
                        None
                    </button>
                    <button
                        type="button"
                        class="btn btn-xs btn-pill"
                        (click)="togglePermissions(permission, 'Read')"
                        [ngClass]="{
                            active: permission.controls.statusRead.value == true
                        }"
                    >
                        Read
                    </button>
                    <button
                        type="button"
                        class="btn btn-xs btn-pill"
                        (click)="togglePermissions(permission, 'Write')"
                        [ngClass]="{
                            active:
                                permission.controls.statusWrite.value == true
                        }"
                    >
                        Write
                    </button>
                    <button
                        *ngIf="permission.controls.statusUpload.value != null"
                        type="button"
                        class="btn btn-xs btn-pill"
                        (click)="togglePermissions(permission, 'Upload')"
                        [ngClass]="{
                            active:
                                permission.controls.statusUpload.value == true
                        }"
                    >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
