import {
    HttpBackend,
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    FlyFreelyConstants,
    FlyFreelyError,
    parseErrorBody,
    SessionExpired,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import {
    catchError,
    Subject,
    switchMap,
    takeUntil,
    throwError,
    timer
} from 'rxjs';

@Injectable()
export class AccountAirspaceDataService {
    private baseUrl: string;

    private externalAuthorisationBearerToken: string;

    private http: HttpClient;
    private stopPollingAuthorisation$ = new Subject<void>();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private constants: FlyFreelyConstants,
        private userService: UserService,
        private httpHandler: HttpBackend
    ) {
        this.http = new HttpClient(httpHandler);
        // this.baseUrl = this.constants.SITE_URL;
        this.startPollingAuthenticationBearerToken();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.stopPollingAuthorisation$.next();
        this.stopPollingAuthorisation$.complete();
    }

    private startPollingAuthenticationBearerToken() {
        // attempt tp refresh the bearer token every 30 minutes
        timer(0, 1800000)
            .pipe(
                switchMap(() => this.getAuthenticationBearerToken()),
                takeUntil(this.stopPollingAuthorisation$)
            )
            .subscribe({
                next: token =>
                    (this.externalAuthorisationBearerToken = token.accessToken),
                error: (error: FlyFreelyError) => {
                    this.stopPollingAuthorisation$.next();
                    this.externalAuthorisationBearerToken = null;
                }
            });
    }

    private getAuthenticationBearerToken() {
        return this.userService.getAlternativeAuthorisationToken();
    }

    downloadAllUsersAuthorisations() {
        if (this.externalAuthorisationBearerToken == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.stopPollingAuthorisation$.next();
            this.startPollingAuthenticationBearerToken();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${this.externalAuthorisationBearerToken}`
        );
        return this.http
            .get(
                `https://dev-laanc.flyfreely.io/authorisations/USA_LAANC/authorisations`,
                {
                    headers: headers,
                    responseType: 'blob'
                }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return throwError(() => parseErrorBody(err));
                })
            );
    }
}
