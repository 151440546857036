import { Injectable } from '@angular/core';
import {
    FlightLogCriteria,
    FlyFreelyError,
    FlyFreelyLoggingService,
    GqlQueryResult,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Apollo, gql } from 'apollo-angular';
import { ColumnSortPreferences } from 'libs/flyfreely-table/src/lib/interfaces';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

const defaultSort: { field: string; order: 'ASC' | 'DESC' } = {
    field: 'startTime',
    order: 'DESC'
};

@Injectable()
export class BatteryHealthDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    // FIXME: type this properly
    private flightLogsSource = new ReplaySubject<any[]>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);
    private hasFlightLogsSource = new ReplaySubject<boolean>(1);

    working$ = this.workingSource.asObservable();
    flightLogs$ = this.flightLogsSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();
    hasFlightLogs$ = this.hasFlightLogsSource.asObservable();

    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private apollo: Apollo,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.flightLogsSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.hasFlightLogsSource.complete();
        this.changeSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private findFlightLogSummaries(
        organisationId: number,
        filters: FlightLogCriteria,
        sorting: { field: string; order: 'ASC' | 'DESC' },
        page: number,
        pageSize: number
    ) {
        const criteria = {
            organisationId,
            ...filters
        };
        return this.apollo
            .query<{
                // FIXME: type this properly
                findFlightLogFiles: GqlQueryResult<any>;
            }>({
                query: gql`
                    query findFlightLogFiles(
                        $criteria: FlightLogCriteria = { organisationId: 304 }
                        $sortFields: [FlightLogSortField]
                        $pageSize: Int
                        $page: Int
                    ) {
                        findFlightLogFiles(
                            criteria: $criteria
                            pageSize: $pageSize
                            page: $page
                            sortFields: $sortFields
                        ) {
                            count
                            results {
                                id
                                assignmentStatus
                                processedStatus
                                rpa {
                                    id
                                    nickname
                                }
                                suggestedMissionAssignment {
                                    mission {
                                        id
                                        name
                                        missionDate
                                        timeZone
                                    }
                                }
                                summary {
                                    startTime
                                    endTime
                                    batterySerialNumber
                                }
                                mission {
                                    id
                                    name
                                    missionDate
                                }
                                flight {
                                    id
                                    number
                                    pilot {
                                        id
                                    }
                                }
                                batteries {
                                    id
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    pageSize,
                    page,
                    sortFields: sorting != null ? [sorting] : []
                },
                fetchPolicy: 'network-only'
            })
            .pipe(map(flightLogs => flightLogs.data.findFlightLogFiles));
    }

    findFlightLogs(
        batteryId: number,
        batterySerialNumber: string,
        organisationId: number,
        page: number,
        limit: number,
        sorting?: ColumnSortPreferences,
        filters?: FlightLogCriteria
    ) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const sortFields: { field: string; order: 'ASC' | 'DESC' } =
            sorting != null
                ? {
                      field: sorting?.column ?? null,
                      order: sorting?.ascending ? 'ASC' : 'DESC'
                  }
                : defaultSort;

        this.findFlightLogSummaries(
            organisationId,
            filters,
            sortFields,
            page,
            limit
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                logs => {
                    // FIXME: type this properly
                    const flightLogs: any[] = logs.results.filter(
                        log =>
                            (batterySerialNumber != null &&
                                log.summary?.batterySerialNumber ===
                                    batterySerialNumber) ||
                            (log.batteries != null &&
                                log.batteries.filter(b => b.id === batteryId)
                                    .length > 0)
                    );
                    this.totalItemsSource.next(logs.count);
                    this.currentPageSource.next(page);
                    this.flightLogsSource.next(flightLogs);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            )
            .add(this.workTracker.createTracker());
    }
}
