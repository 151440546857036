<enhanced-help componentName="task-details" [compact]="true"></enhanced-help>

<div class="modal-body max-height">
    <div class="horizontal-container container-fluid">
        <div class="fcol-6">
            <h5 class="row">
                {{ generalTask?.summary }}
            </h5>
            <div class="task-details row form-group top-buffer">
                <label class="display-label">Details</label>
                <div class="task-details-content">
                    {{ generalTask?.description }}
                </div>
            </div>
            <div class="task-details row form-group top-buffer">
                <label class="display-label">Links & Attachments</label>
                <links-and-attachments-list
                    [attachmentList]="generalTask.attachmentList"
                    [linkList]="generalTask.linkList"
                    [downloadUrlFn]="downloadUrlFn"
                ></links-and-attachments-list>
            </div>
            <div class="task-details row form-group top-buffer">
                <label class="display-label">Comments</label>
                <ui-comments
                    *ngIf="generalTask && organisation"
                    [allowEdit]="false"
                    [comments]="generalTask.commentList"
                    [organisationId]="organisation.id"
                >
                </ui-comments>
            </div>
        </div>
        <div class="fcol-2"></div>
        <div class="fcol-4">
            <div class="row form-group top-buffer">
                <label class="display-label">Assignee</label>
                <div style="width: 200px">
                    <person-avatar
                        [person]="generalTask.assignee"
                        [managingOrganisationId]="organisation.id"
                        [width]="20"
                        [height]="20"
                    >
                    </person-avatar>
                </div>
            </div>
            <div class="row form-group top-buffer">
                <label class="display-label">Reporter</label>
                <div style="width: 200px">
                    <person-avatar
                        [person]="generalTask.reporter"
                        [managingOrganisationId]="organisation.id"
                        [width]="20"
                        [height]="20"
                    >
                    </person-avatar>
                </div>
            </div>
            <div class="row form-group top-buffer">
                <label class="display-label">Priority</label>
                <div>
                    {{ priorityOptions[generalTask.priority] }}
                </div>
            </div>
            <div class="row form-group top-buffer">
                <label class="display-label">Status</label>
                <div>
                    {{ statusOptions[generalTask.status] }}
                </div>
            </div>
            <div class="row form-group top-buffer">
                <label class="display-label">Due Date</label>
                <div>
                    {{ generalTask.dueDate }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        [disabled]="!canDelete || working"
        class="btn btn-danger pull-left"
        type="button"
        (click)="onDeleteTask()"
    >
        <span class="fal fa-trash-alt"></span>
        Delete
    </button>
    <button
        [disabled]="!canEdit || working"
        class="btn btn-primary"
        type="button"
        (click)="editTask()"
    >
        Edit
    </button>
</div>

<ngx-loading [show]="working"></ngx-loading>
