/**
 * This module contains the lat long and MGRS geocoders that allow the user to quickly zoom
 * to a coordinate.
 */
import * as mgrs from 'mgrs';
// import { Result } from 'ngx-mapbox-gl/lib/control/geocoder-control.directive';

function coordinateResult(
    id: string,
    name: string,
    latitude: number,
    longitude: number
): any {
    return {
        type: 'Feature',
        text: name,
        place_type: ['coordinate'],
        properties: {},
        bbox: null,
        place_name: name,
        id: id,
        center: [longitude, latitude],
        geometry: {
            type: 'Point',
            coordinates: [longitude, latitude]
        },
        relevance: null,
        address: null,
        context: null
    };
}

function makeLatLngName(latitude: number, longitude: number) {
    return `Lat: ${latitude} Long: ${longitude}`;
}

function latLongResult(id: string, coord1: number, coord2: number) {
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(
            coordinateResult(id, makeLatLngName(coord2, coord1), coord2, coord1)
        );
    }

    if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push(
            coordinateResult(id, makeLatLngName(coord1, coord2), coord1, coord2)
        );
    }

    if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(
            coordinateResult(id, makeLatLngName(coord1, coord2), coord1, coord2)
        );
        geocodes.push(
            coordinateResult(id, makeLatLngName(coord2, coord1), coord2, coord1)
        );
    }

    return geocodes;
}

export function spacialGeocoder(query: string): any[] {
    const latLongMatch = query.match(
        /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    if (latLongMatch != null && latLongMatch.length > 0) {
        const coord1 = Number(latLongMatch[1]);
        const coord2 = Number(latLongMatch[2]);
        const id = `latlng-${latLongMatch[1]}-${latLongMatch[2]}`;
        return latLongResult(id, coord1, coord2);
    }
    try {
        const mgrsMatch = query.match(/^[ ]*(?:MGRS:? )?([A-Za-z0-9]+)[ ]*$/i);
        if (mgrsMatch != null && mgrsMatch.length > 0) {
            const mgrsQuery = mgrsMatch[1].toUpperCase();
            const [lng, lat] = mgrs.inverse(mgrsQuery);
            return [
                coordinateResult(
                    `mgrs-${mgrsQuery}`,
                    `MGRS: ${mgrsQuery}`,
                    lat,
                    lng
                )
            ];
        }
    } catch {
        // do-nothing
    }
    return [];
}
