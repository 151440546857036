import { Injectable, NgZone } from '@angular/core';
import { Subject, fromEvent, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HubspotService {
    conversationStarted$ = new Subject();
    conversationClosed$ = new Subject();

    private hubspotConversationAvailableSubject = new ReplaySubject<boolean>(1);
    hubspotConversationAvailable$ = this.hubspotConversationAvailableSubject.asObservable();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(zone: NgZone) {
        if (this.isHubspotConversationsAvailable()) {
            this.hubspotConversationAvailableSubject.next(true);
            this.setupListeners();
        } else {
            this.hubspotConversationAvailableSubject.next(false);
            // @ts-ignore - no type sensing available
            window.hsConversationsOnReady = [
                () =>
                    zone.run(() => {
                        this.hubspotConversationAvailableSubject.next(true);
                        this.setupListeners();
                    })
            ];
        }
    }

    ngOnDestroy() {
        this.conversationStarted$.complete();
        this.conversationClosed$.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupListeners() {
        fromEvent(
            // @ts-ignore - no type sensing available
            window.HubSpotConversations,
            'conversationStarted'
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(e => this.conversationStarted$.next(e));

        fromEvent(
            // @ts-ignore - no type sensing available
            window.HubSpotConversations,
            'conversationClosed'
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(e => this.conversationClosed$.next(e));
    }

    isHubspotConversationsAvailable() {
        // @ts-ignore - no type sensing available
        return window.HubSpotConversations != null;
    }

    hideHubspotConversations() {
        if (this.isHubspotConversationsAvailable()) {
            // @ts-ignore - no type sensing available
            window.HubSpotConversations.widget.remove();
            // @ts-ignore - no type sensing available
        } else if (window.__DEV__) {
            console.log(`This would hide the Hubspot chat widgets`);
        }
    }

    showHubspotConversations() {
        if (this.isHubspotConversationsAvailable()) {
            // @ts-ignore - no type sensing available
            window.HubSpotConversations.widget.load();
            // @ts-ignore - no type sensing available
        } else if (window.__DEV__) {
            console.log(`This would show the Hubspot chat widgets`);
        }
    }
}
