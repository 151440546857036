<div class="modal-header no-icon">
    <h3 class="modal-title">
        {{
            mode === 'PURCHASE'
                ? 'Confirm Payment'
                : shouldPay
                ? 'Payment & Payment Details'
                : 'Update Payment Details'
        }}
    </h3>
    <button type="button" (click)="hide()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <ng-container *ngIf="mode === 'PURCHASE' || shouldPay">
            <table
                class="invoice"
                *ngIf="charges$ | async as charges; else loading"
            >
                <tbody>
                    <tr *ngFor="let item of charges.lineItems">
                        <th>{{ item.description }}</th>
                        <td>${{ item.amount.toFixed(2) }}</td>
                    </tr>
                    <tr class="subtotal">
                        <th>Subtotal</th>
                        <td>${{ charges.subTotal.toFixed(2) }}</td>
                    </tr>
                    <tr class="subtotal" *ngIf="currentDiscount > 0">
                        <th>Discount ({{ currentCoupon }})</th>
                        <td>${{ currentDiscount.toFixed(2) }}</td>
                    </tr>
                    <tr class="tax" *ngFor="let tax of charges.taxAmounts">
                        <th>{{ tax.name }} ({{ tax.percentage }}%)</th>
                        <td>${{ tax.amount.toFixed(2) }}</td>
                    </tr>
                    <tr class="total">
                        <th>
                            Total
                            {{
                                !subscriptionId
                                    ? '(due today)'
                                    : currentDueDate
                                    ? '(due ' +
                                      (currentDueDate.toString() | formatDate) +
                                      ')'
                                    : ''
                            }}
                        </th>
                        <th>${{ charges.total.toFixed(2) }}</th>
                    </tr>
                </tbody>
            </table>
            <div class="form-group top-buffer">
                <label>Coupon</label>
                <div class="input-group">
                    <input class="form-control" [formControl]="coupon" />
                    <span class="input-group-btn">
                        <button
                            type="button"
                            (click)="coupons.next(coupon.value)"
                            class="btn btn-primary"
                            [disabled]="working"
                        >
                            Apply
                        </button>
                    </span>
                </div>
                <validation-messages
                    [control]="coupon"
                    [customErrorMessage]="invalidCouponError"
                ></validation-messages>
            </div>
            <ng-template #loading
                ><loading-indicator [isLoading]="true"></loading-indicator
            ></ng-template>
        </ng-container>
        <h4
            class="section-heading"
            [ngStyle]="{ 'margin-top': !shouldPay ? '0' : 'inherit' }"
        >
            <span>Payment Details</span>
            <div></div>
        </h4>
        <ng-container
            *ngIf="existingPayment != null && existingPayment.type === 'card'"
        >
            <div class="radio">
                <label>
                    <input
                        type="radio"
                        name="optionsRadios"
                        [value]="true"
                        [formControl]="useExistingPaymentMethod"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                    Card ending in {{ existingPayment.cardLast4 }}
                    <em>
                        - Exp. {{ existingPayment.cardExpiryMonth }}/{{
                            existingPayment.cardExpiryYear
                        }}
                    </em>
                </label>
            </div>
            <div class="radio">
                <label>
                    <input
                        type="radio"
                        name="optionsRadios"
                        [value]="false"
                        [formControl]="useExistingPaymentMethod"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                    New card
                </label>
            </div>
        </ng-container>

        <div
            *ngIf="
                existingPayment != null &&
                (existingPayment.type === 'none' ||
                    !useExistingPaymentMethod.value)
            "
            @expandOnEnter
            @collapseOnLeave
        >
            <p *ngIf="paymentError != null" class="error">{{ paymentError }}</p>
            <formly-form
                *ngIf="cardFields != null"
                [form]="cardForm"
                [fields]="cardFields"
                [options]="options"
                [model]="cardData"
            ></formly-form>

            <div>
                <label>Card Information</label>
                <div class="horizontal-container fill">
                    <span class="fcol-9h">Card Number</span>
                    <span class="fcol-1h">Expiry</span>
                    <span class="fcol-1">CVC</span>
                </div>
                <ngx-stripe-card
                    [options]="cardOptions"
                    [stripe]="stripe"
                    (change)="onChange($event)"
                ></ngx-stripe-card>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            type="submit"
            [disabled]="
                working ||
                !valid ||
                (mode === 'PAYMENT_DETAILS' &&
                    !shouldPay &&
                    useExistingPaymentMethod.value) ||
                (!useExistingPaymentMethod.value && !cardForm.valid)
            "
        >
            {{
                mode === 'PURCHASE'
                    ? 'Pay Now'
                    : shouldPay
                    ? 'Update & Pay Now'
                    : 'Update'
            }}
        </button>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</form>
