import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    AddressDto,
    AddSharedLicencesCommand,
    AssignLicenceCommand,
    AvailableSubscriptionTiers,
    ChargesDto,
    CustomerDetailsDto,
    FeatureSetDto,
    ModifySubscriptionCommand,
    OrganisationAuthorityGroup,
    OrganisationRelationshipDto,
    OrganisationSubscriptionDto,
    PaySubscriptionCommand,
    PurchaseSubscriptionCommand,
    PurchaseSubscriptionViaCheckoutCommand,
    RemoveSharedLicencesCommand,
    ResultDto,
    SubscriptionDetailsDto,
    SubscriptionSetupOutcomeDto,
    UnassignLicenceCommand
} from '../model/api';
import { httpParamSerializer } from './service.helpers';

export interface CurrentSubscription {
    subscriptionType: FeatureSetDto;
    startDate: string;
}

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    private refreshLicencesSource = new Subject<void>();
    refreshLicences$ = this.refreshLicencesSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
        this.refreshLicencesSource.complete();
    }

    findById(subscriptionId: number) {
        return this.http.get<OrganisationSubscriptionDto>(
            `${this.baseUrl}/webapi/subscriptions/${subscriptionId}`
        );
    }

    findCurrentSubscriptions(organisationId: number) {
        return this.http.get<OrganisationSubscriptionDto[]>(
            `${this.baseUrl}/webapi/subscriptions`,
            { params: httpParamSerializer({ organisationId }) }
        );
    }

    findSharedSubscriptions(organisationId: number) {
        return this.http.get<OrganisationRelationshipDto[]>(
            `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/shareLicences`
        );
    }

    findAvailableSubscriptions(organisationId: number) {
        return this.http.get<AvailableSubscriptionTiers[]>(
            `${this.baseUrl}/webapi/subscriptions/available`,
            { params: httpParamSerializer({ organisationId }) }
        );
    }

    trialUpSubscription(subscriptionId: number) {
        return this.http.post<OrganisationSubscriptionDto>(
            `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/trialUp`,
            null
        );
    }

    purchaseSubscriptionViaCheckout(
        command: PurchaseSubscriptionViaCheckoutCommand
    ) {
        return this.http.post<ResultDto>(
            `${this.baseUrl}/webapi/subscriptions/checkout`,
            command
        );
    }

    purchaseSubscription(command: PurchaseSubscriptionCommand) {
        return this.http.post<SubscriptionSetupOutcomeDto>(
            `${this.baseUrl}/webapi/subscriptions`,
            command
        );
    }

    payExistingSubscription(
        subscriptionId: number,
        command: PaySubscriptionCommand
    ) {
        return this.http.post<SubscriptionSetupOutcomeDto>(
            `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/pay`,
            command
        );
    }

    /**
     * This function allows an organisation to add shared licences from their subscription to a child organisation.
     * This call will also start sharing the subscription if the child organisation is not sharing the subscription already.
     * @param organisationId the parent organisation that wishes to share licences
     * @param relationshipId The ID for the relationship between the parent and the child
     * @param command The shared licence command
     * @returns The shared subscription details
     */
    assignSharedLicences(
        organisationId: number,
        relationshipId: number,
        command: AddSharedLicencesCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/shareLicences`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    assignLicence(subscriptionId: number, command: AssignLicenceCommand) {
        return this.http
            .put<OrganisationSubscriptionDto>(
                `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/licences/assign`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    /**
     * This function allows an organisation to remove shared licences from their subscription to a child organisation.
     * This call can also stop the subscription sharing using the removeSharedSubscription flag.
     * This flag would also allow a parent org to stop sharing licences with a child, but keep sharing the subscription.
     * @param organisationId the parent organisation that owns the subscription and licences
     * @param relationshipId The ID for the relationship between the parent and the child
     * @param command The shared licence command. NB - also state whether the subscription needs to continue to be shared
     * @returns The shared subscription details
     */
    removeSharedLicences(
        organisationId: number,
        relationshipId: number,
        command: RemoveSharedLicencesCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/unshareLicences`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    unassignLicence(subscriptionId: number, command: UnassignLicenceCommand) {
        return this.http
            .put<OrganisationSubscriptionDto>(
                `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/licences/unassign`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findCustomerDetails(organisationId: number) {
        return this.http.get<CustomerDetailsDto>(
            `${this.baseUrl}/webapi/subscriptions/customer`,
            { params: httpParamSerializer({ organisationId }) }
        );
    }

    findSubscriptionDetails(subscriptionId: number) {
        return this.http.get<SubscriptionDetailsDto>(
            `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/details`
        );
    }

    cancelSubscription(subscriptionId: number) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/cancel`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    uncancelSubscription(subscriptionId: number) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/uncancel`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    previewCharge(command: ModifySubscriptionCommand) {
        return this.http.post<ChargesDto>(
            `${this.baseUrl}/webapi/subscriptions/preview`,
            command
        );
    }

    previewSubscriptionChange(
        subscriptionId: number,
        command: ModifySubscriptionCommand
    ) {
        return this.http.post<ChargesDto>(
            `${this.baseUrl}/webapi/subscriptions/${subscriptionId}/preview`,
            command
        );
    }

    updateSubscription(
        subscriptionId: number,
        command: ModifySubscriptionCommand
    ) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/subscriptions/${subscriptionId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    getSelfServeUrl(organisationId: number) {
        return `${this.baseUrl}/webapi/subscriptions/selfServe?organisationId=${organisationId}`;
    }

    findSelfServeUrl(organisationId: number) {
        return this.http
            .get<ResultDto>(this.getSelfServeUrl(organisationId))
            .pipe(map(resp => resp.value));
    }

    findBillingAddress(organisationId: number) {
        return this.http.get<AddressDto>(
            `${this.baseUrl}/webapi/subscriptions/address?organisationId=${organisationId}`
        );
    }

    findTaxAuthorities(
        organisationId: number,
        countryCode: string = undefined
    ) {
        if (countryCode == null) {
            return this.http.get<OrganisationAuthorityGroup[]>(
                `${this.baseUrl}/webapi/subscriptions/authorities`,
                { params: httpParamSerializer({ organisationId }) }
            );
        } else {
            return this.http.get<OrganisationAuthorityGroup[]>(
                `${this.baseUrl}/webapi/subscriptions/authorities/preview`,
                { params: httpParamSerializer({ organisationId, countryCode }) }
            );
        }
    }

    refreshLicences() {
        this.refreshLicencesSource.next();
    }
}
