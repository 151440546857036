import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@blox/material';
import { AirspaceModule } from '@flyfreely-portal-ui/airspace';
import { UserLocationService } from '@flyfreely-portal-ui/flyfreely';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { SharedUiModule } from '@flyfreely-portal-ui/ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { AddressesModule } from 'libs/addresses/src/lib/addresses.module';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { ApiKeysModule } from 'libs/api-keys/src/lib/api-keys.module';
import { OrganisationAuthorityModule } from 'libs/authorities/src/lib/organisation-authority/organisation-authority.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlightHistoryModule } from 'libs/flight-history/src/lib/flight-history.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { PersonnelModule } from 'libs/personnel/src/lib/personnel.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { UpdateAirServicesDialogue } from './air-services/update-air-services-dialogue.component';
import { ApiKeysComponent } from './api-keys/api-keys.component';
import { PendingInvites } from './details/pending-invites/pending-invites.component';
import { ProfileDetails } from './details/profile-details.component';
import { FlightHistory } from './flight-history/profile-flight-history.component';
import { ProfileMarketingPreferences } from './notification-settings/marketing-preferences/marketing-preferences.component';
import { ProfileNotificationSettings } from './notification-settings/notification-settings.component';
import { ProfileDialogue } from './profile-dialogue.component';
import { ProfileDialoguesService } from './profile-dialogues.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        ModalModule.forChild(),
        TabsModule,
        ReactiveFormsModule,
        ValidationModule,
        FormlyModule,
        FormlyBootstrapModule,
        NgSelectModule,
        ToastrModule,
        SharedUiModule,
        AddressesModule,
        OrganisationAuthorityModule,
        PersonnelModule,
        FlightHistoryModule,
        ApiKeysModule,
        LoadingIndicatorModule,
        AnalyticsModule,
        EnhancedHelpModule,
        MaterialModule,
        NgxLoadingModule,
        FlyFreelyTableModule,
        AirspaceModule
    ],
    bootstrap: [],
    declarations: [
        ProfileDialogue,
        ProfileDetails,
        FlightHistory,
        PendingInvites,
        ApiKeysComponent,
        ProfileNotificationSettings,
        ProfileMarketingPreferences,
        UpdateAirServicesDialogue
    ],
    providers: [ProfileDialoguesService, UserLocationService],
    exports: [RouterModule]
})
export class UserProfileModule {}
