/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationFeatureDto } from './locationFeatureDto';


/**
 * Used to create a location
 */
export interface CreateLocationCommand { 
    /**
     * The ID of the owning organisation
     */
    organisationId: number;
    /**
     * The name given to this location
     */
    name: string;
    /**
     * ${swagger.Location.type}
     */
    type: CreateLocationCommand.Type;
    /**
     * The ID of the location that this location is based on
     */
    derivedFromId: number;
    /**
     * The feature areas of the location
     */
    features: Array<LocationFeatureDto>;
}
export namespace CreateLocationCommand {
    export type Type = 'MISSION' | 'TEMPLATE';
    export const Type = {
        MISSION: 'MISSION' as Type,
        TEMPLATE: 'TEMPLATE' as Type
    };
}


