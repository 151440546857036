import { Injectable } from '@angular/core';
import {
    CraftDto,
    MissionOperationTypeDto,
    SimpleMissionWorkflowDto,
    SimplePersonDto
} from '@flyfreely-portal-ui/flyfreely';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface WorkGroup {
    id: number;
    name: string;
    personnelList: SimplePersonDto[];
    rpaList: Pick<CraftDto, 'id' | 'nickname'>[];
    workflowList: SimpleMissionWorkflowDto[];
    defaultMissionWorkflow: SimpleMissionWorkflowDto;
    defaultOperationType: MissionOperationTypeDto;
    defaultMaximumHeight: number;
}

@Injectable({ providedIn: 'root' })
export class WorkGroupsService {
    constructor(private apollo: Apollo) {}

    findWorkgroups(organisationId: number) {
        return this.apollo
            .query<{
                findWorkgroups: WorkGroup[];
            }>({
                query: gql`
                    query findWorkgroups($organisationId: Int!) {
                        findWorkgroups(organisationId: $organisationId) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                            defaultMissionWorkflow {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            defaultOperationType {
                                id
                                name
                                description
                                active
                                selectable
                                coreType
                            }
                            defaultMaximumHeight
                        }
                    }
                `,
                variables: {
                    organisationId
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findWorkgroups)
            );
    }

    createWorkgroup(
        organisationId: number,
        name: string
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                createWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation createWorkgroup(
                        $name: String!
                        $organisationId: Int!
                    ) {
                        createWorkgroup(
                            name: $name
                            organisationId: $organisationId
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            defaultMissionWorkflow {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            defaultOperationType {
                                id
                                name
                                description
                                active
                                selectable
                                coreType
                            }
                            defaultMaximumHeight
                        }
                    }
                `,
                variables: {
                    name,
                    organisationId
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.createWorkgroup != null) {
                        return result.data.createWorkgroup;
                    }
                    throw new Error('Create workgroup mutation failed');
                })
            );
    }

    renameWorkgroup(workgroupId: number, name: string): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                renameWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation renameWorkgroup(
                        $workgroupId: Int!
                        $name: String!
                    ) {
                        renameWorkgroup(
                            workgroupId: $workgroupId
                            name: $name
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    name
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.renameWorkgroup != null) {
                        return result.data.renameWorkgroup;
                    }
                    throw new Error('Rename workgroup mutation failed');
                })
            );
    }

    addPersonToWorkgroup(
        workgroupId: number,
        personId: number
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                addPersonToWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation addPersonToWorkgroup(
                        $workgroupId: Int!
                        $personId: Int!
                    ) {
                        addPersonToWorkgroup(
                            workgroupId: $workgroupId
                            personId: $personId
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    personId
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.addPersonToWorkgroup != null) {
                        return result.data.addPersonToWorkgroup;
                    }
                    throw new Error('Add person to workgroup mutation failed');
                })
            );
    }

    removePersonFromWorkgroup(
        workgroupId: number,
        personId: number
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                removePersonFromWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation removePersonFromWorkgroup(
                        $workgroupId: Int!
                        $personId: Int!
                    ) {
                        removePersonFromWorkgroup(
                            workgroupId: $workgroupId
                            personId: $personId
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    personId
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.removePersonFromWorkgroup != null) {
                        return result.data.removePersonFromWorkgroup;
                    }
                    throw new Error(
                        'Remove person from workgroup mutation failed'
                    );
                })
            );
    }

    addRpaToWorkgroup(
        workgroupId: number,
        rpaId: number
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                addRpaToWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation addRpaToWorkgroup(
                        $workgroupId: Int!
                        $rpaId: Int!
                    ) {
                        addRpaToWorkgroup(
                            workgroupId: $workgroupId
                            rpaId: $rpaId
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    rpaId
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.addRpaToWorkgroup != null) {
                        return result.data.addRpaToWorkgroup;
                    }
                    throw new Error('Add RPA to workgroup mutation failed');
                })
            );
    }

    removeRpaFromWorkgroup(
        workgroupId: number,
        rpaId: number
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                removeRpaFromWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation removeRpaFromWorkgroup(
                        $workgroupId: Int!
                        $rpaId: Int!
                    ) {
                        removeRpaFromWorkgroup(
                            workgroupId: $workgroupId
                            rpaId: $rpaId
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    rpaId
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.removeRpaFromWorkgroup != null) {
                        return result.data.removeRpaFromWorkgroup;
                    }
                    throw new Error(
                        'Remove RPA from workgroup mutation failed'
                    );
                })
            );
    }

    addWorkflowToWorkgroup(
        workgroupId: number,
        workflowId: number
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                addWorkflowToWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation addWorkflowToWorkgroup(
                        $workgroupId: Int!
                        $workflowId: Int!
                    ) {
                        addWorkflowToWorkgroup(
                            workgroupId: $workgroupId
                            workflowId: $workflowId
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    workflowId
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.addWorkflowToWorkgroup != null) {
                        return result.data.addWorkflowToWorkgroup;
                    }
                    throw new Error(
                        'Add workflow to workgroup mutation failed'
                    );
                })
            );
    }

    removeWorkflowFromWorkgroup(
        workgroupId: number,
        workflowId: number
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                removeWorkflowFromWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation removeWorkflowFromWorkgroup(
                        $workgroupId: Int!
                        $workflowId: Int!
                    ) {
                        removeWorkflowFromWorkgroup(
                            workgroupId: $workgroupId
                            workflowId: $workflowId
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    workflowId
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.removeWorkflowFromWorkgroup != null) {
                        return result.data.removeWorkflowFromWorkgroup;
                    }
                    throw new Error(
                        'Remove workflow from workgroup mutation failed'
                    );
                })
            );
    }

    updateDefaultWorkgroupSettings(
        workgroupId: number,
        defaultMissionWorkflowId: number,
        defaultOperationType: number,
        defaultMaximumHeight: number
    ): Observable<WorkGroup> {
        return this.apollo
            .mutate<{
                updateWorkgroup: WorkGroup;
            }>({
                mutation: gql`
                    mutation updateWorkgroup(
                        $workgroupId: Int!
                        $defaultMissionWorkflowId: Int
                        $defaultOperationType: Int
                        $defaultMaximumHeight: Int
                    ) {
                        updateWorkgroup(
                            workgroupId: $workgroupId
                            defaultMissionWorkflowId: $defaultMissionWorkflowId
                            defaultOperationType: $defaultOperationType
                            defaultMaximumHeight: $defaultMaximumHeight
                        ) {
                            id
                            name
                            archived
                            personnelList {
                                id
                                firstName
                                lastName
                            }
                            rpaList {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                                organisation {
                                    id
                                    name
                                }
                            }
                            workflowList {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            availableActions {
                                canEdit
                            }
                            defaultMissionWorkflow {
                                id
                                name
                                activeVersionId
                                creationTime
                                modificationTime
                                archived
                            }
                            defaultOperationType {
                                id
                                name
                                description
                                active
                                selectable
                                coreType
                            }
                            defaultMaximumHeight
                        }
                    }
                `,
                variables: {
                    workgroupId,
                    defaultMissionWorkflowId,
                    defaultOperationType,
                    defaultMaximumHeight
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.updateWorkgroup != null) {
                        return result.data.updateWorkgroup;
                    }
                    throw new Error(
                        'Error while updating default workgroup settings'
                    );
                })
            );
    }
}
