<ng-container screenAnalytics="mission-edit-v2-dialogue">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-missions.png" />
        </div>
        <h3 *ngIf="!isCreated" class="modal-title">
            {{ savedMission?.id ? 'Edit' : 'Add' }} Mission Plan
        </h3>
        <h3 *ngIf="isCreated" class="modal-title">
            {{ savedMission?.name }}
        </h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form
        *ngIf="missionForm"
        [formGroup]="missionForm"
        (ngSubmit)="submit()"
        novalidate
    >
        <div class="modal-body mission inner-container-and-footer">
            <div
                class="modal-body-content container-with-navigation"
                [ngClass]="{ 'no-scroll': locked }"
                scrollSpy
                [spiedTags]="['DIV']"
                [spiedContainer]="content"
                (sectionChange)="onSectionChange($event)"
            >
                <!-- Menu left -->
                <div
                    class="container-menu vertical-container"
                    style="max-height: 100%; min-height: 100%"
                >
                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['objectives']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button
                            type="button"
                            (click)="scrollTo('objectives')"
                            [class.disabled]="locked"
                        >
                            <i class="far fa-list-check tab-title-icon"></i>
                            Objectives
                        </button>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['resources']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button
                            type="button"
                            (click)="scrollTo('resources')"
                            [class.disabled]="
                                !missionObjectivesGroup.valid || locked
                            "
                        >
                            <i class="far fa-book-sparkles tab-title-icon"></i>
                            Resources
                        </button>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['planning']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button
                            type="button"
                            (click)="scrollTo('planning')"
                            [class.disabled]="!isResourcesCompleted || locked"
                        >
                            <i class="far fa-ruler-combined tab-title-icon"></i>
                            Planning
                        </button>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['documentation']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button
                            type="button"
                            (click)="scrollTo('documentation')"
                            [class.disabled]="!isResourcesCompleted || locked"
                        >
                            <i class="far fa-file tab-title-icon"></i>
                            Documentation
                        </button>
                    </div>

                    <div
                        *ngIf="
                            !canCheckAirspace &&
                            airspaceAuthorisationsEnabled &&
                            userCanViewAirspaceAuthorisation &&
                            !showInlineAuthorisation
                        "
                        class="item"
                    >
                        <div class="indicator">
                            <span
                                *ngIf="isActive['authorisation']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <airspace-authorisation-link
                            [canRequest]="
                                userCanRequestAirspaceAuthorisation &&
                                isResourcesCompleted &&
                                savedMission?.id != null &&
                                missionWorkflowVersionId.value != null &&
                                savedMission?.missionWorkflowVersionId ==
                                    missionWorkflowVersionId.value &&
                                !locked &&
                                airspaceAuthorisationsEnabled
                            "
                            [working]="working"
                            [disabled]="
                                missionObjectivesGroup.controls.isDummy.value
                            "
                            (linkClicked)="scrollTo('authorisation')"
                            (requestAuthorisation)="
                                latestAuthorisationStatus == 'CANCELLED'
                                    ? requestNewAirspaceAuthorisation()
                                    : showAirspaceAuthorisation()
                            "
                        ></airspace-authorisation-link>
                    </div>

                    <ng-container *ngIf="canCheckAirspace">
                        <airspace-check
                            #airspaceChecker
                            screenIdentifier="mission-edit-v2"
                        ></airspace-check>
                    </ng-container>
                </div>

                <!-- Content -->
                <div
                    #content
                    class="container-content form-horizontal"
                    tabindex="0"
                >
                    <div style="padding: 0 15px">
                        <enhanced-help
                            class="no-min-height"
                            screenName="enhanced-help-help"
                            componentName="main"
                        >
                        </enhanced-help>
                    </div>

                    <div id="objectives">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{ readonly: working || locked }"
                            >
                                <span>MISSION OBJECTIVES </span>
                                <div></div>
                            </h4>

                            <ng-container
                                formGroupName="missionObjectivesGroup"
                            >
                                <div class="form-group input-content">
                                    <div class="col-xs-3 text-right">
                                        <label
                                            class="control-label"
                                            [ngClass]="{
                                                readonly: working || locked
                                            }"
                                        >
                                            Mission Name
                                            <field-validation-indicator
                                                validatorFormControlName="name"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <input
                                            type="text"
                                            placeholder="Type a name for your new mission"
                                            formControlName="name"
                                            class="form-control"
                                            minlength="3"
                                            (ngModelChange)="
                                                stepCompleted('missionName')
                                            "
                                            (focus)="setStep('missionName')"
                                            [readonly]="working || locked"
                                        />
                                    </div>
                                    <div class="col-xs-3"></div>
                                </div>
                                <enhanced-help componentName="name">
                                </enhanced-help>

                                <ng-container
                                    *ngIf="
                                        canUseWorkgroup &&
                                        (workgroup$ | async)?.length !== 0
                                    "
                                >
                                    <div class="form-group input-content">
                                        <div class="col-xs-3 text-right">
                                            <label
                                                class="control-label"
                                                [ngClass]="{
                                                    readonly: working || locked
                                                }"
                                            >
                                                Workgroup
                                                <field-validation-indicator
                                                    validatorFormControlName="workgroup"
                                                    noun="field"
                                                    placement="right"
                                                ></field-validation-indicator>
                                            </label>
                                        </div>
                                        <div class="col-xs-6">
                                            <ng-select
                                                name="workgroup"
                                                formControlName="workgroup"
                                                placeholder="Select"
                                                [items]="workgroup$ | async"
                                                [readonly]="working || locked"
                                                [clearable]="true"
                                                appendTo="body"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    ng-label-tmp
                                                    let-item="item"
                                                    ><span
                                                        class="ng-option-label"
                                                        [title]="item.name"
                                                    >
                                                        {{ item.name }}</span
                                                    >
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div class="col-xs-3"></div>
                                    </div>
                                    <enhanced-help
                                        componentName="missionWorkgroup"
                                    >
                                    </enhanced-help>
                                </ng-container>

                                <div class="form-group input-content">
                                    <div class="col-xs-3 text-right">
                                        <label
                                            class="control-label"
                                            [ngClass]="{
                                                readonly: working || locked
                                            }"
                                            >Mission Classification
                                            <field-validation-indicator
                                                validatorFormControlName="isDummy"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <div
                                            class="input-group"
                                            btnRadioGroup
                                            formControlName="isDummy"
                                            style="padding-top: 7px"
                                        >
                                            <label
                                                class="btn btn-xs btn-pill"
                                                [btnRadio]="false"
                                                [disabled]="
                                                    working ||
                                                    lockedFields?.isDummy ||
                                                    locked
                                                "
                                                >Real Mission</label
                                            >
                                            <label
                                                class="btn btn-xs btn-pill"
                                                [btnRadio]="true"
                                                [disabled]="
                                                    working ||
                                                    lockedFields?.isDummy ||
                                                    locked
                                                "
                                                >Dummy Mission</label
                                            >
                                        </div>
                                    </div>

                                    <div class="col-xs-3"></div>
                                </div>

                                <enhanced-help componentName="isDummy">
                                </enhanced-help>

                                <div
                                    class="form-group input-content"
                                    *ngIf="canUseObjectives"
                                >
                                    <div class="col-xs-3 text-right">
                                        <label
                                            class="control-label"
                                            [ngClass]="{
                                                readonly: working || locked
                                            }"
                                        >
                                            Mission Objective
                                            <field-validation-indicator
                                                validatorFormControlName="missionObjectiveTypeId"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <ng-select
                                            name="missionObjectiveTypeId"
                                            formControlName="missionObjectiveTypeId"
                                            bindValue="id"
                                            bindLabel="name"
                                            placeholder="Select a mission objective for classifying this mission"
                                            [items]="missionObjectives"
                                            [readonly]="working || locked"
                                            [clearable]="true"
                                            appendTo="body"
                                        >
                                            <ng-template
                                                ng-option-tmp
                                                let-item="item"
                                                let-index="index"
                                            >
                                                {{ item.name }}
                                            </ng-template>
                                            <ng-template
                                                ng-label-tmp
                                                let-item="item"
                                                let-index="index"
                                            >
                                                {{ item.name }}
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="col-xs-3"></div>
                                </div>

                                <enhanced-help
                                    *ngIf="canUseObjectives"
                                    componentName="missionObjectiveTypeId"
                                >
                                </enhanced-help>

                                <div class="form-group input-content">
                                    <div class="col-xs-3 text-right">
                                        <label
                                            class="control-label"
                                            [ngClass]="{
                                                readonly: working || locked
                                            }"
                                            >Mission Objective Description
                                            <field-validation-indicator
                                                validatorFormControlName="missionObjective"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <textarea
                                            class="form-control"
                                            rows="10"
                                            formControlName="missionObjective"
                                            placeholder="A list of instructions or objectives for the mission..."
                                            [autofocus]="isCreated"
                                            (ngModelChange)="
                                                stepCompleted(
                                                    'missionObjective'
                                                )
                                            "
                                            (focus)="
                                                setStep('missionObjective')
                                            "
                                            [readonly]="working || locked"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="col-xs-3"></div>
                                </div>

                                <enhanced-help componentName="missionObjective">
                                </enhanced-help>

                                <div class="form-group input-content">
                                    <div class="col-xs-3 text-right">
                                        <label
                                            class="control-label"
                                            [ngClass]="{
                                                readonly: working || locked
                                            }"
                                        >
                                            Mission Location
                                            <field-validation-indicator
                                                validatorFormControlName="location"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <mission-location-selection
                                            [locked]="locked"
                                            [working]="working"
                                        ></mission-location-selection>
                                    </div>
                                    <div class="col-xs-3"></div>
                                </div>

                                <enhanced-help componentName="locationId">
                                </enhanced-help>
                            </ng-container>

                            <div class="input-content">
                                <flyfreely-map
                                    #map
                                    airspaceCheckResult
                                    airspaceAuthorisations
                                    style="height: 500px"
                                    [organisationId]="organisation.id"
                                    [features]="locationFeatures$ | async"
                                    [showFeatureSelection]="true"
                                    [requireCtrlToZoom]="true"
                                    [startTime]="missionStartTime"
                                    [endTime]="missionEndTime"
                                    [mapData]="mapData$ | async"
                                    [ruleset]="returnMissionRuleset()"
                                    [enableLayerGroupsByDefault]="['NOTAM']"
                                    (featuresLoaded)="
                                        map.zoomToAllFeatures(false)
                                    "
                                    (fullscreenRequested)="
                                        onFullscreenRequested()
                                    "
                                ></flyfreely-map>
                                <div>
                                    <span class="display-label">Centre: </span
                                    ><span
                                        [innerHtml]="
                                            (locationBearings?.locationCentre
                                                ?.coordinates)[1]
                                                | formatLatitude
                                        "
                                    ></span
                                    ><span [innerHtml]="',&nbsp;'"></span
                                    ><span
                                        [innerHtml]="
                                            (locationBearings?.locationCentre
                                                ?.coordinates)[0]
                                                | formatLongitude
                                        "
                                    ></span
                                    ><span [innerHtml]="'&nbsp;'"></span
                                    ><span class="display-label">Area: </span>
                                    {{
                                        locationBearings?.locationArea
                                            | number: '1.0-0'
                                    }}
                                    m<sup>2</sup>
                                </div>
                            </div>
                            <ng-container
                                *ngIf="
                                    isUsingAerodromes &&
                                    location?.airspaceJurisdiction
                                        ?.hasAerodromeData
                                "
                            >
                                <div class="form-group">
                                    <div class="col-xs-3 text-right">
                                        <label class="control-label">
                                            <button
                                                type="button"
                                                class="btn-circle text-center"
                                                [disabled]="working || locked"
                                                (click)="showAerodromeSearch()"
                                            >
                                                <span class="fa fa-plus"></span>
                                            </button>
                                            Nearby Aerodromes
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="horizontal-container">
                                            <ng-container
                                                formArrayName="aerodromesOfInterest"
                                            >
                                                <div
                                                    *ngFor="
                                                        let contact of aerodromesOfInterest.controls;
                                                        let i = index
                                                    "
                                                >
                                                    <div
                                                        class="horizontal-container"
                                                    >
                                                        <contact-pill
                                                            [formControlName]="
                                                                i
                                                            "
                                                            [clickable]="true"
                                                            (removeItem)="
                                                                removeAirport(i)
                                                            "
                                                            (click)="
                                                                showAerodromeSearch(
                                                                    i
                                                                )
                                                            "
                                                        ></contact-pill>
                                                        <div
                                                            class="vertical-align pill-container no-wrap"
                                                            *ngIf="
                                                                (locationBearings
                                                                    ?.aerodromes)[
                                                                    i
                                                                ] != null
                                                            "
                                                        >
                                                            <span
                                                                [innerHtml]="
                                                                    (locationBearings
                                                                        ?.aerodromes)[
                                                                        i
                                                                    ] | bearing
                                                                "
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <enhanced-help componentName="nearbyAerodromes">
                                </enhanced-help>

                                <ng-container *ngIf="canUseNotams">
                                    <div class="form-group">
                                        <div class="col-xs-3 text-right">
                                            <label class="control-label">
                                                <button
                                                    type="button"
                                                    class="btn-circle text-center"
                                                    [disabled]="
                                                        working || locked
                                                    "
                                                    (click)="showNotamSearch()"
                                                >
                                                    <span
                                                        class="fa fa-plus"
                                                    ></span>
                                                </button>
                                                Mission NOTAMs
                                            </label>
                                        </div>
                                        <div class="col-xs-6">
                                            <div
                                                *ngIf="
                                                    declareNoRelevantNotam.value ==
                                                    true
                                                "
                                                class="top-buffer"
                                            >
                                                No relevant NOTAM for
                                                mission.&nbsp;
                                                <em
                                                    *ngIf="
                                                        savedMission.noNotamDeclarationPerson !=
                                                        null
                                                    "
                                                >
                                                    (Declared by
                                                    {{
                                                        savedMission.noNotamDeclarationPerson
                                                            | formatPerson
                                                    }}
                                                    on
                                                    {{
                                                        savedMission.noNotamDeclarationTime
                                                            | formatDate
                                                    }}
                                                    )
                                                </em>
                                            </div>
                                            <div
                                                class="vertical-container"
                                                *ngIf="
                                                    missionForm.controls.notams
                                                        .value.length > 0
                                                "
                                            >
                                                <!-- <ng-container
                                                    formArrayName="aerodromesOfInterest"
                                                > -->
                                                <div
                                                    *ngFor="
                                                        let notam of missionForm
                                                            .controls.notams
                                                            .value;
                                                        let i = index
                                                    "
                                                >
                                                    <div
                                                        class="horizontal-container"
                                                    >
                                                        <notam-details
                                                            style="flex: 1"
                                                            [notam]="notam"
                                                            [listOnly]="true"
                                                            [viewNotamDetailModal]="
                                                                viewNotamDetailModal
                                                            "
                                                            (deleteNotam)="
                                                                removeSearchNotam(
                                                                    notam
                                                                )
                                                            "
                                                        ></notam-details>

                                                        <div
                                                            style="
                                                                line-height: 37px;
                                                            "
                                                            class="no-wrap"
                                                            *ngIf="
                                                                (locationBearings
                                                                    ?.notams)[
                                                                    i
                                                                ] != null
                                                            "
                                                        >
                                                            <span
                                                                [innerHtml]="
                                                                    (locationBearings
                                                                        ?.notams)[
                                                                        i
                                                                    ] | bearing
                                                                "
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- </ng-container> -->
                                            </div>
                                        </div>
                                    </div>
                                    <enhanced-help componentName="notams">
                                    </enhanced-help>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="canCheckAirspace" id="authorisations">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <span>WARNINGS AND BLOCKS</span>
                                <div></div>
                            </h4>
                            <inline-airspace-warnings-and-blockers></inline-airspace-warnings-and-blockers>
                        </div>
                        <div
                            *ngIf="
                                airspaceAuthorisationsEnabled &&
                                showInlineAuthorisation
                            "
                            id="authorisation"
                            class="vertical-container container-fluid"
                        >
                            <h4
                                class="section-heading"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <span>AUTHORISATION</span>
                                <div></div>
                            </h4>
                            <div class="fill">
                                <inline-authorisation
                                    [areAuthorisationRequirementsComplete]="
                                        areAuthorisationRequirementsComplete
                                    "
                                    [missionWorking]="working"
                                    [missionTimeZone]="location.timeZone"
                                    [currentUserCanRequestAuthorisation]="
                                        userCanRequestAirspaceAuthorisation
                                    "
                                    [currentUserCanViewAuthorisation]="
                                        userCanViewAirspaceAuthorisation
                                    "
                                    (onShowAirspaceAuthorisation)="
                                        showAirspaceAuthorisation($event)
                                    "
                                >
                                </inline-authorisation>
                                <!-- <airspace-authorisation-status
                                            [checkingAuthorisation]="
                                                checkingAuthorisation
                                            "
                                            [enabledAuthorisationTypes]="
                                                enabledAuthorisationTypes
                                            "
                                            [areAuthorisationRequirementsComplete]="
                                                areAuthorisationRequirementsComplete
                                            "
                                            [approvedAirspaceAuthorisation]="
                                                latestAuthorisation
                                            "
                                            [jurisdiction]="
                                                location?.airspaceJurisdiction
                                            "
                                            [hasRuleset]="
                                                returnMissionRuleset() !=
                                                null
                                            "
                                            [disabled]="
                                                missionObjectivesGroup
                                                    .controls.isDummy
                                                    .value ||
                                                loadingWorkflow
                                            "
                                            [isDummy]="
                                                missionObjectivesGroup
                                                    .controls.isDummy
                                                    .value
                                            "
                                            (showAirspaceAuthorisation)="
                                                showAirspaceAuthorisation()
                                            "
                                            (cancelAirspaceAuthorisation)="
                                                cancelAirspaceAuthorisation()
                                            "
                                            (requestNewAirspaceAuthorisation)="
                                                requestNewAirspaceAuthorisation()
                                            "
                                        ></airspace-authorisation-status> -->
                            </div>
                        </div>
                    </div>

                    <div id="resources">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <span>MISSION RESOURCES</span>
                                <div></div>
                            </h4>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Mission Type
                                        <field-validation-indicator
                                            validatorFormControlName="missionTypeId"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>

                                <div class="col-xs-6" style="height: 34px">
                                    <lockable
                                        [isLocked]="lockedFields?.missionType"
                                        message="This mission has been created for a specific purpose and can not be changed"
                                    >
                                        <ng-template lockedMode>
                                            {{
                                                mission.missionType?.name
                                            }}</ng-template
                                        >
                                        <ng-template unlockedMode
                                            ><ng-select
                                                name="missionTypeId"
                                                formControlName="missionTypeId"
                                                bindValue="id"
                                                bindLabel="name"
                                                placeholder="Select the type of operation"
                                                [items]="missionTypes"
                                                [readonly]="
                                                    working ||
                                                    lockedFields?.missionType ||
                                                    !missionObjectivesGroup.valid ||
                                                    locked
                                                "
                                                (focus)="setStep('missionType')"
                                                [clearable]="false"
                                                appendTo="body"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                >
                                                    {{ item.name }}
                                                </ng-template>
                                                <ng-template
                                                    ng-label-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                >
                                                    {{ item.name }}
                                                </ng-template>
                                            </ng-select></ng-template
                                        >
                                    </lockable>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="missionTypeId">
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Mission Workflow
                                        <field-validation-indicator
                                            validatorFormControlName="missionWorkflowVersionId"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                    <action-required
                                        class="control-label-action-required"
                                        *ngIf="
                                            missionWorkflowPrimaryAuthority !=
                                                null &&
                                            missionDetails.missionWorkflowVersion !=
                                                null &&
                                            missionDetails
                                                .missionWorkflowVersion
                                                .delegatedAuthority != null &&
                                            !authorityRegistersSetUp
                                        "
                                        [nonCritical]="true"
                                        buttonText="View Authority"
                                        (buttonClick)="
                                            showAuthorityDetails(
                                                missionWorkflowPrimaryAuthority
                                            )
                                        "
                                    >
                                        {{ authorityRegistersActionMessage }}
                                    </action-required>
                                </div>
                                <div class="col-xs-6">
                                    <ng-select
                                        name="missionWorkflowVersion"
                                        placeholder="Select a mission workflow to use"
                                        formControlName="missionWorkflowVersionId"
                                        bindValue="id"
                                        bindLabel="workflowName"
                                        [items]="missionWorkflows"
                                        [readonly]="
                                            working ||
                                            !missionTypeId.value ||
                                            !missionObjectivesGroup.valid ||
                                            locked
                                        "
                                        (focus)="setStep('missionWorkflow')"
                                        [clearable]="false"
                                        appendTo="body"
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            <span
                                                [ngClass]="{
                                                    error: item.expired
                                                }"
                                            >
                                                {{ item.workflowName
                                                }}{{
                                                    item.expired
                                                        ? ' (expired)'
                                                        : ''
                                                }}
                                            </span>
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            <span
                                                [ngClass]="{
                                                    error:
                                                        item.expired ||
                                                        item.authorityArchived
                                                }"
                                            >
                                                {{ item.workflowName
                                                }}{{
                                                    item.expired
                                                        ? ' (expired)'
                                                        : ''
                                                }}{{
                                                    item.authorityArchived
                                                        ? ' (archived)'
                                                        : ''
                                                }}
                                            </span>
                                        </ng-template>
                                    </ng-select>

                                    <div
                                        *ngIf="loadingWorkflow"
                                        class="alert alert-info top-buffer"
                                        role="alert"
                                    >
                                        Loading workflow...
                                    </div>
                                    <div
                                        style="margin-top: 10px"
                                        *ngIf="
                                            savedMission != null &&
                                            savedMission.missionWorkflowVersionId !=
                                                null &&
                                            savedMission.missionWorkflowVersionId !=
                                                missionForm.controls
                                                    .missionWorkflowVersionId
                                                    .value
                                        "
                                    >
                                        <p class="help-block">
                                            By changing workflow any existing
                                            approval requests and documentation
                                            will be cancelled
                                        </p>

                                        <button
                                            type="button"
                                            class="btn btn-default btn-sm"
                                            (click)="undoWorkflowChange()"
                                            [disabled]="
                                                working ||
                                                locked ||
                                                loadingWorkflow
                                            "
                                        >
                                            Undo
                                        </button>
                                        <button
                                            class="btn btn-primary btn-sm"
                                            type="button"
                                            (click)="changeWorkflow()"
                                            [disabled]="working || locked"
                                        >
                                            Change Workflow
                                        </button>
                                    </div>
                                    <div
                                        style="margin-top: 10px"
                                        *ngIf="showConfirmWorkFlow"
                                    >
                                        <p class="help-block">
                                            This workflow requires an approval,
                                            but one hasn't been started. Start
                                            one now?
                                        </p>
                                        <button
                                            class="btn btn-primary btn-sm"
                                            type="button"
                                            (click)="initiateWorkflow()"
                                            [disabled]="working || locked"
                                        >
                                            Confirm Workflow
                                        </button>
                                    </div>
                                    <div
                                        style="margin-top: 10px"
                                        *ngIf="
                                            savedMission?.missionWorkflowVersionId ==
                                                null &&
                                            missionForm.controls
                                                .missionWorkflowVersionId
                                                .value != null &&
                                            !showConfirmWorkFlow &&
                                            !loadingWorkflow
                                        "
                                    >
                                        <p class="help-block">
                                            This workflow has been prefilled and
                                            needs to be acknowledged to become
                                            active. Alternatively you can select
                                            a different workflow
                                        </p>
                                        <button
                                            class="btn btn-primary btn-sm"
                                            type="button"
                                            (click)="initiateWorkflow()"
                                            [disabled]="working || locked"
                                        >
                                            Acknowledge Workflow
                                        </button>
                                    </div>
                                </div>

                                <div class="col-xs-3">
                                    <div class="vertical-container">
                                        <span
                                            *ngIf="
                                                missionDetails
                                                    ?.missionWorkflowVersion
                                                    ?.delegatedAuthority
                                                    ?.authorityType?.ruleset !=
                                                null
                                            "
                                            class="bottom-buffer"
                                            ><strong>Ruleset:</strong>
                                            {{
                                                missionDetails
                                                    .missionWorkflowVersion
                                                    .delegatedAuthority
                                                    .authorityType.ruleset.name
                                            }}
                                        </span>
                                        <span
                                            *ngIf="
                                                missionDetails
                                                    ?.missionWorkflowVersion
                                                    ?.delegatedAuthority != null
                                            "
                                            ><strong>Primary Authority:</strong>
                                            {{
                                                (missionDetails
                                                    .missionWorkflowVersion
                                                    .delegatedAuthority
                                                    | formatAuthority) || 'None'
                                            }}
                                            <button
                                                *ngIf="
                                                    missionWorkflowPrimaryAuthority !=
                                                        null &&
                                                    missionWorkflowPrimaryAuthority
                                                        .availableActions
                                                        .canEdit
                                                "
                                                type="button"
                                                class="btn btn-link btn-sm"
                                                style="
                                                    padding: 0 5px;
                                                    margin: 0;
                                                    line-height: 22px;
                                                "
                                                (click)="
                                                    showAuthorityDetails(
                                                        missionWorkflowPrimaryAuthority
                                                    )
                                                "
                                                [disabled]="
                                                    working ||
                                                    locked ||
                                                    missionWorkflowPrimaryAuthority ==
                                                        null
                                                "
                                            >
                                                Details
                                            </button>
                                        </span>
                                        <ng-container
                                            *ngIf="
                                                hasActiveApprovals &&
                                                !canUseAdditionalAuthorities
                                            "
                                        >
                                            <h4 class="inline-heading">
                                                Required Approvals
                                            </h4>
                                            <div
                                                *ngFor="
                                                    let approval of activeMissionApprovals
                                                "
                                                class="vertical-container approval-data"
                                            >
                                                <!-- <span class="bottom-buffer"
                                                    ><strong>Source:</strong>
                                                    {{
                                                        getApprovalSource(
                                                            approval.approval
                                                        )
                                                    }}
                                                </span> -->
                                                <span
                                                    *ngIf="
                                                        approval.authorityType
                                                            ?.ruleset
                                                    "
                                                    class="bottom-buffer"
                                                    ><strong>Ruleset:</strong>
                                                    {{
                                                        approval.authorityType
                                                            ?.ruleset?.name !=
                                                        null
                                                            ? approval
                                                                  .authorityType
                                                                  ?.ruleset
                                                                  ?.name
                                                            : 'None'
                                                    }}
                                                </span>
                                                <span
                                                    ><strong
                                                        >{{
                                                            getApprovalSource(
                                                                approval.approval
                                                            )
                                                        }}:</strong
                                                    >
                                                    {{
                                                        (getSimplifiedAuthorityFromApproval(
                                                            approval,
                                                            organisation
                                                        ) | formatAuthority) ||
                                                            'None'
                                                    }}
                                                    <button
                                                        *ngIf="
                                                            approval.authority !=
                                                                null &&
                                                            approval.authority
                                                                .availableActions
                                                                ?.canEdit
                                                        "
                                                        type="button"
                                                        class="btn btn-link btn-sm"
                                                        style="
                                                            padding: 0 5px;
                                                            margin: 0;
                                                            line-height: 22px;
                                                        "
                                                        (click)="
                                                            showAuthorityDetails(
                                                                getSimplifiedAuthorityFromApproval(
                                                                    approval
                                                                )
                                                            )
                                                        "
                                                        [disabled]="
                                                            working ||
                                                            locked ||
                                                            approval.authority ==
                                                                null
                                                        "
                                                    >
                                                        Details
                                                    </button>
                                                </span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <enhanced-help
                                componentName="missionWorkflowVersionId"
                            >
                            </enhanced-help>

                            <div
                                *ngIf="canUseAdditionalAuthorities"
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Additional Authorities
                                        <field-validation-indicator
                                            validatorFormControlName="additionalAuthorityIds"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>

                                <div class="col-xs-6">
                                    <ng-container
                                        *ngIf="availableAuthorityLookup != null"
                                    >
                                        <ng-container
                                            *ngFor="
                                                let additionalAuthorityId of missionDetails
                                                    ?.missionWorkflowVersion
                                                    ?.additionalAuthorityIds
                                            "
                                        >
                                            <div
                                                class="horizontal-container top-buffer"
                                            >
                                                <div
                                                    *ngIf="
                                                        availableAuthorityLookup[
                                                            additionalAuthorityId
                                                        ] == null
                                                    "
                                                    class="fill"
                                                >
                                                    <span
                                                        class="status-block expired"
                                                        >Authority not
                                                        available</span
                                                    >
                                                </div>
                                                <div
                                                    *ngIf="
                                                        availableAuthorityLookup[
                                                            additionalAuthorityId
                                                        ] != null
                                                    "
                                                    class="fill"
                                                >
                                                    {{
                                                        availableAuthorityLookup[
                                                            additionalAuthorityId
                                                        ].name
                                                    }}
                                                </div>
                                                <span
                                                    ><em
                                                        >&nbsp;-&nbsp;From
                                                        workflow</em
                                                    ></span
                                                >
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngFor="
                                                let additionalAuthorityId of missionForm
                                                    .value
                                                    .additionalAuthorityIds
                                            "
                                        >
                                            <div
                                                class="horizontal-container top-buffer"
                                            >
                                                <div
                                                    *ngIf="
                                                        availableAuthorityLookup[
                                                            additionalAuthorityId
                                                        ] == null
                                                    "
                                                    class="fill"
                                                >
                                                    <span
                                                        class="status-block expired"
                                                        >Authority not
                                                        available</span
                                                    >
                                                </div>
                                                <div
                                                    *ngIf="
                                                        availableAuthorityLookup[
                                                            additionalAuthorityId
                                                        ] != null
                                                    "
                                                    class="fill"
                                                >
                                                    {{
                                                        availableAuthorityLookup[
                                                            additionalAuthorityId
                                                        ].name
                                                    }}
                                                </div>
                                                <button
                                                    class="btn btn-tertiary btn-delete"
                                                    type="button"
                                                    (click)="
                                                        removeAdditionalAuthority(
                                                            additionalAuthorityId
                                                        )
                                                    "
                                                    [disabled]="
                                                        working ||
                                                        loadingWorkflow
                                                    "
                                                >
                                                    <span
                                                        class="fal fa-trash-alt"
                                                    ></span>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <div class="input-group">
                                        <ng-select
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            [items]="
                                                availableAdditionalAuthorities
                                            "
                                            bindLabel="name"
                                            bindValue="id"
                                            [(ngModel)]="
                                                newAdditionalAuthorityId
                                            "
                                            placeholder="Select an additional authority"
                                            [disabled]="working"
                                        >
                                        </ng-select>
                                        <span class="input-group-btn">
                                            <button
                                                class="btn btn-primary"
                                                type="button"
                                                [disabled]="
                                                    newAdditionalAuthorityId ==
                                                        null ||
                                                    working ||
                                                    loadingWorkflow
                                                "
                                                (click)="
                                                    addAdditionalAuthority()
                                                "
                                            >
                                                Add
                                            </button>
                                        </span>
                                    </div>
                                    <div
                                        style="margin-top: 10px"
                                        *ngIf="
                                            additionalApprovalCreationRequired
                                        "
                                    >
                                        <p class="help-block">
                                            One or more approvals are required
                                            by additional authorities that
                                            haven't been started. Start them
                                            now?
                                        </p>
                                        <button
                                            class="btn btn-primary btn-sm"
                                            type="button"
                                            (click)="
                                                createApprovals(savedMission)
                                            "
                                            [disabled]="working || locked"
                                        >
                                            Start Approvals
                                        </button>
                                    </div>
                                </div>

                                <div class="col-xs-3">
                                    <ng-container
                                        *ngIf="
                                            hasActiveApprovals &&
                                            canUseAdditionalAuthorities
                                        "
                                    >
                                        <h4 class="inline-heading">
                                            Required Approvals
                                        </h4>
                                        <div
                                            *ngFor="
                                                let approval of activeMissionApprovals
                                            "
                                            class="vertical-container approval-data"
                                        >
                                            <!-- <span class="bottom-buffer"
                                                    ><strong>Source:</strong>
                                                    {{
                                                        getApprovalSource(
                                                            approval.approval
                                                        )
                                                    }}
                                                </span> -->
                                            <span
                                                *ngIf="
                                                    approval.authorityType
                                                        ?.ruleset
                                                "
                                                class="bottom-buffer"
                                                ><strong>Ruleset:</strong>
                                                {{
                                                    approval.authorityType
                                                        ?.ruleset?.name != null
                                                        ? approval.authorityType
                                                              ?.ruleset?.name
                                                        : 'None'
                                                }}
                                            </span>
                                            <span
                                                ><strong
                                                    >{{
                                                        getApprovalSource(
                                                            approval.approval
                                                        )
                                                    }}:</strong
                                                >
                                                {{
                                                    (getSimplifiedAuthorityFromApproval(
                                                        approval,
                                                        organisation
                                                    ) | formatAuthority) ||
                                                        'None'
                                                }}
                                                <button
                                                    *ngIf="
                                                        approval.authority !=
                                                            null &&
                                                        approval.authority
                                                            .availableActions
                                                            ?.canEdit
                                                    "
                                                    type="button"
                                                    class="btn btn-link btn-sm"
                                                    style="
                                                        padding: 0 5px;
                                                        margin: 0;
                                                        line-height: 22px;
                                                    "
                                                    (click)="
                                                        showAuthorityDetails(
                                                            getSimplifiedAuthorityFromApproval(
                                                                approval
                                                            )
                                                        )
                                                    "
                                                    [disabled]="
                                                        working ||
                                                        locked ||
                                                        approval.authority ==
                                                            null
                                                    "
                                                >
                                                    Details
                                                </button>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <enhanced-help
                                *ngIf="canUseAdditionalAuthorities"
                                componentName="additionalAuthorityIds"
                            >
                            </enhanced-help>

                            <ng-container formArrayName="craftIds">
                                <div
                                    class="form-group input-content"
                                    [ngClass]="{
                                        disabled:
                                            !missionObjectivesGroup.valid ||
                                            locked
                                    }"
                                >
                                    <div class="col-xs-3 text-right">
                                        <label class="control-label">
                                            <button
                                                type="button"
                                                class="btn-circle text-center"
                                                (click)="addRpa()"
                                                [disabled]="
                                                    working ||
                                                    !missionObjectivesGroup.valid ||
                                                    !canAddCraft ||
                                                    locked
                                                "
                                            >
                                                <span class="fa fa-plus"></span>
                                            </button>
                                            RPA

                                            <field-validation-indicator
                                                [control]="craftIds"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div
                                        class="col-xs-9"
                                        style="min-height: 34px"
                                    >
                                        <ng-container
                                            *ngIf="craftIds.controls.length"
                                        >
                                            <div class="static-table-container">
                                                <table class="table">
                                                    <thead>
                                                        <th
                                                            style="width: 25%"
                                                        ></th>
                                                        <th>Register Status</th>
                                                        <th>Last Flight</th>
                                                        <th>
                                                            Serviceability
                                                            Sign-Off
                                                        </th>
                                                        <th>
                                                            Next Scheduled
                                                            Maintenance
                                                        </th>
                                                        <th>
                                                            Serviceability
                                                            Status
                                                        </th>
                                                        <th
                                                            style="width: 5%"
                                                        ></th>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container
                                                            *ngFor="
                                                                let craft of craftIds.controls;
                                                                let i = index;
                                                                let even = even
                                                            "
                                                        >
                                                            <tr
                                                                mission-rpa-select
                                                                [ngClass]="{
                                                                    'row-even':
                                                                        even
                                                                }"
                                                                [formControl]="
                                                                    craft
                                                                "
                                                                [index]="i"
                                                                [readonly]="
                                                                    working ||
                                                                    lockedFields?.aircraft ||
                                                                    !missionObjectivesGroup.valid ||
                                                                    locked
                                                                "
                                                                [disable]="
                                                                    !missionObjectivesGroup.valid ||
                                                                    locked
                                                                "
                                                                [setStep]="
                                                                    setStep
                                                                "
                                                                [missionDetails]="
                                                                    missionDetails
                                                                "
                                                                [rpaRegisterDisabled]="
                                                                    rpaRegisterDisabled
                                                                "
                                                                [workflow]="
                                                                    missionForm.get(
                                                                        'missionWorkflowVersionId'
                                                                    ).value
                                                                "
                                                                [orgId]="
                                                                    organisation.id
                                                                "
                                                                [canUseWorkgroup]="
                                                                    canUseWorkgroup
                                                                "
                                                                [workgroupFormControl]="
                                                                    missionObjectivesGroup
                                                                        .controls
                                                                        .workgroup
                                                                "
                                                                (showRpaDetails)="
                                                                    showRpaDetails(
                                                                        i
                                                                    )
                                                                "
                                                                (deleteRpa)="
                                                                    deleteRpa(i)
                                                                "
                                                            ></tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <enhanced-help componentName="craftIds">
                            </enhanced-help>

                            <ng-container *ngIf="canUseEquipment">
                                <div
                                    class="form-group input-content"
                                    [ngClass]="{
                                        disabled:
                                            !missionObjectivesGroup.valid ||
                                            locked
                                    }"
                                >
                                    <div class="col-xs-3 text-right">
                                        <label class="control-label">
                                            <button
                                                type="button"
                                                class="btn-circle text-center"
                                                (click)="addEquipment()"
                                                [disabled]="
                                                    working ||
                                                    !missionObjectivesGroup.valid ||
                                                    !canAddEquipment ||
                                                    locked
                                                "
                                            >
                                                <span class="fa fa-plus"></span>
                                            </button>
                                            Equipment

                                            <field-validation-indicator
                                                [control]="equipmentIds"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div
                                        class="col-xs-9"
                                        style="min-height: 34px"
                                    >
                                        <ng-container
                                            *ngIf="equipmentIds.controls.length"
                                        >
                                            <div
                                                class="static-table-container"
                                                [ngClass]="{
                                                    disabled:
                                                        !missionObjectivesGroup.valid ||
                                                        locked
                                                }"
                                            >
                                                <table class="table">
                                                    <thead>
                                                        <th
                                                            style="width: 25%"
                                                        ></th>
                                                        <th>Serial Number</th>
                                                        <th>Model</th>
                                                        <th>Equipmment Type</th>
                                                        <th>
                                                            Serviceability
                                                            Status
                                                        </th>
                                                        <th
                                                            style="width: 5%"
                                                        ></th>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container
                                                            *ngFor="
                                                                let equipment of equipmentIds.controls;
                                                                let i = index;
                                                                let even = even
                                                            "
                                                        >
                                                            <tr
                                                                mission-equipment-select
                                                                [ngClass]="{
                                                                    'row-even':
                                                                        even
                                                                }"
                                                                [formControl]="
                                                                    equipment
                                                                "
                                                                [index]="i"
                                                                [readonly]="
                                                                    working ||
                                                                    !missionObjectivesGroup.valid ||
                                                                    locked
                                                                "
                                                                [disable]="
                                                                    !missionObjectivesGroup.valid ||
                                                                    locked
                                                                "
                                                                [setStep]="
                                                                    setStep
                                                                "
                                                                (showEquipmentDetails)="
                                                                    showEquipmentDetails(
                                                                        i
                                                                    )
                                                                "
                                                                (deleteEquipment)="
                                                                    deleteEquipment(
                                                                        i
                                                                    )
                                                                "
                                                            ></tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                                <!-- <ng-container
                                                *ngFor="
                                                    let equipment of equipmentIds.controls;
                                                    let i = index
                                                "
                                            >
                                                <mission-equipment-select
                                                    class="fill"
                                                    [formControl]="equipment"
                                                    [index]="i"
                                                    [readonly]="
                                                        working ||
                                                        !missionObjectivesGroup.valid ||
                                                        locked
                                                    "
                                                    [disable]="
                                                        !missionObjectivesGroup.valid ||
                                                        locked
                                                    "
                                                    [setStep]="setStep"
                                                    (showEquipmentDetails)="
                                                        showEquipmentDetails(i)
                                                    "
                                                    (deleteEquipment)="
                                                        deleteEquipment(i)
                                                    "
                                                ></mission-equipment-select>
                                            </ng-container> -->
                                            </div>
                                        </ng-container>
                                        <add-associated-resources
                                            *ngIf="hasRpaResourceAssociation"
                                        ></add-associated-resources>
                                    </div>
                                </div>
                                <enhanced-help componentName="equipmentIds">
                                </enhanced-help>
                            </ng-container>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        <button
                                            type="button"
                                            class="btn-circle text-center"
                                            (click)="addCrew()"
                                            [disabled]="
                                                working ||
                                                lockedFields?.missionCrew ||
                                                !missionObjectivesGroup.valid ||
                                                locked
                                            "
                                        >
                                            <span class="fa fa-plus"></span>
                                        </button>
                                        Crew
                                        <field-validation-indicator
                                            [valid]="
                                                missionForm.controls.rpicId
                                                    .valid &&
                                                missionForm.controls.missionCrew
                                                    .valid
                                            "
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>

                                <div class="col-xs-9" style="min-height: 34px">
                                    <div
                                        class="static-table-container"
                                        [ngClass]="{
                                            disabled:
                                                !missionObjectivesGroup.valid ||
                                                locked
                                        }"
                                    >
                                        <table class="table">
                                            <thead>
                                                <th style="width: 35%">Role</th>
                                                <th style="width: 35%">
                                                    Person
                                                </th>
                                                <th style="width: 20%">
                                                    Register Status
                                                </th>
                                                <th style="width: 5%"></th>
                                            </thead>
                                            <tbody>
                                                <tr class="row-even">
                                                    <td>
                                                        Remote Pilot In Command
                                                    </td>
                                                    <td *ngIf="!selectRpic">
                                                        <div
                                                            class="horizontal-container vertical-align space-between"
                                                        >
                                                            <a
                                                                (click)="
                                                                    showPersonDetails(
                                                                        rpicId.value
                                                                    )
                                                                "
                                                                >{{
                                                                    crewLookup
                                                                        ? crewLookup[
                                                                              rpicId
                                                                                  ?.value
                                                                          ]
                                                                        : ''
                                                                }}</a
                                                            >
                                                            <button
                                                                type="button"
                                                                class="btn btn-tertiary"
                                                                tooltip="Select a different pilot"
                                                                placement="top"
                                                                (click)="
                                                                    selectRpic =
                                                                        !selectRpic
                                                                "
                                                                [disabled]="
                                                                    disable ||
                                                                    readonly
                                                                "
                                                            >
                                                                <span
                                                                    class="fal fa-pencil-alt"
                                                                ></span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td *ngIf="selectRpic">
                                                        <div
                                                            class="horizontal-container vertical-align"
                                                        >
                                                            <ng-select
                                                                *ngIf="
                                                                    !pilotRegisterDisabled
                                                                "
                                                                name="rpicId"
                                                                formControlName="rpicId"
                                                                bindValue="id"
                                                                [searchFn]="
                                                                    personSearch
                                                                "
                                                                [items]="pilots"
                                                                [readonly]="
                                                                    working ||
                                                                    lockedFields?.missionCrew ||
                                                                    !missionObjectivesGroup.valid ||
                                                                    locked
                                                                "
                                                                [groupBy]="
                                                                    canUseWorkgroup &&
                                                                    isWorkgroupSelect
                                                                        ? 'registerWithWorkgroupStatus'
                                                                        : 'registerStatus'
                                                                "
                                                                [clearable]="
                                                                    false
                                                                "
                                                                appendTo="body"
                                                                class="wide-panel fill"
                                                                (change)="
                                                                    selectRpic =
                                                                        !selectRpic
                                                                "
                                                            >
                                                                <ng-template
                                                                    ng-optgroup-tmp
                                                                    let-item="item"
                                                                >
                                                                    {{
                                                                        canUseWorkgroup &&
                                                                        isWorkgroupSelect
                                                                            ? item.registerWithWorkgroupStatus
                                                                            : (item.registerStatus
                                                                              | formatMissionEditRegisterStatus)
                                                                    }}
                                                                </ng-template>
                                                                <ng-template
                                                                    ng-option-tmp
                                                                    let-item="item"
                                                                    let-index="index"
                                                                >
                                                                    {{
                                                                        item
                                                                            | formatPerson
                                                                    }}
                                                                </ng-template>
                                                                <ng-template
                                                                    ng-label-tmp
                                                                    let-item="item"
                                                                    let-index="index"
                                                                >
                                                                    {{
                                                                        item
                                                                            | formatPerson
                                                                    }}
                                                                </ng-template>
                                                            </ng-select>
                                                            <!-- Use a select without option grouping if the register is disabled -->
                                                            <!-- (this can't be done programmatically without replacing the whole select element) -->
                                                            <ng-select
                                                                *ngIf="
                                                                    pilotRegisterDisabled
                                                                "
                                                                name="rpicId"
                                                                formControlName="rpicId"
                                                                bindValue="id"
                                                                [searchFn]="
                                                                    personSearch
                                                                "
                                                                [items]="pilots"
                                                                [readonly]="
                                                                    working ||
                                                                    lockedFields?.missionCrew ||
                                                                    !missionObjectivesGroup.valid ||
                                                                    locked
                                                                "
                                                                [clearable]="
                                                                    false
                                                                "
                                                                appendTo="body"
                                                                class="wide-panel fill"
                                                                (change)="
                                                                    selectRpic =
                                                                        !selectRpic
                                                                "
                                                            >
                                                                <ng-template
                                                                    ng-option-tmp
                                                                    let-item="item"
                                                                    let-index="index"
                                                                >
                                                                    {{
                                                                        item
                                                                            | formatPerson
                                                                    }}
                                                                </ng-template>
                                                                <ng-template
                                                                    ng-label-tmp
                                                                    let-item="item"
                                                                    let-index="index"
                                                                >
                                                                    {{
                                                                        item
                                                                            | formatPerson
                                                                    }}
                                                                </ng-template>
                                                            </ng-select>
                                                            <button
                                                                *ngIf="
                                                                    rpicId.value !=
                                                                    null
                                                                "
                                                                type="button"
                                                                class="btn btn-tertiary no-margin"
                                                                tooltip="Cancel"
                                                                placement="top"
                                                                (click)="
                                                                    selectRpic =
                                                                        !selectRpic
                                                                "
                                                                [disabled]="
                                                                    disable ||
                                                                    readonly
                                                                "
                                                            >
                                                                <span
                                                                    class="fal fa-times"
                                                                ></span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <ng-container
                                                        *ngIf="
                                                            !pilotRegisterDisabled &&
                                                            getPilotRegisterStatus(
                                                                rpicId.value
                                                            ) as status
                                                        "
                                                        [ngSwitch]="status"
                                                    >
                                                        <td>
                                                            <span
                                                                *ngSwitchCase="
                                                                    null
                                                                "
                                                            ></span>
                                                            <span
                                                                *ngIf="
                                                                    registerEntriesOnMissionLoading
                                                                "
                                                                >Loading...</span
                                                            >
                                                            <span
                                                                *ngSwitchDefault
                                                            >
                                                                <ng-container
                                                                    *ngIf="
                                                                        !registerEntriesOnMissionLoading
                                                                    "
                                                                >
                                                                    <pilot-register-details-overall-status
                                                                        [pilotId]="
                                                                            rpicId.value
                                                                        "
                                                                        [status]="
                                                                            status
                                                                        "
                                                                    ></pilot-register-details-overall-status>
                                                                    <!-- Only show the details expand button if there are mission RPA
                                                                If there are no RPA on the mission, then the status will show the overall
                                                                authority register status and there will be no mission register details -->
                                                                    <button
                                                                        *ngIf="
                                                                            craftIds
                                                                                .value
                                                                                .length >
                                                                            0
                                                                        "
                                                                        type="button"
                                                                        class="btn btn-tertiary"
                                                                        (click)="
                                                                            showRpicRegisterDetails =
                                                                                !showRpicRegisterDetails
                                                                        "
                                                                    >
                                                                        <span
                                                                            class="fa"
                                                                            [ngClass]="{
                                                                                'fa-chevron-down':
                                                                                    !showRpicRegisterDetails,
                                                                                'fa-chevron-up':
                                                                                    showRpicRegisterDetails
                                                                            }"
                                                                            [tooltip]="
                                                                                (showRpicRegisterDetails
                                                                                    ? 'Hide '
                                                                                    : 'Show more ') +
                                                                                'register status information'
                                                                            "
                                                                            placement="top"
                                                                        ></span>
                                                                    </button>
                                                                </ng-container>
                                                            </span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="
                                                            pilotRegisterDisabled
                                                        "
                                                    >
                                                        <td>N/A</td>
                                                    </ng-container>
                                                    <td></td>
                                                </tr>
                                                <tr
                                                    *ngIf="
                                                        showRpicRegisterDetails &&
                                                        craftIds.value.length >
                                                            0
                                                    "
                                                >
                                                    <td colspan="5">
                                                        <pilot-register-details
                                                            [pilotId]="
                                                                rpicId.value
                                                            "
                                                            [rowEven]="true"
                                                        ></pilot-register-details>
                                                    </td>
                                                </tr>
                                                <ng-container
                                                    formArrayName="missionCrew"
                                                >
                                                    <ng-container
                                                        *ngFor="
                                                            let crew of missionCrew.controls;
                                                            let i = index;
                                                            let even = even
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="{
                                                                show: false
                                                            } as showDetails"
                                                        >
                                                            <!-- Do the usual "even" row styling on odd rows due to the fixed rpic row above -->
                                                            <tr
                                                                [ngClass]="{
                                                                    'row-even':
                                                                        !even
                                                                }"
                                                                mission-pilot-role-v2
                                                                *ngIf="
                                                                    canEditMissionCrew
                                                                "
                                                                [formControl]="
                                                                    crew
                                                                "
                                                                [pilots]="
                                                                    personnel
                                                                "
                                                                [roles]="
                                                                    crewRoles
                                                                "
                                                                [crewLookup]="
                                                                    crewLookup
                                                                "
                                                                [pilotRegisterDisabled]="
                                                                    pilotRegisterDisabled
                                                                "
                                                                (deleteCrew)="
                                                                    deleteCrew(
                                                                        i
                                                                    )
                                                                "
                                                                (toggleShowDetails)="
                                                                    showDetails.show =
                                                                        !showDetails.show
                                                                "
                                                                (showPersonDetails)="
                                                                    showPersonDetails(
                                                                        $event
                                                                    )
                                                                "
                                                                [missionDetails]="
                                                                    missionDetails
                                                                "
                                                                [orgId]="
                                                                    organisation.id
                                                                "
                                                                [workflow]="
                                                                    missionForm.get(
                                                                        'missionWorkflowVersionId'
                                                                    ).value
                                                                "
                                                                [readonly]="
                                                                    working ||
                                                                    lockedFields?.missionCrew ||
                                                                    !missionObjectivesGroup.valid ||
                                                                    locked
                                                                "
                                                                [craftIds]="
                                                                    craftIds.value
                                                                "
                                                            ></tr>
                                                            <tr
                                                                *ngIf="
                                                                    showDetails.show &&
                                                                    craftIds
                                                                        .value
                                                                        .length >
                                                                        0
                                                                "
                                                                [ngClass]="{
                                                                    'row-even':
                                                                        !even
                                                                }"
                                                            >
                                                                <td colspan="5">
                                                                    <pilot-register-details
                                                                        [pilotId]="
                                                                            crew
                                                                                .value
                                                                                .personId
                                                                        "
                                                                        [rowEven]="
                                                                            even
                                                                        "
                                                                    ></pilot-register-details>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- <div class="col-xs-3"></div> -->
                            </div>
                            <enhanced-help componentName="missionCrew">
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled:
                                        !missionObjectivesGroup.valid || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Crew Notes
                                        <field-validation-indicator
                                            validatorFormControlName="crewNotes"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <textarea
                                        class="form-control"
                                        name="crewNotes"
                                        rows="10"
                                        formControlName="crewNotes"
                                        placeholder="Additional information that the crew should be aware of, such as extra equipment required, etc..."
                                        (focus)="setStep('crewNotes')"
                                        [readonly]="
                                            working ||
                                            !missionObjectivesGroup.valid ||
                                            locked
                                        "
                                    >
                                    </textarea>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="crewNotes">
                            </enhanced-help>
                        </div>
                    </div>

                    <div id="planning">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <span>MISSION PLANNING</span>
                                <div></div>
                            </h4>

                            <div
                                class="form-group input-content"
                                style="margin-bottom: 0"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Time Zone
                                        <field-validation-indicator
                                            validatorFormControlName="timeZone"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator
                                    ></label>
                                </div>
                                <div class="col-xs-6 vcenter">
                                    <lockable
                                        [isLocked]="lockedFields?.timeZone"
                                        message="The timezone is set automatically from the location"
                                    >
                                        <ng-template unlockedMode>
                                            <ng-select
                                                *ngIf="timeZoneOffsets != null"
                                                name="timeZone"
                                                placeholder="Select the timezone the mission times are in"
                                                formControlName="timeZone"
                                                [items]="timeZones"
                                                [readonly]="
                                                    working ||
                                                    !isResourcesCompleted ||
                                                    locked
                                                "
                                                appendTo="body"
                                                [clearable]="false"
                                                style="width: 100%"
                                            >
                                                <ng-template
                                                    ng-label-tmp
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                >
                                                    {{ item }} (UTC
                                                    {{ timeZoneOffsets[item] }})
                                                </ng-template>
                                            </ng-select>
                                        </ng-template>
                                        <ng-template lockedMode
                                            ><span>{{
                                                location.timeZone
                                            }}</span></ng-template
                                        >
                                    </lockable>
                                </div>
                            </div>
                            <div
                                class="form-group input-content no-spacing"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right"></div>

                                <div
                                    class="col-xs-6 vcenter"
                                    [ngClass]="{
                                        readonly:
                                            working ||
                                            !isResourcesCompleted ||
                                            locked
                                    }"
                                >
                                    <div class="vertical-container fill">
                                        <div
                                            class="horizontal-container"
                                            *ngIf="sunriseSunset"
                                        >
                                            <div class="right-buffer">
                                                <label class="control-label"
                                                    >Current Time at Location:
                                                </label>
                                                <span style="padding-left: 5px">
                                                    {{ currentLocalTime }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <enhanced-help componentName="timeZone">
                            </enhanced-help>

                            <!--  -->
                            <div
                                class="form-group input-content"
                                style="margin-bottom: 0"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Planned Time
                                        <field-validation-indicator
                                            validatorFormControlName="missionDate"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator
                                    ></label>
                                </div>

                                <div class="col-xs-3">
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            type="text"
                                            required
                                            #datepicker="bsDatepicker"
                                            bsDatepicker
                                            formControlName="missionDate"
                                            (focus)="setStep('plannedDate')"
                                            (change)="
                                                stepCompleted('plannedDate')
                                            "
                                            [readonly]="
                                                working ||
                                                !isResourcesCompleted ||
                                                locked
                                            "
                                            [isDisabled]="
                                                working ||
                                                !isResourcesCompleted ||
                                                locked
                                            "
                                        />
                                        <span
                                            *ngIf="
                                                !working && isResourcesCompleted
                                            "
                                            class="input-group-addon btn-default"
                                            (click)="datepicker.toggle()"
                                            [attr.aria-expanded]="
                                                datepicker.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                            ></i>
                                        </span>
                                    </div>
                                </div>

                                <div
                                    class="col-xs-3"
                                    style="width: fit-content"
                                >
                                    <timepicker
                                        formControlName="missionDate"
                                        [hourStep]="1"
                                        [minuteStep]="1"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        (change)="stepCompleted('plannedTime')"
                                        (focus)="setStep('plannedTime')"
                                        [readonlyInput]="
                                            working ||
                                            !isResourcesCompleted ||
                                            locked
                                        "
                                    >
                                    </timepicker>
                                </div>

                                <div class="col-xs-3">
                                    <label class="control-label">UTC: </label>
                                    <span style="padding-left: 5px">
                                        {{ utc }}
                                    </span>
                                </div>
                            </div>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right"></div>

                                <div
                                    class="col-xs-6 vcenter"
                                    [ngClass]="{
                                        readonly:
                                            working ||
                                            !isResourcesCompleted ||
                                            locked
                                    }"
                                >
                                    <div class="vertical-container fill">
                                        <div
                                            class="horizontal-container top-buffer"
                                            *ngIf="sunriseSunset"
                                        >
                                            <table
                                                class="table table-bordered no-margin"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            Civil Twilight
                                                            Starts
                                                        </th>
                                                        <th>Sunrise</th>
                                                        <th>Sunset</th>
                                                        <th>
                                                            Civil Twilight Ends
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {{
                                                                civilTwilightStart
                                                            }}
                                                        </td>
                                                        <td>{{ sunrise }}</td>
                                                        <td>{{ sunset }}</td>
                                                        <td>
                                                            {{
                                                                civilTwilightEnd
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <span
                                            >Time is local 24 hour time in this
                                            timezone</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <enhanced-help componentName="missionDate">
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Estimated Duration
                                        <field-validation-indicator
                                            validatorFormControlName="missionEstimatedTime"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <duration
                                        formControlName="missionEstimatedTime"
                                        [readonly]="
                                            working ||
                                            !isResourcesCompleted ||
                                            locked
                                        "
                                        [showHour]="true"
                                        [showMinute]="true"
                                        [showSecond]="false"
                                    >
                                    </duration>
                                </div>
                            </div>

                            <enhanced-help componentName="missionEstimatedTime">
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Time of Day
                                        <field-validation-indicator
                                            validatorFormControlName="timeOfDay"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="timeOfDay"
                                        style="padding-top: 7px"
                                        [ngClass]="{
                                            disabled:
                                                !isResourcesCompleted || locked
                                        }"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="DAY"
                                            [disabled]="
                                                working ||
                                                !isResourcesCompleted ||
                                                locked
                                            "
                                            >Day</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="NIGHT"
                                            [disabled]="
                                                working ||
                                                !isResourcesCompleted ||
                                                locked
                                            "
                                            >Night</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="DAY_NIGHT"
                                            [disabled]="
                                                working ||
                                                !isResourcesCompleted ||
                                                locked
                                            "
                                            >Both</label
                                        >
                                    </div>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="timeOfDay">
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Visual Line of Sight
                                        <field-validation-indicator
                                            validatorFormControlName="visualLineOfSight"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="visualLineOfSight"
                                        style="padding-top: 7px"
                                        [ngClass]="{
                                            disabled:
                                                !isResourcesCompleted || locked
                                        }"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="VLOS"
                                            [disabled]="working || locked"
                                            >VLOS</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="EVLOS"
                                            [disabled]="working || locked"
                                            >EVLOS</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="BVLOS"
                                            [disabled]="working || locked"
                                            >BVLOS</label
                                        >
                                    </div>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="visualLineOfSight">
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                style="margin-bottom: 0"
                                [ngClass]="{
                                    disabled: !isResourcesCompleted || locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Planned Max Height
                                        <field-validation-indicator
                                            validatorFormControlName="maximumHeight"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div class="input-group">
                                        <input
                                            name="maximumHeight"
                                            type="number"
                                            class="form-control"
                                            placeholder="The maximum flight height of the mission above ground level"
                                            min="0"
                                            step="1"
                                            formControlName="maximumHeight"
                                            (change)="
                                                stepCompleted('maxHeight')
                                            "
                                            [readonly]="
                                                working ||
                                                !isResourcesCompleted ||
                                                locked
                                            "
                                        />
                                        <span class="input-group-addon"
                                            >ft AGL</span
                                        >
                                    </div>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right"></div>

                                <div
                                    class="col-xs-6 vcenter"
                                    [ngClass]="{
                                        disabled:
                                            !isResourcesCompleted || locked
                                    }"
                                >
                                    <span
                                        style="padding-top: 10px"
                                        *ngIf="maximumHeight.value"
                                    >
                                        {{
                                            maximumHeight.value / 3.281
                                                | number: '1.0-1'
                                        }}
                                        m AGL
                                    </span>
                                </div>
                            </div>

                            <enhanced-help componentName="maximumHeight">
                            </enhanced-help>

                            <ng-container *ngIf="mission.modelVersion === 1">
                                <div
                                    class="form-group input-content"
                                    [ngClass]="{
                                        disabled: !isResourcesCompleted
                                    }"
                                >
                                    <div class="col-xs-3 text-right">
                                        <label class="control-label">
                                            Contact Details
                                            <field-validation-indicator
                                                validatorFormControlName="emergencyContacts"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <textarea
                                            name="emergencyContacts"
                                            class="form-control"
                                            rows="5"
                                            formControlName="emergencyContacts"
                                            placeholder="The emergency contact details for this area"
                                            (change)="stepCompleted('contacts')"
                                            (focus)="setStep('contacts')"
                                            [readonly]="
                                                working || !isResourcesCompleted
                                            "
                                        >
                                        </textarea>
                                    </div>
                                    <div class="col-xs-3"></div>
                                </div>
                                <enhanced-help
                                    componentName="emergencyContacts"
                                >
                                </enhanced-help>

                                <div
                                    class="form-group input-content"
                                    [ngClass]="{
                                        disabled: !isResourcesCompleted
                                    }"
                                >
                                    <div class="col-xs-3 text-right">
                                        <label class="control-label">
                                            Radio Frequencies
                                            <field-validation-indicator
                                                validatorFormControlName="radioFrequencies"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <input
                                            name="radioFrequencies"
                                            type="text"
                                            class="form-control"
                                            formControlName="radioFrequencies"
                                            placeholder="The radio frequency the pilot needs to be on"
                                            (change)="
                                                stepCompleted('frequencies')
                                            "
                                            (focus)="setStep('frequencies')"
                                            [readonly]="
                                                working || !isResourcesCompleted
                                            "
                                        />
                                    </div>

                                    <div class="col-xs-3"></div>
                                </div>

                                <enhanced-help componentName="radioFrequencies">
                                </enhanced-help>
                            </ng-container>

                            <ng-container *ngIf="mission.modelVersion !== 1">
                                <div
                                    class="form-group"
                                    [ngClass]="{
                                        disabled:
                                            !isResourcesCompleted ||
                                            (locked && !isAddingContacts)
                                    }"
                                >
                                    <div class="col-xs-3 text-right">
                                        <label class="control-label">
                                            <button
                                                type="button"
                                                class="btn-circle text-center"
                                                [disabled]="
                                                    working ||
                                                    isAddingContacts ||
                                                    locked
                                                "
                                                (click)="
                                                    isAddingContacts =
                                                        !isAddingContacts
                                                "
                                            >
                                                <span class="fa fa-plus"></span>
                                            </button>
                                            Contact Details
                                            <field-validation-indicator
                                                validatorFormControlName="missionEmergencyContacts"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <div
                                            class="horizontal-container"
                                            style="flex-wrap: wrap"
                                        >
                                            <contact-add
                                                *ngIf="isAddingContacts"
                                                scrollTo
                                                nameLabel="Contact Name"
                                                valueLabel="Number"
                                                [requireName]="true"
                                                [requireValue]="true"
                                                [editName]="true"
                                                [editSubtype]="false"
                                                [editValue]="true"
                                                [readonly]="
                                                    working ||
                                                    !isResourcesCompleted
                                                "
                                                (add)="addContact($event)"
                                                (cancel)="
                                                    isAddingContacts =
                                                        !isAddingContacts
                                                "
                                            ></contact-add>
                                            <ng-container
                                                formArrayName="missionEmergencyContacts"
                                            >
                                                <div
                                                    *ngFor="
                                                        let contact of missionEmergencyContacts.controls;
                                                        let i = index
                                                    "
                                                >
                                                    <contact-pill
                                                        [formControlName]="i"
                                                        (removeItem)="
                                                            removeContact(i)
                                                        "
                                                        [disabled]="locked"
                                                    ></contact-pill>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-xs-3"></div>
                                </div>
                                <enhanced-help
                                    componentName="missionEmergencyContacts"
                                >
                                </enhanced-help>

                                <div
                                    class="form-group"
                                    [ngClass]="{
                                        disabled:
                                            !isResourcesCompleted ||
                                            (locked && !isAddingFrequencies)
                                    }"
                                >
                                    <div class="col-xs-3 text-right">
                                        <label class="control-label">
                                            <button
                                                type="button"
                                                class="btn-circle text-center"
                                                [disabled]="
                                                    working ||
                                                    isAddingFrequencies ||
                                                    locked
                                                "
                                                (click)="
                                                    isAddingFrequencies =
                                                        !isAddingFrequencies
                                                "
                                            >
                                                <span class="fa fa-plus"></span>
                                            </button>
                                            Radio Frequencies
                                            <field-validation-indicator
                                                validatorFormControlName="missionRadioFrequencies"
                                                noun="field"
                                                placement="right"
                                            ></field-validation-indicator>
                                        </label>
                                    </div>
                                    <div class="col-xs-6">
                                        <div
                                            class="horizontal-container"
                                            style="flex-wrap: wrap"
                                        >
                                            <contact-add
                                                *ngIf="isAddingFrequencies"
                                                scrollTo
                                                nameLabel="Frequency Name"
                                                subtypeLabel="Type eg. TWR"
                                                valueLabel="Frequency"
                                                [requireName]="true"
                                                [requireSubtype]="false"
                                                [requireValue]="true"
                                                [editName]="true"
                                                [editSubtype]="true"
                                                [editValue]="true"
                                                [readonly]="
                                                    working ||
                                                    !isResourcesCompleted
                                                "
                                                (add)="addFrequency($event)"
                                                (cancel)="
                                                    isAddingFrequencies =
                                                        !isAddingFrequencies
                                                "
                                            ></contact-add>
                                            <ng-container
                                                formArrayName="missionRadioFrequencies"
                                            >
                                                <div
                                                    *ngFor="
                                                        let frequency of missionRadioFrequencies.controls;
                                                        let j = index
                                                    "
                                                >
                                                    <contact-pill
                                                        [formControlName]="j"
                                                        (removeItem)="
                                                            removeFrequency(j)
                                                        "
                                                        [disabled]="locked"
                                                    ></contact-pill>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-xs-3"></div>
                                </div>

                                <enhanced-help
                                    componentName="missionRadioFrequencies"
                                >
                                </enhanced-help>
                            </ng-container>
                        </div>
                    </div>

                    <div id="documentation">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{
                                    disabled:
                                        !isResourcesCompleted ||
                                        savedMission?.id == null ||
                                        locked
                                }"
                            >
                                <span>DOCUMENTATION</span>
                                <div></div>
                                <div
                                    class="btn-group"
                                    dropdown
                                    [isDisabled]="
                                        !isResourcesCompleted ||
                                        savedMission?.id == null ||
                                        locked
                                    "
                                >
                                    <button
                                        dropdownToggle
                                        type="button"
                                        class="btn btn-link dropdown-toggle"
                                        aria-controls="dropdown-basic"
                                    >
                                        ...
                                    </button>
                                    <ul
                                        *dropdownMenu
                                        class="dropdown-menu dropdown-menu-right"
                                        role="menu"
                                        aria-labelledby="button-basic"
                                    >
                                        <li role="menuitem">
                                            <a
                                                class="dropdown-item"
                                                (click)="
                                                    showSelectPrefillMission()
                                                "
                                                >Prefill from other mission</a
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </h4>
                            <div
                                *ngIf="savedMission?.id == null"
                                class="alert alert-info"
                            >
                                Please save the mission plan to start adding
                                documentation and attachments.
                            </div>
                            <div
                                *ngIf="locked && isAddingContacts"
                                class="alert alert-info"
                            >
                                Please complete adding a contact above.
                            </div>
                            <div
                                *ngIf="locked && isAddingFrequencies"
                                class="alert alert-info"
                            >
                                Please complete adding a frequency above.
                            </div>
                            <mission-documentation
                                *ngIf="!locked"
                                formControlName="documentation"
                                [organisation]="organisation"
                                [documentationStorage]="documentationStorage"
                                [prefillErrors]="prefillErrors"
                                [missionApprovalList]="missionApprovals"
                                [missionDocumentation]="allMissionDocumentation"
                                groupTitle="Mission Planning"
                                step="pre-submit"
                                [readonly]="
                                    working || !isResourcesCompleted || locked
                                "
                                [attachmentsHandler]="attachmentsHandler"
                            ></mission-documentation>

                            <enhanced-help
                                *ngIf="savedMission?.id && !locked"
                                componentName="documentation"
                            >
                            </enhanced-help>
                            <div
                                class="form-group input-content"
                                *ngIf="savedMission?.id && !locked"
                            >
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{
                                            disabled: !isResourcesCompleted
                                        }"
                                        >Attached Documents
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <attachment-list
                                        [attachmentsHandler]="
                                            attachmentsHandler
                                        "
                                        [allowUploads]="isResourcesCompleted"
                                        [allowDelete]="isResourcesCompleted"
                                        [allowEdit]="isResourcesCompleted"
                                        [parentPromiseTracker]="workTracker"
                                        [disabled]="disabled"
                                        [requirementId]="null"
                                        permissions
                                    >
                                    </attachment-list>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                *ngIf="savedMission?.id && !locked"
                                componentName="attachedDocuments"
                            >
                            </enhanced-help>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            !canCheckAirspace &&
                            airspaceAuthorisationsEnabled &&
                            (userCanViewAirspaceAuthorisation ||
                                userCanRequestAirspaceAuthorisation) &&
                            !showInlineAuthorisation
                        "
                        id="authorisation"
                    >
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{
                                    disabled:
                                        !isResourcesCompleted ||
                                        savedMission?.id == null ||
                                        locked
                                }"
                            >
                                <span>AUTHORISATION</span>
                                <div></div>
                            </h4>
                            <div class="fill">
                                <div class="col-xs-3"></div>
                                <div class="col-xs-9">
                                    <div
                                        style="max-width: 740px; height: 280px"
                                    >
                                        <airspace-authorisation-status
                                            [enabledAuthorisationTypes]="
                                                enabledAuthorisationTypes
                                            "
                                            [areAuthorisationRequirementsComplete]="
                                                areAuthorisationRequirementsComplete
                                            "
                                            [jurisdiction]="
                                                location?.airspaceJurisdiction
                                            "
                                            [hasRuleset]="
                                                returnMissionRuleset() != null
                                            "
                                            [disabled]="
                                                missionObjectivesGroup.controls
                                                    .isDummy.value ||
                                                loadingWorkflow
                                            "
                                            [isDummy]="
                                                missionObjectivesGroup.controls
                                                    .isDummy.value
                                            "
                                            [userCanViewAirspaceAuthorisation]="
                                                userCanViewAirspaceAuthorisation
                                            "
                                            [userCanRequestAirspaceAuthorisation]="
                                                userCanRequestAirspaceAuthorisation
                                            "
                                            (showAirspaceAuthorisation)="
                                                showAirspaceAuthorisation()
                                            "
                                            (cancelAirspaceAuthorisation)="
                                                cancelAirspaceAuthorisation(
                                                    $event
                                                )
                                            "
                                            (requestNewAirspaceAuthorisation)="
                                                requestNewAirspaceAuthorisation(
                                                    $event
                                                )
                                            "
                                        ></airspace-authorisation-status>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <p
                *ngIf="showConfirmWorkFlow"
                class="text-danger"
                style="display: inline-block"
            >
                Please confirm the workflow to continue
            </p>
            <p
                *ngIf="
                    !showConfirmWorkFlow && hasPrefilledDocumentationResponses
                "
                class="text-danger"
                style="display: inline-block"
            >
                Please Note: Unsaved cloned values for forms marked with
                "Continue" may be lost after closing this dialogue.
            </p>
            <button
                *ngIf="
                    combinedStatus === 'NEW' ||
                    combinedStatus === 'DRAFT' ||
                    combinedStatus === 'SUBMITTED'
                "
                class="btn btn-default"
                type="button"
                (click)="save()"
                [disabled]="
                    missionObjectivesGroup.controls.name.invalid ||
                    working ||
                    locked ||
                    missionForm.pristine
                "
            >
                {{
                    combinedStatus === 'NEW' || combinedStatus === 'DRAFT'
                        ? 'Save'
                        : 'Amend'
                }}
            </button>

            <button
                *ngIf="
                    combinedStatus === 'NEW' ||
                    combinedStatus === 'DRAFT' ||
                    combinedStatus === 'SUBMITTED'
                "
                class="btn btn-default"
                type="button"
                (click)="saveAndClose()"
                [disabled]="
                    missionObjectivesGroup.controls.name.invalid ||
                    working ||
                    locked ||
                    missionForm.pristine
                "
            >
                {{
                    combinedStatus === 'NEW' || combinedStatus === 'DRAFT'
                        ? 'Save'
                        : 'Amend'
                }}
                and Close
            </button>
            <button
                class="btn btn-primary"
                type="submit"
                [disabled]="
                    working ||
                    !isSubmittable ||
                    approvalCreationRequired ||
                    additionalApprovalCreationRequired ||
                    locked
                "
                *ngIf="submitOption !== 'NONE'"
            >
                {{ submitOption === 'SUBMIT' ? 'Submit for Approval' : 'Fly' }}
            </button>
        </div>
    </form>
    <inline-modal [show]="isShowingSelectPrefillMission"
        ><select-mission
            *ngIf="isShowingSelectPrefillMission"
            [organisation]="organisation"
            (cancel)="hideSelectPrefillMission()"
            (done)="onPrefillFromMission($event)"
        ></select-mission
    ></inline-modal>
    <inline-modal [show]="isAddingAerodromes"
        ><aerodrome-search
            *ngIf="isAddingAerodromes"
            [existingAerodromes]="
                missionForm.controls.aerodromesOfInterest.value
            "
            [existingFrequencies]="
                missionForm.controls.missionRadioFrequencies.value
            "
            [existingContacts]="
                missionForm.controls.missionEmergencyContacts.value
            "
            [defaultAerodromeCode]="defaultAerodromeCode"
            [locationCentre]="locationBearings?.locationCentre"
            (close)="hideAerodromeSearch()"
            (addAerodrome)="addAerodrome($event)"
            (addFrequency)="addAerodromeFrequency($event)"
            (addContact)="addAerodromeContact($event)"
            (removeAerodrome)="removeSearchAirport($event)"
            (removeFrequency)="removeSearchFrequency($event)"
            (removeContact)="removeSearchContact($event)"
        ></aerodrome-search
    ></inline-modal>
    <inline-modal [show]="isAddingNotams"
        ><notam-search
            *ngIf="isAddingNotams && canUseNotams"
            [existingAerodromes]="
                missionForm.controls.aerodromesOfInterest.value
            "
            [existingNotams]="missionForm.controls.notams.value"
            [locationJurisdiction]="location?.airspaceJurisdiction"
            [missionId]="savedMission?.id"
            [hasAcknowledgedNoRelevantNotams]="declareNoRelevantNotam.value"
            (close)="hideNotamSearch()"
            (addNotam)="addAerodromeNotam($event)"
            (removeNotam)="removeSearchNotam($event)"
            (acknowledgeNoRelevantNotam)="acknowledgeNoRelevantNotam()"
            [location]="location"
        ></notam-search
    ></inline-modal>
    <inline-modal
        *ngIf="location?.airspaceJurisdiction"
        [show]="showAirspaceDisclaimer"
        [sizeSmall]="true"
        style="max-height: fit-content"
    >
        <airspace-disclaimer
            screenIdentifier="mission-edit-v2"
            [airspaceJurisdiction]="location.airspaceJurisdiction"
            (done)="onAirspaceDisclaimerAcknowledged()"
        ></airspace-disclaimer>
    </inline-modal>
    <inline-modal
        #viewNotamDetailModal
        [sizeSmall]="true"
        style="max-height: fit-content"
    >
    </inline-modal>
</ng-container>
