import { Injectable } from '@angular/core';
import {
    OrganisationSubscriptionDto,
    SubscriptionService
} from '@flyfreely-portal-ui/flyfreely';
import { OrganisationSubscriptionState } from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import { Subject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { PlanSelectionService } from '../plan-selection/plan-selection.service';
import { SubscriptionPlanSelection } from '../purchase-subscription/purchase-subscription.service';

@Injectable()
export class UpdateSubscriptionService extends PlanSelectionService {
    private subscription: OrganisationSubscriptionDto;

    private purchaseSuccessSubject = new Subject<void>();
    purchaseSuccess$ = this.purchaseSuccessSubject.asObservable();

    constructor(
        private subscriptionService: SubscriptionService,
        private state: OrganisationSubscriptionState
    ) {
        super();
        this.state.subscriptionDetails$.subscribe(
            subscription => (this.subscription = subscription.subscription)
        );
    }

    ngOnDestroy() {
        this.purchaseSuccessSubject.complete();
    }

    previewPurchaseSubscription(
        plan: SubscriptionPlanSelection,
        licenceCount: number
    ) {
        return this.subscriptionService.previewCharge({
            organisationId: this.subscription.organisationId,
            subscriptionPlanIdentifier: plan.identifier,
            licenceCount: licenceCount
        });
    }

    purchaseSubscription(
        subscriptionPlanIdentifier: string,
        licenceCount: number,
        upgradeSubscription: boolean = false
    ) {
        const action = this.subscriptionService
            .updateSubscription(this.subscription.id, {
                organisationId: this.subscription.organisationId,
                subscriptionPlanIdentifier,
                licenceCount
            })
            .pipe(
                tap(() => this.state.pollForLicenceUpdates(licenceCount)),
                shareReplay()
            );

        // Kick-off action
        action.subscribe(() => this.purchaseSuccessSubject.next());

        // Return a copy to the caller can wait too
        return action;
    }
}
