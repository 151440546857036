import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CraftDetailsDto,
    CraftDto,
    CraftService,
    CreateRpaTypeCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NotFound,
    Organisation,
    PROPULSION_SYSTEMS,
    RPA_CATEGORIES,
    ResourceTypeComponentsService,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker,
    fromLocalDate,
    hasFeatureFlag,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { rpaTypeSearch } from 'libs/flyfreely/src/lib/tools/searchFunctions';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { compareRpaType } from '../helpers';
import { RpaUploadService } from '../rpa-upload.service';
@Component({
    selector: 'rpa-add-dialogue',
    templateUrl: './rpa-add-dialogue.component.html',
    providers: [RpaUploadService],
    animations: [collapseOnLeaveAnimation(), expandOnEnterAnimation()]
})
export class RpaAddDialogue implements OnInit, OnDestroy {
    @Input()
    rpaTypes: RpaTypeDto[];
    @Input() organisation: Organisation;
    @Input() rpa: CraftDto;

    @Output() created = new EventEmitter<CraftDetailsDto>();

    rpaNewForm: FormGroup;
    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    aircraft: any;
    rpaType: RpaTypeDto;
    canUseNfc: boolean;
    canBulkUpload = false;
    aircraftModels: RpaTypeDto[];
    addMakeModel = false;
    rpaMakes: string[];
    rpaModels: string[];

    rpaCategories = RPA_CATEGORIES;
    propulsionSystems = PROPULSION_SYSTEMS;

    makeListed = false;
    modelListed = false;
    hasComponentsSetUp = false;
    showComponents = true;

    makeValidator = 'make';
    modelValidator = 'model';

    makeLocked = false;
    modelLocked = false;
    typeLocked = false;

    rpaTypeSearch = rpaTypeSearch;

    constructor(
        private modal: BsModalRef<RpaAddDialogue>,
        modalOptions: ModalOptions,
        private craftService: CraftService,
        private rpaTypesService: RpaTypesService,
        private commonDialoguesService: CommonDialoguesService,
        private rpaUploadService: RpaUploadService,
        private resourceTypeComponentsService: ResourceTypeComponentsService,
        private logging: FlyFreelyLoggingService,
        private changeDetector: ChangeDetectorRef
    ) {
        modalOptions.closeInterceptor = () =>
            preHide(this.rpaNewForm, this.commonDialoguesService);

        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.rpaNewForm = new FormGroup({
            nickname: new FormControl('', [Validators.required]),
            isDummy: new FormControl(false),
            nfcUid: new FormControl(),
            callSign: new FormControl(),
            manufacturerSerialNumber: new FormControl(),
            maximumVisualDistance: new FormControl(),
            rpaTypeId: new FormControl(undefined, Validators.required),
            make: new FormControl(),
            makeText: new FormControl(),
            model: new FormControl(),
            modelText: new FormControl(),
            rpaType: new FormControl(),
            propulsion: new FormControl(),
            initialFlightTime: new FormControl(0),
            procurementDate: new FormControl(),
            components: new FormArray([])
        });
    }

    ngOnInit() {
        this.aircraft = { ...this.rpa };
        this.aircraft.procurementDate = this.aircraft.procurementDate
            ? fromLocalDate(this.aircraft.procurementDate)
            : null;
        this.canUseNfc = hasFeatureFlag(this.organisation, 'craft-nfc');
        this.canBulkUpload = hasFeatureFlag(
            this.organisation,
            'organisationBulkSetup'
        );

        const rpaModels = this.rpaTypes.concat([
            {
                id: -1,
                make: '',
                model: 'Not Listed',
                rpaCategory: null,
                compatibleBatteryTypeIdList: [],
                compatibleBatteryTypeRequirements: {}
            }
        ]);
        rpaModels.sort(compareRpaType);

        this.aircraftModels = rpaModels.concat([
            {
                id: null,
                make: '',
                model: '',
                rpaCategory: null,
                compatibleBatteryTypeIdList: [],
                compatibleBatteryTypeRequirements: {}
            }
        ]);
        this.refreshMakesModels();

        this.rpaType = null;

        this.rpaNewForm.controls.rpaTypeId.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(val => {
                if (val === -1) {
                    this.addMakeModel = true;
                    this.rpaNewForm.controls.make.setValidators(
                        Validators.required
                    );
                    this.rpaNewForm.controls.model.setValidators(
                        Validators.required
                    );
                    this.rpaNewForm.controls.rpaType.setValidators(
                        Validators.required
                    );
                    this.rpaNewForm.controls.propulsion.setValidators(
                        Validators.required
                    );
                } else {
                    this.addMakeModel = false;
                    this.rpaNewForm.controls.make.clearValidators();
                    this.rpaNewForm.controls.model.clearValidators();
                    this.rpaNewForm.controls.rpaType.clearValidators();
                    this.rpaNewForm.controls.propulsion.clearValidators();

                    this.rpaType = this.aircraftModels.find(m => m.id === val);
                }

                this.rpaNewForm.updateValueAndValidity();
                this.changeDetector.detectChanges();
            });

        this.rpaNewForm.controls.make.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(val => {
                this.onMakeUpdated(val);
            });

        this.rpaNewForm.controls.model.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(val => {
                this.onModelUpdated(val);
            });

        this.rpaNewForm.controls.rpaTypeId.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(val => {
                const components = this.rpaNewForm.controls
                    .components as FormArray;
                while (components.controls.length > 0) {
                    components.removeAt(0);
                }
                if (val != null && val !== -1) {
                    this.findComponentsForType(val);
                } else {
                    this.hasComponentsSetUp = false;
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshMakesModels() {
        this.rpaMakes = ['Not Listed'];
        this.rpaModels = ['Not Listed'];
        this.makeListed = true;
        this.modelListed = true;

        this.aircraftModels.forEach(m => {
            if (
                this.rpaMakes.includes(m.make) === false &&
                m.make !== '' &&
                m.make !== 'Not Listed'
            ) {
                this.rpaMakes.push(m.make);
            }
            if (
                this.rpaModels.includes(m.model) === false &&
                m.model !== '' &&
                m.model !== 'Not Listed'
            ) {
                this.rpaModels.push(m.model);
            }
        });
    }

    onMakeUpdated(val: string) {
        if (val === 'Not Listed') {
            this.makeListed = false;
            this.makeValidator = 'makeText';
            this.rpaNewForm.controls.makeText.setValidators(
                Validators.required
            );
            this.rpaNewForm.controls.make.clearValidators();
        } else {
            this.makeListed = true;
            this.makeValidator = 'make';
            this.rpaNewForm.controls.makeText.clearValidators();
            this.rpaNewForm.controls.make.setValidators(Validators.required);
        }

        this.rpaNewForm.updateValueAndValidity();
        this.changeDetector.detectChanges();
    }

    onModelUpdated(val: string) {
        if (val === 'Not Listed') {
            this.modelListed = false;
            this.modelValidator = 'modelText';
            this.rpaNewForm.controls.modelText.setValidators(
                Validators.required
            );
            this.rpaNewForm.controls.model.clearValidators();
        } else {
            this.modelListed = true;
            this.modelValidator = 'model';
            this.rpaNewForm.controls.modelText.clearValidators();
            this.rpaNewForm.controls.model.setValidators(Validators.required);
        }

        this.rpaNewForm.updateValueAndValidity();
        this.changeDetector.detectChanges();
    }

    findComponentsForType(typeId: number) {
        this.resourceTypeComponentsService
            .findResourceSchematicComponents('CRAFT', typeId)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                catchError((error: FlyFreelyError) => {
                    if (error instanceof NotFound) {
                        this.hasComponentsSetUp = false;
                    }
                    return EMPTY;
                })
            )
            .subscribe(result => {
                result.components.sort((a, b) => {
                    if (a.componentType.name < b.componentType.name) {
                        return -1;
                    } else if (a.componentType.name > b.componentType.name) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                result.components.forEach(component => {
                    const name = `${
                        component.componentGroup?.name ??
                        component.componentType.name
                    } ${component.designation}`;
                    const icon =
                        component.componentType.icon != null &&
                        component.componentType.iconFamily != null &&
                        component.componentType.iconFamily === 'FONT_AWESOME'
                            ? `fal fa-${component.componentType.icon}`
                            : null;
                    const group = new FormGroup({
                        name: new FormControl(undefined),
                        id: new FormControl(undefined),
                        icon: new FormControl(undefined),
                        serialNumber: new FormControl(undefined),
                        firmwareVersion: new FormControl(undefined),
                        hasSerialNumber: new FormControl(undefined),
                        hasFirmware: new FormControl(undefined)
                    });
                    group.patchValue({
                        name: name,
                        id: component.id,
                        icon: icon,
                        serialNumber: '',
                        firmwareVersion: '',
                        hasSerialNumber: component.hasSerialNumber,
                        hasFirmware: component.hasFirmwareVersion
                    });
                    (this.rpaNewForm.controls.components as FormArray).push(
                        group
                    );
                });

                this.hasComponentsSetUp = true;
            })
            .add(this.workTracker.createTracker());
    }

    save(addAnother: boolean) {
        const doneWorking = this.workTracker.createTracker();
        const value = this.rpaNewForm.value;
        const make = this.makeListed === true ? value.make : value.makeText;
        const model = this.modelListed === true ? value.model : value.modelText;
        if (value.rpaTypeId == null) {
            const existing = this.aircraftModels.findIndex(
                m => m.make === make && m.model === model
            );

            if (existing !== -1) {
                value.rpaTypeId = this.aircraftModels[existing].id;
            }
        }
        const initialComponents =
            value.components != null && value.components.length > 0
                ? value.components.map((component: any) => ({
                      componentId: component.id,
                      serialNumber:
                          component.serialNumber.length > 0
                              ? component.serialNumber
                              : null,
                      firmwareVersion:
                          component.firmwareVersion.length > 0
                              ? component.firmwareVersion
                              : null
                  }))
                : [];
        const forSaving = {
            id: this.aircraft.id,
            organisationId: this.organisation.id,
            nickname: value.nickname,
            nfcUid: value.nfcUid,
            procurementDate: toLocalDate(value.procurementDate),
            initialFlightTime: value.initialFlightTime
                ? value.initialFlightTime
                : 0,
            rpaTypeId: value.rpaTypeId != null ? value.rpaTypeId : null,
            status: this.aircraft.status,
            manufacturerSerialNumber: value.manufacturerSerialNumber,
            callSign: value.callSign,
            isDummy: value.isDummy || false,
            maximumVisualDistance: value.maximumVisualDistance,
            initialComponentList: initialComponents
        };
        if (value.rpaTypeId !== -1) {
            this.createRpa(forSaving, addAnother, doneWorking);
        } else {
            const command: CreateRpaTypeCommand = {
                make: make,
                model: model,
                organisationId: this.organisation.id,
                physicalSpecifications: {},
                performanceSpecifications: {},
                otherSpecifications: {},
                propulsionSystem: value.propulsion,
                craftType: value.rpaType
            };
            this.rpaTypesService
                .createRpaType(command)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(type => {
                    forSaving.rpaTypeId = type.id;
                    this.createRpa(forSaving, addAnother, doneWorking);
                });
        }
    }

    createRpa(forSaving: any, addAnother: boolean, doneWorking: () => any) {
        return this.craftService.createCraft(forSaving).then(
            savedCraft => {
                this.logging.success('RPA created');
                this.created.next(savedCraft);
                this.rpaNewForm.reset();
                this.rpaNewForm.markAsPristine();
                doneWorking();
                if (addAnother) {
                    this.aircraft = {};
                } else {
                    this.modal.hide();
                }
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while creating RPA: ${error.message}`
                );
                doneWorking();
            }
        );
    }

    invalidFileSelection(files: File[]) {
        if (files == null) {
            return;
        }
        this.logging.error(null, 'This file type is not supported');
    }

    bulkUpload() {
        this.rpaUploadService.showBulkUpload(this.organisation.id);

        this.rpaUploadService.doneImporting$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.modal.hide());
    }

    cancel() {
        this.modal.hide();
    }

    get nickname() {
        return this.rpaNewForm.get('nickname');
    }

    get isDummy() {
        return this.rpaNewForm.get('isDummy');
    }

    get nfcUid() {
        return this.rpaNewForm.get('nfcUid');
    }

    get callSign() {
        return this.rpaNewForm.get('callSign');
    }

    get manufacturerSerialNumber() {
        return this.rpaNewForm.get('manufacturerSerialNumber');
    }

    get maximumVisualDistance() {
        return this.rpaNewForm.get('maximumVisualDistance');
    }

    get rpaTypeId() {
        return this.rpaNewForm.get('rpaTypeId');
    }

    get procurementDate() {
        return this.rpaNewForm.get('procurementDate');
    }

    get initialFlightTime() {
        return this.rpaNewForm.get('initialFlightTime');
    }

    get components() {
        return this.rpaNewForm.get('components') as FormArray;
    }
}
