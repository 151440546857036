import { Component, Input, ViewChild } from '@angular/core';
import {
    AuthorityFilter,
    DownloadService,
    FEATURE_MISSION_REPORTS,
    FlyFreelyError,
    FlyFreelyLoggingService,
    FormReportService,
    FormService,
    MissionReportService,
    WorkTracker,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

// Interface for the Mission Reports Form
interface MissionReportsForm {
    startDate: FormControl<string>;
    endDate: FormControl<string>;
}

// Interface for the Form Reports Form
interface FormReportsForm {
    startDate: FormControl<string>;
    endDate: FormControl<string>;
    formVersionId: FormControl<number>;
}

// Interface for the Flight Reports Form
interface FlightReportsForm {
    startDate: FormControl<string>;
    endDate: FormControl<string>;
    withFlightLogs: FormControl<boolean>;
}

@Component({
    selector: 'mission-reports-dialogue',
    templateUrl: './mission-reports-dialogue.component.html'
})
export class MissionReportsDialogue {
    @Input() organisationId: number;
    @Input() orOrganisationId: number;

    @Input() authorityIds: number[];
    @Input() canFilterByAuthority: boolean;
    @Input() missionAuthorityFilters: {
        name: string;
        children: AuthorityFilter[];
    }[];
    @Input() authorityFilter: AuthorityFilter;

    @ViewChild(ScreenAnalyticsDirective)
    private screenAnalytics: ScreenAnalyticsDirective;

    forms: any[];

    missionReportsForm: FormGroup<MissionReportsForm>;
    formReportsForm: FormGroup<FormReportsForm>;
    flightReportsForm: FormGroup<FlightReportsForm>;

    missionReportsFeature = [FEATURE_MISSION_REPORTS];

    public working: boolean;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<MissionReportsDialogue>,
        private missionReportService: MissionReportService,
        private logging: FlyFreelyLoggingService,
        private formService: FormService,
        private formReportService: FormReportService,
        private downloadService: DownloadService
    ) {
        this.missionReportsForm = new FormGroup<MissionReportsForm>({
            startDate: new FormControl<string>(undefined, [
                Validators.required
            ]),
            endDate: new FormControl<string>(undefined, [])
        });
        this.formReportsForm = new FormGroup<FormReportsForm>({
            startDate: new FormControl<string>(undefined, [
                Validators.required
            ]),
            endDate: new FormControl<string>(undefined, [Validators.required]),
            formVersionId: new FormControl<number>(undefined, [
                Validators.required
            ])
        });
        this.flightReportsForm = new FormGroup<FlightReportsForm>({
            startDate: new FormControl<string>(undefined, [
                Validators.required
            ]),
            endDate: new FormControl<string>(undefined, [Validators.required]),
            withFlightLogs: new FormControl<boolean>(false, [
                Validators.required
            ])
        });
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshForms();

        this.formService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshForms();
            });
    }

    refreshForms() {
        const { startDate, endDate } = this.formReportsForm.value;
        if (startDate == null || endDate == null) {
            this.forms = [];
            return;
        }

        this.formService
            .findForms(this.organisationId)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(forms =>
                    this.formReportService.findVersionUsageCount(
                        this.organisationId,
                        this.orOrganisationId,
                        forms.map(f => f.id),
                        this.authorityIds,
                        toTimestamp(moment(startDate).startOf('day').toDate()),
                        toTimestamp(moment(endDate).startOf('day').toDate())
                    )
                )
            )
            .subscribe({
                next: versions => {
                    const formVersionCounts = versions.map(v => ({
                        id: v.formVersion.id,
                        formName: v.formVersion.formName,
                        versionNumber: v.formVersion.versionNumber,
                        missionCount: v.missionCount
                    }));
                    this.forms = formVersionCounts.reduce(
                        (acc, v) => acc.concat(v),
                        []
                    );
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while loading form versions: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    runFormReport() {
        this.screenAnalytics.track('form-report', 'download');

        const { startDate, endDate, formVersionId } =
            this.formReportsForm.value;
        this.formReportService.downloadFormReport(
            this.organisationId,
            this.orOrganisationId,
            this.authorityIds,
            formVersionId,
            toTimestamp(moment(startDate).startOf('day').toDate()),
            toTimestamp(moment(endDate).endOf('day').toDate())
        );
        this.hide();
    }

    runMissionReport() {
        this.screenAnalytics.track('mission-report', 'download');

        const { startDate, endDate } = this.missionReportsForm.value;

        const startTime = moment(startDate).startOf('day').toDate();
        const endTime =
            endDate != null ? moment(endDate).endOf('day').toDate() : null;

        this.downloadService.downloadFromUrl(
            this.missionReportService.getMissionListReportUrl(
                this.organisationId,
                this.orOrganisationId,
                this.authorityIds,
                toTimestamp(startTime),
                toTimestamp(endTime)
            )
        );
        this.hide();
    }

    runFlightReport() {
        this.screenAnalytics.track('flight-report', 'download');

        const { startDate, endDate, withFlightLogs } =
            this.flightReportsForm.value;

        const startTime = moment(startDate).startOf('day').toDate();
        const endTime =
            endDate != null ? moment(endDate).endOf('day').toDate() : null;

        this.downloadService.downloadFromUrl(
            this.missionReportService.getFlightReportUrl(
                this.organisationId,
                this.orOrganisationId,
                this.authorityIds,
                toTimestamp(startTime),
                toTimestamp(endTime),
                withFlightLogs
            )
        );
        this.hide();
    }

    updateMissionAuthorityFilter() {
        this.orOrganisationId = this.authorityFilter.orOrganisationId;
        this.authorityIds = this.authorityFilter.authorityIds;
        this.organisationId = this.authorityFilter.organisationId;
    }

    hide() {
        this.modal.hide();
    }
}
