<quill-editor
    [(ngModel)]="content"
    format="object"
    style="width: 100%"
></quill-editor>
<div class="pull-right" style="margin-top: 10px">
    <span
        *ngIf="hasCancel"
        (click)="onCancel()"
        style="margin-right: 10px; cursor: pointer"
    >
        Cancel
    </span>
    <span
        *ngIf="hasSave"
        class="text-info"
        (click)="onSave()"
        style="cursor: pointer; margin-bottom: 10px; display: block"
    >
        Save
    </span>
</div>
