import { Component, Input } from '@angular/core';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { Subscription } from 'rxjs';

@Component({
    selector: 'confirmation-dialogue',
    templateUrl: './confirmation-dialogue.component.html'
})
export class ConfirmationDialogue {
    @Input() title: string;
    @Input() message: string;
    @Input() action: string;
    @Input() fn: () => Promise<any>;

    // We pass in callbacks to be able to utilise the legacy
    // wrapped dialogues. This should be changed eventually.
    @Input() close: (result: any) => void;
    @Input() dismiss: () => void;
    @Input() dontAskAgainFunction: (value: boolean) => Promise<boolean>;

    showDontAskAgain = false;
    dontAskAgain: boolean;

    private workTracker: WorkTracker = new WorkTracker();
    private workTrackerSubscription: Subscription;
    working: boolean;

    ngOnInit(): void {
        this.workTrackerSubscription = this.workTracker
            .observable
            .subscribe(working => (this.working = working));
        if (this.dontAskAgainFunction != null) {
            this.showDontAskAgain = true;
        }
    }

    ngOnDestroy() {
        this.workTrackerSubscription.unsubscribe();
    }

    ok() {
        const doneWorking = this.workTracker.createTracker();
        if (this.dontAskAgainFunction != null && this.dontAskAgain != null) {
            this.dontAskAgainFunction(this.dontAskAgain);
        }
        this.fn()
            .then((response: any) => this.close(response))
            .finally(() => doneWorking());
    }

    hide() {
        this.dismiss();
    }
}
