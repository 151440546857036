import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import {
    FormVersionMissionCountDto,
    FormVersionMissionSearchCriteria
} from '../model/api';
import { DownloadService } from './download.service';
import { computePath, httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class FormReportService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();
    private window: Window;

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private downloadService: DownloadService,
        @Inject('Window') window: Window
    ) {
        this.baseUrl = constants.SITE_URL;
        this.window = window;
    }

    findVersionUsageCount(
        organisationId: number,
        orOrganisationId: number,
        formIdList: number[],
        authorityIds: number[],
        startTime: string,
        endTime: string
    ) {
        const criteria: FormVersionMissionSearchCriteria = {
            organisationId,
            orOrganisationId,
            formIdList,
            authorityIds,
            startTime,
            endTime
        };

        return this.http.get<FormVersionMissionCountDto[]>(
            `${this.baseUrl}/webapi/formReports/formVersionCount`,
            { params: httpParamSerializer(criteria) }
        );
    }

    downloadFormReport(
        organisationId: number,
        orOrganisationId: number,
        authorityIds: number[],
        formVersionId: number,
        startTime: string,
        endTime: string
    ) {
        const queryString = this.httpParamSerializer({
            organisationId,
            orOrganisationId,
            authorityIds,
            startTime,
            endTime,
            formVersionId
        });

        this.downloadService.downloadFromUrl(
            computePath(`/webapi/formReports/missions/csv?${queryString}`)
        );
    }

    httpParamSerializer(obj?: any) {
        let query = '';
        for (const key in obj) {
            if (
                Object.prototype.hasOwnProperty.call(obj, key) &&
                obj[key] != null
            ) {
                query += `${key}=${obj[key]}&`;
            }
        }
        return query.slice(0, query.length - 1);
    }
}
