import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit {
    @Input() hasCancel = true;
    @Input() hasSave = true;
    @Input() content: string | Object | undefined;
    @Output() saveEdit = new EventEmitter<Object>();
    @Output() cancelEdit = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
    onSave() {
        this.saveEdit.emit(this.content);
        this.content = '';
    }

    onCancel() {
        this.cancelEdit.emit();
    }
}
