<div
    class="static-table-container"
    [ngClass]="{
        'no-actions': actionColumnWidth === '0' || hideActionColumn,
        'table-borderless': isBorderless == true,
        'action-wide':
            !hideActionColumn &&
            (tableConfig.actions?.length >= 2 || rowActionsTemplate != null)
    }"
    [ngStyle]="{
        'margin-right': actionColumnWidth
    }"
    #myTable
>
    <table class="table">
        <thead>
            <tr>
                <th *ngIf="hasRowSelection"></th>
                <th
                    *ngFor="let column of columns"
                    (click)="changeSortColumn(column.key, column.sortable)"
                    [ngClass]="{
                        'centered-heading': centeredHeadings,
                        sortable: column.sortable
                    }"
                    angulartics2On="click"
                    angularticsAction="table-sort"
                    [tooltip]="column.description || column.name"
                    placement="top"
                    [enhancedHelp]="column.name + '-column'"
                    [enhancedHelpOptional]="true"
                    [enhancedHelpShowOnActivate]="false"
                >
                    {{ column.name }}
                    <ng-container *ngIf="column.sortable">
                        <span
                            *ngIf="column.key == sortColumn && !ascending"
                            class="fa fa-sort-down"
                        ></span>
                        <span
                            *ngIf="column.key == sortColumn && ascending"
                            class="fa fa-sort-up"
                        ></span>
                        <span
                            *ngIf="column.key != sortColumn"
                            class="fal fa-sort"
                        ></span>
                    </ng-container>
                </th>
                <th class="actions" *ngIf="!hideActionColumn">
                    <button
                        *ngIf="
                            (selectableColumns != null &&
                                selectableColumns.length > 0)
                        "
                        type="button"
                        #tablePopover="bs-popover"
                        class="btn btn-tertiary"
                        [popover]="popTemplate"
                        container="body"
                        [outsideClick]="true"
                        [adaptivePosition]="true"
                        placement="left"
                        angulartics2On="click"
                        angularticsAction="table-columns-view"
                    >
                        <span class="fa fa-ellipsis-h"></span>
                    </button>
                </th>
            </tr>
            <tr [formGroup]="searchGroup" *ngIf="hasSearchRow">
                <td
                    *ngIf="hasRowSelection"
                    style="padding: 6px 10px; width: 20px"
                >
                    <input
                        type="checkbox"
                        [checked]="selectAll$ | async"
                        (change)="toggleSelectAll($event)"
                    />
                </td>
                <td
                    *ngFor="
                        let column of columns;
                        let i = index;
                        trackBy: trackByFn
                    "
                >
                    <ng-container [ngSwitch]="column.searchable">
                        <div
                            *ngSwitchCase="'date'"
                            class="input-group input-group-sm"
                        >
                            <input
                                class="form-control"
                                [ngClass]="{
                                    'input-clear-active': hasSearchValue(
                                        column.key
                                    )
                                }"
                                #datePicker="bsDatepicker"
                                bsDatepicker
                                formControlName="{{ column.key }}"
                            />
                            <span
                                *ngIf="hasSearchValue(column.key)"
                                class="input-group-addon input-group-clear calendar"
                                (click)="clearSearch(column.key)"
                            >
                                <i class="fal fa-times"></i>
                            </span>
                            <span
                                class="input-group-addon btn-default"
                                (click)="datePicker.toggle()"
                                [attr.aria-expanded]="datePicker.isOpen"
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>
                        <div
                            *ngSwitchCase="'daterange'"
                            class="input-group input-group-sm"
                        >
                            <input
                                class="form-control"
                                [ngClass]="{
                                    'input-clear-active': hasSearchValue(
                                        column.key
                                    )
                                }"
                                #datePicker="bsDaterangepicker"
                                bsDaterangepicker
                                [bsConfig]="{ adaptivePosition: false}"
                                placement="bottom"
                                [container]="'body'"
                                formControlName="{{ column.key }}"
                            />
                            <span
                                *ngIf="hasSearchValue(column.key)"
                                class="input-group-addon input-group-clear calendar"
                                (click)="clearSearch(column.key)"
                            >
                                <i class="fal fa-times"></i>
                            </span>
                            <span
                                class="input-group-addon btn-default"
                                (click)="datePicker.toggle()"
                                [attr.aria-expanded]="datePicker.isOpen"
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>
                        <ng-select
                            *ngSwitchCase="'selection'"
                            [items]="column.searchOptions"
                            bindLabel="name"
                            bindValue="value"
                            formControlName="{{ column.key }}"
                            appendTo="body"
                            class="wide-panel"
                            [clearable]="
                                column.clearableSearch != null
                                    ? column.clearableSearch
                                    : true
                            "
                            [placeholder]="
                                column.searchPlaceholder
                                    ? column.searchPlaceholder
                                    : 'Search'
                            "
                        >
                        </ng-select>
                        <div
                            *ngSwitchCase="'text'"
                            class="inner-addon left-addon"
                        >
                            <span class="fa fa-search"></span>
                            <input
                                type="text"
                                class="form-control input-sm"
                                [ngClass]="{
                                    'input-clear-active': hasSearchValue(
                                        column.key
                                    )
                                }"
                                formControlName="{{ column.key }}"
                                data-cy="tableTextInput"
                            />
                            <span
                                *ngIf="hasSearchValue(column.key)"
                                class="input-group-clear text"
                                (click)="clearSearch(column.key)"
                            >
                                <i class="fal fa-times"></i>
                            </span>
                        </div>
                    </ng-container>
                </td>
                <td class="actions" *ngIf="!hideActionColumn"></td>
            </tr>
        </thead>
        <tbody data-cy="tableBody">
            <ng-container>
                <tr
                    *ngFor="
                        let row of data | slice : startItem : endItem;
                        let i = index;
                        let even = even
                    "
                    [ngClass]="{
                        'table-rows-comfortable':
                            (activeScan$ | async) === 'comfortable',
                        'row-even':
                            even && (activeScan$ | async) === 'alternate',
                        'table-underlined-rows': underlinedRows
                    }"
                >
                    <td *ngIf="hasRowSelection">
                        <row-selector [row]="row.data"></row-selector>
                    </td>
                    <td
                        *ngFor="
                            let column of columns;
                            let first = first;
                            let last = last
                        "
                        [ngClass]="{
                            'text-centered': centeredColumns,
                            unseen: !isSeen(row),
                            'row-warning': isWarning(row),
                            first: first,
                            last:
                                (tableConfig.actions == null ||
                                    tableConfig.actions.length == 0) &&
                                rowActionsTemplate == null &&
                                last
                        }"
                    >
                        <!-- If any specific formatting is needed a template can be used instead of relying on embedded HTML in the column value -->
                        <ng-container
                            *ngIf="templateLookup[column.key] != null"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    templateLookup[column.key];
                                    context: { row: row.data, column: column }
                                "
                            ></ng-container>
                        </ng-container>

                        <!-- Don't use [innerHTML] in the standard columns since not all the data displayed there can be sanitised -->
                        <ng-container
                            *ngIf="templateLookup[column.key] == null"
                        >
                            <span *ngIf="column.linkFunction == null">
                                {{ row[column.key] }}
                            </span>
                            <a
                                *ngIf="column.linkFunction != null"
                                (click)="column.linkFunction(row.data)"
                            >
                                {{ row[column.key] }}
                            </a>
                        </ng-container>
                    </td>
                    <td
                        class="actions"
                        *ngIf="!hideActionColumn"
                        [ngClass]="{
                            'text-centered': centeredColumns,
                            unseen: !isSeen(row),
                            last: true,
                            comfortable: (activeScan$ | async) === 'comfortable'
                        }"
                    >
                        <ng-container
                            *ngFor="let action of tableConfig.actions"
                        >
                            <button
                                *ngIf="action.icon == 'fal fa-trash-alt'"
                                class="btn btn-tertiary btn-delete"
                                type="button"
                                (click)="action.action(row.data)"
                                tooltip="{{ action.tooltip }}"
                                placement="left"
                            >
                                <span [ngClass]="action.icon"></span>
                            </button>
                            <button
                                *ngIf="action.icon != 'fal fa-trash-alt'"
                                class="btn btn-tertiary"
                                type="button"
                                (click)="action.action(row.data)"
                                tooltip="{{ action.tooltip }}"
                                placement="left"
                            >
                                <span [ngClass]="action.icon"></span>
                            </button>
                        </ng-container>
                        <ng-container
                            *ngIf="rowActionsTemplate != null"
                            [ngTemplateOutlet]="rowActionsTemplate.tpl"
                            [ngTemplateOutletContext]="{ row: row.data }"
                        ></ng-container>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="data?.length === 0">
                <td [attr.colspan]="columns.length">No data to display</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="action-button-container">
    <div
        class="fcol-4 count-buttons"
        [ngClass]="{
            'hide-display': !(
                itemLimit != null && tableConfig.limitSelection != null
            )
        }"
    >
        <ng-select
            [items]="tableConfig.limitSelection || []"
            [(ngModel)]="itemLimit"
            (change)="setLimitPage($event)"
            class="pagination-dropdown dropup"
            [appendTo]="'body'"
            [clearable]="false"
            [searchable]="false"
            placeholder="Items per page"
        >
        </ng-select>
        <ng-container *ngIf="(selectedRow$ | async)?.length > 0">
            <button
                *ngFor="let action of tableConfig.bulkActions"
                type="button"
                class="btn btn-default btn-sm left-buffer"
                placement="top"
                [tooltip]="action.tooltip"
                [disabled]="working"
                (click)="doBulkAction(action)"
            >
                {{ action.name }}
            </button>
        </ng-container>
    </div>

    <div *ngIf="showPagination == true" class="fcol-4 pagination-table">
        <pagination
            [totalItems]="totalItems"
            [(ngModel)]="currentPage"
            (pageChanged)="onPageChanged($event)"
            class="pagination-sm"
            [itemsPerPage]="itemLimit"
            [boundaryLinks]="true"
            [maxSize]="5"
        >
        </pagination>
    </div>

    <div *ngIf="scanability !== 'SELECTABLE'" class="fcol-4"></div>
    <div
        *ngIf="scanability === 'SELECTABLE'"
        class="fcol-4 scanability-buttons"
    >
        <button
            class="comfortable"
            [disabled]="(activeScan$ | async) === 'alternate'"
            type="button"
            (click)="setScanType('alternate')"
            [ngClass]="{
                active: (activeScan$ | async) === 'alternate'
            }"
        >
            <span class="fa fa-list"></span>
        </button>
        <button
            class="alternating"
            [disabled]="(activeScan$ | async) === 'comfortable'"
            type="button"
            (click)="setScanType('comfortable')"
            [ngClass]="{
                active: (activeScan$ | async) === 'comfortable'
            }"
        >
            <span class="fa fa-line-height"></span>
        </button>
    </div>
</div>

<ng-template #popTemplate>
    <div>
        <ng-container
            *ngIf="selectableColumns != null && selectableColumns.length > 0"
        >
            <div *ngFor="let col of selectableColumns">
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            [id]="col.value"
                            (click)="toggle(col)"
                            [checked]="isChecked(col.key ? col.key : col.value)"
                        />
                        <span class="cr"
                            ><i class="cr-icon glyphicon glyphicon-ok"></i>
                        </span>
                        {{ col.name }}</label
                    >
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
