import { AttachmentHandler } from './attachments';
import { UpdateLibraryCommand, LibraryDto, CreateLibraryCommand } from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LibraryService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findLibraries(organisationId: number) {
        return this.http.get<LibraryDto[]>(`${this.baseUrl}/webapi/libraries`, {
            params: new HttpParams().set(
                'organisationId',
                organisationId.toString()
            )
        });
    }

    findLibraryById(libraryId: number, managingOrganisationId: number) {
        let params = new HttpParams();
        if (managingOrganisationId) {
            params = params.set(
                'managingOrganisationId',
                managingOrganisationId.toString()
            );
        }
        return this.http.get<LibraryDto>(
            `${this.baseUrl}/webapi/libraries/${libraryId}`,
            {
                params: params
            }
        );
    }

    createLibrary(createLibraryCommand: CreateLibraryCommand) {
        return this.http
            .post<LibraryDto>(
                `${this.baseUrl}/webapi/libraries`,
                createLibraryCommand
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateLibrary(
        libraryId: number,
        updateLibraryCommand: UpdateLibraryCommand
    ) {
        return this.http
            .put<LibraryDto>(
                `${this.baseUrl}/webapi/libraries/${libraryId}`,
                updateLibraryCommand
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteLibrary(libraryId: number) {
        return this.http
            .delete<void>(`${this.baseUrl}/webapi/libraries/${libraryId}`)
            .pipe(tap(() => this.changeSource.next()));
    }

    attachmentHandler(libraryId: number, managingOrganisationId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/libraries/${libraryId}/attachments`,
            false,
            managingOrganisationId,
            true
        );
    }
}
