import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Optional,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    deepCopy,
    ExclusiveControlService
} from '@flyfreely-portal-ui/flyfreely';
import { fromEvent, Subject } from 'rxjs';
import { filter, switchMapTo, take, takeUntil } from 'rxjs/operators';
import { EditModeDirective } from './edit-mode.directive';
import { ViewModeDirective } from './view-mode.directive';
import { EditHelpDirective } from './edit-help.directive';

/**
 * This component is designed to provide a clickable element that switches from a view template to
 * an edit template.
 *
 * The state of the edit template is intended to be managed by this component, using the `initialContext`
 * input, which is then exposed to the edit template as `context`. `initialContext` must be an object.
 *
 * There is also `state` which has the shape
 * `{ saving: boolean; error: string; }` and which is available in the edit template to manage error message
 * and input state.
 *
 *  e.g.,
 * ```html
    <editable [initialContext]="stripeCustomer.value" (update)="updateStripeCustomer($event)">
        <ng-template viewMode>
            <div class="input-group">
                <span>{{ stripeCustomer.value }}</span>
            </div>
        </ng-template>
        <ng-template editMode let-customer="context" let-state="state">
            <input
                type="text"
                [(ngModel)]="customer.identifier"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                editableOnEnter
                [readonly]="state.saving"
            />
            <div class="text-danger" *ngIf="state.error != null">
                {{ state.error }}
            </div>
        </ng-template>
    </editable>
 * ```
 * When the component leaves the edit state the updated context is emitted.
 *
 * Additionally, the `cancel()` and `submit()` methods are passed to the template to allow
 * inline buttons to interact with the container.
 */
@Component({
    selector: 'editable',
    template: `
        <ng-container *ngIf="!confirmationButtons || viewMode">
            <div class="horizontal-container">
                <div
                    [ngClass]="{ 'clickable-content': !editButton }"
                    class="fill"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            currentView;
                            context: {
                                context: context,
                                state: state,
                                cancel: cancel,
                                submit: submit
                            }
                        "
                    ></ng-container>
                </div>
                <div *ngIf="viewMode && editButton">
                    <button
                        class="btn btn-xs btn-tertiary"
                        type="button"
                        tooltip="Edit"
                        placement="top"
                        [disabled]="disabled"
                        (click)="enterEditMode($event)"
                    >
                        <span class="fal fa-pencil"></span>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="confirmationButtons && !viewMode">
            <div class="horizontal-container">
                <div class="fill">
                    <ng-container
                        *ngTemplateOutlet="
                            currentView;
                            context: {
                                context: context,
                                state: state,
                                cancel: cancel,
                                submit: submit
                            }
                        "
                    ></ng-container>
                </div>
                <div>
                    <button
                        *ngIf="submitButton"
                        class="btn btn-xs btn-tertiary"
                        type="button"
                        tooltip="Accept"
                        placement="top"
                        [disabled]="disabled"
                        (click)="submit($event)"
                    >
                        <span class="fa fa-check"></span>
                    </button>
                    <button
                        class="btn btn-xs btn-tertiary btn-delete"
                        type="button"
                        tooltip="Cancel"
                        placement="top"
                        [disabled]="disabled || editOnly"
                        (click)="cancel($event)"
                    >
                        <span class="fa fa-times"></span>
                    </button>
                </div>
            </div>
            <div *ngIf="editHelpTpl != null">
                <ng-container
                    *ngTemplateOutlet="editHelpTpl.tpl"
                ></ng-container>
            </div>
        </ng-container>
    `,
    styles: [
        `
            .form-inline :host {
                display: inline-block;
            }
            :host {
                display: block;
            }

            :host .horizontal-container {
                align-items: center;
            }

            :host(.view) .clickable-content {
                text-decoration-style: dashed;
                text-decoration-line: underline;
                text-decoration-color: blue;
                cursor: pointer;
            }
        `
    ]
})
export class EditableComponent {
    @ContentChild(ViewModeDirective, { static: true })
    viewModeTpl: ViewModeDirective;

    @ContentChild(EditModeDirective, { static: true })
    editModeTpl: EditModeDirective;

    @ContentChild(EditHelpDirective, { static: true })
    editHelpTpl: EditHelpDirective;

    /**
     * On return from view mode
     */
    @Output() update = new EventEmitter<any>();

    /**
     * Emits on mode change, with a value of whether the new mode
     * is view mode.
     */
    @Output() modeChange = new EventEmitter<boolean>();

    @Output() clickCancel = new EventEmitter<boolean>();

    /**
     * Open the component in edit mode and prevent cancelling.
     */
    @Input() editOnly: boolean;

    @Input() cancelOnClickOutside = true;

    @Input() disabled = false;

    /**
     * Should we show an edit button instead of making the content clickable to edit.
     */
    @Input() editButton = false;
    /**
     * Should we show an check mark submit button in edit mode?
     */
    @Input() submitButton = true;

    /**
     * This is the value object which is provided as the starting point for the edit view
     */
    @Input() initialContext: { [key: string]: any };

    /**
     * Show built in confirmation and cancel buttons. It is suggested that cancelOnClickOutside be set false.
     */
    @Input() confirmationButtons = false;

    /**
     * If provided, this will be used to perform the update operation. The value that is passed is the context object.
     */
    @Input() updateFn: (value: { [key: string]: any }) => Promise<void>;

    context: { [key: string]: any };

    private ngUnsubscribe$ = new Subject<void>();

    private editMode = new Subject<boolean>();
    editMode$ = this.editMode.asObservable();

    private notifyExclusiveControlService: () => void;

    @HostBinding('class.view')
    viewMode = true;

    state = { saving: false, error: null as string };

    constructor(
        private host: ElementRef,
        @Optional() private exclusiveControlService: ExclusiveControlService
    ) {}

    ngOnInit() {
        this.viewModeHandler();
        this.editModeHandler();
        this.viewMode = !this.editOnly;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('editOnly' in changes) {
            this.viewMode = !this.editOnly;
        }

        if ('initialContext' in changes) {
            if (
                this.initialContext != null &&
                typeof this.initialContext !== 'object'
            ) {
                throw new Error('initialContext must be an object');
            }
        }
    }

    ngOnDestroy(): void {
        this.editMode.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Triggers the return to view mode, and the emitting of the updated
     * context value.
     */
    submit = ($event?: Event) => {
        if ($event != null) {
            $event.stopPropagation();
        }
        if (this.updateFn != null) {
            this.state.saving = true;
            this.state.error = null;
            this.updateFn(this.context)
                .then(
                    () => this.doneSubmitting(),
                    error => {
                        if (typeof error === 'string') {
                            this.state.error = error;
                        } else {
                            this.state.error = 'An error occurred while saving';
                        }
                    }
                )
                .finally(() => (this.state.saving = false));

            return;
        }
        this.doneSubmitting();
    };

    /**
     * This performs any UI transitions following a submit
     */
    private doneSubmitting() {
        if (this.notifyExclusiveControlService) {
            this.notifyExclusiveControlService();
        }
        if (this.editOnly) {
            return;
        }
        this.update.next(this.context);
        this.viewMode = true;
        this.modeChange.next(this.viewMode);
    }

    /**
     * Triggers the return to view mode, without emitting an updated value.
     */
    cancel = ($event?: Event) => {
        if ($event != null) {
            $event.stopPropagation();
        }
        if (this.notifyExclusiveControlService) {
            this.notifyExclusiveControlService();
        }
        if (this.editOnly) {
            return;
        }
        this.viewMode = true;
        this.clickCancel.next(this.viewMode);
        this.modeChange.next(this.viewMode);
    };

    enterEditMode($event?: Event) {
        if ($event != null) {
            $event.stopPropagation();
        }
        this.viewMode = false;

        this.context = deepCopy(this.initialContext);

        this.modeChange.next(this.viewMode);
        this.editMode.next(true);
    }

    private get element() {
        return this.host.nativeElement;
    }

    private viewModeHandler() {
        fromEvent<MouseEvent>(this.element, 'click')
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    () =>
                        this.disabled === false &&
                        this.viewMode === true &&
                        this.editButton === false
                )
            )
            .subscribe(e => {
                this.enterEditMode(e);
            });
    }

    private editModeHandler() {
        if (this.exclusiveControlService) {
            this.editMode$.pipe(filter(editMode => editMode)).subscribe(
                () =>
                    (this.notifyExclusiveControlService =
                        this.exclusiveControlService.lock(() => {
                            this.cancel();
                            return true;
                        }))
            );
        }

        if (!this.cancelOnClickOutside) {
            return;
        }

        // FIXME clean up listeners when enter is used instead
        const clickOutside$ = fromEvent(document, 'click').pipe(
            filter(
                ({ target }) =>
                    // Ensures that the element is still conencted to the DOM, else the subsequent test will fail
                    (<Node>target).isConnected &&
                    this.element.contains(target) === false
            ),
            take(1)
        );

        this.editMode$
            .pipe(switchMapTo(clickOutside$), takeUntil(this.ngUnsubscribe$))
            .subscribe(event => this.cancel());
    }

    get currentView() {
        return this.viewMode ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
    }
}
