import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * Generate the Formly fields for editing a person.
 *
 * Note, we don't put validation on disabled fields, as disabled fields are always invalid as per Angular/W3C spec.
 * @param disableEmailUsername should the email and username be disabled
 * @param sendNotification should the send notification option be available
 * @param canEdit should the form be editable
 */
export function editPersonFields(
    disableEmailUsername: boolean,
    sendNotification: boolean,
    canEdit: boolean
): FormlyFieldConfig[] {
    const fields: FormlyFieldConfig[] = [
        {
            key: 'firstName',
            type: 'input',
            props: {
                label: 'First Name',
                required: canEdit,
                disabled: !canEdit
            }
        },
        {
            key: 'lastName',
            type: 'input',
            props: {
                label: 'Last Name',
                required: canEdit,
                disabled: !canEdit
            }
        },
        {
            key: 'email',
            type: 'input',
            props: {
                label: 'Email Address',
                required: canEdit && !disableEmailUsername,
                disabled: disableEmailUsername || !canEdit
            }
        },
        {
            key: 'phoneNumber',
            type: 'input',
            props: {
                label: 'Phone Number',
                required: false,
                disabled: !canEdit
            }
        }
    ];

    if (sendNotification) {
        return fields.concat({
            key: 'sendNotification',
            type: 'checkbox',
            defaultValue: true,
            props: {
                label: 'Send Notification',
                description:
                    'Should an email notification be sent to the user about their new account?',
                disabled: !canEdit
            }
        });
    } else {
        return fields;
    }
}
