/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UpdateRpaTypeConfigCommand { 
    /**
     * The organisation that owns this configuration
     */
    organisationId: number;
    /**
     * The optional RPA type this configuration applies to
     */
    rpaTypeId?: number;
    /**
     * The RPA category this configuration applies to
     */
    rpaCategory?: UpdateRpaTypeConfigCommand.RpaCategory;
    /**
     * The optional authority that this configuration applies to. If null it is the organisation default
     */
    authorityId?: number;
    /**
     * The RPA workflow that is used in this configuration
     */
    workflowId?: number;
    /**
     * The RPA maintenance schedule that is used in this configuration
     */
    maintenanceScheduleId?: number;
}
export namespace UpdateRpaTypeConfigCommand {
    export type RpaCategory = 'MULTIROTOR' | 'HELICOPTOR' | 'AEROPLANE' | 'POWERED_LIFT' | 'AIRSHIP' | 'OTHER';
    export const RpaCategory = {
        MULTIROTOR: 'MULTIROTOR' as RpaCategory,
        HELICOPTOR: 'HELICOPTOR' as RpaCategory,
        AEROPLANE: 'AEROPLANE' as RpaCategory,
        POWERED_LIFT: 'POWERED_LIFT' as RpaCategory,
        AIRSHIP: 'AIRSHIP' as RpaCategory,
        OTHER: 'OTHER' as RpaCategory
    };
}



