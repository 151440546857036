import { Injectable } from '@angular/core';
import {
    LoggedInUser,
    PersonsOrganisationDto,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';
import {
    CurrentOrganisation,
    SelectedOrganisation,
    WidgetStatus,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface WidgetRegistrationWithSection extends WidgetRegistration {
    section: 'general' | 'operations' | 'resources' | 'organisation';
}
export interface OutstandingTasksViewSectionsSelection {
    generalTasks: boolean;
    operations: boolean;
    resources: boolean;
    organisation: boolean;
}
@Injectable()
export class RegisteredTaskWidgets {
    constructor(
        public registeredTaskWidgets: WidgetRegistrationWithSection[]
    ) {}
}

@Injectable()
export class OutstandingTasksViewService {
    private scrollToSectionSubject = new Subject<string>();
    scrollToSection$ = this.scrollToSectionSubject.asObservable();

    private availableTestWidgetsSubject = new ReplaySubject<WidgetStatus>();
    availableTestWidgets$ = this.availableTestWidgetsSubject.asObservable();

    private selectedSectionsSource =
        new BehaviorSubject<OutstandingTasksViewSectionsSelection>({
            generalTasks: true,
            operations: true,
            resources: true,
            organisation: true
        });
    selectedSections$ = this.selectedSectionsSource.asObservable();

    private organisation: PersonsOrganisationDto;
    private personalOrganisation: PersonsOrganisationDto;

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        public registeredTaskWidgets: RegisteredTaskWidgets,
        private workspaceStateService: WorkspaceStateService,
        private userService: UserService
    ) {
        userService.userChange$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(c => c.type === UserStatus.LOGGED_IN)
            )
            .subscribe((change: LoggedInUser) => {
                this.personalOrganisation =
                    userService.getPersonalOrganisation();
                this.refreshAvailableWidgets();
            });
        this.workspaceStateService.currentOrganisation$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    (o: SelectedOrganisation) =>
                        o.type === 'organisation_loaded'
                )
            )
            .subscribe((organisation: CurrentOrganisation) => {
                this.organisation = organisation.organisation;
                this.refreshAvailableWidgets();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.scrollToSectionSubject.complete();
        this.availableTestWidgetsSubject.complete();
        this.selectedSectionsSource.complete();
    }

    public scrollTo(sectionName: string) {
        this.scrollToSectionSubject.next(sectionName);
    }

    private refreshAvailableWidgets() {
        if (this.personalOrganisation == null || this.organisation == null) {
            return;
        }
        const updatedAvailableWidgets: WidgetStatus =
            this.registeredTaskWidgets.registeredTaskWidgets.reduce(
                (acc, reg) => ({
                    ...acc,
                    [reg.widgetIdentifier]: {
                        available:
                            reg.isAvailableForUser != null
                                ? reg.isAvailableForUser(
                                      this.personalOrganisation
                                  )
                                : false || reg.isAvailable(this.organisation),
                        // For now all widgets are always visible in this list.
                        selected: true
                    }
                }),
                {} as WidgetStatus
            );
        this.availableTestWidgetsSubject.next(updatedAvailableWidgets);
    }

    splitWidgetsBySection(availableWidgets: WidgetStatus) {
        const generalTasksWidgets =
            this.registeredTaskWidgets.registeredTaskWidgets.filter(
                w =>
                    w.section === 'general' &&
                    availableWidgets[w.widgetIdentifier].available
            );
        const operationsWidgets =
            this.registeredTaskWidgets.registeredTaskWidgets.filter(
                w =>
                    w.section === 'operations' &&
                    availableWidgets[w.widgetIdentifier].available
            );
        const resourcesWidgets =
            this.registeredTaskWidgets.registeredTaskWidgets.filter(
                w =>
                    w.section === 'resources' &&
                    availableWidgets[w.widgetIdentifier].available
            );
        const organisationWidgets =
            this.registeredTaskWidgets.registeredTaskWidgets.filter(
                w =>
                    w.section === 'organisation' &&
                    availableWidgets[w.widgetIdentifier].available
            );

        return [
            generalTasksWidgets,
            operationsWidgets,
            resourcesWidgets,
            organisationWidgets
        ];
    }

    updateSelectedSections(sections: OutstandingTasksViewSectionsSelection) {
        this.selectedSectionsSource.next(sections);
    }
}
