import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AdminOrganisationDto,
    AuthorityFilter,
    CompleteSortieCommand,
    DisplayableDocumentation,
    DisplayableMissionDto,
    FormResponseFormGroup,
    getMissionWorkflowSteps,
    LocationDetailsDto,
    MissionDetailsDto,
    MissionSummaryDto,
    MissionWorkflowDto,
    MissionWorkflowService,
    Organisation,
    PersonsOrganisationDto,
    SortieDto,
    StepEntityRequiredDocumentation,
    WidgetMission
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { WorkflowDialoguesService } from 'libs/workflows/src/lib/workflow-dialogues.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { MissionFieldAppHandoffDialogue } from './field-app-handoff/mission-field-app-handoff.component';
import { FlightDocumentationEditDialogue } from './flight-documentation-edit/flight-documentation-edit-dialogue.component';
import { FlightDocumentationDialogue } from './flight-documentation/flight-documentation-dialogue.component';
import { FlightTakeoffLandingEditDialogue } from './flight-takeoff-landing/edit-takeoff-landing.component';
import { MissionApprovalWithAuthority } from './interfaces';
import { AcceptMissionApprovalDialogueComponent } from './mission-approval-v2/accept-approval.component';
import { MissionApprovalV2Dialogue } from './mission-approval-v2/mission-approval-v2-dialogue.component';
import { MissionCalendarDialogue } from './mission-calendar/mission-calendar.component';
import { MissionCompletionDialogue } from './mission-completion/mission-completion-dialogue.component';
import { MissionDetailsV2Dialogue } from './mission-details-v2/mission-details-v2-dialogue.component';
import { MissionEditV2Dialogue } from './mission-edit-v2/mission-edit-v2-dialogue.component';
import { MissionRecordEditDialogue } from './mission-record-edit/mission-record-edit.component';
import { MissionReportsDialogue } from './mission-reports/mission-reports-dialogue.component';

@Injectable()
export class MissionDialoguesService {
    constructor(
        private modalService: BsModalService,
        private workflowDialoguesService: WorkflowDialoguesService,
        private missionWorkflowService: MissionWorkflowService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    /**
     * Displays the mission details dialogue.
     *
     * @param missionSummary the mission summary object to display
     * @returns the promise for the dialogue
     */
    showMissionDetails(
        missionId: number,
        allowEditSubmit: boolean,
        currentOrganisation: PersonsOrganisationDto
    ) {
        const organisationId = currentOrganisation.id;
        return this.modalService.show(MissionDetailsV2Dialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                missionId,
                allowEditSubmit,
                organisationId
            }
        });
    }

    showMissionDetailsThroughState(missionId: number) {
        if (missionId) {
            this.router.navigate(['missions', missionId], {
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'preserve'
            });
        }
    }

    /**
     * Displays the mission details dialogue.
     *
     * @param missionSummary the mission summary object to display
     * @returns the promise for the dialogue
     */
    showMissionCompletion(
        mission: DisplayableMissionDto,
        organisation: Organisation
    ) {
        return this.modalService.show<MissionCompletionDialogue>(
            MissionCompletionDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    mission,
                    organisation: organisation as any
                }
            }
        );

        // if (mission.id) {
        //     this.$state.go('complete', {
        //         missionId: mission.id,
        //         organisationId: organisation.id
        //     });
        // }
    }

    /**
     * Displays the mission editor dialogue.
     *
     * Note, the current organisation must be the personnel that owns the mission.
     *
     * @param mission the mission object to display
     * @param organisation the organisation object for the current organisation
     * @returns the promise for the dialogue
     */
    showMissionEditor(
        mission: DisplayableMissionDto,
        organisation: Organisation,
        sourceMissionId: number = null
    ) {
        return this.modalService.show<MissionEditV2Dialogue>(
            MissionEditV2Dialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    mission,
                    organisation,
                    sourceMissionId
                }
            }
        );
    }

    showMissionEditorThroughState(
        mission: DisplayableMissionDto,
        organisation: Organisation,
        sourceMissionId: number = null
    ) {
        if (mission.id) {
            this.router.navigate(['edit', mission.id, organisation.id]);
        } else {
            this.router.navigate(['add', organisation.id]);
        }
    }

    /**
     * Displays the mission editor dialogue for historical missions.
     *
     * @param mission the mission object to display
     * @param organisation the organisation object for the current organisation
     */
    showMissionRecordDialogue(
        mission: DisplayableMissionDto,
        organisation: PersonsOrganisationDto
    ) {
        return this.modalService.show<MissionRecordEditDialogue>(
            MissionRecordEditDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    mission,
                    organisation
                }
            }
        );

        // if (mission.id) {
        //     this.$state.go('historical', {
        //         missionId: mission.id,
        //         organisationId: organisation.id
        //     });
        // }
    }

    showFieldAppHandoff(
        date: Date,
        organisationId: number,
        useSetupGuideInterface?: boolean
    ) {
        return this.modalService.show<MissionFieldAppHandoffDialogue>(
            MissionFieldAppHandoffDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-user-popup modal-centered',
                initialState: {
                    date: date as any,
                    organisationId,
                    useSetupGuideInterface
                }
            }
        );
    }

    showMissionApproval(
        mission: MissionSummaryDto,
        organisation: AdminOrganisationDto | PersonsOrganisationDto,
        approvalId: number,
        missionApproval?: MissionApprovalWithAuthority
    ) {
        return this.modalService.show<MissionApprovalV2Dialogue>(
            MissionApprovalV2Dialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    missionId: mission.id,
                    organisation,
                    approvalId,
                    missionApproval
                }
            }
        );
        // if (mission.id) {
        //     this.$state.go('approve', {
        //         missionId: mission.id,
        //         organisationId: organisation.id
        //     });
        // }
    }

    showAcceptMissionApproval(
        missionApproval: MissionApprovalWithAuthority,
        organisation: PersonsOrganisationDto
    ) {
        return this.modalService.show<AcceptMissionApprovalDialogueComponent>(
            AcceptMissionApprovalDialogueComponent,
            {
                ...MODAL_OPTIONS,
                class: 'modal-md',
                initialState: { missionApproval, organisation }
            }
        );
    }

    showMissionWorkflowDialogue(workflow: MissionWorkflowDto) {
        const steps = getMissionWorkflowSteps([
            'pre-submit',
            'pre-departure',
            'pre-mission',
            'pre-flight',
            'flying',
            'post-flight',
            'post-mission',
            'debriefing'
        ]);

        return this.workflowDialoguesService.showEditWorkflow(
            steps,
            this.missionWorkflowService,
            workflow,
            true,
            true
        );
    }

    showFlightDocumentationEditorDialogue(
        mission: MissionDetailsDto,
        flightGroup: FormControl<CompleteSortieCommand>,
        documentation: DisplayableDocumentation,
        step: string
    ) {
        return this.modalService.show<FlightDocumentationEditDialogue>(
            FlightDocumentationEditDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-md',
                initialState: { mission, flightGroup, documentation, step }
            }
        );
        // return wrapDialogue(this.modalService, FlightDocumentationEditDialogue, 'modal-md', { mission, flight, documentation, step })
    }

    showFlightDocumentationDialogue(
        mission: MissionDetailsDto,
        flight: SortieDto,
        documentation: StepEntityRequiredDocumentation,
        formResponses: FormResponseFormGroup
    ) {
        return this.modalService.show<FlightDocumentationDialogue>(
            FlightDocumentationDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-md',
                initialState: { mission, flight, documentation, formResponses }
            }
        );
    }

    showMissionReportsDialogue(
        organisationId: number,
        orOrganisationId: number,
        authorityIds: number[],
        canFilterByAuthority: boolean,
        missionAuthorityFilters: {
            name: string;
            children: AuthorityFilter[];
        }[],
        authorityFilter: AuthorityFilter
    ) {
        this.modalService.show(MissionReportsDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                organisationId,
                orOrganisationId,
                authorityIds,
                canFilterByAuthority,
                missionAuthorityFilters,
                authorityFilter
            }
        });
    }

    showMissionCalendarDialogue(missions$: Observable<WidgetMission[]>) {
        return this.modalService.show<MissionCalendarDialogue>(
            MissionCalendarDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: { missions$ }
            }
        );
    }

    showFlightTakeoffLandingEditDialogue(
        missionLocation: LocationDetailsDto,
        organisationId: number,
        flight: SortieDto,
        takeoffPoint: number[],
        landingPoint: number[],
        flightLogIds?: number[]
    ) {
        return this.modalService.show<FlightTakeoffLandingEditDialogue>(
            FlightTakeoffLandingEditDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    missionLocation,
                    organisationId,
                    flight,
                    takeoffPoint,
                    landingPoint,
                    flightLogIds
                }
            }
        );
    }
}
