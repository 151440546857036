import { NgModule } from '@angular/core';
import { SubscriptionLicences } from './subscription-licences.component';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { FormatLicenceLimitsPipe } from './formatLicenceLimits.pipe';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { AddressTaxDialogue } from './address-tax-setup/address-tax-dialogue.component';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        NgSelectModule,
        ToastrModule,
        SharedUiModule,
        FormlyModule
    ],
    declarations: [
        SubscriptionLicences,
        FormatLicenceLimitsPipe,
        AddressTaxDialogue
    ],
    exports: [SubscriptionLicences, FormatLicenceLimitsPipe, AddressTaxDialogue]
})
export class SubscriptionsModule {}
