import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import Ajv from 'ajv';
import { Observable, of } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    first,
    startWith,
    switchMap
} from 'rxjs/operators';

const ajv = new Ajv();

const htmlRegex = /<\/?[a-z][\s\S]*>/i;

/**
 * A validator that will return a validation error if any text in the form control's value seems to contain HTML when checked against a REGEX.
 * @returns a notHtml error if the text seems to contain html code, or null if not.
 */
export function htmlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (htmlRegex.test(control.value)) {
            return { notHtml: 'Input cannot contain HTML code' };
        } else {
            return null;
        }
    };
}

/**
 * A Fornmly-compatible validator that will return a validation error if any text in the form control's value seems to contain HTML when checked against a REGEX.
 * @returns a notHtml error if the text seems to contain html code, or null if not.
 */
export function formlyHtmlValidator(
    control: AbstractControl
): ValidationErrors {
    if (htmlRegex.test(control.value)) {
        return { notHtml: true };
    } else {
        return null;
    }
}

/**
 * A form control validator for JSON. Generally to be used to validate JSON entered as free text. Can optionally validate the JSON against a provided schema and return relevant errors
 * @param jsonSchema an optional schema defining the expected shape for the JSON that the parsed JSON will be validated against
 * @returns Validation errors using keys that the validation service can also check against.
 */
export function jsonValidator(jsonSchema?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            const controlValue = JSON.parse(control.value);
            if (controlValue != null && jsonSchema != null) {
                const valid = ajv.validate(jsonSchema, controlValue);
                if (valid) {
                    return null;
                } else {
                    return { jsonSchema: 'JSON does not match expected shape' };
                }
            }
            return null;
        } catch {
            return { json: 'provided JSON is not valid' };
        }
    };
}

// FIXME: this currently won't work. It only validates correctly after the field's value has been changed
/**
 * An async form control validator for JSON. Generally to be used to validate JSON entered as free text. Can optionally validate the JSON against a provided schema and return relevant errors
 * @param jsonSchema an optional schema defining the expected shape for the JSON that the parsed JSON will be validated against
 * @returns An observable with Validation errors using keys that the validation service can also check against.
 */
export function asyncJsonValidator(jsonSchema?: any): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
        control.valueChanges.pipe(
            startWith(control.value), // Needed to set correct validation when the form values are loaded in
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(value => {
                try {
                    const controlValue = JSON.parse(value);
                    if (controlValue != null && jsonSchema != null) {
                        const valid = ajv.validate(jsonSchema, controlValue);
                        if (valid) {
                            return of(null);
                        } else {
                            return of({
                                jsonSchema: 'JSON does not match expected shape'
                            });
                        }
                    }
                    return of(null);
                } catch {
                    return of({ json: 'provided JSON is not valid' });
                }
            }),
            first() // important to make observable finite
        );
}

/**
 * A validator that checks if an array is empty.
 * @returns a notEmptyArray error if the array is empty, or null if not.
 */
export function notEmptyArrayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (
            control.value &&
            Array.isArray(control.value) &&
            control.value.length === 0
        ) {
            return { notEmptyArray: 'Array cannot be empty' };
        }
        return null;
    };
}
