import { Injectable, OnDestroy } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    ReplaySubject,
    Subject,
    tap,
    throwError
} from 'rxjs';
import {
    AttachmentHandler,
    ChangeDetails,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import {
    CreateTaskCommand,
    GeneralTask,
    GeneralTasksService,
    TaskComment,
    TaskPriority,
    TaskStatus,
    UpdateTaskCommand
} from 'libs/flyfreely/src/lib/services/generalTasks.service';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import Type = ChangeDetails.Type;

export const formatGeneralTasksPriority = {
    [TaskPriority.LOW]: 'Low',
    [TaskPriority.MEDIUM]: 'Medium',
    [TaskPriority.HIGH]: 'High'
};
export const formatGeneralTasksStatuses = {
    [TaskStatus.IN_PROGRESS]: 'In Progress',
    [TaskStatus.COMPLETED]: 'Completed',
    [TaskStatus.CANCELLED]: 'Cancelled',
    [TaskStatus.TODO]: 'Todo'
};

@Injectable({
    providedIn: 'root'
})
export class GeneralTasksDataService implements OnDestroy {
    currentOrganisation: PersonsOrganisationDto;

    private generalTasksSource = new ReplaySubject<GeneralTask[]>(1);
    private workingSource = new BehaviorSubject<boolean>(false);
    private totalItemsSource = new ReplaySubject<number>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private changeSource = new Subject<ChangeDetails<GeneralTask>>();
    private commentChangeSource = new Subject<ChangeDetails<TaskComment>>();

    private searchCriteriaSubject = new ReplaySubject<{
        page: number;
        limit: number;
        organisationId: number;
    }>(1);

    searchCriteria$ = this.searchCriteriaSubject.asObservable();

    change$ = this.changeSource.asObservable();
    working$ = this.workingSource.asObservable();
    commentChange$ = this.commentChangeSource.asObservable();
    generalTasks$ = this.generalTasksSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private workspaceStateService: WorkspaceStateService,
        private generalTasksService: GeneralTasksService,
        private logging: FlyFreelyLoggingService,
        private http: HttpClient
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));

        combineLatest([
            this.workspaceStateService.currentOrganisation$.pipe(
                filter(
                    organisation => organisation.type === 'organisation_loaded'
                )
            ),
            this.searchCriteriaSubject
        ]).subscribe(([organisation, criteria]) => {
            this.currentOrganisation = (
                organisation as CurrentOrganisation
            ).organisation;
            if (this.currentOrganisation != null) {
                this.updateTaskTable(criteria);
            } else {
                this.generalTasksSource.next([]);
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workingSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.generalTasksSource.complete();
        this.searchCriteriaSubject.complete();
    }

    findTaskById(taskId: number) {
        return this.generalTasksService.findTaskById(taskId).pipe(
            catchError((error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while findTaskById: ${error.message}`
                );
                return throwError(() => error.message ?? 'Entry is invalid');
            })
        );
    }

    findTasks(page: number, limit: number, organisationId: number) {
        this.searchCriteriaSubject.next({
            page,
            limit,
            organisationId
        });
    }

    private updateTaskTable({
        organisationId,
        page,
        limit
    }: {
        organisationId: number;
        page: number;
        limit: number;
    }) {
        const doneWorking = this.workTracker.createTracker();
        this.generalTasksService
            .findTasks(organisationId, {
                page: page,
                pageSize: limit
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: tasks => {
                    this.totalItemsSource.next(tasks.count);
                    this.currentPageSource.next(page);
                    this.generalTasksSource.next(tasks.results);
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                    this.generalTasksSource.next([]);
                    this.totalItemsSource.next(0);
                    this.currentPageSource.next(0);
                    doneWorking();
                }
            });
    }

    newTask(organisationId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.generalTasksService.newTask(organisationId).pipe(
            tap({
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    createTask(createTaskPara: CreateTaskCommand) {
        const doneWorking = this.workTracker.createTracker();
        return this.generalTasksService.createTask(createTaskPara).pipe(
            tap({
                next: result => {
                    this.changeSource.next({
                        type: ChangeDetails.Type.CREATE,
                        changedId: result.id,
                        result
                    });
                },
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    updateTask(updateTaskPara: UpdateTaskCommand) {
        const doneWorking = this.workTracker.createTracker();
        return this.generalTasksService.updateTask(updateTaskPara).pipe(
            tap({
                next: result => {
                    this.changeSource.next({
                        type: ChangeDetails.Type.UPDATE,
                        changedId: result.id,
                        result
                    });
                },
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    deleteTask(taskId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.generalTasksService.deleteTask(taskId).pipe(
            tap({
                next: result => {
                    this.changeSource.next({
                        type: ChangeDetails.Type.DELETE,
                        changedId: taskId
                    });
                },
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    attachmentHandler(taskId: number, organisationId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/tasks/${taskId}/attachments`,
            false,
            organisationId
        );
    }

    createComment(taskId: number, content: Object) {
        const doneWorking = this.workTracker.createTracker();
        return this.generalTasksService.createTaskComment(taskId, content).pipe(
            tap({
                next: result => {
                    this.commentChangeSource.next({
                        type: Type.CREATE,
                        changedId: result.id
                    });
                },
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    updateComment(taskId: number, commentId: number, content: Object) {
        const doneWorking = this.workTracker.createTracker();
        return this.generalTasksService
            .updateTaskComment(taskId, commentId, content)
            .pipe(
                tap({
                    next: result => {
                        this.commentChangeSource.next({
                            type: Type.UPDATE,
                            changedId: result.id
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }

    deleteComment(taskId: number, commentId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.generalTasksService
            .deleteTaskComment(taskId, commentId)
            .pipe(
                tap({
                    next: result => {
                        this.commentChangeSource.next({
                            type: Type.DELETE,
                            changedId: taskId
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }

    findComments(taskId: number) {
        return this.generalTasksService.findTaskComments(taskId);
    }
}
