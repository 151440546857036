import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentAttachmentVersionDto } from '@flyfreely-portal-ui/flyfreely';
import { FormatDatePipe } from '@flyfreely-portal-ui/ui';
import {
    AirworthinessNoticeApplicability,
    AirworthinessNoticeType,
    LinkDto
} from 'libs/flyfreely/src/lib/model/api/airworthnessNoticeDto';

@Component({
    selector: 'app-notice-card',
    templateUrl: './notice-card.component.html',
    styleUrls: ['./notice-card.component.scss'],
    animations: [
        trigger('expandCollapse', [
            state(
                'expanded',
                style({
                    height: '*',
                    opacity: 1,
                    display: 'block'
                })
            ),
            state(
                'collapsed',
                style({
                    height: '0px',
                    opacity: 0,
                    display: 'none'
                })
            ),
            transition('expanded <=> collapsed', [animate('300ms ease-in-out')])
        ])
    ]
})
export class NoticeCardComponent implements OnInit {
    formattedDateCreated?: string;
    downloadUrlFn = (attachment: CurrentAttachmentVersionDto) => {
        return `webapi/airworthinessNoticeAttachments/${this.id}/${attachment.id}?managingOrganisationId=${this.organisationId}&attachmentVersionId=${attachment.attachmentVersionId}`;
    };

    @Input() name: string;
    @Input() id: number;
    @Input() organisationId: number;
    @Input() description?: string;
    @Input() issuedBy?: string;
    @Input() dueBy: string;
    @Input() type: AirworthinessNoticeType;
    @Input() applicability: AirworthinessNoticeApplicability;
    @Input() dateCreated?: string;
    @Input() completionTimeframe?: string;
    @Input() isExpanded: boolean = false;
    @Input() selected: boolean = false;
    @Output() selectionChange: EventEmitter<number> =
        new EventEmitter<number>();
    @Output() noticeSelected = new EventEmitter<number>();
    @Output() toggleExpand: EventEmitter<void> = new EventEmitter<void>();
    @Output() editNotice = new EventEmitter<number>();
    @Input() attachmentList: CurrentAttachmentVersionDto[] = [];
    @Input() linkList: LinkDto[] = [];

    constructor(
        private formatDatePipe: FormatDatePipe // Declare formatDatePipe as private
    ) {}

    ngOnInit(): void {
        if (this.dateCreated) {
            this.formattedDateCreated = this.formatDatePipe.transform(
                this.dateCreated
            );
        }
    }

    toggleExpandCollapse() {
        this.toggleExpand.emit();
    }

    noticeSelect() {
        this.noticeSelected.emit(this.id);
        this.selectionChange.emit(this.id);
    }

    onEditClick(event: Event) {
        event.stopPropagation();
        this.editNotice.emit(this.id);
    }
}
