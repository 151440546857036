<div class="modal-header">
    <h3 class="modal-title">Update Licence Count</h3>
    <button type="button" (click)="hide()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" style="align-items: center">
    <plan-selection
        *ngIf="subscription != null"
        [selected]="true"
        [plan]="subscription.details.subscriptionPlan"
        [product]="subscription.details.subscriptionProduct"
        [initialLicenceCount]="subscription.details.licenceCount"
        [currentLicenceCount]="subscription.details.licenceCount"
    ></plan-selection>
    <p class="help-block">
        Changes will take effect immediately and be pro-rated on your next bill.
    </p>
</div>

<div class="modal-footer">
    <button class="btn btn-link" type="button" (click)="cancelUpdate()">
        Cancel
    </button>
</div>
