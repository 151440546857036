import { FlightLogCollectionSourceDto } from '@flyfreely-portal-ui/flyfreely';

export const FlightLogCollectionStatusLookup = {
    [FlightLogCollectionSourceDto.LastCompletionStatus.FAILURE]: 'Failure',
    [FlightLogCollectionSourceDto.LastCompletionStatus.INVALID_PASSWORD]:
        'Invalid Password',
    [FlightLogCollectionSourceDto.LastCompletionStatus.NOT_YET_RUN]:
        'Not Yet Run',
    [FlightLogCollectionSourceDto.LastCompletionStatus.SUCCESS]: 'Success'
};

export const FlightLogCollectionTypeLookup = {
    [FlightLogCollectionSourceDto.Type.DJI_CLOUD]: 'DJI',
    [FlightLogCollectionSourceDto.Type.DRONE_DEPLOY]: 'Drone Deploy',
    [FlightLogCollectionSourceDto.Type.DRONE_HARMONY]: 'Drone Harmony',
    [FlightLogCollectionSourceDto.Type.SKYDIO]: 'Skydio'
};
