import { Injectable } from '@angular/core';
import {
    ConcreteMappedEntityConcreteFormDto,
    defaultBuildFormAttachmentUrl,
    FormControlDto,
    FormDto,
    FormResponseDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControlEditDialogue } from './form-control-edit/form-control-edit-dialogue.component';
import { FormEdit } from './form-edit/form-edit.component';
import { FormInputDialogue } from './form-input/form-input-dialogue.component';
import { FormResponseDialogue } from './form-response-dialogue/form-response-dialogue.component';
import { ControlDescription, StoreFormResponse } from './interfaces';
import { CurrentAttachmentVersionDtoWithDownloadUrl } from './internal-interfaces';

@Injectable()
export class DocumentationDialoguesService {
    constructor(private modalService: BsModalService) {}

    showFormEdit(form: FormDto, managingOrganisationId: number) {
        const modal = this.modalService.show(FormEdit, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { form, managingOrganisationId }
        });
        return <BsModalRef<FormEdit>>modal;
    }

    showFormControlEdit(
        control: FormControlDto,
        attachments: CurrentAttachmentVersionDtoWithDownloadUrl[],
        previousControls: ControlDescription[]
    ) {
        const modal = this.modalService.show(FormControlEditDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { control, attachments, previousControls }
        });
        return <BsModalRef<FormControlEditDialogue>>modal;
    }

    showFormResponseDialogue(
        responseList: FormResponseDto[],
        title: string,
        managingOrganisationId: number,
        buildFormAttachmentUrl = defaultBuildFormAttachmentUrl
    ) {
        const modal = this.modalService.show(FormResponseDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                title,
                responseList,
                organisationId: managingOrganisationId,
                buildFormAttachmentUrl
            },
        });
        return <BsModalRef<FormResponseDialogue>>modal;
    }

    /**
     * Because the responses can be from another version of the form, the responses
     * are provided in the DTO form that embed the relevant sections.
     * @param form 
     * @param title 
     * @param responses
     * @param managingOrganisationId 
     * @param storeFormResponse 
     */
    showFormInputDialogue(
        form: ConcreteMappedEntityConcreteFormDto,
        title: string,
        responses: FormResponseDto[],
        managingOrganisationId: number,
        storeFormResponse: StoreFormResponse,
        buildFormAttachmentUrl = defaultBuildFormAttachmentUrl

    ) {
        const modal = this.modalService.show(FormInputDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                title,
                form,
                responses,
                storeFormResponse,
                organisationId: managingOrganisationId,
                buildFormAttachmentUrl
            }
        });
        return <BsModalRef<FormInputDialogue>>modal;
    }

    showCreateNewForm(organisationId: number) {
        const form: FormDto = {
            organisationId,
            availableActions: { canEdit: true, canDelete: true },
            versions: [],
            name: null,
            id: null,
            archived: false
        };
        return this.showFormEdit(form, organisationId);
    }
}
