import { Component, Input, OnInit } from '@angular/core';
import {
    CurrentAttachmentVersionDto,
    DownloadService
} from '@flyfreely-portal-ui/flyfreely';
import { DocumentPreviewDialogueComponent } from 'libs/attachments/src/lib/document-preview/document-preview-dialogue.component';
import {
    AttachmentViewService,
    DocumentPreviewPayload
} from 'libs/attachments/src/lib/documentPreview.service';
import { LinkDto } from 'libs/flyfreely/src/lib/model/api/airworthnessNoticeDto';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'links-and-attachments-list',
    templateUrl: './links-and-attachments-list.component.html',
    styleUrls: ['./links-and-attachments-list.component.scss']
})
export class LinksAndAttachmentsListComponent implements OnInit {
    @Input() attachmentList: CurrentAttachmentVersionDto[];
    @Input() linkList: LinkDto[];
    @Input() downloadUrlFn: (attachment: CurrentAttachmentVersionDto) => string;
    constructor(
        private attachmentViewService: AttachmentViewService,
        private downloadService: DownloadService,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {}

    getFileIcon(contentType: string): string {
        switch (contentType) {
            case 'application/pdf':
                return 'fas fa-file-pdf icon-red';
            case 'text/plain':
                return 'fas fa-file-alt icon-red';
            case 'image/jpeg':
            case 'image/png':
                return 'fas fa-file-image icon-red';
            case 'video/mp4':
            case 'video/mpeg':
                return 'fas fa-file-video icon-red';
            default:
                return 'fas fa-file icon-red';
        }
    }

    showContent(payload: DocumentPreviewPayload) {
        const downloadUrl = this.downloadUrlFn(payload);
        payload = {
            ...payload,
            downloadUrl: downloadUrl
        };
        if (
            payload.contentType.startsWith('video') ||
            payload.contentType.startsWith('image') ||
            payload.contentType.startsWith('text') ||
            payload.contentType.startsWith('application/pdf')
        ) {
            this.attachmentViewService.dispatchDocumentPreviewMessage(payload);
            this.modalService.show(DocumentPreviewDialogueComponent, {
                class: 'modal-full'
            });
        } else {
            this.downloadService.downloadFromUrl(payload.downloadUrl);
            this.attachmentViewService.dispatchDocumentPreviewMessage(payload);
        }
    }

    onLinkClick(link: LinkDto): void {
        window.open(link.url, '_blank'); // Open link in a new tab if not in edit mode
    }
}
