import {
    HttpBackend,
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import {
    AirspaceAuthorisationService,
    AirspaceCheckCommand,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    CraftDto,
    DisplayableMissionDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    GeospatialService,
    InUseMissionWorkflowVersionDto,
    JurisdictionService,
    LocationDetailsDto,
    LocationFeatureDto,
    LookupObject,
    OperationFailed,
    parseErrorBody,
    RpaTypeDto,
    RpaTypesService,
    SessionExpired,
    toLookup,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon';
import { center } from '@turf/center';
import { getCoords } from '@turf/invariant';
import { getOrElse, map as optionMap } from 'fp-ts/es6/Option';
import { Geometry, GeometryCollection, MultiPolygon, Polygon } from 'geojson';
import { flightAreaOf } from 'libs/locations/src/lib/helpers';
import { MissionFormValue } from 'libs/missions/src/lib/interfaces';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
    asyncScheduler,
    BehaviorSubject,
    combineLatest,
    Observable,
    of,
    ReplaySubject,
    scheduled,
    Subject,
    throwError,
    timer
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { AuthorisationError } from '../authorisation/interfaces';
import { AirspaceMissionEditService } from '../interfaces';
import { JurisdictionLibraryDialogue } from './jurisdiction-library/jurisdiction-library.component';

/**
 * Finds any flight area polygons from either the first flight area or offset flight area for a given array of location features. Returns null if there are no valid flight areas or offset flight areas in the provided features.
 *
 * This function will only return the first polygon found and will prioritise flight areas over offset flight areas
 * @param locationFeatures an array of location features (LocationFeatureDto[])
 * @returns a "GeoJson.Polygon" of the flight area geometry.
 */
export function findLocationFlightAreaPolygon(
    locationFeatures: LocationFeatureDto[]
) {
    const flightArea = locationFeatures.find(
        f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
    );
    const offsetFlightArea = locationFeatures.find(
        f => f.type === LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
    );
    if (flightArea != null) {
        return <GeoJSON.Polygon>flightArea.geometry;
    } else if (offsetFlightArea != null) {
        return <GeoJSON.Polygon>(
            (<GeometryCollection>offsetFlightArea.geometry).geometries.find(
                g => g.type === 'Polygon'
            )
        );
    } else {
        return null;
    }
}

export const ruleOutcomeReasonTypeValue = {
    ADVISORY: 'ADV',
    AERODROME: 'AD',
    ALTITUDE: 'ALT',
    DAYTIME: 'DT',
    ELECTRICITY_LINE: 'EL',
    INCIDENT: 'IN',
    INSTRUMENT_APPROACH: 'IA',
    MARINE_PARK: 'MP',
    PRD: 'PRD',
    UNIDENTIFIED: 'UID'
};

export type AirspaceCheckStatus =
    | 'CHECKING'
    | 'INCOMPLETE'
    | 'NO_LOCATION'
    | 'NO_FLIGHT_AREA'
    | 'NO_JURISDICTION'
    | 'NO_CHECK'
    | 'NO_DATE'
    | 'NO_WORKFLOW'
    | 'NO_DELEGATED_AUTHORITY'
    | 'NO_RULESET'
    | 'CANNOT_FLY'
    | 'CAN_FLY_CONDITIONS'
    | 'CAN_FLY';

export interface LocationSunriseSunset {
    civilTwilightBegin: string;
    civilTwilightEnd: string;
    civilTwilightStart: string;
    date: string;
    sunrise: string;
    sunset: string;
}

export const airspaceAuthorisationProviderLookup = {
    [AirspaceCheckDto.AvailableAutomatedAuthorisationList
        .AUS_CASA_AUTHORISATION]: 'CASA'
};

/**
 * A scoped service to provide airspace checking.
 *
 * It can either be provided with the check parameters, or be hooked into a MissionEditService
 * to automatically get updated parameters.
 *
 * `ready$` emits when all requisite data has been loaded.
 *
 * During the normal lifecycle, the following observables are used:
 *
 * `resultStatus$` provides status updates as the checker runs. It will always emit first.
 * `result$` provides an airspace check result when it is ready.
 * `operationFailed$` provides an indication of whether the airspace check has failed. It will revert to `false` when the check first starts.
 */
@Injectable()
export class AirspaceCheckService {
    private rpaTypes: { [rpaTypeId: number]: RpaTypeDto };

    private organisationId: number;
    private currentJurisdictionId: number;

    disabled: boolean;

    private readySubject = new ReplaySubject<void>();
    ready$ = this.readySubject.asObservable();

    private resultStatusSubject = new BehaviorSubject<AirspaceCheckStatus>(
        'INCOMPLETE'
    );
    resultStatus$ = this.resultStatusSubject.asObservable();

    private resultSubject = new ReplaySubject<AirspaceCheckDto | null>(1);
    result$ = this.resultSubject.asObservable();

    private completeCheckSubject = new ReplaySubject<{
        check: AirspaceCheckCommand;
        result: AirspaceCheckDto;
        missionId?: number;
        organisationId?: number;
    }>(1);
    /**
     * Another representation of the entire check with all the details required to chain on to further operations.
     */
    completeCheck$ = this.completeCheckSubject.asObservable();

    savedMissionSubject = new BehaviorSubject<DisplayableMissionDto>(null);
    savedMission$ = this.savedMissionSubject.asObservable();

    private airspaceJurisdictionSubject =
        new BehaviorSubject<AirspaceJurisdictionDto | null>(null);
    airspaceJurisdiction$ = this.airspaceJurisdictionSubject.asObservable();
    jurisdiction$: Observable<string | null> = this.airspaceJurisdiction$.pipe(
        map(j => j?.identifier ?? null)
    );

    set airspaceJurisdiction(value: AirspaceJurisdictionDto | null) {
        if (
            value == null ||
            this.airspaceJurisdictionSubject.getValue() == null ||
            value.id !== this.airspaceJurisdictionSubject.getValue().id
        ) {
            this.airspaceJurisdictionSubject.next(value);
        }
    }

    private alternativeCheckerUrlSource = new BehaviorSubject<string>(null);
    alternativeCheckerUrl$ = this.alternativeCheckerUrlSource.asObservable();

    private externalAuthorisationBearerTokenSource = new BehaviorSubject<{
        accessToken: string;
    }>(null);
    externalAuthorisationBearerToken$ =
        this.externalAuthorisationBearerTokenSource.asObservable();

    private airspaceCheckErrorSubject = new ReplaySubject<AuthorisationError>(
        1
    );
    airspaceCheckError$ = this.airspaceCheckErrorSubject.asObservable();

    private rulesetSubject = new BehaviorSubject<string>(null);
    ruleset$ = this.rulesetSubject.asObservable();

    private missionDateSubject = new ReplaySubject<string>(1);
    missionDate$ = this.missionDateSubject.asObservable();

    private missionLocationSubject = new ReplaySubject<LocationDetailsDto>(1);
    missionLocation$ = this.missionLocationSubject.asObservable();

    private workflowVersionIdSubject = new ReplaySubject<number>(1);
    workflowVersionId$ = this.workflowVersionIdSubject.asObservable();

    private sunriseSunsetSubject = new ReplaySubject<LocationSunriseSunset>(1);
    sunriseSunset$ = this.sunriseSunsetSubject.asObservable();

    private operationFailedSubject = new BehaviorSubject<boolean>(false);
    operationFailed$ = this.operationFailedSubject.asObservable();

    private doAlternativeAirspaceCheck$ = new Subject<AirspaceCheckCommand>();

    private rpaTypesSubject = new Subject<{
        [rpaTypeId: number]: RpaTypeDto;
    }>();
    rpaTypes$ = this.rpaTypesSubject.asObservable();

    currentRuleset: string;
    workflowLoading = false;
    currentWorkflowId: number;
    checkStartTime: string;

    private airspaceJurisdictions: AirspaceJurisdictionDto[];

    private http: HttpClient;
    private stopPollingAuthorisation$ = new Subject<void>();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        @Optional() private missionEdit: AirspaceMissionEditService,
        private logging: FlyFreelyLoggingService,
        private rpaTypeService: RpaTypesService,
        private modalService: BsModalService,
        private geospatialService: GeospatialService,
        private jurisdictionsService: JurisdictionService,
        private airspaceAuthorisationService: AirspaceAuthorisationService,
        private httpHandler: HttpBackend
    ) {
        this.http = new HttpClient(httpHandler);
        // missionEdit will only be available in the mission editor, otherwise don't run this part since other screens use the single check
        if (this.missionEdit) {
            // The mission location is used throughout the checker and needs to be handled separately
            this.missionEdit.savedMission$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(savedMission =>
                    this.savedMissionSubject.next(savedMission)
                );

            this.missionEdit.missionLocation$
                .pipe(
                    filter(l => l != null),
                    tap(location => this.missionLocationSubject.next(location)),

                    takeUntil(this.ngUnsubscribe$)
                )
                .subscribe(location => {
                    if (
                        location == null ||
                        this.airspaceJurisdictionSubject.getValue() == null ||
                        location.airspaceJurisdiction.id !==
                            this.airspaceJurisdictionSubject.getValue().id
                    ) {
                        this.airspaceJurisdictionSubject.next(
                            location.airspaceJurisdiction
                        );
                    }
                });
            /**
             * This checks for sunrise sunset times and civil twilight
             * It runs here instead of in the main checker to allow it to run for all organisations
             * It does not get restricted by any feature flags
             */
            combineLatest([
                this.missionEdit.missionDate$,
                this.missionEdit.missionLocation$
            ])
                .pipe(
                    filter(
                        ([date, location]) =>
                            // Don't run this call if values are null or unchanged
                            date != null && location != null
                    ),
                    distinctUntilChanged(),
                    takeUntil(this.ngUnsubscribe$)
                )
                .subscribe(([date, location]) => {
                    const maybeFlightArea = flightAreaOf(location);
                    const maybeCentre = optionMap((l: LocationFeatureDto) =>
                        center(<GeoJSON.Polygon>l.geometry)
                    )(maybeFlightArea);
                    const centre = getOrElse(() => null)(maybeCentre);
                    if (centre == null) {
                        return;
                    }

                    this.findSunriseSunset(centre, date.toISOString());
                });
        }

        combineLatest([
            this.savedMissionSubject.pipe(
                map(mission => mission?.id),
                startWith(null)
            ),
            this.alternativeCheckerUrl$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([id, url]) => {
                this.stopPollingAuthorisation$.next();
                if (url != null) {
                    this.startPollingAuthenticationBearerToken();
                }
            });

        this.airspaceJurisdiction$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(jurisdiction => {
                if (
                    jurisdiction?.airspaceCheckerUrl !=
                    this.alternativeCheckerUrlSource.getValue()
                ) {
                    this.alternativeCheckerUrlSource.next(
                        jurisdiction?.airspaceCheckerUrl
                    );
                }
            });

        combineLatest([
            this.alternativeCheckerUrl$.pipe(filter(url => url != null)),
            this.externalAuthorisationBearerToken$.pipe(
                filter(token => token != null)
            ),
            this.doAlternativeAirspaceCheck$.pipe(
                filter(command => command != null)
            )
        ])
            .pipe(
                switchMap(([url, token, command]) =>
                    this.checkAirspaceWithAlternativeUrl(command).pipe(
                        map(result => ({ result, command }))
                    )
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: response => {
                    this.handleCheckResponse(response.result);
                    this.completeCheckSubject.next({
                        check: response.command,
                        result: response.result,
                        missionId: this.savedMissionSubject.getValue()?.id,
                        organisationId: this.organisationId
                    });
                },
                error: (error: FlyFreelyError) => {
                    if (error instanceof OperationFailed) {
                        this.operationFailedSubject.next(true);
                        this.resultStatusSubject.next('INCOMPLETE');
                        return;
                    }
                    this.logging.error(
                        error,
                        `Error while performing airspace check: ${error.message}`
                    );
                    this.resultStatusSubject.next('INCOMPLETE');
                }
            });

        // Not sure if we need more things done to "ready" so its in the for we can easily expand.
        combineLatest([this.refreshJurisdictions()])
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.readySubject.next());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.rulesetSubject.complete();
        this.resultStatusSubject.complete();
        this.resultSubject.complete();
        this.workflowVersionIdSubject.complete();
        this.missionDateSubject.complete();
        this.sunriseSunsetSubject.complete();
        this.operationFailedSubject.complete();
        this.rpaTypesSubject.complete();
        this.readySubject.complete();
        this.airspaceJurisdictionSubject.complete();
        this.missionLocationSubject.complete();
        this.completeCheckSubject.complete();
        this.savedMissionSubject.complete();
        this.stopPollingAuthorisation$.next();
        this.stopPollingAuthorisation$.complete();
        this.alternativeCheckerUrlSource.complete();
        this.externalAuthorisationBearerTokenSource.complete();
    }

    updateSavedMission(mission: DisplayableMissionDto) {
        this.savedMissionSubject.next(mission);
    }

    startMissionCheck() {
        this.organisationId = this.missionEdit.organisationId;
        this.rpaTypeService
            .findRpaTypes(this.missionEdit.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpaTypes => {
                this.rpaTypes = rpaTypes.reduce(toLookup, {});
                this.rpaTypesSubject.next(this.rpaTypes);
            });

        const startValue = {
            craftIds: this.missionEdit.missionForm.controls.craftIds.value,
            missionDate:
                this.missionEdit.missionForm.controls.missionDate.value,
            missionEstimatedTime:
                this.missionEdit.missionForm.controls.missionEstimatedTime
                    .value,
            timeZone: this.missionEdit.missionForm.controls.timeZone.value,
            missionWorkflowVersionId:
                this.missionEdit.missionForm.controls.missionWorkflowVersionId
                    .value,
            maximumHeight:
                this.missionEdit.missionForm.controls.maximumHeight.value
        };

        /**
         * This ensures the airspace checker only runs as required during mission form updates
         */
        const missionValues = combineLatest([
            this.missionEdit.missionForm.controls.craftIds.valueChanges,
            this.missionEdit.missionDate$,
            this.missionEdit.missionForm.controls.missionEstimatedTime
                .valueChanges,
            this.missionEdit.missionForm.controls.timeZone.valueChanges,
            this.missionEdit.missionForm.controls.missionWorkflowVersionId
                .valueChanges,
            this.missionEdit.missionForm.controls.maximumHeight.valueChanges
        ]).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(
                ([
                    craftIds,
                    missionDate,
                    missionEstimatedTime,
                    timeZone,
                    missionWorkflowVersionId,
                    maximumHeight
                ]) => {
                    return {
                        craftIds,
                        missionDate,
                        missionEstimatedTime,
                        timeZone,
                        missionWorkflowVersionId,
                        maximumHeight
                    };
                }
            ),
            startWith(startValue)
        );

        combineLatest([
            this.missionEdit.rpas$,
            this.missionEdit.missionWorkflows$,
            this.missionEdit.missionLocation$,
            this.missionEdit.loadingWorkflow$,
            this.rpaTypes$,
            missionValues
        ])
            .pipe(
                filter(
                    ([
                        rpas,
                        workflows,
                        location,
                        loadingWorkflow,
                        rpaTypes,
                        values
                    ]) =>
                        // This prevents the checker from constantly firing during the workflow being changed on the mission.
                        // Specifically for workflow changes, the checker will only run:
                        // 1. when a new workflow is selected from the dropdown,
                        // 2. again when "Change Workflow" has completed
                        !loadingWorkflow
                ),
                switchMap(
                    ([
                        rpas,
                        workflows,
                        location,
                        loadingWorkflow,
                        rpaTypes,
                        values
                    ]) => {
                        this.workflowLoading = loadingWorkflow;
                        this.currentWorkflowId =
                            values.missionWorkflowVersionId;
                        this.workflowVersionIdSubject.next(
                            values.missionWorkflowVersionId
                        );
                        const selectedRpas = rpas.filter(rpa =>
                            values.craftIds.includes(rpa.id)
                        );
                        if (location != null) {
                            this.currentJurisdictionId =
                                location.airspaceJurisdiction?.id;
                        }
                        this.resultStatusSubject.next('INCOMPLETE');
                        this.resultSubject.next(null);
                        // Mapping to unknown first to get past the embedded typed form group. The final types should line up
                        const formValues: MissionFormValue =
                            values as unknown as MissionFormValue;
                        const command = this.setupCheckCommand(
                            selectedRpas,
                            workflows,
                            formValues,
                            location,
                            rpaTypes
                        );
                        if (command == null) {
                            return scheduled(
                                [
                                    {
                                        check: command,
                                        result: null as AirspaceCheckDto
                                    }
                                ],
                                asyncScheduler
                            );
                        } else {
                            this.currentRuleset = command?.ruleset;
                            this.checkStartTime = command.startTime;
                            return this.doCheckAirspace(command).pipe(
                                map(result => ({ check: command, result }))
                            );
                        }
                    }
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: ({ result, check }) => {
                    this.handleCheckResponse(result);
                    this.completeCheckSubject.next({
                        check,
                        result,
                        missionId: this.missionEdit?.missionId,
                        organisationId: this.organisationId
                    });
                },
                error: (error: FlyFreelyError) => {
                    if (error instanceof OperationFailed) {
                        this.operationFailedSubject.next(true);
                        this.resultStatusSubject.next('INCOMPLETE');
                        return;
                    }
                    this.logging.error(
                        error,
                        `Error while performing airspace check: ${error.message}`
                    );
                    this.resultStatusSubject.next('INCOMPLETE');
                }
            });
    }

    startSingleCheck(
        command: AirspaceCheckCommand,
        workflowVersionId: number,
        airspaceJurisdiction?: AirspaceJurisdictionDto,
        missionLocation?: LocationDetailsDto
    ) {
        this.airspaceJurisdictionSubject.next(airspaceJurisdiction);
        this.missionLocationSubject.next(missionLocation);
        this.rulesetSubject.next(command.ruleset);
        this.workflowVersionIdSubject.next(workflowVersionId);
        this.resultStatusSubject.next('CHECKING');
        this.operationFailedSubject.next(false);
        this.checkStartTime = command.startTime;
        if (this.alternativeCheckerUrlSource.getValue() != null) {
            this.doAlternativeAirspaceCheck$.next(command);
            return;
        }
        this.geospatialService
            .checkAirspace(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: response => {
                    this.handleCheckResponse(response);
                    this.completeCheckSubject.next({
                        check: command,
                        result: response,
                        organisationId: this.organisationId
                    });
                },
                error: (error: FlyFreelyError) => {
                    if (error instanceof OperationFailed) {
                        this.operationFailedSubject.next(true);
                        this.resultStatusSubject.next('INCOMPLETE');
                        return;
                    }
                    this.logging.error(
                        error,
                        `Error while performing airspace check: ${error.message}`
                    );
                    this.resultStatusSubject.next('INCOMPLETE');
                }
            });
    }

    private checkAirspaceWithAlternativeUrl(command: AirspaceCheckCommand) {
        if (this.externalAuthorisationBearerTokenSource.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerTokenSource.getValue()
                    .accessToken
            }`
        );
        const url = this.alternativeCheckerUrlSource.getValue();
        return this.http
            .post<AirspaceCheckDto>(`${url}/checker`, command, {
                headers
            })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err instanceof OperationFailed) {
                        this.operationFailedSubject.next(true);
                        this.resultStatusSubject.next('INCOMPLETE');
                        return;
                    }
                    if (err.status === 500) {
                        const returnError: AuthorisationError = {
                            code: 500,
                            message: err.message
                        };
                        this.airspaceCheckErrorSubject.next(returnError);
                        return of(null);
                    }
                    if (err.status === 404) {
                        return of(null as AirspaceCheckDto);
                    }
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    return throwError(() => parseErrorBody(err));
                })
            );
    }

    resetCheckResults() {
        this.workflowVersionIdSubject.next(null);
        this.checkStartTime = null;
        this.resultStatusSubject.next('INCOMPLETE');
        this.resultSubject.next(null);
    }

    doCheckAirspace(
        command: AirspaceCheckCommand
    ): Observable<AirspaceCheckDto> {
        this.resultStatusSubject.next('CHECKING');
        this.operationFailedSubject.next(false);
        if (this.alternativeCheckerUrlSource.getValue() != null) {
            this.doAlternativeAirspaceCheck$.next(command);
            return of(null);
        }
        return this.geospatialService.checkAirspace(command);
    }

    private handleCheckResponse(response: AirspaceCheckDto) {
        // @ts-ignore - response doesn't fully match type
        if (response != null && response.errorMessage != null) {
            this.resultSubject.next(null);
            this.resultStatusSubject.next('INCOMPLETE');
            return;
        }
        if (response != null && response.flightStatus != null) {
            // Why no status update?
            this.resultSubject.next({
                ...response
            });
            if (
                response.flightStatus === 'NA' ||
                response.flightStatus === 'NOT_RUN'
            ) {
                this.resultStatusSubject.next('INCOMPLETE');
            } else {
                this.resultStatusSubject.next(response.flightStatus);
            }
        }
        if (
            response != null &&
            response.flightStatus == null &&
            response.availableAutomatedAuthorisationList.length > 0
        ) {
            // Why no status update?
            this.resultSubject.next({
                ...response
            });
        }
        if (response == null) {
            this.resultSubject.next(null);
            this.resultStatusSubject.next('INCOMPLETE');
        }
    }

    setupCheckCommand(
        rpas: CraftDto[],
        workflows: InUseMissionWorkflowVersionDto[],
        values: MissionFormValue,
        location: LocationDetailsDto,
        rpaTypes: LookupObject<RpaTypeDto>,
        overrideJurisdiction = false,
        setAsIncomplete = true
    ): AirspaceCheckCommand | null {
        if (setAsIncomplete) {
            this.resultStatusSubject.next('INCOMPLETE');
        }
        this.rulesetSubject.next(null);
        const {
            missionDate,
            missionEstimatedTime,
            timeZone,
            missionWorkflowVersionId,
            maximumHeight
        } = values;

        if (location == null) {
            this.resultStatusSubject.next('NO_LOCATION');
            return null;
        }

        const locationPolygon = findLocationFlightAreaPolygon(
            location.features
        );

        if (locationPolygon == null) {
            this.resultStatusSubject.next('NO_FLIGHT_AREA');
            return null;
        }

        if (
            location.airspaceJurisdiction?.airspaceCheckSupport ===
                AirspaceJurisdictionDto.AirspaceCheckSupport.NONE &&
            !overrideJurisdiction
        ) {
            this.resultStatusSubject.next('NO_CHECK');
            return null;
        }

        if (missionWorkflowVersionId == null) {
            this.resultStatusSubject.next('NO_WORKFLOW');
            return null;
        }

        const workflow = workflows.find(w => w.id === missionWorkflowVersionId);

        if (workflow == null) {
            this.resultStatusSubject.next('NO_WORKFLOW');
            return null;
        }

        if (workflow.delegatedAuthority == null) {
            this.resultStatusSubject.next('NO_DELEGATED_AUTHORITY');
            return null;
        }

        const jurisdiction =
            workflow.delegatedAuthority.authorityType?.jurisdiction?.identifier;
        const ruleset =
            workflow.delegatedAuthority.authorityType?.ruleset?.identifier;

        // TODO verify the workflow jurisdiction is the location jurisdiction

        if (
            location == null ||
            this.airspaceJurisdictionSubject.getValue() == null ||
            location.airspaceJurisdiction.id !==
                this.airspaceJurisdictionSubject.getValue().id
        ) {
            this.airspaceJurisdictionSubject.next(
                location.airspaceJurisdiction
            );
        }
        this.rulesetSubject.next(ruleset);

        if (jurisdiction == null) {
            this.resultStatusSubject.next('NO_JURISDICTION');
            return null;
        }

        if (ruleset == null) {
            this.resultStatusSubject.next('NO_RULESET');
            return null;
        }

        if (missionDate == null || timeZone == null) {
            this.resultStatusSubject.next('NO_DATE');
            return null;
        }

        const startTime = toTimestamp(missionDate, timeZone);
        this.missionDateSubject.next(startTime);

        const endTime = moment(startTime)
            .add(missionEstimatedTime ?? 3600, 'seconds')
            .toISOString();

        return {
            startTime,
            endTime,
            location: locationPolygon,
            maximumHeight,
            jurisdiction,
            ruleset,
            rpaList: rpas.map(r => ({
                id: r.id,
                mtow: rpaTypes[r.rpaTypeId]?.performanceSpecifications
                    ?.maxTakeOffWeight,
                serialNumber: r.manufacturerSerialNumber
            })),
            nearestFeatureSearch: false,
            nearestFeatureSearchRadiusMeters: 400
        } as AirspaceCheckCommand;
    }

    private startPollingAuthenticationBearerToken() {
        // attempt tp refresh the bearer token every 30 minutes
        timer(0, 1800000)
            .pipe(
                switchMap(() => this.getAuthenticationBearerToken()),
                takeUntil(this.stopPollingAuthorisation$)
            )
            .subscribe({
                next: token =>
                    this.externalAuthorisationBearerTokenSource.next(token),
                error: (error: FlyFreelyError) => {
                    this.stopPollingAuthorisation$.next();
                    this.externalAuthorisationBearerTokenSource.next(null);
                }
            });
    }

    private getAuthenticationBearerToken() {
        if (this.savedMissionSubject.getValue()?.id != null) {
            return this.airspaceAuthorisationService
                .getExternalAuthorisationBearerToken(
                    this.savedMissionSubject.getValue()?.id
                )
                .pipe(takeUntil(this.ngUnsubscribe$));
        } else {
            return this.geospatialService
                .findAlternativeNonMissionAccessToken()
                .pipe(takeUntil(this.ngUnsubscribe$));
        }
    }

    restartExternalBearerTokenPolling() {
        this.stopPollingAuthorisation$.next();
        if (this.alternativeCheckerUrlSource.getValue() != null) {
            this.startPollingAuthenticationBearerToken();
        }
    }

    findSunriseSunset(centre: any, date: string) {
        const coords = getCoords(centre);
        const plannedDate = moment(date).format('YYYY-MM-DD');
        this.geospatialService
            .getSunriseSunset(coords[1], coords[0], plannedDate)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => this.sunriseSunsetSubject.next(results));
    }

    viewJurisdictionLibrary() {
        this.modalService.show(JurisdictionLibraryDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                jurisdictionId: this.currentJurisdictionId,
                organisationId: this.organisationId
            }
        });
    }

    private refreshJurisdictions() {
        return this.jurisdictionsService.findJurisdictions().pipe(
            takeUntil(this.ngUnsubscribe$),
            tap(jurisdictions => {
                this.airspaceJurisdictions = jurisdictions;
            })
        );
    }

    findJurisdictionForFlightArea(flightArea: Geometry) {
        if (this.airspaceJurisdictions == null) {
            this.airspaceJurisdictionSubject.next(null);
            return;
        }
        const location = center(flightArea);
        const jurisdiction =
            this.airspaceJurisdictions.find(
                f =>
                    f != null &&
                    f.boundary != null &&
                    booleanPointInPolygon(
                        location,
                        f.boundary as Polygon | MultiPolygon
                    )
            ) ?? null;
        this.airspaceJurisdictionSubject.next(jurisdiction);
        return jurisdiction;
    }
}
