import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    FEATURE_INSTANT_MISSIONS,
    FlyFreelyError,
    FlyFreelyLoggingService,
    hasFeatureFlag,
    InUseMissionWorkflowVersionDto,
    MissionOperationTypeDto,
    MissionService,
    MissionWorkflowService,
    OrganisationService,
    PersonsOrganisationDto,
    UpdateOrganisationSettingsCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { forkJoin, startWith, Subject, takeUntil } from 'rxjs';

interface UpdateOrganisationSettingsForm {
    instantMissionWorkflowId: FormControl<number>;
    defaultMissionWorkflowId: FormControl<number>;
    defaultOperationTypeId: FormControl<number>;
    defaultMaximumHeight: FormControl<number>;
}

@Component({
    selector: 'organisation-default-settings',
    templateUrl: './organisation-settings.component.html'
})
export class OrganisationDefaultSettingsComponent {
    @Input() organisation: PersonsOrganisationDto;

    settingsForm: FormGroup<UpdateOrganisationSettingsForm> = new FormGroup({
        defaultMaximumHeight: new FormControl<number>(undefined),
        defaultMissionWorkflowId: new FormControl<number>(undefined),
        defaultOperationTypeId: new FormControl<number>(undefined),
        instantMissionWorkflowId: new FormControl<number>(undefined)
    });

    availableMissionWorkflows: InUseMissionWorkflowVersionDto[];
    availableInstantMissionWorkflows: InUseMissionWorkflowVersionDto[];
    invalidInstantWorkflow: InUseMissionWorkflowVersionDto;
    availableOperationTypes: MissionOperationTypeDto[];

    hasInstantMissions = false;

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private organisationService: OrganisationService,
        private missionWorkflowService: MissionWorkflowService,
        private missionService: MissionService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.updateSettingsFormValues(this.organisation.settings);
        this.refreshAvailableOptions();
        this.refreshFeatureFlags();
        this.settingsForm.controls.instantMissionWorkflowId.valueChanges
            .pipe(
                startWith(
                    this.settingsForm.controls.instantMissionWorkflowId.value
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(workflowId => {
                this.checkInstantMissionWorkflowValidity();
            });

        this.missionWorkflowService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(change => {
                this.refreshAvailableOptions();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    updateSettingsFormValues(settings: UpdateOrganisationSettingsCommand) {
        this.settingsForm.patchValue({
            ...settings
        });
        this.settingsForm.markAsPristine();
    }

    refreshAvailableOptions() {
        forkJoin([
            this.missionService.findMissionTypes(this.organisation.id),
            this.missionWorkflowService.findActiveWorkflows(
                this.organisation.id
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([operationTypes, workflows]) => {
                    this.availableMissionWorkflows = workflows.sort((a, b) =>
                        a.workflowName.localeCompare(b.workflowName)
                    );
                    this.availableInstantMissionWorkflows =
                        this.availableMissionWorkflows.filter(
                            w => w.validFor.indexOf('INSTANT_MISSION') !== -1
                        );
                    this.availableOperationTypes = operationTypes;
                    this.checkInstantMissionWorkflowValidity();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error loading default organisation settings options: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    checkInstantMissionWorkflowValidity() {
        const workflowId =
            this.settingsForm.controls.instantMissionWorkflowId.value;
        if (
            workflowId == null ||
            this.availableInstantMissionWorkflows?.find(
                w => w.workflowId === workflowId
            ) != null
        ) {
            this.invalidInstantWorkflow = null;
            return;
        } else {
            const workflow = this.availableMissionWorkflows?.find(
                workflow => workflow.workflowId === workflowId
            );
            this.invalidInstantWorkflow = workflow ?? {
                id: null,
                workflowId: workflowId,
                workflowName: 'Invalid',
                delegatedAuthorityId: undefined,
                additionalAuthorityIds: [],
                availableActions: {
                    canEdit: false
                },
                versionNumber: 0,
                mappedAttachmentRequirements: {},
                mappedForms: {},
                delegatedAuthority: undefined,
                additionalAuthorities: [],
                workgroupIdList: [],
                expired: false,
                validFor: [],
                authorityExpired: false,
                authorityArchived: false
            };
        }
    }

    refreshFeatureFlags() {
        this.hasInstantMissions = hasFeatureFlag(
            this.organisation,
            FEATURE_INSTANT_MISSIONS
        );
    }

    updateSettings() {
        const value = this.settingsForm.value;
        const command: UpdateOrganisationSettingsCommand = {
            defaultMaximumHeight: value.defaultMaximumHeight,
            defaultMissionWorkflowId: value.defaultMissionWorkflowId,
            defaultOperationTypeId: value.defaultOperationTypeId,
            instantMissionWorkflowId: value.instantMissionWorkflowId
        };
        this.organisationService
            .updateOrganisationSettings(this.organisation.id, command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    // update settings form
                    this.logging.success(
                        'Your default settings have been updated'
                    );
                    this.updateSettingsFormValues(command);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error updating default organisation settings: ${error.message}`
                    );
                }
            });
    }
}
