import { Injectable } from '@angular/core';
import {
    FEATURE_CURRENCY,
    FEATURE_DJI_LOGS,
    FEATURE_DRONE_DEPLOY_LOGS,
    FEATURE_FLIGHT_CONFORMANCE,
    FEATURE_RPA_RESOURCE_ASSOCIATION,
    FEATURE_SKYDIO_LOGS,
    FEATURE_WORKGROUPS
} from '@flyfreely-portal-ui/flyfreely';

interface FeaturesComponent {
    identifier: string;
    name: string;
    type: 'upsell';
    defaultText?: string;
    featureFlag: string;
}

interface UiComponent {
    identifier: string;
    name: string;
    type: 'inline' | 'tooltip' | 'empty' | 'text';
    defaultText?: string;
}

type Component = FeaturesComponent | UiComponent;

export interface ScreenTree {
    identifier: string;
    name: string;
    analyticsIdentifier: string;
    components: Component[];
}

/**
 * This service provides the enhanced help placements for all components.
 *
 * Often, we have an overview/overall enhanced help slot for the entire screen. This should have the identifier `screen`, as `overview` is often a actual part of the screen.
 *
 */
@Injectable({
    providedIn: 'root'
})
export class EnhancedHelpTreeService {
    screenTree: ScreenTree[] = [
        {
            identifier: 'enhanced-help-help',
            analyticsIdentifier: 'enhanced-help-help',
            name: 'Enhanced Help',
            components: [
                { identifier: 'main', name: 'Enhanced Help', type: 'inline' }
            ]
        },
        {
            identifier: 'mission-edit-v2-dialogue',
            analyticsIdentifier: 'mission-edit-v2-dialogue',
            name: 'Mission Planning',
            components: [
                { identifier: 'name', name: 'Mission Name', type: 'inline' },
                {
                    identifier: 'missionWorkgroup',
                    name: 'Workgroup',
                    type: 'inline'
                },
                {
                    identifier: 'isDummy',
                    name: 'Dummy Mission',
                    type: 'inline'
                },
                {
                    identifier: 'missionObjectiveTypeId',
                    name: 'Mission Objective',
                    type: 'inline'
                },
                {
                    identifier: 'missionObjective',
                    name: 'Mission Objective Description',
                    type: 'inline'
                },
                {
                    identifier: 'locationId',
                    name: 'Mission Location',
                    type: 'inline'
                },
                {
                    identifier: 'missionTypeId',
                    name: 'Mission Type',
                    type: 'inline'
                },
                {
                    identifier: 'missionWorkflowVersionId',
                    name: 'Mission Workflow',
                    type: 'inline'
                },
                {
                    identifier: 'additionalAuthorityIds',
                    name: 'Additional Authorities',
                    type: 'inline'
                },
                { identifier: 'craftIds', name: 'RPA', type: 'inline' },
                {
                    identifier: 'equipmentIds',
                    name: 'Equipment',
                    type: 'inline'
                },
                {
                    identifier: 'rpicId',
                    name: '(unused) Remote Pilot in Command',
                    type: 'inline'
                },
                { identifier: 'missionCrew', name: 'Crew', type: 'inline' },
                { identifier: 'crewNotes', name: 'Crew Notes', type: 'inline' },
                { identifier: 'timeZone', name: 'Time Zone', type: 'inline' },
                {
                    identifier: 'missionDate',
                    name: 'Planned Time / UTC',
                    type: 'inline'
                },
                {
                    identifier: 'missionEstimatedTime',
                    name: 'Estimated Duration',
                    type: 'inline'
                },
                {
                    identifier: 'timeOfDay',
                    name: 'Time of Day',
                    type: 'inline'
                },
                {
                    identifier: 'visualLineOfSight',
                    name: 'Visual Line of Sight',
                    type: 'inline'
                },
                {
                    identifier: 'maximumHeight',
                    name: 'Expected Maximum Height',
                    type: 'inline'
                },
                {
                    identifier: 'nearbyAerodromes',
                    name: 'Nearby Aerodromes',
                    type: 'inline'
                },
                {
                    identifier: 'notams',
                    name: 'Mission NOTAMs',
                    type: 'inline'
                },
                {
                    identifier: 'missionEmergencyContacts',
                    name: 'Mission Emergency Contacts (new)',
                    type: 'inline'
                },
                {
                    identifier: 'missionRadioFrequencies',
                    name: 'Mission Radio Frequencies (new)',
                    type: 'inline'
                },
                {
                    identifier: 'emergencyContacts',
                    name: 'Emergency Contacts',
                    type: 'inline'
                },
                {
                    identifier: 'radioFrequencies',
                    name: 'Radio Frequencies',
                    type: 'inline'
                },
                {
                    identifier: 'attachedDocuments',
                    name: 'Attached Documents',
                    type: 'inline'
                },
                {
                    identifier: 'documentation',
                    name: 'Mission Documentation',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'mission-approval-v2-dialogue',
            analyticsIdentifier: 'mission-approval-v2',
            name: 'Mission Approval',
            components: [
                {
                    identifier: 'approvalChecklist',
                    name: 'Approval Checklist',
                    type: 'inline'
                },
                {
                    identifier: 'documentation',
                    name: 'Approval Documentation',
                    type: 'inline'
                },
                {
                    identifier: 'messageToRemotePilot',
                    name: 'Message to Remote Pilot',
                    type: 'inline'
                },
                {
                    identifier: 'maxHeight',
                    name: 'Approved Max Height',
                    type: 'inline'
                },
                {
                    identifier: 'approvalConditions',
                    name: 'Approval Conditions',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'mission-completion-dialogue',
            analyticsIdentifier: 'mission-record-v1',
            name: 'Mission Record',
            components: [
                {
                    identifier: 'outcome',
                    name: 'Objective Outcome',
                    type: 'inline'
                },
                {
                    identifier: 'objectiveOutcomeNotes',
                    name: 'Objective Outcome Details',
                    type: 'inline'
                },
                {
                    identifier: 'actualStartTime',
                    name: 'Start Time',
                    type: 'inline'
                },
                {
                    identifier: 'actualEndTime',
                    name: 'End Time',
                    type: 'inline'
                },
                { identifier: 'journal', name: 'Journal', type: 'inline' },
                { identifier: 'flights', name: 'Flights', type: 'inline' },
                {
                    identifier: 'flightLogs',
                    name: 'Flight Logs',
                    type: 'inline'
                },
                {
                    identifier: 'documentation',
                    name: 'Mission Documentation',
                    type: 'inline'
                },
                {
                    identifier: 'unlisted',
                    name: 'Unlisted Mission Resources',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'historical-mission-record-dialogue',
            analyticsIdentifier: 'historical-mission-record',
            name: 'Historical Mission Record',
            components: [
                { identifier: 'name', name: 'Mission Name', type: 'inline' },
                {
                    identifier: 'missionObjectiveTypeId',
                    name: 'Mission Objective',
                    type: 'inline'
                },
                {
                    identifier: 'missionObjective',
                    name: 'Mission Objective Description',
                    type: 'inline'
                },
                {
                    identifier: 'outcome',
                    name: 'Objective Outcome',
                    type: 'inline'
                },
                {
                    identifier: 'objectiveOutcomeNotes',
                    name: 'Objective Outcome Details',
                    type: 'inline'
                },
                {
                    identifier: 'missionTypeId',
                    name: 'Mission Type',
                    type: 'inline'
                },
                { identifier: 'timeZone', name: 'Time Zone', type: 'inline' },
                {
                    identifier: 'missionDate',
                    name: 'Mission Date',
                    type: 'inline'
                },
                {
                    identifier: 'timeOfDay',
                    name: 'Time of Day',
                    type: 'inline'
                },
                {
                    identifier: 'visualLineOfSight',
                    name: 'Visual Line of Sight',
                    type: 'inline'
                },
                {
                    identifier: 'completionMessage',
                    name: 'Journal',
                    type: 'inline'
                },
                { identifier: 'flights', name: 'Flights', type: 'inline' }
            ]
        },
        {
            identifier: 'rpa-edit',
            analyticsIdentifier: 'rpa-add',
            name: 'RPA Add/Edit',
            components: [
                { identifier: 'nickname', name: 'Name', type: 'inline' },
                { identifier: 'isDummy', name: 'Dummy', type: 'inline' },
                { identifier: 'nfcUid', name: 'NFC UID', type: 'inline' },
                {
                    identifier: 'manufacturerSerialNumber',
                    name: 'Manufacturer Serial Number',
                    type: 'inline'
                },
                {
                    identifier: 'rpaTypeId',
                    name: 'RPA Model',
                    type: 'inline'
                },
                {
                    identifier: 'initialFlightTime',
                    name: 'Estimated Historical Flight Time',
                    type: 'inline'
                },
                {
                    identifier: 'procurementDate',
                    name: 'Estimated Procurement Date',
                    type: 'inline'
                },
                {
                    identifier: 'maximumVisualDistance',
                    name: 'Maximum Visual Distance',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'rpa-view',
            analyticsIdentifier: 'rpa-view',
            name: 'RPA View',
            components: [
                {
                    identifier: 'rpa-details',
                    name: 'RPA Details',
                    type: 'inline'
                },
                {
                    identifier: 'notes',
                    name: 'Notes',
                    type: 'inline'
                },
                {
                    identifier: 'notes-empty',
                    name: 'Empty Notes',
                    type: 'empty'
                },
                {
                    identifier: 'notes-empty-edit',
                    name: 'Empty Notes (can edit)',
                    type: 'empty'
                },
                {
                    identifier: 'flight-history-summary',
                    name: 'Flight History Summary',
                    type: 'inline'
                },
                {
                    identifier: 'logged-flights',
                    name: 'Logged Flights',
                    type: 'inline'
                },
                {
                    identifier: 'component-service-time',
                    name: 'Component Service Time',
                    type: 'inline'
                },
                {
                    identifier: 'maintenance-history-overview',
                    name: 'Maintenance History',
                    type: 'inline'
                },
                {
                    identifier: 'rpa-authorities',
                    name: 'RPA Authorities',
                    type: 'inline'
                },
                {
                    identifier: 'register-status',
                    name: 'Register Status',
                    type: 'inline'
                },
                { identifier: 'rpa-manuals', name: 'Manuals', type: 'inline' },
                {
                    identifier: 'rpa-specifications',
                    name: 'Specifications',
                    type: 'inline'
                },
                {
                    identifier: 'associated-resources',
                    name: 'Associated Resources',
                    type: 'inline'
                },
                {
                    identifier: 'rpa-resource-association-upsell',
                    name: 'RPA Resource Association',
                    type: 'upsell',
                    featureFlag: FEATURE_RPA_RESOURCE_ASSOCIATION
                }
            ]
        },
        {
            identifier: 'battery-edit',
            analyticsIdentifier: 'battery-add',
            name: 'Battery Add/Edit',
            components: [
                { identifier: 'screen', name: 'Screen', type: 'inline' },
                { identifier: 'name', name: 'Name', type: 'inline' },
                {
                    identifier: 'manufacturerSerialNumber',
                    name: 'Manufacturer Serial Number',
                    type: 'inline'
                },
                { identifier: 'nfcUid', name: 'NFC UID', type: 'inline' },
                {
                    identifier: 'purchaseDate',
                    name: 'Purchase Date',
                    type: 'inline'
                },
                {
                    identifier: 'batteryTypeId',
                    name: 'Battery Type',
                    type: 'inline'
                },
                {
                    identifier: 'initialFlightTime',
                    name: 'Initial Flight Time',
                    type: 'inline'
                },
                {
                    identifier: 'initialCycles',
                    name: 'Initial Cycles',
                    type: 'inline'
                },
                { identifier: 'isDummy', name: 'Dummy', type: 'inline' },
                {
                    identifier: 'makeBatterySet',
                    name: 'Make Battery Set',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'battery-view',
            analyticsIdentifier: 'battery-view',
            name: 'Battery View',
            components: [
                {
                    identifier: 'battery-details',
                    name: 'Battery Details',
                    type: 'inline'
                },
                {
                    identifier: 'notes',
                    name: 'Notes',
                    type: 'inline'
                },
                {
                    identifier: 'notes-empty',
                    name: 'Empty Notes',
                    type: 'empty'
                },
                {
                    identifier: 'notes-empty-edit',
                    name: 'Empty Notes (can edit)',
                    type: 'empty'
                },
                {
                    identifier: 'flight-history-summary',
                    name: 'Flight History Summary',
                    type: 'inline'
                },
                {
                    identifier: 'logged-flights',
                    name: 'Logged Flights',
                    type: 'inline'
                },
                {
                    identifier: 'maintenance-history-overview',
                    name: 'Maintenance History',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'battery-set-edit',
            analyticsIdentifier: 'battery-set-add',
            name: 'Battery Set Add/Edit',
            components: [
                { identifier: 'screen', name: 'Screen', type: 'inline' },
                { identifier: 'name', name: 'Name', type: 'inline' },
                {
                    identifier: 'assemblyDate',
                    name: 'Assembly Date',
                    type: 'inline'
                },
                {
                    identifier: 'disassemblyDate',
                    name: 'Disassembly Date',
                    type: 'inline'
                },
                {
                    identifier: 'batteryIds',
                    name: 'Batteries in Set',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'battery-set-view',
            analyticsIdentifier: 'battery-set-view',
            name: 'Battery Set View',
            components: [
                {
                    identifier: 'battery-set-details',
                    name: 'Battery Set Details',
                    type: 'inline'
                },
                {
                    identifier: 'notes',
                    name: 'Notes',
                    type: 'inline'
                },
                {
                    identifier: 'notes-empty',
                    name: 'Empty Notes',
                    type: 'empty'
                },
                {
                    identifier: 'notes-empty-edit',
                    name: 'Empty Notes (can edit)',
                    type: 'empty'
                },
                {
                    identifier: 'flight-history-summary',
                    name: 'Flight History Summary',
                    type: 'inline'
                },
                {
                    identifier: 'logged-flights',
                    name: 'Logged Flights',
                    type: 'inline'
                },
                {
                    identifier: 'maintenance-history-overview',
                    name: 'Maintenance History',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'equipment-view',
            analyticsIdentifier: 'equipment-view',
            name: 'Equipment View',
            components: [
                {
                    identifier: 'equipment-details',
                    name: 'Equipment Details',
                    type: 'inline'
                },
                {
                    identifier: 'notes',
                    name: 'Notes',
                    type: 'inline'
                },
                {
                    identifier: 'notes-empty',
                    name: 'Empty Notes',
                    type: 'empty'
                },
                {
                    identifier: 'notes-empty-edit',
                    name: 'Empty Notes (can edit)',
                    type: 'empty'
                },
                {
                    identifier: 'maintenance-history-overview',
                    name: 'Maintenance History Overview',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'equipment-edit',
            analyticsIdentifier: 'equipment-edit',
            name: 'Equipment Add/Edit',
            components: [
                {
                    identifier: 'equipmentCategory',
                    name: 'Equipment Category',
                    type: 'inline'
                },
                {
                    identifier: 'makeModel',
                    name: 'Equipment Model',
                    type: 'inline'
                },
                {
                    identifier: 'name',
                    name: 'Name/Designation',
                    type: 'inline'
                },
                {
                    identifier: 'manufacturerSerialNumber',
                    name: 'Manufacturer Serial Number',
                    type: 'inline'
                },
                {
                    identifier: 'currentFirmwareVersion',
                    name: 'Current Firmware Version',
                    type: 'inline'
                },
                { identifier: 'nfcId', name: 'NFC UID', type: 'inline' }
            ]
        },
        {
            identifier: 'authority-details',
            analyticsIdentifier: 'authority-details',
            name: 'Authority Details',
            components: [
                { identifier: 'screen', name: 'Main Help', type: 'inline' }
            ]
        },
        {
            identifier: 'rpa-authority-edit',
            analyticsIdentifier: 'rpa-authority-edit',
            name: 'RPA Authority Edit',
            components: [
                { identifier: 'startDate', name: 'Start Date', type: 'inline' },
                {
                    identifier: 'expiryDate',
                    name: 'Expiry Date',
                    type: 'inline'
                },
                { identifier: 'regNumber', name: 'Identifier', type: 'inline' }
            ]
        },
        {
            identifier: 'rpa-register',
            analyticsIdentifier: 'rpa-register',
            name: 'RPA Register',
            components: [
                {
                    identifier: 'rpa-register',
                    name: 'Register Overview',
                    type: 'inline'
                },
                {
                    identifier: 'rpa-register-disabled',
                    name: 'Register Disabled',
                    type: 'empty'
                },
                {
                    identifier: 'conditionChecks',
                    name: 'Register Conditions Check',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'personnel-add',
            analyticsIdentifier: 'personnel-add',
            name: 'Add Personnel',
            components: [
                { identifier: 'email', name: 'Add Email', type: 'inline' },
                {
                    identifier: 'email-list',
                    name: 'Invitee Emails',
                    type: 'inline'
                },
                {
                    identifier: 'subscription-select',
                    name: 'Select Subscription',
                    type: 'inline'
                },
                {
                    identifier: 'organisation-roles',
                    name: 'Organisation Specific Roles',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'personnel-register',
            analyticsIdentifier: 'personnel-register',
            name: 'Personnel Register',
            components: [
                {
                    identifier: 'personnel-register',
                    name: 'Register Overview',
                    type: 'inline'
                },
                {
                    identifier: 'personnel-register-disabled',
                    name: 'Register Disabled',
                    type: 'empty'
                },
                {
                    identifier: 'conditionChecks',
                    name: 'Register Conditions Check',
                    type: 'inline'
                },
                {
                    identifier: 'currencyConfig',
                    name: 'Currency Upsell',
                    type: 'upsell',
                    featureFlag: FEATURE_CURRENCY
                }
            ]
        },
        {
            identifier: 'maintenance',
            analyticsIdentifier: 'maintenance',
            name: 'Maintenance Details Dialogue',
            components: [
                {
                    identifier: 'maintenanceType',
                    name: 'Maintenance Type',
                    type: 'inline'
                },
                {
                    identifier: 'resourceCategory',
                    name: 'Resource Category',
                    type: 'inline'
                },
                { identifier: 'craftIds', name: 'RPA', type: 'inline' },
                { identifier: 'batteryIds', name: 'Battery', type: 'inline' },
                {
                    identifier: 'batterySetIds',
                    name: 'Battery Set',
                    type: 'inline'
                },
                {
                    identifier: 'equipmentIds',
                    name: 'Equipment',
                    type: 'inline'
                },
                {
                    identifier: 'reasonForRequest',
                    name: 'Reason for Request',
                    type: 'inline'
                },
                {
                    identifier: 'safeToUse',
                    name: 'Safe to Use',
                    type: 'inline'
                },
                {
                    identifier: 'maintenanceIdentifier',
                    name: 'Maintenance Identifier',
                    type: 'inline'
                },
                {
                    identifier: 'maintenanceWorkInstructions',
                    name: 'Maintenance Work Instructions',
                    type: 'inline'
                },
                {
                    identifier: 'description',
                    name: 'Description of Required Maintenance',
                    type: 'inline'
                },
                {
                    identifier: 'maintainerId',
                    name: 'Assigned Maintenance Person',
                    type: 'inline'
                },
                {
                    identifier: 'maintenanceOrganisation',
                    name: 'External Maintenance Organisation',
                    type: 'inline'
                },
                {
                    identifier: 'scheduledDate',
                    name: 'Scheduled Date',
                    type: 'inline'
                },
                {
                    identifier: 'tasks',
                    name: 'Maintenance Tasks',
                    type: 'inline'
                },
                {
                    identifier: 'maintenancePerformed',
                    name: 'Maintenance Performed',
                    type: 'inline'
                },
                {
                    identifier: 'maintenanceRemarks',
                    name: 'Maintenance Remarks',
                    type: 'inline'
                },
                { identifier: 'startDate', name: 'Start Date', type: 'inline' },
                { identifier: 'endDate', name: 'End Date', type: 'inline' },
                {
                    identifier: 'flightTestRequired',
                    name: 'Test Flight required',
                    type: 'inline'
                },
                {
                    identifier: 'maintenanceControllerNotes',
                    name: 'Additional Maintenance Controller Notes',
                    type: 'inline'
                },
                {
                    identifier: 'outcome',
                    name: 'Resource Serviceability Outcome',
                    type: 'inline'
                },
                {
                    identifier: 'scheduledMaintenanceCompleted',
                    name: 'Scheduled Maintenance Completed',
                    type: 'inline'
                },
                {
                    identifier: 'requestAttachments',
                    name: 'Request Attachments',
                    type: 'inline'
                },
                {
                    identifier: 'activityAttachments',
                    name: 'Activity Attachments',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'field-app-mission-details',
            analyticsIdentifier: 'field-app-mission-details',
            name: 'Field App Mission Details Flow',
            components: [
                {
                    identifier: 'serviceability-signoff',
                    name: 'Serviceability Signoff',
                    type: 'inline'
                },
                {
                    identifier: 'maintenance-unlisted',
                    name: 'Maintenance on Unlisted RPA',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'organisation-admin',
            analyticsIdentifier: 'organisation-admin',
            name: 'Organisation Admin',
            components: [
                {
                    identifier: 'organisation-overview',
                    name: 'Organisation Overview',
                    type: 'inline'
                },
                {
                    identifier: 'organisation-time-zone',
                    name: 'Organisation Time Zone',
                    type: 'inline'
                },
                {
                    identifier: 'organisation-addresses',
                    name: 'Organisation Addresses',
                    type: 'inline'
                },
                {
                    identifier: 'organisation-authority-edit',
                    name: 'Organisation Authority',
                    type: 'inline'
                },
                {
                    identifier: 'mission-workflow-list',
                    name: 'Mission Workflows',
                    type: 'inline'
                },
                {
                    identifier: 'organisation-default-settings',
                    name: 'Default Mission Settings',
                    type: 'inline'
                },
                {
                    identifier: 'shared-authorities',
                    name: 'Shared Authorities',
                    type: 'inline'
                },
                {
                    identifier: 'condition-library-list',
                    name: 'Approval Conditions',
                    type: 'inline'
                },
                {
                    identifier: 'mission-objectives-list',
                    name: 'Mission Objectives',
                    type: 'inline'
                },
                {
                    identifier: 'battery-type-list',
                    name: 'Battery Types',
                    type: 'inline'
                },
                {
                    identifier: 'rpa-type-list',
                    name: 'RPA Types',
                    type: 'inline'
                },
                {
                    identifier: 'rpa-model-customisation',
                    name: 'RPA Model Customisation',
                    type: 'inline'
                },
                {
                    identifier: 'organisation-subscriptions',
                    name: 'Subscriptions & Billing',
                    type: 'inline'
                },
                { identifier: 'referrals', name: 'Referrals', type: 'inline' },
                {
                    identifier: 'flight-conformance-upsell',
                    name: 'Flight Conformance',
                    type: 'upsell',
                    featureFlag: FEATURE_FLIGHT_CONFORMANCE
                },
                {
                    identifier: 'workgroups-upsell',
                    name: 'Workgroups',
                    type: 'upsell',
                    featureFlag: FEATURE_WORKGROUPS
                }
            ]
        },
        {
            identifier: 'approval-conditions-library-edit',
            analyticsIdentifier: 'approval-conditions-library-edit',
            name: 'Approval Conditions Library Edit',
            components: [
                {
                    identifier: 'screen',
                    name: 'Overview of the whole screen',
                    type: 'inline'
                },
                {
                    identifier: 'add-condition',
                    name: 'Overview of conditions and adding them',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'user-profile',
            analyticsIdentifier: 'user-profile',
            name: 'User Profile',
            components: [
                {
                    identifier: 'profile-details',
                    name: 'Profile Details',
                    type: 'inline'
                },
                {
                    identifier: 'managing-organisations',
                    name: 'Managing Organisations',
                    type: 'inline'
                },
                {
                    identifier: 'organisation-authority-edit',
                    name: 'Organisation Authority',
                    type: 'inline'
                },
                {
                    identifier: 'profile-flight-history',
                    name: 'Flight History',
                    type: 'inline'
                },
                {
                    identifier: 'profile-notification-settings',
                    name: 'Notification Settings',
                    type: 'inline'
                },
                {
                    identifier: 'mailing-list-preferences',
                    name: 'Mailing List Preferences',
                    type: 'inline'
                },
                {
                    identifier: 'api-keys-edit',
                    name: 'API Keys',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'organisation-authority-edit',
            analyticsIdentifier: 'organisation-authority-edit',
            name: 'Organisation Authority Edit',
            components: [
                { identifier: 'startDate', name: 'Start Date', type: 'inline' },
                {
                    identifier: 'expiryDate',
                    name: 'Expiry Date',
                    type: 'inline'
                },
                {
                    identifier: 'identifier',
                    name: 'Identifier',
                    type: 'inline'
                },
                {
                    identifier: 'requiresApprovalPolicy',
                    name: 'Requires Approval',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'location-edit',
            analyticsIdentifier: 'location-edit',
            name: 'Location Template Add/Edit',
            components: [
                {
                    identifier: 'feature-add',
                    name: 'Add Features',
                    type: 'inline'
                },
                {
                    identifier: 'upload-geometry',
                    name: 'Upload Geometry Button',
                    type: 'tooltip'
                }
            ]
        },
        {
            identifier: 'location-view',
            analyticsIdentifier: 'location-view',
            name: 'View Location Template',
            components: []
        },
        {
            identifier: 'location-template-from-mission',
            analyticsIdentifier: 'location-template-from-mission',
            name: 'Create Location From Mission',
            components: [
                { identifier: 'screen', name: 'Main Help', type: 'inline' }
            ]
        },
        {
            identifier: 'location-edit-kml',
            analyticsIdentifier: 'location-edit-kml',
            name: 'KML Upload Feature Setup',
            components: [
                { identifier: 'screen', name: 'Main Help', type: 'inline' }
            ]
        },
        {
            identifier: 'airspace-check-details',
            analyticsIdentifier: 'airspace-check-details',
            name: 'Airspace Check Details',
            components: [
                { identifier: 'overview', name: 'Overview', type: 'inline' }
            ]
        },
        {
            identifier: 'airspace-authorisation-preview',
            analyticsIdentifier: 'airspace-authorisation-preview',
            name: 'Airspace Authorisation',
            components: [
                {
                    identifier: 'screen-AUS_CASA_AUTHORISATION',
                    name: 'CASA Authorisation',
                    type: 'inline'
                },
                {
                    identifier: 'screen-NZL_AIRSHARE',
                    name: 'AirShare Authorisation',
                    type: 'inline'
                },
                {
                    identifier: 'isShielded',
                    name: 'Additional Fields - Shielded Operations',
                    type: 'inline'
                },
                {
                    identifier: 'isCameraInUse',
                    name: 'Additional Fields - Camera In Use',
                    type: 'inline'
                },
                {
                    identifier: 'flightPurpose',
                    name: 'Additional Fields - Flight Purpose',
                    type: 'inline'
                },
                {
                    identifier: 'hasCertifiedTransmitter',
                    name: 'Additional Fields - Certified Transmitter',
                    type: 'inline'
                },
                {
                    identifier: 'hasVhfRadioContact',
                    name: 'Additional Fields - VHF Radio Contact',
                    type: 'inline'
                },
                {
                    identifier: 'descriptionOfOperatingArea',
                    name: 'Additional Fields - Description of Operating Area',
                    type: 'inline'
                },
                {
                    identifier: 'emergencyProcedure',
                    name: 'Additional Fields - Emergency Procedure',
                    type: 'inline'
                },
                {
                    identifier: 'procedureMeasureAltitude',
                    name: 'Additional Fields - Procedure To Measure Altitude',
                    type: 'inline'
                },
                {
                    identifier: 'otherInformation',
                    name: 'Additional Fields - Other Information',
                    type: 'inline'
                },
                {
                    identifier: 'safetyJustification',
                    name: 'Safety Justification',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'flight-log-overview',
            analyticsIdentifier: 'flight-log-overview',
            name: 'Flight Logs',
            components: [
                {
                    identifier: 'flight-log-list',
                    name: 'Flight Log List',
                    type: 'inline'
                },
                {
                    identifier: 'flight-log-uploader',
                    name: 'Flight Log Uploader',
                    type: 'inline'
                },
                {
                    identifier: 'flight-log-assign',
                    name: 'Assign Flight Log to Mission',
                    type: 'inline'
                },
                {
                    identifier: 'flight-log-assign-rpa',
                    name: 'Assign RPA',
                    type: 'inline'
                },
                {
                    identifier: 'incoming-filter',
                    name: 'Incoming Logs Filter',
                    type: 'tooltip'
                },
                {
                    identifier: 'assigned-filter',
                    name: 'Assigned Logs Filter',
                    type: 'tooltip'
                },
                {
                    identifier: 'archived-filter',
                    name: 'Archived Logs Filter',
                    type: 'tooltip'
                },
                {
                    identifier: 'failed-filter',
                    name: 'Failed Logs Filter',
                    type: 'tooltip'
                },
                {
                    identifier: 'settings-button',
                    name: 'Settings Button',
                    type: 'tooltip'
                },
                {
                    identifier: 'upload-button',
                    name: 'Upload Button',
                    type: 'tooltip'
                },
                {
                    identifier: 'empty-flight-logs',
                    name: 'No Logs',
                    type: 'empty'
                },
                {
                    identifier: 'log-battery-not-in-org',
                    name: 'Battery not in organisation',
                    type: 'tooltip'
                },
                {
                    identifier: 'upload-button-text',
                    name: 'Upload Button Text',
                    type: 'text'
                }
            ]
        },
        {
            identifier: 'flight-log-settings',
            analyticsIdentifier: 'flight-log-settings',
            name: 'Flight Logs Settings',
            components: [
                {
                    identifier: 'screen',
                    name: 'Flight Log Collection Screen',
                    type: 'inline',
                    defaultText: ''
                },
                {
                    identifier: 'empty-dji-accounts',
                    name: 'DJI Empty State',
                    type: 'empty',
                    defaultText: 'Add a DJI account to start syncing'
                },
                {
                    identifier: 'empty-dronedeploy-accounts',
                    name: 'DroneDeploy Empty State',
                    type: 'empty',
                    defaultText:
                        'Setup to sync with DroneDeploy by adding the FlyFreely app in DroneDeploy and completing the setup here.'
                },
                {
                    identifier: 'empty-skydio-accounts',
                    name: 'Skydio Empty State',
                    type: 'empty',
                    defaultText: 'Add a Skydio account to start syncing.'
                },
                {
                    identifier: 'upsell-dji',
                    name: 'DJI Sync Features',
                    type: 'upsell',
                    featureFlag: FEATURE_DJI_LOGS
                },
                {
                    identifier: 'upsell-dronedeploy',
                    name: 'DroneDeploy Features',
                    type: 'upsell',
                    featureFlag: FEATURE_DRONE_DEPLOY_LOGS
                },
                {
                    identifier: 'upsell-skydio',
                    name: 'Skydio Features',
                    type: 'upsell',
                    featureFlag: FEATURE_SKYDIO_LOGS
                }
            ]
        },
        {
            identifier: 'flight-log-collection-organisation-settings',
            analyticsIdentifier: 'flight-log-collection-organisation-settings',
            name: 'Flight Log Collection Organisation Settings',
            components: []
        },
        {
            identifier: 'flight-log-mission-creation-settings',
            analyticsIdentifier: 'flight-log-mission-creation-settings',
            name: 'Flight Log Mission Creation Settings',
            components: [
                {
                    identifier: 'enabled',
                    name: 'Toggle Enabled',
                    type: 'inline'
                },
                {
                    identifier: 'section-required-info',
                    name: 'Required Section Info',
                    type: 'inline'
                },
                {
                    identifier: 'maxDistance',
                    name: 'Maximum Distance',
                    type: 'inline'
                },
                {
                    identifier: 'maxTimeDifference',
                    name: 'Maximum time difference',
                    type: 'inline'
                },
                {
                    identifier: 'section-optional-info',
                    name: 'Optional Section Info',
                    type: 'inline'
                },
                {
                    identifier: 'useCollectionSourceOwner',
                    name: 'Use Collection Source Owner',
                    type: 'inline'
                },
                {
                    identifier: 'remotePilotId',
                    name: 'Pilot',
                    type: 'inline'
                },
                {
                    identifier: 'operationTypeId',
                    name: 'Mission Type',
                    type: 'inline'
                },
                {
                    identifier: 'missionWorkflowId',
                    name: 'Workflow',
                    type: 'inline'
                },
                {
                    identifier: 'workgroupId',
                    name: 'Workgroup',
                    type: 'inline'
                },
                {
                    identifier: 'requireRpa',
                    name: 'Log Requires an RPA',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'bulk-upload-selection',
            analyticsIdentifier: 'bulk-upload-selection',
            name: 'Bulk Uploader File Select',
            components: []
        },
        {
            identifier: 'bulk-upload-review',
            analyticsIdentifier: 'bulk-upload-review',
            name: 'Bulk Uploader File Review',
            components: []
        },
        {
            identifier: 'flight-conformance-setup',
            analyticsIdentifier: 'flight-conformance-setup',
            name: 'Flight Conformance Setup',
            components: [
                {
                    identifier: 'flight-conformance-overview',
                    name: 'Flight Conformance Overview',
                    type: 'inline'
                },
                {
                    identifier: 'flight-conformance-default',
                    name: 'Default Flight Conformance Policy',
                    type: 'inline'
                },
                {
                    identifier: 'flight-conformance-authority-type',
                    name: 'Authority Type Flight Conformance Policy',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'flight-conformance-policy',
            analyticsIdentifier: null,
            name: 'Flight Conformance Policy',
            components: [
                {
                    identifier: 'FLIGHT_AREA',
                    name: 'Flight Area Rule Type Description',
                    type: 'inline'
                },
                {
                    identifier: 'MAXIMUM_HEIGHT',
                    name: 'Max. Height Rule Type Description',
                    type: 'inline'
                },
                {
                    identifier: 'VISUAL_DISTANCE',
                    name: 'Max. Visual Distance Rule Type Description',
                    type: 'inline'
                },
                {
                    identifier: 'LANDING_BATTERY_LEVEL',
                    name: 'Landing Battery Level Rule Type Description',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'resource-maintenance',
            analyticsIdentifier: 'resource-maintenance',
            name: 'Resource Maintenance',
            components: [
                {
                    identifier: 'screen',
                    name: 'Overview of the whole screen',
                    type: 'inline'
                },
                {
                    identifier: 'configuration',
                    name: 'The configuration tab',
                    type: 'inline'
                },
                {
                    identifier: 'rpa-schedules',
                    name: 'The RPA Schedules tab',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'maintenance-schedule',
            analyticsIdentifier: 'maintenance-schedule',
            name: 'Maintenance Schedule',
            components: [
                {
                    identifier: 'screen',
                    name: 'Overview of the whole screen',
                    type: 'inline'
                },
                {
                    identifier: 'schedule-tasks',
                    name: 'The tasks that need to be performed',
                    type: 'inline'
                },
                {
                    identifier: 'schedule-triggers',
                    name: 'The trigger configuration',
                    type: 'inline'
                },
                {
                    identifier: 'schedule-triggers-type',
                    name: 'The trigger configuration',
                    type: 'tooltip'
                },
                {
                    identifier: 'schedule-triggers-time',
                    name: 'Time in service trigger point',
                    type: 'tooltip'
                },
                {
                    identifier: 'schedule-triggers-cycles',
                    name: 'Flights/cycles trigger point',
                    type: 'tooltip'
                },
                {
                    identifier: 'schedule-triggers-calendar',
                    name: 'Calendar/days trigger point',
                    type: 'tooltip'
                },
                {
                    identifier: 'schedule-triggers-action',
                    name: 'The action triggered',
                    type: 'tooltip'
                }
            ]
        },
        {
            identifier: 'form-catalogue',
            analyticsIdentifier: 'form-catalogue',
            name: 'Form Catalogue',
            components: [
                {
                    identifier: 'overview',
                    name: 'Overview',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'organisation-admin-form-list',
            analyticsIdentifier: 'organisation-admin-form-list',
            name: 'Organisation Admin Documentation Templates',
            components: [
                {
                    identifier: 'overview',
                    name: 'Documentation Templates',
                    type: 'inline'
                },
                {
                    identifier: 'entity-list-catalogue',
                    name: 'FlyFreely Form Library',
                    type: 'tooltip'
                }
            ]
        },
        {
            identifier: 'currency-configuration',
            analyticsIdentifier: 'currency-configuration',
            name: 'Currency Configuration',
            components: [
                {
                    identifier: 'screen',
                    name: 'Screen',
                    type: 'inline'
                },
                {
                    identifier: 'currency-conditions-column',
                    name: 'Currency Conditions Column',
                    type: 'tooltip'
                },
                {
                    identifier: 'eligible-flight-conditions-column',
                    name: 'Eligible Flight Conditions',
                    type: 'tooltip'
                },
                {
                    identifier: 'currency-requirements-column',
                    name: 'Currency Requirements',
                    type: 'tooltip'
                }
            ]
        },
        {
            identifier: 'dashboard',
            analyticsIdentifier: 'dashboard',
            name: 'Dashboard',
            components: [
                {
                    name: 'Mission Widget Empty (editable)',
                    identifier: 'missions-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Mission Widget Empty',
                    identifier: 'missions-empty',
                    type: 'empty'
                },
                {
                    name: 'RPA Widget Empty (editable)',
                    identifier: 'rpa-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'RPA Widget Empty',
                    identifier: 'rpa-empty',
                    type: 'empty'
                },
                {
                    name: 'Battery Widget Empty (editable)',
                    identifier: 'battery-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Battery Widget Empty',
                    identifier: 'battery-empty',
                    type: 'empty'
                },
                {
                    name: 'Battery Set Widget Empty (editable)',
                    identifier: 'battery-set-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Battery Set Widget Empty',
                    identifier: 'battery-set-empty',
                    type: 'empty'
                },
                {
                    name: 'Maintenance Widget Empty (editable)',
                    identifier: 'maintenance-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Maintenance Widget Empty',
                    identifier: 'maintenance-empty',
                    type: 'empty'
                },
                {
                    name: 'Locations Widget Empty (editable)',
                    identifier: 'locations-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Locations Widget Empty',
                    identifier: 'locations-empty',
                    type: 'empty'
                },
                {
                    name: 'Libraries Widget Empty (editable)',
                    identifier: 'libraries-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Libraries Widget Empty',
                    identifier: 'libraries-empty',
                    type: 'empty'
                },
                {
                    name: 'Organisation Links Widget Empty (editable)',
                    identifier: 'organisation-links-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Organisation Links Widget Empty',
                    identifier: 'organisation-links-empty',
                    type: 'empty'
                },
                {
                    name: 'Equipment Empty (editable)',
                    identifier: 'equipment-empty-editable',
                    type: 'empty'
                },
                {
                    name: 'Equipment Widget Empty',
                    identifier: 'equipment-empty',
                    type: 'empty'
                },
                {
                    name: 'Incident Reports Widget Empty',
                    identifier: 'incident-reports-empty',
                    type: 'empty'
                }
            ]
        },
        {
            identifier: 'mission-view',
            analyticsIdentifier: 'mission-view',
            name: 'Mission View',
            components: [
                {
                    type: 'tooltip',
                    identifier: 'display-table-and-map',
                    name: 'Display Table and Map'
                },
                {
                    type: 'tooltip',
                    identifier: 'display-table',
                    name: 'Display Table'
                },
                {
                    type: 'tooltip',
                    identifier: 'display-map',
                    name: 'Display Map'
                },
                {
                    type: 'tooltip',
                    identifier: 'mission-view-add',
                    name: 'Create Mission'
                },
                {
                    type: 'tooltip',
                    identifier: '"mission-view-flight-logs',
                    name: 'Flight Logs'
                },
                {
                    type: 'tooltip',
                    identifier: 'mission-view-exports',
                    name: 'Mission Export'
                },
                {
                    type: 'tooltip',
                    identifier: 'mission-view-calendar',
                    name: 'Mission Calendar'
                },
                {
                    type: 'empty',
                    identifier: 'missions-empty-editable',
                    name: 'Mission Table Empty (editable)'
                },
                {
                    type: 'empty',
                    identifier: 'missions-empty',
                    name: 'Mission Table Empty'
                }
            ]
        },
        {
            analyticsIdentifier: 'workgroups-setup',
            identifier: 'workgroups-setup',
            name: 'Workgroups Setup',
            components: [
                {
                    type: 'inline',
                    identifier: 'screen',
                    name: 'Workgroups Setup Screen'
                },
                {
                    identifier: 'workgroups-empty',
                    name: 'Workgroups Empty',
                    type: 'empty'
                }
            ]
        },
        {
            analyticsIdentifier: 'airworthiness-notices',
            identifier: 'airworthiness-notices',
            name: 'Airworthiness Notices',
            components: [
                {
                    type: 'inline',
                    identifier: 'screen',
                    name: 'Screen'
                },
                {
                    type: 'empty',
                    identifier: 'no-maintenance-logs',
                    name: 'No maintenance logs'
                },
                {
                    type: 'empty',
                    identifier: 'no-notice-selected',
                    name: 'No notice selected'
                }
            ]
        },
        {
            analyticsIdentifier: 'airworthiness-notices-link-maintenance',
            identifier: 'airworthiness-notices-link-maintenance',
            name: 'Airworthiness Notices Link Maintenance',
            components: [
                {
                    type: 'inline',
                    identifier: 'screen',
                    name: 'Screen'
                },
                {
                    type: 'empty',
                    identifier: 'no-maintenance-logs',
                    name: 'No maintenance logs available'
                }
            ]
        },
        {
            analyticsIdentifier: 'general-task-add',
            identifier: 'general-task-add',
            name: 'General Task Add Dialogue',
            components: [
                {
                    identifier: 'taskName',
                    name: 'Task Name',
                    type: 'inline'
                },
                {
                    identifier: 'taskDetails',
                    name: 'Task Details',
                    type: 'inline'
                },
                {
                    identifier: 'taskPriority',
                    name: 'Task Priority',
                    type: 'inline'
                },
                {
                    identifier: 'taskDueDate',
                    name: 'Due Date',
                    type: 'inline'
                },
                {
                    identifier: 'taskAssignee',
                    name: 'Assignee',
                    type: 'inline'
                },
                {
                    identifier: 'taskReporter',
                    name: 'Task Reporter',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'task-view',
            analyticsIdentifier: 'task-view',
            name: 'Task View',
            components: [
                {
                    identifier: 'task-details',
                    name: 'Task Details',
                    type: 'inline'
                }
            ]
        },
        {
            identifier: 'task-edit',
            analyticsIdentifier: 'task-edit',
            name: 'Task Edit',
            components: [
                {
                    identifier: 'taskName',
                    name: 'Task Name',
                    type: 'inline'
                },
                {
                    identifier: 'taskDetails',
                    name: 'Task Details',
                    type: 'inline'
                },
                {
                    identifier: 'taskPriority',
                    name: 'Task Priority',
                    type: 'inline'
                },
                {
                    identifier: 'taskDueDate',
                    name: 'Due Date',
                    type: 'inline'
                },
                {
                    identifier: 'taskAssignee',
                    name: 'Assignee',
                    type: 'inline'
                },
                {
                    identifier: 'taskStatus',
                    name: 'Status',
                    type: 'inline'
                }
            ]
        },
        {
            analyticsIdentifier: 'nominated-personnel',
            identifier: 'nominated-personnel',
            name: 'Nominated Personnel',
            components: [
                { identifier: 'screen', type: 'inline', name: 'Screen' },
                {
                    identifier: 'scope-col',
                    type: 'tooltip',
                    name: 'Scope column'
                },
                {
                    identifier: 'person-col',
                    type: 'tooltip',
                    name: 'Person column'
                },
                {
                    identifier: 'start-date-col',
                    type: 'tooltip',
                    name: 'Start Date column'
                },
                {
                    identifier: 'end-date-col',
                    type: 'tooltip',
                    name: 'End Date column'
                },
                {
                    identifier: 'approve-own-missions-col',
                    type: 'tooltip',
                    name: 'Approve Own Missions column'
                },
                {
                    identifier: 'manage-missions-col',
                    type: 'tooltip',
                    name: 'Manage Missions column'
                }
            ]
        },
        {
            identifier: 'workspace',
            analyticsIdentifier: 'workspace',
            name: 'Workspace Dashboards',
            components: [
                {
                    identifier: 'manage-dashboard',
                    name: 'Manage Dashboard',
                    type: 'inline'
                },
                {
                    identifier: 'missions-dashboard',
                    name: 'Missions Dashboard',
                    type: 'inline'
                },
                {
                    identifier: 'tasks-dashboard',
                    name: 'Tasks Dashboard',
                    type: 'inline'
                },
                {
                    identifier: 'reporting-dashboard',
                    name: 'Reporting Dashboard',
                    type: 'inline'
                }
            ]
        }
    ];

    findComponent(screen: string, component: string) {
        const screenObject = this.screenTree.find(s => s.identifier === screen);
        if (screenObject == null) {
            return null;
        }

        return screenObject.components.find(c => c.identifier === component);
    }
}
