import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild
} from '@angular/core';
import { WidgetStatus } from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    OutstandingTasksViewSectionsSelection,
    OutstandingTasksViewService,
    WidgetRegistrationWithSection
} from '../../outstanding-tasks-view.service';
import { AuthorityTaskWidgetsDataService } from '../../widgets/organisation/authority-data.service';
import { MaintenanceTaskWidgetDataService } from '../../widgets/resources/maintenance-data.service';

const isChrome =
    // @ts-ignore - window type does exist
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

@Component({
    selector: 'flyfreely-portal-ui-outstanding-tasks-view',
    templateUrl: './outstanding-tasks-view.component.html',
    styleUrls: ['./outstanding-tasks-view.component.scss'],
    providers: [
        AuthorityTaskWidgetsDataService,
        MaintenanceTaskWidgetDataService
    ],
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()]
})
export class OutstandingTasksViewComponent {
    @ViewChild('generalTasksSection') generalTasksSection: ElementRef;
    @ViewChild('operationsSection') operationsSection: ElementRef;
    @ViewChild('resourcesSection') resourcesSection: ElementRef;
    @ViewChild('organisationSection') organisationSection: ElementRef;

    scrollToSections: { [key: string]: ElementRef };

    selectedSections: OutstandingTasksViewSectionsSelection = {
        generalTasks: false,
        operations: false,
        resources: false,
        organisation: false
    };

    generalTasksWidgets: WidgetRegistrationWithSection[];
    operationsWidgets: WidgetRegistrationWithSection[];
    resourcesWidgets: WidgetRegistrationWithSection[];
    organisationWidgets: WidgetRegistrationWithSection[];

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private outstandingViewService: OutstandingTasksViewService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.outstandingViewService.scrollToSection$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(scrollTo => {
                this.scrollToHeading(scrollTo);
            });

        this.outstandingViewService.availableTestWidgets$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(widgets => {
                this.updateShowingWidgets(widgets);
            });

        this.outstandingViewService.selectedSections$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sections => {
                this.selectedSections = sections;
                this.changeDetector.detectChanges();
                // rebuild the DOM refs for scrollTo
                this.buildScrollToReferences();
            });
    }

    ngAfterViewInit() {
        this.buildScrollToReferences();
    }

    private updateShowingWidgets(widgets: WidgetStatus) {
        [
            this.generalTasksWidgets,
            this.operationsWidgets,
            this.resourcesWidgets,
            this.organisationWidgets
        ] = this.outstandingViewService.splitWidgetsBySection(widgets);
    }

    /**
     * Builds DOM references for the scrollTo behaviour
     */
    private buildScrollToReferences() {
        this.scrollToSections = {
            generalTasksSection: this.generalTasksSection,
            operationsSection: this.operationsSection,
            resourcesSection: this.resourcesSection,
            organisationSection: this.organisationSection
        };
    }

    scrollToHeading(scrollTo: string) {
        if (scrollTo && this.scrollToSections[scrollTo] != null) {
            if (isChrome) {
                this.scrollToSections[
                    scrollTo
                ].nativeElement.scrollIntoViewIfNeeded({
                    behavior: 'smooth'
                });
            } else {
                this.scrollToSections[scrollTo].nativeElement.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
}
