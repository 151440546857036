import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    ApiKeyDto,
    ApiKeysService,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationService,
    PersonDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { ApiKeysDialoguesService } from 'libs/api-keys/src/lib/api-keys-dialogues.service';
import { CreateApiKeyFormService } from 'libs/api-keys/src/lib/create-api-key-form/create-api-key-form.service';
import {
    apiKeysTableColumns,
    apiKeysTableConfig
} from 'libs/api-keys/src/lib/helpers';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FormatDateTimePipe } from 'libs/ui/src/lib/pipes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'api-keys',
    templateUrl: './api-keys.component.html',
    styleUrls: ['./api-keys.component.scss'],
    providers: [WorkTracker, CreateApiKeyFormService]
})
export class ApiKeysComponent implements OnInit {
    @Input() currentUser: PersonDto;

    currentKeys: ApiKeyDto[];

    apiForm: FormGroup;

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;

    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private workTracker: WorkTracker,
        private apiKeysService: ApiKeysService,
        private apiKeysFormService: CreateApiKeyFormService,
        private apiKeysDialoguesService: ApiKeysDialoguesService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private organisationService: OrganisationService,
        formatDateTimePipe: FormatDateTimePipe
    ) {
        this.tableConfig = apiKeysTableConfig(apiKey => this.revokeKey(apiKey));
        this.availableColumns = apiKeysTableColumns(v =>
            formatDateTimePipe.transform(v)
        );
    }

    ngOnInit(): void {
        this.apiForm = this.apiKeysFormService.apiForm;
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.refreshOrganisations();
        this.refreshKeys();
    }

    refreshOrganisations() {
        this.organisationService
            .findOrganisationsForPerson(this.currentUser.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.apiKeysFormService.organisations = result.filter(org =>
                        org.featureFlags.includes('apiKeys')
                    );
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing user's organisations: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    refreshKeys() {
        this.apiKeysService
            .findKeys()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.currentKeys = result.filter(
                        k => k.revocationTime == null
                    );
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing existing API keys: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    revokeKey(key: { name: string; id: string }) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Delete Key',
                `Do you wish to delete the API key called ${key.name}? This can not be undone.`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => {
                this.apiKeysService
                    .revokeKey(key.id)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(
                        result => {
                            const reducedKeys = this.currentKeys.filter(
                                k => k.id !== key.id
                            );
                            this.currentKeys = reducedKeys;
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while revoking Key: ${error.message}`
                            );
                        }
                    )
                    .add(this.workTracker.createTracker());
            }, DO_NOTHING);
    }

    createKey(value: any) {
        const createCommand = {
            allOrganisations: value.allOrganisations,
            allPermissions: value.allPermissions,
            name: value.name,
            organisationIds: this.apiKeysFormService.mapOrganisations(
                value.formOrganisations
            ),
            permissions: this.apiKeysFormService.mapPermissions(
                value.formPermissions
            )
        };

        this.apiKeysService
            .createKey(createCommand)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.apiKeysDialoguesService.showKeyConfirmationDialogue(
                        result.id,
                        result.apiKey
                    );
                    this.refreshKeys();
                    this.apiKeysFormService.reset();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while creating Key: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }
}
