import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    BatteryDto,
    BatteryService,
    BatteryTypeDto,
    BatteryTypeService,
    BatteryWithBatteryType,
    CurrentPersonDto,
    PersonsOrganisationDto,
    PreferencesService,
    UserService,
    WorkTracker,
    compareDates,
    hasAnyPermission,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatResourceStatusPipe } from '@flyfreely-portal-ui/resource-ui';
import { FormatBooleanPipe, FormatDatePipe } from '@flyfreely-portal-ui/ui';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { BatteryDialoguesService } from 'libs/batteries/src/lib/battery-dialogues.service';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';
import { dateRange } from 'libs/flyfreely-table/src/lib/search';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import * as moment from 'moment';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { WidgetsPreferencesService } from '../widget-preferences.service';

const BATTERY_IDENTIFIER = 'batteryList';
@Component({
    selector: 'battery-list-widget',
    templateUrl: './battery-list-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class BatteryListWidgetComponent implements OnInit, OnDestroy, Widget {
    organisation: PersonsOrganisationDto;
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    batteries: BatteryWithBatteryType[];

    canAddBattery: boolean;
    userPreferences: TableSetupUserPreferences;
    isSystemAdmin: boolean;
    canDownloadBatteries = false;

    public working: boolean;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    private showDummyData$: Observable<boolean>;
    private batteries$ = new Subject<BatteryWithBatteryType[]>();
    private batteryTypes$ = new Subject<BatteryTypeDto[]>();

    constructor(
        private batteryService: BatteryService,
        private batteryDialogues: BatteryDialoguesService,
        private userService: UserService,
        private batteryTypeService: BatteryTypeService,
        private workspaceStateService: WorkspaceStateService,
        private preferencesService: PreferencesService,
        formatBooleanPipe: FormatBooleanPipe,
        resourceStatusPipe: FormatResourceStatusPipe,
        formatDatePipe: FormatDatePipe,
        private preferences: WidgetsPreferencesService
    ) {
        this.showDummyData$ = preferencesService.showDummyData$.pipe(
            takeUntil(this.ngUnsubscribe$)
        );

        this.availableColumns = [
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'manufacturerSerialNumber',
                name: 'Serial Number',
                searchable: true,
                selectable: true,
                defaultSelection: false,
                linkFunction: battery => this.showBattery(battery)
            },
            {
                value: 'batteryType.make',
                name: 'Battery Type Make',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'batteryType.model',
                name: 'Battery Type Model',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'batteryType.intelligent',
                name: 'Intelligent Battery',
                searchable: 'selection',
                searchOptions: [
                    { name: 'Yes', value: true },
                    { name: 'No', value: false }
                ],
                searchFunction:
                    (selection: boolean) => (intelligent: boolean) =>
                        intelligent === selection,
                selectable: false,
                defaultSelection: false,
                formatterFunction: (text: boolean) =>
                    formatBooleanPipe.transform(text)
            },
            {
                value: 'batteryType.ratedCapacity',
                name: 'Rated Capacity (mAh)',
                searchable: false,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'batteryType.ratedCapacitymWh',
                name: 'Rated Capacity (Wh)',
                searchable: false,
                selectable: true,
                defaultSelection: false,
                formatterFunction: num => (num / 1000).toFixed(2)
            },
            {
                value: 'convertedPurchaseDate',
                key: 'purchaseDate',
                name: 'Purchase Date',
                searchable: 'daterange',
                selectable: true,
                defaultSelection: false,
                formatterFunction: d => formatDatePipe.transform(d),
                searchFunction: dateRange,
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
            },
            {
                value: 'status',
                name: 'Status',
                searchable: 'selection',
                selectable: true,
                defaultSelection: true,
                searchOptions: this.batteryService.getStatuses(),
                defaultSearch: 'SERVICEABLE',
                formatterFunction: s => resourceStatusPipe.transform(s)
            }
        ];
        this.selectedColumns = null;

        this.columnSorting = null;

        this.tableSearch = null;

        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshPermissions();
                this.refreshBatteryTypes();
                this.refreshBatteries();
                this.loadPreferences();
            });
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        combineLatest([
            this.batteries$,
            this.showDummyData$,
            this.batteryTypes$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([batteries, showDummy, batteryTypes]) => {
                const batteryTypeLookup = batteryTypes.reduce(toLookup, {});

                this.batteries = batteries
                    .filter(m => showDummy || !m.isDummy)
                    .map(b => ({
                        ...b,
                        batteryType: batteryTypeLookup[b.batteryTypeId]
                    }));
            });

        this.batteryService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshBatteries();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        this.canAddBattery = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.BATTERY_ADD
        );

        this.canDownloadBatteries = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.BATTERY_LIST
        );

        this.isSystemAdmin =
            this.userService.getCurrentUser().type ===
            CurrentPersonDto.Type.SYSTEM_ADMIN;
    }

    private refreshBatteryTypes() {
        const doneWorking = this.workTracker.createTracker();
        this.batteryTypeService
            .find(this.organisation.id)
            .toPromise()
            .then(batteryTypes => {
                this.batteryTypes$.next(batteryTypes);
                doneWorking();
            });
    }

    private loadPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferences
            .findPreferences('batteryListWidgetTable', this.organisation.id)
            .pipe(
                switchMap(preferences => {
                    if (preferences == null) {
                        return this.preferences
                            .findLegacyPreferences(
                                'batteryListWidgetColumns',
                                this.organisation.id
                            )
                            .pipe(
                                map(pref => ({
                                    selectedColumns: pref,
                                    columnSorting: null,
                                    tableSearch: null,
                                    itemLimit: this.tableConfig.limit
                                })),
                                catchError(() => {
                                    this.handleDefaultPreferences();
                                    return of(null);
                                })
                            );
                    } else {
                        return of(preferences);
                    }
                }),
                catchError(() => {
                    this.selectedColumns = findDefaultColumnSelection(
                        this.availableColumns
                    );
                    return EMPTY;
                })
            )
            .subscribe(preferences => {
                this.applyPreferences(preferences);
            })
            .add(this.workTracker.createTracker);
    }

    private handleDefaultPreferences() {
        this.applyPreferences({});
    }

    private applyPreferences(preferences: TableSetupUserPreferences) {
        this.userPreferences = this.preferences.applyPreferences(
            preferences,
            this.availableColumns,
            this.tableConfig
        );

        this.selectedColumns = this.userPreferences.selectedColumns;
        this.columnSorting = this.userPreferences.columnSorting;
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit = this.userPreferences.itemLimit;

        this.preferences.updateDateRange(this.tableSearch, 'purchaseDate');
    }

    private refreshBatteries() {
        this.batteries = null;
        if (!this.organisation) {
            return;
        }
        const doneWorking = this.workTracker.createTracker();
        this.batteryService
            .findBatteries(this.organisation.id)
            .toPromise()
            .then((results: BatteryDto[]) => {
                this.batteries$.next(
                    results.map(r => ({
                        ...r,
                        convertedPurchaseDate: r.purchaseDate
                            ? moment(r.purchaseDate)
                            : null
                    }))
                );
                doneWorking();
            });
    }

    newBattery() {
        this.batteryDialogues.showAddBatteryDialogue(this.organisation.id);
    }

    showBattery(battery: BatteryDto) {
        this.batteryDialogues.showBatteryDetailsDialogue(
            battery,
            this.organisation
        );
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'batteryListWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    downloadBatteryList() {
        this.batteryDialogues.showBatteryListDownloadDialogue(
            this.organisation,
            this.batteries
        );
    }
}

export const batteryListWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: BATTERY_IDENTIFIER,
    component: BatteryListWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.BATTERY_LIST
        )
};
