import { Component } from '@angular/core';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import FileSaver from 'file-saver';
import { Subject, takeUntil } from 'rxjs';
import { AccountAirspaceDataService } from './account-airspace-data.service';

@Component({
    selector: 'account-airspace-data',
    templateUrl: './account-airspace-data.component.html',
    providers: [AccountAirspaceDataService]
})
export class AccountAirspaceDataComponent {
    disclaimer =
        'This download contains the operational information for your Authorisations. For a full export please contact <a href="mailto: support@flyfreely.io">support@flyfreely.io</a>';

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private accountAirspaceDataService: AccountAirspaceDataService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    downloadAllUsersAuthorisations() {
        this.accountAirspaceDataService
            .downloadAllUsersAuthorisations()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorisations => {
                FileSaver.saveAs(authorisations, `LAANC_Authorisations.csv`);
            })
            .add(this.workTracker.createTracker());
    }
}
