import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { CommentUpdate, Comment, CommentDelete } from '../interfaces';
import {
    CurrentPersonDto,
    LoggedInUser,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RichTextEditorComponent } from 'libs/rich-text-editor/src/lib/rich-text-editor/rich-text-editor.component';

@Component({
    selector: 'ui-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CommentsComponent),
            multi: true
        }
    ]
})
export class CommentsComponent implements OnInit, ControlValueAccessor {
    @ViewChild(RichTextEditorComponent)
    richTextEditor!: RichTextEditorComponent;
    @Input() allowEdit = true;
    @Input() hasSave = true;
    @Input() organisationId!: number;
    @Input() comments: Comment[] = [];
    @Output() updateComment = new EventEmitter<CommentUpdate>();
    @Output() createComment = new EventEmitter<Object>();
    @Output() deleteComment = new EventEmitter<CommentDelete>();
    currentUser$: Observable<CurrentPersonDto>;

    private onChange: (value: Comment[]) => void = () => {};
    private onTouched: () => void = () => {};

    constructor(private userService: UserService) {
        this.currentUser$ = this.userService.userChange$.pipe(
            // @ts-ignore
            filter<LoggedInUser>(c => c.type === UserStatus.LOGGED_IN),
            map(changes => changes.currentUser!)
        );
    }

    ngOnInit(): void {}

    writeValue(obj: Comment[]): void {
        if (obj) {
            this.comments = obj;
        } else {
            this.comments = [];
        }
    }

    registerOnChange(fn: (value: Comment[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {}

    onCreateComment(commentContent: Object) {
        this.createComment.emit(commentContent);
    }

    onUpdateComment($event: CommentUpdate) {
        this.updateComment.emit($event);
    }

    onDeleteComment($event: CommentDelete) {
        this.deleteComment.emit($event);
    }
}
