import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsComponent } from './comments/comments.component';
import { CommentComponent } from './comment/comment.component';
import { SharedUiModule } from '@flyfreely-portal-ui/ui';
import { RichTextEditorModule } from '@flyfreely-portal-ui/rich-text-editor';
import { AttachmentHandlerService } from 'libs/comments/src/lib/attachment-handler.service';

@NgModule({
    imports: [CommonModule, SharedUiModule, RichTextEditorModule.forChild()],
    declarations: [CommentsComponent, CommentComponent],
    exports: [CommentsComponent, CommentComponent],
    providers: [AttachmentHandlerService]
})
export class CommentsModule {}
