import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

declare global {
    interface Window {
        mixpanel?: any;
    }
}
@Injectable({ providedIn: 'root' })
export class Angulartics2Mixpanel {
    constructor(private angulartics2: Angulartics2) {
        this.angulartics2.setUsername.subscribe((x: string) =>
            this.setUsername(x)
        );
        this.angulartics2.setUserProperties.subscribe(x =>
            this.setUserProperties(x)
        );
        this.angulartics2.setUserPropertiesOnce.subscribe(x =>
            this.setUserPropertiesOnce(x)
        );
        this.angulartics2.setSuperProperties.subscribe(x =>
            this.setSuperProperties(x)
        );
        this.angulartics2.setSuperPropertiesOnce.subscribe(x =>
            this.setSuperPropertiesOnce(x)
        );
        this.angulartics2.setAlias.subscribe(x => this.setAlias(x));
    }

    startTracking(): void {
        this.angulartics2.pageTrack
            .pipe(this.angulartics2.filterDeveloperMode())
            .subscribe(x => this.pageTrack(x.path));
        this.angulartics2.eventTrack
            .pipe(this.angulartics2.filterDeveloperMode())
            .subscribe(x => this.eventTrack(x.action, x.properties));
    }

    pageTrack(path: string) {
        try {
            window.mixpanel.track('Page Viewed', { page: path });
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    eventTrack(action: string, properties: any) {
        try {
            window.mixpanel.track(action, properties);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    setUsername(userId: string | { userId: number | string }) {
        try {
            if (userId == null) {
                window.mixpanel.reset();
            } else if (typeof userId === 'object') {
                window.mixpanel.identify(userId.userId);
            } else {
                window.mixpanel.identify(userId);
            }
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    setUserProperties(properties: any) {
        const ignoreProperties = ['email', 'firstName', 'lastName'];
        try {
            const mixpanelProperties = Object.keys(properties)
                .filter(p => ignoreProperties.indexOf(p) === -1)
                .reduce((acc, p) => ({ ...acc, [p]: properties[p] }), {
                    $email: properties.email,
                    $first_name: properties?.firstName,
                    $last_name: properties?.lastName
                });
                window.mixpanel.people.set(mixpanelProperties);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    setUserPropertiesOnce(properties: any) {
        try {
            window.mixpanel.people.set_once(properties);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    setSuperProperties(properties: any) {
        try {
            window.mixpanel.register(properties);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    setSuperPropertiesOnce(properties: any) {
        try {
            window.mixpanel.register_once(properties);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    setAlias(alias: any) {
        try {
            window.mixpanel.alias(alias);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }
}
