import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
    Angulartics2GoogleGA4TagManager,
    Angulartics2Hubspot,
    Angulartics2Mixpanel,
    CopyService,
    FlyFreelyConstants,
    LogAnalytics,
    ReferralManagerService,
    UserAnalyticsService
} from '@flyfreely-portal-ui/flyfreely';

import * as setupSentry from '../../../setup-sentry';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Declare the known window variables our collaborators use.
 */
declare global {
    interface Window {
        mixpanel?: any;
        posthog?: any;
        _hsq?: any;
    }
}

if (environment.production) {
    enableProdMode();
}

// Initiate the Sentry config
setupSentry.initialiseSentry();


platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(
        platformRef => {
            // Ensure Angular destroys itself on hot reloads.
            if (window['ngRef']) {
                window['ngRef'].destroy();
            }
            window['ngRef'] = platformRef;

            // Initialise Angulartics
            platformRef.injector
                .get(Angulartics2GoogleGA4TagManager)
                .startTracking();
            if (window._hsq) {
                platformRef.injector.get(Angulartics2Hubspot).startTracking();
            }

            if (window.mixpanel) {
                platformRef.injector.get(Angulartics2Mixpanel).startTracking();
            }

            if (platformRef.injector.get(FlyFreelyConstants).LOG_ANALYTICS) {
                platformRef.injector.get(LogAnalytics).startTracking();
            }

            platformRef.injector.get(CopyService).cacheCopy();
            platformRef.injector.get(ReferralManagerService);
            platformRef.injector.get(UserAnalyticsService);
        },
        error => console.log('Error starting application', error)
    );
