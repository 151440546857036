import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DO_NOTHING,
    FEATURE_DEMO_ORGANISATION,
    FEATURE_DYNAMIC_REPORTING,
    FEATURE_OUTSTANDING_TASKS_VIEW,
    hasAnyPermission,
    hasFeatureFlag,
    PersonsOrganisationDto,
    PreferencesService,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

const demoUserPreferences = 'demoDashboardHelpViewed';

@Component({
    selector: 'section-selector',
    templateUrl: './section-selector.component.html',
    styleUrls: ['./section-selector.component.scss']
})
export class SectionSelectorComponent implements OnDestroy {
    hideExploreBtn = true;
    modalClose = false;
    hasDynamicReporting$: Observable<boolean>;
    hasOutstandingTasts$: Observable<boolean>;
    canAdminOrganisation$: Observable<boolean>;
    helpComponentIdentifier$: Observable<string | null>;
    isDemoOrganisation$: Observable<boolean>;
    isInPreference$: Observable<boolean>;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private sharedService: WorkspaceStateService,
        activatedRoute: ActivatedRoute,
        private preferencesService: PreferencesService,
        private router: Router
    ) {
        this.sharedService.currentLoadedOrganisation$;

        this.hasDynamicReporting$ =
            this.sharedService.currentOrganisation$.pipe(
                map(
                    o =>
                        o.type === 'organisation_loaded' &&
                        hasFeatureFlag(
                            o.organisation,
                            FEATURE_DYNAMIC_REPORTING
                        )
                )
            );

        this.hasOutstandingTasts$ =
            this.sharedService.currentOrganisation$.pipe(
                map(
                    o =>
                        o.type === 'organisation_loaded' &&
                        hasFeatureFlag(
                            o.organisation,
                            FEATURE_OUTSTANDING_TASKS_VIEW
                        )
                )
            );

        this.canAdminOrganisation$ =
            this.sharedService.currentOrganisation$.pipe(
                map(
                    o =>
                        o.type === 'organisation_loaded' &&
                        !o.organisation.personalOrganisation &&
                        (hasAnyPermission(o.organisation, [
                            PersonsOrganisationDto.Permissions
                                .ORGANISATION_EDIT,
                            PersonsOrganisationDto.Permissions
                                .FLIGHT_OPERATIONS_CONFIGURATION_VIEW
                        ]) ||
                            o.organisation.owner)
                )
            );

        this.helpComponentIdentifier$ = activatedRoute.data.pipe(
            map(d => d.enhancedHelpComponentIdentifier ?? null)
        );

        this.isDemoOrganisation$ = this.sharedService.currentOrganisation$.pipe(
            map(
                o =>
                    o.type === 'organisation_loaded' &&
                    hasFeatureFlag(o.organisation, FEATURE_DEMO_ORGANISATION)
            )
        );

        this.isInPreference$ = this.helpComponentIdentifier$.pipe(
            switchMap(componentName =>
                this.preferencesService
                    .findPreferences(demoUserPreferences, null)
                    .pipe(
                        map(preferences => {
                            const shouldHide =
                                preferences != null &&
                                preferences[componentName] != null;
                            this.hideExploreBtn = shouldHide;
                            return shouldHide;
                        }),
                        startWith(true)
                    )
            ),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    updatePreferences() {
        this.helpComponentIdentifier$
            .pipe(
                take(1),
                switchMap(componentName => {
                    return this.preferencesService
                        .findPreferences(demoUserPreferences, null)
                        .pipe(
                            switchMap(existingPreferences => {
                                const currentPreferences =
                                    existingPreferences || {};
                                const updatedPreferences = {
                                    ...currentPreferences,
                                    [componentName]: toTimestamp(new Date())
                                };
                                return this.preferencesService.updatePreferences(
                                    demoUserPreferences,
                                    null,
                                    updatedPreferences
                                );
                            })
                        );
                }),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(DO_NOTHING);
    }

    onModalClosed() {
        this.modalClose = true;
        setTimeout(() => {
            this.hideExploreBtn = true;
        }, 500);
    }

    goToOnboarding() {
        this.router.navigate(['onboarding']);
    }
}
