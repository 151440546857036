import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatMTOW' })
export class FormatMTOWPipe implements PipeTransform {
    transform(weight: number, ...args: any[]) {
        if (weight == null) {
            return `Unlimited`;
        }

        if (weight < 1000) {
            return `${weight.toPrecision(3)}g`;
        }
        const newWeight = weight / 1000;
        return `${newWeight.toPrecision(3)}kg`;
    }
}
