<div class="modal-header no-icon">
    <h3 class="modal-title">{{ title }} - {{ form.entity.formName }}</h3>
    <button type="button" (click)="cancel()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="modal-body">
        <div [innerHtml]="form.entity.description"></div>
        <formly-form
            [form]="formGroup"
            [fields]="fields"
            [options]="options"
            [model]="initialData"
        ></formly-form>
    </div>

    <div class="modal-footer">
        <button class="btn" type="button" [disabled]="working" (click)="save()">
            Save
        </button>
        <button
            class="btn"
            type="button"
            [disabled]="working"
            (click)="saveAndClose()"
        >
            Save and Close
        </button>
        <button
            class="btn btn-primary"
            type="submit"
            [disabled]="working || formGroup.invalid"
        >
            Complete
        </button>
    </div>
</form>
