import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatEmptyTitle'
})
export class FormatEmptyTitlePipe implements PipeTransform {
    transform(value: unknown): string {
        const draftHtml = '<i>(Draft)</i>';
        return (value === '' || !value ? draftHtml : value) as string;
    }
}
