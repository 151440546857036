import { Pipe, PipeTransform } from '@angular/core';
import { SortieDto } from '@flyfreely-portal-ui/flyfreely';
import { utc } from 'moment';
import { AbstractControl } from '@angular/forms';

function format(startTime: string, endTime: string) {
    if (!startTime || !endTime) {
        return null;
    }
    return utc(endTime).diff(utc(startTime)) / 1000;
}

@Pipe({ name: 'calculateSortieDuration' })
export class CalculateSortieDurationPipe implements PipeTransform {
    transform(sortie: SortieDto | AbstractControl) {
        if (sortie == null) {
            return null;
        }
        if (sortie instanceof AbstractControl) {
            return format(sortie.value.startTime, sortie.value.endTime);
        }
        return utc(sortie.endTime).diff(utc(sortie.startTime)) / 1000;
    }
}
