import { Component, Input } from '@angular/core';
import {
    AutomaticMissionConfigurationDto,
    FlightLogCollectionService,
    FlightLogCollectionSourceDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    ResultDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FlightLogSettingsService } from './flight-log-settings.service';

@Component({
    selector: 'automatic-mission-assignment-settings',
    template: `
        <button
            type="button"
            class="btn btn-tertiary"
            (click)="showSettings()"
            [disabled]="working"
            tooltip="Adjust auto mission creation settings"
            placement="top"
        >
            <span class="fal fa-cog"></span>
        </button>
    `
})
export class AutomaticMissionCreationSettingsComponent {
    @Input() entry: FlightLogCollectionSourceDto;
    working: boolean;

    currentConfig: AutomaticMissionConfigurationDto;

    private workTracker = new WorkTracker();
    ngUnsubscribe$ = new Subject<void>();
    constructor(
        private flightLogSettingsService: FlightLogSettingsService,
        private flightLogCollectionService: FlightLogCollectionService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshFlightLogMissionConfiguration();
        this.flightLogCollectionService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshFlightLogMissionConfiguration());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshFlightLogMissionConfiguration() {
        this.flightLogCollectionService
            .findAutomaticMissionConfiguration(this.entry.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: configs => (this.currentConfig = configs)
            })
            .add(this.workTracker.createTracker());
    }

    showSettings() {
        this.flightLogSettingsService.showAutomaticMissionConfigurationSettings(
            this.entry,
            this.currentConfig
        );
    }

    createMissionsNow() {
        this.flightLogCollectionService
            .applyAutomaticMissionConfiguration(
                this.currentConfig.collectionSourceId
            )
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (result: ResultDto) => {
                    this.logging.success(
                        `Successfully created ${result.value} missions`
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while creating missions: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }
}
