/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.1-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Scheduled maintenance trigger status
 */
export interface TriggerActivationDto { 
    maintenanceScheduleId: number;
    type: TriggerActivationDto.Type;
    /**
     * What action was performed for this trigger
     */
    action: TriggerActivationDto.Action;
    /**
     * When the trigger occurred
     */
    activationTime: string;
    /**
     * When the trigger was resolved/cleared
     */
    resolutionTime?: string;
    /**
     * Current status of this trigger
     */
    status: TriggerActivationDto.Status;
}
export namespace TriggerActivationDto {
    export type Type = 'NONE' | 'UPCOMING' | 'DUE' | 'OVERDUE';
    export const Type = {
        NONE: 'NONE' as Type,
        UPCOMING: 'UPCOMING' as Type,
        DUE: 'DUE' as Type,
        OVERDUE: 'OVERDUE' as Type
    };
    export type Action = 'NONE' | 'NOTIFY' | 'REQUEST_MAINTENANCE' | 'TAKE_OUT_OF_SERVICE';
    export const Action = {
        NONE: 'NONE' as Action,
        NOTIFY: 'NOTIFY' as Action,
        REQUEST_MAINTENANCE: 'REQUEST_MAINTENANCE' as Action,
        TAKE_OUT_OF_SERVICE: 'TAKE_OUT_OF_SERVICE' as Action
    };
    export type Status = 'PENDING' | 'RESOLVED';
    export const Status = {
        PENDING: 'PENDING' as Status,
        RESOLVED: 'RESOLVED' as Status
    };
}



