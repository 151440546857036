import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    DownloadService,
    FlyFreelyLoggingService,
    NameId,
    OrganisationAuthorityService,
    PersonnelReportService,
    PersonService,
    PrintOption,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { PersonnelFieldCsv } from '../personnel-dialogues.service';

@Component({
    selector: 'personnel-reports-dialogue',
    templateUrl: './personnel-reports-dialogue.component.html'
})
export class PersonnelReportsDialogue implements OnInit, OnDestroy {
    @Input() organisationId: number;
    @Input() personnel: PersonnelFieldCsv[];

    authorityTypes: NameId[];
    selectedAuthorityTypes: number[];

    includeRoles = false;

    personnelField: FormlyFieldConfig[] = [
        {
            key: 'personnel',
            type: 'twelve-column',
            props: {
                label: 'Personnel',
                allowAnyKeyType: true
            },
            fieldArray: {
                fieldGroup: [
                    {
                        key: 'lastName',
                        type: 'input',
                        props: {
                            label: 'Last Name',
                            columnSpan: 12
                        }
                    },
                    {
                        key: 'firstName',
                        type: 'input',
                        props: {
                            label: 'First Name',
                            columnSpan: 12
                        }
                    },
                    {
                        key: 'email',
                        type: 'input',
                        props: {
                            label: 'Email',
                            columnSpan: 12
                        }
                    },
                    {
                        key: 'phoneNumber',
                        type: 'input',
                        props: {
                            label: 'Phone Number',
                            columnSpan: 12
                        }
                    },
                    {
                        key: 'roles',
                        type: 'input',
                        props: {
                            label: 'Roles',
                            columnSpan: 12
                        }
                    }
                ]
            }
        }
    ];

    printOptions: PrintOption[] = [];

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<PersonnelReportsDialogue>,
        private personnelReportService: PersonnelReportService,
        private personnelService: PersonService,
        private authorityService: OrganisationAuthorityService,
        private downloadService: DownloadService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshAuthorityTypes();
        this.refreshAvailableReports();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAuthorityTypes() {
        this.authorityService
            .findAvailableAuthorityTypes(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(types => {
                this.authorityTypes = types;
            })
            .add(this.workTracker.createTracker());
    }

    private refreshAvailableReports() {
        if (this.organisationId == null) {
            this.printOptions = [];
            return;
        }

        this.personnelReportService
            .findAvailableListTemplates(
                'PersonnelListReport',
                this.organisationId,
                this.organisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(reports => {
                this.printOptions = reports.map(r => ({
                    name: r.name,
                    description: r.description,
                    print: () => {
                        const doneWorking = this.workTracker.createTracker();
                        this.downloadService
                            .downloadFromUrl(
                                this.personnelReportService.getPdfListUrl(
                                    r.reportTemplateId,
                                    this.organisationId,
                                    this.organisationId
                                )
                            )
                            .catch(() => doneWorking())
                            .then(() => doneWorking());
                    }
                }));
            });
    }

    printReport(printOption: PrintOption) {
        printOption.print();
    }

    downloadPersonnelReport() {
        this.personnelService
            .exportOrganisationPersonnel(
                this.organisationId,
                this.includeRoles,
                this.selectedAuthorityTypes
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                FileSaver.saveAs(result, 'Organisation_Personnel.csv');
            })
            .add(this.workTracker.createTracker());
    }

    cancel() {
        this.modal.hide();
    }
}
