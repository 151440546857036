<flyfreely-map
    #map
    airspaceCheckResult
    class="fill"
    [organisationId]="currentOrganisation?.id"
    [features]="features$ | async"
    [featurePopupTemplate]="featurePopupTemplate"
    [showFeatureSelection]="false"
    [requireCtrlToZoom]="false"
    [isDashboardMap]="true"
    [crosshairCursor]="awaitingFeature"
    [dashboardMode]="missionViewService.viewMode$ | async"
    [ignoreClicksOnFeatureGroups]="ignoreClicksOnFeatureGroups"
    [startTime]="airspaceValue?.startTime"
    [endTime]="airspaceValue?.endTime"
    [ruleset]="airspaceValue?.airspaceRuleset"
    (featureSelected)="onFeatureSelected($event)"
    (featuresLoaded)="onMapFeaturesLoaded()"
    (measurementToolSelected)="onMeasurementToolSelected()"
    (editModeChanged)="onEditModeChanged($event)"
    (featureClusterSelected)="onFeatureClusterSelected($event)"
    (fullscreenRequested)="onFullscreenRequested()"
    (featureUnselected)="onFeatureUnselected()"
    (jurisdictionChanged)="onUpdateJurisdiction($event)"
    (mapClick)="onMapClicked($event)"
>
    <ng-template
        *ngIf="jurisdictionHasAirspaceChecker"
        mapSidebar
        let-map="map"
    >
        <location-airspace-check
            [organisationId]="organisationId"
            [timeZone]="location?.timeZone"
            [flightArea]="flightArea"
            [missionValues]="missionValues"
            [workTracker]="workTracker"
            [jurisdiction]="map.jurisdictionChanged | async"
            [head]
            emptyStateMessage="Click the map point to check"
            [allowMissionCreation]="canAddMission"
            [isDashboardChecker]="true"
            [viewMode]="missionViewService.viewMode$ | async"
            [toggleCheck]="true"
            [radius]="checkerAreaRadius"
            (checkerParametersChange)="onCheckerParametersChanged($event)"
            (createMission)="createMissionFromChecker()"
            (awaitingFeature)="setAwaitingFeature($event)"
        ></location-airspace-check>
    </ng-template>
    <!-- <ng-template
        mapSidebar
        let-map="map"
    >
        <div
            class="airspace-check-button idle"
            (click)="showAddLocation()"
        >
            <span class="fa fa-radar"></span>
            &nbsp;&nbsp;Run an Airspace Check
        </div>
    </ng-template> -->
</flyfreely-map>
<inline-modal
    *ngIf="
        airspaceJurisdiction &&
        airspaceJurisdiction.airspaceDisclaimer &&
        airspaceJurisdiction.airspaceDisclaimer.length
    "
    [show]="showAirspaceDisclaimer"
    [sizeSmall]="true"
    [topSpacing]="true"
    style="max-height: fit-content"
>
    <airspace-disclaimer
        screenIdentifier="mission-view-map"
        [airspaceJurisdiction]="airspaceJurisdiction"
        (done)="onAirspaceDisclaimerAcknowledged()"
    ></airspace-disclaimer>
</inline-modal>
<ng-template #featurePopupTemplate>
    <loading-indicator [isLoading]="working"></loading-indicator>
    <h4 class="popup-title" *ngIf="selectedLocation != null">
        Missions at {{ selectedLocation.name }}
    </h4>
    <div class="popup-panel">
        <div *ngIf="selectedMissions != null">
            <div *ngFor="let mission of selectedMissions">
                <h5>
                    <mission-status-icon
                        [mission]="mission"
                        (nameClicked)="showMission(mission)"
                    ></mission-status-icon>
                </h5>
                <p>
                    {{ mission.missionDate | formatDate }}
                    {{ mission.status | formatMissionStatus }}
                </p>
            </div>
            <div *ngIf="selectedMissions.length === 0">
                There are no missions at this location
            </div>
        </div>
    </div>
</ng-template>
