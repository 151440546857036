import { NgModule } from '@angular/core';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NewsCardComponent } from './news/news-card.component';
import { QuickSetupComponent } from './support/quick-setup/quick-setup.component';
import { SupportLinksComponent } from './support/support-links/support-links.component';

@NgModule({
    imports: [EnhancedHelpModule, AnalyticsModule, TooltipModule],
    declarations: [
        NewsCardComponent,
        QuickSetupComponent,
        SupportLinksComponent
    ],
    exports: [NewsCardComponent, QuickSetupComponent, SupportLinksComponent]
})
export class NewsModule {}
