<div class="top-buffer form-group">
    <label class="right-buffer">Jurisdiction</label>
    <ng-container *ngFor="let jurisdiction of jurisdictions">
        <button
            type="button"
            class="btn btn-xs btn-pill"
            [ngClass]="{ active: jurisdiction.id === activeId }"
            (click)="onSelectJurisdictionId(jurisdiction.id)"
        >
            {{ jurisdiction.name }}
        </button>
    </ng-container>
    <ng-content></ng-content>
</div>
