import * as moment from 'moment';
import 'moment-timezone';

const timestampFormat = 'YYYY-MM-DD[T]HH:mm:ss';

export const defaultTimezone = 'Australia/Queensland';

/**
 * Converts a local date and computes the UTC timestamp given the timezone.
 *
 * @param timestamp the date object representing the local datetime
 * @param inputTimezone the timezone to project into. defaults to the browser timezone
 * @returns  the ISO8901 timestamp in UTC
 */
export function toTimestamp(
    localDateTime: Date,
    inputTimezone?: string
): string {
    if (localDateTime == null) {
        return null;
    }
    if (inputTimezone == null) {
        return moment(localDateTime).toISOString();
    }
    return moment
        .tz(moment(localDateTime).format(timestampFormat), inputTimezone)
        .toISOString();
}

/**
 * Project the timestamp into the given timezone and return it as a local datetime for use with UI components.
 *
 * @param timestamp a UTC timestamp
 * @param displayTimezone the timezone to create the date object in
 * @returns a date object representing the timestamp in the given timezone
 */
export function fromTimestamp(
    timestamp: string | Date,
    displayTimezone?: string
): Date {
    if (timestamp == null) {
        return null;
    }
    if (displayTimezone == null) {
        return moment(timestamp).toDate();
    }
    return moment(
        moment(timestamp).tz(displayTimezone).format(timestampFormat)
    ).toDate();
}

/**
 * Convert a local date string in the form 'YYYY-MM-DD' to a date object that can be used with UI components.
 *
 * @param localDate a local date string
 * @returns date object for the start of the day
 */
export function fromLocalDate(localDate: string | Date) {
    if (!localDate) {
        return null;
    }
    if (localDate instanceof Date) {
        return localDate;
    }
    return moment(localDate).toDate();
}

/**
 * Convert a date object into a date string.
 *
 * @param date a date object
 * @returns the string representation of the date part of the provided object
 */
export function toLocalDate(date: Date) {
    if (!date) {
        return null;
    }
    return moment(date).format('YYYY-MM-DD');
}

export function toLocalTime(
    timestamp: string | Date,
    displayTimezone?: string
) {
    if (timestamp == null) {
        return null;
    }
    if (displayTimezone == null) {
        return moment(timestamp).format('HH:mm');
    }
    return moment.tz(moment(timestamp), displayTimezone).format('HH:mm');
}

/**
 *
 * Takes two date objects and combines them, using one for the date and the other for the time.
 *
 * @param date The Date object to use for the date
 * @param time The Date object to use for the time
 * @param tz The timezone for the date
 * @returns a Moment object with the date and time combined and set to the provided time zone without converting the time (i.e. the time provided will remain unchanged)
 */
export function consolidateDateAndTime(date: Date, time: Date, tz?: string) {
    if (date == null || time == null) {
        return null;
    }
    if (tz == null) {
        tz = moment.tz.guess();
    }
    const onlyDate = moment(date).format('MM-DD-YYYY');
    const onlyTime = moment(time).format('HH:mm');
    const consolidated = moment(`${onlyDate} ${onlyTime}`, 'MM-DD-YYYY HH:mm');
    // adding the .tz(tz, true) at the end sets the timezone without changing the time
    // If tz is null, the current system timezone will be used
    return tz != null ? consolidated.tz(tz, true) : consolidated;
}

export function momentToDate(date: moment.Moment) {
    if (date == null) {
        return;
    }
    return date.toDate();
}

export class FlyfreelyService {
    /**
     * Clear all the errors on a form object
     */
    // FIXME: currently clearErrors and applyErrors below are no longer used. Remove if unneeded
    //    static clearErrors(form: angular.IFormController) {
    //        if (form == null) {
    //            return;
    //        }
    //
    //        let keys = Object.keys(form);
    //        for (let i = 0; i < keys.length; i++) {
    //            if (
    //                keys[i][0] === '$' ||
    //                !form[keys[i]] ||
    //                !form[keys[i]].$errors
    //            ) {
    //                continue;
    //            }
    //            form[keys[i]].$setValidity('server', true);
    //            form[keys[i]].serverError = undefined;
    //        }
    //    }

    /**
     * Applies the errors to the form object
     */
    //    static applyErrors(
    //        form: angular.IFormController,
    //        errors: object | Array<any>
    //    ) {
    //        if (!errors) {
    //            return;
    //        }
    //
    //        if (errors instanceof Array) {
    //            for (let i = 0; i < errors.length; i++) {
    //                if (!form[errors[i].field]) {
    //                    console.warn(
    //                        `Got error for non-existant field ${errors[i].field}`
    //                    );
    //                    continue;
    //                }
    //                form[errors[i].field].serverError = errors[i].message;
    //                form[errors[i].field].$setValidity('server', false);
    //            }
    //        } else {
    //            const keys = Object.keys(errors);
    //            for (let i = 0; i < keys.length; i++) {
    //                if (!form[keys[i]]) {
    //                    console.warn(`Got error for non-existant field ${keys[i]}`);
    //                    continue;
    //                }
    //                form[keys[i]].serverError = errors[keys[i]];
    //                form[keys[i]].$setValidity('server', false);
    //            }
    //        }
    //    }

    static clearFormlyErrors(fields: any[]) {
        for (const field of fields) {
            if (!field.key) {
                continue;
            }

            if (Array.isArray(field.formControl)) {
                for (const formControl of field.formControl) {
                    formControl.$setValidity('server', true);
                    formControl.serverError = undefined;
                }
            } else {
                field.formControl.$setValidity('server', true);
                field.formControl.serverError = undefined;
            }
        }
    }

    static applyFormlyErrors(fields: any[], errors: { [key: string]: any }) {
        for (const field of fields) {
            if (!field.key) {
                continue;
            }
            if (field.key in errors) {
                if (Array.isArray(field.formControl)) {
                    for (const formControl of field.formControl) {
                        formControl.$setValidity('server', false);
                        formControl.serverError = errors[field.key];
                    }
                } else {
                    field.formControl.$setValidity('server', false);
                    field.formControl.serverError = errors[field.key];
                }
            }
        }
    }
}
