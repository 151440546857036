import { Injectable } from '@angular/core';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';
import {
    KeycloakEvent,
    KeycloakEventType,
    KeycloakService
} from 'keycloak-angular';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { httpParamSerializer } from '../service.helpers';
import { KeycloakValidateCommand } from './user.service';

/**
 * Initialise the {@link LoginManager}.
 * @param loginManager 
 * @returns 
 */
export function initializeKeycloak(loginManager: LoginManager) {
    return () => loginManager.init();
}

/**
 * The Login Manager is responsible for brokering all interactions between the KeyCloak system, and
 * the FlyFreely Office App. This mainly involves initialising KC, and managing state.
 * No other component should access KeyCloak or the state variables directly.
 *
 * This may become redundant when we upgrade Angular-KeyCloak, or replace with another OAuth service.
 */
@Injectable({
    providedIn: 'root'
})
export class LoginManager {
    private refreshExpire: string;
    private token: string;
    private refreshToken: string;
    private idToken: string;
    private impersonationToken: string;
    private impersonationIdToken: string;

    private keycloakUrl: string;

    private sessionMaxAge: number;

    private _keycloakEvents$ = new BehaviorSubject<KeycloakEvent>(null);
    keycloakEvents$ = this._keycloakEvents$.asObservable();

    private bearerTokenSubject = new BehaviorSubject<string | null>(null);
    /**
     * The latest bearer token, or `null` if there is no authentication (logged out).
     */
    bearerToken$ = this.bearerTokenSubject.asObservable();

    constructor(
        private keycloakService: KeycloakService,
        private constants: FlyFreelyConstants
    ) {
        this.keycloakUrl = constants.KEYCLOAK_URL;
        this.sessionMaxAge = constants.KC_MAX_AGE;
        keycloakService.keycloakEvents$.subscribe(e => {
            this._keycloakEvents$.next(e);
        });

        // Remove localStorage tokens.
        // TODO: get rid of this once sessionStorage has been used for a while and no localStorage tokens are left.
        localStorage.removeItem('kcRefreshExpiry');
        localStorage.removeItem('kcToken');
        localStorage.removeItem('kcRefreshToken');
        localStorage.removeItem('kcIdToken');

        this.refreshExpire = sessionStorage.getItem('kcRefreshExpiry');
        this.token = sessionStorage.getItem('kcToken');
        this.refreshToken = sessionStorage.getItem('kcRefreshToken');
        this.idToken = sessionStorage.getItem('kcIdToken');
        this.impersonationToken = localStorage.getItem('kcImpersonationToken');
        this.impersonationIdToken = localStorage.getItem(
            'kcImpersonationIdToken'
        );

        if (
            this.refreshExpire != null &&
            moment(this.refreshExpire).isBefore(moment(new Date()))
        ) {
            this.clearTokens();
        }
    }

    /**
     * Initialise the keycloak system, and return its user logged in promise
     * @returns a promise that resolves when keycloak is loaded, either in good or error state
     */
    init() {
        this.keycloakService.keycloakEvents$.subscribe({
            next: e => {
                if (
                    e.type === KeycloakEventType.OnAuthSuccess ||
                    e.type === KeycloakEventType.OnAuthRefreshSuccess
                ) {
                    if (this.impersonationToken == null) {
                        if (
                            this.refreshToken == null ||
                            this.keycloakService.getKeycloakInstance()
                                .refreshToken !== this.refreshToken
                        ) {
                            // This sets the timeout for the refresh tokens to clear the stored tokens if the refresh token has expired.
                            sessionStorage.setItem(
                                'kcRefreshExpiry',
                                moment(new Date()).add(7, 'days').toISOString()
                            );
                            sessionStorage.setItem(
                                'kcRefreshToken',
                                this.keycloakService.getKeycloakInstance()
                                    .refreshToken
                            );
                        }
                        sessionStorage.setItem(
                            'kcToken',
                            this.keycloakService.getKeycloakInstance().token
                        );
                        sessionStorage.setItem(
                            'kcIdToken',
                            this.keycloakService.getKeycloakInstance().idToken
                        );
                        this.bearerTokenSubject.next(this.keycloakService.getKeycloakInstance().token);
                    } else {
                        localStorage.setItem(
                            'kcImpersonationIdToken',
                            this.keycloakService.getKeycloakInstance().idToken
                        );
                        this.bearerTokenSubject.next(this.keycloakService.getKeycloakInstance().idToken);
                    }
                }
                if (e.type === KeycloakEventType.OnAuthLogout) {
                    this.clearTokens();
                }
                if (e.type === KeycloakEventType.OnAuthError) {
                    console.log('Error logging in. Resetting session.');
                    this.clearTokens();
                }
                if (e.type === KeycloakEventType.OnTokenExpired) {
                    console.log('token expired, update token');
                    this.keycloakService.updateToken(20);
                }
            }
        });

        return this.keycloakService
            .init({
                enableBearerInterceptor: false,
                config: {
                    url: `${this.constants.KEYCLOAK_URL}/auth`,
                    realm: 'FlyFreely',
                    clientId: 'flyfreely-app'
                },
                bearerExcludedUrls: ['/assets', '/clients/public'],
                initOptions: {
                    enableLogging: true,
                    checkLoginIframe: false,
                    // use impersonation tokens if they exist.
                    token: this.impersonationToken ?? this.token,
                    idToken: this.impersonationToken
                        ? this.impersonationIdToken ?? null
                        : this.refreshToken,
                    refreshToken: this.impersonationToken
                        ? null
                        : this.refreshToken
                }
            })
            .catch(() => {
                // turn the rejection, that would stop the app loading, into a resolve.
            });
    }

    private clearTokens() {
        sessionStorage.removeItem('kcToken');
        sessionStorage.removeItem('kcRefreshToken');
        sessionStorage.removeItem('kcIdToken');
        sessionStorage.removeItem('kcRefreshExpiry');
        localStorage.removeItem('kcImpersonationToken');
        localStorage.removeItem('kcImpersonationIdToken');
        this.refreshExpire = null;
        this.refreshToken = null;
        this.idToken = null;
        this.token = null;
        this.impersonationToken = null;
        this.bearerTokenSubject.next(null);
    }

    logout(redirect?: string) {
        sessionStorage.removeItem('kcToken');
        sessionStorage.removeItem('kcRefreshToken');
        sessionStorage.removeItem('kcIdToken');
        sessionStorage.removeItem('kcRefreshExpiry');
        localStorage.removeItem('kcImpersonationToken');
        localStorage.removeItem('kcImpersonationIdToken');
        this.bearerTokenSubject.next(null);
        // Logout the user
        this.keycloakService.logout(
            redirect
                ? `${window.location.origin}${redirect}`
                : window.location.origin
        );
    }

    login(loginHint?: string) {
        if (!this.isImpersonating()) {
            return this.keycloakService.login({
                loginHint,
                scope: 'openid offline_access',
                maxAge: this.sessionMaxAge
            });
        } else if (this.isImpersonating()) {
            return this.keycloakService.login({
                scope: 'openid'
            });
        }
    }

    register(loginHint?: string) {
        return this.keycloakService.register({
            loginHint,
            scope: 'openid offline_access',
            maxAge: this.sessionMaxAge
        });
    }

    isImpersonating() {
        return localStorage.getItem('kcImpersonationToken') != null;
    }

    stopImpersonating() {
        localStorage.removeItem('kcImpersonationToken');
        localStorage.removeItem('kcImpersonationIdToken');
    }

    getImpersonationToken() {
        return localStorage.getItem('kcImpersonationToken');
    }

    /**
     * This function allows login in a user using the Keycloak Headless Authenticator.
     * It is not recommended to use this method if the standard Keycloak login can be used instead.
     * This function will reroute the window.
     *
     * Currently unused
     *
     * @param command a command containing a username and password and an optional redirect URI.
     */
    keycloakValidateUser(command: KeycloakValidateCommand) {
        const url = this.keycloakService.getKeycloakInstance().createLoginUrl();

        let nonce = url.slice(
            url.indexOf('nonce=') + 'nonce='.length,
            url.length
        );
        nonce = nonce.includes('&')
            ? nonce.slice(0, nonce.indexOf('&'))
            : nonce;

        let state = url.slice(
            url.indexOf('state=') + 'state='.length,
            url.length
        );
        state = state.slice(0, state.indexOf('&'));

        const redirect = `/${command.redirectTarget}` ?? '';

        const params = httpParamSerializer({
            client_id: 'flyfreely-app',
            redirect_uri: `${window.location.origin}${redirect}`,
            state: state,
            response_mode: 'fragment',
            response_type: 'code',
            scope: 'openid offline_access',
            nonce: nonce,
            username: command.username,
            password: command.password
        });
        return (window.location.href = `${
            this.keycloakUrl
        }/auth/realms/FlyFreely/protocol/openid-connect/auth?${params.toString()}`);
    }
}
