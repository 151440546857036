import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AirspaceAuthorisationManager } from '@flyfreely-portal-ui/airspace';
import { AirspaceAuthorisationDto } from '@flyfreely-portal-ui/flyfreely';
import { AirspaceCheckService } from 'libs/airspace/src/lib/airspace-check';
import { calculateAuthorisationListState } from 'libs/airspace/src/lib/authorisation/authorisation-status';
import {
    authorisationTypesWithMultipleAuthorisations,
    findLatestAirspaceAuthorisationInList,
    isAuthorisationRequired
} from 'libs/airspace/src/lib/authorisation/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// PENDING, APPROVED, FAILED, REJECTED, CANCELLED, COMPLETED, PROVISIONAL, TERMINATED

type Statuses =
    | 'INCOMPLETE'
    | 'OPTIONAL'
    | 'CAN_REQUEST'
    | 'APPROVED_PROVISIONAL'
    | 'APPROVED'
    | 'DECLARED'
    | 'PENDING'
    | 'NO_AUTHORISATION'
    | 'CANCELLED'
    | 'CLOSED'
    | 'COMPLETED';

@Component({
    selector: 'airspace-authorisation-link',
    templateUrl: './mission-airspace-authorisation-link.component.html',
    styleUrls: ['../../../../../../airspace/src/lib/styles.scss']
})
export class MissionAirspaceAuthorisationLink {
    @Input() canRequest: boolean;
    @Input() working: boolean;
    @Input() disabled: boolean;

    @Output() linkClicked = new EventEmitter<void>();
    @Output() requestAuthorisation = new EventEmitter<void>();

    authorisations: AirspaceAuthorisationDto[];

    authorisationState: AirspaceAuthorisationDto.Status;
    state: Statuses = 'INCOMPLETE';
    authStates = AirspaceAuthorisationDto.Status;

    // FIXME: Currently this is only used for Airshare so this is semi-hard coded.
    // Eventually this might need to be passed an authorisation type.
    authorisationType: AirspaceAuthorisationDto.AuthorisationType;

    private isOptional = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private airspaceCheckService: AirspaceCheckService,
        private airspaceAuthorisationManager: AirspaceAuthorisationManager
    ) {
        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => {
                this.isOptional = !isAuthorisationRequired(results);
                this.authorisationType =
                    results != null
                        ? results?.availableAutomatedAuthorisationList[0]
                        : this.authorisationType;
                this.parseState();
            });

        this.airspaceAuthorisationManager.missionAirspaceAuthorisations$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorisations => {
                this.authorisations =
                    authorisations != null && authorisations.length > 0
                        ? authorisationTypesWithMultipleAuthorisations.includes(
                              authorisations[0].authorisationType
                          )
                            ? authorisations
                            : [
                                  findLatestAirspaceAuthorisationInList(
                                      authorisations
                                  )
                              ]
                        : authorisations;

                this.authorisationType =
                    authorisations != null
                        ? authorisations[0]?.authorisationType
                        : null;
                this.parseState();
            });
    }

    ngOnInit() {
        this.parseState();
    }

    ngOnChanges() {
        this.parseState();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    parseState() {
        this.authorisationState = calculateAuthorisationListState(
            this.authorisations
        ) as AirspaceAuthorisationDto.Status;
        if (this.authorisationState == null) {
            if (this.isOptional && this.canRequest) {
                this.state = 'OPTIONAL';
            } else if (this.canRequest) {
                this.state = 'CAN_REQUEST';
            } else {
                this.state = 'INCOMPLETE';
            }
        } else {
            switch (this.authorisationState) {
                case this.authStates.APPROVED:
                case this.authStates.DECLARED:
                    this.state = 'APPROVED';
                    break;
                case this.authStates.PROVISIONAL:
                    this.state = 'APPROVED_PROVISIONAL';
                    break;

                case this.authStates.PENDING:
                    this.state = 'PENDING';
                    break;

                case this.authStates.REJECTED:
                    this.state = 'NO_AUTHORISATION';
                    break;

                case this.authStates.CANCELLED:
                    this.state = 'CANCELLED';
                    break;

                case this.authStates.COMPLETED:
                // @ts-ignore - this state can be passed in some cases
                case 'CLOSED':
                    this.state = 'COMPLETED';
                    break;

                default:
                    this.state = 'INCOMPLETE';
                    break;
            }
        }
    }
}
