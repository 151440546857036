/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.60-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganisationSettingsDto } from './organisationSettingsDto';
import { SimpleAirspaceJurisdictionDto } from './simpleAirspaceJurisdictionDto';

/**
 * Contains the details of an organisation from a persons\'s perspective.
 */
export interface PersonsOrganisationDto {
    id: number;
    /**
     * The name of the organisation
     */
    name: string;
    /**
     * A list of all active feature flags for the organisation
     */
    featureFlags: Array<string>;
    /**
     * Is this a personal organisation
     */
    personalOrganisation: boolean;
    /**
     * The Person ID of the organisation\'s owner
     */
    ownerId: number;
    /**
     * Is the current user the organisation\'s owner
     */
    owner: boolean;
    /**
     * Does the requesting user have a licence for FlyFreely in this organisation
     */
    hasLicence: boolean;
    /**
     * Has the organisation set a logo
     */
    hasLogo: boolean;
    /**
     * The timezone that the organisation uses for formatting timestamps and calculating dates
     */
    timeZone: string;
    /**
     * The list of the current user\'s roles in the organisation
     */
    roles: Array<PersonsOrganisationDto.Roles>;
    /**
     * The list of the current user\'s permissions in the organisation
     */
    permissions: Array<PersonsOrganisationDto.Permissions>;
    /**
     * A list of all jurisdictions enabled for this organisation
     */
    activeJurisdictions: Array<SimpleAirspaceJurisdictionDto>;
    setup?: boolean;
    settings: OrganisationSettingsDto;
}
export namespace PersonsOrganisationDto {
    export type Roles =
        | 'PLANNER'
        | 'RCP'
        | 'ADMIN'
        | 'BILLING_ADMIN'
        | 'PILOT'
        | 'PILOT_PLANNER'
        | 'PILOT_SUBMITTER'
        | 'PILOT_CREW_PLANNER'
        | 'PILOT_INSTANT_MISSION'
        | 'FLIGHT_LOG_COLLECTION_ORGANISATION'
        | 'FLIGHT_LOG_COLLECTION_OWN'
        | 'MAINTENANCE_CONTROLLER'
        | 'MAINTENANCE_PERSON'
        | 'MAINTENANCE_PERSON_EXTERNAL'
        | 'STUDENT'
        | 'OBSERVER'
        | 'READ_ONLY'
        | 'READ_ONLY_HIDDEN';

    export const Roles = {
        PLANNER: 'PLANNER' as Roles,
        RCP: 'RCP' as Roles,
        ADMIN: 'ADMIN' as Roles,
        BILLING_ADMIN: 'BILLING_ADMIN' as Roles,
        PILOT: 'PILOT' as Roles,
        PILOT_PLANNER: 'PILOT_PLANNER' as Roles,
        PILOT_SUBMITTER: 'PILOT_SUBMITTER' as Roles,
        PILOT_CREW_PLANNER: 'PILOT_CREW_PLANNER' as Roles,
        PILOT_INSTANT_MISSION: 'PILOT_INSTANT_MISSION' as Roles,
        FLIGHT_LOG_COLLECTION_ORGANISATION:
            'FLIGHT_LOG_COLLECTION_ORGANISATION' as Roles,
        FLIGHT_LOG_COLLECTION_OWN: 'FLIGHT_LOG_COLLECTION_OWN' as Roles,
        MAINTENANCE_CONTROLLER: 'MAINTENANCE_CONTROLLER' as Roles,
        MAINTENANCE_PERSON: 'MAINTENANCE_PERSON' as Roles,
        MAINTENANCE_PERSON_EXTERNAL: 'MAINTENANCE_PERSON_EXTERNAL' as Roles,
        STUDENT: 'STUDENT' as Roles,
        OBSERVER: 'OBSERVER' as Roles,
        READ_ONLY: 'READ_ONLY' as Roles,
        READ_ONLY_HIDDEN: 'READ_ONLY_HIDDEN' as Roles
    };
    export type Permissions =
        | 'CRAFT_EDIT'
        | 'CRAFT_ADD'
        | 'CRAFT_LIST'
        | 'CRAFT_VIEW'
        | 'LOCATION_EDIT'
        | 'LOCATION_ADD'
        | 'LOCATION_VIEW'
        | 'LOCATION_LIST'
        | 'MISSION_LIST'
        | 'MISSION_VIEW'
        | 'MISSION_APPROVE'
        | 'MISSION_SUBMIT'
        | 'MISSION_ADD'
        | 'MISSION_EDIT'
        | 'MISSION_ADD_OWN'
        | 'MISSION_INSTANT_MISSION'
        | 'MISSION_MANAGE'
        | 'BATTERY_ADD'
        | 'BATTERY_EDIT'
        | 'BATTERY_LIST'
        | 'BATTERYSET_ADD'
        | 'BATTERYSET_EDIT'
        | 'BATTERYSET_LIST'
        | 'ORGANISATION_ACCESS'
        | 'ORGANISATION_VIEW'
        | 'ORGANISATION_EDIT'
        | 'FLIGHT_OPERATIONS_CONFIGURATION'
        | 'FLIGHT_OPERATIONS_CONFIGURATION_VIEW'
        | 'PERSON_ADD'
        | 'PERSON_EDIT'
        | 'PERSON_LIST'
        | 'DOCUMENTATION_ADD'
        | 'DOCUMENTATION_EDIT'
        | 'MAINTENANCE_LIST'
        | 'MAINTENANCE_REQUEST'
        | 'MAINTENANCE_MANAGE'
        | 'MAINTENANCE_PERFORM'
        | 'FLIGHT_LOG_UPLOAD'
        | 'FLIGHT_LOG_MANAGE'
        | 'FLIGHT_LOG_LIST'
        | 'FLIGHT_LOG_COLLECTION_MANAGE'
        | 'FLIGHT_LOG_COLLECTION_OWN'
        | 'TASK_LIST'
        | 'TASK_ADD'
        | 'TASK_EDIT';
    export const Permissions = {
        CRAFT_EDIT: 'CRAFT_EDIT' as Permissions,
        CRAFT_ADD: 'CRAFT_ADD' as Permissions,
        CRAFT_LIST: 'CRAFT_LIST' as Permissions,
        CRAFT_VIEW: 'CRAFT_VIEW' as Permissions,
        LOCATION_EDIT: 'LOCATION_EDIT' as Permissions,
        LOCATION_ADD: 'LOCATION_ADD' as Permissions,
        LOCATION_VIEW: 'LOCATION_VIEW' as Permissions,
        LOCATION_LIST: 'LOCATION_LIST' as Permissions,
        MISSION_LIST: 'MISSION_LIST' as Permissions,
        MISSION_VIEW: 'MISSION_VIEW' as Permissions,
        MISSION_APPROVE: 'MISSION_APPROVE' as Permissions,
        MISSION_SUBMIT: 'MISSION_SUBMIT' as Permissions,
        MISSION_ADD: 'MISSION_ADD' as Permissions,
        MISSION_EDIT: 'MISSION_EDIT' as Permissions,
        MISSION_ADD_OWN: 'MISSION_ADD_OWN' as Permissions,
        MISSION_INSTANT_MISSION: 'MISSION_INSTANT_MISSION' as Permissions,
        MISSION_MANAGE: 'MISSION_MANAGE' as Permissions,
        BATTERY_ADD: 'BATTERY_ADD' as Permissions,
        BATTERY_EDIT: 'BATTERY_EDIT' as Permissions,
        BATTERY_LIST: 'BATTERY_LIST' as Permissions,
        BATTERYSET_ADD: 'BATTERYSET_ADD' as Permissions,
        BATTERYSET_EDIT: 'BATTERYSET_EDIT' as Permissions,
        BATTERYSET_LIST: 'BATTERYSET_LIST' as Permissions,
        ORGANISATION_ACCESS: 'ORGANISATION_ACCESS' as Permissions,
        ORGANISATION_VIEW: 'ORGANISATION_VIEW' as Permissions,
        ORGANISATION_EDIT: 'ORGANISATION_EDIT' as Permissions,
        FLIGHT_OPERATIONS_CONFIGURATION:
            'FLIGHT_OPERATIONS_CONFIGURATION' as Permissions,
        FLIGHT_OPERATIONS_CONFIGURATION_VIEW:
            'FLIGHT_OPERATIONS_CONFIGURATION_VIEW' as Permissions,
        PERSON_ADD: 'PERSON_ADD' as Permissions,
        PERSON_EDIT: 'PERSON_EDIT' as Permissions,
        PERSON_LIST: 'PERSON_LIST' as Permissions,
        DOCUMENTATION_ADD: 'DOCUMENTATION_ADD' as Permissions,
        DOCUMENTATION_EDIT: 'DOCUMENTATION_EDIT' as Permissions,
        MAINTENANCE_LIST: 'MAINTENANCE_LIST' as Permissions,
        MAINTENANCE_REQUEST: 'MAINTENANCE_REQUEST' as Permissions,
        MAINTENANCE_MANAGE: 'MAINTENANCE_MANAGE' as Permissions,
        MAINTENANCE_PERFORM: 'MAINTENANCE_PERFORM' as Permissions,
        FLIGHT_LOG_UPLOAD: 'FLIGHT_LOG_UPLOAD' as Permissions,
        FLIGHT_LOG_MANAGE: 'FLIGHT_LOG_MANAGE' as Permissions,
        FLIGHT_LOG_LIST: 'FLIGHT_LOG_LIST' as Permissions,
        FLIGHT_LOG_COLLECTION_MANAGE:
            'FLIGHT_LOG_COLLECTION_MANAGE' as Permissions,
        FLIGHT_LOG_COLLECTION_OWN: 'FLIGHT_LOG_COLLECTION_OWN' as Permissions,
        TASK_LIST: 'TASK_LIST' as Permissions,
        TASK_ADD: 'TASK_ADD' as Permissions,
        TASK_EDIT: 'TASK_EDIT' as Permissions
    };
}
