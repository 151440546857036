import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AuthorityNominatedPersonnelDto,
    AuthorityTypeDto,
    AuthorityTypeNominatedRoleDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    PersonDto,
    WorkTracker,
    fromLocalDate,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NominatedPersonnelDataService } from './nominated-personnel-data.service';

function toExistingNominatedPersonForm(
    role: AuthorityTypeNominatedRoleDto,
    p: AuthorityNominatedPersonnelDto
) {
    return new FormGroup<FormEntry>({
        id: new FormControl(p.id),
        personId: new FormControl(p.person.id, Validators.required),
        organisationId: new FormControl(p.organisation?.id ?? 0),
        startDate: new FormControl(fromLocalDate(p.startDate)),
        expiryDate: new FormControl(fromLocalDate(p.expiryDate)),
        canApproveOwnMissions: new FormControl(p.canApproveOwnMissions),
        canManageMissions: new FormControl(p.canManageMissions)
    });
}

export interface FormEntry {
    id?: FormControl<number>;
    personId: FormControl<number>;
    organisationId?: FormControl<number>;
    startDate: FormControl<Date>;
    expiryDate?: FormControl<Date>;
    canApproveOwnMissions: FormControl<boolean>;
    canManageMissions: FormControl<boolean>;
}

interface RoleForm {
    role: AuthorityTypeNominatedRoleDto;
    personnel: FormArray<FormGroup<FormEntry>>;
}

interface PersonnelList {
    list$: Observable<PersonDto[]>;
    loading: boolean;
}

@Component({
    selector: 'nominated-personnel',
    templateUrl: './nominated-personnel.component.html',
    providers: [NominatedPersonnelDataService, WorkTracker]
})
export class NominatedPersonnelDialogue implements OnInit, OnDestroy {
    @Input() authority: OrganisationAuthorityDto;
    @Input() authorityType: AuthorityTypeDto;

    roles: RoleForm[] = [];

    personnel: { [organisationId: number]: PersonnelList } = {};
    private ngUnsubscribe$ = new Subject<void>();

    working: boolean;

    addNew: boolean = false;

    error: string;

    constructor(
        private modal: BsModalRef<NominatedPersonnelDialogue>,
        private organisationAuthorityService: OrganisationAuthorityService,
        private logging: FlyFreelyLoggingService,
        public dataService: NominatedPersonnelDataService,
        private workTracker: WorkTracker
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.personnel = [];
        if (this.authority != null) {
            this.dataService.setup(this.authority);
            this.dataService.status$
                .pipe(
                    filter(s => s.state !== 'LOADING'),
                    take(1),
                    takeUntil(this.ngUnsubscribe$)
                )
                .subscribe(s => {
                    if (s.state === 'LOADED') {
                        this.loadNominatedPersonnel();
                    } else if (s.state === 'ERROR') {
                        this.logging.error(
                            null,
                            `Error loading data: ${s.error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            this.error = 'Dialogue not invoked correctly';
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    hide() {
        this.modal.hide();
    }

    private loadNominatedPersonnel() {
        this.organisationAuthorityService
            .findNominatedPersonnel(
                this.authority.id,
                this.authority.organisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                nominatedPersonnel => {
                    this.roles = this.authorityType.nominatedRoles.map(role => {
                        const nominated = nominatedPersonnel
                            .filter(
                                p => p.authorityTypeNominatedRole.id === role.id
                            )
                            .map(p => toExistingNominatedPersonForm(role, p));
                        return {
                            role,
                            personnel: new FormArray<FormGroup<FormEntry>>(
                                nominated
                            )
                        };
                    });
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error populating form: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    addNewPerson(role: RoleForm) {
        const nominated = new FormGroup<FormEntry>({
            id: new FormControl<number>(undefined),
            personId: new FormControl<number>(undefined, Validators.required),
            organisationId: new FormControl<number>(undefined),
            startDate: new FormControl(undefined, Validators.required),
            expiryDate: new FormControl(undefined),
            canApproveOwnMissions: new FormControl(false),
            canManageMissions: new FormControl(false)
        });
        role.personnel.push(nominated);
    }

    save(role: RoleForm, person: FormGroup<FormEntry>) {
        const values = person.value;

        values.organisationId =
            values.organisationId !== 0 ? values.organisationId : null;

        const duplicateCounter = role.personnel.controls.reduce(
            (acc, p: FormGroup<FormEntry>) =>
                values.personId === p.controls.personId.value ? acc + 1 : acc,
            0
        );

        if (duplicateCounter > 1) {
            this.logging.warn('This person has already been nominated');
            return;
        }

        if (person.controls.id.value == null) {
            this.organisationAuthorityService
                .createNominatedPersonnel(this.authority.id, {
                    startDate: toLocalDate(values.startDate),
                    expiryDate: toLocalDate(values.expiryDate),
                    personId: values.personId,
                    organisationId: values.organisationId,
                    authorityTypeNominatedRoleId: role.role.id,
                    managingOrganisationId: this.authority.organisationId,
                    canApproveOwnMissions: values.canApproveOwnMissions,
                    canManageMissions: values.canManageMissions
                })
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: savedPerson => {
                        person.controls.id.patchValue(savedPerson.id);
                        this.logging.success('Nominated person added');
                        role.personnel.markAsPristine();
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error adding person: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        } else {
            this.organisationAuthorityService
                .updateNominatedPersonnel(this.authority.id, values.id, {
                    startDate: toLocalDate(values.startDate),
                    expiryDate: toLocalDate(values.expiryDate),
                    personId: values.personId,
                    organisationId: values.organisationId,
                    authorityTypeNominatedRoleId: role.role.id,
                    managingOrganisationId: this.authority.organisationId,
                    canApproveOwnMissions: values.canApproveOwnMissions,
                    canManageMissions: values.canManageMissions
                })
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: savedPerson => {
                        this.logging.success('Nominated person updated');
                        person.markAsPristine();
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error updating person: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        }
    }

    remove(role: RoleForm, person: FormGroup<FormEntry>, index: number) {
        if (person.controls.id.value == null) {
            role.personnel.removeAt(index);
            role.personnel.markAsPristine();
            return;
        }
        this.organisationAuthorityService
            .removeNominatedPersonnel(
                this.authority.id,
                person.controls.id.value
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    role.personnel.removeAt(index);
                    this.logging.success('Nominated person removed');
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error removing person: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }
}
