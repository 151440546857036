import { FormlyFieldConfig } from '@ngx-formly/core';

export const completionFields: FormlyFieldConfig[] = [
    {
        key: 'journal',
        type: 'textarea',
        props: {
            required: false,
            maxLength: 2000
        }
    },
    {
        key: 'objectiveOutcomeNotes',
        type: 'textarea',
        props: {
            required: false,
            maxLength: 10000
        }
    },
    {
        key: 'reason',
        type: 'input',
        props: {
            required: false,
            maxLength: 50
        }
    }
];
