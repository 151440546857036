<ng-container screenAnalytics="mission-record-v1">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-missions.png" />
        </div>
        <h3 class="modal-title">Edit Mission Record</h3>
        <h5 class="modal-title padding">
            {{ mission.name }}
            {{ mission.readyForFinalisation ? '(Awaiting finalisation)' : '' }}
        </h5>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <form
        [formGroup]="missionCompletionForm"
        (ngSubmit)="save()"
        novalidate
        [formIdentifier]="validationFields"
    >
        <inline-modal #inlineModal></inline-modal>
        <div class="modal-body mission inner-container-and-footer">
            <div
                cdkDropListGroup
                class="modal-body-content container-with-navigation"
                scrollSpy
                [spiedTags]="['DIV']"
                [spiedContainer]="content"
                [activateOffset]="100"
                (sectionChange)="onSectionChange($event)"
            >
                <!-- Menu Left -->
                <div class="container-menu">
                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['objectives']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('objectives')">
                            <i
                                class="far fa-bullseye-pointer tab-title-icon"
                            ></i>
                            Objectives
                        </button>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['operations']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('operations')">
                            <i class="far fa-wave-pulse tab-title-icon"></i>
                            Operations
                        </button>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['flights']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('flights')">
                            <i class="far fa-plane tab-title-icon"></i>
                            Flights
                        </button>
                    </div>
                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['documentation']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button
                            type="button"
                            (click)="scrollTo('documentation')"
                        >
                            <i class="far fa-file tab-title-icon"></i>
                            Documentation
                        </button>
                    </div>

                    <div
                        *ngIf="
                            !locked && hasFlightLogging && (showFlightLogPanel$ | async)
                        "
                        missionFlightLogs
                        @expandOnEnter
                        @collapseOnLeave
                    >
                        <div
                            class="vertical-container top-buffer"
                            style="max-height: 250px; margin-left: 20px"
                        >
                            <unassigned-mission-flight-logs
                                class="vertical-container fill"
                                style="overflow: hidden"
                                [inlineModal]="inlineModal"
                                [log]="'unassigned'"
                                [readonly]="working || locked"
                                [mission]="mission"
                                (missionChanged)="updateMission($event)"
                            >
                            </unassigned-mission-flight-logs>
                        </div>
                    </div>
                </div>
                <!-- Content -->
                <div
                    #content
                    class="form-horizontal container-content"
                    tabindex="0"
                >
                    <div style="padding: 0 15px">
                        <enhanced-help
                            class="no-min-height"
                            screenName="enhanced-help-help"
                            componentName="main"
                        >
                        </enhanced-help>
                    </div>
                    <!-- Objectives -->
                    <div id="objectives">
                        <div class="vertical-container container-fluid">
                            <h4 class="section-heading">
                                <span>Objectives</span>
                                <div></div>
                            </h4>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Planned Objective
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <p>
                                        {{
                                            mission?.missionObjectiveType?.name
                                        }}
                                    </p>
                                    <p class="pre-wrap">
                                        {{ mission?.missionObjective }}
                                    </p>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{ readonly: working }"
                                    >
                                        Objective Outcome
                                        <field-validation-indicator
                                            validatorFormControlName="outcome"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="outcome"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="COMPLETED"
                                            [disabled]="working"
                                            >Completed</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="PARTIALLY_COMPLETED"
                                            [disabled]="working"
                                            >Partially Completed</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="INCOMPLETE"
                                            [disabled]="working"
                                            >Incomplete</label
                                        >
                                    </div>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="mission-completion-dialogue"
                                componentName="outcome"
                            >
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{ readonly: working }"
                                    >
                                        Objective Outcome Details
                                        <field-validation-indicator
                                            validatorFormControlName="objectiveOutcomeNotes"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <textarea
                                        name="objectiveOutcomeNotes"
                                        class="form-control"
                                        formControlName="objectiveOutcomeNotes"
                                        [readonly]="working"
                                    >
                                    </textarea>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="mission-completion-dialogue"
                                componentName="objectiveOutcomeNotes"
                            >
                            </enhanced-help>
                        </div>
                    </div>
                    <!-- Operations -->
                    <div id="operations">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{ disabled: locked }"
                            >
                                <span>Operations </span>
                                <div></div>
                            </h4>

                            <div class="">
                                <div class="col-xs-3 text-right"></div>
                                <div class="col-xs-6">
                                    <p class="help-block">
                                        All times are in {{ mission.timeZone }}
                                    </p>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{
                                            readonly: working || locked
                                        }"
                                    >
                                        Start Time
                                        <field-validation-indicator
                                            validatorFormControlName="actualStartTime"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6 d-flex">
                                    <p class="input-group">
                                        <input
                                            class="form-control"
                                            type="text"
                                            #datepicker="bsDatepicker"
                                            bsDatepicker
                                            formControlName="actualStartTime"
                                            [readonly]="working || locked"
                                        />
                                        <span
                                            class="input-group-addon btn-default"
                                            (click)="datepicker.toggle()"
                                            [attr.aria-expanded]="
                                                datepicker.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                            ></i>
                                        </span>
                                    </p>
                                    <timepicker
                                        formControlName="actualStartTime"
                                        [hourStep]="1"
                                        [minuteStep]="1"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [readonlyInput]="working || locked"
                                    >
                                    </timepicker>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="mission-completion-dialogue"
                                componentName="actualStartTime"
                            >
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{
                                            readonly: working || locked
                                        }"
                                    >
                                        End Time
                                        <field-validation-indicator
                                            validatorFormControlName="actualEndTime"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6 d-flex">
                                    <p class="input-group">
                                        <input
                                            class="form-control"
                                            type="text"
                                            [readonly]="working || locked"
                                            #datepicker="bsDatepicker"
                                            bsDatepicker
                                            formControlName="actualEndTime"
                                        />
                                        <span
                                            class="input-group-addon btn-default"
                                            (click)="datepicker.toggle()"
                                            [attr.aria-expanded]="
                                                datepicker.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                            ></i>
                                        </span>
                                    </p>
                                    <timepicker
                                        formControlName="actualEndTime"
                                        [hourStep]="1"
                                        [minuteStep]="1"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [readonlyInput]="working || locked"
                                    >
                                    </timepicker>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="mission-completion-dialogue"
                                componentName="actualEndTime"
                            >
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{
                                            readonly: working || locked
                                        }"
                                    >
                                        Journal
                                        <field-validation-indicator
                                            validatorFormControlName="journal"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <textarea
                                        class="form-control"
                                        formControlName="journal"
                                        [readonly]="working || locked"
                                    >
                                    </textarea>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="mission-completion-dialogue"
                                componentName="journal"
                            >
                            </enhanced-help>

                            <!-- Unlisted Resources -->
                            <div
                                *ngIf="
                                    mission.unlistedPersonnel.length > 0 ||
                                    mission.unlistedRpas.length > 0 ||
                                    mission.unlistedBatterySets.length > 0 ||
                                    mission.unlistedEquipment.length > 0
                                "
                                [ngClass]="{
                                    disabled: locked
                                }"
                            >
                                <h4
                                    class="section-heading"
                                    [ngClass]="{ readonly: locked }"
                                >
                                    <span>Unlisted Mission Resources </span>
                                    <div></div>
                                </h4>

                                <enhanced-help
                                    screenName="mission-completion-dialogue"
                                    componentName="unlisted"
                                >
                                </enhanced-help>
                                <div class="top-buffer">
                                    <unlisted-mission-resources></unlisted-mission-resources>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="flights">
                        <div class="vertical-container container-fluid">
                            <!-- Flight Records -->
                            <div
                                intersectionSpy
                                [spyElement]="flightHeading"
                                class="form-group"
                                [ngClass]="{ disabled: locked }"
                            >
                                <div class="col-xs-12" missionFlightLogs>
                                    <div
                                        #flightHeading
                                        class="sticky-heading"
                                        style="
                                            z-index: 10;
                                            padding-bottom: 10px;
                                        "
                                    >
                                        <h4 class="section-heading">
                                            <span
                                                [ngClass]="{
                                                    readonly: working || locked
                                                }"
                                                >Flights
                                            </span>
                                            <div></div>
                                            <div dropdown>
                                                <button
                                                    dropdownToggle
                                                    class="btn-link"
                                                    type="button"
                                                    tooltip="Options"
                                                    placement="left"
                                                    data-cy="flightOptionsDropdown"
                                                    [disabled]="
                                                        working || locked
                                                    "
                                                >
                                                    ...
                                                </button>
                                                <ul
                                                    class="dropdown-menu dropdown-menu-right"
                                                    *dropdownMenu
                                                    role="menu"
                                                >
                                                    <li
                                                        *ngIf="
                                                            flights.length > 0
                                                        "
                                                    >
                                                        <a
                                                            class="nav-link"
                                                            (click)="
                                                                deleteAllFlights()
                                                            "
                                                        >
                                                            Delete all flights
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </h4>
                                        <div class="horizontal-container">
                                            <button
                                                class="btn btn-default btn-confirm btn-sm left-buffer right-buffer"
                                                type="button"
                                                (click)="addFlight()"
                                                [disabled]="working || locked"
                                            >
                                                <div class="circle">
                                                    <span
                                                        class="fa fa-plus"
                                                    ></span>
                                                </div>
                                                Add New Flight
                                            </button>
                                            <button
                                                *ngIf="
                                                    canUploadSorties && !locked
                                                "
                                                class="btn btn-default btn-sm left-buffer right-buffer"
                                                type="button"
                                                (click)="bulkFlightUpload()"
                                                [disabled]="working || locked"
                                            >
                                                Bulk Upload
                                            </button>
                                            <mission-overview
                                                class="left-buffer right-buffer"
                                                [mission]="mission"
                                                [historicalMission]="false"
                                            ></mission-overview>
                                        </div>
                                    </div>

                                    <ng-container formArrayName="flights">
                                        <div
                                            *ngFor="
                                                let flight of flights.controls;
                                                let i = index;
                                                let rowEven = odd
                                            "
                                            class="row-padding"
                                            [ngClass]="{ 'row-even': rowEven }"
                                        >
                                            <flight-edit
                                                [formControlName]="i"
                                                [canUseEquipment]="
                                                    canUseEquipment
                                                "
                                                [missionDate]="
                                                    missionCompletionForm
                                                        .controls
                                                        .actualStartTime
                                                "
                                                [readonly]="working || locked"
                                                [hasFlightLogging]="
                                                    hasFlightLogging
                                                "
                                                [hasAdditionalFlightFields]="
                                                    hasAdditionalFlightFields
                                                "
                                                [mission]="mission"
                                                [hasFlightDocumentation]="true"
                                                (showSortieDocumentation)="
                                                    showSortieDocumentation(
                                                        flight
                                                    )
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-tertiary btn-delete btn-xs"
                                                    [disabled]="
                                                        working || locked
                                                    "
                                                    (click)="deleteFlight(i)"
                                                    tooltip="Remove flight"
                                                    placement="top"
                                                >
                                                    <span
                                                        class="fal fa-trash-alt"
                                                    ></span>
                                                </button>
                                            </flight-edit>
                                        </div>
                                    </ng-container>

                                    <enhanced-help
                                        screenName="mission-completion-dialogue"
                                        componentName="flights"
                                    >
                                    </enhanced-help>

                                    <enhanced-help
                                        screenName="mission-completion-dialogue"
                                        componentName="flightLogs"
                                    >
                                    </enhanced-help>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Documentation -->
                    <div id="documentation">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{ disabled: locked }"
                            >
                                <span>Documentation</span>
                                <div></div>
                            </h4>

                            <div
                                class="form-group input-content"
                                [ngClass]="{ disabled: locked }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Attachments
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="row top-buffer"
                                        *ngIf="mission.id && !locked"
                                    >
                                        <div class="col-xs-12">
                                            <attachment-list
                                                [attachmentsHandler]="
                                                    attachmentsHandler
                                                "
                                                [allowUploads]="true"
                                                [allowDelete]="true"
                                                [allowEdit]="true"
                                                [parentPromiseTracker]="
                                                    workTracker
                                                "
                                                permissions
                                            >
                                            </attachment-list>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <div
                                class="form-group input-content"
                                [ngClass]="{ disabled: locked }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Pre Mission
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <mission-documentation
                                        *ngIf="mission.id && !locked"
                                        formControlName="documentation"
                                        [organisation]="organisation"
                                        [documentationStorage]="
                                            documentationStorage
                                        "
                                        [attachmentsHandler]="
                                            attachmentsHandler
                                        "
                                        groupTitle="Mission Completion"
                                        step="pre-mission"
                                    ></mission-documentation>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <div
                                class="form-group input-content"
                                [ngClass]="{
                                    disabled: locked
                                }"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Post Mission
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <mission-documentation
                                        *ngIf="mission.id && !locked"
                                        formControlName="documentation"
                                        [organisation]="organisation"
                                        [documentationStorage]="
                                            documentationStorage
                                        "
                                        [attachmentsHandler]="
                                            attachmentsHandler
                                        "
                                        groupTitle="Mission Completion"
                                        step="post-mission"
                                    ></mission-documentation>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="mission-completion-dialogue"
                                componentName="documentation"
                            >
                            </enhanced-help>
                        </div>
                    </div>

                    <!-- Serviceability -->
                    <div
                        id="Serviceability"
                        *ngIf="
                            canUseServiceabilitySignoffs ||
                            (canUseMaintenance &&
                                missionCompletionForm.controls.maintenanceLogs
                                    .value.length)
                        "
                    >
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{ disabled: locked }"
                            >
                                <span *ngIf="canUseServiceabilitySignoffs"
                                    >Serviceability and&nbsp;</span
                                >
                                <span>Maintenance</span>
                                <div></div>
                            </h4>
                            <ng-container
                                *ngFor="
                                    let rpa of serviceabilitySignoffs.controls
                                "
                            >
                                <serviceability-signoff
                                    *ngIf="
                                        canUseServiceabilitySignoffs && !locked
                                    "
                                    [form]="rpa"
                                    [missionId]="mission.id"
                                    [maintenanceLogs]="
                                        missionCompletionForm.controls
                                            .maintenanceLogs
                                    "
                                    (serviceabilityUpdated)="
                                        onServiceabilityUpdate($event)
                                    "
                                >
                                </serviceability-signoff>

                                <enhanced-help
                                    screenName="mission-completion-dialogue"
                                    componentName="serviceability"
                                >
                                </enhanced-help>

                                <serviceability-maintenance-report
                                    [maintenanceLogs]="
                                        missionCompletionForm.controls
                                            .maintenanceLogs
                                    "
                                    [serviceabilitySignoffs]="
                                        missionCompletionForm.controls
                                            .serviceability
                                    "
                                    [organisation]="organisation"
                                    [form]="rpa"
                                >
                                </serviceability-maintenance-report>

                                <enhanced-help
                                    screenName="mission-completion-dialogue"
                                    componentName="maintenanceLogs"
                                >
                                </enhanced-help>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default" type="submit" [disabled]="working">
                Save
            </button>

            <button
                class="btn btn-default"
                type="button"
                (click)="saveAndClose()"
                [disabled]="working"
            >
                Save and Close
            </button>

            <div
                *ngIf="mission.availableActions.canFinalise && !isSubmittable()"
                class="left-buffer right-buffer"
                style="display: inline-block"
                @collapse
                tooltip="Allows invalid or incomplete mission records to be finalised"
                placement="top"
            >
                <div class="checkbox">
                    <label class="text-danger">
                        <input
                            type="checkbox"
                            name="hasIdentifier"
                            [(ngModel)]="allowFinalisingIncomplete"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="working"
                        />
                        <span class="cr"
                            ><i class="cr-icon glyphicon glyphicon-ok"></i>
                        </span>
                        Finalise incomplete mission record
                    </label>
                </div>
            </div>

            <button
                *ngIf="mission.availableActions.canFinalise"
                class="btn btn-primary"
                type="button"
                [disabled]="
                    working || (!isSubmittable() && !allowFinalisingIncomplete)
                "
                (click)="finalise()"
            >
                Finalise
            </button>

            <button
                *ngIf="!mission.availableActions.canFinalise"
                class="btn btn-primary"
                type="button"
                [disabled]="working || !isSubmittable()"
                (click)="submit()"
            >
                Submit for Finalisation
            </button>
        </div>
    </form>
</ng-container>
