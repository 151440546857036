import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatureAndGroup, FeatureGroup, MapFeature } from '../interfaces';
@Component({
    selector: 'map-marker-toolbar',
    templateUrl: './map-marker-toolbar.component.html',
    styleUrls: ['./map-marker-toolbar.component.scss']
})
export class MapMarkerToolbarComponent {
    @Input()
    public feature: MapFeature;

    @Input()
    public featureGroup: FeatureGroup;

    @Input()
    public allowEditCoordinates = false;

    @Output()
    public onFeatureChange: EventEmitter<FeatureAndGroup>;

    @Output()
    public onFeatureEdit: EventEmitter<FeatureAndGroup>;

    @Output()
    public onFeatureDelete: EventEmitter<FeatureAndGroup>;

    constructor() {
        this.onFeatureEdit = new EventEmitter<FeatureAndGroup>();
        this.onFeatureDelete = new EventEmitter<FeatureAndGroup>();
        this.onFeatureChange = new EventEmitter<FeatureAndGroup>();
    }

    public onFeatureNameChange() {
        this.onFeatureChange.emit(this.getFeatureAndGroup());
    }

    public onFeatureCoordinatesChange() {
        this.onFeatureChange.emit(this.getFeatureAndGroup());
    }

    public onFeatureOffsetChange() {
        if (this.feature.properties.lineOffsetMeters < 1) {
            this.feature.properties.lineOffsetMeters = 1;
        }
        this.onFeatureChange.emit(this.getFeatureAndGroup());
    }

    public onFeatureEditClick() {
        this.onFeatureEdit.emit(this.getFeatureAndGroup());
    }

    public onFeatureDeleteClick() {
        this.onFeatureDelete.emit(this.getFeatureAndGroup());
    }

    public onCategoryChange() {
        this.onFeatureChange.emit(this.getFeatureAndGroup());
    }

    private getFeatureAndGroup(): FeatureAndGroup {
        return {
            feature: this.feature,
            groupId: this.featureGroup.id
        };
    }
}
