import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'inline-edits',
    templateUrl: './inline-edit.component.html'
})
export class InlineEdit implements DoCheck {
    @Input() labelName: string;
    @Input() inlineLabel: boolean = false;
    @Input() model: string;
    @Input() isNewForm: boolean;
    @Input() canEdit: boolean;
    @Input() popoverTitle: string;
    @Output() change = new EventEmitter<string>();
    @Output() save = new EventEmitter<any>();
    lastValue: any;
    newModel: any;
    popover: PopoverDirective;

    ngDoCheck() {
        if (this.lastValue !== this.model) {
            this.lastValue = this.model;
            this.newModel = this.lastValue;
        }
    }

    onChangeModel(model: any) {
        this.change.emit(model);
    }

    openPopover(pop: PopoverDirective) {
        this.popover = pop;
    }

    saveData() {
        this.save.emit(this.newModel);
        this.popover.hide();
    }
}
