import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FieldValidationIndicator } from './field-validation-indicator/field-validation-indicator.component';
import { FormHtmlValidationDirective } from './form-html-validation.directive';
import { ValidationMessagesComponent } from './validation-messages.component';

@NgModule({
    imports: [CommonModule, TooltipModule, FormsModule, ReactiveFormsModule],
    declarations: [
        FieldValidationIndicator,
        ValidationMessagesComponent,
        FormHtmlValidationDirective
    ],
    exports: [
        FieldValidationIndicator,
        ValidationMessagesComponent,
        FormHtmlValidationDirective
    ]
})
export class ValidationModule {}
