import { BehaviorSubject, Observable } from 'rxjs';

export interface LinkDto {
    id: number;
    title: string;
    url: string;
}

export interface LinkDetails {
    title: string;
    url: string;
}

export interface ILinksService {
    loadLinks(sectionId: number);

    // get links$(): Observable<LinkDto[]>;
    createLink(sectionId: number, details: LinkDetails): Observable<LinkDto>;
    updateLink(
        sectionId: number,
        linkId: number,
        details: LinkDetails
    ): Observable<LinkDto>;

    deleteLink(sectionId: number, linkId: number): Observable<boolean>;
}

export enum LinkAction {
    noticeAirworthiness = 'AIRWORTHINESS',
    generalTask = 'GENERAL_TASK'
    // sample for mission
    // mission = 'mission',
}
