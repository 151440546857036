import { Injectable } from '@angular/core';
import {
    DO_NOTHING,
    SaveReferralCommand,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { ReferralService } from '../referral.service';
import { LoginManager } from './login-manager';
import * as moment from 'moment-timezone';

const REFERRAL_COOKIE = 'referral';

@Injectable({
    providedIn: 'root'
})
export class ReferralManagerService {
    referral: {
        referralUrl?: string;
        landingUrl?: string;
        firstVisit?: string;
        signupUrl?: string;
        inviteCode?: string;
        referralSent: boolean;
    } = {
        referralSent: false
    };

    isImpersonating: boolean;

    constructor(
        private cookieService: CookieService,
        private referralService: ReferralService,
        private loginManager: LoginManager,
        userService: UserService
    ) {
        this.loadCookie();

        this.landing();

        // When the user authenticates save the referral
        userService.userChange$
            .pipe(
                filter(() => !loginManager.isImpersonating()),
                filter(c => c.type === UserStatus.LOGGED_IN),
                take(1),
                mergeMap(() => this.saveReferral())
            )
            .subscribe(DO_NOTHING);
    }

    private loadCookie() {
        this.isImpersonating = this.loginManager.isImpersonating();
        const cookieData = this.cookieService.get(REFERRAL_COOKIE);

        if (!this.isImpersonating) {
            try {
                this.referral =
                    cookieData != null && cookieData !== ''
                        ? JSON.parse(cookieData)
                        : {};
            } catch {
                this.referral = { referralSent: false };
            }
        } else {
            this.referral = { referralSent: false };
        }
    }

    /**
     * Record landing information into a cookie if not impersonating a user.
     */
    private landing() {
        if (this.referral.firstVisit != null || this.isImpersonating) {
            return;
        }

        // We need to use URLSearchParams here since the state service isn't always ready by the time the router needs this
        const inviteCode = new URLSearchParams(window.location.search).get(
            'inviteCode'
        );

        this.referral = {
            firstVisit: moment().toISOString(),
            landingUrl: document.location.href,
            referralUrl: document.referrer,
            referralSent: false,
            inviteCode
        };

        this.cookieService.set(
            REFERRAL_COOKIE,
            JSON.stringify(this.referral),
            30
        );
    }

    private saveReferral() {
        if (
            this.referral != null &&
            this.referral.firstVisit != null &&
            !this.referral.referralSent &&
            !this.isImpersonating
        ) {
            const userReferral: SaveReferralCommand = {
                inviteCode: this.referral.inviteCode,
                referral: {
                    firstVisit: this.referral.firstVisit,
                    landingUrl: this.referral.landingUrl,
                    referralUrl: this.referral.referralUrl,
                    signupUrl: null
                }
            };
            this.referral.referralSent = true;
            return this.referralService.saveUserReferral(userReferral).pipe(
                tap(() => this.cookieService.delete(REFERRAL_COOKIE)),
                take(1)
            );
        } else {
            return of(null);
        }
    }
}
