import { Component, OnInit } from '@angular/core';
import {
    CurrentOrganisation,
    SelectedWidgets,
    WidgetStatus,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { Angulartics2 } from 'angulartics2';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'widget-selection',
    templateUrl: './widget-selection.component.html',
    styleUrls: ['./widget-selection.component.scss']
})
export class WidgetSelectionComponent implements OnInit {
    widgets: WidgetStatus;

    currentOrganisation: CurrentOrganisation;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private sharedService: WorkspaceStateService,
        private angulartics2: Angulartics2
    ) {}

    ngOnInit(): void {
        this.sharedService.availableWidgets$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((widgets: WidgetStatus) => {
                this.widgets = widgets;
            });

        this.sharedService.currentOrganisation$
            .pipe(
                filter<CurrentOrganisation>(
                    o => o.type === 'organisation_loaded'
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.currentOrganisation = organisation;
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    onMenuChanged() {
        this.angulartics2.eventTrack.next({
            action: 'toggle',
            properties: {
                category: 'sidebar'
            }
        });

        const selectedWidgets: SelectedWidgets = Object.keys(
            this.widgets
        ).reduce(
            (acc, w) => ({ ...acc, [w]: this.widgets[w].selected }),
            {} as SelectedWidgets
        );

        this.sharedService.changeSelectedWidgets(selectedWidgets);
    }

    public scrollTo(widgetId: string) {
        this.sharedService.scrollTo(widgetId);

        this.angulartics2.eventTrack.next({
            action: 'scrollTo',
            properties: {
                category: 'sidebar',
                label: widgetId
            }
        });
    }
}
