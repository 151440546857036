import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
    CraftDto,
    OrganisationRelationshipDto,
    OrganisationRelationshipService,
    PersonsOrganisationDto,
    ShareResourceCommand,
    UnshareResourceCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OrganisationRelationshipsUiService } from './organisation-relationships-ui.service';

@Component({
    selector: 'shared-rpa',
    template: `<h5 [ngClass]="{ readonly: working }">Shared RPAs</h5>
        <div class="input-group">
            <ng-select
                [items]="
                    organisationRpaList$
                        | async
                        | sortBy: 'nickname'
                        | filterOutById: relationship.sharedRpas
                "
                bindLabel="nickname"
                bindValue="id"
                appendTo="body"
                [formControl]="addRpa"
                placeholder="Select RPA to share with {{
                    relationship.childOrganisation.name
                }}"
                [readonly]="working"
                [clearable]="false"
            >
            </ng-select>
            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working || !addRpa.valid"
                    (click)="shareRpa()"
                >
                    Share
                </button>
            </span>
        </div>
        <div>
            <div
                *ngFor="let rpa of relationship.sharedRpas | sortBy: 'nickname'"
                class="top-buffer"
            >
                {{ rpa | formatRpa }}

                <button
                    type="button"
                    class="btn btn-tertiary btn-delete"
                    tooltip="Remove sharing of RPA"
                    placement="top"
                    (click)="unshareRpa(rpa)"
                >
                    <span class="fal fa-trash-alt"></span>
                </button>

                <br />
            </div>
        </div>`
})
export class SharedRpa implements OnInit, OnDestroy {
    @Input() organisation: PersonsOrganisationDto;
    @Input() relationship: OrganisationRelationshipDto;

    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    organisationRpaList$: Observable<CraftDto[]>;

    addRpa = new FormControl(undefined, [Validators.required]);

    constructor(
        public workTracker: WorkTracker,
        private logging: FlyFreelyLoggingService,
        private angulartics2: Angulartics2,
        private organisationRelationshipService: OrganisationRelationshipService,
        private organisationRelationshipsUi: OrganisationRelationshipsUiService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit(): void {
        this.organisationRpaList$ =
            this.organisationRelationshipsUi.organisationRpaList$.pipe(
                map(res =>
                    res.filter(
                        item => item.organisationId === this.organisation.id
                    )
                )
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    shareRpa() {
        const cmd: ShareResourceCommand = {
            resourceId: this.addRpa.value
        };
        this.organisationRelationshipService
            .shareRpa(this.organisation.id, this.relationship.id, cmd)
            .subscribe(results => {
                this.addRpa.setValue(undefined);
                this.angulartics2.eventTrack.next({
                    action: 'share-rpa',
                    properties: {
                        category: 'shared-resources',
                        label: results.id
                    }
                });
            })
            .add(this.workTracker.createTracker());
    }

    unshareRpa(rpa: CraftDto) {
        const cmd: UnshareResourceCommand = { resourceId: rpa.id };
        this.organisationRelationshipService
            .unshareRpa(this.organisation.id, this.relationship.id, cmd)
            .subscribe(result => {
                this.angulartics2.eventTrack.next({
                    action: 'unshare-rpa',
                    properties: {
                        category: 'shared-resources',
                        label: result.id
                    }
                });
            })
            .add(this.workTracker.createTracker());
    }
}
