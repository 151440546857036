import { FormControl } from '@angular/forms';

export class ValidationService {
    static getValidatorErrorMessage(
        validatorName: string,
        validatorValue?: any
    ) {
        if (validatorName === 'passwordStrength') {
            return validatorValue;
        }
        const config = {
            required: 'This field is required',
            notEmptyArray: 'At least one entry is required',
            minlength: `Minimum length ${validatorValue.requiredLength}`,
            maxlength: `Maximum length ${validatorValue.requiredLength}`,
            notEqual: 'Passwords do not match',
            NotNull: 'This field is required',
            NotInUse: 'The email address is already in use',
            Size: 'This field is not a valid length',
            StrongPassword: 'This password is too weak',
            email: 'This is not a valid email',
            Email: 'This is not a valid email',
            Invalid: 'The provided value is invalid',
            jsonSchema: 'The JSON provided does not match the expected shape',
            json: 'Provided text is not a valid JSON',
            notHtml: 'The entered text cannot contain HTML code',
            // Adding this for casing differences between a UI error and an API error
            NotHtml: 'The entered text cannot contain HTML code'
        };
        if (config[validatorName] != null) {
            return config[validatorName];
        } else {
            return validatorValue;
        }
    }

    static equalTo(matchControl: string) {
        let control1: FormControl;
        let control2: FormControl;
        return function matchOtherControlValue(control: FormControl) {
            if (!control.parent) {
                return null;
            }
            if (!control1) {
                control1 = control;
                control2 = control.parent.get(matchControl) as FormControl;
                if (!control2) {
                    throw new Error(
                        'equalToValidator(): Second control is not found in parent group'
                    );
                }
                control2.valueChanges.subscribe(() => {
                    control1.updateValueAndValidity();
                });
            }
            if (!control2) {
                return null;
            }
            if (control2.value !== control1.value) {
                return { notEqual: true };
            }
            return null;
        };
    }
}
