import { FeatureProperties } from '../constants';

export default [
    // DEFAULT STYLES
    {
        id: 'gl-draw-polygon-fill-inactive',
        type: 'fill',
        filter: [
            'all',
            ['==', ['get', 'active'], 'false'],
            ['==', ['geometry-type'], 'Polygon'],
            ['!=', ['get', 'mode'], 'static']
        ],
        paint: {
            'fill-color': '#3bb2d0',
            'fill-outline-color': '#3bb2d0',
            'fill-opacity': 0.1
        }
    },
    {
        id: 'gl-draw-polygon-fill-active',
        type: 'fill',
        filter: [
            'all',
            ['==', ['get', 'active'], 'true'],
            ['==', ['geometry-type'], 'Polygon']
        ],
        paint: {
            'fill-color': '#fbb03b',
            'fill-outline-color': '#fbb03b',
            'fill-opacity': 0.1
        }
    },
    {
        id: 'gl-draw-polygon-midpoint',
        type: 'circle',
        filter: [
            'all',
            ['==', ['geometry-type'], 'Point'],
            ['==', ['get', 'meta'], 'midpoint']
        ],
        paint: {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    {
        id: 'gl-draw-polygon-stroke-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', ['get', 'active'], 'false'],
            ['==', ['geometry-type'], 'Polygon'],
            ['!=', ['get', 'mode'], 'static']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#3bb2d0',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-polygon-stroke-active',
        type: 'line',
        filter: [
            'all',
            ['==', ['get', 'active'], 'true'],
            ['==', ['geometry-type'], 'Polygon']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-line-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', ['get', 'active'], 'false'],
            ['==', ['geometry-type'], 'LineString'],
            ['!=', ['get', 'mode'], 'static']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#3bb2d0',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-line-active',
        type: 'line',
        filter: [
            'all',
            ['==', ['geometry-type'], 'LineString'],
            ['==', ['get', 'active'], 'true']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', ['get', 'meta'], 'vertex'],
            ['==', ['geometry-type'], 'Point'],
            ['!=', ['get', 'mode'], 'static']
        ],
        paint: {
            'circle-radius': 5,
            'circle-color': '#fff'
        }
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', ['get', 'meta'], 'vertex'],
            ['==', ['geometry-type'], 'Point'],
            ['!=', ['get', 'mode'], 'static']
        ],
        paint: {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    {
        id: 'gl-draw-point-point-stroke-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', ['get', 'active'], 'false'],
            ['==', ['geometry-type'], 'Point'],
            ['==', ['get', 'meta'], 'feature'],
            ['!=', ['get', 'mode'], 'static']
        ],
        paint: {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fff'
        }
    },
    {
        id: 'gl-draw-point-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', ['get', 'active'], 'false'],
            ['==', ['geometry-type'], 'Point'],
            ['==', ['get', 'meta'], 'feature'],
            ['!=', ['get', 'mode'], 'static']
        ],
        paint: {
            'circle-radius': 3,
            'circle-color': '#3bb2d0'
        }
    },
    {
        id: 'gl-draw-point-stroke-active',
        type: 'circle',
        filter: [
            'all',
            ['==', ['geometry-type'], 'Point'],
            ['==', ['get', 'active'], 'true'],
            ['!=', ['get', 'meta'], 'midpoint']
        ],
        paint: {
            'circle-radius': 7,
            'circle-color': '#fff'
        }
    },
    {
        id: 'gl-draw-point-active',
        type: 'circle',
        // filter: ['==', ['geometry-type'], 'Point'],
        filter: [
            'all',
            ['==', ['geometry-type'], 'Point'],
            ['!=', ['get', 'meta'], 'midpoint'],
            ['==', ['get', 'active'], 'true'],
            ['!', ['has', 'user_icon-image']]
        ],
        paint: {
            'circle-radius': 5,
            'circle-color': '#fbb03b'
        }
    },
    {
        id: 'gl-draw-point-active-user',
        type: 'symbol',
        filter: ['all', ['has', 'user_icon-image']],
        layout: {
            'icon-image': ['get', 'user_icon-image'],
            'icon-size': 0.75,
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom'
        }
    },
    {
        id: 'gl-draw-polygon-fill-static',
        type: 'fill',
        filter: [
            'all',
            ['==', ['get', 'mode'], 'static'],
            ['==', ['geometry-type'], 'Polygon']
        ],
        paint: {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.1
        }
    },
    {
        id: 'gl-draw-polygon-stroke-static',
        type: 'line',
        filter: [
            'all',
            ['==', ['get', 'mode'], 'static'],
            ['==', ['geometry-type'], 'Polygon']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#404040',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-line-static',
        type: 'line',
        filter: [
            'all',
            ['==', ['get', 'mode'], 'static'],
            ['==', ['geometry-type'], 'LineString']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#404040',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-point-static',
        type: 'circle',
        filter: [
            'all',
            ['==', ['get', 'mode'], 'static'],
            ['==', ['geometry-type'], 'Point']
        ],
        paint: {
            'circle-radius': 5,
            'circle-color': '#404040'
        }
    },

    // CUSTOM STYLES

    // measurement label
    {
        id: 'gl-draw-measurement-label',
        type: 'symbol',
        filter: [
            '==',
            ['get', 'user_meta'],
            FeatureProperties.MEASUREMENT_LABEL
        ],
        layout: {
            'text-field': '{user_measurement}',
            'text-anchor': 'left',
            'text-offset': [1, 0],
            'text-size': 22
        },
        paint: {
            'text-color': 'rgba(0, 0, 0, 1)',
            'text-halo-color': 'rgba(255, 255, 255, 1)',
            'text-halo-width': 3,
            'icon-opacity': 1,
            'text-halo-blur': 1
        }
    },
    // measurement radius line
    {
        id: 'gl-draw-measurement-radius-line',
        type: 'symbol',
        filter: [
            '==',
            ['get', 'user_meta'],
            FeatureProperties.MEASUREMENT_RADIUS_LINE
        ],
        layout: {
            'text-field': '{user_measurement}',
            'text-anchor': 'center',
            'text-offset': [1, 0],
            'text-size': 22,
            'text-allow-overlap': true
        },
        paint: {
            'text-color': 'rgba(0, 0, 0, 1)',
            'text-halo-color': 'rgba(255, 255, 255, 1)',
            'text-halo-width': 3,
            'text-halo-blur': 1
        }
    }
];
