<ngx-loading
    [show]="show"
    [config]="displayConfig"
    [template]="displayTemplate"
></ngx-loading>

<ng-template #displayTemplate>
    <div class="operation-failed-alert alert alert-danger">
        <div style="text-align: center">
            <h4 class="color-red">{{ formatStatus() }}</h4>
            <p class="color-red no-margin">
                {{ getMessage() }}
            </p>
        </div>
    </div>
</ng-template>
