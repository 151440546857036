/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Creates an unsubmitted maintenance log
 */
export interface CreateMaintenanceLogCommand {
    /**
     * The type of equipment that this maintenance request is being made on
     */
    resourceCategory: CreateMaintenanceLogCommand.ResourceCategory;
    /**
     * The ID of the resource that maintenance is requested on
     */
    resourceId: number;
    /**
     * The reason that this request has been put in
     */
    reasonForRequest: string;
    /**
     * Used to determine if this equipment should be grounded
     */
    safeToUse?: boolean;
    /**
     * The type of maintenance log being requested
     */
    maintenanceLogType: CreateMaintenanceLogCommand.MaintenanceLogType;
    /**
     * The organisation that owns this record
     */
    organisationId: number;
    /**
     * The mission, if any, that this log was created as part of
     */
    missionId?: number;
}
export namespace CreateMaintenanceLogCommand {
    export type ResourceCategory =
        | 'CRAFT'
        | 'BATTERY_SET'
        | 'BATTERY'
        | 'EQUIPMENT'
        | 'UNLISTED_RPA';
    export const ResourceCategory = {
        CRAFT: 'CRAFT' as ResourceCategory,
        BATTERY_SET: 'BATTERY_SET' as ResourceCategory,
        BATTERY: 'BATTERY' as ResourceCategory,
        EQUIPMENT: 'EQUIPMENT' as ResourceCategory,
        UNLISTED_RPA: 'UNLISTED_RPA' as ResourceCategory
    };
    export type MaintenanceLogType =
        | 'DEFECT'
        | 'SCHEDULED'
        | 'IN_FIELD'
        | 'INSPECTION'
        | 'CONFIGURATION_CHANGE'
        | 'OTHER';
    export const MaintenanceLogType = {
        DEFECT: 'DEFECT' as MaintenanceLogType,
        SCHEDULED: 'SCHEDULED' as MaintenanceLogType,
        IN_FIELD: 'IN_FIELD' as MaintenanceLogType,
        INSPECTION: 'INSPECTION' as MaintenanceLogType,
        CONFIGURATION_CHANGE: 'CONFIGURATION_CHANGE' as MaintenanceLogType,
        OTHER: 'OTHER' as MaintenanceLogType
    };
}
