import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AuthorityTypeNominatedRoleDto,
    observeFormControl,
    PersonDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { personSearch } from 'libs/flyfreely/src/lib/tools/searchFunctions';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NominatedPersonnelDataService } from './nominated-personnel-data.service';
import { FormEntry } from './nominated-personnel.component';

@Component({
    template: `<td></td>
        <td
            *ngIf="
                role.allowOrganisationScope && dataService.hasChildOrganisations
            "
        >
            <ng-select
                [formControl]="person.controls.organisationId"
                [readonly]="working || locked"
                [items]="dataService.sharedTo$ | async"
                bindLabel="name"
                bindValue="id"
                appendTo="body"
                placeholder="Select an organisation scope"
                [clearable]="false"
            ></ng-select>
        </td>
        <td>
            <ng-select
                [formControl]="person.controls.personId"
                [readonly]="working || locked"
                [items]="personnel$ | async"
                [searchFn]="personSearch"
                bindLabel="firstName"
                bindValue="id"
                appendTo="body"
                placeholder="Select a person"
                [groupBy]="groupBy"
                [clearable]="false"
                required
            >
                <ng-template ng-label-tmp let-item="item">
                    <span>{{ item | formatPerson: true }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <span>{{ item | formatPerson: true }}</span>
                </ng-template>
            </ng-select>
        </td>
        <td>
            <input
                type="text"
                [formControl]="person.controls.startDate"
                placeholder="Select a date"
                class="form-control"
                bsDatepicker
                [bsConfig]="{ adaptivePosition: false }"
                placement="bottom"
                [container]="'body'"
                [readonly]="working || locked"
                required
            />
        </td>
        <td>
            <input
                type="text"
                [formControl]="person.controls.expiryDate"
                placeholder="Select a date"
                class="form-control"
                bsDatepicker
                [bsConfig]="{ adaptivePosition: false }"
                placement="bottom"
                [container]="'body'"
                [readonly]="working || locked"
            />
        </td>
        <td *ngIf="role.canApprove">
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        [formControl]="person.controls.canApproveOwnMissions"
                        [readonly]="working || locked"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                </label>
            </div>
        </td>
        <td *ngIf="role.hasManageMissions">
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        [formControl]="person.controls.canManageMissions"
                        [readonly]="working || locked"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                </label>
            </div>
        </td>
        <td class="actions">
            <button
                type="button"
                class="btn btn-tertiary"
                [disabled]="working || locked || person.pristine"
                (click)="save.emit()"
                tooltip="Save"
                placement="left"
            >
                <span class="fal fa-save"></span>
            </button>
            <button
                type="button"
                class="btn btn-tertiary btn-delete"
                [disabled]="working || locked"
                (click)="remove.emit()"
                tooltip="Delete"
                placement="left"
            >
                <span class="fal fa-trash-alt"></span>
            </button>
        </td>`,
    selector: 'tr[nominated-personnel-row]'
})
export class NominatedPersonnelRow {
    @Output()
    remove = new EventEmitter<void>();

    @Output()
    save = new EventEmitter<void>();

    @Input()
    person: FormGroup<FormEntry>;

    @Input()
    role: AuthorityTypeNominatedRoleDto;

    /**
     * Becomes locked when other rows are being edited
     */
    @Input()
    locked: boolean;

    working: boolean;

    personnel$ = new ReplaySubject<PersonDto[]>(1);

    groupBy: string | undefined;

    private ngUnsubscribe$ = new Subject<void>();

    personSearch = personSearch;

    constructor(
        public dataService: NominatedPersonnelDataService,
        private workTracker: WorkTracker
    ) {
        this.groupBy = dataService.hasChildOrganisations
            ? 'organisationName'
            : undefined;
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        observeFormControl<number>(this.person.controls.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisationId =>
                this.personnel$.next(
                    this.dataService.personnel[
                        organisationId == null ? 0 : organisationId
                    ]
                )
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
