import { AttachmentHandler } from './attachments';
import {
    HistoricalFlightDto,
    UpdateHistoricalFlightCommand,
    CreateHistoricalFlightCommand
} from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class PersonHistoricalLogBookService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findHistoricalFlights(managingOrganisationId: number, personId: number) {
        return this.http.get<HistoricalFlightDto[]>(
            `${this.baseUrl}/webapi/historicalFlights`,
            {
                params: httpParamSerializer({
                    personId,
                    managingOrganisationId
                })
            }
        );
    }

    updateHistoricalFlight(
        flightId: number,
        command: UpdateHistoricalFlightCommand
    ) {
        return this.http
            .put<HistoricalFlightDto[]>(
                `${this.baseUrl}/webapi/historicalFlights/${flightId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    createHistoricalFlight(command: CreateHistoricalFlightCommand) {
        return this.http
            .post<HistoricalFlightDto>(
                `${this.baseUrl}/webapi/historicalFlights`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    removeHistoricalFlight(flightId: number, managingOrganisationId: number) {
        return this.http
            .delete<HistoricalFlightDto>(
                `${this.baseUrl}/webapi/historicalFlights/${flightId}?managingOrganisationId=${managingOrganisationId}`
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    attachmentHandler(
        personId: number,
        managingOrganisationId: number
    ): AttachmentHandler {
        return new AttachmentHandler(
            this.http,
            `/webapi/historicalFlights/attachments/${personId}`,
            false,
            managingOrganisationId
        );
    }
}
