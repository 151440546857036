import { Injectable } from '@angular/core';
import {
    BatterySetDto,
    BatterySetService,
    CraftDto,
    CraftService,
    EquipmentDto,
    EquipmentService,
    OrganisationRelationshipDto,
    OrganisationRelationshipService,
    OrganisationSubscriptionDto,
    SubscriptionService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class OrganisationRelationshipsUiService {
    private organisationRpaListSubject = new ReplaySubject<CraftDto[]>(1);
    organisationRpaList$ = this.organisationRpaListSubject.asObservable();

    private organisationBatterySetListSubject = new ReplaySubject<
        BatterySetDto[]
    >(1);
    organisationBatterySetList$ = this.organisationBatterySetListSubject.asObservable();

    private organisationEquipmentListSubject = new ReplaySubject<
        EquipmentDto[]
    >(1);
    organisationEquipmentList$ = this.organisationEquipmentListSubject.asObservable();

    private childrenSubject = new ReplaySubject<OrganisationRelationshipDto[]>(
        1
    );
    children$ = this.childrenSubject.asObservable();

    private subscriptionSubject = new ReplaySubject<
        OrganisationSubscriptionDto
    >(1);
    subscription$ = this.subscriptionSubject.asObservable();

    private ngUnsubscribe$ = new Subject<void>();
    private organisationId: number;

    constructor(
        private workTracker: WorkTracker,
        private craftService: CraftService,
        private organisationRelationshipService: OrganisationRelationshipService,
        private subscriptionService: SubscriptionService,
        private batterySetService: BatterySetService,
        private equipmentService: EquipmentService
    ) {
        organisationRelationshipService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(relationship => this.refreshRelationships());
    }

    setup(organisationId: number) {
        this.organisationId = organisationId;
        this.refreshRelationships();
        this.findOrganisationRpas();
        this.findOrganisationBatterySets();
        this.findOrganisationEquipment();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshRelationships() {
        if (this.organisationId == null) {
            return;
        }

        const currentTime = moment(moment.now()).format(
            'YYYY-MM-DD[T]HH:mm:ss'
        );

        combineLatest([
            this.organisationRelationshipService.findChildRelationships(
                this.organisationId
            ),
            this.subscriptionService.findCurrentSubscriptions(
                this.organisationId
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([results, subscriptions]) => {
                this.subscriptionSubject.next(
                    subscriptions.find(
                        s =>
                            s.status ===
                            OrganisationSubscriptionDto.Status.ACTIVE
                    )
                );
                this.childrenSubject.next(
                    results.filter(child => {
                        return child.endTime > currentTime || !child.endTime;
                    })
                );
            })
            .add(this.workTracker.createTracker());
    }

    private findOrganisationRpas() {
        this.craftService
            .findCrafts(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpas => {
                this.organisationRpaListSubject.next(rpas);
            });
    }

    private findOrganisationBatterySets() {
        this.batterySetService
            .findBatterySets(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(batterySetList => {
                this.organisationBatterySetListSubject.next(batterySetList);
            });
    }

    private findOrganisationEquipment() {
        this.equipmentService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(equipmentList => {
                this.organisationEquipmentListSubject.next(equipmentList);
            });
    }
}
