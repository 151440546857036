import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator
} from '@angular/forms';
import {
    DO_NOTHING,
    FlyFreelyError,
    OperatingCategoryValuesDtoEndorsementReference,
    operatingConditionsTypeLookup,
    operatingConditionsValueLookup,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface ApplyEndorsementActions {
    add: (
        endorsementId: number
    ) => Observable<OperatingCategoryValuesDtoEndorsementReference[]>;
    remove: (
        endorsementId: number
    ) => Observable<OperatingCategoryValuesDtoEndorsementReference[]>;
}

@Component({
    selector: 'apply-endorsements',
    template: `
        <div class="horizontal-container" [formGroup]="formGroup">
            <div
                *ngFor="
                    let type of available$ | async;
                    let first = first;
                    let last = last
                "
                class="vertical-container contents"
                [ngClass]="{ 'right-buffer': !last, 'left-buffer': !first }"
            >
                {{ operatingConditionsTypeLookup[type.type] }}
                <label
                    *ngFor="let value of type.values"
                    class="btn btn-xs btn-pill"
                    btnCheckbox
                    role="button"
                    [formControlName]="value.id"
                    ><ng-container *ngIf="type.type != 'RPA_MTOW'">{{
                        operatingConditionsValueLookup[value.value.value]
                    }}</ng-container>
                    <ng-container *ngIf="type.type == 'RPA_MTOW'"
                        ><{{ value.value.value | formatMTOW }}</ng-container
                    ></label
                >
            </div>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ApplyEndorsements),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ApplyEndorsements),
            multi: true
        }
    ]
})
export class ApplyEndorsements implements ControlValueAccessor, Validator {
    @Input()
    availableEndorsementList: OperatingCategoryValuesDtoEndorsementReference[];

    operatingConditionsTypeLookup = operatingConditionsTypeLookup;
    operatingConditionsValueLookup = operatingConditionsValueLookup;

    available$ = new ReplaySubject<
        OperatingCategoryValuesDtoEndorsementReference[]
    >(1);
    private applied$ = new ReplaySubject<number[]>(1);

    onChange: (arg: any) => void = DO_NOTHING;
    onTouched: (arg: any) => void = DO_NOTHING;
    onValidate: (arg: any) => void = DO_NOTHING;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;

    formGroup: FormGroup;

    constructor() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        if (this.availableEndorsementList == null) {
            throw new Error('availableEndorsementList input is not set');
        }
        this.formGroup = new FormGroup(
            this.availableEndorsementList.reduce(
                (acc, e) => ({
                    ...acc,
                    ...e.values.reduce(
                        (acc2, v) => ({
                            ...acc2,
                            [v.id]: new FormControl(undefined)
                        }),
                        {}
                    )
                }),
                {} as { [id: number]: FormControl }
            )
        );

        combineLatest([this.available$, this.applied$])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([available, applied]) => {
                const availableList = available.reduce(
                    (acc, e) => acc.concat(e.values.map(v => v.id)),
                    []
                );
                this.formGroup.setValue(
                    availableList.reduce(
                        (acc, v) => ({
                            ...acc,
                            [v]: applied.indexOf(v) !== -1
                        }),
                        {}
                    )
                );
            });

        this.formGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(v => this.onChange(Object.keys(v).filter(k => v[k])));
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('availableEndorsementList' in changes) {
            this.available$.next(this.availableEndorsementList);
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.available$.complete();
        this.applied$.complete();
    }

    registerOnChange(fn: (arg: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: (arg: any) => void) {
        this.onTouched = fn;
    }

    registerOnValidatorChange(fn: () => void) {
        this.onValidate = fn;
    }

    writeValue(endorsementList: number[]) {
        if (endorsementList == null) {
            return;
        }
        this.applied$.next(endorsementList);
    }

    validate(_: FormControl) {
        return null;
    }
}
