import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WorkSpaceComponent } from '../workspace.component';
import { ReportingRoutingModule } from '@flyfreely-portal-ui/reporting';
import { OutstandingTasksRoutingModule } from '@flyfreely-portal-ui/outstanding-tasks';
import { MissionViewRoutingModule } from '@flyfreely-portal-ui/mission-view';
import { ManageDashboardRoutingModule } from '@flyfreely-portal-ui/manage-dashboard';
import { WorkspaceModule } from '../workspace.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        WorkspaceModule,
        RouterModule.forChild([
            {
                path: 'home',
                component: WorkSpaceComponent,
                loadChildren: () => ManageDashboardRoutingModule,
                data: {
                    enhancedHelpComponentIdentifier: 'manage-dashboard'
                }
            },
            {
                path: 'missions',
                component: WorkSpaceComponent,
                loadChildren: () => MissionViewRoutingModule,
                data: {
                    enhancedHelpComponentIdentifier: 'missions-dashboard'
                }
            },
            {
                path: 'reporting',
                component: WorkSpaceComponent,
                loadChildren: () => ReportingRoutingModule,
                data: {
                    enhancedHelpComponentIdentifier: 'reporting-dashboard'
                }
            },
            {
                path: 'tasks',
                component: WorkSpaceComponent,
                loadChildren: () => OutstandingTasksRoutingModule,
                data: {
                    enhancedHelpComponentIdentifier: 'tasks-dashboard'
                }
            }
        ])
    ],
    exports: [RouterModule]
})
export class WorkspaceRoutingModule {}
