/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface ServiceabilitySignoffCommand { 
    /**
     * The ID of the RPA if it exists in the system
     */
    rpaId?: number;
    /**
     * The reason for the unserviceable state. Required if unserviceable.
     */
    reason?: string;
    /**
     * What is the status of the serviceability
     */
    serviceability: ServiceabilitySignoffCommand.Serviceability;
}
export namespace ServiceabilitySignoffCommand {
    export type Serviceability = 'SERVICEABLE' | 'UNSERVICEABLE';
    export const Serviceability = {
        SERVICEABLE: 'SERVICEABLE' as Serviceability,
        UNSERVICEABLE: 'UNSERVICEABLE' as Serviceability
    };
}



