import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    ApproversMissionApprovalDto,
    MissionApprovalUpdateCommand,
    RejectMissionApprovalCommand,
    RequestersMissionApprovalDto,
    RevokeMissionApprovalCommand,
    SimpleAuthorityDto
} from '../model/api';
import { NameValue } from '../model/ui';
import { MissionService } from './mission.service';

export function isApprovalResolved(
    status: RequestersMissionApprovalDto.Status
) {
    return (
        status === RequestersMissionApprovalDto.Status.APPROVED ||
        status === RequestersMissionApprovalDto.Status.REJECTED
    );
}

export const missionApprovalStatuses = [
    { value: 'CANCELLED', name: 'Cancelled' },
    { value: 'DRAFT', name: 'Draft' },
    { value: 'PENDING', name: 'Pending' },
    { value: 'BEING_REVIEWED', name: 'In Progress' },
    { value: 'APPROVED', name: 'Approved' },
    { value: 'REJECTED', name: 'Rejected' },
    { value: 'REVOKED', name: 'Revoked' },
    { value: 'ACCEPTED', name: 'Accepted' }
];

export const approvalSteps = [
    { value: 'pre-submit', name: 'Mission Planning' }
];

/**
 * This service is a facade for many of the mission approval related services.
 * @ngInject
 */
@Injectable({
    providedIn: 'root'
})
export class MissionApprovalService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private missionService: MissionService
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findMissionApprovals(missionId: number) {
        return this.http.get(`${this.baseUrl}/webapi/missionApprovals`, {
            params: new HttpParams().set('missionId', missionId.toString())
        });
    }

    findMissionApproval(approvalId: number) {
        return this.http.get<ApproversMissionApprovalDto>(
            `${this.baseUrl}/webapi/missionApprovals/${approvalId}`
        );
    }

    createMissionApproval(missionId: number) {
        return this.http
            .post<RequestersMissionApprovalDto>(
                `${this.baseUrl}/webapi/missionApprovals`,
                { missionId: missionId }
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    submitForApproval(approvalId: number, formResponses: any) {
        if (!formResponses) {
            formResponses = [];
        }

        const url = `/webapi/missionApprovals/${approvalId}/submit`;
        return this.http
            .put<RequestersMissionApprovalDto>(`${this.baseUrl}${url}`, {
                formResponses: formResponses
            })
            .pipe(tap(() => this.changeSource.next()));
    }

    cancelApproval(approvalId: number) {
        const url = `/webapi/missionApprovals/${approvalId}/cancel`;
        return this.http
            .put(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    updateApproval(approvalId: number, update: MissionApprovalUpdateCommand) {
        return this.http
            .put<ApproversMissionApprovalDto>(
                `${this.baseUrl}/webapi/missionApprovals/${approvalId}`,
                update
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    approveApproval(
        approvalId: number,
        missionApprovalFields: MissionApprovalUpdateCommand
    ) {
        const url = `/webapi/missionApprovals/${approvalId}/approve`;
        return firstValueFrom(
            this.http
                .put<ApproversMissionApprovalDto>(
                    `${this.baseUrl}${url}`,
                    missionApprovalFields
                )
                .pipe(tap(() => this.missionService.notifyMissionChanged()))
        );
    }

    acceptApproval(approvalId: number) {
        const url = `/webapi/missionApprovals/${approvalId}/accept`;
        return this.http
            .put<ApproversMissionApprovalDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.missionService.notifyMissionChanged()));
    }

    rejectApproval(approvalId: number, command: RejectMissionApprovalCommand) {
        const url = `/webapi/missionApprovals/${approvalId}/reject`;
        return this.http
            .put<ApproversMissionApprovalDto>(`${this.baseUrl}${url}`, command)
            .toPromise()
            .then(() => this.missionService.notifyMissionChanged());
    }

    revokeApproval(
        approvalId: number,
        missionRevokeFields: RevokeMissionApprovalCommand
    ) {
        const url = `/webapi/missionApprovals/${approvalId}/revoke`;
        return this.http
            .put<ApproversMissionApprovalDto>(
                `${this.baseUrl}${url}`,
                missionRevokeFields
            )
            .toPromise()
            .then(() => this.missionService.notifyMissionChanged());
    }

    startReviewing(approvalId: number) {
        const url = `/webapi/missionApprovals/${approvalId}/review`;
        return this.http
            .put(`${this.baseUrl}${url}`, null)
            .toPromise()
            .then(() => this.missionService.notifyMissionChanged());
    }

    stopReviewing(approvalId: number) {
        const url = `/webapi/missionApprovals/${approvalId}/stopReview`;
        return this.http
            .put(`${this.baseUrl}${url}`, null)
            .toPromise()
            .then(() => this.missionService.notifyMissionChanged());
    }

    findApprovingAuthorities(
        organisationId: number,
        missionTypeId: number,
        date: string
    ) {
        let params = new HttpParams().set(
            'organisationId',
            organisationId.toString()
        );
        if (missionTypeId) {
            params = params.set('missionTypeId', missionTypeId.toString());
        }
        if (date) {
            params = params.set('date', date);
        }

        return this.http.get<SimpleAuthorityDto[]>(
            `${this.baseUrl}/webapi/missionApprovals/approvers`,
            { params: params }
        );
    }

    getStatuses(): NameValue[] {
        return missionApprovalStatuses;
    }

    getApprovalSteps() {
        return approvalSteps;
    }
}
