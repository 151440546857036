import { Component, Input } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceActivitySummary,
    MaintenanceLogSummary,
    MaintenanceService,
    UserService,
    WorkTracker,
    compareDates
} from '@flyfreely-portal-ui/flyfreely';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaintenanceDialogues } from '../maintenance-dialogues.service';

@Component({
    selector: 'maintenance-history',
    template: `
        <enhanced-help
            helpTitle="Maintenance History Overview"
            componentName="maintenance-history-overview"
            [compact]="true"
        >
        </enhanced-help>
        <table
            class="table top-buffer"
            *ngIf="
                maintenanceActivities != null &&
                maintenanceActivities.length > 0
            "
        >
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Date Submitted</th>
                    <th>Reason for Request</th>
                    <th>Time Completed</th>
                    <th>Activity Status</th>
                    <th>Outcome</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let activity of maintenanceActivities
                            | slice: startItem:endItem
                    "
                >
                    <td>
                        <a (click)="show(activity)"
                            >{{
                                activity.maintenanceLogType
                                    | formatMaintenanceLogType
                            }}
                            {{
                                activity.maintenanceLogStatus
                                    | formatMaintenanceStatus
                            }}</a
                        >
                    </td>
                    <td>
                        <a (click)="show(activity)">{{
                            activity.requestTime | formatDateTime
                        }}</a>
                    </td>
                    <td>{{ activity.reasonForRequest }}</td>
                    <td>{{ activity.endTime | formatDateTime }}</td>
                    <td>
                        {{ activity.status | formatMaintenanceActivityStatus }}
                    </td>
                    <td>{{ activity.outcome | formatMaintenanceOutcome }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4" class="text-center">
                        <pagination
                            [totalItems]="maintenanceActivities.length"
                            [(ngModel)]="currentPage"
                            (pageChanged)="onPageChanged($event)"
                            class="pagination-sm"
                            [itemsPerPage]="itemsPerPage"
                            [boundaryLinks]="true"
                        ></pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
        <loading-indicator [isLoading]="working"></loading-indicator>

        <div
            class="alert alert-info"
            role="alert"
            *ngIf="
                maintenanceActivities != null &&
                maintenanceActivities.length == 0
            "
        >
            There are no maintenance logs
        </div>
    `
})
export class MaintenanceHistory {
    @Input() organisationId: number;
    @Input() resourceType: string;
    @Input() resourceId: number;

    maintenanceActivities: {
        maintenanceLogType: MaintenanceLogSummary.MaintenanceLogType;
        maintenanceLogStatus: MaintenanceLogSummary.Status;
        maintenanceLogId: number;
        requestTime: string;
        reasonForRequest: string;
        endTime: string;
        
        status: MaintenanceActivitySummary.Status;
        outcome: MaintenanceLogSummary.Outcome;
    }[];

    itemsPerPage: number = 10;
    currentPage: number = 0;
    textSearch:string | null

    startItem = 0;
    endItem = 0;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private maintenanceService: MaintenanceService,
        private maintenanceDialogues: MaintenanceDialogues,
        private userService: UserService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshMaintenanceLogs();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshMaintenanceLogs() {
        if (!this.resourceId || !this.resourceType || !this.organisationId) {
            return;
        }

        this.maintenanceService
            .findMaintenanceLogs(
                this.organisationId,
                this.textSearch,
                this.resourceType,
                this.resourceId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                maintenanceLogs => {
                    this.maintenanceActivities = maintenanceLogs.reduce(
                        (acc, log) =>
                            acc.concat(
                                log.activities.length > 0
                                    ? log.activities.map(a => ({
                                          maintenanceLogType:
                                              log.maintenanceLogType,
                                          maintenanceLogStatus: log.status,
                                          maintenanceLogId: log.id,
                                          requestTime: log.requestTime,
                                          reasonForRequest:
                                              log.reasonForRequest,
                                          outcome: log.outcome,
                                          status: a.status,
                                          endTime: a.endTime,
                                          organisationId: log.organisationId
                                      }))
                                    : {
                                          maintenanceLogType:
                                              log.maintenanceLogType,
                                          maintenanceLogStatus: log.status,
                                          maintenanceLogId: log.id,
                                          requestTime: log.requestTime,
                                          reasonForRequest:
                                              log.reasonForRequest,
                                          outcome: log.outcome,
                                          organisationId: log.organisationId
                                      }
                            ),
                        []
                    );
                    this.maintenanceActivities.sort((a, b) =>
                        compareDates(b.requestTime, a.requestTime)
                    );

                    this.onPageChanged({
                        page: 1,
                        itemsPerPage: this.itemsPerPage
                    });
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while fetching maintenance logs: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    onPageChanged(newPage: PageChangedEvent) {
        this.currentPage = newPage.page;

        this.startItem = this.itemsPerPage * (this.currentPage - 1);
        this.endItem = this.itemsPerPage * this.currentPage;
    }

    show(log: { maintenanceLogId: number; organisationId: number }) {
        this.maintenanceDialogues.showMaintenanceDetails(
            log.organisationId,
            // this.userService
            //     .findUsersOrganisations()
            //     .find(o => o.id === log.organisationId),
            log.maintenanceLogId
        );
    }
}
