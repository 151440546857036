import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { AuthorityModule } from 'libs/authorities/src/lib/authorities.module';
import { BulkUploadsModule } from 'libs/bulk-uploads/src/lib/bulk-uploads.module';
import { CommonDialoguesModule } from 'libs/common-dialogues/src/lib/common-dialogues.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlightHistoryModule } from 'libs/flight-history/src/lib/flight-history.module';
import { AuthorityReportService } from '@flyfreely-portal-ui/flyfreely';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { WorkflowModule } from 'libs/workflows/src/lib/workflows.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ngfModule } from 'angular-file';
import { MaintenanceModule } from 'libs/maintenance/src/lib/maintenance.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { RpaAddDialogue } from './add-dialogue/rpa-add-dialogue.component';
import { RpaDialogue } from './details-dialogue/rpa-dialogue.component';
import { RpaEdit } from './details/rpa-edit/rpa-edit.component';
import { RpaView } from './details/rpa-view/rpa-view.component';
import { ModelSpecification } from './model-specification/model-specification.component';
import { AuthorityEditDialogueComponent } from './registration/authority-edit-dialogue/authority-edit-dialogue.component';
import { JurisdictionSelectionComponent } from './registration/jurisdiction/jurisdiction-selection.component';
import { AuthorityEditComponent } from './registration/rpa-authority-edit/authority-edit.component';
import { AuthorityListComponent } from './registration/rpa-authority-list/authority-list.component';
import { RpaDialoguesService } from './rpa-dialogues.service';
import { RpaRegistersStatusComponent } from './rpa-registers/rpa-registers-status.component';
import { RpaReportsDialogue } from './rpa-reports-dialogue/rpa-reports-dialogue.component';
import { RpaTypeEditDialogue } from './rpa-types/edit/rpa-type-edit.component';
import { RpaTypeList } from './rpa-types/list/rpa-type-list.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';
import { AssociatedResourcesComponent } from './associated-resources/associated-resources.component';
import { NotesViewComponent } from './rpa-notes/notes-view/notes-view.component';
import { NotesEditComponent } from './rpa-notes/notes-edit/notes-edit.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // ngx-bootstrap
        TooltipModule,
        ModalModule.forChild(),
        TabsModule,
        BsDropdownModule,
        BsDatepickerModule,
        ButtonsModule,
        // ngx-formly
        ReactiveFormsModule,
        FormlyModule,
        FormlyBootstrapModule,
        // ng-select
        NgSelectModule,
        ngfModule,
        ToastrModule,
        SharedUiModule,
        ResourceUiModule,
        DocumentationModule,
        WorkflowModule,
        FlightHistoryModule,
        AttachmentsModule,
        LoadingIndicatorModule,
        AuthorityModule,
        MaintenanceModule,
        CommonDialoguesModule,
        ValidationModule,
        FlyFreelyTableModule,
        BulkUploadsModule,
        EnhancedHelpModule,
        FeaturesModule,
        MaterialModule,
        AnalyticsModule
    ],
    providers: [RpaDialoguesService, AuthorityReportService],
    bootstrap: [],
    declarations: [
        RpaAddDialogue,
        RpaDialogue,
        RpaReportsDialogue,
        RpaView,
        RpaEdit,
        AuthorityListComponent,
        AuthorityEditComponent,
        JurisdictionSelectionComponent,
        AuthorityEditDialogueComponent,
        ModelSpecification,
        RpaTypeList,
        RpaTypeEditDialogue,
        RpaRegistersStatusComponent,
        AssociatedResourcesComponent,
        NotesViewComponent,
        NotesEditComponent
    ],
    exports: [
        JurisdictionSelectionComponent,
        AuthorityEditDialogueComponent,
        RpaTypeList
    ]
})
export class RpaModule {}
