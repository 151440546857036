import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {
    CreateEquipmentCommand,
    EquipmentModelService,
    EquipmentService,
    EquipmentTypeDto,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameValue,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, from, switchMap, tap } from 'rxjs';
import { groupBy, mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { EquipmentUploadService } from './equipment-upload.service';

const NOT_LISTED = 'NOT_LISTED';

@Component({
    templateUrl: './equipment-add.component.html',
    providers: [EquipmentUploadService]
})
export class EquipmentAddDialogue implements OnInit, OnDestroy {
    @Input() organisationId: number;

    equipmentForm: FormGroup;
    equipmentMakeGroup: FormGroup;
    equipmentModelGroup: FormGroup;

    equipmentCategories: NameValue[];
    equipmentMakes: NameValue[];
    equipmentModels: NameValue[];
    // isLockselection: boolean = true;
    makeListed: boolean;
    modelListed: boolean;
    makeLocked: boolean;
    modelLocked: boolean;
    selectedEquipmentModelID: number = 0;

    IMG_URL: string;

    private workTracker = new WorkTracker();
    working: boolean = false;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private logging: FlyFreelyLoggingService,
        private equipmentService: EquipmentService,
        constants: FlyFreelyConstants,
        private equipmentModelService: EquipmentModelService,
        private equipmentUploadService: EquipmentUploadService,
        private userService: UserService,
        private modal: BsModalRef<EquipmentAddDialogue>
    ) {
        this.modelListed = true;
        this.makeListed = true;
        this.makeLocked = true;
        this.modelLocked = true;
        this.IMG_URL = constants.IMG_URL;
        this.equipmentCategories = equipmentService.getEquipmentCategories();

        this.equipmentMakeGroup = new FormGroup(
            {
                makeSelect: new FormControl(undefined),
                makeText: new FormControl(undefined)
            },
            {
                validators: [EquipmentMakeValidator('makeSelect', 'makeText')]
            }
        );
        this.equipmentModelGroup = new FormGroup(
            {
                modelSelect: new FormControl(undefined),
                modelText: new FormControl(undefined)
            },
            {
                validators: [
                    EquipmentModelValidator('modelSelect', 'modelText')
                ]
            }
        );
        this.equipmentForm = new FormGroup({
            equipmentCategory: new FormControl(undefined, [
                Validators.required
            ]),
            make: this.equipmentMakeGroup,
            model: this.equipmentModelGroup,
            currentFirmwareVersion: new FormControl(undefined),
            manufacturerSerialNumber: new FormControl(undefined, [
                Validators.required
            ]),
            name: new FormControl(undefined, [Validators.required]),
            nfcId: new FormControl(undefined),
            isDummy: new FormControl(undefined, [Validators.required])
        });
    }

    ngOnInit(): void {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    close() {
        this.modal.hide();
    }

    onEquipmentTypeSelectionChange(event: NameValue) {
        this.resetEquipmentMakeSelection();
        this.updateLocks(event);

        this.makeListed = true;
        this.modelListed = true;

        this.equipmentMakes = [];
        this.selectedEquipmentModelID = 0;
        if (event) {
            this.loadEquipmentMakes(event.value);
        }
    }

    private resetEquipmentMakeSelection() {
        this.equipmentMakeGroup.controls.makeSelect.reset();
    }

    private updateLocks(event: NameValue) {
        const isEventNull = event == null;
        this.makeLocked = isEventNull;
        this.modelLocked = isEventNull;
    }

    private loadEquipmentMakes(category: string) {
        this.equipmentModelService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(equipmentModels => {
                const filteredModels = this.filterEquipmentModels(
                    equipmentModels,
                    category
                );
                this.equipmentMakes = this.mapToEquipmentMakes(filteredModels);
            })
            .add(this.workTracker.createTracker());
    }

    private mapToEquipmentMakes(
        equipmentModels: EquipmentTypeDto[]
    ): { name: string; value: string }[] {
        const equipmentMakes = [];

        from(equipmentModels)
            .pipe(
                groupBy(model => model.make),
                mergeMap(group => group.pipe(toArray())),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(groupedModels => {
                equipmentMakes.push({
                    name: groupedModels[0].make,
                    value: groupedModels[0].make
                });
            });

        equipmentMakes.push({ name: 'Not Listed', value: NOT_LISTED });
        return equipmentMakes;
    }

    private filterEquipmentModels(
        equipmentModels: EquipmentTypeDto[],
        category: string
    ): EquipmentTypeDto[] {
        return equipmentModels.filter(
            model => model.equipmentCategory === category
        );
    }

    equipmentModelOnChange(event: NameValue | any) {
        if (event !== undefined && event.value === NOT_LISTED) {
            this.modelListed = false;
            this.makeListed = true;
            const equip = this.equipmentMakes.find(
                f => f.value === this.equipmentMakeGroup.value.makeSelect
            ).name;
            this.makeLocked = true;
            this.equipmentMakeGroup.controls.makeText.setValue(equip);

            this.selectedEquipmentModelID = 0;
        } else if (event === undefined) {
            this.modelListed = true;
            this.makeListed = true;
            this.makeLocked = false;
            this.equipmentModelGroup.controls.modelText.reset();
            this.equipmentModelGroup.controls.modelSelect.reset();
        } else {
            this.modelListed = true;
            this.makeListed = true;
            this.equipmentMakeGroup.controls.makeText.setValue(undefined);
            this.makeLocked = false;
            if (event !== undefined && event.equipmentModelId !== undefined) {
                this.selectedEquipmentModelID = event.equipmentModelId;
            }
        }
    }

    equipmentMakeOnChange(event: NameValue) {
        this.selectedEquipmentModelID = 0;
        if (event !== undefined && event.value === NOT_LISTED) {
            this.makeListed = false;
            this.modelListed = false;
            this.equipmentModelGroup.controls.modelSelect.reset();
            this.modelLocked = true;
        } else {
            this.makeListed = true;
            this.modelListed = true;
            this.modelLocked = false;
            this.equipmentModelGroup.controls.modelText.reset();
            this.equipmentModelGroup.controls.modelSelect.reset();

            this.equipmentModels = [];
            if (event) {
                this.loadEquipmentModels(event.value);
            }
        }
    }

    private loadEquipmentModels(make: string) {
        this.equipmentModels = [];
        this.equipmentModelService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(equipmentModel => {
                const filteredModels = this.filterModelsByCategoryAndMake(
                    equipmentModel,
                    make
                );
                this.equipmentModels =
                    this.mapToEquipmentModelOptions(filteredModels);
            })
            .add(this.workTracker.createTracker());
    }

    private filterModelsByCategoryAndMake(
        equipmentModels: EquipmentTypeDto[],
        make: string
    ): EquipmentTypeDto[] {
        const category = this.equipmentForm.controls.equipmentCategory.value;
        return equipmentModels
            .filter(model => model.equipmentCategory === category)
            .filter(model => model.make === make);
    }

    private mapToEquipmentModelOptions(filteredModels: EquipmentTypeDto[]): {
        name: string;
        value: string;
        equipmentModelId: number | null;
    }[] {
        return filteredModels
            .map(model => ({
                name: model.model,
                value: model.model,
                equipmentModelId: model.id
            }))
            .concat({
                name: 'Not Listed',
                value: NOT_LISTED,
                equipmentModelId: null
            });
    }

    clearEquipmentType(event: any) {
        this.equipmentMakeGroup.controls.makeSelect.reset();
        this.makeLocked = true;
        this.equipmentModelGroup.controls.modelSelect.reset();
        this.modelLocked = true;
    }

    submit() {
        const values = this.equipmentForm.value;
        this.prepareAndCreateEquipment(values)
            .subscribe({
                next: () => this.close(),
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Failed to create equipment model or equipment: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    addAnother() {
        const values = this.equipmentForm.value;
        this.prepareAndCreateEquipment(values)
            .subscribe({
                next: () => this.resetForm(),
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Failed to create equipment model or equipment: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    private createEquipment(
        values: { [key: string]: any },
        equipmentTypeId?: number
    ) {
        const command: CreateEquipmentCommand = {
            currentFirmwareVersion: values.currentFirmwareVersion,
            equipmentTypeId: equipmentTypeId ?? this.selectedEquipmentModelID,
            manufacturerSerialNumber: values.manufacturerSerialNumber,
            name: values.name,
            nfcId: values.nfcId,
            organisationId: this.organisationId,
            isDummy: values.isDummy,
            initialComponentList: []
        };

        return this.equipmentService.create(command).pipe(
            tap(() => this.logging.success('Equipment created successfully')),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    private prepareAndCreateEquipment(values: { [key: string]: any }) {
        const isCustomMakeOrModel =
            values.make.makeSelect === NOT_LISTED ||
            (this.modelLocked && values.model.modelSelect === null) ||
            values.model.modelSelect === NOT_LISTED;
        if (isCustomMakeOrModel) {
            const equipmentModelDto = {
                equipmentCategory: values.equipmentCategory,
                make: values.make.makeText,
                model: values.model.modelText,
                organisationId: this.organisationId
            };
            return this.equipmentModelService.create(equipmentModelDto).pipe(
                switchMap(equipmentTypeDto =>
                    this.createEquipment(values, equipmentTypeDto.id)
                ),
                takeUntil(this.ngUnsubscribe$)
            );
        }
        return this.createEquipment(values);
    }

    resetForm() {
        this.equipmentForm.reset();
        this.makeLocked = true;
        this.modelLocked = true;
        this.modelListed = true;
        this.makeListed = true;
    }

    bulkUpload() {
        this.equipmentUploadService.showBulkUpload(this.organisationId);
        this.equipmentUploadService.doneImporting$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.modal.hide());
    }
}

export function EquipmentMakeValidator(
    makeSelectCtrlName: string,
    makeTextCtrlName: string
): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
        if (!group || !group.controls) {
            return;
        }
        const equipmentSelectValue = group.controls[makeSelectCtrlName]
            ? group.controls[makeSelectCtrlName].value
            : null; // to get value in input tag
        const equipmentTextValue = group.controls[makeTextCtrlName]
            ? group.controls[makeTextCtrlName].value
            : null;

        if (equipmentSelectValue && equipmentSelectValue !== '') {
            if (
                equipmentSelectValue === NOT_LISTED &&
                (equipmentTextValue == null || equipmentTextValue === '')
            ) {
                return { requiredField: true };
            }
        } else {
            return { requiredField: true };
        }
    };
}

export function EquipmentModelValidator(
    modelSelectCtrlName: string,
    modelTextCtrlName: string
): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
        if (!group || !group.controls) {
            return;
        }
        const equipmentSelectValue = group.controls[modelSelectCtrlName]
            ? group.controls[modelSelectCtrlName].value
            : null; // to get value in input tag
        const equipmentTextValue = group.controls[modelTextCtrlName]
            ? group.controls[modelTextCtrlName].value
            : null;

        if (equipmentSelectValue && equipmentSelectValue !== '') {
            if (
                equipmentSelectValue === NOT_LISTED &&
                (equipmentTextValue == null || equipmentTextValue === '')
            ) {
                return { requiredField: true };
            }
        } else if (
            equipmentSelectValue === null &&
            !(equipmentTextValue === null || equipmentTextValue === '')
        ) {
            return;
        } else {
            return { requiredField: true };
        }
    };
}
