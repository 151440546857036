import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BatteryService,
    BatterySetService,
    DO_NOTHING,
    LocationService,
    NotificationDto,
    NotificationsService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BatteryDialoguesService } from 'libs/batteries/src/lib/battery-dialogues.service';
import { EquipmentDialoguesService } from 'libs/equipment/src/lib/equipment-dialogues.service';
import { LocationDialoguesService } from 'libs/locations/src/lib/location-dialogues.service';
import { MaintenanceDialogues } from 'libs/maintenance/src/lib/maintenance-dialogues.service';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { debounceTime } from 'rxjs/operators';

interface NotificationsLoading {
    type: 'LOADING';
}

interface NotificationsLoaded {
    type: 'LOADED';
    notifications: NotificationDto[];
}

@Injectable({
    providedIn: 'root'
})
export class NotificationFeedService {
    private notificationListSubject = new BehaviorSubject<
        NotificationsLoading | NotificationsLoaded
    >({ type: 'LOADING' });
    private numberOfNewSubject = new BehaviorSubject<number>(0);
    notificationsList$ = this.notificationListSubject.asObservable();
    numberOfNew$ = this.numberOfNewSubject.asObservable();

    currentOrganisation: PersonsOrganisationDto;
    notifications: NotificationDto[];
    private websocketConnectionStatus:
        | 'connecting'
        | 'reconnected'
        | 'disconnected';
    private websocketConnected = false;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();

    working: boolean;
    constructor(
        private notificationsService: NotificationsService,
        private missionDialoguesService: MissionDialoguesService,
        private rpaDialoguesService: RpaDialoguesService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private batteryDialoguesService: BatteryDialoguesService,
        private equipmentDialoguesService: EquipmentDialoguesService,
        private locationService: LocationService,
        private locationDialoguesService: LocationDialoguesService,
        private maintenanceDialoguesService: MaintenanceDialogues,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.setWatchers();
    }

    ngOnDestroy() {
        this.notificationListSubject.complete();
        this.numberOfNewSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setCurrentOrganisation(organisation: PersonsOrganisationDto) {
        this.currentOrganisation = organisation;
    }

    // clear out notifications on org switch to prevent populating across orgs until refresh
    onSwitchOrganisation() {
        this.notifications = [];
        this.notificationListSubject.next({ type: 'LOADING' });
        this.numberOfNewSubject.next(0);
    }

    refreshNotifications(startWebsocket = false) {
        if (this.currentOrganisation == null) {
            return;
        }
        this.notificationsService
            .findNotifications(0, this.currentOrganisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: notifications => {
                    this.notifications = notifications.results;
                    this.notificationListSubject.next({
                        type: 'LOADED',
                        notifications: notifications.results
                    });
                    this.numberOfNewSubject.next(
                        notifications.results.filter(n => n.read === false)
                            .length ?? 0
                    );
                    if (startWebsocket) {
                        this.subscribeToNotifications();
                    }
                },
                error: DO_NOTHING
            });
    }

    subscribeToNotifications() {
        if (this.websocketConnected) {
            // Only start the websocket connection the first time the service loads.
            return;
        }
        this.notificationsService
            .setupNotificationStream(this.currentOrganisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(notification => {
                this.websocketConnected = true;
                const existingNotifications =
                    this.notifications.concat(notification);
                this.notifications = existingNotifications;
                this.notificationListSubject.next({
                    type: 'LOADED',
                    notifications: existingNotifications
                });
                this.numberOfNewSubject.next(
                    existingNotifications.filter(n => n.read === false)
                        .length ?? 0
                );
            });
    }

    archiveNotification(notificationId: number) {
        this.notificationsService
            .markNotificationRead(notificationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                if (result.success === true) {
                    this.notifications.find(n => n.id === notificationId).read =
                        true;
                    this.notificationListSubject.next({
                        type: 'LOADED',
                        notifications: this.notifications
                    });
                    this.numberOfNewSubject.next(
                        this.notifications.filter(n => n.read === false)
                            .length ?? 0
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    goToNotificationSubject(notificationId: number) {
        const notification = this.notifications.find(
            n => n.id === notificationId
        );
        const link = notification?.reference ?? '';
        if (link !== '') {
            const component = link.split('/')[0];
            const param =
                link.split('/').length > 1 ? link.split('/')[1] : null;
            switch (component) {
                case 'missions':
                    this.missionDialoguesService.showMissionDetails(
                        parseInt(param, 10),
                        true,
                        this.currentOrganisation
                    );
                    break;

                case 'rpa':
                    this.rpaDialoguesService.showCraftDetails(
                        parseInt(param, 10),
                        this.currentOrganisation.id,
                        true
                    );
                    break;

                case 'batteries':
                    this.batteryService
                        .findBattery(parseInt(param, 10))
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe(battery => {
                            this.batteryDialoguesService.showBatteryDetailsDialogue(
                                battery,
                                this.currentOrganisation
                            );
                        });
                    break;

                case 'battery-sets':
                    this.batterySetService
                        .findBatterySet(parseInt(param, 10))
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe(batterySet => {
                            this.batteryDialoguesService.showBatterySetDetailsDialogue(
                                batterySet,
                                this.currentOrganisation
                            );
                        });
                    break;

                case 'equipment':
                    this.equipmentDialoguesService.showEquipmentDetails(
                        parseInt(param, 10),
                        this.currentOrganisation,
                        true
                    );
                    break;

                case 'locations':
                    this.locationService
                        .findLocation(parseInt(param, 10))
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe(location => {
                            this.locationDialoguesService.showLocation(
                                location,
                                this.currentOrganisation.id
                            );
                        });
                    break;

                case 'maintenance':
                    this.maintenanceDialoguesService.showMaintenanceDetails(
                        this.currentOrganisation.id,
                        parseInt(param, 10)
                    );
                    break;

                case 'orgadmin':
                    // TODO router test
                    this.router.navigate([component], {
                        queryParams: {
                            tab: param ?? 'details',
                            ...this.activatedRoute.snapshot.queryParams
                        }
                    });
                    break;

                case 'profile':
                case 'userprofile':
                    // TODO ROUTER test
                    this.router.navigateByUrl('/userprofile');
                    break;

                default:
                    if (link !== '') {
                        try {
                            this.router.navigate([link], {
                                queryParams: {
                                    organisationId: this.currentOrganisation.id
                                }
                            });
                        } catch (e: any) {
                            // the route may not exist
                            console.warn({ e });
                        }
                    }
                    break;
            }
        }
    }

    setWatchers() {
        this.notificationsService.connectionChange$
            .pipe(
                // Add a small debounce for instabilities during reconnections.
                debounceTime(200),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(status => {
                if (
                    status === 'reconnected' &&
                    this.websocketConnectionStatus === 'disconnected'
                ) {
                    this.websocketConnectionStatus = status;
                    this.refreshNotifications(false);
                } else {
                    this.websocketConnectionStatus = status;
                }
            });
    }
}
