import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    LocationDetailsDto,
    LocationDto,
    LocationService,
    PersonsOrganisationDto,
    PreferencesService,
    WorkTracker,
    hasAnyPermission
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { LocationDialoguesService } from 'libs/locations/src/lib/location-dialogues.service';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import { firstValueFrom, of, Subject } from 'rxjs';
import { concatMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';

export const LOCATIONS_IDENTIFIER = 'locations';

@Component({
    selector: 'locations-widget',
    templateUrl: './locations-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class LocationsWidgetComponent implements OnInit, OnDestroy, Widget {
    organisation: PersonsOrganisationDto;

    public availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    public working: boolean;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    canEdit: boolean;
    canAdd: boolean;
    userPreferences: TableSetupUserPreferences;

    locations: LocationDto[];

    constructor(
        private locationService: LocationService,
        private locationDialoguesService: LocationDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private preferencesService: PreferencesService,
        private workspaceStateService: WorkspaceStateService
    ) {
        this.availableColumns = [
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: false,
                linkFunction: (location: LocationDto) =>
                    this.showLocation(location)
            }
        ];
        this.tableConfig = {
            limit: 10,
            actions: [
                {
                    action: (item: LocationDto) => this.cloneLocation(item),
                    icon: 'fal fa-clone',
                    tooltip: 'Clone Location'
                }
            ],
            bulkActions: [
                {
                    name: 'Delete',
                    action: (locations: LocationDto[]) =>
                        commonDialoguesService.showConfirmationDialogue(
                            'Delete Selected Locations',
                            `Are you sure you want to delete these ${locations.length} locations?`,
                            'Delete',
                            () =>
                                firstValueFrom(
                                    of(...locations).pipe(
                                        concatMap(l =>
                                            locationService
                                                .deleteLocation(l.id)
                                                .pipe(
                                                    tap({
                                                        error: error =>
                                                            this.logging.error(
                                                                error,
                                                                `Could not delete ${l.name}`
                                                            )
                                                    })
                                                )
                                        ),
                                        toArray()
                                    )
                                )
                        )
                }
            ],
            limitSelection: [10, 25, 50, 100]
        };

        this.columnSorting = null;
        this.selectedColumns = [];
        this.tableSearch = null;
    }

    ngOnInit(): void {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshLocations();
                this.refreshPermissions();
            });

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        // this.refreshLocations();
        // this.refreshPermissions();
        this.locationService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshLocations());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        this.canEdit = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.LOCATION_EDIT
        );
        this.canAdd = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.LOCATION_ADD
        );
        this.tableConfig.actions = this.canAdd
            ? [
                  {
                      action: (item: LocationDto) => this.cloneLocation(item),
                      icon: 'fal fa-clone',
                      tooltip: 'Clone Location'
                  }
              ]
            : [];
    }

    private refreshLocations() {
        this.locationService
            .findLocations(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: locations => {
                    this.locations = locations;
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing locations: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'locationsWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    newLocation() {
        this.locationDialoguesService.newLocation(
            this.organisation.id,
            LocationDetailsDto.Type.TEMPLATE,
            true
        );
    }

    cloneLocation(location: LocationDto) {
        this.locationDialoguesService.cloneLocation(
            location,
            this.organisation
        );
    }

    editLocation(location: LocationDto) {
        this.locationDialoguesService.editLocation(
            location.id,
            this.organisation.id,
            true
        );
    }

    showLocation(location: LocationDto) {
        this.locationDialoguesService.showLocation(
            location,
            this.organisation.id
        );
    }

    deleteLocation(location: LocationDto) {
        this.commonDialoguesService.showConfirmationDialogue(
            'Delete this location?',
            `Are you sure you wish to delete this location: ${location.name}`,
            'Delete',
            () => this.locationService.deleteLocation(location.id).toPromise()
        );
    }

    showLocationCloneDialogue() {
        this.locationDialoguesService.showMissionLocationCloneDialogue(
            this.organisation.id
        );
    }
}

export const locationsWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: LOCATIONS_IDENTIFIER,
    component: LocationsWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.LOCATION_LIST
        )
};
