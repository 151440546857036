/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RpaTypeDto } from './rpaTypeDto';


/**
 * The list of flights performed by the RP
 */
export interface PilotMissionFlightSummary { 
    /**
     * The mission ID that these flights occurred in
     */
    missionId: number;
    /**
     * The time of day the flight occured at
     */
    timeOfDay: PilotMissionFlightSummary.TimeOfDay;
    /**
     * The the visual contact between the RP and RPA
     */
    visualLineOfSight: PilotMissionFlightSummary.VisualLineOfSight;
    /**
     * The date/time the flights occurred on
     */
    date: string;
    /**
     * The flight ID
     */
    sortieId: number;
    /**
     * The flight number within this mission
     */
    number: number;
    /**
     * The duration of flight, calculated or specified, in seconds
     */
    duration: number;
    /**
     * The ID of the RPA used on this flight
     */
    rpaId: number;
    rpaModel?: RpaTypeDto;
    /**
     * The name of the location of the flight
     */
    locationName?: string;
}
export namespace PilotMissionFlightSummary {
    export type TimeOfDay = 'DAY' | 'NIGHT';
    export const TimeOfDay = {
        DAY: 'DAY' as TimeOfDay,
        NIGHT: 'NIGHT' as TimeOfDay
    };
    export type VisualLineOfSight = 'VLOS' | 'EVLOS' | 'BVLOS';
    export const VisualLineOfSight = {
        VLOS: 'VLOS' as VisualLineOfSight,
        EVLOS: 'EVLOS' as VisualLineOfSight,
        BVLOS: 'BVLOS' as VisualLineOfSight
    };
}


