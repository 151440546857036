<div>
    <enhanced-help
        componentName="flight-history-summary"
        [compact]="true"
    ></enhanced-help>
    <ng-container *ngIf="history">
        <table class="table">
            <tr>
                <th></th>
                <th>Flights</th>
                <th>Flight Time</th>
            </tr>
            <tr>
                <th>Initial</th>
                <td>{{ history.initialFlightCount }}</td>
                <td>{{ history.initialFlightTime | formatDuration  | defaultTo }}</td>
            </tr>
            <tr>
                <th>
                    Logged
                    <span *ngIf="history.dateOfLastMission != null"
                        >(last mission
                        {{ history.dateOfLastMission | formatDate }})</span
                    >
                </th>
                <td>{{ history.loggedFlightCount }}</td>
                <td>{{ history.loggedFlightTime | formatDuration  | defaultTo }}</td>
            </tr>
            <tr style="border-top: 1px solid">
                <th>Total</th>
                <td>{{ history.totalFlightCount }}</td>
                <td>{{ history.totalFlightTime | formatDuration  | defaultTo }}</td>
            </tr>
            <tr *ngIf="history.unassignedFlightLogCount !== 0 || history.unassignedFlightLogDuration !== 0">
                <th><i>Unassigned Flight Logs</i></th>
                <td><i>{{ history.unassignedFlightLogCount }}</i></td>
                <td><i>{{ history.unassignedFlightLogDuration | formatDuration  | defaultTo }}</i></td>
            </tr>
        </table>
    </ng-container>
</div>
