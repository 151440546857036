<div class="modal-header no-icon">
    <h3 class="modal-title">Billing Address and Tax Details</h3>
    <button type="button" (click)="hide()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <div class="horizontal-container">
            <div class="contents fill">
                <formly-form
                    *ngIf="addressFields != null"
                    [form]="addressForm"
                    [fields]="addressFields"
                    [options]="options"
                    [model]="workingAddress"
                ></formly-form>
            </div>
            <div class="contents fill">
                <formly-form
                    *ngIf="taxFields != null"
                    [form]="taxForm"
                    [fields]="taxFields"
                    [options]="options"
                    [model]="workingTax"
                ></formly-form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            type="submit"
            [disabled]="addressForm?.invalid || working"
        >
            Continue
        </button>
    </div>
</form>
