import { Component, forwardRef, Input } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormGroup,
    FormControl
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const DURATION_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DurationComponent),
    multi: true
};

@Component({
    selector: 'duration',
    templateUrl: './duration.component.html',
    providers: [DURATION_CONTROL_VALUE_ACCESSOR]
})
export class DurationComponent implements ControlValueAccessor {
    @Input() showHour: boolean;
    @Input() showMinute: boolean;
    @Input() showSecond: boolean;

    @Input() readonly: boolean;
    @Input() clearable: boolean = true;

    formGroup: FormGroup;

    model: number;
    hours: number;
    minutes: number;
    seconds: number;
    onChange = Function.prototype;
    onTouched = Function.prototype;

    private ngUnsubscribe$: Subject<void> = new Subject();

    ngOnInit() {
        this.formGroup = new FormGroup({
            hours: new FormControl(undefined),
            minutes: new FormControl(undefined),
            seconds: new FormControl(undefined)
        });

        this.formGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => this.updateDuration());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    writeValue(value: any): void {
        this.model = value || 0;
        this.setupControl(this.model);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private setupControl(duration: number) {
        this.formGroup.patchValue({
            hours: this.getHours(duration),
            minutes: this.getMinutes(duration),
            seconds: this.getSeconds(duration)
        });
    }

    private getHours(duration: number) {
        if (duration == null) {
            return null;
        }
        const hours = duration / 60 / 60;
        return this.showHour ? (hours ? Math.floor(hours) : 0) : 0;
    }

    private getMinutes(duration: number) {
        if (duration == null) {
            return null;
        }
        const minutes = this.showHour ? (duration % 3600) / 60 : duration / 60;
        return minutes ? Math.floor(minutes) : 0;
    }

    private getSeconds(duration: number) {
        if (duration == null) {
            return null;
        }
        const minutes = Math.floor(duration / 60);
        const seconds = duration - minutes * 60;
        return seconds ? seconds : 0;
    }

    private calculateDuration() {
        const { hours, minutes, seconds } = this.formGroup.value;

        if (hours == null && minutes == null && seconds == null) {
            return null;
        }

        const hoursAsSeconds = (hours || 0) * 60 * 60;
        const minutesAsSeconds = (minutes || 0) * 60;

        return hoursAsSeconds + minutesAsSeconds + (seconds || 0);
    }

    updateDuration() {
        this.onChange(this.calculateDuration());
    }

    onRemove() {
        if (this.readonly) {
            return;
        }
        this.hours = this.minutes = this.seconds = 0;
        this.onChange(0);
    }

    inputSize() {
        let count = 0;

        if (this.showHour) {
            count++;
        }

        if (this.showMinute) {
            count++;
        }

        if (this.showSecond) {
            count++;
        }

        if (count === 0) {
            return 'col-xs-12';
        }

        const colSize = 12 / count;
        return `col-xs-${colSize}`;
    }
}
