import MapboxDraw from '@mapbox/mapbox-gl-draw';

class PolygonState {
    polygon: any; // DrawFeature

    currentVertexPosition: number;
}
// We're extending the default polygon
const DrawPolygon = { ...MapboxDraw.modes.draw_polygon };

DrawPolygon.onTrash = function (state: PolygonState) {
    // remove last added coordinate
    // polygons work with Rings. In our case we assume there is ever only 1 ring, indicated by the "0" before the .
    // it's not documented, but the unit tests are useful:
    // https://github.com/mapbox/mapbox-gl-draw/blob/2b9ce3e58e3695c018a48b6fca78ed1a9d1b67c2/test/polygon.test.js

    if (state.currentVertexPosition <= 2) {
        return state;
    }
    state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);
    state.currentVertexPosition--;

    // unfortunately, below does not work. See https://github.com/mapbox/mapbox-gl-draw/issues/959
    // it does not execute displayFeatures.
    this.doRender(state.polygon.id);

    return state;
};

export default DrawPolygon;
