import { Component, Input } from '@angular/core';
import {
    CurrentPersonDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NotificationPreferenceDto,
    NotificationsService,
    UpdateDisabledNotificationCommand,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationSettingsService } from './notification-settings.service';

interface NotificationPreferences {
    identifier: string;
    name: string;
    muteApp: boolean;
    muteEmail: boolean;
    mutePush: boolean;
}

interface NotificationGroup {
    grouping: string;
    notifications: NotificationPreferences[];
}

// Which notification setting groupings do we want available in the list, as well as the order in which we wish to list them
const displayableGroupings = [
    'Missions',
    'Mission Approval',
    'Maintenance',
    'Flight Conformance'
];

@Component({
    selector: 'profile-notification-settings',
    templateUrl: './notification-settings.component.html'
})
export class ProfileNotificationSettings {
    @Input() currentUser: CurrentPersonDto;

    notifications: NotificationGroup[];

    working = false;
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private notificationService: NotificationsService,
        private notificationSettingsService: NotificationSettingsService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService
    ) {
        this.notificationSettingsService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshNotificationIdentifiers();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshNotificationIdentifiers() {
        this.notificationService
            .findNotificationIdentifiers()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                notifications => {
                    // Create a map to store the index of each item in the preferred order
                    const orderIndex = new Map();
                    displayableGroupings.forEach((item, index) =>
                        orderIndex.set(item, index)
                    );

                    const groupings = notifications
                        .filter(n => displayableGroupings.includes(n.grouping))
                        .sort((a, b) => {
                            // Sort the filtered groups according to the preferred order set by displayableGroupings
                            const indexA = displayableGroupings.indexOf(
                                a.grouping
                            );
                            const indexB = displayableGroupings.indexOf(
                                b.grouping
                            );

                            if (indexA === -1) return 1;
                            if (indexB === -1) return -1;

                            return indexA - indexB;
                        })
                        .reduce(
                            (acc, n) =>
                                acc.includes(n.grouping)
                                    ? acc
                                    : acc.concat(n.grouping),
                            []
                        );
                    this.notifications = groupings.map(g => ({
                        grouping: g,
                        notifications: notifications
                            .filter(n => n.grouping === g)
                            .reduce(
                                (acc, g) =>
                                    acc.concat(
                                        g.notificationList.map<
                                            NotificationPreferences
                                        >(n => {
                                            const preference = this.currentUser.disabledNotifications?.find(
                                                notification =>
                                                    notification.notificationIdentifier ===
                                                    n.identifier
                                            ) ?? {
                                                notificationIdentifier:
                                                    n.identifier,
                                                muteApp: false,
                                                muteEmail: false,
                                                mutePush: false
                                            };
                                            return {
                                                ...n,
                                                // Passing these as negatives so things make sense to the user
                                                // The API works in terms of what's disabled, hence the fields called "mute<source>"
                                                // but the users will see it in terms of which ones are enabled
                                                muteApp: !preference.muteApp,
                                                muteEmail: !preference.muteEmail,
                                                mutePush: !preference.mutePush
                                            };
                                        })
                                    ),
                                []
                            )
                    }));
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error finding notification preference identifiers: ${error.message}`
                    )
            )
            .add(this.notificationSettingsService.createWorkTracker());
    }

    updateNotificationSetting(notification: NotificationPreferences) {
        const command: UpdateDisabledNotificationCommand = {
            notificationIdentifier: notification.identifier,
            // Flipping the user settings around again on save so the data makes sense to the API
            muteApp: !notification.muteApp,
            muteEmail: !notification.muteEmail,
            mutePush: !notification.mutePush
        };
        this.notificationService
            .updateNotificationPreferences(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    // First find which setting has changed and build toast text for it
                    const oldSetting = this.currentUser.disabledNotifications?.find(
                        n =>
                            n.notificationIdentifier ==
                            command.notificationIdentifier
                    );

                    const findSettingUpdateType = (
                        oldSetting?: NotificationPreferenceDto
                    ) => {
                        if (oldSetting == null) {
                            // If this is the first time this setting is getting changed, return whichever one is now true
                            const app = command.muteApp;
                            const email = command.muteEmail;
                            // const push = command.mutePush;
                            const changed = app
                                ? 'Unsubscribed from app notifications'
                                : email
                                ? 'Unsubscribed from email notifications'
                                : 'Unsubscribed from push notifications';
                            return changed;
                        } else {
                            // If this setting is getting updated, return the unique one
                            const app = command.muteApp !== oldSetting.muteApp;
                            const email =
                                command.muteEmail !== oldSetting.muteEmail;
                            const push =
                                command.mutePush !== oldSetting.mutePush;
                            const changed = app
                                ? `${
                                      command.muteApp
                                          ? 'Unsubscribed from'
                                          : 'Subscribed to'
                                  } app notifications`
                                : email
                                ? `${
                                      command.muteEmail
                                          ? 'Unsubscribed from'
                                          : 'Subscribed to'
                                  } email notifications`
                                : `${
                                      command.mutePush
                                          ? 'Unsubscribed from'
                                          : 'Subscribed to'
                                  } push notifications`;
                            return changed;
                        }
                    };

                    const updatedSetting = findSettingUpdateType(oldSetting);

                    // Update the current user to include the current notification settings without using the API
                    this.currentUser.disabledNotifications = this.currentUser.disabledNotifications?.filter(
                        n =>
                            n.notificationIdentifier !=
                            command.notificationIdentifier
                    );
                    this.currentUser.disabledNotifications == null
                        ? (this.currentUser.disabledNotifications = [command])
                        : this.currentUser.disabledNotifications.push(command);
                    const organisations = this.userService.findUsersOrganisations();
                    this.userService.updateCurrentUser(
                        this.currentUser,
                        organisations
                    );
                    this.logging.success(
                        `${updatedSetting} for ${notification.name}`
                    );
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating ${notification.name}: ${error.message}`
                    )
            )
            .add(this.notificationSettingsService.createWorkTracker());
    }
}
