import {
    CreateObjectiveCommand,
    UpdateObjectiveCommand,
    MissionObjectiveDto
} from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MissionObjectivesService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<MissionObjectiveDto[]>(
            `${this.baseUrl}/webapi/missionObjectives`,
            {
                params: new HttpParams().set(
                    'organisationId',
                    organisationId.toString()
                )
            }
        );
    }

    create(command: CreateObjectiveCommand) {
        return this.http
            .post<MissionObjectiveDto>(
                `${this.baseUrl}/webapi/missionObjectives`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    update(missionObjectiveId: number, command: UpdateObjectiveCommand) {
        return this.http
            .put<MissionObjectiveDto>(
                `${this.baseUrl}/webapi/missionObjectives/${missionObjectiveId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
