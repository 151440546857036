import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router
} from '@angular/router';

@Injectable()
export class MissionsGuard implements CanActivate {
    constructor(private router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot) {
        //TODO router test below
        const organisationId = +route.queryParams.organisationId;
        if (isNaN(organisationId)) {
            return this.router.createUrlTree(['/home'], {
                queryParamsHandling: 'preserve'
            });
        }
        return true;
    }
}
