import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatArray' })
export class FormatArrayPipe implements PipeTransform {
    transform(value: string[], ...args: any[]): string | null {
        if (value == null) {
            return null;
        }
        return value.join(', ');
    }
}
