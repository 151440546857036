import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CopyService,
    DashboardService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { hasExistingCommercialOrganisations } from 'libs/subscriptions/src/lib/helpers';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'more-features-dialogue',
    templateUrl: './more-features-dialogue.component.html'
})
export class MoreFeaturesDialogue {
    @Input() organisationId: number;
    @Input() isPersonalOrg: boolean;
    moreFeatures: string;

    createOrganisationTooltip = 'Create a free commercial organisation';

    salesContacted = false;
    canCreateOrganisation = false;

    public working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef,
        private copyService: CopyService,
        private userService: UserService,
        private dashboardService: DashboardService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.copyService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshCopy());
        this.refreshPermissions();
        this.refreshCopy();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshPermissions() {
        this.canCreateOrganisation = !hasExistingCommercialOrganisations(
            this.userService.findUsersOrganisations(),
            this.userService.getCurrentUser()?.id
        );
        this.setupTooltips();
    }

    setupTooltips() {
        this.createOrganisationTooltip = this.canCreateOrganisation
            ? 'Create a free commercial organisation'
            : 'You have already created an organisation';
    }

    refreshCopy() {
        this.copyService
            .findCopy('more-features-dialogue')
            .then(result => (this.moreFeatures = result));
    }

    createCommercialOrganisation() {
        this.dashboardService.shouldReturnToDashboard(
            true,
            this.organisationId
        );
        this.router.navigate(['onboarding']);
        this.modal.hide();
    }

    upgrade() {
        this.router.navigate(['orgadmin', 'subscriptions'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
        this.modal.hide();
    }

    contactSales() {
        this.userService
            .fetchOrganisationForUser(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                const doneWorking = this.workTracker.createTracker();
                this.commonDialoguesService
                    .showFormlyDialogue(
                        'Contact Sales',
                        'Contact',
                        true,
                        true,
                        [
                            {
                                key: 'message',
                                type: 'textarea',
                                props: {
                                    label: 'Message',
                                    required: true
                                }
                            }
                        ],
                        {
                            message: this.isPersonalOrg
                                ? `I would like to set up an organisation.`
                                : `I would like to upgrade my organisation ${organisation.name}`
                        },
                        (data: { message: string }) =>
                            this.userService
                                .contactSales({ message: data.message })
                                .toPromise()
                    )
                    .then(
                        result => {
                            this.salesContacted = true;
                            this.logging.success(
                                `Sales will be in touch shortly`
                            );
                            doneWorking();
                        },
                        (error: FlyFreelyError) => {
                            if (error != null) {
                                this.logging.error(
                                    error,
                                    `There was an error, please use the in app chat`
                                );
                            }
                            doneWorking();
                        }
                    )
                    .catch(() => doneWorking());
            })
            .add(this.workTracker.createTracker());
    }

    close() {
        this.modal.hide();
    }
}
