<div class="modal-header">
    <div class="panel-heading-icon">
        <img imgPath="/icons/icon-rpa.png" />
    </div>
    <h3 class="modal-title">RPA Reports</h3>
    <button type="button" (click)="cancel()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <tabset>
        <tab heading="RPA List">
            <div class="modal-body">
                <p>
                    Export a CSV report of all RPA in this organisation as
                    listed in the RPA widget. Select an authority below to add
                    columns for that authority to the report.
                </p>
                <label>Select authorities (optional)</label>
                <ng-select
                    [(ngModel)]="selectedAuthorityTypeIds"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="working"
                    [clearable]="true"
                    [items]="authorityTypes"
                    [multiple]="true"
                    placeholder="Select an authority"
                    bindLabel="name"
                    bindValue="id"
                    appendTo="body"
                >
                </ng-select>
            </div>
            <div class="modal-footer">
                <button
                    (click)="exportRpa()"
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working"
                    angulartics2On="click"
                    angularticsAction="dashboard-report-rpa"
                >
                    Download
                </button>
            </div>
        </tab>
        <!-- <ng-container *ngFor="let printOption of printOptions">
        <tab [heading]="printOption.name">
            <div class="modal-body">
                <p class="top-buffer">
                    {{ printOption.description }}
                </p>
            </div>
            <div class="modal-footer">
                <button
                    (click)="printReport(printOption)"
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working"
                    angulartics2On="click"
                    angularticsAction="dashboard-report-rpa"
                >
                    <span class="fa fa-print"></span> Print
                    {{ printOption.name }}
                </button>
            </div>
        </tab>
    </ng-container> -->
    </tabset>
</div>
