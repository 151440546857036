/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveHours } from './activeHours';


/**
 * 
 */
export interface Reason { 
    type?: Reason.Type;
    identifier?: string;
    name?: string;
    subClass?: string;
    state?: Reason.State;
    message?: string;
    activeHours?: Array<ActiveHours>;
}
export namespace Reason {
    export type Type = 'PRD' | 'AERODROME' | 'INCIDENT' | 'ALTITUDE' | 'DAYTIME' | 'ELECTRICITY_LINE' | 'MARINE_PARK' | 'ADVISORY' | 'INSTRUMENT_APPROACH' | 'TEMPORARY_RESTRICTED_OR_PROHIBITED_ZONE' | 'GCD' | 'UNIDENTIFIED';
    export const Type = {
        PRD: 'PRD' as Type,
        AERODROME: 'AERODROME' as Type,
        INCIDENT: 'INCIDENT' as Type,
        ALTITUDE: 'ALTITUDE' as Type,
        DAYTIME: 'DAYTIME' as Type,
        ELECTRICITY_LINE: 'ELECTRICITY_LINE' as Type,
        MARINE_PARK: 'MARINE_PARK' as Type,
        ADVISORY: 'ADVISORY' as Type,
        INSTRUMENT_APPROACH: 'INSTRUMENT_APPROACH' as Type,
        TEMPORARY_RESTRICTED_OR_PROHIBITED_ZONE: 'TEMPORARY_RESTRICTED_OR_PROHIBITED_ZONE' as Type,
        GCD: 'GCD' as Type,
        UNIDENTIFIED: 'UNIDENTIFIED' as Type
    };
    export type State = 'ACTIVATED' | 'DEACTIVATED';
    export const State = {
        ACTIVATED: 'ACTIVATED' as State,
        DEACTIVATED: 'DEACTIVATED' as State
    };
}



