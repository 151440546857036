import { Injectable } from '@angular/core';
import { RpaRegisterEntryExpiryDto } from '@flyfreely-portal-ui/flyfreely';
import { BehaviorSubject } from 'rxjs';

/**
 * A scoped service providing common functions for the RPA authorities.
 * Currently it is only supplying the register expiry lookups, which isn't applicable,
 * but is required for sufficient overlap with organisation authorities, which uses the same components.
 */
@Injectable()
export class RpaAuthorityEditService {
    private registerExpiryLookupsSource = new BehaviorSubject<{
        [registerId: number]: RpaRegisterEntryExpiryDto.ExpiryStatus;
    }>([]);
    registerExpiryLookups$ = this.registerExpiryLookupsSource.asObservable();

    ngOnDestroy() {
        this.registerExpiryLookupsSource.complete();
    }
}
