<div class="panel widget battery">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-batteries.png" />
        </div>
        <div class="panel-title">Batteries</div>

        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                type="button"
                class="btn btn-inline"
                (click)="downloadBatteryList()"
                tooltip="Export the list of batteries"
                placement="left"
                *ngIf="isSystemAdmin || canDownloadBatteries"
            >
                <i class="fa-file-export fa"></i>
            </button>
            <button
                type="button"
                class="btn btn-inline"
                (click)="newBattery()"
                tooltip="Add a new battery"
                placement="left"
                *ngIf="canAddBattery"
                angulartics2On="click"
                angularticsAction="dashboard-battery-add"
                data-cy="batteryListAddButton"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>

    <div class="panel-body">
        <loading-indicator [isLoading]="working"></loading-indicator>
        <static-table
            *ngIf="batteries?.length > 0"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="batteries"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
            data-cy="BatteryListTable"
        >
            <ng-template
                columnTemplate="name"
                let-row="row"
                let-column="column"
            >
                <status-icon
                    [battery]="row"
                    (nameClicked)="showBattery(row)"
                ></status-icon>
            </ng-template>
        </static-table>
        <empty
            *ngIf="canAddBattery && batteries && batteries.length == 0"
            componentName="battery-empty-editable"
        ></empty>
        <empty
            *ngIf="!canAddBattery && batteries && batteries.length == 0"
            componentName="battery-empty"
        ></empty>
    </div>
</div>
