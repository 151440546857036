import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DisplayableMissionDto,
    SortieDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignRpa } from './assign-rpa/assign-rpa.component';
import { FlightAnalyser } from './flight-analyser/flight-analyser-dialogue.component';
import { FlightLogWithDuration } from './flight-log-list/flight-log-list-dialogue.component';
import { FlightLogUploader } from './flight-log-uploader.component';
import { FlightLogSettings } from './settings/flight-log-settings.component';
import { unescape } from 'lodash';

@Injectable()
export class FlightLogsDialoguesService {
    constructor(
        private modalService: BsModalService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    showFlightLog(
        flightLogId: number,
        organisationId: number,
        mission?: DisplayableMissionDto,
        flight?: SortieDto
    ) {
        return this.modalService.show(FlightAnalyser, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                mission,
                flight,
                flightLogId,
                organisationId
            }
        });
    }

    showFlightLogSettings(organisationId: number) {
        // Currently there is no clear solution to routing the settins modal without changing the view away from the dashboard
        // I'm leaving the routing here, but for now I'm changing the modal to a normal unrouted modal.

        // this.router.navigate([buildTargetedUrl('settings')], {
        //     queryParamsHandling: 'preserve',
        //     relativeTo: this.activatedRoute
        // });

        this.modalService.show<FlightLogSettings>(FlightLogSettings, {
            ...MODAL_OPTIONS,
            class: 'modal-task modal-lg',
            initialState: {
                organisationId
            }
        });
    }

    showAssignRpa(
        flightLog: FlightLogWithDuration,
        enhancedHelpActive: boolean
    ) {
        return this.modalService.show<AssignRpa>(AssignRpa, {
            ...MODAL_OPTIONS,
            class: 'modal-task modal-lg',
            initialState: {
                flightLog,
                enhancedHelpActive
            }
        });
    }

    showFlightLogUploader(
        organisationId: number,
        files: File[],
        enhancedHelpActive: boolean
    ) {
        return this.modalService.show<FlightLogUploader>(FlightLogUploader, {
            ...MODAL_OPTIONS,
            class: 'modal-task',
            initialState: {
                organisationId,
                files,
                enhancedHelpActive
            }
        });
    }
}
