import { Pipe, PipeTransform } from '@angular/core';
import { InService } from '@flyfreely-portal-ui/flyfreely';
import { FormatDurationPipe } from 'libs/ui/src/lib/pipes';
import moment from 'moment';

@Pipe({
    name: 'formatInServiceTime'
})
export class FormatInServiceTimePipe implements PipeTransform {
    constructor(private durationPipe: FormatDurationPipe) {}
    transform(time: InService) {
        let ret = '';
        if (time.time != null) {
            ret += `Time: ${this.durationPipe.transform(time.time)} `;
        }
        if (time.cycles != null) {
            ret += `Cycles: ${time.cycles} `;
        }
        if (time.timestamp != null) {
            ret += `Days: ${moment()
                .startOf('day')
                .diff(moment(time.timestamp).startOf('day'), 'days')}`;
        }
        return ret;
    }
}
