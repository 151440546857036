import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    AddressDto,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NotFound,
    OrganisationSubscriptionDto,
    PersonsOrganisationDto,
    SubscriptionDetailsDto,
    SubscriptionLimitDto,
    SubscriptionService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { AddressTaxDialogue } from 'libs/subscriptions/src/lib/address-tax-setup/address-tax-dialogue.component';
import {
    OrganisationSubscriptionState,
    SubscriptionAndDetails
} from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
    ObservableNotification,
    ReplaySubject,
    Subject,
    combineLatest,
    of,
    race,
    throwError
} from 'rxjs';
import {
    catchError,
    dematerialize,
    filter,
    materialize,
    switchMap,
    take,
    takeUntil,
    takeWhile,
    tap
} from 'rxjs/operators';
import { ConfirmPaymentDialogue } from '../confirm-payment/confirm-payment.component';
import { UpdateSubscription } from '../update-subscription/update-subscription.component';
import { UpgradeSubscriptionDialogue } from '../upgrade-subscription/upgrade-subscription-dialogue.component';

const complete = () => takeWhile(() => false);

@Component({
    selector: 'current-subscription',
    templateUrl: './current-subscription.component.html'
})
export class CurrentSubscription {
    @Input() organisation: PersonsOrganisationDto;

    private organisationLoaded$ = new Subject<boolean>();
    private shouldShowUpgrade$ = new ReplaySubject<boolean>(1);

    salesContacted = false;

    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    subscription: OrganisationSubscriptionDto;
    subscriptionDetails: SubscriptionDetailsDto;
    subscriptionAndDetails: SubscriptionAndDetails;
    availableSubscriptions: OrganisationSubscriptionDto[];
    selectSubscription = false;

    requiresLicences = true;
    licenceLimit: number;

    isUpdatingSubscription = false;
    isCancelled = false;

    updatePending = false;

    isShowingPurchaseSubscription = false;

    canUpdateSubscription = false;
    canUpdateSubscriptionMessage: string = null;

    trialUpPlan: string;

    constructor(
        private subscriptionService: SubscriptionService,
        private state: OrganisationSubscriptionState,
        private activatedRoute: ActivatedRoute,
        private commonDialoguesService: CommonDialoguesService,
        private modalService: BsModalService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService,
        private angulartics2: Angulartics2
    ) {
        this.state.subscription$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(d => d != null)
            )
            .subscribe(subscription => {
                this.isUpdatingSubscription = false;
                this.isCancelled = subscription.endTime != null;

                this.subscription = subscription;

                const licenceLimit = subscription.limits.find(
                    l =>
                        l.licencedEntityType ===
                        SubscriptionLimitDto.LicencedEntityType.PERSONNEL
                );

                const otherLicenceLimit =
                    licenceLimit == null
                        ? subscription.limits.find(
                              l =>
                                  l.licencedEntityType ===
                                      SubscriptionLimitDto.LicencedEntityType
                                          .PILOT ||
                                  l.licencedEntityType ===
                                      SubscriptionLimitDto.LicencedEntityType
                                          .RPA
                          )
                        : null;

                this.requiresLicences =
                    licenceLimit != null || otherLicenceLimit != null;

                // If the route to this tab came from the upsell component, show the upgrade screen automatically
                if (
                    this.activatedRoute.snapshot.params?.feature != null &&
                    this.activatedRoute.snapshot.params?.tab ===
                        'subscriptions' &&
                    subscription != null
                ) {
                    this.shouldShowUpgrade$.next(true);
                }

                if (subscription.externalIdentifier == null) {
                    // If there is no external identifier then this must be false, otherwise the
                    // subscription details logic will calculate this
                    this.canUpdateSubscription = false;
                }
            });
        this.state.availableSubscriptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(available => (this.availableSubscriptions = available));

        this.state.subscriptionDetails$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(({ details, subscription }) => {
                this.subscriptionDetails = details;
                this.subscriptionAndDetails = { details, subscription };
                this.canUpdateSubscription =
                    subscription.endTime == null &&
                    details.collectionMethod === 'charge_automatically';
                this.canUpdateSubscriptionMessage = this.canUpdateSubscription
                    ? null
                    : details.collectionMethod !== 'charge_automatically'
                    ? 'Invoiced accounts must contact sales to adjust subscriptions'
                    : 'Subscription has been cancelled';

                this.trialUpPlan = subscription.availableTrialUp?.name;
            });

        this.state.updatePending$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(updatePending => (this.updatePending = updatePending));
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        combineLatest([this.organisationLoaded$, this.shouldShowUpgrade$])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([orgLoaded, showUpgrade]) => {
                if (orgLoaded && showUpgrade) {
                    this.upgradeSubscription();
                }
            });

        this.organisationLoaded$.next(this.organisation != null);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('organisation' in changes) {
            this.organisationLoaded$.next(this.organisation != null);
        }
    }

    ngOnDestroy(): void {
        this.state.resetToDefaultSubscription();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.organisationLoaded$.complete();
        this.shouldShowUpgrade$.complete();
    }

    updateSelectedSubscription(subscription: OrganisationSubscriptionDto) {
        this.state.change(subscription);
        this.selectSubscription = false;
    }

    showPaymentDetails() {
        const currentDueDate =
            this.subscriptionDetails.currentInvoice?.dueDate != null
                ? this.subscriptionDetails.currentInvoice?.dueDate
                : this.subscriptionDetails.upcomingInvoice?.dueDate;
        this.subscriptionService
            .findBillingAddress(this.organisation.id)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                catchError((error: FlyFreelyError) =>
                    error instanceof NotFound
                        ? of(null)
                        : throwError(() => error)
                ),
                switchMap(address => {
                    if (address != null) {
                        return of(address).pipe(materialize());
                    }
                    const modal = this.modalService.show<AddressTaxDialogue>(
                        AddressTaxDialogue,
                        {
                            ...MODAL_OPTIONS,
                            class: 'modal-task',
                            initialState: {
                                address,
                                organisationId: this.organisation.id
                            }
                        }
                    );

                    return race(
                        modal.onHidden.pipe(complete(), materialize()),
                        modal.content.complete.pipe(materialize())
                    );
                }),
                catchError((error: FlyFreelyError, value) => {
                    this.logging.error(
                        error,
                        `An error occurred while trying to update payment details: ${error.message}`
                    );
                    return throwError(() => error);
                }),
                dematerialize<ObservableNotification<AddressDto>>()
            )
            .subscribe(address =>
                this.showPaymentModal(address, currentDueDate)
            );
    }

    showPaymentModal(address: AddressDto, currentDueDate: string) {
        const paymentModal = this.modalService.show<ConfirmPaymentDialogue>(
            ConfirmPaymentDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-task',
                initialState: {
                    address: address,
                    organisationId: this.organisation.id,
                    subscriptionId: this.subscription.id,
                    licenceCount: this.subscriptionDetails.licenceCount,
                    subscriptionPlanIdentifier:
                        this.subscriptionDetails.subscriptionPlan.identifier,
                    currentDueDate: currentDueDate,
                    mode: 'PAYMENT_DETAILS'
                }
            }
        );
        paymentModal.onHide.pipe(take(1)).subscribe(() => {
            this.state.refresh(this.organisation.id);
            this.angulartics2.eventTrack.next({
                action: 'update-subscription-payment',
                properties: {
                    category: 'subscriptions',
                    label: this.subscriptionDetails.subscriptionPlan.identifier,
                    value: this.subscriptionDetails.licenceCount
                }
            });
        });
    }

    cancelSubscription() {
        this.commonDialoguesService.showConfirmationDialogue(
            'Cancel Subscription',
            `If you cancel this subscription your personnel will lose access at the end of this billing cycle.`,
            'Cancel Subscription',
            () =>
                this.subscriptionService
                    .cancelSubscription(this.subscription.id)
                    .pipe(
                        tap(() => this.state.pollForSubscriptionCancellation())
                    )
                    .toPromise()
        );
    }

    uncancelSubscription() {
        this.commonDialoguesService.showConfirmationDialogue(
            'Restart Subscription',
            `If you proceed your subscription will continue past this billing cycle.`,
            'Restart Subscription',
            () =>
                this.subscriptionService
                    .uncancelSubscription(this.subscription.id)
                    .pipe(
                        tap(() =>
                            this.state.pollForSubscriptionUncancellation()
                        )
                    )
                    .toPromise()
        );
    }

    upgradeSubscription() {
        this.shouldShowUpgrade$.next(false);
        this.modalService.show(UpgradeSubscriptionDialogue, {
            class: 'modal-lg modal-task',
            initialState: {
                organisation: this.organisation,
                showUpgrade: true,
                subscriptionId: this.subscription.id,
                subscriptionDetails: this.subscriptionAndDetails
            },
            providers: [
                {
                    provide: OrganisationSubscriptionState,
                    useValue: this.state
                }
            ]
        });
    }

    trialUpSubscription() {
        this.angulartics2.eventTrack.next({
            action: 'user-view-uptrial',
            properties: {
                category: 'subscriptions',
                label: this.subscriptionDetails.subscriptionPlan.identifier,
                value: this.subscription.availableTrialUp.name
            }
        });
        this.commonDialoguesService
            .showConfirmationDialogue(
                `Start a ${this.trialUpPlan} Trial`,
                `Start a short trial that allows you to preview the next subscription tier and the features included with that tier.<br><br>Once the trial ends your current subscription will resume.<br><br>Would you like to trial the next tier?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => {
                this.angulartics2.eventTrack.next({
                    action: 'user-uptrial-subscription',
                    properties: {
                        category: 'subscriptions',
                        label: this.subscriptionDetails.subscriptionPlan
                            .identifier,
                        value: this.subscription.availableTrialUp.name
                    }
                });
                this.subscriptionService
                    .trialUpSubscription(this.subscription.id)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(
                        result => {
                            this.state.refresh(this.organisation.id);
                            this.subscription = result;
                            this.logging.success(
                                `Started ${this.trialUpPlan} trial`
                            );
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while setting up trial for ${this.trialUpPlan}: ${error.message}`
                            );
                        }
                    )
                    .add(this.workTracker.createTracker());
            }, DO_NOTHING);
    }

    updateSubscription() {
        this.modalService.show(UpdateSubscription, {
            class: 'modal-task',
            providers: [
                {
                    provide: OrganisationSubscriptionState,
                    useValue: this.state
                }
            ]
        });
    }

    cancelUpdateSubscription() {
        this.isUpdatingSubscription = false;
    }

    contactSales() {
        const doneWorking = this.workTracker.createTracker();

        this.commonDialoguesService
            .showFormlyDialogue(
                'Contact Sales',
                'Contact',
                true,
                true,
                [
                    {
                        key: 'message',
                        type: 'textarea',
                        props: { label: 'Message', required: true }
                    }
                ],
                {
                    message: `I would like help with my subscription ${this.organisation.name}`
                },
                (data: { message: string }) =>
                    this.userService
                        .contactSales({ message: data.message })
                        .toPromise()
            )
            .then(result => {
                this.salesContacted = true;
                this.logging.success(`Sales will be in touch shortly`);
                doneWorking();
            })
            .catch(() => doneWorking());
    }

    selfServe() {
        this.subscriptionService
            .findSelfServeUrl(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                url => (window.location.href = url),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `An error has occurred: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }
}
