import { FormlyFieldConfig } from '@ngx-formly/core';

export function basicUserProfileFields(): FormlyFieldConfig[] {
    return [
        {
            key: 'firstName',
            type: 'input',
            props: {
                label: 'First Name',
                required: true
            }
        },
        {
            key: 'lastName',
            type: 'input',
            props: {
                label: 'Last Name',
                required: true
            }
        },
        {
            key: 'phoneNumber',
            type: 'phonenumber',
            props: {
                label: 'Phone Number',
                required: false,
                minLength: 10
            }
        }
    ];
}
