import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

export interface Entity {
    id: number;
    name: string;
    archived: boolean;
    organisationId?: number;
}

/**
 * A generic entity list that allows items to be added, archived, and unarchived.
 */
@Component({
    selector: 'entity-list',
    template: `<div class="horizontal-container">
        <button
            *ngIf="canAdd"
            type="button"
            class="btn-circle btn-link"
            (click)="create.emit()"
            [tooltip]="'Create new ' + type"
            [disabled]="working"
        >
            <span class="fa fa-plus"></span>
        </button>
        <div class="fill">
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            <button
                                *ngIf="hasCatalogue"
                                type="button"
                                class="btn btn-xs btn-primary"
                                style="margin-right: 8px;"
                                (click)="browseCatalogue.emit()"
                                position="top"
                                tooltip=""
                            >
                                {{ catalogueName }}
                            </button>
                        </th>
                        <th>
                            <div
                                *ngIf="canArchive"
                                class="checkbox pull-right"
                                style="margin: 0;"
                            >
                                <div mdcFormField>
                                    <div mdcSwitch>
                                        <div mdcSwitchThumb>
                                            <input
                                                type="checkbox"
                                                mdcSwitchInput
                                                [(ngModel)]="showArchived"
                                                [ngModelOptions]="{
                                                    standalone: true
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <label mdcFormFieldLabel
                                        >Show archived items?</label
                                    >
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of items">
                        <tr *ngIf="showArchived || !item.archived" class="data">
                            <td>
                                <a (click)="edit.emit(item)">{{ item.name }}</a>
                            </td>
                            <td class="actions">
                                <button
                                    *ngIf="
                                        item.organisationId != null &&
                                        !item.archived
                                    "
                                    type="button"
                                    class="btn btn-tertiary"
                                    (click)="archive.emit(item)"
                                    [disabled]="working"
                                    tooltip="Archive"
                                    placement="left"
                                >
                                    <span class="fal fa-eye-slash"></span>
                                </button>

                                <button
                                    *ngIf="
                                        item.organisationId != null &&
                                        item.archived
                                    "
                                    type="button"
                                    class="btn btn-tertiary"
                                    (click)="unarchive.emit(item)"
                                    [disabled]="working"
                                    tooltip="Unarchive"
                                    placement="left"
                                >
                                    <span class="fal fa-eye"></span>
                                </button>

                                <button
                                    *ngIf="canClone"
                                    type="button"
                                    class="btn btn-tertiary"
                                    (click)="clone.emit(item)"
                                    tooltip="Clone"
                                    placement="left"
                                >
                                    <i class="fa-clone fal"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityList {
    @Input() items: Entity[];
    @Input() working: boolean;
    @Input() type: string;
    @Input() canAdd = true;
    @Input() canClone = false;
    @Input() canArchive = true;
    @Input() hasCatalogue = false;
    @Input() catalogueName;
    @Output() create = new EventEmitter<void>();
    @Output() edit = new EventEmitter<Entity>();
    @Output() archive = new EventEmitter<Entity>();
    @Output() unarchive = new EventEmitter<Entity>();
    @Output() clone = new EventEmitter<Entity>();
    @Output() browseCatalogue = new EventEmitter<Entity>();
    @Output() hideModal = new EventEmitter<Entity>();

    showArchived = false;
}
