<div class="horizontal-container vertical-align person-avatar-pill">
    <div
        [ngStyle]="{
            width: (width || 25) + 'px',
            height: (height || 25) + 'px'
        }"
    >
        <profile-picture
            *ngIf="person != null"
            [personId]="person.id"
            [managingOrganisationId]="managingOrganisationId"
            [shadow]="false"
            [width]="width || 25"
            [height]="height || 25"
        >
        </profile-picture>
    </div>
    <div *ngIf="!onlyImage" class="person-name-container">
        <span *ngIf="!isLink">{{
            (person | formatPerson) || alternativeName
        }}</span>
        <a *ngIf="isLink" (click)="onPersonClicked.emit(person)">{{
            (person | formatPerson) || alternativeName
        }}</a>
    </div>
</div>
