<ng-container
    *ngIf="
        serviceTimes != null &&
        (serviceTimes.components.length > 0 || serviceTimes.total != null)
    "
>
    <h4 class="section-heading">
        <span *ngIf="timeInServiceMode == 'total'">Total Time In Service</span>
        <span *ngIf="timeInServiceMode == 'maintenance'"
            >Time In Service at Maintenance Request</span
        >
        <div></div>
    </h4>
    <enhanced-help
        componentName="component-service-time"
        [compact]="true"
    ></enhanced-help>

    <!-- If the resource has no components, then only show its total time in service -->
    <ng-container
        *ngIf="
            serviceTimes.components.length == 0 && serviceTimes.total != null
        "
    >
        <div class="fcol-8">
            <div class="fill table-container no-actions">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 15%"></th>
                            <th style="width: 15%">Time</th>
                            <th style="width: 15%">Cycles</th>
                            <!-- <th style="width: 15%">Last used</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Total</strong>
                            </td>
                            <td>
                                {{ serviceTimes.total.time | formatDuration  | defaultTo }}
                            </td>
                            <td>
                                {{ serviceTimes.total.cycles }}
                            </td>
                            <!-- <td>
                                {{
                                    serviceTimes.total.timestamp
                                        | formatDateTime
                                }}
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>

    <!-- If the resource has components, then show them all -->
    <ng-container *ngIf="serviceTimes.components.length > 0">
        <div
            *ngIf="timeInServiceMode == 'total' && hasScheduledMaintenance"
            class="horizontal-container pad-children"
        >
            <span class="display-label">Scheduled Maintenance</span>
            <div [ngSwitch]="serviceTimes?.triggerStatus?.type">
                <span *ngSwitchCase="'UPCOMING'" class="status-block pending"
                    >Upcoming</span
                >
                <span *ngSwitchCase="'DUE'" class="status-block pending"
                    >Due</span
                >
                <span *ngSwitchCase="'OVERDUE'" class="status-block inactive"
                    >Overdue</span
                >
                <!-- FIXME need a no schedule case -->
                <span *ngSwitchDefault class="status-block active"
                    >Not Due</span
                >
            </div>
        </div>
        <div class="vertical-scroll horizontal-container bottom-buffer">
            <div class="fcol-8">
                <div class="fill table-container no-actions">
                    <table class="table">
                        <thead>
                            <tr *ngIf="hasScheduledMaintenance">
                                <th style="width: 20%">Component</th>
                                <th style="width: 15%">Serial Number</th>
                                <th style="width: 15%">Firmware Version</th>
                                <th style="width: 15%">
                                    Total time in service&nbsp;
                                    <i
                                        class="fal fa-info-circle info-tooltip"
                                        [tooltip]="
                                            'Unless component has been replaced during maintenance, days in service are calculated from the ' +
                                            (resourceType === 'CRAFT'
                                                ? 'RPA'
                                                : 'equipment') +
                                            '\'s estimated procurement date'
                                        "
                                        placement="top"
                                    ></i>
                                </th>
                                <th style="width: 15%">
                                    Time until next maintenance
                                </th>
                            </tr>
                            <tr *ngIf="!hasScheduledMaintenance">
                                <th style="width: 20%">Component</th>
                                <th style="width: 20%">Serial Number</th>
                                <th style="width: 20%">Firmware Version</th>
                                <th style="width: 20%">
                                    Total time in service
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!working">
                            <ng-container
                                *ngFor="
                                    let component of serviceTimes.components;
                                    let i = index
                                "
                            >
                                <tr
                                    class="data"
                                    (mouseover)="getAttachmentUrl(component)"
                                >
                                    <td>
                                        <span
                                            *ngIf="
                                                findComponentIcon(component);
                                                let componentIconClass
                                            "
                                            [class]="componentIconClass"
                                        ></span
                                        >{{
                                            component.componentGroup?.name ||
                                                component.componentType.name
                                        }}
                                        {{ component.designation }}
                                    </td>
                                    <td>
                                        {{ component.serialNumber }}
                                    </td>
                                    <td>
                                        {{ component.firmwareVersion }}
                                    </td>
                                    <td>
                                        <ng-container
                                            *ngIf="component.total != null"
                                        >
                                            {{
                                                component.total
                                                    | formatInServiceTime
                                            }}
                                        </ng-container>
                                    </td>
                                    <td *ngIf="hasScheduledMaintenance">
                                        <ng-container
                                            *ngIf="
                                                component.untilMaintenanceSchedule !=
                                                null
                                            "
                                        >
                                            {{
                                                component.untilMaintenanceSchedule
                                                    | formatInServiceTime
                                            }}
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                class="fcol-4"
                style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                "
            >
                <img
                    *ngIf="diagramUrl != null"
                    style="max-width: 300px; height: auto"
                    [authenticatedSrc]="diagramUrl"
                    [cache]="false"
                />
            </div>
        </div>
    </ng-container>
</ng-container>
