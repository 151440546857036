import { Pipe, PipeTransform } from '@angular/core';
import { RpaTypeDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatRpaType' })
export class FormatRpaTypePipe implements PipeTransform {
    transform(rpaModel: RpaTypeDto, defaultText = '(Other)') {
        if (
            rpaModel == null ||
            (rpaModel.id == null &&
                rpaModel.make == null &&
                rpaModel.model == null)
        ) {
            return defaultText;
        }
        return `${rpaModel.make} ${rpaModel.model}`;
    }
}
