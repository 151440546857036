/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.12.7-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaintenanceActivityActions } from './maintenanceActivityActions';
import { MaintenanceFlightTestDto } from './maintenanceFlightTestDto';
import { SimplePersonDto } from './simplePersonDto';
import { MaintenanceTaskDto } from './maintenanceTaskDto';


/**
 * A maintenance activity that is being, or has been performed
 */
export interface MaintenanceActivityDto { 
    id?: number;
    /**
     * The maintenance that is required to be performed
     */
    requiredMaintenanceDescription?: string;
    maintainer?: SimplePersonDto;
    /**
     * The details of the organisation performing the maintenance for us
     */
    maintenanceOrganisation?: string;
    /**
     * What is the original scheduled date for the maintenance
     */
    scheduledDate?: string;
    /**
     * What date has the maintenance actually started
     */
    startDate?: string;
    /**
     * What date has the maintenance actually completed (use endTime instead)
     */
    endDate?: string;
    /**
     * When the maintenance actually completed
     */
    endTime?: string;
    /**
     * What maintenance was performed
     */
    maintenancePerformed?: string;
    /**
     * Additional remarks about the maintenance
     */
    maintenanceRemarks?: string;
    /**
     * Does the maintenance controller want a flight test
     */
    flightTestRequired?: boolean;
    /**
     * Additional notes about the maintenance made by the maintenance controller
     */
    maintenanceControllerNotes?: string;
    /**
     * The current status of the maintenance activity
     */
    status?: MaintenanceActivityDto.Status;
    availableActions: MaintenanceActivityActions;
    /**
     * Flight tests and their results
     */
    flightTests?: Array<MaintenanceFlightTestDto>;
    tasks: Array<MaintenanceTaskDto>;
}
export namespace MaintenanceActivityDto {
    export type Status = 'DRAFT' | 'ASSIGNED' | 'SUBMITTED' | 'COMPLETED' | 'IN_PROGRESS' | 'CANCELLED' | 'DELETED';
    export const Status = {
        DRAFT: 'DRAFT' as Status,
        ASSIGNED: 'ASSIGNED' as Status,
        SUBMITTED: 'SUBMITTED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        IN_PROGRESS: 'IN_PROGRESS' as Status,
        CANCELLED: 'CANCELLED' as Status,
        DELETED: 'DELETED' as Status
    };
}



