<div id="maintenanceContainer" class="panel widget maintenance">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-maintenance.png" />
        </div>
        <div class="panel-title">Maintenance</div>

        <div class="panel-content" >
                <div class="search-container">
                    <input
                    type="text"
                    class="form-control search-input"
                    placeholder="Search anything in Maintenance..."
                    (input)="onSearch($event.target.value)"
                    />
                    <i class="fa fa-search search-icon"></i>
                    <i class="fa fa-times clear-icon" (click)="clearSearch()"></i>
            </div>
        </div>

        <div class="panel-buttons">
           
            <button 
            class="btn-base-narrow border-white corner-full-curve bg-transparent"
            type="button"
            (click)="showAirWorthnessNotice()"
            *ngIf="hasAirworthiness"
            tooltip="Airworthiness Notice"
            placement="left"
         
        >
            <i class="fa-light fa-clipboard-list-check"></i>
                     Airworthiness Notices
            </button>
            <button
                class="btn btn-inline"
                (click)="showMaintenanceReportsDialogue()"
                tooltip="Maintenance reports"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-maintenance-reports"
            >
                <span class="fa-print fa"></span>
            </button>
            <button
                class="btn btn-inline"
                (click)="newMaintenanceRequest()"
                *ngIf="canRequestMaintenance"
                tooltip="Create a maintenance request"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-maintenance-add"
                data-cy="addMaintenanceRequestBtn"
            >
                <i class="fa-plus fa"></i>
            </button>
   
        </div>
    </div>

    <div class="panel-body">
        <loading-indicator [isLoading]="working"></loading-indicator>
        <plan-highlight [featureFlags]="maintenanceFeature"></plan-highlight>
        <static-table
            *ngIf="maintenanceRequests?.length >0 || textSearch != null"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="maintenanceRequests"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
            data-cy="maintenanceRequestTable"
        >
        </static-table>
        <empty
            *ngIf="
                canRequestMaintenance &&
                maintenanceRequests &&
                textSearch == null  &&
                maintenanceRequests.length == 0
            "
            componentName="maintenance-empty-editable"
        >
        </empty>
        <empty
            *ngIf="
                !canRequestMaintenance &&
                maintenanceRequests &&
                textSearch == null &&
                maintenanceRequests.length == 0
            "
            componentName="maintenance-empty"
        >
        </empty>
        
    </div>
</div>
