import { FormGroup } from '@angular/forms';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';


/**
 * A generic preHide implementation
 * @param formGroup formGroup to check if it is dirty
 * @param commonDialoguesService dialogues service to use to show message
 * @param cancelAction the cleanup/cancel action to run
 */
export function preHide(
    formGroup: FormGroup, // Removed the incorrect generic usage
    commonDialoguesService: CommonDialoguesService,
    cancelAction: () => PromiseLike<any> = () => Promise.resolve()
): Promise<void> {
    if (formGroup.dirty) {
        return commonDialoguesService.showConfirmationDialogue(
            'Confirm Cancel',
            `You have unsaved changes, are you sure you want to cancel?`,
            'Yes',
            cancelAction
        );
    }
    return Promise.resolve();
}

/**
 * A wrapper function that takes a function and returns a promise that resolves or rejects
 * depending on the return value of the function.
 * @param fn a function that returns whether to close or not
 */
export function preHideCheck(fn: () => boolean): Promise<void> {
    return fn() ? Promise.resolve() : Promise.reject();
}
