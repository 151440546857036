import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
    selector: 'formly-editable-ng-select',
    template: `
        <div class="form-group">
            <ng-select
                [items]="to.options"
                [clearable]="!to.required"
                [formControl]="formControl"
                appendTo="body"
                [addTag]="addTag"
            >
            </ng-select>
        </div>
    `
})
export class FormlyEditableNgSelect extends FieldType {
    addTag(tag: string) {
        return tag;
    }
}
