<div class="airspace-authorisation-container fill">
    <ng-container [ngSwitch]="airspaceApprovalState">
        <ng-container *ngSwitchCase="'PASS'">
            <div class="airspace-authorisation-result pass fcol-6">
                <div
                    style="
                        line-height: 32px;
                        display: flex;
                        text-transform: uppercase;
                        font-weight: bold;
                    "
                >
                    <span
                        style="
                            font-size: 32px;
                            margin-right: 15px;
                            font-weight: normal;
                        "
                        class="fal fa-radar"
                    ></span>
                    Airspace is clear for takeoff
                </div>
            </div>
            <div
                class="airspace-authorisation-action pass fcol-5"
                *ngIf="availableAuthorisationType == null"
            >
                <div
                    style="
                        position: absolute;
                        margin: auto;
                        color: #424b5a;
                        opacity: 0.1;
                        font-size: 150px;
                    "
                >
                    <span class="fa fa-solid fa-sparkles"></span>
                </div>
            </div>
            <div
                class="airspace-authorisation-action pass fcol-5"
                *ngIf="availableAuthorisationType != null"
            >
                <div>
                    {{ availableAuthorisationType | formatAuthorisationType }}
                    Authorisation is not required
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'AUTO_APPROVAL'">
            <div class="airspace-authorisation-result authorise">
                <div>
                    {{ availableAuthorisationType | formatAuthorisationType }}
                    authorisation needed for mission
                    <div class="authorisation-icon">
                        <span
                            style="font-size: 32px; text-align: center"
                            [ngClass]="{
                                'radar-optional': isOptional,
                                'radar-authorise': !isOptional
                            }"
                            class="fal fa-radar top-buffer"
                        ></span>
                    </div>
                </div>
            </div>
            <div
                class="airspace-authorisation-action"
                [ngClass]="{
                    authorise: !isOptional,
                    'authorise-disabled': isOptional
                }"
            >
                <div class="authorise-action-content">
                    <p
                        *ngIf="
                            availableAuthorisationType ==
                            'AUS_CASA_AUTHORISATION'
                        "
                    >
                        Partial or all eligibility criteria met for
                        {{
                            availableAuthorisationType | formatAuthorisationType
                        }}
                        Automated Authorisation
                    </p>
                    <p
                        *ngIf="
                            availableAuthorisationType !=
                            'AUS_CASA_AUTHORISATION'
                        "
                    >
                        <span *ngIf="isOptional" style="line-height: 2em">
                            Authorisation in Non-Controlled Airspace is
                            optional.<br />
                        </span>
                        Authorisation will determine if you are eligible to
                        enter airspace at the location and time of your mission.
                    </p>
                    <button
                        *ngIf="
                            availableAuthorisationType != null &&
                            airspaceCheckCanFly &&
                            userCanRequestAirspaceAuthorisation
                        "
                        type="button"
                        class="btn btn-default"
                        (click)="showAirspaceAuthorisations()"
                        [disabled]="
                            (availableAuthorisationType == 'NZL_AIRSHARE' &&
                                !hasRuleset) ||
                            disabled ||
                            working
                        "
                    >
                        Request
                        {{
                            availableAuthorisationType | formatAuthorisationType
                        }}
                        Authorisation
                        <span
                            class="overlay"
                            [tooltip]="
                                availableAuthorisationType == 'NZL_AIRSHARE' &&
                                !hasRuleset
                                    ? 'A workflow with a valid authority is required to request authorisation'
                                    : disabled
                                    ? isDummy
                                        ? 'Cannot request authorisation for dummy missions'
                                        : 'Mission is not in a valid state to request authorisation'
                                    : null
                            "
                            placement="top"
                        ></span>
                    </button>
                    <p
                        *ngIf="
                            availableAuthorisationType ==
                            'AUS_CASA_AUTHORISATION'
                        "
                    >
                        Other options are available, such as initiating a manual
                        area authorisation or revising the mission plan
                    </p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'BLOCKED'">
            <div class="airspace-authorisation-result block fcol-6">
                <div
                    style="
                        line-height: 32px;
                        display: flex;
                        text-transform: uppercase;
                        font-weight: bold;
                    "
                >
                    <span
                        style="
                            font-size: 32px;
                            margin-right: 15px;
                            font-weight: normal;
                        "
                        class="fal fa-radar"
                    ></span>
                    Cannot Fly Here
                </div>
            </div>
            <div
                class="airspace-authorisation-action block fcol-5"
                *ngIf="availableAuthorisationType == null"
            >
                <div
                    style="
                        position: absolute;
                        margin: auto;
                        color: #424b5a;
                        opacity: 0.1;
                        font-size: 150px;
                    "
                >
                    <span class="fa fa-solid fa-sparkles"></span>
                </div>
            </div>
            <div
                class="airspace-authorisation-action block fcol-5"
                *ngIf="availableAuthorisationType != null"
            >
                <div>
                    Mission plan has issues preventing a
                    {{ availableAuthorisationType | formatAuthorisationType }}
                    Automated Authorisation
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PENDING'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div class="airspace-authorisation-action pending fill">
                <div class="vertical-container">
                    <div class="authorisation-result-title pending">
                        <span class="fal fa-radar"></span><br />
                        This Mission has a pending authorisation
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                        <button
                            *ngIf="
                                cancelOrClose === 'CANCEL' &&
                                availableAuthorisationType != null
                            "
                            type="button"
                            class="btn btn-default left-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="cancelAuthorisation()"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'APPROVED'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action pass fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title pass">
                        <span class="fal fa-radar"></span><br />
                        This Mission has authorisation to fly
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                        <button
                            *ngIf="
                                cancelOrClose === 'CANCEL' &&
                                availableAuthorisationType != null
                            "
                            type="button"
                            class="btn btn-default left-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="cancelAuthorisation()"
                        >
                            Cancel
                        </button>
                        <button
                            *ngIf="
                                cancelOrClose === 'CLOSE' &&
                                availableAuthorisationType != null
                            "
                            type="button"
                            class="btn btn-default left-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="closeAuthorisation()"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'ALTERNATIVE'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action pass fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title pass">
                        <span class="fal fa-radar"></span><br />
                        This Mission has an authorised operation
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PROVISIONAL'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action pass fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title pass">
                        <span class="fal fa-radar"></span><br />
                        This Mission has provisional authorisation
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                        <button
                            *ngIf="
                                cancelOrClose === 'CANCEL' &&
                                availableAuthorisationType != null
                            "
                            type="button"
                            class="btn btn-default left-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="cancelAuthorisation()"
                        >
                            Cancel
                        </button>
                        <button
                            *ngIf="
                                cancelOrClose === 'CLOSE' &&
                                availableAuthorisationType != null
                            "
                            type="button"
                            class="btn btn-default left-buffer"
                            style="max-width: 132px; min-width: 85px"
                            [disabled]="working"
                            (click)="closeAuthorisation()"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'CANCELLED'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action block fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title fail">
                        <span class="fal fa-radar"></span><br />
                        Authorisation cancelled
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 128px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                        <button
                            *ngIf="
                                availableAuthorisationType != null &&
                                airspaceCheckCanFly &&
                                canRequestNew &&
                                !isMissionDetailsView
                            "
                            type="button"
                            class="btn btn-default left-buffer"
                            style="
                                max-width: 128px;
                                min-width: 85px;
                                white-space: normal;
                            "
                            [disabled]="disabled || working"
                            (click)="requestNewAirspaceAuthorisation.emit()"
                        >
                            Request New
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'REJECTED'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action block fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title fail">
                        <span class="fal fa-radar"></span><br />
                        This Mission has a rejected authorisation
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 128px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                        <button
                            *ngIf="
                                availableAuthorisationType != null &&
                                airspaceCheckCanFly &&
                                canRequestNew
                            "
                            type="button"
                            class="btn btn-default left-buffer"
                            style="
                                max-width: 128px;
                                min-width: 85px;
                                white-space: normal;
                            "
                            [disabled]="disabled || working"
                            (click)="requestNewAirspaceAuthorisation.emit()"
                        >
                            Request New
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'RESCINDED'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action block fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title fail">
                        <span class="fal fa-radar"></span><br />
                        This Mission has a rescinded authorisation
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 128px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'RESCINDED_AWAITING'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action block fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title fail">
                        <span class="fal fa-radar"></span><br />
                        This Mission has a rescinded authorisation that is
                        awaiting acknowledgement
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 128px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'INVALID'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div
                class="airspace-authorisation-action block fill"
                style="padding: 0; justify-content: center"
            >
                <div class="vertical-container">
                    <div class="authorisation-result-title fail">
                        <span class="fal fa-radar"></span><br />
                        This Mission has an Invalid authorisation that needs to
                        be cancelled by you
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default right-buffer"
                            style="max-width: 128px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'CLOSED'">
            <div class="airspace-authorisation-result fcol-7">
                <div class="vertical-container flex-centered">
                    <airspace-authorisation-summary
                        [authorisations]="approvedAirspaceAuthorisations"
                        [authorisationStatus]="airspaceApprovalState"
                        (showAuthorisation)="showAirspaceAuthorisations()"
                    ></airspace-authorisation-summary>
                </div>
            </div>
            <div class="airspace-authorisation-action closed fill">
                <div class="vertical-container">
                    <div class="authorisation-result-title closed">
                        <span class="fal fa-radar"></span><br />
                        {{
                            availableAuthorisationType | formatAuthorisationType
                        }}
                        authorisation closed
                    </div>
                    <div class="horizontal-container flex-centered">
                        <button
                            type="button"
                            class="btn btn-default"
                            style="max-width: 128px; min-width: 85px"
                            [disabled]="working"
                            (click)="showAirspaceAuthorisations()"
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'ADVISE_NOT_AVAILABLE'">
            <div class="airspace-authorisation-result advise fcol-6">
                <div
                    style="
                        line-height: 32px;
                        display: flex;
                        text-transform: uppercase;
                        font-weight: bold;
                    "
                >
                    <span
                        style="
                            font-size: 32px;
                            margin-right: 15px;
                            font-weight: normal;
                        "
                        class="fal fa-radar"
                    ></span>
                    Airspace Warning
                </div>
            </div>
            <div
                class="airspace-authorisation-action advise fcol-5"
                *ngIf="!availableAuthorisationType != null"
            >
                <div
                    style="
                        position: absolute;
                        margin: auto;
                        color: #424b5a;
                        opacity: 0.1;
                        font-size: 150px;
                    "
                >
                    <span class="fa fa-solid fa-sparkles"></span>
                </div>
            </div>
            <div
                class="airspace-authorisation-action advise fcol-5"
                *ngIf="availableAuthorisationType != null"
            >
                <div
                    style="
                        position: absolute;
                        margin: auto;
                        color: #424b5a;
                        opacity: 0.1;
                        font-size: 150px;
                    "
                >
                    <span class="fa fa-solid fa-sparkles"></span>
                </div>
                <div style="position: relative">
                    Check the airspace checker results before proceeding
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'ADVISE_INCOMPLETE'">
            <div class="airspace-authorisation-result fcol-6">
                <div
                    style="
                        line-height: 32px;
                        display: flex;
                        text-transform: uppercase;
                        font-weight: bold;
                    "
                >
                    <span
                        style="
                            font-size: 32px;
                            margin-right: 15px;
                            font-weight: normal;
                        "
                        class="fal fa-radar"
                    ></span>
                    {{ availableAuthorisationType | formatAuthorisationType }}
                    Authorisations
                </div>
            </div>
            <div class="airspace-authorisation-action incomplete fcol-5">
                <div
                    style="
                        position: absolute;
                        margin: auto;
                        color: #424b5a;
                        opacity: 0.1;
                        font-size: 150px;
                    "
                >
                    <span class="fa fa-solid fa-sparkles"></span>
                </div>
                <div style="position: relative">
                    Authorisation can be requested after minimum data
                    requirements are met: RPIC, Flight Area defined, Workflow,
                    Date and Duration of your mission.
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'UNKNOWN'">
            <div class="airspace-authorisation-result">
                Unknown Airspace Authorisation State
            </div>
            <div class="airspace-authorisation-action none"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'CHECKING'">
            <div class="airspace-authorisation-result"></div>
            <div class="airspace-authorisation-action"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'NO_CHECK'">
            <div class="airspace-authorisation-result"></div>
            <div class="airspace-authorisation-action none"></div>
        </ng-container>
        <airspace-unavailable-alert
            *ngSwitchCase="'OPERATION_FAILED'"
            [show]="true"
        ></airspace-unavailable-alert>
    </ng-container>
</div>
<ngx-loading
    [show]="
        (airspaceCheckService.resultStatus$ | async) === 'CHECKING' ||
        checkingAuthorisation ||
        (workTracker.observable | async)
    "
></ngx-loading>
