import { Injectable } from '@angular/core';
import {
    InviteDto,
    SimpleOrganisationDto,
    SimplePersonDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Apollo, gql } from 'apollo-angular';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

export interface OnboardingRequirements {
    canCreateOrganisation: boolean;
    organisationDomainExists: boolean;
    hasExistingOrganisations: boolean;
}

export interface Invite {
    id: number;
    recipientEmail?: string;
    status: InviteDto.Status;
    requiresLicence: boolean;
    recipient: SimplePersonDto;
    organisation: SimpleOrganisationDto;
}

@Injectable()
export class PersonalDetailsDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private invitesSource = new ReplaySubject<Invite[]>(1);
    private createOrganisationsResultSource =
        new ReplaySubject<OnboardingRequirements>(1);

    working$ = this.workingSource.asObservable();
    invites$ = this.invitesSource.asObservable();
    createOrganisationsResult$ =
        this.createOrganisationsResultSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private apollo: Apollo) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.invitesSource.complete();
        this.createOrganisationsResultSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findPersonalDetailsForUser() {
        this.apollo
            .query<{
                invites: Invite[];
                organisationRequirements: OnboardingRequirements;
            }>({
                query: gql`
                    {
                        invites: findInvitesForCurrentUser {
                            id
                            recipientEmail
                            recipient {
                                id
                                firstName
                                lastName
                            }
                            organisation {
                                id
                                name
                            }
                            status
                            requiresLicence
                        }
                        organisationRequirements: findOnboardingRequirements {
                            canCreateOrganisation
                            organisationDomainExists
                            hasExistingOrganisations
                        }
                    }
                `
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data)
            )
            .subscribe(result => {
                this.invitesSource.next(result.invites);
                this.createOrganisationsResultSource.next(
                    result.organisationRequirements
                );
            });
    }
}
