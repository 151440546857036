<ng-container screenAnalytics="battery-view">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-batteries.png" />
        </div>
        <h3 class="modal-title">{{ isEdit ? 'Edit' : 'View' }} Battery</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body inner-container no-footer">
        <tabset
            #batteryTabs
            vertical="true"
            type="pills"
            class="tab-set-vertical"
        >
            <tab>
                <ng-template tabHeading>
                    <i class="far fa-list-ul tab-title-icon"></i>
                    <span>Details</span>
                </ng-template>
                <battery-details
                    class="modal-body-content"
                    *ngIf="!isEdit"
                    [organisation]="organisation"
                    [battery]="battery"
                    (edit)="toggleViewEdit()"
                    (deleted)="onDeleted()"
                >
                </battery-details>
                <battery-edit
                    #batteryEdit
                    class="modal-body-content"
                    *ngIf="isEdit"
                    [organisation]="organisation"
                    [battery]="battery"
                    (updated)="batteryUpdated($event)"
                    (cancelled)="toggleViewEdit()"
                >
                </battery-edit>
            </tab>
            <tab>
                <ng-template tabHeading>
                    <i class="far fa-memo-pad tab-title-icon"></i>
                    <span>Notes</span>
                </ng-template>
                <notes-view *ngIf="!isEdit"
                            class="modal-body-content"
                            [noteType]="NoteType.Battery"
                            [source]="battery"
                            (edit)="toggleViewEdit()"
                ></notes-view>
                <notes-edit
                    *ngIf="isEdit"
                    class="modal-body-content"
                    [noteType]="NoteType.Battery"
                    [source]="battery"
                    (cancelled)="toggleViewEdit()"
                >
                </notes-edit>
            </tab>
            <tab>
                <ng-template tabHeading>
                    <i class="far fa-plane tab-title-icon"></i>
                    <span>Flight History</span>
                </ng-template>
                <div class="container-scroll modal-body-content">
                    <flight-history-summary
                        [battery]="battery"
                    ></flight-history-summary>
                    <logged-flights
                        [battery]="battery"
                        [managingOrganisationId]="organisation.id"
                    ></logged-flights>
                </div>
            </tab>
            <tab *ngIf="hasBatteryHealth">
                <ng-template tabHeading>
                    <i class="far fa-address-card tab-title-icon"></i>
                    <span>Battery Health</span>
                </ng-template>
                <battery-health
                    class="container-scroll modal-body-content"
                    [battery]="battery"
                    [organisationId]="organisation.id"
                ></battery-health>
            </tab>
            <tab>
                <ng-template tabHeading>
                    <i class="far fa-wrench tab-title-icon"></i>
                    <span>Maintenance History</span>
                </ng-template>
                <div class="container-scroll modal-body-content">
                    <plan-highlight
                        [featureFlags]="maintenanceScheduleFeature"
                    ></plan-highlight>
                    <maintenance-schedule-config
                        *ngIf="hasScheduledMaintenance"
                        [resourceId]="battery.id"
                        [resourceType]="'BATTERY'"
                        [managingOrganisationId]="managingOrganisationId"
                        [maintenanceScheduleAssignment]="
                            battery.assignedMaintenanceSchedule
                        "
                        [canAssign]="battery.availableActions.canEdit"
                        [organisationId]="battery.organisationId"
                    ></maintenance-schedule-config>
                    <h4 class="section-heading">
                        <span>Maintenance History</span>
                        <div></div>
                    </h4>
                    <maintenance-history
                        [organisationId]="organisation.id"
                        [resourceType]="'BATTERY'"
                        [resourceId]="battery.id"
                    ></maintenance-history>
                </div>
            </tab>
        </tabset>
    </div>
    <!-- The "modal-help-switch" class allows a "floating" enhanced help button for cases like this with tabs & children with their own footers -->
</ng-container>
