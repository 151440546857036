<span *ngIf="!messages || !messages.length">No messages</span>
<div
    class="messages-container modal-scrollbar"
    *ngIf="messages && messages.length > 0"
>
    <div
        class="message"
        *ngFor="let message of messages; let last = last"
        [ngClass]="{ 'last-message': last }"
    >
        <div class="row">
            <div class="message-sender">
                <span *ngIf="message.updateTime" class="message-header"
                    >Sent at
                    {{ message.updateTime | date: "H:mm 'on' dd/MM/yyyy" }}
                    -
                    <span *ngIf="message.from" class="message-sender-name"
                        >by {{ message.from }}</span
                    ></span
                >
            </div>
            <div class="message-body">
                {{ message.message }}
            </div>
        </div>
    </div>
</div>
