import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'api-key-confirmation',
    templateUrl: './api-key-confirmation-dialogue.component.html'
})
export class ApiKeyCreationDialogue {
    @Input() identifier: string;

    @Input() key: string;

    constructor(private modal: BsModalRef) {}

    cancel() {
        this.modal.hide();
    }
}
