/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.18-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimpleOrganisationDto } from './simpleOrganisationDto';
import { AirspaceJurisdictionDto } from './airspaceJurisdictionDto';
import { OperatingCategoryValuesDtoEndorsementReference } from './operatingCategoryValuesDtoEndorsementReference';
import { AuthorityTypeActions } from './authorityTypeActions';
import { AuthorityTypeNominatedRoleDto } from './authorityTypeNominatedRoleDto';
import { SimpleAuthorityTypeDto } from './simpleAuthorityTypeDto';
import { DisplayableAuthorityTypeDto } from './displayableAuthorityTypeDto';
import { RulesetDto } from './rulesetDto';
import { OrganisationAuthorityDto } from './organisationAuthorityDto';


/**
 * Describes an authority that an organisation can have, and its authority if it exists
 */
export interface OrganisationAuthorityGroup { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The name of this authority type
     */
    name: string;
    issuedBy: SimpleOrganisationDto;
    /**
     * Does the authority have an expiry date
     */
    hasExpiry: boolean;
    /**
     * This authority type will also record an identifier such as a serial number
     */
    hasIdentifier: boolean;
    /**
     * Does the authority type support multiple instances
     */
    hasMultipleInstances: boolean;
    /**
     * Can the authority type have a workflow associated with it
     */
    hasWorkflow: boolean;
    /**
     * ${swagger.AuthorityType.isPrimary}
     */
    isPrimary: boolean;
    /**
     * The type of organisation that this authority applies to
     */
    appliesTo: OrganisationAuthorityGroup.AppliesTo;
    /**
     * The customised name for identifier
     */
    identifierLabel?: string;
    /**
     * The pattern to validate the identifier against
     */
    identifierValidationRegex?: string;
    /**
     * ${swagger.AuthorityType.issuingMechanism}
     */
    issuingMechanism: OrganisationAuthorityGroup.IssuingMechanism;
    /**
     * The customised name for authority type
     */
    abbreviation?: string;
    /**
     * Is this authority type requires approval
     */
    requiresApproval: boolean;
    jurisdiction?: AirspaceJurisdictionDto;
    /**
     * Help text that describes how to get this authority type
     */
    helperText: string;
    /**
     * A short description of the authority type
     */
    description: string;
    /**
     * What kind of activity is this applicable to
     */
    activityType?: OrganisationAuthorityGroup.ActivityType;
    /**
     * The date when the authority type was discontinued
     */
    discontinueDate?: string;
    /**
     * The message to display on the discontinued authority type
     */
    discontinueMessage?: string;
    ruleset?: RulesetDto;
    /**
     * The equivalent authority types of an authority type
     */
    equivalencies?: Array<DisplayableAuthorityTypeDto>;
    dependentOnAuthorityType?: SimpleAuthorityTypeDto;
    implicitEndorsementList?: Array<OperatingCategoryValuesDtoEndorsementReference>;
    availableEndorsementList?: Array<OperatingCategoryValuesDtoEndorsementReference>;
    /**
     * Any authorities of this type that exist for this organisation
     */
    authorities?: Array<OrganisationAuthorityDto>;
    /**
     * The associated roles of an authority type
     */
    nominatedRoles: Array<AuthorityTypeNominatedRoleDto>;
    availableActions: AuthorityTypeActions;
}
export namespace OrganisationAuthorityGroup {
    export type AppliesTo = 'PERSONAL' | 'COMMERCIAL' | 'RPA';
    export const AppliesTo = {
        PERSONAL: 'PERSONAL' as AppliesTo,
        COMMERCIAL: 'COMMERCIAL' as AppliesTo,
        RPA: 'RPA' as AppliesTo
    };
    export type IssuingMechanism = 'SELF_REPORTED' | 'ISSUER' | 'AUTOMATIC';
    export const IssuingMechanism = {
        SELF_REPORTED: 'SELF_REPORTED' as IssuingMechanism,
        ISSUER: 'ISSUER' as IssuingMechanism,
        AUTOMATIC: 'AUTOMATIC' as IssuingMechanism
    };
    export type ActivityType = 'COMMERCIAL' | 'NON_COMMERCIAL';
    export const ActivityType = {
        COMMERCIAL: 'COMMERCIAL' as ActivityType,
        NON_COMMERCIAL: 'NON_COMMERCIAL' as ActivityType
    };
}



