<ng-container screenAnalytics="currency-configuration">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Currency Requirements</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="formGroup" novalidate>
        <div class="modal-body no-footer">
            <plan-highlight
                [featureFlags]="currencyFeatureFlag"
            ></plan-highlight>
            <enhanced-help componentName="screen" [compact]="true">
            </enhanced-help>
            <loading-indicator [isLoading]="working"></loading-indicator>
            <div class="table-container side-sheet-table">
                <table class="table" *ngIf="!working">
                    <thead>
                        <tr>
                            <th
                                style="width: 30%"
                                tooltip="Currency Conditions"
                                placement="top"
                                enhancedHelp="currency-conditions-column"
                                [enhancedHelpOptional]="true"
                            >
                                Currency Conditions
                            </th>
                            <th
                                style="width: 30%"
                                tooltip="Eligible Flight Conditions"
                                placement="top"
                                enhancedHelp="eligible-flight-conditions-column"
                                [enhancedHelpOptional]="true"
                            >
                                Eligible Flight Conditions
                            </th>
                            <th
                                tooltip="Currency Requirements"
                                placement="top"
                                enhancedHelp="currency-requirements-column"
                                [enhancedHelpOptional]="true"
                            >
                                Currency Requirements
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody formGroupName="edit">
                        <tr *ngFor="let config of currencyConfigurations">
                            <td>
                                <ng-container
                                    *ngIf="editingConfig != config.id"
                                >
                                    <ng-container
                                        *ngFor="
                                            let condition of config.currencyOperatingConditionList;
                                            let lastCondition = last
                                        "
                                    >
                                        <span
                                            *ngFor="
                                                let value of condition.values;
                                                let last = last
                                            "
                                            ><ng-container
                                                *ngIf="value.type != 'RPA_MTOW'"
                                                >{{
                                                    operatingConditionsValueLookup[
                                                        value.value
                                                    ]
                                                }}</ng-container
                                            >
                                            <ng-container
                                                *ngIf="value.type == 'RPA_MTOW'"
                                                ><{{
                                                    value.value | formatMTOW
                                                }}</ng-container
                                            >{{
                                                last && lastCondition
                                                    ? ''
                                                    : ', '
                                            }}</span
                                        >
                                    </ng-container>
                                    <span
                                        *ngIf="
                                            config
                                                .currencyOperatingConditionList
                                                .length === 0
                                        "
                                        >Any</span
                                    >
                                </ng-container>
                                <ng-container
                                    *ngIf="editingConfig == config.id"
                                >
                                    <div
                                        class="input-group"
                                        style="width: 100%"
                                    >
                                        <ng-select
                                            [items]="operatingConditions"
                                            appendTo="body"
                                            [clearable]="false"
                                            [groupBy]="typesGroup"
                                            [multiple]="true"
                                            formControlName="currencyOperatingConditionList"
                                            placeholder="Select Currency Conditions"
                                            [readonly]="working"
                                        >
                                            <ng-template
                                                ng-template
                                                ng-option-tmp
                                                let-item="item"
                                                let-index="index"
                                            >
                                                {{ item.value }}
                                            </ng-template>
                                            <ng-template
                                                ng-label-tmp
                                                let-item="item"
                                                let-clear="clear"
                                            >
                                                <span
                                                    class="ng-value-icon left"
                                                    (click)="clear(item)"
                                                    aria-hidden="true"
                                                    >x</span
                                                >
                                                <span class="ng-value-label">{{
                                                    item.value
                                                }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="editingConfig != config.id"
                                >
                                    <ng-container
                                        *ngFor="
                                            let condition of config.requiredOperatingConditionList;
                                            let lastCondition = last
                                        "
                                    >
                                        <span
                                            *ngFor="
                                                let value of condition.values;
                                                let last = last
                                            "
                                            ><ng-container
                                                *ngIf="value.type != 'RPA_MTOW'"
                                                >{{
                                                    operatingConditionsValueLookup[
                                                        value.value
                                                    ]
                                                }}</ng-container
                                            >
                                            <ng-container
                                                *ngIf="value.type == 'RPA_MTOW'"
                                                ><{{
                                                    value.value | formatMTOW
                                                }}</ng-container
                                            >{{
                                                last && lastCondition
                                                    ? ''
                                                    : ', '
                                            }}</span
                                        >
                                    </ng-container>
                                    <span
                                        *ngIf="
                                            config
                                                .requiredOperatingConditionList
                                                .length === 0
                                        "
                                        >Any</span
                                    >
                                </ng-container>
                                <ng-container
                                    *ngIf="editingConfig == config.id"
                                >
                                    <div
                                        class="input-group"
                                        style="width: 100%"
                                    >
                                        <ng-select
                                            [items]="operatingConditions"
                                            appendTo="body"
                                            [clearable]="false"
                                            [groupBy]="typesGroup"
                                            [multiple]="true"
                                            formControlName="requiredOperatingConditionIdList"
                                            placeholder="Select Flight Conditions"
                                            [readonly]="working"
                                        >
                                            <ng-template
                                                ng-template
                                                ng-option-tmp
                                                let-item="item"
                                                let-index="index"
                                            >
                                                {{ item.value }}
                                            </ng-template>
                                            <ng-template
                                                ng-label-tmp
                                                let-item="item"
                                                let-clear="clear"
                                            >
                                                <span
                                                    class="ng-value-icon left"
                                                    (click)="clear(item)"
                                                    aria-hidden="true"
                                                    >x</span
                                                >
                                                <span class="ng-value-label">{{
                                                    item.value
                                                }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="editingConfig != config.id"
                                >
                                    {{ config.days }} days,<br />
                                    {{ config.requiredFlights }} flights,<br />
                                    {{
                                        config.requiredFlightTimeSeconds
                                            | formatDuration | defaultTo
                                    }}
                                    total flight time
                                </ng-container>
                                <ng-container
                                    *ngIf="editingConfig == config.id"
                                >
                                    <div
                                        class="horizontal-container vertical-align"
                                    >
                                        <label
                                            style="
                                                min-width: 20%;
                                                margin-right: 20px;
                                            "
                                            >Days</label
                                        >
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="days"
                                            min="0"
                                        />
                                    </div>
                                    <div
                                        class="horizontal-container vertical-align"
                                    >
                                        <label
                                            style="
                                                min-width: 20%;
                                                margin-right: 20px;
                                            "
                                            ># Flights</label
                                        >
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="requiredFlights"
                                            min="0"
                                        />
                                    </div>
                                    <div
                                        class="horizontal-container vertical-align"
                                    >
                                        <label
                                            style="
                                                min-width: 20%;
                                                margin-right: 20px;
                                            "
                                            >Flight Time</label
                                        >
                                        <duration
                                            formControlName="requiredFlightTimeSeconds"
                                            [showHour]="true"
                                            [showMinute]="true"
                                            [showSecond]="true"
                                            [clearable]="false"
                                        >
                                        </duration>
                                    </div>
                                </ng-container>
                            </td>
                            <td class="actions">
                                <div
                                    class="inline"
                                    *ngIf="editingConfig == config.id"
                                >
                                    <button
                                        class="btn btn-sm btn-primary"
                                        type="button"
                                        (click)="updateConfig(config)"
                                    >
                                        Save
                                    </button>
                                    <button
                                        class="btn btn-sm btn-link"
                                        type="button"
                                        (click)="cancelEditMode(entry)"
                                    >
                                        Cancel
                                    </button>
                                </div>

                                <div
                                    class="inline"
                                    *ngIf="editingConfig != config?.id"
                                >
                                    <button
                                        class="btn btn-sm btn-tertiary"
                                        *ngIf="config?.id"
                                        (click)="showEditMode(config)"
                                    >
                                        <span class="fal fa-pencil-alt"></span>
                                    </button>
                                    <button
                                        class="btn btn-sm btn-tertiary btn-delete"
                                        *ngIf="config?.id"
                                        (click)="deleteCurrencyConfig(config)"
                                    >
                                        <span class="fal fa-trash-alt"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot formGroupName="add">
                        <tr>
                            <td>
                                <div class="input-group" style="width: 100%">
                                    <ng-select
                                        [items]="operatingConditions"
                                        bindLabel="value"
                                        bindValue="id"
                                        appendTo="body"
                                        [clearable]="false"
                                        [groupBy]="typesGroup"
                                        [multiple]="true"
                                        formControlName="currencyOperatingConditionList"
                                        placeholder="Select Currency Conditions"
                                        [readonly]="working"
                                    >
                                    </ng-select>
                                </div>
                            </td>
                            <td>
                                <div class="input-group" style="width: 100%">
                                    <ng-select
                                        [items]="operatingConditions"
                                        bindLabel="value"
                                        bindValue="id"
                                        appendTo="body"
                                        [clearable]="false"
                                        [groupBy]="typesGroup"
                                        [multiple]="true"
                                        formControlName="requiredOperatingConditionIdList"
                                        placeholder="Select Flight Conditions"
                                        [readonly]="working"
                                    >
                                    </ng-select>
                                </div>
                            </td>
                            <td>
                                <div
                                    class="horizontal-container vertical-align"
                                >
                                    <label
                                        style="
                                            min-width: 20%;
                                            margin-right: 20px;
                                        "
                                        >Days</label
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        formControlName="days"
                                        min="0"
                                    />
                                </div>
                                <div
                                    class="horizontal-container vertical-align"
                                >
                                    <label
                                        style="
                                            min-width: 20%;
                                            margin-right: 20px;
                                        "
                                        ># Flights</label
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        formControlName="requiredFlights"
                                        min="0"
                                    />
                                </div>
                                <div
                                    class="horizontal-container vertical-align"
                                >
                                    <label
                                        style="
                                            min-width: 20%;
                                            margin-right: 20px;
                                        "
                                        >Flight Time</label
                                    >
                                    <duration
                                        formControlName="requiredFlightTimeSeconds"
                                        [showHour]="true"
                                        [showMinute]="true"
                                        [showSecond]="true"
                                        [clearable]="false"
                                    >
                                    </duration>
                                </div>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    [disabled]="working"
                                    (click)="addConfig()"
                                >
                                    Add
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </form>
</ng-container>
