<div class="modal-header">
    <div class="panel-heading-icon">
        <img imgPath="/icons/icon-maintenance.png" />
    </div>
    <h3 class="modal-title">Maintenance Reports</h3>
    <button type="button" (click)="cancel()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <tabset>
        <tab [heading]="'Maintenance List'">
            <div class="modal-body">
                <p>
                    Download a CSV report of all maintenance in this
                    organisation.
                </p>
            </div>
            <div class="modal-footer">
                <button
                    (click)="downloadMaintenanceList()"
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working"
                    angulartics2On="click"
                    angularticsAction="dashboard-report-maintenance"
                >
                    Download
                </button>
            </div>
        </tab>
        <ng-container *ngFor="let printOption of printOptions">
            <tab [heading]="printOption.name">
                <div class="modal-body">
                    <p class="top-buffer">
                        {{ printOption.description }}
                    </p>
                </div>
                <div class="modal-footer">
                    <button
                        (click)="printReport(printOption)"
                        type="button"
                        class="btn btn-primary"
                        [disabled]="working"
                        angulartics2On="click"
                        angularticsAction="dashboard-report-maintenance"
                    >
                        <span class="fa fa-print"></span> Print
                        {{ printOption.name }}
                    </button>
                </div>
            </tab>
        </ng-container>
    </tabset>
</div>
