import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EquipmentDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
  selector: 'equipment-list-item',
  templateUrl: './equipment-list-item.component.html',
  styleUrls: ['./equipment-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentListItemComponent {
  @Input() item: EquipmentDto;
}
