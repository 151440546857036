import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * This service is used to manage the state of the UI when a component enters
 * a state where it needs exclusive control of the UI. For instance, entering
 * an edit mode.
 *
 * WARNING: Locking or subscribing in the component constructor will caused issues
 * because the observable will emit immediately, possibly causing a UI update before the UI
 * has been built. This results in null point errors in the template, and can cause weird
 * rendering issues (duplicate components with *ngIf for instance).
 */
@Injectable()
export class ExclusiveControlService {
    private lockSource = new BehaviorSubject<boolean>(false);
    lock$ = this.lockSource.asObservable().pipe(distinctUntilChanged());

    private unlockRequestFn: () => boolean;

    get locked() {
        return this.lockSource.value;
    }

    ngOnDestroy() {
        this.lockSource.complete();
    }

    lock(unlockRequest: () => boolean) {
        this.lockSource.next(true);
        this.unlockRequestFn = unlockRequest;
        return () => {
            this.lockSource.next(false);
            this.unlockRequestFn = null;
        };
    }

    requestUnlock() {
        if (this.unlockRequestFn != null) {
            if (!this.unlockRequestFn()) {
                return;
            }
            this.unlockRequestFn = null;
        }

        this.lockSource.next(false);
    }
}
