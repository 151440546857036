import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {
    CreateEquipmentTypeCommand,
    EquipmentDto,
    EquipmentModelService,
    EquipmentService,
    EquipmentTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LockedEquipmentFields,
    NameValue,
    PersonsOrganisationDto,
    UpdateEquipmentCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject, from } from 'rxjs';
import { groupBy, mergeMap, takeUntil, toArray } from 'rxjs/operators';

const NOT_LISTED = 'NOT_LISTED';

@Component({
    selector: 'equipment-edit',
    templateUrl: './equipment-edit.component.html'
})
export class EquipmentEdit implements OnInit, OnDestroy {
    @Input() equipment: EquipmentDto;
    @Input() organisation: PersonsOrganisationDto;
    @Output() cancelled = new EventEmitter();
    @Output() updatedEquipment = new EventEmitter<EquipmentDto>();

    equipmentForm: FormGroup;
    equipmentMakeGroup: FormGroup;
    equipmentModelGroup: FormGroup;

    equipmentCategories: NameValue[];
    equipmentMakes: NameValue[];
    equipmentModels: NameValue[];

    lockedFields: LockedEquipmentFields;

    makeListed: boolean;
    modelListed: boolean;
    selectedEquipmentModelID: number = 0;

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    working: boolean = false;

    constructor(
        private logging: FlyFreelyLoggingService,
        private equipmentModelService: EquipmentModelService,
        private commonDialoguesService: CommonDialoguesService,
        private equipmentService: EquipmentService
    ) {
        this.modelListed = true;
        this.makeListed = true;
        this.equipmentCategories = equipmentService.getEquipmentCategories();
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.equipmentMakeGroup = new FormGroup(
            {
                makeSelect: new FormControl(this.equipment.equipmentType.make),
                makeText: new FormControl(this.equipment.equipmentType.make)
            },
            {
                validators: [EquipmentMakeValidator('makeSelect', 'makeText')]
            }
        );
        this.equipmentModelGroup = new FormGroup(
            {
                modelSelect: new FormControl(
                    this.equipment.equipmentType.model
                ),
                modelText: new FormControl(this.equipment.equipmentType.model)
            },
            {
                validators: [
                    EquipmentModelValidator('modelSelect', 'modelText')
                ]
            }
        );
        this.equipmentForm = new FormGroup({
            equipmentCategory: new FormControl(
                this.equipment.equipmentType.equipmentCategory,
                [Validators.required]
            ),
            make: this.equipmentMakeGroup,
            model: this.equipmentModelGroup,
            currentFirmwareVersion: new FormControl(
                this.equipment.currentFirmwareVersion
            ),
            manufacturerSerialNumber: new FormControl(
                this.equipment.manufacturerSerialNumber,
                [Validators.required]
            ),
            name: new FormControl(this.equipment.name, [Validators.required]),
            nfcId: new FormControl(this.equipment.nfcId),
            isDummy: new FormControl(this.equipment.isDummy, [
                Validators.required
            ])
        });

        this.lockedFields = this.equipment.lockedFields;
        this.findMakes();
        this.findModels();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    saveEquipment() {
        if (this.equipmentForm.invalid) {
            return;
        }
        const values = this.equipmentForm.value;
        let make: string;
        let model: string;
        if (values.make.makeSelect && values.make.makeSelect !== 'NOT_LISTED') {
            make = values.make.makeSelect;
        } else {
            make = values.make.makeText;
        }
        if (
            values.model.modelSelect &&
            values.model.modelSelect !== 'NOT_LISTED'
        ) {
            model = values.model.modelSelect;
        } else {
            model = values.model.modelText;
        }

        this.equipmentForm.disable();

        if (
            values.make.makeSelect === NOT_LISTED ||
            values.model.modelSelect === NOT_LISTED
        ) {
            const createTypeCommand: CreateEquipmentTypeCommand = {
                equipmentCategory: values.equipmentCategory,
                make: make,
                model: model,
                organisationId: this.organisation.id
            };
            this.equipmentModelService
                .create(createTypeCommand)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    savedEquipmentType => {
                        const updateEquipmentCommand: UpdateEquipmentCommand = {
                            nfcId: values.nfcId,
                            manufacturerSerialNumber:
                                values.manufacturerSerialNumber,
                            name: values.name,
                            currentFirmwareVersion:
                                values.currentFirmwareVersion,
                            equipmentTypeId: savedEquipmentType.id,
                            isDummy: values.isDummy,
                            initialComponentList: []
                        };
                        this.equipmentService
                            .update(this.equipment.id, updateEquipmentCommand)
                            .pipe(takeUntil(this.ngUnsubscribe$))
                            .subscribe(equipment => {
                                this.updatedEquipment.emit(equipment);
                                this.equipmentForm.markAsPristine();
                                this.logging.success(`Equipment updated`);
                                this.cancel();
                            });
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error saving equipment: ${error.message}`
                        )
                )
                .add(this.workTracker.createTracker());
        } else {
            const updateEquipmentCommand: UpdateEquipmentCommand = {
                nfcId: values.nfcId,
                manufacturerSerialNumber: values.manufacturerSerialNumber,
                name: values.name,
                currentFirmwareVersion: values.currentFirmwareVersion,
                equipmentTypeId:
                    this.selectedEquipmentModelID !== 0
                        ? this.selectedEquipmentModelID
                        : this.equipment.equipmentType.id,
                isDummy: values.isDummy,
                initialComponentList: []
            };
            this.equipmentService
                .update(this.equipment.id, updateEquipmentCommand)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    equipment => {
                        this.updatedEquipment.emit(equipment);
                        this.equipmentForm.markAsPristine();
                        this.logging.success(`Equipment updated`);
                        this.cancel();
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error saving equipment: ${error.message}`
                        )
                )
                .add(this.workTracker.createTracker());
        }
    }

    cancel() {
        if (this.equipmentForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }
    cancelEdit() {
        this.cancelled.emit(null);
    }
    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    findMakes() {
        this.equipmentModelService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((model: EquipmentTypeDto[]) => {
                const filteredEquipmentModel: EquipmentTypeDto[] = model.filter(
                    f =>
                        f.equipmentCategory ===
                        this.equipmentForm.value.equipmentCategory
                );
                const tempequipmentMakes = [];
                const source = from(filteredEquipmentModel);
                // group by make
                const example = source.pipe(
                    groupBy(person => person.make),
                    // return each item in group as array
                    mergeMap(group => group.pipe(toArray()))
                );

                example.subscribe(et => {
                    tempequipmentMakes.push({
                        name: et[0].make,
                        value: et[0].make
                    });
                });

                tempequipmentMakes.push({
                    name: 'Not Listed',
                    value: NOT_LISTED
                });
                this.equipmentMakes = tempequipmentMakes;
            })
            .add(this.workTracker.createTracker());
    }

    findModels() {
        let make: string;
        if (
            this.equipmentForm.controls.make.value.makeSelect ===
                'NOT_LISTED' &&
            this.equipmentForm.controls.make.value.makeSelect !== undefined
        ) {
            make = this.equipmentForm.controls.make.value.makeText;
        } else {
            make = this.equipmentForm.controls.make.value.makeSelect;
        }

        this.equipmentModels = [];
        if (event != null) {
            this.equipmentModelService
                .find(this.organisation.id)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe((equipmentModel: EquipmentTypeDto[]) => {
                    const equipmentTypeFiltered: EquipmentTypeDto[] = equipmentModel.filter(
                        f =>
                            f.equipmentCategory ===
                            this.equipmentForm.controls.equipmentCategory.value
                    );
                    const equipmentMakeFiltered: EquipmentTypeDto[] = equipmentTypeFiltered.filter(
                        f => f.make === make
                    );

                    this.equipmentModels = equipmentMakeFiltered
                        .map(fe => ({
                            name: fe.model,
                            value: fe.model,
                            equipmentModelId: fe.id
                        }))
                        .concat({
                            name: 'Not Listed',
                            value: NOT_LISTED,
                            equipmentModelId: null
                        });
                })
                .add(this.workTracker.createTracker());
        }
    }

    onEquipmentTypeSelectionChange(event: NameValue) {
        this.equipmentForm.controls.makeSelect.reset();
        this.equipmentForm.controls.makeSelect.enable();
        this.equipmentForm.controls.modelSelect.enable();

        this.makeListed = true;
        this.modelListed = true;

        this.equipmentMakes = [];
        this.selectedEquipmentModelID = 0;
        if (event != null) {
            this.findMakes();
        }
    }

    equipmentModelOnChange(event: NameValue | any) {
        if (event !== undefined && event.value === NOT_LISTED) {
            this.modelListed = false;
            this.makeListed = true;
            const equip = this.equipmentMakes.find(
                f => f.value === this.equipmentMakeGroup.value.makeSelect
            ).name;
            this.equipmentMakeGroup.controls.makeSelect.disable();
            this.equipmentMakeGroup.controls.makeText.setValue(equip);
            this.equipmentModelGroup.controls.modelText.enable();

            this.selectedEquipmentModelID = 0;
        } else if (event === undefined) {
            this.modelListed = true;
            this.makeListed = true;
            this.equipmentModelGroup.controls.modelText.reset();
            this.equipmentModelGroup.controls.modelSelect.reset();
        } else {
            this.modelListed = true;
            this.makeListed = true;
            this.equipmentMakeGroup.controls.makeText.enable();
            this.equipmentMakeGroup.controls.makeText.setValue('');
            this.equipmentMakeGroup.controls.makeSelect.enable();
            if (event !== undefined && event.equipmentModelId !== undefined) {
                this.selectedEquipmentModelID = event.equipmentModelId;
            }
        }
    }

    equipmentMakeOnChange(event: NameValue) {
        this.selectedEquipmentModelID = 0;
        if (event !== undefined && event.value === NOT_LISTED) {
            this.makeListed = false;
            this.modelListed = false;
            this.equipmentModelGroup.controls.modelSelect.reset();
            this.equipmentModelGroup.controls.modelSelect.disable();
            this.equipmentModelGroup.controls.modelText.enable();
        } else {
            this.makeListed = true;
            this.modelListed = true;
            this.equipmentModelGroup.controls.modelSelect.enable();
            this.equipmentModelGroup.controls.modelText.reset();
            this.equipmentModelGroup.controls.modelSelect.reset();
            this.equipmentModelGroup.controls.modelText.disable();

            this.findModels();
        }
    }

    clearEquipmentType(event: any) {
        this.equipmentForm.controls.makeSelect.reset();
        this.equipmentForm.controls.makeSelect.disable();
        this.equipmentForm.controls.modelSelect.reset();
        this.equipmentForm.controls.modelSelect.disable();
    }
}

export function EquipmentMakeValidator(
    makeSelectCtrlName: string,
    makeTextCtrlName: string
): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
        if (!group || !group.controls) {
            return;
        }
        const equipmentSelectValue = group.controls[makeSelectCtrlName]
            ? group.controls[makeSelectCtrlName].value
            : null; // to get value in input tag
        const equipmentTextValue = group.controls[makeTextCtrlName]
            ? group.controls[makeTextCtrlName].value
            : null;

        if (equipmentSelectValue && equipmentSelectValue !== '') {
            if (
                equipmentSelectValue === NOT_LISTED &&
                (equipmentTextValue == null || equipmentTextValue === '')
            ) {
                return { requiredField: true };
            }
        } else {
            return { requiredField: true };
        }
    };
}
export function EquipmentModelValidator(
    modelSelectCtrlName: string,
    modelTextCtrlName: string
): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
        if (!group || !group.controls) {
            return;
        }
        const equipmentSelectValue = group.controls[modelSelectCtrlName]
            ? group.controls[modelSelectCtrlName].value
            : null; // to get value in input tag
        const equipmentTextValue = group.controls[modelTextCtrlName]
            ? group.controls[modelTextCtrlName].value
            : null;

        if (equipmentSelectValue && equipmentSelectValue !== '') {
            if (
                equipmentSelectValue === NOT_LISTED &&
                (equipmentTextValue == null || equipmentTextValue === '')
            ) {
                return { requiredField: true };
            }
        } else if (
            equipmentSelectValue === null &&
            !(equipmentTextValue === null || equipmentTextValue === '')
        ) {
            return;
        } else {
            return { requiredField: true };
        }
    };
}
