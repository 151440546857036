<ng-container screenAnalytics="nominated-personnel">
    <div class="modal-header no-icon">
        <h4 id="dialog-child-name" class="modal-title pull-left">
            Nominated Personnel
        </h4>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body vertical-container">
        <enhanced-help componentName="screen"></enhanced-help>
        <div *ngFor="let role of roles; let i = index">
            <h4>{{ role.role.name }}</h4>
            <div class="table-container form">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                <button
                                    type="button"
                                    class="btn-circle btn-link"
                                    (click)="addNewPerson(role)"
                                    [disabled]="
                                        working ||
                                        role.personnel.invalid ||
                                        role.personnel.dirty
                                    "
                                >
                                    <span class="fa fa-plus"></span>
                                </button>
                            </th>
                            <th
                                *ngIf="
                                    role.role.allowOrganisationScope &&
                                    dataService.hasChildOrganisations
                                "
                                tooltip
                                placement="top"
                                enhancedHelp="scope-col"
                            >
                                Scope
                            </th>
                            <th
                                tooltip
                                placement="top"
                                enhancedHelp="person-col"
                            >
                                Personnel
                            </th>
                            <th
                                tooltip
                                placement="top"
                                enhancedHelp="start-date-col"
                            >
                                Start Date
                            </th>
                            <th
                                tooltip
                                placement="top"
                                enhancedHelp="end-date-col"
                            >
                                End Date
                            </th>
                            <th
                                *ngIf="role.role.canApprove"
                                tooltip
                                placement="top"
                                enhancedHelp="approve-own-mission-col"
                            >
                                Can Approve Own Missions
                            </th>
                            <th
                                *ngIf="role.role.hasManageMissions"
                                tooltip
                                placement="top"
                                enhancedHelp="manage-missions-col"
                            >
                                Can Manage Missions
                            </th>
                            <th class="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            nominated-personnel-row
                            *ngFor="
                                let person of role.personnel.controls;
                                let j = index
                            "
                            [person]="person"
                            [role]="role.role"
                            [locked]="role.personnel.dirty && person.pristine"
                            (remove)="remove(role, person, j)"
                            (save)="save(role, person)"
                        ></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-container>
