import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveMapStyleDto, DataLayerDto } from '../model/api';
import { map } from 'rxjs/operators';
import { httpParamSerializer } from './service.helpers';
import { MetadataKeyGroupName } from '../model/ui';
import { FlyFreelyConstants } from '../constants';
import { SourceType } from 'libs/map/src/lib/interfaces';

export interface NamedLayer extends mapboxgl.Layer {
    name: string;
}

export type GroupDynamicData =
    | GroupIdentifierDynamicData
    | GroupPropertiesDynamicData;

export interface GroupIdentifierDynamicData {
    type: 'IDENTIFIER';
    source: string;
    identifierField: string;
}

export interface GroupProgrammaticDynamicData {
    type: 'PROGRAMMATIC';
    source: string;
    identifierField: string;
}

export interface GroupPropertiesDynamicData {
    type: 'PROPERTIES';
    source: string;
}

export interface LayerGroup {
    /**
     * The name of this layer group, for display
     */
    name: string;
    /**
     * If not an identified layer then should this layer group be selected.
     */
    default: boolean;
    /**
     * Which feature properties are used for dynamically controlling the layer
     */
    dynamicData: GroupDynamicData[];
    layers: NamedLayer[];
    /**
     * The identifier for the layer group so they can be programmatically turned on.
     */
    identifier?: string;
}

export interface LayerGroupings {
    groups: LayerGroup[];
    ungrouped: NamedLayer[];
}

export interface TypedDataLayerDto extends DataLayerDto {
    layers: LayerGroupings;
    sources: SourceType[];
}

export interface TypedActiveMapStyleDto extends ActiveMapStyleDto {
    dataLayers: TypedDataLayerDto[];
}

function isNamedLayers(
    layers: NamedLayer[] | LayerGroupings
): layers is NamedLayer[] {
    return Array.isArray(layers);
}

/**
 * Add missing properties to the layer group
 * @param layerGroup the original layer group
 * @returns the fixed layer group
 */
function fixLayerGroup(layerGroup: LayerGroup) {
    return Array.isArray(layerGroup.dynamicData) && layerGroup.default != null
        ? layerGroup
        : {
              ...layerGroup,
              dynamicData: layerGroup.dynamicData ?? [],
              default: layerGroup.default ?? true
          };
}

export function convertDataLayer(
    layers: NamedLayer[] | LayerGroupings
): LayerGroupings {
    if (!isNamedLayers(layers)) {
        return { ...layers, groups: layers.groups.map(fixLayerGroup) };
    }

    const groups: { [groupId: string]: LayerGroup } = layers
        .filter(l => l?.metadata?.[MetadataKeyGroupName] != null)
        .reduce((acc, l) => {
            const groupId = l.metadata?.[MetadataKeyGroupName];
            const group: LayerGroup =
                groupId in acc
                    ? acc[groupId]
                    : {
                          name: groupId,
                          default: false,
                          layers: [],
                          dynamicData: []
                      };
            return {
                ...acc,
                [groupId]: {
                    ...group,
                    layers: group.layers.concat(l),
                    default: group.default,
                    identifier: group.identifier
                }
            };
        }, {});

    return {
        groups: Object.keys(groups).map(g => groups[g]),
        ungrouped: layers.filter(
            l => l?.metadata?.[MetadataKeyGroupName] == null
        )
    };
}

function convertActiveMapStyleDto(
    style: ActiveMapStyleDto
): TypedActiveMapStyleDto {
    return {
        ...style,
        dataLayers: style.dataLayers.map(d => ({
            ...d,
            layers: convertDataLayer(d.layers)
        }))
    };
}

@Injectable({
    providedIn: 'root'
})
export class MapStyleService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findDefaultMapStyle(organisationId: number) {
        return this.http
            .get<TypedActiveMapStyleDto>(`${this.baseUrl}/webapi/maps/style`, {
                params: httpParamSerializer({ organisationId })
            })
            .pipe(map(convertActiveMapStyleDto));
    }
}
