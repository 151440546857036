import { Component, Input } from '@angular/core';
import {
    DocumentationResponses,
    FormResponseDto,
    NameValue
} from '@flyfreely-portal-ui/flyfreely';
import { DocumentationDialoguesService } from '../documentation-dialogues.service';

@Component({
    selector: 'documentation-responses',
    template: `
        <ng-container *ngFor="let step of steps">
            <div
                *ngIf="
                    responses?.formResponses[step.value] != null &&
                    responses.formResponses.length > 0
                "
            >
                <div
                    *ngFor="let response of responses.formResponses[step.value]"
                    class="wrap"
                >
                    <a (click)="showFormResponse(response)">
                        {{ response.formName }}
                    </a>
                </div>
            </div>
        </ng-container>
    `
})
export class DocumentationResponsesComponent {
    @Input() organisationId: number;
    @Input() responses: DocumentationResponses;
    @Input() title: string;
    @Input() steps: NameValue[];

    constructor(
        private documentationDialoguesService: DocumentationDialoguesService
    ) {}

    showFormResponse(response: FormResponseDto) {
        this.documentationDialoguesService.showFormResponseDialogue(
            [response],
            this.title,
            this.organisationId
        );
    }
}
