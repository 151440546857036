import { Observable } from 'rxjs';
import { NameValue } from '../model/ui';

/**
 * These are the generic interfaces and methods provided by all upload related services.
 * The upload services manage data, api calls and working state across all bulk-import dialogues.
 */
export interface UploadService {
    importFile(file: File): void;
    validateRow(
        row: any,
        index: number,
        columnOptions?: ColumnOptions[]
    ): void;
    import(
        indices: number[],
        columnOptions?: ColumnOptions[]
    ): void;
    downloadTemplate(): void;
    bulkEditOptions$: Observable<BulkEditOptions>;
    working$: Observable<boolean>;
    columnOptions$: Observable<ColumnOptions[]>;
    failedImportText$: Observable<BulkImportErrorText[]>;
}

/**
 * Interface to set the bulk edit options for columns.
 * Specify which column keys cannot be bulk-edited and which column keys should have unique values.
 */
export interface BulkEditOptions {
    cannotBulkEdit: string[];
    uniqueInSet: string[];
}

export interface ColumnOptions {
    columnName: string;
    type: ColumnOptionTypes;
    placeholder?: string;
    values?: NameValue[];
    result?: any;
    cellType?: ColumnOptionTypes;
    cellValues?: NameValue[];
}

export enum ColumnOptionTypes {
    SELECT = 'SELECT',
    TEXT = 'TEXT',
    RANGE = 'RANGE',
    NUMBER = 'NUMBER',
    NONE = 'NONE'
}

export interface BulkImportErrorText {
    rowIndex: number;
    key: string;
    text: string;
}

/**
 * This is used to return 'INVALID' for missing required fields in a bulk import document.
 * This allows the file to still be selected and these entries then simply excluded in the import dialogue
 */
export function checkRequiredValidity(field: string) {
    if (field === '' || field == null || field === undefined) {
        return 'INVALID';
    } else {
        return field;
    }
}
