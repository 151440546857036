import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import {
    CreateDefaultFlightConformancePolicyCommand,
    CreateFlightConformancePolicyCommand,
    CreateFlightConformanceRuleCommand,
    FlightConformancePolicyDto,
    FlightConformanceRuleDto,
    UpdateFlightConformancePolicyCommand,
    UpdateFlightConformanceRuleActionsCommand,
    UpdateFlightConformanceRuleCommand
} from '../model/api';
import { NameValue } from '../model/ui';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class FlightConformancePoliciesService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<FlightConformancePolicyDto[]>(
            `${this.baseUrl}/webapi/flightConformancePolicies`,
            { params: httpParamSerializer({ organisationId }) }
        );
    }

    create(command: CreateFlightConformancePolicyCommand) {
        return this.http.post<FlightConformancePolicyDto>(
            `${this.baseUrl}/webapi/flightConformancePolicies`,
            command
        );
    }

    createDefaultPolicy(command: CreateDefaultFlightConformancePolicyCommand) {
        return this.http.post<FlightConformancePolicyDto>(
            `${this.baseUrl}/webapi/flightConformancePolicies/default`,
            command
        );
    }

    update(
        conformancePolicyId: number,
        command: UpdateFlightConformancePolicyCommand
    ) {
        return this.http.put<FlightConformancePolicyDto>(
            `${this.baseUrl}/webapi/flightConformancePolicies/${conformancePolicyId}`,
            command
        );
    }

    createRule(
        conformancePolicyId: number,
        command: CreateFlightConformanceRuleCommand
    ) {
        return this.http.post<FlightConformanceRuleDto>(
            `${this.baseUrl}/webapi/flightConformancePolicies/${conformancePolicyId}/rules`,
            command
        );
    }

    updateRule(
        conformancePolicyId: number,
        conformanceRuleId: number,
        command: UpdateFlightConformanceRuleCommand
    ) {
        return this.http.put<FlightConformanceRuleDto>(
            `${this.baseUrl}/webapi/flightConformancePolicies/${conformancePolicyId}/rules/${conformanceRuleId}`,
            command
        );
    }

    updateRuleActions(
        conformancePolicyId: number,
        conformanceRuleId: number,
        command: UpdateFlightConformanceRuleActionsCommand
    ) {
        return this.http.put<FlightConformanceRuleDto>(
            `${this.baseUrl}/webapi/flightConformancePolicies/${conformancePolicyId}/rules/${conformanceRuleId}/actions`,
            command
        );
    }
}

export const CONFORMANCE_RULE_TYPE: NameValue[] = [
    {
        name: 'Maximum height (ft)',
        value: FlightConformanceRuleDto.Type.MAXIMUM_HEIGHT
    },
    {
        name: 'Maximum visual distance (m)',
        value: FlightConformanceRuleDto.Type.VISUAL_DISTANCE
    },
    {
        name: 'Flight Area',
        value: FlightConformanceRuleDto.Type.FLIGHT_AREA
    },
    {
        name: 'Landing Battery Level',
        value: FlightConformanceRuleDto.Type.LANDING_BATTERY_LEVEL
    }
];
