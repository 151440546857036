<div class="modal-header no-icon">
    <h3 class="modal-title">
        {{ response.formName }}
    </h3>
    <button
        type="button"
        (click)="modal.hide()"
        aria-label="Close"
        class="close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h3 class="content-title">{{ response.formName }}</h3>

    <form-response
        [response]="response"
        [organisationId]="organisationId"
        [buildFormAttachmentUrl]="buildFormAttachmentUrl"
    ></form-response>
</div>
<div class="modal-footer">
    <div *ngIf="response && response.submittedTime" class="pull-left">
        Submitted {{ response.submittedTime | formatDateTime }}
    </div>
    <div
        *ngIf="response && response.submittedTime"
        class="pull-right horizontal-container center-children pad-children"
    >
        <label>Revisions</label>
        <ng-select
            [items]="responseList"
            [(ngModel)]="response"
            appendTo="body"
            [clearable]="false"
        >
            <ng-template ng-option-tmp let-item="item">
                {{ item.submittedTime | formatDateTime }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                {{ item.submittedTime | formatDateTime }}
            </ng-template>
        </ng-select>
    </div>
</div>
