import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttachmentHandler, MaintenanceLogSummary, MaintenanceService } from '@flyfreely-portal-ui/flyfreely';
import { AirworthinessNoticeApplicability } from 'libs/flyfreely/src/lib/model/api/airworthnessNoticeDto';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AirworthinessNoticesDialogueService {

    organisationId: number;
    private organisationIdSubject = new BehaviorSubject<number>(0);
  
    organisationId$ = this.organisationIdSubject.asObservable();
    
    private selectedCardIdSubject = new BehaviorSubject<number | null>(null);

    private airworthinessNoticeIdSubject = new BehaviorSubject<number | null>(null);
    airworthinessNoticeId$ = this.airworthinessNoticeIdSubject.asObservable();

    // maintenance logs for selected Notice
    private maintenanceNoticeLogSubject = new BehaviorSubject<MaintenanceLogSummary[]>([]);
    maintenanceNoticeLog$ = this.maintenanceNoticeLogSubject.asObservable();

    // all maintenance logs 
    private maintenanceLogSubject = new BehaviorSubject<MaintenanceLogSummary[]>([]);
    maintenanceLog$ = this.maintenanceLogSubject.asObservable();

    private noticeCreationStatusSubject = new BehaviorSubject<boolean>(false);
    noticeCreationStatus$ = this.noticeCreationStatusSubject.asObservable();

    private expandedGroupSubject = new BehaviorSubject<AirworthinessNoticeApplicability | null>(null);
    expandedGroup$: Observable<AirworthinessNoticeApplicability | null> = this.expandedGroupSubject.asObservable();
  
    constructor(
      private maintenanceService: MaintenanceService,
      private http: HttpClient,
      
    ) {
      this.organisationId$.subscribe(id => {this.organisationId = id})
    }
  
    setOrganisationId(organisationId: number): void {
      this.organisationIdSubject.next(organisationId);
    }
  
    setAirworthinessNoticeId(airworthinessNoticeId: number): void {
      this.airworthinessNoticeIdSubject.next(airworthinessNoticeId);
    }

    setNoticeCreationStatus(status: boolean): void {
      this.noticeCreationStatusSubject.next(status);
    }

    setExpandedGroup(key: AirworthinessNoticeApplicability | null): void {
      this.expandedGroupSubject.next(key);
      // console.log('this is applicability:', key);
      
    }
  
    updateLinkedMaintenanceLogs(): void {
      const organisationId = this.organisationIdSubject.value;
      const airworthinessNoticeId = this.airworthinessNoticeIdSubject.value;
      this.maintenanceService.findMaintenanceLogs(organisationId, null, null, null, airworthinessNoticeId)
        .subscribe(logs => {
          this.maintenanceNoticeLogSubject.next(logs);
        });
    }




getSelectedCardId(): Observable<number | null> {
  return this.selectedCardIdSubject.asObservable();
}


setSelectedCardId(cardId: number | null): void {
  this.selectedCardIdSubject.next(cardId);
  if (cardId === null) {
    this.clearMaintenanceNoticeLogs();
  }
  // console.log('this is new cardId:', cardId);
  
}

get selectedCardIdValue(): number | null {
  return this.selectedCardIdSubject.value;
}


updateMaintenanceLogs(organisationId: number): void {
  this.maintenanceService
    .findMaintenanceLogs(organisationId)
    .subscribe((data) => {
      this.maintenanceLogSubject.next(data);
    });
}

attachmentHandler(noticeId: number) {
  return new AttachmentHandler(
      this.http,
      `/webapi/airworthinessNoticeAttachments/${noticeId}`,
      false,
      this.organisationId
  );
}

clearMaintenanceNoticeLogs() {
  this.maintenanceNoticeLogSubject.next([]);
}
}