import { Component, OnInit } from '@angular/core';
import {
    AuthorityTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LoggedInUser,
    OnboardingAuthorityGroup,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    OrganisationService,
    PersonDto,
    SimpleAirspaceJurisdictionDto,
    UserService,
    UserStatus,
    WorkTracker,
    OnboardingPhases,
    OnboardingService
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'personal-certification',
    templateUrl: './personal-certification.component.html',
    styleUrls: ['./personal-certification.component.scss']
})
export class PersonalCertificationComponent implements OnInit {
    currentUser: PersonDto;
    availableJurisdictions: SimpleAirspaceJurisdictionDto[];
    authorityGroups: OnboardingAuthorityGroup[];
    organisationId: number;
    hasRequestedSales: number[];

    hasAddedAllRequired = true;

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private obsds: OnboardingData,
        private userService: UserService,
        private organisationService: OrganisationService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private onboardingService: OnboardingService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.userService.userChange$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(c => c.type === UserStatus.LOGGED_IN)
            )
            .subscribe((resp: LoggedInUser) => {
                this.currentUser = resp.currentUser;
                this.organisationId = resp.currentUser.personalOrganisationId;
            });

        this.organisationAuthorityService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(resp => {
                this.refreshAuthorities();
            });

        if (this.currentUser) {
            this.findJurisdictions();
            this.refreshAuthorities();
            this.hasRequestedSales = this.obsds.requestedPersonalSales;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findJurisdictions() {
        this.organisationService
            .findOrganisationsForPerson(this.currentUser.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(orgs => {
                const personalOrg = orgs.find(
                    org => org.id === this.currentUser.personalOrganisationId
                );
                this.availableJurisdictions = personalOrg.activeJurisdictions;
            })
            .add(this.workTracker.createTracker());
    }

    refreshAuthorities() {
        const doneWorking = this.workTracker.createTracker();

        this.authorityGroups = [];
        this.onboardingService
            .findAuthoritiesForOnboarding(
                this.organisationId,
                this.organisationId,
                OnboardingPhases.INDIVIDUAL_LICENCES,
                this.obsds.personalJurisdictionId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: authorities => {
                    this.authorityGroups = authorities.sort((a, b) =>
                        this.requiredByAuthority(a) &&
                        this.requiredByAuthority(a).authorities.filter(
                            auth => auth.id === b.id
                        ).length === 0
                            ? 0
                            : this.requiredByAuthority(a) &&
                              this.requiredByAuthority(a).authorities.filter(
                                  auth => auth.id === b.id
                              ).length > 0
                            ? 1
                            : -1
                    );
                    this.hasAddedAllRequired = this.authorityGroups.reduce(
                        (acc, a) =>
                            acc &&
                            this.requiredByAuthority(a) != null &&
                            this.requiredByAuthority(a).authorities?.length > 0
                                ? this.hasAuthority(a)
                                : true,
                        true
                    );
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while fetching authorities: ${error.message}`
                    );
                    doneWorking();
                }
            })
            .add(this.workTracker.createTracker());
    }

    hasAuthority(authorityGroup: OrganisationAuthorityGroup) {
        if (authorityGroup.authorities.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    requiredAuthorityAdded(
        authorityGroup: OrganisationAuthorityGroup | AuthorityTypeDto
    ) {
        if (authorityGroup.dependentOnAuthorityType == null) {
            return true;
        }
        const requiredAuthorityGroup = this.authorityGroups.find(
            a => a.id === authorityGroup.dependentOnAuthorityType.id
        );
        return (
            requiredAuthorityGroup != null &&
            requiredAuthorityGroup.authorities.length > 0
        );
    }

    requiredByAuthority(authorityGroup: OrganisationAuthorityGroup) {
        return this.authorityGroups.find(
            a =>
                a.dependentOnAuthorityType != null &&
                a.dependentOnAuthorityType.id === authorityGroup.id
        );
    }

    contactSales(authorityGroup: OnboardingAuthorityGroup) {
        const doneWorking = this.workTracker.createTracker();

        this.userService
            .contactSales({
                message: `I would like more information on the following authority: ${authorityGroup.name}`
            })
            .subscribe(
                () => {
                    this.hasRequestedSales.push(authorityGroup.id);
                    this.obsds.requestedPersonalSales = this.hasRequestedSales;
                    this.logging.success(
                        `A message has been sent to our sales team. They will be in contact soon.`
                    );
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while contacting sales: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    hasContactedSales(authorityGroup: OnboardingAuthorityGroup) {
        return this.hasRequestedSales.find(id =>
            id === authorityGroup.id ? true : false
        );
    }

    previousScreen() {
        this.obsds.backToPersonalFlyingPreference();
    }

    nextScreen() {
        this.obsds.personalNonPrimaryCompleted();
    }

    addAuthority(authorityGroup: AuthorityTypeDto) {
        if (
            authorityGroup.issuingMechanism !== 'SELF_REPORTED' ||
            !this.requiredAuthorityAdded(authorityGroup)
        ) {
            return;
        }
        const dependentOnAuthorityGroup =
            authorityGroup.dependentOnAuthorityType != null
                ? this.authorityGroups.find(
                      a => a.id === authorityGroup.dependentOnAuthorityType.id
                  )
                : null;

        const activeDependentAuthority =
            dependentOnAuthorityGroup == null
                ? null
                : dependentOnAuthorityGroup.authorities.length === 1
                ? dependentOnAuthorityGroup.authorities[0]
                : dependentOnAuthorityGroup.authorities.find(
                      a =>
                          (moment(a.startDate).isBefore(moment()) &&
                              a.expiryDate == null) ||
                          moment(a.expiryDate).isAfter(moment())
                  );

        this.authorityDialoguesService.showAuthorityEdit(
            this.organisationId,
            authorityGroup,
            null,
            this.organisationId,
            this.organisationAuthorityService,
            activeDependentAuthority
        );
    }

    editAuthority(
        authorityGroup: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        if (authorityGroup.issuingMechanism !== 'SELF_REPORTED') {
            return;
        }
        this.authorityDialoguesService.showAuthorityEdit(
            this.organisationId,
            authorityGroup,
            authority,
            this.organisationId,
            this.organisationAuthorityService
        );
    }

    remove(authority: OrganisationAuthorityDto) {
        this.organisationAuthorityService.deleteAuthority(
            authority.id,
            this.organisationId
        );
    }

    // authoritySelected(existing: boolean, authorityGroup: AuthorityTypeDto) {
    //     if (existing) {
    //         this.editAuthority(authorityGroup, authorityGroup.authorities[0])
    //     }

    // }
}
