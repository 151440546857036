import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    AttachmentHandler,
    MaintenanceScheduleDto,
    MaintenanceScheduleService,
    PersonDto,
    PersonsOrganisationDto,
    SimpleMaintenanceScheduleDto,
    WorkTracker,
    personSearch
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    MaintenanceActivityGroupModel,
    MaintenanceControllerGroupModel,
    MaintenanceDetailsGroupModel,
    MaintenanceFormModel,
    MaintenanceRequestGroupModel,
    ScheduledMaintenanceActivity
} from '../maintenance-form.service';
import {
    DisplayableMaintenanceActivity,
    MaintenanceRequestService
} from '../maintenance-request.service';
import { MaintenanceScheduleDialogueService } from '../maintenance-schedule/maintenance-schedule-dialogue.service';

@Component({
    selector: 'maintenance-activity-edit',
    templateUrl: './maintenance-activity-edit.component.html',
    providers: [MaintenanceScheduleDialogueService]
})
export class MaintenanceActivityEdit {
    @Input() form: FormGroup<MaintenanceFormModel>;
    @Input() organisation: PersonsOrganisationDto;
    @Input() overallStatus: string;
    @Input() activity: DisplayableMaintenanceActivity;
    @Input() allActivities: DisplayableMaintenanceActivity[];
    @Input() isMaintenancePerson: boolean;
    @Input() isMaintenanceController: boolean;
    @Input() activityNumber: number;
    @Input() canUseMaintenanceTasks: boolean;
    @Input() isExternalMaintenance: boolean;

    personnel$: Observable<PersonDto[]>;

    maintenanceSchedule: MaintenanceScheduleDto;

    personSearch = personSearch;

    canAddMaintenanceTask: boolean = false;
    canAddScheduledMaintenance: boolean = false;
    canEditActivity = false;

    working: boolean = false;
    workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    attachmentsHandler: AttachmentHandler;

    constructor(
        private maintenanceRequestService: MaintenanceRequestService,
        private maintenanceScheduleService: MaintenanceScheduleService,
        private maintenanceScheduleDialogueService: MaintenanceScheduleDialogueService
    ) {
        this.personnel$ =
            this.maintenanceRequestService.maintenancePersonCandidates$;
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        if (this.activity.id != null) {
            this.attachmentsHandler =
                this.maintenanceRequestService.attachmentHandler(
                    this.activity.id
                );
            this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);
            this.canEditActivity =
                this.activity?.availableActions?.canPerformActivity;
        }

        this.maintenanceRequestService.maintenanceSchedule$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(schedule => {
                if (schedule != null) {
                    this.refreshMaintenanceSchedule(schedule);
                    this.maintenanceScheduleDialogueService.setup(
                        this.organisation.id,
                        schedule.resourceCategory
                    );
                }
            });

        combineLatest([
            this.maintenanceRequestService.taskTypes$,
            this.maintenanceScheduleDialogueService.componentGroup$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([taskTypes, componentGroups]) => {
                if (
                    taskTypes != null && taskTypes.length > 0
                        ? true
                        : false &&
                          componentGroups != null &&
                          componentGroups.length > 0
                ) {
                    this.canAddScheduledMaintenance = true;
                }
            });

        this.setupDateTime();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupDateTime() {
        this.maintenanceDetailsGroup.controls.endTime.patchValue(
            this.activity?.endTime != null
                ? moment(this.activity.endTime).toDate()
                : undefined
        );
    }

    refreshMaintenanceSchedule(schedule: SimpleMaintenanceScheduleDto) {
        this.maintenanceScheduleService
            .findById(schedule.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(maintenanceSchedule => {
                this.maintenanceSchedule = maintenanceSchedule;
            });
    }

    showAddComponentForm() {
        this.canAddMaintenanceTask = false;
        this.maintenanceRequestService.addMaintenanceTask();
    }

    showAddScheduledMaintenance() {
        this.canAddScheduledMaintenance = false;
        this.maintenanceRequestService.addScheduledMaintenanceTask();
    }

    completedNow() {
        const now = new Date();
        // Set twice because the way we default to noon checks the previous value
        this.maintenanceDetailsGroup.controls.endTime.setValue(now);
        this.maintenanceDetailsGroup.controls.endTime.setValue(now);
    }

    get maintenanceActivities() {
        return this.form.controls
            .maintenanceActivities as FormGroup<MaintenanceActivityGroupModel>;
    }

    get maintenanceDetailsGroup() {
        return this.form.controls
            .maintenanceDetailsGroup as FormGroup<MaintenanceDetailsGroupModel>;
    }

    get maintenanceControllerGroup() {
        return this.form.controls
            .maintenanceControllerGroup as FormGroup<MaintenanceControllerGroupModel>;
    }

    get maintenanceRequestGroup() {
        return this.form.controls
            .maintenanceRequestGroup as FormGroup<MaintenanceRequestGroupModel>;
    }

    get scheduledTasks() {
        return <FormArray<FormControl<ScheduledMaintenanceActivity>>>(
            this.maintenanceActivities.controls.scheduledTasks
        );
    }
}
