import { Pipe, PipeTransform } from '@angular/core';
import { CollectNowQueue } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatFlightLogQueuePosition' })
export class FormatFlightLogQueuePositionPipe implements PipeTransform {
    transform(collectNowQueue: CollectNowQueue) {
        if (
            collectNowQueue === null ||
            collectNowQueue.queuePosition === null
        ) {
            return 'Scheduled for collection';
        }
        return `Scheduled for collection - no. ${collectNowQueue.queuePosition} in queue.`;
    }
}
