import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    CraftService,
    DownloadService,
    NameId,
    PrintOption,
    RpaAuthorityService,
    RpaReportService,
    SimpleOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import FileSaver from 'file-saver';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface SimpleAuthority {
    id: number;
    name: string;
    identifier: string;
    identifierLabel: string;
}

@Component({
    selector: 'rpa-reports-dialogue',
    templateUrl: './rpa-reports-dialogue.component.html'
})
export class RpaReportsDialogue implements OnInit, OnDestroy {
    @Input() organisation: SimpleOrganisationDto;

    authorities: SimpleAuthority[];
    authority: SimpleAuthority;
    rpaLogAuthorities: SimpleAuthority[] = [
        {
            id: 1,
            identifier: 'Test',
            identifierLabel: 'Test',
            name: 'Test Auth'
        }
    ];
    rpaLogAuthority: SimpleAuthority;

    authorityTypes: NameId[];
    selectedAuthorityTypeIds: number[];

    printOptions: PrintOption[] = [];

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<RpaReportsDialogue>,
        private logging: FlyFreelyLoggingService,
        private rpaAuthorityService: RpaAuthorityService,
        private rpaReportService: RpaReportService,
        private rpaService: CraftService,
        private downloadService: DownloadService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshAuthorityTypes();

        // TODO: uncomment this to enable RPA reports
        // this.refreshAvailableReports();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAuthorityTypes() {
        if (this.organisation == null) {
            return;
        }
        this.rpaAuthorityService
            .findAvailableAuthorityTypes(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                authorityTypes =>
                    (this.authorityTypes = authorityTypes.map(a => ({
                        name: a.name,
                        id: a.id
                    })))
            )
            .add(this.workTracker.createTracker());
    }

    private refreshAvailableReports() {
        if (this.organisation.id == null) {
            this.printOptions = [];
            return;
        }

        this.rpaReportService
            .findAvailableListTemplates(
                'RpaListReport',
                this.organisation.id,
                this.organisation.id
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(reports => {
                this.printOptions = reports.map(r => ({
                    name: r.name,
                    description: r.description,
                    print: () => {
                        const doneWorking = this.workTracker.createTracker();
                        this.downloadService
                            .downloadFromUrl(
                                this.rpaReportService.getPdfListUrl(
                                    r.reportTemplateId,
                                    this.organisation.id,
                                    this.organisation.id
                                )
                            )
                            .catch(() => doneWorking())
                            .then(() => doneWorking());
                    }
                }));
            });
    }

    exportRpa() {
        this.rpaService
            .exportOrganisationRpa(
                this.organisation.id,
                this.selectedAuthorityTypeIds
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                FileSaver.saveAs(result, `Organisation_RPA.csv`);
            })
            .add(this.workTracker.createTracker());
    }

    printReport(printOption: PrintOption) {
        printOption.print();
    }

    cancel() {
        this.modal.hide();
    }
}
