import {
    ConcreteRpaTypeWorkflowDto,
    CraftDto,
    RpaTypeConfigChangeSummary,
    RpaTypeConfigDto,
    UpdateRpaTypeConfigCommand
} from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class RpaTypeConfigService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<RpaTypeConfigDto[]>(
            `${this.baseUrl}/webapi/rpaTypeConfig`,
            {
                params: httpParamSerializer({ organisationId })
            }
        );
    }

    update(command: UpdateRpaTypeConfigCommand) {
        return this.http
            .post<RpaTypeConfigDto>(
                `${this.baseUrl}/webapi/rpaTypeConfig`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    preview(command: UpdateRpaTypeConfigCommand) {
        return this.http
            .post<RpaTypeConfigChangeSummary>(
                `${this.baseUrl}/webapi/rpaTypeConfig/preview`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findActiveWorkflow(organisationId: number, RpaTypeId: number) {
        return this.http.get<ConcreteRpaTypeWorkflowDto>(
            `${this.baseUrl}/webapi/rpaTypeConfig/active`,
            {
                params: httpParamSerializer({ organisationId, RpaTypeId })
            }
        );
    }
}
