<div class="horizontal-container center-children" [title]="listedBatteries">
    <div class="fill right-buffer">
        <status-icon [batterySet]="item" [clickable]="false"></status-icon>
    </div>
    <div class="fill vertical-container">
        <div class="wrap-overflow">
            {{ item.batteries[0]?.batteryTypeMake }}
            {{ item.batteries[0]?.batteryTypeModel }}
        </div>
    </div>
</div>
