import {
    DisplayCondition,
    FormControlDto,
    FormSectionDto,
    ConcreteFormDto,
    FormResponseCommand,
    FormResponseDto,
    FormDto,
    FormVersionDto,
    ConcreteMappedEntityConcreteFormDto
} from '../api';
import { Progress, dateOrderBySubmittedTime } from './documentation';
import { range } from '../../utils';

/**
 * Represents responses for controls in a form.
 */
export interface ControlValues {
    [controlId: string]: string[];
}

function buildSectionResponse(section: FormSectionDto): ControlValues {
    return section.controls.reduce(
        (acc, c) => ({ ...acc, [c.id]: c.prefill || [] }),
        {}
    );
}

/**
 * Setup a FormResponseCommand object prepopulated with the prefill data for repeating
 * element controls.
 *
 * @param form the form the responses are for
 */
export function initialiseResponse(
    form: ConcreteMappedEntityConcreteFormDto,
    relatedEntityType?: string,
    relatedEntityId?: number
): FormResponseDto {
    const values = form.entity.sections.reduce(
        (acc: ControlValues, s) => ({
            ...acc,
            ...(s.repeatingGroup ? buildSectionResponse(s) : {})
        }),
        {}
    );
    return {
        formVersionId: form.entity.formVersionId,
        values: values,
        requiringEntityId: form.requiringEntityId,
        requiringEntityType: form.requiringEntityType,
        relatedEntityType: relatedEntityType,
        relatedEntityId: relatedEntityId,
        completed: false,
        sections: form.entity.sections,
        formId: form.entity.formId,
        formName: form.entity.formName
    };
}

/**
 * Produce a FormResponseDto from a form response command and the form definition.
 * @param response form response command
 * @param form form definition
 */
export function toFormResponseDto(
    response: FormResponseCommand,
    form: ConcreteFormDto
): FormResponseDto {
    return {
        ...response,
        formId: form.formId,
        formName: form.formName,
        sections: form.sections
    };
}

/**
 * Form responses with the step property.
 */
export interface FormResponsesWithStep extends FormResponseDto {
    // The step that this response was stored in
    step: string;
}

/**
 * Form responses grouped by step.
 */
interface FormResponseStepGroup {
    [step: string]: FormResponseDto[];
}

/**
 * Form responses grouped by formId.
 */
export interface FormResponseFormGroup {
    [formId: number]: FormResponsesWithStep[];
}

/**
 * Combines all of the responses from all of the provided groups into a single structure grouped
 * by form id.
 *
 * @param responseGroups an array of form response groups from a documentation DTO
 */
export function combineFormResponses(
    responseGroups: FormResponseStepGroup[]
): FormResponseFormGroup {
    const collapseGroup = (
        responses: FormResponseStepGroup,
        groupings: FormResponseFormGroup
    ) =>
        Object.keys(responses).reduce((acc: FormResponseFormGroup, step) => {
            responses[step].forEach(r => {
                if (r.formId in acc) {
                    acc[r.formId].push({ ...r, step });
                } else {
                    acc[r.formId] = [{ ...r, step }];
                }
                return acc;
            });
            return acc;
        }, groupings);

    const combinedResponses = responseGroups.reduce(
        (acc: FormResponseFormGroup, v: FormResponseStepGroup) =>
            collapseGroup(v, acc),
        {}
    );
    Object.keys(combinedResponses).forEach(fId =>
        combinedResponses[fId].sort(dateOrderBySubmittedTime)
    );
    return combinedResponses;
}

/**
 * Clones the display condition object updating the related IDs.
 * @param displayCondition the original display condition
 * @param idLookup a lookup for finding updated control IDs
 */
export function cloneDisplayCondition(
    displayCondition: DisplayCondition,
    idLookup: { [originalId: number]: number }
) {
    if (displayCondition == null || displayCondition.subjectControlId == null) {
        return null;
    }
    return {
        ...displayCondition,
        subjectControlId: idLookup[displayCondition.subjectControlId]
    };
}

export interface IdLookup {
    nextId: number;
    idLookup: { [oldId: number]: number };
}

/**
 * Clone the section, updating all related IDs
 * @param section the original section
 */
export function cloneFormSection(
    section: FormSectionDto,
    newSectionId: number,
    idLookup: IdLookup,
    nameSuffix = ''
): [FormSectionDto, IdLookup] {
    const newControlIds = section.controls.reduce(
        (acc, c) => {
            acc.idLookup[c.id] = acc.nextId;
            acc.nextId = acc.nextId - 1;
            return acc;
        },
        { nextId: idLookup.nextId, idLookup: { ...idLookup.idLookup } }
    );

    const clonedSection: FormSectionDto = {
        id: newSectionId,
        name: `${section.name}${nameSuffix}`,
        order: null,
        description: section.description,
        layout: section.layout,
        repeatingGroup: section.repeatingGroup,
        controls: section.controls.map(c => ({
            ...c,
            id: newControlIds.idLookup[c.id],
            inputControlIds: c.inputControlIds.map(
                id => newControlIds.idLookup[id]
            ),
            displayCondition: cloneDisplayCondition(
                c.displayCondition,
                newControlIds.idLookup
            ),
            groupWithPreviousControl: c.groupWithPreviousControl ?? false
        }))
    };
    return [clonedSection, newControlIds];
}

/**
 * Clones the version, by rewriting the IDs. Also fixes any known data defects.
 * @param version the version to be cloned
 * @param nextVersionNumber the new version number
 * @returns an unsaved version
 */
export function cloneFormVersion(
    version: FormVersionDto,
    nextVersionNumber: number
): FormVersionDto {
    if (version == null) {
        return {
            id: null,
            availableActions: { canEdit: true, canDelete: false },
            versionNumber: nextVersionNumber,
            sections: []
        };
    }
    const newVersion: FormVersionDto = {
        id: null,
        availableActions: { canEdit: true, canDelete: false },
        versionNumber: nextVersionNumber,
        sections: version.sections.reduce(
            (acc, s, sIx) => {
                const [section, idLookup] = cloneFormSection(
                    s,
                    -sIx,
                    acc.idLookup
                );
                return { idLookup, sections: acc.sections.concat(section) };
            },
            { idLookup: { nextId: -1, idLookup: {} }, sections: [] }
        ).sections
    };

    return newVersion;
}

/**
 * Clone a form and its current version.
 * @param form the current form
 * @param organisationId the owner of the new form
 */
export function cloneForm(form: FormDto, organisationId: number): FormDto {
    const clonedVersion = cloneFormVersion(
        form.versions.find(v => v.id === form.activeVersionId),
        1
    );

    return {
        id: null,
        organisationId,
        name: form.name,
        availableActions: { canEdit: true, canDelete: false },
        activeVersionId: null,
        versions: [clonedVersion],
        archived: false
    };
}

/**
 * Determines whether there is a valid response for this form, and returns the values associated
 * with that form response.
 *
 * @param form the form structure that is being checked against
 * @param responseList the responses that could contain the response for this form
 * @param step the step to use to tell if a response should count as progress
 */
export function checkFormProgress(
    form: ConcreteFormDto,
    responseList: FormResponsesWithStep[],
    step: string
): { progress: Progress; completed: boolean } {
    if (!responseList) {
        return { progress: { total: 1, progress: 0 }, completed: false };
    }

    const responseObject = responseList.find(
        r => r.formId === form.formId && r.step === step // && r.submittedTime != null
    );

    if (!responseObject) {
        return { progress: { total: 1, progress: 0 }, completed: false };
    }

    const emptyProgress = { total: 0, progress: 0 };

    const progress = form.sections
        .map(s => {
            const max = s.repeatingGroup
                ? findMaxLength(s.controls, responseObject.values)
                : 1;
            return s.controls
                .filter(c => c.required)
                .map(c => {
                    return range(0, max - 1)
                        .filter(ix =>
                            isControlVisible(c, ix, responseObject.values)
                        )
                        .map(ix => ({
                            total: 1,
                            progress: existsAndHasValue(
                                c.id,
                                ix,
                                responseObject.values
                            )
                                ? 1
                                : 0
                        }))
                        .reduce(addProgress, emptyProgress);
                })
                .reduce(addProgress, emptyProgress);
        })
        .reduce(addProgress, emptyProgress);

    return { progress, completed: responseObject.completed };
}

function existsAndHasValue(
    controlId: number,
    sectionIx: number,
    values: ControlValues
) {
    if (!(controlId in values)) {
        return false;
    }
    if (!(sectionIx in values[controlId])) {
        return false;
    }
    return values[controlId][sectionIx] !== '';
}

function addProgress(a: Progress, b: Progress): Progress {
    return { total: a.total + b.total, progress: a.progress + b.progress };
}

/**
 * Calculate if the control is visible.
 * @param item
 * @param sectionIx the index of the row within a repeating group
 * @param formData
 */
export function isControlVisible(
    control: FormControlDto,
    sectionIx: number,
    formData: ControlValues
): boolean {
    if (!control.displayCondition) {
        return true;
    }

    const { subjectControlId, comparator, values } = control.displayCondition;

    if (!existsAndHasValue(subjectControlId, sectionIx, formData)) {
        return false;
    }

    const valueInt = parseInt(formData[subjectControlId][sectionIx], 10);
    const valueStr = formData[subjectControlId][sectionIx];
    const temp = valueInt ? valueInt : 0;

    const val1 = parseInt(values[0], 10);
    const val2 = parseInt(values[1], 10);

    switch (comparator) {
        case 'EQUAL':
            return valueStr ? values[0] === valueStr : false;
        case 'NOT_EQUAL':
            return valueStr ? values[0] !== valueStr : false;
        case 'GT':
            return val1 < temp;
        case 'GTE':
            return val1 <= temp;
        case 'LT':
            return val1 > temp;
        case 'LTE':
            return val1 >= temp;
        case 'BETWEEN':
            return val1 <= temp && val2 <= temp;
        case 'NOT_BETWEEN':
            return !(val1 <= temp && val2 >= temp);
    }
}

/**
 * Finds the maximum length of the data for any control. In reality these should all be the same
 * but just in case we do this test.
 * @param controls array of controls in the section
 * @param formData the form data
 */
export function findMaxLength(
    controls: FormControlDto[],
    formData: ControlValues
) {
    return controls.reduce(
        (acc, c) =>
            c.id in formData ? Math.max(acc, formData[c.id].length) : acc,
        0
    );
}

/**
 * This calculates a placeholder control ID that is negative, as we need to be able
 * to create references between controls before the form is saved and we have real
 * IDs
 */
export function nextControlId(version: FormVersionDto) {
    const minControlId = (accum: number, control: FormControlDto) =>
        Math.min(accum, control.id);
    const minControlIdForSections = (accum: number, section: FormSectionDto) =>
        section.controls.reduce(minControlId, accum);
    return version.sections.reduce(minControlIdForSections, 0) - 1;
}

export function nextSectionId(version: FormVersionDto) {
    const minSectionId = version.sections.reduce(
        (acc, section) => Math.min(acc, section.id),
        0
    );
    return minSectionId - 1;
}
