import { Component, Input } from '@angular/core';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
    PlanHighlightService,
    SubscriptionFeatureSetSummary
} from './plan-highlight.service';

@Component({
    selector: 'plan-highlight',
    template: `
        <ng-container *ngIf="isTrialling">
            <div class="feature" *ngIf="!working && featureHighlightText">
                {{ featureHighlightText }}
            </div>
            <div class="feature" *ngIf="working">Loading...</div>
        </ng-container>
    `,
    // $color-brand-golden
    styles: [
        `
            .feature {
                border-radius: 10px;
                padding: 5px 10px;
                margin-top: 5px;
                margin-bottom: 5px;
                border: 1px solid #ffd366;
                background-color: #ffb500;
                color: #363839 !important;
                width: 100%;
            }
        `
    ]
})
export class PlanHighlightComponent {
    @Input() featureFlags: string[];

    plansWithFeatures: SubscriptionFeatureSetSummary[];
    featureHighlightText: string;
    isTrialling = false;

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private planHighlightService: PlanHighlightService) {}

    ngOnInit() {
        combineLatest([
            this.workTracker.observable,
            this.planHighlightService.working$
        ])
            .pipe(
                map(([working, serviceWorking]) => working || serviceWorking),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(working => (this.working = working));

        this.planHighlightService.isTrialling$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(isTrialling => (this.isTrialling = isTrialling));
        this.planHighlightService.subscriptionPlansWithFeatures$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(plans => {
                this.plansWithFeatures = plans;
                this.getRequiredPlans();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    getRequiredPlans() {
        if (
            this.plansWithFeatures == null ||
            this.featureFlags == null ||
            this.plansWithFeatures.length === 0 ||
            this.featureFlags.length === 0
        ) {
            return (this.featureHighlightText = null);
        }
        const names = this.plansWithFeatures
            .filter(p =>
                this.featureFlags.reduce(
                    (acc, f) => acc && p.features.includes(f),
                    true
                )
            )
            .map(p => p.name);
        const lastName = names.length > 1 ? names.pop() : null;
        if (names.length > 0) {
            this.featureHighlightText = `${
                this.featureFlags.length > 1
                    ? 'These trial features are'
                    : 'This trial feature is'
            } available as part of the ${names.join(', ')}${
                lastName != null ? ` & ${lastName}` : ''
            } subscription${lastName != null ? 's' : ''}`;
        } else {
            this.featureHighlightText = null;
        }
    }
}
