import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    MaintenanceService,
    PrintOption,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import FileSaver from 'file-saver';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'maintenance-reports-dialogue',
    templateUrl: './maintenance-reports-dialogue.component.html'
})
export class MaintenanceReportsDialogue implements OnInit, OnDestroy {
    @Input() organisationId: number;

    printOptions: PrintOption[] = [];

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef,
        private maintenanceService: MaintenanceService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    printReport(printOption: PrintOption) {
        printOption.print();
    }

    downloadMaintenanceList() {
        this.maintenanceService
            .exportOrganisationMaintenance(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                FileSaver.saveAs(result, 'Organisation_Maintenance.csv');
            })
            .add(this.workTracker.createTracker());
    }

    cancel() {
        this.modal.hide();
    }
}
