import { FormGroup } from '@angular/forms';
import {
    CraftDto,
    DisplayableMissionDto,
    InUseMissionWorkflowVersionDto,
    LocationDetailsDto
} from '@flyfreely-portal-ui/flyfreely';
import { MissionFormModel } from 'libs/missions/src/lib/interfaces';
import { Observable } from 'rxjs';

/**
 * An interface describing the mission edit service to allow injection of the service without needing to import it in this module.
 */
export class AirspaceMissionEditService {
    missionDate$: Observable<Date>;
    missionLocation$: Observable<LocationDetailsDto>;
    rpas$: Observable<CraftDto[]>;
    missionWorkflows$: Observable<InUseMissionWorkflowVersionDto[]>;
    loadingWorkflow$: Observable<boolean>;
    savedMission$: Observable<DisplayableMissionDto>;

    missionForm: FormGroup<MissionFormModel>;
    organisationId: number;

    missionId: number | undefined;
}
