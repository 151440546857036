<div class="vertical-container">
    <div class="horizontal-container">
        <div class="vertical-container fill">
            <div class="data" *ngIf="!selectSubscription">
                <span class="display-label">Subscription</span>
                <div class="vertical-container">
                    <editable
                        [editOnly]="false"
                        [confirmationButtons]="true"
                        [cancelOnClickOutside]="true"
                        [submitButton]="false"
                    >
                        <ng-template viewMode>
                            {{
                                subscription?.name ||
                                    subscription?.featureSet?.name
                            }}
                        </ng-template>
                        <ng-template editMode>
                            <ng-select
                                [(ngModel)]="subscription"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="working"
                                [clearable]="false"
                                (change)="updateSelectedSubscription($event)"
                                [items]="availableSubscriptions"
                                class="wide-panel"
                                placeholder="Select a Subscription"
                                appendTo="body"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <span
                                        [tooltip]="
                                            item.externalIdentifier
                                                ? null
                                                : 'This is a custom subscription'
                                        "
                                        placement="top"
                                    >
                                        {{
                                            item.featureSet.name
                                        }}&nbsp;&lbrack;{{
                                            item.startTime | formatDate
                                        }}&rbrack;
                                    </span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    {{ item.featureSet.name }}&nbsp;&lbrack;{{
                                        item.startTime | formatDate
                                    }}&rbrack;{{
                                        item.externalIdentifier
                                            ? ''
                                            : ' - Custom'
                                    }}
                                </ng-template>
                            </ng-select>
                        </ng-template>
                    </editable>
                    <button
                        *ngIf="
                            subscription?.externalIdentifier != null &&
                            subscription.subscriptionType != 'SHARED'
                        "
                        (click)="upgradeSubscription()"
                        type="button"
                        class="left-buffer btn btn-inline btn-link btn-sm"
                        [disabled]="!canUpdateSubscription"
                        [tooltip]="canUpdateSubscriptionMessage"
                        placement="right"
                    >
                        Upgrade
                    </button>
                </div>
            </div>
            <div class="data">
                <span class="display-label">Subscription Start</span
                >{{ subscription?.startTime | formatDate }}
            </div>
            <div class="data">
                <span class="display-label">Subscription End</span>
                <span
                    *ngIf="subscription != null && subscription.endTime == null"
                    >Ongoing</span
                >
                <span
                    *ngIf="subscription != null && subscription.endTime != null"
                    >{{ subscription?.endTime | formatDate }} (Cancelled)</span
                >
            </div>
            <div class="data">
                <span class="display-label">Current Period Start</span
                >{{ subscriptionDetails?.periodStart | formatDate }}
            </div>
            <div class="data">
                <span class="display-label">Current Period End</span
                >{{ subscriptionDetails?.periodEnd | formatDate }}
            </div>
            <div class="data">
                <ngx-loading [show]="updatePending"></ngx-loading>
                <span class="display-label">Licences</span
                >{{ subscription?.limits | formatLicenceLimits }}
                <button
                    *ngIf="
                        subscription?.externalIdentifier != null &&
                        subscription.subscriptionType != 'SHARED'
                    "
                    (click)="updateSubscription()"
                    type="button"
                    class="left-buffer btn btn-inline btn-link btn-sm"
                    [disabled]="!canUpdateSubscription"
                    [tooltip]="canUpdateSubscriptionMessage"
                    placement="right"
                >
                    Update Licence Count
                </button>
            </div>
        </div>
        <div
            *ngIf="
                subscription != null &&
                subscription.subscriptionType != 'SHARED'
            "
            class="right-buffer left-buffer fill"
        >
            <billing-address-tax-details
                [organisationId]="subscription.organisationId"
            ></billing-address-tax-details>
        </div>
        <div class="vertical-container fill">
            <div class="vertical-container fill">
                <ng-container
                    *ngIf="
                        subscription?.externalIdentifier != null &&
                        subscription.subscriptionType !== 'SHARED'
                    "
                >
                    <div class="horizontal-container vertical-align">
                        <button
                            type="button"
                            class="btn btn-sm btn-link"
                            (click)="showPaymentDetails()"
                            [disabled]="!canUpdateSubscription"
                        >
                            Payments & Update Payment Details
                        </button>
                        <action-required
                            *ngIf="
                                !isCancelled &&
                                subscription?.externalIdentifier != null &&
                                !subscriptionDetails?.hasDefaultPaymentMethod
                            "
                            [nonCritical]="true"
                        >
                            You currently do not have an automatic payment
                            method setup. Please set one up to ensure your
                            subscription doesn't get cancelled.
                        </action-required>
                        <action-required
                            *ngIf="
                                !isCancelled &&
                                subscription?.externalStatus == 'incomplete'
                            "
                            [nonCritical]="true"
                        >
                            The last attempt to charge your currently selected
                            payment method has failed. Please revise your
                            payment method to ensure your subscription doesn't
                            get cancelled.
                        </action-required>
                    </div>
                    <div class="horizontal-container vertical-align">
                        <button
                            type="button"
                            class="btn btn-sm btn-link"
                            (click)="selfServe()"
                        >
                            Invoices
                        </button>
                        <button
                            *ngIf="!isCancelled"
                            type="button"
                            class="btn btn-sm btn-link"
                            (click)="cancelSubscription()"
                            [disabled]="!canUpdateSubscription"
                        >
                            Cancel Subscription
                        </button>

                        <button
                            *ngIf="
                                isCancelled &&
                                subscription?.externalIdentifier != null
                            "
                            type="button"
                            class="btn btn-sm btn-link"
                            (click)="uncancelSubscription()"
                        >
                            Restart Subscription
                        </button>
                    </div>
                </ng-container>
                <div
                    *ngIf="
                        subscription?.externalIdentifier == null &&
                        subscription.subscriptionType !== 'SHARED'
                    "
                    class="alert alert-info"
                >
                    This is a custom subscription. Please contact sales to
                    modify it.
                </div>
                <div
                    *ngIf="subscription.subscriptionType == 'SHARED'"
                    class="alert alert-info"
                >
                    This is a shared subscription and can only be managed by the
                    subscription owner.
                </div>
            </div>
            <div class="action-container">
                <button
                    *ngIf="trialUpPlan != null"
                    (click)="trialUpSubscription()"
                    type="button"
                    class="btn btn-golden"
                    [disabled]="working"
                    [tooltip]="
                        'Trial the feature set from a ' +
                        trialUpPlan +
                        ' subscription.'
                    "
                    placement="top"
                >
                    Start a {{ trialUpPlan }} trial
                </button>
                <button
                    type="button"
                    [disabled]="salesContacted"
                    class="btn btn-default"
                    (click)="contactSales()"
                >
                    Contact Sales
                </button>
            </div>
        </div>
    </div>
    <h4 class="section-heading">
        <span>Licences</span>
        <div></div>
    </h4>
    <subscription-licences
        *ngIf="requiresLicences"
        [organisation]="organisation"
    ></subscription-licences>
    <div *ngIf="!requiresLicences" class="alert alert-info">
        Unlimited licences
    </div>
</div>
