import { MapEvent } from './map-event';
import { MapEventType } from './map-event-type';

export class MapMoveEndEvent implements MapEvent {
    type: MapEventType = MapEventType.mapMoveEnd;

    constructor(
        public payload: mapboxgl.MapboxEvent<
            MouseEvent | TouchEvent | WheelEvent
        > &
            mapboxgl.EventData
    ) {}
}
