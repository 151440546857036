<div *ngIf="activity != null">
    <div
        *ngIf="!hideCard"
        class="checklist-card"
        [@expandOnEnter]
        [@collapseOnLeave]
        (@collapseOnLeave.done)="onCardHidden()"
    >
        <div class="horizontal-container fill">
            <div class="fcol-2 activity-icon">
                <a
                    (click)="showCardMenu()"
                    [@tada]="tada"
                    class="activity-card-icon"
                    [ngClass]="{ 'icon-hover-states': !callAttention }"
                >
                    <span
                        class="card-flip-icon"
                        [ngClass]="{
                            'card-viewed': activity.new == false
                        }"
                        placement="right"
                    >
                        <span
                            [ngClass]="{
                                fa: true,
                                'fa-caret-down': collapsed,
                                'fa-caret-up': !collapsed
                            }"
                        ></span>
                    </span>
                    <span
                        *ngIf="activity.stepNumber != 0 && !callAttention"
                        class="activity-counter"
                        [ngClass]="{
                            'card-viewed': activity.new == false,
                            'card-complete fal fa-check':
                                autoCompleted &&
                                !activity.completed &&
                                !canShowTakeMeBack()
                        }"
                    >
                        {{
                            !autoCompleted ||
                            activity.completed ||
                            canShowTakeMeBack()
                                ? activity.stepNumber
                                : ''
                        }}
                    </span>
                    <span
                        *ngIf="activity.stepNumber == 0 && !callAttention"
                        class="activity-counter"
                        [ngClass]="{
                            'card-viewed': activity.new == false,
                            'card-complete fal fa-check':
                                autoCompleted && !activity.completed
                        }"
                    >
                        <img
                            *ngIf="!autoCompleted || activity.completed"
                            imgPath="/flyfreely-logo-sm.png"
                        />
                    </span>
                </a>
            </div>
            <div
                class="vertical-container fill"
                style="cursor: pointer"
                (click)="showHideCardActivities()"
            >
                <div class="activity-heading">
                    <span class="activity-title">{{ activity.title }}</span>
                    <span class="activity-subtitle">{{
                        menuState == MenuButtonType.COMPLETED
                            ? 'Completed'
                            : !autoCompleted || activity.completed
                            ? activity.subtitle
                            : 'Completed'
                    }}</span>
                </div>
            </div>
        </div>
        <div
            *ngIf="!collapsed"
            class="activity-card-action-container"
            [@collapseOnLeave]
            [@expandOnEnter]
        >
            <div
                *ngIf="
                    activity.videoThumbnail != null &&
                    menuState != MenuButtonType.MENU_ACTIVE &&
                    menuState != MenuButtonType.COMPLETED
                "
                class="horizontal-container fill"
                [@collapseOnLeave]
                [@expandOnEnter]
            >
                <div class="fcol-2 activity-icon">
                    <div
                        class="fill vertical-container"
                        style="
                            align-items: centert;
                            justify-content: flex-start;
                            margin-left: 3px;
                            margin-top: 12px;
                        "
                    >
                        <div *ngIf="!activity.completed && !autoCompleted">
                            <a
                                class="activity-card-icon"
                                (click)="completeCard()"
                                [ngClass]="{ readonly: working }"
                            >
                                <span
                                    class="activity-button-icon"
                                    style="margin-bottom: 15px; margin-right: 0"
                                    [tooltip]="
                                        !autoCompleted ? 'Mark As Done' : 'Done'
                                    "
                                    placement="right"
                                >
                                    <span class="fal fa-check"></span>
                                </span>
                            </a>
                        </div>
                        <div
                            *ngIf="
                                activity.hasActivityLink && canShowTakeMeThere()
                            "
                        >
                            <a
                                class="activity-card-icon"
                                (click)="goToActivity()"
                                [ngClass]="{ readonly: working }"
                            >
                                <span
                                    class="activity-button-icon"
                                    style="
                                        padding-left: 3px;
                                        padding-bottom: 1px;
                                        font-size: 17px;
                                        margin-right: 0;
                                    "
                                    tooltip="Take me there"
                                    placement="right"
                                >
                                    <span class="fal fa-share-square"></span>
                                </span>
                            </a>
                        </div>
                        <div
                            *ngIf="
                                !activity.completed &&
                                activity.hasActivityLink &&
                                canShowTakeMeBack()
                            "
                        >
                            <a
                                class="activity-card-icon"
                                (click)="goToActivity()"
                                [ngClass]="{ readonly: working }"
                            >
                                <span
                                    class="activity-button-icon"
                                    style="
                                        padding-left: 3px;
                                        padding-bottom: 1px;
                                        font-size: 17px;
                                        margin-right: 0;
                                    "
                                    tooltip="Take me back"
                                    placement="right"
                                >
                                    <span class="fal fa-share-square"></span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <a class="activity-thumbnail" (click)="showVideo()">
                    <img
                        *ngIf="thumbnail == null"
                        imgPath="/onboarding/checklist-assets/default-thumbnail.png"
                    />
                    <img *ngIf="thumbnail != null" [src]="thumbnail" />
                </a>
            </div>
            <div
                *ngIf="menuState == MenuButtonType.COMPLETED"
                class="horizontal-container fill"
                [@collapseOnLeave]
                [@expandOnEnter]
            >
                <div
                    class="fill vertical-container flex-centered top-buffer bottom-buffer"
                    [ngClass]="{
                        'activity-card-actions': activity.videoThumbnail != null
                    }"
                >
                    <div class="horizontal-container flex-centered">
                        <span class="activity-title">Completed!</span>
                    </div>
                </div>
            </div>
            <div
                *ngIf="menuState == MenuButtonType.MENU_ACTIVE"
                class="horizontal-container fill"
                [@collapseOnLeave]
                [@expandOnEnter]
            >
                <div
                    class="fill vertical-container flex-centered top-buffer bottom-buffer"
                    [ngClass]="{
                        'activity-card-actions': activity.videoThumbnail != null
                    }"
                >
                    <div *ngIf="!activity.completed">
                        <button
                            type="button"
                            class="activity-button"
                            (click)="completeCard()"
                            [ngClass]="{ readonly: working }"
                        >
                            <span class="activity-button-icon"
                                ><span class="fal fa-check"></span
                            ></span>
                            <span class="activity-title">
                                {{ !autoCompleted ? 'Mark As Done' : 'Done' }}
                            </span>
                        </button>
                    </div>
                    <div
                        *ngIf="activity.hasActivityLink && canShowTakeMeThere()"
                    >
                        <button
                            type="button"
                            class="activity-button"
                            (click)="goToActivity()"
                            [ngClass]="{ readonly: working }"
                        >
                            <span
                                class="activity-button-icon"
                                style="
                                    padding-left: 3px;
                                    padding-bottom: 1px;
                                    font-size: 17px;
                                "
                            >
                                <span class="fal fa-share-square"></span>
                            </span>
                            <span class="activity-title">Take Me There</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
