<div mdcFormField>
    <div mdcSwitch>
        <div mdcSwitchThumb>
            <input
                type="checkbox"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="active"
                mdcSwitchInput
                (change)="toggleEnhancedHelp()"
            />
        </div>
    </div>
    <label mdcFormFieldLabel>Enhanced Help </label>
</div>
