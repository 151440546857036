import {
    AirspaceAuthorisationDto,
    AirspaceCheckDto,
    LocationFeatureDto,
    OperationAuthorisation
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';

/**
 * A function that returns a geometry object suitable for use in the authorisation call command objects.
 * If the passed in flight area is a corridor, i.e. has a GeometryCollection type geometry, the function will strip out only the polygon for the command.
 * @param flightArea the flight area that will be used in the authorisation call
 * @returns a GeoJson.Polygon object
 */
export function findSelectedFlightAreaPolygon(flightArea: LocationFeatureDto) {
    if (flightArea == null || flightArea.geometry == null) {
        return null;
    }
    if (flightArea.geometry.type !== 'GeometryCollection') {
        return flightArea.geometry;
    }
    return flightArea.geometry.geometries.find(g => g.type === 'Polygon');
}

function findLatestAirspaceAuthorisationDate(
    authorisation: AirspaceAuthorisationDto
) {
    const created = moment(authorisation.createTime);
    const updated = moment(authorisation.updateTime);
    const cancelled = moment(authorisation.cancelTime);
    const isUpdated = updated.isAfter(created);
    const isCancelled =
        cancelled.isSameOrAfter(updated) && cancelled.isAfter(created);
    return isCancelled ? cancelled : isUpdated ? updated : created;
}

export function findLatestAirspaceAuthorisationInList(
    airspaceAuthorisationList: AirspaceAuthorisationDto[]
) {
    if (
        airspaceAuthorisationList == null ||
        airspaceAuthorisationList.length === 0
    ) {
        return null;
    }
    return airspaceAuthorisationList.reduce(
        (acc, a) =>
            findLatestAirspaceAuthorisationDate(a).isAfter(
                findLatestAirspaceAuthorisationDate(acc)
            )
                ? a
                : acc,
        airspaceAuthorisationList[0]
    );
}

/**
 * Determine if the airspace authorisation is required according to the airspace check result.
 */
export function isAuthorisationRequired(checkResult: AirspaceCheckDto) {
    if (
        checkResult != null &&
        checkResult.availableAutomatedAuthorisationList != null &&
        checkResult.availableAutomatedAuthorisationList.length > 0 &&
        checkResult.ruleOutcomes.some(
            o => o.outcome != null && o.outcome === 'AUTHORISATION_REQUIRED'
        )
    ) {
        return true;
    } else {
        return false;
    }
}

export function formatTime(time: string | Date, timeZone: string) {
    const localTime =
        timeZone != null
            ? moment(time).tz(timeZone).format('DD/MM/YYYY h:mmA')
            : moment(time).format('DD/MM/YYYY h:mmA');
    // If there's no timezone, then indicate that it's UTC
    return timeZone != null ? `${localTime} Local` : `${localTime} UTC`;
}

export function formatAuthorisationTime(time: string | Date, timeZone: string) {
    const localTime =
        timeZone != null
            ? moment(time).tz(timeZone).format('H:mm D MMM YYYY zz')
            : moment(time).format('H:mm D MMM YYYY zz');
    const utc = moment(time).utc().format('YYYY-MM-DD HH:mm');
    return `${localTime} (${utc}Z)`;
}

export function formatCivilTwilight(twilighttime: string, timeZone: string) {
    return twilighttime != null &&
        formatTime(twilighttime, timeZone) !== 'Invalid date Local'
        ? formatTime(twilighttime, timeZone)
        : null;
}

export const authorisationTypesWithMultipleAuthorisations = [
    AirspaceAuthorisationDto.AuthorisationType.USA_LAANC
];

/**
 * Takes the different types of authorisation responses and remaps the LAANC type to one the preview modal can show
 * @param authorisation the result from requesting an authorisation.
 * @returns the authorisation as an AirspaceAuthorisationDto with an authorisationList.
 */
export function mapAuthorisationResult(
    authorisations: OperationAuthorisation[] | AirspaceAuthorisationDto[]
): AirspaceAuthorisationDto[] {
    if (authorisations == null || authorisations.length === 0) {
        return null;
    }
    if (
        authorisations[0].authorisationType ===
        AirspaceAuthorisationDto.AuthorisationType.USA_LAANC
    ) {
        return (<OperationAuthorisation[]>(
            authorisations
        )).map<AirspaceAuthorisationDto>(authorisation => ({
            ...authorisation,
            // @ts-ignore - for now these don't overlap, but the different statuses are handled correctly in the rest of the front end.
            status: authorisation.status
        }));
    } else {
        return <AirspaceAuthorisationDto[]>authorisations;
    }
}

/**
 * A lookup object for airspaces that require the authorisation request to be submitted by the RPIC themselves
 * by matching airspace jurisdiction identifiers to their corresponding authorisation types.
 *
 * The jurisdiction identifiers are hard coded and need to be checked against the actual data.
 */
export const airspaceAuthorisationTypesRequiringCurrentUserAsRpic = {
    ['USA']: AirspaceAuthorisationDto.AuthorisationType.USA_LAANC
};

export const airspaceAuthorisationsRequiringDataManagement = ['USA'];
