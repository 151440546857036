import { Component, Input } from '@angular/core';
import {
    AttachmentHandler,
    JurisdictionService
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    template: `
        <div class="modal-header no-icon">
            <h3 class="modal-title">Jurisdiction Library</h3>
            <button
                type="button"
                (click)="close()"
                aria-label="Close"
                class="close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <attachment-list
                *ngIf="attachmentsHandler != null"
                [attachmentsHandler]="attachmentsHandler"
                [allowUploads]="false"
                [allowDelete]="false"
                [allowEdit]="false"
                [allowAcknowledgements]="true"
                [requestingOrganisationId]="organisationId"
            >
            </attachment-list>
        </div>
    `
})
export class JurisdictionLibraryDialogue {
    @Input() jurisdictionId: number;
    @Input() organisationId: number;

    attachmentsHandler: AttachmentHandler;

    constructor(
        private jurisdictionService: JurisdictionService,
        private modal: BsModalRef
    ) {}

    ngOnInit() {
        this.refreshAttachmentHandler();
    }

    refreshAttachmentHandler() {
        this.attachmentsHandler = this.jurisdictionService.attachmentHandler(
            this.jurisdictionId
        );
    }

    close() {
        this.modal.hide();
    }
}
