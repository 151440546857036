import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatLongitude' })
export class FormatLongitudePipe implements PipeTransform {
    transform(longitude: number) {
        if (longitude == null) {
            return null;
        }
        const absLongitude = Math.abs(longitude);
        const degrees = Math.floor(absLongitude);
        const minutes = (absLongitude - degrees) * 60;

        return `${degrees}&deg; ${minutes.toFixed(2)}${
            longitude > 0 ? 'E' : 'W'
        }`;
    }
}
