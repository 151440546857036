/**
 * Combines common search functions that can be used with ng-select. These
 * need to be bound to the controller so they can be called as methods.
 * ```
 * export class MyController {
 *     personSearch = personSearch.bind(this);
 * }
 * ```
 */

import {
    MissionSummaryDto,
    RpaTypeDto,
    SortieDto
} from '../model/api';
import { FormatDateTimePipe } from 'libs/ui/src/lib/pipes';

export function personSearch(
    term: string,
    item: { firstName: string; lastName: string; email: string }
) {
    const term_ = term.toUpperCase();
    return (
        item.firstName.toUpperCase().indexOf(term_) > -1 ||
        item.lastName.toUpperCase().indexOf(term_) > -1 ||
        item.email.toUpperCase().indexOf(term_) > -1
    );
}

export function rpaTypeSearch(term: string, item: RpaTypeDto) {
    term = term.toLocaleLowerCase();
    const rpaName = `${item.make} ${item.model}`.toLocaleLowerCase();
    const found = rpaName.indexOf(term) > -1;
    return found;
}

export function missionSearch(term: string, item: MissionSummaryDto) {
    term = term.toLocaleLowerCase();
    const formatDateTime = new FormatDateTimePipe();
    const missionName = `${item.name} ${formatDateTime.transform(
        item.missionDate
    )}`.toLocaleLowerCase();
    const found = missionName.indexOf(term) > -1;
    return found;
}

export function flightNumberSearch(term: string, item: SortieDto) {
    term = term.toLocaleLowerCase();
    const flightNumber = `Flight ${item.number}`.toLocaleLowerCase();
    const found = flightNumber.indexOf(term) > -1;
    return found;
}
