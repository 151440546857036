<div class="modal-header no-icon">
    <h3 class="modal-title pull-left">
        {{ authorisationType | formatAuthorisationType }} Pilot Registration
    </h3>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body inner-container-and-footer">
    <div>
        <!-- <span [innerHtml]="copyText | autolink"></span> -->
        <span>
            Please note:<br />
            In order to use
            {{ authorisationType | formatAuthorisationType }} authorisations you
            need to register with the
            {{ authorisationType | formatAuthorisationType }} system.
            Registration will either be for Part 101 operations only, or for
            Part 102 and Part 101 operations by associating with a Part 102
            operators certificate.<br />
            Once you register you will need to contact
            {{ authorisationType | formatAuthorisationType }} to update your
            registration and will not currently be able to change operations
            type from within this platform. We suggest that if you intend to fly
            under Part 102 at any point then you register as Part 102 now.
            <br />Registration will for commercial flying, using your personal
            organisation if no other organisation is available. To register for
            recreational flying, visit the
            {{ authorisationType | formatAuthorisationType }} website.
        </span>
    </div>
    <form [formGroup]="registrationForm" novalidate (ngSubmit)="register()">
        <div class="fill top-buffer">
            <label>Operations Type</label>
            <ng-select
                *ngIf="options != null"
                name="operations"
                [items]="options"
                formControlName="organisationId"
                [readonly]="working"
                bindLabel="name"
                bindValue="id"
                appendTo="body"
                placeholder="Select an Operations type"
            >
            </ng-select>
        </div>
        <div class="action-container">
            <button
                type="button"
                class="btn btn-primary"
                (click)="register()"
                [disabled]="working || !registrationForm.valid"
            >
                Register
            </button>
        </div>
    </form>
    <loading-indicator [isLoading]="working"></loading-indicator>
</div>
