import { Pipe, PipeTransform } from '@angular/core';
import {
    CraftDto,
} from '@flyfreely-portal-ui/flyfreely';
import { FormatDateTimePipe } from 'libs/ui/src/lib/pipes/generic/formatDateTime';
import { FormatDurationPipe } from 'libs/ui/src/lib/pipes';
import { calculateLogDuration } from './helpers';
import { FlightLogWithDuration } from './flight-log-list/flight-log-list-dialogue.component';

@Pipe({
    name: 'formatFlightLog'
})
export class FormatFlightLogPipe implements PipeTransform {
    constructor(
        private formatDateTime: FormatDateTimePipe,
        private formatDuration: FormatDurationPipe
    ) {}

    transform(flightLog: FlightLogWithDuration, rpa?: CraftDto) {
        return `${this.formatName(flightLog, rpa)}
        ${this.formatDateTime.transform(flightLog.summary?.startTime)}
        <em> ${this.formatDuration.transform(
            calculateLogDuration(flightLog)
        )}</em>`;
    }

    formatName(flightLog: FlightLogWithDuration, rpa?: CraftDto) {
        if (rpa == null) {
            return `<strong><em>Unassigned</em> (${flightLog.summary?.rpaName}) </strong>`;
        }
        return `<strong>${rpa.nickname} (${flightLog.summary?.rpaName}) </strong>`;
    }
}
