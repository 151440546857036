<div class="panel widget" *ngIf="(missions && missions.length > 0) || working">
    <div class="panel-heading-flex">
        <div class="panel-title no-uppercase">
            Missions Awaiting Finalisation
        </div>
        <div class="panel-content"></div>
        <div class="panel-buttons">
            <button
                class="btn btn-inline"
                (click)="showBody = !showBody"
                [tooltip]="showBody ? 'Hide' : 'Show'"
                placement="left"
            >
                <i *ngIf="showBody" class="fa-chevron-up fa no-border"></i>
                <i *ngIf="!showBody" class="fa-chevron-down fa no-border"></i>
            </button>
        </div>
    </div>
    <div *ngIf="showBody" class="panel-body" @expandOnEnter @collapseOnLeave>
        <div
            class="static-table-container no-actions"
            *ngIf="missions && missions.length"
        >
            <static-table
                [availableColumns]="availableColumns"
                [selectedColumns]="selectedColumns"
                [tableData]="missions"
                [tableConfig]="tableConfig"
                [borderless]="false"
                [underlinedRows]="true"
                scanability="comfortable"
                (pageChanged)="onTablePageChanged($event)"
                (selectedColumnsChanged)="
                    updateSelectedColumns($event.selectedColumns)
                "
                (sortOrderChanged)="updateColumnSorting($event)"
                (itemLimitUpdated)="updateItemLimit($event)"
            >
                <ng-template
                    columnTemplate="rpic"
                    let-row="row"
                    let-column="column"
                >
                    <div style="width: 200px">
                        <person-avatar
                            [person]="row.rpic"
                            [managingOrganisationId]="organisation.id"
                        ></person-avatar>
                    </div>
                </ng-template>
            </static-table>
        </div>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
<completed-outstanding-tasks-banner
    *ngIf="(!missions || missions.length == 0) && !working"
    widgetHeading="Missions Awaiting Finalisation"
></completed-outstanding-tasks-banner>
