import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AirspaceAuthorisationDto,
    AirspaceAuthorisationResponse,
    FlyFreelyConstants,
    httpParamSerializer,
    RegisterRemotePilotCommand,
    RemotePilotRegistrationDto,
    RemotePilotRegistrationOptions,
    RequestAirspaceAuthorisationCommand
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface RegisterPilotForAirspaceAuthorityCommand {
    personId: number;
    organisationId: number;
    authorisationType: AirspaceAuthorisationDto.AuthorisationType;
}

export interface RegisterPilotForAirspaceAuthorityResponse {
    remotePilotEmail: string;
    organisationId: number;
    authorityId: number;
    authenticationProviderResponse: string;
}

export const airspaceAuthorisationTypeList = [
    {
        name: 'AirShare',
        value: AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE
    },
    {
        name: 'CASA',
        value: AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION
    },
    {
        name: 'FIMS',
        value: AirspaceAuthorisationDto.AuthorisationType.AUS_ASA_FIMS
    },
    {
        name: 'LAANC',
        value: AirspaceAuthorisationDto.AuthorisationType.USA_LAANC
    }
];

@Injectable({
    providedIn: 'root'
})
export class AirspaceAuthorisationService {
    private baseUrl: string;

    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    getAuthorisationPreview(command: RequestAirspaceAuthorisationCommand) {
        return this.http.post<AirspaceAuthorisationResponse>(
            `${this.baseUrl}/webapi/airspaceAuthorisations/preview`,
            command
        );
    }

    applyForAuthorisation(command: RequestAirspaceAuthorisationCommand) {
        return this.http
            .post<AirspaceAuthorisationDto[]>(
                `${this.baseUrl}/webapi/airspaceAuthorisations`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findAuthorisation(
        id: string,
        authorisationType: string = 'AUS_CASA_AUTHORISATION'
    ) {
        return this.http.get<AirspaceAuthorisationDto[]>(
            `${this.baseUrl}/webapi/airspaceAuthorisations/${authorisationType}/${id}`
        );
    }

    cancelAuthorisation(
        id: string,
        authorisationType: string = 'AUS_CASA_AUTHORISATION'
    ) {
        return this.http
            .post<AirspaceAuthorisationDto>(
                `${this.baseUrl}/webapi/airspaceAuthorisations/${authorisationType}/${id}/cancel`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    closeAuthorisation(
        id: string,
        authorisationType: string = 'AUS_CASA_AUTHORISATION'
    ) {
        return this.http
            .post<AirspaceAuthorisationDto>(
                `${this.baseUrl}/webapi/airspaceAuthorisations/${authorisationType}/${id}/close`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findPilotRegistrationOptions(
        authorisationType: AirspaceAuthorisationDto.AuthorisationType,
        managingOrganisationId: number
    ) {
        return this.http.get<RemotePilotRegistrationOptions>(
            `${this.baseUrl}/webapi/registrations/registerRp`,
            {
                params: httpParamSerializer({
                    authorisationType,
                    managingOrganisationId
                })
            }
        );
    }

    registerPilotForAuthority(command: RegisterRemotePilotCommand) {
        return this.http.post<RemotePilotRegistrationDto>(
            `${this.baseUrl}/webapi/registrations/registerRp`,
            command
        );
    }

    getExternalAuthorisationBearerToken(missionId: number) {
        return this.http.post<{ accessToken: string }>(
            `${this.baseUrl}/webapi/airspaceAuthorisations/token`,
            null,
            {
                params: httpParamSerializer({
                    missionId
                })
            }
        );
    }
}
