import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/**
 * The check component sets its value to 'true' or '' depending on
 * its checked status.
 */
@Component({
    selector: 'formly-check',
    template: `
        <div class="checkbox">
            <label>
                <input
                    type="checkbox"
                    class="formly-field-checkbox"
                    [class.is-invalid]="showError"
                    [indeterminate]="
                        to.indeterminate && formControl.value === null
                    "
                    [(ngModel)]="value"
                    [formlyAttributes]="field"
                />
                <span class="cr"
                    ><i class="cr-icon glyphicon glyphicon-ok"></i>
                </span>
                {{ to.label }}
            </label>
        </div>
    `
})
export class FormlyCheck extends FieldType {
    defaultOptions = {
        props: {
            indeterminate: false,
            hideLabel: true
        }
    };

    set value(checked: boolean) {
        this.formControl.setValue(checked ? 'true' : '');
    }

    get value() {
        return this.formControl.value === 'true';
    }
}
