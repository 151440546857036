import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    FEATURE_MISSION_CALENDAR,
    LocationService,
    MissionSummaryDto,
    PersonDto,
    WidgetMission
} from '@flyfreely-portal-ui/flyfreely';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { FormatMissionStatusIconPipe } from '@flyfreely-portal-ui/ui';
import * as moment from 'moment-timezone';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { findRpic, getMonthRange } from '../helpers';

const colors: { [colour: string]: { primary: string; secondary: string } } = {
    transparent: {
        primary: 'transparent',
        secondary: 'transparent'
    },
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    },
    grey: {
        primary: '#d5d5dc',
        secondary: '#4A4A4A'
    },
    green: {
        primary: '#42b983',
        secondary: '#00de7a'
    }
};

interface MissionCalendarEvent extends CalendarEvent {
    status: MissionSummaryDto.Status;
    time: string;
    locationName: string;
    rpaNames: string[];
    rpic: PersonDto;
}

@Component({
    selector: 'mission-calendar-dialogue',
    templateUrl: './mission-calendar.component.html',
    styles: [
        `
            .cal-event-container span {
                word-break: break-word;
            }
        `
    ]
})
export class MissionCalendarDialogue implements OnInit, OnDestroy {
    @Input() missions$: Observable<WidgetMission[]>;
    @Output() showMission = new EventEmitter<WidgetMission>();
    @Output() updateMissions = new EventEmitter<{
        startTime: string;
        endTime: string;
    }>();

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    refresh = new Subject<void>();

    events$: Observable<MissionCalendarEvent[]>;

    activeDayIsOpen = true;

    missionCalendarFeature = [FEATURE_MISSION_CALENDAR];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<MissionCalendarDialogue>,
        private locationService: LocationService,
        private missionStatusIconPipe: FormatMissionStatusIconPipe
    ) {}

    ngOnInit() {
        this.events$ = this.missions$.pipe(
            map(missions =>
                missions.map(mission => ({
                    start: moment(mission.missionDate).toDate(),
                    id: mission.id,
                    color: this.missionColour(mission),
                    title: this.findLocationName(mission),
                    end: moment(mission.missionDate)
                        .add(mission.missionEstimatedTime, 'seconds')
                        .toDate(),
                    draggable: false,
                    // FlyFreely properties
                    status: mission.status,
                    time: `${this.calculateMissionTime(
                        mission.missionDate,
                        mission.missionEstimatedTime
                    )}`,
                    locationName: `${this.findLocationName(mission)}`,
                    rpaNames: mission.craftNicknames,
                    rpic: findRpic(mission.missionCrewDetails)
                }))
            )
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    missionColour(mission: WidgetMission) {
        const col = this.missionStatusIconPipe.findStatusColour(mission.status);
        switch (col) {
            case 'green':
                return colors.green;

            case 'yellow':
                return colors.yellow;

            case 'grey':
                return colors.grey;

            case 'red':
                return colors.red;

            case 'blue':
                return colors.blue;

            default:
                return colors.transparent;
        }
    }

    calculateMissionTime(start: string | Date, duration: number) {
        const startTime = moment(start).format('hh:mm a');
        const endTime = moment(start)
            .add(duration, 'seconds')
            .format('hh:mm a');
        return `${startTime} - ${endTime}`;
    }

    findLocationName(mission: WidgetMission) {
        if (mission.locationName != null) {
            return mission.locationName;
        } else if (mission.locationId != null) {
            this.locationService
                .findLocation(mission.locationId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(location => {
                    return location.name ?? '';
                });
        } else {
            return `Mission: ${mission.name}`;
        }
    }

    /* setView(view: any) {
        this.view = view;
        this.refresh.next();
    } */

    openEvent(event: CalendarEvent) {
        this.missions$.subscribe(missions => {
            const mission = missions.find(m => m.id === event.id);
            this.showMission.emit(mission);
        });
    }

    /* openWeek(date: any) {
        this.viewDate = date;
        this.view = CalendarView.Week;
    } */

    closeOpenMonthViewDay() {
        this.refreshMissions();
        this.activeDayIsOpen = false;
    }

    refreshMissions() {
        const { startOfMonth, endOfMonth } = getMonthRange(
            this.viewDate.toISOString()
        );

        this.updateMissions.next({
            startTime: startOfMonth,
            endTime: endOfMonth
        });
    }

    hide() {
        this.modal.hide();
    }
}
