import { Component, Input } from '@angular/core';
import {
    BatterySetDto,
    BatterySetLastUse,
    BatterySetService,
    CraftDto,
    CraftService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatRpaTypePipe } from '@flyfreely-portal-ui/resource-ui';
import { FormatDateTimePipe } from '@flyfreely-portal-ui/ui';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'battery-set-compatible-rpa',
    templateUrl: './battery-set-compatible-rpa.component.html'
})
export class BatterySetCompatibleRpaComponent {
    @Input() batterySet: BatterySetDto;
    @Input() organisationId: number;

    allRpa: CraftDto[] = [];
    compatibleRpa: CraftDto[] = [];
    rpaTypesLookup: { [rpaTypeId: number]: RpaTypeDto };
    batterySetLastUse: {
        [rpaId: number]: BatterySetLastUse;
    };

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;

    rpaLoaded = false;

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private rpaService: CraftService,
        private rpaTypesService: RpaTypesService,
        private batterySetService: BatterySetService,
        private rpaTypePipe: FormatRpaTypePipe,
        private formatDateTimePipe: FormatDateTimePipe,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.availableColumns = [
            {
                name: 'Name',
                key: 'nickname',
                defaultSelection: true,
                selectable: false,
                searchable: false
            },
            {
                name: 'Make & Model',
                key: 'rpaTypeId',
                defaultSelection: true,
                selectable: false,
                searchable: false,
                formatterFunction: (t, r) =>
                    this.rpaTypesLookup != null
                        ? `${this.rpaTypePipe.transform(
                              this.rpaTypesLookup[r.rpaTypeId]
                          )}`
                        : ''
            },
            {
                name: 'Last Use',
                key: 'id',
                defaultSelection: true,
                selectable: false,
                searchable: false,
                formatterFunction: (t, r) =>
                    this.batterySetLastUse != null &&
                    this.batterySetLastUse[r.id] != null
                        ? this.formatDateTimePipe.transform(
                              this.batterySetLastUse[r.id].lastUse
                          )
                        : 'Not yet flown together',
                description:
                    'The last time this RPA was flown using this battery set'
            }
        ];
        this.tableConfig = {
            actions: [],
            limit: 10
        };
    }

    ngOnInit() {
        this.batterySetService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshRpa());

        this.refreshRpa();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshRpa() {
        this.rpaLoaded = false;
        forkJoin([
            this.rpaService.findCrafts(this.organisationId),
            this.rpaTypesService.findRpaTypes(this.organisationId),
            this.batterySetService.findLastBatterySetUse(
                this.batterySet.id,
                this.organisationId
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([rpa, rpaTypes, lastUse]) => {
                    this.allRpa = rpa;
                    this.rpaTypesLookup = rpaTypes.reduce(toLookup, {});
                    this.batterySetLastUse = lastUse.reduce(
                        (acc, l) => ({
                            ...acc,
                            [l.rpa.id]: l
                        }),
                        {}
                    );
                    // Reduce all the batteries in the set to {[battery type ID]: number of batteries of that type in the set}
                    const batterySetTypes = this.batterySet.batteries.reduce(
                        (acc: { [key: string]: number }, battery) =>
                            acc == null
                                ? {
                                      [battery.batteryTypeId]: this.batterySet.batteries.filter(
                                          b =>
                                              b.batteryTypeId ===
                                              battery.batteryTypeId
                                      ).length
                                  }
                                : acc[battery.batteryTypeId] != null
                                ? acc
                                : (acc[
                                      battery.batteryTypeId
                                  ] = this.batterySet.batteries.filter(
                                      b =>
                                          b.batteryTypeId ===
                                          battery.batteryTypeId
                                  ).length),
                        null
                    );
                    // Filter all the org RPA using their RPA Types to determine if they are compatible with the set
                    this.compatibleRpa = this.allRpa.filter(rpa => {
                        const rpaType = rpaTypes.find(
                            type => type.id === rpa.rpaTypeId
                        );
                        return Object.keys(batterySetTypes).reduce(
                            (acc, t) =>
                                acc &&
                                rpaType?.compatibleBatteryTypeIdList.includes(
                                    parseInt(t, 10)
                                ) &&
                                rpaType?.compatibleBatteryTypeRequirements[
                                    t
                                ] === batterySetTypes[t],
                            true
                        );
                    });
                    this.rpaLoaded = true;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading compatible RPAs: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }
}
