<div
    class="horizontal-container fill align-center container-scroll modal-scrollbar"
    *ngIf="airspaceStatus != null"
>
    <div class="checking-container">
        <span *ngIf="airspaceStatus == 'CHECKING'"><em>Checking...</em></span>
        <span *ngIf="airspaceStatus == 'INCOMPLETE'">
            <em>Airspace check conditions incomplete</em>
        </span>
        <span
            *ngIf="
                airspaceStatus == 'NO_CHECK' &&
                authorisationPreviewAdvises.length == 0 &&
                authorisationPreviewBlocks.length == 0
            "
        >
            <em>
                No airspace check available for the current planned location
            </em>
        </span>
    </div>
    <div
        *ngIf="
            airspaceStatus !== 'CHECKING' &&
            airspaceStatus !== 'INCOMPLETE' &&
            airspaceStatus !== 'NO_CHECK' &&
            airspaceCheckAdvises.length == 0 &&
            authorisationPreviewAdvises.length == 0 &&
            airspaceCheckBlocks.length == 0 &&
            authorisationPreviewBlocks.length == 0
        "
        class="airspace-authorisation-result pass fcol-6"
    >
        <div
            style="
                line-height: 32px;
                display: flex;
                text-transform: uppercase;
                font-weight: bold;
            "
        >
            <span
                style="font-size: 32px; margin-right: 15px; font-weight: normal"
                class="fal fa-radar"
            ></span>
            Airspace is clear for takeoff
        </div>
    </div>

    <ng-container
        *ngIf="airspaceStatus != 'CHECKING' && airspaceStatus != 'INCOMPLETE'"
    >
        <ng-container
            *ngFor="let block of airspaceCheckBlocks; let last = last"
        >
            <ng-template #tooltipMessage>
                <p [innerHTML]="block.message" class="tooltip-inner"></p>
            </ng-template>
            <div
                class="outcome-card result-section block"
                [ngClass]="{
                    pad: !last || authorisationPreviewBlocks.length > 0
                }"
                [tooltip]="tooltipMessage"
                placement="top"
            >
                <span class="icon fad fa-times-circle"></span>&nbsp;&nbsp;{{
                    block.name
                }}
            </div>
        </ng-container>
        <ng-container
            *ngFor="let block of authorisationPreviewBlocks; let last = last"
        >
            <ng-template #tooltipMessage>
                <p [innerHTML]="block.message" class="tooltip-inner"></p>
            </ng-template>
            <div
                class="outcome-card result-section block"
                [ngClass]="{ pad: !last || airspaceCheckAdvises.length > 0 }"
                [tooltip]="tooltipMessage"
                placement="top"
            >
                <span class="icon fad fa-times-circle"></span>&nbsp;&nbsp;{{
                    block.name
                }}
                <span
                    *ngIf="
                        block.relatedMissionIdList &&
                        block.relatedMissionIdList.length > 0
                    "
                    class="left-buffer"
                >
                    <a
                        [popover]="responseMissionListTemplate"
                        [popoverContext]="{
                            relatedMissionIdList: block.relatedMissionIdList,
                            popover: popover
                        }"
                        [outsideClick]="true"
                        #popover="bs-popover"
                        placement="bottom"
                    >
                        Related Missions&nbsp;<span
                            class="fal fa-chevron-down"
                        ></span>
                    </a>
                </span>
            </div>
        </ng-container>
        <ng-container
            *ngFor="let advise of airspaceCheckAdvises; let last = last"
        >
            <ng-template #tooltipMessage>
                <p [innerHTML]="advise.message" class="tooltip-inner"></p>
            </ng-template>
            <div
                class="outcome-card result-section advise"
                [ngClass]="{ pad: !last || !authorisationPreviewAdvises }"
                [tooltip]="tooltipMessage"
                placement="top"
            >
                <span class="icon fad fa-exclamation-circle"></span
                >&nbsp;&nbsp;{{ advise.name }}
            </div>
        </ng-container>

        <!-- <span [tooltip]="HtmlContent" contentType="template">
  Tooltip with template content
</span> -->
        <ng-container
            *ngFor="let advise of authorisationPreviewAdvises; let last = last"
        >
            <ng-template #tooltipMessage>
                <p [innerHTML]="advise.message" class="tooltip-inner"></p>
            </ng-template>
            <div
                class="outcome-card result-section advise"
                [ngClass]="{ pad: !last }"
                [tooltip]="tooltipMessage"
                placement="top"
            >
                <span class="icon fad fa-exclamation-circle"></span
                >&nbsp;&nbsp;{{ advise.name }}
                <span
                    *ngIf="
                        advise.relatedMissionIdList &&
                        advise.relatedMissionIdList.length > 0
                    "
                    class="left-buffer"
                >
                    <a
                        [popover]="responseMissionListTemplate"
                        [popoverContext]="{
                            relatedMissionIdList: advise.relatedMissionIdList,
                            popover: popover
                        }"
                        [outsideClick]="true"
                        #popover="bs-popover"
                        placement="bottom"
                    >
                        Related Missions&nbsp;<span
                            class="fal fa-chevron-down"
                        ></span>
                    </a>
                </span>
            </div>
        </ng-container>

        <button
            *ngIf="airspaceCheckAdvises.length || airspaceCheckBlocks.length"
            type="button"
            class="btn btn-link"
            [disabled]="airspaceStatus === 'CHECKING'"
            (click)="showAirspaceDetails()"
        >
            View Airspace Details
        </button>
    </ng-container>
</div>

<ng-template
    #responseMissionListTemplate
    let-relatedMissionIdList="relatedMissionIdList"
    let-popover="popover"
>
    <div *ngFor="let missionId of relatedMissionIdList">
        <a (click)="goToRelatedMission(missionId)">{{
            relatedMissionLookup[missionId] != null
                ? relatedMissionLookup[missionId].name
                : 'Loading...'
        }}</a>
    </div>
</ng-template>
