import { Injectable } from '@angular/core';
import {
    CombinedMissionStatus,
    FEATURE_MISSION_CALENDAR,
    FEATURE_MISSION_REPORTS,
    FEATURE_ORGANISATION_RELATIONSHIPS,
    FEATURE_RPA_LOG_COLLECTION,
    GqlFilterField,
    MissionCrewDetailsDto,
    MissionDto,
    PersonsOrganisationDto,
    hasAnyPermission,
    hasFeatureFlag,
    revertCombinedStatus,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import {
    TableColumn,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatPersonPipe } from '@flyfreely-portal-ui/ui';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class MissionViewService {
    private viewModeSource = new BehaviorSubject<'MAP' | 'LIST' | 'BOTH'>(
        'BOTH'
    );
    viewMode$ = this.viewModeSource.asObservable();

    currentOrganisation: PersonsOrganisationDto;

    private showReportsDialogueSource = new Subject<void>();
    private showMissionCalendarSource = new Subject<void>();
    private permissionsUpdatedSource = new BehaviorSubject<boolean>(false);
    showReportsDialogue$ = this.showReportsDialogueSource.asObservable();
    showMissionCalendar$ = this.showMissionCalendarSource.asObservable();
    permissionsUpdated$ = this.permissionsUpdatedSource.asObservable();

    canFilterByAuthority = false;
    canUseCalendar = true;
    canListMissions = true;
    canSeeOwnMissions = true;
    hasFlightLogging = true;
    canReport = true;
    canAddMission = true;
    canEditMission = true;

    constructor(private formatPersonPipe: FormatPersonPipe) {}

    ngOnDestroy() {
        this.viewModeSource.complete();
        this.showReportsDialogueSource.complete();
        this.showMissionCalendarSource.complete();
        this.permissionsUpdatedSource.complete();
    }

    setViewMode(viewMode: 'MAP' | 'LIST' | 'BOTH') {
        this.viewModeSource.next(viewMode);
    }

    refreshPermissions(organisation: PersonsOrganisationDto) {
        this.currentOrganisation = organisation;
        if (this.currentOrganisation == null) {
            this.canAddMission = false;
            this.canEditMission = false;
            this.canListMissions = false;
            this.canSeeOwnMissions = false;
            this.canFilterByAuthority = false;
            return;
        }

        this.canAddMission = hasAnyPermission(this.currentOrganisation, [
            PersonsOrganisationDto.Permissions.MISSION_ADD,
            PersonsOrganisationDto.Permissions.MISSION_ADD_OWN
        ]);

        this.canEditMission = hasAnyPermission(
            this.currentOrganisation,
            PersonsOrganisationDto.Permissions.MISSION_EDIT
        );

        this.canListMissions = hasAnyPermission(this.currentOrganisation, [
            PersonsOrganisationDto.Permissions.MISSION_LIST
        ]);

        this.canSeeOwnMissions = hasAnyPermission(this.currentOrganisation, [
            PersonsOrganisationDto.Permissions.MISSION_VIEW
        ]);

        this.canFilterByAuthority = hasFeatureFlag(
            this.currentOrganisation,
            FEATURE_ORGANISATION_RELATIONSHIPS
        );

        this.hasFlightLogging =
            hasFeatureFlag(
                this.currentOrganisation,
                FEATURE_RPA_LOG_COLLECTION
            ) &&
            hasAnyPermission(this.currentOrganisation, [
                PersonsOrganisationDto.Permissions.FLIGHT_LOG_COLLECTION_MANAGE,
                PersonsOrganisationDto.Permissions.FLIGHT_LOG_COLLECTION_OWN,
                PersonsOrganisationDto.Permissions.FLIGHT_LOG_MANAGE
            ]);

        this.canReport = hasFeatureFlag(
            this.currentOrganisation,
            FEATURE_MISSION_REPORTS
        );

        this.canUseCalendar = hasFeatureFlag(
            this.currentOrganisation,
            FEATURE_MISSION_CALENDAR
        );
        this.permissionsUpdatedSource.next(true);
    }

    findCrew(missionCrew: MissionCrewDetailsDto[]) {
        return missionCrew
            .filter(c => c.role.coreRole !== 'PILOT_IN_COMMAND')
            .map(c => this.formatPersonPipe.transform(c.person));
    }

    parseFilterFieldNames(name: string) {
        switch (name) {
            case 'missionWorkflowVersion.name':
                return 'workflowName';

            case 'locationName':
                return 'locationName';

            case 'missionCrewNames':
                return 'missionCrew';

            case 'missionType.name':
                return 'missionOperationType';

            case 'missionApproval.requestTime':
                return 'missionApprovalRequestTime';

            case 'missionApproval.resolutionTime':
                return 'missionApprovalResolutionTime';

            default:
                return name;
        }
    }

    getDateFilterValue(
        search: Record<string, unknown>,
        key: string,
        field: string
    ) {
        if (search == null) {
            return [];
        }
        const date = search[Object.keys(search).find(k => k === key)];
        if (date == null) {
            return [];
        }
        const dateValue = `${toTimestamp(date[0])} ${toTimestamp(date[1])}`;
        return [
            {
                field: field,
                filter: dateValue
            }
        ];
    }

    private createCombinedDeconstructedStatuses(
        search: Record<string, unknown>
    ) {
        const combinedStatus =
            search != null
                ? (search[
                      Object.keys(search).find(k => k === 'combinedStatus')
                  ] as CombinedMissionStatus)
                : null;
        const deconstructedStatus = revertCombinedStatus(combinedStatus);
        const combinedDeconstructed = { combinedStatus, deconstructedStatus };
        return combinedDeconstructed;
    }

    updateMissionStatusFilters(search: Record<string, unknown>) {
        const {
            combinedStatus,
            deconstructedStatus
        } = this.createCombinedDeconstructedStatuses(search);
        return deconstructedStatus?.missionStatus;
    }

    filterCombinedStatus(search: Record<string, unknown>) {
        const {
            combinedStatus,
            deconstructedStatus
        } = this.createCombinedDeconstructedStatuses(search);
        if (combinedStatus == null || deconstructedStatus == null) {
            return [];
        }
        const approvalStatuses: GqlFilterField[] =
            deconstructedStatus.approvalStatus == null ||
            deconstructedStatus.approvalStatus.length === 0
                ? []
                : deconstructedStatus.approvalStatus.map(s => ({
                      field: 'status',
                      filter: s.toString()
                  }));
        const readyForFinalisation: GqlFilterField[] =
            deconstructedStatus.readyForFinalisation == null
                ? []
                : [
                      {
                          field: 'readyForFinalisation',
                          filter: deconstructedStatus.readyForFinalisation
                      }
                  ];
        const outstandingApprovals: GqlFilterField[] =
            deconstructedStatus.outstandingApprovals == null
                ? []
                : [
                      {
                          field: 'outstandingApprovals',
                          filter: deconstructedStatus.outstandingApprovals
                      }
                  ];
        return approvalStatuses
            .concat(readyForFinalisation)
            .concat(outstandingApprovals);
    }

    fixFilter(availableColumns: TableColumn[]) {
        return (p: TableSetupUserPreferences) => {
            const sort =
                availableColumns != null &&
                p?.columnSorting?.column != null &&
                (availableColumns.find(
                    c =>
                        c.value === p.columnSorting.column ||
                        c.key === p.columnSorting.column
                ) == null ||
                    availableColumns.find(
                        c =>
                            c.value === p.columnSorting.column ||
                            c.key === p.columnSorting.column
                    ).sortable === false)
                    ? {
                          ...p,
                          columnSorting: null
                      }
                    : p;

            const pref =
                sort?.tableSearch?.combinedStatuses ===
                'DRAFT|READY_TO_FLY|PREPARED|FLYING|ON_SITE|DONE_FLYING|COMPLETED'
                    ? {
                          ...sort,
                          tableSearch: {
                              ...sort.tableSearch,
                              combinedStatuses:
                                  'DRAFT|SUBMITTED|READY_TO_FLY|PREPARED|FLYING|ON_SITE|DONE_FLYING|COMPLETED|READY_FOR_FINALISATION'
                          }
                      }
                    : sort;
            if (pref?.tableSearch != null) {
                const getDates = (k: string) => {
                    return pref.tableSearch[k] != null
                        ? (<string[]>pref.tableSearch[k]).map(d =>
                              moment(d).toDate()
                          )
                        : null;
                };
                const search = Object.keys(pref.tableSearch).reduce(
                    (acc, k) => {
                        if (
                            k.toString() ===
                            'missionWorkflowVersion.workflowName'
                        ) {
                            return {
                                ...acc,
                                ['missionWorkflowVersion.name']:
                                    pref.tableSearch[
                                        'missionWorkflowVersion.workflowName'
                                    ]
                            };
                        } else if (
                            k.toString() === 'missionDate' ||
                            k.toString() === 'missionApproval.requestTime' ||
                            k.toString() === 'missionApproval.resolutionTime'
                        ) {
                            if (
                                pref.tableSearch[k] == null ||
                                (<string[]>pref.tableSearch[k]).filter(
                                    s => s != null
                                ).length === 0
                            ) {
                                return {
                                    ...acc,
                                    [k]: null
                                };
                            }
                            return {
                                ...acc,
                                [k]: getDates(k.toString())
                            };
                        } else if (k === 'type') {
                            return {
                                ...acc,
                                type: this.validateOptions(
                                    pref.tableSearch[k],
                                    [
                                        MissionDto.Type.STANDARD,
                                        MissionDto.Type.RETROSPECTIVE,
                                        MissionDto.Type.OFFLINE,
                                        MissionDto.Type.FIELD_APP_BACKUP,
                                        MissionDto.Type.IMPORTED
                                    ]
                                )
                            };
                        } else {
                            return {
                                ...acc,
                                [k]: pref.tableSearch[k]
                            };
                        }
                    },
                    {}
                );
                return {
                    ...pref,
                    tableSearch: {
                        ...search
                    }
                };
            } else {
                return pref;
            }
        };
    }

    private validateOptions(current: string, options: any[]): string {
        if (current == null) {
            return current;
        }

        for (const option of options) {
            if (option === current) {
                return current;
            }
        }

        return null;
    }

    showMissionReportsDialogue() {
        this.showReportsDialogueSource.next();
    }

    showMissionCalendar() {
        this.showMissionCalendarSource.next();
    }
}
