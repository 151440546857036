import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import {
    LoginManager,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { Subject, firstValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * This authentication guard is responsible for initialiating a login if required, via the `LoginManager`.
 */
@Injectable()
export class AuthenticatedGuard implements CanActivate {
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private loginManager: LoginManager
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    async canActivate() {
        if (this.userService.getUserStatus() === UserStatus.LOGGED_IN) {
            return true;
        }
        if (
            this.userService.getUserStatus() === UserStatus.UNKNOWN ||
            this.userService.getUserStatus() === UserStatus.LOADING
        ) {
            // wait until changed
            const newStatus = await firstValueFrom(
                this.userService.userChange$.pipe(
                    filter(
                        c =>
                            c.type !== UserStatus.UNKNOWN &&
                            c.type !== UserStatus.LOADING
                    ),
                    takeUntil(this.ngUnsubscribe$)
                )
            );
            if (newStatus.type === UserStatus.LOGGED_IN) {
                return true;
            }
        }
        this.loginManager.login();
        return false;
    }
}
