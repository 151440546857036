import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    AdminOrganisationDto,
    AuthorityRegisterSummaryDto,
    AuthorityService,
    AuthorityTypeDto,
    CraftAuthorityDto,
    CreateCraftAuthorityCommand,
    CreateOrganisationAuthorityCommand,
    DO_NOTHING,
    FlyFreelyLoggingService,
    NameId,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    OrganisationDto,
    PersonsOrganisationDto,
    SharedAuthorityGroup,
    SimpleAirspaceJurisdictionDto,
    SimpleAuthorityDto,
    UiCraftAuthorityDto,
    UiOrganisationAuthorityDto,
    UpdateAuthorityCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation,
    fadeInExpandOnEnterAnimation,
    fadeInOnEnterAnimation,
    fadeOutCollapseOnLeaveAnimation,
    fadeOutOnLeaveAnimation
} from 'angular-animations';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import * as moment from 'moment-timezone';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AuthorityDialoguesService } from '../../authority-dialogues.service';
import { AuthorityGroupAddService } from '../../authority-group-add/authority-group-add.service';
import {
    findNewJurisdictions,
    reduceToGroupWithOneAuthority,
    toSimpleAuthority
} from '../../helpers';
import { AuthorityGroupEditService } from '../../registers/interfaces';
import {
    OrganisationAuthorityEditService,
    RegisterExpirySummary
} from '../organisation-authority-edit.service';

const GLOBAL_JURISDICTION_ID = -1;

function isRequiredByPrimary(
    authority: OrganisationAuthorityGroup,
    primaryAuthorities: OrganisationAuthorityGroup[]
) {
    return (
        primaryAuthorities.filter(
            a =>
                a.dependentOnAuthorityType != null &&
                a.dependentOnAuthorityType.id === authority.id
        ).length > 0
    );
}
@Component({
    selector: 'organisation-authority-edit',
    templateUrl: './organisation-authority-edit.component.html',
    providers: [
        AuthorityGroupAddService,
        {
            provide: AuthorityGroupEditService,
            useClass: OrganisationAuthorityEditService
        }
    ],
    animations: [
        fadeInExpandOnEnterAnimation(),
        fadeOutCollapseOnLeaveAnimation(),
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation(),
        expandOnEnterAnimation(),
        collapseOnLeaveAnimation()
    ],
   
})
export class OrganisationAuthorityEdit implements OnInit {
    @Input() organisationId: number;
    @Input() managingOrganisationId: number;
    @Input() showDone: boolean;
    @Input() icon: string;

    @Output() done = new EventEmitter<void>();

    authorityService: AuthorityService<
        UiCraftAuthorityDto | UiOrganisationAuthorityDto,
        CreateCraftAuthorityCommand | CreateOrganisationAuthorityCommand,
        UpdateAuthorityCommand
    >;
    status: 'LOADING' | 'LOADED' | 'NO_AUTHORITIES' = 'LOADING';
    createdAuthorities: OrganisationAuthorityGroup[];
    visibleAuthorities: {
        [jurisdictionId: number]: {
            primary: OrganisationAuthorityGroup[];
            secondary: OrganisationAuthorityGroup[];
        };
    } = {};
    linkedAuthorities: {
        [authorityGroupId: number]: OrganisationAuthorityGroup[];
    };
    // The keys are the authority group (A) that is depended upon and the details are for the authority group (B) depending on group A.
    authoritiesDependedUpon: {
        [authorityGroupId: number]: {
            name: string;
            id: number;
        }[];
    } = {};
    availableAuthorities: OrganisationAuthorityGroup[];
    organisation: PersonsOrganisationDto;
    authorityOrganisation: AdminOrganisationDto;
    availableJurisdictions: SimpleAirspaceJurisdictionDto[];
    currentJurisdictionId: number;
    canUseWorkflow = true;
    globalJurisdiction = false;
    sharedAuthorities: SimpleAuthorityDto[];
    inUseJurisdictions: NameId[];

    showArchived: boolean;
    numberOfArchived = 0;
    today: Date = new Date();

    registerSummaries: RegisterExpirySummary[];

    showOrganisationAuthorities = true;
    showSharedAuthorities = true;
    hasVisibleSharedAuthorities = false;

    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    @ViewChild('jurisdictionPopover', { static: true })
    jurisdictionPopover: PopoverDirective;

    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private organisationAuthorityEditService: AuthorityGroupEditService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private authorityGroupAddService: AuthorityGroupAddService<
            OrganisationAuthorityGroup
        >,
        private logging: FlyFreelyLoggingService
    ) {
        this.currentJurisdictionId = null;
    }

    ngOnInit() {
        combineLatest([
            this.workTracker.observable.pipe(startWith(false)),
            this.organisationAuthorityEditService.working$.pipe(
                startWith(false)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([working, serviceWorking]) =>
                    (this.working = working || serviceWorking)
            );

        this.authorityGroupAddService.setup(this.managingOrganisationId);

        this.organisationAuthorityEditService.authorities$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorities => this.updateAuthorities(authorities));

        this.organisationAuthorityEditService.organisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => this.updateOrganisation(organisation));

        this.organisationAuthorityEditService.sharedAuthorities$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sharedAuthorities =>
                this.updateSharedAuthorities(sharedAuthorities)
            );

        combineLatest([
            this.organisationAuthorityEditService.registerExpiries$,
            this.organisationAuthorityEditService.registerExpiryLookups$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([registerExpiries, registerExpiryLookups]) => {
                this.registerSummaries = registerExpiries.map(
                    registerExpiry => {
                        const authority = this.createdAuthorities.find(
                            a =>
                                a.authorities?.find(
                                    auth =>
                                        auth?.registers.find(
                                            r => r.id === registerExpiry.id
                                        ) != null
                                ) != null
                        );
                        const authorityRegister = authority.authorities
                            ?.find(
                                a =>
                                    a.registers.find(
                                        r => r.id === registerExpiry.id
                                    ) != null
                            )
                            .registers.find(r => r.id === registerExpiry.id);
                        return {
                            authorityName: authority?.name ?? '',
                            authorityId: authority?.id ?? null,
                            registerName: registerExpiry.name,
                            registerId: registerExpiry.id,
                            registerMode: authorityRegister.registerMode,
                            overallStatus:
                                registerExpiryLookups[registerExpiry.id],
                            registerEntries: registerExpiry.registerEntries,
                            register: registerExpiry
                        };
                    }
                );
            });

        this.organisationAuthorityEditService.setup(
            this.organisationId,
            this.managingOrganisationId
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAuthorities() {
        if (!this.organisationId) {
            return;
        }
        this.organisationAuthorityEditService.refreshAllAuthorities();
    }

    updateOrganisation(organisation: PersonsOrganisationDto) {
        this.organisation = organisation;
        this.availableJurisdictions = [
            ...organisation.activeJurisdictions.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            })
        ];
        this.updateAddServiceAuthorities();
    }

    updateAuthorities(authorities: OrganisationAuthorityGroup[]) {
        this.status = authorities.some(
            a => a.authorities != null && a.authorities.length > 0
        )
            ? 'LOADED'
            : 'NO_AUTHORITIES';

        const primaryAuthorities = authorities
            .filter(a => a.isPrimary)
            .sort((a, b) => a.name.localeCompare(b.name));
        const nonPrimaryAuthorities = authorities
            .filter(a => !a.isPrimary)
            .sort((a, b) => a.name.localeCompare(b.name));

        const sortedAuthorities = primaryAuthorities.concat(
            nonPrimaryAuthorities
        );

        this.availableAuthorities = sortedAuthorities.filter(
            authority =>
                (authority.authorities.length === 0 ||
                    authority.hasMultipleInstances === true) &&
                authority.availableActions.canCreate &&
                !authority.isPrimary &&
                !isRequiredByPrimary(authority, primaryAuthorities) &&
                // FIXME: the dependentonauthority logic needs to be fixed once we have a good linking solution
                // !authority.dependentOnAuthorityType?.isPrimary
                (authority.dependentOnAuthorityType == null ||
                    !authority.hasMultipleInstances)
        );
        this.findLinkedAuthorities(sortedAuthorities);

        this.createdAuthorities = sortedAuthorities.filter(
            authority =>
                !this.hasNoAuthorities(authority) ||
                authority.isPrimary ||
                isRequiredByPrimary(authority, primaryAuthorities)
        );
        this.refreshNumberOfArchived();
        this.inUseJurisdictions = [
            ...this.createdAuthorities,
            ...this.availableAuthorities
        ].reduce(
            (acc, e) =>
                findNewJurisdictions(
                    acc,
                    e.jurisdiction,
                    GLOBAL_JURISDICTION_ID
                ),
            [] as NameId[]
        );
        this.updateVisibleAuthorities();
        this.findDependentAuthorities();
        this.updateAddServiceAuthorities();
    }

    updateVisibleAuthorities() {
        this.visibleAuthorities = {};
        this.inUseJurisdictions.forEach(j => {
            const visibleInJurisdiction = this.createdAuthorities
                .filter(a => this.isInJurisdiction(a, j.id))
                .filter(
                    a =>
                        a.availableActions.canCreate ||
                        a.authorities?.length > 0
                )
                .filter(
                    a =>
                        this.showArchived ||
                        !this.areAllExpiredOrArchived(a) ||
                        !this.isDiscontinued(a)
                );

            this.visibleAuthorities[j.id] = {
                primary: visibleInJurisdiction
                    .filter(a => a.isPrimary)
                    .reduce(
                        (acc: OrganisationAuthorityGroup[], g) =>
                            acc.concat(
                                reduceToGroupWithOneAuthority(
                                    g
                                ) as OrganisationAuthorityGroup[]
                            ),
                        []
                    )
                    .filter(
                        g =>
                            this.showArchived ||
                            (g.authorities != null &&
                            g.authorities.length > 0 &&
                            g.authorities[0] != null
                                ? !g.authorities[0].archived
                                : true)
                    ),
                secondary: visibleInJurisdiction
                    .filter(
                        a =>
                            !a.isPrimary &&
                            (a.dependentOnAuthorityType == null ||
                                a.authorities?.filter(
                                    auth =>
                                        auth.dependentOnAuthorityId == null ||
                                        !a.hasMultipleInstances
                                )?.length > 0)
                    )
                    .reduce(
                        (acc: OrganisationAuthorityGroup[], g) =>
                            acc.concat(
                                reduceToGroupWithOneAuthority(
                                    g
                                ) as OrganisationAuthorityGroup[]
                            ),
                        []
                    )
                    .filter(
                        g =>
                            this.showArchived ||
                            (g.authorities != null &&
                            g.authorities.length > 0 &&
                            g.authorities[0] != null
                                ? !g.authorities[0].archived
                                : true)
                    )
            };
        });
    }

    findLinkedAuthorities(sortedAuthorities: OrganisationAuthorityGroup[]) {
        this.linkedAuthorities = sortedAuthorities.reduce((acc, authority) => {
            return {
                ...acc,
                [authority.id]: sortedAuthorities.filter(
                    a =>
                        // FIXME: the below logic has temporary changes until the linking is fixed
                        a.dependentOnAuthorityType?.id === authority.id &&
                        (a.authorities == null || a.authorities.length == 0) &&
                        /* a.authorities.filter(
                                auth =>
                                    authority.authorities.find(
                                        a2 =>
                                            auth.dependentOnAuthorityId !=
                                                null &&
                                            auth.dependentOnAuthorityId ===
                                                a2.id
                                    ) != null
                            ).length > 0 || */
                        a.hasMultipleInstances
                )
            };
        }, {});
    }

    /**
     * Creates an indexed list of authority IDs as keys and a list of details of the authorities depending upon that key as the value.
     * e.g. {[authority1.id]: [{name: Authority requiring authority 1, id: 5}]}
     */
    findDependentAuthorities() {
        const authoritiesWithDependents = this.createdAuthorities.filter(
            a => a.dependentOnAuthorityType != null
        );
        this.authoritiesDependedUpon = authoritiesWithDependents.reduce(
            (acc, authority) => ({
                ...acc,
                [authority.dependentOnAuthorityType.id]: [
                    ...(acc[authority.dependentOnAuthorityType.id] ?? []),
                    {
                        name: authority.name,
                        id: authority.id
                    }
                ]
            }),
            {}
        );
    }

    /**
     * returns the authority that a specific authority depends upon
     */
    findDependentOnAuthorityGroups(authorityGroup: OrganisationAuthorityGroup) {
        if (
            this.createdAuthorities == null ||
            authorityGroup.dependentOnAuthorityType == null
        ) {
            return null;
        }
        return this.createdAuthorities.find(
            a => a.id === authorityGroup.dependentOnAuthorityType.id
        );
    }

    updateSharedAuthorities(authorities: SharedAuthorityGroup[]) {
        this.sharedAuthorities = authorities
            .map(group =>
                group.authorities.map(auth =>
                    toSimpleAuthority(auth, auth.organisation, group)
                )
            )
            .reduce((acc, auths) => [...acc, ...auths], []);
        this.hasVisibleSharedAuthorities =
            this.sharedAuthorities.filter(a => !a.archived).length > 0;
        this.refreshNumberOfArchived();
    }

    updateAddServiceAuthorities() {
        if (
            this.availableAuthorities == null ||
            this.availableJurisdictions == null
        ) {
            return;
        }
        // TODO: this should possibly read off of observables so it fires at the right time.
        this.authorityGroupAddService.updateAuthorities(
            this.availableAuthorities,
            this.availableJurisdictions
        );
    }

    refreshSharedAuthorities() {
        const today = moment().format('YYYY-MM-DD');
        this.organisationAuthorityService
            .findSharedAuthorities(this.organisationId, today)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorities => {
                this.sharedAuthorities = authorities
                    .map(group =>
                        group.authorities.map(auth =>
                            toSimpleAuthority(auth, auth.organisation, group)
                        )
                    )
                    .reduce((acc, auths) => [...acc, ...auths], []);
                this.hasVisibleSharedAuthorities =
                    this.sharedAuthorities.filter(a => !a.archived).length > 0;
                this.refreshNumberOfArchived();
            })
            .add(this.workTracker.createTracker());
    }

    refreshNumberOfArchived() {
        const numberOfShared =
            this.sharedAuthorities?.filter(a => a.archived).length ?? 0;
        const numberOfDiscontinued =
            this.createdAuthorities?.filter(
                a =>
                    !a.isPrimary &&
                    !isRequiredByPrimary(
                        a,
                        this.createdAuthorities.filter(g => g.isPrimary)
                    ) &&
                    this.isDiscontinued(a)
            ).length ?? 0;
        const archivedCreated =
            this.createdAuthorities?.filter(a =>
                this.areAllExpiredOrArchived(a)
            ).length ?? 0;
        this.numberOfArchived =
            numberOfShared + numberOfDiscontinued + archivedCreated;
    }

    onSetJurisdictionId(jurisdictionId: number) {
        this.currentJurisdictionId = jurisdictionId;
    }

    isVisibleAuthorityGroup(
        authority: OrganisationAuthorityGroup,
        jurisdictionId: number
    ) {
        return (
            this.isInSelectedJurisdiction(authority) &&
            this.isInJurisdiction(authority, jurisdictionId)
        );
    }

    isInSelectedJurisdiction(authority: OrganisationAuthorityGroup) {
        if (this.currentJurisdictionId == null) {
            return true;
        }
        if (this.currentJurisdictionId === GLOBAL_JURISDICTION_ID) {
            return authority.jurisdiction == null;
        }
        return (
            authority.jurisdiction != null &&
            authority.jurisdiction.id === this.currentJurisdictionId
        );
    }

    hasExpired(authority: SimpleAuthorityDto | OrganisationAuthorityDto) {
        return (
            authority.expiryDate != null &&
            moment(authority.expiryDate).isBefore(moment(this.today))
        );
    }

    hasNoAuthorities(authority: OrganisationAuthorityGroup) {
        return (
            authority.authorities == null || authority.authorities.length === 0
        );
    }

    isDiscontinued(authorityGroup: OrganisationAuthorityGroup) {
        return (
            authorityGroup.discontinueDate != null &&
            moment(authorityGroup.discontinueDate).isBefore(moment(this.today))
        );
    }

    areAllExpiredOrArchived(authorityGroup: OrganisationAuthorityGroup) {
        const primaryAuthorities = this.createdAuthorities.filter(
            auth => auth.isPrimary
        );
        let expired: OrganisationAuthorityDto[];

        // If this is a primary authority, then only return if it's archived
        // We always want to show a primary that has expired unless the user hides it.
        if (authorityGroup.isPrimary) {
            return authorityGroup.authorities != null
                ? authorityGroup.authorities.filter(a => a.archived).length ===
                      authorityGroup.authorities.length
                : false;
        }

        // Otherwise process expired or archived as normal.
        expired = authorityGroup.authorities.filter(
            a => this.hasExpired(a) || a.archived
        );

        // If this authority is required by any primary authorities, then only check for expired/archived if all those primaries are archived
        // If any of those primaries are visible, then we still want to show this authority regardless of its status.
        if (isRequiredByPrimary(authorityGroup, primaryAuthorities)) {
            const visibleRelatedPrimaries = primaryAuthorities
                .filter(
                    a =>
                        a.dependentOnAuthorityType != null &&
                        a.dependentOnAuthorityType.id === authorityGroup.id
                )
                .filter(
                    a =>
                        this.showArchived ||
                        !this.areAllExpiredOrArchived(a) ||
                        !this.isDiscontinued(a)
                );
            if (visibleRelatedPrimaries.length > 0) {
                return false;
            }
        }

        return expired.length === authorityGroup.authorities.length;
    }

    isInJurisdiction(
        authority: OrganisationAuthorityGroup,
        jurisdictionId: number
    ) {
        if (jurisdictionId === GLOBAL_JURISDICTION_ID) {
            return authority.jurisdiction == null;
        }
        return (
            authority.jurisdiction != null &&
            authority.jurisdiction.id === jurisdictionId
        );
    }

    onChangeShowArchived() {
        this.updateVisibleAuthorities();
    }

    scrollToAuthority(authority: OrganisationAuthorityGroup) {
        if (document.getElementById(`${authority.id}`) != null) {
            document
                .getElementById(`${authority.id}`)
                .scrollIntoView({ behavior: 'smooth' });
        } else {
            this.logging.error(
                null,
                'Something went wrong: Unable to scroll to this authority'
            );
        }
    }

    showAuthority(authorityType: AuthorityTypeDto, authority: any) {
        const modal = this.authorityDialoguesService.showAuthorityDetails(
            this.managingOrganisationId,
            authorityType,
            authority,
            this.icon,
            true,
            this.canUseWorkflow,
            this.registerSummaries
        );

        modal.content.onEditAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.editAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.onDeleteAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.deleteAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.onShowWorkflow
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.showWorkflow(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.onShowNominatedPersonnel
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.showNominatedPersonnel(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.onShowRegister
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.showRegister(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority,
                    payload.register
                );
            });
        modal.content.archiveAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.archiveAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.unarchiveAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.unarchiveAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
    }

    editAuthority(authorityType: AuthorityTypeDto, authority: any) {
        this.authorityDialoguesService.showAuthorityEdit(
            this.organisationId,
            authorityType,
            authority,
            this.managingOrganisationId,
            this.organisationAuthorityService
        );
    }

    showWorkflow(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        this.authorityDialoguesService.showAuthorityWorkflowDialogue(
            this.organisation.id,
            authorityType,
            authority,
            this.managingOrganisationId
        );
    }

    showRegister(
        authorityType: AuthorityTypeDto,
        authority: CraftAuthorityDto | OrganisationAuthorityDto,
        register: AuthorityRegisterSummaryDto
    ) {
        if (
            register.registerEntityType ===
            AuthorityRegisterSummaryDto.RegisterEntityType.RPA
        ) {
            this.authorityDialoguesService.showRpaRegisterDialogue(
                this.organisation.id,
                authorityType,
                <CraftAuthorityDto>authority,
                register,
                this.managingOrganisationId
            );
        } else {
            this.authorityDialoguesService.showPersonnelRegisterDialogue(
                this.organisation.id,
                authorityType,
                <OrganisationAuthorityDto>authority,
                register,
                this.managingOrganisationId
            );
        }
    }

    showNominatedPersonnel(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        this.authorityDialoguesService.showNominatedPersonnel(
            authorityType,
            authority
        );
    }

    createAuthority(
        authorityType: AuthorityTypeDto,
        dependentOnAuthority?: OrganisationAuthorityDto | CraftAuthorityDto
    ) {
        // FIXME: This is a temporary fix until we have a better way of handling this
        // The entire first IF statement can be removed once there's a better solution to linking on create
        const authorityGroup = this.availableAuthorities.find(
            auth => auth.id === authorityType.id
        );
        if (
            authorityType.dependentOnAuthorityType != null &&
            dependentOnAuthority == null &&
            (authorityGroup?.authorities == null ||
                authorityGroup?.authorities.length === 0)
        ) {
            const dependentOnAuthorityType = this.createdAuthorities.find(
                auth => auth.id === authorityType.dependentOnAuthorityType.id
            );
            if (dependentOnAuthorityType == null) {
                return this.commonDialoguesService
                    .showConfirmationDialogue(
                        'Required Authority missing',
                        `In order to add a ${authorityType.name} you must first create a ${authorityType.dependentOnAuthorityType.name}`,
                        'OK',
                        () => Promise.resolve()
                    )
                    .then(DO_NOTHING)
                    .catch(DO_NOTHING);
            }
            const modal = this.authorityDialoguesService.showAssociateAuthority(
                this.managingOrganisationId,
                dependentOnAuthorityType,
                null,
                authorityType.name,
                true
            );
            modal.content.selectedLinkedAuthority
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(selectedAuthority => {
                    this.authorityDialoguesService.showAuthorityEdit(
                        this.organisationId,
                        authorityType,
                        null,
                        this.managingOrganisationId,
                        this.organisationAuthorityService,
                        selectedAuthority
                    );
                });
        } else {
            this.authorityDialoguesService.showAuthorityEdit(
                this.organisationId,
                authorityType,
                null,
                this.managingOrganisationId,
                this.organisationAuthorityService,
                dependentOnAuthority
            );
        }
    }

    deleteAuthority(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Authority',
                `Do you wish to delete this ${authorityType.name}?`,
                'Delete',
                () =>
                    this.organisationAuthorityService.deleteAuthority(
                        authority.id,
                        this.managingOrganisationId
                    )
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    archiveAuthority(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Archive Authority',
                `Do you wish to archive this ${authorityType.name}?`,
                'Archive',
                () =>
                    this.organisationAuthorityService
                        .archiveAuthority(
                            authority.id,
                            this.managingOrganisationId
                        )
                        .toPromise()
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    unarchiveAuthority(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Unarchive Authority',
                `Do you wish to unarchive this ${authorityType.name}?`,
                'Unarchive',
                () =>
                    this.organisationAuthorityService
                        .unarchiveAuthority(
                            authority.id,
                            this.managingOrganisationId
                        )
                        .toPromise()
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    linkAuthority(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        const dependentOnAuthorityType = this.createdAuthorities.find(
            auth => auth.id === authorityType.dependentOnAuthorityType.id
        );
        this.authorityDialoguesService.showAssociateAuthority(
            this.managingOrganisationId,
            dependentOnAuthorityType,
            authority,
            authorityType.name
        );
    }

    jurisdictionsUpdated(organisation: OrganisationDto) {
        this.organisation.activeJurisdictions =
            organisation.activeJurisdictions;

        this.availableJurisdictions = [
            ...organisation.activeJurisdictions.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            })
        ];
        this.jurisdictionPopover.hide();
        this.refreshAuthorities();
    }

    updatedAuthority(updated: any) {
        this.refreshAuthorities();
    }

    showList() {
        this.done.emit(null);
    }
}
