import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    MaintenanceLogDto,
    PersonsOrganisationDto,
    ServiceabilitySignoffDto
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { MaintenanceDialogues } from 'libs/maintenance/src/lib/maintenance-dialogues.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
    MissionCompletionService,
    ServiceabilitySignoffForm
} from '../../mission-completion.service';

const statusValues = [
    { value: MaintenanceLogDto.Status.CANCELLED, name: 'Cancelled' },
    { value: MaintenanceLogDto.Status.COMPLETED, name: 'Completed' },
    { value: MaintenanceLogDto.Status.DRAFT, name: 'Draft' },
    { value: MaintenanceLogDto.Status.REQUESTED, name: 'Requested' }
];

const typeValues = [
    { value: MaintenanceLogDto.MaintenanceLogType.DEFECT, name: 'Defect' },
    { value: MaintenanceLogDto.MaintenanceLogType.SCHEDULED, name: 'Scheduled' }
];

@Component({
    selector: 'serviceability-maintenance-report',
    templateUrl: './serviceability-maintenance-report.component.html'
})
export class ServiceabilityMaintenanceReportComponent {
    @Input() serviceabilitySignoffs: FormControl<ServiceabilitySignoffDto[]>;
    @Input() maintenanceLogs: FormControl<MaintenanceLogDto[]>;
    @Input() organisation: PersonsOrganisationDto;
    @Input() form: FormGroup<ServiceabilitySignoffForm>;
    serviceLogs: MaintenanceLogDto[];
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private maintenanceDialoguesService: MaintenanceDialogues,
        private missionCompletionService: MissionCompletionService
    ) {
        this.availableColumns = [
            {
                value: 'status',
                name: 'Status',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: status => {
                    return statusValues.find(s => s.value === status).name;
                },
                linkFunction: (request: MaintenanceLogDto) =>
                    this.showMaintenanceRequest(request)
            },
            {
                value: 'maintenanceLogType',
                name: 'Type',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: type => {
                    return typeValues.find(s => s.value === type).name;
                },
                linkFunction: (request: MaintenanceLogDto) =>
                    this.showMaintenanceRequest(request)
            },
            {
                value: 'reasonForRequest',
                name: 'Details',
                searchable: false,
                selectable: false,
                defaultSelection: true
            }
        ];

        this.selectedColumns = null;

        this.tableConfig = {
            limit: null,
            actions: []
        };
    }

    ngOnInit() {
        this.refreshMaintenanceLogs();
        this.maintenanceLogs.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(() => this.refreshMaintenanceLogs());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshMaintenanceLogs() {
        this.serviceLogs = [];
        const maintenanceLogs = this.maintenanceLogs.value;
        const serviceabilitySignoffs = this.serviceabilitySignoffs.value;
        const valid =
            maintenanceLogs?.length > 0 && serviceabilitySignoffs?.length > 0;
        if (valid === true) {
            const value = this.form.value;
            const rpa = value.rpaId ?? value.unlistedRpaId;
            const ids = serviceabilitySignoffs.map(s => {
                if (s.rpaId === rpa || s.unlistedRpaId === rpa) {
                    return s.maintenanceLogId;
                }
            });
            maintenanceLogs.map(log => {
                if (
                    log.resourceId != null &&
                    log.resourceId === rpa &&
                    ids.findIndex(id => id === log.id) === -1
                ) {
                    this.serviceLogs.push(log);
                }
            });
        }
    }

    showMaintenanceRequest(request: MaintenanceLogDto) {
        this.maintenanceDialoguesService.showMaintenanceDetails(
            this.organisation.id,
            request.id
        );
    }

    submitMaintenance(request: MaintenanceLogDto) {
        this.missionCompletionService.submitMaintenanceRequest(request.id);
    }

    cancelMaintenance(request: MaintenanceLogDto) {
        this.missionCompletionService.cancelMaintenanceRequest(request.id);
    }
}
