import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    FullParamMapResolver,
    ModalContainer
} from '@flyfreely-portal-ui/ngx-bootstrap-customisation';
import { MissionDetailsV2Dialogue } from '../mission-details-v2/mission-details-v2-dialogue.component';
import { MissionsModule } from '../missions.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MissionsModule,
        RouterModule.forChild([
            {
                path: '',
                redirectTo: 'details',
                pathMatch: 'full'
            },
            {
                path: ':missionId',
                component: ModalContainer,
                data: {
                    modalContent: MissionDetailsV2Dialogue,
                    modalClass: 'modal-lg',
                    relativeRoute: '../../'
                },
                resolve: {
                    paramMap: FullParamMapResolver
                }
            }
            /* {
                path: '',
                component: ModalContainer,
                data: {
                    modalContent: MissionDetailsV2Dialogue,
                    modalClass: 'modal-lg'
                },
                resolve: {
                    paramMap: FullParamMapResolver
                }
            } */
        ])
    ],
    exports: [RouterModule]
})
export class MissionRoutingModule {}
