import { Injectable } from '@angular/core';
import {
    BatteryService,
    BatterySetService,
    CraftService,
    DashboardService,
    FeedbackService,
    FlyFreelyConstants,
    FlyFreelyLoggingService,
    HubspotService,
    MissionService,
    OnboardingService,
    PersonDto,
    PersonsOrganisationDto,
    PreferencesService,
    SetupGuideActivityDto,
    SetupGuideService,
    UpdateSetupGuideActivityCommand,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { getOrElse } from 'fp-ts/es6/Option';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { ActivityStreamDialoguesService } from 'libs/sidebar-activity-stream/src/lib/activity-stream-dialogues.service';
import * as moment from 'moment';
import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    combineLatest,
    forkJoin
} from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

export interface ActivityCardDto {
    activityId: number;
    type: SetupGuideActivityDto.Type;
    title: string;
    subtitle: string;
    stepNumber?: number;
    new: boolean;
    completed?: boolean;
    videoUrl?: string;
    videoThumbnail?: string;
    hasActivityLink?: boolean;
    stateValue?: any;
    task?: SetupGuideActivityDto.StepIdentifier;
}

interface SetupGuidePreferences {
    showDismissConfirmation: boolean;
    currentGuidedSetupLists: { list: SetupGuideActivityDto.Type }[];
    guidedSetupStatusValues: {
        stepIdentifier: SetupGuideActivityDto.StepIdentifier;
        value: any;
        completed: boolean;
    }[];
    dateStarted: string;
}

const feedbackIdentifier = 'SetupGuideChecklist';

export const checklistTypes = [
    { value: 'START_HERE', name: 'Dummy Mission Tutorial' },
    { value: 'GENERAL', name: 'General' },
    { value: 'REGISTERS', name: 'Registers' },
    { value: 'WORKFLOWS', name: 'Workflows' }
];

@Injectable({
    providedIn: 'root'
})
export class SetupGuideChecklistService {
    private checklistTypeSubject = new ReplaySubject<
        SetupGuideActivityDto.Type[]
    >();
    private numberOfNewSubject = new BehaviorSubject<number>(0);
    private scrollToSubject = new ReplaySubject<string>(1);
    private setupGuideCardsSubject = new ReplaySubject<ActivityCardDto[]>();
    private workingSubject = new BehaviorSubject<boolean>(false);
    private completeCardSubject = new ReplaySubject<
        SetupGuideActivityDto.StepIdentifier[]
    >();
    private activeVideoSubject = new ReplaySubject<{
        videoUrl: string;
        videoThumbnail: string;
        activitytitle: string;
        play: boolean;
        stepId: number;
        task: SetupGuideActivityDto.StepIdentifier;
    }>();
    private showConfirmationSubject = new ReplaySubject<boolean>();
    private changeOrganisationIdSubject = new Subject<number>();
    private collapsedChecklistsSubject = new BehaviorSubject<
        SetupGuideActivityDto.Type[]
    >(null);
    private shouldSetChecklistSubject = new BehaviorSubject<boolean>(false);
    private setChecklistSubject = new BehaviorSubject<boolean>(false);
    private enablePictureInPictureSource = new BehaviorSubject<boolean>(false);
    checklistType$ = this.checklistTypeSubject.asObservable();
    numberOfNew$ = this.numberOfNewSubject.asObservable();
    scrollTo$ = this.scrollToSubject.asObservable();
    setupGuideCards$ = this.setupGuideCardsSubject.asObservable();
    working$ = this.workingSubject.asObservable();
    completeCard$ = this.completeCardSubject.asObservable();
    activeVideo$ = this.activeVideoSubject.asObservable();
    showConfirmation$ = this.showConfirmationSubject.asObservable();
    changeOrganisationId$ = this.changeOrganisationIdSubject.asObservable();
    collapsedChecklists$ = this.collapsedChecklistsSubject.asObservable();
    enablePictureInPicture$ = this.enablePictureInPictureSource.asObservable();

    currentUser: PersonDto;

    cardShouldNotify = true;

    checklistValues: SetupGuideActivityDto[] = [];
    userPreferences: SetupGuidePreferences;
    stateValues: {
        identifier: SetupGuideActivityDto.StepIdentifier;
        stateValue: any;
    }[];
    currentLists: SetupGuideActivityDto.Type[] = [];

    activityTimelineChecklist: ActivityCardDto[];
    completedItems: SetupGuideActivityDto.StepIdentifier[] = [];

    organisation: PersonsOrganisationDto;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();

    working: boolean;
    runningActivity = false;

    IMG_URL: string;

    constructor(
        constants: FlyFreelyConstants,
        private activityStreamDialoguesService: ActivityStreamDialoguesService,
        private missionDialoguesService: MissionDialoguesService,
        private missionService: MissionService,
        private userService: UserService,
        private preferencesService: PreferencesService,
        private setupGuideService: SetupGuideService,
        private dashboardService: DashboardService,
        private logging: FlyFreelyLoggingService,
        private craftService: CraftService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private onboardingService: OnboardingService,
        private feedbackService: FeedbackService,
        private hubspotService: HubspotService,
        private angulartics2: Angulartics2
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                this.workingSubject.next(working);
            });
        this.currentUser = this.userService.getCurrentUser();
        this.IMG_URL = constants.IMG_URL;
        // Check that what's new has been closed and that the start here checklist hasn't started yet, then start it.
        combineLatest([
            this.shouldSetChecklistSubject.asObservable(),
            this.setChecklistSubject.asObservable()
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([shouldSet, set]) => {
                if (shouldSet && set) {
                    this.setChecklist(SetupGuideActivityDto.Type.START_HERE);
                }
            });
    }

    ngOnDestroy() {
        this.checklistTypeSubject.complete();
        this.numberOfNewSubject.complete();
        this.scrollToSubject.complete();
        this.setupGuideCardsSubject.complete();
        this.workingSubject.complete();
        this.completeCardSubject.complete();
        this.activeVideoSubject.complete();
        this.showConfirmationSubject.complete();
        this.changeOrganisationIdSubject.complete();
        this.collapsedChecklistsSubject.complete();
        this.shouldSetChecklistSubject.complete();
        this.setChecklistSubject.complete();
        this.enablePictureInPictureSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshCurrentList() {
        this.preferencesService
            .findPreferences(
                'setupGuide',
                this.currentUser.personalOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (preferences: SetupGuidePreferences) => {
                    if (preferences != null) {
                        this.userPreferences = preferences;
                        this.currentLists =
                            preferences.currentGuidedSetupLists != null
                                ? preferences.currentGuidedSetupLists.map(
                                      l => l.list
                                  )
                                : [];
                        if (preferences.dateStarted != null) {
                            const dateDiff = this.findDaysSinceStarted();
                            if (dateDiff >= 7) {
                                this.showFeedback('time');
                            }
                        }
                    } else {
                        this.userPreferences = {
                            currentGuidedSetupLists: null,
                            guidedSetupStatusValues: null,
                            showDismissConfirmation: true,
                            dateStarted: new Date().toISOString()
                        };
                        this.currentLists = [];
                        this.updateUserPreferences();
                    }

                    if (this.currentLists.length === 0) {
                        this.shouldSetChecklistSubject.next(true);
                    }
                    if (this.checklistValues == null) {
                        this.checklistValues = [];
                    }
                    this.showConfirmationSubject.next(
                        this.userPreferences.showDismissConfirmation
                    );
                    this.currentLists.forEach(type => this.refreshValues(type));
                    this.checklistTypeSubject.next(this.currentLists);
                },
                error: () => {
                    this.userPreferences = {
                        currentGuidedSetupLists: null,
                        guidedSetupStatusValues: null,
                        showDismissConfirmation: true,
                        dateStarted: new Date().toISOString()
                    };
                    this.currentLists = [];
                    this.shouldSetChecklistSubject.next(true);
                    this.showConfirmationSubject.next(
                        this.userPreferences.showDismissConfirmation
                    );
                    this.updateUserPreferences();
                }
            })
            .add(this.workTracker.createTracker());
    }

    refreshValues(type: SetupGuideActivityDto.Type) {
        this.setupGuideService
            .findActivities(type, this.currentUser.id)
            // findActivityChecklist(type)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((checklist: SetupGuideActivityDto[]) => {
                if (checklist == null) {
                    return;
                }
                const loaded =
                    this.checklistValues.findIndex(
                        c => c.stepIdentifier === checklist[0].stepIdentifier
                    ) !== -1;
                if (!loaded) {
                    checklist.forEach(list => this.checklistValues.push(list));
                }
                this.refreshActivityChecklist();
            })
            .add(this.workTracker.createTracker());
    }

    refreshActivityChecklist() {
        if (this.checklistValues != null) {
            this.activityTimelineChecklist = this.checklistValues.map(item => {
                const userData =
                    this.userPreferences.guidedSetupStatusValues != null
                        ? this.userPreferences.guidedSetupStatusValues.find(
                              u => u.stepIdentifier === item.stepIdentifier
                          )
                        : null;
                return this.buildChecklistData(item, userData);
            });
            this.activityTimelineChecklist.sort(
                (a, b) => a.stepNumber - b.stepNumber
            );
        } else {
            this.activityTimelineChecklist = [];
        }
        this.refreshCompletedItems();
        this.refreshChecklistPreferences();
        if (
            this.currentLists.includes(SetupGuideActivityDto.Type.START_HERE) &&
            this.activityTimelineChecklist.length > 0
        ) {
            const i = this.activityTimelineChecklist.findIndex(
                a => a.task === SetupGuideActivityDto.StepIdentifier.WELCOME
            );
            if (
                i !== -1 &&
                !this.completedItems.includes(
                    SetupGuideActivityDto.StepIdentifier.WELCOME
                )
            ) {
                this.showWelcome(i);
            } else {
                this.numberOfNewSubject.next(
                    this.activityTimelineChecklist.filter(a => a.new === true)
                        .length
                );
                this.setupGuideCardsSubject.next(
                    this.activityTimelineChecklist
                );
                return;
            }
        } else {
            this.numberOfNewSubject.next(
                this.activityTimelineChecklist.filter(a => a.new === true)
                    .length
            );
            this.setupGuideCardsSubject.next(this.activityTimelineChecklist);
        }
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'setupGuide',
                this.currentUser.personalOrganisationId,
                this.userPreferences
            )
            .subscribe();
    }

    findDaysSinceStarted() {
        const currentDate = moment();
        const startedDate = moment(this.userPreferences.dateStarted);
        return currentDate.diff(startedDate, 'days');
    }

    readyToShowStartHere() {
        this.setChecklistSubject.next(true);
    }

    setChecklist(type: SetupGuideActivityDto.Type) {
        if (
            this.currentLists.includes(type) === false &&
            type === SetupGuideActivityDto.Type.START_HERE
        ) {
            // log the date the checklist was started after "Start Here" was first clicked.
            if (this.userPreferences.dateStarted == null) {
                this.userPreferences.dateStarted = new Date().toISOString();
                this.updateUserPreferences();
            }
            if (
                this.userPreferences.currentGuidedSetupLists == null ||
                this.userPreferences.currentGuidedSetupLists.findIndex(
                    l => l.list === type
                ) === -1
            ) {
                if (this.userPreferences.currentGuidedSetupLists == null) {
                    this.userPreferences.currentGuidedSetupLists = [
                        { list: type }
                    ];
                } else {
                    this.userPreferences.currentGuidedSetupLists.push({
                        list: type
                    });
                }
                this.updateUserPreferences();
            }
            if (this.currentLists == null) {
                this.currentLists = [type];
            } else {
                this.currentLists.push(type);
            }
            this.refreshValues(type);
            this.checklistTypeSubject.next(this.currentLists);
            this.checkDummyData();
        }
    }

    setOrganisation(organisation: PersonsOrganisationDto) {
        this.organisation = organisation;
    }

    checkDummyData() {
        // Check if there are dummy RPA set up for this org.
        this.craftService
            .findCrafts(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(craft => {
                if (craft != null) {
                    const dummyCraft = craft.filter(c => c.isDummy === true);
                    if (dummyCraft.length > 0) {
                        return;
                    }
                }
                // If there's no dummy data for this org, create it
                // Dummy data is needed for the checklist and should have been created during onboarding.
                this.createDummyData();
            })
            .add(this.workTracker.createTracker());
    }

    createDummyData() {
        this.onboardingService
            .createDummyEquipment(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                // Call the change observable on the affected services to refresh widgets and other dependant components.
                this.craftService.onCraftChanged();
                this.batteryService.onBatteryChange();
                this.batterySetService.onBatterySetChange();
            })
            .add(this.workTracker.createTracker());
    }

    buildChecklistData(apiData: SetupGuideActivityDto, userData: any) {
        let thumbnailUrl = apiData.videoThumbnail;
        // check if the new thumbnails are pointing to a static url, else replace with the placeholder.
        if (
            thumbnailUrl.slice(
                thumbnailUrl.lastIndexOf('/') + 1,
                thumbnailUrl.length
            ) === 'thumb.jpg'
        ) {
            thumbnailUrl = `${this.IMG_URL}/onboarding/checklist-assets/thumb.jpg`;
        }
        const item: ActivityCardDto = {
            activityId: apiData.id,
            new: apiData.status === SetupGuideActivityDto.Status.NEW,
            completed: apiData.status === SetupGuideActivityDto.Status.COMPLETE,
            title: apiData.title,
            subtitle: apiData.subtitle,
            type: apiData.type,
            task: apiData.stepIdentifier ?? null,
            videoUrl: apiData.videoUrl ?? null,
            videoThumbnail: thumbnailUrl,
            stateValue:
                userData != null && userData.value !== ''
                    ? userData.value ?? null
                    : null,
            hasActivityLink: this.findHasLink(apiData.stepIdentifier) ?? null,
            stepNumber: (apiData.position ?? 1) - 1
        };
        return item;
    }

    findHasLink(identifier: SetupGuideActivityDto.StepIdentifier) {
        if (identifier == null) {
            return false;
        }
        const task = SetupGuideActivityDto.StepIdentifier;
        if (
            identifier === task.CHECK_ORG ||
            identifier === task.CREATE_LOCATION ||
            identifier === task.CREATE_MISSION ||
            identifier === task.FIELD_APP ||
            identifier === task.SUBMIT_MISSION ||
            identifier === task.COMPLETE_MISSION
        ) {
            return true;
        }
        return false;
    }

    checkIfOriginalOrg() {
        if (
            this.userPreferences == null ||
            this.userPreferences.guidedSetupStatusValues == null
        ) {
            return true;
        }
        const i = this.userPreferences.guidedSetupStatusValues.findIndex(
            l =>
                l.stepIdentifier ===
                SetupGuideActivityDto.StepIdentifier.CHECK_ORG
        );
        if (i === -1) {
            return true;
        }
        return (
            this.organisation.id ===
            this.userPreferences.guidedSetupStatusValues[i].value
        );
    }

    refreshChecklistPreferences() {
        // This updates the collapse and video picture in picture preferences.
        forkJoin([
            this.preferencesService
                .findPreferencesAsOption('checklists', null)
                .pipe(
                    map(
                        p =>
                            getOrElse(() => ({ collapsedChecklists: [] }))(p)
                                .collapsedChecklists
                    )
                ),
            this.preferencesService
                .findPreferencesAsOption('pictureInPicture', null)
                .pipe(
                    map(
                        p =>
                            getOrElse(() => ({
                                enablePictureInPicture: false
                            }))(p).enablePictureInPicture
                    )
                )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([collapsedChecklists, enablePictureInPicture]) => {
                this.collapsedChecklistsSubject.next(collapsedChecklists);
                this.enablePictureInPictureSource.next(enablePictureInPicture);
            });
    }

    updateCollapsedChecklists(
        collapsedChecklists: SetupGuideActivityDto.Type[]
    ) {
        this.collapsedChecklistsSubject.next(collapsedChecklists);
        this.preferencesService
            .updatePreferences('checklists', null, { collapsedChecklists })
            .pipe(take(1))
            .subscribe();
    }

    updatePictureInPicturePreferences(enablePictureInPicture: boolean) {
        this.enablePictureInPictureSource.next(enablePictureInPicture);
        this.preferencesService
            .updatePreferences('pictureInPicture', null, {
                enablePictureInPicture
            })
            .pipe(take(1))
            .subscribe();
    }

    refreshCompletedItems() {
        if (this.completedItems == null) {
            this.completedItems = [];
        }
        if (this.userPreferences.guidedSetupStatusValues != null) {
            this.userPreferences.guidedSetupStatusValues.forEach(item => {
                if (
                    item.completed === true &&
                    this.completedItems.includes(item.stepIdentifier) === false
                ) {
                    this.completedItems.push(item.stepIdentifier);
                }
            });
            this.completeCardSubject.next(this.completedItems);
        }
    }

    showWelcome(i: number) {
        const welcomeVideo = this.activityStreamDialoguesService.showVideo(
            this.activityTimelineChecklist[i].videoUrl,
            this.activityTimelineChecklist[i].videoThumbnail,
            this.activityTimelineChecklist[i].title,
            this.activityTimelineChecklist[i].activityId,
            this.activityTimelineChecklist[i].task
        );
        welcomeVideo.onHide
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                takeUntil(welcomeVideo.onHidden)
            )
            .subscribe(() => {
                // Open the notification bar as the checklist is loaded for the first time
                this.dashboardService.showNotificationBar();
                this.numberOfNewSubject.next(
                    this.activityTimelineChecklist.filter(a => a.new === true)
                        .length
                );
                this.setupGuideCardsSubject.next(
                    this.activityTimelineChecklist
                );

                this.markActivityViewed(this.activityTimelineChecklist[i]);
                this.markActivityDone(this.activityTimelineChecklist[i]);
            });
    }

    showVideo(
        videoUrl: string,
        videoThumbnail: string,
        activityTitle: string,
        stepId: number,
        task: SetupGuideActivityDto.StepIdentifier
    ) {
        const videoCommand = {
            videoUrl: videoUrl,
            videoThumbnail: videoThumbnail,
            activitytitle: activityTitle,
            play: true,
            stepId: stepId,
            task: task
        };
        this.hubspotService.hideHubspotConversations();
        this.activeVideoSubject.next(videoCommand);
    }

    hideVideo() {
        const videoCommand: any = {
            videoUrl: null,
            videoThumbnail: null,
            activitytitle: null,
            play: false,
            stepId: null,
            task: null
        };
        this.hubspotService.showHubspotConversations();
        this.activeVideoSubject.next(videoCommand);
    }

    canCardAlert() {
        const alert = this.cardShouldNotify;
        this.cardShouldNotify = false;
        return alert;
    }

    getActivityTask(activity: ActivityCardDto) {
        const doneWorking = this.workTracker.createTracker();
        const task = SetupGuideActivityDto.StepIdentifier;
        if (this.userPreferences.guidedSetupStatusValues == null) {
            this.userPreferences.guidedSetupStatusValues = [];
        }
        switch (activity.task) {
            case task.CHECK_ORG: {
                const i =
                    this.userPreferences.guidedSetupStatusValues.findIndex(
                        v =>
                            v.stepIdentifier ===
                            SetupGuideActivityDto.StepIdentifier.CHECK_ORG
                    );
                if (i === -1) {
                    return;
                }
                doneWorking();
                return this.changeOrganisationIdSubject.next(
                    this.userPreferences.guidedSetupStatusValues[i].value
                );
            }

            case task.CREATE_LOCATION:
                doneWorking();
                return this.scrollToSubject.next('locations');

            case task.CREATE_MISSION:
                if (activity.stateValue == null) {
                    doneWorking();
                    return this.scrollToSubject.next('missionList');
                } else {
                    this.runningActivity = true;
                    return this.missionService
                        .findMission(activity.stateValue)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe(mission => {
                            const diag =
                                this.missionDialoguesService.showMissionDetails(
                                    mission.id,
                                    true,
                                    this.organisation
                                );
                            diag.onHide
                                .pipe(takeUntil(this.ngUnsubscribe$))
                                .subscribe(() => {
                                    this.runningActivity = false;
                                    doneWorking();
                                });
                        });
                }

            case task.SUBMIT_MISSION:
                if (
                    this.organisation == null ||
                    this.runningActivity === true
                ) {
                    doneWorking();
                    return;
                }
                // runningActivity helps prevent double-clicking duplicating actions.
                // This guards in case all the processes and observables cause working to kick in late.
                this.runningActivity = true;
                return this.missionService
                    .findMission(activity.stateValue)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(mission => {
                        const diag =
                            this.missionDialoguesService.showMissionEditor(
                                mission,
                                this.organisation
                            );
                        diag.onHide
                            .pipe(takeUntil(this.ngUnsubscribe$))
                            .subscribe(() => {
                                doneWorking();
                                this.runningActivity = false;
                            });
                    });

            case task.FIELD_APP:
                if (
                    this.organisation == null ||
                    this.runningActivity === true
                ) {
                    doneWorking();
                    return;
                }
                doneWorking();
                return this.missionDialoguesService.showFieldAppHandoff(
                    new Date(),
                    this.organisation.id,
                    true
                );

            case task.COMPLETE_MISSION:
                if (
                    this.organisation == null ||
                    this.runningActivity === true
                ) {
                    doneWorking();
                    return;
                }
                this.runningActivity = true;
                return this.missionService
                    .findMission(activity.stateValue)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(mission => {
                        if (mission == null) {
                            this.runningActivity = false;
                            doneWorking();
                            return;
                        }
                        const diag =
                            this.missionDialoguesService.showMissionDetails(
                                mission.id,
                                true,
                                this.organisation
                            );
                        diag.onHide
                            .pipe(takeUntil(this.ngUnsubscribe$))
                            .subscribe(() => {
                                this.runningActivity = false;
                                doneWorking();
                                return;
                            });
                    });

            default:
                doneWorking();
                return;
        }
    }

    completeActivityTask(
        task: SetupGuideActivityDto.StepIdentifier,
        persistenceValue?: string | number
    ) {
        if (this.activityTimelineChecklist == null) {
            return;
        }
        let i;
        if (task !== SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION) {
            if (
                this.activityTimelineChecklist.length === 0 ||
                // check if activity has been completed.
                this.activityTimelineChecklist.find(a => a.task === task) ==
                    null ||
                this.activityTimelineChecklist.find(a => a.task === task)
                    .completed === true
            ) {
                return;
            }

            i = this.activityTimelineChecklist.findIndex(a => a.task === task);
        }
        if (this.userPreferences.guidedSetupStatusValues == null) {
            this.userPreferences.guidedSetupStatusValues = [];
        }
        let index = this.userPreferences.guidedSetupStatusValues.findIndex(
            p => p.stepIdentifier === task
        );
        if (
            index !== -1 &&
            this.userPreferences.guidedSetupStatusValues[index] != null
        ) {
            this.userPreferences.guidedSetupStatusValues[index] = {
                ...this.userPreferences.guidedSetupStatusValues[index],
                value:
                    this.userPreferences.guidedSetupStatusValues[index].value ==
                    null
                        ? persistenceValue
                        : this.userPreferences.guidedSetupStatusValues[index]
                              .value,
                completed:
                    task ===
                        SetupGuideActivityDto.StepIdentifier.CREATE_MISSION &&
                    !this.completedItems.includes(
                        SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
                    )
                        ? false
                        : true
            };
        } else {
            this.userPreferences.guidedSetupStatusValues.push({
                stepIdentifier: task,
                value: persistenceValue,
                completed:
                    task ===
                        SetupGuideActivityDto.StepIdentifier.CREATE_MISSION &&
                    !this.completedItems.includes(
                        SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
                    )
                        ? false
                        : true
            });
            index = this.userPreferences.guidedSetupStatusValues.findIndex(
                p => p.stepIdentifier === task
            );
        }
        persistenceValue =
            this.userPreferences.guidedSetupStatusValues[index]?.value ?? null;
        if (task !== SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION) {
            this.activityTimelineChecklist[i].stateValue = persistenceValue;
        }
        if (
            task === SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION ||
            (i != null &&
                i !== -1 &&
                this.activityTimelineChecklist[i].completed === false)
        ) {
            if (
                task === SetupGuideActivityDto.StepIdentifier.CHECK_ORG &&
                persistenceValue !== this.organisation.id &&
                persistenceValue !== null
            ) {
                return;
            }
            if (task === SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION) {
                if (
                    this.activityTimelineChecklist.find(
                        item =>
                            item.task ===
                            SetupGuideActivityDto.StepIdentifier.CREATE_MISSION
                    ).completed
                ) {
                    return;
                }
                this.userPreferences.guidedSetupStatusValues.find(
                    v =>
                        v.stepIdentifier ===
                        SetupGuideActivityDto.StepIdentifier.CREATE_MISSION
                ).completed = true;

                const completeIndex = this.activityTimelineChecklist.findIndex(
                    a =>
                        a.task ===
                        SetupGuideActivityDto.StepIdentifier.COMPLETE_MISSION
                );

                const userCompleteIndex =
                    this.userPreferences.guidedSetupStatusValues.findIndex(
                        p =>
                            p.stepIdentifier ===
                            SetupGuideActivityDto.StepIdentifier
                                .COMPLETE_MISSION
                    );

                if (
                    completeIndex != null &&
                    this.activityTimelineChecklist[completeIndex] != null
                ) {
                    this.activityTimelineChecklist[completeIndex].stateValue =
                        persistenceValue;
                }

                if (userCompleteIndex !== -1) {
                    this.userPreferences.guidedSetupStatusValues[
                        userCompleteIndex
                    ] = {
                        ...this.userPreferences[userCompleteIndex],
                        value: persistenceValue
                    };
                } else {
                    this.userPreferences.guidedSetupStatusValues.push({
                        stepIdentifier:
                            SetupGuideActivityDto.StepIdentifier
                                .COMPLETE_MISSION,
                        value: persistenceValue,
                        completed: false
                    });
                }
            }
            if (
                persistenceValue != null &&
                task === SetupGuideActivityDto.StepIdentifier.CREATE_MISSION &&
                this.userPreferences.guidedSetupStatusValues[index] != null &&
                this.userPreferences.guidedSetupStatusValues[index].value ===
                    persistenceValue
            ) {
                const userSubmitIndex =
                    this.userPreferences.guidedSetupStatusValues.findIndex(
                        p =>
                            p.stepIdentifier ===
                            SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
                    );
                if (userSubmitIndex !== -1) {
                    this.userPreferences.guidedSetupStatusValues[
                        userSubmitIndex
                    ] = {
                        ...this.userPreferences[userSubmitIndex],
                        value: persistenceValue
                    };
                } else {
                    this.userPreferences.guidedSetupStatusValues.push({
                        stepIdentifier:
                            SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION,
                        value: persistenceValue,
                        completed: false
                    });
                }

                const fieldAppIndex = this.activityTimelineChecklist.findIndex(
                    a =>
                        a.task ===
                        SetupGuideActivityDto.StepIdentifier.FIELD_APP
                );
                const userFieldAppIndex =
                    this.userPreferences.guidedSetupStatusValues.findIndex(
                        p =>
                            p.stepIdentifier ===
                            SetupGuideActivityDto.StepIdentifier.FIELD_APP
                    );
                if (
                    fieldAppIndex != null &&
                    this.activityTimelineChecklist[fieldAppIndex] != null
                ) {
                    this.activityTimelineChecklist[fieldAppIndex].stateValue =
                        persistenceValue;
                }
                if (userFieldAppIndex !== -1) {
                    this.userPreferences.guidedSetupStatusValues[
                        userFieldAppIndex
                    ] = {
                        ...this.userPreferences[userFieldAppIndex],
                        value: persistenceValue
                    };
                } else {
                    this.userPreferences.guidedSetupStatusValues.push({
                        stepIdentifier:
                            SetupGuideActivityDto.StepIdentifier.FIELD_APP,
                        value: persistenceValue,
                        completed: false
                    });
                }
            }

            this.updateUserPreferences();

            if (this.completedItems.includes(task) === false) {
                this.completedItems.push(task);
                if (
                    task !== SetupGuideActivityDto.StepIdentifier.CREATE_MISSION
                ) {
                    this.logging.success(
                        `Completed checklist item: "${
                            task ===
                            SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
                                ? 'Plan Mission'
                                : this.activityTimelineChecklist[i].title
                        }"`
                    );
                }
            }
            this.completeCardSubject.next(this.completedItems);
        }
    }

    markActivityViewed(activity: ActivityCardDto) {
        if (
            this.checklistValues.find(item => item.id === activity.activityId)
                .status === SetupGuideActivityDto.Status.VIEWED
        ) {
            return;
        }
        const command: UpdateSetupGuideActivityCommand = {
            activityId: activity.activityId,
            personId: this.currentUser.id,
            status: SetupGuideActivityDto.Status.VIEWED
        };
        this.setupGuideService
            .updateActivity(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                const i = this.checklistValues.findIndex(
                    item => item.id === result.id
                );
                this.checklistValues[i] = result;
                // Below is done instead of refreshing the list via the function above to preserve card collapse states.
                const index = this.activityTimelineChecklist.findIndex(
                    a => a.task === activity.task
                );
                this.activityTimelineChecklist[index].new =
                    result.status === SetupGuideActivityDto.Status.NEW;
                this.numberOfNewSubject.next(
                    this.activityTimelineChecklist.filter(a => a.new === true)
                        .length
                );
                this.setupGuideCardsSubject.next(
                    this.activityTimelineChecklist
                );
            })
            .add(this.workTracker.createTracker());
    }

    markActivityDone(activity: ActivityCardDto) {
        if (this.userPreferences.guidedSetupStatusValues == null) {
            this.userPreferences.guidedSetupStatusValues = [];
        }
        const index = this.userPreferences.guidedSetupStatusValues.findIndex(
            v => v.stepIdentifier === activity.task
        );
        if (index === -1) {
            this.userPreferences.guidedSetupStatusValues.push({
                completed: true,
                stepIdentifier: activity.task,
                value:
                    activity.task ===
                    SetupGuideActivityDto.StepIdentifier.CHECK_ORG
                        ? this.organisation.id
                        : null
            });
        } else {
            this.userPreferences.guidedSetupStatusValues[index].completed =
                true;
        }
        if (!this.completedItems.includes(activity.task)) {
            this.completedItems.push(activity.task);
        }
        if (
            activity.task ===
            SetupGuideActivityDto.StepIdentifier.CREATE_MISSION
        ) {
            const i = this.userPreferences.guidedSetupStatusValues.findIndex(
                v =>
                    v.stepIdentifier ===
                    SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
            );
            if (i !== -1) {
                this.userPreferences.guidedSetupStatusValues[i].completed =
                    true;
            } else {
                this.userPreferences.guidedSetupStatusValues.push({
                    completed: true,
                    stepIdentifier:
                        SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION,
                    value: null
                });
            }
            if (
                !this.completedItems.includes(
                    SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
                )
            ) {
                this.completedItems.push(
                    SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION
                );
            }
        }
        this.updateUserPreferences();
        this.completeCardSubject.next(this.completedItems);
        // If the checklist is completed (i.e. the archive button appears), show the feedback window
        if (
            this.activityTimelineChecklist.reduce(
                (acc, a) => acc && this.completedItems.includes(a.task),
                true
            )
        ) {
            this.showFeedback('completed');
        }
    }

    archiveGroup(type: SetupGuideActivityDto.Type) {
        // archive all cards for the given type via the API.
        const activities = this.activityTimelineChecklist.filter(
            a => a.type === type
        );
        activities.forEach(activity => {
            if (
                this.checklistValues.find(
                    item => item.id === activity.activityId
                ).status === SetupGuideActivityDto.Status.COMPLETE
            ) {
                return;
            }
            const command: UpdateSetupGuideActivityCommand = {
                activityId: activity.activityId,
                personId: this.currentUser.id,
                status: SetupGuideActivityDto.Status.COMPLETE
            };
            this.setupGuideService
                .updateActivity(command)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(result => {
                    const i = this.checklistValues.findIndex(
                        item => item.id === result.id
                    );
                    this.checklistValues[i] = result;
                    // Below is done instead of refreshing the list via the function above to preserve card collapse states.
                    const index = this.activityTimelineChecklist.findIndex(
                        a => a.task === activity.task
                    );
                    this.activityTimelineChecklist[index].new =
                        result.status === SetupGuideActivityDto.Status.NEW;
                    this.activityTimelineChecklist[index].completed =
                        result.status === SetupGuideActivityDto.Status.COMPLETE;
                    this.setupGuideCardsSubject.next(
                        this.activityTimelineChecklist
                    );
                })
                .add(this.workTracker.createTracker());
        });
    }

    unarchiveGroup(type: SetupGuideActivityDto.Type) {
        const activities = this.activityTimelineChecklist.filter(
            a => a.type === type
        );
        activities.forEach(activity => {
            if (
                this.checklistValues.find(
                    item => item.id === activity.activityId
                ).status !== SetupGuideActivityDto.Status.COMPLETE
            ) {
                return;
            }
            const command: UpdateSetupGuideActivityCommand = {
                activityId: activity.activityId,
                personId: this.currentUser.id,
                status: SetupGuideActivityDto.Status.VIEWED
            };
            this.setupGuideService
                .updateActivity(command)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(result => {
                    const i = this.checklistValues.findIndex(
                        item => item.id === result.id
                    );
                    this.checklistValues[i] = result;
                    // Below is done instead of refreshing the list via the function above to preserve card collapse states.
                    const index = this.activityTimelineChecklist.findIndex(
                        a => a.task === activity.task
                    );
                    this.activityTimelineChecklist[index].new =
                        result.status === SetupGuideActivityDto.Status.NEW;
                    this.activityTimelineChecklist[index].completed =
                        result.status === SetupGuideActivityDto.Status.COMPLETE;
                    this.setupGuideCardsSubject.next(
                        this.activityTimelineChecklist
                    );
                })
                .add(this.workTracker.createTracker());
        });
    }

    dontShowConfirmationAgain(value: boolean) {
        this.showConfirmationSubject.next(!value);
        this.userPreferences.showDismissConfirmation = !value;
        this.updateUserPreferences();
    }

    showFeedback(reason: string) {
        this.feedbackService.showOnce(
            feedbackIdentifier,
            this.currentUser.personalOrganisationId
        );
        // If the start here flow has been completed, send the event to the analytics
        if (
            reason === 'completed' &&
            this.activityTimelineChecklist
                .filter(a => a.type === SetupGuideActivityDto.Type.START_HERE)
                .reduce(
                    (acc, a) =>
                        acc &&
                        this.completedItems.includes(a.task) &&
                        // the completed field indicates the list is already archived
                        !a.completed,
                    true
                )
        ) {
            this.angulartics2.eventTrack.next({
                action: 'flow-finished',
                properties: {
                    category: 'setup-guide',
                    label: SetupGuideActivityDto.Type.START_HERE
                }
            });
        }
    }
}
