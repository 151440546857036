import {
    LocationDetailsDto,
    LocationFeatureDto
} from '@flyfreely-portal-ui/flyfreely';
import { chain, chainNullableK, fromNullable } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { GeometryCollection } from 'geojson';
import { FeatureGroup, MapFeature } from 'libs/map/src/lib/interfaces';

/**
 * Converts a Location from the backend into a MapFeature for use with the FlyFreely Map.
 * @param location FlyFreely location
 */
export function locationToFeature(
    location: Partial<LocationDetailsDto>
): MapFeature {
    if (location.features == null || location.features.length === 0) {
        return null;
    }
    const feature = location.features.find(
        f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
    );
    if (feature == null) {
        return null;
    }

    return {
        id: location.id ?? 0,
        name: location.name,
        geom: <GeoJSON.Polygon>feature.geometry
    };
}

export function toMapFeature(
    feature: LocationFeatureDto,
    id: number
): MapFeature {
    return {
        id,
        name: feature.name,
        geom: <GeoJSON.Polygon>feature.geometry,
        categoryId: feature.type,
        properties: feature.properties
    };
}

export function groupByMapFeature(
    locationFeatures: LocationFeatureDto[],
    nextFeatureId: number
) {
    return locationFeatures.reduce(
        (acc, f, ix) => {
            if (
                f.type === LocationFeatureDto.Type.FLIGHT_AREA ||
                f.type === LocationFeatureDto.Type.DANGER ||
                f.type === LocationFeatureDto.Type.NO_FLY ||
                f.type === LocationFeatureDto.Type.AREA_OF_INTEREST
            ) {
                return {
                    ...acc,
                    areas: [...acc.areas, toMapFeature(f, ix + nextFeatureId)]
                };
            }
            if (
                f.type === LocationFeatureDto.Type.RP ||
                f.type === LocationFeatureDto.Type.OBSERVER ||
                f.type === LocationFeatureDto.Type.TAKEOFF ||
                f.type === LocationFeatureDto.Type.LANDING ||
                f.type === LocationFeatureDto.Type.TAKEOFF_LANDING ||
                f.type === LocationFeatureDto.Type.POINT_OF_INTEREST
            ) {
                return {
                    ...acc,
                    markers: [
                        ...acc.markers,
                        toMapFeature(f, ix + nextFeatureId)
                    ]
                };
            }

            if (
                f.type === LocationFeatureDto.Type.FLIGHT_PATH_LINE ||
                f.type === LocationFeatureDto.Type.GENERAL_LINE
            ) {
                return {
                    ...acc,
                    lines: [...acc.lines, toMapFeature(f, ix + nextFeatureId)]
                };
            }

            if (
                f.type === LocationFeatureDto.Type.OFFSET_DANGER_AREA ||
                f.type === LocationFeatureDto.Type.OFFSET_FLIGHT_AREA ||
                f.type === LocationFeatureDto.Type.OFFSET_NO_FLY_AREA
            ) {
                return {
                    ...acc,
                    corridors: [
                        ...acc.corridors,
                        toMapFeature(f, ix + nextFeatureId)
                    ]
                };
            }
            return acc;
        },
        {
            areas: [] as MapFeature[],
            markers: [] as MapFeature[],
            lines: [] as MapFeature[],
            corridors: [] as MapFeature[]
        }
    );
}

// TODO: implement other mapFeatures  (not only flight area)
export function getFeatureGroups(
    locationFeatures: LocationFeatureDto[],
    nextFeatureId: number,
    nextGroupId = 0
): { nextId: number; features: FeatureGroup[] } {
    const groups = groupByMapFeature(locationFeatures, nextFeatureId);
    return {
        nextId: locationFeatures.length + nextFeatureId,
        features: [
            {
                id: nextGroupId + 0,
                name: 'Areas',
                type: 'Polygon',
                canAdd: true,
                minimumNumber: 0,
                maximumNumber: null,
                categories: [
                    {
                        id: LocationFeatureDto.Type.FLIGHT_AREA,
                        name: 'Flight Area'
                    },
                    {
                        id: LocationFeatureDto.Type.NO_FLY,
                        name: 'No Fly Area'
                    },
                    {
                        id: LocationFeatureDto.Type.DANGER,
                        name: 'Danger'
                    },
                    {
                        id: LocationFeatureDto.Type.AREA_OF_INTEREST,
                        name: 'Area of Interest'
                    }
                ],
                existingFeatures: groups.areas,
                styles: {
                    fill: [
                        {
                            paint: {
                                'fill-color': '#ff1234'
                            },
                            filter: ['!', ['has', 'category-id']]
                        },
                        {
                            paint: {
                                'fill-color': '#990000',
                                'fill-opacity': 0.4
                            },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.FLIGHT_AREA
                            ]
                        },
                        {
                            paint: {
                                'fill-pattern': 'no-fly',
                                'fill-opacity': 0.3
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.DANGER
                            ]
                        },
                        {
                            paint: {
                                'fill-pattern': 'danger-area',
                                'fill-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.NO_FLY
                            ]
                        },
                        {
                            paint: {
                                'fill-color': '#999999',
                                'fill-opacity': 0.4
                            },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.AREA_OF_INTEREST
                            ]
                        }
                    ],
                    line: [
                        {
                            paint: {
                                'line-color': '#990000',
                                'line-width': 3,
                                'line-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.FLIGHT_AREA
                            ]
                        },
                        {
                            paint: {
                                'line-color': '#fecb1c',
                                'line-width': 3,
                                'line-opacity': 0.5
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.DANGER
                            ]
                        },
                        {
                            paint: {
                                'line-width': 3,
                                'line-opacity': 0.5
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.NO_FLY
                            ]
                        },
                        {
                            paint: {
                                'line-color': '#AAAAAA',
                                'line-width': 3,
                                'line-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.AREA_OF_INTEREST
                            ]
                        }
                    ],
                    symbol: [
                        {
                            layout: {
                                'text-field': '{name}',
                                'symbol-placement': 'line',
                                'text-anchor': 'bottom',
                                'symbol-spacing': 150,
                                'text-max-angle': 35,
                                'text-size': 12,
                                'text-letter-spacing': 0.1,
                                'text-font': [
                                    'Roboto Bold',
                                    'Arial Unicode MS Regular'
                                ]
                            },
                            paint: {
                                'text-halo-color': 'hsl(0, 100%, 100%)',
                                'text-halo-width': 1,
                                'text-halo-blur': 1
                            }
                        }
                    ]
                }
            },
            {
                id: nextGroupId + 1,
                name: 'Markers',
                type: 'Point',
                canAdd: true,
                minimumNumber: 0,
                maximumNumber: null,
                categories: [
                    {
                        id: LocationFeatureDto.Type.RP,
                        name: 'RP'
                    },
                    {
                        id: LocationFeatureDto.Type.OBSERVER,
                        name: 'Observer/Spotter'
                    },
                    {
                        id: LocationFeatureDto.Type.TAKEOFF_LANDING,
                        name: 'Take off & landing'
                    },
                    {
                        id: LocationFeatureDto.Type.LANDING,
                        name: 'Alternative Landing'
                    },
                    {
                        id: LocationFeatureDto.Type.POINT_OF_INTEREST,
                        name: 'Point of Interest'
                    }
                ],
                existingFeatures: groups.markers,
                styles: {
                    symbol: [
                        {
                            layout: { 'icon-image': 'flight-rp' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.RP
                            ]
                        },
                        {
                            // todo: different icon
                            layout: { 'icon-image': 'flight-observer' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.OBSERVER
                            ]
                        },
                        {
                            layout: { 'icon-image': 'flight-takeoff' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.TAKEOFF
                            ]
                        },
                        {
                            layout: { 'icon-image': 'flight-landing' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.LANDING
                            ]
                        },
                        {
                            layout: { 'icon-image': 'flight-takeoff' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.TAKEOFF_LANDING
                            ]
                        },
                        {
                            layout: { 'icon-image': 'flight-poi' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.POINT_OF_INTEREST
                            ]
                        }
                    ]
                }
            },
            {
                id: nextGroupId + 2,
                name: 'Lines',
                type: 'LineString',
                canAdd: true,
                minimumNumber: 0,
                maximumNumber: null,
                categories: [
                    {
                        id: LocationFeatureDto.Type.FLIGHT_PATH_LINE,
                        name: 'Flight'
                    },
                    {
                        id: LocationFeatureDto.Type.GENERAL_LINE,
                        name: 'General'
                    }
                ],
                existingFeatures: groups.lines,
                styles: {
                    line: [
                        {
                            paint: {
                                'line-color': '#00FF96',
                                'line-width': 3,
                                'line-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.GENERAL_LINE
                            ]
                        },
                        {
                            paint: {
                                'line-color': '#00ffff',
                                'line-width': 3,
                                'line-opacity': 0.5
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.FLIGHT_PATH_LINE
                            ]
                        }
                    ]
                }
            },
            {
                id: nextGroupId + 3,
                name: 'Corridors',
                type: 'AreaLineString',
                canAdd: true,
                minimumNumber: 0,
                maximumNumber: null,
                categories: [
                    {
                        id: LocationFeatureDto.Type.OFFSET_FLIGHT_AREA,
                        name: 'Flight Area'
                    },
                    {
                        id: LocationFeatureDto.Type.OFFSET_NO_FLY_AREA,
                        name: 'No Fly Area'
                    },
                    {
                        id: LocationFeatureDto.Type.OFFSET_DANGER_AREA,
                        name: 'Danger'
                    }
                ],
                existingFeatures: groups.corridors,
                styles: {
                    fill: [
                        {
                            paint: {
                                'fill-color': '#ff1234'
                            },
                            filter: ['!', ['has', 'category-id']]
                        },
                        {
                            paint: {
                                'fill-color': '#990000',
                                'fill-opacity': 0.4
                            },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
                            ]
                        },
                        {
                            paint: {
                                'fill-pattern': 'no-fly',
                                'fill-opacity': 0.3
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.OFFSET_DANGER_AREA
                            ]
                        },
                        {
                            paint: {
                                'fill-pattern': 'danger-area',
                                'fill-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.OFFSET_NO_FLY_AREA
                            ]
                        }
                    ],
                    line: [
                        {
                            paint: {
                                'line-color': '#FF0000',
                                'line-width': 3,
                                'line-opacity': 1,
                                'line-dasharray': [5, 5]
                            },

                            filter: ['==', ['geometry-type'], 'LineString']
                        },
                        {
                            paint: {
                                'line-color': '#990000',
                                'line-width': 3,
                                'line-opacity': 1
                            },

                            filter: [
                                'all',
                                [
                                    '==',
                                    ['get', 'category-id'],
                                    LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
                                ],
                                ['==', ['geometry-type'], 'Polygon']
                            ]
                        },
                        {
                            paint: {
                                'line-color': '#fecb1c',
                                'line-width': 3,
                                'line-opacity': 0.5
                            },

                            filter: [
                                'all',
                                [
                                    '==',
                                    ['get', 'category-id'],
                                    LocationFeatureDto.Type.OFFSET_DANGER_AREA
                                ],
                                ['==', ['geometry-type'], 'Polygon']
                            ]
                        },
                        {
                            paint: {
                                'line-width': 3,
                                'line-opacity': 0.5
                            },

                            filter: [
                                'all',
                                [
                                    '==',
                                    ['get', 'category-id'],
                                    LocationFeatureDto.Type.OFFSET_NO_FLY_AREA
                                ],
                                ['==', ['geometry-type'], 'Polygon']
                            ]
                        }
                    ],
                    symbol: [
                        {
                            layout: {
                                'text-field': '{name}',
                                'symbol-placement': 'line',
                                'text-anchor': 'bottom',
                                'symbol-spacing': 150,
                                'text-max-angle': 35,
                                'text-size': 12,
                                'text-letter-spacing': 0.1,
                                'text-font': [
                                    'Roboto Bold',
                                    'Arial Unicode MS Regular'
                                ]
                            },
                            paint: {
                                'text-halo-color': 'hsl(0, 100%, 100%)',
                                'text-halo-width': 1,
                                'text-halo-blur': 1
                            }
                        }
                    ]
                }
            }
        ]
    };
}

/**
 * Returns the first valid flight area.
 *
 * @param location a location
 * @returns an optional of a location feature
 */
export function flightAreaOf(location: LocationDetailsDto) {
    return pipe(
        fromNullable(location),
        chain(l => flightAreaOfFeatures(l.features ?? []))
    );
}

/**
 * Returns the first valid flight area.
 *
 * @param locationFeatures a location feature array
 * @returns an optional of a location feature
 */
export function flightAreaOfFeatures(locationFeatures: LocationFeatureDto[]) {
    return pipe(
        fromNullable(
            locationFeatures.filter(
                f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
            ).length > 0
                ? locationFeatures.filter(
                      f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
                  )
                : locationFeatures.filter(
                      f => f.type === LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
                  )
        ),
        chainNullableK(f =>
            f.find(g =>
                validPolygon(
                    g.type === LocationFeatureDto.Type.FLIGHT_AREA
                        ? g.geometry
                        : findFlightAreaFeatureType(g, 'Polygon')
                )
            )
        )
    );
}

/**
 * Returns the first point in the flight area. This is for a fallback if
 * there is no polygons
 * @param locationFeatures  a location feature array
 * @returns an optional point for the flight area
 */
export function flightAreaPointOfFeatures(
    locationFeatures: LocationFeatureDto[]
) {
    return pipe(
        fromNullable(
            locationFeatures.filter(
                f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
            ).length > 0
                ? locationFeatures.filter(
                      f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
                  )
                : locationFeatures.filter(
                      f => f.type === LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
                  )
        ),
        chainNullableK(f =>
            f.find(g => findFlightAreaFeatureType(g, 'Point') != null)
        )
    );
}

/**
 * Test if the provided is a valid polygon with points. Does not do full polygon validation.
 * @param geometry geometry to test
 * @returns is a valid polygon with points
 */
export function validPolygon(geometry: GeoJSON.Geometry) {
    return (
        geometry != null &&
        geometry.type === 'Polygon' &&
        geometry.coordinates != null &&
        geometry.coordinates.length > 0 &&
        geometry.coordinates[0].length > 0
    );
}

/**
 * Find a specific feature type, including looking for that type from within a geometry collection's geometries.
 * Doesn't scan deeper than one level for geometry collections
 *
 * @param feature A LocationFeatureDto feature to find the type for
 * @param featureType The GeoJson geometry type to look for, eg. 'Polygon' or 'Point'
 * @returns the geometry from the feature matching the type. Returns null if no match is found.
 */
function findFlightAreaFeatureType(
    feature: LocationFeatureDto,
    featureType: GeoJSON.GeoJsonGeometryTypes
) {
    if (feature.geometry.type === 'GeometryCollection') {
        return (<GeometryCollection>feature.geometry).geometries.find(
            g => g.type === featureType
        );
    } else {
        return feature.geometry?.type === featureType ? feature.geometry : null;
    }
}

/**
 * Extract the file name from the content disposition response header.
 *
 * https://stackoverflow.com/a/67994693/160056
 *
 * @param contentDisposition the content-disposition header
 * @returns filename
 */
export function getFileName(contentDisposition: string): string {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string = null;
    if (utf8FilenameRegex.test(contentDisposition)) {
        fileName = decodeURIComponent(
            utf8FilenameRegex.exec(contentDisposition)[1]
        );
    } else {
        const matches = asciiFilenameRegex.exec(contentDisposition);
        if (matches != null && matches[2]) {
            fileName = matches[2];
        }
    }
    return fileName;
}
