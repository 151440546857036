<ng-container *ngIf="dashboardService.sideBarState$ | async as sidebar">
    <div class="panel">
        <div
            *ngIf="!sidebar.isSideBarCollapsed"
            class="panel-heading vertical-align space-between"
        >
            Mission Controls
            <button
                class="btn btn-tertiary panel-collapse-button"
                type="button"
                (click)="showControls = !showControls"
            >
                <span
                    class="fa"
                    [ngClass]="{
                        'fa-chevron-down': !showControls,
                        'fa-chevron-up': showControls
                    }"
                ></span>
            </button>
        </div>
        <div
            *ngIf="showControls"
            class="panel-body vertical-container"
            @expandOnEnter
            @collapseOnLeave
        >
            <button
                *ngIf="canAddMission"
                type="button"
                class="btn btn-primary btn-sm"
                (click)="newMission()"
                angulartics2On="click"
                angularticsAction="mission-view-add"
                tooltip="Start planning a new mission"
                enhancedHelp="mission-view-add"
                placement="right"
            >
                <i class="fa-plus fa"></i>
                <span *ngIf="!sidebar.isSideBarCollapsed"> Create Mission</span>
            </button>
            <button
                *ngIf="hasFlightLogging"
                type="button"
                class="btn btn-default btn-sm"
                (click)="showFlightLogsDialogue()"
                angulartics2On="click"
                angularticsAction="mission-view-flight-logs"
                tooltip="Show flight logs"
                enhancedHelp="mission-view-flight-logs"
                placement="right"
            >
                <i class="fa-map fa"></i>
                <span *ngIf="!sidebar.isSideBarCollapsed">
                    Flight Logs
                    <span *ngIf="newFlightLogs > 0" class="unseen-counter">{{
                        newFlightLogs < 10 ? newFlightLogs : '+'
                    }}</span></span
                >
            </button>

            <button
                *ngIf="canReport"
                type="button"
                class="btn btn-default btn-sm"
                (click)="showMissionReportsDialogue()"
                angulartics2On="click"
                angularticsAction="mission-view-exports"
                tooltip="Export the missions to a CSV file"
                enhancedHelp="mission-view-exports"
                placement="right"
            >
                <i class="fa-print fa"></i>
                <span *ngIf="!sidebar.isSideBarCollapsed">
                    Mission Exports</span
                >
            </button>

            <button
                *ngIf="canUseCalendar"
                type="button"
                class="btn btn-default btn-sm"
                (click)="showMissionCalendar()"
                angulartics2On="click"
                angularticsAction="mission-view-calendar"
                tooltip="Show the missions on a calendar"
                enhancedHelp="mission-view-calendar"
                placement="right"
            >
                <i class="fa-calendar-alt fa"></i>
                <span *ngIf="!sidebar.isSideBarCollapsed">
                    Mission Calendar</span
                >
            </button>
        </div>
    </div>
    <div class="panel">
        <div
            *ngIf="!sidebar.isSideBarCollapsed"
            class="panel-heading vertical-align space-between"
        >
            Viewport Options
            <button
                class="btn btn-tertiary panel-collapse-button"
                type="button"
                (click)="showOptions = !showOptions"
            >
                <span
                    class="fa"
                    [ngClass]="{
                        'fa-chevron-down': !showOptions,
                        'fa-chevron-up': showOptions
                    }"
                ></span>
            </button>
        </div>
        <div
            *ngIf="showOptions"
            class="panel-body vertical-container"
            btnRadioGroup
            [(ngModel)]="missionViewMode"
            @expandOnEnter
            @collapseOnLeave
        >
            <label
                class="btn btn-sm btn-pill btn-default bottom-buffer"
                btnRadio="MAP"
                (click)="updateMissionViewMode()"
                angulartics2On="click"
                angularticsAction="display-map"
                tooltip="Show the map for airspace checking only"
                enhancedHelp="display-map"
                placement="right"
                ><span class="fa fa-map-location"></span>
                <span *ngIf="!sidebar.isSideBarCollapsed">
                    Map View Only</span
                ></label
            >
            <label
                class="btn btn-sm btn-pill btn-default bottom-buffer"
                btnRadio="LIST"
                (click)="updateMissionViewMode()"
                angulartics2On="click"
                angularticsAction="display-table"
                tooltip="Show the mission table only"
                enhancedHelp="display-table"
                placement="right"
                ><span class="fa fa-table"></span
                ><span *ngIf="!sidebar.isSideBarCollapsed">
                    Table View Only</span
                ></label
            >
            <label
                class="btn btn-sm btn-pill btn-default"
                btnRadio="BOTH"
                (click)="updateMissionViewMode()"
                angulartics2On="click"
                angularticsAction="display-table-and-map"
                tooltip="Show the mission table and show the locations on the map"
                enhancedHelp="display-table-and-map"
                placement="right"
                ><span class="fa fa-square-minus"></span
                ><span *ngIf="!sidebar.isSideBarCollapsed"> Both</span></label
            >
        </div>
    </div>
</ng-container>
