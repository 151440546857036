<div class="panel widget">
    <div class="panel-heading-flex">
        <div class="panel-title no-uppercase">General Tasks</div>
        <div class="panel-content"></div>
        <div class="panel-buttons">
            <button
                class="btn btn-inline"
                (click)="showBody = !showBody"
                [tooltip]="showBody ? 'Hide' : 'Show'"
                placement="left"
            >
                <i *ngIf="showBody" class="fa-chevron-up fa no-border"></i>
                <i *ngIf="!showBody" class="fa-chevron-down fa no-border"></i>
            </button>
        </div>
    </div>
    <div *ngIf="showBody" class="panel-body" @expandOnEnter @collapseOnLeave>
        <div class="static-table-container no-actions" *ngIf="generalTasks">
            <static-table
                [availableColumns]="availableColumns"
                [selectedColumns]="selectedColumns"
                [tableData]="generalTasks"
                [tableConfig]="tableConfig"
                [borderless]="false"
                [underlinedRows]="true"
                scanability="comfortable"
                (pageChanged)="onTablePageChanged($event)"
                (selectedColumnsChanged)="
                    updateSelectedColumns($event.selectedColumns)
                "
                (itemLimitUpdated)="updateItemLimit($event)"
            >
                <ng-template
                    columnTemplate="summary"
                    let-row="row"
                    let-column="column"
                >
                    <a
                        [innerHTML]="row.summary | formatEmptyTitle | unsafeHtml"
                        (click)="viewElement(row)"
                    >
                    </a>
                </ng-template>
                <ng-template
                    columnTemplate="assignee"
                    let-row="row"
                    let-column="column"
                >
                    <div style="width: 200px">
                        <person-avatar
                            [person]="row.assignee"
                            [managingOrganisationId]="organisation.id"
                        ></person-avatar>
                    </div>
                </ng-template>
                <ng-template
                    columnTemplate="reporter"
                    let-row="row"
                    let-column="column"
                >
                    <div style="width: 200px">
                        <person-avatar
                            [person]="row.reporter"
                            [managingOrganisationId]="organisation.id"
                        ></person-avatar>
                    </div>
                </ng-template>
                <ng-template
                    columnTemplate="priority"
                    let-row="row"
                    let-column="column"
                >
                    <span
                        class="status-cell"
                        [ngClass]="{
                            success: row.priority === 'LOW',
                            warning: row.priority === 'MEDIUM',
                            error: row.priority === 'HIGH'
                        }"
                    >{{
                            row.priority != null
                                ? formatPriority[row.priority]
                                : 'OK'
                        }}</span
                    >
                </ng-template>
                <ng-template
                    columnTemplate="status"
                    let-row="row"
                    let-column="column"
                >
                    <span
                        class="status-cell"
                        [ngClass]="{
                            success: row.status === 'COMPLETED',
                            info: row.status === 'IN_PROGRESS',
                            warning: row.status === 'TODO',
                            error: row.status === 'CANCELLED'
                        }"
                    >{{
                            row.status != null
                                ? formatStatuses[row.status]
                                : 'OK'
                        }}</span
                    >
                </ng-template>
            </static-table>
            <button
                *ngIf="canAddTask"
                class="btn btn-primary btn-new-task"
                type="button"
                [disabled]="working"
                (click)="onAddNewTask()"
            >
                + New Task
            </button>
        </div>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
