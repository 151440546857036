/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface ContactDto { 
    type: ContactDto.Type;
    /**
     * The identifier for the entity this is associated with. e.g., the aerodrome
     */
    identifier?: string;
    /**
     * An identifier for the subtype of this contact. If it is a frequency this could be TWR
     */
    subtype?: string;
    /**
     * A stand alone name for this contact. This might be specific to the contact, or just be the associated entity, e.g., the aerodrome
     */
    name: string;
    value: string;
}
export namespace ContactDto {
    export type Type = 'AERODROME' | 'CUSTOM';
    export const Type = {
        AERODROME: 'AERODROME' as Type,
        CUSTOM: 'CUSTOM' as Type
    };
}



