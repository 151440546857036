import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AttachmentHandler,
    AuthorityService,
    AuthorityTypeDto,
    CraftAuthorityDto,
    CurrentAttachmentVersionDto,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker,
    fromLocalDate,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'authority-edit-dialogue',
    templateUrl: './authority-edit-dialogue.component.html'
})
export class AuthorityEditDialogueComponent implements OnInit, OnDestroy {
    @Input() resolve: { [key: string]: any };
    @Input() modalRef: BsModalRef;
    @Output() updated = new EventEmitter<CraftAuthorityDto>();
    endorsedEntityId: number;
    managingOrganisationId: number;
    authorityType: AuthorityTypeDto;
    authority: CraftAuthorityDto;
    attachments: CurrentAttachmentVersionDto[];
    attachmentsHandler: AttachmentHandler;
    _expiryDate: Date;
    _startDate: Date;
    documentForm: FormGroup;
    authorityService: AuthorityService<CraftAuthorityDto, unknown, unknown>;
    IMG_URL: string;

    isNew: boolean;

    private workTracker = new WorkTracker();
    working = false;

    constructor(
        private logging: FlyFreelyLoggingService,
        constants: FlyFreelyConstants
    ) {
        this.IMG_URL = constants.IMG_URL;
        this.workTracker
            .asObservable()
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.endorsedEntityId = this.resolve.endorsedEntityId;
        this.authorityService = this.resolve.authorityService;
        this.managingOrganisationId = this.resolve.managingOrganisationId;

        this.authorityType = this.resolve.authorityType;
        if (this.resolve.authority) {
            this.authority = this.resolve.authority;

            if (this.authority.expiryDate) {
                this._expiryDate = fromLocalDate(this.authority.expiryDate);
            }

            if (this.authority.startDate) {
                this._startDate = fromLocalDate(this.authority.startDate);
            }

            this.attachmentsHandler = this.authorityService.attachmentHandler(
                this.authority.id,
                this.managingOrganisationId
            );
        }

        if (this.authority != null) {
            this.isNew = this.authority.status === CraftAuthorityDto.Status.NEW;
        }

        this.initForm();
    }

    ngOnDestroy() {
        this.workTracker.ngOnDestroy();
    }

    initForm() {
        this.documentForm = new FormGroup({
            startDate: new FormControl(this._startDate, [Validators.required]),
            regNumber: this.authority
                ? new FormControl(this.authority.identifier)
                : new FormControl(''),
            expiryDate:
                this.authority && this.authority.expiryDate
                    ? new FormControl(this._expiryDate, [Validators.required])
                    : new FormControl('')
        });
    }

    onSubmit() {
        const payload = {
            authorityTypeId: this.authorityType.id,
            expiryDate: toLocalDate(this.expiryDate.value),
            startDate: toLocalDate(this.startDate.value),
            identifier: this.regNumber.value,
            managingOrganisationId: this.managingOrganisationId
        };

        if (!this.authority) {
            this.authorityService
                .createAuthority(this.endorsedEntityId, payload)
                .subscribe({
                    next: results => {
                        this.isNew = false;
                        this.updated.emit(results);
                    },
                    error: error => {
                        this.authorityService.deleteAuthority(
                            this.authority.id,
                            this.managingOrganisationId
                        );
                        this.logging.error(
                            error,
                            `Error creating authority: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            this.authorityService
                .updateAuthority(this.authority.id, payload)
                .subscribe({
                    next: results => {
                        this.updated.emit(results);
                    },
                    error: error =>
                        this.logging.error(
                            error,
                            `Error updating authority: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        }
    }

    close() {
        if (this.authority != null && this.authority.id != null && this.isNew) {
            const doneWorking = this.workTracker.createTracker();
            this.authorityService
                .deleteAuthority(this.authority.id, this.managingOrganisationId)
                .then(
                    () => {
                        doneWorking();
                        this.modalRef.hide();
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error removing authority on cancel: ${error.message}`
                        );
                        doneWorking();
                        this.modalRef.hide();
                    }
                );
        } else {
            this.modalRef.hide();
        }
    }

    get startDate() {
        return this.documentForm.get('startDate');
    }

    get regNumber() {
        return this.documentForm.get('regNumber');
    }

    get expiryDate() {
        return this.documentForm.get('expiryDate');
    }
}
