export enum MapEventType {
    mapLoaded,
    mapDrawCreated,
    mapDrawUpdated,
    mapDrawModeChanged,
    mapDrawSelectionChanged,
    mapDrawDeleted,
    mouseDown,
    mouseMove,
    mouseClick,
    touchStart,
    touchTap,
    toolbarButtonClicked,
    activeInteractionChanged,
    measurementValueChanged,
    mapMoveEnd,
    mapZoomEnd
}
