import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonDto,
    PersonService,
    PersonsOrganisationDto,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface OrganisationLookup {
    [organisationID: number]: boolean;
}

@Component({
    selector: 'managing-organisations',
    templateUrl: './managing-organisations.component.html'
})
export class ManagingOrganisations implements OnInit, OnChanges, OnDestroy {
    @Input() person: PersonDto;

    managingOrganisations: OrganisationLookup = {};

    availableOrganisations: PersonsOrganisationDto[];

    private workTracker = new WorkTracker();
    working: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    addNew = false;
    newOrganisationId: number;

    error: string;

    constructor(
        private personService: PersonService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        if (this.person != null) {
            this.loadOrganisations();
        } else {
            this.error = 'Dialogue not invoked correctly';
        }

        this.userService.userChange$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.loadOrganisations();
            });

        this.personService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => this.loadOrganisations());
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('person' in changes) {
            this.loadOrganisations();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private loadOrganisations() {
        if (this.person == null) {
            this.availableOrganisations = [];
            this.managingOrganisations = [];
            return;
        }

        this.availableOrganisations = this.userService
            .findUsersOrganisations()
            .sort((o1, o2) =>
                o1.personalOrganisation
                    ? -1
                    : o2.personalOrganisation
                    ? 1
                    : o1.name.localeCompare(o2.name)
            );

        this.userService
            .findManagingOrganisations()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                managingOrganisations =>
                    (this.managingOrganisations = managingOrganisations.reduce(
                        (acc, o) => ({ ...acc, [o.id]: true }),
                        {} as OrganisationLookup
                    ))
            );
    }

    // remove organisation entirely
    removeOrganisation(organisation: PersonsOrganisationDto) {
        // do something
    }

    manageSelected($event: any, organisation: PersonsOrganisationDto) {
        const doneWorking = this.workTracker.createTracker();

        if ($event.target.checked === false) {
            this.userService
                .removeManagingOrganisations(organisation.id)
                .toPromise()
                .then(
                    managingorganisations => {
                        this.managingOrganisations = managingorganisations.reduce(
                            (acc, o) => ({ ...acc, [o.id]: true }),
                            {} as OrganisationLookup
                        );
                        doneWorking();

                        this.logging.success(
                            `Is no longer managed by ${organisation.name}`
                        );
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error editing managing organisation: ${error.message}`
                        )
                );
        } else {
            this.userService
                .addManagingOrganisations(organisation.id)
                .toPromise()
                .then(
                    managingorganisations => {
                        this.managingOrganisations = managingorganisations.reduce(
                            (acc, o) => ({ ...acc, [o.id]: true }),
                            {} as OrganisationLookup
                        );
                        doneWorking();

                        this.logging.success(
                            `Is now managed by ${organisation.name}`
                        );
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error editing managing organisation: ${error.message}`
                        )
                );
        }
    }

    changeDefault(organisation: PersonsOrganisationDto) {
        const doneWorking = this.workTracker.createTracker();

        this.userService.updateDefaultOrganisation(organisation.id).then(
            person => {
                this.person = person;
                doneWorking();

                this.logging.success(
                    `Default organisation changed to ${organisation.name}`
                );
            },
            (error: FlyFreelyError) =>
                this.logging.error(
                    error,
                    `Error updating default organisation: ${error.message}`
                )
        );
    }
}
