<ng-container screenAnalytics="organisation-authority-edit">
    <div class="modal-header no-icon">
        <h3 class="modal-title">{{ authorityType.name }} Details</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="formGroup" (ngSubmit)="save()" novalidate>
        <div class="modal-body">
            <ng-container *ngIf="authority != null">
                <div class="top-buffer">
                    <div class="form-group">
                        <label>Start Date</label>
                        <field-validation-indicator
                            *ngIf="authority.availableActions.canEdit"
                            class="pull-right"
                            validatorFormControlName="startDate"
                        ></field-validation-indicator>
                        <lockable
                            [isLocked]="!authority.availableActions.canEdit"
                            [message]="lockedMessage"
                        >
                            <ng-template lockedMode let-identifier="context">{{
                                formGroup.get('startDate').value | formatDate
                            }}</ng-template>

                            <ng-template unlockedMode let-identifier="context">
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        #startDatePicker="bsDatepicker"
                                        bsDatepicker
                                        formControlName="startDate"
                                    />
                                    <span
                                        class="input-group-addon btn-default"
                                        (click)="startDatePicker.toggle()"
                                        [attr.aria-expanded]="
                                            startDatePicker.isOpen
                                        "
                                    >
                                        <i
                                            class="glyphicon glyphicon-calendar"
                                        ></i>
                                    </span>
                                </div>
                                <validation-messages
                                    validatorFormControlName="startDate"
                                ></validation-messages
                            ></ng-template>
                        </lockable>
                    </div>
                </div>
                <enhanced-help componentName="startDate" [compact]="true">
                </enhanced-help>

                <div class="top-buffer" *ngIf="authorityType.hasExpiry">
                    <div class="form-group">
                        <label>Expiry Date</label>
                        <lockable
                            [isLocked]="!authority.availableActions.canEdit"
                            [message]="lockedMessage"
                        >
                            <ng-template lockedMode let-identifier="context">{{
                                formGroup.get('expiryDate').value | formatDate
                            }}</ng-template>
                            <ng-template unlockedMode let-identifier="context">
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        #expiryDatePicker="bsDatepicker"
                                        bsDatepicker
                                        formControlName="expiryDate"
                                    />
                                    <span
                                        class="input-group-addon btn-default"
                                        (click)="expiryDatePicker.toggle()"
                                        [attr.aria-expanded]="
                                            expiryDatePicker.isOpen
                                        "
                                    >
                                        <i
                                            class="glyphicon glyphicon-calendar"
                                        ></i>
                                    </span>
                                </div>
                                <validation-messages
                                    validatorFormControlName="expiryDate"
                                ></validation-messages
                            ></ng-template>
                        </lockable>
                    </div>
                </div>
                <enhanced-help
                    *ngIf="authorityType.hasExpiry"
                    componentName="expiryDate"
                    [compact]="true"
                >
                </enhanced-help>

                <div class="top-buffer" *ngIf="authorityType.hasIdentifier">
                    <div class="form-group">
                        <label>{{
                            authorityType.identifierLabel || 'Identifier'
                        }}</label>
                        <field-validation-indicator
                            *ngIf="authority.availableActions.canEdit"
                            class="pull-right"
                            validatorFormControlName="identifier"
                        ></field-validation-indicator>

                        <lockable
                            [isLocked]="!authority.availableActions.canEdit"
                            [message]="lockedMessage"
                        >
                            <ng-template lockedMode let-identifier="context">{{
                                formGroup.get('identifier').value
                            }}</ng-template>
                            <ng-template unlockedMode let-identifier="context">
                                <input
                                    class="form-control"
                                    formControlName="identifier" />
                                <validation-messages
                                    validatorFormControlName="identifier"
                                ></validation-messages
                            ></ng-template>
                        </lockable>
                    </div>
                </div>
                <enhanced-help
                    *ngIf="authorityType.hasIdentifier"
                    componentName="identifier"
                    [compact]="true"
                >
                </enhanced-help>

                <div class="top-buffer" *ngIf="hasRequiresApprovalPolicy">
                    <label>Requires Approval</label>
                    <field-validation-indicator
                        class="pull-right"
                        validatorFormControlName="requiresApprovalPolicy"
                    ></field-validation-indicator>
                    <div
                        class="input-group"
                        btnRadioGroup
                        formControlName="requiresApprovalPolicy"
                        style="padding-top: 5px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            btnRadio="NEVER"
                            [disabled]="working"
                            >Never</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            btnRadio="ALWAYS"
                            [disabled]="working"
                            >Always</label
                        >
                    </div>
                    <validation-messages
                        validatorFormControlName="requiresApprovalPolicy"
                    >
                    </validation-messages>
                </div>
                <enhanced-help
                    *ngIf="hasRequiresApprovalPolicy"
                    componentName="requiresApprovalPolicy"
                    [compact]="true"
                >
                </enhanced-help>

                <div
                    *ngIf="
                        hasEndorsements &&
                        authorityType.availableEndorsementList?.length
                    "
                    class="top-buffer"
                >
                    <label>Available Endorsements</label>
                    <apply-endorsements
                        [availableEndorsementList]="
                            authorityType.availableEndorsementList
                        "
                        formControlName="appliedEndorsementIdList"
                    ></apply-endorsements>
                </div>

                <div class="top-buffer" *ngIf="authorityId">
                    <div class="form-group">
                        <label>Attachments</label>
                        <attachment-list
                            [attachmentsHandler]="attachmentsHandler"
                            [allowUploads]="true"
                            [allowEdit]="true"
                            [allowDelete]="true"
                            [allowAcknowledgements]="false"
                            [requestingOrganisationId]="managingOrganisationId"
                            permissions
                        ></attachment-list>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-primary"
                type="submit"
                [disabled]="formGroup.invalid || working"
                data-cy="authorityEditSaveBtn"
            >
                Save
            </button>
        </div>
        <ngx-loading [show]="working"></ngx-loading>
    </form>
</ng-container>
