import { PilotRegisterStatusUnion } from './mission-edit.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatMissionEditRegisterStatus'
})
export class FormatMissionEditRegisterStatus implements PipeTransform {
    transform(value: PilotRegisterStatusUnion): string {
        switch (value) {
            case null:
                return '';
            case 'LOADING':
                // Not used
                return 'Loading...';
            case 'NOT_ON_REGISTER':
                return 'Not on register';
            case 'PENDING':
                return 'Pending';
            case 'SUSPENDED':
                return 'Suspended';
            case 'EXPIRED':
                return 'Expired';
            case 'NOT_AVAILABLE':
                return 'Not available';
            case 'ACTIVE':
                return 'Active';
        }
    }
}
