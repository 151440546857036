import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'copy-to-clipboard',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<button
        class="btn btn-default btn-tertiary"
        tooltip="Copy to clipboard"
        placement="top"
        ngxClipboard
        [cbContent]="content"
        (cbOnSuccess)="onCopyToClipboard()"
    >
        <span class="fal fa-copy"></span>
    </button>`
})
export class CopyToClipboard {
    @Input()
    content: string;

    constructor(private logging: FlyFreelyLoggingService) {}

    onCopyToClipboard() {
        this.logging.success('Copied to clipboard');
    }
}
