import {
    ModuleWithProviders,
    NgModule,
    inject,
    Injector,
    NgZone
} from '@angular/core';
import { QUILL_CONFIG_TOKEN, QuillModule } from 'ngx-quill';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RichTextEditorConfigService } from './rich-text-editor-config.service';
import 'quill-mention';
import { AttachmentHandler } from '@flyfreely-portal-ui/flyfreely';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import Quill from 'quill';
import { FetchImageService } from './fetch-image.service';

const BlockEmbed = Quill.import('blots/block/embed');

export class CustomImageBlot extends BlockEmbed {
    static blotName = 'customImage';
    static tagName = 'div';
    static className = 'custom-image';

    static injector: Injector;

    static create(value: string) {
        const node = super.create();
        node.setAttribute('data-url', value);
        node.innerHTML = 'loading...';

        this.fetchAndRenderImage(node, value);

        return node;
    }

    static value(node: any) {
        return node.getAttribute('data-url');
    }

    static fetchAndRenderImage(node: any, url: string) {
        const fetchImageService = this.injector.get(FetchImageService);

        fetchImageService
            .fetchImage(url)
            .then(imageDataUrl => {
                const img = document.createElement('img');
                if (typeof imageDataUrl === 'string') {
                    img.src = imageDataUrl;
                }
                node.innerHTML = '';
                node.appendChild(img);
            })
            .catch(error => {
                console.error('Error fetching image:', error);
                node.innerHTML = 'load image fail';
            });
    }
}
Quill.register(CustomImageBlot);

export function quillConfigFactory(configService: RichTextEditorConfigService) {
    function imageHandler(this: any) {
        const editor = this.quill;
        const range = editor.getSelection();

        const fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/*');
        fileInput.click();

        fileInput.onchange = async () => {
            // @ts-ignore
            const file: File = fileInput.files[0];
            if (file) {
                const fetchImageService =
                    CustomImageBlot.injector.get(FetchImageService);
                try {
                    const imageUrl = await fetchImageService.uploadImage(file);
                    if (imageUrl) {
                        editor.insertEmbed(
                            range.index,
                            'customImage',
                            imageUrl,
                            'user'
                        );
                        editor.setSelection(range.index + 1);
                    }
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            }
        };
    }

    return {
        modules: {
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['image']
                ],
                handlers: {
                    image: imageHandler
                }
            },
            mention: {
                mentionDenotationChars: ['@', '#RPA', '#BATTERY'],
                onSelect: function (
                    item: any,
                    insertItem: (item: any) => void
                ) {
                    if (item.id === 'loading') {
                        return false;
                    }
                    insertItem(item);
                    return true;
                },
                source: async function (
                    searchTerm: string,
                    renderList: (arg0: any[], arg1: any) => void,
                    mentionChar: string
                ) {
                    renderList(
                        [{ value: 'loading...', id: 'loading', loading: true }],
                        searchTerm
                    );
                    let items: any[] = [];
                    if (mentionChar === '@') {
                        items = await configService.suggestPeople(searchTerm);
                    } else if (mentionChar === '#RPA') {
                        items = await configService.suggestRpa(searchTerm);
                    } else if (mentionChar === '#BATTERY') {
                        items = await configService.suggestBattery(searchTerm);
                    }
                    renderList(items, searchTerm);
                },
                renderItem: function (
                    item: { loading: any; value: any },
                    searchTerm: any
                ) {
                    if (item.loading) {
                        return `<div class="mention-loading" style="pointer-events: none;">${item.value}</div>`;
                    }
                    return `${item.value}`;
                },
                blotName: 'mention'
            }
        }
    };
}

@NgModule({
    imports: [QuillModule, FormsModule, CommonModule, HttpClientModule],
    exports: [QuillModule, RichTextEditorComponent],
    declarations: [RichTextEditorComponent],
    providers: [RichTextEditorConfigService, FetchImageService]
})
export class RichTextEditorModule {
    constructor(private injector: Injector) {
        CustomImageBlot.injector = this.injector;
    }
    static forChild(): ModuleWithProviders<RichTextEditorModule> {
        return {
            ngModule: RichTextEditorModule,
            providers: [
                RichTextEditorConfigService,
                {
                    provide: QUILL_CONFIG_TOKEN,
                    useFactory: quillConfigFactory,
                    deps: [RichTextEditorConfigService, FetchImageService]
                }
            ]
        };
    }
}
