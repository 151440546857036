import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { fromNullable, getOrElse } from 'fp-ts/es6/Option';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import { LoggedInUser, UserService, UserStatus } from './system/user.service';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {
    private baseUrl: string;

    private ngUnsubscribe$ = new Subject<void>();

    private showDummyDataSource = new BehaviorSubject(true);
    showDummyData$ = this.showDummyDataSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private angulartics2: Angulartics2,
        userService: UserService
    ) {
        this.baseUrl = constants.SITE_URL;

        userService.userChange$
            .pipe(
                filter(c => c.type === UserStatus.LOGGED_IN),
                map((change: LoggedInUser) => change.currentUser),
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(() => this.refreshPreferences());
    }

    ngOnDestroy() {
        this.showDummyDataSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPreferences() {
        this.findPreferencesAsOption('dummy-data', null)
            .pipe(
                map(
                    p =>
                        getOrElse(() => ({ showDummyData: true }))(p)
                            .showDummyData
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(showDummyData =>
                this.showDummyDataSource.next(showDummyData)
            );
    }

    /**
     * Updates user preferences stored for a particular component. The organisation ID is optional. Preferences is a JS object.
     * @param {*} component component identifier
     * @param {*} organisationId the organisation to store this against (optional)
     * @param {*} preferences preferences object
     */
    updatePreferences(
        component: string,
        organisationId: number,
        preferences: { [key: string]: any }
    ) {
        return this.http.post(
            `${this.baseUrl}/webapi/user/preferences`,
            preferences,
            {
                params: new HttpParams()
                    .set('component', component)
                    .set(
                        'organisationId',
                        organisationId != null ? organisationId.toString() : ''
                    )
            }
        );
    }

    /**
     * Gets the  user preferences stored for a particular component. The organisation ID is optional.
     * You should use `getOrElse` from `fp-ts/es6/Option` to handle the response.
     * @param {*} component component identifier
     * @param {*} organisationId the organisation to store this against (optional)
     */
    findPreferencesAsOption<T = any>(
        component: string,
        organisationId: number
    ) {
        return this.findPreferences<T>(component, organisationId).pipe(
            map(resp => fromNullable(resp))
        );
    }

    findPreferences<T = any>(component: string, organisationId: number) {
        return this.http.get<T>(`${this.baseUrl}/webapi/user/preferences`, {
            params: httpParamSerializer({ component, organisationId })
        });
    }

    setDummyDataPreference(showDummyData: boolean) {
        this.angulartics2.eventTrack.next({
            action: showDummyData ? 'dummy-data-on' : 'dummy-data-off',
            properties: {}
        });

        return this.updatePreferences('dummy-data', null, {
            showDummyData
        }).subscribe(() => this.showDummyDataSource.next(showDummyData));
    }

    get showDummyData() {
        return this.showDummyDataSource.getValue();
    }

    toggleDummyDataPreference() {
        this.setDummyDataPreference(!this.showDummyDataSource.value);
    }
}
