import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CreateHistoricalFlightCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameValue,
    PersonDto,
    PersonHistoricalLogBookService,
    UpdateHistoricalFlightCommand,
    UserService,
    WorkTracker,
    fromLocalDate,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaTypePipe } from '@flyfreely-portal-ui/resource-ui';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlightHistoryUploadService } from './flight-history-upload.service';
import { ManualFlightSummary, RpaModelSummary } from './interfaces';

@Component({
    selector: 'flight-history-edit',
    templateUrl: './flight-history-edit.component.html',
    styleUrls: ['./flight-history-edit.scss'],
    providers: [FlightHistoryUploadService]
})
export class FlightHistoryEdit {
    @Input() rpaCategoryList: NameValue[];
    @Input() rpaTypeList: RpaModelSummary[];
    @Input() timeTypes: NameValue[];
    @Input() sightTypes: NameValue[];
    @Input() currentUser: PersonDto;
    @Input() currentlyEditing: ManualFlightSummary;
    @Input() currentlyEditingId: number;
    @Input() managingOrganisationId: number;
    @Output() done = new EventEmitter<void>();

    formGroup: FormGroup;

    private workTracker = new WorkTracker();
    working = false;
    private ngUnsubscribe$ = new Subject<void>();
    disabledRpaTypeAndMtow = new BehaviorSubject<boolean>(false);

    constructor(
        private formatRpaType: FormatRpaTypePipe,
        modalOptions: ModalOptions,
        private personHistoricalLogBookService: PersonHistoricalLogBookService,
        private flightHistoryUploadService: FlightHistoryUploadService,
        private commonDialoguesService: CommonDialoguesService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService,
        private modal: BsModalRef
    ) {
        modalOptions.closeInterceptor = () =>
            preHide(this.formGroup, this.commonDialoguesService);

        this.formGroup = new FormGroup({
            missionId: new FormControl(undefined),
            rpaTypeId: new FormControl(undefined),
            rpaCategory: new FormControl(undefined, [Validators.required]),
            mtow: new FormControl(undefined),
            timeOfDay: new FormControl(undefined, [Validators.required]),
            lineOfSight: new FormControl(undefined, [Validators.required]),
            locationName: new FormControl(undefined, [Validators.required]),
            flightDate: new FormControl(undefined, [Validators.required]),
            numberOfFlights: new FormControl(undefined, [Validators.required]),
            duration: new FormControl(undefined, [Validators.required]),
            simulated: new FormControl(false, [Validators.required])
        });

        this.setupChangeListener();
    }

    ngOnInit() {
        if (this.currentlyEditing != null) {
            this.formGroup.patchValue({
                rpaCategory: this.currentlyEditing.rpaCategory,
                rpaTypeId: this.currentlyEditing.rpaType?.id,
                mtow: this.currentlyEditing.mtow,
                timeOfDay: this.currentlyEditing.timeOfDay,
                lineOfSight: this.currentlyEditing.visualLineOfSight,
                locationName: this.currentlyEditing.locationName,
                flightDate: fromLocalDate(this.currentlyEditing.flightDate),
                numberOfFlights: this.currentlyEditing.numberOfFlights,
                duration: this.currentlyEditing.duration,
                simulated: this.currentlyEditing.simulated || false
            });
        } else {
            this.formGroup.reset();
        }

        this.findAllowBulkUpload();
    }

    findAllowBulkUpload() {
        const organisations = this.userService.findUsersOrganisations();
        const organisation = organisations.find(
            o => o.id === this.managingOrganisationId
        );
    }

    setupChangeListener() {
        this.formGroup
            .get('rpaTypeId')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((val: number) => {
                if (val === null) {
                    this.formGroup.controls.rpaCategory.enable();
                    this.formGroup.controls.rpaCategory.setValue(null);
                    this.formGroup.controls.mtow.enable();
                    this.disabledRpaTypeAndMtow.next(false);
                    this.formGroup.controls.mtow.setValue(null);
                } else {
                    const type = this.rpaTypeList.find(m => m.value === val);
                    this.formGroup.controls.rpaCategory.setValue(type.type);
                    if (type?.mtow != null) {
                        this.formGroup.controls.mtow.setValue(type?.mtow);
                    } else {
                        this.formGroup.controls.mtow.setValue(null);
                    }

                    this.disabledRpaTypeAndMtow.next(true);
                }
            });
    }

    saveFlight() {
        const f = this.formGroup.value;

        if (this.currentlyEditingId) {
            const entry: UpdateHistoricalFlightCommand = {
                rpaCategory: f.rpaCategory,
                rpaTypeId: f.rpaTypeId,
                maxTakeOffWeight: f.value,
                flightDate: toLocalDate(f.flightDate),
                duration: f.duration,
                locationName: f.locationName,
                numberOfFlights: f.numberOfFlights,
                timeOfDay: f.timeOfDay,
                visualLineOfSight: f.lineOfSight,
                simulated: f.simulated
            };

            this.personHistoricalLogBookService
                .updateHistoricalFlight(this.currentlyEditingId, entry)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: result => {
                        this.logging.success(`Flight updated`);
                        this.formGroup.reset();
                        this.formGroup.markAsPristine();
                        this.close();
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while updating flight record: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            const entry: CreateHistoricalFlightCommand = {
                pilotId: this.currentUser.id,
                rpaTypeId: f.rpaTypeId,
                rpaCategory: f.rpaCategory,
                maxTakeOffWeight: f.mtow,
                flightDate: toLocalDate(f.flightDate),
                duration: f.duration,
                locationName: f.locationName,
                numberOfFlights: f.numberOfFlights,
                timeOfDay: f.timeOfDay,
                visualLineOfSight: f.lineOfSight,
                simulated: f.simulated,
                managingOrganisationId: this.managingOrganisationId
            };

            this.personHistoricalLogBookService
                .createHistoricalFlight(entry)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: result => {
                        this.logging.success(`Flight created`);
                        this.formGroup.reset();
                        this.formGroup.markAsPristine();
                        this.close();
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while creating flight record: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }

    bulkUpload() {
        this.flightHistoryUploadService.showBulkUpload(
            this.currentUser.id,
            this.managingOrganisationId,
            this.rpaTypeList.map(r => ({
                value: r.value,
                name: r.name,
                mtow: r?.mtow
            }))
        );

        // Close the edit modal once done bulk-uploading.
        // This should bubble up to the detailed flight history component and unlock the modal using the exclusive lock service.
        this.flightHistoryUploadService.doneImporting$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.close();
            });
    }

    hideForm() {
        this.close();
    }

    close() {
        this.done.emit();
        this.modal.hide();
    }
}
