import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '@flyfreely-portal-ui/analytics';
import { Angulartics2Module } from 'angulartics2';
import { FlyFreelyVideoModule } from 'libs/flyfreely-video/src/lib/flyfreely-video.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FeaturesDialoguesService } from './features-dialogues.service';
import { MoreFeaturesDialogue } from './more-features/more-features-dialogue.component';
import { NewFeature } from './new-feature/new-feature.component';
import { PlanHighlightComponent } from './plan-highlight/plan-highlight.component';
import { PlanHighlightService } from './plan-highlight/plan-highlight.service';
import { UpsellComponent } from './upsell.component';
@NgModule({
    imports: [
        CommonModule,
        AnalyticsModule,
        SharedUiModule,
        FlyFreelyVideoModule,
        PopoverModule,
        TooltipModule,
        Angulartics2Module,
        RouterModule
    ],
    providers: [PlanHighlightService, FeaturesDialoguesService],
    declarations: [
        UpsellComponent,
        NewFeature,
        PlanHighlightComponent,
        MoreFeaturesDialogue
    ],
    exports: [UpsellComponent, NewFeature, PlanHighlightComponent]
})
export class FeaturesModule {}
