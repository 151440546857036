/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.16.5-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface SubscriptionLimitDto { 
    /**
     * The type of licence
     */
    licencedEntityType: SubscriptionLimitDto.LicencedEntityType;
    /**
     * The number of licences purchased
     */
    instances: number;
    /**
     * The number of licences still available
     */
    availableLicenceCount: number;
    /**
     * The number of licences shared with child organisations
     */
    sharedInstancesCount: number;
    /**
     * The number of licences used by pending invites
     */
    inviteInstancesCount: number;
}
export namespace SubscriptionLimitDto {
    export type LicencedEntityType = 'RPA' | 'PERSONNEL' | 'PILOT';
    export const LicencedEntityType = {
        RPA: 'RPA' as LicencedEntityType,
        PERSONNEL: 'PERSONNEL' as LicencedEntityType,
        PILOT: 'PILOT' as LicencedEntityType
    };
}



