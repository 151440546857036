<div *ngFor="let step of steps" class="completed-documents">
    <h5>{{ step.name }}</h5>
    <ng-container *ngIf="documentationResponses != null">
        <div
            class="step-documents"
            *ngFor="let entity of documentationResponses[step.value]"
        >
            <ng-container *ngIf="entity.forms.length > 0">
                <h6>{{ entity.name }}</h6>
                <div class="document" *ngFor="let form of entity.forms">
                    <span>
                        <a
                            (click)="
                                showFormResponse(
                                    step.value,
                                    entity,
                                    form.entity.formId
                                )
                            "
                        >
                            {{ form.entity.formName }}
                        </a>
                        <field-validation-indicator
                            *ngIf="form.required && step.validate"
                            [valid]="form.completed"
                        >
                        </field-validation-indicator>
                        <a
                            *ngIf="step.editable"
                            class="action"
                            (click)="editForm(step.value, entity, form)"
                            >Edit</a
                        >
                        <a
                            class="action"
                            *ngIf="
                                entity.formResponses[form.entity.formId]
                                    .length > 0
                            "
                            (click)="
                                printFormResponse(
                                    entity.formResponses[form.entity.formId][0],
                                    step.value
                                )
                            "
                        >
                            Print
                        </a>
                        <!--
                        <a class="action"
                        (click)="printFormTemplate(form.entity.formId, step.value)">
                            Print Template
                        </a> -->
                    </span>
                </div>
            </ng-container>
            <ng-container
                *ngIf="entity.attachments && attachmentsByRequirementId"
            >
                <div *ngFor="let attachmentType of entity.attachments">
                    <h6>{{ attachmentType.entity.name }}</h6>
                    <div
                        class="document"
                        *ngFor="
                            let attached of attachmentsByRequirementId[
                                attachmentType.entity.id
                            ]
                        "
                    >
                        <a [downloadUrl]="attached.downloadUrl">
                            {{ attached.name }}
                        </a>
                    </div>
                    <em
                        *ngIf="
                            !attachmentsByRequirementId[
                                attachmentType.entity.id
                            ]
                        "
                    >
                        No attached documents
                    </em>
                </div>
            </ng-container>
        </div>
        <div *ngIf="!hasData[step.value]">
            No documentation required
        </div>
    </ng-container>
</div>
<p *ngIf="!hasData">No extra documentation</p>
