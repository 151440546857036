/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimplePersonDto } from './simplePersonDto';


/**
 * 
 */
export interface ServiceabilitySignoffDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The time the signoff was performed
     */
    signoffTime: string;
    signoffPerson: SimplePersonDto;
    /**
     * The ID of the RPA if it exists in the system
     */
    rpaId?: number;
    /**
     * The ID of an unserviceable RPA
     */
    unlistedRpaId?: string;
    /**
     * The ID of any associated maintenance request
     */
    maintenanceLogId?: number;
    /**
     * What is the status of the serviceability
     */
    serviceability: ServiceabilitySignoffDto.Serviceability;
}
export namespace ServiceabilitySignoffDto {
    export type Serviceability = 'SERVICEABLE' | 'UNSERVICEABLE';
    export const Serviceability = {
        SERVICEABLE: 'SERVICEABLE' as Serviceability,
        UNSERVICEABLE: 'UNSERVICEABLE' as Serviceability
    };
}



