import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
    AddressDto,
    AddressService,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    OrganisationService,
    PersonService,
    ResultDto,
    SubscriptionService,
    UpdateAddressCommand,
    UserLocationService,
    WorkTracker,
    emailValidator
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import CountryStates from 'libs/ui/src/lib/data/country-states.json';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, forkJoin, of } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

const countryStates = CountryStates as Country[];

export interface CountryAndCode {
    iso: string;
    name: string;
}

export interface Country {
    iso: string;
    name: string;
    phonePrefix: string;
    phonePlaceholder: string;
    states: string[];
}

export interface IpLookupResult {
    country: string;
    countryCode: string;
    city: string;
    region: string;
    regionName: string;
    zip: string;
}

/**
 * Find the country by iso code, so that we can handle the country objects
 * @param code the iso code of the country we are resolving
 * @param countries the list of countries
 */
function findCountryByCode(code: string, countries: Country[]): Country {
    if (code == null) {
        return null;
    }
    const matchableName = code.toUpperCase().trim();
    return countries.find(c => c.iso.toUpperCase() === matchableName);
}

@Component({
    selector: 'address-tax-dialogue',
    templateUrl: './address-tax-dialogue.component.html'
})
export class AddressTaxDialogue implements OnInit {
    @Input() address: AddressDto;
    @Input() organisationId: number;

    @Output() complete = new EventEmitter<AddressDto>();

    addressForm: FormGroup;
    addressFields: FormlyFieldConfig[];

    taxForm: FormGroup;
    taxFields: FormlyFieldConfig[];
    options: FormlyFormOptions;

    taxAuthorities: OrganisationAuthorityGroup[];

    workingAddress: any;
    workingTax: { [authorityId: number]: string };

    private hasCompleted = false;

    public countries = countryStates;
    public states: string[] = [];

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    working = false;

    countryStateLookup = countryStates.reduce(
        (acc, c) => ({ ...acc, [c.iso]: c }),
        {}
    );

    constructor(
        private modal: BsModalRef<AddressTaxDialogue>,
        modalOptions: ModalOptions,
        private addressService: AddressService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private userLocationService: UserLocationService,
        private subscriptionService: SubscriptionService,
        private authorityService: OrganisationAuthorityService,
        private angulartics: Angulartics2,
        private changeDetector: ChangeDetectorRef,
        private organisationService: OrganisationService,
        private personService: PersonService
    ) {
        modalOptions.closeInterceptor = () => {
            this.angulartics.eventTrack.next({
                action: this.hasCompleted
                    ? 'purchase-address-done'
                    : 'purchase-address-cancel',
                properties: {
                    category: 'subscriptions'
                }
            });
            return preHide(this.addressForm, this.commonDialoguesService);
        };
    }

    ngOnInit() {
        this.addressForm = new FormGroup({});

        this.taxForm = new FormGroup({});

        this.buildFormlyFields();

        this.complete
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => (this.hasCompleted = true));

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    /**
     * Wait until the formly has initialised
     */
    ngAfterViewInit() {
        this.addressForm
            .get('country')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((country: Country) => {
                this.onCountryChanged(country);
                this.setupTaxFields(country?.iso);
            });

        if (this.address != null) {
            this.addressForm.patchValue({
                suburb: this.address.suburb || null,
                street: this.address.street || null,
                street2: this.address.street2 || null,
                state: this.address.state || null,
                postcode: this.address.postcode || null,
                country: findCountryByCode(this.address.country, countryStates),
                name: this.address.name || null,
                email: this.address.email || null
            });

            this.setupTaxFields(this.address.country);
        } else {
            this.getIPAddress(true, false);

            this.organisationService
                .findById(this.organisationId, this.organisationId)
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    switchMap(o =>
                        this.personService.findPerson(
                            o.ownerId,
                            this.organisationId
                        )
                    )
                )
                .subscribe({
                    next: owner =>
                        this.addressForm.patchValue({
                            email: owner.email
                        }),
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error refreshing personal details for address: ${error.message}`
                        )
                });
        }
    }

    ngOnDestroy() {
        this.complete.complete();

        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    buildFormlyFields() {
        this.addressFields = [
            {
                key: 'name',
                type: 'input',
                props: {
                    label: 'Name for Invoice',
                    required: true
                }
            },
            {
                key: 'country',
                type: 'country',
                props: {
                    label: 'Country',
                    required: true
                }
            },
            {
                key: 'street',
                type: 'input',
                props: {
                    label: 'Address Line 1',
                    required: true
                }
            },
            {
                key: 'street2',
                type: 'input',
                props: {
                    label: 'Address Line 2',
                    required: false
                }
            },
            {
                key: 'suburb',
                type: 'input',
                props: {
                    label: 'City/Suburb',
                    required: true
                }
            },
            {
                key: 'state',
                type: 'input',
                props: {
                    label: 'State',
                    required: true,
                    minLength: 1
                },
                hideExpression:
                    'model.country == null || model.country?.states?.length > 0'
            },
            {
                key: 'state',
                type: 'ng-select',
                props: {
                    label: 'State',
                    required: true,
                    options: this.states.map(s => {
                        return {
                            name: s,
                            label: s,
                            value: s
                        };
                    })
                },
                hideExpression:
                    'model.country == null || model.country?.states?.length === 0'
            },
            {
                key: 'postcode',
                type: 'input',
                props: {
                    label: 'Postal Code',
                    required: true
                }
            },
            {
                key: 'email',
                type: 'input',
                props: {
                    label: 'Billing Email Address',
                    required: true
                },
                validators: {
                    validation: [emailValidator]
                }
            }
        ];
    }

    setupTaxFields(countryCode: string) {
        if (countryCode == null || countryCode.length === 0) {
            this.taxFields = [];
            return;
        }

        this.subscriptionService
            .findTaxAuthorities(this.organisationId, countryCode)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorities => {
                this.taxAuthorities = authorities;
                this.taxFields = authorities.map(at => ({
                    key: at.id.toString(),
                    type: 'input',
                    defaultValue:
                        at.authorities.length > 0
                            ? at.authorities[0].identifier
                            : null,
                    props: {
                        label: at.name,
                        maxLength: 40
                    },
                    validators: {
                        validation:
                            at.identifierValidationRegex != null
                                ? [
                                      Validators.pattern(
                                          at.identifierValidationRegex
                                      )
                                  ]
                                : []
                    }
                }));
            });
    }

    onCountryChanged(country: Country) {
        if (country == null || country.states == null) {
            this.states = [];
            return;
        }

        this.states = country.states;
        const index = this.addressFields.findIndex(
            field => field.key === 'state' && field.type === 'ng-select'
        );
        this.addressFields[index].props.options = this.states.map(s => {
            return {
                name: s,
                label: s,
                value: s
            };
        });
        this.changeDetector.detectChanges();
    }

    onSubmit() {
        const form = this.addressForm.value;

        const forSaving: UpdateAddressCommand = {
            addressType: UpdateAddressCommand.AddressType.BILLING,
            country: form.country.iso ? form.country.iso : this.address.country,
            postcode: form.postcode,
            state: form.state,
            street: form.street,
            street2: form.street2 || '',
            suburb: form.suburb,
            name: form.name,
            email: form.email
        };

        const addressSave =
            this.address != null && this.address.id != null
                ? this.addressService.updateAddress(this.address.id, forSaving)
                : this.addressService
                      .createAddress({
                          ...forSaving,
                          organisationId: this.organisationId
                      })
                      .pipe(tap(address => (this.address = address)));

        addressSave
            .pipe(
                switchMap(address => {
                    const authoritySaves = this.prepareAuthoritySave();
                    if (authoritySaves.length === 0) {
                        return of({ address, authorities: [] });
                    } else {
                        return forkJoin(authoritySaves).pipe(
                            map(authorities => ({ address, authorities }))
                        );
                    }
                }),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: results => {
                    this.complete.emit(results.address);
                    this.logging.success('Changes saved');
                    this.addressForm.markAsPristine();
                    this.modal.hide();
                },
                error: error =>
                    this.logging.error(
                        error,
                        `There was an error while saving: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    private prepareAuthoritySave() {
        const values = this.taxForm.value;

        return this.taxAuthorities
            .filter(a => values[a.id] != null && values[a.id].length > 0)
            .filter(
                a =>
                    a.authorities.length === 0 ||
                    values[a.id] !== a.authorities[0].identifier
            )
            .map(a =>
                a.authorities.length > 0
                    ? this.authorityService.updateAuthority(
                          a.authorities[0].id,
                          {
                              startDate:
                                  a.authorities[0].startDate || '2020-01-01',
                              identifier: values[a.id],
                              requiresApprovalPolicy: null,
                              appliedEndorsementIdList: []
                          }
                      )
                    : this.authorityService
                          .newAuthority(
                              this.organisationId,
                              a.id,
                              this.organisationId,
                              null
                          )
                          .pipe(
                              mergeMap(saved =>
                                  this.authorityService.createAuthority(
                                      saved.id,
                                      {
                                          startDate:
                                              a.authorities[0].startDate ||
                                              '2020-01-01',
                                          identifier: values[a.id]
                                      }
                                  )
                              )
                          )
            );
    }

    hide() {
        this.modal.hide();
    }

    /**
     * Set class and place holder for selected country
     * @param country
     */
    onIpLookup(result: ResultDto) {
        this.addressForm.patchValue({
            country: findCountryByCode(result.value, countryStates)
            // state: result.regionName,
            // postcode: result.zip
        });
    }

    /**
     * Get IP address information and set the default country according to the response.
     * If API call fails in any case then set US as the default country.
     */
    async getIPAddress(findCountry: boolean, findPhone: boolean) {
        this.userLocationService
            .findUserLocation()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    if (findCountry) {
                        this.onIpLookup(result);
                    }
                },
                error: err => DO_NOTHING
            });
    }
}
