import { Component } from '@angular/core';
import {
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    FEATURE_OUTSTANDING_TASKS_VIEW,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    PersonsOrganisationDto,
    SimpleAuthorityTypeDto,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatDatePipe } from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { RegisterExpirySummary } from 'libs/authorities/src/lib/organisation-authority/organisation-authority-edit.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetRegistrationWithSection } from '../../../outstanding-tasks-view.service';
import {
    AuthorityTaskWidgetsDataService,
    formatAuthorityExpiryWidgetStatuses
} from '../authority-data.service';

const ORGANISATION_AUTHORITY_EXPIRY_IDENTIFIER =
    'organisationAuthoritiesExpiryTaskWidget';

interface TableAuthority {
    name: string;
    identifier: string;
    startDate: string;
    expiryDate: string;
    status: OrganisationAuthorityDto.ExpiryStatus;
    authority: OrganisationAuthorityDto;
    authorityType: SimpleAuthorityTypeDto;
}

@Component({
    selector: 'organisation-authorisation-expiries-widget',
    templateUrl: './organisation-authority-expiries-widget.component.html',
    styleUrls: ['../../widget-styles.scss'],
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()]
})
export class OrganisationAuthorityExpiriesWidgetComponent {
    organisation: PersonsOrganisationDto;
    showBody = true;

    authoritiesWithExpiry: TableAuthority[];
    registerSummaries: RegisterExpirySummary[];

    formatStatuses = formatAuthorityExpiryWidgetStatuses;

    //Static table variables
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;

    working = false;
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private authorityDataService: AuthorityTaskWidgetsDataService,
        private workspaceStateService: WorkspaceStateService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private formatDatePipe: FormatDatePipe
    ) {
        this.availableColumns = [
            {
                value: 'name',
                name: 'Authority',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.viewAuthority(row)
            },
            {
                value: 'identifier',
                name: 'Identifier',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'startDate',
                name: 'Start Date',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: d => this.formatDatePipe.transform(d)
            },
            {
                value: 'expiryDate',
                name: 'Expiry Date',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'status',
                name: 'Status',
                searchable: false,
                selectable: false,
                defaultSelection: true
            }
        ];

        this.selectedColumns = null;

        this.tableConfig = {
            limit: 3,
            actions: []
        };

        this.authorityDataService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
            });
        this.authorityDataService.currentOrganisationAuthorities$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorities => {
                this.buildTableData(authorities);
            });
        this.authorityDataService.authorityRegisterExpiries$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(registerSummaries => {
                this.registerSummaries = registerSummaries;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Takes the full list of authorities for the organsiation and filters out only authorities with expiry dates,
     * then recompiles them into a list of authorities displayable by the table.
     * @param authorities all of the authority groups for the organisation
     */
    buildTableData(authorities: OrganisationAuthorityGroup[]) {
        let count = 1;
        const withExpiries = authorities.reduce(
            (acc: TableAuthority[], authorityGroup) => {
                const authoritiesWithExpiry = authorityGroup.authorities
                    .filter(
                        a =>
                            a.expiryDate != null &&
                            (a.expiryStatus === 'EXPIRED' ||
                                a.expiryStatus === 'UPCOMING')
                    )
                    .map(
                        authority =>
                            <TableAuthority>{
                                authority: authority,
                                authorityType: <SimpleAuthorityTypeDto>(
                                    authorityGroup
                                ),
                                name: authorityGroup.name,
                                identifier: authority.identifier,
                                startDate: authority.startDate,
                                expiryDate: authority.expiryDate,
                                status: authority.expiryStatus
                            }
                    );
                return acc.concat(authoritiesWithExpiry);
            },
            []
        );
        this.authoritiesWithExpiry = withExpiries;
    }

    viewAuthority(authority: TableAuthority) {
        const modal = this.authorityDialoguesService.showAuthorityDetails(
            this.organisation.id,
            authority.authorityType,
            authority.authority,
            null,
            true,
            true,
            this.registerSummaries
        );
        modal.content.onShowRegister
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.showRegister(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority,
                    payload.register
                );
            });
    }
    showRegister(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto,
        register: AuthorityRegisterSummaryDto
    ) {
        this.authorityDialoguesService.showPersonnelRegisterDialogue(
            this.organisation.id,
            authorityType,
            <OrganisationAuthorityDto>authority,
            register,
            this.organisation.id
        );
    }
}

export const organisationAuthorityExpiryTaskWidgetRegistration: WidgetRegistrationWithSection =
    {
        widgetIdentifier: ORGANISATION_AUTHORITY_EXPIRY_IDENTIFIER,
        section: 'organisation',
        component: OrganisationAuthorityExpiriesWidgetComponent,
        isAvailable: organisation =>
            hasFeatureFlag(organisation, FEATURE_OUTSTANDING_TASKS_VIEW)
    };
