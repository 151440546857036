import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GqlQueryResult } from '.';
import { FlyFreelyConstants } from '../constants';
import {
    AnalysedFlightLogDto,
    AssignFlightLogCommand,
    CraftDto,
    CreateFlightsFromLogsCommand,
    FlightConformanceResultDto,
    FlightConformanceRuleResultDto,
    FlightLogCollectionSourceDto,
    FlightLogCounts,
    FlightLogCriteria,
    FlightLogFileDto,
    FlightLogFileWithSummary,
    FlightLogSummaryDto,
    LogAssignmentResult,
    MissionDto,
    PreSyncCheckCommand,
    PreSyncCheckResult,
    SimpleFlightLogCollectionSourceDto,
    SimpleOrganisationDto,
    SimplePersonDto,
    UserDebugReportCommand
} from '../model/api';
import { DownloadService } from './download.service';
import { computePath, httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class FlightLogsService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private downloadService: DownloadService,
        @Inject('Window') private window: Window,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findFlightLogForAnalyser(id: number, organisationId: number) {
        return this.apollo
            .query<{
                findFlightLogFiles: GqlQueryResult<FlightLogSummaryGQL & DebugReportListProperty>;
            }>({
                query: gql`
                    query findFlightLogFiles(
                        $criteria: FlightLogCriteria
                        $sortFields: [FlightLogSortField]
                        $pageSize: Int
                        $page: Int
                    ) {
                        findFlightLogFiles(
                            criteria: $criteria
                            pageSize: $pageSize
                            page: $page
                            sortFields: $sortFields
                        ) {
                            results {
                                id
                                collectionSource
                                collectionDeviceIdentifier
                                collectionDeviceName
                                collectionTime
                                conformanceResult {
                                    id
                                    policy {
                                        id
                                    }
                                    pilot {
                                        id
                                    }
                                    flight {
                                        id
                                    }
                                    sendTime
                                    status
                                    ruleResultList {
                                        id
                                        ruleVersion {
                                            id
                                            description
                                            defaultValue
                                            maximumValue
                                            rule {
                                                id
                                                type
                                            }
                                        }
                                        comparisonValue
                                        comparisonSource
                                        actualValue
                                        acknowledgementMessage
                                        acknowledgementTime
                                        status
                                    }
                                }
                                assignmentStatus
                                processedStatus
                                fileName
                                outOfDate
                                incorrectRpa
                                incorrectMission
                                rpa {
                                    id
                                    nickname
                                }
                                suggestedMissionAssignment {
                                    mission {
                                        id
                                        name
                                        missionDate
                                        timeZone
                                    }
                                    locationDistance
                                    timeDistance
                                }
                                summary {
                                    rpaType
                                    rpaName
                                    rpaSerialNumber
                                    batterySerialNumber
                                    startTime
                                    endTime
                                    startLongitude
                                    endLongitude
                                    startLatitude
                                    endLatitude
                                }
                                creationBy {
                                    lastName
                                    firstName
                                }
                                mission {
                                    id
                                    name
                                    missionDate
                                }
                                flight {
                                    id
                                    pilot {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                                flightLogCollectionSource {
                                    id
                                    type
                                    username
                                }
                                organisation {
                                    id
                                    name
                                    personalOrganisation
                                }
                                batteries {
                                    id
                                }
                                debugReportList {
                                    description
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria: {
                        idList: [id],
                        organisationId
                    },
                    pageSize: 1,
                    page: 0,
                    sortFields: []
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                map(flightLogs => flightLogs.data.findFlightLogFiles.results[0])
            );
    }

    findFlightLogsSummaries(
        organisationId: number,
        filters: FlightLogCriteria,
        sorting: { field: string; order: 'ASC' | 'DESC' },
        page: number,
        pageSize: number
    ) {
        const criteria = {
            organisationId,
            ...filters
        };
        return this.apollo
            .query<{
                findFlightLogFiles: GqlQueryResult<FlightLogSummaryGQL>;
            }>({
                query: gql`
                    query findFlightLogFiles(
                        $criteria: FlightLogCriteria
                        $sortFields: [FlightLogSortField]
                        $pageSize: Int
                        $page: Int
                    ) {
                        findFlightLogFiles(
                            criteria: $criteria
                            pageSize: $pageSize
                            page: $page
                            sortFields: $sortFields
                        ) {
                            count
                            results {
                                id
                                collectionSource
                                collectionDeviceIdentifier
                                collectionDeviceName
                                collectionTime
                                conformanceResult {
                                    id
                                    policy {
                                        id
                                    }
                                    pilot {
                                        id
                                    }
                                    flight {
                                        id
                                    }
                                    sendTime
                                    status
                                    ruleResultList {
                                        id
                                        ruleVersion {
                                            id
                                            description
                                            defaultValue
                                            maximumValue
                                            rule {
                                                id
                                                type
                                            }
                                        }
                                        comparisonValue
                                        comparisonSource
                                        actualValue
                                        acknowledgementMessage
                                        acknowledgementTime
                                        status
                                    }
                                }
                                assignmentStatus
                                processedStatus
                                fileName
                                outOfDate
                                incorrectRpa
                                incorrectMission
                                rpa {
                                    id
                                    nickname
                                }
                                suggestedMissionAssignment {
                                    mission {
                                        id
                                        name
                                        missionDate
                                        timeZone
                                    }
                                    locationDistance
                                    timeDistance
                                }
                                summary {
                                    rpaType
                                    rpaName
                                    rpaSerialNumber
                                    batterySerialNumber
                                    startTime
                                    endTime
                                    startLongitude
                                    endLongitude
                                    startLatitude
                                    endLatitude
                                }
                                creationBy {
                                    lastName
                                    firstName
                                }
                                mission {
                                    id
                                    name
                                    missionDate
                                }
                                flight {
                                    id
                                    pilot {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                                flightLogCollectionSource {
                                    id
                                    type
                                    username
                                }
                                organisation {
                                    id
                                    name
                                    personalOrganisation
                                }
                                batteries {
                                    id
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    pageSize,
                    page,
                    sortFields: sorting != null ? [sorting] : []
                },
                fetchPolicy: 'network-only'
            })
            .pipe(map(flightLogs => flightLogs.data.findFlightLogFiles));
    }

    findCounts(organisationId: number) {
        return this.http.get<FlightLogCounts>(
            `${this.baseUrl}/webapi/flightLogs/counts`,
            {
                params: httpParamSerializer({
                    organisationId
                })
            }
        );
    }

    findFlightLogSummaryById(flightLogId: number) {
        return this.http.get<FlightLogFileWithSummary>(
            `${this.baseUrl}/webapi/flightLogs/${flightLogId}/summary`
        );
    }

    analyseFlightLog(flightLogId: number) {
        return this.http.get<AnalysedFlightLogDto>(
            `${this.baseUrl}/webapi/flightLogs/${flightLogId}/analyse`
        );
    }

    assignFlightLog(flightLogId: number, command: AssignFlightLogCommand) {
        return this.http
            .put<LogAssignmentResult>(
                `${this.baseUrl}/webapi/flightLogs/${flightLogId}/assign`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    archiveFlightLog(flightLogId: number) {
        return this.http
            .put<FlightLogFileDto>(
                `${this.baseUrl}/webapi/flightLogs/${flightLogId}/archive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    unarchiveFlightLog(flightLogId: number) {
        return this.http
            .put<FlightLogFileDto>(
                `${this.baseUrl}/webapi/flightLogs/${flightLogId}/unarchive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    assignRpa(flightLogId: number, rpaId?: number) {
        return this.http
            .put<FlightLogFileDto>(
                `${this.baseUrl}/webapi/flightLogs/${flightLogId}/assignRpa`,
                { rpaId }
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    reassignRpa(flightLogId: number) {
        return this.http
            .put<FlightLogFileDto>(
                `${this.baseUrl}/webapi/flightLogs/${flightLogId}/reassignRpa`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteFlightLog(flightLogId: number) {
        return this.http
            .delete<void>(`${this.baseUrl}/webapi/flightLogs/${flightLogId}`)
            .pipe(tap(() => this.changeSource.next()));
    }

    reprocess(flightLogIds: number[]) {
        return this.http
            .post<FlightLogFileDto[]>(
                `${this.baseUrl}/webapi/flightLogs/reprocess`,
                flightLogIds
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    performPreSyncCheck(command: PreSyncCheckCommand) {
        return this.http
            .post<PreSyncCheckResult[]>(
                `${this.baseUrl}/webapi/flightLogs/preSyncCheck`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    upload(organisationId: number, file: File) {
        return this.http
            .post<FlightLogFileDto>(`${this.baseUrl}/webapi/flightLogs`, file, {
                headers: {
                    'Content-Type': file.type
                },
                params: httpParamSerializer({
                    organisationId,
                    fileName: file.name,
                    deviceName: '',
                    deviceIdentifier: ''
                })
            })
            .pipe(tap(() => this.changeSource.next()));
    }

    getCsvDownloadUrl(flightLogId: number): string {
        return computePath(
            `${this.baseUrl}/webapi/flightLogs/${flightLogId}/csv`
        );
    }

    getLogDownloadUrl(flightLogId: number): string {
        return computePath(`${this.baseUrl}/webapi/flightLogs/${flightLogId}`);
    }

    getKmlDownloadUrl(flightLogId: number): string {
        return computePath(
            `${this.baseUrl}/webapi/flightLogs/${flightLogId}/kml`
        );
    }

    getFlightLogFile(flightLogId: number) {
        this.downloadService.downloadFromUrl(
            this.getLogDownloadUrl(flightLogId)
        );
    }

    createFlightsFromLogs(command: CreateFlightsFromLogsCommand) {
        return this.http
            .post<LogAssignmentResult[]>(
                `${this.baseUrl}/webapi/flightLogs/createFlights`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    submitDebugReport(
        flightLogFileId: number,
        command: UserDebugReportCommand
    ) {
        return this.http.post<void>(
            `${this.baseUrl}/webapi/flightLogs/${flightLogFileId}/debugReport`,
            command
        );
    }
}

export interface FlightSummary {
    startTime: string;
    endTime: string;
    batterySerialNumber?: string;
    endBatteryLevel?: number;
    endBatteryTemperature?: number;
    endLatitude?: number;
    endLongitude?: number;
    maximumDistanceFromHome?: number;
    maximumDistanceFromPilot?: number;
    maximumHeight?: number;
    maximumSpeed?: number;
    photoCount?: number;
    rpaName?: string;
    rpaSerialNumber?: string;
    rpaType?: string;
    startBatteryLevel?: number;
    startBatteryTemperature?: number;
    startLatitude?: number;
    startLongitude?: number;
    totalDistance?: number;
    flight: FlightSummaryFlight[];
}

export interface FlightSummaryFlight {
    motorTime: number;
    rpaLatitude: number;
    rpaLongitude: number;
    rpaHeight: number;
    rpLatitude: number;
    rpLongitude: number;
    homeLatitude: number;
    homeLongitude: number;
    batteryTemperature: number;
    batteryLevel: number;
}

// FIXME this should be generated automatically from the GQL
export interface FlightLogSummaryGQL {
    id: number;
    collectionSource?: string;
    collectionDeviceIdentifier?: string;
    collectionDeviceName?: string;
    fileName?: string;
    contentType?: string;
    collectionTime?: string;
    creationTime?: string;
    latitude?: number;
    longitude?: number;
    organisation?: SimpleOrganisationDto;
    mission?: MissionDto;
    flight?: {
        id: number;
        pilot: SimplePersonDto;
    };
    rpa?: CraftDto;
    batteries: [{ id: number }];
    assignmentStatus: FlightLogFileDto.AssignmentStatus;
    processedStatus: FlightLogFileDto.ProcessedStatus;
    seen: boolean;
    outOfDate: boolean;
    incorrectRpa: boolean;
    incorrectMission: boolean;
    creationBy: SimplePersonDto;
    flightLogCollectionSource?: SimpleFlightLogCollectionSourceDto;
    summary?: FlightLogSummaryDto;
    suggestedMissionAssignment?: {
        mission: {
            id: number;
            name: string;
            missionDate: string;
            timeZone: string;
        };
        locationDistance: number;
        timeDistance: number;
    };
    conformanceResult?: {
        id: number;
        policy: {
            id: number;
        };
        pilot: {
            id: number;
        };
        flight: {
            id: number;
        };
        sendTime: string;
        status: FlightConformanceResultDto.Status;
        ruleResultList: {
            id: number;
            ruleVersion: {
                id: number;
                description: string;
                defaultValue: number;
                maximumValue: number;
                rule: {
                    id: number;
                    type: FlightConformanceRuleResultDto.Type;
                };
            };
            comparisonValue: number;
            comparisonSource: FlightConformanceRuleResultDto.ComparisonSource;
            actualValue: number;
            acknowledgementMessage: string;
            acknowledgementTime: string;
            status: FlightConformanceRuleResultDto.Status;
        }[];
    };
}

export interface DebugReportListProperty {
    debugReportList: { description: string }[];
}

export const FLIGHT_LOG_COLLECTION_TYPE_LIST = [
    { name: 'DJI Sync', value: FlightLogCollectionSourceDto.Type.DJI_CLOUD },
    {
        name: 'DroneDeploy',
        value: FlightLogCollectionSourceDto.Type.DRONE_DEPLOY
    },
    {
        name: 'Drone Harmony',
        value: FlightLogCollectionSourceDto.Type.DRONE_HARMONY
    },
    { name: 'Skydio', value: FlightLogCollectionSourceDto.Type.SKYDIO }
];
