/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.20-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The basic information about the authority register for listing purposes
 */
export interface AuthorityRegisterSummaryDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The name of the register
     */
    name: string;
    /**
     * The type of entity that the register contains
     */
    registerEntityType: AuthorityRegisterSummaryDto.RegisterEntityType;
    /**
     * What enforcement mode is the register in
     */
    registerMode: AuthorityRegisterSummaryDto.RegisterMode;
    /**
     * Does this register have current entries (these may not be active though)
     */
    isSetup: boolean;
}
export namespace AuthorityRegisterSummaryDto {
    export type RegisterEntityType = 'RPA' | 'PILOT';
    export const RegisterEntityType = {
        RPA: 'RPA' as RegisterEntityType,
        PILOT: 'PILOT' as RegisterEntityType
    };
    export type RegisterMode = 'DISABLED' | 'INFORM' | 'ENFORCE';
    export const RegisterMode = {
        DISABLED: 'DISABLED' as RegisterMode,
        INFORM: 'INFORM' as RegisterMode,
        ENFORCE: 'ENFORCE' as RegisterMode
    };
}



