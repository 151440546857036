import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { RegisterSideSheetService } from '../side-sheet/register-side-sheet.service';

@Component({
    selector: 'register-settings',
    template: `
        <form [formGroup]="registerSettingsForm">
            <label>Register Mode</label>
            <div
                class="input-group"
                btnRadioGroup
                formControlName="registerMode"
            >
                <label
                    class="btn btn-xs btn-pill"
                    btnRadio="DISABLED"
                    [disabled]="working"
                    >Disabled</label
                >
                <label
                    class="btn btn-xs btn-pill"
                    btnRadio="INFORM"
                    [disabled]="working"
                    >Inform</label
                >
                <span class="upcoming-feature" *ngIf="!working">
                    Enforce (Coming soon to Teams)
                </span>
                <!-- <label
                    class="btn btn-xs btn-pill"
                    btnRadio="ENFORCE"
                    [disabled]="working"
                    >Enforce</label
                > -->
            </div>
        </form>
    `,
    styles: [
        `
            .upcoming-feature {
                border-radius: 15px;
                padding: 3px 7px;
                font-size: 12px;
                line-height: 1.5;
                font-weight: normal;
                text-align: center;
                vertical-align: middle;
                border: 1px solid #ffd366;
                background-color: #ffb500;
                color: #363839 !important;
            }
        `
    ],
    host: {
        class: 'vertical-container fill'
    }
})
export class RegisterSettings {
    get registerSettingsForm() {
        return this.registerSideSheetService.registerSettingsForm;
    }

    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private registerSideSheetService: RegisterSideSheetService) {}
}
