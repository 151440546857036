<div class="certification-container">
    <ng-container *ngFor="let authorityGroup of authorityGroups">
        <div
            class="certification-content onboarding-frame"
            [ngClass]="{
                notfinished: !hasAuthority(authorityGroup),
                'not-clickable':
                    authorityGroup.issuingMechanism !== 'SELF_REPORTED'
            }"
        >
            <div
                *ngIf="hasAuthority(authorityGroup)"
                (click)="
                    editAuthority(authorityGroup, authorityGroup.authorities[0])
                "
            >
                <i class="far fa-check-circle indication_topright"></i>
                <h4>
                    {{ authorityGroup.name }}
                    <i
                        *ngIf="authorityGroup.description"
                        class="fas fa-info-circle info-tooltip"
                        [tooltip]="authorityGroup.description"
                        container="certification-content"
                    ></i>
                </h4>
                <h5>Issued by {{ authorityGroup.issuedBy.name }}</h5>
                <p>
                    Start Date:
                    {{
                        authorityGroup.authorities[0].startDate
                            ? (authorityGroup.authorities[0].startDate
                              | formatDate)
                            : '-'
                    }}
                </p>
                <p>
                    Expiry:
                    {{
                        authorityGroup.authorities[0].expiryDate
                            ? (authorityGroup.authorities[0].expiryDate
                              | formatDate)
                            : '-'
                    }}
                </p>
                <p
                    *ngIf="
                        authorityGroup.hasIdentifier &&
                        authorityGroup.authorities[0].identifier
                    "
                >
                    {{ authorityGroup.identifierLabel }}:
                    {{ authorityGroup.authorities[0].identifier }}
                </p>
                <p
                    class="help-block pre-wrap"
                    [innerHtml]="authorityGroup.helperText | autolink"
                ></p>
            </div>

            <div
                *ngIf="!hasAuthority(authorityGroup)"
                (click)="addAuthority(authorityGroup)"
            >
                <i class="far fa-check-circle indication_topright"></i>
                <h4>
                    {{ authorityGroup.name }}
                    <i
                        *ngIf="authorityGroup.description"
                        class="fas fa-info-circle info-tooltip"
                        [tooltip]="authorityGroup.description"
                        container="certification-content"
                    ></i>
                </h4>
                <h5>Issued by {{ authorityGroup.issuedBy.name }}</h5>

                <p
                    class="help-block pre-wrap"
                    [innerHtml]="authorityGroup.helperText | autolink"
                ></p>
            </div>

            <button
                class="btn sales-button"
                *ngIf="
                    authorityGroup.hasContactSales &&
                    !hasContactedSales(authorityGroup)
                "
                (click)="contactSales(authorityGroup)"
            >
                Contact Sales
            </button>
        </div>
    </ng-container>
    <div class="custom--btn">
        <button (click)="previousScreen()">Back</button>
        <button
            class="next"
            name="personal-primary_next"
            (click)="nextScreen()"
        >
            Next
        </button>
    </div>
</div>
