import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    CraftDetailsDto,
    CraftService,
    FlightLogCriteria,
    FlightLogFileDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import * as moment from 'moment-timezone';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    mergeMap,
    map,
    reduce,
    shareReplay,
    takeUntil
} from 'rxjs/operators';
import { FlightLogDataService } from '../flight-log-data.service';
import { FlightLogWithDuration } from '../flight-log-list/flight-log-list-dialogue.component';

interface TableLog extends FlightLogWithDuration {
    assignedRpa: string;
    canSelect: boolean;
}

@Component({
    selector: 'select-flight-logs',
    template: `
        <div screenAnalytics="flight-log-assign-log">
            <table
                *ngIf="flightLogs != null && tableFlightLogs != null"
                class="table centered-heading"
            >
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Collection Device</th>
                        <th>RPA Name</th>
                        <th>Assigned RPA</th>
                        <th>Start Time</th>
                        <th>Duration</th>
                    </tr>
                    <tr [formGroup]="searchGroup">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="input-group input-group-sm">
                                <input
                                    class="form-control"
                                    [ngClass]="{
                                        'input-clear-active': hasSearchValue()
                                    }"
                                    #datePicker="bsDaterangepicker"
                                    bsDaterangepicker
                                    formControlName="dateRange"
                                />
                                <span
                                    class="input-group-addon input-group-clear calendar"
                                    *ngIf="hasSearchValue()"
                                    (click)="clearSearch()"
                                >
                                    <i class="fal fa-times"></i>
                                </span>
                                <span
                                    class="input-group-addon btn-default"
                                    (click)="datePicker.toggle()"
                                    [attr.aria-expanded]="datePicker.isOpen"
                                >
                                    <i class="glyphicon glyphicon-calendar"></i>
                                </span>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tableFlightLogs">
                        <td>
                            <div class="checkbox">
                                <label *ngIf="item.canSelect == true">
                                    <input
                                        type="checkbox"
                                        [(checklist)]="selected"
                                        [checklistValue]="item"
                                        [disabled]="working"
                                    />
                                    <span class="cr"
                                        ><i
                                            class="cr-icon glyphicon glyphicon-ok"
                                        ></i>
                                    </span>
                                </label>
                                <label
                                    *ngIf="item.canSelect == false"
                                    tooltip="Only logs with assigned RPA's can be selected."
                                >
                                    <span class="fal fa-ban"></span>
                                </label>
                            </div>
                        </td>
                        <td></td>
                        <td>{{ item.collectionDeviceName }}</td>
                        <td>{{ item.summary?.rpaName }}</td>
                        <td>{{ item.assignedRpa }}</td>
                        <td>{{ item.summary?.startTime | formatDateTime }}</td>
                        <td>
                            {{
                                calculateDuration(
                                    item.summary?.startTime,
                                    item.summary?.endTime
                                ) | formatDuration
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                class="text-center"
                *ngIf="flightLogs && flightLogs.length < totalLength"
            >
                <button
                    class="btn btn-inline"
                    type="button"
                    [disabled]="working"
                    (click)="loadMore()"
                >
                    Load More
                </button>
            </div>
        </div>
        <div class="action-container">
            <button
                type="button"
                (click)="cancel()"
                class="btn btn-sm btn-default"
            >
                Cancel
            </button>
            <button
                type="button"
                (click)="done()"
                class="btn btn-sm btn-primary"
                angulartics2On="click"
                angularticsAction="assign-logs"
                angularticsCategory="flight-logs"
            >
                Assign
            </button>
        </div>
        <ngx-loading [show]="working"></ngx-loading>
    `,
    host: {
        class: 'container-with-footer',
        style: 'height: 100%;'
    },
    providers: [FlightLogDataService]
})
export class SelectFlightLogs {
    @Input() organisationId: number;
    @Input() startTime: string;
    @Input() endTime: string;
    @Input() timeZone: string;
    @Output()
    flightLogsSelected = new EventEmitter<FlightLogFileDto[]>();

    flightLogs: FlightLogFileDto[] = [];
    tableFlightLogs: TableLog[] = [];

    currentPage = 0;
    totalLength = 0;

    rpaSearchCache: {
        [rpaId: number]: Observable<CraftDetailsDto>;
    } = {};

    selected: FlightLogFileDto[] = [];

    searchGroup: FormGroup;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working = false;

    constructor(
        private craftService: CraftService,
        private flightLogDataService: FlightLogDataService,
        private changeDetector: ChangeDetectorRef
    ) {
        combineLatest([
            this.workTracker.observable,
            this.flightLogDataService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([working, gqlWorking]) => {
                this.working = working || gqlWorking;
                // This is needed because the parent modal uses onPush change detection
                this.changeDetector.markForCheck();
            });
    }

    ngOnInit() {
        this.searchGroup = new FormGroup({
            dateRange: new FormControl()
        });

        this.searchGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.search());

        this.flightLogDataService.flightLogs$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(flightLogs => {
                this.flightLogs = this.flightLogs.concat(flightLogs);
                this.setupFlightLogs(flightLogs);
            });
        this.flightLogDataService.totalItems$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(total => (this.totalLength = total));

        this.refreshFlightLogs();
    }

    refreshFlightLogs() {
        this.tableFlightLogs = [];
        const filters: FlightLogCriteria = {
            startTime: this.startTime,
            endTime: this.endTime,
            organisationId: this.organisationId
        };
        this.findFlightLogs(filters);
    }

    getFilters() {
        const searchValue = this.searchGroup.value;

        const start =
            searchValue.dateRange != null
                ? moment(searchValue.dateRange[0])
                      .tz(this.timeZone)
                      .startOf('day')
                      .toISOString()
                : this.startTime;
        const end =
            searchValue.dateRange != null
                ? moment(searchValue.dateRange[1])
                      .tz(this.timeZone)
                      .startOf('day')
                      .toISOString()
                : this.endTime;

        return {
            startTime: start ? start : this.startTime,
            endTime: end ? end : this.endTime,
            assignmentStatus: FlightLogCriteria.AssignmentStatus.UNASSIGNED,
            organisationId: this.organisationId
        } as FlightLogCriteria;
    }

    search() {
        this.currentPage = 0;
        this.flightLogs = [];
        this.tableFlightLogs = [];
        const filters = this.getFilters();
        this.findFlightLogs(filters);
    }

    findFlightLogs(filters: FlightLogCriteria) {
        this.flightLogDataService.findFlightLogs(
            this.currentPage,
            15,
            null,
            filters,
            this.organisationId
        );
    }

    loadMore() {
        this.currentPage++;
        const filters = this.getFilters();
        this.findFlightLogs(filters);
    }

    hasSearchValue() {
        return (
            this.searchGroup.controls.dateRange.value &&
            this.searchGroup.controls.dateRange.value.length > 0
        );
    }

    clearSearch() {
        this.searchGroup.controls.dateRange.patchValue('');
    }

    calculateDuration(startTime: string, endTime: string) {
        const start = moment(startTime);
        const end = moment(endTime);
        const time: number = moment.duration(end.diff(start)).asSeconds();
        if (time >= 0) {
            return time;
        }
        return 0;
    }

    setupFlightLogs(flightLogs: FlightLogWithDuration[]) {
        const doneWorking = this.workTracker.createTracker();
        if (flightLogs == null || flightLogs.length === 0) {
            doneWorking();
            return;
        }

        const lookupRpa = (rpaId: number) => {
            if (!(rpaId in this.rpaSearchCache)) {
                this.rpaSearchCache[rpaId] = this.craftService
                    .findById(rpaId, this.organisationId)
                    .pipe(shareReplay());
            }

            return this.rpaSearchCache[rpaId];
        };

        of(...flightLogs)
            .pipe(
                mergeMap(log =>
                    log.rpaId == null
                        ? of({
                              ...log,
                              assignedRpa: '',
                              canSelect: false
                          } as TableLog)
                        : lookupRpa(log.rpaId).pipe(
                              map(
                                  rpa =>
                                      ({
                                          ...log,
                                          assignedRpa: rpa.nickname,
                                          canSelect: true
                                      } as TableLog)
                              ),
                              catchError(err => {
                                  doneWorking();
                                  return of(err);
                              })
                          )
                ),
                reduce((acc, r) => acc.concat(r), [] as TableLog[]),
                takeUntil(this.ngUnsubscribe$),
                catchError(err => {
                    doneWorking();
                    return of(err);
                })
            )
            .subscribe(tableFlightLogs => {
                this.tableFlightLogs =
                    this.tableFlightLogs.concat(tableFlightLogs);
                doneWorking();
                this.changeDetector.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    done() {
        this.flightLogsSelected.emit(this.selected);
    }

    cancel() {
        this.flightLogsSelected.emit([]);
    }
}
