import { Injectable } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    PersonDto,
    PersonService,
    SimpleOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BehaviorSubject, ReplaySubject, Subject, forkJoin, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

function addOrganisationName(
    group: { organisationId: number; personnel: PersonDto[] },
    organisationName: string
) {
    return {
        organisationId: group.organisationId,
        personnel: group.personnel.map(p => ({
            ...p,
            organisationName
        }))
    };
}
interface LoadingState {
    state: 'LOADING';
}

interface LoadedState {
    state: 'LOADED';
}

interface ErrorState {
    state: 'ERROR';
    error: FlyFreelyError;
}

type States = LoadingState | LoadedState | ErrorState;

const ANY_ORGANISATIONS = 0;

interface PersonDtoWithOrganisation extends PersonDto {
    organisationName: string;
}

@Injectable()
export class NominatedPersonnelDataService {
    private ngUnsubscribe$ = new Subject<void>();

    private statusSubject = new BehaviorSubject<States>({ state: 'LOADING' });
    status$ = this.statusSubject.asObservable();

    private partialErrors$ = new BehaviorSubject<FlyFreelyError[]>([]);

    private sharedToSubject = new ReplaySubject<SimpleOrganisationDto[]>(1);
    sharedTo$ = this.sharedToSubject.asObservable();
    hasChildOrganisations = false;

    private authority: OrganisationAuthorityDto;

    personnel: { [organisationId: number]: PersonDtoWithOrganisation[] };

    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private personService: PersonService,
        private logging: FlyFreelyLoggingService,
        private workTracker: WorkTracker
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setup(authority: OrganisationAuthorityDto) {
        this.authority = authority;
        this.sharedTo$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sharedTo => {
                const ids = [this.authority.organisationId].concat(
                    sharedTo
                        .filter(o => o.id !== ANY_ORGANISATIONS)
                        .map(o => o.id)
                );
                this.loadPersonnel(ids);
            });
        this.refreshSharedTo();
    }

    private fetch(organisationId: number) {
        return this.personService
            .findPersonnel(organisationId)
            .pipe(map(personnel => ({ organisationId, personnel })));
    }

    private loadPersonnel(ids: number[]) {
        forkJoin(
            ids.map(id =>
                this.fetch(id).pipe(
                    map(g => addOrganisationName(g, 'This Organisation')),
                    catchError((error: FlyFreelyError) => {
                        const errors = this.partialErrors$.getValue();
                        errors.concat(error);
                        this.partialErrors$.next(errors);
                        return of(null);
                    })
                )
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: personnelByOrganisation => {
                    // partialErrors$ allows the function to continue as long as some of the calls succeeded
                    // If all the calls errored out then throw and stop.
                    const allErrors = this.partialErrors$.getValue();
                    allErrors.forEach(error => {
                        this.logging.error(
                            error,
                            `Error fetching personnel list for an organisation on the authority: ${error.message}`
                        );
                    });
                    if (allErrors.length === ids.length) {
                        this.statusSubject.next({
                            state: 'ERROR',
                            error: allErrors[0]
                        });
                        return;
                    }

                    const { personnel } = personnelByOrganisation
                        .filter(p => p != null)
                        .shift();
                    const personIdList = personnel.map(p => p.id);

                    this.personnel = personnelByOrganisation
                        .filter(p => p != null)
                        .reduce(
                            (acc, g) => ({
                                ...acc,
                                [g.organisationId]: g.personnel
                                    .filter(
                                        p => personIdList.indexOf(p.id) === -1
                                    )
                                    .concat(personnel)
                            }),
                            { 0: personnel }
                        );
                    this.statusSubject.next({ state: 'LOADED' });
                }
            })
            .add(this.workTracker.createTracker());
    }

    private refreshSharedTo() {
        this.organisationAuthorityService
            .findSharedTo(this.authority.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                sharedTo => {
                    if (sharedTo.length === 0) {
                        this.hasChildOrganisations = false;
                        this.sharedToSubject.next([]);
                        return;
                    }
                    this.hasChildOrganisations = true;
                    this.sharedToSubject.next(
                        [
                            {
                                name: 'Any',
                                id: ANY_ORGANISATIONS,
                                personalOrganisation: false
                            }
                        ].concat(sharedTo)
                    );
                },
                (error: FlyFreelyError) =>
                    this.statusSubject.next({ state: 'ERROR', error })
            )
            .add(this.workTracker.createTracker());
    }
}
