import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeModule } from '@circlon/angular-tree-component';
import {
    BodyCss,
    FlyFreelyHttpInterceptor,
    LoginManager,
    PageTitle,
    flyFreelyErrorHandlerProvider,
    initializeKeycloak,
    mobileCss
} from '@flyfreely-portal-ui/flyfreely';
import { ManageDashboardModule } from '@flyfreely-portal-ui/manage-dashboard';
import { OutstandingTasksModule } from '@flyfreely-portal-ui/outstanding-tasks';
import { ReportingModule } from '@flyfreely-portal-ui/reporting';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { Angulartics2Module } from 'angulartics2';
import { KeycloakAngularModule } from 'keycloak-angular';
import { ApiKeysModule } from 'libs/api-keys/src/lib/api-keys.module';
import { EquipmentModule } from 'libs/equipment/src/lib/equipment.module';
import {
    FRONTEND_APP,
    FlyFreelyDatepickerConfig,
    FlyFreelyDaterangepickerConfig,
    FlyFreelyPopoverConfig,
    FlyFreelyTooltipConfig,
    Frontend
} from 'libs/flyfreely/src/lib/app.config';
import { GraphQlModule } from 'libs/graph-ql/src/lib/graph-ql.module';
import { OnboardingModule } from 'libs/onboarding/src/lib/onboarding.module';
import { generalTasksWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/general-task/general-tasks-widget.component';
import { missionsAwaitingApprovalTaskWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/operations/missions-awaiting-approval/missions-awaiting-approval.component';
import { missionsAwaitingFinalisationTaskWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/operations/missions-awaiting-finalisation/missions-awaiting-finalisation.component';
import { reconcileFlightLogsTaskWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/operations/outstanding-flight-logs/outstanding-flight-logs.component';
import { organisationAuthorityExpiryTaskWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/organisation/authorisation-expiries/organisation-authority-expiries-widget.component';
import { assignedMaintenanceTaskWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/resources/assigned-maintenance/assigned-maintenance-widget.component';
import { rpaAuthoritiesTaskWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/resources/rpa-authorities/rpa-authorities-widget.component';
import { upcomingMaintenanceTaskWidgetRegistration } from 'libs/outstanding-tasks/src/lib/widgets/resources/upcoming-maintenance/upcoming-maintenance-widget.component';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { htmlValidationExtension } from 'libs/validation/src/lib/formly-html-validation';
import { formlyHtmlValidator } from 'libs/validation/src/lib/formValidators';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {
    BsDatepickerConfig,
    BsDatepickerModule,
    BsDaterangepickerConfig
} from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { setTheme } from 'ngx-bootstrap/utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing.component';
import { UserModule } from './user/user.module';
import { batteryListWidgetRegistration } from './widgets/batteries/battery-list-widget.component';
import { batterySetWidgetRegistration } from './widgets/batteries/battery-set-list-widget.component';
import { equipmentWidgetRegistration } from './widgets/equipment/equipment-widget.component';
import { incidentReportWidgetRegistration } from './widgets/incident-reports/incident-reports-widget.component';
import { libraryWidgetRegistration } from './widgets/libraries/library-list-widget.component';
import { locationsWidgetRegistration } from './widgets/locations/locations-widget.component';
import { maintenanceWidgetRegistration } from './widgets/maintenance/maintenance-widget.component';
import { organisationLinksWidgetRegistration } from './widgets/organisation-links/organisation-links-widget.component';
import { personnelWidgetRegistration } from './widgets/personnel/personnel-widget.component';
import { riskRegisterWidgetRegistration } from './widgets/risk-register/risk-register-widget.component';
import { craftWidgetRegistration } from './widgets/rpa/rpa-list-widget.component';
import { WelcomeWidgetRegistration } from './widgets/welcome/welcome-widget.component';
import { WidgetsModule } from './widgets/widgets.module';
import { SignupToDemoComponent } from './signup-to-demo/signup-to-demo.component';

export const APP_FRONTEND_CONFIG: Frontend = 'user';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        // ngx-bootstrap
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        AccordionModule.forRoot(),
        TimepickerModule.forRoot(),
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PaginationModule.forRoot(),
        CollapseModule.forRoot(),
        NgxLoadingModule.forRoot({}),
        NgxStripeModule.forRoot(),
        TreeModule,
        // ngx-formly
        ReactiveFormsModule,
        FormlyModule.forRoot({
            extensions: [
                {
                    name: 'html-validation',
                    extension: htmlValidationExtension
                }
            ],
            validators: [{ name: 'html', validation: formlyHtmlValidator }]
        }),
        FormlyBootstrapModule,
        // ng-select
        NgSelectModule,
        ToastrModule.forRoot(),
        SharedUiModule,
        ValidationModule,
        OnboardingModule,
        WidgetsModule,
        ApiKeysModule,
        EquipmentModule,
        KeycloakAngularModule,
        OutstandingTasksModule.forRoot([
            missionsAwaitingApprovalTaskWidgetRegistration,
            missionsAwaitingFinalisationTaskWidgetRegistration,
            reconcileFlightLogsTaskWidgetRegistration,
            upcomingMaintenanceTaskWidgetRegistration,
            assignedMaintenanceTaskWidgetRegistration,
            rpaAuthoritiesTaskWidgetRegistration,
            organisationAuthorityExpiryTaskWidgetRegistration,
            generalTasksWidgetRegistration
        ]),
        ManageDashboardModule.forRoot([
            WelcomeWidgetRegistration,
            craftWidgetRegistration,
            personnelWidgetRegistration,
            batteryListWidgetRegistration,
            batterySetWidgetRegistration,
            locationsWidgetRegistration,
            libraryWidgetRegistration,
            riskRegisterWidgetRegistration,
            organisationLinksWidgetRegistration,
            equipmentWidgetRegistration,
            incidentReportWidgetRegistration,
            maintenanceWidgetRegistration
        ]),
        UserModule,
        Angulartics2Module.forRoot({
            pageTracking: {
                clearQueryParams: true,
                clearIds: true
            }
        }),
        ReportingModule,
        GraphQlModule
    ],
    providers: [
        { provide: 'Window', useValue: window },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [LoginManager]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => setTheme('bs3'),
            multi: true,
            deps: []
        },
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    document: Document,
                    deviceDetectorService: DeviceDetectorService
                ) =>
                () =>
                    mobileCss(document, deviceDetectorService),
            multi: true,
            deps: [DOCUMENT, DeviceDetectorService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FlyFreelyHttpInterceptor,
            multi: true
        },
        {
            provide: TooltipConfig,
            useClass: FlyFreelyTooltipConfig
        },
        {
            provide: PopoverConfig,
            useClass: FlyFreelyPopoverConfig
        },
        {
            provide: BsDatepickerConfig,
            useClass: FlyFreelyDatepickerConfig
        },
        {
            provide: BsDaterangepickerConfig,
            useClass: FlyFreelyDaterangepickerConfig
        },
        {
            provide: FRONTEND_APP,
            useValue: APP_FRONTEND_CONFIG
        },
        PageTitle,
        BodyCss,
        Title,
        flyFreelyErrorHandlerProvider
    ],
    bootstrap: [AppComponent],
    declarations: [
        LandingComponent,
        AppComponent,
        SignupToDemoComponent
    ]
})
export class AppModule {}
