import {
    buildFormlyField,
    calculateSectionKey,
    convertToColumn,
    defaultBuildFormAttachmentUrl,
    FormColumn,
    FormSectionDto
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * Build a new section that includes a section heading and a description.
 */
function buildFormlySection(
    section: FormSectionDto,
    formId: number,
    managingOrganisationId: number,
    buildFormAttachmentUrl: typeof defaultBuildFormAttachmentUrl
) {
    const fieldSection: FormlyFieldConfig = {
        key: `section-${section.id}`,
        type: 'section-heading',
        props: {
            label: section.name,
            text: section.description
        }
    };
    if (section.repeatingGroup) {
        const controlType =
            section.layout === FormSectionDto.Layout.TABLE
                ? 'table'
                : 'twelve-column';
        const converter =
            section.layout === FormSectionDto.Layout.TABLE
                ? convertToColumn
                : (field: FormlyFieldConfig) => field;

        const tableGroup = {
            key: calculateSectionKey(section),
            type: controlType,
            props: {
                label: section.name,
                text: section.description,
                columns: section.controls.map(
                    field =>
                        ({
                            name: field.label,
                            key: field.id.toString()
                        } as FormColumn)
                )
            },
            fieldArray: {
                fieldGroup: section.controls
                    .map(field =>
                        buildFormlyField(field, formId, managingOrganisationId, buildFormAttachmentUrl)
                    )
                    .filter(c => c != null)
                    .map(converter)
            }
        };
        return [tableGroup];
    } else {
        return [fieldSection].concat(
            section.controls
                .map(field =>
                    buildFormlyField(field, formId, managingOrganisationId, buildFormAttachmentUrl)
                )
                .filter(c => c != null)
        );
    }
}

/**
 * Builds formly fields from FlyFreely form sections.
 *
 */
export function buildFormFields(
    sections: FormSectionDto[],
    formId: number,
    managingOrganisationId: number,
    buildFormAttachmentUrl = defaultBuildFormAttachmentUrl
): FormlyFieldConfig[] {
    return [].concat(
        ...sections.map(section =>
            buildFormlySection(section, formId, managingOrganisationId, buildFormAttachmentUrl)
        )
    );
}
