import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'onboarding-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    // Displays the FlyFreely logo in white
}
