import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisteredWidgets } from '@flyfreely-portal-ui/workspace';
import { DashboardComponent } from './dashboard.component';
import { WidgetSelectionComponent } from './widget-selection/widget-selection.component';
import { SubscriptionBanner } from './subscription-banner.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WidgetModule, WidgetRegistration } from '@flyfreely-portal-ui/widget';
import { SharedUiModule } from '@flyfreely-portal-ui/ui';
import { AnalyticsModule } from '@flyfreely-portal-ui/analytics';
import { MaterialModule } from '@blox/material';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild([]),
        WidgetModule,
        SharedUiModule,
        AnalyticsModule,
        MaterialModule
    ],
    declarations: [
        DashboardComponent,
        WidgetSelectionComponent,
        SubscriptionBanner
    ]
})
export class ManageDashboardModule {
    static forRoot(
        registeredWidgets: WidgetRegistration[]
    ): ModuleWithProviders<any> {
        return {
            ngModule: ManageDashboardModule,
            providers: [
                // FIXME figure out how to properly inject tokens
                {
                    provide: RegisteredWidgets,
                    useValue: new RegisteredWidgets(registeredWidgets)
                }
            ]
        };
    }
}
