import { Injectable } from '@angular/core';
import {
    RpaTypeWorkflowDto,
    RpaTypeWorkflowService,
    MaintenanceScheduleService,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    PersonsOrganisationDto,
    RpaTypeConfigDto,
    RpaTypeConfigService,
    RpaTypeDto,
    RpaTypesService,
    SimpleMaintenanceScheduleDto,
    NameIdArchived
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
    filter,
    map,
    shareReplay,
    startWith,
    switchMap,
    takeUntil
} from 'rxjs/operators';

export const NO_AUTHORITY_ID = 0;

@Injectable()
export class ResourceMaintenanceDataService {
    rpaTypeList$: Observable<RpaTypeDto[]>;
    rpaTypeConfigList$: Observable<RpaTypeConfigDto[]>;
    maintenanceScheduleList$: Observable<SimpleMaintenanceScheduleDto[]>;
    craftModelWorkflowList$: Observable<RpaTypeWorkflowDto[]>;
    organisationAuthoritiesList$: Observable<NameIdArchived[]>;

    private organisation$ = new Observable<PersonsOrganisationDto>();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        rpaTypesService: RpaTypesService,
        rpaTypeConfigService: RpaTypeConfigService,
        maintenanceScheduleService: MaintenanceScheduleService,
        craftModelWorkflowService: RpaTypeWorkflowService,
        organisationAuthorityService: OrganisationAuthorityService,
        workspaceStateService: WorkspaceStateService
    ) {
        this.organisation$ = workspaceStateService.currentOrganisation$.pipe(
            takeUntil(this.ngUnsubscribe$),
            filter(event => event.type === 'organisation_loaded'),
            map((event: CurrentOrganisation) => event.organisation),
            shareReplay(1)
        );

        this.rpaTypeList$ = combineLatest([
            this.organisation$,
            rpaTypesService.change$.pipe(startWith([undefined]))
        ]).pipe(
            switchMap(([org, _]) => rpaTypesService.findRpaTypes(org.id)),
            shareReplay(1),
            takeUntil(this.ngUnsubscribe$)
        );

        this.rpaTypeConfigList$ = combineLatest([
            this.organisation$,
            rpaTypeConfigService.change$.pipe(startWith([undefined]))
        ]).pipe(
            switchMap(([org, _]) => rpaTypeConfigService.find(org.id)),
            shareReplay(1),
            takeUntil(this.ngUnsubscribe$)
        );

        this.maintenanceScheduleList$ = combineLatest([
            this.organisation$,
            maintenanceScheduleService.change$.pipe(startWith([undefined]))
        ]).pipe(
            switchMap(([org, _]) => maintenanceScheduleService.find(org.id)),
            shareReplay(1),
            takeUntil(this.ngUnsubscribe$)
        );

        this.craftModelWorkflowList$ = combineLatest([
            this.organisation$,
            craftModelWorkflowService.change$.pipe(startWith([undefined]))
        ]).pipe(
            switchMap(([org, _]) =>
                craftModelWorkflowService.findWorkflows(org.id)
            ),
            shareReplay(1),
            takeUntil(this.ngUnsubscribe$)
        );

        this.organisationAuthoritiesList$ = combineLatest([
            this.organisation$,
            organisationAuthorityService.change$.pipe(startWith([undefined]))
        ]).pipe(
            switchMap(([org, _]) =>
                organisationAuthorityService.findAuthorities(org.id, org.id)
            ),
            map(authorities => this.parseAuthorities(authorities)),
            shareReplay(1),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private parseAuthorities(authorities: OrganisationAuthorityGroup[]) {
        return [
            {
                id: NO_AUTHORITY_ID,
                name: 'Organisation Default',
                archived: false
            }
        ].concat(
            authorities
                .filter(g => g.hasWorkflow)
                .reduce(
                    (acc, g) =>
                        acc.concat(
                            g.authorities.map(
                                a =>
                                    ({
                                        id: a.id,
                                        name: `${
                                            g.abbreviation != null
                                                ? g.abbreviation
                                                : g.name
                                        }${
                                            a.identifier
                                                ? ` ${a.identifier}`
                                                : ''
                                        } ${a.startDate}`,
                                        archived: a?.archived
                                    } as NameIdArchived)
                            )
                        ),
                    []
                )
        );
    }
}
