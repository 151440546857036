/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Their role
 */
export interface MissionRoleDto { 
    id?: number;
    name?: string;
    enabled?: boolean;
    organisationId?: number;
    order?: number;
    required?: boolean;
    numberOfInstances?: number;
    coreRole?: MissionRoleDto.CoreRole;
}
export namespace MissionRoleDto {
    export type CoreRole = 'PILOT_IN_COMMAND' | 'PILOT';
    export const CoreRole = {
        PILOT_IN_COMMAND: 'PILOT_IN_COMMAND' as CoreRole,
        PILOT: 'PILOT' as CoreRole
    };
}


