import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    AttachmentViewService,
    DocumentPreviewPayload
} from '../documentPreview.service';

@Component({
    selector: 'document-preview-dialogue',
    templateUrl: './document-preview-dialogue.component.html'
})
export class DocumentPreviewDialogueComponent implements OnInit {
    fileUrl: SafeResourceUrl | null = null;
    url: string;
    contentType: string;
    isReady: boolean = false;
    textContent: string;
    @Output() cancel = new EventEmitter<void>();
    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private attachmentViewService: AttachmentViewService,
        private modal: BsModalRef<DocumentPreviewDialogueComponent>,
        private sanitizer: DomSanitizer
    ) {}

    attachment$: Observable<DocumentPreviewPayload>;

    ngOnInit(): void {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.attachment$ = this.attachmentViewService.attachment$;
        this.attachment$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(attachment => {
                this.url = attachment.downloadUrl;
                this.contentType = attachment.contentType;
                this.getFile(this.url, this.contentType);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    close() {
        this.modal.hide();
    }

    getFileIcon(): string {
        switch (this.contentType) {
            case 'application/pdf':
                return 'fas fa-file-pdf icon-red';
            case 'text/plain':
                return 'fas fa-file-alt icon-red';
            case 'image/jpeg':
            case 'image/png':
                return 'fas fa-file-image icon-red';
            case 'video/mp4':
            case 'video/mpeg':
                return 'fas fa-file-video icon-red';
            default:
                return 'fas fa-file icon-red';
        }
    }

    getFile(url: string, contentType: string): void {
        this.attachmentViewService
            .fetchFile(url)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(blob => {
                if (contentType === 'text/plain') {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.textContent = reader.result as string;
                    };
                    reader.onerror = error => {
                        console.error('Error reading text file:', error);
                    };
                    reader.readAsText(blob);
                } else {
                    let objectUrl = window.URL.createObjectURL(blob);
                    if (contentType === 'application/pdf') {
                        objectUrl += '#toolbar=0';
                    }
                    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                        objectUrl
                    );
                }
                this.isReady = true;
            })
            .add(this.workTracker.createTracker());
    }
    isPdfFile(payLoad: DocumentPreviewPayload): boolean {
        // Check if the contentType of the DocumentPreviewPayload is a PDF
        return payLoad.contentType === 'application/pdf';
    }

    isImageFile(payLoad: DocumentPreviewPayload): boolean {
        // Check if the contentType of the attachment is an image
        return payLoad.contentType.startsWith('image');
    }
    isVideoFile(payLoad: DocumentPreviewPayload): boolean {
        // Check if the contentType of the attachment is an video
        return payLoad.contentType.startsWith('video');
    }

    isTextFile(payLoad: DocumentPreviewPayload): boolean {
        // Check if the contentType of the attachment is plain text
        return payLoad.contentType === 'text/plain';
    }
}
