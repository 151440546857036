import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalContainer } from '@flyfreely-portal-ui/ngx-bootstrap-customisation';
import { FlightLogListDialogue } from '../flight-log-list/flight-log-list-dialogue.component';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: ModalContainer,
                data: {
                    modalContent: FlightLogListDialogue,
                    modalClass: 'modal-lg'
                }
                /* children: [
              // FIXME: Flight log settings should be a child route, but it's not working with the modal services properly and breaks the route
              {
                  // Using matcher instead of path so this can be nested in any way
                  matcher: (
                      segments: UrlSegment[],
                      group: UrlSegmentGroup,
                      route: Route
                  ) => {
                      return segments != null &&
                          segments.find(s => s.path === 'settings') != null
                          ? { consumed: segments }
                          : null;
                  },
                  // path: 'settings',
                  component: ModalContainer,
                  data: {
                      modalContent: FlightLogSettings,
                      modalClass: 'modal-task modal-lg',
                      parentRoute: '/home/missions/flightlogs'
                  },
                  resolve: {
                      paramMap: FullParamMapResolver
                  }
              }
          ] */
            }
        ])
    ],
    exports: [RouterModule]
})
export class FlightLogsRoutingModule {}
