import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    emailValidator,
    FEATURE_EXTERNAL_MAINTENANCE_PERSON,
    FEATURE_HIDDEN_READ_ONLY_ROLE,
    FEATURE_INSTANT_MISSIONS,
    FEATURE_PERSONNEL_BULK_UPLOADS,
    FEATURE_READ_ONLY_ROLE,
    FEATURE_STUDENT_ROLE,
    findOrganisation,
    FlyFreelyError,
    FlyFreelyLoggingService,
    hasFeatureFlag,
    InvitesService,
    OrganisationSubscriptionDto,
    PersonRolesDto,
    SendInviteCommand,
    SubscriptionLimitDto,
    SubscriptionService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { OrganisationSubscriptionsService } from '@flyfreely-portal-ui/workspace';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { isPersonalOrganisationSubscription } from 'libs/subscriptions/src/lib/helpers';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createRolesFormGroup, toRoleList } from '../helpers';
import { PersonUploadService } from './person-upload.service';

@Component({
    selector: 'personnel-add-dialogue',
    templateUrl: './person-add-dialogue.component.html',
    providers: [PersonUploadService]
})
export class PersonnelAddDialogue implements OnInit, OnDestroy {
    @Input() organisationId: number;

    allowStudent: boolean;
    allowReadOnly: boolean;
    allowInstantMission: boolean;
    allowReadOnlyHidden: boolean;
    allowFlightLogCollection: boolean;
    allowExternalMaintenancePerson: boolean;
    availablePersonnel: number;
    initialAvailablePilots: number;
    availablePilots: number;
    totalPilots: number;
    pilotLimitReached: boolean;
    canAdd = true;
    overLimits: boolean;

    isPersonalOrganisationSubscription = false;
    availableSubscriptions: OrganisationSubscriptionDto[] = [];
    selectedSubscription: OrganisationSubscriptionDto;

    personNewForm: FormGroup;
    personInviteForm: FormGroup;

    invitees: string[];

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    canBulkUpload = false;

    constructor(
        private modal: BsModalRef<PersonnelAddDialogue>,
        private personUploadService: PersonUploadService,
        private userService: UserService,
        private invitesService: InvitesService,
        private subscriptionService: SubscriptionService,
        private organisationSubscriptionsService: OrganisationSubscriptionsService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.invitees = [];

        this.refreshPermissions();

        this.personNewForm = new FormGroup({
            person: new FormGroup({}),
            roles: createRolesFormGroup(false, true)
        });

        this.personInviteForm = new FormGroup({
            email: new FormControl('', emailValidator),
            roles: createRolesFormGroup(false, true),
            selectedSubscriptionId: new FormControl(undefined)
        });

        this.refreshSubscriptions();

        this.personInviteForm.controls.selectedSubscriptionId.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(selectedSubscriptionId => {
                this.selectedSubscription = this.availableSubscriptions.find(
                    s => s.id === selectedSubscriptionId
                );
                this.setupSubscriptionLimits();
            });

        this.personInviteForm.controls.roles.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                if (value.PILOT && this.availablePilots != null) {
                    this.availablePilots =
                        this.initialAvailablePilots - this.invitees.length;
                }
                if (!value.PILOT && this.availablePilots != null) {
                    this.availablePilots = this.initialAvailablePilots;
                    this.personInviteForm.get('roles').patchValue(
                        {
                            PILOT_CREW_PLANNER: false,
                            PILOT_PLANNER: false,
                            PILOT_SUBMITTER: false
                        },
                        { emitEvent: false }
                    );
                }
                this.calculatePermission();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        const organisation = findOrganisation(
            this.userService.findUsersOrganisations(),
            this.organisationId
        );

        const isSystemAdmin =
            this.userService.getCurrentUser().type === 'SYSTEM_ADMIN';
        this.canBulkUpload =
            isSystemAdmin ||
            pipe(
                organisation,
                map(o => hasFeatureFlag(o, FEATURE_PERSONNEL_BULK_UPLOADS)),
                getOrElse(() => false)
            );

        this.allowStudent = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_STUDENT_ROLE)),
            getOrElse(() => false)
        );

        this.allowReadOnly = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_READ_ONLY_ROLE)),
            getOrElse(() => false)
        );

        this.allowInstantMission = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_INSTANT_MISSIONS)),
            getOrElse(() => false)
        );

        this.allowReadOnlyHidden = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_HIDDEN_READ_ONLY_ROLE)),
            getOrElse(() => false)
        );

        this.allowExternalMaintenancePerson = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_EXTERNAL_MAINTENANCE_PERSON)),
            getOrElse(() => false)
        );
    }

    refreshSubscriptions() {
        const doneWorking = this.workTracker.createTracker();
        combineLatest([
            this.organisationSubscriptionsService.currentSubscriptions$,
            this.subscriptionService.findCurrentSubscriptions(
                this.organisationId
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([currentSubscription, subscriptions]) => {
                    this.isPersonalOrganisationSubscription =
                        isPersonalOrganisationSubscription(currentSubscription);
                    // Can't use the isPersonalOrganisationSubscription check here, as it doesn't pick up the type sensing properly.
                    if (
                        !isPersonalOrganisationSubscription(currentSubscription)
                    ) {
                        this.availableSubscriptions = subscriptions.filter(
                            s =>
                                s.status ===
                                OrganisationSubscriptionDto.Status.ACTIVE
                        );
                        if (this.selectedSubscription == null) {
                            this.selectedSubscription =
                                currentSubscription.subscription;
                        } else {
                            this.selectedSubscription =
                                this.availableSubscriptions[0];
                        }
                        this.personInviteForm.controls.selectedSubscriptionId.patchValue(
                            this.selectedSubscription.id
                        );
                    }
                    this.setupSubscriptionLimits();
                    // FIXME: Using the normal worktracker on teardown, either in this subscription's complete, or in a .add() caused the
                    // component to get stuck in the working state.
                    doneWorking();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while refreshing subscriptions: ${error.message}`
                    )
            });
    }

    setupSubscriptionLimits() {
        const subscription = this.selectedSubscription;
        // Don't calculate limits if there are none
        if (
            !this.isPersonalOrganisationSubscription &&
            subscription?.limits?.length > 0
        ) {
            this.availablePersonnel =
                subscription.limits[0].licencedEntityType ===
                SubscriptionLimitDto.LicencedEntityType.PERSONNEL
                    ? subscription.limits[0].availableLicenceCount
                    : null;
            this.availablePilots =
                subscription.limits[0].licencedEntityType ===
                SubscriptionLimitDto.LicencedEntityType.PILOT
                    ? subscription.limits[0].availableLicenceCount
                    : null;
            this.initialAvailablePilots =
                subscription.limits[0].licencedEntityType ===
                SubscriptionLimitDto.LicencedEntityType.PILOT
                    ? subscription.limits[0].availableLicenceCount
                    : 0;
            this.totalPilots =
                subscription.limits[0].licencedEntityType ===
                SubscriptionLimitDto.LicencedEntityType.PILOT
                    ? subscription.limits[0].instances
                    : 0;
            this.pilotLimitReached =
                subscription.limits[0].licencedEntityType ===
                SubscriptionLimitDto.LicencedEntityType.PILOT
                    ? subscription.limits[0].availableLicenceCount === 0
                    : true;
        }
        this.calculatePermission();
    }

    addInvitee() {
        const invitee: string =
            this.personInviteForm.controls.email.value.trim();

        if (!invitee.includes('@') || !invitee.includes('.')) {
            this.logging.error(null, 'Please enter a valid email address');
            return;
        }

        this.invitees.push(invitee);
        if (
            this.personInviteForm.value.roles.PILOT &&
            this.availablePilots != null
        ) {
            this.availablePilots =
                this.initialAvailablePilots - this.invitees.length;
        }
        this.calculatePermission();
        this.personInviteForm.controls.email.reset();
        this.personInviteForm.controls.email.markAsPristine();
        return;
    }

    invite() {
        const { roles } = this.personInviteForm.value;

        const roleList = toRoleList(roles);
        if (roleList.length === 0) {
            this.logging.error(
                null,
                'Please select at least one role from the role list.'
            );
            return;
        }

        this.invitees.map(invitee => {
            const command: SendInviteCommand = {
                organisationId: this.organisationId,
                recipientEmail: invitee,
                recipientRoles: roleList as PersonRolesDto.Roles[],
                specificSubscriptionId: this.selectedSubscription.id
            };
            this.invitesService
                .sendInvite(command)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    result => {
                        this.deleteInvitee(result.recipientEmail);
                        if (this.invitees.length === 0) {
                            this.invitees = [];
                            this.subscriptionService.refreshLicences();
                            this.personInviteForm.reset();
                            this.personInviteForm.markAsPristine();
                            this.logging.success('All invites have been sent');
                        }
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while inviting ${command.recipientEmail}: ${error.message}`
                        );
                    }
                )
                .add(this.workTracker.createTracker());
        });
    }

    deleteInvitee(item: string) {
        const index = this.invitees.findIndex(inv => inv === item);
        this.invitees.splice(index, 1);
        if (
            this.personInviteForm.value.roles.PILOT &&
            this.availablePilots != null
        ) {
            this.availablePilots =
                this.initialAvailablePilots - this.invitees.length;
        }
        this.calculatePermission();
    }

    calculatePermission() {
        const personnelLimit =
            this.availablePersonnel != null &&
            this.invitees.length >= this.availablePersonnel;
        const pilotLimit =
            this.availablePilots != null &&
            this.availablePilots <= 0 &&
            this.personInviteForm.controls.roles.value.PILOT;
        this.canAdd = !personnelLimit || !pilotLimit;

        const overPersonnel =
            this.availablePersonnel != null &&
            this.invitees.length > this.availablePersonnel;
        const overPilot =
            this.availablePilots != null && this.availablePilots < 0;
        this.overLimits = overPersonnel || overPilot;
    }

    cancel() {
        this.modal.hide();
    }

    invalidFileSelection(files: File[]) {
        if (files == null) {
            return;
        }
        this.logging.error(null, 'This file type is not supported');
    }

    bulkUpload() {
        this.personUploadService.showBulkUpload(this.organisationId);
        this.personUploadService.doneImporting$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.modal.hide());
    }
}
