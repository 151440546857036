import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Country } from 'libs/addresses/src/lib/address-edit/address-edit-dialogue.component';
import CountryStates from 'libs/ui/src/lib/data/country-states.json';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

function loadSortedCountries() {
    const countries: Country[] = CountryStates as Country[];
    countries.sort((a, b) => a.name.localeCompare(b.name));
    return countries;
}

const countryStates: Country[] = loadSortedCountries();

@Component({
    selector: 'formly-country-select',
    template: `
        <div class="form-group">
            <ng-select
                [items]="countries"
                [clearable]="!to.required"
                bindLabel="name"
                [(ngModel)]="country"
                (ngModelChange)="selectCountry($event)"
                appendTo="body"
            >
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <div style="display: flex; align-items: center;">
                        <span
                            class="flag fg_{{ item.iso.toLowerCase() }}"
                        ></span>
                        <span>&nbsp;{{ item.name }}</span>
                    </div>
                </ng-template>
            </ng-select>
        </div>
    `
})
export class FormlyCountrySelect extends FieldType {
    countries = countryStates;

    country: Country;

    private ngUnsubscribe$ = new Subject<void>();

    controlSubscription: Subscription;

    ngOnInit() {
        this.controlSubscription = this.formControl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(v => this.parseCountry(v));
        this.parseCountry(this.formControl.value);
    }

    parseCountry(value: Country) {
        if (value == null) {
            return;
        }
        if (!value.iso && typeof value === 'string') {
            this.country = this.countries.find(
                c => c.iso === value || c.name === value
            );
            return;
        }
        this.country = value;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    set value(value: Country) {
        this.country = value;
        this.storeValue();
    }

    get value() {
        return this.country;
    }

    private setCountry(country: Country) {
        this.country = country;
        this.storeValue();
    }

    selectCountry(country: Country) {
        this.setCountry(country);
    }

    private storeValue() {
        if (this.country == null) {
            return;
        } else {
            this.formControl.setValue(this.country);
        }
    }
}
