import { Component, Input } from '@angular/core';
import {
    AuthorityTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionRegisterStatusDetails,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    PersonService,
    PersonnelRegisterEntryDetailsDto,
    RegisterEntryVerificationActivityDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { matchConditionChecks } from 'libs/authorities/src/lib/registers/helpers';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Columns {
    heading: string;
    check: boolean;
}

interface Row {
    status: PersonnelRegisterEntryDetailsDto.Status;
    columns: Columns[];
}

interface Register {
    heading: string;
    subtitle: string;
    isMissionRegister: boolean;
    id: number;
    rows: Row[];
    canView: boolean;
    authorityId: number;
}

@Component({
    selector: 'personnel-registers-status',
    templateUrl: './personnel-registers-status.component.html',
    styles: [
        `
            .status-column {
                width: 10%;
                min-width: 60px;
            }
        `
    ],
    styleUrls: ['./styles.scss']
})
export class PersonnelRegisterStatusComponent {
    @Input() managingOrganisationId: number;
    @Input() personId: number;
    @Input() canEdit: boolean;
    @Input() canAdminOrganisation: boolean;
    @Input() missionDetails: MissionRegisterStatusDetails;
    @Input() hasCurrency: boolean;

    time: string;
    missionName: string;
    registers: Register[];
    allRegisters: PersonnelRegisterEntryDetailsDto[];
    allAuthorities: OrganisationAuthorityDto[];

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;

    constructor(
        private personService: PersonService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        if (this.missionDetails == null) {
            this.time = moment().toISOString();
        } else {
            this.time = this.missionDetails.missionDate;
            this.missionName = this.missionDetails.missionName;
        }
        this.registers = [];
        this.refreshData();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshData() {
        // Find person's register authorities.
        this.personService
            .findPersonnelRegisterEntryByPersonId(
                this.personId,
                this.managingOrganisationId,
                this.time.toString()
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => this.mapRegisters(result),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating person's registers: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    mapRegisters(registers: PersonnelRegisterEntryDetailsDto[]) {
        if (registers == null) {
            this.allRegisters = [];
            return;
        }
        this.allRegisters = registers;
        registers.forEach(r => {
            const isMissionRegister =
                this.missionDetails != null
                    ? r.authority.id ===
                      this.missionDetails.delegatedAuthorityId
                    : false;
            const title = isMissionRegister
                ? `${r.authority.organisation.name} ${
                      r.authority.identifier ?? ''
                  } ${r.authorityRegister.name} (Mission Register)`
                : `${r.authority.organisation.name} ${
                      r.authority.identifier ?? ''
                  } ${r.authorityRegister.name}`;
            const subtitle =
                this.missionName != null
                    ? isMissionRegister
                        ? `Relevant mission register status as at ${moment(
                              this.time
                          ).format('hh:mm DD/MM/YYYY')} as required by ${
                              this.missionName
                          }`
                        : `Showing register status as at ${moment(
                              this.time
                          ).format('hh:mm DD/MM/YYYY')} as required by ${
                              this.missionName
                          }`
                    : '';
            const cols: Columns[] = r.conditions.map(c => ({
                heading: c.name,
                check:
                    r.checks.find(
                        check => check.authorityConditionId === c.id
                    ) != null
                        ? r.checks.find(check => matchConditionChecks(c, check))
                              ?.status ===
                          RegisterEntryVerificationActivityDto.Status.PASSED
                        : false
            }));
            const index = this.registers.findIndex(
                reg => reg.heading === title
            );
            if (index === -1) {
                this.registers.push({
                    heading: title,
                    subtitle: subtitle,
                    id: r.id,
                    isMissionRegister: isMissionRegister,
                    rows: [
                        {
                            status: r.status,
                            columns: cols
                        }
                    ],
                    canView:
                        r.authority.organisation.id ===
                        this.managingOrganisationId,
                    authorityId: r.authority?.id
                });
            } else {
                this.registers[index].rows.push({
                    status: r.status,
                    columns: cols
                });
            }
        });
        // Sort relevant mission registers to the top
        this.registers.sort((a, b) =>
            a.isMissionRegister
                ? -1
                : b.isMissionRegister
                ? 1
                : a.heading.localeCompare(b.heading)
        );
    }

    showRegister(register: Register) {
        const reg = this.allRegisters.find(r => register.id === r.id);
        this.organisationAuthorityService
            .findAuthority(reg.authority.id, this.managingOrganisationId)
            .subscribe(
                authority => {
                    this.authorityDialoguesService.showPersonnelRegisterDialogue(
                        reg.authority.organisation.id,
                        <AuthorityTypeDto>reg.authority.authorityType,
                        authority,
                        reg.authorityRegister,
                        this.managingOrganisationId
                    );
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error viewing register: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }
}
