<div
    *ngIf="status != null && ribbonData != null"
    class="mission-status-ribbon {{ ribbonData.class }}"
>
    <p class="ribbon-label">{{ ribbonData.label }}</p>
    <p *ngIf="ribbonData.hasDate" class="mission-date">
        <span class="fal fa-clock"></span> {{ date }}
    </p>
    <p>
        {{ ribbonData.text }}
        <span class="app" *ngIf="ribbonData.fieldApp"
            ><strong>Field App*</strong></span
        >
        <span class="app" *ngIf="ribbonData.officeApp"
            ><strong>Office App</strong></span
        >
    </p>
    <button
        *ngIf="ribbonData.hasButton && !ribbonData.multipleApprovals"
        type="button"
        class="btn-action relative"
        (click)="ribbonAction()"
        [tooltip]="ribbonData.buttonTooltip"
        [disabled]="ribbonData.buttonDisabled"
    >
        {{ ribbonData.buttonText }}
        <span
            class="overlay"
            [tooltip]="ribbonData.buttonTooltip"
            placement="top"
        ></span>
    </button>
    <button
        *ngIf="ribbonData.hasButton && ribbonData.multipleApprovals"
        type="button"
        class="btn-action relative"
        (click)="toggleDetails()"
        [disabled]="ribbonData.buttonDisabled"
    >
        {{ ribbonData.buttonText }}
        <span
            class="overlay"
            [tooltip]="ribbonData.buttonTooltip"
            placement="top"
        ></span>
    </button>
    <button type="button" class="btn-chevron" (click)="toggleDetails()">
        <span *ngIf="!details" class="fa fa-chevron-down"></span>
        <span *ngIf="details" class="fa fa-chevron-up"></span>
    </button>
</div>
<div
    *ngIf="ribbonData != null"
    (@collapse.done)="setOverflow($event)"
    (@collapse.start)="unsetOverflow($event)"
    [@collapse]="!details"
    [ngClass]="{ 'icon-overflow': overflow, expanded: details }"
    class="mission-status-ribbon-details {{ ribbonData.class }}"
    #ribbonDetails
>
    <div *ngIf="!ribbonData.multipleApprovals" class="status-contents">
        <div
            class="status-row ribbon-status-icons"
            [@fadeIn]="fade"
            [@fadeOut]="!fade"
            [ngClass]="{ 'hidden-icons': hidden }"
        >
            <div class="status-column">
                <p
                    class="fa fa-check"
                    *ngIf="
                        ribbonData.submission ||
                        ribbonData.approval ||
                        ribbonData.launch ||
                        ribbonData.operations ||
                        ribbonData.documentation ||
                        ribbonData.reconciliation ||
                        ribbonData.finalRequest ||
                        ribbonData.finalised
                    "
                ></p>
                <div class="active-status-icon" *ngIf="ribbonData.draft">
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <p
                    class="fa fa-check"
                    *ngIf="
                        ribbonData.approval ||
                        ribbonData.launch ||
                        ribbonData.operations ||
                        ribbonData.documentation ||
                        ribbonData.reconciliation ||
                        ribbonData.finalRequest ||
                        ribbonData.finalised
                    "
                ></p>
                <div class="active-status-icon" *ngIf="ribbonData.submission">
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <p
                    class="fa fa-check"
                    *ngIf="
                        ribbonData.launch ||
                        ribbonData.operations ||
                        ribbonData.documentation ||
                        ribbonData.reconciliation ||
                        ribbonData.finalRequest ||
                        ribbonData.finalised
                    "
                ></p>
                <div class="active-status-icon" *ngIf="ribbonData.approval">
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <div>&nbsp;</div>
            </div>
            <div class="status-column">
                <p
                    class="fa fa-check"
                    *ngIf="
                        ribbonData.operations ||
                        ribbonData.documentation ||
                        ribbonData.reconciliation ||
                        ribbonData.finalRequest ||
                        ribbonData.finalised
                    "
                ></p>
                <div class="active-status-icon" *ngIf="ribbonData.launch">
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <p
                    class="fa fa-check"
                    *ngIf="
                        ribbonData.documentation ||
                        ribbonData.reconciliation ||
                        ribbonData.finalRequest ||
                        ribbonData.finalised
                    "
                ></p>
                <div class="active-status-icon" *ngIf="ribbonData.operations">
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <p
                    class="fa fa-check"
                    *ngIf="
                        ribbonData.reconciliation ||
                        ribbonData.finalRequest ||
                        ribbonData.finalised
                    "
                ></p>
                <div
                    class="active-status-icon"
                    *ngIf="ribbonData.documentation"
                >
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <div>&nbsp;</div>
            </div>
            <div class="status-column">
                <p
                    class="fa fa-check"
                    *ngIf="ribbonData.finalRequest || ribbonData.finalised"
                ></p>
                <div
                    class="active-status-icon"
                    *ngIf="ribbonData.reconciliation"
                >
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <p class="fa fa-check" *ngIf="ribbonData.finalised"></p>
                <div class="active-status-icon" *ngIf="ribbonData.finalRequest">
                    <svg viewBox="0 0 24 11" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                        <path
                            d="M18.3138 7.47912C15.1403 12.1613 8.24259 12.1613 5.06917 7.47911L0.000127393 -1.36831e-07L23.3828 1.90735e-06L18.3138 7.47912Z"
                        />
                    </svg>
                </div>
            </div>
            <div class="status-column">
                <p class="fa fa-check" *ngIf="ribbonData.finalised"></p>
            </div>
        </div>
        <div class="status-row">
            <div class="status-column">
                <p *ngIf="!ribbonData.draft">Mission Planning</p>
                <p *ngIf="ribbonData.draft">
                    <strong>Mission Planning</strong>
                </p>
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.submission">Mission Submitted</p>
                <p *ngIf="ribbonData.submission">
                    <strong>Mission Submitted</strong>
                </p>
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.approval">Mission Approval</p>
                <p *ngIf="ribbonData.approval">
                    <strong>Mission Approval</strong>
                </p>
            </div>
            <div class="status-column status-chevron">
                <img
                    imgPath="/mission-details/right-chevron.svg"
                    type="img/svg"
                />
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.launch">Mission Started</p>
                <p>
                    <strong *ngIf="ribbonData.launch">Mission Started</strong>
                </p>
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.operations">Mission Execution</p>
                <p *ngIf="ribbonData.operations">
                    <strong>Mission Execution</strong>
                </p>
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.documentation">Flying Completed</p>
                <p *ngIf="ribbonData.documentation">
                    <strong>Flying Completed</strong>
                </p>
            </div>
            <div class="status-column status-chevron">
                <img
                    imgPath="/mission-details/right-chevron.svg"
                    type="img/svg"
                />
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.reconciliation">Mission Reconciliation</p>
                <p *ngIf="ribbonData.reconciliation">
                    <strong>Mission Reconciliation</strong>
                </p>
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.finalRequest">Mission Finalisation</p>
                <p *ngIf="ribbonData.finalRequest">
                    <strong>Mission Finalisation</strong>
                </p>
            </div>
            <div class="status-column">
                <p *ngIf="!ribbonData.finalised">Mission Finalised</p>
                <p *ngIf="ribbonData.finalised">
                    <strong>Mission Finalised</strong>
                </p>
            </div>
        </div>
        <div class="status-row mission-phases">
            <div class="phases-column">
                <h5>Mission Planning Phase</h5>
                <div class="ribbon-bracket"></div>
            </div>
            <div class="status-column">
                <div>&nbsp;</div>
            </div>
            <div class="phases-column">
                <h5>Mission Execution Phase</h5>
                <div class="ribbon-bracket"></div>
            </div>
            <div class="status-column">
                <div>&nbsp;</div>
            </div>
            <div class="phases-column">
                <h5>Mission Reconciliation Phase</h5>
                <div class="ribbon-bracket"></div>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            ribbonData.multipleApprovals && activeMissionApprovals.length > 0
        "
        class="status-contents bottom-padded"
    >
        <div class="well top-buffer">
            <table class="table">
                <thead>
                    <tr>
                        <th>Authority</th>
                        <th>Submission time</th>
                        <th>Approval status</th>
                        <th>Message</th>
                        <th>Conditions</th>
                        <th style="width: 5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let approval of activeMissionApprovals">
                        <td>
                            {{
                                getSimplifiedAuthorityFromApproval(
                                    approval,
                                    organisation
                                ) | formatAuthority: false
                            }}
                        </td>
                        <!-- <td>{{ approval.authorityType.name }}</td> -->
                        <td>
                            {{ approval.approval.requestTime | formatDateTime }}
                            <br />
                            <span
                                ><em>{{
                                    approval.approval.requestedBy | formatPerson
                                }}</em></span
                            >
                        </td>
                        <td>
                            {{
                                approval.approval.status | formatApprovalStatus
                            }}
                            <span *ngIf="isResolved(approval.approval.status)">
                                <em>{{
                                    approval.approval.approvedBy | formatPerson
                                }}</em>
                                {{
                                    approval.approval.resolutionTime
                                        | formatDateTime
                                }}
                            </span>
                        </td>
                        <td style="white-space: pre-line">
                            {{ approval.approval.messageFromApprover }}
                            <br />
                        </td>
                        <td>
                            <ol>
                                <li
                                    *ngFor="
                                        let condition of approval.approval
                                            .conditions
                                    "
                                >
                                    {{ condition }}
                                </li>
                            </ol>
                        </td>
                        <td>
                            <div
                                class="horizontal-container"
                                style="justify-content: end"
                            >
                                <button
                                    class="btn btn-default-action btn-sm right-buffer"
                                    type="button"
                                    *ngIf="
                                        approval.approval.status == 'DRAFT' ||
                                        approval.approval.status == 'PENDING'
                                    "
                                    (click)="cancelApproval(approval.approval)"
                                    [disabled]="working"
                                >
                                    Cancel
                                </button>
                                <button
                                    class="btn btn-primary btn-sm"
                                    type="button"
                                    tooltip="View or perform the mission approval"
                                    placement="top"
                                    *ngIf="
                                        approval.approval.status !=
                                            'CANCELLED' &&
                                        approval.approval.status != 'DRAFT'
                                    "
                                    [disabled]="working"
                                    (click)="performApproval(approval.approval)"
                                >
                                    {{
                                        approval.approval.status == 'PENDING' ||
                                        approval.approval.status ==
                                            'BEING_REVIEWED'
                                            ? 'Perform'
                                            : 'View'
                                    }}
                                    Approval
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div
    *ngIf="
        status != null &&
        conformanceRibbon != null &&
        (conformanceNonResolved | async).length > 0
    "
    class="mission-status-ribbon {{ conformanceRibbon.class }}"
>
    <p class="ribbon-label">{{ conformanceRibbon.label }}</p>
    <p *ngIf="conformanceRibbon.hasDate" class="mission-date">
        <span class="fal fa-clock"></span> {{ date }}
    </p>
    <p>
        {{ formatConformanceAlertText(conformanceRibbon.text) }}
        <span class="app" *ngIf="conformanceRibbon.fieldApp"
            ><strong>Field App*</strong></span
        >
        <span class="app" *ngIf="conformanceRibbon.officeApp"
            ><strong>Office App</strong></span
        >
    </p>

    <button
        *ngIf="ribbonData.hasButton"
        type="button"
        class="btn-action relative"
        (click)="viewConformanceIssue()"
    >
        View Issues
        <span class="fad fa-exclamation-triangle"> </span>
    </button>
</div>
