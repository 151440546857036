import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe will pass the value, or return a default.
 *
 * It defaults to returning a `-` character, but can return any default value provided.
 */
@Pipe({
    name: 'defaultTo'
})
export class DefaultToPipe implements PipeTransform {
    transform(value: any, defaultValue = '-'): any {
        if (value == null) {
            return defaultValue;
        }
        return value;
    }
}
