<ng-container screenAnalytics="battery-set-add">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-battery-sets.png" />
        </div>
        <h3 class="modal-title">Add Flight Battery Set</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="batterySetForm" (ngSubmit)="submit()" novalidate>
        <div class="modal-body">
            <enhanced-help
                screenName="battery-set-edit"
                componentName="screen"
                [compact]="true"
            >
            </enhanced-help>
            <div class="form-group top-buffer">
                <p>
                    A flight battery set is the battery or batteries used to
                    power a RPA for a single flight.
                </p>

                <label>Name</label>
                <field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="name"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input id="name" class="form-control" formControlName="name" />
            </div>
            <enhanced-help
                screenName="battery-set-edit"
                componentName="name"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <label>Assembly Date</label>
                <field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="assemblyDate"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        class="form-control"
                        #assemblyDatePicker="bsDatepicker"
                        bsDatepicker
                        formControlName="assemblyDate"
                    />
                    <span
                        class="input-group-addon btn-default"
                        (click)="assemblyDatePicker.toggle()"
                        [attr.aria-expanded]="assemblyDatePicker.isOpen"
                    >
                        <i
                            class="glyphicon glyphicon-calendar"
                            style="cursor: pointer"
                        ></i>
                    </span>
                </div>
            </div>
            <enhanced-help
                screenName="battery-set-edit"
                componentName="assemblyDate"
                [compact]="true"
            >
            </enhanced-help>

            <div class="top-buffer">
                <label>Batteries in Set</label>
                <field-validation-indicator
                    class="pull-right"
                    [valid]="batterySetForm.controls.batteries.valid"
                    [required]="true"
                ></field-validation-indicator>
            </div>
            <enhanced-help
                *ngIf="
                    batterySetForm.controls.assemblyDate.invalid ||
                    batterySetForm.controls.assemblyDate.value == null
                "
                screenName="battery-set-edit"
                componentName="batteryIds"
                [compact]="true"
            >
            </enhanced-help>

            <div class="top-buffer">
                <div class="col-xs-12">
                    <div
                        *ngIf="
                            batterySetForm.controls.assemblyDate.invalid ||
                            batterySetForm.controls.assemblyDate.value == null
                        "
                    >
                        <div class="alert alert-info">
                            Specify an assembly date to display the available
                            batteries
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="top-buffer"
                *ngIf="
                    batterySetForm.controls.assemblyDate.valid &&
                    batterySetForm.controls.assemblyDate.value != null
                "
            >
                <p class="display-label">Included Batteries</p>
                <div
                    *ngFor="
                        let battery of batterySetForm.controls.batteries.value;
                        let ix = index
                    "
                >
                    <a (click)="showBatteryDetails(battery)">
                        Name: {{ battery.name }} (SN:{{
                            battery.manufacturerSerialNumber
                        }})
                    </a>
                    <button
                        class="btn btn-tertiary"
                        type="button"
                        (click)="removeBattery(ix)"
                    >
                        <span class="fa fa-minus"></span>
                    </button>
                </div>
                <p class="display-label">Available Batteries</p>
                <div
                    class="static-table-container no-actions"
                    [formGroup]="searchGroup"
                >
                    <div class="inner-addon left-addon">
                        <span class="fa fa-search"></span>
                        <input
                            type="text"
                            class="form-control input-sm"
                            [ngClass]="{
                                'input-clear-active': hasSearchValue()
                            }"
                            formControlName="search"
                        />
                        <span
                            class="input-group-clear text"
                            *ngIf="hasSearchValue()"
                            (click)="clearSearch()"
                        >
                            <i class="fal fa-times"></i>
                        </span>
                    </div>
                </div>
                <div *ngFor="let battery of displayableBatteries">
                    <a (click)="showBatteryDetails(battery)">
                        Name: {{ battery.name }} (SN:
                        {{ battery.manufacturerSerialNumber }})
                    </a>
                    <button
                        class="btn btn-tertiary"
                        type="button"
                        (click)="addBattery(battery)"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                </div>
            </div>
            <enhanced-help
                *ngIf="
                    batterySetForm.controls.assemblyDate.valid ||
                    batterySetForm.controls.assemblyDate.value != null
                "
                screenName="battery-set-edit"
                componentName="batteryIds"
                [compact]="true"
            >
            </enhanced-help>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-primary pull-right"
                type="submit"
                [disabled]="batterySetForm.invalid || working"
            >
                Save
            </button>
        </div>
    </form>
</ng-container>
