import { Injectable } from '@angular/core';
import {
    BatteryDto,
    BatterySetDto,
    BatteryTypeDto,
    BatteryWithBatteryType,
    Organisation,
    PersonsOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BatteryAddDialogue } from './add/battery-add-dialgoue.component';
import { BatterySetAddDialogue } from './battery-sets/add/battery-set-add-dialgoue.component';
import { BatterySetDetails } from './battery-sets/battery-set-details.component';
import { BatteryTypeDialogue } from './battery-types/edit/battery-type-dialogue.component';
import { BatteryDetailsDialogue } from './details-dialogue/battery-details-dialogue.component';
import { BatteryListDownloadDialogue } from './downloads/battery-list-download/battery-list-download-dialogue.component';

@Injectable()
export class BatteryDialoguesService {
    constructor(private modalService: BsModalService) {}

    showBatteryDetailsDialogue(
        battery: BatteryDto,
        organisation: Organisation
    ) {
        return this.modalService.show(BatteryDetailsDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { battery, organisation }
        });
    }

    showAddBatteryDialogue(
        organisationId: number,
        batterySerialNumber?: string,
        serialNumberOrigin?: string
    ) {
        return this.modalService.show(BatteryAddDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                organisationId,
                batterySerialNumber,
                serialNumberOrigin
            }
        });
    }

    showBatterySetDetailsDialogue(
        batterySet: BatterySetDto,
        organisation: Organisation
    ) {
        return this.modalService.show(BatterySetDetails, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                currentOrganisation: organisation,
                batterySetId: batterySet.id,
                managingOrganisationId: batterySet.organisationId
            }
        });
    }

    showAddBatterySetDialogue(organisationId: number) {
        return this.modalService.show(BatterySetAddDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { organisationId }
        });
    }

    showBatteryTypeEditDialogue(
        batteryType: BatteryTypeDto,
        organisation: PersonsOrganisationDto
    ) {
        return this.modalService.show(BatteryTypeDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { batteryType, organisation }
        });
    }

    showBatteryListDownloadDialogue(
        organisation: PersonsOrganisationDto,
        batteries: BatteryWithBatteryType[]
    ) {
        return this.modalService.show(BatteryListDownloadDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { organisation, batteries }
        });
    }
}
