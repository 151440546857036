import { CoordinateDto } from '@flyfreely-portal-ui/flyfreely';
import { buffer } from '@turf/buffer';
import { circle } from '@turf/circle';
import { feature } from '@turf/helpers';
import { length } from '@turf/length';
import { Feature, GeoJsonProperties, LineString, Position } from 'geojson';
import * as mapboxgl from 'mapbox-gl';
import { LngLat } from 'mapbox-gl';
import { FeatureProperties } from './constants';
import { FeatureType, MapFeature, MapGeometry } from './interfaces';

export function toLngLat(coordinate: CoordinateDto): LngLat {
    return new LngLat(coordinate.longitude, coordinate.latitude);
}

export function pointToLngLat(point: Position): LngLat {
    return new LngLat(point[0], point[1]);
}

export function getMeasurementDescription(
    geoJson: Feature<LineString>
): string {
    const kms = length(geoJson);
    return getMeasurementDescriptionForKms(kms);
}

export function getMeasurementDescriptionForKms(kms: number): string {
    const miles = toNauticalMiles(kms);
    return `${kms.toFixed(2)} km \n ${miles.toFixed(2)} nm`;
}

export function toPosition(coordinate: CoordinateDto): Position {
    return [coordinate.longitude, coordinate.latitude];
}

export function toNauticalMiles(kms: number): number {
    return kms / 1.852;
}

export function boundsOf(geom: GeoJSON.Geometry) {
    const bounds = new mapboxgl.LngLatBounds();
    switch (geom.type) {
        case 'LineString':
            geom.coordinates.forEach(c =>
                bounds.extend(new LngLat(c[0], c[1]))
            );
            return bounds;

        case 'Polygon':
            geom.coordinates.forEach(p =>
                p.forEach(c => bounds.extend(new LngLat(c[0], c[1])))
            );
            return bounds;

        case 'Point':
            bounds.extend(new LngLat(geom.coordinates[0], geom.coordinates[1]));
            return bounds;

        case 'GeometryCollection':
            geom.geometries.forEach(p => bounds.extend(boundsOf(p)));
            return bounds;
    }
}

export function emptyMapFeature(type: FeatureType): MapGeometry {
    switch (type) {
        case 'LineString':
            return {
                type: 'LineString',
                coordinates: []
            };

        case 'Point':
            return {
                type: 'Point',
                coordinates: []
            };

        case 'Polygon':
            return {
                type: 'Polygon',
                coordinates: []
            };
    }
}

export function getMeasurementProperties(
    features: Feature[],
    points: Feature[]
) {
    let isMeasurementLine = false;
    let isMeasurementLabel = false;
    let isMeasurementRadius = false;
    let isMeasurementRadiusLine = false;
    let featureId = '';

    features.forEach((feature: Feature) => {
        switch (feature.properties.meta) {
            case FeatureProperties.MEASUREMENT_LINE:
                // when e.points.length > 1, the user has clicked on the starting point
                // in this case, DrawSingleMeasurementLineSelect changes
                // mode to PointSelect to be able to drag the starting point.
                isMeasurementLine = points.length === 0;
                featureId = feature.id.toString();
                return;
            case FeatureProperties.MEASUREMENT_LABEL:
                isMeasurementLabel = true;
                featureId = feature.properties.relatedFeatureIds[0];
                return;
            case FeatureProperties.MEASUREMENT_RADIUS:
                isMeasurementRadius = true;
                featureId = feature.id.toString();
                return;
            case FeatureProperties.MEASUREMENT_RADIUS_LINE:
                isMeasurementRadiusLine = true;
                featureId = feature.properties.relatedFeatureIds[0];
                return;
        }
    });
    return {
        featureId,
        isMeasurementLine,
        isMeasurementLabel,
        isMeasurementRadius,
        isMeasurementRadiusLine
    };
}

export function createBufferFromLine(
    lineGeoJson: LineString,
    distanceMeters: number
) {
    if (lineGeoJson.coordinates.length < 2) {
        return null;
    }

    const lineBuffer = buffer(lineGeoJson, distanceMeters, {
        units: 'meters'
    });

    return lineBuffer;
}

export class MapMeasurementCircleFeature implements MapFeature {
    id: number;
    name: string;
    centerCoordinates: number[];
    radiusKm: number;
    properties: GeoJsonProperties;
    geom: MapGeometry;
    children: MapFeature[];

    constructor(
        id: number,
        name: string,
        centerCoordinates: number[],
        radiusKm: number
    ) {
        this.id = id;
        this.name = name;
        this.centerCoordinates = centerCoordinates;
        this.radiusKm = radiusKm;
        this.setProperties();
    }

    private setProperties() {
        const circleFeature = circle(this.centerCoordinates, this.radiusKm);
        this.geom = circleFeature.geometry;

        const id = this.id + 1000;

        const lineGeom: LineString = {
            type: 'LineString',
            coordinates: [this.centerCoordinates, this.geom.coordinates[0][0]]
        };
        this.children = [
            {
                id: id,
                geom: lineGeom,
                name: `${name}_measurement`,
                properties: {
                    meta: FeatureProperties.MEASUREMENT_RADIUS_LINE,
                    measurement: getMeasurementDescription(feature(lineGeom)),
                    parent: this.id,
                    relatedFeatureIds: [this.id]
                }
            }
        ];

        this.properties = {
            center: this.centerCoordinates,
            radiusInKm: this.radiusKm,
            meta: FeatureProperties.MEASUREMENT_RADIUS,
            relatedFeatureIds: [this.children.map(c => c.id)]
        };
    }
}
export class MapMeasurementLineFeature implements MapFeature {
    id: number;
    name: string;
    coordinates: number[][];
    geom: LineString;
    properties: GeoJsonProperties;
    children: MapFeature[];

    constructor(id: number, name: string, coordinates: number[][]) {
        this.id = id;
        this.name = name;
        this.coordinates = coordinates;
        this.geom = {
            type: 'LineString',
            coordinates: coordinates
        };
        this.properties = {
            meta: FeatureProperties.MEASUREMENT_LINE
        };
        this.setChildren();
        this.properties.relatedFeatureIds = [this.children.map(c => c.id)];
    }
    private setChildren() {
        const id = this.id + 1000;
        this.children = [
            {
                id: id,
                geom: {
                    type: 'Point',
                    coordinates: this.coordinates[this.coordinates.length - 1]
                },
                name: `${name}_measurement`,
                properties: {
                    meta: FeatureProperties.MEASUREMENT_LABEL,
                    measurement: getMeasurementDescription(feature(this.geom)),
                    parent: this.id,
                    relatedFeatureIds: [this.id]
                }
            }
        ];
    }
}
