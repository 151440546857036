import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

@Injectable({ providedIn: 'root' })
export class LogAnalytics {
    constructor(private angulartics2: Angulartics2) {}

    startTracking(): void {
        this.angulartics2.pageTrack.subscribe(x => console.log('Analytics', x));
        this.angulartics2.eventTrack.subscribe(x =>
            console.log('Analytics', x)
        );
        this.angulartics2.exceptionTrack.subscribe(x =>
            console.log('Analytics', x)
        );
        this.angulartics2.userTimings.subscribe(x =>
            console.log('Analytics', x)
        );
    }
}
