import { FormlyFieldConfig } from '@ngx-formly/core';

export const batteryFields: FormlyFieldConfig[] = [
    {
        key: 'name',
        type: 'input',
        props: {
            required: true,
            maxLength: 50
        }
    },
    {
        key: 'manufacturerSerialNumber',
        type: 'input',
        props: {
            required: false,
            maxLength: 50
        }
    },
    {
        key: 'cycles',
        type: 'input',
        props: {
            type: 'number',
            required: true,
            max: 10000,
            min: 0
        }
    }
];
