import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'setup-finished',
    templateUrl: './setup-finished.component.html',
    styleUrls: ['./setup-finished.component.scss']
})
export class SetupFinishedComponent implements OnInit {
    setupFinishedForm: FormGroup;
    requestSent = false;

    private workTracker = new WorkTracker();
    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private obsds: OnboardingData,
        private userService: UserService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    // setupBillingDetails() {}

    requestCustomerSuccess() {
        const doneWorking = this.workTracker.createTracker();

        this.userService
            .contactSupport({
                message:
                    'I would like help getting setup from customer success.'
            })
            .subscribe(
                () => {
                    this.requestSent = true;
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while requesting support: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    requestDedicatedOnboarding() {
        const doneWorking = this.workTracker.createTracker();

        this.userService
            .contactSupport({
                message: 'I would like dedicated onboarding assistance.'
            })
            .subscribe(
                () => {
                    this.requestSent = true;
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while requesting onboarding assistance: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    // invitePersonnel() {}

    previousScreen() {
        this.obsds.backToOrganisationCertification();
    }

    setupFinished() {
        this.obsds.organisationSetupCompleted();
    }
}
