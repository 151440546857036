import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    FEATURE_FLIGHT_CONFORMANCE,
    FEATURE_OUTSTANDING_TASKS_VIEW,
    FlightLogCriteria,
    FlightLogsService,
    PersonsOrganisationDto,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatDatePipe, FormatPersonPipe } from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { FlightLogsDialoguesService } from 'libs/flight-logs/src/lib/flight-logs-dialogues.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetRegistrationWithSection } from '../../../outstanding-tasks-view.service';
import {
    OutstandingFlightLogsDataService,
    OutstandingFlightLogsFlight
} from './outstanding-flight-logs-data.service';

const RECONCILE_FLIGHT_LOGS_IDENTIFIER = 'reconcileFlightLogsTaskWidget';

@Component({
    selector: 'outstanding-flight-logs-widget',
    templateUrl: './outstanding-flight-logs.component.html',
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()],
    providers: [OutstandingFlightLogsDataService]
})
export class OutstandingFlightLogsWidgetComponent {
    organisation: PersonsOrganisationDto;
    showBody = true;

    flightLogs: OutstandingFlightLogsFlight[];

    //Static table variables
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    gqlSorting: ColumnSortPreferences;

    working = false;
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private outstandingFlightLogsDataService: OutstandingFlightLogsDataService,
        private workspaceStateService: WorkspaceStateService,
        private flightLogsService: FlightLogsService,
        private flightLogDialoguesService: FlightLogsDialoguesService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formatPersonPipe: FormatPersonPipe,
        private formatDatePipe: FormatDatePipe
    ) {
        this.availableColumns = [
            {
                value: 'startTime',
                name: 'Date',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: p => this.formatDatePipe.transform(p),
                linkFunction: row => this.viewFlightLogs(row)
            },
            {
                value: 'flightLogName',
                name: 'Flight Log',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: row => this.viewFlightLogs(row)
            },
            {
                value: 'rpaName',
                name: 'RPA',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'rpaSerialNumber',
                name: 'RPA Serial Number',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'missionName',
                name: 'Mission',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'pilotName',
                name: 'Pilot',
                searchable: false,
                selectable: false,
                sortable: false,
                defaultSelection: true
            },
            {
                value: 'conformanceResultStatus',
                name: 'Conformance Breach',
                searchable: false,
                selectable: false,
                defaultSelection: true
            }
        ];

        this.selectedColumns = null;

        this.tableConfig = {
            serverPagination: true,
            currentPage: 0,
            limit: 3,
            actions: []
        };

        this.gqlSorting = null;

        this.outstandingFlightLogsDataService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshSubscriptions();
                this.refreshFlightLogs();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshFlightLogs() {
        this.outstandingFlightLogsDataService.findFlightLogs(
            this.tableConfig.currentPage,
            this.tableConfig.limit,
            this.gqlSorting,
            {
                organisationId: this.organisation.id,
                assignmentStatus: FlightLogCriteria.AssignmentStatus.UNASSIGNED
            },
            this.organisation.id
        );
    }

    refreshSubscriptions() {
        combineLatest([
            this.outstandingFlightLogsDataService.flightLogs$,
            this.outstandingFlightLogsDataService.currentPage$,
            this.outstandingFlightLogsDataService.totalItems$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([logs, currentPage, totalItems]) => {
                this.flightLogs = logs;
                if (this.tableConfig != null) {
                    const currentConfig = this.tableConfig;
                    this.tableConfig = {
                        ...currentConfig,
                        currentPage: currentPage,
                        totalItems: totalItems
                    };
                }
            });

        this.flightLogsService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshFlightLogs());
    }

    viewFlightLogs(flightLog: OutstandingFlightLogsFlight) {
        this.flightLogDialoguesService.showFlightLog(
            flightLog.id,
            this.organisation.id
        );
    }

    goToFlightLogs() {
        this.router.navigate(['flightlogs'], {
            queryParamsHandling: 'preserve',
            relativeTo: this.activatedRoute
        });
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.refreshFlightLogs();
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (!this.selectedColumns.includes(this.gqlSorting.column)) {
            this.gqlSorting = null;
        }
        this.refreshFlightLogs();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.gqlSorting =
            sorting == null
                ? null
                : {
                      column: sorting.column.toString(),
                      ascending: sorting.ascending
                  };
        this.tableConfig.currentPage = 0;
        this.refreshFlightLogs();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.refreshFlightLogs();
    }
}

export const reconcileFlightLogsTaskWidgetRegistration: WidgetRegistrationWithSection =
    {
        widgetIdentifier: RECONCILE_FLIGHT_LOGS_IDENTIFIER,
        section: 'operations',
        component: OutstandingFlightLogsWidgetComponent,
        isAvailable: organisation =>
            hasFeatureFlag(organisation, FEATURE_OUTSTANDING_TASKS_VIEW) &&
            hasFeatureFlag(organisation, FEATURE_FLIGHT_CONFORMANCE)
    };
