import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AdminOrganisationDto,
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    PersonnelRegisterEntryExpiryDto
} from '@flyfreely-portal-ui/flyfreely';
import { FormatDatePipe } from '@flyfreely-portal-ui/ui';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation,
    fadeInExpandOnEnterAnimation,
    fadeOutCollapseOnLeaveAnimation
} from 'angular-animations';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorityActionPayload } from '../organisation-authority/organisation-authority-edit.service';
import { AuthorityGroupEditService } from '../registers/interfaces';

export interface AuthorityTypeActionPayload {
    authorityType: AuthorityTypeDto | OrganisationAuthorityGroup;
    authority?: any;
}

/**
 * The statuses for authority groups used primarily in driving styles.
 * OK = All required fields completed and no actions required
 * ATTENTION = Action required (e.g. Register expiry)
 * INCOMPLETE = Incomplete required authority (e.g. primary or dependentonauthority not yet filled in)
 * EMPTY = blank state for non-required (mainly secondary) authorities added, but not yet set up.
 */
type GroupStatus = 'ACTIVE' | 'ATTENTION' | 'INCOMPLETE' | 'EMPTY';
const GroupStatus = {
    ACTIVE: 'ACTIVE' as GroupStatus,
    ATTENTION: 'ATTENTION' as GroupStatus,
    INCOMPLETE: 'INCOMPLETE' as GroupStatus,
    EMPTY: 'EMPTY' as GroupStatus
};

@Component({
    selector: 'authority-group-edit',
    templateUrl: './authority-group-edit.component.html',
    styleUrls: ['./authority-group-edit.styles.scss'],
    animations: [
        fadeInExpandOnEnterAnimation(),
        fadeOutCollapseOnLeaveAnimation(),
        expandOnEnterAnimation(),
        collapseOnLeaveAnimation()
    ]
})
export class AuthorityGroupEdit implements OnChanges {
    @Input() organisation: AdminOrganisationDto;
    @Input() managingOrganisationId: number;
    @Input() authorityGroup: OrganisationAuthorityGroup;
    @Input() dependentOnAuthorityGroup: OrganisationAuthorityGroup;
    @Input() linkedAuthorities: OrganisationAuthorityGroup[];
    @Input() dependentAuthorityTypes: { name: string; id: number }[];
    @Input() showArchived: boolean;
    @Input() viewOnly: boolean = false;

    @Output() onShowAuthority = new EventEmitter<AuthorityActionPayload>();
    @Output() onCreateAuthority = new EventEmitter<AuthorityActionPayload>();
    @Output() onLinkAuthority = new EventEmitter<AuthorityActionPayload>();

    groupStatus: GroupStatus = GroupStatus.EMPTY;
    card?: {
        typeName: string;
        statusName: string;
        statusStyle: 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'DANGER';
        statusTooltip?: string;
    };

    showDependentAuthorityOptions = false;
    showDependentWarning = false;
    dependentWarningTypeNames: string;

    availableLinkedAuthorities: OrganisationAuthorityGroup[] = [];
    addedLinkedAuthorities: OrganisationAuthorityGroup[] = [];
    selectedLinkedAuthority: OrganisationAuthorityGroup;

    canLinkToPrimary = false;

    today: Date = new Date();
    discontinuedDate: string;

    registerExpiries: {
        [registerId: number]: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
    };

    @HostBinding('@fadeOutCollapseOnLeave') get leave() {
        return true;
    }

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private authorityGroupEditService: AuthorityGroupEditService,
        private formatDatePipe: FormatDatePipe
    ) {}

    ngOnInit() {
        this.authorityGroupEditService.registerExpiryLookups$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(expiries => {
                this.registerExpiries = expiries;
                this.setupGroupStatus();
            });

        this.canLinkToPrimary =
            !this.authorityGroup.isPrimary &&
            this.authorityGroup.dependentOnAuthorityType != null &&
            this.authorityGroup.authorities[0].dependentOnAuthorityId == null;

        this.showDependentWarning =
            this.dependentAuthorityTypes != null &&
            (this.authorityGroup.authorities == null ||
                this.authorityGroup.authorities.length === 0);

        if (this.authorityGroup.discontinueDate != null) {
            this.discontinuedDate = this.formatDatePipe.transform(
                this.authorityGroup.discontinueDate
            );
        }

        if (this.dependentAuthorityTypes != null) {
            this.dependentWarningTypeNames =
                this.dependentAuthorityTypes.reduce(
                    (acc, type, i) =>
                        `${acc}${type.name}${
                            i == this.dependentAuthorityTypes.length - 1
                                ? ''
                                : i == this.dependentAuthorityTypes.length - 2
                                ? ' & '
                                : ', '
                        }`,
                    ''
                );
        }

        this.setupGroupStatus();
        this.setupCard();
        this.setupLinkedAuthorities();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('linkedAuthorities' in changes) {
            this.setupLinkedAuthorities();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupLinkedAuthorities() {
        if (this.linkedAuthorities == null) {
            return;
        }
        this.availableLinkedAuthorities = this.linkedAuthorities.filter(
            authority =>
                (authority.authorities.length === 0 ||
                    authority.hasMultipleInstances === true) &&
                authority.availableActions.canCreate
        );

        const addedGroups = this.linkedAuthorities.filter(
            authority =>
                authority.authorities.filter(
                    a =>
                        a.dependentOnAuthorityId ===
                        this.authorityGroup.authorities[0].id
                ).length > 0
        );
        this.addedLinkedAuthorities = addedGroups.map(group => ({
            ...group,
            authorities: group.authorities.filter(
                a =>
                    a.dependentOnAuthorityId ===
                    this.authorityGroup.authorities[0].id
            )
        }));
    }

    setupGroupStatus() {
        if (
            this.authorityGroup.discontinueDate != null &&
            moment(this.authorityGroup.discontinueDate).isBefore(this.today)
        ) {
            this.groupStatus = GroupStatus.EMPTY;
            return;
        }
        if (this.authorityGroup.authorities.length === 0) {
            this.groupStatus =
                this.dependentAuthorityTypes != null
                    ? GroupStatus.INCOMPLETE
                    : GroupStatus.EMPTY;
            return;
        }

        const anyActiveAuthority =
            this.authorityGroup.authorities.find(
                a => !this.isFutureDated(a) && !this.hasExpired(a)
            ) != null;

        if (this.authorityGroup.isPrimary) {
            const actionRequired = this.hasRegisterExpiry(
                this.authorityGroup.authorities[0]
            );
            this.groupStatus = !anyActiveAuthority
                ? GroupStatus.INCOMPLETE
                : actionRequired
                ? GroupStatus.ATTENTION
                : GroupStatus.ACTIVE;
        } else {
            this.groupStatus = !anyActiveAuthority
                ? GroupStatus.INCOMPLETE
                : GroupStatus.ACTIVE;
        }
    }

    private setupCard() {
        const typeName = this.authorityGroup.isPrimary
            ? 'Primary'
            : 'Secondary';
        const status = this.getStatus(
            this.authorityGroup,
            this.authorityGroup.authorities
        );
        switch (status) {
            case 'NEW':
                this.card = {
                    typeName,
                    statusName: 'New',
                    statusStyle: 'PENDING'
                };
                break;
            case 'ACTIVE':
                this.card = {
                    typeName,
                    statusName: 'Active',
                    statusStyle: 'ACTIVE'
                };
                break;
            case 'PENDING':
                this.card = {
                    typeName,
                    statusName: 'Pending',
                    statusStyle: 'PENDING'
                };
                break;
            case 'AVAILABLE':
                this.card = {
                    typeName,
                    statusName: 'Available',
                    statusStyle: 'INACTIVE'
                };
                break;
            case 'EXPIRED':
                this.card = {
                    typeName,
                    statusName: 'Expired',
                    statusStyle: 'DANGER'
                };
                break;
            case 'ARCHIVED':
                this.card = {
                    typeName,
                    statusName: 'Archived',
                    statusStyle: 'INACTIVE'
                };
                break;
            case 'REQUIRED':
                this.card = {
                    typeName,
                    statusName: 'Required',
                    statusStyle: 'DANGER',
                    statusTooltip:
                        'This authority is required for other authorities you already have setup'
                };
                break;
            case 'ACTION_REQUIRED':
                this.card = {
                    typeName,
                    statusName: 'Action Required',
                    statusStyle: 'DANGER',
                    statusTooltip:
                        'This authority has one or more register expiries'
                };
                break;
        }
    }

    hasExpired(authority: OrganisationAuthorityDto) {
        return (
            authority.expiryDate != null &&
            moment(authority.expiryDate).isBefore(moment(this.today))
        );
    }

    isFutureDated(authority: OrganisationAuthorityDto) {
        return (
            authority.startDate != null &&
            moment(authority.startDate).isAfter(moment(this.today))
        );
    }

    hasRegisterExpiry(authority: OrganisationAuthorityDto) {
        return authority.registers == null || this.registerExpiries == null
            ? false
            : authority.registers
                  .filter(
                      r =>
                          r.registerMode !==
                          AuthorityRegisterSummaryDto.RegisterMode.DISABLED
                  )
                  .reduce(
                      (acc, r) =>
                          acc ||
                          this.registerExpiries[r.id] !==
                              PersonnelRegisterEntryExpiryDto.ExpiryStatus.OK,
                      false
                  );
    }

    getStatus(
        authorityGroup: OrganisationAuthorityGroup,
        authorities: OrganisationAuthorityDto[]
    ) {
        if (authorities.length === 0) {
            if (this.dependentAuthorityTypes != null) {
                return 'REQUIRED';
            } else {
                return 'AVAILABLE';
            }
        }
        const findStatus = (authority: OrganisationAuthorityDto) => {
            if (this.isFutureDated(authority)) {
                return 'PENDING';
            }
            if (authority.status === OrganisationAuthorityDto.Status.NEW) {
                return 'NEW';
            }
            if (this.hasExpired(authority)) {
                return 'EXPIRED';
            }
            if (authority.archived) {
                return 'ARCHIVED';
            }
            if (this.hasRegisterExpiry(authority)) {
                return 'ACTION_REQUIRED';
            }
            return 'ACTIVE';
        };
        if (authorities.length === 1) {
            return findStatus(authorities[0]);
        }
        const currentAuthority = authorities.reduce((acc, authority) => {
            if (acc == null) {
                return authority;
            }
            if (
                moment(authority.startDate).isAfter(moment(acc.startDate)) &&
                (authority.expiryDate == null ||
                    (acc.expiryDate != null &&
                        authority.expiryDate != null &&
                        moment(authority.expiryDate).isAfter(
                            moment(acc.expiryDate)
                        )))
            ) {
                return authority;
            }
            return acc;
        }, null);
        return findStatus(currentAuthority);
    }

    viewLinkedAuthority(
        authorityType: OrganisationAuthorityGroup,
        authority: OrganisationAuthorityDto
    ) {
        this.onShowAuthority.emit({
            authorityType,
            authority
        });
    }

    createAuthority() {
        this.onCreateAuthority.emit({
            authorityType: this.authorityGroup,
            authority:
                this.dependentOnAuthorityGroup != null
                    ? this.dependentOnAuthorityGroup.authorities[0]
                    : null
        });
    }
}
