import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'help-video-dialogue',
    template: `
        <div class="modal-header no-icon">
            <h4 class="modal-title pull-left">{{ title }}</h4>
            <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="hide()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <embedded-video-player
                [videoUrl]="videoUrl"
            ></embedded-video-player>
        </div>
    `
})
export class HelpVideoDialogue {
    @Input()
    videoUrl: string;

    @Input()
    title: string;

    isMp4 = false;

    safeUrl: SafeResourceUrl;

    constructor(private modal: BsModalRef) {}

    hide() {
        this.modal.hide();
    }
}
