import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    AttachmentHandler,
    combineFormResponses,
    CompletedDocumentationDto,
    CompleteSortieCommand,
    DisplayableDocumentation,
    FormResponseCommand,
    MissionDetailsDto,
    MissionService,
    NameValue,
    StepDescription,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { DocumentationActions } from 'libs/documentation/src/lib/interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'flight-documentation-edit-dialogue',
    templateUrl: './flight-documentation-edit-dialogue.component.html'
})
export class FlightDocumentationEditDialogue {
    @Input() mission: MissionDetailsDto;
    @Input() flightGroup: FormControl<CompleteSortieCommand>;
    @Input() documentation: DisplayableDocumentation;
    @Input() step: string;

    @Output() documentationUpdate =
        new EventEmitter<CompletedDocumentationDto>();

    attachmentHandler: AttachmentHandler;
    documentationActions: DocumentationActions;

    flight: CompleteSortieCommand;

    form: FormGroup<{ notes: FormControl<string> }>;

    steps: NameValue[];
    stepDescription: StepDescription[];

    working = false;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef,
        private missionService: MissionService
    ) {
        this.steps = missionService.getFlightSteps();
        this.form = new FormGroup({
            notes: new FormControl<string>(undefined)
        });
    }

    ngOnInit() {
        this.workTracker
            .observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.flight = this.flightGroup.value;
        if (this.flight.notes != null) {
            this.form.patchValue({
                notes: this.flight.notes
            });
        }

        this.stepDescription = this.steps.map(s => ({
            ...s,
            editable: true,
            validate: true
        }));

        this.setupDocumentationActions();
        this.attachmentHandler = this.missionService.flightAttachmentHandler(
            this.mission.id,
            this.flight.id
        );

        this.form.controls.notes.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(notes =>
                this.flightGroup.patchValue({
                    ...this.flightGroup.value,
                    notes: notes
                })
            );
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('mission' in changes || 'flight' in changes) {
            this.attachmentHandler =
                this.missionService.flightAttachmentHandler(
                    this.mission.id,
                    this.flight.id
                );
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupDocumentationActions() {
        this.documentationActions = {
            getFormTemplatePrintUrl: (checklistId: number, step: string) =>
                this.missionService.getFlightFormTemplatePrintUrl(
                    this.mission.id,
                    this.flight.id,
                    checklistId,
                    step,
                    this.mission.organisationId
                ),
            getFormResponsePrintUrl: (formId: number, step: string) =>
                this.missionService.getFlightFormResponsePrintUrl(
                    this.mission.id,
                    this.flight.id,
                    formId,
                    step,
                    this.mission.organisationId
                ),
            storeFormResponse: (
                formId: number,
                step: string,
                response: FormResponseCommand
            ) => {
                return this.missionService
                    .updateSortieDocumentation(
                        this.flight.id,
                        this.mission.id,
                        {
                            formResponses: {
                                [step]: [response]
                            }
                        }
                    )
                    .pipe(
                        takeUntil(this.ngUnsubscribe$),
                        tap(docs => this.documentationUpdate.next(docs)),
                        tap(docs => {
                            this.documentation = {
                                ...this.documentation,
                                formResponses: combineFormResponses([
                                    docs.formResponses
                                ])
                            };
                        }),
                        map(() => undefined)
                    )
                    .toPromise();
            }
        };
    }

    close() {
        this.modal.hide();
    }
}
