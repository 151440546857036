import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    FlyFreelyConstants,
    httpParamSerializer,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { Vizzly } from '@vizzly/services';
import {
    Dashboard,
    SaveableDashboardDefinition
} from '@vizzly/services/dist/shared-logic/src/Dashboard/types';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    from,
    Observable,
    of,
    ReplaySubject,
    Subject
} from 'rxjs';
import {
    filter,
    map,
    mapTo,
    mergeMap,
    shareReplay,
    takeUntil,
    tap
} from 'rxjs/operators';

interface BaseDashboard {
    dashboardId: string;
    parentDashboardId: string;
    isParent: boolean;
    canEdit: boolean;
    canDelete: boolean;
    metadata: { name?: string };
}

interface VizzlyToken {
    dashboardAccessToken: string;
    dataAccessToken: string;
    blankDashboardId: string;
}

/**
 * This service handles the loading of the API object, which will be setup with the appropriate permissions
 * based on whether the person wants to do admin activities or not.
 *
 * When they are an admin, we do not load the child dashboards, as the dashboard component will not
 * edit it anyway.
 *
 * The default parent dashboard is used as the "blank" dashboard parent.
 */
@Injectable()
export class ReportingService {
    private baseUrl: string;
    private organisation$ = new ReplaySubject<PersonsOrganisationDto>(1);
    private ngUnsubscribe$ = new Subject<void>();

    private requestAdmin$ = new BehaviorSubject<boolean>(false);

    private currentReportSubject = new BehaviorSubject<
        BaseDashboard | undefined
    >(undefined);
    currentReport$ = this.currentReportSubject.asObservable();

    private defaultReportListSubject = new BehaviorSubject<
        undefined | Dashboard[]
    >(undefined);

    private reportListSubject = new BehaviorSubject<Dashboard[]>([]);

    /**
     * An initialised instance of the Vizzly API instance.
     */
    apiSubject: Observable<Vizzly>;

    token$ = this.requestAdmin$.pipe(
        mergeMap(isAdmin => this.getReportingToken(isAdmin)),
        map(({ dashboardAccessToken, dataAccessToken, blankDashboardId }) => {
            this.blankReportId = blankDashboardId;
            return { dashboardAccessToken, dataAccessToken };
        }),
        shareReplay(1)
    );

    /**
     * The dashboard list should be the parents without children, plus the children.
     */
    reportList$ = combineLatest([
        this.defaultReportListSubject.pipe(filter(x => x != null)),
        this.reportListSubject,
        this.requestAdmin$
    ]).pipe(
        map(([parentList, childList, requestAdmin]) => {
            return requestAdmin
                ? parentList.filter(p => p.id !== this.blankReportId)
                : [
                      ...childList,
                      ...parentList.filter(
                          p =>
                              !childList.some(
                                  c => c.parentDashboardId === p.id
                              ) && p.id !== this.blankReportId
                      )
                  ];
        }),
        shareReplay()
    );

    private _queryEngineEndpoint: string;
    get queryEngineEndpoint() {
        return this._queryEngineEndpoint;
    }

    /**
     * This is valid once the `vizzlyApi` is valid
     */
    isAdmin?: boolean;

    blankReportId?: string;
    isSystemAdmin$: Observable<boolean>;

    private workTracker = new WorkTracker();
    working$ = this.workTracker.observable;

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        workspaceStateService: WorkspaceStateService
    ) {
        this.baseUrl = constants.SITE_URL;
        this._queryEngineEndpoint = `${this.baseUrl}/vizzly`;

        workspaceStateService.currentOrganisation$
            .pipe(
                filter(o => o.type === 'organisation_loaded'),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe((currentOrganisation: CurrentOrganisation) =>
                this.organisation$.next(currentOrganisation.organisation)
            );

        this.isSystemAdmin$ = workspaceStateService.user$.pipe(
            map(user => user.type === 'SYSTEM_ADMIN')
        );

        this.apiSubject = from(
            Vizzly.load({
                queryEngine: this._queryEngineEndpoint,
                identity: () => firstValueFrom(this.token$)
            })
        ).pipe(
            mergeMap(api => {
                // Load all the important variable from the service now
                this.isAdmin =
                    api.getIdentityConfig().dashboardAccess.accessType ===
                    'admin';
                return of(api);
            }),
            shareReplay(1)
        );

        this.refreshReports().subscribe(_ => {
            // Not sure this is the right way to select a default report
            const reports = this.reportListSubject.getValue();
            if (reports.length > 0) {
                this.setCurrentReport(reports[0]);
            }
        });
    }

    ngOnDestroy() {
        this.requestAdmin$.complete();
        this.currentReportSubject.complete();
        this.organisation$.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    toggleRequestAdmin() {
        this.requestAdmin$.next(!this.requestAdmin$.value);
    }

    switchReport(dashboardId: string) {
        const dashboard = this.reportListSubject.value.find(
            d => d.id === dashboardId
        );
        if (dashboard != null) {
            this.setCurrentReport(dashboard);
        }

        const parentDashboard = this.defaultReportListSubject.value.find(
            d => d.id === dashboardId
        );
        if (parentDashboard != null) {
            this.setCurrentReport(parentDashboard);
        }
    }

    getReportingToken(isAdmin: boolean) {
        return this.organisation$.pipe(
            mergeMap(o =>
                this.http.get<VizzlyToken>(
                    `${this.baseUrl}/webapi/dynamicReports/vizzly`,
                    {
                        params: httpParamSerializer({
                            organisationId: o.id,
                            isAdmin
                        })
                    }
                )
            )
        );
    }
    /**
     * Populates the `reportListSubject` with the child dashboards of the current dashboards, or an empty list if the user is an admin.
     */
    private refreshReports() {
        const workDone = this.workTracker.createTracker();

        return this.apiSubject.pipe(
            mergeMap(api => api.getDashboards()),
            tap(dashboards => {
                this.defaultReportListSubject.next(
                    Vizzly.filterDashboards(dashboards, {
                        onlyParentDashboards: true
                    })
                );
                this.reportListSubject.next(
                    !this.isAdmin
                        ? Vizzly.filterDashboards(dashboards, {
                              onlyChildDashboards: true
                          })
                        : []
                );
            }),
            tap({ error: workDone, complete: workDone }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    createDashboard(
        name: string,
        parentDashboardId?: string,
        sourceDashboardId?: string
    ) {
        let definition: SaveableDashboardDefinition;

        if (sourceDashboardId != null) {
            const dashboard = this.reportListSubject.value.find(
                d => d.id === sourceDashboardId
            );
            if (dashboard != null) {
                definition = {
                    display: dashboard.display,
                    componentLibrary: dashboard.componentLibrary,
                    customFields: {},
                    globalFilters: [],
                    isPlayground: false
                };
            } else {
                const parentDashboard =
                    this.defaultReportListSubject.value.find(
                        d => d.id === sourceDashboardId
                    );
                definition = {
                    display: parentDashboard.display,
                    componentLibrary: parentDashboard.componentLibrary,
                    customFields: {},
                    globalFilters: [],
                    isPlayground: false
                };
            }
        } else {
            definition = new Vizzly.Dashboard().build();
        }

        return this.apiSubject.pipe(
            mergeMap(api =>
                api.createDashboard({
                    parentDashboardId: parentDashboardId ?? this.blankReportId,
                    definition,
                    metadata: {
                        name
                    }
                })
            ),
            // We want to wait for the report list refresh before returning the new dashboard
            mergeMap(dashboard => this.refreshReports().pipe(mapTo(dashboard)))
        );
    }

    /**
     * Delete the dashboard, then select the next available dashboard to be the current dashboard,
     * or select none.
     * @param dashboardId
     */
    deleteReport(dashboardId: string) {
        this.apiSubject
            .pipe(
                mergeMap(api =>
                    api.updateDashboard({ dashboardId, deleted: true })
                )
            )
            .subscribe(() => {
                this.refreshReports();
            })
            .add(this.workTracker.createTracker());
    }

    updateName(dashboardId: string, newName: string) {
        this.apiSubject
            .pipe(
                mergeMap(api =>
                    api.updateDashboard({
                        dashboardId,
                        metadata: { name: newName }
                    })
                ),
                tap(dashboard => {
                    if (
                        dashboard.id ===
                        this.currentReportSubject.value?.dashboardId
                    ) {
                        this.setCurrentReport(dashboard);
                    }
                }),
                mergeMap(() => this.refreshReports())
            )
            .subscribe();
    }

    setCurrentReport(dashboard: Dashboard) {
        this.currentReportSubject.next({
            dashboardId: dashboard.id,
            isParent: dashboard.parentDashboardId == null,
            parentDashboardId: dashboard.parentDashboardId,
            canDelete: dashboard.parentDashboardId != null,
            canEdit: dashboard.parentDashboardId != null || this.isAdmin,
            metadata: dashboard.metadata
        });
    }
}
