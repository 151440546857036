import { Directive, AfterViewInit, ElementRef } from '@angular/core';

const isChrome =
    // @ts-ignore - wondow type does exist
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

/**
 * Cross browser supported scroll to
 * @param element native element reference
 */
export function scrollToElement(element: any) {
    if (isChrome) {
        element.scrollIntoViewIfNeeded({ behavior: 'smooth' });
    } else {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

@Directive({ selector: '[scrollTo]' })
export class ScrollToDirective implements AfterViewInit {
    constructor(private elRef: ElementRef) {}
    ngAfterViewInit() {
        scrollToElement(this.elRef.nativeElement);
    }
}
