<div
    class="panel widget"
    *ngIf="(flightLogs && flightLogs.length > 0) || working"
>
    <div class="panel-heading-flex">
        <div class="panel-title no-uppercase">Fight Logs to be Reconciled</div>
        <div class="panel-content"></div>
        <div class="panel-buttons">
            <button
                class="btn btn-inline"
                (click)="goToFlightLogs()"
                tooltip="Go to flight logs"
                placement="left"
            >
                <i class="fa-map fa"></i>
            </button>
            <button
                class="btn btn-inline"
                (click)="showBody = !showBody"
                [tooltip]="showBody ? 'Hide' : 'Show'"
                placement="left"
            >
                <i *ngIf="showBody" class="fa-chevron-up fa no-border"></i>
                <i *ngIf="!showBody" class="fa-chevron-down fa no-border"></i>
            </button>
        </div>
    </div>
    <div *ngIf="showBody" class="panel-body" @expandOnEnter @collapseOnLeave>
        <div
            class="static-table-container no-actions"
            *ngIf="flightLogs && flightLogs.length"
        >
            <static-table
                [availableColumns]="availableColumns"
                [selectedColumns]="selectedColumns"
                [tableData]="flightLogs"
                [tableConfig]="tableConfig"
                [borderless]="false"
                [underlinedRows]="true"
                scanability="comfortable"
                (pageChanged)="onTablePageChanged($event)"
                (selectedColumnsChanged)="
                    updateSelectedColumns($event.selectedColumns)
                "
                (sortOrderChanged)="updateColumnSorting($event)"
                (itemLimitUpdated)="updateItemLimit($event)"
            >
                <ng-template
                    columnTemplate="pilotName"
                    let-row="row"
                    let-column="column"
                >
                    <div style="width: 200px">
                        <person-avatar
                            [person]="row.pilotName"
                            [managingOrganisationId]="organisation.id"
                            alternativeName="No associated pilot"
                        ></person-avatar>
                    </div>
                </ng-template>
            </static-table>
        </div>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
<completed-outstanding-tasks-banner
    *ngIf="(!flightLogs || flightLogs.length == 0) && !working"
    widgetHeading="Fight Logs to be Reconciled"
></completed-outstanding-tasks-banner>
