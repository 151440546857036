<ng-container screenAnalytics="airworthiness-notices">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Airworthness Notices</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button type="button" (click)="cancel()" aria-label="Close" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body no-footer inner-container p-0">
        <ngx-loading [show]="working"></ngx-loading>
        <div class="d-flex">
            <div class="notices-left-panel" #noticesContainer >
                <div *ngIf="!searchMode" class="d-flex space-between mb-16">
                    <h4>Airworthiness Notices</h4>
                    <div class="d-flex space-between space-16">
                        <button 
                            class="circle-btn-icon border-gray bg-transparent"
                            [disabled]="!hasNotices" 
                            type="button"
                            (click)="searchModeShow()">
                            <i class="fa fa-search search-icon"></i>
                        </button>
                        <button 
                            class="btn btn-default-action btn-sm" 
                            type="button"
                            (click)="addNotice()">
                            <i class="fa fa-plus"></i>
                            Create Notice
                        </button>
                    </div>
                </div>
                <div *ngIf="searchMode" class="d-flex space-between mb-32">
                    <button 
                            class="btn-base border-none bg-transparent p-0" 
                            type="button"
                            (click)="searchModeShow()">
                            <i class="fa fa-arrow-left-long fa-lg"></i>
                        </button>
                    <div class="d-flex space-between">
                        <div class="d-flex p-relative w-400">
                            <input
                            type="text"
                            class="form-control corner-left-curve border-gray"
                            placeholder="Search..."
                            [(ngModel)]="searchTerm"
                            />
                            <i class="fa fa-times clear-icon" (click)="clearSearch()"></i>
                        </div>
                        <button 
                            class="btn-base border-blue bg-blue color-white corner-right-curve" 
                            
                            type="button"
                            (click)="searchNotices()">
                            <i class="fa-light fa-search  color-white"></i>
                            Search
                        </button>
                    </div>
                
                </div>
                <div *ngIf="filteredNotices$ | async as filteredNotices">
                    <!-- Render other groups (APPLICABLE, NOT_APPLICABLE, UNKNOWN) first -->
                    <div *ngFor="let applicability of (filteredNotices | keyvalue)">
                    <ng-container *ngIf="applicability.key !== 'COMPLETED'">
                        <h4 
                        (click)="toggleGroup(applicability.key)" 
                        [ngClass]="{'cursor-pointer': !searchMode}" 
                        class="mb-16 group"
                        [attr.data-group-key]="applicability.key"
                        >
                        <i class="mr-16 w-16" *ngIf="!searchMode" 
                            [ngClass]="isGroupExpanded(applicability.key) ? 'fa-light fa-chevron-down' : 'fa-regular fa-chevron-right'"></i>
                        {{ getApplicabilityDisplay(applicability.key) }}
                        </h4>
                        <div [@expandCollapse]="isGroupExpanded(applicability.key) ? 'expanded' : 'collapsed'">
                        <div *ngFor="let notice of applicability.value">
                            <app-notice-card
                            [name]="notice.name" 
                            [id]="notice.id"
                            [applicability]="getApplicabilityDisplay(notice.applicability)"
                            [type]="getTypeDisplay(notice.type)"
                            [dueBy]="notice.dueBy"
                            [description]="notice.description"
                            [issuedBy]="notice.issuedBy"
                            [dateCreated]="notice.creationTime"
                            [isExpanded]="isExpanded(notice.id)"
                            (toggleExpand)="toggleExpandCollapse(notice.id)"
                            (noticeSelected)="onNoticeSelected($event)"
                            (editNotice)="onEditNotice($event)"
                            [selected]="(selectedCardId$ | async) === notice.id"
                            (selectionChange)="onSelectionChange(notice.id)"
                            [attr.data-notice-id]="notice.id"
                            [linkList]="notice.linkList"
                            [attachmentList]="notice.attachmentList"
                            [organisationId]="organisationId"
                            ></app-notice-card>
                        </div>
                        </div>
                    </ng-container>
                    </div>
                    
                    <!-- Now render the Completed group last -->
                    <div *ngIf="filteredNotices.COMPLETED?.length > 0">
                    <h4 
                        (click)="toggleGroup('COMPLETED')" 
                        [ngClass]="{'cursor-pointer': !searchMode}" 
                        class="mb-16 group"
                        [attr.data-group-key]="'COMPLETED'"
                    >
                        <i class="mr-16 w-16" *ngIf="!searchMode" 
                        [ngClass]="isGroupExpanded('COMPLETED') ? 'fa-light fa-chevron-down' : 'fa-regular fa-chevron-right'"></i>
                        Completed
                    </h4>
                    <div [@expandCollapse]="isGroupExpanded('COMPLETED') ? 'expanded' : 'collapsed'">
                        <div *ngFor="let notice of filteredNotices.COMPLETED">
                        <app-notice-card
                            [name]="notice.name" 
                            [id]="notice.id"
                            [applicability]="getApplicabilityDisplay(notice.applicability)"
                            [type]="getTypeDisplay(notice.type)"
                            [dueBy]="notice.dueBy"
                            [description]="notice.description"
                            [issuedBy]="notice.issuedBy"
                            [dateCreated]="notice.creationTime"
                            [isExpanded]="isExpanded(notice.id)"
                            (toggleExpand)="toggleExpandCollapse(notice.id)"
                            (noticeSelected)="onNoticeSelected($event)"
                            (editNotice)="onEditNotice($event)"
                            [selected]="(selectedCardId$ | async) === notice.id"
                            (selectionChange)="onSelectionChange(notice.id)"
                            [attr.data-notice-id]="notice.id"
                        ></app-notice-card>
                        </div>
                    </div>
                    </div>
                </div>
                
                
                
            
            </div>
            <div class="notices-right-panel d-flex flex-column">
                <div class="d-flex space-between space-16 px-16 mb-32">
                    <h4 class="m-0">Maintenance Request</h4>
                    <div class="d-flex space-between ">
                        <button
                        type="button"
                        (click)="linkRequest()"
                        [disabled]="!isAnyCardSelected()"
                        class="btn-base bg-transparent color-blue border-none"
                    >
                    <i class="fa-regular fa-link"></i>
                    Maintenance Request
                    </button>
                        <button 
                        [disabled]="!isAnyCardSelected()"
                            class="btn btn-default-action btn-sm" 
                            type="button"
                            (click)="createBulkMaintenance()">
                            <i class="fa fa-plus"></i>
                            Create Request
                        </button>
                    </div>
                </div>
                <enhanced-help componentName="screen" [compact]="true">
                </enhanced-help>
                <notices-maintenance-request-list></notices-maintenance-request-list> 
                <div class="d-flex space-between px-16 mt-auto align-end">
                    <button
                    type="button"
                    (click)="confirmStatusChange()"
                    [disabled]="!isAnyCardSelected()"
                    class="btn btn-primary"
                >
                    <i class="fa-regular fa-circle-check"></i>
                        Sign Off on Airworthiness Notice Completion
                    <!-- {{ currentNoticeStatus === 'COMPLETED' ? 'Mark as Incomplete' : 'Mark as Completed' }} -->
                </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
