import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FlyFreelyVideoPlayer } from './flyfreely-video.component';
import { EmbeddedVideoPlayer } from './video-player/embedded-video-player.component';

@NgModule({
    imports: [
        CommonModule,
        TooltipModule,

        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ],
    declarations: [FlyFreelyVideoPlayer, EmbeddedVideoPlayer],
    exports: [FlyFreelyVideoPlayer, EmbeddedVideoPlayer]
})
export class FlyFreelyVideoModule {}
