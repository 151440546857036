<div class="flight_preferences_container main_container">
    <div
        class="flight_preferences_flying onboarding-frame"
        (click)="nextScreen('flying')"
    >
        <div class="flying-img-container">
            <img imgPath="/onboarding/flying-assets/individual-flying.png" />
        </div>
        <div class="rowone fill">
            <h4>
                YES. I will be involved in flight operations or maintenance.
            </h4>
            <p>Select this if you plan on having one of the following roles:</p>
            <ul>
                <li>Student Pilot</li>
                <li>Remote Pilot</li>
                <li>Chief Remote Pilot</li>
                <li>Maintenance Controller</li>
                <li>Maintenance Person</li>
                <li>Observer</li>
                <li>Payload Operator</li>
            </ul>
        </div>
    </div>
    <div
        class="flight_preferences_notflying onboarding-frame"
        (click)="nextScreen('notflying')"
    >
        <div class="flying-img-container">
            <img
                imgPath="/onboarding/flying-assets/individual-not-flying.jpg"
            />
        </div>
        <div class="rowone fill">
            <h4>
                NO. I will not be involved in flight operations or maintenance.
            </h4>
            <p>Select this if you plan on having one of the following roles:</p>
            <ul>
                <li>Administrator</li>
                <li>Planner</li>
                <li>Project Manager</li>
            </ul>
        </div>
    </div>
    <div class="custom--btn">
        <button (click)="previousScreen()">Back</button>
    </div>
</div>
