<ng-container screenAnalytics="location-view">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-locations.png" />
        </div>
        <h3 class="modal-title">View Location</h3>
        <p class="modal-title padding" *ngIf="location">
            {{ location.name }}
        </p>
        <knowledge-base-link></knowledge-base-link>
        <button type="button" (click)="hide()" aria-label="Close" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div>
        <ngx-loading [show]="working || location == null"></ngx-loading>
        <div class="modal-body vertical-container max-height no-padding">
            <flyfreely-map
                #map
                class="fill"
                *ngIf="location != null && location.features.length > 0"
                [organisationId]="organisationId"
                [features]="features"
                [showFeatureSelection]="false"
                [startTime]="airspaceValue?.startTime"
                [endTime]="airspaceValue?.endTime"
                [ruleset]="airspaceValue?.airspaceRuleset"
                (fullscreenRequested)="onFullscreenRequested()"
                (featuresLoaded)="map.zoomToAllFeatures(false)"
                (jurisdictionChanged)="showAirspaceDisclaimerDialogue()"
            >
                <ng-template mapSidebar let-map="map">
                    <location-airspace-check
                        [organisationId]="organisationId"
                        [timeZone]="location?.timeZone"
                        [flightArea]="flightArea"
                        [workTracker]="workTracker"
                        [jurisdiction]="map.jurisdictionChanged | async"
                        (checkerParametersChange)="airspaceValue = $event"
                    ></location-airspace-check>
                </ng-template>
            </flyfreely-map>
            <div *ngIf="location != null && location.features.length == 0">
                No flight area defined
            </div>
            <inline-modal
                *ngIf="
                    location &&
                    location.airspaceJurisdiction &&
                    location.airspaceJurisdiction.airspaceDisclaimer &&
                    location.airspaceJurisdiction.airspaceDisclaimer.length
                "
                [show]="showAirspaceDisclaimer"
                [sizeSmall]="true"
                [topSpacing]="true"
                style="max-height: fit-content"
            >
                <airspace-disclaimer
                    screenIdentifier="location-view"
                    [airspaceJurisdiction]="location.airspaceJurisdiction"
                    (done)="onAirspaceDisclaimerAcknowledged()"
                ></airspace-disclaimer>
            </inline-modal>
        </div>

        <div class="modal-footer">
            <button
                *ngIf="location != null && location.availableActions.canDelete"
                class="btn btn-danger pull-left"
                type="button"
                (click)="deleteLocation()"
                [disabled]="working"
            >
                <span class="fal fa-trash-alt"></span>
                Delete
            </button>
            <a
                *ngIf="location != null && location.features.length > 0"
                class="btn btn-link"
                type="button"
                target="_blank"
                (click)="downloadKml()"
                analyticsOn
                analyticsAction="download-kml"
            >
                Download KML
            </a>
            <button
                type="button"
                class="btn btn-default"
                analyticsOn
                analyticsAction="create-mission"
                [disabled]="location == null || working"
                (click)="createMission()"
            >
                Create Mission
            </button>
            <button
                *ngIf="location != null && location.availableActions.canEdit"
                class="btn btn-primary"
                type="button"
                (click)="edit()"
                [disabled]="working"
            >
                Edit
            </button>
        </div>
    </div>
</ng-container>
