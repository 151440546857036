/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface ComponentTypeDto { 
    id: number;
    resourceCategory: ComponentTypeDto.ResourceCategory;
    name: string;
    enabled: boolean;
    /**
     * The short icon name
     */
    icon?: string;
    /**
     * The family of icons to us
     */
    iconFamily?: ComponentTypeDto.IconFamily;
}
export namespace ComponentTypeDto {
    export type ResourceCategory = 'CRAFT' | 'BATTERY_SET' | 'BATTERY' | 'EQUIPMENT' | 'UNLISTED_RPA';
    export const ResourceCategory = {
        CRAFT: 'CRAFT' as ResourceCategory,
        BATTERY_SET: 'BATTERY_SET' as ResourceCategory,
        BATTERY: 'BATTERY' as ResourceCategory,
        EQUIPMENT: 'EQUIPMENT' as ResourceCategory,
        UNLISTED_RPA: 'UNLISTED_RPA' as ResourceCategory
    };
    export type IconFamily = 'FONT_AWESOME';
    export const IconFamily = {
        FONT_AWESOME: 'FONT_AWESOME' as IconFamily
    };
}



