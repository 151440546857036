import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AttachmentHandler,
    CurrentAttachmentVersionDto,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    Organisation,
    PersonRolesDto,
    PersonService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { LinkAction } from 'libs/attachments/src/lib/link-manager/link-manager.interface';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { GeneralTask } from 'libs/flyfreely/src/lib/services/generalTasks.service';
import {
    formatGeneralTasksPriority,
    formatGeneralTasksStatuses,
    GeneralTasksDataService
} from 'libs/outstanding-tasks/src/lib/widgets/general-task/general-tasks-data.service';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'general-task-details',
    templateUrl: './general-task-details.component.html',
    styleUrls: ['./general-task-details.component.scss']
})
export class GeneralTaskDetailsComponent implements OnInit {
    @Input() generalTask: GeneralTask;
    @Input() organisation: Organisation;
    @Output() edit = new EventEmitter<void>();
    @Output() deleted = new EventEmitter<void>();

    downloadUrlFn = (attachment: CurrentAttachmentVersionDto) => {
        return `webapi/tasks/${this.generalTask.id}/attachments/${attachment.id}?managingOrganisationId=${this.organisation.id}&attachmentVersionId=${attachment.attachmentVersionId}`;
    };

    attachmentsHandler: AttachmentHandler;
    private workTracker = new WorkTracker();
    working: boolean = false;
    canDelete: boolean;
    canEdit: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    personList$: Observable<PersonRolesDto[]>;

    statusOptions = formatGeneralTasksStatuses;

    priorityOptions = formatGeneralTasksPriority;

    constructor(
        private personService: PersonService,
        private generalTasksDataService: GeneralTasksDataService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit(): void {
        this.personList$ = this.personService.findPersonnel(
            this.organisation.id
        );
        this.refreshGeneralTasks();
        this.attachmentsHandler =
            this.generalTasksDataService.attachmentHandler(
                this.generalTask.id,
                this.organisation.id
            );
    }

    private refreshGeneralTasks(): void {
        this.generalTasksDataService
            .findTaskById(this.generalTask.id)
            .subscribe({
                next: result => {
                    this.generalTask = result;
                    this.refreshPermissions();
                }
            });
    }

    private refreshPermissions() {
        if (this.generalTask == null) {
            this.canDelete = false;
            this.canEdit = false;
            return;
        }
        this.canEdit = this.generalTask.availableActions.canEdit;
        this.canDelete = this.generalTask.availableActions.canDelete;
    }

    editTask() {
        this.edit.emit();
    }

    onDeleteTask() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete this task?',
                `Are you sure you wish to delete this task: ${this.generalTask.summary}`,
                'Delete',
                () =>
                    firstValueFrom(
                        this.generalTasksDataService.deleteTask(
                            this.generalTask.id
                        )
                    )
            )
            .then(
                () => Promise.resolve(),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error delete Task: ${error.message}`
                    )
            )
            .finally(() => this.deleted.emit())
            .catch(DO_NOTHING);
    }

    protected readonly LinkAction = LinkAction;
}
