/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.25-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UpdateSortieCrewCommand } from './updateSortieCrewCommand';
import { FormResponseCommand } from './formResponseCommand';
import { Point } from 'geojson';


/**
 * The details of a sortie that is being completed
 */
export interface CompleteSortieCommand { 
    id?: number;
    /**
     * The number of the sortie, starting at 1
     */
    number: number;
    /**
     * A name given to this sortie
     */
    name?: string;
    /**
     * An optional value of the duration of the flight in seconds
     */
    manualDuration?: number;
    durationSource?: CompleteSortieCommand.DurationSource;
    /**
     * The crew of this sortie
     */
    crew?: Array<UpdateSortieCrewCommand>;
    /**
     * What is the status of the sortie on completion
     */
    status: CompleteSortieCommand.Status;
    /**
     * The manually collected sortie start time
     */
    manualStartTime?: string;
    /**
     * The reason for a negative outcome on a mission
     */
    reason?: string;
    /**
     * The battery set used on this sortie
     */
    batterySetId?: number;
    /**
     * The craft used on this sortie
     */
    craftId?: number;
    /**
     * The pilot who flew the sortie
     */
    pilotId?: number;
    /**
     * The extra form responses for this particular sortie
     */
    formResponses?: { [key: string]: Array<FormResponseCommand>; };
    /**
     * The visual line of sight option of sortie
     */
    visualLineOfSight?: CompleteSortieCommand.VisualLineOfSight;
    /**
     * The time of day in which sortie occur
     */
    timeOfDay?: CompleteSortieCommand.TimeOfDay;
    /**
     * The flight issue status default is no_issues
     */
    flightIssueStatus?: CompleteSortieCommand.FlightIssueStatus;
    /**
     * The list of FlightInterruptions
     */
    interruptions?: Array<CompleteSortieCommand.Interruptions>;
    /**
     * The flight notes
     */
    notes?: string;
    equipmentIds: Array<number>;
    manualLandingPoint?: Point;
    manualTakeoffPoint?: Point;
    duration?: number;
}
export namespace CompleteSortieCommand {
    export type DurationSource = 'TIMER' | 'MANUAL' | 'LOGS';
    export const DurationSource = {
        TIMER: 'TIMER' as DurationSource,
        MANUAL: 'MANUAL' as DurationSource,
        LOGS: 'LOGS' as DurationSource
    };
    export type Status = 'STARTED' | 'COMPLETED' | 'ABORTED' | 'NOT_STARTED';
    export const Status = {
        STARTED: 'STARTED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        ABORTED: 'ABORTED' as Status,
        NOT_STARTED: 'NOT_STARTED' as Status
    };
    export type VisualLineOfSight = 'VLOS' | 'EVLOS' | 'BVLOS';
    export const VisualLineOfSight = {
        VLOS: 'VLOS' as VisualLineOfSight,
        EVLOS: 'EVLOS' as VisualLineOfSight,
        BVLOS: 'BVLOS' as VisualLineOfSight
    };
    export type TimeOfDay = 'DAY' | 'NIGHT' | 'DAY_NIGHT';
    export const TimeOfDay = {
        DAY: 'DAY' as TimeOfDay,
        NIGHT: 'NIGHT' as TimeOfDay,
        DAY_NIGHT: 'DAY_NIGHT' as TimeOfDay
    };
    export type FlightIssueStatus = 'NO_ISSUES' | 'INTERRUPTIONS' | 'INCIDENTS_OR_ACCIDENTS';
    export const FlightIssueStatus = {
        NO_ISSUES: 'NO_ISSUES' as FlightIssueStatus,
        INTERRUPTIONS: 'INTERRUPTIONS' as FlightIssueStatus,
        INCIDENTS_OR_ACCIDENTS: 'INCIDENTS_OR_ACCIDENTS' as FlightIssueStatus
    };
    export type Interruptions = 'BIRD_ACTIVITY' | 'PROJECTILES' | 'AIRCRAFT_PROXIMITY_GROUNDING' | 'PEOPLE_IN_AREA' | 'HARD_LANDING' | 'CHALLENGED_BY_AUTHORITIES_OR_PEOPLE' | 'CHANGE_IN_WEATHER' | 'OTHER' | 'CHANGE_IN_VISIBILITY';
    export const Interruptions = {
        BIRD_ACTIVITY: 'BIRD_ACTIVITY' as Interruptions,
        PROJECTILES: 'PROJECTILES' as Interruptions,
        AIRCRAFT_PROXIMITY_GROUNDING: 'AIRCRAFT_PROXIMITY_GROUNDING' as Interruptions,
        PEOPLE_IN_AREA: 'PEOPLE_IN_AREA' as Interruptions,
        HARD_LANDING: 'HARD_LANDING' as Interruptions,
        CHALLENGED_BY_AUTHORITIES_OR_PEOPLE: 'CHALLENGED_BY_AUTHORITIES_OR_PEOPLE' as Interruptions,
        CHANGE_IN_WEATHER: 'CHANGE_IN_WEATHER' as Interruptions,
        OTHER: 'OTHER' as Interruptions,
        CHANGE_IN_VISIBILITY: 'CHANGE_IN_VISIBILITY' as Interruptions
    };
}



