import { Injectable } from '@angular/core';
import {
    BulkEditOptions,
    BulkImportErrorText,
    ColumnOptionTypes,
    ColumnOptions,
    CreateEquipmentCommand,
    DO_NOTHING,
    EquipmentDto,
    EquipmentModelService,
    EquipmentService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameValue,
    WorkTracker,
    checkRequiredValidity,
    downloadCsv,
    parseCsvByHeadings,
    removeEmptyRows
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { invalidFieldText } from 'libs/bulk-uploads/src/lib/helpers';
import { BulkImportDialogue } from 'libs/bulk-uploads/src/lib/import-dialogue/import-dialogue.component';
import { BulkUploadDialogue } from 'libs/bulk-uploads/src/lib/upload-dialogue/upload-dialogue.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Columns } from 'ngx-easy-table';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateEquipmentTypeDialogue } from './create-equipment-type-dialogue.component';

interface ImportEquipment extends CreateEquipmentCommand {
    make: string;
    model: string;
    makeModel: string;
}

const template: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            {
                key: 'currentFirmwareVersion',
                props: {
                    label: 'Firmware Version'
                }
            },
            {
                key: 'make',
                props: {
                    label: 'Make'
                }
            },
            {
                key: 'model',
                props: {
                    label: 'Model'
                }
            },
            {
                key: 'manufacturerSerialNumber',
                props: {
                    label: 'Serial Number'
                }
            },
            {
                key: 'name',
                props: {
                    label: 'Name'
                }
            },
            {
                key: 'nfcId',
                props: {
                    label: 'NFC ID'
                }
            }
        ]
    },
    props: {
        label: 'Bulk_Equipment_Import'
    }
};

const templateData = [
    {
        currentFirmwareVersion: '1.2.5',
        make: 'DJI',
        model: 'CrystalSky',
        manufacturerSerialNumber: '123456e12',
        name: 'Crystal 1',
        nfcId: '02 9B 54 D5 45 2B 88'
    }
];

const columns: Columns[] = [
    { key: 'currentFirmwareVersion', title: 'Firmware Version' },
    { key: 'makeModel', title: 'Make & Model' },
    { key: 'manufacturerSerialNumber', title: 'Serial Number' },
    { key: 'name', title: 'Name' },
    { key: 'nfcId', title: 'NFC ID' }
];

const bulkEditOptions: BulkEditOptions = {
    cannotBulkEdit: ['manufacturerSerialNumber', 'nfcId'],
    uniqueInSet: ['name']
};

@Injectable()
export class EquipmentUploadService {
    organisationId: number;
    equipmentTypes: NameValue<number>[];
    existingEquipment: EquipmentDto[];
    equipmentMakes: string[];
    equipmentModels: string[];

    uploadDialogue: BsModalRef<BulkUploadDialogue>;
    importDialogue: BsModalRef<BulkImportDialogue>;

    template: CreateEquipmentCommand;
    importedFile: ImportEquipment[];

    columnOptions: ColumnOptions[];
    columnOptionsSubject = new ReplaySubject<ColumnOptions[]>();
    failedImportTextSubject = new ReplaySubject<BulkImportErrorText[]>();
    bulkEditOptionsSubject = new ReplaySubject<BulkEditOptions>();
    bulkEditOptions$ = this.bulkEditOptionsSubject.asObservable();
    columnOptions$ = this.columnOptionsSubject.asObservable();
    failedImportText$ = this.failedImportTextSubject.asObservable();
    failedImportText: BulkImportErrorText[] = [];

    workingSubject = new ReplaySubject<boolean>();
    doneImportingSubject = new Subject<void>();
    doneImporting$ = this.doneImportingSubject.asObservable();
    working$ = this.workingSubject.asObservable();
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private equipmentService: EquipmentService,
        private equipmentModelService: EquipmentModelService,
        private modalService: BsModalService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSubject.next(working));

        this.bulkEditOptionsSubject.next(bulkEditOptions);
    }

    ngOnDestroy() {
        this.doneImportingSubject.complete();
        this.workingSubject.complete();
        this.columnOptionsSubject.complete();
        this.failedImportTextSubject.complete();
        this.bulkEditOptionsSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    showBulkUpload(organisationId: number) {
        this.organisationId = organisationId;
        this.uploadDialogue = this.modalService.show(BulkUploadDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                uploadService: this,
                hasTemplate: true,
                entity: 'Equipment'
            }
        });

        this.refreshEquipmentTypes(organisationId);

        this.equipmentService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => (this.existingEquipment = results))
            .add(this.workTracker.createTracker());
    }

    importFile(file: File) {
        parseCsvByHeadings<ImportEquipment>(file, {
            'Firmware Version': 'currentFirmwareVersion',
            Make: 'make',
            Model: 'model',
            'Serial Number': 'manufacturerSerialNumber',
            Name: 'name',
            'NFC ID': 'nfcId'
        }).then(result => {
            // Sometimes the csv parser doesn't filter out empty rows.
            // The filtered variable is a redundancy check to ensure empty rows are ignored.
            const filtered = removeEmptyRows<ImportEquipment[]>(result);
            this.importedFile = filtered.map(r => {
                return {
                    ...r,
                    makeModel:
                        checkRequiredValidity(r.make) === 'INVALID' ||
                        checkRequiredValidity(r.model) === 'INVALID'
                            ? 'INVALID'
                            : `${r.make} ${r.model}`,
                    manufacturerSerialNumber: checkRequiredValidity(
                        r.manufacturerSerialNumber
                    ),
                    name: checkRequiredValidity(r.name)
                };
            });
            this.setupColumnOptions();
            this.importedFile.forEach((row, i) =>
                this.validateRow(row, i, this.columnOptions)
            );
            this.showImportDialogue();
            this.uploadDialogue.hide();
        });
    }

    refreshEquipmentTypes(organisationId: number) {
        this.equipmentModelService
            .find(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => {
                this.equipmentTypes = results.map(t => ({
                    name: `${t.make} ${t.model}`,
                    value: t.id
                }));
                const allMakes = results.map(t => t.make);
                const filteredMakes: string[] = [];
                allMakes.forEach(make => {
                    if (filteredMakes.findIndex(m => m === make) === -1) {
                        filteredMakes.push(make);
                    }
                });
                const allModels = results.map(t => t.model);
                const filteredModels: string[] = [];
                allModels.forEach(model => {
                    if (filteredModels.findIndex(m => m === model) === -1) {
                        filteredModels.push(model);
                    }
                });
                this.equipmentMakes = filteredMakes;
                this.equipmentModels = filteredModels;
                this.setupColumnOptions();
            })
            .add(this.workTracker.createTracker());
    }

    setupColumnOptions() {
        const makeModel = [];
        makeModel.push({
            name: 'Add a new equipment type',
            value: 'Add a new equipment type'
        });
        this.equipmentTypes.forEach(t =>
            makeModel.push({ name: t.name, value: t.name })
        );
        this.columnOptions = [
            {
                columnName: 'makeModel',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: makeModel.sort((a, b) => (a.name > b.name ? 1 : -1))
            }
        ];
        this.columnOptionsSubject.next(this.columnOptions);
    }

    validateRow(
        row: ImportEquipment,
        rowIndex: number,
        columnOptions: ColumnOptions[]
    ) {
        // Validate each row and add ' *INVALID*' markers to any invalid
        if (row.makeModel === 'Add a new equipment type') {
            // launch an add-type dialogue
            this.showNewEquipmentDialogue(row, rowIndex);
            return;
        }
        if (
            this.equipmentTypes.findIndex(e =>
                e.name
                    .toUpperCase()
                    .includes(row.makeModel.trim().toUpperCase())
            ) === -1
        ) {
            this.updateErrorText(
                rowIndex,
                'makeModel',
                `${row.makeModel} is an unknown make and model. Please ensure this make and model are setup in your organisation`
            );
            row.makeModel =
                row.makeModel === 'INVALID'
                    ? 'INVALID'
                    : `${row.makeModel} ${invalidFieldText}`;
        }
        this.existingEquipment.forEach(e => {
            if (e.name.toUpperCase() === row.name.toUpperCase()) {
                this.updateErrorText(
                    rowIndex,
                    'name',
                    `${row.name} already exists for this organisation`
                );
            }
            if (e.manufacturerSerialNumber === row.manufacturerSerialNumber) {
                this.updateErrorText(
                    rowIndex,
                    'manufacturerSerialNumber',
                    `${row.name} already exists for this organisation`
                );
                row.manufacturerSerialNumber =
                    row.manufacturerSerialNumber === 'INVALID'
                        ? 'INVALID'
                        : `${row.manufacturerSerialNumber} ${invalidFieldText}`;
            }
            if (
                this.failedImportText.findIndex(
                    t => t.rowIndex === rowIndex && t.key === 'name'
                ) !== -1 &&
                !row.name.includes(invalidFieldText)
            ) {
                row.name =
                    row.name === 'INVALID'
                        ? 'INVALID'
                        : `${row.name} ${invalidFieldText}`;
            }
        });
        this.failedImportTextSubject.next(this.failedImportText);

        return row;
    }

    showNewEquipmentDialogue(row: ImportEquipment, rowIndex: number) {
        const dialogue = this.modalService.show<CreateEquipmentTypeDialogue>(
            CreateEquipmentTypeDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-task',
                initialState: {
                    organisationId: this.organisationId,
                    makes: this.equipmentMakes,
                    models: this.equipmentModels,
                    make: row.make,
                    model: row.model
                }
            }
        );

        dialogue.content.createdType
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                row.makeModel = `${result.make} ${result.model}`;
                this.refreshEquipmentTypes(this.organisationId);
            });

        dialogue.onHide.subscribe(() => {
            if (row.makeModel === 'Add a new equipment type') {
                row.makeModel = `${row.make} ${row.model}`;
            }
            this.validateRow(row, rowIndex, this.columnOptions);
        });
    }

    showImportDialogue() {
        this.importDialogue = this.modalService.show(BulkImportDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                uploadService: this,
                data: this.importedFile,
                columns: columns,
                entity: 'Equipment'
            }
        });
    }

    import(selected: number[], columnOptions: ColumnOptions[]) {
        const data = selected.map(n => this.importedFile[n]);
        /**
         * Validity checks with individual feedback for clarity.
         */
        const failed: number[] = [];
        data.map((e, i) => {
            if (Object.keys(e).filter(key => e[key] === 'INVALID').length > 0) {
                failed.push(i);
            }
        });

        data.forEach((entry, i) => {
            const rowIndex = this.importedFile.findIndex(e => e === entry);
            if (
                Object.keys(entry).filter(key => entry[key] === 'INVALID')
                    .length > 0
            ) {
                return;
            }
            if (
                this.equipmentTypes.findIndex(
                    e =>
                        e.name.toUpperCase() ===
                        entry.makeModel.trim().toUpperCase()
                ) === -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'makeModel',
                    `${entry.makeModel} is an unknown make and model. Please ensure this make and model are setup in your organisation`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            this.existingEquipment.forEach(e => {
                if (
                    e.name.toUpperCase() === entry.name.toUpperCase() ||
                    e.manufacturerSerialNumber ===
                        entry.manufacturerSerialNumber
                ) {
                    this.updateErrorText(
                        rowIndex,
                        'manufacturerSerialNumber',
                        `${entry.name} already exists for this organisation`
                    );
                    if (failed.includes(i) === false) {
                        failed.push(i);
                    }
                }
            });
        });

        const validEntries = data.filter(
            (e, i) => failed.includes(i) === false
        );

        if (validEntries.length > 0) {
            const command: CreateEquipmentCommand[] = validEntries.map(
                entry => ({
                    isDummy: false,
                    manufacturerSerialNumber: entry.manufacturerSerialNumber,
                    name: entry.name,
                    organisationId: this.organisationId,
                    currentFirmwareVersion: entry.currentFirmwareVersion,
                    nfcId: entry.nfcId,
                    equipmentTypeId: this.equipmentTypes.find(
                        t =>
                            t.name.toUpperCase() ===
                            `${entry.makeModel.trim().toUpperCase()}`
                    ).value,
                    initialComponentList: []
                })
            );

            let completed: boolean = true;
            command.forEach(cmd => {
                this.equipmentService
                    .create(cmd)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(DO_NOTHING, (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error creating ${cmd.name}: ${error.message}`
                        );
                        completed = false;
                    })
                    .add(this.workTracker.createTracker());
            });
            if (!completed) {
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
                return;
            } else {
                this.logging.success(
                    `Successfully imported ${command.length} items.`
                );
                if (failed.length > 0) {
                    this.importDialogue.content.importedRowIndices =
                        this.importedFile.map((row, i) => {
                            if (validEntries.includes(row)) {
                                return i;
                            }
                        });
                    this.importedFile = this.importedFile.filter(
                        e => validEntries.includes(e) === false
                    );
                    this.importDialogue.content.onFailedRows();
                    this.logging.warn(
                        'Some imported rows have failed, please fix all errors and try again'
                    );
                    this.failedImportTextSubject.next(this.failedImportText);
                    this.failedImportText = [];
                } else {
                    this.importDialogue.hide();
                    this.doneImportingSubject.next();
                }
            }
        } else {
            if (failed.length > 0) {
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
            }
        }
    }

    downloadTemplate() {
        downloadCsv(template, templateData);
    }

    updateErrorText(rowIndex: number, key: string, text: string) {
        if (
            this.failedImportText.findIndex(
                t => t.key === key && t.rowIndex === rowIndex
            ) !== -1
        ) {
            return;
        } else {
            this.failedImportText.push({
                rowIndex: rowIndex,
                key: key,
                text: text
            });
        }
    }
}
