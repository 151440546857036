import { Component } from '@angular/core';
import {
    downloadCsv,
    FlyFreelyLoggingService,
    FormControlDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { downloadTemplate, parseCsvFile } from './helpers';

/**
 * A multi-entry section that uses a 12-column layout. This includes the section header so that
 * we can embed the add button as per the style guide.
 */

@Component({
    selector: 'formly-12-column',
    template: `
        <div>
            <h4 class="section-heading">
                <button
                    type="button"
                    class="btn-circle btn-link"
                    (click)="add()"
                >
                    <span class="fa fa-plus"></span></button
                ><span>{{ to.label }}</span>
                <div></div>
                <div class="btn-group" dropdown>
                    <button
                        dropdownToggle
                        type="button"
                        class="btn btn-link dropdown-toggle"
                        aria-controls="dropdown-basic"
                    >
                        ...
                    </button>
                    <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                    >
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                (click)="downloadTemplate()"
                                >Download Template</a
                            >
                        </li>
                        <li role="menuitem">
                            <a class="dropdown-item" (click)="downloadCsv()"
                                >Download CSV</a
                            >
                        </li>
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                ngfSelect
                                accept="text/csv,application/vnd.ms-excel"
                                (fileChange)="uploadCsv($event)"
                                (lastInvalidsChange)="invalidFile($event)"
                                [files]="files"
                                >Upload CSV</a
                            >
                        </li>
                    </ul>
                </div>
            </h4>
            <p>{{ to.text }}</p>

            <ng-container *ngFor="let row of model; let rowIndex = index">
                <a [name]="field.fieldGroup[rowIndex].id"></a>
                <h4>
                    Entry {{ rowIndex + 1 }}
                    <button
                        type="button"
                        class="btn btn-tertiary btn-delete"
                        [disabled]="working"
                        (click)="remove(rowIndex)"
                        tooltip="Delete"
                        placement="left"
                    >
                        <span class="fal fa-trash-alt"></span>
                    </button>
                </h4>
                <div class="row" *ngFor="let fieldRow of layout">
                    <div
                        *ngFor="let column of fieldRow"
                        [class]="'col-sm-' + column.columnSpan"
                    >
                        <formly-field
                            *ngFor="let control of column.controls"
                            [field]="getField(control.prop, rowIndex)"
                        ></formly-field>
                    </div>
                </div>
            </ng-container>
            <div class="bottom-buffer" *ngIf="model.length > 0">
                <button
                    class="btn btn-section-action"
                    type="button"
                    (click)="add()"
                >
                    <div class="circle">
                        <span class="fa fa-plus"></span>
                    </div>
                    Add New Entry
                </button>
            </div>
        </div>
    `
})
export class Formly12Column extends FieldArrayType {
    layout: { columnSpan: number; controls: { prop: string }[] }[][];

    // Files is used by the file uploader to manage file names uploaded and allow re-uploading the same file.
    files: File[];

    working: boolean;
    workTracker: WorkTracker = new WorkTracker();
    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(private logging: FlyFreelyLoggingService) {
        super();
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        // Check if fieldArray is a function and call it if necessary
        const fieldArray = <FormlyFieldConfig>this.field.fieldArray;

        const controls = fieldArray?.fieldGroup?.reduce(
            (
                acc: { columnSpan: number; controls: { prop: string }[] }[],
                field,
                ix
            ) => {
                if (field.props.columnSpan === 0) {
                    return acc;
                }
                if (acc.length === 0) {
                    return [
                        {
                            columnSpan: field.props.columnSpan,
                            controls: [{ prop: <string>field.key }]
                        }
                    ];
                }

                const previousField =
                    fieldArray.fieldGroup[ix - 1].props.flyfreelyField;
                const thisField: FormControlDto = field.props.flyfreelyField;

                if (
                    previousField != null &&
                    thisField.displayCondition?.subjectControlId ===
                        previousField.displayCondition?.subjectControlId &&
                    thisField.groupWithPreviousControl === true
                ) {
                    acc[acc.length - 1].controls.push({
                        prop: <string>field.key
                    });
                    return acc;
                }

                return acc.concat({
                    columnSpan: field.props.columnSpan,
                    controls: [{ prop: <string>field.key }]
                });
            },
            []
        );

        this.layout = [[]];
        let cols = 0;
        for (const controlGroups of controls) {
            if (cols + controlGroups.columnSpan > 12) {
                this.layout.push([]);
                cols = 0;
            }

            this.layout[this.layout.length - 1].push(controlGroups);

            cols += controlGroups.columnSpan;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    getField(column: string, rowIndex: number): FormlyFieldConfig {
        return this.field.fieldGroup[rowIndex].fieldGroup.find(
            f => f.key === column
        );
    }

    downloadTemplate() {
        downloadTemplate(this.field);
    }

    downloadCsv() {
        downloadCsv(this.field, this.formControl.value);
    }

    uploadCsv(file: File) {
        const { allowAnyKeyType = false } = this.field?.props || {};

        parseCsvFile(file, this.field, allowAnyKeyType).then(
            results => {
                // Clearing the files list allows re-uploading the same file name multiple times if required.
                this.files = [];
                let validCount = results.length;
                results.forEach(result => {
                    const values = Object.values(result);

                    if (values.every(val => !val)) {
                        this.logging.error(
                            null,
                            `Invalid Form Field and Value: imported CSV does not match the format of this form section`
                        );
                        validCount--;
                        return;
                    }
                    this.add(undefined, result);
                });
                this.logging.success(`Imported ${validCount} results`);
            },
            error => this.logging.error(error, error)
        );
    }

    invalidFile(file: File[]) {
        if (!file) {
            return;
        }
        this.logging.error(null, 'This file type is not supported');
    }
}
