<div
    class="card-container"
    [ngClass]="{
        selected: selected,
        pending: statusStyle === 'PENDING',
        active: statusStyle === 'ACTIVE',
        inactive: statusStyle === 'INACTIVE',
        danger: statusStyle === 'DANGER'
    }"
>
    <div class="d-flex vertical-align space-between mb-8">
        <span class="badge badge-informational">{{ typeName }}</span>
        <span
            class="badge"
            [ngClass]="{
                'badge-pending': statusStyle === 'PENDING',
                'badge-active': statusStyle === 'ACTIVE',
                'badge-inactive': statusStyle === 'INACTIVE',
                'badge-danger': statusStyle === 'DANGER'
            }"
            [tooltip]="statusTooltip"
            placement="top"
            >{{ statusName }}</span
        >
    </div>
    <ng-content></ng-content>
</div>
