<div class="container-centred onboardingscreens_maincontainer">
    <personal-details
        *ngIf="currentTab === 'PERSONAL_DETAILS'"
    ></personal-details>
    <flight-preferences *ngIf="currentTab === 'PERSONAL_FLIGHT_PREFERENCES'">
    </flight-preferences>
    <personal-certification
        *ngIf="currentTab === 'PERSONAL_NONPRIMARY'"
    ></personal-certification>
    <personal-primary
        *ngIf="currentTab === 'PERSONAL_PRIMARY'"
    ></personal-primary>
    <!-- <flight-history *ngIf="currentTab === 'FLIGHT_HISTORY'"></flight-history> -->
    <personal-details-complete
        *ngIf="currentTab === 'PERSONAL_SETUP_COMPLETE'"
    ></personal-details-complete>

    <organisation-details
        *ngIf="currentTab === 'ORGANISATION_DETAILS'"
    ></organisation-details>
    <organisation-certification
        *ngIf="currentTab === 'ORGANISATION_CERTIFICATION'"
    ></organisation-certification>
    <setup-finished
        *ngIf="currentTab === 'ORGANISATION_SETUP_COMPLETE'"
    ></setup-finished>
    <field-app-download
        *ngIf="currentTab === 'FIELD_APP_DOWNLOAD'"
    ></field-app-download>
    <switch-to-desktop
        *ngIf="currentTab === 'SWITCH_TO_DESKTOP'"
    ></switch-to-desktop>
</div>
