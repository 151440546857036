import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CreateCurrencyConfigurationCommand,
    CurrencyConfigurationDto,
    CurrencyService,
    FEATURE_CURRENCY,
    FlyFreelyError,
    FlyFreelyLoggingService,
    JurisdictionService,
    OperatingCategoryValuesDtoObject,
    UpdateCurrencyConfigurationCommand,
    WorkTracker,
    operatingConditionsTypeLookup,
    operatingConditionsValueLookup
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FormatMTOWPipe } from 'libs/ui/src/lib/pipes';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'currency-configuration-dialogue',
    templateUrl: './currency-configuration.component.html'
})
export class CurrencyConfigurationDialogue {
    @Input() airspaceJurisdictionId: number;
    @Input() personnelRegisterId: number;
    @Input() managingOrganisationId: number;

    operatingConditions: {
        type: string;
        value: string;
        id: number;
    }[];
    currencyConfigurations: CurrencyConfigurationDto[];
    editingConfig: number;
    currencyFeatureFlag = [FEATURE_CURRENCY];

    operatingConditionsTypeLookup = operatingConditionsTypeLookup;
    operatingConditionsValueLookup = operatingConditionsValueLookup;

    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    // the ID of the entry being edited
    editing: number;
    formGroup: FormGroup;

    constructor(
        public modal: BsModalRef<CurrencyConfigurationDialogue>,
        modalOptions: ModalOptions,
        private logging: FlyFreelyLoggingService,
        private currencyService: CurrencyService,
        private jurisdictionService: JurisdictionService,
        private commonDialoguesService: CommonDialoguesService,
        private formatMTOWPipe: FormatMTOWPipe
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.editingConfig != null) {
                this.editingConfig = null;
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.formGroup = new FormGroup({
            add: new FormGroup({
                currencyOperatingConditionList: new FormControl(
                    [],
                    [Validators.required]
                ),
                requiredOperatingConditionIdList: new FormControl([]),
                days: new FormControl(undefined),
                requiredFlights: new FormControl(0, [Validators.required]),
                requiredFlightTimeSeconds: new FormControl(0, [
                    Validators.required
                ])
            }),
            edit: new FormGroup({
                currencyOperatingConditionList: new FormControl(
                    [],
                    [Validators.required]
                ),
                requiredOperatingConditionIdList: new FormControl([]),
                days: new FormControl(undefined),
                requiredFlights: new FormControl(0, [Validators.required]),
                requiredFlightTimeSeconds: new FormControl(0, [
                    Validators.required
                ])
            })
        });
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    fetchData() {
        combineLatest([
            this.currencyService.findCurrencyConfiguration(
                this.personnelRegisterId,
                this.managingOrganisationId
            ),
            this.jurisdictionService.findOperatingConditionCategories(
                this.airspaceJurisdictionId
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([currencyConfig, operatingConditionCategories]) => {
                this.currencyConfigurations = currencyConfig;
                this.operatingConditions = operatingConditionCategories.reduce(
                    (acc, v) =>
                        acc.concat(
                            v.values.map(val => ({
                                type:
                                    this.operatingConditionsTypeLookup[
                                        val.type
                                    ] ?? 'Misc',
                                value:
                                    val.type ===
                                    OperatingCategoryValuesDtoObject.Type
                                        .RPA_MTOW
                                        ? `<${this.formatMTOWPipe.transform(
                                              val.value as number
                                          )}`
                                        : this.operatingConditionsValueLookup[
                                              val.value
                                          ],
                                id: val.id
                            }))
                        ),
                    []
                );
            })
            .add(this.workTracker.createTracker());
    }

    addConfig() {
        const value = this.formGroup.controls.add.value;
        const command: CreateCurrencyConfigurationCommand = {
            personnelRegisterId: this.personnelRegisterId,
            days: value.days,
            requiredFlights: value.requiredFlights,
            requiredFlightTimeSeconds: value.requiredFlightTimeSeconds,
            currencyOperatingConditionList:
                value.currencyOperatingConditionList,
            requiredOperatingConditionIdList:
                value.requiredOperatingConditionIdList
        };

        this.currencyService
            .createCurrencyConfiguration(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                value => {
                    this.logging.success(`Added configuration`);
                    this.formGroup.controls.add.reset();
                    this.fetchData();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error adding configuration: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    updateConfig(config: CurrencyConfigurationDto) {
        const value = this.formGroup.controls.edit.value;
        const command: UpdateCurrencyConfigurationCommand = {
            days: value.days,
            requiredFlights: value.requiredFlights,
            requiredFlightTimeSeconds: value.requiredFlightTimeSeconds,
            currencyOperatingConditionIdList: value.currencyOperatingConditionList.reduce(
                (acc, c) => acc.concat(c.id),
                []
            ),
            requiredOperatingConditionIdList: value.requiredOperatingConditionIdList.reduce(
                (acc, c) => acc.concat(c.id),
                []
            )
        };
        this.currencyService
            .updateCurrencyConfiguration(config.id, command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                value => {
                    this.logging.success(`Updated configuration`);
                    this.formGroup.controls.edit.reset();
                    this.editingConfig = null;
                    this.fetchData();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating configuration: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    showEditMode(config: CurrencyConfigurationDto) {
        const currencyOperatingConditionList = config.currencyOperatingConditionList.reduce(
            (acc, c) =>
                acc.concat(c.values).map(val => ({
                    type:
                        this.operatingConditionsTypeLookup[val.type] ?? 'Misc',
                    value:
                        val.type ===
                        OperatingCategoryValuesDtoObject.Type.RPA_MTOW
                            ? `<${this.formatMTOWPipe.transform(
                                  val.value as number
                              )}`
                            : val.value,
                    id: val.id
                })),
            []
        );
        const requiredOperatingConditionList = config.requiredOperatingConditionList.reduce(
            (acc, c) =>
                acc.concat(c.values).map(val => ({
                    type:
                        this.operatingConditionsTypeLookup[val.type] ?? 'Misc',
                    value:
                        val.type ===
                        OperatingCategoryValuesDtoObject.Type.RPA_MTOW
                            ? `<${this.formatMTOWPipe.transform(
                                  val.value as number
                              )}`
                            : val.value,
                    id: val.id
                })),
            []
        );
        this.formGroup.controls.edit.setValue({
            currencyOperatingConditionList: currencyOperatingConditionList,
            requiredOperatingConditionIdList: requiredOperatingConditionList,
            days: config.days,
            requiredFlights: config.requiredFlights,
            requiredFlightTimeSeconds: config.requiredFlightTimeSeconds
        });
        this.formGroup.updateValueAndValidity();
        this.editingConfig = config.id;
    }

    deleteCurrencyConfig(config: CurrencyConfigurationDto) {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete currency configuration',
                'Are you sure you wish to delete this currency configuration?',
                'Delete',
                () =>
                    this.currencyService
                        .deleteCurrencyConfiguration(config.id)
                        .toPromise()
            )
            .then(
                () => {
                    this.fetchData();
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error deleting configuration: ${error.message}`
                    );
                    doneWorking();
                }
            )
            .catch(() => {
                doneWorking();
            });
    }

    cancelEditMode() {
        this.editingConfig = null;
        this.formGroup.controls.edit.reset();
    }

    typesGroup(item: any) {
        return item.type;
    }
}
