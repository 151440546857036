<div
    class="vertical-scroll horizontal-container"
    *ngIf="resource && maintenanceLog && formArray != null"
>
    <div class="col-xs-8">
        <div class="fill table-container no-actions">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 5%;">
                            Done
                        </th>
                        <th style="width: 23%;">
                            Component
                        </th>

                        <th style="width: 23%;">
                            Task
                        </th>

                        <th style="width: 36%;">
                            Notes
                        </th>
                        <th style="width: 10%;"></th>
                    </tr>
                </thead>
                <tbody *ngIf="!working">
                    <tr *ngIf="editing === 'ADD'">
                        <td colspan="4">
                            <maintenance-task-edit
                                [components]="components"
                                [taskTypes]="taskTypes"
                                [inServiceComponents]="inServiceComponents"
                                (getAttachmentUrl)="
                                    getComponentAttachmentUrl($event)
                                "
                                (done)="onAddComponent($event)"
                                (cancel)="onCancelEdit()"
                            ></maintenance-task-edit>
                        </td>
                    </tr>
                    <ng-container
                        *ngFor="let row of formArray.controls; let i = index"
                    >
                        <tr
                            *ngIf="
                                editing === 'EDIT' && i === currentlyEditingId
                            "
                        >
                            <td colspan="4">
                                <maintenance-task-edit
                                    [selectedComponent]="row.value"
                                    [components]="components"
                                    [taskTypes]="taskTypes"
                                    [isRequired]="row.value.required"
                                    [inServiceComponents]="inServiceComponents"
                                    (getAttachmentUrl)="
                                        getComponentAttachmentUrl($event)
                                    "
                                    (done)="onEditComponent($event, i)"
                                    (cancel)="onCancelEdit()"
                                ></maintenance-task-edit>
                            </td>
                        </tr>
                        <tr
                            *ngIf="i !== currentlyEditingId"
                            class="data"
                            (mouseover)="getAttachmentUrl(row.value)"
                        >
                            <td>
                                <div
                                    class="checkbox centered text-center"
                                    *ngIf="row.value.completed != null"
                                >
                                    <label>
                                        <input
                                            type="checkbox"
                                            [(ngModel)]="row.value.completed"
                                            (ngModelChange)="
                                                onCompletedChanged()
                                            "
                                        />
                                        <span class="cr"
                                            ><i
                                                class="cr-icon glyphicon glyphicon-ok"
                                            ></i>
                                        </span>
                                    </label>
                                </div>
                            </td>
                            <td>
                                <span
                                    *ngIf="
                                        findComponentIcon(
                                            row.value.componentId
                                        );
                                        let componentIconClass
                                    "
                                    [class]="componentIconClass"
                                ></span
                                >{{ row.value.componentName }}
                            </td>
                            <td>
                                <span
                                    *ngIf="
                                        findTaskIcon(row.value.taskTypeId);
                                        let taskIconClass
                                    "
                                    [class]="taskIconClass"
                                ></span>
                                {{ row.value.taskTypeName }}
                            </td>
                            <td>
                                {{ row.value.notes }}
                            </td>
                            <td>
                                <button
                                    *ngIf="i != currentlyEditingId"
                                    type="button"
                                    class="btn btn-tertiary"
                                    (click)="editComponent(i)"
                                    [disabled]="editing !== 'NONE' || working"
                                    placement="top"
                                    tooltip="Edit Component"
                                >
                                    <span class="fal fa-pencil-alt"></span>
                                </button>

                                <button
                                    *ngIf="
                                        i != currentlyEditingId &&
                                        row.value.required == false
                                    "
                                    type="button"
                                    class="btn btn-tertiary btn-delete"
                                    (click)="removeComponent(i)"
                                    [disabled]="editing !== 'NONE' || working"
                                    placement="top"
                                    tooltip="Delete component"
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </td></tr
                    ></ng-container>
                    <ng-container
                        *ngIf="
                            (!hasTypes || !hasComponents) &&
                            formArray.value.length == 0
                        "
                    >
                        <tr>
                            <td *ngIf="!hasComponents">
                                No components set up for this type
                            </td>
                            <td *ngIf="hasComponents"></td>
                            <td *ngIf="!hasTypes">
                                No tasks set up for this type
                            </td>
                            <td *ngIf="hasTypes"></td>
                            <td></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div
        class="col-xs-4"
        style="display: flex; align-items: center; justify-content: center;"
    >
        <img
            *ngIf="diagramUrl != null"
            style="max-width: 100%; height: auto;"
            [authenticatedSrc]="diagramUrl"
        />
    </div>
</div>
