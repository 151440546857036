<div class="activity-stream" screenAnalytics="activity-stream">
    <h4 class="activity-panel-heading">Your Notifications</h4>
    <div class="activity-categories">
        <a
            class="activity-tab"
            [ngClass]="{ active: showCurrent }"
            (click)="changeTab(tabs.CURRENT)"
        >
            <span class="activity-tab-text">Current</span>
            <span
                *ngIf="numberOfNew != null && numberOfNew > 0"
                class="new-notifications-counter"
            >
                {{ numberOfNew }}
            </span>
        </a>
        <a
            class="activity-tab"
            [ngClass]="{ active: showHistory }"
            (click)="changeTab(tabs.HISTORY)"
        >
            <span class="activity-tab-text">History</span>
        </a>
    </div>
    <div class="activity-container activity-scrollbar">
        <loading-indicator
            [isLoading]="notificationsLoading"
            style="align-self: center"
        ></loading-indicator>
        <ng-container *ngIf="showCurrent">
            <ng-container
                *ngIf="
                    alertCardIndex != null &&
                    activities != null &&
                    activities.length
                "
            >
                <ng-container *ngFor="let list of checklists">
                    <div
                        *ngIf="!list.archived"
                        class="card-group"
                        [ngClass]="{
                            collapsed: collapsedChecklists.includes(list.type)
                        }"
                        @expandOnEnter
                        @collapseOnLeave
                    >
                        <div
                            class="horizontal-container card-group-heading"
                            style="justify-content: space-between"
                        >
                            <div class="horizontal-container">
                                <button
                                    type="button"
                                    class="btn-link checklist-toggle-button"
                                    (click)="toggleChecklist(list.type)"
                                >
                                    <span
                                        class="fa"
                                        [ngClass]="{
                                            'fa-chevron-up': !collapsedChecklists.includes(
                                                list.type
                                            ),
                                            'fa-chevron-down': collapsedChecklists.includes(
                                                list.type
                                            )
                                        }"
                                    ></span>
                                </button>
                                <span>{{ list.label }}</span>
                            </div>
                            <button
                                *ngIf="list.cards.length"
                                type="button"
                                class="btn archive-button left-buffer"
                                tooltip="Archive"
                                placement="top"
                                (click)="archiveGroup(list.type)"
                            >
                                <span class="fal fa-archive"></span>
                            </button>
                        </div>
                        <ng-container
                            *ngIf="!collapsedChecklists.includes(list.type)"
                        >
                            <ng-container
                                *ngFor="
                                    let activity of list.cards;
                                    let i = index
                                "
                            >
                                <activity-card
                                    [activity]="activity"
                                    [isAlertCard]="i == alertCardIndex"
                                    (cardViewed)="onVideoViewed(activity)"
                                    (complete)="completeCard(activity)"
                                ></activity-card>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <div
                *ngIf="
                    activeNotifications != null && activeNotifications.length
                "
            >
                <ng-container
                    *ngFor="
                        let notification of activeNotifications;
                        let i = index
                    "
                >
                    <notification-card
                        [notification]="notification"
                        (cardRead)="onNotificationRead(notification)"
                    ></notification-card>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="showHistory">
            <ng-container *ngFor="let list of checklists">
                <div
                    *ngIf="list.archived"
                    class="card-group"
                    [ngClass]="{
                        collapsed: collapsedChecklists.includes(list.type)
                    }"
                    @expandOnEnter
                    @collapseOnLeave
                >
                    <div
                        class="horizontal-container card-group-heading"
                        style="justify-content: space-between"
                    >
                        <div class="horizontal-container">
                            <button
                                type="button"
                                class="btn-link checklist-toggle-button"
                                (click)="toggleChecklist(list.type)"
                            >
                                <span
                                    class="fa"
                                    [ngClass]="{
                                        'fa-chevron-up': !collapsedChecklists.includes(
                                            list.type
                                        ),
                                        'fa-chevron-down': collapsedChecklists.includes(
                                            list.type
                                        )
                                    }"
                                ></span>
                            </button>
                            <span>{{ list.label }}</span>
                            <button
                                *ngIf="list.cards.length"
                                type="button"
                                class="btn archive-button left-buffer"
                                tooltip="Unarchive"
                                placement="top"
                                (click)="unarchiveGroup(list.type)"
                            >
                                <span class="fal fa-box-open"></span>
                            </button>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="!collapsedChecklists.includes(list.type)"
                    >
                        <ng-container
                            *ngFor="let activity of list.cards; let i = index"
                        >
                            <activity-card
                                [activity]="activity"
                            ></activity-card>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <div
                *ngIf="
                    completedNotifications != null &&
                    completedNotifications.length
                "
            >
                <ng-container
                    *ngFor="
                        let notification of completedNotifications;
                        let i = index
                    "
                >
                    <notification-card
                        [notification]="notification"
                    ></notification-card>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div style="padding: 5px">
        <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="showAllWhatsNew()"
            angulartics2On="click"
            angularticsCategory="help"
            angularticsAction="whats-new-notifications"
        >
            <span class="fa fa-gift" aria-hidden="true"></span> What's New
        </button>
    </div>
</div>
