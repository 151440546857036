import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    AircraftRegisterEntryDto,
    AssociateAuthorityCommand,
    AuthorityNominatedPersonnelDto,
    AuthorityRegisterConditionsDto,
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    AuthorityVerificationRecordDto,
    AuthorityWorkflowDto,
    AuthorityWorkflowVersionDto,
    ChangeActiveAuthorityWorkflowVersionCommand,
    CraftAuthorityGroup,
    CreateAircraftRegisterEntryCommand,
    CreateAuthorityNominatedPersonnelCommand,
    CreateOrganisationAuthorityCommand,
    CreatePersonnelRegisterEntryCommand,
    CurrentAttachmentVersionDto,
    DisplayableOrganisationAuthorityDto,
    ManageAuthorityCommand,
    MissionWorkflowDto,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    PersonnelRegisterEntryDto,
    PersonnelRegisterExpirySummary,
    RegisterEntryOnMissionSummary,
    RegisterEntrySummary,
    RegisterEntryVerificationActivityDto,
    RegisterEntryVerificationCommand,
    RegisterEntryVerificationDetailsDto,
    RpaRegisterExpirySummary,
    RpaTypeWorkflowDto,
    SharedAuthorityGroup,
    SimpleOrganisationDto,
    UpdateAircraftRegisterEntryCommand,
    UpdateAuthorityCommand,
    UpdateAuthorityNominatedPersonnelCommand,
    UpdateAuthorityWorkflowVersionCommand,
    UpdatePersonnelRegisterEntryCommand,
    UpdateRegisterConditionsCommand,
    UpdateRegisterSettingsCommand
} from '../model/api';
import {
    AttachmentHandler,
    DownloadableAttachmentVersionDto
} from './attachments';
import { AuthorityService } from './authorities';
import {
    ChangeDetails,
    computePath,
    httpParamSerializer
} from './service.helpers';
import { WorkflowHandler } from './workflowHandler';

/**
 * Type guard for determining an Organisation Authority Group.
 * @param authorityGroup object to test
 */
export function isOrganisationAuthorityGroup(
    authorityGroup: CraftAuthorityGroup | OrganisationAuthorityGroup
): authorityGroup is OrganisationAuthorityGroup {
    return (
        authorityGroup.appliesTo === CraftAuthorityGroup.AppliesTo.COMMERCIAL ||
        authorityGroup.appliesTo === CraftAuthorityGroup.AppliesTo.PERSONAL
    );
}

class AuthorityWorkflowHandler implements WorkflowHandler {
    private authorityId: number;
    private authorityService: OrganisationAuthorityService;

    constructor(
        authorityId: number,
        authorityService: OrganisationAuthorityService
    ) {
        this.authorityId = authorityId;
        this.authorityService = authorityService;
    }

    changeActiveVersion(
        workflowId: number,
        command: ChangeActiveAuthorityWorkflowVersionCommand
    ) {
        return this.authorityService
            .changeActiveVersion(this.authorityId, command)
            .toPromise();
    }

    renameWorkflow(workflowId: number, command: any): any {
        throw new Error('Method not supported on this type of entity');
    }

    updateWorkflowVersion(
        workflowId: number,
        command: UpdateAuthorityWorkflowVersionCommand
    ) {
        return firstValueFrom(
            this.authorityService.updateWorkflowVersion(
                this.authorityId,
                command
            )
        );
    }

    createNewWorkflowVersion(command: UpdateAuthorityWorkflowVersionCommand) {
        return firstValueFrom(
            this.authorityService.createNewWorkflowVersion(
                this.authorityId,
                command
            )
        );
    }
}

export interface UiDisplayableOrganisationAuthorityDto
    extends DisplayableOrganisationAuthorityDto {
    attachments: DownloadableAttachmentVersionDto[];
}

export interface UiOrganisationAuthorityDto extends OrganisationAuthorityDto {
    attachments: DownloadableAttachmentVersionDto[];
}

export const displayableRegisterModeLookup = {
    [AuthorityRegisterSummaryDto.RegisterMode.DISABLED]: 'Disabled',
    [AuthorityRegisterSummaryDto.RegisterMode.ENFORCE]: 'Enforce',
    [AuthorityRegisterSummaryDto.RegisterMode.INFORM]: 'Inform'
};

@Injectable({
    providedIn: 'root'
})
export class OrganisationAuthorityService
    implements
        AuthorityService<
            OrganisationAuthorityDto,
            CreateOrganisationAuthorityCommand,
            UpdateAuthorityCommand
        > {
    private baseUrl: string;
    private changeSource = new Subject<ChangeDetails>();
    change$ = this.changeSource.asObservable();

    type: 'ORGANISATION' | 'RPA' = 'ORGANISATION';

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    notifyUpdated(authorityId: number) {
        this.changeSource.next({
            type: ChangeDetails.Type.UPDATE,
            changedId: authorityId
        });
    }

    findAuthorities(organisationId: number, managingOrganisationId?: number) {
        return this.http
            .get<OrganisationAuthorityGroup[]>(
                `${this.baseUrl}/webapi/organisationAuthorities`,
                {
                    params: httpParamSerializer({
                        organisationId,
                        managingOrganisationId
                    })
                }
            )
            .pipe(
                map(authorityTypes => {
                    authorityTypes.forEach(authorityType => {
                        if (authorityType.authorities == null) {
                            authorityType.authorities = [];
                        } else {
                            authorityType.authorities.map(e => {
                                const buildDownloadUrl = (
                                    d: CurrentAttachmentVersionDto
                                ) =>
                                    this.attachDownloadUrl(
                                        organisationId,
                                        e.id,
                                        d
                                    );
                                return {
                                    ...e,
                                    attachments: e.attachments.map(a =>
                                        buildDownloadUrl(a)
                                    )
                                };
                            });
                        }
                        return authorityType;
                    });
                    return authorityTypes;
                })
            );
    }

    findSharedAuthorities(organisationId: number, date: string) {
        return this.http
            .get<SharedAuthorityGroup[]>(
                `${this.baseUrl}/webapi/organisationAuthorities/shared`,
                {
                    params: httpParamSerializer({
                        organisationId,
                        date
                    })
                }
            )
            .pipe(
                map(authorityTypes => {
                    authorityTypes.forEach(authorityType => {
                        if (authorityType.authorities == null) {
                            authorityType.authorities = [];
                        } else {
                            authorityType.authorities.map(e => {
                                const buildDownloadUrl = (
                                    d: CurrentAttachmentVersionDto
                                ) =>
                                    this.attachDownloadUrl(
                                        organisationId,
                                        e.id,
                                        d
                                    );
                                return {
                                    ...e,
                                    attachments: e.attachments.map(a =>
                                        buildDownloadUrl(a)
                                    )
                                };
                            });
                        }
                        return authorityType;
                    });
                    return authorityTypes;
                })
            );
    }

    findAuthority(authorityId: number, managingOrganisationId: number) {
        return this.http
            .get<OrganisationAuthorityDto>(
                `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}`,
                {
                    params: httpParamSerializer({ managingOrganisationId })
                }
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    managingOrganisationId,
                                    authorityId,
                                    d
                                )
                            )
                        } as UiOrganisationAuthorityDto)
                )
            );
    }

    findDisplayableAuthority(
        authorityId: number,
        managingOrganisationId: number
    ) {
        return this.http
            .get<DisplayableOrganisationAuthorityDto>(
                `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}/display`,
                {
                    params: httpParamSerializer({ managingOrganisationId })
                }
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    managingOrganisationId,
                                    authorityId,
                                    d
                                )
                            )
                        } as UiDisplayableOrganisationAuthorityDto)
                )
            );
    }

    /**
     * Creates an authority placeholder that can be completed by the user.
     * @param organisationId the organisation to attach the authority to
     * @param authorityTypeId the authority type to create
     * @param managingOrganisationId the managing organisation in this request
     */
    newAuthority(
        organisationId: number,
        authorityTypeId: number,
        managingOrganisationId: number,
        dependentOnAuthorityId: number
    ) {
        return this.http
            .post<OrganisationAuthorityDto>(
                `${this.baseUrl}/webapi/organisationAuthorities`,
                {
                    organisationId,
                    authorityTypeId,
                    managingOrganisationId,
                    dependentOnAuthorityId
                }
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiOrganisationAuthorityDto)
                )
            );
    }

    createAuthority(
        authorityId: number,
        createCommand: CreateOrganisationAuthorityCommand
    ) {
        return this.http
            .put<OrganisationAuthorityDto>(
                `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}/create`,
                createCommand
            )
            .pipe(
                tap(() =>
                    this.changeSource.next({
                        type: ChangeDetails.Type.CREATE,
                        changedId: authorityId
                    })
                ),
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    createCommand.managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiOrganisationAuthorityDto)
                )
            );
    }

    updateAuthority(
        authorityId: number,
        updateCommand: UpdateAuthorityCommand
    ) {
        return this.http
            .put<OrganisationAuthorityDto>(
                `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}`,
                updateCommand
            )
            .pipe(
                tap(() => this.notifyUpdated(authorityId)),
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    updateCommand.managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiOrganisationAuthorityDto)
                )
            );
    }

    manageAuthority(
        authorityId: number,
        updateCommand: ManageAuthorityCommand
    ) {
        return this.http
            .put<OrganisationAuthorityDto>(
                `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}/manage`,
                updateCommand
            )
            .pipe(
                tap(() => this.notifyUpdated(authorityId)),
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    updateCommand.managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiOrganisationAuthorityDto)
                )
            );
    }

    linkAuthority(authorityId: number, command: AssociateAuthorityCommand) {
        return this.http
            .put<OrganisationAuthorityDto>(
                `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}/associate`,
                command
            )
            .pipe(
                tap(() => this.notifyUpdated(authorityId)),
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    command.managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiOrganisationAuthorityDto)
                )
            );
    }

    private attachDownloadUrl(
        managingOrganisationId: number,
        authorityId: number,
        document: CurrentAttachmentVersionDto
    ): DownloadableAttachmentVersionDto {
        return {
            ...document,
            downloadUrl: computePath(
                '/webapi/organisationAuthorities/' +
                    `/${authorityId}/attachments/${document.id}`,
                { managingOrganisationId }
            )
        };
    }

    findRegisteredAircraftByAircraftId(aircraftId: number) {
        return this.http.get<AircraftRegisterEntryDto[]>(
            `${this.baseUrl}/webapi/crafts/${aircraftId}/authorityRegisters`
        );
    }

    findRegisteredPersonnelByPersonId(personId: number) {
        return this.http.get<PersonnelRegisterEntryDto[]>(
            `${this.baseUrl}/webapi/personnel/${personId}/authorityRegisters`
        );
    }

    aircraftRegister(
        authorityId: number,
        registerId: number,
        managingOrganisationId?: number
    ) {
        return new Register<
            CreateAircraftRegisterEntryCommand,
            UpdateAircraftRegisterEntryCommand,
            AircraftRegisterEntryDto
        >(
            `/webapi/organisationAuthorities/${authorityId}/aircraftRegisters/${registerId}`,
            this.http,
            this,
            managingOrganisationId
        );
    }

    personnelRegister(
        authorityId: number,
        registerId: number,
        managingOrganisationId?: number
    ) {
        return new Register<
            CreatePersonnelRegisterEntryCommand,
            UpdatePersonnelRegisterEntryCommand,
            PersonnelRegisterEntryDto
        >(
            `/webapi/organisationAuthorities/${authorityId}/personnelRegisters/${registerId}`,
            this.http,
            this,
            managingOrganisationId
        );
    }

    personnelRegisterExpiries(
        authorityId: number,
        registerId: number,
        managingOrganisationId?: number
    ) {
        return this.http.get<PersonnelRegisterExpirySummary>(
            `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}}/personnelRegisters/${registerId}/expiry`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    aircraftRegisterExpiries(
        authorityId: number,
        registerId: number,
        managingOrganisationId: number
    ) {
        return this.http.get<RpaRegisterExpirySummary>(
            `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}}/aircraftRegisters/${registerId}/expiry`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    findWorkflow(authorityId: number, managingOrganisationId: number) {
        return this.http.get<AuthorityWorkflowDto>(
            `${this.baseUrl}/webapi/organisationAuthorities/${authorityId}/workflow`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    createNewWorkflowVersion(
        authorityId: number,
        command: UpdateAuthorityWorkflowVersionCommand
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/workflow`;
        return this.http
            .post<MissionWorkflowDto | RpaTypeWorkflowDto>(
                `${this.baseUrl}${url}`,
                command
            )
            .pipe(tap(() => this.changeSource.next(null)));
    }

    updateWorkflowVersion(
        authorityId: number,
        command: UpdateAuthorityWorkflowVersionCommand
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/workflow`;
        return this.http
            .put<MissionWorkflowDto | RpaTypeWorkflowDto>(
                `${this.baseUrl}${url}`,
                command
            )
            .pipe(tap(() => this.changeSource.next(null)));
    }

    getWorkflowHandler(authorityId: number) {
        return new AuthorityWorkflowHandler(authorityId, this);
    }

    changeActiveVersion(
        authorityId: number,
        command: ChangeActiveAuthorityWorkflowVersionCommand
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/workflow/active`;
        return this.http.put<MissionWorkflowDto | RpaTypeWorkflowDto>(
            `${this.baseUrl}${url}`,
            command
        );
    }

    attachmentHandler(authorityId: number, managingOrganisationId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/organisationAuthorities/${authorityId}/` + `attachments`,
            true,
            managingOrganisationId,
            true
        );
    }

    findActiveVersion(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/organisationAuthorities/${authorityId}/workflow/active`;
        return this.http.get<AuthorityWorkflowVersionDto>(
            `${this.baseUrl}${url}`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    findNominatedPersonnel(
        authorityId: number,
        managingOrganisationId: number
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/nominatedPersonnel`;
        return this.http.get<AuthorityNominatedPersonnelDto[]>(
            `${this.baseUrl}${url}`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    createNominatedPersonnel(
        authorityId: number,
        command: CreateAuthorityNominatedPersonnelCommand
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/nominatedPersonnel`;
        return this.http
            .post<AuthorityNominatedPersonnelDto>(
                `${this.baseUrl}${url}`,
                command
            )
            .pipe(tap(() => this.notifyUpdated(authorityId)));
    }

    updateNominatedPersonnel(
        authorityId: number,
        nominatedPersonnelId: number,
        command: UpdateAuthorityNominatedPersonnelCommand
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/nominatedPersonnel/${nominatedPersonnelId}`;
        return this.http
            .put<AuthorityNominatedPersonnelDto>(
                `${this.baseUrl}${url}`,
                command
            )
            .pipe(tap(() => this.notifyUpdated(authorityId)));
    }

    removeNominatedPersonnel(
        authorityId: number,
        nominatedPersonnelId: number
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/nominatedPersonnel/${nominatedPersonnelId}`;
        return this.http
            .delete<AuthorityNominatedPersonnelDto>(`${this.baseUrl}${url}`)
            .pipe(tap(() => this.notifyUpdated(authorityId)));
    }

    findAvailableAuthorityTypes(managingOrganisationId: number) {
        return this.http.get<AuthorityTypeDto[]>(
            `${this.baseUrl}/webapi/organisationAuthorities/authorityTypes`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    deleteAuthority(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/organisationAuthorities/${authorityId}`;
        return firstValueFrom(
            this.http
                .delete<void>(`${this.baseUrl}${url}`, {
                    params: httpParamSerializer({ managingOrganisationId })
                })
                .pipe(
                    tap(() =>
                        this.changeSource.next({
                            type: ChangeDetails.Type.DELETE,
                            changedId: authorityId
                        })
                    )
                )
        );
    }

    archiveAuthority(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/organisationAuthorities/${authorityId}/archive?managingOrganisationId=${managingOrganisationId}`;
        return this.http
            .put<OrganisationAuthorityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.notifyUpdated(authorityId)));
    }

    unarchiveAuthority(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/organisationAuthorities/${authorityId}/unarchive?managingOrganisationId=${managingOrganisationId}`;
        return this.http
            .put<OrganisationAuthorityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.notifyUpdated(authorityId)));
    }

    verifyAuthority(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/organisationAuthorities/${authorityId}/verification?managingOrganisationId=${managingOrganisationId}`;
        return this.http
            .post<AuthorityVerificationRecordDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.notifyUpdated(authorityId)));
    }

    findAuthorityTypeForAuthority(
        authorityId: number,
        managingOrganisationId: number
    ) {
        const url = `/webapi/organisationAuthorities/${authorityId}/type?managingOrganisationId=${managingOrganisationId}`;
        return this.http.get<AuthorityTypeDto>(`${this.baseUrl}${url}`);
    }

    findSharedTo(authorityId: number) {
        const url = `/webapi/organisationAuthorities/${authorityId}/sharedTo`;
        return this.http.get<SimpleOrganisationDto[]>(`${this.baseUrl}${url}`);
    }

    findPersonnelRegisterStatuses(
        authorityId: number,
        personnelIdList: number[],
        at: string,
        managingOrganisationId: number
    ) {
        return this.http.get<RegisterEntrySummary[]>(
            `${this.baseUrl}/webapi/organisationAuthorities/personnelRegisterStatuses`,
            {
                params: httpParamSerializer({
                    authorityId,
                    personId: personnelIdList,
                    at,
                    managingOrganisationId
                })
            }
        );
    }

    findPersonnelRegisterStatusesForMission(
        authorityIds: number[],
        personIds: number[],
        rpaIds: number[],
        timeOfDay: string,
        visualLineOfSight: string,
        at: string,
        managingOrganisationId: number
    ) {
        return this.http.get<RegisterEntryOnMissionSummary[]>(
            `${this.baseUrl}/webapi/organisationAuthorities/personnelMissionRegisterStatuses`,
            {
                params: httpParamSerializer({
                    authorityId: authorityIds,
                    personId: personIds,
                    rpaId: rpaIds,
                    timeOfDay,
                    visualLineOfSight,
                    at,
                    managingOrganisationId
                })
            }
        );
    }

    findRpaRegisterStatuses(
        authorityId: number,
        rpaIdList: number[],
        at: string,
        managingOrganisationId: number
    ) {
        return this.http.get<RegisterEntrySummary[]>(
            `${this.baseUrl}/webapi/organisationAuthorities/rpaRegisterStatuses`,
            {
                params: httpParamSerializer({
                    authorityId,
                    rpaId: rpaIdList,
                    at,
                    managingOrganisationId
                })
            }
        );
    }
}

export interface RegisterConditionsHandler {
    findRegisterConditions(): Promise<AuthorityRegisterConditionsDto>;

    updateRegisterConditions(
        command: UpdateRegisterConditionsCommand,
        authorityId: number
    ): Promise<AuthorityRegisterConditionsDto>;

    findCheckValues(
        authorityRegisterId: number
    ): Observable<RegisterEntryVerificationDetailsDto>;
}

export class Register<C, U, R> implements RegisterConditionsHandler {
    private suffix: string;

    constructor(
        suffix: string,
        private http: HttpClient,
        private organisationAuthorityService: OrganisationAuthorityService,
        private managingOrganisationId?: number
    ) {
        this.suffix = suffix;
    }

    find() {
        return this.http.get<R[]>(this.suffix, {
            params: httpParamSerializer({
                managingOrganisationId: this.managingOrganisationId
            })
        });
    }

    createRegisterEntry(command: C) {
        return this.http.post<R>(this.suffix, command);
    }

    updateRegisterEntry(authorityRegistryId: number, command: U) {
        return this.http.put<R>(
            `${this.suffix}/${authorityRegistryId}`,
            command
        );
    }

    deleteRegisterEntry(registerEntryId: number) {
        return this.http.delete(`${this.suffix}/${registerEntryId}`);
    }

    findRegisterConditions() {
        return firstValueFrom(
            this.http.get<AuthorityRegisterConditionsDto>(
                `${this.suffix}/conditions`,
                {
                    params: httpParamSerializer({
                        managingOrganisationId: this.managingOrganisationId
                    })
                }
            )
        );
    }

    updateRegisterSettings(
        command: UpdateRegisterSettingsCommand,
        authorityId: number
    ) {
        return firstValueFrom(
            this.http
                .put<AuthorityRegisterConditionsDto>(
                    `${this.suffix}/settings`,
                    command
                )
                .pipe(
                    tap(() =>
                        this.organisationAuthorityService.notifyUpdated(
                            authorityId
                        )
                    )
                )
        );
    }

    updateRegisterConditions(
        command: UpdateRegisterConditionsCommand,
        authorityId: number
    ) {
        return firstValueFrom(
            this.http
                .post<AuthorityRegisterConditionsDto>(
                    `${this.suffix}/conditions`,
                    command
                )
                .pipe(
                    tap(() =>
                        this.organisationAuthorityService.notifyUpdated(
                            authorityId
                        )
                    )
                )
        );
    }

    findCheckValues(authorityRegisterId: number) {
        return this.http.get<RegisterEntryVerificationDetailsDto>(
            `${this.suffix}/conditions/${authorityRegisterId}/checks`,
            {
                params: httpParamSerializer({
                    managingOrganisationId: this.managingOrganisationId
                })
            }
        );
    }

    updateRegisterEntryVerification(
        registerEntryId: number,
        registerConditionId: number,
        authorityId: number,
        command: RegisterEntryVerificationCommand
    ) {
        return this.http
            .post<RegisterEntryVerificationActivityDto>(
                `${this.suffix}/conditions/${registerEntryId}/checks/${registerConditionId}`,
                command
            )
            .pipe(
                tap(() =>
                    this.organisationAuthorityService.notifyUpdated(authorityId)
                )
            );
    }
}
