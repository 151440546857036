import { Component } from '@angular/core';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    EVENT_ENHANCED_HELP_OFF,
    EVENT_ENHANCED_HELP_ON
} from '../enhanced-help.service';

@Component({
    selector: 'enhanced-help-switch',
    templateUrl: './enhanced-help-switch.component.html',
    styles: [
        `
            :host {
                display: flex;
            }

            label {
                white-space: nowrap;
                color: #fff;
            }

            .mdc-switch.mdc-switch--checked .mdc-switch__thumb {
                border-color: #fff !important;
            }

            .mdc-switch.mdc-switch--checked .mdc-switch__track {
                background-color: #fff !important;
            }
        `
    ]
})
export class EnhancedHelpSwitch {
    active: boolean;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(private screenAnalytics: ScreenAnalyticsDirective) {}

    ngOnInit() {
        // set the initial state of enhanced help across screens
        this.screenAnalytics.helpActive$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(active => {
                if (this.active !== active) {
                    this.active = active;
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    toggleEnhancedHelp() {
        this.screenAnalytics.track(
            'help',
            this.active ? EVENT_ENHANCED_HELP_ON : EVENT_ENHANCED_HELP_OFF
        );
        this.screenAnalytics.setHelpActive(this.active);
    }
}
