<div class="panel widget battery-set">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-battery-sets.png" />
        </div>
        <div class="panel-title">Flight Battery Sets</div>

        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                class="btn btn-inline"
                (click)="newBatterySet()"
                tooltip="Add a new battery set"
                placement="left"
                *ngIf="canAddBatterySet"
                angulartics2On="click"
                angularticsAction="dashboard-battery-set-add"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>

    <div class="panel-body">
        <loading-indicator [isLoading]="working"></loading-indicator>
        <static-table
            *ngIf="batterySets?.length > 0"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="batterySets"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
        >
            <ng-template
                columnTemplate="name"
                let-row="row"
                let-column="column"
            >
                <status-icon
                    [batterySet]="row"
                    (nameClicked)="showBatterySet(row)"
                ></status-icon>
            </ng-template>
        </static-table>
        <empty
            *ngIf="canAddBatterySet && batterySets && batterySets.length == 0"
            componentName="battery-set-empty-editable"
        ></empty>
        <empty
            *ngIf="!canAddBatterySet && batterySets && batterySets.length == 0"
            componentName="battery-set-empty"
        ></empty>
        
    </div>
</div>
