<div class="personaldetails_complete_container main_container">
    <div
        *ngIf="invites != null && invites.length > 0 && hasAcceptedInvite"
        class="personaldetails_complete_frame onboarding-frame"
    >
        <div class="img-container">
            <img
                class="commercial"
                imgPath="/onboarding/flying-assets/individual-flying.png"
            />
        </div>
        <h4>You have joined {{ inviteOrganisationName }}</h4>
        <p>
            You have accepted an invitation to join an already existing company.
        </p>
        <div>
            <button class="btn invite-button btn-primary" (click)="Done()">
                Accept Invitation
            </button>
        </div>
    </div>
    <ng-container
        *ngIf="invites != null && (invites.length == 0 || !hasAcceptedInvite)"
    >
        <div
            *ngIf="inExistingOrganisations"
            class="personaldetails_complete_frame onboarding-frame"
            (click)="done()"
        >
            <div class="img-container">
                <img
                    class="commercial"
                    imgPath="/onboarding/flying-assets/individual-flying.png"
                />
            </div>
            <h4>NO. Join existing organisation</h4>
            <p>You are already a part of a company.</p>
        </div>
        <div
            *ngIf="
                !ownsAnOrganisation &&
                organisationDetails.canCreateOrganisation &&
                !organisationDetails.organisationDomainExists
            "
            class="personaldetails_complete_frame middleframe onboarding-frame"
            (click)="nextScreen()"
        >
            <div class="img-container">
                <img
                    class="commercial"
                    imgPath="/onboarding/flying-assets/individual-company.jpg"
                />
            </div>
            <h4>YES. I would like to create a new Company Organisation.</h4>
            <p>
                If your company already exists in the system ask your Company
                administrator and obtain an invite.
            </p>
        </div>
        <div
            *ngIf="
                !ownsAnOrganisation &&
                organisationDetails.organisationDomainExists
            "
            class="personaldetails_complete_frame middleframe onboarding-frame"
            (click)="requestAccess()"
        >
            <div class="img-container">
                <img
                    class="commercial"
                    imgPath="/onboarding/flying-assets/individual-company.jpg"
                />
            </div>
            <h4>I would like to request access to an existing Company</h4>
            <p>
                The domain associated with your email address is already
                associated with an existing company in the FlyFreely system. By
                clicking this button you can request access to join this
                company.
            </p>
        </div>
        <div
            *ngIf="
                ownsAnOrganisation &&
                !organisationDetails.organisationDomainExists
            "
            class="personaldetails_complete_frame middleframe"
        >
            <div class="img-container">
                <img
                    class="commercial"
                    imgPath="/onboarding/flying-assets/individual-company.jpg"
                />
            </div>
            <h4>You already own an organisation</h4>
            <p>
                You can only create a single organisation through the
                onboarding. Please contact support if you require additional
                organisations.
            </p>
        </div>
        <div
            class="personaldetails_complete_frame onboarding-frame"
            (click)="done()"
        >
            <div class="img-container">
                <img
                    imgPath="/onboarding/flying-assets/individual-no-company.jpg"
                />
            </div>
            <h4>
                NO. I do not want to
                {{
                    !organisationDetails.organisationDomainExists
                        ? 'setup a new'
                        : 'join an existing'
                }}
                Company now.
            </h4>
            <p>
                You can always
                {{
                    !organisationDetails.organisationDomainExists
                        ? 'setup a new'
                        : 'join an existing'
                }}
                Company at a later date if required.
            </p>
        </div>
    </ng-container>

    <div class="custom--btn">
        <button (click)="previousScreen()">Back</button>
    </div>
</div>
