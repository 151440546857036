import { Injectable } from '@angular/core';
import { GqlQueryResult } from '@flyfreely-portal-ui/flyfreely';
import { Apollo, gql } from 'apollo-angular';
import { filter, map } from 'rxjs/operators';

interface OnboardingRequirements {
    canCreateOrganisation: boolean;
    organisationDomainExists: boolean;
}

@Injectable()
export class UserOrganisationDataService {
    constructor(private apollo: Apollo) {}

    findOrganisationCreationPermissionsForUser() {
        return this.apollo
            .query<{ organisationRequirements: OnboardingRequirements }>({
                query: gql`
                    {
                        organisationRequirements: findOnboardingRequirements {
                            canCreateOrganisation
                            organisationDomainExists
                        }
                    }
                `
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.organisationRequirements)
            );
    }
}
