import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    CraftDto,
    CraftService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    PreferencesService,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker,
    compareDates,
    hasAnyPermission,
    toLookup,
    triggerActivationTypes,
    hasFeatureFlag,
    FEATURE_WORKGROUPS
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import {
    FormatResourceStatusPipe,
    FormatRpaCategoryPipe
} from '@flyfreely-portal-ui/resource-ui';
import {
    FormatColumnArrayPipe,
    FormatDatePipe,
    FormatMaintenanceTriggerTypePipe
} from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';
import { dateRange } from 'libs/flyfreely-table/src/lib/search';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import * as moment from 'moment';
import { EMPTY, Observable, Subject, combineLatest, of, merge } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { WidgetsPreferencesService } from '../widget-preferences.service';
import { WorkGroup } from 'libs/flyfreely/src/lib/services/workgroups.service';
import { WorkgroupsDataService } from '@flyfreely-portal-ui/organisation-admin';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';

const CRAFT_IDENTIFIER = 'rpaList';

interface WidgetCraftDto extends CraftDto {
    rpaType?: RpaTypeDto;
    convertedProcurementDate?: moment.Moment;
}

@Component({
    selector: 'craft-list-widget',
    templateUrl: './rpa-list-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class CraftListWidgetComponent implements OnInit, OnDestroy, Widget {
    organisation: PersonsOrganisationDto | undefined;

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    // new table options for persistent filtering and sorting
    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    canAddCraft: boolean;
    canEditCraft: boolean;
    canListCraft: boolean;
    canReport = false;

    userPreferences: TableSetupUserPreferences;

    crafts: WidgetCraftDto[];

    canUseWorkgroups = false;
    public working: boolean;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    private showDummyData$: Observable<boolean>;
    private crafts$ = new Subject<WidgetCraftDto[]>();
    private rpaType$ = new Subject<RpaTypeDto[]>();
    private rpaTypes: RpaTypeDto[];

    constructor(
        private craftService: CraftService,
        private rpaTypesService: RpaTypesService,
        private rpaDialoguesService: RpaDialoguesService,
        private workspaceStateService: WorkspaceStateService,
        private logging: FlyFreelyLoggingService,
        private preferencesService: PreferencesService,
        private formatDatePipe: FormatDatePipe,
        private resourceStatusPipe: FormatResourceStatusPipe,
        private rpaTypePipe: FormatRpaCategoryPipe,
        private formatMaintenanceTriggerType: FormatMaintenanceTriggerTypePipe,
        private preferences: WidgetsPreferencesService,
        private formatColumnArrayPipe: FormatColumnArrayPipe,
        private workgroupDataService: WorkgroupsDataService
    ) {
        this.showDummyData$ = preferencesService.showDummyData$.pipe(
            takeUntil(this.ngUnsubscribe$)
        );

        this.columnSorting = null;

        this.tableSearch = null;

        this.selectedColumns = null;

        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                // has to be executed in this order as eg refreshCrafts depends on the preferences
                this.loadPreferences();
                this.refreshPermissions();
                this.workgroupDataService.findWorkgroups(this.organisation.id);
                this.setupColumns();
                this.refreshCrafts();
                this.refreshRpaTypes();
            });

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        combineLatest([
            this.crafts$,
            this.showDummyData$,
            this.rpaType$,
            this.workgroupDataService.workgroup$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([crafts, showDummy, rpaTypes, workgroups]) => {
                this.crafts = this.processCraftsData(
                    showDummy,
                    rpaTypes,
                    workgroups,
                    crafts
                );
            });

        this.rpaType$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpaTypes => (this.rpaTypes = rpaTypes));

        merge(this.craftService.change$, this.workgroupDataService.rpaChange$)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshCrafts();
            });

        this.rpaTypesService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshRpaTypes();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private processCraftsData(
        showDummy: boolean,
        rpaTypes: RpaTypeDto[],
        workgroups: WorkGroup[],
        crafts: WidgetCraftDto[]
    ) {
        const rpaTypeLookup = rpaTypes.reduce(toLookup, {});
        let newCrafts = crafts
            .filter(m => showDummy || !m.isDummy)
            .map(craft => ({
                ...craft,
                rpaType: rpaTypeLookup[craft.rpaTypeId]
            }));
        if (!this.canUseWorkgroups) {
            return newCrafts;
        }
        const workGroupMap = workgroups.reduce((acc, curr) => {
            acc[curr.id] = curr.name;
            return acc;
        }, {});
        return newCrafts.map(craft =>
            Object.assign({}, craft, {
                workgroupNameList: craft.workgroupIdList.map(
                    id => workGroupMap[id]
                )
            })
        );
    }

    private setupColumns() {
        this.availableColumns = [
            {
                value: 'nickname',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: false
            },
            {
                value: 'convertedProcurementDate',
                key: 'procurementDate',
                name: 'Procurement Date',
                searchable: 'daterange',
                selectable: true,
                defaultSelection: false,
                formatterFunction: t => this.formatDatePipe.transform(t),
                searchFunction: dateRange,
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
            },
            {
                value: 'rpaType.make',
                name: 'RPA Make',
                searchable: false,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'rpaType.model',
                name: 'RPA Model',
                searchable: false,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'rpaType.craftType',
                name: 'RPA Category',
                searchable: false,
                selectable: true,
                defaultSelection: false,
                formatterFunction: (craft: WidgetCraftDto) =>
                    this.rpaTypePipe.transform(craft)
            },
            {
                value: 'status',
                name: 'Status',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                searchOptions: this.craftService.getStatuses(),
                defaultSearch: 'ACTIVE',
                formatterFunction: (craft: WidgetCraftDto) =>
                    this.resourceStatusPipe.transform(craft)
            },
            {
                value: 'manufacturerSerialNumber',
                name: 'Manufacturer Serial Number',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'organisationName',
                name: 'Owner',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'maintenanceScheduleTriggerActivation.type',
                name: 'Maintenance',
                searchable: true,
                selectable: true,
                defaultSelection: false,
                searchOptions: triggerActivationTypes,
                formatterFunction: (craft: WidgetCraftDto) =>
                    this.formatMaintenanceTriggerType.transform(
                        craft?.maintenanceScheduleTriggerActivation?.type
                    )
            },
            ...(this.canUseWorkgroups
                ? [
                      {
                          value: 'workgroupNameList',
                          name: 'Workgroup',
                          searchable: true,
                          selectable: true,
                          defaultSelection: true,
                          formatterFunction: (a: string[]) =>
                              a == null
                                  ? null
                                  : this.formatColumnArrayPipe.transform(a, 2)
                      } as TableColumn
                  ]
                : [])
        ];
    }

    private loadPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferences
            .findPreferences('craftListWidgetTable', this.organisation.id)
            .pipe(
                switchMap(preferences => {
                    if (preferences == null) {
                        return this.preferences
                            .findLegacyPreferences(
                                'craftListWidgetColumns',
                                this.organisation.id
                            )
                            .pipe(
                                map(pref => ({
                                    selectedColumns: pref,
                                    columnSorting: null,
                                    tableSearch: null,
                                    itemLimit: this.tableConfig.limit
                                })),
                                catchError(() => {
                                    this.handleDefaultPreferences();
                                    return of(null);
                                })
                            );
                    } else {
                        return of(preferences);
                    }
                }),
                catchError(() => {
                    this.selectedColumns = findDefaultColumnSelection(
                        this.availableColumns
                    );
                    return EMPTY;
                })
            )
            .subscribe(preferences => {
                this.applyPreferences(preferences);
            })
            .add(this.workTracker.createTracker);
    }

    private handleDefaultPreferences() {
        this.applyPreferences({});
    }

    private applyPreferences(preferences: TableSetupUserPreferences) {
        this.userPreferences = this.preferences.applyPreferences(
            preferences,
            this.availableColumns,
            this.tableConfig
        );

        this.selectedColumns = this.userPreferences.selectedColumns;
        this.columnSorting = this.userPreferences.columnSorting;
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit = this.userPreferences.itemLimit;

        this.preferences.updateDateRange(this.tableSearch, 'procurementDate');
    }

    private refreshPermissions() {
        if (!this.organisation) {
            return;
        }
        this.canAddCraft = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.CRAFT_ADD
        );

        this.canEditCraft = this.canAddCraft;
        this.canListCraft = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.CRAFT_LIST
        );

        this.canReport = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.CRAFT_ADD
        );
        this.canUseWorkgroups = hasFeatureFlag(
            this.organisation,
            FEATURE_WORKGROUPS
        );
    }

    private refreshRpaTypes() {
        if (!this.organisation) {
            return;
        }
        this.rpaTypesService
            .findRpaTypes(this.organisation.id)
            .subscribe({
                next: results => this.rpaType$.next(results),
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing RPA type list: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    private refreshCrafts() {
        this.crafts = null;
        if (!this.organisation || !this.canListCraft) {
            return;
        }

        this.craftService
            .findCrafts(this.organisation.id)
            .subscribe(
                results => {
                    this.crafts$.next(
                        results.map(r => ({
                            ...r,
                            convertedProcurementDate: r.procurementDate
                                ? moment(r.procurementDate)
                                : null
                        }))
                    );
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing RPA: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    showCraft(craft: CraftDto) {
        if (!this.organisation) {
            return;
        }
        this.rpaDialoguesService.showCraftDetails(
            craft.id,
            this.organisation.id,
            this.canEditCraft
        );
    }

    newCraft() {
        this.rpaDialoguesService.showAddRpaDialogue(
            this.rpaTypes,
            this.organisation
        );
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferencesService
            .updatePreferences(
                'craftListWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    showRpaReportsDialogue() {
        this.rpaDialoguesService.showRpaReportsDialogue(this.organisation);
    }
}

export const craftWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: CRAFT_IDENTIFIER,
    component: CraftListWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.CRAFT_LIST
        )
};
