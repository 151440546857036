import { Pipe, PipeTransform } from '@angular/core';

/**
 * The service only returns missions up to 1000m away.
 */
@Pipe({
    name: 'locationDistanceClass'
})
export class LocationDistanceClassPipe implements PipeTransform {
    transform = locationDistanceClass;
}

export function locationDistanceClass(distanceInM: number) {
    if (distanceInM < 20) {
        return 'status-icon-green';
    } else if (distanceInM < 1000) {
        return 'status-icon-yellow';
    }
    return 'status-icon-red';
}
