import { Pipe, PipeTransform } from '@angular/core';
import { RpaTypesService } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '@flyfreely-portal-ui/ui';

@Pipe({ name: 'formatRpaCategory' })
export class FormatRpaCategoryPipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor(rpaTypesService: RpaTypesService) {
        this.transform = setupStatusFormatter(
            rpaTypesService.getRpaCategories()
        );
    }
}
