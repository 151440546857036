import { Directive, TemplateRef } from '@angular/core';

/**
 * Marks a template as for being used in the help slot of the editable component when in edit mode.
 */
@Directive({
    selector: '[editHelp]'
})
export class EditHelpDirective {
    constructor(public tpl: TemplateRef<any>) {}
}
