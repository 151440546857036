import { Injectable } from '@angular/core';
import { PersonsOrganisationDto } from '../model/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationStart,
    Router
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BodyCss {
    currentOrganisation: PersonsOrganisationDto;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.removeBodyClasses();
                } else if (event instanceof NavigationEnd) {
                    this.addBodyClasses();
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    removeBodyClasses() {
        const classes: string[] = this.activatedRoute.snapshot.data?.classes;
        if (classes == null) {
            return;
        }
        classes.forEach(c => document.body.classList.remove(c));
    }

    addBodyClasses() {
        const classes: string[] = this.activatedRoute.snapshot.data?.classes;
        if (classes == null) {
            return;
        }
        classes.forEach(c => document.body.classList.add(c));
    }
}
