import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotamDto } from '@flyfreely-portal-ui/flyfreely';
import { NOTAM_ENTITIES, NOTAM_STATUSES } from '@flyfreely-portal-ui/ui';
import { collapseAnimation } from 'angular-animations';
import { InlineModal } from 'libs/inline-modal/src/lib/inline-modal.component';
import { ViewRawNotamDialogueComponent } from 'libs/missions/src/lib/mission-edit-v2/view-notam-dialogue/view-raw-notam-dialogue.component';

@Component({
    selector: 'notam-details',
    templateUrl: './notam-details.component.html',
    styleUrls: ['./notam-styles.scss'],
    animations: [collapseAnimation()]
})
export class NotamDetailsComponent {
    @Input() notam: NotamDto;
    @Input() listOnly?: boolean = false;
    @Input() disableActions?: boolean = false;
    @Input() isExpanded: boolean = false;
    @Input() viewNotamDetailModal: InlineModal;
    @Output() deleteNotam = new EventEmitter<void>();

    startTime: string;
    endTime: string;

    entity: string = '';
    status: string = '';

    ngOnInit() {
        this.startTime = `${this.notam.startTime.toString().slice(0, 16)}Z`;
        this.endTime = this.notam.endTime
            ? `${this.notam.endTime.toString().slice(0, 16)}Z`
            : '';
        this.formatQCode();
    }

    formatQCode() {
        if (this.notam.entity != null) {
            this.entity = NOTAM_ENTITIES[this.notam.entity] ?? '';
        }
        if (this.notam.status != null) {
            this.status = NOTAM_STATUSES[this.notam.status] ?? '';
        }
    }

    onViewNotamDetail() {
        const {
            component,
            onDestroy
        } = this.viewNotamDetailModal.showComponent(
            ViewRawNotamDialogueComponent,
            {
                notamTitle: `${this.notam.id}${this.notam.location}${this.notam.entity}`,
                notamContent: this.notam.completeMessage
            }
        );

        component.done.subscribe(() => {
            this.viewNotamDetailModal.closeComponent();
        });
    }
}

// TODO:
// Display for times
// trim seconds from times
