import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    ChangeActiveMaintenanceScheduleVersionCommand,
    CreateMaintenanceScheduleCommand,
    MaintenanceScheduleDto,
    PreviewMaintenanceScheduleUpdateCommand,
    RenameMaintenanceScheduleCommand,
    ResultDto,
    SimpleMaintenanceScheduleDto,
    UpdateMaintenanceScheduleTriggersCommand,
    UpdateMaintenanceScheduleVersionCommand
} from '../model/api';
import { getMissionWorkflowSteps } from './missionWorkflow.service';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceScheduleService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<SimpleMaintenanceScheduleDto[]>(
            `${this.baseUrl}/webapi/maintenanceSchedules`,
            {
                params: httpParamSerializer({ organisationId })
            }
        );
    }

    findById(maintenanceScheduleId: number, managingOrganisationId?: number) {
        return this.http.get<MaintenanceScheduleDto>(
            `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    create(command: CreateMaintenanceScheduleCommand) {
        return this.http
            .post<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    update(
        maintenanceScheduleId: number,
        command: UpdateMaintenanceScheduleVersionCommand
    ) {
        return this.http
            .put<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateMaintenanceScheduleName(
        maintenanceScheduleId: number,
        command: RenameMaintenanceScheduleCommand
    ) {
        return this.http
            .put<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}/name`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateMaintenanceScheduleTriggers(
        maintenanceScheduleId: number,
        command: UpdateMaintenanceScheduleTriggersCommand
    ) {
        return this.http
            .put<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}/triggers`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    preview(
        maintenanceScheduleId: number,
        command: PreviewMaintenanceScheduleUpdateCommand
    ) {
        return this.http
            .put<ResultDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}/preview`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    changeActiveVersion(
        maintenanceScheduleId: number,
        command: ChangeActiveMaintenanceScheduleVersionCommand
    ) {
        return this.http
            .put<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}/active`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    renameWorkflow(
        maintenanceScheduleId: number,
        command: RenameMaintenanceScheduleCommand
    ) {
        return this.http
            .put<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}/name`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    archiveWorkflow(maintenanceScheduleId: number) {
        return this.http
            .put<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}/archive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    unarchiveWorkflow(maintenanceScheduleId: number) {
        return this.http
            .put<MaintenanceScheduleDto>(
                `${this.baseUrl}/webapi/maintenanceSchedules/${maintenanceScheduleId}/unarchive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    getWorkflowSteps() {
        return getMissionWorkflowSteps([
            'pre-mission',
            'pre-flight',
            'flying',
            'post-flight',
            'post-mission'
        ]);
    }
}
