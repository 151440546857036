import { Pipe, PipeTransform } from '@angular/core';
import { DisplayableMissionDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatObjectiveOutcome' })
export class FormatObjectiveOutcomePipe implements PipeTransform {
    transform(objectiveOutcome: DisplayableMissionDto.Outcome): string | null {
        if (objectiveOutcome == null) {
            return null;
        }

        switch (objectiveOutcome) {
            case DisplayableMissionDto.Outcome.COMPLETED:
                return 'Completed';
            case DisplayableMissionDto.Outcome.INCOMPLETE:
                return 'Incomplete';
            case DisplayableMissionDto.Outcome.PARTIALLY_COMPLETED:
                return 'Partially Completed';
        }
    }
}
