import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl,FormGroup } from '@angular/forms';
import {
    AddressDto,
    CardDeclinedException,
    ChargesDto,
    CustomerDetailsDto,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InvalidOperation,
    OrganisationSubscriptionDto,
    SubscriptionService,
    SubscriptionSetupOutcomeDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
    StripeCardElementOptions,
    StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {
    StripeCardComponent,
    StripeFactoryService,
    StripeInstance
} from 'ngx-stripe';
import {
    EMPTY,
    MonoTypeOperatorFunction,
    Observable,
    Operator,
    ReplaySubject,
    Subject,
    Subscriber,
    TeardownLogic,
    of
} from 'rxjs';
import {
    catchError,
    map,
    share,
    startWith,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import { PaymentsService, paymentIntentValues } from '../payments.service';

class IgnoreErrorsSubscriber<T, K> extends Subscriber<T> {
    _error() {
        // noop
    }
}

class IgnoreErrorsOperator<T, K> implements Operator<T, T> {
    call(subscriber: Subscriber<T>, source: any): TeardownLogic {
        return source.subscribe(new IgnoreErrorsSubscriber(subscriber));
    }
}

function ignoreErrors<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => source.lift(new IgnoreErrorsOperator());
}

export type ConfirmPaymentMode = 'PURCHASE' | 'PAYMENT_DETAILS';

/**
 * The confirm-payment modal can operate in one of two modes:
 * 1. PURCHASE mode which starts a new subscription, or
 * 2. PAYMENT_DETAILS mode which updates payment details and pays outstanding invoices if required
 */
@Component({
    selector: 'confirm-payment',
    templateUrl: './confirm-payment.component.html',
    styles: [
        `
            .invoice tr.subtotal {
                border-top: 1px solid;
            }

            .invoice tr.total {
                border-top: 1px solid;
                font-size: 1.1em;
            }
        `
    ],
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()],
    providers: [PaymentsService]
})
export class ConfirmPaymentDialogue implements OnInit {
    @Input() address: AddressDto;
    @Input() organisationId: number;
    @Input() subscriptionId?: number;
    @Input() licenceCount: number;
    @Input() subscriptionPlanIdentifier: string;
    @Input() currentDueDate: string;
    @Input() mode: ConfirmPaymentMode;

    @Output() complete = new EventEmitter<SubscriptionSetupOutcomeDto>();

    coupons = new ReplaySubject<string>(1);
    currentCoupon: string;
    charges$: Observable<ChargesDto>;
    shouldPay: boolean;

    useExistingPaymentMethod = new FormControl<boolean>(true);
    coupon = new FormControl<string>(undefined);
    currentDiscount = 0;

    requiresPaymentMethodAfterPaying = false;

    paymentError: string;

    private workTracker = new WorkTracker();
    working = false;

    valid = false;

    hasCompleted = false;

    private ngUnsubscribe$ = new Subject<void>();

    cardForm: FormGroup;
    cardFields: FormlyFieldConfig[];
    cardData = {
        name: '',
        country: null as any,
        postcode: ''
    };
    options: FormlyFormOptions;

    stripe: StripeInstance;

    existingPayment: CustomerDetailsDto;

    @ViewChild(StripeCardComponent) card: StripeCardComponent;

    cardOptions: StripeCardElementOptions = {
        hidePostalCode: true,
        style: {
            base: {
                iconColor: '#666EE8',
                color: '#31325F',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '16px',
                '::placeholder': {
                    color: '#999'
                }
            }
        }
    };

    constructor(
        private modal: BsModalRef<ConfirmPaymentDialogue>,
        modalOptions: ModalOptions,
        private subscriptionService: SubscriptionService,
        private logging: FlyFreelyLoggingService,
        private angulartics: Angulartics2,
        private stripeFactory: StripeFactoryService,
        private flyfreelyConstants: FlyFreelyConstants,
        private paymentsService: PaymentsService
    ) {
        this.stripe = this.stripeFactory.create(
            this.flyfreelyConstants.STRIPE_PK
        );

        modalOptions.closeInterceptor = () => {
            if (!this.hasCompleted) {
                this.angulartics.eventTrack.next({
                    action: 'purchase-plan-cancelled',
                    properties: {
                        category: 'subscriptions',
                        label: this.subscriptionPlanIdentifier,
                        value: this.licenceCount
                    }
                });
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        if (this.mode == null) {
            throw new Error('mode not set on <confirm-payment>');
        }

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.cardForm = new FormGroup({});

        this.cardData = {
            name: '',
            country: this.address?.country,
            postcode: this.address?.postcode
        };

        this.buildFormlyFields();

        this.subscriptionService
            .findCustomerDetails(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: details => {
                    this.existingPayment = details;
                    this.useExistingPaymentMethod.setValue(
                        details.type !== 'none'
                    );
                    this.shouldPay = this.currentDueDate != null;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing subscription details: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());

        const fetchCharges = this.coupons.pipe(
            tap(() => this.coupon.setErrors(null)),
            switchMap(coupon => {
                const doneWorking = this.workTracker.createTracker();

                return this.subscriptionService
                    .previewCharge({
                        organisationId: this.organisationId,
                        subscriptionPlanIdentifier:
                            this.subscriptionPlanIdentifier,
                        licenceCount: this.licenceCount,
                        coupon
                    })
                    .pipe(
                        tap({
                            next: returned => {
                                this.currentCoupon = coupon;
                                this.calculateDiscount(returned);
                            },
                            error: doneWorking,
                            complete: doneWorking
                        }),
                        catchError((error: FlyFreelyError) => {
                            if (error instanceof InvalidOperation) {
                                if (error.fields?.coupon?.code === 'Invalid') {
                                    this.coupon.setErrors({ invalid: true });
                                }
                            }
                            return EMPTY;
                        })
                    );
            }),
            share()
        );

        // This is subscribed in the template to ensure that `fetchCharges` runs
        this.charges$ = fetchCharges.pipe(ignoreErrors());

        this.coupons.next(null);

        this.useExistingPaymentMethod.valueChanges
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                startWith(this.useExistingPaymentMethod.value)
            )
            .subscribe(useExisting => (this.valid = useExisting));
    }

    ngOnDestroy() {
        this.complete.complete();

        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshSubscriptions() {
        this.subscriptionService
            .findCurrentSubscriptions(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: subscriptions => {
                    const filteredSubscriptions = subscriptions.filter(
                        sub =>
                            sub.status ===
                            OrganisationSubscriptionDto.Status.ACTIVE
                    );

                    if (filteredSubscriptions.length === 0) {
                        this.mode = 'PURCHASE';
                    }
                    this.subscriptionId = filteredSubscriptions[0].id;
                    this.mode = 'PAYMENT_DETAILS';
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating subscription details: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    buildFormlyFields() {
        this.cardFields = [
            {
                key: 'name',
                type: 'input',
                props: {
                    label: 'Name on Card',
                    required: true
                }
            },
            {
                key: 'country',
                type: 'country',
                props: {
                    label: 'Country',
                    required: true
                }
            },
            {
                key: 'postcode',
                type: 'input',
                props: {
                    label: 'Postal Code',
                    required: true
                }
            }
        ];
    }

    calculateDiscount(charges: ChargesDto) {
        if (charges == null) {
            this.currentDiscount = 0;
            return;
        }
        const taxes = charges.taxAmounts.reduce((acc, t) => acc + t.amount, 0);
        this.currentDiscount = charges.subTotal - charges.total + taxes;
    }

    onSubmit() {
        this.angulartics.eventTrack.next({
            action: 'purchase-plan-pay',
            properties: {
                category: 'subscriptions',
                label: this.subscriptionPlanIdentifier,
                value: this.licenceCount
            }
        });
        this.paymentError = null;

        const paymentMethod = this.useExistingPaymentMethod.value
            ? of(this.existingPayment.id)
            : this.stripe
                  .createPaymentMethod({
                      type: 'card',
                      card: this.card.element,
                      billing_details: {
                          name: this.cardData.name,
                          address: {
                              country: this.cardData.country.iso,
                              postal_code: this.cardData.postcode
                          }
                      }
                  })
                  .pipe(map(method => method.paymentMethod.id));

        paymentMethod
            .pipe(
                switchMap(methodId =>
                    this.mode === 'PURCHASE'
                        ? this.subscriptionService.purchaseSubscription({
                              licenceCount: this.licenceCount,
                              subscriptionPlanIdentifier:
                                  this.subscriptionPlanIdentifier,
                              organisationId: this.organisationId,
                              paymentMethodId: methodId,
                              subscriptionId: this.subscriptionId,
                              coupon: this.currentCoupon
                          })
                        : this.subscriptionService.payExistingSubscription(
                              this.subscriptionId,
                              {
                                  paymentMethodId: methodId
                              }
                          )
                )
            )
            .subscribe({
                next: result => {
                    // const outcome = result as SubscriptionSetupOutcomeDto;
                    if (
                        result.status === 'active' &&
                        result.paymentIntentStatus !== 'requires_payment_method'
                    ) {
                        this.logging.success(
                            `Subscription ${
                                this.subscriptionId == null
                                    ? 'created'
                                    : 'updated'
                            }`
                        );
                        this.requiresPaymentMethodAfterPaying = false;
                        this.hasCompleted = true;
                        this.complete.next(result);
                        this.modal.hide();
                        return;
                    } else {
                        this.paymentError = `There was an error processing your subscription. ${
                            paymentIntentValues[result.paymentIntentStatus] ??
                            ''
                        }`;
                        if (
                            result.paymentIntentStatus ===
                            'requires_payment_method'
                        ) {
                            this.requiresPaymentMethodAfterPaying = true;
                            this.useExistingPaymentMethod.patchValue(false);
                        }
                        if (result.id != null) {
                            this.subscriptionId = result.id;
                            this.mode = 'PAYMENT_DETAILS';
                        } else {
                            this.refreshSubscriptions();
                        }
                    }
                },
                error: (
                    error:
                        | CardDeclinedException
                        | InvalidOperation
                        | FlyFreelyError
                ) => {
                    this.refreshSubscriptions();
                    this.paymentError =
                        this.paymentsService.parsePaymentError(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    onChange(change: StripeCardNumberElementChangeEvent) {
        this.valid = change.complete;
    }

    hide() {
        this.modal.hide();
    }

    invalidCouponError() {
        return 'The coupon is not valid';
    }
}
