import { NameValue } from '@flyfreely-portal-ui/flyfreely';

/**
 * Create a lookup function for the status list
 * @param statuses list of NameValue statuses
 */
export function setupStatusFormatter(statuses: NameValue[]) {
    const statusLookup = statuses.reduce((acc, status) => {
        acc[status.value] = status.name;
        return acc;
    }, {});

    return (status: string) =>
        status in statusLookup ? statusLookup[status] : status;
}
