import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlightHistoryModule } from 'libs/flight-history/src/lib/flight-history.module';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { FormDirectivesModule } from 'libs/form-directive/src/lib/form-directive.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { MaintenanceModule } from 'libs/maintenance/src/lib/maintenance.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { WorkflowModule } from 'libs/workflows/src/lib/workflows.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ngfModule } from 'angular-file';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { BatteryAddDialogue } from './add/battery-add-dialgoue.component';
import { BatteryDialoguesService } from './battery-dialogues.service';
import { BatterySetAddDialogue } from './battery-sets/add/battery-set-add-dialgoue.component';
import { BatterySetDetails } from './battery-sets/battery-set-details.component';
import { BatterySetEdit } from './battery-sets/edit/battery-set-edit.component';
import { BatterySetView } from './battery-sets/view/battery-set-view.component';
import { BatteryTypeDialogue } from './battery-types/edit/battery-type-dialogue.component';
import { BatteryTypeList } from './battery-types/list/battery-type-list.component';
import { BatteryDetailsDialogue } from './details-dialogue/battery-details-dialogue.component';
import { BatteryDetails } from './details/battery-details.component';
import { BatteryEdit } from './edit/battery-edit.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FeaturesModule } from 'libs/features/src/lib/features.module';
import { BatteryListDownloadDialogue } from './downloads/battery-list-download/battery-list-download-dialogue.component';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';
import { BatteryHealthComponent } from './battery-health/battery-health.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CompatibleRpaTypesComponent } from './battery-types/compatible-rpa-types/compatible-rpa-types.component';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { BatterySetCompatibleRpaComponent } from './battery-sets/compatible-rpa/battery-set-compatible-rpa.component';
import { NotesViewComponent } from 'libs/batteries/src/lib/batteries-notes/notes-view/notes-view.component';
import { NotesEditComponent } from 'libs/batteries/src/lib/batteries-notes/notes-edit/notes-edit.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedUiModule,
        // ngx-bootstrap
        TooltipModule,
        ModalModule.forChild(),
        TabsModule,
        BsDropdownModule,
        BsDatepickerModule,
        ButtonsModule,

        // ngx-formly
        ReactiveFormsModule,
        FormlyModule,
        FormlyBootstrapModule,
        FormDirectivesModule,

        // ng-select
        NgSelectModule,

        ngfModule,

        NgxChartsModule,

        ToastrModule, // ToastrModule added
        SharedUiModule,
        LoadingIndicatorModule,
        DocumentationModule,
        WorkflowModule,
        FlightHistoryModule,
        MaintenanceModule,
        ValidationModule,
        FlyFreelyTableModule,
        MaterialModule,
        ResourceUiModule,

        EnhancedHelpModule,
        FeaturesModule,
        AnalyticsModule,
        EditableModule
    ],
    providers: [BatteryDialoguesService],
    declarations: [
        BatteryDetailsDialogue,
        BatteryDetails,
        BatteryEdit,
        BatterySetView,
        BatterySetDetails,
        BatterySetEdit,
        BatteryTypeList,
        BatteryTypeDialogue,
        BatteryAddDialogue,
        BatterySetAddDialogue,
        BatteryListDownloadDialogue,
        BatteryHealthComponent,
        CompatibleRpaTypesComponent,
        BatterySetCompatibleRpaComponent,
        NotesViewComponent,
        NotesEditComponent
    ],
    exports: [BatteryTypeList, CompatibleRpaTypesComponent]
})
export class BatteryModule {}
