import { Injectable } from '@angular/core';
import {
    MissionRegisterStatusDetails,
    Organisation,

    RpaTypeDto,
    SimpleOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RpaAddDialogue } from './add-dialogue/rpa-add-dialogue.component';
import { RpaDialogue } from './details-dialogue/rpa-dialogue.component';
import { RpaReportsDialogue } from './rpa-reports-dialogue/rpa-reports-dialogue.component';
import { RpaTypeEditDialogue } from './rpa-types/edit/rpa-type-edit.component';

@Injectable()
export class RpaDialoguesService {
    constructor(private modalService: BsModalService) {}

    showCraftDetails(
        rpaId: number,
        managingOrganisationId: number,
        canEdit: boolean,
        // This is optional unused functionality to show the register status at the time the mission was flown.
        missionDetails?: MissionRegisterStatusDetails
    ) {
        this.modalService.show(RpaDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                canEdit,
                managingOrganisationId,
                rpaId,
                missionDetails
            }
        });
    }

    showAddRpaDialogue(rpaTypes: RpaTypeDto[], organisation: Organisation) {
        return this.modalService.show(RpaAddDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { rpaTypes, organisation }
        });
    }

    showRpaTypeEditDialogue(rpaTypeId: number, organisationId: number) {
        return this.modalService.show(RpaTypeEditDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { rpaTypeId, organisationId }
        });
    }

    showRpaReportsDialogue(organisation: SimpleOrganisationDto) {
        return this.modalService.show(RpaReportsDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { organisation }
        });
    }
}
