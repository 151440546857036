<enhanced-help
    componentName="mission-objectives-list"
    [compact]="true"
>
</enhanced-help>
<div>
    <div *ngFor="let objective of objectives">
        <editable
            [initialContext]="{ name: objective.name, objectiveId: objective.id }"
            [confirmationButtons]="true"
            [updateFn]="updateObjectiveName"
            [disabled]="working"
        >
            <ng-template viewMode>
                {{ objective.name }}
            </ng-template>
            <ng-template editMode let-context="context">
                <input
                    type="text"
                    [(ngModel)]="context.name"
                    class="form-control"
                    editableOnEnter
                />
            </ng-template>
        </editable>
    </div>
</div>
<div>
    <form (submit)="addObjective()" [formGroup]="createFormGroup">
        <div class="input-group">
            <input
                class="form-control"
                formControlName="name"
                placeholder="Enter the name of the new objective here"
            />
            <span class="input-group-btn">
                <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="working || createFormGroup.invalid"
                >
                    Add
                </button>
            </span>
        </div>
    </form>
</div>
