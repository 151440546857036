import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AuthorityFilter,
    DashboardService,
    MissionService,
    PersonsOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MissionViewService } from '../mission-view.service';

@Component({
    selector: 'flyfreely-portal-ui-mission-view-sidebar',
    templateUrl: './mission-view-sidebar.component.html',
    styleUrls: ['./mission-view-sidebar.component.scss'],
    animations: [collapseOnLeaveAnimation(), expandOnEnterAnimation()]
})
export class MissionViewSidebarComponent implements OnInit {
    organisation: PersonsOrganisationDto;

    canUseCalendar = true;
    canListMissions = true;
    hasFlightLogging = true;
    canReport = true;
    canAddMission = true;

    showOptions = true;
    showControls = true;

    missionViewMode: 'MAP' | 'LIST' | 'BOTH' = 'BOTH';

    missionAuthorityFilter: AuthorityFilter;

    missionAuthorityFilters$: Observable<
        { name: string; children: AuthorityFilter[] }[]
    >;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionDialoguesService: MissionDialoguesService,
        private missionViewService: MissionViewService,
        private sharedService: WorkspaceStateService,
        private router: Router,
        private missionService: MissionService,
        public dashboardService: DashboardService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.sharedService.currentOrganisation$
            .pipe(
                filter<CurrentOrganisation>(
                    o => o.type === 'organisation_loaded'
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
            });

        this.missionViewService.permissionsUpdated$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(updated => {
                if (updated) {
                    this.canUseCalendar =
                        this.missionViewService.canUseCalendar;
                    this.canListMissions =
                        this.missionViewService.canListMissions;
                    this.hasFlightLogging =
                        this.missionViewService.hasFlightLogging;
                    this.canReport = this.missionViewService.canReport;
                    this.canAddMission = this.missionViewService.canAddMission;
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    newMission() {
        this.missionDialoguesService.showMissionEditor(
            this.missionService.newMission(this.organisation),
            this.organisation
        );
    }

    showMissionReportsDialogue() {
        this.missionViewService.showMissionReportsDialogue();
    }

    showFlightLogsDialogue() {
        this.router.navigate(['flightlogs'], {
            queryParamsHandling: 'preserve',
            relativeTo: this.activatedRoute
        });
    }

    showMissionCalendar() {
        this.missionViewService.showMissionCalendar();
    }

    updateMissionViewMode() {
        this.missionViewService.setViewMode(this.missionViewMode);
    }
}
