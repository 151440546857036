import { NgModule } from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { EditableComponent } from './editable.component';
import { CommonModule } from '@angular/common';
import { EditableOnEnterDirective } from './editable-on-enter.directive';
import { EditHelpDirective } from './edit-help.directive';

/**
 * Based on https://medium.com/claritydesignsystem/four-ways-of-listening-to-dom-events-in-angular-part-2-hostlistener-1b66d45b3e3d
 */
@NgModule({
    imports: [CommonModule],
    declarations: [
        ViewModeDirective,
        EditModeDirective,
        EditableComponent,
        EditableOnEnterDirective,
        EditHelpDirective
    ],
    exports: [
        ViewModeDirective,
        EditModeDirective,
        EditHelpDirective,
        EditableComponent,
        EditableOnEnterDirective
    ]
})
export class EditableModule {}
