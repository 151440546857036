import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { RpaFlightHistoryEntry } from 'libs/flight-history/src/lib/flight-history-data.service';
import { Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GqlQueryResult } from '.';
import { FlyFreelyConstants } from '../constants';
import {
    AircraftRegisterEntryDetailsDto,
    AircraftUnderMaintenanceCommand,
    AssignMaintenanceScheduleCommand,
    AssociatedResourceCommand,
    CraftDetailsDto,
    CraftDto,
    CreateCraftCommand,
    DisposeRpaCommand,
    FlightHistorySummaryDto,
    GroundAircraftCommand,
    MaintenanceScheduleAssignmentDto,
    MarkAircraftActiveCommand,
    RetireAircraftCommand,
    TotalInServiceTime,
    UpdateCraftCommand,
    UpdateNotesCommand,
    UpdateRpaSerialNumberCommand
} from '../model/api';
import { NameValue } from '../model/ui';
import { AttachmentHandler } from './attachments';
import { ChangeEvent, entityChanged, entityDeleted } from './changes';
import { httpParamSerializer } from './service.helpers';

export const AIRCRAFT_CHANGED_EVENT = 'aircraft-changed';

export const RPA_STATUSES: NameValue[] = [
    { value: 'SERVICEABLE|UNDER_MAINTENANCE|UNSERVICEABLE', name: 'Current' },
    { value: 'SERVICEABLE', name: 'Serviceable' },
    { value: 'UNSERVICEABLE', name: 'Unserviceable' },
    { value: 'UNDER_MAINTENANCE', name: 'Under maintenance' },
    { value: 'RETIRED', name: 'Retired' },
    { value: 'DISPOSED', name: 'Disposed' }
];

export interface RpaCriteria {
    craftModelId?: number;
    authorityRegisterId?: number;
    authorityRegisterReferenceDate?: number;
    related?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CraftService {
    private baseUrl: string;
    private changeSource = new Subject<ChangeEvent<CraftDetailsDto>>();
    change$ = this.changeSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    private notifyAircraftChanged(updatedCraft: CraftDetailsDto) {
        this.changeSource.next(entityChanged(updatedCraft));
    }

    createCraft(craftDetails: CreateCraftCommand) {
        return this.http
            .post<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts`,
                craftDetails
            )
            .toPromise()
            .then(aircraft => {
                this.notifyAircraftChanged(aircraft);
                return aircraft;
            });
    }

    updateCraft(craftId: number, craftDetails: UpdateCraftCommand) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${craftId}`,
                craftDetails
            )
            .toPromise()
            .then(aircraft => {
                this.notifyAircraftChanged(aircraft);
                return aircraft;
            });
    }

    updateInternalSerialNumber(
        craftId: number,
        command: UpdateRpaSerialNumberCommand
    ) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${craftId}/internalSerialNumber`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    findCrafts(organisationId: number, criteria?: RpaCriteria) {
        return this.http.get<CraftDto[]>(`${this.baseUrl}/webapi/crafts`, {
            params: httpParamSerializer({ ...criteria, organisationId })
        });
    }

    findCraftsById(ids: Array<number>, managingOrganisationId: number) {
        if (!ids) {
            return Promise.resolve([]);
        }
        return Promise.all(
            ids.map(id => this.findById(id, managingOrganisationId))
        );
    }

    findById(craftId: number, managingOrganisationId: number) {
        return this.http.get<CraftDetailsDto>(
            `${this.baseUrl}/webapi/crafts/${craftId}`,
            {
                params: httpParamSerializer({
                    managingOrganisationId
                })
            }
        );
    }

    findRpaFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
            includeType: ('LOGGED' | 'HISTORICAL' | 'UNASSIGNED')[];
        },
        order: 'ASC' | 'DESC',
        page = 0,
        pageSize = 10
    ) {
        return this.apollo
            .query<{
                findRpaFlightHistory: GqlQueryResult<RpaFlightHistoryEntry>;
            }>({
                query: gql`
                    query find(
                        $criteria: ResourceFlightHistoryCriteria
                        $order: Direction
                        $pageSize: Int
                        $page: Int
                    ) {
                        findRpaFlightHistory(
                            criteria: $criteria
                            order: $order
                            page: $page
                            pageSize: $pageSize
                        ) {
                            count
                            results {
                                id
                                type
                                missionId
                                flightNumber
                                startTime
                                endTime
                                duration
                                visualLineOfSight
                                timeOfDay
                                rpaId
                                serviceabilitySignoff {
                                    signoffPerson {
                                        firstName
                                        lastName
                                    }
                                    serviceability
                                }
                                rpaType {
                                    id
                                    make
                                    model
                                    rpaCategory
                                    performanceSpecifications {
                                        minTakeOffWeight
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    order,
                    page,
                    pageSize
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findRpaFlightHistory)
            );
    }

    findFlightHistorySummary(craftId: number, managingOrganisationId?: number) {
        return this.http.get<FlightHistorySummaryDto>(
            `${this.baseUrl}/webapi/crafts/${craftId}/history/summary`,
            {
                params: httpParamSerializer({
                    managingOrganisationId
                })
            }
        );
    }

    findTotalTimeInService(
        craftId: number,
        managingOrganisationId: number,
        until?: string
    ) {
        return this.http.get<TotalInServiceTime>(
            `${this.baseUrl}/webapi/crafts/${craftId}/totalTimeInService`,
            {
                params: httpParamSerializer({
                    managingOrganisationId,
                    until
                })
            }
        );
    }

    findAircraftRegisterEntryByAircraftId(
        craftId: number,
        managingOrganisationId: number,
        at: string
    ) {
        return this.http.get<AircraftRegisterEntryDetailsDto[]>(
            `${this.baseUrl}/webapi/crafts/${craftId}/authorityRegisters`,
            { params: httpParamSerializer({ at, managingOrganisationId }) }
        );
    }

    deleteCraft(craftId: number) {
        return this.http
            .delete(`${this.baseUrl}/webapi/crafts/${craftId}`)
            .pipe(tap(() => this.changeSource.next(entityDeleted(craftId))));
    }

    attachmentHandler(craftId: number, managingOrganisationId?: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/crafts/${craftId}/attachments`,
            null,
            managingOrganisationId
        );
    }

    getStatuses() {
        return RPA_STATUSES;
    }

    markAircraftUnderMaintenance(
        craftId: number,
        command: AircraftUnderMaintenanceCommand
    ) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${craftId}/underMaintenance`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    markAircraftActive(craftId: number, command: MarkAircraftActiveCommand) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${craftId}/active`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    markAircraftRetire(craftId: number, command: RetireAircraftCommand) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${craftId}/retire`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    markAircraftGrounded(craftId: number, command: GroundAircraftCommand) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${craftId}/ground`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    disposeRpa(craftId: number, command: DisposeRpaCommand) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${craftId}/dispose`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    exportOrganisationRpa(organisationId: number, authorityTypeIds?: number[]) {
        const headers = new HttpHeaders({
            'Content-Type': 'text/csv;charset=utf-8',
            Accept: 'text/csv;charset=utf-8'
        });
        return this.http.get(`${this.baseUrl}/webapi/rpa/export`, {
            params: httpParamSerializer({
                organisationId,
                authorityTypeId: authorityTypeIds
            }),
            responseType: 'blob',
            headers: headers
        });
    }

    assignMaintenanceSchedule(
        rpaId: number,
        command: AssignMaintenanceScheduleCommand
    ) {
        return this.http
            .put<MaintenanceScheduleAssignmentDto>(
                `${this.baseUrl}/webapi/crafts/${rpaId}/maintenanceSchedule`,
                command
            )
            .pipe(tap(() => this.changeSource.next(null)));
    }

    addAssociatedEquipment(rpaId: number, command: AssociatedResourceCommand) {
        return this.http
            .post<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${rpaId}/equipment`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    removeAssociatedEquipment(
        rpaId: number,
        command: AssociatedResourceCommand
    ) {
        return this.http
            .delete<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${rpaId}/equipment/${command.resourceId}`,

                {
                    params: httpParamSerializer({
                        managingOrganisationId: command.managingOrganisationId
                    })
                }
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    addAssociatedBatterySet(rpaId: number, command: AssociatedResourceCommand) {
        return this.http
            .post<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${rpaId}/batterySets`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    removeAssociatedBatterySet(
        rpaId: number,
        command: AssociatedResourceCommand
    ) {
        return this.http
            .delete<CraftDetailsDto>(
                `${this.baseUrl}/webapi/crafts/${rpaId}/batterySets/${command.resourceId}`,

                {
                    params: httpParamSerializer({
                        managingOrganisationId: command.managingOrganisationId
                    })
                }
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    updateCraftNotes(rpaId: number, command: UpdateNotesCommand) {
        return this.http
            .put<CraftDetailsDto>(
                `${this.baseUrl}/webapi/rpa/${rpaId}/notes`,
                command
            )
            .pipe(tap(rpa => this.notifyAircraftChanged(rpa)));
    }

    onCraftChanged() {
        this.changeSource.next(null);
    }
}
