/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlanTier } from './planTier';


/**
 * 
 */
export interface SubscriptionPlan { 
    identifier: string;
    currency: string;
    tiers: Array<PlanTier>;
    renewalPeriod: SubscriptionPlan.RenewalPeriod;
    minimumLicenceCount: number;
}
export namespace SubscriptionPlan {
    export type RenewalPeriod = 'NONE' | 'MONTHLY' | 'ANNUAL';
    export const RenewalPeriod = {
        NONE: 'NONE' as RenewalPeriod,
        MONTHLY: 'MONTHLY' as RenewalPeriod,
        ANNUAL: 'ANNUAL' as RenewalPeriod
    };
}



