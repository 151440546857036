import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/**
 * The check component sets its value to 'true' or '' depending on
 * its checked status.
 */
@Component({
    selector: 'formly-date',
    template: `
        <div class="input-group">
            <input
                [formControl]="formControl"
                class="form-control"
                [formlyAttributes]="field"
                #datepicker="bsDatepicker"
                bsDatepicker
                [bsConfig]="{ adaptivePosition: false }"
                placement="bottom"
                [container]="'body'"
            />
            <span
                class="input-group-addon btn-default"
                (click)="datepicker.toggle()"
                [attr.aria-expanded]="datepicker.isOpen"
            >
                <i
                    class="glyphicon glyphicon-calendar"
                    style="cursor: pointer"
                ></i>
            </span>
        </div>
    `
})
export class FormlyDate extends FieldType {}
