import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { JurisdictionsModule } from 'libs/jurisdictions/src/lib/jurisdictions.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { RpaModule } from 'libs/rpa/src/lib/rpa.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AuthorityModule } from '../authorities.module';
import { OrganisationAuthorityEdit } from './organisation-authority-edit/organisation-authority-edit.component';
import { OrganisationAuthorityList } from './organisation-authority-list/organisation-authority-list.component';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        SharedUiModule,
        LoadingIndicatorModule,
        AuthorityModule,
        PopoverModule,
        RpaModule,
        JurisdictionsModule,
        FormsModule,
        MaterialModule,
        ValidationModule,

        EnhancedHelpModule,
        AnalyticsModule
    ],
    declarations: [OrganisationAuthorityList, OrganisationAuthorityEdit],
    exports: [OrganisationAuthorityList, OrganisationAuthorityEdit]
})
export class OrganisationAuthorityModule {}
