/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthorityConditionDto } from './authorityConditionDto';


/**
 * 
 */
export interface AuthorityTypeRegisterConditionsDto { 
    /**
     * The ID of the register
     */
    id: number;
    /**
     * The name of the register
     */
    name: string;
    enabled: boolean;
    /**
     * The type of entity that the register contains
     */
    registerEntityType: AuthorityTypeRegisterConditionsDto.RegisterEntityType;
    /**
     * The checks that are applied to this register
     */
    defaultConditions: Array<AuthorityConditionDto>;
}
export namespace AuthorityTypeRegisterConditionsDto {
    export type RegisterEntityType = 'RPA' | 'PILOT';
    export const RegisterEntityType = {
        RPA: 'RPA' as RegisterEntityType,
        PILOT: 'PILOT' as RegisterEntityType
    };
}



