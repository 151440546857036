import { Component, Input } from '@angular/core';
import {
    AttachmentHandler,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonDto,
    personSearch,
    PersonsOrganisationDto,
    ResourceComponentInServiceTime,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MaintenanceDialogues } from '../maintenance-dialogues.service';
import { MaintenanceFormService } from '../maintenance-form.service';
import {
    DisplayableMaintenanceActivity,
    MaintenanceRequestService
} from '../maintenance-request.service';

@Component({
    selector: 'maintenance-activity-details',
    templateUrl: './maintenance-activity-details.component.html'
})
export class MaintenanceActivityDetails {
    @Input() organisation: PersonsOrganisationDto;
    @Input() activity: DisplayableMaintenanceActivity;
    @Input() overallStatus: string;
    @Input() isMaintenanceController: boolean;
    @Input() canUseMaintenanceTasks: boolean;
    @Input() maintenanceLogId: number;

    personSearch = personSearch;

    attachmentsHandler: AttachmentHandler;

    maintainerId: number;
    maintenanceOrganisation: string;
    showingReassign: boolean = false;

    personnel$: Observable<PersonDto[]>;

    canEditActivity = false;
    inServiceTimeComponents: ResourceComponentInServiceTime[];

    working: boolean = false;
    workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private maintenanceResourceService: MaintenanceRequestService,
        private maintenanceFormService: MaintenanceFormService,
        private maintenanceDialoguesService: MaintenanceDialogues,
        private maintenanceRequestService: MaintenanceRequestService,
        private logging: FlyFreelyLoggingService
    ) {
        this.personnel$ = this.maintenanceRequestService.maintenancePersonCandidates$;
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.maintenanceResourceService.totalTimeInService$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                times => (this.inServiceTimeComponents = times.components)
            );

        if (this.activity.id != null) {
            this.attachmentsHandler =
                this.maintenanceResourceService.attachmentHandler(
                    this.activity.id
                );
            this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);
        }

        this.canEditActivity =
            this.activity.availableActions.canPerformActivity;

        if (this.activity.maintainer != null) {
            this.maintainerId = this.activity.maintainer.id;
        } else {
            this.maintenanceOrganisation =
                this.activity.maintenanceOrganisation;
        }
        this.sortActivityTasks();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    toggleReassign() {
        this.showingReassign = !this.showingReassign;
    }

    reassignMaintenance() {
        this.maintenanceResourceService
            .reassignMaintenanceActivity(
                this.activity.id,
                this.maintainerId,
                this.maintenanceOrganisation
            )
            .subscribe({
                next: result => {
                    this.activity = { ...result, number: this.activity.number };
                    this.logging.success(
                        'Successfully updated the assigned maintenance person'
                    );
                    this.toggleReassign();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error reassigning maintenance person: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    sortActivityTasks() {
        this.activity.tasks.sort((a, b) => {
            if (a.taskType.name < b.taskType.name) {
                return -1;
            } else if (a.taskType.name > b.taskType.name) {
                return 1;
            } else {
                if (
                    `${a.component.componentType.name} ${a.component.designation}` <
                    `${b.component.componentType.name} ${b.component.designation}`
                ) {
                    return -1;
                } else if (
                    `${a.component.componentType.name} ${a.component.designation}` >
                    `${b.component.componentType.name} ${b.component.designation}`
                ) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }

    editActivity() {
        const modal =
            this.maintenanceDialoguesService.showEditCompletedActivityDialogue(
                this.organisation,
                this.canUseMaintenanceTasks,
                this.activity,
                this.maintenanceLogId,
                this.inServiceTimeComponents,
                this.maintenanceResourceService,
                this.maintenanceFormService
            );
    }
}
