import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FEATURE_AIRWORTHINESS_NOTICES,
    FEATURE_MAINTENANCE,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceLogSummary,
    MaintenanceService,
    PersonDto,
    PersonsOrganisationDto,
    PreferencesService,
    UserService,
    WorkTracker,
    compareDates,
    hasAnyPermission,
    hasFeatureFlag,
    maintenanceOutcomes
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import {
    FormatDateTimePipe,
    FormatMaintenanceEquipmentCategoryPipe,
    FormatMaintenanceLogTypePipe,
    FormatMaintenanceOutcomePipe,
    FormatMaintenanceStatusPipe,
    FormatPersonPipe
} from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { dateRange } from 'libs/flyfreely-table/src/lib/search';
import { MaintenanceDialogues } from 'libs/maintenance/src/lib/maintenance-dialogues.service';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import * as moment from 'moment-timezone';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import {
    catchError,
    debounceTime,
    map,
    switchMap,
    takeUntil
} from 'rxjs/operators';
import { WidgetsPreferencesService } from '../widget-preferences.service';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';


const MAINTENANCE_IDENTIFIER = 'maintenance';

@Component({
    selector: 'maintenance-widget',
    templateUrl: './maintenance-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
            .search-input {
                height: 32px !important;
                border-radius: 4px;
                padding-left: 32px !important;
            }
            .search-icon {
                position: absolute;
                left: 10px;
                color: #ccc;
                pointer-events: none; /* Prevents clicking the icon */
            }
            .search-container {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
            }
            .clear-icon {
                cursor: pointer;
                position: absolute;
                color: #ccc;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        `
    ]
})
export class MaintenanceWidgetComponent implements OnInit, OnDestroy, Widget {
    organisation: PersonsOrganisationDto;
    equipmentTypes: any[];
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;
    hasAirworthiness: boolean;
    // maintenanceSearchRes: boolean = true;
    textSearch: string | null = '';
    private searchSubject = new Subject<string | null>();

    maintainerList: any[];
    equipmentList: {
        CRAFT: any[];
        BATTERY: any[];
        BATTERYSETS: any[];
    };

    maintenanceRequests: any[];

    canRequestMaintenance: boolean;
    canViewAllDetails: boolean;

    maintenanceFeature = [FEATURE_MAINTENANCE];

    currentUser: PersonDto;
    userPreferences: TableSetupUserPreferences;

    public working: boolean;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private maintenanceService: MaintenanceService,
        private userService: UserService,
        private preferences: WidgetsPreferencesService,
        private logging: FlyFreelyLoggingService,
        private maintenanceDialogues: MaintenanceDialogues,
        private preferencesService: PreferencesService,
        formatDateTimePipe: FormatDateTimePipe,
        formatPersonPipe: FormatPersonPipe,
        maintenanceStatusPipe: FormatMaintenanceStatusPipe,
        maintenanceLogTypePipe: FormatMaintenanceLogTypePipe,
        maintenanceEquipmentTypePipe: FormatMaintenanceEquipmentCategoryPipe,
        maintenanceOutcomePipe: FormatMaintenanceOutcomePipe,
        private workspaceStateService: WorkspaceStateService
    ) {
        this.equipmentTypes = maintenanceService.getEquipmentTypes();

        const statusFilters =
            this.maintenanceService.getCombinedStatusFilters();

        this.availableColumns = [
            {
                value: 'id',
                name: 'ID',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                linkFunction: (maintenanceLog: any) =>
                    this.updateMaintenanceRequest(maintenanceLog.id)
            },
            {
                value: 'maintenanceLogType',
                name: 'Type',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                linkFunction: (maintenanceLog: any) =>
                    this.updateMaintenanceRequest(maintenanceLog.id),
                searchOptions: this.maintenanceService.getMaintenanceLogTypes(),
                formatterFunction: t => maintenanceLogTypePipe.transform(t)
            },
            {
                value: 'resourceCategory',
                name: 'Resource Category',
                searchable: true,
                selectable: true,
                defaultSelection: false,
                linkFunction: (maintenanceLog: any) =>
                    this.updateMaintenanceRequest(maintenanceLog.id),
                searchOptions: this.maintenanceService.getEquipmentTypes(),
                formatterFunction: et =>
                    maintenanceEquipmentTypePipe.transform(et)
            },
            {
                value: 'resource.name',
                name: 'Name',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'identifier',
                name: 'Identifier',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'requestTime',
                name: 'Requested',
                searchable: 'daterange',
                selectable: true,
                defaultSelection: true,
                formatterFunction: t => formatDateTimePipe.transform(t),
                searchFunction: dateRange,
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
            },
            {
                value: 'combinedStatus',
                name: 'Status',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                searchOptions: statusFilters,
                defaultSearch: `'${statusFilters[0].value}'`,
                searchFunction: (search: string) => {
                    const criteria = search.split('|');
                    return (status: string) => criteria.indexOf(status) !== -1;
                },
                formatterFunction: s => maintenanceStatusPipe.transform(s)
            },
            {
                value: 'assignee',
                name: 'Assigned To',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                formatterFunction: p => formatPersonPipe.transform(p),
                searchFunction: (text: string) => (person: PersonDto) =>
                    formatPersonPipe
                        .transform(person)
                        .toLowerCase()
                        .includes(text.toLowerCase())
            },
            {
                value: 'outcome',
                name: 'Outcome',
                searchable: true,
                selectable: false,
                defaultSelection: false,
                searchOptions: maintenanceOutcomes,
                formatterFunction: et => maintenanceOutcomePipe.transform(et)
            }
        ];
        this.selectedColumns = null;
        this.columnSorting = null;
        this.tableSearch = null;
        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };
    }

    ngOnInit() {
        
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.loadPreferences();
                this.setupData();
                this.hasAirworthiness = hasFeatureFlag(this.organisation, FEATURE_AIRWORTHINESS_NOTICES);
            });

        this.searchSubject
            .pipe(
                debounceTime(500),
                switchMap(searchTerm =>
                    this.refreshMaintenanceRequests(searchTerm)
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe();
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.currentUser = this.userService.getCurrentUser();

        this.maintenanceService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMaintenanceRequests(this.textSearch));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private loadPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferences
            .findPreferences('maintenanceWidgetTable', this.organisation.id)
            .pipe(
                switchMap(preferences => {
                    if (preferences == null) {
                        return this.preferences
                            .findLegacyPreferences(
                                'maintenanceWidgetColumns',
                                this.organisation.id
                            )
                            .pipe(
                                map(pref => ({
                                    selectedColumns: pref,
                                    columnSorting: null,
                                    tableSearch: null,
                                    itemLimit: this.tableConfig.limit
                                })),
                                catchError(() => {
                                    this.handleDefaultPreferences();
                                    return of(null);
                                })
                            );
                    } else {
                        return of(preferences);
                    }
                }),
                catchError(() => {
                    this.handleDefaultPreferences();
                    return EMPTY;
                })
            )
            .subscribe(preferences => {
                this.applyPreferences(preferences);
            })
            .add(this.workTracker.createTracker);
    }

    private handleDefaultPreferences() {
        this.applyPreferences({});
    }

    private applyPreferences(preferences: TableSetupUserPreferences) {
        this.userPreferences = this.preferences.applyPreferences(
            preferences,
            this.availableColumns,
            this.tableConfig
        );

        this.selectedColumns = this.userPreferences.selectedColumns;
        this.columnSorting = this.userPreferences.columnSorting;
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit = this.userPreferences.itemLimit;

        this.preferences.updateDateRange(this.tableSearch, 'requestTime');
    }

    private setupData() {
        this.refreshMaintenanceRequests(this.textSearch);
        this.refreshPermissions();
    }

    private refreshPermissions() {
        this.canRequestMaintenance = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.MAINTENANCE_REQUEST
        );
        this.canViewAllDetails = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.MAINTENANCE_MANAGE
        );
    }

    private buildDecoratedMaintenanceRequest(
        maintenanceLog: MaintenanceLogSummary
    ) {
        const latestActivity =
            maintenanceLog.activities.length > 0
                ? maintenanceLog.activities[
                      maintenanceLog.activities.length - 1
                  ]
                : null;
        return {
            ...maintenanceLog,
            combinedStatus: this.maintenanceService.getCombinedStatus(
                maintenanceLog.status,
                latestActivity != null ? latestActivity.status : null
            ),
            requestTime: maintenanceLog.requestTime
                ? moment(maintenanceLog.requestTime)
                : null
        };
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'maintenanceWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    newMaintenanceRequest() {
        this.maintenanceDialogues.showMaintenanceDetails(this.organisation.id);
    }

    updateMaintenanceRequest(maintenanceLogId: number) {
        this.maintenanceDialogues.showMaintenanceDetails(
            this.organisation.id,
            maintenanceLogId
        );
    }

    private refreshMaintenanceRequests(
        searchTerm: string | null
    ): Observable<any> {
        if (!this.organisation) {
            return;
        }
        this.maintenanceService
            .findMaintenanceLogs(this.organisation.id, searchTerm)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (results: any[]) => {
                    this.maintenanceRequests = results.map(mr =>
                        this.buildDecoratedMaintenanceRequest(mr)
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error refreshing equipment: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
        return of(null);
    }

    showMaintenanceReportsDialogue() {
        this.maintenanceDialogues.showMaintenanceReportsDialogue(
            this.organisation.id
        );
    }
    showAirWorthnessNotice() {
        this.maintenanceDialogues.showAirworthnessNotice(
            this.organisation.id
        );
    }
    onSearch(term: string) {
        this.textSearch = term || null;
        this.searchSubject.next(this.textSearch);
    }

    clearSearch() {
        this.searchSubject.next('');
        (document.querySelector('.search-input') as HTMLInputElement).value =
            '';
    }
}

export const maintenanceWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: MAINTENANCE_IDENTIFIER,
    component: MaintenanceWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(organisation, [
            PersonsOrganisationDto.Permissions.MAINTENANCE_LIST,
            PersonsOrganisationDto.Permissions.MAINTENANCE_MANAGE,
            PersonsOrganisationDto.Permissions.MAINTENANCE_REQUEST,
            PersonsOrganisationDto.Permissions.MAINTENANCE_PERFORM
        ]) && hasFeatureFlag(organisation, FEATURE_MAINTENANCE)
};
