import { Component, Input } from '@angular/core';
import { SetupGuideActivityDto } from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { FlyFreelyVideoPlayerOptions } from 'libs/flyfreely-video/src/lib/helpers';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'activity-video-dialogue',
    templateUrl: './activity-video.component.html'
})
export class ActivityVideoPlayerDialogue {
    @Input() videoUrl: string;
    @Input() thumbnail: string;
    @Input() activityTitle: string;
    @Input() task: SetupGuideActivityDto.StepIdentifier;
    @Input() stepId: number;

    percentagePlayed = 0;

    videoOptions: FlyFreelyVideoPlayerOptions = {
        allowPictureInPicture: false
    };

    constructor(
        private modal: BsModalRef,
        private angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        this.angulartics2.eventTrack.next({
            action: 'video-dialogue-opened',
            properties: {
                category: `setup-guide`,
                label: this.stepId,
                value: this.percentagePlayed.toString(),
                videoUrl: this.videoUrl,
                task: this.task
            }
        });
    }

    updatePercentagePlayed(percentage: number) {
        this.percentagePlayed = percentage;
    }

    close() {
        this.angulartics2.eventTrack.next({
            action: 'video-dialogue-closed',
            properties: {
                category: `setup-guide`,
                label: this.stepId,
                value: this.percentagePlayed.toString(),
                videoUrl: this.videoUrl,
                task: this.task
            }
        });
        this.modal.hide();
    }
}
