/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface PrdStatus { 
    jurisdiction?: string;
    prd?: string;
    status?: PrdStatus.Status;
    floor?: number;
    ceiling?: number;
    notamText?: string;
}
export namespace PrdStatus {
    export type Status = 'ACTIVE' | 'INACTIVE' | 'UNKNOWN';
    export const Status = {
        ACTIVE: 'ACTIVE' as Status,
        INACTIVE: 'INACTIVE' as Status,
        UNKNOWN: 'UNKNOWN' as Status
    };
}



