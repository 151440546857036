<div
    *ngIf="play"
    class="mini-video-player-container"
    [ngClass]="{
        bottom: bottom,
        top: !bottom,
        minimised: !maximised,
        maximised: maximised
    }"
    [@bounceInLeftOnEnter]
    [@bounceOutLeftOnLeave]
>
    <div class="modal-content" [ngClass]="{ inverted: !bottom && !maximised }">
        <div
            class="modal-header no-icon"
            style="padding: 0 10px; display: flex; align-items: center"
        >
            <div
                class="flex-2"
                style="height: 100%; display: flex; align-items: center"
            >
                <span
                    style="color: white; font-size: 14px; font-weight: bold"
                    >{{ activityTitle }}</span
                >
            </div>
            <div
                class="horizontal-container fill"
                style="justify-content: flex-end; align-items: center"
            >
                <button
                    type="button"
                    class="close"
                    style="font-size: 14px"
                    (click)="moveAcross()"
                >
                    <span
                        aria-hidden="true"
                        class="fal"
                        [ngClass]="{
                            'fa-angle-double-up': bottom,
                            'fa-angle-double-down': !bottom
                        }"
                    ></span>
                </button>
                <button
                    type="button"
                    class="close"
                    aria-label="Minimise"
                    (click)="minimise()"
                >
                    <span aria-hidden="true">&ndash;</span>
                </button>
                <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="close()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div style="background-color: #000; height: 280px; width: 100%">
            <flyfreely-video
                *ngIf="videoUrl != null"
                [videoUrl]="videoUrl"
                videoType="video/mp4"
                [autoPlay]="true"
                [enablePiPOnLoad]="loadInPip"
                (pipEntered)="minimiseForPip(false)"
                (pipClosed)="minimiseForPip(true)"
                (percentagePlayed)="updatePercentage($event)"
            ></flyfreely-video>
        </div>
    </div>
</div>
