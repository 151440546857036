import {
    CurrentSubscriptions,
    NoSubscriptions,
    OrganisationSubscriptionsState,
    PersonalOrganisationSubscription
} from './interfaces';
import { PersonsOrganisationDto } from '@flyfreely-portal-ui/flyfreely';

export function isNoSubscriptions(
    subscription: OrganisationSubscriptionsState
): subscription is NoSubscriptions {
    return subscription?.type === 'no_subscriptions';
}

export function isCurrentSubscription(
    subscription: OrganisationSubscriptionsState
): subscription is CurrentSubscriptions {
    return subscription?.type === 'subscriptions';
}

export function isPersonalOrganisationSubscription(
    subscription: OrganisationSubscriptionsState
): subscription is PersonalOrganisationSubscription {
    return subscription?.type === 'personal_organisation';
}

/**
 * Tests whether the user is the owner of another commercial organisation
 */
export function hasExistingCommercialOrganisations(
    organisations: PersonsOrganisationDto[],
    personId: number
) {
    return (
        // This null escape is a fallback for in case something doesn't load correctly.
        // The components calling this should handle all other possible issues, such as race conditions
        organisations != null &&
        personId != null &&
        organisations.filter(
            o => !o.personalOrganisation && o.ownerId === personId
        ).length !== 0
    );
}
