import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-indicator',
    template: `<span *ngIf="isLoading"
        ><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i
    ></span>`
})
export class LoadingIndicator {
    @Input() isLoading: boolean;
}
