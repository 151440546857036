import { Pipe, PipeTransform } from '@angular/core';
import { MISSION_TYPES } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '../helpers';

@Pipe({ name: 'formatMissionType' })
export class FormatMissionTypePipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor() {
        this.transform = setupStatusFormatter(MISSION_TYPES);
    }
}
