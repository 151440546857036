import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    EquipmentDto,
    EquipmentService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UpdateNotesCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';

@Component({
    selector: 'notes-edit',
    templateUrl: './notes-edit.component.html',
    styleUrls: ['./notes-edit.component.scss']
})
export class NotesEditComponent implements OnInit, OnDestroy {
    @Input() equipment: EquipmentDto;

    equipmentNotesForm: FormGroup;
    private workTracker = new WorkTracker();
    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    @Output() cancelled = new EventEmitter();

    constructor(
        private commonDialoguesService: CommonDialoguesService,
        private equipmentService: EquipmentService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit(): void {
        this.equipmentNotesForm = new FormGroup({
            notes: new FormControl(this.equipment.notes)
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancel() {
        if (this.equipmentNotesForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    cancelEdit() {
        this.cancelled.emit(null);
    }

    saveNotes() {
        if (this.equipmentNotesForm.invalid) {
            return;
        }
        const values = this.equipmentNotesForm.value;
        const payload = {
            id: this.equipment.id,
            notes: values.notes
        } as UpdateNotesCommand;
        if (this.equipment.id) {
            this.equipmentService
                .updateEquipmentNotes(this.equipment.id, payload)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: () => {
                        this.logging.success(
                            'Update equipment Notes Successfully'
                        );
                        this.equipmentNotesForm.markAsPristine();
                        this.cancel();
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error while update equipment Notes: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        }
    }
}
