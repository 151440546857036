<vg-player (onPlayerReady)="onPlayerReady($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls [vgAutohide]="true" [vgAutohideTime]="1">
        <vg-play-pause></vg-play-pause>
        <vg-playback-button *ngIf="options.speedSelector"></vg-playback-button>

        <vg-scrub-bar style="pointer-events: none"></vg-scrub-bar>

        <vg-time-display
            vgProperty="current"
            vgFormat="mm:ss"
            *ngIf="options.timeDisplay"
        ></vg-time-display>
        <vg-time-display
            vgProperty="total"
            vgFormat="mm:ss"
            *ngIf="options.showTotalTime"
        ></vg-time-display>

        <vg-mute></vg-mute>
        <vg-volume></vg-volume>

        <button
            *ngIf="
                supportsPip &&
                !isInPip &&
                options.allowPictureInPicture &&
                metadataLoaded
            "
            type="button"
            class="pip-button"
            tooltip="Enter picture-in-picture"
            placement="top"
            (click)="enterPip()"
        >
            <span class="fal fa-external-link-square-alt pip-icon"></span>
        </button>

        <vg-fullscreen *ngIf="options.allowFullscreen"></vg-fullscreen>

        <vg-track-selector *ngIf="options.allowTracks"></vg-track-selector>
    </vg-controls>

    <video
        [vgMedia]="media"
        #media
        id="singleVideo"
        preload="auto"
        style="width: 100%"
        [poster]="thumbnail"
        (play)="onPlaying()"
    >
        <source [src]="videoUrl" [type]="videoType" />
    </video>
</vg-player>
