import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    ExclusiveControlService,
    MaintenanceScheduleAssignmentDto,
    MaintenanceScheduleDto,
    MaintenanceScheduleService,
    NameId,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    SimpleMaintenanceScheduleDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceMaintenanceService } from '../resource-maintenance.service';

/**
 * This component allows the user, if they are allowed to, to set the
 * maintenance schedule for the resource
 */
@Component({
    selector: 'maintenance-schedule-config',
    templateUrl: './maintenance-schedule-config.component.html'
})
export class MaintenanceScheduleConfig {
    @Input() resourceId: number;
    @Input() resourceType: string;
    @Input() subCategory:
        | MaintenanceScheduleDto.EquipmentCategory
        | MaintenanceScheduleDto.RpaCategory;
    @Input() managingOrganisationId: number;
    @Input() maintenanceScheduleAssignment: MaintenanceScheduleAssignmentDto;
    @Input() canAssign: boolean;
    @Input() organisationId: number;

    availableSchedules: SimpleMaintenanceScheduleDto[];
    authorities: NameId[];

    formGroup = new FormGroup<{
        authorityId?: FormControl<number>;
        maintenanceScheduleId?: FormControl<number>;
    }>({
        authorityId: new FormControl(0),
        maintenanceScheduleId: new FormControl(undefined)
    });

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    private unlock: () => void;

    mode: 'VIEW' | 'EDIT' = 'VIEW';

    constructor(
        private maintenanceScheduleService: MaintenanceScheduleService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private resourceMaintenanceService: ResourceMaintenanceService,
        private exclusiveControlService: ExclusiveControlService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        if (this.canAssign) {
            this.maintenanceScheduleService
                .find(this.organisationId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    availableSchedules =>
                        (this.availableSchedules = availableSchedules.filter(
                            s =>
                                s.resourceCategory === this.resourceType &&
                                // if there are equipment or rpa categories on the schedule, filter by them too
                                ((s.equipmentCategory != null ||
                                    s.rpaCategory != null) &&
                                this.subCategory != null
                                    ? s.equipmentCategory ===
                                          this.subCategory ||
                                      s.rpaCategory === this.subCategory
                                    : true)
                        ))
                );

            this.organisationAuthorityService
                .findAuthorities(this.organisationId, this.organisationId)
                .subscribe(
                    authorities =>
                        (this.authorities = this.parseAuthorities(authorities))
                );
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    edit() {
        this.unlock = this.exclusiveControlService.lock(() => {
            this.mode = 'VIEW';
            return true;
        });
        this.mode = 'EDIT';

        this.formGroup.setValue({
            authorityId: this.maintenanceScheduleAssignment?.authority?.id ?? 0,
            maintenanceScheduleId:
                this.maintenanceScheduleAssignment?.overrideMaintenanceSchedule
                    ?.id ?? null
        });
    }

    assign() {
        const value = this.formGroup.value;

        this.resourceMaintenanceService
            .assign({
                organisationId: this.organisationId,
                maintenanceScheduleId: value.maintenanceScheduleId,
                authorityId: value.authorityId === 0 ? null : value.authorityId
            })
            .subscribe(assignment => {
                this.maintenanceScheduleAssignment = assignment;
                this.cancel();
            })
            .add(this.workTracker.createTracker());
    }

    cancel() {
        this.mode = 'VIEW';
        if (this.unlock != null) {
            this.unlock();
        }
    }

    private parseAuthorities(authorities: OrganisationAuthorityGroup[]) {
        return [{ id: 0, name: 'Organisation Default' }].concat(
            authorities
                .filter(g => g.hasWorkflow)
                .reduce(
                    (acc, g) =>
                        acc.concat(
                            g.authorities.map(
                                a =>
                                    ({
                                        id: a.id,
                                        name: `${
                                            g.abbreviation != null
                                                ? g.abbreviation
                                                : g.name
                                        } ${a.identifier ?? ''} ${a.startDate}`
                                    } as NameId)
                            )
                        ),
                    []
                )
        );
    }
}
