import * as Sentry from '@sentry/angular';
import { browserTracingIntegration } from '@sentry/angular';

/**
 * Setup Sentry using the DSN that has been applied to the window.
 */
export function initialiseSentry() {
    // @ts-ignore
    if (window.sentryDsn != null) {
        try {
            Sentry.init({
                // @ts-ignore
                dsn: window.sentryDsn,
                // @ts-ignore
                environment: window.sentryEnv,
                // @ts-ignore this is set by webpack
                release: release.identifier || 'dev',
                ignoreErrors: [/"status":401/],
                integrations: [browserTracingIntegration()],
                tracesSampleRate: 0.1
            });
        } catch (e) {
            window.console.warn(e);
        }
    }
}
