import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AirspaceJurisdictionDto,
    AttachmentHandler,
    FlyFreelyError,
    FlyFreelyLoggingService,
    JurisdictionService,
    LibraryActions,
    LibraryDto,
    LibraryService,
    MissionApprovalService,
    OrganisationAuthorityService,
    PersonRolesDto,
    PersonsOrganisationDto,
    SimpleAirspaceJurisdictionDto,
    SimpleAuthorityDto,
    WorkTracker,
    hasAnyPermission,
    hasAnyRole
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatAuthorityPipe } from '@flyfreely-portal-ui/ui';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { LibraryDialoguesService } from 'libs/libraries/src/lib/library-dialogue.service';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import * as moment from 'moment';
import { BehaviorSubject, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

export const LIBRARY_IDENTIFIER = 'libraries';

/**
 * This type encapsulates both Libraries and Authority Libraries.
 */
enum LibraryType {
    LIBRARY,
    ENDORSEMENT,
    JURISDICTION
}

interface Library {
    type: LibraryType;
    id: number;
    name: string;
    availableActions: LibraryActions;
}

function libraryDtoToLibrary(l: LibraryDto): Library {
    return {
        type: LibraryType.LIBRARY,
        name: l.name,
        id: l.id,
        availableActions: l.availableActions
    };
}

function authorityToLibrary(
    e: SimpleAuthorityDto,
    authorityFormat: string
): Library {
    return {
        type: LibraryType.ENDORSEMENT,
        name: authorityFormat,
        id: e.id,
        availableActions: { canEdit: false }
    };
}

function jurisdictionToLibrary(
    jurisdiction: AirspaceJurisdictionDto | SimpleAirspaceJurisdictionDto
): Library {
    return {
        type: LibraryType.JURISDICTION,
        name: `Jurisdiction library for ${jurisdiction.name}`,
        id: jurisdiction.id,
        availableActions: { canEdit: false }
    };
}

@Component({
    selector: 'library-list-widget',
    templateUrl: './library-list-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class LibraryListWidgetComponent implements OnInit, OnDestroy, Widget {
    organisation: PersonsOrganisationDto;

    public working: boolean;
    private workTracker: WorkTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    selectedTableColumns: TableColumn[] = [];

    libraryList: string[];

    libraries = new Subject<Library[]>();
    authorities = new Subject<Library[]>();
    jurisdictions = new BehaviorSubject<Library[]>(undefined);
    libraryList$ = combineLatest([
        this.libraries,
        this.authorities,
        this.jurisdictions
    ]).pipe(
        map(lists => lists.reduce((acc, l) => acc.concat(l), [])),
        tap(libraries => {
            if (libraries.length === 0) {
                return;
            }
            this.libraryList = libraries.map(l => {
                return l.name;
            });
            if (
                this.library == null ||
                // After deleting the selected library won't be in the list any more
                libraries.find(l => l.id === this.library.id) == null
            ) {
                this.library = libraries[0];
                this.refreshDocuments();
                return;
            }

            this.library =
                libraries.find(
                    l =>
                        l.type === this.library.type && l.id === this.library.id
                ) ?? libraries[0];
        })
    );

    library: Library;

    canAdd: boolean;

    attachmentsHandler: AttachmentHandler;

    constructor(
        private formatAuthorityPipe: FormatAuthorityPipe,
        private missionApprovalService: MissionApprovalService,
        private libraryService: LibraryService,
        private libraryDialogues: LibraryDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private jurisdictionService: JurisdictionService,
        private logging: FlyFreelyLoggingService,
        private workspaceStateService: WorkspaceStateService
    ) {}

    ngOnInit(): void {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshLibraries();
                this.refreshAuthorities();

                this.refreshPermissions();
                if (this.organisation) {
                    this.jurisdictions.next(
                        this.organisation.activeJurisdictions
                            .filter(j => j.hasLibrary)
                            .map(j => jurisdictionToLibrary(j))
                    );
                }
            });
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.libraryService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshLibraries());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();

        if (this.attachmentsHandler != null) {
            this.attachmentsHandler.destroy();
        }
    }

    private refreshPermissions() {
        this.canAdd = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
        );
    }

    private refreshLibraries() {
        if (!this.organisation) {
            return;
        }

        this.libraryService
            .findLibraries(this.organisation.id)
            .subscribe(
                results =>
                    this.libraries.next(
                        results.map(l => libraryDtoToLibrary(l))
                    ),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing document libraries: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    private refreshAuthorities() {
        if (!this.organisation) {
            return;
        }
        this.missionApprovalService
            .findApprovingAuthorities(
                this.organisation.id,
                null,
                moment().format('YYYY-MM-DD')
            )
            .subscribe(
                authorities =>
                    this.authorities.next(
                        authorities
                            .filter(a => a.hasLibrary && !a.archived)
                            .map(a =>
                                authorityToLibrary(
                                    a,
                                    this.formatAuthorityPipe.transform(a)
                                )
                            )
                    ),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing authorities: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    refreshDocuments() {
        if (!this.organisation) {
            return;
        }

        if (this.attachmentsHandler != null) {
            this.attachmentsHandler.destroy();
            this.attachmentsHandler = null;
        }

        if (this.library == null) {
            this.attachmentsHandler = null;
            return;
        }

        switch (this.library.type) {
            case LibraryType.ENDORSEMENT:
                this.attachmentsHandler =
                    this.organisationAuthorityService.attachmentHandler(
                        this.library.id,
                        this.organisation.id
                    );
                break;
            case LibraryType.LIBRARY:
                this.attachmentsHandler = this.libraryService.attachmentHandler(
                    this.library.id,
                    this.organisation.id
                );
                break;
            case LibraryType.JURISDICTION:
                this.attachmentsHandler =
                    this.jurisdictionService.attachmentHandler(this.library.id);
                break;
        }
    }

    newLibrary() {
        this.libraryDialogues.createLibrary(this.organisation.id).then(
            library => {
                this.library = libraryDtoToLibrary(library);
                this.logging.success('Library created');
                this.refreshLibraries();
                // If the documents aren't refreshed here the previously selected library's docs remain loaded
                this.refreshDocuments();
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error creating library: ${error.message}`
                );
            }
        );
    }

    editLibrary(library: LibraryDto) {
        this.libraryDialogues.editLibrary(library).then(
            event => {
                if (event.type === 'DELETE') {
                    this.logging.success('Library deleted');
                } else if (event.type === 'UPDATE') {
                    this.logging.success('Library name changed');
                }
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error updating library: ${error.message}`
                );
            }
        );
    }
}

export const libraryWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: LIBRARY_IDENTIFIER,
    component: LibraryListWidgetComponent,
    isAvailable: organisation =>
        organisation &&
        !organisation.personalOrganisation &&
        !hasAnyRole(organisation.roles, [PersonRolesDto.Roles.READ_ONLY]) &&
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.ORGANISATION_VIEW
        )
};
