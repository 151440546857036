import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    applyResponsesToRequiredDocumentation,
    AttachmentHandler,
    ConcreteMappedEntityConcreteFormDto,
    CurrentAttachmentVersionDto,
    DownloadService,
    existsAndHasValue,
    FormResponseCommand,
    FormResponseDto,
    FormResponseFormGroup,
    initialiseResponse,
    MaybeRequiringEntity,
    RelatedEntityRequiredDocumentationAndResponses,
    RequestersMissionApprovalDto,
    RequiredDocumentation,
    StepDescription,
    StepEntityRequiredDocumentation,
    StepEntityRequiredDocumentationAndResponses,
    toFormResponseDto
} from '@flyfreely-portal-ui/flyfreely';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentationDialoguesService } from '../documentation-dialogues.service';
import { DocumentationActions } from '../interfaces';
import { formAttachmentUrlFactory } from 'libs/missions/src/lib/helpers';

@Component({
    selector: 'documentation-list',
    templateUrl: './documentation-list.component.html',
    styleUrls: ['./documentation-list.component.scss']
})
export class DocumentationList implements OnChanges {
    @Input() organisationId: number;
    @Input() title: string;
    @Input() requiringEntities: MaybeRequiringEntity[];
    @Input() formResponses: FormResponseFormGroup;
    @Input() requiredDocumentation: StepEntityRequiredDocumentation;
    @Input() steps: StepDescription[];
    @Input() documentationActions: DocumentationActions;
    @Input() attachmentsHandler: AttachmentHandler;
    @Input() missionRequiredDocumentation: {
        [key: string]: RequiredDocumentation;
    };
    @Input() missionApprovalList?: RequestersMissionApprovalDto[];

    attachmentsByRequirementId: {
        [requirementId: number]: CurrentAttachmentVersionDto[];
    };

    attachments: CurrentAttachmentVersionDto[];
    hasData: { [step: string]: boolean };
    documentationResponses: StepEntityRequiredDocumentationAndResponses;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private documentationDialoguesService: DocumentationDialoguesService,
        private downloadService: DownloadService
    ) {}

    ngOnInit() {
        if (this.attachmentsHandler == null) {
            this.attachments = [];
            return;
        }
        this.attachmentsHandler.attachments$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(attachments => {
                this.attachments = attachments;
                this.attachmentsByRequirementId = this.attachments
                    .filter(a => a.requirementId != null)
                    .reduce(
                        (acc, a) => ({
                            ...acc,
                            [a.requirementId]:
                                a.requirementId in acc
                                    ? acc[a.requirementId].concat(a)
                                    : [a]
                        }),
                        {} as {
                            [
                                requirementId: number
                            ]: CurrentAttachmentVersionDto[];
                        }
                    );
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            'formResponses' in changes ||
            'requiredDocumentation' in changes ||
            'requiringEntities' in changes
        ) {
            this.refreshStatus();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshStatus() {
        this.hasData = {};

        if (!this.steps || !this.requiredDocumentation || !this.formResponses) {
            return;
        }

        this.documentationResponses = applyResponsesToRequiredDocumentation(
            this.requiredDocumentation,
            this.formResponses,
            this.requiringEntities
        );

        for (const step of this.steps) {
            this.hasData[step.value] = this.requiredDocumentation[
                step.value
            ].reduce((acc, req) => acc || req.forms.length > 0, false);
        }
    }

    showFormResponse(
        step: string,
        entity: RelatedEntityRequiredDocumentationAndResponses,
        formId: number
    ) {
        const form = entity.forms.find(f => f.entity.formId === formId);
        const responses = entity.formResponses[formId].filter(
            r => r.step === step
        );
        const responseList =
            responses.length > 0
                ? responses
                : [
                      toFormResponseDto(
                          initialiseResponse(
                              form,
                              entity.relatedEntityType,
                              entity.relatedEntityId
                          ),
                          form.entity
                      )
                  ];

        const buildFormAttachmentUrl = formAttachmentUrlFactory(
            form,
            this.missionApprovalList
        );

        this.documentationDialoguesService.showFormResponseDialogue(
            responseList,
            this.title,
            this.organisationId,
            buildFormAttachmentUrl
        );
    }

    printFormResponse(response: FormResponseDto, step: string) {
        this.downloadService.downloadFromUrl(
            this.documentationActions.getFormResponsePrintUrl(
                response.formId,
                step
            )
        );
    }

    printFormTemplate(formId: number, step: string) {
        this.downloadService.downloadFromUrl(
            this.documentationActions.getFormTemplatePrintUrl(formId, step)
        );
    }

    editForm(
        step: string,
        entity: RelatedEntityRequiredDocumentationAndResponses,
        form: ConcreteMappedEntityConcreteFormDto
    ) {
        const originalResponses: FormResponseDto[] = existsAndHasValue(
            entity.formResponses[form.entity.formId]
        )
            ? [
                  this.formResponses[form.entity.formId].find(
                      resp => resp.step === step
                  )
              ]
            : [];

        const subscriptions = {
            onHide: null as Subscription
        };

        const modal = this.documentationDialoguesService.showFormInputDialogue(
            form,
            `${this.title} Form`,
            originalResponses,
            this.organisationId,
            (response: FormResponseCommand) =>
                this.documentationActions.storeFormResponse(
                    form.entity.formId,
                    step,
                    {
                        ...response,
                        relatedEntityType: entity.relatedEntityType,
                        relatedEntityId: entity.relatedEntityId
                    }
                )
        );

        subscriptions.onHide = modal.onHide.subscribe(() =>
            subscriptions.onHide.unsubscribe()
        );
    }
}
