import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DO_NOTHING } from '../utils';
import { PreferencesService } from './preferences.service';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    identifiersAlreadyShown: string[];

    private ngUnsubscribe$ = new Subject<void>();
    constructor(private preferencesService: PreferencesService) {}

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Check if HotJar is available in this instance. Returns a boolean;
     */
    isHotJarAvailable() {
        // @ts-ignore - type sense not available
        return window.hj != null;
    }

    /**
     * Show a feedback form for the component with the matching identifier.
     * The feedback service will ensure this is only shown once using user preferences.
     * @param identifier A string literal as identifier for the the feedback to be shown.
     * @param organisationId (optional) Used to mark as shown per organisation in user preferences
     */
    showOnce(identifier: string, organisationId?: number) {
        this.preferencesService
            .findPreferences('feedback', organisationId ?? null)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (result: string[]) => {
                    this.identifiersAlreadyShown = result ?? [];
                    if (result != null && result.includes(identifier)) {
                        return;
                    }
                    this.showFeedback(identifier, organisationId);
                },
                error: () => {
                    this.identifiersAlreadyShown = [];
                    this.showFeedback(identifier, organisationId);
                }
            });
    }

    /**
     * Show feedback the identifier regardless of how many times it has been shown before.
     * To limit the feedback to only showing once, use the <showOnce> function.
     * NOTE: This will still mark the feedback as shown in user preferences.
     * @param identifier A string literal as identifier for the the feedback to be shown.
     * @param organisationId (optional) Used to mark as shown per organisation in user preferences
     */
    showFeedback(identifier: string, organisationId?: number) {
        // @ts-ignore - type sense not available
        if (window.__DEV__) {
            alert(
                `This would show HotJar feedback for the identifier: ${identifier}`
            );
        }
        if (this.isHotJarAvailable()) {
            // @ts-ignore - type sense not available
            window.hj('trigger', identifier);
            this.markAsShown(identifier, organisationId);
        }
    }

    private markAsShown(identifier: string, organisationId?: number) {
        this.identifiersAlreadyShown.push(identifier);
        this.preferencesService
            .updatePreferences(
                'feedback',
                organisationId ?? null,
                this.identifiersAlreadyShown
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(DO_NOTHING);
    }
}
