<ng-template
    #documentList
    let-relatedEntity="relatedEntity"
    let-approvalId="approvalId"
    let-readonly="readonly"
>
    <ng-container *ngFor="let form of relatedEntity.forms; let first = first">
        <ng-container
            *ngIf="
                missionDocumentation &&
                    requiredFormsPerApproval[approvalId] &&
                    requiredFormsPerApproval[approvalId][form.entity?.formId];
                else emptyDocumentList
            "
        >
            <div class="form-group input-content row">
                <label
                    class="col-xs-6 control-label"
                    [ngClass]="{ readonly: readonly }"
                    >{{ form.entity.formName }}
                    <field-validation-indicator
                        [required]="form.required"
                        [valid]="form.completed"
                        [hasValue]="
                            form.completed === true ||
                            form.progress.progress > 0
                        "
                        noun="document"
                    ></field-validation-indicator
                ></label>
                <div class="col-xs-6">
                    <p [ngClass]="{ readonly: readonly }">
                        {{ form.entity.description }}
                    </p>
                    <p
                        *ngIf="
                            prefillErrors != null &&
                            prefillErrors[form.entity.formId]
                        "
                        class="alert alert-warning"
                    >
                        <span class="fal fa-info-circle right-buffer"></span>
                        This documentation could not be prefilled, probably
                        because the active version is different to the prefill
                        version
                    </p>

                    <button
                        class="btn btn-default btn-confirm"
                        type="button"
                        (click)="showForm(relatedEntity, form)"
                        [disabled]="readonly"
                    >
                        <span
                            *ngIf="
                                !form.completed && form.progress.progress == 0
                            "
                        >
                            Start
                        </span>
                        <span
                            *ngIf="
                                !form.completed && form.progress.progress > 0
                            "
                        >
                            Continue
                        </span>
                        <span *ngIf="form.completed"> Done </span>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-template #emptyDocumentList>
            <div
                *ngIf="approvalId > -1 && first"
                class="form-group input-content row"
            >
                <p>No approval documents</p>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-container *ngFor="let relatedEntity of documentationResponses">
    <span
        *ngIf="
            relatedEntity.forms.length > 0 ||
            relatedEntity.attachments.length > 0
        "
        >{{ relatedEntity.name }}</span
    >
    <div *ngIf="relatedEntity.forms.length > 0">
        <ng-container
            [ngTemplateOutlet]="documentList"
            [ngTemplateOutletContext]="{
                relatedEntity: relatedEntity,
                approvalId: -1,
                readonly: readonly
            }"
        ></ng-container>
        <div
            *ngIf="activeApprovals && activeApprovals.length"
            class="horizontal-container container-scroll"
        >
            <ng-container
                *ngFor="let approval of activeApprovals; let i = index"
            >
                <div
                    class="vertical-container contents approval-data approval-documentation"
                    style="justify-content: space-between"
                    [ngClass]="{ disabled: readonly }"
                >
                    <div class="vertical-container" style="min-height: 10rem">
                        <h4
                            class="inline-heading"
                            [ngClass]="{ disabled: readonly }"
                        >
                            Approval {{ i + 1 }}
                        </h4>
                        <span class="top-buffer bottom-buffer"
                            ><strong>Ruleset:</strong>
                            {{
                                approval.authorityType?.ruleset?.name != null
                                    ? approval.authorityType?.ruleset?.name
                                    : 'None'
                            }}
                        </span>
                        <span
                            ><strong>Authority:</strong>
                            {{
                                (getSimplifiedAuthorityFromApproval(
                                    approval,
                                    organisation
                                ) | formatAuthority) || 'None'
                            }}
                        </span>
                    </div>
                    <div class="vertical-container">
                        <h4
                            class="inline-heading"
                            [ngClass]="{ disabled: readonly }"
                        >
                            Approval documentation
                        </h4>
                        <ng-container
                            [ngTemplateOutlet]="documentList"
                            [ngTemplateOutletContext]="{
                                relatedEntity: relatedEntity,
                                approvalId: approval.approval.id,
                                readonly: readonly
                            }"
                        ></ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div
        *ngIf="relatedEntity.attachments.length > 0 && this.attachments != null"
    >
        <div
            *ngFor="let attachment of relatedEntity.attachments"
            class="form-group input-content"
        >
            <label
                class="col-xs-3 control-label"
                [ngClass]="{ readonly: readonly }"
                >{{ attachment.entity.name }}
                <field-validation-indicator
                    [required]="attachment.entity.minimumInstances > 0"
                    [valid]="attachment.valid"
                    [hasValue]="attachment.hasValue"
                    noun="attachment"
                ></field-validation-indicator>
            </label>
            <div class="col-xs-6">
                <attachment-list
                    [attachmentsHandler]="attachmentsHandler"
                    [allowUploads]="!attachment.metMaximum"
                    [allowReuploads]="true"
                    [allowDelete]="true"
                    [allowEdit]="true"
                    [parentPromiseTracker]="workTracker"
                    [disabled]="readonly"
                    [requirementId]="attachment.entity.id"
                    permissions
                >
                </attachment-list>
            </div>
        </div>
    </div>
</ng-container>
