import { Component, Input } from '@angular/core';
import { ngxLoadingAnimationTypes, NgxLoadingConfig } from 'ngx-loading';

@Component({
    selector: 'airspace-unavailable-alert',
    template: `
        <ngx-loading
            [show]="show"
            [config]="displayConfig"
            [template]="displayTemplate"
        ></ngx-loading>

        <ng-template #displayTemplate>
            <div class="operation-failed-alert alert alert-danger">
                <p *ngIf="!customStatusText && !customStatusMessage">
                    The Airspace Checker and Authorisation system is not
                    available currently. Please try again later.
                </p>
                <p *ngIf="customStatusMessage">
                    {{ customStatusMessage }}
                </p>
                <p *ngIf="customStatusText" [innerHTML]="customStatusText"></p>
            </div>
        </ng-template>
    `
})
export class AirspaceUnavailableAlert {
    @Input() show = false;
    @Input() config: NgxLoadingConfig;
    @Input() customStatusMessage: string;
    @Input() customStatusText: string;

    defaultConfig: NgxLoadingConfig = {
        animationType: ngxLoadingAnimationTypes.none
    };

    displayConfig: NgxLoadingConfig;

    ngOnInit() {
        this.displayConfig = this.config ?? this.defaultConfig;
    }
}
