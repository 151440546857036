import { Injectable } from '@angular/core';
import { AttachmentHandler } from '@flyfreely-portal-ui/flyfreely';

@Injectable()
export class AttachmentHandlerService {
    private _attachmentHandler: AttachmentHandler | null = null;

    set attachmentHandler(handler: AttachmentHandler | null) {
        this._attachmentHandler = handler;
    }

    get attachmentHandler(): AttachmentHandler | null {
        return this._attachmentHandler;
    }
}
