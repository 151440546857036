import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    MapDrawSelectionChangeEvent,
    MapEventType,
    ToolbarButton,
    ToolbarButtonClickedMapEvent
} from '../events';
import { MapService } from '../map.service';
import { getMeasurementProperties } from '../utils';

@Component({
    selector: 'map-toolbar-delete-button',
    template: `<button
        *ngIf="isActive"
        class="btn btn-light"
        type="button"
        (click)="deleteMeasurement()"
        placement="bottom"
        tooltip="Delete measurement"
    >
        <i class="fal fa-trash"></i>
    </button>`
})
export class MapToolbarDeleteButtonComponent implements OnInit {
    private ngUnsubscribe$ = new Subject<void>();

    isActive = false;

    featureId: string;

    constructor(
        private mapService: MapService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event => event.type === MapEventType.mapDrawSelectionChanged
                )
            )
            .subscribe((event: MapDrawSelectionChangeEvent) => {
                const {
                    isMeasurementLabel,
                    isMeasurementLine,
                    isMeasurementRadius,
                    isMeasurementRadiusLine,
                    featureId
                } = getMeasurementProperties(
                    event.payload.features,
                    event.payload.points
                );

                this.featureId = featureId;

                this.isActive =
                    isMeasurementLabel ||
                    isMeasurementLine ||
                    isMeasurementRadius ||
                    isMeasurementRadiusLine;
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy(): any {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    deleteMeasurement() {
        this.mapService.mapEvent$.next(
            new ToolbarButtonClickedMapEvent({
                button: ToolbarButton.Delete,
                featureId: this.featureId
            })
        );
    }
}
