import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
    AirspaceAuthorisationManager,
    airspaceAuthorisationTypesRequiringCurrentUserAsRpic,
    AirspaceMissionEditService
} from '@flyfreely-portal-ui/airspace';
import {
    AerodromeDetailsDto,
    AirspaceAuthorisationDto,
    AirspaceAuthorisationService,
    AirspaceCheckCommand,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    areRequirementsCompleted,
    AttachmentHandler,
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    AuthorityTypeRegisterConditionsDto,
    buildDisplayableMissionDocumentation,
    CombinedMissionStatus,
    ContactDto,
    ContactWithLocationDto,
    CraftAuthorityDto,
    CurrentAttachmentVersionDto,
    defaultTimezone,
    DisplayableMissionDto,
    displayableMissionDtoCombinedStatus,
    DO_NOTHING,
    EquipmentDto,
    EquipmentTypeDto,
    ExclusiveControlService,
    FEATURE_ADDITIONAL_AUTHORITIES,
    FEATURE_EQUIPMENT,
    FEATURE_MISSION_AERODROMES,
    FEATURE_MISSION_OBJECTIVES,
    FEATURE_NOTAMS,
    FEATURE_RPA_RESOURCE_ASSOCIATION,
    FEATURE_WORKGROUPS,
    FlyFreelyError,
    FlyFreelyLoggingService,
    FormResponseCommand,
    fromTimestamp,
    hasFeatureFlag,
    InUseMissionWorkflowVersionDto,
    LocationDetailsDto,
    LocationDto,
    LocationFeatureDto,
    LockedMissionFields,
    MissionApprovalService,
    MissionCrewDto,
    MissionDocumentationDto,
    MissionObjectiveDto,
    MissionObjectivesService,
    MissionOperationTypeDto,
    MissionRoleDto,
    MissionService,
    MissionSummaryDto,
    NameId,
    NotamDto,
    observeFormControl,
    Organisation,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    personSearch,
    PersonsOrganisationDto,
    PreferencesService,
    prefillPreviousDocumentation,
    RequestersMissionApprovalDto,
    RpaTypeDto,
    RpaTypesService,
    SetupGuideActivityDto,
    SimpleAuthorityDto,
    toLocalTime,
    toLookup,
    toTimestamp,
    updateFormTreeValidity,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { WorkgroupsDataService } from '@flyfreely-portal-ui/organisation-admin';
import { FormatRpaPipe } from '@flyfreely-portal-ui/resource-ui';
import area from '@turf/area';
import bearing from '@turf/bearing';
import center from '@turf/center';
import distance from '@turf/distance';
import { bearingToAzimuth } from '@turf/helpers';
import { Angulartics2 } from 'angulartics2';
import { pipe } from 'fp-ts/es6/function';
import { alt, getOrElse, map as optionMap } from 'fp-ts/es6/Option';
import { FeatureCollection } from 'geojson';
import {
    AirspaceCheckService,
    LocationSunriseSunset
} from 'libs/airspace/src/lib/airspace-check/airspace-check.service';
import { AirspaceDialoguesService } from 'libs/airspace/src/lib/airspace-dialogues.service';
import { calculateAuthorisationListState } from 'libs/airspace/src/lib/authorisation/authorisation-status';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { toSimpleAuthority } from 'libs/authorities/src/lib/helpers';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { DocumentationStorage } from 'libs/documentation/src/lib/interfaces';
import { EquipmentDialoguesService } from 'libs/equipment/src/lib/equipment-dialogues.service';
import { WorkGroup } from 'libs/flyfreely/src/lib/services/workgroups.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import {
    flightAreaOf,
    flightAreaPointOfFeatures,
    getFeatureGroups
} from 'libs/locations/src/lib/helpers';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { FormatMissionEditRegisterStatus } from 'libs/missions/src/lib/mission-edit-v2/formatMissionEditRegisterStatus.pipe';
import { SetupGuideChecklistService } from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import { PersonnelDialoguesService } from 'libs/personnel/src/lib/personnel-dialogues.service';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import { FormatAuthorityPipe } from 'libs/ui/src/lib/pipes';
import * as moment from 'moment';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    forkJoin,
    from,
    Observable,
    of,
    Subject,
    timer
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { LocationBearing } from '../bearing.pipe';
import {
    aerodromesToFeatureGroups,
    findActiveApprovals,
    getSimplifiedAuthorityFromApproval,
    notamToFeatureCollection,
    sortMissionRegisterStatus
} from '../helpers';
import { MissionApprovalWithAuthority, MissionFormValue } from '../interfaces';
import { MissionDialoguesService } from '../mission-dialogues.service';
import { validateCrew } from '../mission-validators';
import {
    AerodromeContact,
    AerodromeFrequency
} from './aerodrome-search/aerodrome-search-dialogue.component';
import { MissionDocumentation } from './mission-documentation/mission-documentation.component';
import {
    MissionEditService,
    PersonWithRegisterStatus,
    SelectableRpa
} from './mission-edit.service';

const MISSION_FIELD_APP_INFO = 'mission-field-app-info';
const SCREEN_IDENTIFIER = 'mission-edit-v2-dialogue';
const AIRSPACE_DISCLAIMER = 'airspace-disclaimer-acknowledgement';

/**
 * Returns a matcher function for the given contact.
 * @param contact contact to match
 */
function matchesAerodromeContact(contact: AerodromeContact) {
    return (c: ContactDto) =>
        c.identifier === contact.aerodromeCode &&
        c.value === contact.contact &&
        c.subtype === contact.type;
}

function matchesAerodromeFrequency(frequency: AerodromeFrequency) {
    return (f: ContactDto) =>
        f.identifier === frequency.aerodromeCode &&
        f.value === frequency.frequency &&
        f.subtype === frequency.abbreviation;
}

enum SubmitOption {
    NONE = 'NONE',
    SUBMIT = 'SUBMIT',
    FLY = 'FLY'
}

interface LocationBearings {
    notams: LocationBearing[];
    aerodromes: LocationBearing[];
    locationCentre: GeoJSON.Point;
    locationArea: number;
}

interface AdditionalAuthorityNameIdWithRequiresApproval extends NameId {
    requiresApproval: boolean;
}

/**
 * The mapping to ContactDto is
 * ContactDto.identifier = aerodrome code = InfoPillData.identifier
 * ContactDto.name = aerodrome name = part of InfoPillData.name
 * ContactDto.subtype = Frequency abbreviation or Contact type = InfoPillData.label
 */
@Component({
    selector: 'mission-edit-v2-dialogue',
    templateUrl: './mission-edit-v2-dialogue.component.html',
    styleUrls: ['../styles.scss'],
    providers: [
        ExclusiveControlService,
        MissionEditService,
        AirspaceCheckService,
        AirspaceAuthorisationManager,
        WorkTracker,
        {
            provide: AirspaceMissionEditService,
            useExisting: MissionEditService
        },
        WorkgroupsDataService
    ]
})
export class MissionEditV2Dialogue implements OnInit, OnDestroy {
    @Input() mission: DisplayableMissionDto;
    @Input() organisation: Organisation;
    @Input() sourceMissionId: number;

    lockedFields: LockedMissionFields;

    // This holds the last saved version of the mission
    savedMission: DisplayableMissionDto;

    // This holds the current resolved values for the values in the FormGroup
    missionDetails = {
        missionWorkflowVersion: null as InUseMissionWorkflowVersionDto
    };

    // A flag to indicate not to run the workflow change functions when true
    skipLoadingWorkflows = false;

    working = false;

    disabled: boolean;

    isWorkgroupSelect = false;

    private ngUnsubscribe$ = new Subject<void>();

    rpas: SelectableRpa[];
    pilots: PersonWithRegisterStatus[];
    personnel: PersonWithRegisterStatus[];
    rpicRole: MissionRoleDto;
    crewRoles: MissionRoleDto[];
    missionTypes: MissionOperationTypeDto[];
    equipment: EquipmentDto[];
    rpaTypes: RpaTypeDto[];

    pilotRegisterDisabled = true;
    rpaRegisterDisabled = true;

    timeZones: string[];
    timeZoneOffsets: { [timeZone: string]: string };
    utc: string;

    missionStartTime: string;
    missionEndTime: string;

    isCreated: boolean;
    hasClonedFormResponses = false;
    isAddingContacts: boolean;
    isAddingFrequencies: boolean;
    isAddingAerodromes: boolean;
    defaultAerodromeCode: string = null;
    uploadMessage: string;
    confirmedStep: any[];
    locked: boolean;

    weather: any;
    attachments: CurrentAttachmentVersionDto[];
    missionWorkflows: InUseMissionWorkflowVersionDto[];
    location: LocationDetailsDto;

    locationFeatures$: Observable<FeatureGroup[]>;

    locationBearings: LocationBearings;

    sunriseSunset: LocationSunriseSunset;
    sunrise: string;
    sunset: string;
    civilTwilightStart: string;
    civilTwilightEnd: string;

    currentLocalTime: string;
    newClock$ = new Subject<void>();

    uploadError: string;

    /**
     * A list of all the current mission approvals across the workflow authorities
     * as well as additional authorities added to the mission.
     */
    missionApprovals: MissionApprovalWithAuthority[] = [];
    getSimplifiedAuthorityFromApproval = getSimplifiedAuthorityFromApproval;

    missionWorkflowPrimaryAuthority: OrganisationAuthorityDto;
    /**
     * List of all authorities associated to the mission approvals.
     */
    missionAuthorities: OrganisationAuthorityDto[] = [];
    missionAuthorityGroups: OrganisationAuthorityGroup[] = [];
    authorityRegistersSetUp: boolean;
    authorityRegistersActionMessage: string;
    crewLookup: { [id: number]: string };
    selectRpic = true;
    showRpicRegisterDetails = false;
    registerEntriesOnMissionLoading = false;

    availableAuthorityLookup: {
        [authorityId: number]: AdditionalAuthorityNameIdWithRequiresApproval;
    };
    availableAdditionalAuthorities: AdditionalAuthorityNameIdWithRequiresApproval[] =
        [];
    allAdditionalAuthorities: AdditionalAuthorityNameIdWithRequiresApproval[] =
        [];
    newAdditionalAuthorityId: number;

    /**
     * Is an approval required to be created
     */
    // approvalCreationRequired = false;
    submitOption: SubmitOption = SubmitOption.NONE;
    canSubmitForApproval: boolean;
    canEditMissionCrew: boolean;
    canUseAdditionalAuthorities: boolean;
    canUseAerodromes: boolean;
    canUseEquipment: boolean;
    canUseNotams: boolean;
    canUseWorkgroup: boolean;
    canAddEquipment: boolean;
    canAddCraft: boolean;

    hasRpaResourceAssociation: boolean;
    canCheckAirspace: boolean;
    userCanViewAirspaceAuthorisation = false;
    userCanRequestAirspaceAuthorisation = false;

    latestAuthorisations: AirspaceAuthorisationDto[];
    latestAuthorisationStatus: AirspaceAuthorisationDto.Status;
    airspaceAuthorisationsEnabled: boolean;
    checkingAuthorisation: boolean;
    enabledAuthorisationTypes: AirspaceAuthorisationDto.AuthorisationType[] = [
        'NZL_AIRSHARE',
        'USA_LAANC'
    ];
    areAuthorisationRequirementsComplete: boolean;
    airspaceCheckCanFly: boolean;
    isAuthorisationOptional = true;
    showInlineAuthorisation = false;

    isUsingAerodromes = false;
    isAddingNotams = false;
    showAirspaceDisclaimer = false;

    currentStep: string;
    attachmentsHandler: AttachmentHandler;

    isShowingSelectPrefillMission = false;

    workgroup$ = this.workgroupDataService.workgroup$;

    canUseObjectives: boolean;
    missionObjectives: MissionObjectiveDto[];

    loadingWorkflow = false;

    renderForm = false;
    isActive: { [key: string]: boolean };

    isSubmittable = false;
    isValidAuthorisation = false;
    authorisationTooltip = 'Request Authorisation';

    combinedStatus: CombinedMissionStatus;

    cancelStack: (() => void)[] = [];

    personSearch = personSearch.bind(this);

    isResourcesCompleted = false;
    time: string;

    mapData$ = new BehaviorSubject<{ [source: string]: FeatureCollection }>({});

    showHandoffPopup = true;

    prefillErrors: { [formId: number]: boolean };

    allMissionDocumentation: MissionDocumentationDto;

    @ViewChild('map', { read: ElementRef })
    mapElement: ElementRef;
    @ViewChild(MissionDocumentation) missionDocumentation: MissionDocumentation;
    documentationStorage: DocumentationStorage = {
        storeFormResponse: (
            formId: number,
            step: string,
            response: FormResponseCommand
        ) =>
            this.missionService
                .updateDocumentation(this.savedMission.id, {
                    [step]: [response]
                })
                .toPromise()
                .then(documentation => this.setupDocumentation(documentation))
    };

    get missionForm() {
        return this.missionEditService.missionForm;
    }

    get missionObjectivesGroup() {
        return this.missionEditService.missionObjectivesGroup;
    }

    constructor(
        private modal: BsModalRef<MissionEditV2Dialogue>,
        modalOptions: ModalOptions,
        private missionService: MissionService,
        private formatRpaPipe: FormatRpaPipe,
        public workTracker: WorkTracker,
        private rpaDialoguesService: RpaDialoguesService,
        private equipmentDialoguesService: EquipmentDialoguesService,
        private personDialoguesService: PersonnelDialoguesService,
        private missionApprovalService: MissionApprovalService,
        private missionObjectivesService: MissionObjectivesService,
        public airspaceCheckService: AirspaceCheckService,
        private airspaceDialoguesService: AirspaceDialoguesService,
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        private airspaceAuthorisationService: AirspaceAuthorisationService,
        private logging: FlyFreelyLoggingService,
        private changeDetector: ChangeDetectorRef,
        private fullScreenService: FullScreenService,
        public angulartics2: Angulartics2,
        private commonDialoguesService: CommonDialoguesService,
        @Inject(forwardRef(() => MissionDialoguesService))
        private missionDialoguesService: MissionDialoguesService,
        private preferencesService: PreferencesService,
        private exclusiveControlService: ExclusiveControlService,
        private missionEditService: MissionEditService,
        private setupGuideChecklistService: SetupGuideChecklistService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private rpaTypesService: RpaTypesService,
        private userService: UserService,
        private formatAuthorityPipe: FormatAuthorityPipe,
        private workgroupDataService: WorkgroupsDataService
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.locked === true) {
                this.exclusiveControlService.requestUnlock();
                this.isAddingFrequencies = false;
                this.isAddingContacts = false;
                return Promise.reject();
            }
            if (this.cancelStack.length > 0) {
                this.cancelStack[this.cancelStack.length - 1]();
                return Promise.reject();
            }
            if (this.missionForm.dirty) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            }
            return Promise.resolve();
        };

        this.isCreated = false;

        airspaceAuthorisationManager.setup(['NZL_AIRSHARE', 'USA_LAANC']);
    }

    ngOnInit() {
        this.refreshPermissions();

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.exclusiveControlService.lock$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locked => {
                this.locked = locked;
                this.changeDetector.detectChanges();
            });

        this.activeTab('objectives');

        this.timeZones = moment.tz.names();

        this.missionEditService.missionWorkflows$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missionWorkflows => {
                const selectedWorkflowVersionId =
                    this.missionWorkflowVersionId.value;
                this.missionWorkflows = missionWorkflows
                    .filter(
                        missionWorkflow =>
                            missionWorkflow.delegatedAuthority === null ||
                            missionWorkflow.id === selectedWorkflowVersionId ||
                            !missionWorkflow.authorityArchived
                    )
                    .map(item => ({
                        ...item,
                        disabled: item.authorityArchived
                    }));
                this.updateMissionWorkflowVersionReference();
                this.refreshControlState();
                this.refreshCurrentAuthority();
                this.setDefaultWorkflows();
            });

        this.missionEditService.loadingWorkflow$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                loadingWorkflow => (this.loadingWorkflow = loadingWorkflow)
            );
        this.missionEditService.registerEntriesOnMissionLoading$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                loading => (this.registerEntriesOnMissionLoading = loading)
            );

        this.missionEditService.setOrganisationId(
            this.mission?.organisationId ??
                this.savedMission?.organisationId ??
                this.organisation.id
        );
        this.missionEditService.setup();

        if (
            this.location?.airspaceJurisdiction?.airspaceCheckSupport !==
            AirspaceJurisdictionDto.AirspaceCheckSupport.NONE
        ) {
            // Start the mission checker so we can verify the authorisation information
            this.airspaceCheckService.startMissionCheck();
        }
        this.findCanCheckAirspace();

        combineLatest([
            this.missionForm.controls.missionTypeId.statusChanges,
            this.missionForm.controls.missionWorkflowVersionId.statusChanges,
            this.missionForm.controls.craftIds.statusChanges,
            this.missionForm.controls.rpicId.statusChanges
        ])
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(() => {
                this.updateIsResourcesCompleted();
            });

        this.equipmentIds.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(value => {
                this.canAddEquipment =
                    value.filter(v => v == null).length === 0;
            });

        this.craftIds.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(value => {
                this.canAddCraft = value.filter(v => v == null).length === 0;
            });

        // When the location in the service changes take a copy
        const location$ = this.missionEditService.missionLocation$.pipe(
            takeUntil(this.ngUnsubscribe$),
            tap(location => {
                this.location = location;
                this.findCanCheckAirspace();
                this.updateAirspaceAuthorisationsEnabled();
                this.setCurrentTime();
            })
        );

        const aerodromes$ =
            this.missionForm.controls.aerodromesOfInterest.valueChanges.pipe(
                distinctUntilChanged()
            );

        const notams$ = this.missionForm.controls.notams.valueChanges.pipe(
            distinctUntilChanged()
        );

        // Find all the features for the map
        this.locationFeatures$ = combineLatest([
            location$.pipe(
                map(location =>
                    location != null
                        ? getFeatureGroups(location.features, 0, 2).features
                        : []
                )
            ),
            aerodromes$.pipe(
                map(aerodromes => aerodromesToFeatureGroups(aerodromes))
            )
        ]).pipe(
            map(featureGroups =>
                featureGroups.reduce((acc, curr) => acc.concat(curr), [])
            )
        );

        notams$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(notams =>
                this.mapData$.next({ NOTAM: notamToFeatureCollection(notams) })
            );

        combineLatest([aerodromes$, notams$, location$])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([aerodromes, notams, _]) => {
                this.updateGeographicInformation(aerodromes, notams);
            });

        combineLatest([
            this.missionForm.statusChanges.pipe(distinctUntilChanged()),
            this.missionEditService.savedMission$.pipe(
                map(m => m.id),
                distinctUntilChanged()
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([valid, missionId]) => {
                this.isSubmittable = valid === 'VALID' && missionId != null;
                this.changeDetector.markForCheck();
            });

        this.missionForm.valueChanges
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                map(v => v.missionWorkflowVersionId),
                filter(v => v != null),
                distinctUntilChanged()
            )
            .subscribe(missionWorkflowVersion =>
                this.onMissionWorkflowChanged()
            );

        this.missionEditService.selectableRpas$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpas => {
                this.rpas = rpas;
            });

        this.missionEditService.lockedFields$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(lockedFields => (this.lockedFields = lockedFields));

        combineLatest([
            this.missionForm.controls.timeZone.valueChanges,
            this.missionForm.controls.missionDate.valueChanges
        ])
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(([timeZone, missionDate]) => {
                if (timeZone) {
                    const t = moment.tz(
                        moment(missionDate).format('YYYY-MM-DD[T]HH:mm:ss'),
                        timeZone
                    );
                    this.utc = `${t.tz('UTC').format('YYYY-MM-DD HH:mm')}Z`;
                    this.findTimeZones();
                    this.setCurrentTime();
                }
            });

        // Keep the multiple components in sync
        this.missionDate.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(date => {
                this.missionDate.setValue(date, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });

                this.missionStartTime = toTimestamp(
                    this.missionForm.controls.missionDate.value,
                    this.missionForm.controls.timeZone.value
                );
                const duration =
                    this.missionForm.controls.missionEstimatedTime.value;
                this.missionEndTime = moment(this.missionStartTime)
                    .add(duration ?? 3600, 'seconds')
                    .toISOString();
                this.calculateTimeOfDay();
            });

        const doneWorking = this.workTracker.createTracker();
        this.refreshData()
            .then(() => {
                this.setupMission(this.mission);
                this.setupFormArrays();
            })
            .then(() => {
                if (this.isUsingAerodromes) {
                    this.angulartics2.eventTrack.next({
                        action: 'aerodromes',
                        properties: {
                            category: 'features',
                            label: SCREEN_IDENTIFIER
                        }
                    });
                }

                doneWorking();
                // This is required to overcome a ExpressionChangedAfterItHasBeenCheckedError caused by updating the form
                this.changeDetector.detectChanges();
            });
        combineLatest([
            this.airspaceCheckService.sunriseSunset$,
            this.timeZone.valueChanges
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([sunriseSunset, timeZone]) =>
                this.setSunriseSunset(sunriseSunset)
            );

        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(checkResult => {
                if (checkResult == null) {
                    return;
                }
                if (
                    checkResult.availableAutomatedAuthorisationList != null &&
                    checkResult.availableAutomatedAuthorisationList.length >
                        0 &&
                    checkResult.ruleOutcomes.find(
                        o =>
                            o.outcome != null &&
                            o.outcome === 'AUTHORISATION_REQUIRED'
                    ) != null
                ) {
                    this.isAuthorisationOptional = false;
                } else {
                    this.isAuthorisationOptional = true;
                }
                this.showInlineAuthorisation =
                    checkResult.availableAutomatedAuthorisationList.includes(
                        AirspaceCheckDto.AvailableAutomatedAuthorisationList
                            .USA_LAANC
                    ) && checkResult.jurisdiction === 'USA';
                this.refreshControlState();
                this.changeDetector.markForCheck();
            });

        observeFormControl(this.missionObjectivesGroup.controls.workgroup)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((workgroup: WorkGroup) => {
                if (workgroup != null) {
                    if (
                        this.savedMission?.maximumHeight == null &&
                        workgroup.defaultMaximumHeight != null
                    ) {
                        this.missionForm.controls.maximumHeight.patchValue(
                            workgroup.defaultMaximumHeight,
                            { emitEvent: false }
                        );
                    }
                    if (
                        this.savedMission?.missionType == null &&
                        workgroup.defaultOperationType != null
                    ) {
                        this.missionForm.controls.missionTypeId.patchValue(
                            workgroup.defaultOperationType.id,
                            { emitEvent: false }
                        );
                    }
                }
                this.setDefaultWorkflows();
            });

        this.refreshAirspacePermissions();

        this.airspaceAuthorisationService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                // if the mission is null it's a draft mission and can't be fetched without being saved first
                if (this.mission != null && this.mission.id != null) {
                    this.missionService
                        .findMission(this.mission.id)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe({
                            next: mission => this.setupMission(mission),
                            error: (error: FlyFreelyError) => {
                                this.logging.error(
                                    error,
                                    `Error refreshing mission for authorisation check: ${error.message}`
                                );
                                this.changeDetector.detectChanges();
                            }
                        })
                        .add(this.workTracker.createTracker());
                }
            });

        this.airspaceAuthorisationManager.missionAirspaceAuthorisations$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorisation => {
                this.latestAuthorisations = authorisation;
                this.latestAuthorisationStatus =
                    calculateAuthorisationListState(
                        authorisation
                    ) as AirspaceAuthorisationDto.Status;
                this.setupAirspaceAuthorisationResult();
                this.changeDetector.detectChanges();
            });

        this.airspaceAuthorisationManager.checkingAuthorisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(checking => (this.checkingAuthorisation = checking));

        this.currentStep = 'missionName';
        this.confirmedStep = [];
        this.workgroupDataService.findWorkgroups(this.organisation.id);
    }

    ngOnDestroy() {
        this.newClock$.next();
        this.newClock$.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.mapData$.complete();
    }

    setupFormArrays() {
        if (this.mission.aerodromesOfInterest?.length) {
            this.mission.aerodromesOfInterest.forEach(airport => {
                const name = `${airport.name} ${airport.identifier}`;
                this.missionForm.controls.aerodromesOfInterest.push(
                    new FormControl(airport)
                );
            });
        }
        if (this.mission.missionContacts?.length) {
            this.mission.missionContacts.forEach(contact => {
                this.missionForm.controls.missionEmergencyContacts.push(
                    new FormControl(contact)
                );
            });
        }

        if (this.mission.missionRadioFrequencies?.length) {
            this.mission.missionRadioFrequencies.forEach(frequency => {
                const parsedName = frequency.identifier
                    ? `${frequency.name} ${frequency.identifier}`
                    : frequency.name;
                this.missionForm.controls.missionRadioFrequencies.push(
                    new FormControl(frequency)
                );
            });
        }

        if (this.mission.notams?.length > 0) {
            this.mission.notams.forEach(notam =>
                this.missionForm.controls.notams.push(new FormControl(notam))
            );
        }
    }

    private refreshPermissions() {
        this.canSubmitForApproval = !this.organisation.personalOrganisation;
        this.canEditMissionCrew = !this.organisation.personalOrganisation;

        this.canUseAdditionalAuthorities = hasFeatureFlag(
            this.organisation,
            FEATURE_ADDITIONAL_AUTHORITIES
        );
        this.canUseObjectives = hasFeatureFlag(
            this.organisation,
            FEATURE_MISSION_OBJECTIVES
        );

        this.canUseAerodromes = hasFeatureFlag(
            this.organisation,
            FEATURE_MISSION_AERODROMES
        );

        this.canUseEquipment = hasFeatureFlag(
            this.organisation,
            FEATURE_EQUIPMENT
        );

        this.hasRpaResourceAssociation = hasFeatureFlag(
            this.organisation,
            FEATURE_RPA_RESOURCE_ASSOCIATION
        );

        this.canUseNotams = hasFeatureFlag(this.organisation, FEATURE_NOTAMS);

        this.canUseWorkgroup = hasFeatureFlag(
            this.organisation,
            FEATURE_WORKGROUPS
        );
    }

    refreshAirspacePermissions() {
        // Check if the current user can use airspace authorisations, eg. LAANC requiring the current user to be an RPIC on the mission
        combineLatest([
            this.airspaceCheckService.completeCheck$,
            this.missionForm.controls.rpicId.valueChanges.pipe(
                startWith(this.missionForm.controls.rpicId.value)
            ),
            this.airspaceAuthorisationManager.missionAirspaceAuthorisations$.pipe(
                startWith(null)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([completedCheck, rpicId, authorisations]) => {
                // Check if the current airspace jurisdiction's authorisations require that the current user be the RPIC
                if (
                    completedCheck != null &&
                    completedCheck.result != null &&
                    !completedCheck.result.availableAutomatedAuthorisationList?.includes(
                        airspaceAuthorisationTypesRequiringCurrentUserAsRpic[
                            completedCheck.result.jurisdiction
                        ]
                    )
                ) {
                    this.userCanViewAirspaceAuthorisation = true;
                    this.userCanRequestAirspaceAuthorisation = true;
                    return;
                }

                // Check if the current user is an RPIC on the current mission and there are no existing authorisations
                // This implies the current user can perform a new authorisation request.
                const currentUser = this.userService.getCurrentUser();
                this.userCanRequestAirspaceAuthorisation =
                    rpicId === currentUser.id;

                // This assumes that any authorisations returned are for this person, but this logic can be changed here at a later date
                this.userCanViewAirspaceAuthorisation =
                    authorisations != null && authorisations.length > 0;
            });
    }

    /**
     * Set the flags for the airspace checker, and start it if required.
     * This needs to happen after the MissionEditService is setup.
     */
    private findCanCheckAirspace() {
        // Turn on the full check if requried
        if (
            this.location?.airspaceJurisdiction?.airspaceCheckSupport ===
            AirspaceJurisdictionDto.AirspaceCheckSupport.AIRSPACE_CHECK
        ) {
            this.canCheckAirspace = true;
            this.showAirspaceDisclaimerDialogue();
        } else {
            this.canCheckAirspace = false;
        }
    }

    private updateAirspaceAuthorisationsEnabled() {
        // This is updated here on each check in case of location changes
        const authorisationSupport =
            this.location?.airspaceJurisdiction?.airspaceCheckSupport;
        this.airspaceAuthorisationsEnabled =
            authorisationSupport != null &&
            authorisationSupport !==
                AirspaceJurisdictionDto.AirspaceCheckSupport.NONE;
    }

    findTimeZones() {
        const date = this.missionForm.controls.missionDate.value;
        if (date != null) {
            this.timeZoneOffsets = this.timeZones.reduce((acc, tz) => {
                const offset = moment
                    .tz(moment(date).format('YYYY-MM-DD[T]HH:mm:ss'), tz)
                    .format('Z');
                return {
                    ...acc,
                    [tz]: offset
                };
            }, {});
        }
    }

    setCurrentTime() {
        this.newClock$.next();
        this.currentLocalTime = '';
        const timeZone = this.lockedFields?.timeZone
            ? this.location?.timeZone
            : this.timeZone.value;
        if (timeZone != null) {
            timer(0, 1000)
                .pipe(
                    map(() => toLocalTime(new Date(), timeZone)),
                    takeUntil(this.newClock$),
                    takeUntil(this.ngUnsubscribe$)
                )
                .subscribe(time => (this.currentLocalTime = time));
        }
    }

    /**
     * Refresh all dependent data and return a promise for this.
     */
    private refreshData() {
        // Live stream that updates with the mission status
        combineLatest([
            this.missionEditService.personnel$,
            observeFormControl<WorkGroup>(
                this.missionObjectivesGroup.controls.workgroup
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([personnel, selectWorkgroup]) => {
                    this.pilots = personnel.filter(p =>
                        p.roles.find(r => r === 'PILOT')
                    );
                    if (this.canUseWorkgroup && selectWorkgroup) {
                        this.isWorkgroupSelect = true;
                        let selectWorkgroupPersonnelIds =
                            selectWorkgroup.personnelList.map(
                                personnel => personnel.id
                            );

                        const formatStatus =
                            new FormatMissionEditRegisterStatus();

                        const personnelsInWorkgroup = this.pilots
                            .filter(pilot =>
                                selectWorkgroupPersonnelIds.includes(pilot.id)
                            )
                            .map(pilot => ({
                                ...pilot,
                                registerWithWorkgroupStatus: `In Workgroup - ${formatStatus.transform(
                                    pilot.registerStatus
                                )}`
                            }));

                        const personnelsNotInWorkgroup = this.pilots
                            .filter(
                                pilot =>
                                    !selectWorkgroupPersonnelIds.includes(
                                        pilot.id
                                    )
                            )
                            .map(pilot => ({
                                ...pilot,
                                registerWithWorkgroupStatus: `Not In Workgroup - ${formatStatus.transform(
                                    pilot.registerStatus
                                )}`
                            }));

                        this.pilots = [
                            ...personnelsInWorkgroup.sort((a, b) =>
                                sortMissionRegisterStatus(a, b)
                            ),
                            ...personnelsNotInWorkgroup.sort((a, b) =>
                                sortMissionRegisterStatus(a, b)
                            )
                        ];
                    } else {
                        this.isWorkgroupSelect = false;
                        this.pilots.sort((a, b) =>
                            sortMissionRegisterStatus(a, b)
                        );
                    }
                    this.personnel = personnel.sort((a, b) =>
                        sortMissionRegisterStatus(a, b)
                    );
                    this.crewLookup = personnel.reduce(
                        (acc, p) => ({
                            ...acc,
                            [p.id]: `${p.firstName} ${p.lastName}`
                        }),
                        {}
                    );
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while setting up personnel: ${error.message}`
                    );
                }
            );

        const today = moment().format('YYYY-MM-DD');
        combineLatest([
            this.organisationAuthorityService.findAuthorities(
                this.organisation.id,
                this.mission?.organisationId ?? this.organisation.id
            ),
            this.organisationAuthorityService.findSharedAuthorities(
                this.organisation.id,
                today
            )
        ])
            .pipe(
                take(1),
                map(([authorities, sharedAuthorityGroups]) => {
                    const additionalAuthorities = authorities
                        .filter(group => !group.isPrimary && group.hasWorkflow)
                        .map(group =>
                            group.authorities.map(auth => ({
                                id: auth.id,
                                name: this.formatAuthorityPipe.transform(
                                    toSimpleAuthority(
                                        auth,
                                        this.organisation,
                                        group
                                    )
                                ),
                                requiresApproval: toSimpleAuthority(
                                    auth,
                                    this.organisation,
                                    group
                                ).requiresApproval
                            }))
                        )
                        .reduce((acc, auths) => [...acc, ...auths], []);

                    const sharedAuthorities = sharedAuthorityGroups
                        .filter(group => !group.isPrimary && group.hasWorkflow)
                        .map(group =>
                            group.authorities.map(auth => ({
                                id: auth.id,
                                name: this.formatAuthorityPipe.transform(
                                    toSimpleAuthority(
                                        auth,
                                        auth.organisation,
                                        group
                                    )
                                ),
                                requiresApproval: toSimpleAuthority(
                                    auth,
                                    auth.organisation,
                                    group
                                ).requiresApproval
                            }))
                        )
                        .reduce((acc, auths) => [...acc, ...auths], []);

                    this.availableAdditionalAuthorities = [
                        ...additionalAuthorities,
                        ...sharedAuthorities
                    ];
                    this.allAdditionalAuthorities =
                        this.availableAdditionalAuthorities;

                    this.missionAuthorityGroups = authorities.concat(
                        sharedAuthorityGroups
                    );

                    return this.availableAdditionalAuthorities;
                }),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(
                additionalAuthorities =>
                    (this.availableAuthorityLookup =
                        additionalAuthorities.reduce(toLookup, {}))
            )
            .add(this.workTracker.createTracker());

        return Promise.all([
            this.missionEditService.locations$.pipe(take(1)).toPromise(),
            this.missionEditService.rpas$.pipe(take(1)).toPromise(),

            this.preferencesService
                .findPreferencesAsOption(MISSION_FIELD_APP_INFO, null)
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    tap(preferences => {
                        this.showHandoffPopup = getOrElse(() => ({
                            show: true
                        }))(preferences).show;
                    })
                )
                .toPromise()
                .catch((error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while setting up mission documentation: ${error.message}`
                    );
                }),

            this.missionEditService.missionRoles$
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    take(1),
                    tap(roles => {
                        this.crewRoles = roles.filter(
                            r =>
                                r.coreRole !==
                                MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                        );
                        this.rpicRole = roles.filter(
                            r =>
                                r.coreRole ===
                                MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                        )[0];
                    })
                )
                .toPromise(),

            this.missionObjectivesService
                .find(this.organisation.id)
                .toPromise()
                .then(
                    missionObjectives =>
                        (this.missionObjectives = missionObjectives),
                    (error: FlyFreelyError) => {
                        this.logging.error(error);
                    }
                ),

            this.rpaTypesService
                .findRpaTypes(this.organisation.id)
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    tap(types => {
                        this.rpaTypes = types;
                    })
                )
                .toPromise()
        ]);
    }

    addCrew() {
        if (this.canAddRow()) {
            this.missionEditService.addCrew();
            this.changeDetector.markForCheck();
        }
    }

    deleteCrew(i: number) {
        this.missionEditService.removeCrew(i);
        this.changeDetector.detectChanges();
    }

    addRpa() {
        this.missionEditService.addRpa(null);
        this.changeDetector.markForCheck();
    }

    deleteRpa(i: number) {
        this.missionEditService.removeRpa(i);
        this.changeDetector.detectChanges();
    }

    addEquipment() {
        this.missionEditService.addEquipment(null);
        this.changeDetector.markForCheck();
    }

    deleteEquipment(i: number) {
        this.missionEditService.removeEquipment(i);
        this.changeDetector.detectChanges();
    }

    canAddRow() {
        return this.missionCrew.value.reduce(
            (acc: boolean, crew: MissionCrewDto) =>
                acc && crew.personId != null && crew.missionRoleId != null,
            true
        );
    }

    private setupLookups() {
        this.missionService
            .findMissionTypes(this.organisation.id)
            .toPromise()
            .then(
                missionTypes => {
                    this.missionTypes = missionTypes;
                },
                error => {
                    this.logging.error(error);
                }
            );
    }

    private setupMission(mission: DisplayableMissionDto) {
        this.missionEditService.savedMission = mission;
        this.savedMission = mission;
        this.combinedStatus = displayableMissionDtoCombinedStatus(mission);

        this.setupAttachmentsHandler();

        this.savedMission.timeZone = mission.timeZone || defaultTimezone;

        this.savedMission.uid = this.createUID(mission);

        this.getTime(mission.missionEstimatedTime);

        this.setupLookups();

        if (!mission.craftIds) {
            this.savedMission.craftIds = [];
        }

        this.isUsingAerodromes =
            this.canUseAerodromes && mission.modelVersion > 1;

        this.refreshMissionAirspaceAuthorisation();

        return firstValueFrom(this.updateMissionApprovals())
            .then(() => this.refreshDocumentation())
            .then(() => this.prefillPreviousDocumentation())
            .then(() => this.initFormValues())
            .then(() => this.refreshControlState());
    }

    refreshMissionAirspaceAuthorisation() {
        this.refreshAirspaceAuthorisation();
    }

    setupAirspaceAuthorisationResult() {
        this.updateAuthorisationRequirementsComplete();
        this.changeDetector.detectChanges();
    }

    private updateAuthorisationRequirementsComplete() {
        this.areAuthorisationRequirementsComplete =
            this.isResourcesCompleted &&
            this.savedMission?.id != null &&
            this.missionWorkflowVersionId.value != null &&
            this.savedMission?.missionWorkflowVersionId ===
                this.missionWorkflowVersionId.value &&
            !this.locked;
    }

    private setupAttachmentsHandler() {
        if (this.savedMission.id != null && this.attachmentsHandler == null) {
            this.attachmentsHandler = this.missionService.attachmentHandler(
                this.savedMission.id
            );
            this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);
        }
    }

    /**
     * Refresh the submit options, and other workflow related buttons. Should only occur once the workflows have been refreshed.
     */
    private refreshControlState() {
        if (
            this.combinedStatus === CombinedMissionStatus.DRAFT ||
            // If the submitted mission has any draft approvals, they need to be submitted too
            (this.combinedStatus === CombinedMissionStatus.SUBMITTED &&
                this.savedMission.approvals.filter(
                    a => a.status === RequestersMissionApprovalDto.Status.DRAFT
                ).length > 0)
        ) {
            this.submitOption =
                this.approvalCreationRequired ||
                (this.savedMission.approvals != null &&
                    this.savedMission.approvals.filter(
                        a =>
                            a.status ===
                            RequestersMissionApprovalDto.Status.DRAFT
                    ).length > 0) ||
                (this.savedMission.additionalAuthorities != null &&
                    this.savedMission.additionalAuthorities.filter(
                        a => a.requiresApproval
                    ).length > 0)
                    ? SubmitOption.SUBMIT
                    : SubmitOption.FLY;
        } else {
            this.submitOption = SubmitOption.NONE;
        }
        this.missionForm.updateValueAndValidity();
        this.changeDetector.detectChanges();
    }

    initFormValues() {
        const craftIds = this.savedMission.craftIds;
        const missionCrew = this.organisation.personalOrganisation
            ? []
            : this.savedMission.missionCrewDetails
                  .filter(c => c.role == null || c.role.id !== this.rpicRole.id)
                  .map(c => ({
                      personId: c.person != null ? c.person.id : null,
                      missionRoleId: c.role != null ? c.role.id : null
                  }));

        const rpicId = this.organisation.personalOrganisation
            ? this.organisation.ownerId
            : this.savedMission.missionCrewDetails
                  .filter(
                      c =>
                          c.role != null &&
                          c.person != null &&
                          c.role.id === this.rpicRole.id
                  )
                  .map(c => c.person.id)[0];
        this.selectRpic = rpicId == null;

        const equipmentIds = this.savedMission.equipment.map(e => e.id);

        const additionalAuthorities =
            this.savedMission.additionalAuthorities.map(a => a.id) ?? [];

        craftIds.forEach(id => {
            const craftIdArray = this.missionForm.controls.craftIds;
            if (craftIdArray.value.findIndex(c => c === id) === -1) {
                craftIdArray.push(new FormControl(id, Validators.required));
                craftIdArray.updateValueAndValidity();
            }
        });

        equipmentIds.forEach(id => {
            const equipmentIdArray = this.missionForm.controls.equipmentIds;
            if (equipmentIdArray.value.findIndex(e => e === id) === -1) {
                equipmentIdArray.push(new FormControl(id));
                equipmentIdArray.updateValueAndValidity();
            }
        });

        missionCrew.forEach(crew => {
            const missionCrewArray = this.missionForm.controls.missionCrew;
            if (
                missionCrewArray.value.findIndex(
                    c =>
                        c.personId === crew.personId &&
                        c.missionRoleId === crew.missionRoleId
                ) === -1
            ) {
                missionCrewArray.push(
                    new FormControl(crew, Validators.required)
                );
                missionCrewArray.setValidators([
                    Validators.required,
                    validateCrew(this.crewRoles)
                ]);
                missionCrewArray.updateValueAndValidity();
            }
        });

        additionalAuthorities.forEach(authority => {
            const additionalAuthorityArray =
                this.missionForm.controls.additionalAuthorityIds;
            if (
                additionalAuthorityArray.value.findIndex(
                    a => a === authority
                ) === -1
            ) {
                additionalAuthorityArray.push(new FormControl(authority));
                additionalAuthorityArray.updateValueAndValidity();
            }
        });

        const organisationSettings = (<PersonsOrganisationDto>this.organisation)
            .settings;
        const defaultOrganisationMaximumHeight =
            organisationSettings?.defaultMaximumHeight;
        const defaultOrganisationOperationTypeId =
            organisationSettings?.defaultOperationTypeId;
        const defaultWorkgroupMaximumHeight =
            this.missionObjectivesGroup.value?.workgroup?.defaultMaximumHeight;
        const defaultWorkgropOperationTypeId =
            this.missionObjectivesGroup.value?.workgroup?.defaultOperationType
                ?.id;

        this.changeDetector.markForCheck();

        // We don't emit events because if we do it will fire events as it sets values, not after all values are set
        this.missionForm.patchValue(
            {
                missionObjectivesGroup: {
                    name: this.savedMission.name || null,
                    isDummy: this.savedMission.isDummy || false,
                    missionObjective:
                        this.savedMission.missionObjective || null,
                    missionObjectiveTypeId:
                        this.savedMission.missionObjectiveType != null
                            ? this.savedMission.missionObjectiveType.id
                            : null,
                    location: this.savedMission.location || null
                },
                missionTypeId:
                    this.savedMission.missionType != null
                        ? this.savedMission.missionType.id
                        : defaultWorkgropOperationTypeId != null
                        ? defaultWorkgropOperationTypeId
                        : defaultOrganisationOperationTypeId,
                missionWorkflowVersionId:
                    this.savedMission.missionWorkflowVersion != null
                        ? this.savedMission.missionWorkflowVersion.id
                        : null,
                rpicId: rpicId,
                crewNotes: this.savedMission.crewNotes || null,
                missionDate:
                    this.savedMission.timeZone != null
                        ? this.savedMission.missionDate
                            ? fromTimestamp(
                                  this.savedMission.missionDate,
                                  this.savedMission.timeZone
                              )
                            : moment().hours(8).minute(0).seconds(0).toDate()
                        : null,
                timeZone: this.savedMission.timeZone,
                missionEstimatedTime: this.savedMission.missionEstimatedTime,
                maximumHeight:
                    this.savedMission.maximumHeight != null
                        ? this.savedMission.maximumHeight
                        : defaultWorkgroupMaximumHeight != null
                        ? defaultWorkgroupMaximumHeight
                        : defaultOrganisationMaximumHeight,
                emergencyContacts: this.savedMission.emergencyContacts || null,
                radioFrequencies: this.savedMission.radioFrequencies || null,
                visualLineOfSight: this.savedMission.visualLineOfSight || null,
                timeOfDay: this.savedMission.timeOfDay || null,
                declareNoRelevantNotam:
                    this.savedMission.noNotamDeclarationPerson != null &&
                    this.savedMission.noNotamDeclarationTime != null &&
                    this.missionForm.controls.notams.value?.length === 0
            },
            { emitEvent: false }
        );

        this.workgroup$.pipe(take(1)).subscribe(workgroup => {
            if (this.savedMission.hasOwnProperty('workgroupId')) {
                this.missionForm.controls.missionObjectivesGroup.patchValue(
                    {
                        workgroup: workgroup.find(
                            item => item.id === this.savedMission.workgroupId
                        )
                    },
                    { emitEvent: false }
                );
            }
        });

        this.updateAdditionalAuthorities();
        updateFormTreeValidity(this.missionForm);
        if (this.sourceMissionId === null) {
            this.missionForm.markAsPristine();
        }
    }

    private setDefaultWorkflows() {
        if (this.savedMission?.missionWorkflowVersionId != null) {
            this.skipLoadingWorkflows = false;
            return;
        }
        const organisationSettings = (<PersonsOrganisationDto>this.organisation)
            .settings;
        const defaultWorkgroupWorkflowId = this.missionWorkflows?.find(
            wf =>
                wf.workflowId ===
                this.missionObjectivesGroup.controls.workgroup.value
                    ?.defaultMissionWorkflow?.id
        )?.id;
        const defaultOrganisationWorkflowId = this.missionWorkflows?.find(
            wf =>
                wf.workflowId === organisationSettings?.defaultMissionWorkflowId
        )?.id;

        if (
            defaultWorkgroupWorkflowId == null &&
            defaultOrganisationWorkflowId == null
        ) {
            this.skipLoadingWorkflows = false;
            return;
        }
        // Setting skipLoadingWorkflows here will just update the form values without initiating the change workflow flow
        // This prevents the mission saving prematurely and requires the user to confirm the workflow to continue
        this.skipLoadingWorkflows = true;
        this.missionForm.patchValue(
            {
                missionWorkflowVersionId:
                    defaultWorkgroupWorkflowId ?? defaultOrganisationWorkflowId
            },
            { onlySelf: true, emitEvent: false }
        );
    }

    private saveAndSetupMission(): Promise<DisplayableMissionDto> {
        const doneWorking = this.workTracker.createTracker();

        // If we have an unsaved location then save it.
        // if (this.location != null && this.location.id == null) {
        //     this.createMissionLocation(this.location);
        // }

        return this.missionEditService
            .saveMission()
            .then(savedMission => {
                const message =
                    this.savedMission.id != null ? 'updated' : 'saved';

                this.logging.success(`The mission has been ${message}`);
                return this.setupMission(savedMission).then(() => savedMission);
            })
            .finally(() => doneWorking());
    }

    /**
     * Prefill the documentation from the mission from which this mission was cloned
     */
    private prefillPreviousDocumentation() {
        if (
            // Only prefill docs for saved missions
            this.savedMission.id == null ||
            this.sourceMissionId == null ||
            !hasFeatureFlag(this.organisation, 'cloneDocumentation')
        ) {
            return Promise.resolve();
        }

        this.hasClonedFormResponses = true;
        this.prefillFromMission(this.sourceMissionId);
    }

    private prefillFromMission(missionId: number) {
        if (this.rpas == null) {
            this.rpas = [];
        }
        const documentationPromise = Promise.all([
            this.missionService.findMission(missionId).toPromise(),
            this.missionService.findDocumentation(missionId).toPromise(),
            this.missionService
                .findDocumentation(this.savedMission.id)
                .toPromise()
        ]).then(
            ([mission, documentation, newDocumentation]) => {
                const oldDocumentation = buildDisplayableMissionDocumentation(
                    documentation,
                    this.activeMissionApprovals.map(
                        approval => approval.approval
                    ),
                    this.rpas,
                    this.formatRpaPipe.transform
                );
                const oldForms = oldDocumentation.formResponses;

                const newForms = buildDisplayableMissionDocumentation(
                    newDocumentation,
                    this.activeMissionApprovals.map(
                        approval => approval.approval
                    ),
                    this.rpas,
                    this.formatRpaPipe.transform
                );

                const { formResponses, prefillErrors } =
                    prefillPreviousDocumentation(
                        newForms.formResponses,
                        oldForms,
                        newForms.requiredDocumentation,
                        'pre-submit'
                    );

                this.prefillErrors = prefillErrors;

                this.missionForm.controls.documentation.setValue({
                    ...this.missionForm.controls.documentation.value,
                    formResponses
                });
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while setting up mission documentation: ${error.message}`
                );
            }
        );
        return documentationPromise;
    }

    stepCompleted(step: string) {
        if (!this.confirmedStep) {
            return;
        }

        const foundStep = this.confirmedStep.find(st => st === step);
        switch (step) {
            case 'missionName':
                if (foundStep) {
                    if (this.isCreated) {
                        this.currentStep = 'missionObjective';
                    } else {
                        this.currentStep = 'createMission';
                    }
                }
                break;
            case 'missionObjective':
                if (foundStep) {
                    this.currentStep = 'missionLocation';
                }
                break;
            case 'missionLocation':
                this.currentStep = 'operationType';
                break;
            case 'operationType':
                this.currentStep = 'missionWorkflow';
                break;
            case 'missionWorkflow':
                this.currentStep = 'rpa';
                break;
            case 'rpa':
                this.currentStep = 'equipment';
                break;
            case 'equipment':
                this.currentStep = 'crew';
                break;
            case 'crew':
                if (foundStep) {
                    this.currentStep = 'crewNotes';
                }
                break;
            case 'crewNotes':
                if (foundStep) {
                    this.currentStep = 'plannedDate';
                }
                break;
            case 'plannedDate':
                if (foundStep) {
                    this.currentStep = 'plannedTime';
                }
                break;
            case 'plannedTime':
                if (foundStep) {
                    this.currentStep = 'maxHeight';
                }
                break;
            case 'maxHeight':
                if (foundStep) {
                    this.currentStep = 'contacts';
                }
                break;
            case 'contacts':
                if (foundStep) {
                    this.currentStep = 'frequencies';
                }
                break;
            case 'frequencies':
                if (foundStep) {
                    this.currentStep = 'attachments';
                }
                break;
            case 'attachments':
                if (foundStep) {
                    this.currentStep = 'missionFeasibility';
                }
                break;
            case 'missionFeasibility':
                if (foundStep) {
                    this.currentStep = 'jsa';
                }
                break;
            case 'jsa':
                if (foundStep) {
                    this.currentStep = 'missionSummary';
                }
                break;
            case 'missionSummary':
                if (foundStep) {
                    this.currentStep = 'none';
                }
                break;
        }
        this.changeDetector.detectChanges();
    }

    setStep(step: string) {
        if (step === this.currentStep) {
            return;
        }
        this.currentStep = step;
        this.changeDetector.detectChanges();
    }

    refreshDocumentation() {
        if (this.savedMission.id == null) {
            // unsaved missions can't have documentation
            return Promise.resolve();
        }

        const promise = this.missionService
            .findDocumentation(this.savedMission.id)
            .toPromise()
            .then(
                documentation => this.setupDocumentation(documentation),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing mission documentation: ${error.message}`
                    );
                }
            );
        return Promise.resolve(promise);
    }

    private setupDocumentation(documentation: MissionDocumentationDto) {
        if (this.rpas == null) {
            this.rpas = [];
        }
        this.allMissionDocumentation = documentation;
        const newDocumentation = buildDisplayableMissionDocumentation(
            documentation,
            this.activeMissionApprovals.map(approval => approval.approval),
            this.rpas,
            this.formatRpaPipe.transform
        );

        const oldDocumentation = this.missionForm.get('documentation').value;

        this.missionForm.patchValue({
            documentation: {
                ...newDocumentation,
                ...prefillPreviousDocumentation(
                    newDocumentation.formResponses,
                    oldDocumentation != null
                        ? oldDocumentation.formResponses
                        : {},
                    newDocumentation.requiredDocumentation,
                    'pre-submit'
                )
            }
        });
    }

    addAerodrome(aerodrome: AerodromeDetailsDto) {
        const existingAerodromes =
            this.missionForm.controls.aerodromesOfInterest.value;
        if (
            existingAerodromes.findIndex(
                a => a.identifier === aerodrome.code
            ) === -1
        ) {
            this.missionForm.controls.aerodromesOfInterest.push(
                new FormControl({
                    type: ContactDto.Type.AERODROME,
                    name: aerodrome.name,
                    value: aerodrome.operator,
                    identifier: aerodrome.code,
                    location: aerodrome.location
                })
            );
        }

        this.angulartics2.eventTrack.next({
            action: 'aerodrome-add',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${aerodrome.code}`
            }
        });
    }

    addAerodromeFrequency(frequency: AerodromeFrequency) {
        const existingFrequencies =
            this.missionForm.controls.missionRadioFrequencies.value;
        if (
            existingFrequencies.findIndex(
                matchesAerodromeFrequency(frequency)
            ) === -1
        ) {
            this.missionForm.controls.missionRadioFrequencies.push(
                new FormControl({
                    name: frequency.name,
                    subtype: frequency.abbreviation || '',
                    value: frequency.frequency || '',
                    type: ContactDto.Type.AERODROME,
                    identifier: frequency.aerodromeCode || null
                })
            );
        }

        this.angulartics2.eventTrack.next({
            action: 'aerodrome-frequency-add',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${frequency.aerodromeCode}-${frequency.abbreviation}`
            }
        });
    }

    addAerodromeContact(contact: AerodromeContact) {
        const existingContacts =
            this.missionForm.controls.missionEmergencyContacts.value;
        if (
            existingContacts.findIndex(matchesAerodromeContact(contact)) === -1
        ) {
            this.missionForm.controls.missionEmergencyContacts.push(
                new FormControl({
                    name: contact.aerodromeName,
                    subtype: contact.type,
                    value: contact.contact,
                    identifier: contact.aerodromeCode,
                    type: ContactDto.Type.AERODROME
                })
            );
        }

        this.angulartics2.eventTrack.next({
            action: 'aerodrome-contact-add',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${contact.aerodromeCode}-${contact.type}`
            }
        });
    }

    addAerodromeNotam(notam: NotamDto) {
        this.missionForm.controls.notams.push(new FormControl(notam));
        this.missionForm.patchValue({
            // For an unsaved mission, this will set declareNoRelevantNotam to false
            declareNoRelevantNotam:
                this.savedMission.noNotamDeclarationPerson != null &&
                this.missionForm.controls.notams.value?.length === 0
        });

        this.angulartics2.eventTrack.next({
            action: 'notam-add',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${notam.location}`
            }
        });
    }

    findNearbyAirports() {
        // Find airports based on mission location and populate contacts and frequencies
    }

    addContact(item: ContactDto) {
        this.missionForm.controls.missionEmergencyContacts.push(
            new FormControl({
                ...item,
                type: ContactDto.Type.CUSTOM
            })
        );
        this.isAddingContacts = false;
    }

    addFrequency(item: ContactDto) {
        this.missionForm.controls.missionRadioFrequencies.push(
            new FormControl({
                ...item,
                type: ContactDto.Type.CUSTOM
            })
        );
        this.isAddingFrequencies = false;
    }

    removeAirport(index: number) {
        this.missionForm.controls.aerodromesOfInterest.removeAt(index);

        this.angulartics2.eventTrack.next({
            action: 'aerodrome-remove',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    removeSearchAirport(aerodrome: AerodromeDetailsDto) {
        const aerodromes = this.missionForm.controls.aerodromesOfInterest.value;
        const index = aerodromes.findIndex(
            a => a.identifier === aerodrome.code
        );
        if (index !== -1) {
            this.missionForm.controls.aerodromesOfInterest.removeAt(index);
        }

        this.angulartics2.eventTrack.next({
            action: 'aerodrome-remove',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${aerodrome.code}`
            }
        });
    }

    removeContact(index: number) {
        this.missionForm.controls.missionEmergencyContacts.removeAt(index);

        this.angulartics2.eventTrack.next({
            action: 'contact-remove',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    removeSearchContact(contact: AerodromeContact) {
        const contacts =
            this.missionForm.controls.missionEmergencyContacts.value;
        const index = contacts.findIndex(matchesAerodromeContact(contact));
        if (index !== -1) {
            this.missionForm.controls.missionEmergencyContacts.removeAt(index);
        }

        this.angulartics2.eventTrack.next({
            action: 'aerodrome-contact-remove',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${contact.aerodromeCode}-${contact.type}`
            }
        });
    }

    removeFrequency(index: number) {
        this.missionForm.controls.missionRadioFrequencies.removeAt(index);

        this.angulartics2.eventTrack.next({
            action: 'frequency-remove',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    removeSearchFrequency(frequency: AerodromeFrequency) {
        const frequencies =
            this.missionForm.controls.missionRadioFrequencies.value;
        const index = frequencies.findIndex(
            matchesAerodromeFrequency(frequency)
        );
        if (index !== -1) {
            this.missionForm.controls.missionRadioFrequencies.removeAt(index);
        }

        this.angulartics2.eventTrack.next({
            action: 'aerodrome-frequency-remove',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${frequency.aerodromeCode}-${frequency.abbreviation}`
            }
        });
    }

    removeSearchNotam(notam: NotamDto) {
        const notams = this.missionForm.controls.notams.value;
        const index = notams.findIndex(n => n.id === notam.id);
        if (index !== -1) {
            this.missionForm.controls.notams.removeAt(index);
        }
        this.missionForm.patchValue({
            // For an unsaved mission, this will set declareNoRelevantNotam to false
            declareNoRelevantNotam:
                this.savedMission.noNotamDeclarationPerson != null &&
                this.missionForm.controls.notams.value?.length === 0
        });

        this.angulartics2.eventTrack.next({
            action: 'notam-remove',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: `${notam.location}`
            }
        });
    }

    createUID(mission: DisplayableMissionDto) {
        if (mission.uid != null) {
            return mission.uid;
        }
        // date-organisation_id-sequential_number
        const currentDate = new Date();
        const datestring = moment(currentDate).format('YYYYMMDD-HHmmss');
        const organisationID = this.organisation.id;
        const UID = `${organisationID}-${datestring}`;
        return UID;
    }

    getTime(time: number) {
        const hours = Math.floor(time / 3600);
        time = time - hours * 3600;
        const minutes = Math.floor(time / 60);
        const timeString = `${hours} hours and ${minutes} minutes`;

        return timeString;
    }

    isPlanningCompleted() {
        if (
            this.missionForm.controls.documentation.invalid ||
            this.missionForm.controls.documentation.value == null
        ) {
            return false;
        }

        const value = this.missionForm.controls.documentation.value;

        // TODO: need to verify when the forms are done, the problem is that we don't have formResponses at this stage.
        const presubmit = value.requiredDocumentation['pre-submit'];

        const presubmitCompleted = presubmit.reduce(
            (acc, requirements) =>
                acc &&
                areRequirementsCompleted(
                    requirements,
                    value.formResponses,
                    'pre-submit'
                ),
            true
        );

        return this.missionDate.value && presubmitCompleted;
    }

    saveDocumentation(event: any) {
        console.log(event);
    }

    // reloadMissionPage() {
    //     this.missionDialoguesService.showMissionDetailsThroughState(
    //         this.mission?.id
    //     );
    // }

    save() {
        return this.saveAndSetupMission().catch(error => {
            this.logging.error(error, `Error while saving: ${error.message}`);
            return Promise.reject(error);
        });
    }

    saveAndClose() {
        this.save().then(results => this.modal.hide());
    }

    close() {
        this.modal.hide();
    }

    submit() {
        if (!this.isSubmittable) {
            this.logging.warn('This mission is missing information');
            return;
        }

        const doneWorking = this.workTracker.createTracker();

        this.saveAndSetupMission()
            .then(savedMission => {
                if (this.submitOption === SubmitOption.FLY) {
                    return this.missionService
                        .prepareToFly(this.savedMission.id)
                        .then(
                            updatedMission => {
                                this.setupMission(updatedMission);
                                if (
                                    updatedMission.status !==
                                    DisplayableMissionDto.Status.READY_TO_FLY
                                ) {
                                    this.logging.warn(
                                        'For some reason the mission did not become ready to fly'
                                    );
                                    return;
                                }
                                this.logging.success('Mission is ready to fly');
                                this.fieldAppHandoff();
                                return;
                            },
                            error => {
                                this.logging.error(error);
                            }
                        );
                }

                const draftApprovals =
                    this.savedMission.approvals?.filter(
                        a =>
                            a.status ===
                            RequestersMissionApprovalDto.Status.DRAFT
                    ) ?? [];
                const approvalsInProgress =
                    this.savedMission.approvals?.filter(
                        a =>
                            a.status ===
                                RequestersMissionApprovalDto.Status
                                    .BEING_REVIEWED ||
                            a.status ===
                                RequestersMissionApprovalDto.Status.PENDING
                    ) ?? [];

                if (draftApprovals.length > 0) {
                    return firstValueFrom(
                        combineLatest(
                            draftApprovals.map(approval =>
                                this.missionApprovalService.submitForApproval(
                                    approval.id,
                                    []
                                )
                            )
                        ).pipe(takeUntil(this.ngUnsubscribe$))
                    ).then(
                        approval => {
                            this.logging.success(
                                'Mission submitted for approval'
                            );
                            this.modal.hide();
                        },
                        error => {
                            this.logging.error(
                                error,
                                'Error submitting mission for approval'
                            );
                        }
                    );
                } else if (
                    draftApprovals.length === 0 &&
                    approvalsInProgress.length > 0
                ) {
                    // Can not submit in this state
                    this.logging.success(
                        'Mission approval is already in progress'
                    );
                    return;
                }
            })
            .finally(() => {
                this.setupGuideChecklistService.completeActivityTask(
                    SetupGuideActivityDto.StepIdentifier.SUBMIT_MISSION,
                    this.mission.id
                );
                doneWorking();
            });
    }

    onMissionWorkflowChanged() {
        this.updateMissionWorkflowVersionReference();
        if (this.skipLoadingWorkflows) {
            this.skipLoadingWorkflows = false;
            return;
        }
        if (this.savedMission == null) {
            return;
        }

        if (this.savedMission.missionWorkflowVersionId == null) {
            this.initiateWorkflow();
        } else {
            // do-nothing
        }
    }

    private updateMissionWorkflowVersionReference() {
        if (this.missionWorkflows == null) {
            return;
        }
        this.missionDetails.missionWorkflowVersion = this.missionWorkflows.find(
            wf => wf.id === this.missionWorkflowVersionId.value
        );
        this.updateAdditionalAuthorities();
    }

    private refreshCurrentAuthority() {
        if (
            this.missionDetails.missionWorkflowVersion == null ||
            this.missionDetails.missionWorkflowVersion.delegatedAuthority ==
                null
        ) {
            this.missionEditService.setLoadingWorkflowTracker(false);
            return;
        }

        const authorityId =
            this.missionDetails.missionWorkflowVersion.delegatedAuthority.id;
        const organisationId =
            this.savedMission?.organisationId ?? this.organisation.id;

        this.organisationAuthorityService
            .findAuthority(authorityId, organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: authority => {
                    this.missionWorkflowPrimaryAuthority = authority;
                    if ('requiresApprovalPolicy' in authority) {
                        this.authorityRegistersSetUp =
                            authority.registers.filter(r => r.isSetup === true)
                                .length === authority.registers.length;

                        const pilotRegister = authority.registers.find(
                            r =>
                                r.registerEntityType ===
                                AuthorityTypeRegisterConditionsDto
                                    .RegisterEntityType.PILOT
                        );

                        const rpaRegister = authority.registers.find(
                            r =>
                                r.registerEntityType ===
                                AuthorityTypeRegisterConditionsDto
                                    .RegisterEntityType.RPA
                        );

                        const personnelSetup = pilotRegister?.isSetup === false;
                        const rpaSetup = rpaRegister?.isSetup === false;
                        const message =
                            personnelSetup && rpaSetup
                                ? 'The Remote Pilot Register and RPA Register are not configured correctly.'
                                : personnelSetup
                                ? 'Remote Pilot Register is not configured correctly.'
                                : 'RPA Register is not configured correctly.';
                        this.authorityRegistersActionMessage = message;
                        this.pilotRegisterDisabled =
                            pilotRegister?.registerMode ===
                            AuthorityRegisterSummaryDto.RegisterMode.DISABLED;
                        this.rpaRegisterDisabled =
                            rpaRegister?.registerMode ===
                            AuthorityRegisterSummaryDto.RegisterMode.DISABLED;
                    }
                    this.updateMissionApprovals()
                        .pipe(
                            map(approvals => from(this.refreshDocumentation()))
                        )
                        .subscribe(DO_NOTHING)
                        .add(this.workTracker.createTracker());
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error setting up workflow authority: ${error.message}`
                    );
                },
                complete: () => {
                    this.missionEditService.setLoadingWorkflowTracker(false);
                }
            })
            .add(this.workTracker.createTracker());
    }

    /**
     * A function that is called when the workflow on the mission is changed or updated.
     * It filters mission additional authorities against those on the workflow to prevent duplicates.
     * It also adjusts the available additional authorities list based on which are already on the workflow.
     */
    updateAdditionalAuthorities() {
        // Find additional authorities from the workflow
        const additionalWorkflowAuthorities =
            this.missionDetails?.missionWorkflowVersion
                ?.additionalAuthorityIds ?? [];
        // Remove mission additional authorities that are on the workflow
        this.missionForm.value.additionalAuthorityIds.forEach((id, index) => {
            if (additionalWorkflowAuthorities.includes(id)) {
                this.missionForm.controls.additionalAuthorityIds.removeAt(
                    index
                );
            }
        });
        // Update available additional authorities list.
        this.availableAdditionalAuthorities =
            this.allAdditionalAuthorities.filter(
                a =>
                    additionalWorkflowAuthorities.find(id => id === a.id) ==
                        null &&
                    this.missionForm.value.additionalAuthorityIds.find(
                        id => id === a.id
                    ) == null
            );
    }

    addAdditionalAuthority() {
        const id = this.newAdditionalAuthorityId;
        this.newAdditionalAuthorityId = null;
        const newAdditionalAuthority = this.allAdditionalAuthorities.find(
            a => a.id === id
        );
        this.availableAdditionalAuthorities =
            this.availableAdditionalAuthorities.filter(a => a.id !== id);
        this.missionForm.controls.additionalAuthorityIds.push(
            new FormControl(id)
        );
        if (newAdditionalAuthority.requiresApproval) {
            const doneWorking = this.workTracker.createTracker();

            this.missionEditService
                .saveMission()
                .then(mission => {
                    return this.createApprovals(mission);
                })
                .finally(() => {
                    doneWorking();
                });
        }
    }

    removeAdditionalAuthority(id: number) {
        const index = this.missionForm.value.additionalAuthorityIds.findIndex(
            a => a === id
        );
        const removedAdditionalAuthority = this.allAdditionalAuthorities.find(
            a => a.id === id
        );
        const authorities = this.availableAdditionalAuthorities.concat(
            this.allAdditionalAuthorities.find(a => a.id === id)
        );
        const additionalWorkflowAuthorities =
            this.missionDetails.missionWorkflowVersion?.additionalAuthorityIds;

        this.availableAdditionalAuthorities = this.allAdditionalAuthorities
            .filter(
                a =>
                    additionalWorkflowAuthorities.find(id => id === a.id) ==
                    null
            )
            .filter(a => authorities.find(a2 => a2.id === a.id) != null);

        this.missionForm.controls.additionalAuthorityIds.removeAt(index);
        if (removedAdditionalAuthority.requiresApproval) {
            const approvalToCancel = this.savedMission.approvals
                ?.filter(
                    a =>
                        a.status !==
                        RequestersMissionApprovalDto.Status.CANCELLED
                )
                .find(a => a.authorityId === removedAdditionalAuthority.id);
            if (approvalToCancel == null) {
                return;
            }
            const doneWorking = this.workTracker.createTracker();

            this.missionEditService
                .saveMission()
                .then(mission =>
                    firstValueFrom(this.cancelApproval(approvalToCancel.id))
                )
                .then(refreshedMission => this.setupMission(refreshedMission))
                .catch((error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error setting up mission: ${error.message}`
                    )
                )
                .then(() => this.prefillPreviousDocumentation())
                .finally(() => {
                    doneWorking();
                });
        }
    }

    startAdditionalAuthorityApprovals() {
        if (this.savedMission == null) {
            this.save().then(mission => this.createApprovals(mission));
        } else {
            this.createApprovals(this.savedMission);
        }
    }

    createApprovals(mission: DisplayableMissionDto) {
        return firstValueFrom(
            this.missionApprovalService.createMissionApproval(mission.id).pipe(
                switchMap(approvals => {
                    return this.missionService
                        .findMission(mission.id)
                        .pipe(takeUntil(this.ngUnsubscribe$));
                }),
                takeUntil(this.ngUnsubscribe$)
            )
        )
            .then(refreshedMission => this.setupMission(refreshedMission))
            .catch((error: FlyFreelyError) =>
                this.logging.error(
                    error,
                    `Error setting up mission: ${error.message}`
                )
            )
            .then(() => this.prefillPreviousDocumentation());
    }

    cancelApproval(approvalId: number) {
        return this.missionApprovalService.cancelApproval(approvalId).pipe(
            switchMap(approvals => {
                return this.missionService
                    .findMission(this.savedMission.id)
                    .pipe(takeUntil(this.ngUnsubscribe$));
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    updateMissionApprovals() {
        const allApprovals = this.savedMission?.approvals ?? [];
        // Check if any approval authorities need to be fetched from the server.
        const approvalsWithoutAuthorityData = allApprovals
            .reduce(
                (acc, a) =>
                    acc.some(approval => a.authorityId === approval.authorityId)
                        ? acc
                        : acc.concat(a),
                []
            )
            .filter(
                a =>
                    this.missionAuthorities.find(
                        auth => auth.id === a.authorityId
                    ) == null
            );
        if (approvalsWithoutAuthorityData.length === 0) {
            return of(null);
        }
        const managingOrganisationId =
            this.savedMission?.organisationId ?? this.organisation.id;
        return combineLatest(
            approvalsWithoutAuthorityData.map(a =>
                this.organisationAuthorityService.findAuthority(
                    a.authorityId,
                    managingOrganisationId
                )
            )
        ).pipe(
            tap({
                next: authorities => {
                    this.missionAuthorities.concat(authorities);
                    this.missionApprovals = allApprovals.map(approval => {
                        const authority = authorities.find(
                            a => a.id === approval.authorityId
                        );
                        const authorityType = this.missionAuthorityGroups.find(
                            a =>
                                a.id ===
                                authorities.find(
                                    a => a.id === approval.authorityId
                                )?.authorityTypeId
                        );
                        return {
                            approval: approval,
                            authority: authority,
                            authorityType: authorityType
                        };
                    });
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching approval authorities: ${error.message}`
                    )
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    /**
     * Takes an approval and determines if the approval is related to the primary authority on a workflow, any of the workflow's secondary authorities, or any additional authorities. Returns a string identifying the source of the approval that can be displayed in the template as-is.
     * @param approval An approval on the mission
     * @returns A string identifying the source of the approval
     */
    getApprovalSource(approval: RequestersMissionApprovalDto) {
        const workflowPrimaryAuthority =
            this.savedMission.missionWorkflowVersion?.delegatedAuthority;
        const workflowSecondaryAuthorities =
            this.savedMission.missionWorkflowVersion?.additionalAuthorities;
        const additionalAuthorities = this.savedMission.additionalAuthorities;
        return approval.authorityId === workflowPrimaryAuthority?.id
            ? 'Workflow Primary Authority'
            : workflowSecondaryAuthorities.find(
                  a => a.id === approval.authorityId
              ) != null
            ? 'Workflow Secondary Authority'
            : additionalAuthorities.find(a => a.id === approval.authorityId) !=
              null
            ? 'Additional Authority'
            : 'Unknown';
    }

    initiateWorkflow() {
        this.missionEditService.setLoadingWorkflowTracker(true);
        const doneWorking = this.workTracker.createTracker();

        this.missionEditService
            .saveMission()
            .then(mission => {
                if (
                    (mission.missionWorkflowVersion.delegatedAuthority ==
                        null ||
                        !mission.missionWorkflowVersion.delegatedAuthority
                            .requiresApproval) &&
                    (mission.missionWorkflowVersion.additionalAuthorities ==
                        null ||
                        mission.missionWorkflowVersion.additionalAuthorities.filter(
                            a => a.requiresApproval
                        ).length === 0)
                ) {
                    return this.setupMission(mission);
                }
                return this.createApprovals(mission);
            })
            .then(() => {
                this.stepCompleted('missionWorkflow');
                this.changeDetector.detectChanges();
                this.refreshCurrentAuthority();
            })
            .finally(() => {
                doneWorking();
            });
    }

    changeWorkflow() {
        if (
            (this.savedMission.missionWorkflowVersion.delegatedAuthority !=
                null &&
                this.savedMission.missionWorkflowVersion.delegatedAuthority
                    .requiresApproval) ||
            (this.savedMission.missionWorkflowVersion.additionalAuthorities !=
                null &&
                this.savedMission.missionWorkflowVersion.additionalAuthorities.filter(
                    a => a.requiresApproval
                ).length > 0)
        ) {
            const authorityIds = [
                this.savedMission.missionWorkflowVersion.delegatedAuthority.id
            ].concat(
                this.savedMission.missionWorkflowVersion?.additionalAuthorities?.map(
                    a => a.id
                ) ?? []
            );
            const approvalsRelatedToAuthority = findActiveApprovals(
                this.savedMission.approvals,
                true
            ).reduce(
                (acc, a) =>
                    authorityIds.includes(a.authorityId) ? acc.concat(a) : acc,
                []
            );
            if (
                approvalsRelatedToAuthority != null &&
                approvalsRelatedToAuthority.length > 0
            ) {
                forkJoin(
                    approvalsRelatedToAuthority.map(a =>
                        this.cancelApproval(a.id)
                    )
                )
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe({
                        next: () => this.initiateWorkflow(),
                        error: (error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                `Error cancelling approvals: ${error.message}`
                            )
                    });
            }
        } else {
            this.initiateWorkflow();
        }
    }

    undoWorkflowChange() {
        this.missionForm.patchValue({
            missionWorkflowVersionId: this.savedMission.missionWorkflowVersionId
        });
    }

    returnMissionRuleset() {
        return this.missionDetails?.missionWorkflowVersion?.delegatedAuthority
            ?.authorityType?.ruleset;
    }

    showAuthorityDetails(authority: SimpleAuthorityDto) {
        this.angulartics2.eventTrack.next({
            action: 'primary-authority-view',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });

        const modal = this.authorityDialoguesService.showAuthorityDetails(
            this.organisation.id,
            authority.authorityType,
            this.missionWorkflowPrimaryAuthority
        );
        modal.content.onShowRegister
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.showRegister(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority,
                    payload.register
                );
            });
    }

    showRegister(
        authorityType: AuthorityTypeDto,
        authority: CraftAuthorityDto | OrganisationAuthorityDto,
        register: AuthorityRegisterSummaryDto
    ) {
        if (
            register.registerEntityType ===
            AuthorityRegisterSummaryDto.RegisterEntityType.RPA
        ) {
            this.authorityDialoguesService.showRpaRegisterDialogue(
                this.organisation.id,
                authorityType,
                <CraftAuthorityDto>authority,
                register,
                this.organisation.id
            );
        } else {
            this.authorityDialoguesService.showPersonnelRegisterDialogue(
                this.organisation.id,
                authorityType,
                <OrganisationAuthorityDto>authority,
                register,
                this.organisation.id
            );
        }
    }

    showRpaDetails(i: number) {
        const rpaId = this.craftIds.value[i];
        this.rpaDialoguesService.showCraftDetails(
            rpaId,
            this.organisation.id,
            false
        );
    }

    showEquipmentDetails(i: number) {
        const equipmentId = this.equipmentIds.value[i];
        this.equipmentDialoguesService.showEquipmentDetails(
            equipmentId,
            this.organisation,
            false
        );
    }

    showPersonDetails(i: number) {
        const person = this.personnel.find(p => p.id === i);
        this.personDialoguesService.showPersonDetailsDialogue(
            person,
            this.organisation.id,
            false
        );
    }

    updateIsResourcesCompleted() {
        this.isResourcesCompleted =
            this.missionTypeId.valid &&
            this.missionWorkflowVersionId.valid &&
            this.craftIds.valid &&
            this.rpicId.valid;
        this.updateAuthorisationRequirementsComplete();

        this.changeDetector.detectChanges();
    }

    scrollTo(section: string) {
        this.activeTab(section);
        document
            .querySelector(`#${section}`)
            .scrollIntoView({ behavior: 'smooth' });
    }

    onSectionChange(sectionId: string) {
        this.activeTab(sectionId);
    }

    activeTab(section: string) {
        this.isActive = {};
        this.isActive[section] = true;
    }

    showSelectPrefillMission() {
        this.isShowingSelectPrefillMission = true;
        this.cancelStack.push(() => this.hideSelectPrefillMission());

        this.angulartics2.eventTrack.next({
            action: 'documentation-prefill-show',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    hideSelectPrefillMission() {
        this.isShowingSelectPrefillMission = false;
        this.cancelStack.pop();
    }

    showAerodromeSearch(index?: number) {
        if (index != null) {
            const aerodrome: ContactDto =
                this.missionForm.controls.aerodromesOfInterest.value[index];
            this.defaultAerodromeCode = aerodrome.identifier;
        }
        this.isAddingAerodromes = true;
        this.cancelStack.push(() => this.hideAerodromeSearch());
    }

    hideAerodromeSearch() {
        this.defaultAerodromeCode = null;
        this.isAddingAerodromes = false;
        this.cancelStack.pop();
    }

    showNotamSearch() {
        this.isAddingNotams = true;
        this.cancelStack.push(() => this.hideNotamSearch());
    }

    hideNotamSearch() {
        this.isAddingNotams = false;
        this.cancelStack.pop();
    }

    acknowledgeNoRelevantNotam() {
        this.missionForm.patchValue({
            declareNoRelevantNotam: true
        });
        if (this.savedMission?.id == null) {
            return;
        }
        this.missionService
            .acknowledgeNoRelevantNotam(
                this.savedMission.id,
                this.userService.getCurrentUser().id
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.saveAndSetupMission())
            .add(this.workTracker.createTracker());
    }

    showAirspaceAuthorisation(requestNew = false) {
        if (this.working) {
            return;
        }
        const mission = this.missionForm.value as MissionFormValue;

        const command: AirspaceCheckCommand =
            this.airspaceCheckService.setupCheckCommand(
                this.rpas.filter(r => mission.craftIds.includes(r.id)),
                this.missionWorkflows,
                mission,
                this.location,
                this.rpaTypes.reduce(toLookup, {}),
                true,
                false
            );
        const doneWorking = this.workTracker.createTracker();
        this.missionEditService
            .saveMission()
            .then(savedMission => {
                doneWorking();
                this.airspaceAuthorisationManager.updateMission(savedMission);
                this.angulartics2.eventTrack.next({
                    action:
                        // FIXME not sure about this one
                        this.latestAuthorisations == null
                            ? 'show-request-authorisation'
                            : 'view-authorisation',
                    properties: {
                        category: 'airspace-authorisation'
                    }
                });
                const modal =
                    this.airspaceDialoguesService.showAirspaceAuthorisationDialogue(
                        this.airspaceAuthorisationManager,
                        this.organisation,
                        command,
                        savedMission,
                        null,
                        null,
                        this.rpaTypes,
                        requestNew
                    );
                modal.content.missionUpdated
                    .pipe(
                        takeUntil(modal.onHide),
                        takeUntil(this.ngUnsubscribe$)
                    )
                    .subscribe(mission => {
                        // No need to setup the mission again if nothing changed
                        if (
                            mission.modificationTime ===
                            this.savedMission.modificationTime
                        ) {
                            return;
                        }
                        this.setupMission(mission);
                    });
                modal.content.missionTimeUpdated
                    .pipe(
                        takeUntil(modal.onHide),
                        takeUntil(this.ngUnsubscribe$)
                    )
                    .subscribe(time => {
                        this.missionForm.patchValue({
                            missionDate: time.startTime,
                            missionEstimatedTime: time.duration
                        });
                        this.save();
                    });
                modal.onHidden
                    .pipe(takeUntil(this.ngUnsubscribe$), take(1))
                    .subscribe(() => {
                        this.missionService
                            .findMission(savedMission.id)
                            .pipe(takeUntil(this.ngUnsubscribe$))
                            .subscribe({
                                next: updatedMission => {
                                    this.airspaceAuthorisationManager.updateMission(
                                        updatedMission
                                    );
                                    // No need to setup the mission again if nothing changed
                                    if (
                                        updatedMission.modificationTime ===
                                        this.savedMission.modificationTime
                                    ) {
                                        return;
                                    }
                                    this.setupMission(updatedMission);
                                },
                                error: (error: FlyFreelyError) =>
                                    this.logging.error(
                                        error,
                                        `Error while updating mission: ${error.message}`
                                    )
                            })
                            .add(this.workTracker.createTracker());
                    });
            })
            .catch((error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error updating mission: ${error.message}`
                );
                doneWorking();
            });
    }

    cancelAirspaceAuthorisation(authorisation: AirspaceAuthorisationDto) {
        this.angulartics2.eventTrack.next({
            action: 'cancel-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Cancel Airspace Authorisation?',
                `Are you sure you want to cancel this airspace authorisation`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => {
                this.airspaceAuthorisationManager
                    .cancelAuthorisation(
                        authorisation.id,
                        authorisation.authorisationType
                    )
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe({
                        next: authorisation => {
                            this.missionService
                                .findMission(this.mission.id)
                                .pipe(takeUntil(this.ngUnsubscribe$))
                                .subscribe(mission =>
                                    this.setupMission(mission)
                                )
                                .add(this.workTracker.createTracker());
                        },
                        error: (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error cancelling airspace authorisation: ${error.message}`
                            );
                        }
                    })
                    .add(this.workTracker.createTracker());
            }, DO_NOTHING);
    }

    refreshAirspaceAuthorisation() {
        if (this.savedMission == null || this.mission?.id == null) {
            return;
        }
        this.airspaceAuthorisationManager.findAuthorisationsByMissionId(
            this.savedMission?.id ?? this.mission.id
        );
    }

    requestNewAirspaceAuthorisation() {
        this.latestAuthorisations = null;
        this.angulartics2.eventTrack.next({
            action: 'show-request-new-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });
        this.showAirspaceAuthorisation(true);
    }

    onPrefillFromMission(mission: MissionSummaryDto) {
        this.isShowingSelectPrefillMission = false;

        const doneWorking = this.workTracker.createTracker();
        this.prefillFromMission(mission.id).then(
            () => {
                this.logging.success('Documentation prefilled');
                doneWorking();
            },
            (error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error while loading mission: ${error.message}`
                );
                doneWorking();
            }
        );

        this.angulartics2.eventTrack.next({
            action: 'documentation-prefill',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        });
    }

    /**
     * Update the map and other geographic bearings based on the selected location, aerodromes and NOTAMs.
     * @param location the currently selected location
     * @param aerodromes the list of aerodromes
     * @param notams the selected NOTAMs
     */
    private updateGeographicInformation(
        aerodromes: ContactWithLocationDto[],
        notams: NotamDto[]
    ) {
        const maybeFlightArea = flightAreaOf(this.location);

        const centre: GeoJSON.Point | null = pipe(
            maybeFlightArea,
            optionMap(
                (l: LocationFeatureDto) =>
                    center(<GeoJSON.Polygon>l.geometry).geometry
            ),
            // Fallback if there is no polygon
            alt(() =>
                optionMap((f: LocationFeatureDto) => <GeoJSON.Point>f.geometry)(
                    flightAreaPointOfFeatures(this.location?.features ?? [])
                )
            ),
            getOrElse(() => null)
        );

        const locationArea = getOrElse(() => null)(
            optionMap((l: LocationFeatureDto) =>
                area(<GeoJSON.Polygon>l.geometry)
            )(maybeFlightArea)
        );

        const getBearing = (pos: GeoJSON.Point) =>
            ({
                bearing: bearingToAzimuth(bearing(pos, centre)),
                distance: distance(centre, pos) * 0.539957
            } as LocationBearing);

        // sort notams to closest first
        notams = notams.sort(
            (a, b) =>
                getBearing(a.position).distance -
                getBearing(b.position).distance
        );
        if (this.missionForm.controls.notams.value !== notams) {
            this.missionForm.controls.notams.patchValue(notams);
        }

        this.locationBearings = {
            notams:
                notams != null
                    ? notams
                          .filter(n => n.position != null)
                          .map(n =>
                              n.position != null ? getBearing(n.position) : null
                          )
                    : [],
            aerodromes:
                aerodromes != null
                    ? aerodromes
                          .filter(a => a.location != null)
                          .map(a => getBearing(a.location))
                    : [],
            locationCentre: centre,
            locationArea: locationArea
        };
    }

    setSunriseSunset(sunriseSunset: LocationSunriseSunset) {
        this.sunriseSunset = sunriseSunset;
        const timeZone =
            this.lockedFields.timeZone || this.timeZone.value == null
                ? this.location.timeZone
                : this.timeZone.value;
        this.sunrise = toLocalTime(sunriseSunset.sunrise, timeZone);
        this.sunset = toLocalTime(sunriseSunset.sunset, timeZone);
        this.civilTwilightStart = toLocalTime(
            sunriseSunset.civilTwilightStart,
            timeZone
        );
        this.civilTwilightEnd = toLocalTime(
            sunriseSunset.civilTwilightEnd,
            timeZone
        );
        this.calculateTimeOfDay();
    }

    calculateTimeOfDay() {
        if (this.sunriseSunset == null) {
            return;
        }
        const civilStart = this.sunriseSunset.civilTwilightStart;
        const civilEnd = this.sunriseSunset.civilTwilightEnd;
        const startTimeInDay = moment(this.missionStartTime).isBetween(
            moment(civilStart),
            moment(civilEnd)
        );
        const endTimeInDay = moment(this.missionEndTime).isBetween(
            moment(civilStart),
            moment(civilEnd)
        );
        const timeOfDay =
            startTimeInDay && endTimeInDay
                ? DisplayableMissionDto.TimeOfDay.DAY
                : !startTimeInDay && !endTimeInDay
                ? DisplayableMissionDto.TimeOfDay.NIGHT
                : DisplayableMissionDto.TimeOfDay.DAY_NIGHT;
        if (timeOfDay !== this.missionForm.controls.timeOfDay.value) {
            this.missionForm.controls.timeOfDay.patchValue(timeOfDay);
        }
        this.changeDetector.detectChanges();
    }

    get missionTypeId() {
        return this.missionForm.get('missionTypeId');
    }

    get missionWorkflowVersionId() {
        return this.missionForm.get('missionWorkflowVersionId');
    }

    get craftIds() {
        return this.missionForm.get('craftIds');
    }

    get equipmentIds() {
        return this.missionForm.get('equipmentIds');
    }

    get crewNotes() {
        return this.missionForm.get('crewNotes');
    }

    get missionDate() {
        return this.missionForm.get('missionDate');
    }

    get timeZone() {
        return this.missionForm.get('timeZone');
    }

    get maximumHeight() {
        return this.missionForm.get('maximumHeight');
    }

    get emergencyContacts() {
        return this.missionForm.get('emergencyContacts');
    }

    get missionEmergencyContacts() {
        return this.missionForm.get('missionEmergencyContacts');
    }

    get radioFrequencies() {
        return this.missionForm.get('radioFrequencies');
    }

    get missionRadioFrequencies() {
        return this.missionForm.get('missionRadioFrequencies');
    }

    get aerodromesOfInterest() {
        return this.missionForm.get('aerodromesOfInterest');
    }

    get visualLineOfSight() {
        return this.missionForm.get('visualLineOfSight');
    }

    get timeOfDay() {
        return this.missionForm.get('timeOfDay');
    }

    get missionCrew() {
        return this.missionForm.get('missionCrew');
    }

    get rpicId() {
        return this.missionForm.get('rpicId');
    }

    get missionEstimatedTime() {
        return this.missionForm.get('missionEstimatedTime');
    }

    get declareNoRelevantNotam() {
        return this.missionForm.get('declareNoRelevantNotam');
    }

    equipmentGroup(item: any) {
        const category = EquipmentTypeDto.EquipmentCategory;
        switch (item.equipmentType.equipmentCategory) {
            case category.BASE_STATION:
                return 'Base Station';
            case category.OTHER:
                return 'Other';
            case category.PAYLOAD:
                return 'Payload';
            case category.MONITOR:
                return 'Monitor';
            case category.CONTROL_ACCESSORIES:
                return 'Control Accessories';
            case category.CONTROLLER:
                return 'Controller';
            default:
                return null;
        }
    }

    locationGroup(item: any) {
        const category = LocationDto.Type;
        switch (item.type) {
            case category.TEMPLATE:
                return 'Location Templates';
            case category.MISSION:
                return 'Current Mission Location';
            default:
                return null;
        }
    }

    onFullscreenRequested() {
        this.fullScreenService.toggleFullScreen(this.mapElement);
    }

    fieldAppHandoff() {
        if (this.showHandoffPopup === true) {
            const date = fromTimestamp(
                this.savedMission.missionDate,
                this.savedMission.timeZone
            );
            const organisationId = this.organisation.id;

            const handoffPopup =
                this.missionDialoguesService.showFieldAppHandoff(
                    date,
                    organisationId
                );

            handoffPopup.onHidden
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(() => this.modal.hide());
        } else {
            this.modal.hide();
        }
    }

    showAirspaceDisclaimerDialogue() {
        const disclaimer =
            this.location?.airspaceJurisdiction?.airspaceDisclaimer;
        if (disclaimer == null || disclaimer.length == 0) {
            this.showAirspaceDisclaimer = false;
            return;
        }
        this.preferencesService
            .findPreferencesAsOption(AIRSPACE_DISCLAIMER, null)
            .pipe(
                map(p =>
                    getOrElse(() => ({
                        date: moment(new Date()).subtract(1, 'day').toDate()
                    }))(p)
                ),
                takeUntil(this.ngUnsubscribe$),
                take(1)
            )
            .subscribe(
                preferences => {
                    if (preferences == null) {
                        this.showAirspaceDisclaimer = true;
                        return;
                    }
                    const today = moment(new Date()).format('YYYY-MM-DD');
                    const lastViewed = moment(preferences.date).format(
                        'YYYY-MM-DD'
                    );
                    // Only show the disclaimer once per account
                    this.showAirspaceDisclaimer =
                        moment(lastViewed).isBefore(today);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            );
    }

    onAirspaceDisclaimerAcknowledged() {
        this.showAirspaceDisclaimer = false;
        this.preferencesService
            .updatePreferences(AIRSPACE_DISCLAIMER, null, { date: new Date() })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(DO_NOTHING)
            .add(this.workTracker.createTracker());
    }

    getPilotRegisterStatus(personId: number) {
        return this.pilots?.find(p => p.id === personId)?.registerStatus;
    }

    get showConfirmWorkFlow() {
        return (
            !this.lockedFields?.missionWorkflowVersion &&
            !this.loadingWorkflow &&
            this.approvalCreationRequired
        );
    }

    get approvalCreationRequired() {
        const workflow = this.missionDetails.missionWorkflowVersion;
        const inactiveStatuses = [
            RequestersMissionApprovalDto.Status.CANCELLED,
            RequestersMissionApprovalDto.Status.REJECTED,
            RequestersMissionApprovalDto.Status.REVOKED,
            RequestersMissionApprovalDto.Status.REVISION_REQUIRED
        ];
        const activeApprovals =
            this.savedMission?.approvals?.filter(
                a => !inactiveStatuses.includes(a.status)
            ) ?? [];
        const showChangeWorkflow =
            this.savedMission != null &&
            this.savedMission.missionWorkflowVersionId != null &&
            this.savedMission.missionWorkflowVersionId !=
                this.missionForm.controls.missionWorkflowVersionId.value;
        return (
            !showChangeWorkflow &&
            this.missionDetails != null &&
            workflow != null &&
            this.savedMission != null &&
            workflow.id === this.missionWorkflowVersionId.value &&
            ((workflow.delegatedAuthority != null &&
                workflow.delegatedAuthority.requiresApproval === true &&
                activeApprovals.find(
                    approval =>
                        approval.authorityId === workflow.delegatedAuthority.id
                ) == null) ||
                (workflow.additionalAuthorities != null &&
                    workflow.additionalAuthorities.filter(
                        a =>
                            a.requiresApproval &&
                            activeApprovals.find(
                                approval => approval.authorityId === a.id
                            ) == null
                    ).length > 0))
        );
    }

    get additionalApprovalCreationRequired() {
        const additionalAuthoritiesWithApproval =
            this.missionForm.value.additionalAuthorityIds
                ?.map(id =>
                    this.allAdditionalAuthorities.find(auth => auth.id === id)
                )
                .filter(auth => auth?.requiresApproval) ?? [];
        const inactiveStatuses = [
            RequestersMissionApprovalDto.Status.CANCELLED,
            RequestersMissionApprovalDto.Status.REJECTED,
            RequestersMissionApprovalDto.Status.REVOKED,
            RequestersMissionApprovalDto.Status.REVISION_REQUIRED
        ];
        const activeApprovals =
            this.savedMission?.approvals?.filter(
                a => !inactiveStatuses.includes(a.status)
            ) ?? [];

        return (
            !this.approvalCreationRequired &&
            this.canUseAdditionalAuthorities &&
            additionalAuthoritiesWithApproval.length > 0 &&
            additionalAuthoritiesWithApproval.filter(
                a =>
                    activeApprovals.find(
                        approval => approval.authorityId === a.id
                    ) == null
            ).length > 0
        );
    }

    get hasPrefilledDocumentationResponses() {
        return (
            this.hasClonedFormResponses &&
            this.missionDocumentation?.documentationResponses != null &&
            Array.isArray(this.missionDocumentation.documentationResponses) &&
            this.missionDocumentation.documentationResponses.length > 0 &&
            this.missionDocumentation.documentationResponses.some(
                relatedEntity =>
                    relatedEntity.forms.some(
                        form => !form.completed && form.progress.progress > 0
                    )
            )
        );
    }

    // get missionApprovals() {
    //     return this.savedMission?.approvals;
    // }

    get activeMissionApprovals() {
        return this.missionApprovals?.filter(
            a =>
                a.approval.status !==
                RequestersMissionApprovalDto.Status.CANCELLED
        );
    }

    /**
     * Does the mission have any approvals that aren't cancelled, rejected or revoked
     */
    get hasActiveApprovals() {
        return (
            this.missionApprovals != null &&
            this.missionApprovals.length > 0 &&
            this.missionApprovals.some(
                approval =>
                    approval.approval.status !==
                        RequestersMissionApprovalDto.Status.CANCELLED &&
                    approval.approval.status !==
                        RequestersMissionApprovalDto.Status.REJECTED &&
                    approval.approval.status !==
                        RequestersMissionApprovalDto.Status.REVOKED
            )
        );
    }
}
