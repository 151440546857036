import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { ReferralSource } from './referrals/referral-source.component';
import { Referrals } from './referrals/referrals.component';

@NgModule({
    imports: [
        CommonModule,
        ClipboardModule,
        TooltipModule,
        SharedUiModule,
        FlyFreelyTableModule,
        EnhancedHelpModule,
        AnalyticsModule
    ],
    declarations: [Referrals, ReferralSource],
    exports: [Referrals]
})
export class ReferralsModule {}
