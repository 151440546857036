import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

/**
 * Display a static help area based on either the `props.message`
 * or looked up from the `props.messageLookup` using the `props.lookupProp`.
 */
@Component({
    selector: 'formly-help',
    template: `
        <div>
            <static-help *ngIf="message" [helpText]="message"></static-help>
        </div>
    `
})
export class FormlyHelp extends FieldType {
    private ngUnsubscribe$ = new Subject<void>();

    message: string;

    ngOnInit() {
        if (this.to.message != null) {
            this.message = this.to.message;
        } else if (
            this.to.lookupProp != null &&
            this.to.messageLookup != null
        ) {
            const lookupControl = (<FormGroup>(
                this.field.parent.formControl
            )).controls[this.to.lookupProp];
            if (lookupControl == null) {
                console.warn(`No such lookupProp for ${this.field.id}`);
                return;
            }

            lookupControl.valueChanges
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    startWith(lookupControl.value)
                )
                .subscribe(
                    (v: string) => (this.message = this.to.messageLookup[v])
                );
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
