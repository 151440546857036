<div class="dynamic-help-edit-container" [ngClass]="{ 'keep-open': keepOpen }">
    <div class="dynamic-help-contents horizontal-container fill">
        <div class="container-scroll modal-scrollbar fcol-2">
            <div *ngIf="currentScreenName == null">
                <label> Current Screen: </label>
                <div>
                    {{
                        currentScreenName != null
                            ? currentScreenName
                            : 'Hover over a help entry'
                    }}
                </div>
            </div>
            <div *ngIf="currentScreenName != null">
                <label>Current Screen Items: </label>
                <div *ngFor="let item of screenItems">
                    <button
                        type="button"
                        class="btn btn-xs btn-pill top-buffer"
                        [ngClass]="{
                            active:
                                currentComponent?.component === item.component
                        }"
                        (click)="onChangeComponent(item)"
                    >
                        {{ item.helpTitle }}
                    </button>
                </div>
            </div>
        </div>
        <div
            class="container-scroll modal-scrollbar fcol-2"
            *ngIf="items && currentComponent"
        >
            <label>Recent Items: </label>
            <div *ngFor="let item of items">
                <button
                    type="button"
                    class="btn btn-xs btn-pill top-buffer"
                    [ngClass]="{
                        active: currentComponent.component === item.component
                    }"
                    (click)="onChangeComponent(item)"
                >
                    {{ item.helpTitle }}
                </button>
            </div>
        </div>
        <div
            class="fcol-8 vertical-container"
            *ngIf="items && currentComponent"
        >
            <div>
                <div
                    *ngIf="findComponentType() === 'inline'"
                    class="enhanced-help-block-compact"
                >
                    <div class="helper-heading-container">
                        <i class="fas fa-life-ring fa-2x"></i>
                    </div>
                    <label
                        class="helper-text-label"
                        [innerHTML]="currentComponent?.helpText | autolink"
                    >
                    </label>
                </div>
                <div *ngIf="findComponentType() === 'tooltip'">
                    Tooltip:&nbsp;<span
                        class="tooltip-preview"
                        [innerHTML]="currentComponent?.helpText | autolink"
                    >
                    </span>
                </div>
                <div *ngIf="findComponentType() === 'empty'">
                    <span [innerHTML]="currentComponent?.helpText | autolink">
                    </span>
                </div>
                <div *ngIf="findComponentType() === 'text'">
                    <button type="button" class="btn btn-default">
                        {{ currentComponent?.helpText }}
                    </button>
                </div>
                <!-- TODO: need to be able to show the correct help layout (compact or not) -->
                <!-- <div
                    class="enhanced-help-block"
                >
                    <div class="col-xs-3 text-right helper-heading-container">
                        <label class="helper-heading-label">
                            {{ currentComponent?.helpTitle }}
                        </label>
                        <i class="fas fa-life-ring fa-2x"></i>
                    </div>
                    <div class="col-xs-9 helper-text-container">
                        <div class="helper-text">
                            <label
                                class="helper-text-label"
                                [innerHTML]="currentComponent?.helpText | autolink"
                            >
                            </label>
                        </div>
                        <div>
                            <div *ngIf="currentComponent?.supportVideoUrl?.length > 0">
                                <a (click)="showVideo()">Video</a>
                            </div>
                            <div *ngIf="currentComponent?.knowledgeBaseUrl?.length > 0">
                                <a
                                    [href]="currentComponent?.knowledgeBaseUrl"
                                    target="_blank"
                                    (click)="trackKnowledgeBase()"
                                    >Knowledge Base</a
                                >
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="container-scroll modal-scrollbar">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 5%">Type</th>
                            <th style="width: 15%">Help Title</th>
                            <th style="width: 70%">Help Text</th>
                            <th style="width: 10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{
                                    findComponentType() === 'inline'
                                        ? 'Help'
                                        : findComponentType() === 'tooltip'
                                        ? 'Tooltip'
                                        : findComponentType() === 'empty'
                                        ? 'Empty'
                                        : 'Text'
                                }}
                            </td>
                            <td>
                                {{ currentComponent.helpTitle }}
                            </td>
                            <td>
                                <textarea
                                    *ngIf="findComponentType() === 'inline'"
                                    placeholder="'Enter enhanced help text to display'"
                                    [(ngModel)]="currentComponent.helpText"
                                    [ngModelOptions]="{ standalone: true }"
                                    [readonly]="working"
                                    [allowHtml]="true"
                                    rows="4"
                                    class="form-control"
                                ></textarea>
                                <textarea
                                    *ngIf="findComponentType() === 'empty'"
                                    placeholder="'Enter text to display'"
                                    [(ngModel)]="currentComponent.helpText"
                                    [ngModelOptions]="{ standalone: true }"
                                    [readonly]="working"
                                    [allowHtml]="true"
                                    rows="4"
                                    class="form-control"
                                ></textarea>
                                <input
                                    *ngIf="findComponentType() === 'tooltip'"
                                    placeholder="Enter tooltip text to display"
                                    [(ngModel)]="currentComponent.helpText"
                                    [ngModelOptions]="{ standalone: true }"
                                    [readonly]="working"
                                    [allowHtml]="true"
                                    class="form-control"
                                />
                                <input
                                    *ngIf="findComponentType() === 'text'"
                                    placeholder="Enter text to display"
                                    [(ngModel)]="currentComponent.helpText"
                                    [ngModelOptions]="{ standalone: true }"
                                    [readonly]="working"
                                    [allowHtml]="true"
                                    class="form-control"
                                />
                            </td>
                            <td>
                                <button
                                    class="btn btn-primary"
                                    [disabled]="working"
                                    (click)="updateComponentHelp()"
                                >
                                    Save Changes
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td class="horizontal-container center-children">
                                <label class="right-buffer">Title</label>
                                <input
                                    type="text"
                                    placeholder="The title of the feature which is used on the modals"
                                    [(ngModel)]="currentComponent.helpTitle"
                                    [ngModelOptions]="{ standalone: true }"
                                    class="form-control"
                                    minlength="3"
                                    [readonly]="working"
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td class="horizontal-container center-children">
                                <label class="right-buffer"
                                    >Knowledge Base Link</label
                                >
                                <input
                                    type="text"
                                    placeholder="The link to this enhanced helps's knowledge base article"
                                    [(ngModel)]="
                                        currentComponent.knowledgeBaseUrl
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                    class="form-control"
                                    minlength="3"
                                    [readonly]="working"
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td class="horizontal-container center-children">
                                <label class="right-buffer"
                                    >Support/Demo Video</label
                                >
                                <input
                                    type="text"
                                    placeholder="The link to this enhanced helps's support video"
                                    [(ngModel)]="
                                        currentComponent.supportVideoUrl
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                    class="form-control"
                                    minlength="3"
                                    [readonly]="working"
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td class="horizontal-container center-children">
                                <label class="right-buffer"
                                    >Always On Until</label
                                >
                                <div
                                    id="datecontainer"
                                    class="input-group fill datecontainer"
                                    style="position: relative"
                                >
                                    <input
                                        type="text"
                                        placeholder="Turn this help entry on until this date"
                                        [(ngModel)]="alwaysOnUntil"
                                        [ngModelOptions]="{ standalone: true }"
                                        class="form-control"
                                        #datepicker="bsDatepicker"
                                        bsDatepicker
                                        container="datecontainer"
                                        placement="top"
                                        (onShown)="keepOpen = true"
                                        [readonly]="working"
                                    />
                                    <span
                                        *ngIf="!working"
                                        class="input-group-addon btn-default"
                                        (click)="datepicker.toggle()"
                                        [attr.aria-expanded]="datepicker.isOpen"
                                    >
                                        <i
                                            class="glyphicon glyphicon-calendar"
                                        ></i>
                                    </span>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
