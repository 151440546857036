import { Component, Input } from '@angular/core';
import {
    DO_NOTHING,
    InviteDto,
    OrganisationIdentifierDto,
    OrganisationService,
    PersonDto,
    ProfileService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProfileDialoguesService } from '../profile-dialogues.service';
import { basicUserProfileFields } from '../fieldsets';

@Component({
    selector: 'profile-details',
    templateUrl: './profile-details.component.html'
})
export class ProfileDetails {
    @Input() currentUser: PersonDto;
    @Input() invites: InviteDto[];
    @Input() canUseNotams: boolean;

    userAsaIdentifiers: OrganisationIdentifierDto;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;

    constructor(
        private profileDialoguesService: ProfileDialoguesService,
        private organisationService: OrganisationService,
        private commonDialoguesService: CommonDialoguesService,
        private profileService: ProfileService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshAsaDetails();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshAsaDetails() {
        if (!this.canUseNotams) {
            return;
        }
        this.organisationService
            .findOrganisationIdentifiers(
                this.currentUser.personalOrganisationId,
                'ASA'
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.userAsaIdentifiers = result;
                },
                () => {
                    this.userAsaIdentifiers = {
                        identifier: null,
                        integration: null
                    };
                }
            )
            .add(this.workTracker.createTracker());
    }

    manageAccountDetails() {
        window.open(
            'https://dev-id.flyfreely.io/auth/realms/FlyFreely/account',
            '_blank'
        );
    }

    updatePersonalDetails() {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Update Personal Details',
                'Update',
                true,
                true,
                basicUserProfileFields(),
                {
                    firstName: this.currentUser.firstName,
                    lastName: this.currentUser.lastName,
                    phoneNumber: this.currentUser.phoneNumber
                },
                data =>
                    this.profileService
                        .updateDetails({
                            ...data
                        })
                        .toPromise()
            )
            .catch(DO_NOTHING);
    }

    updateAirServices() {
        const dialogue = this.profileDialoguesService.showAirServicesDialogue(
            this.currentUser,
            this.userAsaIdentifiers
        );

        dialogue.onHide.pipe(take(1)).subscribe(() => this.refreshAsaDetails());
    }
}
