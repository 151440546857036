import { Component, Input, OnInit } from '@angular/core';
import { CraftDetailsDto, CraftDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
  selector: 'rpa-list-item',
  templateUrl: './rpa-list-item.component.html',
  styleUrls: ['./rpa-list-item.component.scss']
})
export class RpaListItemComponent {
  @Input() item: CraftDetailsDto;

}
