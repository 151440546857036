import { Injectable } from '@angular/core';
import {
    StepDescription,
    WorkflowHandler
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WorkflowEditDialogue } from './workflow-edit/workflow-edit-dialogue.component';

@Injectable()
export class WorkflowDialoguesService {
    constructor(private modalService: BsModalService) {}

    showEditWorkflow(
        steps: StepDescription[],
        workflowHandler: WorkflowHandler,
        workflow: any,
        hasDelegatedAuthority: boolean,
        hasName: boolean,
        hasApproverChecks: boolean = false
    ) {
        const modal = this.modalService.show(WorkflowEditDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                steps: steps,
                workflowHandler: workflowHandler,
                workflow: workflow,
                hasDelegatedAuthority: hasDelegatedAuthority,
                hasName: hasName,
                hasApproverChecks: hasApproverChecks
            }
        });
        return modal;
    }
}
