import { Directive, Renderer2, Input, ElementRef } from '@angular/core';
import { ScreenAnalyticsDirective } from './screen-analytics.directive';

/**
 * Adds a listener to collect analytics on event, which is tied in to the `screenAnalytics`,
 * the name of which is placed as the label of the event. Replaces `angulartics2On`.
 * Based on https://github.com/angulartics/angulartics2/blob/master/src/lib/core/angulartics2On.ts
 */
@Directive({
    selector: '[analyticsOn]'
})
export class AnalyticsOnDirective {
    @Input()
    analyticsOn: string;

    @Input()
    analyticsCategory: string;

    @Input()
    analyticsAction: string;

    @Input()
    analyticsLabel: string;

    constructor(
        private screenAnalytics: ScreenAnalyticsDirective,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngAfterContentInit() {
        this.renderer.listen(
            this.elRef.nativeElement,
            this.analyticsOn || 'click',
            (event: Event) =>
                this.screenAnalytics.track(
                    this.analyticsCategory || 'Event',
                    this.analyticsAction || this.analyticsOn || 'click',
                    null,
                    this.analyticsLabel
                )
        );
    }
}
