<div
    *ngIf="helpText != null && !compact && active"
    @fadeInExpandOnEnter
    @fadeOutCollapseOnLeave
    class="enhanced-help-block"
>
    <div class="col-xs-3 text-right helper-heading-container">
        <label class="helper-heading-label">
            {{ helpTitle }}
        </label>
        <i class="fas fa-life-ring fa-2x"></i>
    </div>
    <div
        class="col-xs-9 helper-text-container horizontal-container vertical-align"
    >
        <div class="helper-text fill">
            <label class="helper-text-label" [innerHTML]="helpText | autolink">
            </label>
        </div>
        <div>
            <div *ngIf="supportVideoUrl != null && supportVideoUrl?.length > 0">
                <a (click)="showVideo()"
                    ><span
                        class="fa-duotone fa-clapperboard-play black-text"
                    ></span>
                    Help Video</a
                >
            </div>
            <div
                *ngIf="knowledgeBaseUrl != null && knowledgeBaseUrl?.length > 0"
            >
                <a
                    [href]="knowledgeBaseUrl"
                    target="_blank"
                    (click)="trackKnowledgeBase()"
                    ><span class="fas fa-question-circle black-text"></span>
                    Knowledge Base</a
                >
            </div>
        </div>
    </div>
</div>
<div
    *ngIf="helpObject == null && !compact && active && editMode"
    @fadeInExpandOnEnter
    @fadeOutCollapseOnLeave
    class="enhanced-help-block"
>
    <div class="col-xs-3 text-right helper-heading-container">
        <label class="helper-heading-label">
            {{ componentName }}
        </label>
        <i class="fas fa-life-ring fa-2x"></i>
    </div>
    <div class="col-xs-9 helper-text-container">
        <div class="helper-text">
            <label class="helper-text-label">
                Enhanced help text goes here
            </label>
        </div>
        <div></div>
    </div>
</div>
<div
    *ngIf="helpText != null && compact && active"
    @fadeInExpandOnEnter
    @fadeOutCollapseOnLeave
    class="enhanced-help-block-compact horizontal-container"
>
    <div class="helper-heading-container">
        <i class="fas fa-life-ring fa-2x"></i>
    </div>
    <label class="helper-text-label fill" [innerHTML]="helpText | autolink">
    </label>
    <div>
        <div *ngIf="supportVideoUrl != null && supportVideoUrl.length > 0">
            <a (click)="showVideo()"
                ><span class="fa fa-clapperboard-play black-text"></span> Help
                Video</a
            >
        </div>
        <div *ngIf="knowledgeBaseUrl != null && knowledgeBaseUrl.length > 0">
            <a
                [href]="knowledgeBaseUrl"
                target="_blank"
                (click)="trackKnowledgeBase()"
                ><span class="fas fa-question-circle black-text"></span>
                Knowledge Base</a
            >
        </div>
    </div>
</div>
<div
    *ngIf="helpObject == null && compact && active && editMode"
    @fadeInExpandOnEnter
    @fadeOutCollapseOnLeave
    class="enhanced-help-block-compact"
>
    <div class="helper-heading-container">
        <i class="fas fa-life-ring fa-2x"></i>
    </div>
    <label class="helper-text-label">
        Enhanced help for {{ componentName }} goes here
    </label>
</div>
