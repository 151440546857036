import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { getMeasurementDescription } from '../utils';


class MeasurementLineSelectState {
    dragMoveLocation: any;
    boxSelectStartLocation: any;
    boxSelectElement: any;
    boxSelecting: boolean;
    canBoxSelect: boolean;
    dragMoving: boolean;
    canDragMove: boolean;
    featureId: string;
    selectedCoordPaths: [];
    measurement: any; // DrawFeature
    feature: any;
}

const DrawSingleMeasurementLinePointSelect = {
    ...MapboxDraw.modes.direct_select
};

// Required method which will display the features
DrawSingleMeasurementLinePointSelect.toDisplayFeatures = function (
    state: MeasurementLineSelectState,
    geojson: any,
    push: (arg0: any) => void
) {
    if (state.featureId === geojson.properties.id) {
        geojson.properties.active = MapboxDraw.constants.activeStates.ACTIVE;
        push(geojson);
        MapboxDraw.lib.createSupplementaryPoints(geojson, {
            // map: this.map,
            midpoints: true,
            selectedPaths: state.selectedCoordPaths
        }).forEach(push);

        const measurementFeatureId =
            state.feature.properties.relatedFeatureIds[0];
        const lineGeoJson = state.feature.toGeoJSON();
        const measurement = this.getFeature(measurementFeatureId);
        // @ts-ignore: not in the current type
        measurement.properties.measurement = getMeasurementDescription(
            lineGeoJson
        );
        push(measurement.toGeoJSON());
    } else {
        geojson.properties.active = MapboxDraw.constants.activeStates.INACTIVE;
        push(geojson);
    }
    // @ts-ignore: not in the current type
    this.fireActionable(state);
};
export default DrawSingleMeasurementLinePointSelect;
