<ng-container screenAnalytics="flight-log-assign-rpa">
    <div class="rpa-container" style="min-height: 80vh">
        <ng-container [ngSwitch]="step">
            <div *ngSwitchCase="'LOADING'">Loading...</div>
            <div *ngSwitchCase="'ASSIGN'">
                <enhanced-help
                    *ngIf="enhancedHelpActive"
                    screenName="flight-log-overview"
                    componentName="flight-log-assign-rpa"
                    [compact]="true"
                    [alwaysActive]="true"
                >
                </enhanced-help>
                <div class="bottom-buffer">
                    <div class="horizontal-container">
                        <p class="display-label">Log RPA Name</p>
                        <p class="left-buffer">{{ rpaName }}</p>
                    </div>
                    <div class="horizontal-container">
                        <p class="display-label">Log RPA Type</p>
                        <p class="left-buffer">{{ rpaType }}</p>
                    </div>
                    <div class="horizontal-container" *ngIf="rpaSerialNumber">
                        <p class="display-label">Log RPA FC Serial Number</p>
                        <p class="left-buffer">{{ rpaSerialNumber }}</p>
                    </div>
                </div>
                <ng-container [ngSwitch]="source">
                    <p *ngSwitchCase="'MISSION'">
                        Please select from the list of RPA on the mission
                    </p>
                    <p *ngSwitchCase="'ALL'">
                        Please select from the list of all RPA
                    </p>
                </ng-container>
                <static-table
                    *ngIf="!working"
                    [availableColumns]="availableColumns"
                    [selectedColumns]="selectedColumns"
                    [tableData]="rpas"
                    [tableConfig]="tableConfig"
                    [tableSearch]="tableSearch"
                >
                    <ng-template
                        rowActions
                        let-row="row"
                        *ngIf="
                            flightLog &&
                            rpaSerialNumber &&
                            rpaSerialNumber != 'null'
                        "
                    >
                        <ng-container [ngSwitch]="row.assignedToMission">
                            <button
                                *ngSwitchCase="false"
                                class="btn btn-xs btn-pill assign"
                                type="button"
                                (click)="onRpaSelected(row)"
                                tooltip="Assign this RPA"
                                placement="left"
                                angulartics2On="click"
                                angularticsAction="assign-rpa"
                                angularticsCategory="flight-logs"
                            >
                                Assign
                            </button>
                            <button
                                *ngSwitchCase="true"
                                class="btn btn-xs btn-pill assigned"
                                type="button"
                                disabled="true"
                                tooltip="Already assigned"
                                placement="left"
                            >
                                <em>Assigned</em>
                            </button>
                        </ng-container>
                    </ng-template>
                    <ng-template
                        rowActions
                        let-row="row"
                        *ngIf="
                            flightLog &&
                            (!rpaSerialNumber || rpaSerialNumber == 'null')
                        "
                    >
                        <button
                            class="btn btn-xs btn-pill assign"
                            type="button"
                            (click)="onRpaSelected(row)"
                            tooltip="Assign this RPA"
                            placement="left"
                            angulartics2On="click"
                            angularticsAction="assign-rpa"
                            angularticsCategory="flight-logs"
                        >
                            Assign
                        </button>
                    </ng-template>
                </static-table>
            </div>

            <div *ngSwitchCase="'ASSIGNING'">Assigning...</div>
        </ng-container>
    </div>

    <div class="action-container">
        <button
            class="btn btn-sm btn-block btn-default top-buffer"
            type="button"
            (click)="this.close(false)"
        >
            Done
        </button>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</ng-container>
