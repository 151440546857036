import { Component, Input, SimpleChanges } from '@angular/core';
import {
    DownloadService,
    PersonRolesDto,
    PersonnelReportService,
    PrintOption,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'print-personnel-reports',
    templateUrl: './print-personnel-reports.component.html'
})
export class PrintPersonnelReports {
    @Input() person: PersonRolesDto;
    @Input() managingOrganisationId: number;

    printOptions: PrintOption[] = [];

    private ngUnsubscribe$: Subject<void> = new Subject();
    public workTracker = new WorkTracker();

    constructor(
        private personnelReportService: PersonnelReportService,
        private downloadService: DownloadService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if ('person' in changes || 'managingOrganisationId' in changes) {
            if (this.person != null) {
                this.refreshAvailableReports();
            }
        }
    }

    ngOnDestroy() {
        this.workTracker.ngOnDestroy();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAvailableReports() {
        if (this.person == null) {
            this.printOptions = null;
            return;
        }

        this.personnelReportService
            .findAvailableTemplates(
                'PersonReport',
                this.person.id,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(reports => {
                this.printOptions = reports.map(r => ({
                    name: r.name,
                    description: r.description ? r.description : '',
                    print: () => {
                        const doneWorking = this.workTracker.createTracker();
                        this.downloadService
                            .downloadFromUrl(
                                this.personnelReportService.getPdfReportUrl(
                                    r.reportTemplateId,
                                    this.person.id,
                                    this.managingOrganisationId
                                )
                            )
                            .finally(doneWorking);
                    }
                }));
            });
    }
}
