import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { InlineModalModule } from 'libs/inline-modal/src/lib/inline-modal.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { MapModule } from 'libs/map/src/lib/map.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { AirspaceAuthorisationsDirective } from './airspace-authorisations.directive';
import { AirspaceCheckResultDirective } from './airspace-check-result.directive';
import { AirspaceCheckDetailsDialogue } from './airspace-check/airspace-check-details/airspace-check-details-dialogue.component';
import { AirspaceCheck } from './airspace-check/airspace-check.component';
import { JurisdictionLibraryDialogue } from './airspace-check/jurisdiction-library/jurisdiction-library.component';
import { JurisdictionLinksComponent } from './airspace-check/jurisdiction-links/jurisdiction-links.component';
import { AirspaceCheckStatusIndicator } from './airspace-check/status-indicator/airspace-check-status-indicator.component';
import { InlineAirspaceWarningsAndBlockersComponent } from './airspace-check/warnings-and-blockers/inline-warnings-and-blockers.component';
import { AccountAirspaceDataComponent } from './airspace-data-settings/account-airspace-data.component';
import { AirspaceDialoguesService } from './airspace-dialogues.service';
import { AirspaceUnavailableAlert } from './airspace-unavailable-alert/airspace-unavailable-alert.component';
import { AirspaceAuthorisationAcknowledgementDialogue } from './authorisation/authorisation-agreement/authorisation-agreement-dialogue.component';
import { AirspaceAuthorisationMessagingComponent } from './authorisation/authorisation-messaging/airspace-authorisation-messaging.component';
import { AirspaceConsentForTrialDialogue } from './authorisation/authorisation-permissions/casa-consent-for-trial/airspace-consent-for-trial.component';
import { AirspaceAuthorisationPreviewDialogue } from './authorisation/authorisation-preview/airspace-authorisation-preview.component';
import { AirspaceAuthorisationStatus } from './authorisation/authorisation-status/authorisation-status.component';
import { AirspaceAuthorisationSummary } from './authorisation/authorisation-summary/airspace-authorisation-summary.component';
import { InlineAuthorisationComponent } from './authorisation/inline-authorisation/inline-authorisation.component';
import { RemotePilotRegistrationDialogue } from './authorisation/remote-pilot-registration/remote-pilot-registration.component';
import { AirspaceDisclaimerDialogue } from './disclaimer/airspace-disclaimer-dialogue.component';
import { FormatAuthorisationEligibilityPipe } from './formatters/format-authorisation-eligibility.pipe';
import { FormatAuthorisationStatusPipe } from './formatters/format-authorisation-status.pipe';
import { AirspaceNotificationCard } from './notifications/airspace-notification.component';
import { AirspaceRuleDetailsDialogue } from './rule-details-dialogue/airspace-rule-details-dialogue.component';
import { AirspaceRuleMessageDialogue } from './rule-details-dialogue/rule-message/airspace-rule-message-dialogue.component';
import { ShowErrorsComponent } from './show-errors/show-errors.component';
import { AirspaceCheckResults } from './airspace-check/airspace-check-results.component';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forChild(),
        InlineModalModule,
        PopoverModule,
        TooltipModule,
        AttachmentsModule,
        MapModule,
        NgxLoadingModule,
        ValidationModule,
        AnalyticsModule,
        FormlyModule,
        BsDatepickerModule,
        TimepickerModule,
        EnhancedHelpModule,
        FeaturesModule,
        CollapseModule,
        MaterialModule,
        LoadingIndicatorModule,
        NgSelectModule
    ],
    providers: [AirspaceDialoguesService],
    declarations: [
        AirspaceRuleDetailsDialogue,
        AirspaceRuleMessageDialogue,
        AirspaceDisclaimerDialogue,
        AirspaceNotificationCard,
        AirspaceCheck,
        AirspaceCheckStatusIndicator,
        JurisdictionLibraryDialogue,
        AirspaceAuthorisationPreviewDialogue,
        AirspaceConsentForTrialDialogue,
        AirspaceAuthorisationAcknowledgementDialogue,
        AirspaceAuthorisationSummary,
        AirspaceUnavailableAlert,
        AirspaceAuthorisationStatus,
        RemotePilotRegistrationDialogue,
        AirspaceAuthorisationMessagingComponent,
        JurisdictionLinksComponent,
        AirspaceCheckResultDirective,
        AirspaceCheckDetailsDialogue,
        FormatAuthorisationEligibilityPipe,
        InlineAirspaceWarningsAndBlockersComponent,
        AirspaceAuthorisationsDirective,
        InlineAuthorisationComponent,
        FormatAuthorisationEligibilityPipe,
        FormatAuthorisationStatusPipe,
        ShowErrorsComponent,
        AccountAirspaceDataComponent,
        AirspaceCheckResults
    ],
    exports: [
        AirspaceRuleDetailsDialogue,
        AirspaceRuleMessageDialogue,
        AirspaceDisclaimerDialogue,
        AirspaceNotificationCard,
        AirspaceCheck,
        AirspaceAuthorisationPreviewDialogue,
        AirspaceConsentForTrialDialogue,
        AirspaceAuthorisationSummary,
        AirspaceUnavailableAlert,
        AirspaceAuthorisationStatus,
        AirspaceAuthorisationMessagingComponent,
        JurisdictionLinksComponent,
        AirspaceCheckResultDirective,
        FormatAuthorisationEligibilityPipe,
        FormatAuthorisationStatusPipe,
        InlineAirspaceWarningsAndBlockersComponent,
        AirspaceAuthorisationsDirective,
        InlineAuthorisationComponent,
        AccountAirspaceDataComponent,
        AirspaceCheckResults
    ]
})
export class AirspaceModule {}

export {
    AirspaceAuthorisationMessagingComponent,
    AirspaceAuthorisationPreviewDialogue,
    AirspaceAuthorisationStatus,
    AirspaceAuthorisationSummary,
    AirspaceCheck,
    AirspaceConsentForTrialDialogue,
    AirspaceDisclaimerDialogue,
    AirspaceNotificationCard,
    AirspaceRuleDetailsDialogue,
    AirspaceRuleMessageDialogue,
    AirspaceUnavailableAlert,
    JurisdictionLinksComponent,
    AirspaceCheckResults
};
