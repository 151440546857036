import { Component } from '@angular/core';
import {
    downloadCsv,
    FlyFreelyLoggingService,
    FormColumn,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { downloadTemplate, parseCsvFile } from './helpers';

/**
 * A multi-entry section that uses a table. This includes the section header so that
 * we can embed the add button as per the style guide.
 */

@Component({
    selector: 'formly-input-table',
    template: `
        <div>
            <h4 class="section-heading">
                <button
                    type="button"
                    class="btn-circle btn-link"
                    (click)="add()"
                >
                    <span class="fa fa-plus"></span></button
                ><span>{{ to.label }}</span>
                <div></div>
                <div class="btn-group" dropdown>
                    <button
                        dropdownToggle
                        type="button"
                        class="btn btn-link dropdown-toggle"
                        aria-controls="dropdown-basic"
                    >
                        ...
                    </button>
                    <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                    >
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                (click)="downloadTemplate()"
                                >Download Template</a
                            >
                        </li>
                        <li role="menuitem">
                            <a class="dropdown-item" (click)="downloadCsv()"
                                >Download CSV</a
                            >
                        </li>
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                ngfSelect
                                accept="text/csv,application/vnd.ms-excel"
                                (fileChange)="uploadCsv($event)"
                                (lastInvalidsChange)="invalidFile()"
                                [files]="files"
                                >Upload CSV</a
                            >
                        </li>
                    </ul>
                </div>
            </h4>
            <p>{{ to.text }}</p>
            <div class="static-table-container">
                <table class="table">
                    <thead>
                        <tr>
                            <th *ngFor="let column of to.columns">
                                {{ column.name }}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let row of model; let rowIndex = index">
                            <td
                                class="formly-td"
                                *ngFor="let column of to.columns"
                            >
                                <formly-field
                                    [field]="getField(field, column, rowIndex)"
                                ></formly-field>
                            </td>
                            <td class="actions">
                                <button
                                    type="button"
                                    class="btn btn-tertiary btn-delete"
                                    [disabled]="working"
                                    (click)="remove(rowIndex)"
                                    tooltip="Delete"
                                    placement="left"
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="bottom-buffer" *ngIf="model.length > 0">
                <button
                    class="btn btn-section-action"
                    type="button"
                    (click)="add()"
                >
                    <div class="circle">
                        <span class="fa fa-plus"></span>
                    </div>
                    Add New Entry
                </button>
            </div>
        </div>
    `
})
export class FormlyTable extends FieldArrayType {
    working: boolean;
    workTracker: WorkTracker = new WorkTracker();
    // Files is used by the file uploader to manage file names uploaded and allow re-uploading the same file.
    files: File[];
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private logging: FlyFreelyLoggingService) {
        super();
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    getField(
        field: FormlyFieldConfig,
        column: FormColumn,
        rowIndex: number
    ): FormlyFieldConfig {
        return field.fieldGroup[rowIndex].fieldGroup.find(
            f => f.key === column.key
        );
    }

    downloadTemplate() {
        downloadTemplate(this.field);
    }

    downloadCsv() {
        // downloadCsv(this.field, this.formControl.value);
        downloadCsv(this.field, this.formControl.value);
    }

    uploadCsv(file: File) {
        parseCsvFile(file, this.field).then(
            results => {
                // Clearing the files list allows re-uploading the same file name multiple times if required.
                this.files = [];
                let validCount = results.length;
                results.forEach(result => {
                    const values = Object.values(result);

                    if (values.every(val => !val)) {
                        this.logging.error(
                            null,
                            `Invalid Form Field and Value: imported CSV does not match the format of this form section`
                        );
                        validCount--;
                        return;
                    }

                    this.add(undefined, result);
                });
                if (validCount > 0) {
                    this.logging.success(`Imported ${validCount} results`);
                }
            },
            error => this.logging.error(null, error)
        );
    }

    invalidFile(file: File[]) {
        if (!file) {
            return;
        }
        this.logging.error(null, 'This file type is not supported');
    }
}
