<td class="horizontal-container fill vertical-align space-between">
    <ng-select
        *ngIf="availableEquipment != null && editing"
        name="craft"
        [(ngModel)]="selectedEquipmentId"
        [ngModelOptions]="{ standalone: true }"
        [clearable]="false"
        bindValue="id"
        bindLabel="nickname"
        placeholder="Select Equipment"
        [items]="availableEquipment"
        [hideSelected]="true"
        [readonly]="readonly"
        [disabled]="disable || readonly"
        (focus)="setStep('equipment')"
        appendTo="body"
        class="fill wide-panel"
        [searchFn]="equipmentSearch"
        (change)="updateSelectedEquipment()"
    >
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <equipment-list-item [item]="item"></equipment-list-item>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-index="index">
            <status-icon [equipment]="item" [clickable]="false"></status-icon>
        </ng-template>
    </ng-select>
    <status-icon
        *ngIf="selectedEquipment && !editing"
        [equipment]="selectedEquipment"
        (nameClicked)="viewEquipment()"
    ></status-icon>
    <button
        *ngIf="selectedEquipment && !editing"
        type="button"
        class="btn btn-tertiary"
        tooltip="Select a different equipment"
        placement="top"
        (click)="editing = !editing"
        [disabled]="disable || readonly"
    >
        <span class="fal fa-pencil-alt"></span>
    </button>
    <button
        *ngIf="selectedEquipment && editing"
        type="button"
        class="btn btn-tertiary"
        tooltip="Cancel"
        placement="top"
        (click)="editing = !editing"
        [disabled]="disable || readonly"
    >
        <span class="fal fa-times"></span>
    </button>
</td>
<td>
    {{
        selectedEquipment == null
            ? ''
            : selectedEquipment.manufacturerSerialNumber
    }}
</td>
<td>
    {{
        selectedEquipment == null
            ? ''
            : selectedEquipment.equipmentType.make +
              ' ' +
              selectedEquipment.equipmentType.model
    }}
</td>
<td>
    {{
        selectedEquipment == null
            ? ''
            : (selectedEquipment.equipmentType.equipmentCategory
              | formatEquipmentCategory)
    }}
</td>
<td>
    <span
        *ngIf="selectedEquipment != null && selectedEquipment.status != null"
        class="status-block"
        [ngClass]="selectedEquipment.status | equipmentStatusBlock"
    >
        {{ selectedEquipment.status | formatResourceStatus }}
    </span>
    <span *ngIf="selectedEquipment == null || selectedEquipment.status == null"
        >N/A</span
    >
</td>
<!-- add padding to align bin button with ng-select row height -->
<td>
    <button
        type="button"
        class="btn btn-tertiary btn-delete"
        tooltip="Remove this equipment from the mission"
        placement="top"
        (click)="deleteRow()"
        [disabled]="disable || readonly"
    >
        <span class="fal fa-trash-alt"></span>
    </button>
</td>
