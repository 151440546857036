<div class="main-content" [screenAnalytics]="'invites'">
    <div class="container">
        <div class="main-wrapper row">
            <div class="col-sm-10 col-md-4 col-md-offset-2">
                <ng-container *ngIf="currentStatus === 'LOADING'">
                    <div class="form-group">
                        <img
                            imgPath="/flyfreely-logo-black-text.png"
                            class="logo-img"
                        />
                        <div class="horizontal-container top-buffer">
                            <p>Loading...</p>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentStatus === 'LOGGED_IN'">
                    <div class="form-group">
                        <img
                            imgPath="/flyfreely-logo-black-text.png"
                            class="logo-img"
                        />
                        <p *ngIf="error == null">
                            You have been invited to
                            <strong>{{ inviteOrganisation }}</strong>
                        </p>
                        <p *ngIf="error != null">{{ error }}</p>
                    </div>

                    <div>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="allowManaging"
                                    [(ngModel)]="inviteDetails.allowManaging"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i>
                                </span>
                                Allow Organisation to manage you
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="defaultOrganisation"
                                    [(ngModel)]="
                                        inviteDetails.defaultOrganisation
                                    "
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i>
                                </span>
                                Set Organisation as your default organisation
                            </label>
                        </div>
                    </div>

                    <div class="form-group p-relative">
                        <button
                            class="login-btn-primary"
                            type="button"
                            [disabled]="working"
                            (click)="acceptInvite()"
                        >
                            <span>Accept Invite</span>
                        </button>
                        <div
                            class="slope-background slope-btn-background md"
                        ></div>
                    </div>
                    <button class="btn btn-link" (click)="ignoreInvite()">
                        Ignore Invite
                    </button>
                </ng-container>

                <ng-container *ngIf="currentStatus === 'LOGIN'">
                    <div class="form-group">
                        <img
                            imgPath="/flyfreely-logo-black-text.png"
                            class="logo-img"
                        />
                        <div
                            *ngIf="error == null"
                            class="horizontal-container top-buffer"
                        >
                            <!-- <p>Loading...</p> -->
                            <p
                                *ngIf="
                                    error == null && inviteOrganisation != null
                                "
                            >
                                You have been invited to
                                <strong>{{ inviteOrganisation }}</strong>
                            </p>
                        </div>
                        <div *ngIf="error != null" class="top-buffer">
                            <p>{{ error }}</p>
                        </div>
                        <div class="form-group p-relative">
                            <button
                                class="login-btn-primary"
                                type="button"
                                [disabled]="working"
                                (click)="goToHome()"
                            >
                                <span>{{ loggedIn ? 'Home' : 'Login' }}</span>
                            </button>
                            <div
                                class="slope-background slope-btn-background md"
                            ></div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentStatus === 'SIGNUP'">
                    <div class="form-group">
                        <img
                            imgPath="/flyfreely-logo-black-text.png"
                            class="logo-img"
                        />
                        <div
                            *ngIf="error == null"
                            class="horizontal-container top-buffer"
                        >
                            <!-- <p>Loading...</p> -->
                            <p
                                *ngIf="
                                    error == null && inviteOrganisation != null
                                "
                            >
                                You have been invited to
                                <strong>{{ inviteOrganisation }}</strong>
                            </p>
                        </div>
                        <div
                            *ngIf="error != null"
                            class="horizontal-container top-buffer"
                        >
                            <p>{{ error }}</p>
                        </div>
                        <div class="form-group p-relative">
                            <button
                                class="login-btn-primary"
                                type="button"
                                [disabled]="working"
                                (click)="
                                    error != null ? goToLogin() : doLogin()
                                "
                            >
                                <span>Sign Up / Login</span>
                            </button>
                            <div
                                class="slope-background slope-btn-background md"
                            ></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="currentStatus === 'ERROR'">
                    <img
                        imgPath="/flyfreely-logo-black-text.png"
                        class="logo-img"
                    />
                    <div class="alert alert-danger">
                        The invite is invalid, or is meant for someone else. You
                        are currently logged in as {{ user.email }}.
                    </div>
                    <div><a (click)="goToHome()">Home</a></div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<footer class="text-center">
    <small
        ><a href="https://www.flyfreely.io/terms-of-use/" target="_blank"
            >Terms and conditions</a
        >
        &amp;
        <a href="https://www.flyfreely.io/privacy-policy/" target="_blank"
            >Privacy policy</a
        ></small
    >
</footer>
