import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    fadeInAnimation,
    fadeOutAnimation,
    slideInRightAnimation,
    slideOutRightAnimation
} from 'angular-animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegisterSideSheetService } from './register-side-sheet.service';

@Component({
    selector: 'registers-side-sheet',
    templateUrl: './registers-side-sheet.component.html',
    styleUrls: ['./registers-side-sheet.scss'],
    animations: [
        slideInRightAnimation(),
        slideOutRightAnimation(),
        fadeInAnimation(),
        fadeOutAnimation()
    ],
    providers: [RegisterSideSheetService]
})
export class RegistersSideSheet {
    @Input() registerConditionsHandler: any;
    @Input() expand: boolean;
    @Output() conditionsChanged: EventEmitter<void> = new EventEmitter();
    @Output() closeSideSheet: EventEmitter<void> = new EventEmitter();

    closeSheet: boolean = false;

    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private registerSideSheetService: RegisterSideSheetService) {}

    ngOnInit() {
        this.registerSideSheetService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.registerSideSheetService.conditionsChanged$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.onConditionsChanged());

        this.registerSideSheetService.setConditionHandler(
            this.registerConditionsHandler
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    onConditionsChanged() {
        this.conditionsChanged.emit();
        this.closeSheet = true;
    }

    save() {
        this.registerSideSheetService.save();
    }
}
