<div class="container-centred">
    <div class="welcomeblock_title" [innerHtml]="title"></div>
    <div class="welcomeblock_heading-container">
        <span
            class="welcomeblock_heading"
            [innerHtml]="welcomeblockheading"
        ></span>
        <a *ngIf="hasHelp" class="headingHelp" (click)="headingHelp()"
            >&nbsp;<span class="fa fa-question-circle"></span>
        </a>
    </div>
</div>
