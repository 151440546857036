import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AttachmentHandler,
    AuthorityService,
    AuthorityTypeDto,
    CraftAuthorityDto,
    CreateCraftAuthorityCommand,
    CreateOrganisationAuthorityCommand,
    CurrentAttachmentVersionDto,
    FEATURE_ENDORSEMENTS,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    UiCraftAuthorityDto,
    UiOrganisationAuthorityDto,
    UpdateAuthorityCommand,
    UserService,
    WorkTracker,
    fromLocalDate,
    hasFeatureFlag,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'authority-edit-dialogue',
    templateUrl: './authority-edit-dialogue.component.html'
})
export class AuthorityEditDialogue {
    @Input() managingOrganisationId: number;
    @Input() authorityType: AuthorityTypeDto;
    @Input() dependentOnAuthority: OrganisationAuthorityDto | CraftAuthorityDto;
    @Input() authority: OrganisationAuthorityDto | CraftAuthorityDto;
    @Input() endorsedEntityId: number;
    @Input() authorityService: AuthorityService<
        UiCraftAuthorityDto | UiOrganisationAuthorityDto,
        CreateCraftAuthorityCommand | CreateOrganisationAuthorityCommand,
        UpdateAuthorityCommand
    >;
    @Input() attachments: CurrentAttachmentVersionDto[];

    authorityId: number;

    attachmentsHandler: AttachmentHandler;

    formGroup: FormGroup;

    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    private isNew: boolean;

    hasEndorsements = false;

    hasRequiresApprovalPolicy = false;

    lockedMessage =
        'These fields are not editable. Please contact support if they are incorrect.';

    constructor(
        public modal: BsModalRef,
        modalOptions: ModalOptions,
        private logging: FlyFreelyLoggingService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private userService: UserService
    ) {
        modalOptions.closeInterceptor = () => this.preHide();
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        const controls: any = {
            startDate: new FormControl(undefined, [Validators.required]),
            appliedEndorsementIdList: new FormControl(undefined)
        };

        if (this.authorityType.hasExpiry) {
            controls.expiryDate = new FormControl(undefined);
        }

        if (this.authorityType.hasIdentifier) {
            if (this.authorityType.identifierValidationRegex != null) {
                controls.identifier = new FormControl(undefined, [
                    Validators.required,
                    Validators.pattern(
                        this.authorityType.identifierValidationRegex
                    )
                ]);
            } else {
                controls.identifier = new FormControl(undefined);
            }
        }

        this.hasRequiresApprovalPolicy =
            !this.authorityType.requiresApproval &&
            this.authorityType.hasWorkflow;

        if (this.hasRequiresApprovalPolicy) {
            controls.requiresApprovalPolicy = new FormControl(undefined, [
                Validators.required
            ]);
        }

        this.formGroup = new FormGroup(controls);

        if (this.authority == null || this.authority.id == null) {
            this.isNew = true;
            this.authorityService
                .newAuthority(
                    this.endorsedEntityId,
                    this.authorityType.id,
                    this.managingOrganisationId,
                    this.dependentOnAuthority?.id
                )
                .subscribe(authority => this.setAuthority(authority))
                .add(this.workTracker.createTracker());
        } else {
            this.isNew = false;

            this.setAuthority(this.authority);
        }

        this.hasEndorsements =
            this.authorityService.type === 'ORGANISATION' &&
            pipe(
                this.userService.findOrganisationForUser(
                    this.isNew
                        ? this.managingOrganisationId
                        : (<OrganisationAuthorityDto>this.authority)
                              .organisationId
                ),
                map(o => hasFeatureFlag(o, FEATURE_ENDORSEMENTS)),
                getOrElse(() => false)
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setAuthority(
        authority: OrganisationAuthorityDto | CraftAuthorityDto
    ) {
        this.authority = authority;
        this.authorityId = authority.id;

        this.attachmentsHandler = this.authorityService.attachmentHandler(
            authority.id,
            this.managingOrganisationId
        );
        this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);

        const value: any = {
            startDate: fromLocalDate(authority.startDate)
        };
        if (this.authorityType.hasExpiry) {
            value.expiryDate = fromLocalDate(authority.expiryDate);
        }

        if (this.authorityType.hasIdentifier) {
            value.identifier = authority.identifier;
        }

        if (this.hasRequiresApprovalPolicy) {
            value.requiresApprovalPolicy = (<OrganisationAuthorityDto>(
                authority
            )).requiresApprovalPolicy;
        }

        if (Object.keys(authority).includes('appliedEndorsementList')) {
            value.appliedEndorsementIdList = (<OrganisationAuthorityDto>(
                authority
            )).appliedEndorsementList.reduce(
                (acc, e) => acc.concat(e.values.map(v => v.id)),
                []
            );
        } else {
            value.appliedEndorsementIdList = null;
        }

        this.formGroup.setValue(value);
    }

    /**
     * called before hiding occurs
     */
    private preHide() {
        if (this.isNew) {
            const doneWorking = this.workTracker.createTracker();
            return this.authorityService
                .deleteAuthority(this.authorityId, this.managingOrganisationId)
                .finally(() => doneWorking());
        }
        return Promise.resolve();
    }

    save() {
        if (
            !this.authority.availableActions.canEdit &&
            this.authority.availableActions.canManage
        ) {
            this.saveForCanManage();
            return;
        }
        const value = this.formGroup.value;
        const forSaving: UpdateAuthorityCommand = {
            expiryDate: toLocalDate(value.expiryDate),
            startDate: toLocalDate(value.startDate),
            identifier: value.identifier,
            managingOrganisationId: this.managingOrganisationId,
            requiresApprovalPolicy: value.requiresApprovalPolicy,
            appliedEndorsementIdList: value.appliedEndorsementIdList
        };

        if (this.isNew) {
            this.authorityService
                .createAuthority(this.authorityId, {
                    ...forSaving,
                    createMissionWorkflow: true
                })
                .subscribe({
                    next: results => {
                        this.logging.success('Authority created');
                        this.isNew = false;
                        this.modal.hide();
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error creating authority: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        } else {
            this.authorityService
                .updateAuthority(this.authorityId, forSaving)
                .subscribe({
                    next: results => {
                        this.logging.success('Authority updated');
                        this.isNew = false;
                        this.modal.hide();
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error updating authority: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        }
    }

    saveForCanManage() {
        const value = this.formGroup.value;
        const forSaving: UpdateAuthorityCommand = {
            expiryDate: toLocalDate(value.expiryDate),
            startDate: toLocalDate(value.startDate),
            identifier: value.identifier,
            managingOrganisationId: this.managingOrganisationId,
            requiresApprovalPolicy: value.requiresApprovalPolicy,
            appliedEndorsementIdList: value.appliedEndorsementIdList
        };

        this.organisationAuthorityService
            .manageAuthority(this.authorityId, forSaving)
            .subscribe({
                next: results => {
                    this.logging.success('Authority updated');
                    this.isNew = false;
                    this.modal.hide();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating authority: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }
}
