import { HttpErrorResponse } from '@angular/common/http';
import { FieldError } from '../model/api';

/**
 * This file contains all the base error classes.
 */
export type FieldErrorTree =
    | FieldError
    | { [field: string]: FieldError | FieldErrorTree };

export function isFieldError(obj: any): obj is FieldError {
    return obj?.code != null;
}

/**
 * This is the base interface that errors returned by FlyFreely services will implement
 */
export class FlyFreelyError extends Error {
    message: string;
}

export class ValidationError extends FlyFreelyError {
    message = 'Request validation failed';

    constructor(public fields: { [field: string]: FieldErrorTree }) {
        super();
    }
}

export class InvalidOperation extends FlyFreelyError {
    constructor(
        public reason: string,
        public message: string,
        public code: string,
        public fields: { [field: string]: FieldErrorTree },
        public fieldErrors: { [field: string]: FieldErrorTree },
        public stateErrors: { [field: string]: FieldErrorTree }
    ) {
        super();
    }
}

export class OperationFailed extends FlyFreelyError {
    constructor(
        public reason: string,
        public message: string,
        public code: string,
        public fields: { [field: string]: FieldErrorTree }
    ) {
        super();
    }
}

export class CardDeclinedException extends FlyFreelyError {
    constructor(
        public declineCode: string,
        public message: string,
        public code: string,
        public fields: { [field: string]: FieldErrorTree }
    ) {
        super();
    }
}

export class NoAccess extends FlyFreelyError {
    constructor(
        public declineCode: string,
        public message: string,
        public code: string,
        public fields: { [field: string]: FieldErrorTree }
    ) {
        super();
    }
}

export class SessionExpired extends FlyFreelyError {
    message = 'Session expired';
}

export class Unauthorised extends FlyFreelyError {
    message = 'Invalid username or password';
}

export class OperationForbidden extends FlyFreelyError {
    message = 'Operation forbidden';
}

export class NotFound extends FlyFreelyError {
    message = 'Not found';
}

export class TemplateError extends FlyFreelyError {
    constructor(public message: string, public line: number) {
        super();
    }
}

export class UnknownError extends FlyFreelyError {
    message: string;
    constructor(public response: HttpErrorResponse) {
        super();
        this.message = response.message;
    }
}
