import { Component, OnInit } from '@angular/core';
import {
    AuthorityTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LoggedInUser,
    OnboardingAuthorityGroup,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    OrganisationService,
    PersonDto,
    SimpleAirspaceJurisdictionDto,
    UserService,
    OnboardingService,
    OnboardingPhases,
    UserStatus,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'personal-primary',
    templateUrl: './personal-primary.component.html'
})
export class PersonalPrimaryComponent implements OnInit {
    currentUser: PersonDto;
    availableJurisdictions: SimpleAirspaceJurisdictionDto[];
    authorityGroups: OnboardingAuthorityGroup[];
    organisationId: number;
    hasRequestedSales: number[];

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private obsds: OnboardingData,
        private userService: UserService,
        private organisationService: OrganisationService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private onboardingService: OnboardingService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.userService.userChange$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(c => c.type === UserStatus.LOGGED_IN)
            )
            .subscribe((resp: LoggedInUser) => {
                this.currentUser = resp.currentUser;
                this.organisationId = resp.currentUser.personalOrganisationId;
            });
        this.organisationAuthorityService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(resp => {
                this.refreshAuthorities();
            });

        if (this.currentUser) {
            this.findJurisdictions();
            this.refreshAuthorities();
            this.hasRequestedSales = this.obsds.requestedPersonalSales;
        }
    }

    findJurisdictions() {
        this.organisationService
            .findOrganisationsForPerson(this.currentUser.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(orgs => {
                const personalOrg = orgs.find(
                    org => org.id === this.currentUser.personalOrganisationId
                );
                this.availableJurisdictions = personalOrg.activeJurisdictions;
            });
    }

    refreshAuthorities() {
        const doneWorking = this.workTracker.createTracker();

        this.authorityGroups = [];
        this.onboardingService
            .findAuthoritiesForOnboarding(
                this.organisationId,
                this.organisationId,
                OnboardingPhases.INDIVIDUAL_RULES,
                this.obsds.personalJurisdictionId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                authorities => {
                    this.authorityGroups = authorities;
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while fetching authorities: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    hasAuthority(authorityGroup: OrganisationAuthorityGroup) {
        if (authorityGroup.authorities.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    contactSales(authorityGroup: OnboardingAuthorityGroup) {
        const doneWorking = this.workTracker.createTracker();

        this.userService
            .contactSales({
                message: `I would like more information on the following authority: ${authorityGroup.name}`
            })
            .subscribe(
                () => {
                    this.hasRequestedSales.push(authorityGroup.id);
                    this.obsds.requestedPersonalSales = this.hasRequestedSales;
                    this.logging.success(
                        `A message has been sent to our sales team. They will be in contact soon.`
                    );
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while contacting sales: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    hasContactedSales(authorityGroup: OnboardingAuthorityGroup) {
        return this.hasRequestedSales.find(id =>
            id === authorityGroup.id ? true : false
        );
    }

    previousScreen() {
        this.obsds.backToPersonalNonPrimary();
    }

    nextScreen() {
        this.obsds.personalPrimaryCompleted();
    }

    addAuthority(authorityGroup: AuthorityTypeDto) {
        if (authorityGroup.issuingMechanism !== 'SELF_REPORTED') {
            return;
        }

        this.authorityDialoguesService.showAuthorityEdit(
            this.organisationId,
            authorityGroup,
            null,
            this.organisationId,
            this.organisationAuthorityService
        );
    }

    editAuthority(
        authorityGroup: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        if (authorityGroup.issuingMechanism !== 'SELF_REPORTED') {
            return;
        }
        this.authorityDialoguesService.showAuthorityEdit(
            this.organisationId,
            authorityGroup,
            authority,
            this.organisationId,
            this.organisationAuthorityService
        );
    }

    remove(authority: OrganisationAuthorityDto) {
        this.organisationAuthorityService.deleteAuthority(
            authority.id,
            this.organisationId
        );
    }

    // authoritySelected(existing: boolean, authorityGroup: AuthorityTypeDto) {
    //     if (existing) {
    //         this.editAuthority(authorityGroup, authorityGroup.authorities[0])
    //     }

    // }
}
