import { Component, Input } from '@angular/core';

/**
 * A version of the enhanced help that reads all its details from props, and is always active.
 */
@Component({
    selector: 'static-help',
    templateUrl: `./static-help.component.html`,
    styleUrls: ['./styles.scss']
})
export class StaticHelp {
    @Input() helpTitle: string;
    @Input() helpText: string;
    @Input() compact?: boolean;
}
