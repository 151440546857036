import { Component, Input } from '@angular/core';
import {
    AirspaceNotification,
    airspaceOperatorTypesLookup
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'airspace-notification',
    templateUrl: './airspace-notification.component.html',
    // FIXME: check if this is the best way to apply these styles or if they should live somewhere else
    styleUrls: ['../../../../sidebar-activity-stream/src/lib/styles.scss']
})
export class AirspaceNotificationCard {
    @Input() notification: AirspaceNotification;

    day: string;
    month: string;
    operatorTypes = airspaceOperatorTypesLookup;

    constructor(private angulartics2: Angulartics2) {}

    goToUrl() {
        this.angulartics2.eventTrack.next({
            action: 'follow-notification-link',
            properties: {
                category: 'airspace-check',
                label: this.notification.title
            }
        });

        window.open(this.notification.url);
    }
}
