import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import {
    ReferralReport,
    ReferralSourceStatistics,
    ReferralSourceSummary,
    SaveReferralCommand
} from '../model/api';

@Injectable({
    providedIn: 'root'
})
export class ReferralService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findSourcesByOrganisation(organisationId: number) {
        return this.http.get<ReferralSourceSummary[]>(
            `${this.baseUrl}/webapi/referrals/organisations/${organisationId}`
        );
    }

    findPromoterSourcesByOrganisation(organisationId: number) {
        return this.http.get<ReferralSourceSummary[]>(
            `${this.baseUrl}/webapi/referrals/organisations/${organisationId}/promoter`
        );
    }

    findSourceStatistics(sourceId: number) {
        return this.http.get<ReferralSourceStatistics>(
            `${this.baseUrl}/webapi/referrals/${sourceId}/statistics`
        );
    }

    findSourceReport(sourceId: number) {
        return this.http.get<ReferralReport[]>(
            `${this.baseUrl}/webapi/referrals/${sourceId}/report`
        );
    }

    saveUserReferral(referral: SaveReferralCommand) {
        return this.http.put<void>(
            `${this.baseUrl}/publicapi/referrals/save`,
            referral
        );
    }
}
