/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationActions } from './locationActions';


/**
 * The location metadata including available actions
 */
export interface LocationDto { 
    /**
     * The ID of the saved location
     */
    id: number;
    /**
     * The user provided name for the location
     */
    name: string;
    availableActions: LocationActions;
    /**
     * The ID of the organisation that owns the locations
     */
    organisationId: number;
    /**
     * ${swagger.Location.type}
     */
    type: LocationDto.Type;
}
export namespace LocationDto {
    export type Type = 'MISSION' | 'TEMPLATE';
    export const Type = {
        MISSION: 'MISSION' as Type,
        TEMPLATE: 'TEMPLATE' as Type
    };
}


