import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {
    AerodromeContactDto,
    AerodromeDetailsDto,
    AerodromeFrequencyDto,
    ContactDto,
    FEATURE_MISSION_AERODROMES,
    JurisdictionService,
    NotamDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { distance } from '@turf/distance';
import { Point } from 'geojson';
import { getMeasurementDescriptionForKms } from 'libs/map/src/lib/utils';
import { Subject } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    switchMap,
    takeUntil
} from 'rxjs/operators';
import { InformationSearchComponent } from '../contact-pill/search/info-search.component';

export interface AerodromeFrequency extends AerodromeFrequencyDto {
    aerodromeCode: string;
    selected: boolean;
}

export interface AerodromeContact extends AerodromeContactDto {
    aerodromeCode: string;
    aerodromeName: string;
    selected: boolean;
}

interface SelectableAerodrome extends AerodromeDetailsDto {
    selected: boolean;
}

interface SelectableNotam extends NotamDto {
    selected: boolean;
}

const rangeOptions = [
    {
        label: getMeasurementDescriptionForKms(3),
        value: 3000
    },
    {
        label: getMeasurementDescriptionForKms(5),
        value: 5000
    },
    {
        label: getMeasurementDescriptionForKms(10),
        value: 10000
    },
    {
        label: getMeasurementDescriptionForKms(25),
        value: 25000
    },
    {
        label: getMeasurementDescriptionForKms(50),
        value: 50000
    }
];

@Component({
    selector: 'aerodrome-search',
    templateUrl: './aerodrome-search-dialogue.component.html',
    styleUrls: ['../search-dialogues.scss'],
    host: {
        class: 'fill-parent container-with-footer'
    }
})
export class AerodromeSearchDialogue {
    @Input() existingAerodromes: ContactDto[];
    @Input() existingFrequencies: ContactDto[];
    @Input() existingContacts: ContactDto[];
    @Input() defaultAerodromeCode: string;
    @Input() locationCentre: GeoJSON.Point;
    @Output() close = new EventEmitter<void>();
    @Output() addAerodrome = new EventEmitter<AerodromeDetailsDto>();
    @Output() addFrequency = new EventEmitter<AerodromeFrequency>();
    @Output() addContact = new EventEmitter<AerodromeContact>();
    @Output() removeAerodrome = new EventEmitter<AerodromeDetailsDto>();
    @Output() removeFrequency = new EventEmitter<AerodromeFrequency>();
    @Output() removeContact = new EventEmitter<AerodromeContact>();

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;

    private searchSubject = new Subject<string>();
    private rangeSubject = new Subject<number>();

    aerodromes: AerodromeDetailsDto[];
    nearbyAerodromes: AerodromeDetailsDto[];
    aerodrome: SelectableAerodrome;
    frequencies: AerodromeFrequency[];
    contacts: AerodromeContact[];

    displayNearbyAerodromes = true;

    range: number = 10000;
    rangeOptions = rangeOptions;

    aerodromesFeature = [FEATURE_MISSION_AERODROMES];

    @ViewChild(InformationSearchComponent)
    private search: InformationSearchComponent;

    constructor(private jurisdictionService: JurisdictionService) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.openDefaultAirport();

        this.rangeSubject
            .pipe(
                filter(() => this.locationCentre != null),
                takeUntil(this.ngUnsubscribe$),
                distinctUntilChanged(),
                switchMap(range =>
                    this.jurisdictionService.findNearbyAerodromes(
                        range,
                        this.locationCentre.coordinates[0],
                        this.locationCentre.coordinates[1]
                    )
                )
            )
            .subscribe(aerodromes => {
                this.nearbyAerodromes = aerodromes;
            });

        this.searchSubject
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(search => search != null && search.length > 0),
                switchMap(search =>
                    this.jurisdictionService.findAerodromes(search)
                )
            )
            .subscribe(results => {
                this.aerodromes = results.slice(0, 5);
            });

        this.updateRange();
    }

    ngOnDestroy() {
        this.searchSubject.complete();
        this.rangeSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findAirports(search: string) {
        this.searchSubject.next(search);
    }

    updateRange() {
        this.rangeSubject.next(this.range);
    }

    calculateDistance(point: Point) {
        if (point == null) {
            return '';
        }
        return `${(distance(this.locationCentre, point) * 0.539957).toFixed(
            1
        )}nm`;
    }

    openDefaultAirport() {
        if (this.defaultAerodromeCode == null) {
            return;
        }

        this.jurisdictionService
            .findAerodromesByCode(this.defaultAerodromeCode)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(airport => this.selectAirport(airport))
            .add(this.workTracker.createTracker());
    }

    displayNearby() {
        this.aerodrome = null;
        this.frequencies = null;
        this.contacts = null;
        this.displayNearbyAerodromes = true;
    }

    selectAirport(aerodrome: AerodromeDetailsDto) {
        this.displayNearbyAerodromes = false;
        this.search.clear();

        this.aerodrome = {
            ...aerodrome,
            selected: this.existingAerodromes.filter(
                a => aerodrome.code === a.identifier
            ).length
                ? true
                : false
        };
        this.aerodromes = [];
        this.frequencies = aerodrome.frequencies.map(f => ({
            abbreviation: f.abbreviation,
            name: f.name,
            frequency: f.frequency,
            id: f.id,
            aerodromeCode: aerodrome.code,
            selected: this.existingFrequencies.filter(
                freq => freq.value === f.frequency
            ).length
                ? true
                : false
        }));

        this.contacts = aerodrome.contacts.map(c => ({
            type: c.type,
            contact: c.contact,
            aerodromeCode: aerodrome.code,
            aerodromeName: aerodrome.name,
            id: c.id,
            selected: this.existingContacts.filter(
                contact => contact.value === c.contact
            ).length
                ? true
                : false
        }));
    }

    submitAerodrome(aerodrome: AerodromeDetailsDto) {
        this.addAerodrome.emit(aerodrome);
        this.aerodrome.selected = true;
    }

    submitFrequency(frequency: AerodromeFrequency) {
        this.addFrequency.emit(frequency);
        this.frequencies.map(f => {
            if (f === frequency) {
                f.selected = true;
            }
        });
    }

    submitContact(contact: AerodromeContact) {
        this.addContact.emit(contact);
        this.contacts.map(c => {
            if (c === contact) {
                c.selected = true;
            }
        });
    }

    deleteAerodrome(aerodrome: AerodromeDetailsDto) {
        this.removeAerodrome.emit(aerodrome);
        this.aerodrome.selected = false;
    }

    deleteFrequency(frequency: AerodromeFrequency) {
        this.removeFrequency.emit(frequency);
        this.frequencies.map(f => {
            if (f === frequency) {
                f.selected = false;
            }
        });
    }

    deleteContact(contact: AerodromeContact) {
        this.removeContact.emit(contact);
        this.contacts.map(c => {
            if (c === contact) {
                c.selected = false;
            }
        });
    }

    closeModal() {
        this.close.emit();
        this.aerodromes = [];
    }
}
