import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { NgSelectModule } from '@ng-select/ng-select';
import { ngfModule } from 'angular-file';
import { Angulartics2Module } from 'angulartics2';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { BatteryModule } from 'libs/batteries/src/lib/batteries.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FeaturesModule } from 'libs/features/src';
import { InlineModalModule } from 'libs/inline-modal/src/lib/inline-modal.module';
import { MapModule } from 'libs/map/src/lib/map.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableModule } from 'ngx-easy-table';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ToastrModule } from 'ngx-toastr';
import { AssignFlightLog } from './assign-flight-log/assign-flight-log.component';
import { AssignRpa } from './assign-rpa/assign-rpa.component';
import { FlightAnalyser } from './flight-analyser/flight-analyser-dialogue.component';
import { FlightLogList } from './flight-log-list.component';
import { FindCurrentConformanceResultPipe } from './flight-log-list/find-current-conformance-result.pipe';
import { FlightConformanceIcon } from './flight-log-list/flight-conformance-icon.component';
import { FlightLogListDialogue } from './flight-log-list/flight-log-list-dialogue.component';
import { FlightLogUploader } from './flight-log-uploader.component';
import { FlightLogsDialoguesService } from './flight-logs-dialogues.service';
import { FormatFlightLogPipe } from './formatFlightLog.pipe';
import { LocationDistanceClassPipe } from './location-distance-class.pipe';
import { SelectFlightLogs } from './select-flight-logs/select-flight-logs.component';
import { AutomaticMissionCreationSettingsComponent } from './settings/automatic-mission-creation-settings.component';
import { DjiSyncSettings } from './settings/dji-sync.component';
import { DroneDeploySettings } from './settings/drone-deploy.component';
import { FlightLogCollectionAlert } from './settings/flight-log-collection-alert.component';
import { FlightLogSettings } from './settings/flight-log-settings.component';
import { SkydioSettings } from './settings/skydio.component';
import { SuggestedMissionAssignment } from './suggested-mission-assignment.component';
import { SuggestedMissionMatchClassPipe } from './suggested-mission-match-class.pipe';
import { TimeDistanceClassPipe } from './time-distance-class.pipe';
import { RouterModule } from '@angular/router';

/**
 * The Table module provides several table implementations that all
 * conform to the same interface (with varying feature completeness).
 *
 * In general, the calling code is responsible for ensuring the provided
 * data is in the correct format (e.g., timestamps are Moment objects, not string),
 * but they can provide custom formatters to allow the table implementation
 * manage the display format of the value.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild([]),
        TooltipModule,
        ButtonsModule,
        NgxLoadingModule,
        ModalModule.forChild(),
        SharedUiModule,
        ValidationModule,
        InlineModalModule,
        FlyFreelyTableModule,
        BsDatepickerModule,
        PopoverModule,
        MaterialModule,
        MapModule,
        NgxLoadingModule,
        NgxMapboxGLModule.withConfig({
            accessToken:
                'pk.eyJ1IjoibnNpbSIsImEiOiJjamRvNmZ1b3gwajNkMndwcXBvb2J5NnhxIn0.Nm6YB1avHyif3piBnfWSeA'
        }),
        NgSelectModule,
        ToastrModule,
        ngfModule,
        TableModule,
        BatteryModule,
        AnalyticsModule,
        Angulartics2Module,
        EnhancedHelpModule,
        FeaturesModule,
        EditableModule
    ],
    providers: [FlightLogsDialoguesService],
    declarations: [
        FlightAnalyser,
        FlightLogListDialogue,
        AssignFlightLog,
        FlightLogList,
        SelectFlightLogs,
        FlightLogUploader,
        AssignRpa,
        FormatFlightLogPipe,
        FlightLogSettings,
        LocationDistanceClassPipe,
        TimeDistanceClassPipe,
        SuggestedMissionMatchClassPipe,
        SuggestedMissionAssignment,
        FlightLogCollectionAlert,
        FlightConformanceIcon,
        FindCurrentConformanceResultPipe,
        DjiSyncSettings,
        DroneDeploySettings,
        SkydioSettings,
        AutomaticMissionCreationSettingsComponent
    ],
    exports: [
        AssignFlightLog,
        FlightLogListDialogue,
        FlightLogList,
        SelectFlightLogs,
        FormatFlightLogPipe
    ]
})
export class FlightLogsModule {}
