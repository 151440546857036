import { Pipe, PipeTransform } from '@angular/core';
import { CraftDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'rpaStatusClass' })
export class RpaStatusClassPipe implements PipeTransform {
    transform(status: CraftDto.Status) {
        switch (status) {
            case CraftDto.Status.SERVICEABLE:
                return 'rpa-status-serviceable';
            case CraftDto.Status.UNSERVICEABLE:
                return 'rpa-status-unserviceable';
            case CraftDto.Status.RETIRED:
                return 'rpa-status-retired';
            case CraftDto.Status.UNDER_MAINTENANCE:
                return 'rpa-status-under-maintenance';
            default:
                return `rpa-status-${status.toLowerCase()}`;
        }
    }
}
