import { Injectable } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    GqlFilterField,
    GqlSortField,
    MissionDetailsDto,
    MissionService,
    MissionSummaryDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { ColumnSortPreferences } from 'libs/flyfreely-table/src/lib/interfaces';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class LinkTestFlightDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private missionsSource = new ReplaySubject<MissionSummaryDto[]>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);
    private hasMissionsSource = new ReplaySubject<boolean>(1);

    working$ = this.workingSource.asObservable();
    missions$ = this.missionsSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();
    hasMissions$ = this.hasMissionsSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionService: MissionService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.missionsSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.hasMissionsSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findMissions(
        page: number,
        limit: number,
        sorting: ColumnSortPreferences,
        filters: GqlFilterField[],
        organisationId: number,
        authorityIds?: number[],
        orOrganisationId?: number,
        status?: MissionSummaryDto.Status[]
    ) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const doneWorking = this.workTracker.createTracker();

        // this.subscriptionTypes$ is used here to ensure it has a value and prevent a race condition.
        const sortFields: GqlSortField = {
            field: sorting?.column ?? null,
            order: sorting?.ascending ? 'ASC' : 'DESC'
        };
        this.missionService
            .findMissionSummaries(
                organisationId,
                {
                    page: page,
                    pageSize: limit,
                    sortFields: sorting != null ? [sortFields] : [],
                    filters: filters
                },
                null,
                authorityIds,
                orOrganisationId,
                status
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                missions => {
                    const missionDetails: MissionDetailsDto[] = missions.results.map(
                        r => ({
                            ...r,
                            locationName: r.location?.name,
                            organisationName: r.organisation?.name,
                            missionCrewDetails: r.missionCrew.map((p: any) => ({
                                person: p.person,
                                role: p.missionRole
                            })),
                            craftNicknames: r.crafts?.map(
                                (c: any) => c?.nickname
                            ),
                            missionType: r.missionOperationType,
                            missionWorkflowVersion: r.workflowVersion?.workflow,
                            missionApproval: r.missionApprovals
                                .filter(
                                    (a: any) =>
                                        a.status === 'DRAFT' ||
                                        a.status === 'PENDING' ||
                                        a.status === 'BEING_REVIEWED' ||
                                        a.status === 'APPROVED' ||
                                        a.status === 'ACCEPTED'
                                )
                                .slice(-1)[0],
                            flightConformanceResultList:
                                r.flightConformanceResultList
                        })
                    );
                    this.totalItemsSource.next(missions.count);
                    this.currentPageSource.next(page);
                    this.missionsSource.next(missionDetails);
                    if (missions.count > 0) {
                        this.hasMissionsSource.next(true);
                    }
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                    this.missionsSource.next([]);
                    this.totalItemsSource.next(0);
                    this.currentPageSource.next(0);
                    doneWorking();
                }
            );
    }
}
