import { Directive, Input, Optional } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EnhancedHelpService } from './enhanced-help.service';

/**
 * This directive is used with the ngx-bootstrap tooltip directive to
 * allow the tooltip text to be set via the enhanced help system.
 * If the screen name needs to be set it can be via the `screenName` attribute
 * but having it set by the screen analytics is better.
 *
 * `<th tooltip placement="top" enhancedHelp="schedule-triggers-type">Type</th>`
 */
@Directive({
    selector: '[tooltip][enhancedHelp]'
})
export class EnhancedHelpTooltip {
    @Input()
    enhancedHelp: string;
    @Input() screenName: string;
    /**
     * Is this enhanced help directive optional? Useful when we are putting a tooltip on a reusable component
     * that might not be inside a screenAnalytics directive.
     */
    @Input() enhancedHelpOptional = false;

    /**
     * Should the tooltip be displayed when the enhanced help switch is switched on?
     */
    @Input() enhancedHelpShowOnActivate = true;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private tooltip: TooltipDirective,
        private enhancedHelpService: EnhancedHelpService,
        private logging: FlyFreelyLoggingService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {
        // Don't run the directive if the screen analytics isn't available
        if (screenAnalytics != null && screenAnalytics.helpActive$) {
            screenAnalytics.helpActive$
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    filter(_ => this.enhancedHelpShowOnActivate)
                )
                .subscribe(active => {
                    if (active) {
                        tooltip.show();
                    } else {
                        tooltip.hide();
                    }
                });
        }
    }

    ngOnInit() {
        this.getHelpObject();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    getHelpObject() {
        const screenName =
            this.screenName ?? this.screenAnalytics?.screenAnalytics;

        if (screenName == null) {
            if (this.enhancedHelpOptional) {
                return;
            }
            throw new Error(`screenName not specified and can't be inferred`);
        }

        this.enhancedHelpService
            .findByScreenAndComponent(screenName, this.enhancedHelp)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => (this.tooltip.tooltip = results.helpText),
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            );
    }
}
