import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    Cancellable,
    EquipmentDto,
    EquipmentService,
    ExclusiveControlService,
    FEATURE_ADDITIONAL_MAINTENANCE,
    FEATURE_SCHEDULED_MAINTENANCE,
    findOrganisation,
    hasFeatureFlag,
    Organisation,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { equipmentMaintenanceServiceProvider } from './equipment-maintenance.service';
import { ResourceMaintenanceService } from 'libs/maintenance/src/lib/resource-maintenance.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

enum Editing {
    NONE = 'NONE',
    DETAILS = 'DETAILS',
    MAINTENANCE = 'MAINTENANCE',
    NOTES = 'NOTES'
}

@Component({
    selector: 'equipment-dialogue',
    templateUrl: './equipment-dialogue.component.html',
    providers: [ExclusiveControlService, equipmentMaintenanceServiceProvider]
})
export class EquipmentDialogue implements OnInit, OnDestroy {
    @Input() canEdit: boolean;
    @Input() organisation: Organisation;
    @Input() equipmentId: number;

    @ViewChild('equipmentEdit', { static: false }) equipmentEdit: Cancellable;

    equipment: EquipmentDto;

    isEditing: Editing = Editing.NONE;

    hasScheduledMaintenance: boolean;

    maintenanceScheduleFeature = [FEATURE_SCHEDULED_MAINTENANCE];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<EquipmentDialogue>,
        modalOptions: ModalOptions,
        private equipmentService: EquipmentService,
        private equipmentMaintenanceService: ResourceMaintenanceService,
        private userService: UserService
    ) {
        modalOptions.closeInterceptor = () => {
            switch (this.isEditing) {
                case Editing.DETAILS:
                    this.onEditCancelled();
                    return Promise.reject();
                case Editing.MAINTENANCE:
                    this.equipmentEdit.cancel();
                    return Promise.reject();
                case Editing.NONE:
                    return Promise.resolve();
            }
        };
    }

    ngOnInit() {
        this.refreshEquipments();
        this.equipmentService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshEquipments();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshEquipments() {
        this.equipmentService
            .findById(this.equipmentId, this.organisation.id)
            .toPromise()
            .then(equipment => {
                this.equipment = equipment;
                this.refreshPermissions();

                this.equipmentMaintenanceService.setup(this.equipmentId);
            });
    }

    refreshPermissions() {
        const maybeOrganisation = findOrganisation(
            this.userService.findUsersOrganisations(),
            this.equipment.organisationId
        );

        this.hasScheduledMaintenance = pipe(
            maybeOrganisation,
            map(
                organisation =>
                    hasFeatureFlag(
                        organisation,
                        FEATURE_SCHEDULED_MAINTENANCE
                    ) &&
                    hasFeatureFlag(organisation, FEATURE_ADDITIONAL_MAINTENANCE)
            ),
            getOrElse(() => false)
        );
    }

    editEquipment() {
        this.isEditing = Editing.DETAILS;
    }

    editEquipmentNotes() {
        this.isEditing = Editing.NOTES;
    }

    onEditCancelled() {
        this.isEditing = Editing.NONE;
    }

    onUpdateEquipment(updatedEquipment: EquipmentDto) {
        Object.assign(this.equipment, updatedEquipment);
    }

    onSelectMaintenance() {
        this.isEditing = Editing.MAINTENANCE;
    }

    onEquipmentDeleted() {
        this.modal.hide();
    }

    close() {
        this.modal.hide();
    }
}
