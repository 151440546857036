<enhanced-help
    componentName="register-status"
    [compact]="true"
></enhanced-help>
<div class="inner-container container-scroll" *ngIf="registers != null">
    <ng-container *ngFor="let register of registers">
        <h4
            class="section-heading"
            [ngStyle]="{ 'margin-bottom: 0': register.subtitle.length }"
        >
            <span>
                {{ register.heading }}
            </span>
            <div></div>
            <a
                *ngIf="canEdit && canAdminOrganisation && register.canView"
                (click)="!working ? showRegister(register) : null"
            >
                View Register
            </a>
        </h4>
        <p *ngIf="register.subtitle.length" class="subtitle">
            {{ register.subtitle }}
        </p>
        <div
            class="table-container table-borderless"
            *ngFor="let row of register.rows"
        >
            <table class="table">
                <thead>
                    <tr>
                        <th class="status-column">Status</th>
                        <th *ngFor="let column of row.columns">
                            {{ column.heading }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="padding: 10px 0" class="status-column">
                            <register-status-display
                                [status]="row.status"
                            ></register-status-display>
                        </td>
                        <td *ngFor="let column of row.columns">
                            <span
                                *ngIf="column.check == true"
                                class="fa fa-check green-tick"
                            >
                            </span>
                            <span
                                *ngIf="column.check == false"
                                class="red-cross"
                            >
                                X
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>
<div
    class="alert alert-info"
    role="alert"
    *ngIf="registers != null && registers.length == 0 && !working"
>
    This RPA has no current registers to display.
</div>
<loading-indicator [isLoading]="working"></loading-indicator>
