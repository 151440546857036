/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.54-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * All values for an operating condition category
 */
export interface OperatingCategoryValuesDtoObject { 
    type?: OperatingCategoryValuesDtoObject.Type;
    values?: Array<any>;
}
export namespace OperatingCategoryValuesDtoObject {
    export type Type = 'LOS' | 'TIME_OF_DAY' | 'RPA_MTOW' | 'RPA_CATEGORY' | 'COMPOSITE';
    export const Type = {
        LOS: 'LOS' as Type,
        TIME_OF_DAY: 'TIME_OF_DAY' as Type,
        RPA_MTOW: 'RPA_MTOW' as Type,
        RPA_CATEGORY: 'RPA_CATEGORY' as Type,
        COMPOSITE: 'COMPOSITE' as Type
    };
}



