import { Component, Input } from '@angular/core';
import {
    FlightConformancePoliciesService,
    FlightConformancePolicyDto,
    FlightConformanceRuleDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityService,
    PersonsOrganisationDto,
    SimpleAuthorityTypeDto,
    StateUpdateCommand,
    WorkTracker,
    mutateState,
    patchableState,
    setState
} from '@flyfreely-portal-ui/flyfreely';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, scan, share, shareReplay, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'flight-conformance-setup',
    templateUrl: './flight-conformance-setup.component.html',
    providers: [WorkTracker]
})
export class FlightConformanceSetup {
    @Input()
    organisation: PersonsOrganisationDto;

    public working: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    private policyListSource = new ReplaySubject<
        StateUpdateCommand<FlightConformancePolicyDto[]>
    >(1);
    private policyList$ = this.policyListSource
        .pipe<FlightConformancePolicyDto[]>(
            scan((acc, command) => patchableState(acc, command), [])
        )
        .pipe(shareReplay());

    nonDefaultPolicyList$ = this.policyList$.pipe(
        map(policies => policies.filter(p => p.authorityType != null))
    );

    defaultPolicy$ = this.policyList$.pipe(
        map(policies => policies.find(p => p.authorityType == null))
    );

    selectedAuthorityType: SimpleAuthorityTypeDto;

    authorityTypeListSource = new ReplaySubject<
        { id: number; name: string; count: number }[]
    >();

    /**
     * Observable list of authority types that are not in use
     */
    authorityTypeList$ = combineLatest([
        this.policyList$,
        this.authorityTypeListSource
    ]).pipe(
        map(([policyList, authorityTypeList]) => {
            const policyAuthorityIds = new Set(
                policyList
                    .filter(p => p.authorityType != null)
                    .map(p => p.authorityType.id)
            );
            return authorityTypeList.filter(a => !policyAuthorityIds.has(a.id));
        }),
        share()
    );

    constructor(
        private flightConformancePoliciesService: FlightConformancePoliciesService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private logging: FlyFreelyLoggingService,
        private workTracker: WorkTracker
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshPolicyList();

        this.refreshAuthorityTypeList();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPolicyList() {
        this.flightConformancePoliciesService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                policyList => this.policyListSource.next(setState(policyList)),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing policy list: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    private refreshAuthorityTypeList() {
        this.organisationAuthorityService
            .findAuthorities(this.organisation.id, this.organisation.id)
            .subscribe(authorityGroups =>
                this.authorityTypeListSource.next(
                    authorityGroups
                        .filter(a => a.authorities.length > 0)
                        .filter(a => a.isPrimary)
                        .map(a => ({
                            id: a.id,
                            name: a.name,
                            count: a.authorities.length
                        }))
                )
            );
    }

    setupPolicy(authorityType: SimpleAuthorityTypeDto) {
        this.flightConformancePoliciesService
            .create({
                organisationId: this.organisation.id,
                authorityTypeId: authorityType.id
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                policy => this.refreshPolicyList(),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while creating policy: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    setupDefaultPolicy() {
        this.flightConformancePoliciesService
            .createDefaultPolicy({
                organisationId: this.organisation.id
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                policy => this.refreshPolicyList(),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while setting up default policy: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    updatePolicy(policy: FlightConformancePolicyDto) {
        this.policyListSource.next(
            mutateState(orig =>
                orig.map(o => (o.id === policy.id ? policy : o))
            )
        );
    }

    updateRule(policyRule: [number, FlightConformanceRuleDto]) {
        this.policyListSource.next(
            mutateState(orig =>
                orig.map(o =>
                    o.id !== policyRule[0]
                        ? o
                        : {
                              ...o,
                              ruleList: o.ruleList.map(r =>
                                  r.ruleId !== policyRule[1].ruleId
                                      ? r
                                      : policyRule[1]
                              )
                          }
                )
            )
        );
    }
}
