import { Injectable } from '@angular/core';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ConfirmationDialogue } from './confirmation-dialogue/confirmation-dialogue.component';
import { FormlyDialogueController } from './formly-dialogue/formly-dialogue.component';

@Injectable()
export class CommonDialoguesService {
    constructor(
        private modalService: BsModalService,
        private logging: FlyFreelyLoggingService
    ) {}

    /**
     * This is a drop-in replacement for the FlyFreelyUI.showConfirmationDialogue that uses
     * ngx-bootstrap modals.
     *
     * The returned promise will resolve with the value resolved from the `fn` function, or reject if the user closed the modal.
     *
     * If the function `fn` rejects then the modal will not close.
     *
     * @param title the title of the dialogue
     * @param message the message to display
     * @param action the name of the action
     * @param fn the function to run to complete the action
     */
    showConfirmationDialogue(
        title: string,
        message: string,
        action: string,
        fn: () => PromiseLike<any>,
        dontAskAgainFunction?: (value: boolean) => PromiseLike<boolean>
    ) {
        return wrapDialogueInternal<any>(
            this.modalService,
            ConfirmationDialogue,
            'modal-md',
            {
                title,
                message,
                action,
                fn,
                dontAskAgainFunction: dontAskAgainFunction
            }
        );
    }

    /**
     * Show a dialogue that uses a Formly Form to display or collect data. Its full capability is
     * to display a view of a record, and then switch to an edit mode, and back again. This behaviour can
     * be controlled using the parameters.
     * @param title the title of the dialogue
     * @param saveAction the label on the save action
     * @param canEdit is there an edit action available
     * @param onlyEdit is the data only displayed in an edit mode, and the dialogue closed when saved
     * @param fields the formly field config
     * @param data the data to display
     * @param saveFunction a promise that saves the data
     * @param modalClass the modal class, to control how big or the style of the modal
     * @param screenAnalytics what screen analytics identifier to use
     * @param destructiveAction if present then show a destructive action that uses the label and function described in the object
     * @returns a promise that resolves with the value provided to the `close()` function, or reject if the user closed the modal.
     */
    showFormlyDialogue<T, R>(
        title: string,
        saveAction: string,
        canEdit: boolean,
        onlyEdit: boolean,
        fields: FormlyFieldConfig[],
        data: Partial<T>,
        saveFunction: (data: T) => PromiseLike<R> = (data: unknown) =>
            Promise.resolve(<R>data),
        modalClass = 'modal-md',
        screenAnalytics: string = null,
        destructiveAction: {
            label: string;
            fn: () => PromiseLike<R>;
        } = undefined
    ) {
        return wrapDialogueInternal<R>(
            this.modalService,
            FormlyDialogueController,
            modalClass,
            {
                title,
                canEdit,
                onlyEdit,
                fields,
                data,
                saveFunction,
                action: saveAction,
                screenAnalytics,
                destructiveAction
            }
        );
    }
}

/**
 * The returned promise will resolve with the value provided to the `close()` function, or reject if the user closed the modal.
 */
function wrapDialogueInternal<T>(
    modalService: BsModalService,
    component: any,
    _class: string,
    initialState: any
): Promise<T> {
    return new Promise<any>((resolve, reject) => {
        const modalInstance: {
            modal: BsModalRef<any>;
            subscription: Subscription;
        } = { modal: null, subscription: null };
        const close = (result: T) => {
            modalInstance.subscription.unsubscribe();
            modalInstance.modal.hide();
            resolve(result);
        };
        const dismiss = (error: any) => {
            modalInstance.subscription.unsubscribe();
            modalInstance.modal.hide();
            reject(error);
        };
        try {
            modalInstance.modal = modalService.show(component, {
                ...MODAL_OPTIONS,
                class: _class,
                // All legacy dialogues assume that ESC is handled
                keyboard: true,
                initialState: { ...initialState, close, dismiss }
            });

            modalInstance.subscription = modalInstance.modal.onHide.subscribe(
                () => {
                    dismiss(undefined);
                }
            );
        } catch (e) {
            this.logging.error(e);
            dismiss(e);
        }
    });
}
