import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup
} from '@angular/forms';
import {
    FormatBatterySetPipe,
    FormatRpaPipe
} from '@flyfreely-portal-ui/resource-ui';
import { findFirst } from 'fp-ts/es6/Array';
import * as moment from 'moment';
import {
    BatteryDto,
    BatterySetDto,
    CraftDto,
    EquipmentDto,
    PersonsOrganisationDto
} from '../model/api';
import { Resource } from '../model/resources';

/**
 * Finds the organisation with the given ID from the list of organisations.
 */
export function findOrganisation(
    organisations: PersonsOrganisationDto[],
    organisationId: number
) {
    return findFirst<PersonsOrganisationDto>(o => o.id === organisationId)(
        organisations
    );
}

/**
 * Checks if the organisation has the given feature flag.
 */
export function hasFeatureFlag(
    organisation: { featureFlags: string[] },
    featureFlag: string
) {
    if (organisation == null) {
        return false;
    }
    return organisation.featureFlags.indexOf(featureFlag) !== -1;
}

/**
 * Checks if the organisation has any of the given permissions.
 */
export function hasAnyPermission(
    organisation: { permissions: string[] },
    flag:
        | PersonsOrganisationDto.Permissions
        | PersonsOrganisationDto.Permissions[]
) {
    if (organisation == null) {
        return false;
    }
    if (Array.isArray(flag)) {
        return (
            flag.findIndex(f => organisation.permissions.indexOf(f) !== -1) !==
            -1
        );
    }
    return organisation && organisation.permissions.indexOf(flag) !== -1;
}

/**
 * Checks if the person has any of the given roles.
 */
export function hasAnyRole(
    personsRoles: PersonsOrganisationDto.Roles[],
    candidateRoles: PersonsOrganisationDto.Roles[]
) {
    if (personsRoles == null || candidateRoles == null) {
        return false;
    }
    return personsRoles.findIndex(r => candidateRoles.indexOf(r) !== -1) !== -1;
}

/**
 * Updates the validity of each control from this point to the children.
 */
export function updateFormTreeValidity(
    control: AbstractControl | AbstractControl<any>
) {
    if (control instanceof FormArray) {
        for (let i = 0; i < control.length; i++) {
            updateFormTreeValidity(control.at(i));
        }
    } else if (control instanceof FormGroup) {
        Object.keys(control.controls).forEach(name =>
            updateFormTreeValidity(control.get(name))
        );
    }

    control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
}

/**
 * Clears all controls from the form array.
 */
// export function clearFormArray(array: FormArray) {
//     while (array.length > 0) {
//         array.removeAt(array.length - 1);
//     }
// }

export function clearFormArray<T = any>(
    array: FormArray | FormArray<FormControl<T>>
) {
    while (array.length > 0) {
        array.removeAt(array.length - 1);
    }
}

/**
 * Adjusts the size of the form array to match the desired length.
 */
export function adjustFormArray<T>(
    formArray: FormArray,
    length: number
) {
    while (formArray.length < length) {
        formArray.push(new FormControl());
    }

    while (formArray.length > length) {
        formArray.removeAt(formArray.length - 1);
    }
}

/**
 * Calculates the difference between two time stamps in seconds.
 */
export function calculateDuration(startTime: string, endTime: string) {
    if (startTime == null || endTime == null) {
        return null;
    }
    return moment(endTime).diff(moment(startTime), 's');
}

/**
 * Reverses the order of a number.
 */
export function compareReverse(val: number) {
    return val * -1;
}

/**
 * Comparator function for sorting dates.
 */
export function compareDates(
    a: string | Date | moment.Moment,
    b: string | Date | moment.Moment
) {
    if (a == null && b == null) {
        return 0;
    }
    if (a == null && b != null) {
        return 1;
    }
    if (a != null && b == null) {
        return -1;
    }
    const a_ = moment.isMoment(a) ? a : moment(a);
    const b_ = moment.isMoment(b) ? b : moment(b);

    return a_.valueOf() - b_.valueOf();
}

/**
 * A track by function for using the ID inside *ngFor loops.
 */
export function trackById<I>(idx: number, obj: { id: I }): I {
    return obj.id;
}

/**
 * Returns the name of an object of type Resource.
 */
export function findResourceName(obj: Resource, resourceType: string) {
    if (resourceType === 'CRAFT') {
        const formatRpaPipe = new FormatRpaPipe();
        return formatRpaPipe.transform(obj as CraftDto);
    }
    if (resourceType === 'BATTERY') {
        const battery = obj as BatteryDto;
        return battery.name;
    }
    if (resourceType === 'BATTERY_SET') {
        const formatBatterySet = new FormatBatterySetPipe();
        return formatBatterySet.transform(obj as BatterySetDto);
    }
    if (resourceType === 'EQUIPMENT') {
        const equipment = obj as EquipmentDto;
        return equipment.name;
    }
}

/**
 * Checks if the argument is defined (not null or undefined).
 */
export function isDefined<T>(
    arg: T | null | undefined
): arg is T extends null | undefined ? never : T {
    return arg !== null && arg !== undefined;
}
