<div
    [ngSwitch]="status"
    class="alert"
    [ngClass]="{
        'alert-success': status === 'OK',
        'alert-warning': status !== 'OK'
    }"
>
    <ng-container *ngSwitchCase="'OK'">
        <span class="fal fa-check-circle"></span> All Authorities OK
    </ng-container>
    <ng-container *ngSwitchDefault>
        <h4>
            <span>Authorities Requiring Attention</span>
        </h4>
        <div class="horizontal-container fill vertical-align">
            <div class="fcol-3">
                <label>Authority</label>
            </div>
            <div class="fcol-3">
                <label>Entry</label>
            </div>
            <div class="fcol-3">
                <label>Status</label>
            </div>
        </div>
        <ng-container
            *ngFor="let expiry of filteredAuthorityExpiries; let i = index"
        >
            <div class="vertical-container data fill">
                <div class="horizontal-container fill vertical-align">
                    <div class="fcol-3">
                        {{ expiry.authorityName }}
                    </div>
                    <div class="fcol-3">
                        <a (click)="showAuthority(expiry)">
                            Authority Expiry
                        </a>
                    </div>
                    <div class="fcol-3">
                        <ng-container [ngSwitch]="expiry.overallStatus">
                            <span
                                *ngSwitchCase="'OK'"
                                class="status-block active"
                                >OK</span
                            >
                            <span
                                *ngSwitchCase="'UPCOMING'"
                                class="status-block pending"
                                >Upcoming Expiry</span
                            >
                            <span
                                *ngSwitchCase="'EXPIRED'"
                                class="status-block inactive"
                                >Expired</span
                            >
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngFor="
                let expiry of filteredRegisterExpirySummaries;
                let i = index
            "
        >
            <div class="vertical-container data fill">
                <div class="horizontal-container fill vertical-align">
                    <div class="fcol-3">
                        {{ expiry.authorityName }}
                    </div>
                    <div class="fcol-3">
                        <a (click)="showRegister(expiry)">
                            {{ expiry.registerName }}
                        </a>
                    </div>
                    <div class="fcol-3">
                        <ng-container [ngSwitch]="expiry.overallStatus">
                            <span
                                *ngSwitchCase="'OK'"
                                class="status-block active"
                                >OK</span
                            >
                            <span
                                *ngSwitchCase="'UPCOMING'"
                                class="status-block pending"
                                >Upcoming Expiry</span
                            >
                            <span
                                *ngSwitchCase="'EXPIRED'"
                                class="status-block inactive"
                                >Expired</span
                            >
                        </ng-container>
                    </div>
                    <div
                        class="fcol-3 horizontal-contaienr vertical-align"
                        *ngIf="
                            expiry.overallStatus !== 'OK' &&
                            (currentlyEditingIndex == null ||
                                currentlyEditingIndex === i)
                        "
                    >
                        Expiry details
                        <button
                            class="btn-tertiary left-buffer"
                            type="button"
                            [disabled]="
                                currentlyEditingIndex != null &&
                                currentlyEditingIndex !== i
                            "
                            (click)="showHideExpiryDetails(i)"
                        >
                            <span
                                *ngIf="currentlyEditingIndex !== i"
                                class="fa fa-chevron-down"
                            ></span>
                            <span
                                *ngIf="currentlyEditingIndex === i"
                                class="fa fa-chevron-up"
                            ></span>
                        </button>
                    </div>
                </div>
                <div
                    *ngIf="currentlyEditingIndex === i"
                    class="vertical-container fill top-buffer"
                >
                    <div class="horizontal-container fill vertical-align">
                        <div class="fcol-3">
                            <label>Name</label>
                        </div>
                        <div class="fcol-8">
                            <label>Details</label>
                        </div>
                    </div>
                    <div
                        *ngFor="let entry of expiry.registerEntries"
                        class="horizontal-container fill vertical-align"
                    >
                        <div class="fcol-3">
                            <span *ngIf="entry && entry.person">
                                {{
                                    entry.person.firstName +
                                        ' ' +
                                        entry.person.lastName
                                }}
                            </span>
                            <span *ngIf="entry && entry.rpa">
                                {{ entry.rpa.nickname }}
                            </span>
                        </div>
                        <div class="fcol-8">
                            {{ getExpiryDetailsText(entry) }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
