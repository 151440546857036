import { Injectable, Provider } from '@angular/core';
import {
    AssignMaintenanceScheduleCommand,
    CraftService,
    MaintenanceScheduleAssignmentDto
} from '@flyfreely-portal-ui/flyfreely';
import { ResourceMaintenanceService } from 'libs/maintenance/src/lib/resource-maintenance.service';
import { Observable } from 'rxjs';

@Injectable()
export class RpaMaintenanceService /* implements ResourceMaintenanceService */ {
    private rpaId: number;

    constructor(private craftService: CraftService) {}

    setup(rpaId: number) {
        this.rpaId = rpaId;
    }

    assign(
        command: AssignMaintenanceScheduleCommand
    ): Observable<MaintenanceScheduleAssignmentDto> {
        return this.craftService.assignMaintenanceSchedule(this.rpaId, command);
    }
}

/**
 * Provider for the RPA maintenance service. Required because we need to "provide"
 * the base class.
 */
export const rpaMaintenanceServiceProvider: Provider = {
    provide: ResourceMaintenanceService,
    useClass: RpaMaintenanceService
};
