import { Component, Input } from '@angular/core';
import {
    FEATURE_ADDITIONAL_MAINTENANCE,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceScheduleService,
    PersonsOrganisationDto,
    SimpleMaintenanceScheduleDto,
    WorkTracker,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import { MaintenanceDialogues } from 'libs/maintenance/src/lib/maintenance-dialogues.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'maintenance-schedule-list',
    templateUrl: './maintenance-schedule-list.component.html'
})
export class MaintenanceScheduleList {
    @Input() organisation: PersonsOrganisationDto;

    rpaSchedules: SimpleMaintenanceScheduleDto[];
    batterySchedules: SimpleMaintenanceScheduleDto[];
    equipmentSchedules: SimpleMaintenanceScheduleDto[];

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    hasAdditionalMaintenance = false;

    constructor(
        private maintenanceScheduleService: MaintenanceScheduleService,
        private maintenanceDialoguesService: MaintenanceDialogues,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.maintenanceScheduleService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMaintenanceSchedules());

        this.refreshMaintenanceSchedules();

        this.hasAdditionalMaintenance = hasFeatureFlag(
            this.organisation,
            FEATURE_ADDITIONAL_MAINTENANCE
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshMaintenanceSchedules() {
        this.maintenanceScheduleService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                schedules => {
                    this.rpaSchedules = schedules
                        .filter(
                            s =>
                                s.resourceCategory ===
                                SimpleMaintenanceScheduleDto.ResourceCategory
                                    .CRAFT
                        )
                        .map(s => ({
                            ...s,
                            name: `${s.name} (${s.rpaCategory})`
                        }));
                    this.batterySchedules = schedules.filter(
                        s =>
                            s.resourceCategory ===
                            SimpleMaintenanceScheduleDto.ResourceCategory
                                .BATTERY
                    );
                    this.equipmentSchedules = schedules
                        .filter(
                            s =>
                                s.resourceCategory ===
                                SimpleMaintenanceScheduleDto.ResourceCategory
                                    .EQUIPMENT
                        )
                        .map(s => ({
                            ...s,
                            name: `${s.name} (${s.equipmentCategory})`
                        }));
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing maintenance schedules: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    createMaintenanceSchedule() {
        this.maintenanceDialoguesService
            .showMaintenanceScheduleDialogue(null, this.organisation.id)
            .onHide.asObservable()
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMaintenanceSchedules());
    }

    editMaintenanceSchedule(schedule: SimpleMaintenanceScheduleDto) {
        this.maintenanceDialoguesService
            .showMaintenanceScheduleDialogue(schedule, this.organisation.id)
            .onHide.asObservable()
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMaintenanceSchedules());
    }
}
