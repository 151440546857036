<div id="shortcutLinkContainer" class="panel widget dark news">
    <div class="panel-heading-flex">
        <div class="panel-icon dark">
            <img imgPath="/icons/icon-welcome.png" />
        </div>
        <div class="panel-title">Getting Started</div>

        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                class="btn btn-inline"
                (click)="closeWidget()"
                tooltip="Hide"
                placement="left"
            >
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>

    <div class="panel-body">
        <div class="horizontal-container">
            <div class="horizontal-container fill widget-container">
                <div class="contents" style="width: 30%; min-width: 400px">
                    <quick-setup></quick-setup>
                </div>
                <!-- <div class="fill contents" style="min-width: 300px;">
                    <news-card
                        newsType="Platform"
                    ></news-card>
                </div> -->
                <div
                    class="fill contents top-buffer"
                    style="min-width: 420px; margin-top: 25px; max-width: 600px"
                >
                    <flyfreely-video
                        videoUrl="https://static.flyfreely.io/platform/videos/FlyFreely%20Animated%20Overview.mp4"
                        videoType="video/mp4"
                        thumbnail="https://www.flyfreely.io/wp-content/uploads/2021/03/hero-video-poster-2-2-aspect-ratio-1024-563.jpg"
                        (play)="onPlayVideo()"
                    ></flyfreely-video>
                </div>
                <div class="contents" style="width: 30%; min-width: 440px">
                    <support-links></support-links>
                </div>
            </div>
        </div>
    </div>
</div>
