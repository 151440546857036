import { Injectable } from '@angular/core';
import { PreferencesService } from '../preferences.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, shareReplay, scan, take, tap } from 'rxjs/operators';
import { getOrElse } from 'fp-ts/es6/Option';
import { Angulartics2 } from 'angulartics2';

const SIDE_BAR_PREFERENCES = 'sidebar';

interface SideBarState {
    showNotificationBar: boolean;
    isSideBarCollapsed: boolean;
}

interface DashboardReturnOptions {
    shouldReturn: boolean;
    initialOrganisationId?: number;
}

/**
 * Manages the state of the dashboard and all associated components such as the sidebar.
 */
// FIXME should be scoped service, provided by the dashboard, but for some reason the setup guide checklist is root
@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private returnToDashboardSource = new BehaviorSubject<
        DashboardReturnOptions
    >({ shouldReturn: false });
    private sideBarStateSubject = new Subject<Partial<SideBarState>>();
    sideBarState$ = this.sideBarStateSubject.asObservable().pipe(
        scan((current: SideBarState, change) => ({
            ...current,
            ...change
        })),
        shareReplay(1)
    );
    returnToDashboard$ = this.returnToDashboardSource.asObservable();
    currentSideBarState: SideBarState;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private preferencesService: PreferencesService,
        angulartics2: Angulartics2
    ) {
        this.initSidebarState();
        this.sideBarState$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(state => {
                if (
                    this.currentSideBarState != null &&
                    this.currentSideBarState.isSideBarCollapsed !==
                        state.isSideBarCollapsed
                ) {
                    angulartics2.eventTrack.next({
                        action: state.isSideBarCollapsed
                            ? 'collapse'
                            : 'expand',
                        properties: {
                            category: 'sidebar'
                        }
                    });
                }
                this.currentSideBarState = state;
            });
    }

    ngOnDestroy() {
        this.sideBarStateSubject.complete();
        this.returnToDashboardSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private initSidebarState() {
        this.preferencesService
            .findPreferencesAsOption(SIDE_BAR_PREFERENCES, null)
            .pipe(
                map(p =>
                    getOrElse(() => ({
                        showNotificationBar: false,
                        isSidebarCollapsed: false
                    }))(p)
                ),
                takeUntil(this.ngUnsubscribe$),
                take(1)
            )
            .subscribe(state => {
                this.sideBarStateSubject.next(state);
                this.setupSidebarPersistence();
            });
    }

    private setupSidebarPersistence() {
        this.sideBarState$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(state =>
                this.preferencesService
                    .updatePreferences(SIDE_BAR_PREFERENCES, null, state)
                    .subscribe()
            );
    }

    shouldReturnToDashboard(
        shouldReturn: boolean,
        initialOrganisationId?: number
    ) {
        this.returnToDashboardSource.next({
            shouldReturn,
            initialOrganisationId
        });
    }

    showNotificationBar() {
        this.sideBarStateSubject.next({
            showNotificationBar: true
        });
    }

    hideNotificationBar() {
        this.sideBarStateSubject.next({ showNotificationBar: false });
    }

    toggleNotificationBar() {
        this.sideBarStateSubject.next({
            showNotificationBar: !this.currentSideBarState.showNotificationBar
        });
    }

    toggleSidebar() {
        /* if (this.currentSideBarState.showNotificationBar) {
            this.hideNotificationBar();
            return;
        } */

        this.sideBarStateSubject.next({
            isSideBarCollapsed: !this.currentSideBarState.isSideBarCollapsed
        });
    }
}
