import { Component, Input } from '@angular/core';
import {
    FormArray,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceService,
    PersonsOrganisationDto,
    ResourceComponentInServiceTime,
    UpdateAssignedMaintenanceActivityCommand,
    WorkTracker,
    fromLocalDate,
    fromTimestamp,
    toLocalDate,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaintenanceDetailsGroupModel } from '../maintenance-form.service';
import {
    DisplayableMaintenanceActivity,
    DisplayableMaintenanceTask,
    MaintenanceRequestService
} from '../maintenance-request.service';

@Component({
    selector: 'completed-maintenance-activity-edit',
    templateUrl: './completed-maintenance-activity-edit-dialogue.component.html'
})
export class CompletedMaintenanceActivityEditDialogue {
    @Input() organisation: PersonsOrganisationDto;
    @Input() canUseMaintenanceTasks: boolean;
    @Input() activity: DisplayableMaintenanceActivity;
    @Input() maintenanceLogId: number;
    @Input() components: ResourceComponentInServiceTime[];

    form: FormGroup<MaintenanceDetailsGroupModel>;

    canAddMaintenanceTask = false;

    working: boolean = false;
    workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<CompletedMaintenanceActivityEditDialogue>,
        private maintenanceService: MaintenanceService,
        private commonDialoguesService: CommonDialoguesService,
        private maintenanceRequestService: MaintenanceRequestService,
        private logging: FlyFreelyLoggingService,
        modalOptions: ModalOptions
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.form = new FormGroup({
            maintenancePerformed: new FormControl(undefined),
            tasks: new FormArray([]),
            maintenanceRemarks: new FormControl(undefined),
            startDate: new FormControl(undefined),
            endTime: new FormControl(undefined, [Validators.required])
        });

        modalOptions.closeInterceptor = () =>
            preHide(
                this.form as unknown as FormGroup,
                this.commonDialoguesService
            );
    }

    ngOnInit() {
        this.setupMaintenanceDetails();

        this.form.controls.endTime.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(endTime =>
                this.form.controls.endTime.setValue(endTime, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                })
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setTasksFromActivity() {
        const taskList = this.tasks;
        this.activity.tasks.sort((a, b) => {
            if (a.taskType.name < b.taskType.name) {
                return -1;
            } else if (a.taskType.name > b.taskType.name) {
                return 1;
            } else {
                if (
                    `${a.component.componentType.name} ${a.component.designation}` <
                    `${b.component.componentType.name} ${b.component.designation}`
                ) {
                    return -1;
                } else if (
                    `${a.component.componentType.name} ${a.component.designation}` >
                    `${b.component.componentType.name} ${b.component.designation}`
                ) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        this.activity.tasks.map(task => {
            if (
                taskList.value.findIndex(
                    t =>
                        t.componentId === task.component.id &&
                        t.taskTypeId === task.taskType.id
                ) === -1
            ) {
                const component = this.components.find(
                    c => c.id === task.component.id
                );
                const row = new FormControl({
                    required: task.required,
                    completed: task.completed,
                    componentId: task.component.id,
                    componentName: `${task.component.componentType.name} ${task.component.designation}`,
                    notes: task.notes ?? '',
                    taskTypeId: task.taskType.id,
                    taskTypeName: task.taskType.name,
                    serialNumber:
                        task.serialNumber ?? component.serialNumber ?? '',
                    firmwareVersion:
                        task.firmwareVersion ?? component.firmwareVersion ?? ''
                });
                taskList.push(row);
            }
        });
        this.tasks.markAsPristine();
    }

    setupMaintenanceDetails() {
        this.form.patchValue({
            maintenancePerformed: this.activity.maintenancePerformed,
            maintenanceRemarks: this.activity.maintenanceRemarks,
            startDate: fromLocalDate(this.activity.startDate),
            endTime: fromTimestamp(
                this.activity.endTime,
                this.organisation.timeZone
            )
        });
        this.setTasksFromActivity();
    }

    completedNow() {
        const now = new Date();
        // Set twice because the way we default to noon checks the previous value
        this.form.controls.endTime.setValue(now);
        this.form.controls.endTime.setValue(now);
    }

    saveAssignedMaintenanceActivity() {
        const f = this.form.value;
        const toSave: UpdateAssignedMaintenanceActivityCommand = {
            endTime: toTimestamp(f.endTime, this.organisation.timeZone),
            tasks: f.tasks.map(t => ({ ...t, notes: t.notes || '' })) ?? [],
            maintenancePerformed: f.maintenancePerformed,
            maintenanceRemarks: f.maintenanceRemarks,
            startDate: toLocalDate(f.startDate)
        };
        this.maintenanceService
            .updateAssignedMaintenanceActivity(
                this.maintenanceLogId,
                this.activity.id,
                toSave
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                maintenance => {
                    this.logging.success(`Maintenance activity updated`);
                    this.form.markAsPristine();
                    this.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating maintenance: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    showAddComponentForm() {
        this.canAddMaintenanceTask = false;
        this.maintenanceRequestService.addMaintenanceTask();
    }

    hide() {
        this.modal.hide();
    }

    get tasks() {
        return <FormArray<FormControl<DisplayableMaintenanceTask>>>(
            this.form.get('tasks')
        );
    }
}
