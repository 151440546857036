import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonDialoguesModule } from 'libs/common-dialogues/src/lib/common-dialogues.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { RiskRegisterDialoguesService } from './risk-register-dialogues.service';

@NgModule({
    imports: [CommonModule, CommonDialoguesModule, SharedUiModule],
    providers: [RiskRegisterDialoguesService]
})
export class RiskRegisterModule {}
