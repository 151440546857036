import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CreateShortcutLinkCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    hasAnyPermission,
    PersonsOrganisationDto,
    ShortcutLinkDto,
    ShortcutLinkService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const ORGANISATION_LINKS_IDENTIFIER = 'organisationLinks';

@Component({
    selector: 'organisation-links-widget',
    templateUrl: './organisation-links-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class OrganisationLinksWidgetComponent
    implements OnInit, OnDestroy, Widget
{
    organisation: PersonsOrganisationDto;

    public working: boolean;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    canEdit: boolean;
    shortcutLinksList: ShortcutLinkDto[];
    linkForm: FormGroup;
    currentlyEditting: number;
    isNew = false;

    deleteVisible: boolean;

    constructor(
        private commonDialoguesService: CommonDialoguesService,
        private linksService: ShortcutLinkService,
        private logging: FlyFreelyLoggingService,
        private workspaceStateService: WorkspaceStateService
    ) {}

    ngOnInit(): void {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshShortcutLinks();
            });
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.linkForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            link: new FormControl('', [Validators.required])
        });

        this.linksService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshShortcutLinks());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshShortcutLinks() {
        this.shortcutLinksList = null;
        if (!this.organisation) {
            return;
        }

        this.canEdit =
            this.organisation.permissions.indexOf(
                PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
            ) !== -1;

        this.linksService
            .findShortCutLinksList(this.organisation.id)
            .subscribe({
                next: shortcutLinks => {
                    this.shortcutLinksList = shortcutLinks;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    editLink(link: ShortcutLinkDto) {
        this.currentlyEditting = link.id;
        this.linkForm.patchValue({ name: link.title, link: link.link });
    }
    cancel() {
        this.currentlyEditting = null;
        this.isNew = false;
    }

    newShortLink() {
        this.isNew = true;
        this.currentlyEditting = null;
        this.linkForm.reset();
    }

    saveShortCutLink() {
        const f = this.linkForm.controls;

        if (this.isNew) {
            const newLink: CreateShortcutLinkCommand = {
                organisationId: this.organisation.id,
                link: f.link.value,
                title: f.name.value
            };

            this.linksService
                .createShortCutLink(newLink)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: link => {
                        this.currentlyEditting = null;
                        this.isNew = false;
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(error, error.message);
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            const forSaving = {
                id: this.currentlyEditting,
                organisationId: this.organisation.id,
                link: f.link.value,
                title: f.name.value
            };

            this.linksService
                .updateShortCutLink(this.currentlyEditting, forSaving)
                .subscribe({
                    next: () => {
                        this.currentlyEditting = null;
                    },
                    error: error => {
                        this.logging.error(error);
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }

    deleteLink(shortcutLink: ShortcutLinkDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete shortcut',
                `Delete the shortcut link ${shortcutLink.title} to ${shortcutLink.link}?`,
                'Delete',
                () =>
                    firstValueFrom(
                        this.linksService.deleteShortCutLink(shortcutLink.id)
                    )
            )
            .then(
                () => this.refreshShortcutLinks(),
                () => {
                    return;
                }
            );
    }
}

export const organisationLinksWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: ORGANISATION_LINKS_IDENTIFIER,
    component: OrganisationLinksWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.ORGANISATION_VIEW
        )
};
