import { Component, forwardRef } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    ComponentGroupDto,
    DO_NOTHING,
    MaintenanceScheduleTaskCommand,
    MaintenanceTaskTypeDto,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, skipWhile, takeUntil } from 'rxjs/operators';
import { MaintenanceScheduleDialogueService } from './maintenance-schedule-dialogue.service';

interface MaintenanceTaskEntryForm {
    taskTypeId: FormControl<number>;
    calendarTime: FormControl<number>;
    cycles: FormControl<number>;
    inServiceTime: FormControl<number>;
}

interface MaintenanceTaskEntry {
    taskTypeId: number;
    calendarTime?: number;
    cycles?: number;
    inServiceTime?: number;
}

interface ComponentGroupForm {
    componentGroupId: number;
    componentGroup: ComponentGroupDto;
    tasks: FormArray<FormGroup<MaintenanceTaskEntryForm>>;
}

function newTask(t?: MaintenanceScheduleTaskCommand) {
    return new FormGroup<MaintenanceTaskEntryForm>({
        taskTypeId: new FormControl(t?.taskTypeId),
        calendarTime: new FormControl(t?.calendarTime),
        cycles: new FormControl(t?.cycles),
        inServiceTime: new FormControl(
            t?.inServiceTime != null ? t.inServiceTime / 3600 : null
        )
    });
}

@Component({
    selector: 'maintenance-schedule-tasks',
    template: `
        <div *ngFor="let group of groups">
            <h5>{{ group.componentGroup.name }}</h5>
            <div class="horizontal-container">
                <button
                    type="button"
                    class="btn-circle btn-link"
                    (click)="add(group)"
                    [tooltip]="
                        'Create new task for ' + group.componentGroup.name
                    "
                    [disabled]="working"
                >
                    <span class="fa fa-plus"></span>
                </button>
                <div class="fill">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Task Type</th>
                                <th>In Service Time (Hours)</th>
                                <th>Flights/Cycles</th>
                                <th>Calendar Time (Days)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="
                                    let item of group.tasks.controls;
                                    let i = index
                                "
                                [formGroup]="item"
                            >
                                <tr>
                                    <td>
                                        <ng-select
                                            [items]="taskType$ | async"
                                            bindLabel="name"
                                            bindValue="id"
                                            placeholder="Select the task type for this"
                                            [clearable]="false"
                                            formControlName="taskTypeId"
                                        >
                                        </ng-select>
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="inServiceTime"
                                            min="1"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="cycles"
                                            min="1"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="calendarTime"
                                            min="1"
                                        />
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            (click)="deleteTask(group, i)"
                                            class="btn btn-sm btn-tertiary btn-delete"
                                            tooltip="Delete Task"
                                            placement="top"
                                        >
                                            <span
                                                class="fal fa-trash-alt"
                                            ></span>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Component Group</label>
            <div class="input-group">
                <ng-select
                    [items]="filteredComponentGroup$ | async"
                    bindLabel="name"
                    placeholder="Select a component group to customise"
                    [clearable]="false"
                    [(ngModel)]="selectedComponentGroup"
                >
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.name }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                    </ng-template>
                </ng-select>

                <span class="input-group-btn">
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="selectedComponentGroup == null"
                        (click)="customiseComponentGroup()"
                    >
                        Customise
                    </button>
                </span>
            </div>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MaintenanceScheduleTasks),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MaintenanceScheduleTasks),
            multi: true
        }
    ]
})
export class MaintenanceScheduleTasks
    implements ControlValueAccessor, Validator
{
    onChange: (val?: any) => any = DO_NOTHING;
    onTouched: (val?: any) => any = DO_NOTHING;
    onValidate: (val?: any) => any = DO_NOTHING;

    private ngUnsubscribe$ = new Subject<void>();

    private incomingValue$ = new ReplaySubject<
        MaintenanceScheduleTaskCommand[]
    >();

    private inUseComponentGroupIds = new ReplaySubject<number[]>();

    filteredComponentGroup$: Observable<ComponentGroupDto[]>;

    selectedComponentGroup: ComponentGroupDto;

    taskType$: Observable<MaintenanceTaskTypeDto[]>;

    groups: ComponentGroupForm[];

    working = false;

    private muteUpdates = false;

    constructor(
        private maintenanceScheduleDialogueService: MaintenanceScheduleDialogueService
    ) {}

    ngOnInit() {
        combineLatest([
            this.maintenanceScheduleDialogueService.componentGroup$,
            this.incomingValue$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([componentGroups, value]) =>
                this.updateValues(value, componentGroups)
            );

        this.filteredComponentGroup$ = combineLatest([
            this.maintenanceScheduleDialogueService.componentGroup$,
            this.inUseComponentGroupIds
        ]).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(([componentGroups, inUseComponentGroups]) =>
                componentGroups.filter(
                    g => inUseComponentGroups.indexOf(g.id) === -1
                )
            )
        );

        this.taskType$ = this.maintenanceScheduleDialogueService.taskTypes$;
    }

    ngOnDestroy() {
        this.incomingValue$.complete();
        this.inUseComponentGroupIds.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidate = fn;
    }

    writeValue(obj: MaintenanceScheduleTaskCommand[]): void {
        this.incomingValue$.next(obj);
    }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    validate(control: AbstractControl): ValidationErrors {
        if (this.groups == null) {
            return null;
        }
        return this.groups.reduce(
            (acc, g) => ({
                required: acc.required || g.tasks.valid
            }),
            {} as ValidationErrors
        );
    }

    private newComponentGroupTaskList(
        initialValue: FormGroup<MaintenanceTaskEntryForm>[] = []
    ) {
        const taskList = new FormArray<FormGroup<MaintenanceTaskEntryForm>>(
            initialValue
        );

        taskList.valueChanges
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                skipWhile(() => this.muteUpdates)
            )
            .subscribe(() => this.notifyChanges());

        return taskList;
    }

    customiseComponentGroup() {
        this.groups.push({
            componentGroup: this.selectedComponentGroup,
            componentGroupId: this.selectedComponentGroup.id,
            tasks: this.newComponentGroupTaskList()
        });
        this.selectedComponentGroup = null;
    }

    add(group: ComponentGroupForm) {
        group.tasks.push(newTask());
    }

    deleteTask(group: ComponentGroupForm, ix: number) {
        group.tasks.removeAt(ix);
    }

    private updateValues(
        obj: MaintenanceScheduleTaskCommand[],
        componentGroups: ComponentGroupDto[]
    ) {
        this.muteUpdates = true;
        const componentGroupLookup = componentGroups.reduce(toLookup, {});

        const inUseComponentGroupIds = Array.from(
            obj.reduce(
                (acc, g) => acc.add(g.componentGroupId),
                new Set<number>()
            )
        );

        const groups = obj.reduce((grouped, t) => {
            const newRow = newTask(t);
            if (t.componentGroupId in grouped) {
                grouped[t.componentGroupId].push(newRow);
                return grouped;
            }
            return {
                ...grouped,
                [t.componentGroupId]: this.newComponentGroupTaskList([newRow])
            };
        }, {});

        this.groups = inUseComponentGroupIds.map(g => ({
            componentGroup: componentGroupLookup[g],
            componentGroupId: <number>g,
            tasks: groups[g]
        }));

        this.inUseComponentGroupIds.next(inUseComponentGroupIds);
        this.muteUpdates = false;
    }

    /**
     * Calculate the control value and notify the form control
     */
    private notifyChanges() {
        this.onChange(
            this.groups?.reduce(
                (acc, g) =>
                    acc.concat(
                        g.tasks.value.map(v => ({
                            ...v,
                            inServiceTime:
                                v.inServiceTime != null
                                    ? v.inServiceTime * 3600
                                    : null,
                            componentGroupId: g.componentGroupId
                        }))
                    ),
                []
            )
        );
    }
}
