import { GqlQueryResult } from '@flyfreely-portal-ui/flyfreely';

/**
 * The common values needed to perform pagination with ng-select
 */
export interface NgSelectPaginationValues {
    /**
     * The total number of results emmited in the last API fetch response.
     * This is used mainly to prevent unnecessary loading.
     */
    totalIdems: number;
    /**
     * The API request page that was reached before searching within the ng-select.
     * This allows resetting to the previous fetch configuration after a search is cleared.
     */
    preSearchPage: number;
    /**
     * The current fetch page to request from the API, should be initialised to 0 before the first fetch.
     */
    currentPage: number;
    /**
     * The current search value
     */
    searchValue: string;
}

/**
 * The date structure of the search object emitted by an ng-select's (search) output
 */
export interface NgSelectSearch {
    term: string;
    items: any[];
}

/**
 * A function to prep the ng-select data before the GraphQL call in the case of a search.
 * This should be called when handling the ng-select's (search) output.
 * @param search The search output from the ng-select
 * @param ngSelectPaginationValues the ng-select's pagination values
 */
export function ngSelectPreSearchConfigure(
    search: NgSelectSearch,
    ngSelectPaginationValues: NgSelectPaginationValues
): NgSelectPaginationValues {
    if (search.term === '' && ngSelectPaginationValues.preSearchPage != null) {
        return {
            ...ngSelectPaginationValues,
            searchValue: null,
            currentPage: ngSelectPaginationValues.preSearchPage,
            preSearchPage: null
        };
    } else {
        return {
            ...ngSelectPaginationValues,
            searchValue: search?.term,
            currentPage: 0,
            preSearchPage:
                ngSelectPaginationValues.preSearchPage == null
                    ? ngSelectPaginationValues.currentPage
                    : ngSelectPaginationValues.preSearchPage
        };
    }
}

/**
 * Updates the ng-select pagination data after a GraphQL query.
 * @param result the return from the GraphQL query
 * @param ngSelectPaginationValues the ng-select's pagination values
 */
export function updateNgSelectData(
    result: GqlQueryResult<any>,
    ngSelectPaginationValues: NgSelectPaginationValues
): NgSelectPaginationValues {
    return {
        ...ngSelectPaginationValues,
        totalIdems: result.count,
        currentPage: ngSelectPaginationValues.currentPage + 1
    };
}
