import { Component, OnInit } from '@angular/core';
import { OnboardingDialoguesService } from '../onboarding-dialogues.service';
import {
    OnboardingData,
    OnboardingScreens,
    OnboardingTab
} from '../onboarding-screens-data.service';

@Component({
    selector: 'onboarding-welcome-block',
    templateUrl: './welcome-block.component.html',
    styleUrls: ['./welcome-block.component.scss']
})
export class WelcomeBlockComponent implements OnInit {
    welcomeblockheading: string;
    helpText: string;
    helpTitle: string;
    title: string;
    name: string;
    hasHelp: boolean;
    activeTab: OnboardingTab;
    activeScreen: OnboardingScreens;
    constructor(
        private obsds: OnboardingData,
        private onboardingDialoguesService: OnboardingDialoguesService
    ) {}

    ngOnInit() {
        this.obsds.headings$.subscribe(headings => {
            this.welcomeblockheading = headings.heading;
            this.title = headings.title;
            this.helpText = headings.helpText;
            this.hasHelp = headings.hasHelp;
            this.helpTitle = headings.helpTitle ?? '';
        });
        this.obsds.personAndPersonalOrganisation$.subscribe(resp => {
            this.name = resp.person.firstName;
        });
        this.obsds.currentScreenAndTab$.subscribe(resp => {
            this.activeTab = resp.tab;
            this.activeScreen = resp.screen;
        });

        this.isImportantScreen(this.activeTab);
    }

    headingHelp() {
        this.onboardingDialoguesService.showHelp(this.helpText, this.helpTitle);
    }

    isImportantScreen(activeTab: OnboardingTab) {
        const importantScreens = [
            'PERSONAL_DETAILS',
            'PERSONAL_SETUP_COMPLETE',
            'ORGANISATION_SETUP_COMPLETE'
        ];
        const value = importantScreens.find(tab => tab === activeTab);
        return value;
    }
}
