import { Pipe, PipeTransform } from '@angular/core';
import { PersonDto, SimplePersonDto } from '@flyfreely-portal-ui/flyfreely';

function isPersonDto(person: PersonDto | SimplePersonDto): person is PersonDto {
    return Object.prototype.hasOwnProperty.call(person, 'email');
}

export function formatPerson(
    person: PersonDto | SimplePersonDto,
    includeEmail = false
) {
    if (!person) {
        return '';
    }
    const namePart = `${person.firstName} ${person.lastName}`;
    const emailPart =
        includeEmail && isPersonDto(person) ? ` <${person.email}>` : '';

    return `${namePart}${emailPart}`;
}

@Pipe({ name: 'formatPerson' })
export class FormatPersonPipe implements PipeTransform {
    transform = formatPerson.bind(this);
}
