<div class="switch_to_desktop_container main_container">
    <div class="switch_to_desktop_frame onboarding-frame">
        <div class="switch-warning-container fill flex-centered">
            <span class="switch-warning fa fa-exclamation"></span>
            <img
                class="switch-warning-icon"
                imgPath="/onboarding/mobile-assets/desktop-switch@2x.png"
            />
        </div>
        <div class="switch-to-desktop-text">
            The Office App is not optimised for mobile devices at this time.
            Please use a desktop or laptop device and log into the FlyFreely
            Office App for the Optimised Experience.
        </div>
    </div>
    <div class="custom--btn">
        <button class="next" name="swithcToDesktop_next" (click)="next()">
            Proceed Anyway
        </button>
    </div>
</div>
