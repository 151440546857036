import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormValidationDirective } from './form-validation.directive';
import { FormIdentifierDirective } from './form-identifier.directive';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [FormValidationDirective, FormIdentifierDirective],
    exports: [FormValidationDirective, FormIdentifierDirective]
})
export class FormDirectivesModule {}
