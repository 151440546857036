<div class="vertical-container">
    <div class="plan-and-switch" *ngIf="plans">
        <!-- <div class="horizontal-container fill flex-centered"> -->
        <div mdcFormField>
            <label mdcFormFieldLabel> Monthly </label>
            <div mdcSwitch>
                <div mdcSwitchThumb>
                    <input
                        type="checkbox"
                        mdcSwitchInput
                        [disabled]="working"
                        (change)="toggleRenewalPeriod()"
                    />
                </div>
            </div>
            <label mdcFormFieldLabel> Annually </label>
        </div>
        <!-- </div> -->

        <div class="panel-container">
            <plan-selection
                *ngIf="plans.freePlan != null"
                [plan]="plans.freePlan"
                [featuredPlan]="
                    plans.freePlan.identifier === featuredPlan?.identifier
                "
                [selectedFeature]="featureName"
                [product]="plans.freePlan"
                [selected]="selectedPlan === plans.freePlan"
                [current]="currentPlan === plans.freePlan"
                [upgrading]="showUpgrade"
                [initialLicenceCount]="plans.freePlan.minimumLicenceCount"
                (select)="selectPlan($event)"
                data-cy="freePlanSelection"
            ></plan-selection>
            <ng-container *ngIf="!renewalPeriodAnnually">
                <ng-container *ngFor="let plan of plans.monthlyPlan">
                    <plan-selection
                        *ngIf="plan != null"
                        [plan]="plan"
                        [featuredPlan]="
                            plan.identifier === featuredPlan?.identifier
                        "
                        [selectedFeature]="featureName"
                        [product]="plan"
                        [selected]="selectedPlan === plan"
                        [current]="currentPlan === plan"
                        [upgrading]="showUpgrade"
                        [initialLicenceCount]="
                            currentLicenceCount
                                | defaultTo: plan.minimumLicenceCount
                        "
                        (select)="selectPlan($event)"
                        data-cy="monthlyPlanSelection"
                    ></plan-selection>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="renewalPeriodAnnually">
                <ng-container *ngFor="let plan of plans.annualPlan">
                    <plan-selection
                        *ngIf="plan != null"
                        [plan]="plan"
                        [featuredPlan]="
                            plan.identifier === featuredPlan?.identifier
                        "
                        [selectedFeature]="featureName"
                        [product]="plan"
                        [selected]="selectedPlan === plan"
                        [current]="currentPlan === plan"
                        [upgrading]="showUpgrade"
                        [initialLicenceCount]="
                            currentLicenceCount
                                | defaultTo: plan.minimumLicenceCount
                        "
                        (select)="selectPlan($event)"
                        data-cy="annualPlanSelection"
                    ></plan-selection>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="plans == null" style="height: 300px; position: relative">
        <div *ngIf="!working" class="alert alert-info">
            No plan options available for this subscription. If this is a custom
            subscription please contact sales to modify it.
        </div>
    </div>

    <div
        class="action-container"
        *ngIf="!showUpgrade || showContactSales || (plans == null && !working)"
    >
        <div class="horizontal-container vertical-align">
            <p class="feature-label" *ngIf="showContactSales && !featuredPlan">
                {{ featureName }} is not part of any current subscription plans,
                please contact our sales team in order to find the best solution
                for you
            </p>
            <button
                *ngIf="trialUpPlan != null"
                type="button"
                [disabled]="working"
                class="btn btn-golden"
                (click)="trialUpSubscription()"
                [tooltip]="
                    'Trial the feature set from a ' +
                    trialUpPlan +
                    ' subscription.'
                "
                placement="top"
            >
                Start a {{ trialUpPlan }} trial
            </button>
            <button
                type="button"
                [disabled]="salesContacted || working"
                class="btn btn-default"
                (click)="contactSales()"
            >
                Contact Sales
            </button>
        </div>
    </div>

    <div
        class="action-container"
        *ngIf="showUpgrade && !showContactSales && plans != null && !working"
    >
        <div class="horizontal-container vertical-align pull-right">
            <button
                *ngIf="trialUpPlan != null"
                type="button"
                [disabled]="working"
                class="btn btn-golden"
                (click)="trialUpSubscription()"
                [tooltip]="
                    'Trial the feature set from a ' +
                    trialUpPlan +
                    ' subscription.'
                "
                placement="top"
            >
                Start a {{ trialUpPlan }} trial
            </button>
        </div>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</div>
