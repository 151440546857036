import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CreateEquipmentTypeCommand,
    EquipmentModelService,
    EquipmentService,
    EquipmentTypeDetailsDto,
    NameValue,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface CreatedType {
    make: string;
    model: string;
    category: string;
}

@Component({
    selector: 'create-equipment-type',
    template: `
        <div class="modal-header">
            <h3 class="modal-title">Create Equipment Type</h3>
            <button
                type="button"
                (click)="close()"
                aria-label="Close"
                class="close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <form [formGroup]="form" *ngIf="form != null">
                <div class="form-group top-buffer">
                    <label>Equipment Category</label>
                    <ng-select
                        [items]="categories"
                        [readonly]="working"
                        bindValue="value"
                        bindLabel="name"
                        appendTo="body"
                        [clearable]="false"
                        (change)="onEquipmentTypeSelectionChange()"
                        formControlName="category"
                    >
                    </ng-select>
                </div>
                <div class="horizontal-container">
                    <div class="form-group top-buffer">
                        <label>Equipment Make</label>
                        <ng-select
                            [items]="makes"
                            [readonly]="makeLocked || working"
                            bindValue="value"
                            bindLabel="name"
                            [addTag]="true"
                            addTagText="equipmentModelId"
                            appendTo="body"
                            (change)="equipmentMakeOnChange($event)"
                            formControlName="make"
                        >
                        </ng-select>

                        <div *ngIf="!makeListed">
                            <input
                                [readonly]="makeListed || working"
                                type="text"
                                class="form-control"
                                formControlName="makeText"
                                placeholder="Type the Equipment Make here..."
                            />
                        </div>
                    </div>
                    <div class="form-group top-buffer">
                        <label>Equipment Model</label>
                        <ng-select
                            [items]="models"
                            [readonly]="modelLocked || working"
                            bindValue="value"
                            bindLabel="name"
                            appendTo="body"
                            (change)="equipmentModelOnChange($event)"
                            formControlName="model"
                        >
                        </ng-select>
                        <div *ngIf="!modelListed">
                            <input
                                [readonly]="modelListed || working"
                                type="text"
                                class="form-control"
                                formControlName="modelText"
                                placeholder="Type the Equipment Model here..."
                            />
                        </div>
                    </div>
                </div>
                <div class="action-container">
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="save()"
                        [disabled]="working || form.invalid"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    `
})
export class CreateEquipmentTypeDialogue {
    @Input() organisationId: number;
    @Input() make: string;
    @Input() model: string;
    @Input() makes: string[];
    @Input() models: string[];

    @Output() createdType = new EventEmitter<CreatedType>();

    form: FormGroup;

    categories: NameValue[];

    makeListed: boolean;
    modelListed: boolean;
    modelLocked: boolean = true;
    makeLocked: boolean = true;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();

    working: boolean;

    constructor(
        private equipmentModelService: EquipmentModelService,
        private equipmentService: EquipmentService,
        private modal: BsModalRef<CreateEquipmentTypeDialogue>
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.form = new FormGroup({
            category: new FormControl(undefined, Validators.required),
            make: new FormControl(undefined, Validators.required),
            makeText: new FormControl(undefined),
            model: new FormControl(undefined, Validators.required),
            modelText: new FormControl(undefined)
        });
        this.setupOptions();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupOptions() {
        this.categories = this.equipmentService.getEquipmentCategories();
        this.makes.push('Not Listed');
        this.models.push('Not Listed');
        this.makeListed = true;
        this.modelListed = true;

        const hasMake = this.makes.findIndex(m => m === this.make) !== -1;
        const hasModel = this.models.findIndex(m => m === this.model) !== -1;
        if (hasMake === true) {
            this.form.controls.make.patchValue(this.make);
        } else {
            this.form.controls.make.patchValue('Not Listed');
            this.form.controls.make.setValidators(null);
            this.form.controls.makeText.patchValue(this.make);
            this.form.controls.makeText.setValidators(Validators.required);
            this.makeListed = false;
        }
        if (hasModel === true) {
            this.form.controls.model.patchValue(this.model);
        } else {
            this.form.controls.model.patchValue('Not Listed');
            this.form.controls.model.setValidators(null);
            this.form.controls.modelText.patchValue(this.model);
            this.form.controls.modelText.setValidators(Validators.required);
            this.modelListed = false;
        }
        this.form.updateValueAndValidity();
    }

    onEquipmentTypeSelectionChange() {
        this.makeLocked = false;
        this.modelLocked = false;
    }

    equipmentModelOnChange(event: string) {
        if (event !== undefined && event === 'Not Listed') {
            this.modelListed = false;
            this.form.controls.model.setValidators(null);
            this.form.controls.modelText.setValidators(Validators.required);
        } else if (event === undefined) {
            this.modelListed = true;
            this.form.controls.modelText.patchValue(undefined);
            this.form.controls.model.setValidators(Validators.required);
            this.form.controls.modelText.setValidators(null);
        } else {
            this.modelListed = true;
            this.form.controls.modelText.patchValue(undefined);
            this.form.controls.model.setValidators(Validators.required);
            this.form.controls.modelText.setValidators(null);
        }
        this.form.updateValueAndValidity();
    }

    equipmentMakeOnChange(event: string) {
        if (event !== undefined && event === 'Not Listed') {
            this.makeListed = false;
            this.modelListed = false;
            this.form.controls.model.patchValue('Not Listed');
            this.form.controls.make.setValidators(null);
            this.form.controls.makeText.setValidators(Validators.required);

            this.form.controls.model.setValidators(null);
            this.form.controls.modelText.setValidators(Validators.required);
        } else {
            this.makeListed = true;
            this.form.controls.makeText.patchValue(undefined);
            this.form.controls.make.setValidators(Validators.required);
            this.form.controls.makeText.setValidators(null);
        }
        this.form.updateValueAndValidity();
    }

    save() {
        const val = this.form.value;
        const command: CreateEquipmentTypeCommand = {
            equipmentCategory: this.findCategory(val.category),
            make: this.makeListed === true ? val.make : val.makeText,
            model: this.modelListed === true ? val.model : val.modelText,
            organisationId: this.organisationId
        };
        this.equipmentModelService
            .create(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                const created: CreatedType = {
                    category: result.equipmentCategory,
                    make: result.make,
                    model: result.model
                };
                this.createdType.emit(created);
                this.modal.hide();
            })
            .add(() => this.workTracker.createTracker());
    }

    findCategory(category: string) {
        const cat = EquipmentTypeDetailsDto.EquipmentCategory;
        switch (category) {
            case 'Base Station':
                return cat.BASE_STATION;
            case 'Controller':
                return cat.CONTROLLER;
            case 'Control Accessories':
                return cat.CONTROL_ACCESSORIES;
            case 'Monitor':
                return cat.MONITOR;
            case 'Other':
                return cat.OTHER;
            case 'Payload':
                return cat.PAYLOAD;
            default:
                return cat.PAYLOAD;
        }
    }

    close() {
        this.modal.hide();
    }
}
