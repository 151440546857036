import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {
    AnalysedFlightLogDto,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LocationDetailsDto,
    LocationFeatureDto,
    LocationService,
    MissionService,
    OrganisationService,
    SortieDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { isNumber } from '@turf/helpers';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { flightSummaryToFeatures } from 'libs/flight-logs/src/lib/helpers';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { getFeatureGroups, toMapFeature } from 'libs/locations/src/lib/helpers';
import { FlyFreelyMapComponent } from 'libs/map/src/lib/flyfreely-map/flyfreely-map.component';
import {
    FeatureAndGroup,
    FeatureGroup,
    MapFeature
} from 'libs/map/src/lib/interfaces';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

// Mapbox requires a non-negative value for IDs
const NEW_FEATURE = 0;

function getTakeoffLandingMapFeature(
    locationFeatures: LocationFeatureDto[],
    nextFeatureId: number
) {
    return locationFeatures.reduce(
        (acc, f, ix) => {
            if (f.type === LocationFeatureDto.Type.TAKEOFF) {
                return {
                    ...acc,
                    takeoffPoint: [
                        ...acc.takeoffPoint,
                        toMapFeature(f, ix + nextFeatureId)
                    ]
                };
            }
            if (f.type === LocationFeatureDto.Type.LANDING) {
                return {
                    ...acc,
                    landingPoint: [
                        ...acc.landingPoint,
                        toMapFeature(f, ix + nextFeatureId)
                    ]
                };
            }
        },
        {
            takeoffPoint: [] as MapFeature[],
            landingPoint: [] as MapFeature[]
        }
    );
}

function getTakeoffLandingGroups(
    locationFeatures: LocationFeatureDto[],
    nextFeatureId: number,
    nextGroupId = 0
): { nextId: number; features: FeatureGroup[] } {
    const groups = getTakeoffLandingMapFeature(
        locationFeatures ?? [],
        nextFeatureId
    );
    return {
        nextId: locationFeatures.length + nextFeatureId,
        features: [
            {
                id: nextGroupId++,
                name: 'Takeoff Point',
                type: 'Point',
                canAdd: true,
                minimumNumber: 0,
                maximumNumber: null,
                categories: [
                    {
                        id: LocationFeatureDto.Type.TAKEOFF,
                        name: 'Takeoff Point'
                    }
                ],
                existingFeatures: groups.takeoffPoint ?? [],
                styles: {
                    symbol: [
                        {
                            layout: { 'icon-image': 'flight-takeoff' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.TAKEOFF
                            ]
                        }
                    ]
                }
            },
            {
                id: nextGroupId++,
                name: 'Landing Point',
                type: 'Point',
                canAdd: true,
                minimumNumber: 0,
                maximumNumber: null,
                categories: [
                    {
                        id: LocationFeatureDto.Type.LANDING,
                        name: 'Landing Point'
                    }
                ],
                existingFeatures: groups.landingPoint ?? [],
                styles: {
                    symbol: [
                        {
                            layout: { 'icon-image': 'flight-landing' },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.LANDING
                            ]
                        }
                    ]
                }
            }
        ]
    };
}

@Component({
    selector: 'edit-flight-takeoff-landing',
    templateUrl: './edit-takeoff-landing.component.html',
    styleUrls: [
        '../../../../../libs/locations/src/lib/location-edit-v2/location-edit-dialogue.scss'
    ]
})
export class FlightTakeoffLandingEditDialogue {
    @Input() missionLocation: LocationDetailsDto;
    @Input() organisationId: number;
    @Input() flight: SortieDto;
    @Input() takeoffPoint: number[];
    @Input() landingPoint: number[];
    @Input() flightLogIds: number[];

    @Output() updatedTakeoffPoint = new EventEmitter<number[]>();
    @Output() updatedLandingPoint = new EventEmitter<number[]>();

    locationFeatures: FeatureGroup[];
    takeOffLandingGroups: FeatureGroup[];

    mapReady$ = new Subject<void>();
    featuresReady$ = new Subject<void>();

    @ViewChild('map', { static: true }) map: FlyFreelyMapComponent;
    @ViewChild('drawingArea', { static: true }) drawingArea: ElementRef;

    selectedFeature: FeatureAndGroup;
    selectedNonEditableFeature: MapFeature;
    selectedTakeoffLanding = false;
    addedFeature: FeatureAndGroup;
    selectedFeatureGroup: FeatureGroup;

    hoverFeatureId: number;
    editFeatureId: number;
    private nextId = 1;

    private hasChanged = false;
    private isLoaded = false;
    isEditing = false;
    isAddingFeature = false;

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private modal: BsModalRef<FlightTakeoffLandingEditDialogue>,
        modalOptions: ModalOptions,
        private flightLogsService: FlightLogsService,
        private locationService: LocationService,
        private missionService: MissionService,
        private organisationService: OrganisationService,
        private commonDialoguesService: CommonDialoguesService,
        private fullscreenService: FullScreenService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        modalOptions.closeInterceptor = () => {
            this.handleEditOnClose();

            if (this.hasChanged) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        combineLatest([this.mapReady$, this.featuresReady$])
            .pipe(debounceTime(300), takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.map.zoomToAllFeatures(true);
            });

        if (
            this.flight.durationSource === SortieDto.DurationSource.LOGS &&
            this.flightLogIds != null
        ) {
            this.findLogFlightAreas();
        } else {
            if (this.missionLocation != null) {
                this.setupMissionFeaturesOnly();
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.mapReady$.complete();
        this.featuresReady$.complete();
    }

    findLogFlightAreas() {
        forkJoin(
            this.flightLogIds.map(id =>
                this.flightLogsService.analyseFlightLog(id)
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                flightSummaries => {
                    this.loadFlightFeatures(flightSummaries);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while analysing log file: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    setupMissionFeaturesOnly() {
        const takeoff: LocationFeatureDto =
            this.takeoffPoint != null
                ? {
                      name: 'Takeoff Point',
                      geometry: <GeoJSON.Point>{
                          coordinates: this.takeoffPoint,
                          type: 'Point'
                      },
                      type: LocationFeatureDto.Type.TAKEOFF
                  }
                : (null as LocationFeatureDto);

        const landing: LocationFeatureDto =
            this.landingPoint != null
                ? {
                      name: 'Landing Point',
                      geometry: <GeoJSON.Point>{
                          coordinates: this.landingPoint,
                          type: 'Point'
                      },
                      type: LocationFeatureDto.Type.LANDING
                  }
                : (null as LocationFeatureDto);

        const combinedFeatures = [takeoff]
            .concat(landing)
            .filter(f => f != null);

        this.takeOffLandingGroups = getTakeoffLandingGroups(
            combinedFeatures,
            1,
            2
        ).features;
        let newFeatureId =
            this.takeOffLandingGroups.reduce(
                (acc, g) =>
                    g.existingFeatures
                        .filter(f => isNumber(f.id))
                        .reduce((acc2, f) => Math.max(acc2, <number>f.id), acc),
                -1
            ) + 1;

        let newGroupId =
            this.takeOffLandingGroups.reduce(
                (acc, g) => Math.max(acc, g.id),
                -1
            ) + 1;

        const missionFeatures =
            this.missionLocation != null
                ? getFeatureGroups(
                      this.missionLocation.features,
                      newFeatureId++,
                      newGroupId++
                  ).features
                : ([] as FeatureGroup[]);

        this.locationFeatures = missionFeatures.concat(
            this.takeOffLandingGroups
        );
        this.nextId = newFeatureId++;
        this.featuresReady$.next();
    }

    loadFlightFeatures(flightSummaries: AnalysedFlightLogDto[]) {
        const flightFeatures = flightSummaries.reduce(
            (acc, flightSummary) =>
                acc.concat(
                    flightSummaryToFeatures(flightSummary).filter(
                        feature =>
                            feature.existingFeatures.reduce(
                                (acc, f) =>
                                    f.geom.type === 'LineString'
                                        ? acc.concat(f)
                                        : acc,
                                []
                            ).length > 0
                    )
                ),
            []
        );
        const logStartPoints = flightSummaries.reduce(
            (acc, flightSummary, i) =>
                acc.concat({
                    name: `Takeoff Point${
                        flightSummaries.length > 1 ? ` ${i + 1}` : ''
                    }`,
                    geometry: <GeoJSON.Point>{
                        coordinates: [
                            flightSummary.startLongitude,
                            flightSummary.startLatitude
                        ],
                        type: 'Point'
                    },
                    type: LocationFeatureDto.Type.TAKEOFF
                }),
            []
        );
        const logEndPoints = flightSummaries.reduce(
            (acc, flightSummary, i) =>
                acc.concat({
                    name: `Landing Point${
                        flightSummaries.length > 1 ? ` ${i + 1}` : ''
                    }`,
                    geometry: <GeoJSON.Point>{
                        coordinates: [
                            flightSummary.endLongitude,
                            flightSummary.endLatitude
                        ],
                        type: 'Point'
                    },
                    type: LocationFeatureDto.Type.LANDING
                }),
            []
        );
        let newFeatureId =
            flightFeatures.length > 0
                ? flightFeatures.reduce(
                      (acc, g) =>
                          g.existingFeatures
                              .filter(f => isNumber(f.id))
                              .reduce(
                                  (acc2, f) => Math.max(acc2, <number>f.id),
                                  acc
                              ),
                      -1
                  ) + 1
                : 1;

        const newGroupId =
            flightFeatures.length > 0
                ? flightFeatures.reduce((acc, g) => Math.max(acc, g.id), -1) + 1
                : 1;

        const takeoff: LocationFeatureDto[] =
            logStartPoints.length > 0
                ? logStartPoints
                : this.takeoffPoint != null
                ? [
                      {
                          name: 'Takeoff Point',
                          geometry: <GeoJSON.Point>{
                              coordinates: this.takeoffPoint,
                              type: 'Point'
                          },
                          type: LocationFeatureDto.Type.TAKEOFF
                      }
                  ]
                : ([] as LocationFeatureDto[]);

        const landing: LocationFeatureDto[] =
            logEndPoints.length > 0
                ? logEndPoints
                : this.landingPoint != null
                ? [
                      {
                          name: 'Landing Point',
                          geometry: <GeoJSON.Point>{
                              coordinates: this.landingPoint,
                              type: 'Point'
                          },
                          type: LocationFeatureDto.Type.LANDING
                      }
                  ]
                : ([] as LocationFeatureDto[]);

        const combinedFeatures = takeoff.concat(landing).filter(f => f != null);

        this.takeOffLandingGroups = getTakeoffLandingGroups(
            combinedFeatures,
            newFeatureId,
            newGroupId
        ).features;

        let missionFeatureId =
            this.takeOffLandingGroups.reduce(
                (acc, g) =>
                    g.existingFeatures
                        .filter(f => isNumber(f.id))
                        .reduce((acc2, f) => Math.max(acc2, <number>f.id), acc),
                -1
            ) + newFeatureId;

        let missionGroupId =
            this.takeOffLandingGroups.reduce(
                (acc, g) => Math.max(acc, g.id),
                -1
            ) + newGroupId;

        const missionFeatures =
            this.missionLocation != null
                ? getFeatureGroups(
                      this.missionLocation.features,
                      missionFeatureId++,
                      missionGroupId++
                  ).features
                : ([] as FeatureGroup[]);

        this.locationFeatures = missionFeatures
            .concat(flightFeatures)
            .concat(this.takeOffLandingGroups);

        this.nextId = newFeatureId++;
        this.featuresReady$.next();
    }

    edit() {
        if (this.takeOffLandingGroups[0].existingFeatures.length === 0) {
            this.map.editFeature({
                groupId: this.takeOffLandingGroups[0].id,
                feature: null
            });
        } else {
            this.map.editFeature({
                groupId: this.takeOffLandingGroups[0].id,
                feature: this.takeOffLandingGroups[0].existingFeatures[0]
            });
        }
    }

    onFeatureNameChange(featureGroup: FeatureGroup, feature: MapFeature) {
        this.map.showFeature(
            {
                feature,
                groupId: featureGroup.id
            },
            true
        );
    }

    onFullscreenRequested() {
        this.fullscreenService.toggleFullScreen(this.drawingArea);
    }

    onEditModeChanged(isEditing: boolean) {
        this.hasChanged = true;

        if (this.isEditing && this.isAddingFeature && !isEditing) {
            this.isAddingFeature = false;
            this.map.showPopup();
            this.setSelectedFeature(this.addedFeature);
        }
        this.isEditing = isEditing;
    }

    onFeatureHover(featureId: number) {
        if (
            this.takeOffLandingGroups.find(
                g => g.existingFeatures.find(f => f.id === featureId) != null
            ) == null
        ) {
            return;
        }
        this.hoverFeatureId = featureId;
    }

    onFeaturesLoaded() {
        if (!this.isLoaded) {
            this.isLoaded = true;
            this.featuresReady$.next();
        }
    }

    onSelectedFeatureUpdated(updated: FeatureAndGroup) {
        this.onFeaturesUpdated(updated);
    }

    onFeaturesUpdated(updated: FeatureAndGroup) {
        if (this.isAddingFeature) {
            return;
        }
        this.hasChanged = true;

        const isNew = updated.feature.id == null;

        const feature = !isNew
            ? updated
            : {
                  groupId: updated.groupId,
                  feature: {
                      ...updated.feature,
                      id: NEW_FEATURE,
                      name: updated.feature.name // this.formGroup.value.name
                  }
              };

        if (feature?.feature.children != null) {
            feature.feature.children.forEach(c => {
                c.categoryId = feature.feature.categoryId;
            });
        }

        const updateFeature = (existing: MapFeature[], toAdd: MapFeature) =>
            !isNew
                ? existing.map(f => (f.id === toAdd.id ? toAdd : f))
                : existing.concat(toAdd);

        this.takeOffLandingGroups = this.takeOffLandingGroups.map(g =>
            g.id === updated.groupId
                ? {
                      ...g,
                      existingFeatures: updateFeature(
                          g.existingFeatures,
                          feature.feature
                      )
                  }
                : g
        );
        this.locationFeatures = this.locationFeatures.map(g =>
            g.id === updated.groupId
                ? {
                      ...g,
                      existingFeatures: updateFeature(
                          g.existingFeatures,
                          feature.feature
                      )
                  }
                : g
        );

        if (feature.feature.id === NEW_FEATURE) {
            this.map.showFeature(feature);
        }
    }

    onFeatureSelected(selectedFeature: FeatureAndGroup) {
        this.selectedNonEditableFeature = null;
        this.selectedTakeoffLanding = false;
        if (this.isAddingFeature) {
            return;
        }
        if (
            this.takeOffLandingGroups.find(
                g => g.id === selectedFeature.groupId
            ) == null ||
            this.flight.durationSource === SortieDto.DurationSource.LOGS
        ) {
            // don't allow editing the point if not part of a group or if the flight has logs
            this.selectedFeature = null;
            this.selectedNonEditableFeature = selectedFeature.feature;
            this.selectedTakeoffLanding =
                (selectedFeature.feature.categoryId != null &&
                    selectedFeature.feature.categoryId ===
                        LocationFeatureDto.Type.TAKEOFF) ||
                selectedFeature.feature.categoryId ===
                    LocationFeatureDto.Type.LANDING;
            return;
        }

        if (
            this.selectedFeature != null &&
            this.selectedFeature.feature.id === selectedFeature.feature.id
        ) {
            this.onFeatureEdit(selectedFeature);
        } else {
            this.setSelectedFeature(selectedFeature);
        }
    }

    onFeatureUnselected() {
        if (!this.isAddingFeature) {
            this.selectedFeature = null;
            this.selectedNonEditableFeature = null;
            this.selectedFeatureGroup = null;
        }
    }

    onFeatureEdit(feature: FeatureAndGroup) {
        this.map.editFeature({
            feature: feature.feature,
            groupId: feature.groupId
        });
        this.setSelectedFeature(feature);
    }

    onFeatureDelete(feature: FeatureAndGroup) {
        const featureGroup = this.takeOffLandingGroups.find(
            fg => fg.id === feature.groupId
        );
        this.deleteFeature(featureGroup, feature.feature);
        this.setSelectedFeature(null);
        this.map.hidePopup();
    }

    onFeatureNameSelected(feature: MapFeature) {
        const groupId = this.takeOffLandingGroups.find(
            g => g.existingFeatures.filter(f => f.id === feature.id).length > 0
        ).id;
        const featureAndGroup: FeatureAndGroup = {
            groupId,
            feature
        };
        this.onFeatureSelected(featureAndGroup);
        this.map.zoomToFeature(feature, true, false);
    }

    addNewFeature(featureGroup: FeatureGroup) {
        this.cancelAddingFeature();

        this.isAddingFeature = true;
        const feature = {
            name: featureGroup.categories[0].name,
            geometry: <GeoJSON.Point>{
                type: 'Point'
            },
            type: featureGroup.categories[0].id as LocationFeatureDto.Type
        };
        const newFeature: MapFeature = toMapFeature(feature, this.nextId++);

        this.selectedFeature = {
            groupId: featureGroup.id,
            feature: newFeature
        };
        this.addedFeature = this.selectedFeature;

        this.takeOffLandingGroups = this.takeOffLandingGroups.map(g =>
            g.id === featureGroup.id
                ? {
                      ...g,
                      existingFeatures: g.existingFeatures.concat(newFeature)
                  }
                : g
        );
        this.locationFeatures = this.locationFeatures.map(g =>
            g.id === featureGroup.id
                ? {
                      ...g,
                      existingFeatures: g.existingFeatures.concat(newFeature)
                  }
                : g
        );

        this.map.editFeature({
            groupId: featureGroup.id,
            feature: newFeature
        });
    }

    cancelAddingFeature() {
        if (this.isAddingFeature && this.selectedFeature != null) {
            // Cancel the existing add first
            this.takeOffLandingGroups = this.takeOffLandingGroups.map(g =>
                g.id === this.selectedFeature.groupId
                    ? {
                          ...g,
                          existingFeatures: g.existingFeatures.filter(
                              f => f.id !== this.selectedFeature.feature.id
                          )
                      }
                    : g
            );
            this.locationFeatures = this.locationFeatures.map(g =>
                g.id === this.selectedFeature.groupId
                    ? {
                          ...g,
                          existingFeatures: g.existingFeatures.filter(
                              f => f.id !== this.selectedFeature.feature.id
                          )
                      }
                    : g
            );
        }
    }

    editFeature(featureGroup: FeatureGroup, feature: MapFeature) {
        this.editFeatureId = <number>feature.id;
        this.map.editFeature({
            feature,
            groupId: featureGroup.id
        });
    }

    deleteFeature(featureGroup: FeatureGroup, feature: MapFeature) {
        this.map.doneEditing();

        this.takeOffLandingGroups = this.takeOffLandingGroups.map(fg =>
            fg.id !== featureGroup.id
                ? fg
                : {
                      ...fg,
                      existingFeatures: fg.existingFeatures.filter(
                          f => f.id !== feature.id
                      )
                  }
        );
        this.locationFeatures = this.locationFeatures.map(fg =>
            fg.id !== featureGroup.id
                ? fg
                : {
                      ...fg,
                      existingFeatures: fg.existingFeatures.filter(
                          f => f.id !== feature.id
                      )
                  }
        );
    }

    highlightFeature(featureGroup: FeatureGroup, highlightFeatureId: number) {
        this.map.highlightFeature(featureGroup.id, highlightFeatureId);
    }

    unhighlightFeature(featureGroup: FeatureGroup, highlightFeatureId: number) {
        this.map.unhighlightFeature(featureGroup.id, highlightFeatureId);
    }

    private setSelectedFeature(feature: FeatureAndGroup) {
        this.selectedFeatureGroup = feature
            ? this.takeOffLandingGroups.find(fg => fg.id === feature.groupId)
            : null;
        if (this.selectedFeatureGroup == null) {
            this.selectedFeature = null;
            return;
        } else {
            this.selectedFeature = feature;
        }
    }

    handleEditOnClose() {
        if (this.isAddingFeature) {
            this.cancelAddingFeature();
            this.map.doneEditing();
            this.onFeatureDelete(this.selectedFeature);
            this.hasChanged = true;
            this.isAddingFeature = false;
        }
        if (this.isEditing) {
            this.map.doneEditing();
            this.hasChanged = true;
        }
    }

    save() {
        this.handleEditOnClose();
        const takeoff = (<GeoJSON.Point>(
            this.takeOffLandingGroups.find(
                g => g.categories[0].id === LocationFeatureDto.Type.TAKEOFF
            ).existingFeatures[0]?.geom
        ))?.coordinates;
        const landing = (<GeoJSON.Point>(
            this.takeOffLandingGroups.find(
                g => g.categories[0].id === LocationFeatureDto.Type.LANDING
            ).existingFeatures[0]?.geom
        ))?.coordinates;
        if (takeoff != null) {
            this.updatedTakeoffPoint.emit(takeoff);
        }
        if (landing != null) {
            this.updatedLandingPoint.emit(landing);
        }
        this.hasChanged = false;
        this.modal.hide();
    }

    onMapReady() {
        this.mapReady$.next();
    }

    close() {
        this.modal.hide();
    }
}
