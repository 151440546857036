import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {
    DownloadProgressWithUrl,
    DownloadService,
    DO_NOTHING
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[downloadUrl]'
})
export class FileDownloadsDirective {
    @Input() downloadUrl: string;

    isDownloading = false;
    initialText: string;
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private downloadService: DownloadService,
        private elRef: ElementRef
    ) {}

    ngOnInit() {
        this.initialText = this.elRef.nativeElement.innerHTML;
        this.downloadService.downloading$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.setElementDisabled(working);
                this.setElementText(working);
            });
        this.downloadService.progress$
            .pipe(
                filter(
                    p =>
                        this.isDownloading &&
                        p.filter(i => i.url === this.downloadUrl) != null
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(progress => {
                const currentProgress = progress.find(
                    i => i.url === this.downloadUrl
                );
                if (currentProgress != null) {
                    this.updateDownloadProgress(currentProgress.progress);
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    @HostListener('click')
    onClick() {
        if (this.downloadUrl != null) {
            this.isDownloading = true;
            this.downloadService.downloadFromUrl(this.downloadUrl);
        }
    }

    setElementDisabled(working: boolean) {
        if (this.elRef.nativeElement.tagName === 'A') {
            if (working) {
                this.elRef.nativeElement.classList.add('disabled');
            } else {
                this.elRef.nativeElement.classList.remove('disabled');
            }
        }
        try {
            this.elRef.nativeElement.disabled = working;
        } catch {
            DO_NOTHING;
        }
    }

    setElementText(working: boolean) {
        if (!this.isDownloading) {
            return;
        }
        const text: string = this.elRef.nativeElement.innerHTML;
        if (this.initialText.length == 0) {
            this.initialText = this.elRef.nativeElement.innerHTML;
        }
        if (
            working &&
            text.includes('Download') &&
            !text.includes('Downloading')
        ) {
            const index = text.indexOf('Download') + 'Download'.length;
            const newText = `${text.slice(0, index)}ing${text.slice(
                index
            )}  0%`;
            this.elRef.nativeElement.innerHTML = newText;
        } else if (
            working &&
            !text.includes('Download') &&
            !text.includes('Downloading')
        ) {
            this.elRef.nativeElement.innerHTML = `${this.initialText} 0%`;
        } else if (!working) {
            this.elRef.nativeElement.innerHTML = this.initialText;
            if (this.isDownloading) {
                this.isDownloading = false;
            }
        }
    }

    updateDownloadProgress(progress: number) {
        const text: string = this.elRef.nativeElement.innerHTML;
        if (this.isDownloading && text.slice(-1) === '%') {
            // Update the current download percentage
            const strippedText = text.split(' ').slice(0, -1).join(' ');
            const newText = `${strippedText} ${progress}%`;
            this.elRef.nativeElement.innerHTML = newText;
        } else if (this.isDownloading && text.slice(-1) !== '%') {
            // Add a download percentage
            this.elRef.nativeElement.innerHTML = `${text} ${progress}%`;
        } else if (!this.isDownloading && text.slice(-1) === '%') {
            this.elRef.nativeElement.innerHTML = this.initialText;
        }
    }
}
