import { Injectable } from '@angular/core';
import {
    FlyFreelyLoggingService,
    LocationFeatureDto
} from '@flyfreely-portal-ui/flyfreely';
import { Feature, Geometry, GeometryCollection } from 'geojson';

export const locationFeatureTypes = [
    { value: 'AREA_OF_INTEREST', name: 'Area Of Interest' },
    { value: 'DANGER', name: 'Danger' },
    { value: 'FLIGHT_AREA', name: 'Flight Area' },
    { value: 'FLIGHT_PATH_LINE', name: 'Flight Path Line' },
    { value: 'GENERAL_LINE', name: 'General Line' },
    { value: 'LANDING', name: 'Landing' },
    { value: 'NO_FLY', name: 'No Fly' },
    { value: 'OBSERVER', name: 'Observer' },
    { value: 'POINT_OF_INTEREST', name: 'Point Of Interest' },
    { value: 'RP', name: 'RP' },
    { value: 'TAKEOFF', name: 'Takeoff' },
    { value: 'TAKEOFF_LANDING', name: 'Takeoff/Landing' },
    { value: 'OFFSET_FLIGHT_AREA', name: 'Corridor Flight Area' },
    { value: 'OFFSET_DANGER_AREA', name: 'Corridor Danger Area' },
    { value: 'OFFSET_NO_FLY_AREA', name: 'Corridor No Fly Area' }
];

export interface UploadedFeature {
    featureType: LocationFeatureDto.Type;
    name: string;
    geometry: Geometry;
}

export interface KmlFeatureGroups {
    type:
        | 'Point'
        | 'MultiPoint'
        | 'LineString'
        | 'MultiLineString'
        | 'Polygon'
        | 'MultiPolygon'
        | 'GeometryCollection';
    featureType: 'Areas' | 'Markers' | 'Lines' | 'Corridors';
    features: UploadedFeature[];
}

export function isSinglePolygon(merged: KmlFeatureGroups[]) {
    return (
        merged.length === 1 &&
        merged[0].type === 'Polygon' &&
        merged[0].features.length === 1
    );
}

@Injectable()
export class KmlUploadService {

    constructor(private logging: FlyFreelyLoggingService) {}

    parseKmlGroupings(featureCollection: GeoJSON.FeatureCollection) {
        return this.extractFeatureGroups(featureCollection.features);
    }

    private extractFeatureGroups(
        importFeatures: Feature[],
        featureNames?: string[]
    ) {
        const extractedGroups: KmlFeatureGroups[] = [];
        const features = importFeatures.filter(
            f => f != null && f.geometry != null
        );
        const failedLength = importFeatures.length - features.length;
        const pushToExtracted = (
            type:
                | 'Point'
                | 'MultiPoint'
                | 'LineString'
                | 'MultiLineString'
                | 'Polygon'
                | 'MultiPolygon'
                | 'GeometryCollection',
            feats: UploadedFeature[]
        ) => {
            const i = extractedGroups.findIndex(g => g.type === type);
            if (i === -1) {
                extractedGroups.push({
                    type: type,
                    featureType: this.findFeatureType(type),
                    features: feats
                });
            } else {
                if (extractedGroups[i].features.length > 0) {
                    extractedGroups[i].features = extractedGroups[
                        i
                    ].features.concat(feats);
                } else {
                    extractedGroups[i].features = feats;
                }
            }
        };

        const types = features.reduce(
            (acc, g) =>
                acc.includes(g.geometry.type)
                    ? acc
                    : acc.concat(g.geometry.type),
            []
        );

        types.forEach(t => {
            if (
                t === 'MultiPolygon' ||
                t === 'MultiPoint' ||
                t === 'MultiLineString'
            ) {
                const geometryType =
                    t === 'MultiPolygon'
                        ? 'Polygon'
                        : t === 'MultiPoint'
                        ? 'Point'
                        : 'LineString';
                const feats = features
                    .filter(f => f.geometry.type === t)
                    .reduce(
                        (acc, f, i) => acc.concat(this.extractMulti(f, i)),
                        []
                    );
                return pushToExtracted(geometryType, feats);
            } else if (t === 'GeometryCollection') {
                const groups = features.filter(
                    f => f.geometry.type === 'GeometryCollection'
                );
                groups.forEach((f: Feature) => {
                    const collection: GeometryCollection = <GeometryCollection>(
                        f.geometry
                    );
                    const geomFeatures: Feature[] = collection.geometries.map(
                        g => ({
                            ...f,
                            geometry: g
                        })
                    );
                    const extracted = this.extractFeatureGroups(geomFeatures);
                    extracted.forEach(item =>
                        pushToExtracted(item.type, item.features)
                    );
                });
            } else {
                const feats = features
                    .filter(f => f.geometry.type === t)
                    .map((f, i) => this.mapFeatures(f, i));
                return pushToExtracted(t, feats);
            }
        });

        if (failedLength > 0) {
            this.logging.error(
                null,
                `Error importing ${failedLength} features. Please verify the uploaded file is correct and try again.`
            );
        }

        return extractedGroups;
    }

    findFeatureType(
        geometryType:
            | 'Point'
            | 'MultiPoint'
            | 'LineString'
            | 'MultiLineString'
            | 'Polygon'
            | 'MultiPolygon'
            | 'GeometryCollection'
    ) {
        switch (geometryType) {
            case 'Polygon':
            case 'MultiPolygon':
                return 'Areas';

            case 'LineString':
            case 'MultiLineString':
                return 'Lines';

            case 'Point':
            case 'MultiPoint':
                return 'Markers';

            default:
                return 'Areas';
        }
    }

    private mapFeatures(feature: Feature, index: number) {
        let type: LocationFeatureDto.Type;
        const name: string = feature.properties?.name;
        // Try to derive type from feature name, otherwise use a generic type
        switch (feature.geometry.type) {
            case 'Polygon':
                type = LocationFeatureDto.Type.FLIGHT_AREA;
                if (name != null) {
                    // Danger seems to be the only real flight area type that will have a unique name apart from the default
                    if (name.toLowerCase().includes('danger')) {
                        type = LocationFeatureDto.Type.DANGER;
                    }
                }
                break;

            case 'LineString':
                type = LocationFeatureDto.Type.FLIGHT_PATH_LINE;
                break;

            case 'Point':
                type = LocationFeatureDto.Type.POINT_OF_INTEREST;
                if (name != null) {
                    if (
                        name.toLowerCase().includes('takeoff') ||
                        name.toLowerCase().includes('take-off') ||
                        name.toLowerCase().includes('take off')
                    ) {
                        type = LocationFeatureDto.Type.TAKEOFF;
                    }
                    if (name.toLowerCase().includes('landing')) {
                        type = LocationFeatureDto.Type.LANDING;
                    }
                    if (name.toLowerCase().includes('pilot')) {
                        type = LocationFeatureDto.Type.RP;
                    }
                    if (name.toLowerCase().includes('observer')) {
                        type = LocationFeatureDto.Type.OBSERVER;
                    }
                }
                break;

            default:
                type = LocationFeatureDto.Type.FLIGHT_AREA;
                break;
        }
        const featureName = locationFeatureTypes.find(t => t.value === type)
            .name;
        return {
            name:
                feature.properties?.name == null
                    ? `${featureName} ${index + 1}`
                    : feature.properties?.name,
            featureType: type,
            geometry: feature.geometry
        };
    }

    private extractMulti(feature: Feature, index: number) {
        let type: LocationFeatureDto.Type;
        switch (feature.geometry.type) {
            case 'MultiPolygon':
                type = LocationFeatureDto.Type.FLIGHT_AREA;
                break;

            case 'MultiLineString':
                type = LocationFeatureDto.Type.FLIGHT_PATH_LINE;
                break;

            case 'MultiPoint':
                type = LocationFeatureDto.Type.POINT_OF_INTEREST;
                break;

            default:
                type = LocationFeatureDto.Type.FLIGHT_AREA;
                break;
        }
        const featureName = locationFeatureTypes.find(t => t.value === type)
            .name;
        const geometryType =
            feature.geometry.type === 'MultiPolygon'
                ? 'Polygon'
                : feature.geometry.type === 'MultiPoint'
                ? 'Point'
                : 'LineString';
        if (
            feature.geometry.type === 'MultiPolygon' ||
            feature.geometry.type === 'MultiPoint' ||
            feature.geometry.type === 'MultiLineString'
        ) {
            const positionSets = feature.geometry.coordinates;
            // @ts-ignore - function incorrectly flags as not callable
            return <UploadedFeature[]>positionSets.map((p, i) => ({
                name:
                    feature.properties?.name == null
                        ? `Multi ${featureName} ${index + i + 1}`
                        : `${feature.properties?.name} ${index + i + 1}`,
                featureType: type,
                geometry: {
                    type: geometryType,
                    coordinates: p
                }
            }));
        }
    }

}
