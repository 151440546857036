import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    AddSharedAuthorityCommand,
    OrganisationRelationshipDto,
    RemoveSharedAuthorityCommand,
    ShareResourceCommand,
    StartOrganisationRelationshipCommand,
    UnshareResourceCommand
} from '../model/api';

@Injectable({
    providedIn: 'root'
})
export class OrganisationRelationshipService {
    private baseUrl: string;
    private changeSource = new Subject<OrganisationRelationshipDto | null>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    addChildRelationship(
        organisationId: number,
        command: StartOrganisationRelationshipCommand
    ) {
        return this.http
            .post<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    removeChildRelationship(organisationId: number, relationshipId: number) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/end`,
                null
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    findChildRelationships(organisationId: number) {
        return this.http.get<OrganisationRelationshipDto[]>(
            `${this.baseUrl}/webapi/organisations/${organisationId}/childRelationships`
        );
    }

    shareAuthorityWithChild(
        organisationId: number,
        relationshipId: number,
        command: AddSharedAuthorityCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/shareAuthority`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    unshareAuthority(
        organisationId: number,
        relationshipId: number,
        command: RemoveSharedAuthorityCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/unshareAuthority`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    shareRpa(
        organisationId: number,
        relationshipId: number,
        command: ShareResourceCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/shareRpa`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    unshareRpa(
        organisationId: number,
        relationshipId: number,
        command: UnshareResourceCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/unshareRpa`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    shareBatterySet(
        organisationId: number,
        relationshipId: number,
        command: ShareResourceCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/shareBatterySet`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    unshareBatterySet(
        organisationId: number,
        relationshipId: number,
        command: UnshareResourceCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/unshareBatterySet`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    shareEquipment(
        organisationId: number,
        relationshipId: number,
        command: ShareResourceCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/shareEquipment`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }

    unshareEquipment(
        organisationId: number,
        relationshipId: number,
        command: UnshareResourceCommand
    ) {
        return this.http
            .put<OrganisationRelationshipDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/relationships/childRelationships/${relationshipId}/unshareEquipment`,
                command
            )
            .pipe(tap(response => this.changeSource.next(response)));
    }
}
