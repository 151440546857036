<form
    [formGroup]="inputForm"
    class="pill-input-container right-buffer"
    @fadeInExpandOnEnter
    @fadeOutCollapseOnLeave
>
    <div
        *ngIf="editName !== false"
        class="info-input-group"
        [ngClass]="{ readonly: readonly }"
    >
        <input
            type="text"
            [placeholder]="nameLabel"
            formControlName="name"
            maxlength="100"
            class="info-input"
            [readonly]="readonly"
        />
        <field-validation-indicator
            class="pull-right"
            [control]="inputForm.controls.name"
        ></field-validation-indicator>
    </div>
    <div
        *ngIf="editSubtype === true"
        class="info-input-group"
        [ngClass]="{ readonly: readonly }"
    >
        <input
            type="text"
            [placeholder]="subtypeLabel"
            formControlName="subtype"
            maxlength="20"
            class="info-input"
            [readonly]="readonly"
        />
        <field-validation-indicator
            class="pull-right"
            [control]="inputForm.controls.subtype"
        ></field-validation-indicator>
    </div>
    <div class="horizontal-container">
        <div
            *ngIf="editValue === true"
            class="info-input-group"
            [ngClass]="{ readonly: readonly }"
        >
            <input
                type="text"
                [placeholder]="valueLabel"
                formControlName="value"
                maxlength="200"
                class="info-input"
                [readonly]="readonly"
            />
            <field-validation-indicator
                class="pull-right"
                [control]="inputForm.controls.value"
            ></field-validation-indicator>
        </div>
        <div class="info-buttons">
            <button
                type="button"
                (click)="addItem()"
                class="btn btn-link"
                [disabled]="inputForm.invalid || readonly"
            >
                CREATE
            </button>
            <button
                type="button"
                (click)="cancelAdd()"
                class="btn btn-tertiary btn-delete"
                tooltip="Cancel"
                placement="left"
                [disabled]="readonly"
            >
                <span class="fal fa-trash-alt"></span>
            </button>
        </div>
    </div>
</form>
