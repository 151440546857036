import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    AirspaceJurisdictionDto,
    JurisdictionService,
    OrganisationDto,
    OrganisationService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { compareJurisdictions } from '../helpers';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'organisation-jurisdictions',
    templateUrl: './organisation-jurisdictions.component.html'
})
export class OrganisationJurisdictions implements OnInit, OnDestroy {
    @Input() organisation: PersonsOrganisationDto;
    @Input() managingOrganisationId: number;
    @Input() buttonClasses: string;

    @Output() saved = new EventEmitter<OrganisationDto>();

    jurisdictions: AirspaceJurisdictionDto[];

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$: Subject<void> = new Subject();

    activeJurisdictionIds: number[];

    constructor(
        private jurisdictionService: JurisdictionService,
        private organisationService: OrganisationService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshJurisdictions();
        this.activeJurisdictionIds = this.organisation.activeJurisdictions.map(
            j => j.id
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshJurisdictions() {
        const doneWorking = this.workTracker.createTracker();
        this.jurisdictionService
            .findJurisdictions()
            .toPromise()
            .then(jurisdictions => {
                doneWorking();
                const sortedJurisdictions = [...jurisdictions];
                sortedJurisdictions.sort(compareJurisdictions);
                this.jurisdictions = sortedJurisdictions;
            });
    }

    save() {
        const doneWorking = this.workTracker.createTracker();
        this.organisationService
            .updateJurisdictions(
                this.organisation.id,
                this.activeJurisdictionIds,
                this.managingOrganisationId
            )
            .toPromise()
            .then(organisation => {
                doneWorking();
                this.saved.emit(organisation);
            });
    }
}
