import { Injectable } from '@angular/core';
import { FlyFreelyConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import {
    SetupGuideActivityDto,
    UpdateSetupGuideActivityCommand
} from '../model/api';
import { httpParamSerializer } from './service.helpers';

@Injectable()
export class SetupGuideService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findActivities(flowType: SetupGuideActivityDto.Type, personId: number) {
        return this.http.get<SetupGuideActivityDto[]>(
            `${this.baseUrl}/webapi/setupguides`,
            {
                params: httpParamSerializer({ flowType, personId })
            }
        );
    }

    updateActivity(command: UpdateSetupGuideActivityCommand) {
        return this.http.put<SetupGuideActivityDto>(
            `${this.baseUrl}/webapi/setupguides/${command.activityId}`,
            command
        );
    }
}
