import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { AirspaceAuthorisationDto } from '@flyfreely-portal-ui/flyfreely';
import {
    authorisationTypesWithMultipleAuthorisations,
    findLatestAirspaceAuthorisationInList
} from '../helpers';
import { AirspaceAuthorisationState } from '../interfaces';

@Component({
    template: `
        <ng-container
            *ngIf="authorisation == null && authorisations?.length > 0"
        >
            <div class="vertical-container flex-centered fill">
                This mission has multiple authorisations.
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                authorisations == null ||
                (authorisations.length <= 1 &&
                    authorisation?.authorisationType !== 'USA_LAANC')
            "
            [ngSwitch]="authorisationStatus"
        >
            <div
                *ngSwitchCase="'PENDING'"
                class="vertical-container flex-centered fill"
            >
                <div class="horizontal-container" style="position: relative;">
                    <span class="far fa-circle status-icon process-line"></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Request Submitted
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="far fa-circle status-icon pending process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label top-buffer">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Pending
                        </span>
                        {{ authorisation?.updateTime | formatDateTime }}
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="far fa-certificate status-icon process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label"> Authorisation ID </span>
                        {{ authorisation?.id }}
                    </div>
                </div>
            </div>
            <div
                *ngSwitchCase="'APPROVED'"
                class="vertical-container flex-centered fill"
            >
                <div class="horizontal-container" style="position: relative;">
                    <span class="far fa-circle status-icon process-line"></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Request Submitted
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-circle status-icon success process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label top-buffer">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Successful
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-certificate status-icon success process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label"> Authorisation ID </span>
                        {{ authorisation?.id }}
                    </div>
                </div>
            </div>
            <div
                *ngSwitchCase="'DECLARED'"
                class="vertical-container flex-centered fill"
            >
                <div class="horizontal-container" style="position: relative;">
                    <span class="far fa-circle status-icon process-line"></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Request Submitted
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-circle status-icon success process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label top-buffer">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Declared
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-certificate status-icon success process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label"> Authorisation ID </span>
                        {{ authorisation?.id }}
                    </div>
                </div>
            </div>
            <div
                *ngSwitchCase="'PROVISIONAL'"
                class="vertical-container flex-centered fill"
            >
                <div class="horizontal-container" style="position: relative;">
                    <span class="far fa-circle status-icon process-line"></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Request Submitted
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-circle status-icon success process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label top-buffer">
                            Provisional Authorisation Approved
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-certificate status-icon success process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label"> Authorisation ID </span>
                        {{ authorisation?.id }}
                    </div>
                </div>
            </div>
            <div
                *ngSwitchCase="'CANCELLED'"
                class="vertical-container flex-centered fill"
            >
                <div class="horizontal-container" style="position: relative;">
                    <span class="far fa-circle status-icon process-line"></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Successful
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-circle status-icon cancel process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label top-buffer">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Request Cancelled
                        </span>
                        {{ authorisation?.cancelTime | formatDateTime }}
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-octagon status-icon cancel process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label"> Authorisation ID </span>
                        {{ authorisation?.id }}
                    </div>
                </div>
            </div>
            <div
                *ngSwitchCase="'CLOSED'"
                class="vertical-container flex-centered fill"
            >
                <div class="horizontal-container" style="position: relative;">
                    <span class="far fa-circle status-icon process-line"></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Successful
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-circle status-icon complete process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label topm-buffer">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Request Closed
                        </span>
                        {{ authorisation?.updateTime | formatDateTime }}
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-certificate status-icon complete process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label"> Authorisation ID </span>
                        {{ authorisation?.id }}
                    </div>
                </div>
            </div>
            <div
                *ngSwitchCase="'REJECTED'"
                class="vertical-container flex-centered fill"
            >
                <div class="horizontal-container" style="position: relative;">
                    <span class="far fa-circle status-icon process-line"></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Submitted
                        </span>
                        {{ authorisation?.createTime | formatDateTime }}
                        <a
                            *ngIf="
                                authorisation?.conditionList &&
                                authorisation?.conditionList.length > 0
                            "
                            (click)="showAuthorisation.emit()"
                            >See conditions</a
                        >
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-circle status-icon cancel process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label topm-buffer">
                            {{
                                authorisation?.authorisationType ==
                                'AUS_CASA_AUTHORISATION'
                                    ? 'Automated '
                                    : ''
                            }}Authorisation Rejected
                        </span>
                        {{ authorisation?.updateTime | formatDateTime }}
                    </div>
                </div>
                <div class="horizontal-container" style="position: relative;">
                    <span
                        class="fas fa-certificate status-icon cancel process-line"
                    ></span>
                    <div class="vertical-container bottom-buffer">
                        <span class="display-label"> Authorisation ID </span>
                        {{ authorisation?.id }}
                    </div>
                </div>
            </div>
        </ng-container>
    `,
    styles: [
        `
            .status-icon {
                font-size: 16px;
                margin-right: 10px;
                margin-left: -5px;
                margin-top: 2px;
            }
            .success {
                color: #00de7a;
            }
            .pending {
                color: #fecb1c;
            }
            .cancel {
                color: #f44336;
            }
            .complete {
                color: #5899db;
            }
            .process-line:after {
                content: '';
                position: absolute;
                border-left: 2px solid #cacad8;
                left: 2px;
                top: calc(1rem + 2px);
                height: calc(100% - 1rem - 2px);
            }
            .border-status-ready-to-fly {
                border-left: 5px solid #00de7a;
                padding-left: 5px;
            }
            .border-status-cancelled {
                border-left: 5px solid #f44336;
                padding-left: 5px;
            }
            .border-status-completed {
                border-left: 5px solid #5899db;
                padding-left: 5px;
            }
        `
    ],
    selector: 'airspace-authorisation-summary',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirspaceAuthorisationSummary {
    @Input()
    authorisations: AirspaceAuthorisationDto[];
    @Input() authorisationStatus: AirspaceAuthorisationState;
    @Output()
    showAuthorisation = new EventEmitter<void>();

    authorisation: AirspaceAuthorisationDto;

    ngOnChanges() {
        if (
            this.authorisations != null &&
            this.authorisations.length > 0 &&
            authorisationTypesWithMultipleAuthorisations.includes(
                this.authorisations[0].authorisationType
            )
        ) {
            this.authorisation = null;
        } else {
            this.authorisation = findLatestAirspaceAuthorisationInList(
                this.authorisations
            );
        }
    }
}
