import { Injectable } from '@angular/core';
import {
    SelectedOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import {
    BatteryService,
    CraftService,
    PersonService
} from '@flyfreely-portal-ui/flyfreely';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class RichTextEditorConfigService {
    constructor(
        private workspaceStateService: WorkspaceStateService,
        private personService: PersonService,
        private craftService: CraftService,
        private batteryService: BatteryService
    ) {}

    async suggestPeople(searchTerm: string) {
        const organisation = await firstValueFrom<SelectedOrganisation>(
            this.workspaceStateService.currentOrganisation$
        );

        if (organisation.type !== 'organisation_loaded') {
            return [];
        }

        const organisationId = organisation.organisation.id;

        const allPersonnel = await firstValueFrom(
            this.personService.findPersonnel(organisationId)
        );

        const personnelOptions = allPersonnel.map(personnel => ({
            id: personnel.id,
            value: `${personnel.firstName} ${personnel.lastName}`
        }));

        return personnelOptions.filter(person =>
            person.value.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    async suggestRpa(searchTerm: string) {
        const organisation = await firstValueFrom<SelectedOrganisation>(
            this.workspaceStateService.currentOrganisation$
        );

        if (organisation.type !== 'organisation_loaded') {
            return [];
        }

        const organisationId = organisation.organisation.id;

        const allRpa = await firstValueFrom(
            this.craftService.findCrafts(organisationId)
        );

        const rpaOptions = allRpa.map(rpa => ({
            id: rpa.id,
            value: rpa.nickname
        }));

        return rpaOptions.filter(rpa =>
            rpa.value.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    async suggestBattery(searchTerm: string) {
        const organisation = await firstValueFrom<SelectedOrganisation>(
            this.workspaceStateService.currentOrganisation$
        );

        if (organisation.type !== 'organisation_loaded') {
            return [];
        }

        const organisationId = organisation.organisation.id;

        const allBatteries = await firstValueFrom(
            this.batteryService.findBatteries(organisationId)
        );

        const batteryOptions = allBatteries.map(battery => ({
            id: battery.id,
            value: battery.name
        }));

        return batteryOptions.filter(battery =>
            battery.value.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
}
