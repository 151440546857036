import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CreateEquipmentCommand,
    EquipmentDto,
    EquipmentTypeDto,
    FlyFreelyConstants,
    NameValue,
    TotalInServiceTime,
    UpdateEquipmentCommand,
    UpdateNotesCommand
} from '@flyfreely-portal-ui/flyfreely';
import { Apollo, gql } from 'apollo-angular';
import { FlightHistoryEntry } from 'libs/flight-history/src/lib/flight-history-data.service';
import { Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
    AssignMaintenanceScheduleCommand,
    FlightHistorySummaryDto,
    MaintenanceScheduleAssignmentDto
} from '../model/api';
import { GqlQueryResult } from './interfaces';
import { RESOURCE_STATUS_LIST } from './resources';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class EquipmentService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<EquipmentDto[]>(
            `${this.baseUrl}/webapi/equipment`,
            {
                params: new HttpParams().set(
                    'organisationId',
                    organisationId.toString()
                )
            }
        );
    }

    findById(equipmentId: number, managingOrganisationId: number) {
        return this.http.get<EquipmentDto>(
            `${this.baseUrl}/webapi/equipment/${equipmentId}`,
            {
                params: new HttpParams().set(
                    'managingOrganisationId',
                    managingOrganisationId.toString()
                )
            }
        );
    }

    create(command: CreateEquipmentCommand) {
        return this.http
            .post<EquipmentDto>(`${this.baseUrl}/webapi/equipment`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    update(equipmentId: number, command: UpdateEquipmentCommand) {
        return this.http
            .put<EquipmentDto>(
                `${this.baseUrl}/webapi/equipment/${equipmentId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    delete(equipmentId: number) {
        return this.http
            .delete(`${this.baseUrl}/webapi/equipment/${equipmentId}`)
            .pipe(tap(() => this.changeSource.next()));
    }

    findTotalTimeInService(
        equipmentId: number,
        managingOrganisationId?: number
    ) {
        return this.http.get<TotalInServiceTime>(
            `${this.baseUrl}/webapi/equipment/${equipmentId}/totalTimeInService`,
            {
                params: httpParamSerializer({
                    managingOrganisationId
                })
            }
        );
    }

    findFlightStatistics(equipmentId: number) {
        return this.http.get<FlightHistorySummaryDto>(
            `${this.baseUrl}/webapi/equipment/${equipmentId}/history/summary`
        );
    }

    findEquipmentFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
            includeType: ('LOGGED' | 'HISTORICAL' | 'UNASSIGNED')[];
        },
        order: 'ASC' | 'DESC',
        page = 0,
        pageSize = 10
    ) {
        return this.apollo
            .query<{
                findEquipmentFlightHistory: GqlQueryResult<FlightHistoryEntry>;
            }>({
                query: gql`
                    query find(
                        $criteria: ResourceFlightHistoryCriteria
                        $order: Direction
                        $pageSize: Int
                        $page: Int
                    ) {
                        findEquipmentFlightHistory(
                            criteria: $criteria
                            order: $order
                            page: $page
                            pageSize: $pageSize
                        ) {
                            count
                            results {
                                id
                                type
                                missionId
                                flightNumber
                                startTime
                                endTime
                                duration
                                visualLineOfSight
                                timeOfDay
                                rpaId
                                rpaType {
                                    id
                                    make
                                    model
                                    rpaCategory
                                    performanceSpecifications {
                                        minTakeOffWeight
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    order,
                    page,
                    pageSize
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findEquipmentFlightHistory)
            );
    }

    assignMaintenanceSchedule(
        equipmentId: number,
        command: AssignMaintenanceScheduleCommand
    ) {
        return this.http
            .put<MaintenanceScheduleAssignmentDto>(
                `${this.baseUrl}/webapi/equipment/${equipmentId}/maintenanceSchedule`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    getEquipmentCategories(): NameValue[] {
        return EQUIPMENT_CATEGORIES;
    }

    getStatuses() {
        return RESOURCE_STATUS_LIST;
    }

    updateEquipmentNotes(equipmentId: number, command: UpdateNotesCommand) {
        return this.http
            .put<EquipmentDto>(
                `${this.baseUrl}/webapi/equipment/${equipmentId}/notes`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}

/**
 * For use with Equipment services only.
 */
export const EQUIPMENT_CATEGORIES = [
    {
        value: EquipmentTypeDto.EquipmentCategory.PAYLOAD,
        name: 'Payload'
    },
    {
        value: EquipmentTypeDto.EquipmentCategory.BASE_STATION,
        name: 'Base Station'
    },
    {
        value: EquipmentTypeDto.EquipmentCategory.CONTROLLER,
        name: 'Controller'
    },
    {
        value: EquipmentTypeDto.EquipmentCategory.CONTROL_ACCESSORIES,
        name: 'Control Accessories'
    },
    {
        value: EquipmentTypeDto.EquipmentCategory.COMMUNICATION_EQUIPMENT,
        name: 'Communication Equipment'
    },
    {
        value: EquipmentTypeDto.EquipmentCategory.MONITOR,
        name: 'Monitor'
    },
    {
        value: EquipmentTypeDto.EquipmentCategory.OTHER,
        name: 'Other'
    }
];
