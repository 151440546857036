import { Component, OnInit, Input } from '@angular/core';

/**
 * Provides the basic card shape and status badges, while allowing the calling
 * component to insert the content that is required, and also control the size
 * by the card fitting to the parent.
 */
@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
    @Input()
    typeName?: string;

    @Input()
    statusStyle?: 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'DANGER';

    @Input()
    statusTooltip?: string;

    @Input()
    statusName?: string;

    @Input()
    selected = false;

    constructor() {}

    ngOnInit(): void {}
}
