import {
    Component,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { document } from 'ngx-bootstrap/utils';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FlyFreelyVideoPlayerOptions } from './helpers';

interface NewVideoElement extends HTMLVideoElement {
    requestPictureInPicture(): any;
}

const defaultSettings: FlyFreelyVideoPlayerOptions = {
    timeDisplay: true,
    speedSelector: false,
    showTotalTime: true,
    allowFullscreen: true,
    allowTracks: false,
    showControls: true,
    allowPictureInPicture: true
};

@Component({
    selector: 'flyfreely-video',
    templateUrl: './flyfreely-video.component.html',
    styles: [
        `
            .pip-button {
                color: #fff;
                background-color: transparent;
                border: none;
                margin-left: 15px;
                padding: 0;
                font-size: 18px;
            }
            .pip-icon {
                transform: rotate(90deg);
            }
        `
    ]
})
export class FlyFreelyVideoPlayer {
    @Input() videoUrl: string;
    @Input() videoType: string;
    @Input() thumbnail: string;
    @Input() playerOptions: FlyFreelyVideoPlayerOptions;
    @Input() autoPlay: boolean = false;
    @Input() enablePiPOnLoad = false;
    @Output() play = new EventEmitter<void>();
    @Output() pipEntered = new EventEmitter<void>();
    @Output() pipClosed = new EventEmitter<void>();
    @Output() percentagePlayed = new EventEmitter<number>();

    @ViewChild('media') videoElement: ElementRef;

    video: NewVideoElement;

    supportsPip: boolean;

    isInPip = false;

    options: FlyFreelyVideoPlayerOptions;

    videoPlayer: VgApiService;

    metadataLoaded: boolean;

    currentPercentagePlayed = 0;

    private ngUnsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.options = {
            ...defaultSettings,
            ...this.playerOptions
        };
        const isPiPSupported = 'pictureInPictureEnabled' in document;

        const isPiPEnabled = document.pictureInPictureEnabled;
        this.supportsPip = isPiPEnabled === true && isPiPSupported === true;
    }

    onPlayerReady(api: VgApiService) {
        this.videoPlayer = api;

        this.videoPlayer
            .getDefaultMedia()
            .subscriptions.timeUpdate.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(time => {
                this.updateTime();
            });

        this.videoPlayer
            .getDefaultMedia()
            .subscriptions.loadedMetadata.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(loaded => {
                this.metadataLoaded = loaded;
            });
    }

    ngAfterViewInit() {
        this.video = this.videoElement.nativeElement;
        this.video.autoplay = this.autoPlay;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    updateTime() {
        const time: {
            current: number;
            total: number;
            left: number;
        } = this.videoPlayer.getDefaultMedia().time;
        const percentage = this.getPercentage(time.current, time.total);
        if (percentage > this.currentPercentagePlayed) {
            this.currentPercentagePlayed = percentage;
            this.percentagePlayed.emit(this.currentPercentagePlayed);
        }
    }

    getPercentage(time: number, duration: number) {
        if (time === 0 || duration === 0) {
            return 0;
        }
        return Math.round((100 * time) / duration);
    }

    onPlaying() {
        if (this.enablePiPOnLoad && !this.isInPip) {
            this.enterPip();
        }
        this.play.emit();
    }

    enterPip() {
        if (!this.metadataLoaded) {
            return;
        }
        this.video.requestPictureInPicture();
        this.video.addEventListener('enterpictureinpicture', () => {
            this.isInPip = true;
            this.pipEntered.emit();
        });
        this.video.addEventListener('leavepictureinpicture', () => {
            this.isInPip = false;
            this.pipClosed.emit();
        });
    }
}
