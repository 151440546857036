import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    CompleteMissionCommand,
    CompleteSortieCommand,
    DisplayableMissionDto,
    FlightLogFileDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionService,
    SortieDto,
    WorkTracker,
    prepareResponses,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FlightLogsDialoguesService } from 'libs/flight-logs/src/lib/flight-logs-dialogues.service';
import { SelectFlightLogs } from 'libs/flight-logs/src/lib/select-flight-logs/select-flight-logs.component';
import { InlineModal } from 'libs/inline-modal/src/lib/inline-modal.component';
import * as moment from 'moment';
import { PopoverDirective } from 'ngx-bootstrap/popover/public_api';
import { ReplaySubject, Subject, combineLatest, from } from 'rxjs';
import { concatMap, first, map, startWith, takeUntil } from 'rxjs/operators';
// Removed the import for SortableOptions (no longer needed)
// import { SortableOptions } from 'sortablejs';
import {
    DisplayableResource,
    MissionCompletionService
} from '../mission-completion.service';
import {
    FlightLogFileWithRpa,
    MissionFlightLogsDirective
} from '../mission-flight-logs.directive';
// Imported Angular CDK drag-and-drop functionality
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
    selector: 'unassigned-mission-flight-logs',
    templateUrl: './unassigned-mission-flight-logs.component.html'
})
export class UnassignedMissionFlightLogs {
    @Input() flightId: number;
    @Input() log: string;
    @Input() inlineModal: InlineModal;
    @Input() readonly: boolean;
    @Input() mission: DisplayableMissionDto;
    @Input() historicalMission = false;
    @Output() missionChanged = new EventEmitter<DisplayableMissionDto>();
    rnd = (10+ Math.floor(Math.random() * 1000))

    flightLogs$ = new ReplaySubject<FlightLogFileWithRpa[]>(1);

    hasLogs = false;

    availablePersonnel: DisplayableResource[];

    // Removed the old SortableJS options
    // flightLogSortOptions = {
    //     group: 'flightLogs',
    //     sort: false,
    //     delay: 1,
    //     disabled: false,
    //     delayOnTouchOnly: true,
    //     onAddOriginal: (evt: any, log: FlightLogFileDto) => {
    //         this.flightLogsData.assignFlightLog(
    //             parseInt(evt.item.dataset.id, 10),
    //             this.flightId
    //         );
    //     },
    // } as SortableOptions;

    popover: PopoverDirective;

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightLogsData: MissionFlightLogsDirective,
        private flightLogsDialoguesService: FlightLogsDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private missionCompletionService: MissionCompletionService,
        private missionService: MissionService,
        private changeDetector: ChangeDetectorRef,
        private logging: FlyFreelyLoggingService,
        private angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        combineLatest([
            this.flightLogsData.working$,
            this.workTracker.observable
        ])
            .pipe(startWith([false, false]), takeUntil(this.ngUnsubscribe$))
            .subscribe(([loading, working]) => {
                this.working = loading || working;
                this.changeDetector.markForCheck();
            });

        this.flightLogsData.flightLogs$
            .pipe(
                map(logs => logs.filter(log => log.flightId === this.flightId)),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(logs => {
                this.hasLogs = logs.length > 0;
                this.flightLogs$.next(logs);
            });

        this.missionCompletionService.availablePersonnel$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(personnel => (this.availablePersonnel = personnel));

        this.missionCompletionService.savedMission$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mission => (this.mission = mission));

        this.flightLogsData.setServiceType(this.historicalMission);
    }

    // New drop method added for handling the drop event of CDK drag-and-drop.
    // It reorders the `flightLogs` array based on the drop event data.
    drop(event: CdkDragDrop<any[]>, array: any[]) {
        console.log('this is drop', event);
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          } else {
          
            transferArrayItem(
              event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex,
            );
            this.flightLogsData.assignFlightLog(
                parseInt(event.container.data[event.currentIndex].id, 10),
                this.flightId
            );
          }
    }

    ngOnDestroy() {
        this.flightLogs$.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    attachLogFiles() {
        const { component, onDestroy } = this.inlineModal.showComponent(
            SelectFlightLogs,
            {
                organisationId: this.mission.organisationId,
                startTime: moment(this.mission.missionDate)
                    .tz(this.mission.timeZone)
                    .startOf('day')
                    .toISOString(),
                endTime: moment(this.mission.missionDate)
                    .tz(this.mission.timeZone)
                    .endOf('day')
                    .toISOString(),
                timeZone: this.mission.timeZone
            }
        );

        component.flightLogsSelected
            .pipe(takeUntil(onDestroy))
            .subscribe((logs: FlightLogFileDto[]) => {
                logs.forEach(fl => this.flightLogsData.assignFlightLog(fl.id));
                this.inlineModal.closeComponent();
            });
    }

    assignLogFiles(flightId: number, logId: number) {
        this.angulartics2.eventTrack.next({
            action: 'assign-log-using-dialogue',
            properties: {
                category: 'flight-logs'
            }
        });
        return this.flightLogsData.assignFlightLog(logId, flightId);
    }

    createFlightsFromLogs() {
        // Grab details needed from the mission.
        const value = this.missionCompletionService.form.value;
        const pilotId =
            this.availablePersonnel.length === 1
                ? this.availablePersonnel[0].id
                : null;

        // Find the currently listed flights and the highest flight number
        const flightList = this.missionCompletionService.form.controls.flights
            .value;

        const maxFlightNumber = flightList
            .map(flight => flight.number)
            .filter((v: number) => v != null)
            .reduce((acc: number, v: number) => Math.max(acc, v), 0);

        this.flightLogs$
            .pipe(first(), takeUntil(this.ngUnsubscribe$))
            .subscribe(flightLogs => {
                const validFlightLogs = flightLogs.filter(fl => fl.rpa != null);
                if (validFlightLogs.length === 0) {
                    this.logging.warn(
                        'There are no valid flight logs with an RPA assigned'
                    );
                    return;
                }
                // flightNumbers will track the newly created flight numbers for assigning logs
                const flightNumbers: number[] = [];

                const sorties: CompleteSortieCommand[] = validFlightLogs.map(
                    (log, index) => {
                        const number = maxFlightNumber + index + 1;
                        flightNumbers.push(number);
                        return {
                            number,
                            pilotId,
                            craftId: log.rpaId,
                            batterySetId: null,
                            equipmentIds: [],
                            status: CompleteSortieCommand.Status.COMPLETED,
                            reason: null,
                            durationSource:
                                CompleteSortieCommand.DurationSource.MANUAL,
                            manualDuration: 0,
                            manualStartTime: toTimestamp(
                                value.actualStartTime,
                                this.mission.timeZone
                            )
                        };
                    }
                );

                const sortieCmd = flightList.concat(sorties);

                const cmd: CompleteMissionCommand = {
                    actualStartTime: toTimestamp(
                        value.actualStartTime,
                        this.mission.timeZone
                    ),
                    actualEndTime: toTimestamp(
                        value.actualEndTime,
                        this.mission.timeZone
                    ),
                    actualLocationId: this.mission.locationId,
                    formResponses:
                        value.documentation != null
                            ? prepareResponses(
                                  value.documentation.formResponses
                              )
                            : {},
                    message: value.journal,
                    outcome: value.outcome,
                    objectiveOutcomeNotes: value.objectiveOutcomeNotes,
                    sorties: sortieCmd
                };

                this.missionService
                    .completeMission(this.mission.id, cmd)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(
                        result => {
                            this.missionChanged.emit(result);
                            this.bulkAttachLogs(
                                flightLogs,
                                result.sorties,
                                flightNumbers
                            );
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(error, error.message);
                        }
                    )
                    .add(this.workTracker.createTracker());
            });
    }

    bulkAttachLogs(
        flightLogs: FlightLogFileWithRpa[],
        sorties: SortieDto[],
        numbers: number[]
    ) {
        return from(flightLogs)
            .pipe(
                concatMap((log, i) => {
                    const flight = sorties.find(s => s.number === numbers[i]);
                    return this.flightLogsData
                        .doAssignFlightLog(log.id, flight.id)
                        .toPromise();
                }),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(
                () => {
                    this.logging.success(
                        `Created ${flightLogs.length} flights and assigned an unassigned flight log to each`
                    );
                    this.angulartics2.eventTrack.next({
                        action: 'create-sorties-from-logs',
                        properties: {
                            category: 'flight-logs'
                        }
                    });
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Something went wrong when creating flights: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    openPopover(pop: PopoverDirective) {
        this.popover = pop;
    }

    visualiseLog(log: FlightLogFileDto) {
        this.flightLogsDialoguesService.showFlightLog(
            log.id,
            this.mission.organisationId,
            this.mission,
            null
        );
    }

    unassignFlightLog(log: FlightLogFileDto) {
        this.angulartics2.eventTrack.next({
            action: 'unassign-log-using-button',
            properties: {
                category: 'flight-logs'
            }
        });
        this.flightLogsData.unassignFlightLog(log.id);
    }

    unattachFlightLog(log: FlightLogFileDto) {
        this.commonDialoguesService.showConfirmationDialogue(
            'Unassign Flight Log',
            `Are you sure you wish to unassign this flight log from this mission?
            The log will be removed from the mission and will no longer be available to assign to a flight.`,
            'Unassign',
            () =>
                new Promise<void>(resolve => {
                    this.flightLogsData.unattachFlightLog(log.id);
                    this.angulartics2.eventTrack.next({
                        action: 'unattach-flight-log',
                        properties: {
                            category: 'flight-logs'
                        }
                    });
           
                    resolve();
                },
            )
        );
    }
}
