<!-- <h4 class="section-heading">
    <span>Battery Health Overview</span>
    <div></div>
</h4>
<div class="alert alert-info">Info summary goes here</div> -->
<div class="inner-container vertical-container fill">
    <h4 class="section-heading">
        <span>Battery Health Per flight</span>
        <div></div>
    </h4>
    <!-- TODO: this should be a gql ng-select -->
    <div class="horizontal-container bottom-buffer">
        <label class="fill-3">Flight</label>
        <div class="fill-1"></div>
        <!-- TODO implement ng-select search -->
        <ng-select
            [items]="flightLogs$ | async"
            bindValue="id"
            [(ngModel)]="selectedFlightLogId"
            [ngModelOptions]="{ standalone: true }"
            (change)="analyseFlightLog()"
            appendTo="body"
            class="fill-8 wide-panel"
            [clearable]="false"
            [searchable]="false"
            placeholder="Select a flight to view battery health"
            [loading]="loading"
            [virtualScroll]="true"
            (scrollToEnd)="onFlightLogSelectScroll()"
            (search)="onFlightLogSelectSearch($event)"
            (close)="onFlightLogSelectClose()"
        >
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item.summary.startTime | formatDate }}
                {{ item.summary.startTime | formatTime }}-{{
                    item.summary.endTime | formatTime
                }}{{ item.mission ? ': ' + item.mission.name : ''
                }}{{ item.flight ? ' - flight #' + item.flight.number : '' }}
            </ng-template>
        </ng-select>
    </div>
    <div
        class="vertical-container fill"
        *ngIf="
            batteryCharge != null ||
            batteryTemperature != null ||
            batteryVoltages != null
        "
    >
        <!-- TODO: figure out how to display these values in a single graph with multiple y-axes and how to toggle the visibility of each layer -->
        <div class="horizontal-container">
            <div
                class="input-group"
                btnRadioGroup
                style="padding-top: 7px"
                [(ngModel)]="selectedDisplayData"
            >
                <label
                    *ngIf="batteryCharge != null"
                    class="btn btn-xs btn-pill"
                    btnRadio="charge"
                    [disabled]="working"
                    >Charge Level</label
                >
                <label
                    *ngIf="batteryTemperature != null"
                    class="btn btn-xs btn-pill"
                    btnRadio="temperature"
                    [disabled]="working"
                    >Temperature</label
                >
                <label
                    *ngIf="batteryVoltages != null"
                    class="btn btn-xs btn-pill"
                    btnRadio="voltage"
                    [disabled]="working"
                    >Voltages</label
                >
            </div>
        </div>
        <ngx-charts-line-chart
            *ngIf="selectedDisplayData == 'charge'"
            class="fill"
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [yScaleMin]="0"
            [yScaleMax]="100"
            [xAxisLabel]="xAxisLabel"
            yAxisLabel="Percentage %"
            [timeline]="timeline"
            [results]="batteryCharge"
        >
        </ngx-charts-line-chart>
        <ngx-charts-line-chart
            *ngIf="selectedDisplayData == 'voltage'"
            class="fill"
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [yScaleMin]="voltageYLimits[0]"
            [yScaleMax]="voltageYLimits[1]"
            [xAxisLabel]="xAxisLabel"
            yAxisLabel="Volts"
            [timeline]="timeline"
            [results]="batteryVoltages"
        >
        </ngx-charts-line-chart>
        <ngx-charts-line-chart
            *ngIf="selectedDisplayData == 'temperature'"
            class="fill"
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [yScaleMin]="temperatureYLimits[0]"
            [yScaleMax]="temperatureYLimits[1]"
            [xAxisLabel]="xAxisLabel"
            yAxisLabel="Degrees C"
            [timeline]="timeline"
            [results]="batteryTemperature"
        >
        </ngx-charts-line-chart>
    </div>
</div>
