import { Directive } from '@angular/core';
import { MapDynamicData } from 'libs/map/src/lib/dynamic-data.service';
import { AirspaceCheckService } from './airspace-check';
import { Subject, takeUntil } from 'rxjs';
import { mapSourceFiltersForResponse } from './helpers';

/**
 * This directive will ensure that the map displays all the relevant map layers, by relaying updates from the airspace checker to the map dynamic data service.
 */
@Directive({
    selector: 'flyfreely-map[airspaceCheckResult]'
})
export class AirspaceCheckResultDirective {
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private airspaceCheckService: AirspaceCheckService,
        private dynamicDataService: MapDynamicData
    ) {}

    ngOnInit() {
        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result =>
                this.dynamicDataService.setProgrammaticMapConfig(
                    this,
                    result != null
                        ? {
                              mapSourceFilters:
                                  mapSourceFiltersForResponse(result),

                              enabledLayerGroups: Object.keys(
                                  result.automatedAuthorisationLayerGroups
                              ).reduce(
                                  (acc, key) =>
                                      acc.concat(
                                          result
                                              .automatedAuthorisationLayerGroups[
                                              key
                                          ]
                                      ),
                                  [] as string[]
                              )
                          }
                        : { enabledLayerGroups: [], mapSourceFilters: {} }
                )
            );
    }
    ngOnDestroy() {
        this.dynamicDataService.clearProgrammaticMapConfig(this);
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
