import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input
} from '@angular/core';
import {
    CompletedDocumentationDto,
    ExclusiveControlService,
    FEATURE_FLIGHT_CONFORMANCE,
    FlightConformanceResultDto,
    GqlFilterField,
    NameValue,
    PersonDto,
    PersonHistoricalLogBookService,
    PersonsOrganisationDto,
    PilotMissionFlightSummary,
    RPA_CATEGORIES,
    RpaTypeDto,
    RpaTypesService,
    UserService,
    WorkTracker,
    fromLocalDate,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import {
    FormatRpaCategoryPipe,
    FormatRpaTypePipe
} from '@flyfreely-portal-ui/resource-ui';
import {
    FormatDatePipe,
    FormatDurationPipe,
    FormatMTOWPipe,
    flightConformanceParsedStates,
    setupStatusFormatter,
    toConformanceResultStatusArray
} from '@flyfreely-portal-ui/ui';
import {
    fadeInExpandOnEnterAnimation,
    fadeOutCollapseOnLeaveAnimation
} from 'angular-animations';
import { getOrElse, map as optionMap } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { dateRange } from 'libs/flyfreely-table/src/lib/search';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    combineLatest,
    firstValueFrom
} from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
    FlightHistoryDataService,
    PilotFlightHistoryEntry,
    PilotFlightSummaryWithConformance
} from '../flight-history-data.service';
import { FlightHistoryDialoguesService } from '../flight-history-dialogues.service';
import { FlightHistoryUploadService } from './flight-history-upload.service';
import {
    FlightSummary,
    ManualFlightSummary,
    RpaModelSummary
} from './interfaces';

interface FlightDocumentation {
    [missionId: number]: { [sortieId: number]: CompletedDocumentationDto };
}

enum Editing {
    NONE = 'NONE',
    ADD = 'ADD',
    EDIT = 'EDIT'
}

@Component({
    selector: 'detailed-flight-history',
    templateUrl: './detailed-flight-history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInExpandOnEnterAnimation(),
        fadeOutCollapseOnLeaveAnimation()
    ],
    host: {
        class: 'vertical-container'
    },
    providers: [FlightHistoryUploadService, FlightHistoryDataService]
})
export class DetailedFlightHistory {
    @Input() currentUser: PersonDto;
    @Input() canEdit: boolean;
    @Input() managingOrganisationId: number;
    @Input() personalHistory = false;

    rows: FlightSummary[] = [];
    loggedFlight$ = new BehaviorSubject<FlightSummary[]>([]);
    manualFlight$ = new Subject<FlightSummary[]>();
    allLoggedFlights: FlightSummary[] = [];
    sorting$ = new BehaviorSubject<{ sortColumn: string; ascending: boolean }>({
        sortColumn: 'date',
        ascending: false
    });
    showHistorical$ = new Subject<boolean>();
    rpaTypes$ = new ReplaySubject<RpaTypeDto[]>(1);

    showHistorical = true;
    sorting: 'ASC' | 'DESC' = 'DESC';
    totalLength = 0;
    page = 0;

    currentlyEditingId: number;
    currentlyEditing: FlightSummary;
    conformanceStates = flightConformanceParsedStates;
    conformanceFilter: string;
    conformanceResultStatus: FlightConformanceResultDto.Status[];
    canUseFlightConformance: boolean;

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    tableSorting: ColumnSortPreferences;
    tableSearch: any;
    tableFilters: GqlFilterField[] = [];

    editing = Editing.NONE;

    rpaCategoryList: NameValue[] = RPA_CATEGORIES;
    timeTypes: NameValue[] = [
        { name: 'Day', value: PilotMissionFlightSummary.TimeOfDay.DAY },
        { name: 'Night', value: PilotMissionFlightSummary.TimeOfDay.NIGHT }
    ];

    sightTypes: NameValue[] = [
        {
            name: 'BVLOS',
            value: PilotMissionFlightSummary.VisualLineOfSight.BVLOS
        },
        {
            name: 'EVLOS',
            value: PilotMissionFlightSummary.VisualLineOfSight.EVLOS
        },
        {
            name: 'VLOS',
            value: PilotMissionFlightSummary.VisualLineOfSight.VLOS
        }
    ];

    flightLogTypes: NameValue[] = [
        {
            name: 'Logged',
            value: 'LOGGED'
        },
        {
            name: 'Manual',
            value: 'MANUAL'
        },
        {
            name: 'Historical',
            value: 'HISTORICAL'
        }
    ];

    rpaModels: RpaModelSummary[];

    rpaModelLookup: { [id: number]: string };

    historyDocumentation: FlightDocumentation;

    organisation: PersonsOrganisationDto | undefined | null;

    private workTracker = new WorkTracker();
    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    isLocked = false;
    unlock: () => void;

    constructor(
        private personHistoricalLogBookService: PersonHistoricalLogBookService,
        private rpaTypesService: RpaTypesService,
        private formatRpaTypePipe: FormatRpaTypePipe,
        private formatRpaCategoryPipe: FormatRpaCategoryPipe,
        private formatMTOWPipe: FormatMTOWPipe,
        private formatDatePipe: FormatDatePipe,
        private formatDurationPipe: FormatDurationPipe,
        private commonDialoguesService: CommonDialoguesService,
        private missionDialoguesService: MissionDialoguesService,
        private userService: UserService,
        private flightHistoryDataService: FlightHistoryDataService,
        private changeDetector: ChangeDetectorRef,
        private exclusiveControlService: ExclusiveControlService,
        private flightHistoryDialogueService: FlightHistoryDialoguesService
    ) {
        this.exclusiveControlService.lock$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locked => {
                this.isLocked = locked;
                if (!locked && this.editing !== Editing.NONE) {
                    this.editing = Editing.NONE;
                    this.currentlyEditingId = null;
                    this.changeDetector.detectChanges();
                }
            });

        this.availableColumns = [
            {
                value: 'flightType',
                name: 'Flight Type',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                searchOptions: this.flightLogTypes,
                linkFunction: (item: FlightSummary) => {
                    if (item.flightType === 'LOGGED') {
                        this.showMission(item.missionId);
                    } else if (item.flightType === 'HISTORICAL') {
                        this.editFlight(item);
                    }
                },
                formatterFunction: setupStatusFormatter(this.flightLogTypes)
            },
            {
                value: 'rpaType',
                name: 'RPA Type',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: (item: RpaTypeDto) =>
                    this.formatRpaTypePipe.transform(item, '-')
            },
            {
                value: 'rpaCategory',
                name: 'RPA Category',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: (item: string) =>
                    this.formatRpaCategoryPipe.transform(item)
            },
            {
                value: 'mtow',
                name: 'MTOW',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: (item: number) =>
                    this.formatMTOWPipe.transform(item)
            },
            {
                value: 'timeOfDay',
                name: 'Time Of Day',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: setupStatusFormatter(this.timeTypes),
                searchOptions: this.timeTypes
            },
            {
                value: 'visualLineOfSight',
                name: 'Line of Sight',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                searchOptions: this.sightTypes
            },
            {
                value: 'locationName',
                name: 'Location',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'flightDate',
                name: 'Date',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                searchFunction: dateRange,
                formatterFunction: (item: string) =>
                    this.formatDatePipe.transform(item)
            },
            {
                value: 'numberOfFlights',
                name: 'Number Of Flights',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'duration',
                name: 'Duration',
                searchable: false,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: (item: number) =>
                    this.formatDurationPipe.transform(item)
            },
            {
                value: 'conformanceResultList',
                name: 'Conformance',
                searchable: true,
                sortable: false,
                selectable: false,
                defaultSelection: true,
                searchOptions: this.conformanceStates
            }
        ];

        this.selectedColumns = null;

        this.tableConfig = {
            serverPagination: true,
            limit: 15,
            actions: [],
            currentPage: 0
        };
    }

    ngOnInit() {
        this.organisation = getOrElse(() => null)(
            this.userService.findOrganisationForUser(
                this.managingOrganisationId
            )
        );
        this.canUseFlightConformance = false;
        if (this.organisation != null) {
            this.canUseFlightConformance = pipe(
                this.userService.findOrganisationForUser(this.organisation.id),
                optionMap(o => hasFeatureFlag(o, FEATURE_FLIGHT_CONFORMANCE)),
                getOrElse(() => false)
            );
        }

        if (!this.canUseFlightConformance) {
            this.availableColumns = this.availableColumns.filter(
                c => c.value !== 'conformanceResultList'
            );
        }

        combineLatest([
            this.workTracker.observable,
            this.flightHistoryDataService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([working, loadingHistory]) => {
                this.working = working || loadingHistory;
                this.changeDetector.detectChanges();
            });

        this.sorting$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sorting => {
                this.page = 0;
                this.sorting = sorting.ascending ? 'ASC' : 'DESC';
                this.allLoggedFlights = [];
                this.refreshPilotHistory();
            });

        combineLatest([
            this.flightHistoryDataService.currentPage$,
            this.flightHistoryDataService.totalItems$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([page, total]) => {
                this.page = page;
                this.totalLength = total;
                const currentConfig = this.tableConfig;
                this.tableConfig = {
                    ...currentConfig,
                    currentPage: page,
                    totalItems: total
                };
            });

        this.flightHistoryDataService.pilotHistory$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(flights => {
                this.setupFlightLogs(flights);
            });

        combineLatest([this.rpaTypes$, this.loggedFlight$, this.sorting$])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([models, logged, sorting]) => {
                this.findRpaModels(models);
                this.rows = logged;
                this.changeDetector.detectChanges();
            });

        this.refreshRpaTypes();
        this.refreshPilotHistory();
        this.changeDetector.detectChanges();

        this.personHistoricalLogBookService.change$
            .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshPilotHistory();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshPilotHistory() {
        this.flightHistoryDataService.findDetailedPilotFlightHistory(
            {
                id: this.currentUser.id,
                managingOrganisationId: this.managingOrganisationId,
                conformanceResultStatus: this.conformanceResultStatus
            },
            this.sorting,
            this.tableConfig.currentPage,
            this.tableConfig.limit
        );
    }

    findRpaModels(models: RpaTypeDto[]) {
        this.rpaModels = [];

        this.rpaModels = models.map(m => ({
            ...m,
            name: this.formatRpaTypePipe.transform(m),
            value: m.id,
            type: m.craftType,
            mtow: m.performanceSpecifications
                ? m.performanceSpecifications.maxTakeOffWeight
                : undefined
        }));
        this.rpaModels.push({
            id: null,
            name: 'Not Listed',
            value: null,
            make: 'Not Listed',
            model: '',
            rpaCategory: null,
            compatibleBatteryTypeIdList: [],
            compatibleBatteryTypeRequirements: {}
        });
        this.rpaModelLookup = this.rpaModels.reduce(
            (acc, m) => ({ ...acc, [m.value]: m.name }),
            {}
        );
    }

    private refreshRpaTypes() {
        this.rpaTypesService
            .findRpaTypes(this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(types => this.rpaTypes$.next(types))
            .add(this.workTracker.createTracker());
    }

    private setupFlightLogs(flights: PilotFlightHistoryEntry[]) {
        if (!this.currentUser) {
            return;
        }

        const logged: FlightSummary[] = flights.map(
            (flight: PilotFlightHistoryEntry) => {
                if (flight.type === 'LOGGED') {
                    return {
                        flightType: 'LOGGED',
                        rpaType: flight.rpaType,
                        rpaCategory: flight.rpaType?.rpaCategory
                            ? flight.rpaType.rpaCategory
                            : null,
                        mtow: flight.rpaType?.performanceSpecifications
                            ?.maxTakeOffWeight
                            ? flight.rpaType.performanceSpecifications
                                  .maxTakeOffWeight
                            : null,
                        timeOfDay: flight.timeOfDay,
                        visualLineOfSight: flight.visualLineOfSight,
                        locationName: flight.locationName,
                        flightDate: fromLocalDate(flight.startTime),
                        numberOfFlights: flight.numberOfFlights,
                        duration: flight.duration,
                        flightId: flight.id,
                        missionId: flight.missionId,
                        conformanceResultList: flight.conformanceResultList
                    };
                } else {
                    return {
                        flightType: 'HISTORICAL',
                        rpaType: flight.rpaType,
                        rpaCategory: flight.rpaType?.rpaCategory
                            ? flight.rpaType.rpaCategory
                            : null,
                        mtow: flight.rpaType?.performanceSpecifications
                            ?.maxTakeOffWeight
                            ? flight.rpaType.performanceSpecifications
                                  .maxTakeOffWeight
                            : null,
                        timeOfDay: flight.timeOfDay,
                        visualLineOfSight: flight.visualLineOfSight,
                        locationName: flight.locationName,
                        flightDate: fromLocalDate(flight.startTime),
                        numberOfFlights: flight.numberOfFlights,
                        duration: flight.duration,
                        flightId: flight.id,
                        missionId: flight.missionId,
                        simulated: false // FIXME: need to get flag sent through
                    };
                }
            }
        );

        this.loggedFlight$.next(logged);
    }

    editFlight(flight: ManualFlightSummary) {
        this.editing = Editing.EDIT;
        this.unlock = this.exclusiveControlService.lock(() => true);
        this.currentlyEditingId = flight.flightId;
        this.currentlyEditing = flight;

        const modal =
            this.flightHistoryDialogueService.showAddFlightHistoryEdit(
                flight,
                this.rpaModels,
                this.rpaCategoryList,
                this.timeTypes,
                this.sightTypes,
                this.currentUser,
                this.currentlyEditingId,
                this.managingOrganisationId
            );

        modal.content.done
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(done => {
                this.onHideFlightEdit();
            });
    }

    showAddFlightForm() {
        this.editing = Editing.ADD;
        this.unlock = this.exclusiveControlService.lock(() => true);
        this.currentlyEditing = null;
        const modal =
            this.flightHistoryDialogueService.showAddFlightHistoryEdit(
                null,
                this.rpaModels,
                this.rpaCategoryList,
                this.timeTypes,
                this.sightTypes,
                this.currentUser,
                null,
                this.managingOrganisationId
            );

        modal.content.done
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(done => {
                this.onHideFlightEdit();
            });
    }

    onHideFlightEdit() {
        this.unlock();
    }

    removeFlight(flight: FlightSummary) {
        this.commonDialoguesService.showConfirmationDialogue(
            'Confirm Delete',
            `Do you wish to delete this flight?`,
            'Yes',
            () =>
                firstValueFrom(
                    this.personHistoricalLogBookService
                        .removeHistoricalFlight(
                            flight.flightId,
                            this.managingOrganisationId
                        )
                        .pipe(takeUntil(this.ngUnsubscribe$))
                )
        );
    }

    filterFlights() {
        this.conformanceResultStatus = toConformanceResultStatusArray(
            this.conformanceFilter
        );
        this.refreshPilotHistory();
    }

    showMission(missionId: number) {
        this.missionDialoguesService.showMissionDetails(
            missionId,
            false,
            this.organisation
        );
    }

    onLoadMore() {
        this.page++;
        this.refreshPilotHistory();
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.refreshPilotHistory();
    }

    onTableSorted(preferences: ColumnSortPreferences) {
        this.tableSorting = preferences;
        this.tableConfig.currentPage = 0;
        // this.refreshPilotHistory();
    }

    onTableSearch(search: any) {
        this.tableSearch = search;
        if (search == null || Object.keys(search).length === 0) {
            this.tableFilters = [];
        } else {
            // const filters: GqlFilterField[] = Object.keys(search)
            //     .filter(
            //         k =>
            //             this.availableColumns.find(
            //                 c => c.value === k.toString()
            //             ).searchable
            //     )
            //     .filter(k => k.toString() !== 'conformanceResultList')
            //     .map(k => ({
            //         field: k.toString(),
            //         filter: search[k]?.toString() ?? ''
            //     }));
            // this.tableFilters = filters;

            const conformanceResultKey = Object.keys(search).find(
                k => k.toString() === 'conformanceResultList'
            );
            this.conformanceResultStatus =
                toConformanceResultStatusArray(search[conformanceResultKey]) ??
                null;
        }
        this.refreshPilotHistory();
    }

    parseConformance(flight: PilotFlightSummaryWithConformance) {
        return flight.conformanceResultList &&
            flight.conformanceResultList.length > 0
            ? flight.conformanceResultList[0]?.status
            : null;
    }
}
