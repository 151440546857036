<div class="container-fluid">
    <form [formGroup]="formGroup" [formIdentifier]="validationFields">
        <div class="horizontal-container fill bottom-buffer vertical-align">
            <span class="display-label" [ngClass]="{ readonly: readonly }">
                Flight {{ formGroup.controls.number.value }}
                <field-validation-indicator
                    [control]="formGroup"
                    placement="top"
                ></field-validation-indicator>
            </span>
            <div
                class="input-group left-buffer"
                btnRadioGroup
                formControlName="status"
            >
                <ng-container *ngFor="let status of sortieStatuses">
                    <label
                        class="btn btn-xs btn-pill"
                        [btnRadio]="status.value"
                        [disabled]="readonly"
                        >{{ status.name }}</label
                    >
                </ng-container>
            </div>
            <ng-content></ng-content>
        </div>
        <div class="horizontal-container fill bottom-buffer">
            <div
                [ngClass]="{
                    'fcol-4': !isNarrowResolution,
                    'fcol-5': isNarrowResolution
                }"
            >
                <label [ngClass]="{ readonly: readonly }">Duration</label>
                <div class="input-group">
                    <div
                        *ngIf="
                            formGroup.controls.durationSource.value === 'LOGS'
                        "
                    >
                        {{ duration | formatDuration  | defaultTo }}
                        <span>
                            <em> Calculated from flight logs </em>
                        </span>
                    </div>
                    <duration
                        *ngIf="
                            formGroup.controls.durationSource.value == null ||
                            formGroup.controls.durationSource.value == 'MANUAL'
                        "
                        formControlName="manualDuration"
                        [showHour]="true"
                        [showMinute]="true"
                        [showSecond]="true"
                        [clearable]="false"
                        [readonly]="readonly"
                    >
                    </duration>
                    <div
                        *ngIf="
                            allowAutomatic &&
                            formGroup.controls.durationSource.value !== 'LOGS'
                        "
                        class="input-group"
                        btnRadioGroup
                        formControlName="durationSource"
                        style="padding-top: 7px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            btnRadio="MANUAL"
                            [disabled]="readonly"
                            >Manual</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            btnRadio="TIMER"
                            [disabled]="readonly"
                            >Timer: {{ duration | formatDuration | defaultTo }} ({{
                                startTime.value | formatTime
                            }}
                            to
                            {{ endTime.value | formatTime }})</label
                        >
                    </div>
                </div>
            </div>
            <div
                *ngIf="
                    formGroup.controls.durationSource.value == null ||
                    formGroup.controls.durationSource.value == 'MANUAL'
                "
                [ngClass]="{
                    'fcol-4': !isNarrowResolution,
                    'fcol-3': isNarrowResolution
                }"
                style="min-width: 11em"
            >
                <div>
                    <label [ngClass]="{ readonly: readonly }">Start Time</label>
                    <timepicker
                        formControlName="manualStartTime"
                        [hourStep]="1"
                        [minuteStep]="1"
                        [showMeridian]="false"
                        [showSpinners]="false"
                        [readonlyInput]="readonly"
                    >
                    </timepicker>
                </div>
            </div>
            <div
                *ngIf="formGroup.controls.durationSource.value == 'LOGS'"
                class="fcol-4"
                style="min-width: 11em"
            >
                <div>
                    <label [ngClass]="{ readonly: readonly }">Start Time</label>
                    <div *ngIf="formGroup.value.startTime">
                        {{ formGroup.value.startTime | formatTime }}
                    </div>
                </div>
            </div>

            <div class="fill">
                <label [ngClass]="{ readonly: readonly }">
                    Flight Objective</label
                >
                <input
                    formControlName="reason"
                    placeholder="Reason for the flight"
                    class="form-control"
                    [readonly]="readonly"
                />
            </div>
        </div>
        <div class="horizontal-container fill bottom-buffer">
            <div class="fcol-4 fill-spacing">
                <label [ngClass]="{ readonly: readonly }">
                    Take off point (lng, lat)
                </label>
                <div class="horizontal-container certical-align">
                    <input
                        [formControl]="
                            takeOffLandingForm.controls.takeOffCoordinates
                        "
                        placeholder="Takeoff coordinates"
                        class="form-control"
                        [readonly]="
                            readonly ||
                            formGroup.controls.durationSource.value === 'LOGS'
                        "
                    />
                    <button
                        type="button"
                        class="btn btn-tertiary"
                        (click)="showTakeoffLandingEdit()"
                        [tooltip]="
                            flightLogsForFlight &&
                            flightLogsForFlight.length > 0
                                ? 'View take off point'
                                : 'Edit take off point'
                        "
                        placement="top"
                        [disabled]="readonly"
                    >
                        <span class="fal fa-map"></span>
                    </button>
                </div>
            </div>
            <div class="fcol-4 fill-spacing">
                <label [ngClass]="{ readonly: readonly }">
                    Landing point (lng, lat)
                </label>
                <div class="horizontal-container vertical-align">
                    <input
                        [formControl]="
                            takeOffLandingForm.controls.landingCoordinates
                        "
                        placeholder="Landing coordinates"
                        class="form-control"
                        [readonly]="
                            readonly ||
                            formGroup.controls.durationSource.value === 'LOGS'
                        "
                    />
                    <button
                        type="button"
                        class="btn btn-tertiary"
                        (click)="showTakeoffLandingEdit()"
                        [tooltip]="
                            flightLogsForFlight &&
                            flightLogsForFlight.length > 0
                                ? 'View landing point'
                                : 'Edit landing point'
                        "
                        placement="top"
                        [disabled]="readonly"
                    >
                        <span class="fal fa-map"></span>
                    </button>
                </div>
            </div>
            <div
                *ngIf="hasFlightDocumentation"
                class="fill"
                style="display: flex; align-items: flex-end"
            >
                <button
                    type="button"
                    class="btn btn-default"
                    [disabled]="readonly"
                    tooltip="Show flight documentation"
                    placement="top"
                    (click)="showSortieDocumentation.emit()"
                >
                    <span class="fal fa-folder"></span>
                   {{DocButtonText}}
                </button>
            </div>
        </div>
        <!-- Validation message container for the coordinate fields above -->
        <div
            class="horizontal-container fill"
            *ngIf="takeOffLandingForm.invalid"
        >
            <div class="horizontal-container fcol-8">
                <div class="fcol-5 fill-spacing">
                    <validation-messages
                        [control]="
                            takeOffLandingForm.controls.takeOffCoordinates
                        "
                    ></validation-messages>
                </div>
                <div class="fcol-1"></div>
                <div class="fcol-5 fill-spacing">
                    <validation-messages
                        [control]="
                            takeOffLandingForm.controls.landingCoordinates
                        "
                    ></validation-messages>
                </div>
                <div class="fcol-1"></div>
            </div>
        </div>
        <div class="horizontal-container fill bottom-buffer">
            <div class="fcol-4 fill-spacing">
                <label [ngClass]="{ readonly: readonly }"> Remote Pilot</label>
                <ng-select
                    appendTo="body"
                    formControlName="pilotId"
                    [items]="availablePersonnel"
                    groupBy="missionStatus"
                    bindLabel="name"
                    bindValue="id"
                    [clearable]="false"
                    [readonly]="readonly"
                >
                </ng-select>
                <p class="help-block" *ngIf="unlistedPilot != null">
                    Unlisted: {{ unlistedPilot }}
                </p>
            </div>
            <div class="fcol-4 fill-spacing">
                <label [ngClass]="{ readonly: readonly }"> RPA</label>
                <ng-select
                    appendTo="body"
                    formControlName="craftId"
                    [items]="availableRpas"
                    [searchFn]="rpaSearch"
                    groupBy="missionStatus"
                    bindLabel="nickname"
                    bindValue="id"
                    [clearable]="false"
                    [readonly]="
                        readonly ||
                        formGroup.controls.durationSource.value === 'LOGS'
                    "
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <rpa-list-item [item]="item"></rpa-list-item>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        <status-icon
                            [rpa]="item"
                            [clickable]="false"
                        ></status-icon>
                    </ng-template>
                </ng-select>
                <p class="help-block" *ngIf="unlistedRpa != null">
                    Unlisted: {{ unlistedRpa }}
                </p>
            </div>
            <div class="fill">
                <label [ngClass]="{ readonly: readonly }"> Battery Set</label>
                <ng-container *ngIf="availableBatterySets">
                    <ng-select
                        appendTo="body"
                        formControlName="batterySetId"
                        [items]="availableBatterySets"
                        [searchFn]="batterySetSearch"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="grouping"
                        [readonly]="readonly"
                    >
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                        >
                            <battery-set-list-item
                                [item]="item"
                            ></battery-set-list-item>
                        </ng-template>
                        <ng-template
                            ng-label-tmp
                            let-item="item"
                            let-index="index"
                        >
                            <status-icon
                                [batterySet]="item"
                                [clickable]="false"
                            ></status-icon>
                        </ng-template>
                    </ng-select>
                </ng-container>
                <p class="help-block" *ngIf="unlistedBatterySet != null">
                    Unlisted: {{ unlistedBatterySet }}
                </p>
            </div>
        </div>
        <div class="fill bottom-buffer" *ngIf="canUseEquipment">
            <label
                *ngIf="availableEquipment && availableEquipment.length"
                [ngClass]="{ readonly: readonly }"
            >
                Equipment</label
            >
            <ng-container
                *ngIf="availableEquipment && availableEquipment.length"
            >
                <ng-select
                    appendTo="body"
                    formControlName="equipmentIds"
                    [items]="availableEquipment"
                    [searchFn]="equipmentSearch"
                    bindLabel="name"
                    bindValue="id"
                    groupBy="grouping"
                    [multiple]="true"
                    [readonly]="readonly"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <equipment-list-item
                            [item]="item"
                        ></equipment-list-item>
                    </ng-template>
                    <ng-template
                        ng-label-tmp
                        let-item="item"
                        let-index="index"
                        let-clear="clear"
                    >
                        <div class="horizontal-container vertical-align">
                            <span
                                class="ng-value-icon left"
                                (click)="clear(item)"
                                aria-hidden="true"
                                >x</span
                            >
                            <status-icon
                                [equipment]="item"
                                [clickable]="false"
                            ></status-icon>
                        </div>
                    </ng-template>
                </ng-select>
            </ng-container>
            <p class="help-block" *ngIf="unlistedEquipment != null">
                Unlisted: {{ unlistedEquipment }}
            </p>
        </div>
        <div *ngIf="hasAdditionalFlightFields" class="fill bottom-buffer">
            <ng-container formArrayName="crew">
                <label class="control-label">
                    <button
                        type="button"
                        class="btn-circle text-center"
                        (click)="addCrew()"
                        [disabled]="readonly || !canAddCrew()"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                    Additional Crew
                </label>
                <div [ngClass]="{ readonly: readonly }" class="fill">
                    <div
                        class="fcol-8 top-buffer"
                        *ngIf="flightCrew.controls.length"
                    >
                        <ng-container
                            *ngFor="
                                let crewMember of flightCrew.controls;
                                let i = index
                            "
                        >
                            <mission-pilot-role-v2
                                [formControl]="crewMember"
                                [pilots]="personnel"
                                [roles]="missionRoles"
                                (deleteCrew)="deleteCrew(i)"
                                [readonly]="readonly"
                            >
                            </mission-pilot-role-v2>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="!hasFlightDocumentation" class="fill bottom-buffer">
            <label [ngClass]="{ readonly: readonly }">Flight Notes</label>
            <textarea
                class="form-control bottom-buffer"
                formControlName="notes"
                maxlength="2000"
            >
            </textarea>
        </div>
        <div *ngIf="hasFlightLogging">
            <unassigned-mission-flight-logs
                [flightId]="formGroup.controls.id.value"
                [log]="'assigned-' + uniqueLogId"
                [readonly]="readonly"
                [mission]="mission"
                [historicalMission]="historicalMission"
            ></unassigned-mission-flight-logs>
        </div>
    </form>
</div>
