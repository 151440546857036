import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralTaskAddDialogue } from './add-task/general-task-add-dialogue.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import {
    Organisation,
    PersonsOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import { GeneralTask } from 'libs/flyfreely/src/lib/services/generalTasks.service';
import { GeneralTaskDetailsDialogueComponent } from './general-task-details-dialogue/general-task-details-dialogue.component';

@Injectable()
export class GeneralTasksDialoguesService {
    constructor(private modelService: BsModalService) {}

    showAddTaskDialogue(organisation: PersonsOrganisationDto) {
        return this.modelService.show(GeneralTaskAddDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                organisation
            }
        });
    }

    showTaskDetailsDialogue(task: GeneralTask, organisation: Organisation) {
        return this.modelService.show(GeneralTaskDetailsDialogueComponent, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                organisation,
                task
            }
        });
    }
}
