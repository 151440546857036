import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    fromLocalDate,
    fromTimestamp,
    MaintenanceActivityDto,
    MaintenanceLogDto,
    MaintenanceService,
    ResourceComponentInServiceTime
} from '@flyfreely-portal-ui/flyfreely';
import { BulkCreateMaintenanceLogCommand } from 'libs/flyfreely/src/lib/model/api/bulkCreateMaintenanceLogCommand';
import { of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    first,
    map,
    pairwise,
    startWith,
    switchMap,
    takeUntil
} from 'rxjs/operators';
import {
    DisplayableMaintenanceActivity,
    DisplayableMaintenanceLogDto,
    DisplayableMaintenanceTask
} from './maintenance-request.service';

export interface ScheduledMaintenanceActivity {
    canEdit: boolean;
    selected: boolean;
    componentId: number;
    componentName: string;
    taskTypeId: number;
    taskTypeName: string;
    timeSinceLast: string;
    status: string;
}

export interface MaintenanceRequestGroupModel {
    maintenanceType: FormControl<MaintenanceLogDto.MaintenanceLogType>;
    resourceCategory: FormControl<MaintenanceLogDto.ResourceCategory>;
    resourceId: FormControl<number>;
    reasonForRequest: FormControl<string>;
    safeToUse: FormControl<boolean>;
}

export interface MaintenanceRequestGroupValue {
    maintenanceType: MaintenanceLogDto.MaintenanceLogType;
    resourceCategory: MaintenanceLogDto.ResourceCategory;
    resourceId: number;
    reasonForRequest: string;
    safeToUse: boolean;
}

export interface MaintenanceBulkRequestModel {
    maintenanceType: BulkCreateMaintenanceLogCommand.MaintenanceLogType;
    resourceCategory: BulkCreateMaintenanceLogCommand.ResourceCategory;
    resourceIdList: Array<number>;
    reasonForRequest: string;
    safeToUse: boolean;
    airworthinessNoticeId?: number;
}

export interface MaintenanceBulkRequestFormModel {
    maintenanceType: FormControl<BulkCreateMaintenanceLogCommand.MaintenanceLogType>;
    resourceCategory: FormControl<BulkCreateMaintenanceLogCommand.ResourceCategory>;
    resourceIdList: FormControl<Array<number>>;
    reasonForRequest: FormControl<string>;
    safeToUse: FormControl<boolean>;
    airworthinessNoticeId: FormControl<number>;
}

export interface MaintenanceDetailsGroupModel {
    maintenancePerformed: FormControl<string>;
    tasks: FormArray<FormControl<DisplayableMaintenanceTask>>;
    maintenanceRemarks: FormControl<string>;
    startDate: FormControl<Date>;
    endTime: FormControl<Date>;
}

export interface MaintenanceDetailsGroupValue {
    maintenancePerformed: string;
    tasks: DisplayableMaintenanceTask[];
    maintenanceRemarks: string;
    startDate: Date;
    endTime: Date;
}

export interface FlightTestGroupModel {
    flightTestRequired: FormControl<boolean>;
}

export interface FlightTestGroupValue {
    flightTestRequired: boolean;
}

export interface IdentifierWorkInstructionsModel {
    identifier: FormControl<string>;
    workInstructions: FormControl<string>;
}

export interface IdentifierWorkInstructionsValue {
    identifier: string;
    workInstructions: string;
}

export interface MaintenanceControllerGroupModel {
    maintenanceControllerNotes: FormControl<string>;
}

export interface MaintenanceControllerGroupValue {
    maintenanceControllerNotes: string;
}

export interface MaintenanceActivityGroupModel {
    description: FormControl<string>;
    maintainerId: FormControl<number>;
    maintenanceOrganisation: FormControl<string>;
    scheduledDate: FormControl<Date>;
    scheduledTasks: FormArray<FormControl<ScheduledMaintenanceActivity>>;
}

export interface MaintenanceActivityGroupValue {
    description: string;
    maintainerId: number;
    maintenanceOrganisation: string;
    scheduledDate: Date;
    scheduledTasks: ScheduledMaintenanceActivity[];
}

export interface FinalisationGroupModel {
    scheduledMaintenanceCompleted: FormControl<boolean>;
    outcome: FormControl<MaintenanceLogDto.Outcome>;
}

export interface FinalisationGroupValue {
    scheduledMaintenanceCompleted: boolean;
    outcome: MaintenanceLogDto.Outcome;
}

export interface MaintenanceFormModel {
    maintenanceRequestGroup: FormGroup<MaintenanceRequestGroupModel>;
    maintenanceActivities: FormGroup<MaintenanceActivityGroupModel>;
    maintenanceDetailsGroup: FormGroup<MaintenanceDetailsGroupModel>;
    maintenanceControllerGroup: FormGroup<MaintenanceControllerGroupModel>;
    finalisationGroup: FormGroup<FinalisationGroupModel>;
}

export interface MaintenanceFormValue {
    maintenanceRequestGroup: MaintenanceRequestGroupModel;
    maintenanceBulkRequest: MaintenanceBulkRequestModel;
    maintenanceActivities: MaintenanceActivityGroupModel;
    maintenanceDetailsGroup: MaintenanceDetailsGroupModel;
    maintenanceControllerGroup: MaintenanceControllerGroupModel;
    finalisationGroup: FinalisationGroupModel;
}

@Injectable()
export class MaintenanceFormService {
    public maintenanceForm: FormGroup<MaintenanceFormModel>;
    public maintenanceRequestGroup: FormGroup<MaintenanceRequestGroupModel>;
    public maintenanceBulkRequest: FormGroup<MaintenanceBulkRequestFormModel>;
    public maintenanceActivityGroup: FormGroup<MaintenanceActivityGroupModel>;
    public maintenanceDetailsGroup: FormGroup<MaintenanceDetailsGroupModel>;
    public flightTestGroup: FormGroup<FlightTestGroupModel>;
    public identifierWorkInstructionsGroup: FormGroup<IdentifierWorkInstructionsModel>;
    public maintenanceControllerGroup: FormGroup<MaintenanceControllerGroupModel>;
    public finalisationGroup: FormGroup<FinalisationGroupModel>;

    public timeZone: string;
    public organisationId: number;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(private maintenanceService: MaintenanceService) {
        this.maintenanceRequestGroup = new FormGroup({
            maintenanceType: new FormControl(undefined, [Validators.required]),
            resourceCategory: new FormControl(undefined, [Validators.required]),
            resourceId: new FormControl(undefined, [Validators.required]),
            reasonForRequest: new FormControl(undefined),
            safeToUse: new FormControl(undefined, [Validators.required])
        });
        this.maintenanceBulkRequest = new FormGroup({
            maintenanceType: new FormControl(undefined, [Validators.required]),
            resourceCategory: new FormControl(undefined, [Validators.required]),
            resourceIdList: new FormControl([], [Validators.required]),
            reasonForRequest: new FormControl(undefined),
            safeToUse: new FormControl(undefined, [Validators.required]),
            airworthinessNoticeId: new FormControl(undefined)
        });

        this.maintenanceActivityGroup = new FormGroup({
            description: new FormControl(undefined, [Validators.required]),
            maintainerId: new FormControl(undefined),
            maintenanceOrganisation: new FormControl(undefined),
            scheduledDate: new FormControl(undefined),
            scheduledTasks: new FormArray([])
        });

        this.maintenanceDetailsGroup = new FormGroup({
            maintenancePerformed: new FormControl(undefined),
            tasks: new FormArray([]),
            maintenanceRemarks: new FormControl(undefined),
            startDate: new FormControl(undefined),
            endTime: new FormControl(undefined, [Validators.required])
        });

        // Keep the multiple components in sync
        this.maintenanceDetailsGroup.controls.endTime.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$), pairwise())
            .subscribe(([previous, current]) => {
                if (previous == null && current != null) {
                    current = new Date(current);
                    current.setHours(12);
                    current.setMinutes(0);
                    current.setSeconds(0);
                    current.setMilliseconds(0);
                }
                this.maintenanceDetailsGroup.controls.endTime.setValue(
                    current,
                    {
                        onlySelf: true,
                        emitEvent: false,
                        emitModelToViewChange: true
                    }
                );
            });

        this.flightTestGroup = new FormGroup({
            flightTestRequired: new FormControl(undefined, [
                Validators.required
            ])
        });

        this.maintenanceControllerGroup = new FormGroup({
            maintenanceControllerNotes: new FormControl(undefined)
        });

        this.finalisationGroup = new FormGroup({
            outcome: new FormControl(undefined, [Validators.required]),
            scheduledMaintenanceCompleted: new FormControl(undefined, [
                Validators.required
            ])
        });

        this.identifierWorkInstructionsGroup = new FormGroup({
            identifier: new FormControl(
                undefined,
                [],
                (control: AbstractControl) => {
                    if (
                        !control.valueChanges ||
                        control.pristine ||
                        control.invalid ||
                        control.value === ''
                    ) {
                        return of(null);
                    } else {
                        return control.valueChanges.pipe(
                            startWith(null),
                            debounceTime(400),
                            distinctUntilChanged(),
                            switchMap(val =>
                                this.maintenanceService.checkIdentifierUnique(
                                    this.organisationId,
                                    val
                                )
                            ),
                            map(res =>
                                res.success
                                    ? null
                                    : { identifierExist: res.message }
                            ),
                            first()
                        );
                    }
                }
            ),
            workInstructions: new FormControl(undefined)
        });

        // @ts-ignore - ignore type sensing
        this.maintenanceForm = new FormGroup({
            maintenanceRequestGroup: this.maintenanceRequestGroup,
            maintenanceBulkRequest: this.maintenanceBulkRequest,
            maintenanceActivities: this.maintenanceActivityGroup,
            maintenanceDetailsGroup: this.maintenanceDetailsGroup,
            flightTestGroup: this.flightTestGroup,
            maintenanceControllerGroup: this.maintenanceControllerGroup,
            finalisationGroup: this.finalisationGroup
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setTasksFromActivity(
        activity: DisplayableMaintenanceActivity,
        components: ResourceComponentInServiceTime[]
    ) {
        const taskList = this.tasks;
        activity.tasks.sort((a, b) => {
            if (a.taskType.name < b.taskType.name) {
                return -1;
            } else if (a.taskType.name > b.taskType.name) {
                return 1;
            } else {
                if (
                    `${a.component.componentType.name} ${a.component.designation}` <
                    `${b.component.componentType.name} ${b.component.designation}`
                ) {
                    return -1;
                } else if (
                    `${a.component.componentType.name} ${a.component.designation}` >
                    `${b.component.componentType.name} ${b.component.designation}`
                ) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        activity.tasks.map(task => {
            if (
                taskList.value.findIndex(
                    t =>
                        t.componentId === task.component.id &&
                        t.taskTypeId === task.taskType.id
                ) === -1
            ) {
                const component = components.find(
                    c => c.id === task.component.id
                );
                const row = new FormControl({
                    required: task.required,
                    completed: task.completed,
                    componentId: task.component.id,
                    componentName: `${task.component.componentType.name} ${task.component.designation}`,
                    notes: task.notes ?? '',
                    taskTypeId: task.taskType.id,
                    taskTypeName: task.taskType.name,
                    serialNumber:
                        task.serialNumber ?? component.serialNumber ?? '',
                    firmwareVersion:
                        task.firmwareVersion ?? component.firmwareVersion ?? ''
                });
                taskList.push(row);
            }
        });
        this.tasks.markAsPristine();
    }

    setupMaintenanceLog(maintenanceLog: DisplayableMaintenanceLogDto) {
        // this.updateValidator(maintenanceLog.resourceCategory);

        this.maintenanceRequestGroup.patchValue({
            maintenanceType: maintenanceLog.maintenanceLogType,
            resourceCategory: maintenanceLog.resourceCategory,
            reasonForRequest: maintenanceLog.reasonForRequest,
            safeToUse: maintenanceLog.safeToUse,
            resourceId: maintenanceLog.resourceId
        });

        this.identifierWorkInstructionsGroup.patchValue({
            identifier: maintenanceLog.identifier,
            workInstructions: maintenanceLog.workInstructionUrl
        });
    }

    setupMaintenanceActivity(maintenanceActivity: MaintenanceActivityDto) {
        this.maintenanceActivityGroup.patchValue({
            description: maintenanceActivity.requiredMaintenanceDescription,
            maintainerId: maintenanceActivity.maintainer?.id,
            maintenanceOrganisation:
                maintenanceActivity.maintenanceOrganisation,
            scheduledDate: fromLocalDate(maintenanceActivity.scheduledDate)
        });
        this.maintenanceDetailsGroup.patchValue({
            maintenancePerformed: maintenanceActivity.maintenancePerformed,
            maintenanceRemarks: maintenanceActivity.maintenanceRemarks,
            startDate: fromLocalDate(maintenanceActivity.startDate),
            endTime: fromTimestamp(maintenanceActivity.endTime, this.timeZone)
        });
        this.flightTestGroup.patchValue({
            flightTestRequired: maintenanceActivity.flightTestRequired
        });
        this.maintenanceControllerGroup.patchValue({
            // flightTestRequired: maintenanceActivity.flightTestRequired,
            maintenanceControllerNotes:
                maintenanceActivity.maintenanceControllerNotes
        });
    }

    private updateValidator(
        resourceCategory: MaintenanceLogDto.ResourceCategory
    ) {
        if (resourceCategory === MaintenanceLogDto.ResourceCategory.CRAFT) {
            this.flightTestGroup.controls.flightTestRequired.setValidators(
                Validators.required
            );
        } else {
            this.flightTestGroup.controls.flightTestRequired.clearValidators();
        }
    }

    get tasks() {
        return <FormArray<FormControl<DisplayableMaintenanceTask>>>(
            this.maintenanceDetailsGroup.get('tasks')
        );
    }
}
