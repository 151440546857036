import { Component, Input } from '@angular/core';
import {
    FlyFreelyConstants,
    PersonService
} from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePicture {
    @Input() personId: number;
    @Input() managingOrganisationId: number;
    @Input() width: number;
    @Input() height: number;
    @Input() shadow = true;

    profilePictureUrl: string;

    isRelative: boolean;
    style: { [style: string]: string };

    defaultImage: string;

    constructor(
        private personService: PersonService,
        constants: FlyFreelyConstants
    ) {
        this.defaultImage = `${constants.IMG_URL}/default-profile.png`;
    }

    ngOnInit() {
        this.style = {
            width: `${this.width}px`,
            height: `${this.height}px`,
            'min-width': `${this.width}px`,
            'min-height': `${this.height}px`
        };

        this.profilePictureUrl = this.personService.getProfilePhotoUrl(
            this.personId,
            this.managingOrganisationId
        );
    }
}
