<enhanced-help
    helpTitle="API Keys"
    componentName="api-keys-edit"
    [compact]="true"
>
</enhanced-help>

<div class="modal-body-content container-scroll">
    <div>
        <div class="well">
            <h5>New API Key</h5>
            <form [formGroup]="apiForm" (ngSubmit)="createKey(apiForm.value)">
                <div class="vertical-container">
                    <create-api-key-form></create-api-key-form>
                    <div class="form-group top-buffer">
                        <button
                            type="submit"
                            class="btn btn-primary pull-right"
                            [disabled]="
                                working || apiForm.invalid || apiForm.pristine
                            "
                        >
                            Create API Key
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="currentKeys != null" class="top-buffer relative">
            <h4>API Keys</h4>

            <static-table
                [availableColumns]="availableColumns"
                [tableData]="currentKeys"
                [tableConfig]="tableConfig"
            >
            </static-table>
        </div>
    </div>
</div>
<ngx-loading [show]="working"></ngx-loading>
