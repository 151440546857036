import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { MobileDeviceWarningDialogue } from 'libs/news-and-support/src/lib/support/mobile-warning/mobile-warning.component';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NotificationDialoguesService } from './notifications-dialogues.service';
import { WhatsNewDialogue } from './whats-new/whats-new-dialogue.component';

@NgModule({
    imports: [
        CommonModule,
        ModalModule.forChild(),
        LoadingIndicatorModule,
        SharedUiModule,
        AnalyticsModule,
        Angulartics2Module
    ],
    declarations: [WhatsNewDialogue, MobileDeviceWarningDialogue],
    providers: [NotificationDialoguesService]
})
export class NotificationModule {}
