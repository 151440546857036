<ng-container *ngIf="isDummy && name != null">
    <div class="status-icon-block vertical-align">
        <div class="status-icon" [tooltip]="toolTipText" placement="top">
            <div>
                <img [src]="dummySource" class="dummy-icon" />
                <div [class]="statusColour" class="dummy-icon"></div>
            </div>
        </div>
        <div class="name-with-icon wrap-overflow">
            <a *ngIf="clickable" (click)="nameClicked.emit()">{{ name }}</a>
            <span *ngIf="!clickable">{{ name }}</span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!isDummy && name != null">
    <div class="status-icon-block vertical-align">
        <div class="status-icon" [tooltip]="toolTipText" placement="top">
            <div [class]="statusColour"></div>
        </div>
        <div class="name-with-icon wrap-overflow">
            <a *ngIf="clickable" (click)="nameClicked.emit()">{{ name }}</a>
            <span *ngIf="!clickable">{{ name }}</span>
        </div>
    </div>
</ng-container>
