import { HttpClient } from '@angular/common/http';
import { FlyFreelyConstants } from '../constants';
import { Injectable } from '@angular/core';
import {
    LocationSummary,
    MissionCriteria,
    MissionSummaryDto
} from '../model/api';
import { httpParamSerializer } from './service.helpers';
import { Apollo, gql } from 'apollo-angular';
import { Point } from 'geojson';
import { GqlFilterField } from './interfaces';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OperationsSummaryService {
    private baseUrl: string;

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    findLocations(organisationId: number) {
        return this.http.get<LocationSummary[]>(
            `${this.baseUrl}/webapi/operationsSummary/locations`,
            {
                params: httpParamSerializer({ organisationId })
            }
        );
    }

    findMissionsAtLocation(locationId: number) {
        return this.http.get<MissionSummaryDto[]>(
            `${this.baseUrl}/webapi/operationsSummary/missions`,
            {
                params: httpParamSerializer({ locationId })
            }
        );
    }

    findMissionLocations(criteria: MissionCriteria, filters: GqlFilterField[]) {
        return this.apollo
            .query<{
                findMissionLocations: {
                    id: number;
                    name: string;
                    representativePoint: Point;
                }[];
            }>({
                query: gql`
                    query find($criteria: MissionCriteria, $filters: [GqlFilterField]) {
                        findMissionLocations(
                            criteria: $criteria
                            filters: $filters
                        ) {
                            id
                            name
                            representativePoint {
                                type
                                coordinates
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    filters
                },
                fetchPolicy: 'network-only'
            })
            .pipe(map(res => res.data.findMissionLocations));
    }
}
