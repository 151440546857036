<div *ngIf="reportingService.isSystemAdmin$ | async" mdcFormField>
    <label mdcFormFieldLabel>Edit Default Reports</label>
    <div mdcSwitch>
        <div mdcSwitchThumb>
            <input
                type="checkbox"
                mdcSwitchInput
                [ngModel]="reportingService.requestAdmin$ | async"
                (change)="reportingService.toggleRequestAdmin()"
            />
        </div>
    </div>
</div>
<div *ngIf="dashboardId != null" class="horizontal-container">
    <editable
        class="fill"
        [confirmationButtons]="true"
        [cancelOnClickOutside]="false"
        [editButton]="false"
        [initialContext]="{ name: name }"
        [disabled]="!canEdit"
        (update)="updateName($event.name)"
    >
        <ng-template viewMode>
            <h4>{{ name || '(No name)' }}</h4>
        </ng-template>
        <ng-template editMode let-context="context">
            <input
                type="text"
                [(ngModel)]="context.name"
                [ngModelOptions]="{
                    standalone: true
                }"
                class="form-control"
                editableOnEnter
            />
        </ng-template>
    </editable>
    <button
        class="btn btn-tertiary"
        type="button"
        (click)="copyReport()"
        tooltip="Make a copy of this report"
    >
        <span class="fal fa-clone"></span>
    </button>
    <button
        *ngIf="canDelete"
        class="btn btn-tertiary btn-delete"
        type="button"
        (click)="deleteReport()"
        tooltip="Delete this report"
    >
        <span class="fal fa-trash-alt"></span>
    </button>
</div>
<vizzly-dashboard
    #dashboard
    [ngClass]="{ hide: dashboardId == null }"
></vizzly-dashboard>
<router-outlet></router-outlet>