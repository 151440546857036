import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GraphqlSubscriptionStateService } from '@flyfreely-portal-ui/graph-ql';
import { KeycloakService } from 'keycloak-angular';
import { HttpMethods } from 'keycloak-angular/lib/core/interfaces/keycloak-options';
import { Observable, from, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { uiVersion } from '../utils';
import {
    CardDeclinedException,
    InvalidOperation,
    NotFound,
    OperationFailed,
    OperationForbidden,
    SessionExpired,
    TemplateError,
    Unauthorised,
    UnknownError,
    ValidationError
} from './errors';
import { LoginManager } from './system/login-manager';

/**
 * work around for https://github.com/angular/angular/issues/19103
 * @param response error response
 */
export function parseErrorBody(response: HttpErrorResponse) {
    switch (response.status) {
        case 400:
            if (response.error.reason === 'TemplateError') {
                return new TemplateError(
                    response?.error?.message,
                    response?.error?.error?.line
                );
            } else if (response.error.reason === 'CardDeclinedException') {
                return new CardDeclinedException(
                    response?.error?.error?.declineCode,
                    response?.error?.message,
                    response?.error?.code,
                    response?.error?.error?.fields
                );
            } else if (response.error.baseClass === 'InvalidOperation') {
                return new InvalidOperation(
                    response?.error?.reason,
                    response?.error?.message,
                    response?.error?.code,
                    response?.error?.fields,
                    response?.error?.fieldErrors,
                    response?.error?.stateErrors
                );
            }
            return new UnknownError(response);
        case 401:
            return new Unauthorised();
        case 403:
            return new OperationForbidden();
        case 404:
            return new NotFound();
        case 422:
            return new ValidationError(response?.error?.error?.fields);
        case 500:
            if (response.error.code === 'OPERATION_ERROR') {
                return new OperationFailed(
                    response?.error?.reason,
                    response?.error?.message,
                    response?.error?.code,
                    response?.error?.error?.fields
                );
            }
            return new UnknownError(response);
        default:
            return new UnknownError(response);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FlyFreelyHttpInterceptor implements HttpInterceptor {
    xFlyFreelyUi: string;
    constructor(
        private keycloakService: KeycloakService,
        private loginManager: LoginManager,
        private graphqlSubscriptionState: GraphqlSubscriptionStateService
    ) {
        this.xFlyFreelyUi = `OfficeApp ${uiVersion}`;
    }

    isUrlExcluded(
        { method, url }: any,
        urlPattern: RegExp,
        httpMethods?: HttpMethods[]
    ) {
        const httpTest =
            httpMethods == null ||
            httpMethods.length === 0 ||
            httpMethods.join().indexOf(method.toUpperCase()) > -1;
        const urlTest = urlPattern.test(url);
        return httpTest && urlTest;
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Remote calls do not need the extra headers
        const isRemoteCall = !request.url.startsWith('/');
        const newRequest = isRemoteCall
            ? request
            : request.clone({
                  headers: request.headers
                      .set('x-requested-with', 'XMLHttpRequest')
                      .set('x-flyfreely-ui', this.xFlyFreelyUi)
              });
        const { excludedUrls } = this.keycloakService;
        const shallPass =
            (excludedUrls ?? []).findIndex(item =>
                this.isUrlExcluded(request, item.urlPattern, item.httpMethods)
            ) > -1;
        if (shallPass) {
            this.handleRequest(request, next);
        }

        return from(this.keycloakService.isLoggedIn()).pipe(
            mergeMap(loggedIn =>
                loggedIn
                    ? this.handleRequestWithTokenHeader(newRequest, next)
                    : this.handleRequest(newRequest, next)
            )
        );
    }

    handleRequestWithTokenHeader(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const replacementToken = this.loginManager.getImpersonationToken();
        // only use the impersonation token when not in system admin. This is done by checking the url
        const isAdminPath =
            window.location.href.slice(window.origin.length).slice(0, 6) ===
            '/admin';
        if (replacementToken && !isAdminPath) {
            const kcReq = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${replacementToken}`
                }
            });

            return this.handleRequest(kcReq, next);
        } else {
            return this.keycloakService.addTokenToHeader(request.headers).pipe(
                mergeMap(headersWithBearer => {
                    const kcReq = request.clone({ headers: headersWithBearer });
                    return this.handleRequest(kcReq, next);
                })
            );
        }
    }

    /**
     * When there is a 401 clean up the GraphQL connections, then ensure the login manager is logged out. Login redirects are the responsibility of the Authenticated route guard..
     * @param request
     * @param next
     * @returns
     */
    handleRequest(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    // Close all GQL websocket connections
                    this.graphqlSubscriptionState.closeAll();

                    this.keycloakService.isLoggedIn().then(loggedIn => {
                        if (loggedIn) {
                            this.loginManager.logout();
                        }
                    });

                    return throwError(() => new SessionExpired());
                }
                return throwError(() => parseErrorBody(err));
            })
        );
    }
}
