import { Component, Input } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    ReferralReport,
    ReferralService,
    ReferralSourceStatistics,
    ReferralSourceSummary,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferralReportService } from '../referral-reports.service';

interface ReferralSummary {
    firstVisit: moment.Moment;
    referralUrl: string;
    signupUrl: string;
    landingUrl: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    signupTime: moment.Moment;
    organisationName: string;
    organisationCreationTime: moment.Moment;
}

function convertToTableData(reports: ReferralReport[]): ReferralSummary[] {
    return reports
        .map(ref => {
            return ref.organisations.map(o => ({
                firstVisit:
                    ref.firstVisit != null ? moment(ref.firstVisit) : null,
                referralUrl: ref.referralUrl,
                signupUrl: ref.signupUrl,
                landingUrl: ref.landingUrl,
                firstName: ref.person.firstName,
                lastName: ref.person.lastName,
                email: ref.person.email,
                phoneNumber: ref.person.phoneNumber,
                signupTime:
                    ref.person.signupTime != null
                        ? moment(ref.person.signupTime)
                        : null,
                organisationName: o.name,
                organisationCreationTime:
                    o.creationTime != null ? moment(o.creationTime) : null
            }));
        })
        .reduce((acc, ref) => acc.concat(ref), []);
}

@Component({
    selector: 'referral-source',
    template: `
        <div class="panel panel-default">
            <div *ngIf="!hideHeading" class="panel-heading">
                <ng-container *ngIf="referralSummary.type == 'PROMOTER'">
                    Promoter link
                    {{
                        referralSummary.name != null
                            ? '(' + referralSummary.name + ')'
                            : ''
                    }}:
                    <span class="display-label">
                        {{ referralSummary.signupUrl }}
                    </span>
                    <copy-to-clipboard
                        [content]="referralSummary.signupUrl"
                    ></copy-to-clipboard>
                </ng-container>
                <ng-container *ngIf="referralSummary.type == 'INVITE'">
                    Invites
                </ng-container>
                <button
                    class="btn btn-default btn-tertiary pull-right"
                    type="button"
                    (click)="downloadReport()"
                    tooltip="Download report"
                >
                    <span class="fal fa-download"></span>
                </button>
            </div>

            <div class="panel-body">
                <div class="data">
                    <span class="display-label">Signups</span
                    >{{ statistics?.totalSignups }}
                </div>
                <div class="data">
                    <span class="display-label">First Signup</span
                    >{{ statistics?.firstSignupTime | formatDateTime }}
                </div>
                <div class="data">
                    <span class="display-label">Latest Signup</span
                    >{{ statistics?.lastSignupTime | formatDateTime }}
                </div>
                <div class="data">
                    <span class="display-label">Organisations</span
                    >{{ statistics?.totalOrganisations }}
                </div>
                <div class="data">
                    <span class="display-label">First Creation</span
                    >{{ statistics?.firstCreationTime | formatDateTime }}
                </div>
                <div class="data">
                    <span class="display-label">Latest Creation</span
                    >{{ statistics?.lastCreationTime | formatDateTime }}
                </div>
            </div>
        </div>
    `
})
export class ReferralSource {
    @Input()
    referralSummary: ReferralSourceSummary;

    @Input()
    hideHeading: boolean;

    reports: ReferralReport[];
    tableData: ReferralSummary[];

    statistics: ReferralSourceStatistics;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private logging: FlyFreelyLoggingService,
        private referralService: ReferralService,
        private referralReportService: ReferralReportService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.referralService
            .findSourceStatistics(this.referralSummary.id)
            .subscribe(
                statistics => {
                    this.statistics = statistics;
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching statistics: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    public downloadReport() {
        this.referralService
            .findSourceReport(this.referralSummary.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(report =>
                this.referralReportService.generateReportCsv(report)
            )
            .add(this.workTracker.createTracker());
    }
}
