import { downloadCsv } from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SearchOptions, TableColumn } from './interfaces';

/**
 * Resolve the inner value of an object, including using array notation.
 *
 * @param obj the object being traversed into
 * @param part the traversal path
 */
function resolveIndexedValue(obj: any, part: string) {
    if (part.indexOf('[') !== -1) {
        const startIx = part.indexOf('[');
        const endIx = part.indexOf(']');
        const basePart = part.substr(0, startIx);
        const extraPart = part.substr(startIx + 1, endIx - startIx - 1);
        return obj[basePart][extraPart];
    }
    return obj[part];
}

/**
 * Read the value of an object from the path.
 * @param object the object to read
 * @param path the dot notation path
 */

export function resolveValue(object: any, path: string) {
    if (object == null || path == null) {
        return undefined;
    }

    const parts = path.split('.');
    return parts.reduce(
        (acc, part) => (acc ? resolveIndexedValue(acc, part) : null),
        object
    );
}

/**
 * Fully populate the TableColumn definition.
 *
 * @param column the declared column
 */
export function initColumn(column: TableColumn): TableColumn {
    if (column.key == null && column.value == null) {
        throw new Error(
            'Either a key or a value must be supplied for a column'
        );
    }
    const key = column.key ?? column.value;
    const value = column.value ?? key;
    return {
        ...column,
        key,
        value,
        searchable: resolveSearchable(column)
    };
}

/**
 * Turn the implicit search settings into an explicit search setting.
 * @param column the initial search option
 */
function resolveSearchable(column: TableColumn): SearchOptions {
    if (column.searchable === true) {
        return column.searchOptions == null ? 'text' : 'selection';
    }

    if (
        column.searchable === 'date' ||
        column.searchable === 'daterange' ||
        column.searchable === 'selection' ||
        column.searchable === 'text'
    ) {
        return column.searchable;
    }

    return false;
}

/**
 * Returns a column list for the defaultly selected columns.
 */
export function findDefaultColumnSelection(columns: TableColumn[]) {
    return columns.filter(c => c.defaultSelection).map(c => c.key ?? c.value);
}

/**
 * Downloads a .csv file that mirrors the table's columns and formatted values
 * @param tableColumns the TableColumn array used to configure the static table. Ususally named "availableColumns"
 * @param tableData the data object passed to the table in the HTML
 * @param label the name for the generated .csv file
 */
export function downloadStaticTable(
    tableColumns: TableColumn[],
    tableData: any,
    label: string
) {
    const config = generateFormlyConfig(tableColumns, label);
    const data = parseData(tableColumns, tableData, config);
    // downloadCsv(config, data);
    downloadCsv(config, data);
}

function parseData(
    tableColumns: TableColumn[],
    data: any,
    config: FormlyFieldConfig
) {
    // Check if fieldArray is a function and call it if necessary
    const fieldArray = <FormlyFieldConfig>config.fieldArray;

    // const headings = config.fieldArray.fieldGroup.map(col => col.key);
    const headings = fieldArray?.fieldGroup?.map(col => col.key) || [];

    const formatValue = (item: any, key: string) => {
        const val = resolveValue(item, key);
        const col = tableColumns.find(c => c.value === key);
        if (col.formatterFunction != null) {
            return col.formatterFunction(val, item);
        }
        return val != null ? val.toString() : '';
    };

    return data.map((item: any) =>
        headings.reduce(
            (acc, key: string) => ({
                ...acc,
                [key]: formatValue(item, key)
            }),
            {}
        )
    );
}

function generateFormlyConfig(tableColumns: TableColumn[], label: string) {
    const generateFieldGroups = () => {
        return tableColumns.map(col => ({
            key: col.value,
            props: {
                label: col.name
            }
        }));
    };

    const formly: FormlyFieldConfig = {
        fieldArray: {
            fieldGroup: generateFieldGroups()
        },
        props: {
            label: label
        }
    };

    return formly;
}
