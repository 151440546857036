<div class="panel widget craft">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-rpa.png" />
        </div>
        <div class="panel-title">RPA (Remotely Piloted Aircraft)</div>

        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                *ngIf="canReport"
                class="btn btn-inline"
                (click)="showRpaReportsDialogue()"
                tooltip="Rpa reports"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-rpa-reports"
            >
                <i class="fa-print fa"></i>
            </button>
            <button
                class="btn btn-inline"
                (click)="newCraft()"
                *ngIf="canAddCraft"
                tooltip="Add a new RPA"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-rpa-add"
                data-cy="addNewRpaButton"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>

    <div class="panel-body">
        <loading-indicator [isLoading]="working"></loading-indicator>
        <static-table
            *ngIf="crafts?.length > 0"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="crafts"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
            data-cy="rpaTable"
        >
            <ng-template
                columnTemplate="nickname"
                let-row="row"
                let-column="column"
            >
                <status-icon
                    [rpa]="row"
                    (nameClicked)="showCraft(row)"
                ></status-icon>
            </ng-template>
        </static-table>
        <empty
            *ngIf="canAddCraft && crafts && crafts.length == 0"
            componentName="rpa-empty-editable"
        ></empty>
        <empty
            *ngIf="!canAddCraft && crafts && crafts.length == 0"
            componentName="rpa-empty"
        ></empty>
        
    </div>
</div>
