<div
    class="set-background-image"
    [ngClass]="{
        'bg-personal-details': currentTab == 'PERSONAL_DETAILS',
        'bg-personal-flying-preference':
            currentTab == 'PERSONAL_FLIGHT_PREFERENCES',
        'bg-personal-certification': currentTab == 'PERSONAL_NONPRIMARY',
        'bg-personal-primary': currentTab == 'PERSONAL_PRIMARY',
        'bg-flight-history': currentTab == 'FLIGHT_HISTORY',
        'bg-personal-setup-complete': currentTab == 'PERSONAL_SETUP_COMPLETE',
        'bg-organisation-details': currentTab == 'ORGANISATION_DETAILS',
        'bg-organisation-certification':
            currentTab == 'ORGANISATION_CERTIFICATION',
        'bg-organisation-setup-complete':
            currentTab == 'ORGANISATION_SETUP_COMPLETE'
    }"
>
    <div class="full_containter onboarding-overlay">
        <onboarding-header></onboarding-header>
        <onboarding-welcome-block></onboarding-welcome-block>
        <onboarding-screens></onboarding-screens>
        <personal-footer
            *ngIf="activeFooter == 'PERSONAL_DETAILS'"
        ></personal-footer>
        <organisation-footer
            *ngIf="activeFooter == 'ORGANISATION_DETAILS'"
        ></organisation-footer>
    </div>
</div>
