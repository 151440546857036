import { Component, Input } from '@angular/core';
import { PersonsOrganisationDto } from '@flyfreely-portal-ui/flyfreely';
import { hasFeatureFlag } from '@flyfreely-portal-ui/flyfreely';
import { FEATURE_SCHEDULED_MAINTENANCE } from '@flyfreely-portal-ui/flyfreely';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceMaintenanceDataService } from './resource-maintenance-data.service';

@Component({
    selector: 'resource-maintenance',
    template: ` <ng-container screenAnalytics="resource-maintenance">
        <enhanced-help
            screenName="resource-maintenance"
            componentName="screen"
            [compact]="true"
        ></enhanced-help
        ><tabset>
            <tab heading="Configuration">
                <enhanced-help
                    screenName="resource-maintenance"
                    componentName="configuration"
                ></enhanced-help>
                <rpa-type-customisation
                    class="modal-body-content"
                    [organisation]="organisation"
                >
                </rpa-type-customisation>
            </tab>
            <tab
                *ngIf="hasScheduledMaintenance"
                heading="Maintenance Schedules"
            >
                <ng-template tabHeading>
                    <new-feature
                        type="BETA"
                        name="Maintenance Schedules"
                        description="Automatically take action when RPA are due for maintenance"
                    ></new-feature
                ></ng-template>
                <plan-highlight
                    [featureFlags]="maintenanceScheduleFeature"
                ></plan-highlight>
                <enhanced-help
                    screenName="resource-maintenance"
                    componentName="rpa-schedules"
                ></enhanced-help>
                <maintenance-schedule-list
                    class="modal-body-content"
                    [organisation]="organisation"
                ></maintenance-schedule-list>
            </tab>
            <tab *ngIf="true" heading="Battery Types">
                <battery-type-list
                    class="modal-body-content"
                    [organisation]="organisation"
                >
                </battery-type-list>
            </tab>
            <tab *ngIf="true" heading="RPA Types">
                <rpa-type-list
                    class="modal-body-content"
                    [organisation]="organisation"
                    [canEdit]="true"
                >
                </rpa-type-list>
            </tab> </tabset
    ></ng-container>`,
    providers: [WorkTracker, ResourceMaintenanceDataService]
})
export class ResourceMaintenance {
    @Input() organisation: PersonsOrganisationDto;

    hasScheduledMaintenance: boolean;
    maintenanceScheduleFeature = [FEATURE_SCHEDULED_MAINTENANCE];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(private workspaceStateService: WorkspaceStateService) {}

    ngOnInit(): void {
        this.workspaceStateService.currentOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(event => {
                if (event.type === 'organisation_loaded') {
                    this.organisation = event.organisation;

                    this.hasScheduledMaintenance = hasFeatureFlag(
                        this.organisation,
                        FEATURE_SCHEDULED_MAINTENANCE
                    );
                } else {
                    this.organisation = null;
                    this.hasScheduledMaintenance = false;
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
