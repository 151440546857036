<!-- Dependency warning "popover". This allows better control of the behaviour than a normal popover -->
<div
    *ngIf="authorityGroup != null && showDependentWarning"
    class="dependency-warning-container"
    @fadeInExpandOnEnter
    @fadeOutCollapseOnLeave
>
    <div class="dependency-warning-popup">
        <div class="dependency-warning-heading">
            {{ authorityGroup.name }}
            <button
                type="button"
                class="close-warning-button"
                (click)="showDependentWarning = !showDependentWarning"
            >
                ×
            </button>
        </div>
        <div class="dependency-warning-content">
            {{ authorityGroup.abbreviation ?? authorityGroup.name }} is required
            by {{ dependentWarningTypeNames }}
        </div>
    </div>
</div>

<!-- Authority Card -->
<card
    *ngIf="authorityGroup != null && card != null"
    @fadeInExpandOnEnter
    [typeName]="card.typeName"
    [statusName]="card.statusName"
    [statusStyle]="card.statusStyle"
    [statusTooltip]="card.statusTooltip"
>
    <div>
        <div class="fill authority-card-body">
            <div class="authority-title">
                <h4>
                    {{ authorityGroup.name }}
                    <i
                        *ngIf="
                            authorityGroup.description &&
                            authorityGroup.authorities.length === 0
                        "
                        class="fal fa-info-circle left-buffer"
                        [tooltip]="authorityGroup.description"
                        container="helper-content"
                    ></i>
                </h4>
            </div>
            <p class="authority-sub-title">
                Issued by {{ authorityGroup.issuedBy.name }}
            </p>
            <div *ngIf="authorityGroup.authorities.length === 0">
                <p
                    class="help-block pre-wrap"
                    [innerHtml]="authorityGroup.helperText | autolink"
                ></p>
            </div>
            <div
                *ngIf="
                    authorityGroup.discontinueDate != null &&
                    authorityGroup.discontinueMessage != null
                "
                class="alert alert-info"
                [innerHtml]="authorityGroup.discontinueMessage"
            ></div>
            <div
                *ngIf="
                    authorityGroup.discontinueDate != null &&
                    authorityGroup.discontinueMessage == null
                "
                class="alert alert-info"
                [innerHtml]="'Discontinued on ' + discontinuedDate"
            ></div>
            <div *ngFor="let authority of authorityGroup.authorities">
                <ng-container
                    *ngIf="
                        showArchived ||
                        (!showArchived && !authority.archived) ||
                        (authorityGroup.hasWorkflow && !authority.archived)
                    "
                >
                    <div style="margin-top: 25px">
                        <div class="horizontal-container fill bottom-buffer">
                            <div class="fcol-5">
                                <label>Start</label>
                                <p>{{ authority.startDate | formatDate }}</p>
                            </div>
                            <div *ngIf="authority.expiryDate != null">
                                <label>Expiry</label>
                                <p>{{ authority.expiryDate | formatDate }}</p>
                            </div>
                        </div>
                        <div class="horizontal-container fill">
                            <div class="fcol-5">
                                <label>{{
                                    authorityGroup.identifierLabel
                                }}</label>
                            </div>
                            {{ authority.identifier }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="action-container">
            <button
                *ngIf="
                    linkedAuthorities != null &&
                    (availableLinkedAuthorities.length > 0 ||
                        addedLinkedAuthorities.length > 0)
                "
                type="button"
                class="btn btn-default btn-sm"
                (click)="
                    showDependentAuthorityOptions =
                        !showDependentAuthorityOptions
                "
            >
                {{ !showDependentAuthorityOptions ? 'Show' : 'Hide' }} Linked
                Authorities
                <span
                    class="fal"
                    [ngClass]="{
                        'fa-chevron-down': !showDependentAuthorityOptions,
                        'fa-chevron-up': showDependentAuthorityOptions
                    }"
                ></span>
            </button>
            <button
                *ngIf="!viewOnly && canLinkToPrimary"
                type="button"
                class="btn btn-sm btn-default"
                (click)="
                    onLinkAuthority.emit({
                        authorityType: authorityGroup,
                        authority: authorityGroup.authorities[0]
                    })
                "
            >
                Link Authority
            </button>
            <button
                *ngIf="
                    authorityGroup.authorities != null &&
                    authorityGroup.authorities.length > 0
                "
                type="button"
                class="btn btn-default-action btn-sm"
                (click)="
                    onShowAuthority.emit({
                        authorityType: authorityGroup,
                        authority: authorityGroup.authorities[0]
                    })
                "
            >
                View Authority
            </button>
            <button
                *ngIf="
                    !viewOnly &&
                    (authorityGroup.authorities == null ||
                        authorityGroup.authorities.length === 0)
                "
                type="button"
                class="btn btn-default-action btn-sm"
                [disabled]="
                    dependentOnAuthorityGroup != null &&
                    dependentOnAuthorityGroup.authorities?.length === 0
                "
                (click)="createAuthority()"
            >
                <span
                    [tooltip]="
                        dependentOnAuthorityGroup != null &&
                        dependentOnAuthorityGroup.authorities?.length === 0
                            ? 'Add a ' +
                              dependentOnAuthorityGroup.name +
                              ' authority first'
                            : ''
                    "
                    placement="top"
                >
                    <span class="fa fa-plus"></span> Add
                    {{ authorityGroup.abbreviation }}
                </span>
            </button>
        </div>
    </div>
    <div
        *ngIf="showDependentAuthorityOptions"
        class="linked-authority"
        [ngClass]="{
            active: groupStatus == 'ACTIVE',
            inactive: groupStatus == 'EMPTY',
            incomplete: groupStatus == 'INCOMPLETE',
            'action-required': groupStatus == 'ATTENTION'
        }"
        @expandOnEnter
        @collapseOnLeave
    >
        <div class="fill container-scroll modal-scrollbar relative top-buffer">
            <div *ngIf="!viewOnly" class="input-group sticky-heading">
                <ng-select
                    placeholder="Select Authority Type To Add"
                    [items]="availableLinkedAuthorities"
                    bindLabel="name"
                    [(ngModel)]="selectedLinkedAuthority"
                    appendTo="body"
                    class="wide-panel"
                >
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                    >
                        <div>{{ item.name }}</div>
                        <div *ngIf="item.description">
                            <p class="help-block">{{ item.description }}</p>
                        </div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                    </ng-template>
                </ng-select>
                <span class="input-group-btn">
                    <button
                        type="button"
                        class="btn btn-default-action"
                        (click)="
                            onCreateAuthority.emit({
                                authorityType: selectedLinkedAuthority,
                                authority: authorityGroup.authorities[0]
                            })
                        "
                        [disabled]="working || selectedLinkedAuthority == null"
                    >
                        <span class="fa fa-plus"></span> Add Authority
                    </button>
                </span>
            </div>
            <ng-container *ngFor="let authorityType of addedLinkedAuthorities">
                <div
                    *ngFor="let authority of authorityType.authorities"
                    class="horizontal-container fill top-buffer bottom-buffer contents data space-between vertical-align"
                >
                    <div
                        class="fill ellipsis"
                        [tooltip]="authorityType.name"
                        appendTo="body"
                    >
                        {{ authorityType.abbreviation || authorityType.name }}
                    </div>
                    <div
                        *ngIf="authority.identifier"
                        class="left-buffer ellipsis"
                        tooltip="{{ authorityType.identifierLabel }}
                    {{ authority.identifier }}"
                    >
                        {{ authority.identifier }}
                    </div>
                    <div class="left-buffer">
                        {{ authority.startDate | formatDate
                        }}{{ authority.expiryDate ? ' - ' : ''
                        }}{{ authority.expiryDate | formatDate }}
                    </div>
                    <button
                        type="button"
                        class="btn-tertiary"
                        (click)="viewLinkedAuthority(authorityType, authority)"
                        tooltip="View authority"
                        placement="left"
                    >
                        <span class="fa fa-ellipsis-vertical"></span>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</card>
