import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import {
    CurrentPersonDto,
    LoggedInUser,
    PageTitle,
    PersonsOrganisationDto,
    PreferencesService,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    ReplaySubject,
    Subject
} from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class RegisteredWidgets {
    constructor(public registeredWidgets: WidgetRegistration[]) {}
}

export interface WidgetStatus {
    [widgetIdentifier: string]: {
        available: boolean;
        selected: boolean;
    };
}

export type SelectedWidgets = { [widgetId: string]: boolean };

export interface LoadingOrganisations {
    type: 'loading';
}

export interface CurrentOrganisation {
    type: 'organisation_loaded';
    organisation: PersonsOrganisationDto;
}

export interface NoAccessToOrganisation {
    type: 'no_access';
}

export interface NoLicenceToOrganisation {
    type: 'no_licence';
    organisation: PersonsOrganisationDto;
}

export type SelectedOrganisation =
    | LoadingOrganisations
    | CurrentOrganisation
    | NoAccessToOrganisation
    | NoLicenceToOrganisation;

@Injectable({
    providedIn: 'root'
})
export class WorkspaceStateService implements OnDestroy {
    private availableWidgetsSubject = new ReplaySubject<WidgetStatus>();
    private scrollToListenerSubject = new Subject<string>();
    private currentOrganisationSubject =
        new BehaviorSubject<SelectedOrganisation>({ type: 'loading' });

    public availableWidgets$ = this.availableWidgetsSubject.asObservable();
    public scrollToListener$ = this.scrollToListenerSubject.asObservable();
    public currentOrganisation$ =
        this.currentOrganisationSubject.asObservable();
    public currentLoadedOrganisation$ = this.currentOrganisationSubject.pipe(
        filter<CurrentOrganisation>(o => o.type === 'organisation_loaded')
    );

    private widgetElements: { [widgetIdentifier: string]: ElementRef } = {};
    private selectedWidgets: SelectedWidgets = {};

    private organisation: PersonsOrganisationDto;
    private personalOrganisation: PersonsOrganisationDto;
    private organisationId = new BehaviorSubject<number | undefined>(undefined);

    private ngUnsubscribe$ = new Subject<void>();

    user$: Observable<CurrentPersonDto>;

    constructor(
        public registeredWidgets: RegisteredWidgets,
        private userService: UserService,
        private preferencesService: PreferencesService,
        private pageTitleService: PageTitle
    ) {
        this.user$ = userService.userChange$.pipe(
            filter(c => c.type === UserStatus.LOGGED_IN),
            // FIXME This casting isn't needed in VSCode, but the TS compiler is complaining
            map(c => (<LoggedInUser>c).currentUser),
            distinctUntilChanged(),
            takeUntil(this.ngUnsubscribe$)
        );

        this.user$.subscribe(() => {
            this.personalOrganisation = userService.getPersonalOrganisation();
        });

        this.currentOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(selectedOrganisation => {
                if (selectedOrganisation.type === 'organisation_loaded') {
                    this.pageTitleService.refreshOrganisation(
                        selectedOrganisation.organisation
                    );
                } else {
                    this.pageTitleService.refreshOrganisation(null);
                }
            });

        combineLatest([
            this.organisationId,
            userService.userChange$.pipe(
                filter(c => c.type === UserStatus.LOGGED_IN),
                distinctUntilChanged()
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([organisationId, user]) =>
                this.notifyOrganisationChanged(
                    organisationId,
                    (<LoggedInUser>user).currentUser,
                    (<LoggedInUser>user).currentUsersOrganisations
                )
            );
    }

    ngOnDestroy() {
        this.availableWidgetsSubject.complete();
        this.scrollToListenerSubject.complete();
        this.currentOrganisationSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    public registerWidget(widgetId: string, element: ElementRef) {
        this.widgetElements[widgetId] = element;
    }

    public unregisterWidget(widgetId: string) {
        this.widgetElements[widgetId] = null;
    }

    public scrollTo(widgetId: string) {
        if (this.widgetElements[widgetId] == null) {
            return;
        }
        this.widgetElements[widgetId].nativeElement.scrollIntoView({
            behavior: 'smooth'
        });
    }

    private refreshAvailableWidgets() {
        const updatedAvailableWidgets: WidgetStatus =
            this.registeredWidgets.registeredWidgets.reduce(
                (acc, reg) => ({
                    ...acc,
                    [reg.widgetIdentifier]: {
                        available:
                            reg.isAvailableForUser != null
                                ? reg.isAvailableForUser(
                                      this.personalOrganisation
                                  )
                                : false || reg.isAvailable(this.organisation),
                        selected:
                            this.selectedWidgets[reg.widgetIdentifier] !== false
                    }
                }),
                {} as WidgetStatus
            );

        this.availableWidgetsSubject.next(updatedAvailableWidgets);
    }

    public getCurrentOrganisation() {
        return this.currentOrganisationSubject.getValue();
    }

    public changeOrganisation(organisationId: number) {
        if (organisationId === this.organisationId.getValue()) {
            return;
        }
        this.organisationId.next(organisationId);

        this.userService.setAdditionalOrganisation(undefined);

        if (organisationId == null) {
            this.selectedWidgets = {};
            return;
        }
    }

    private notifyOrganisationChanged(
        organisationId: number | undefined,
        user: CurrentPersonDto | undefined,
        organisations: PersonsOrganisationDto[]
    ) {
        if (organisationId == null) {
            return;
        }

        const oldOrganisation = this.organisation;

        this.organisation = organisations?.find(o => o.id === organisationId);

        if (
            oldOrganisation === this.organisation &&
            this.organisation != null
        ) {
            return;
        }
        if (user == null) {
            return;
        }

        if (this.organisation == null) {
            if (user.type === 'SYSTEM_ADMIN') {
                this.userService
                    .fetchOrganisationForUser(organisationId)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe({
                        next: organisation => {
                            this.organisation = organisation;
                            this.userService.setAdditionalOrganisation(
                                organisation
                            );
                            this.sendNotification(this.organisation, user.type);
                        },
                        error: () => {
                            this.currentOrganisationSubject.next({
                                type: 'no_access'
                            });
                        }
                    });
            } else {
                this.currentOrganisationSubject.next({ type: 'no_access' });
            }
        } else {
            this.sendNotification(this.organisation, user.type);
        }
    }

    private sendNotification(
        organisation: PersonsOrganisationDto,
        userType: CurrentPersonDto.Type
    ) {
        if (organisation == null) {
            return;
        }
        const updateType =
            organisation.hasLicence ||
            organisation.owner ||
            userType === 'SYSTEM_ADMIN'
                ? 'organisation_loaded'
                : 'no_licence';

        this.currentOrganisationSubject.next({
            type: updateType,
            organisation
        });

        if (updateType === 'organisation_loaded') {
            this.loadPreferences(organisation.id);
        }

        // if (
        //     oldOrganisation == null ||
        //     oldOrganisation.id !== this.organisation.id
        // ) {
        //     this.changeOrganisation(this.organisation.id);
        // }
    }

    private loadPreferences(organisationId: number) {
        this.preferencesService
            .findPreferences('menu', organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (preferences: any) => {
                    this.selectedWidgets = preferences ?? {};
                    this.refreshAvailableWidgets();
                },
                error: () => {
                    this.refreshAvailableWidgets();
                }
            });
    }

    public changeSelectedWidgets(selectedWidgets: SelectedWidgets) {
        this.selectedWidgets = selectedWidgets;
        this.refreshAvailableWidgets();

        this.preferencesService
            .updatePreferences(
                'menu',
                this.organisationId.getValue(),
                selectedWidgets
            )
            .subscribe();
    }
}
