import { Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Angulartics2 } from 'angulartics2';

const SCREEN_IDENTIFIER = 'embedded-video-player';

@Component({
    selector: 'embedded-video-player',
    template: `
        <iframe
            *ngIf="!isMp4"
            [src]="safeUrl"
            frameborder="0"
            [style]="styles"
        ></iframe>
        <flyfreely-video
            *ngIf="isMp4"
            [videoUrl]="videoUrl"
            videoType="video/mp4"
            (play)="onPlayVideo()"
        ></flyfreely-video>
    `
})
export class EmbeddedVideoPlayer {
    @Input()
    videoUrl: string;

    @Input() analyticsLabel: string;

    @Input() displayWidth: number = 860;
    @Input() displayHeight: number = 480;

    styles: string;

    isMp4 = false;

    safeUrl: SafeResourceUrl;

    constructor(
        private sanitizer: DomSanitizer,
        private angulartics2: Angulartics2
    ) {
        this.styles = `width: ${this.displayWidth}px; height: ${this.displayHeight}px;`;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.styles = `width: ${this.displayWidth}px; height: ${this.displayHeight}px;`;
        if (changes.videoUrl) {
            this.isMp4 = this.videoUrl.endsWith('.mp4');
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.videoUrl
            );
        }
    }

    onPlayVideo() {
        this.angulartics2.eventTrack.next({
            action: 'play',
            properties: {
                category: SCREEN_IDENTIFIER,
                label: this.analyticsLabel
            }
        });
    }
}
