import { Injectable, NgZone } from '@angular/core';
import {
    UserService,
    UserStatus,
    LoginEvent,
    UserChanged
} from './user.service';
import { Angulartics2 } from 'angulartics2';
import { Subject, fromEvent, Observable } from 'rxjs';
import { takeUntil, share } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root'
})
export class UserAnalyticsService {
    private ngUnsubscribe$ = new Subject<void>();

    userChange$: Observable<UserChanged>;

    constructor(
        private userService: UserService,
        private angulartics2: Angulartics2,
        zone: NgZone
    ) {
        this.userChange$ = userService.userChange$.pipe(
            takeUntil(this.ngUnsubscribe$),
            share()
        );

        this.userChange$.subscribe(change =>
            this.angularticsUserChangeHandler(change)
        );

        this.userChange$.subscribe(change =>
            this.sentryUserChangeHandler(change)
        );

        userService.loginEvent$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(event => this.angularticsLoginEventHandler(event));

        // @ts-ignore - no type sensing available
        if (window.UserSnap != null) {
            // @ts-ignore - no type sensing available
            this.setupUserSnap(window.UserSnap);
        } else {
            // @ts-ignore - no type sensing available
            window.onUsersnapCXPostLoad = (UserSnap: any) =>
                zone.run(() => this.setupUserSnap(UserSnap));
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupUserSnap(UserSnap: any) {
        // @ts-ignore - no type sensing available
        fromEvent(UserSnap, 'open')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(event => {
                const currentUser = this.userService.getCurrentUser();
                if (currentUser != null) {
                    // @ts-ignore - no type sensing available
                    event.api.setValue('visitor', currentUser.email);
                }
            });
    }

    private angularticsUserChangeHandler(change: UserChanged) {
        switch (change.type) {
            case UserStatus.LOGGED_IN:
                this.angulartics2.setUserProperties.next({
                    email: change?.currentUser?.email,
                    firstname: change?.currentUser?.firstName,
                    lastname: change?.currentUser?.lastName,
                    dimension2: change?.currentUser?.type,
                    ...change?.currentUser?.userAttributes
                });
                if (change?.currentUser?.id != null) {
                    this.angulartics2.setUsername.next(
                        change?.currentUser?.id.toString()
                    );
                } else {
                    this.angulartics2.setUsername.next(null);
                }
                break;

            case UserStatus.LOGGED_OUT:
                this.angulartics2.setUsername.next(null);
                break;
        }
    }

    private angularticsLoginEventHandler(event: LoginEvent): void {
        switch (event) {
            case LoginEvent.LOGIN:
                this.angulartics2.eventTrack.next({
                    action: 'login',
                    properties: {
                        category: 'session'
                    }
                });
                return;
            case LoginEvent.LOGOUT:
                this.angulartics2.eventTrack.next({
                    action: 'logout',
                    properties: {
                        category: 'session'
                    }
                });
                return;
            case LoginEvent.LOGIN_FAILED:
                this.angulartics2.eventTrack.next({
                    action: 'login_failed',
                    properties: {
                        category: 'session'
                    }
                });
                return;
        }
    }

    private sentryUserChangeHandler(change: UserChanged) {
        switch (change.type) {
            case UserStatus.LOGGED_IN:
                if (change?.currentUser?.id != null) {
                    Sentry.setUser({
                        id: change?.currentUser?.id?.toString(),
                        email: change?.currentUser?.email,
                        ip_address: '{{auto}}'
                    });
                } else {
                    Sentry.setUser(null);
                }
                break;

            case UserStatus.LOGGED_OUT:
                Sentry.setUser(null);
                break;
        }
    }
}
