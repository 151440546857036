import { RpaTypeDto } from '@flyfreely-portal-ui/flyfreely';

/**
 * Sort by organisation, then make, then model.
 * @param a
 * @param b
 */
export function compareRpaType(a: RpaTypeDto, b: RpaTypeDto) {
    if (a.organisationId != null && b.organisationId == null) {
        return -1;
    }

    if (a.organisationId == null && b.organisationId != null) {
        return 1;
    }

    if (a.organisationId !== b.organisationId) {
        return a.organisationId - b.organisationId;
    }

    if (a.make === b.make) {
        if (a.model > b.model) {
            return 1;
        } else {
            return -1;
        }
    } else if (a.make > b.make) {
        return 1;
    } else {
        return -1;
    }
}
