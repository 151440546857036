import {
    BatteryDto,
    BatterySetDto,
    CraftDetailsDto,
    EquipmentDto
} from '@flyfreely-portal-ui/flyfreely';

/**
 * A reusable function to search for RPA. Mainly aimed at ng-select searches.
 * @param term The search term
 * @param item An RPA to match against the search term
 * @returns true if the search term is in the name or the serial number of the RPA
 */
export function rpaSearch(term: string, item: CraftDetailsDto) {
    term = term.toLocaleLowerCase();
    const rpaName = `${item.nickname} ${item.manufacturerSerialNumber} ${item.rpaType?.make} ${item.rpaType?.model}`.toLocaleLowerCase();
    const found = rpaName.indexOf(term) > -1;
    return found;
}

/**
 * A reusable function to search for batteries. Mainly aimed at ng-select searches.
 * @param term The search term
 * @param item A battery to match against the search term
 * @returns true if the search term is in the name, make, model or the serial number of the battery
 */
export function batterySearch(term: string, item: BatteryDto) {
    term = term.toLocaleLowerCase();
    const rpaName = `${item.name} ${item.manufacturerSerialNumber} ${item.batteryTypeMake} ${item.batteryTypeModel}`.toLocaleLowerCase();
    const found = rpaName.indexOf(term) > -1;
    return found;
}

/**
 * A reusable function to search for battery sets. Mainly aimed at ng-select searches.
 * This search matches against the set, but also all the batteries in the set.
 * @param term The search term
 * @param item A battery set to match against the search term
 * @returns true if the search term is in the name of the set, or in the the name, make, model or the serial number of the batteries in the set
 */
export function batterySetSearch(term: string, item: BatterySetDto) {
    term = term.toLocaleLowerCase();
    const rpaName = `${item.name} ${
        item.batteries != null ? item.batteries[0].batteryTypeMake : ''
    } ${
        item.batteries != null ? item.batteries[0].batteryTypeModel : ''
    }`.toLocaleLowerCase();
    const found = rpaName.indexOf(term) > -1;

    // check if the term matches any of the batteries in the set.
    const foundInBatteries =
        item.batteries != null
            ? item.batteries.filter(battery => batterySearch(term, battery))
                  .length > 0
            : false;

    return found || foundInBatteries;
}

/**
 * A reusable function to search for equipment. Mainly aimed at ng-select searches.
 * @param term The search term
 * @param item An equipment item to match against the search term
 * @returns true if the search term is in the name, category, make, model or the serial number of the equipment
 */
export function equipmentSearch(term: string, item: EquipmentDto) {
    term = term.toLocaleLowerCase();
    const rpaName = `${item.name} ${item.manufacturerSerialNumber} ${item.equipmentType?.equipmentCategory} ${item.equipmentType?.make} ${item.equipmentType?.model}`.toLocaleLowerCase();
    const found = rpaName.indexOf(term) > -1;
    return found;
}
