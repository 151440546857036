<div class="modal-header no-icon">
    <h3 class="modal-title">Workflow</h3>
    <button
        type="button"
        (click)="modal.hide()"
        aria-label="Close"
        class="close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form *ngIf="currentWorkflow != null" (ngSubmit)="save()" novalidate>
    <div class="modal-body">
        <div class="modal-body-content">
            <div class="row workflow">
                <div class="col-xs-12 form-group" *ngIf="hasName">
                    <inline-edits
                        (save)="renameForm($event)"
                        (change)="onChangeWorkflowName($event)"
                        [labelName]="'Name'"
                        [model]="currentWorkflow?.name"
                        [isNewForm]="isNewWorkflow"
                        [canEdit]="true"
                    >
                    </inline-edits>
                </div>

                <div class="col-xs-12 form-group">
                    <div class="input-group flex-version-migrate">
                        <ng-select
                            class="select-version"
                            name="workflowVersion"
                            placeholder="New Version"
                            [ngModel]="selectedVersionId"
                            (change)="changeSelectedVersion($event.id)"
                            bindValue="id"
                            [items]="versions"
                            [clearable]="false"
                            appendTo="body"
                        >
                            <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-index="index"
                            >
                                <span>
                                    Version {{ item.versionNumber }}
                                    {{
                                        activeVersionId == item.id
                                            ? '(Active)'
                                            : ''
                                    }}
                                </span>
                            </ng-template>
                            <ng-template
                                ng-label-tmp
                                let-item="item"
                                let-index="index"
                            >
                                <span>
                                    Version {{ item.versionNumber }}
                                    {{
                                        activeVersionId == item.id
                                            ? '(Active)'
                                            : ''
                                    }}
                                </span>
                            </ng-template>
                        </ng-select>

                        <div class="action-buttons">
                            <action-required
                                *ngIf="needMigration$ | async"
                                class="left-buffer"
                                [nonCritical]="true"
                                buttonText="Migrate Now"
                                (buttonClick)="migrateForms()"
                            >
                                This workflow uses global forms, which is no
                                longer supported. Migrate Now make a copy of
                                these forms, and update the workflow to use
                                them.
                            </action-required>

                            <span class="input-group-btn">
                                <button
                                    type="button"
                                    class="btn-tertiary btn-sm"
                                    (click)="cloneVersion()"
                                    [disabled]="
                                        !selectedVersionId ||
                                        !workflow.availableActions.canEdit
                                    "
                                    tooltip="Clone this version"
                                >
                                    <i class="fa-clone fal"></i>
                                </button>

                                <button
                                    type="button"
                                    class="btn-tertiary btn-sm"
                                    tooltip="Make this the active version"
                                    (click)="toggleActiveVersion()"
                                    [disabled]="
                                        !selectedVersionId ||
                                        !workflow.availableActions.canEdit
                                    "
                                >
                                    <span
                                        class="fal fa-star"
                                        *ngIf="
                                            selectedVersionId !==
                                            activeVersionId
                                        "
                                    >
                                    </span>
                                    <span
                                        class="fa fa-star"
                                        *ngIf="
                                            selectedVersionId ===
                                            activeVersionId
                                        "
                                    >
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="row"
                *ngIf="hasDelegatedAuthority && fullPrimaryAuthorities != null"
            >
                <div class="col-xs-12 form-group">
                    <label>Primary Authority</label>
                    <ng-select
                        name="primaryAuthority"
                        placeholder="Select Authority"
                        [readonly]="!currentVersion.availableActions.canEdit"
                        [ngModel]="currentVersion.delegatedAuthorityId"
                        (change)="authorityChanged($event)"
                        bindValue="id"
                        bindLabel="name"
                        [items]="primaryAuthorities"
                        appendTo="body"
                    >
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                        >
                            <span
                                [ngClass]="{
                                    error: !isValidAuthority(item.id)
                                }"
                            >
                                {{ item.name }}{{ authorityStatus(item.id) }}
                            </span>
                        </ng-template>
                        <ng-template
                            ng-label-tmp
                            let-item="item"
                            let-index="index"
                        >
                            <span
                                [ngClass]="{
                                    error: !isValidAuthority(item.id)
                                }"
                            >
                                {{ item.name }}{{ authorityStatus(item.id) }}
                            </span>
                        </ng-template>
                    </ng-select>
                    <p class="help-block">
                        This authority will be used by all missions using this
                        workflow. Any additional forms applied by this authority
                        are marked inline below, and marked with a *.
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="hasDelegatedAuthority">
                <div class="col-xs-12 form-group">
                    <label>Additional Authorities</label>
                    <ng-container *ngIf="availableAuthorityLookup != null">
                        <ng-container
                            *ngFor="
                                let additionalAuthorityId of currentVersion.additionalAuthorityIds
                            "
                        >
                            <div class="horizontal-container">
                                <div
                                    *ngIf="
                                        availableAuthorityLookup[
                                            additionalAuthorityId
                                        ] == null
                                    "
                                    class="fill"
                                >
                                    <span class="status-block expired"
                                        >Authority not available</span
                                    >
                                </div>
                                <div
                                    *ngIf="
                                        availableAuthorityLookup[
                                            additionalAuthorityId
                                        ] != null
                                    "
                                    class="fill"
                                >
                                    {{
                                        availableAuthorityLookup[
                                            additionalAuthorityId
                                        ].name
                                    }}
                                </div>
                                <button
                                    class="btn btn-tertiary btn-delete"
                                    type="button"
                                    (click)="
                                        removeAdditionalAuthority(
                                            additionalAuthorityId
                                        )
                                    "
                                    [disabled]="
                                        !currentVersion.availableActions.canEdit
                                    "
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div class="input-group">
                        <ng-select
                            [ngModelOptions]="{ standalone: true }"
                            [items]="availableAdditionalAuthorities"
                            bindLabel="name"
                            bindValue="id"
                            [(ngModel)]="newAdditionalAuthorityId"
                            placeholder="Select an additional authority"
                            [disabled]="
                                !currentVersion.availableActions.canEdit
                            "
                        >
                        </ng-select>
                        <span class="input-group-btn">
                            <button
                                class="btn btn-primary"
                                type="button"
                                [disabled]="
                                    newAdditionalAuthorityId == null ||
                                    !currentVersion.availableActions.canEdit
                                "
                                (click)="addAdditionalAuthority()"
                            >
                                Add
                            </button>
                        </span>
                    </div>
                    <p class="help-block">
                        This authority will be used by all missions using this
                        workflow. Any additional forms applied by this authority
                        are marked inline below, and marked with a *.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <h5>Required Documentation (Forms)</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <workflow-step-details
                        [disabled]="!currentVersion.availableActions.canEdit"
                        [steps]="filterSteps()"
                        [mappedForms]="currentVersion.mappedForms"
                        [mappedApproverChecks]="
                            currentVersion.mappedApproverChecks
                        "
                        [mappedAttachmentRequirements]="
                            currentVersion.mappedAttachmentRequirements
                        "
                        [forms]="availableForms$ | async"
                        [additionalDocumentation]="authorityDocumentation"
                        [organisationId]="organisationId"
                        [hasApproverChecks]="hasApproverChecks"
                    ></workflow-step-details>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <loading-indicator class="pull-left" [isLoading]="working">
        </loading-indicator>
        <button
            class="btn"
            type="submit"
            [disabled]="
                !currentVersion.availableActions.canEdit ||
                working ||
                isWorkflowNameRequiredAndEmpty
            "
        >
            <span>Save</span>
        </button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="saveAndClose()"
            [disabled]="
                !currentVersion.availableActions.canEdit ||
                working ||
                isWorkflowNameRequiredAndEmpty
            "
        >
            <span>Save and Close</span>
        </button>
    </div>
</form>
