/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.12-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckExpiryDto } from './checkExpiryDto';
import { CraftDto } from './craftDto';


/**
 * The register entries with expiry information
 */
export interface RpaRegisterEntryExpiryDto { 
    /**
     * The ID of this register entry
     */
    id: number;
    /**
     * The authority holding the register
     */
    authorityRegisterId: number;
    /**
     * The date that this person started being registered under this authority
     */
    startDate: string;
    /**
     * The date that this person ceased being on the register
     */
    endDate?: string;
    rpa: CraftDto;
    /**
     * Whether the entry has expired according to the expiry rules
     */
    expiryStatus?: RpaRegisterEntryExpiryDto.ExpiryStatus;
    /**
     * When the entry expires, based on the soonest of all expired elements
     */
    expiryDate?: string;
    /**
     * When the expiry check was last performed
     */
    lastExpiryUpdate?: string;
    checkExpiryList: Array<CheckExpiryDto>;
}
export namespace RpaRegisterEntryExpiryDto {
    export type ExpiryStatus = 'OK' | 'UPCOMING' | 'EXPIRED';
    export const ExpiryStatus = {
        OK: 'OK' as ExpiryStatus,
        UPCOMING: 'UPCOMING' as ExpiryStatus,
        EXPIRED: 'EXPIRED' as ExpiryStatus
    };
}



