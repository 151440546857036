import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { BatteryDto, BatterySetDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'battery-set-list-item',
    templateUrl: './battery-set-list-item.component.html',
    styleUrls: ['./battery-set-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatterySetListItemComponent {
    @Input() item: BatterySetDto;

    listedBatteries: string;

    ngOnInit() {
        const shortenedBatteries =
            this.item.batteries?.reduce(
                (acc: string[], battery: BatteryDto) =>
                    acc.length >= 5
                        ? acc
                        : acc.concat(
                              `${battery.name} - SN: ${
                                  battery.manufacturerSerialNumber ?? 'N/A'
                              }`
                          ),
                []
            ) ?? [];
        this.listedBatteries =
            shortenedBatteries.length == 0
                ? 'None'
                : shortenedBatteries.join('\n');
    }
}
