/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.33-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveGridCellDto } from './activeGridCellDto';
import { Geometry } from 'geojson';
import { AirspaceAuthorisationMessage } from './airspaceAuthorisationMessage';
import { SimpleAuthorityDto } from './simpleAuthorityDto';
import { SimplePersonDto } from './simplePersonDto';


/**
 * 
 */
export interface AirspaceAuthorisationDto { 
    id: string;
    controllingAuthority?: string;
    maximumHeight?: number;
    activeGridCellList: Array<ActiveGridCellDto>;
    operatingArea: Geometry;
    createTime: string;
    cancelTime?: string;
    updateTime?: string;
    status: AirspaceAuthorisationDto.Status;
    startTime: string;
    endTime: string;
    operatorsCertificateIdentifier?: string;
    remotePilotLicenceIdentifier?: string;
    authorisationType: AirspaceAuthorisationDto.AuthorisationType;
    conditionList: Array<string>;
    messageList: Array<AirspaceAuthorisationMessage>;
    /**
     * 
     */
    authorisationProviderResponse?: any;
    /**
     * 
     */
    additionalProperties?: any;
    rpic: SimplePersonDto;
    operatorsCertificate: SimpleAuthorityDto;
}
export namespace AirspaceAuthorisationDto {
    export type Status = 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED' | 'COMPLETED' | 'PROVISIONAL' | 'DECLARED';
    export const Status = {
        PENDING: 'PENDING' as Status,
        APPROVED: 'APPROVED' as Status,
        REJECTED: 'REJECTED' as Status,
        CANCELLED: 'CANCELLED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        PROVISIONAL: 'PROVISIONAL' as Status,
        DECLARED: 'DECLARED' as Status
    };
    export type AuthorisationType = 'AUS_CASA_AUTHORISATION' | 'AUS_ASA_FIMS' | 'NZL_AIRSHARE' | 'USA_LAANC';
    export const AuthorisationType = {
        AUS_CASA_AUTHORISATION: 'AUS_CASA_AUTHORISATION' as AuthorisationType,
        AUS_ASA_FIMS: 'AUS_ASA_FIMS' as AuthorisationType,
        NZL_AIRSHARE: 'NZL_AIRSHARE' as AuthorisationType,
        USA_LAANC: 'USA_LAANC' as AuthorisationType
    };
}



