<ng-container screenAnalytics="airspace-check-details">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Airspace Check Result</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body no-footer inner-container">
        <div class="modal-body-content container-with-navigation" tabindex="0">
            <div class="container-menu">
                <airspace-check-results
                    screenIdentifier="airspace-check-details"
                    (ruleResultClick)="scrollToRule($event.code)"
                ></airspace-check-results>
            </div>
            <div *ngIf="airspaceJurisdiction" class="container-content">
                <enhanced-help
                    componentName="overview"
                    [compact]="true"
                ></enhanced-help>
                <h4 class="bottom-buffer">
                    {{ rulesetAbbreviation }}
                </h4>
                <ng-container *ngFor="let outcome of outcomeList">
                    <h5 class="top-buffer" id="rule-{{ outcome.code }}">
                        <span
                            class="block"
                            [ngClass]="{
                                pass: outcome.outcome === 'PASS',
                                advise: outcome.outcome === 'ADVISE',
                                block: outcome.outcome === 'BLOCK'
                            }"
                        >
                            <span
                                class="icon fad"
                                [ngClass]="{
                                    'fa-check-circle':
                                        outcome.outcome === 'PASS',
                                    'fa-exclamation-circle':
                                        outcome.outcome === 'ADVISE',
                                    'fa-times-circle':
                                        outcome.outcome === 'BLOCK'
                                }"
                            ></span>
                            &nbsp;&nbsp;{{ outcome.name }}
                        </span>
                    </h5>
                    <p>{{ outcome.outcomeMessage }}</p>
                    <div
                        *ngIf="
                            outcome.featureList[0].existingFeatures.length > 0
                        "
                        class="horizontal-container top-buffer bottom-buffer"
                    >
                        <flyfreely-map
                            #map
                            class="fill"
                            style="height: 500px; max-width: 800px"
                            [organisationId]="organisationId"
                            [features]="outcome.featureList"
                            [startTime]="checkCommand?.startTime"
                            [endTime]="checkCommand?.endTime"
                            [ruleset]="checkCommand?.ruleset"
                            [showFeatureSelection]="false"
                            [requireCtrlToZoom]="true"
                            [mapSourceFilters]="mapSourceFilters"
                            [enabledLayerGroups]="enabledMapLayerGroups"
                            [simplifiedMap]="true"
                            (featuresLoaded)="map.zoomToAllFeatures(true)"
                        ></flyfreely-map>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
