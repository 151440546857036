<ng-container screenAnalytics="takeoff-landing-edit">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-locations.png" />
        </div>
        <h3 class="modal-title">Edit Takeoff & Landing Location</h3>
        <!-- <enhanced-help-switch></enhanced-help-switch> -->
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div
        class="modal-body horizontal-container max-height no-padding"
        #drawingArea
    >
        <div
            class="map-sidebar container-scroll feature-groups-container"
            [ngClass]="{ expanded: true }"
        >
            <ng-container *ngIf="map != null">
                <enhanced-help componentName="feature-add" [compact]="true">
                </enhanced-help>
                <div
                    class="card"
                    *ngFor="let featureGroup of takeOffLandingGroups"
                >
                    <div class="header horizontal-container">
                        <h4 class="fill">{{ featureGroup.name }}</h4>
                        <!-- There should only be one takeoff and one landing point per flight, so disable the button once there is one -->
                        <!-- We also only want the points to be editable if the source is not a flight log -->
                        <button
                            type="button"
                            [disabled]="
                                isEditing ||
                                isAddingFeature ||
                                featureGroup.existingFeatures.length > 0 ||
                                flight.durationSource === 'LOGS'
                            "
                            class="btn btn-circle"
                            (click)="addNewFeature(featureGroup)"
                        >
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>
                    <div
                        *ngFor="let feature of featureGroup.existingFeatures"
                        class="horizontal-container"
                        [ngClass]="{
                            hover: feature.id === hoverFeatureId,
                            selected: feature.id === editFeatureId
                        }"
                        (mouseenter)="
                            highlightFeature(featureGroup, feature.id)
                        "
                        (mouseleave)="
                            unhighlightFeature(featureGroup, feature.id)
                        "
                    >
                        <span class="feature-name"
                            ><a (click)="onFeatureNameSelected(feature)">{{
                                feature.name
                            }}</a></span
                        >
                    </div>
                </div>
            </ng-container>
        </div>
        <flyfreely-map
            #map
            class="fill"
            [organisationId]="organisationId"
            [features]="locationFeatures"
            (featuresUpdated)="onFeaturesUpdated($event)"
            (featureSelected)="onFeatureSelected($event)"
            (featureUnselected)="onFeatureUnselected()"
            [showEditCompletion]="false"
            [showFeatureSelection]="false"
            (fullscreenRequested)="onFullscreenRequested()"
            (editModeChanged)="onEditModeChanged($event)"
            (featuresLoaded)="onFeaturesLoaded()"
            (featureHover)="onFeatureHover($event)"
            [featurePopupTemplate]="featurePopupTemplate"
            (mapReady)="onMapReady()"
        >
        </flyfreely-map>
        <ngx-loading [show]="working"></ngx-loading>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-default"
            type="button"
            (click)="close()"
            [disabled]="working || isAddingFeature"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary"
            type="button"
            (click)="save()"
            [disabled]="working || isAddingFeature"
        >
            Save
        </button>
    </div>
</ng-container>

<ng-template #featurePopupTemplate>
    <map-marker-toolbar
        *ngIf="selectedFeature"
        [feature]="selectedFeature.feature"
        [featureGroup]="selectedFeatureGroup"
        [allowEditCoordinates]="true"
        (onFeatureChange)="onSelectedFeatureUpdated($event)"
        (onFeatureEdit)="onFeatureEdit($event)"
        (onFeatureDelete)="onFeatureDelete($event)"
    ></map-marker-toolbar>
    <!-- If a mission feature that isn't a takeoff or landing point is selected (eg. a mission flight area) then just show the name in the popup -->
    <div *ngIf="selectedNonEditableFeature && !selectedFeature">
        <label> {{ selectedNonEditableFeature.name }} </label>
        <div *ngIf="selectedTakeoffLanding">
            <label>Coordinates</label>
            <p>Lng: {{ selectedNonEditableFeature.geom.coordinates[0] }}</p>
            <p>Lat: {{ selectedNonEditableFeature.geom.coordinates[1] }}</p>
        </div>
    </div>
</ng-template>
