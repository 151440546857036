import { Injectable } from '@angular/core';
import {
    BatteryDto,
    BatteryService,
    BatterySetDto,
    BatterySetService,
    BatteryTypeDto,
    BatteryTypeService,
    BulkEditOptions,
    BulkImportErrorText,
    ColumnOptionTypes,
    ColumnOptions,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameValue,
    WorkTracker,
    checkRequiredValidity,
    downloadCsv,
    parseCsvByHeadings,
    removeEmptyRows,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';

import {
    CHECK_INTEGER,
    CHECK_IS_TIME_WITH_COLONS
} from 'libs/bulk-uploads/src/lib/checks';
import {
    convertDurationToTimestamp,
    convertTimestampToSeconds,
    dateFormats,
    invalidFieldText,
    parseBulkUploadDate,
    validateBulkUploadDate
} from 'libs/bulk-uploads/src/lib/helpers';
import { BulkImportDialogue } from 'libs/bulk-uploads/src/lib/import-dialogue/import-dialogue.component';
import { BulkUploadDialogue } from 'libs/bulk-uploads/src/lib/upload-dialogue/upload-dialogue.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Columns } from 'ngx-easy-table';
import { ReplaySubject, Subject, combineLatest, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ImportBattery {
    name: string;
    make: string;
    model: string;
    makeModel: string;
    purchaseDate: string;
    serialNumber: string;
    batterySet: string;
    flightTime: string;
    initialCycles: string;
    nfcUid: string;
}

export const uploadBatteryTemplate: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            { key: 'name', props: { label: 'Name' } },
            { key: 'make', props: { label: 'Make' } },
            { key: 'model', props: { label: 'Model' } },
            {
                key: 'purchaseDate',
                props: { label: 'Purchase Date' }
            },
            {
                key: 'serialNumber',
                props: { label: 'Serial Number' }
            },
            { key: 'flightTime', props: { label: 'Flight Time' } },
            {
                key: 'initialCycles',
                props: { label: 'Initial Cycles' }
            },
            { key: 'batterySet', props: { label: 'Battery Set' } },
            { key: 'nfcUid', props: { label: 'NFC ID' } }
        ]
    },
    props: {
        label: 'Bulk_Battery_Import'
    }
};

const templateData = [
    {
        name: 'Battery 1',
        make: 'DJI',
        model: 'Phantom 3 Battery',
        purchaseDate: '31-01-2020',
        serialNumber: 'E52-434543A',
        batterySet: 'Set number 3',
        flightTime: '5:24:20',
        initialCycles: '10',
        nfcUid: '1234869'
    }
];

const columns: Columns[] = [
    { key: 'name', title: 'Name' },
    { key: 'makeModel', title: 'Make & Model' },
    { key: 'purchaseDate', title: 'Purchase Date' },
    { key: 'serialNumber', title: 'Serial Number' },
    { key: 'flightTime', title: 'Flight Time (HH:MM:SS)' },
    { key: 'initialCycles', title: 'Initial Cycles' },
    { key: 'batterySet', title: 'Battery Set' },
    { key: 'nfcUid', title: 'NFC ID' }
];

const bulkEditOptions: BulkEditOptions = {
    cannotBulkEdit: ['serialNumber', 'nfcUid'],
    uniqueInSet: ['name']
};

@Injectable()
export class BatteryUploadService {
    organisationId: number;

    uploadDialogue: BsModalRef<BulkUploadDialogue>;
    importDialogue: BsModalRef<BulkImportDialogue>;

    importedFile: ImportBattery[];

    columnOptions: ColumnOptions[];
    columnOptionsSubject = new ReplaySubject<ColumnOptions[]>();
    failedImportTextSubject = new ReplaySubject<BulkImportErrorText[]>();
    bulkEditOptionsSubject = new ReplaySubject<BulkEditOptions>();
    bulkEditOptions$ = this.bulkEditOptionsSubject.asObservable();
    columnOptions$ = this.columnOptionsSubject.asObservable();
    failedImportText$ = this.failedImportTextSubject.asObservable();
    failedImportText: BulkImportErrorText[] = [];

    allBatteryTypes: BatteryTypeDto[];
    existingSerialNumbers: string[];
    dateFormat: string;
    allBatterySets: BatterySetDto[];
    importedSets: NameValue[];

    workingSubject = new ReplaySubject<boolean>();
    doneImportingSubject = new Subject<void>();
    doneImporting$ = this.doneImportingSubject.asObservable();
    working$ = this.workingSubject.asObservable();
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private batteryTypeService: BatteryTypeService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private modalService: BsModalService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSubject.next(working));

        this.bulkEditOptionsSubject.next(bulkEditOptions);
    }

    ngOnDestroy() {
        this.doneImportingSubject.complete();
        this.workingSubject.complete();
        this.columnOptionsSubject.complete();
        this.failedImportTextSubject.complete();
        this.bulkEditOptionsSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    showBulkUpload(organisationId: number) {
        this.organisationId = organisationId;
        this.uploadDialogue = this.modalService.show(BulkUploadDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                uploadService: this,
                hasTemplate: true,
                entity: 'Battery'
            }
        });
        this.refreshOrginisationExistingBatteries();
        this.refreshOrganisationExistingBatterySets();
    }

    importFile(file: File) {
        this.importedSets = [];
        parseCsvByHeadings<ImportBattery>(file, {
            Name: 'name',
            Make: 'make',
            Model: 'model',
            'Serial Number': 'serialNumber',
            'Purchase Date': 'purchaseDate',
            'Initial Cycles': 'initialCycles',
            'Flight Time': 'flightTime',
            'Battery Set': 'batterySet',
            'NFC ID': 'nfcUid'
        }).then(result => {
            // Sometimes the csv parser doesn't filter out empty rows.
            // The filtered variable is a redundancy check to ensure empty rows are ignored.
            const filtered = removeEmptyRows<ImportBattery[]>(result);
            this.importedFile = filtered.map(r => {
                if (r.batterySet != null && r.batterySet !== '') {
                    this.importedSets.push({
                        name: r.batterySet,
                        value: r.batterySet
                    });
                }
                return {
                    ...r,
                    name: checkRequiredValidity(r.name),
                    makeModel:
                        checkRequiredValidity(r.make) === 'INVALID' ||
                        checkRequiredValidity(r.model) === 'INVALID'
                            ? 'INVALID'
                            : `${r.make} ${r.model}`,
                    initialCycles:
                        checkRequiredValidity(r.initialCycles) === 'INVALID'
                            ? '0'
                            : r.initialCycles,
                    flightTime:
                        checkRequiredValidity(r.flightTime) === 'INVALID'
                            ? '0'
                            : r.flightTime,
                    batterySet: r.batterySet === '' ? 'New Set' : r.batterySet
                };
            });
            this.setupColumnOptions();
            this.importedFile.forEach((row, i) => {
                row.flightTime = convertDurationToTimestamp(row.flightTime);
                this.validateRow(row, i, this.columnOptions);
            });
            this.showImportDialogue();
            this.uploadDialogue.hide();
        });
    }

    setupColumnOptions() {
        const existingSets = this.allBatterySets
            .map(s => ({ name: `${s.name}`, value: `${s.name}` }))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        const listedSets = this.importedSets.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );
        const sets = [
            {
                name: 'No Set',
                value: 'No Set'
            },
            {
                name: 'New Set',
                value: 'New Set'
            }
        ]
            .concat(listedSets)
            .concat(existingSets);
        this.columnOptions = [
            {
                columnName: 'purchaseDate',
                type: ColumnOptionTypes.SELECT,
                placeholder: 'Select the date format used',
                values: dateFormats
            },
            {
                columnName: 'makeModel',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: this.allBatteryTypes
                    .map(t => ({
                        name: `${t.make} ${t.model}`,
                        value: `${t.make} ${t.model}`
                    }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
            },
            {
                columnName: 'batterySet',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: sets
            }
        ];
        this.columnOptionsSubject.next(this.columnOptions);
    }

    validateRow(
        row: ImportBattery,
        rowIndex: number,
        options: ColumnOptions[]
    ) {
        this.dateFormat = options.find(
            option => option != null && option.columnName === 'purchaseDate'
        ).result;
        // Validate each row and add ' *INVALID*' markers to any invalid
        if (
            row.serialNumber != null &&
            this.existingSerialNumbers.findIndex(
                p => row.serialNumber === p
            ) !== -1
        ) {
            this.updateErrorText(
                rowIndex,
                'serialNumber',
                `${row.name} already exists in this organisation.`
            );
            row.serialNumber = `${row.serialNumber} ${invalidFieldText}`;
        }
        if (
            row.purchaseDate != null &&
            validateBulkUploadDate(row.purchaseDate, this.dateFormat) === false
        ) {
            this.updateErrorText(
                rowIndex,
                'purchaseDate',
                `The date: ${row.purchaseDate} is invalid. Please ensure you have selected the correct format`
            );
            row.purchaseDate = `${row.purchaseDate} ${invalidFieldText}`;
        }
        if (
            row.makeModel !== 'INVALID' &&
            this.allBatteryTypes.findIndex(
                m =>
                    `${m.make.toLowerCase()} ${m.model.toLowerCase()}` ===
                    row.makeModel.trim().toLowerCase()
            ) === -1
        ) {
            this.updateErrorText(
                rowIndex,
                'makeModel',
                `${row.makeModel} is not a known battery type.Please ensure this type is correct and has been set up for your organisation`
            );
            row.makeModel = `${row.makeModel} ${invalidFieldText}`;
        }
        if (
            row.flightTime !== 'INVALID' &&
            row.flightTime.match(CHECK_IS_TIME_WITH_COLONS) == null
        ) {
            this.updateErrorText(
                rowIndex,
                'flightTime',
                `Please ensure flight time is in HH:MM:SS format and indicates the amount of hours the battery has flown.`
            );
            row.flightTime = `${row.flightTime} ${invalidFieldText}`;
        }
        if (
            row.initialCycles !== 'INVALID' &&
            row.initialCycles.match(CHECK_INTEGER) == null
        ) {
            this.updateErrorText(
                rowIndex,
                'initialCycles',
                `Please ensure initital cycles is only numbers and indicates the amount of discharge cycles the battery has had.`
            );
            row.initialCycles = `${row.initialCycles} ${invalidFieldText}`;
        }
        if (
            row.batterySet != null &&
            row.batterySet !== 'New Set' &&
            row.batterySet !== 'No Set' &&
            !this.checkSetValidity(row)
        ) {
            this.updateErrorText(
                rowIndex,
                'batterySet',
                'This battery set contains batteries of multiple types. Ideally a battery set should only consist of batteries of one battery type.'
            );
            row.batterySet = `${row.batterySet} ${invalidFieldText}`;
        }
        this.failedImportTextSubject.next(this.failedImportText);

        return row;
    }

    showImportDialogue() {
        this.importDialogue = this.modalService.show(BulkImportDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                uploadService: this,
                data: this.importedFile,
                columns: columns,
                entity: 'Battery'
            }
        });
    }

    private refreshOrginisationExistingBatteries() {
        if (isNaN(this.organisationId)) {
            return;
        }
        this.batteryService
            .findBatteries(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => {
                this.existingSerialNumbers = results.map(
                    e => e.manufacturerSerialNumber
                );
                this.refreshBatteryTypes();
            })
            .add(this.workTracker.createTracker());
    }

    private refreshOrganisationExistingBatterySets() {
        this.batterySetService
            .findBatterySets(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sets => (this.allBatterySets = sets))
            .add(this.workTracker.createTracker());
    }

    private refreshBatteryTypes() {
        this.batteryTypeService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(batteryTypes => {
                this.allBatteryTypes = batteryTypes;
            })
            .add(this.workTracker.createTracker());
    }

    import(selected: number[], options: ColumnOptions[]) {
        const data: ImportBattery[] = selected.map(n => this.importedFile[n]);
        this.dateFormat = options.find(
            option => option != null && option.columnName === 'purchaseDate'
        ).result;
        /**
         * Validity checks with individual feedback for clarity.
         */
        const failed: number[] = [];
        data.map((e, i) => {
            if (Object.keys(e).filter(key => e[key] === 'INVALID').length > 0) {
                failed.push(i);
            }
        });

        data.forEach((entry, i) => {
            const rowIndex = this.importedFile.findIndex(e => e === entry);
            if (
                Object.keys(entry).filter(key => entry[key] === 'INVALID')
                    .length > 0
            ) {
                return;
            }
            if (
                entry.serialNumber != null &&
                this.existingSerialNumbers.findIndex(
                    p => entry.serialNumber === p
                ) !== -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'serialNumber',
                    `${entry.name} already exists in this organisation.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.purchaseDate != null &&
                entry.purchaseDate.length > 0 &&
                validateBulkUploadDate(entry.purchaseDate, this.dateFormat) ===
                    false
            ) {
                this.updateErrorText(
                    rowIndex,
                    'purchaseDate',
                    `The date: ${entry.purchaseDate} is invalid. Please ensure you have selected the correct format`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                this.allBatteryTypes.findIndex(
                    m =>
                        `${m.make.toLowerCase()} ${m.model.toLowerCase()}` ===
                        entry.makeModel.trim().toLowerCase()
                ) === -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'makeModel',
                    `${entry.makeModel} is not a known battery type.Please ensure this type is correct and has been set up for your organisation`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (entry.flightTime.match(CHECK_IS_TIME_WITH_COLONS) == null) {
                this.updateErrorText(
                    rowIndex,
                    'flightTime',
                    `Please ensure flight time is in HH:MM:SS format and indicates the amount of hours the battery has flown.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (entry.initialCycles.match(CHECK_INTEGER) == null) {
                this.updateErrorText(
                    rowIndex,
                    'initialCycles',
                    `Please ensure initital cycles is only numbers and indicates the amount of discharge cycles the battery has had.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.batterySet !== 'New Set' &&
                entry.batterySet !== 'No Set' &&
                !this.checkSetValidity(entry)
            ) {
                this.updateErrorText(
                    rowIndex,
                    'batterySet',
                    'This battery set contains batteries of multiple types. Ideally a battery set should only consist of batteries of one battery type.'
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
        });

        const validEntries = data.filter(
            (e, i) => failed.includes(i) === false
        );

        if (validEntries.length > 0) {
            const groups = validEntries.reduce(
                (acc, b) => ({
                    ...acc,
                    [b.batterySet]:
                        b.batterySet in acc ? acc[b.batterySet].concat(b) : [b]
                }),
                {} as { [batterySet: string]: ImportBattery[] }
            );

            const doneWorking = this.workTracker.createTracker();
            this.createBatteries(groups)
                .then(() => {
                    this.logging.success(
                        `Successfully imported ${validEntries.length} ${
                            validEntries.length === 1 ? 'battery' : 'batteries'
                        }.`
                    );
                    if (failed.length > 0) {
                        this.importDialogue.content.importedRowIndices =
                            this.importedFile.map((row, i) => {
                                if (validEntries.includes(row)) {
                                    return i;
                                }
                            });
                        this.importedFile = this.importedFile.filter(
                            e => validEntries.includes(e) === false
                        );
                        this.importDialogue.content.onFailedRows();
                        this.logging.warn(
                            'Some imported rows have failed, please fix all errors and try again'
                        );
                        this.failedImportTextSubject.next(
                            this.failedImportText
                        );
                        this.failedImportText = [];
                        doneWorking();
                    } else {
                        doneWorking();
                        this.importDialogue.hide();
                        this.doneImportingSubject.next();
                    }
                })
                .catch(() => {
                    this.importDialogue.content.onFailedRows();
                    this.logging.warn(
                        'Some imported rows have failed, please fix all errors and try again'
                    );
                    this.failedImportTextSubject.next(this.failedImportText);
                    this.failedImportText = [];
                    return;
                });
        } else {
            if (failed.length > 0) {
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
            }
        }
    }

    createBatteries(groups: { [batterySet: string]: ImportBattery[] }) {
        return Promise.all(
            Object.keys(groups).map(bs =>
                Promise.all(
                    groups[bs].map(b =>
                        this.batteryService
                            .createBattery({
                                organisationId: this.organisationId,
                                name: b.name,
                                purchaseDate:
                                    parseBulkUploadDate(
                                        b.purchaseDate,
                                        this.dateFormat
                                    ) ?? null,
                                batteryTypeId: this.allBatteryTypes.find(
                                    t =>
                                        `${t.make.toLowerCase()} ${t.model.toLowerCase()}` ===
                                        b.makeModel.trim().toLowerCase()
                                ).id,
                                isDummy: false,
                                manufacturerSerialNumber: b.serialNumber,
                                initialCycles: parseInt(
                                    b.initialCycles.trim(),
                                    10
                                ),
                                initialFlightTime: convertTimestampToSeconds(
                                    b.flightTime
                                ),
                                nfcUid: b.nfcUid,
                                initialComponentList: []
                            })
                            .toPromise()
                    )
                ).then(batteries => {
                    if (bs == null || bs === 'No Set') {
                        return;
                    }
                    if (bs === 'New Set' || bs === '') {
                        const commands = batteries.map(b =>
                            this.batterySetService.createBatterySet({
                                organisationId: this.organisationId,
                                name: b.name,
                                batteryIds: [b.id],
                                assemblyDate:
                                    b.purchaseDate ?? toLocalDate(new Date())
                            })
                        );
                        combineLatest(commands)
                            .pipe(takeUntil(this.ngUnsubscribe$))
                            .subscribe(DO_NOTHING, (error: FlyFreelyError) =>
                                throwError(error)
                            )
                            .add(this.workTracker.createTracker());
                    } else {
                        const doneWorking = this.workTracker.createTracker();
                        this.batterySetService
                            .createBatterySet({
                                organisationId: this.organisationId,
                                name: bs,
                                batteryIds: batteries.map(b => b.id),
                                assemblyDate:
                                    this.findLatestDate(batteries) ??
                                    toLocalDate(new Date())
                            })
                            .then(
                                () => {
                                    doneWorking();
                                },
                                (error: FlyFreelyError) => {
                                    throwError(error);
                                    doneWorking();
                                }
                            );
                    }
                })
            )
        );
    }

    private findLatestDate(batteries: BatteryDto[]) {
        if (batteries.length) {
            return (
                batteries.reduce((acc, e, i) =>
                    (e.purchaseDate == null ||
                        acc.purchaseDate > e.purchaseDate) &&
                    i
                        ? acc
                        : e
                )?.purchaseDate ?? null
            );
        }
    }

    downloadTemplate() {
        downloadCsv(uploadBatteryTemplate, templateData);
    }

    updateErrorText(rowIndex: number, key: string, text: string) {
        if (
            this.failedImportText.findIndex(
                t => t.key === key && t.rowIndex === rowIndex
            ) !== -1
        ) {
            return;
        } else {
            this.failedImportText.push({
                rowIndex: rowIndex,
                key: key,
                text: text
            });
        }
    }

    checkSetValidity(entry: ImportBattery) {
        const allInSet = this.importedFile.filter(
            b => b.batterySet === entry.batterySet
        );
        const onlyThisType = allInSet.filter(
            s => s.makeModel === entry.makeModel
        );
        return onlyThisType.length === allInSet.length;
    }
}
