import {
    PersonnelRegisterEntryDto,
    AuthorityTypeDto,
    AuthorityRegisterConditionsDto,
    AuthorityConditionDto,
    RegisterEntryVerificationActivityDto,
    PersonnelRegisterEntryExpiryDto,
    OrganisationAuthorityGroup,
    PersonsOrganisationDto,
    SharedAuthorityGroup,
    PersonnelRegisterExpirySummary,
    RpaRegisterExpirySummary,
    UpdateRegisterSettingsCommand
} from '@flyfreely-portal-ui/flyfreely';
import { Observable } from 'rxjs';

/**
 * A displayable check result. This is a denormalised version of the verification activity.
 */
export interface CheckResult {
    /**
     * The ID of the check this relates to
     */
    checkId: number;
    /**
     * The HTML content to display in the check space
     */
    content: string;
    /**
     * The ID of the verified entity.
     */
    verificationEntityId: number;
    /**
     * The template name for use in the popover. Null for no popover.
     */
    popover: string;

    details: { [extraProp: string]: any };

    checkConditionType: RegisterEntryVerificationActivityDto.ConditionType;

    activity: RegisterEntryVerificationActivityDto;
}

export interface CheckResults {
    [entityId: number]: {
        status: PersonnelRegisterEntryDto.StatusSource;
        [checkId: number]: CheckResult;
    };
}

export interface RegisterCheck {
    id: number;
    entity?: string;
    entityId?: number;
    name: string;
    conditionType?: AuthorityConditionDto.ConditionType;
}

/**
 * Update the CheckResults with the new checkValue.
 *
 * @param acc the current index
 * @param acknowledgement the new acknowledgement
 */
export function mergeCheckResults(
    acc: CheckResults,
    result: { entryId: number; checks: CheckResult[]; status: any }
): CheckResults {
    return {
        ...acc,
        [result.entryId]: {
            ...result.checks.reduce(
                (checks, check) => ({
                    ...checks,
                    [check.checkId]: check
                }),
                {}
            ),
            status: result.status
        }
    };
}

export interface RegisterEntry {
    /**
     * The ID of this entry
     */
    id?: number;
    startDate?: Date;
    endDate?: Date;
    /**
     * The ID of the entity being added to the registry
     */
    subjectId?: number;
    subjectName?: string;
    // status?: AircraftRegisterEntryDto.Status | PersonnelRegisterEntryDto.Status;
    statusSource?: PersonnelRegisterEntryDto.StatusSource; //  AircraftRegisterEntryDto.StatusSource |
    editable?: boolean;
}

export interface UiRegisterConditionsHandler {
    findCandidateAuthorityTypes(): Promise<AuthorityTypeDto[]>;
    findRegisterConditions(): Promise<AuthorityRegisterConditionsDto>;
    updateRegisterConditions(
        checks: AuthorityConditionDto[]
    ): Promise<AuthorityRegisterConditionsDto>;
    updateRegisterSettings(
        registerMode: UpdateRegisterSettingsCommand.RegisterMode
    ): Promise<AuthorityRegisterConditionsDto>;
}

/**
 * This defines the interface that is used to provide central functions to authorities and authority groups
 */
export abstract class AuthorityGroupEditService {
    abstract registerExpiryLookups$: Observable<{
        [registerId: number]: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
    }>;
    abstract working$: Observable<boolean>;
    abstract authorities$: Observable<OrganisationAuthorityGroup[]>;
    abstract organisation$: Observable<PersonsOrganisationDto>;
    abstract sharedAuthorities$: Observable<SharedAuthorityGroup[]>;
    abstract registerExpiries$: Observable<
        (PersonnelRegisterExpirySummary | RpaRegisterExpirySummary)[]
    >;
    abstract setup(entityId: number, managingOrganisationId: number): void;
    abstract refreshAllAuthorities(): void;
}
