import { Component, EventEmitter, Output } from '@angular/core';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import {
    OrganisationSubscriptionState,
    SubscriptionAndDetails
} from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlanSelectionService } from '../plan-selection/plan-selection.service';
import { UpdateSubscriptionService } from './update-subscription.service';

@Component({
    selector: 'update-subscription',
    templateUrl: './update-subscription.component.html',
    providers: [
        {
            provide: PlanSelectionService,
            useClass: UpdateSubscriptionService
        }
    ]
})
export class UpdateSubscription {
    @Output()
    cancel = new EventEmitter<void>();

    private ngUnsubscribe$ = new Subject<void>();

    subscription: SubscriptionAndDetails;

    constructor(
        private modal: BsModalRef,
        organisationSubscriptionState: OrganisationSubscriptionState,
        planSelectionService: PlanSelectionService,
        logging: FlyFreelyLoggingService
    ) {
        organisationSubscriptionState.subscriptionDetails$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(subscription => {
                this.subscription = subscription;
            });

        planSelectionService.purchaseSuccess$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                logging.success(
                    'Upgrade successful, it may take a few minutes to appear'
                );
                modal.hide();
            });
    }

    cancelUpdate() {
        this.modal.hide();
    }

    hide() {
        this.modal.hide();
    }
}
