<div class="button-groups">
    <div class="btn-group">
        <map-toolbar-measurement-button
            [enabled]="buttonsEnabled"
            [buttonType]="ToolbarButton.MeasurementLine"
        ></map-toolbar-measurement-button>
        <map-toolbar-measurement-button
            [enabled]="buttonsEnabled"
            [buttonType]="ToolbarButton.MeasurementRadius"
        ></map-toolbar-measurement-button>
        <map-toolbar-delete-button></map-toolbar-delete-button>
    </div>
</div>
