import { Pipe, PipeTransform } from '@angular/core';
import { PersonnelRegisterEntryDetailsDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'registerStatusClass', pure: true })
export class RegisterStatusClassPipe implements PipeTransform {
    transform(status: PersonnelRegisterEntryDetailsDto.Status) {
        const s = PersonnelRegisterEntryDetailsDto.Status;
        switch (status) {
            case s.ACTIVE:
                return 'active';

            case s.NOT_ON_REGISTER:
                return 'inactive';

            default:
                return 'pending';
        }
        /* if (status == null) {
            return 'disabled';
        }
        if (status.filter(s => s === RegisterEntrySummary.RegisterStatus.ACTIVE).length === status.length) {
            return 'active';
        } else {
            if (status.filter(s => s === RegisterEntrySummary.RegisterStatus.NOT_ON_REGISTER).length !== 0) {
                return 'inactive';
            } else {
                return 'pending';
            }
        } */
    }
}
