import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup
} from '@angular/forms';
import { ITreeOptions } from '@circlon/angular-tree-component/lib/defs/api';
import {
    EnhancedHelpDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    ScreenHelpDto,
    UpdateEnhancedHelpCommand,
    UpdateScreenHelpCommand,
    WorkTracker,
    downloadCsv,
    parseCsvByHeadings
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import moment from 'moment';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnhancedHelpAdminService } from '../enhanced-help-admin.service';
import {
    EnhancedHelpTreeService,
    ScreenTree
} from '../enhanced-help-tree.service';
import {
    EnhancedHelpService,
    HelpEntry,
    HelpGroup
} from '../enhanced-help.service';

export const enhancedHelpTreeOptions: ITreeOptions = {
    allowDrag: false,
    allowDrop: false,
    displayField: 'name',
    idField: 'identifier'
};

interface HelpItems {
    id: string;
    name: string;
    forms: HelpEntry[];
}

interface ImportFileFormat {
    screenName: string;
    screenIdentifier: string;
    name: string;
    identifier: string;
    value: string;
}

interface ScreenHelpLookup {
    [screen: string]: {
        knowledgeBaseUrl?: string;
        supportVideoUrl?: string;
    };
}

const exportTemplate: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            {
                key: 'screenName',
                props: {
                    label: 'Screen Name'
                }
            },
            {
                key: 'screenIdentifier',
                props: {
                    label: 'Screen Identifier'
                }
            },
            {
                key: 'name',
                props: {
                    label: 'Component Name'
                }
            },
            {
                key: 'identifier',
                props: {
                    label: 'Component Identifier'
                }
            },
            {
                key: 'value',
                props: {
                    label: 'Help Text'
                }
            }
        ]
    },
    props: {
        label: 'Enhanced_Help_Export'
    }
};

@Component({
    selector: 'enhanced-help-editor-list',
    templateUrl: './enhanced-help-editor-list.component.html',
    providers: [ScreenAnalyticsDirective]
})
export class EnhancedHelpEditorList implements OnInit, OnDestroy {
    private ngUnsubscribe$ = new Subject<void>();
    private workTracker: WorkTracker = new WorkTracker();
    working: boolean;

    screenTree: ScreenTree[];
    allScreens: EnhancedHelpDto[];
    allReturnScreens: EnhancedHelpDto[];
    helpScreens: HelpGroup[];
    screenHelpLookup: ScreenHelpLookup;

    enhancedHelpTreeOptions = enhancedHelpTreeOptions;
    selectedHelp$ = new BehaviorSubject({});
    indexOfSelectedHelp$ = new BehaviorSubject<number>(0);

    error: string;

    form: FormGroup;
    selectedForm: FormGroup;
    showSelection = false;
    file: File;

    constructor(
        private enhancedHelpAdminService: EnhancedHelpAdminService,
        private enhancedHelpService: EnhancedHelpService,
        private enhancedHelpTreeService: EnhancedHelpTreeService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective,
        private fb: FormBuilder,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.selectedForm = new FormGroup({});
    }

    ngOnInit() {
        this.refreshHelpValues();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshHelpValues() {
        forkJoin([
            this.enhancedHelpAdminService.findAll(),
            this.enhancedHelpService.findAllScreenHelp()
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([enhancedHelp, screenHelp]) => {
                    this.allScreens = enhancedHelp;
                    this.allReturnScreens = enhancedHelp;
                    this.screenTree =
                        this.enhancedHelpTreeService.screenTree.sort((a, b) => {
                            const nameA = a.name.toUpperCase();
                            const nameB = b.name.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0;
                        });
                    this.helpScreens = this.getHelpScreens();
                    this.screenHelpLookup = this.generateLookups(screenHelp);
                    this.setupForm();
                },
                error: (error: FlyFreelyError) => {
                    this.error = error.message;
                }
            })
            .add(this.workTracker.createTracker());
    }

    onSaveChanges(formGroup: FormGroup) {
        const value = formGroup.value;
        const alwaysOnUntil =
            value.alwaysOnUntil != null
                ? moment(
                      moment(
                          <Date>value.alwaysOnUntil
                      ) /* .format('YYYY-MM-DD') */
                  ).toISOString()
                : null;
        const command: UpdateEnhancedHelpCommand = {
            ...value,
            alwaysOnUntil: alwaysOnUntil
        };

        this.enhancedHelpAdminService
            .update(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success(
                        `Help text for ${value.helpTitle} updated successfully`
                    );
                    formGroup.markAsPristine();
                },
                error: (error: FlyFreelyError) => {
                    this.error = error.message;
                }
            })
            .add(this.workTracker.createTracker());
    }

    setupForm() {
        this.form = this.fb.group({
            helpItems: this.fb.array([])
        });
        this.populateForm();
    }

    get helpForms() {
        return this.form.get('helpItems') as FormArray;
    }

    generateLookups(screenHelp: ScreenHelpDto[]) {
        if (screenHelp == null) {
            return null;
        }
        return screenHelp.reduce(
            (acc, s) => ({
                ...acc,
                [s.screen]: {
                    knowledgeBaseUrl: s.knowledgeBaseUrl,
                    supportVideoUrl: s.supportVideoUrl
                }
            }),
            {}
        );
    }

    populateForm() {
        // First populate screens from static array
        for (let i = 0; i < this.screenTree.length; i++) {
            const helpScreen = this.fb.group({
                id: this.screenTree[i].identifier,
                name: this.screenTree[i].name,
                forms: this.fb.array([]),
                knowledgeBaseUrl:
                    this.screenHelpLookup != null &&
                    this.screenHelpLookup[this.screenTree[i].identifier] != null
                        ? this.screenHelpLookup[this.screenTree[i].identifier]
                              .knowledgeBaseUrl
                        : null,
                supportVideoUrl:
                    this.screenHelpLookup != null &&
                    this.screenHelpLookup[this.screenTree[i].identifier] != null
                        ? this.screenHelpLookup[this.screenTree[i].identifier]
                              .supportVideoUrl
                        : null
            });
            // Then populate each editable field with either blank fields or existing data from the server.
            for (let j = 0; j < this.screenTree[i].components.length; j++) {
                const helpScreenIndex = this.helpScreens.findIndex(
                    e => e.identifier === this.screenTree[i].identifier
                );
                // If server has help-text for this field, use that
                if (
                    helpScreenIndex >= 0 &&
                    this.helpScreens[helpScreenIndex].components.findIndex(
                        e =>
                            e.component ===
                            this.screenTree[i].components[j].identifier
                    ) !== -1
                ) {
                    const helpTextIndex = this.helpScreens[
                        helpScreenIndex
                    ].components.findIndex(
                        e =>
                            e.component ===
                            this.screenTree[i].components[j].identifier
                    );
                    const alwaysOnUntil =
                        this.helpScreens[helpScreenIndex].components[
                            helpTextIndex
                        ]?.alwaysOnUntil;
                    const form = this.fb.group({
                        screen: this.screenTree[i].identifier,
                        component: this.screenTree[i].components[j].identifier,
                        type: this.screenTree[i].components[j].type,
                        name: this.screenTree[i].components[j].name,
                        helpTitle:
                            this.helpScreens[helpScreenIndex].components[
                                helpTextIndex
                            ].helpTitle ??
                            this.screenTree[i].components[j].name,
                        helpText:
                            this.helpScreens[helpScreenIndex].components[
                                helpTextIndex
                            ].helpText,
                        knowledgeBaseUrl:
                            this.helpScreens[helpScreenIndex].components[
                                helpTextIndex
                            ]?.knowledgeBaseUrl,
                        supportVideoUrl:
                            this.helpScreens[helpScreenIndex].components[
                                helpTextIndex
                            ]?.supportVideoUrl,
                        alwaysOnUntil:
                            alwaysOnUntil != null
                                ? moment(alwaysOnUntil).toDate()
                                : null
                    });
                    const comp = helpScreen.get('forms') as FormArray;
                    comp.push(form);
                    // Else generate the field with an empty help-text box.
                } else {
                    const form = this.fb.group({
                        component: this.screenTree[i].components[j].identifier,
                        type: this.screenTree[i].components[j].type,
                        name: this.screenTree[i].components[j].name,
                        helpTitle: this.screenTree[i].components[j].name,
                        helpText: '',
                        screen: this.screenTree[i].identifier,
                        knowledgeBaseUrl: undefined,
                        supportVideoUrl: undefined,
                        alwaysOnUntil: undefined
                    });
                    const comp = helpScreen.get('forms') as FormArray;
                    comp.push(form);
                }
            }
            this.helpForms.push(helpScreen);
        }
    }

    getHelpScreens() {
        const updateOrCreate = (helpGroup: HelpGroup, entry: EnhancedHelpDto) =>
            helpGroup != null
                ? {
                      ...helpGroup,
                      components: helpGroup.components.concat(entry)
                  }
                : {
                      identifier: entry.screen,
                      name: entry.screen,
                      components: [entry]
                  };

        const screens: {
            [screenIdentifier: string]: HelpGroup;
        } = this.allScreens.reduce(
            (acc, entry: EnhancedHelpDto) => ({
                ...acc,
                [entry.screen]: updateOrCreate(acc[entry.screen], entry)
            }),
            {}
        );

        return Object.keys(screens).map(identifier => screens[identifier]);
    }

    parseDataForExport() {
        const formValue: HelpItems[] = this.form.controls.helpItems.value;
        return this.screenTree
            .map(item =>
                item.components.map(component => ({
                    screenName: item.name,
                    screenIdentifier: item.identifier,
                    name: component.name,
                    identifier: component.identifier,
                    value:
                        this.adjustQuotes(
                            formValue
                                .find(i => i.id === item.identifier)
                                .forms.find(
                                    v =>
                                        v.component === component.identifier &&
                                        v.helpTitle === component.name
                                ).helpText
                        ) ?? ''
                }))
            )
            .reduce((acc, i) => acc.concat(i));
    }

    saveScreenHelp(
        screenIdentifier: string,
        knowledge: FormControl,
        video: FormControl
    ) {
        const command: UpdateScreenHelpCommand = {
            knowledgeBaseUrl: knowledge.value,
            supportVideoUrl: video.value
        };
        if (
            command.knowledgeBaseUrl != null ||
            command.supportVideoUrl != null
        ) {
            this.enhancedHelpAdminService
                .updateScreenHelp(command, screenIdentifier)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(result => {
                    this.logging.success(
                        `Updated links for ${screenIdentifier}`
                    );
                    knowledge.patchValue(result.knowledgeBaseUrl);
                    video.patchValue(result.supportVideoUrl);
                    knowledge.markAsPristine();
                    video.markAsPristine();
                })
                .add(this.workTracker.createTracker());
        }
    }

    export() {
        downloadCsv(exportTemplate, this.parseDataForExport());
    }

    import(file: any) {
        const doneWorking = this.workTracker.createTracker();
        const existingValues: ImportFileFormat[] = this.parseDataForExport();
        let unknownTree: boolean = false;
        parseCsvByHeadings(file, {
            'Screen Name': 'screenName',
            'Screen Identifier': 'screenIdentifier',
            'Component Name': 'name',
            'Component Identifier': 'identifier',
            'Help Text': 'value'
        })
            .then((result: ImportFileFormat[]) =>
                result.forEach(item => {
                    if (
                        existingValues.findIndex(
                            i => i.screenIdentifier === item.screenIdentifier
                        ) === -1
                    ) {
                        unknownTree = true;
                    } else if (
                        existingValues.findIndex(
                            i =>
                                i.screenIdentifier === item.screenIdentifier &&
                                i.identifier === item.identifier
                        ) === -1
                    ) {
                        unknownTree = true;
                    } else if (
                        existingValues.findIndex(
                            i =>
                                i.screenIdentifier === item.screenIdentifier &&
                                i.identifier === item.identifier &&
                                i.value === item.value
                        ) === -1
                    ) {
                        this.pushValue(item);
                    } else {
                        // do nothing
                    }
                })
            )
            .then(() => {
                if (unknownTree === true) {
                    this.logging.warn(
                        'Some rows in the imported file do not coincide with existing help screens. Please ensure to only update values for existing help screens.'
                    );
                }
                doneWorking();
            })
            .catch((error: FlyFreelyError) => {
                doneWorking();
                this.logging.error(
                    error,
                    `An error occured during import: ${error.message}`
                );
            });
    }

    adjustQuotes(value: string) {
        // Adjust quotes in the value text for csv compatibility to prevent import errors.
        const newValue = value.replace(/"/g, `""`);
        return newValue;
    }

    pushValue(row: ImportFileFormat) {
        const index = this.helpForms.value.findIndex(
            (control: HelpItems) => control.id === row.screenIdentifier
        );
        const comp = this.helpForms.controls[index].get(
            'forms'
        ) as FormArray;
        const i = comp.value.findIndex(
            (c: HelpEntry) => c.component === row.identifier
        );

        // This escapes borderline cases where the value hasn't changed, but still triggers this function
        // resulting in the control being marked as dirty with no change to its value.
        // This seems to only be applicable to fields that have had there quotes adjusted during export.
        if (comp.controls[i].value.helpText === row.value) {
            return;
        }

        comp.controls[i].patchValue({
            helpText: row.value
        });
        comp.controls[i].markAsDirty();
    }

    activateTreeNode(event: any) {
        this.showSelection = false;
        this.selectedForm = null;
        const index = this.helpForms.value.findIndex(
            (control: HelpItems) => control.id === event.node.data.identifier
        );

        const comp = this.helpForms.controls[index];
        this.selectedForm = comp as FormGroup;
        this.showSelection = true;

        this.selectedHelp$.next(comp);
        this.indexOfSelectedHelp$.next(index);
    }

    countItemsWithWarning(event: any) {
        const help = this.helpForms.value.find(
            (control: HelpItems) => control.id === event.identifier
        );

        let count = 0;

        help.forms.map(h => {
            count += !h?.helpText && 1;
        });

        return count;
    }
}
