<ng-container *ngIf="dashboardService.sideBarState$ | async as sidebar">
    <ul
        *ngIf="
            currentOrganisation != null &&
            currentOrganisation.type === 'organisation_loaded'
        "
        class="nav flex-column"
    >
        <li class="nav-item nav-heading">
            <ul class="nav">
                <li class="nav-item" *ngIf="visibleSections.generalTasks">
                    <span class="nav-link">
                        <span class="fa-list-check fa nav-icon"></span>
                        <span class="fa-list-check fa nav-icon active"></span>
                        <span
                            class="name"
                            (click)="scrollTo('generalTasksSection')"
                            >General Tasks
                        </span>
                        <div
                            class="d-flex vertical-align pull-right"
                            mdcFormField
                        >
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [(ngModel)]="
                                            selectedWidgets.generalTasks
                                        "
                                        (change)="onMenuChanged()"
                                    />
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
                <li class="nav-item" *ngIf="visibleSections.operations">
                    <span class="nav-link">
                        <span class="fa-industry-windows fa nav-icon"></span>
                        <span
                            class="fa-industry-windows fa nav-icon active"
                        ></span>
                        <span
                            class="name"
                            (click)="scrollTo('operationsSection')"
                            >Operations
                        </span>
                        <div
                            class="d-flex vertical-align pull-right"
                            mdcFormField
                        >
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [(ngModel)]="selectedWidgets.operations"
                                        (change)="onMenuChanged()"
                                    />
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
                <li class="nav-item" *ngIf="visibleSections.resources">
                    <span class="nav-link">
                        <span class="fa-drone fa nav-icon"></span>
                        <span class="fa-drone fa nav-icon active"></span>
                        <span
                            class="name"
                            (click)="scrollTo('resourcesSection')"
                            >Resources
                        </span>
                        <div
                            class="d-flex vertical-align pull-right"
                            mdcFormField
                        >
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [(ngModel)]="selectedWidgets.resources"
                                        (change)="onMenuChanged()"
                                    />
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
                <li class="nav-item" *ngIf="visibleSections.organisation">
                    <span class="nav-link">
                        <span class="fa-buildings fa nav-icon"></span>
                        <span class="fa-buildings fa nav-icon active"></span>
                        <span
                            class="name"
                            (click)="scrollTo('organisationSection')"
                            >Organisation
                        </span>
                        <div
                            class="d-flex vertical-align pull-right"
                            mdcFormField
                        >
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [(ngModel)]="
                                            selectedWidgets.organisation
                                        "
                                        (change)="onMenuChanged()"
                                    />
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
            </ul>
        </li>
    </ul>
</ng-container>
