<div class="modal-body-content container-scroll">
    <enhanced-help
        componentName="managing-organisations"
        [compact]="true"
    >
    </enhanced-help>
    <form>
        <table class="table">
            <thead>
                <tr>
                    <th>Member of Organisation</th>
                    <th>Organisation Can Manage Your Profile</th>
                    <th>Default Organisation</th>
                    <!-- <th>
                        Remove
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let organisation of availableOrganisations">
                    <td>
                        {{ organisation.name }}
                    </td>
                    <td>
                        <div
                            *ngIf="!organisation.personalOrganisation"
                            mdcFormField
                        >
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [checked]="
                                            managingOrganisations[organisation.id]
                                        "
                                        [disabled]="working"
                                        (change)="
                                            manageSelected($event, organisation)
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <span *ngIf="organisation.personalOrganisation"
                            >N/A</span
                        >
                    </td>

                    <td>
                        <input
                            type="radio"
                            name="defaultOrg"
                            (click)="changeDefault(organisation)"
                            [disabled]="working"
                            [checked]="
                                organisation.id === person.defaultOrganisationId
                            "
                        />
                    </td>
                    <!-- <td>   
                        <div *ngIf="organisation.personalOrganisation === false">
                        <button
                            type="button"
                            class="btn-link btn-tertiary text-center"
                            (click)="removeOrganisation(organisation)"
                            [disabled]="working"
                        >
                            <span class="fa fa-times-circle"></span>
                        </button>     
                        </div>               
                    </td> -->
                </tr>
            </tbody>
        </table>
    </form>
</div>
