import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { BatteryDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'battery-list-item',
    templateUrl: './battery-list-item.component.html',
    styleUrls: ['./battery-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatteryListItemComponent {
    @Input() item: BatteryDto;

    ngOnInit() {
        console.log(this.item);
    }
}
