import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    ILinksService,
    LinkDetails,
    LinkDto
} from 'libs/attachments/src/lib/link-manager/link-manager.interface';

@Injectable({
    providedIn: 'root'
})
export class TaskLinkManagerService implements ILinksService {
    constructor(private apollo: Apollo) {}

    createLink(sectionId: number, details: LinkDetails): Observable<LinkDto> {
        return this.apollo
            .mutate<{ createTaskLink: LinkDto }>({
                mutation: gql`
                    mutation createTaskLink(
                        $taskId: Long!
                        $details: LinkDetails!
                    ) {
                        createTaskLink(taskId: $taskId, details: $details) {
                            id
                            title
                            url
                        }
                    }
                `,
                variables: {
                    taskId: sectionId,
                    details
                }
            })
            .pipe(
                tap(() => this.loadLinks(sectionId)), // Reload links after creating
                map(response => response.data.createTaskLink)
            );
    }

    deleteLink(sectionId: number, linkId: number): Observable<boolean> {
        return this.apollo
            .mutate<{ deleteTaskLink: { success: boolean } }>({
                mutation: gql`
                    mutation deleteTaskLink($taskId: Long!, $linkId: Long!) {
                        deleteTaskLink(taskId: $taskId, linkId: $linkId) {
                            success
                        }
                    }
                `,
                variables: {
                    taskId: sectionId,
                    linkId
                }
            })
            .pipe(
                tap(() => this.loadLinks(sectionId)), // Reload links after deleting
                map(response => response.data.deleteTaskLink.success)
            );
    }

    loadLinks(taskId: number): Observable<LinkDto[]> {
        return this.apollo
            .mutate<{ findTaskLinks: LinkDto[] }>({
                mutation: gql`
                    mutation findTaskLinks($taskId: Long!) {
                        findTaskLinks(taskId: $taskId) {
                            id
                            title
                            url
                        }
                    }
                `,
                variables: {
                    taskId
                }
            })
            .pipe(map(response => response.data.findTaskLinks));
    }

    updateLink(
        sectionId: number,
        linkId: number,
        details: LinkDetails
    ): Observable<LinkDto> {
        return this.apollo
            .mutate<{ updateTaskLink: LinkDto }>({
                mutation: gql`
                    mutation updateTaskLink(
                        $taskId: Long!
                        $linkId: Long!
                        $details: LinkDetails!
                    ) {
                        updateTaskLink(
                            taskId: $taskId
                            linkId: $linkId
                            details: $details
                        ) {
                            id
                            title
                            url
                        }
                    }
                `,
                variables: {
                    taskId: sectionId,
                    linkId,
                    details
                }
            })
            .pipe(
                tap(() => this.loadLinks(sectionId)), // Reload links after updating
                map(response => response.data.updateTaskLink)
            );
    }
}
