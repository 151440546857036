import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
    AttachmentDto,
    GqlQueryResult,
    Pagination,
    ResultDto,
    SimplePersonDto
} from '@flyfreely-portal-ui/flyfreely';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LinkDto } from 'libs/flyfreely/src/lib/model/api/airworthnessNoticeDto';

export interface GeneralTask {
    id: number;
    summary: string;
    description: string;
    dueDate: string;
    reporter: SimplePersonDto;
    assignee: SimplePersonDto;
    priority: TaskPriority;
    status: TaskStatus;
    attachmentList: AttachmentDto[];
    commentList: TaskComment[];
    linkList: LinkDto[];
    availableActions: {
        canEdit: boolean;
        canDelete: boolean;
        canContribute: boolean;
    };
}

export interface TaskComment {
    id: number;
    author: {
        id: number;
        firstName: string;
        lastName: string;
    };
    creationTime: string;
    modificationTime: string;
    content: {
        ops: any[];
    };
}

export interface CreateTaskCommand {
    taskId: number;
    summary: string;
    description: string;
    dueDate: string;
    assigneeId: number;
    priority: TaskPriority;
}

export interface UpdateTaskCommand {
    taskId: number;
    summary: string;
    description: string;
    dueDate: string;
    assigneeId: number;
    priority: TaskPriority;
    status: TaskStatus;
}

export type TaskPriority = 'LOW' | 'MEDIUM' | 'HIGH';
export const TaskPriority = {
    LOW: 'LOW' as TaskPriority,
    MEDIUM: 'MEDIUM' as TaskPriority,
    HIGH: 'HIGH' as TaskPriority
};
export type TaskStatus = 'TODO' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED';
export const TaskStatus = {
    TODO: 'TODO' as TaskStatus,
    IN_PROGRESS: 'IN_PROGRESS' as TaskStatus,
    COMPLETED: 'COMPLETED' as TaskStatus,
    CANCELLED: 'CANCELLED' as TaskStatus
};

@Injectable({ providedIn: 'root' })
export class GeneralTasksService {
    constructor(private apollo: Apollo) {}

    findTasks(organisationId: number, pagination: Pagination) {
        return this.apollo
            .query<{
                findTasks: GqlQueryResult<GeneralTask>;
            }>({
                query: gql`
                    query findTasks(
                        $organisationId: Long!
                        $page: Int
                        $pageSize: Int
                    ) {
                        findTasks(
                            criteria: { organisationId: $organisationId }
                            page: $page
                            pageSize: $pageSize
                        ) {
                            count
                            results {
                                id
                                summary
                                description
                                dueDate
                                reporter {
                                    id
                                    firstName
                                    lastName
                                }
                                assignee {
                                    id
                                    firstName
                                    lastName
                                }
                                priority
                                status
                                availableActions {
                                    canEdit
                                    canDelete
                                    canContribute
                                }
                            }
                        }
                    }
                `,
                variables: {
                    organisationId,
                    ...pagination
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findTasks)
            );
    }

    findTaskById(taskId: number): Observable<GeneralTask> {
        return this.apollo
            .query<{
                findTaskById: GeneralTask;
            }>({
                query: gql`
                    query findTaskById($taskId: Long!) {
                        findTaskById(taskId: $taskId) {
                            id
                            summary
                            description
                            dueDate
                            reporter {
                                id
                                firstName
                                lastName
                            }
                            assignee {
                                id
                                firstName
                                lastName
                            }
                            priority
                            status
                            attachmentList {
                                id
                                attachmentVersionId
                                name
                                description
                                purpose
                                contentType
                                version
                                originalFileName
                                changes
                                uploadTime
                                requiresAcknowledgement
                                requirementId
                            }
                            linkList {
                                id
                                title
                                url
                            }
                            commentList {
                                id
                                author {
                                    id
                                    firstName
                                    lastName
                                }
                                creationTime
                                modificationTime
                                content {
                                    ops
                                }
                            }
                            availableActions {
                                canEdit
                                canDelete
                                canContribute
                            }
                        }
                    }
                `,
                variables: {
                    taskId
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findTaskById)
            );
    }

    createTask(args: CreateTaskCommand): Observable<GeneralTask> {
        return this.apollo
            .mutate<{ createTask: GeneralTask }>({
                mutation: gql`
                    mutation createTask(
                        $taskId: Long!
                        $summary: String!
                        $description: String!
                        $dueDate: LocalDate
                        $assigneeId: Long
                        $priority: TaskPriority!
                    ) {
                        createTask(
                            taskId: $taskId
                            summary: $summary
                            description: $description
                            dueDate: $dueDate
                            assigneeId: $assigneeId
                            priority: $priority
                        ) {
                            id
                            summary
                            description
                            dueDate
                            reporter {
                                id
                                firstName
                                lastName
                            }
                            assignee {
                                id
                                firstName
                                lastName
                            }
                            priority
                            status
                            availableActions {
                                canEdit
                                canDelete
                                canContribute
                            }
                            organisationId
                            creationTime
                            modificationTime
                            attachmentList {
                                id
                                attachmentVersionId
                                name
                                description
                                purpose
                                contentType
                                version
                                originalFileName
                                changes
                                uploadTime
                                requiresAcknowledgement
                                requirementId
                            }
                            linkList {
                                id
                                title
                                url
                            }
                            commentList {
                                id
                                author {
                                    id
                                    firstName
                                    lastName
                                }
                                creationTime
                                modificationTime
                                content {
                                    ops
                                }
                            }
                        }
                    }
                `,
                variables: {
                    ...args
                }
            })
            .pipe(
                map(response => {
                    if (response?.data?.createTask) {
                        return response.data.createTask;
                    }
                    throw new Error('Create task mutation failed');
                })
            );
    }

    updateTask(args: UpdateTaskCommand): Observable<GeneralTask> {
        return this.apollo
            .mutate<{
                updateTask: GeneralTask;
            }>({
                mutation: gql`
                    mutation updateTask(
                        $taskId: Long!
                        $summary: String!
                        $description: String!
                        $dueDate: LocalDate
                        $assigneeId: Long
                        $priority: TaskPriority!
                        $status: TaskStatus!
                    ) {
                        updateTask(
                            taskId: $taskId
                            summary: $summary
                            description: $description
                            dueDate: $dueDate
                            assigneeId: $assigneeId
                            priority: $priority
                            status: $status
                        ) {
                            id
                            summary
                            description
                            dueDate
                            reporter {
                                id
                                firstName
                                lastName
                            }
                            assignee {
                                id
                                firstName
                                lastName
                            }
                            priority
                            status
                            availableActions {
                                canEdit
                                canDelete
                                canContribute
                            }
                        }
                    }
                `,
                variables: {
                    ...args
                }
            })
            .pipe(
                map(result => {
                    if (result?.data?.updateTask != null) {
                        return result.data.updateTask;
                    }
                    throw new Error('Update task mutation failed');
                })
            );
    }

    newTask(organisationId: number): Observable<GeneralTask> {
        return this.apollo
            .mutate<{
                newTask: GeneralTask;
            }>({
                mutation: gql`
                    mutation newTask($organisationId: Long!) {
                        newTask(organisationId: $organisationId) {
                            id
                            summary
                            description
                            dueDate
                            reporter {
                                id
                                firstName
                                lastName
                            }
                            assignee {
                                id
                                firstName
                                lastName
                            }
                            priority
                            status
                            availableActions {
                                canEdit
                                canDelete
                                canContribute
                            }
                            organisationId
                            creationTime
                            modificationTime
                            attachmentList {
                                id
                                attachmentVersionId
                                name
                                description
                                purpose
                                contentType
                                version
                                originalFileName
                                changes
                                uploadTime
                                requiresAcknowledgement
                                requirementId
                            }
                            linkList {
                                id
                                title
                                url
                            }
                            commentList {
                                id
                                author {
                                    id
                                    lastName
                                    firstName
                                }
                                creationTime
                                modificationTime
                                content {
                                    ops
                                }
                            }
                        }
                    }
                `,
                variables: { organisationId }
            })
            .pipe(
                map(result => {
                    if (result?.data?.newTask != null) {
                        return result.data.newTask;
                    }
                    throw new Error('New task mutation failed');
                })
            );
    }

    deleteTask(taskId: number): Observable<boolean> {
        return this.apollo
            .mutate<{
                deleteTask: { success: boolean };
            }>({
                mutation: gql`
                    mutation deleteTask($taskId: Long!) {
                        deleteTask(taskId: $taskId) {
                            success
                        }
                    }
                `,
                variables: { taskId }
            })
            .pipe(
                map(result => {
                    if (result?.data?.deleteTask?.success) {
                        return result.data.deleteTask.success;
                    }
                    throw new Error('Delete task mutation failed');
                })
            );
    }

    createTaskComment(
        taskId: number,
        content: Object
    ): Observable<TaskComment> {
        return this.apollo
            .mutate<{ createTaskComment: TaskComment }>({
                mutation: gql`
                    mutation createTaskComment(
                        $taskId: Long!
                        $content: CommentContentInput!
                    ) {
                        createTaskComment(taskId: $taskId, content: $content) {
                            id
                            author {
                                id
                                firstName
                                lastName
                            }
                            creationTime
                            modificationTime
                            content {
                                ops
                            }
                        }
                    }
                `,
                variables: {
                    taskId,
                    content
                }
            })
            .pipe(
                map(response => {
                    if (response?.data?.createTaskComment) {
                        return response.data.createTaskComment;
                    }
                    throw new Error('Create task comment failed');
                })
            );
    }

    findTaskComments(taskId: number): Observable<TaskComment[]> {
        return this.apollo
            .query<{ findTaskComments: TaskComment[] }>({
                query: gql`
                    query findTaskComments($taskId: Long!) {
                        findTaskComments(taskId: $taskId) {
                            id
                            author {
                                id
                                firstName
                                lastName
                            }
                            creationTime
                            modificationTime
                            content {
                                ops
                            }
                        }
                    }
                `,
                variables: {
                    taskId
                }
            })
            .pipe(
                map(response => {
                    if (response?.data?.findTaskComments) {
                        return response.data.findTaskComments;
                    }
                    throw new Error('Find task comments query failed');
                })
            );
    }

    updateTaskComment(
        taskId: number,
        commentId: number,
        content: Object
    ): Observable<TaskComment> {
        return this.apollo
            .mutate<{ updateTaskComment: TaskComment }>({
                mutation: gql`
                    mutation updateTaskComment(
                        $taskId: Long!
                        $commentId: Long!
                        $content: CommentContentInput!
                    ) {
                        updateTaskComment(
                            taskId: $taskId
                            commentId: $commentId
                            content: $content
                        ) {
                            id
                            author {
                                id
                                firstName
                                lastName
                            }
                            creationTime
                            modificationTime
                            content {
                                ops
                            }
                        }
                    }
                `,
                variables: {
                    taskId,
                    commentId,
                    content
                }
            })
            .pipe(
                map(response => {
                    if (response?.data?.updateTaskComment) {
                        return response.data.updateTaskComment;
                    }
                    throw new Error('Update task comment mutation failed');
                })
            );
    }

    deleteTaskComment(taskId: number, commentId: number): Observable<boolean> {
        return this.apollo
            .mutate<{ deleteTaskComment: ResultDto }>({
                mutation: gql`
                    mutation deleteTaskComment(
                        $taskId: Long!
                        $commentId: Long!
                    ) {
                        deleteTaskComment(
                            taskId: $taskId
                            commentId: $commentId
                        ) {
                            success
                        }
                    }
                `,
                variables: {
                    taskId,
                    commentId
                }
            })
            .pipe(
                map(response => {
                    if (response?.data?.deleteTaskComment?.success) {
                        return response.data.deleteTaskComment.success;
                    }
                    throw new Error('Delete task comment mutation failed');
                })
            );
    }
}
