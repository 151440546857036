<div class="modal-content">
    <div class="modal-header no-icon">
        <h4 class="modal-title pull-left">Mission Phase Handover</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-body-content inner-container">
            <div class="col-xs-12">
                <div class="col-xs-6" style="padding: 0">
                    <img [src]="graphicPath" />
                </div>
                <div class="col-xs-6">
                    <div class="left-buffer right-buffer">
                        <div style="min-height: 45px"></div>
                        <h4 class="popup-title">
                            {{
                                useSetupGuideInterface
                                    ? 'Download the Field App'
                                    : 'Mission Launch'
                            }}
                        </h4>
                        <h4
                            *ngIf="!useSetupGuideInterface"
                            class="popup-heading"
                        >
                            <span class="fal fa-clock"></span>
                            {{ date | date: 'd MMMM yyyy' }}
                        </h4>
                        <div class="row contents top-buffer right-buffer">
                            <p
                                *ngIf="!useSetupGuideInterface"
                                class="popup-text"
                            >
                                This mission has moved from the planning phase
                                and is now awaiting to be executed in the
                                <strong>Field App</strong>*.
                            </p>
                            <p
                                *ngIf="useSetupGuideInterface"
                                class="popup-text"
                            >
                                Use the links below to download the offical
                                FlyFreely
                                <strong>Field App</strong> for mission
                                execution*.
                            </p>
                            <div style="min-height: 10px"></div>
                            <div
                                class="horizontal-container"
                                style="
                                    height: 40px;
                                    min-height: 40px;
                                    justify-content: space-around;
                                "
                            >
                                <a
                                    href="https://apps.apple.com/au/app/flyfreely-field-app/id1480135793"
                                    target="_blank"
                                    ><img [src]="appStore"
                                /></a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=io.flyfreely.fieldapp"
                                    target="_blank"
                                    ><img [src]="playStore"
                                /></a>
                            </div>
                        </div>
                        <div style="min-height: 30px"></div>
                        <p class="popup-fine-print">
                            * Mission Execution via field app is encouraged but
                            not mandatory. <br />Some workflows require a
                            pre-departure step to be completed.
                        </p>
                        <div style="min-height: 10px"></div>
                        <div *ngIf="!useSetupGuideInterface" class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    [checked]="!shouldShow"
                                    (click)="toggleShow()"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                <span>Don't show again</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
