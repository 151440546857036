<div class="container-with-footer">
    <div class="vertical-container">
        <h4 class="primary-heading top-heading">Authorities</h4>
        <div class="form-group">
            <jurisdiction-selection
                [availableJurisdictions]="availableJurisdictions"
                (selectJurisdictionId)="onSetJurisdictionId($event)"
            >
            </jurisdiction-selection>
            <div
                class="horizontal-container vertical-align contents left-buffer top-buffer bottom-buffer"
                style="margin-top: 24px"
            >
                <div
                    class="top-buffer right-buffer"
                    style="margin-top: 0; margin-bottom: 0"
                >
                    <div mdcFormField>
                        <div mdcSwitch>
                            <div mdcSwitchThumb>
                                <input
                                    type="checkbox"
                                    mdcSwitchInput
                                    [(ngModel)]="showArchived"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="onChangeShowArchived()"
                                />
                            </div>
                        </div>
                        <label mdcFormFieldLabel
                            >Show archived authorities?</label
                        >
                    </div>
                </div>
                <ng-container *ngIf="numberOfArchived > 0 && !showArchived">
                    <div @fadeOutOnLeave>
                        <span class="left-buffer hint-block" @fadeInOnEnter>
                            ({{ numberOfArchived }} archived
                            {{
                                numberOfArchived == 1
                                    ? 'authority'
                                    : 'authorities'
                            }})
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="top-buffer form-group" *ngIf="availableAuthorities != null">
            <label class="display-label">Add Authority</label>
            <p *ngIf="availableAuthorities.length === 0">
                No Authorities Available to Add
            </p>
            <authority-group-add
                *ngIf="availableAuthorities.length !== 0"
                [working]="working"
                (createAuthority)="createAuthority(edit_dialogue, $event)"
            ></authority-group-add>
        </div>

        <div class="container-scroll top-buffer">
            <ng-container *ngFor="let jurisdiction of inUseJurisdictions">
                <ng-container
                    *ngIf="
                        visibleAuthorities[jurisdiction.id] != null &&
                        visibleAuthorities[jurisdiction.id].length > 0
                    "
                >
                    <h4
                        class="primary-heading"
                        *ngIf="currentJurisdictionId == null"
                    >
                        {{ jurisdiction.name }}
                    </h4>
                    <div
                        class="horizontal-container tiled-children"
                    >
                        <ng-container
                            *ngFor="
                                let authorityType of visibleAuthorities[
                                    jurisdiction.id
                                ]
                            "
                        >
                            <authority-group-edit
                                [attr.id]="authorityType.id"
                                [authorityGroup]="authorityType"
                                [managingOrganisationId]="
                                    managingOrganisationId
                                "
                                [showArchived]="showArchived"
                                (onShowAuthority)="
                                    showAuthority(
                                        $event.authorityType,
                                        $event.authority
                                    )
                                "
                                (onCreateAuthority)="
                                    createAuthority(
                                        $event.authorityType,
                                        $event.authority
                                    )
                                "
                            ></authority-group-edit>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>

            <div *ngIf="authorities.length == 0">
                <div>
                    <div class="alert alert-info" role="alert">
                        No Authorities
                    </div>
                </div>
            </div>
        </div>
    </div>
    <loading-indicator [isLoading]="working"></loading-indicator>
    <div class="action-container">
        <button
            type="button"
            class="btn btn-primary pull-right"
            (click)="showList()"
        >
            Done
        </button>
    </div>
</div>

<ng-template #edit_dialogue>
    <authority-edit-dialogue
        [resolve]="resolve"
        [modalRef]="modalRef"
        (updated)="updatedAuthority($event)"
    >
    </authority-edit-dialogue>
</ng-template>
