import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import {
    ComponentTypeDto,
    CraftDto,
    MaintenanceTaskTypeDto,
    Resource,
    ResourceComponentInServiceTime,
    ResourceSchematicComponentDto,
    RpaTypesService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { fromNullable, getOrElse, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MaintenanceFormService } from '../maintenance-form.service';
import {
    DisplayableMaintenanceActivity,
    DisplayableMaintenanceLogDto,
    DisplayableMaintenanceTask,
    MaintenanceRequestService
} from '../maintenance-request.service';

enum Editing {
    NONE = 'NONE',
    ADD = 'ADD',
    EDIT = 'EDIT'
}
@Component({
    selector: 'maintenance-task-list',
    templateUrl: './maintenance-task-list.component.html'
})
export class MaintenanceTaskList {
    @Input() formArray: FormArray<FormControl<DisplayableMaintenanceTask>>;
    @Input() activityNumber: number;
    @Output() saveMaintenanceTask = new EventEmitter<void>();
    @Output() editMaintenanceTask = new EventEmitter<void>();
    currentlyEditingId: number;
    editing = Editing.NONE;

    diagramUrl: string;

    maintenanceLog: DisplayableMaintenanceLogDto;
    activities: DisplayableMaintenanceActivity;

    resource: Resource;
    resourceCategory: string;

    schematicId: number;
    components: ResourceSchematicComponentDto[];
    inServiceComponents: ResourceComponentInServiceTime[];

    taskTypes: MaintenanceTaskTypeDto[];
    hasComponents: boolean;
    hasTypes: boolean;

    private workTracker = new WorkTracker();
    working: boolean = false;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private maintenanceRequestService: MaintenanceRequestService,
        private maintenanceFormService: MaintenanceFormService,
        private rpaTypesService: RpaTypesService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                this.changeDetector.detectChanges();
            });

        combineLatest([
            this.maintenanceRequestService.resource$,
            this.maintenanceRequestService.resourceCategory$,
            this.maintenanceRequestService.taskTypes$,
            this.maintenanceRequestService.resourceSchematic$,
            this.maintenanceRequestService.totalTimeInService$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([resource, category, taskTypes, schematic, inService]) => {
                    this.resourceCategory = category;
                    this.resource = resource;
                    this.components = schematic?.components ?? null;
                    this.taskTypes = taskTypes;
                    this.inServiceComponents = inService.components;
                    this.hasTypes =
                        taskTypes != null && taskTypes.length > 0
                            ? true
                            : false;
                    this.hasComponents =
                        schematic != null && schematic.components.length > 0;
                    if (this.hasComponents === true && this.hasTypes === true) {
                        this.saveMaintenanceTask.emit();
                    }
                    if (
                        category != null &&
                        category === 'CRAFT' &&
                        resource != null
                    ) {
                        this.findSchematic(resource as CraftDto);
                    }
                }
            );

        this.maintenanceRequestService.addMaintenanceTask$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.showAddComponentForm());

        combineLatest([
            this.maintenanceRequestService.maintenanceLog$,
            this.maintenanceRequestService.totalTimeInService$
        ])
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                distinctUntilChanged(),
                // debounce prevents errors during saving due to observable cycling
                debounceTime(500)
            )
            .subscribe(([update, inService]) => {
                this.maintenanceLog = update.log;

                const activity = update.log.activities.find(
                    act => act.number === this.activityNumber
                );
                if (activity != null && this.activities !== activity) {
                    this.activities = activity;
                    this.maintenanceFormService.setTasksFromActivity(
                        activity,
                        inService.components
                    );
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findSchematic(resource: CraftDto) {
        const modelId = resource.rpaTypeId;
        this.rpaTypesService
            .findRpaType(modelId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(model => {
                this.schematicId = model.schematicId;
            });
    }

    getAttachmentUrl(component: any) {
        if (this.editing !== Editing.NONE) {
            return;
        }
        if (component == null) {
            this.diagramUrl = null;
            return;
        }
        const attachmentId = this.components.find(
            c => c.id === component.componentId
        ).attachmentId;
        this.diagramUrl = this.rpaTypesService.getSchematicAttachmentUrl(
            this.schematicId,
            attachmentId
        );
    }

    getComponentAttachmentUrl(componentId: number) {
        const attachmentId = this.components.find(
            c => c.id === componentId
        ).attachmentId;
        this.diagramUrl = this.rpaTypesService.getSchematicAttachmentUrl(
            this.schematicId,
            attachmentId
        );
    }

    findTaskName(id: number) {
        const task = this.taskTypes.find(t => t.id === id);
        return task.name;
    }

    findComponentName(id: number) {
        const component = this.components.find(c => c.id === id);
        return `${component.componentType.name} ${component.designation}`;
    }

    findComponentIcon(id: number) {
        const component = this.components.find(c => c.id === id);
        if (
            component.componentType.iconFamily !==
                ComponentTypeDto.IconFamily.FONT_AWESOME ||
            component.componentType.icon == null
        ) {
            return null;
        }
        const icon = `right-buffer fal fa-${component.componentType.icon}`;
        return icon;
    }

    findTaskIcon(id: number) {
        const taskType = this.taskTypes.find(c => c.id === id);
        if (
            taskType.iconFamily !== ComponentTypeDto.IconFamily.FONT_AWESOME ||
            taskType.icon == null
        ) {
            return null;
        }
        const icon = `right-buffer fal fa-${taskType.icon}`;
        return icon;
    }

    editComponent(index: number) {
        this.editing = Editing.EDIT;
        this.currentlyEditingId = index;
        this.editMaintenanceTask.emit();
    }

    removeComponent(index: number) {
        this.formArray.removeAt(index);
    }

    showAddComponentForm() {
        this.editing = Editing.ADD;
        this.currentlyEditingId = null;
    }

    onAddComponent(task: DisplayableMaintenanceTask) {
        this.editing = Editing.NONE;

        if (task) {
            const row = new FormControl<DisplayableMaintenanceTask>(
                this.toDisplayableMaintenanceTask(task)
            );
            this.formArray.push(row);
            this.formArray.markAsDirty();
            this.saveMaintenanceTask.emit();
        }
    }

    onEditComponent(task: DisplayableMaintenanceTask, index: number) {
        const row = this.toDisplayableMaintenanceTask(task, index);
        this.formArray.controls[index].patchValue(row);
        this.formArray.markAsDirty();
        this.editing = Editing.NONE;
        this.currentlyEditingId = null;
        this.saveMaintenanceTask.emit();
    }

    onCancelEdit() {
        this.editing = Editing.NONE;
        this.currentlyEditingId = null;
        this.saveMaintenanceTask.emit();
        this.getAttachmentUrl(this.formArray.value[0] ?? null);
    }

    onCompletedChanged() {
        this.formArray.markAsDirty();
    }

    private toDisplayableMaintenanceTask(
        task: DisplayableMaintenanceTask,
        index?: number
    ): DisplayableMaintenanceTask {
        return {
            ...task,
            required:
                index != null
                    ? this.formArray.controls[index].value.required
                    : false,
            completed:
                index != null
                    ? this.formArray.controls[index].value.completed
                    : false,
            taskTypeName: this.taskTypes.find(t => t.id === task.taskTypeId)
                ?.name,
            componentName: pipe(
                fromNullable(
                    this.components.find(c => c.id === task.componentId)
                ),
                map(
                    (component: ResourceSchematicComponentDto) =>
                        `${component.componentType.name} ${component.designation}`
                ),
                getOrElse(() => '')
            ),
            firmwareVersion: task.firmwareVersion,
            serialNumber: task.serialNumber
        };
    }
}
