import { Component, Input } from '@angular/core';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';
import { PreferencesService } from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';

const MISSION_FIELD_APP_INFO = 'mission-field-app-info';

@Component({
    selector: 'mission-field-app-handoff',
    templateUrl: './mission-field-app-handoff.component.html'
})
export class MissionFieldAppHandoffDialogue {
    @Input() date: string;
    @Input() organisationId: number;
    @Input() useSetupGuideInterface: boolean = false;

    missionDate: string;

    graphicPath: string;
    appStore: string;
    playStore: string;

    shouldShow: boolean = true;

    constructor(
        private modal: BsModalRef<MissionFieldAppHandoffDialogue>,
        private constants: FlyFreelyConstants,
        private preferencesService: PreferencesService
    ) {
        this.graphicPath = `${this.constants.IMG_URL}/mission-handoff-graphic.png`;
        this.appStore = `${this.constants.IMG_URL}/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png`;
        this.playStore = `${this.constants.IMG_URL}/Google_Play_Store_badge_EN.png`;
    }

    toggleShow() {
        this.shouldShow ? (this.shouldShow = false) : (this.shouldShow = true);
        const preference = {
            show: this.shouldShow
        };
        this.preferencesService
            .updatePreferences(MISSION_FIELD_APP_INFO, null, preference)
            .toPromise();
    }

    close() {
        this.modal.hide();
    }
}
