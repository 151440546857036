/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.33-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from 'geojson';
import { AirspaceJurisdictionLinkDto } from './airspaceJurisdictionLinkDto';
import { FirDto } from './firDto';
import { NotamSourceDto } from './notamSourceDto';
import { RulesetDto } from './rulesetDto';
import { SimpleAuthorityTypeDto } from './simpleAuthorityTypeDto';
import { SimpleOrganisationDto } from './simpleOrganisationDto';

/**
 * The jurisdiction that this location is in
 */
export interface AirspaceJurisdictionDto {
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The name for this instance which is displayed to users
     */
    name: string;
    identifier?: string;
    /**
     * What level of airspace check is supported
     */
    airspaceCheckSupport: AirspaceJurisdictionDto.AirspaceCheckSupport;
    hasAerodromeData: boolean;
    hasLibrary: boolean;
    /**
     * The URL for the airspace checker that will be used for this jurisdiction
     */
    airspaceCheckerUrl?: string;
    /**
     * Is this instance available for users to use
     */
    enabled: boolean;
    ansp?: SimpleOrganisationDto;
    caa?: SimpleOrganisationDto;
    atsb?: SimpleOrganisationDto;
    personalIdentifier?: SimpleAuthorityTypeDto;
    companyIdentifier?: SimpleAuthorityTypeDto;
    companyOperatorsCertificate?: SimpleAuthorityTypeDto;
    boundary?: Geometry;
    firList: Array<FirDto>;
    notamSourceList: Array<NotamSourceDto>;
    rulesetList: Array<RulesetDto>;
    airspaceDisclaimer?: string;
    hasNotifications: boolean;
    linkList: Array<AirspaceJurisdictionLinkDto>;
    hasAirspaceChecker: boolean;
}
export namespace AirspaceJurisdictionDto {
    export type AirspaceCheckSupport =
        | 'NONE'
        | 'AUTHORISATION_CHECK'
        | 'AIRSPACE_CHECK';
    export const AirspaceCheckSupport = {
        NONE: 'NONE' as AirspaceCheckSupport,
        AUTHORISATION_CHECK: 'AUTHORISATION_CHECK' as AirspaceCheckSupport,
        AIRSPACE_CHECK: 'AIRSPACE_CHECK' as AirspaceCheckSupport
    };
}
