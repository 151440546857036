import {
    ComponentFactoryResolver,
    Directive,
    Input,
    ViewContainerRef
} from '@angular/core';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { WidgetRegistration } from './interfaces';

export class Widget {}

/**
 * Inserts the widget component as a sibling of the attached element.
 *
 * There is a legacy feature where the [organisation] is set, but this is not a proper
 * component lifecycle (no `ngOnChanges`) so the preferred way of getting the organisation
 * is to subscribe to the `WorkspaceStateService` observable.
 */
@Directive({
    selector: '[widget]'
})
export class WidgetDirective {
    @Input() widget?: WidgetRegistration;

    constructor(
        private workspaceStateService: WorkspaceStateService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit() {
        if (this.widget == null) {
            throw new Error('No widget provided for widget directive');
        }
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                this.widget.component
            );
        this.viewContainerRef.clear();
        const componentRef =
            this.viewContainerRef.createComponent<Widget>(componentFactory);

        this.workspaceStateService.registerWidget(
            this.widget.widgetIdentifier,
            componentRef.location
        );
    }

    ngOnDestroy() {
        if (this.widget != null) {
            this.workspaceStateService.unregisterWidget(
                this.widget.widgetIdentifier
            );
        }
    }
}
