<form
    [formGroup]="batterySetForm"
    (ngSubmit)="submit()"
    novalidate
    class="container-with-footer"
>
    <div class="container-fluid">
        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <p>
                    A flight battery set is the battery or batteries used to
                    power a RPA for a single flight.
                </p>
            </div>

            <div class="col-xs-12">
                <label>Name</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batterySetForm.controls.name"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input id="name" class="form-control" formControlName="name" />
            </div>
        </div>
        <enhanced-help
            screenName="battery-set-edit"
            componentName="name"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Assembly Date</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batterySetForm.controls.assemblyDate"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        class="form-control"
                        #assemblyDatePicker="bsDatepicker"
                        bsDatepicker
                        formControlName="assemblyDate"
                    />
                    <span
                        class="input-group-addon btn-default"
                        (click)="assemblyDatePicker.toggle()"
                        [attr.aria-expanded]="assemblyDatePicker.isOpen"
                    >
                        <i
                            class="glyphicon glyphicon-calendar"
                            style="cursor: pointer"
                        ></i>
                    </span>
                </div>
            </div>
        </div>
        <enhanced-help
            screenName="battery-set-edit"
            componentName="assemblyDate"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Disassembly Date</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batterySetForm.controls.disassemblyDate"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        class="form-control"
                        #disassemblyDatePicker="bsDatepicker"
                        bsDatepicker
                        formControlName="disassemblyDate"
                    />
                    <span
                        class="input-group-addon btn-default"
                        (click)="disassemblyDatePicker.toggle()"
                        [attr.aria-expanded]="disassemblyDatePicker.isOpen"
                    >
                        <i
                            class="glyphicon glyphicon-calendar"
                            style="cursor: pointer"
                        ></i>
                    </span>
                </div>
            </div>
        </div>
        <enhanced-help
            screenName="battery-set-edit"
            componentName="disassemblyDate"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row top-buffer">
            <div class="col-xs-12">
                <label>Batteries in Set</label>
                <field-validation-indicator
                    class="pull-right"
                    [valid]="batterySetForm.controls.batteries.valid"
                ></field-validation-indicator>
            </div>
        </div>
        <enhanced-help
            screenName="battery-set-edit"
            componentName="batteryIds"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row top-buffer">
            <div class="col-xs-12">
                <div *ngIf="!batterySet.assemblyDate">
                    <div class="alert alert-info">
                        Specify an assembly date to display the available
                        batteries
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row top-buffer"
            *ngIf="
                batterySetForm.controls.assemblyDate.valid &&
                batterySetForm.controls.assemblyDate.value != null
            "
        >
            <div class="col-xs-12">
                <lockable
                    [isLocked]="lockedFields?.batteries"
                    message="This battery set has been used on a real mission so batteries cannot be added or removed"
                >
                    <ng-template lockedMode>
                        <p class="display-label">Included Batteries</p>
                        <div
                            *ngFor="
                                let battery of batterySetForm.controls.batteries
                                    .value;
                                let ix = index
                            "
                        >
                            <a (click)="showBatteryDetails(null, battery)"
                                >Name: {{ battery.name }} (SN:
                                {{ battery.manufacturerSerialNumber }}) Type:
                                {{ battery.batteryTypeId }}</a
                            >
                        </div>
                    </ng-template>

                    <ng-template unlockedMode>
                        <p class="display-label">Included Batteries</p>
                        <div
                            *ngFor="
                                let battery of batterySetForm.controls.batteries
                                    .value;
                                let ix = index
                            "
                        >
                            <a (click)="showBatteryDetails(null, battery)"
                                >Name: {{ battery.name }} (SN:
                                {{ battery.manufacturerSerialNumber }}) Type:
                                {{ battery.batteryTypeId }}</a
                            >
                            <button
                                class="btn btn-tertiary"
                                type="button"
                                (click)="removeBattery(ix)"
                            >
                                <span class="fa fa-minus"></span>
                            </button>
                        </div>
                        <p class="display-label">Available Batteries</p>
                        <div *ngFor="let battery of availableBatteries">
                            <div *ngIf="canAddBattery(battery)">
                                <a (click)="showBatteryDetails(null, battery)"
                                    >Name: {{ battery.name }} (SN:
                                    {{ battery.manufacturerSerialNumber }})
                                    Type:
                                    {{ battery.batteryTypeId }}
                                </a>
                                <button
                                    class="btn btn-tertiary"
                                    type="button"
                                    (click)="addBattery(battery)"
                                >
                                    <span class="fa fa-plus"></span>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </lockable>
            </div>
        </div>
    </div>
    <div class="action-container">
        <button
            type="button"
            class="btn btn-default pull-left"
            (click)="cancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary pull-right"
            type="submit"
            [disabled]="
                !(batterySet.batteries.length != 0 && batterySetForm.valid) ||
                working
            "
        >
            Save
        </button>
        <p class="text-danger" *ngIf="error">{{ error }}</p>
    </div>
</form>
