/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.11-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MaintenanceScheduleTriggerCommand { 
    type: MaintenanceScheduleTriggerCommand.Type;
    action: MaintenanceScheduleTriggerCommand.Action;
    inServiceTime?: number;
    calendarTime?: number;
    cycles?: number;
}
export namespace MaintenanceScheduleTriggerCommand {
    export type Type = 'UPCOMING' | 'DUE' | 'OVERDUE';
    export const Type = {
        UPCOMING: 'UPCOMING' as Type,
        DUE: 'DUE' as Type,
        OVERDUE: 'OVERDUE' as Type
    };
    export type Action = 'NOTIFY' | 'REQUEST_MAINTENANCE' | 'TAKE_OUT_OF_SERVICE';
    export const Action = {
        NOTIFY: 'NOTIFY' as Action,
        REQUEST_MAINTENANCE: 'REQUEST_MAINTENANCE' as Action,
        TAKE_OUT_OF_SERVICE: 'TAKE_OUT_OF_SERVICE' as Action
    };
}


