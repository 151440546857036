import { Directive, TemplateRef } from '@angular/core';

/**
 * This directive indicates the template to use when the field is unlocked
 */
@Directive({
    selector: '[unlockedMode]'
})
export class UnlockedModeDirective {
    constructor(public tpl: TemplateRef<any>) {}
}
