<ng-container screenAnalytics="mission-approval-v2">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-approvals.png" />
        </div>
        <h3 class="modal-title">Mission Approval</h3>
        <h5
            class="modal-title"
            tooltip="This number uniquely identifies this mission within FlyFreely, and should be used when communicating with the Chief Pilot"
            placement="bottom"
        >
            UID: {{ mission?.uid }}
        </h5>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-content-full-width">
            <summary-header
                [mission]="mission"
                [organisation]="organisation"
                [status]="status"
                [approval]="approval"
                [missionApproval]="missionApproval"
            >
            </summary-header>
        </div>
        <div class="row">
            <div class="col-lg-5 top-buffer">
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Organisation Name:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        {{ mission?.organisationName }}
                    </div>
                </div>

                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Mission Name:
                    </div>
                    <div class="col-xs-12 col-sm-8">{{ mission?.name }}</div>
                </div>
                <div
                    class="row top-buffer"
                    *ngIf="
                        mission != null && mission.missionObjectiveType != null
                    "
                >
                    <div class="col-xs-12 col-sm-3 display-label">
                        Objective:
                    </div>
                    <div class="col-xs-12 col-sm-9 pre-wrap">
                        {{ mission?.missionObjectiveType?.name }}
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Mission Objective:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        {{ mission?.missionObjective }}
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">Date:</div>
                    <div class="col-xs-12 col-sm-8">
                        {{ missionDate | formatDateTime }}
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Operation Type:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        {{ mission?.missionType.name }}
                    </div>
                </div>

                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Remote Pilot In Command:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        <a
                            *ngIf="
                                missionDetails != null &&
                                missionDetails.rpic != null
                            "
                            (click)="showPilot(missionDetails.rpic)"
                        >
                            {{ missionDetails.rpic | formatPerson }} #{{
                                missionDetails.rpic.activeJurisdictions
                                    | formatJurisdictionAuthorityIdentifier
                                        : airspaceJurisdictionId
                            }}
                        </a>
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Additional Crew:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        <ng-container
                            *ngIf="
                                missionDetails != null &&
                                missionDetails.additionalCrew
                            "
                        >
                            <span
                                *ngFor="
                                    let crew of missionDetails.additionalCrew;
                                    let last = last
                                "
                            >
                                <a (click)="showPilot(crew.person)">
                                    {{ crew.person | formatPerson }} #{{
                                        crew.person.arn
                                    }}
                                </a>
                                ({{ crew.role.name }}) {{ !last ? ',' : '' }}
                            </span>
                        </ng-container>
                    </div>
                </div>

                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">RPA:</div>
                    <div class="col-xs-12 col-sm-8">
                        <ng-container *ngIf="mission != null">
                            <span
                                *ngFor="
                                    let craft of mission.crafts;
                                    let last = last
                                "
                            >
                                <a (click)="showCraft(craft)">
                                    {{ craft | formatRpa }}
                                </a>
                                {{ !last ? ', ' : '' }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="row top-buffer" *ngIf="canUseEquipment">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Equipment:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        <ng-container *ngIf="mission != null">
                            <span
                                *ngFor="
                                    let equipment of mission.equipment;
                                    let last = last
                                "
                            >
                                <a (click)="showEquipment(equipment)">
                                    {{ equipment.name }}
                                </a>
                                {{ !last ? ', ' : '' }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Location:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        {{ mission?.location?.name }}
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Planned Max Height:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        {{ mission?.maximumHeight }} ft (AGL)
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Crew Notes:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        {{ mission?.crewNotes }}
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Emergency Contacts:
                    </div>
                    <div class="col-xs-12 col-sm-8 pre-wrap">
                        {{ mission?.emergencyContacts }}
                    </div>
                </div>
                <div class="row top-buffer">
                    <div class="col-xs-12 col-sm-4 display-label">
                        Radio Frequencies:
                    </div>
                    <div class="col-xs-12 col-sm-8">
                        {{ mission?.radioFrequencies }}
                    </div>
                </div>
            </div>
            <div class="col-lg-7 top-buffer">
                <tabset *ngIf="mission != null">
                    <tab heading="Flight Area">
                        <flyfreely-map
                            *ngIf="locationFeatures != null"
                            #map
                            style="height: 300px"
                            [organisationId]="organisation.id"
                            [features]="locationFeatures"
                            [startTime]="missionStartTime"
                            [endTime]="missionEndTime"
                            [ruleset]="missionRuleset"
                            [showFeatureSelection]="false"
                            [requireCtrlToZoom]="true"
                            [mapSourceFilters]="mapSourceFilters"
                            [enabledLayerGroups]="enabledMapLayerGroups"
                            [mapData]="mapData$ | async"
                            [enableLayerGroupsByDefault]="['NOTAM']"
                            (fullscreenRequested)="onFullscreenRequested()"
                            (featuresLoaded)="map.zoomToAllFeatures(true)"
                        ></flyfreely-map>
                        <div
                            *ngIf="
                                locationFeatures == null &&
                                (mission.location == null ||
                                    mission.location.features.length == 0)
                            "
                        >
                            No flight area defined
                        </div>
                    </tab>
                    <tab heading="Attached Documents">
                        <ng-container *ngIf="status">
                            <attachment-list
                                [attachmentsHandler]="attachmentsHandler"
                                [allowUploads]="status.canAttachFiles"
                                [allowDelete]="false"
                                [allowEdit]="status.canAttachFiles"
                                [parentPromiseTracker]="workTracker"
                                permissions
                            >
                            </attachment-list>
                        </ng-container>
                    </tab>
                    <tab
                        heading="Flights and Completion Details"
                        *ngIf="isCompleted || mission.status === 'FLYING'"
                    >
                        <h4 *ngIf="isCompleted">Mission Completion Details</h4>
                        <div class="row top-buffer" *ngIf="isCompleted">
                            <div class="col-xs-12 col-sm-2 display-label">
                                Actual Location:
                            </div>
                            <div class="col-xs-12 col-sm-2">
                                {{ actualLocation?.name }}
                            </div>
                            <div class="col-xs-12 col-sm-2 display-label">
                                Actual Date/Time:
                            </div>
                            <div class="col-xs-12 col-sm-2">
                                {{ mission.actualStartTime | formatDateTime }}
                            </div>
                            <div class="col-xs-12 col-sm-2 display-label">
                                Objective Outcome:
                            </div>
                            <div class="col-xs-12 col-sm-2">
                                {{ mission.outcome }}
                            </div>
                        </div>
                        <div class="row top-buffer" *ngIf="isCompleted">
                            <div class="col-xs-12 col-sm-2 display-label">
                                Mission Journal:
                            </div>
                            <div class="col-xs-12 col-sm-10">
                                {{ mission.completionMessage }}
                            </div>
                        </div>

                        <h4>Flights</h4>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Duration</th>
                                    <th>Flight Status</th>
                                    <th>Reason</th>
                                    <th>Remote Pilot</th>
                                    <th>RPA</th>
                                    <th>Battery Set</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let sortie of mission.sorties">
                                    <td>{{ sortie.number }}</td>
                                    <td>
                                        {{
                                            sortie.duration | formatDuration: ''
                                        }}
                                        <span *ngIf="sortie.startTime">
                                            <em
                                                >{{
                                                    sortie
                                                        | calculateSortieDuration
                                                        | formatDuration: ''
                                                }}
                                                ({{
                                                    sortie.startTime
                                                        | formatTime
                                                }}
                                                to
                                                {{
                                                    sortie.endTime | formatTime
                                                }})
                                            </em>
                                        </span>
                                    </td>
                                    <td>{{ sortie.status }}</td>
                                    <td>{{ sortie.reason }}</td>
                                    <td>
                                        {{
                                            personLookup[sortie.pilotId]
                                                | formatPerson
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            (craftLookup
                                                ? craftLookup[sortie.craftId]
                                                : null
                                            ) | formatRpa
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            (batterySetLookup
                                                ? batterySetLookup[
                                                      sortie.batterySetId
                                                  ]
                                                : null
                                            ) | formatBatterySet
                                        }}
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            class="btn btn-tertiary"
                                            [disabled]="working"
                                            (click)="
                                                showSortieDocumentation(sortie)
                                            "
                                        >
                                            <span
                                                class="fal fa-paperclip"
                                            ></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tab>
                    <tab
                        heading="Incident Reports"
                        *ngIf="
                            mission.status === 'COMPLETED' ||
                            mission.status === 'FINALISED'
                        "
                    >
                        <incident-report-list
                            class="top-buffer container-padding"
                            [missionId]="mission.id"
                            [timeZone]="mission.timeZone"
                            [organisationId]="mission.organisationId"
                            [canAdd]="true"
                        >
                        </incident-report-list>
                    </tab>
                </tabset>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 col-md-5 col-xl-2 top-buffer">
                <div class="horizontal-container vertical-align">
                    <h4 class="right-buffer">Approval Checklist</h4>
                    <field-validation-indicator [valid]="isMissionApprovable">
                    </field-validation-indicator>
                </div>
                <enhanced-help
                    screenName="mission-approval-v2-dialogue"
                    componentName="approvalChecklist"
                    [compact]="true"
                >
                </enhanced-help>
                <table>
                    <tbody>
                        <ng-container
                            *ngIf="
                                approval &&
                                approval.mappedApproverChecks &&
                                approval.mappedApproverChecks['pre-submit']
                            "
                        >
                            <tr
                                *ngFor="
                                    let check of approval.mappedApproverChecks[
                                        'pre-submit'
                                    ]
                                "
                            >
                                <td>
                                    <div class="checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [(checklist)]="
                                                    approval.completedChecks
                                                "
                                                [checklistValue]="check.id"
                                                (checklistChange)="
                                                    markApprovalChecklistChanged()
                                                "
                                                [disabled]="status.readOnly"
                                            />
                                            <span class="cr">
                                                <i
                                                    class="cr-icon glyphicon glyphicon-ok"
                                                ></i>
                                            </span>
                                            {{ check.check }}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-5 col-md-7 col-xl-3 top-buffer">
                <h4>Documentation</h4>
                <enhanced-help
                    screenName="mission-approval-v2-dialogue"
                    componentName="documentation"
                    [compact]="true"
                >
                </enhanced-help>
                <ng-container *ngIf="documentation">
                    <documentation-list
                        [organisationId]="mission.organisationId"
                        [formResponses]="documentation.formResponses"
                        [requiredDocumentation]="
                            documentation.requiredDocumentation
                        "
                        [steps]="missionDocumentationSteps"
                        [title]="'Mission'"
                        [requiringEntities]="requiringEntities"
                        [documentationActions]="documentationActions"
                        [missionApprovalList]="mission?.approvals"
                    ></documentation-list>
                </ng-container>
            </div>
            <div class="clearfix hidden-xl"></div>

            <div>
                <form [formGroup]="form" [formIdentifier]="validationFields">
                    <div class="col-xl-7 top-buffer">
                        <!-- Hiding and quickly cycling the tabs being rendered whenever booleans driving ngIf for the tabs change preserves the order -->
                        <tabset *ngIf="showApprovalTabs">
                            <!-- Automated approvals is only available to CRPs -->
                            <tab *ngIf="canDoAirspaceCheck">
                                <ng-template tabHeading>
                                    <span
                                        >Airspace Check
                                        <action-required
                                            *ngIf="
                                                airspaceApprovalState ===
                                                'AUTO_APPROVAL'
                                            "
                                            class="left-buffer"
                                        >
                                            Mission has a flight area in
                                            airspace that is eligible for an
                                            Automated Authorisation by CASA
                                        </action-required>
                                    </span>
                                </ng-template>
                                <div
                                    class="horizontal-container fill"
                                    style="max-height: 264px; height: 264px"
                                >
                                    <div class="fcol-8">
                                        <airspace-authorisation-status
                                            [enabledAuthorisationTypes]="
                                                enabledAuthorisationTypes
                                            "
                                            [areAuthorisationRequirementsComplete]="
                                                true
                                            "
                                            [jurisdiction]="
                                                mission.location
                                                    ?.airspaceJurisdiction
                                            "
                                            [userCanViewAirspaceAuthorisation]="
                                                true
                                            "
                                            [userCanRequestAirspaceAuthorisation]="
                                                true
                                            "
                                            [isAdditionalAuthorityApproval]="
                                                !isPrimaryWorkflowApproval
                                            "
                                            (showAirspaceAuthorisation)="
                                                showAirspaceAuthorisation()
                                            "
                                            (requestNewAirspaceAuthorisation)="
                                                requestNewAirspaceAuthorisation()
                                            "
                                            (cancelAirspaceAuthorisation)="
                                                cancelAirspaceAuthorisation(
                                                    $event
                                                )
                                            "
                                        ></airspace-authorisation-status>
                                    </div>
                                    <div
                                        class="fcol-4"
                                        style="max-height: 100%"
                                    >
                                        <airspace-check
                                            style="max-height: 100%"
                                        ></airspace-check>
                                    </div>
                                </div>
                            </tab>
                            <tab heading="Approval Details" *ngIf="true">
                                <div class="row">
                                    <div class="col-xs-12 col-md-5">
                                        <h4>Message to Remote Pilot</h4>
                                        <enhanced-help
                                            screenName="mission-approval-v2-dialogue"
                                            componentName="messageToRemotePilot"
                                            [compact]="true"
                                        >
                                        </enhanced-help>
                                        <ng-container *ngIf="approval">
                                            <textarea
                                                class="form-control textarea-lock-width"
                                                style="
                                                    width: 100%;
                                                    height: 200px;
                                                "
                                                formControlName="messageFromApprover"
                                                placeholder="Your mission..."
                                                [disableControl]="
                                                    status.readOnly
                                                "
                                            >
                                            </textarea>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="form-group top-buffer">
                                            <enhanced-help
                                                screenName="mission-approval-v2-dialogue"
                                                componentName="maxHeight"
                                                [compact]="true"
                                            >
                                            </enhanced-help>
                                            <label>Approved Max Height</label>
                                            <div class="input-group">
                                                <input
                                                    type="number"
                                                    formControlName="maximumHeight"
                                                    placeholder="The maximum flight height above ground level that is approved of the mission"
                                                    min="0"
                                                    step="1"
                                                    class="form-control"
                                                    [disableControl]="
                                                        status.readOnly
                                                    "
                                                />
                                                <span class="input-group-addon">
                                                    ft AGL
                                                </span>
                                            </div>
                                        </div>
                                        <table
                                            class="table"
                                            #approvalConditionsTable
                                        >
                                            <thead>
                                                <th *ngIf="!status.readOnly">
                                                    <button
                                                        class="btn-circle btn-link"
                                                        *ngIf="!status.readOnly"
                                                        (click)="addCondition()"
                                                    >
                                                        <span
                                                            class="fa fa-plus"
                                                        ></span>
                                                    </button>
                                                </th>
                                                <th>
                                                    <h4 class="align-heading">
                                                        <span>Conditions</span>
                                                        <button
                                                            class="btn btn-xs btn-default spacing"
                                                            (click)="
                                                                addConditionsFromLibrary()
                                                            "
                                                            *ngIf="
                                                                !status.readOnly &&
                                                                canUseApprovalConditionsLibrary
                                                            "
                                                        >
                                                            Add Condition From
                                                            Library
                                                        </button>
                                                    </h4>
                                                    <enhanced-help
                                                        screenName="mission-approval-v2-dialogue"
                                                        componentName="approvalConditions"
                                                        [compact]="true"
                                                    >
                                                    </enhanced-help>
                                                </th>
                                                <th></th>
                                            </thead>
                                            <tbody
                                                *ngIf="
                                                    !status.readOnly && approval
                                                "
                                                formArrayName="conditions"
                                                cdkDropList
                                                (cdkDropListDropped)="
                                                    drop(
                                                        $event,
                                                        formConditions.controls
                                                    )
                                                "
                                            >
                                                <tr
                                                    *ngFor="
                                                        let condition of formConditions.controls;
                                                        let i = index
                                                    "
                                                    cdkDrag
                                                >
                                                    <td class="text-right">
                                                        <span
                                                            class="fas fa-grip-vertical row-hover grab-handle"
                                                            cdkDragHandle
                                                        ></span>
                                                    </td>
                                                    <td class="col-md-11">
                                                        <textarea
                                                            [formControlName]="
                                                                i
                                                            "
                                                            class="form-control textarea-lock-width"
                                                            rows="2"
                                                        ></textarea>
                                                    </td>
                                                    <td>
                                                        <button
                                                            class="btn btn-tertiary btn-delete"
                                                            (click)="
                                                                deleteCondition(
                                                                    i
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                class="fal fa-trash-alt"
                                                            ></span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <p
                                                    class="align-heading"
                                                    *ngIf="
                                                        approval &&
                                                        approval.conditions
                                                            .length === 0 &&
                                                        status.readOnly
                                                    "
                                                >
                                                    No conditions were applied
                                                </p>
                                            </tfoot>
                                        </table>

                                        <ol *ngIf="status.readOnly">
                                            <li
                                                *ngFor="
                                                    let condition of approval.conditions
                                                "
                                            >
                                                {{ condition }}
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </tab>
                            <tab
                                heading="Internal Approval Details"
                                *ngIf="true"
                            >
                                <h4>Internal Approval Notes</h4>
                                <ng-container *ngIf="approval">
                                    <textarea
                                        class="form-control textarea-lock-width"
                                        formControlName="approversNotes"
                                        [disabled]="status.readOnly"
                                        rows="5"
                                    >
                                    </textarea>
                                </ng-container>
                            </tab>
                        </tabset>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div
            class="btn-group"
            *ngIf="printOptions.length > 0"
            dropdown
            [dropup]="true"
        >
            <button
                class="btn btn-default dropdown-toggle"
                dropdownToggle
                type="button"
                aria-controls="dropdown-container"
                [disabled]="working"
            >
                Reports
                <span class="caret"></span>
            </button>
            <ul
                class="dropdown-menu dropdown-menu-right modal-dropdown"
                *dropdownMenu
                role="menu"
            >
                <li *ngFor="let option of printOptions" role="menuitem">
                    <a (click)="option.print()">{{ option.name }}</a>
                </li>
            </ul>
        </div>

        <button
            class="btn btn-default"
            type="button"
            (click)="save()"
            *ngIf="!status.readOnly"
            [disabled]="working"
        >
            Save
        </button>

        <button
            class="btn btn-default"
            type="button"
            (click)="saveAndClose()"
            *ngIf="!status.readOnly"
            [disabled]="working"
        >
            Save and Close
        </button>

        <button
            class="btn btn-primary"
            type="button"
            *ngIf="status.isBeingReviewed"
            (click)="stopProgress()"
            [disabled]="working"
        >
            Unlock Request
        </button>

        <button
            class="btn btn-primary"
            type="button"
            *ngIf="status.isPending"
            (click)="startProgress()"
            [disabled]="working"
        >
            Lock Request
        </button>
        <!-- TODO: create the action here -->
        <!-- <button
            class="btn btn-primary"
            type="button"
        >
            Minor Issue Found
        </button> -->

        <button
            class="btn btn-primary"
            type="button"
            *ngIf="!status.readOnly"
            (click)="reject()"
            [disabled]="working"
        >
            Reject
        </button>

        <button
            class="btn btn-primary"
            type="button"
            (click)="approve()"
            *ngIf="!status.readOnly"
            [disabled]="!isMissionApprovable || working"
        >
            Approve
        </button>

        <button
            class="btn btn-primary"
            type="button"
            (click)="revoke()"
            *ngIf="isMissionApprovalRevokeable"
            [disabled]="working"
        >
            Revoke Approval
        </button>
    </div>
</ng-container>
