import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    DownloadService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameValue,
    PersonRolesDto,
    PersonService,
    PersonnelReportService,
    PrintOption,
    SubscriptionLimitDto,
    hasFeatureFlag,
    FEATURE_WORKGROUPS,
    PersonsOrganisationDto,
    findOrganisation,
    UserService,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { isPersonalOrganisationSubscription } from 'libs/subscriptions/src/lib/helpers';
import { OrganisationSubscriptionsState } from 'libs/subscriptions/src/lib/interfaces';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse } from 'fp-ts/es6/Option';
import { WorkgroupsDataService } from '@flyfreely-portal-ui/organisation-admin';
import { WorkGroup } from 'libs/flyfreely/src/lib/services/workgroups.service';

@Component({
    selector: 'personnel-details',
    templateUrl: './personnel-details.component.html'
})
export class PersonnelDetails implements OnInit, OnChanges {
    @Input() person: PersonRolesDto;
    @Input() managingOrganisationId: number;
    @Input() canEdit: boolean;
    @Input() licences: OrganisationSubscriptionsState;
    @Output() edit = new EventEmitter<void>();

    roles: string;
    workgroups: string;
    userRoles: { [role: string]: string };
    assignedLicences: string;
    organisation: PersonsOrganisationDto;
    canUseWorkgroups = false;

    jurisdictionIdentifiers: NameValue[] = [];

    printOptions: PrintOption[] = [];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private personService: PersonService,
        private personnelReportService: PersonnelReportService,
        private downloadService: DownloadService,
        private logging: FlyFreelyLoggingService,
        private userService: UserService,
        private workgroupsDataService: WorkgroupsDataService
    ) {
        this.userRoles = this.personService.getPersonRoles();
    }

    ngOnInit() {
        this.personService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshPerson());

        this.parseLicences();

        this.findJurisdictionIdenitifiers();

        const maybeOrganisation = findOrganisation(
            this.userService.findUsersOrganisations(),
            this.managingOrganisationId
        );

        this.organisation = pipe(
            maybeOrganisation,
            getOrElse(() => undefined)
        );

        this.canUseWorkgroups = hasFeatureFlag(
            this.organisation,
            FEATURE_WORKGROUPS
        );

        this.workgroupsDataService.workgroup$
            .pipe(
                map(workgroups =>
                    workgroups.reduce(
                        toLookup,
                        {} as { [workgoupId: number]: WorkGroup }
                    )
                ),
                take(1),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(
                workgroups =>
                    (this.workgroups = this.person.workgroupIdList
                        .map(workgroupId => workgroups[workgroupId]?.name)
                        .join(', '))
            );
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('person' in changes || 'managingOrganisationId' in changes) {
            if (
                this.person != null &&
                this.person.roles != null &&
                this.person.roles.length > 0
            ) {
                this.refreshRoles();
                this.refreshAvailableReports();
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshPerson() {
        this.personService
            .findPerson(this.person.id, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: person => {
                    this.person = person;
                    this.findJurisdictionIdenitifiers();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching person details: ${error.message}`
                    )
            });
    }

    refreshRoles() {
        this.roles =
            this.person != null && this.person.roles != null
                ? this.person.roles
                      .map(role => this.userRoles[role])
                      .join(', ')
                : null;
    }

    parseLicences() {
        if (this.person.hasLicence) {
            this.assignedLicences = 'Yes';
            return;
        }
        if (isPersonalOrganisationSubscription(this.licences)) {
            this.assignedLicences = 'Not Applicable';
            return;
        }
        const limits = this.licences.subscription?.limits;
        const personnel = limits?.filter(
            l =>
                l.licencedEntityType ===
                SubscriptionLimitDto.LicencedEntityType.PERSONNEL
        );
        const pilots = limits?.filter(
            l =>
                l.licencedEntityType ===
                SubscriptionLimitDto.LicencedEntityType.PILOT
        );
        if (
            limits == null ||
            limits.length === 0 ||
            // A person with no roles is an archived person.
            this.person.roles == null ||
            (personnel.length === 0 &&
                pilots.length > 0 &&
                this.person.roles.filter(
                    r =>
                        r === PersonRolesDto.Roles.PILOT ||
                        r === PersonRolesDto.Roles.PILOT_CREW_PLANNER ||
                        r === PersonRolesDto.Roles.PILOT_PLANNER ||
                        r === PersonRolesDto.Roles.PILOT_SUBMITTER
                ).length === 0)
        ) {
            this.assignedLicences = 'Not Applicable';
            return;
        }
        this.assignedLicences = 'No';
        return;
    }

    findJurisdictionIdenitifiers() {
        if (
            this.person == null ||
            this.person.activeJurisdictions == null ||
            this.person.activeJurisdictions.length === 0
        ) {
            this.jurisdictionIdentifiers = [];
            return;
        }
        const authorityIdentifiers = this.person.activeJurisdictions.filter(
            a => a.enabled && a.identifier != null
        );
        this.jurisdictionIdentifiers = authorityIdentifiers.map(i => ({
            name:
                i.identifier.authorityType?.identifierLabel != null
                    ? `${
                          i.identifier.authorityType.identifierLabel.endsWith(
                              ':'
                          )
                              ? i.identifier.authorityType.identifierLabel.slice(
                                    0,
                                    -1
                                )
                              : i.identifier.authorityType.identifierLabel
                      } (${i.name}):`
                    : `${i.name} Jurisdiction Identifier:`,
            value: i.identifier.identifier
        }));
    }

    onEdit() {
        this.edit.emit();
    }

    private refreshAvailableReports() {
        if (this.person == null) {
            this.printOptions = null;
            return;
        }

        this.personnelReportService
            .findAvailableTemplates(
                'PersonReport',
                this.person.id,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(reports => {
                this.printOptions = reports.map(r => ({
                    description: r.description ? r.description : '',
                    name: r.name,
                    print: () =>
                        this.downloadService.downloadFromUrl(
                            this.personnelReportService.getPdfReportUrl(
                                r.reportTemplateId,
                                this.person.id,
                                this.managingOrganisationId
                            )
                        )
                }));
            });
    }
}
