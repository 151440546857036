<div class="modal-body max-height">
    <div class="contents">
        <div
            class="horizontal-container container-fluid"
            [formGroup]="editGeneralTaskForm"
        >
            <div class="fcol-6">
                <div class="task-details row form-group top-buffer">
                    <label>Task Name</label>
                    <field-validation-indicator
                        class="pull-right"
                        [control]="editGeneralTaskForm.controls['summary']"
                        [noun]="'field'"
                        [placement]="'left'"
                    ></field-validation-indicator>
                    <input
                        class="form-control"
                        formControlName="summary"
                        placeholder="Type a name for this Task"
                    />
                </div>
                <enhanced-help
                    screenName="task-edit"
                    componentName="taskName"
                    [compact]="true"
                >
                </enhanced-help>
                <div class="task-details row form-group top-buffer">
                    <label>Details</label>
                    <field-validation-indicator
                        class="pull-right"
                        [control]="editGeneralTaskForm.controls['description']"
                        [noun]="'field'"
                        [placement]="'left'"
                    ></field-validation-indicator>
                    <textarea
                        class="form-control"
                        formControlName="description"
                    ></textarea>
                </div>
                <div class="task-details row form-group top-buffer">
                    <label class="control-label"> Links & Attachments </label>
                    <div class="horizontal-container top-buffer">
                        <div class="fcol-6">
                            <app-link-manager
                                *ngIf="generalTask"
                                [sectionId]="generalTask.id"
                                [linkAction]="LinkAction.generalTask"
                            >
                            </app-link-manager>
                        </div>
                        <div class="fcol-1"></div>
                        <div class="fcol-5 d-flex align-center">
                            <attachment-list
                                [attachmentsHandler]="attachmentsHandler"
                                [allowUploads]="true"
                                [newTemplate]="true"
                                [allowDelete]="true"
                                [allowEdit]="true"
                            >
                            </attachment-list>
                        </div>
                    </div>
                </div>
                <div class="row form-group input-content">
                    <div>
                        <label class="control-label"> Comments </label>
                    </div>
                    <div style="margin-bottom: 30px; margin-top: 10px">
                        <ui-comments
                            *ngIf="organisation && generalTask"
                            [comments]="comments"
                            [organisationId]="organisation.id"
                            (createComment)="onCreateComment($event)"
                            (deleteComment)="onDeleteComment($event)"
                            (updateComment)="onUpdateComment($event)"
                        >
                        </ui-comments>
                    </div>
                </div>
                <enhanced-help
                    screenName="task-edit"
                    componentName="taskDetails"
                    [compact]="true"
                >
                </enhanced-help>
            </div>
            <div class="fcol-2"></div>
            <div class="fcol-4">
                <div class="row form-group top-buffer">
                    <label class="display-label">Assignee</label>
                    <field-validation-indicator
                        class="pull-right"
                        [control]="editGeneralTaskForm.controls['assignee']"
                        [noun]="'field'"
                        [placement]="'left'"
                    ></field-validation-indicator>
                    <ng-select
                        [items]="personList$ | async"
                        bindValue="id"
                        formControlName="assignee"
                        [searchFn]="personSearch"
                    >
                        <ng-template ng-option-tmp let-item="item">
                            <person-avatar
                                [person]="item"
                                [managingOrganisationId]="organisation.id"
                                [width]="25"
                                [height]="25"
                            >
                            </person-avatar>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <person-avatar
                                [person]="item"
                                [managingOrganisationId]="organisation.id"
                                [width]="20"
                                [height]="20"
                            >
                            </person-avatar>
                        </ng-template>
                    </ng-select>
                </div>
                <enhanced-help
                    screenName="task-edit"
                    componentName="taskAssignee"
                    [compact]="true"
                >
                </enhanced-help>
                <div class="row form-group top-buffer">
                    <label class="display-label">Priority</label>
                    <field-validation-indicator
                        class="pull-right"
                        [control]="editGeneralTaskForm.controls['priority']"
                        [noun]="'field'"
                        [placement]="'left'"
                    ></field-validation-indicator>
                    <ng-select
                        [items]="priorityOptions"
                        bindValue="value"
                        formControlName="priority"
                        [clearable]="false"
                    ></ng-select>
                </div>
                <enhanced-help
                    screenName="task-edit"
                    componentName="taskPriority"
                    [compact]="true"
                >
                </enhanced-help>
                <div class="row form-group top-buffer">
                    <label class="display-label">Status</label>
                    <field-validation-indicator
                        class="pull-right"
                        [control]="editGeneralTaskForm.controls['status']"
                        [noun]="'field'"
                        [placement]="'left'"
                    ></field-validation-indicator>
                    <ng-select
                        [items]="statusOptions"
                        bindValue="value"
                        formControlName="status"
                        [clearable]="false"
                    ></ng-select>
                </div>
                <enhanced-help
                    screenName="task-edit"
                    componentName="taskStatus"
                    [compact]="true"
                >
                </enhanced-help>
                <div class="row form-group top-buffer">
                    <label class="display-label">Due Date</label>
                    <field-validation-indicator
                        class="pull-right"
                        [control]="editGeneralTaskForm.controls['dueDate']"
                        [noun]="'field'"
                        [placement]="'left'"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            class="form-control"
                            #datepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="dueDate"
                        />
                        <span
                            class="input-group-addon btn-default"
                            (click)="datepicker.toggle()"
                            [attr.aria-expanded]="datepicker.isOpen"
                        >
                            <i
                                class="glyphicon glyphicon-calendar"
                                style="cursor: pointer"
                            ></i>
                        </span>
                    </div>
                </div>
                <enhanced-help
                    screenName="task-edit"
                    componentName="taskDueDate"
                    [compact]="true"
                >
                </enhanced-help>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-default pull-left"
        (click)="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary pull-right"
        type="submit"
        [disabled]="working"
        (click)="onSave()"
    >
        Save
    </button>
</div>
<ngx-loading [show]="working"></ngx-loading>
