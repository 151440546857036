<form
    [formGroup]="equipmentNotesForm"
    novalidate
    class="container-with-footer"
    (ngSubmit)="saveNotes()"
>
    <div class="container-fluid">
        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Notes</label>
                <field-validation-indicator
                    validatorFormControlName="notes"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <textarea class="form-control" formControlName="notes" style="min-height: calc(100vh - 350px);"></textarea>
            </div>
        </div>
    </div>

    <div class="action-container">
        <button
            type="button"
            class="btn btn-default pull-left"
            style="margin-right: 0.5rem"
            (click)="cancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary pull-right"
            type="submit"
            [disabled]="equipmentNotesForm.invalid || working"
        >
            Save
        </button>
    </div>
</form>
