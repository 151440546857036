<div class="modal-header no-icon">
    <h3 class="modal-title">Form</h3>
    <button
        type="button"
        (click)="modal.hide()"
        aria-label="Close"
        class="close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form #formGroup (ngSubmit)="save()" novalidate>
    <div class="modal-body">
        <div class="modal-body-content">
            <div class="row top-buffer">
                <div class="col-xs-12 col-sm-4 form-group">
                    <inline-edits
                        [labelName]="'Name'"
                        [model]="form.name"
                        [isNewForm]="isNewForm()"
                        [canEdit]="canEdit()"
                        (change)="onChangeFormName($event)"
                        (save)="renameForm($event)"
                    ></inline-edits>
                </div>

                <div class="col-xs-4 form-group">
                    <label>Version</label>

                    <div class="input-group">
                        <select
                            class="form-control"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="selectedVersionId"
                            (ngModelChange)="changeSelectedVersion($event)"
                        >
                            <option
                                *ngFor="let version of form.versions"
                                [ngValue]="version.id"
                            >
                                Version {{ version.versionNumber }}
                                {{
                                    form.activeVersionId != null &&
                                    form.activeVersionId == version.id
                                        ? '(Active)'
                                        : ''
                                }}
                            </option>
                            <option [ngValue]="null" *ngIf="canEdit()">
                                New Version
                            </option>
                        </select>

                        <span class="input-group-btn">
                            <button
                                type="button"
                                class="btn-tertiary btn-sm"
                                (click)="cloneVersion()"
                                *ngIf="canEdit()"
                                [disabled]="working || !selectedVersionId"
                                tooltip="Clone this version"
                                placement="top"
                            >
                                <i class="fa-clone fal"></i>
                            </button>

                            <button
                                type="button"
                                class="btn-tertiary btn-sm"
                                (click)="setAsActiveVersion()"
                                *ngIf="canEdit()"
                                [disabled]="working || !selectedVersionId"
                                tooltip="Set this as current version"
                                placement="top"
                            >
                                <span
                                    class="fal fa-star"
                                    *ngIf="
                                        selectedVersionId !==
                                        form.activeVersionId
                                    "
                                >
                                </span>
                                <span
                                    class="fa fa-star"
                                    *ngIf="
                                        selectedVersionId ===
                                        form.activeVersionId
                                    "
                                ></span>
                            </button>
                        </span>
                    </div>
                    <label for="file">
                        <a>Import Form</a>
                    </label>
                    <input
                        type="file"
                        id="file"
                        (change)="importFormVersion($event)"
                        style="
                            width: 0;
                            height: 0;
                            opacity: 0;
                            overflow: hidden;
                            position: absolute;
                            z-index: -1;
                        "
                    />
                </div>

                <div class="col-xs-2 col-sm-3 text-right">
                    <button
                        *ngIf="canEditVersion()"
                        type="button"
                        #pop="bs-popover"
                        class="btn btn-sm btn-primary"
                        [popover]="myPopoverTemplate"
                        [outsideClick]="true"
                        [disabled]="working"
                        (click)="openPopover(pop)"
                        container="body"
                        popoverTitle="Sections"
                        placement="left"
                    >
                        <span
                            class="fas fa-th-list"
                            tooltip="Here you can delete sections and re-order them"
                            placement="top"
                        >
                        </span>
                        Arrange Sections
                    </button>
                </div>
            </div>

            <div class="row top-buffer">
                <div class="col-sm-12 col-md-12 form-group">
                    <label>Form Description</label>
                    <textarea
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        [(ngModel)]="currentVersion.description"
                        (ngModelChange)="formChanged()"
                        [disabled]="!canEditVersion()"
                        required
                    >
                    </textarea>
                </div>
            </div>

            <div class="row top-buffer">
                <div class="col-xs-12">
                    <accordion
                        *ngIf="form.id"
                        [closeOthers]="true"
                        [isAnimated]="true"
                    >
                        <accordion-group
                            (isOpenChange)="
                                $event ? (opened = true) : (opened = false)
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-link btn-block clearfix"
                                accordion-heading
                            >
                                <div class="pull-left float-left">
                                    Form Gallery
                                </div>
                                <div class="pull-right">
                                    <span
                                        class="pull-right glyphicon"
                                        [ngClass]="{
                                            'glyphicon-chevron-down': opened,
                                            'glyphicon-chevron-right': !opened
                                        }"
                                    ></span>
                                </div>
                            </button>
                            <attachment-list
                                [attachmentsHandler]="attachmentsHandler"
                                [allowUploads]="form.availableActions.canEdit"
                                [allowDelete]="form.availableActions.canEdit"
                                permissions
                            >
                            </attachment-list>
                        </accordion-group>
                    </accordion>
                </div>
            </div>

            <ng-container
                *ngFor="
                    let section of currentVersion.sections;
                    let index = index
                "
            >
                <hr />
                <div class="row">
                    <div class="col-sm-12 col-md-5 form-group">
                        <label>Section Name</label>
                        <input
                            class="form-control"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="section.name"
                            (ngModelChange)="formChanged()"
                            [disabled]="!canEditVersion()"
                            required
                        />
                    </div>
                    <div class="col-sm-12 col-md-7 form-group">
                        <label>Description</label>
                        <textarea
                            class="form-control"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="section.description"
                            (ngModelChange)="formChanged()"
                            [disabled]="!canEditVersion()"
                        >
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-2">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    [ngModelOptions]="{ standalone: true }"
                                    [(ngModel)]="section.repeatingGroup"
                                    (ngModelChange)="formChanged()"
                                    [disabled]="!canEditVersion()"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i>
                                </span>
                                Repeating elements
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <label>Layout</label>
                        <select
                            class="form-control"
                            *ngIf="section.repeatingGroup"
                            [(ngModel)]="section.layout"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="formChanged()"
                            [disabled]="!canEditVersion()"
                        >
                            <option
                                *ngFor="let layout of repeatingLayoutOptions"
                                [ngValue]="layout.value"
                            >
                                {{ layout.name }}
                            </option>
                        </select>
                        <select
                            class="form-control"
                            *ngIf="!section.repeatingGroup"
                            [(ngModel)]="section.layout"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="formChanged()"
                            [disabled]="!canEditVersion()"
                        >
                            <option
                                *ngFor="let layout of nonRepeatingLayoutOptions"
                                [ngValue]="layout.value"
                            >
                                {{ layout.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <span *ngIf="section.repeatingGroup">
                            <button
                                type="button"
                                class="btn btn-sm btn-tertiary"
                                (click)="editPrefill(section)"
                                [disabled]="!canEditVersion()"
                            >
                                <i class="fal fa-pencil-alt"></i>
                            </button>
                            <span>Edit Prefill</span>
                        </span>
                        <span *ngIf="canEditVersion()">
                            <button
                                type="button"
                                class="btn btn-sm btn-tertiary"
                                (click)="cloneSection(section)"
                                [disabled]="working"
                            >
                                <i class="fa-clone fal"></i>
                            </button>
                            <span>Clone Section</span>
                        </span>
                    </div>
                </div>

                <div class="row top-buffer">
                    <div class="col-xs-12">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Label</th>
                                    <th>Placeholder</th>
                                    <th>Required</th>
                                    <th>Type</th>
                                    <th
                                        *ngIf="
                                            section.layout == 'TWELVE_COLUMN'
                                        "
                                        style="width: 150px"
                                    >
                                        Group with Previous
                                    </th>
                                    <th
                                        *ngIf="
                                            section.layout == 'TWELVE_COLUMN'
                                        "
                                        style="width: 150px"
                                    >
                                        Column Span
                                    </th>
                                    <th>Conditional</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody cdkDropList [cdkDropListData]="section.controls" (cdkDropListDropped)="dropControl($event, section)">
                                <tr *ngFor="let control of section.controls; let controlIx = index" cdkDrag>
                                    <td class="text-right">
                                        <span
                                            class="fas fa-grip-vertical row-hover grab-handle" cdkDragHandle
                                        ></span>
                                    </td>
                                    <td
                                        *ngIf="
                                            control.type != 'info' &&
                                            control.type != 'warning' &&
                                            control.type !=
                                                'warning-with-acknowledgement'
                                        "
                                    >
                                        <input
                                            class="form-control"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            [(ngModel)]="control.label"
                                            (ngModelChange)="formChanged()"
                                            [disabled]="!canEditVersion()"
                                        />
                                    </td>
                                    <td
                                        *ngIf="
                                            control.type != 'info' &&
                                            control.type != 'warning' &&
                                            control.type !=
                                                'warning-with-acknowledgement'
                                        "
                                    >
                                        <input
                                            class="form-control"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            [(ngModel)]="control.placeholder"
                                            (ngModelChange)="formChanged()"
                                            [disabled]="!canEditVersion()"
                                        />
                                    </td>
                                    <td
                                        *ngIf="
                                            control.type != 'info' &&
                                            control.type != 'warning' &&
                                            control.type !=
                                                'warning-with-acknowledgement'
                                        "
                                    >
                                        <div class="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    [(ngModel)]="
                                                        control.required
                                                    "
                                                    (ngModelChange)="
                                                        formChanged()
                                                    "
                                                    [disabled]="
                                                        !canEditVersion()
                                                    "
                                                />
                                                <span class="cr"
                                                    ><i
                                                        class="cr-icon glyphicon glyphicon-ok"
                                                    ></i>
                                                </span>
                                            </label>
                                        </div>
                                    </td>
                                    <td
                                        colspan="3"
                                        *ngIf="
                                            control.type == 'info' ||
                                            control.type == 'warning' ||
                                            control.type ==
                                                'warning-with-acknowledgement'
                                        "
                                        class="wordwrap"
                                    >
                                        {{ control.description }}
                                    </td>
                                    <td>
                                        {{ control.type }}
                                        <action-required
                                            *ngIf="
                                                control.type === 'image' &&
                                                !isImageValid(
                                                    control.config.attachmentId
                                                )
                                            "
                                            [nonCritical]="true"
                                        >
                                            The image specified in this control
                                            does not exist. Please edit to
                                            correct this.
                                        </action-required>
                                    </td>
                                    <td
                                        *ngIf="
                                            section.layout == 'TWELVE_COLUMN'
                                        "
                                    >
                                        <div class="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    [(ngModel)]="
                                                        control.groupWithPreviousControl
                                                    "
                                                    (ngModelChange)="
                                                        formChanged()
                                                    "
                                                    [disabled]="
                                                        !canEditVersion()
                                                    "
                                                />
                                                <span class="cr"
                                                    ><i
                                                        class="cr-icon glyphicon glyphicon-ok"
                                                    ></i>
                                                </span>
                                            </label>
                                        </div>
                                    </td>
                                    <td
                                        *ngIf="
                                            section.layout == 'TWELVE_COLUMN'
                                        "
                                    >
                                        <input
                                            *ngIf="!control.groupWithPreviousControl"
                                            class="form-control"
                                            type="number"
                                            min="0"
                                            max="12"
                                            placeholder="1 - 12"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            [(ngModel)]="control.columnSpan"
                                            (ngModelChange)="formChanged()"
                                            required
                                            [disabled]="!canEditVersion()"
                                        />
                                    </td>
                                    <td>
                                        {{
                                            control.displayCondition == null
                                                ? 'No'
                                                : 'Yes'
                                        }}
                                    </td>
                                    <td class="text-right">
                                        <button
                                            class="btn btn-tertiary"
                                            type="button"
                                            (click)="
                                                editControl(
                                                    section,
                                                    control,
                                                    controlIx
                                                )
                                            "
                                            *ngIf="canEditVersion()"
                                            tooltip="Edit element"
                                            placement="top"
                                        >
                                            <span
                                                class="fal fa-pencil-alt"
                                            ></span>
                                        </button>

                                        <button
                                            class="btn btn-tertiary btn-delete"
                                            type="button"
                                            (click)="
                                                deleteControl(
                                                    section.controls,
                                                    controlIx
                                                )
                                            "
                                            *ngIf="canEditVersion()"
                                            tooltip="Remove element"
                                            placement="top"
                                        >
                                            <span
                                                class="fal fa-trash-alt"
                                            ></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td colspan="6">
                                        <button
                                            class="btn btn-section-action"
                                            type="button"
                                            (click)="addControl(section)"
                                            *ngIf="canEditVersion()"
                                            tooltip="Add control to this form"
                                            placement="top"
                                        >
                                            <div class="circle">
                                                <span class="fa fa-plus"></span>
                                            </div>
                                            Add New Control
                                        </button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div class="row"></div>
            </ng-container>

            <div class="row top-buffer bottom-buffer">
                <div class="col-xs-12">
                    <button
                        class="btn btn-section-action"
                        type="button"
                        (click)="createSection()"
                        *ngIf="canEditVersion()"
                        [disabled]="working"
                    >
                        <div class="circle">
                            <span class="fa fa-plus"></span>
                        </div>
                        Add Form Section
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <loading-indicator class="pull-left" [isLoading]="working">
        </loading-indicator>
        <button
            class="btn pull-left"
            type="button"
            (click)="exportFormVersion()"
            [disabled]="working"
        >
            Export Form
        </button>
        <button
            class="btn"
            type="button"
            (click)="previewForm()"
            [disabled]="working"
        >
            Preview Form
        </button>
        <button
            class="btn"
            [disabled]="!canEditVersion() || working"
            *ngIf="canEdit()"
        >
            Save
        </button>
        <button
            class="btn btn-primary"
            type="button"
            (click)="saveAndClose()"
            [disabled]="!canEditVersion() || working"
            *ngIf="canEdit()"
        >
            Save and Close
        </button>
    </div>
</form>

<ng-template #myPopoverTemplate>
    <table cdkDropList [cdkDropListData]="currentVersion.sections" (cdkDropListDropped)="dropSection($event)">
        <tbody >
            <tr *ngFor="let section of currentVersion.sections; let i = index" cdkDrag >
                <td>
                    <span
                        class="fas fa-grip-vertical row-hover grab-handle" cdkDragHandle
                    ></span>
                    {{ section.name }}
                </td>
                <td class="text-right">
                    <button
                        class="btn btn-tertiary btn-delete"
                        type="button"
                        (click)="deleteSection(i)"
                        tooltip="Delete section"
                        placement="top"
                    >
                        <span class="fal fa-trash-alt"></span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="currentVersion.sections.length == 0" class="alert alert-info">
        No sections in this form
    </div>
</ng-template>
