/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Flight tests and their results
 */
export interface MaintenanceFlightTestDto { 
    id?: number;
    flightTestMissionId?: number;
    flightTestMissionObjective?: string;
    flightTestMissionDate?: string;
    flightTestMissionTimeZone?: string;
    flightTestMissionStatus?: MaintenanceFlightTestDto.FlightTestMissionStatus;
    flightTestPerformed?: string;
    flightTestRemarks?: string;
    furtherMaintenanceRequired?: boolean;
}
export namespace MaintenanceFlightTestDto {
    export type FlightTestMissionStatus = 'DRAFT' | 'READY_TO_FLY' | 'ACTIVATED' | 'PREPARED' | 'ON_SITE' | 'FLYING' | 'DONE_FLYING' | 'CANCELLED' | 'COMPLETED' | 'DELETED' | 'FINALISED' | 'AWAITING_SYNC';
    export const FlightTestMissionStatus = {
        DRAFT: 'DRAFT' as FlightTestMissionStatus,
        READY_TO_FLY: 'READY_TO_FLY' as FlightTestMissionStatus,
        ACTIVATED: 'ACTIVATED' as FlightTestMissionStatus,
        PREPARED: 'PREPARED' as FlightTestMissionStatus,
        ON_SITE: 'ON_SITE' as FlightTestMissionStatus,
        FLYING: 'FLYING' as FlightTestMissionStatus,
        DONE_FLYING: 'DONE_FLYING' as FlightTestMissionStatus,
        CANCELLED: 'CANCELLED' as FlightTestMissionStatus,
        COMPLETED: 'COMPLETED' as FlightTestMissionStatus,
        DELETED: 'DELETED' as FlightTestMissionStatus,
        FINALISED: 'FINALISED' as FlightTestMissionStatus,
        AWAITING_SYNC: 'AWAITING_SYNC' as FlightTestMissionStatus
    };
}


