import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
    EquipmentDto,
    OrganisationRelationshipDto,
    OrganisationRelationshipService,
    PersonsOrganisationDto,
    ShareResourceCommand,
    UnshareResourceCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { OrganisationRelationshipsUiService } from './organisation-relationships-ui.service';

@Component({
    selector: 'shared-equipment',
    template: `<h5 [ngClass]="{ readonly: working }">Shared Equipment</h5>
        <div class="input-group">
            <ng-select
                [items]="
                    organisationEquipmentList$
                        | async
                        | sortBy: 'name'
                        | filterOutById: relationship.sharedEquipmentList
                "
                bindLabel="name"
                bindValue="id"
                appendTo="body"
                [formControl]="addEquipment"
                placeholder="Select equipment to share with {{
                    relationship.childOrganisation.name
                }}"
                [readonly]="working"
                [clearable]="false"
            >
            </ng-select>
            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working || !addEquipment.valid"
                    (click)="shareEquipment()"
                >
                    Share
                </button>
            </span>
        </div>
        <div>
            <div
                *ngFor="let equipment of relationship.sharedEquipmentList"
                class="top-buffer"
            >
                {{ equipment | formatEquipment }}

                <button
                    type="button"
                    class="btn btn-tertiary btn-delete"
                    tooltip="Remove sharing of equipment"
                    placement="top"
                    (click)="unshareEquipment(equipment)"
                >
                    <span class="fal fa-trash-alt"></span>
                </button>

                <br />
            </div>
        </div>`
})
export class SharedEquipment implements OnInit, OnDestroy {
    @Input() organisation: PersonsOrganisationDto;
    @Input() relationship: OrganisationRelationshipDto;

    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    organisationEquipmentList$: Observable<EquipmentDto[]>;

    addEquipment = new FormControl(undefined, [Validators.required]);

    constructor(
        public workTracker: WorkTracker,
        private logging: FlyFreelyLoggingService,
        private angulartics2: Angulartics2,
        private organisationRelationshipService: OrganisationRelationshipService,
        private organisationRelationshipsUi: OrganisationRelationshipsUiService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.organisationEquipmentList$ =
            this.organisationRelationshipsUi.organisationEquipmentList$.pipe(
                map(res =>
                    res.filter(
                        item => item.organisationId === this.organisation.id
                    )
                )
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    shareEquipment() {
        const cmd: ShareResourceCommand = {
            resourceId: this.addEquipment.value
        };
        this.organisationRelationshipService
            .shareEquipment(this.organisation.id, this.relationship.id, cmd)
            .subscribe(results => {
                this.addEquipment.setValue(undefined);
                this.angulartics2.eventTrack.next({
                    action: 'share-equipment',
                    properties: {
                        category: 'shared-resources',
                        label: results.id
                    }
                });
            })
            .add(this.workTracker.createTracker());
    }

    unshareEquipment(equipment: EquipmentDto) {
        const cmd: UnshareResourceCommand = { resourceId: equipment.id };
        this.organisationRelationshipService
            .unshareEquipment(this.organisation.id, this.relationship.id, cmd)
            .subscribe(result => {
                this.angulartics2.eventTrack.next({
                    action: 'unshare-equipment',
                    properties: {
                        category: 'shared-resources',
                        label: result.id
                    }
                });
            })
            .add(this.workTracker.createTracker());
    }
}
