/**
 * Component dialogue that allows selection of rows from bulk upload csv.
 * Returns array of indices for the imported data that can then be used to limit the import.
 * Utilises the NGX Easy Table
 *
 * @param configuration a standard config file for ngx-easy-table with display options
 * @param columns the required display columns
 * @param data the data to be displayed
 * @param uploadService the service calling this modal or the service containing the validation method
 */
import { Component, Input, ViewChild } from '@angular/core';
import {
    BulkEditOptions,
    BulkImportErrorText,
    ColumnOptions,
    ColumnOptionTypes,
    NameValue,
    UploadService
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { invalidFieldText, warningFieldText } from '../helpers';

interface DisplayColumn extends Columns {
    options?: ColumnOptions;
}

interface RowTypes {
    [key: string]: ColumnOptionTypes;
}

interface RowValues {
    [key: string]: NameValue[];
}

interface CellValidity {
    rowIndex: number;
    key: string;
}

@Component({
    selector: 'bulk-import-dialogue',
    templateUrl: './import-dialogue.component.html',
    styles: [
        `
            :host
                ::ng-deep
                #table
                > thead
                > tr
                > th
                > .form-group
                > .form-checkbox
                > .checkbox {
                min-height: 20px;
                padding-left: 20px;
                margin-bottom: 0;
                font-weight: normal;
                cursor: pointer;
            }

            :host ::ng-deep #selectAllCheckbox > input {
                position: relative;
                width: 1.3em;
                height: 1.3em;
                float: left;
                margin-right: 0.5em;
            }

            :host ::ng-deep #selectAllCheckbox > i {
                position: absolute;
                font-size: 0.8em;
                line-height: 0;
                top: 50%;
                left: 20%;
            }

            :host ::ng-deep .ngx-form-icon {
                border: 1px solid #a9a9a9 !important;
                border-radius: 0.25em !important;
                height: 1.3em !important;
                width: 1.3em !important;
            }

            :host ::ng-deep .ngx-form-icon::before {
                border: 0.25rem solid #000 !important;
                border-left-width: 0 !important;
                border-top-width: 0 !important;
            }

            :host ::ng-deep .ngx-form-checkbox input:checked + .ngx-form-icon {
                background: unset;
            }

            .invalid-cell {
                background-color: #f2dede;
            }

            .warning-cell {
                background-color: #fffbe1;
            }

            .import-error-text {
                color: lighten(#595974 15%);
            }

            .fa-exclamation-triangle {
                color: #ffa83d;
            }
        `
    ]
})
export class BulkImportDialogue {
    @Input() configuration: Config = { ...DefaultConfig };
    @Input() columns: Columns[];
    @Input() data: any[];
    @Input() uploadService: UploadService;
    @Input() entity: string;

    columnOptions: ColumnOptions[];

    displayColumns: DisplayColumn[] = [];
    rowTypeLookup: RowTypes[];
    rowValueLookup: RowValues[];
    invalidCells: CellValidity[] = [];
    warningCells: CellValidity[] = [];

    importedRowIndices: number[];

    failedImportText: BulkImportErrorText[];

    columnOptionTypes = ColumnOptionTypes;

    selectedIndices: number[] = [];
    selected = new Set();

    editing = false;

    selectAll = false;
    working: boolean;
    private ngUnsubscribe$ = new Subject<void>();

    bulkEditOptions: BulkEditOptions;
    bulkEditColumns: Columns[];
    bulkEditColumn: Columns;
    bulkEditValue: string;

    @ViewChild('popover', { static: false })
    popover: PopoverDirective;

    constructor(private modal: BsModalRef, modalOptions: ModalOptions) {
        modalOptions.closeInterceptor = () => {
            if (this.popover != null && this.popover.isOpen) {
                this.popover.hide();
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.uploadService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.uploadService.columnOptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(options => {
                this.columnOptions = options;
                this.setupColumnOptions();
            });
        this.uploadService.failedImportText$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(text => {
                this.failedImportText = text;
            });

        this.uploadService.bulkEditOptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(options => {
                this.bulkEditOptions = options;
                this.bulkEditColumns = this.columns.filter(
                    c => !this.bulkEditOptions.cannotBulkEdit.includes(c.key)
                );
            });

        this.configuration.orderEnabled = false;
        this.configuration.checkboxes = true;
        this.data.forEach((row, i) => {
            this.validateCells(row, i);
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    eventEmitted($event: { event: string; value: any }) {
        const i = this.data.findIndex((d: any) => d === $event.value.row);
        switch ($event.event) {
            case 'onSelectAll':
                if (this.selectAll) {
                    this.selectedIndices = [];
                } else {
                    this.selectedIndices = [];
                    this.data.forEach((_: any, key: any) => {
                        this.selectedIndices.push(key);
                    });
                }
                this.selectAll = !this.selectAll;
                this.data.forEach((_: any, key: any) => {
                    if (this.selected.has(key) && !this.selectAll) {
                        this.selected.delete(key);
                    } else if (this.selectAll) {
                        this.selected.add(key);
                    }
                });
                break;

            case 'onPagination': {
                const startItem =
                    $event.value.page > 1
                        ? ($event.value.page - 1) * $event.value.limit
                        : 0;
                const endItem = startItem + $event.value.limit;
                const currentRows = this.data.slice(startItem, endItem);
                this.selectedIndices = [];
                currentRows.forEach((r, l) => {
                    const j = this.data.findIndex((d: any) => d === r);
                    const add = this.selected.has(j);
                    if (add) {
                        this.selectedIndices.push(l);
                    }
                });
                break;
            }
        }
    }

    onChange(row: any, rowIndex: number) {
        const i = this.data.findIndex((d: any) => d === row);
        const index = this.selectedIndices.findIndex(s => s === i);
        if (index !== -1) {
            this.selectedIndices.splice(index, 1);
        } else {
            this.selectedIndices.push(i);
        }
        if (this.selected.has(i)) {
            this.selected.delete(i);
        } else {
            this.selected.add(i);
        }
    }

    openPopover(pop: any) {
        this.popover = pop;
    }

    closePopover() {
        this.bulkEditColumn = null;
        this.bulkEditValue = null;
        this.popover.hide();
    }

    editMultiple() {
        let duplicateCounter = 0;
        this.selectedIndices.forEach(i => {
            this.data.forEach((d, index) => {
                if (index === i) {
                    // If this is a free text field that isn't a date, prevent duplicates by adding a number
                    if (
                        (this.rowTypeLookup == null ||
                            this.rowTypeLookup[this.bulkEditColumn.key] ==
                                null ||
                            this.findRowType(this.bulkEditColumn.key) ===
                                this.columnOptionTypes.TEXT) &&
                        this.bulkEditOptions.uniqueInSet.includes(
                            this.bulkEditColumn.key
                        ) &&
                        duplicateCounter > 0
                    ) {
                        this.bulkEditValue = this.bulkEditValue.concat(
                            `-${duplicateCounter}`
                        );
                    }
                    duplicateCounter++;
                    d[this.bulkEditColumn.key] = this.bulkEditValue;
                    this.validateRow(d, index);
                }
            });
        });
        this.bulkEditColumn = null;
        this.bulkEditValue = null;
    }

    revalidateAll() {
        // revalidate the table with the new columnOptions for the service's validator.
        this.data.forEach((row, i) => {
            this.validateRow(row, i);
        });
    }

    validateRow(row: any, index: number) {
        if (this.columnOptions != null) {
            this.uploadService.validateRow(
                row,
                index,
                this.displayColumns.map(col => col.options)
            );
        } else {
            this.uploadService.validateRow(row, index);
        }

        if (this.invalidCells.length > 0) {
            const filtered = this.invalidCells.filter(
                c => c.rowIndex !== index
            );
            this.invalidCells = filtered;
        }
        if (this.warningCells.length > 0) {
            const filtered = this.warningCells.filter(
                c => c.rowIndex !== index
            );
            this.warningCells = filtered;
        }
        this.validateCells(row, index);
    }

    isInvalid(key: string, index: number, row?: any) {
        if (row != null) {
            this.validateRow(row, index);
        }
        return (
            this.invalidCells.findIndex(
                cell => cell.key === key && cell.rowIndex === index
            ) !== -1
        );
    }

    hasWarning(key: string, index: number, row?: any) {
        if (row != null) {
            this.validateRow(row, index);
        }
        return (
            this.warningCells.findIndex(
                cell => cell.key === key && cell.rowIndex === index
            ) !== -1
        );
    }

    validateCells(row: any, i: number) {
        // parse each cell to turn the returned invalid text into a marker and remove it from the cell's value.
        Object.keys(row).forEach(key => {
            if (row[key].includes(invalidFieldText)) {
                row[key] = row[key].slice(0, -invalidFieldText.length).trim();
                this.invalidateCell(key, i);
            }
            if (row[key] === 'INVALID') {
                this.invalidateCell(key, i);
            }
            if (row[key].includes(warningFieldText)) {
                row[key] = row[key].slice(0, -warningFieldText.length).trim();
                this.warnCell(key, i);
            }
        });
    }

    invalidateCell(key: string, index: number) {
        if (this.isInvalid(key, index)) {
            return;
        }
        this.invalidCells.push({
            key: key,
            rowIndex: index
        });
    }

    warnCell(key: string, index: number) {
        if (this.hasWarning(key, index)) {
            return;
        }
        this.warningCells.push({
            key: key,
            rowIndex: index
        });
    }

    import() {
        const selected: any = [];
        this.data.forEach((d, i) => {
            if (this.selected.has(i)) {
                selected.push(i);
            }
        });
        if (this.columnOptions != null) {
            this.uploadService.import(
                selected,
                this.displayColumns.map(col => col.options)
            );
        } else {
            this.uploadService.import(selected);
        }
    }

    onFailedRows() {
        const newData: any[] = [];
        this.data.forEach((row, i) => {
            if (
                this.importedRowIndices != null &&
                this.importedRowIndices.includes(i) === true
            ) {
                // if this row has imported, shift all following invalid cells up by one.
                const nextRowInvalidIndex = this.invalidCells.findIndex(
                    c => c.rowIndex === i + 1
                );
                if (nextRowInvalidIndex !== -1) {
                    this.invalidCells.forEach((c, j) => {
                        if (j >= nextRowInvalidIndex) {
                            c.rowIndex--;
                        }
                    });
                    this.failedImportText.forEach((t, k) => {
                        if (k >= nextRowInvalidIndex) {
                            t.rowIndex--;
                        }
                    });
                }
            } else {
                newData.push(row);
            }
        });
        this.selectedIndices = [];
        this.selected.clear();
        this.importedRowIndices = [];
        this.data = null;
        this.data = newData;
        this.revalidateAll();
    }

    findErrorText(key: string, index: number) {
        if (this.failedImportText == null) {
            return null;
        }
        const i = this.failedImportText.findIndex(
            t => t.key === key && t.rowIndex === index
        );
        if (i !== -1) {
            return this.failedImportText[i].text;
        } else {
            return null;
        }
    }

    setupColumnOptions() {
        if (this.columnOptions == null) {
            return;
        }
        this.displayColumns = this.columns.map(col => {
            return {
                ...col,
                options:
                    this.columnOptions.find(c => c.columnName === col.key) ??
                    null
            };
        });

        this.rowTypeLookup = this.columnOptions.reduce(
            (acc, r) => ({
                ...acc,
                [r.columnName]: r.cellType ?? ColumnOptionTypes.TEXT
            }),
            []
        );
        this.rowValueLookup = this.columnOptions.reduce(
            (acc, r) => ({
                ...acc,
                [r.columnName]: r.cellValues ?? null
            }),
            []
        );
    }

    findRowType(key: string) {
        return this.rowTypeLookup[key];
    }

    cancel() {
        this.modal.hide();
    }

    generateTooltip() {
        if (this.selectedIndices.length === 0) {
            return 'Select which entries you would like to import';
        } else {
            return null;
        }
    }
}
