/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.12-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface CheckExpiryDto { 
    registerConditionId?: number;
    /**
     * Whether the entry has expired according to the expiry rules
     */
    expiryStatus?: CheckExpiryDto.ExpiryStatus;
    /**
     * When the entry expires, based on the soonest of all expired elements
     */
    expiryDate?: string;
}
export namespace CheckExpiryDto {
    export type ExpiryStatus = 'OK' | 'UPCOMING' | 'EXPIRED';
    export const ExpiryStatus = {
        OK: 'OK' as ExpiryStatus,
        UPCOMING: 'UPCOMING' as ExpiryStatus,
        EXPIRED: 'EXPIRED' as ExpiryStatus
    };
}



