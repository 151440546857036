<div class="modal-header no-icon">
    <h4 id="dialog-child-name" class="modal-title pull-left">
        Flight Documentation
    </h4>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div>
        <documentation-list
            title="Flight Docs"
            [organisationId]="mission.organisationId"
            [requiredDocumentation]="documentation.requiredDocumentation"
            [formResponses]="documentation.formResponses"
            [steps]="stepDescription"
            [documentationActions]="documentationActions"
            [missionApprovalList]="mission?.approvals"
        ></documentation-list>
        <form [formGroup]="form">
            <div class="form-group top-buffer">
                <label>Flight Notes</label>
                <textarea class="form-control" formControlName="notes">
                </textarea>
            </div>
        </form>
        <h4>Attachments</h4>
        <attachment-list
            [attachmentsHandler]="attachmentHandler"
            [allowEdit]="true"
            [allowDelete]="true"
            [allowUploads]="true"
            permissions
        ></attachment-list>
    </div>
</div>
