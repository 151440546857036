<div
    class="container-scroll inner-tab-container fill modal-scrollbar bottom-buffer"
>
    <h4 class="section-heading">
        <span>RPA Maintenance Schedule Library</span>
        <div></div>
    </h4>
    <entity-list
        type="Maintenance Schedules"
        [items]="rpaSchedules"
        [canArchive]="false"
        (create)="createMaintenanceSchedule()"
        (edit)="editMaintenanceSchedule($event)"
        [working]="working"
    ></entity-list>
    <ng-container *ngIf="hasAdditionalMaintenance">
        <h4 class="section-heading">
            <span>Battery Maintenance Schedule Library</span>
            <div></div>
        </h4>
        <entity-list
            type="Maintenance Schedules"
            [items]="batterySchedules"
            [canArchive]="false"
            (create)="createMaintenanceSchedule()"
            (edit)="editMaintenanceSchedule($event)"
            [working]="working"
        ></entity-list>
        <h4 class="section-heading">
            <span>Equipment Maintenance Schedule Library</span>
            <div></div>
        </h4>
        <entity-list
            type="Maintenance Schedules"
            [items]="equipmentSchedules"
            [canArchive]="false"
            (create)="createMaintenanceSchedule()"
            (edit)="editMaintenanceSchedule($event)"
            [working]="working"
        ></entity-list>
    </ng-container>
</div>
