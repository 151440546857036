import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild
} from '@angular/core';
import {
    AttachmentHandler,
    DisplayableMissionDto,
    FEATURE_ADDITIONAL_FLIGHT_FIELDS,
    FEATURE_EQUIPMENT,
    FEATURE_MISSION_OBJECTIVES,
    FEATURE_RPA_LOG_COLLECTION,
    FEATURE_UPLOAD_SORTIES,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    hasFeatureFlag,
    InUseMissionWorkflowVersionDto,
    LocationDetailsDto,
    MissionCrewDto,
    MissionObjectiveDto,
    MissionObjectivesService,
    MissionOperationTypeDto,
    MissionRoleDto,
    MissionRoleService,
    MissionService,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    PersonRolesDto,
    personSearch,
    PersonsOrganisationDto,
    SimpleLocationDto,
    SortieDto,
    toTimestamp,
    UpdateHistoricalMissionCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseAnimation,
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FlightUploadService } from 'libs/flight-logs/src/lib/flight-upload.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { InlineModal } from 'libs/inline-modal/src/lib/inline-modal.component';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { IntersectionSpyDirective } from 'libs/ui/src/lib/intersection-spy/intersection-spy.directive';
import * as moment from 'moment';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { LocationBearing } from '../bearing.pipe';
import { MissionCompletionService } from '../mission-completion/mission-completion.service';
import { MissionDialoguesService } from '../mission-dialogues.service';
import {
    MissionRecordModel,
    MissionRecordService
} from './mission-record.service';
interface LocationBearings {
    notams: LocationBearing[];
    aerodromes: LocationBearing[];
    locationCentre: GeoJSON.Point;
    locationArea: number;
}

const SCREEN_IDENTIFIER = 'historical-mission-record-dialogue';

@Component({
    selector: 'mission-record-edit',
    templateUrl: './mission-record-edit.component.html',
    styleUrls: ['../styles.scss'],
    providers: [
        MissionRecordService,
        FlightUploadService,
        MissionCompletionService
    ],
    animations: [
        collapseAnimation(),
        expandOnEnterAnimation(),
        collapseOnLeaveAnimation()
    ]
})
export class MissionRecordEditDialogue {
    @Input() mission: DisplayableMissionDto;
    @Input() organisation: PersonsOrganisationDto;

    organisationId: number;

    isActive: { [key: string]: boolean };
    currentStep: string;
    confirmedStep: any[];
    isCreated: boolean;

    @ViewChild(IntersectionSpyDirective)
    intersectionSpyDirective: IntersectionSpyDirective;
    get showFlightLogPanel$() {
        return (
            this.intersectionSpyDirective?.spyService?.visibility$ ?? of(false)
        );
    }

    isObjectivesCompleted: boolean;

    canUseObjectives: boolean;
    canUseEquipment: boolean;
    canSubmitForApproval: boolean;
    hasFlightLogging: boolean;
    hasAdditionalFlightFields: boolean;
    canCreatSorties: boolean;
    canUploadSorties: boolean;
    uniqueLogId: string;
    allowFinalisingIncomplete: boolean;

    missionObjectives: MissionObjectiveDto[];
    missionTypes: MissionOperationTypeDto[];
    pilotRoleId: number;
    rpicRoleId: number;

    missionWorkflows: InUseMissionWorkflowVersionDto[];
    missionWorkflowVersionId: number;
    // This holds the current resolved values for the values in the FormGroup
    missionDetails = {
        missionWorkflowVersion: null as InUseMissionWorkflowVersionDto
    };
    loadingWorkflow: boolean;
    currentAuthority: OrganisationAuthorityDto;

    attachmentHandler: AttachmentHandler;

    timeZones: string[];
    timeZoneOffsets: { [timeZone: string]: string };
    utc: string;

    locations: SimpleLocationDto[];
    location: LocationDetailsDto;
    currentLocationId: number;
    hasLocation = false;
    showLocationName = true;

    pilots$: Observable<PersonRolesDto[]>;

    locationFeatures: FeatureGroup[];

    locationBearings: LocationBearings;

    working: boolean;

    private ngUnsubscribe$ = new Subject<void>();
    workTracker = new WorkTracker();
    workTrackerPromise: Promise<boolean>;

    get missionRecordForm() {
        return this.missionRecordService.form;
    }
    get sorties() {
        return this.missionRecordService.form.controls.sorties;
    }

    @ViewChild('inlineModal', { static: true }) inlineModal: InlineModal;
    @ViewChild('map', { read: ElementRef, static: true }) map: ElementRef;

    constructor(
        private missionService: MissionService,
        private missionRecordService: MissionRecordService,
        private missionObjectivesService: MissionObjectivesService,
        private missionDialoguesService: MissionDialoguesService,
        private flightUploadService: FlightUploadService,
        private commonDialoguesService: CommonDialoguesService,
        private missionRoleService: MissionRoleService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private changeDetector: ChangeDetectorRef,
        private fullScreenService: FullScreenService,
        private modal: BsModalRef,
        modalOptions: ModalOptions,
        private logging: FlyFreelyLoggingService,
        public angulartics2: Angulartics2,
        private flightLogsService: FlightLogsService
    ) {
        this.uniqueLogId = uuidv4();
        // Drive the working state by both the service's and this component's work trackers.
        this.workTrackerPromise = combineLatest([
            this.workTracker.observable,
            this.missionRecordService.working$
        ])
            .pipe(
                map((statuses: boolean[]) =>
                    statuses.reduce((acc, s) => acc && s, true)
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .toPromise();

        this.workTrackerPromise.then(working => (this.working = working));

        modalOptions.closeInterceptor = () => {
            if (this.inlineModal.show) {
                this.inlineModal.closeComponent();
                return Promise.reject();
            } else {
                if (this.missionRecordForm.dirty) {
                    return this.commonDialoguesService.showConfirmationDialogue(
                        'Confirm Cancel',
                        `You have unsaved changes, are you sure you want to cancel?`,
                        'Yes',
                        () => Promise.resolve()
                    );
                }
                return Promise.resolve();
            }
        };
    }

    ngOnInit() {
        this.organisationId =
            this.mission?.organisationId ?? this.organisation.id;

        this.pilots$ = this.missionRecordService.allPersonnel$.pipe(
            map(personnel =>
                personnel.filter(
                    p => p.roles.indexOf(PersonRolesDto.Roles.PILOT) !== -1
                )
            )
        );

        this.flightLogsService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshFlights());

        this.activeTab('objectives');

        this.missionRecordService.setupMissionForm(
            this.mission,
            this.organisationId
        );
        this.refreshData();
        this.findTimeZones(moment(this.mission.missionDate).toDate());

        this.attachmentHandler = this.missionService.attachmentHandler(
            this.mission.id
        );

        combineLatest([
            this.missionRecordForm.controls.timeZone.valueChanges,
            this.missionRecordForm.controls.missionDate.valueChanges,
            this.missionRecordForm.controls.missionWorkflowVersionId
                .valueChanges
        ])
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(([timeZone, missionDate, workflowVersionId]) => {
                if (timeZone) {
                    const t = moment.tz(
                        moment(missionDate).format('YYYY-MM-DD[T]HH:mm:ss'),
                        timeZone
                    );
                    this.utc = `${t.tz('UTC').format('YYYY-MM-DD HH:mm')}Z`;
                    this.findTimeZones();
                }
                this.missionWorkflowVersionId = workflowVersionId;
                this.updateMissionWorkflowVersionReference();
            });

        combineLatest([
            this.missionRecordForm.controls.name.statusChanges,
            this.missionRecordForm.controls.missionObjectiveTypeId
                .statusChanges,
            this.missionRecordForm.controls.missionObjective.statusChanges
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.isObjectivesCompleted =
                    this.missionRecordForm.controls.name.valid &&
                    this.missionRecordForm.controls.missionObjectiveTypeId
                        .valid &&
                    this.missionRecordForm.controls.missionObjective.valid;
            });

        this.missionRecordService.missionWorkflows$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missionWorkflows => {
                this.missionWorkflows = missionWorkflows;
                this.updateMissionWorkflowVersionReference();
            });

        // Prevent "ExpressionChangedAfterItHasBeenCheckedError" because the form can be changed by the service.
        this.missionRecordService.savedMission$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.changeDetector.detectChanges());

        this.refreshFeatureFlags();
    }
    private refreshFlights() {
        this.missionService
            .findMission(this.mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(m => {
                this.missionRecordService.updateFlights(m.sorties);
            })
            .add(this.workTracker.createTracker());
    }
    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshData() {
        forkJoin([
            this.missionObjectivesService.find(this.organisationId),
            this.missionService.findMissionTypes(this.organisationId),
            this.missionRoleService.findMissionRoles(this.organisationId)
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([objectives, missionTypes, roles]) => {
                    this.missionObjectives = objectives;
                    this.missionTypes = missionTypes;
                    this.rpicRoleId = roles.find(
                        r =>
                            r.coreRole ===
                            MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                    ).id;
                    this.pilotRoleId = roles.find(
                        r => r.coreRole === MissionRoleDto.CoreRole.PILOT
                    ).id;
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error refreshing resources: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());

        this.timeZones = moment.tz.names();
    }

    refreshFeatureFlags() {
        this.canSubmitForApproval = !this.organisation.personalOrganisation;
        this.canUseObjectives = hasFeatureFlag(
            this.organisation,
            FEATURE_MISSION_OBJECTIVES
        );
        this.hasFlightLogging = hasFeatureFlag(
            this.organisation,
            FEATURE_RPA_LOG_COLLECTION
        );
        this.canUseEquipment = hasFeatureFlag(
            this.organisation,
            FEATURE_EQUIPMENT
        );
        this.canUploadSorties = hasFeatureFlag(
            this.organisation,
            FEATURE_UPLOAD_SORTIES
        );
        this.hasAdditionalFlightFields = hasFeatureFlag(
            this.organisation,
            FEATURE_ADDITIONAL_FLIGHT_FIELDS
        );
        if (!this.canUseObjectives) {
            this.missionRecordService.noObjectivesRequired();
        }
    }

    findTimeZones(missionDate?: Date) {
        const date =
            missionDate ?? this.missionRecordForm.controls.missionDate.value;
        if (date != null) {
            this.timeZoneOffsets = this.timeZones.reduce((acc, tz) => {
                const offset = moment
                    .tz(moment(date).format('YYYY-MM-DD[T]HH:mm:ss'), tz)
                    .format('Z');
                return {
                    ...acc,
                    [tz]: offset
                };
            }, {});
        }
    }

    private updateMissionWorkflowVersionReference() {
        if (
            this.missionWorkflowVersionId != null &&
            this.missionWorkflows != null
        ) {
            this.missionDetails.missionWorkflowVersion =
                this.missionWorkflows.find(
                    wf => wf.id === this.missionWorkflowVersionId
                );
        }
        this.refreshCurrentAuthority();
    }

    private refreshCurrentAuthority() {
        if (
            this.missionDetails.missionWorkflowVersion == null ||
            this.missionDetails.missionWorkflowVersion.delegatedAuthority ==
                null
        ) {
            this.loadingWorkflow = false;
            return;
        }
        this.loadingWorkflow = true;

        const authorityId =
            this.missionDetails.missionWorkflowVersion.delegatedAuthority.id;
        const organisationId =
            this.mission.organisationId ?? this.organisation.id;

        this.organisationAuthorityService
            .findAuthority(authorityId, organisationId)
            .subscribe({
                next: authority => {
                    this.currentAuthority = authority;
                },
                complete: () => {
                    this.loadingWorkflow = false;
                }
            })
            .add(this.workTracker.createTracker());
    }

    showAuthorityDetails() {
        /* this.angulartics2.eventTrack.next({
            action: 'primary-authority-view',
            properties: {
                category: SCREEN_IDENTIFIER
            }
        }); */

        const authority =
            this.missionDetails.missionWorkflowVersion.delegatedAuthority;

        this.authorityDialoguesService.showAuthorityDetails(
            this.organisationId,
            authority.authorityType,
            this.currentAuthority
        );
    }

    addFlight() {
        this.missionService
            .newSortie(this.mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sortie => {
                this.missionRecordService.addFlight({
                    ...sortie,
                    status: SortieDto.Status.COMPLETED,
                    craftId:
                        this.mission.craftIds.length === 1 &&
                        this.mission.unlistedRpas.length === 0
                            ? this.mission.craftIds[0]
                            : null,
                    pilotId:
                        this.mission.missionCrewDetails.length === 1 &&
                        this.mission.unlistedPersonnel.length === 0
                            ? this.mission.missionCrewDetails[0].person.id
                            : null
                });
            })
            .add(this.workTracker.createTracker());
    }

    deleteFlight(ix: number) {
        this.missionRecordService.deleteFlight(ix);
    }

    bulkFlightUpload() {
        this.flightUploadService.showBulkUpload(
            this.mission.missionDate,
            this.missionRecordService.availableRpas$,
            this.missionRecordService.availablePersonnel$,
            this.missionRecordService.availableBatterySets$,
            this.missionRecordService.allRpas$,
            this.missionRecordService.allBatterySets$
        );
    }

    updateResources(command: UpdateHistoricalMissionCommand) {
        const rpaIds: number[] = command.sorties.reduce(
            (acc, s) => (s.craftId != null ? acc.concat([s.craftId]) : acc),
            []
        );
        const equipmentIds: number[] = command.sorties.reduce(
            (acc, s) =>
                s.equipmentIds != null ? acc.concat(s.equipmentIds) : acc,
            []
        );
        const rpic = this.mission.missionCrewDetails.find(
            c => c.role.coreRole === MissionRoleDto.CoreRole.PILOT_IN_COMMAND
        );

        const flightCrew: MissionCrewDto[] = command.sorties.reduce(
            (acc, s) =>
                s.pilotId != null
                    ? acc.concat(
                          [
                              {
                                  missionRoleId:
                                      s.pilotId === rpic?.person?.id
                                          ? this.rpicRoleId
                                          : this.pilotRoleId,
                                  personId: s.pilotId
                              }
                          ].concat(s.crew ?? [])
                      )
                    : acc,
            []
        );

        const crewDetails: MissionCrewDto[] =
            rpic != null && rpic.person != null
                ? [
                      {
                          missionRoleId: this.rpicRoleId,
                          personId: rpic?.person?.id
                      }
                  ].concat(
                      flightCrew.filter(
                          c => c.missionRoleId !== this.rpicRoleId
                      )
                  )
                : flightCrew.filter(c => c.missionRoleId !== this.rpicRoleId);

        command.craftIds = rpaIds;
        command.equipmentIds = equipmentIds;
        command.missionCrew = crewDetails;
    }

    save() {
        const doneWorking = this.workTracker.createTracker();
        const values = <MissionRecordModel>this.missionRecordForm.value;

        const command: UpdateHistoricalMissionCommand = {
            ...values,
            missionDate: toTimestamp(values.missionDate, values.timeZone),
            craftIds: [],
            equipmentIds: [],
            missionCrew: []
        };
        this.updateResources(command);
        return this.missionService
            .updateHistoricalMission(this.mission.id, command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(
                mission => {
                    this.missionRecordForm.markAsPristine();
                    this.logging.success(`${mission.name} saved`);
                    doneWorking();
                    return mission;
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error saving mission: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    saveAndClose() {
        this.save().then(result => this.modal.hide());
    }

    submit() {
        this.save()
            .then(mission =>
                this.missionService
                    .readyForFinalisation(this.mission.id)
                    .toPromise()
            )
            .then(mission => {
                this.logging.info(
                    'The mission has been flagged as ready to finalise with the approver'
                );
                this.modal.hide();
            });
    }

    // reloadMissionPage() {
    //     this.missionDialoguesService.showMissionDetailsThroughState(
    //         this.mission.id
    //     );
    // }

    finalise() {
        this.save()
            .then(() =>
                this.commonDialoguesService.showConfirmationDialogue(
                    'Finalise Mission',
                    'Do you want to finalise this mission? No further edits will be allowed',
                    'Finalise',
                    () =>
                        this.missionService.finaliseMission(
                            this.mission.id,
                            this.allowFinalisingIncomplete || false
                        )
                )
            )
            .then(
                results => {
                    this.logging.success(
                        'Mission finalised, no further changes possible'
                    );
                    this.modal.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error finalising mission: ${error.message}`
                    )
            );
    }

    onSectionChange(sectionId: string) {
        this.activeTab(sectionId);
    }

    activeTab(section: string) {
        this.isActive = {};
        this.isActive[section] = true;
    }

    stepCompleted(step: string) {
        if (!this.confirmedStep) {
            return;
        }

        const foundStep = this.confirmedStep.find(st => st === step);
        switch (step) {
            case 'missionName':
                if (foundStep) {
                    if (this.isCreated) {
                        this.currentStep = 'missionObjective';
                    } else {
                        this.currentStep = 'createMission';
                    }
                }
                break;
            case 'missionObjective':
                if (foundStep) {
                    this.currentStep = 'missionLocation';
                }
                break;
            case 'operationType':
                if (foundStep) {
                    this.currentStep = 'plannedDate';
                }
                break;
            case 'plannedDate':
                if (foundStep) {
                    this.currentStep = 'plannedTime';
                }
                break;
            case 'plannedTime':
                if (foundStep) {
                    this.currentStep = 'flights';
                }
                break;
            case 'flights':
                if (foundStep) {
                    this.currentStep = 'attachments';
                }
                break;
            case 'attachments':
                if (foundStep) {
                    this.currentStep = 'none';
                }
                break;
        }
        this.changeDetector.detectChanges();
    }

    setStep(step: string) {
        if (step === this.currentStep) {
            return;
        }
        this.currentStep = step;
        this.changeDetector.detectChanges();
    }

    scrollTo(section: string) {
        this.activeTab(section);
        document
            .querySelector(`#${section}`)
            .scrollIntoView({ behavior: 'smooth' });
    }

    onFullscreenRequested() {
        this.fullScreenService.toggleFullScreen(this.map);
    }

    close() {
        this.modal.hide();
    }

    personSearch = personSearch;
}
