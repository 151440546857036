import {
    AirspaceJurisdictionDto,
    NamedLayer
} from '@flyfreely-portal-ui/flyfreely';
import { SourceType } from './interfaces';

export const JURISDICTIONS_ID = 'jurisdictions';

export const jurisdictionAirspaceSources = (
    jurisdictions: AirspaceJurisdictionDto[]
) =>
    [
        {
            id: JURISDICTIONS_ID,
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: jurisdictions
                    .filter(j => j.enabled && j.boundary != null)
                    .map(j => ({
                        id: `jurisdiction-${j.id}`,
                        type: 'Feature',
                        geometry: j.boundary
                    }))
            }
        }
    ] as SourceType[];

export const jurisdictionAirspaceLayers = [
    {
        id: JURISDICTIONS_ID,
        type: 'fill',
        source: JURISDICTIONS_ID,
        minzoom: 1,
        paint: {
            'fill-color': '#fa2fa3',
            'fill-opacity': 0
        }
    }
] as NamedLayer[];
