import { Injectable } from '@angular/core';
import {
    AuthorityRegisterSummaryDto,
    AuthorityService,
    AuthorityTypeDto,
    CraftAuthorityDto,
    CreateCraftAuthorityCommand,
    CreateOrganisationAuthorityCommand,
    getMissionWorkflowSteps,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    SimpleAuthorityTypeDto,
    UiCraftAuthorityDto,
    UiOrganisationAuthorityDto,
    UpdateAuthorityCommand
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { WorkflowDialoguesService } from 'libs/workflows/src/lib/workflow-dialogues.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssociateAuthoritiesDialogueComponent } from './associate-authority/associate-authority.component';
import { AuthorityDetailsDialogue } from './authority-details/authority-details-dialogue.component';
import { AuthorityEditDialogue } from './authority-edit/authority-edit-dialogue.component';
import { NominatedPersonnelDialogue } from './nominated-personnel/nominated-personnel.component';
import { RegisterExpirySummary } from './organisation-authority/organisation-authority-edit.service';
import { PersonnelRegisterDialogue } from './registers/personnel-register/personnel-register-dialogue.component';
import { RpaRegisterDialogue } from './registers/rpa-register/rpa-register-dialogue.component';

@Injectable()
export class AuthorityDialoguesService {
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modalService: BsModalService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private workflowDialoguesService: WorkflowDialoguesService
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    showAuthorityEdit(
        endorsedEntityId: number,
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto | CraftAuthorityDto,
        managingOrganisationId: number,
        authorityService: AuthorityService<
            UiCraftAuthorityDto | UiOrganisationAuthorityDto,
            CreateCraftAuthorityCommand | CreateOrganisationAuthorityCommand,
            UpdateAuthorityCommand
        >,
        dependentOnAuthority?: OrganisationAuthorityDto | CraftAuthorityDto
    ) {
        return this.modalService.show(AuthorityEditDialogue, {
            class: 'modal-lg',
            initialState: {
                endorsedEntityId,
                authorityType: { ...authorityType },
                authority: authority != null ? { ...authority } : null,
                managingOrganisationId,
                authorityService,
                dependentOnAuthority
            }
        });
    }

    showAuthorityWorkflowDialogue(
        organisationId: number,
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto,
        managingOrganisationId: number
    ) {
        const steps = getMissionWorkflowSteps([
            'pre-submit',
            'pre-departure',
            'pre-mission',
            'pre-flight',
            'flying',
            'post-flight',
            'post-mission',
            'debriefing'
        ]);

        return this.organisationAuthorityService
            .findWorkflow(authority.id, managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(workflow =>
                this.workflowDialoguesService.showEditWorkflow(
                    steps,
                    this.organisationAuthorityService.getWorkflowHandler(
                        authority.id
                    ),
                    workflow,
                    false,
                    false,
                    authority.requiresApproval || authorityType.requiresApproval
                )
            );
    }

    showAuthorityDetails(
        managingOrganisationId: number,
        authorityType: SimpleAuthorityTypeDto,
        authority: OrganisationAuthorityDto | CraftAuthorityDto,
        icon: string = null,
        canEdit: boolean = false,
        canUseWorkflow?: boolean,
        registerSummaries?: RegisterExpirySummary[],
        viewOnly: boolean = false
    ) {
        return this.modalService.show(AuthorityDetailsDialogue, {
            class: 'modal-lg',
            initialState: {
                authorityType: { ...authorityType },
                authority: { ...authority },
                managingOrganisationId,
                icon,
                canEdit,
                canUseWorkflow,
                registerSummaries,
                viewOnly
            }
        });
    }

    showAssociateAuthority(
        managingOrganisationId: number,
        dependentOnAuthorityType: OrganisationAuthorityGroup,
        authority: OrganisationAuthorityDto,
        name: string,
        // FIXME: This is a temporary fix until we have a better way of doing this for "top-level" authorities.
        selectForCreate?: boolean
    ) {
        return this.modalService.show(AssociateAuthoritiesDialogueComponent, {
            class: 'modal-md',
            initialState: {
                authority,
                managingOrganisationId,
                dependentOnAuthorityType,
                name,
                selectForCreate
            }
        });
    }

    showRpaRegisterDialogue(
        organisationId: number,
        authorityType: AuthorityTypeDto,
        authority: CraftAuthorityDto,
        register: AuthorityRegisterSummaryDto,
        managingOrganisationId: number
    ) {
        this.modalService.show(RpaRegisterDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                organisationId,
                authorityType,
                authority,
                register,
                managingOrganisationId
            }
        });
    }

    showPersonnelRegisterDialogue(
        organisationId: number,
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto,
        register: AuthorityRegisterSummaryDto,
        managingOrganisationId: number
    ) {
        this.modalService.show(PersonnelRegisterDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                organisationId,
                authorityType,
                authority,
                register,
                managingOrganisationId
            }
        });
    }

    showNominatedPersonnel(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        this.modalService.show(NominatedPersonnelDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { authorityType, authority }
        });
    }
}
