import { PipeTransform, Pipe } from '@angular/core';

export interface LocationBearing {
    bearing: number;
    distance: number;
}

@Pipe({
    name: 'bearing'
})
export class BearingPipe implements PipeTransform {
    transform(value: LocationBearing) {
        return `${value.distance.toFixed(1)}nm ${value.bearing.toFixed(
            0
        )}&deg;`;
    }
}
