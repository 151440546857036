import { Injectable } from '@angular/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MoreFeaturesDialogue } from './more-features/more-features-dialogue.component';

@Injectable()
export class FeaturesDialoguesService {
    constructor(private modalService: BsModalService) {}

    showMoreFeatures(organisationId: number, isPersonalOrg: boolean) {
        return this.modalService.show(MoreFeaturesDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { organisationId, isPersonalOrg }
        });
    }
}
