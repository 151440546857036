import { Component, Input, OnInit } from '@angular/core';
import { ngxLoadingAnimationTypes, NgxLoadingConfig } from 'ngx-loading';

@Component({
    selector: 'routing-error',
    templateUrl: './routing-error.component.html',
    styles: [
        `
            .color-red {
                color: red;
            }
        `
    ]
})
export class RoutingErrorComponent implements OnInit {
    @Input() status: string;
    @Input() noun: string;
    @Input() show = false;
    @Input() config: NgxLoadingConfig;
    @Input() message: string;

    defaultConfig: NgxLoadingConfig = {
        animationType: ngxLoadingAnimationTypes.none
    };

    displayConfig: NgxLoadingConfig;

    ngOnInit() {
        this.displayConfig = this.config ?? this.defaultConfig;
    }

    getMessage() {
        if (this.message) {
            return this.message;
        }
        if (this.noun == null || this.noun === '') {
            this.noun = 'item';
        }

        switch (this.status) {
            case 'NOT_FOUND':
                return `The ${this.noun} you are trying to access is not available. Please ensure the URL entered is correct.`;
            case 'NO_ACCESS':
                return `Access to this ${this.noun} is not allowed.`;
            default:
                return 'An error has occurred';
        }
    }

    formatStatus() {
        return this.status.replace('_', ' ');
    }
}
