import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import * as FileSaver from 'file-saver';
import { ReplaySubject, Subject } from 'rxjs';
import { last, map, takeUntil } from 'rxjs/operators';
import { WorkTracker } from '../work-tracker';
import { FlyFreelyError } from './errors';

/**
 * This is a method to try and derive a file name using the fetched HTTPResponse headers for an API fetch that returns a blob.
 * @param headers the HTTPResponse headers from an API call's return
 * @returns a file name with extension as a string
 * Taken from https://stackoverflow.com/questions/54753021/how-can-i-pass-an-auth-token-when-downloading-a-file
 */
const getFilenameFromHeaders = (headers: HttpHeaders) => {
    // The content-disposition header should include a suggested filename for the file
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
        return null;
    }

    const leadIn = 'filename=';
    const start = contentDisposition.search(leadIn);
    if (start < 0) {
        return null;
    }

    // Get the 'value' after the filename= part (which may be enclosed in quotes)
    const value = contentDisposition.substring(start + leadIn.length).trim();
    if (value.length === 0) {
        return null;
    }

    // If it's not quoted, we can return the whole thing
    const firstCharacter = value[0];
    if (firstCharacter !== '"' && firstCharacter !== "'") {
        return value;
    }

    // If it's quoted, it must have a matching end-quote
    if (value.length < 2) {
        return null;
    }

    // The end-quote must match the opening quote
    const lastCharacter = value[value.length - 1];
    if (lastCharacter !== firstCharacter) {
        return null;
    }

    // Return the content of the quotes
    return value.substring(1, value.length - 1);
};

export interface DownloadProgressWithUrl {
    url: string;
    progress: number;
}

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    private downloadingSource = new ReplaySubject<boolean>(1);
    downloading$ = this.downloadingSource.asObservable();

    private progressSource = new ReplaySubject<DownloadProgressWithUrl[]>();
    progress$ = this.progressSource.asObservable();

    progressItems: DownloadProgressWithUrl[] = [];

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private http: HttpClient,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.downloadingSource.next(working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.downloadingSource.complete();
        this.progressSource.complete();
    }

    /**
     * Download and save a file, such as a report or attachment
     * @param url the downloadUrl. This should be a complete url as generated by the report service's getPdfReportUrl method.
     * @returns saves the downloaded file with the filename derived from its content disposition
     */
    downloadFromUrl(url: string) {
        const doneWorking = this.workTracker.createTracker();
        return this.http
            .get(url, {
                responseType: 'blob',
                observe: 'events',
                reportProgress: true
            })
            .pipe(
                map((event: HttpEvent<Blob>) => {
                    const progressValue =
                        event.type === HttpEventType.DownloadProgress
                            ? Math.floor((event.loaded * 100) / event.total)
                            : null;
                    let item = this.progressItems.find(i => i.url === url);
                    switch (event.type) {
                        case HttpEventType.Response:
                            return event;
                        case HttpEventType.DownloadProgress:
                            if (item != null) {
                                item.progress = progressValue;
                            } else {
                                item = {
                                    url: url,
                                    progress: progressValue
                                };
                            }
                            this.progressItems = this.progressItems
                                .filter(i => i.url !== url)
                                .concat(item);
                            this.progressSource.next(this.progressItems);
                            return;
                        default:
                            return;
                    }
                }),
                last()
            )
            .toPromise()
            .then(
                response => {
                    // Saving the response as a new blob with the type as 'application/octet-stream'
                    // ensures the file is downloaded and not opened in the same window
                    const blob = new Blob([response.body], {
                        type: 'application/octet-stream'
                    });
                    doneWorking();
                    // getFilenameFromHeaders should return the correct file type extension too, so the file gets saved as the correct type
                    FileSaver.saveAs(
                        blob,
                        getFilenameFromHeaders(response.headers) ?? 'file'
                    );
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error downloading file: ${error.message}`
                    );
                    doneWorking();
                }
            )
            .catch((error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error downloading file: ${error.message}`
                );
                doneWorking();
            });
    }
}
