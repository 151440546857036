/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.44-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaintenanceActivityDto } from './maintenanceActivityDto';
import { SimplePersonDto } from './simplePersonDto';
import { MaintenanceLogActions } from './maintenanceLogActions';

/**
 * A complete maintenance log object graph
 */
export interface MaintenanceLogDto {
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The organisation that owns this record
     */
    organisationId: number;
    /**
     * The time the request was made
     */
    requestTime?: string;
    requester?: SimplePersonDto;
    /**
     * The type of equipment that this maintenance request is being made on
     */
    resourceCategory?: MaintenanceLogDto.ResourceCategory;
    /**
     * The ID of the resource that maintenance is requested on
     */
    resourceId?: number;
    /**
     * The status of this maintenance request log
     */
    status?: MaintenanceLogDto.Status;
    /**
     * The mission, if any, that this log was created as part of
     */
    missionId?: number;
    /**
     * The reason that this request has been put in
     */
    reasonForRequest?: string;
    /**
     * ${swagger.MaintenanceLog.outcome}
     */
    outcome?: MaintenanceLogDto.Outcome;
    /**
     * ${swagger.MaintenanceLog.saveToUse}
     */
    safeToUse?: boolean;
    /**
     * ${swagger.MaintenanceLog.identifier}
     */
    identifier?: string;
    /**
     * ${swagger.MaintenanceLog.workInstructionUrl}
     */
    workInstructionUrl?: string;
    /**
     * The activities that have been carried out relating to this request
     */
    activities?: Array<MaintenanceActivityDto>;
    availableActions: MaintenanceLogActions;
    /**
     * The type of maintenance log being requested
     */
    maintenanceLogType?: MaintenanceLogDto.MaintenanceLogType;
    /**
     * Does the maintenance completed satisfy scheduled maintenance requirements
     */
    scheduledMaintenanceCompleted?: boolean;
    releasedBy?: SimplePersonDto;
    /**
     * ${swagger.MaintenanceLog.releaseTime}
     */
    releaseTime?: string;
    releaseSignature?: string;
}
export namespace MaintenanceLogDto {
    export type ResourceCategory =
        | 'CRAFT'
        | 'BATTERY_SET'
        | 'BATTERY'
        | 'EQUIPMENT'
        | 'UNLISTED_RPA';
    export const ResourceCategory = {
        CRAFT: 'CRAFT' as ResourceCategory,
        BATTERY_SET: 'BATTERY_SET' as ResourceCategory,
        BATTERY: 'BATTERY' as ResourceCategory,
        EQUIPMENT: 'EQUIPMENT' as ResourceCategory,
        UNLISTED_RPA: 'UNLISTED_RPA' as ResourceCategory
    };
    export type Status =
        | 'DRAFT'
        | 'REQUESTED'
        | 'COMPLETED'
        | 'CANCELLED'
        | 'DELETED';
    export const Status = {
        DRAFT: 'DRAFT' as Status,
        REQUESTED: 'REQUESTED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        CANCELLED: 'CANCELLED' as Status,
        DELETED: 'DELETED' as Status
    };
    export type Outcome = 'RELEASE_TO_SERVICE' | 'RETIRE_FROM_SERVICE';
    export const Outcome = {
        RELEASE_TO_SERVICE: 'RELEASE_TO_SERVICE' as Outcome,
        RETIRE_FROM_SERVICE: 'RETIRE_FROM_SERVICE' as Outcome
    };
    export type MaintenanceLogType =
        | 'DEFECT'
        | 'SCHEDULED'
        | 'IN_FIELD'
        | 'INSPECTION'
        | 'CONFIGURATION_CHANGE'
        | 'OTHER';
    export const MaintenanceLogType = {
        DEFECT: 'DEFECT' as MaintenanceLogType,
        SCHEDULED: 'SCHEDULED' as MaintenanceLogType,
        IN_FIELD: 'IN_FIELD' as MaintenanceLogType,
        INSPECTION: 'INSPECTION' as MaintenanceLogType,
        CONFIGURATION_CHANGE: 'CONFIGURATION_CHANGE' as MaintenanceLogType,
        OTHER: 'OTHER' as MaintenanceLogType
    };
}
