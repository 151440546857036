import MapboxDraw, { MapMouseEvent } from '@mapbox/mapbox-gl-draw';
import { FeatureProperties, MapModes } from '../constants';
import { getMeasurementDescription } from '../utils';

class SingleMeasurementLineState {
    line: any; // DrawFeature
    measurement: any; // DrawFeature
    currentVertexPosition: number;
    direction: string;
    isDragging: boolean;
}

// We're extending the default line string, and
// add logic to draw a measurement label, and to stop the line after the second point.
const DrawSingleMeasurementLine = { ...MapboxDraw.modes.draw_line_string };

DrawSingleMeasurementLine.onSetup = function (opts: {
    featureId?: any;
}): SingleMeasurementLineState {
    opts = opts || {};
    const featureId = opts.featureId;

    let currentVertexPosition, line, measurement;
    const direction = 'forward';
    if (featureId) {
        line = this.getFeature(featureId);
        measurement = this.getFeature(line.properties.relatedFeatureIds[0]);
        currentVertexPosition = 1;
    } else {
        this.clearSelectedFeatures();
        line = this.newFeature({
            type: 'Feature',
            properties: {
                meta: FeatureProperties.MEASUREMENT_LINE
            },
            geometry: {
                type: 'LineString',
                coordinates: []
            }
        });
        measurement = this.newFeature({
            type: 'Feature',
            properties: {
                meta: FeatureProperties.MEASUREMENT_LABEL,
                measurement: ``,
                parent: line.id
            },
            geometry: {
                type: 'Point',
                coordinates: []
            }
        });
        line.properties.relatedFeatureIds = [measurement.id];
        measurement.properties.relatedFeatureIds = [line.id];
        currentVertexPosition = 0;
        this.addFeature(line);
        this.addFeature(measurement);
    }
    return {
        line,
        measurement,
        currentVertexPosition,
        direction,
        isDragging: false
    };
};

DrawSingleMeasurementLine.onDrag = function (
    state: SingleMeasurementLineState,
    e: MapMouseEvent
) {
    state.isDragging = true;
    this.onMouseMove(state, e);
};

DrawSingleMeasurementLine.onMouseUp = function (
    state: SingleMeasurementLineState
) {
    if (state.isDragging) {
        this.changeMode(MapModes.SIMPLE_SELECT as MapboxDraw.DrawMode);
    }
};

// when clicked, either start or stop a single line
// @ts-ignore: not in the current type
DrawSingleMeasurementLine.clickAnywhere = function (
    state: SingleMeasurementLineState,
    e: MapMouseEvent
) {
    if (state.currentVertexPosition === 1) {
        state.line.updateCoordinate(
            state.currentVertexPosition,
            e.lngLat.lng,
            e.lngLat.lat
        );
        state.line.updateCoordinate(
            state.currentVertexPosition + 1,
            e.lngLat.lng,
            e.lngLat.lat
        );

        e.preventDefault();
        return this.changeMode(MapModes.SIMPLE_SELECT);
    }
    state.line.updateCoordinate(
        state.currentVertexPosition,
        e.lngLat.lng,
        e.lngLat.lat
    );
    if (state.direction === 'forward') {
        state.currentVertexPosition++;
        state.line.updateCoordinate(
            state.currentVertexPosition,
            e.lngLat.lng,
            e.lngLat.lat
        );
    } else {
        state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    }
};

// on mouse move, update the line coordinate and measurement
DrawSingleMeasurementLine.onMouseMove = function (
    state: SingleMeasurementLineState,
    e: { lngLat: { lng: any; lat: any } }
) {
    state.line.updateCoordinate(
        state.currentVertexPosition,
        e.lngLat.lng,
        e.lngLat.lat
    );
    state.measurement.updateCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    if (state.currentVertexPosition === 1) {
        // we are drawing a line.
        const lineGeoJson = state.line.toGeoJSON();
        state.measurement.properties.measurement = getMeasurementDescription(
            lineGeoJson
        );
        this.map.fire('draw.update', {
            features: [lineGeoJson, state.measurement.toGeoJSON()]
        });
    }
};

DrawSingleMeasurementLine.onKeyUp = function (
    state: SingleMeasurementLineState,
    e: { keyCode: number }
) {
    if (e.keyCode === 13) {
        // ENTER
        this.changeMode(MapModes.SIMPLE_SELECT as MapboxDraw.DrawMode);
    } else if (e.keyCode === 27) {
        // ESC
        this.deleteFeature(state.line.id, {
            silent: true
        });
        this.deleteFeature(state.measurement.id, {
            silent: true
        });
        this.changeMode(MapModes.SIMPLE_SELECT as MapboxDraw.DrawMode);
    }
};

DrawSingleMeasurementLine.onStop = function (
    state: SingleMeasurementLineState
) {
    this.activateUIButton();
    // check to see if we've deleted this feature
    if (this.getFeature(state.line.id) === undefined) {
        return;
    }

    if (!state.isDragging) {
        // remove last added coordinate
        state.line.removeCoordinate(`${state.currentVertexPosition}`);
    }
    if (state.line.isValid()) {
        this.map.fire(MapboxDraw.constants.events.CREATE, {
            features: [state.line.toGeoJSON(), state.measurement.toGeoJSON()]
        });
    } else {
        this.deleteFeature(state.line.id, {
            silent: true
        });
        this.deleteFeature(state.measurement.id, {
            silent: true
        });
        this.changeMode(MapModes.SIMPLE_SELECT as MapboxDraw.DrawMode, {}, { silent: true });
    }
};

// Required method which will display the features
DrawSingleMeasurementLine.toDisplayFeatures = function (
    state: SingleMeasurementLineState,
    geojson: any,
    display: (arg0: any) => void
) {
    display(geojson);
};

export default DrawSingleMeasurementLine;
