<enhanced-help componentName="associated-resources"></enhanced-help>
<h4 class="section-heading">
    <span>Associated Equipment</span>
    <div></div>
</h4>
<div class="table-container-no-scroll">
    <static-table
        [availableColumns]="associatedEquipmentColumns"
        [tableData]="associatedEquipment$ | async"
        [tableConfig]="associatedEquipmentTableConfig"
        [centeredHeadings]="true"
        [borderless]="true"
        scanability="alternate"
    >
        <ng-template columnTemplate="name" let-row="row" let-column="column">
            <status-icon
                [equipment]="row"
                (nameClicked)="showEquipment(row)"
            ></status-icon>
        </ng-template>

        <ng-template rowActions let-row="row">
            <button
                [disabled]="working"
                class="btn btn-tertiary btn-delete"
                type="button"
                (click)="removeAssociatedEquipment(row.id)"
                tooltip="Remove associated equipment"
                placement="left"
                analyticsOn
                analyticsAction="remove-associated-equipment"
            >
                <span class="fal fa-trash-alt"></span>
            </button>
        </ng-template>
    </static-table>
</div>

<div class="input-group">
    <ng-select
        [items]="availableEquipmentList$ | async"
        [searchFn]="equipmentSearch"
        bindLabel="name"
        bindValue="id"
        appendTo="body"
        [formControl]="addEquipment"
        placeholder="Select equipment to associate"
        [readonly]="working"
        [clearable]="false"
    >
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <equipment-list-item [item]="item"></equipment-list-item>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-index="index">
            <status-icon [equipment]="item" [clickable]="false"></status-icon>
        </ng-template>
    </ng-select>
    <span class="input-group-btn">
        <button
            type="button"
            class="btn btn-primary"
            [disabled]="working || !addEquipment.valid"
            (click)="associateEquipment()"
            analyticsOn
            analyticsAction="add-associated-equipment"
        >
            Add
        </button>
    </span>
</div>

<h4 class="section-heading">
    <span>Associated Battery Sets</span>
    <div></div>
</h4>
<div class="table-container-no-scroll">
    <loading-indicator [isLoading]="working"></loading-indicator>
    <static-table
        [availableColumns]="associatedBatterySetColumns"
        [tableData]="associatedBattery$ | async"
        [tableConfig]="associatedBatterySetTableConfig"
        [centeredHeadings]="true"
        [borderless]="true"
        scanability="alternate"
    >
        <ng-template columnTemplate="name" let-row="row" let-column="column">
            <status-icon
                [batterySet]="row"
                (nameClicked)="showBatterySet(row)"
            ></status-icon>
        </ng-template>

        <ng-template rowActions let-row="row">
            <button
                [disabled]="working"
                class="btn btn-tertiary btn-delete"
                type="button"
                (click)="removeAssociatedBatterySet(row.id)"
                tooltip="Remove associated battery set"
                placement="left"
                analyticsOn
                analyticsAction="remove-associated-battery-set"
            >
                <span class="fal fa-trash-alt"></span>
            </button>
        </ng-template>
    </static-table>
</div>
<div class="input-group">
    <ng-select
        [items]="availableBatterySetList$ | async"
        [searchFn]="batterySetSearch"
        bindLabel="name"
        bindValue="id"
        appendTo="body"
        [formControl]="addBatterySet"
        [readonly]="working"
        [clearable]="false"
    >
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <battery-set-list-item [item]="item"></battery-set-list-item>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-index="index">
            <status-icon [batterySet]="item" [clickable]="false"></status-icon>
        </ng-template>
    </ng-select>
    <span class="input-group-btn">
        <button
            type="button"
            class="btn btn-primary"
            [disabled]="working || !addBatterySet.valid"
            (click)="associateBatterySet()"
            analyticsOn
            analyticsAction="add-associated-battery-set"
        >
            Add
        </button>
    </span>
</div>
