import { Pipe, PipeTransform } from '@angular/core';
import { BatterySetDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatBatterySet' })
export class FormatBatterySetPipe implements PipeTransform {
    transform(batterySet: BatterySetDto) {
        if (!batterySet) {
            return null;
        }
        return batterySet.name;
    }
}
