/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.45-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CurrentAttachmentVersionDto } from './currentAttachmentVersionDto';
import { AuthorityActions } from './authorityActions';


/**
 * 
 */
export interface CraftAuthorityDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The type of this authority
     */
    authorityTypeId: number;
    /**
     * When the authority starts taking effect
     */
    startDate: string;
    /**
     * When does the authority expire
     */
    expiryDate?: string;
    /**
     * The identifier, such as a serial number, that this authority has
     */
    identifier?: string;
    /**
     * The list of documents attached to this authority
     */
    attachments: Array<CurrentAttachmentVersionDto>;
    /**
     * The RPA id
     */
    craftId: number;
    availableActions: AuthorityActions;
    /**
     * ${swagger.Authority.status}
     */
    status: CraftAuthorityDto.Status;
    /**
     * Has this authority been archived
     */
    archived: boolean;
    /**
     * Is the expiry of the authority coming up
     */
    expiryStatus: CraftAuthorityDto.ExpiryStatus;
    dependentOnAuthorityId?: number;
}
export namespace CraftAuthorityDto {
    export type Status = 'NEW' | 'RECORDED' | 'DELETED';
    export const Status = {
        NEW: 'NEW' as Status,
        RECORDED: 'RECORDED' as Status,
        DELETED: 'DELETED' as Status
    };
    export type ExpiryStatus = 'OK' | 'UPCOMING' | 'EXPIRED';
    export const ExpiryStatus = {
        OK: 'OK' as ExpiryStatus,
        UPCOMING: 'UPCOMING' as ExpiryStatus,
        EXPIRED: 'EXPIRED' as ExpiryStatus
    };
}



