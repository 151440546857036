import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentBubbleComponent } from './bubble/component-bubble.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ComponentBubbleComponent],
    exports: [ComponentBubbleComponent]
})
export class ComponentBubbleModule {}

export { ComponentBubbleComponent };
