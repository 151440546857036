import { parseCsvByHeadings, produceCsv } from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as FileSaver from 'file-saver';
import { NgSelectSearch } from 'libs/ng-select/src/lib/helpers';
import { ReplaySubject } from 'rxjs';

/**
 * Produce a template CSV blob that can be used to upload to this field config,
 * and display a save as dialogue.
 * @param field Formly field config
 */
export function downloadTemplate(field: FormlyFieldConfig) {
    const content = produceCsv(field, [{}]);
    const blob = new Blob([content], {
        type: 'text/csv;charset=utf-8'
    });
    FileSaver.saveAs(blob, `${field.props.label} - Template.csv`);
}

/**
 * Parse the provided CSV file according to the Formly field configuration.
 * @param file CSV file
 * @param field Formly field configuration
 */
export function parseCsvFile(
    file: File,
    field: FormlyFieldConfig,
    allowAnyKeyType = false
) {
    // Check if fieldArray is a function, if so, call it to get the actual configuration
    const fieldArray = <FormlyFieldConfig>field.fieldArray;

    // Now safely access fieldGroup
    const headingLookup = fieldArray?.fieldGroup?.reduce((acc, f) => {
        const value = allowAnyKeyType
            ? f.key
            : Number.parseInt(<string>f.key, 10);
        return {
            ...acc,
            [f.props.heading || f.props.label]: f.key
        };
    }, {} as { [heading: string]: any });

    return parseCsvByHeadings(file, headingLookup);
}

/**
 * A common interface for the Graph-QL scoped services passed to the formly-graphql-ng-select elements.
 * refreeshItems initialises the first page fetch from the API
 * onSelectScroll should trigger when the ng-select scrolls to bottom and in the service trigger pagination
 * onSelectSearch is linked to the ng-select box's search output
 * onSelectClose triggers when the ng-select is closed and clears the search values.
 */
export interface FormlyGraphQlPaginationService {
    refreshItems(originalValue?: any): (originalValue?: any) => any;
    onSelectScroll(): () => any;
    onSelectSearch(search: NgSelectSearch): (search: NgSelectSearch) => any;
    onSelectClose(): () => any;
    results$: ReplaySubject<any>;
    loading$: ReplaySubject<boolean>;
}
