import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    CreateShortcutLinkCommand,
    ShortcutLinkDto,
    UpdateShortcutLinkCommand
} from '../model/api';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class ShortcutLinkService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findShortCutLinksList(organisationId: number) {
        return this.http.get<ShortcutLinkDto[]>(
            `${this.baseUrl}/webapi/shortcutLinks`,
            {
                params: httpParamSerializer({
                    organisationId
                })
            }
        );
    }

    createShortCutLink(shortcutLink: CreateShortcutLinkCommand) {
        return this.http
            .post<ShortcutLinkDto>(
                `${this.baseUrl}/webapi/shortcutLinks`,
                shortcutLink
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateShortCutLink(
        shortCutLinkId: number,
        shortCutLink: UpdateShortcutLinkCommand
    ) {
        return this.http
            .put<ShortcutLinkDto>(
                `${this.baseUrl}/webapi/shortcutLinks/${shortCutLinkId}`,
                shortCutLink
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteShortCutLink(shortCutLinkId: number) {
        return this.http
            .delete(`${this.baseUrl}/webapi/shortcutLinks/${shortCutLinkId}`)
            .pipe(tap(() => this.changeSource.next()));
    }
}
