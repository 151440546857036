import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import {
    ActiveGridCellDto,
    OperationAuthorisation,
    PersonDto,
    SimpleAuthorityDto
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import {
    combineLatest,
    distinctUntilChanged,
    first,
    map,
    Observable,
    of,
    startWith,
    switchMap
} from 'rxjs';
import { CraftWithError } from '../interfaces';
import { AirspaceAuthorisationForm } from './airspace-authorisation-preview.service';

export function activeGridCellListValidator() {
    return (
        control: AbstractControl<ActiveGridCellDto[]>
    ): Observable<ValidationErrors | null> => {
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            control.valueChanges.pipe(startWith(control.value)),
            parentForm.controls.plannedMaxHeight.valueChanges.pipe(
                startWith(parentForm.controls.plannedMaxHeight.value)
            ),
            parentForm.controls.authorisationMaxHeight.valueChanges.pipe(
                startWith(parentForm.controls.authorisationMaxHeight.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            switchMap(
                ([gridCells, plannedMaxHeight, authorisationMaxHeight]) => {
                    if (
                        gridCells != null &&
                        plannedMaxHeight != null &&
                        authorisationMaxHeight != null &&
                        plannedMaxHeight > authorisationMaxHeight
                    ) {
                        return of({
                            activeGridCellList:
                                'Planned height exceeds GCD limitations'
                        });
                    }
                    return of(null);
                }
            ),
            first()
        );
    };
}

export function plannedMaxHeightValidator() {
    return (
        control: AbstractControl<number>
    ): Observable<ValidationErrors | null> => {
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            control.valueChanges.pipe(startWith(control.value)),
            parentForm.controls.activeGridCellList.valueChanges.pipe(
                startWith(parentForm.controls.activeGridCellList.value)
            ),
            parentForm.controls.authorisationMaxHeight.valueChanges.pipe(
                startWith(parentForm.controls.authorisationMaxHeight.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            switchMap(
                ([plannedMaxHeight, gridCells, authorisationMaxHeight]) => {
                    if (
                        gridCells != null &&
                        plannedMaxHeight != null &&
                        authorisationMaxHeight != null &&
                        plannedMaxHeight > authorisationMaxHeight
                    ) {
                        return of({
                            plannedMaxHeight: `Exceeds maximum GCD height of ${authorisationMaxHeight} ft`
                        });
                    }
                    return of(null);
                }
            ),
            first()
        );
    };
}

export function rpicValidator() {
    return (
        control: AbstractControl<PersonDto>
    ): Observable<ValidationErrors | null> => {
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            control.valueChanges.pipe(startWith(control.value)),
            parentForm.controls.rpicArn.valueChanges.pipe(
                startWith(parentForm.controls.rpicArn.value)
            ),
            parentForm.controls.rpicAuthLabel.valueChanges.pipe(
                startWith(parentForm.controls.rpicAuthLabel.value)
            ),
            parentForm.controls.authorisationErrors.valueChanges.pipe(
                startWith(parentForm.controls.authorisationErrors.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            switchMap(([rpic, arn, authLabel, errors]) => {
                // Handle API errors first
                if (errors == null) {
                    errors = {};
                }
                const jurisdictionIdentifierError =
                    errors['mission.missionCrew.rpic.jurisdictionIdentifier'];
                if (jurisdictionIdentifierError != null) {
                    return of({
                        rpic: jurisdictionIdentifierError.message
                    });
                }
                // Removing this temporarily as it will be handled asynchronously below
                // TODO: find which way of validation works best
                /* const missingRpicPhoneError =
                    errors['mission.missionCrew.rpic.phoneNumber'] ||
                    errors['remotePilotPhoneNumber'];
                if (missingRpicPhoneError != null) {
                    return of({
                        rpic: missingRpicPhoneError.message
                    });
                } */
                const missingRpicJurisdictionError =
                    errors['mission.missionCrew.rpic.jurisdiction'];
                if (missingRpicJurisdictionError != null) {
                    return of({
                        rpic: missingRpicJurisdictionError.message
                    });
                }

                const rpicRegistrationError =
                    errors.remotePilotRegistrationDetails;
                if (rpicRegistrationError != null) {
                    return of({
                        rpic: rpicRegistrationError.message
                    });
                }

                // Now handle UI form errors
                const error =
                    rpic == null
                        ? 'No remote pilot selected'
                        : rpic.phoneNumber == null
                        ? 'Missing phone number'
                        : // FIXME: this is temporary to get a valid LAANC approval
                          /* : arn == null
                        ? 'Remote pilot does not have a licence in this jurisdiction'
                        : authLabel == null
                        ? 'Remote pilot does not have a valid licence' */
                          null;
                if (error != null) {
                    return of({
                        rpic: error
                    });
                }
                return of(null);
            }),
            first()
        );
    };
}

export function delegatedAuthorityIdentifierValidator() {
    return (
        control: AbstractControl<SimpleAuthorityDto>
    ): ValidationErrors | null => {
        if (control.value == null || control.value.identifier == null) {
            return {
                delegatedAuthority: {
                    invalid: true,
                    message: 'No delegated authority certificate number'
                }
            };
        }
        return null;
    };
}

export function authorisationListValidator() {
    return (
        control: AbstractControl<OperationAuthorisation[]>
    ): ValidationErrors | null => {
        if (control.value == null || control.value.length === 0) {
            return {
                authorisationList: {
                    invalid: true,
                    message:
                        'There are no authorizations available for this mission.'
                }
            };
        }
        return null;
    };
}

export function operationAuthorisationsErrorsValidator() {
    return (
        control: AbstractControl<OperationAuthorisation[]>
    ): ValidationErrors | null => {
        const errors =
            control.value != null
                ? control.value.reduce(
                      (acc, op) =>
                          op.errors != null && Object.keys(op.errors).length > 0
                              ? acc.concat(op.errors)
                              : acc,
                      []
                  )
                : [];
        if (errors.length > 0) {
            return {
                authorisationList: { invalid: true, errors: errors }
            };
        }
        if (control.value == null || control.value?.length === 0) {
            return {
                authorisationList: {
                    invalid: true,
                    message:
                        'There are no authorizations available for this mission.'
                }
            };
        }
        return null;
    };
}

export function rpaListLengthValidator() {
    return (
        control: AbstractControl<CraftWithError[]>
    ): ValidationErrors | null => {
        if (control.value == null || control.value.length == 0) {
            return {
                delegatedAuthority: {
                    invalid: true,
                    message: 'No assigned RPA'
                }
            };
        }
        // FIXME: MTOW validation needs to be handled per-RPA,
        // meaning this should probably be a form array with a separate form control for each craft
        // const areMissingMtows = control.value.some(craft => craft.missingMtow);
        // if (areMissingMtows) {
        //     return {
        //         delegatedAuthority: {
        //             invalid: true,
        //             message: `${
        //                 control.value.length === 1
        //                     ? 'RPA is'
        //                     : 'One or more RPA are'
        //             } missing a MTOW`
        //         }
        //     };
        // }
        return null;
    };
}

export function rpaErrorsValidator() {
    return (
        control: AbstractControl<CraftWithError[]>
    ): Observable<ValidationErrors | null> => {
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            control.valueChanges.pipe(startWith(control.value)),
            parentForm.controls.authorisationErrors.valueChanges.pipe(
                startWith(parentForm.controls.authorisationErrors.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            switchMap(([craftList, errors]) => {
                // FIXME `errors` is coming back `null`. Not sure if this is because of the new service or an unhandled state
                if (errors?.rpaList) {
                    return of({
                        rpaList: errors.rpaList.message
                    });
                }
                return of(null);
            }),
            first()
        );
    };
}

export function startTimeValidator() {
    return (
        control: AbstractControl<string>
    ): Observable<ValidationErrors | null> => {
        const now = moment(new Date()).utc().toISOString();
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            control.valueChanges.pipe(startWith(control.value)),
            parentForm.controls.validatorStartTime.valueChanges.pipe(
                startWith(parentForm.controls.validatorStartTime.value)
            ),
            parentForm.controls.timeZone.valueChanges.pipe(
                startWith(parentForm.controls.timeZone.value)
            ),
            parentForm.controls.validatorCivilTwilightStart.valueChanges.pipe(
                startWith(parentForm.controls.validatorCivilTwilightStart.value)
            ),
            parentForm.controls.validatorCivilTwilightEnd.valueChanges.pipe(
                startWith(parentForm.controls.validatorCivilTwilightEnd.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            switchMap(([startControl, start, timeZone, dawn, dusk]) => {
                const startTime = moment(start);
                const timeDiff = startTime.diff(
                    moment.tz(now, timeZone),
                    'days'
                );
                const inRange = timeDiff < 30;
                const notPast = startTime.isAfter(moment.tz(now, timeZone));
                const startDayPass =
                    dawn != null && dusk != null
                        ? startTime.isBetween(moment(dawn), moment(dusk))
                        : true;

                const invalid = !inRange || !notPast || !startDayPass;
                const errorMessage = !inRange
                    ? 'Cannot be more than 30 days from now'
                    : !notPast
                    ? 'Start time has passed.'
                    : 'Start time should be within civil twilight hours';

                if (invalid) {
                    return of({
                        startTime: errorMessage
                    });
                }
                return of(null);
            }),
            first()
        );
    };
}
export function endTimeValidator() {
    return (
        control: AbstractControl<string>
    ): Observable<ValidationErrors | null> => {
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            control.valueChanges.pipe(startWith(control.value)),
            parentForm.controls.validatorEndTime.valueChanges.pipe(
                startWith(parentForm.controls.validatorEndTime.value)
            ),
            parentForm.controls.validatorStartTime.valueChanges.pipe(
                startWith(parentForm.controls.validatorStartTime.value)
            ),
            parentForm.controls.validatorCivilTwilightStart.valueChanges.pipe(
                startWith(parentForm.controls.validatorCivilTwilightStart.value)
            ),
            parentForm.controls.validatorCivilTwilightEnd.valueChanges.pipe(
                startWith(parentForm.controls.validatorCivilTwilightEnd.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            switchMap(([endControl, end, startTime, dawn, dusk]) => {
                const endTime = moment(end);
                const hoursDiff = endTime.diff(moment(startTime), 'hours');
                const endInRange = hoursDiff < 24;
                const endDayPass =
                    dawn != null && dusk != null
                        ? endTime.isBetween(moment(dawn), moment(dusk))
                        : true;

                const invalid = !endInRange || !endDayPass;
                const errorMessage = !endInRange
                    ? 'Approval window cannot be more than 24 hours'
                    : 'End time should be within civil twilight hours';

                if (invalid) {
                    return of({
                        endTime: errorMessage
                    });
                }
                return of(null);
            }),
            first()
        );
    };
}

export function civilTwilightValidator() {
    return (
        control: AbstractControl<string>
    ): Observable<ValidationErrors | null> => {
        const now = moment(new Date()).utc().toISOString();
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            parentForm.controls.validatorStartTime.valueChanges.pipe(
                startWith(parentForm.controls.validatorStartTime.value)
            ),
            parentForm.controls.validatorEndTime.valueChanges.pipe(
                startWith(parentForm.controls.validatorEndTime.value)
            ),
            parentForm.controls.validatorCivilTwilightStart.valueChanges.pipe(
                startWith(parentForm.controls.validatorCivilTwilightStart.value)
            ),
            parentForm.controls.validatorCivilTwilightEnd.valueChanges.pipe(
                startWith(parentForm.controls.validatorCivilTwilightEnd.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            map(([start, end, dawn, dusk]) => {
                const startTime = moment(start);
                const endTime = moment(end);
                const startDayPass =
                    dawn != null && dusk != null
                        ? startTime.isBetween(moment(dawn), moment(dusk))
                        : true;
                const endDayPass =
                    dawn != null && dusk != null
                        ? endTime.isBetween(moment(dawn), moment(dusk))
                        : true;

                const invalid = !startDayPass || !endDayPass;
                const errorMessage = !startDayPass
                    ? 'Start time should be within civil twilight hours'
                    : 'End time should be within civil twilight hours';
                if (invalid) {
                    return {
                        civilTwilight: errorMessage
                    };
                }
                return null;
            }),
            first()
        );
    };
}

export function airspaceValidator() {
    return (
        control: AbstractControl<string>
    ): Observable<ValidationErrors | null> => {
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        if (parentForm == null) {
            return of(null);
        }
        return combineLatest([
            control.valueChanges.pipe(startWith(control.value)),
            parentForm.controls.authorisationErrors.valueChanges.pipe(
                startWith(parentForm.controls.authorisationErrors.value)
            )
        ]).pipe(
            distinctUntilChanged(),
            switchMap(([airspace, errors]) => {
                if (airspace == null) {
                    const message = errors['airspace'] ?? '';
                    return of({
                        airspaceValidation: message
                    });
                }
                return of(null);
            }),
            first()
        );
    };
}

/**
 * A validator for the authorisationErrors array in the form
 * Accepts the individual form field identifier and
 * invalidates that field if there are any errors for that field or its descendants.
 *
 * If ignoreIfControlHasValue is true, the validator will only validate the field against the errors control
 * if the provided control name doesn't have a value.
 */
export function responseErrorsValidator(
    fieldRootName: string,
    ignoreIfControlHasValue?: string
) {
    return (
        control: AbstractControl<any>
    ): Observable<ValidationErrors | null> => {
        const parentForm = <FormGroup<AirspaceAuthorisationForm>>control.parent;
        const validateAgainstControl =
            ignoreIfControlHasValue != null
                ? <FormGroup<AirspaceAuthorisationForm>>(
                      control.parent?.controls[ignoreIfControlHasValue]
                  )
                : null;
        if (
            parentForm == null ||
            (validateAgainstControl != null &&
                validateAgainstControl.value != null)
        ) {
            return of(null);
        }
        return parentForm.controls.authorisationErrors.valueChanges.pipe(
            startWith(parentForm.controls.authorisationErrors.value),
            switchMap(errors => {
                const keyName = findErrorKeys(errors, fieldRootName);
                const message =
                    keyName != null ? findFirstError(errors[keyName]) : null;
                if (message != null) {
                    return of({
                        [fieldRootName]: message
                    });
                }
                return of(null);
            }),
            first()
        );
    };
}

export function acknowledgementValidator() {
    return (control: AbstractControl<boolean>): ValidationErrors | null => {
        if (control.value == null || control.value === false) {
            return {
                delegatedAuthority: {
                    invalid: true,
                    message: 'You must acknowledge this message to proceed'
                }
            };
        }
        return null;
    };
}

function findErrorKeys(errors: { [key: string]: any }, fieldRootName: string) {
    if (errors == null || Object.keys(errors).length === 0) {
        return null;
    }
    if (errors[fieldRootName] != null) {
        return fieldRootName;
    }
    if (Object.keys(errors).some(key => key === fieldRootName)) {
        const pathedKey = Object.keys(errors).filter(
            key => key === fieldRootName
        );
        return pathedKey[0];
    }
}

function findFirstError(errors: { [key: string]: any }) {
    if (errors.message != null && typeof errors.message === 'string') {
        return errors.message;
    }
    const descendants = Object.keys(errors).filter(
        key => key !== 'code' && key !== 'message'
    );
    if (descendants.length === 0) {
        return null;
    }
    const descendantErrors = descendants
        .map(d => findFirstError(errors[d]))
        .filter(e => e != null);
    if (descendantErrors.length === 0) {
        return null;
    } else {
        return descendantErrors[0];
    }
}
