export const flightConformanceParsedStates = [
    {
        name: 'No Breaches',
        value: 'NO_BREACHES'
    },
    {
        name: 'No Policy',
        value: 'NO_POLICY'
    },
    {
        name: 'Breaches',
        value: 'BREACHES'
    }
];
