import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { Angulartics2Module } from 'angulartics2';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColumnTemplateDirective } from './column-template.directive';
import { FlyFreelyTable } from './dynamic-table/flyfreely-table.component';
import { RowActionsDirective } from './row-actions.directive';
import { StaticTable } from './static-table/static-table.component';
import { RowSelector } from './static-table/row-selector.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        Angulartics2Module,
        SharedUiModule,
        TooltipModule,
        NgSelectModule,
        PopoverModule,
        EnhancedHelpModule,
        PaginationModule,
        BsDatepickerModule
    ],
    providers: [],
    bootstrap: [],
    declarations: [
        FlyFreelyTable,
        StaticTable,
        ColumnTemplateDirective,
        RowActionsDirective,
        RowSelector
    ],
    exports: [
        FlyFreelyTable,
        StaticTable,
        ColumnTemplateDirective,
        RowActionsDirective
    ]
})
export class FlyFreelyTableModule {}
