import { Injectable } from '@angular/core';
import {
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    PersonnelRegisterEntryExpiryDto,
    PersonnelRegisterExpirySummary,
    PersonsOrganisationDto,
    RpaRegisterEntryExpiryDto,
    RpaRegisterExpirySummary,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormatPersonPipe } from '@flyfreely-portal-ui/ui';
import {
    CurrentOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import {
    RegisterExpirySummary,
    toRegisterExpiriesStatusLookup
} from 'libs/authorities/src/lib/organisation-authority/organisation-authority-edit.service';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    forkJoin
} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface DisplayableRegisterExpiries {
    name: string;
    type: PersonnelRegisterExpirySummary.RegisterEntityType;
    serial: string;
    expiryDate: string;
    status: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
    registerId: number;
    authorityId: number;
    authorityName: string;
    authorityType: AuthorityTypeDto | OrganisationAuthorityGroup;
    authority: OrganisationAuthorityDto;
    registerName: string;
}

export const formatAuthorityExpiryWidgetStatuses = {
    [PersonnelRegisterEntryExpiryDto.ExpiryStatus.EXPIRED]: 'Expired',
    [PersonnelRegisterEntryExpiryDto.ExpiryStatus.UPCOMING]: 'Upcoming',
    [PersonnelRegisterEntryExpiryDto.ExpiryStatus.OK]: 'OK'
};

@Injectable()
export class AuthorityTaskWidgetsDataService {
    currentOrganisation: PersonsOrganisationDto;

    private currentOrganisationAuthoritiesSource = new BehaviorSubject<
        OrganisationAuthorityGroup[]
    >([]);
    private currentOrganisationAuthorityTypesSource = new BehaviorSubject<
        AuthorityTypeDto[]
    >([]);
    private authorityRegisterExpiriesSource = new BehaviorSubject<
        RegisterExpirySummary[]
    >([]);
    private registerExpirySummariesSource = new BehaviorSubject<
        DisplayableRegisterExpiries[]
    >([]);
    private workingSource = new BehaviorSubject<boolean>(false);

    private registerExpiries$ = new BehaviorSubject<
        (PersonnelRegisterExpirySummary | RpaRegisterExpirySummary)[]
    >([]);
    private registerExpiryLookups$ = new BehaviorSubject<{
        [registerId: number]: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
    }>(null);
    currentOrganisationAuthorities$ = this.currentOrganisationAuthoritiesSource.asObservable();
    currentOrganisationAuthorityTypes$ = this.currentOrganisationAuthorityTypesSource.asObservable();
    registerExpirySummaries$ = this.registerExpirySummariesSource.asObservable();
    authorityRegisterExpiries$ = this.authorityRegisterExpiriesSource.asObservable();
    working$ = this.workingSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private workspaceStateService: WorkspaceStateService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private logging: FlyFreelyLoggingService,
        private formatPersonPipe: FormatPersonPipe
    ) {
        this.workspaceStateService.currentOrganisation$
            .pipe(
                filter(
                    organisation => organisation.type === 'organisation_loaded'
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe((organisation: CurrentOrganisation) => {
                this.currentOrganisation = organisation.organisation;
                if (this.currentOrganisation != null) {
                    this.setupAuthorities();
                } else {
                    this.currentOrganisationAuthoritiesSource.next([]);
                    this.currentOrganisationAuthorityTypesSource.next([]);
                    this.authorityRegisterExpiriesSource.next([]);
                    this.registerExpirySummariesSource.next([]);
                }
            });

        combineLatest([
            this.currentOrganisationAuthorities$,
            this.currentOrganisationAuthorityTypes$,
            this.registerExpiries$,
            this.registerExpiryLookups$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    authorities,
                    authorityTypes,
                    registerExpiries,
                    registerExpiryLookups
                ]) => {
                    if (
                        registerExpiries != null &&
                        registerExpiryLookups != null
                    ) {
                        this.buildExpirySummaries();
                    }
                }
            );

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.workingSource.next(working);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workingSource.complete();
        this.currentOrganisationAuthoritiesSource.complete();
        this.registerExpiries$.complete();
        this.registerExpiryLookups$.complete();
        this.registerExpirySummariesSource.complete();
        this.currentOrganisationAuthorityTypesSource.complete();
        this.authorityRegisterExpiriesSource.complete();
    }

    setupAuthorities() {
        combineLatest([
            this.organisationAuthorityService.findAvailableAuthorityTypes(
                this.currentOrganisation.id
            ),
            this.organisationAuthorityService.findAuthorities(
                this.currentOrganisation.id,
                this.currentOrganisation.id
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([authorityTypes, authorities]) => {
                this.currentOrganisationAuthoritiesSource.next(authorities);
                this.currentOrganisationAuthorityTypesSource.next(
                    authorityTypes
                );
                const authoritiesWithRegisters = authorities.filter(
                    a =>
                        a.authorities?.length > 0 &&
                        a.authorities?.filter(
                            auth => auth?.registers.length > 0
                        ).length > 0
                );
                this.refreshRegisterExpiries(authoritiesWithRegisters);
            })
            .add(this.workTracker.createTracker());
    }

    refreshRegisterExpiries(authorityGroups: OrganisationAuthorityGroup[]) {
        // first extract all authorities that have registers from the authority groups
        const authorities: OrganisationAuthorityDto[] = authorityGroups.reduce(
            (acc, group) =>
                acc.concat(
                    group.authorities.filter(a => a?.registers.length > 0)
                ),
            []
        );

        forkJoin(
            authorities.reduce(
                (
                    acc: Observable<
                        | PersonnelRegisterExpirySummary
                        | RpaRegisterExpirySummary
                    >[],
                    auth
                ) =>
                    acc.concat(
                        auth.registers.map(r =>
                            r.registerEntityType ===
                            AuthorityRegisterSummaryDto.RegisterEntityType.PILOT
                                ? this.organisationAuthorityService.personnelRegisterExpiries(
                                      auth.id,
                                      r.id,
                                      this.currentOrganisation.id
                                  )
                                : this.organisationAuthorityService.aircraftRegisterExpiries(
                                      auth.id,
                                      r.id,
                                      this.currentOrganisation.id
                                  )
                        )
                    ),
                []
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: expiries => {
                    this.registerExpiries$.next(expiries);
                    this.registerExpiryLookups$.next(
                        toRegisterExpiriesStatusLookup(expiries)
                    );

                    const authorityRegisterExpiries = expiries.map(
                        registerExpiry => {
                            const authority = authorities.find(
                                auth =>
                                    auth?.registers.find(
                                        r => r.id === registerExpiry.id
                                    ) != null
                            );
                            const authorityGroup = authorityGroups.find(
                                a => a.id === authority.authorityTypeId
                            );
                            const authorityRegister = authority.registers.find(
                                r => r.id === registerExpiry.id
                            );
                            return {
                                authorityName: authorityGroup?.name ?? '',
                                authorityId: authority?.id ?? null,
                                registerName: registerExpiry.name,
                                registerId: registerExpiry.id,
                                registerMode: authorityRegister.registerMode,
                                overallStatus: this.registerExpiryLookups$.getValue()[
                                    registerExpiry.id
                                ],
                                registerEntries: registerExpiry.registerEntries,
                                register: registerExpiry
                            };
                        }
                    );
                    this.authorityRegisterExpiriesSource.next(
                        authorityRegisterExpiries
                    );
                },
                error: (error: FlyFreelyError) => this.logging.error(error)
            })
            .add(this.workTracker.createTracker());
    }

    buildExpirySummaries() {
        const authorities = this.currentOrganisationAuthoritiesSource.getValue();
        const expiries = this.registerExpiries$.getValue();
        const registerExpiries = this.registerExpiryLookups$.getValue();
        // Find all registerExpiries that are not OK.
        const upcomingAndExpired = Object.keys(registerExpiries)
            .filter(
                k =>
                    registerExpiries[k] !==
                    PersonnelRegisterEntryExpiryDto.ExpiryStatus.OK
            )
            .map(k => expiries.find(e => e.id === parseInt(k)));
        if (upcomingAndExpired.length === 0) {
            this.registerExpirySummariesSource.next([]);
            return;
        }
        // Create a list of all individually expiring entities on the registers
        const upcomingAndExpiredEntries = upcomingAndExpired.reduce(
            (
                acc: (
                    | PersonnelRegisterEntryExpiryDto
                    | RpaRegisterEntryExpiryDto
                )[],
                entry
            ) => {
                const relevantAuthorityRegister = authorities
                    .find(
                        auth =>
                            auth.authorities?.find(
                                a =>
                                    a.registers.find(r => r.id === entry.id) !=
                                    null
                            ) != null
                    )
                    .authorities.find(
                        a => a.registers.find(r => r.id === entry.id) != null
                    )
                    .registers.find(r => r.id === entry.id);
                // This strong typing keeps the compiler happy
                const withUpcomingOrExpired =
                    entry.registerEntityType ===
                    PersonnelRegisterExpirySummary.RegisterEntityType.PILOT
                        ? (<PersonnelRegisterExpirySummary>(
                              entry
                          )).registerEntries.filter(
                              e =>
                                  e.expiryStatus !==
                                  PersonnelRegisterEntryExpiryDto.ExpiryStatus
                                      .OK
                          )
                        : (<RpaRegisterExpirySummary>(
                              entry
                          )).registerEntries.filter(
                              e =>
                                  e.expiryStatus !==
                                  RpaRegisterEntryExpiryDto.ExpiryStatus.OK
                          );
                // Don't add the entries if the authority register is disabled.
                return relevantAuthorityRegister.registerMode !==
                    AuthorityRegisterSummaryDto.RegisterMode.DISABLED
                    ? acc.concat(withUpcomingOrExpired)
                    : acc;
            },
            []
        );
        // build the expiry data that will be rendered on the table
        const expirySummaries: DisplayableRegisterExpiries[] = upcomingAndExpiredEntries.reduce(
            (acc: DisplayableRegisterExpiries[], entry, index) => {
                const relevantRegisterExpiry = upcomingAndExpired.find(
                    e => e.id === entry.authorityRegisterId
                );
                const registerType = relevantRegisterExpiry.registerEntityType;
                const authorityGroup = authorities.find(
                    auth =>
                        auth.authorities &&
                        auth.authorities.find(
                            a =>
                                a.registers.find(
                                    r => r.id === relevantRegisterExpiry.id
                                ) != null
                        ) != null
                );
                const authority = authorityGroup.authorities.find(
                    a =>
                        a.registers.find(
                            r => r.id === relevantRegisterExpiry.id
                        ) != null
                );
                const authorityType = this.currentOrganisationAuthorityTypesSource
                    .getValue()
                    .find(a => a.id === authority.authorityTypeId);
                const displayableExpiry: DisplayableRegisterExpiries = {
                    type: registerType,
                    name:
                        registerType === 'PILOT'
                            ? this.formatPersonPipe.transform(
                                  (<PersonnelRegisterEntryExpiryDto>entry)
                                      .person
                              )
                            : (<RpaRegisterEntryExpiryDto>entry).rpa.nickname,
                    serial:
                        registerType === 'PILOT'
                            ? null
                            : (<RpaRegisterEntryExpiryDto>entry).rpa
                                  .manufacturerSerialNumber,
                    registerId: relevantRegisterExpiry.id,
                    registerName: relevantRegisterExpiry.name,
                    status: entry.expiryStatus,
                    expiryDate: entry.expiryDate,
                    authorityId: authority.id,
                    authorityType: authorityGroup,
                    authority: authority,
                    authorityName: authorityGroup.name
                };
                return acc.concat(displayableExpiry);
            },
            []
        );
        this.registerExpirySummariesSource.next(expirySummaries);
    }
}
