/**
 * Contains the interfaces used for change notification.
 */

export interface EntityUpdated<T> {
    type: 'UPDATE',
    entity: T
}

export interface EntityDeleted {
    type: 'DELETE',
    entityId: number
}

export type ChangeEvent<T> = EntityUpdated<T> | EntityDeleted;

export function entityChanged<T>(entity: T): EntityUpdated<T> {
    return {
        type: 'UPDATE',
        entity
    }
}

export function entityDeleted(entityId: number): EntityDeleted {
    return {
        type: 'DELETE',
        entityId
    }
}
