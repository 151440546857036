<ng-container>
    <div class="fill">
        <ng-container *ngIf="preferences || !working">
            <h4 class="section-heading">
                <span>Mailing Lists</span>
                <div></div>
            </h4>
            <enhanced-help
                componentName="mailing-list-preferences"
                [compact]="true"
            >
            </enhanced-help>
            <div>
                <table class="table" style="width: 100%">
                    <tbody>
                        <tr *ngFor="let preference of preferences">
                            <td style="width: 15%">
                                <label style="margin-bottom: 0">
                                    {{ preference.name }}
                                </label>
                            </td>
                            <td style="width: 45%">
                                {{ preference.description }}
                            </td>
                            <td style="width: 20%">
                                <div mdcSwitch>
                                    <div mdcSwitchThumb>
                                        <input
                                            type="checkbox"
                                            mdcSwitchInput
                                            [(ngModel)]="preference.subscribed"
                                            (change)="
                                                updateSubscriptionPreference(
                                                    preference
                                                )
                                            "
                                            [disabled]="working"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <div
            *ngIf="(!preferences || preferences.length === 0) && !working"
            class="left-buffer"
        >
            No available mailing list preferences to display
        </div>
    </div>
</ng-container>
