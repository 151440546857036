import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MissionWorkflowList } from './mission-workflow-list/mission-workflow-list.component';
import { WorkflowDialoguesService } from './workflow-dialogues.service';
import { WorkflowEditDialogue } from './workflow-edit/workflow-edit-dialogue.component';
import { WorkflowStepDetails } from './workflow-step-details/workflow-step-details.component';
import { SharedUiModule } from '@flyfreely-portal-ui/ui';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule,
        SharedUiModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        TabsModule,
        NgSelectModule,
        TooltipModule,
        DragDropModule,
        MaterialModule,
        EnhancedHelpModule,
        AnalyticsModule
    ],
    providers: [WorkflowDialoguesService],
    bootstrap: [],
    declarations: [
        MissionWorkflowList,
        WorkflowEditDialogue,
        WorkflowStepDetails
    ],
    exports: [MissionWorkflowList, WorkflowStepDetails]
})
export class WorkflowModule {}
