import { Directive, HostBinding, HostListener } from '@angular/core';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    defaultKnowledgeBaseUrl,
    KnowledgeBaseService
} from './knowledge-base-link.service';

@Directive({
    selector: 'a[knowledgeBase]'
})
export class KnowledgeBaseLinkDirective {
    @HostBinding('target')
    target = '_blank';

    @HostBinding('href')
    href = defaultKnowledgeBaseUrl;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private screenAnalytics: ScreenAnalyticsDirective,
        private knowledgeBaseService: KnowledgeBaseService
    ) {}

    ngOnInit() {
        this.knowledgeBaseService
            .findKnowledgeBaseUrlByScreen(this.screenAnalytics.screen)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                link =>
                    (this.href =
                        link?.knowledgeBaseUrl ?? defaultKnowledgeBaseUrl)
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    @HostListener('click')
    click() {
        this.screenAnalytics.track('help', 'knowledge-base');
    }
}
