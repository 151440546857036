/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.34
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The collection source used to collect this flight log file
 */
export interface SimpleFlightLogCollectionSourceDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The type of collection source
     */
    type: SimpleFlightLogCollectionSourceDto.Type;
    /**
     * The username, if any, associated with this collection source
     */
    username?: string;
}
export namespace SimpleFlightLogCollectionSourceDto {
    export type Type = 'DJI_CLOUD' | 'DRONE_HARMONY' | 'DRONE_DEPLOY' | 'SKYDIO';
    export const Type = {
        DJI_CLOUD: 'DJI_CLOUD' as Type,
        DRONE_HARMONY: 'DRONE_HARMONY' as Type,
        DRONE_DEPLOY: 'DRONE_DEPLOY' as Type,
        SKYDIO: 'SKYDIO' as Type
    };
}


