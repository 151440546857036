import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationSubscriptionDto,
    PersonsOrganisationDto,
    SubscriptionDetailsDto,
    SubscriptionPlan,
    SubscriptionService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import {
    OrganisationSubscriptionState,
    SubscriptionAndDetails
} from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PlanSelectionService } from '../plan-selection/plan-selection.service';
import {
    AvailableSubscriptionPlans,
    PurchaseSubscriptionService,
    SubscriptionPlanSelection
} from './purchase-subscription.service';

@Component({
    selector: 'purchase-subscription',
    templateUrl: './purchase-subscription.component.html',
    styleUrls: ['./purchase-subscription.styles.scss'],
    providers: [
        PurchaseSubscriptionService,
        {
            provide: PlanSelectionService,
            useExisting: PurchaseSubscriptionService
        }
    ]
})
export class PurchaseSubscription {
    @Input() organisation: PersonsOrganisationDto;

    @Input() subscriptionId?: number;

    @Input() showUpgrade = false;

    @Input() subscriptionDetails?: SubscriptionAndDetails;

    @Output() onHide = new EventEmitter<void>();

    @Output() uptrialSelected = new EventEmitter<void>();

    renewalPeriodAnnually = false;

    private ngUnsubscribe$ = new Subject<void>();

    selectedPlan: SubscriptionPlanSelection;

    requiredFeatureFlag: string;
    featureName: string;
    featuredPlan: SubscriptionPlanSelection;

    currentSubscription: OrganisationSubscriptionDto;
    currentPlan: SubscriptionPlanSelection;
    currentSubscriptionDetails: SubscriptionDetailsDto;

    trialUpPlan: string;

    showContactSales = false;

    plans: AvailableSubscriptionPlans;

    salesContacted = false;

    currentLicenceCount?: number;

    working: boolean;
    private workTracker = new WorkTracker();
    constructor(
        private purchaseSubscriptionService: PurchaseSubscriptionService,
        private subscriptionService: SubscriptionService,
        private subscriptionState: OrganisationSubscriptionState,
        private userService: UserService,

        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private angulartics2: Angulartics2,
        private activatedRoute: ActivatedRoute
    ) {
        combineLatest([
            this.purchaseSubscriptionService.working$,
            this.workTracker.observable
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([serviceWorking, working]) =>
                    (this.working = serviceWorking || working)
            );
    }

    ngOnInit() {
        this.requiredFeatureFlag = this.activatedRoute.snapshot.params.feature;
        this.featureName =
            this.activatedRoute.snapshot.params.featureName ??
            'The selected feature';

        this.subscriptionState.subscription$
            .pipe(
                filter(subscription => subscription != null),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(subscription => {
                this.trialUpPlan = subscription.availableTrialUp?.name;
            });

        this.purchaseSubscriptionService.availablePlans$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(plans => {
                this.plans = plans;
                if (this.showUpgrade && this.subscriptionDetails != null) {
                    this.currentSubscriptionDetails =
                        this.subscriptionDetails.details;
                    this.currentSubscription =
                        this.subscriptionDetails.subscription;

                    this.currentLicenceCount =
                        this.currentSubscription?.limits?.[0]?.instances;

                    this.findCurrentPlan();
                } else {
                    this.currentPlan = plans.freePlan;
                    this.currentLicenceCount = 1;
                }
                this.findFeaturedPlan();
            });

        this.purchaseSubscriptionService.setup(
            this.organisation.id,
            this.subscriptionId
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    contactSales() {
        const doneWorking = this.workTracker.createTracker();

        this.commonDialoguesService
            .showFormlyDialogue(
                'Contact Sales',
                'Contact',
                true,
                true,
                [
                    {
                        key: 'message',
                        type: 'textarea',
                        props: { label: 'Message', required: true }
                    }
                ],
                {
                    message: `I would like to upgrade my organisation ${this.organisation.name}`
                },
                (data: { message: string }) =>
                    this.userService
                        .contactSales({ message: data.message })
                        .toPromise()
            )
            .then(
                result => {
                    this.salesContacted = true;
                    this.logging.success(`Sales will be in touch shortly`);
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    if (error != null) {
                        this.logging.error(
                            error,
                            `There was an error, please use the in app chat`
                        );
                    }
                    doneWorking();
                }
            )
            .catch(() => doneWorking());
    }

    findCurrentPlan() {
        if (
            this.plans == null ||
            this.currentSubscription == null ||
            this.subscriptionDetails == null ||
            this.currentSubscription?.featureSet?.name == null
        ) {
            this.currentPlan == null;
            return;
        }
        const period = SubscriptionPlan.RenewalPeriod;
        switch (
            this.currentSubscriptionDetails.subscriptionPlan.renewalPeriod
        ) {
            case period.MONTHLY:
                this.currentPlan =
                    this.plans.monthlyPlan.find(
                        plan => plan.name === this.currentSubscription.name
                    ) ?? this.plans.freePlan;
                break;

            case period.ANNUAL:
                this.currentPlan =
                    this.plans.annualPlan.find(
                        plan => plan.name === this.currentSubscription.name
                    ) ?? this.plans.freePlan;
                break;

            default:
                this.currentPlan = this.plans.freePlan;
                break;
        }
    }

    findFeaturedPlan() {
        if (
            this.requiredFeatureFlag == null ||
            typeof this.requiredFeatureFlag !== 'string'
        ) {
            return;
        }
        const monthlyPlan = this.plans.monthlyPlan.find(p =>
            p.featureSetList.includes(this.requiredFeatureFlag)
        );
        const annualPlan = this.plans.annualPlan.find(p =>
            p.featureSetList.includes(this.requiredFeatureFlag)
        );
        const featured = this.renewalPeriodAnnually ? annualPlan : monthlyPlan;
        if (featured == null) {
            this.showContactSales = true;
        } else {
            this.featuredPlan = featured;
            this.selectPlan(featured);
        }
    }

    trialUpSubscription() {
        this.angulartics2.eventTrack.next({
            action: 'user-view-uptrial',
            properties: {
                category: 'subscriptions',
                label: this.subscriptionDetails.details.subscriptionPlan
                    .identifier,
                value: this.subscriptionDetails.subscription.availableTrialUp
                    .name
            }
        });
        this.commonDialoguesService
            .showConfirmationDialogue(
                `Start a ${this.trialUpPlan} Trial`,
                `Start a short trial that allows you to preview the next subscription tier and the features included with that tier.<br><br>Once the trial ends your current subscription will resume.<br><br>Would you like to trial the next tier?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => {
                this.angulartics2.eventTrack.next({
                    action: 'user-uptrial-subscription',
                    properties: {
                        category: 'subscriptions',
                        label: this.subscriptionDetails.details.subscriptionPlan
                            .identifier,
                        value: this.subscriptionDetails.subscription
                            .availableTrialUp.name
                    }
                });
                this.subscriptionService
                    .trialUpSubscription(this.subscriptionId)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(
                        result => {
                            this.subscriptionState.refresh(
                                this.organisation.id
                            );
                            this.logging.success(
                                `Started ${this.trialUpPlan} trial`
                            );
                            this.uptrialSelected.emit();
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while setting up trial for ${this.trialUpPlan}: ${error.message}`
                            );
                        }
                    )
                    .add(this.workTracker.createTracker());
            }, DO_NOTHING);
    }

    selectPlan(plan: SubscriptionPlanSelection) {
        this.selectedPlan = plan;
    }

    toggleRenewalPeriod() {
        this.renewalPeriodAnnually = !this.renewalPeriodAnnually;
        this.findFeaturedPlan();
    }
}
