import { Injectable } from '@angular/core';
import {
    ChangeEvent,
    LibraryDto,
    LibraryService
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { firstValueFrom, map } from 'rxjs';

const fields: FormlyFieldConfig[] = [
    {
        key: 'name',
        type: 'input',
        props: {
            label: 'Name',
            required: true,
            minLength: 2
        }
    },
    {
        key: 'description',
        type: 'input',
        props: {
            label: 'Description'
        }
    }
];

@Injectable()
export class LibraryDialoguesService {
    constructor(
        private libraryService: LibraryService,
        private commonDialoguesService: CommonDialoguesService
    ) {}

    createLibrary(organisationId: number): Promise<LibraryDto> {
        return this.commonDialoguesService.showFormlyDialogue<
            { name: string; description?: string },
            LibraryDto
        >('Create Library', 'Create', false, true, fields, {}, library =>
            firstValueFrom(
                this.libraryService.createLibrary({
                    ...library,
                    organisationId: organisationId
                })
            )
        );
    }

    editLibrary(library: LibraryDto): Promise<ChangeEvent<LibraryDto>> {
        return this.commonDialoguesService.showFormlyDialogue(
            'Update Library',
            'Update',
            false,
            true,
            fields,
            library,
            newLibrary =>
                firstValueFrom(
                    this.libraryService
                        .updateLibrary(library.id, newLibrary)
                        .pipe(
                            map(library => ({
                                type: 'UPDATE',
                                entity: library
                            }))
                        )
                ),
            undefined,
            undefined,
            {
                label: 'Delete',
                fn: () =>
                    this.commonDialoguesService.showConfirmationDialogue(
                        'Delete Library',
                        `Are you sure you want to delete ${library.name}?`,
                        'Delete',
                        () =>
                            firstValueFrom(
                                this.libraryService
                                    .deleteLibrary(library.id)
                                    .pipe(
                                        map(() => ({
                                            type: 'DELETE',
                                            entityId: library.id
                                        }))
                                    )
                            )
                    )
            }
        );
    }
}
