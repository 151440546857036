import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    BatterySetDto,
    BatterySetService,
    Cancellable,
    Organisation,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NoteType } from '../batteries-notes/notes-edit/notes-edit.component';

@Component({
    selector: 'battery-set-details',
    templateUrl: './battery-set-details.component.html'
})
export class BatterySetDetails implements OnInit {
    @Input() currentOrganisation: Organisation;
    @Input() batterySetId: number;
    @Input() managingOrganisationId: number;

    batterySet: BatterySetDto;
    @ViewChild('batterySetTabs', { static: true })
    batterySetTabs: TabsetComponent;

    @ViewChild('batterySetEdit', { static: false }) batterySetEdit: Cancellable;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    isEdit: boolean = false;
    constructor(
        private modal: BsModalRef<BatterySetDetails>,
        modalOptions: ModalOptions,
        private batterySetService: BatterySetService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        modalOptions.closeInterceptor = () => {
            if (this.isEdit) {
                this.batterySetEdit.cancel();
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.refreshBatterySet();

        this.batterySetService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshBatterySet());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshBatterySet() {
        const doneWorking = this.workTracker.createTracker();

        this.batterySetService
            .findBatterySet(this.batterySetId, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(batterySet => (this.batterySet = batterySet))
            .finally(doneWorking);
    }

    toggleViewEdit() {
        this.isEdit = !this.isEdit;
        this.disableEnableTabs();
    }

    disableEnableTabs() {
        for (let i = 0; i < this.batterySetTabs.tabs.length; i++) {
            this.batterySetTabs.tabs[i].disabled =
                !this.batterySetTabs.tabs[i].disabled;
        }
    }

    onUpdate(batterySet: BatterySetDto) {
        this.batterySet = Object.assign({}, batterySet);
        this.toggleViewEdit();
    }

    close() {
        this.modal.hide();
    }

    onDeleted() {
        this.modal.hide();
    }

    protected readonly NoteType = NoteType;
}
