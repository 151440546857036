import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AcknowledgeRuleResultCommand,
    FlightConformanceResultDto,
    FlightConformanceRuleResultDto
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import { httpParamSerializer } from './service.helpers';

export const flightConformanceStatusLookup = {
    [FlightConformanceResultDto.Status.CANCELLED]: 'Cancelled',
    [FlightConformanceResultDto.Status.NO_BREACHES]: 'No Breaches',
    [FlightConformanceResultDto.Status.NO_POLICY]: 'No Policy',
    [FlightConformanceResultDto.Status.PRELIMINARY]: 'Preliminary',
    [FlightConformanceResultDto.Status.SENT]: 'Sent',
    [FlightConformanceResultDto.Status.COMPLETED]: 'Completed',
    [FlightConformanceResultDto.Status.FINALISED]: 'Finalised'
};

export const flightConformanceTypeList = [
    {
        name: 'Maximum Height (ft)',
        value: FlightConformanceRuleResultDto.Type.MAXIMUM_HEIGHT
    },
    {
        name: 'Visual Distance (m)',
        value: FlightConformanceRuleResultDto.Type.VISUAL_DISTANCE
    },
    {
        name: 'Flight Area',
        value: FlightConformanceRuleResultDto.Type.FLIGHT_AREA
    },
    {
        name: 'Landing Battery Level (%)',
        value: FlightConformanceRuleResultDto.Type.LANDING_BATTERY_LEVEL
    }
];

@Injectable({
    providedIn: 'root'
})
export class FlightConformanceResultsService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(missionId: number, managingOrganisationId: number) {
        return this.http.get<FlightConformanceResultDto[]>(
            `${this.baseUrl}/webapi/flightConformanceResults`,
            {
                params: httpParamSerializer({
                    missionId,
                    managingOrganisationId
                })
            }
        );
    }

    acknowledgeRuleResult(flightConformanceRuleResultId: number, command: AcknowledgeRuleResultCommand) {
        return this.http
            .post<FlightConformanceResultDto>(
                `${this.baseUrl}/webapi/flightConformanceRuleResults/${flightConformanceRuleResultId}/acknowledge`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    finaliseResult(flightConformanceResultId: number) {
        return this.http
            .post<FlightConformanceResultDto>(
                `${this.baseUrl}/webapi/flightConformanceResults/${flightConformanceResultId}/finalise`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    unfinaliseResult(flightConformanceResultId: number) {
        return this.http
            .post<FlightConformanceResultDto>(
                `${this.baseUrl}/webapi/flightConformanceResults/${flightConformanceResultId}/unfinalise`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    cancelResult(flightConformanceResultId: number) {
        return this.http
            .post<FlightConformanceResultDto>(
                `${this.baseUrl}/webapi/flightConformanceResults/${flightConformanceResultId}/cancel`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
