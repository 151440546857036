<form [formGroup]="formGroup">
    <ng-container
        *ngIf="
            !showSimplifiedOutcome ||
            airspaceStatus === 'CHECKING' ||
            airspaceStatus === 'IDLE'
        "
    >
        <div
            *ngIf="isDrawing"
            class="airspace-check-button incomplete"
            (click)="stopDrawing()"
        >
            <span class="fa fa-radar"></span>
            &nbsp;&nbsp;Cancel Airspace Check
        </div>
        <div
            *ngIf="!isDrawing"
            class="airspace-check-button"
            [ngClass]="{
                idle: airspaceStatus == 'IDLE',
                incomplete:
                    airspaceStatus == 'INCOMPLETE' ||
                    airspaceStatus == 'CHECKING',
                'can-fly': airspaceStatus == 'CAN_FLY',
                'can-fly-conditions': airspaceStatus == 'CAN_FLY_CONDITIONS',
                'cannot-fly': airspaceStatus == 'CANNOT_FLY'
            }"
            (click)="airspaceCheckClick()"
            [ngSwitch]="airspaceStatus"
        >
            <ng-container *ngSwitchCase="'IDLE'">
                <span class="fa fa-radar"></span>
                <ng-container
                    *ngIf="toggleCheck && firstCheck; else standardIdleText"
                >
                    &nbsp;&nbsp;Start Airspace Check
                </ng-container>
                <ng-template #standardIdleText
                    >&nbsp;&nbsp;Airspace Check</ng-template
                >
            </ng-container>
            <ng-container *ngSwitchCase="'INCOMPLETE'">
                {{
                    canCheckAirspace
                        ? 'Airspace Check Incomplete'
                        : 'No Airspace Check Available'
                }}
            </ng-container>
            <ng-container *ngSwitchCase="'CHECKING'">
                <span class="fa fa-spinner fa-pulse fa-fw"></span>
                &nbsp;&nbsp;Checking
            </ng-container>
            <ng-container *ngSwitchCase="'CAN_FLY'">
                <span class="fad fa-check-circle"></span>
                &nbsp;&nbsp;Airspace is good to fly
            </ng-container>
            <ng-container *ngSwitchCase="'CAN_FLY_CONDITIONS'">
                <span class="fad fa-exclamation-circle"></span>
                &nbsp;&nbsp;Airspace Warning
            </ng-container>
            <ng-container *ngSwitchCase="'CANNOT_FLY'">
                <span class="fad fa-times-circle"></span>
                &nbsp;&nbsp;Airspace has issues
            </ng-container>
        </div>
    </ng-container>
    <p
        *ngIf="
            canCheckAirspace &&
            !hasFlightArea &&
            !useSimplified &&
            ((toggleCheck && isDrawing) || !toggleCheck)
        "
    >
        <em>{{ emptyStateMessage }}</em>
    </p>
    <div
        *ngIf="
            toggleCheck &&
            !isDrawing &&
            !firstCheck &&
            airspaceStatus !== 'CHECKING'
        "
        class="horizontal-container space-between"
    >
        <span
            type="button"
            class="btn btn-link btn-xs"
            (click)="startDrawing()"
        >
            Start another check
        </span>
        <jurisdiction-links *ngIf="!firstCheck"></jurisdiction-links>
    </div>
    <ng-container *ngIf="!(toggleCheck && isDrawing)">
        <div
            *ngIf="
                showSimplifiedOutcome &&
                airspaceStatus !== 'CHECKING' &&
                airspaceStatus !== 'IDLE'
            "
            class="caa-button authorisation-unavailable"
            [ngClass]="{
                'authorisation-unavailable':
                    airspaceStatus == 'INCOMPLETE' ||
                    caaAuthorisationStatus == 'UNKNOWN',
                'authorisation-available':
                    caaAuthorisationStatus == 'AUTHORISATION_REQUIRED',
                'authorisation-incomplete':
                    caaAuthorisationStatus == 'AUTHORISATION_NOT_REQUIRED'
            }"
        >
            <span class="fa fa-radar"></span>
            &nbsp;&nbsp;Airspace Check
        </div>
        <div
            class="airspace-check-result-container modal-scrollbar"
            [ngClass]="{
                dashboard: isDashboardChecker,
                map: viewMode == 'MAP',
                both: viewMode == 'BOTH'
            }"
        >
            <div
                *ngIf="airspaceInitialised && formGroup != null"
                [collapse]="editingAirspaceParameters || !showAirspaceChecker"
                [isAnimated]="true"
                class="top-buffer bottom-buffer"
                style="padding: 0 15px"
            >
                <span
                    class="btn btn-xs btn-link"
                    [ngClass]="{ 'btn-expired': isInPast }"
                    (click)="
                        editingAirspaceParameters = !editingAirspaceParameters
                    "
                    [tooltip]="
                        isInPast
                            ? 'The date used for this airspace check is in the past. The check results may be inaccurate or incorrect.'
                            : ''
                    "
                    placement="top"
                >
                    {{
                        formGroup?.controls?.airspaceDate?.value
                            | formatDateTime: formGroup?.value?.airspaceTimeZone
                    }}
                    {{
                        formGroup?.value?.airspaceTimeZone
                            ? ''
                            : checkerTimeZoneAbbreviation
                    }}
                </span>
                <span
                    class="btn btn-xs btn-link"
                    (click)="
                        editingAirspaceParameters = !editingAirspaceParameters
                    "
                >
                    {{ formGroup?.controls?.airspaceDuration?.value / 3600 }}
                    {{
                        formGroup?.controls?.airspaceDuration?.value / 3600 == 1
                            ? 'hour'
                            : 'hours'
                    }}
                </span>
                <span
                    class="btn btn-xs btn-link"
                    (click)="
                        editingAirspaceParameters = !editingAirspaceParameters
                    "
                >
                    {{
                        formGroup?.controls?.airspaceRuleset?.value
                            ?.abbreviation ||
                            formGroup?.controls?.airspaceRuleset?.value?.name ||
                            '-'
                    }}
                </span>
                <span
                    class="btn btn-xs btn-link"
                    (click)="
                        editingAirspaceParameters = !editingAirspaceParameters
                    "
                >
                    {{ formGroup?.controls?.airspaceHeight?.value }}&nbsp;ft AGL
                </span>
                <span
                    *ngIf="radius"
                    class="btn btn-xs btn-link"
                    (click)="
                        editingAirspaceParameters = !editingAirspaceParameters
                    "
                >
                    {{ formGroup?.controls?.radius?.value }}&nbsp;m
                </span>
            </div>
            <div
                *ngIf="formGroup != null"
                [collapse]="!editingAirspaceParameters || !showAirspaceChecker"
                [isAnimated]="true"
                class="container-scroll modal-scrollbar top-buffer"
            >
                <div>
                    <div class="hotizontal-container vertical-align">
                        <label class="control-label right-buffer">
                            Planned Time
                        </label>
                        <span
                            class="btn btn-xs btn-pill left-buffer"
                            style="margin-bottom: 5px"
                            (click)="setTimeToNow()"
                        >
                            Set to now
                        </span>
                    </div>
                    <field-validation-indicator
                        validatorFormControlName="airspaceDate"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            class="form-control"
                            type="text"
                            required
                            #datepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="airspaceDate"
                            [readonly]="working"
                            [isDisabled]="working"
                        />
                        <span
                            *ngIf="!working"
                            class="input-group-addon btn-default"
                            (click)="datepicker.toggle()"
                            [attr.aria-expanded]="datepicker.isOpen"
                        >
                            <i class="glyphicon glyphicon-calendar"></i>
                        </span>
                    </div>
                    <div
                        class="horizontal-container vertical-align"
                        style="width: fit-content"
                    >
                        <timepicker
                            formControlName="airspaceDate"
                            [hourStep]="1"
                            [minuteStep]="1"
                            [showMeridian]="false"
                            [showSpinners]="false"
                            [readonlyInput]="working"
                        >
                        </timepicker>
                        &nbsp;{{ checkerTimeZoneAbbreviation }}
                    </div>
                </div>
                <div>
                    <label class="control-label"> Duration </label>
                    <field-validation-indicator
                        validatorFormControlName="airspaceDuration"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <duration
                        formControlName="airspaceDuration"
                        [readonly]="working"
                        [showHour]="true"
                        [showMinute]="false"
                        [showSecond]="false"
                    >
                    </duration>
                </div>
                <div>
                    <label class="control-label"> Ruleset </label>
                    <field-validation-indicator
                        validatorFormControlName="airspaceRuleset"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <ng-select
                        [items]="airspaceRulesetLookup"
                        [readonly]="working"
                        [clearable]="false"
                        bindLabel="name"
                        appendTo="body"
                        formControlName="airspaceRuleset"
                    >
                    </ng-select>
                </div>
                <div class="bottom-buffer">
                    <label class="control-label"> Maximum Height </label>
                    <field-validation-indicator
                        validatorFormControlName="airspaceHeight"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            name="maximumHeight"
                            type="number"
                            class="form-control"
                            placeholder="The maximum flight height of the mission above ground level"
                            min="0"
                            step="1"
                            formControlName="airspaceHeight"
                            [readonly]="working"
                        />
                        <span class="input-group-addon">ft AGL</span>
                    </div>
                </div>
                <div *ngIf="radius" class="bottom-buffer">
                    <label class="control-label"> Check Radius </label>
                    <field-validation-indicator
                        validatorFormControlName="radius"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            name="radius"
                            type="number"
                            class="form-control"
                            placeholder="The radius of the check area"
                            min="1"
                            step="1"
                            formControlName="radius"
                            [readonly]="working"
                        />
                        <span class="input-group-addon">m</span>
                    </div>
                </div>
                <!-- Using a label here to bypass the map style overrides for buttons -->
                <label
                    *ngIf="!working && !formGroup.invalid"
                    type="button"
                    class="btn btn-primary btn-sm btn-block"
                    (click)="updateAirspaceCheckCommand()"
                >
                    Update
                </label>
                <label
                    *ngIf="working || formGroup.invalid"
                    type="button"
                    class="btn btn-primary btn-sm btn-block disabled"
                    style="color: #fff !important"
                    (click)="updateAirspaceCheckCommand()"
                    [tooltip]="
                        formGroup.controls.airspaceDuration.invalid
                            ? 'The check duration has to be more than 0 and less than 24 hours'
                            : formGroup.controls.airspaceDate.invalid
                            ? 'The selected date and time is in the past'
                            : ''
                    "
                    placement="left"
                >
                    Update
                </label>
            </div>
            <div
                *ngIf="!useSimplified"
                [collapse]="editingAirspaceParameters || !showAirspaceChecker"
                [isAnimated]="true"
                class="container-scroll modal-scrollbar"
            >
                <airspace-check
                    #airspaceCheck
                    screenIdentifier="location-edit"
                    style="justify-content: center"
                    [hideHeader]="true"
                    linkPosition="NONE"
                ></airspace-check>
            </div>
            <div
                *ngIf="
                    useSimplified &&
                    showSimplifiedOutcome &&
                    airspaceStatus != 'IDLE' &&
                    airspaceStatus != 'CHECKING'
                "
                class="authorisation-details authorisation-incomplete"
                [ngClass]="{
                    'authorisation-unavailable':
                        airspaceStatus == 'INCOMPLETE' ||
                        caaAuthorisationStatus == 'UNKNOWN',
                    'authorisation-available':
                        caaAuthorisationStatus == 'AUTHORISATION_REQUIRED',
                    'authorisation-incomplete':
                        caaAuthorisationStatus == 'AUTHORISATION_NOT_REQUIRED'
                }"
                style="cursor: unset"
                [ngSwitch]="caaAuthorisationStatus"
            >
                <ng-container *ngSwitchCase="'AUTHORISATION_REQUIRED'">
                    Authorisation is required for this mission
                </ng-container>
                <ng-container *ngSwitchCase="'AUTHORISATION_NOT_REQUIRED'">
                    Authorisation not required but available and recommended for
                    this location
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="airspaceStatus == 'INCOMPLETE'">
                        Location data incomplete
                    </ng-container>
                    <ng-container *ngIf="airspaceStatus != 'INCOMPLETE'">
                        Authorisation availability and requirements can not be
                        determined for this location at this time
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div
            [collapse]="editingAirspaceParameters || showAirspaceChecker"
            [isAnimated]="true"
        >
            <div class="contents horizontal-container space-between top-buffer">
                <label
                    tooltip="The date and time of the current airspace result and map data"
                    placement="left"
                    >{{ checkerTime }}</label
                >
                <jurisdiction-links *ngIf="firstCheck"></jurisdiction-links>
            </div>
        </div>
    </ng-container>
    <button
        *ngIf="airspaceStatus !== 'IDLE' && airspaceStatus !== 'INCOMPLETE'"
        type="button"
        class="btn btn-sm btn-block btn-link"
        [disabled]="airspaceStatus === 'CHECKING'"
        (click)="showAirspaceCheckDetails()"
    >
        View Result Details
    </button>
    <div *ngIf="authorisationAvailable != null">
        <div
            style="
                line-height: 20px;
                border-radius: 10px;
                padding: 5px 10px;
                background: #eee;
                color: #000;
                margin-bottom: 5px;
            "
        >
            <span class="fa-regular fa-circle-exclamation"></span>
            {{ authorisationAvailable }}
        </div>
    </div>
    <!-- Don't deactivate button on the form's date being valid, rather use the more forgiving "isInPast" -->
    <button
        *ngIf="allowMissionCreation && !editingAirspaceParameters"
        type="button"
        class="btn btn-primary btn-sm btn-block"
        [disabled]="
            working ||
            airspaceStatus == null ||
            airspaceStatus == 'INCOMPLETE' ||
            airspaceStatus == 'CHECKING' ||
            airspaceStatus == 'IDLE' ||
            formGroup.controls.airspaceDuration.invalid ||
            formGroup.controls.airspaceRuleset.invalid ||
            formGroup.controls.airspaceHeight.invalid ||
            formGroup.controls.airspaceTimeZone.invalid ||
            this.isInPast
        "
        analyticsOn
        analyticsAction="airspace-check-create-mission"
        (click)="createMission.emit()"
    >
        <span
            [tooltip]="
                airspaceStatus === 'IDLE'
                    ? 'The airspace checker needs to run for a potential location first. Click on \'Start Airspace Check\' to begin'
                    : formGroup.controls.airspaceDuration.invalid
                    ? 'The check duration has to be more than 0 and less than 24 hours'
                    : formGroup.controls.airspaceDate.invalid
                    ? 'The selected date and time is in the past'
                    : ''
            "
            placement="left"
        >
            <span class="fa fa-plus"></span> Create Mission
        </span>
    </button>
</form>
