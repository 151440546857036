<div
    class="modal-header modal-header-lg relative"
    [ngClass]="{
        block: mode === 'BLOCK',
        advise: mode === 'ADVISE',
        pass: mode === 'PASS'
    }"
>
    <div
        class="information-icon"
        [ngClass]="{
            'block-icon': mode === 'BLOCK',
            'advise-icon': mode === 'ADVISE',
            'pass-icon': mode === 'PASS'
        }"
    >
        <span *ngIf="mode === 'ADVISE'" class="fa fa-exclamation"></span>
        <span *ngIf="mode === 'BLOCK'" class="fad fa-times"></span>
        <span *ngIf="mode === 'PASS'" class="fad fa-check"></span>
    </div>
    <h4
        class="modal-title modal-title-sm"
        [ngClass]="{
            block: mode === 'BLOCK',
            advise: mode === 'ADVISE',
            pass: mode === 'PASS'
        }"
    >
        {{ modalTitle }}
    </h4>
</div>
<div class="modal-body information-modal">
    <div class="horizontal-container">
        <div
            *ngIf="hasReasons()"
            class="vertical-container container-scroll fill"
        >
            <ng-container *ngFor="let rule of ruleOutcomes; let i = index">
                <div
                    class="horizontal-container information-block"
                    [ngClass]="{
                        blocks: mode === 'BLOCK',
                        advises: mode === 'ADVISE',
                        passes: mode === 'PASS'
                    }"
                >
                    <div
                        class="index-number"
                        [ngClass]="{
                            block: mode === 'BLOCK',
                            advise: mode === 'ADVISE',
                            pass: mode === 'PASS'
                        }"
                    >
                        {{ i + 1 }}
                    </div>
                    <span
                        ><strong>{{ rule.reason[0]?.identifier }}</strong
                        >&nbsp;{{ rule.reason[0]?.name }}</span
                    >
                    <!-- <span
                        >{{ returnRuleType(rule) }}&nbsp;&nbsp;<strong>{{
                            rule.reason[0]?.identifier
                        }}</strong
                        >&nbsp;{{ rule.reason[0]?.name }}</span
                    > -->
                    <div
                        *ngIf="rule.reason[0].message && !rule.reason[0]?.state"
                        style="flex: 1; text-align: right; padding-right: 10px"
                    >
                        <button
                            type="button"
                            class="btn btn-xs"
                            style="border-radius: 1em"
                            [ngClass]="{
                                block: mode === 'BLOCK',
                                advise: mode === 'ADVISE',
                                pass: mode === 'PASS'
                            }"
                            (click)="
                                showAdvisoryMessage(rule.reason[0].message)
                            "
                        >
                            Show Message
                        </button>
                    </div>
                    <div
                        *ngIf="rule.reason[0].message && rule.reason[0]?.state"
                        style="
                            width: 100%;
                            text-align: right;
                            padding-right: 80px;
                        "
                    >
                        <button
                            type="button"
                            class="btn btn-xs"
                            style="border-radius: 1em"
                            [ngClass]="{
                                block: mode === 'BLOCK',
                                advise: mode === 'ADVISE',
                                pass: mode === 'PASS'
                            }"
                            (click)="
                                showAdvisoryMessage(rule.reason[0].message)
                            "
                        >
                            Show Message
                        </button>
                    </div>
                    <div
                        *ngIf="rule.reason[0]?.state"
                        class="airspace-status-indicator"
                        [ngClass]="{
                            block:
                                rule.reason[0].state == 'ACTIVATED' &&
                                mode === 'BLOCK',
                            advise:
                                rule.reason[0].state == 'ACTIVATED' &&
                                mode === 'ADVISE',
                            pass:
                                rule.reason[0].state == 'ACTIVATED' &&
                                mode === 'PASS',
                            inactive: rule.reason[0].state != 'ACTIVATED'
                        }"
                    >
                        {{
                            rule.reason[0].state == 'ACTIVATED'
                                ? 'Active'
                                : 'Inactive'
                        }}
                    </div>
                </div>
            </ng-container>
        </div>
        <div
            *ngIf="!hasReasons()"
            class="vertical-container container-scroll fill"
        >
            <span class="fa fa-sparkles sparkle"></span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-default pull-left"
        (click)="viewRule()"
    >
        View Rule
    </button>
    <button
        type="button"
        class="btn"
        [ngClass]="{
            block: mode === 'BLOCK',
            advise: mode === 'ADVISE',
            pass: mode === 'PASS'
        }"
        (click)="close()"
    >
        OK
    </button>
    <inline-modal
        #inlineModal
        [sizeSmall]="true"
        style="max-height: fit-content"
    ></inline-modal>
</div>
