<ng-container screenAnalytics="approval-conditions-library-edit">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Approval Conditions Library</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="form">
        <ngx-loading [show]="working"></ngx-loading>
        <div class="modal-body vertical-container">
            <enhanced-help componentName="screen"></enhanced-help>
            <div>
                <label class="control-label" [ngClass]="{ readonly: working }">
                    Library Name</label
                >
                <input
                    *ngIf="!conditionsLibraryId"
                    class="form-control"
                    type="text"
                    formControlName="name"
                    [readonly]="working"
                />
                <editable
                    *ngIf="conditionsLibraryId"
                    [confirmationButtons]="true"
                    [cancelOnClickOutside]="false"
                    [initialContext]="{
                        name: form.controls.name.value
                    }"
                    [updateFn]="updateName"
                    (modeChange)="onEditableModeChange($event)"
                >
                    <ng-template viewMode>
                        {{ form.controls.name.value }}
                    </ng-template>
                    <ng-template
                        editMode
                        let-context="context"
                        let-state="state"
                    >
                        <input
                            type="text"
                            class="form-control"
                            [(ngModel)]="context.name"
                            [ngModelOptions]="{ standalone: true }"
                            editableOnEnter
                            [readonly]="state.saving"
                        />
                        <div class="text-danger" *ngIf="state.error != null">
                            {{ state.error }}
                        </div>
                    </ng-template>
                </editable>

                <enhanced-help componentName="add-condition"></enhanced-help>
                <h4 class="section-heading">
                    <button
                        class="btn-circle btn-link"
                        (click)="newCondition()"
                        tooltip="Add a new approval condition"
                        placement="top"
                        [disabled]="working || isEditingName"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                    <span> Add Condition</span>
                </h4>
            </div>
            <div class="fill container-scroll">
                <table class="table">
                    <tbody
                         formArrayName="conditions"
                         cdkDropList
                         [cdkDropListData]="conditions.controls"
                         (cdkDropListDropped)="drop($event)"
                          >
                        <tr *ngFor="let condition of conditions.controls; let i = index" cdkDrag>
                            <td>
                                <span class="fas fa-grip-vertical row-hover grab-handle" cdkDragHandle></span>
                            </td>
                            <td [formGroupName]="i">
                                <textarea formControlName="condition" class="form-control" rows="3"></textarea>
                            </td>
                            <td>
                                <button class="btn btn-tertiary btn-delete" type="button" (click)="removeCondition(i)">
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button
                (click)="save()"
                class="btn"
                [disabled]="
                    working || form.invalid || isEditingName || form.pristine
                "
            >
                Save
            </button>
            <button
                (click)="saveAndClose()"
                class="btn btn-primary"
                [disabled]="working || form.invalid || isEditingName"
            >
                Save and Close
            </button>
        </div>
    </form>
</ng-container>
