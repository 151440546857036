import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    OrganisationAuthorityGroup,
    AuthorityTypeDto,
    CraftAuthorityGroup
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityGroupAddService } from './authority-group-add.service';

@Component({
    selector: 'authority-group-add',
    template: `
        <div class="input-group">
            <ng-select
                placeholder="Select Authority Type To Add"
                [items]="filteredAuthorities"
                [groupBy]="authorityGroup"
                [(ngModel)]="selectedAuthority"
                appendTo="body"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div>{{ item.name }}</div>
                    <div *ngIf="item.description">
                        <p class="help-block">{{ item.description }}</p>
                    </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    {{ item.name }}
                </ng-template>
            </ng-select>
            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="add()"
                    [disabled]="working || selectedAuthority == null"
                >
                    <span class="fa fa-plus"></span> Add Authority
                </button>
            </span>
        </div>
    `
})
export class AuthorityGroupAddComponent {
    @Input() working: boolean;

    @Output() createAuthority: EventEmitter<
        AuthorityTypeDto
    > = new EventEmitter();

    filteredAuthorities: (
        | OrganisationAuthorityGroup
        | CraftAuthorityGroup
    )[] = [];

    selectedAuthority: AuthorityTypeDto;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(private authorityGroupAddService: AuthorityGroupAddService) {}

    ngOnInit() {
        this.authorityGroupAddService.filteredAuthorities$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(filteredAuthorities => {
                this.filteredAuthorities = filteredAuthorities;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    authorityGroup(item: OrganisationAuthorityGroup) {
        return item.issuedBy.name;
    }

    add() {
        if (this.selectedAuthority != null) {
            this.createAuthority.emit(this.selectedAuthority);
            this.selectedAuthority = null;
        }
    }
}
