<div class="modal-header no-icon">
    <h3 class="modal-title">
        {{ isCreating ? 'Add' : 'Edit' }} Your Air Services Australia Details
    </h3>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hide()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="updateDetails()">
    <div class="modal-body">
        <div class="form-group">
            <label>Username</label>
            <input
                class="form-control"
                type="text"
                formControlName="username"
            />
        </div>

        <div class="form-group">
            <label>Password</label>
            <input
                class="form-control"
                type="password"
                formControlName="password"
            />
        </div>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!formGroup.valid || formGroup.pristine || working"
        >
            Save
        </button>
    </div>
</form>
