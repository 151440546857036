/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimplePersonDto } from './simplePersonDto';
import { AuthorityWorkflowApproverCheck } from './authorityWorkflowApproverCheck';


/**
 * 
 */
export interface ApproversMissionApprovalDto { 
    /**
     * The unique ID of the approval
     */
    id: number;
    /**
     * The current status of the approval
     */
    status: ApproversMissionApprovalDto.Status;
    /**
     * The time that the approval was originally requested
     */
    requestTime?: string;
    /**
     * The time the approval was either approved or cancelled
     */
    resolutionTime?: string;
    /**
     * A message from the approver to the RPIC when approving the mission
     */
    messageFromApprover?: string;
    /**
     * The maximum permitted height of operations in feet
     */
    maximumHeight?: number;
    /**
     * A list of the approval check IDs that have been completed by the approver
     */
    completedChecks: Array<number>;
    /**
     * The checks the approver will perform on the mission, mapped by workflow step
     */
    mappedApproverChecks: { [key: string]: Array<AuthorityWorkflowApproverCheck>; };
    approvedBy?: SimplePersonDto;
    requestedBy?: SimplePersonDto;
    /**
     * Confidential notes made by the approver
     */
    approversNotes?: string;
    /**
     * A list of conditions imposed by the approver
     */
    conditions?: Array<string>;
    acceptedBy?: SimplePersonDto;
    /**
     * The time that the approval was accepted
     */
    acceptTime?: string;
    authorityWorkflowVersionId: number;
    authorityId: number;
}
export namespace ApproversMissionApprovalDto {
    export type Status = 'DRAFT' | 'PENDING' | 'BEING_REVIEWED' | 'REVISION_REQUIRED' | 'APPROVED' | 'REJECTED' | 'CANCELLED' | 'ACCEPTED' | 'REVOKED';
    export const Status = {
        DRAFT: 'DRAFT' as Status,
        PENDING: 'PENDING' as Status,
        BEING_REVIEWED: 'BEING_REVIEWED' as Status,
        REVISION_REQUIRED: 'REVISION_REQUIRED' as Status,
        APPROVED: 'APPROVED' as Status,
        REJECTED: 'REJECTED' as Status,
        CANCELLED: 'CANCELLED' as Status,
        ACCEPTED: 'ACCEPTED' as Status,
        REVOKED: 'REVOKED' as Status
    };
}



