import { Component } from '@angular/core';
import {
    DO_NOTHING,
    FlightLogCollectionService,
    FlightLogCollectionSourceDto,
    FlightLogCollectionSourceWithApiKeyDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FlightLogSettingsService } from './flight-log-settings.service';

@Component({
    selector: 'drone-deploy-settings',
    template: ` <div *ngIf="newSource != null" class="well">
            <p>
                To complete the setup, install the FlyFreely DroneDeploy app and
                enter in this sync key in its settings.
            </p>
            <strong
                >{{ newSource.apiKey.id }}.{{ newSource.apiKey.apiKey }}</strong
            ><copy-to-clipboard
                [content]="newSource.apiKey.id + '.' + newSource.apiKey.apiKey"
            ></copy-to-clipboard>
            <p>
                Make a note of this key as it can not be retrieved again once
                this window is closed. A new key will need to be generated.
            </p>
        </div>
        <ng-container
            *ngIf="droneDeployList$ | async as droneDeployList"
            class="vertical-container fill"
        >
            <div
                class="bottom-buffer fill"
                *ngIf="droneDeployList.length === 0"
            >
                <empty
                    componentName="empty-dronedeploy-accounts"
                    style="margin-left: 30px;"
                >
                    <button
                        type="button"
                        class="btn btn-default top-buffer"
                        (click)="setupDroneDeploy()"
                    >
                        Setup DroneDeploy
                    </button>
                </empty>
            </div>

            <table *ngIf="droneDeployList.length > 0" class="table">
                <thead>
                    <tr>
                        <th style="width: 60%">Last Completion Time</th>
                        <th
                            *ngIf="flightLogSettingsService.canUseAlerts"
                            style="width: 50%"
                        >
                            Alerts
                        </th>
                        <th style="width: 10%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of droneDeployList">
                        <td>
                            {{ entry.lastCompletionTime | formatDateTime }}
                        </td>
                        <td
                            *ngIf="
                                flightLogSettingsService.canUseAlerts &&
                                canManageAll
                            "
                        >
                            <flight-log-alert
                                [alertSettings]="entry.alertSettings"
                                (showSettings)="
                                    flightLogSettingsService.showAlertSettings(
                                        entry
                                    )
                                "
                            ></flight-log-alert>
                        </td>
                        <td class="actions">
                            <button
                                class="btn btn-tertiary btn-delete"
                                type="button"
                                (click)="deleteDroneDeploy(entry)"
                                [disabled]="working"
                                tooltip="Delete"
                                placement="left"
                            >
                                <span class="fal fa-trash-alt"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>`
})
export class DroneDeploySettings {
    private ngUnsubscribe$ = new Subject<void>();

    droneDeployList$: Observable<FlightLogCollectionSourceDto[]>;

    newSource?: FlightLogCollectionSourceWithApiKeyDto;

    working: boolean;

    constructor(
        public flightLogSettingsService: FlightLogSettingsService,
        private workTracker: WorkTracker,
        private commonDialoguesService: CommonDialoguesService,
        private flightLogCollectionService: FlightLogCollectionService,
        private logging: FlyFreelyLoggingService
    ) {
        this.droneDeployList$ =
            this.flightLogSettingsService.flightLogCollectionSourceList$.pipe(
                takeUntil(this.ngUnsubscribe$),
                map(sources =>
                    sources.filter(
                        s =>
                            s.type ===
                            FlightLogCollectionSourceDto.Type.DRONE_DEPLOY
                    )
                )
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupDroneDeploy() {
        this.flightLogCollectionService
            .createApiSource({
                organisationId: this.flightLogSettingsService.organisationId,
                type: 'DRONE_DEPLOY'
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.newSource = result;
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error creating drone deploy source ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    deleteDroneDeploy(entry: FlightLogCollectionSourceDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Collection',
                'Are you sure you want to delete this collection?',
                'Delete',
                () => Promise.resolve()
            )
            .then(() =>
                this.flightLogCollectionService
                    .deleteSource(entry.id)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(() =>
                        this.logging.success('DroneDeploy account deleted')
                    )
                    .add(this.workTracker.createTracker())
            )
            .catch(DO_NOTHING);
    }
}
