import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AirspaceAuthorisationDto,
    DisplayableMissionDto,
    FieldError,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OperationAuthorisation,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { AirspaceCheckService } from '../../airspace-check';
import { AirspaceAuthorisationManager } from '../airspace-authorisation-manager.service';
import { AuthorisationError } from '../interfaces';

/**
 * A function that determines the eligibility of an operation for authorisation based off the eligibility status.
 * @param eligibility the operation eligibility status
 * @returns true if the operation is eligible for authorisation, or false if not.
 */
function findAuthorisationEligibility(
    eligibility: OperationAuthorisation.AuthorisationEligibility
) {
    if (eligibility == null) {
        return false;
    }
    const status = OperationAuthorisation.AuthorisationEligibility;
    switch (eligibility) {
        case status.NOT_ELIGIBLE:
        case status.NOT_REQUIRED:
        case status.UNAVAILABLE:
            return false;
        default:
            return true;
    }
}

@Component({
    selector: 'inline-authorisation',
    templateUrl: './inline-authorisation.component.html',
    styleUrls: ['./inline-authorisation.component.scss']
})
export class InlineAuthorisationComponent {
    /**
     * Does the mission have all the required information, such as workflow, RPA, and RP?
     */
    @Input() areAuthorisationRequirementsComplete: boolean = false;
    @Input() missionWorking: boolean = false;
    @Input() missionTimeZone: string;
    @Input() currentUserCanRequestAuthorisation: boolean;
    @Input() currentUserCanViewAuthorisation: boolean;
    @Output() onShowAirspaceAuthorisation = new EventEmitter<boolean>();

    areAuthorisationRequirementsComplete$ = new BehaviorSubject<boolean>(false);

    authorisation$ = new BehaviorSubject<
        AirspaceAuthorisationDto[] | OperationAuthorisation[]
    >(null);
    authorisationType: AirspaceAuthorisationDto.AuthorisationType;

    submitOrView: 'SUBMIT' | 'VIEW' = 'SUBMIT';
    termsAccepted = false;

    private ngUnsubscribe$ = new Subject<void>();

    airspaceCheckCanFly: boolean;

    airspaceWorking = false;

    resultError: AuthorisationError;

    authorisationUnavailable = false;
    missionLineOfSightIneligible = false;

    airspaceServiceUnavailable = false;
    authorisationServiceUnavailable = false;
    authorisationUnavailableMessage: string;

    userPermissionsUpdated$ = new Subject<void>();

    mode: 'INITIAL' | 'PREVIEW' = 'INITIAL';

    constructor(
        public airspaceCheckService: AirspaceCheckService,
        public airspaceAuthorisationManager: AirspaceAuthorisationManager,
        public workTracker: WorkTracker,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.setupAirspaceCheckListeners();
        this.userPermissionsUpdated$.next();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('areAuthorisationRequirementsComplete' in changes) {
            this.areAuthorisationRequirementsComplete$.next(
                this.areAuthorisationRequirementsComplete
            );
        }
        if (
            'currentUserCanRequestAuthorisation' in changes ||
            'currentUserCanViewAuthorisation' in changes
        ) {
            this.userPermissionsUpdated$.next();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupAirspaceCheckListeners() {
        combineLatest([
            this.airspaceCheckService.resultStatus$,
            this.airspaceAuthorisationManager.checkingAuthorisation$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([status, authorisationWorking]) => {
                this.airspaceWorking =
                    status === 'CHECKING' || authorisationWorking;
            });
        combineLatest([
            this.airspaceAuthorisationManager.authorisationServiceUnavailable$,
            this.airspaceCheckService.operationFailed$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([authorisationState, checkerAuthorisationFailed]) => {
                this.airspaceServiceUnavailable = checkerAuthorisationFailed;
                this.authorisationServiceUnavailable =
                    authorisationState.status === 'UNAVAILABLE';
                this.authorisationUnavailableMessage =
                    authorisationState.unavailableMessage;
            });

        combineLatest([
            this.airspaceAuthorisationManager.airspaceAuthorisationEnabled$,
            this.airspaceCheckService.resultStatus$.pipe(
                distinctUntilChanged()
            ),
            this.airspaceAuthorisationManager.airspaceAuthorisationPreview$.pipe(
                startWith(null)
            ),
            this.airspaceAuthorisationManager.missionAirspaceAuthorisations$.pipe(
                startWith(null)
            ),
            this.airspaceAuthorisationManager.authorisationRequestError$.pipe(
                startWith(null)
            ),
            this.userPermissionsUpdated$.pipe(startWith())
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    airspaceAuthorisationsEnabled,
                    status,
                    airspaceAuthorisation,
                    missionAuthorisations,
                    resultError,
                    permissionsUpdated
                ]) => {
                    this.airspaceCheckCanFly =
                        status === 'CAN_FLY' || status === 'CAN_FLY_CONDITIONS';
                    this.resultError = resultError;
                    const hasValidMissionAuthorisations =
                        missionAuthorisations?.filter(
                            a =>
                                a.status !==
                                    OperationAuthorisation.Status.CANCELLED &&
                                a.status !==
                                    OperationAuthorisation.Status.REJECTED &&
                                a.status !==
                                    OperationAuthorisation.Status.ERROR &&
                                a.status !==
                                    OperationAuthorisation.Status.RESCINDED &&
                                a.status !==
                                    OperationAuthorisation.Status.COMPLETED
                        )?.length > 0;
                    this.authorisationUnavailable =
                        (airspaceAuthorisation == null &&
                            (missionAuthorisations == null ||
                                !hasValidMissionAuthorisations)) ||
                        // find if there are any authorisations in the list that are eligible, else authorisationUnavailable = true
                        ((missionAuthorisations == null ||
                            !hasValidMissionAuthorisations) &&
                            (airspaceAuthorisation?.authorisationList.find(
                                auth =>
                                    auth.authorisationEligibility ===
                                    OperationAuthorisation
                                        .AuthorisationEligibility.UNAVAILABLE
                            ) != null ||
                                airspaceAuthorisation.authorisationList.filter(
                                    auth =>
                                        findAuthorisationEligibility(
                                            auth.authorisationEligibility
                                        )
                                ).length === 0));
                    // TODO: this is only valid for LAANC and may need further logic around the jurisdictions.
                    this.missionLineOfSightIneligible =
                        this.airspaceAuthorisationManager.savedMission
                            .visualLineOfSight !==
                        DisplayableMissionDto.VisualLineOfSight.VLOS;
                    if (this.missionLineOfSightIneligible) {
                        const error: { [control: string]: FieldError } = {
                            'mission.visualLineOfSight': {
                                message:
                                    'Automatic authorization only available for VLOS missions'
                            }
                        };
                        if (missionAuthorisations.length > 0) {
                            // @ts-ignore - errors field does exist on API return
                            missionAuthorisations.errors = {
                                // @ts-ignore - errors field does exist on API return
                                ...(missionAuthorisations.errors ?? {}),
                                'mission.visualLineOfSight': {
                                    message:
                                        'Automatic authorization only available for VLOS missions'
                                }
                            };
                        } else {
                            airspaceAuthorisation.errors = {
                                ...(airspaceAuthorisation.errors ?? {}),
                                'mission.visualLineOfSight': {
                                    message:
                                        'Automatic authorization only available for VLOS missions'
                                }
                            };
                        }
                    }

                    this.authorisation$.next(
                        missionAuthorisations?.length > 0
                            ? missionAuthorisations
                            : airspaceAuthorisation?.authorisationList
                    );
                    this.authorisationType =
                        missionAuthorisations != null &&
                        missionAuthorisations.length > 0
                            ? missionAuthorisations[0].authorisationType
                            : airspaceAuthorisation?.authorisationType;

                    if (
                        missionAuthorisations != null &&
                        missionAuthorisations.length > 0 &&
                        this.currentUserCanViewAuthorisation
                    ) {
                        this.submitOrView = 'VIEW';
                        this.mode = 'PREVIEW';
                    } else {
                        this.submitOrView = 'SUBMIT';
                    }
                }
            );
    }

    acknowledgeRescinded(authorisation: OperationAuthorisation) {
        this.airspaceAuthorisationManager
            .acknowledgeRescinded(authorisation.id, this.authorisationType)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success('Rescinded status acknowledged');
                    const authorisations = this.authorisation$
                        .getValue()
                        .map(auth => (auth.id === result.id ? result : auth));
                    this.authorisation$.next(authorisations);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while acknowledging rescinded status: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    showDisclaimer() {
        this.commonDialoguesService.showConfirmationDialogue(
            'LAANC Disclaimer',
            `<p>
            FlyFreely is a provider of UAS services within the FAA’s Low-
Altitude Authorization and Notification Capability (LAANC). LAANC may be used to satisfy
compliance with Air Traffic authorization. Information provided here is based on real-time and
available projected information on airspace status and airport-specific maps, and that information
is subject to change. Planning tools should be checked prior to flight for any changes that could
impact the operation.</p>
            <p>FlyFreely cannot ensure the completion or accuracy of all displayed TFR data. Remote pilots
are responsible for checking the airspace they are operating in per Part 107.49 and complying
with all restrictions that may be present such as restricted and prohibited airspace, temporary
flight restrictions, etc. To obtain the most accurate and up to date information operators should
check tfr.faa.gov [hyperlink to tfr.faa.gov] and ensure their operation(s) do not overlap with any
active TFRs or other restricted airspaces prior to launch</p>`,
            'Accept',
            () => {
                this.mode = 'PREVIEW';
                return Promise.resolve();
            }
        );
    }
}
