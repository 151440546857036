import { NameValue } from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function missionWeatherFields(
    weatherConditions: NameValue[],
    cloudCoverage: NameValue[]
): FormlyFieldConfig[] {
    return [
        {
            key: 'windSpeed',
            type: 'input',
            props: {
                label: 'Wind Speed',
                type: 'number',
                required: false,
                addonRight: {
                    text: 'kn'
                }
            }
        },
        {
            key: 'windGusts',
            type: 'input',
            props: {
                label: 'Wind Gusts',
                type: 'number',
                required: false,
                addonRight: {
                    text: 'kn'
                }
            }
        },
        {
            key: 'temperature',
            type: 'input',
            props: {
                label: 'Temperature',
                type: 'number',
                required: false,
                addonRight: {
                    text: 'C'
                }
            }
        },
        {
            key: 'visibility',
            type: 'input',
            props: {
                label: 'Visibility',
                type: 'number',
                required: false,
                min: 0,
                addonRight: {
                    text: 'm'
                }
            }
        },
        {
            key: 'conditions',
            type: 'ng-select',
            props: {
                label: 'Weather Conditions',
                required: false,
                options: weatherConditions
            }
        },
        {
            key: 'cloudCoverage',
            type: 'ng-select',
            props: {
                label: 'Cloud Coverage',
                required: false,
                options: cloudCoverage
            }
        },
        {
            key: 'cloudLevel',
            type: 'input',
            props: {
                label: 'Cloud Level',
                required: false,
                type: 'number',
                min: 0,
                addonRight: {
                    text: 'ft'
                }
            },
            hideExpression: 'model.cloudCoverage === "CLEAR"'
        }
    ];
}

export const missionApprovalFields: FormlyFieldConfig[] = [
    {
        key: 'messageFromApprover',
        type: 'textarea',
        props: {
            label: 'Message to Remote Pilot',
            required: true,
            maxLength: 2000
        }
    },
    {
        key: 'approversNotes',
        type: 'textarea',
        props: {
            label: 'Internal Notes',
            required: false,
            maxLength: 2000
        }
    },
    {
        key: 'conditions',
        type: 'textarea',
        props: {
            label: 'Approval Conditions',
            required: true,
            maxLength: 2000
        }
    },
    {
        key: 'maximumHeight',
        type: 'input',
        props: {
            type: 'number',
            required: true,
            min: 0
        }
    }
];
