import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AdminIncidentReportDto,
    AttachmentHandler,
    CreateIncidentReportCommand,
    DisplayableMissionDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    IncidentReportDto,
    IncidentReportsService,
    MissionService,
    UpdateIncidentReportCommand,
    WorkTracker,
    fromLocalDate,
    fromTimestamp,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaPipe } from '@flyfreely-portal-ui/resource-ui';
import { FormatPersonPipe } from '@flyfreely-portal-ui/ui';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {
    Subject,
    asyncScheduler,
    combineLatest,
    firstValueFrom,
    of,
    zip
} from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'incident-report-edit',
    templateUrl: './incident-report-edit.component.html'
})
export class IncidentReportEdit implements OnInit {
    @Input() missionId: number;
    @Input() incidentReport: IncidentReportDto;
    @Input() id: number;

    options: FormlyFormOptions;

    mission: DisplayableMissionDto;
    savedIncidentReport: AdminIncidentReportDto;

    attachmentsHandler: AttachmentHandler;

    inputForm: FormGroup;
    validForSave: boolean;
    fields: FormlyFieldConfig[];
    initialValues: {
        report?: string;
        incidentTime: Date;
        rpa?: number;
        pilot?: number;
        resolution?: string;
        resolutionNotes?: string;
    };

    working: boolean = false;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<IncidentReportEdit>,
        modalOptions: ModalOptions,
        private missionService: MissionService,
        private incidentReportsService: IncidentReportsService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private formatRpaPipe: FormatRpaPipe,
        private formatPersonPipe: FormatPersonPipe
    ) {
        this.inputForm = new FormGroup({});

        modalOptions.closeInterceptor = () => {
            if (this.inputForm.dirty) {
                return commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel Report',
                    `You have unsaved changes, ${
                        this.savedIncidentReport?.status === 'NEW'
                            ? 'closing the dialogue will change the report status to "Cancelled". This action cannot be undone. Are you sure you wish to cancel the report?'
                            : 'Are you sure you wish to cancel?'
                    }`,
                    'Yes',
                    () => {
                        if (this.savedIncidentReport?.status === 'NEW') {
                            return firstValueFrom(
                                this.incidentReportsService.cancelIncidentReport(
                                    this.savedIncidentReport.id
                                )
                            );
                        }
                        return Promise.resolve();
                    }
                );
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        if (this.incidentReport) {
            // incident report passed in
            this.savedIncidentReport = this.incidentReport;
            this.loadIncidentReport(this.savedIncidentReport.id);
        } else if (this.id) {
            // incident report id passed in
            this.loadIncidentReport(this.id);
        } else if (this.missionId) {
            // Initialise blank incident report for mission
            this.newIncidentReport(this.missionId);
        }

        this.inputForm.statusChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(status => {
                // This is a list of the form controls that are required for saving
                // The form can still be valid for saving even if it's not valid for resolve
                const validToSaveKeys = [
                    'report',
                    'incidentTime',
                    'rpa',
                    'pilot'
                ];
                this.validForSave = validToSaveKeys.reduce(
                    (acc, key) => acc && this.inputForm.controls[key].valid,
                    true
                );
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupFields() {
        this.fields = [
            {
                key: 'report',
                type: 'textarea',
                props: {
                    required: true,
                    label: 'Report Details',
                    description:
                        'Describe the circumstances and details of the incident'
                }
            },
            {
                key: 'incidentTime',
                type: 'datetime',
                props: {
                    required: true,
                    label: 'Incident Time',
                    description: 'The date and time of the incident'
                }
            },
            {
                key: 'rpa',
                type: 'ng-select',
                props: {
                    required: true,
                    label: 'RPA Involved',
                    valueProp: 'id',
                    labelProp: 'name',
                    options: this.mission.crafts.map(c => ({
                        name: this.formatRpaPipe.transform(c),
                        value: c.id.toString(),
                        id: c.id
                    }))
                }
            },
            {
                key: 'pilot',
                type: 'ng-select',
                props: {
                    required: true,
                    label: 'Pilot Involved',
                    valueProp: 'id',
                    labelProp: 'name',
                    options: this.mission.missionCrewDetails.map(c => ({
                        name: this.formatPersonPipe.transform(c.person),
                        value: c.person.id.toString(),
                        id: c.person.id
                    }))
                }
            }
        ];
        if (
            this.savedIncidentReport.status === 'SUBMITTED' ||
            this.savedIncidentReport.status === 'RESOLVED'
        ) {
            this.fields = this.fields.concat([
                {
                    key: 'resolution',
                    type: 'textarea',
                    props: {
                        required: true,
                        label: 'Resolution Details',
                        description: 'What was the response to this incident'
                    }
                }
            ]);

            if (this.savedIncidentReport.resolutionNotes !== undefined) {
                this.fields = this.fields.concat([
                    {
                        key: 'resolutionNotes',
                        type: 'textarea',
                        props: {
                            required: false,
                            label: 'Resolution Notes',
                            description: 'Confidential notes from the admin/CRP'
                        }
                    }
                ]);
            }
        }
    }

    private newIncidentReport(missionId: number) {
        combineLatest([
            this.missionService.findMission(missionId),
            this.incidentReportsService.newIncidentReport({
                missionId: missionId
            })
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([mission, report]) => {
                    this.mission = mission;

                    this.savedIncidentReport = report;

                    this.attachmentsHandler =
                        this.incidentReportsService.attachmentsHandler(
                            report.id
                        );
                    this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);

                    this.initialValues = {
                        ...report,
                        rpa: report?.rpa?.id,
                        pilot: report?.pilot?.id,
                        incidentTime: fromTimestamp(
                            this.mission.missionDate,
                            this.mission.timeZone
                        )
                    };

                    this.setupFields();

                    // Mark the form as dirty so the modal can't be closed without either submitting or cancelling.
                    // This prevents the incident report from being saved with a "New" status.
                    this.inputForm.markAsDirty();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }

    private loadIncidentReport(incidentReportId: number) {
        this.attachmentsHandler =
            this.incidentReportsService.attachmentsHandler(incidentReportId);
        this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);

        this.incidentReportsService
            .findById(incidentReportId)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(report =>
                    zip(
                        this.missionService.findMission(report.mission.id),
                        of(report)
                    )
                )
            )
            .subscribe({
                next: ([mission, report]) => {
                    this.mission = mission;
                    this.savedIncidentReport = report;
                    this.initialValues = {
                        ...report,
                        rpa: report?.rpa?.id,
                        pilot: report?.pilot?.id,
                        incidentTime: fromTimestamp(
                            report.incidentTime,
                            this.mission.timeZone
                        )
                    };

                    this.setupFields();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }

    submit() {
        if (this.savedIncidentReport.status === 'NEW') {
            this.createIncidentReport();
        } else if (this.savedIncidentReport.status === 'SUBMITTED') {
            this.updateIncidentReport(false);
        }
    }

    saveAndClose() {
        this.updateIncidentReport(true);
    }

    private updateIfRequired() {
        if (this.inputForm.dirty) {
            return this.doUpdateIncidentReport();
        } else {
            return of(this.savedIncidentReport, asyncScheduler);
        }
    }

    resolveIncidentReport() {
        this.updateIfRequired()
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(report =>
                    this.incidentReportsService.resolveIncidentReport(report.id)
                )
            )
            .subscribe({
                next: result => {
                    this.logging.success('Incident report resolved');
                    this.inputForm.markAsPristine();
                    this.close();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }

    private createIncidentReport() {
        const f = this.inputForm.value;
        const cmd: CreateIncidentReportCommand = {
            report: f.report,
            incidentTime: toTimestamp(f.incidentTime, this.mission.timeZone),
            pilotId: f.pilot,
            rpaId: f.rpa
        };
        this.incidentReportsService
            .createIncidentReport(this.savedIncidentReport.id, cmd)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: report => {
                    this.savedIncidentReport = report;
                    this.logging.success('Incident report logged');
                    this.inputForm.markAsPristine();
                    this.close();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }

    private doUpdateIncidentReport() {
        const f = this.inputForm.value;
        const cmd: UpdateIncidentReportCommand = {
            flightId: this.savedIncidentReport.flight
                ? this.savedIncidentReport.flight.id
                : null,
            incidentTime: toTimestamp(
                fromLocalDate(f.incidentTime),
                this.mission.timeZone ? this.mission.timeZone : null
            ),
            pilotId: f.pilot,
            report: f.report,
            resolution: f.resolution,
            resolutionNotes: f.resolutionNotes,
            rpaId: f.rpa
        };
        return this.incidentReportsService.updateIncidentReport(
            this.savedIncidentReport.id,
            cmd
        );
    }

    private updateIncidentReport(close: boolean) {
        this.doUpdateIncidentReport()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: report => {
                    this.savedIncidentReport = report;
                    this.logging.success('Incident report updated');
                    this.inputForm.markAsPristine();

                    if (close) {
                        this.close();
                    }
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }

    cancelIncidentReport() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Cancel Incident Report',
                'Are you sure you want to cancel this incident report? This will change the report status to "Cancelled". This action cannot be undone.',
                'OK',
                () =>
                    firstValueFrom(
                        this.incidentReportsService.cancelIncidentReport(
                            this.savedIncidentReport.id
                        )
                    )
            )
            .then(
                () => {
                    this.inputForm.reset();
                    this.inputForm.markAsPristine();
                    this.savedIncidentReport = null;
                    this.modal.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error cancelling incident report: ${error.message}`
                    )
            );
    }

    close() {
        this.modal.hide();
    }
}
