import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '@flyfreely-portal-ui/analytics';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { SharedUiModule } from '@flyfreely-portal-ui/ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { AirspaceModule } from 'libs/airspace/src/lib/airspace.module';
import { InlineModalModule } from 'libs/inline-modal/src/lib/inline-modal.module';
import { LocationsModule } from 'libs/locations/src/lib/locations.module';
import { MapModule } from 'libs/map/src/lib/map.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { MissionViewMapComponent } from './mission-view-map/mission-view-map.component';
import { MissionViewSidebarComponent } from './mission-view-sidebar/mission-view-sidebar.component';
import { MissionViewService } from './mission-view.service';
import { MissionViewComponent } from './mission-view/mission-view.component';
import { MissionsGuard } from './missions.guard';
import { RouterModule } from '@angular/router';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedUiModule,
        MapModule,
        NgxLoadingModule,
        AnalyticsModule,
        FlyFreelyTableModule,
        NgSelectModule,
        ButtonsModule,
        TooltipModule,
        AirspaceModule,
        InlineModalModule,

        LocationsModule,
        ReactiveFormsModule,
        RouterModule.forChild([])
    ],
    declarations: [
        MissionViewComponent,
        MissionViewSidebarComponent,
        MissionViewMapComponent
    ],
    providers: [MissionViewService, MissionsGuard],
})
export class MissionViewModule {}
