import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionService,
    NameId,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlightLogWithDuration } from './flight-log-list/flight-log-list-dialogue.component';

@Component({
    selector: 'suggested-mission-assignment',
    template: `{{ flightLog.suggestedMissionAssignment.missionName }}
        <div class="status-icon-block">
            <div class="status-icon">
                <div class="status-icon-green"></div>
            </div>
            <div class="name-with-icon">RPA Matches</div>
        </div>
        <div class="status-icon-block">
            <div class="status-icon">
                <div
                    [class]="
                        flightLog.suggestedMissionAssignment.timeDistance
                            | timeDistanceClass
                    "
                ></div>
            </div>
            <div class="name-with-icon">
                Time
                {{
                    flightLog.suggestedMissionAssignment.timeDistance
                        | formatDuration
                }}
                difference
            </div>
        </div>
        <div class="status-icon-block">
            <div class="status-icon">
                <div
                    [class]="
                        flightLog.suggestedMissionAssignment.locationDistance
                            | locationDistanceClass
                    "
                ></div>
            </div>
            <div class="name-with-icon">
                Location
                {{ flightLog.suggestedMissionAssignment.locationDistance }} m
                away
            </div>
        </div>
        <div class="form-group">
            <label>Assign to a flight</label>
            <ng-select
                [items]="flights"
                [clearable]="true"
                bindLabel="name"
                bindValue="id"
                placeholder="Leave Unassigned"
                [disabled]="working"
                [(ngModel)]="selectedFlightId"
                (click)="$event.stopPropagation()"
            >
            </ng-select>
        </div>
        <button
            class="btn btn-sm btn-block btn-primary"
            type="button"
            [disabled]="working"
            (click)="assignFlightLog()"
            angulartics2On
            angularticsAction="assign-mission-suggestion"
            angularticsCategory="flight-log-overview"
            [angularticsProperties]="{ screen: 'flight-log-overview' }"
        >
            Assign
        </button>
        <button
            class="btn btn-sm btn-block btn-default"
            type="button"
            [disabled]="working"
            (click)="showOnMap()"
            angulartics2On
            angularticsAction="preview-mission-suggestion"
            angularticsCategory="flight-log-overview"
            [angularticsProperties]="{ screen: 'flight-log-overview' }"
        >
            View on Mission Plan
        </button>`
})
export class SuggestedMissionAssignment {
    @Input() flightLog: FlightLogWithDuration;
    @Output() hide = new EventEmitter<void>();
    @Output() showFlightAnalyser = new EventEmitter<void>();

    flights: NameId[];
    selectedFlightId: number;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightLogsService: FlightLogsService,
        private missionService: MissionService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.missionService
            .findMission(this.flightLog.suggestedMissionAssignment.missionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                mission => {
                    this.flights = mission.sorties
                        .map(s => ({
                            name: `Flight #${s.number}`,
                            id: s.id
                        }))
                        .concat({
                            name: 'Create New Flight',
                            id: -1
                        });
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error fetching the recommended mission: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    assignFlightLog() {
        if (this.selectedFlightId === -1) {
            this.missionService
                .newSortie(this.flightLog.suggestedMissionAssignment.missionId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    newSortie => {
                        this.flights.concat({
                            name: `Flight #${newSortie.number}`,
                            id: newSortie.id
                        });
                        this.selectedFlightId = newSortie.id;
                        this.assignLogToMission();
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error creating new flight: ${error.message}`
                        )
                )
                .add(this.workTracker.createTracker());
        } else {
            this.assignLogToMission();
        }
    }

    assignLogToMission() {
        this.flightLogsService
            .assignFlightLog(this.flightLog.id, {
                missionId: this.flightLog.suggestedMissionAssignment.missionId,
                flightId: this.selectedFlightId
            })
            .subscribe(
                updatedFlightLog => {
                    this.logging.success('Flight assigned');
                    this.hide.emit();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error assigning flight: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    showOnMap() {
        this.showFlightAnalyser.emit();
        this.hide.emit();
    }
}
