<div class="modal-body-content container-with-footer">
    <div class="vertical-container">
        <enhanced-help componentName="profile-flight-history" [compact]="true">
        </enhanced-help>
        <pilot-flight-history-summary
            [person]="currentUser"
            [managingOrganisationId]="currentUser.personalOrganisationId"
        ></pilot-flight-history-summary>

        <detailed-flight-history
            class="fill"
            [currentUser]="currentUser"
            [managingOrganisationId]="currentUser.personalOrganisationId"
            [canEdit]="true"
            [personalHistory]="true"
        ></detailed-flight-history>
    </div>
    <div class="action-container">
        <print-personnel-reports
            [person]="currentUser"
            [managingOrganisationId]="currentUser.personalOrganisationId"
        >
        </print-personnel-reports>
    </div>
</div>
