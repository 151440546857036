import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatLatitude' })
export class FormatLatitudePipe implements PipeTransform {
    transform(latitude: number) {
        if (latitude == null) {
            return null;
        }
        const absLatitude = Math.abs(latitude);
        const degrees = Math.floor(absLatitude);
        const minutes = (absLatitude - degrees) * 60;

        return `${degrees}&deg; ${minutes.toFixed(2)}${
            latitude > 0 ? 'N' : 'S'
        }`;
    }
}
