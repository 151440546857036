import { Injectable } from '@angular/core';
import {
    FlightConformanceResultDto,
    FlyFreelyError,
    GqlFilterField,
    GqlSortField,
    GraphQlMissionFilters,
    MissionDetailsDto,
    MissionService,
    MissionSummaryDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { ColumnSortPreferences } from '@flyfreely-portal-ui/flyfreely-table';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MissionViewDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private missionsSource = new ReplaySubject<MissionSummaryDto[]>(1);
    private calendarMissionsSource = new ReplaySubject<MissionSummaryDto[]>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);
    private hasMissionsSource = new ReplaySubject<boolean>(1);

    private searchCriteriaSubject = new ReplaySubject<{
        page: number;
        limit: number;
        sorting: ColumnSortPreferences;
        filters: GqlFilterField[];
        organisationId: number;
        conformanceResultStatus: FlightConformanceResultDto.Status[];
        authorityIds?: number[];
        orOrganisationId?: number;
        status?: MissionSummaryDto.Status[];
        startTime: string;
        endTime: string;
    }>(1);

    private calendarSearchCriteriaSubject = new ReplaySubject<{
        startTime: string;
        endTime: string;
        sorting: ColumnSortPreferences;
        filters: GqlFilterField[];
        organisationId: number;
        conformanceResultStatus: FlightConformanceResultDto.Status[];
        authorityIds?: number[];
        orOrganisationId?: number;
        status?: MissionSummaryDto.Status[];
    }>(1);

    searchCriteria$ = this.searchCriteriaSubject.asObservable();
    calendarSearchCriteria$ = this.calendarSearchCriteriaSubject.asObservable();

    working$ = this.workingSource.asObservable();
    missions$ = this.missionsSource.asObservable();
    calendarMissions$ = this.calendarMissionsSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();
    hasMissions$ = this.hasMissionsSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private missionService: MissionService) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));

        this.searchCriteriaSubject.subscribe(criteria =>
            this.updateMissionTable(criteria)
        );

        this.calendarSearchCriteriaSubject.subscribe(criteria =>
            this.updateCalendarMission(criteria)
        );
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.missionsSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.hasMissionsSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.searchCriteriaSubject.complete();
    }

    findMissions(
        page: number,
        limit: number,
        sorting: ColumnSortPreferences,
        filters: GqlFilterField[],
        organisationId: number,
        conformanceResultStatus: FlightConformanceResultDto.Status[],
        authorityIds?: number[],
        orOrganisationId?: number,
        status?: MissionSummaryDto.Status[],
        startTime?: string,
        endTime?: string
    ) {
        this.searchCriteriaSubject.next({
            page,
            limit,
            sorting,
            filters,
            organisationId,
            conformanceResultStatus,
            authorityIds,
            orOrganisationId,
            status,
            startTime,
            endTime
        });
    }

    findCalendarMissions(
        startTime: string,
        endTime: string,
        sorting: ColumnSortPreferences,
        filters: GqlFilterField[],
        organisationId: number,
        conformanceResultStatus: FlightConformanceResultDto.Status[],
        authorityIds?: number[],
        orOrganisationId?: number,
        status?: MissionSummaryDto.Status[]
    ) {
        this.calendarSearchCriteriaSubject.next({
            startTime,
            endTime,
            sorting,
            filters,
            organisationId,
            conformanceResultStatus,
            authorityIds,
            orOrganisationId,
            status
        });
    }

    private updateMissionTable({
        page,
        limit,
        sorting,
        filters,
        organisationId,
        graphQlFilters,
        authorityIds,
        orOrganisationId,
        status,
        conformanceResultStatus,
        startTime,
        endTime
    }: {
        page: number;
        limit: number;
        sorting: ColumnSortPreferences;
        filters: GqlFilterField[];
        organisationId: number;
        graphQlFilters?: GraphQlMissionFilters;
        authorityIds?: number[];
        orOrganisationId?: number;
        status?: MissionSummaryDto.Status[];
        conformanceResultStatus: FlightConformanceResultDto.Status[];
        startTime: string;
        endTime: string;
    }) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const doneWorking = this.workTracker.createTracker();

        // this.subscriptionTypes$ is used here to ensure it has a value and prevent a race condition.
        const sortFields: GqlSortField = {
            field: sorting?.column ?? null,
            order: sorting?.ascending ? 'ASC' : 'DESC'
        };
        this.missionService
            .findMissionSummaries(
                organisationId,
                {
                    page: page,
                    pageSize: limit,
                    sortFields: sorting != null ? [sortFields] : [],
                    filters: filters
                },
                conformanceResultStatus,
                authorityIds,
                orOrganisationId,
                status,
                startTime,
                endTime
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: missions => {
                    const missionDetails: MissionDetailsDto[] =
                        missions.results.map(r => ({
                            ...r,
                            locationName: r.location?.name,
                            organisationName: r.organisation?.name,
                            missionCrewDetails: r.missionCrew.map((p: any) => ({
                                person: p.person,
                                role: p.missionRole
                            })),
                            craftNicknames: r.crafts?.map(
                                (c: any) => c?.nickname
                            ),
                            missionType: r.missionOperationType,
                            missionWorkflowVersion: r.workflowVersion?.workflow,
                            missionApproval: r.missionApprovals
                                .filter(
                                    (a: any) =>
                                        a.status === 'DRAFT' ||
                                        a.status === 'PENDING' ||
                                        a.status === 'BEING_REVIEWED' ||
                                        a.status === 'APPROVED' ||
                                        a.status === 'ACCEPTED'
                                )
                                .slice(-1)[0],
                            flightConformanceResultList:
                                r.flightConformanceResultList
                        }));
                    this.totalItemsSource.next(missions.count);
                    this.currentPageSource.next(page);
                    this.missionsSource.next(missionDetails);
                    if (missions.count > 0) {
                        this.hasMissionsSource.next(true);
                    }
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    console.error(error);
                    this.missionsSource.next([]);
                    this.totalItemsSource.next(0);
                    this.currentPageSource.next(0);
                    doneWorking();
                }
            });
    }

    private updateCalendarMission({
        startTime,
        endTime,
        sorting,
        filters,
        organisationId,
        graphQlFilters,
        authorityIds,
        orOrganisationId,
        status,
        conformanceResultStatus
    }: {
        startTime: string;
        endTime: string;
        sorting: ColumnSortPreferences;
        filters: GqlFilterField[];
        organisationId: number;
        graphQlFilters?: GraphQlMissionFilters;
        authorityIds?: number[];
        orOrganisationId?: number;
        status?: MissionSummaryDto.Status[];
        conformanceResultStatus: FlightConformanceResultDto.Status[];
    }) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const doneWorking = this.workTracker.createTracker();

        // this.subscriptionTypes$ is used here to ensure it has a value and prevent a race condition.
        const sortFields: GqlSortField = {
            field: sorting?.column ?? null,
            order: sorting?.ascending ? 'ASC' : 'DESC'
        };
        this.missionService
            .findMissionSummaries(
                organisationId,
                {
                    page: 0,
                    pageSize: 1000,
                    sortFields: sorting != null ? [sortFields] : [],
                    filters: filters
                },
                conformanceResultStatus,
                authorityIds,
                orOrganisationId,
                status,
                startTime,
                endTime
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: missions => {
                    const missionDetails: MissionDetailsDto[] =
                        missions.results.map(r => ({
                            ...r,
                            locationName: r.location?.name,
                            organisationName: r.organisation?.name,
                            missionCrewDetails: r.missionCrew.map((p: any) => ({
                                person: p.person,
                                role: p.missionRole
                            })),
                            craftNicknames: r.crafts?.map(
                                (c: any) => c?.nickname
                            ),
                            missionType: r.missionOperationType,
                            missionWorkflowVersion: r.workflowVersion?.workflow,
                            missionApproval: r.missionApprovals
                                .filter(
                                    (a: any) =>
                                        a.status === 'DRAFT' ||
                                        a.status === 'PENDING' ||
                                        a.status === 'BEING_REVIEWED' ||
                                        a.status === 'APPROVED' ||
                                        a.status === 'ACCEPTED'
                                )
                                .slice(-1)[0],
                            flightConformanceResultList:
                                r.flightConformanceResultList
                        }));
                    this.calendarMissionsSource.next(missionDetails);
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    console.error(error);
                    this.calendarMissionsSource.next([]);
                    doneWorking();
                }
            });
    }

    /**
     * Check if there are any missions in the organisation if no filters are applied
     * This is used primarily for messaging in the mission list widget
     * @param organisationId the Organisation ID to check against
     */
    checkMissionTotals(
        organisationId: number,
        authorityIds?: number[],
        orOrganisationId?: number
    ) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const doneWorking = this.workTracker.createTracker();
        return this.missionService
            .findMissionSummaries(
                organisationId,
                {
                    page: 0,
                    pageSize: 1,
                    sortFields: [],
                    filters: []
                },
                null,
                orOrganisationId != null ? authorityIds : null,
                orOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.hasMissionsSource.next(result.count > 0);
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    console.error(error);
                    doneWorking();
                }
            });
    }
}
