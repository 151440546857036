import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    FEATURE_ORGANISATION_RELATIONSHIPS,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InvalidOperation,
    LicenceDto,
    PersonRolesDto,
    PersonService,
    PersonsOrganisationDto,
    SubscriptionLimitDto,
    SubscriptionService,
    WorkTracker,
    hasFeatureFlag,
    personSearch,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { OrganisationSubscriptionState } from './organisation-subscription-state.service';

@Component({
    selector: 'subscription-licences',
    template: `
        <div *ngIf="licenceLimit != null">
            {{ licenceLimit.availableLicenceCount }} of
            {{ licenceLimit.instances }} available ({{ assignedCount }} assigned
            {{ licenceLimit.inviteInstancesCount }} invites<ng-container
                *ngIf="canUseOrganisationRelationships"
            >
                {{ licenceLimit.sharedInstancesCount }} shared</ng-container
            >)
        </div>
        <div [formGroup]="formGroup">
            <div class="input-group">
                <ng-select
                    [items]="availablePersonnel$ | async"
                    bindValue="id"
                    appendTo="body"
                    formControlName="personId"
                    placeholder="Select "
                    [readonly]="working"
                    [clearable]="false"
                    [searchFn]="personSearch"
                >
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        {{ item | formatPerson: true }}
                    </ng-template>
                </ng-select>
                <span class="input-group-btn">
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="
                            working ||
                            !formGroup.valid ||
                            (remainingLicences != null &&
                                remainingLicences <= 0)
                        "
                        (click)="assignLicence()"
                    >
                        Add
                    </button>
                </span>
            </div>
            <div
                *ngFor="let licence of activeLicences$ | async"
                class="horizontal-container data "
            >
                <div class="fill">
                    {{ licence.person | formatPerson }} &lt;{{
                        licence.person.email
                    }}&gt;
                </div>
                <button
                    class="btn btn-tertiary"
                    type="button"
                    (click)="unassignLicence(licence.person.id)"
                    [disabled]="working"
                >
                    <span class="fal fa-trash-alt"></span>
                </button>
            </div>
        </div>
    `
})
export class SubscriptionLicences {
    @Input() organisation: PersonsOrganisationDto;

    subscriptionId: number;

    licenceLimit: SubscriptionLimitDto | undefined;

    remainingLicences: number;
    assignedCount: number;

    canUseOrganisationRelationships: boolean;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working = false;

    formGroup = new FormGroup({
        personId: new FormControl(undefined, [Validators.required])
    });

    personnel$ = new BehaviorSubject<PersonRolesDto[]>(undefined);

    availablePersonnel$: Observable<PersonRolesDto[]>;

    activeLicences$: Observable<
        { person: PersonRolesDto; licence: LicenceDto }[]
    >;

    personSearch = personSearch.bind(this);

    constructor(
        private state: OrganisationSubscriptionState,
        private subscriptionService: SubscriptionService,
        private personnelService: PersonService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        state.subscription$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(s => s !== undefined),
                map(data => data.organisationId),
                distinctUntilChanged()
            )
            .subscribe(organisationId => this.refreshPersonnel(organisationId));

        state.subscription$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(s => s !== undefined)
            )
            .subscribe(subscription => {
                this.subscriptionId = subscription?.id;
                this.licenceLimit = subscription?.limits[0];
                this.assignedCount = subscription.activeLicences.length;
                this.remainingLicences =
                    subscription?.limits[0]?.availableLicenceCount ?? null;
            });

        this.availablePersonnel$ = combineLatest([
            this.personnel$,
            state.subscription$
        ]).pipe(
            filter(
                ([personnel, data]) =>
                    personnel !== undefined && data !== undefined
            ),
            map(([personnel, data]) => {
                const personIds = data.activeLicences
                    .filter(
                        l =>
                            l.entityType ===
                                this.licenceLimit.licencedEntityType &&
                            l.endTime == null
                    )
                    .map(l => l.entityId);
                return personnel.filter(p => personIds.indexOf(p.id) === -1);
            })
        );

        this.activeLicences$ = combineLatest([
            this.personnel$,
            state.subscription$
        ]).pipe(
            filter(
                ([personnel, data]) =>
                    personnel !== undefined && data !== undefined
            ),
            map(([personnel, data]) => {
                const lookup = personnel.reduce(toLookup, {});

                return data.activeLicences
                    .filter(
                        l =>
                            l.entityType ===
                                this.licenceLimit.licencedEntityType &&
                            l.endTime == null
                    )
                    .map(l => ({
                        person:
                            lookup[l.entityId] ??
                            ({
                                id: l.entityId,
                                firstName: 'Unknown',
                                lastName: 'User'
                            } as PersonRolesDto),
                        licence: l
                    }));
            })
        );
    }

    ngOnInit() {
        this.canUseOrganisationRelationships = hasFeatureFlag(
            this.organisation,
            FEATURE_ORGANISATION_RELATIONSHIPS
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPersonnel(organisationId: number) {
        this.personnelService
            .findPersonnel(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                personnel => {
                    this.personnel$.next(personnel);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while fetching personnel: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    assignLicence() {
        this.subscriptionService
            .assignLicence(this.subscriptionId, {
                entityId: this.formGroup.value.personId,
                entityType: this.licenceLimit.licencedEntityType
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                subscription => {
                    this.logging.success(`Licence assigned`);
                    this.state.change(subscription);
                    this.formGroup.reset();
                },
                (error: FlyFreelyError) => {
                    if (
                        error instanceof InvalidOperation &&
                        error.code === 'LICENCE_LIMIT'
                    ) {
                        this.logging.error(
                            error,
                            `You have reached the limit for this type of licence`
                        );
                    } else {
                        this.logging.error(
                            error,
                            `Error while assigning licence: ${error.message}`
                        );
                    }
                }
            )

            .add(this.workTracker.createTracker());
    }

    unassignLicence(personId: number) {
        this.subscriptionService
            .unassignLicence(this.subscriptionId, {
                entityId: personId,
                entityType: this.licenceLimit.licencedEntityType
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                subscription => {
                    this.logging.success(`Licence unassigned`);
                    this.state.change(subscription);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while unassigning licence: ${error.message}`
                    )
            )

            .add(this.workTracker.createTracker());
    }
}
