import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MissionObjectivesList } from './mission-objectives-list.component';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { EditableModule } from '@flyfreely-portal-ui/editable';

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        NgSelectModule,
        EnhancedHelpModule,
        AnalyticsModule,
        EditableModule
    ],
    providers: [],
    bootstrap: [],
    declarations: [MissionObjectivesList],
    exports: [MissionObjectivesList]
})
export class MissionObjectivesModule {}
