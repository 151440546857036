/**
 * This file contains common parsers, validators and parser options primarily used by the bulk uploaders
 */

import {
    BatteryDto,
    BatterySetDto,
    CraftDto,
    EquipmentDto,
    NameValue
} from '@flyfreely-portal-ui/flyfreely';
import {
    CHECK_DECIMAL,
    CHECK_HOURS_MINUTES_SECONDS,
    CHECK_IS_TIME_STRING
} from './checks';
import * as moment from 'moment-timezone';

// This is the text that will display next to all invalid fields in the importer.
export const invalidFieldText = '*INVALID*';
export const warningFieldText = '*WARNING*';

/**
 * Helpers for working with dates required to be YYYY-MM-DD
 */
export const dateFormats: NameValue[] = [
    {
        name: 'DD-MM-YYYY',
        value: 'DD-MM-YYYY'
    },
    {
        name: 'MM-DD-YYYY',
        value: 'MM-DD-YYYY'
    },
    {
        name: 'YYYY-MM-DD',
        value: 'YYYY-MM-DD'
    },
    {
        name: 'YYYY-DD-MM',
        value: 'YYYY-DD-MM'
    }
];

export function parseBulkUploadDate(date: string, format: string) {
    if (date == null || date.length === 0) {
        return null;
    }
    if (format != null) {
        return moment(date, format).format('YYYY-MM-DD');
    } else {
        return moment(date).format('YYYY-MM-DD');
    }
}

export function validateBulkUploadDate(date: string, format: string) {
    // If no format was selected, try to parse the date as is
    if (format == null) {
        return parseBulkUploadDate(date, null) !== 'Invalid date';
    }
    // Check that the format matches the date entered, otherwise results in a bad parse
    const yearIndex = format.split('-').findIndex(i => i.length === 4);
    const dividers =
        date.indexOf('-') !== -1 ? '-' : date.indexOf('/') !== -1 ? '/' : null;
    if (
        yearIndex === -1 ||
        dividers == null ||
        date.split(dividers)[yearIndex].length !== 4
    ) {
        return false;
    }
    // Try parsing the date with the suppliad format to see if it's valid
    const altFormat = format.split('-').join('/');
    if (parseBulkUploadDate(date, format) !== 'Invalid date') {
        return true;
    } else {
        return false;
    }
}

/**
 * Function to ensure durations entered into various formats are all returned as seconds
 * Can accept numbers ony (indicating a value already in seconds) and in ##h##m##s format and partial variations thereof
 * @param duration The duration as input by the user
 * @returns the duration in seconds as a string or an the input text as is if invalid so the importer can invalidate it
 */
export function parseDuration(duration: string) {
    // Return the duration as is if null, invalid or only numbers (which assumes that it's already in seconds)
    if (
        duration == null ||
        duration.match(CHECK_DECIMAL) != null ||
        (duration.match(CHECK_DECIMAL) == null &&
            duration.match(CHECK_HOURS_MINUTES_SECONDS) == null)
    ) {
        return duration;
    }
    // If the function has not returned by this point we assume duration is in ##h##m##s format, or some variation thereof

    // first remove all whitespace and convert to lowercase
    const time = duration.toLowerCase().replace(/\s/g, '');

    const hasHours = time.includes('h');
    const hasMinutes =
        time.includes('m') || (hasHours && time.length > time.indexOf('h') + 1);
    const hasSeconds =
        time.includes('s') ||
        (!hasHours && !hasMinutes) ||
        (hasMinutes &&
            time.includes('m') &&
            time.length > time.indexOf('m') + 1);

    const hours = hasHours ? time.slice(0, time.indexOf('h')) : '0';
    const minutes = hasMinutes
        ? time.slice(
              hasHours ? time.indexOf('h') + 1 : 0,
              time.includes('m') ? time.indexOf('m') : time.length + 1
          )
        : '0';
    const seconds = hasSeconds
        ? time.slice(
              hasMinutes ? time.indexOf('m') + 1 : 0,
              time.includes('s') ? time.indexOf('s') : time.length + 1
          )
        : '0';

    const totalSeconds =
        parseInt(hours, 10) * 3600 +
        parseInt(minutes, 10) * 60 +
        parseInt(seconds, 10);

    return totalSeconds.toString();
}

/**
 * Takes a given time string in either HHhMMmSSs format or HH:MM:SS format,
 * with hours and minutes, as well as ending identifiers as optional, and returns the time in HH:MM:SS format.
 * Also allows the time to be in decimal format, at which point it is assumed the time is in hours and converted to HH:MM:SS format.
 * Alternatively, if the time is not in the correct format, returns null.
 * @param time a time string in either HHhMMmSSs format or HH:MM:SS format, with hours as optional, or a string of a decimal number indicating number of hours.
 * @returns a string of the time converted to HH:MM:SS format, or null if the time is not in the correct format
 */
export function convertInputTimeToHoursMinutesSeconds(time: string) {
    if (CHECK_DECIMAL.test(time)) {
        // Convert the decimal time to "HH:MM:SS"
        // This assumes a decimal value indicates number of hours
        const hours = Math.floor(Number(time));
        const minutes = Math.floor((Number(time) - hours) * 60);
        const seconds = Math.floor(
            ((Number(time) - hours) * 60 - minutes) * 60
        );
        return `${hours
            .toString()
            .padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else if (CHECK_IS_TIME_STRING.test(time)) {
        // Extract the time components from the string using regex
        // This will generate an array where:
        // - The first element is the entire match
        // - The second element is the hours
        // - The third element is the minutes
        // - The fourth element is the seconds
        // - The fifth element is the last digits in the match
        // - The sixth element is the last identifier in the match, which can be an empty string if the last digits are not followed by an identifier
        const match = time.match(CHECK_IS_TIME_STRING);

        if (match !== null) {
            // We can determine the number of hours, minutes and seconds from the respective array positions in the regex match
            const hours = match[1] != null ? parseInt(match[1]) : 0;
            const minutes = match[2] != null ? parseInt(match[2]) : 0;
            const seconds = match[3] != null ? parseInt(match[3]) : 0;
            // We can use these values to calculate the total number of seconds that we are sure of
            const totalSecondsFromKnown = hours * 3600 + minutes * 60 + seconds;
            // If we have a identifier in group 5, we can use this to determine the remaining value
            // Otherwise, we can use the known values above to find the remaining value
            let totalSeconds = totalSecondsFromKnown;
            // If we know the number of seconds, match[4] can be ignored as it will most likely be milliseconds, which are not supported
            if (match[3] == null && match[4] != null) {
                if (match[5] != null && match[5] !== '') {
                    switch (match[5]) {
                        case 'h':
                            totalSeconds += parseInt(match[4]) * 3600;
                            break;
                        case 'm':
                            totalSeconds += parseInt(match[4]) * 60;
                            break;
                        case 's':
                            totalSeconds += parseInt(match[4]);
                            break;

                        default:
                            break;
                    }
                } else {
                    // If we know the number of minutes, or don't know any values, match[4] will be the number of seconds
                    // Otherwise, if we know the number of hours, match[4] will be the number of minutes
                    if (
                        match[2] != null ||
                        (match[1] == null && match[2] == null)
                    ) {
                        totalSeconds += parseInt(match[4]);
                    } else if (match[1] != null) {
                        totalSeconds += parseInt(match[4]) * 60;
                    }
                }
            }
            return convertSecondsToTimestamp(totalSeconds);
        }

        return null;
    }

    return null;
}

/**
 * Converts a provided seconds value into an HH:MM:SS format.
 * Doing this via this method and not with moment.js means that the hour value can be more than 24 hours.
 * @param seconds The number of seconds to convert
 * @returns A string of the time in HH:MM:SS format
 */
export function convertSecondsToTimestamp(seconds: number) {
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

/**
 * A function to convert a HH:MM:SS string into a number of seconds.
 * The provided string can have any number of hours, which is different from how we parse time elsewhere.
 * @param time The time to convert, as a string in HH:MM:SS format
 * @returns The number of seconds in the provided time as a number
 */
export function convertTimestampToSeconds(time: string) {
    let splitValues = time.split(':');
    if (splitValues.length !== 3) {
        const timeStamp = convertInputTimeToHoursMinutesSeconds(time);
        if (timeStamp == null) {
            return null;
        }
        splitValues = timeStamp.split(':');
    }
    const hours = parseInt(splitValues[0]);
    const minutes = parseInt(splitValues[1]);
    const seconds = parseInt(splitValues[2]);
    return hours * 3600 + minutes * 60 + seconds;
}

/**
 * Checks if the value is a serial number against all available craft and, if so, returns the craft's name.
 * @param identifier the value passed into the importer in the csv
 * @param allCraft all RPA available to this org
 * @returns the craft name if the identifier matches a serial number, else returns the identifier as is
 */
export function convertRpaSerialToName(
    identifier: string,
    allCraft: CraftDto[]
) {
    const entity = allCraft.find(
        e => e.manufacturerSerialNumber === identifier
    );
    if (entity == null) {
        return identifier;
    } else {
        return entity.nickname;
    }
}

/**
 * Checks if the value is a serial number against all available batteries and, if so, returns the battery's name.
 * @param identifier the value passed into the importer in the csv
 * @param allEquipment all batteries available to this org
 * @returns the entity's name if the identifier matches a serial number, else returns the identifier as is
 */
export function convertBatterySerialToName(
    identifier: string,
    allBatteries: BatteryDto[]
) {
    const entity = allBatteries.find(
        e => e.manufacturerSerialNumber === identifier
    );
    if (entity == null) {
        return identifier;
    } else {
        return entity.name;
    }
}

/**
 * Checks if the value is a serial number against the batteries in each available battery set and, if so, returns the battery set's name.
 * Will try to find a set containing only 1 battery, else will return the first set containing the relevant serial number.
 * @param identifier the value passed into the importer in the csv
 * @param allEquipment all battery sets available to this org
 * @returns the battery set's name if the identifier matches a serial number, else returns the identifier as is
 */
export function convertBatterySetSerialToName(
    identifier: string,
    allBatterySets: BatterySetDto[]
) {
    const allMatching = allBatterySets.filter(e =>
        e.batteries.find(bat => bat.manufacturerSerialNumber === identifier)
    );
    const entity =
        allMatching.find(s => s.batteries.length === 1) ??
        allMatching.length > 0
            ? allMatching[0]
            : null;
    if (entity == null) {
        return identifier;
    } else {
        return entity.name;
    }
}

/**
 * Checks if the value is a serial number against all available equipment and, if so, returns the entity's name.
 * @param identifier the value passed into the importer in the csv
 * @param allEquipment all equipment available to this org
 * @returns the entity's name if the identifier matches a serial number, else returns the identifier as is
 */
export function convertEquipmentSerialToName(
    identifier: string,
    allEquipment: EquipmentDto[]
) {
    const entity = allEquipment.find(
        e => e.manufacturerSerialNumber === identifier
    );
    if (entity == null) {
        return identifier;
    } else {
        return entity.name;
    }
}

/**
 * Takes a duration value from the import CSV and tries to convert it to HH:MM:SS format.
 * If the value is not in any expected format, it will not be changed.
 * @param duration The duration value to convert
 * @returns The duration value in HH:MM:SS format, or the original value if it could not be converted
 */
export function convertDurationToTimestamp(duration: string) {
    const correctTimeFormat = convertInputTimeToHoursMinutesSeconds(duration);
    if (correctTimeFormat != null) {
        return correctTimeFormat;
    } else {
        return duration;
    }
}
