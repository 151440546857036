import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

/**
 * Resolves the route paramMap to an object
 */
@Injectable({ providedIn: 'root' })
export class ParamMapResolver
    implements Resolve<{ [key: string]: number | string }> {
    resolve(route: ActivatedRouteSnapshot) {
        return route.paramMap.keys.reduce((acc, key) => {
            acc[key] = isNaN(+route.paramMap.get(key))
                ? route.paramMap.get(key)
                : +route.paramMap.get(key);
            return acc;
        }, {});
    }
}

/**
 * Combines the route paramMap and queryParamMap and resolves them to an object
 */
@Injectable({ providedIn: 'root' })
export class FullParamMapResolver
    implements Resolve<{ [key: string]: number | string }> {
    resolve(route: ActivatedRouteSnapshot) {
        const paramMap = route.paramMap.keys.reduce((acc, key) => {
            acc[key] = isNaN(+route.paramMap.get(key))
                ? route.paramMap.get(key)
                : +route.paramMap.get(key);
            return acc;
        }, {});
        const queryParamMap = route.queryParamMap.keys.reduce((acc, key) => {
            acc[key] = isNaN(+route.queryParamMap.get(key))
                ? route.queryParamMap.get(key)
                : +route.queryParamMap.get(key);
            return acc;
        }, {});
        return {
            ...paramMap,
            ...queryParamMap
        };
    }
}
