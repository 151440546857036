import { Injectable } from '@angular/core';
import {
    MissionRegisterStatusDetails,
    PersonRolesDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PersonnelAddDialogue } from './add-dialogue/person-add-dialogue.component';
import { PersonnelDialogue } from './details-dialogue/personnel-dialogue.component';
import { PersonnelReportsDialogue } from './personnel-reports-dialogue/personnel-reports-dialogue.component';

export interface PersonnelFieldCsv {
    lastName?: string;
    firstName?: string;
    email: string;
    phoneNumber?: string;
    status?: string;
    inviteId?: number;
    roles: string[];
}

@Injectable()
export class PersonnelDialoguesService {
    constructor(private modalService: BsModalService) {}

    showPersonDetailsDialogue(
        person: PersonRolesDto,
        organisationId: number,
        canEdit: boolean,
        // This is optional unused functionality to show the register status at the time the mission was flown.
        missionDetails?: MissionRegisterStatusDetails
    ) {
        this.modalService.show(PersonnelDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                canEdit,
                organisationId,
                person: { ...person },
                missionDetails
            }
        });
    }

    showAddPersonDialogue(organisationId: number) {
        this.modalService.show(PersonnelAddDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { organisationId }
        });
    }

    showPersonnelReportsDialogue(
        organisationId: number,
        personnel: PersonnelFieldCsv[]
    ) {
        this.modalService.show(PersonnelReportsDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { organisationId, personnel }
        });
    }
}
