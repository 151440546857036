<div *ngIf="notification != null">
    <div class="airspace-notification-card">
        <div class="horizontal-container fill">
            <div class="vertical-container fill">
                <div class="airspace-notification-heading flex-centered">
                    <span class="notification-title airspace-notification">
                        {{ notification.title }}
                    </span>
                    <span class="notification-subtitle airspace-notification">
                        {{ notification.body }}
                    </span>
                    <div
                        class="horizontal-container"
                        style="flex-wrap: wrap"
                        *ngIf="notification.operatorTypes.length"
                    >
                        <ng-container
                            *ngFor="let type of notification.operatorTypes"
                        >
                            <span class="notification-category">{{
                                operatorTypes[type]
                            }}</span>
                        </ng-container>
                    </div>
                    <button
                        *ngIf="notification.url"
                        type="button"
                        class="btn btn-primary btn-sm"
                        style="margin: 5px 10px 5px 5px"
                        (click)="goToUrl()"
                    >
                        View
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
