<div class="widget panel missions">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-libraries.png" />
        </div>
        <div class="panel-title">Document Libraries</div>

        <div class="panel-content">
            <ng-select
                [items]="libraryList$ | async"
                appendTo="#dashboard"
                bindLabel="name"
                [clearable]="false"
                [(ngModel)]="library"
                (ngModelChange)="refreshDocuments()"
                tooltip="Change library"
                placement="left"
            >
            </ng-select>
        </div>

        <div class="panel-buttons">
            <button
                class="btn btn-inline btn-title-action"
                type="button"
                (click)="editLibrary(library)"
                tooltip="Edit library"
                placement="left"
                *ngIf="library && library.availableActions?.canEdit"
            >
                <span class="fal fa-pencil-alt"></span>
            </button>
            <button
                class="btn btn-inline btn-title-action"
                (click)="newLibrary()"
                *ngIf="canAdd"
                tooltip="New library"
                placement="left"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>
    <div class="panel-body">
        <attachment-list
            *ngIf="library?.id"
            [attachmentsHandler]="attachmentsHandler"
            [allowUploads]="library.availableActions.canEdit"
            [allowDelete]="library.availableActions.canEdit"
            [allowEdit]="library.availableActions.canEdit"
            [allowAcknowledgements]="true"
            [requestingOrganisationId]="organisation.id"
            permissions
        >
        </attachment-list>
        <empty
            *ngIf="
                canAdd && (!library || (libraryList && libraryList.length == 0))
            "
            componentName="libraries-empty-editable"
        >
        </empty>
        <empty
            *ngIf="
                !canAdd &&
                (!library || (libraryList && libraryList.length == 0))
            "
            componentName="libraries-empty"
        >
        </empty>
    </div>
</div>
