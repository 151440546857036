import { compareDates } from '@flyfreely-portal-ui/flyfreely';

export const apiKeysTableColumns = (
    formatDateTimePipe: (date: string) => string
) => [
    {
        value: 'name',
        name: 'Name',
        searchable: false,
        selectable: false,
        defaultSelection: true
    },
    {
        value: 'id',
        name: 'Id',
        searchable: false,
        selectable: false,
        defaultSelection: true
    },
    {
        value: 'creationTime',
        name: 'Created',
        searchable: false,
        selectable: false,
        defaultSelection: true,
        formatterFunction: t => formatDateTimePipe(t),
        compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
    },
    {
        value: 'lastUseTime',
        name: 'Last Accessed',
        searchable: false,
        selectable: false,
        defaultSelection: true,
        formatterFunction: t => formatDateTimePipe(t),
        compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
    }
];

export const apiKeysTableConfig = (
    revokeKey: (key: { name: string; id: string }) => void
) => ({
    limit: 25,
    actions: [
        {
            action: (item: { name: string; id: string }) => revokeKey(item),
            icon: 'fal fa-trash-alt',
            tooltip: 'Revoke this key'
        }
    ]
});
