import { FeatureProperties, MapModes } from '../constants';
import { createBufferFromLine } from '../utils';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Polygon, GeometryCollection, LineString, Feature } from 'geojson';

class AreaLineState {
    line: any; // DrawFeature
    polygon: any; // DrawFeature

    currentVertexPosition: number;
    direction: string;
    isDragging: boolean;
}
// We're extending the default line string
const AreaDrawLine = { ...MapboxDraw.modes.draw_line_string };

AreaDrawLine.onSetup = function (opts: { featureId?: any }): AreaLineState {
    opts = opts || {};
    const featureId = opts.featureId;

    let currentVertexPosition, line, polygon;
    const direction = 'forward';

    if (featureId) {
        // only polygon was added to the map
        // we need to recreate the line (which runs through the middle) again and add it.
        line = this.getFeature(featureId);
        polygon = this.newFeature({
            type: 'Feature',
            properties: {
                meta: FeatureProperties.AREA_LINE,
                customDrawMode: MapModes.DRAW_AREA_LINE_SELECT,
                lineOffsetMeters: 50,
                parent: line.id
            },
            geometry: {
                type: 'Polygon',
                coordinates: []
            }
        });
        currentVertexPosition = line.coordinates.length - 1;
        this.addFeature(polygon);
        this.setSelected(line.id);
    } else {
        this.clearSelectedFeatures();
        line = this.newFeature({
            type: 'Feature',
            properties: {
                meta: FeatureProperties.AREA_LINE,
                customDrawMode: MapModes.DRAW_AREA_LINE_SELECT,
                lineOffsetMeters: 50
            },
            geometry: {
                type: 'LineString',
                coordinates: []
            }
        });
        polygon = this.newFeature({
            type: 'Feature',
            properties: {
                meta: FeatureProperties.AREA_POLYGON,
                customDrawMode: MapModes.DRAW_AREA_LINE_SELECT,
                parent: line.id
            },
            geometry: {
                type: 'Polygon',
                coordinates: []
            }
        });
        // line.properties.relatedFeatureIds = [polygon.id];
        // polygon.properties.relatedFeatureIds = [line.id];
        currentVertexPosition = 0;
        this.addFeature(line);
        this.addFeature(polygon);
        this.setSelected(line.id);
    }
    return {
        line,
        polygon,
        currentVertexPosition,
        direction,
        isDragging: false
    };
};

// on mouse move, update the line coordinate and measurement
AreaDrawLine.onMouseMove = function (
    state: AreaLineState,
    e: { lngLat: { lng: any; lat: any } }
) {
    state.line.updateCoordinate(
        state.currentVertexPosition,
        e.lngLat.lng,
        e.lngLat.lat
    );
    const lineGeoJson: Feature<LineString> = state.line.toGeoJSON();

    const buffer = createBufferFromLine(
        lineGeoJson.geometry,
        state.line.properties.lineOffsetMeters
    );
    if (buffer != null) {
        state.polygon.incomingCoords(buffer.geometry.coordinates);
    }
};

AreaDrawLine.onTrash = function (state: AreaLineState) {
    // remove last added coordinate

    if (state.currentVertexPosition < 2) {
        return;
    }

    state.line.removeCoordinate(`${state.currentVertexPosition}`);
    state.currentVertexPosition--;
    const lastCoordinate = state.line.coordinates[state.currentVertexPosition];

    state.line.updateCoordinate(
        state.currentVertexPosition,
        lastCoordinate.lngLat.lng,
        lastCoordinate.lngLat.lat
    );
    // unfortunately, below does not work. See https://github.com/mapbox/mapbox-gl-draw/issues/959
    // it does not execute displayFeatures.
    this.doRender(state.line.id);

    return state;
};

AreaDrawLine.onStop = function (state: AreaLineState) {
    this.activateUIButton();
    // check to see if we've deleted this feature
    if (this.getFeature(state.line.id) === undefined) {
        return;
    }

    if (!state.isDragging) {
        // remove last added coordinate
        state.line.removeCoordinate(`${state.currentVertexPosition}`);
    }
    if (state.polygon.isValid()) {
        const newFeatureGeoJson = state.polygon.toGeoJSON();
        // newFeatureGeoJson.properties.lineOffsetMeters =
        //     state.line.properties.lineOffsetMeters;
        // newFeatureGeoJson.properties.lineGeoJson = state.line.toGeoJSON();
        // this.map.fire(MapboxDraw.constants.events.CREATE, {
        //     features: [state.line.toGeoJSON(), newFeatureGeoJson]
        // });
        this.map.fire(MapboxDraw.constants.events.CREATE, {
            features: [buildAreaLineFeature(state.line.toGeoJSON().geometry, state.line.properties)]
        });
    } else {
        this.deleteFeature(state.line.id, {
            silent: true
        });
        this.deleteFeature(state.polygon.id, {
            silent: true
        });
        this.changeMode(MapModes.SIMPLE_SELECT as MapboxDraw.DrawMode, {}, { silent: true });
    }
};

export function buildAreaLineFeature(line: LineString, properties: {lineOffsetMeters?: number}) {
    const lineOffsetMeters = properties.lineOffsetMeters ?? 50;
    const buffer = createBufferFromLine(
        line,
        lineOffsetMeters
    );
    return {
        type: 'Feature',
        properties: {
            lineOffsetMeters
        },
        geometry: {
            type: 'GeometryCollection',
            geometries: [buffer.geometry, line]
        } as GeometryCollection<LineString | Polygon>
    }
}

export default AreaDrawLine;
