import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface DocumentPreviewPayload {
    id: number;
    attachmentVersionId: number;
    name: string;
    description: string | null;
    purpose: string | null;
    contentType: string;
    version: number;
    originalFileName: string;
    changes: string | null;
    uploadTime: string;
    requiresAcknowledgement: boolean;
    requirementId: number | null;
    downloadUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class AttachmentViewService {
    private attachmentViewSubject = new BehaviorSubject<DocumentPreviewPayload>(
        null
    );

    attachment$ = this.attachmentViewSubject.asObservable();

    constructor(private http: HttpClient) {}

    ngOnDestroy() {
        this.attachmentViewSubject.complete();
    }

    fetchFile(url: string): Observable<Blob> {
        return this.http.get(url, { responseType: 'blob' });
    }

    // Method to update the Subject's value with a single array
    dispatchDocumentPreviewMessage(attachmentPayload: DocumentPreviewPayload) {
        this.attachmentViewSubject.next(attachmentPayload);
    }
}
