import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CurrentPersonDto,
    FlyFreelyConstants,
    hasAnyPermission,
    HubspotService,
    LoggedInUser,
    PersonsOrganisationDto,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { AvailableWidgets } from '@flyfreely-portal-ui/widget';
import {
    OrganisationSubscriptionsService,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { DynamicHelpEditService } from 'libs/enhanced-help/src/lib/dynamic-help-editor/dynamic-help-edit.service';
import { NotificationDialoguesService } from 'libs/notifications/src/lib/notifications-dialogues.service';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.scss']
})
export class WorkSpaceComponent {
    organisationId: number;

    scrollToListener: any;
    selectedWidgets: AvailableWidgets;
    // onChangeOrganisation: any;
    availableWidgets: any;

    showDynamicHelpEdit$ = this.dynamicHelpEditService.editMode$;

    showAlternativeSupport = false;
    alternativeSupportUrl?: SafeResourceUrl;

    canAdminOrganisation = false;

    newLayout = false;

    currentUser: CurrentPersonDto | undefined;
    currentOrganisationLoaded = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private sharedService: WorkspaceStateService,
        private subscriptionService: OrganisationSubscriptionsService,
        private hubspotService: HubspotService,
        private dynamicHelpEditService: DynamicHelpEditService,
        private notificationDialoguesService: NotificationDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        sanitizer: DomSanitizer,
        constants: FlyFreelyConstants,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.alternativeSupportUrl =
            constants.ALTERNATIVE_SUPPORT_URL != null &&
            constants.ALTERNATIVE_SUPPORT_URL.length > 0
                ? sanitizer.bypassSecurityTrustResourceUrl(
                      constants.ALTERNATIVE_SUPPORT_URL
                  )
                : null;
    }

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(params => {
                this.organisationId =
                    params?.organisationId != null
                        ? parseInt(params.organisationId)
                        : null;
                this.sharedService.changeOrganisation(this.organisationId);
            });

        if (this.alternativeSupportUrl == null) {
            this.showAlternativeSupport = false;
            this.hubspotService.showHubspotConversations();
        } else {
            combineLatest([
                this.hubspotService.hubspotConversationAvailable$,
                this.subscriptionService.currentSubscriptions$
            ])
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(([conversationAvailable, subscription]) => {
                    if (
                        subscription.type === 'no_subscriptions' ||
                        subscription.type === 'personal_organisation' ||
                        !conversationAvailable
                    ) {
                        this.showAlternativeSupport = true;
                        this.hubspotService.hideHubspotConversations();
                        return;
                    }
                    this.showAlternativeSupport = false;
                    this.hubspotService.showHubspotConversations();
                });
        }

        combineLatest([
            this.userService.userChange$.pipe(
                filter<LoggedInUser>(c => c.type === UserStatus.LOGGED_IN)
            ),
            this.sharedService.currentOrganisation$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([changes, organisation]) => {
                this.currentOrganisationLoaded =
                    organisation.type !== 'loading';
                this.currentUser = changes.currentUser;

                this.canAdminOrganisation =
                    organisation.type === 'organisation_loaded' &&
                    !organisation.organisation.personalOrganisation &&
                    (hasAnyPermission(organisation.organisation, [
                        PersonsOrganisationDto.Permissions.ORGANISATION_EDIT,
                        PersonsOrganisationDto.Permissions
                            .FLIGHT_OPERATIONS_CONFIGURATION_VIEW
                    ]) ||
                        organisation.organisation.owner);

                this.newLayout = organisation.type === 'organisation_loaded';

                // Handle bad routes that can happen on occasion, such as routing without parameters and
                // ending up on the empty dashboard route. Once ready, redirect to the default tab
                const currentUrl = this.router.url.split('/');
                const lastPath = currentUrl[currentUrl.length - 1];
                const queryParams = this.activatedRoute.snapshot.queryParams;
                if (
                    organisation.type === 'organisation_loaded' &&
                    // If there are parameters, these will be included in the lastPath
                    (lastPath.split('?').includes('home') ||
                        queryParams == null ||
                        Object.keys(queryParams).length === 0)
                ) {
                    this.router.navigate(['home'], {
                        queryParamsHandling: 'merge'
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    logout() {
        this.userService.logout();
    }

    showAllWhatsNew() {
        this.notificationDialoguesService.showAllWhatsNew();
    }

    changeWidgetSelection(updatedSelection: any) {
        this.selectedWidgets = { ...updatedSelection };
    }

    scrollTo(widgetId: number) {
        if (!this.scrollToListener) {
            return;
        }
        this.scrollToListener(widgetId);
    }

    registerScrollToListener(listener: any) {
        this.scrollToListener = listener;
    }

    removeScrollToListener() {
        this.scrollToListener = null;
    }

    showUserProfile() {
        this.router.navigate(['userprofile'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    }

    showOrgAdmin() {
        this.commonDialoguesService.showConfirmationDialogue(
            'Organisation Admin Has Moved',
            'To access organisation admin, use the cog icon on the main navigation bar',
            'Got it',
            () => Promise.resolve()
        );
    }
}
