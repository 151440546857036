<ng-container screenAnalytics="flight-conformance-setup">
    <enhanced-help
        screenName="flight-conformance-setup"
        componentName="flight-conformance-overview"
        [compact]="true"
    >
    </enhanced-help>
    <div class="modal-body-content container-scroll contents">
        <h4 class="section-heading">
            <span>Default Flight Conformance Policy</span>
            <div></div>
        </h4>
        <enhanced-help
            screenName="flight-conformance-setup"
            componentName="flight-conformance-default"
            [compact]="true"
        >
        </enhanced-help>
        <ng-container
            *ngIf="defaultPolicy$ | async as defaultPolicy; else noDefault"
        >
            <flight-conformance-policy
                [policy]="defaultPolicy"
                (updatePolicy)="updatePolicy($event)"
                (updateRule)="updateRule($event)"
            ></flight-conformance-policy>
        </ng-container>

        <ng-template #noDefault>
            <div *ngIf="!working" class="alert alert-info">
                You do not have a default policy setup.
                <button
                    type="button"
                    class="btn btn-default"
                    (click)="setupDefaultPolicy()"
                    [disabled]="working"
                >
                    Setup Default
                </button>
            </div>
        </ng-template>

        <div *ngFor="let policy of nonDefaultPolicyList$ | async">
            <h4 class="section-heading">
                <span
                    >{{ policy.authorityType?.name }} Flight Conformance
                    Policy</span
                >
                <div></div>
            </h4>
            <flight-conformance-policy
                [policy]="policy"
                (updatePolicy)="updatePolicy($event)"
                (updateRule)="updateRule($event)"
            ></flight-conformance-policy>
        </div>
        <div>
            <h4 class="section-heading">
                <span>New Policy</span>
                <div></div>
            </h4>
            <enhanced-help
                screenName="flight-conformance-setup"
                componentName="flight-conformance-authority-type"
                [compact]="true"
            >
            </enhanced-help>
            <div class="form-group">
                <label>New Policy For Authority Type</label>
                <div class="input-group">
                    <ng-select
                        [items]="authorityTypeList$ | async"
                        bindLabel="name"
                        placeholder="Select an authority type"
                        [clearable]="false"
                        [(ngModel)]="selectedAuthorityType"
                    ></ng-select>
                    <span class="input-group-btn">
                        <button
                            type="button"
                            class="btn btn-primary"
                            [disabled]="selectedAuthorityType == null"
                            (click)="setupPolicy(selectedAuthorityType)"
                        >
                            Setup
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</ng-container>
