import { Component, Input } from '@angular/core';
import {
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { OrganisationSubscriptionState } from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PurchaseSubscriptionService } from './purchase-subscription/purchase-subscription.service';

enum Status {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    TRIALING = 'TRIALING',
    NO_SUBSCRIPTION = 'NO_SUBSCRIPTION'
}

@Component({
    selector: 'organisation-subscriptions',
    templateUrl: './organisation-subscriptions.component.html',
    styles: [
        
    ],
    providers: [OrganisationSubscriptionState, PurchaseSubscriptionService]
})
export class OrganisationSubscriptions {
    @Input() organisation: PersonsOrganisationDto;

    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    daysToExpiry: number;
    subscriptionName: string;
    subscriptionId: number;

    freePlanName: string;

    status = Status.LOADING;

    constructor(
        private purchaseSubscriptionService: PurchaseSubscriptionService,
        private state: OrganisationSubscriptionState
    ) {
        this.state.subscription$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(subscription => {
                this.status =
                    subscription == null
                        ? Status.NO_SUBSCRIPTION
                        : subscription.externalStatus === 'trialing'
                        ? Status.TRIALING
                        : Status.LOADED;

                if (this.status === Status.TRIALING) {
                    this.daysToExpiry =
                        subscription.trialEndTime != null
                            ? -moment().diff(
                                  moment(subscription.trialEndTime),
                                  'days'
                              )
                            : null;
                    this.subscriptionName = subscription.name;
                    this.subscriptionId = subscription.id;
                }
            });
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.state.refresh(this.organisation.id);

        this.purchaseSubscriptionService.availablePlans$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(plans => {
                this.freePlanName = plans.freePlan.name;
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
