import {
    AddressDto,
    AddressService,
    DO_NOTHING,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { AddressDialoguesService } from '../address-dialogues.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'address-list',
    templateUrl: './address-list.component.html'
})
export class AddressList {
    @Input() organisationId: number;
    addresses: AddressDto[];

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private addressService: AddressService,
        private commonDialoguesService: CommonDialoguesService,
        private addressDialoguesService: AddressDialoguesService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshProfile();
        this.addressService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(change => this.refreshProfile());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshProfile() {
        const doneWorking = this.workTracker.createTracker();
        this.addressService
            .findAddressesForOrganisation(this.organisationId)
            .toPromise()
            .then((addresses: AddressDto[]) => (this.addresses = addresses))
            .finally(() => doneWorking());
    }

    newAddress() {
        this.addressDialoguesService.showAddressEdit(
            this.organisationId,
            undefined,
            'New Address'
        );
    }

    deleteAddress(address: AddressDto) {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Address',
                'Do you wish to delete this address',
                'Delete',
                () => this.addressService.deleteAddress(address.id).toPromise()
            )
            .then(
                () => this.refreshProfile(),
                DO_NOTHING
            )
            .catch(() => doneWorking())
            .finally(() => doneWorking());
    }

    editAddress(address: AddressDto) {
        this.addressDialoguesService.showAddressEdit(
            this.organisationId,
            { ...address },
            'Update Address'
        );
    }
}
