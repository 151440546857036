import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    MaintenanceActivityDto,
    PersonsOrganisationDto,
    ResourceComponentInServiceTime,
    SimpleMaintenanceScheduleDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import {
    buildTargetedUrl,
    buildTargetedUrlWithParams
} from 'libs/ui/src/lib/routing-helpers/routing-helpers';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BulkMaintenance } from './bulk/bulk-maintenance-dialogue.component';
import { CompletedMaintenanceActivityEditDialogue } from './completed-maintenance-activity-edit-dialogue/completed-maintenance-activity-edit-dialogue.component';
import { LinkTestFlightComponent } from './flight-test/link-flight-test/link-flight-test.component';
import { MaintenanceDetailsDialogue } from './maintenance-details-dialogue/maintenance-details-dialogue.component';
import { MaintenanceFormService } from './maintenance-form.service';
import { MaintenanceReportsDialogue } from './maintenance-reports-dialogue/maintenance-reports-dialogue.component';
import {
    DisplayableMaintenanceActivity,
    MaintenanceRequestService
} from './maintenance-request.service';
import { MaintenanceScheduleDialogue } from './maintenance-schedule/maintenance-schedule-dialogue.component';
import { AirworthnessNoticesDialogue } from './airworthness-notice/airworthness-notices-dialogue.component';
import { AirworthnessNoticesEditDialogue } from './airworthness-notice/airworthness-notices-edit-dialogue/airworthness-notices-edit-dialogue.component';
import { LinkNoticeToMaintenanceComponent } from './airworthness-notice/link-notice-to-maintenance-dialogue/link-notice-to-maintenance.component';
import { AirworthnessNoticesDto } from 'libs/flyfreely/src/lib/model/api/airworthnessNoticeDto';
@Injectable()
export class MaintenanceDialogues {
    constructor(
        private modalService: BsModalService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}


    showBulkMaintenance(organisation: PersonsOrganisationDto) {
        this.modalService.show(BulkMaintenance, {
            ...MODAL_OPTIONS,
            initialState: { organisation }
        });
    }

    showMaintenanceReportsDialogue(organisationId: number) {
        this.modalService.show(MaintenanceReportsDialogue, {
            ...MODAL_OPTIONS,
            initialState: { organisationId }
        });
    }
    showAirworthnessNotice(organisationId: number) {
        this.modalService.show(AirworthnessNoticesDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { organisationId }
        });
    }
    // showEditAirworthnessNotice(organisationId: number) {
    //     this.modalService.show(AirworthnessNoticesEditDialogue, {
    //         ...MODAL_OPTIONS,
    //         class: 'modal-md',
    //         initialState: { organisationId }
    //     });
    // }

    showEditAirworthnessNotice(organisationId: number, notice?: AirworthnessNoticesDto) {
        const isEditMode = !!notice; // Determine if we're in edit mode based on the presence of a notice
    
        const initialState = {
            organisationId,
            notice: notice , // Pass the notice if provided, otherwise null
            isEditMode: isEditMode // Add a flag to indicate the mode
        };
    
        this.modalService.show(AirworthnessNoticesEditDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: initialState // Use the updated initialState
        });
    }
    showLinkmaintenanceRequest(organisationId: number) {
        this.modalService.show(LinkNoticeToMaintenanceComponent, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { organisationId }
        });
    }


    showMaintenanceDetails(organisationId: number, maintenanceLogId?: number) {
        if (maintenanceLogId) {
            this.router.navigateByUrl(
                buildTargetedUrlWithParams(
                    `maintenance/edit/${maintenanceLogId}`,
                    this.activatedRoute
                )
            );
        } else {
            this.router.navigate([buildTargetedUrl(`maintenance/add`)], {
                queryParamsHandling: 'preserve',
                relativeTo: this.activatedRoute
            });
        }

    }
    showBulkMaintenanceDetails(organisationId: number) {
        this.router.navigate([buildTargetedUrl(`maintenance/bulk`)], {
            queryParamsHandling: 'preserve',
            // relativeTo: this.activatedRoute
        });
    }

    showMaintenanceDetailsModal(
        organisationId: number,
        maintenanceLogId?: number
    ) {
        if (maintenanceLogId) {
            return this.modalService.show(MaintenanceDetailsDialogue, {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    organisationId,
                    maintenanceLogId
                }
            });
        } else {
            return this.modalService.show(MaintenanceDetailsDialogue, {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    organisationId
                }
            });
        }
    }

    showMaintenanceScheduleDialogue(
        maintenanceSchedule: SimpleMaintenanceScheduleDto,
        organisationId: number
    ) {
        return this.modalService.show(MaintenanceScheduleDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { maintenanceSchedule, organisationId }
        });
    }

    showLinkTestFlightDialogue(
        organisationId: number,
        maintenanceActivity: MaintenanceActivityDto,
        rpaId?: number
    ) {
        return this.modalService.show(LinkTestFlightComponent, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                organisationId,
                maintenanceActivity,
                rpaId
            }
        });
    }

    showEditCompletedActivityDialogue(
        organisation: PersonsOrganisationDto,
        canUseMaintenanceTasks: boolean,
        activity: DisplayableMaintenanceActivity,
        maintenanceLogId: number,
        components: ResourceComponentInServiceTime[],
        maintenanceRequestService: MaintenanceRequestService,
        maintenanceFormService: MaintenanceFormService
    ) {
        return this.modalService.show(
            CompletedMaintenanceActivityEditDialogue,
            {
                class: 'modal-lg',
                initialState: {
                    organisation,
                    canUseMaintenanceTasks,
                    activity,
                    maintenanceLogId,
                    components
                },
                providers: [
                    {
                        provide: MaintenanceRequestService,
                        useValue: maintenanceRequestService
                    },
                    {
                        provide: MaintenanceFormService,
                        useValue: maintenanceFormService
                    }
                ]
            }
        );
    }
}
