import { Injector, Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that is passed another pipe name, or a function to call.
 */
@Pipe({
    name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {
    public constructor(private injector: Injector) {}

    transform(value: any, pipeToken: any, pipeArgs: any[]): any {
        if (typeof pipeToken === 'function') {
            return pipeToken(value);
        } else if (typeof pipeToken === 'string') {
            const pipe = this.injector.get(pipeToken);
            return pipe.transform(value, ...pipeArgs);
        } else {
            return value;
        }
    }
}
