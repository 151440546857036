import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'formatLocalDateTimeWithUTC'
})
export class FormatLocalDateTimeWithUTCPipe implements PipeTransform {
    transform(time: string | Date, timeZone?: string): string {
        if (!time) {
            return null;
        }
        const localTime =
            timeZone != null
                ? moment(time).tz(timeZone).format('HH:mm D MMM YYYY zz')
                : moment(time).format('HH:mm D MMM YYYY zz');
        const utc = moment(time).utc().format('YYYY-MM-DD HH:mm');
        return `${localTime} (${utc}Z)`;
    }
}

@Pipe({
    name: 'formatLocalDateTimeWithUTCProtracted'
})
export class FormatLocalDateTimeWithUTCProtractedPipe implements PipeTransform {
    transform(time: string | Date, timeZone?: string): string {
        if (!time) {
            return null;
        }
        const localTime =
            timeZone != null
                ? moment(time).tz(timeZone).format('HH:mm D MMM zz')
                : moment(time).format('HH:mm D MMM zz');
        const utc = moment(time).utc().format('YY-MM-DD HH:mm');
        return `${localTime} (${utc}Z)`;
    }
}
