import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-checkbox',
    template: `
        <div class="checkbox">
            <label>
                <input
                    type="checkbox"
                    class="formly-field-checkbox"
                    [class.is-invalid]="showError"
                    [indeterminate]="
                        to.indeterminate && formControl.value === null
                    "
                    [formControl]="formControl"
                    [formlyAttributes]="field"
                />
                <span class="cr"
                    ><i class="cr-icon glyphicon glyphicon-ok"></i>
                </span>
                {{ to.label }}
            </label>
        </div>
    `
})
export class FormlyCheckbox extends FieldType {
    defaultOptions = {
        props: {
            indeterminate: false,
            hideLabel: true
        }
    };
}
