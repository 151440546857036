import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionObjectiveDto,
    MissionObjectivesService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'mission-objectives-list',
    templateUrl: './mission-objectives-list.component.html',
    host: {
        class: 'container-with-footer'
    }
})
export class MissionObjectivesList {
    @Input() organisation: PersonsOrganisationDto;

    private ngUnsubscribe$: Subject<void> = new Subject();
    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    objectives: MissionObjectiveDto[];

    createFormGroup = new FormGroup({
        name: new FormControl(undefined, Validators.required)
    });

    constructor(
        private missionObjectivesService: MissionObjectivesService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.missionObjectivesService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshObjectives());

        this.refreshObjectives();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshObjectives() {
        this.missionObjectivesService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(objectives => (this.objectives = objectives))
            .add(this.workTracker.createTracker());
    }

    updateObjectiveName = (value: { name: string; objectiveId: number }) => {
        if (value == null || value.name == null || value.objectiveId == null) {
            return Promise.reject();
        }
        return firstValueFrom(
            this.missionObjectivesService
                .update(value.objectiveId, {
                    name: value.name
                })
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    tap({
                        next: () =>
                            this.logging.success('Objective name updated'),
                        error: (error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                'Could not update objective name'
                            )
                    })
                )
        ).finally(this.workTracker.createTracker());
    };

    addObjective() {
        this.missionObjectivesService
            .create({
                name: this.createFormGroup.get('name').value,
                organisationId: this.organisation.id
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => {
                    this.logging.success(
                        `Successfully added mission objective`
                    );
                    this.createFormGroup.setValue({ name: null });
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error adding mission objective: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }
}
