export class MapModes {
    static DRAW_LINE_STRING = 'draw_line_string';
    static DRAW_SINGLE_LINE = 'draw_single_line';
    static DRAW_SINGLE_LINE_SELECT = 'draw_single_line_select';
    static DRAW_SINGLE_LINE_POINT_SELECT = 'draw_single_line_point_select';
    static DRAW_RADIUS = 'draw_radius';
    static DRAW_RADIUS_SELECT = 'draw_radius_select';
    static DRAW_POLYGON = 'draw_polygon';
    static SIMPLE_SELECT = 'simple_select';
    static DIRECT_SELECT = 'direct_select';
    static DRAW_AREA_LINE = 'draw_area_line';
    static DRAW_AREA_LINE_SELECT = 'draw_area_line_select';
}

export enum FeatureProperties {
    MEASUREMENT_LINE = 'measurementLine',
    MEASUREMENT_RADIUS_LINE = 'measurementRadiusLine',
    MEASUREMENT_RADIUS = 'measurementRadius',
    MEASUREMENT_LABEL = 'measurementLabel',
    AREA_LINE = 'areaLine',
    AREA_POLYGON = 'areaPolygon'
}
