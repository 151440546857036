import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import { CurrentPersonDto, UpdatePersonDetailsCommand } from '../model/api';
import { UserService } from './system/user.service';

/**
 * The profile service is a facade to the user and organisation profile view,
 * and the underlying services that are required to modify these profiles, such
 * as the address service.
 */
@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private baseUrl: string;

    constructor(
        constants: FlyFreelyConstants,
        private userService: UserService,
        private http: HttpClient
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    requestEmailChange(email: string) {
        return this.http.post(`${this.baseUrl}/webapi/user/email`, {
            email: email
        });
    }

    completeEmailChange(email: string, code: string) {
        return this.http
            .post(`${this.baseUrl}/webapi/user/email/change`, {
                email: email,
                code: code
            })
            .pipe(
                tap(() => {
                    this.userService.updateCurrentUser(
                        { ...this.userService.getCurrentUser(), email },
                        this.userService.findUsersOrganisations()
                    );
                })
            );
    }

    /**
     * Update the current user's details.
     */
    updateDetails(details: UpdatePersonDetailsCommand) {
        return this.http
            .put<CurrentPersonDto>(
                `${this.baseUrl}/webapi/user/details`,
                details
            )
            .pipe(
                tap(updatedUser =>
                    this.userService.updateCurrentUser(
                        updatedUser,
                        this.userService.findUsersOrganisations()
                    )
                )
            );
    }
}
