import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { InvitesLandingComponent } from './invites/invites-landing.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule,
        ValidationModule,
        SharedUiModule,
        LoadingIndicatorModule,
        AnalyticsModule
    ],
    declarations: [InvitesLandingComponent],
    providers: [CookieService]
})
export class UserModule {}
