<div
    class="table-overlay"
    [ngClass]="{ 'overlay-active': expand }"
    (click)="closeSheet = true"
    [@fadeIn]="expand"
    [@fadeOut]="closeSheet"
></div>
<div
    class="side-sheet"
    [ngClass]="{ 'side-sheet-expanded': expand }"
    [@slideInRight]="expand"
    [@slideOutRight]="closeSheet"
    (@slideInRight.done)="closeSheet = false"
    (@slideOutRight.done)="closeSideSheet.emit()"
>
    <tabset class="fill scroll" style="max-height: 100%" *ngIf="expand">
        <tab heading="Authorities">
            <register-conditions mode="AUTHORITY"> </register-conditions>
        </tab>
        <tab heading="Manual Checks">
            <register-conditions mode="MANUAL"> </register-conditions>
        </tab>
        <tab heading="Settings">
            <register-settings></register-settings>
        </tab>
    </tabset>

    <div class="side-sheet-footer">
        <ng-content></ng-content>
        <button
            type="button"
            class="btn btn-sm btn-block btn-primary"
            (click)="save()"
            [disabled]="working"
        >
            Apply
        </button>
    </div>
</div>
