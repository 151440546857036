import { Injectable } from '@angular/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConditionLibraryEditDialogue } from './condition-library-edit/condition-library-edit.component';

@Injectable()
export class ConditionLibraryDialoguesService {
    constructor(private modalService: BsModalService) {}

    newConditionLibrary(organisationId: number) {
        const modal = this.modalService.show(ConditionLibraryEditDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                organisationId
            }
        });

        return modal;
    }

    /**
     * Displays the condition library edit dialogue.
     *
     * @returns the promise for the dialgoue
     */
    editConditionLibrary(conditionsLibraryId: number, organisationId: number) {
        const modal = this.modalService.show(ConditionLibraryEditDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                organisationId,
                conditionsLibraryId
            }
        });

        return modal;
    }
}
