import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILinksService, LinkAction, LinkDto } from './link-manager.interface';
import { NoticeLinksService } from './links-services/notice-link-manager.service';
import { TaskLinkManagerService } from 'libs/attachments/src/lib/link-manager/links-services/task-link-manager.service';

@Injectable({
    providedIn: 'root'
})
export class LinkFactoryService {
    private linksSubject = new BehaviorSubject<LinkDto[]>([]);
    links$ = this.linksSubject.asObservable();

    constructor(
        private noticeLinkManageService: NoticeLinksService,
        private taskLinkManagerService: TaskLinkManagerService
    ) // sample for mission links service here
    // private missionLinkManageService: MissionLinksService,
    {}

    getLinkService(linkAction: LinkAction): ILinksService {
        switch (linkAction) {
            case LinkAction.noticeAirworthiness:
                return this.noticeLinkManageService;
            case LinkAction.generalTask:
                return this.taskLinkManagerService;
            // sample for mission
            // case LinkAction.mission:
            //   return this.missionLinkManageService
            default:
                break;
        }
    }
}
