<ng-container screenAnalytics="organisation-admin">
    <div class="modal-header no-icon">
        <h3 id="dialog-child-name" class="modal-title">Organisation Admin</h3>
        <button
            *ngIf="showMoreFeatures"
            type="button"
            class="btn btn-golden btn-sm"
            style="margin-right: 15px"
            (click)="showMoreFeaturesDialogue()"
        >
            More features
        </button>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body inner-container no-footer">
        <ngx-loading *ngIf="organisation == null" [show]="true"></ngx-loading>
        <tabset
            #tabset
            *ngIf="organisation != null"
            vertical="true"
            type="pills"
            class="tab-set-vertical fill misson"
        >
            <tab routerTab="details" [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-buildings tab-title-icon"></i>
                    <span> Organisation Details </span>
                </ng-template>
                <organisation-overview
                    [organisation]="organisation"
                    class="modal-body-content"
                ></organisation-overview>
                <version></version>
            </tab>
            <tab
                *ngIf="canManageFlightConfiguration"
                routerTab="documentation"
                [disabled]="locked"
            >
                <ng-template tabHeading>
                    <i class="far fa-file-lines tab-title-icon"></i>
                    <span> Documentation Templates </span>
                </ng-template>
                <form-list
                    class="modal-body-content"
                    [organisation]="organisation"
                >
                </form-list>
            </tab>
            <tab
                *ngIf="canManageFlightConfiguration"
                routerTab="authorities"
                [disabled]="locked"
            >
                <ng-template tabHeading>
                    <i class="far fa-credit-card-blank tab-title-icon"></i>
                    <span> Authorities </span>
                </ng-template>
                <organisation-authority-edit
                    [organisationId]="organisation.id"
                    [managingOrganisationId]="organisation.id"
                    [showDone]="false"
                >
                </organisation-authority-edit>
            </tab>
            <tab
                *ngIf="canManageFlightConfiguration"
                routerTab="missionConfiguration"
                [disabled]="locked"
            >
                <ng-template tabHeading>
                    <i class="far fa-gear tab-title-icon"></i>
                    <span> Mission Configuration </span>
                </ng-template>
                <tabset>
                    <tab heading="Mission Workflow">
                        <mission-workflow-list
                            class="modal-body-content"
                            [organisation]="organisation"
                        >
                        </mission-workflow-list>
                    </tab>
                    <tab
                        *ngIf="canUseApprovalConditionsLibrary"
                        heading="Approval Conditions"
                    >
                        <plan-highlight
                            [featureFlags]="[
                                featureFlags['canUseApprovalConditionsLibrary']
                            ]"
                        ></plan-highlight>
                        <div class="modal-body-content container-scroll">
                            <condition-library-list
                                [organisationId]="organisation.id"
                            ></condition-library-list>
                        </div>
                    </tab>
                    <tab *ngIf="canUseObjectives" heading="Mission Objectives">
                        <plan-highlight
                            [featureFlags]="[featureFlags['canUseObjectives']]"
                        ></plan-highlight>
                        <div class="modal-body-content vertical-container">
                            <div>
                                <h4>Mission Objectives</h4>
                                <mission-objectives-list
                                    class="fill"
                                    [organisation]="organisation"
                                ></mission-objectives-list>
                            </div>
                        </div>
                    </tab>
                    <tab heading="Default Mission Settings">
                        <div class="modal-body-content vertical-container">
                            <div>
                                <h4>Default Mission Settings</h4>
                                <organisation-default-settings
                                    class="fill"
                                    [organisation]="organisation"
                                ></organisation-default-settings>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </tab>
            <tab
                *ngIf="hasRpaWorkflow && canManageFlightConfiguration"
                routerTab="resourceMaintenance"
                [disabled]="locked"
            >
                <ng-template tabHeading>
                    <i class="far fa-paint-roller tab-title-icon"></i>
                    <span> Resource Maintenance </span>
                </ng-template>
                <plan-highlight
                    [featureFlags]="[featureFlags['hasRpaWorkflow']]"
                ></plan-highlight>
                <resource-maintenance></resource-maintenance>
            </tab>
            <tab
                *ngIf="canManageFlightConfiguration"
                routerTab="flightConformance"
                [disabled]="locked"
                ><ng-template tabHeading>
                    <i class="far fa-plane tab-title-icon"></i>
                    <span> Flight Conformance </span>
                    <new-feature
                        type="BETA"
                        name="Flight Conformance"
                        description="Automatically check if the flights have been performed within parameters of the mission plan, and the organisation's rules."
                    ></new-feature
                ></ng-template>
                <plan-highlight
                    *ngIf="canUseFlightConformance"
                    [featureFlags]="[featureFlags['canUseFlightConformance']]"
                ></plan-highlight>
                <flight-conformance-setup
                    *ngIf="canUseFlightConformance"
                    [organisation]="organisation"
                ></flight-conformance-setup>
                <upsell
                    *ngIf="!canUseFlightConformance"
                    layout="LARGE"
                    componentName="flight-conformance-upsell"
                ></upsell>
            </tab>
            <tab
                *ngIf="canUseSharedAuthorities && canManageFlightConfiguration"
                routerTab="sharedResources"
                [disabled]="locked"
            >
                <ng-template tabHeading>
                    <i class="far fa-share-nodes tab-title-icon"></i>
                    <span> Shared Resources </span>
                </ng-template>
                <div class="modal-body-content container-scroll">
                    <plan-highlight
                        [featureFlags]="[
                            featureFlags['canUseSharedAuthorities']
                        ]"
                    ></plan-highlight>
                    <shared-resources
                        [organisation]="organisation"
                    ></shared-resources>
                </div>
            </tab>
            <tab
                *ngIf="canViewBilling"
                routerTab="subscriptions"
                [disabled]="locked"
            >
                <ng-template tabHeading>
                    <i class="far fa-wallet tab-title-icon"></i>
                    <span> Subscriptions &amp; Billing </span>
                </ng-template>
                <div class="modal-body-content container-scroll">
                    <organisation-subscriptions [organisation]="organisation">
                    </organisation-subscriptions>
                </div>
            </tab>
            <tab
                *ngIf="canUseReferrals"
                routerTab="referrals"
                [disabled]="locked"
            >
                <ng-template tabHeading>
                    <i class="far fa-user-plus tab-title-icon"></i>
                    <span> Referrals </span>
                </ng-template>
                <plan-highlight
                    [featureFlags]="[featureFlags['canUseReferrals']]"
                ></plan-highlight>
                <referrals [organisationId]="organisation.id"></referrals>
            </tab>
            <tab *ngIf="canUseWorkgroups" routerTab="workgroups"
                ><ng-template tabHeading>
                    <i class="far fa-people-group tab-title-icon"></i>
                    <span> Workgroups </span>
                    <new-feature
                        type="BETA"
                        name="Workgroups"
                        description="Organise your team into workgroups to help streamline your operations management."
                    ></new-feature
                ></ng-template>
                <plan-highlight
                    *ngIf="canUseWorkgroups"
                    [featureFlags]="[featureFlags['canUseWorkgroups']]"
                ></plan-highlight>
                <workgroups-setup
                    [organisationId]="organisation.id"
                    *ngIf="canUseWorkgroups"
                ></workgroups-setup>
                <upsell
                    *ngIf="!canUseWorkgroups"
                    layout="LARGE"
                    componentName="workgroups-upsell"
                ></upsell>
            </tab>
            <ng-template></ng-template>
        </tabset>
    </div>
</ng-container>
