import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'text-area-auto-grow',
    template: `
        <textarea
            [name]="key"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [cols]="to.cols"
            [rows]="to.rows"
            class="form-control"
            [class.is-invalid]="showError"
            [formlyAttributes]="field"
            [disabled]="to.disabled"
        >
        </textarea>
    `
})
export class FormlyTextarea extends FieldType {}
