import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { Angulartics2Module } from 'angulartics2';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { BatteryModule } from 'libs/batteries/src/lib/batteries.module';
import { EquipmentModule } from 'libs/equipment/src/lib/equipment.module';
import { FlyFreelyVideoModule } from 'libs/flyfreely-video/src/lib/flyfreely-video.module';
import { FullScreenModule } from 'libs/fullscreen/src/lib/fullscreen.module';
import { LibrariesModule } from 'libs/libraries/src/lib/libraries.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { LocationsModule } from 'libs/locations/src/lib/locations.module';
import { MaintenanceModule } from 'libs/maintenance/src/lib/maintenance.module';
import { MapModule } from 'libs/map/src/lib/map.module';
import { MissionsModule } from 'libs/missions/src/lib/missions.module';
import { NewsModule } from 'libs/news-and-support/src/lib/news-and-support.module';
import { OnboardingModule } from 'libs/onboarding/src/lib/onboarding.module';
import { PersonnelModule } from 'libs/personnel/src/lib/personnel.module';
import { RiskRegisterModule } from 'libs/risk-register/src/lib/risk-register.module';
import { RpaModule } from 'libs/rpa/src/lib/rpa.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { BatteryListWidgetComponent } from './batteries/battery-list-widget.component';
import { BatterySetListWidgetComponent } from './batteries/battery-set-list-widget.component';
import { EquipmentWidgetComponent } from './equipment/equipment-widget.component';
import { IncidentReportsWidgetComponent } from './incident-reports/incident-reports-widget.component';
import { LibraryListWidgetComponent } from './libraries/library-list-widget.component';
import { LocationsWidgetComponent } from './locations/locations-widget.component';
import { MaintenanceWidgetComponent } from './maintenance/maintenance-widget.component';
import { OrganisationLinksWidgetComponent } from './organisation-links/organisation-links-widget.component';
import { PersonnelWidgetComponent } from './personnel/personnel-widget.component';
import { RiskRegisterWidgetComponent } from './risk-register/risk-register-widget.component';
import { CraftListWidgetComponent } from './rpa/rpa-list-widget.component';
import { WelcomeWidgetComponent } from './welcome/welcome-widget.component';
import { WidgetsPreferencesService } from './widget-preferences.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedUiModule,
        FlyFreelyTableModule,
        LoadingIndicatorModule,
        AttachmentsModule,
        Angulartics2Module,

        NgSelectModule,
        TabsModule,
        ReactiveFormsModule,
        ValidationModule,
        BsDatepickerModule,

        FlyFreelyFormlyModule,
        TooltipModule,
        EditableModule,
        ToastrModule,

        LibrariesModule,
        RiskRegisterModule,
        LocationsModule,

        // TODO: MissionsModule is required even though we don't use any of its components
        MissionsModule,
        MaintenanceModule,
        BatteryModule,
        RpaModule,
        EquipmentModule,
        PersonnelModule,
        OnboardingModule,
        NewsModule,
        MapModule,
        FullScreenModule,
        FeaturesModule,
        ResourceUiModule,

        RouterModule,
        FlyFreelyVideoModule
    ],
    declarations: [
        IncidentReportsWidgetComponent,
        OrganisationLinksWidgetComponent,
        RiskRegisterWidgetComponent,
        LibraryListWidgetComponent,
        LocationsWidgetComponent,
        BatteryListWidgetComponent,
        BatterySetListWidgetComponent,
        CraftListWidgetComponent,
        MaintenanceWidgetComponent,
        PersonnelWidgetComponent,
        EquipmentWidgetComponent,
        WelcomeWidgetComponent
    ],
    providers: [WidgetsPreferencesService],
    exports: [
        IncidentReportsWidgetComponent,
        OrganisationLinksWidgetComponent,
        RiskRegisterWidgetComponent,
        LibraryListWidgetComponent,
        LocationsWidgetComponent,
        BatteryListWidgetComponent,
        BatterySetListWidgetComponent,
        CraftListWidgetComponent,
        MaintenanceWidgetComponent,
        PersonnelWidgetComponent,
        EquipmentWidgetComponent,
        WelcomeWidgetComponent
    ]
})
export class WidgetsModule {}
