<div class="vertical-container" style="padding: 25px 25px 25px 15px">
    <div class="horizontal-container fill">
        <button
            class="btn btn-primary start-here-button"
            type="button"
            (click)="changeChecklist(types.START_HERE)"
            [disabled]="working || isStartHereActive"
            [tooltip]="
                isStartHereActive
                    ? 'This checklist has already been started and can be continued in your activity bar, which can be toggled with the bell icon in the upper left of the screen.'
                    : ''
            "
            placement="top"
        >
            Start Here<br />Dummy Mission Tutorial
        </button>
    </div>
    <div class="time-container">
        <i class="fal fa-clock clock"></i>
        <div class="timing-block">
            <span>8-10 Minutes</span>
        </div>
    </div>
    <p class="quick-start-text">
        Quickly get familiar with our platform by creating a dummy mission.
        Dummy missions allow you to experience the end-to-end mission planning,
        execution and reconciliation process without affecting any records in
        the system, such as your flight log or drone flight times.
    </p>

    <!-- Below is one of the original buttons with an icon displaying the estimated time the steps will take: -->
    <!-- <div class="horizontal-container fill" style="margin: 10px 0;">
        <button
            class="btn btn-default btn-confirm btn-wide"
            type="button"
            [disabled]="true"
            (click)="changeChecklist(types.GENERAL)"
        >
            General Setup&#42;
        </button>
        <div class="time-container">
            <i class="fal fa-clock clock"></i>
            <div class="timing-block">
                <span><strong>10-45</strong></span>
                <span>minutes</span>
            </div>
        </div>
    </div> -->
</div>
