import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    FormControl,
    Validator
} from '@angular/forms';
import {
    CraftDetailsDto,
    CraftService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LookupObject,
    observeFormControl,
    RpaTypeDto,
    toLookup,
    TotalInServiceTime,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { WorkGroup } from 'libs/flyfreely/src/lib/services/workgroups.service';
import { FormatMissionEditRegisterStatus } from 'libs/missions/src/lib/mission-edit-v2/formatMissionEditRegisterStatus.pipe';
import { sortRpaByRegisterStatus } from 'libs/resource-ui/src/lib/helpers';
import { rpaSearch } from 'libs/resource-ui/src/lib/searchFunctions';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissionEditV2Dialogue } from '../mission-edit-v2-dialogue.component';
import { MissionEditService, SelectableRpa } from '../mission-edit.service';

interface SelectableRpaWithType extends SelectableRpa {
    rpaType: RpaTypeDto;
}

export const MISSION_RPA_SELECT_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MissionRpaSelection),
    multi: true
};

export const MISSION_RPA_SELECT_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MissionRpaSelection),
    multi: true
};

@Component({
    selector: 'tr[mission-rpa-select]',
    templateUrl: './mission-rpa-select.component.html',
    providers: [MISSION_RPA_SELECT_ACCESSOR, MISSION_RPA_SELECT_VALIDATOR]
})
export class MissionRpaSelection
    implements ControlValueAccessor, Validator, OnChanges
{
    @Input() index: number;
    @Input() disable: boolean;
    @Input() readonly: boolean;
    @Input() orgId: number;
    @Input()
    missionDetails: typeof MissionEditV2Dialogue.prototype.missionDetails;
    @Input() rpaRegisterDisabled = true;
    @Input() workflow: number;
    @Input() setStep: (step: string) => any;
    @Input() canUseWorkgroup: boolean;
    @Input() workgroupFormControl: FormControl;
    @Output() showRpaDetails = new EventEmitter<void>();
    @Output() deleteRpa = new EventEmitter<void>();

    editing = true;
    isWorkgroupSelect = false;

    allOrganisationRpa: SelectableRpaWithType[];
    availableRpa: SelectableRpaWithType[];
    rpaTypeLookup: LookupObject<RpaTypeDto>;

    selectedRpaId: number;
    time: string = moment().toISOString();
    selectedRpaType: RpaTypeDto;
    selectedRpa: SelectableRpaWithType;
    selectedRpaDetails = new BehaviorSubject<CraftDetailsDto>(
        {} as CraftDetailsDto
    );
    totalTimeInService = new BehaviorSubject<TotalInServiceTime>(
        {} as TotalInServiceTime
    );

    onChange = Function.prototype;
    onTouched = Function.prototype;

    rpaSearch = rpaSearch;

    get missionObjectivesGroup() {
        return this.missionEditService.missionObjectivesGroup;
    }

    workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionEditService: MissionEditService,
        private logging: FlyFreelyLoggingService,
        private changeDetector: ChangeDetectorRef,
        private rpaService: CraftService
    ) {}

    ngOnInit() {
        this.missionEditService.missionForm.controls.craftIds.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.setupAvailableRpas());

        combineLatest([
            this.missionEditService.rpaTypes$,
            this.missionEditService.selectableRpas$,
            observeFormControl<WorkGroup>(this.workgroupFormControl)
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([types, rpas, selectWorkgroup]) => {
                this.rpaTypeLookup = types.reduce(toLookup, {});
                this.allOrganisationRpa = rpas.map(rpa => ({
                    ...rpa,
                    rpaType: this.rpaTypeLookup[rpa.rpaTypeId]
                }));
                if (this.canUseWorkgroup && selectWorkgroup) {
                    this.isWorkgroupSelect = true;
                    let selectWorkgroupRpaIds = selectWorkgroup.rpaList.map(
                        rpa => rpa.id
                    );

                    const formatStatus = new FormatMissionEditRegisterStatus();

                    const rapsInWorkgroup = this.allOrganisationRpa
                        .filter(rpa => selectWorkgroupRpaIds.includes(rpa.id))
                        .map(rpa => ({
                            ...rpa,
                            registerWithWorkgroupStatus: `In Workgroup - ${
                                rpa.registerStatus === 'NOT_ON_REGISTER'
                                    ? ''
                                    : 'Register Status '
                            }${formatStatus.transform(rpa.registerStatus)}`
                        }));

                    const rapsNotInWorkgroup = this.allOrganisationRpa
                        .filter(rpa => !selectWorkgroupRpaIds.includes(rpa.id))
                        .map(rpa => ({
                            ...rpa,
                            registerWithWorkgroupStatus: `Not In Workgroup - ${
                                rpa.registerStatus === 'NOT_ON_REGISTER'
                                    ? ''
                                    : 'Register Status '
                            } ${formatStatus.transform(rpa.registerStatus)}`
                        }));

                    this.allOrganisationRpa = [
                        ...rapsInWorkgroup.sort(sortRpaByRegisterStatus),
                        ...rapsNotInWorkgroup.sort(sortRpaByRegisterStatus)
                    ];
                } else {
                    this.isWorkgroupSelect = false;
                    this.allOrganisationRpa.sort(sortRpaByRegisterStatus);
                }
                if (this.selectedRpaId != null && this.selectedRpa == null) {
                    this.selectedRpa = this.allOrganisationRpa.find(
                        r => r.id === this.selectedRpaId
                    );
                    this.getRpaDetails();
                } else if (
                    this.selectedRpaId != null &&
                    this.selectedRpa != null
                ) {
                    // Update craft according to new fetch in case something like register status has changed
                    this.selectedRpa = this.allOrganisationRpa.find(
                        r => r.id === this.selectedRpaId
                    );
                }
                this.setupAvailableRpas();
            });

        this.setupAvailableRpas();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('selectedRpas' in changes) {
            this.setupAvailableRpas();
        }
    }

    writeValue(value: any): void {
        this.selectedRpa =
            value != null
                ? this.allOrganisationRpa?.find(r => r.id === value)
                : null;
        this.selectedRpaId = value;
        this.editing = value != null && this.selectedRpa == null ? false : true;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(c: FormControl) {
        const isAllValid = this.selectedRpaId != null;
        return isAllValid
            ? null
            : {
                  length: {
                      valid: isAllValid
                  }
              };
    }

    onUpdate() {
        this.onChange(this.selectedRpaId);
    }

    deleteRow() {
        this.deleteRpa.emit();
    }

    viewRpa() {
        this.showRpaDetails.emit();
    }

    updateSelectedRpa() {
        this.selectedRpa = this.allOrganisationRpa.find(
            r => r.id === this.selectedRpaId
        );

        this.selectedRpaDetails.next({} as CraftDetailsDto);

        this.getRpaDetails();

        this.onUpdate();
        this.editing = false;
    }

    setupAvailableRpas() {
        if (this.allOrganisationRpa == null) {
            return;
        }
        const selectedRpas =
            this.missionEditService.missionForm.controls.craftIds.value;
        if (selectedRpas == null || selectedRpas.length === 0) {
            this.availableRpa = this.allOrganisationRpa;
            return;
        }
        const otherSelected = selectedRpas.filter(
            v => v !== this.selectedRpaId
        );
        this.availableRpa = this.allOrganisationRpa.filter(
            r => otherSelected.find(v => v === r.id) == null
        );
        this.changeDetector.detectChanges();
    }

    getTimeToNextService() {
        const { triggerStatus } = this.totalTimeInService.getValue();

        if (triggerStatus?.type === 'NONE') {
            return 'N/A';
        } else {
            const {
                inServiceTime = null,
                cycles = null,
                calendarTime = null
            } = triggerStatus || {};
            if (inServiceTime) {
                return `${Math.round(inServiceTime / 3600)} hours`;
            } else if (cycles) {
                return `${cycles} flights`;
            } else {
                return calendarTime;
            }
        }
    }

    getRegisterIconClass() {
        const register = this.selectedRpa.registerStatus;

        switch (register) {
            case 'ACTIVE':
                return 'fa fa-check text-success';
            case 'EXPIRED':
            case 'SUSPENDED':
            case 'NOT_ON_REGISTER':
            case 'NOT_AVAILABLE':
                return 'fa fa-times text-danger';
            case 'PENDING':
                return 'far fa-circle-exclamation text-warning';
            default:
                '';
        }
    }

    getRpaDetails() {
        if (this.selectedRpa?.id != null) {
            combineLatest([
                this.rpaService.findById(
                    this.selectedRpa.id,
                    this.missionEditService.organisationId
                ),
                this.rpaService.findTotalTimeInService(
                    this.selectedRpa.id,
                    this.missionEditService.organisationId
                )
            ])
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    ([rpa, time]) => {
                        this.selectedRpaDetails.next(rpa);
                        this.totalTimeInService.next(time);
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error fetching RPA details: ${error.message}`
                        );
                    }
                )
                .add(this.workTracker.createTracker());
        }
    }
}
