import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AirspaceAuthorisationDto,
    AirspaceAuthorisationResponse,
    AirspaceAuthorisationService,
    AirspaceCheckCommand,
    ApproversMissionApprovalDto,
    CraftDto,
    DO_NOTHING,
    DisplayableMissionDto,
    FailedValidation,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LocationFeatureDto,
    LookupObject,
    MissionCrewDetailsDto,
    MissionCrewDto,
    MissionService,
    NameValue,
    OperationAuthorisation,
    Organisation,
    PersonDto,
    PersonService,
    RequestAirspaceAuthorisationCommand,
    RpaTypeDto,
    RuleOutcome,
    SimpleAuthorityDto,
    UpdateMissionCommand,
    UserLocationService,
    UserService,
    WorkTracker,
    fromTimestamp,
    toLookup,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Angulartics2 } from 'angulartics2';
import { Country } from 'libs/addresses/src/lib/address-edit/address-edit-dialogue.component';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { EnhancedHelpService } from 'libs/enhanced-help/src/lib/enhanced-help.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { toMapFeature } from 'libs/locations/src/lib/helpers';
import { MapSourceFilters } from 'libs/map/src/lib/dynamic-data.service';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { PersonnelDialoguesService } from 'libs/personnel/src/lib/personnel-dialogues.service';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import CountryStates from 'libs/ui/src/lib/data/country-states.json';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    ReplaySubject,
    Subject,
    combineLatest,
    firstValueFrom,
    throwError
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    startWith,
    take,
    takeUntil
} from 'rxjs/operators';
import { findLocationFlightAreaPolygon } from '../../airspace-check';
import { AirspaceDialoguesService } from '../../airspace-dialogues.service';
import { AirspaceAuthorisationManager } from '../airspace-authorisation-manager.service';
import { calculateAuthorisationListState } from '../authorisation-status';
import {
    authorisationTypesWithMultipleAuthorisations,
    findLatestAirspaceAuthorisationInList,
    findSelectedFlightAreaPolygon
} from '../helpers';
import {
    AdditionalInformationFields,
    AdditionalInformationForm,
    AirspaceAuthorisationState,
    CasaFlightProfiles,
    CraftWithError,
    TimeValidationResult
} from '../interfaces';
import {
    AirspaceAuthorisationForm,
    AirspaceAuthorisationFormValue,
    AirspaceAuthorisationPreviewService,
    airspaceAuthorisationFormErrors,
    authorisationTypeCanEditMissionInPreview,
    delegatedAuthorityLabel,
    justificationLabel,
    specialValidationKeys,
    visibleAuthorisationFieldsByType
} from './airspace-authorisation-preview.service';
import { removeWhiteSpace } from './helpers';

const countryStates = CountryStates as Country[];

const flightPurposes: NameValue[] = [
    { name: 'Aerial Filming', value: 'AERIAL_FILMING' },
    { name: 'Surveillance', value: 'SURVEILLANCE' },
    { name: 'Survey Mapping', value: 'SURVEY_MAPPING' },
    { name: 'Infrastructure Inspection', value: 'INFRASTRUCTURE_INSPECTION' },
    { name: 'Inventory Management', value: 'INVENTORY_MANAGEMENT' },
    { name: 'Construction Monitoring', value: 'CONSTRUCTION_MONITORING' },
    { name: 'Demonstration', value: 'DEMONSTRATION' },
    { name: 'Training', value: 'TRAINING' },
    { name: 'UAM Operation', value: 'UAM_OPERATION' },
    { name: 'Fishing', value: 'FISHING' },
    // { name: 'Police', value: 'POLICE' },
    // { name: 'Customs', value: 'CUSTOMS' },
    // { name: 'Traffic Surveillance', value: 'TRAFFIC_SURVEILLANCE' },
    // { name: 'Search and Rescue', value: 'SEARCH_AND_RESCUE' },
    // { name: 'Environmental Control', value: 'ENVIRONMENTAL_CONTROL' },
    // { name: 'Medical', value: 'MEDICAL' },
    // { name: 'Evacuations', value: 'EVACUATIONS' },
    // { name: 'Fire Fighting', value: 'FIRE_FIGHTING' },
    // { name: 'VIP Security', value: 'VIP_SECURITY' },
    { name: 'Other', value: 'OTHER' }
];

@Component({
    selector: 'airspace-authorisation-preview',
    templateUrl: './airspace-authorisation-preview.component.html',
    styleUrls: ['./styles.scss', '../../styles.scss'],
    providers: [AirspaceAuthorisationPreviewService]
})
export class AirspaceAuthorisationPreviewDialogue {
    @Input() organisation: Organisation;
    @Input() checkCommand: AirspaceCheckCommand;
    @Input() mission: DisplayableMissionDto;
    @Input() approval: ApproversMissionApprovalDto;
    // The authorisation to show
    @Input() authorisations: AirspaceAuthorisationDto[];
    @Input() rpaTypes: RpaTypeDto[];
    @Input() requestNew: boolean;

    @Output() missionUpdated = new EventEmitter<DisplayableMissionDto>();
    @Output() missionTimeUpdated = new EventEmitter<{
        startTime: Date;
        duration: number;
    }>();

    airspaceAuthorisationForm: FormGroup<AirspaceAuthorisationForm>;
    visibleAuthorisationFieldsByType = visibleAuthorisationFieldsByType;
    visibleFormFields: string[];

    airspaceAuthorisationType: AirspaceAuthorisationDto.AuthorisationType;
    airspaceAuthorisationStatus: AirspaceAuthorisationState;

    authorisationPreview: AirspaceAuthorisationResponse;
    authorisationResponse: AirspaceAuthorisationResponse;

    approvedIds: string[];

    rpic: PersonDto;
    crp: PersonDto;
    crpArn: string;
    crpAuthLabel: string;
    rpicArn: string;
    rpicAuthLabel: string;
    rpicPhone: string;

    rpaList: CraftWithError[];

    delegatedAuthority: SimpleAuthorityDto;

    acknowledgeCrp = false;
    acknowledgeWeight = false;

    features$ = new ReplaySubject<FeatureGroup[]>(1);
    locationFeatures: FeatureGroup[] = [];
    missionFlightAreas: LocationFeatureDto[];
    selectedFlightArea: LocationFeatureDto;
    nextId = 0;

    startTime: string;
    endTime: string;
    timeValidation: TimeValidationResult;
    civilTwilightBegin: string;
    civilTwilightEnd: string;

    updatedTimeForm: FormGroup<{
        startTime: FormControl<Date>;
        duration: FormControl<number>;
    }>;
    editStart: boolean;
    editEnd: boolean;

    validationResultMessages: FailedValidation[];
    validRequest: boolean;
    canEditMission = false;

    // TODO: this error handler is getting convoluted and needs a refactor
    missingRpicPhone: boolean;
    missingRpicJurisdictionIdentifier: boolean;
    missingRpicJurisdiction: boolean;
    rpicMissingRequiredRegistration = false;
    rpicInvalidRegistration = false;
    requestError = false;
    errorMessage: string;
    missingRpicMessages: {
        phone: string;
        jurisdictionIdentifier: string;
        jurisdiction: string;
        registration: string;
    } = {
        phone: 'Missing phone number',
        jurisdictionIdentifier: 'Missing licence number',
        jurisdiction:
            'Remote pilot does not have a licence in this jurisdiction',
        registration: 'Remote pilot must be registered'
    };
    craftMissingMtowIds: number[];
    rpicDetailsFields: FormlyFieldConfig[];
    rpicDetailsForm: FormGroup<{ phoneNumber: FormControl<string> }>;
    additionalInformationFormlyFields: FormlyFieldConfig[];
    additionalInformationForm: FormGroup<AdditionalInformationForm>;

    casaFlightProfiles: NameValue[] = [
        { name: 'Automated - Grid', value: CasaFlightProfiles.AUTOMATED_GRID },
        {
            name: 'Automated - Waypoint',
            value: CasaFlightProfiles.AUTOMATED_WAYPOINT
        },
        { name: 'Manual', value: CasaFlightProfiles.MANUAL }
    ];
    casaFlightProfile: CasaFlightProfiles;

    collapseGcdTable: boolean;

    mapSourceFilters: MapSourceFilters = {};
    enabledMapLayerGroups: string[];

    operationFailed: boolean;
    authorisationServiceUnavailable = false;
    authorisationUnavailableMessage: string;

    expandWarningsAndBlockersIndex = 0;
    relatedMissionLookup: LookupObject<DisplayableMissionDto>;

    @ViewChild('map', { read: ElementRef, static: true }) map: ElementRef;

    private initValuesCompleted$ = new Subject<void>();

    authorisationLoading = false;
    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    private authorisationFormChanged$ = new Subject<void>();
    constructor(
        private airspaceAuthorisationService: AirspaceAuthorisationService,
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        private airspaceAuthorisationPreviewService: AirspaceAuthorisationPreviewService,
        private personService: PersonService,
        private userService: UserService,
        private missionService: MissionService,
        private userLocationService: UserLocationService,
        private enhancedHelpService: EnhancedHelpService,
        private personnelDialoguesService: PersonnelDialoguesService,
        private missionDialoguesService: MissionDialoguesService,
        private rpaDialoguesService: RpaDialoguesService,
        private airspaceDialoguesService: AirspaceDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private modal: BsModalRef,
        private fullScreenService: FullScreenService,
        private angulartics2: Angulartics2
    ) {
        this.updatedTimeForm = new FormGroup({
            startTime: new FormControl<Date>(undefined),
            duration: new FormControl<number>(undefined)
        });

        this.rpicDetailsForm = new FormGroup({
            phoneNumber: new FormControl<string>(undefined)
        });
    }

    ngOnInit() {
        if (this.authorisations != null && this.authorisations.length > 0) {
            this.airspaceAuthorisationStatus = calculateAuthorisationListState(
                this.authorisations
            );
        }

        combineLatest([
            this.workTracker.observable.pipe(startWith(false)),
            this.airspaceAuthorisationManager.checkingAuthorisation$.pipe(
                startWith(false)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([working, serviceWorking]) => {
                this.working = working || serviceWorking;
                this.authorisationLoading = serviceWorking;
            });
        this.airspaceAuthorisationPreviewService.setInputData(
            this.mission,
            this.organisation
        );

        // TODO: the changes$ from services need to be handled by the new service.
        this.missionService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMission());

        this.personService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.setupValues());

        combineLatest([
            this.initValuesCompleted$,
            this.airspaceAuthorisationManager.availableAirspaceAuthorisations$.pipe(
                distinctUntilChanged()
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([_, types]) => {
                this.airspaceAuthorisationType = types ? types[0] : null;
                this.canEditMission =
                    authorisationTypeCanEditMissionInPreview[
                        this.airspaceAuthorisationType
                    ];
                if (this.airspaceAuthorisationType != null) {
                    this.airspaceAuthorisationForm =
                        this.airspaceAuthorisationPreviewService.authorisationForm;

                    this.visibleFormFields =
                        visibleAuthorisationFieldsByType[
                            this.airspaceAuthorisationType
                        ];
                    this.checkAuthorisation();
                    this.authorisationFormChanged$.next();
                    this.setupFormListeners();
                }
            });

        this.airspaceAuthorisationManager.airspaceAuthorisationPreview$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorisation =>
                this.setupAuthorisationResult(authorisation)
            );
        this.airspaceAuthorisationManager.missionAirspaceAuthorisations$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(airspaceAuthorisation => {
                this.authorisations =
                    airspaceAuthorisation?.length > 0
                        ? authorisationTypesWithMultipleAuthorisations.includes(
                              airspaceAuthorisation[0].authorisationType
                          )
                            ? airspaceAuthorisation
                            : [
                                  findLatestAirspaceAuthorisationInList(
                                      airspaceAuthorisation
                                  )
                              ]
                        : null;
                this.airspaceAuthorisationStatus =
                    airspaceAuthorisation == null ||
                    airspaceAuthorisation.length === 0
                        ? 'UNKNOWN'
                        : // FIXME: this is a hack and needs to be revised
                          calculateAuthorisationListState(this.authorisations);
            });

        this.airspaceAuthorisationManager.authorisationServiceUnavailable$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorisationState => {
                this.authorisationServiceUnavailable =
                    authorisationState.status === 'UNAVAILABLE';
                this.authorisationUnavailableMessage =
                    authorisationState.unavailableMessage;
            });

        this.airspaceAuthorisationManager.relatedMissions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(relatedMissions => {
                this.relatedMissionLookup = relatedMissions.reduce(
                    toLookup,
                    {}
                );
            });

        this.selectedFlightArea = this.mission?.location?.features?.find(
            f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
        );

        this.updatedTimeForm.controls.startTime.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(time => {
                this.updatedTimeForm.controls.startTime.setValue(time, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });
            });

        this.updatedTimeForm.controls.duration.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(duration => {
                this.updatedTimeForm.controls.duration.setValue(duration, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });
            });

        this.setupValues();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.authorisationFormChanged$.next();
        this.authorisationFormChanged$.complete();
        this.features$.complete();
        this.initValuesCompleted$.complete();
    }

    refreshMission() {
        this.missionService
            .findMission(this.mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mission => {
                this.resetComponentState();
                this.mission = mission;
                this.airspaceAuthorisationPreviewService.setInputData(
                    this.mission,
                    this.organisation
                );
                this.selectedFlightArea =
                    this.mission?.location?.features?.find(
                        f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
                    );
                this.createCheckCommand(mission);
                this.setupValues();
            })
            .add(this.workTracker.createTracker());
    }

    setupFormListeners() {
        combineLatest([
            this.airspaceAuthorisationForm.valueChanges,
            this.airspaceAuthorisationManager.authorisationStateErrors$
        ])
            .pipe(takeUntil(this.authorisationFormChanged$))
            .subscribe(([result, stateErrors]) => {
                // Get any state errors from the authorisation request
                const stateErrorText =
                    stateErrors != null
                        ? Object.keys(stateErrors)
                              .filter(
                                  key => !specialValidationKeys.includes(key)
                              )
                              .reduce(
                                  (acc, k) =>
                                      stateErrors[k] != null
                                          ? acc.concat(
                                                `error with ${k}: ${stateErrors[k]}`
                                            )
                                          : acc,
                                  []
                              )
                        : [];
                // Add any generic error messages from invalid form fields.
                const errorFields = stateErrorText.concat(
                    Object.keys(result ?? {}).reduce(
                        (acc, key) =>
                            this.airspaceAuthorisationForm.controls[key].invalid
                                ? acc.concat(
                                      airspaceAuthorisationFormErrors[key]
                                  )
                                : acc,
                        []
                    )
                );
                let message: string;
                // Update the current error message with any remaining error message.
                if (
                    result.authorisationErrors != null &&
                    Object.keys(result.authorisationErrors).length
                ) {
                    // Grab the first error in the errors object. We'll only display one at a time until all errors have been handled.
                    message = Object.keys(result.authorisationErrors)
                        .filter(key => !specialValidationKeys.includes(key))
                        .reduce(
                            (acc, k) =>
                                acc == null &&
                                result.authorisationErrors[k].message != null
                                    ? result.authorisationErrors[k].message
                                    : // FIXME: possible keys need to be better mapped out to show the displayed field heading in the error.
                                    result.authorisationErrors[k].code ===
                                      'NotNull'
                                    ? `field ${k} is missing a value`
                                    : acc,
                            null
                        );
                } else {
                    message =
                        errorFields.length > 0 ? errorFields.join(', ') : null;
                }
                this.errorMessage =
                    message == null
                        ? message
                        : message[0].toUpperCase() + message.slice(1);
            });
    }

    /**
     * Reset all variables to their initial values so a clean refresh can happen.
     * This is probably overkill, but ensures nothing is skipped during a refresh.
     */
    resetComponentState() {
        this.authorisationPreview = null;
        this.rpicPhone = null;
        this.features$.next([]);
        this.locationFeatures = [];
        this.missionFlightAreas = null;
        this.selectedFlightArea = null;
        this.nextId = 0;
        this.startTime = null;
        this.endTime = null;
        this.timeValidation = null;
        this.civilTwilightBegin = null;
        this.civilTwilightEnd = null;
        this.updatedTimeForm.reset();
        this.editStart = false;
        this.editEnd = false;
        this.validationResultMessages = null;
        this.validRequest = false;
        this.missingRpicPhone = false;
        this.missingRpicJurisdictionIdentifier = false;
        this.missingRpicJurisdiction = false;
        this.rpicMissingRequiredRegistration = false;
        this.rpicInvalidRegistration = false;
        this.requestError = false;
        this.errorMessage = null;
        this.missingRpicMessages = {
            phone: 'Missing phone number',
            jurisdictionIdentifier: 'Missing licence number',
            jurisdiction:
                'Remote pilot does not have a licence in this jurisdiction',
            registration: 'Remote pilot must be registered'
        };
        this.craftMissingMtowIds = null;
        this.rpicDetailsFields = null;
        this.rpicDetailsForm.reset();
        this.additionalInformationFormlyFields = null;
        this.additionalInformationForm = null;
        this.casaFlightProfiles = [
            {
                name: 'Automated - Grid',
                value: CasaFlightProfiles.AUTOMATED_GRID
            },
            {
                name: 'Automated - Waypoint',
                value: CasaFlightProfiles.AUTOMATED_WAYPOINT
            },
            { name: 'Manual', value: CasaFlightProfiles.MANUAL }
        ];
        this.casaFlightProfile = null;
        this.mapSourceFilters = {};
        this.enabledMapLayerGroups = null;
        this.operationFailed = false;
    }

    createCheckCommand(mission: DisplayableMissionDto) {
        const startTime = mission.missionDate;
        const endTime =
            mission.missionEstimatedTime == null
                ? this.checkCommand.endTime
                : moment(startTime)
                      .add(mission.missionEstimatedTime, 'seconds')
                      .toISOString();

        const location =
            this.selectedFlightArea?.geometry ??
            findLocationFlightAreaPolygon(this.mission?.location?.features);

        const jurisdiction =
            mission.missionWorkflowVersion.delegatedAuthority.authorityType
                ?.jurisdiction?.identifier;

        const ruleset =
            mission.missionWorkflowVersion.delegatedAuthority.authorityType
                ?.ruleset?.identifier;

        const rpaList = mission.crafts
            .filter(
                r =>
                    r.rpaTypeId != null &&
                    this.rpaTypes[r.rpaTypeId]?.performanceSpecifications
                        ?.maxTakeOffWeight != null
            )
            .map(r => ({
                id: r.id,
                mtow: this.rpaTypes[r.rpaTypeId].performanceSpecifications
                    .maxTakeOffWeight,
                serialNumber: r.manufacturerSerialNumber
            }));

        this.checkCommand = {
            ...this.checkCommand,
            startTime,
            endTime,
            location,
            ruleset,
            jurisdiction,
            rpaList,
            nearestFeatureSearch: false,
            nearestFeatureSearchRadiusMeters: 400,
            maximumHeight: this.mission.maximumHeight ?? 400
        };
    }

    setupAirspaceCheckValidationMessages(reason: string) {
        const blocked =
            this.authorisationPreview.airspaceCheckResponse?.ruleOutcomes?.filter(
                ruleOutcome => ruleOutcome.outcome === RuleOutcome.Outcome.BLOCK
            ) ?? [];
        const messages = blocked.map(ruleOutcome => ruleOutcome.message);
        return [reason].concat(messages).join('<br>');
    }

    setupValues() {
        // TODO: I'm not sure this is needed, but need to verify
        /* if (
            this.airspaceAuthorisationType ===
                AirspaceAuthorisationDto.AuthorisationType
                    .AUS_CASA_AUTHORISATION &&
            this.mission.location.features.filter(
                f => f.type === LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
            ).length <= 1
        ) {
            const missionLocationGeometry = findLocationFlightAreaPolygon(
                this.mission?.location?.features
            );
            this.addFeature({
                name: 'Mission Flight Area',
                type: LocationFeatureDto.Type.FLIGHT_AREA,
                geometry: missionLocationGeometry
            } as LocationFeatureDto);
        } else { */
        this.missionFlightAreas =
            this.mission.location != null
                ? <LocationFeatureDto[]>(
                      this.mission.location.features.filter(
                          f =>
                              f.type === LocationFeatureDto.Type.FLIGHT_AREA ||
                              f.type ===
                                  LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
                      )
                  )
                : null;
        if (this.missionFlightAreas != null) {
            this.selectedFlightArea = this.missionFlightAreas[0];
            this.missionFlightAreas.forEach((a, i) => {
                this.addFeature({
                    name: a.name,
                    type:
                        i !== 0
                            ? 'SECONDARY_FLIGHT_AREA'
                            : LocationFeatureDto.Type.FLIGHT_AREA,
                    geometry: findLocationFlightAreaPolygon([a])
                } as LocationFeatureDto);
            });
        }

        this.initValuesCompleted$.next();
    }

    setupExistingAuthorisation(fetchNew: boolean = true) {
        this.approvedIds = this.authorisations.map(
            authorisation => authorisation.id
        );
        const rpic = this.formValues.rpic;
        const command: RequestAirspaceAuthorisationCommand = {
            missionId: this.mission.id,
            remotePilotPhoneNumber: removeWhiteSpace(rpic?.phoneNumber),
            authorisationType: this.airspaceAuthorisationType,
            flightArea: findSelectedFlightAreaPolygon(this.selectedFlightArea)
        };
        this.authorisations.map(authorisation => {
            const geometry = <GeoJSON.Polygon>authorisation.operatingArea;
            this.addFeature({
                name: 'Operating Area',
                type: LocationFeatureDto.Type.AREA_OF_INTEREST,
                geometry: geometry
            } as LocationFeatureDto);
            this.requestError = false;
        });

        if (fetchNew) {
            this.airspaceAuthorisationManager.refreshAuthorisationPreview(
                command,
                this.formValues
            );
        }
    }

    editAdditionalFields() {
        this.commonDialoguesService.showFormlyDialogue(
            'Additional Information',
            'Save',
            true,
            true,
            this.additionalInformationFormlyFields,
            {
                ...this.additionalInformationForm.value
            },
            data =>
                new Promise<void>(resolve => {
                    if (data != null) {
                        this.additionalInformationForm.patchValue(data);
                    }
                    resolve();
                })
        );
    }

    checkAuthorisation() {
        // if requesting a new authorisation, don't load the old one.
        if (this.authorisations != null) {
            this.authorisations.map(authorisation => {
                const geometry = <GeoJSON.Polygon>authorisation.operatingArea;
                this.addFeature({
                    name: 'Operating Area',
                    type: LocationFeatureDto.Type.AREA_OF_INTEREST,
                    geometry: geometry
                } as LocationFeatureDto);
                this.requestError = false;
            });
            this.requestError = false;
        }
        const rpic = this.formValues.rpic;

        const command: RequestAirspaceAuthorisationCommand = {
            missionId: this.mission.id,
            remotePilotPhoneNumber: removeWhiteSpace(rpic?.phoneNumber),
            authorisationType: this.airspaceAuthorisationType,
            flightArea: findSelectedFlightAreaPolygon(this.selectedFlightArea)
        };
        if (this.additionalInformationForm != null) {
            this.additionalInformationForm.clearValidators();
        }
        this.delegatedAuthority =
            this.mission?.missionWorkflowVersion?.delegatedAuthority;
        this.missingRpicJurisdiction = false;
        this.missingRpicJurisdictionIdentifier = false;
        this.missingRpicPhone = false;
        this.operationFailed = false;
        this.requestError = false;

        this.airspaceAuthorisationManager.refreshAuthorisationPreview(
            command,
            this.formValues
        );

        this.airspaceAuthorisationManager.refreshExistingMissionAuthorisations(
            this.airspaceAuthorisationType
        );
    }

    setupAuthorisationResult(result: AirspaceAuthorisationResponse) {
        if (result?.airspaceCheckResponse != null) {
            this.mapSourceFilters =
                result.airspaceCheckResponse.airspaceDatasetList?.reduce(
                    (acc, v) => ({
                        ...acc,
                        [v.identifier]: v.version
                    }),
                    {} as MapSourceFilters
                );
            this.enabledMapLayerGroups = Object.keys(this.mapSourceFilters);
            this.enabledMapLayerGroups = Object.keys(this.mapSourceFilters);
        }
        if (
            result.authorisationType ===
            AirspaceAuthorisationResponse.AuthorisationType.NZL_AIRSHARE
        ) {
            this.setupAdditionalInformationForm();
            this.setupFormlyFields();
            this.additionalInformationForm.patchValue({
                descriptionOfOperatingArea:
                    this.mission?.locationName ??
                    this.mission?.location?.name ??
                    '',
                // FIXME: this needs to be better derived
                operatorType:
                    this.delegatedAuthority?.authorityType?.ruleset?.abbreviation?.includes(
                        '102'
                    ) &&
                    this.delegatedAuthority?.authorityType?.activityType ===
                        'COMMERCIAL'
                        ? 'COMMERCIAL'
                        : 'RECREATIONAL'
            });
            this.additionalInformationForm.markAsPristine();
        }
        this.authorisationPreview = result;
        if (result == null) {
            return;
        }
        this.setupFormlyFields();
        this.updatedTimeForm.patchValue({
            startTime: fromTimestamp(
                this.mission.missionDate,
                this.mission.timeZone
            ),
            duration: this.mission.missionEstimatedTime
        });
        this.updatedTimeForm.markAsPristine();
        this.setOperatingArea(result);
    }

    // TODO: I don't thing non-live previews are a thing any more. Test and remove if no longer needed.
    /**
     * Generates an authorisation preview object for jurisdictions that don't offer an API preview
     * Pulls most details off the mission and airspaceCheckCommand to prefill as much as possible while still allowing submitting a request
     */
    // setupNonLivePreview() {
    //     if (this.mission == null && this.checkCommand == null) {
    //         this.authorisationPreview = null;
    //         return;
    //     }
    //     const rpic = this.mission.missionCrewDetails.find(
    //         c =>
    //             c.role?.coreRole != null &&
    //             c.role?.coreRole === MissionRoleDto.CoreRole.PILOT_IN_COMMAND
    //     );
    //     if (rpic != null) {
    //         this.checkRpicRegistration(rpic);
    //     }
    //
    //     this.delegatedAuthority =
    //         this.mission?.missionWorkflowVersion?.delegatedAuthority;
    //
    //     if (this.authorisation != null) {
    //         this.setupExistingAuthorisation();
    //         return;
    //     }
    //     const preview: AirspaceAuthorisationResponse = {
    //         // TODO: this should be derived in a different way, possibly in a different input object
    //         authorisationType: 'NZL_AIRSHARE',
    //         // TODO: currently this is just the max height allowed in NZ
    //         // maximumHeight: 400,
    //         validRequest: !this.missingRpicPhone
    //     };
    //     this.authorisationPreview = preview;
    //     this.setupAdditionalInformationForm();
    //     this.setupFormlyFields();
    //     this.additionalInformationForm.patchValue({
    //         descriptionOfOperatingArea:
    //             this.mission?.locationName ??
    //             this.mission?.location?.name ??
    //             '',
    //         // FIXME: this needs to be better derived
    //         operatorType:
    //             this.delegatedAuthority?.authorityType?.ruleset?.abbreviation?.includes(
    //                 '102'
    //             ) &&
    //             this.delegatedAuthority?.authorityType?.activityType ===
    //                 'COMMERCIAL'
    //                 ? 'COMMERCIAL'
    //                 : 'RECREATIONAL'
    //     });
    //     this.additionalInformationForm.markAsPristine();
    //     this.updatedTimeForm.patchValue({
    //         startTime: fromTimestamp(
    //             this.mission.missionDate,
    //             this.mission.timeZone
    //         ),
    //         duration: this.mission.missionEstimatedTime
    //     });
    //     this.updatedTimeForm.markAsPristine();
    //     this.setOperatingArea(preview);
    // }

    /**
     * A function to check the airspace authority registration status of a pilot without setting up an authorisation preview via the API
     * Should not be called if the authorisation preview API has been called already as it will result in a duplicate call.
     * @param rpic the mission's rpic as pulled from the mission's missionCrew field
     */
    checkRpicRegistration(rpic: MissionCrewDetailsDto) {
        // TODO: this probably isn't required anymore and can be removed. The main preview call should handle this.
        const command: RequestAirspaceAuthorisationCommand = {
            missionId: this.mission.id,
            remotePilotPhoneNumber: removeWhiteSpace(rpic?.person?.phoneNumber),
            authorisationType: this.airspaceAuthorisationType,
            flightArea: findSelectedFlightAreaPolygon(this.selectedFlightArea)
        };
        this.requestError = false;
    }

    showRegisterPilot() {
        this.angulartics2.eventTrack.next({
            action: 'authorisation-show-register-pilot',
            properties: {
                category: 'airspace-authorisation'
            }
        });
        const modal =
            this.airspaceDialoguesService.showRemotePilotRegistrationDialogue(
                this.authorisationPreview?.authorisationType ??
                    this.authorisations[0]?.authorisationType,
                this.formValues.rpic.id,
                this.organisation.id,
                this.formValues.rpic.phoneNumber
            );

        modal.content.success$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(success => {
                if (success) {
                    this.rpicMissingRequiredRegistration = false;
                    this.checkAuthorisation();
                    this.refreshMission();
                }
            });
    }

    updateRpicPhoneNumber(newNumber: string) {
        if (newNumber == null) {
            return;
        } else {
            const rpic = {
                ...this.formValues.rpic,
                phoneNumber: newNumber
            };
            this.airspaceAuthorisationForm.patchValue({
                rpicPhone: newNumber,
                rpic: rpic
            });
        }
    }

    setupFormlyFields() {
        this.rpicDetailsFields = [
            {
                key: 'phoneNumber',
                type: 'phonenumber',
                props: {
                    label: `RPIC Phone Number${
                        this.missingRpicPhone ? '' : ' (optional)'
                    }`,
                    required: this.missingRpicPhone ? true : false,
                    disabled: this.working
                }
            }
        ];
        if (
            this.additionalInformationFormlyFields == null &&
            this.authorisationPreview?.authorisationType === 'NZL_AIRSHARE'
        ) {
            this.enhancedHelpService
                .findByScreen('airspace-authorisation-preview')
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(fields => {
                    const findDescription = (key: string) => {
                        const textField = fields.find(f => f.component === key);
                        if (
                            textField == null ||
                            textField.helpText == null ||
                            textField.helpText.length === 0
                        ) {
                            return null;
                        }
                        return textField.helpText;
                    };
                    this.additionalInformationFormlyFields =
                        this.airspaceAuthorisationType ===
                        AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE
                            ? [
                                  {
                                      key: 'isShielded',
                                      type: 'radio',
                                      templateOptions: {
                                          label: `Shielded Operation`,
                                          required: true,
                                          options: [
                                              { value: true, name: 'Yes' },
                                              { value: false, name: 'No' }
                                          ],
                                          description:
                                              findDescription('isShielded')
                                      }
                                  },
                                  {
                                      key: 'isCameraInUse',
                                      type: 'radio',
                                      templateOptions: {
                                          label: `Camera In Use`,
                                          required: true,
                                          options: [
                                              { value: true, name: 'Yes' },
                                              { value: false, name: 'No' }
                                          ],
                                          description:
                                              findDescription('isCameraInUse')
                                      }
                                  },
                                  {
                                      key: 'flightPurpose',
                                      type: 'ng-select',
                                      templateOptions: {
                                          label: `Flight Purpose`,
                                          labelProp: 'name',
                                          valueProp: 'value',
                                          options: flightPurposes,
                                          columnSpan: 12,
                                          required: true,
                                          multiple: false,
                                          clearable: true,
                                          description:
                                              findDescription('flightPurpose')
                                      }
                                  },
                                  {
                                      key: 'hasCertifiedTransmitter',
                                      type: 'radio',
                                      templateOptions: {
                                          label: `Certified Transmitter`,
                                          required: true,
                                          options: [
                                              { value: true, name: 'Yes' },
                                              { value: false, name: 'No' }
                                          ],
                                          description: findDescription(
                                              'hasCertifiedTransmitter'
                                          )
                                      }
                                  },
                                  {
                                      key: 'hasVhfRadioContact',
                                      type: 'radio',
                                      templateOptions: {
                                          label: `VHF Radio Contact`,
                                          required: true,
                                          options: [
                                              { value: true, name: 'Yes' },
                                              { value: false, name: 'No' }
                                          ],
                                          description:
                                              findDescription(
                                                  'hasVhfRadioContact'
                                              )
                                      }
                                  },
                                  {
                                      key: 'descriptionOfOperatingArea',
                                      type: 'input',
                                      templateOptions: {
                                          label: `Description of Operating Area`,
                                          required: false,
                                          description: findDescription(
                                              'descriptionOfOperatingArea'
                                          )
                                      }
                                  },
                                  {
                                      key: 'emergencyProcedure',
                                      type: 'input',
                                      templateOptions: {
                                          label: `Emergency Procedure`,
                                          required: false,
                                          description:
                                              findDescription(
                                                  'emergencyProcedure'
                                              )
                                      }
                                  },
                                  {
                                      key: 'procedureMeasureAltitude',
                                      type: 'input',
                                      templateOptions: {
                                          label: `Procedure To Measure Altitude`,
                                          required: false,
                                          description: findDescription(
                                              'procedureMeasureAltitude'
                                          )
                                      }
                                  },
                                  {
                                      key: 'otherInformation',
                                      type: 'input',
                                      templateOptions: {
                                          label: `Other Information`,
                                          required: false,
                                          description:
                                              findDescription(
                                                  'otherInformation'
                                              )
                                      }
                                  }
                              ]
                            : [];
                })
                .add(this.workTracker.createTracker());
        }
        this.findCountryCode();
    }

    setupAdditionalInformationForm() {
        this.additionalInformationForm =
            new FormGroup<AdditionalInformationForm>({
                isShielded: new FormControl(undefined),
                isCameraInUse: new FormControl(undefined),
                flightPurpose: new FormControl(undefined, Validators.required),
                operatorType: new FormControl(undefined),
                hasCertifiedTransmitter: new FormControl(
                    undefined,
                    Validators.required
                ),
                hasVhfRadioContact: new FormControl(
                    undefined,
                    Validators.required
                ),
                descriptionOfOperatingArea: new FormControl(undefined),
                emergencyProcedure: new FormControl(undefined),
                procedureMeasureAltitude: new FormControl(undefined),
                otherInformation: new FormControl(undefined),
                safetyJustification: new FormControl(undefined)
            });
    }

    findCountryCode() {
        this.userLocationService
            .findUserLocation()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: res => {
                    const country = countryStates.find(
                        c => c.iso === res.value
                    );
                    if (country == null) {
                        return;
                    }
                    const number =
                        this.rpicDetailsForm.controls.phoneNumber.value ?? '';
                    this.rpicDetailsForm.controls.phoneNumber.patchValue(
                        `+${country.phonePrefix}${
                            number[0] === '0' ? number.slice(1) : number
                        }`
                    );
                },
                error: DO_NOTHING
            })
            .add(this.workTracker.createTracker());
    }

    private setOperatingArea(
        result: AirspaceAuthorisationDto | AirspaceAuthorisationResponse
    ) {
        // const geometry =
        //     result != null ? <GeoJSON.Polygon>result.operatingArea : null;
        // this.addFeature({
        //     name: 'Operating Area',
        //     type: LocationFeatureDto.Type.AREA_OF_INTEREST,
        //     geometry: geometry
        // } as LocationFeatureDto);
    }

    selectFlightArea(area: LocationFeatureDto) {
        const oldSelected = this.selectedFlightArea;
        this.selectedFlightArea = area;
        this.locationFeatures = this.locationFeatures.filter(
            fg =>
                fg.name !== oldSelected.name &&
                fg.name !== area.name &&
                fg?.categories?.find(
                    c =>
                        (c.name === oldSelected.name &&
                            c.id === oldSelected.type) ||
                        (c.name === area.name && c.id === area.type)
                ) == null
        );
        const index = this.missionFlightAreas.findIndex(
            f =>
                f.name === this.selectedFlightArea.name &&
                f.type === this.selectedFlightArea.type
        );
        this.missionFlightAreas.forEach((a, i) => {
            this.addFeature({
                name: a.name,
                type:
                    i !== index
                        ? 'SECONDARY_FLIGHT_AREA'
                        : LocationFeatureDto.Type.FLIGHT_AREA,
                geometry: findLocationFlightAreaPolygon([a])
            } as LocationFeatureDto);
        });
        this.checkAuthorisation();
    }

    private addFeature(locationFeature: LocationFeatureDto) {
        if (
            this.locationFeatures.length === 0 ||
            this.locationFeatures.filter(
                fg =>
                    fg?.categories?.findIndex(
                        c => c.id === locationFeature.type
                    ) !== -1
            ).length === 0
        ) {
            this.locationFeatures = this.locationFeatures.concat({
                id: this.nextId,
                name: locationFeature.name,
                type: 'Polygon',
                canAdd: false,
                categories: [
                    {
                        id: locationFeature.type,
                        name: locationFeature.name
                    }
                ],
                existingFeatures:
                    locationFeature != null
                        ? [toMapFeature(locationFeature, this.nextId++)]
                        : [],
                styles: {
                    fill: [
                        {
                            paint: {
                                'fill-color': '#ff1234'
                            },
                            filter: ['!', ['has', 'category-id']]
                        },
                        {
                            paint: {
                                'fill-color': '#990000',
                                'fill-opacity': 0.4
                            },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.FLIGHT_AREA
                            ]
                        },
                        {
                            paint: {
                                'fill-color': '#6b3c3c',
                                'fill-opacity': 0.4
                            },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                'SECONDARY_FLIGHT_AREA'
                            ]
                        },
                        {
                            paint: {
                                'fill-pattern': 'no-fly',
                                'fill-opacity': 0.3
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.DANGER
                            ]
                        },
                        {
                            paint: {
                                'fill-pattern': 'danger-area',
                                'fill-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.NO_FLY
                            ]
                        },
                        {
                            paint: {
                                'fill-color': 'hsl(50,100,50)',
                                'fill-opacity': 0.4
                            },
                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.AREA_OF_INTEREST
                            ]
                        }
                    ],
                    line: [
                        {
                            paint: {
                                'line-color': '#990000',
                                'line-width': 3,
                                'line-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.FLIGHT_AREA
                            ]
                        },
                        {
                            paint: {
                                'line-color': '#6b3c3c',
                                'line-width': 3,
                                'line-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                'SECONDARY_FLIGHT_AREA'
                            ]
                        },
                        {
                            paint: {
                                'line-color': '#fecb1c',
                                'line-width': 3,
                                'line-opacity': 0.5
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.DANGER
                            ]
                        },
                        {
                            paint: {
                                'line-width': 3,
                                'line-opacity': 0.5
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.NO_FLY
                            ]
                        },
                        {
                            paint: {
                                'line-color': 'hsl(50,100,50)',
                                'line-width': 3,
                                'line-opacity': 1
                            },

                            filter: [
                                '==',
                                ['get', 'category-id'],
                                LocationFeatureDto.Type.AREA_OF_INTEREST
                            ]
                        }
                    ],
                    symbol: [
                        {
                            layout: {
                                'text-field': '{name}',
                                'symbol-placement': 'line',
                                'text-anchor': 'bottom',
                                'symbol-spacing': 150,
                                'text-max-angle': 35,
                                'text-size': 12,
                                'text-letter-spacing': 0.1,
                                'text-font': [
                                    'Roboto Bold',
                                    'Arial Unicode MS Regular'
                                ]
                            },
                            paint: {
                                'text-halo-color': 'hsl(0, 100%, 100%)',
                                'text-halo-width': 1,
                                'text-halo-blur': 1
                            }
                        }
                    ]
                }
            });
        } else {
            this.locationFeatures = this.locationFeatures.map(fg =>
                fg.categories.findIndex(c => c.id === locationFeature.type) !==
                -1
                    ? {
                          ...fg,
                          existingFeatures: fg.existingFeatures.concat(
                              toMapFeature(locationFeature, this.nextId++)
                          )
                      }
                    : fg
            );
        }
        this.features$.next(this.locationFeatures);
    }

    showPilot(pilot: PersonDto) {
        this.personService
            .findPerson(pilot.id, this.mission.organisationId)
            .toPromise()
            .then(
                person => {
                    this.personnelDialoguesService.showPersonDetailsDialogue(
                        person,
                        this.mission.organisationId,
                        false
                    );
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error retrieving details for ${pilot.firstName} ${pilot.lastName}: ${error.message}`
                    )
            );
    }

    showRpa(rpa: CraftDto) {
        this.rpaDialoguesService.showCraftDetails(
            rpa.id,
            this.organisation.id,
            false
        );
    }

    cancelEditStart() {
        this.updatedTimeForm.controls.startTime.patchValue(
            fromTimestamp(this.mission.missionDate, this.mission.timeZone)
        );
        this.editStart = false;
    }

    cancelEditDuration() {
        this.updatedTimeForm.controls.duration.patchValue(
            this.mission.missionEstimatedTime
        );
        this.editEnd = true;
    }

    updateMissionTime() {
        const timeValues = <{ startTime: Date; duration: number }>(
            this.updatedTimeForm.value
        );
        this.missionTimeUpdated.emit(timeValues);
        this.editStart = false;
        this.editEnd = false;
    }

    updateMissionDetails() {
        // TODO: ensure this plays nice with the services
        this.angulartics2.eventTrack.next({
            action: 'authorisation-mission-update',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        const timeValues = this.updatedTimeForm.value;
        const updateCommand: UpdateMissionCommand = {
            ...this.mission,
            isDummy: this.mission.isDummy ?? false,
            equipmentIds: this.mission.equipment.map(e => e.id),
            missionCrew: this.mission.missionCrewDetails.reduce(
                (acc: MissionCrewDto[], c: MissionCrewDetailsDto) => [
                    ...acc,
                    {
                        missionRoleId: c.role.id,
                        personId: c.person.id
                    }
                ],
                []
            ),
            aerodromesOfInterest: this.mission.aerodromesOfInterest.map(
                a => a.identifier
            ),
            notams:
                this.mission.notams.map(n => ({
                    id: n.id,
                    location: n.location,
                    fir: n.fir
                })) ?? [],
            missionDate: toTimestamp(
                timeValues.startTime,
                this.mission.timeZone
            ),
            missionEstimatedTime: timeValues.duration,
            missionTypeId: this.mission.missionType.id,
            additionalAuthorityIds: this.mission.additionalAuthorities?.map(
                a => a.id
            ),
            emergencyContacts:
                this.mission.modelVersion === 1
                    ? this.mission.emergencyContacts
                    : null,
            radioFrequencies:
                this.mission.modelVersion === 1
                    ? this.mission.radioFrequencies
                    : null,
            missionObjectiveTypeId:
                this.mission.missionObjectiveType?.id ?? null
        };
        const doneWorking = this.workTracker.createTracker();
        this.missionService
            .updateMission(this.mission.id, updateCommand)
            .then(mission => {
                doneWorking();
                this.updatedTimeForm.markAsPristine();
                this.editStart = false;
                this.editEnd = false;
                this.authorisationPreview = null;
                this.locationFeatures = [];
                this.features$.next([]);
                this.mapSourceFilters = {};
                this.nextId = 0;
                this.resetComponentState();
                this.mission = mission;
                this.selectedFlightArea =
                    this.mission?.location?.features?.find(
                        f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
                    );
                this.createCheckCommand(mission);
                this.missionUpdated.emit(mission);
            })
            .catch((error: FlyFreelyError) => {
                this.logging.error(error, error.message);
                doneWorking();
            });
    }

    goToRelatedMission(id: number) {
        this.airspaceAuthorisationManager.goToRelatedMission(id);
    }

    showMissionEditDialogue() {
        this.angulartics2.eventTrack.next({
            action: 'authorisation-mission-edit',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        this.missionDialoguesService.showMissionEditor(
            this.mission,
            this.organisation
        );
    }

    requestAuthorisation() {
        this.angulartics2.eventTrack.next({
            action: 'request-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        // TODO: do we need a specific acknowledgement for each authorisation type? i.e. should this be a multi-functional modal?
        if (
            this.authorisationPreview.authorisationType ===
            'AUS_CASA_AUTHORISATION'
        ) {
            const dialogue =
                this.airspaceDialoguesService.showAuthorisationAcknowledgementDialogue();

            dialogue.content.acknowledged
                .pipe(takeUntil(this.ngUnsubscribe$), take(1))
                .subscribe(() => this.submit());
        } else {
            this.submit();
        }
    }

    submit() {
        // TODO: update this to use the services
        const additionalInformation = this.additionalInformationForm?.value;
        const formValues = this.airspaceAuthorisationForm.value;
        // FIXME: this is still not the proper way to do this
        const additionalProperties =
            this.airspaceAuthorisationType ===
            AirspaceAuthorisationDto.AuthorisationType.AUS_CASA_AUTHORISATION
                ? {
                      flightProfile:
                          this.airspaceAuthorisationForm.value.flightProfile
                  }
                : this.airspaceAuthorisationType ===
                  AirspaceAuthorisationDto.AuthorisationType.USA_LAANC
                ? {
                      safetyJustification: this.justificationRequired
                          ? this.airspaceAuthorisationForm.value
                                .safetyJustification
                          : null
                  }
                : additionalInformation?.flightPurpose != null &&
                  additionalInformation.flightPurpose.length > 0
                ? <AdditionalInformationFields>{
                      isCameraInUse: additionalInformation.isCameraInUse,
                      isShielded: additionalInformation.isShielded,
                      flightPurpose: additionalInformation.flightPurpose,
                      // TODO: figure out how to derive operatorType
                      operatorType:
                          additionalInformation.operatorType ?? 'RECREATIONAL',
                      controlZoneRequest: {
                          descriptionOfOperatingArea:
                              additionalInformation.descriptionOfOperatingArea ??
                              '',
                          emergencyProcedure:
                              additionalInformation.emergencyProcedure ?? '',
                          otherInformation:
                              additionalInformation.otherInformation ?? '',
                          procedureMeasureAltitude:
                              additionalInformation.procedureMeasureAltitude ??
                              '',
                          hasCertifiedTransmitter:
                              additionalInformation.hasCertifiedTransmitter,
                          hasVhfRadioContact:
                              additionalInformation.hasVhfRadioContact
                      }
                  }
                : null;
        const command: RequestAirspaceAuthorisationCommand = {
            missionId: this.mission.id,
            remotePilotPhoneNumber: removeWhiteSpace(
                formValues.rpic?.phoneNumber
            ),
            authorisationType: this.airspaceAuthorisationType,
            additionalProviderProperties: additionalProperties,
            flightArea: findSelectedFlightAreaPolygon(this.selectedFlightArea),
            publicMissionName: formValues.publicMissionName
        };
        this.requestError = false;

        this.airspaceAuthorisationManager
            .applyForAuthorisation(command, this.formValues)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.authorisationPreview = null;
                    this.requestNew = false;
                    this.authorisations =
                        result != null &&
                        authorisationTypesWithMultipleAuthorisations.includes(
                            result[0]?.authorisationType
                        )
                            ? result
                            : [
                                  findLatestAirspaceAuthorisationInList(
                                      this.authorisations
                                  )
                              ];
                    this.airspaceAuthorisationStatus =
                        calculateAuthorisationListState(this.authorisations);
                    this.setupExistingAuthorisation(false);
                    this.requestNew = false;
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while requesting authorisation: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    cancelAuthorisation(authorisation: OperationAuthorisation) {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                `Cancel Authorisation ${authorisation.id}`,
                'Are you sure you want to cancel this authorisation?',
                'Yes',
                () =>
                    firstValueFrom(
                        this.airspaceAuthorisationManager
                            .cancelAuthorisation(
                                authorisation.id,
                                this.airspaceAuthorisationType
                            )
                            .pipe(
                                catchError((error: FlyFreelyError) => {
                                    this.logging.error(
                                        error,
                                        `Error while cancelling authorisation: ${error.message}`
                                    );
                                    return throwError(() => error);
                                })
                            )
                    )
            )
            .then(result => {
                doneWorking();
                this.logging.success('Authorisation cancelled successfully');
                this.checkAuthorisation();
            })
            .catch(() => doneWorking());
    }

    closeAuthorisation(authorisation: OperationAuthorisation) {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                `Close Authorisation ${authorisation.id}`,
                'Are you sure you want to close this authorisation?',
                'Yes',
                () =>
                    firstValueFrom(
                        this.airspaceAuthorisationManager
                            .closeAuthorisation(
                                authorisation.id,
                                this.airspaceAuthorisationType
                            )
                            .pipe(
                                catchError((error: FlyFreelyError) => {
                                    this.logging.error(
                                        error,
                                        `Error while closing authorisation: ${error.message}`
                                    );
                                    return throwError(() => error);
                                })
                            )
                    )
            )
            .then(result => {
                doneWorking();
                this.logging.success('Authorisation cancelled successfully');
                this.checkAuthorisation();
            })
            .catch(() => doneWorking());
    }

    deleteAuthorisation(authorisation: OperationAuthorisation) {
        const isFinalAuthorisation =
            this.authorisations.filter(a => a.id !== authorisation.id)
                .length === 0;
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                `Delete Authorisation`,
                'Are you sure you want to delete this authorisation? This cannot be undone.',
                'Yes',
                () =>
                    firstValueFrom(
                        this.airspaceAuthorisationManager
                            .deleteAuthorisation(
                                authorisation.id,
                                this.airspaceAuthorisationType
                            )
                            .pipe(
                                catchError((error: FlyFreelyError) => {
                                    this.logging.error(
                                        error,
                                        `Error while deleting authorisation: ${error.message}`
                                    );
                                    return throwError(() => error);
                                })
                            )
                    )
            )
            .then(result => {
                doneWorking();
                this.logging.success('Authorisation deleted successfully');
                if (isFinalAuthorisation) {
                    this.close();
                } else {
                    this.checkAuthorisation();
                }
            })
            .catch(() => doneWorking());
    }

    acknowledgeRescinded(authorisation: OperationAuthorisation) {
        this.airspaceAuthorisationManager
            .acknowledgeRescinded(
                authorisation.id,
                this.airspaceAuthorisationType
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success('Rescinded status acknowledged');
                    this.checkAuthorisation();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while acknowledging rescinded status: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    onFullscreenRequested() {
        this.fullScreenService.toggleFullScreen(this.map);
    }

    close() {
        this.modal.hide();
    }

    isFeet(unit: string) {
        return unit.toLowerCase() === 'feet';
    }

    get isInPast() {
        return this.formValues?.startTime != null
            ? moment(this.formValues.startTime).isBefore(moment())
            : false;
    }

    get flightProfileName() {
        if (this.casaFlightProfile == null) {
            return '';
        }
        return this.casaFlightProfiles.find(
            p => p.value === this.casaFlightProfile
        )?.name;
    }

    get formValues() {
        return <AirspaceAuthorisationFormValue>(
            this.airspaceAuthorisationForm?.value
        );
    }

    get delegatedAuthorityLabel() {
        if (
            this.airspaceAuthorisationType == null ||
            delegatedAuthorityLabel[this.airspaceAuthorisationType] == null
        ) {
            return 'Delegated Authority';
        }
        return delegatedAuthorityLabel[this.airspaceAuthorisationType];
    }

    get justificationLabel() {
        if (
            this.airspaceAuthorisationType == null ||
            justificationLabel[this.airspaceAuthorisationType] == null
        ) {
            return 'Justification';
        }
        return justificationLabel[this.airspaceAuthorisationType];
    }

    get justificationRequired() {
        return this.airspaceAuthorisationPreviewService.justificationRequired;
    }
}
