import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
    BatteryService,
    BatterySetDto,
    BatterySetService,
    BatteryTypeDto,
    BatteryTypeService,
    compareDates,
    hasAnyPermission,
    PersonsOrganisationDto,
    PreferencesService,
    toLookup,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatResourceStatusPipe } from '@flyfreely-portal-ui/resource-ui';
import {
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { BatteryDialoguesService } from 'libs/batteries/src/lib/battery-dialogues.service';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';
import { dateRange } from 'libs/flyfreely-table/src/lib/search';
import { FormatDatePipe } from 'libs/ui/src/lib/pipes/generic/formatDate';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import * as moment from 'moment';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { WidgetsPreferencesService } from '../widget-preferences.service';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';

const BATTERY_SET_IDENTIFIER = 'batterySetList';

interface BatterySetWithType extends BatterySetDto {
    batteryType?: BatteryTypeDto;
    convertedAssemblyDate: moment.Moment;
    convertedDisassemblyDate: moment.Moment;
}

@Component({
    selector: 'battery-set-list-widget',
    templateUrl: './battery-set-list-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class BatterySetListWidgetComponent
    implements OnInit, OnDestroy, Widget {
    organisation: PersonsOrganisationDto;

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    batterySets: BatterySetWithType[];

    canAddBatterySet: boolean;
    userPreferences: TableSetupUserPreferences;

    public working: boolean;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    private showDummyData$: Observable<boolean>;
    private batterySets$ = new Subject<BatterySetWithType[]>();
    private batteryTypes$ = new Subject<BatteryTypeDto[]>();

    constructor(
        private batteryTypeService: BatteryTypeService,
        private batterySetService: BatterySetService,
        batteryService: BatteryService,
        private batteryDialogues: BatteryDialoguesService,
        private workspaceStateService: WorkspaceStateService,
        private preferencesService: PreferencesService,
        formatDatePipe: FormatDatePipe,
        resourceStatusPipe: FormatResourceStatusPipe,
        private preferences: WidgetsPreferencesService
    ) {
        this.showDummyData$ = preferencesService.showDummyData$.pipe(
            takeUntil(this.ngUnsubscribe$)
        );

        this.availableColumns = [
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'convertedAssemblyDate',
                key: 'assemblyDate',
                name: 'Assembly Date',
                searchable: 'daterange',
                selectable: true,
                defaultSelection: true,
                formatterFunction: d => formatDatePipe.transform(d),
                searchFunction: dateRange,
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
            },
            {
                value: 'convertedDisassemblyDate',
                key: 'disassemblyDate',
                name: 'Disassembly Date',
                searchable: 'daterange',
                selectable: true,
                defaultSelection: false,
                formatterFunction: d => formatDatePipe.transform(d),
                searchFunction: dateRange,
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
            },
            {
                value: 'batteryType.make',
                name: 'Battery Type Make',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'batteryType.model',
                name: 'Battery Type Model',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'status',
                name: 'Status',
                searchable: 'selection',
                selectable: true,
                defaultSelection: true,
                searchOptions: batteryService.getStatuses(),
                defaultSearch: 'SERVICEABLE',
                formatterFunction: s => resourceStatusPipe.transform(s)
            }
        ];
        this.selectedColumns = null;

        this.columnSorting = null;

        this.tableSearch = null;

        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshPermissions();
                this.refreshBatteryTypes();
                this.refreshBatterySets();
                this.loadPreferences();
            });
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        combineLatest([
            this.batterySets$,
            this.showDummyData$,
            this.batteryTypes$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([batterySets, showDummy, batteryTypes]) => {
                const batteryTypeLookup = batteryTypes.reduce(toLookup, {});

                this.batterySets = batterySets
                    .filter(m => showDummy || !m.isDummy)
                    .map(s => ({
                        ...s,
                        batteryType:
                            s.batteries.length === 0
                                ? null
                                : batteryTypeLookup[
                                      s.batteries[0].batteryTypeId
                                  ]
                    }));
            });

        this.batterySetService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshBatterySets();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        // check for changes to the bindings
        if ('organisation' in changes) {
            this.refreshPermissions();
            this.refreshBatteryTypes();
            this.refreshBatterySets();
            this.loadPreferences();
        }
    }

    private refreshPermissions() {
        this.canAddBatterySet = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.BATTERYSET_ADD
        );
    }

    private loadPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferences
            .findPreferences('batterySetListWidgetTable', this.organisation.id)
            .pipe(
                switchMap(preferences => {
                    if (preferences == null) {
                        return this.preferences
                            .findLegacyPreferences(
                                'batterySetListWidgetColumns',
                                this.organisation.id
                            )
                            .pipe(
                                map(pref => ({
                                    selectedColumns: pref,
                                    columnSorting: null,
                                    tableSearch: null,
                                    itemLimit: this.tableConfig.limit
                                })),
                                catchError(() => {
                                    this.handleDefaultPreferences();
                                    return of(null);
                                })
                            );
                    } else {
                        return of(preferences);
                    }
                }),
                catchError(() => {
                    this.selectedColumns = findDefaultColumnSelection(
                        this.availableColumns
                    );
                    return EMPTY;
                })
            )
            .subscribe(preferences => {
                this.applyPreferences(preferences);
            })
            .add(this.workTracker.createTracker);
    }

    private handleDefaultPreferences() {
        this.applyPreferences({});
    }

    private applyPreferences(preferences: TableSetupUserPreferences) {
        this.userPreferences = this.preferences.applyPreferences(
            preferences,
            this.availableColumns,
            this.tableConfig
        );

        this.selectedColumns = this.userPreferences.selectedColumns;
        this.columnSorting = this.userPreferences.columnSorting;
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit = this.userPreferences.itemLimit;

        this.preferences.updateDateRange(this.tableSearch, 'assemblyDate');
        this.preferences.updateDateRange(this.tableSearch, 'disassemblyDate');
    }

    private refreshBatteryTypes() {
        const doneWorking = this.workTracker.createTracker();
        this.batteryTypeService
            .find(this.organisation.id)
            .toPromise()
            .then(batteryTypes => {
                this.batteryTypes$.next(batteryTypes);
                doneWorking();
            });
    }

    private refreshBatterySets() {
        this.batterySets = null;
        if (!this.organisation) {
            return;
        }
        const doneWorking = this.workTracker.createTracker();
        this.batterySetService
            .findBatterySets(this.organisation.id)
            .toPromise()
            .then(results => {
                this.batterySets$.next(
                    results.map(r => ({
                        ...r,
                        convertedAssemblyDate: r.assemblyDate
                            ? moment(r.assemblyDate)
                            : null,
                        convertedDisassemblyDate: r.disassemblyDate
                            ? moment(r.disassemblyDate)
                            : null
                    }))
                );
                doneWorking();
            });
    }

    newBatterySet() {
        this.batteryDialogues.showAddBatterySetDialogue(this.organisation.id);
    }

    showBatterySet(batterySet: BatterySetDto) {
        this.batteryDialogues.showBatterySetDetailsDialogue(
            batterySet,
            this.organisation
        );
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'batterySetListWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }
}

export const batterySetWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: BATTERY_SET_IDENTIFIER,
    component: BatterySetListWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.BATTERYSET_LIST
        )
};
