import { Injectable } from '@angular/core';
import {
    Angulartics2,
    GoogleAnalyticsSettings,
    GoogleTagManagerSettings
} from 'angulartics2';

/**
 * Code pulled from
 * https://github.com/angulartics/angulartics2/blob/3d754ef5324bf1cdb43096aba178d71b9ab89915/src/lib/providers/gtm/gtm.ts
 */

declare let dataLayer: any;

export class GoogleTagManagerDefaults implements GoogleTagManagerSettings {
    userId = null;
}

@Injectable({ providedIn: 'root' })
export class Angulartics2GoogleGA4TagManager {
    dimensionsAndMetrics = [];
    settings: Partial<GoogleAnalyticsSettings>;

    constructor(private angulartics2: Angulartics2) {
        // The dataLayer needs to be initialized
        if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer = (window as any).dataLayer =
                (window as any).dataLayer || [];
        }
        const defaults = new GoogleTagManagerDefaults();
        // Set the default settings for this module
        this.angulartics2.settings.gtm = {
            ...defaults,
            ...this.angulartics2.settings.gtm
        };
        this.angulartics2.setUsername.subscribe((x: string) =>
            this.setUsername(x)
        );
    }

    startTracking(): void {
        this.angulartics2.pageTrack
            .pipe(this.angulartics2.filterDeveloperMode())
            .subscribe(x => this.pageTrack(x.path));
        this.angulartics2.eventTrack
            .pipe(this.angulartics2.filterDeveloperMode())
            .subscribe(x => this.eventTrack(x.action, x.properties));
        this.angulartics2.exceptionTrack
            .pipe(this.angulartics2.filterDeveloperMode())
            .subscribe((x: any) => this.exceptionTrack(x));
    }

    pageTrack(path: string) {
        this.pushLayer({
            event: 'Page View',
            'content-name': path,
            userId: this.angulartics2.settings.gtm.userId
        });
    }

    /**
     * Send Data Layer
     *
     * @layer data layer object
     */
    pushLayer(layer: any) {
        if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push(layer);
        }
    }

    /**
     * Send interactions to the dataLayer, i.e. for event tracking in Google Analytics
     *
     * @param action associated with the event
     */
    eventTrack(action: string, properties: any) {
        // Set a default GTM category
        properties = properties || {};

        this.pushLayer({
            event: properties.event || 'interaction',
            target: properties.category || 'Event',
            action,
            label: properties.label,
            value: properties.value,
            interactionType: properties.noninteraction,
            userId: this.angulartics2.settings.gtm.userId,
            ...properties.gtmCustom
        });
    }

    /**
     * Set userId for use with Universal Analytics User ID feature
     *
     * @param userId used to identify user cross-device in Google Analytics
     */
    setUsername(userId: string) {
        this.angulartics2.settings.gtm.userId = userId;
    }

    /**
     * Exception Track Event in GTM
     *
     */
    exceptionTrack(properties: any) {
        if (
            !properties ||
            !properties.appId ||
            !properties.appName ||
            !properties.appVersion
        ) {
            console.error('Must be setted appId, appName and appVersion.');
            return;
        }

        if (properties.fatal === undefined) {
            console.log('No "fatal" provided, sending with fatal=true');
            properties.exFatal = true;
        }

        properties.exDescription = properties.event
            ? properties.event.stack
            : properties.description;

        this.eventTrack(
            `Exception thrown for ${properties.appName} <${properties.appId}@${properties.appVersion}>`,
            {
                category: 'Exception',
                label: properties.exDescription
            }
        );
    }
}
