<ng-container screenAnalytics="equipment-edit">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img [src]="IMG_URL + '/icons/icon-equipment.png'" />
        </div>
        <h3 id="dialog-child-name" class="modal-title">Create Equipment</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="equipmentForm">
            <div class="form-group top-buffer">
                <field-validation-indicator
                    class="pull-right"
                    [noun]="'field'"
                    [placement]="'left'"
                    validatorFormControlName="equipmentCategory"
                ></field-validation-indicator>
                <label>Equipment Category</label>
                <ng-select
                    [items]="equipmentCategories"
                    [readonly]="working"
                    bindValue="value"
                    bindLabel="name"
                    appendTo="body"
                    (change)="onEquipmentTypeSelectionChange($event)"
                    (clear)="clearEquipmentType($event)"
                    formControlName="equipmentCategory"
                >
                </ng-select>
            </div>
            <enhanced-help componentName="equipmentCategory" [compact]="true">
            </enhanced-help>

            <div class="horizontal-container">
                <div class="form-group top-buffer" formGroupName="make">
                    <field-validation-indicator
                        class="pull-right"
                        [noun]="'field'"
                        [placement]="'left'"
                        validatorFormControlName=""
                    ></field-validation-indicator>
                    <label>Equipment Make</label>
                    <ng-select
                        [items]="equipmentMakes"
                        [readonly]="makeLocked"
                        bindValue="value"
                        bindLabel="name"
                        [addTag]="true"
                        addTagText="equipmentModelId"
                        appendTo="body"
                        (change)="equipmentMakeOnChange($event)"
                        formControlName="makeSelect"
                    >
                    </ng-select>

                    <div *ngIf="!makeListed">
                        <input
                            [readonly]="makeListed"
                            type="text"
                            class="form-control"
                            formControlName="makeText"
                            placeholder="Type the Equipment Make here..."
                        />
                    </div>
                </div>
                <div class="form-group top-buffer" formGroupName="model">
                    <field-validation-indicator
                        class="pull-right"
                        [noun]="'field'"
                        [placement]="'left'"
                        validatorFormControlName=""
                    ></field-validation-indicator>
                    <label>Equipment Model</label>
                    <ng-select
                        [items]="equipmentModels"
                        [readonly]="modelLocked"
                        bindValue="value"
                        bindLabel="name"
                        appendTo="body"
                        (change)="equipmentModelOnChange($event)"
                        formControlName="modelSelect"
                    >
                    </ng-select>
                    <div *ngIf="!modelListed">
                        <input
                            [readonly]="modelListed"
                            type="text"
                            class="form-control"
                            formControlName="modelText"
                            placeholder="Type the Equipment Model here..."
                        />
                    </div>
                </div>
            </div>
            <enhanced-help componentName="makeModel" [compact]="true">
            </enhanced-help>

            <div class="form-group top-buffer">
                <field-validation-indicator
                    class="pull-right"
                    [noun]="'field'"
                    [placement]="'left'"
                    validatorFormControlName="name"
                ></field-validation-indicator>
                <label>Name/Designation</label>
                <input
                    type="text"
                    formControlName="name"
                    class="form-control"
                    maxlength="100"
                />
            </div>
            <enhanced-help componentName="name" [compact]="true">
            </enhanced-help>

            <div class="form-group top-buffer">
                <field-validation-indicator
                    class="pull-right"
                    [noun]="'field'"
                    [placement]="'left'"
                    validatorFormControlName="manufacturerSerialNumber"
                ></field-validation-indicator>
                <label>Serial Number</label>
                <input
                    type="text"
                    formControlName="manufacturerSerialNumber"
                    class="form-control"
                    maxlength="100"
                />
            </div>
            <enhanced-help
                componentName="manufacturerSerialNumber"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <field-validation-indicator
                    validatorFormControlName="currentFirmwareVersion"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>Current Firmware Version </label>
                <input
                    type="text"
                    formControlName="currentFirmwareVersion"
                    class="form-control"
                    maxlength="10"
                />
            </div>
            <enhanced-help
                componentName="currentFirmwareVersion"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <field-validation-indicator
                    validatorFormControlName="nfcId"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>NFC ID</label>
                <input
                    type="text"
                    formControlName="nfcId"
                    class="form-control"
                    maxlength="30"
                />
            </div>
            <enhanced-help componentName="nfcId" [compact]="true">
            </enhanced-help>

            <div class="form-group top-buffer">
                <div>
                    <label>Equipment Classification</label>
                    <field-validation-indicator
                        validatorFormControlName="isDummy"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>

                    <div
                        class="input-group"
                        btnRadioGroup
                        formControlName="isDummy"
                        style="padding-top: 5px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="false"
                            [disabled]="working"
                            >Real Equipment</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="true"
                            [disabled]="working"
                            >Dummy Equipment</label
                        >
                    </div>
                    <validation-messages validatorFormControlName="isDummy">
                    </validation-messages>
                </div>
            </div>
            <enhanced-help componentName="isDummy" [compact]="true">
            </enhanced-help>
        </form>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-default pull-left"
            type="button"
            [disabled]="working"
            (click)="bulkUpload()"
        >
            Bulk Upload
        </button>
        <button
            class="btn btn-default"
            type="button"
            [disabled]="working || equipmentForm.invalid"
            (click)="addAnother()"
        >
            Add Another
        </button>
        <button
            class="btn btn-primary"
            type="button"
            [disabled]="working || equipmentForm.invalid"
            (click)="submit()"
            data-cy="equipmentAddCreateButton"
        >
            Create
        </button>
    </div>
</ng-container>
