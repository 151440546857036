<ng-container
    screenAnalytics="flight-log-settings"
    [screenContext]="screenContext"
>
    <div class="modal-header">
        <h3 class="modal-title">Flight Log Collection Settings</h3>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body max-height">
        <ng-container *ngIf="hasDjiLogs && (canManageAll || canManageOwn)">
            <h4 class="section-heading">
                <span>DJI</span>
                <div></div>
            </h4>
            <plan-highlight
                [featureFlags]="featureFlags['hasDjiLogs']"
            ></plan-highlight>
            <dji-sync-settings
                [hasMissionConfig]="hasMissionConfig"
                [canManageAll]="canManageAll"
            ></dji-sync-settings>
        </ng-container>
        <upsell
            *ngIf="!hasDjiLogs && (canManageAll || canManageOwn)"
            componentName="upsell-dji"
        ></upsell>

        <ng-container *ngIf="hasDroneDeploy && canManageAll">
            <h4 class="section-heading">
                <span>DroneDeploy</span>
                <div></div>
            </h4>
            <plan-highlight
                [featureFlags]="featureFlags['hasDroneDeploy']"
            ></plan-highlight>
            <drone-deploy-settings></drone-deploy-settings>
        </ng-container>
        <upsell
            *ngIf="!hasDroneDeploy && canManageAll"
            componentName="upsell-dronedeploy"
        ></upsell>

        <ng-container *ngIf="hasSkydio && canManageAll">
            <h4 class="section-heading">
                <span>Skydio</span>
                <div></div>
            </h4>
            <plan-highlight
                [featureFlags]="featureFlags['hasSkydio']"
            ></plan-highlight>
            <skydio-settings></skydio-settings>
        </ng-container>
        <upsell
            *ngIf="!hasSkydio && canManageAll"
            componentName="upsell-skydio"
        ></upsell>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
    <div class="modal-footer">
        <button
            *ngIf="canManageAll && hasMissionConfig"
            class="btn btn-primary"
            type="button"
            (click)="showOrganisationSettings()"
        >
            <span class="fal fa-cog"></span> Default Automatic Mission Creation
            Settings
        </button>
    </div>
</ng-container>
