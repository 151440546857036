import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {
    AuthorityService,
    AuthorityTypeDto,
    CraftAuthorityDto,
    CraftAuthorityGroup,
    CraftDto,
    CreateCraftAuthorityCommand,
    DO_NOTHING,
    NameId,
    OrganisationAuthorityDto,
    OrganisationService,
    RpaAuthorityService,
    SimpleAirspaceJurisdictionDto,
    UiCraftAuthorityDto,
    UpdateAuthorityCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    fadeInOnEnterAnimation,
    fadeOutOnLeaveAnimation
} from 'angular-animations';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { AuthorityGroupAddService } from 'libs/authorities/src/lib/authority-group-add/authority-group-add.service';
import {
    findNewJurisdictions,
    reduceToGroupWithOneAuthority
} from 'libs/authorities/src/lib/helpers';
import { AuthorityGroupEditService } from 'libs/authorities/src/lib/registers/interfaces';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RpaAuthorityEditService } from './authority-edit.service';

const GLOBAL_JURISDICTION_ID = -1;

@Component({
    selector: 'authority-edit',
    templateUrl: './authority-edit.component.html',
    providers: [
        AuthorityGroupAddService,
        {
            provide: AuthorityGroupEditService,
            useClass: RpaAuthorityEditService
        }
    ],
    animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()]
})
export class AuthorityEditComponent implements OnInit, OnChanges, OnDestroy {
    @Input() rpa: CraftDto;
    @Input() managingOrganisationId: number;
    @Output() done = new EventEmitter<void>();

    authorities: CraftAuthorityGroup[];
    availableAuthorities: CraftAuthorityGroup[];
    createdAuthorities: CraftAuthorityGroup[];
    availableJurisdictions: SimpleAirspaceJurisdictionDto[];
    currentJurisdictionId: number;
    resolve: { [key: string]: any };
    modalRef: BsModalRef;
    authorityService: AuthorityService<
        UiCraftAuthorityDto,
        CreateCraftAuthorityCommand,
        UpdateAuthorityCommand
    >;
    inUseJurisdictions: NameId[];

    visibleAuthorities: {
        [jurisdictionId: number]: CraftAuthorityGroup[];
    } = {};

    showArchived = false;
    numberOfArchived: number;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working = false;

    constructor(
        private rpaAuthorityService: RpaAuthorityService,
        private organisationService: OrganisationService,
        private modalService: BsModalService,
        private commonDialoguesService: CommonDialoguesService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private authorityGroupAddService: AuthorityGroupAddService<
            CraftAuthorityGroup
        >
    ) {
        this.currentJurisdictionId = null;
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.resolve = {};
        this.resolve.managingOrganisationId = this.managingOrganisationId;

        this.authorityGroupAddService.setup(this.managingOrganisationId);

        this.rpaAuthorityService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshAuthorities());
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('rpa' in changes) {
            this.refreshAuthorities();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAuthorities() {
        this.authorities = [];

        if (!this.rpa) {
            return;
        }

        combineLatest([
            this.rpaAuthorityService.findAuthorities(
                this.rpa.id,
                this.managingOrganisationId
            ),
            this.organisationService.findByIdForUser(
                this.rpa.organisationId,
                this.managingOrganisationId
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([authorities, organisation]) => {
                const sortedAuthorities = authorities.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                this.authorities = sortedAuthorities.filter(
                    a => a.authorities != null && a.authorities.length > 0
                );
                this.availableAuthorities = sortedAuthorities.filter(
                    authority =>
                        (authority.authorities.length === 0 ||
                            authority.hasMultipleInstances === true) &&
                        authority.availableActions.canCreate
                );
                this.createdAuthorities = sortedAuthorities.filter(
                    authority => authority.authorities.length > 0
                );
                this.inUseJurisdictions = this.createdAuthorities.reduce(
                    (acc, e) =>
                        findNewJurisdictions(
                            acc,
                            e.jurisdiction,
                            GLOBAL_JURISDICTION_ID
                        ),
                    [] as NameId[]
                );
                this.availableJurisdictions = organisation.activeJurisdictions;
                this.authorityGroupAddService.updateAuthorities(
                    this.availableAuthorities,
                    this.availableJurisdictions
                );
                this.refreshNumberOfArchived();

                this.updateVisibleAuthorities();
            })
            .add(this.workTracker.createTracker());
    }

    updateVisibleAuthorities() {
        this.visibleAuthorities = {};
        this.inUseJurisdictions.forEach(j => {
            const visibleInJurisdiction = this.createdAuthorities
                .filter(
                    a =>
                        this.isInSelectedJurisdiction(a) &&
                        this.isInJurisdiction(a, j.id)
                )
                .filter(
                    a =>
                        a.availableActions.canCreate ||
                        a.authorities?.length > 0
                )
                .filter(
                    a => this.showArchived || !this.areAllExpiredOrArchived(a)
                );
            if (this.visibleAuthorities == null) {
                this.visibleAuthorities = {
                    [j.id]: visibleInJurisdiction
                        .reduce(
                            (acc: CraftAuthorityGroup[], g) =>
                                acc.concat(
                                    reduceToGroupWithOneAuthority(
                                        g
                                    ) as CraftAuthorityGroup[]
                                ),
                            []
                        )
                        .filter(
                            g =>
                                this.showArchived ||
                                (g.authorities != null &&
                                g.authorities.length > 0 &&
                                g.authorities[0] != null
                                    ? !g.authorities[0].archived
                                    : true)
                        )
                };
            } else {
                this.visibleAuthorities[j.id] = visibleInJurisdiction
                    .reduce(
                        (acc: CraftAuthorityGroup[], g) =>
                            acc.concat(
                                reduceToGroupWithOneAuthority(
                                    g
                                ) as CraftAuthorityGroup[]
                            ),
                        []
                    )
                    .filter(
                        g =>
                            this.showArchived ||
                            (g.authorities != null &&
                            g.authorities.length > 0 &&
                            g.authorities[0] != null
                                ? !g.authorities[0].archived
                                : true)
                    );
            }
        });
    }

    areAllExpiredOrArchived(authorityGroup: CraftAuthorityGroup) {
        // Otherwise process expired or archived as normal.
        const expired = authorityGroup.authorities.filter(
            a => this.hasExpired(a) || a.archived
        );

        return expired.length === authorityGroup.authorities.length;
    }

    hasExpired(authority: CraftAuthorityDto) {
        return (
            authority.expiryDate != null &&
            moment(authority.expiryDate).isBefore(
                moment(moment().format('YYYY-MM-DD'))
            )
        );
    }

    isInSelectedJurisdiction(authority: CraftAuthorityGroup) {
        if (this.currentJurisdictionId == null) {
            return true;
        }
        if (this.currentJurisdictionId === GLOBAL_JURISDICTION_ID) {
            return authority.jurisdiction == null;
        }
        return (
            authority.jurisdiction != null &&
            authority.jurisdiction.id === this.currentJurisdictionId
        );
    }

    isInJurisdiction(authority: CraftAuthorityGroup, jurisdictionId: number) {
        if (jurisdictionId === GLOBAL_JURISDICTION_ID) {
            return authority.jurisdiction == null;
        }
        return (
            authority.jurisdiction != null &&
            authority.jurisdiction.id === jurisdictionId
        );
    }

    onSetJurisdictionId(jurisdictionId: number) {
        this.currentJurisdictionId = jurisdictionId;
    }

    onChangeShowArchived() {
        this.updateVisibleAuthorities();
    }

    refreshNumberOfArchived() {
        this.numberOfArchived =
            this.createdAuthorities?.filter(a =>
                this.areAllExpiredOrArchived(a)
            ).length ?? 0;
    }

    hasNoAuthorities(authority: CraftAuthorityGroup) {
        return (
            authority.authorities == null || authority.authorities.length === 0
        );
    }

    hasArchivedAuthorities(authorityGroup: CraftAuthorityGroup) {
        if (
            this.hasNoAuthorities(authorityGroup) ||
            authorityGroup.authorities.reduce(
                (acc, a) => a.archived || acc,
                false
            )
        ) {
            return true;
        }

        return false;
    }

    showAuthority(
        authorityType: AuthorityTypeDto,
        authority: CraftAuthorityDto
    ) {
        const modal = this.authorityDialoguesService.showAuthorityDetails(
            this.managingOrganisationId,
            authorityType,
            authority,
            '/icons/icon-rpa.png',
            true,
            false,
            null
        );

        modal.content.onEditAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.editAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.onDeleteAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.deleteAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.archiveAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.archiveAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
        modal.content.unarchiveAuthority
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(payload => {
                this.unarchiveAuthority(
                    <AuthorityTypeDto>payload.authorityType,
                    payload.authority
                );
            });
    }

    editAuthority(authorityType: AuthorityTypeDto, authority: any) {
        this.authorityDialoguesService.showAuthorityEdit(
            this.rpa.id,
            authorityType,
            authority,
            this.managingOrganisationId,
            this.rpaAuthorityService
        );
    }

    archiveAuthority(authorityType: AuthorityTypeDto, authority: any) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Archive Authority',
                `Do you wish to archive this ${authorityType.name}?`,
                'Archive',
                () =>
                    this.rpaAuthorityService
                        .archiveAuthority(
                            authority.id,
                            this.managingOrganisationId
                        )
                        .toPromise()
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    unarchiveAuthority(authorityType: AuthorityTypeDto, authority: any) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Unarchive Authority',
                `Do you wish to unarchive this ${authorityType.name}?`,
                'Unarchive',
                () =>
                    this.rpaAuthorityService
                        .unarchiveAuthority(
                            authority.id,
                            this.managingOrganisationId
                        )
                        .toPromise()
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    deleteAuthority(
        authorityType: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Authority',
                `Do you wish to delete this ${authorityType.name}?`,
                'Delete',
                () =>
                    this.rpaAuthorityService.deleteAuthority(
                        authority.id,
                        this.managingOrganisationId
                    )
            )
            .then(() => this.refreshAuthorities(), DO_NOTHING);
    }

    createAuthority(
        template: TemplateRef<any>,
        authorityType: AuthorityTypeDto
    ) {
        this.resolve.endorsedEntityId = this.rpa.id;
        this.resolve.authorityType = authorityType;
        this.resolve.authorityService = this.rpaAuthorityService;
        this.authorityService = this.resolve.authorityService;
        this.authorityService
            .newAuthority(
                this.rpa.id,
                authorityType.id,
                this.managingOrganisationId
            )
            .subscribe(authority => {
                this.resolve.authority = authority;
                this.modalRef = this.modalService.show(template, {
                    class: 'modal-lg'
                });
            });
    }

    updatedAuthority(updated: any) {
        this.refreshAuthorities();
        this.modalRef.hide();
    }

    showList() {
        this.done.emit(null);
    }
}
