import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AirspaceJurisdictionDto,
    CreateOrganisationByUserCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    JurisdictionService,
    OrganisationService,
    PersonDto,
    SimpleOrganisationDto,
    OnboardingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject, of } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { OnboardingDialoguesService } from '../../onboarding-dialogues.service';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'organisation-details',
    templateUrl: './organisation-details.component.html',
    styleUrls: ['./organisation-details.component.scss'],
    viewProviders: []
})
export class OrganisationDetailsComponent implements OnInit {
    formGroup: FormGroup;
    curentUser: PersonDto;
    organisation: SimpleOrganisationDto;
    closeResult: string;
    imagePreview: string | ArrayBuffer;
    selectedFile: File;
    name: string;
    jurisdictions: AirspaceJurisdictionDto[];
    organisationId: number;
    hasCreatedDummyData: boolean = false;

    private ngUnsubscribe$: Subject<void> = new Subject();
    private workTracker = new WorkTracker();
    public working = false;

    constructor(
        private el: ElementRef,
        private obsds: OnboardingData,
        private jurisdictionService: JurisdictionService,
        private organisationService: OrganisationService,
        private onboardingService: OnboardingService,
        private logging: FlyFreelyLoggingService,
        private onboardingDialoguesService: OnboardingDialoguesService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        const businessName = this.obsds.getBusinessName() ?? '';

        this.formGroup = new FormGroup({
            profileImage: new FormControl(undefined, [Validators.required]),
            accountDetails: new FormGroup({
                businessName: new FormControl(businessName, [
                    Validators.required,
                    Validators.minLength(2)
                ])
                // emailOfOrganisationOwner: new FormControl('', [
                //     Validators.required,
                //     emailValidator
                // ]),
                // assignMeAsOwner: new FormControl(false),
                // organisationAddress: new FormControl(''),
                // setAsMapStartPoint: new FormControl(''),
                // companyARN: new FormControl('')
            }),
            jurisdictionSetup: new FormGroup({
                jurisdiction: new FormControl(undefined, [Validators.required])
            })
        });

        this.organisationService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.refreshOrganisationDetails();
            });

        this.refreshOrganisationDetails();

        this.jurisdictionService
            .findJurisdictions()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                jurisdictions =>
                    (this.jurisdictions = jurisdictions.sort((a, b) =>
                        a.name.localeCompare(b.name)
                    ))
            );

        this.imagePreview = 'assets/image/img1.jpg';

        this.hasCreatedDummyData = this.obsds.checkCommercialDummyData();
        if (this.hasCreatedDummyData) {
            (<FormGroup>(
                this.formGroup.controls.jurisdictionSetup
            )).controls.prefillDummyData.disable();
            (<FormGroup>(
                this.formGroup.controls.jurisdictionSetup
            )).controls.prefillDummyData.setValue(true);
        }

        this.onFormValueChange();
    }

    onFileUpload(event: any) {
        this.selectedFile = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            this.imagePreview = reader.result;
        };

        reader.readAsDataURL(this.selectedFile);
        const organisationDetailsPhoto = this.el.nativeElement.querySelector(
            '.organisationdetails_framethree'
        );
        organisationDetailsPhoto.classList.remove('notfinished');
    }

    loadLogoFromURL(imageUrl: string) {
        return new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                this.formGroup.controls.profileImage.setValue(xhr.response);
                resolve();
            };
            xhr.onerror = () => reject();
            xhr.open('GET', imageUrl);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    loadLogoFromFile(organisationid: number) {
        return this.organisationService
            .getLogo(organisationid)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (logo: Blob) => {
                    if (logo != null) {
                        this.formGroup.controls.profileImage.setValue(logo);
                    } else {
                        return this.loadLogoFromURL(
                            'images/onboarding/FF-Onboarding-Commercial-UploadLogo.png'
                        );
                    }
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error fetching organisation logo: ${error.message}`
                    );
                    return this.loadLogoFromURL(
                        'images/onboarding/FF-Onboarding-Commercial-UploadLogo.png'
                    ).then(() =>
                        this.formGroup.controls.profileImage.markAsUntouched()
                    );
                }
            });
    }

    selectImg(selectedElm: any) {
        const imageUrl = selectedElm.attributes.src.value;
        const result = this.loadLogoFromURL(imageUrl);
    }

    showHelp(text: string, title: string) {
        this.onboardingDialoguesService.showHelp(text, title);
    }

    refreshOrganisationDetails() {
        if (!this.obsds.organisationId) {
            this.loadLogoFromURL(
                'images/onboarding/FF-Onboarding-Commercial-UploadLogo.png'
            );
            return;
        }
        this.organisationId = this.obsds.organisationId;
        const doneWorking = this.workTracker.createTracker();
        this.organisationService
            .findById(this.obsds.organisationId, this.obsds.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                org => {
                    this.loadLogoFromFile(org.id).add(() => {
                        this.formGroup.controls.profileImage.markAsUntouched();
                    });
                    this.formGroup.patchValue({
                        accountDetails: {
                            businessName: org.name
                        },
                        jurisdictionSetup: {
                            jurisdiction: org.activeJurisdictions[0]
                        }
                    });
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing organisation details: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    createOrganisation() {
        const doneWorking = this.workTracker.createTracker();
        const { profileImage, accountDetails, jurisdictionSetup } =
            this.formGroup.value;

        const cmd: CreateOrganisationByUserCommand = {
            name: accountDetails.businessName,
            activeJurisdictionIds: [jurisdictionSetup.jurisdiction.id]
        };
        this.organisationService
            .createCommercialOrganisation(cmd)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(results => {
                    this.organisationId = results.id;
                    return this.organisationService.uploadLogo(
                        profileImage,
                        this.organisationId
                    );
                }),
                mergeMap(() => {
                    return this.onboardingService
                        .createDummyEquipment(this.organisationId)
                        .pipe(
                            catchError(error => of(undefined)),
                            tap(() => this.obsds.createdCommercialDummyData())
                        );
                })
            )
            .subscribe({
                next: () => {
                    doneWorking();
                    this.obsds.organisationDetailsCompleted(
                        this.organisationId,
                        jurisdictionSetup.jurisdiction.id
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error creating commercial organisation: ${error.message}`
                    );
                    doneWorking();
                }
            });
    }

    updateOrganisation() {
        const doneWorking = this.workTracker.createTracker();
        const { profileImage, accountDetails, jurisdictionSetup } =
            this.formGroup.value;

        this.organisationService
            .updateJurisdictions(this.organisationId, [
                jurisdictionSetup.jurisdiction.id
            ])
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(() => {
                    if (!this.formGroup.controls.profileImage.untouched) {
                        return this.organisationService.uploadLogo(
                            profileImage,
                            this.organisationId
                        );
                    } else {
                        return of(null);
                    }
                }),
                mergeMap(() =>
                    this.organisationService.updateOrganisationName(
                        this.organisationId,
                        { name: accountDetails.businessName }
                    )
                ),
                mergeMap(() => {
                    if (!this.obsds.hasCreatedCommercialDummyData) {
                        return this.onboardingService
                            .createDummyEquipment(this.organisationId)
                            .pipe(
                                catchError(error => of(undefined)),
                                tap(() =>
                                    this.obsds.createdCommercialDummyData()
                                )
                            );
                    }
                    return of(null);
                })
            )
            .subscribe({
                next: () => {
                    doneWorking();
                    this.obsds.organisationDetailsCompleted(
                        this.organisationId,
                        jurisdictionSetup.jurisdiction.id
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while updating organisation: ${error.message}`
                    );
                    doneWorking();
                }
            });
    }

    nextScreen() {
        if (this.obsds.organisationId) {
            this.updateOrganisation();
        } else {
            this.createOrganisation();
        }
    }

    previousScreen() {
        this.obsds.backToPersonalSetupCompletion();
    }

    onFormValueChange(): void {
        this.formGroup.valueChanges.subscribe(val => {
            const organisationDetails = this.el.nativeElement.querySelector(
                '.organisationdetails_frameone'
            );
            const organisationJurisdiction =
                this.el.nativeElement.querySelector(
                    '.organisationdetails_frametwo'
                );

            if (this.formGroup.get('accountDetails').valid) {
                organisationDetails.classList.remove('notfinished');
            } else {
                organisationDetails.classList.add('notfinished');
            }

            if (this.formGroup.get('jurisdictionSetup').valid) {
                organisationJurisdiction.classList.remove('notfinished');
            } else {
                organisationJurisdiction.classList.add('notfinished');
            }
        });
    }

    get jurisdictionSetup() {
        return this.formGroup.get('jurisdictionSetup') as FormGroup;
    }

    get accountDetails() {
        return this.formGroup.get('accountDetails') as FormGroup;
    }
}
