import { Injectable } from '@angular/core';
import {
    ActiveResourceSchematicDto,
    BatteryDto,
    CompleteMaintenanceLogCommand,
    CraftDto,
    CraftService,
    EquipmentDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InvalidOperation,
    MaintenanceActivityDto,
    MaintenanceLogDto,
    MaintenanceScheduleAssignmentDto,
    MaintenanceService,
    MaintenanceTaskTypeDto,
    NotFound,
    OperationForbidden,
    PersonDto,
    PersonRolesDto,
    PersonService,
    PersonsOrganisationDto,
    ReassignMaintenanceActivityCommand,
    Resource,
    ResourceComponentInServiceTime,
    ResourceTypeComponentsService,
    SimpleMaintenanceScheduleDto,
    TotalInServiceTime,
    UpdateSubmittedMaintenanceLog,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    combineLatest,
    forkJoin,
    throwError
} from 'rxjs';
import {
    catchError,
    map,
    shareReplay,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';

function sortComponents(
    a: ResourceComponentInServiceTime,
    b: ResourceComponentInServiceTime
): number {
    const componentTypeOrder = a.componentType.name.localeCompare(
        b.componentType.name
    );
    if (componentTypeOrder !== 0) {
        return componentTypeOrder;
    }
    return a.designation.localeCompare(b.designation);
}
export interface DisplayableMaintenanceActivity extends MaintenanceActivityDto {
    number: number;
}

export interface DisplayableMaintenanceLogDto extends MaintenanceLogDto {
    activities: DisplayableMaintenanceActivity[];
}

export interface DisplayableMaintenanceTask {
    required: boolean;
    completed: boolean;
    componentId: number;
    componentName: string;
    notes: string;
    taskTypeId: number;
    taskTypeName: string;
    firmwareVersion: string;
    serialNumber: string;
}

export interface UpdateMaintenanceTask {
    componentId: number;
    notes: string;
    taskTypeId: number;
}

export type MaintenanceLogChanges = 'INITIAL' | 'SAVE' | 'UPDATE_SUBMITTED_LOG';

@Injectable()
export class MaintenanceRequestService {
    private resourceSubject = new ReplaySubject<Resource>();
    private resourceCategorySubject = new ReplaySubject<string>();
    private resourceTypeIdSubject = new ReplaySubject<number>();
    private taskTypesSubject = new ReplaySubject<MaintenanceTaskTypeDto[]>();
    private resourceSchematicSubject =
        new ReplaySubject<ActiveResourceSchematicDto>();
    private maintenanceLogSubject = new ReplaySubject<{
        log: DisplayableMaintenanceLogDto;
        changes: MaintenanceLogChanges;
    }>();
    private addMaintenanceTaskSubject = new Subject<void>();
    private addScheduledMaintenanceTaskSubject = new Subject<void>();
    private maintenanceScheduleSubject =
        new ReplaySubject<SimpleMaintenanceScheduleDto>(1);
    private totalTimeInServiceSubject = new ReplaySubject<TotalInServiceTime>(
        1
    );
    private organisationIdSource = new ReplaySubject<number>(1);

    private routingErrorSubject = new BehaviorSubject<
        'ERROR' | 'NOT_FOUND' | 'NO_ACCESS' | 'NONE'
    >('NONE');

    routingError$ = this.routingErrorSubject.asObservable();

    public resource$ = this.resourceSubject.asObservable();
    public resourceCategory$ = this.resourceCategorySubject.asObservable();
    public resourceTypeId$ = this.resourceTypeIdSubject.asObservable();
    public taskTypes$ = this.taskTypesSubject.asObservable();
    public resourceSchematic$ = this.resourceSchematicSubject.asObservable();
    public maintenanceLog$ = this.maintenanceLogSubject.asObservable();
    public addMaintenanceTask$ = this.addMaintenanceTaskSubject.asObservable();
    public addScheduledMaintenanceTask$ =
        this.addScheduledMaintenanceTaskSubject.asObservable();
    public maintenanceSchedule$ =
        this.maintenanceScheduleSubject.asObservable();
    public totalTimeInService$ = this.totalTimeInServiceSubject.asObservable();

    public maintenancePersonCandidates$ = this.organisationIdSource.pipe(
        switchMap(organisationId =>
            this.personService.findPersonnelWithPermission(
                organisationId,
                PersonsOrganisationDto.Permissions.MAINTENANCE_PERFORM
            )
        ),
        catchError(() =>
            throwError(() => ({
                message: 'Failed to load maintenance people'
            }))
        ),
        shareReplay()
    );

    private ngUnsubscribe$ = new Subject<void>();
    working = false;
    public workTracker = new WorkTracker();

    private maintenanceLogId: number;

    constructor(
        private resourceTypeComponentsService: ResourceTypeComponentsService,
        private maintenanceService: MaintenanceService,
        private craftService: CraftService,
        private personService: PersonService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
            });
    }

    ngOnDestroy() {
        this.resourceSubject.complete();
        this.resourceCategorySubject.complete();
        this.resourceTypeIdSubject.complete();
        this.taskTypesSubject.complete();
        this.resourceSchematicSubject.complete();
        this.maintenanceLogSubject.complete();
        this.addMaintenanceTaskSubject.complete();
        this.addScheduledMaintenanceTaskSubject.complete();
        this.maintenanceScheduleSubject.complete();
        this.totalTimeInServiceSubject.complete();
        this.organisationIdSource.complete();
        this.routingErrorSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Initialise the service to have the data ready for the given organisation.
     * @param organisationId the organisation to initialise the service for
     */
    setup(organisationId: number) {
        this.organisationIdSource.next(organisationId);
    }

    private setupMaintenanceLog(
        log: MaintenanceLogDto,
        changes: MaintenanceLogChanges
    ) {
        const maintenanceLog: DisplayableMaintenanceLogDto = {
            ...log,
            activities: log.activities.map((a, ix) => ({
                ...a,
                number: ix + 1
            }))
        };

        if (maintenanceLog.activities.length === 0) {
            maintenanceLog.activities.push({
                status: MaintenanceActivityDto.Status.DRAFT,
                number: 1,
                tasks: [],
                flightTests: [],
                availableActions: {
                    canAssignActivity: true,
                    canCancelActivity: false,
                    canFinaliseActivity: false,
                    canPerformActivity: false,
                    canSubmitActivity: false,
                    canUnfinaliseActivity: false
                }
            });
        }
        this.maintenanceLogSubject.next({ log: maintenanceLog, changes });
    }

    /**
     * Update the stored maintenance log from an API action that happened in the UI
     * @param log the updated maintenance log
     */
    updateMaintenanceLog(log: MaintenanceLogDto) {
        this.setupMaintenanceLog(log, 'SAVE');
    }

    /**
     * Refresh the maintenance log from the API.
     * @param maintenanceLogId the maintenance log ID to fetch from the API
     */
    refreshMaintenanceLog(maintenanceLogId: number) {
        if (maintenanceLogId == null) {
            return;
        }
        this.maintenanceLogId = maintenanceLogId;

        this.maintenanceService
            .findMaintenanceLogById(maintenanceLogId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: (log: MaintenanceLogDto) =>
                    this.setupMaintenanceLog(log, 'SAVE'),
                error: (error: FlyFreelyError) => {
                    if (
                        error instanceof NotFound ||
                        error instanceof InvalidOperation
                    ) {
                        this.routingErrorSubject.next('NOT_FOUND');
                    } else if (error instanceof OperationForbidden) {
                        this.routingErrorSubject.next('NO_ACCESS');
                    } else {
                        this.logging.error(
                            error,
                            `Error while loading maintenance log: ${error.message}`
                        );
                        this.routingErrorSubject.next('ERROR');
                    }
                }
            })
            .add(this.workTracker.createTracker());
    }

    updateResource(
        resource: Resource,
        category: string,
        managingOrganisationId: number
    ) {
        this.resourceSubject.next(resource);
        this.resourceCategorySubject.next(category);
        this.findTaskTypes(category);
        this.findResourceSchematic(resource, category);
        this.findAssignedMaintenanceSchedule(
            resource,
            category,
            managingOrganisationId
        );
    }

    updateMaintenanceSchedule(
        assignedMaintenanceSchedule: MaintenanceScheduleAssignmentDto
    ) {
        if (assignedMaintenanceSchedule == null) {
            return;
        }
        if (assignedMaintenanceSchedule.overrideMaintenanceSchedule != null) {
            this.maintenanceScheduleSubject.next(
                assignedMaintenanceSchedule.overrideMaintenanceSchedule
            );
        } else if (
            assignedMaintenanceSchedule.defaultMaintenanceSchedule != null
        ) {
            this.maintenanceScheduleSubject.next(
                assignedMaintenanceSchedule.defaultMaintenanceSchedule
            );
        }
    }

    addMaintenanceTask() {
        this.addMaintenanceTaskSubject.next();
    }

    addScheduledMaintenanceTask() {
        this.addScheduledMaintenanceTaskSubject.next();
    }

    findAssignedMaintenanceSchedule(
        resource: Resource,
        category: string,
        managingOrganisationId: number
    ) {
        if (category === MaintenanceLogDto.ResourceCategory.CRAFT) {
            forkJoin([
                this.craftService.findById(resource.id, managingOrganisationId),
                this.craftService.findTotalTimeInService(
                    resource.id,
                    managingOrganisationId
                )
            ])
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(([craft, total]) => {
                    this.updateMaintenanceSchedule(
                        craft.assignedMaintenanceSchedule
                    );
                    this.totalTimeInServiceSubject.next({
                        ...total,
                        components: [...total.components].sort(sortComponents)
                    });
                })
                .add(this.workTracker.createTracker());
        }
    }

    findTaskTypes(resourceCategory: string) {
        this.resourceTypeComponentsService
            .findMaintenanceTaskTypes(resourceCategory)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.taskTypesSubject.next(result);
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching maintenance task types: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    findResourceSchematic(resource: Resource, resourceCategory: string) {
        if (!resource) {
            return;
        }
        let typeId: number;
        if (resourceCategory === 'CRAFT') {
            const rpa: CraftDto = <CraftDto>resource;
            if (!rpa.rpaTypeId) {
                return;
            }
            typeId = rpa.rpaTypeId;
        } else if (resourceCategory === 'BATTERY') {
            const battery: BatteryDto = <BatteryDto>resource;
            typeId = battery.batteryTypeId;
        } else if (resourceCategory === 'EQUIPMENT') {
            const equipment: EquipmentDto = <EquipmentDto>resource;
            typeId = equipment.equipmentType.id;
        } else {
            return;
        }

        if (typeId != null) {
            this.resourceTypeIdSubject.next(typeId);
        }

        this.resourceTypeComponentsService
            .findResourceSchematicComponents(resourceCategory, typeId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.resourceSchematicSubject.next(result);
                },
                error: (error: FlyFreelyError) => {
                    if (error instanceof NotFound) {
                        this.resourceSchematicSubject.next(null);
                        return;
                    }
                    this.logging.error(
                        error,
                        `Error fetching maintenance component types: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    attachmentHandler(maintenanceActivityId: number) {
        if (maintenanceActivityId == null) {
            throw new Error('Invalid ID of null');
        }

        if (this.maintenanceLogId == null) {
            throw new Error('No log available');
        }

        return this.maintenanceService.attachmentHandler(
            this.maintenanceLogId,
            maintenanceActivityId
        );
    }

    reassignMaintenanceActivity(
        activityId: number,
        maintainerId: number,
        maintenanceOrganisation: string
    ) {
        const cmd: ReassignMaintenanceActivityCommand = {
            maintainerId,
            maintenanceOrganisation
        };
        return this.maintenanceService
            .reassignMaintenanceActivity(this.maintenanceLogId, activityId, cmd)
            .pipe(takeUntil(this.ngUnsubscribe$));
    }

    finaliseMaintenanceLog(command: CompleteMaintenanceLogCommand) {
        return this.maintenanceService
            .finaliseMaintenanceLog(this.maintenanceLogId, command)
            .pipe(tap(response => this.setupMaintenanceLog(response, 'SAVE')));
    }

    unfinaliseMaintenanceLog() {
        return this.maintenanceService
            .unfinaliseMaintenanceLog(this.maintenanceLogId)
            .pipe(tap(response => this.setupMaintenanceLog(response, 'SAVE')));
    }

    unfinaliseMaintenanceActivity(activityId: number) {
        return this.maintenanceService.unfinaliseMaintenanceActivity(
            this.maintenanceLogId,
            activityId
        );
    }

    updateMaintenanceIdentifierAndWorkInstructionsUrl(
        command: UpdateSubmittedMaintenanceLog
    ) {
        return this.maintenanceService
            .updateMaintenanceIdentifierAndWorkInstructionsUrl(
                this.maintenanceLogId,
                command
            )
            .pipe(
                tap(response => {
                    this.setupMaintenanceLog(response, 'UPDATE_SUBMITTED_LOG');
                })
            );
    }
}
