<ng-container screenAnalytics="rpa-authority-edit">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img [src]="IMG_URL + '/icons/icon-rpa.png'" />
        </div>
        <h3 class="modal-title">{{ authorityType.name }} Details</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="documentForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-body">
            <div class="row top-buffer">
                <div class="col-xs-12 form-group">
                    <label>Start Date</label>
                    <field-validation-indicator
                        class="pull-right"
                        [valid]="documentForm.valid"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            id="datepicker"
                            class="form-control"
                            #datepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="startDate"
                        />
                        <span
                            class="input-group-addon btn-default"
                            (click)="datepicker.toggle()"
                            [attr.aria-expanded]="datepicker.isOpen"
                        >
                            <i class="glyphicon glyphicon-calendar"></i>
                        </span>
                    </div>
                    <!--                <validation-messages form="documentForm" field="startDate"></validation-messages>-->
                </div>
            </div>
            <enhanced-help componentName="startDate" [compact]="true">
            </enhanced-help>

            <div class="row top-buffer" *ngIf="authorityType.hasExpiry">
                <div class="col-xs-12 form-group">
                    <label>Expiry Date</label>
                    <field-validation-indicator
                        class="pull-right"
                        [valid]="documentForm.valid"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            class="form-control"
                            #datepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="expiryDate"
                        />
                        <span
                            class="input-group-addon btn-default"
                            (click)="datepicker.toggle()"
                            [attr.aria-expanded]="datepicker.isOpen"
                        >
                            <i class="glyphicon glyphicon-calendar"></i>
                        </span>
                    </div>
                    <!--                <validation-messages form="documentForm" field="expiryDate" always-validate="true"></validation-messages>-->
                </div>
            </div>
            <enhanced-help
                *ngIf="authorityType.hasExpiry"
                componentName="expiryDate"
                [compact]="true"
            >
            </enhanced-help>

            <div class="row top-buffer" *ngIf="authorityType.hasIdentifier">
                <div class="col-xs-12 form-group">
                    <label>{{
                        authorityType.identifierLabel || 'Identifier'
                    }}</label>
                    <input class="form-control" formControlName="regNumber" />
                </div>
            </div>
            <enhanced-help
                *ngIf="authorityType.hasIdentifier"
                componentName="regNumber"
                [compact]="true"
            >
            </enhanced-help>

            <div class="row top-buffer" *ngIf="authority">
                <div class="col-xs-12 form-group">
                    <label>Attachments</label>
                    <attachment-list
                        [attachmentsHandler]="attachmentsHandler"
                        [allowUploads]="true"
                        [allowEdit]="true"
                        [allowDelete]="true"
                        [allowAcknowledgements]="false"
                        [requestingOrganisationId]="managingOrganisationId"
                        permissions
                    ></attachment-list>
                </div>
            </div>
            <loading-indicator [isLoading]="working"></loading-indicator>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-primary"
                type="submit"
                [disabled]="documentForm.invalid || working"
            >
                Save
            </button>
        </div>
    </form>
</ng-container>
