import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'equipmentStatusBlock' })
export class EquipmentStatusBlockPipe implements PipeTransform {
    transform(status: EquipmentDto.Status) {
        switch (status) {
            case EquipmentDto.Status.SERVICEABLE:
                return 'active';
            case EquipmentDto.Status.UNSERVICEABLE:
                return 'inactive';
            case EquipmentDto.Status.RETIRED:
                return 'disabled';
            case EquipmentDto.Status.UNDER_MAINTENANCE:
                return 'pending';
            default:
                return `disabled`;
        }
    }
}
