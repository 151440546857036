import { Component, OnInit } from '@angular/core';
import {
    fadeInUpOnEnterAnimation,
    fadeOutDownOnLeaveAnimation
} from 'angular-animations';

@Component({
    selector: 'component-bubble',
    templateUrl: './component-bubble.component.html',
    styleUrls: ['./component-bubble.component.scss'],
    animations: [
        fadeInUpOnEnterAnimation({ duration: 400 }),
        fadeOutDownOnLeaveAnimation({ duration: 400 })
    ]
})
export class ComponentBubbleComponent implements OnInit {
    show = false;

    constructor() {}

    ngOnInit(): void {}

    showHide() {
        this.show = !this.show;
    }
}
