/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.45-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface CreateFlightLogCollectionSourceCommand { 
    organisationId: number;
    /**
     * The type of collection source
     */
    type: CreateFlightLogCollectionSourceCommand.Type;
    /**
     * The frequency at which files are checked
     */
    collectionFrequency: CreateFlightLogCollectionSourceCommand.CollectionFrequency;
    /**
     * The username, if any, associated with this collection source
     */
    username: string;
    /**
     * The password for the account the files are being collected from
     */
    password: string;
    /**
     * Is this a personal account?
     */
    personalAccount?: boolean;
}
export namespace CreateFlightLogCollectionSourceCommand {
    export type Type = 'DJI_CLOUD' | 'DRONE_HARMONY' | 'DRONE_DEPLOY' | 'SKYDIO';
    export const Type = {
        DJI_CLOUD: 'DJI_CLOUD' as Type,
        DRONE_HARMONY: 'DRONE_HARMONY' as Type,
        DRONE_DEPLOY: 'DRONE_DEPLOY' as Type,
        SKYDIO: 'SKYDIO' as Type
    };
    export type CollectionFrequency = 'NEVER' | 'FIVE_MINUTES' | 'HOURLY' | 'DAILY';
    export const CollectionFrequency = {
        NEVER: 'NEVER' as CollectionFrequency,
        FIVE_MINUTES: 'FIVE_MINUTES' as CollectionFrequency,
        HOURLY: 'HOURLY' as CollectionFrequency,
        DAILY: 'DAILY' as CollectionFrequency
    };
}



