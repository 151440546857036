import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonDialoguesService } from './common-dialogues.service';
import { ConfirmationDialogue } from './confirmation-dialogue/confirmation-dialogue.component';
import { FormlyDialogueController } from './formly-dialogue/formly-dialogue.component';
import { FormlyView } from './formly-view.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        LoadingIndicatorModule,
        ModalModule.forChild(),
        FormlyModule,
        FormlyBootstrapModule,
        SharedUiModule,
        AnalyticsModule,
        EnhancedHelpModule
    ],
    providers: [CommonDialoguesService],
    bootstrap: [],
    declarations: [ConfirmationDialogue, FormlyDialogueController, FormlyView],
    exports: [ConfirmationDialogue, FormlyDialogueController]
})
export class CommonDialoguesModule {}
