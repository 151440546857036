import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { PersonDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'profile-flight-history',
    templateUrl: './profile-flight-history.component.html'
})
export class FlightHistory {
    @Input() currentUser: PersonDto;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        this.changeDetector.detectChanges();
    }
}
