import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CreateOtherReferralSourceCommand,
    CreatePromoterSourceCommand,
    ReferralDto,
    ReferralReport,
    ReferralSourceSummary,
    UpdateInviteReferralSourceCommand,
    UpdateOtherReferralSourceCommand,
    UpdatePromoterReferralSourceCommand
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { httpParamSerializer } from '../service.helpers';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';

@Injectable({
    providedIn: 'root'
})
export class AdminReferralService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(criteria: { promoterId?: number; type?: ReferralSourceSummary.Type }) {
        return this.http.get<ReferralSourceSummary[]>(
            `${this.baseUrl}/adminapi/referrals/sources`,
            { params: httpParamSerializer(criteria) }
        );
    }

    findById(referralSourceId: number) {
        return this.http.get<ReferralSourceSummary>(
            `${this.baseUrl}/adminapi/referrals/sources/${referralSourceId}`
        );
    }

    createPromoterSourceForOrganisation(command: CreatePromoterSourceCommand) {
        return this.http
            .post<ReferralSourceSummary>(
                `${this.baseUrl}/adminapi/referrals/sources/promoter`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    createOtherReferralSource(command: CreateOtherReferralSourceCommand) {
        return this.http
            .post<ReferralSourceSummary>(
                `${this.baseUrl}/adminapi/referrals/sources/other`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateOtherReferralSource(
        referralSourceId: number,
        command: UpdateOtherReferralSourceCommand
    ) {
        return this.http
            .put<ReferralSourceSummary>(
                `${this.baseUrl}/adminapi/referrals/sources/other/${referralSourceId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateInviteReferralSource(
        referralSourceId: number,
        command: UpdateInviteReferralSourceCommand
    ) {
        return this.http
            .put<ReferralSourceSummary>(
                `${this.baseUrl}/adminapi/referrals/sources/invite/${referralSourceId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updatePromoterReferralSource(
        referralSourceId: number,
        command: UpdatePromoterReferralSourceCommand
    ) {
        return this.http
            .put<ReferralSourceSummary>(
                `${this.baseUrl}/adminapi/referrals/sources/promoter/${referralSourceId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findReportForSource(referralSourceId: number) {
        return this.http.get<ReferralReport[]>(
            `${this.baseUrl}/adminapi/referrals/sources/${referralSourceId}/report`
        );
    }

    findReferralReportsByDateRange(endTime: string, startTime: string) {
        return this.http.get<ReferralReport[]>(
            `${this.baseUrl}/adminapi/referrals/report`,
            { params: httpParamSerializer({ startTime, endTime }) }
        );
    }

    assignReferralSource(personId: number, referralSourceId: number) {
        return this.http.put<ReferralDto>(
            `${this.baseUrl}/adminapi/personnel/${personId}/referral`,
            { referralSourceId }
        );
    }
}
