import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    BatteryService,
    BatteryTypeService,
    CreateBatteryCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UserService,
    WorkTracker,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { preHide } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import { ModelItem } from 'libs/rpa/src/lib/details/rpa-edit/rpa-edit.component';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BatteryUploadService } from '../battery-upload.service';
import { batteryFields } from '../fields';

@Component({
    selector: 'battery-add-dialogue',
    templateUrl: './battery-add-dialogue.component.html',
    providers: [BatteryUploadService]
})
export class BatteryAddDialogue implements OnInit, OnDestroy {
    @Input() organisationId: number;
    @Input() batterySerialNumber: string;
    @Input() serialNumberOrigin: string;

    validationFields: FormlyFieldConfig[] = batteryFields;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    batteryForm: FormGroup;
    canRender: boolean;
    batteryTypeItems: ModelItem[];

    canUseNfc: boolean = false;

    constructor(
        private batteryTypeService: BatteryTypeService,
        private batteryService: BatteryService,
        private commonDialoguesService: CommonDialoguesService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService,
        private modal: BsModalRef<BatteryAddDialogue>,
        modalOptions: ModalOptions,
        private batteryUploadService: BatteryUploadService
    ) {
        modalOptions.closeInterceptor = () =>
            preHide(this.batteryForm, this.commonDialoguesService);

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.batteryTypeItems = [];

        this.refreshBatteryTypes();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshBatteryTypes() {
        this.batteryTypeService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteryTypes => {
                    this.canRender = true;

                    this.batteryTypeItems = batteryTypes.map(item => ({
                        id: item.id,
                        name: `${item.make} ${item.model}`
                    }));

                    this.initForm();
                },
                error: error =>
                    this.logging.error(
                        error,
                        `Error while fetching battery types: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    initForm() {
        this.batteryForm = new FormGroup({
            isDummy: new FormControl(false),
            name: new FormControl(undefined),
            purchaseDate: new FormControl(undefined, [Validators.required]),
            manufacturerSerialNumber: new FormControl(undefined),
            nfcUid: new FormControl(undefined),
            batteryTypeId: new FormControl(undefined, [Validators.required]),
            makeBatterySet: new FormControl(true, [Validators.required]),
            initialFlightTime: new FormControl(0, [Validators.required]),
            initialCycles: new FormControl(0, [Validators.required])
        });
        if (this.batterySerialNumber != null) {
            // If there is a serial number supplied (eg. from a battery on a flight log) then lock that as the serial number value
            this.batteryForm.controls.manufacturerSerialNumber.patchValue(
                this.batterySerialNumber
            );
            this.batteryForm.controls.manufacturerSerialNumber.disable();
            this.batteryForm.markAsPristine();
            this.batteryForm.updateValueAndValidity();
        }
    }

    invalidFileSelection(files: File[]) {
        if (files == null) {
            return;
        }
        this.logging.error(null, 'This file type is not supported');
    }

    bulkUpload() {
        this.batteryUploadService.showBulkUpload(this.organisationId);
        this.batteryUploadService.doneImporting$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.modal.hide());
    }

    save(addAnother: boolean) {
        const payload: CreateBatteryCommand = {
            name: this.name.value,
            organisationId: this.organisationId,
            manufacturerSerialNumber: this.manufacturerSerialNumber.value,
            nfcUid: this.nfcUid.value,
            purchaseDate: toLocalDate(this.purchaseDate.value),
            batteryTypeId: parseInt(this.batteryTypeId.value, 10),
            isDummy: this.isDummy.value || false,
            makeBatterySet: this.makeBatterySet.value || false,
            initialCycles: this.initialCycles.value
                ? this.initialCycles.value
                : 0,
            initialFlightTime: this.initialFlightTime.value
                ? this.initialFlightTime.value
                : 0,
            initialComponentList: []
        };
        this.batteryService
            .createBattery(payload)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                (battery: any) => {
                    const extraMessage = this.makeBatterySet.value
                        ? ' and battery set created'
                        : '';
                    this.logging.success(`Battery created ${extraMessage}`);
                    if (addAnother) {
                        this.batteryForm.patchValue({
                            name: null,
                            manufacturerSerialNumber: null
                        });
                    } else {
                        this.batteryForm.markAsPristine();
                        this.modal.hide();
                    }
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while creating battery: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    close() {
        this.modal.hide();
    }

    get isDummy() {
        return this.batteryForm.get('isDummy');
    }

    get batteryTypeId() {
        return this.batteryForm.get('batteryTypeId');
    }

    get name() {
        return this.batteryForm.get('name');
    }

    get purchaseDate() {
        return this.batteryForm.get('purchaseDate');
    }

    get manufacturerSerialNumber() {
        return this.batteryForm.get('manufacturerSerialNumber');
    }

    get nfcUid() {
        return this.batteryForm.get('nfcUid');
    }

    get makeBatterySet() {
        return this.batteryForm.get('makeBatterySet');
    }

    get initialFlightTime() {
        return this.batteryForm.get('initialFlightTime');
    }

    get initialCycles() {
        return this.batteryForm.get('initialCycles');
    }
}
