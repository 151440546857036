import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {
    BatteryDto,
    BatteryService,
    BatterySetDto,
    BatterySetService,
    CraftDto,
    CraftService,
    EquipmentDto,
    EquipmentService,
    FlightHistorySummaryDto,
    PersonDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'flight-history-summary',
    templateUrl: './flight-history-summary.component.html'
})
export class FlightHistorySummary {
    @Input() aircraft: CraftDto;
    @Input() battery: BatteryDto;
    @Input() batterySet: BatterySetDto;
    @Input() person: PersonDto;
    @Input() equipment: EquipmentDto;
    @Input() managingOrganisationId: number;

    working = false;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    history: FlightHistorySummaryDto;
    constructor(
        private craftService: CraftService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private equipmentService: EquipmentService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('aircraft' in changes) {
            this.refreshAircraftFlightHistory();
        }
        if ('battery' in changes) {
            this.refreshBatteryFlightHistory();
        }
        if ('batterySet' in changes) {
            this.refreshBatterySetHistory();
        }
        if ('equipment' in changes) {
            this.refreshEquipmentFlightHistory();
        }
    }

    private refreshAircraftFlightHistory() {
        if (this.aircraft == null) {
            return;
        }

        this.craftService
            .findFlightHistorySummary(
                this.aircraft.id,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(history => {
                this.history = history;
            })
            .add(this.workTracker.createTracker());
    }

    private refreshBatteryFlightHistory() {
        if (this.battery == null) {
            return;
        }

        this.batteryService
            .findFlightStatistics(this.battery.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(history => {
                this.history = history;
            })
            .add(this.workTracker.createTracker());
    }

    private refreshBatterySetHistory() {
        if (this.batterySet == null) {
            return;
        }

        this.batterySetService
            .findFlightStatistics(this.batterySet.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(history => {
                this.history = history;
            })
            .add(this.workTracker.createTracker());
    }

    private refreshEquipmentFlightHistory() {
        if (this.equipment == null) {
            return;
        }

        this.equipmentService
            .findFlightStatistics(this.equipment.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(history => {
                this.history = history;
            })
            .add(this.workTracker.createTracker());
    }
}
