/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimpleOrganisationDto } from './simpleOrganisationDto';
import { AirspaceJurisdictionDto } from './airspaceJurisdictionDto';
import { CraftAuthorityDto } from './craftAuthorityDto';
import { AuthorityTypeActions } from './authorityTypeActions';


/**
 * A grouping of authorities for an RPA for a single endorsement type
 */
export interface CraftAuthorityGroup { 
    /**
     * ${swagger.AuthorityType.id}
     */
    id: number;
    /**
     * The name of this authority type
     */
    name: string;
    /**
     * Does the authority have an expiry date
     */
    hasExpiry: boolean;
    /**
     * This authority type will also record an identifier such as a serial number
     */
    hasIdentifier: boolean;
    /**
     * Does the authority type support multiple instances
     */
    hasMultipleInstances: boolean;
    /**
     * ${swagger.AuthorityType.issuingMechanism}
     */
    issuingMechanism: CraftAuthorityGroup.IssuingMechanism;
    /**
     * Any authorities of this type that exist for this RPA
     */
    authorities?: Array<CraftAuthorityDto>;
    availableActions: AuthorityTypeActions;
    /**
     * The customised name for identifier
     */
    identifierLabel?: string;
    jurisdiction: AirspaceJurisdictionDto;
    issuedBy?: SimpleOrganisationDto;
    /**
     * Help text that describes how to get this authority type
     */
    helperText: string;
    /**
     * A short description of the authority type
     */
    description: string;
    /**
     * The type of organisation that this authority applies to
     */
    appliesTo: CraftAuthorityGroup.AppliesTo;
    selfReported?: boolean;
}
export namespace CraftAuthorityGroup {
    export type IssuingMechanism = 'SELF_REPORTED' | 'ISSUER' | 'AUTOMATIC';
    export const IssuingMechanism = {
        SELF_REPORTED: 'SELF_REPORTED' as IssuingMechanism,
        ISSUER: 'ISSUER' as IssuingMechanism,
        AUTOMATIC: 'AUTOMATIC' as IssuingMechanism
    };
    export type AppliesTo = 'PERSONAL' | 'COMMERCIAL' | 'RPA';
    export const AppliesTo = {
        PERSONAL: 'PERSONAL' as AppliesTo,
        COMMERCIAL: 'COMMERCIAL' as AppliesTo,
        RPA: 'RPA' as AppliesTo
    };
}


