import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalContainer } from '@flyfreely-portal-ui/ngx-bootstrap-customisation';
import { ProfileDialogue } from '../profile-dialogue.component';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: ModalContainer,
                data: {
                    modalContent: ProfileDialogue,
                    modalClass: 'modal-lg'
                }
            }
        ])
    ],
    exports: [RouterModule]
})
export class UserProfileRoutingModule {}
