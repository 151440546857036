import { Injectable } from '@angular/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OnboardingHelpDialogue } from './help-dialogue/help-dialogue.component';
import { WebcamDialogue } from './webcam-dialogue/webcam-dialogue.component';

@Injectable()
export class OnboardingDialoguesService {
    constructor(private modalService: BsModalService) {}

    showHelp(helpText: string, helpTitle: string) {
        return this.modalService.show<OnboardingHelpDialogue>(
            OnboardingHelpDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-md modal-help',
                initialState: { helpText, helpTitle }
            }
        );
    }

    showWebcamDialogue() {
        return this.modalService.show<WebcamDialogue>(WebcamDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-dialog modal-responsive'
        });
    }
}
