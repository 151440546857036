<div
    class="panel widget"
    *ngIf="(maintenanceLogs && maintenanceLogs.length > 0) || working"
>
    <div class="panel-heading-flex">
        <div class="panel-title no-uppercase">Upcoming Maintenance</div>
        <div class="panel-content"></div>
        <div class="panel-buttons">
            <button
                class="btn btn-inline"
                (click)="showBody = !showBody"
                [tooltip]="showBody ? 'Hide' : 'Show'"
                placement="left"
            >
                <i *ngIf="showBody" class="fa-chevron-up fa no-border"></i>
                <i *ngIf="!showBody" class="fa-chevron-down fa no-border"></i>
            </button>
        </div>
    </div>
    <div *ngIf="showBody" class="panel-body" @expandOnEnter @collapseOnLeave>
        <div
            class="static-table-container no-actions"
            *ngIf="maintenanceLogs && maintenanceLogs.length"
        >
            <static-table
                [availableColumns]="availableColumns"
                [selectedColumns]="selectedColumns"
                [tableData]="maintenanceLogs"
                [tableConfig]="tableConfig"
                [borderless]="false"
                [underlinedRows]="true"
                scanability="comfortable"
            >
            </static-table>
        </div>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
<completed-outstanding-tasks-banner
    *ngIf="(!maintenanceLogs || maintenanceLogs.length == 0) && !working"
    widgetHeading="Upcoming Maintenance"
></completed-outstanding-tasks-banner>
