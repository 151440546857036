import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {
    AerodromeDetailsDto,
    AirspaceJurisdictionDto,
    ContactDto,
    DO_NOTHING,
    FEATURE_NOTAMS,
    FlyFreelyLoggingService,
    JurisdictionService,
    LocationDetailsDto,
    LocationFeatureDto,
    NotamDto,
    NotamService,
    OrganisationIdentifierDto,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import bearing from '@turf/bearing';
import center from '@turf/center';
import distance from '@turf/distance';
import { bearingToAzimuth } from '@turf/helpers';
import { getOrElse, map as optionMap } from 'fp-ts/es6/Option';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { flightAreaOf } from 'libs/locations/src/lib/helpers';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { ProfileDialoguesService } from 'libs/user-profile/src/lib/profile-dialogues.service';
import { asyncScheduler, scheduled, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LocationBearing } from '../../bearing.pipe';
import { InformationSearchComponent } from '../contact-pill/search/info-search.component';

interface SelectableNotam extends NotamDto {
    selected: boolean;
    locationBearing?: LocationBearing;
}

interface SelectableAerodrome extends ContactDto {
    selected: boolean;
    locationBearing?: LocationBearing;
}

interface LocationBearings {
    notams: SelectableNotam[];
    aerodromes: LocationBearing[];
    locationCentre: GeoJSON.Point;
    locationArea: number;
}

@Component({
    selector: 'notam-search',
    templateUrl: './notam-search-dialogue.component.html',
    styleUrls: ['../search-dialogues.scss', './notam-search.scss'],
    host: {
        class: 'fill-parent container-with-footer'
    }
})
export class NotamSearchDialogue {
    @Input() missionId: number;
    @Input() existingAerodromes: ContactDto[];
    @Input() existingNotams: NotamDto[];
    @Input() locationJurisdiction: AirspaceJurisdictionDto;
    @Input() location: LocationDetailsDto;
    @Input() hasAcknowledgedNoRelevantNotams: boolean;
    @Output() close = new EventEmitter<void>();
    @Output() addNotam = new EventEmitter<NotamDto>();
    @Output() removeNotam = new EventEmitter<NotamDto>();
    @Output() acknowledgeNoRelevantNotam = new EventEmitter<void>();

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;

    private searchSubject = new Subject<string>();
    private filterSubject = new Subject<SelectableAerodrome[]>();

    aerodromes: AerodromeDetailsDto[];
    selectedFilters: SelectableAerodrome[];
    aerodrome: AerodromeDetailsDto;
    additional: ContactDto[] = [];

    locationFeatures: FeatureGroup[];
    locationBearings: LocationBearings;

    @ViewChild(InformationSearchComponent)
    private search: InformationSearchComponent;

    notams: SelectableNotam[] = [];
    showNotams = false;
    loadingNotams = false;
    notamErrorCode: string;

    notamFeature = [FEATURE_NOTAMS];

    missionHasNotams: boolean;

    constructor(
        private jurisdictionService: JurisdictionService,
        private notamService: NotamService,
        private userService: UserService,
        private profileDialoguesService: ProfileDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.setupJurisdiction();
        this.setupSearch();
        this.setupNotams();
        this.setupFilters();

        this.calculateNotamLocationBearing();
    }

    ngOnDestroy() {
        this.searchSubject.complete();
        this.filterSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupJurisdiction() {
        this.additional =
            this.locationJurisdiction == null
                ? []
                : this.locationJurisdiction.firList
                      .map(
                          f =>
                              ({
                                  name: f.name,
                                  identifier: f.code,
                                  type: ContactDto.Type.CUSTOM
                              } as ContactDto)
                      )
                      .concat(
                          this.locationJurisdiction.notamSourceList.map(
                              f =>
                                  ({
                                      name: f.name,
                                      identifier: f.code,
                                      type: ContactDto.Type.CUSTOM
                                  } as ContactDto)
                          )
                      );
    }

    setupSearch() {
        this.searchSubject
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(search => search != null && search.length > 0),
                switchMap(search =>
                    this.jurisdictionService.findAerodromes(search)
                )
            )
            .subscribe(results => {
                this.aerodromes = results
                    .filter(
                        r =>
                            this.selectedFilters.findIndex(
                                a => a.identifier === r.code
                            ) === -1
                    )
                    .slice(0, 5);
                this.calculateNotamLocationBearing();
            });
    }

    setupNotams() {
        this.filterSubject
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                map(aerodromes =>
                    aerodromes
                        .filter(a => a.selected === true)
                        .map(a => a.identifier)
                ),
                switchMap(locations => {
                    this.notams = [];
                    this.showNotams = true;
                    this.notamErrorCode = null;
                    this.loadingNotams = true;

                    if (locations.length === 0) {
                        return scheduled([], asyncScheduler);
                    }

                    const doneWorking = this.workTracker.createTracker();

                    return this.notamService
                        .findNotams(locations, this.missionId)
                        .pipe(
                            tap({
                                error: doneWorking,
                                complete: doneWorking
                            })
                        );
                })
            )
            .subscribe(
                notams => this.updateNotams(notams),
                error => {
                    this.loadingNotams = false;
                    this.notamErrorCode = error.code ?? '';
                    if (this.notamErrorCode !== 'NOT_SETUP') {
                        this.logging.error(
                            error,
                            `There was an error while fetching NOTAMs: ${error.message}`
                        );
                    }
                }
            );
    }

    calculateNotamLocationBearing() {
        const maybeFlightArea = flightAreaOf(this.location);
        const maybeCentre = optionMap((l: LocationFeatureDto) =>
            center(<GeoJSON.Polygon>l.geometry)
        )(maybeFlightArea);
        const centre = getOrElse(() => null)(maybeCentre);

        const getBearing = (pos: GeoJSON.Point) =>
            centre != null
                ? {
                      bearing: bearingToAzimuth(bearing(pos, centre)),
                      distance: distance(centre, pos) * 0.539957
                  }
                : (null as LocationBearing);

        this.locationBearings = {
            notams:
                this.notams != null
                    ? this.notams
                          .filter(n => n.position != null)
                          .map(n => {
                              if (n.position) {
                                  const result = getBearing(n.position);
                                  return {
                                      ...n,
                                      locationBearing:
                                          result != null
                                              ? {
                                                    bearing:
                                                        result.bearing ?? null,
                                                    distance:
                                                        result.distance ?? null
                                                }
                                              : null
                                  };
                              } else {
                                  return n;
                              }
                          })
                          .sort(
                              (a, b) =>
                                  a.locationBearing?.distance -
                                  b.locationBearing?.distance
                          )
                    : [],
            aerodromes:
                this.aerodromes != null
                    ? this.aerodromes
                          .filter(a => a.location != null)
                          .map(a => getBearing(a.location))
                    : [],
            locationCentre: centre != null ? centre.geometry : null,
            locationArea: null
        };
        this.updateHasNotams();
    }

    setupFilters() {
        this.selectedFilters = this.existingAerodromes
            .map(a => ({
                ...a,
                selected: true
            }))
            .concat(
                this.additional
                    .filter(
                        a =>
                            this.existingAerodromes.findIndex(
                                e => e.identifier === a.identifier
                            ) === -1
                    )
                    .map(a => ({
                        ...a,
                        selected: true
                    }))
            );

        this.filterSubject.next(this.selectedFilters);
    }

    updateNotams(notams: NotamDto[]) {
        this.notams = [];
        this.showNotams = true;
        this.notamErrorCode = null;
        this.loadingNotams = true;

        notams.map(notam => {
            this.notams.push({
                ...notam,
                selected: this.isNotamSelected(notam)
            });
        });

        this.updateHasNotams();
        this.loadingNotams = false;
        this.calculateNotamLocationBearing();
    }

    findAirports(search: string) {
        this.searchSubject.next(search);
    }

    selectAirport(aerodrome: AerodromeDetailsDto) {
        this.search.clear();

        const existing = this.selectedFilters.find(
            a => a.identifier === aerodrome.code
        );

        if (existing != null) {
            existing.selected = true;
        } else {
            this.selectedFilters.push({
                value: aerodrome.operator,
                name: aerodrome.name,
                type: ContactDto.Type.AERODROME,
                identifier: aerodrome.code,
                selected: true
            });
        }

        this.aerodromes = [];
        this.filterSubject.next(this.selectedFilters);
    }

    isNotamSelected(notam: NotamDto) {
        return this.existingNotams.findIndex(n => n.id === notam.id) !== -1;
    }

    toggleFilter(aerodrome: SelectableAerodrome) {
        aerodrome.selected = !aerodrome.selected;
        this.filterSubject.next(this.selectedFilters);
    }

    updateAsaDetails() {
        const currentUser = this.userService.getCurrentUser();
        const asaDetails: OrganisationIdentifierDto = {
            identifier: null,
            integration: null
        };

        const dialogue = this.profileDialoguesService.showAirServicesDialogue(
            currentUser,
            asaDetails
        );

        dialogue.onHidden
            .asObservable()
            .pipe(take(1))
            .subscribe(() => {
                this.setupNotams();
                this.filterSubject.next(this.selectedFilters);
            });
    }

    submitNotam(notam: SelectableNotam) {
        this.addNotam.emit(notam);
        notam.selected = true;
        this.missionHasNotams = true;
    }

    deleteNotam(notam: SelectableNotam) {
        this.removeNotam.emit(notam);
        notam.selected = false;
        this.updateHasNotams();
    }

    updateHasNotams() {
        this.missionHasNotams =
            this.locationBearings.notams.filter(n => n.selected).length > 0;
    }

    acknowledgeNoneRelevant() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Acknowledge No Relevant NOTAM',
                'Are you sure you want to acknowledge that none of the currently active NOTAM messages are of relevance to this mission?',
                'Yes',
                () => Promise.resolve()
            )
            .then(() => {
                this.acknowledgeNoRelevantNotam.emit();
                this.close.emit();
            }, DO_NOTHING);
    }

    closeModal() {
        this.close.emit();
    }
}
