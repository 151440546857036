<form [formGroup]="inputForm" (submit)="submit()">
    <div class="info-input-group" [ngClass]="{ readonly: readonly }">
        <input
            type="text"
            formControlName="value"
            [placeholder]="placeholder ? placeholder : ''"
            [readonly]="readonly"
            class="info-input"
        />
        <span>
            <a *ngIf="showNearby" (click)="selectNearby()">Nearby</a>
            <button
                (click)="submit()"
                class="search-button"
                [disabled]="inputForm.invalid || readonly"
            >
                <span class="fa fa-search"></span>
            </button>
        </span>
    </div>
</form>
