import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-section-heading',
    template: `
        <div>
            <h4 class="section-heading">
                <span>{{ to.label }}</span>
                <div></div>
            </h4>
            <p>{{ to.text }}</p>
        </div>
    `
})
export class FormlySectionHeading extends FieldType {}
