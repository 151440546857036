<ng-container screenAnalytics="mission-overview-map">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Mission Overview</h3>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div
        class="modal-body no-footer inner-container max-height horizontal-container flight-analyser-container"
    >
        <div class="fill horizontal-container">
            <flyfreely-map
                #map
                [style]="'mapbox://styles/mapbox/outdoors-v9'"
                logoPosition="bottom-left"
                class="fill"
                [features]="renderedFeatures"
                [organisationId]="mission?.organisationId"
                (featuresLoaded)="map.zoomToAllFeatures(false)"
            >
            </flyfreely-map>
        </div>
        <div class="contents container-scroll fcol-3">
            <div
                *ngIf="
                    working &&
                    (groupedFeatures == null || groupedFeatures.length === 0)
                "
            >
                Loading...
            </div>
            <div
                *ngIf="
                    !working &&
                    (groupedFeatures == null || groupedFeatures.length === 0)
                "
            >
                No mission features to display
            </div>
            <div *ngIf="groupedFeatures != null && groupedFeatures.length > 0">
                <ng-container *ngFor="let grouping of groupedFeatures">
                    <h4 class="section-heading">
                        <span>
                            {{ grouping.groupName }}
                        </span>
                        <div></div>
                    </h4>
                    <ng-container *ngFor="let group of grouping.groups">
                        <div>
                            <div class="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [ngModelOptions]="{
                                            standalone: true
                                        }"
                                        [(ngModel)]="group.selected"
                                        (ngModelChange)="
                                            updateRenderedFeatures()
                                        "
                                        [disabled]="working"
                                    />
                                    <span class="cr"
                                        ><i
                                            class="cr-icon glyphicon glyphicon-ok"
                                        ></i>
                                    </span>
                                    {{ group.groupName }}
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <ngx-loading [show]="working"></ngx-loading>
    </div>
</ng-container>
