import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    EquipmentDto,
    EquipmentService,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    PrintOption,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'equipment-view',
    templateUrl: './equipment-view.component.html'
})
export class EquipmentView implements OnInit, OnDestroy {
    @Input() equipment: EquipmentDto;
    @Input() organisation: PersonsOrganisationDto;
    @Input() allowEdit: boolean;
    @Output() edit = new EventEmitter<void>();
    @Output() deleted = new EventEmitter<void>();

    onEquipmentUpdated: (event: { equipment: EquipmentDto }) => void;

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    working: boolean = false;

    // Does the container allow editing
    canUseNfc: boolean;
    // Is editing available
    canEdit = true;
    canDelete: boolean;
    isOwner: boolean;

    printOptions: PrintOption[] = [];

    constructor(
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private equipmentService: EquipmentService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.canUseNfc = false;
    }

    ngOnInit() {
        // this.refreshEquipment();
        this.refreshPermissions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('equipment' in changes) {
            this.refreshPermissions();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        if (!this.equipment) {
            this.canDelete = false;
            this.canEdit = false;
            this.isOwner = false;
            return;
        }
        this.canEdit = this.allowEdit;
        this.isOwner = this.equipment.organisationId === this.organisation.id;
    }

    deleteEquipment() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete this equipment?',
                `Are you sure you wish to delete this equipment: ${this.equipment.name}`,
                'Delete',
                // TODO: update
                () =>
                    this.equipmentService.delete(this.equipment.id).toPromise()
            )
            .then(() => this.deleted.emit());
    }

    private updateEquipment(equipment: EquipmentDto) {
        this.equipment = equipment;
        this.onEquipmentUpdated({ equipment });
    }

    editEquipment() {
        this.edit.emit();
    }

    // disposeEquipment() {
    //     const doneWorking = this.workTracker.createTracker();
    //     this.commonDialoguesService.showFormlyDialogue('Dispose of Equipment',
    //     'Dispose',
    //     true,
    //     true,
    //     [{
    //         key: 'disposalDate',
    //         type: 'date',
    //         props: { label: 'Disposal Date', required:false}
    //     }],
    //     {},
    //     (data: {disposalDate: Date}) =>
    //     this.equipmentService.
    //     )
    // }
}
