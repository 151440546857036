import {
    Directive,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    HostListener
} from '@angular/core';

/**
 * This directive needs to be placed on the scrolling container.
 * It will then notify when its children scroll onto the screen
 * based on the spiedTags.
 * To choose a more deeply nested child container set the
 * spied container ref.
 */
@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
    @Input() spiedTags: string[];
    @Output() sectionChange = new EventEmitter<string>();

    /**
     * An optional container to look in to find the sections.
     */
    @Input()
    spiedContainer: ElementRef;

    /**
     * The distance in px before the element actually enters
     * the view, to be considered "active"
     */
    @Input() activateOffset = 0;

    private currentSection: string;

    constructor(private _el: ElementRef) {}

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        let currentSection: string;
        const children = (this.spiedContainer ?? this._el.nativeElement)
            .children;
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop;
        for (let i = 0; i < children.length; i++) {
            const element = children[i];

            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                if (
                    element.offsetTop - parentOffset - this.activateOffset <=
                    scrollTop
                ) {
                    currentSection = element.id;
                }
            }
        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }
}
