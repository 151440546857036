<ng-container [formGroup]="formGroup">
    <div class="modal-header">
        <h3 class="modal-title">
            {{ currentlyEditing != null ? 'Edit' : 'Add' }} Flight History
        </h3>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row top-buffer">
            <div class="col-md-12">
                <label>RPA Type</label>
                <rpa-type-selector
                    [rpaTypeList]="rpaTypeList"
                    selectorFormControlName="rpaTypeId"
                ></rpa-type-selector>
            </div>
        </div>
        <div class="row top-buffer">
            <div class="col-md-12">
                <label>RPA Category</label>
                <ng-select
                    [items]="rpaCategoryList"
                    bindLabel="name"
                    bindValue="value"
                    formControlName="rpaCategory"
                    appendTo="body"
                    [clearable]="false"
                    [readonly]="disabledRpaTypeAndMtow | async"
                >
                </ng-select>
            </div>
        </div>

        <div class="row top-buffer">
            <div class="col-md-12">
                <label>Maximum Take Off Weight (g)</label>
                <input
                    class="form-control"
                    formControlName="mtow"
                    type="number"
                    min="1"
                    [readonly]="disabledRpaTypeAndMtow | async"
                />
            </div>
        </div>
        <div class="row top-buffer">
            <div class="col-md-12">
                <label>Line of Sight</label>
                <ng-select
                    [items]="sightTypes"
                    bindLabel="name"
                    bindValue="value"
                    formControlName="lineOfSight"
                    appendTo="body"
                >
                </ng-select>
            </div>
        </div>

        <div class="row top-buffer">
            <div class="col-md-12">
                <label>Location Name</label>
                <input class="form-control" formControlName="locationName" />
            </div>
        </div>
        <div class="row top-buffer">
            <div class="col-md-12">
                <label>Date</label>
                <div class="input-group">
                    <input
                        class="form-control"
                        #editDate="bsDatepicker"
                        bsDatepicker
                        formControlName="flightDate"
                    />
                    <span
                        class="input-group-addon btn-default"
                        (click)="editDate.toggle()"
                        [attr.aria-expanded]="editDate.isOpen"
                    >
                        <i
                            class="glyphicon glyphicon-calendar"
                            style="cursor: pointer"
                        ></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="row top-buffer">
            <div class="col-md-12">
                <label>Time of Day</label>
                <ng-select
                    [items]="timeTypes"
                    bindLabel="name"
                    bindValue="value"
                    formControlName="timeOfDay"
                    appendTo="body"
                >
                </ng-select>
            </div>
        </div>

        <div class="row top-buffer">
            <div class="col-md-12">
                <label>Number of Flights</label>
                <input
                    class="form-control"
                    formControlName="numberOfFlights"
                    type="number"
                    min="1"
                />
            </div>
        </div>
        <div class="row top-buffer">
            <div class="col-md-12">
                <label [ngClass]="{ readonly: working }"> Duration </label>
                <duration
                    formControlName="duration"
                    [readonly]="working"
                    [showHour]="true"
                    [showMinute]="true"
                    [showSecond]="true"
                >
                </duration>
            </div>
        </div>

        <div class="row top-buffer">
            <div class="col-md-12">
                <label>Real or Simulated</label>
                <div
                    class="input-group"
                    btnRadioGroup
                    formControlName="simulated"
                    style="padding-top: 5px"
                >
                    <label
                        class="btn btn-xs btn-pill"
                        [btnRadio]="false"
                        [disabled]="working"
                        >Real Flights</label
                    >
                    <label
                        class="btn btn-xs btn-pill"
                        [btnRadio]="true"
                        [disabled]="working"
                        >Simulated</label
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            *ngIf="!currentlyEditingId"
            type="button"
            class="btn btn-sm btn-default"
            (click)="bulkUpload()"
            [disabled]="working"
            placement="top"
            tooltip="Bulk upload flight logs"
        >
            Bulk Upload
        </button>
        <button
            type="button"
            class="btn btn-sm btn-primary btn"
            (click)="saveFlight()"
            [disabled]="formGroup.invalid || working"
            placement="top"
            tooltip="Save this entry"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-link btn-sm"
            (click)="close()"
            [disabled]="working"
            placement="top"
            tooltip="Cancel editing"
        >
            Cancel
        </button>
    </div>
</ng-container>
