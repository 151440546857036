<div class="modal-header no-icon">
    <h3 class="modal-title">Edit Maintenance Activity Details</h3>
    <button type="button" (click)="hide()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="form">
    <div class="modal-body">
        <div class="modal-body-content">
            <div
                *ngIf="canUseMaintenanceTasks"
                class="form-group input-content"
            >
                <div class="col-xs-3 text-right">
                    <button
                        class="btn-circle btn-link"
                        type="button"
                        (click)="showAddComponentForm()"
                        [disabled]="!canAddMaintenanceTask || working"
                        placement="top"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Maintenance Tasks
                    </label>
                </div>
                <div class="col-xs-9">
                    <maintenance-task-list
                        [formArray]="form.controls.tasks"
                        [activityNumber]="activityNumber"
                        (saveMaintenanceTask)="canAddMaintenanceTask = true"
                        (editMaintenanceTask)="canAddMaintenanceTask = false"
                    >
                    </maintenance-task-list>
                </div>
            </div>

            <!-- <enhanced-help componentName="tasks"> </enhanced-help> -->

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Maintenance Performed
                        <field-validation-indicator
                            [control]="form.controls.maintenancePerformed"
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <textarea
                        class="form-control"
                        formControlName="maintenancePerformed"
                        rows="2"
                        [readonly]="working"
                    >
                    </textarea>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <!-- <enhanced-help componentName="maintenancePerformed"> </enhanced-help> -->

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Maintenance Remarks
                        <field-validation-indicator
                            [control]="form.controls.maintenanceRemarks"
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <textarea
                        class="form-control"
                        formControlName="maintenanceRemarks"
                        rows="2"
                        [readonly]="working"
                    >
                    </textarea>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <!-- <enhanced-help componentName="maintenanceRemarks"> </enhanced-help> -->

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Activity Start Date
                        <field-validation-indicator
                            [control]="form.controls.startDate"
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <div class="input-group">
                        <input
                            class="form-control"
                            type="text"
                            #startDatepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="startDate"
                            placement="bottom"
                            [readonly]="working"
                            [isDisabled]="working"
                        />
                        <span
                            *ngIf="!working"
                            class="input-group-addon btn-default"
                            (click)="startDatepicker.toggle()"
                            [attr.aria-expanded]="startDatepicker.isOpen"
                        >
                            <i class="glyphicon glyphicon-calendar"></i>
                        </span>
                    </div>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <!-- <enhanced-help componentName="startDate"> </enhanced-help> -->

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Activity End Time
                        <field-validation-indicator
                            [control]="form.controls.endTime"
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <div class="horizontal-container pad-children">
                        <div class="input-group">
                            <input
                                class="form-control"
                                type="text"
                                #endDatepicker="bsDatepicker"
                                bsDatepicker
                                formControlName="endTime"
                                placement="bottom"
                                [minDate]="form.controls.startDate.value"
                                [readonly]="working"
                                [isDisabled]="working"
                            />
                            <span
                                *ngIf="!working"
                                class="input-group-addon btn-default"
                                (click)="endDatepicker.toggle()"
                                [attr.aria-expanded]="endDatepicker.isOpen"
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>
                        <timepicker
                            formControlName="endTime"
                            [hourStep]="1"
                            [minuteStep]="1"
                            [showMeridian]="false"
                            [showSpinners]="false"
                            [readonlyInput]="working"
                        >
                        </timepicker>
                        <span>{{ organisation.timeZone }}</span>
                        <button
                            type="button"
                            (click)="completedNow()"
                            class="btn btn-default btn-sm"
                        >
                            Now
                        </button>
                    </div>
                    <p class="help-block">
                        This time is used to order the activity against missions
                        to calculate the configuration version
                    </p>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <!-- <enhanced-help componentName="endDate"> </enhanced-help> -->
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            type="button"
            [disabled]="working || form.invalid"
            (click)="saveAssignedMaintenanceActivity()"
        >
            Save
        </button>
    </div>
</form>
