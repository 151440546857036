<form [formGroup]="form" *ngIf="serviceLogs.length > 0">
    <div
        class="vertical-container container-fluid bottom-buffer"
        *ngIf="form != null"
    >
        <div class="form-group">
            <div class="input-content">
                <div class="col-xs-3"></div>
                <div class="col-xs-6">
                    <label
                        >{{ form.controls.name.value }} Maintenance Logs:</label
                    >
                    <div class="static-table-container custom-actions">
                        <static-table
                            [availableColumns]="availableColumns"
                            [selectedColumns]="selectedColumns"
                            [tableData]="serviceLogs"
                            [tableConfig]="tableConfig"
                        >
                            <ng-template rowActions let-row="row">
                                <ng-container *ngIf="row.status == 'DRAFT'">
                                    <button
                                        class="btn btn-primary btn-xs"
                                        type="button"
                                        (click)="submitMaintenance(row)"
                                        tooltip="Submit this maintenance log"
                                        placement="left"
                                        angulartics2On="click"
                                        angularticsAction="submit-maintenance"
                                        angularticsCategory="mission-completion"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        class="btn btn-default btn-xs"
                                        type="button"
                                        (click)="cancelMaintenance(row)"
                                        tooltip="Cancel this maintenance log"
                                        placement="left"
                                        angulartics2On="click"
                                        angularticsAction="cancel-maintenance"
                                        angularticsCategory="mission-completion"
                                    >
                                        Cancel
                                    </button>
                                </ng-container>
                            </ng-template>
                        </static-table>
                    </div>
                </div>
                <div class="col-xs-3"></div>
            </div>
        </div>
    </div>
</form>
