/**
 * This module contains search factories for use with the FlyFreely tables.
 */

import * as moment from 'moment';

export function dateRange(range: [Date, Date]) {
    const start = moment(range[0]).startOf('day');
    const end = moment(range[1]).endOf('day');

    return (date: moment.Moment) =>
        date ? date.isBetween(start, end, undefined, '[]') : false;
}

export function isInArray(value: any) {
    return (array: any[]) => array != null && array.indexOf(value) !== -1;
}
