import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { AuthorityModule } from 'libs/authorities/src/lib/authorities.module';
import { OrganisationAuthorityModule } from 'libs/authorities/src/lib/organisation-authority/organisation-authority.module';
import { BulkUploadsModule } from 'libs/bulk-uploads/src/lib/bulk-uploads.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlightHistoryModule } from 'libs/flight-history/src/lib/flight-history.module';
import { JurisdictionsModule } from 'libs/jurisdictions/src/lib/jurisdictions.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { RpaModule } from 'libs/rpa/src/lib/rpa.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { WorkflowModule } from 'libs/workflows/src/lib/workflows.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ngfModule } from 'angular-file';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { PersonnelAddDialogue } from './add-dialogue/person-add-dialogue.component';
import { PersonnelDialogue } from './details-dialogue/personnel-dialogue.component';
import { ManagingOrganisations } from './managing-organisations/managing-organisations.component';
import { PersonnelDetails } from './personnel-details/personnel-details.component';
import { PersonnelDialoguesService } from './personnel-dialogues.service';
import { PersonnelEdit } from './personnel-edit/personnel-edit.component';
import { PersonnelRegisterStatusComponent } from './personnel-registers/personnel-registers-status.component';
import { PersonnelReportsDialogue } from './personnel-reports-dialogue/personnel-reports-dialogue.component';
import { PersonnelRoles } from './personnel-roles/personnel-roles.component';
import { PrintPersonnelReports } from './print-personnel-reports/print-personnel-reports.component';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { PersonnelCurrencyComponent } from './personnel-currency/personnel-currency.component';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // ngx-bootstrap
        TooltipModule,
        ModalModule.forChild(),
        TabsModule,
        BsDropdownModule,
        BsDatepickerModule,
        PopoverModule,
        // ngx-formly
        ReactiveFormsModule,
        ValidationModule,
        FormlyModule,
        FormlyBootstrapModule,
        // ng-select
        NgSelectModule,
        ngfModule,
        ToastrModule,
        TooltipModule,
        MaterialModule,
        SharedUiModule,
        DocumentationModule,
        WorkflowModule,
        AttachmentsModule,
        LoadingIndicatorModule,
        AuthorityModule,
        OrganisationAuthorityModule,
        RpaModule,
        FlightHistoryModule,
        JurisdictionsModule,
        BulkUploadsModule,
        EnhancedHelpModule,
        AnalyticsModule
    ],
    providers: [PersonnelDialoguesService],
    bootstrap: [],
    declarations: [
        PersonnelAddDialogue,
        PersonnelReportsDialogue,
        ManagingOrganisations,
        PersonnelDialogue,
        PersonnelDetails,
        PersonnelEdit,
        PersonnelRoles,
        PrintPersonnelReports,
        PersonnelRegisterStatusComponent,
        PersonnelCurrencyComponent
    ],
    exports: [
        ManagingOrganisations,
        PersonnelDialogue,
        PersonnelRoles,
        PrintPersonnelReports
    ]
})
export class PersonnelModule {}
