<div>
    <div
        *ngIf="
            cancelledIncidentReports != null &&
            cancelledIncidentReports.length > 0
        "
        class="bottom-buffer right-buffer"
    >
        <div mdcFormField>
            <div mdcSwitch>
                <div mdcSwitchThumb>
                    <input
                        type="checkbox"
                        mdcSwitchInput
                        [(ngModel)]="showCancelled"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </div>
            </div>
            <label mdcFormFieldLabel>Show cancelled incident reports?</label>
        </div>
    </div>
    <div *ngFor="let report of incidentReports">
        <button
            class="btn btn-xs btn-primary pull-right"
            type="button"
            *ngIf="report.availableActions.canResolve"
            (click)="reviewIncidentReport(report)"
        >
            Review
        </button>

        <b>Status: </b> {{ report.status | formatIncidentReportStatus }}
        <b>Remote Pilot:</b> {{ report.pilot | formatPerson }} <b>RPA:</b>
        {{ report.rpa | formatRpa }} <b>Incident Time:</b>
        {{ report.incidentTime | formatDateTime: timeZone }}
        <p><b>Report:</b> {{ report.report }}</p>

        <p *ngIf="report.status === 'RESOLVED'">
            <b>Resolution:</b> {{ report.resolution }}
        </p>
    </div>
    <ng-container *ngIf="showCancelled">
        <div *ngFor="let report of cancelledIncidentReports">
            <button
                class="btn btn-xs btn-primary pull-right"
                type="button"
                *ngIf="report.availableActions.canResolve"
                (click)="reviewIncidentReport(report)"
            >
                Review
            </button>

            <b>Status: </b> {{ report.status | formatIncidentReportStatus }}
            <b>Remote Pilot:</b> {{ report.pilot | formatPerson }} <b>RPA:</b>
            {{ report.rpa | formatRpa }} <b>Incident Time:</b>
            {{ report.incidentTime | formatDateTime: timeZone }}
            <p><b>Report:</b> {{ report.report }}</p>

            <p *ngIf="report.status === 'RESOLVED'">
                <b>Resolution:</b> {{ report.resolution }}
            </p>
        </div>
    </ng-container>
    <div class="action-container">
        <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="createIncidentReport()"
            (disabled)="(working)"
        >
            Add Incident Report
        </button>
    </div>
</div>
