<div class="field_app_download_container main_container">
    <div class="field_app_download_frame onboarding-frame">
        <img
            class="mobile-image"
            imgPath="/onboarding/mobile-assets/FF-FA-Store.png"
        />
        <h5 class="download-text">Download the FlyFreely Field App</h5>
        <div
            class="horizontal-container flex-centered vertical-align fill store-link-container"
        >
            <a
                href="https://apps.apple.com/au/app/flyfreely-field-app/id1480135793"
                target="_blank"
            >
                <img
                    class="download-store-logo"
                    imgPath="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png"
                />
            </a>
            <a
                href="https://play.google.com/store/apps/details?id=io.flyfreely.fieldapp"
                target="_blank"
            >
                <img
                    class="download-store-logo"
                    imgPath="/Google_Play_Store_badge_EN.png"
                />
            </a>
        </div>
    </div>
</div>
