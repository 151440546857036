import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgSelectSearch } from 'libs/ng-select/src/lib/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormlyGraphQlPaginationService } from './helpers';

/**
 * A version of the Formly ng-select that can load items dynamically and paginate using a scoped graph-ql service that is passed in.
 * The scoped service should be passed in using the 'service' value in the Formly template options.
 * For an example implementation, see 'src\flyfreely\adminui2\jurisdictions\edit\jurisdiction-edit-dialogue.component.ts'
 */
@Component({
    selector: 'formly-graphql-ng-select',
    template: `
        <div class="form-group">
            <ng-select
                [items]="items"
                [clearable]="!to.required"
                [formControl]="formControl"
                [bindLabel]="to.labelProp || 'label'"
                [bindValue]="to.valueProp || 'value'"
                appendTo="body"
                [loading]="loading"
                [virtualScroll]="true"
                (scrollToEnd)="onSelectScroll()"
                (search)="onSelectSearch($event)"
                (close)="onSelectClose()"
                (open)="onSelectOpen()"
            >
            </ng-select>
        </div>
    `
})
export class FormlyGraphQlNgSelect extends FieldType {
    graphQlService: FormlyGraphQlPaginationService;
    items: any = [];
    loading: boolean = false;
    isOpen: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.graphQlService = this.to.service;
        this.graphQlService.loading$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(loading => (this.loading = loading && this.isOpen));
        this.graphQlService.results$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => (this.items = results));
        this.graphQlService.refreshItems(this.formControl.value);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    onSelectScroll() {
        this.graphQlService.onSelectScroll();
    }

    onSelectSearch(search: NgSelectSearch) {
        this.graphQlService.onSelectSearch(search);
    }

    onSelectOpen() {
        this.isOpen = true;
    }

    onSelectClose() {
        this.isOpen = false;
        this.graphQlService.onSelectClose();
    }
}
