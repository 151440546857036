import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FlightLogCollectionAlertDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'flight-log-alert',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-container *ngIf="alertSettings == null"
            ><a (click)="showSettings.emit()">Setup Alert</a></ng-container
        >
        <ng-container *ngIf="alertSettings != null"
            ><button
                class="btn btn-tertiary"
                (click)="showSettings.emit()"
                tooltip="Adjust flight log collection alerts settings"
                placement="top"
            >
                <span class="fal fa-cog"></span>
            </button>
            <ng-container *ngIf="alertSettings.status == 'ALERT'"
                ><action-required
                    ><p
                        *ngIf="
                            alertSettings.invalidPassword &&
                            alertSettings.invalidPasswordState
                        "
                    >
                        The password for the account is incorrect
                    </p>
                    <p
                        *ngIf="
                            alertSettings.daysSinceLastFile.length > 0 &&
                            alertSettings.daysSinceLastFileState
                        "
                    >
                        {{ alertSettings.daysSinceLastFileState }} days since
                        last flight log file
                    </p></action-required
                ></ng-container
            >
        </ng-container>
    `
})
export class FlightLogCollectionAlert {
    @Input()
    alertSettings: FlightLogCollectionAlertDto;

    @Output()
    showSettings = new EventEmitter<void>();
}
