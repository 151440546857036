import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MapEventType, ToolbarButton } from '../events';
import { MapService } from '../map.service';
@Component({
    selector: 'map-toolbar',
    templateUrl: './map-toolbar.component.html',
    styleUrls: [
        './map-toolbar.component.scss',
        '../shared.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class MapToolbarComponent implements OnInit, OnDestroy {
    // expose enum
    ToolbarButton = ToolbarButton;

    buttonsEnabled: boolean = false;
    private ngUnsubscribe = new Subject<void>();

    constructor(private mapService: MapService) {}

    ngOnInit() {
        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter(event => event.type === MapEventType.mapLoaded)
            )
            .subscribe(() => {
                this.buttonsEnabled = true;
            });
    }

    ngOnDestroy(): any {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
