import { Component, ViewChild, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingDialoguesService } from './onboarding-dialogues.service';

export const LOGO_SELECTION_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LogoSelection),
    multi: true
};
@Component({
    selector: 'logo-selection',
    templateUrl: './logo-selection.html',
    styleUrls: ['./logo-selection.scss'],
    providers: [LOGO_SELECTION_CONTROL_VALUE_ACCESSOR]
})
export class LogoSelection implements ControlValueAccessor {
    @Input() noun: string;
    @Input() hasLogo: boolean;

    public imgSrc: string;
    baseDropValid: boolean;

    onChange = Function.prototype;
    onTouched = Function.prototype;

    // TODO: add webcam functionality to allow taking photos if a camera is available
    canUseCamera: boolean = true;
    openWebcam: boolean = false;
    isCompany: boolean;

    isMobile: boolean;
    showOptions: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private onboardingDialoguesService: OnboardingDialoguesService
    ) {}

    ngOnInit() {
        this.isCompany = this.noun === 'company logo';
        this.setMobile();
        window.addEventListener('resize', () => this.setMobile(), true);
        WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
                this.canUseCamera = mediaDevices && mediaDevices.length > 0;
            }
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setMobile() {
        if (window.innerWidth <= 767) {
            this.isMobile = true;
            this.showOptions = !this.hasLogo;
        } else {
            this.isMobile = false;
        }
    }

    hideOptions() {
        // allows open and close via tap on non-mobile mode
        if (!this.isMobile) {
            this.showOptions = false;
        }
    }

    selectLogo(logoFile: File) {
        this.hasLogo = true;
        this.showOptions = false;
        this.onChange(logoFile);
        this.setupPreview(logoFile);
    }

    takePhoto() {
        const cam = this.onboardingDialoguesService.showWebcamDialogue();
        this.openWebcam = true;

        cam.onHidden
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => (this.openWebcam = false));

        cam.content.image$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(image => {
                this.imgSrc = image;
                this.hasLogo = true;
                this.showOptions = false;
            });
    }

    setupPreview(logoFile: File) {
        this.onTouched();
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                this.imgSrc = reader.result;
            }
        };

        reader.readAsDataURL(logoFile);
        if (this.hasLogo === true) {
            this.showOptions = false;
        }
    }

    writeValue(value: any): void {
        if (value == null) {
            this.imgSrc = null;
            return;
        }

        this.setupPreview(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
