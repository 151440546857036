/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.33-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthorisationRuleOutcome } from './authorisationRuleOutcome';
import { FieldError } from './fieldError';
import { OperationAuthorisation } from './operationAuthorisation';
import { AirspaceCheckResponse } from './airspaceCheckResponse';
import { FailedValidation } from './failedValidation';


/**
 * 
 */
export interface AirspaceAuthorisationResponse { 
    airspaceCheckResponse: AirspaceCheckResponse;
    authorisationType: AirspaceAuthorisationResponse.AuthorisationType;
    failedValues: Array<FailedValidation>;
    authorisationServiceMessage?: string;
    authorisationServiceStatus?: AirspaceAuthorisationResponse.AuthorisationServiceStatus;
    /**
     * Errors, warnings, and information relating specifically to authorisations
     */
    authorisationRuleOutcomeList: Array<AuthorisationRuleOutcome>;
    /**
     * The operation\'s request ID, as opposed to the authorisation request ID. This should become operationId.
     */
    requestId?: string;
    authorisationList: Array<OperationAuthorisation>;
    /**
     * Request field errors.
     */
    errors: { [key: string]: FieldError; };
    validRequest: boolean;
}
export namespace AirspaceAuthorisationResponse {
    export type AuthorisationType = 'AUS_CASA_AUTHORISATION' | 'AUS_ASA_FIMS' | 'NZL_AIRSHARE' | 'USA_LAANC';
    export const AuthorisationType = {
        AUS_CASA_AUTHORISATION: 'AUS_CASA_AUTHORISATION' as AuthorisationType,
        AUS_ASA_FIMS: 'AUS_ASA_FIMS' as AuthorisationType,
        NZL_AIRSHARE: 'NZL_AIRSHARE' as AuthorisationType,
        USA_LAANC: 'USA_LAANC' as AuthorisationType
    };
    export type AuthorisationServiceStatus = 'AVAILABLE' | 'UNAVAILABLE';
    export const AuthorisationServiceStatus = {
        AVAILABLE: 'AVAILABLE' as AuthorisationServiceStatus,
        UNAVAILABLE: 'UNAVAILABLE' as AuthorisationServiceStatus
    };
}



