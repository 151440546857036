<div class="panel widget pilot">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-incident-report.png" />
        </div>
        <div class="panel-title">Incident Reports</div>

        <div class="panel-content"></div>

        <div class="panel-buttons"></div>
    </div>

    <div class="panel-body">
        <plan-highlight
            [featureFlags]="incidentReportsFeature"
        ></plan-highlight>
        <static-table
            *ngIf="incidentReports?.length > 0"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="incidentReports"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
        >
        </static-table>
        <empty
            *ngIf="incidentReports && incidentReports.length == 0"
            componentName="incident-reports-empty"
        >
        </empty>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
