import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    EquipmentDto,
    EquipmentService,
    FEATURE_EQUIPMENT,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    PreferencesService,
    WorkTracker,
    hasAnyPermission,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatEquipmentCategoryPipe } from '@flyfreely-portal-ui/resource-ui';
import {
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { EquipmentDialoguesService } from 'libs/equipment/src/lib/equipment-dialogues.service';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import { EMPTY, Subject, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { WidgetsPreferencesService } from '../widget-preferences.service';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';

export const EQUIPMENT_WIDGET_IDENTIFIER = 'equipment';

@Component({
    selector: 'equipment-widget',
    templateUrl: './equipment-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class EquipmentWidgetComponent implements OnInit, OnChanges, Widget {
    equipments: EquipmentDto[];
    availableColumns: TableColumn[];
    selectedColumns: string[];

    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    isLoading: boolean;
    tableConfig: TableConfig;
    IMG_URL: string;
    canAdd = false;

    equipmentFeature = [FEATURE_EQUIPMENT];

    organisation: PersonsOrganisationDto;

    public working: boolean;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    userPreferences: TableSetupUserPreferences;

    constructor(
        constants: FlyFreelyConstants,
        private equipmentService: EquipmentService,
        private preferencesService: PreferencesService,
        formatEquipmentCategoryPipe: FormatEquipmentCategoryPipe,
        private equipmentDialoguesService: EquipmentDialoguesService,
        private logging: FlyFreelyLoggingService,
        private workspaceStateService: WorkspaceStateService,
        private preferences: WidgetsPreferencesService
    ) {
        this.IMG_URL = constants.IMG_URL;
        this.availableColumns = [
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'equipmentCategory',
                name: 'Equipment Category',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                searchOptions: equipmentService.getEquipmentCategories(),
                formatterFunction: formatEquipmentCategoryPipe.transform
            },
            {
                value: 'equipmentModelMake',
                name: 'Equipment Make',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'equipmentModelModel',
                name: 'Equipment Model',
                searchable: true,
                selectable: true,
                defaultSelection: true
            },
            {
                value: 'manufacturerSerialNumber',
                name: 'Serial Number',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'currentFirmwareVersion',
                name: 'Current Firmware Version',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'id',
                name: 'ID',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'nfcId',
                name: 'NFC ID',
                searchable: true,
                selectable: true,
                defaultSelection: false
            }
        ];
        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };

        this.columnSorting = null;
        this.selectedColumns = [];
        this.tableSearch = null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('organisation' in changes) {
            this.refreshEquipments();
            this.loadPreferences();
        }
    }

    ngOnInit(): void {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshPermissions();
                this.refreshEquipments();
                this.loadPreferences();
            });

        this.equipmentService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshEquipments();
            });
    }

    private refreshPermissions() {
        this.canAdd = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.CRAFT_ADD
        );
    }

    refreshEquipments() {
        if (!this.organisation) {
            return;
        }

        this.equipmentService
            .find(this.organisation.id)
            .subscribe(
                results => {
                    this.equipments = results.map(e => ({
                        ...e,
                        equipmentCategory: e.equipmentType.equipmentCategory,
                        equipmentModelMake: e.equipmentType.make,
                        equipmentModelModel: e.equipmentType.model
                    }));
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing equipment: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    editEquipment(equipment: EquipmentDto) {
        this.equipmentDialoguesService.showEquipmentDetails(
            equipment.id,
            this.organisation,
            true
        );
    }

    addEquipment() {
        this.equipmentDialoguesService.addEquipment(this.organisation.id);
    }

    loadPreferences() {
        if (!this.organisation) {
            return;
        }

        this.preferences
            .findPreferences('equipmentListWidgetTable', this.organisation.id)
            .pipe(
                switchMap(preferences => {
                    if (preferences == null) {
                        return this.preferences
                            .findLegacyPreferences(
                                'equipmentListWidgetColumns',
                                this.organisation.id
                            )
                            .pipe(
                                map(pref => ({
                                    selectedColumns: pref,
                                    columnSorting: null,
                                    tableSearch: null,
                                    itemLimit: this.tableConfig.limit
                                })),
                                catchError(() => {
                                    this.handleDefaultPreferences();
                                    return of(null);
                                })
                            );
                    } else {
                        return of(preferences);
                    }
                }),
                catchError(() => {
                    this.selectedColumns = findDefaultColumnSelection(
                        this.availableColumns
                    );
                    return EMPTY;
                })
            )
            .subscribe(preferences => {
                this.applyPreferences(preferences);
            })
            .add(this.workTracker.createTracker);
    }

    private handleDefaultPreferences() {
        this.applyPreferences({});
    }

    private applyPreferences(preferences: TableSetupUserPreferences) {
        this.userPreferences = this.preferences.applyPreferences(
            preferences,
            this.availableColumns,
            this.tableConfig
        );

        this.selectedColumns = this.userPreferences.selectedColumns;
        this.columnSorting = this.userPreferences.columnSorting;
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit = this.userPreferences.itemLimit;
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'equipmentListWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
    }

    delete(item: EquipmentDto) {
        // this.adminJurisdictionService.delete(item.id);
    }
}

export const equipmentWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: EQUIPMENT_WIDGET_IDENTIFIER,
    component: EquipmentWidgetComponent,
    isAvailable: organisation =>
        hasFeatureFlag(organisation, FEATURE_EQUIPMENT) &&
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.CRAFT_LIST
        )
};
