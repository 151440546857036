<ng-container screenAnalytics="personnel-add">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-personnel.png" />
        </div>
        <h3 class="modal-title">Add Personnel</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="personInviteForm" (ngSubmit)="addInvitee()">
            <div style="min-height: 250px">
                <div class="form-group">
                    <label class="control-label top-buffer">
                        Users email address
                    </label>
                    <div class="input-group">
                        <input
                            type="text"
                            formControlName="email"
                            class="form-control"
                            placeholder="pilot@yourorg.com"
                            [readonly]="working || !canAdd"
                        />

                        <span
                            class="input-group-btn"
                            [tooltip]="
                                canAdd
                                    ? 'Add invitee'
                                    : 'Maximum number of licences reached'
                            "
                            placement="bottom"
                        >
                            <button
                                type="button"
                                class="btn btn-primary"
                                (click)="addInvitee()"
                                [disabled]="
                                    working ||
                                    !canAdd ||
                                    !personInviteForm.controls.email.valid ||
                                    personInviteForm.controls.email.value ==
                                        null ||
                                    personInviteForm.controls.email.value
                                        ?.length == 0
                                "
                            >
                                Add
                            </button>
                        </span>
                    </div>
                    <p class="help-block" *ngIf="!canAdd">
                        Maximum number of licences reached
                    </p>
                </div>
                <enhanced-help componentName="email" [compact]="true">
                </enhanced-help>
                <div *ngIf="invitees.length === 0" class="alert alert-info">
                    Add an email address above to get started
                </div>
                <ng-container *ngIf="invitees && invitees.length > 0">
                    <label
                        class="control-label top-buffer"
                        *ngIf="invitees?.length == 1"
                        >Invitee Email:</label
                    >
                    <label
                        class="control-label top-buffer"
                        *ngIf="invitees?.length > 1"
                        >Invitee Emails:</label
                    >
                    <div
                        *ngFor="let invitee of invitees; let index = i"
                        class="contents container-fluid"
                    >
                        <span>
                            {{ invitee }}
                            <button
                                type="button"
                                class="btn-tertiary btn-delete btn-sm pull-right"
                                tooltip="Remove email from invite list"
                                [disabled]="working"
                                (click)="deleteInvitee(invitee)"
                            >
                                <span class="fal fa-trash-alt"></span>
                            </button>
                        </span>
                    </div>
                    <enhanced-help componentName="email-list" [compact]="true">
                    </enhanced-help>
                </ng-container>
            </div>
            <div class="form-group" *ngIf="availableSubscriptions?.length > 1">
                <label>Subscription to assign licences from</label>
                <ng-select
                    [items]="availableSubscriptions"
                    [readonly]="working || !canAdd"
                    [clearable]="false"
                    bindValue="id"
                    appendTo="body"
                    formControlName="selectedSubscriptionId"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <span
                            [tooltip]="
                                item.externalIdentifier
                                    ? null
                                    : 'This is a custom subscription'
                            "
                            placement="top"
                        >
                            {{ item.featureSet.name }}&nbsp;&lbrack;{{
                                item.startTime | formatDate
                            }}&rbrack;
                        </span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.featureSet.name }}&nbsp;&lbrack;{{
                            item.startTime | formatDate
                        }}&rbrack;{{
                            item.externalIdentifier ? '' : ' - Custom'
                        }}
                    </ng-template>
                </ng-select>
                <enhanced-help
                    componentName="subscription-select"
                    [compact]="true"
                >
                </enhanced-help>
            </div>
            <h4>Organisation Specific Roles</h4>
            <enhanced-help componentName="organisation-roles" [compact]="true">
            </enhanced-help>
            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <personnel-roles
                        [formGroup]="personInviteForm.controls.roles"
                        [allowStudent]="allowStudent"
                        [allowReadOnly]="allowReadOnly"
                        [allowInstantMission]="allowInstantMission"
                        [allowFlightLogCollection]="allowFlightLogCollection"
                        [allowReadOnlyHidden]="allowReadOnlyHidden"
                        [allowExternalMaintenancePerson]="allowExternalMaintenancePerson"
                        [pilotLimitReached]="pilotLimitReached"
                        [totalPilots]="totalPilots"
                        [availablePilots]="availablePilots"
                        [showHelp]="false"
                        [multipleSubscriptions]="
                            availableSubscriptions.length > 0
                        "
                    ></personnel-roles>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button
            *ngIf="canBulkUpload"
            class="btn btn-default pull-left"
            type="button"
            (click)="bulkUpload()"
            [disabled]="
                working ||
                !canAdd ||
                (availablePilots != null && availablePilots <= 0)
            "
        >
            Bulk Upload
        </button>
        <button
            class="btn btn-primary"
            type="button"
            (click)="invite()"
            [disabled]="
                invitees?.length < 1 ||
                personInviteForm.invalid ||
                working ||
                overLimits
            "
        >
            <span
                [tooltip]="
                    overLimits
                        ? 'Invite list exceeds maximum available licences'
                        : invitees?.length < 1
                        ? 'Add email addresses of people to invite'
                        : ''
                "
                placement="left"
            >
                Send Invitations
            </span>
        </button>
    </div>
</ng-container>
