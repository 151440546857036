<form
    [formGroup]="equipmentForm"
    novalidate
    class="container-with-footer"
    (ngSubmit)="saveEquipment()"
>
    <div class="container-fluid">
        <div class="form-group top-buffer">
            <label>Equipment Category</label>
            <field-validation-indicator
                validatorFormControlName="equipmentCategory"
                [noun]="'field'"
                [placement]="'left'"
                class="pull-right"
            ></field-validation-indicator>
            <ng-select
                [items]="equipmentCategories"
                [readonly]="working"
                bindValue="value"
                bindLabel="name"
                appendTo="body"
                (change)="onEquipmentTypeSelectionChange($event)"
                (clear)="clearEquipmentType($event)"
                formControlName="equipmentCategory"
            >
            </ng-select>
        </div>
        <enhanced-help
            screenName="equipment-edit"
            componentName="equipmentCategory"
            [compact]="true"
        >
        </enhanced-help>

        <div class="horizontal-container">
            <div class="form-group top-buffer" formGroupName="make">
                <field-validation-indicator
                    class="pull-right"
                    [noun]="'field'"
                    [placement]="'left'"
                    validatorFormControlName=""
                ></field-validation-indicator>
                <label>Equipment Make</label>
                <ng-select
                    [items]="equipmentMakes"
                    bindValue="value"
                    bindLabel="name"
                    [addTag]="true"
                    addTagText="equipmentModelId"
                    appendTo="body"
                    (change)="equipmentMakeOnChange($event)"
                    formControlName="makeSelect"
                >
                </ng-select>

                <div *ngIf="!makeListed">
                    <input
                        [readonly]="makeListed"
                        type="text"
                        class="form-control"
                        formControlName="makeText"
                        placeholder="Type the Equipment Make here..."
                    />
                </div>
            </div>
            <div class="form-group top-buffer" formGroupName="model">
                <field-validation-indicator
                    class="pull-right"
                    [noun]="'field'"
                    [placement]="'left'"
                    validatorFormControlName=""
                ></field-validation-indicator>
                <label>Equipment Model</label>
                <ng-select
                    [items]="equipmentModels"
                    bindValue="value"
                    bindLabel="name"
                    appendTo="body"
                    (change)="equipmentModelOnChange($event)"
                    formControlName="modelSelect"
                >
                </ng-select>
                <div *ngIf="!modelListed">
                    <input
                        [readonly]="modelListed"
                        type="text"
                        class="form-control"
                        formControlName="modelText"
                        placeholder="Type the Equipment Model here..."
                    />
                </div>
            </div>
        </div>
        <enhanced-help
            screenName="equipment-edit"
            componentName="makeModel"
            [compact]="true"
        >
        </enhanced-help>

        <div class="form-group top-buffer">
            <label>Name/Designation</label>
            <field-validation-indicator
                validatorFormControlName="name"
                [noun]="'field'"
                [placement]="'left'"
                class="pull-right"
            ></field-validation-indicator>
            <input class="form-control" formControlName="name" />
        </div>
        <enhanced-help
            screenName="equipment-edit"
            componentName="name"
            [compact]="true"
        >
        </enhanced-help>

        <div class="form-group top-buffer">
            <label>Manufacturer Serial Number</label>
            <field-validation-indicator
                validatorFormControlName="manufacturerSerialNumber"
                [noun]="'field'"
                [placement]="'left'"
                class="pull-right"
            ></field-validation-indicator>
            <input
                class="form-control"
                formControlName="manufacturerSerialNumber"
            />
        </div>
        <enhanced-help
            screenName="equipment-edit"
            componentName="manufacturerSerialNumber"
            [compact]="true"
        >
        </enhanced-help>

        <div class="form-group top-buffer">
            <label>Current Firmware Version</label>
            <field-validation-indicator
                validatorFormControlName="currentFirmwareVersion"
                [noun]="'field'"
                [placement]="'left'"
                class="pull-right"
            ></field-validation-indicator>
            <input
                type="text"
                formControlName="currentFirmwareVersion"
                class="form-control"
                maxlength="255"
            />
        </div>
        <enhanced-help
            screenName="equipment-edit"
            componentName="currentFirmwareVersion"
            [compact]="true"
        >
        </enhanced-help>

        <div class="form-group top-buffer">
            <label>NFC UID</label>
            <field-validation-indicator
                validatorFormControlName="nfcId"
                [noun]="'field'"
                [placement]="'left'"
                class="pull-right"
            ></field-validation-indicator>
            <input class="form-control" formControlName="nfcId" />
        </div>
        <enhanced-help
            screenName="equipment-edit"
            componentName="nfcId"
            [compact]="true"
        >
        </enhanced-help>

        <div class="form-group top-buffer">
            <div>
                <label>Equipment Classification</label>
                <field-validation-indicator
                    validatorFormControlName="isDummy"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <lockable
                    [isLocked]="lockedFields?.isDummy"
                    message="This equipment has been used on a real mission so can't be made dummy"
                >
                    <ng-template lockedMode>
                        {{ equipment.isDummy ? 'Dummy' : 'Real' }}
                        Equipment
                    </ng-template>
                    <ng-template unlockedMode
                        ><div
                            class="input-group"
                            btnRadioGroup
                            formControlName="isDummy"
                            style="padding-top: 5px"
                        >
                            <label
                                class="btn btn-xs btn-pill"
                                [btnRadio]="false"
                                [disabled]="working"
                                >Real Equipment</label
                            >
                            <label
                                class="btn btn-xs btn-pill"
                                [btnRadio]="true"
                                [disabled]="working"
                                >Dummy Equipment</label
                            >
                        </div></ng-template
                    >
                </lockable>

                <validation-messages validatorFormControlName="isDummy">
                </validation-messages>
            </div>
        </div>
        <enhanced-help
            screenName="equipment-edit"
            componentName="isDummy"
            [compact]="true"
        >
        </enhanced-help>
    </div>

    <div class="action-container">
        <div class="pull-right">
            <button
                type="button"
                class="btn btn-default"
                style="margin-right: 0.5rem"
                (click)="cancel()"
            >
                Cancel
            </button>
            <button
                class="btn btn-primary"
                type="submit"
                [disabled]="equipmentForm.invalid || working"
            >
                Save
            </button>
        </div>
    </div>
</form>
