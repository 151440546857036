import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {
    AcknowledgementDto,
    AttachmentHandler,
    CurrentAttachmentVersionDto,
    DownloadService,
    DownloadableAttachmentVersionDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameValue,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttachmentHistory } from '../attachment-history/attachment-history.component';
import { DocumentPreviewDialogueComponent } from '../document-preview/document-preview-dialogue.component';
import {
    AttachmentViewService,
    DocumentPreviewPayload
} from '../documentPreview.service';

interface EditableAttachment extends CurrentAttachmentVersionDto {
    file: File;
    uploadProgress?: number;
    reuploadMode: boolean;
    reuploadChanges: string;
    queued: boolean;
    editMode: boolean;
}

interface AcknowledgementLookup {
    [attachmentVersionId: number]: string;
}

function removeExtension(filename: string) {
    const ix = filename.lastIndexOf('.');
    // Don't unintentionally remove non-extensions
    if (filename.length - ix > 5) {
        return filename;
    }

    return filename.substring(0, ix);
}

function asEditableAttachment(
    attachment: DownloadableAttachmentVersionDto
): EditableAttachment {
    return {
        ...attachment,
        file: null,
        reuploadChanges: '',
        reuploadMode: false,
        queued: false,
        editMode: false
    };
}

@Component({
    selector: 'attachment-list',
    templateUrl: './attachments-list.component.html',
    styleUrls: ['./attachments-list.component.scss'],
    styles: [
        `
            :host {
                position: relative;
                display: block;
                min-height: 50px;
            }
        `
    ]
})
export class AttachmentList implements OnInit, OnChanges, OnDestroy {
    @Input() attachmentsHandler: AttachmentHandler;
    @Input() allowUploads: boolean;
    @Input() allowDelete: boolean;
    @Input() allowEdit: boolean;
    @Input() allowAcknowledgements: boolean;
    @Input() removeExtension: boolean;
    @Input() parentPromiseTracker: any;
    @Input() requestingOrganisationId: number;
    @Input() hideHeadings: boolean;
    @Input() requirementId: number;
    @Input() allowReuploads: boolean;
    @Input() disabled = false;
    @Input() newTemplate: boolean = false;

    private originalAttachments: CurrentAttachmentVersionDto[];

    attachments: EditableAttachment[];
    uploadedFile: any;

    canPreview: { [attachmentId: number]: boolean };

    canRequireAcknowledgement: boolean;
    baseDropValid: boolean;
    hasVersionHistory = false;

    uploadError: string;
    purposes: NameValue[];
    purposesOnly: boolean;
    purposeLookup: { [value: string]: string };
    acknowledgements: AcknowledgementLookup;

    files: File[];

    downloadAllUrl: string;
    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    allowDownload = true;

    error: string;
    constructor(
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        private modalService: BsModalService,
        private changeDetector: ChangeDetectorRef,
        private attachmentViewService: AttachmentViewService,
        private downloadService: DownloadService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                this.changeDetector.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('attachmentsHandler' in changes) {
            if (this.attachmentsHandler != null) {
                this.canRequireAcknowledgement =
                    this.attachmentsHandler.getCanRequireAcknowledgement();
                this.hasVersionHistory =
                    this.attachmentsHandler.hasVersionHistory;
                this.downloadAllUrl =
                    this.attachmentsHandler.getDownloadAllUrl();
                this.attachments = null;
                this.originalAttachments = null;

                this.attachmentsHandler.attachments$
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(attachments => {
                        this.originalAttachments = attachments;
                        // .filter(
                        //     a => a.requirementId === this.requirementId
                        // );

                        this.setupAttachments();
                        this.refreshAcknowledgements();
                    });
            } else {
                this.canRequireAcknowledgement = null;
                this.downloadAllUrl = null;
                this.attachments = null;
                this.originalAttachments = null;
            }

            this.changeDetector.markForCheck();
        }
        if (
            'allowEdit' in changes ||
            'allowDelete' in changes ||
            'allowUploads' in changes ||
            'disabled' in changes ||
            'allowReuploads' in changes
        ) {
            this.setupAttachments();
        }

        if ('parentPromiseTracker' in changes) {
            this.workTracker.setParentTracker(this.parentPromiseTracker);
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workTracker.ngOnDestroy();
    }

    refreshAcknowledgements() {
        if (this.attachmentsHandler == null) {
            this.attachments = [];
            return;
        }

        this.attachmentsHandler.findAcknowledgements().then(
            acknowledgements =>
                (this.acknowledgements = acknowledgements.reduce(
                    (acc: AcknowledgementLookup, a) => ({
                        ...acc,
                        [a.attachmentVersionId]: a.modificationTime
                    }),
                    {}
                )),
            (error: FlyFreelyError) => {
                this.logging.error(error);
            }
        );
    }

    private setupAttachments() {
        if (this.originalAttachments == null) {
            this.attachments = null;
            return;
        }

        const grouper = (
            acc: { [key: string]: any },
            a: CurrentAttachmentVersionDto
        ) => {
            if (a.purpose in acc) {
                return {
                    ...acc,
                    [a.purpose]: acc[a.purpose].concat(a)
                };
            }
            return {
                ...acc,
                [a.purpose]: [a]
            };
        };

        const groupByPurpose = this.originalAttachments
            .filter(a => a.purpose != null)
            .reduce(grouper, {});

        const attachmentsWithPurpose =
            this.purposes == null
                ? []
                : this.purposes.reduce(
                      (acc, p) =>
                          acc.concat(
                              p.value in groupByPurpose
                                  ? groupByPurpose[p.value]
                                  : [],
                              this.allowUploads
                                  ? [{ name: p.name, purpose: p.value }]
                                  : []
                          ),
                      []
                  );

        this.attachments = attachmentsWithPurpose.concat(
            this.originalAttachments.filter(
                a =>
                    (a.purpose == null || a.purpose === '') &&
                    (a.requirementId === this.requirementId ||
                        (a.requirementId == null && this.requirementId == null))
            )
        );

        if (this.attachments != null) {
            this.refreshCanPreview();
        } else {
            this.canPreview = {};
        }

        this.purposeLookup =
            this.purposes != null
                ? this.purposes.reduce(
                      (acc, p) => ({ ...acc, [p.value]: p.name }),
                      {}
                  )
                : {};

        this.changeDetector.markForCheck();
    }

    private refreshCanPreview() {
        if (this.attachments == null) {
            return;
        }

        this.canPreview = this.attachments.reduce(
            (acc: { [attachmentId: number]: boolean }, att) => {
                acc[att.id] =
                    att.contentType != null &&
                    att.contentType.startsWith('image/');
                return acc;
            },
            {}
        );
    }

    filesSelected(files: File[]) {
        if (files.length !== 0) {
            const newAttachments = files.map(
                f =>
                    ({
                        name: removeExtension(f.name),
                        file: f,
                        reuploadMode: false,
                        uploadTime: null,
                        id: null,
                        attachmentVersionId: null,
                        queued: true,
                        requirementId: this.requirementId
                            ? this.requirementId
                            : null
                    } as EditableAttachment)
            );

            this.attachments = this.attachments.concat(newAttachments);
            this.uploadNext(newAttachments);
            this.files.length = 0;
        }
    }

    storeAttachment(attachment: EditableAttachment, file: any) {
        if (file && (attachment.name == null || attachment.name.length === 0)) {
            attachment.name = removeExtension(file.name);
        }

        this.uploadedFile = file;
    }

    hideReuploadAttachment(attachment: EditableAttachment) {
        attachment.uploadProgress = null;
        this.uploadedFile = null;
        attachment.reuploadMode = false;
        attachment.changes = '';
    }

    /**
     * This does not use a work tracker as we allow rows to independently perform work, so
     * we hold the state at that level.
     *
     * @param attachment the attachment being updated
     */
    reuploadAttachment(attachment: EditableAttachment) {
        this.error = null;

        attachment.uploadProgress = 0;

        this.attachmentsHandler
            .reuploadAttachment(
                attachment.id,
                this.uploadedFile,
                attachment.reuploadChanges,
                (progress: ProgressEvent) =>
                    (attachment.uploadProgress = Math.floor(
                        (progress.loaded * 100) / progress.total
                    ))
            )
            .then(
                () => {
                    this.logging.success('A new version was created!');
                    this.attachments = this.attachments.map(a =>
                        a === attachment
                            ? ({
                                  ...a,
                                  contentType: this.uploadedFile.type,
                                  reuploadMode: false,
                                  uploadProgress: null,
                                  uploadTime: moment().utc().toISOString()
                              } as EditableAttachment)
                            : a
                    );
                    this.hideReuploadAttachment(attachment);
                },
                () => {
                    this.error = 'Error uploading the file!';
                    this.hideReuploadAttachment(attachment);
                }
            );
    }

    private uploadNext(attachments: EditableAttachment[]) {
        if (attachments.length === 0) {
            return;
        }
        const nextAttachment = attachments[0];
        this.uploadAttachment(
            nextAttachment,
            nextAttachment.file,
            nextAttachment
        ).then(() => this.uploadNext(attachments.slice(1)));
    }

    private uploadAttachment(
        updatedAttachment: EditableAttachment,
        file: File,
        originalAttachment: EditableAttachment
    ) {
        const {
            name,
            description,
            purpose,
            requiresAcknowledgement,
            requirementId,
            changes
        } = updatedAttachment;
        this.error = null;

        originalAttachment.uploadProgress = 0;
        const doneWorking = this.workTracker.createTracker();
        return this.attachmentsHandler
            .uploadNewAttachment(
                name,
                description,
                requirementId,
                file,
                purpose,
                requiresAcknowledgement || false,
                (progress: ProgressEvent) =>
                    (originalAttachment.uploadProgress = Math.floor(
                        (progress.loaded * 100) / progress.total
                    ))
            )
            .then(uploadedAttachment => {
                this.logging.success('File uploaded!');
                this.attachments = this.attachments.map(a =>
                    a === originalAttachment
                        ? asEditableAttachment(uploadedAttachment)
                        : a
                );
                doneWorking();
            })
            .catch(() => {
                this.error = 'Error uploading the file!';
                doneWorking();
            })
            .finally(() => {
                originalAttachment.uploadProgress = null;
                doneWorking();
            });
    }

    acknowledgeAttachment(attachment: CurrentAttachmentVersionDto) {
        const acknowledgeAttachment = () =>
            this.attachmentsHandler
                .acknowledgeAttachment(
                    attachment.id,
                    attachment.attachmentVersionId
                )
                .toPromise();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Acknowledge Compliance',
                `<p>I hereby acknowledge that I have received the instructions, procedures and data contained in the ${attachment.name}.</p>

                <p>I also understand that the contents of this ${attachment.name} have been devised to ensure the safety and standardisation of
                operations.</p>
                <p>I agree to abide by the instructions contained in the ${attachment.name} at all times.</p>`,
                'Acknowledge',
                () => Promise.resolve()
            )
            .then(() => {
                const doneWorking = this.workTracker.createTracker();
                acknowledgeAttachment()
                    .then((acknowledge: AcknowledgementDto) => {
                        this.logging.success(
                            `${attachment.name} Acknowledged.`
                        );
                        this.acknowledgements[attachment.attachmentVersionId] =
                            acknowledge.modificationTime.toString();
                        doneWorking();
                    })
                    .catch((error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Acknowledging attachment failed: ${error.message}`
                        );
                        doneWorking();
                    });
            });
    }

    showContent(payload: DocumentPreviewPayload) {
        if (
            payload.contentType.startsWith('video') ||
            payload.contentType.startsWith('image') ||
            payload.contentType.startsWith('text') ||
            payload.contentType.startsWith('application/pdf')
        ) {
            this.attachmentViewService.dispatchDocumentPreviewMessage(payload);
            this.modalService.show(DocumentPreviewDialogueComponent, {
                class: 'modal-full'
            });
        } else {
            this.downloadService.downloadFromUrl(payload.downloadUrl);
        }
    }

    showAttachmentHistory(attachment: CurrentAttachmentVersionDto) {
        const modal = this.modalService.show<AttachmentHistory>(
            AttachmentHistory,
            {
                ...MODAL_OPTIONS,
                class: 'modal-task',
                initialState: {
                    attachment,
                    attachmentsHandler: this.attachmentsHandler,
                    organisationId: this.requestingOrganisationId,
                    canDownload: this.allowDownload
                }
            }
        );
    }

    deleteAttachment(attachment: CurrentAttachmentVersionDto) {
        const deleteAttachment = () =>
            this.attachmentsHandler
                .deleteAttachment(attachment.id)
                .toPromise()
                .then(() => {
                    this.attachments = this.attachments.filter(
                        a => a !== attachment
                    );
                });

        this.commonDialoguesService.showConfirmationDialogue(
            'Delete Attachment',
            'Are you sure you wish to delete this attachment?',
            'Delete',
            deleteAttachment
        );
    }

    isSupportedFile(contentType: string): boolean {
        if (
            !contentType.startsWith('video') &&
            !contentType.startsWith('image') &&
            !contentType.startsWith('text') &&
            !contentType.startsWith('application/pdf')
        ) {
            return;
        }
    }

    updateAttachment(attachment: any, ix: number) {
        const id = attachment.id;
        const { name, description, requiresAcknowledgement } = attachment;
        attachment.editMode = false;
        attachment.reuploadMode = false;
        attachment.queued = false;

        const doneWorking = this.workTracker.createTracker();
        this.attachmentsHandler
            .updateAttachmentDetails(
                id,
                { name, description: description || '' },
                requiresAcknowledgement || false
            )
            .toPromise()
            .then(() => {
                doneWorking();
            });
    }

    getFileIcon(contentType: string): string {
        switch (contentType) {
            case 'application/pdf':
                return 'fas fa-file-pdf icon-red';
            case 'text/plain':
                return 'fas fa-file-alt icon-red';
            case 'image/jpeg':
            case 'image/png':
                return 'fas fa-file-image icon-red';
            case 'video/mp4':
            case 'video/mpeg':
                return 'fas fa-file-video icon-red';
            default:
                return 'fas fa-file icon-red';
        }
    }

    setAllowDownload(allowDownload: boolean) {
        this.allowDownload = allowDownload;
        this.changeDetector.detectChanges();
    }
}
