<div class="container-fluid" *ngIf="maintenanceLog">
    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Maintenance Request ID</div>
        </div>
        <div class="col-xs-6">
            {{ maintenanceLog.id }}
        </div>
        <div class="col-xs-3"></div>
    </div>
    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Maintenance Type</div>
        </div>
        <div class="col-xs-6">
            {{ maintenanceLog.maintenanceLogType | formatMaintenanceLogType }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Resource Category</div>
        </div>
        <div class="col-xs-6">
            {{
                maintenanceLog.resourceCategory
                    | formatMaintenanceEquipmentCategory
            }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <ng-container *ngIf="maintenanceLog.resourceCategory === 'CRAFT'">
            <div class="col-xs-3 text-right display-label">RPA</div>
            <div class="col-xs-6">
                <a (click)="showEquipment()">
                    {{ resourceName }}
                </a>
            </div>
        </ng-container>
        <ng-container *ngIf="maintenanceLog.resourceCategory === 'BATTERY'">
            <div class="col-xs-3 text-right display-label">Battery</div>
            <div class="col-xs-6">
                <a (click)="showEquipment()"> {{ resourceName }}</a>
            </div>
        </ng-container>
        <ng-container *ngIf="maintenanceLog.resourceCategory === 'BATTERY_SET'">
            <div class="col-xs-3 text-right display-label">Battery Set</div>
            <div class="col-xs-6">
                <a (click)="showEquipment()"> {{ resourceName }}</a>
            </div>
        </ng-container>
        <ng-container *ngIf="maintenanceLog.resourceCategory === 'EQUIPMENT'">
            <div class="col-xs-3 text-right display-label">Equipment</div>
            <div class="col-xs-6">
                <a (click)="showEquipment()"> {{ resourceName }}</a>
            </div>
        </ng-container>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Reason for Request</div>
        </div>
        <div class="col-xs-6">
            {{ maintenanceLog.reasonForRequest }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Safe to Use</div>
        </div>
        <div class="col-xs-6">
            {{ maintenanceLog.safeToUse ? 'Yes' : 'No' }}
        </div>
        <div class="col-xs-3"></div>
    </div>
    <div [formGroup]="form" *ngIf="overallStatus !== 'COMPLETED'">
        <div *ngIf="hasMaintenanceNotices" class="form-group input-content">
            <div class="col-xs-3 text-right">
                <label class="control-label">
                    Maintenance Identifier
                    <field-validation-indicator
                        [control]="form.controls.identifier"
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <div class="input-group" style="padding-top: 7px">
                    <editable
                        #identifier
                        [confirmationButtons]="true"
                        [cancelOnClickOutside]="false"
                        [updateFn]="updateIdentifierAndWorkInstructions"
                        (clickCancel)="revertIdentifier()"
                    >
                        <ng-template viewMode>
                            {{ form.controls.identifier.value || '(None)' }}
                        </ng-template>
                        <ng-template editMode>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="identifier"
                                editableOnEnter
                            />
                        </ng-template>
                    </editable>
                    <validation-messages
                        [control]="form.controls.identifier"
                    ></validation-messages>
                </div>
            </div>
            <div class="col-xs-3"></div>
        </div>
        <enhanced-help componentName="maintenanceIdentifier"> </enhanced-help>
        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <label class="control-label">
                    Work Instructions
                    <field-validation-indicator
                        [control]="form.controls.workInstructions"
                        [noun]="'field'"
                        [placement]="'right'"
                    ></field-validation-indicator>
                </label>
            </div>
            <div class="col-xs-6">
                <div class="input-group" style="padding-top: 7px">
                    <editable
                        #workInstructions
                        [confirmationButtons]="true"
                        [cancelOnClickOutside]="false"
                        [editButton]="true"
                        [updateFn]="updateIdentifierAndWorkInstructions"
                        (clickCancel)="revertWorkInstructions()"
                    >
                        <ng-template viewMode>
                            <span
                                [innerHTML]="
                                    form.controls.workInstructions.value
                                        | autolink
                                "
                            ></span>
                            <i
                                *ngIf="form.controls.workInstructions.value"
                                class="fa fa-arrow-up-right-from-square left-buffer"
                                tooltip="Link will open in a new tab. External links are not under FlyFreely’s control."
                                placement="top"
                            >
                            </i>
                        </ng-template>
                        <ng-template editMode>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="workInstructions"
                                editableOnEnter
                            />
                        </ng-template>
                    </editable>
                </div>
            </div>
            <div class="col-xs-3"></div>
        </div>
        <enhanced-help componentName="maintenanceWorkInstructions">
        </enhanced-help>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Request Status</div>
        </div>
        <div class="col-xs-6">
            {{ combinedStatus | formatMaintenanceStatus }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Request Date</div>
        </div>
        <div class="col-xs-6">
            {{ maintenanceLog.requestTime.toString() | formatDate }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Requested By</div>
        </div>
        <div class="col-xs-6">
            {{ maintenanceLog.requester | formatPerson }}
        </div>
        <div class="col-xs-3"></div>
    </div>
</div>
