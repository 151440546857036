import { Pipe, PipeTransform } from '@angular/core';
import {
    ReferencedAuthorityDto,
    SimpleAuthorityDto
} from '@flyfreely-portal-ui/flyfreely';
import { FormatDatePipe } from '../generic/formatDate';

@Pipe({ name: 'formatAuthority' })
export class FormatAuthorityPipe implements PipeTransform {
    constructor(private formatDate: FormatDatePipe) {}

    transform(
        authority: SimpleAuthorityDto | ReferencedAuthorityDto,
        showDateRange: boolean = true
    ) {
        if (
            authority == null ||
            !(authority instanceof Object) ||
            authority.authorityType == null ||
            authority.organisation == null
        ) {
            return null;
        }

        const authorityName =
            authority.authorityType.abbreviation != null
                ? authority.authorityType.abbreviation
                : authority.authorityType.name;

        const identifier =
            authority.identifier != null &&
            authority.authorityType.hasIdentifier
                ? `${authority.authorityType.identifierLabel} ${authority.identifier}`
                : '';

        const dateRange =
            this.formatDate.transform(authority.startDate) +
            (authority.expiryDate != null
                ? ` - ${this.formatDate.transform(authority.expiryDate)}`
                : '');

        return showDateRange
            ? `${authority.organisation.name}'s ${authorityName} ${identifier} (${dateRange})`
            : `${authority.organisation.name}'s ${authorityName} ${identifier}`;
    }
}
