import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    BatteryDto,
    BatteryService,
    BatteryTypeService,
    Cancellable,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LockedBatteryFields,
    PersonsOrganisationDto,
    UpdateBatteryCommand,
    WorkTracker,
    fromLocalDate,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { ModelItem } from 'libs/rpa/src/lib/details/rpa-edit/rpa-edit.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { batteryFields } from '../fields';

@Component({
    selector: 'battery-edit',
    templateUrl: './battery-edit.component.html'
})
export class BatteryEdit implements OnInit, OnDestroy, Cancellable {
    @Input() organisation: PersonsOrganisationDto;
    @Input() battery: BatteryDto;

    @Output() updated = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();
    editBattery: any;

    validationFields: FormlyFieldConfig[] = batteryFields;

    private workTracker = new WorkTracker();
    working: boolean = false;

    batteryForm: FormGroup;
    canRender: boolean;
    batteryTypeItems: ModelItem[];

    canUseNfc: boolean = false;

    lockedFields: LockedBatteryFields;

    private ngUnsubscribe$: Subject<void> = new Subject();
    constructor(
        private batteryTypeService: BatteryTypeService,
        private batteryService: BatteryService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.batteryTypeItems = [];
        this.editBattery = {
            ...this.battery,
            batteryTypeId:
                this.battery.batteryTypeId != null
                    ? this.battery.batteryTypeId.toString()
                    : null,
            purchaseDate: fromLocalDate(this.battery.purchaseDate)
        };
        this.refreshBatteryTypes();
        this.lockedFields = this.battery.lockedFields;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshBatteryTypes() {
        this.batteryTypeService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                batteryTypes => {
                    this.canRender = true;
                    batteryTypes.forEach(item => {
                        const name = `${item.make} ${item.model}`;
                        const _item = {
                            id: item.id,
                            name: name
                        };
                        this.batteryTypeItems.push(_item);
                    });
                    this.initForm();
                },
                error => this.logging.error(error)
            )
            .add(this.workTracker.createTracker());
    }

    initForm() {
        this.batteryForm = new FormGroup({
            isDummy: new FormControl(this.editBattery.isDummy),
            name: new FormControl(this.editBattery.name, [Validators.required]),
            purchaseDate: new FormControl(
                this.editBattery.purchaseDate
                    ? fromLocalDate(this.editBattery.purchaseDate)
                    : null,
                [Validators.required]
            ),
            manufacturerSerialNumber: new FormControl(
                this.editBattery.manufacturerSerialNumber
            ),
            nfcUid: new FormControl(this.editBattery.nfcUid),
            batteryTypeId: new FormControl(
                parseInt(this.editBattery.batteryTypeId, 10),
                [Validators.required]
            ),
            initialFlightTime: new FormControl(this.battery.initialFlightTime, [
                Validators.required
            ]),
            initialCycles: new FormControl(this.battery.initialCycles, [
                Validators.required
            ])
        });
    }

    saveBattery() {
        const payload: UpdateBatteryCommand = {
            name: this.name.value,
            manufacturerSerialNumber: this.manufacturerSerialNumber.value,
            nfcUid: this.nfcUid.value,
            purchaseDate: toLocalDate(this.purchaseDate.value),
            batteryTypeId: parseInt(this.batteryTypeId.value, 10),
            isDummy: this.isDummy.value || false,
            initialCycles: this.initialCycles.value
                ? this.initialCycles.value
                : 0,
            initialFlightTime: this.initialFlightTime.value
                ? this.initialFlightTime.value
                : 0,
            initialComponentList: []
        };
        this.batteryService
            .updateBattery(this.editBattery.id, payload)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                (battery: any) => {
                    this.logging.success(`Battery updated`);
                    this.updated.emit(battery);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error saving battery: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancelEdit() {
        this.cancelled.emit();
    }

    cancel() {
        if (this.batteryForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    get isDummy() {
        return this.batteryForm.get('isDummy');
    }

    get batteryTypeId() {
        return this.batteryForm.get('batteryTypeId');
    }

    get name() {
        return this.batteryForm.get('name');
    }

    get purchaseDate() {
        return this.batteryForm.get('purchaseDate');
    }

    get manufacturerSerialNumber() {
        return this.batteryForm.get('manufacturerSerialNumber');
    }

    get nfcUid() {
        return this.batteryForm.get('nfcUid');
    }

    get initialFlightTime() {
        return this.batteryForm.get('initialFlightTime');
    }

    get initialCycles() {
        return this.batteryForm.get('initialCycles');
    }
}
