import { Component } from '@angular/core';
import { airspaceAuthorisationsRequiringDataManagement } from '@flyfreely-portal-ui/airspace';
import {
    ExclusiveControlService,
    LoggedInUser,
    UserService,
    UserStatus,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { NotificationSettingsService } from './notification-settings/notification-settings.service';

@Component({
    selector: 'profile-dialogue',
    templateUrl: './profile-dialogue.component.html',
    providers: [ExclusiveControlService, NotificationSettingsService]
})
export class ProfileDialogue {
    currentUser$ = this.userService.userChange$.pipe(
        filter(c => c.type === UserStatus.LOGGED_IN),
        map((change: LoggedInUser) => change.currentUser),
        startWith(this.userService.getCurrentUser())
    );
    private ngUnsubscribe$ = new Subject<void>();

    private workTracker = new WorkTracker();

    working = false;

    locked = false;

    editingHistoricalFlights = false;

    hasApiKeyOrganisations = false;

    hasNotamOrganisations = false;

    hasAirspaceData = false;

    constructor(
        private modal: BsModalRef<ProfileDialogue>,
        modalOptions: ModalOptions,
        private userService: UserService,
        private exclusiveControlService: ExclusiveControlService,
        private commonDialoguesService: CommonDialoguesService
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.locked === true) {
                return this.commonDialoguesService
                    .showConfirmationDialogue(
                        'Confirm Cancel',
                        `You have unsaved changes, are you sure you want to cancel?`,
                        'Yes',
                        () => Promise.resolve()
                    )
                    .then(() => {
                        this.exclusiveControlService.lock(() => true);
                        this.exclusiveControlService.requestUnlock();
                    });
            } else {
                return Promise.resolve();
            }
        };
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refreshFeatureFlags();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshFeatureFlags() {
        const userOrgs = this.userService.findUsersOrganisations();
        this.hasApiKeyOrganisations =
            userOrgs.filter(org => org.featureFlags.includes('apiKeys')) !=
                null &&
            userOrgs.filter(org => org.featureFlags.includes('apiKeys'))
                .length > 0;
        this.hasNotamOrganisations =
            userOrgs.filter(org => org.featureFlags.includes('notams')) !=
                null &&
            userOrgs.filter(org => org.featureFlags.includes('notams')).length >
                0;
        this.hasAirspaceData = userOrgs.some(org =>
            org.activeJurisdictions.some(jurisdiction =>
                airspaceAuthorisationsRequiringDataManagement.includes(
                    jurisdiction.identifier
                )
            )
        );
    }

    close() {
        this.modal.hide();
    }

    onEditHistoricalFlights() {
        this.editingHistoricalFlights = true;
    }

    onDoneEditingHistoricalFlights() {
        this.editingHistoricalFlights = false;
    }
}
