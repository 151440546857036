<div *ngIf="attachment$ | async as attachment">
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            <span [class]="getFileIcon()"></span>
            {{ attachment.name }}
        </h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div>
        <ngx-loading [show]="working"></ngx-loading>
        <div *ngIf="isReady" class="inner-content modal-scroolbar">
            <section class="d-flex" *ngIf="isPdfFile(attachment)">
                <!-- Display PDF -->
                <embed
                    [src]="fileUrl"
                    class="attachment-scale margin-auto"
                    type="application/pdf"
                    width="60%"
                />
            </section>
            <section
                class="overflow-auto"
                *ngIf="isImageFile(attachment)"
                #imageContainer
            >
                <!-- Display Image -->
                <img
                    class="margin-auto"
                    [src]="fileUrl"
                    alt="Attachment Image"
                />
            </section>
            <section class="overflow-auto" *ngIf="isTextFile(attachment)">
                <!-- Display Text -->
                <pre class="attachment-scale text-center">{{
                    textContent
                }}</pre>
            </section>
            <section
                class="container-scroll d-flex"
                *ngIf="isVideoFile(attachment)"
            >
                <!-- Display Video -->
                <video
                    width="60%"
                    class="attachment-scale margin-auto"
                    controls
                >
                    <source [src]="fileUrl" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </section>
        </div>
    </div>
    <button
        class="btn btn-default btn-pill btn-sm btn-download-position"
        [downloadUrl]="attachment.downloadUrl"
    >
        <span class="fa fa-download"></span> Download
    </button>
</div>
