import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    FullParamMapResolver,
    ModalContainer
} from '@flyfreely-portal-ui/ngx-bootstrap-customisation';
import { MaintenanceDetailsDialogue } from '../maintenance-details-dialogue/maintenance-details-dialogue.component';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                children: [
                    {
                        path: 'add',
                        component: ModalContainer,
                        data: {
                            modalContent: MaintenanceDetailsDialogue,
                            modalClass: 'modal-lg',
                            relativeRoute: '../../'
                        },
                        resolve: {
                            paramMap: FullParamMapResolver
                        }
                    },
                    {
                        path: 'bulk',
                        component: ModalContainer,
                        data: {
                            modalContent: MaintenanceDetailsDialogue,
                            modalClass: 'modal-lg',
                            relativeRoute: '../../'
                        },
                        resolve: {
                            paramMap: FullParamMapResolver
                        }
                    },
                    
                    {
                        path: 'edit/:maintenanceLogId',
                        component: ModalContainer,
                        data: {
                            modalContent: MaintenanceDetailsDialogue,
                            modalClass: 'modal-lg',
                            relativeRoute: '../../../'
                        },
                        resolve: {
                            paramMap: FullParamMapResolver
                        }
                    }
                ]
            }
        ])
    ],
    exports: [RouterModule]
})
export class MaintenanceRoutingModule {}
