import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {
    FlyFreelyLoggingService,
    hasFeatureFlag,
    PersonsOrganisationDto,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map, Option } from 'fp-ts/es6/Option';
import { Subject } from 'rxjs';

/**
 * This guard checks that the organisation has the feature flag defined as `requiredFeatureFlag` in the route data.
 * 
 * You often need to add `runGuardsAndResolvers: 'always'` to the router configuration so the change of
 * organisation is detected.
 */
@Injectable()
export class FeatureFlagGuard implements CanActivate {
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private logger: FlyFreelyLoggingService,
        private router: Router
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const featureFlag = route.data.requiredFeatureFlag;
        if (featureFlag == null) {
            return true;
        }

        if (route.queryParams.organisationId == null) {
            this.logger.error('No current organisationId');
            return false;
        }

        return pipe(
            this.userService.findOrganisationForUser(
                parseInt(route.queryParams.organisationId)
            ),
            map(o =>
                hasFeatureFlag(o, featureFlag)
                    ? true
                    : this.router.createUrlTree(['/home'], {
                          queryParams: route.queryParams
                      })
            ),
            getOrElse<boolean | UrlTree>(() => this.router.createUrlTree(['/']))
        );
    }
}
