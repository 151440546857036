import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ControlContainer,
    FormGroup,
    FormGroupDirective
} from '@angular/forms';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateApiKeyFormService } from './create-api-key-form.service';

@Component({
    selector: 'create-api-key-form',
    templateUrl: './create-api-key-form.component.html',
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective }
    ]
})
export class CreateApiKeyForm implements OnInit, OnDestroy {
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        public apiKeyFormService: CreateApiKeyFormService,
        private workTracker: WorkTracker
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    togglePermissions(permission: FormGroup, val: string) {
        this.apiKeyFormService.togglePermissions(permission, val);
    }
}
