import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
    selector: 'formly-ng-select',
    template: `
        <div class="form-group">
            <ng-select
                [items]="to.options"
                [clearable]="!to.required"
                [bindLabel]="to.labelProp || 'label'"
                [bindValue]="to.valueProp || 'value'"
                [multiple]="to.multiple || false"
                [clearable]="to.clearable || false"
                [formControl]="formControl"
                appendTo="body"
            >
            </ng-select>
        </div>
    `
})
export class FormlyNgSelect extends FieldType {}
