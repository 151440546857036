/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.45-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FlightLogCollectionAlertDto } from './flightLogCollectionAlertDto';
import { SimplePersonDto } from './simplePersonDto';
import { CollectNowQueue } from './collectNowQueue';


/**
 * 
 */
export interface FlightLogCollectionSourceDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The type of collection source
     */
    type: FlightLogCollectionSourceDto.Type;
    /**
     * The username, if any, associated with this collection source
     */
    username?: string;
    /**
     * The frequency at which files are checked
     */
    collectionFrequency: FlightLogCollectionSourceDto.CollectionFrequency;
    /**
     * When was the last time a collection occurred
     */
    lastCompletionTime?: string;
    /**
     * What was the message last time a collection occurred
     */
    lastCompletionMessage?: string;
    /**
     * What was the result of the last collection
     */
    lastCompletionStatus: FlightLogCollectionSourceDto.LastCompletionStatus;
    /**
     * Is this schedule to collect as soon as possible
     */
    collectNow: boolean;
    alertSettings?: FlightLogCollectionAlertDto;
    collectNowQueue?: CollectNowQueue;
    owner?: SimplePersonDto;
}
export namespace FlightLogCollectionSourceDto {
    export type Type = 'DJI_CLOUD' | 'DRONE_HARMONY' | 'DRONE_DEPLOY' | 'SKYDIO';
    export const Type = {
        DJI_CLOUD: 'DJI_CLOUD' as Type,
        DRONE_HARMONY: 'DRONE_HARMONY' as Type,
        DRONE_DEPLOY: 'DRONE_DEPLOY' as Type,
        SKYDIO: 'SKYDIO' as Type
    };
    export type CollectionFrequency = 'NEVER' | 'FIVE_MINUTES' | 'HOURLY' | 'DAILY';
    export const CollectionFrequency = {
        NEVER: 'NEVER' as CollectionFrequency,
        FIVE_MINUTES: 'FIVE_MINUTES' as CollectionFrequency,
        HOURLY: 'HOURLY' as CollectionFrequency,
        DAILY: 'DAILY' as CollectionFrequency
    };
    export type LastCompletionStatus = 'SUCCESS' | 'FAILURE' | 'NOT_YET_RUN' | 'INVALID_PASSWORD';
    export const LastCompletionStatus = {
        SUCCESS: 'SUCCESS' as LastCompletionStatus,
        FAILURE: 'FAILURE' as LastCompletionStatus,
        NOT_YET_RUN: 'NOT_YET_RUN' as LastCompletionStatus,
        INVALID_PASSWORD: 'INVALID_PASSWORD' as LastCompletionStatus
    };
}



