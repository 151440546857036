/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionPlan } from './subscriptionPlan';


/**
 * 
 */
export interface AvailableSubscriptionTiers { 
    identifier: string;
    name: string;
    licencedEntity: AvailableSubscriptionTiers.LicencedEntity;
    quantityLabel?: string;
    featureSetName?: string;
    customerDescription?: string;
    /**
     * The feature flags provided by this feature set
     */
    featureFlagList: Array<string>;
    annualPlan?: SubscriptionPlan;
    monthlyPlan?: SubscriptionPlan;
}
export namespace AvailableSubscriptionTiers {
    export type LicencedEntity = 'RPA' | 'PERSONNEL' | 'PILOT';
    export const LicencedEntity = {
        RPA: 'RPA' as LicencedEntity,
        PERSONNEL: 'PERSONNEL' as LicencedEntity,
        PILOT: 'PILOT' as LicencedEntity
    };
}



