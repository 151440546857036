/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.19.4
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestersMissionApprovalDto } from './requestersMissionApprovalDto';
import { MissionCrewDetailsDto } from './missionCrewDetailsDto';
import { MissionObjectiveDto } from './missionObjectiveDto';
import { MissionOperationTypeDto } from './missionOperationTypeDto';
import { EquipmentDto } from './equipmentDto';
import { InUseMissionWorkflowVersionDto } from './inUseMissionWorkflowVersionDto';


/**
 * 
 */
export interface MissionSummaryDto { 
    id: number;
    /**
     * The name given to this mission by the planner
     */
    name: string;
    /**
     * The current status of the mission
     */
    status: MissionSummaryDto.Status;
    /**
     * The mission has had approvals or authorisations revoked after starting, and must be aborted
     */
    disallowed?: boolean;
    /**
     * The final debrief message following the mission
     */
    completionMessage?: string;
    /**
     * The timestamp for the planned start of the mission
     */
    missionDate?: string;
    /**
     * The timezone that the mission is occurring in (e.g., Australia/Queensland)
     */
    timeZone?: string;
    /**
     * The objective text for the mission
     */
    missionObjective?: string;
    missionObjectiveType?: MissionObjectiveDto;
    missionType?: MissionOperationTypeDto;
    missionOperationType?: MissionOperationTypeDto;
    /**
     * The type of the mission which normally represents how the mission was created
     */
    type?: MissionSummaryDto.Type;
    /**
     * A unique identifier for the mission
     */
    uid?: string;
    /**
     * The ID of the location of the flight area
     */
    locationId?: number;
    /**
     * The name the user has given the location
     */
    locationName?: string;
    /**
     * The name of the organisation that owns the mission
     */
    organisationName?: string;
    /**
     * ${swagger.Mission.organisationId}
     */
    organisationId?: number;
    /**
     * Which workgroup is this mission associated with
     */
    workgroupId?: number;
    craftIds: Array<number>;
    /**
     * The name the user has given the RPA
     */
    craftNicknames: Array<string>;
    /**
     * ${swagger.Mission.equipment}
     */
    equipment: Array<EquipmentDto>;
    missionCrewDetails: Array<MissionCrewDetailsDto>;
    /**
     * Maximum flight height AGL in feet
     */
    maximumHeight?: number;
    /**
     * Any additional notes for the crew of the mission
     */
    crewNotes?: string;
    /**
     * The mission workflow version used for this mission
     */
    missionWorkflowVersionId?: number;
    /**
     * The estimated duration of the mission in seconds
     */
    missionEstimatedTime?: number;
    /**
     * The mission being dummy field
     */
    isDummy?: boolean;
    /**
     * The visual line of sight option of mission
     */
    visualLineOfSight?: MissionSummaryDto.VisualLineOfSight;
    /**
     * The time in which mission occur
     */
    timeOfDay?: MissionSummaryDto.TimeOfDay;
    /**
     * ${swagger.Mission.readyForFinalisation}
     */
    readyForFinalisation?: boolean;
    /**
     * The data model version identifier for this domain entity
     */
    modelVersion: number;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    /**
     * ${swagger.Generic.readyTime}
     */
    readyTime?: string;
    missionApproval?: RequestersMissionApprovalDto;
    missionWorkflowVersion?: InUseMissionWorkflowVersionDto;
    /**
     * The jurisdiction the location is in
     */
    airspaceJurisdictionName?: string;
}
export namespace MissionSummaryDto {
    export type Status = 'DRAFT' | 'READY_TO_FLY' | 'ACTIVATED' | 'PREPARED' | 'ON_SITE' | 'FLYING' | 'DONE_FLYING' | 'CANCELLED' | 'COMPLETED' | 'DELETED' | 'FINALISED' | 'AWAITING_SYNC';
    export const Status = {
        DRAFT: 'DRAFT' as Status,
        READY_TO_FLY: 'READY_TO_FLY' as Status,
        ACTIVATED: 'ACTIVATED' as Status,
        PREPARED: 'PREPARED' as Status,
        ON_SITE: 'ON_SITE' as Status,
        FLYING: 'FLYING' as Status,
        DONE_FLYING: 'DONE_FLYING' as Status,
        CANCELLED: 'CANCELLED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        DELETED: 'DELETED' as Status,
        FINALISED: 'FINALISED' as Status,
        AWAITING_SYNC: 'AWAITING_SYNC' as Status
    };
    export type Type = 'STANDARD' | 'RETROSPECTIVE' | 'OFFLINE' | 'INSTANT_MISSION' | 'FIELD_APP_BACKUP' | 'IMPORTED';
    export const Type = {
        STANDARD: 'STANDARD' as Type,
        RETROSPECTIVE: 'RETROSPECTIVE' as Type,
        OFFLINE: 'OFFLINE' as Type,
        INSTANT_MISSION: 'INSTANT_MISSION' as Type,
        FIELD_APP_BACKUP: 'FIELD_APP_BACKUP' as Type,
        IMPORTED: 'IMPORTED' as Type
    };
    export type VisualLineOfSight = 'VLOS' | 'EVLOS' | 'BVLOS';
    export const VisualLineOfSight = {
        VLOS: 'VLOS' as VisualLineOfSight,
        EVLOS: 'EVLOS' as VisualLineOfSight,
        BVLOS: 'BVLOS' as VisualLineOfSight
    };
    export type TimeOfDay = 'DAY' | 'NIGHT' | 'DAY_NIGHT';
    export const TimeOfDay = {
        DAY: 'DAY' as TimeOfDay,
        NIGHT: 'NIGHT' as TimeOfDay,
        DAY_NIGHT: 'DAY_NIGHT' as TimeOfDay
    };
}



