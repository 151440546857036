/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface RegisterEntryVerificationCommand { 
    /**
     * The register condition id to be verified
     */
    registerConditionId: number;
    /**
     * The verification status in case of actionable item
     */
    status?: RegisterEntryVerificationCommand.Status;
    /**
     * The register entry for which the verification is done
     */
    registerEntryId: number;
    managingOrganisationId?: number;
    /**
     * The expiry date of the verification
     */
    expiryDate?: string;
    notes?: string;
}
export namespace RegisterEntryVerificationCommand {
    export type Status = 'PASSED' | 'PENDING' | 'PARTIAL';
    export const Status = {
        PASSED: 'PASSED' as Status,
        PENDING: 'PENDING' as Status,
        PARTIAL: 'PARTIAL' as Status
    };
}



