import { HttpParams, HttpParameterCodec } from '@angular/common/http';

/**
 * An object to be returnd by the changes$ observable of a service to better inform subscribers of the details of eact change
 * This aims to improve change detection and load times by allowing components to filter when to respond to changes.
 */
export interface ChangeDetails<T = any> {
    /**
     * The type of change (CREATE | UPDATE | DELETE) performed by the service.
     */
    type: ChangeDetails.Type;
    /**
     * The ID relevant to this change, ususally the returned object's ID
     */
    changedId: number;
    /**
     * Can be used to pass additional information or even an entire returned object to reduce unneeded API calls.
     */
    result?: T;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ChangeDetails {
    export enum Type {
        CREATE = 'CREATE',
        UPDATE = 'UPDATE',
        DELETE = 'DELETE'
    }
}

function prefixWithQuestionMark(queryString: string) {
    if (queryString == null || queryString.length === 0) {
        return '';
    }
    return `?${queryString}`;
}

/**
 * From https://medium.com/better-programming/how-to-fix-angular-httpclient-not-escaping-url-parameters-ddce3f9b8746
 */
class CustomHttpParamEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

/**
 * Compute the path including query string.
 * @param path base path
 * @param queryParams query parameters object
 */
export function computePath(path: string, queryParams?: any) {
    const queryString = prefixWithQuestionMark(
        httpParamSerializer(queryParams).toString()
    );
    return `${path}${queryString}`;
}

/**
 * Convert an object into a HttpParams object. Must be a simple object, with no nesting,
 * and only string or number values. Null keys are automatically excluded.
 * @param obj object to be converted
 */
export function httpParamSerializer(obj?: {
    [param: string]:
        | string
        | number
        | string[]
        | number[]
        | boolean
        | boolean[];
}): HttpParams {
    if (obj == null) {
        return new HttpParams();
    }

    const keys = Object.keys(obj);

    return keys
        .filter(key => obj[key] != null)
        .reduce((acc, key) => {
            const entry = obj[key];
            if (Array.isArray(entry)) {
                // @ts-ignore this isn't inferring the possible types of v properly
                return entry.reduce(
                    (acc2: HttpParams, v: string | number) =>
                        acc2.append(key, toString(v)),
                    acc
                );
            } else {
                return acc.append(key, toString(entry));
            }
        }, new HttpParams({ encoder: new CustomHttpParamEncoder() }));
}

/**
 * A null safe to string.
 * @param val value to convert
 */
function toString(val: string | number | boolean): string {
    return val == null ? null : val.toString();
}
