<div *ngIf="!compact"
        class="enhanced-help-block">
    <div class="text-right helper-heading-container">
        <label class="helper-heading-label">
            {{ helpTitle }}
        </label>
        <i class="fas fa-life-ring fa-2x"></i>
    </div>
    <div class="helper-text-container">
        <div class="helper-text">
            <label
                class="helper-text-label"
                [innerHTML]="helpText | autolink"
            >
            </label>
        </div>
    </div>
</div>
<div *ngIf="compact"
        class="enhanced-help-block-compact">
        <div class="helper-heading-container">
            <i class="fas fa-life-ring fa-2x"></i>
        </div>
        <label
            class="helper-text-label"
            [innerHTML]="helpText | autolink"
        >
        </label>
</div>