import { Directive } from '@angular/core';
import { Polygon } from 'geojson';
import { MapDynamicData } from 'libs/map/src/lib/dynamic-data.service';
import { Subject, takeUntil } from 'rxjs';
import { AirspaceAuthorisationManager } from './authorisation/airspace-authorisation-manager.service';

@Directive({
    selector: 'flyfreely-map[airspaceAuthorisations]'
})
export class AirspaceAuthorisationsDirective {
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        private mapDynamicData: MapDynamicData
    ) {
        airspaceAuthorisationManager.airspaceAuthorisationPreview$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                if (result != null && result.authorisationList?.length > 0) {
                    this.mapDynamicData.setProgrammaticMapConfig(this, {
                        featureGroupList: [
                            {
                                canAdd: false,
                                id: 99,
                                name: 'Authorisations',
                                type: 'Polygon',
                                existingFeatures: result.authorisationList.map(
                                    (a, ix) => ({
                                        geom: <Polygon>a.operatingArea,
                                        id: ix,
                                        name: a.controllingAuthority
                                    })
                                ),
                                styles: {
                                    fill: [
                                        {
                                            paint: { 'fill-color': '#ff0000' }
                                        }
                                    ],
                                    line: [
                                        {
                                            paint: {
                                                'line-color': '#00ff00',
                                                'line-width': 2
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    });
                }
            });
    }

    ngOnDestroy() {
        this.mapDynamicData.clearProgrammaticMapConfig(this);
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
