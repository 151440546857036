<div class="vertical-scroll horizontal-container bottom-buffer">
    <div class="fcol-8">
        <ng-container *ngFor="let group of availableComponents">
            <div class="fill" *ngIf="hasAnyInGroup(group)">
                <div class="fcol-10">
                    <h5>
                        <a
                            class="right-buffer"
                            style="color: #000"
                            (click)="toggleGroup(group.id)"
                            [attr.aria-expanded]="!isHidden(group.id)"
                            aria-controls="collapseBasic"
                        >
                            <i
                                *ngIf="isHidden(group.id)"
                                class="fas fa-angle-down"
                            ></i>
                            <i
                                *ngIf="!isHidden(group.id)"
                                class="fas fa-angle-up"
                            ></i>
                        </a>
                        <span>{{ group.name }}</span>
                        <div></div>
                    </h5>
                </div>
                <div
                    class="table-container no-actions"
                    *ngIf="!isHidden(group.id)"
                    [@expandOnEnter]
                    [@collapseOnLeave]
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 15%">Include</th>
                                <th style="width: 17%">Component</th>
                                <th style="width: 17%">Task</th>
                                <th style="width: 17%">Schedule</th>
                                <th style="width: 17%">Time Since Last</th>
                                <th style="width: 17%">Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody *ngIf="tasks.value != null">
                            <ng-container
                                *ngFor="
                                    let task of tasks.controls;
                                    let i = index
                                "
                            >
                                <ng-container
                                    *ngIf="
                                        i != currentlyEditingId &&
                                        isInGroup(task.value, group)
                                    "
                                >
                                    <tr
                                        class="data"
                                        (mouseover)="
                                            getAttachmentUrl(
                                                task.value.componentId
                                            )
                                        "
                                    >
                                        <td>
                                            <div class="checkbox">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="selectTask"
                                                        style="cursor: pointer"
                                                        (ngModelChange)="
                                                            onSelectionUpdated()
                                                        "
                                                        [(ngModel)]="
                                                            task.value.selected
                                                        "
                                                    />
                                                    <span class="cr">
                                                        <i
                                                            class="cr-icon glyphicon glyphicon-ok"
                                                        ></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="
                                                    findComponentIcon(task);
                                                    let componentIconClass
                                                "
                                                [class]="componentIconClass"
                                            ></span
                                            >{{ task.value.componentName }}
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="
                                                    findTaskIcon(task);
                                                    let taskIconClass
                                                "
                                                [class]="taskIconClass"
                                            ></span
                                            >{{ task.value.taskTypeName }}
                                        </td>
                                        <td>
                                            {{ calculateSchedule(task) }}
                                        </td>
                                        <td>
                                            {{ task.value.timeSinceLast }}
                                        </td>
                                        <td>
                                            {{ task.value.status }}
                                        </td>
                                        <td>
                                            <div
                                                class="horizontal-container"
                                                *ngIf="task.value.canEdit"
                                            >
                                                <button
                                                    *ngIf="
                                                        i != currentlyEditingId
                                                    "
                                                    type="button"
                                                    class="btn btn-tertiary"
                                                    (click)="editTask(i)"
                                                    [disabled]="
                                                        editing !== 'NONE' ||
                                                        working
                                                    "
                                                    placement="top"
                                                    tooltip="Edit Component"
                                                >
                                                    <span
                                                        class="fal fa-pencil-alt"
                                                    ></span>
                                                </button>

                                                <button
                                                    *ngIf="
                                                        i != currentlyEditingId
                                                    "
                                                    type="button"
                                                    class="btn btn-tertiary btn-delete"
                                                    (click)="deleteTask(i)"
                                                    [disabled]="
                                                        editing !== 'NONE' ||
                                                        working
                                                    "
                                                    placement="top"
                                                    tooltip="Delete component"
                                                >
                                                    <span
                                                        class="fal fa-trash-alt"
                                                    ></span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        editing == 'EDIT' &&
                                        i == currentlyEditingId &&
                                        isInGroup(task.value, group)
                                    "
                                >
                                    <tr>
                                        <td colspan="2">
                                            <label class="control-label"
                                                >Component</label
                                            >
                                            <ng-select
                                                [items]="components"
                                                [(ngModel)]="selectedComponent"
                                                appendTo="body"
                                                [clearable]="false"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    ng-label-tmp
                                                    let-item="item"
                                                >
                                                    <div
                                                        (mouseover)="
                                                            getAttachmentUrl(
                                                                item
                                                                    .componentType
                                                                    .id
                                                            )
                                                        "
                                                    >
                                                        <span
                                                            *ngIf="
                                                                item
                                                                    .componentType
                                                                    .iconFamily ===
                                                                    'FONT_AWESOME' &&
                                                                item
                                                                    .componentType
                                                                    .icon !=
                                                                    null
                                                            "
                                                            [class]="
                                                                'right-buffer fal fa-' +
                                                                item
                                                                    .componentType
                                                                    .icon
                                                            "
                                                        ></span
                                                        >{{
                                                            item.componentType
                                                                .name
                                                        }}&nbsp;{{
                                                            item.designation
                                                        }}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </td>
                                        <td colspan="2">
                                            <label class="control-label"
                                                >Task Type</label
                                            >
                                            <ng-select
                                                [items]="taskTypes"
                                                [(ngModel)]="selectedTaskType"
                                                appendTo="body"
                                                [clearable]="false"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    ng-label-tmp
                                                    let-item="item"
                                                >
                                                    <span
                                                        *ngIf="
                                                            item.iconFamily ===
                                                                'FONT_AWESOME' &&
                                                            item.icon != null
                                                        "
                                                        [class]="
                                                            'right-buffer fal fa-' +
                                                            item.icon
                                                        "
                                                    ></span
                                                    >{{ item.name }}
                                                </ng-template>
                                            </ng-select>
                                        </td>
                                        <td colspan="2">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm pull-right"
                                                (click)="saveTask(i)"
                                                [disabled]="
                                                    tasks.invalid ||
                                                    (selectedTaskType != null &&
                                                        selectedComponent !=
                                                            null &&
                                                        !isUnique()) ||
                                                    working
                                                "
                                                placement="top"
                                                tooltip="Save"
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-link btn-sm pull-right"
                                                (click)="cancelAction()"
                                                [disabled]="working"
                                                placement="top"
                                                tooltip="Cancel"
                                            >
                                                Cancel
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
        <div
            class="fill table-container no-actions"
            *ngIf="hasAnyInGroup(null)"
        >
            <div class="fcol-10">
                <h5>
                    <a
                        class="right-buffer"
                        style="color: #000"
                        (click)="toggleGroup(-1)"
                        [attr.aria-expanded]="!isHidden(-1)"
                        aria-controls="collapseBasic"
                    >
                        <i *ngIf="isHidden(-1)" class="fas fa-angle-down"></i>
                        <i *ngIf="!isHidden(-1)" class="fas fa-angle-up"></i>
                    </a>
                    <span>Ungrouped</span>
                    <div></div>
                </h5>
            </div>
            <div
                class="table-container no-actions"
                *ngIf="!isHidden(-1)"
                [@expandOnEnter]
                [@collapseOnLeave]
            >
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 15%">Include</th>
                            <th style="width: 17%">Component</th>
                            <th style="width: 17%">Task</th>
                            <th style="width: 17%">Schedule</th>
                            <th style="width: 17%">Time Since Last</th>
                            <th style="width: 17%">Status</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody *ngIf="tasks.value != null">
                        <ng-container
                            *ngFor="let task of tasks.controls; let i = index"
                        >
                            <ng-container
                                *ngIf="
                                    i != currentlyEditingId &&
                                    isInGroup(task.value, null)
                                "
                            >
                                <tr
                                    class="data"
                                    (mouseover)="
                                        getAttachmentUrl(task.value.componentId)
                                    "
                                >
                                    <td>
                                        <div class="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="selectTask"
                                                    style="cursor: pointer"
                                                    (ngModelChange)="
                                                        onSelectionUpdated()
                                                    "
                                                    [(ngModel)]="
                                                        task.value.selected
                                                    "
                                                />
                                                <span class="cr">
                                                    <i
                                                        class="cr-icon glyphicon glyphicon-ok"
                                                    ></i>
                                                </span>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <span
                                            *ngIf="
                                                findComponentIcon(task);
                                                let componentIconClass
                                            "
                                            [class]="componentIconClass"
                                        ></span
                                        >{{ task.value.componentName }}
                                    </td>
                                    <td>
                                        <span
                                            *ngIf="
                                                findTaskIcon(task);
                                                let taskIconClass
                                            "
                                            [class]="taskIconClass"
                                        ></span
                                        >{{ task.value.taskTypeName }}
                                    </td>
                                    <td>
                                        {{ calculateSchedule(task) }}
                                    </td>
                                    <td>
                                        {{ task.value.timeSinceLast }}
                                    </td>
                                    <td>
                                        {{ task.value.status }}
                                    </td>
                                    <td>
                                        <div
                                            class="horizontal-container"
                                            *ngIf="task.value.canEdit"
                                        >
                                            <button
                                                *ngIf="i != currentlyEditingId"
                                                type="button"
                                                class="btn btn-tertiary"
                                                (click)="editTask(i)"
                                                [disabled]="
                                                    editing !== 'NONE' ||
                                                    working
                                                "
                                                placement="top"
                                                tooltip="Edit Component"
                                            >
                                                <span
                                                    class="fal fa-pencil-alt"
                                                ></span>
                                            </button>

                                            <button
                                                *ngIf="i != currentlyEditingId"
                                                type="button"
                                                class="btn btn-tertiary btn-delete"
                                                (click)="deleteTask(i)"
                                                [disabled]="
                                                    editing !== 'NONE' ||
                                                    working
                                                "
                                                placement="top"
                                                tooltip="Delete component"
                                            >
                                                <span
                                                    class="fal fa-trash-alt"
                                                ></span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    editing == 'EDIT' &&
                                    i == currentlyEditingId &&
                                    isInGroup(task.value, null)
                                "
                            >
                                <tr>
                                    <td colspan="2">
                                        <label class="control-label"
                                            >Component</label
                                        >
                                        <ng-select
                                            [items]="components"
                                            [(ngModel)]="selectedComponent"
                                            appendTo="body"
                                            [clearable]="false"
                                        >
                                            <ng-template
                                                ng-option-tmp
                                                ng-label-tmp
                                                let-item="item"
                                            >
                                                <div
                                                    (mouseover)="
                                                        getAttachmentUrl(
                                                            item.componentType
                                                                .id
                                                        )
                                                    "
                                                >
                                                    <span
                                                        *ngIf="
                                                            item.componentType
                                                                .iconFamily ===
                                                                'FONT_AWESOME' &&
                                                            item.componentType
                                                                .icon != null
                                                        "
                                                        [class]="
                                                            'right-buffer fal fa-' +
                                                            item.componentType
                                                                .icon
                                                        "
                                                    ></span
                                                    >{{
                                                        item.componentType.name
                                                    }}&nbsp;{{
                                                        item.designation
                                                    }}
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </td>
                                    <td colspan="2">
                                        <label class="control-label"
                                            >Task Type</label
                                        >
                                        <ng-select
                                            [items]="taskTypes"
                                            [(ngModel)]="selectedTaskType"
                                            appendTo="body"
                                            [clearable]="false"
                                        >
                                            <ng-template
                                                ng-option-tmp
                                                ng-label-tmp
                                                let-item="item"
                                            >
                                                <span
                                                    *ngIf="
                                                        item.iconFamily ===
                                                            'FONT_AWESOME' &&
                                                        item.icon != null
                                                    "
                                                    [class]="
                                                        'right-buffer fal fa-' +
                                                        item.icon
                                                    "
                                                ></span
                                                >{{ item.name }}
                                            </ng-template>
                                        </ng-select>
                                    </td>
                                    <td colspan="2">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm pull-right"
                                            (click)="saveTask(i)"
                                            [disabled]="
                                                tasks.invalid ||
                                                (selectedTaskType != null &&
                                                    selectedComponent != null &&
                                                    !isUnique()) ||
                                                working
                                            "
                                            placement="top"
                                            tooltip="Save"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-link btn-sm pull-right"
                                            (click)="cancelAction()"
                                            [disabled]="working"
                                            placement="top"
                                            tooltip="Cancel"
                                        >
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="fill horizontal-container"
            style="align-items: flex-end"
            *ngIf="editing == 'ADD'"
        >
            <div class="fcol-4">
                <label class="control-label"> Component </label>
                <ng-select
                    [items]="components"
                    [(ngModel)]="selectedComponent"
                    appendTo="body"
                    [clearable]="false"
                >
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <div
                            (mouseover)="
                                getAttachmentUrl(item.componentType.id)
                            "
                        >
                            <span
                                *ngIf="
                                    item.componentType.iconFamily ===
                                        'FONT_AWESOME' &&
                                    item.componentType.icon != null
                                "
                                [class]="
                                    'right-buffer fal fa-' +
                                    item.componentType.icon
                                "
                            ></span>
                            {{ item.componentType.name }}&nbsp;{{
                                item.designation
                            }}
                        </div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="fcol-4">
                <label class="control-label">Task Type</label>
                <ng-select
                    [items]="taskTypes"
                    [(ngModel)]="selectedTaskType"
                    appendTo="body"
                    [clearable]="false"
                >
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <span
                            *ngIf="
                                item.iconFamily === 'FONT_AWESOME' &&
                                item.icon != null
                            "
                            [class]="'right-buffer fal fa-' + item.icon"
                        ></span>
                        {{ item.name }}
                    </ng-template>
                </ng-select>
            </div>
            <div class="fcol-4">
                <button
                    type="button"
                    class="btn btn-primary btn-sm pull-right"
                    (click)="addTask()"
                    [disabled]="
                        tasks.invalid ||
                        (selectedTaskType != null &&
                            selectedComponent != null &&
                            !isUnique()) ||
                        working
                    "
                    placement="top"
                    tooltip="Save"
                >
                    Save
                </button>
                <button
                    type="button"
                    class="btn btn-link btn-sm pull-right"
                    (click)="cancelAction()"
                    [disabled]="working"
                    placement="top"
                    tooltip="Cancel"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
    <div
        class="fcol-4"
        style="display: flex; align-items: center; justify-content: center"
    >
        <img
            *ngIf="diagramUrl != null"
            style="max-width: 100%; height: auto"
            [authenticatedSrc]="diagramUrl"
            [cache]="false"
        />
    </div>
</div>
