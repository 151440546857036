import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {
    EnhancedHelpDto,
    FlyFreelyError
} from '@flyfreely-portal-ui/flyfreely';
import {
    fadeInExpandOnEnterAnimation,
    fadeOutCollapseOnLeaveAnimation
} from 'angular-animations';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicHelpEditService } from './dynamic-help-editor/dynamic-help-edit.service';
import { EnhancedHelpService } from './enhanced-help.service';
import moment from 'moment';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';

/**
 * Display the inline enhanced help component. This component
 * has two views: a normal full view that includes the control's name,
 * and a compact view that is just the text.
 *
 * This component also supports getting the `screenName` from
 * `ScreenAnalyticsDirective` further up the hierarchy.
 */
@Component({
    selector: 'enhanced-help',
    templateUrl: `./enhanced-help.component.html`,
    styleUrls: ['./styles.scss'],
    animations: [
        fadeInExpandOnEnterAnimation(),
        fadeOutCollapseOnLeaveAnimation()
    ]
})
export class EnhancedHelp implements OnInit, OnDestroy {
    /**
     * The name of the enhanced help object to display.
     */
    @Input() componentName: string;
    /**
     * The screen name to use to locate this enhanced help object. Falls back to the {@link ScreenAnalyticsDirective} value if not provided.
     */
    @Input() screenName?: string;
    /**
     * Display in the compact layout?
     */
    @Input() compact = false;
    /**
     * Is this enhanced help always visible, otherwise, its visibility is controlled by the {@link ScreenAnalyticsDirective}.
     */
    @Input() alwaysActive = false;
    /**
     *
     */
    @Input() helpObjectOverride?: EnhancedHelpDto;

    error: string;

    helpObject?: EnhancedHelpDto;

    active: boolean;
    editMode: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private enhancedHelpService: EnhancedHelpService,
        private dynamicHelpEditService: DynamicHelpEditService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {}

    ngOnInit() {
        this.getHelpObject();
        this.enhancedHelpService.changes$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(change => this.getHelpObject());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setActiveState() {
        if (this.alwaysActive === false) {
            combineLatest([
                this.dynamicHelpEditService.editMode$,
                this.screenAnalytics.helpActive$
            ])
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(([editMode, active]) => {
                    this.active = editMode || active;
                    this.editMode = editMode;
                });
        } else {
            this.active = true;
        }
    }

    getHelpObject() {
        const screenName =
            this.screenName ?? this.screenAnalytics?.screenAnalytics;

        if (screenName == null) {
            throw new Error(`screenName not specified and can't be inferred`);
        }

        // We select null/missing values so that we properly initialise the active state
        this.enhancedHelpService
            .findByScreenAndComponent(screenName, this.componentName, true)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                // This is only a fallback and should actually happen in the backend
                next: results => {
                    this.helpObject =
                        results != null &&
                        results.helpText != null &&
                        results.helpText.length > 0
                            ? results
                            : null;
                    this.findShowUntilValue();
                },
                error: (error: FlyFreelyError) => {
                    this.error = error.message;
                }
            });
    }

    findShowUntilValue() {
        const today = moment(moment.now()).format('YYYY-MM-DD');
        if (
            this.helpObject?.alwaysOnUntil != null &&
            moment(this.helpObject.alwaysOnUntil).isAfter(moment(today))
        ) {
            this.alwaysActive = true;
        }
        this.setActiveState();
    }

    showVideo() {
        // Don't log analytics for enhanced help editor override values.
        // TODO: might need to turn off analytics for edit mode as a whole.
        if (this.helpObjectOverride?.supportVideoUrl != null) {
            this.enhancedHelpService.showVideo(
                this.helpObjectOverride.supportVideoUrl,
                this.helpTitle
            );
            return;
        }
        this.screenAnalytics.track('help', 'show-video', {
            component: this.componentName
        });
        this.enhancedHelpService.showVideo(
            this.helpObject.supportVideoUrl,
            this.helpObject.helpTitle
        );
    }

    trackKnowledgeBase() {
        // Don't log analytics for enhanced help editor override values.
        // TODO: might need to turn off analytics for edit mode as a whole.
        if (this.helpObjectOverride?.knowledgeBaseUrl != null) {
            return;
        }
        this.screenAnalytics.track('help', 'show-knowledge-base', {
            component: this.componentName
        });
    }

    get helpTitle() {
        return (
            this.helpObjectOverride?.helpTitle ??
            this.helpObject?.helpTitle ??
            this.componentName
        );
    }

    get helpText() {
        return (
            this.helpObjectOverride?.helpText ??
            this.helpObject?.helpText
        );
    }

    get supportVideoUrl() {
        return (
            this.helpObjectOverride?.supportVideoUrl ??
            this.helpObject?.supportVideoUrl
        );
    }

    get knowledgeBaseUrl() {
        return (
            this.helpObjectOverride?.knowledgeBaseUrl ??
            this.helpObject?.knowledgeBaseUrl
        );
    }
}
