import { Component, Input } from '@angular/core';
import { defaultBuildFormAttachmentUrl, FormResponseDto } from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * This component is a dialogue that allows the user to see the response to a single checklist.
 */
@Component({
    selector: 'form-response-dialogue',
    templateUrl: './form-response-dialogue.component.html'
})
export class FormResponseDialogue {
    @Input() responseList: FormResponseDto[];
    @Input() title: string;
    @Input() organisationId: number;
    @Input() buildFormAttachmentUrl: typeof defaultBuildFormAttachmentUrl;

    displayValues: { [questionId: number]: string };
    fields: FormlyFieldConfig[];

    response: FormResponseDto;

    constructor(public modal: BsModalRef<FormResponseDialogue>) {}

    ngOnInit() {
        this.response = this.responseList[0];
    }
}
