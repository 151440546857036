<form [formGroup]="formGroup" (ngSubmit)="purchase()">
    <div
        class="panel"
        [ngClass]="{
            selected: selected,
            featured: featuredPlan
        }"
    >
        <ngx-loading [show]="plan == null"></ngx-loading>
        <ng-container *ngIf="plan != null">
            <div class="panel-heading">
                {{ product.name }}
                <p
                    class="feature-label"
                    *ngIf="featuredPlan && selectedFeature"
                >
                    Includes {{ selectedFeature }}
                </p>
            </div>
            <div class="panel-body">
                <div [innerHtml]="product.customerDescription | autolink"></div>
                <div>
                    <p *ngFor="let tier of plan.tiers; let ix = index">
                        <span *ngIf="tier.tierLimit != null"
                            >Up to {{ tier.tierLimit }}</span
                        ><span
                            *ngIf="
                                tier.tierLimit == null && plan.tiers.length > 1
                            "
                            >{{ plan.tiers[ix - 1].tierLimit + 1 }}+
                        </span>
                        ${{ tier.unitAmount }}
                        {{ plan.currency | uppercase }} per
                        {{ product.quantityLabel || 'licence' }}
                    </p>
                    <p *ngIf="plan.renewalPeriod !== 'NONE'">
                        Billing Period:
                        {{
                            plan.renewalPeriod == 'ANNUAL'
                                ? 'Annual'
                                : 'Monthly'
                        }}
                    </p>
                </div>
            </div>
            <div
                *ngIf="selected && product.name.toLowerCase() != 'free'"
                @expandOnEnter
            >
                <div class="text-center">Licences</div>
                <div class="panel-body licence-count-panel">
                    <button
                        type="button"
                        class="btn btn-default btn-sm"
                        (click)="subtract()"
                        [disabled]="
                            plan.minimumLicenceCount
                                ? formGroup.value.licenceCount ==
                                  plan.minimumLicenceCount
                                : formGroup.value.licenceCount == 0
                        "
                    >
                        <span class="fal fa-minus"></span>
                    </button>
                    <input
                        class="form-control"
                        formControlName="licenceCount"
                        type="number"
                        [tooltip]="
                            formGroup.controls.licenceCount.valid
                                ? ''
                                : 'Licence count must be at least ' +
                                  plan.minimumLicenceCount
                        "
                        placement="top"
                        [min]="
                            plan.minimumLicenceCount
                                ? plan.minimumLicenceCount
                                : 1
                        "
                    />
                    <button
                        type="button"
                        class="btn btn-default btn-sm"
                        (click)="add()"
                    >
                        <span class="fal fa-plus"></span>
                    </button>
                </div>
            </div>
            <div
                *ngIf="selected && product.name.toLowerCase() != 'free'"
                class="panel-body text-center"
            >
                <p *ngIf="previewCharge != null">
                    Recurring charge of ${{ previewCharge.subTotal }}
                    {{ plan.currency | uppercase }}
                    {{
                        plan.renewalPeriod === 'ANNUAL'
                            ? 'per annum'
                            : 'per month'
                    }}
                    &nbsp;<em>*Tax calculated at checkout</em>
                </p>
                <loading-indicator
                    [isLoading]="previewCharge == null"
                ></loading-indicator>
            </div>
            <div *ngIf="!selected" class="panel-footer">
                <button
                    class="btn btn-default btn-block"
                    type="button"
                    tabindex="0"
                    (click)="selectThis()"
                    [disabled]="current && !upgrading"
                >
                    {{ current ? 'Current' : 'Select' }}
                </button>
            </div>

            <div *ngIf="selected" class="panel-footer">
                <button
                    type="submit"
                    [disabled]="
                        working ||
                        disabled ||
                        (upgrading &&
                            formGroup.value.licenceCount ==
                                initialLicenceCount &&
                            current)
                    "
                    class="btn btn-primary btn-block"
                >
                    Purchase
                </button>
            </div>
        </ng-container>
    </div>
</form>
