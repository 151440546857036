<form [formGroup]="form">
    <div class="vertical-container container-fluid">
        <ng-container
            *ngIf="form.controls.unlistedPersonnel != null && personnelLoaded"
        >
            <h5>Pilots</h5>
            <ng-container formArrayName="unlistedPersonnel">
                <div
                    class="form-group input-content"
                    *ngFor="
                        let person of unlistedPersonnelForm.controls;
                        let i = index
                    "
                    [formGroupName]="i"
                >
                    <div class="col-xs-3 text-right">
                        <label
                            class="control-label left-buffer"
                            [ngClass]="{ readonly: working }"
                        >
                            {{ unlistedPersonnel[i].firstName }}
                            {{ unlistedPersonnel[i].lastName }}
                            <field-validation-indicator
                                validatorFormControlName=""
                            ></field-validation-indicator>
                        </label>
                    </div>
                    <div class="col-xs-6">
                        <div>
                            <ng-select
                                [items]="
                                    missionCompletionService.allPersonnel$
                                        | async
                                "
                                [readonly]="working"
                                formControlName="id"
                                bindValue="id"
                                appendTo="body"
                                [clearable]="false"
                                (change)="linkPerson(i, 'id')"
                            >
                                <ng-template ng-option-tmp let-item="item">
                                    {{ item | formatPerson }}
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    {{ item | formatPerson }}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-xs-3">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="ignored"
                                    (change)="linkPerson(i, 'ignored')"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                Ignore
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="form.controls.unlistedRpas != null && craftLoaded">
            <h5>RPAs</h5>
            <ng-container formArrayName="unlistedRpas">
                <div
                    class="form-group input-content"
                    *ngFor="let rpa of unlistedRpaForm.controls; let i = index"
                    [formGroupName]="i"
                >
                    <div class="col-xs-3 text-right">
                        <label
                            class="control-label left-buffer"
                            [ngClass]="{ readonly: working }"
                        >
                            {{ unlistedRpas[i].name }}
                            <field-validation-indicator
                                validatorFormControlName=""
                            ></field-validation-indicator>
                        </label>
                    </div>
                    <div class="col-xs-6">
                        <ng-select
                            [items]="missionCompletionService.allRpas$ | async"
                            formControlName="id"
                            [readonly]="working"
                            bindLabel="nickname"
                            bindValue="id"
                            appendTo="body"
                            [clearable]="false"
                            (change)="linkRpa(i, 'id')"
                        >
                        </ng-select>
                    </div>
                    <div class="col-xs-3">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="ignored"
                                    (change)="linkRpa(i, 'ignored')"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                Ignore
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container
            *ngIf="
                form.controls.unlistedBatterySets != null && batterySetsLoaded
            "
        >
            <h5>Battery Sets</h5>
            <ng-container formArrayName="unlistedBatterySets">
                <div
                    class="form-group input-content"
                    *ngFor="
                        let batterySet of unlistedBatterySetsForm.controls;
                        let i = index
                    "
                    [formGroupName]="i"
                >
                    <div class="col-xs-3 text-right">
                        <label
                            class="control-label left-buffer"
                            [ngClass]="{ readonly: working }"
                        >
                            {{ unlistedBatterySets[i].name }}
                            <field-validation-indicator
                                validatorFormControlName=""
                            ></field-validation-indicator>
                        </label>
                    </div>
                    <div class="col-xs-6">
                        <ng-select
                            [items]="
                                missionCompletionService.allBatterySets$ | async
                            "
                            [readonly]="working"
                            formControlName="id"
                            bindLabel="name"
                            bindValue="id"
                            appendTo="body"
                            [clearable]="false"
                            (change)="linkBatterySet(i, 'id')"
                        >
                        </ng-select>
                    </div>
                    <div class="col-xs-3">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="ignored"
                                    (change)="linkBatterySet(i, 'ignored')"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                Ignore
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container
            *ngIf="form.controls.unlistedEquipment != null && equipmentLoaded"
        >
            <h5>Equipment</h5>
            <ng-container formArrayName="unlistedEquipment">
                <div
                    class="form-group input-content"
                    *ngFor="
                        let equipmentControl of unlistedEquipmentForm.controls;
                        let i = index
                    "
                    [formGroupName]="i"
                >
                    <div class="col-xs-3 text-right">
                        <label
                            class="control-label left-buffer"
                            [ngClass]="{ readonly: working }"
                        >
                            {{ unlistedEquipment[i].name }}
                            <field-validation-indicator
                                validatorFormControlName=""
                            ></field-validation-indicator>
                        </label>
                    </div>
                    <div class="col-xs-6">
                        <ng-select
                            [items]="
                                missionCompletionService.allEquipment$ | async
                            "
                            [readonly]="working"
                            formControlName="id"
                            bindLabel="name"
                            bindValue="id"
                            appendTo="body"
                            [clearable]="false"
                            (change)="linkEquipment(i, 'id')"
                        >
                        </ng-select>
                    </div>
                    <div class="col-xs-3">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    formControlName="ignored"
                                    (change)="linkEquipment(i, 'ignored')"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                                Ignore
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</form>
