import { Directive, Input, HostBinding } from '@angular/core';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';

/**
 * Sets the image src property to a value that is relative to the site.
 */
@Directive({
    selector: 'img[imgPath]'
})
export class ImageSourceDirective {
    @Input() imgPath: string;
    public pathPrefix: string;

    @HostBinding('src') imgSrc: string;

    constructor(constants: FlyFreelyConstants) {
        this.pathPrefix = constants.IMG_URL;
    }

    ngOnInit() {
        this.imgSrc = this.pathPrefix + this.imgPath;
    }
}
