import { Injectable } from '@angular/core';
import {
    fromLocalDate,
    RiskDto,
    RiskRegisterService,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FormatBooleanPipe } from 'libs/ui/src/lib/pipes/generic/formatBoolean';
import { FormatDatePipe } from 'libs/ui/src/lib/pipes/generic/formatDate';

const riskFields: FormlyFieldConfig[] = [
    {
        key: 'riskCategory',
        type: 'input',
        props: {
            label: 'Risk Category',
            description: 'The classification for this risk',
            required: true
        }
    },
    {
        key: 'dateIdentified',
        type: 'date',
        props: {
            label: 'Date Identified',
            description:
                'The date when this risk was first added to the register',
            required: true,
            formatter: FormatDatePipe
        }
    },
    {
        key: 'riskDescription',
        type: 'textarea',
        props: {
            label: 'Risk Description',
            description: 'What is this risk and how can it happen',
            required: true
        }
    },
    {
        key: 'riskConsequence',
        type: 'textarea',
        props: {
            label: 'Risk Consequences',
            description:
                'What will be the consequence of this risk eventuating',
            required: true
        }
    },
    {
        key: 'initialControl',
        type: 'textarea',
        props: {
            label: 'Initial Control',
            description: 'What controls are already in place',
            required: true
        }
    },
    {
        key: 'initialLikelihoodRating',
        type: 'input',
        props: {
            label: 'Initial Likelihood Rating',
            description: 'What is the likelihood of this occurring',
            required: true,
            type: 'number',
            min: 0,
            max: 5
        }
    },
    {
        key: 'initialConsequenceRating',
        type: 'input',
        props: {
            label: 'Initial Consequence Rating',
            description: '',
            required: true,
            type: 'number',
            min: 0,
            max: 5
        }
    },
    {
        key: 'additionalControl',
        type: 'textarea',
        props: {
            label: 'Additional Control',
            description: 'What additional controls will be put in place',
            required: false
        }
    },
    {
        key: 'additionalLikelihoodRating',
        type: 'input',
        props: {
            label: 'Additional Likelihood Rating',
            description: 'What is the likelihood of this risk occurring now',
            required: false,
            type: 'number',
            min: 0,
            max: 5
        }
    },
    {
        key: 'additionalConsequenceRating',
        type: 'input',
        props: {
            label: 'Additional Consequence Rating',
            description: '',
            required: false,
            type: 'number',
            min: 0,
            max: 5
        }
    },
    {
        key: 'expired',
        type: 'checkbox',
        props: {
            label: 'Expired',
            description: 'Has this risk expired and is no longer used',
            required: false,
            formatter: FormatBooleanPipe
        }
    }
];

function setupCreateCommand(organisationId: number, data: any) {
    return {
        ...data,
        organisationId,
        dateIdentified: toLocalDate(data.dateIdentified),
        expired: data.expired || false
    };
}

function setupUpdateCommand(data: any) {
    return {
        ...data,
        dateIdentified: toLocalDate(data.dateIdentified),
        expired: data.expired || false
    };
}

function setupEditData(data: RiskDto) {
    return {
        ...data,
        dateIdentified: fromLocalDate(data.dateIdentified)
    };
}

@Injectable()
export class RiskRegisterDialoguesService {
    constructor(
        private riskRegisterService: RiskRegisterService,
        private commonDialoguesService: CommonDialoguesService
    ) {}

    showCreateRiskDialogue(organisationId: number) {
        return this.commonDialoguesService.showFormlyDialogue(
            'Create Risk Entry',
            'Save',
            false,
            true,
            riskFields,
            {},
            data =>
                this.riskRegisterService
                    .create(setupCreateCommand(organisationId, data))
                    .toPromise()
        );
    }

    showEditRiskDialogue(risk: RiskDto, canEdit: boolean) {
        return this.commonDialoguesService.showFormlyDialogue(
            'Edit Risk Entry',
            'Save',
            canEdit,
            false,
            riskFields,
            setupEditData(risk),
            data =>
                this.riskRegisterService
                    .update(risk.id, setupUpdateCommand(data))
                    .toPromise()
        );
    }
}
