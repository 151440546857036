<div>
    <div *ngIf="candidateMissions != null && candidateMissions.length === 0">
        <p>There are no existing missions.</p>
    </div>
    <div *ngIf="candidateMissions != null && candidateMissions.length > 0">
        <p>There are {{ candidateMissions.length }} candidate missions.</p>
        <div>
            <label>Mission</label>
            <ng-select
                [items]="candidateMissions"
                [(ngModel)]="selectedMission"
                [clearable]="false"
            >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name }} {{ item.missionDate }}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <button
        [disabled]="selectedMission == null"
        class="btn btn-sm btn-block btn-primary top-buffer"
        type="button"
        (click)="selectMission()"
    >
        Select Mission
    </button>
    <button
        class="btn btn-sm btn-block btn-default top-buffer"
        type="button"
        (click)="cancel.emit()"
    >
        Back
    </button>

    <ngx-loading [show]="working"></ngx-loading>
</div>
