<div class="footer-main--container">
    <div class="footer_container organisation-footer">
        <ng-container *ngIf="!isMobile">
            <div
                class="footer_setupcomplete footer_icon--container setfinished settick org-footer"
            >
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container">
                        <img imgPath="/onboarding/setfinished.png" />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Company Decision
                    </div>
                </div>
            </div>
            <div
                class="footer_organisationdetails footer_icon--container setfinished org-footer"
                [ngClass]="{ settick: tabId > 2 }"
            >
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container">
                        <img imgPath="/onboarding/personal-icon.png" />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Company Profile
                    </div>
                </div>
            </div>
            <div
                class="footer_organisationcertification footer_icon--container org-footer"
                [ngClass]="{ settick: tabId > 3, setfinished: tabId >= 3 }"
            >
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container">
                        <img
                            imgPath="/onboarding/personal-certificate-icon.png"
                        />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Company Authorities
                    </div>
                </div>
            </div>
            <div
                class="footer_setupfinished footer_icon--container org-footer"
                [ngClass]="{ setfinished: tabId >= 4 }"
            >
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container">
                        <img imgPath="/onboarding/setup-icon.png" />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Setup Complete
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <div
                class="footer_setupcomplete footer_icon--container setfinished settick org-footer"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Company Decision
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img imgPath="/onboarding/setfinished.png" />
                    </div>
                </div>
            </div>
            <div
                class="footer_organisationdetails footer_icon--container setfinished org-footer"
                [ngClass]="{ settick: tabId > 2 }"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Company Profile
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img imgPath="/onboarding/personal-icon.png" />
                    </div>
                </div>
            </div>
            <div
                class="footer_organisationcertification footer_icon--container org-footer"
                [ngClass]="{ settick: tabId > 3, setfinished: tabId >= 3 }"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Company Authorities
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img
                            imgPath="/onboarding/personal-certificate-icon.png"
                        />
                    </div>
                </div>
            </div>
            <div
                class="footer_setupfinished footer_icon--container org-footer"
                [ngClass]="{ setfinished: tabId >= 4 }"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Setup Complete
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img imgPath="/onboarding/setup-icon.png" />
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
