<ng-container screenAnalytics="personnel-register">
    <div class="modal-header no-icon">
        <h3 class="modal-title">
            {{ authorityType.name }} {{ title }} Register -
            {{ displayableRegisterModeLookup[register.registerMode] }}
        </h3>
        <enhanced-help-switch></enhanced-help-switch>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
    <div
        class="modal-body no-footer"
        [ngClass]="{ 'no-scroll': expandedSideSheet }"
    >
        <form [formGroup]="formGroup" novalidate>
            <div class="modal-body-content container-scroll">
                <enhanced-help
                    componentName="personnel-register"
                    [compact]="true"
                >
                </enhanced-help>
                <div
                    class="side-sheet-table"
                    *ngIf="!working && register.registerMode !== 'DISABLED'"
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th
                                    style="width: 70px"
                                    *ngIf="hasProfilePicture"
                                ></th>
                                <th class="sortable">
                                    <span (click)="sort('subjectName')">
                                        Name
                                        <ng-container
                                            *ngIf="sortList['subjectName']"
                                        >
                                            <span
                                                *ngIf="
                                                    sortList['subjectName']
                                                        .ascending
                                                "
                                                class="fa fa-sort-up"
                                            >
                                            </span>
                                            <span
                                                *ngIf="
                                                    !sortList['subjectName']
                                                        .ascending
                                                "
                                                class="fa fa-sort-down"
                                            >
                                            </span>
                                        </ng-container>
                                        <span
                                            *ngIf="!sortList['subjectName']"
                                            class="fal fa-sort"
                                        ></span>
                                    </span>
                                </th>
                                <th class="sortable">
                                    <span (click)="sort('startDate')">
                                        Start Date
                                        <ng-container
                                            *ngIf="sortList['startDate']"
                                        >
                                            <span
                                                *ngIf="
                                                    sortList['startDate']
                                                        .ascending
                                                "
                                                class="fa fa-sort-up"
                                            >
                                            </span>
                                            <span
                                                *ngIf="
                                                    !sortList['startDate']
                                                        .ascending
                                                "
                                                class="fa fa-sort-down"
                                            >
                                            </span>
                                        </ng-container>
                                        <span
                                            *ngIf="!sortList['startDate']"
                                            class="fal fa-sort"
                                        ></span>
                                    </span>
                                </th>
                                <th class="sortable">
                                    <span (click)="sort('endDate')">
                                        End Date
                                        <ng-container
                                            *ngIf="sortList['endDate']"
                                        >
                                            <span
                                                *ngIf="
                                                    sortList['endDate']
                                                        .ascending
                                                "
                                                class="fa fa-sort-up"
                                            >
                                            </span>
                                            <span
                                                *ngIf="
                                                    !sortList['endDate']
                                                        .ascending
                                                "
                                                class="fa fa-sort-down"
                                            >
                                            </span>
                                        </ng-container>
                                        <span
                                            *ngIf="!sortList['endDate']"
                                            class="fal fa-sort"
                                        ></span>
                                    </span>
                                </th>
                                <th class="sortable">
                                    <span (click)="sort('status')">
                                        Status
                                        <ng-container
                                            *ngIf="sortList['status']"
                                        >
                                            <span
                                                *ngIf="
                                                    sortList['status'].ascending
                                                "
                                                class="fa fa-sort-up"
                                            >
                                            </span>
                                            <span
                                                *ngIf="
                                                    !sortList['status']
                                                        .ascending
                                                "
                                                class="fa fa-sort-down"
                                            >
                                            </span>
                                        </ng-container>
                                        <span
                                            *ngIf="!sortList['status']"
                                            class="fal fa-sort"
                                        ></span>
                                    </span>
                                </th>
                                <th *ngFor="let check of checks">
                                    {{ check.name }}
                                </th>
                                <th
                                    class="actions right-buffer"
                                    style="border-bottom: none"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-tertiary btn-sm"
                                        (click)="expandedSideSheet = true"
                                    >
                                        <span
                                            class="fa fa-ellipsis-h"
                                            tooltip="Customise the register condition columns"
                                            placement="top"
                                        ></span>
                                    </button>
                                </th>
                            </tr>
                            <tr>
                                <th *ngIf="hasProfilePicture"></th>
                                <th></th>
                                <th style="min-width: 15rem"></th>
                                <th style="min-width: 15rem"></th>
                                <th>
                                    <select
                                        class="form-control"
                                        #filterStatus
                                        (change)="
                                            filterByStatus(filterStatus.value)
                                        "
                                    >
                                        <option [value]="'all'">(All)</option>
                                        <option
                                            *ngFor="let status of statuses"
                                            [value]="status.value"
                                        >
                                            {{ status.name }}
                                        </option>
                                    </select>
                                </th>
                                <th *ngFor="let check of checks"></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody formGroupName="edit">
                            <tr *ngFor="let entry of entries">
                                <td *ngIf="hasProfilePicture">
                                    <profile-picture
                                        [personId]="entry?.subjectId"
                                        [managingOrganisationId]="
                                            organisationId
                                        "
                                        [width]="35"
                                        [height]="35"
                                    >
                                    </profile-picture>
                                </td>
                                <td>
                                    <a (click)="showEntry(entry)">{{
                                        entry.subjectName
                                    }}</a>
                                </td>
                                <td>
                                    <div
                                        *ngIf="entry.editable"
                                        class="input-group"
                                        [class.has-error]="
                                            hasError('edit.startDate')
                                        "
                                    >
                                        <input
                                            class="form-control"
                                            #editStartDate="bsDatepicker"
                                            bsDatepicker
                                            formControlName="startDate"
                                        />
                                        <span
                                            class="input-group-addon btn-default"
                                            (click)="editStartDate.toggle()"
                                            [attr.aria-expanded]="
                                                editStartDate.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                                style="cursor: pointer"
                                            ></i>
                                        </span>
                                    </div>
                                    <validation-messages
                                        *ngIf="entry.editable"
                                        [control]="edit.controls.startDate"
                                    ></validation-messages>
                                    <span *ngIf="!entry.editable">{{
                                        entry.startDate | formatDate
                                    }}</span>
                                </td>
                                <td>
                                    <div
                                        *ngIf="entry.editable"
                                        class="input-group"
                                        [class.has-error]="
                                            hasError('edit.endDate')
                                        "
                                    >
                                        <input
                                            class="form-control"
                                            #editEndDate="bsDatepicker"
                                            bsDatepicker
                                            formControlName="endDate"
                                            [minDate]="
                                                formGroup.get('edit.startDate')
                                                    .value
                                            "
                                        />
                                        <span
                                            class="input-group-addon btn-default"
                                            (click)="editEndDate.toggle()"
                                            [attr.aria-expanded]="
                                                editEndDate.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                                style="cursor: pointer"
                                            ></i>
                                        </span>
                                    </div>
                                    <validation-messages
                                        *ngIf="entry.editable"
                                        [control]="edit.controls.endDate"
                                    ></validation-messages>
                                    <span *ngIf="!entry.editable">{{
                                        entry.endDate | formatDate
                                    }}</span>
                                </td>
                                <td>
                                    <ng-select
                                        *ngIf="entry.editable"
                                        [items]="statuses"
                                        bindLabel="name"
                                        bindValue="value"
                                        appendTo="body"
                                        [clearable]="false"
                                        formControlName="statusSource"
                                        placeholder="Status of this record"
                                    >
                                    </ng-select>
                                    <span *ngIf="!entry.editable">
                                        <register-status-display
                                            [status]="
                                                checkResults[entry.id]?.status
                                            "
                                        ></register-status-display>
                                    </span>
                                </td>
                                <td *ngFor="let check of checks">
                                    <ng-container
                                        *ngIf="
                                            findCheckResult(
                                                entry,
                                                check
                                            ) as checkResult
                                        "
                                    >
                                        <span
                                            *ngIf="checkResult.popover == null"
                                            [innerHtml]="checkResult.content"
                                        ></span>
                                        <span
                                            *ngIf="checkResult.popover != null"
                                            #pop="bs-popover"
                                            [popover]="
                                                authorityCheckPopoverTemplate
                                            "
                                            container="body"
                                            [outsideClick]="true"
                                            placement="bottom"
                                            (click)="openPopover(pop)"
                                            [innerHtml]="checkResult.content"
                                            style="cursor: pointer"
                                        >
                                        </span>
                                        <action-required
                                            *ngIf="
                                                mappedExpiries != null &&
                                                mappedExpiries[entry.id] !=
                                                    null &&
                                                mappedExpiries[entry.id][
                                                    checkResult.checkId
                                                ] != null &&
                                                mappedExpiries[entry.id][
                                                    checkResult.checkId
                                                ].expiryStatus === 'UPCOMING'
                                            "
                                            [noPopover]="true"
                                            tooltipText="This element has an upcoming expiry"
                                        ></action-required>
                                        <action-required
                                            *ngIf="
                                                mappedExpiries != null &&
                                                mappedExpiries[entry.id] !=
                                                    null &&
                                                mappedExpiries[entry.id][
                                                    checkResult.checkId
                                                ] != null &&
                                                mappedExpiries[entry.id][
                                                    checkResult.checkId
                                                ].expiryStatus === 'EXPIRED'
                                            "
                                            [noPopover]="true"
                                            tooltipText="This element has expired"
                                        ></action-required>
                                        <ng-template
                                            #authorityCheckPopoverTemplate
                                        >
                                            <organisation-authority-check
                                                [authorityId]="
                                                    checkResult.verificationEntityId
                                                "
                                                [managingOrganisationId]="
                                                    managingOrganisationId
                                                "
                                                [type]="check.conditionType"
                                                [details]="checkResult.details"
                                                [description]="
                                                    check.description
                                                "
                                                [checkDetails]="
                                                    checkResult.activity
                                                "
                                                [registerConditionsHandler]="
                                                    personnelRegister
                                                "
                                                [checkName]="check.name"
                                                [acknowledgedAttachment]="
                                                    findCheckAttachment(
                                                        check.name
                                                    )
                                                "
                                                register="PERSONNEL"
                                                [subjectId]="entry.subjectId"
                                                [registerId]="register.id"
                                                (onCheckUpdated)="
                                                    onCheckUpdated()
                                                "
                                            >
                                            </organisation-authority-check>
                                        </ng-template>
                                    </ng-container>
                                </td>
                                <td class="actions">
                                    <div class="inline" *ngIf="entry.editable">
                                        <button
                                            class="btn btn-sm btn-primary"
                                            type="button"
                                            (click)="updateConcreteEntry()"
                                            [disabled]="working || edit.invalid"
                                        >
                                            Save
                                        </button>
                                        <button
                                            class="btn btn-sm"
                                            type="button"
                                            (click)="cancelEditMode(entry)"
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                    <div class="inline" *ngIf="!entry.editable">
                                        <button
                                            class="btn btn-sm btn-tertiary"
                                            *ngIf="entry.id"
                                            (click)="showEditMode(entry)"
                                        >
                                            <span
                                                class="fal fa-pencil-alt"
                                            ></span>
                                        </button>
                                        <button
                                            class="btn btn-sm btn-tertiary btn-delete"
                                            *ngIf="entry.id"
                                            (click)="deleteConcreteEntry(entry)"
                                        >
                                            <span
                                                class="fal fa-trash-alt"
                                            ></span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot formGroupName="add">
                            <tr>
                                <td
                                    [attr.colspan]="
                                        hasProfilePicture ? '2' : '1'
                                    "
                                    [class.has-error]="
                                        hasError('add.subjectId')
                                    "
                                >
                                    <ng-select
                                        [items]="candidates"
                                        bindLabel="name"
                                        bindValue="id"
                                        appendTo="body"
                                        [clearable]="false"
                                        formControlName="subjectId"
                                        placeholder="The person to add to the register"
                                        [readonly]="working"
                                    >
                                    </ng-select>
                                </td>
                                <td>
                                    <div
                                        class="input-group"
                                        [class.has-error]="
                                            hasError('add.startDate')
                                        "
                                    >
                                        <input
                                            class="form-control"
                                            #startDatePicker="bsDatepicker"
                                            bsDatepicker
                                            formControlName="startDate"
                                            [readonly]="working"
                                        />
                                        <span
                                            class="input-group-addon btn-default"
                                            (click)="startDatePicker.toggle()"
                                            [attr.aria-expanded]="
                                                startDatePicker.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                                style="cursor: pointer"
                                            ></i>
                                        </span>
                                    </div>

                                    <validation-messages
                                        [control]="add.controls.startDate"
                                    ></validation-messages>
                                </td>
                                <td>
                                    <div
                                        class="input-group"
                                        [class.has-error]="
                                            hasError('add.endDate')
                                        "
                                    >
                                        <input
                                            class="form-control"
                                            #endDatePicker="bsDatepicker"
                                            bsDatepicker
                                            formControlName="endDate"
                                            [minDate]="
                                                formGroup.get('add.startDate')
                                                    .value
                                            "
                                            [readonly]="working"
                                        />
                                        <span
                                            class="input-group-addon btn-default"
                                            (click)="endDatePicker.toggle()"
                                            [attr.aria-expanded]="
                                                endDatePicker.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                                style="cursor: pointer"
                                            ></i>
                                        </span>
                                    </div>
                                    <validation-messages
                                        [control]="add.controls.endDate"
                                    ></validation-messages>
                                </td>
                                <td
                                    [attr.colspan]="checks.length + 1"
                                    [class.has-error]="
                                        hasError('add.statusSource')
                                    "
                                >
                                    <ng-select
                                        [items]="statuses"
                                        bindLabel="name"
                                        bindValue="value"
                                        appendTo="body"
                                        [clearable]="false"
                                        formControlName="statusSource"
                                        placeholder="Status of this record"
                                        [readonly]="working"
                                    >
                                    </ng-select>
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-primary"
                                        [disabled]="
                                            working ||
                                            add.invalid ||
                                            add.pristine
                                        "
                                        (click)="createConcreteEntry()"
                                    >
                                        Save
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div
                    class="vertical-container fill"
                    *ngIf="register.registerMode === 'DISABLED' && !working"
                >
                    <empty
                        componentName="personnel-register-disabled"
                        [disabledState]="true"
                    >
                        <button
                            type="button"
                            class="btn btn-default top-buffer"
                            (click)="expandedSideSheet = true"
                        >
                            Edit register settings
                        </button>
                    </empty>
                </div>
                <registers-side-sheet
                    [expand]="expandedSideSheet"
                    [registerConditionsHandler]="registerConditionsHandler"
                    (conditionsChanged)="onConditionsChanged()"
                    (closeSideSheet)="expandedSideSheet = false"
                    ><button
                        *ngIf="hasCurrency"
                        type="button"
                        class="btn btn-sm btn-default btn-block"
                        (click)="showCurrencyConfiguration()"
                    >
                        Configure Currency
                    </button>
                    <upsell
                        *ngIf="!hasCurrency"
                        componentName="currencyConfig"
                        layout="NARROW"
                    ></upsell>
                </registers-side-sheet>
            </div>
        </form>
    </div>
</ng-container>

<ng-template #checkTemplate>
    <register-conditions
        [registerConditionsHandler]="registerConditionsHandler"
        (conditionsChanged)="onConditionsChanged()"
    >
    </register-conditions>
</ng-template>
