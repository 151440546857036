import { Pipe, PipeTransform } from '@angular/core';

export type TypeGuard<A, B extends A> = (a: A) => a is B;

/**
 * Implements a typeguard in a template.
 * From https://github.com/angular/angular/issues/34522#issuecomment-762973301
 *
 * e.g., where `isCircle` is a type guard function provided by the class.
 * ```
   <span *ngIf="shape| guardType: isCircle as circle">
      <!-- circle is strongly typed as a Circle -->
      radius = {{circle.radius}}
    </span>
    ```
 */
@Pipe({
    name: 'guardType'
})
export class GuardTypePipe implements PipeTransform {
    transform<A, B extends A>(
        value: A,
        typeGuard: TypeGuard<A, B>
    ): B | undefined {
        return typeGuard(value) ? value : undefined;
    }
}
