import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AttachmentHandler } from '@flyfreely-portal-ui/flyfreely';
import { AttachmentHandlerService } from 'libs/comments/src/lib/attachment-handler.service';

@Injectable()
export class FetchImageService {
    constructor(
        private httpClient: HttpClient,
        private attachmentHandlerService: AttachmentHandlerService
    ) {}

    fetchImage(url: string) {
        return new Promise((resolve, reject) => {
            this.httpClient.get(url, { responseType: 'blob' }).subscribe(
                blob => {
                    const blobUrl = URL.createObjectURL(blob);
                    resolve(blobUrl);
                },
                error => {
                    console.error('Error fetching image:', error);
                    reject(error);
                }
            );
        });
    }

    async uploadImage(file: File): Promise<string> {
        try {
            // const attachmentHandler = new AttachmentHandler(
            //     this.httpClient,
            //     '/webapi/libraries/37/attachments',
            //     false,
            //     21,
            //     false
            // );
            if (this.attachmentHandlerService.attachmentHandler) {
                const attachment =
                    await this.attachmentHandlerService.attachmentHandler.uploadNewAttachment(
                        file.name,
                        '',
                        0,
                        file,
                        'Image',
                        false,
                        () => {}
                    );
                return attachment.downloadUrl!;
            } else {
                throw new Error('AttachmentHandler is null');
            }
        } catch (error) {
            console.error('Image upload failed:', error);
            throw error;
        }
    }
}
