import {
    Directive,
    ElementRef,
    Input,
    Optional,
    Renderer2
} from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnhancedHelpService } from './enhanced-help.service';

/**
 * This directive is used to allow text in HTML elements, like buttons, to be set
 * via the Enhanced Help editor.
 * If the screen name needs to be set it can be via the `screenName` attribute
 * but having it set by the screen analytics is better.
 * A default text value can be supplied that will display on the element if the enhanced help entry is
 * blank/null.
 *
 * `<button dynamicText defaultText="Click Me" enhancedHelp="schedule-triggers-button"></button>`
 */
@Directive({
    selector: '[dynamicText][enhancedHelp]'
})
export class EnhancedHelpDynamicText {
    @Input()
    enhancedHelp: string;
    @Input() screenName: string;
    /**
     * Is this enhanced help directive optional? Useful when we are running the directive on a reusable component
     * that might not be inside a screenAnalytics directive.
     */
    @Input() enhancedHelpOptional = false;
    @Input() defaultText: string;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private elRef: ElementRef,
        private renderer: Renderer2,
        private enhancedHelpService: EnhancedHelpService,
        private logging: FlyFreelyLoggingService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {}

    ngOnInit() {
        this.renderer.setProperty(
            this.elRef.nativeElement,
            'textContent',
            this.defaultText
        );
        this.getHelpObject();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    getHelpObject() {
        const screenName =
            this.screenName ?? this.screenAnalytics?.screenAnalytics;

        if (screenName == null) {
            if (this.enhancedHelpOptional) {
                return;
            }
            throw new Error(`screenName not specified and can't be inferred`);
        }

        this.enhancedHelpService
            .findByScreenAndComponent(screenName, this.enhancedHelp)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    if (
                        results != null &&
                        results.helpText != null &&
                        results.helpText.trim().length > 0
                    ) {
                        this.renderer.setProperty(
                            this.elRef.nativeElement,
                            'textContent',
                            results.helpText
                        );
                    }
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            );
    }
}
