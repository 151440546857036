import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    AuthorityTypeDto,
    AuthorityVerificationRecordDto,
    CraftAuthorityDto,
    CraftAuthorityGroup,
    CreateCraftAuthorityCommand,
    CurrentAttachmentVersionDto,
    DisplayableCraftAuthorityDto,
    UpdateAuthorityCommand,
    UpdateCraftAuthorityCommand
} from '../model/api';
import {
    AttachmentHandler,
    DownloadableAttachmentVersionDto
} from './attachments';
import { AuthorityService } from './authorities';
import { GqlQueryResult, GqlSortField } from './interfaces';
import { computePath, httpParamSerializer } from './service.helpers';

export interface UiDisplayableCraftAuthorityDto
    extends DisplayableCraftAuthorityDto {
    attachments: DownloadableAttachmentVersionDto[];
}

export interface UiCraftAuthorityDto extends CraftAuthorityDto {
    attachments: DownloadableAttachmentVersionDto[];
}

export interface RpaAuthoritySortField {
    field: string;
    order: 'ASC' | 'DESC';
}

@Injectable({
    providedIn: 'root'
})
export class RpaAuthorityService
    implements
        AuthorityService<
            UiCraftAuthorityDto,
            CreateCraftAuthorityCommand,
            UpdateAuthorityCommand
        > {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    type: 'ORGANISATION' | 'RPA' = 'RPA';

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findRpaAuthorities(
        organisationId: number,
        page: number,
        pageSize: number,
        expiryStatus: CraftAuthorityDto.ExpiryStatus[] = [
            CraftAuthorityDto.ExpiryStatus.OK,
            CraftAuthorityDto.ExpiryStatus.UPCOMING,
            CraftAuthorityDto.ExpiryStatus.EXPIRED
        ],
        rpaId: number = null,
        sortFields: GqlSortField[] = [
            {
                field: 'expiryDate',
                order: 'ASC'
            }
        ]
    ) {
        return this.apollo
            .query<{
                findRpaAuthorities: GqlQueryResult<any>;
            }>({
                query: gql`
                    query findRpaAuthorities(
                        $organisationId: Long!
                        $rpaId: Long
                        $expiryStatus: [ExpiryStatus]
                        $page: Int
                        $pageSize: Int
                        $sortFields: [RpaAuthoritySortField]
                    ) {
                        findRpaAuthorities(
                            organisationId: $organisationId
                            rpaId: $rpaId
                            expiryStatus: $expiryStatus
                            page: $page
                            pageSize: $pageSize
                            sortFields: $sortFields
                        ) {
                            count
                            results {
                                id
                                rpa {
                                    id
                                    nickname
                                    callSign
                                    status
                                    isDummy
                                    organisation {
                                        id
                                        name
                                    }
                                }
                                authorityType {
                                    id
                                    issuedBy {
                                        id
                                        name
                                        adminNotes
                                        referrer
                                        personalOrganisation
                                        lastActivity
                                        creationTime
                                    }
                                    name
                                    hasExpiry
                                    issuingMechanism
                                    hasIdentifier
                                    hasWorkflow
                                    defaultMissionWorkflowName
                                    isPrimary
                                    appliesTo
                                    activityType
                                    identifierLabel
                                    hasMultipleInstances
                                    abbreviation
                                    requiresApproval
                                    helperText
                                    description
                                    discontinueDate
                                    discontinueMessage
                                    jurisdiction {
                                        id
                                        name
                                        enabled
                                        hasAerodromeData
                                        airspaceCheckSupport
                                    }
                                }
                                startDate
                                expiryDate
                                identifier
                                archived
                                status
                                expiryStatus
                            }
                        }
                    }
                `,
                variables: {
                    organisationId,
                    page,
                    pageSize,
                    expiryStatus,
                    rpaId,
                    sortFields
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findRpaAuthorities)
            );
    }

    findAuthorities(rpaId: number, managingOrganisationId?: number) {
        return this.http
            .get<CraftAuthorityGroup[]>(
                `${this.baseUrl}/webapi/craftAuthorities`,
                {
                    params: httpParamSerializer({
                        managingOrganisationId,
                        craftId: rpaId
                    })
                }
            )
            .pipe(
                map(authorityTypes => {
                    authorityTypes.forEach(authorityType => {
                        if (authorityType.authorities == null) {
                            authorityType.authorities = [];
                        } else {
                            authorityType.authorities.forEach(e => {
                                const buildDownloadUrl = (
                                    d: CurrentAttachmentVersionDto
                                ) => this.attachDownloadUrl(rpaId, e.id, d);
                                e.attachments.forEach(a => buildDownloadUrl(a));
                            });
                        }
                        return authorityType;
                    });
                    return authorityTypes;
                })
            );
    }

    findAuthority(managingOrganisationId: number, authorityId: number) {
        return this.http
            .get<CraftAuthorityDto>(
                `${this.baseUrl}/webapi/craftAuthorities/${authorityId}`,
                {
                    params: httpParamSerializer({ managingOrganisationId })
                }
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    managingOrganisationId,
                                    authorityId,
                                    d
                                )
                            )
                        } as UiCraftAuthorityDto)
                )
            );
    }

    findDisplayableAuthority(
        authorityId: number,
        managingOrganisationId: number
    ) {
        return this.http
            .get<DisplayableCraftAuthorityDto>(
                `${this.baseUrl}/webapi/craftAuthorities/${authorityId}/display`,
                {
                    params: httpParamSerializer({ managingOrganisationId })
                }
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiDisplayableCraftAuthorityDto)
                )
            );
    }

    newAuthority(
        craftId: number,
        authorityTypeId: number,
        managingOrganisationId: number
    ) {
        return this.http
            .post<CraftAuthorityDto>(
                `${this.baseUrl}/webapi/craftAuthorities`,
                {
                    craftId,
                    authorityTypeId,
                    managingOrganisationId
                }
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiCraftAuthorityDto)
                )
            );
    }

    createAuthority(
        authorityId: number,
        createCommand: CreateCraftAuthorityCommand
    ) {
        return this.http
            .put<CraftAuthorityDto>(
                `${this.baseUrl}/webapi/craftAuthorities/${authorityId}`,
                createCommand
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    createCommand.managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiCraftAuthorityDto)
                )
            );
    }

    updateAuthority(
        authorityId: number,
        updateCommand: UpdateCraftAuthorityCommand
    ) {
        return this.http
            .put<CraftAuthorityDto>(
                `${this.baseUrl}/webapi/craftAuthorities/${authorityId}`,
                updateCommand
            )
            .pipe(
                map(
                    authority =>
                        ({
                            ...authority,
                            attachments: authority.attachments.map(d =>
                                this.attachDownloadUrl(
                                    updateCommand.managingOrganisationId,
                                    authority.id,
                                    d
                                )
                            )
                        } as UiCraftAuthorityDto)
                )
            );
    }

    private attachDownloadUrl(
        managingOrganisationId: number,
        authorityId: number,
        document: CurrentAttachmentVersionDto
    ): DownloadableAttachmentVersionDto {
        return {
            ...document,
            downloadUrl: computePath(
                '/webapi/craftAuthorities/' +
                    `/${authorityId}/attachments/${document.id}`,
                { managingOrganisationId }
            )
        };
    }

    attachmentHandler(authorityId: number, managingOrganisationId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/craftAuthorities/${authorityId}/` + `attachments`,
            true,
            managingOrganisationId,
            true
        );
    }

    findAvailableAuthorityTypes(managingOrganisationId: number) {
        return this.http
            .get<AuthorityTypeDto[]>(
                `${this.baseUrl}/webapi/craftAuthorities/authorityTypes`,
                {
                    params: httpParamSerializer({ managingOrganisationId })
                }
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    archiveAuthority(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/rpaAuthorities/${authorityId}/archive?managingOrganisationId=${managingOrganisationId}`;
        return this.http
            .put<CraftAuthorityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    unarchiveAuthority(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/rpaAuthorities/${authorityId}/unarchive?managingOrganisationId=${managingOrganisationId}`;
        return this.http
            .put<CraftAuthorityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    verifyAuthority(authorityId: number, managingOrganisationId: number) {
        return this.http
            .post<AuthorityVerificationRecordDto>(
                `${this.baseUrl}/webapi/craftAuthorities/${authorityId}/verification`,
                {
                    params: httpParamSerializer({ managingOrganisationId })
                }
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteAuthority(authorityId: number, managingOrganisationId: number) {
        const url = `/webapi/craftAuthorities/${authorityId}?managingOrganisationId=${managingOrganisationId}`;
        return this.http.delete<void>(`${this.baseUrl}${url}`).toPromise();
    }
}
