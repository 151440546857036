import { Component, forwardRef } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    DO_NOTHING,
    MaintenanceScheduleTriggerCommand
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface MaintenanceScheduleTriggerCommandForm {
    type: FormControl<MaintenanceScheduleTriggerCommand.Type>;
    action: FormControl<MaintenanceScheduleTriggerCommand.Action>;
    inServiceTime: FormControl<number>;
    calendarTime: FormControl<number>;
    cycles: FormControl<number>;
}

/**
 * This component is a form control that takes the configured trigger, displays all
 * possible triggers, and only returns those triggers which have been configured.
 * A configured trigger is one with an action associated with it.
 */
@Component({
    selector: 'maintenance-schedule-triggers',
    template: `<table class="table">
        <thead>
            <tr>
                <th
                    tooltip
                    placement="top"
                    enhancedHelp="schedule-triggers-type"
                >
                    Type
                </th>
                <th
                    tooltip
                    placement="top"
                    enhancedHelp="schedule-triggers-time"
                >
                    In Service Time (Hours)
                </th>
                <th
                    tooltip
                    placement="top"
                    enhancedHelp="schedule-triggers-flights"
                >
                    Flights/Cycles
                </th>
                <th
                    tooltip
                    placement="top"
                    enhancedHelp="schedule-triggers-calendar"
                >
                    Calendar Time (Days)
                </th>
                <th
                    tooltip
                    placement="top"
                    enhancedHelp="schedule-triggers-action"
                >
                    Action
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of triggerControls.controls" [formGroup]="row">
                <td>
                    {{ row.controls.type.value | formatMaintenanceTriggerType }}
                </td>
                <td>
                    <input
                        type="number"
                        class="form-control"
                        formControlName="inServiceTime"
                        min="0"
                    />
                </td>
                <td>
                    <input
                        type="number"
                        class="form-control"
                        formControlName="cycles"
                        min="0"
                    />
                </td>
                <td>
                    <input
                        type="number"
                        class="form-control"
                        formControlName="calendarTime"
                        min="0"
                    />
                </td>
                <td>
                    <ng-select
                        formControlName="action"
                        [items]="actionOptions"
                        bindLabel="name"
                        bindValue="value"
                    ></ng-select>
                </td>
            </tr>
        </tbody>
    </table>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MaintenanceScheduleTriggers),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MaintenanceScheduleTriggers),
            multi: true
        }
    ]
})
export class MaintenanceScheduleTriggers
    implements ControlValueAccessor, Validator
{
    onChange: (val?: any) => any = DO_NOTHING;
    onTouched: (val?: any) => any = DO_NOTHING;
    onValidate: (val?: any) => any = DO_NOTHING;

    triggerControls = new FormArray<
        FormGroup<MaintenanceScheduleTriggerCommandForm>
    >([]);

    actionOptions = [
        {
            name: 'Notify',
            value: MaintenanceScheduleTriggerCommand.Action.NOTIFY
        },
        {
            name: 'Raise maintenance request',
            value: MaintenanceScheduleTriggerCommand.Action.REQUEST_MAINTENANCE
        },
        {
            name: 'Take out of service',
            value: MaintenanceScheduleTriggerCommand.Action.TAKE_OUT_OF_SERVICE
        }
    ];

    private ngUnsubscribe$ = new Subject<void>();

    constructor() {
        this.triggerControls.push(
            this.buildRow(MaintenanceScheduleTriggerCommand.Type.UPCOMING)
        );
        this.triggerControls.push(
            this.buildRow(MaintenanceScheduleTriggerCommand.Type.DUE)
        );
        this.triggerControls.push(
            this.buildRow(MaintenanceScheduleTriggerCommand.Type.OVERDUE)
        );

        this.triggerControls.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(v =>
                this.onChange(
                    v
                        .filter(f => f.action != null)
                        .map(f => ({
                            ...f,
                            inServiceTime:
                                f.inServiceTime != null
                                    ? f.inServiceTime * 3600
                                    : null
                        }))
                )
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidate = fn;
    }

    writeValue(obj: MaintenanceScheduleTriggerCommand[]): void {
        const parts = obj.reduce(
            (acc, v) => ({
                ...acc,
                [v.type]: {
                    ...v,
                    inServiceTime:
                        v.inServiceTime != null ? v.inServiceTime / 3600 : null
                }
            }),
            {}
        );

        this.triggerControls.controls[0].patchValue(
            parts[MaintenanceScheduleTriggerCommand.Type.UPCOMING] ?? {
                action: null,
                calendarTime: null,
                cycles: null,
                inServiceTime: null
            },
            { onlySelf: true }
        );
        this.triggerControls.controls[1].patchValue(
            parts[MaintenanceScheduleTriggerCommand.Type.DUE] ?? {
                action: null,
                calendarTime: 0,
                cycles: 0,
                inServiceTime: 0
            },
            { onlySelf: true }
        );
        this.triggerControls.controls[2].patchValue(
            parts[MaintenanceScheduleTriggerCommand.Type.OVERDUE] ?? {
                action: null,
                calendarTime: null,
                cycles: null,
                inServiceTime: null
            },
            { onlySelf: true }
        );
    }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    validate(control: AbstractControl): ValidationErrors {
        return {};
        // if (this.groups == null) {
        //     return null;
        // }
        // return this.groups.reduce(
        //     (acc, g) => ({
        //         required: acc.required || g.tasks.valid
        //     }),
        //     {} as ValidationErrors
        // );
    }

    private buildRow(
        type: MaintenanceScheduleTriggerCommand.Type
    ): FormGroup<MaintenanceScheduleTriggerCommandForm> {
        return new FormGroup<MaintenanceScheduleTriggerCommandForm>({
            type: new FormControl<MaintenanceScheduleTriggerCommand.Type>(type),
            action: new FormControl<MaintenanceScheduleTriggerCommand.Action>(
                undefined
            ),
            calendarTime: new FormControl<number>(undefined),
            inServiceTime: new FormControl<number>(undefined),
            cycles: new FormControl<number>(undefined)
        });
    }
}
