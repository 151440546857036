import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AttachmentHandler,
    AuthorityConditionDto,
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    ChangeDetails,
    CheckExpiryDto,
    CreatePersonnelRegisterEntryCommand,
    CurrentAttachmentVersionDto,
    FEATURE_CURRENCY,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameId,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    PersonService,
    PersonnelRegisterEntryDto,
    PersonnelRegisterExpirySummary,
    Register,
    UpdatePersonnelRegisterEntryCommand,
    UpdateRegisterSettingsCommand,
    UserService,
    WorkTracker,
    displayableRegisterModeLookup,
    fromLocalDate,
    hasFeatureFlag,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import {
    FormatDatePipe,
    FormatDateTimePipe,
    FormatPersonPipe
} from '@flyfreely-portal-ui/ui';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { CurrencyDialoguesService } from 'libs/currency/src/lib/currency-dialogues.service';
import { PersonnelDialoguesService } from 'libs/personnel/src/lib/personnel-dialogues.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subject, forkJoin, firstValueFrom } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
    buildCheckContent,
    getCheckResultDetails,
    getPopoverValue,
    mapCheckExpiry,
    validateExistingEntityEndDate,
    validateExistingEntityStartDate,
    validateNewEntityEndDate,
    validateNewEntityStartDate
} from '../helpers';
import {
    CheckResults,
    RegisterCheck,
    RegisterEntry,
    UiRegisterConditionsHandler,
    mergeCheckResults
} from '../interfaces';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'personnel-register-dialogue',
    templateUrl: './personnel-register-dialogue.component.html'
})
export class PersonnelRegisterDialogue implements OnDestroy {
    @Input() authority: OrganisationAuthorityDto;
    @Input() managingOrganisationId: number;
    @Input() organisationId: number;
    @Input() authorityType: AuthorityTypeDto;
    @Input() register: AuthorityRegisterSummaryDto;

    attachmentHandler: AttachmentHandler;
    attachments: CurrentAttachmentVersionDto[];

    candidates: NameId[] = [];
    /**
     * The entries in the register.
     */
    entries: RegisterEntry[] = [];
    entriesBuf: RegisterEntry[] = [];
    sortList: { [key: string]: any };

    registerConditionsHandler: UiRegisterConditionsHandler;
    personnelRegister: Register<
        CreatePersonnelRegisterEntryCommand,
        UpdatePersonnelRegisterEntryCommand,
        PersonnelRegisterEntryDto
    >;

    displayableRegisterModeLookup = displayableRegisterModeLookup;

    checks: RegisterCheck[] = [];
    checkResults: CheckResults;

    expiry: PersonnelRegisterExpirySummary;
    mappedExpiries: {
        [entryId: number]: { [checkId: number]: CheckExpiryDto };
    } = {};

    hasProfilePicture = true;
    title = 'Personnel';

    @ViewChild('popover', { static: false })
    popover: PopoverDirective;

    error: string;

    statuses = [
        { name: 'Pending', value: 'PENDING' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Suspended', value: 'SUSPENDED' },
        { name: 'Auto', value: 'AUTO' }
    ];

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    // the ID of the entry being edited
    editing: number;
    formGroup: FormGroup;

    expandedSideSheet: boolean = false;

    hasCurrency = false;

    constructor(
        public modal: BsModalRef<PersonnelRegisterDialogue>,
        modalOptions: ModalOptions,
        private logging: FlyFreelyLoggingService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private formatPersonPipe: FormatPersonPipe,
        private formatDatePipe: FormatDatePipe,
        private formatDateTimePipe: FormatDateTimePipe,
        private personService: PersonService,
        private commonDialoguesService: CommonDialoguesService,
        private personnelDialoguesService: PersonnelDialoguesService,
        private currencyDialoguesService: CurrencyDialoguesService,
        private userService: UserService
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.popover != null && this.popover.isOpen) {
                this.popover.hide();
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }


    ngOnInit() {
        this.personnelRegister = this.organisationAuthorityService.personnelRegister(
            this.authority.id,
            this.register.id,
            this.managingOrganisationId
            
            
        );

        this.workTracker
            .observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.attachmentHandler = this.organisationAuthorityService.attachmentHandler(
            this.authority.id,
            this.organisationId
        );

        this.attachmentHandler.attachments$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(attachments => (this.attachments = attachments));

        this.formGroup = new FormGroup({
            add: new FormGroup({
                subjectId: new FormControl(undefined, [Validators.required]),
                startDate: new FormControl(undefined, [Validators.required]),
                endDate: new FormControl(undefined),
                statusSource: new FormControl('AUTO', [Validators.required])
            }),
            edit: new FormGroup({
                startDate: new FormControl(undefined, [Validators.required]),
                endDate: new FormControl(undefined),
                statusSource: new FormControl(undefined, [Validators.required])
            })
        });

        this.sortList = {};
        this.fetchData();
        this.registerConditionsHandler = this.getConditionsHandler();

        this.hasCurrency = pipe(
            this.userService.findOrganisationForUser(this.organisationId),
            map(o => hasFeatureFlag(o, FEATURE_CURRENCY)),
            getOrElse(() => false)
        );
    }

    ngOnDestroy(): void {
        if (this.attachmentHandler != null) {
            this.attachmentHandler.destroy();
        }
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    fetchData() {
        const doneWorking = this.workTracker.createTracker();
        forkJoin([
            this.refreshCandidates(),
            this.refreshExisting(),
            this.refreshExpiries()
        ])
            .toPromise()
            .then(([candidates, entries, expiries]) => {
                this.candidates = candidates;
                this.entries = this.entriesBuf = entries;
                this.add.controls.startDate.setValidators([
                    Validators.required,
                    validateNewEntityStartDate(
                        this.entries,
                        this.formGroup,
                        'person'
                    )
                ]);
                this.add.controls.endDate.setValidators([
                    validateNewEntityEndDate(
                        this.entries,
                        this.formGroup,
                        'person'
                    )
                ]);
            })
            .then(() => {
                this.refreshChecks()
                    .then(checks => (this.checks = checks))
                    .then(() =>
                        this.refreshExpiries().then(() =>
                            this.refreshCheckResults()
                        )
                    )
                    .then(checkResults => {
                        this.checkResults = checkResults;
                        doneWorking();
                    });
            });
    }

    refreshRegister() {
        this.organisationAuthorityService
            .findAuthority(this.authority.id, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                authority => {
                    this.authority = authority;
                    const register = authority.registers.find(
                        r => r.id === this.register.id
                    );
                    this.register = register;
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing register details: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    refreshConditions() {
        const doneWorking = this.workTracker.createTracker();
        this.refreshChecks()
            .then(checks => (this.checks = checks))
            .then(() =>
                this.refreshExpiries().then(() => this.refreshCheckResults())
            )
            .then(
                checkResults => {
                    this.checkResults = checkResults;
                    doneWorking();
                },
                error => this.logging.error(error)
            );
    }

    onConditionsChanged() {
        this.refreshRegister();
        this.refreshConditions();
    }

    openPopover(pop: any) {
        this.popover = pop;
    }

    getConditionsHandler() {
        return {
            findCandidateAuthorityTypes: () =>
                this.organisationAuthorityService
                    .findAvailableAuthorityTypes(this.organisationId)
                    .toPromise(),
            findRegisterConditions: () =>
                this.personnelRegister.findRegisterConditions(),
            updateRegisterConditions: (conditions: AuthorityConditionDto[]) =>
                this.personnelRegister.updateRegisterConditions(
                    {
                        managingOrganisationId: this.organisationId,
                        authorityRegisterId: this.register.id,
                        conditions
                    },
                    this.authority.id
                ),
            updateRegisterSettings: (
                registerMode: UpdateRegisterSettingsCommand.RegisterMode
            ) =>
                this.personnelRegister.updateRegisterSettings(
                    {
                        authorityRegisterId: this.register.id,
                        registerMode
                    },
                    this.authority.id
                )
        };
    }

    private toRegisterEntry(c: PersonnelRegisterEntryDto): RegisterEntry {
        return {
            id: c.id,
            startDate: fromLocalDate(c.startDate),
            endDate: fromLocalDate(c.endDate),
            subjectId: c.personId,
            subjectName: this.formatPersonPipe.transform(c.person),
            statusSource: c.statusSource
        };
    }

    refreshExpiries() {
        return this.organisationAuthorityService
            .personnelRegisterExpiries(
                this.authority.id,
                this.register.id,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(expiry => (this.expiry = expiry));
    }

    refreshCandidates(): PromiseLike<NameId[]> {
        return this.personService
            .findRelatedPersonnel(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(personnel =>
                personnel.map(p => ({
                    id: p.id,
                    name: this.formatPersonPipe.transform(p)
                }))
            );
    }

    refreshExisting(): PromiseLike<RegisterEntry[]> {
        return this.personnelRegister
            .find()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(candidates => candidates.map(c => this.toRegisterEntry(c)));
    }

    refreshChecks(): PromiseLike<RegisterCheck[]> {
        return Promise.all([
            this.personnelRegister.findRegisterConditions()
        ]).then(([conditions]) =>
            conditions.conditions
                .filter(c => c.enabled)
                .map(c => ({
                    id: c.id,
                    entity: c.entity,
                    entityId: c.entityId,
                    name: c.name,
                    description: c.description,
                    conditionType: c.conditionType
                }))
        );
    }

    findCheckResult(entry: RegisterEntry, check: RegisterCheck) {
        if (this.checkResults == null || this.checkResults[entry.id] == null) {
            return null;
        }
        const results = this.checkResults[entry.id];
        if (results[check.id] != null) {
            return results[check.id];
        } else if (results[check.entityId] != null) {
            return results[check.entityId];
        } else {
            const key = Object.keys(results)
                .filter(k => k != 'status')
                .find(
                    k => results[k]?.checkConditionType === check.conditionType
                );
            if (key != null) {
                return results[key];
            } else {
                return null;
            }
        }
    }

    refreshCheckResults(): PromiseLike<CheckResults> {
        if (this.entries == null) {
            return Promise.resolve({});
        }

        return Promise.all(
            this.entries.map(e =>
                this.personnelRegister
                    .findCheckValues(e.id)
                    .toPromise()
                    .then(results => {
                        return {
                            status: results.status,
                            entryId: e.id,
                            checks: results.checks.map(r => {
                                if (this.mappedExpiries[e.id] != null) {
                                    this.mappedExpiries = {
                                        ...this.mappedExpiries,
                                        [e.id]: {
                                            ...this.mappedExpiries[e.id],
                                            [r.authorityConditionId]: mapCheckExpiry(
                                                this.expiry,
                                                r,
                                                this.register.id
                                            )
                                        }
                                    };
                                } else {
                                    this.mappedExpiries = {
                                        ...this.mappedExpiries,
                                        [e.id]: {
                                            [r.authorityConditionId]: mapCheckExpiry(
                                                this.expiry,
                                                r,
                                                this.register.id
                                            )
                                        }
                                    };
                                }
                                return {
                                    checkId:
                                        r.authorityConditionId ??
                                        r.verificationEntityId ??
                                        r.entityId ??
                                        -1,
                                    checkConditionType: r.conditionType,
                                    content: buildCheckContent(
                                        r,
                                        this.formatDatePipe.transform,
                                        this.formatDateTimePipe.transform
                                    ),
                                    popover: getPopoverValue(r),
                                    verificationEntityId:
                                        r.verificationEntityId,
                                    details: getCheckResultDetails(
                                        r,
                                        this.authority.id
                                    ),
                                    activity: r
                                };
                            })
                        };
                    })
            )
        ).then(results => results.reduce(mergeCheckResults, {}));
    }

    onCheckUpdated() {
        const doneWorking = this.workTracker.createTracker();
        this.refreshExpiries().then(() =>
            this.refreshCheckResults().then(checkResults => {
                this.checkResults = checkResults;
                doneWorking();
            })
        );
    }

    findCheckAttachment(checkName: string) {
        return this.attachments.find(a => a.name === checkName) ?? null;
    }

    createConcreteEntry() {
        const addFormGroup = <FormGroup>this.formGroup.get('add');
        const entry = addFormGroup.value;

        if (addFormGroup.invalid) {
            addFormGroup.markAllAsTouched();
            this.logging.error(null, 'Not all fields have been completed');
            return;
        }

        const doneWorking = this.workTracker.createTracker();
        firstValueFrom(
            this.organisationAuthorityService
                .personnelRegister(this.authority.id, this.register.id)
                .createRegisterEntry({
                    personId: entry.subjectId,
                    startDate: toLocalDate(entry.startDate),
                    endDate: toLocalDate(entry.endDate),
                    statusSource: entry.statusSource
                })
                .pipe(
                    tap(() =>
                        this.organisationAuthorityService.notifyUpdated(
                            this.authority.id
                        )
                    )
                )
        )
            .then(c => this.toRegisterEntry(c))
            .then(result => {
                this.entries.push(result);
                this.logging.success('New entry added to register');
                addFormGroup.reset();
                addFormGroup.controls.statusSource.patchValue('AUTO');
                return this.refreshExpiries().then(() =>
                    this.refreshCheckResults()
                );
            })
            .then(checkResults => (this.checkResults = checkResults))
            .catch(error => this.logging.error(error))
            .finally(() => doneWorking());
    }

    updateConcreteEntry() {
        const payload = this.formGroup.get('edit').value;

        const doneWorking = this.workTracker.createTracker();
        firstValueFrom(
            this.organisationAuthorityService
                .personnelRegister(this.authority.id, this.register.id)
                .updateRegisterEntry(this.editing, {
                    startDate: toLocalDate(payload.startDate),
                    endDate: toLocalDate(payload.endDate),
                    statusSource: payload.statusSource
                })
                .pipe(
                    tap(() =>
                        this.organisationAuthorityService.notifyUpdated(
                            this.authority.id
                        )
                    )
                )
        )
            .then(c => this.toRegisterEntry(c))
            .then(result => {
                const index = this.entries.findIndex(
                    e => e.id === this.editing
                );
                this.entries.splice(index, 1, result);
                this.editing = null;
                this.logging.success('Register entry updated');
                return this.refreshExpiries().then(() =>
                    this.refreshCheckResults()
                );
            })
            .then(checkResults => (this.checkResults = checkResults))
            .catch(error => this.logging.error(error))
            .finally(() => doneWorking());
    }

    deleteConcreteEntry(entry: RegisterEntry) {
        const deleteRegisterEntry = () => {
            return firstValueFrom(
                this.organisationAuthorityService
                    .personnelRegister(this.authority.id, this.register.id)
                    .deleteRegisterEntry(entry.id)
                    .pipe(
                        tap(() =>
                            this.organisationAuthorityService.notifyUpdated(
                                this.authority.id
                            )
                        )
                    )
            ).then(
                () => {
                    const index = this.entries.indexOf(entry);
                    this.entries.splice(index, 1);
                    this.logging.success('Register entry deleted');
                },
                error => this.logging.error(error)
            );
        };
        this.commonDialoguesService.showConfirmationDialogue(
            'Delete entry',
            'Are you sure you wish to delete this authority register entry?',
            'Delete',
            deleteRegisterEntry
        );
    }

    showEntry(entry: RegisterEntry): void {
        this.personService
            .findPerson(entry.subjectId, this.organisationId)
            .toPromise()
            .then(
                person =>
                    this.personnelDialoguesService.showPersonDetailsDialogue(
                        person,
                        this.organisationId,
                        true
                    ),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error retrieving details for ${entry.subjectName}: ${error.message}`
                    )
            );
    }

    showEditMode(entry: RegisterEntry): void {
        this.editing = entry.id;
        this.edit.setValue({
            startDate: entry.startDate,
            endDate: entry.endDate,
            statusSource: entry.statusSource
        });
        this.edit.controls.startDate.setValidators([
            Validators.required,
            validateExistingEntityStartDate(entry.id, this.entries, 'person')
        ]);
        this.edit.controls.endDate.setValidators([
            validateExistingEntityEndDate(entry.id, this.entries, 'person')
        ]);
        entry.editable = true;
    }

    cancelEditMode(entry: RegisterEntry): void {
        entry.editable = false;
        this.editing = null;
    }

    sort(name: string) {
        if (this.sortList[name]) {
            this.sortList[name] = {
                ascending: !this.sortList[name].ascending
            };
        } else {
            this.sortList = {};
            this.sortList[name] = {
                ascending: true
            };
        }
        this.sortTable(name, this.sortList[name].ascending);
    }

    sortTable(name: string, ascending: boolean) {
        if (ascending) {
            this.entryAsc(name);
        } else {
            this.entryDes(name);
        }
    }

    entryAsc(name: string) {
        this.entries.sort((a, b) => {
            if (a[name] > b[name]) {
                return 1;
            } else if (a[name] < b[name]) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    entryDes(name: string) {
        this.entries.sort((a, b) => {
            if (a[name] > b[name]) {
                return -1;
            } else if (a[name] < b[name]) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    filterByStatus(value: string) {
        if (value === 'all') {
            this.entries = this.entriesBuf;
        } else {
            this.entries = this.entriesBuf.filter(entry => {
                return this.checkResults[entry.id]?.status === value;
            });
        }
    }

    hasError(controlName: string) {
        const control = this.formGroup?.get(controlName);
        return (control.touched || control.dirty) && control.invalid;
    }

    showCurrencyConfiguration() {
        this.currencyDialoguesService
            .showCurrencyConfigurationDialogue(
                this.authorityType.jurisdiction.id,
                this.register.id,
                this.managingOrganisationId
            )
            .onHide.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.onConditionsChanged());
    }

    get add() {
        return this.formGroup.get('add') as FormGroup;
    }

    get edit() {
        return this.formGroup.get('edit') as FormGroup;
    }
}
