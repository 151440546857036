import { Component, Input, OnInit } from '@angular/core';
import {
    AttachmentHandler,
    SimpleAuthorityDto,
    DisplayableMissionDto,
    OrganisationAuthorityService,
    SimpleAuthorityTypeDto
} from '@flyfreely-portal-ui/flyfreely';

interface Authority {
    id: number;
    startDate: string;
    endDate: string;
    name: string;
    identifier: string;
    attachmentHandler: AttachmentHandler;
    authorityType: SimpleAuthorityTypeDto;
}

@Component({
    selector: 'authority-library',
    templateUrl: './authority-library.component.html'
})
export class AuthorityLibrary implements OnInit {
    @Input() organisationId: number;
    @Input() mission: DisplayableMissionDto;

    additionalWorkflowAuthorities: SimpleAuthorityDto[];
    additionalAuthorities: SimpleAuthorityDto[];
    authorities: Authority[];

    constructor(
        private organisationAuthorityService: OrganisationAuthorityService
    ) {}

    ngOnInit() {
        this.authorities = [];
        this.additionalWorkflowAuthorities = this.mission.missionWorkflowVersion.additionalAuthorities;
        this.additionalAuthorities = this.mission.additionalAuthorities;
        this.refreshAuthorities();
    }

    private refreshAuthorities() {
        if (this.organisationId == null) {
            return;
        }

        this.authorities = []
            .concat(this.findDelegatedAuthority())
            .concat(this.findAdditionalWorkflowAuthorities())
            .concat(this.findAdditionalMissionAuthorities());
    }

    private findDelegatedAuthority() {
        const { delegatedAuthority } = this.mission.missionWorkflowVersion;

        return delegatedAuthority != null
            ? [this.toAuthority(delegatedAuthority)]
            : [];
    }

    private findAdditionalWorkflowAuthorities() {
        const additionalAuthorities =
            this.mission?.missionWorkflowVersion?.additionalAuthorities ?? [];

        return additionalAuthorities.map(a => this.toAuthority(a));
    }

    private findAdditionalMissionAuthorities() {
        const additionalAuthorities = this.mission?.additionalAuthorities ?? [];

        return additionalAuthorities.map(a => this.toAuthority(a));
    }

    private toAuthority(authority: SimpleAuthorityDto) {
        return {
            id: authority.authorityType.id,
            name: authority.authorityType.name,
            startDate: authority.startDate,
            endDate: authority.expiryDate,
            identifier: authority.identifier,
            attachmentHandler: this.getAttachmentsHandler(authority.id),
            authorityType: authority.authorityType
        };
    }

    private getAttachmentsHandler(id: number) {
        const attachmentHandler: AttachmentHandler = this.organisationAuthorityService.attachmentHandler(
            id,
            this.organisationId
        );

        return attachmentHandler;
    }
}
