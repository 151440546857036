import { ValidatorFn } from '@angular/forms';
import { AdminOrganisationDto, PersonsOrganisationDto } from './api';

/**
 * This file contains common UI models that don't originate from the API.
 */

/**
 * Used for most lookup lists.
 */
export interface NameValue<T = string> {
    name: string;
    value: T;
}

/**
 * Another common lookup interface.
 */
export interface NameId {
    id: number;
    name: string;
}

/**
 * A lookup interface that includes the archived status of the entry. Useful for filtering entries by active.
 */
export interface NameIdArchived {
    id: number;
    name: string;
    archived: boolean;
}

/**
 * A lookup interface for form controls with optional validators.
 */
export interface NameValueValidation<T = string> {
    name: string;
    value: T;
    validator?: ValidatorFn;
}

/**
 * Label Value is used for ng-select options.
 */
export interface LabelValue<T = string> {
    name: string;
    value: T;
}

/**
 * This type is used to describe types of controls from a form editor perspective.
 */
export interface ControlTypeDescriptor {
    type: string;
    name: string;
    hasLabel: boolean;
    hasOptions: boolean;
    hasPlaceholder: boolean;
    hasInput: boolean;
    hasConfig: boolean;
    config: NameValueValidation[];
    configValidator?: ValidatorFn;
}

/**
 * This union type is for accepting any organisation type.
 */
export type Organisation = AdminOrganisationDto | PersonsOrganisationDto;

/**
 * An option in a list for a type of report to print
 */
export interface PrintOption {
    name: string;
    description: string;
    print: (args?: any) => void;
}

/*
Layers in the map can be grouped. If they are not grouped, they are shown in e.g. admin with this gruop name.
*/
export const UncategorizedLayerGroupName = 'Uncategorized';

/*
Metadata key of the value for the layer group name
*/
export const MetadataKeyGroupName = 'flyfreely:groupname';

/*
Metadata key of the template
*/
export const MetadataKeyTemplateName = 'flyfreely:template';

/*
Metadata key of the "Shown by default" value
*/
export const MetadataKeyIsDefault = 'flyfreely:default';
