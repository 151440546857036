import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'airspace-authorisation-consent-for-trial',
    template: `
        <div class="modal-header no-icon">
            <h3 class="modal-title pull-left">CRP Consent For Trial Access</h3>
            <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="close()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body inner-container-and-footer">
            <div>
                Thank you for agreeing to participate in a trial enabling the
                FlyFreely Office App (“the app”) to process requests to operate
                RPAS in CASA approved areas (airspace authorisation requests).
                <br /><br />
                During the trial, you will be able to submit airspace
                authorisation requests through the app. When you do, CASA will
                need to share some of your personal information with FlyFreely
                (“us” or “we”) and Airservices Australia so your identity can be
                verified. This information will include the aviation reference
                number (ARN) and remotely piloted operator certificate (ReOC)
                number CASA has issued you.
                <br /><br />
                Neither we nor Airservices Australia will use this information
                for any other purpose. We will protect your personal information
                in accordance with our privacy policy [insert link to app
                developer privacy policy]. For information about how Airservices
                Australia may handle your personal information, refer to its
                privacy policy.
                <br /><br />
                Please click the boxes below to indicate your consent to your
                personal information being used in this way. Without this
                consent, we will be unable to process any airspace authorisation
                request you may make.
            </div>
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="acknowledgeCrp"
                        [(ngModel)]="acknowledgeShare"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                    I consent to CASA sharing my ARN, ReOC number and other
                    relevant personal information with you and Airservices
                    Australia for the purpose of verifying my identity during
                    the trial.
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="acknowledgeCrp"
                        [(ngModel)]="acknowledgeReoc"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                    If I have opted-in to the trial on behalf of an organisation
                    I work for, I confirm I am authorised by the organisation to
                    provide its ARN, ReOC number and other company identifiers
                    for that purpose during the trial.
                </label>
            </div>
            <div class="action-container" style="text-align: center">
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="!acknowledgeShare || !acknowledgeReoc"
                    (click)="acknowledge()"
                >
                    OK
                </button>
            </div>
        </div>
    `
})
export class AirspaceConsentForTrialDialogue {
    @Output() acknowledged = new EventEmitter<void>();
    acknowledgeShare: boolean;
    acknowledgeReoc: boolean;

    constructor(private modal: BsModalRef) {}

    close() {
        this.modal.hide();
    }

    acknowledge() {
        this.acknowledged.emit();
        this.modal.hide();
    }
}
