/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.47-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TriggerActivationDto } from './triggerActivationDto';


/**
 * The RPA that was involved in this incident report
 */
export interface CraftDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The amount of flight time the RPA apart from the logged flights
     */
    initialFlightTime: number;
    /**
     * The amount the RPA is insured for
     */
    insuredValue?: number;
    /**
     * The UID of the NFC tag on the RPA
     */
    nfcUid?: string;
    /**
     * The name or designation of the RPA
     */
    nickname: string;
    /**
     * The specific call sign for the RPA
     */
    callSign?: string;
    /**
     * When was the battery RPA
     */
    procurementDate?: string;
    /**
     * The date the RPA was no longer owned by the organisation
     */
    disposalDate?: string;
    /**
     * The current status of the RPA
     */
    status: CraftDto.Status;
    /**
     * The serial number printed on the RPA
     */
    manufacturerSerialNumber?: string;
    /**
     * The serial number returned in RPA logs
     */
    internalSerialNumber?: string;
    /**
     * The organisation that owns the RPA
     */
    organisationId: number;
    /**
     * ${swagger.Rpa.organisationName}
     */
    organisationName: string;
    /**
     * Is this a dummy RPA, who\'s logged flights will not count towards log books
     */
    isDummy: boolean;
    /**
     * The maximum distance at which the RPA can be seen
     */
    maximumVisualDistance?: number;
    /**
     * ${swagger.Rpa.craftModelId}
     */
    rpaTypeId: number;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    maintenanceScheduleTriggerActivation?: TriggerActivationDto;
    associatedBatterySetIdList: Array<number>;
    associatedEquipmentIdList: Array<number>;
    workgroupIdList: Array<number>;
    /**
     * The name or designation of the RPA
     */
    name: string;
}
export namespace CraftDto {
    export type Status = 'SERVICEABLE' | 'UNSERVICEABLE' | 'UNDER_MAINTENANCE' | 'RETIRED' | 'DISPOSED' | 'DELETED';
    export const Status = {
        SERVICEABLE: 'SERVICEABLE' as Status,
        UNSERVICEABLE: 'UNSERVICEABLE' as Status,
        UNDER_MAINTENANCE: 'UNDER_MAINTENANCE' as Status,
        RETIRED: 'RETIRED' as Status,
        DISPOSED: 'DISPOSED' as Status,
        DELETED: 'DELETED' as Status
    };
}



