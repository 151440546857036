<div class="modal-header no-icon">
    <h4 id="dialog-child-name" class="modal-title pull-left">{{ title }}</h4>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hide()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [innerHTML]="message"></div>
    <loading-indicator [isLoading]="working"></loading-indicator>
</div>
<div class="modal-footer">
    <div class="checkbox pull-left" *ngIf="showDontAskAgain">
        <label class="">
            <input
                type="checkbox"
                name="dontAskAgain"
                [(ngModel)]="dontAskAgain"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="working"
            />
            <span class="cr"
                ><i class="cr-icon glyphicon glyphicon-ok"></i>
            </span>
            Don't ask again
        </label>
    </div>
    <button
        class="btn btn-primary pull-right"
        type="button"
        [disabled]="working"
        (click)="ok()"
    >
        {{ action || 'OK' }}
    </button>
</div>
