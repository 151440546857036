import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    DO_NOTHING,
    FEATURE_EXTERNAL_MAINTENANCE_PERSON,
    FEATURE_INSTANT_MISSIONS,
    FEATURE_HIDDEN_READ_ONLY_ROLE,
    FEATURE_READ_ONLY_ROLE,
    FEATURE_STUDENT_ROLE,
    findOrganisation,
    FlyFreelyError,
    FlyFreelyLoggingService,
    hasFeatureFlag,
    PersonRolesDto,
    PersonService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { editPersonFields } from 'libs/personnel/src/lib/fieldsets';
import { isPersonalOrganisationSubscription } from 'libs/subscriptions/src/lib/helpers';
import { OrganisationSubscriptionsState } from 'libs/subscriptions/src/lib/interfaces';
import { FormControl, FormGroup } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { createRolesFormGroup, loadRoleValues, toRoleList } from '../helpers';

@Component({
    selector: 'personnel-edit',
    templateUrl: './personnel-edit.component.html'
})
export class PersonnelEdit implements OnInit, OnDestroy {
    @Input() person: PersonRolesDto;
    @Input() organisationId: number;
    @Input() licences: OrganisationSubscriptionsState;
    @Output() personUpdated = new EventEmitter<PersonRolesDto>();
    @Output() cancelled = new EventEmitter<void>();

    originPerson: PersonRolesDto;
    isNewPerson: boolean;
    canEditDetails: boolean;
    fields: any;
    personRolesForm: FormGroup;
    personDetailsForm: FormGroup;
    personForm: FormGroup;

    allowStudent: boolean;
    allowReadOnly: boolean;
    allowInstantMission: boolean;
    allowReadOnlyHidden: boolean;;
    allowPilot: boolean;
    allowExternalMaintenancePerson: boolean;
    availablePilots: number;
    totalPilots: number;

    hasAvailablePilotsOnInit: boolean;
    alreadyHasPilotRole: boolean;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private personService: PersonService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.canEditDetails = this.person.canManage;

        this.findPermissions();

        this.fields = editPersonFields(
            !this.isNewPerson,
            this.isNewPerson,
            this.canEditDetails
        );

        this.alreadyHasPilotRole = this.person?.roles.includes(
            PersonRolesDto.Roles.PILOT
        );

        this.initForm();

        this.personRolesForm.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                if (
                    !isPersonalOrganisationSubscription(this.licences) &&
                    this.availablePilots != null &&
                    this.hasAvailablePilotsOnInit
                ) {
                    if (
                        this.personRolesForm.value.PILOT &&
                        this.alreadyHasPilotRole
                    ) {
                        this.availablePilots = this.licences.availablePilots;
                    } else if (
                        this.personRolesForm.value.PILOT &&
                        !this.alreadyHasPilotRole
                    ) {
                        this.availablePilots =
                            this.licences.availablePilots - 1;
                    } else if (
                        !this.personRolesForm.value.PILOT &&
                        this.alreadyHasPilotRole
                    ) {
                        this.availablePilots =
                            this.licences.availablePilots + 1;
                    } else {
                        this.availablePilots = this.licences.availablePilots;
                    }
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupPersonForm() {
        if (!this.person.roles) {
            this.person.roles = [];
        }
        const formControlKeys = Object.keys(this.person);
        const formControls = formControlKeys.reduce(
            (acc, key) => ({ ...acc, [key]: new FormControl(undefined) }),
            {}
        );
        this.personDetailsForm = new FormGroup(formControls);
    }

    private findPermissions() {
        const organisation = findOrganisation(
            this.userService.findUsersOrganisations(),
            this.organisationId
        );

        this.allowStudent = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_STUDENT_ROLE)),
            getOrElse(() => false)
        );

        this.allowReadOnly = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_READ_ONLY_ROLE)),
            getOrElse(() => false)
        );

        this.allowInstantMission = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_INSTANT_MISSIONS)),
            getOrElse(() => false)
        );

        this.allowReadOnlyHidden = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_HIDDEN_READ_ONLY_ROLE)),
            getOrElse(() => false)
        );

        this.allowExternalMaintenancePerson = pipe(
            organisation,
            map(o => hasFeatureFlag(o, FEATURE_EXTERNAL_MAINTENANCE_PERSON)),
            getOrElse(() => false)
        );

    }

    findPilotLimit() {
        if (
            isPersonalOrganisationSubscription(this.licences) ||
            this.licences.availablePilots == null
        ) {
            this.allowPilot = true;
            return;
        }

        this.allowPilot =
            this.alreadyHasPilotRole || this.licences.availablePilots > 0;

        this.totalPilots = this.licences.totalPilots;

        if (this.personRolesForm.value.PILOT && this.alreadyHasPilotRole) {
            this.availablePilots = this.licences.availablePilots;
        } else if (
            this.personRolesForm.value.PILOT &&
            !this.alreadyHasPilotRole
        ) {
            this.availablePilots = this.licences.availablePilots - 1;
        } else if (
            !this.personRolesForm.value.PILOT &&
            this.alreadyHasPilotRole
        ) {
            this.availablePilots = this.licences.availablePilots + 1;
        } else {
            this.availablePilots = this.licences.availablePilots;
        }
    }

    initForm() {
        this.personRolesForm = createRolesFormGroup();
        this.setupPersonForm();
        this.originPerson = Object.assign({}, this.person);

        this.personForm = new FormGroup({
            personDetails: this.personDetailsForm,
            personRoles: this.personRolesForm
        });

        this.personDetailsForm.setValue(this.originPerson);
        this.personRolesForm.setValue(loadRoleValues(this.person.roles));

        this.personForm.updateValueAndValidity();
        this.personForm.markAsPristine();

        this.hasAvailablePilotsOnInit =
            !isPersonalOrganisationSubscription(this.licences) &&
            (this.alreadyHasPilotRole || this.licences.availablePilots > 0);

        this.findPilotLimit();
    }

    onSubmit() {
        const roles = toRoleList(this.personRolesForm.value);
        if (roles.length === 0) {
            this.commonDialoguesService
                .showConfirmationDialogue(
                    'Remove person from organisation',
                    `By deselecting all of this person's roles you will remove them from this organisation. You will still be able to view them via the archived personnel section of your personnel list, but will have to re-invite them in order to re-assign them any roles. Do you wish to continue?`,
                    'Yes',
                    () => Promise.resolve()
                )
                .then(() => {
                    this.savePerson(roles);
                }, DO_NOTHING);
        } else {
            this.savePerson(roles);
        }
    }

    savePerson(roles: PersonRolesDto.Roles[]) {
        if (this.canEditDetails) {
            // FIXME: Need to figure out why the form values aren't trustworthy here, but the variable is
            // If using the personForm value the email field is missing, but everything is correct on the originPerson object
            const updatePerson = {
                organisationId: this.organisationId,
                firstName: this.originPerson.firstName,
                lastName: this.originPerson.lastName,
                email: this.originPerson.email,
                phoneNumber: this.originPerson.phoneNumber
            };

            this.personService
                .updatePerson(this.person.id, updatePerson)
                .pipe(
                    switchMap(person => {
                        this.person = { ...this.person, ...person };
                        this.personDetailsForm.patchValue(person);
                        return this.saveRoles(roles);
                    }),
                    takeUntil(this.ngUnsubscribe$)
                )
                .subscribe({
                    next: () => {
                        this.personForm.markAsPristine();
                        this.showToastNotifyAndClose();
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error updating person: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            this.saveRoles(roles)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: () => {
                        this.personForm.markAsPristine();
                        this.showToastNotifyAndClose();
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error updating person's roles: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }

    saveRoles(roles: PersonRolesDto.Roles[]) {
        const payload = {
            roles,
            id: this.person.id,
            worksForId: this.organisationId
        };
        return this.personService.updateRoles(this.person.id, payload).pipe(
            switchMap(result => {
                this.person.roles = roles;
                return of(result);
            })
        );
    }

    showToastNotifyAndClose() {
        this.logging.success('Person updated');
        this.personUpdated.emit(this.person);
        this.cancelEdit();
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancel() {
        if (this.personRolesForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    cancelEdit() {
        this.cancelled.emit(null);
    }
}
