<ng-container screenAnalytics="historical-mission-record-dialogue">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-missions.png" />
        </div>
        <h3 class="modal-title">Edit Retrospective Mission Record</h3>
        <h5 class="modal-title padding">
            {{ mission.name }}
        </h5>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="missionRecordForm" (ngSubmit)="submit()" novalidate>
        <inline-modal #inlineModal></inline-modal>
        <div class="modal-body mission inner-container-and-footer">
            <div
                cdkDropListGroup
                class="modal-body-content container-with-navigation"
                scrollSpy
                [spiedTags]="['DIV']"
                [spiedContainer]="content"
                (sectionChange)="onSectionChange($event)"
            >
                <!-- Menu left -->
                <div class="container-menu">
                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['objectives']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('objectives')">
                            <i
                                class="far fa-bullseye-pointer tab-title-icon"
                            ></i>
                            Objectives
                        </button>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['operations']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('operations')">
                            <i class="far fa-wave-pulse tab-title-icon"></i>
                            Operations
                        </button>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['flights']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('flights')">
                            <i class="far fa-plane tab-title-icon"></i>
                            Flights
                        </button>
                    </div>
                    <div
                        *ngIf="
                            hasFlightLogging && (showFlightLogPanel$ | async)
                        "
                        missionFlightLogs
                        @expandOnEnter
                        @collapseOnLeave
                    >
                        <div
                            class="vertical-container top-buffer"
                            style="max-height: 250px; margin-left: 20px"
                        >
                            <unassigned-mission-flight-logs
                                *ngIf="hasFlightLogging"
                                class="vertical-container fill"
                                style="overflow: hidden"
                                [inlineModal]="inlineModal"
                                [log]="'assigned-' + uniqueLogId"
                                [readonly]="working || locked"
                                [mission]="mission"
                                [historicalMission]="true"
                                (missionChanged)="updateMission($event)"
                            >
                            </unassigned-mission-flight-logs>
                        </div>
                    </div>

                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['attachments']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('attachments')">
                            <i class="far fa-file tab-title-icon"></i>
                            Attachments
                        </button>
                    </div>
                </div>

                <!-- Content -->
                <div
                    #content
                    class="container-content form-horizontal"
                    tabindex="0"
                >
                    <div style="padding: 0 15px">
                        <enhanced-help
                            class="no-min-height"
                            screenName="enhanced-help-help"
                            componentName="main"
                        >
                        </enhanced-help>
                    </div>

                    <div id="objectives">
                        <div class="vertical-container container-fluid">
                            <h4
                                class="section-heading"
                                [ngClass]="{ readonly: working }"
                            >
                                <span>MISSION OBJECTIVES</span>
                                <div></div>
                            </h4>
                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{
                                            readonly: working
                                        }"
                                    >
                                        Mission Name
                                        <field-validation-indicator
                                            validatorFormControlName="name"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <input
                                        type="text"
                                        placeholder="Type a name for your retrospective mission"
                                        formControlName="name"
                                        class="form-control"
                                        minlength="3"
                                        (ngModelChange)="
                                            stepCompleted('missionName')
                                        "
                                        (focus)="setStep('missionName')"
                                        [readonly]="working"
                                    />
                                </div>
                                <div class="col-xs-3"></div>
                            </div>
                            <enhanced-help
                                style="min-height: 45px; display: block"
                                componentName="name"
                            >
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                *ngIf="canUseObjectives"
                            >
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{
                                            readonly: working
                                        }"
                                    >
                                        Mission Objective
                                        <field-validation-indicator
                                            validatorFormControlName="missionObjectiveTypeId"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <ng-select
                                        name="missionObjectiveTypeId"
                                        formControlName="missionObjectiveTypeId"
                                        bindValue="id"
                                        bindLabel="name"
                                        placeholder="Select a mission objective for classifying this mission"
                                        [items]="missionObjectives"
                                        [readonly]="working"
                                        [clearable]="true"
                                        appendTo="body"
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>
                            <enhanced-help
                                *ngIf="canUseObjectives"
                                componentName="missionObjectiveTypeId"
                            >
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                *ngIf="canUseObjectives"
                            >
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{
                                            readonly: working
                                        }"
                                        >Mission Objective Description
                                        <field-validation-indicator
                                            validatorFormControlName="missionObjective"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <textarea
                                        class="form-control"
                                        rows="10"
                                        formControlName="missionObjective"
                                        placeholder="A list of instructions or objectives for the mission..."
                                        [autofocus]="isCreated"
                                        (ngModelChange)="
                                            stepCompleted('missionObjective')
                                        "
                                        (focus)="setStep('missionObjective')"
                                        [readonly]="working"
                                    >
                                    </textarea>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                *ngIf="canUseObjectives"
                                componentName="missionObjective"
                            >
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{ readonly: working }"
                                    >
                                        Objective Outcome
                                        <field-validation-indicator
                                            validatorFormControlName="outcome"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="outcome"
                                        style="padding-top: 7px"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="COMPLETED"
                                            [disabled]="working"
                                        >
                                            Completed
                                        </label>
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="PARTIALLY_COMPLETED"
                                            [disabled]="working"
                                        >
                                            Partially Completed
                                        </label>
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="INCOMPLETE"
                                            [disabled]="working"
                                        >
                                            Incomplete
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="outcome">
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{ readonly: working }"
                                    >
                                        Objective Outcome Details
                                        <field-validation-indicator
                                            validatorFormControlName="objectiveOutcomeNotes"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <textarea
                                        name="objectiveOutcomeNotes"
                                        class="form-control"
                                        formControlName="objectiveOutcomeNotes"
                                        [readonly]="working"
                                    >
                                    </textarea>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                componentName="objectiveOutcomeNotes"
                            >
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label
                                        class="control-label"
                                        [ngClass]="{ readonly: working }"
                                    >
                                        Journal
                                        <field-validation-indicator
                                            validatorFormControlName="completionMessage"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <textarea
                                        class="form-control"
                                        formControlName="completionMessage"
                                        [readonly]="working"
                                    >
                                    </textarea>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="completionMessage">
                            </enhanced-help>
                        </div>
                    </div>

                    <div id="operations">
                        <div class="vertical-container container-fluid">
                            <h4 class="section-heading">
                                <span>Operations</span>
                                <div></div>
                            </h4>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Mission Type
                                        <field-validation-indicator
                                            validatorFormControlName="missionTypeId"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>

                                <div class="col-xs-6" style="height: 34px">
                                    <ng-select
                                        name="missionTypeId"
                                        formControlName="missionTypeId"
                                        bindValue="id"
                                        bindLabel="name"
                                        placeholder="Select the type of operation"
                                        [items]="missionTypes"
                                        [readonly]="working"
                                        (focus)="setStep('missionType')"
                                        [clearable]="false"
                                        appendTo="body"
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="missionTypeId">
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Mission Workflow
                                        <field-validation-indicator
                                            validatorFormControlName="missionWorkflowVersionId"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <ng-select
                                        name="missionWorkflowVersion"
                                        placeholder="Select a mission workflow to use"
                                        formControlName="missionWorkflowVersionId"
                                        bindValue="id"
                                        bindLabel="workflowName"
                                        [items]="missionWorkflows"
                                        (focus)="setStep('missionWorkflow')"
                                        [clearable]="false"
                                        appendTo="body"
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            <span>
                                                {{ item.workflowName }}
                                            </span>
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            <span>
                                                {{ item.workflowName }}
                                            </span>
                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="col-xs-3">
                                    <div class="vertical-container">
                                        <span
                                            *ngIf="
                                                missionDetails
                                                    ?.missionWorkflowVersion
                                                    ?.delegatedAuthority
                                                    ?.authorityType?.ruleset !=
                                                null
                                            "
                                            class="bottom-buffer"
                                            ><strong>Ruleset:</strong>
                                            {{
                                                missionDetails
                                                    .missionWorkflowVersion
                                                    .delegatedAuthority
                                                    .authorityType.ruleset.name
                                            }}
                                        </span>
                                        <span
                                            *ngIf="
                                                missionDetails
                                                    ?.missionWorkflowVersion
                                                    ?.delegatedAuthority != null
                                            "
                                            ><strong>Primary Authority:</strong>
                                            {{
                                                (missionDetails
                                                    .missionWorkflowVersion
                                                    .delegatedAuthority
                                                    | formatAuthority) || 'None'
                                            }}
                                            <button
                                                *ngIf="
                                                    currentAuthority != null &&
                                                    currentAuthority
                                                        .availableActions
                                                        .canEdit
                                                "
                                                type="button"
                                                class="btn btn-link btn-sm"
                                                style="
                                                    padding: 0 5px;
                                                    margin: 0;
                                                    line-height: 22px;
                                                "
                                                (click)="showAuthorityDetails()"
                                                [disabled]="
                                                    working ||
                                                    currentAuthority == null
                                                "
                                            >
                                                Details
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <enhanced-help
                                componentName="missionWorkflowVersionId"
                            >
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Remote Pilot in Command
                                        <field-validation-indicator
                                            validatorFormControlName="rpicId"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>

                                <div class="col-xs-6">
                                    <ng-select
                                        name="rpicId"
                                        formControlName="rpicId"
                                        bindValue="id"
                                        [searchFn]="personSearch"
                                        [items]="pilots$ | async"
                                        [readonly]="working"
                                        [clearable]="false"
                                        appendTo="body"
                                    >
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            {{ item | formatPerson }}
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            {{ item | formatPerson }}
                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="rpicId">
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Time Zone
                                        <field-validation-indicator
                                            validatorFormControlName="timeZone"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6 vcenter">
                                    <ng-select
                                        *ngIf="timeZoneOffsets != null"
                                        name="timeZone"
                                        placeholder="Select the timezone the mission times are in"
                                        formControlName="timeZone"
                                        [items]="timeZones"
                                        [readonly]="working"
                                        appendTo="body"
                                        [clearable]="false"
                                        style="width: 100%"
                                    >
                                        <ng-template
                                            ng-label-tmp
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index"
                                        >
                                            {{ item }} (UTC
                                            {{ timeZoneOffsets[item] }})
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>

                            <enhanced-help componentName="timeZone">
                            </enhanced-help>

                            <div
                                class="form-group input-content"
                                style="margin-bottom: 0"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Mission Date
                                        <field-validation-indicator
                                            validatorFormControlName="missionDate"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>

                                <div class="col-xs-3">
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            type="text"
                                            #datepicker="bsDatepicker"
                                            bsDatepicker
                                            formControlName="missionDate"
                                            (focus)="setStep('plannedDate')"
                                            (change)="
                                                stepCompleted('plannedDate')
                                            "
                                            [readonly]="working"
                                            [isDisabled]="working"
                                        />
                                        <span
                                            *ngIf="!working"
                                            class="input-group-addon btn-default"
                                            (click)="datepicker.toggle()"
                                            [attr.aria-expanded]="
                                                datepicker.isOpen
                                            "
                                        >
                                            <i
                                                class="glyphicon glyphicon-calendar"
                                            ></i>
                                        </span>
                                    </div>
                                </div>

                                <div
                                    class="col-xs-2"
                                    style="width: fit-content"
                                >
                                    <timepicker
                                        formControlName="missionDate"
                                        [hourStep]="1"
                                        [minuteStep]="1"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        (change)="stepCompleted('plannedTime')"
                                        (focus)="setStep('plannedTime')"
                                        [readonlyInput]="working"
                                    >
                                    </timepicker>
                                </div>

                                <div class="col-xs-3">
                                    <label class="control-label">UTC: </label>
                                    <span style="padding-left: 5px">
                                        {{ utc }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right"></div>

                                <div
                                    class="col-xs-6 vcenter"
                                    [ngClass]="{
                                        readonly: working
                                    }"
                                >
                                    <span style="padding-top: 10px"
                                        >Time is local 24 hour time in this
                                        timezone</span
                                    >
                                </div>
                            </div>
                            <enhanced-help componentName="missionDate">
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Time of Day
                                        <field-validation-indicator
                                            validatorFormControlName="timeOfDay"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="timeOfDay"
                                        style="padding-top: 7px"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="DAY"
                                            [disabled]="working"
                                            >Day</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="NIGHT"
                                            [disabled]="working"
                                            >Night</label
                                        >
                                    </div>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="timeOfDay">
                            </enhanced-help>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right">
                                    <label class="control-label"
                                        >Visual Line of Sight
                                        <field-validation-indicator
                                            validatorFormControlName="visualLineOfSight"
                                            noun="field"
                                            placement="right"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="visualLineOfSight"
                                        style="padding-top: 7px"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="VLOS"
                                            [disabled]="working"
                                            >VLOS</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="EVLOS"
                                            [disabled]="working"
                                            >EVLOS</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="BVLOS"
                                            [disabled]="working"
                                            >BVLOS</label
                                        >
                                    </div>
                                </div>

                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help componentName="visualLineOfSight">
                            </enhanced-help>
                        </div>
                    </div>

                    <!-- Flight Records -->
                    <div
                        intersectionSpy
                        [spyElement]="flightHeading"
                        class="vertical-container container-fluid"
                        id="flights"
                    >
                        <div class="form-group">
                            <div class="col-xs-12" missionFlightLogs>
                                <div
                                    #flightHeading
                                    class="sticky-heading"
                                    style="z-index: 10; padding-bottom: 10px"
                                >
                                    <h4 class="section-heading">
                                        <span
                                            [ngClass]="{
                                                readonly: working
                                            }"
                                            >Flights
                                        </span>
                                        <div></div>
                                    </h4>
                                    <div class="horizontal-container">
                                        <button
                                            class="btn btn-default btn-confirm btn-sm left-buffer right-buffer"
                                            type="button"
                                            (click)="addFlight()"
                                            [disabled]="
                                                missionRecordForm.controls
                                                    .sorties.valid || working
                                            "
                                        >
                                            <div class="circle">
                                                <span class="fa fa-plus"></span>
                                            </div>
                                            Add New Flight
                                        </button>
                                        <button
                                            *ngIf="canUploadSorties"
                                            class="btn btn-default btn-sm left-buffer right-buffer"
                                            type="button"
                                            (click)="bulkFlightUpload()"
                                            [disabled]="
                                                !missionRecordForm.controls
                                                    .sorties.valid ||
                                                working ||
                                                locked
                                            "
                                        >
                                            Bulk Upload
                                        </button>
                                        <mission-overview
                                            class="left-buffer right-buffer"
                                            [mission]="mission"
                                            [historicalMission]="false"
                                        ></mission-overview>
                                    </div>
                                </div>

                                <ng-container formArrayName="sorties">
                                    <div
                                        *ngFor="
                                            let flight of sorties.controls;
                                            let i = index;
                                            let rowEven = odd
                                        "
                                        class="row-padding"
                                        [ngClass]="{ 'row-even': rowEven }"
                                    >
                                        <flight-edit
                                            [formControlName]="i"
                                            [canUseEquipment]="canUseEquipment"
                                            [missionDate]="
                                                missionRecordForm.controls
                                                    .missionDate
                                            "
                                            [readonly]="working"
                                            [hasFlightLogging]="
                                                hasFlightLogging
                                            "
                                            [hasAdditionalFlightFields]="
                                                hasAdditionalFlightFields
                                            "
                                            [mission]="mission"
                                            [historicalMission]="true"
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-tertiary btn-delete btn-xs"
                                                [disabled]="working"
                                                (click)="deleteFlight(i)"
                                                tooltip="Remove flight"
                                                placement="top"
                                            >
                                                <span
                                                    class="fal fa-trash-alt"
                                                ></span>
                                            </button>
                                        </flight-edit>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <enhanced-help componentName="flights"></enhanced-help>
                    </div>

                    <!-- Documentation -->
                    <div id="attachments">
                        <div class="vertical-container container-fluid">
                            <h4 class="section-heading">
                                <span>Attachments</span>
                                <div></div>
                            </h4>

                            <div class="form-group input-content">
                                <div class="col-xs-3 text-right"></div>
                                <div class="col-xs-6">
                                    <div
                                        class="row top-buffer"
                                        *ngIf="mission.id"
                                    >
                                        <div class="col-xs-12">
                                            <attachment-list
                                                [attachmentsHandler]="
                                                    attachmentHandler
                                                "
                                                [allowUploads]="true"
                                                [allowDelete]="true"
                                                [allowEdit]="true"
                                                [parentPromiseTracker]="
                                                    workTracker
                                                "
                                                permissions
                                            >
                                            </attachment-list>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-default"
                type="button"
                (click)="save()"
                [disabled]="working || missionRecordForm.invalid"
            >
                Save
            </button>

            <button
                class="btn btn-default"
                type="button"
                (click)="saveAndClose()"
                [disabled]="working || missionRecordForm.invalid"
            >
                Save and Close
            </button>

            <div
                *ngIf="
                    mission.availableActions.canFinalise &&
                    !missionRecordForm.valid
                "
                class="left-buffer right-buffer"
                style="display: inline-block"
                @collapse
                tooltip="Allows invalid or incomplete mission records to be finalised"
                placement="top"
            >
                <div class="checkbox">
                    <label class="text-danger">
                        <input
                            type="checkbox"
                            name="hasIdentifier"
                            [(ngModel)]="allowFinalisingIncomplete"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="working"
                        />
                        <span class="cr"
                            ><i class="cr-icon glyphicon glyphicon-ok"></i>
                        </span>
                        Finalise incomplete mission record
                    </label>
                </div>
            </div>

            <button
                *ngIf="mission.availableActions.canFinalise"
                class="btn btn-primary"
                type="button"
                [disabled]="
                    working ||
                    (!missionRecordForm.valid && !allowFinalisingIncomplete)
                "
                (click)="finalise()"
            >
                Finalise
            </button>

            <button
                *ngIf="!mission.availableActions.canFinalise"
                class="btn btn-primary"
                type="button"
                [disabled]="working || !missionRecordForm.valid"
                (click)="submit()"
            >
                Submit for Finalisation
            </button>
        </div>
    </form>
</ng-container>
