import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { ScreenAnalyticsDirective } from './screen-analytics.directive';
import { AnalyticsOnDirective } from './analytics-on.directive';

/**
 * This module provides wraps the analytics provider to make it
 * easier for other directives on the page to track all required
 * metadata.
 *
 * This directive is normally placed in the root element (or a wrapping <ng-container>)
 * so that all children can get access to it.
 */
@NgModule({
    imports: [Angulartics2Module],
    declarations: [ScreenAnalyticsDirective, AnalyticsOnDirective],
    exports: [ScreenAnalyticsDirective, AnalyticsOnDirective]
})
export class AnalyticsModule {}
