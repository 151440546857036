import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    CombinedMissionStatus,
    CraftDetailsDto,
    CraftService,
    GqlFilterField,
    MaintenanceActivityDto,
    MissionService,
    MissionSummaryDto,
    WorkTracker,
    revertCombinedStatus,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import {
    FormatColumnArrayPipe,
    FormatDateTimePipe,
    FormatMissionStatusPipe,
    FormatPersonPipe,
    MissionStatusClassPipe
} from '@flyfreely-portal-ui/ui';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from 'libs/flyfreely-table/src/lib/interfaces';
import { findRpic } from 'libs/missions/src/lib/helpers';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LinkTestFlightDataService } from './link-flight-test-data.service';

interface LinkableMission extends MissionSummaryDto {
    // missionCrewNames?: string[];
    craftNames?: string[];
    combinedStatus?: CombinedMissionStatus;
    convertedDate: moment.Moment;
    // convertedRequestTime?: moment.Moment;
    // convertedResolutionTime?: moment.Moment;
}

@Component({
    selector: 'link-test-flight',
    templateUrl: './link-flight-test.component.html',
    providers: [
        LinkTestFlightDataService,
        MissionStatusClassPipe,
        ScreenAnalyticsDirective
    ]
})
export class LinkTestFlightComponent {
    @Input() organisationId: number;
    @Input() maintenanceActivity: MaintenanceActivityDto;
    @Input() rpaId: number;

    @Output() linkedMissionId = new EventEmitter<number>();

    maintenanceRpa: CraftDetailsDto;

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    tableSorting: ColumnSortPreferences;
    gqlSorting: ColumnSortPreferences;
    missionStatusFilters: MissionSummaryDto.Status[];
    tableSearch: any;
    tableFilters: GqlFilterField[] = [];
    // fixedTableFilters contains the filter data that needs to be passed on each filter, i.e. the rpaId
    fixedTableFilters: GqlFilterField[] = [];

    missions$ = new Subject<LinkableMission[]>();

    public working: boolean;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private linkTestFlightDataService: LinkTestFlightDataService,
        private missionService: MissionService,
        private rpaService: CraftService,
        private commonDialoguesService: CommonDialoguesService,
        private formatDateTimePipe: FormatDateTimePipe,
        private missionStatusPipe: FormatMissionStatusPipe,
        private formatPersonPipe: FormatPersonPipe,
        private formatColumnArrayPipe: FormatColumnArrayPipe,
        private modal: BsModalRef,
        private changeDetector: ChangeDetectorRef
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.linkTestFlightDataService.missions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missions => this.updateMissionsList(missions));

        this.linkTestFlightDataService.totalItems$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(totalItems => {
                const currentConfig = this.tableConfig;
                this.tableConfig = {
                    ...currentConfig,
                    totalItems: totalItems
                };
            });

        if (this.rpaId) {
            this.refreshRpaDetails();
        } else {
            this.setupTableColumns();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.missions$.complete();
    }

    refreshRpaDetails() {
        this.rpaService
            .findById(this.rpaId, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpa => {
                this.maintenanceRpa = rpa;
                this.setupTableColumns();
            })
            .add(this.workTracker.createTracker());
    }

    setupTableColumns() {
        // make sure to filter by the maintenance RPA if this is an RPA maintenance.
        // Use the maintenance dates as the default date filters

        const statusFilters = this.missionService.getCombinedStatusFilters();
        this.gqlSorting = { column: 'missionDate', ascending: false };

        // Fetch mission types?

        const standardColumns: TableColumn[] = [
            {
                value: 'organisationName',
                name: 'Organisation',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'uid',
                name: 'UID',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'convertedDate',
                key: 'missionDate',
                name: 'Date/Time',
                searchable: 'daterange',
                selectable: false,
                defaultSelection: true,
                formatterFunction: t => this.formatDateTimePipe.transform(t),
                compareFunction: (a, b) => 0
                // searchFunction: () => () => true
            },
            {
                value: 'locationName',
                name: 'Location',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'combinedStatus',
                name: 'Status',
                searchable: true,
                selectable: false,
                defaultSelection: true,
                sortable: false,
                searchOptions: statusFilters,
                formatterFunction: s => this.missionStatusPipe.transform(s)
            },
            {
                value: 'rpic',
                name: 'RPIC',
                searchable: false,
                // searchable: 'text',
                selectable: false,
                defaultSelection: true,
                formatterFunction: p => this.formatPersonPipe.transform(p)
            },
            {
                value: 'craftNicknames',
                name: 'RPA',
                searchable: this.rpaId == null,
                selectable: false,
                sortable: false,
                defaultSelection: true,
                formatterFunction: a =>
                    this.formatColumnArrayPipe.transform(a, 1)
            },
            {
                value: 'missionWorkflowVersion.name',
                key: 'missionWorkflowVersion.name',
                name: 'Workflow',
                searchable: true,
                selectable: false,
                defaultSelection: true
            }
        ];

        this.availableColumns = standardColumns;
        this.tableConfig = {
            serverPagination: true,
            currentPage: 0,
            limit: 10,
            actions: [
                {
                    action: (item: MissionSummaryDto) =>
                        this.selectMission(item),
                    icon: 'fal fa-link',
                    tooltip: 'Link mission to maintenance as test flight'
                }
            ]
        };

        this.tableSorting = this.tableSorting ?? null;

        this.tableSearch = this.setupDefaultTableSearch();
        this.tableFilters = this.tableSearch;

        this.selectedColumns = this.selectedColumns ?? null;

        // this.refreshMissionTypeFilter();
        this.refreshMissions();
    }

    setupDefaultTableSearch() {
        const rpaSearch: GqlFilterField[] = this.rpaId
            ? [
                  {
                      field: 'craftNicknames',
                      filter: this.maintenanceRpa.nickname
                  }
              ]
            : [];
        this.fixedTableFilters = rpaSearch;
        const startDate = this.maintenanceActivity.startDate
            ? toTimestamp(moment(this.maintenanceActivity.startDate).toDate())
            : null;
        const endDate = this.maintenanceActivity.endDate
            ? toTimestamp(moment(this.maintenanceActivity.endDate).toDate())
            : null;
        const dateSearch: GqlFilterField[] =
            startDate && endDate
                ? [
                      {
                          field: 'convertedDate',
                          filter: `${startDate} ${endDate}`
                      }
                  ]
                : [];
        return rpaSearch.concat(dateSearch);
    }

    refreshMissions() {
        const filters: GqlFilterField[] = this.tableFilters.map(filter =>
            filter.field === 'convertedDate'
                ? {
                      field: 'missionDate',
                      filter: filter.filter
                  }
                : filter
        );
        this.linkTestFlightDataService.findMissions(
            this.tableConfig.currentPage,
            this.tableConfig.limit,
            this.gqlSorting,
            filters,
            this.organisationId
        );
    }

    private updateMissionsList(missions: MissionSummaryDto[]) {
        this.missions$.next(
            missions.map(m => ({
                ...m,
                // missionCrewNames: this.findCrew(m.missionCrewDetails),
                rpic: findRpic(m.missionCrewDetails),
                combinedStatus: this.missionService.getCombinedStatus(m),
                convertedDate: moment(m.missionDate).tz(m.timeZone)
                // convertedRequestTime: m.missionApproval?.requestTime
                //     ? moment(m.missionApproval.requestTime)
                //     : null,
                // convertedResolutionTime: m.missionApproval?.resolutionTime
                //     ? moment(m.missionApproval.resolutionTime)
                //     : null
            }))
        );
    }

    showMission() {}

    private parseFilterFieldNames(name: string) {
        switch (name) {
            case 'missionWorkflowVersion.name':
                return 'workflowName';

            case 'locationName':
                return 'locationName';

            case 'missionCrewNames':
                return 'missionCrew';

            case 'missionType.name':
                return 'missionOperationType';

            case 'missionApproval.requestTime':
                return 'missionApprovalRequestTime';

            case 'missionApproval.resolutionTime':
                return 'missionApprovalResolutionTime';

            default:
                return name;
        }
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (!this.selectedColumns.includes(this.tableSorting.column)) {
            this.tableSorting = null;
            this.gqlSorting = null;
        }
        this.refreshMissions();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.tableSorting = sorting;
        this.gqlSorting =
            sorting == null
                ? null
                : {
                      column: this.parseFilterFieldNames(
                          sorting.column.toString()
                      ),
                      ascending: sorting.ascending
                  };
        this.tableConfig.currentPage = 0;
        this.refreshMissions();
    }

    onTableSearch(search: Record<string, unknown>) {
        this.tableSearch = Object.keys(search).reduce(
            (acc, k) => (search[k] ? { ...acc, k: search[k] } : acc),
            {}
        );
        this.missionStatusFilters = null;
        // Build table filters with standard fields, filtering out fields that require extra parsing
        const filters: GqlFilterField[] =
            search == null || Object.keys(search).length === 0
                ? []
                : Object.keys(search)
                      .filter(
                          k =>
                              k !== 'missionDate' &&
                              k !== 'combinedStatus' &&
                              k !== 'missionType.name'
                      )
                      .filter(
                          k =>
                              this.availableColumns.find(c => c.value === k)
                                  .searchable
                      )
                      .filter(k => search[k] != null && search[k] !== '')
                      .map(k => ({
                          field: this.parseFilterFieldNames(k),
                          filter: search[k]?.toString() ?? ''
                      }));

        const missionDateFilters: GqlFilterField[] = this.getDateFilterValue(
            search,
            'missionDate',
            'missionDate'
        );

        const additionalFilters: GqlFilterField[] = missionDateFilters.concat(
            this.filterCombinedStatus(search)
        );

        this.tableFilters = filters
            .concat(additionalFilters)
            .concat(this.fixedTableFilters);
        this.tableConfig.currentPage = 0;
        // This is needed because the static table uses onPush change detection
        // Whenever a query repeats a previous output there is a chance the table won't update without explicit change detection
        this.changeDetector.detectChanges();
        this.refreshMissions();
    }

    private getDateFilterValue(
        search: Record<string, unknown>,
        key: string,
        field: string
    ) {
        if (search == null) {
            return [];
        }
        const date = search[Object.keys(search).find(k => k === key)];
        if (date == null) {
            return [];
        }
        const dateValue = `${toTimestamp(date[0])} ${toTimestamp(date[1])}`;
        return [
            {
                field: field,
                filter: dateValue
            }
        ];
    }

    private filterCombinedStatus(search: Record<string, unknown>) {
        if (search == null) {
            return [];
        }
        const combinedStatus = search[
            Object.keys(search).find(k => k === 'combinedStatus')
        ] as CombinedMissionStatus;
        if (combinedStatus == null) {
            return [];
        }
        const deconstructedStatus = revertCombinedStatus(combinedStatus);
        if (deconstructedStatus == null) {
            return [];
        }
        this.missionStatusFilters = deconstructedStatus.missionStatus;
        const approvalStatuses: GqlFilterField[] =
            deconstructedStatus.approvalStatus == null ||
            deconstructedStatus.approvalStatus.length === 0
                ? []
                : deconstructedStatus.approvalStatus.map(s => ({
                      field: 'status',
                      filter: s.toString()
                  }));
        const readyForFinalisation: GqlFilterField[] =
            deconstructedStatus.readyForFinalisation == null
                ? []
                : [
                      {
                          field: 'readyForFinalisation',
                          filter: deconstructedStatus.readyForFinalisation
                      }
                  ];
        const outstandingApprovals: GqlFilterField[] =
            deconstructedStatus.outstandingApprovals == null
                ? []
                : [
                      {
                          field: 'outstandingApprovals',
                          filter: deconstructedStatus.outstandingApprovals
                      }
                  ];
        return approvalStatuses
            .concat(readyForFinalisation)
            .concat(outstandingApprovals);
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.refreshMissions();
    }

    selectMission(mission: MissionSummaryDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Link mission as test flight',
                `Do you want to link the mission "${mission.name}" as a test flight?`,
                'Yes',
                () =>
                    new Promise<void>(resolve => {
                        this.linkedMissionId.emit(mission.id);
                        resolve();
                    })
            )
            .then(() => this.modal.hide());
    }

    close() {
        this.modal.hide();
    }
}
