import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {
    AerodromeDetailsDto,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    RuleCodeDto,
    RuleOutcome
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AirspaceDialoguesService } from '../airspace-dialogues.service';
import { AuthorisationError } from '../authorisation/interfaces';
import { AirspaceCheckService } from './airspace-check.service';

interface AirspaceRuleOutcomeLookup {
    [ruleCode: string]: RuleOutcome[];
}

interface RuleDetailsValues {
    aerodromes: { [code: string]: AerodromeDetailsDto };
    ruleOutcomes: RuleOutcome[];
    message: RuleCodeDto;
}
@Component({
    selector: 'airspace-check',
    templateUrl: './airspace-check.component.html',
    styleUrls: ['./airspace-check.component.scss'],
})
export class AirspaceCheck {
    @Input() screenIdentifier: string;
    @Input() hideHeader = false;
    @Input() inlineMode = false;
    @Input() linkPosition: 'TOP' | 'BOTTOM' | 'NONE' = 'TOP';
    @Output() showInlineRuleDetails = new EventEmitter<RuleDetailsValues>();
    private ngUnsubscribe$ = new Subject<void>();

    result: AirspaceCheckDto;
    jurisdictionName: string;
    missionDate: string;
    status: string;
    workflowVersionId: number;

    airspaceJurisdiction: AirspaceJurisdictionDto;

    operationFailed = false;
    externalAirspaceError: AuthorisationError;

    showBlockers: boolean;
    showAdvises: boolean;
    showPasses: boolean;

    messages: { [code: string]: RuleCodeDto };

    blockRuleLookup: AirspaceRuleOutcomeLookup;
    adviseRuleLookup: AirspaceRuleOutcomeLookup;
    passRuleLookup: AirspaceRuleOutcomeLookup;

    constructor(
        private airspaceCheckService: AirspaceCheckService,
        private airspaceDialoguesService: AirspaceDialoguesService,
        private angulartics2: Angulartics2
    ) {
        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => this.refreshResult(result));
        this.airspaceCheckService.resultStatus$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => (this.status = result));
        this.airspaceCheckService.operationFailed$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(failed => (this.operationFailed = failed));
        this.airspaceCheckService.airspaceCheckError$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(error => (this.externalAirspaceError = error));

        // pull this separately to get the location value without the date completing
        this.airspaceCheckService.jurisdiction$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(jurisdiction => (this.jurisdictionName = jurisdiction));
        this.airspaceCheckService.airspaceJurisdiction$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(jurisdiction => {
                this.airspaceJurisdiction = jurisdiction;
            });

        combineLatest([
            this.airspaceCheckService.jurisdiction$,
            this.airspaceCheckService.missionDate$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([jurisdiction, date]) => {
                this.missionDate = date;
            });

        this.airspaceCheckService.workflowVersionId$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(id => (this.workflowVersionId = id));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshResult(result: AirspaceCheckDto) {
        this.result = result;

        // Reset data and break if nothing to display.
        this.blockRuleLookup = {};
        this.adviseRuleLookup = {};
        this.passRuleLookup = {};
        if (result == null) {
            this.showBlockers = false;
            return;
        }

        this.messages = result.ruleMessages;
        this.blockRuleLookup = result?.ruleOutcomes
            .filter(o => o.outcome === RuleOutcome.Outcome.BLOCK)
            .reduce((acc, o) => {
                const ruleCode = o.code;
                if (Object.keys(acc).includes(ruleCode)) {
                    acc[ruleCode].push(o);
                    return acc;
                } else {
                    return { ...acc, [ruleCode]: [o] };
                }
            }, {}) as AirspaceRuleOutcomeLookup;
        this.adviseRuleLookup = result?.ruleOutcomes
            .filter(
                o =>
                    o.outcome === RuleOutcome.Outcome.ADVISE ||
                    o.outcome === RuleOutcome.Outcome.AUTHORISATION_REQUIRED
            )
            .reduce((acc, o) => {
                const ruleCode = o.code;
                if (Object.keys(acc).includes(ruleCode)) {
                    acc[ruleCode].push(o);
                    return acc;
                } else {
                    return { ...acc, [ruleCode]: [o] };
                }
            }, {}) as AirspaceRuleOutcomeLookup;
        this.passRuleLookup = result?.ruleOutcomes
            .filter(o => o.outcome === RuleOutcome.Outcome.PASS)
            .reduce((acc, o) => {
                const ruleCode = o.code;
                if (Object.keys(acc).includes(ruleCode)) {
                    acc[ruleCode].push(o);
                    return acc;
                } else {
                    return { ...acc, [ruleCode]: [o] };
                }
            }, {}) as AirspaceRuleOutcomeLookup;
    }

    viewJurisdictionLibrary() {
        this.airspaceCheckService.viewJurisdictionLibrary();
    }

    
    showAirspaceCheckDetails() {
        this.airspaceDialoguesService.showAirspaceCheckDetailsDialogue(
            this.airspaceCheckService
        );
    }

    viewRuleDetails(code: string, type: 'BLOCK' | 'ADVISE' | 'PASS') {
        const message = this.messages[code];
        const outcome =
            type === 'BLOCK'
                ? this.blockRuleLookup[code]
                : type === 'ADVISE'
                ? this.adviseRuleLookup[code]
                : this.passRuleLookup[code];

        if (!this.inlineMode) {
            this.airspaceDialoguesService.showRuleDetailsDialogue(
                this.result.aerodromes,
                outcome,
                message
            );
        } else {
            const values = {
                aerodromes: this.result.aerodromes,
                ruleOutcomes: outcome,
                message
            };
            this.showInlineRuleDetails.emit(values);
        }

        this.angulartics2.eventTrack.next({
            action: 'view-rule-details',
            properties: {
                category: 'airspace-check',
                label: this.screenIdentifier ?? null
            }
        });
    }
}
