import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'equipmentStatusClass' })
export class EquipmentStatusClassPipe implements PipeTransform {
    transform(status: EquipmentDto.Status) {
        switch (status) {
            case EquipmentDto.Status.SERVICEABLE:
                return 'equipment-status-serviceable';
            case EquipmentDto.Status.UNSERVICEABLE:
                return 'equipment-status-unserviceable';
            case EquipmentDto.Status.RETIRED:
                return 'equipment-status-retired';
            case EquipmentDto.Status.UNDER_MAINTENANCE:
                return 'equipment-status-under-maintenance';
            default:
                return `equipment-status-${status.toLowerCase()}`;
        }
    }
}
