<button
    *ngIf="hasLogo && showOptions"
    type="button"
    class="close logo-edit-button"
    (click)="showOptions = !showOptions"
>
    &times;
</button>
<div
    class="logo-container"
    ngfDrop
    [(validDrag)]="baseDropValid"
    [class.invalid-drag]="baseDropValid === false"
    [class.valid-drag]="baseDropValid"
    accept="image/*"
    (fileChange)="selectLogo($event)"
    (mouseenter)="showOptions = true"
    (mouseleave)="showOptions = false"
>
    <img
        *ngIf="!openWebcam"
        [src]="imgSrc"
        [ngClass]="{ mix: !hasLogo }"
        (click)="showOptions = true"
    />
    <div *ngIf="!hasLogo && !openWebcam" class="edit-logo">
        <label>{{ noun }}</label>
    </div>
    <div
        class="logo-upload-options"
        [ngClass]="{ 'show-options': showOptions }"
        (click)="hideOptions()"
    >
        <div
            class="vertical-container logo-icon"
            ngfSelect
            accept="image/*"
            (fileChange)="selectLogo($event)"
        >
            <span class="logo-action fa fa-cloud-upload"></span>
            <label *ngIf="!isCompany">Upload<br />Headshot</label>
            <label *ngIf="isCompany">Upload<br />Logo</label>
        </div>
        <div
            *ngIf="canUseCamera && !isCompany"
            class="vertical-container logo-icon-space"
            (click)="takePhoto()"
        >
            <span class="logo-action fa fa-camera"></span>
            <label>Take<br />Selfie</label>
        </div>
    </div>
</div>
