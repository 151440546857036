import { Component, Input, SimpleChanges } from '@angular/core';
import {
    ApproversMissionApprovalDto,
    DisplayableMissionDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionMessageDto,
    MissionService,
    PersonDto,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    ApprovalScreenStatus,
    findActiveApprovals,
    findActiveMissionApprovals,
    getSimplifiedAuthorityFromApproval
} from '../helpers';
import { MissionApprovalWithAuthority } from '../interfaces';

@Component({
    selector: 'summary-header',
    templateUrl: './summary-header.component.html',
    styleUrls: ['./summary-header.component.scss']
})
export class SummaryHeader {
    @Input() mission: DisplayableMissionDto;
    @Input() organisation: PersonsOrganisationDto;
    @Input() approval?: ApproversMissionApprovalDto;
    @Input() missionApproval?: MissionApprovalWithAuthority;
    @Input() missionApprovals: MissionApprovalWithAuthority[] = [];
    @Input() status: ApprovalScreenStatus;
    @Input() working: boolean;

    activeApprovals: MissionApprovalWithAuthority[] = [];
    getSimplifiedAuthorityFromApproval = getSimplifiedAuthorityFromApproval;

    rpic: PersonDto;
    discussions: MissionMessageDto[];
    message: string;
    toggle: any;

    hasChat: boolean = false;
    chatRecipient: string = null;

    loadingChat = false;
    summaryStatus: string;
    statusClass: string;

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private missionService: MissionService,
        private logging: FlyFreelyLoggingService
    ) {
        this.setAllToggleFalse();
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.loadingChat = working));
    }

    ngOnInit() {
        this.missionService.commsChanges$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshDiscussions());
        this.activeApprovals = findActiveMissionApprovals(
            this.missionApprovals
        );
        if (this.approval == null && this.activeApprovals?.length === 1) {
            this.approval = this.activeApprovals[0]
                .approval as ApproversMissionApprovalDto;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.refreshDiscussions();
        this.activeApprovals = findActiveMissionApprovals(
            this.missionApprovals
        );
        if (this.approval == null && this.activeApprovals?.length === 1) {
            this.approval = this.activeApprovals[0]
                .approval as ApproversMissionApprovalDto;
        }

        if ('mission' in changes) {
            if (this.mission == null) {
                this.hasChat = false;
                this.summaryStatus = null;
                this.statusClass = null;
                this.rpic = null;
                return;
            }
            this.hasChat =
                findActiveApprovals(this.mission.approvals).length > 0;
            // this.summaryStatus = this.getSummaryStatus();
            // this.statusClass = this.getStatusClass();

            const rpicRole = this.mission.missionCrewDetails.find(
                c => c.role.coreRole === 'PILOT_IN_COMMAND'
            );
            this.rpic = rpicRole != null ? rpicRole.person : null;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setAllToggleFalse() {
        this.toggle = {
            isCriteria: false,
            isCondition: false,
            isActivity: false,
            isDocument: false
        };
    }

    private getApprovalStatus() {
        const approvalStatuses = findActiveApprovals(
            this.mission.approvals
        ).map(a => a.status);
        if (
            approvalStatuses.filter(status => status === 'BEING_REVIEWED')
                .length === approvalStatuses.length
        ) {
            return 'LOCKED';
        } else if (approvalStatuses.some(status => status === 'PENDING')) {
            return 'WAITING';
        }
        return findActiveApprovals(this.mission.approvals).map(a => a.status);
    }

    private getMissionStatus() {
        switch (this.mission.status) {
            case 'FLYING':
                return 'LOCKED';
            case 'READY_TO_FLY':
                return 'READY TO FLY';
            default:
                return this.mission.status;
        }
    }

    //     getStatusClass() {
    //         return `mission-status-${this.getSummaryStatus().toLowerCase()}`;
    //     }
    //
    //     getSummaryStatus() {
    //         if (this.approval != null) {
    //             return this.getApprovalStatus();
    //         } else {
    //             return this.getMissionStatus();
    //         }
    //     }

    isShowPanel(panel: string) {
        switch (panel) {
            case 'criteria':
                return this.approval != null;
            case 'conditions':
                return this.approval != null;
            case 'activity':
                return true;
            case 'documents':
                return true;
        }
    }

    getName(discussion: MissionMessageDto) {
        return `${discussion.sender.firstName} ${discussion.sender.lastName}`;
    }

    isRole(role: string) {
        return this.organisation.roles.find(r => r === role) ? true : false;
    }

    onTogglePress(toggle: string) {
        this.toggle = {
            isCriteria: false,
            isCondition: false,
            isActivity: false,
            isDocument: false,
            [toggle]: !this.toggle[toggle]
        };
    }

    refreshDiscussions() {
        if (this.mission == null) {
            return;
        }
        this.chatRecipient =
            this.approval != null ? 'remote pilot' : 'approver';
        this.missionService
            .findDiscussions(this.mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: discussions => {
                    const reversedDiscussion = [...discussions];
                    reversedDiscussion.reverse();
                    this.discussions = reversedDiscussion;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error loading messages: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    sendMessage() {
        this.missionService
            .createDiscussion(this.mission.id, this.message)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.message = '';
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error sending message: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }
}
