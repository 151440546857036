import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { NgSelectModule } from '@ng-select/ng-select';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';

import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { ApplyApprovalConditions } from './apply-approvals/apply-approval-conditions.component';
import { ConditionLibraryDialoguesService } from './approval-conditions-dialogues.service';
import { ConditionLibraryEditDialogue } from './condition-library-edit/condition-library-edit.component';
import { ConditionLibraryList } from './condition-library-list/condition-library-list.component';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxLoadingModule,
        SharedUiModule,
        DocumentationModule,
        PaginationModule,
        AttachmentsModule,
        SharedUiModule,
        ValidationModule,
        BsDatepickerModule,
        TooltipModule,
        ToastrModule,
        NgSelectModule,
        DragDropModule, // Add DragDropModule from Angular CDK
        EditableModule,
        EnhancedHelpModule,
        AnalyticsModule
    ],
    providers: [ConditionLibraryDialoguesService],
    declarations: [
        ConditionLibraryList,
        ConditionLibraryEditDialogue,
        ApplyApprovalConditions
    ],
    exports: [
        ConditionLibraryList,
        ConditionLibraryEditDialogue,
        ApplyApprovalConditions
    ]
})
export class ApprovalConditionsModule {}
