import { Injectable } from '@angular/core';
import {
    hasAnyPermission,
    InvitesService,
    OrganisationSubscriptionDto,
    PersonService,
    PersonsOrganisationDto,
    SubscriptionLimitDto,
    SubscriptionService
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentSubscriptions,
    OrganisationSubscriptionsState,
    PersonalOrganisationSubscription
} from 'libs/subscriptions/src/lib/interfaces';
import { OrganisationSubscriptionState } from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { WorkspaceStateService } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class OrganisationSubscriptionsService {
    private currentSubscriptionsSubject =
        new BehaviorSubject<OrganisationSubscriptionsState>({
            type: 'loading'
        });
    public currentSubscriptions$ =
        this.currentSubscriptionsSubject.asObservable();

    private refresh$ = new Subject<void>();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        workspaceState: WorkspaceStateService,
        private subscriptionService: SubscriptionService,
        private invitesService: InvitesService,
        private personService: PersonService,
        private organisationSubscriptionState: OrganisationSubscriptionState
    ) {
        workspaceState.currentOrganisation$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                switchMap(selected => {
                    switch (selected.type) {
                        case 'loading':
                            return of({
                                type: 'loading'
                            }) as Observable<OrganisationSubscriptionsState>;

                        case 'no_access':
                        case 'no_licence':
                            return of({
                                type: 'no_visibility'
                            }) as Observable<OrganisationSubscriptionsState>;
                        case 'organisation_loaded':
                            if (
                                hasAnyPermission(
                                    selected.organisation,
                                    PersonsOrganisationDto.Permissions
                                        .ORGANISATION_EDIT
                                )
                            ) {
                                // calculate based on the subscription, but update when the subscription changes
                                return this.refresh$.pipe(
                                    startWith(() => undefined),
                                    mergeMap(() =>
                                        this.calculate(selected.organisation)
                                    )
                                );
                            } else {
                                return of({
                                    type: 'no_visibility'
                                }) as Observable<OrganisationSubscriptionsState>;
                            }
                    }
                })
            )
            .subscribe(next => {
                this.currentSubscriptionsSubject.next(next);
            });

        combineLatest([
            this.subscriptionService.refreshLicences$,
            this.invitesService.change$,
            this.personService.change$,
            this.organisationSubscriptionState.subscriptionUpdated$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refresh());
    }

    ngOnDestroy() {
        this.refresh$.complete();

        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refresh() {
        this.refresh$.next();
    }

    private calculate(organisation: PersonsOrganisationDto) {
        if (organisation.personalOrganisation) {
            return of({
                assignedLicences: 1,
                hasLicencesAvailable: false,
                totalLicences: 1,
                name: 'Personal Organisation',
                type: 'personal_organisation'
            } as PersonalOrganisationSubscription);
        }
        return this.subscriptionService
            .findCurrentSubscriptions(organisation.id)
            .pipe(
                map(s => {
                    const filteredSubscriptions = s.filter(
                        sub =>
                            sub.status ===
                            OrganisationSubscriptionDto.Status.ACTIVE
                    );

                    if (filteredSubscriptions.length === 0) {
                        return {
                            type: 'no_subscriptions'
                        } as OrganisationSubscriptionsState;
                    }
                    const subscription = filteredSubscriptions[0];

                    const personnelLicences = subscription.limits.filter(
                        l =>
                            l.licencedEntityType ===
                            SubscriptionLimitDto.LicencedEntityType.PERSONNEL
                    );

                    const pilotLicences = subscription.limits.filter(
                        l =>
                            l.licencedEntityType ===
                            SubscriptionLimitDto.LicencedEntityType.PILOT
                    );

                    const totalPersonnel =
                        personnelLicences.length > 0
                            ? personnelLicences.reduce(
                                  (acc, l) => acc + l.instances,
                                  0
                              )
                            : null;

                    const totalPilots =
                        pilotLicences.length > 0
                            ? pilotLicences.reduce(
                                  (acc, l) => acc + l.instances,
                                  0
                              )
                            : null;

                    const pilotsAvailable =
                        totalPilots != null
                            ? pilotLicences.reduce(
                                  (acc, l) => acc + l.availableLicenceCount,
                                  0
                              )
                            : null;

                    const personnelAvailable =
                        totalPersonnel != null
                            ? personnelLicences.reduce(
                                  (acc, l) => acc + l.availableLicenceCount,
                                  0
                              )
                            : null;

                    const hasLicences =
                        subscription.limits.length === 0 ||
                        (personnelAvailable != null &&
                            personnelAvailable > 0) ||
                        (personnelAvailable == null &&
                            pilotLicences.length > 0);

                    const assignedLicences =
                        totalPersonnel - personnelAvailable;

                    const isTriallingUp =
                        subscription.trialUpFeatureSet != null &&
                        subscription.trialUpStartTime != null &&
                        subscription.trialUpEndTime != null &&
                        moment(moment.now()).isBetween(
                            moment(subscription.trialUpStartTime),
                            moment(subscription.trialUpEndTime),
                            'day',
                            '[]'
                        );

                    return {
                        type: 'subscriptions',
                        subscription,
                        hasLicencesAvailable: hasLicences,
                        availablePilots: pilotsAvailable,
                        totalPilots: totalPilots,
                        availablePersonnel: personnelAvailable,
                        assignedPersonnel: assignedLicences,
                        totalPersonnel: totalPersonnel,
                        status: subscription.externalStatus,
                        payment:
                            subscription.externalIdentifier == null
                                ? 'none'
                                : subscription.externalCollectionMethod ===
                                  'charge_automatically'
                                ? 'automatic'
                                : 'manual',
                        trialEndTime: subscription.trialEndTime,
                        name: subscription.name,
                        isTrialing: subscription.isTrialing,
                        isTriallingUp: isTriallingUp,
                        trialUpPlan: subscription.trialUpFeatureSet?.name,
                        trialUpEndTime: subscription.trialUpEndTime
                    } as CurrentSubscriptions;
                })
            );
    }
}
