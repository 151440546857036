import { Component, forwardRef, Inject, Input, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
    BatteryDto,
    BatteryService,
    CurrentPersonDto,
    DO_NOTHING,
    FEATURE_FLIGHT_CONFORMANCE,
    FEATURE_REPROCESS_FLIGHT_LOGS,
    FlightConformanceResultDto,
    FlightLogFileDto,
    FlightLogsService,
    FlightLogSummaryDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    hasAnyPermission,
    hasFeatureFlag,
    LookupObject,
    MissionService,
    NameValue,
    PersonsOrganisationDto,
    SimpleFlightLogCollectionSourceDto,
    SuggestedMissionAssignmentDto,
    toLookup,
    toTimestamp,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, isSome, Option, map as optionMap } from 'fp-ts/es6/Option';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { BatteryDialoguesService } from 'libs/batteries/src/lib/battery-dialogues.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { InlineModal } from 'libs/inline-modal/src/lib/inline-modal.component';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { preHideCheck } from 'libs/ngx-bootstrap-customisation/src/lib/utils';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import { FormatDurationPipe, formatPerson } from 'libs/ui/src/lib/pipes';
import * as moment from 'moment';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { AssignFlightLog } from '../assign-flight-log/assign-flight-log.component';
import { FlightLogDataService } from '../flight-log-data.service';
import { FlightLogsDialoguesService } from '../flight-logs-dialogues.service';

const conformanceStatuses = [
    { name: 'No Policy', value: FlightConformanceResultDto.Status.NO_POLICY },
    {
        name: 'No Breaches',
        value: FlightConformanceResultDto.Status.NO_BREACHES
    },
    {
        name: 'Preliminary',
        value: FlightConformanceResultDto.Status.PRELIMINARY
    },
    { name: 'Sent', value: FlightConformanceResultDto.Status.SENT },
    { name: 'Completed', value: 'COMPLETED' }
] as NameValue[];

export interface FlightLogWithDuration extends FlightLogFileDto {
    collectedBy: string;
    duration: number;
    uploadTime: moment.Moment;
    startTime: string;
    summary?: FlightLogSummaryDto;
    suggestedMissionAssignment?: SuggestedMissionAssignmentDto;
    conformanceResultList?: Array<FlightConformanceResultDto>;
    incorrectMission: boolean;
    incorrectRpa: boolean;
}

function toCollectedBy(fl: FlightLogWithDuration) {
    if (fl.flightLogCollectionSource != null) {
        switch (fl.flightLogCollectionSource.type) {
            case SimpleFlightLogCollectionSourceDto.Type.DJI_CLOUD:
                return `DJI ${fl.flightLogCollectionSource.username}`;
            case SimpleFlightLogCollectionSourceDto.Type.DRONE_DEPLOY:
                return `Drone Deploy ${fl.flightLogCollectionSource.username}`;
            case SimpleFlightLogCollectionSourceDto.Type.SKYDIO:
                return `Skydio ${fl.flightLogCollectionSource.username}`;
            case SimpleFlightLogCollectionSourceDto.Type.DRONE_HARMONY:
                return 'Drone Harmony';
        }
    }

    return formatPerson(fl.creationBy);
}

type FILTER_OPTIONS = 'INCOMING' | 'ASSIGNED' | 'FAILED' | 'ARCHIVED';
@Component({
    selector: 'flight-log-list-dialogue',
    templateUrl: './flight-log-list-dialogue.component.html',
    providers: [FlightLogDataService]
})
export class FlightLogListDialogue {
    @Input() organisationId: number;
    @Input() flightLogs: FlightLogWithDuration[];

    private workTracker = new WorkTracker();
    working = false;

    canUseSettings = false;
    canAddBattery = false;
    canViewBatteries = false;
    canViewAllLogs = false;
    canReprocessLogs = false;

    flightLogsSource = new BehaviorSubject<FlightLogWithDuration[]>(undefined);
    hasFlightLogs = true;

    batteryListSource = new BehaviorSubject<BatteryDto[]>([]);

    batteriesLookup: LookupObject<BatteryDto>;

    unseenIds: number[] = [];

    baseDropValid: boolean;
    isUploading: boolean;

    isSystemAdmin: boolean;

    // filterSelection = new FormControl<FILTER_OPTIONS>('INCOMING');
    filterSelection = new FormControl<FILTER_OPTIONS>(
        'INCOMING',
        Validators.required
    );

    tableActionCount: number;
    tableSorting: ColumnSortPreferences = {
        column: 'startTime',
        ascending: false
    };
    tableSearch: any;
    tableFilters: any = {};

    flightLogs$ = this.flightLogsSource.pipe(
        filter(flightLogs => flightLogs != null)
    );

    selectedFlightLog: FlightLogWithDuration;

    uploadingFlightLogs: File[];

    availableColumns: TableColumn[];

    tableConfig: TableConfig;

    enhancedHelpActive = false;
    currentPage = new BehaviorSubject<number>(0);

    hasOutOfDateLogs = false;

    private ngUnsubscribe$ = new Subject<void>();

    @ViewChild(InlineModal, { static: true }) inlineModal: InlineModal;

    @ViewChild(ScreenAnalyticsDirective, { static: true })
    private screenAnalytics: ScreenAnalyticsDirective;

    constructor(
        private flightLogsService: FlightLogsService,
        @Inject(forwardRef(() => FlightLogsDialoguesService))
        private flightLogsDialoguesService: FlightLogsDialoguesService,
        @Inject(forwardRef(() => MissionDialoguesService))
        private missionDialoguesService: MissionDialoguesService,
        private missionService: MissionService,
        private flightLogDataService: FlightLogDataService,
        private batteryService: BatteryService,
        private batteryDialoguesService: BatteryDialoguesService,
        private logging: FlyFreelyLoggingService,
        private rpaDialoguesService: RpaDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        private userService: UserService,
        public modal: BsModalRef,
        modalOptions: ModalOptions,
        formatDuration: FormatDurationPipe,
        private activatedRoute: ActivatedRoute
    ) {
        modalOptions.closeInterceptor = () =>
            preHideCheck(() => this.preHide());

        this.availableColumns = [
            // {
            //     name: 'Upload Time',
            //     value: 'uploadTime',
            //     defaultSelection: true,
            //     searchable: false,
            //     selectable: false,
            //     formatterFunction: formatDateTime.transform
            // },
            {
                name: 'Flight Log File Name',
                value: 'fileName',
                searchable: 'text',
                defaultSelection: false,
                selectable: true
            },
            {
                name: 'Uploaded By',
                value: 'collectedBy',
                searchable: false,
                defaultSelection: true,
                selectable: false,
                sortable: false,
                formatterFunction: (_: any, fl: FlightLogWithDuration) =>
                    toCollectedBy(fl)
            },
            {
                name: 'Flight Start Time',
                value: 'startTime',
                defaultSelection: true,
                searchable: 'daterange',
                selectable: false,
                // searchFunction: dateRange,
                compareFunction: (a, b) =>
                    (<moment.Moment>a.rawData).diff(<moment.Moment>b.rawData)
            },
            {
                name: 'Duration',
                value: 'duration',
                defaultSelection: true,
                searchable: false,
                selectable: false,
                sortable: false,
                formatterFunction: (duration: number) =>
                    formatDuration.transform(duration)
            },
            {
                name: 'Batteries',
                value: 'batteryIds',
                defaultSelection: true,
                searchable: false,
                selectable: false,
                sortable: false
            },
            {
                name: 'Log RPA Type',
                key: 'rpaType',
                defaultSelection: true,
                searchable: false,
                selectable: false,
                sortable: false,
                formatterFunction: (_: any, row: FlightLogWithDuration) =>
                    row.summary?.rpaType
            },
            {
                name: 'Log RPA Name',
                key: 'logRpaName',
                defaultSelection: true,
                searchable: true,
                selectable: false,
                sortable: false,
                formatterFunction: (_: any, row: FlightLogWithDuration) =>
                    row.summary?.rpaName
            },
            {
                name: 'Log RPA FC Serial Number',
                description: `The Flight Controller for the RPA. This isn't the same as the airframe serial number`,
                key: 'logRpaSerialNumber',
                defaultSelection: true,
                searchable: true,
                selectable: false,
                sortable: false,
                formatterFunction: (_: any, row: FlightLogWithDuration) =>
                    row.summary?.rpaSerialNumber
            },
            {
                name: 'Auto Matched RPA',
                key: 'matchedRpaName',
                defaultSelection: true,
                searchable: false,
                selectable: false,
                sortable: false
            },
            {
                name: 'Mission Name',
                value: 'missionName',
                defaultSelection: true,
                searchable: 'text',
                selectable: false,
                sortable: false
            },
            {
                name: 'Flight',
                value: 'flightId',
                defaultSelection: true,
                searchable: false,
                selectable: false,
                sortable: false
            },
            {
                name: 'Conformance',
                key: 'conformance',
                defaultSelection: true,
                sortable: false,
                searchable: true,
                selectable: false,
                searchOptions: conformanceStatuses
                // searchFunction: (selection: string) => {
                //     if (selection === 'COMPLETED') {
                //         return (_, row: FlightLogWithDuration) =>
                //             row?.conformanceResultList
                //                 ?.find(r => r.status === 'SENT')
                //                 ?.ruleResultList?.filter(
                //                     r => r.status === 'UNACKNOWLEDGED'
                //                 )?.length === 0;
                //     } else {
                //         return (_, row: FlightLogWithDuration) =>
                //             row?.conformanceResultList?.find(
                //                 r => r.status === selection
                //             ) != null;
                //     }
                // }
            }
            // {
            //     name: 'Remote Pilot',
            //     value: 'pilot',
            //     defaultSelection: true,
            //     searchable: false,
            //     selectable: false,
            //     formatterFunction: (pilot: SimplePersonDto) =>
            //         formatPersonPipe.transform(pilot)
            // }
            // {
            //     name: 'Control Device Name',
            //     value: 'collectionDeviceName',
            //     defaultSelection: true,
            //     searchable: false,
            //     selectable: false
            // },
        ];

        this.isSystemAdmin =
            userService.getCurrentUser()?.type ===
            CurrentPersonDto.Type.SYSTEM_ADMIN;

        this.tableConfig = {
            limit: 20,
            serverPagination: true,
            actions: [],
            isWarningFunction: (log: FlightLogWithDuration) => log.outOfDate
            // isSeenFunction: (log: FlightLogWithDuration) => log.seen
        };
    }

    ngOnInit() {
        this.organisationId =
            +this.activatedRoute.snapshot.queryParams.organisationId;
        this.activatedRoute.queryParams.subscribe(params => {
            this.organisationId = +params.organisationId;
        });

        combineLatest([
            this.workTracker.observable,
            this.flightLogDataService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([working, gqlLoading]) =>
                    (this.working = working || gqlLoading)
            );

        this.userService.userChange$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(userChanged =>
                this.refreshPermissions(
                    this.userService.findOrganisationForUser(
                        this.organisationId
                    )
                )
            );

        this.filterSelection.valueChanges.subscribe(select => {
            this.onTablePageChanged(0);
            this.calculateActionColumns();
        });

        this.flightLogsService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshFlightLogs());

        this.batteryService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshBatteryList());

        this.refreshBatteryList();
        this.refreshFlightLogs();

        this.calculateActionColumns();

        combineLatest([
            this.flightLogDataService.totalItems$,
            this.flightLogDataService.currentPage$,
            this.flightLogDataService.flightLogs$,
            this.flightLogDataService.hasFlightLogs$,
            this.batteryListSource.asObservable().pipe(startWith([]))
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([totalItems, currentPage, logs, hasLogs, batteries]) => {
                    const currentTableConfig = this.tableConfig;
                    this.tableConfig = {
                        ...currentTableConfig,
                        totalItems: totalItems,
                        currentPage: currentPage
                    };
                    this.setupLogBatteries(logs, batteries);
                    this.hasFlightLogs = hasLogs;
                    this.hasOutOfDateLogs = logs.reduce(
                        (acc, log) => acc || log.outOfDate,
                        false
                    );
                }
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.flightLogsSource.complete();
        this.batteryListSource.complete();
    }

    refreshFlightLogs() {
        const filters = {
            ...this.tableFilters,
            [this.filterSelection.value === 'FAILED'
                ? 'processedStatus'
                : 'assignmentStatus']:
                this.filterSelection.value === 'FAILED'
                    ? FlightLogFileDto.ProcessedStatus.FAILED
                    : FlightLogFileDto.AssignmentStatus[
                          this.filterSelection.value === 'INCOMING'
                              ? 'UNASSIGNED'
                              : this.filterSelection.value
                      ]
        };

        this.flightLogDataService.findFlightLogs(
            this.currentPage.value,
            this.tableConfig.limit,
            this.tableSorting,
            filters,
            this.organisationId
        );
    }

    refreshBatteryList() {
        this.batteryService
            .findBatteries(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteries => {
                    this.batteryListSource.next(batteries);
                    this.batteriesLookup = batteries.reduce(toLookup, {});
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing organisation battery list: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    refreshPermissions(organisation: Option<PersonsOrganisationDto>) {
        this.canUseSettings = pipe(
            organisation,
            optionMap(o =>
                hasAnyPermission(o, [
                    PersonsOrganisationDto.Permissions
                        .FLIGHT_LOG_COLLECTION_MANAGE,
                    PersonsOrganisationDto.Permissions.FLIGHT_LOG_COLLECTION_OWN
                ])
            ),
            getOrElse(() => false)
        );

        this.canAddBattery = pipe(
            organisation,
            optionMap(o =>
                hasAnyPermission(
                    o,
                    PersonsOrganisationDto.Permissions.BATTERY_ADD
                )
            ),
            getOrElse(() => false)
        );

        this.canViewBatteries = pipe(
            organisation,
            optionMap(o =>
                hasAnyPermission(
                    o,
                    PersonsOrganisationDto.Permissions.BATTERY_LIST
                )
            ),
            getOrElse(() => false)
        );

        this.canViewAllLogs = pipe(
            organisation,
            optionMap(o =>
                hasAnyPermission(
                    o,
                    PersonsOrganisationDto.Permissions.FLIGHT_LOG_LIST
                )
            ),
            getOrElse(() => false)
        );

        const personalOrganisation = this.userService.getPersonalOrganisation();

        this.canReprocessLogs = pipe(
            organisation,
            optionMap(
                o =>
                    hasFeatureFlag(o, FEATURE_REPROCESS_FLIGHT_LOGS) &&
                    hasAnyPermission(
                        personalOrganisation,
                        PersonsOrganisationDto.Permissions.FLIGHT_LOG_MANAGE
                    )
            ),
            getOrElse(() => false)
        );

        const canUseConformance = pipe(
            organisation,
            optionMap(o => hasFeatureFlag(o, FEATURE_FLIGHT_CONFORMANCE)),
            getOrElse(() => false)
        );

        if (!canUseConformance) {
            this.availableColumns = this.availableColumns.filter(
                c => c.key !== 'conformance'
            );
        }
    }

    /**
     * Function to extract the battery serial from the flight log summary and,
     * if it's not null and matches a known battery,
     * add it to the battery id list in the observable being fed to the table.
     * @param logs The flight logs from the flightLogs$ observable
     * @param batteries The organisation's battery list
     */
    setupLogBatteries(logs: FlightLogWithDuration[], batteries: BatteryDto[]) {
        const findBatteryId = (log: FlightLogWithDuration) => {
            if (log.summary?.batterySerialNumber == null) {
                return null;
            }
            return batteries.find(
                b =>
                    b.manufacturerSerialNumber ===
                    log.summary.batterySerialNumber
            )?.id;
        };
        const withBatteries = logs.map(log => ({
            ...log,
            batteryIds:
                log.batteryIds != null && log.batteryIds.length > 0
                    ? findBatteryId(log) != null &&
                      !log.batteryIds.includes(findBatteryId(log))
                        ? log.batteryIds.concat([
                              batteries.find(
                                  b =>
                                      b.manufacturerSerialNumber ===
                                      log.summary.batterySerialNumber
                              ).id ?? null
                          ])
                        : log.batteryIds
                    : findBatteryId(log) != null
                    ? [findBatteryId(log)]
                    : []
        }));
        this.flightLogsSource.next(withBatteries);
    }

    archiveLog(log: FlightLogWithDuration) {
        this.flightLogsService
            .archiveFlightLog(log.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.refreshFlightLogs();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while archiving flight log: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    unarchiveLog(log: FlightLogWithDuration) {
        this.flightLogsService
            .unarchiveFlightLog(log.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.refreshFlightLogs();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while unarchiving flight log: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    showFlightAnalyser(log: FlightLogWithDuration, showMission?: boolean) {
        if (showMission === true && log.suggestedMissionAssignment != null) {
            this.missionService
                .findMission(log.suggestedMissionAssignment.missionId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: mission => {
                        this.flightLogsDialoguesService.showFlightLog(
                            log.id,
                            mission.organisationId,
                            mission,
                            null
                        );
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error while refreshing mission details and flight analyser: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        } else {
            this.flightLogsDialoguesService.showFlightLog(
                log.id,
                this.organisationId,
                null,
                null
            );
        }
    }

    onTablePageChanged(page: number) {
        this.tableConfig.currentPage = page;
        this.currentPage.next(page);
        this.refreshFlightLogs();
    }

    downloadLog(log: FlightLogWithDuration) {
        this.flightLogsService.getFlightLogFile(log.id);
    }

    reprocess() {
        this.doReprocess(this.flightLogsSource.value.map(fl => fl.id));
    }

    doReprocess(ids: number[]) {
        this.flightLogsService
            .reprocess(ids)
            .subscribe({
                next: updatedFlightLogs =>
                    this.logging.success(`Flight logs reprocessed`),
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while reprocessing flight logs: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    uploadLogs(files: File[]) {
        this.uploadingFlightLogs = files;

        const component = this.flightLogsDialoguesService.showFlightLogUploader(
            this.organisationId,
            files,
            this.screenAnalytics.helpActive
        );

        component.onHidden
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.uploadingFlightLogs = null;
                this.isUploading = false;
                this.refreshFlightLogs();
            });
    }

    unassignFlightLog(log: FlightLogFileDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Unassign Flight Log',
                `Are you sure you wish to unassign this flight log from this mission?`,
                'Unassign',
                () =>
                    firstValueFrom(
                        this.flightLogsService.assignFlightLog(log.id, {
                            missionId: null,
                            flightId: null
                        })
                    )
            )
            .then(
                () => {
                    this.refreshFlightLogs();
                    this.logging.success('Flight log unassigned');
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error unassigning flight log: ${error.message}`
                    )
            );
    }

    deleteLog(log: FlightLogFileDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Flight Log',
                `Are you sure you wish to delete this flight log?`,
                'Delete',
                () => this.flightLogsService.deleteFlightLog(log.id).toPromise()
            )
            .then(
                () => {
                    this.logging.success('Flight log deleted');
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error deleting flight log: ${error.message}`
                    )
            );
    }

    onDoneUploadingFlightLogs() {
        this.uploadingFlightLogs = null;
    }

    showRpa(rpaId: number) {
        this.rpaDialoguesService.showCraftDetails(
            rpaId,
            this.organisationId,
            false
        );
    }

    showBattery(id: number) {
        const battery = this.batteriesLookup[id];
        const organisation = this.userService
            .findUsersOrganisations()
            .find(o => o.id === this.organisationId);
        this.batteryDialoguesService.showBatteryDetailsDialogue(
            battery,
            organisation
        );
    }

    addBattery(log: FlightLogWithDuration) {
        this.batteryDialoguesService.showAddBatteryDialogue(
            this.organisationId,
            log.summary.batterySerialNumber,
            'flight log'
        );
    }

    showAssignMission(selectedFlightLog: FlightLogWithDuration) {
        this.flightLogsSource
            .pipe(
                map(logs =>
                    logs.filter(
                        l =>
                            l.assignmentStatus ===
                            FlightLogFileDto.AssignmentStatus.UNASSIGNED
                    )
                )
            )
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(flightLogs => {
                const { component, onDestroy } = this.inlineModal.showComponent(
                    AssignFlightLog,
                    {
                        flightLog: selectedFlightLog,
                        organisationId: this.organisationId,
                        flightLogs: flightLogs,
                        enhancedHelpActive: this.screenAnalytics.helpActive
                    }
                );

                component.cancel
                    .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(onDestroy))
                    .subscribe(() => {
                        this.uploadingFlightLogs = null;
                        this.inlineModal.closeComponent();
                    });
                component.done
                    .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(onDestroy))
                    .subscribe(() => {
                        this.uploadingFlightLogs = null;
                        this.inlineModal.closeComponent();
                    });
                component.viewFlightLog
                    .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(onDestroy))
                    .subscribe(id => {
                        const log = flightLogs.find(
                            l => l.id === id
                        ) as FlightLogWithDuration;
                        this.showFlightAnalyser(log);
                    });
            });
    }

    showAssignRpa(selectedFlightLog: FlightLogWithDuration) {
        const component = this.flightLogsDialoguesService.showAssignRpa(
            // The GQL type needs to be reformatted to work for the type the assign modal requires
            selectedFlightLog,
            this.screenAnalytics.helpActive
        );

        component.content.done
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(component.onHidden))
            .subscribe(() => {
                this.refreshFlightLogs();
            });
    }

    showMission(missionId: number) {
        const organisation = this.userService.findOrganisationForUser(
            this.organisationId
        );
        if (isSome(organisation)) {
            this.missionDialoguesService.showMissionDetails(
                missionId,
                false,
                organisation.value
            );
        } else {
            this.logging.error(null, `Error fetching the organisation`);
        }
    }

    showMissionRecord(missionId: number) {
        const organisation = this.userService.findOrganisationForUser(
            this.organisationId
        );
        if (isSome(organisation)) {
            this.missionService
                .findMission(missionId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: mission => {
                        this.missionDialoguesService.showMissionCompletion(
                            mission,
                            organisation.value
                        );
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error fetching the mission: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            this.logging.error(null, `Error fetching the organisation`);
        }
    }

    showSettings() {
        this.flightLogsDialoguesService.showFlightLogSettings(
            this.organisationId
        );
    }

    reassignRpa(flightLog: FlightLogWithDuration) {
        this.flightLogsService
            .reassignRpa(flightLog.id)
            .subscribe({
                next: DO_NOTHING,
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error automatically reassigning the RPA: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    private calculateActionColumns() {
        const value = this.filterSelection.value;
        const baseNumber =
            value === 'INCOMING' || value === 'ARCHIVED'
                ? 3
                : value === 'ASSIGNED'
                ? 1
                : 2;
        this.tableActionCount = this.isSystemAdmin
            ? baseNumber + 1
            : baseNumber;
    }

    onTableSearch(search: { [key: string]: any }) {
        this.tableSearch = search;
        const newFilter = {};
        for (const key of Object.keys(search)) {
            if (search[key]) {
                if (key === 'startTime') {
                    newFilter['startTime'] = toTimestamp(search[key][0]);
                    newFilter['endTime'] = toTimestamp(search[key][1]);
                } else if (key === 'conformance') {
                    newFilter['conformanceResultStatus'] = search[key];
                } else {
                    newFilter[key] = search[key];
                }
            }
        }
        this.tableFilters = newFilter;
        this.tableConfig.currentPage = 0;
        this.currentPage.next(0);
        this.refreshFlightLogs();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.tableSorting = sorting;
        this.refreshFlightLogs();
    }

    formatStartDate(values: [Date, Date]) {
        const start = toTimestamp(values[0]);
        const end = toTimestamp(values[1]);

        return `${start} ${end}`;
    }

    private preHide() {
        if (this.inlineModal.show) {
            this.inlineModal.closeComponent();
            return false;
        }

        return true;
    }

    close() {
        this.modal.hide();
    }
}
