<div class="modal-header no-icon">
    <h3 class="modal-title">Attachment History</h3>
    <button type="button" (click)="close()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-container *ngIf="attachmentHistory">
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th>Version</th>
                        <th>Changes</th>
                        <th>Update time</th>
                        <th>Updated by</th>
                        <th>Original File Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let version of attachmentHistory.versions;
                            let index = index
                        "
                    >
                        <td class="wordwrap">
                            <a
                                *ngIf="canDownload"
                                [downloadUrl]="version.downloadUrl"
                                angulartics2On="click"
                                angularticsCategory="attachments"
                                angularticsAction="download-previous-version"
                            >
                                Version {{ index }}
                            </a>
                            <p *ngIf="!canDownload">Version {{ index }}</p>
                        </td>
                        <td class="wordwrap">
                            {{ version.changes }}
                        </td>
                        <td class="wordwrap">
                            {{ version.creationTime | formatDateTime }}
                        </td>
                        <td class="wordwrap">
                            {{ version.creationBy.firstName }}
                            {{ version.creationBy.lastName }}
                        </td>
                        <td class="wordwrap">
                            {{ version.originalFileName }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- <a [downloadUrl]="attachment.downloadUrl" target="_blank">
            {{ attachment.name }}
        </a>
        <p class="help-block" *ngIf="attachment.id">
            (Updated
            {{ attachment.uploadTime | formatDateTime }})
        </p> -->
        </div>
    </ng-container>
</div>
