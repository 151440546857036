import {
    EquipmentTypeDto,
    CreateEquipmentTypeCommand,
    ChangeEquipmentTypeSchematicCommand
} from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EquipmentModelService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<EquipmentTypeDto[]>(
            `${this.baseUrl}/webapi/equipmentModels`,
            {
                params: new HttpParams().set(
                    'organisationId',
                    organisationId.toString()
                )
            }
        );
    }

    findById(equipmentModelId: number, managingOrganisationId: number) {
        return this.http.get<EquipmentTypeDto>(
            `${this.baseUrl}/webapi/equipmentModels/${equipmentModelId}`,
            {
                params: new HttpParams().set(
                    'managingOrganisationId',
                    managingOrganisationId.toString()
                )
            }
        );
    }

    create(command: CreateEquipmentTypeCommand) {
        return this.http
            .post<EquipmentTypeDto>(
                `${this.baseUrl}/webapi/equipmentModels`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    changeEquipmentTypeSchematic(
        equipmentTypeId: number,
        command: ChangeEquipmentTypeSchematicCommand
    ) {
        return this.http
            .put<EquipmentTypeDto>(
                `${this.baseUrl}/webapi/equipmentModels/${equipmentTypeId}/schematic`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    getSchematicAttachmentUrl(
        resourceSchematicId: number,
        attachmentId: number
    ) {
        if (resourceSchematicId == null || attachmentId == null) {
            return null;
        }
        return `${this.baseUrl}/webapi/resources/resourceSchematics/${resourceSchematicId}/attachments/${attachmentId}`;
    }
}
