import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import {
    AsYouType,
    CountryCode,
    parsePhoneNumberFromString,
    PhoneNumber
} from 'libphonenumber-js/max';
import { Country } from 'libs/addresses/src/lib/address-edit/address-edit-dialogue.component';
import CountryStates from 'libs/ui/src/lib/data/country-states.json';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

function loadSortedCountries() {
    const countries = CountryStates as Country[];
    countries.sort((a, b) => a.name.localeCompare(b.name));
    return countries;
}

const countryStates: Country[] = loadSortedCountries();

@Component({
    selector: 'formly-phonenumber',
    template: `
        <div class="input-group">
            <div
                class="input-group-btn"
                dropdown
                container="body"
                [insideClick]="true"
            >
                <button
                    type="button"
                    dropdownToggle
                    class="dropdown-toggle btn btn-default"
                    aria-controls="dropdown-basic"
                >
                    <span class="flag {{ selectedCountryClass }}"></span>
                    <span *ngIf="country" class="phone-prefix-space">
                        +{{ country.phonePrefix }}
                    </span>
                    <span class="caret"></span>
                </button>
                <ul
                    id="dropdown-basic"
                    *dropdownMenu
                    class="dropdown-menu formly-phonenumber"
                    role="menu"
                    aria-labelledby="button-basic"
                >
                    <div class="static-table-container no-actions ">
                        <div class="inner-addon left-addon">
                            <span class="fa fa-search"></span>
                            <input
                                type="text"
                                class="form-control input-sm"
                                [formControl]="searchControl"
                            />
                        </div>
                        <li
                            class="menuitem"
                            role="menuitem"
                            *ngFor="let c of filteredCountries"
                            (click)="selectCountry(c)"
                            dropdownToggle
                        >
                            <span
                                class="flag fg_{{ c['iso'].toLowerCase() }}"
                            ></span>
                            <span class="dropdown-item" href="#"
                                >{{ c.name }} +{{ c.phonePrefix }}</span
                            >
                        </li>
                    </div>
                </ul>
            </div>
            <input
                [(ngModel)]="value"
                [placeholder]="placeholder"
                type="tel"
                class="form-control"
            />
        </div>
    `,
    styleUrls: ['./styles.scss']
})
export class FormlyPhoneNumber extends FieldType {
    countries = countryStates;

    filteredCountries = countryStates;

    country: Country;
    selectedCountryClass: string;

    searchControl: FormControl;

    // The number part of the phone number
    number: string;
    placeholder: string;

    private ngUnsubscribe$ = new Subject<void>();

    controlSubscription: Subscription;

    ngOnInit() {
        this.controlSubscription = this.formControl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(v => this.parseValue(v));
        this.parseValue(this.formControl.value);
        this.searchControl = new FormControl('');
        this.searchControl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(search => this.liveSearch(search));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private parseValue(phoneNumber: string) {
        if (phoneNumber == null) {
            this.setCountry(null);
            this.number = phoneNumber;
            return;
        }

        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (parsedNumber == null) {
            const countryDetails = this.findCountry(phoneNumber);
            this.setCountry(countryDetails.country);
            this.number = countryDetails.number;
            return;
        }

        if (parsedNumber.country == null) {
            const countryDetails = this.findCountry(phoneNumber);
            if (countryDetails != null) {
                this.setCountry(countryDetails.country);
                this.number = countryDetails.number;
                return;
            }
            this.number = phoneNumber;
            return;
        }

        this.setCountry(
            this.countries.find(c => c.iso === parsedNumber.country)
        );

        this.number = parsedNumber.nationalNumber.toString();
    }

    liveSearch(search: string) {
        this.filteredCountries =
            this.countries.filter(
                c =>
                    c.name.toLowerCase().includes(search.toLowerCase()) ||
                    c.iso.toLowerCase().includes(search.toLowerCase()) ||
                    c.phonePrefix.includes(
                        search && search[0] === '+' ? search.slice(1) : search
                    )
            ) ?? countryStates;
    }

    findCountry(phoneNumber: string) {
        if (phoneNumber == null || phoneNumber[0] !== '+') {
            return;
        }
        let i: number = phoneNumber.length;
        let parsedNumber: PhoneNumber;
        let searchNumber = phoneNumber;
        let country: Country;
        while (i <= 50 && country == null) {
            parsedNumber = parsePhoneNumberFromString(searchNumber);
            if (
                parsedNumber == null ||
                parsedNumber.countryCallingCode == null
            ) {
                searchNumber = `${searchNumber}0`;
            } else {
                country = this.countries.find(
                    c => c.phonePrefix === `${parsedNumber.countryCallingCode}`
                );
            }
            i++;
        }
        const slicedNumber =
            country != null
                ? phoneNumber.slice(country.phonePrefix.length + 1)
                : phoneNumber;
        return {
            country: country,
            number: slicedNumber
        };
    }

    set value(value: string) {
        this.number = value;
        this.storeValue();
    }

    get value() {
        return this.number;
    }

    private setCountry(country: Country) {
        this.country = country;
        if (country == null) {
            this.selectedCountryClass = 'fg_';
            this.placeholder = '';
        } else {
            this.selectedCountryClass = `fg_${country.iso.toLowerCase()}`;
            this.placeholder = new AsYouType(country.iso as CountryCode).input(
                country.phonePlaceholder
            );
        }
    }

    selectCountry(country: Country) {
        this.setCountry(country);
        this.storeValue();
    }

    private storeValue() {
        if (this.number == null) {
            // Don't do anything with invalid number
            return;
        }
        if (this.country == null) {
            this.formControl.setValue(this.number);
        } else {
            const normalisedNumber = parsePhoneNumberFromString(
                this.number,
                this.country.iso as CountryCode
            );
            // new AsYouType(this.country.iso as CountryCode).input(this.number || '')
            // formatNumber(
            //     this.number || '',
            //     this.country.iso as CountryCode,
            //     'INTERNATIONAL'
            // );
            if (normalisedNumber != null) {
                this.formControl.setValue(
                    normalisedNumber.formatInternational()
                );
            }
        }
    }
}
