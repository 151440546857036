/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.34
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FlightLogCriteria {
    organisationId: number;
    idList?: number[];
    processedStatus?: FlightLogCriteria.ProcessedStatus;
    assignmentStatus?: FlightLogCriteria.AssignmentStatus;
    missionId?: number;
    missionName?: string;
    matchedRpaName?: string;
    flightId?: number;
    startTime?: string;
    endTime?: string;
    collectionStartTime?: string;
    collectionEndTime?: string;
    fileName?: string;
    conformanceResultStatus?: FlightLogCriteria.ConformanceResultStatus;
    logRpaName?: string;
    logBatterySerialNumber?: string;
    logRpaSerialNumber?: string;
    incorrectAssociations?: boolean;
    outOfDate?: boolean;
}
export namespace FlightLogCriteria {
    export type ProcessedStatus =
        | 'UNPROCESSED'
        | 'PROCESSING'
        | 'PROCESSED'
        | 'FAILED';
    export const ProcessedStatus = {
        UNPROCESSED: 'UNPROCESSED' as ProcessedStatus,
        PROCESSING: 'PROCESSING' as ProcessedStatus,
        PROCESSED: 'PROCESSED' as ProcessedStatus,
        FAILED: 'FAILED' as ProcessedStatus
    };
    export type AssignmentStatus =
        | 'UNASSIGNED'
        | 'ARCHIVED'
        | 'ASSIGNED'
        | 'DELETED';
    export const AssignmentStatus = {
        UNASSIGNED: 'UNASSIGNED' as AssignmentStatus,
        ARCHIVED: 'ARCHIVED' as AssignmentStatus,
        ASSIGNED: 'ASSIGNED' as AssignmentStatus,
        DELETED: 'DELETED' as AssignmentStatus
    };
    export type ConformanceResultStatus =
        | 'NO_POLICY'
        | 'NO_BREACHES'
        | 'PRELIMINARY'
        | 'SENT'
        | 'CANCELLED'
        | 'COMPLETED'
        | 'FINALISED';
    export const ConformanceResultStatus = {
        NO_POLICY: 'NO_POLICY' as ConformanceResultStatus,
        NO_BREACHES: 'NO_BREACHES' as ConformanceResultStatus,
        PRELIMINARY: 'PRELIMINARY' as ConformanceResultStatus,
        SENT: 'SENT' as ConformanceResultStatus,
        CANCELLED: 'CANCELLED' as ConformanceResultStatus,
        COMPLETED: 'COMPLETED' as ConformanceResultStatus,
        FINALISED: 'FINALISED' as ConformanceResultStatus
    };
}
