/**
 * This module is for formly forms that are reused across multiple systems.
 */

import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * For @class UserDebugReportCommand
 */
export const DEBUG_REPORT_FIELDS: FormlyFieldConfig[] = [
    {
        key: 'description',
        type: 'textarea',
        props: {
            label: 'Description',
            required: true
        }
    },
    {
        key: 'authoriseSharingWithVendors',
        type: 'checkbox',
        props: {
            label: 'Authorise sharing with vendors',
            required: false,
            description:
                'Can FlyFreely pass this file to the vendor who is supporting this service to help us resolve the issue faster?'
        }
    },
    {
        key: 'authoriseUseInTests',
        type: 'checkbox',
        props: {
            label: 'Authorise use in tests',
            required: false,
            description:
                'Can FlyFreely retain this file to test for regressions'
        }
    }
];
