/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface FlightConformanceRuleDto { 
    ruleId: number;
    ruleVersionId: number;
    type: FlightConformanceRuleDto.Type;
    description?: string;
    defaultValue?: number;
    maximumValue?: number;
    enabled: boolean;
}
export namespace FlightConformanceRuleDto {
    export type Type = 'VISUAL_DISTANCE' | 'MAXIMUM_HEIGHT' | 'FLIGHT_AREA' | 'LANDING_BATTERY_LEVEL';
    export const Type = {
        VISUAL_DISTANCE: 'VISUAL_DISTANCE' as Type,
        MAXIMUM_HEIGHT: 'MAXIMUM_HEIGHT' as Type,
        FLIGHT_AREA: 'FLIGHT_AREA' as Type,
        LANDING_BATTERY_LEVEL: 'LANDING_BATTERY_LEVEL' as Type
    };
}



