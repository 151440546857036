import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { EnhancedHelpAdminService } from '../enhanced-help-admin.service';
import { EnhancedHelpService } from '../enhanced-help.service';
import {
    DO_NOTHING,
    EnhancedHelpDto,
    UpdateEnhancedHelpCommand
} from '@flyfreely-portal-ui/flyfreely';
import { EnhancedHelpTreeService } from '../enhanced-help-tree.service';

@Injectable({
    providedIn: 'root'
})
export class DynamicHelpEditService {
    private recentSelectionListSource = new ReplaySubject<EnhancedHelpDto[]>(1);
    recentSelectionList$ = this.recentSelectionListSource.asObservable();
    private currentScreenSource = new ReplaySubject<string>(1);
    currentScreen$ = this.currentScreenSource.asObservable();
    private currentScreenComponentsSource = new ReplaySubject<
        EnhancedHelpDto[]
    >(1);
    currentScreenComponents$ = this.currentScreenComponentsSource.asObservable();

    private editModeSource = new BehaviorSubject<boolean>(false);
    editMode$ = this.editModeSource.asObservable();

    private recentSelections: EnhancedHelpDto[] = [];
    private editMode: boolean;
    private currentScreen: string;
    private screenTree = this.enhancedHelpTreeService.screenTree;

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private enhancedHelpService: EnhancedHelpService,
        private enhancedHelpAdminService: EnhancedHelpAdminService,
        private enhancedHelpTreeService: EnhancedHelpTreeService
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.recentSelectionListSource.complete();
        this.editModeSource.complete();
        this.currentScreenSource.complete();
        this.currentScreenComponentsSource.complete();
    }

    setEditMode(mode: boolean) {
        this.editMode = mode;
        this.editModeSource.next(this.editMode);
    }

    elementSelected(elementName: string, screenName?: string) {
        if (!this.editMode) {
            return;
        }
        if (screenName == null && this.currentScreen != null) {
            screenName = this.currentScreen;
        }
        if (screenName != null) {
            const screenObject = this.screenTree.find(
                h => h.analyticsIdentifier === screenName
            );
            if (screenName != this.currentScreen) {
                this.currentScreen = screenName;
                this.currentScreenSource.next(screenName);
                this.recentSelections = [];
                this.recentSelectionListSource.next([]);
                this.enhancedHelpService
                    .findByScreen(screenObject.identifier)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(components => {
                        const emptyComponents = screenObject.components.filter(
                            c =>
                                components.find(
                                    comp => comp.component === c.identifier
                                ) == null
                        );
                        const empties: EnhancedHelpDto[] = emptyComponents.map(
                            c => ({
                                component: c.identifier,
                                helpText: c.defaultText,
                                helpTitle: c.name,
                                screen:
                                    screenObject.identifier ??
                                    screenObject.analyticsIdentifier
                            })
                        );
                        this.currentScreenComponentsSource.next(
                            components.concat(empties)
                        );
                    });
            }
            this.enhancedHelpService
                .findByScreenAndComponent(
                    screenObject.identifier,
                    elementName,
                    true
                )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(component => {
                    this.recentSelections = this.recentSelections.filter(
                        s => s.component != component.component
                    );
                    if (this.recentSelections.length < 10) {
                        this.recentSelections.unshift(component);
                    } else {
                        this.recentSelections.pop();
                        this.recentSelections.unshift(component);
                    }
                    this.recentSelectionListSource.next(this.recentSelections);
                });
        }
    }

    updateElement(command: UpdateEnhancedHelpCommand) {
        return this.enhancedHelpAdminService
            .update(command)
            .pipe(
                tap(() =>
                    this.enhancedHelpService
                        .findAll()
                        .pipe(take(1))
                        .subscribe(DO_NOTHING)
                )
            );
    }
}
