<enhanced-help componentName="rpa-details" [compact]="true"></enhanced-help>
<div class="container-with-footer">
    <div class="contents">
        <div class="data">
            <span class="display-label">Name/Designation</span>
            {{ rpa.nickname }}
        </div>

        <div class="data">
            <span class="display-label">RPA Classification</span>
            {{ rpa.isDummy ? 'Dummy RPA' : 'Real RPA' }}
        </div>

        <div class="data">
            <span class="display-label">Type</span>
            {{ rpa.rpaType | formatRpaType }}
        </div>

        <div class="data">
            <span class="display-label">Manufacturer Serial Number</span>
            {{ rpa.manufacturerSerialNumber }}
        </div>

        <div class="data">
            <span class="display-label">Flight Controller Serial Number</span>
            {{ rpa.internalSerialNumber }}
        </div>

        <div *ngIf="canUseNfc" class="data">
            <span class="display-label">NFC UID</span>
            {{ rpa.nfcUid }}
        </div>

        <div class="data">
            <span class="display-label">Historical Flight Time</span>
            {{ rpa.initialFlightTime | formatDuration | defaultTo }}
        </div>

        <div class="data">
            <span class="display-label">Call sign</span>
            {{ rpa.callSign }}
        </div>

        <div class="data">
            <span class="display-label">Procurement Date</span>
            {{ rpa.procurementDate | formatDate }}
        </div>

        <div class="data">
            <span class="display-label">Maximum Visual Distance</span>
            <ng-container [ngSwitch]="maximumVisualDistance?.source">
                <ng-container *ngSwitchCase="'CUSTOM'"
                    >{{ maximumVisualDistance.value }}m
                    <em>Custom</em></ng-container
                >
                <ng-container *ngSwitchCase="'TYPE'"
                    >{{ maximumVisualDistance.value }}m
                    <em>RPA Type</em></ng-container
                >
                <ng-container *ngSwitchCase="'NONE'"
                    ><em>Not set</em></ng-container
                >
            </ng-container>
        </div>

        <div class="data">
            <span class="display-label">Status</span>
            {{ rpa.status | formatResourceStatus }}
        </div>

        <div class="data" *ngIf="rpa.status === 'DISPOSED'">
            <span class="display-label">Disposal Date</span>
            {{ rpa.disposalDate | formatDate }}
        </div>

        <div class="data">
            <span class="display-label">Owner</span>
            {{ owner?.name }}
        </div>

        <div class="data" *ngIf="canUseWorkgroups">
            <span class="display-label">Workgroups</span>
            {{ workgroups || 'None' }}
        </div>

        <h4 class="section-heading top-buffer">
            <span>Attachments</span>
            <div></div>
        </h4>

        <attachment-list
            [attachmentsHandler]="attachmentsHandler"
            [allowUploads]="false"
            [allowDelete]="false"
            [allowEdit]="false"
            [parentPromiseTracker]="workTracker"
            permissions
        ></attachment-list>
    </div>
    <div class="action-container">
        <button
            *ngIf="hasDelete"
            class="btn btn-danger pull-left"
            type="button"
            (click)="deleteAircraft()"
            [disabled]="!canDelete || working"
        >
            <span class="fal fa-trash-alt"></span>
            Delete
        </button>

        <div
            *ngIf="printOptions != null && printOptions.length > 0"
            class="btn-group"
            dropdown
            [dropup]="true"
        >
            <button
                id="reports-button-container"
                dropdownToggle
                type="button"
                class="btn btn-default dropdown-toggle"
                aria-controls="dropdown-container"
                [disabled]="working"
            >
                Reports
                <span class="caret"></span>
            </button>
            <ul
                *dropdownMenu
                id="reports-dropdown-container"
                class="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="reports-button-container"
            >
                <li role="menuitem" *ngFor="let report of printOptions">
                    <a class="dropdown-item" (click)="report.print()">{{
                        report.name
                    }}</a>
                </li>
            </ul>
        </div>
        <div
            *ngIf="canManage && hasManageOptions"
            class="btn-group"
            dropdown
            [dropup]="true"
            [isDisabled]="working"
        >
            <button
                *ngIf="canEdit"
                id="manage-button-container"
                dropdownToggle
                type="button"
                class="btn btn-default dropdown-toggle"
                aria-controls="dropdown-container"
                [ngClass]="{ disabled: working }"
            >
                Manage
                <span class="caret"></span>
            </button>
            <ul
                *dropdownMenu
                id="manage-dropdown-container"
                class="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="manage-button-container"
            >
                <li role="menuitem" *ngIf="canMarkRetiredAirCraft()">
                    <a class="dropdown-item" (click)="markAircraftRetired()"
                        >Mark as Retired</a
                    >
                </li>
                <li role="menuitem" *ngIf="canMarkUnderMaintenance()">
                    <a
                        class="dropdown-item"
                        (click)="markAircraftUnderMaintenance()"
                        >Mark as Under Maintenance</a
                    >
                </li>
                <li role="menuitem" *ngIf="canMarkActive()">
                    <a class="dropdown-item" (click)="markAircraftActive()"
                        >Mark as Serviceable</a
                    >
                </li>
                <li role="menuitem" *ngIf="canMarkGrounded()">
                    <a class="dropdown-item" (click)="markAircraftGrounded()"
                        >Mark as Unserviceable</a
                    >
                </li>
                <li role="menuitem" *ngIf="canDisposeRpa()">
                    <a class="dropdown-item" (click)="disposeRpa()"
                        >Dispose RPA</a
                    >
                </li>
            </ul>
        </div>

        <button
            *ngIf="hasEdit"
            [disabled]="!canEdit || working"
            class="btn btn-primary"
            type="button"
            (click)="editRPA()"
            data-cy="editRpa"
        >
            Edit
        </button>
    </div>
</div>
