import MapboxDraw from '@mapbox/mapbox-gl-draw';

class LineState {
    line: any; // DrawFeature

    currentVertexPosition: number;
    direction: string;
    isDragging: boolean;
}
// We're extending the default line string
const DrawLine = { ...MapboxDraw.modes.draw_line_string };

DrawLine.onTrash = function (state: LineState) {
    // remove last added coordinate

    if (state.currentVertexPosition < 2) {
        return;
    }

    state.line.removeCoordinate(`${state.currentVertexPosition}`);
    state.currentVertexPosition--;
    const lastCoordinate = state.line.coordinates[state.currentVertexPosition];

    state.line.updateCoordinate(
        state.currentVertexPosition,
        lastCoordinate.lngLat.lng,
        lastCoordinate.lngLat.lat
    );
    // unfortunately, below does not work. See https://github.com/mapbox/mapbox-gl-draw/issues/959
    // it does not execute displayFeatures.
    this.doRender(state.line.id);

    return state;
};

export default DrawLine;
