import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostBinding
} from '@angular/core';
import { NotificationFeedService } from 'libs/notifications/src/lib/notification-feed.service';
import { Subject } from 'rxjs';
import { NotificationDto } from '@flyfreely-portal-ui/flyfreely';
import * as moment from 'moment';

@Component({
    selector: 'notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['../styles.scss']
})
export class NotificationCard {
    @Input() notification: NotificationDto;
    @Output() cardRead = new EventEmitter<void>();

    day: string;
    month: string;

    private ngUnsubscribe$ = new Subject<void>();
    constructor(private notificationFeedService: NotificationFeedService) {}

    ngOnInit() {
        if (this.notification.action == null) {
            this.notification.action = 'General';
        }
        if (this.notification.subject == null) {
            this.notification.subject = ' ';
        }
        if (this.notification.timestamp != null) {
            this.day = moment(this.notification.timestamp)
                .format('DD')
                .toString();
            this.month = moment(this.notification.timestamp)
                .format('MMM')
                .toString();
        } else {
            this.day = ' ';
            this.month = ' ';
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    goToSubject() {
        this.notificationFeedService.goToNotificationSubject(
            this.notification.id
        );
    }

    archiveNotification() {
        this.cardRead.emit();
    }
}
