import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    AdminOrganisationDto,
    MissionService,
    MissionSummaryDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * A simple inline mission selection component
 */
@Component({
    selector: 'select-mission',
    templateUrl: './select-mission.component.html'
})
export class SelectMission {
    @Input() organisation: AdminOrganisationDto;
    @Output() done = new EventEmitter<MissionSummaryDto>();
    @Output() cancel = new EventEmitter<void>();

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    candidateMissions: MissionSummaryDto[];

    selectedMission: MissionSummaryDto;

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(private missionService: MissionService) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.findCandidateMissions();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private findCandidateMissions() {
        const doneWorking = this.workTracker.createTracker();

        this.missionService
            .findMissions(this.organisation.id, {})
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missions => {
                this.candidateMissions = missions;
                doneWorking();
            });
    }

    selectMission() {
        this.done.emit(this.selectedMission);
    }
}
