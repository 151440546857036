import { Injectable } from '@angular/core';
import {
    AirspaceJurisdictionDto,
    CraftAuthorityGroup,
    isOrganisationAuthorityGroup,
    OrganisationAuthorityGroup,
    SimpleAirspaceJurisdictionDto
} from '@flyfreely-portal-ui/flyfreely';
import { ReplaySubject } from 'rxjs';

type AuthorityGroup = OrganisationAuthorityGroup | CraftAuthorityGroup;

/**
 * This is a scoped service that manages the authorities used by the "AuthorityGroupAddComponent".
 */
@Injectable()
export class AuthorityGroupAddService<
    T extends AuthorityGroup = AuthorityGroup
> {
    private filteredAuthoritiesSubject = new ReplaySubject<T[]>();
    filteredAuthorities$ = this.filteredAuthoritiesSubject.asObservable();

    availableJurisdictions: SimpleAirspaceJurisdictionDto[];

    private managingOrganisationId: number;

    ngOnDestroy() {
        this.filteredAuthoritiesSubject.complete();
    }

    setup(organisationId: number) {
        this.managingOrganisationId = organisationId;
    }

    updateAuthorities(
        authorities: T[],
        jurisdictions: SimpleAirspaceJurisdictionDto[]
    ) {
        this.availableJurisdictions = jurisdictions;
        const availableAuthorities = authorities.filter(
            a =>
                a.issuingMechanism !==
                    OrganisationAuthorityGroup.IssuingMechanism.ISSUER ||
                (a.issuingMechanism ===
                    OrganisationAuthorityGroup.IssuingMechanism.ISSUER &&
                    a.issuedBy.id === this.managingOrganisationId)
        );

        const filteredAuthorities = this.sortAuthorities(availableAuthorities);
        this.filteredAuthoritiesSubject.next(filteredAuthorities);
    }

    private sortAuthorities(authorities: T[]) {
        const primaries = authorities
            .filter(
                a =>
                    isOrganisationAuthorityGroup(a) &&
                    a.isPrimary === true &&
                    this.isInAvailableJurisdictions(a.jurisdiction)
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1));

        const nonPrimaries = authorities
            .filter(
                a =>
                    primaries.findIndex(p => p.id === a.id) === -1 &&
                    this.isInAvailableJurisdictions(a.jurisdiction)
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1));

        return primaries.concat(nonPrimaries);
    }

    private isInAvailableJurisdictions(jurisdiction: AirspaceJurisdictionDto) {
        // assume null jurisdiction to mean global
        if (jurisdiction == null) {
            return true;
        }
        return (
            this.availableJurisdictions.findIndex(
                j => j.id === jurisdiction.id
            ) !== -1
        );
    }
}
