import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    clearFormArray,
    LinkUnlistedEntityCommand,
    MissionService,
    UnlistedBatterySetDto,
    UnlistedEquipmentDto,
    UnlistedPersonDto,
    UnlistedRpaDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissionCompletionService } from '../mission-completion.service';

interface UnlistedResourceSelectionForm {
    id?: FormControl<number>;
    ignored: FormControl<boolean>;
}

interface UnlistedResourceSelectionForm {
    id?: FormControl<number>;
    ignored: FormControl<boolean>;
}

export interface MissionUnlistedResourcesFormModel {
    unlistedRpas: FormArray<FormGroup<UnlistedResourceSelectionForm>>;
    unlistedBatterySets: FormArray<FormGroup<UnlistedResourceSelectionForm>>;
    unlistedPersonnel: FormArray<FormGroup<UnlistedResourceSelectionForm>>;
    unlistedEquipment: FormArray<FormGroup<UnlistedResourceSelectionForm>>;
}

function createUnlistedResourceSelectionFormGroup(
    id: number,
    ignored: boolean
) {
    return new FormGroup<UnlistedResourceSelectionForm>(
        {
            id: new FormControl<number>(id),
            ignored: new FormControl<boolean>(ignored)
        },
        (c: FormGroup<UnlistedResourceSelectionForm>) =>
            c.value == null || (c.value.id == null && c.value.ignored !== true)
                ? { required: true }
                : null
    );
}

@Component({
    selector: 'unlisted-mission-resources',
    templateUrl: './unlisted-mission-resources.component.html'
})
export class UnlistedMissionResourcesComponent {
    unlistedPersonnel: UnlistedPersonDto[];
    unlistedRpas: UnlistedRpaDto[];
    unlistedBatterySets: UnlistedBatterySetDto[];
    unlistedEquipment: UnlistedEquipmentDto[];
    organisationId: number;
    missionId: number;

    form: FormGroup<MissionUnlistedResourcesFormModel>;

    personnelLoaded = false;
    craftLoaded = false;
    batterySetsLoaded = false;
    equipmentLoaded = false;

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionService: MissionService,
        public missionCompletionService: MissionCompletionService
    ) {
        this.form = new FormGroup<MissionUnlistedResourcesFormModel>({
            unlistedRpas: new FormArray([]),
            unlistedBatterySets: new FormArray([]),
            unlistedPersonnel: new FormArray([]),
            unlistedEquipment: new FormArray([])
        });
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.missionCompletionService.savedMission$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mission => {
                this.missionId = mission.id;
                this.organisationId = mission.organisationId;

                this.unlistedBatterySets = mission.unlistedBatterySets;
                this.unlistedEquipment = mission.unlistedEquipment;
                this.unlistedPersonnel = mission.unlistedPersonnel;
                this.unlistedRpas = mission.unlistedRpas;

                this.setupForm();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupForm() {
        clearFormArray(this.unlistedRpaForm);
        this.unlistedRpas.forEach(r => this.addRpa(r));

        clearFormArray(this.unlistedBatterySetsForm);
        this.unlistedBatterySets.forEach(b => this.addBatterySet(b));

        clearFormArray(this.unlistedPersonnelForm);
        this.unlistedPersonnel.forEach(p => this.addPerson(p));

        clearFormArray(this.unlistedEquipmentForm);
        this.unlistedEquipment.forEach(e => this.addEquipment(e));
        this.form.updateValueAndValidity();
    }

    linkPerson(i: number, field: 'ignored' | 'id') {
        const { id, ignored: ignoredValue } =
            this.unlistedPersonnelForm.controls[i].value;
        const ignored = field === 'ignored' && ignoredValue;
        const command: LinkUnlistedEntityCommand = {
            ignored,
            identifier: this.unlistedPersonnel[i].id,
            id: ignored ? null : id
        };

        this.missionService
            .linkUnlistedPerson(command, this.missionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result =>
                this.missionCompletionService.setupMission(
                    result,
                    this.organisationId
                )
            )
            .add(this.workTracker.createTracker());
    }

    linkRpa(i: number, field: 'ignored' | 'id') {
        const { id, ignored: ignoredValue } =
            this.unlistedRpaForm.controls[i].value;
        const ignored = field === 'ignored' && ignoredValue;
        const command: LinkUnlistedEntityCommand = {
            ignored,
            identifier: this.unlistedRpas[i].id,
            id: ignored ? null : id
        };

        this.missionService
            .linkUnlistedRpa(command, this.missionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result =>
                this.missionCompletionService.setupMission(
                    result,
                    this.organisationId
                )
            )
            .add(this.workTracker.createTracker());
    }

    linkBatterySet(i: number, field: 'ignored' | 'id') {
        const { id, ignored: ignoredValue } =
            this.unlistedBatterySetsForm.controls[i].value;
        const ignored = field === 'ignored' && ignoredValue;
        const command: LinkUnlistedEntityCommand = {
            ignored,
            identifier: this.unlistedBatterySets[i].id,
            id: ignored ? null : id
        };

        this.missionService
            .linkUnlistedBatterySet(command, this.missionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result =>
                this.missionCompletionService.setupMission(
                    result,
                    this.organisationId
                )
            )
            .add(this.workTracker.createTracker());
    }

    linkEquipment(i: number, field: 'ignored' | 'id') {
        const { id, ignored: ignoredValue } =
            this.unlistedEquipmentForm.controls[i].value;
        const ignored = field === 'ignored' && ignoredValue;
        const command: LinkUnlistedEntityCommand = {
            ignored,
            identifier: this.unlistedEquipment[i].id,
            id: ignored ? null : id
        };

        this.missionService
            .linkUnlistedEquipment(command, this.missionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result =>
                this.missionCompletionService.setupMission(
                    result,
                    this.organisationId
                )
            )
            .add(this.workTracker.createTracker());
    }

    addRpa(r: UnlistedRpaDto): void {
        const craft = this.form.controls.unlistedRpas;
        craft.push(
            createUnlistedResourceSelectionFormGroup(r.rpaId, r.ignored)
        );
        if (craft.length === this.unlistedRpas.length) {
            this.craftLoaded = true;
        }
    }

    addPerson(p: UnlistedPersonDto): void {
        const personnel = this.form.controls.unlistedPersonnel;
        personnel.push(
            createUnlistedResourceSelectionFormGroup(p.personId, p.ignored)
        );
        if (personnel.length === this.unlistedPersonnel.length) {
            this.personnelLoaded = true;
        }
    }

    addBatterySet(b: UnlistedBatterySetDto): void {
        const batterySets = this.form.controls.unlistedBatterySets;
        batterySets.push(
            createUnlistedResourceSelectionFormGroup(b.batterySetId, b.ignored)
        );
        if (batterySets.length === this.unlistedBatterySets.length) {
            this.batterySetsLoaded = true;
        }
    }

    addEquipment(e: UnlistedEquipmentDto): void {
        const equipment = this.form.controls.unlistedEquipment;

        equipment.push(
            createUnlistedResourceSelectionFormGroup(e.equipmentId, e.ignored)
        );
        if (equipment.length === this.unlistedEquipment.length) {
            this.equipmentLoaded = true;
        }
    }

    get unlistedBatterySetsForm() {
        return this.form.get('unlistedBatterySets') as FormArray<
            FormGroup<UnlistedResourceSelectionForm>
        >;
    }

    get unlistedRpaForm() {
        return this.form.get('unlistedRpas') as FormArray<
            FormGroup<UnlistedResourceSelectionForm>
        >;
    }

    get unlistedPersonnelForm() {
        return this.form.get('unlistedPersonnel') as FormArray<
            FormGroup<UnlistedResourceSelectionForm>
        >;
    }

    get unlistedEquipmentForm() {
        return this.form.get('unlistedEquipment') as FormArray<
            FormGroup<UnlistedResourceSelectionForm>
        >;
    }
}
