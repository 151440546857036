import { Directive, Input, Optional, SkipSelf } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { isPromise } from 'libs/flyfreely/src/lib/utils';
import {
    combineLatest,
    Observable,
    of,
    ReplaySubject,
    Subscription
} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

/**
 * A directive that manages the analytics tracking for a section of the screen. It sets
 * the label and category of the event to the value of `screenAnalytics`, and provides a
 * method to tracking specific events.
 * It also manages the enhanced help state for the child `<enhanced-help>` elements,
 * as controlled by the child `<enhanced-help-switch>`.
 */
@Directive({
    selector: '[screenAnalytics]'
})
export class ScreenAnalyticsDirective {
    @Input()
    screenAnalytics: string;

    /**
     * An optional context object that is added to the screen show event properties. This can be a promise,
     * in which case when it resolves the event will send.
     * This is applied first, so any built in properties will override those supplied here.
     */
    @Input()
    screenContext?:
        | { [key: string]: string | boolean }
        | PromiseLike<{ [key: string]: string | boolean }>;

    /**
     * Should we send hide and show events
     */
    @Input()
    screenAnalyticsHideShow = true;

    private helpActiveSource: ReplaySubject<boolean> = new ReplaySubject();
    helpActive$: Observable<boolean> = combineLatest([
        this.parent != null ? this.parent.helpActive$ : of(false),
        this.helpActiveSource
    ]).pipe(
        map(
            ([parentHelpActive, thisHelpActive]) =>
                parentHelpActive || thisHelpActive
        ),
        shareReplay()
    );

    helpActive: boolean;

    private helpActiveSubscription: Subscription;

    constructor(
        private angulartics: Angulartics2,
        @Optional() @SkipSelf() private parent: ScreenAnalyticsDirective
    ) {
        this.helpActiveSubscription = this.helpActive$.subscribe(
            helpActive => (this.helpActive = helpActive)
        );

        this.helpActiveSource.next(false);
    }

    ngOnInit() {
        if (this.screenAnalytics != null && this.screenAnalyticsHideShow) {
            if (this.screenContext != null && isPromise(this.screenContext)) {
                this.screenContext.then(additionalProperties =>
                    this.sendScreenShowEvent(additionalProperties)
                );
            } else {
                this.sendScreenShowEvent(
                    <{ [key: string]: string | boolean }>this.screenContext
                );
            }
        }
    }

    ngOnDestroy() {
        if (this.screenAnalytics != null && this.screenAnalyticsHideShow) {
            this.angulartics.eventTrack.next({
                action: 'hide',
                properties: {
                    category: 'screen',
                    label: this.screenAnalytics,
                    screen: this.screenAnalytics
                }
            });
        }
        this.helpActiveSource.complete();
        this.helpActiveSubscription.unsubscribe();
    }

    private sendScreenShowEvent(additionalProperties?: {
        [key: string]: string | boolean;
    }) {
        this.angulartics.eventTrack.next({
            action: 'show',
            properties: {
                ...additionalProperties,
                category: 'screen',
                label: this.screenAnalytics,
                screen: this.screenAnalytics
            }
        });
    }

    /**
     * Track an event using the screen analytics screen names.
     * @param category event category
     * @param action event action
     * @param additional additional properties, which can override built in ones
     */
    track(
        category: string,
        action: string,
        additional?: Record<string, string>,
        label?: string
    ) {
        if (this.screenAnalytics != null) {
            this.angulartics.eventTrack.next({
                action: action,
                properties: {
                    category,
                    label: label ?? this.screenAnalytics,
                    screen: this.screenAnalytics,
                    dimension1: this.screenAnalytics,
                    ...additional
                }
            });
        } else {
            console.warn('Trying to track without a screenAnalytics value');
        }
    }

    setHelpActive(value: boolean) {
        this.helpActiveSource.next(value);
    }

    get screen() {
        return this.screenAnalytics;
    }
}
