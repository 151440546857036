/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Used to describe a condition for displaying the control
 */
export interface DisplayCondition { 
    /**
     * The control ID that the condition is being checked from
     */
    subjectControlId?: number;
    /**
     * Comparison operation
     */
    comparator?: DisplayCondition.Comparator;
    /**
     * The string representation of the candidate values, comma separated
     */
    values?: Array<string>;
}
export namespace DisplayCondition {
    export type Comparator = 'EQUAL' | 'NOT_EQUAL' | 'GT' | 'GTE' | 'LT' | 'LTE' | 'BETWEEN' | 'NOT_BETWEEN' | 'IN' | 'NOT_IN';
    export const Comparator = {
        EQUAL: 'EQUAL' as Comparator,
        NOT_EQUAL: 'NOT_EQUAL' as Comparator,
        GT: 'GT' as Comparator,
        GTE: 'GTE' as Comparator,
        LT: 'LT' as Comparator,
        LTE: 'LTE' as Comparator,
        BETWEEN: 'BETWEEN' as Comparator,
        NOT_BETWEEN: 'NOT_BETWEEN' as Comparator,
        IN: 'IN' as Comparator,
        NOT_IN: 'NOT_IN' as Comparator
    };
}


