import {
    AfterContentInit,
    Directive,
    EventEmitter,
    Inject,
    Input,
    Output
} from '@angular/core';
import MapboxGeocoder, { Result, Results } from '@mapbox/mapbox-gl-geocoder';
import { MapService } from 'ngx-mapbox-gl';
import { Subject, take, takeUntil } from 'rxjs';
import { MAPBOX_GEOCODER_API_KEY } from '../map.module';

/**
 * This is a lightweight clone of the original ngx-mapbox-gl library's geocoder directive remove (here)[https://github.com/Wykks/ngx-mapbox-gl/commit/53d2f1c0f8fe28d4c0d2a20df7cd429219dc490f#diff-fbe486cc36e38fc3f10df71f2a2a7a6f80b4daf66dbe508bcb25d849d0b3c2f3L104].
 *
 * It needs to be wired in to the component's map service as it relies on the order of execution to get put in the correct location. A more versitile solution would be a reimplementation using the mapbox geocoder API directly.
 */
@Directive({
    selector: '[ffGeocoder]'
})
export class GeocoderDirective implements AfterContentInit {
    @Input()
    localGeocoder: (query: string) => Result[];
    @Input()
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' =
        'top-left';

    @Output() geocoderResults = new EventEmitter<Results>();
    @Output() geocoderResult = new EventEmitter<{ result: Result }>();
    @Output() geocoderError = new EventEmitter<any>();

    private geocoder?: MapboxGeocoder;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        @Inject(MAPBOX_GEOCODER_API_KEY)
        private readonly MAPBOX_GEOCODER_API_KEY: string,
        private mapService: MapService
    ) {}

    ngAfterContentInit(): void {
        this.mapService.mapCreated$
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(event => {
                this.geocoder = new MapboxGeocoder({
                    accessToken: this.MAPBOX_GEOCODER_API_KEY,
                    localGeocoder: this.localGeocoder,
                    // Explicitly disabling markers to get rid of a runtime error.
                    // (from https://github.com/mapbox/mapbox-gl-geocoder/issues/252#issuecomment-482593720)
                    marker: false
                    // Markers can be enabled, which will drop a pin at the search location
                    // To do this import mapboxgl from 'mapbox-gl' and uncomment the following line:
                    // mapboxgl: mapboxgl
                });
                this.geocoder.on('result', (results: Results) => {
                    this.geocoderResults.emit(results);
                });
                this.geocoder.on('error', (error: any) => {
                    this.geocoderError.emit(error);
                });
                this.mapService.addControl(this.geocoder, this.position);
            });
    }

    ngOnDestroy() {
        this.mapService.removeControl(this.geocoder);
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
