import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    StatusResponse,
    StatusService,
    uiVersion
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// From `custom-webpack.config.cjs` DefinePlugin
declare var release: { identifier?: string };

@Component({
    selector: 'version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit, OnDestroy {
    uiVersion = uiVersion;
    gitSha: string;
    status: StatusResponse;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(statusService: StatusService) {
        statusService
            .getStatus()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(status => {
                this.status = status;
            });
    }

    ngOnInit() {
        if (typeof release !== 'undefined' && release && release.identifier) {
            this.gitSha = release.identifier.slice(0, 5);
        } else {
            this.gitSha = 'dev';
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
