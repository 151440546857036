import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    BatteryService,
    BatterySetService,
    CraftService,
    CreateMaintenanceActivityCommand,
    CreateMaintenanceLogCommand,
    DO_NOTHING,
    EquipmentService,
    FEATURE_MAINTENANCE_NOTICES,
    FEATURE_MAINTENANCE_TASKS,
    FEATURE_SCHEDULED_MAINTENANCE,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LinkMissionAsFlightTestCommand,
    MaintenanceActivityDto,
    MaintenanceFlightTestDto,
    MaintenanceLogDto,
    MaintenanceService,
    MissionService,
    OrganisationService,
    PersonDto,
    PersonsOrganisationDto,
    Resource,
    UnlinkMissionAsFlightTestCommand,
    UpdateAssignedMaintenanceActivityCommand,
    UpdateSubmittedMaintenanceActivityCommand,
    UpdateUnassignedMaintenanceActivityCommand,
    UpdateUnsubmittedMaintenanceLogCommand,
    UserService,
    WorkTracker,
    fromLocalDate,
    hasAnyPermission,
    hasFeatureFlag,
    returnLast,
    toLocalDate,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, firstValueFrom } from 'rxjs';
import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { MaintenanceDialogues } from '../maintenance-dialogues.service';
import { MaintenanceFormService } from '../maintenance-form.service';
import {
    DisplayableMaintenanceActivity,
    DisplayableMaintenanceLogDto,
    MaintenanceLogChanges,
    MaintenanceRequestService
} from '../maintenance-request.service';
import { BulkCreateMaintenanceLogCommand } from 'libs/flyfreely/src/lib/model/api/bulkCreateMaintenanceLogCommand';
import { AirworthinessNoticesDialogueService } from '../airworthness-notice/airworthness-notices.service';

enum initialisation {
    START = 'START',
    LOADING = 'LOADING',
    INITIALISED = 'INITIALISED'
}

@Component({
    selector: 'maintenance-details-dialogue',
    templateUrl: './maintenance-details-dialogue.component.html',
    providers: [MaintenanceRequestService, MaintenanceFormService]
})
export class MaintenanceDetailsDialogue {
    @Input() organisationId: number;
    @Input() organisation: PersonsOrganisationDto;
    @Input() maintenanceLogId: number;
    createBulk: string = 'add';
    resource: Resource;

    maintenanceLog: DisplayableMaintenanceLogDto;
    maintenanceActivity: MaintenanceActivityDto;
    isEdit = false;
    isMaintenanceController: boolean;
    isMaintenancePerson: boolean;
    hasScheduledMaintenance: boolean;
    hasMaintenanceNotices: boolean;
    airworthinessNoticeId:number = 0;

    /**
     * Is this maintenance performed by an external organisation
     */
    isExternalMaintenance: boolean;
    activeActivityTab: number;
    currentUser: PersonDto;
    canUseMaintenanceTasks = false;
    maintenanceTaskFeature = [FEATURE_MAINTENANCE_TASKS];

    requiresFlightTest: boolean;
    noOutstandingFlightTest = true;

    isActive: { [key: string]: boolean };
    isNew: boolean;

    get maintenanceForm() {
        return this.maintenanceFormService.maintenanceForm;
    }

    get identifierWorkInstructionsGroup() {
        return this.maintenanceFormService.identifierWorkInstructionsGroup;
    }

    get maintenanceRequestGroup() {
        return this.maintenanceFormService.maintenanceRequestGroup;
    }
    get maintenanceBulkRequest() {
        return this.maintenanceFormService.maintenanceBulkRequest;
    }

    get maintenanceActivityGroup() {
        return this.maintenanceFormService.maintenanceActivityGroup;
    }

    get maintenanceDetailsGroup() {
        return this.maintenanceFormService.maintenanceDetailsGroup;
    }

    get flightTestGroup() {
        return this.maintenanceFormService.flightTestGroup;
    }

    get maintenanceControllerGroup() {
        return this.maintenanceFormService.maintenanceControllerGroup;
    }

    get finalisationGroup() {
        return this.maintenanceFormService.finalisationGroup;
    }

    overallStatus: string;
    modalStatus: 'ERROR' | 'LOADING' | 'NO_ACCESS' | 'NOT_FOUND' | 'LOADED' =
        'LOADING';

    workTracker: WorkTracker;
    working = false;

    initialisation = initialisation;
    isInitialised: initialisation = initialisation.START;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private organisationService: OrganisationService,
        private commonDialoguesService: CommonDialoguesService,
        private maintenanceService: MaintenanceService,
        private craftService: CraftService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private maintenanceRequestService: MaintenanceRequestService,
        private equipmentService: EquipmentService,
        private maintenanceFormService: MaintenanceFormService,
        private missionService: MissionService,
        private maintenanceDialoguesService: MaintenanceDialogues,
        private userService: UserService,
        private logging: FlyFreelyLoggingService,
        private modal: BsModalRef<MaintenanceDetailsDialogue>,
        modalOptions: ModalOptions,
        private route: ActivatedRoute,
        private router: Router,
        private airworthinessNoticeService: AirworthinessNoticesDialogueService
    ) {
        this.workTracker = maintenanceRequestService.workTracker;
        this.currentUser = userService.getCurrentUser();
        // this.route.params.subscribe((params: Params) => {
        //     this.createBulk = params['bulk'] ;
        //     console.log('this is oram', this.createBulk);
            
          
        //  });
        this.createBulk = this.router.url.includes('bulk')? 'bulk' : 'add';
        console.log('this is from url', this.createBulk);

        modalOptions.closeInterceptor = () => {
            if (this.maintenanceForm.dirty && this.maintenanceForm.touched) {
                return commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.maintenanceFormService.organisationId = this.organisationId;
        this.maintenanceRequestService.setup(this.organisationId);
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                if (
                    working === true &&
                    this.isInitialised === initialisation.START
                ) {
                    this.isInitialised = initialisation.LOADING;
                }
                if (
                    working === false &&
                    this.isInitialised === initialisation.LOADING
                ) {
                    this.isInitialised = initialisation.INITIALISED;
                }
            });

        this.activeTab('request');

        this.refreshOrganisation();

        this.maintenanceRequestService.maintenanceLog$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(update => {
                if (
                    this.maintenanceLog != null &&
                    this.maintenanceLogId == null
                ) {
                    this.maintenanceLogId = update.log.id;
                }
                this.loadMaintenanceLog(update.log, update.changes);
            });

        this.flightTestGroup.controls.flightTestRequired.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                this.requiresFlightTest = value;
                this.findOutstandingFlights(value);
            });

        this.missionService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                if (this.maintenanceLogId != null) {
                    this.maintenanceRequestService.refreshMaintenanceLog(
                        this.maintenanceLogId
                    );
                }
            });

        this.maintenanceRequestService.routingError$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(error => {
                if (error !== 'NONE') {
                    this.modalStatus = error;
                }
            });
        
        this.airworthinessNoticeService.airworthinessNoticeId$.subscribe(id => {
            this.airworthinessNoticeId = id;
            console.log('Received Airworthiness Notice ID:', this.airworthinessNoticeId);
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshOrganisation() {
        this.modalStatus = 'LOADING';
        if (this.organisation != null) {
            this.setupMaintenance();
        } else {
            this.organisationService
                .findByIdForUser(this.organisationId, this.organisationId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: organisation => {
                        this.organisation = organisation;
                        this.setupMaintenance();
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error refreshing organisation details: ${error.message}`
                        );
                        this.modalStatus = 'ERROR';
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }

    setupMaintenance() {
        this.maintenanceFormService.timeZone = this.organisation.timeZone;

        if (this.maintenanceLogId) {
            this.maintenanceRequestService.refreshMaintenanceLog(
                this.maintenanceLogId
            );
            this.isNew = false;
        } else {
            this.maintenanceLog = {
                id: null,
                organisationId: this.organisationId ?? this.organisation?.id,
                status: MaintenanceLogDto.Status.DRAFT,
                requester: this.currentUser,
                activities: [],
                availableActions: {
                    canCancel: false,
                    canCreateActivity: false,
                    canSubmit: true,
                    canFinalise: false,
                    canUnfinalise: false
                }
            };
            this.isNew = true;
            this.findStatus();
            this.maintenanceActivity = null;
        }
        this.refreshPermissions();
    }

    findResources() {
        if (
            this.maintenanceLog.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.CRAFT
        ) {
            this.findRpa();
        } else if (
            this.maintenanceLog.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.BATTERY
        ) {
            this.findBattery();
        } else if (
            this.maintenanceLog.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.BATTERY_SET
        ) {
            this.findBatterySet();
        } else if (
            this.maintenanceLog.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.EQUIPMENT
        ) {
            this.findEquipment();
        }
    }

    findRpa() {
        this.craftService
            .findCrafts(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: rpas => {
                    this.resource = rpas.find(
                        rpa => rpa.id === this.maintenanceLog.resourceId
                    );
                    this.maintenanceRequestService.updateResource(
                        this.resource,
                        MaintenanceLogDto.ResourceCategory.CRAFT,
                        this.organisation.id
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading RPAs: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    findBattery() {
        this.batteryService
            .findBatteries(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteries => {
                    this.resource = batteries.find(
                        b => b.id === this.maintenanceLog.resourceId
                    );
                    this.maintenanceRequestService.updateResource(
                        this.resource,
                        MaintenanceLogDto.ResourceCategory.BATTERY,
                        this.organisation.id
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading batteries: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    findEquipment() {
        this.equipmentService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: equipment => {
                    this.resource = equipment.find(
                        item => item.id === this.maintenanceLog.resourceId
                    );
                    this.maintenanceRequestService.updateResource(
                        this.resource,
                        MaintenanceLogDto.ResourceCategory.EQUIPMENT,
                        this.organisation.id
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading batteries: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    findBatterySet() {
        this.batterySetService
            .findBatterySets(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteries => {
                    this.resource = batteries.find(
                        set => set.id === this.maintenanceLog.resourceId
                    );
                    this.maintenanceRequestService.updateResource(
                        this.resource,
                        MaintenanceLogDto.ResourceCategory.BATTERY_SET,
                        this.organisation.id
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading batteries: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    private loadMaintenanceLog(
        maintenanceLog: DisplayableMaintenanceLogDto,
        changes: MaintenanceLogChanges
    ) {
        if (!maintenanceLog) {
            this.modalStatus = 'ERROR';
            return;
        }

        this.maintenanceFormService.setupMaintenanceLog(maintenanceLog);

        this.maintenanceLog = maintenanceLog;

        if (changes === 'INITIAL' || changes === 'SAVE') {
            this.setMaintenanceActivity(
                returnLast(this.maintenanceLog.activities)
            );
        }

        this.refreshPermissions();

        this.findStatus();
        this.findResources();
    }

    refreshPermissions() {
        this.isMaintenanceController = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.MAINTENANCE_MANAGE
        );

        this.canUseMaintenanceTasks = hasFeatureFlag(
            this.organisation,
            FEATURE_MAINTENANCE_TASKS
        );

        this.isMaintenancePerson =
            this.maintenanceActivity &&
            this.maintenanceActivity.maintainer?.id === this.currentUser.id;

        this.isExternalMaintenance =
            this.maintenanceActivity?.maintenanceOrganisation != null;

        this.hasScheduledMaintenance = hasFeatureFlag(
            this.organisation,
            FEATURE_SCHEDULED_MAINTENANCE
        );

        this.hasMaintenanceNotices = hasFeatureFlag(
            this.organisation,
            FEATURE_MAINTENANCE_NOTICES
        );
    }

    findStatus() {

        if (!this.maintenanceLog) {
            return;
        }
        if (!this.maintenanceLog) {
            this.modalStatus = 'ERROR';
            return;
        } else if (this.maintenanceLog.status === 'DRAFT') {
            this.overallStatus = 'DRAFT_REQUEST';
        } else if (this.maintenanceLog.status === 'REQUESTED') {
            if (this.maintenanceLog.activities.length === 0) {
                this.overallStatus = 'DRAFT_ACTIVITY';
                this.modalStatus = 'LOADED';
                return;
            }
            const mostRecentActivity =
                this.maintenanceLog.activities[
                    this.maintenanceLog.activities.length - 1
                ];
            if (
                mostRecentActivity.status ===
                MaintenanceActivityDto.Status.DRAFT
            ) {
                this.overallStatus = 'DRAFT_ACTIVITY';
            } else if (
                mostRecentActivity.status ===
                MaintenanceActivityDto.Status.ASSIGNED
            ) {
                this.overallStatus = 'ASSIGNED_ACTIVITY';
            } else if (
                mostRecentActivity.status ===
                MaintenanceActivityDto.Status.SUBMITTED
            ) {
                this.overallStatus = 'SUBMITTED_ACTIVITY';
            } else if (
                mostRecentActivity.status ===
                MaintenanceActivityDto.Status.COMPLETED
            ) {
                this.overallStatus = 'COMPLETED_ACTIVITY';
            }
        } else {
            this.overallStatus = 'COMPLETED';
        }
        if (this.createBulk == 'bulk') {
            this.overallStatus = 'BULK_REQUEST';
     
        } 
        this.modalStatus = 'LOADED';
    }

    private updateRequest() {
        const f = this.maintenanceRequestGroup.value;

        if (this.isNew) {
            const toSave: CreateMaintenanceLogCommand = {
                maintenanceLogType: f.maintenanceType,
                resourceId: f.resourceId,
                resourceCategory: f.resourceCategory,
                organisationId: this.organisation.id,
                reasonForRequest: f.reasonForRequest || '',
                safeToUse: f.safeToUse
            };
            return this.maintenanceService.createMaintenanceLog(toSave);
        } else {
            const toSave: UpdateUnsubmittedMaintenanceLogCommand = {
                maintenanceLogType: f.maintenanceType,
                resourceId: f.resourceId,
                resourceCategory: f.resourceCategory,
                reasonForRequest: f.reasonForRequest,
                safeToUse: f.safeToUse
            };

            return this.maintenanceService.updateMaintenanceLog(
                this.maintenanceLog.id,
                toSave
            );
        }
    }

    draftSaveTooltip() {
        const controls = this.maintenanceRequestGroup.controls;
        if (controls.maintenanceType.invalid || controls.resourceId.invalid) {
            return 'Please select a maintenance type and resource first.';
        } else {
            return;
        }
    }

    saveDraftRequest() {
        this.updateRequest()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    if (this.isNew) {
                        this.maintenanceRequestService.updateMaintenanceLog(
                            result
                        );
                        this.isNew = false;
                    }
                    this.logging.success(`Successfully saved draft request`);
                    this.changeUrlWithoutReloading(result.id);
                    this.maintenanceForm.markAsPristine();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while saving draft: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }
    saveDraftBulkRequest() {
        this.updateRequest()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    if (this.isNew) {
                        this.maintenanceRequestService.updateMaintenanceLog(
                            result
                        );
                        this.isNew = false;
                    }
                    this.logging.success(`Successfully saved draft request`);
                    this.changeUrlWithoutReloading(result.id);
                    this.maintenanceForm.markAsPristine();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while saving draft: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    updateSubmittedMaintenanceActivity() {
        const controllerNotes =
            this.maintenanceControllerGroup.value.maintenanceControllerNotes;
        const flightRequired = this.flightTestGroup.value.flightTestRequired;

        const toSave: UpdateSubmittedMaintenanceActivityCommand = {
            // flightTestRequired: f.flightTestRequired,
            maintenanceControllerNotes: controllerNotes,
            flightTestRequired: flightRequired
        };

        return this.maintenanceService.updateSubmittedMaintenanceActivity(
            this.maintenanceLog.id,
            this.maintenanceActivity.id,
            toSave
        );
    }

    saveDraftSubmittedActivity() {
        this.updateSubmittedMaintenanceActivity()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Successfully saved draft of maintenance controller details`
                    );
                    this.maintenanceForm.markAsPristine();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while saving draft: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    finaliseMaintenance() {
        const { outcome, scheduledMaintenanceCompleted } =
            this.finalisationGroup.value;
        this.updateSubmittedMaintenanceActivity()
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(result =>
                    this.maintenanceRequestService.finaliseMaintenanceLog({
                        outcome,
                        scheduledMaintenanceCompleted
                    })
                )
            )
            .subscribe({
                next: result => {
                    this.logging.success(
                        result.outcome ===
                            MaintenanceLogDto.Outcome.RELEASE_TO_SERVICE
                            ? `Released to Service`
                            : `Retired from Service`
                    );
                    this.maintenanceForm.markAsPristine();
                    this.cancel();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while completing request: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    finaliseMaintenanceLog() {
        const { outcome, scheduledMaintenanceCompleted } =
            this.finalisationGroup.value;
        this.maintenanceRequestService
            .finaliseMaintenanceLog({
                outcome,
                scheduledMaintenanceCompleted
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.logging.success(
                    result.outcome ===
                        MaintenanceLogDto.Outcome.RELEASE_TO_SERVICE
                        ? `Released to Service`
                        : `Retired from Service`
                );
                this.maintenanceForm.markAsPristine();
                this.cancel();
            });
    }

    unfinaliseMaintenanceLog() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Unfinalise Maintenance',
                `Are you sure you wish to unfinalise this maintenance?`,
                'Yes',
                () =>
                    firstValueFrom(
                        this.maintenanceRequestService
                            .unfinaliseMaintenanceLog()
                            .pipe(takeUntil(this.ngUnsubscribe$))
                    )
            )
            .then(() => {
                this.maintenanceForm.markAsPristine();
                this.logging.success('Maintenance log unfinalised');
                return;
            }, DO_NOTHING);
    }

    unfinaliseMaintenanceActivity() {
        this.maintenanceRequestService
            .unfinaliseMaintenanceActivity(this.maintenanceActivity.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.maintenanceForm.markAsPristine();
                this.logging.success('Maintenance activity unfinalised');
                return;
            });
    }

    requestFurtherMaintenance() {
        this.updateSubmittedMaintenanceActivity()
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(result =>
                    this.maintenanceService.completeSubmittedMaintenanceActivity(
                        this.maintenanceLogId,
                        this.maintenanceActivity.id
                    )
                ),
                mergeMap(result =>
                    this.maintenanceService.createMaintenanceActivity(
                        this.maintenanceLogId,
                        {}
                    )
                )
            )
            .subscribe({
                next: saved => {
                    this.maintenanceRequestService.refreshMaintenanceLog(
                        this.maintenanceLogId
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while trying to request further maintenance: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    requestMaintenance() {
        const doneWorking = this.workTracker.createTracker();

        this.updateRequest()
            .pipe(
                switchMap(result =>
                    this.maintenanceService.submitMaintenanceLog(result.id)
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: result => {
                    this.maintenanceLog = {
                        ...result,
                        activities: [],
                        requester: this.currentUser
                    };

                    this.logging.success(`Succesfully requested maintenance`);
                    this.maintenanceForm.markAsPristine();
                    this.maintenanceRequestService.updateMaintenanceLog(result);
                    this.isNew = false;
                    this.changeUrlWithoutReloading(result.id);
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while requesting maintenance: ${error.message}`
                    );
                    doneWorking();
                }
            });
    }

    private saveAssignedBulkMaintenanceLog() {
        const f = this.maintenanceFormService.maintenanceBulkRequest;
        const toSave: BulkCreateMaintenanceLogCommand = {
            organisationId: this.organisationId,
            resourceCategory: f.value.resourceCategory,
            resourceIdList: f.value.resourceIdList,
            reasonForRequest: f.value.reasonForRequest,
            safeToUse: f.value.safeToUse,
            maintenanceLogType: f.value.maintenanceType,
            airworthinessNoticeId: this.airworthinessNoticeId

        };
        return this.maintenanceService.createBulkMaintenanceRequest(
            toSave
        );
    }

    requestBulkMaintenance() {
        const doneWorking = this.workTracker.createTracker();
        this.saveAssignedBulkMaintenanceLog()
            .subscribe({
                next: result => {
                    
                    this.logging.success(`Succesfully requested maintenance`);
                    this.maintenanceForm.markAsPristine();
                    this.isNew = false;
                    this.maintenanceFormService.maintenanceBulkRequest.reset();
                    this.airworthinessNoticeService.setSelectedCardId(this.airworthinessNoticeId);
                    this.airworthinessNoticeService.updateLinkedMaintenanceLogs();
                    // this.changeUrlWithoutReloading(result.id);
                    doneWorking();
                    this.cancel();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while requesting maintenance: ${error.message}`
                    );
                    doneWorking();
                }
            });





           
            
           
    }

    private saveUnassignedMaintenanceActivity() {
        const f = this.maintenanceActivityGroup.value;

        if (!this.maintenanceActivity.id) {
            // FIXME: this needs to be able to create on add once fixed in the backend
            const toSave: CreateMaintenanceActivityCommand = {
                maintainerId: f.maintainerId,
                maintenanceOrganisation: f.maintenanceOrganisation,
                requiredMaintenanceDescription: f.description,
                scheduledDate: toLocalDate(f.scheduledDate),
                tasks: f.scheduledTasks
                    .filter(t => t.selected)
                    .map(t => ({
                        componentId: t.componentId,
                        taskTypeId: t.taskTypeId,
                        notes: '',
                        required: true
                    }))
            };
            return this.maintenanceService.createMaintenanceActivity(
                this.maintenanceLog.id,
                toSave
            );
        } else {
            const toSave: UpdateUnassignedMaintenanceActivityCommand = {
                maintainerId: f.maintainerId,
                maintenanceOrganisation: f.maintenanceOrganisation,
                requiredMaintenanceDescription: f.description,
                scheduledDate: toLocalDate(f.scheduledDate),
                tasks: f.scheduledTasks
                    .filter(t => t.selected)
                    .map(t => ({
                        componentId: t.componentId,
                        taskTypeId: t.taskTypeId,
                        notes: '',
                        required: true
                    }))
            };
            return this.maintenanceService.updateUnassignedMaintenanceActivity(
                this.maintenanceLog.id,
                this.maintenanceActivity.id,
                toSave
            );
        }
    }

    

    private saveAssignedMaintenanceActivity() {
        const f = this.maintenanceDetailsGroup.value;
        const toSave: UpdateAssignedMaintenanceActivityCommand = {
            endTime: toTimestamp(f.endTime, this.organisation.timeZone),
            tasks: f.tasks.map(t => ({ ...t, notes: t.notes || '' })) ?? [],
            maintenancePerformed: f.maintenancePerformed,
            maintenanceRemarks: f.maintenanceRemarks,
            startDate: toLocalDate(f.startDate)
        };
        return this.maintenanceService.updateAssignedMaintenanceActivity(
            this.maintenanceLog.id,
            this.maintenanceActivity.id,
            toSave
        );
    }

    saveDraftActivity() {
        const doneWorking = this.workTracker.createTracker();

        this.saveUnassignedMaintenanceActivity()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.logging.success(`Successfully saved draft activity`);
                    this.maintenanceForm.markAsPristine();
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while saving Draft: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    saveDraftMaintenance() {
        const doneWorking = this.workTracker.createTracker();
        this.saveAssignedMaintenanceActivity()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Succesfully saved draft maintenance details`
                    );
                    this.maintenanceDetailsGroup.markAsPristine();
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while saving Draft: ${error.message}`
                    );
                    doneWorking();
                }
            });
    }

    submitMaintenance() {
        const doneWorking = this.workTracker.createTracker();
        this.saveAssignedMaintenanceActivity()
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(result =>
                    this.maintenanceService.submitMaintenanceActivity(
                        this.maintenanceLog.id,
                        result.id
                    )
                )
            )
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Succesfully submitted maintenance details`
                    );
                    this.maintenanceForm.markAsPristine();
                    this.maintenanceRequestService.refreshMaintenanceLog(
                        this.maintenanceLogId
                    );
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while submitting maintenance details: ${error.message}`
                    );
                    doneWorking();
                }
            });
    }

    assignActivity() {
        const doneWorking = this.workTracker.createTracker();
        this.saveUnassignedMaintenanceActivity()
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(result =>
                    this.maintenanceService.assignMaintenanceActivity(
                        this.maintenanceLog.id,
                        result.id
                    )
                )
            )
            .subscribe({
                next: result => {
                    this.logging.success(`Succesfully assigned activity`);
                    this.maintenanceForm.markAsPristine();
                    this.maintenanceRequestService.refreshMaintenanceLog(
                        this.maintenanceLogId
                    );
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while assigning activity: ${error.message}`
                    );
                    doneWorking();
                }
            });
    }

    cancelMaintenance() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel Maintenance',
                `Are you sure you wish to cancel this maintenance?`,
                'Yes',
                () =>
                    this.maintenanceService
                        .cancelMaintenanceLog(this.maintenanceLogId)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .toPromise()
            )
            .then(() => {
                this.maintenanceForm.markAsPristine();
                this.logging.success('Maintenance cancelled');
                this.cancel();
            }, DO_NOTHING);
    }

    private setMaintenanceActivity(
        maintenanceActivity: DisplayableMaintenanceActivity
    ) {
        this.maintenanceActivity = maintenanceActivity;
        // @ts-ignore - bad type sensing
        this.maintenanceActivity.scheduledDate = fromLocalDate(
            this.maintenanceActivity.scheduledDate
        );
        // @ts-ignore - bad type sensing
        this.maintenanceActivity.startDate = fromLocalDate(
            this.maintenanceActivity.startDate
        );
        // @ts-ignore - bad type sensing
        this.maintenanceActivity.endDate = fromLocalDate(
            this.maintenanceActivity.endDate
        );

        this.maintenanceFormService.setupMaintenanceActivity(
            this.maintenanceActivity
        );

        this.activeActivityTab = maintenanceActivity.number;
        // this.refreshStatusShortcuts();
    }

    findOutstandingFlights(requiresTest: boolean) {
        if (requiresTest === false) {
            this.noOutstandingFlightTest = true;
        } else if (requiresTest === true) {
            const flights = this.maintenanceActivity.flightTests;
            this.noOutstandingFlightTest =
                flights == null ||
                flights.length === 0 ||
                flights.findIndex(
                    t =>
                        t.flightTestMissionStatus !==
                            MaintenanceFlightTestDto.FlightTestMissionStatus
                                .FINALISED &&
                        t.flightTestMissionStatus !==
                            MaintenanceFlightTestDto.FlightTestMissionStatus
                                .COMPLETED &&
                        t.flightTestMissionStatus !==
                            MaintenanceFlightTestDto.FlightTestMissionStatus
                                .CANCELLED
                ) === -1;
        }
    }

    showCreateTestFlight() {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Create a test mission',
                'Create',
                true,
                true,
                [
                    {
                        key: 'date',
                        type: 'date',
                        props: {
                            label: 'Mission Date',
                            required: true
                        }
                    },
                    {
                        key: 'objective',
                        type: 'textarea',
                        props: {
                            label: 'Mission Objective',
                            required: true
                        }
                    }
                ],
                { date: null as Date, objective: null as string },
                data =>
                    new Promise<void>(resolve => {
                        this.createTestFlight(data);
                        resolve();
                    })
            )
            .catch(DO_NOTHING);
    }

    showLinkTestFlight() {
        const modal =
            this.maintenanceDialoguesService.showLinkTestFlightDialogue(
                this.organisationId,
                this.maintenanceActivity,
                this.maintenanceLog.resourceCategory ===
                    MaintenanceLogDto.ResourceCategory.CRAFT
                    ? this.resource.id
                    : null
            );

        modal.content.linkedMissionId
            .pipe(takeUntil(modal.onHidden), takeUntil(this.ngUnsubscribe$))
            .subscribe(id => this.linkTestFlight(id));
    }

    onUnlinkTestFlight(missionId: number) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Unlink Test Flight',
                `Are you sure you wish to unlink this test flight from this maintenance activity?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.unlinkTestFlight(missionId), DO_NOTHING);
    }

    createTestFlight(data: { objective: string; date: Date }) {
        const command = {
            date: toLocalDate(data.date),
            objective: data.objective
        };
        this.maintenanceService
            .createFlightTest(
                this.maintenanceLogId,
                this.maintenanceActivity.id,
                command
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success('Test flight created');
                    this.saveDraftSubmittedActivity();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error creating test flight: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    linkTestFlight(missionId: number) {
        const command: LinkMissionAsFlightTestCommand = {
            missionId: missionId
        };
        this.maintenanceService
            .linkFlightTest(
                this.maintenanceLogId,
                this.maintenanceActivity.id,
                command
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success('Test flight linked');
                    this.saveDraftSubmittedActivity();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error linking test flight: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    unlinkTestFlight(missionId: number) {
        const command: UnlinkMissionAsFlightTestCommand = {
            missionId: missionId
        };
        this.maintenanceService
            .unlinkFlightTest(
                this.maintenanceLogId,
                this.maintenanceActivity.id,
                command
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success('Test flight unlinked');
                    this.saveDraftSubmittedActivity();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error unlinking test flight: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    scrollTo(section: string) {
        this.activeTab(section);
        document
            .querySelector(`#${section}`)
            .scrollIntoView({ behavior: 'smooth' });
    }

    onSectionChange(sectionId: string) {
        this.activeTab(sectionId);
    }

    activeTab(section: string) {
        this.isActive = {};
        this.isActive[section] = true;
    }

    cancel() {
        this.modal.hide();
    }

    changeUrlWithoutReloading(maintenanceLogId: number) {
        const url = window.location.href;

        const newUrl = url.replace(
            'add?organisation',
            `${maintenanceLogId}?organisation`
        );
        window.history.pushState({}, '', newUrl);
    }
}
