import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlyFreelyConstants } from '../constants';
import { httpParamSerializer } from './service.helpers';

// TODO: replace this with the proper DTO when available

export enum AirspaceOperatorTypes {
    AU_RECREATIONAL = 'AU_RECREATIONAL',
    AU_COMMERCIAL_EXCLUDED = 'AU_COMMERCIAL_EXCLUDED',
    AU_REOC = 'AU_REOC'
}

export const AirspaceOperatorTypesParser = {
    RECREATIONAL: AirspaceOperatorTypes.AU_RECREATIONAL,
    EXCLUDED: AirspaceOperatorTypes.AU_COMMERCIAL_EXCLUDED,
    INCLUDED: AirspaceOperatorTypes.AU_REOC
};
export interface AirspaceNotification {
    body: string;
    jurisdictionCode: string;
    startTime: string;
    endTime: string;
    title: string;
    operatorTypes: AirspaceOperatorTypes[];
    url: string;
}

export interface AirspaceNotificationSearch {
    searchTime: string;
    jurisdictionCode: string;
    notifications: AirspaceNotification[];
}

export const airspaceOperatorTypesLookup = {
    [AirspaceOperatorTypes.AU_RECREATIONAL]: 'For Fun',
    [AirspaceOperatorTypes.AU_COMMERCIAL_EXCLUDED]: 'Ex-Cat',
    [AirspaceOperatorTypes.AU_REOC]: 'ReOC'
};

@Injectable({
    providedIn: 'root'
})
export class AirspaceNotificationService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    getNotifications(jurisdictionCode: string, time: string) {
        return this.http.get<AirspaceNotificationSearch>(
            `${this.baseUrl}/webapi/geospatial/airspace/notifications`,
            {
                params: httpParamSerializer({
                    jurisdictionCode,
                    time
                })
            }
        );
    }
}
