import { NgControl } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

/**
 * This directive performs a similar role to [disabled] on normal form controls,
 * except it works with ReactiveForms.
 */
@Directive({
    selector: '[disableControl]'
})
export class DisableControlDirective {
    @Input() disableControl: boolean;

    constructor(private ngControl: NgControl) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.disableControl) {
            this.ngControl.control.disable();
        } else {
            this.ngControl.control.enable();
        }
    }
}
