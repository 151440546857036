<ng-container *ngIf="(conformanceResults | async) != null">
    <ng-container
        *ngFor="let result of conformanceResults | async; let last = last"
    >
        <h5>Flight #{{ result.flightNumber }}</h5>
        <div class="container-conformance">
            <div class="horizontal-container pad-children align-children">
                <span class="display-label">Pilot</span
                ><span class="no-wrap"> {{ result.pilotName }}</span>
                <span class="display-label">Status</span>
                <div class="fill"></div>
                <div [ngSwitch]="result.status">
                    <span
                        *ngSwitchCase="'NO_BREACHES'"
                        class="status-block active"
                        >No Breaches</span
                    >
                    <span
                        *ngSwitchCase="'NO_POLICY'"
                        class="status-block disabled"
                        >No Policy</span
                    >
                    <ng-container *ngSwitchCase="'PRELIMINARY'">
                        <span class="status-block pending">Preliminary</span>
                        <em *ngIf="result.flightId == null"
                            >The flight log must be assigned to a flight</em
                        >
                        <em
                            *ngIf="
                                result.flightId != null &&
                                result.pilotId == null
                            "
                            >A pilot must be assigned to the flight</em
                        >
                        <em
                            *ngIf="
                                result.flightId != null &&
                                result.pilotId != null
                            "
                            >This assignment will complete within 1 hour, and a
                            notification will be sent the the pilot then</em
                        >
                    </ng-container>
                    <span *ngSwitchDefault class="status-block pending">{{
                        result.parsedStatus
                    }}</span>
                </div>
            </div>
            <div class="pad-children">
                <button
                    *ngIf="
                        mission.status === 'COMPLETED' &&
                        mission.availableActions.hasFinalise &&
                        (result.status === 'SENT' ||
                            result.status === 'COMPLETED')
                    "
                    class="btn-sm btn-primary"
                    tooltip="Mark this conformance result as final, preventing further updates"
                    (click)="finalise(result)"
                >
                    Finalise
                </button>
                <button
                    *ngIf="
                        mission.status === 'COMPLETED' &&
                        mission.availableActions.hasFinalise &&
                        (result.status === 'PRELIMINARY' ||
                            result.status === 'SENT' ||
                            result.status === 'COMPLETED')
                    "
                    class="btn-sm btn-danger"
                    tooltip="Cancel this conformance result, permanently removing the record"
                    (click)="cancel(result)"
                >
                    Cancel
                </button>
                <button
                    *ngIf="
                        mission.status === 'COMPLETED' &&
                        mission.availableActions.hasFinalise &&
                        result.status === 'FINALISED'
                    "
                    class="btn-sm btn-default"
                    (click)="unfinalise(result)"
                >
                    Unfinalise
                </button>
            </div>
        </div>

        <ng-container
            *ngIf="
                result.status != 'NO_BREACHES' && result.status != 'NO_POLICY'
            "
        >
            <div class="fluid-container">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 15%">Rule Type</th>
                            <th style="width: 30%">Description</th>
                            <th style="width: 10%">Maximum</th>
                            <th style="width: 10%">Actual</th>
                            <th style="width: 35%">Acknowledgement</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rule of result.ruleResultList">
                            <td>
                                {{ rule.type | formatFlightConformanceType }}
                            </td>
                            <td class="wordwrap">
                                {{ rule.description }}
                            </td>
                            <td>
                                {{ rule.comparisonValue | number: '1.0-0' }}
                            </td>
                            <td>
                                {{ rule.actualValue | number: '1.0-0' }}
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="result.status === 'PRELIMINARY'"
                                >
                                    Preliminary Result
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        result.status !== 'PRELIMINARY' &&
                                        rule.status === ruleStatus.ACKNOWLEDGED
                                    "
                                >
                                    {{ rule.acknowledgementMessage }}
                                </ng-container>
                                <ng-container
                                    *ngIf="rule.status === ruleStatus.NO_DATA"
                                >
                                    No Data
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        result.status !== 'FINALISED' &&
                                        rule.status ===
                                            ruleStatus.UNACKNOWLEDGED &&
                                        result.pilotId != null &&
                                        currentUserId === result.pilotId &&
                                        mission.status !== 'FINALISED'
                                    "
                                >
                                    <button
                                        type="button"
                                        class="btn btn-xs btn-pill btn-primary"
                                        (click)="acknowledgeBreach(rule)"
                                        [disabled]="working"
                                        analyticsOn
                                        analyticsAction="acknowledge-conformance-rule-result"
                                    >
                                        Acknowledge
                                    </button>
                                </ng-container>

                                <ng-container
                                    *ngIf="
                                        result.status !== 'PRELIMINARY' &&
                                        rule.status ==
                                            ruleStatus.UNACKNOWLEDGED &&
                                        (result.pilotId == null ||
                                            currentUserId != result.pilotId ||
                                            mission.status === 'FINALISED')
                                    "
                                >
                                    Unacknowledged
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
