import { Component, Input } from '@angular/core';
import {
    CompleteSortieCommand,
    DisplayableMissionDto,
    FlightLogsService,
    FlightSummary,
    FlyFreelyError,
    FlyFreelyLoggingService,
    SortieDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { flightSummaryToFeatures } from 'libs/flight-logs/src/lib/helpers';
import { getFeatureGroups } from 'libs/locations/src/lib/helpers';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject, concat } from 'rxjs';
import { map, takeUntil, toArray } from 'rxjs/operators';
import { MissionCompletionService } from '../../mission-completion/mission-completion.service';
import {
    FlightLogFileWithRpa,
    MissionFlightLogsDirective
} from '../../mission-completion/mission-flight-logs.directive';
import { MissionRecordService } from '../../mission-record-edit/mission-record.service';

interface FeatureGroupings {
    groupName: string;
    groups: {
        groupId: number;
        groupName: string;
        features: SelectableFeatures[];
        selected: boolean;
    }[];
}

interface SelectableFeatures {
    featureId: string | number;
    featureName: string;
}

interface FlightSummaryWithFlightId {
    flightSummary: FlightSummary;
    flightId: number;
}

function getFlightTakeoffLandingPointFeatureGroups(
    flight: CompleteSortieCommand | SortieDto,
    startIndex: number
): FeatureGroup[] {
    return [
        {
            id: startIndex,
            canAdd: false,
            type: 'Point',
            name: 'Takeoff Point',
            existingFeatures: flight.manualTakeoffPoint
                ? [
                      {
                          id: `takeoff${
                              startIndex != null
                                  ? `_${startIndex.toString()}`
                                  : ''
                          }`,
                          name: `Takeoff Point ${flight.number}`,
                          geom: flight.manualTakeoffPoint
                      }
                  ]
                : [],
            styles: {
                symbol: [
                    {
                        layout: {
                            'icon-image': 'flight-takeoff'
                        }
                    }
                ]
            }
        },
        {
            id: startIndex + 1,
            canAdd: false,
            type: 'Point',
            name: 'Landing Point',
            existingFeatures: flight.manualLandingPoint
                ? [
                      {
                          id: `landing${
                              startIndex != null
                                  ? `_${startIndex.toString()}`
                                  : ''
                          }`,
                          name: `Landing Point ${flight.number}`,
                          geom: flight.manualLandingPoint
                      }
                  ]
                : [],
            styles: {
                symbol: [
                    {
                        layout: {
                            'icon-image': 'flight-landing'
                        }
                    }
                ]
            }
        }
    ];
}

@Component({
    selector: 'mission-overview-map',
    templateUrl: './mission-overview-map.component.html'
})
export class MissionOverviewMapDialogue {
    @Input() mission: DisplayableMissionDto;
    @Input() historicalMission: boolean;

    features$ = new BehaviorSubject<FeatureGroup[]>([]);
    renderedFeatures: FeatureGroup[];

    groupedFeatures: FeatureGroupings[] = [];

    flightLogFiles: FlightLogFileWithRpa[];
    allFlights: CompleteSortieCommand[] = [];

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private modal: BsModalRef,
        private missionCompletionService: MissionCompletionService,
        private missionRecordService: MissionRecordService,
        private flightLogsService: FlightLogsService,
        private flightLogs: MissionFlightLogsDirective,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        if (this.historicalMission) {
            this.allFlights =
                this.missionRecordService.form.controls.sorties.value ?? [];
        } else {
            this.allFlights =
                this.missionCompletionService.form.controls.flights.value ?? [];
        }

        this.features$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(features => this.updateRenderedFeatures());

        this.findFlightLogs();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findFlightLogs() {
        this.flightLogs.flightLogs$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(logs => {
                this.flightLogFiles = logs;
                this.refreshFeatures();
            });
    }

    refreshFeatures() {
        if (this.flightLogFiles.length > 0) {
            concat(
                ...this.flightLogFiles.map(log =>
                    this.flightLogsService.analyseFlightLog(log.id).pipe(
                        map(l => ({
                            flightSummary: l,
                            flightId: this.allFlights.find(
                                f =>
                                    f.id != null &&
                                    log.flightId != null &&
                                    f.id === log.flightId
                            )?.id
                        }))
                    )
                )
            )
                .pipe(toArray(), takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    flightSummaries => {
                        // @ts-ignore this is the correct type
                        this.loadFeatureSummary(flightSummaries);
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error while fetching flight log: ${error.message}`
                        )
                )
                .add(this.workTracker.createTracker());
        } else {
            this.loadFeatureSummary([]);
        }
    }

    updateRenderedFeatures() {
        const allFeatures = this.features$.getValue();
        const visibleFeatureIds: (string | number)[] = this.groupedFeatures
            .reduce((acc, g) => {
                const features = g.groups
                    .filter(
                        group => group.selected && group.features.length > 0
                    )
                    .reduce((acc2, g) => acc2.concat(g.features), []);
                return acc.concat(features);
            }, [])
            .reduce((acc3, features) => acc3.concat(features), [])
            .map(f => f.featureId);

        const filteredFeatures = allFeatures.reduce(
            (acc, f) =>
                f.existingFeatures != null &&
                f.existingFeatures.length > 0 &&
                f.existingFeatures.filter(feat =>
                    visibleFeatureIds.includes(feat.id)
                ).length > 0
                    ? acc.concat(f)
                    : acc,
            []
        );

        this.renderedFeatures = filteredFeatures;
    }

    loadFeatureSummary(flightSummaries: FlightSummaryWithFlightId[]) {
        let flightFeatures: FeatureGroup[] = [];
        let newFeatureId = 0;
        let newGroupId = 0;

        /**
         * A function to call each time a map layer or feature group us built below.
         * It will take the length of the features array supplied and,
         * using the existing feature and group IDs from above,
         * recalculate them to ensure the next layer is built with unique IDs.
         *
         * Since we're only concerned with rendering features, the primary goal here
         * is to generate unique IDs without too much care for them following any set rules.
         *
         * @param features the features generated for a map layer
         */
        const recalculateIds = (features: any[]) => {
            newFeatureId = newFeatureId + features.length;
            newGroupId = Math.max(
                newGroupId + features.length,
                newFeatureId + 1
            );
        };

        const missionFlightFeatures: {
            featureGroup: FeatureGroup[];
            flightIndex: number;
        }[] = this.allFlights.reduce((acc, f, i) => {
            const featureGroup = getFlightTakeoffLandingPointFeatureGroups(
                f,
                newFeatureId
            );
            const features = acc.concat({
                featureGroup: featureGroup,
                flightIndex: i
            });
            recalculateIds(featureGroup);
            return features;
        }, []);

        const flightGroups =
            this.allFlights.map((f, i) => ({
                groupId: f.id ?? f.number,
                groupName: f.name ?? `Flight ${f.number}`,
                features: missionFlightFeatures
                    .find(f => f.flightIndex === i)
                    .featureGroup.reduce(
                        (acc, g) =>
                            acc.concat(
                                g.existingFeatures.map<SelectableFeatures>(
                                    f => ({
                                        featureId: f.id,
                                        featureName: f.name
                                    })
                                )
                            ),
                        []
                    )
            })) ?? [];

        /**
         * pushes the current flightGroups into the groupedFeatures list for the layer selector
         */
        const pushFlightGroupToSelector = () => {
            const flights: FeatureGroupings = {
                groupName: 'Mission Flights',
                groups: flightGroups
                    .filter(f => f.features.length > 0)
                    .map(g => ({ ...g, selected: true }))
            };
            this.groupedFeatures.push(flights);
        };

        if (flightSummaries.length > 0) {
            const flightLogsGroup: FeatureGroupings = {
                groupName: 'Flight Logs',
                groups: []
            };
            for (let i = 0; i < flightSummaries.length; i++) {
                const flightSummary = flightSummaries[i].flightSummary;
                const features = flightSummaryToFeatures(
                    // @ts-ignore this is the correct type
                    flightSummary,
                    newFeatureId
                );
                const flightIndex = flightGroups.findIndex(
                    f => f.groupId === flightSummaries[i].flightId
                );
                const featuresForGroup = {
                    groupId: newGroupId,
                    groupName: moment
                        .tz(
                            flightSummary.startTime,
                            this.mission.timeZone ??
                                this.mission.location.timeZone
                        )
                        .format('HH:mm:ss'),
                    features: features.reduce(
                        (acc, g) =>
                            acc.concat(
                                g.existingFeatures.map<SelectableFeatures>(
                                    f => ({
                                        featureId: f.id,
                                        featureName: f.name
                                    })
                                )
                            ),
                        []
                    )
                };
                if (flightSummaries[i].flightId != null && flightIndex !== -1) {
                    flightGroups[flightIndex] = {
                        ...flightGroups[flightIndex],
                        features: flightGroups[flightIndex].features.concat(
                            featuresForGroup.features
                        )
                    };
                } else {
                    flightLogsGroup.groups.push({
                        ...featuresForGroup,
                        selected: true
                    });
                }
                flightFeatures = flightFeatures.concat(features);
                recalculateIds(features);
            }

            pushFlightGroupToSelector();
            this.groupedFeatures.push(flightLogsGroup);
        } else {
            pushFlightGroupToSelector();
        }

        const missionFeatures =
            this.mission != null && this.mission.location != null
                ? getFeatureGroups(
                      this.mission.location.features,
                      newFeatureId,
                      newGroupId
                  ).features
                : ([] as FeatureGroup[]);

        const missionGroups: FeatureGroupings[] = missionFeatures.map<
            FeatureGroupings
        >(m => ({
            groupName: `Mission ${m.name}`,
            groups: m.existingFeatures
                .map((f, i) => ({
                    groupId: m.id,
                    groupName: f.name,
                    features: [
                        {
                            featureId: f.id,
                            featureName: f.name
                        }
                    ],
                    selected: true
                }))
                .filter(g => g.features.length > 0)
        }));

        this.groupedFeatures = this.groupedFeatures
            .concat(missionGroups)
            .filter(g => g.groups != null && g.groups.length > 0);

        this.features$.next(
            missionFeatures
                .concat(flightFeatures)
                .concat(
                    missionFlightFeatures.reduce(
                        (acc, f) => acc.concat(f.featureGroup),
                        []
                    )
                )
        );
    }

    close() {
        this.modal.hide();
    }
}
