<ng-container screenAnalytics="battery-set-view">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-battery-sets.png" />
        </div>
        <h3 class="modal-title">
            {{ isEdit ? 'Edit' : 'View' }} Flight Battery Set
        </h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body inner-container no-footer">
        <tabset
            #batterySetTabs
            vertical="true"
            type="pills"
            class="tab-set-vertical"
        >
            <tab>
                <ng-template tabHeading>
                    <i class="far fa-list-ul tab-title-icon"></i>
                    <span>General</span>
                </ng-template>
                <battery-set-view
                    class="modal-body-content"
                    *ngIf="!isEdit && batterySet != null"
                    [batterySet]="batterySet"
                    [currentOrganisation]="currentOrganisation"
                    (edit)="toggleViewEdit()"
                    (deleted)="onDeleted()"
                >
                </battery-set-view>
                <battery-set-edit
                    #batterySetEdit
                    class="modal-body-content"
                    *ngIf="isEdit && batterySet != null"
                    [currentOrganisation]="currentOrganisation"
                    [batterySet]="batterySet"
                    (cancelled)="toggleViewEdit()"
                    (updated)="onUpdate($event)"
                >
                </battery-set-edit>
            </tab>
            <tab>
                <ng-template tabHeading>
                    <i class="far fa-memo-pad tab-title-icon"></i>
                    <span>Notes</span>
                </ng-template>
                <notes-view *ngIf="!isEdit && batterySet != null"
                            class="modal-body-content"
                            [noteType]="NoteType.BatterySet"
                            [source]="batterySet"
                            (edit)="toggleViewEdit()"
                ></notes-view>
                <notes-edit
                    *ngIf="isEdit && batterySet != null"
                    class="modal-body-content"
                    [noteType]="NoteType.BatterySet"
                    [source]="batterySet"
                    (cancelled)="toggleViewEdit()"
                >
                </notes-edit>
            </tab>
            <tab>
                <ng-template tabHeading>
                    <i class="far fa-plane tab-title-icon"></i>
                    <span>Flight History</span>
                </ng-template>
                <div class="container-scroll modal-body-content">
                    <flight-history-summary [batterySet]="batterySet">
                    </flight-history-summary>

                    <logged-flights
                        [batterySet]="batterySet"
                        [managingOrganisationId]="currentOrganisation.id"
                    ></logged-flights>
                </div>
            </tab>
        </tabset>
    </div>
    <!-- The "modal-help-switch" class allows a "floating" enhanced help button for cases like this with tabs & children with their own footers -->
</ng-container>
