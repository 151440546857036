import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'webcam-dialogue',
    templateUrl: './webcam-dialogue.component.html',
    styleUrls: ['./webcam-dialogue.scss']
})
export class WebcamDialogue {
    camImageSource = new Subject<string>();
    triggerSource = new Subject<void>();
    switchCamSource = new BehaviorSubject<boolean>(true);
    image$ = this.camImageSource.asObservable();
    photo = false;
    camSide: boolean = true;

    multipleCameras: boolean;

    error: string;

    imgWidth: number;
    imgHeight: number;

    public imgSrc: string;
    imageChangedEvent: any;
    showCropper: boolean;

    checkingSize: boolean = false;
    scalingImage: WebcamImage;

    outputImage: string;

    videoOptions: MediaTrackConstraints;

    constructor(private modal: BsModalRef<WebcamDialogue>) {}

    ngOnInit() {
        WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
                this.multipleCameras = mediaDevices && mediaDevices.length > 1;
            }
        );
        this.imgWidth = window.innerWidth;
        this.imgHeight = window.innerHeight;
    }

    ngAfterViewInit() {
        this.setWebcamSize();
        window.addEventListener('resize', () => this.setWebcamSize(), true);
    }

    ngOnDestroy() {
        this.triggerSource.complete();
        this.camImageSource.complete();
    }

    /**
     * This function first takes a test image.
     * It uses that image to determine the camera's aspect ratio.
     * The numbers passed to imgWidth and imgHeight are the max allowed sizes.
     * Since the webcam will always obey its aspect ratio,
     * we only need to ensure the shorter side is the correct size for our circular mask.
     * The longer side can be specified as extra long to ensure correct sizing.
     * @param checkingSize allows the test image to be captured without changing the display
     */
    setWebcamSize() {
        if (this.scalingImage == null || this.scalingImage.imageData == null) {
            this.checkingSize = true;
            this.triggerSource.next();
            return;
        }
        const imageHeight = this.scalingImage.imageData.height;
        const imageWidth = this.scalingImage.imageData.width;
        if (window.innerWidth <= 767) {
            if (imageHeight > imageWidth) {
                this.imgWidth = window.innerWidth * 0.91;
                this.imgHeight = window.innerHeight * 2;
            } else {
                this.imgWidth = window.innerWidth * 2;
                this.imgHeight = window.innerWidth * 0.91;
            }
        } else {
            if (imageHeight > imageWidth) {
                this.imgWidth = 485;
                this.imgHeight = 800;
            } else {
                this.imgWidth = 800;
                this.imgHeight = 485;
            }
        }
        this.scalingImage = null;
    }

    takePhoto() {
        this.triggerSource.next();
    }

    photoTaken(webcamImage: WebcamImage) {
        if (this.checkingSize === true) {
            this.scalingImage = webcamImage;
            this.checkingSize = false;
            this.setWebcamSize();
        } else {
            this.imgSrc = webcamImage.imageAsDataUrl;
            this.imageChangedEvent = true;
            this.photo = true;
        }
    }

    usePhoto() {
        this.camImageSource.next(this.outputImage);
        this.close();
    }

    handleInitError(error: WebcamInitError) {
        // Handles an error where camera access hasn't been allowed.
        if (
            error.mediaStreamError &&
            error.mediaStreamError.name === 'NotAllowedError'
        ) {
            this.error =
                'Camera access denied, please review camera permissions in your browser settings';
        }
        console.warn(error.message);
    }

    switchCamera() {
        this.camSide = !this.camSide;
        this.switchCamSource.next(this.camSide);
    }

    imageCropped(event: ImageCroppedEvent) {
        this.outputImage = event.base64;
    }

    loadImageFailed() {
        console.warn('cropper failed');
    }

    imageLoaded() {
        this.showCropper = true;
    }

    close() {
        this.modal.hide();
    }
}
