
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AirworthnessNoticesDto } from '../model/api/airworthnessNoticeDto';

export interface LinkDto {
  id: number;
  title: string;
  url: string;
}

export interface LinkDetails {
  title: string;
  url: string;
}

@Injectable({
    providedIn: 'root'
  })
  export class AirworthinessNoticesService {
  
    constructor(private apollo: Apollo) {}
  
    find(organisationId: number, search?: string): Observable<AirworthnessNoticesDto[]> {
    return this.apollo
      .query<{
        findAirworthinessNotices: {
          count: number;
          results: AirworthnessNoticesDto[];
        };
      }>({
        query: gql`
          query find($organisationId: Long!) {
            findAirworthinessNotices(organisationId: $organisationId) {
              count
              results {
                id
                name
                description
                issuedBy
                dueBy
                type
                applicability
                creationTime
                modificationTime
                status
                 attachmentList{
                              id
                              attachmentVersionId
                              name
                              contentType
                            }
                            linkList{
                              id
                              title
                              url
                            }
              }
            }
          }
        `,
        variables: {
          organisationId,
          search
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        filter(r => !r.loading),
        map(r => r.data.findAirworthinessNotices.results)
      );
  }

  completeStatus(
    airworthinessNoticeId: number,
): Observable<any> {
    return this.apollo
        .mutate<{ completeAirworthinessNotice: AirworthnessNoticesDto }>({
            mutation: gql`
            mutation completeAirworthinessNotice(
                $airworthinessNoticeId: Long!,
            ) {
                completeAirworthinessNotice(
                        airworthinessNoticeId : $airworthinessNoticeId 
                ) {
                    name
                    status
                }
            }
            `,
            variables: {
              airworthinessNoticeId,
            }
        })
        .pipe(
            filter(r => !r.loading),
        );
}

uncompleteStatus(
  airworthinessNoticeId: number,
): Observable<any> {
  return this.apollo
      .mutate<{ uncompleteAirworthinessNotice: AirworthnessNoticesDto }>({
          mutation: gql`
          mutation uncompleteAirworthinessNotice(
              $airworthinessNoticeId: Long!,
          ) {
              uncompleteAirworthinessNotice(
                      airworthinessNoticeId : $airworthinessNoticeId 
              ) {
                  name
                  status
              }
          }
          `,
          variables: {
            airworthinessNoticeId,
          }
      })
      .pipe(
          filter(r => !r.loading),
      );
}

    create(
      organisationId: number,
      name: string,
      type: string,
      applicability: string,
      issuedBy?: string,
      dueBy?: string,
      description?: string
  ): Observable<any> {
      return this.apollo
          .mutate<{ createAirworthinessNotice: AirworthnessNoticesDto }>({
              mutation: gql`
              mutation createAirworthinessNotice(
                  $organisationId: Long!,
                  $name: String!,
                  $type: AirworthinessNoticeType!,
                  $applicability: AirworthinessNoticeApplicability!,
                  $issuedBy: String,
                  $dueBy: String,
                  $description: String
              ) {
                  createAirworthinessNotice(
                      command: {
                          organisationId: $organisationId,
                          name: $name,
                          type: $type,
                          applicability: $applicability,
                          issuedBy: $issuedBy,
                          dueBy: $dueBy,
                          description: $description
                      }
                  ) {
                      id
                      name
                      description
                      issuedBy
                      dueBy
                      type
                      applicability
                  }
              }
              `,
              variables: {
                  organisationId,
                  name,
                  type,
                  applicability,
                  issuedBy,
                  dueBy,
                  description
              }
          })
          .pipe(
              filter(r => !r.loading),
          );
  }
    update(
      id: number,
      name: string,
      type: string,
      applicability: string,
      issuedBy?: string,
      dueBy?: string,
      description?: string,
     
  ): Observable<any> {
      return this.apollo
          .mutate<{ updateAirworthinessNotice: AirworthnessNoticesDto }>({
              mutation: gql`
              mutation updateAirworthinessNotice(
                  $id: Long!,
                  $name: String!,
                  $type: AirworthinessNoticeType!,
                  $applicability: AirworthinessNoticeApplicability!,
                  $issuedBy: String,
                  $dueBy: String,
                  $description: String,
          
              ) {
                  updateAirworthinessNotice(
                      command: {
                          id: $id,
                          name: $name,
                          type: $type,
                          applicability: $applicability,
                          issuedBy: $issuedBy,
                          dueBy: $dueBy,
                          description: $description,
               
                      }
                  ) {
                      id
                      name
                      type
                      applicability
                      issuedBy
                      dueBy
                      description
          
                  }
              }
              `,
              variables: {
                  id,
                  name,
                  type,
                  applicability,
                  issuedBy,
                  dueBy,
                  description,
        
              }
          })
          .pipe(
              filter(r => !r.loading),
          );
  }


  

  findLinks(
    airworthinessNoticeId: number
    ): Observable<LinkDto[]> {
        return this.apollo
          .query<{findAirworthinessNoticeLinks: LinkDto[] }>
              ({
            query: gql`
               query findAirworthinessNoticeLinks($airworthinessNoticeId: Int!) {
                findAirworthinessNoticeLinks(
                  airworthinessNoticeId: $airworthinessNoticeId) {
                    id
                    title
                    url
                }
            }

        `,
        variables: {
            airworthinessNoticeId
        }
    }).pipe(
        map(r => r.data.findAirworthinessNoticeLinks)
    );
  }

  createLink(
    airworthinessNoticeId: number,
      details: LinkDetails
    ): Observable<LinkDto> {
    return this.apollo.mutate<{ createAirworthinessNoticeLink: LinkDto }>({
        mutation: gql`
            mutation createAirworthinessNoticeLink(
              $airworthinessNoticeId: Long!,
               $details: LinkDetails
               ){
                createAirworthinessNoticeLink(
                       airworthinessNoticeId: $airworthinessNoticeId,
                       details: $details
                   ){
                    id
                    title
                    url
                }
            }
        `,
        variables: {
            airworthinessNoticeId,
            details
        }
    }).pipe(
        map(r => r.data.createAirworthinessNoticeLink)
    );
}

updateLink(airworthinessNoticeId: number, linkId: number, details: LinkDetails): Observable<LinkDto> {
  return this.apollo.mutate<{ updateAirworthinessNoticeLink: LinkDto }>({
      mutation: gql`
          mutation updateAirworthinessNoticeLink($airworthinessNoticeId: Long!, $linkId: Long!, $details: LinkDetails) {
              updateAirworthinessNoticeLink(airworthinessNoticeId: $airworthinessNoticeId, linkId: $linkId, details: $details) {
                  id
                  title
                  url
              }
          }
      `,
      variables: {
          airworthinessNoticeId,
          linkId,
          details
      }
  }).pipe(
      map(response => response.data.updateAirworthinessNoticeLink)
  );
}

deleteLink(airworthinessNoticeId: number, linkId: number): Observable<boolean> {
  return this.apollo.mutate<{ deleteAirworthinessNoticeLink: boolean }>({
      mutation: gql`
          mutation deleteAirworthinessNoticeLink($airworthinessNoticeId: Long!, $linkId: Long!) {
              deleteAirworthinessNoticeLink(airworthinessNoticeId: $airworthinessNoticeId, linkId: $linkId)
          }
      `,
      variables: {
          airworthinessNoticeId,
          linkId
      }
  }).pipe(
      map(response => response.data.deleteAirworthinessNoticeLink)
  );
}
}