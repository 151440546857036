<enhanced-help
    componentName="equipment-details"
    [compact]="true"
></enhanced-help>
<div class="container-with-footer">
    <div class="contents">
        <div class="data">
            <span class="display-label">Equipment Category</span>
            {{ equipment.equipmentType.equipmentCategory | formatEquipmentCategory }}
        </div>

        <div class="data">
            <span class="display-label">Make</span>
            {{ equipment.equipmentType.make }}
        </div>

        <div class="data">
            <span class="display-label">Model</span>
            {{ equipment.equipmentType.model }}
        </div>

        <div class="data">
            <span class="display-label">Name</span>
            {{ equipment.name }}
        </div>

        <div class="data">
            <span class="display-label">Manufacturer Serial Number</span>
            {{ equipment.manufacturerSerialNumber }}
        </div>

        <div class="data">
            <span class="display-label">NFC ID</span>
            {{ equipment.nfcId }}
        </div>

        <div class="data">
            <span class="display-label">Current Firmware Version</span>
            {{ equipment.currentFirmwareVersion }}
        </div>

        <div class="data">
            <span class="display-label">Equipment Classification</span>
            {{ equipment.isDummy ? 'Dummy Equipment' : 'Real Equipment' }}
        </div>
    </div>
    <!-- 
    <h4 class="section-heading top-buffer">
        <span>Attachments</span>
        <div></div>
    </h4>

    <div class="row top-buffer">
        <div class="col-xs-12">
            <attachment-list
                [attachmentsHandler]="attachmentsHandler"
                [allowUploads]="false"
                [allowDelete]="false"
                [allowEdit]="false"
                [parentPromiseTracker]="promiseTracker"
                permissions
            ></attachment-list>
        </div>
    </div> -->
    <div class="action-container">
        <button
            *ngIf="equipment.availableActions.hasDelete && isOwner"
            [disabled]="!equipment.availableActions.canDelete"
            class="btn btn-danger pull-left"
            type="button"
            (click)="deleteEquipment()"
        >
            <span class="fal fa-trash-alt"></span>
            Delete
        </button>

        <div
            class="btn-group"
            dropdown
            [dropup]="true"
            *ngIf="printOptions != null && printOptions.length > 0"
        >
            <button
                id="reports-button-container"
                dropdownToggle
                type="button"
                class="btn btn-default dropdown-toggle"
                aria-controls="dropdown-container"
            >
                Reports
                <span class="caret"></span>
            </button>
            <ul
                id="reports-dropdown-container"
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="reports-button-container"
            >
                <li role="menuitem" *ngFor="let report of printOptions">
                    <a class="dropdown-item" (click)="report.print()">{{
                        report.name
                    }}</a>
                </li>
            </ul>
        </div>
        <!-- <div class="btn-group" dropdown [dropup]="true">
            <button
                id="manage-button-container"
                dropdownToggle
                type="button"
                class="btn btn-default dropdown-toggle"
                aria-controls="dropdown-container"
            >
                Manage
                <span class="caret"></span>
            </button>
            <ul
                id="manage-dropdown-container"
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="manage-button-container"
            >
                <li role="menuitem">
                    <a class="dropdown-item" (click)="disposeRpa()"
                        >Dispose RPA</a
                    >
                </li>
            </ul>
        </div> -->

        <button
            *ngIf="equipment.availableActions.hasEdit && isOwner"
            [disabled]="!equipment.availableActions.canEdit"
            class="btn btn-primary"
            type="button"
            (click)="editEquipment()"
            data-cy="equipmentEditButton"
        >
            Edit
        </button>
    </div>
</div>
