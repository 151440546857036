<ng-container [screenAnalytics]="screenAnalytics">
    <div class="modal-header no-icon">
        <h3 class="modal-title">
            {{ title }}
        </h3>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="inputForm" (ngSubmit)="submit()">
        <div class="modal-body">
            <formly-form
                *ngIf="editing"
                [form]="inputForm"
                [fields]="fields"
                [options]="options"
                [model]="data"
            ></formly-form>
            <formly-view
                *ngIf="!editing"
                [model]="data"
                [fields]="fields"
            ></formly-view>
        </div>

        <div class="modal-footer">
            <button
                *ngIf="destructiveAction != null"
                type="button"
                class="btn btn-danger pull-left"
                [disabled]="working"
                (click)="destructive()"
            >
                {{ destructiveAction.label }}
            </button>
            <button
                *ngIf="canEdit && !editing"
                class="btn btn-primary"
                type="button"
                [disabled]="working"
                (click)="edit()"
            >
                Edit
            </button>
            <button
                *ngIf="editing"
                class="btn btn-primary"
                type="submit"
                [disabled]="inputForm.invalid || working"
                data-cy="formly-dialogue-submit"
            >
                {{ action }}
            </button>
        </div>
    </form>
</ng-container>
