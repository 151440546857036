import {
    AnalysedFlightLogDto,
    CoordinateDto,
    FlightPoint,
    FlightSummary,
    FlightSummaryFlight
} from '@flyfreely-portal-ui/flyfreely';
import { Position } from 'geojson';
import { FeatureGroup, MapFeature } from 'libs/map/src/lib/interfaces';
import * as moment from 'moment';
import { FlightLogWithDuration } from './flight-log-list/flight-log-list-dialogue.component';

/**
 * Returns the duration of the flight log from the summary
 * @param log the log file to calculate the duration of
 */
export function calculateLogDuration(log: FlightLogWithDuration) {
    if (
        log == null ||
        log.summary == null ||
        log.summary.startTime == null ||
        log.summary.endTime == null
    ) {
        return null;
    }

    const start = moment(log.summary.startTime);
    const end = moment(log.summary.endTime);
    const time: number = moment.duration(end.diff(start)).asSeconds();
    if (time >= 0) {
        return time;
    }
    return 0;
}

export function flightSummaryToFeatures(
    flightSummary: AnalysedFlightLogDto,
    index: number = 0
): FeatureGroup[] {
    const result: FeatureGroup[] = [];
    const flightPathFeature = getFlightPathFeature(flightSummary, index);
    const pilotPathFeature = getPilotPathFeature(flightSummary, index);
    const homePathFeature = getHomePathFeature(flightSummary, index);
    if (flightPathFeature != null) {
        result.push({
            canAdd: false,
            id: index++,
            existingFeatures: [flightPathFeature],
            name: 'Paths',
            type: 'LineString',
            styles: {
                line: [
                    {
                        paint: {
                            'line-width': 2
                        }
                    }
                ]
            }
        });
    }
    if (pilotPathFeature != null) {
        result.push({
            id: index++,
            canAdd: false,
            type: 'Point',
            name: 'Pilot Path',
            existingFeatures: [pilotPathFeature],
            styles: {
                symbol: [
                    {
                        layout: {
                            'icon-image': 'flight-rp'
                        }
                    }
                ]
            }
        });
    }
    if (homePathFeature != null) {
        result.push({
            id: index++,
            canAdd: false,
            type: 'Point',
            name: 'Home Path',
            existingFeatures: [homePathFeature],
            styles: {
                symbol: [
                    {
                        layout: {
                            'icon-image': 'flight-home'
                        }
                    }
                ]
            }
        });
    }

    result.push(...getStartEndPointFeatureGroups(flightSummary, index));

    return result;
}

function getStartEndPointFeatureGroups(
    flightSummary: AnalysedFlightLogDto,
    startIndex: number
): FeatureGroup[] {
    const startLocation: CoordinateDto = {
        latitude: flightSummary.startLatitude,
        longitude: flightSummary.startLongitude
    };
    const endLocation: CoordinateDto = {
        latitude: flightSummary.endLatitude,
        longitude: flightSummary.endLongitude
    };
    return [
        {
            id: startIndex,
            canAdd: false,
            type: 'Point',
            name: 'Starting point',
            existingFeatures: [
                {
                    id: `start${
                        startIndex != null ? `_${startIndex.toString()}` : ''
                    }`,
                    name: 'Start',
                    geom: {
                        type: 'Point',
                        coordinates: [
                            startLocation.longitude,
                            startLocation.latitude
                        ]
                    }
                }
            ],
            styles: {
                symbol: [
                    {
                        layout: {
                            'icon-image': 'flight-takeoff'
                        }
                    }
                ]
            }
        },
        {
            id: startIndex + 1,
            canAdd: false,
            type: 'Point',
            name: 'End point',
            existingFeatures: [
                {
                    id: `end${
                        startIndex != null ? `_${startIndex.toString()}` : ''
                    }`,
                    name: 'End',
                    geom: {
                        type: 'Point',
                        coordinates: [
                            endLocation.longitude,
                            endLocation.latitude
                        ]
                    }
                }
            ],
            styles: {
                symbol: [
                    {
                        layout: {
                            'icon-image': 'flight-landing'
                        }
                    }
                ]
            }
        }
    ];
}

function getCoordinates(
    lngFunction: (flightSummaryFlight: FlightPoint) => number,
    latFunction: (flightSummaryFlight: FlightPoint) => number,
    flight: AnalysedFlightLogDto
): Position[] {
    const coordinates: Position[] = flight.flight
        .filter(f => lngFunction(f) != null && latFunction(f) != null)
        .map(f => [lngFunction(f), latFunction(f)]);
    return coordinates;
}

function getFlightPathFeature(
    flight: AnalysedFlightLogDto,
    index?: number
): MapFeature {
    const lngFunction = (f: FlightSummaryFlight) => f.rpaLongitude;
    const latFunction = (f: FlightSummaryFlight) => f.rpaLatitude;
    const coordinates = getCoordinates(lngFunction, latFunction, flight);
    if (coordinates.length > 0) {
        return {
            id: `flightPath${index != null ? `_${index.toString()}` : ''}`,
            name: '',
            geom: {
                type: 'LineString',
                coordinates: coordinates
            }
        };
    } else {
        return null;
    }
}

function getPoint(
    lngFunction: (flightSummaryFlight: FlightSummaryFlight) => number,
    latFunction: (flightSummaryFlight: FlightSummaryFlight) => number,
    flight: AnalysedFlightLogDto
): number[] {
    const coordinates = getCoordinates(lngFunction, latFunction, flight);
    if (coordinates.length > 0) {
        return [coordinates[0][0], coordinates[0][1]];
    }
    return [];
}

function getPilotPathFeature(
    flight: AnalysedFlightLogDto,
    index?: number
): MapFeature {
    const lngFunction = (f: FlightSummaryFlight) => f.rpLongitude;
    const latFunction = (f: FlightSummaryFlight) => f.rpLatitude;
    const point = getPoint(lngFunction, latFunction, flight);
    if (point.length > 0) {
        return {
            id: `flightPath${index != null ? `_${index.toString()}` : ''}`,
            name: '',
            geom: {
                type: 'Point',
                coordinates: getPoint(lngFunction, latFunction, flight)
            }
        };
    } else {
        return null;
    }
}

function getHomePathFeature(
    flight: AnalysedFlightLogDto,
    index?: number
): MapFeature {
    const lngFunction = (f: FlightSummaryFlight) => f.homeLongitude;
    const latFunction = (f: FlightSummaryFlight) => f.homeLatitude;

    const point = getPoint(lngFunction, latFunction, flight);
    if (point.length > 0) {
        return {
            id: 'homePath',
            name: '',
            geom: {
                type: 'Point',
                coordinates: point
            }
        };
    } else {
        return null;
    }
}
