import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightConformanceResultDto } from '@flyfreely-portal-ui/flyfreely';
import { flightConformanceStatusLookup } from '@flyfreely-portal-ui/flyfreely';

/**
 * FIXME move to a conformance module once Nx migration is done.
 */
@Component({
    template: `<span
            *ngIf="result?.status === 'NO_BREACHES'"
            class="status-block active"
            >No Breaches</span
        >
        <span
            *ngIf="result?.status === 'NO_POLICY'"
            class="status-block disabled"
            >No Policy</span
        >
        <a
            *ngIf="
                result?.status === 'PRELIMINARY' || result?.status === 'SENT'
            "
            [popover]="conformanceResultTemplate"
            [popoverContext]="{
                result: result,
                popover: popover
            }"
            [outsideClick]="true"
            #popover="bs-popover"
            placement="left"
            analyticsOn
            analyticsAction="show-conformance-result"
        >
            <span
                *ngIf="
                    result?.status === 'PRELIMINARY' ||
                    result?.status === 'SENT'
                "
                class="status-block pending"
                >Breaches</span
            >
        </a>
        <ng-template
            #conformanceResultTemplate
            let-result="result"
            let-popover="popover"
        >
            <div style="width: 460px;">
                <div *ngIf="result != null" class="buffer-top buffer-bottom">
                    <div class="vertical-container">
                        <div
                            class="vertical-container left-buffer right-buffer"
                        >
                            <label class="control-label">Status</label>
                            {{ flightConformanceStatusLookup[result.status] }}
                        </div>
                        <table
                            *ngIf="
                                result.ruleResultList != null &&
                                result.ruleResultList.length > 0
                            "
                            class="table"
                        >
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Max. Value</th>
                                    <th>Actual Value</th>
                                    <th>Outcome</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let rule of result.ruleResultList;
                                        let rowOdd = odd
                                    "
                                    [ngClass]="{ 'row-even': !rowOdd }"
                                >
                                    <td>
                                        {{
                                            rule.type
                                                | formatFlightConformanceType
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            rule.comparisonValue
                                                | number: '1.0-0'
                                        }}
                                    </td>
                                    <td>
                                        {{ rule.actualValue | number: '1.0-0' }}
                                    </td>
                                    <td>
                                        {{
                                            rule.status === 'ACKNOWLEDGED'
                                                ? 'Acknowledged'
                                                : rule.status === 'NO_DATA'
                                                ? 'No Data'
                                                : 'Unacknowledged'
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'conformance-icon',
    styles: [
        `
            .breach {
                --fa-primary-color: #595974;
                --fa-secondary-color: #fecb1c;
            }
        `
    ]
})
export class FlightConformanceIcon {
    @Input()
    result: FlightConformanceResultDto;

    flightConformanceStatusLookup = flightConformanceStatusLookup;
}
