import { Component, Input } from '@angular/core';
import {
    FlightLogFileDto,
    FlightLogsService,
    FlyFreelyError,
    PreSyncCheckCommand,
    PreSyncCheckFile
} from '@flyfreely-portal-ui/flyfreely';
import {
    fadeInExpandOnEnterAnimation,
    fadeOutCollapseLeftOnLeaveAnimation
} from 'angular-animations';
import FlightLogWithSummary from 'libs/testing/src/lib/flight-log-with-summary.json';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FlightLogDataService } from './flight-log-data.service';
import { FlightLogWithDuration } from './flight-log-list/flight-log-list-dialogue.component';
import { FlightLogsDialoguesService } from './flight-logs-dialogues.service';

// FIXME: imported jsons with enums need to be figured out
// @ts-ignore - ignoring bad type sensing
const flightLog: FlightLogFileWithSummary = FlightLogWithSummary;

function b2h(buffer: any) {
    return Array.prototype.map
        .call(new Uint8Array(buffer), (x: any) =>
            `00${x.toString(16)}`.slice(-2)
        )
        .join('');
}

interface UploadWrapper {
    file: File;
    id: number;
    logId?: number;
    status:
        | 'CHECKING'
        | 'PENDING'
        | 'UPLOADING'
        | 'PROCESSING'
        | 'PROCESSED'
        | 'UPLOAD_FAILED'
        | 'PROCESSING_FAILED'
        | 'EXISTING'
        | 'DELETED';
    log?: FlightLogWithDuration;
    error?: string;
}
@Component({
    selector: 'flight-log-uploader',
    template: `
        <div class="modal-header">
            <h3 class="modal-title">Flight Log Uploads</h3>
        </div>
        <div class="modal-body">
            <enhanced-help
                *ngIf="enhancedHelpActive"
                helpTitle="Upload Flight Logs"
                screenName="flight-log-overview"
                componentName="flight-log-uploader"
                [compact]="true"
                [alwaysActive]="true"
            >
            </enhanced-help>
            <div *ngFor="let upload of uploads">
                <div class="horizontal-container">
                    <div class="fcol-7">
                        <i
                            class="fas fa-circle"
                            *ngIf="
                                upload.status == 'PENDING' ||
                                upload.status == 'CHECKING'
                            "
                        ></i>
                        <i
                            class="fas fa-circle-notch fa-spin"
                            *ngIf="
                                upload.status == 'UPLOADING' ||
                                upload.status == 'PROCESSING'
                            "
                        ></i>
                        <i
                            class="fas fa-check"
                            *ngIf="upload.status == 'PROCESSED'"
                        ></i>
                        <i
                            class="fal fa-exclamation-circle"
                            *ngIf="
                                upload.status == 'EXISTING' ||
                                upload.status == 'DELETED'
                            "
                        ></i>
                        <i
                            class="fas fa-times"
                            *ngIf="
                                upload.status == 'UPLOAD_FAILED' ||
                                upload.status == 'PROCESSING_FAILED'
                            "
                        ></i>
                        {{ upload.file.name }}
                    </div>
                    <div class="fcol-5" [ngSwitch]="upload.status">
                        <span *ngSwitchCase="'CHECKING'" class="left-buffer"
                            ><em>Checking...</em></span
                        >
                        <span *ngSwitchCase="'PENDING'" class="left-buffer"
                            ><em>Pending...</em></span
                        >
                        <span *ngSwitchCase="'UPLOADING'" class="left-buffer"
                            ><em>Uploading...</em></span
                        >
                        <span
                            *ngSwitchCase="'UPLOAD_FAILED'"
                            class="text-danger left-buffer"
                            ><em>Upload failed</em> {{ upload.error }}</span
                        >
                        <span *ngSwitchCase="'EXISTING'" class="left-buffer"
                            ><em>Already uploaded</em></span
                        >
                        <span *ngSwitchCase="'DELETED'" class="left-buffer"
                            ><em>Previously deleted.&nbsp;</em>
                            <a
                                *ngIf="
                                    assigningRpaId == null &&
                                    !assignedAnRpaIds.includes(upload.id)
                                "
                                class="left-buffer"
                                (click)="uploadFile(upload)"
                            >
                                Upload again
                            </a>
                        </span>
                        <span *ngSwitchCase="'PROCESSING'" class="left-buffer"
                            ><em>Processing...</em></span
                        >
                        <span
                            *ngSwitchCase="'PROCESSING_FAILED'"
                            class="text-danger left-buffer"
                            ><em>Processing failed</em> {{ upload.error }}</span
                        >
                        <ng-container
                            *ngSwitchCase="'PROCESSED'"
                            [ngSwitch]="canAssignRpa(upload.id)"
                        >
                            <span *ngCase="false" class="left-buffer"
                                >Uploaded</span
                            >
                            <ng-container *ngSwitchCase="true">
                                <a
                                    *ngIf="
                                        assigningRpaId == null &&
                                        !assignedAnRpaIds.includes(upload.id)
                                    "
                                    class="left-buffer"
                                    (click)="showAssignRpa(upload.id)"
                                >
                                    Assign RPA
                                </a>
                                <span
                                    *ngIf="
                                        assigningRpaId != null &&
                                        assigningRpaId != upload.id
                                    "
                                    class="left-buffer"
                                >
                                    Assign RPA
                                </span>
                                <span
                                    *ngIf="assigningRpaId == upload.id"
                                    class="left-buffer"
                                >
                                    Assigning RPA
                                </span>
                                <span
                                    *ngIf="assignedAnRpaIds.includes(upload.id)"
                                    class="left-buffer"
                                >
                                    RPA Assigned
                                    <a
                                        *ngIf="assigningRpaId == null"
                                        class="left-buffer"
                                        (click)="showAssignRpa(upload.id)"
                                    >
                                        Change
                                    </a>
                                </span>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-primary"
                (click)="close()"
                [disabled]="!completed"
            >
                Done
            </button>
        </div>
    `,
    styles: [
        `
            :host {
                height: 100%;
            }
        `
    ],
    animations: [
        fadeInExpandOnEnterAnimation(),
        fadeOutCollapseLeftOnLeaveAnimation()
    ],
    providers: [FlightLogDataService]
})
export class FlightLogUploader {
    @Input() organisationId: number;
    @Input() files: File[];
    @Input() enhancedHelpActive = false;

    uploads: UploadWrapper[];
    completed = false;

    assigningRpaId: number;
    assignedAnRpaIds: number[] = [];
    selectedLog: FlightLogWithDuration;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightLogService: FlightLogsService,
        private flightLogDataService: FlightLogDataService,
        private flightLogsDialoguesService: FlightLogsDialoguesService,
        private modal: BsModalRef
    ) {}

    ngOnInit() {
        this.uploads = this.files.map((file, id) => ({
            file,
            id,
            status: 'CHECKING',
            preSyncResult: null
        }));

        this.buildPreSyncCheckFiles().then(preSyncCheckFiles =>
            this.doPreSyncCheck(preSyncCheckFiles)
        );

        this.flightLogDataService.flightLogs$
            .pipe(debounceTime(5 * 1000), takeUntil(this.ngUnsubscribe$))
            .subscribe(logs => {
                if (logs.length > 0) {
                    this.checkProcessed(logs);
                }
                const candidates = this.uploads.filter(
                    u => u.status === 'PROCESSING'
                );
                if (candidates.length === 0) {
                    return;
                }
                this.checkProcessingLogs();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private processNext() {
        const next = this.uploads.find(u => u.status === 'PENDING');

        if (next == null) {
            this.completed = true;
            return;
        }

        this.uploadFile(next);
    }

    uploadFile(upload: UploadWrapper) {
        upload.status = 'UPLOADING';
        this.flightLogService
            .upload(this.organisationId, upload.file)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                log => {
                    upload.status = 'PROCESSING';
                    upload.logId = log.id;
                    this.pollStatus();
                    this.processNext();
                },
                (error: FlyFreelyError) => {
                    upload.status = 'UPLOAD_FAILED';
                    upload.error = error.message;
                    this.processNext();
                }
            );
    }

    async buildPreSyncCheckFiles() {
        return Promise.all<PreSyncCheckFile>(
            this.uploads.map(
                async f =>
                    new Promise(resolve => {
                        const fileReader = new FileReader();
                        fileReader.readAsArrayBuffer(f.file);
                        fileReader.onloadend = async (entry: any) => {
                            // output: the sha256 digest hex encoded of the file
                            const hash = b2h(
                                await crypto.subtle.digest(
                                    'SHA-512',
                                    entry.target.result
                                )
                            );
                            resolve({
                                checksum: hash,
                                fileName: f.file.name,
                                fileSize: f.file.size
                            });
                        };
                    })
            )
        );
    }

    doPreSyncCheck(checkFiles: PreSyncCheckFile[]) {
        const command: PreSyncCheckCommand = {
            files: checkFiles,
            includeDeletedFiles: true,
            organisationId: this.organisationId
        };
        this.flightLogService
            .performPreSyncCheck(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                result.forEach(r => {
                    this.uploads.find(u => u.file.name === r.fileName).status =
                        r.exists && !r.wasDeleted
                            ? 'EXISTING'
                            : r.exists && r.wasDeleted
                            ? 'DELETED'
                            : 'PENDING';
                });
                this.processNext();
            });
    }

    canAssignRpa(id: number): boolean {
        const entity = this.uploads.find(u => u.id === id);
        if (entity == null) {
            return false;
        }
        const log = entity.log;
        if (log == null) {
            return false;
        }
        return log?.rpaId == null && log.summary.rpaSerialNumber != null;
    }

    showAssignRpa(id: number): void {
        const entity = this.uploads.find(u => u.id === id);
        if (entity == null) {
            return;
        }
        this.selectedLog = entity.log;
        if (this.selectedLog == null) {
            return;
        }
        const modal = this.flightLogsDialoguesService.showAssignRpa(
            this.selectedLog,
            this.enhancedHelpActive
        );
        this.assigningRpaId = id;

        modal.content.done
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(modal.onHidden))
            .subscribe(() => this.assignedAnRpaIds.push(id));

        modal.onHidden
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.doneAssigning());
    }

    doneAssigning() {
        this.assigningRpaId = null;
        this.selectedLog = null;
    }

    pollStatus() {
        const candidates = this.uploads.filter(u => u.status === 'PROCESSING');
        if (candidates.length === 0) {
            return;
        }
        this.checkProcessingLogs();
    }

    checkProcessingLogs() {
        const candidates = this.uploads.filter(u => u.status === 'PROCESSING');
        const logIds = candidates
            .map(c => (c.log != null ? c.log.id : c.logId))
            .filter(id => id != null);
        if (logIds.length === 0) {
            return;
        }

        // Setting the page length to 100 is arbitrary and can be adjusted as needed.
        this.flightLogDataService.findFlightLogs(
            0,
            100,
            null,
            {
                organisationId: this.organisationId,
                idList: logIds
            },
            this.organisationId
        );
    }

    checkProcessed(logs: FlightLogWithDuration[]) {
        logs.forEach(l => {
            const upload = this.uploads.find(
                u => u.log?.id === l.id || u.logId === l.id
            );
            if (
                upload == null ||
                upload.status === 'PROCESSED' ||
                upload.status === 'PROCESSING_FAILED'
            ) {
                return;
            }
            upload.log = l;
            if (
                l.processedStatus === FlightLogFileDto.ProcessedStatus.PROCESSED
            ) {
                upload.status = 'PROCESSED';
            } else if (
                l.processedStatus === FlightLogFileDto.ProcessedStatus.FAILED
            ) {
                upload.status = 'PROCESSING_FAILED';
            }
        });

        const processingList = this.uploads.filter(
            u => u.status === 'PROCESSING'
        );
    }

    close() {
        this.modal.hide();
    }
}
