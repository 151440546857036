import { AirspaceCheckResponse } from '@flyfreely-portal-ui/flyfreely';
import { MapSourceFilters } from 'libs/map/src/lib/dynamic-data.service';

/**
 * Calculate the map source filters for the the airspace check response.
 * @param airspaceCheckResponse the response to use
 * @returns the source filter map
 */
export function mapSourceFiltersForResponse(
    airspaceCheckResponse: AirspaceCheckResponse
) {
    return airspaceCheckResponse.airspaceDatasetList?.reduce(
        (acc, v) => ({
            ...acc,
            [v.identifier]: v.version
        }),
        {} as MapSourceFilters
    );
}
