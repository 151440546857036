import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Angulartics2 } from 'angulartics2';
import { scrollToElement } from 'libs/ui/src/lib/scrollTo.directive';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ColumnTemplateDirective } from '../column-template.directive';
import { initColumn, resolveValue } from '../helpers';
import {
    BulkTableAction,
    ColumnSortPreferences,
    CompareData,
    TableColumn,
    TableConfig
} from '../interfaces';
import { RowActionsDirective } from '../row-actions.directive';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';

/**
 * Return a lambda that performs the given search on a row.
 * @param availableColumns the columns config to use for the search
 * @param search the search values
 */
function setupSearch(availableColumns: TableColumn[], search: any) {
    const searchFunctions = availableColumns
        .filter(c => c.searchable !== false)
        .filter(c => search[c.key] != null && search[c.key] !== '')
        .map(c => ({
            key: c.value,
            fn: (c.searchFunction != null
                ? c.searchFunction
                : c.searchOptions != null
                ? strictSearch
                : partialSearch)(search[c.key])
        }))
        .filter(c => c.fn !== undefined);

    return (row: any) =>
        searchFunctions.reduce(
            (acc, c) => acc && c.fn(resolveValue(row, c.key), row),
            true
        );
}

/**
 * A strict search implementation that matches the value exactly.
 * @param selection the value that is being searched for
 */
function strictSearch(selection: any) {
    const searchValue = selection.toString().trim().toUpperCase();
    if (searchValue === '') {
        return undefined;
    }
    if (searchValue.indexOf('|') !== -1) {
        const searchArray: string[] = searchValue.split('|');
        return (rowValue: any, row: any) => {
            let found = false;
            searchArray.map((s: string) => {
                if (
                    rowValue != null &&
                    rowValue.toString().trim().toUpperCase().indexOf(s) !== -1
                ) {
                    found = true;
                } else {
                    return;
                }
            });
            return found;
        };
    } else {
        return (rowValue: any, row: any) => searchValue === rowValue;
    }
}

/**
 * A search implementation that does a partial string match
 * @param searchValue the value that is being searched for
 */
function partialSearch(searchValue: any) {
    if (searchValue == null) {
        return () => true;
    }
    const normalisedSearchValue = searchValue.toString().trim().toUpperCase();
    return (rowValue: any, row: any) =>
        rowValue != null &&
        rowValue
            .toString()
            .trim()
            .toUpperCase()
            .indexOf(normalisedSearchValue) !== -1;
}

interface ColumnSelection {
    selectedColumns: string[];
}

/**
 * The Static Table is a low overhead table implementation
 * that does not support any pagination, filtering, etc.
 *
 * It does however support column templates, to provide complex
 * interactions for specific columns.
 *
 * All table and column definitions utilise the standard interfaces.
 *
 * Internally, the rows are pre-rendered to avoid excessive redraw cost.
 * The original row data is provided in the `data` property of the row object.
 * This is what is passed through to the column template.
 *
 * When using custom row action templates the width of the table actions column can be controlled
 * by passing in the number of action buttons to the [templateActionsWidth] input.
 * This helps prevent columns overlapping buttons in the case where there are more
 * than 3 buttons.
 *
 * All column headings have enhanced help tooltips with the name [column name]-column.
 */

@Component({
    selector: 'static-table',
    templateUrl: './static-table.component.html',
    styleUrls: ['./static-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticTable implements OnInit, OnChanges {
    @ViewChild('myTable') tableRef: ElementRef;

    @Input() tableConfig: TableConfig;
    @Input() tableData: any[];
    @Input() centeredHeadings?: boolean;
    @Input() borderless?: boolean;
    @Input() underlinedRows?: boolean;
    @Input() columnSorting?: ColumnSortPreferences;
    @Input() tableSearch?: any;
    @Input() hideActionColumn = false;

    /**
     * This is assumed to be set once and not updated
     */
    @Input('availableColumns')
    set availableColumns(columns: TableColumn[]) {
        if (columns == null) {
            this._availableColumns = [];
            return;
        }
        this._availableColumns = columns.map(initColumn);

        // Clear invalid search values
        if (this.searchGroup == null) {
            return;
        }
        const validColumns = this._availableColumns.map(c => c.key);

        const currentSearch = this.searchGroup.value;
        for (const column of this._availableColumns) {
            if (this.searchGroup.get(column.key) == null) {
                console.log('adding columns control');
                this.searchGroup.addControl(
                    column.key,
                    new FormControl(undefined)
                );
            }
            if (
                currentSearch[column.key] != null &&
                column.searchable === 'selection' &&
                (column.searchOptions == null ||
                    column.searchOptions.findIndex(
                        o => o.value === currentSearch[column.key]
                    ) === -1)
            ) {
                this.searchGroup.patchValue({ [column.key]: null });
            }
        }

        Object.keys(this.searchGroup.controls).forEach(col => {
            if (!validColumns.includes(col)) {
                this.searchGroup.removeControl(col);
            }
        });
    }

    @Input() selectedColumns: string[];
    @Input() centeredColumns = false;
    @Input() templateActionsWidth = 0;
    @Input() emitSearchValuesOnly = false;
    @Input() scanability: 'SELECTABLE' | 'comfortable' | 'alternate' =
        'SELECTABLE';
    @Output() selectedColumnsChanged = new EventEmitter<ColumnSelection>();
    @Output() sortOrderChanged = new EventEmitter<ColumnSortPreferences>();
    @Output() search = new EventEmitter<{ [key: string]: any }>();
    @Output() itemLimitUpdated = new EventEmitter<number>();
    @Output() pageChanged = new EventEmitter<number>();

    @ContentChildren(ColumnTemplateDirective)
    templates: QueryList<ColumnTemplateDirective>;

    @ContentChild(RowActionsDirective)
    rowActionsTemplate: RowActionsDirective;

    @ViewChild('tablePopover', { static: true })
    tablePopover: PopoverDirective;

    selectedRow$ = new BehaviorSubject<any[]>([]);
    selectAll$ = new BehaviorSubject<boolean>(false);

    private _availableColumns: TableColumn[];
    selectableColumns: TableColumn[];

    actionColumnWidth = '2.5em';

    templateLookup: { [column: string]: TemplateRef<any> } = {};

    columns: TableColumn[];
    sortOrder: string;
    sortColumn: string;
    ascending: boolean;

    data: { data: { [key: string]: any }; [key: string]: any }[];
    clearable: boolean;

    totalItems: number;
    currentPage = 1;
    startItem = 0;
    endItem: number;
    itemLimit: number;

    showPagination: boolean;
    isBorderless = false;

    serverPagination: boolean;

    searchGroup: FormGroup;
    hasSearchRow: boolean;

    isLoaded = false;

    hasRowSelection = false;

    private ngUnsubscribe$ = new Subject<void>();
    activeScan$ = new BehaviorSubject<'alternate' | 'comfortable'>('alternate');

    private workTracker = new WorkTracker();
    working = false;

    constructor(
        private angulartics: Angulartics2
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngAfterViewInit() {
        this.templateLookup =
            this.templates != null
                ? this.templates.reduce(
                      (acc, d) => ({ ...acc, [d.column]: d.tpl }),
                      {}
                  )
                : {};

        this.findHasSearchRow();
    }

    ngOnInit() {
        this.itemLimit = this?.tableConfig?.limit ?? null;
        this.serverPagination = this?.tableConfig?.serverPagination ?? false;

        this.searchGroup = new FormGroup(
            this._availableColumns.reduce(
                (acc, c) => ({ ...acc, [c.key]: new FormControl(undefined) }),
                {}
            )
        );

        if (this.tableSearch) {
            this.searchGroup.patchValue(this.tableSearch);
        }

        this.searchGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(search => {
                if (this.tableConfig.totalItems != null) {
                    return;
                }
                this.updateData();
                this.resetPage();
            });
        // subscribing to valueChanges twice enables the search to happen in real time
        // while delaying the update preferences call to the server
        this.searchGroup.valueChanges
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(() => {
                this.search.emit(this.searchGroup.value);
            });

        this.fixSelectedColumns();
        this.calculateColumns();
        this.updateData();
        this.refreshPagination();
        this.isLoaded = true;

        this.selectedRow$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(selected => {
                const allSelected =
                    selected.length > 0 &&
                    selected.length ===
                        Math.min(this.endItem, this.data.length) -
                            this.startItem;
                if (allSelected === this.selectAll$.getValue()) {
                    return;
                }
                this.selectAll$.next(allSelected);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            'tableSearch' in changes &&
            this.tableConfig.totalItems != null &&
            this.searchGroup != null &&
            Object.keys(this.searchGroup.value).filter(
                k => this.searchGroup.value[k] != null
            ).length > 0
        ) {
            return;
        }
        if (
            'scanability' in changes &&
            this.scanability != null &&
            this.scanability != 'SELECTABLE'
        ) {
            this.activeScan$.next(this.scanability);
        }
        if ('tableConfig' in changes) {
            this.tableConfig = {
                ...changes.tableConfig.currentValue,
                actions: changes.tableConfig.currentValue.actions ?? [],
                bulkActions: changes.tableConfig.currentValue.bulkActions ?? []
            };
            this.hasRowSelection = this.tableConfig.bulkActions.length > 0;
        }
        if ('availableColumns' in changes || 'selectedColumns' in changes) {
            this.fixSelectedColumns();
            this.calculateColumns();
            this.findHasSearchRow();
        }
        if ('tableData' in changes && this.isLoaded) {
            this.updateData();
            if (!this.serverPagination) {
                this.resetPage();
            }
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    changeSortColumn(column: string, sortable: boolean) {
        if (!sortable) {
            return;
        }
        if (column === this.sortColumn) {
            this.ascending = !this.ascending;
        } else {
            this.sortColumn = column;
            this.ascending = true;
        }
        this.sortOrderChanged.emit({
            column: column,
            ascending: this.ascending
        });
        this.updateData();
        this.resetPage();
    }

    private calculateColumns() {
        if (this._availableColumns == null) {
            this.columns = [];
            return;
        }
        this.columns = this._availableColumns
            .filter(
                c =>
                    !c.selectable ||
                    (this.selectedColumns != null &&
                        this.selectedColumns.indexOf(c.key) !== -1)
            )
            .map(col => ({
                ...col,
                sortable: col.sortable ?? true
            }));
    }

    private sortBy(a: CompareData, b: CompareData) {
        if (a.renderedData < b.renderedData) {
            return -1;
        }
        if (a.renderedData > b.renderedData) {
            return 1;
        }
        return 0;
    }

    private updateData() {
        if (this.tableData == null) {
            this.data = null;
            return;
        }
        if (this.borderless) {
            this.isBorderless = true;
        }
        if (this.columnSorting != null) {
            this.sortColumn = this.columnSorting.column;
            this.ascending = this.columnSorting.ascending;
        }
        const search = setupSearch(
            this._availableColumns,
            this.searchGroup.value
        );

        const defaultFormatter = (val: any) =>
            val != null ? val.toString() : '';

        const formatters = this._availableColumns.reduce(
            (acc, c) => ({
                ...acc,
                [c.key]: c.formatterFunction ?? defaultFormatter
            }),
            {}
        );

        const formatRow = originalRow =>
            this._availableColumns.reduce(
                (row, col) => ({
                    ...row,
                    [col.key]: formatters[col.key](
                        resolveValue(originalRow, col.value),
                        originalRow
                    )
                }),
                { data: originalRow }
            );

        if (this.serverPagination || this.tableConfig.totalItems != null) {
            this.totalItems = this.tableConfig.totalItems;
            this.data = this.tableData.map(formatRow);
            return;
        }

        // Client side pagination, and sorting.
        this.data = this.tableData.filter(row => search(row)).map(formatRow);

        this.totalItems = this.data.length;

        this.endItem =
            this.itemLimit !== null ? this.itemLimit : this.data.length;

        const sortColumnConfig = this.columns.find(
            c => c.key === this.sortColumn
        );

        const compareFunction =
            sortColumnConfig?.compareFunction ?? this.sortBy;
        const order = this.ascending ? 1 : -1;

        this.data.sort(
            (a: any, b: any) =>
                compareFunction(
                    {
                        rawData: a.data[this.sortColumn],
                        renderedData: a[this.sortColumn]
                    },
                    {
                        rawData: b.data[this.sortColumn],
                        renderedData: b[this.sortColumn]
                    }
                ) * order
        );
    }

    private fixSelectedColumns() {
        this.selectableColumns = this._availableColumns.filter(
            c => c.selectable
        );
        if (this.selectedColumns != null || !this._availableColumns) {
            return;
        }

        this.selectedColumns = this._availableColumns
            .filter(
                c =>
                    !c.selectable ||
                    (c.selectable &&
                        this.selectedColumns != null &&
                        this.selectedColumns.indexOf(c.value) === -1)
            )
            .map(c => c.value);

        this.calculateActionColumnWidth();
    }

    private calculateActionColumnWidth() {
        // replacing no-actions style
        if (
            this.selectableColumns == null &&
            this.tableConfig.actions.length === 0 &&
            this.templateActionsWidth === 0
        ) {
            this.actionColumnWidth = '0';
            return;
        }

        // replacing actions-wide style
        if (
            this.tableConfig.actions?.length === 2 ||
            (this.rowActionsTemplate != null && this.templateActionsWidth <= 2)
        ) {
            this.actionColumnWidth = '3.5em';
            return;
        }

        // calculated width for more than 3 action buttons
        if (
            this.templateActionsWidth >= 3 ||
            this.tableConfig.actions?.length >= 3
        ) {
            const templateOverConfig =
                this.templateActionsWidth >= this.tableConfig.actions?.length;
            const actionsCount = templateOverConfig
                ? this.templateActionsWidth
                : this.tableConfig.actions?.length;
            this.actionColumnWidth = `${actionsCount * 2 - 0.5}em`;
            return;
        }

        // else return the default
        this.actionColumnWidth = '2.5em';
    }

    toggle(column: TableColumn) {
        const columnName =
            column.key != null || column.key !== '' ? column.key : column.value;
        const isChecked = this.isChecked(columnName);

        if (isChecked) {
            this.selectedColumns = this.selectedColumns.filter(
                c => c !== columnName
            );
        } else {
            this.selectedColumns = [...this.selectedColumns, columnName];
        }
        this.selectedColumnsChanged.emit({
            selectedColumns: this.selectedColumns
        });

        if (!isChecked) {
            this.searchGroup.patchValue({ [column.key]: null });
        }

        this.calculateColumns();
        this.angulartics.eventTrack.next({ action: 'table-columns-change' });
    }

    findHasSearchRow() {
        if (this._availableColumns == null) {
            this.hasSearchRow = false;
            return;
        }
        this.hasSearchRow =
            this._availableColumns.filter(
                c => c.searchable !== false || c.searchFunction != null
            ).length > 0;
    }

    hasSearchValue(key: any) {
        return (
            this.searchGroup.controls[key].value &&
            this.searchGroup.controls[key].value.length > 0
        );
    }

    clearSearch(key: any) {
        this.searchGroup.controls[key].patchValue(undefined);
        this.search.emit(this.searchGroup.value);
    }

    /**
     * The trackByFn function preserves DOM state on dynamic fields during changes, such as an emit event or a regeneration of a list.
     * More info at: https://angular.io/api/core/TrackByFunction
     * @param index the HTML element index in the dynamically populated list
     */
    trackByFn(index: number, column: TableColumn) {
        return column.key;
    }

    resetPage() {
        this.currentPage = (this.tableConfig.currentPage ?? 0) + 1;

        this.startItem = 0;
        this.endItem =
            this.itemLimit !== null ? this.itemLimit : this.data.length;
    }

    isChecked(columnName: string) {
        return this.selectedColumns &&
            this.selectedColumns.indexOf(columnName) !== -1
            ? true
            : false;
    }

    isSeen(row: any) {
        if (this.tableConfig.isSeenFunction != null) {
            return this.tableConfig.isSeenFunction(row.data);
        } else {
            return true;
        }
    }

    isWarning(row: any) {
        if (this.tableConfig.isWarningFunction != null) {
            return this.tableConfig.isWarningFunction(row.data);
        } else {
            return false;
        }
    }

    refreshPagination() {
        this.showPagination = this?.tableConfig?.limit != null;
    }

    onPageChanged(newPage: PageChangedEvent) {
        if (this.tableConfig.totalItems != null) {
            this.currentPage = newPage.page;
            return this.pageChanged.emit(newPage.page - 1);
        }
        const itemsPerPage = this.itemLimit;
        if (itemsPerPage == null) {
            return;
        }
        this.currentPage = newPage.page;

        this.startItem = itemsPerPage * (this.currentPage - 1);
        this.endItem = itemsPerPage * this.currentPage;

        this.selectedRow$.next([]);
    }

    setLimit() {
        this.endItem =
            this.itemLimit !== null ? this.itemLimit : this.data.length;
        this.itemLimitUpdated.emit(this.itemLimit);
    }

    setScanType(type: 'comfortable' | 'alternate') {
        this.activeScan$.next(type);
        this.scrollToBlockStart();
    }

    setLimitPage(limit: number) {
        this.itemLimit = limit;
        this.endItem =
            this.itemLimit !== null ? this.itemLimit : this.data.length;
        this.itemLimitUpdated.emit(this.itemLimit);
        this.scrollToBlockStart();
    }

    scrollToBlockStart() {
        scrollToElement(this.tableRef.nativeElement);
    }

    toggleSelectAll(event: Event) {
        const isChecked = (event.target as HTMLInputElement).checked;
        if (isChecked) {
            this.selectedRow$.next(
                this.data.slice(this.startItem, this.endItem).map(d => d.data)
            );
            this.selectAll$.next(true);
        } else {
            this.selectedRow$.next([]);
            this.selectAll$.next(false);
        }
    }

    /**
     * Execute the bulk table action.
     * @param action the action configuration
     */
    doBulkAction(action: BulkTableAction) {
        const response = action.action(this.selectedRow$.getValue());
        if (response instanceof Promise) {
            const doneWorking = this.workTracker.createTracker();

            response.finally(doneWorking);
        }
    }
}
