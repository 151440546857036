<form [formGroup]="form">
    <div
        class="vertical-container container-fluid bottom-buffer"
        *ngIf="form != null"
    >
        <div class="form-group">
            <div class="input-content">
                <div class="col-xs-3 text-right">
                    <span class="display-label left-buffer">
                        {{ form.controls.name.value }}
                        <field-validation-indicator
                            [valid]="form.valid"
                        ></field-validation-indicator>
                    </span>
                </div>
                <ng-container *ngIf="form.controls.flown.value == true">
                    <div class="col-xs-9">
                        <div
                            class="input-group"
                            btnRadioGroup
                            [formControl]="form.controls.serviceability"
                        >
                            <label
                                class="btn btn-sm btn-pill"
                                btnRadio="SERVICEABLE"
                                (click)="checkFormValidity()"
                                [disabled]="
                                    form.controls.rpaId.value == null ||
                                    (assignedMaintenanceLog != null &&
                                        assignedMaintenanceLog.status !=
                                            'DRAFT' &&
                                        assignedMaintenanceLog.status !=
                                            'CANCELLED')
                                "
                            >
                                Serviceable
                            </label>
                            <label
                                class="btn btn-sm btn-pill"
                                btnRadio="UNSERVICEABLE"
                                (click)="checkFormValidity()"
                                [disabled]="
                                    form.controls.rpaId.value == null ||
                                    (assignedMaintenanceLog != null &&
                                        assignedMaintenanceLog.status !=
                                            'DRAFT' &&
                                        assignedMaintenanceLog.status !=
                                            'CANCELLED')
                                "
                            >
                                Unserviceable
                            </label>
                            <span
                                class="left-buffer alert alert-info"
                                *ngIf="
                                    form.controls.serviceability.value !=
                                        null &&
                                    form.controls.signoffPerson.value != null
                                "
                            >
                                Signed off by
                                {{
                                    form.controls.signoffPerson.value
                                        | formatPerson
                                }}
                                @
                                {{
                                    form.controls.signoffTime.value
                                        | date: 'd MMM yyyy HH:mm'
                                }}
                            </span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="form.controls.flown.value === false">
                    <div class="col-xs-6">
                        <span class="display-label">No Signoff Required</span>
                    </div>
                    <div class="col-xs-3"></div>
                </ng-container>
            </div>
            <div class="input-content">
                <div class="col-xs-3"></div>
                <div class="col-xs-6">
                    <ng-container
                        *ngIf="
                            form.controls.flown.value === true &&
                            form.controls.serviceability.value ===
                                'UNSERVICEABLE'
                        "
                    >
                        <label class="control-label">Reason</label>
                        <action-required *ngIf="!requestSubmitted()">
                            All maintenance reported during a mission must be
                            submitted before a mission can be
                            finalised.</action-required
                        >
                        <ng-container *ngIf="requestSubmitted()">
                            <input
                                [formControl]="form.controls.reason"
                                placeholder="Reason for marking RPA as unserviceable"
                                class="form-control"
                                [readonly]="requestSubmitted()"
                            />
                        </ng-container>
                        <ng-container *ngIf="!requestSubmitted()">
                            <div class="input-group">
                                <input
                                    [formControl]="form.controls.reason"
                                    placeholder="Reason for marking RPA as unserviceable"
                                    class="form-control"
                                />
                                <span class="input-group-btn">
                                    <button
                                        class="btn btn-primary"
                                        type="button"
                                        (click)="submitMaintenance()"
                                        tooltip="Submit this maintenance log"
                                        placement="left"
                                        angulartics2On="click"
                                        angularticsAction="submit-maintenance"
                                        angularticsCategory="mission-completion"
                                    >
                                        Submit
                                    </button>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-xs-3"></div>
            </div>
        </div>
    </div>
</form>
