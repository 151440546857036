import { Component } from '@angular/core';
import {
    AirspaceJurisdictionDto,
    AirspaceNotification,
    AirspaceNotificationService,
    AirspaceOperatorTypesParser,
    RulesetDto
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { combineLatest, Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { filter, map, retry, switchMap, takeUntil } from 'rxjs/operators';
import { AirspaceCheckService } from '..';

@Component({
    selector: 'jurisdiction-links',
    templateUrl: './jurisdiction-links.component.html',
    styleUrls: ['./jurisdiction-links.component.scss']
})
export class JurisdictionLinksComponent {
    private ngUnsubscribe$ = new Subject<void>();

    private notifications$ = new ReplaySubject<AirspaceNotification[]>(1);

    airspaceNotifications: AirspaceNotification[];

    airspaceJurisdiction$: Observable<AirspaceJurisdictionDto>;
    ruleset$: Observable<RulesetDto | undefined>;

    constructor(
        private airspaceCheckService: AirspaceCheckService,
        private airspaceNotificationService: AirspaceNotificationService
    ) {
        this.airspaceJurisdiction$ = airspaceCheckService.airspaceJurisdiction$;
        this.ruleset$ = combineLatest([
            airspaceCheckService.airspaceJurisdiction$,
            airspaceCheckService.ruleset$
        ]).pipe(
            map(([jurisdiction, ruleset]) =>
                jurisdiction.rulesetList.find(r => r.identifier === ruleset)
            )
        );

        combineLatest([airspaceCheckService.ruleset$, this.notifications$])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([ruleset, notifications]) => {
                if (ruleset == null) {
                    return (this.airspaceNotifications = notifications.filter(
                        n => n.operatorTypes.length === 0
                    ));
                }
                const airspaceRuleset = AirspaceOperatorTypesParser[ruleset];
                this.airspaceNotifications = notifications.filter(n =>
                    n.operatorTypes.includes(airspaceRuleset)
                );
            });

        this.setupNotifications();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.notifications$.complete();
    }

    setupNotifications() {
        // wait 1 second before refresh for any API processes to finish and then refresh every minute until stopped
        combineLatest([
            timer(1000, 60 * 1000),
            this.airspaceCheckService.airspaceJurisdiction$
        ])
            .pipe(
                filter(([_, jurisdiction]) => jurisdiction.hasNotifications),
                switchMap(([_, jurisdiction]) =>
                    this.airspaceNotificationService.getNotifications(
                        jurisdiction.identifier,
                        moment().toISOString()
                    )
                ),
                // Only retry twice on fail before stopping. Ensures pollings stops on a lost connection, etc
                retry(2),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(airspaceNotifications => {
                const notifications = airspaceNotifications.notifications.sort(
                    (a, b) => moment(b.startTime).diff(a.startTime)
                );

                this.notifications$.next(notifications);
            });
    }
}
