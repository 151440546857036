<ng-container screenAnalytics="location-template-from-mission">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Create Location Template From Mission</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body max-height">
        <div class="horizontal-container" style="height: 100%">
            <div class="fcol-6 vertical-container">
                <enhanced-help componentName="screen" [compact]="true">
                </enhanced-help>
                <div class="form-group top-buffer contents">
                    <div class="input-group vertical-container">
                        <label class="control-label"> Mission </label>
                        <ng-select
                            name="missionSelect"
                            bindLabel="name"
                            placeholder="Select a mission"
                            [(ngModel)]="selectedMission"
                            [ngModelOptions]="{ standalone: true }"
                            [items]="missions"
                            [readonly]="working"
                            [clearable]="true"
                            (change)="onMissionSelected()"
                            appendTo="body"
                        >
                            <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-index="index"
                            >
                                {{ item.name }}
                            </ng-template>
                            <ng-template
                                ng-label-tmp
                                let-item="item"
                                let-index="index"
                            >
                                {{ item.name }}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="form-group top-buffer contents">
                    <div>
                        <label class="control-label"> Name </label>
                        <input
                            class="form-control"
                            [(ngModel)]="locationName"
                            placeholder="Type a name for this Location"
                        />
                    </div>
                </div>
            </div>
            <div class="fcol-6">
                <div class="input-content top-buffer" style="height: 100%">
                    <div
                        class="alert alert-warning"
                        role="alert"
                        *ngIf="selectedMission != null && location == null"
                    >
                        The selected mission has no location to clone.
                    </div>
                    <flyfreely-map
                        *ngIf="selectedMission != null && location != null"
                        #map
                        class="model-body-content"
                        [organisationId]="organisationId"
                        [features]="locationFeatures"
                        [showFeatureSelection]="true"
                        [requireCtrlToZoom]="true"
                        (featuresLoaded)="map.zoomToFeatureGroup(0)"
                        (fullscreenRequested)="onFullscreenRequested()"
                    ></flyfreely-map>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            [disabled]="working || location == null"
            type="button"
            class="btn btn-primary pull-right"
            (click)="createLocationType()"
            analyticsOn="click"
            analyticsCategory="locations"
            analyticsAction="clone-from-mission"
        >
            Create
        </button>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</ng-container>
