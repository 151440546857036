import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    ActiveMapStyleDto,
    AirspaceJurisdictionDto,
    BaseStyleDto,
    DO_NOTHING,
    LayerGroup,
    MetadataKeyIsDefault,
    MetadataKeyTemplateName,
    NamedLayer,
    PreferencesService,
    RulesetDto,
    SimpleAirspaceJurisdictionDto,
    trackById,
    TypedActiveMapStyleDto
} from '@flyfreely-portal-ui/flyfreely';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon';
import { center } from '@turf/center';
import { centroid } from '@turf/centroid';
import { feature, point } from '@turf/helpers';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation,
    fadeInOnEnterAnimation,
    fadeOutOnLeaveAnimation
} from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { getOrElse } from 'fp-ts/es6/Option';
import {
    Feature,
    FeatureCollection,
    GeoJsonProperties,
    Geometry,
    GeometryCollection,
    MultiPolygon,
    Polygon
} from 'geojson';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import * as mapboxgl from 'mapbox-gl';
import { LngLat, PointLike } from 'mapbox-gl';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MapComponent } from 'ngx-mapbox-gl';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    ReplaySubject,
    Subject
} from 'rxjs';
import { bufferCount, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
    jurisdictionAirspaceLayers,
    jurisdictionAirspaceSources
} from '../airspace';
import { MapModes } from '../constants';
import DrawAreaLine from '../draw-modes/draw-area-line';
import DrawAreaLineSelect from '../draw-modes/draw-area-line-select';
import DrawLine from '../draw-modes/draw-line';
import DrawPolygon from '../draw-modes/draw-polygon';
import { MapDynamicData, MapSourceFilters } from '../dynamic-data.service';
import {
    MapDrawCreatedEvent,
    MapDrawUpdatedEvent,
    MapDrawUpdatedEventData,
    MapEventType,
    MapInteractionState
} from '../events';
import { spacialGeocoder } from '../geocoders';
import {
    FeatureAndGroup,
    FeatureAndGroupWithJurisdiction,
    FeatureGroup,
    FeaturesAndGroup,
    LayerSection,
    MapFeature,
    MapViewport,
    SelectedFeatures,
    SourceType,
    WithId
} from '../interfaces';
import drawStyles from '../layers/draw-styles';
import { MapFeatureSelectorComponent } from '../map-feature-selector/map-feature-selector.component';
import { DEFAULT_BASE_STYLE, MapService } from '../map.service';
import { boundsOf } from '../utils';
import {
    calculateClusterGroupAndFeatures,
    calculateSourceId,
    calculateSourceIdFromId,
    createGeoJsonSource,
    createLayers,
    diff,
    extractFlattenedLayers,
    getFeatures,
    idListsDiffer,
    isValidGeometry
} from './helpers';
import { MapSidebarDirective } from './map-sidebar-template.directive';

const DEFAULT_ZOOM_LEVEL = 3;
const DEFAULT_LNG = 106.42072352575883;
const DEFAULT_LAT = -43.77646981204873;
const FLYFREELY_MAP_COMPONENT = 'flyfreely-map-component';

interface MapPreferencesV1 {
    version: never;
    boundsSw: number[];
    boundsNe: number[];
    baseStyleName: string;
    zoom: number;
    visibleLayers: string[];
}

interface MapPreferencesV2 {
    version: 2;
    boundsSw: number[];
    boundsNe: number[];
    baseStyleName: string;
    zoom: number;
    jurisdictions: { [jurisdictionId: string]: { [layerId: string]: boolean } };
}

interface MapPreferencesV1 {
    bounds: mapboxgl.LngLatBounds;
    baseStyleName: string;
    visibleLayers: string[];
    zoom: number;
}

/**
 * The preferences are stored as booleans so we know all of the selected layers, so the
 * rest can be defaulted.
 */
type MapPreferencesDto = MapPreferencesV1 | MapPreferencesV2;

interface MapPreferences {
    bounds: mapboxgl.LngLatBounds;
    baseStyleName: string;
    jurisdictions: { [jurisdictionId: string]: { [layerId: string]: boolean } };
    zoom: number;
}

interface DisplayableFeature extends Feature {
    layer: mapboxgl.Layer;
    source: string;
    sourceLayer: string;
    state: {
        [key: string]: any;
    };
}

function isMapPreferencesV1(p: MapPreferencesDto): p is MapPreferencesV1 {
    return p.version == undefined;
}

/**
 * This component provides a consistent map experience across the FlyFreely application. It
 * provides two kinds of map features:
 * 1) Layers - typically airspace layers, but this could also include other boundaries
 * 2) Features - these are the context specific additions to the map such as flight area.
 *
 * It also manages the airspace data based on the jurisdiction. This allows jurisdictional
 * information to be passed back with features.
 */
@Component({
    selector: 'flyfreely-map',
    templateUrl: './flyfreely-map.component.html',
    styleUrls: ['./flyfreely-map.component.scss', '../shared.scss'],
    providers: [MapService, MapDynamicData],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation(),
        expandOnEnterAnimation(),
        collapseOnLeaveAnimation()
    ]
})
export class FlyFreelyMapComponent implements OnInit, OnChanges, OnDestroy {
    @Input() organisationId: number;
    /**
     * Should the done/cancel buttons be displayed in the map when editing?
     */
    @Input() showEditCompletion = true;

    @Input() showFeatureSelection: boolean;

    @Input() showFullScreen = true;

    @Input() showSavePreferences = true;

    @Input() featuresTitle = 'Markers';

    private featureListSubject = new BehaviorSubject<FeatureGroup[]>([]);
    @Input() set features(features: FeatureGroup[]) {
        this.featureListSubject.next(features ?? []);
    }

    @HostBinding('class.crosshair')
    @Input()
    crosshairCursor = false;

    /**
     * Does the map require CTRL to be held in order to scroll?
     */
    @Input() requireCtrlToZoom = false;
    showScrollOverlay = false;
    /**
     * The available airspace jurisdictions to display in the position information on the map
     */
    @Input() airspaceJurisdictions: AirspaceJurisdictionDto[];
    /**
     * Bypasses the jurisdiction map layer filtering for maps like the admin map style editor
     */
    @Input() enableJurisdictionLayerFiltering = true;
    /**
     * Emitted when the feature values from the @Input has completed loading
     */
    @Output() featuresLoaded = new EventEmitter<void>();

    @Output() mapLoaded = new EventEmitter<void>();

    @Output() configLoaded = new EventEmitter<void>();

    @Output() mapReady = new EventEmitter<void>();

    @Output() jurisdictionChanged = this.mapService.visibleJurisdictions$.pipe(
        map(j => j.selected)
    );

    /**
     * The overridden template to use when showing a feature popup.
     * Otherwise the default template is used.
     */
    @Input() featurePopupTemplate: TemplateRef<null>;

    /**
     * The template to use when showing a cluster popup
     */
    @Input() featureClusterPopupTemplate: TemplateRef<null>;

    /**
     * Able to manually supply configs.
     */
    @Input()
    config: ActiveMapStyleDto;

    /**
     * Identifies the widget map for certain unique behaviours required there.
     */
    @Input() isDashboardMap = false;

    /**
     * The currently selected dashboard mode. Used to calculate container heights.
     */
    @Input() dashboardMode: 'MAP' | 'BOTH' = 'BOTH';

    /**
     * The currently selected dashboard mode. Used to calculate container heights.
     */
    @Input() simplifiedMap: boolean = false;

    /**
     * Supplied initial position
     */
    @Input() initialPosition?: MapViewport;

    /**
     * Optional start and end times for managing dynamic layers.
     */
    @Input() startTime: string;
    @Input() endTime: string;

    @Input() ruleset: RulesetDto;

    private enabledLayerGroups$ = new BehaviorSubject<string[]>([]);
    /**
     * Controls which layer groups are shown. Layer group identifiers listed here will be forced on and not controllable by the user.
     */
    @Input()
    set enabledLayerGroups(val: string[]) {
        this.enabledLayerGroups$.next(val ?? []);
    }
    private allEnabledLayerGroups: string[] = [];

    /**
     * These layer groups are enabled by default when they become available, but are controllable by the user.
     */
    @Input() enableLayerGroupsByDefault = [] as string[];

    /**
     * Variable names and the value to replace them with for mapbox url and tile pointers.
     * This should be entered in the map style editor as a url with the variables marked by curly braces - {variable_name}
     * In the input object the variable name should be passed without the curly braces. These will be added by the parsers.
     * eg
     * In the map style editor:
     * http://someurl.domain/details/{variable_name}
     * Then in the HTML when calling the map:
     * [mapSourceFilters]="{'variable_name': 'variable_value'}"
     */
    @Input() mapSourceFilters: MapSourceFilters;

    /**
     * Provide dynamicly updated map data for GeoJSON sources.
     */
    @Input()
    set mapData(data: { [source: string]: FeatureCollection }) {
        this.mapDynamicData.setMapData(data);
    }

    /**
     * The position to show the marker at. There can only be a single marker, as these are slow to render, so should be used sparingly.
     */
    @Input() marker: mapboxgl.LngLatLike;
    /**
     * Is the marker draggable.
     */
    @Input() markerDraggable: boolean;
    /**
     * The template to display as the marker.
     */
    @Input() markerTemplate: TemplateRef<null>;
    @Output() markerDragEnd = new EventEmitter<mapboxgl.Marker>();

    /**
     * Which feature groups should we ignore clicks on
     */
    @Input()
    ignoreClicksOnFeatureGroups = [] as number[];

    /**
     * The editing feature has been updated
     */
    @Output() featuresUpdated: EventEmitter<FeatureAndGroupWithJurisdiction> =
        new EventEmitter();

    @Output() featureSelected: EventEmitter<FeatureAndGroup> =
        new EventEmitter();

    @Output() measurementToolSelected: EventEmitter<void> = new EventEmitter();

    @Output() featureClusterSelected: EventEmitter<FeaturesAndGroup> =
        new EventEmitter();

    @Output() featureUnselected: EventEmitter<void> = new EventEmitter();

    @Output() geocodeResultSelected: EventEmitter<MapFeature> =
        new EventEmitter();

    @Output() fullscreenRequested: EventEmitter<void> = new EventEmitter();

    @Output() editModeChanged: EventEmitter<boolean> = new EventEmitter();

    /**
     * This is a low level internal event used for specific integrations.
     */
    @Output() rawFeaturesClick = new EventEmitter<
        mapboxgl.MapboxGeoJSONFeature[]
    >();

    /**
     * The feature ID of the current feature being hovered
     */
    @Output() featureHover = new EventEmitter<number>();

    /**
     * Triggers when the viewport changes
     */
    @Output() viewportChange = new EventEmitter<MapViewport>();

    @Output() mapClick = new EventEmitter<LngLat>();

    popupTemplate: TemplateRef<null>;

    @ViewChild('airspaceLayerListTemplate', { read: TemplateRef, static: true })
    airspaceLayerListTemplate: TemplateRef<null>;

    @ViewChild('map', { static: false })
    private mapComponent: MapComponent;

    @ContentChild(MapSidebarDirective)
    mapSidebarDirective: MapSidebarDirective;

    /**
     * Flag for controlling measurement tool availability
     */
    hasMeasurementTools = false;

    isConfigLoaded = false;

    baseStyles: BaseStyleDto[];
    baseStyle: BaseStyleDto;

    private areFeaturesLoaded = false;
    private areStylesLoaded = false;

    defaultView = new mapboxgl.LngLatBounds(
        [106.42072352575883, -43.77646981204873],
        [161.24894882869165, -9.30462336665947]
    );

    /**
     * This default view allows the map to start with a view of the whole globe
     */
    defaultGlobalView = new mapboxgl.LngLatBounds(
        [260.0, 60.0],
        [-170.0, -50.0]
    );

    initialView?: mapboxgl.LngLatBounds;

    initialCentre?: mapboxgl.LngLat;
    initialZoom?: number;

    draw: MapboxDraw;

    private airSpaceSources = new ReplaySubject<SourceType[]>(1);
    /**
     * The airspace sources, which is augmented with any custom data provided to the component
     */
    airSpaceSources$ = this.airSpaceSources.asObservable();
    airSpaceLayers: NamedLayer[];

    // The complete list of current layer ids
    airSpaceLayerIds: string[] = [];
    airSpaceLayerNames: { [id: string]: string };

    layerSections: LayerSection[];

    // The IDs of the feature related layers. Used for easy lookup
    featureLayerIds: string[] = [];

    featureSources: (mapboxgl.GeoJSONSourceRaw & WithId)[] = [];
    featureLayers: mapboxgl.Layer[] = [];

    // The point at which to show the popup
    selectedPoint: LngLat;
    // The features to show in the popup
    selectedAirspaceFeatures: DisplayableFeature[] = [];
    // The layers to show in the popup
    selectedAirSpaceLayerIds: string[] = [];
    jurisdictionSelectedLayerIds: { [jurisdictionId: number]: string[] } = {};
    layouts: { [layerId: string]: mapboxgl.Layout };

    visibleFeatures: SelectedFeatures = {};

    currentlyEditing: FeatureAndGroup;

    selectedTreeItem: any = null;

    @ViewChild('featuresComponent', { static: true })
    rightbar: MapFeatureSelectorComponent;

    private overlayTimeout: number;

    private ngUnsubscribe$ = new Subject<void>();

    private visibleJurisdictions: AirspaceJurisdictionDto[];

    images = this.mapService.getImages();

    currentMouseLocation: LngLat;

    currentMapLocation: MapViewport;

    currentJurisdictionAtMousePosition: AirspaceJurisdictionDto;
    currentlySelectedJurisdiction: AirspaceJurisdictionDto;
    currentlySelectedJurisdictionId: number;

    mapConfig: TypedActiveMapStyleDto;

    /**
     * The preferences loaded from the server that are used as the "defaults". These are used to reset the
     * working values
     */
    storedPreferences: MapPreferences = {
        bounds: undefined,
        baseStyleName: undefined,
        jurisdictions: {},
        zoom: undefined
    };

    hasZoomedToJurisdiction: boolean;

    // Variables to use for responsive layout.
    useMobileLayout: boolean;
    sidebarPosition: string;
    showMapRenderOptions = false;

    /**
     * Draw modes registered by child directives
     */
    private registeredDrawModes: { [name: string]: any } = {};

    /**
     * A concatenation of all the valid feature groups.
     */
    private featureList: FeatureGroup[] = [];

    constructor(
        public mapService: MapService,
        private fullScreenService: FullScreenService,
        private changeDetector: ChangeDetectorRef,
        private zone: NgZone,
        private angulartics: Angulartics2,
        private mapDynamicData: MapDynamicData,
        private deviceDetectorService: DeviceDetectorService,
        @Optional() private preferencesService: PreferencesService,
        private commonDialoguesService: CommonDialoguesService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {}

    /**
     * Setup all of the data event and handlers, then load the config
     */
    async ngOnInit() {
        this.refreshUseMobileLayout();
        this.setupMapEventHandlers();

        this.mapDynamicData.setJurisdictionLayerFiltering(
            this.enableJurisdictionLayerFiltering
        );
        this.mapDynamicData.setSourceFilters(this.mapSourceFilters);

        combineLatest([this.mapLoaded, this.configLoaded])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.mapReady.next());

        if (this.initialPosition == null) {
            const bounds = this.findFeatureBounds();
            this.initialView =
                bounds.isEmpty() || this.isDashboardMap
                    ? this.defaultGlobalView
                    : bounds;
            this.initialCentre = undefined;
            this.initialZoom = undefined;
        } else {
            this.initialCentre = new LngLat(
                this.initialPosition.longitude,
                this.initialPosition.latitude
            );
            this.initialZoom = this.initialPosition.zoom;
            this.initialView = undefined;
            this.mapService.initialCentre = this.initialCentre;
        }

        this.mapService.visibleJurisdictions$
            .pipe(
                startWith({ visible: [], selected: null }),
                bufferCount(2, 1),
                // A type specific `distinctUntilChanged`
                filter(
                    ([a, b]) =>
                        // bufferCount emits a single entry on completed
                        a != null &&
                        b != null &&
                        (a.selected?.id != b.selected?.id ||
                            idListsDiffer(a.visible, b.visible))
                ),
                map(([a, b]) => b),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(jurisdictions => {
                this.currentlySelectedJurisdiction = jurisdictions.selected;
                this.currentlySelectedJurisdictionId =
                    jurisdictions.selected == null
                        ? -1
                        : jurisdictions.selected.id;
                this.visibleJurisdictions = jurisdictions.visible;

                this.setLayers();
            });

        combineLatest([
            this.enabledLayerGroups$,
            this.mapDynamicData.programmaticMapConfiguration$.pipe(
                map(config => config.enabledLayerGroups)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([enabledLayerGroups, programmaticMapConfiguration]) => {
                this.allEnabledLayerGroups = [
                    ...enabledLayerGroups,
                    ...programmaticMapConfiguration
                ];
                this.setLayers();
            });

        combineLatest([
            this.mapLoaded,
            this.featureListSubject,
            this.mapDynamicData.programmaticMapConfiguration$.pipe(
                map(config => config.featureGroupList)
            )
        ]).subscribe(([mapReady, featureList, programmaticFeatureGroupList]) =>
            this.setupFeatureLayers(
                featureList.concat(programmaticFeatureGroupList)
            )
        );

        if (this.config != null) {
            this.mapService.setConfig(this.config);
        } else {
            this.mapService.setupForOrganisation(this.organisationId);
        }

        // set start and end time. The map service will handle null values
        this.mapDynamicData.setStartEndTime(this.startTime, this.endTime);
        this.mapDynamicData.setRuleset(this.ruleset);

        // Fetch the preferences, which are needed when the config is loaded next
        await this.restorePreferences();

        this.mapService.mapStyle$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(config => this.loadConfig(config));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isConfigLoaded && 'config' in changes) {
            this.mapService.setConfig(this.config);
            // } else if ('mapSourceFilters' in changes) {
            // mapsourceFilters is checked twice on changes because the call below should only happen if there's no manual config update.
            // this.mapDynamicData.setSourceFilters(this.mapSourceFilters);
        }
        if ('startTime' in changes || 'endTime' in changes) {
            // update start and end times, the map service will handle null values
            this.mapDynamicData.setStartEndTime(this.startTime, this.endTime);
        }
        if ('ruleset' in changes) {
            this.mapDynamicData.setRuleset(this.ruleset);
        }
        if ('mapSourceFilters' in changes) {
            // This call should happen every time mapSourceFilters are changed
            this.mapDynamicData.setSourceFilters(this.mapSourceFilters);
        }
        if ('enabledLayerGroups' in changes) {
            this.setLayers();
        }
        if ('airspaceJurisdictions' in changes) {
            this.mapService.setOverrideJurisdictionList(
                this.airspaceJurisdictions
            );
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();

        this.featureListSubject.complete();
        this.enabledLayerGroups$.complete();
        this.airSpaceSources.complete();
    }

    private setupMapEventHandlers() {
        this.mapService.mapEvent$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(event => {
                const createdPayload = (event as MapDrawCreatedEvent).payload;
                const updatedPayload = (event as MapDrawUpdatedEvent).payload;
                switch (event.type) {
                    case MapEventType.mouseClick:
                    case MapEventType.touchTap:
                        this.onMapClick(event.payload);
                        break;
                    case MapEventType.mouseMove:
                        this.onMapMouseMove(event.payload);
                        break;

                    case MapEventType.mapDrawSelectionChanged:
                        if (event.payload.features.length === 0) {
                            // Stop editing AFTER this selection change finishes
                            setTimeout(() => this.doneEditing());
                        }
                        // If not done editing emit this as a measurement event to prevent external component actions like airspace checks
                        this.measurementToolSelected.emit();
                        break;

                    case MapEventType.mapDrawCreated: {
                        if (
                            this.currentlyEditing != null &&
                            this.currentlyEditing.feature != null
                        ) {
                            this.updateCurrentlyEditing(createdPayload);

                            this.editFeature(this.currentlyEditing);
                        }
                        break;
                    }

                    case MapEventType.mapDrawUpdated: {
                        const payload = (event as MapDrawUpdatedEvent).payload;
                        if (
                            this.currentlyEditing != null &&
                            this.currentlyEditing.feature != null
                        ) {
                            this.updateCurrentlyEditing(updatedPayload);
                        }
                        break;
                    }
                    case MapEventType.mapMoveEnd:
                    case MapEventType.mapZoomEnd:
                        this.notifyViewportChange();
                }
            });

        this.mapService.availableTools$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(permissions => {
                this.hasMeasurementTools = permissions.hasMeasurementTools;
            });
    }

    private updateCurrentlyEditing(payload: MapDrawUpdatedEventData) {
        this.currentlyEditing.feature.geom = payload.features[0]
            .geometry as any;
        this.currentlyEditing.feature.properties =
            payload.features[0].properties;
        this.currentlyEditing.feature.children = payload.features
            .splice(1)
            .map(childFeature => ({
                geom: childFeature.geometry as any,
                id: childFeature.id,
                properties: {
                    ...childFeature.properties,
                    parent: this.currentlyEditing.feature.id
                },
                name: ''
            }));
    }

    /**
     * Apply the passed config to the map, appending any additional required layers and sources
     * at the same time.
     * @param config the config to apply
     */
    private loadConfig(config: TypedActiveMapStyleDto) {
        this.mapConfig = config;
        this.baseStyles = config.baseStyles;
        const baseStyleToLoad =
            this.baseStyle?.name ?? this.storedPreferences.baseStyleName;

        if (baseStyleToLoad != null) {
            this.baseStyle =
                config.baseStyles.find(b => b.name === baseStyleToLoad) ??
                DEFAULT_BASE_STYLE;
        } else {
            this.baseStyle = config.baseStyles[0] ?? DEFAULT_BASE_STYLE;
        }

        this.setLayers();

        this.isConfigLoaded = true;

        this.configLoaded.next();

        this.changeDetector.detectChanges();
    }

    /**
     * Set the available layers, setting the new layers and newly selected jurisdiction layers to their default visibility.
     *
     * Selected layers are based on the selected jurisdiction. The current state is stored, and is pre-seeded with the saved
     * preferences, which are in turn pre-seeded with the layer defaults.
     *
     */
    private setLayers() {
        if (this.mapConfig == null) {
            return;
        }
        const isAvailableInJurisdiction = (ids: number[]) => {
            if (this.enableJurisdictionLayerFiltering === false) {
                return true;
            }
            // Special case to have the "selected" jurisdiction layers visible, without having to adjust the visible jurisdictions
            if (ids.includes(this.currentlySelectedJurisdictionId)) {
                return true;
            }
            const isGlobal = ids == null || ids.length === 0;
            if (this.visibleJurisdictions == null) {
                return isGlobal;
            } else {
                return (
                    isGlobal ||
                    (this.visibleJurisdictions != null &&
                        ids.some(id =>
                            this.visibleJurisdictions.some(j => j.id === id)
                        ))
                );
            }
        };

        const config = {
            ...this.mapConfig,
            dataLayers: this.mapConfig.dataLayers.filter(dl =>
                isAvailableInJurisdiction(dl.airspaceJurisdictionIds)
            )
        };

        const configAirspaceSources: SourceType[] = config.dataLayers.reduce(
            (acc, l) => acc.concat(l.sources),
            []
        );

        this.airSpaceSources.next(
            jurisdictionAirspaceSources(config.jurisdictions).concat(
                configAirspaceSources
            )
        );

        const configAirspaceLayers: NamedLayer[] = extractFlattenedLayers(
            config.dataLayers,
            this.allEnabledLayerGroups.concat(this.enableLayerGroupsByDefault)
        );

        this.airSpaceLayers = jurisdictionAirspaceLayers.concat(
            configAirspaceLayers.filter(l => l.id != null)
        );

        const updatedAirSpaceLayerIds = configAirspaceLayers
            .filter(l => l.id != null)
            .map(l => l.id);

        const newAirSpaceLayerIds = diff(
            updatedAirSpaceLayerIds,
            this.airSpaceLayerIds
        );

        this.airSpaceLayerIds = updatedAirSpaceLayerIds;

        const preferences =
            this.storedPreferences.jurisdictions[
                this.currentlySelectedJurisdictionId
            ] ?? {};

        const layerToSwitchOnByDefault = config.dataLayers
            // Replace with flatMap when we get to ES2018+
            .reduce(
                (acc, dl) => acc.concat(dl.layers.groups),
                [] as LayerGroup[]
            )
            .filter(
                lg =>
                    lg.identifier != null &&
                    this.enableLayerGroupsByDefault.includes(lg.identifier)
            )
            .reduce(
                (acc, lg) => acc.concat(lg.layers.map(l => l.id)),
                [] as string[]
            );

        const newLayerIdsFilteredByPreferencesAndDefaults =
            newAirSpaceLayerIds.filter(
                l => preferences[l] || layerToSwitchOnByDefault.includes(l)
            );

        this.selectedAirSpaceLayerIds = (
            this.jurisdictionSelectedLayerIds[
                this.currentlySelectedJurisdictionId
            ] ??
            this.airSpaceLayers
                .filter(
                    l =>
                        preferences[l.id] === true ||
                        (preferences[l.id] == null &&
                            l?.metadata?.[MetadataKeyIsDefault] !== false)
                )
                .map(l => l.id)
        ).concat(newLayerIdsFilteredByPreferencesAndDefaults);

        // Check if the layer is part of the API driven layergroups. This should then override any user preferences to disable layers
        const isInEnabledLayers = (l: NamedLayer) => {
            if (l == null) {
                return false;
            }
            const dataLayer = config.dataLayers.find(
                dl =>
                    dl.layers.groups.find(
                        g => g.layers.find(layer => l.id === layer.id) != null
                    ) != null
            );
            const identifier = dataLayer?.layers.groups.find(
                g => g.layers.find(layer => l.id === layer.id) != null
            ).identifier;
            return (
                identifier != null &&
                this.allEnabledLayerGroups.includes(identifier)
            );
        };

        this.layouts = configAirspaceLayers.reduce(
            (acc, l) => ({
                ...acc,
                [l.id]: {
                    ...l.layout,
                    visibility:
                        this.selectedAirSpaceLayerIds.includes(l.id) ||
                        isInEnabledLayers(l)
                            ? 'visible'
                            : 'none'
                }
            }),
            {}
        );

        this.jurisdictionSelectedLayerIds = {
            ...this.jurisdictionSelectedLayerIds,
            [this.currentlySelectedJurisdictionId]:
                this.selectedAirSpaceLayerIds
        };

        this.airSpaceLayerNames = configAirspaceLayers.reduce(
            (acc, l) => ({ ...acc, [l.id]: l.name }),
            {}
        );

        this.layerSections = config.dataLayers.map(dl => ({
            name: dl.name,
            layerGroups: dl.layers.groups
                // Filter out layer groups with identifier from the layer selector. They are controlled via the enabledLayerGroups input.
                // enableLayerGroupsByDefault will still allow user selection
                .filter(
                    g =>
                        g.identifier == null ||
                        this.enableLayerGroupsByDefault.includes(g.identifier)
                )
                .map(g => ({ ...g, id: uuidv4() })),
            layers: dl.layers.ungrouped.filter(l => l.id != null)
        }));
        this.changeDetector.detectChanges();
    }

    get map() {
        return this.mapComponent != null ? this.mapComponent.mapInstance : null;
    }

    /**
     * Zoom to the extent of the features provided to the map.
     *
     * @param useDefaultView should the default view be used if no features exist
     */
    zoomToAllFeatures(useDefaultView: boolean) {
        if (this.map == null || this.featureList == null) {
            return false;
        }

        const bounds = this.findFeatureBounds();

        if (!bounds.isEmpty()) {
            this.map.fitBounds(bounds, { duration: 0, padding: 100 });
        } else if (useDefaultView) {
            this.zoomToDefaultView();
        }
        return true;
    }

    zoomToDefaultView() {
        if (this.storedPreferences.bounds && this.storedPreferences.zoom > 0) {
            this.map.fitBounds(this.storedPreferences.bounds, {
                duration: 0,
                padding: 100
            });
            this.map.setZoom(this.storedPreferences.zoom);
        } else {
            const defaultJurisdiction =
                this.mapService.getDefaultJurisdiction();
            if (
                defaultJurisdiction != null &&
                defaultJurisdiction.boundary != null
            ) {
                this.zoomToJurisdiction(defaultJurisdiction);
            } else {
                this.map.fitBounds(this.defaultView, {
                    duration: 0,
                    padding: 100
                });
            }
        }
    }

    zoomToJurisdiction(jurisdiction: AirspaceJurisdictionDto) {
        if (jurisdiction?.boundary == null || this.map == null) {
            return;
        }
        this.hasZoomedToJurisdiction = true;
        this.map.fitBounds(boundsOf(jurisdiction.boundary), {
            duration: 0,
            padding: 100
        });
    }

    /**
     * Sets the current jurisdiction. For this to work correctly this jurisdiction should be `===` to one
     * of the jurisdictions in the `airspaceJurisdictions` property.
     * @param jurisdiction the jurisdiction to select
     */
    setCurrentJurisdiction(jurisdiction: AirspaceJurisdictionDto) {
        this.mapService.setCurrentJurisdiction(jurisdiction);
    }

    /**
     * Zoom to the extent of the features in the named feature group.
     *
     * @param useDefaultView should the default view be used if no features exist
     */
    zoomToFeatureGroup(featureGroupId: number, useDefaultView = true) {
        if (this.map == null || this.featureList == null) {
            return;
        }

        const bounds = this.findFeatureBounds(featureGroupId);

        if (!bounds.isEmpty()) {
            this.map.fitBounds(bounds, { duration: 0, padding: 100 });
        } else if (useDefaultView) {
            this.zoomToDefaultView();
        }
    }

    /**
     * Center on and zoom to to a specific feature on the map features in the named feature group.
     *
     * @param useDefaultView should the default view be used if no features exist
     */
    zoomToFeature(
        feature: MapFeature,
        selectFeature = true,
        useDefaultView = true
    ) {
        if (
            this.map == null ||
            this.featureList == null ||
            !isValidGeometry(feature.geom)
        ) {
            return;
        }

        const bounds = boundsOf(feature.geom);

        if (!bounds.isEmpty()) {
            this.map.fitBounds(bounds, { duration: 0, padding: 100 });
        } else if (useDefaultView) {
            this.zoomToDefaultView();
        }

        if (selectFeature) {
            this.selectedPoint = bounds.getCenter();
            if (this.featurePopupTemplate != null) {
                this.popupTemplate = this.featurePopupTemplate;
            }
        }

        this.changeDetector.detectChanges();
    }

    centerToFeature(featureAndGroup: FeatureAndGroup) {
        const featureCenter = center(featureAndGroup.feature.geom).geometry
            .coordinates;

        this.map.flyTo({
            center: [featureCenter[0], featureCenter[1]],
            duration: 0
        });
    }

    onResize() {
        this.map?.resize();
    }

    findFeatureBounds(featureGroupId = null as number): mapboxgl.LngLatBounds {
        const bounds = this.featureList
            .filter(
                group => featureGroupId == null || featureGroupId === group.id
            )
            .reduce(
                (b, group) =>
                    group.existingFeatures
                        .filter(f => isValidGeometry(f.geom))
                        .reduce(
                            (bounds2, f) => bounds2.extend(boundsOf(f.geom)),
                            b
                        ),
                new mapboxgl.LngLatBounds()
            );
        return bounds;
    }

    /**
     * Synchronise the layers and features from the "features" property.
     */
    private setupFeatureLayers(featureList: FeatureGroup[]) {
        const newFeatureLayerIds: string[] = [];

        const newVisibleFeatures = {};
        this.featureList = featureList;

        this.featureLayers = featureList.reduce((acc, group) => {
            const newFeatures: (number | string)[] = [];
            group.existingFeatures.forEach(f => {
                if (isValidGeometry(f.geom)) {
                    newFeatures.push(f.id);
                }
                if (f.children != null) {
                    f.children.forEach(c => {
                        newFeatures.push(c.id);
                    });
                }
            });
            newVisibleFeatures[group.id] = new Set<number | string>(
                newFeatures
            );

            const featureLayerId = calculateSourceId(group);
            const newLayers = createLayers(featureLayerId, group);
            newLayers.forEach(l => newFeatureLayerIds.push(l.id));

            return acc.concat(newLayers);
        }, []);

        this.visibleFeatures = newVisibleFeatures;
        this.featureLayerIds = newFeatureLayerIds;

        // We calculate the source last so that the correct visible layers are selected when we populate its data.
        // Note, this is fed to the template to render, so the order of operations does not matter
        this.featureSources = featureList.map(group => {
            const { sourceData } = this.calculateData(group.id);
            return {
                ...createGeoJsonSource(group),
                id: calculateSourceId(group),
                data: sourceData
            };
        });

        this.areFeaturesLoaded = true;
        this.featuresLoaded.next();

        this.changeDetector.detectChanges();
    }

    private calculateData(groupId: number) {
        const group = this.featureList.find(g => g.id === groupId);
        if (group == null) {
            return {};
        }

        const sourceId = calculateSourceId(group);
        const visibleFeatures = this.visibleFeatures[groupId] || new Set();
        return {
            sourceId,
            sourceData: {
                type: 'FeatureCollection',
                features: getFeatures(
                    group.existingFeatures,
                    visibleFeatures,
                    groupId
                )
            } as FeatureCollection
        };
    }

    /**
     * Recalculate the data for a specific layer, typically to hide/show an individual
     * feature.
     * @param groupId the group to update
     */
    private redrawLayer(groupId: number) {
        const { sourceId, sourceData } = this.calculateData(groupId);
        if (sourceId == null) {
            return;
        }

        if (sourceId.startsWith('mapbox-gl-draw')) {
            this.draw.add(sourceData);
        } else {
            const source = this.map.getSource(sourceId);
            if (source != null) {
                this.zone.runOutsideAngular(() =>
                    (source as mapboxgl.GeoJSONSource).setData(sourceData)
                );
            }
        }
    }

    mapZoomIn() {
        this.map.zoomIn();
    }

    mapZoomOut() {
        this.map.zoomOut();
    }

    setDefaultMap() {
        this.map.flyTo({
            center: [DEFAULT_LNG, DEFAULT_LAT],
            zoom: DEFAULT_ZOOM_LEVEL
        });
    }

    onShowLayer(layerId: string) {
        if (this.map == null) {
            console.warn('map is null');
            return;
        }
        if (this.map.getLayer(layerId)) {
            if (this.selectedAirSpaceLayerIds.indexOf(layerId) === -1) {
                this.selectedAirSpaceLayerIds =
                    this.selectedAirSpaceLayerIds.concat(layerId);
                this.jurisdictionSelectedLayerIds[
                    this.currentlySelectedJurisdictionId
                ] = this.selectedAirSpaceLayerIds;
            }
            this.layouts[layerId] = {
                ...this.layouts[layerId],
                visibility: 'visible'
            };
            this.mapDynamicData.onLayersUpdated();
        } else {
            console.warn(`Could not find layer with id ${layerId}`);
        }
    }

    onHideLayer(layerId: string) {
        if (this.map == null) {
            return;
        }
        if (this.map.getLayer(layerId)) {
            this.selectedAirSpaceLayerIds =
                this.selectedAirSpaceLayerIds.filter(l => l !== layerId);
            this.jurisdictionSelectedLayerIds[
                this.currentlySelectedJurisdictionId
            ] = this.selectedAirSpaceLayerIds;

            this.layouts[layerId] = {
                ...this.layouts[layerId],
                visibility: 'none'
            };
            this.mapDynamicData.onLayersUpdated();
        } else {
            console.warn(`Could not find layer with id ${layerId}`);
        }
    }

    onMapLoad(loadedMap: mapboxgl.Map) {
        this.mapService.map = loadedMap;

        this.setupDrawingTool();
        // this.setSelectedMapFeature(null, null);

        loadedMap.on('wheel', e => {
            if (
                this.requireCtrlToZoom &&
                !this.fullScreenService.isFullScreen()
            ) {
                if (e.originalEvent.ctrlKey) {
                    this.hideOverlay();
                    return;
                }
                this.showOverlay();
                e.preventDefault();
            }
        });

        // need to wait for config
        this.mapLoaded.next();
        this.notifyViewportChange();
    }

    private setupDrawingTool() {
        this.draw = new MapboxDraw({
            controls: {
                trash: false
            },
            displayControlsDefault: false,
            userProperties: true,
            styles: drawStyles,
            modes: {
                ...MapboxDraw.modes,
                ...this.registeredDrawModes,
                draw_area_line: DrawAreaLine,
                draw_area_line_select: DrawAreaLineSelect,
                draw_line_string: DrawLine,
                draw_polygon: DrawPolygon
            }
        });

        this.map.addControl(this.draw);
        this.mapService.draw = this.draw;
    }

    registerDrawModes(modes: { [name: string]: any }) {
        if (this.draw != null) {
            throw new Error(`Draw already initialised. Can't add new modes`);
        }
        this.registeredDrawModes = { ...this.registeredDrawModes, ...modes };
    }

    onGeocodeResultSelected({ result }: any) {
        this.geocodeResultSelected.next({
            id: null,
            name: result.text,
            geom: result.geometry
        });
    }

    private getRenderedTemplate(
        template: string,
        properties: { [key: string]: string }
    ): string {
        if (!template || template === '') {
            return '';
        }
        const tokenRegex = /{{([^}]+)}}/g;
        let curMatch: RegExpExecArray;
        let rendered = template;
        while ((curMatch = tokenRegex.exec(template))) {
            const tokenRaw = curMatch[0];
            const propertyName = curMatch[1].trim();
            rendered = rendered.replace(
                tokenRaw,
                properties[propertyName] ?? ''
            );
        }

        return rendered;
    }

    private onMapClick($event: mapboxgl.MapMouseEvent) {
        this.mapClick.next($event.lngLat);

        if (
            this.draw.getMode() !== 'simple_select' ||
            this.mapService.activeInteraction ===
                MapInteractionState.MeasurementLine
        ) {
            // Emitting measurement tool selected to prevent other component actions like airspace checks
            this.measurementToolSelected.next();
            // click is handled in map-measurement-interaction.directive.ts
            // below click events can be handled in a similar way
            this.selectedPoint = null;
            this.setSelectedMapFeature(null, null);
            this.changeDetector.detectChanges();
            return;
        }

        if (
            this.draw.getMode() !== 'simple_select' ||
            this.mapService.activeInteraction ===
                MapInteractionState.MeasurementRadius
        ) {
            // click is handled in map-measurement-interaction.directive.ts
            // Emitting measurement tool selected to prevent other component actions like airspace checks
            this.measurementToolSelected.next();
        }

        this.rawFeaturesClick.next(
            this.map.queryRenderedFeatures($event.point)
        );

        if (this.areFeaturesLoaded) {
            // Set `bbox` as 5px rectangle area around clicked point.
            // this makes it easier to click on e.g. a line
            const bbox = [
                [$event.point.x - 5, $event.point.y - 5],
                [$event.point.x + 5, $event.point.y + 5]
            ] as [PointLike, PointLike];

            const layersToIgnore = (this.ignoreClicksOnFeatureGroups ?? [])
                .map(id => this.featureList.find(g => g.id === id))
                .map(g => createLayers(calculateSourceId(g), g))
                .reduce(
                    (acc, layers) => acc.concat(layers.map(l => l.id)),
                    [] as string[]
                );

            const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
                layers: this.featureLayerIds.filter(
                    lId => !layersToIgnore.includes(lId)
                )
            });
            if (selectedFeatures.length > 0) {
                this.processSelectedFeatures(selectedFeatures, $event);

                this.changeDetector.detectChanges();
                return;
            }
        }

        // no features loaded or clicked, check for airspace features
        const selectedAirSpaceFeatures = this.map.queryRenderedFeatures(
            $event.point,
            {
                layers: this.airSpaceLayerIds
            }
        );
        this.selectedAirspaceFeatures = this.getUniqueFeatures(
            selectedAirSpaceFeatures
        );

        if (this.selectedAirspaceFeatures.length > 0) {
            this.selectedPoint = $event.lngLat;
            this.popupTemplate = this.airspaceLayerListTemplate;
            this.setSelectedMapFeature(null, null);

            this.changeDetector.detectChanges();
            return;
        }

        this.selectedPoint = null;
        this.setSelectedMapFeature(null, null);
        this.changeDetector.detectChanges();
    }

    private processSelectedFeatures(
        selectedFeatures: mapboxgl.MapboxGeoJSONFeature[],
        $event: mapboxgl.MapMouseEvent
    ) {
        if (selectedFeatures[0].properties.cluster) {
            const hasHandler = this.featureClusterPopupTemplate != null;
            this.popupTemplate = hasHandler
                ? this.featureClusterPopupTemplate
                : null;
            this.selectedPoint = hasHandler ? $event.lngLat : null;

            const featureHandler = hasHandler
                ? (features: Feature<Geometry>[]) =>
                      this.notifyFeatures(selectedFeatures[0].source, features)
                : (features: Feature<Geometry>[]) =>
                      this.zoomToFeatures(features);

            const source = this.map.getSource(
                selectedFeatures[0].source
            ) as mapboxgl.GeoJSONSource;
            const { cluster_id, point_count } = selectedFeatures[0].properties;
            source.getClusterLeaves(
                cluster_id,
                point_count,
                0,
                (err, features) => {
                    featureHandler(features);
                }
            );
        } else {
            if (this.isEditing) {
                this.emitCurrentlyEditing();
            }

            if (this.featurePopupTemplate != null) {
                this.popupTemplate = this.featurePopupTemplate;
                this.selectedPoint = $event.lngLat;
            }
            const id =
                <number>selectedFeatures[0].id ??
                selectedFeatures[0].properties.parent;

            this.setSelectedMapFeature(id, selectedFeatures[0].source);
        }
    }

    private getUniqueFeatures(
        selectedAirSpaceFeatures: mapboxgl.MapboxGeoJSONFeature[]
    ) {
        const unique: mapboxgl.MapboxGeoJSONFeature[] = [];
        selectedAirSpaceFeatures.forEach(feat => {
            const airSpaceLayer = this.airSpaceLayers.find(
                l => l.id === feat.layer.id
            );
            const template = airSpaceLayer.metadata
                ? airSpaceLayer.metadata[MetadataKeyTemplateName]
                : '';

            const properties = Object.keys(feat.state).reduce(
                (acc, key) => ({ ...acc, [`state.${key}`]: feat.state[key] }),
                feat.properties
            );

            const renderedTemplate = this.getRenderedTemplate(
                template,
                properties
            );
            const existing = unique.find(
                e => e.layer.id === feat.layer.id && e.id === feat.id
            );

            // only add features with either a renderedTemplate, or if source is airspace or flyfreely
            // i.e. when they are rendered in airspaceLayerListTemplate

            if (
                feat.layer.source === 'airspace' ||
                feat.layer.source === 'flyfreely' ||
                renderedTemplate
            ) {
                if (!existing) {
                    feat.properties.renderedTemplate = renderedTemplate;
                    unique.push(feat);
                }
            } else {
                console.log(
                    'ignoring feature as it will not be rendered',
                    feat
                );
            }
        });
        return unique;
    }

    private notifyFeatures(sourceId: string, features: Feature<Geometry>[]) {
        const group = this.featureList.find(
            g => calculateSourceId(g) === sourceId
        );
        if (group === undefined) {
            return;
        }
        this.featureClusterSelected.next(
            calculateClusterGroupAndFeatures(group, features)
        );
    }

    private onMapMouseMove($event: mapboxgl.MapMouseEvent) {
        this.currentMouseLocation = $event.lngLat;
        this.findJurisdictionAtMousePosition();
        this.changeDetector.markForCheck();
    }

    private findJurisdictionAtMousePosition() {
        if (this.airspaceJurisdictions == null) {
            this.currentJurisdictionAtMousePosition = null;
            return;
        }
        const location = [
            this.currentMouseLocation.lng,
            this.currentMouseLocation.lat
        ];
        this.currentJurisdictionAtMousePosition =
            this.airspaceJurisdictions.find(f =>
                booleanPointInPolygon(
                    point(location),
                    feature(f.boundary as Polygon | MultiPolygon)
                )
            ) ?? null;
    }

    private zoomToFeatures(features: Feature<Geometry>[]) {
        const bounds = features.reduce(
            (b, group) => b.extend(boundsOf(group.geometry)),
            new mapboxgl.LngLatBounds()
        );

        if (!bounds.isEmpty()) {
            this.map.fitBounds(bounds, { duration: 500, padding: 100 });
        }
    }

    private setSelectedMapFeature(
        selectedFeatureId: number,
        selectedSourceId: string
    ) {
        this.featureList.forEach(group => {
            const sourceId = calculateSourceId(group);
            group.existingFeatures.forEach(feature => {
                const state = this.map.getFeatureState({
                    id: feature.id,
                    source: sourceId
                });

                const requiredValue =
                    selectedFeatureId === feature.id &&
                    selectedSourceId === sourceId;

                if (state.selected !== requiredValue) {
                    this.map.setFeatureState(
                        { id: feature.id, source: sourceId },
                        { selected: requiredValue }
                    );
                }
                if (requiredValue) {
                    this.featureSelected.next({ groupId: group.id, feature });
                }
            });
        });

        if (selectedFeatureId == null && selectedSourceId == null) {
            this.featureUnselected.next();
        }
    }

    showOverlay() {
        this.showScrollOverlay = true;
        if (this.overlayTimeout != null) {
            window.clearTimeout(this.overlayTimeout);
        }
        this.overlayTimeout = window.setTimeout(() => {
            this.showScrollOverlay = false;
            this.overlayTimeout = null;

            this.changeDetector.detectChanges();
        }, 3000);

        this.changeDetector.detectChanges();
    }

    hideOverlay() {
        if (this.showScrollOverlay === false) {
            return;
        }
        this.showScrollOverlay = false;
        window.clearTimeout(this.overlayTimeout);
        this.overlayTimeout = null;
        this.changeDetector.detectChanges();
    }

    setBaseStyle(baseStyle: BaseStyleDto) {
        this.areStylesLoaded = false;
        this.baseStyle = baseStyle;
    }

    showFeature(feature: FeatureAndGroup, forceUpdate = false) {
        if (this.visibleFeatures[feature.groupId] == null) {
            this.visibleFeatures[feature.groupId] = new Set();
        }
        if (this.visibleFeatures[feature.groupId].has(feature.feature.id)) {
            if (!forceUpdate) {
                return;
            }
        } else {
            this.visibleFeatures[feature.groupId].add(feature.feature.id);
        }
        this.visibleFeatures = { ...this.visibleFeatures };
        this.redrawLayer(feature.groupId);
    }

    hideFeature(event: FeatureAndGroup) {
        if (this.visibleFeatures[event.groupId] == null) {
            this.visibleFeatures[event.groupId] = new Set();
        }
        if (!this.visibleFeatures[event.groupId].has(event.feature.id)) {
            return;
        }
        this.visibleFeatures[event.groupId].delete(event.feature.id);
        this.visibleFeatures = { ...this.visibleFeatures };
        this.redrawLayer(event.groupId);
    }

    highlightFeature(groupId: number, featureId: number) {
        if (this.map != null) {
            this.map.setFeatureState(
                { source: calculateSourceIdFromId(groupId), id: featureId },
                { highlight: true }
            );
        }
    }

    unhighlightFeature(groupId: number, featureId: number) {
        if (this.map != null) {
            this.map.setFeatureState(
                { source: calculateSourceIdFromId(groupId), id: featureId },
                { highlight: false }
            );
        }
    }

    onActivateFeature(event: FeatureAndGroup) {
        const { geom } = event.feature;
        this.map.fitBounds(boundsOf(geom));
    }

    get isMapReady() {
        return this.map != null && this.map.loaded;
    }

    get isMapAndStylesReady() {
        return this.map != null && this.map.loaded && this.areStylesLoaded;
    }

    get areMapFeaturesReady() {
        return this.map != null && this.map.loaded && this.areFeaturesLoaded;
    }

    get isEditing() {
        return this.currentlyEditing != null;
    }

    get isDrawingReady() {
        return this.areMapFeaturesReady && this.draw != null;
    }

    public hidePopup() {
        this.selectedPoint = null;
    }

    public showPopup() {
        this.selectedPoint = this.currentMouseLocation;
        if (this.featurePopupTemplate != null) {
            this.popupTemplate = this.featurePopupTemplate;
        }
    }

    /**
     * Begin editing a feature, discarding any existing edit function. The properties of the provided
     * feature are copied to the draw instance, and can be used to set the 'icon-image' property.
     *
     * @param featureAndGroup the compound object containing the feature and its group identifier
     */
    editFeature(
        featureAndGroup: FeatureAndGroup,
        additionalProperties?: GeoJsonProperties
    ) {
        this.editModeChanged.next(true);

        this.cleanupCurrentEdit();

        this.currentlyEditing = { ...featureAndGroup };

        if (
            featureAndGroup.feature == null ||
            featureAndGroup.feature.geom == null
        ) {
            this.draw.changeMode('draw_polygon');
            return;
        }

        const group = this.featureList.find(
            g => g.id === featureAndGroup.groupId
        );

        if (!isValidGeometry(featureAndGroup.feature.geom)) {
            switch (group?.type) {
                case 'Point':
                    this.draw.changeMode('draw_point');
                    return;
                case 'Polygon':
                    this.draw.changeMode('draw_polygon');
                    return;
                case 'LineString':
                    this.draw.changeMode('draw_line_string');
                    return;
                case 'AreaLineString':
                    this.draw.changeMode(MapModes.DRAW_AREA_LINE);
                    return;
            }
            return;
        }

        if (group.type === 'AreaLineString') {
            const linestring = (<GeometryCollection>(
                featureAndGroup.feature.geom
            )).geometries.find(g => g.type === 'LineString');
            // Create the draw area line features
            const drawFeatures = this.draw.add({
                id: featureAndGroup.feature.id,
                type: 'Feature',
                geometry: linestring,
                properties: {
                    ...featureAndGroup.feature.properties,
                    ...additionalProperties
                }
            });
            this.draw.changeMode(MapModes.DRAW_AREA_LINE_SELECT, {
                featureId: drawFeatures[0]
            });
        } else if (featureAndGroup.feature.geom.type === 'Point') {
            const drawFeatures = this.draw.add({
                id: featureAndGroup.feature.id,
                type: 'Feature',
                geometry: featureAndGroup.feature.geom,
                properties: {
                    ...featureAndGroup.feature.properties,
                    ...additionalProperties
                }
            });

            this.draw.changeMode('simple_select', {
                featureIds: [drawFeatures[0]]
            });
        } else {
            const drawFeatures = this.draw.add({
                id: featureAndGroup.feature.id,
                type: 'Feature',
                geometry: featureAndGroup.feature.geom,
                properties: {
                    ...featureAndGroup.feature.properties,
                    ...additionalProperties
                }
            });
            this.draw.changeMode('direct_select', {
                featureId: drawFeatures[0]
            });
        }
        this.hideFeature(featureAndGroup);
        this.changeDetector.detectChanges();
    }

    /**
     * Remove the current feature and continue editing.
     */
    clearEditing() {
        if (!this.isEditing) {
            return;
        }

        this.draw.deleteAll();
        this.draw.changeMode('draw_polygon');
    }

    stopEditing() {
        if (!this.isEditing) {
            return;
        }

        this.cleanupCurrentEdit();
        this.editModeChanged.next(false);
    }

    private cleanupCurrentEdit() {
        this.draw?.deleteAll();
        if (this.currentlyEditing?.feature != null) {
            this.showFeature(this.currentlyEditing);
        }
        this.currentlyEditing = null;
    }

    doneEditing() {
        if (!this.isEditing) {
            return;
        }

        this.emitCurrentlyEditing();

        this.stopEditing();
    }

    private emitCurrentlyEditing() {
        // const { features }: { features: any[] } = this.draw.getAll();

        // if (!features || features.length === 0) {
        //     return;
        // }

        let jurisdiction: SimpleAirspaceJurisdictionDto = undefined;
        try {
            const jurisdictionCentroid = centroid(
                this.currentlyEditing.feature.geom
            );
            jurisdiction = this.mapService.findJurisdictionAt(
                <[number, number]>jurisdictionCentroid.geometry.coordinates
            );
        } catch (err) {
            // e.g. if it doesn't contain coordinates
            console.warn(err);
            return;
        }

        if (this.currentlyEditing.feature != null) {
            const featureGroup: FeatureAndGroupWithJurisdiction = {
                groupId: this.currentlyEditing.groupId,
                feature: {
                    id: this.currentlyEditing.feature.id,
                    name: this.currentlyEditing.feature.name,
                    geom: this.currentlyEditing.feature.geom,
                    properties: this.currentlyEditing.feature.properties,
                    categoryId: this.currentlyEditing.feature.categoryId
                },
                jurisdiction
            };
            this.featuresUpdated.next(featureGroup);
        } else {
            const featureGroup: FeatureAndGroupWithJurisdiction = {
                groupId: this.currentlyEditing.groupId,
                feature: {
                    id: null,
                    name: null,
                    geom: this.currentlyEditing.feature.geom,
                    properties: null,
                    categoryId: null
                },
                jurisdiction
            };

            this.featuresUpdated.next(featureGroup);
        }
    }

    toggleFullScreen() {
        this.fullscreenRequested.emit();
        this.angulartics.eventTrack.next({
            action: 'fullscreen',
            properties: {
                category: 'map',
                label: this?.screenAnalytics?.screenAnalytics
            }
        });
    }

    isFullScreenSupported() {
        return this.fullScreenService.isFullScreenSupported;
    }

    /**
     * Uses the device detector service to find the current device on load.
     * NOTE: This does not dynamically refresh, and so the styles associated with mobile will remain on window resize.
     * For this reason it us used sparingly.
     */
    refreshUseMobileLayout() {
        this.useMobileLayout =
            this.deviceDetectorService.isMobile() ||
            (this.deviceDetectorService.isTablet() &&
                this.deviceDetectorService.orientation.toLowerCase() ===
                    'portrait');
        this.sidebarPosition = this.useMobileLayout
            ? 'bottom-left'
            : 'top-right';
    }

    private notifyViewportChange() {
        const { lat, lng } = this.map.getCenter();
        const zoom = this.map.getZoom();

        this.currentMapLocation = {
            latitude: lat,
            longitude: lng,
            zoom
        };

        this.viewportChange.emit(this.currentMapLocation);
    }

    async saveClick() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Save preferences',
                `Pressing OK will update your default settings with the following:
                <ul>
                    <li>Current Map Base Layer</li>
                    <li>Current Zoom Level</li>
                    <li>Current Map Position</li>
                    <li>Current Layer Selection</li>
                </ul>`,
                'OK',
                () => Promise.resolve()
            )
            .then(async () => {
                this.savePreferences();
            }, DO_NOTHING);
    }

    private async savePreferences() {
        if (this.preferencesService == null) {
            return;
        }

        const bounds = this.map.getBounds();
        const visibleLayers: string[] = [];
        for (const [key, value] of Object.entries(this.layouts)) {
            if (value.visibility === 'visible') {
                visibleLayers.push(key);
            }
        }

        const jurisdictionLayers = Object.keys(
            this.jurisdictionSelectedLayerIds
        ).reduce(
            (acc, jurisdiction) => ({
                ...acc,
                [jurisdiction]: this.airSpaceLayerIds.reduce(
                    (acc2, lId) => ({
                        ...acc2,
                        [lId]: this.jurisdictionSelectedLayerIds[
                            jurisdiction
                        ].includes(lId)
                    }),
                    {}
                )
            }),
            {} as { [jurisdictionId: string]: { [layerId: string]: boolean } }
        );

        await firstValueFrom(
            this.preferencesService.updatePreferences(
                FLYFREELY_MAP_COMPONENT,
                null,
                {
                    mapPreferences: {
                        version: 2,
                        boundsNe: [
                            bounds.getNorthEast().lng,
                            bounds.getNorthEast().lat
                        ],
                        boundsSw: [
                            bounds.getSouthWest().lng,
                            bounds.getSouthWest().lat
                        ],
                        baseStyleName: this.baseStyle.name,
                        zoom: this.map.getZoom(),
                        jurisdictions: jurisdictionLayers
                    } as MapPreferencesV2
                }
            )
        );
    }

    private async restorePreferences() {
        if (this.preferencesService == null) {
            return;
        }
        try {
            const preferences = (await firstValueFrom(
                this.preferencesService
                    .findPreferencesAsOption(FLYFREELY_MAP_COMPONENT, null)
                    .pipe(
                        map(
                            p =>
                                getOrElse(() => ({
                                    mapPreferences: null
                                }))(p).mapPreferences
                        )
                    )
            )) as MapPreferencesDto;

            const boundsSw = new mapboxgl.LngLat(
                preferences.boundsSw[0],
                preferences.boundsSw[1]
            );
            const boundsNe = new mapboxgl.LngLat(
                preferences.boundsNe[0],
                preferences.boundsNe[1]
            );
            this.storedPreferences.bounds = new mapboxgl.LngLatBounds(
                boundsSw,
                boundsNe
            );
            this.storedPreferences.baseStyleName = preferences.baseStyleName;
            if (isMapPreferencesV1(preferences)) {
                this.storedPreferences.jurisdictions = {};
            } else {
                this.storedPreferences.jurisdictions =
                    preferences.jurisdictions;
            }

            this.storedPreferences.zoom = preferences.zoom;
        } catch (e) {
            console.warn('restoring preferences failed', e);
        }
    }

    /**
     * Update the data for a GeoJSON source that is provided in the map styles.
     *
     * @param sourceId the source id from the styles
     * @param data the updated data
     */
    updateGeoJSONSource(sourceId: string, data: GeoJSON.FeatureCollection) {
        const source = this.map.getSource(sourceId);
        if (source != null) {
            this.zone.runOutsideAngular(() =>
                (source as mapboxgl.GeoJSONSource).setData(data)
            );
        } else {
            console.log(`Source not available ${sourceId}`);
        }
    }

    localGeocoder = spacialGeocoder;

    trackById = trackById;
}
