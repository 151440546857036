/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.7-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface CreateHistoricalFlightCommand { 
    /**
     * The ID of the pilot on this flight
     */
    pilotId: number;
    /**
     * The organisation that is managing this user
     */
    managingOrganisationId?: number;
    /**
     * The date of the flight
     */
    flightDate: string;
    /**
     * ${swagger.HistoricalFlight.missionTypeId}
     */
    missionTypeId?: number;
    /**
     * The RPA model that the flights relate to. Null if generic
     */
    rpaTypeId?: number;
    /**
     * The RPA type classification
     */
    rpaCategory?: CreateHistoricalFlightCommand.RpaCategory;
    /**
     * The RPA maximum take off weight
     */
    maxTakeOffWeight?: number;
    /**
     * The name of the location where the flights took place
     */
    locationName: string;
    /**
     * The visual line of sight of operation
     */
    visualLineOfSight: CreateHistoricalFlightCommand.VisualLineOfSight;
    /**
     * The operation time of the day
     */
    timeOfDay: CreateHistoricalFlightCommand.TimeOfDay;
    /**
     * The total flight time in seconds
     */
    duration: number;
    /**
     * The total number of flights for this combination of pilot and RPA in this mission
     */
    numberOfFlights: number;
    /**
     * Is this a simulated flight
     */
    simulated: boolean;
}
export namespace CreateHistoricalFlightCommand {
    export type RpaCategory = 'MULTIROTOR' | 'HELICOPTOR' | 'AEROPLANE' | 'POWERED_LIFT' | 'AIRSHIP' | 'OTHER';
    export const RpaCategory = {
        MULTIROTOR: 'MULTIROTOR' as RpaCategory,
        HELICOPTOR: 'HELICOPTOR' as RpaCategory,
        AEROPLANE: 'AEROPLANE' as RpaCategory,
        POWERED_LIFT: 'POWERED_LIFT' as RpaCategory,
        AIRSHIP: 'AIRSHIP' as RpaCategory,
        OTHER: 'OTHER' as RpaCategory
    };
    export type VisualLineOfSight = 'VLOS' | 'EVLOS' | 'BVLOS';
    export const VisualLineOfSight = {
        VLOS: 'VLOS' as VisualLineOfSight,
        EVLOS: 'EVLOS' as VisualLineOfSight,
        BVLOS: 'BVLOS' as VisualLineOfSight
    };
    export type TimeOfDay = 'DAY' | 'NIGHT' | 'DAY_NIGHT';
    export const TimeOfDay = {
        DAY: 'DAY' as TimeOfDay,
        NIGHT: 'NIGHT' as TimeOfDay,
        DAY_NIGHT: 'DAY_NIGHT' as TimeOfDay
    };
}



