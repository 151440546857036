/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.45-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LicenceDto } from './licenceDto';
import { SubscriptionLimitDto } from './subscriptionLimitDto';
import { SimpleFeatureSetDto } from './simpleFeatureSetDto';


/**
 * A subscription that is applied to an organisation
 */
export interface OrganisationSubscriptionDto { 
    /**
     * The ID of this subscription
     */
    id: number;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    organisationId: number;
    name?: string;
    /**
     * The date this subscription starts
     */
    startTime: string;
    /**
     * The end date of the subscription. Null if it has no defined end date
     */
    endTime?: string;
    /**
     * ${swagger.Subscription.trialStartTime}
     */
    trialStartTime?: string;
    /**
     * ${swagger.Subscription.trialEndTime}
     */
    trialEndTime?: string;
    externalStatus?: string;
    externalCollectionMethod?: string;
    featureSet: SimpleFeatureSetDto;
    /**
     * Does this have a Stripe subscription
     */
    externalIdentifier?: string;
    /**
     * The identifier of the plan/price in the external payment system
     */
    externalPlanIdentifier?: string;
    /**
     * Is this a shared subscription
     */
    parentSubscriptionId?: number;
    limits: Array<SubscriptionLimitDto>;
    activeLicences: Array<LicenceDto>;
    trialUpStartTime?: string;
    trialUpEndTime?: string;
    trialUpFeatureSet?: SimpleFeatureSetDto;
    availableTrialUp?: SimpleFeatureSetDto;
    status: OrganisationSubscriptionDto.Status;
    subscriptionType: OrganisationSubscriptionDto.SubscriptionType;
    isTrialing: boolean;
}
export namespace OrganisationSubscriptionDto {
    export type Status = 'ACTIVE' | 'PENDING_PAYMENT' | 'INACTIVE';
    export const Status = {
        ACTIVE: 'ACTIVE' as Status,
        PENDING_PAYMENT: 'PENDING_PAYMENT' as Status,
        INACTIVE: 'INACTIVE' as Status
    };
    export type SubscriptionType = 'STRIPE' | 'MANUAL' | 'SHARED';
    export const SubscriptionType = {
        STRIPE: 'STRIPE' as SubscriptionType,
        MANUAL: 'MANUAL' as SubscriptionType,
        SHARED: 'SHARED' as SubscriptionType
    };
}



