import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RuleCodeDto } from '@flyfreely-portal-ui/flyfreely';
@Component({
    selector: 'airspace-rule-message-dialogue',
    template: `
        <div class="container-with-footer rule-message-container">
            <div class="vertical-container fill" *ngIf="message">
                <h4 class="top-buffer bottom-buffer">
                    Operating Rule &nbsp;{{ message.ruleCode }}
                </h4>
                <div class="container-scroll airspace-message">
                    {{ message.flyFreelyDescription }}
                </div>
            </div>
            <div class="vertical-container" *ngIf="advisoryMessage">
                <h4 class="top-buffer bottom-buffer">Advisory Message</h4>
                <div class="container-scroll airspace-message">
                    {{ advisoryMessage }}
                </div>
            </div>

            <div class="action-container bottom-buffer centered-button">
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        advise: resultType == 'ADVISE',
                        block: resultType == 'BLOCK',
                        pass: resultType == 'PASS'
                    }"
                    (click)="done.emit()"
                >
                    Done
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./styles.scss']
})
export class AirspaceRuleMessageDialogue {
    @Input() message: RuleCodeDto;
    @Input() advisoryMessage: string;
    @Input() resultType: 'BLOCK' | 'ADVISE' | 'PASS';
    @Output() done = new EventEmitter<void>();
}
