import { Component, Input } from '@angular/core';

@Component({
    selector: 'completed-outstanding-tasks-banner',
    template: `
        <div class="panel-heading-success">
            <div class="panel-title no-uppercase">
                <span class="panel-check-icon fal fa-check-circle"></span
                ><span class="panel-heading-text">{{ widgetHeading }}</span>
            </div>
            <div class="panel-content"></div>
        </div>
    `,
    styles: [
        `
            .panel-heading-success {
                background-color: #30d455 !important;
                line-height: 1.5rem;
                height: 40px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
                border-radius: 0;
                border: none;
                margin: 0.3rem 0;
                z-index: 100;
            }

            .panel-title {
                color: #000 !important;
            }

            .panel-check-icon {
                font-size: 1.4rem;
                position: absolute;
            }

            .panel-heading-text {
                margin-left: 1.7rem;
            }
        `
    ]
})
export class CompletedOutstandingTasksBannerComponent {
    @Input() widgetHeading: string;
}
