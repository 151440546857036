import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {
    AerodromeDetailsDto,
    Reason,
    RuleCodeDto,
    RuleOutcome
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { InlineModal } from 'libs/inline-modal/src/lib/inline-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ruleOutcomeReasonTypeValue } from '../airspace-check/airspace-check.service';
import { AirspaceRuleMessageDialogue } from './rule-message/airspace-rule-message-dialogue.component';

enum Mode {
    BLOCK = 'BLOCK',
    ADVISE = 'ADVISE',
    PASS = 'PASS'
}

@Component({
    selector: 'airspace-rule-details-dialogue',
    templateUrl: './airspace-rule-details-dialogue.component.html',
    styleUrls: ['./styles.scss']
})
export class AirspaceRuleDetailsDialogue {
    @Input() aerodromes: { [code: string]: AerodromeDetailsDto };
    @Input() ruleOutcomes: RuleOutcome[];
    @Input() message: RuleCodeDto;
    @Input() inline: boolean = false;
    @Output() done = new EventEmitter<void>();

    modalTitle: string;
    mode: Mode = Mode.ADVISE;

    @ViewChild('inlineModal', { static: true }) inlineModal: InlineModal;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private angulartics2: Angulartics2,
        private modal: BsModalRef
    ) {}

    ngOnInit() {
        this.modalTitle = this.findModalTitle();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findModalTitle() {
        switch (this.ruleOutcomes[0]?.outcome) {
            case RuleOutcome.Outcome.BLOCK:
                this.mode = Mode.BLOCK;
                return this.message?.blockMessage;
            case RuleOutcome.Outcome.ADVISE:
                this.mode = Mode.ADVISE;
                return this.message?.adviseMessage;
            case RuleOutcome.Outcome.PASS:
                this.mode = Mode.PASS;
                return this.message?.passMessage;
            default:
                return this.message?.name;
        }
    }

    returnRuleType(rule: RuleOutcome) {
        return ruleOutcomeReasonTypeValue[
            rule.reason[0]?.type ?? Reason.Type.UNIDENTIFIED
        ];
    }

    showAdvisoryMessage(message: string) {
        this.angulartics2.eventTrack.next({
            action: 'view-advisory',
            properties: {
                category: 'airspace-check'
            }
        });

        const { component, onDestroy } = this.inlineModal.showComponent(
            AirspaceRuleMessageDialogue,
            {
                advisoryMessage: message,
                resultType: this.mode
            }
        );

        component.done
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(onDestroy))
            .subscribe(() => {
                this.inlineModal.closeComponent();
            });
    }

    viewRule() {
        this.angulartics2.eventTrack.next({
            action: 'view-rule',
            properties: {
                category: 'airspace-check'
            }
        });

        const { component, onDestroy } = this.inlineModal.showComponent(
            AirspaceRuleMessageDialogue,
            {
                message: this.message,
                resultType: this.mode
            }
        );

        component.done
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(onDestroy))
            .subscribe(() => {
                this.inlineModal.closeComponent();
            });
    }

    hasReasons() {
        return this.ruleOutcomes?.filter(o => o.reason != null)?.length > 0;
    }

    close() {
        if (!this.inline) {
            this.modal.hide();
        } else {
            this.done.emit();
        }
    }
}
