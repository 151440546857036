import {
    Directive,
    Input,
    ElementRef,
    Renderer2
} from '@angular/core';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';

/**
 * Sets the background-image to a path that is relative to the site.
 */
@Directive({
    selector: 'div[imgPath]'
})
export class DivImageSourceDirective {
    @Input() imgPath: string;
    public pathPrefix: string;

    constructor(
        constants: FlyFreelyConstants,
        private renderer: Renderer2,
        private elRef: ElementRef
    ) {
        this.pathPrefix = constants.IMG_URL;
    }

    ngOnInit() {
        this.renderer.setStyle(
            this.elRef.nativeElement,
            'background-image',
            `url(${this.pathPrefix + this.imgPath})`
        );
    }
}
