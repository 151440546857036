/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.60-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Used to create a person inside of an organisation
 */
export interface CreatePersonOrganisationCommand { 
    /**
     * The email address the person will use
     */
    email: string;
    /**
     * The person\'s first name
     */
    firstName: string;
    /**
     * The person\'s last name
     */
    lastName: string;
    /**
     * The person\'s phone number
     */
    phoneNumber?: string;
    /**
     * A username that the person can use to log in (optional)
     */
    username?: string;
    /**
     * The organisation ID that will manage this person (optional)
     */
    organisationId?: number;
    /**
     * A list of roles the person has in this organisation
     */
    roles?: Array<CreatePersonOrganisationCommand.Roles>;
    /**
     * Should an introduction email be sent
     */
    sendNotification: boolean;
    /**
     * The person\'s date of birth
     */
    dateOfBirth?: string;
}
export namespace CreatePersonOrganisationCommand {
    export type Roles = 'PLANNER' | 'RCP' | 'ADMIN' | 'BILLING_ADMIN' | 'PILOT' | 'PILOT_PLANNER' | 'PILOT_SUBMITTER' | 'PILOT_CREW_PLANNER' | 'PILOT_INSTANT_MISSION' | 'FLIGHT_LOG_COLLECTION_ORGANISATION' | 'FLIGHT_LOG_COLLECTION_OWN' | 'MAINTENANCE_CONTROLLER' | 'MAINTENANCE_PERSON' | 'MAINTENANCE_PERSON_EXTERNAL' | 'STUDENT' | 'OBSERVER' | 'READ_ONLY';
    export const Roles = {
        PLANNER: 'PLANNER' as Roles,
        RCP: 'RCP' as Roles,
        ADMIN: 'ADMIN' as Roles,
        BILLING_ADMIN: 'BILLING_ADMIN' as Roles,
        PILOT: 'PILOT' as Roles,
        PILOT_PLANNER: 'PILOT_PLANNER' as Roles,
        PILOT_SUBMITTER: 'PILOT_SUBMITTER' as Roles,
        PILOT_CREW_PLANNER: 'PILOT_CREW_PLANNER' as Roles,
        PILOT_INSTANT_MISSION: 'PILOT_INSTANT_MISSION' as Roles,
        FLIGHT_LOG_COLLECTION_ORGANISATION: 'FLIGHT_LOG_COLLECTION_ORGANISATION' as Roles,
        FLIGHT_LOG_COLLECTION_OWN: 'FLIGHT_LOG_COLLECTION_OWN' as Roles,
        MAINTENANCE_CONTROLLER: 'MAINTENANCE_CONTROLLER' as Roles,
        MAINTENANCE_PERSON: 'MAINTENANCE_PERSON' as Roles,
        MAINTENANCE_PERSON_EXTERNAL: 'MAINTENANCE_PERSON_EXTERNAL' as Roles,
        STUDENT: 'STUDENT' as Roles,
        OBSERVER: 'OBSERVER' as Roles,
        READ_ONLY: 'READ_ONLY' as Roles
    };
}



