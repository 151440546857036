<ng-container *ngIf="airspaceJurisdiction$ | async as airspaceJurisdiction">
    <ng-container *ngIf="airspaceJurisdiction?.linkList">
        <ng-container *ngFor="let link of airspaceJurisdiction?.linkList">
            <a
                [href]="link.link"
                target="_blank"
                [tooltip]="link.title"
                placement="top"
                class="airspace-check-icons"
                angulartics2On="click"
                angularticsAction="jurisdiction-link"
                angularticsCategory="airspace-check"
                angularticsLabel="screenIdentifier"
            >
                <span
                    [class]="link.icon"
                    [ngClass]="{
                        fal: true
                    }"
                ></span>
            </a>
        </ng-container>
    </ng-container>
    <span
        *ngIf="airspaceJurisdiction?.hasNotifications"
        tooltip="Notifications"
        placement="top"
    >
        <a
            [popover]="notificationPopupTemplate"
            [popoverContext]="{
                popover: popover,
                airspaceNotifications: airspaceNotifications
            }"
            [outsideClick]="true"
            #popover="bs-popover"
            placement="bottom"
            class="airspace-check-icons"
            angulartics2On="click"
            angularticsAction="toggle-notifications"
            angularticsCategory="airspace-check"
            angularticsLabel="screenIdentifier"
        >
            <span
                class="fa-bell"
                [ngClass]="{
                    fal: !airspaceNotifications?.length,
                    fa: airspaceNotifications?.length
                }"
            ></span>
        </a>
    </span>

    <ng-template
        #notificationPopupTemplate
        let-popover="popover"
        let-airspaceNotifications="airspaceNotifications"
    >
        <div
            class="container-scroll modal-scrollbar"
            style="max-height: calc(100vh - 442px); max-width: 300px"
        >
            <div
                *ngIf="
                    airspaceNotifications != null &&
                    airspaceNotifications.length
                "
            >
                <div *ngFor="let notification of airspaceNotifications">
                    <airspace-notification
                        [notification]="notification"
                    ></airspace-notification>
                </div>
            </div>
            <div
                *ngIf="
                    airspaceNotifications == null ||
                    !airspaceNotifications.length
                "
            >
                No New CASA Notifications
                <span *ngIf="ruleset$ | async as ruleset"
                    >for {{ ruleset.name }}</span
                >
            </div>
        </div>
    </ng-template>
</ng-container>
