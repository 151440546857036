import { Directive, Input } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Directive({
    selector: '[formIdentifier]'
})
export class FormIdentifierDirective {
    @Input('formIdentifier') fields: FormlyFieldConfig[];

    findField(name: string) {
        if (!this.fields) {
            return;
        }
        return this.fields.find(e =>
            name.includes((<string>e.key).toLowerCase())
        );
    }
}
