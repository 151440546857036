<h4 class="section-heading">
    <span>Maintenance Schedule</span>
    <div></div>
</h4>
<ng-container *ngIf="mode === 'VIEW'">
    <div *ngIf="maintenanceScheduleAssignment == null" class="alert alert-info">
        There is no maintenance schedule assigned.
        <a *ngIf="canAssign" (click)="edit()">Edit</a>
    </div>
    <div
        *ngIf="maintenanceScheduleAssignment != null"
        class="horizontal-container"
    >
        <div class="right-buffer">
            <span class="display-label">Organisation</span>
            {{ maintenanceScheduleAssignment.organisation.name }}
        </div>
        <div class="right-buffer">
            <span class="display-label">Authority</span>
            {{
                (maintenanceScheduleAssignment.authority | formatAuthority) ||
                    'None'
            }}
        </div>
        <div
            *ngIf="
                maintenanceScheduleAssignment.overrideMaintenanceSchedule !=
                null
            "
            class="right-buffer"
        >
            <span class="display-label">Maintenance Schedule</span>
            {{ maintenanceScheduleAssignment.overrideMaintenanceSchedule.name }}
            <em
                >overriding
                {{
                    maintenanceScheduleAssignment.defaultMaintenanceSchedule
                        ?.name || 'None'
                }}
            </em>
        </div>
        <div
            *ngIf="
                maintenanceScheduleAssignment.overrideMaintenanceSchedule ==
                null
            "
            class="right-buffer"
        >
            <span class="display-label">Maintenance Schedule</span>
            {{
                maintenanceScheduleAssignment.defaultMaintenanceSchedule
                    ?.name || 'None'
            }}
        </div>
        <a *ngIf="canAssign" (click)="edit()">Edit</a>
    </div>
</ng-container>
<div *ngIf="mode === 'EDIT'" class="well" [formGroup]="formGroup">
    <div class="form-group">
        <label>Authority</label>
        <ng-select
            formControlName="authorityId"
            [readonly]="working"
            [items]="authorities"
            [clearable]="false"
            bindLabel="name"
            bindValue="id"
        >
        </ng-select>
    </div>
    <div class="form-group">
        <label>Override Schedule</label>
        <ng-select
            formControlName="maintenanceScheduleId"
            [readonly]="working"
            [items]="availableSchedules"
            [clearable]="true"
            bindLabel="name"
            bindValue="id"
        >
        </ng-select>
    </div>
    <div class="action-container">
        <button
            type="button"
            class="btn btn-primary btn-sm"
            [disabled]="working"
            (click)="assign()"
        >
            Assign
        </button>
        <button
            type="button"
            class="btn btn-link btn-sm"
            [disabled]="working"
            (click)="cancel()"
        >
            Cancel
        </button>
    </div>
</div>
