import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OrganisationAdminRoutingModule } from '@flyfreely-portal-ui/organisation-admin';
import { UserProfileRoutingModule } from '@flyfreely-portal-ui/user-profile';
import { MaintenanceRoutingModule } from '@flyfreely-portal-ui/maintenance';
import { DashboardComponent } from '../dashboard.component';
import { WidgetSelectionComponent } from '../widget-selection/widget-selection.component';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: DashboardComponent,
                children: [
                    {
                        path: 'orgadmin',
                        loadChildren: () => OrganisationAdminRoutingModule
                    },
                    {
                        path: 'userprofile',
                        loadChildren: () => UserProfileRoutingModule
                    },
                    {
                        path: 'maintenance',
                        loadChildren: () => MaintenanceRoutingModule
                    }
                ]
            },
            {
                path: '',
                component: WidgetSelectionComponent,
                outlet: 'sidebar'
            }
        ])
    ],
    exports: [RouterModule]
})
export class ManageDashboardRoutingModule {}
