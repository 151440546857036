import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SimplePersonDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'person-avatar',
    templateUrl: './person-avatar.component.html',
    styles: [
        `
            .person-avatar-pill {
                width: 100%;
                min-width: fit-content;
                padding: 5px 10px 5px 5px;
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
                background: #e9ecef;
                border-radius: 4em;
            }

            .person-name-container {
                margin-left: 10px;
            }
        `
    ]
})
export class PersonAvatarComponent {
    @Input() person: SimplePersonDto;
    @Input() managingOrganisationId: number;
    @Input() alternativeName: string;
    @Input() onlyImage: boolean;
    @Input() width: number;
    @Input() height: number;
    @Input() isLink: boolean;
    @Output() onPersonClicked = new EventEmitter<SimplePersonDto>();

    ngOnInit() {
        if (this.alternativeName == null) {
            this.alternativeName = `Unknown`;
        }
    }
}
