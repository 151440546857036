<div class="modal-header no-icon">
    <h3 class="modal-title pull-left">Mission Calendar</h3>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hide()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body no-footer">
    <div class="modal-body-content vertical-container">
        <plan-highlight
            [featureFlags]="missionCalendarFeature"
        ></plan-highlight>
        <div class="calendar-heading">
            <div class="button-group">
                <div
                    class="btn btn-circle btn-link left-buffer right-buffer"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                >
                    <span class="fa fa-arrow-left"></span>
                </div>
                <div
                    class="btn btn-sm btn-pill left-buffer right-buffer"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                >
                    Today
                </div>
                <div
                    class="btn btn-circle btn-link left-buffer right-buffer"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                >
                    <span class="fa fa-arrow-right"></span>
                </div>
            </div>
            <h4>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</h4>
            <!-- <div class="view-buttons">
                <button
                    type="button"
                    class="btn btn-sm right-buffer"
                    [ngClass]="{
                        'btn-primary': view === CalendarView.Week,
                        'btn-default': view === CalendarView.Month
                    }"
                    (click)="setView(CalendarView.Week)"
                >
                    Week View
                </button>
                <button
                    type="button" 
                    class="btn btn-sm right-buffer"
                    [ngClass]="{
                        'btn-primary': view === CalendarView.Month,
                        'btn-default': view === CalendarView.Week
                    }"
                    (click)="setView(CalendarView.Month)"
                >
                    Month View
                </button>
            </div> -->
        </div>
        <div class="fill container-scroll">
            <ng-container [ngSwitch]="view">
                <mwl-calendar-month-view
                    *ngSwitchCase="CalendarView.Month"
                    [viewDate]="viewDate"
                    [events]="events$ | async"
                    [refresh]="refresh"
                    [cellTemplate]="customMonthCellTemplate"
                >
                </mwl-calendar-month-view>
                <!-- <mwl-calendar-week-view
                *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                [eventTemplate]="customWeekEventTemplate"
            ></mwl-calendar-week-view> -->
            </ng-container>
        </div>
    </div>
</div>

<ng-template #customMonthCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
        <span class="cal-day-number">{{
            day.date | calendarDate: 'monthViewDayNumber':locale
        }}</span>
    </div>

    <div class="cal-event-container" *ngIf="day.events.length">
        <ng-container *ngFor="let event of day.events; let i = index">
            <div class="vertical-container bottom-buffer" *ngIf="i < 3">
                <a class="event-heading" (click)="openEvent(event)"
                    >{{ event.time }} -
                    {{ event.status | formatMissionStatus }}</a
                >
                <span>{{ event.locationName }}</span>
                <div>
                    <strong>RPIC: </strong>{{ event.rpic | formatPerson }}
                </div>
                <div><strong>RPA: </strong>{{ event.rpaNames | join }}</div>
            </div>
            <div class="vertical-container" *ngIf="i == 3">
                <span>& {{ day.events.length - 3 }} more...</span>
            </div>
        </ng-container>
    </div>
</ng-template>

<!-- <ng-template #customWeekEventTemplate let-column="column" let-locale="locale">
    <div class="cal-event-container bottom-buffer">    
        <ng-container
            *ngFor="let timeEvent of column.events; let i = index;"
            #event
        >
            <div class="vertical-container" *ngIf="i == 0">
                <div class="event-heading bottom-buffer">{{ timeEvent.event.locationName }} - {{ timeEvent.event.status | formatMissionStatus }}</div>
                <div><strong>RPIC: </strong>{{ timeEvent.event.rpic | formatPerson }}</div>
                <div><strong>RPA: </strong>{{ timeEvent.event.rpaNames | join }}</div>
                <span *ngIf="column.events.length > 1" class="pull-right" (click)="showEvents(column.events)">and {{ column.events.length - 1 }} more...</span>
            </div>
        </ng-container>
    </div>
</ng-template> -->
