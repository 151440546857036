import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AuthorityTypeDto,
    NameId,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityGroupEditService } from 'libs/authorities/src/lib/registers/interfaces';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthorityDialoguesService } from '../../authority-dialogues.service';
import {
    findNewJurisdictions,
    reduceToGroupWithOneAuthority
} from '../../helpers';
import { OrganisationAuthorityEditService } from '../organisation-authority-edit.service';

const GLOBAL_JURISDICTION_ID = -1;

@Component({
    selector: 'organisation-authority-list',
    templateUrl: './organisation-authority-list.component.html',
       providers: [
        {
            provide: AuthorityGroupEditService,
            useClass: OrganisationAuthorityEditService
        }
    ]
})
export class OrganisationAuthorityList implements OnInit, OnChanges, OnDestroy {
    @Input() organisationId: number;
    @Input() managingOrganisationId: number;
    @Input() entityType: string;
    @Input() canEdit: boolean;
    @Input() icon: string;

    @Output() edit = new EventEmitter<void>();

    authorities: OrganisationAuthorityGroup[];
    inUseJurisdictions: NameId[];

    visibleAuthorities: {
        [jurisdictionId: number]: OrganisationAuthorityGroup[];
    } = {};

    allArchived = false;

    private workTracker = new WorkTracker();
    private workTrackerSubscription: Subscription;
    working: boolean = false;

    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private authorityDialoguesService: AuthorityDialoguesService
    ) {
        this.workTrackerSubscription = this.workTracker.observable.subscribe(
            working => (this.working = working)
        );
    }

    ngOnInit() {
        this.authorities = [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('organisationId' in changes) {
            this.refreshAuthorities();
        }
    }

    ngOnDestroy() {
        this.workTrackerSubscription.unsubscribe();
    }

    private refreshAuthorities() {
        if (this.organisationId == null) {
            return;
        }
        this.organisationAuthorityService
            .findAuthorities(this.organisationId, this.managingOrganisationId)
            .subscribe(results => {
                this.loadAuthorities(results);
            })
            .add(this.workTracker.createTracker());
    }

    private loadAuthorities(authorities: OrganisationAuthorityGroup[]) {
        const sortedAuthorities = authorities.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        this.authorities = sortedAuthorities.filter(
            e => e.authorities != null && e.authorities.length > 0
        );
        this.inUseJurisdictions = this.authorities.reduce(
            (acc, e) => findNewJurisdictions(acc, e.jurisdiction, GLOBAL_JURISDICTION_ID),
            [] as NameId[]
        );

        this.updateVisibleAuthorities();
    }
    updateVisibleAuthorities() {
        // This is also the spot to update the logic so each authority gets a card instead of each authority group
        this.visibleAuthorities = {};
        this.inUseJurisdictions.forEach(j => {
            const visibleInJurisdiction = this.authorities
                .filter(a => this.isInJurisdiction(a, j.id))
                .filter(
                    a =>
                        a.availableActions.canCreate ||
                        a.authorities?.length > 0
                )
                .filter(a => !this.areAllExpiredOrArchived(a));
            if (this.visibleAuthorities == null) {
                this.visibleAuthorities = {
                    [j.id]: visibleInJurisdiction.reduce(
                        (acc, g) =>
                            acc.concat(reduceToGroupWithOneAuthority(g)),
                        []
                    )
                };
            } else {
                this.visibleAuthorities[j.id] = visibleInJurisdiction.reduce(
                    (acc, g) => acc.concat(reduceToGroupWithOneAuthority(g)),
                    []
                );
            }
        });
        this.allArchived = Object.keys(this.visibleAuthorities).reduce(
            (acc, j) =>
                acc &&
                (this.visibleAuthorities[j].length === 0 ||
                    this.visibleAuthorities[j].filter(
                        a => a.authorities[0].archived
                    ).length === this.visibleAuthorities[j].length),
            true
        );
    }

    areAllExpiredOrArchived(authorityGroup: OrganisationAuthorityGroup) {
        const expired = authorityGroup.authorities.filter(
            a => this.hasExpired(a) || a.archived
        );

        return expired.length === authorityGroup.authorities.length;
    }

    hasExpired(authority: OrganisationAuthorityDto) {
        return (
            authority.expiryDate != null &&
            moment(authority.expiryDate).isBefore(
                moment(moment().format('YYYY-MM-DD'))
            )
        );
    }

    isInJurisdiction(
        authority: OrganisationAuthorityGroup,
        jurisdictionId: number
    ) {
        if (jurisdictionId == GLOBAL_JURISDICTION_ID) {
            return authority.jurisdiction == null;
        }
        return (
            authority.jurisdiction != null &&
            authority.jurisdiction.id === jurisdictionId
        );
    }

    showAuthority(authorityType: AuthorityTypeDto, authority: any) {
        const modal = this.authorityDialoguesService.showAuthorityDetails(
            this.managingOrganisationId,
            authorityType,
            authority,
            null,
            false,
            false,
            null,
            true
        );
    }

    onEdit() {
        this.edit.emit(null);
    }
}
