<ng-container screenAnalytics="bulk-upload-selection">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Bulk Upload {{ entity }}</h3>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="inner-container">
            <p>
                Please select a file containing all entries for the bulk upload.
            </p>
            <p *ngIf="hasTemplate">
                You can also
                <a (click)="downloadTemplate()">download a template</a>
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-default pull-left"
            ngfSelect
            accept="text/csv,.csv,application/vnd.ms-excel"
            [(file)]="file"
            (fileChange)="fileSelected($event)"
        >
            Select File
        </button>
    </div>
</ng-container>
