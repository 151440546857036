import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonsOrganisationDto } from '@flyfreely-portal-ui/flyfreely';
import {
    findActiveMissionApprovals,
    getSimplifiedAuthorityFromApproval
} from '../../helpers';
import { MissionApprovalWithAuthority } from '../../interfaces';

@Component({
    selector: 'mission-approval-selection-popup',
    template: `
        <div class="panel-heading horizontal-container space-between">
            <h4 class="primary-heading">Approvals Required</h4>
            <button type="button" (click)="cancel.emit()" class="close">
                &times;
            </button>
        </div>
        <div class="container-scroll" style="max-height: 75vh;">
            <div
                class="contents vertical-container"
                *ngFor="let approval of activeApprovals; let i = index"
            >
                <div class="approval-data contents vertical-container">
                    <div
                        class="horizontal-container space-between align-vertical"
                    >
                        <h4 class="inline-heading">Approval {{ i + 1 }}</h4>
                        <span
                            class="badge"
                            [ngClass]="{
                                'badge-pending':
                                    approval.approval.status === 'UNDER_REVIEW',
                                'badge-success':
                                    approval.approval.status === 'APPROVED',
                                'badge-inactive':
                                    approval.approval.status === 'PENDING',
                                'badge-danger':
                                    approval.approval.status === 'CANCELLED' ||
                                    approval.approval.status === 'REJECTED' ||
                                    approval.approval.status === 'REVOKED'
                            }"
                        >
                            <span
                                class="fal right-buffer"
                                [ngClass]="{
                                    'fa-circle-check':
                                        approval.approval.status === 'APPROVED',
                                    'fa-circle-xmark':
                                        approval.approval.status ===
                                            'CANCELLED' ||
                                        approval.approval.status ===
                                            'REJECTED' ||
                                        approval.approval.status === 'REVOKED',
                                    'fa-circle-exclamation':
                                        approval.approval.status !==
                                            'APPROVED' &&
                                        approval.approval.status !==
                                            'REJECTED' &&
                                        approval.approval.status !==
                                            'REVOKED' &&
                                        approval.approval.status !== 'CANCELLED'
                                }"
                            ></span>
                            {{
                                approval.approval.status | formatApprovalStatus
                            }}
                        </span>
                    </div>
                    <span class="bottom-buffer"
                        ><strong>Ruleset:</strong>
                        {{
                            approval.authorityType?.ruleset?.name != null
                                ? approval.authorityType?.ruleset?.name
                                : 'None'
                        }}
                    </span>
                    <span
                        ><strong>Authority:</strong>
                        {{
                            (getSimplifiedAuthorityFromApproval(
                                approval,
                                organisation
                            ) | formatAuthority) || 'None'
                        }}
                    </span>
                    <span
                        *ngIf="approval.approval.requestedBy != null"
                        class="bottom-buffer"
                    >
                        <strong>Request by:</strong>
                        {{ approval.approval.requestedBy | formatPerson }}
                        {{
                            approval.approval.requestTime
                                | date: 'dd/MM/yyyy H:mm'
                        }}
                    </span>
                    <span
                        *ngIf="approval.approval.approvedBy != null"
                        class="bottom-buffer"
                    >
                        <strong>Approved by:</strong>
                        {{ approval.approval.approvedBy | formatPerson }}
                        {{
                            approval.approval.resolutionTime
                                | date: 'dd/MM/yyyy H:mm'
                        }}
                    </span>
                    <span
                        *ngIf="approval.approval.acceptedBy != null"
                        class="bottom-buffer"
                    >
                        <strong>Accepted by:</strong>
                        {{ approval.approval.acceptedBy | formatPerson }}
                        {{
                            approval.approval.acceptTime
                                | date: 'dd/MM/yyyy H:mm'
                        }}
                    </span>
                    <div
                        class="horizontal-container bottom-buffer"
                        style="justify-content: end;"
                    >
                        <button
                            *ngIf="canApprove"
                            type="button"
                            class="btn btn-default btn-sm"
                            (click)="viewApproval.emit(approval)"
                            [disabled]="working"
                        >
                            View
                        </button>
                        <button
                            *ngIf="canAcceptApproval"
                            type="button"
                            class="btn btn-primary btn-sm"
                            (click)="acceptApproval.emit(approval)"
                            [disabled]="working"
                        >
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['../../styles.scss']
})
export class MissionApprovalSelectionPopupComponent {
    @Input() organisation: PersonsOrganisationDto;
    @Input() approvals: MissionApprovalWithAuthority[];
    @Input() canApprove: boolean;
    @Input() canAcceptApproval: boolean;
    @Output() viewApproval = new EventEmitter<MissionApprovalWithAuthority>();
    @Output() acceptApproval = new EventEmitter<MissionApprovalWithAuthority>();
    @Output() cancel = new EventEmitter<void>();

    activeApprovals: MissionApprovalWithAuthority[] = [];

    getSimplifiedAuthorityFromApproval = getSimplifiedAuthorityFromApproval;

    ngOnInit() {
        this.activeApprovals = findActiveMissionApprovals(this.approvals);
    }

    ngOnChanges() {
        this.activeApprovals = findActiveMissionApprovals(this.approvals);
    }
}
