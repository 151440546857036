/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The model of the equipment
 */
export interface EquipmentTypeDto { 
    id?: number;
    /**
     * The type of equipment
     */
    equipmentCategory: EquipmentTypeDto.EquipmentCategory;
    /**
     * The make of equipment
     */
    make: string;
    /**
     * The model of equipment
     */
    model: string;
    /**
     * The organisation that will own this equipment model
     */
    organisationId: number;
    /**
     * The schematic used for this equipment type
     */
    schematicId?: number;
}
export namespace EquipmentTypeDto {
    export type EquipmentCategory = 'PAYLOAD' | 'BASE_STATION' | 'CONTROLLER' | 'MONITOR' | 'CONTROL_ACCESSORIES' | 'COMMUNICATION_EQUIPMENT' | 'OTHER';
    export const EquipmentCategory = {
        PAYLOAD: 'PAYLOAD' as EquipmentCategory,
        BASE_STATION: 'BASE_STATION' as EquipmentCategory,
        CONTROLLER: 'CONTROLLER' as EquipmentCategory,
        MONITOR: 'MONITOR' as EquipmentCategory,
        CONTROL_ACCESSORIES: 'CONTROL_ACCESSORIES' as EquipmentCategory,
        COMMUNICATION_EQUIPMENT: 'COMMUNICATION_EQUIPMENT' as EquipmentCategory,
        OTHER: 'OTHER' as EquipmentCategory
    };
}


