<div class="modal-header no-icon">
    <h3 class="modal-title">Control Properties</h3>
    <button
        type="button"
        (click)="modal.hide()"
        aria-label="Close"
        class="close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="controlForm" (ngSubmit)="submit()">
    <div class="modal-body">
        <div class="top-buffer">
            <div class="form-group">
                <field-validation-indicator
                    [control]="controlForm.controls.controlType"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label class="control-label"> Type </label>
                <select
                    class="form-control"
                    [formControl]="controlForm.controls.controlType"
                >
                    <option
                        *ngFor="let controlType of controlTypes"
                        [ngValue]="controlType"
                    >
                        {{ controlType.name }}
                    </option>
                </select>
            </div>
        </div>

        <div
            class="top-buffer"
            *ngIf="
                controlForm.controls.controlType.value &&
                controlForm.controls.controlType.value.hasLabel
            "
        >
            <div class="form-group">
                <field-validation-indicator
                    [control]="controlForm.controls.label"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>Label</label>
                <input
                    class="form-control"
                    [formControl]="controlForm.controls.label"
                />
            </div>
        </div>
        <div
            class="top-buffer"
            *ngIf="
                controlForm.controls.controlType.value &&
                controlForm.controls.controlType.value.hasPlaceholder
            "
        >
            <div class="form-group">
                <field-validation-indicator
                    [control]="controlForm.controls['placeholder']"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>Placeholder</label>
                <input
                    class="form-control"
                    [formControl]="controlForm.controls.placeholder"
                />
            </div>
        </div>
        <div class="top-buffer">
            <div class="form-group">
                <field-validation-indicator
                    [control]="controlForm.controls.description"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>Description</label>
                <textarea
                    class="form-control"
                    [formControl]="controlForm.controls.description"
                ></textarea>
            </div>
        </div>
        <div
            class="top-buffer"
            *ngIf="
                controlForm.controls.controlType.value &&
                controlForm.controls.controlType.value.type === 'image'
            "
        >
            <div class="form-group">
                <field-validation-indicator
                    [control]="controlForm.controls.imageAttachment"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>Image</label>
                <ng-select
                    [formControl]="controlForm.controls.imageAttachment"
                    bindLabel="name"
                    placeholder="Select an attachment"
                    [items]="attachments"
                >
                </ng-select>
                <img
                    *ngIf="getImageAttachment.value"
                    [authenticatedSrc]="getImageAttachment.value.downloadUrl"
                />
            </div>
        </div>
        <div
            class="top-buffer"
            *ngIf="
                controlForm.controls.controlType.value &&
                controlForm.controls.controlType.value.hasInput
            "
        >
            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            [value]="true"
                            formControlName="required"
                        />
                        <span class="cr"
                            ><i class="cr-icon glyphicon glyphicon-ok"></i>
                        </span>
                        Required
                    </label>
                </div>
            </div>
        </div>
        <div
            class="top-buffer"
            *ngIf="
                controlForm.controls.controlType.value &&
                controlForm.controls.controlType.value.config.length > 0 &&
                controlForm.controls.controlType.value.hasConfig
            "
        >
            <div class="form-group" [formGroup]="controlForm.controls.config">
                <label>Config</label>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let configItem of controlForm.controls
                                    .controlType.value.config
                            "
                        >
                            <td>{{ configItem.name }}</td>
                            <td>
                                <input
                                    class="form-control input-sm"
                                    [formControlName]="configItem.value"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <div
                                    *ngIf="configGroup.errors"
                                    class="text-danger"
                                >
                                    {{ configGroup.errors.config }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div
            class="top-buffer"
            *ngIf="
                controlForm.controls.controlType.value &&
                controlForm.controls.controlType.value.hasOptions &&
                controlForm.controls.controlType.value.hasInput
            "
        >
            <div class="form-group">
                <label>Options</label>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Value</th>
                            <th>Name</th>
                            <th>Group</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody
                        formArrayName="options"
                        cdkDropList
                        [cdkDropListData]="controlForm.controls.options.value"
                        (cdkDropListDropped)="dropOption($event)"
                    >
                        <tr
                        *ngFor="let option of getOptions.controls; let index = index"
                        cdkDrag
                        [formGroupName]="index"
                        >
                            <td>
                                <input
                                    class="form-control input-sm"
                                    formControlName="value"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control input-sm"
                                    formControlName="name"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control input-sm"
                                    formControlName="group"
                                />
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-tertiary btn-delete"
                                    (click)="removeOption(index)"
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </td>
                            <td class="grab-handle">
                                <span
                                    class="glyphicon glyphicon-option-vertical"
                                ></span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-xs"
                                    (click)="addOption()"
                                >
                                    Add Option
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div class="top-buffer">
            <div class="form-group">
                <field-validation-indicator
                    [control]="controlForm.controls.whenToDisplay"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>Conditional Display</label>
                <select
                    class="form-control"
                    [formControl]="controlForm.controls.whenToDisplay"
                >
                    <option [ngValue]="'ALWAYS'">Always</option>
                    <option [ngValue]="'NEVER'">Never</option>
                    <option [ngValue]="'CONDITIONALLY'">Conditionally</option>
                </select>

                <div
                    *ngIf="
                        getWhenToDisplay.value &&
                        getWhenToDisplay.value === 'CONDITIONALLY'
                    "
                >
                    <field-validation-indicator
                        [valid]="
                            controlForm.controls.subjectControl.valid &&
                            controlForm.controls.comparator.value &&
                            (controlForm.controls['conditionValues'].valid ||
                                shouldLimitOptions())
                        "
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <label>Display Condition</label>
                    <div class="horizontal-container">
                        <div class="form-group">
                            <label>When (Source Control)</label>
                            <select
                                class="form-control"
                                [formControl]="
                                    controlForm.controls.subjectControl
                                "
                            >
                                <option
                                    *ngFor="let c of comparatorControls"
                                    [ngValue]="c"
                                >
                                    {{ c.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Is (Comparison)</label>
                            <select
                                class="form-control"
                                [formControl]="controlForm.controls.comparator"
                            >
                                <option
                                    *ngFor="let c of comparators"
                                    [ngValue]="c"
                                >
                                    {{ c.name }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label>To (Value)</label>
                            <ng-container
                                *ngIf="
                                    controlForm.controls['comparator'].value &&
                                    !limitOptions
                                "
                                formArrayName="conditionValues"
                            >
                                <input
                                    class="form-control"
                                    *ngFor="
                                        let ix of range(
                                            controlForm.controls['comparator']
                                                .value.operands
                                        )
                                    "
                                    [formControlName]="ix"
                                />
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    controlForm.controls.comparator.value &&
                                    limitOptions
                                "
                                formArrayName="conditionValues"
                            >
                                <select
                                    *ngFor="
                                        let ix of range(
                                            controlForm.controls.comparator
                                                .value.operands
                                        )
                                    "
                                    class="form-control"
                                    [formControlName]="ix"
                                >
                                    <optgroup label="Select One">
                                        <option
                                            *ngFor="let c of comparatorOptions"
                                            [value]="c"
                                        >
                                            {{ c }}
                                        </option>
                                    </optgroup>
                                </select>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="top-buffer"
            *ngIf="
                controlForm.controls.controlType.value &&
                controlForm.controls.controlType.value.type === 'calculated'
            "
        >
            <div class="form-group">
                <field-validation-indicator
                    [control]="controlForm.controls.operator"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <label>Calculation</label>
                <select
                    class="form-control"
                    [formControl]="controlForm.controls.operator"
                >
                    <option *ngFor="let o of operators" [ngValue]="o.value">
                        {{ o.name }}
                    </option>
                </select>

                <table class="table" formArrayName="operands">
                    <tbody>
                        <tr
                            *ngFor="
                                let operand of getOperands.controls;
                                let index = index
                            "
                        >
                            <td>
                                <select
                                    class="form-control"
                                    [formControlName]="index"
                                >
                                    <option
                                        *ngFor="let c of numericalControls"
                                        [ngValue]="c.id"
                                    >
                                        {{ c.name }}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button
                                    class="btn btn-tertiary btn-delete"
                                    type="button"
                                    (click)="removeOperand(index)"
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                <button
                                    class="btn btn-tertiary"
                                    type="button"
                                    (click)="addOperand()"
                                >
                                    <span class="fa fa-plus"></span>
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            type="submit"
            [disabled]="controlForm.invalid"
        >
            {{ control.id ? 'Update' : 'Create' }}
        </button>
    </div>
</form>
