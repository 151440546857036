import {
    AirspaceJurisdictionDto,
    CraftAuthorityGroup,
    DisplayableAuthorityTypeDto,
    NameId,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    SimpleAuthorityDto,
    SimpleOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';

export function byActiveJurisdictions(activeJurisdictionIds: number[]) {
    return (authority: OrganisationAuthorityGroup) =>
        authority.jurisdiction == null ||
        activeJurisdictionIds.indexOf(authority.jurisdiction.id) !== -1;
}

/**
 * Find new jurisdictions and add them to the list. If a global jurisdiction is used add it first.
 *
 * @param acc a list of the jurisdictions that have already been found
 * @param jurisdictions the new jurisdiction to add
 */
export function findNewJurisdictions(
    acc: NameId[],
    jurisdiction: AirspaceJurisdictionDto,
    globalJurisdictionId: number
) {
    if (jurisdiction == null) {
        return acc.findIndex(j => j.id === globalJurisdictionId) !== -1
            ? acc
            : [{ id: globalJurisdictionId, name: 'Global' }].concat(acc);
    }
    return acc.concat(
        acc.findIndex(j2 => j2.id === jurisdiction.id) === -1
            ? [{ id: jurisdiction.id, name: jurisdiction.name }]
            : []
    );
}

export function toSimpleAuthority(
    authority: OrganisationAuthorityDto,
    organisation: SimpleOrganisationDto,
    authorityType: DisplayableAuthorityTypeDto
): SimpleAuthorityDto {
    return {
        ...authority,
        organisation,
        authorityType: {
            id: authorityType.id,
            name: authorityType.name,
            appliesTo: authorityType.appliesTo,
            hasExpiry: authorityType.hasExpiry,
            hasIdentifier: authorityType.hasIdentifier,
            identifierLabel: authorityType.identifierLabel,
            hasWorkflow: authorityType.hasWorkflow,
            description: authorityType.description,
            hasMultipleInstances: authorityType.hasMultipleInstances,
            helperText: authorityType.helperText,
            isPrimary: authorityType.isPrimary,
            issuedBy: authorityType.issuedBy,
            requiresApproval: authorityType.requiresApproval
        },
        hasLibrary: authority.attachments.length > 0,
        expired: authority.expired
    };
}

export function reduceToGroupWithOneAuthority(
    group: OrganisationAuthorityGroup | CraftAuthorityGroup
) {
    if (group.authorities == null || group.authorities.length < 2) {
        return [group];
    }
    return group.authorities.map(a => ({ ...group, authorities: [a] }));
}
