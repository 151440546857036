import { Directive } from '@angular/core';
import { AttachmentList } from './attachments-list/attachments-list.component';
import {
    hasAnyRole,
    PersonRolesDto,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';

/**
 * Manage the allow download permission based on the role.
 */
@Directive({
    selector: 'attachment-list[permissions]'
})
export class AttachmentPermissionsDirective {
    constructor(
        private attachmentList: AttachmentList,
        private userService: UserService,
        private sharedService: WorkspaceStateService
    ) {}

    ngOnInit() {
        const user = this.userService.getCurrentUser();
        const organisation = this.sharedService.getCurrentOrganisation();
        if (user == null || organisation.type !== 'organisation_loaded') {
            this.attachmentList.setAllowDownload(false);
            return;
        }
        this.attachmentList.setAllowDownload(
            (organisation.organisation.roles.length > 0 &&
                !hasAnyRole(organisation.organisation.roles, [
                    PersonRolesDto.Roles.READ_ONLY
                ])) ||
                user.type === 'SYSTEM_ADMIN'
        );
    }
}
