import { Component, ComponentFactoryResolver, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, firstValueFrom } from 'rxjs';
import { MODAL_OPTIONS } from './ngx-config';

const BASE_PROVIDERS = [
    ActivatedRoute,
    Router
];

const NOT_FOUND = {};

/**
 * This container is used in the Router to host a modal component.
 */
@Component({
    template: ``
})
export class ModalContainer {
    private modal: BsModalRef<any>;
    private subscription: Subscription;

    constructor(
        private modalService: BsModalService,
        private router: Router,
        private injector: Injector,
        private activatedRoute: ActivatedRoute
    ) {}

    async ngOnInit() {
        const data = await firstValueFrom(this.activatedRoute.data);
        const modalProviders = data.modalProviders;
        const compFactoryResolver = this.injector.get(ComponentFactoryResolver);
        const compFactory = compFactoryResolver.resolveComponentFactory(
            data.modalContent
        );

        const initialState =
            data.paramMap == null
                ? {}
                : compFactory.inputs.reduce(
                      (acc: any, prop: any) => ({
                          ...acc,
                          [prop.propName]: data.paramMap[prop.propName]
                      }),
                      {}
                  );

        const providers = BASE_PROVIDERS.concat(modalProviders ?? []);

        this.modal = this.modalService.show(data.modalContent, {
            ...MODAL_OPTIONS,
            initialState,
            class: data.modalClass,
            providers: providers
                .map(p => ({
                    provide: p,
                    useValue: this.injector.get(p, NOT_FOUND)
                }))
                .filter(p => p.useValue !== NOT_FOUND)
        });

        this.subscription = this.modal.onHide.subscribe(() => {
            if (data.parentRoute) {
                this.router.navigate([data.parentRoute], {
                    queryParamsHandling: 'preserve'
                });
            } else if (data.relativeRoute) {
                this.router.navigate([data.relativeRoute], {
                    relativeTo: this.activatedRoute,
                    queryParamsHandling: 'preserve'
                });
            } else {
                this.router.navigate(['../'], {
                    relativeTo: this.activatedRoute,
                    queryParamsHandling: 'preserve'
                });
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.modal.hide();
    }
}
