import { Component, ElementRef } from '@angular/core';
import {
    AirspaceCheckCommand,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    LocationDetailsDto,
    LocationFeatureDto,
    RuleOutcome
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { getFeatureGroups } from 'libs/locations/src/lib/helpers';
import { MapSourceFilters } from 'libs/map/src/lib/dynamic-data.service';
import { FeatureGroup, MapGeometry } from 'libs/map/src/lib/interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { AirspaceCheckService } from '..';

const OUTCOME_ORDER = {
    [RuleOutcome.Outcome.BLOCK]: 1,
    [RuleOutcome.Outcome.ADVISE]: 2,
    [RuleOutcome.Outcome.AUTHORISATION_REQUIRED]: 2,
    [RuleOutcome.Outcome.PASS]: 3
};

interface OutcomeDetails {
    code: string;
    /**
     * We put the intersecting features in the first element.
     */
    featureList: FeatureGroup[];
    outcomeMessage: string;
    name: string;
    description: string;
    outcome: RuleOutcome.Outcome;
}

@Component({
    selector: 'airspace-check-details-dialogue',
    templateUrl: './airspace-check-details-dialogue.component.html',
    styleUrls: ['./airspace-check-details-dialogue.component.scss'],
    styles: [
        `
            .icon {
                --fa-primary-opacity: 1;
                --fa-secondary-opacity: 1;
            }
        `
    ],
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()]
})
export class AirspaceCheckDetailsDialogue {
    organisationId: number;
    result: AirspaceCheckDto;
    checkCommand: AirspaceCheckCommand;
    airspaceJurisdiction: AirspaceJurisdictionDto;
    status: string;
    rulesetAbbreviation: string;

    private missionLocation: LocationDetailsDto;
    private missionFeatures: FeatureGroup[];

    mapSourceFilters: MapSourceFilters = {};
    enabledMapLayerGroups: string[];

    private nextId = 1;

    operationFailed = false;

    outcomeList: OutcomeDetails[];

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<AirspaceCheckDetailsDialogue>,
        private airspaceCheckService: AirspaceCheckService,
        private element: ElementRef
    ) {
        this.airspaceCheckService.resultStatus$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => (this.status = result));
        this.airspaceCheckService.operationFailed$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(failed => (this.operationFailed = failed));

        combineLatest([
            this.airspaceCheckService.completeCheck$,
            this.airspaceCheckService.airspaceJurisdiction$,
            this.airspaceCheckService.missionLocation$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([completeCheck, jurisdiction, location]) => {
                this.result = completeCheck.result;
                this.checkCommand = completeCheck.check;
                this.airspaceJurisdiction = jurisdiction;
                this.missionLocation = location;
                this.organisationId = completeCheck.organisationId;
                const ruleset = jurisdiction?.rulesetList?.find(
                    r =>
                        r.identifier != null &&
                        r.identifier === completeCheck.check.ruleset
                );
                this.rulesetAbbreviation = ruleset?.abbreviation;
                this.setupMissionMapFeatures();
                this.buildOutcomeList();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Build the outcome list, grouped by code.
     */
    buildOutcomeList() {
        const grouped = this.result.ruleOutcomes.reduce((acc, outcome) => {
            const messages = this.result.ruleMessages[outcome.code];
            if (acc[outcome.code] != null) {
                this.addOutcomeFeature(
                    acc[outcome.code].featureList[0],
                    <MapGeometry>outcome.intersectingGeometry
                );
            } else {
                const featureList = [
                    this.generateOutcomeFeatureGroup(messages.name)
                ].concat(this.missionFeatures);

                this.addOutcomeFeature(
                    featureList[0],
                    <MapGeometry>outcome.intersectingGeometry
                );
                acc[outcome.code] = {
                    code: outcome.code,
                    featureList: featureList,
                    name: messages.name,
                    description: messages.flyFreelyDescription,
                    outcomeMessage: outcome.message,
                    outcome: outcome.outcome
                };
            }
            return acc;
        }, {} as { [code: string]: OutcomeDetails });

        this.outcomeList = Object.values(grouped);
        this.outcomeList.sort(
            (a, b) => OUTCOME_ORDER[a.outcome] - OUTCOME_ORDER[b.outcome]
        );
    }

    setupMissionMapFeatures() {
        const missionFlightAreas =
            this.missionLocation != null
                ? <LocationFeatureDto[]>(
                      this.missionLocation.features.filter(
                          f =>
                              f.type === LocationFeatureDto.Type.FLIGHT_AREA ||
                              f.type ===
                                  LocationFeatureDto.Type.OFFSET_FLIGHT_AREA
                      )
                  )
                : [];
        const { features, nextId } = getFeatureGroups(
            missionFlightAreas,
            ++this.nextId
        );
        this.missionFeatures = features;
        this.nextId = nextId + 1;

        if (this.result != null) {
            this.mapSourceFilters = this.result.airspaceDatasetList?.reduce(
                (acc, v) => ({
                    ...acc,
                    [v.identifier]: v.version
                }),
                {} as MapSourceFilters
            );
            this.enabledMapLayerGroups = Object.keys(this.mapSourceFilters);
            this.enabledMapLayerGroups = Object.keys(this.mapSourceFilters);
        }
    }

    private generateOutcomeFeatureGroup(name: string): FeatureGroup {
        return {
            id: this.nextId,
            name: name,
            type: 'Polygon',
            canAdd: false,
            categories: [
                {
                    id: LocationFeatureDto.Type.AREA_OF_INTEREST,
                    name: name
                }
            ],
            existingFeatures: [],
            styles: {
                fill: [
                    {
                        paint: {
                            'fill-color': '#ff1234'
                        },
                        filter: ['!', ['has', 'category-id']]
                    },
                    {
                        paint: {
                            'fill-color': '#990000',
                            'fill-opacity': 0.4
                        },
                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.FLIGHT_AREA
                        ]
                    },
                    {
                        paint: {
                            'fill-color': '#6b3c3c',
                            'fill-opacity': 0.4
                        },
                        filter: [
                            '==',
                            ['get', 'category-id'],
                            'SECONDARY_FLIGHT_AREA'
                        ]
                    },
                    {
                        paint: {
                            'fill-pattern': 'no-fly',
                            'fill-opacity': 0.3
                        },

                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.DANGER
                        ]
                    },
                    {
                        paint: {
                            'fill-pattern': 'danger-area',
                            'fill-opacity': 1
                        },

                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.NO_FLY
                        ]
                    },
                    {
                        paint: {
                            'fill-color': 'hsl(50,100,50)',
                            'fill-opacity': 0.4
                        },
                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.AREA_OF_INTEREST
                        ]
                    }
                ],
                line: [
                    {
                        paint: {
                            'line-color': '#990000',
                            'line-width': 3,
                            'line-opacity': 1
                        },

                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.FLIGHT_AREA
                        ]
                    },
                    {
                        paint: {
                            'line-color': '#6b3c3c',
                            'line-width': 3,
                            'line-opacity': 1
                        },

                        filter: [
                            '==',
                            ['get', 'category-id'],
                            'SECONDARY_FLIGHT_AREA'
                        ]
                    },
                    {
                        paint: {
                            'line-color': '#fecb1c',
                            'line-width': 3,
                            'line-opacity': 0.5
                        },

                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.DANGER
                        ]
                    },
                    {
                        paint: {
                            'line-width': 3,
                            'line-opacity': 0.5
                        },

                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.NO_FLY
                        ]
                    },
                    {
                        paint: {
                            'line-color': 'hsl(50,100,50)',
                            'line-width': 3,
                            'line-opacity': 1
                        },

                        filter: [
                            '==',
                            ['get', 'category-id'],
                            LocationFeatureDto.Type.AREA_OF_INTEREST
                        ]
                    }
                ],
                symbol: [
                    {
                        layout: {
                            'text-field': '{name}',
                            'symbol-placement': 'line',
                            'text-anchor': 'bottom',
                            'symbol-spacing': 150,
                            'text-max-angle': 35,
                            'text-size': 12,
                            'text-letter-spacing': 0.1,
                            'text-font': [
                                'Roboto Bold',
                                'Arial Unicode MS Regular'
                            ]
                        },
                        paint: {
                            'text-halo-color': 'hsl(0, 100%, 100%)',
                            'text-halo-width': 1,
                            'text-halo-blur': 1
                        }
                    }
                ]
            }
        };
    }

    private addOutcomeFeature(
        featureGroup: FeatureGroup,
        intersectingGeometry: MapGeometry
    ) {
        if (intersectingGeometry == null) {
            return;
        }

        featureGroup.existingFeatures.push({
            id: this.nextId++,
            geom: intersectingGeometry,
            categoryId: LocationFeatureDto.Type.AREA_OF_INTEREST,
            name: ''
        });
    }

    scrollToRule(ruleId: string) {
        (<HTMLElement>this.element.nativeElement)
            .querySelector('#rule-' + ruleId)
            .scrollIntoView({
                behavior: 'smooth'
            });
    }

    close() {
        this.modal.hide();
    }
}
