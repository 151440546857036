import { Component, Input } from '@angular/core';
import {
    ComponentTypeDto,
    CraftService,
    EquipmentModelService,
    EquipmentService,
    ResourceComponentInServiceTime,
    RpaTypesService,
    TotalInServiceTime,
    WorkTracker,
    toTimestamp
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

function sortComponents(
    a: ResourceComponentInServiceTime,
    b: ResourceComponentInServiceTime
): number {
    const componentTypeOrder = a.componentType.name.localeCompare(
        b.componentType.name
    );
    if (componentTypeOrder !== 0) {
        return componentTypeOrder;
    }
    return a.designation.localeCompare(b.designation);
}

@Component({
    selector: 'component-service-time',
    templateUrl: './component-service-time.component.html'
})
export class ComponentServiceTime {
    @Input() resourceId: number;
    @Input() resourceType: 'CRAFT' | 'EQUIPMENT';
    @Input() managingOrganisationId: number;
    @Input() hasScheduledMaintenance: boolean = false;
    @Input() timeInServiceMode: 'total' | 'maintenance' = 'total';
    @Input() until: string = toTimestamp(moment().startOf('day').toDate());

    serviceTimes: TotalInServiceTime;

    diagramUrl: string;

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private craftservice: CraftService,
        private rpaTypesService: RpaTypesService,
        private equipmentService: EquipmentService,
        private equipmentTypeService: EquipmentModelService
    ) {}

    ngOnInit() {
        if (this.resourceType === 'CRAFT') {
            this.craftservice.change$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(() => this.refreshServiceTimes());
        }
        if (this.resourceType === 'EQUIPMENT') {
            this.equipmentService.change$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(() => this.refreshServiceTimes());
        }
        this.refreshServiceTimes();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshServiceTimes() {
        if (this.resourceType === 'CRAFT') {
            this.craftservice
                .findTotalTimeInService(
                    this.resourceId,
                    this.managingOrganisationId,
                    this.until
                )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    total =>
                        (this.serviceTimes = {
                            ...total,
                            components: [...total.components].sort(
                                sortComponents
                            )
                        })
                )
                .add(this.workTracker.createTracker());
        }
        if (this.resourceType === 'EQUIPMENT') {
            this.equipmentService
                .findTotalTimeInService(
                    this.resourceId,
                    this.managingOrganisationId
                )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    total =>
                        (this.serviceTimes = {
                            ...total,
                            components: [...total.components].sort(
                                sortComponents
                            )
                        })
                )
                .add(this.workTracker.createTracker());
        }
    }

    getAttachmentUrl(component: ResourceComponentInServiceTime) {
        this.diagramUrl = null;
        // TODO: this call can move to one service since it's not type-specific
        if (this.resourceType === 'CRAFT') {
            this.diagramUrl = this.rpaTypesService.getSchematicAttachmentUrl(
                this.serviceTimes.schematicId,
                component.attachmentId
            );
        }
        if (this.resourceType === 'EQUIPMENT') {
            this.diagramUrl = this.equipmentTypeService.getSchematicAttachmentUrl(
                this.serviceTimes.schematicId,
                component.attachmentId
            );
        }
    }

    findComponentIcon(component: ResourceComponentInServiceTime) {
        if (
            component.componentType.iconFamily !==
                ComponentTypeDto.IconFamily.FONT_AWESOME ||
            component.componentType.icon == null
        ) {
            return null;
        }
        const icon = `right-buffer fal fa-${component.componentType.icon}`;
        return icon;
    }
}
