import { Pipe, PipeTransform } from '@angular/core';

/**
 * Joins an array of strings, optionally stopping at a certain limit.
 */
@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
    transform(value: string[], limit: number = null, separator = ', ') {
        if (value == null) {
            return null;
        }

        if (limit == null || limit < value.length) {
            return value.join(separator);
        }

        return `${value.slice(0, limit).join(separator)} and ${
            limit - value.length
        } others`;
    }
}
