import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-value',
    template: `
        <div>
            {{ formControl.value || '' }}
        </div>
    `
})
export class FormlyValue extends FieldType {}
