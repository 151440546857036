import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'formatTime' })
export class FormatTime implements PipeTransform {
    transform(dateTimeStr: string): string | null {
        if (!dateTimeStr) {
            return null;
        }
        return moment(dateTimeStr).format('HH:mm');
    }
}
