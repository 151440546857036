import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ContactSalesCommand,
    CurrentPersonDto,
    FlyFreelyConstants,
    PersonsOrganisationDto,
    SimpleOrganisationDto,
    WhatsNewDto,
    isDefined
} from '@flyfreely-portal-ui/flyfreely';
import { chainNullableK, fromNullable } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { KeycloakEventType } from 'keycloak-angular';
import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    combineLatest,
    firstValueFrom,
    of
} from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { PersonService } from '../person.service';
import { httpParamSerializer } from '../service.helpers';

import * as Sentry from '@sentry/angular';
import { OrganisationService } from '../organisation.service';
import { LoginManager } from './login-manager';

export enum UserStatus {
    UNKNOWN = 'UNKNOWN',
    LOADING = 'LOADING',
    LOGGED_IN = 'LOGGED_IN',
    LOGGED_OUT = 'LOGGED_OUT'
}

export interface UnknownUser {
    type: UserStatus.UNKNOWN;
}

export interface LoadingUser {
    type: UserStatus.LOADING;
}

export interface LoggedInUser {
    type: UserStatus.LOGGED_IN;
    currentUser: CurrentPersonDto;
    currentUsersOrganisations: PersonsOrganisationDto[];
}

export interface LoggedOutUser {
    type: UserStatus.LOGGED_OUT;
}

export type UserChanged =
    | UnknownUser
    | LoadingUser
    | LoggedInUser
    | LoggedOutUser;

export enum LoginEvent {
    LOGIN,
    LOGIN_FAILED,
    LOGOUT
}

export interface KeycloakValidateCommand {
    username: string;
    password: string;
    redirectTarget?: string;
}

export function isLoggedIn(u: UserChanged): u is LoggedInUser {
    return u.type === UserStatus.LOGGED_IN;
}

export function isLoggedOut(u: UserChanged): u is LoggedOutUser {
    return u.type === UserStatus.LOGGED_OUT;
}

export function isUnknownUser(u: UserChanged): u is UnknownUser {
    return u.type === UserStatus.UNKNOWN;
}

export function isLoadingUser(u: UserChanged): u is LoadingUser {
    return u.type === UserStatus.LOADING;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private baseUrl: string;
    private userChangeSource = new ReplaySubject<UserChanged>(1);
    userChange$ = this.userChangeSource.asObservable();

    private loginEventSource = new Subject<LoginEvent>();
    loginEvent$ = this.loginEventSource.asObservable();

    private keycloakStatusSource = new ReplaySubject<KeycloakEventType>();
    keycloakStatus$ = this.keycloakStatusSource.asObservable();

    // User organisation information, organised into a single unit of information
    private userOrganisations$ = new BehaviorSubject<{
        userStatus: UserStatus;
        currentUser: CurrentPersonDto | undefined;
        additionalOrganisation: PersonsOrganisationDto | undefined;
        organisationList: PersonsOrganisationDto[] | undefined;
    }>({
        userStatus: UserStatus.UNKNOWN,
        currentUser: undefined,
        additionalOrganisation: undefined,
        organisationList: undefined
    });

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private constants: FlyFreelyConstants,
        private http: HttpClient,
        private loginManager: LoginManager,
        private personService: PersonService,
        organisationService: OrganisationService
    ) {
        this.baseUrl = constants.SITE_URL;

        this.userOrganisations$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(({ userStatus, currentUser, organisationList }) =>
                this.notifyUserChanged(
                    userStatus,
                    currentUser,
                    organisationList
                )
            );

        // Initialise class variables
        this.userChangeSource.next({ type: UserStatus.UNKNOWN });
        this.loginManager.keycloakEvents$
            .pipe(takeUntil(this.ngUnsubscribe$), filter(isDefined))
            .subscribe(event => {
                // The login components use this observable to know when the keycloak service is ready to be polled for login status
                this.keycloakStatusSource.next(event.type);
                if (event.type === KeycloakEventType.OnReady) {
                    if (
                        this.userOrganisations$.getValue().userStatus !==
                        UserStatus.LOGGED_IN
                    ) {
                        this.refreshCurrentUser();
                    }
                } else if (
                    event.type === KeycloakEventType.OnAuthLogout ||
                    event.type === KeycloakEventType.OnAuthError ||
                    event.type === KeycloakEventType.OnAuthRefreshError
                ) {
                    this.invalidateUser();
                }
            });

        organisationService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshUsersOrganisations();
            });
    }

    ngOnDestroy() {
        this.loginEventSource.complete();
        this.userChangeSource.complete();
        this.keycloakStatusSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    getCurrentUser() {
        return this.userOrganisations$.getValue().currentUser;
    }

    getUserStatus() {
        return this.userOrganisations$.getValue().userStatus;
    }

    getPersonalOrganisation() {
        const { currentUser, organisationList } =
            this.userOrganisations$.getValue();
        if (currentUser == null || organisationList == null) {
            return null;
        }

        return this.injectForcedFeatureFlags(
            organisationList.find(
                o => o.id === currentUser.personalOrganisationId
            )
        );
    }

    findOrganisationForUser(organisationId: number) {
        return pipe(
            fromNullable(this.findUsersOrganisations()),
            chainNullableK((orgs: PersonsOrganisationDto[]) =>
                orgs.find(o => o.id === organisationId)
            )
        );
    }

    fetchOrganisationForUser(organisationId: number) {
        const { currentUser } = this.userOrganisations$.getValue();
        return this.http.get<PersonsOrganisationDto>(
            `${this.baseUrl}/webapi/organisations/${organisationId}/forUser`,
            {
                params: httpParamSerializer({
                    managingOrganisationId: currentUser.personalOrganisationId
                })
            }
        );
    }

    updateDefaultOrganisation(organisationId: number) {
        const { currentUser } = this.userOrganisations$.getValue();
        return firstValueFrom(
            this.http.put(`${this.baseUrl}/webapi/user/defaultOrganisation`, {
                organisationId
            })
        ).then(() => {
            currentUser.defaultOrganisationId = organisationId;
            return currentUser;
        });
    }

    getAlternativeAuthorisationToken() {
        return this.http.post<{ accessToken: string }>(
            `${this.baseUrl}/webapi/user/authorisationToken`,
            null
        );
    }

    markNotificationRead(notificationId: number) {
        return this.http.post(
            `${this.baseUrl}/webapi/user/notifications/${notificationId}`,
            null
        );
    }

    changePassword(password: string) {
        return this.http.put(`${this.baseUrl}/webapi/user/password`, {
            password: password
        });
    }

    requestAccountUpgrade(upgradeCommand: any) {
        return this.http.post(
            `${this.baseUrl}/webapi/user/upgrade`,
            upgradeCommand
        );
    }

    contactSales(command: ContactSalesCommand) {
        return this.http.post(
            `${this.baseUrl}/webapi/user/contactSales`,
            command
        );
    }

    contactSupport(command: ContactSalesCommand) {
        return this.http.post(
            `${this.baseUrl}/webapi/user/contactSupport`,
            command
        );
    }

    /**
     * Used when a system admin enters an organisation.
     * @param organisation the extra organisation
     */
    setAdditionalOrganisation(organisation: PersonsOrganisationDto) {
        const current = this.userOrganisations$.getValue();
        // Forces the null to undefined
        this.userOrganisations$.next({
            ...current,
            additionalOrganisation: organisation ?? undefined
        });
    }

    /**
     * Fetches the organisations for the current user, and injects any forced feature flags.
     *
     */
    findUsersOrganisations() {
        const { organisationList, additionalOrganisation } =
            this.userOrganisations$.getValue();
        if (organisationList == null) {
            return [];
        }
        return (
            additionalOrganisation != null
                ? organisationList.concat(additionalOrganisation)
                : organisationList
        ).map(o => this.injectForcedFeatureFlags(o));
    }

    private injectForcedFeatureFlags(
        organisation: PersonsOrganisationDto
    ): PersonsOrganisationDto {
        if (organisation == null) {
            return organisation;
        }
        return {
            ...organisation,
            featureFlags: organisation.featureFlags.concat(
                this.constants.FORCE_FEATURE_FLAGS
            )
        };
    }

    /**
     * A private method for updating the current user details within the UI.
     */
    async refreshCurrentUser(): Promise<void> {
        try {
            const results = await firstValueFrom(
                combineLatest([
                    this.http.get<CurrentPersonDto>(
                        `${this.baseUrl}/webapi/user`
                    ),
                    this.http.get<PersonsOrganisationDto[]>(
                        `${this.baseUrl}/webapi/user/organisations`
                    )
                ]).pipe(take(1))
            );
            this.updateCurrentUser(results[0], results[1]);
        } catch (error: any) {
            console.error('Error refreshing current user', error);
            this.invalidateUser();
        }
    }

    /**
     * This function requests a refresh of the organisation list without updating the user info.
     */
    refreshUsersOrganisations() {
        const { currentUser } = this.userOrganisations$.getValue();

        if (currentUser == null) {
            throw new Error('Refreshing organisations without user loaded');
        }
        return this.http
            .get<PersonsOrganisationDto[]>(
                `${this.baseUrl}/webapi/user/organisations`
            )
            .pipe(take(1))
            .subscribe({
                next: organisations =>
                    this.updateCurrentUser(currentUser, organisations),
                error: error => this.invalidateUser()
            });
    }

    /**
     * Invalidates the user session details in the UI, and swallow any exceptions.
     */
    private invalidateUser() {
        console.warn('invalidateUser');
        this.userOrganisations$.next({
            userStatus: UserStatus.LOGGED_OUT,
            currentUser: undefined,
            organisationList: undefined,
            additionalOrganisation: undefined
        });
    }

    logout(redirect?: string) {
        this.loginManager.logout(redirect);
        return this.invalidateUser();
    }

    /**
     * A notification to the service that the current user has been logged out. This is invoked by the authHttpInterceptor
     * when it gets a 401.
     *
     */
    loggedOut() {
        this.invalidateUser();
    }

    notifyUserChanged(
        userStatus: UserStatus,
        currentUser: CurrentPersonDto,
        organisationList: PersonsOrganisationDto[]
    ) {
        switch (userStatus) {
            case UserStatus.LOGGED_IN:
                if (currentUser == null) {
                    Sentry.captureMessage(
                        'Invalid transition to LOGGED_IN while currentUser is null',
                        'error'
                    );
                }
                if (organisationList == null) {
                    Sentry.captureMessage(
                        'Invalid transition to LOGGED_IN while currentUsersOrganisations is null',
                        'error'
                    );
                }
                this.userChangeSource.next({
                    type: UserStatus.LOGGED_IN,
                    currentUser,
                    currentUsersOrganisations: this.findUsersOrganisations()
                });
                break;

            case UserStatus.LOGGED_OUT:
                this.userChangeSource.next({
                    type: UserStatus.LOGGED_OUT
                });
                break;

            case UserStatus.UNKNOWN:
                this.userChangeSource.next({
                    type: UserStatus.UNKNOWN
                });
                break;
        }
    }

    updateCurrentUser(
        user: CurrentPersonDto,
        organisations: PersonsOrganisationDto[]
    ) {
        const { additionalOrganisation } = this.userOrganisations$.getValue();
        this.userOrganisations$.next({
            additionalOrganisation,
            userStatus: UserStatus.LOGGED_IN,
            currentUser: user,
            organisationList: organisations
        });
    }

    findLatestWhatsNew() {
        return this.http.get<WhatsNewDto>('/webapi/user/whatsNew');
    }

    findAllWhatsNew() {
        return this.http.get<WhatsNewDto>('/webapi/user/whatsNew/all');
    }

    findManagingOrganisations() {
        return this.http.get<SimpleOrganisationDto[]>(
            `${this.baseUrl}/webapi/user/managingOrganisations`
        );
    }

    addManagingOrganisations(managingOrganisationId: number) {
        return (
            this.http
                .post<SimpleOrganisationDto[]>(
                    `${this.baseUrl}/webapi/user/managingOrganisations`,
                    { organisationId: managingOrganisationId }
                )
                // Call next on the person service since this change affects personnel
                .pipe(tap(() => this.personService.markAsChanged()))
        );
    }

    removeManagingOrganisations(managingOrganisationId: number) {
        const url = `/webapi/user/managingOrganisations/${managingOrganisationId}`;
        return (
            this.http
                .delete<SimpleOrganisationDto[]>(`${this.baseUrl}${url}`)
                // Call next on the person service since this change affects personnel
                .pipe(tap(() => this.personService.markAsChanged()))
        );
    }
}
