import { Component, Input } from '@angular/core';
import {
    BatteryDto,
    BatteryService,
    BatterySetDto,
    BatterySetService,
    BatteryWithBatteryType,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameId,
    NameValue,
    PersonsOrganisationDto,
    WorkTracker,
    downloadCsv
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
    ImportBattery,
    uploadBatteryTemplate
} from '../../battery-upload.service';

@Component({
    selector: 'battery-list-download',
    template: `
        <div class="modal-header no-icon">
            <h3 class="modal-title">Export Battery List</h3>
            <button
                type="button"
                (click)="cancel()"
                aria-label="Close"
                class="close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <label>Select a battery type</label>
            <ng-select
                [items]="batteryTypes"
                [(ngModel)]="selectedBatteryTypeId"
                bindLabel="name"
                bindValue="id"
                [clearable]="false"
                [disabled]="working"
                appendTo="body"
            >
            </ng-select>
            <label>Select a battery status</label>
            <ng-select
                [items]="batteryStatuses"
                [(ngModel)]="statusFilter"
                bindLabel="name"
                bindValue="value"
                [clearable]="true"
                [disabled]="working"
                appendTo="body"
            >
            </ng-select>
            <div class="action-container">
                <loading-indicator [isLoading]="working"></loading-indicator>
                <button
                    type="button"
                    class="btn btn-default"
                    [disabled]="
                        working || batteries == null || batteries.length == 0
                    "
                    (click)="downloadBatteries()"
                >
                    Download List
                </button>
            </div>
        </div>
    `
})
export class BatteryListDownloadDialogue {
    @Input() organisation: PersonsOrganisationDto;
    @Input() batteries: BatteryWithBatteryType[];

    batterySets: BatterySetDto[];
    batteryTypes: NameId[];
    selectedBatteryTypeId: number = -1;

    batteryStatuses: NameValue[];
    statusFilter: BatteryDto.Status;

    toDownload: ImportBattery[];

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<BatteryListDownloadDialogue>,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.batteryStatuses = this.batteryService.getStatuses();
        this.setupBatteryTypes();
        this.refreshBatterySets();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshBatterySets() {
        this.batterySetService
            .findBatterySets(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sets => (this.batterySets = sets))
            .add(this.workTracker.createTracker());
    }

    setupBatteryTypes() {
        if (this.batteries == null || this.batteries.length === 0) {
            return;
        }
        this.batteryTypes = [{ name: 'All Types', id: -1 }].concat(
            this.batteries.reduce(
                (acc, b) =>
                    acc.find(t => t.id === b.batteryTypeId) == null
                        ? acc.concat({
                              name: `${b.batteryTypeMake}${
                                  b.batteryTypeMake != null ? ' ' : ''
                              }${b.batteryTypeModel}`,
                              id: b.batteryTypeId
                          })
                        : acc,
                []
            )
        );
        this.selectedBatteryTypeId = -1;
    }

    findBatteriesForType(downloadAfterCompleteFields?: FormlyFieldConfig) {
        const filtered = this.batteries.filter(b =>
            this.selectedBatteryTypeId != null &&
            this.selectedBatteryTypeId !== -1
                ? b.batteryTypeId === this.selectedBatteryTypeId
                : true &&
                  (this.statusFilter == null || b.status === this.statusFilter)
        );

        if (filtered.length == 0) {
            this.logging.warn(
                'No batteries match the currently selected filters'
            );
            return;
        }

        const withSets = filtered.map(b => ({
            ...b,
            batterySet:
                this.batterySets.find(
                    s => s.batteries.filter(bat => bat.id === b.id).length > 0
                )?.name ?? ''
        }));
        return combineLatest(
            withSets.map(b =>
                this.batteryService
                    .findFlightStatistics(b.id)
                    .pipe(map(h => ({ ...h, id: b.id })))
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                histories => {
                    this.toDownload = withSets.reduce<ImportBattery[]>(
                        (acc, b) => {
                            const batteryHistory = histories.find(
                                h => h.id === b.id
                            );
                            const flightTime =
                                batteryHistory?.flightTime != null
                                    ? batteryHistory.flightTime.toString()
                                    : '';
                            const initialCycles =
                                batteryHistory?.totalFlightCount != null
                                    ? batteryHistory.totalFlightCount.toString()
                                    : '';

                            return acc.concat({
                                batterySet: b.batterySet,
                                flightTime: flightTime,
                                initialCycles: initialCycles,
                                make: b.batteryTypeMake ?? '',
                                model: b.batteryTypeModel ?? '',
                                makeModel: `${b.batteryTypeMake}${
                                    b.batteryTypeMake != null ? ' ' : ''
                                }${b.batteryTypeModel ?? ''}`,
                                name: b.name ?? '',
                                nfcUid: b.nfcUid ?? '',
                                purchaseDate:
                                    b.convertedPurchaseDate != null
                                        ? b.convertedPurchaseDate.format(
                                              'DD-MM-YYYY'
                                          )
                                        : '',
                                serialNumber: b.manufacturerSerialNumber ?? ''
                            });
                        },
                        []
                    );
                    if (downloadAfterCompleteFields != null) {
                        downloadCsv(
                            downloadAfterCompleteFields,
                            this.toDownload
                        );
                    }
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `There was an error while trying to download this battery list: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    downloadBatteries() {
        const downloadTemplate: FormlyFieldConfig = {
            ...uploadBatteryTemplate,
            props: {
                label: `Exported_batteries_for_${this.organisation.name
                    .split(' ')
                    .join('_')}`
            }
        };
        this.findBatteriesForType(downloadTemplate);
    }

    cancel() {
        this.modal.hide();
    }
}
