import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OutstandingTasksViewComponent } from '../view/outstanding-tasks-view/outstanding-tasks-view.component';
import { OutstandingTasksSidebarComponent } from '../view/outstanding-tasks-sidebar/outstanding-tasks-sidebar.component';
import { FlightLogsRoutingModule } from '@flyfreely-portal-ui/flight-logs';
import { OrganisationAdminRoutingModule } from '@flyfreely-portal-ui/organisation-admin';
import { UserProfileRoutingModule } from '@flyfreely-portal-ui/user-profile';
import { FEATURE_OUTSTANDING_TASKS_VIEW } from '@flyfreely-portal-ui/flyfreely';
import { FeatureFlagGuard } from 'libs/ui/src/lib/featureFlag.guard';
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: OutstandingTasksViewComponent,
                canActivate: [FeatureFlagGuard],
                data: { requiredFeatureFlag: FEATURE_OUTSTANDING_TASKS_VIEW },
                runGuardsAndResolvers: 'always',
                children: [
                    {
                        path: 'flightlogs',
                        loadChildren: () => FlightLogsRoutingModule
                    },
                    {
                        path: 'orgadmin',
                        loadChildren: () => OrganisationAdminRoutingModule
                    },
                    {
                        path: 'userprofile',
                        loadChildren: () => UserProfileRoutingModule
                    }
                ]
            },
            {
                path: '',
                component: OutstandingTasksSidebarComponent,
                outlet: 'sidebar'
            }
        ])
    ],
    exports: [RouterModule]
})
export class OutstandingTasksRoutingModule {}
