<div class="fill" *ngIf="authorisationType != null">
    <button
        type="button"
        class="caa-button"
        [ngClass]="{
            'authorisation-incomplete':
                state == 'INCOMPLETE' || state == 'OPTIONAL',
            'authorisation-available': state == 'CAN_REQUEST',
            'authorisation-pass':
                state == 'APPROVED_PROVISIONAL' ||
                state == 'APPROVED' ||
                state == 'DECLARED',
            'authorisation-pending': state == 'PENDING',
            'authorisation-fail':
                state == 'NO_AUTHORISATION' || state == 'CANCELLED',
            'authorisation-closed': state == 'COMPLETED' || state == 'CLOSED'
        }"
        (click)="linkClicked.emit()"
    >
        <span class="fal fa-radar"></span>
        {{ authorisationType | formatAuthorisationType }} Authorisation
    </button>
    <div
        class="authorisation-details"
        [ngClass]="{
            'authorisation-incomplete':
                state == 'INCOMPLETE' || state == 'OPTIONAL',
            'authorisation-available': state == 'CAN_REQUEST',
            'authorisation-pass':
                state == 'APPROVED_PROVISIONAL' ||
                state == 'APPROVED' ||
                state == 'DECLARED',
            'authorisation-pending': state == 'PENDING',
            'authorisation-fail':
                state == 'NO_AUTHORISATION' || state == 'CANCELLED',
            'authorisation-closed': state == 'COMPLETED' || state == 'CLOSED'
        }"
    >
        <ng-container [ngSwitch]="state">
            <ng-container *ngSwitchCase="'INCOMPLETE'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                can be requested after minimum data requirements are met: RPIC,
                Flight Area defined, Workflow, Date and Duration of your mission
            </ng-container>
            <ng-container *ngSwitchCase="'OPTIONAL'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                not required but available and recommended for this mission
            </ng-container>
            <ng-container *ngSwitchCase="'CAN_REQUEST'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                is required for this mission
            </ng-container>
            <ng-container *ngSwitchCase="'APPROVED_PROVISIONAL'">
                Request has been provisionally authorised pending final ATC
                authorisation at time of operation
            </ng-container>
            <ng-container *ngSwitchCase="'APPROVED'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                request confirmed
            </ng-container>
            <ng-container *ngSwitchCase="'DECLARED'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                request declared
            </ng-container>
            <ng-container *ngSwitchCase="'PENDING'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                request submitted and is pending a decision
            </ng-container>
            <ng-container *ngSwitchCase="'NO_AUTHORISATION'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                request rejected
            </ng-container>
            <ng-container *ngSwitchCase="'CANCELLED'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                has been cancelled
            </ng-container>
            <ng-container *ngSwitchCase="'COMPLETED'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                is now closed
            </ng-container>
            <ng-container *ngSwitchCase="'CLOSED'">
                {{ authorisationType | formatAuthorisationType }} authorisation
                is now closed
            </ng-container>
        </ng-container>
        <button
            *ngIf="state == 'CAN_REQUEST' || state == 'CANCELLED'"
            type="button"
            class="btn btn-default-action"
            style="margin-top: 16px"
            (click)="requestAuthorisation.emit()"
            [disabled]="working || disabled"
        >
            Request Authorisation
        </button>
    </div>
</div>
