import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type FeatureType = 'NEW' | 'BETA' | 'PREVIEW';

@Component({
    selector: 'new-feature',
    template: `
        <ng-container [ngSwitch]="type">
            <span
                *ngSwitchCase="'NEW'"
                class="beta feature"
                [popover]="newFeatureTemplate"
                container="body"
                [placement]="placement"
                [outsideClick]="true"
                triggers="mouseenter:mouseleave"
                >New</span
            >
            <span
                *ngSwitchCase="'BETA'"
                class="beta feature"
                [popover]="newFeatureTemplate"
                container="body"
                [placement]="placement"
                [outsideClick]="true"
                triggers="mouseenter:mouseleave"
                >Beta</span
            >
            <span
                *ngSwitchCase="'PREVIEW'"
                class="preview feature"
                [popover]="newFeatureTemplate"
                container="body"
                [placement]="placement"
                [outsideClick]="true"
                triggers="mouseenter:mouseleave"
                >Preview</span
            >
        </ng-container>
        <ng-template #newFeatureTemplate>
            <p>
                <strong>{{ name }}</strong>
            </p>
            <p>{{ description }}</p>
            <ng-container [ngSwitch]="type">
                <p *ngSwitchCase="'BETA'" class="feature-type">
                    Beta features may be refined or moved to different plan
                    levels before they are released.
                </p>
                <p *ngSwitchCase="'PREVIEW'" class="feature-type">
                    Preview features may change significantly before they are
                    released, or be removed entirely. We make them available to
                    get feedback.
                </p>
            </ng-container>
        </ng-template>
    `,
    styles: [
        `
            :host {
                margin-left: 5px;
            }
            .feature {
                border-radius: 10px;
                font-size: 10px;
                padding: 5px;
                margin-top: 5px;
                display: inline;
                border: 1px solid;
                background-color: #fafafa;
            }
            .beta {
                color: #42b983 !important;
            }
            .preview {
                color: #ff8558 !important;
            }
            .feature-type {
                font-style: italic;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewFeature {
    @Input()
    type: FeatureType = 'BETA';

    @Input()
    name: string;

    @Input()
    description: string;

    @Input()
    knowledgeBaseUrl: string;

    @Input()
    placement = 'bottom';
}
