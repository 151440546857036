import { FlightConformanceResultDto } from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';

export const toConformanceResultStatusArray = (status: string) => {
    const conformance = FlightConformanceResultDto.Status;
    switch (status) {
        case 'NO_BREACHES':
            return [conformance.NO_BREACHES];

        case 'NO_POLICY':
            return [conformance.NO_POLICY];

        case 'BREACHES':
            return [conformance.PRELIMINARY, conformance.SENT];

        default:
            return null;
    }
};

export const findMostPressingConformance = (
    statuses: FlightConformanceResultDto[]
) => {
    if (statuses == null || statuses.length === 0) {
        return null;
    }
    const conformance = FlightConformanceResultDto.Status;
    const filtered = statuses.reduce(
        (acc, s) => (acc.includes(s.status) ? acc : acc.concat(s.status)),
        []
    );
    if (filtered.includes(conformance.SENT)) {
        return conformance.SENT;
    } else if (filtered.includes(conformance.PRELIMINARY)) {
        return conformance.PRELIMINARY;
    } else if (filtered.includes(conformance.NO_BREACHES)) {
        return conformance.NO_BREACHES;
    } else if (filtered.includes(conformance.NO_POLICY)) {
        return conformance.NO_POLICY;
    } else {
        const earliest = statuses.reduce(
            (acc, s) =>
                moment(s.sendTime).isBefore(moment(acc.sendTime)) ? s : acc,
            statuses[0]
        );
        return earliest;
    }
};
