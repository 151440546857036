// This file is for the web UI specific forms code

import { FormlyFieldConfig } from '@ngx-formly/core';
import { httpParamSerializer } from '../../services';
import { toLookup } from '../../utils';
import {
    DisplayCondition,
    FormControlDto,
    FormSectionDto,
    OptionDto
} from '../api';
import { ControlValues, isControlVisible } from './forms';

/**
 * Remove the null groups because formly will render them.
 */
function removeNullGroup(option: OptionDto) {
    if (option.group === null) {
        delete option.group;
    }
    return option;
}

/**
 * Generate the display conditions expression for use with Formly.
 *
 * @param displayCondition the display conditions object
 */
function generateFormlyHideExpression(
    displayCondition: DisplayCondition
): string {
    if (!displayCondition) {
        return undefined;
    }

    function negate(expression: string) {
        return `!(${expression})`;
    }

    function singleOperand(op: string, ix = 0) {
        return `model[${displayCondition.subjectControlId}] ${op} "${displayCondition.values[ix]}"`;
    }

    if (displayCondition.comparator === 'EQUAL') {
        return negate(singleOperand('=='));
    } else if (displayCondition.comparator === 'NOT_EQUAL') {
        return negate(singleOperand('!='));
    } else if (displayCondition.comparator === 'GT') {
        return negate(singleOperand('>'));
    } else if (displayCondition.comparator === 'GTE') {
        return negate(singleOperand('>='));
    } else if (displayCondition.comparator === 'LT') {
        return negate(singleOperand('<'));
    } else if (displayCondition.comparator === 'LTE') {
        return negate(singleOperand('<='));
    } else if (displayCondition.comparator === 'BETWEEN') {
        return negate(`${singleOperand('>=', 0)} && ${singleOperand('<=', 1)}`);
    } else if (displayCondition.comparator === 'NOT_BETWEEN') {
        return `${singleOperand('>=', 0)} && ${singleOperand('<=', 1)}`;
    }
    return 'true';
}

export function defaultBuildFormAttachmentUrl(
    managingOrganisationId: number,
    formId: number,
    attachmentId: number,
    attachmentVersionId: number
): string {
    const params = httpParamSerializer({
        attachmentVersionId,
        managingOrganisationId
    }).toString();
    return `/webapi/forms/${formId}/attachments/${attachmentId}?${params}`;
}

/**
 * Builds a Formly form control from a FlyFreely form control definition.
 *
 * @param field the FlyFreely form control
 */
export function buildFormlyField(
    field: FormControlDto,
    formId: number,
    managingOrganisationId: number,
    buildFormAttachmentUrl: typeof defaultBuildFormAttachmentUrl
): FormlyFieldConfig {
    const layoutOptions = {
        columnSpan: field.columnSpan,
        columnOffset: field.columnOffset,
        flyfreelyField: field
    };

    if (field.type === 'number') {
        return {
            key: field.id.toString(),
            type: 'input',
            props: {
                ...layoutOptions,
                label: field.label,
                type: 'number',
                placeholder: field.placeholder ? field.placeholder : undefined,
                description: field.description,
                required: field.required,
                min: field.config ? parseInt(field.config.min, 10) : undefined,
                max: field.config ? parseInt(field.config.max, 10) : undefined
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'range') {
        return {
            key: field.id.toString(),
            type: 'range',
            props: {
                ...layoutOptions,
                label: field.label,
                type: 'range',
                placeholder: field.placeholder ? field.placeholder : undefined,
                description: field.description,
                required: field.required,
                min: field.config ? parseInt(field.config.min, 10) : undefined,
                max: field.config ? parseInt(field.config.max, 10) : undefined
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'text') {
        return {
            key: field.id.toString(),
            type: 'input',
            props: {
                ...layoutOptions,
                label: field.label,
                placeholder: field.placeholder ? field.placeholder : undefined,
                description: field.description,
                required: field.required
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'textarea') {
        return {
            key: field.id.toString(),
            type: 'textarea',
            props: {
                ...layoutOptions,
                label: field.label,
                placeholder: field.placeholder ? field.placeholder : undefined,
                description: field.description,
                required: field.required
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'single-select') {
        return {
            key: field.id.toString(),
            type: 'ng-select',
            props: {
                ...layoutOptions,
                label: field.label,
                placeholder: field.placeholder ? field.placeholder : undefined,
                description: field.description,
                required: field.required,
                options: field.options
                    ? field.options.map(removeNullGroup)
                    : [],
                labelProp: 'name'
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'boolean') {
        return {
            key: field.id.toString(),
            type: 'radio',
            props: {
                ...layoutOptions,
                label: field.label,
                placeholder: field.placeholder ? field.placeholder : undefined,
                description: field.description,
                required: field.required,
                options: [
                    { name: 'Yes', value: 'true' },
                    { name: 'No', value: 'false' }
                ],
                labelProp: 'name'
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'checkbox') {
        return {
            key: field.id.toString(),
            type: 'check',
            props: {
                ...layoutOptions,
                label: field.label,
                placeholder: field.placeholder ? field.placeholder : undefined,
                description: field.description,
                required: field.required
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'info') {
        return {
            key: field.id.toString(),
            type: 'info',
            props: {
                ...layoutOptions,
                message: field.description
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'warning') {
        return {
            key: field.id.toString(),
            type: 'warning',
            props: {
                ...layoutOptions,
                message: field.description
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'warning-with-acknowledgement') {
        return {
            key: field.id.toString(),
            type: 'warning-with-acknowledgement',
            props: {
                ...layoutOptions,
                message: field.description
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'image') {
        return {
            key: field.id.toString(),
            type: 'image',
            props: {
                ...layoutOptions,
                description: field.description,
                imageUrl: buildFormAttachmentUrl(
                    managingOrganisationId,
                    formId,
                    parseInt(field.config.attachmentId),
                    parseInt(field.config.attachmentVersionId)
                )
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    } else if (field.type === 'calculated') {
        return {
            key: field.id.toString(),
            type: 'calculatedValue',
            props: {
                ...layoutOptions,
                label: field.label
            },
            hideExpression: generateFormlyHideExpression(field.displayCondition)
        };
    }
}

/**
 * Converts a form field to a column so that the label isn't displayed.
 *
 * @param field the form field
 */
export function convertToColumn(field: FormlyFieldConfig) {
    if (field == null) {
        return null;
    }
    field.props.heading = field.props ? field.props.label : '';
    field.props.label = null;
    return field;
}

/**
 * Generate a key for the response object which will address the given
 * form section.
 *
 * @param section the section requiring the key
 */
export function calculateSectionKey(section: FormSectionDto) {
    return `section-${section.id}-table`;
}

const NO_RESPONSE = '-';

interface HtmlDisplay {
    type: 'html';
    value: string;
}

interface ImageDisplay {
    type: 'img';
    value: string;
}

/**
 * Returns the displayable version of the value provided.
 *
 */
export function buildDisplayValue(
    formId: number,
    control: FormControlDto,
    value: any,
    attachmentUrl: (
        attachmentId: number,
        attachmentVersionId: number | null
    ) => string
): HtmlDisplay | ImageDisplay {
    if (control.type === 'single-select') {
        for (const option of control.options) {
            if (String(option.value) === String(value)) {
                return { type: 'html', value: String(option.name) };
            }
        }
        // Default to the value
        return { type: 'html', value };
    } else if (control.type === 'boolean') {
        return {
            type: 'html',
            value: value == null ? NO_RESPONSE : value === 'true' ? 'Yes' : 'No'
        };
    } else if (control.type === 'checkbox') {
        return {
            type: 'html',
            value:
                value == null
                    ? NO_RESPONSE
                    : value === 'true'
                    ? 'Checked'
                    : 'Unchecked'
        };
    } else if (control.type === 'info') {
        return {
            type: 'html',
            value: `<i>${control.description}</i>`
        };
    } else if (control.type === 'warning') {
        return {
            type: 'html',
            value: `<b>${control.description}</b>`
        };
    } else if (control.type === 'warning-with-acknowledgement') {
        const acknowledged = value ? 'Acknowledged' : 'Not Acknowledged';
        return {
            type: 'html',
            value: `<b>${control.description}</b><br/><b><i>${acknowledged}</i></b>`
        };
    } else if (control.type === 'image') {
        return {
            type: 'img',
            value: attachmentUrl(
                parseInt(control.config.attachmentId),
                control.config.attachmentVersionId != null
                    ? parseInt(control.config.attachmentVersionId)
                    : null
            )
        };
    } else {
        return {
            type: 'html',
            value: value == null ? NO_RESPONSE : value
        };
    }
}

/**
 * Converts data that may have been collected on a form, to the list
 * style that is used within the storage system
 */
export function prepareForStorage(
    sections: FormSectionDto[],
    values: { [key: string]: any }
): ControlValues {
    // Create a lookup for the controls, and a list of controls in their natural order
    const { controlLookup, controlIds } = sections.reduce(
        (acc, sec) => ({
            controlLookup: {
                ...acc.controlLookup,
                ...sec.controls.reduce(toLookup, {})
            },
            controlIds: acc.controlIds.concat(sec.controls.map(c => c.id))
        }),
        { controlLookup: {}, controlIds: [] }
    );

    const validKeys = controlIds.filter(k => k in values);

    const filterVisibleData = (
        cId: string,
        data: string[],
        acc: ControlValues
    ) =>
        data.map((v, ix) =>
            isControlVisible(controlLookup[cId], ix, acc) ? v : ''
        );

    return validKeys.reduce((acc, key) => {
        acc[key] = filterVisibleData(
            key,
            Array.isArray(values[key]) ? values[key] : [values[key]],
            acc
        );
        return acc;
    }, {});
}

/**
 * This flatterns a data structure that possibly includes table data.
 *
 * @param formFields fields that we want to extract the data for
 * @param responses the formly data object
 */
export function flattern(
    formFields: FormlyFieldConfig[] | FormlyFieldConfig[],
    responses: any
): ControlValues {
    const result = {};
    for (const field of formFields) {
        if (field.type !== 'table' && field.type !== 'twelve-column') {
            result[<string>field.key] = [responses[<string>field.key]];
            continue;
        }

        const columns =
            field.props.fields != null
                ? field.props.fields.map((f: FormlyFieldConfig) => f.key)
                : field.props.columns.map((f: FormColumn) => f.key);
        // Merge the nested keys
        for (const nestedField of columns) {
            result[nestedField] = [];
        }

        for (const row of responses[<string>field.key]) {
            for (const nestedField of columns) {
                result[nestedField].push(
                    row[nestedField] != null ? row[nestedField] : ''
                );
            }
        }
    }
    return result;
}

const numericalControlTypes = ['number', 'range'];

/**
 * Converts the data to a form suitable for the control.
 * @param controlType the type of the control that holds this data
 * @param values the value array
 */
function convertValues(controlType: string, values: string[]) {
    if (values == null) {
        return [];
    }
    if (numericalControlTypes.indexOf(controlType) !== -1) {
        return values.map(v => (v !== '' ? parseInt(v, 10) : v));
    }

    return values;
}

/**
 * Generate the response values that are in the structure required to be edited by
 * Formly.
 *
 * @param sections array of sections
 * @param values response values
 */
export function prepareForEdit(
    sections: FormSectionDto[],
    values: ControlValues
): ControlValues {
    const result = {};
    for (const section of sections) {
        if (section.repeatingGroup) {
            const length = section.controls.reduce(
                (acc, c) =>
                    Math.max(acc, values[c.id] ? values[c.id].length : 0),
                0
            );
            const sectionResult: any[] = [];
            for (let i = 0; i < length; i++) {
                sectionResult.push({});
            }

            for (const control of section.controls) {
                const convertedValues =
                    convertValues(control.type, values[control.id]) || [];
                for (let i = 0; i < convertedValues.length; i++) {
                    sectionResult[i][control.id] = convertedValues[i];
                }
            }

            result[calculateSectionKey(section)] = sectionResult;
        } else {
            for (const control of section.controls) {
                result[control.id] =
                    values[control.id] != null &&
                    values[control.id].length === 1
                        ? convertValues(control.type, values[control.id])[0]
                        : null;
            }
        }
    }
    return result;
}

export function evaulateDisplayCondition(
    control: FormControlDto,
    values: ControlValues
): boolean {
    if (!control.displayCondition) {
        return true;
    }
    const val1 = control.displayCondition.values;
    const val2 =
        control.displayCondition.subjectControlId in values
            ? values[control.displayCondition.subjectControlId][0]
            : null;

    if (control.displayCondition.comparator === 'EQUAL') {
        return val1[0] === val2;
    } else if (control.displayCondition.comparator === 'NOT_EQUAL') {
        return val1[0] !== val2;
    } else if (control.displayCondition.comparator === 'GT') {
        return parseFloat(val1[0]) < parseFloat(val2);
    } else if (control.displayCondition.comparator === 'GTE') {
        return parseFloat(val1[0]) <= parseFloat(val2);
    } else if (control.displayCondition.comparator === 'LT') {
        return parseFloat(val1[0]) > parseFloat(val2);
    } else if (control.displayCondition.comparator === 'LTE') {
        return parseFloat(val1[0]) >= parseFloat(val2);
    } else if (control.displayCondition.comparator === 'BETWEEN') {
        return (
            parseFloat(val1[0]) <= parseFloat(val2) &&
            parseFloat(val1[1]) >= parseFloat(val2)
        );
    } else if (control.displayCondition.comparator === 'NOT_BETWEEN') {
        return !(
            parseFloat(val1[0]) <= parseFloat(val2) &&
            parseFloat(val1[1]) >= parseFloat(val2)
        );
    }
}

export interface ControlDisplayFlags {
    [controlId: number]: boolean;
}

/**
 * Builds a data structure indicating which elements should be displayed and which hidden.
 *
 * @param sections an array of form sections
 * @param values the values for the form being rendered
 */
export function buildDisplayCondition(
    sections: FormSectionDto[],
    values: ControlValues
): ControlDisplayFlags {
    return sections.reduce(
        (allControls: ControlDisplayFlags, section) => ({
            ...allControls,
            ...section.controls.reduce((acc: ControlDisplayFlags, control) => {
                acc[control.id] = evaulateDisplayCondition(control, values);
                return acc;
            }, {})
        }),
        {}
    );
}

/**
 * Safely read control value from values
 * @param values
 * @param controlId
 * @param index
 */
export function readControlValue(
    values: ControlValues,
    controlId: number,
    index: number
) {
    if (!(controlId in values)) {
        return undefined;
    }
    if (!(index in values[controlId])) {
        return undefined;
    }
    return values[controlId][index];
}

/**
 * The type of a form column in a repeating section
 */
export interface FormColumn {
    name: string;
    key: string;
}
