import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    PersonsOrganisationDto,
} from '../model/api';
import {
    LoggedInUser,
    UserService,
    UserStatus
} from '../services';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PageTitle {
    currentOrganisation: PersonsOrganisationDto;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(private pageTitle: Title, private userService: UserService) {
        this.userService.userChange$
            .pipe(
                filter(c => c.type === UserStatus.LOGGED_IN),
                map((change: LoggedInUser) => change.currentUser),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(user => {
                this.refreshTitle();
            });
    }

    ngOnInit() {
        this.refreshTitle();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setTitle(organisationName: string) {
        this.pageTitle.setTitle(
            organisationName != null
                ? `${organisationName} - FlyFreely`
                : `FlyFreely`
        );
    }

    refreshTitle() {
        this.currentOrganisation != null
            ? this.setTitle(this.currentOrganisation.name)
            : this.setTitle(null);
    }

    refreshOrganisation(organisation: PersonsOrganisationDto) {
        if (organisation == null) {
            this.refreshTitle();
            return;
        }
        this.currentOrganisation = organisation;
        this.setTitle(organisation.name);
    }
}
