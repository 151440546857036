/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.11-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaintenanceScheduleTaskCommand } from './maintenanceScheduleTaskCommand';
import { WorkflowMappedEntity } from './workflowMappedEntity';
import { UpdateMaintenanceScheduleTriggerCommand } from './updateMaintenanceScheduleTriggerCommand';
import { WorkflowAttachmentRequirement } from './workflowAttachmentRequirement';


/**
 * Describes the maintenance schedule
 */
export interface CreateMaintenanceScheduleCommand { 
    /**
     * The ID of the organisation this is being created on
     */
    organisationId?: number;
    /**
     * The forms associated with this workflow, mapped by workflow step
     */
    mappedForms: { [key: string]: Array<WorkflowMappedEntity>; };
    /**
     * The checklists associated with this workflow, mapped by workflow step
     */
    mappedAttachmentRequirements: { [key: string]: Array<WorkflowAttachmentRequirement>; };
    name: string;
    resourceCategory: CreateMaintenanceScheduleCommand.ResourceCategory;
    rpaCategory?: CreateMaintenanceScheduleCommand.RpaCategory;
    equipmentCategory?: CreateMaintenanceScheduleCommand.EquipmentCategory;
    /**
     * ${swagger.MaintenanceSchedule.inFieldServiceableComponentGroupIds}
     */
    inFieldServiceableComponentGroupIds: Array<number>;
    taskList: Array<MaintenanceScheduleTaskCommand>;
    triggerList: Array<UpdateMaintenanceScheduleTriggerCommand>;
}
export namespace CreateMaintenanceScheduleCommand {
    export type ResourceCategory = 'CRAFT' | 'BATTERY_SET' | 'BATTERY' | 'EQUIPMENT' | 'UNLISTED_RPA';
    export const ResourceCategory = {
        CRAFT: 'CRAFT' as ResourceCategory,
        BATTERY_SET: 'BATTERY_SET' as ResourceCategory,
        BATTERY: 'BATTERY' as ResourceCategory,
        EQUIPMENT: 'EQUIPMENT' as ResourceCategory,
        UNLISTED_RPA: 'UNLISTED_RPA' as ResourceCategory
    };
    export type RpaCategory = 'MULTIROTOR' | 'HELICOPTOR' | 'AEROPLANE' | 'POWERED_LIFT' | 'AIRSHIP' | 'OTHER';
    export const RpaCategory = {
        MULTIROTOR: 'MULTIROTOR' as RpaCategory,
        HELICOPTOR: 'HELICOPTOR' as RpaCategory,
        AEROPLANE: 'AEROPLANE' as RpaCategory,
        POWERED_LIFT: 'POWERED_LIFT' as RpaCategory,
        AIRSHIP: 'AIRSHIP' as RpaCategory,
        OTHER: 'OTHER' as RpaCategory
    };
    export type EquipmentCategory = 'PAYLOAD' | 'BASE_STATION' | 'CONTROLLER' | 'MONITOR' | 'CONTROL_ACCESSORIES' | 'COMMUNICATION_EQUIPMENT' | 'OTHER';
    export const EquipmentCategory = {
        PAYLOAD: 'PAYLOAD' as EquipmentCategory,
        BASE_STATION: 'BASE_STATION' as EquipmentCategory,
        CONTROLLER: 'CONTROLLER' as EquipmentCategory,
        MONITOR: 'MONITOR' as EquipmentCategory,
        CONTROL_ACCESSORIES: 'CONTROL_ACCESSORIES' as EquipmentCategory,
        COMMUNICATION_EQUIPMENT: 'COMMUNICATION_EQUIPMENT' as EquipmentCategory,
        OTHER: 'OTHER' as EquipmentCategory
    };
}


