<ng-container screenAnalytics="flight-log-assign-mission">
    <div class="form-group top-buffer" style="flex: none">
        <enhanced-help
            *ngIf="enhancedHelpActive"
            screenName="flight-log-overview"
            componentName="flight-log-assign"
            [compact]="true"
            [alwaysActive]="true"
        >
        </enhanced-help>
        <div class="horizontal-container flex-centered">
            <div
                class="input-group"
                btnRadioGroup
                [(ngModel)]="isCreatingRetrospective"
                style="padding-top: 5px"
            >
                <label
                    class="btn btn-sm btn-pill"
                    [btnRadio]="true"
                    [disabled]="working"
                    tooltip="Create a mission out of this flight log."
                    retrospective
                    >Create Retrospective Mission</label
                >
                <label
                    class="btn btn-sm btn-pill"
                    [btnRadio]="false"
                    [disabled]="working"
                    tooltip="Assign this flight log to an existing mission"
                    c
                    >Assign To Existing Mission</label
                >
            </div>
        </div>
    </div>
    <div class="vertical-container fill">
        <ng-container *ngIf="!isCreatingRetrospective">
            <div
                *ngIf="
                    candidateMissions != null && candidateMissions.length === 0
                "
            >
                <p>
                    There are no existing missions close to the time this flight
                    occurred. Please create one and then assign this mission.
                </p>
            </div>
            <div
                style="height: 100%"
                *ngIf="
                    candidateMissions != null && candidateMissions.length > 0
                "
                class="vertical-container fill"
            >
                <p>
                    There are {{ candidateMissions.length }} candidate missions.
                </p>
                <div>
                    <label>Mission</label>
                    <ng-select
                        [items]="candidateMissions"
                        [(ngModel)]="selectedMission"
                        (change)="onMissionSelected($event)"
                        [clearable]="false"
                        [searchFn]="missionSearch"
                    >
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                            {{ item.name }}
                            {{ item.missionDate | formatDateTime }}
                        </ng-template>
                    </ng-select>
                </div>
                <!-- <div class="top-buffer">
                    <label [ngClass]="{ readonly: candidateFlights == null }">
                        Flight Assignment for original log
                    </label>
                    <ng-select
                        [readonly]="candidateFlights == null"
                        [items]="candidateFlights"
                        (change)="onFlightSelected($event)"
                        [(ngModel)]="selectedFlight"
                        [searchFn]="flightNumberSearch"
                    >
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                            {{
                                item.number == -1
                                    ? 'Create a new flight'
                                    : 'Flight ' + item.number
                            }}
                        </ng-template>
                    </ng-select>
                </div> -->
                <p
                    class="help-block"
                    *ngIf="
                        selectedFlight != null && selectedFlight.number == -1
                    "
                >
                    A new flight will be created for this mission and this
                    flight log will be assigned to it.
                </p>
                <div class="top-buffer" *ngIf="flightRpa != null">
                    <label>RPA</label>
                    <div>Flight: {{ flightRpa | formatRpa }}</div>
                    <div>Log File: {{ rpaName }}</div>
                    <div></div>
                    <div *ngIf="rpaLinkingStatus === 'MATCH'">
                        The RPA matches
                    </div>
                    <div *ngIf="rpaLinkingStatus === 'NO_MATCH'">
                        The RPA on the flight does not match the RPA in the
                        flight log, based on the flight controller serial number
                    </div>
                    <div *ngIf="rpaLinkingStatus === 'CAN_ASSIGN_TO_LOG'">
                        The RPA will be linked to this log
                    </div>
                    <div *ngIf="rpaLinkingStatus === 'CAN_ASSIGN_TO_RPA'">
                        The RPA can be setup to automatically link, would you
                        like to do this?
                        <button
                            type="button"
                            (click)="assignRpa()"
                            class="btn btn-xs btn-primary"
                        >
                            Assign
                        </button>
                    </div>
                </div>

                <div
                    class="top-buffer container-scroll fill"
                    style="
                        position: relative;
                        margin-bottom: -10px;
                        max-width: 600px;
                        padding: 0 5px;
                    "
                    *ngIf="compatibleLogs != null && compatibleLogs.length > 0"
                >
                    <ngx-table
                        class="buffer-top"
                        [data]="compatibleLogs"
                        [configuration]="configurationSecondTable"
                        [columns]="columnsSecondTable"
                        (event)="eventEmitted($event)"
                    >
                        <ng-template let-row let-index="index">
                            <div
                                class="checkbox"
                                [ngClass]="{ readonly: row.locked }"
                            >
                                <label>
                                    <input
                                        type="checkbox"
                                        [ngModelOptions]="{ standalone: true }"
                                        [(ngModel)]="row.selected"
                                        [disabled]="row.locked"
                                    />
                                    <span class="cr"
                                        ><i
                                            class="cr-icon glyphicon glyphicon-ok"
                                        ></i
                                    ></span>
                                </label>
                            </div>
                            <ng-container
                                *ngFor="let col of columnsSecondTable"
                            >
                                <td
                                    [ngSwitch]="col.key"
                                    [ngClass]="{
                                        'table-actions':
                                            col.key === 'selected' ||
                                            col.key === 'action'
                                    }"
                                >
                                    <div *ngSwitchCase="'flight'">
                                        <ng-select
                                            [readonly]="
                                                candidateFlights == null
                                            "
                                            [items]="candidateFlights"
                                            [(ngModel)]="row.selectedFlightId"
                                            [searchFn]="flightNumberSearch"
                                            [clearable]="true"
                                            bindValue="id"
                                            placeholder="No Flight"
                                        >
                                            <ng-template
                                                ng-option-tmp
                                                ng-label-tmp
                                                let-item="item"
                                            >
                                                {{
                                                    item.number == -1
                                                        ? 'Create a new flight'
                                                        : 'Flight ' +
                                                          item.number
                                                }}
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div *ngSwitchCase="'takeoffDifference'">
                                        {{ parseTakeOffDifference(row) }}
                                    </div>
                                    <button
                                        *ngSwitchCase="'action'"
                                        class="btn btn-tertiary btn-sm"
                                        [disabled]="working"
                                        (click)="viewFlightLog.emit(row.id)"
                                    >
                                        <span
                                            class="fal fa-map"
                                            tooltip="View flight log"
                                            placement="top"
                                        ></span>
                                    </button>
                                    <div *ngSwitchDefault>
                                        {{ row[col.key] }}
                                    </div>
                                </td>
                            </ng-container>
                        </ng-template>
                    </ngx-table>
                </div>
                <button
                    [disabled]="selectedMission == null"
                    class="btn btn-sm btn-block btn-primary top-buffer"
                    type="button"
                    (click)="assignFlightLog()"
                    angulartics2On="click"
                    angularticsAction="assign-mission"
                    angularticsCategory="flight-logs"
                >
                    Assign To Existing Mission
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="isCreatingRetrospective">
            <div class="vertical-container fill">
                <div>
                    <label>Mission Name</label>
                    <input
                        type="text"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        required
                        [disabled]="working"
                        [(ngModel)]="retrospectiveMissionName"
                    />
                </div>
                <div class="top-buffer">
                    <label>Pilot In Command</label>
                    <ng-select
                        [items]="pilots"
                        [(ngModel)]="retrospectiveMissionPilot"
                        [clearable]="false"
                        [searchFn]="personSearch"
                    >
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                            {{ item | formatPerson }}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="top-buffer">
                    <label>Operation Type</label>
                    <ng-select
                        [items]="operationTypes"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="retrospectiveOperationTypeId"
                        [clearable]="true"
                    >
                    </ng-select>
                </div>
                <div class="top-buffer">
                    <label>Workflow</label>
                    <ng-select
                        [items]="availableWorkflows"
                        bindLabel="workflowName"
                        bindValue="id"
                        [(ngModel)]="retrospectiveMissionWorkflowId"
                        [clearable]="false"
                    >
                    </ng-select>
                </div>
                <div
                    class="top-buffer container-scroll fill"
                    style="
                        position: relative;
                        margin-bottom: -10px;
                        max-width: 600px;
                        padding: 0 5px;
                    "
                    *ngIf="compatibleLogs != null && compatibleLogs.length > 0"
                >
                    <ngx-table
                        class="buffer-top"
                        [data]="compatibleLogs"
                        [configuration]="configuration"
                        [columns]="columns"
                        (event)="eventEmitted($event)"
                    >
                        <ng-template let-row let-index="index">
                            <div
                                class="checkbox"
                                [ngClass]="{ readonly: row.locked }"
                            >
                                <label>
                                    <input
                                        type="checkbox"
                                        [ngModelOptions]="{ standalone: true }"
                                        [(ngModel)]="row.selected"
                                        [disabled]="row.locked"
                                    />
                                    <span class="cr"
                                        ><i
                                            class="cr-icon glyphicon glyphicon-ok"
                                        ></i
                                    ></span>
                                </label>
                            </div>
                            <ng-container *ngFor="let col of columns">
                                <td
                                    [ngSwitch]="col.key"
                                    [ngClass]="{
                                        'table-actions':
                                            col.key === 'selected' ||
                                            col.key === 'action'
                                    }"
                                >
                                    <div *ngSwitchCase="'takeoffDifference'">
                                        {{ parseTakeOffDifference(row) }}
                                    </div>
                                    <button
                                        *ngSwitchCase="'action'"
                                        class="btn btn-tertiary btn-sm"
                                        [disabled]="working"
                                        (click)="viewFlightLog.emit(row.id)"
                                    >
                                        <span
                                            class="fal fa-map"
                                            tooltip="View flight log"
                                            placement="top"
                                        ></span>
                                    </button>
                                    <div *ngSwitchDefault>
                                        {{ row[col.key] }}
                                    </div>
                                </td>
                            </ng-container>
                        </ng-template>
                    </ngx-table>
                </div>
                <button
                    [disabled]="
                        working ||
                        retrospectiveMissionName == null ||
                        retrospectiveMissionName.length < 3 ||
                        compatibleLogs == null ||
                        compatibleLogs.length == 0
                    "
                    class="btn btn-sm btn-block btn-primary top-buffer"
                    type="button"
                    (click)="createRetrospectiveMission()"
                    angulartics2On="click"
                    angularticsAction="create-historical-mission"
                    angularticsCategory="flight-logs"
                >
                    Create Retrospective Mission
                </button>
            </div>
        </ng-container>
        <ngx-loading [show]="working"></ngx-loading>
    </div>
    <div class="action-container" style="padding-top: 0">
        <button
            class="btn btn-sm btn-block btn-default top-buffer"
            style="margin-left: 0"
            type="button"
            (click)="cancel.emit()"
        >
            Back
        </button>
    </div>
</ng-container>
