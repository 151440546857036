import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    ChangeActiveMissionWorkflowVersionCommand,
    CreateMissionWorkflowCommand,
    InUseMissionWorkflowVersionDto,
    MissionWorkflowDto,
    RenameMissionWorkflowCommand,
    SimpleMissionWorkflowDto,
    UpdateMissionWorkflowVersionCommand
} from '../model/api';
import { StepDescription } from '../model/workflow/stepDescription';
import { WorkflowHandler } from './workflowHandler';

export function getMissionWorkflowSteps(
    editable: string[],
    validate: string[] = []
): StepDescription[] {
    return [
        {
            name: 'Mission Planning',
            value: 'pre-submit'
        },
        {
            name: 'Pre-Departure',
            value: 'pre-departure'
        },
        {
            name: 'Pre Mission',
            value: 'pre-mission'
        },
        {
            name: 'Pre Flight',
            value: 'pre-flight'
        },
        {
            name: 'Flying',
            value: 'flying'
        },
        {
            name: 'Post Flight',
            value: 'post-flight'
        },
        {
            name: 'Post Mission',
            value: 'post-mission'
        }
        // {
        //     name: 'Mission Debriefing',
        //     value: 'debriefing',
        // }
    ].map(s => ({
        ...s,
        editable: editable.indexOf(s.value) !== -1,
        validate: validate.indexOf(s.value) !== -1
    }));
}

@Injectable({
    providedIn: 'root'
})
export class MissionWorkflowService implements WorkflowHandler {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findActiveWorkflows(organisationId: number, dateStr?: string) {
        let params = new HttpParams().set(
            'organisationId',
            organisationId.toString()
        );
        if (dateStr) {
            params = params.set('at', dateStr);
        }

        return this.http.get<InUseMissionWorkflowVersionDto[]>(
            `${this.baseUrl}/webapi/missionWorkflows/active`,
            { params: params }
        );
    }

    changeActiveVersion(
        workflowId: number,
        command: ChangeActiveMissionWorkflowVersionCommand
    ) {
        return (
            this.http
                .put<MissionWorkflowDto>(
                    `${this.baseUrl}/webapi/missionWorkflows/${workflowId}/active`,
                    command
                )
                .pipe(tap(() => this.changeSource.next()))
                // FIXME: these can all be changed to observables
                .toPromise()
        );
    }

    renameWorkflow(workflowId: number, command: RenameMissionWorkflowCommand) {
        return this.http
            .put<MissionWorkflowDto>(
                `${this.baseUrl}/webapi/missionWorkflows/${workflowId}/name`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    updateWorkflowVersion(
        workflowId: number,
        command: UpdateMissionWorkflowVersionCommand
    ) {
        return this.http
            .put<MissionWorkflowDto>(
                `${this.baseUrl}/webapi/missionWorkflows/${workflowId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    createNewWorkflowVersion(command: CreateMissionWorkflowCommand) {
        return this.http
            .post<MissionWorkflowDto>(
                `${this.baseUrl}/webapi/missionWorkflows`,
                command
            )
            .pipe(tap(() => this.changeSource.next()))
            .toPromise();
    }

    findWorkflows(organisationId: number) {
        return this.http.get<SimpleMissionWorkflowDto[]>(
            `${this.baseUrl}/webapi/missionWorkflows`,
            {
                params: new HttpParams().set(
                    'organisationId',
                    organisationId.toString()
                )
            }
        );
    }

    findWorkflowById(workflowId: number) {
        return this.http.get<MissionWorkflowDto>(
            `${this.baseUrl}/webapi/missionWorkflows/${workflowId}`,
            {}
        );
    }

    archiveWorkflow(workflowId: number) {
        return this.http
            .put<MissionWorkflowDto>(
                `${this.baseUrl}/webapi/missionWorkflows/${workflowId}/archive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    unarchiveWorkflow(workflowId: number) {
        return this.http
            .put<MissionWorkflowDto>(
                `${this.baseUrl}/webapi/missionWorkflows/${workflowId}/unarchive`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
