import { Injectable } from '@angular/core';
import {
    BulkEditOptions,
    BulkImportErrorText,
    ColumnOptionTypes,
    ColumnOptions,
    CraftService,
    CreateCraftCommand,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker,
    checkRequiredValidity,
    downloadCsv,
    parseCsvByHeadings,
    removeEmptyRows
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CHECK_IS_TIME_WITH_COLONS } from 'libs/bulk-uploads/src/lib/checks';
import {
    convertDurationToTimestamp,
    convertTimestampToSeconds,
    dateFormats,
    invalidFieldText,
    parseBulkUploadDate,
    validateBulkUploadDate
} from 'libs/bulk-uploads/src/lib/helpers';
import { BulkImportDialogue } from 'libs/bulk-uploads/src/lib/import-dialogue/import-dialogue.component';
import { BulkUploadDialogue } from 'libs/bulk-uploads/src/lib/upload-dialogue/upload-dialogue.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Columns } from 'ngx-easy-table';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ImportCraft {
    name: string;
    makeModel: string;
    make: string;
    model: string;
    purchaseDate: string;
    serialNumber: string;
    flightTime: string;
}

const template: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            {
                key: 'name',
                props: {
                    label: 'Name'
                }
            },
            {
                key: 'make',
                props: {
                    label: 'Make'
                }
            },
            {
                key: 'model',
                props: {
                    label: 'Model'
                }
            },
            {
                key: 'purchaseDate',
                props: {
                    label: 'Purchase Date'
                }
            },
            {
                key: 'serialNumber',
                props: {
                    label: 'Serial Number'
                }
            },
            {
                key: 'flightTime',
                props: {
                    label: 'Flight Time'
                }
            }
        ]
    },
    props: {
        label: 'Bulk_RPA_Import'
    }
};

const templateData = [
    {
        name: 'RPA 1',
        make: 'DJI',
        model: 'Phantom 3',
        purchaseDate: '31-01-2020',
        serialNumber: 'E52-434543A',
        flightTime: '5:24:20'
    }
];

const columns: Columns[] = [
    { key: 'name', title: 'Name' },
    { key: 'makeModel', title: 'Make & Model' },
    { key: 'purchaseDate', title: 'Purchase Date' },
    { key: 'serialNumber', title: 'Serial Number' },
    { key: 'flightTime', title: 'Flight Time (HH:MM:SS)' }
];

const bulkEditOptions: BulkEditOptions = {
    cannotBulkEdit: ['serialNumber'],
    uniqueInSet: ['name']
};

@Injectable()
export class RpaUploadService {
    organisationId: number;

    uploadDialogue: BsModalRef<BulkUploadDialogue>;
    importDialogue: BsModalRef<BulkImportDialogue>;

    importedFile: ImportCraft[];

    columnOptions: ColumnOptions[];
    columnOptionsSubject = new ReplaySubject<ColumnOptions[]>();
    failedImportTextSubject = new ReplaySubject<BulkImportErrorText[]>();
    bulkEditOptionsSubject = new ReplaySubject<BulkEditOptions>();
    bulkEditOptions$ = this.bulkEditOptionsSubject.asObservable();
    columnOptions$ = this.columnOptionsSubject.asObservable();
    failedImportText$ = this.failedImportTextSubject.asObservable();
    failedImportText: BulkImportErrorText[] = [];

    aircraftModels: RpaTypeDto[];
    existingManufacturerSerialNumbers: string[];

    workingSubject = new ReplaySubject<boolean>();
    doneImportingSubject = new Subject<void>();
    doneImporting$ = this.doneImportingSubject.asObservable();
    working$ = this.workingSubject.asObservable();
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private rpaTypesService: RpaTypesService,
        private craftService: CraftService,
        private modalService: BsModalService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSubject.next(working));

        this.bulkEditOptionsSubject.next(bulkEditOptions);
    }

    ngOnDestroy() {
        this.doneImportingSubject.complete();
        this.workingSubject.complete();
        this.columnOptionsSubject.complete();
        this.failedImportTextSubject.complete();
        this.bulkEditOptionsSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    showBulkUpload(organisationId: number) {
        this.organisationId = organisationId;
        this.uploadDialogue = this.modalService.show(BulkUploadDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                uploadService: this,
                hasTemplate: true,
                entity: 'RPA'
            }
        });

        this.refreshCraft();
    }

    importFile(file: File) {
        parseCsvByHeadings<ImportCraft>(file, {
            Name: 'name',
            Make: 'make',
            Model: 'model',
            'Purchase Date': 'purchaseDate',
            'Serial Number': 'serialNumber',
            'Flight Time': 'flightTime'
        }).then(result => {
            // Sometimes the csv parser doesn't filter out empty rows.
            // The filtered variable is a redundancy check to ensure empty rows are ignored.
            const filtered = removeEmptyRows<ImportCraft[]>(result);
            this.importedFile = filtered.map(r => {
                return {
                    ...r,
                    makeModel: `${r.make} ${r.model}`,
                    name: checkRequiredValidity(r.name),
                    flightTime: checkRequiredValidity(r.flightTime)
                };
            });
            this.setupColumnOptions();
            this.importedFile.forEach((row, i) => {
                row.flightTime = convertDurationToTimestamp(row.flightTime);
                this.validateRow(row, i, this.columnOptions);
            });
            this.showImportDialogue();
            this.uploadDialogue.hide();
        });
    }

    setupColumnOptions() {
        this.columnOptions = [
            {
                columnName: 'purchaseDate',
                type: ColumnOptionTypes.SELECT,
                placeholder: 'Select the date format used',
                values: dateFormats
            },
            {
                columnName: 'makeModel',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: this.aircraftModels
                    .map(m => ({
                        name: `${m.make} ${m.model}`,
                        value: `${m.make} ${m.model}`
                    }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
            }
        ];
        this.columnOptionsSubject.next(this.columnOptions);
    }

    validateRow(row: ImportCraft, rowIndex: number, options: ColumnOptions[]) {
        // Validate each row and add ' *INVALID*' markers to any invalid
        const dateFormat = options.find(
            option => option != null && option.columnName === 'purchaseDate'
        ).result;
        if (
            row.serialNumber &&
            this.existingManufacturerSerialNumbers.findIndex(
                p => row.serialNumber === p
            ) !== -1
        ) {
            this.updateErrorText(
                rowIndex,
                'serialNumber',
                `${row.name} already exists in this organisation.`
            );
            row.serialNumber = `${row.serialNumber} ${invalidFieldText}`;
        }
        if (
            row.purchaseDate &&
            validateBulkUploadDate(row.purchaseDate, dateFormat) === false
        ) {
            this.updateErrorText(
                rowIndex,
                'purchaseDate',
                `The date: ${row.purchaseDate} is invalid. Please ensure you have selected the correct format`
            );
            row.purchaseDate = `${row.purchaseDate} ${invalidFieldText}`;
        }
        if (
            row.makeModel &&
            this.aircraftModels.findIndex(
                m =>
                    `${m.make.toLowerCase()} ${m.model.toLowerCase()}` ===
                    row.makeModel.trim().toLowerCase()
            ) === -1
        ) {
            this.updateErrorText(
                rowIndex,
                'makeModel',
                `${row.makeModel} is not a known RPA model.Please ensure this model is correct and has been set up for your organisation`
            );
            row.makeModel = `${row.makeModel} ${invalidFieldText}`;
        }
        if (
            row.flightTime !== 'INVALID' &&
            row.flightTime.match(CHECK_IS_TIME_WITH_COLONS) == null
        ) {
            this.updateErrorText(
                rowIndex,
                'flightTime',
                `Please ensure flight time is in HH:MM:SS format and indicates the amount of hours the RPA has flown.`
            );
            row.flightTime = `${row.flightTime} ${invalidFieldText}`;
        }
        this.failedImportTextSubject.next(this.failedImportText);

        return row;
    }

    showImportDialogue() {
        this.importDialogue = this.modalService.show(BulkImportDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                uploadService: this,
                data: this.importedFile,
                columns: columns,
                entity: 'RPA'
            }
        });
    }

    private refreshCraft() {
        this.craftService
            .findCrafts(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => {
                this.existingManufacturerSerialNumbers = results.map(
                    e => e.manufacturerSerialNumber
                );
                this.refreshRpaTypes();
            })
            .add(this.workTracker.createTracker());
    }

    private refreshRpaTypes() {
        this.rpaTypesService
            .findRpaTypes(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => {
                this.aircraftModels = results;
            })
            .add(this.workTracker.createTracker());
    }

    import(selected: number[], options: ColumnOptions[]) {
        const data: ImportCraft[] = selected.map(n => this.importedFile[n]);
        const dateFormat = options.find(
            option => option != null && option.columnName === 'purchaseDate'
        ).result;
        /**
         * Validity checks with individual feedback for clarity.
         */
        const failed: number[] = [];
        data.map((e, i) => {
            if (Object.keys(e).filter(key => e[key] === 'INVALID').length > 0) {
                failed.push(i);
            }
        });

        data.forEach((entry, i) => {
            const rowIndex = this.importedFile.findIndex(e => e === entry);
            if (
                Object.keys(entry).filter(key => entry[key] === 'INVALID')
                    .length > 0
            ) {
                return;
            }
            if (
                entry.serialNumber &&
                this.existingManufacturerSerialNumbers.findIndex(
                    p => entry.serialNumber === p
                ) !== -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'serialNumber',
                    `${entry.name} already exists in this organisation.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.purchaseDate != null &&
                validateBulkUploadDate(entry.purchaseDate, dateFormat) === false
            ) {
                this.updateErrorText(
                    rowIndex,
                    'purchaseDate',
                    `The date: ${entry.purchaseDate} is invalid. Please ensure you have selected the correct format`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.makeModel.length > 0 &&
                this.aircraftModels.findIndex(
                    m =>
                        `${m.make.toLowerCase()} ${m.model.toLowerCase()}` ===
                        entry.makeModel.trim().toLowerCase()
                ) === -1
            ) {
                this.updateErrorText(
                    rowIndex,
                    'makeModel',
                    `${entry.makeModel} is not a known RPA model.Please ensure this model is correct and has been set up for your organisation`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (entry.flightTime.match(CHECK_IS_TIME_WITH_COLONS) == null) {
                this.updateErrorText(
                    rowIndex,
                    'flightTime',
                    `Please ensure flight time is in HH:MM:SS format and indicates the amount of hours the RPA has flown.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
        });

        const validEntries = data.filter(
            (e, i) => failed.includes(i) === false
        );

        if (validEntries.length > 0) {
            const findRpaTypeId = (entry: ImportCraft) => {
                const craft = this.aircraftModels.find(
                    m =>
                        `${m.make.toLowerCase()} ${m.model.toLowerCase()}` ===
                        entry.makeModel.trim().toLowerCase()
                );
                return craft.id;
            };
            const command: CreateCraftCommand[] = validEntries.map(entry => ({
                initialFlightTime: convertTimestampToSeconds(entry.flightTime),
                isDummy: false,
                nickname: entry.name,
                organisationId: this.organisationId,
                manufacturerSerialNumber: entry.serialNumber,
                procurementDate:
                    parseBulkUploadDate(entry.purchaseDate, dateFormat) ?? null,
                rpaTypeId: findRpaTypeId(entry),
                initialComponentList: []
            }));

            let completed = true;
            command.forEach(cmd => {
                this.craftService
                    .createCraft(cmd)
                    .then(DO_NOTHING, (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error creating ${cmd.nickname}: ${error.message}`
                        );
                        completed = false;
                    })
                    .finally(() => this.workTracker.createTracker());
            });
            if (!completed) {
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
                return;
            } else {
                this.logging.success(
                    `Successfully imported ${command.length} craft.`
                );
                if (failed.length > 0) {
                    this.importDialogue.content.importedRowIndices =
                        this.importedFile.map((row, i) => {
                            if (validEntries.includes(row)) {
                                return i;
                            }
                        });
                    this.importedFile = this.importedFile.filter(
                        e => validEntries.includes(e) === false
                    );
                    this.importDialogue.content.onFailedRows();
                    this.logging.warn(
                        'Some imported rows have failed, please fix all errors and try again'
                    );
                    this.failedImportTextSubject.next(this.failedImportText);
                    this.failedImportText = [];
                } else {
                    this.importDialogue.hide();
                    this.doneImportingSubject.next();
                }
            }
        } else {
            if (failed.length > 0) {
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
            }
        }
    }

    downloadTemplate() {
        downloadCsv(template, templateData);
    }

    updateErrorText(rowIndex: number, key: string, text: string) {
        if (
            this.failedImportText.findIndex(
                t => t.key === key && t.rowIndex === rowIndex
            ) !== -1
        ) {
            return;
        } else {
            this.failedImportText.push({
                rowIndex: rowIndex,
                key: key,
                text: text
            });
        }
    }
}
