<td class="horizontal-container fill vertical-align space-between">
    <ng-select
        *ngIf="availableRpa != null && editing && !rpaRegisterDisabled"
        name="craft"
        [(ngModel)]="selectedRpaId"
        [ngModelOptions]="{ standalone: true }"
        [clearable]="false"
        bindValue="id"
        bindLabel="nickname"
        placeholder="Select an RPA"
        [items]="availableRpa"
        [searchFn]="rpaSearch"
        [readonly]="readonly"
        [disabled]="disable || readonly"
        (focus)="setStep('rpa')"
        [groupBy]="canUseWorkgroup && isWorkgroupSelect ? 'registerWithWorkgroupStatus' : 'registerStatus'"
        appendTo="body"
        class="fill wide-panel"
        (change)="updateSelectedRpa()"
    >
        <ng-template ng-optgroup-tmp let-item="item">
            {{ canUseWorkgroup && isWorkgroupSelect ? item.registerWithWorkgroupStatus : item.registerStatus | formatMissionEditRegisterStatus }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <rpa-list-item [item]="item"></rpa-list-item>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-index="index">
            <status-icon [rpa]="item" [clickable]="false"></status-icon>
        </ng-template>
    </ng-select>
    <!-- Use a select without option grouping if the register is disabled -->
    <!-- (this can't be done programmatically without replacing the whole select element) -->
    <ng-select
        *ngIf="availableRpa != null && editing && rpaRegisterDisabled"
        name="craft"
        [(ngModel)]="selectedRpaId"
        [ngModelOptions]="{ standalone: true }"
        [clearable]="false"
        bindValue="id"
        bindLabel="nickname"
        placeholder="Select an RPA"
        [items]="availableRpa"
        [searchFn]="rpaSearch"
        [hideSelected]="true"
        [readonly]="readonly"
        [disabled]="disable || readonly"
        (focus)="setStep('rpa')"
        appendTo="body"
        class="wide-panel"
        (change)="updateSelectedRpa()"
    >
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <rpa-list-item [item]="item"></rpa-list-item>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-index="index">
            <status-icon [rpa]="item" [clickable]="false"></status-icon>
        </ng-template>
    </ng-select>
    <status-icon
        *ngIf="selectedRpa && !editing"
        [rpa]="selectedRpa"
        (nameClicked)="viewRpa()"
    ></status-icon>
    <button
        *ngIf="selectedRpa && !editing"
        type="button"
        class="btn btn-tertiary no-margin"
        tooltip="Select a different RPA"
        placement="top"
        (click)="editing = !editing"
        [disabled]="disable || readonly"
    >
        <span class="fal fa-pencil-alt"></span>
    </button>
    <button
        *ngIf="selectedRpa && editing"
        type="button"
        class="btn btn-tertiary no-margin"
        tooltip="Cancel"
        placement="top"
        (click)="editing = !editing"
        [disabled]="disable || readonly"
    >
        <span class="fal fa-times"></span>
    </button>
</td>
<ng-container *ngIf="!(workTracker.observable | async)">
    <td>
        <span
            *ngIf="selectedRpa?.registerStatus != null && !rpaRegisterDisabled"
            class="status-block"
            [ngClass]="selectedRpa.registerStatus | registerStatusClass"
        >
            {{ selectedRpa.registerStatus | formatMissionEditRegisterStatus }}
        </span>
        <span *ngIf="selectedRpa?.registerStatus == null || rpaRegisterDisabled"
        >N/A</span
        >
    </td>
    <td>
        {{ (selectedRpaDetails | async)?.lastUse?.timestamp | formatDate }}
    </td>
    <td>
        {{
            (selectedRpaDetails | async).lastUse?.serviceabilitySignoff
                ?.signoffPerson | formatPerson
        }}
    </td>
    <td>{{ getTimeToNextService() }}</td>
    <td>
        <span
            *ngIf="selectedRpaDetails.getValue()?.status"
            class="status-block"
            [ngClass]="selectedRpaDetails.getValue()?.status | rpaStatusBlock"
        >
            {{ selectedRpaDetails.getValue()?.status | formatResourceStatus }}
        </span>
        <span *ngIf="!selectedRpaDetails.getValue()?.status">N/A</span>
    </td>
    <td>
        <button
            type="button"
            class="btn btn-tertiary btn-delete"
            tooltip="Remove this RPA from the mission"
            placement="top"
            (click)="deleteRow()"
            [disabled]="disable || readonly"
        >
            <span class="fal fa-trash-alt"></span>
        </button>
    </td>
</ng-container>
<td
    *ngIf="workTracker.observable | async"
    style="text-align: center !important"
    colspan="6"
>
    Loading...
</td>
