import { Directive } from '@angular/core';
import {
    CraftDto,
    CraftService,
    DO_NOTHING,
    FlightLogFileDto,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { FlightLogDataService } from 'libs/flight-logs/src/lib/flight-log-data.service';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, mergeMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { MissionRecordService } from '../mission-record-edit/mission-record.service';
import { MissionCompletionService } from './mission-completion.service';
import { Angulartics2 } from 'angulartics2';


export interface FlightLogFileWithRpa extends FlightLogFileDto {
    rpa: CraftDto;
    startTime?: string;
}

@Directive({
    selector: '[missionFlightLogs]',
    providers: [FlightLogDataService]
})
export class MissionFlightLogsDirective {
    state$ = new ReplaySubject<{ missionId: number; organisationId: number }>(
        1
    );

    missionService: MissionCompletionService | MissionRecordService;

    private rpaSource = new ReplaySubject<CraftDto[]>();
    private flightLogsSource = new ReplaySubject<FlightLogFileDto[]>();

    private flightLogWithRpaSource = new ReplaySubject<FlightLogFileWithRpa[]>(
        1
    );
    private workingSource = new ReplaySubject<boolean>(1);

    flightLogs$ = this.flightLogWithRpaSource.asObservable();
    working$ = this.workingSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionCompletionService: MissionCompletionService,
        private missionRecordService: MissionRecordService,
        private flightLogService: FlightLogsService,
        private flightLogDataService: FlightLogDataService,
        private logging: FlyFreelyLoggingService,
        private craftService: CraftService,
        private angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        combineLatest([
            this.workTracker.observable,
            this.flightLogDataService.working$
        ])
            .pipe(startWith([false, false]), takeUntil(this.ngUnsubscribe$))
            .subscribe(([working, loading]) => {
                this.workingSource.next(working || loading);
            });

        combineLatest([this.flightLogsSource, this.rpaSource])
            .pipe(
                map(([logs, rpas]) => {
                    const rpaLookup = rpas.reduce(toLookup, {});

                    return logs.map(
                        l =>
                            ({
                                ...l,
                                rpa: rpaLookup[l.rpaId]
                            } as FlightLogFileWithRpa)
                    );
                })
            )
            .subscribe(logfiles => this.flightLogWithRpaSource.next(logfiles));
        this.refreshFlightLogs();

        this.flightLogService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshFlightLogs());

        this.missionCompletionService.savedMission$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mission => {
                this.state$.next({
                    organisationId: mission.organisationId,
                    missionId: mission.id
                });
            });
        this.missionRecordService.savedMission$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mission => {
                this.state$.next({
                    organisationId: mission.organisationId,
                    missionId: mission.id
                });
            });

        this.state$
            .pipe(
                mergeMap(({ organisationId }) =>
                    this.craftService.findCrafts(organisationId)
                )
            )
            .subscribe(rpas => {
                this.rpaSource.next(rpas);
            });

        this.flightLogDataService.flightLogs$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(flightlogs => {
                this.flightLogsSource.next(flightlogs);
            });
    }

    ngOnDestroy() {
        this.flightLogsSource.complete();
        this.flightLogWithRpaSource.complete();
        this.rpaSource.complete();
        this.workingSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setServiceType(historicalMission: boolean) {
        if (historicalMission) {
            this.missionService = this.missionRecordService;
        } else {
            this.missionService = this.missionCompletionService;
        }
    }

    refreshFlightLogs() {
        this.state$
            .pipe(
                tap(({ organisationId, missionId }) =>
                    this.flightLogDataService.findFlightLogs(
                        0,
                        // Can't do pagination on this.
                        1000000,
                        null,
                        {
                            organisationId: organisationId,
                            missionId: missionId
                        },
                        organisationId
                    )
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            // Don't need to do anything since the data service observable will trigger the rest.
            .subscribe(DO_NOTHING);
    }

    assignFlightLog(flightLogId: number, flightId?: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.doAssignFlightLog(flightLogId, flightId).subscribe(
            _ => {
                doneWorking();
                this.refreshFlightLogs();
                this.logging.success('Flight log assigned');
            },
            (error: FlyFreelyError) => {
                   // return the log to the unassigned list
            this.unassignFlightLog(flightLogId)
                doneWorking();
                this.logging.error(
                    error,
                    `Error assigning flight log: ${error.message}`
                );
            }
        );
    }

    /**
     * Perform the assignment of the flight log to a flight.
     * @param flightLogId the flight log to assign
     * @param flightId the flight log to assign to
     * @returns the observable of the action
     */
    doAssignFlightLog(flightLogId: number, flightId?: number) {
        return this.state$.pipe(
            mergeMap(({ missionId }) =>
                this.flightLogService.assignFlightLog(flightLogId, {
                    flightId: flightId,
                    missionId
                })
            ),
            takeUntil(this.ngUnsubscribe$),
            tap(result => {
                this.missionService.updateFlight(result.flight);
            })
        );
    }

    unassignFlightLog(flightLogId: number) {
        const doneWorking = this.workTracker.createTracker();
        this.state$
            .pipe(
                mergeMap(({ missionId }) =>
                    this.flightLogService.assignFlightLog(flightLogId, {
                        flightId: null,
                        missionId: missionId
                    })
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(
                result => {
                    this.missionService.updateFlight(result.flight);
                    this.refreshFlightLogs();
                    doneWorking();
                    this.logging.success('Flight log unassigned');
                },
                (error: FlyFreelyError) => {
                    doneWorking();
                    this.logging.error(
                        error,
                        `Error unassigning flight log: ${error.message}`
                    );
                }
            );
    }

    unattachFlightLog(flightLogId: number) {
        const doneWorking = this.workTracker.createTracker();
        this.flightLogService
            .assignFlightLog(flightLogId, {
                flightId: null,
                missionId: null
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => {
                    this.refreshFlightLogs();
                    doneWorking();
                    this.logging.success('Flight log unattached');
                },
                (error: FlyFreelyError) => {
                    doneWorking();
                    this.logging.error(
                        error,
                        `Error unattaching flight log: ${error.message}`
                    );
                }
            );
    }
}
