<div class="panel widget locations">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-locations.png" />
        </div>
        <div class="panel-title">Location Templates</div>

        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                *ngIf="canAdd"
                class="btn btn-inline"
                (click)="showLocationCloneDialogue()"
                tooltip="Clone a mission location"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-location-clone-location"
            >
                <i class="fa-clone fa"></i>
            </button>
            <button
                *ngIf="canAdd"
                class="btn btn-inline btn-title-action"
                (click)="newLocation()"
                tooltip="Add a new location"
                placement="left"
                data-cy="addNewLocationButton"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>

    <div class="panel-body">
        <static-table
            *ngIf="locations?.length > 0"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="locations"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (sortOrderChanged)="updateColumnSorting($event)"
            (search)="updateSearchPreferences($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            [centeredHeadings]="true"
            [borderless]="true"
            data-cy="locationTable"
        >
            <ng-template rowActions let-row="row">
                <button
                    *ngIf="row.availableActions?.canDelete == true"
                    class="btn btn-tertiary btn-delete"
                    type="button"
                    (click)="deleteLocation(row)"
                    tooltip="Delete Location"
                    placement="left"
                >
                    <span class="fal fa-trash-alt"></span>
                </button>
                <button
                    *ngIf="row.availableActions?.canDelete == false"
                    class="btn btn-tertiary btn-delete"
                    type="button"
                    [disabled]="true"
                    tooltip="Cannot Delete Location"
                    placement="left"
                >
                    <span class="fal fa-trash-alt"></span>
                </button>
            </ng-template>
        </static-table>
        <empty
            *ngIf="canAdd && locations && locations.length == 0"
            componentName="locations-empty-editable"
        >
        </empty>
        <empty
            *ngIf="!canAdd && locations && locations.length == 0"
            componentName="locations-empty"
        >
        </empty>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
