<div class="horizontal-container" style="justify-content: space-between">
    <h4 class="header">
        Flight List<ng-container *ngIf="!personalHistory">
            For This Organisation</ng-container
        >
    </h4>

    <div class="pull-right" style="margin: 0"></div>
</div>
<div class="vertical-scroll horizontal-container">
    <button
        class="btn-circle btn-link"
        type="button"
        *ngIf="canEdit"
        (click)="showAddFlightForm()"
        [disabled]="working"
        placement="top"
    >
        <span class="fa fa-plus"></span>
    </button>
    <div class="fill" style="position: relative">
        <static-table
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="rows"
            [tableConfig]="tableConfig"
            [columnSorting]="tableSorting"
            [tableSearch]="tableSearch"
            [centeredHeadings]="true"
            [borderless]="true"
            (pageChanged)="onTablePageChanged($event)"
            (sortOrderChanged)="onTableSorted($event)"
            (search)="onTableSearch($event)"
        >
            <ng-template
                columnTemplate="conformanceResultList"
                let-row="row"
                let-column="column"
            >
                <ng-container
                    *ngIf="
                        row.conformanceResultList != null &&
                        row.conformanceResultList.length > 0
                    "
                >
                    <conformance-status-icon
                        [status]="parseConformance(row)"
                    ></conformance-status-icon>
                </ng-container>
            </ng-template>
            <ng-template rowActions let-row="row">
                <ng-container *ngIf="canEdit">
                    <button
                        *ngIf="
                            row.flightType === 'HISTORICAL' &&
                            row.flightId != currentlyEditingId
                        "
                        type="button"
                        class="btn btn-tertiary btn-delete"
                        (click)="removeFlight(row)"
                        [disabled]="editing !== 'NONE' || working"
                        placement="top"
                        tooltip="Delete flight"
                    >
                        <span class="fal fa-trash-alt"></span>
                    </button>
                </ng-container>
            </ng-template>
        </static-table>
    </div>
</div>
<loading-indicator [isLoading]="working"></loading-indicator>
