<ng-container screenAnalytics="flight-log-analyser">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Flight Analyser</h3>
        <h5
            *ngIf="flight != null || mission != null"
            class="modal-title"
            tooltip="The mission being previewed"
            placement="bottom"
        >
            {{ mission.name }}
            <span *ngIf="flight != null"> Flight {{ flight.number }}</span>
        </h5>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div
        class="modal-body no-footer inner-container max-height horizontal-container flight-analyser-container"
    >
        <div class="fill horizontal-container">
            <flyfreely-map
                #map
                [style]="'mapbox://styles/mapbox/outdoors-v9'"
                logoPosition="bottom-left"
                class="fill"
                [features]="features"
                [organisationId]="organisationId || mission?.organisationId"
                (featuresLoaded)="map.zoomToAllFeatures(false)"
            >
            </flyfreely-map>

            <div *ngIf="!working && (!features || features.length === 0)">
                <div class="alert alert-info">
                    There is no GPS data in this log file
                </div>
            </div>
        </div>
        <div class="contents container-scroll">
            <div>
                <span class="display-label">Max Distance</span>
                <ng-container *ngIf="maxDistance != null">
                    <span *ngIf="isNoHome(maxDistance)">
                        No home value set</span
                    >
                    <span *ngIf="maxDistance | guardType: isValue as distance">
                        {{ distance.value }} m</span
                    >
                </ng-container>
            </div>
            <div>
                <span class="display-label">Max Height</span>
                {{ maxHeight * 3.3 | number: '1.0-0' }} ft ({{
                    maxHeight | number: '1.0-0'
                }}
                m)
            </div>
            <div>
                <span class="display-label">Start Time</span>
                {{ startTime | formatDateTime }}
            </div>
            <div>
                <span class="display-label">End Time</span>
                {{ endTime | formatDateTime }}
            </div>
            <div>
                <span class="display-label">Flight Duration</span>
                {{ duration | formatDuration  | defaultTo }}
            </div>
            <div>
                <span class="display-label">Take Off Point</span>&nbsp;
                <span
                    [innerHTML]="startLocation?.latitude | formatLatitude | defaultTo"
                ></span
                >&nbsp;
                <span
                    [innerHTML]="startLocation?.longitude | formatLongitude | defaultTo"
                ></span>
            </div>
            <div>
                <span class="display-label">Landing Point</span>&nbsp;
                <span
                    [innerHTML]="landingLocation?.latitude | formatLatitude | defaultTo"
                ></span
                >&nbsp;
                <span
                    [innerHTML]="landingLocation?.longitude | formatLongitude | defaultTo"
                ></span>
            </div>
            <div *ngIf="rpaType != null">
                <span class="display-label">Log RPA Type</span>
                {{ rpaType }}
            </div>
            <div>
                <span class="display-label">Log RPA Name</span>
                {{ rpaName }}
            </div>
            <div>
                <span class="display-label">Log RPA FC Serial Number</span>
                {{ rpaSerial }}
            </div>
            <div>
                <label class="display-label">Flight Batteries</label>
                <div
                    *ngFor="let battery of batteries; let i = index"
                    tooltip="Displays battery's recorded serial number (if available), charge levels (%) at flight's start and end, and battery temperature (°C) at flight's start and end."
                    placement="top"
                >
                    {{ i + 1 + '.' }}&nbsp;{{
                        battery.batterySerialNumber
                            ? battery.batterySerialNumber + ' '
                            : ''
                    }}<em>Levels:</em>&nbsp;{{
                        battery.startBatteryLevel
                            ? (battery.startBatteryLevel | number: '1.0-0')
                            : '-'
                    }}%&nbsp;<span class="fal fa-right"></span>&nbsp;{{
                        battery.endBatteryLevel
                            ? (battery.endBatteryLevel | number: '1.0-0')
                            : '-'
                    }}%&nbsp;<em>Temps:</em>&nbsp;{{
                        battery.startBatteryTemperature
                            ? (battery.startBatteryTemperature
                              | number: '1.0-1')
                            : '-'
                    }}&deg;&nbsp;<span class="fal fa-right"></span>&nbsp;{{
                        battery.endBatteryTemperature
                            ? (battery.endBatteryTemperature | number: '1.0-1')
                            : '-'
                    }}&deg;
                </div>
            </div>
            <div class="top-buffer vertical-container">
                <a
                    class="btn btn-sm btn-default"
                    type="button"
                    [downloadUrl]="csvDownloadUrl"
                    target="_blank"
                    >Download CSV</a
                ><a
                    class="btn btn-sm btn-default"
                    type="button"
                    [downloadUrl]="logDownloadUrl"
                    target="_blank"
                    >Download Log</a
                ><a
                    class="btn btn-sm btn-default"
                    type="button"
                    [downloadUrl]="kmlDownloadUrl"
                    target="_blank"
                    >Download KML</a
                >
                <button
                    *ngIf="hasSubmittedDebugReport !== true"
                    type="button"
                    class="btn btn-link"
                    (click)="submitDebugReport()"
                    [disabled]="working"
                >
                    Report an issue
                </button>
                <div *ngIf="hasSubmittedDebugReport === true">
                    An issue has been reported to FlyFreely about this file
                </div>
            </div>
            <div class="top-buffer">
                <div>
                    <img [src]="baseImgUrl + '/map/marker-rp.png'" />
                    Pilot Location
                </div>
                <div>
                    <i class="fas fa-circle" style="color: #ff0000"></i>RPA Path
                </div>
                <div>
                    <img [src]="baseImgUrl + '/map/marker-home.png'" /> Home
                    Location
                </div>
                <div>
                    <img [src]="baseImgUrl + '/map/marker-takeoff.png'" />
                    Take-off Location
                </div>
                <div>
                    <img [src]="baseImgUrl + '/map/marker-landing.png'" />
                    Landing Location
                </div>
            </div>
        </div>
        <ngx-loading [show]="working"></ngx-loading>
    </div>
</ng-container>
