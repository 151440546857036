import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AirspaceJurisdictionDto } from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'airspace-disclaimer',
    template: `
        <div class="container-with-footer text-center">
            <div class="vertical-container">
                <h4 class="top-buffer bottom-buffer">
                    Airspace Information Disclaimer
                </h4>
                {{ airspaceJurisdiction.airspaceDisclaimer }}
            </div>

            <div class="action-container bottom-buffer">
                <div class="horizontal-container flex-centered">
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="acknowledge()"
                    >
                        Acknowledge
                    </button>
                </div>
            </div>
        </div>
    `
})
export class AirspaceDisclaimerDialogue {
    @Input() screenIdentifier: string;
    @Input() airspaceJurisdiction: AirspaceJurisdictionDto;
    @Output() done = new EventEmitter<void>();

    constructor(private angulartics2: Angulartics2) {}

    acknowledge() {
        this.angulartics2.eventTrack.next({
            action: 'acknowledge-disclaimer',
            properties: {
                category: 'airspace-check',
                label: this.screenIdentifier
            }
        });
        this.done.emit();
    }
}
