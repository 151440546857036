import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LandingComponent } from '../landing.component';
import { AuthenticatedGuard } from '@flyfreely-portal-ui/ui';
import { InvitesLandingComponent } from '../user/invites/invites-landing.component';
import { WorkspaceRoutingModule } from '../workspace/workspace-routing/workspace-routing.module';
import { OnboardingRoutingModule } from 'libs/onboarding/src/lib/onboarding-routing/onboarding-routing.module';
import { SignupToDemoComponent } from '../signup-to-demo/signup-to-demo.component';

/**
 * The router is setup to have 3 entry points:
 * 1. the initial landing screen {@link LandingComponent} which redirects the user to their default organisation.
 * 2. the workspace module which is the main application structure
 * 3. the invites landing screen {@link InvitesLandingComponent} which is used to handles authenticed and unauthenticated users' invites
 * 4. the onboarding module which is used to guide users through the setup process.
 *
 * There is also a catch-all route that redirects any unknown paths to the root of the application.
 *
 * Routes 1, 2, and 4 are both guarded by the {@link AuthenticatedGuard} to ensure that only authenticated users can access them.
 *
 * All child modules provide thier own routes and guards using the `loadChildren` property.
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(
            [
                {
                    path: 'invites',
                    component: InvitesLandingComponent
                },
                // The initial landing screen which redirects the user
                {
                    path: '',
                    component: LandingComponent,
                    canActivate: [AuthenticatedGuard],
                    pathMatch: 'full'
                },
                {
                    path: 'demo',
                    canActivate: [AuthenticatedGuard],
                    component: SignupToDemoComponent
                },
                {
                    path: 'onboarding',
                    canActivate: [AuthenticatedGuard],
                    loadChildren: () => OnboardingRoutingModule
                },
                // The parent route to everything else
                {
                    path: '',
                    canActivate: [AuthenticatedGuard],
                    loadChildren: () => WorkspaceRoutingModule
                },
                {
                    path: '**',
                    redirectTo: ''
                }
            ],
            {
                // enable to debug routing
                enableTracing: false
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
