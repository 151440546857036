import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@blox/material';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { UserProfileModule } from '@flyfreely-portal-ui/user-profile';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AirspaceModule } from 'libs/airspace/src/lib/airspace.module';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { ApprovalConditionsModule } from 'libs/approval-conditions/src/lib/approval-conditions.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlightLogsModule } from 'libs/flight-logs/src/lib/flight-logs.module';
import { FormDirectivesModule } from 'libs/form-directive/src/lib/form-directive.module';
import { InlineModalModule } from 'libs/inline-modal/src/lib/inline-modal.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { MapModule } from 'libs/map/src/lib/map.module';
import { ViewRawNotamDialogueComponent } from 'libs/missions/src/lib/mission-edit-v2/view-notam-dialogue/view-raw-notam-dialogue.component';
import { ResourceUiModule } from 'libs/resource-ui/src';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { AuthorityLibrary } from './authority-library/authority-library.component';
import { BearingPipe } from './bearing.pipe';
import { MissionFieldAppHandoffDialogue } from './field-app-handoff/mission-field-app-handoff.component';
import { FlightConformanceDetails } from './flight-conformance/flight-conformance-details.component';
import { FlightDocumentationEditDialogue } from './flight-documentation-edit/flight-documentation-edit-dialogue.component';
import { FlightDocumentationDialogue } from './flight-documentation/flight-documentation-dialogue.component';
import { FlightTakeoffLandingEditDialogue } from './flight-takeoff-landing/edit-takeoff-landing.component';
import { IncidentReportDialoguesService } from './incident-report-dialogues.service';
import { IncidentReportEdit } from './incident-report-edit/incident-report-edit.component';
import { IncidentReportList } from './incident-report-list/incident-report-list.component';
import { AcceptMissionApprovalDialogueComponent } from './mission-approval-v2/accept-approval.component';
import { MissionApprovalV2Dialogue } from './mission-approval-v2/mission-approval-v2-dialogue.component';
import { MissionCalendarDialogue } from './mission-calendar/mission-calendar.component';
import { FlightEdit } from './mission-completion/flight-edit/flight-edit.component';
import { MissionCompletionDialogue } from './mission-completion/mission-completion-dialogue.component';
import { MissionFlightLogsDirective } from './mission-completion/mission-flight-logs.directive';
import { ServiceabilityMaintenanceReportComponent } from './mission-completion/serviceability/maintenance-report/serviceability-maintenance-report.component';
import { ServiceabilitySignoffComponent } from './mission-completion/serviceability/serviceability-signoff/serviceability-signoff.component';
import { AssignToFlightDialogue } from './mission-completion/unassigned-mission-flight-logs/assign-to-flight/assign-to-flight.component';
import { UnassignedMissionFlightLogs } from './mission-completion/unassigned-mission-flight-logs/unassigned-mission-flight-logs.component';
import { UnlistedMissionResourcesComponent } from './mission-completion/unlisted-mission-resources/unlisted-mission-resources.component';
import { MissionDetailsStatus } from './mission-details-v2/mission-details-status/mission-details-status.component';
import { MissionDetailsV2Dialogue } from './mission-details-v2/mission-details-v2-dialogue.component';
import { MissionApprovalSelectionPopupComponent } from './mission-details-v2/popup-approval-selector/popup-approval-selector.component';
import { MissionDialoguesService } from './mission-dialogues.service';
import { AddAssociatedResources } from './mission-edit-v2/add-associated-resources.component';
import { AerodromeSearchDialogue } from './mission-edit-v2/aerodrome-search/aerodrome-search-dialogue.component';
import { MissionAirspaceAuthorisationLink } from './mission-edit-v2/airspace/authorisation-link/mission-airspace-authorisation-link.component';
import { ContactPillAdd } from './mission-edit-v2/contact-pill/add/contact-add.component';
import { ContactPillView } from './mission-edit-v2/contact-pill/contact-pill.component';
import { InformationSearchComponent } from './mission-edit-v2/contact-pill/search/info-search.component';
import { FormatMissionEditRegisterStatus } from './mission-edit-v2/formatMissionEditRegisterStatus.pipe';
import { MissionDocumentation } from './mission-edit-v2/mission-documentation/mission-documentation.component';
import { MissionEditV2Dialogue } from './mission-edit-v2/mission-edit-v2-dialogue.component';
import { MissionEquipmentSelection } from './mission-edit-v2/mission-equipment-select/mission-equipment-select.component';
import { MissionLocationSelection } from './mission-edit-v2/mission-location-selection.component';
import { MissionRpaSelection } from './mission-edit-v2/mission-rpa-select/mission-rpa-select.component';
import { NotamDetailsComponent } from './mission-edit-v2/notam-details/notam-details.component';
import { NotamSearchDialogue } from './mission-edit-v2/notam-search/notam-search-dialogue.component';
import { PilotRegisterDetailsOverallStatusIndicatorComponent } from './mission-edit-v2/pilot-register-details/overall-status-indicator/pilot-register-details-overall-status.component';
import { PilotMissionRegisterDetails } from './mission-edit-v2/pilot-register-details/pilot-register-details.component';
import { SelectMission } from './mission-edit-v2/select-mission/select-mission.component';
import { MissionOverviewMapDialogue } from './mission-overview/map-dialogue/mission-overview-map.component';
import { MissionOverviewComponent } from './mission-overview/mission-overview.component';
import { MissionPilotRoleV2 } from './mission-pilot-role-v2/mission-pilot-role-v2.component';
import { MissionRecordEditDialogue } from './mission-record-edit/mission-record-edit.component';
import { MissionReportsDialogue } from './mission-reports/mission-reports-dialogue.component';
import { MissionWorkflowApproval } from './mission-workflow-approval/mission-workflow-approval.component';
import { SummaryHeader } from './summary-header/summary-header.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule,
        LoadingIndicatorModule,
        FlyFreelyFormlyModule,
        FormlyModule,
        FormDirectivesModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        MaterialModule,
        BsDatepickerModule,
        ModalModule.forChild(),
        TabsModule,
        AttachmentsModule,
        DocumentationModule,
        TooltipModule,
        BsDropdownModule,
        ButtonsModule,
        FlyFreelyTableModule,
        NgSelectModule,
        AccordionModule,
        TimepickerModule,
        AirspaceModule,
        SharedUiModule,
        ResourceUiModule,
        ValidationModule,
        MapModule,
        FlightLogsModule,
        InlineModalModule,
        NgxLoadingModule,
        ApprovalConditionsModule,
        DragDropModule,
        EnhancedHelpModule,
        AnalyticsModule,
        FormDirectivesModule,
        PopoverModule,
        UserProfileModule,
        RouterModule.forChild([]),
        FeaturesModule
        // TODO Router: test admin FlightLogsModule
    ],
    providers: [MissionDialoguesService, IncidentReportDialoguesService],
    bootstrap: [],
    declarations: [
        MissionDetailsV2Dialogue,
        MissionCompletionDialogue,
        MissionEditV2Dialogue,
        MissionFieldAppHandoffDialogue,
        MissionApprovalV2Dialogue,
        FlightDocumentationDialogue,
        FlightDocumentationEditDialogue,
        MissionWorkflowApproval,
        SummaryHeader,
        MissionReportsDialogue,
        MissionCalendarDialogue,
        IncidentReportList,
        IncidentReportEdit,
        AuthorityLibrary,
        MissionPilotRoleV2,
        MissionDocumentation,
        SelectMission,
        FlightEdit,
        MissionFlightLogsDirective,
        UnassignedMissionFlightLogs,
        AerodromeSearchDialogue,
        MissionDetailsStatus,
        ContactPillAdd,
        ContactPillView,
        InformationSearchComponent,
        NotamDetailsComponent,
        UnlistedMissionResourcesComponent,
        ServiceabilitySignoffComponent,
        ServiceabilityMaintenanceReportComponent,
        BearingPipe,
        NotamSearchDialogue,
        MissionRecordEditDialogue,
        AssignToFlightDialogue,
        MissionLocationSelection,
        MissionRpaSelection,
        MissionEquipmentSelection,
        FlightConformanceDetails,
        MissionAirspaceAuthorisationLink,
        MissionOverviewComponent,
        MissionOverviewMapDialogue,
        FlightTakeoffLandingEditDialogue,
        FormatMissionEditRegisterStatus,
        PilotMissionRegisterDetails,
        PilotRegisterDetailsOverallStatusIndicatorComponent,
        AddAssociatedResources,
        AcceptMissionApprovalDialogueComponent,
        ViewRawNotamDialogueComponent,
        MissionApprovalSelectionPopupComponent
    ],
    exports: []
})
export class MissionsModule {}
