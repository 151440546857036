<ng-container screenAnalytics="rpa-add">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-rpa.png" />
        </div>
        <h3 class="modal-title">Add RPA</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
            data-cy="closeRpaDialogue"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="rpaNewForm" (ngSubmit)="save(false)" novalidate>
        <div class="modal-body">
            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>Name/Designation</label>
                    <field-validation-indicator
                        validatorFormControlName="nickname"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <input
                        class="form-control"
                        formControlName="nickname"
                        placeholder="Type a name for this RPA"
                    />
                    <validation-messages
                        validatorFormControlName="nickname"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="nickname"
                [compact]="true"
            >
            </enhanced-help>

            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>RPA Type</label>
                    <field-validation-indicator
                        validatorFormControlName="rpaTypeId"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <ng-select
                        [items]="aircraftModels"
                        bindLabel="model"
                        bindValue="id"
                        formControlName="rpaTypeId"
                        placeholder="Select an RPA type"
                        [searchFn]="rpaTypeSearch"
                    >
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                        >
                            <ng-container *ngIf="item.id != -1">
                                {{ item | formatRpaType }}
                                <span
                                    *ngIf="item.organisationId != null"
                                    class="status-block custom"
                                    >Custom</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.id == -1">
                                {{ item | formatRpaType }}
                                <span style="color: #939598"
                                    >&nbsp;- Create a new RPA Type</span
                                >
                            </ng-container>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <ng-container *ngIf="item.id != -1">
                                {{ item | formatRpaType }}
                                <span
                                    *ngIf="item.organisationId != null"
                                    class="status-block custom"
                                    >Custom</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.id == -1">
                                <span>Create a new RPA type below:</span>
                            </ng-container>
                        </ng-template>
                    </ng-select>
                    <validation-messages
                        validatorFormControlName="rpaTypeId"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="rpaTypeId"
                [compact]="true"
            >
            </enhanced-help>
            <div *ngIf="addMakeModel" @collapseOnLeave @expandOnEnter>
                <div class="row form-group top-buffer">
                    <div class="col-xs-6">
                        <label>New RPA Type Make</label>
                        <!-- Using [valid] for the validation indicators instead of formControlName due to changing validators not reflected in validators correctly -->
                        <field-validation-indicator
                            [valid]="
                                rpaNewForm.controls.make.valid &&
                                rpaNewForm.controls.makeText.valid
                            "
                            [noun]="'field'"
                            [placement]="'left'"
                            class="pull-right"
                        ></field-validation-indicator>
                        <ng-select
                            [items]="rpaMakes"
                            [readonly]="makeLocked || working"
                            bindValue="value"
                            bindLabel="name"
                            appendTo="body"
                            formControlName="make"
                            placeholder="Select the new type's make"
                        >
                            <ng-template ng-option-tmp let-item="item">
                                <ng-container *ngIf="item != 'Not Listed'">
                                    {{ item }}
                                </ng-container>
                                <ng-container *ngIf="item == 'Not Listed'">
                                    {{ item }}
                                    <span style="color: #939598"
                                        >&nbsp;- Add a make</span
                                    >
                                </ng-container>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <ng-container *ngIf="item != 'Not Listed'">
                                    {{ item }}
                                </ng-container>
                                <ng-container *ngIf="item == 'Not Listed'">
                                    Add a new make below
                                </ng-container>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="!makeListed">
                            <input
                                [readonly]="makeListed || working"
                                type="text"
                                class="form-control"
                                formControlName="makeText"
                                placeholder="Type the new RPA Make here."
                            />
                        </div>
                        <validation-messages
                            validatorFormControlName="make"
                        ></validation-messages>
                    </div>
                    <div class="col-xs-6">
                        <label>New RPA Type Model</label>
                        <field-validation-indicator
                            [valid]="
                                rpaNewForm.controls.model.valid &&
                                rpaNewForm.controls.modelText.valid
                            "
                            [noun]="'field'"
                            [placement]="'left'"
                            class="pull-right"
                        ></field-validation-indicator>
                        <ng-select
                            [items]="rpaModels"
                            [readonly]="modelLocked || working"
                            bindValue="value"
                            bindLabel="name"
                            appendTo="body"
                            formControlName="model"
                            placeholder="Select the new type's model"
                        >
                            <ng-template ng-option-tmp let-item="item">
                                <ng-container *ngIf="item != 'Not Listed'">
                                    {{ item }}
                                </ng-container>
                                <ng-container *ngIf="item == 'Not Listed'">
                                    {{ item }}
                                    <span style="color: #939598"
                                        >&nbsp;- Add a model</span
                                    >
                                </ng-container>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <ng-container *ngIf="item != 'Not Listed'">
                                    {{ item }}
                                </ng-container>
                                <ng-container *ngIf="item == 'Not Listed'">
                                    Add a new model below
                                </ng-container>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="!modelListed">
                            <input
                                [readonly]="modelListed || working"
                                type="text"
                                class="form-control"
                                formControlName="modelText"
                                placeholder="Type the RPA Model here."
                            />
                        </div>
                        <validation-messages
                            validatorFormControlName="model"
                        ></validation-messages>
                    </div>
                </div>
                <div class="row form-group top-buffer">
                    <div class="col-xs-6">
                        <label>New RPA Type Category</label>
                        <field-validation-indicator
                            [valid]="rpaNewForm.controls.rpaType.valid"
                            [noun]="'field'"
                            [placement]="'left'"
                            class="pull-right"
                        ></field-validation-indicator>
                        <ng-select
                            [items]="rpaCategories"
                            [readonly]="makeLocked || working"
                            bindValue="value"
                            bindLabel="name"
                            appendTo="body"
                            formControlName="rpaType"
                            placeholder="Select the new type's category"
                        >
                        </ng-select>
                    </div>
                    <div class="col-xs-6">
                        <label>New RPA Type Propulsion System</label>
                        <field-validation-indicator
                            [valid]="rpaNewForm.controls.propulsion.valid"
                            [noun]="'field'"
                            [placement]="'left'"
                            class="pull-right"
                        ></field-validation-indicator>
                        <ng-select
                            [items]="propulsionSystems"
                            [readonly]="modelLocked || working"
                            bindValue="value"
                            bindLabel="name"
                            appendTo="body"
                            formControlName="propulsion"
                            placeholder="Select the new type's propulsion system"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
            <div
                *ngIf="hasComponentsSetUp"
                class="row form-group top-buffer"
                [@expandOnEnter]
                [@collapseOnLeave]
            >
                <div class="col-xs-12">
                    <label>Component Details</label>
                    <span>
                        <button
                            type="button"
                            class="btn-sm btn-link"
                            [tooltip]="
                                showComponents
                                    ? 'Hide component details'
                                    : 'Show component details'
                            "
                            placement="left"
                            (click)="showComponents = !showComponents"
                        >
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-angle-up': showComponents,
                                    'fa-angle-down': !showComponents
                                }"
                            ></span>
                        </button>
                    </span>
                    <div
                        class="row"
                        *ngIf="showComponents"
                        [@expandOnEnter]
                        [@collapseOnLeave]
                    >
                        <div
                            formArrayName="components"
                            *ngIf="
                                components.value != null &&
                                components.controls.length > 0
                            "
                            class="col-xs-12"
                        >
                            <ng-container
                                *ngFor="
                                    let component of components.controls;
                                    let i = index;
                                    let even = even
                                "
                                [formGroupName]="i"
                            >
                                <div
                                    *ngIf="
                                        component.value.hasSerialNumber ||
                                        component.value.hasFirmware
                                    "
                                    class="row row-padding"
                                    [ngClass]="{ 'row-even': even }"
                                >
                                    <div class="col-xs-4">
                                        <label>Component</label>
                                        <p>
                                            <span
                                                *ngIf="
                                                    component.value.icon != null
                                                "
                                                [class]="component.value.icon"
                                            ></span
                                            >&nbsp;{{ component.value.name }}
                                        </p>
                                    </div>
                                    <div class="col-xs-4">
                                        <ng-container
                                            *ngIf="
                                                component.value.hasSerialNumber
                                            "
                                        >
                                            <label>Serial Number</label>
                                            <input
                                                [readonly]="working"
                                                type="text"
                                                class="form-control"
                                                formControlName="serialNumber"
                                                placeholder="eg. SN12345"
                                            />
                                        </ng-container>
                                    </div>
                                    <div class="col-xs-4">
                                        <ng-container
                                            *ngIf="component.value.hasFirmware"
                                        >
                                            <label>Firmware Version</label>
                                            <input
                                                [readonly]="working"
                                                type="text"
                                                class="form-control"
                                                formControlName="firmwareVersion"
                                                placeholder="eg. V1.5.6"
                                            />
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>Manufacturer Serial Number</label>
                    <field-validation-indicator
                        validatorFormControlName="manufacturerSerialNumber"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <input
                        class="form-control"
                        formControlName="manufacturerSerialNumber"
                    />
                    <validation-messages
                        validatorFormControlName="manufacturerSerialNumber"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="manufacturerSerialNumber"
                [compact]="true"
            >
            </enhanced-help>

            <div *ngIf="canUseNfc" class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>NFC UID</label>
                    <field-validation-indicator
                        validatorFormControlName="nfcUid"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <input
                        class="form-control"
                        formControlName="nfcUid"
                        placeholder="The RPA NFC UID &#40;if known&#41;"
                    />
                    <validation-messages
                        validatorFormControlName="nfcUid"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help
                *ngIf="canUseNfc"
                screenName="rpa-edit"
                componentName="nfcUid"
                [compact]="true"
            >
            </enhanced-help>

            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>Estimated Historical Flight Time</label>
                    <field-validation-indicator
                        validatorFormControlName="initialFlightTime"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <duration
                        formControlName="initialFlightTime"
                        [showHour]="true"
                        [showMinute]="true"
                        [showSecond]="true"
                        [clearable]="false"
                    >
                    </duration>
                    <validation-messages
                        validatorFormControlName="initialFlightTime"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="initialFlightTime"
                [compact]="true"
            >
            </enhanced-help>

            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>Call Sign</label>
                    <field-validation-indicator
                        validatorFormControlName="callSign"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <input class="form-control" formControlName="callSign" />
                    <validation-messages
                        validatorFormControlName="callSign"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="callSign"
                [compact]="true"
            >
            </enhanced-help>

            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>Estimated Procurement Date</label>
                    <field-validation-indicator
                        validatorFormControlName="procurementDate"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            class="form-control"
                            #datepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="procurementDate"
                        />
                        <span
                            class="input-group-addon btn-default"
                            (click)="datepicker.toggle()"
                            [attr.aria-expanded]="datepicker.isOpen"
                        >
                            <i
                                class="glyphicon glyphicon-calendar"
                                style="cursor: pointer"
                            ></i>
                        </span>
                    </div>
                    <validation-messages
                        validatorFormControlName="procurementDate"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="procurementDate"
                [compact]="true"
            >
            </enhanced-help>

            <div class="row form-group top-buffer">
                <div class="col-xs-12">
                    <label>Maximum Visual Distance</label>
                    <field-validation-indicator
                        validatorFormControlName="maximumVisualDistance"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                    <div class="input-group">
                        <input
                            type="number"
                            class="form-control"
                            min="0"
                            step="1"
                            formControlName="maximumVisualDistance"
                            server-errors
                        />
                        <span class="input-group-addon">m</span>
                    </div>
                    <validation-messages
                        validatorFormControlName="maximumVisualDistance"
                    ></validation-messages>
                    <p
                        *ngIf="
                            rpaType?.otherSpecifications
                                ?.defaultMaximumVisualDistance != null
                        "
                        class="help-text"
                    >
                        The default maximum visual distance is
                        {{
                            rpaType?.otherSpecifications
                                ?.defaultMaximumVisualDistance
                        }}m as set by the RPA type
                    </p>
                    <p
                        *ngIf="
                            rpaType?.otherSpecifications
                                ?.defaultMaximumVisualDistance == null
                        "
                        class="help-text"
                    >
                        There is no default maximum visual distance as there is
                        none set on the RPA type
                    </p>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="maximumVisualDistance"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <div>
                    <label>RPA Classification</label>
                    <field-validation-indicator
                        validatorFormControlName="isDummy"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>

                    <div
                        class="input-group"
                        btnRadioGroup
                        formControlName="isDummy"
                        style="padding-top: 5px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="false"
                            [disabled]="working"
                            >Real RPA</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="true"
                            [disabled]="working"
                            >Dummy RPA</label
                        >
                    </div>
                    <validation-messages validatorFormControlName="isDummy">
                    </validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="rpa-edit"
                componentName="isDummy"
                [compact]="true"
            >
            </enhanced-help>
        </div>
        <div class="modal-footer">
            <button
                *ngIf="canBulkUpload"
                class="btn btn-default pull-left"
                type="button"
                (click)="bulkUpload()"
                [disabled]="working"
            >
                Bulk Upload
            </button>
            <button
                class="btn btn-default"
                type="button"
                (click)="save(true)"
                [disabled]="rpaNewForm.invalid || working"
            >
                Add Another
            </button>
            <button
                class="btn btn-primary"
                type="submit"
                [disabled]="rpaNewForm.invalid || working"
                data-cy="submitRpa"
            >
                Create
            </button>
        </div>
    </form>
</ng-container>
