<h4 class="section-heading">
    <span>Basic Information</span>
    <div></div>
</h4>
<div class="contents" *ngFor="let field of fields">
    <div
        class="data"
        *ngIf="basicSpecifications && basicSpecifications[field.key]"
    >
        <span class="display-label">{{ field.props.label }}</span>
        {{ basicSpecifications[field.key] }}
    </div>
    <div
        class="data"
        *ngIf="!basicSpecifications && !basicSpecifications[field.key]"
    >
        <span class="display-label">{{ field.props.label }}</span>
    </div>
</div>

<h4 class="section-heading top-buffer">
    <span>Physical Information</span>
    <div></div>
</h4>
<div class="contents" *ngFor="let field of physicalSpecificationFields">
    <div
        class="data"
        *ngIf="
            rpaType.physicalSpecifications &&
            rpaType.physicalSpecifications[field.key]
        "
    >
        <span class="display-label">{{ field.props.label }}</span>
        {{ rpaType.physicalSpecifications[field.key] }}
    </div>
</div>

<h4 class="section-heading top-buffer">
    <span>Performance Information</span>
    <div></div>
</h4>
<div class="contents" *ngFor="let field of performanceSpecificationFields">
    <div
        class="data"
        *ngIf="
            rpaType.performanceSpecifications &&
            rpaType.performanceSpecifications[field.key]
        "
    >
        <span class="display-label">{{ field.props.label }}</span>
        {{ rpaType.performanceSpecifications[field.key] }}
    </div>
</div>

<h4 class="section-heading top-buffer">
    <span>Other Information</span>
    <div></div>
</h4>
<div class="contents" *ngFor="let field of otherSpecificationFields">
    <div
        class="data"
        *ngIf="
            rpaType.otherSpecifications &&
            rpaType.otherSpecifications[field.key]
        "
    >
        <span class="display-label">{{ field.props.label }}</span>
        {{ rpaType.otherSpecifications[field.key] }}
    </div>
</div>
