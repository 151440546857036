<form
    *ngIf="canRender"
    [formGroup]="batteryForm"
    novalidate
    (ngSubmit)="saveBattery()"
    class="container-with-footer"
    [formIdentifier]="validationFields"
>
    <div class="container-fluid">
        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Battery Type</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batteryForm.controls['batteryTypeId']"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <ng-select
                    *ngIf="batteryTypeItems.length > 0"
                    [items]="batteryTypeItems"
                    bindLabel="name"
                    bindValue="id"
                    appendTo="body"
                    [addTag]="true"
                    formControlName="batteryTypeId"
                >
                </ng-select>
            </div>
        </div>
        <enhanced-help
            screenName="battery-edit"
            componentName="batteryTypeId"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Name/Designation</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batteryForm.controls['name']"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input class="form-control" formControlName="name" />
            </div>
        </div>
        <enhanced-help
            screenName="battery-edit"
            componentName="name"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Purchase Date</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batteryForm.controls['purchaseDate']"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        class="form-control"
                        #datepicker="bsDatepicker"
                        bsDatepicker
                        formControlName="purchaseDate"
                    />
                    <span
                        class="input-group-addon btn-default"
                        (click)="datepicker.toggle()"
                        [attr.aria-expanded]="datepicker.isOpen"
                    >
                        <i class="glyphicon glyphicon-calendar"></i>
                    </span>
                </div>
            </div>
        </div>
        <enhanced-help
            screenName="battery-edit"
            componentName="purchaseDate"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Estimated Historical Flight Time</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batteryForm.controls['initialFlightTime']"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <duration
                    formControlName="initialFlightTime"
                    [showHour]="true"
                    [showMinute]="true"
                    [showSecond]="true"
                    [clearable]="false"
                >
                </duration>
            </div>
        </div>
        <enhanced-help
            screenName="battery-edit"
            componentName="initialFlightTime"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Initial Cycles</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batteryForm.controls['initialCycles']"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"
                        formControlName="initialCycles"
                    />
                    <span class="input-group-addon">cycles</span>
                </div>
            </div>
        </div>
        <enhanced-help
            screenName="battery-edit"
            componentName="initialCycles"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Manufacturer Serial Number</label>

                <field-validation-indicator
                    class="pull-right"
                    [control]="batteryForm.controls['manufacturerSerialNumber']"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input
                    class="form-control"
                    formControlName="manufacturerSerialNumber"
                />
            </div>
        </div>
        <enhanced-help
            screenName="battery-edit"
            componentName="manufacturerSerialNumber"
            [compact]="true"
        >
        </enhanced-help>

        <div *ngIf="canUseNfc" class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>NFC UID</label>
                <field-validation-indicator
                    class="pull-right"
                    [control]="batteryForm.controls['nfcUid']"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input class="form-control" formControlName="nfcUid" />
            </div>
        </div>
        <enhanced-help
            *ngIf="canUseNfc"
            screenName="battery-edit"
            componentName="nfcUid"
            [compact]="true"
        >
        </enhanced-help>

        <div>
            <div>
                <label>Battery Classification</label>
                <field-validation-indicator
                    validatorFormControlName="isDummy"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
            </div>

            <lockable
                [isLocked]="lockedFields?.isDummy"
                message="This battery has been used on a real mission so can't be made dummy"
            >
                <ng-template lockedMode>
                    {{ battery.isDummy ? 'Dummy' : 'Real' }}
                    Battery
                </ng-template>
                <ng-template unlockedMode>
                    <div
                        class="input-group"
                        btnRadioGroup
                        formControlName="isDummy"
                        style="padding-top: 5px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="false"
                            [disabled]="working"
                            >Real Battery</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="true"
                            [disabled]="working"
                            >Dummy Battery</label
                        >
                    </div>
                </ng-template>
            </lockable>
            <validation-messages [control]="isDummy"> </validation-messages>
        </div>
        <enhanced-help
            screenName="battery-edit"
            componentName="isDummy"
            [compact]="true"
        >
        </enhanced-help>
    </div>

    <div class="action-container">
        <button
            type="button"
            class="btn btn-default pull-left"
            (click)="cancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary pull-right"
            type="submit"
            [disabled]="working"
        >
            Save
        </button>
    </div>
</form>
