import { Component, Input } from '@angular/core';
import {
    AddressDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NotFound,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    SubscriptionService
} from '@flyfreely-portal-ui/flyfreely';
import { AddressDialoguesService } from 'libs/addresses/src/lib/address-dialogues.service';
import { Subject, firstValueFrom, throwError } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'billing-address-tax-details',
    template: `
        <h5>Billing Address</h5>
        <loading-indicator [isLoading]="loadingAddress"></loading-indicator>
        <ng-container *ngIf="!loadingAddress && address != null">
            <p>{{ address.street }}</p>
            <p>{{ address.street2 }}</p>
            <p>
                {{ address.suburb }}
            </p>
            <p>{{ address.state }} {{ address.postcode }}</p>
            <p>{{ address.countryName }}</p>
            <a (click)="editAddress()">Edit Address</a>
        </ng-container>
        <p *ngIf="!loadingAddress && address == null">
            <a (click)="editAddress()">Setup Address</a>
        </p>
        <h5>Tax Information</h5>
        <loading-indicator [isLoading]="loadingTax"></loading-indicator>
        <ng-container *ngIf="!loadingTax && taxAuthorities != null">
            <div *ngFor="let tax of taxAuthorities" class="form-inline">
                <span class="display-label right-buffer">{{ tax.name }}</span>
                <editable
                    editableOnEnter
                    [updateFn]="saveTaxAuthority"
                    [initialContext]="{
                        identifier:
                            tax.authorities.length > 0
                                ? tax.authorities[0].identifier
                                : '',
                        authority: tax
                    }"
                >
                    <ng-template viewMode>
                        <span *ngIf="tax.authorities.length === 0">Setup</span>
                        <span *ngIf="tax.authorities.length > 0">{{
                            tax.authorities[0].identifier || 'Setup'
                        }}</span>
                    </ng-template>
                    <ng-template
                        editMode
                        let-context="context"
                        let-state="state"
                    >
                        <input
                            type="text"
                            class="form-control"
                            [(ngModel)]="context.identifier"
                        />
                        <div *ngIf="state.error != null" class="text-danger">
                            {{ state.error }}
                        </div>
                    </ng-template>
                </editable>
            </div>
        </ng-container>
        <p *ngIf="!loadingTax && taxAuthorities.length === 0">
            No additional tax information required
        </p>
    `,
    styles: [
        `
            :host {
                display: block;
                width: 300px;
            }
        `
    ]
})
export class BillingAddressAndTaxDetails {
    @Input()
    organisationId: number;

    address: AddressDto;
    loadingAddress = true;

    taxAuthorities: OrganisationAuthorityGroup[];
    loadingTax = true;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private subscriptionService: SubscriptionService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private logging: FlyFreelyLoggingService,
        private addressDialoguesService: AddressDialoguesService
    ) {}

    ngOnInit() {
        this.refreshAddress();

        this.subscriptionService
            .findTaxAuthorities(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorities => {
                this.taxAuthorities = authorities;
            })
            .add(() => (this.loadingTax = false));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAddress() {
        this.subscriptionService
            .findBillingAddress(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: address => {
                    this.address = address;
                },
                error: (error: FlyFreelyError) => {
                    if (error instanceof NotFound) {
                        return;
                    }
                    this.logging.error(
                        error,
                        `Error while loading address: ${error.message}`
                    );
                }
            })
            .add(() => (this.loadingAddress = false));
    }

    saveTaxAuthority = (value: {
        identifier: string;
        authority: OrganisationAuthorityGroup;
    }) => {
        if (value.authority.authorities.length === 0) {
            return firstValueFrom(
                this.organisationAuthorityService
                    .newAuthority(
                        this.organisationId,
                        value.authority.id,
                        this.organisationId,
                        null
                    )
                    .pipe(
                        mergeMap(authority =>
                            this.organisationAuthorityService.createAuthority(
                                authority.id,
                                {
                                    identifier: value.identifier,
                                    startDate: '2020-01-01'
                                }
                            )
                        ),
                        tap(authority => {
                            value.authority.authorities.push(authority);
                            this.logging.success(`${value.authority.name} set`);
                        })
                    )
            );
        } else {
            const originalAuthority = value.authority.authorities[0];
            return firstValueFrom(
                this.organisationAuthorityService
                    .updateAuthority(originalAuthority.id, {
                        identifier: value.identifier,
                        startDate: originalAuthority.startDate || '2020-01-01',
                        requiresApprovalPolicy: null,
                        appliedEndorsementIdList: []
                    })
                    .pipe(
                        catchError((error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while updating authority: ${error.message}`
                            );
                            return throwError(() => error.message ?? 'Entry is invalid');
                        }),
                        tap(authority => {
                            value.authority.authorities = value.authority.authorities.map(
                                a =>
                                    a.id === originalAuthority.id
                                        ? authority
                                        : a
                            );
                            this.logging.success(`${value.authority.name} set`);
                        })
                    )
            );
        }
    };

    editAddress() {
        // FIXME this is a poor way to trigger the refresh

        this.addressDialoguesService
            .showAddressEdit(
                this.organisationId,
                this.address ??
                    ({
                        addressType: AddressDto.AddressType.BILLING,
                        organisationId: this.organisationId
                    } as AddressDto),
                this.address == null
                    ? 'Setup Billing Address'
                    : 'Edit Billing Address',
                ['addressType', 'name', 'phoneNumber'],
                'modal-task'
            )
            .onHidden.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshAddress());
    }
}
