/**
 * Contains common regex checks
 */

export const CHECK_INTEGER = /^[0-9]+$/;
export const CHECK_DECIMAL = /^[0-9]+(\.[0-9]+)?$/;
export const CHECK_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CHECK_HOURS_MINUTES_SECONDS = /^([0-5]?\d[hH]\s?)?([0-5]?\d[mM]?\s?)?([0-5]?\d[sS]?)?([0-9]+?\d[sShHmM]?)?$/;
export const CHECK_IS_TIME_STRING = /^(?:(\d+)[:h])?(?:(\d+)[:m])?(?:(\d+)s)?(?:(\d+)?([:hms]|$))$/;
export const CHECK_IS_TIME_WITH_COLONS = /^(\d+):[0-5][0-9]:[0-5][0-9]$/;
