import {
    Directive,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';

@Directive({
    selector: '[colorFadeOut]'
})
export class ColorFadeOutDirective implements OnChanges {
    @Input() defaultColor: string = '#FFB500';
    @Input() fadeTrigger: boolean;

    @HostBinding('style.backgroundColor') backgroundColor: string;
    @HostBinding('style.transition') transition =
        'background-color 0.5s ease-out';

    constructor() {
        this.backgroundColor = this.defaultColor;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fadeTrigger && changes.fadeTrigger.currentValue === true) {
            this.backgroundColor = '#ffffff';
        } else if (
            (changes.defaultColor && !this.fadeTrigger) ||
            (changes.fadeTrigger && changes.fadeTrigger.currentValue === false)
        ) {
            this.backgroundColor = this.defaultColor;
        }
    }
}
