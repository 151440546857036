import {
    AirspaceAuthorisationResponse,
    AirspaceCheckResponse,
    CurrentPersonDto,
    DisplayableMissionDto,
    MissionRoleDto,
    PersonRolesDto
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import { formatAuthorisationTime, formatCivilTwilight } from '../helpers';

export function findTimeValues(
    mission: DisplayableMissionDto,
    checkResponse: AirspaceCheckResponse
) {
    const timeZone =
        mission.location?.timeZone ??
        mission.timeZone ??
        checkResponse?.timeZone ??
        null;
    const now = moment(new Date()).utc().toISOString();

    const startTime = formatAuthorisationTime(
        mission.missionDate,
        mission.timeZone
    );
    const validatorStart = moment
        .tz(mission.missionDate, mission.timeZone)
        .toISOString();

    const endTime = formatAuthorisationTime(
        moment(mission.missionDate)
            .add(mission.missionEstimatedTime, 'seconds')
            .toDate(),
        mission.timeZone
    );
    const validatorEnd = moment
        .tz(mission.missionDate, mission.timeZone)
        .add(mission.missionEstimatedTime, 'seconds')
        .toISOString();

    const civilTwilightStart = formatCivilTwilight(
        checkResponse?.sunsetAndRise?.civilTwilightStart,
        timeZone
    );
    const validatorCivilTwilightStart = moment
        .tz(checkResponse?.sunsetAndRise?.civilTwilightStart, timeZone)
        .toISOString();
    const civilTwilightEnd = formatCivilTwilight(
        checkResponse?.sunsetAndRise?.civilTwilightEnd,
        timeZone
    );
    const validatorCivilTwilightEnd = moment
        .tz(checkResponse?.sunsetAndRise?.civilTwilightEnd, timeZone)
        .toISOString();

    // Need start and end in a different format for duration calculations
    const start = moment.tz(mission.missionDate, mission.timeZone);
    const end = moment.tz(
        moment(mission.missionDate).add(
            mission.missionEstimatedTime,
            'seconds'
        ),
        mission.timeZone
    );
    const timeDiff = start.diff(moment.tz(now, timeZone), 'days');
    const duration = moment(end).diff(start, 'minutes');
    const hoursDiff = moment(end).diff(start, 'hours');
    const minutesDiff = duration - hoursDiff * 60;
    const startRangeString = `${timeDiff} Days from now`;
    const endRangeString = `${hoursDiff}Hr ${minutesDiff} Min Approval Window`;
    return {
        timeZone,
        startTime,
        validatorStart,
        endTime,
        validatorEnd,
        startRangeString,
        endRangeString,
        civilTwilightStart,
        validatorCivilTwilightStart,
        civilTwilightEnd,
        validatorCivilTwilightEnd
    };
}

export function findPilotValues(
    mission: DisplayableMissionDto,
    people: PersonRolesDto[],
    currentUser: CurrentPersonDto
) {
    const currentJurisdiction = mission.location.airspaceJurisdiction;

    // Setup the RPIC details
    const rpic = mission.missionCrewDetails?.find(
        person =>
            person.role.coreRole === MissionRoleDto.CoreRole.PILOT_IN_COMMAND
    )?.person;
    const rpicPhone = rpic?.phoneNumber;
    const rpicArnAuth = rpic?.activeJurisdictions.find(
        j => j.id === currentJurisdiction.id
    );
    const rpicArn =
        rpicArnAuth != null ? rpicArnAuth.identifier?.identifier : '';
    const rpicAuthLabel =
        rpicArnAuth != null
            ? rpicArnAuth.identifier?.authorityType?.identifierLabel
            : 'Licence Number';

    // Setup the CRP details
    // Find all CRPs in the current org
    const crps = people?.filter(person =>
        person.roles.includes(PersonRolesDto.Roles.RCP)
    );
    // If the current user has a CRP role, use them as CRP, otherwise take the first CRP in the list.
    const crp = crps?.reduce(
        (acc, person) => (person.id === currentUser.id ? person : acc),
        crps != null ? crps[0] : null
    );
    const crpArnAuth = crp?.activeJurisdictions?.find(
        j => j.id === currentJurisdiction.id
    );

    const crpArn = crpArnAuth != null ? crpArnAuth.identifier?.identifier : '';
    const crpAuthLabel =
        crpArnAuth != null
            ? crpArnAuth.identifier?.authorityType?.identifierLabel
            : 'Licence Number';
    return {
        rpic,
        rpicPhone,
        rpicArn,
        rpicAuthLabel,
        crp,
        crpArn,
        crpAuthLabel
    };
}

function reduceToUniqueErrors(
    mergedErrors: { [key: string]: any },
    errorsToMerge: { [key: string]: any }
) {
    const keys = Object.keys(errorsToMerge);
    const newErrors = keys.filter(key => !mergedErrors[key]);
    const overlappingErrors = keys.filter(key => mergedErrors[key]);
    const overlappingErrorsToMerge = overlappingErrors.filter(
        key =>
            errorsToMerge[key] !== mergedErrors[key] &&
            errorsToMerge[key] !== null
    );
    const keysToMerge = newErrors.concat(overlappingErrorsToMerge);
    return {
        ...mergedErrors,
        ...keysToMerge.reduce(
            (acc, k) => ({ ...acc, [k]: errorsToMerge[k] }),
            {}
        )
    };
}

export function combineAuthorisationErrors(
    authorisation: AirspaceAuthorisationResponse
) {
    if (authorisation == null) {
        return [];
    }
    const mainErrors = authorisation.errors;
    const listErrors = authorisation.authorisationList
        .map(a => a?.errors)
        .reduce(
            (acc, errors) =>
                errors != null ? reduceToUniqueErrors(acc, errors) : acc,
            {}
        );
    return reduceToUniqueErrors(mainErrors, listErrors);
}

/**
 * A function to find the earliest start time among all authorisations.
 */
function findAuthorisationStartTime(
    authorisation: AirspaceAuthorisationResponse
) {
    if (authorisation.authorisationList.length === 0) {
        return null;
    }
    return authorisation.authorisationList
        .map(a => a.startTime)
        .reduce((acc, start) =>
            acc == null || moment(start).isBefore(moment(acc)) ? start : acc
        );
}

/**
 * A function to find the latest end time among all authorisations.
 */
function findAuthorisationEndTime(
    authorisation: AirspaceAuthorisationResponse
) {
    if (authorisation.authorisationList.length === 0) {
        return null;
    }
    return authorisation.authorisationList
        .map(a => a.endTime)
        .reduce((acc, end) =>
            acc == null || moment(end).isAfter(moment(acc)) ? end : acc
        );
}

export function removeWhiteSpace(string: string) {
    if (string == null) {
        return '';
    }
    return string.replace(/\s/g, '');
}
