/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.19.4
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AirspaceAuthorisationDto } from './airspaceAuthorisationDto';
import { AvailableCoverage } from './availableCoverage';
import { AvailableMaintenanceTasks } from './availableMaintenanceTasks';
import { ContactDto } from './contactDto';
import { ContactWithLocationDto } from './contactWithLocationDto';
import { CraftDto } from './craftDto';
import { CurrentAttachmentVersionDto } from './currentAttachmentVersionDto';
import { EquipmentDto } from './equipmentDto';
import { InUseMissionWorkflowVersionDto } from './inUseMissionWorkflowVersionDto';
import { LocationDetailsDto } from './locationDetailsDto';
import { LockedMissionFields } from './lockedMissionFields';
import { MaintenanceLogDto } from './maintenanceLogDto';
import { MissionActions } from './missionActions';
import { MissionCrewDetailsDto } from './missionCrewDetailsDto';
import { MissionMessageDto } from './missionMessageDto';
import { MissionObjectiveDto } from './missionObjectiveDto';
import { MissionOperationTypeDto } from './missionOperationTypeDto';
import { NotamDto } from './notamDto';
import { ParticipatingDeviceDto } from './participatingDeviceDto';
import { RequestersMissionApprovalDto } from './requestersMissionApprovalDto';
import { RpaTypeDto } from './rpaTypeDto';
import { ServiceabilitySignoffDto } from './serviceabilitySignoffDto';
import { SimpleAuthorityDto } from './simpleAuthorityDto';
import { SimplePersonDto } from './simplePersonDto';
import { SimpleWorkgroupDto } from './simpleWorkgroupDto';
import { SortieDto } from './sortieDto';
import { UnlistedBatterySetDto } from './unlistedBatterySetDto';
import { UnlistedEquipmentDto } from './unlistedEquipmentDto';
import { UnlistedPersonDto } from './unlistedPersonDto';
import { UnlistedRpaDto } from './unlistedRpaDto';
import { WeatherDto } from './weatherDto';

/**
 * A mission with all the common properties resolved so they can be displayed
 */
export interface DisplayableMissionDto {
    id: number;
    /**
     * The name given to this mission by the planner
     */
    name: string;
    /**
     * The current status of the mission
     */
    status: DisplayableMissionDto.Status;
    /**
     * The mission has had approvals or authorisations revoked after starting, and must be aborted
     */
    disallowed?: boolean;
    /**
     * The final debrief message following the mission
     */
    completionMessage?: string;
    /**
     * The timestamp for the planned start of the mission
     */
    missionDate?: string;
    /**
     * The timezone that the mission is occurring in (e.g., Australia/Queensland)
     */
    timeZone?: string;
    /**
     * The objective text for the mission
     */
    missionObjective?: string;
    missionObjectiveType?: MissionObjectiveDto;
    missionType?: MissionOperationTypeDto;
    missionOperationType?: MissionOperationTypeDto;
    /**
     * The type of the mission which normally represents how the mission was created
     */
    type?: DisplayableMissionDto.Type;
    /**
     * A unique identifier for the mission
     */
    uid?: string;
    /**
     * The ID of the location of the flight area
     */
    locationId?: number;
    /**
     * The name the user has given the location
     */
    locationName?: string;
    /**
     * The name of the organisation that owns the mission
     */
    organisationName?: string;
    /**
     * ${swagger.Mission.organisationId}
     */
    organisationId?: number;
    /**
     * Which workgroup is this mission associated with
     */
    workgroupId?: number;
    craftIds: Array<number>;
    /**
     * The name the user has given the RPA
     */
    craftNicknames: Array<string>;
    /**
     * ${swagger.Mission.equipment}
     */
    equipment: Array<EquipmentDto>;
    missionCrewDetails: Array<MissionCrewDetailsDto>;
    /**
     * Maximum flight height AGL in feet
     */
    maximumHeight?: number;
    /**
     * Any additional notes for the crew of the mission
     */
    crewNotes?: string;
    /**
     * The mission workflow version used for this mission
     */
    missionWorkflowVersionId?: number;
    /**
     * The estimated duration of the mission in seconds
     */
    missionEstimatedTime?: number;
    /**
     * The mission being dummy field
     */
    isDummy?: boolean;
    /**
     * The visual line of sight option of mission
     */
    visualLineOfSight?: DisplayableMissionDto.VisualLineOfSight;
    /**
     * The time in which mission occur
     */
    timeOfDay?: DisplayableMissionDto.TimeOfDay;
    /**
     * ${swagger.Mission.readyForFinalisation}
     */
    readyForFinalisation?: boolean;
    /**
     * The data model version identifier for this domain entity
     */
    modelVersion: number;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    /**
     * ${swagger.Generic.readyTime}
     */
    readyTime?: string;
    /**
     * ${swagger.Mission.emergencyContacts}
     */
    emergencyContacts?: string;
    /**
     * ${swagger.Mission.radioFrequencies}
     */
    radioFrequencies?: string;
    /**
     * ${swagger.Mission.aerodromesOfInterest}
     */
    missionContacts: Array<ContactDto>;
    /**
     * ${swagger.Mission.aerodromesOfInterest}
     */
    missionRadioFrequencies: Array<ContactDto>;
    /**
     * ${swagger.Mission.aerodromesOfInterest}
     */
    aerodromesOfInterest: Array<ContactWithLocationDto>;
    missionWorkflowVersion?: InUseMissionWorkflowVersionDto;
    additionalAuthorities: Array<SimpleAuthorityDto>;
    weather?: WeatherDto;
    approvals: Array<RequestersMissionApprovalDto>;
    sorties: Array<SortieDto>;
    actualStartTime?: string;
    actualEndTime?: string;
    actualLocationId?: number;
    /**
     * The outcome of the objectives of this mission
     */
    outcome?: DisplayableMissionDto.Outcome;
    /**
     * Notes about the objective outcome that was achieved
     */
    objectiveOutcomeNotes?: string;
    availableActions: MissionActions;
    lockedFields?: LockedMissionFields;
    unlistedRpas: Array<UnlistedRpaDto>;
    unlistedPersonnel: Array<UnlistedPersonDto>;
    unlistedBatterySets: Array<UnlistedBatterySetDto>;
    unlistedEquipment: Array<UnlistedEquipmentDto>;
    workgroup?: SimpleWorkgroupDto;
    location?: LocationDetailsDto;
    /**
     * The details of the RPA associated with this mission
     */
    crafts: Array<CraftDto>;
    /**
     * The list of documents attached to a mission
     */
    attachments: Array<CurrentAttachmentVersionDto>;
    /**
     * Flight attachments keyed on the flight id
     */
    flightAttachments: { [key: string]: Array<CurrentAttachmentVersionDto> };
    /**
     * The available mission coverage
     */
    insuranceCoverage: Array<AvailableCoverage>;
    /**
     * The discussion about mission
     */
    discussions?: Array<MissionMessageDto>;
    /**
     * Participating devices
     */
    devices?: Array<ParticipatingDeviceDto>;
    rpaTypes?: { [key: string]: RpaTypeDto };
    rpaTypeMaintenance?: { [key: string]: AvailableMaintenanceTasks };
    notams?: Array<NotamDto>;
    /**
     * All serviceability signoffs completed for this mission
     */
    serviceabilitySignoffs: Array<ServiceabilitySignoffDto>;
    /**
     * All maintenance logs/requests associated with this mission
     */
    maintenanceLogs: Array<MaintenanceLogDto>;
    checksumModificationTime: string;
    airspaceAuthorisationList: Array<AirspaceAuthorisationDto>;
    noNotamDeclarationPerson?: SimplePersonDto;
    /**
     * The timestamp of the no relevant NOTAM declaration
     */
    noNotamDeclarationTime?: string;
    /**
     * The feature level that are required to fly this mission
     */
    featureLevel: number;
}
export namespace DisplayableMissionDto {
    export type Status =
        | 'DRAFT'
        | 'READY_TO_FLY'
        | 'ACTIVATED'
        | 'PREPARED'
        | 'ON_SITE'
        | 'FLYING'
        | 'DONE_FLYING'
        | 'CANCELLED'
        | 'COMPLETED'
        | 'DELETED'
        | 'FINALISED'
        | 'AWAITING_SYNC';
    export const Status = {
        DRAFT: 'DRAFT' as Status,
        READY_TO_FLY: 'READY_TO_FLY' as Status,
        ACTIVATED: 'ACTIVATED' as Status,
        PREPARED: 'PREPARED' as Status,
        ON_SITE: 'ON_SITE' as Status,
        FLYING: 'FLYING' as Status,
        DONE_FLYING: 'DONE_FLYING' as Status,
        CANCELLED: 'CANCELLED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        DELETED: 'DELETED' as Status,
        FINALISED: 'FINALISED' as Status,
        AWAITING_SYNC: 'AWAITING_SYNC' as Status
    };
    export type Type =
        | 'STANDARD'
        | 'RETROSPECTIVE'
        | 'OFFLINE'
        | 'INSTANT_MISSION'
        | 'FIELD_APP_BACKUP'
        | 'IMPORTED';
    export const Type = {
        STANDARD: 'STANDARD' as Type,
        RETROSPECTIVE: 'RETROSPECTIVE' as Type,
        OFFLINE: 'OFFLINE' as Type,
        INSTANT_MISSION: 'INSTANT_MISSION' as Type,
        FIELD_APP_BACKUP: 'FIELD_APP_BACKUP' as Type,
        IMPORTED: 'IMPORTED' as Type
    };
    export type VisualLineOfSight = 'VLOS' | 'EVLOS' | 'BVLOS';
    export const VisualLineOfSight = {
        VLOS: 'VLOS' as VisualLineOfSight,
        EVLOS: 'EVLOS' as VisualLineOfSight,
        BVLOS: 'BVLOS' as VisualLineOfSight
    };
    export type TimeOfDay = 'DAY' | 'NIGHT' | 'DAY_NIGHT';
    export const TimeOfDay = {
        DAY: 'DAY' as TimeOfDay,
        NIGHT: 'NIGHT' as TimeOfDay,
        DAY_NIGHT: 'DAY_NIGHT' as TimeOfDay
    };
    export type Outcome = 'COMPLETED' | 'PARTIALLY_COMPLETED' | 'INCOMPLETE';
    export const Outcome = {
        COMPLETED: 'COMPLETED' as Outcome,
        PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED' as Outcome,
        INCOMPLETE: 'INCOMPLETE' as Outcome
    };
}
