import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface FormModel {
    value: FormControl<string>;  
}

@Component({
    selector: 'info-search',
    templateUrl: './info-search.component.html',
    styleUrls: ['../contact-pill.scss']
})
export class InformationSearchComponent implements OnInit, OnDestroy {
    @Input() readonly?: boolean;
    @Input() placeholder: string;
    @Input() showNearby = false;

    @Output() search = new EventEmitter<string>();
    @Output() liveSearch = new EventEmitter<string>();
    @Output() nearby = new EventEmitter<void>();

    private ngUnsubscribe$ = new Subject<void>();

    inputForm: FormGroup<FormModel>;

    constructor() {
        this.inputForm = new FormGroup<FormModel>({
            value: new FormControl<string>(null, Validators.required) 
        });
    }

    ngOnInit() {
        this.inputForm.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(change => this.liveSearch.emit(change.value));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    selectNearby() {
        this.nearby.emit();
    }

    submit() {
        this.search.emit(this.inputForm.value.value);
    }

    clear() {
        this.inputForm.reset();
    }
}
