import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * This component displays a generic confirmation dialogue that provides a consistent look
 * and feel for all operations.
 *
 */
@Component({
    selector: 'formly-dialogue',
    templateUrl: './formly-dialogue.component.html'
})
export class FormlyDialogueController<D> {
    options: FormlyFormOptions;

    @Input() title: string;
    @Input() action: string;
    @Input() fields: FormlyFieldConfig[];
    @Input() data: any;
    @Input() saveFunction: (data: any) => PromiseLike<D>;
    @Input() close: (data: D) => void;
    @Input() dismiss: (err: any) => void;
    @Input() screenAnalytics: string;
    @Input() destructiveAction: {
        label: string;
        fn: () => PromiseLike<D>;
    };

    @Input() canEdit: boolean;
    @Input() onlyEdit: boolean;
    editing: boolean;

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    inputForm = new FormGroup({});

    constructor(private logging: FlyFreelyLoggingService) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        // Initialise editing
        this.editing = this.onlyEdit;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    edit() {
        this.editing = true;
    }

    submit() {
        const doneWorking = this.workTracker.createTracker();
        this.saveFunction(this.data).then(
            (result: any) => {
                doneWorking();
                this.close(result);
            },
            (error: FlyFreelyError) => {
                this.logging.error(error, error.message);
                doneWorking();
            }
        );
    }

    destructive() {
        const doneWorking = this.workTracker.createTracker();
        this.destructiveAction.fn().then(
            result => {
                doneWorking();
                this.close(result);
            },
            (error?: FlyFreelyError) => {
                if (error != null) {
                    this.logging.error(error, error.message);
                }
                doneWorking();
            }
        );
    }

    cancel() {
        this.dismiss(null);
    }
}
