import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    AttachmentHandler,
    BatterySetDto,
    buildDisplayableMissionDocumentation,
    combineFormResponses,
    CompletedDocumentationDto,
    CompleteMissionCommand,
    CompleteSortieCommand,
    DisplayableDocumentation,
    DisplayableMissionDocumentation,
    DisplayableMissionDto,
    FEATURE_ADDITIONAL_FLIGHT_FIELDS,
    FEATURE_EQUIPMENT,
    FEATURE_MAINTENANCE,
    FEATURE_RPA_LOG_COLLECTION,
    FEATURE_SERVICEABILITY_SIGNOFF,
    FEATURE_UPLOAD_SORTIES,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    FormResponseCommand,
    hasFeatureFlag,
    MissionDocumentationDto,
    MissionService,
    NameValue,
    PersonDto,
    PersonsOrganisationDto,
    prepareResponses,
    SetupGuideActivityDto,
    SimpleLocationDto,
    SortieDto,
    toTimestamp,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaPipe } from '@flyfreely-portal-ui/resource-ui';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
    collapseAnimation,
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { DocumentationStorage } from 'libs/documentation/src/lib/interfaces';
import {
    FlightUploadService,
    ImportSortie
} from 'libs/flight-logs/src/lib/flight-upload.service';
import { InlineModal } from 'libs/inline-modal/src/lib/inline-modal.component';
import { SetupGuideChecklistService } from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { EmptyError, firstValueFrom, of, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    mapTo,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { findActiveApprovals } from '../helpers';
import { MissionDialoguesService } from '../mission-dialogues.service';
import { MissionRecordService } from '../mission-record-edit/mission-record.service';
import { completionFields } from './fields';
import {
    MissionCompletionFormModel,
    MissionCompletionService,
    ServiceabilitySignoffFields
} from './mission-completion.service';
import { IntersectionSpyDirective } from 'libs/ui/src/lib/intersection-spy/intersection-spy.directive';

@Component({
    selector: 'mission-completion-dialogue',
    templateUrl: './mission-completion-dialogue.component.html',
    styleUrls: ['../styles.scss'],
    providers: [
        MissionCompletionService,
        FlightUploadService,
        MissionRecordService
    ],
    animations: [
        collapseAnimation(),
        expandOnEnterAnimation(),
        collapseOnLeaveAnimation()
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionCompletionDialogue implements OnInit, OnDestroy {
    @Input() mission: DisplayableMissionDto;
    @Input() organisation: PersonsOrganisationDto;
    @Input() locations: SimpleLocationDto[];

    @Output() missionChanged = new EventEmitter<DisplayableMissionDto>();

    missionCompletionForm: FormGroup<MissionCompletionFormModel>;
    validationFields: FormlyFieldConfig[] = completionFields;

    working: boolean = false;
    workTracker = new WorkTracker();

    @ViewChild(IntersectionSpyDirective)
    intersectionSpyDirective: IntersectionSpyDirective;
    get showFlightLogPanel$() {
        return (
            this.intersectionSpyDirective?.spyService?.visibility$ ?? of(false)
        );
    }

    @ViewChild('inlineModal', { static: true }) inlineModal: InlineModal;
    @ViewChild('flightHeading', { static: true }) flightHeading: ElementRef;

    objectiveOutcomes: NameValue[];
    sortieStatuses: NameValue[];
    batterySets: BatterySetDto[];
    attachmentsHandler: AttachmentHandler;

    sorties: SortieDto[];
    pilots: PersonDto[];
    isActive: { [key: string]: boolean } = {};

    updatedDocumentation: DisplayableMissionDocumentation;

    canSubmitForApproval: boolean;
    canEditMissionCrew: boolean;
    canUseMissionWorkflow: boolean;
    canUploadSorties = false;
    hasFlightLogging = false;
    hasAdditionalFlightFields = false;
    canUseServiceabilitySignoffs: boolean;
    canUseMaintenance: boolean;

    allUnlistedLinked: boolean;
    allMaintenanceSubmitted: boolean;
    canUseEquipment: boolean;

    locked = true;
    allowFinalisingIncomplete = false;

    documentationStorage: DocumentationStorage = {
        storeFormResponse: (
            formId: number,
            step: string,
            response: FormResponseCommand
        ) =>
            firstValueFrom(
                this.missionService
                    .updateDocumentation(this.mission.id, {
                        [step]: [response]
                    })
                    .pipe(
                        tap(documentation =>
                            this.setupDocumentation(documentation)
                        ),
                        mapTo(undefined)
                    )
            )
    };

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<MissionCompletionDialogue>,
        modalOptions: ModalOptions,
        private missionService: MissionService,
        private formatRpaPipe: FormatRpaPipe,
        private missionCompletionService: MissionCompletionService,
        private flightUploadService: FlightUploadService,
        private flightLogsService: FlightLogsService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        @Inject(forwardRef(() => MissionDialoguesService))
        private missionDialoguesService: MissionDialoguesService,
        private setupGuideChecklistService: SetupGuideChecklistService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.objectiveOutcomes = MissionService.getObjectiveOutcomes();
        this.missionCompletionForm = this.missionCompletionService.form;

        modalOptions.closeInterceptor = () => {
            if (this.inlineModal.show) {
                this.inlineModal.closeComponent();
                return Promise.reject();
            } else {
                if (this.missionCompletionForm.dirty) {
                    return commonDialoguesService.showConfirmationDialogue(
                        'Confirm Cancel',
                        `You have unsaved changes, are you sure you want to cancel?`,
                        'Yes',
                        () => Promise.resolve()
                    );
                }
                return Promise.resolve();
            }
        };
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                this.changeDetector.detectChanges();
            });

        this.missionCompletionService.savedMission$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(mission => this.missionChanged.emit(mission));

        this.flightLogsService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshFlights());

        this.attachmentsHandler = this.missionService.attachmentHandler(
            this.mission.id
        );
        this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);

        // Keep the multiple components in sync
        this.missionCompletionForm.controls.actualStartTime.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(time => {
                this.missionCompletionForm.controls.actualStartTime.setValue(
                    time,
                    {
                        onlySelf: true,
                        emitEvent: false,
                        emitModelToViewChange: true
                    }
                );
            });

        this.missionCompletionForm.controls.actualEndTime.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(time => {
                this.missionCompletionForm.controls.actualEndTime.setValue(
                    time,
                    {
                        onlySelf: true,
                        emitEvent: false,
                        emitModelToViewChange: true
                    }
                );
            });

        this.missionCompletionForm.controls.outcome.statusChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.locked =
                    !this.missionCompletionForm.controls.outcome.valid;
                this.changeDetector.markForCheck();
            });

        this.missionCompletionService.setupMission(
            this.mission,
            this.mission.organisationId ?? this.organisation.id
        );
        this.missionCompletionService.allUnlistedLinked$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                allUnlistedLinked =>
                    (this.allUnlistedLinked = allUnlistedLinked)
            );
        this.missionCompletionService.allMaintenanceSubmitted$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                allMaintenanceSubmitted =>
                    (this.allMaintenanceSubmitted = allMaintenanceSubmitted)
            );

        this.refreshBatterySets();
        this.refreshDocumentation();

        this.filterPilots();

        this.canSubmitForApproval = !this.organisation.personalOrganisation;

        this.hasFlightLogging = hasFeatureFlag(
            this.organisation,
            FEATURE_RPA_LOG_COLLECTION
        );
        this.hasAdditionalFlightFields = hasFeatureFlag(
            this.organisation,
            FEATURE_ADDITIONAL_FLIGHT_FIELDS
        );

        this.canUseServiceabilitySignoffs = hasFeatureFlag(
            this.organisation,
            FEATURE_SERVICEABILITY_SIGNOFF
        );

        this.canUseMaintenance = hasFeatureFlag(
            this.organisation,
            FEATURE_MAINTENANCE
        );
        this.canUseEquipment = hasFeatureFlag(
            this.organisation,
            FEATURE_EQUIPMENT
        );
        this.canUploadSorties = hasFeatureFlag(
            this.organisation,
            FEATURE_UPLOAD_SORTIES
        );

        if (this.canUseServiceabilitySignoffs) {
            this.missionCompletionService.refreshServiceabilitySignoffs(
                this.mission
            );
            this.missionCompletionForm.controls.flights.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
                .subscribe(() =>
                    this.missionCompletionService.refreshServiceabilitySignoffs(
                        this.mission
                    )
                );
        }

        this.sorties = this.mission.sorties;

        this.flightUploadService.flights$.pipe(take(1)).subscribe(sorties => {
            this.parseBulkFlights(sorties);
        });

        this.activeTab('objectives');
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshFlights() {
        this.missionService
            .findMission(this.mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(m => {
                this.missionCompletionService.updateFlights(m.sorties);
            })
            .add(this.workTracker.createTracker());
    }

    addFlight() {
        if (this.missionCompletionForm.dirty) {
            this.save().then(() => this.createNewSortie());
        } else {
            this.createNewSortie();
        }
    }

    createNewSortie() {
        this.missionService
            .newSortie(this.mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: sortie => {
                    this.missionCompletionService.addFlight({
                        ...sortie,
                        craftId:
                            this.mission.craftIds.length === 1 &&
                            this.mission.unlistedRpas.length === 0
                                ? this.mission.craftIds[0]
                                : null,
                        pilotId:
                            this.mission.missionCrewDetails.length === 1 &&
                            this.mission.unlistedPersonnel.length === 0
                                ? this.mission.missionCrewDetails[0].person.id
                                : null
                    });
                    this.changeDetector.detectChanges();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error creating new flight: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    deleteFlight(ix: number) {
        this.missionCompletionService.deleteFlight(ix);
    }

    deleteAllFlights() {
        if (this.flights.length === 0) {
            return;
        }
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete All Flights',
                'Are you sure you want to delete all flights for this mission?',
                'Delete All',
                () => Promise.resolve()
            )
            .then(() => {
                this.missionCompletionService.deleteAllFlights();
            })
            .then(() => {
                this.save();
                this.changeDetector.markForCheck();
            });
    }

    bulkFlightUpload() {
        this.flightUploadService.showBulkUpload(
            this.mission.missionDate,
            this.missionCompletionService.availableRpas$,
            this.missionCompletionService.availablePersonnel$,
            this.missionCompletionService.availableBatterySets$,
            this.missionCompletionService.allRpas$,
            this.missionCompletionService.allBatterySets$
        );
    }

    parseBulkFlights(sorties: ImportSortie[]) {
        // create one new flight to get the correct number. Then populate the rest iterating off of this.
        this.missionService
            .newSortie(this.mission.id)
            .pipe(
                tap(result => {
                    for (let i = 0; i < sorties.length; i++) {
                        const newSortie: SortieDto = {
                            ...result,
                            ...sorties[i],
                            number: result.number + i,
                            id: i === 0 ? result.id : null,
                            creationTime: new Date().toISOString(),
                            modificationTime: new Date().toISOString()
                        };
                        this.missionCompletionService.addFlight(newSortie);
                    }
                }),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: result => {
                    this.changeDetector.markForCheck();
                    this.save()
                        .then(() => {
                            this.logging.success(
                                `Successfully imported ${sorties.length} flights.`
                            );
                            this.flightUploadService.hideDialogue(false);
                        })
                        .catch((error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `error creating flights for this mission: ${error.message}`
                            );
                            this.flightUploadService.hideDialogue(true);
                        });
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `error creating flights for this mission: ${error.message}`
                    );
                    this.flightUploadService.hideDialogue(true);
                }
            })
            .add(this.workTracker.createTracker());
    }

    filterPilots() {
        this.pilots = this.mission.missionCrewDetails.map(p => p.person);
        this.pilots.filter((person, i) => this.pilots.indexOf(person) === i);
    }

    private refreshBatterySets() {
        this.missionCompletionService.allBatterySets$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(batterySets => (this.batterySets = batterySets));
    }

    refreshDocumentation() {
        if (this.mission.id == null) {
            return Promise.resolve();
        }

        return firstValueFrom(
            this.missionService
                .findDocumentation(this.mission.id)
                .pipe(
                    tap(documentation => this.setupDocumentation(documentation))
                )
        );
    }

    private setupDocumentation(documentation: MissionDocumentationDto) {
        this.missionCompletionForm.patchValue({
            documentation: buildDisplayableMissionDocumentation(
                documentation,
                findActiveApprovals(this.mission.approvals),
                this.mission.crafts,
                this.formatRpaPipe.transform
            )
        });
        this.changeDetector.detectChanges();
    }

    showSortieDocumentation(flightGroup: FormControl<CompleteSortieCommand>) {
        const flight = flightGroup.value;
        const documentation = this.missionCompletionForm.controls.documentation
            .value as DisplayableMissionDocumentation;

        // Filter documentation by RPA on the flight and default organisation documents
        const docs = Object.keys(documentation.requiredDocumentation).reduce(
            (acc, key) => ({
                ...acc,
                [key]: documentation.requiredDocumentation[key].filter(
                    doc =>
                        (doc.relatedEntityId === flight.craftId &&
                            doc.relatedEntityType === 'Craft') ||
                        doc.relatedEntityId == null
                )
            }),
            {}
        );

        if (flight?.id == null) {
            this.showSortieDocumentationDialogue(docs, null, flightGroup);
        } else {
            this.missionService
                .findSortieDocumentation(this.mission.id, flight.id)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(sortieDocs => {
                    this.showSortieDocumentationDialogue(
                        docs,
                        sortieDocs,
                        flightGroup
                    );
                })
                .add(this.workTracker.createTracker());
        }
    }

    private showSortieDocumentationDialogue(
        docs: any,
        sortieDocs: CompletedDocumentationDto,
        flightGroup: FormControl<CompleteSortieCommand>
    ) {
        const storedResponses = sortieDocs ?? { formResponses: {} };

        const formResponses = combineFormResponses([
            storedResponses ? storedResponses.formResponses : {}
        ]);

        const sortieDocumentation: DisplayableDocumentation = {
            requiredDocumentation: docs,
            formResponses: formResponses
        };
        const modal =
            this.missionDialoguesService.showFlightDocumentationEditorDialogue(
                this.mission,
                flightGroup,
                sortieDocumentation,
                'post-mission'
            );

        modal.content.documentationUpdate
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                takeUntil(this.modal.onHidden)
            )
            .subscribe(updatedResponses => {
                this.changeDetector.detectChanges();
            });
    }

    onServiceabilityUpdate(mission: DisplayableMissionDto) {
        this.missionCompletionForm.controls.maintenanceLogs.patchValue(
            mission.maintenanceLogs
        );
        this.missionCompletionForm.controls.serviceability.patchValue(
            mission.serviceabilitySignoffs
        );
        this.missionChanged.emit(mission);
    }

    onSectionChange(sectionId: string) {
        this.activeTab(sectionId);
    }

    activeTab(section: string) {
        this.isActive = {};
        this.isActive[section] = true;
    }

    scrollTo(section: string) {
        this.activeTab(section);
        document
            .querySelector(`#${section}`)
            .scrollIntoView({ behavior: 'smooth' });
    }

    // reloadMissionPage() {
    //     this.missionDialoguesService.showMissionDetailsThroughState(
    //         this.mission?.id
    //     );
    // }

    submit() {
        this.save()
            .then(() =>
                firstValueFrom(
                    this.missionService.readyForFinalisation(this.mission.id)
                )
            )
            .then(mission => {
                this.missionChanged.emit(mission);
                this.logging.info(
                    'The mission has been flagged as ready to finalise with the approver'
                );
                this.modal.hide();
            });
    }

    finalise() {
        this.save(true)
            .then(() =>
                this.commonDialoguesService.showConfirmationDialogue(
                    'Finalise Mission',
                    'Do you want to finalise this mission? No further edits will be allowed',
                    'Finalise',
                    () =>
                        this.missionService.finaliseMission(
                            this.mission.id,
                            this.allowFinalisingIncomplete || false
                        )
                )
            )
            .then(
                results => {
                    this.missionChanged.emit(results);
                    this.logging.success(
                        'Mission finalised, no further changes possible'
                    );
                    this.setupGuideChecklistService.completeActivityTask(
                        SetupGuideActivityDto.StepIdentifier.COMPLETE_MISSION,
                        this.mission.id
                    );
                    this.modal.hide();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while finalising mission: ${error.message}`
                    );
                }
            );
    }

    save(toFinalise = false) {
        const value = this.missionCompletionForm.value;
        // if (this.updatedDocumentation != null) {
        //     const sortieDocumentation: CompletedDocumentationDto =
        //     this.updatedDocumentation.sortieResponses;
        // } else {}

        const setSortieDuration = (
            sortie: SortieDto | CompleteSortieCommand
        ) => {
            const status = SortieDto.Status;
            if (
                sortie.status === status.NOT_STARTED ||
                sortie.status === status.STARTED
            ) {
                if (
                    sortie.duration > 0 ||
                    sortie.manualDuration > 0 ||
                    sortie.durationSource !== SortieDto.DurationSource.MANUAL
                ) {
                    return toFinalise ? status.COMPLETED : status.STARTED;
                }
            }
            return sortie.status;
        };

        const sortieCmd: CompleteSortieCommand[] =
            this.missionCompletionForm.controls.flights.value.map(f => ({
                id: f.id,
                number: f.number,
                durationSource: f.durationSource,
                manualDuration: f.manualDuration,
                notes: f.notes,
                status: setSortieDuration(f),
                reason: f.reason,
                pilotId: f.pilotId,
                craftId: f.craftId,
                batterySetId: f.batterySetId,
                equipmentIds: f.equipmentIds,
                manualStartTime: f.manualStartTime,
                crew: f.crew.filter(
                    c => c.missionRoleId != null && c.personId != null
                ),
                manualTakeoffPoint: f.manualTakeoffPoint,
                manualLandingPoint: f.manualLandingPoint
                // formResponses:
                //     documentation.sortieResponses[f.number].formResponses,
            }));

        const cmd: CompleteMissionCommand = {
            actualStartTime: toTimestamp(
                value.actualStartTime,
                this.mission.timeZone
            ),
            actualEndTime: toTimestamp(
                value.actualEndTime,
                this.mission.timeZone
            ),
            actualLocationId: this.mission.locationId,
            formResponses:
                value.documentation != null
                    ? prepareResponses(value.documentation.formResponses)
                    : {},
            message: value.journal,
            outcome: value.outcome,
            objectiveOutcomeNotes: value.objectiveOutcomeNotes,
            sorties: sortieCmd
        };

        const doneWorking = this.workTracker.createTracker();

        return firstValueFrom(
            this.missionService
                .completeMission(this.mission.id, cmd)
                .pipe(takeUntil(this.ngUnsubscribe$))
        ).then(
            result => {
                this.logging.success('The mission has been saved');
                this.missionCompletionForm.markAsPristine();
                this.mission = result;
                this.missionChanged.emit(result);
                doneWorking();

                return result;
            },
            (error: FlyFreelyError | EmptyError) => {
                if (error instanceof EmptyError) {
                    // This just means the modal is being destroyed before the observable has emitted its first value and it can be ignored
                    doneWorking();
                    return Promise.resolve(null);
                }
                this.logging.error(
                    error,
                    `Error while saving mission: ${error.message}`
                );
                doneWorking();

                return Promise.reject(error);
            }
        );
    }

    saveAndClose() {
        this.save().then(results => this.modal.hide());
    }

    updateMission(mission: DisplayableMissionDto) {
        this.mission = mission;
        this.missionCompletionService.setupMission(
            mission,
            this.mission.organisationId ?? this.organisation.id
        );
        this.missionChanged.emit(mission);
    }

    close() {
        this.modal.hide();
    }

    isSubmittable() {
        return (
            this.missionCompletionForm.valid &&
            this.allUnlistedLinked === true &&
            this.allMaintenanceSubmitted === true
        );
    }

    get flights() {
        return <FormArray<FormControl<CompleteSortieCommand>>>(
            this.missionCompletionForm.get('flights')
        );
    }

    get serviceabilitySignoffs() {
        return <FormArray<FormControl<ServiceabilitySignoffFields>>>(
            this.missionCompletionForm.get('serviceabilitySignoffs')
        );
    }
}
