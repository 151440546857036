import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

/**
 * adds HTML validation to all input formly fields to prevent entering HTML to text fields
 * Can be overridden by adding a 'allowHtml' field set to true in the field's props
 */
export const htmlValidationExtension: FormlyExtension = {
    prePopulate(field: FormlyFieldConfig): void {
        if (field.type !== 'input' || field.props['allowHtml'] === true) {
            return;
        }
        field.validators = {
            validation: ['html']
        };
    }
};
