<div class="panel">
    <div class="panel-body vertical-container">
        <ngx-loading [show]="working"></ngx-loading>
        <button
            *ngFor="let report of reports$ | async"
            type="button"
            class="btn btn-sm btn-default"
            (click)="switchReport(report['id'])"
        >
            {{ report.metadata.name || '(No name)' }}
        </button>
        <button
            type="button"
            (click)="create()"
            class="btn btn-primary btn-sm"
            placement="right"
            tooltip="Create a new blank report"
        >
            <i class="fa-plus fa"></i> New Report
        </button>
    </div>
</div>
