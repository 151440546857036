<ng-container>
    <div class="fill" *ngIf="notifications">
        <div *ngFor="let group of notifications">
            <h4 class="section-heading">
                <span>{{ group.grouping }}</span>
                <div></div>
            </h4>
            <div class="fill">
                <table class="table" style="width: 100%">
                    <thead>
                        <tr>
                            <th style="width: 40%">Name</th>
                            <th style="width: 20%">App notifications</th>
                            <th style="width: 20%">Email notifications</th>
                            <!-- <th style="width: 20%">Push notifications</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let notification of group.notifications">
                            <td>
                                {{ notification.name }}
                            </td>
                            <td>
                                <div mdcSwitch>
                                    <div mdcSwitchThumb>
                                        <input
                                            type="checkbox"
                                            mdcSwitchInput
                                            [(ngModel)]="notification.muteApp"
                                            (change)="
                                                updateNotificationSetting(
                                                    notification
                                                )
                                            "
                                            [disabled]="working"
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div mdcSwitch>
                                    <div mdcSwitchThumb>
                                        <input
                                            type="checkbox"
                                            mdcSwitchInput
                                            [(ngModel)]="notification.muteEmail"
                                            (change)="
                                                updateNotificationSetting(
                                                    notification
                                                )
                                            "
                                            [disabled]="working"
                                        />
                                    </div>
                                </div>
                            </td>
                            <!-- <td>
                                <div mdcSwitch>
                                    <div mdcSwitchThumb>
                                        <input
                                            type="checkbox"
                                            mdcSwitchInput
                                            [(ngModel)]="notification.mutePush"
                                            (change)="
                                                updateNotificationSetting(
                                                    notification
                                                )
                                            "
                                            [disabled]="working"
                                        />
                                    </div>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <loading-indicator [isLoading]="working"></loading-indicator>
</ng-container>
