<div>
    <div *ngFor="let section of response.sections">
        <h5>{{ section.name }}</h5>
        <table
            *ngIf="section.controls.length > 0 && !section.repeatingGroup"
            class="table"
        >
            <tbody>
                <ng-container *ngFor="let control of section.controls">
                    <tr *ngIf="displayConditions[control.id]">
                        <th>{{ control.label }}</th>

                        <td
                            *ngIf="displayValues[control.id] != null &&
                                displayValues[control.id].type === 'html'
                            "
                            [innerHtml]="displayValues[control.id].value"
                            style="width: 30%"
                            class="wordwrap"
                        ></td>
                        <td
                            *ngIf="displayValues[control.id] != null &&
                                displayValues[control.id].type === 'img'
                            "
                            style="width: 30%"
                            class="wordwrap"
                        >
                            <img
                                [authenticatedSrc]="
                                    displayValues[control.id].value
                                "
                            />
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <table
            *ngIf="section.controls.length > 0 && section.repeatingGroup"
            class="table"
        >
            <thead>
                <tr>
                    <th *ngFor="let control of section.controls">
                        {{ control.label }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let rowIx of range(
                            displayValues[section.controls[0].id]
                        )
                    "
                >
                    <ng-container *ngFor="let control of section.controls">
                        <td
                            *ngIf="displayValues[control.id][rowIx] != null &&
                                displayValues[control.id][rowIx].type === 'html'
                            "
                            [innerHtml]="displayValues[control.id][rowIx].value"
                            class="wordwrap"
                        ></td>
                        <td
                            *ngIf="displayValues[control.id][rowIx] != null &&
                                displayValues[control.id][rowIx].type === 'img'
                            "
                            class="wordwrap"
                        >
                            <img
                                [authenticatedSrc]="
                                    displayValues[control.id][rowIx].value
                                "
                            />
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>
