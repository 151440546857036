import {
    Component,
    Input,
    SimpleChanges
} from '@angular/core';
import {
    PersonDto,
    PersonService,
    PilotFlightAggregatesDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pilot-flight-history-summary',
    templateUrl: './pilot-flight-history-summary.component.html'
})
export class PilotFlightHistorySummary {
    @Input() person: PersonDto;
    @Input() managingOrganisationId: number;

    working = false;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    history: PilotFlightAggregatesDto;
    constructor(private personService: PersonService) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('person' in changes) {
            this.refreshPersonFlightHistory();
        }
    }

    private refreshPersonFlightHistory() {
        if (this.person == null) {
            return;
        }

        this.personService
            .findFlightStatisticsBrief(
                this.person.id,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(history => {
                this.history = history;
            }).add(this.workTracker.createTracker());
    }
}
