<div class="modal-header no-icon">
    <h4 class="modal-title">API Key Created</h4>
    <button type="button" (click)="cancel()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        Your API key was successfully created. Below is your key.
        <strong>Please save it</strong> before closing or refreshing this window
        as it will only be shown once.
    </p>
    <div class="top-buffer text-center">
        <strong>{{ identifier }}.{{ key }}</strong> <copy-to-clipboard [content]="identifier + '.' + key" ></copy-to-clipboard>
    </div>
</div>
