import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';
import { AvailableReportDto } from '@flyfreely-portal-ui/flyfreely';

@Injectable({
    providedIn: 'root'
})
export class RpaReportService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findAvailableTemplates(
        reportEventIdentifier: string,
        rpaId: number,
        managingOrganisationId: number
    ) {
        return this.http.get<AvailableReportDto[]>(
            `${this.baseUrl}/reportsapi/rpa/${rpaId}`,
            {
                params: new HttpParams()
                    .set('reportEventIdentifier', reportEventIdentifier)
                    .set(
                        'managingOrganisationId',
                        managingOrganisationId
                            ? managingOrganisationId.toString()
                            : null
                    )
            }
        );
    }

    findAvailableListTemplates(
        reportEventIdentifier: string,
        organisationId: number,
        managingOrganisationId: number
    ) {
        return this.http.get<AvailableReportDto[]>(
            `${this.baseUrl}/reportsapi/rpa`,
            {
                params: new HttpParams()
                    .set('reportEventIdentifier', reportEventIdentifier)
                    .set('organisationId', organisationId.toString())
                    .set(
                        'managingOrganisationId',
                        managingOrganisationId
                            ? managingOrganisationId.toString()
                            : null
                    )
            }
        );
    }

    getPdfListUrl(
        reportTemplateId: number,
        organisationId: number,
        managingOrganisationId: number
    ) {
        const params = new HttpParams()
            .set('organisationId', organisationId.toString())
            .set(
                'managingOrganisationId',
                managingOrganisationId
                    ? managingOrganisationId.toString()
                    : null
            )
            .set('format', 'pdf')
            .toString();

        return `${this.baseUrl}/reportsapi/rpa/render/${reportTemplateId}?${params}`;
    }

    getHtmlReportUrl(
        reportTemplateId: number,
        rpaId: number,
        managingOrganisationId: number
    ) {
        const params = new HttpParams()
            .set(
                'managingOrganisationId',
                managingOrganisationId
                    ? managingOrganisationId.toString()
                    : null
            )
            .toString();

        return `${this.baseUrl}/reportsapi/rpa/${rpaId}/render/${reportTemplateId}?${params}`;
    }

    getPdfReportUrl(
        reportTemplateId: number,
        rpaId: number,
        managingOrganisationId: number
    ) {
        const params = new HttpParams()
            .set(
                'managingOrganisationId',
                managingOrganisationId
                    ? managingOrganisationId.toString()
                    : null
            )
            .set('format', 'pdf')
            .toString();

        return `${this.baseUrl}/reportsapi/rpa/${rpaId}/render/${reportTemplateId}?${params}`;
    }
}
