import { Component, Input } from '@angular/core';
import {
    CraftDto,
    RegisterEntryOnMissionSummary,
    RpaCategoryGroup,
    RpaTypeDto
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaPipe, FormatRpaTypePipe } from '@flyfreely-portal-ui/resource-ui';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MissionEditService } from '../mission-edit.service';

/**
 * An inline component that display the mission specific register details per pilot.
 * Only accepts one pilot ID and displays register details relevant to that pilot.
 * This is shown in a table with one row for each RPA type and shows the RPA in that type and the pilot's status for that type on the current mission register.
 */
@Component({
    selector: 'pilot-register-details',
    templateUrl: './pilot-register-details.component.html'
})
export class PilotMissionRegisterDetails {
    @Input() pilotId: number;
    @Input() rowEven: boolean;

    entrySummary: RegisterEntryOnMissionSummary;
    statusSource: RpaCategoryGroup.RegisterStatusSource | 'NONE' = 'NONE';
    rpaTypes: { [rpaId: number]: string } = {};
    rpas: { [rpaId: number]: string } = {};

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private missionEditService: MissionEditService,
        private formatRpaTypePipe: FormatRpaTypePipe,
        private formatRpaPipe: FormatRpaPipe
    ) {}

    ngOnInit() {
        combineLatest([
            this.missionEditService.registerEntriesOnMission$.pipe(
                filter(e => e != null)
            ),
            this.missionEditService.rpaTypes$.pipe(filter(e => e != null)),
            this.missionEditService.rpas$.pipe(filter(e => e != null))
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([entries, rpaTypes, rpas]) => {
                this.entrySummary = entries.find(
                    e => e.entityId === this.pilotId
                );
                // Check if the register status is auto configured.
                // The status has to be auto configured in order for the RPA lists to come through.
                this.statusSource =
                    this.entrySummary?.registerStatusGroupList?.length > 0
                        ? this.entrySummary.registerStatusGroupList[0]
                              .registerStatusSource
                        : 'NONE';
                if (
                    this.statusSource ===
                        RpaCategoryGroup.RegisterStatusSource.AUTO &&
                    this.entrySummary.registerStatusGroupList.filter(
                        list =>
                            list.rpaIdList != null && list.rpaIdList.length > 0
                    ).length > 0
                ) {
                    this.parseRpaLists(this.entrySummary, rpaTypes, rpas);
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * A function to build lookups and other objects for the HTML to use
     * Reduces down the larger RPATypes array into only the types related to the RPA on the mission
     * Aims to prevent excessive logic and function calling in the HTML by pre-parsing all data for display
     * @param entry The register entry with details for this pilot
     * @param rpaTypes The full organisation RPA types list
     * @param rpas The RPAs on the current mission (from the mission edit service)
     */
    parseRpaLists(
        entry: RegisterEntryOnMissionSummary,
        rpaTypes: RpaTypeDto[],
        rpas: CraftDto[]
    ) {
        const rpaIds: number[] = entry.registerStatusGroupList.reduce(
            (acc2, s) => acc2.concat(s.rpaIdList),
            []
        );
        this.rpaTypes = rpaIds.reduce(
            (acc, id) => ({
                ...acc,
                [id]: this.formatRpaTypePipe.transform(
                    rpaTypes.find(
                        t => t.id === rpas.find(rpa => rpa.id === id)?.rpaTypeId
                    ),
                    'Unknown'
                )
            }),
            {}
        );
        const rpaNames = rpaIds.reduce(
            (acc, id) => ({
                ...acc,
                [id]:
                    this.formatRpaPipe.transform(
                        rpas.find(rpa => rpa.id === id)
                    ) ?? ''
            }),
            {}
        );
        const rpaNameLists = this.entrySummary.registerStatusGroupList
            .map(l => l.rpaIdList)
            .reduce(
                (acc, list) => ({
                    ...acc,
                    [list[0]]: list.map(id => rpaNames[id]).join(', ')
                }),
                {}
            );
        this.rpas = rpaIds.reduce(
            (acc, id) => ({
                ...acc,
                [id]: rpaNameLists[id]
            }),
            {}
        );
    }
}
