import { Injectable, OnDestroy } from '@angular/core';
import {
    ChangeDetails,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    WorkGroup,
    WorkGroupsService
} from 'libs/flyfreely/src/lib/services/workgroups.service';
import { ReplaySubject, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface updateDefaultWorkgroupSettingsCommand {
    workgroupId: number;
    defaultMissionWorkflowId: number;
    defaultOperationType: number;
    defaultMaximumHeight: number;
}

@Injectable({
    providedIn: 'root'
})
export class WorkgroupsDataService implements OnDestroy {
    private workingSource = new ReplaySubject<boolean>(1);
    private workgroupSource = new ReplaySubject<WorkGroup[]>(1);
    private changeSource = new Subject<ChangeDetails<WorkGroup>>();
    private personnelChangeSource = new Subject<void>();
    private rpaChangeSource = new Subject<void>();

    change$ = this.changeSource.asObservable();
    personnelChange$ = this.personnelChangeSource.asObservable();
    rpaChange$ = this.rpaChangeSource.asObservable();

    working$ = this.workingSource.asObservable();
    workgroup$ = this.workgroupSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private workgroupService: WorkGroupsService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workgroupSource.complete();
        this.workingSource.complete();
    }

    findWorkgroups(organisationId: number) {
        this.workgroupService
            .findWorkgroups(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: workgroups => {
                    this.workgroupSource.next(workgroups);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    createWorkgroup(organisationId: number, name: string) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService.createWorkgroup(organisationId, name).pipe(
            tap({
                next: result => {
                    this.changeSource.next({
                        type: ChangeDetails.Type.CREATE,
                        changedId: result.id,
                        result
                    });
                },
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    renameWorkgroup(workgroupId: number, name: string) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService.renameWorkgroup(workgroupId, name).pipe(
            tap({
                next: result => {
                    this.changeSource.next({
                        type: ChangeDetails.Type.UPDATE,
                        changedId: result.id,
                        result
                    });
                },
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    addPersonnelToWorkGroup(workgroupId: number, personId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService
            .addPersonToWorkgroup(workgroupId, personId)
            .pipe(
                tap({
                    next: result => {
                        this.changeSource.next({
                            type: ChangeDetails.Type.UPDATE,
                            changedId: result.id,
                            result
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }

    removePersonnelFromWorkgroup(workgroupId: number, personId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService
            .removePersonFromWorkgroup(workgroupId, personId)
            .pipe(
                tap({
                    next: result => {
                        this.changeSource.next({
                            type: ChangeDetails.Type.UPDATE,
                            changedId: result.id,
                            result
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }

    addRpaToWorkGroup(workgroupId: number, rpaId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService.addRpaToWorkgroup(workgroupId, rpaId).pipe(
            tap({
                next: result => {
                    this.changeSource.next({
                        type: ChangeDetails.Type.UPDATE,
                        changedId: result.id,
                        result
                    });
                },
                finalize: doneWorking
            }),
            takeUntil(this.ngUnsubscribe$)
        );
    }

    removeRpaFromWorkgroup(workgroupId: number, rpaId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService
            .removeRpaFromWorkgroup(workgroupId, rpaId)
            .pipe(
                tap({
                    next: result => {
                        this.changeSource.next({
                            type: ChangeDetails.Type.UPDATE,
                            changedId: result.id,
                            result
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }

    addWorkflowToWorkGroup(workgroupId: number, workflowId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService
            .addWorkflowToWorkgroup(workgroupId, workflowId)
            .pipe(
                tap({
                    next: result => {
                        this.changeSource.next({
                            type: ChangeDetails.Type.UPDATE,
                            changedId: result.id,
                            result
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }

    removeWorkflowFromWorkgroup(workgroupId: number, workflowId: number) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService
            .removeWorkflowFromWorkgroup(workgroupId, workflowId)
            .pipe(
                tap({
                    next: result => {
                        this.changeSource.next({
                            type: ChangeDetails.Type.UPDATE,
                            changedId: result.id,
                            result
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }

    updateDefaultWorkgroupSettings(
        command: updateDefaultWorkgroupSettingsCommand
    ) {
        const doneWorking = this.workTracker.createTracker();
        return this.workgroupService
            .updateDefaultWorkgroupSettings(
                command.workgroupId,
                command.defaultMissionWorkflowId,
                command.defaultOperationType,
                command.defaultMaximumHeight
            )
            .pipe(
                tap({
                    next: result => {
                        this.changeSource.next({
                            type: ChangeDetails.Type.UPDATE,
                            changedId: result.id,
                            result
                        });
                    },
                    finalize: doneWorking
                }),
                takeUntil(this.ngUnsubscribe$)
            );
    }
}
