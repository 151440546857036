import { Component, Input } from '@angular/core';
import {
    AnalysedFlightLogDto,
    BatterySummary,
    CoordinateDto,
    CraftService,
    DEBUG_REPORT_FIELDS,
    DisplayableMissionDto,
    FlightLogsService,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    fromTimestamp,
    RpaTypesService,
    SortieDto,
    UserDebugReportCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { distance } from '@turf/distance';
import { isNumber } from '@turf/helpers';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { getFeatureGroups } from 'libs/locations/src/lib/helpers';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { firstValueFrom, Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { flightSummaryToFeatures } from '../helpers';

interface DistanceValue {
    type: 'VALUE';
    value: number;
}
interface DistanceNoHome {
    type: 'NO_HOME';
}

type Distance = DistanceNoHome | DistanceValue;

@Component({
    selector: 'flight-analyser',
    templateUrl: './flight-analyser-dialogue.component.html',
    styleUrls: ['./flight-analyser-dialogue.component.scss']
})
export class FlightAnalyser {
    @Input() mission?: DisplayableMissionDto;
    @Input() flight?: SortieDto;
    @Input() flightLogId: number;
    @Input() organisationId?: number;

    maxDistance: Distance;

    maxHeight: number;
    duration: number;
    startTime: Date;
    endTime: Date;
    startLocation: CoordinateDto;
    landingLocation: CoordinateDto;
    rpaType: string;
    rpaName: string;
    batteries: BatterySummary[] = [];
    rpaSerial: string;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    private refreshDetails = new Subject<void>();

    hasSubmittedDebugReport?: boolean;

    csvDownloadUrl: string;
    logDownloadUrl: string;
    kmlDownloadUrl: string;

    features: FeatureGroup[];

    baseImgUrl: string;

    constructor(
        private flightLogsService: FlightLogsService,
        private commonDialoguesService: CommonDialoguesService,
        private craftService: CraftService,
        private rpaTypesService: RpaTypesService,
        public modal: BsModalRef,
        private logging: FlyFreelyLoggingService,
        constants: FlyFreelyConstants
    ) {
        this.baseImgUrl = constants.IMG_URL;
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.flightLogsService
            .analyseFlightLog(this.flightLogId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: flightSummary => {
                    this.loadFlightSummary(flightSummary);
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while analysing log file: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());

        this.refreshDetails
            .pipe(
                mergeMap(() => {
                    const working = this.workTracker.createTracker();
                    return this.flightLogsService
                        .findFlightLogForAnalyser(
                            this.flightLogId,
                            this.organisationId
                        )
                        .pipe(
                            tap({
                                complete: working,
                                error: working
                            })
                        );
                })
            )
            .subscribe({
                next: details => {
                    this.hasSubmittedDebugReport =
                        details.debugReportList.length > 0;
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while fetch log file details: ${error.message}`
                    )
            });

        this.csvDownloadUrl = this.flightLogsService.getCsvDownloadUrl(
            this.flightLogId
        );

        this.logDownloadUrl = this.flightLogsService.getLogDownloadUrl(
            this.flightLogId
        );

        this.kmlDownloadUrl = this.flightLogsService.getKmlDownloadUrl(
            this.flightLogId
        );

        this.refreshDetails.next();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    loadFlightSummary(flightSummary: AnalysedFlightLogDto) {
        const flightFeatures = flightSummaryToFeatures(flightSummary);
        const newFeatureId =
            flightFeatures.reduce(
                (acc, g) =>
                    g.existingFeatures
                        .filter(f => isNumber(f.id))
                        .reduce((acc2, f) => Math.max(acc2, <number>f.id), acc),
                -1
            ) + 1;

        const newGroupId =
            flightFeatures.reduce((acc, g) => Math.max(acc, g.id), -1) + 1;

        const missionFeatures =
            this.mission != null && this.mission.location != null
                ? getFeatureGroups(
                      this.mission.location.features,
                      newFeatureId,
                      newGroupId
                  ).features
                : ([] as FeatureGroup[]);

        this.features = missionFeatures.concat(flightFeatures);

        if (
            this.organisationId &&
            flightSummary.rpaSerialNumber != null &&
            flightSummary.rpaSerialNumber !== ''
        ) {
            this.craftService
                .findCrafts(this.organisationId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(crafts => {
                    const craft = crafts.find(
                        c =>
                            c.internalSerialNumber ===
                            flightSummary.rpaSerialNumber
                    );
                    this.rpaName = craft?.nickname
                        ? craft.nickname
                        : flightSummary.rpaName;
                    if (craft && craft.rpaTypeId) {
                        this.rpaTypesService
                            .findRpaType(craft.rpaTypeId)
                            .pipe(takeUntil(this.ngUnsubscribe$))
                            .subscribe(model => {
                                this.rpaType = model.craftType;
                            });
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            this.rpaName = flightSummary.rpaName;
            this.rpaType = flightSummary.rpaType;
        }

        this.rpaSerial = flightSummary.rpaSerialNumber;
        this.batteries = flightSummary.batteries ?? [];

        this.startTime = fromTimestamp(flightSummary.startTime);
        this.endTime = fromTimestamp(flightSummary.endTime);
        this.duration =
            this.startTime != null && this.endTime != null
                ? moment(this.endTime).diff(this.startTime, 's')
                : null;

        this.startLocation = {
            latitude: flightSummary.startLatitude,
            longitude: flightSummary.startLongitude
        };
        this.landingLocation = {
            latitude: flightSummary.endLatitude,
            longitude: flightSummary.endLongitude
        };

        const distancePairs = flightSummary.flight.filter(
            p => p.rpaLatitude != null && p.rpaLongitude != null
        );
        this.maxDistance =
            distancePairs.length === 0 ||
            this.startLocation.longitude == null ||
            this.startLocation.latitude == null
                ? { type: 'NO_HOME' }
                : {
                      type: 'VALUE',
                      value: Math.round(
                          distancePairs.reduce(
                              (acc, p) =>
                                  Math.max(
                                      acc,
                                      distance(
                                          [
                                              this.startLocation.longitude,
                                              this.startLocation.latitude
                                          ],
                                          [p.rpaLongitude, p.rpaLatitude]
                                      )
                                  ),
                              0
                          ) * 1000
                      )
                  };

        this.maxHeight = flightSummary.flight.reduce(
            (acc, p) =>
                p.rpaHeight != null && typeof p.rpaHeight === 'number'
                    ? Math.max(acc, p.rpaHeight)
                    : acc,
            0
        );
    }

    isNoHome(value: Distance): value is DistanceNoHome {
        return value?.type === 'NO_HOME';
    }

    isValue(value: Distance): value is DistanceValue {
        return value?.type === 'VALUE';
    }

    submitDebugReport() {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Report an issue with this flight log to FlyFreely',
                'Submit Debug Report',
                true,
                true,
                DEBUG_REPORT_FIELDS,
                {
                    authoriseSharingWithVendors: true,
                    authoriseUseInTests: true
                } as UserDebugReportCommand,
                data =>
                    firstValueFrom(
                        this.flightLogsService.submitDebugReport(
                            this.flightLogId,
                            data
                        )
                    )
            )
            .then(() => {
                this.refreshDetails.next();
                this.logging.success('Debug report submitted');
            });
    }
}
