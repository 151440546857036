import { Component, EventEmitter, Output } from '@angular/core';
import {
    hasAnyPermission,
    isLoggedIn,
    PersonsOrganisationDto,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    NoLicenceToOrganisation,
    OrganisationSubscriptionsService,
    UserOrganisationDataService,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import {
    hasExistingCommercialOrganisations,
    isCurrentSubscription,
    isNoSubscriptions,
    isPersonalOrganisationSubscription
} from 'libs/subscriptions/src/lib/helpers';
import {
    CurrentSubscriptions,
    NoSubscriptions,
    PersonalOrganisationSubscription
} from 'libs/subscriptions/src/lib/interfaces';
import * as moment from 'moment-timezone';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'subscription-banner',
    template: `<ng-container *ngIf="subscription != null"
        ><ng-container
            *ngIf="
                subscription
                    | guardType: isNoSubscriptions as currentSubscription
            "
        >
            <div class="alert alert-info">
                <span class="fal fa-info-circle right-buffer"></span> You are
                currently on a Free plan which has limited features.
                <a
                    *ngIf="canManageBilling"
                    routerLink="orgadmin/subscriptions"
                    queryParamsHandling="merge"
                    analyticsOn
                    analyticsCategory="subscriptions"
                    analyticsAction="trial-upgrade"
                >
                    Click to upgrade today.
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                !hasExistingCommercialOrganisations &&
                (canCreateOrganisation || organisationDomainExists) &&
                (subscription | guardType: isPersonalOrganisationSubscription)
            "
        >
            <div
                *ngIf="canCreateOrganisation && !organisationDomainExists"
                class="alert alert-info"
            >
                <span class="fal fa-info-circle right-buffer"></span> You are
                using your personal organisation which is for recreational
                missions.
                <a (click)="createOrganisation.next()"
                    >Create an organisation</a
                >
                for commercial features and upgrade options.
            </div>
            <div
                *ngIf="!canCreateOrganisation && organisationDomainExists"
                class="alert alert-info"
            >
                <span class="fal fa-info-circle right-buffer"></span> The domain
                from your email address is associated with an existing
                organisation.
                <a (click)="requestAccess.next()">Request Access</a>
                to request to join this organisation.
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                subscription
                    | guardType: isCurrentSubscription as currentSubscription
            "
        >
            <div
                *ngIf="
                    currentSubscription?.status === 'past_due' &&
                    currentSubscription?.payment === 'automatic'
                "
                class="alert alert-danger"
            >
                <span class="fal fa-info-circle right-buffer"></span>Your
                subscription payment is overdue.
                <a
                    *ngIf="canManageBilling"
                    routerLink="orgadmin/subscriptions"
                    queryParamsHandling="merge"
                    analyticsOn
                    analyticsCategory="subscriptions"
                    analyticsAction="update-payment-details-overdue"
                    >Please update payment details here</a
                >
                <span *ngIf="!canManageBilling"
                    >Please contact your organisation owner.</span
                >
            </div>
            <div
                *ngIf="currentSubscription?.status === 'trialing'"
                class="alert alert-info"
            >
                <span class="fal fa-info-circle right-buffer"></span>Your
                {{ currentSubscription.name }} trial ends
                {{
                    daysToExpiry != null
                        ? 'in ' + daysToExpiry.toString() + ' days'
                        : 'soon'
                }}.
                <a
                    *ngIf="canManageBilling"
                    routerLink="orgadmin/subscriptions"
                    queryParamsHandling="merge"
                    analyticsOn
                    analyticsCategory="subscriptions"
                    analyticsAction="trial-upgrade"
                >
                    Click to upgrade today.
                </a>
            </div>
            <div
                *ngIf="currentSubscription?.isTriallingUp"
                class="alert alert-info"
            >
                <span class="fal fa-info-circle right-buffer"></span>Your
                {{ currentSubscription.trialUpPlan }} trial ends
                {{
                    daysToExpiry != null
                        ? 'in ' + daysToExpiry.toString() + ' days'
                        : 'soon'
                }}.
            </div>
        </ng-container>
    </ng-container>`,
    providers: [UserOrganisationDataService]
})
export class SubscriptionBanner {
    @Output() createOrganisation = new EventEmitter<void>();
    @Output() requestAccess = new EventEmitter<void>();
    private ngUnsubscribe$ = new Subject<void>();

    subscription:
        | CurrentSubscriptions
        | NoSubscriptions
        | PersonalOrganisationSubscription
        | null = null;

    canManageBilling = false;
    daysToExpiry: number | null = null;

    hasExistingCommercialOrganisations?: boolean;
    canCreateOrganisation = false;
    organisationDomainExists = false;

    constructor(
        subscriptionsService: OrganisationSubscriptionsService,
        userOrganisationDataService: UserOrganisationDataService,
        sharedService: WorkspaceStateService,
        userService: UserService
    ) {
        combineLatest([
            userOrganisationDataService.findOrganisationCreationPermissionsForUser(),
            userService.userChange$.pipe(filter(isLoggedIn))
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([organisationPermissions, user]) => {
                this.canCreateOrganisation =
                    organisationPermissions.canCreateOrganisation;
                this.organisationDomainExists =
                    organisationPermissions.organisationDomainExists;
                this.hasExistingCommercialOrganisations =
                    hasExistingCommercialOrganisations(
                        user.currentUsersOrganisations,
                        user.currentUser.id
                    );
            });

        subscriptionsService.currentSubscriptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(subscription => {
                if (subscription.type === 'subscriptions') {
                    this.subscription = subscription;
                    this.daysToExpiry =
                        subscription.trialEndTime != null
                            ? -moment().diff(
                                  moment(subscription.trialEndTime),
                                  'days'
                              )
                            : subscription.trialUpEndTime != null
                            ? -moment().diff(
                                  moment(subscription.trialUpEndTime),
                                  'days'
                              )
                            : null;
                    return;
                } else if (
                    subscription.type === 'no_subscriptions' ||
                    subscription.type === 'personal_organisation'
                ) {
                    this.subscription = subscription;
                    return;
                }
                this.subscription = null;
            });

        sharedService.currentOrganisation$
            .pipe(
                filter(
                    o =>
                        o.type === 'organisation_loaded' ||
                        o.type === 'no_licence'
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(
                (organisation: CurrentOrganisation | NoLicenceToOrganisation) =>
                    (this.canManageBilling = hasAnyPermission(
                        organisation.organisation,
                        PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
                    ))
            );
    }

    isCurrentSubscription = isCurrentSubscription;
    isNoSubscriptions = isNoSubscriptions;
    isPersonalOrganisationSubscription = isPersonalOrganisationSubscription;
}
