import { Pipe, PipeTransform } from '@angular/core';
import {
    ReferencedAuthorityDto,
    SimpleAuthorityDto
} from '@flyfreely-portal-ui/flyfreely';

@Pipe({
    name: 'sortAuthorities',
    pure: true
})
export class SortAuthoritiesPipe implements PipeTransform {
    transform(
        authorities: (SimpleAuthorityDto | ReferencedAuthorityDto)[]
    ): (SimpleAuthorityDto | ReferencedAuthorityDto)[] {
        if (!authorities) {
            return [];
        }
        return authorities.slice().sort((a, b) => {
            // 1. sort by organisation.name
            const orgCompare = a.organisation.name.localeCompare(
                b.organisation.name
            );
            if (orgCompare !== 0) {
                return orgCompare;
            }
            // 2. sort by authorityName
            const authorityNameA =
                a.authorityType.abbreviation ?? a.authorityType.name;
            const authorityNameB =
                b.authorityType.abbreviation ?? b.authorityType.name;
            const nameCompare = authorityNameA.localeCompare(authorityNameB);
            if (nameCompare !== 0) {
                return nameCompare;
            }
            // 3. sort by identifier
            const identifierA =
                a.identifier != null && a.authorityType.hasIdentifier
                    ? `${a.authorityType.identifierLabel} ${a.identifier}`
                    : '';
            const identifierB =
                b.identifier != null && b.authorityType.hasIdentifier
                    ? `${b.authorityType.identifierLabel} ${b.identifier}`
                    : '';
            return identifierA.localeCompare(identifierB);
        });
    }
}
