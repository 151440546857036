// Todo: this service will be used for prefrences in widgets and it is not compelete
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import {
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { PreferencesService } from '@flyfreely-portal-ui/flyfreely';
import * as moment from 'moment';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';

@Injectable()
export class WidgetsPreferencesService {
    tableConfig: TableConfig = {
        limit: 10,
        actions: [],
        limitSelection: [10, 25, 50, 100]
    };

    userPreferences: TableSetupUserPreferences; // Declare the userPreferences property

    constructor(private preferencesService: PreferencesService) {}

    findPreferences(component: string, organisationId: number) {
        return this.preferencesService
            .findPreferences<TableSetupUserPreferences>(
                component,
                organisationId
            )
            .pipe(shareReplay(1));
    }

    /**
     * Fetch legacy widget preferences that only stored a list of selected columns.
     */
    findLegacyPreferences(component: string, organisationId: number) {
        return this.preferencesService
            .findPreferences<string[]>(component, organisationId)
            .pipe(shareReplay(1));
    }

    /**
     * Return the table search with columns that are no longer available removed.
     * @param tableSearch
     * @param selectedColumns
     */
    cleanUpTableSearch(
        tableSearch: any | null,
        selectedColumns: string[] | null
    ): Record<string, any> | null {
        if (tableSearch == null || selectedColumns == null) {
            return {};
        }
        return Object.keys(tableSearch).reduce(
            (acc, key) => ({
                ...acc,
                [key]: selectedColumns.includes(key)
                    ? tableSearch[key]
                    : undefined
            }),
            {}
        );
    }

    updateDateRange(tableSearch: any, dateRange: string | null) {
        if (tableSearch && tableSearch[dateRange]) {
            tableSearch[dateRange] = tableSearch[dateRange].map(date =>
                moment(date).toDate()
            );
        }
        return tableSearch;
    }

    /**
     * Uses the provided preferences object, and cleans up any potentially invalid values.
     * @param preferences 
     * @param availableColumns 
     * @returns 
     */
    applyPreferences(preferences: TableSetupUserPreferences, availableColumns: TableColumn[], tableConfig: TableConfig) {
        const selectedColumns =
            preferences.selectedColumns ??
            findDefaultColumnSelection(availableColumns);

        return {
            selectedColumns,
            columnSorting: preferences.columnSorting,
            tableSearch: this.cleanUpTableSearch(
                preferences.tableSearch,
                selectedColumns
            ),
            itemLimit: preferences.itemLimit ?? tableConfig.limit
        };
    }
}
