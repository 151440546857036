import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    CraftDetailsDto,
    CraftService,
    CreateHistoricalMissionCommand,
    DisplayableMissionDto,
    FlightLogsService,
    flightNumberSearch,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionCrewDto,
    MissionDto,
    MissionOperationTypeDto,
    MissionRoleDto,
    MissionRoleService,
    missionSearch,
    MissionService,
    MissionSummaryDto,
    MissionWorkflowService,
    MissionWorkflowVersionDto,
    PersonRolesDto,
    personSearch,
    PersonService,
    SortieDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { distance } from '@turf/distance';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { FlightLogWithDuration } from '../flight-log-list/flight-log-list-dialogue.component';

const ORIGINAL_RPA = 'Original RPA';

interface DisplayableCompatibleLog {
    id: number;
    timeDifference: string;
    takeoffDifference: number;
    collectedBy: string;
    rpaDifference: string;
    selected: boolean;
    locked: boolean;
    selectedFlightId?: number;
}

enum RpaLinkingStatus {
    NO_MATCH = 'NO_MATCH',
    MATCH = 'MATCH',
    CAN_ASSIGN_TO_LOG = 'CAN_ASSIGN_TO_LOG',
    CAN_ASSIGN_TO_RPA = 'CAN_ASSIGN_TO_RPA',
    NOTHING = 'NOTHING'
}

interface flightIdWithLogId {
    flightLogId: number;
    flightId: number;
    flightNumber: number;
}

function calculateRpaLinkingStatus(logSerial: string, rpaSerial: string) {
    if (logSerial != null) {
        if (rpaSerial == null) {
            return RpaLinkingStatus.CAN_ASSIGN_TO_RPA;
        }
        return logSerial === rpaSerial
            ? RpaLinkingStatus.MATCH
            : RpaLinkingStatus.NO_MATCH;
    } else {
        return rpaSerial != null
            ? RpaLinkingStatus.CAN_ASSIGN_TO_LOG
            : RpaLinkingStatus.NOTHING;
    }
}

@Component({
    selector: 'assign-flight-log',
    templateUrl: './assign-flight-log.component.html',
    styles: [
        `
            .checkbox {
                padding-left: 5px;
                margin-top: 12px;
                margin-bottom: 5px;
            }

            :host ::ng-deep #table {
                font-size: 1em;
                font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial,
                    sans-serif;
            }

            :host ::ng-deep #table > thead > tr,
            :host ::ng-deep #table > tbody > tr {
                table-layout: auto;
                vertical-align: middle;
            }

            :host ::ng-deep #table > thead > tr > th.table-actions,
            .table-actions {
                max-width: 5%;
                width: 5%;
            }

            :host ::ng-deep #table > tbody > tr > td {
                padding: 2px 5px;
                text-align: center;
            }

            :host ::ng-deep #table > thead > tr > th {
                padding: 4px 5px;
                text-align: center;
            }

            :host
                ::ng-deep
                #table
                > thead
                > tr
                > th
                > .form-group
                > .form-checkbox
                > .checkbox {
                min-height: 20px;
                padding-left: 20px;
                padding-bottom: 1.3rem;
                margin-bottom: 0;
                font-weight: normal;
                cursor: pointer;
            }

            :host ::ng-deep #selectAllCheckbox > input {
                position: relative;
                width: 1.3em;
                height: 1.3em;
                float: left;
                margin-right: 0.5em;
            }

            :host ::ng-deep #selectAllCheckbox > i {
                position: absolute;
                font-size: 0.8em;
                line-height: 0;
                top: 50%;
                left: 20%;
            }

            :host ::ng-deep .ngx-form-icon {
                border: 1px solid #a9a9a9 !important;
                border-radius: 0.25em !important;
                height: 1.3em !important;
                width: 1.3em !important;
            }

            :host ::ng-deep .ngx-form-icon::before {
                border: 0.25rem solid #000 !important;
                border-left-width: 0 !important;
                border-top-width: 0 !important;
            }

            :host ::ng-deep .ngx-form-checkbox input:checked + .ngx-form-icon {
                background: unset;
            }
        `
    ],
    host: {
        class: 'container-with-footer'
    }
})
export class AssignFlightLog {
    @Input() flightLog: FlightLogWithDuration;
    @Input() organisationId: number;
    @Input() flightLogs: FlightLogWithDuration[];
    @Input() enhancedHelpActive: boolean = false;
    @Output() viewFlightLog = new EventEmitter<number>();
    @Output() done = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();

    private workTracker = new WorkTracker();
    working: boolean = false;

    isCreatingRetrospective = true;

    candidateMissions: MissionSummaryDto[];
    candidateFlights: SortieDto[];

    compatibleLogs: DisplayableCompatibleLog[];

    selectedMission: MissionSummaryDto;
    selectedFlight: SortieDto;
    flightRpa: CraftDetailsDto;

    retrospectiveMissionName: string;
    retrospectiveMissionPilot: PersonRolesDto;
    retrospectiveOperationTypeId: number;
    retrospectiveMissionWorkflowId: number;
    flightDate: string;
    pilots: PersonRolesDto[];
    crew: MissionCrewDto[] = [];
    flightLogIdList: flightIdWithLogId[] = [];
    rpicRoleId: number;
    pilotRoleId: number;
    createdSortie: SortieDto;

    operationTypes: MissionOperationTypeDto[];
    availableWorkflows: MissionWorkflowVersionDto[];

    configuration: Config = {
        ...DefaultConfig,
        infiniteScroll: false,
        paginationEnabled: false,
        fixedColumnWidth: false,
        checkboxes: true,
        tableLayout: {
            borderless: true,
            hover: true,
            striped: false,
            style: 'tiny',
            theme: 'light'
        }
    };

    configurationSecondTable: Config = {
        ...DefaultConfig,
        infiniteScroll: false,
        paginationEnabled: false,
        fixedColumnWidth: false,
        checkboxes: true,
        tableLayout: {
            borderless: true,
            hover: true,
            striped: false,
            style: 'tiny',
            theme: 'light'
        }
    };
    selectAll = false;
    columns: Columns[];
    columnsSecondTable: Columns[];
    currentData: DisplayableCompatibleLog[];
    currentPage = 0;

    private rpaSerialNumber: string;
    rpaName: string;

    rpaLinkingStatus: RpaLinkingStatus;

    personSearch = personSearch.bind(this);
    missionSearch = missionSearch.bind(this);
    flightNumberSearch = flightNumberSearch.bind(this);

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightLogsService: FlightLogsService,
        private missionService: MissionService,
        private missionWorkflowService: MissionWorkflowService,
        private craftService: CraftService,
        private personService: PersonService,
        private missionRoleService: MissionRoleService,
        private logging: FlyFreelyLoggingService,
        public modal: BsModalRef
    ) {
        this.columns = [
            {
                title: 'Time Difference',
                key: 'timeDifference'
            },
            {
                title: 'Uploaded By',
                key: 'collectedBy'
            },
            {
                title: 'Takeoff Difference',
                key: 'takeoffDifference'
            },
            {
                title: 'RPA Difference',
                key: 'rpaDifference'
            },
            {
                key: 'action',
                title: ' ',
                width: '5%',
                cssClass: {
                    name: 'table-actions',
                    includeHeader: true
                }
            }
        ];

        this.columnsSecondTable = [
            {
                title: 'Flight',
                key: 'flight',
                width: '15%'
            },
            {
                title: 'Time Difference',
                key: 'timeDifference'
            },
            {
                title: 'Uploaded By',
                key: 'collectedBy'
            },
            {
                title: 'Takeoff Difference',
                key: 'takeoffDifference'
            },
            {
                title: 'RPA Difference',
                key: 'rpaDifference'
            },
            {
                key: 'action',
                title: ' ',
                width: '5%',
                cssClass: {
                    name: 'table-actions',
                    includeHeader: true
                }
            }
        ];
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.findCandidateMissions();
        this.findPilots();
        this.findMissionOptions();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private findCandidateMissions() {
        this.flightLogsService
            .analyseFlightLog(this.flightLog.id)
            .pipe(
                mergeMap(summary => {
                    this.flightDate = moment(summary.startTime)
                        .toDate()
                        .toISOString();
                    const searchStart = moment(summary.startTime)
                        .add(-7, 'days')
                        .toISOString();
                    const searchEnd = moment(summary.endTime)
                        .add(7, 'days')
                        .toISOString();

                    this.rpaSerialNumber = summary.rpaSerialNumber;
                    this.rpaName = summary.rpaName;

                    this.findCompatibleLogs();
                    return this.missionService.findMissions(
                        this.organisationId,
                        {
                            startTime: searchStart,
                            endTime: searchEnd,
                            status: [
                                MissionDto.Status.READY_TO_FLY,
                                MissionDto.Status.COMPLETED
                            ]
                        }
                    );
                })
            )
            .subscribe({
                next: missions => {
                    this.candidateMissions = missions;
                    if (this.flightLog.missionId != null) {
                        this.selectedMission = this.candidateMissions.find(
                            m => m.id === this.flightLog.missionId
                        );
                        this.onMissionSelected(this.selectedMission);
                    }
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error analysing initial flight log: ${error.message}`
                    );
                    this.cancel.emit();
                }
            })
            .add(this.workTracker.createTracker());
    }

    private findPilots() {
        forkJoin([
            this.personService.findPersonnel(this.organisationId),
            this.missionRoleService.findMissionRoles(this.organisationId)
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([persons, roles]) => {
                    this.pilots = persons.filter(p =>
                        p.roles.find(r => r === 'PILOT')
                    );
                    this.rpicRoleId =
                        roles != null
                            ? roles.find(
                                  r =>
                                      r.coreRole ===
                                      MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                              ).id
                            : null;
                    this.pilotRoleId =
                        roles != null
                            ? roles.find(
                                  r =>
                                      r.coreRole ===
                                      MissionRoleDto.CoreRole.PILOT
                              ).id
                            : null;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error loading available pilots: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    private findCompatibleLogs() {
        const maxDateRange = moment(this.flightDate).add(8, 'hours');
        const minDateRange = moment(this.flightDate).subtract(8, 'hours');
        const compatable = this.flightLogs.filter(
            log =>
                log.summary != null &&
                log.summary.startTime != null &&
                log.id !== this.flightLog.id &&
                log.missionId == null &&
                moment(log.summary?.startTime).isBetween(
                    minDateRange,
                    maxDateRange
                )
        );
        // show only logs within +- 8 hours & auto select those closer than 2km.
        const initialLog: DisplayableCompatibleLog[] = this.flightLogs
            .filter(l => l.id === this.flightLog.id)
            .map(l => ({
                collectedBy: l.collectedBy,
                id: this.flightLog.id,
                rpaDifference: ORIGINAL_RPA,
                takeoffDifference: 0,
                timeDifference: 'Original Log',
                selected: true,
                locked: true
            }));
        this.compatibleLogs = initialLog.concat(
            compatable.map(log => ({
                collectedBy: log.collectedBy,
                id: log.id,
                rpaDifference: this.findRpaDifference(log),
                takeoffDifference: this.findTakeoffDifference(log),
                timeDifference: this.findTimeDifference(log),
                // Auto select flights closer than 100m
                selected: this.findTakeoffDifference(log) < 0.1,
                locked: false
            }))
        );

        this.configuration.rows = this.compatibleLogs.length;
        this.configurationSecondTable.rows = this.compatibleLogs.length;
        this.configuration = { ...this.configuration };
        this.configurationSecondTable = { ...this.configurationSecondTable };
    }

    findMissionOptions() {
        forkJoin([
            this.missionService.findMissionTypes(this.organisationId),
            this.missionWorkflowService.findActiveWorkflows(
                this.organisationId,
                <string>this.flightDate
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([types, workflows]) => {
                    this.operationTypes = types;
                    this.availableWorkflows = workflows;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error loading mission options: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    findTimeDifference(log: FlightLogWithDuration) {
        const minuteDifference = moment(log.summary.startTime).diff(
            this.flightDate,
            'minutes'
        );

        const hours =
            minuteDifference < 0
                ? Math.ceil(minuteDifference / 60) * -1
                : Math.floor(minuteDifference / 60);
        const minutes =
            minuteDifference < 0
                ? (minuteDifference - hours * 60) * -1
                : minuteDifference - hours * 60;
        const hourText = `${
            hours !== 0 && hours !== -1 && hours !== 1
                ? `${hours} hrs`
                : hours === -1 || hours === 1
                ? `${hours} hr`
                : ''
        }`;
        const minuteText = `${
            hours === 0 || minutes !== 0
                ? minutes === -1 || minutes === 1
                    ? `${minutes} min`
                    : `${minutes} mins`
                : ''
        }`;

        return `${
            hours === 0 && minutes === 0 ? '' : minuteDifference < 0 ? '-' : '+'
        }${hourText}${hours !== 0 && minutes !== 0 ? ' ' : ''}${minuteText}`;
    }

    findTakeoffDifference(log: FlightLogWithDuration) {
        const startingLong = log.summary?.startLongitude;
        const startingLat = log.summary?.startLatitude;
        if (startingLat == null || startingLong == null) {
            return null;
        }
        const center = [startingLong, startingLat];
        const originalLog = this.flightLogs.find(
            l => l.id === this.flightLog.id
        );
        if (originalLog == null) {
            return;
        }
        const originalStartingLong = originalLog.summary?.startLongitude;
        const originalStartingLat = originalLog.summary?.startLatitude;
        if (originalStartingLong == null || originalStartingLat == null) {
            return null;
        }
        const origin = [originalStartingLong, originalStartingLat];
        const takeoffDistance = distance(origin, center, {
            units: 'kilometers'
        });
        if (takeoffDistance == null) {
            return null;
        }
        return takeoffDistance;
    }

    parseTakeOffDifference(log: DisplayableCompatibleLog) {
        const difference = log.takeoffDifference;
        if (difference == null) {
            return 'No Location Data';
        }
        if (difference < 1) {
            const decimal = +(
                Math.round((difference * 1000 + Number.EPSILON) * 100) / 100
            );
            return `${decimal}m`;
        }
        const km = +(Math.round((difference + Number.EPSILON) * 100) / 100);
        return `${km}km`;
    }

    findRpaDifference(log: FlightLogWithDuration) {
        if (
            (log.rpaId != null && log.rpaId === this.flightLog.rpaId) ||
            (log.rpaId == null &&
                log.summary != null &&
                log.summary.rpaSerialNumber ===
                    this.flightLog?.summary?.rpaSerialNumber)
        ) {
            return ORIGINAL_RPA;
        }
        return log.summary?.rpaName;
    }

    eventEmitted($event: { event: string; value: any }) {
        switch ($event.event) {
            case 'onSelectAll':
                this.selectAll = !this.selectAll;
                if (this.selectAll) {
                    this.compatibleLogs.map(log => (log.selected = true));
                } else {
                    // Don't deselect the original log
                    this.compatibleLogs.map((log, i) =>
                        i !== 0 ? (log.selected = false) : (log.selected = true)
                    );
                }
                break;
        }
    }

    onMissionSelected(mission: MissionSummaryDto) {
        this.candidateFlights = [];
        if (mission == null) {
            return;
        }

        this.missionService
            .findMission(mission.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: fullMission => {
                    this.candidateFlights = fullMission.sorties;
                    if (this.candidateFlights == null) {
                        this.candidateFlights = [];
                    }
                    this.candidateFlights.push({
                        number: -1,
                        id: -1,
                        status: 'COMPLETED',
                        durationSource: 'TIMER'
                    });
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error loading selected mission: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    onFlightSelected(flight: SortieDto) {
        if (flight.craftId == null) {
            return;
        }

        this.craftService
            .findById(flight.craftId, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: rpa => {
                    this.flightRpa = rpa;
                    this.rpaLinkingStatus = calculateRpaLinkingStatus(
                        this.rpaSerialNumber,
                        rpa.internalSerialNumber
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error loading RPA for selected flight: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    assignFlightLog() {
        const selectedLogs = this.compatibleLogs.filter(log => log.selected);
        const logsThatCreateFlights = selectedLogs.filter(
            log => log.selectedFlightId != null && log.selectedFlightId === -1
        );
        const assignableLogs: {
            logId: number;
            flightId?: number;
        }[] = selectedLogs
            .filter(l => l.selectedFlightId !== -1)
            .map(log => ({
                logId: log.id,
                flightId: log.selectedFlightId
            }));
        // First create flights as needed
        if (logsThatCreateFlights.length > 0) {
            this.flightLogsService
                .createFlightsFromLogs({
                    missionId: this.selectedMission.id,
                    flightLogIdList: logsThatCreateFlights.map(log => log.id)
                })
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: results => {
                        results.forEach(r => {
                            // Update flight details to prevent more flights being created if the next log fails
                            this.candidateFlights.push(r.flight);
                            this.compatibleLogs.find(
                                l => l.id === r.flightLogFile.id
                            ).selectedFlightId = r.flight?.id;
                        });

                        this.assignLogsToMission(assignableLogs);
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error creating flights from logs: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            this.assignLogsToMission(assignableLogs);
        }
    }

    assignLogsToMission(
        assignableLogs: {
            logId: number;
            flightId?: number;
        }[]
    ) {
        // assign all logs as required
        combineLatest(
            assignableLogs.map(details =>
                this.flightLogsService
                    .assignFlightLog(details.logId, {
                        missionId: this.selectedMission.id,
                        flightId: details.flightId
                    })
                    .pipe(
                        tap(log => {
                            if (log.flight != null) {
                                // remove successful logs from the list of assignable logs and update all variables
                                // This will prevent excessive duplicates and re-assigning logs already assigned if any in the list fail
                                assignableLogs.filter(
                                    l => l.logId !== log.flightLogFile.id
                                );
                                const tableLog = this.compatibleLogs.find(
                                    l => l.id === log.flightLogFile.id
                                );
                                tableLog.selectedFlightId = log.flight.id;
                                tableLog.selected = false;
                            }
                        })
                    )
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: updatedFlightLogs => {
                    this.logging.success('Flight assigned');
                    this.done.emit();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error assigning flights: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    assignRpa() {
        this.craftService
            .updateInternalSerialNumber(this.flightRpa.id, {
                internalSerialNumber: this.rpaSerialNumber,
                managingOrganisationId: this.flightLog.organisationId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success(
                        'RPA has been associated with the flight log'
                    );
                    this.rpaLinkingStatus = RpaLinkingStatus.MATCH;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while assigning RPA: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    createRetrospectiveMission() {
        if (this.flightLog.rpaId == null) {
            // Don't create a retrospective mission if the created sortie is going to fail
            this.logging.warn(
                'The primary flight log on this mission is missing a valid RPA. Please update the flight log data from the previous screen and try again.'
            );
            return;
        }
        // reset crew on save to prevent duplicate crew members
        this.crew = [];
        if (this.rpicRoleId != null && this.retrospectiveMissionPilot != null) {
            this.crew.push({
                missionRoleId: this.rpicRoleId,
                personId: this.retrospectiveMissionPilot.id
            });
        }
        if (this.pilotRoleId != null) {
            if (this.rpicRoleId != null && this.flightLog.pilot != null) {
                this.crew.push({
                    missionRoleId: this.pilotRoleId,
                    personId: this.flightLog.pilot.id
                });
            } else if (
                this.rpicRoleId == null &&
                this.retrospectiveMissionPilot != null
            ) {
                this.crew.push({
                    missionRoleId: this.pilotRoleId,
                    personId: this.retrospectiveMissionPilot.id
                });
            }
        }
        const command: CreateHistoricalMissionCommand = {
            craftIds:
                this.flightLog.rpaId != null ? [this.flightLog.rpaId] : [],
            equipmentIds: [],
            missionCrew: this.crew ?? [],
            organisationId: this.organisationId,
            name: this.retrospectiveMissionName,
            missionDate: this.flightDate,
            missionTypeId: this.retrospectiveOperationTypeId,
            missionWorkflowVersionId: this.retrospectiveMissionWorkflowId,
            completionMessage: null,
            outcome: CreateHistoricalMissionCommand.Outcome.COMPLETED,
            sorties: []
        };
        this.missionService
            .createHistoricalMission(command)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(mission =>
                    this.flightLogsService
                        .createFlightsFromLogs({
                            missionId: mission.id,
                            pilotId: this.retrospectiveMissionPilot?.id,
                            // This will include the default log
                            flightLogIdList: this.compatibleLogs
                                .filter(l => l.selected)
                                .map(l => l.id)
                        })
                        .pipe(map(results => [mission, results]))
                )
            )
            .subscribe({
                next: ([mission, results]) => {
                    this.selectedMission = <DisplayableMissionDto>mission;
                    this.logging.success('Mission created');
                    this.done.emit();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error creating retrospective mission: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    sortFlights(a: DisplayableCompatibleLog, b: DisplayableCompatibleLog) {
        const logTimeA = moment(
            this.flightLogs.find(log => log.id === a.id).summary.startTime
        );
        const logTimeB = moment(
            this.flightLogs.find(log => log.id === b.id).summary.startTime
        );
        if (moment(logTimeA).isBefore(logTimeB)) {
            return 1;
        } else if (moment(logTimeA).isAfter(logTimeB)) {
            return -1;
        } else {
            return 0;
        }
    }
}
