import { Injectable } from '@angular/core';
import {
    ManualFlightSummary,
    RpaModelSummary
} from './detailed-flight-history/interfaces';
import {
    NameValue,
    PersonDto,
    RpaTypeDto
} from '@flyfreely-portal-ui/flyfreely';
import { FlightHistoryEdit } from './detailed-flight-history/flight-history-edit.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';

@Injectable()
export class FlightHistoryDialoguesService {
    constructor(private modalService: BsModalService) {}

    showAddFlightHistoryEdit(
        currentlyEditing: ManualFlightSummary,
        rpaTypeList: RpaModelSummary[],
        rpaCategoryList: NameValue[],
        timeTypes: NameValue[],
        sightTypes: NameValue[],
        currentUser: PersonDto,
        currentlyEditingId: number,
        managingOrganisationId: number
    ) {
        return this.modalService.show(FlightHistoryEdit, {
            ...MODAL_OPTIONS,
            class: 'modal-task modal-md',
            initialState: {
                currentlyEditing,
                rpaTypeList,
                rpaCategoryList,
                timeTypes,
                sightTypes,
                currentUser,
                currentlyEditingId,
                managingOrganisationId
            }
        });
    }
}
