<ng-container screenAnalytics="more-features-dialogue">
    <div class="modal-header modal-upsell no-icon">
        <h4 class="modal-title">More Features</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body max-height">
        <div class="modal-body-content">
            <div class="container-scroll">
                <div
                    *ngIf="moreFeatures != null"
                    [innerHtml]="moreFeatures"
                ></div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-default pull-left"
            (click)="contactSales()"
            [disabled]="working || salesContacted"
        >
            Contact Sales
        </button>
        <button
            *ngIf="isPersonalOrg"
            type="button"
            class="btn btn-golden relative"
            (click)="createCommercialOrganisation()"
            angulartics2On="click"
            angularticsCategory="account"
            angularticsAction="create-commercial-start"
            [disabled]="working || !canCreateOrganisation"
        >
            Create Organisation
            <span
                class="overlay"
                [tooltip]="createOrganisationTooltip"
                placement="top"
            ></span>
        </button>
        <button
            *ngIf="!isPersonalOrg"
            type="button"
            class="btn btn-golden relative"
            tooltip="Upgrade your current subscription to a higher tier"
            placement="top"
            analyticsOn="click"
            analyticsAction="upgrade"
            analyticsCategory="upsell"
            analyticsLabel="more-features"
            (click)="upgrade()"
            [disabled]="working"
        >
            Upgrade My Plan
        </button>
    </div>
</ng-container>
