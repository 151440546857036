<enhanced-help componentName="organisation-overview" [compact]="true">
</enhanced-help>
<div class="data">
    <span class="display-label">Name </span>
    <editable
        [initialContext]="{ name: organisation.name }"
        [confirmationButtons]="true"
        [updateFn]="updateName"
        [disabled]="working"
    >
        <ng-template viewMode>
            {{ organisation.name }}
        </ng-template>
        <ng-template editMode let-context="context">
            <input
                type="text"
                [(ngModel)]="context.name"
                class="form-control"
                editableOnEnter
            />
        </ng-template>
    </editable>
</div>
<div *ngIf="owner != null" class="data">
    <span class="display-label">Owner </span> {{ owner | formatPerson }}
    <span class="glyphicon glyphicon-earphone"></span> {{ owner.phoneNumber }}
</div>
<div class="data">
    <span class="display-label">Time Zone </span>
    <editable
        [initialContext]="{ timeZone: organisation.timeZone }"
        [confirmationButtons]="true"
        [updateFn]="updateTimeZone"
        [disabled]="working"
    >
        <ng-template viewMode>
            {{ organisation.timeZone }}
        </ng-template>
        <ng-template editMode let-context="context">
            <ng-select
                style="min-width: 300px"
                [items]="timeZones"
                [(ngModel)]="context.timeZone"
                [clearable]="false"
            ></ng-select>
        </ng-template>
        <ng-template editHelp
            ><div class="alert alert-warning top-buffer">
                Warning: Changing the time zone will affect when expiry times
                occur.
            </div></ng-template
        >
    </editable>
</div>
<enhanced-help componentName="organisation-time-zone" [compact]="true">
</enhanced-help>
<address-list
    *ngIf="organisation != null"
    [organisationId]="organisation.id"
></address-list>
<enhanced-help componentName="organisation-addresses" [compact]="true">
</enhanced-help>
