import { Injectable } from '@angular/core';
import {
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    OrganisationService,
    PersonnelRegisterEntryExpiryDto,
    PersonnelRegisterExpirySummary,
    PersonsOrganisationDto,
    RpaRegisterEntryExpiryDto,
    RpaRegisterExpirySummary,
    SharedAuthorityGroup,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import moment from 'moment';
import {
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    forkJoin
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorityGroupEditService } from '../registers/interfaces';

export interface RegisterExpirySummary {
    authorityName: string;
    registerName: string;
    registerId: number;
    authorityId: number;
    overallStatus: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
    registerMode: AuthorityRegisterSummaryDto.RegisterMode;
    registerEntries: (
        | PersonnelRegisterEntryExpiryDto
        | RpaRegisterEntryExpiryDto
    )[];
}

export interface AuthorityExpirySummary {
    authorityName: string;
    authorityGroupId: number;
    authorityId: number;
    overallStatus: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
}

export interface AuthorityActionPayload {
    authorityType: AuthorityTypeDto | OrganisationAuthorityGroup;
    // FIXME: this type is actually OrganisationAuthorityDto | CraftAuthorityDto, but causes errors when typed
    authority: any;
    register?: AuthorityRegisterSummaryDto;
}

/**
 * A function that takes a list of register expiry summaries and returns a lookup where
 * the key is the register ID and the value is the most pressing expiry from both the register itself and the check expiry list
 * @param expiry an array of register expiry summaries
 * @returns a lookup where the key is the register ID and the value is the most pressing expiry status
 */
export function toRegisterExpiriesStatusLookup(
    expiry: (PersonnelRegisterExpirySummary | RpaRegisterExpirySummary)[]
): { [registerId: number]: PersonnelRegisterEntryExpiryDto.ExpiryStatus } {
    const keys = expiry.map(e => e.id);
    const findMostPressing = (
        entries: (PersonnelRegisterEntryExpiryDto | RpaRegisterEntryExpiryDto)[]
    ) => {
        const expired = PersonnelRegisterEntryExpiryDto.ExpiryStatus.EXPIRED;
        const upcoming = PersonnelRegisterEntryExpiryDto.ExpiryStatus.UPCOMING;
        const ok = PersonnelRegisterEntryExpiryDto.ExpiryStatus.OK;
        return entries.reduce((acc, entry) => {
            const registerStatus = entry.expiryStatus;
            const mostUrgentCheck = entry.checkExpiryList.reduce(
                (acc2, check) =>
                    check.expiryStatus === expired
                        ? (acc2 = expired)
                        : check.expiryStatus === upcoming && acc2 !== expired
                        ? (acc2 = upcoming)
                        : acc2,
                ok
            );
            if (registerStatus === expired || mostUrgentCheck === expired) {
                return (acc = expired);
            } else if (
                registerStatus === upcoming ||
                (mostUrgentCheck === upcoming && acc !== expired)
            ) {
                return (acc = upcoming);
            } else {
                return acc;
            }
        }, ok);
    };
    return keys.reduce(
        (acc, k) => ({
            ...acc,
            [k]: findMostPressing(expiry.find(e => e.id === k).registerEntries)
        }),
        {}
    );
}

@Injectable()
export class OrganisationAuthorityEditService extends AuthorityGroupEditService {
    private organisationSource = new ReplaySubject<PersonsOrganisationDto>(1);
    private authoritiesSource = new ReplaySubject<OrganisationAuthorityGroup[]>(
        1
    );
    private sharedAuthoritiesSource = new ReplaySubject<SharedAuthorityGroup[]>(
        1
    );
    private registryExpirySource = new ReplaySubject<
        (PersonnelRegisterExpirySummary | RpaRegisterExpirySummary)[]
    >(1);
    private registerExpiryLookupsSource = new ReplaySubject<{
        [registerId: number]: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
    }>(1);
    private workingSource = new ReplaySubject<boolean>(1);
    private authoritiesWithRegisters$ = new Subject<
        OrganisationAuthorityGroup[]
    >();
    organisation$ = this.organisationSource.asObservable();
    authorities$ = this.authoritiesSource.asObservable();
    sharedAuthorities$ = this.sharedAuthoritiesSource.asObservable();
    registerExpiries$ = this.registryExpirySource.asObservable();
    registerExpiryLookups$ = this.registerExpiryLookupsSource.asObservable();
    working$ = this.workingSource.asObservable();

    private organisation: PersonsOrganisationDto;
    private managingOrganisationId: number;
    private authorities: OrganisationAuthorityGroup[];
    private sharedAuthorities: SharedAuthorityGroup[];

    private working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private organisationService: OrganisationService,
        private logging: FlyFreelyLoggingService
    ) {
        super();
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.workingSource.next(working);
                this.working = working;
            });
        combineLatest([this.organisation$, this.authoritiesWithRegisters$])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([organisation, authorities]) => {
                if (authorities?.length > 0 && organisation != null) {
                    this.refreshRegisterExpiries(authorities);
                }
            });
        this.organisationAuthorityService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                if (
                    this.organisation != null &&
                    this.managingOrganisationId != null
                ) {
                    this.refreshOwnedAuthorities();
                }
            });

        this.organisation$.subscribe(() => {
            this.refreshAllAuthorities();
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.organisationSource.complete();
        this.authoritiesSource.complete();
        this.sharedAuthoritiesSource.complete();
        this.registryExpirySource.complete();
        this.registerExpiryLookupsSource.complete();
        this.workingSource.complete();
    }

    refreshOwnedAuthorities() {
        this.organisationAuthorityService
            .findAuthorities(this.organisation.id, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(authorities => {
                this.authorities = authorities;
                this.authoritiesSource.next(authorities);
                const withRegisters = authorities.filter(
                    a =>
                        a.authorities?.length > 0 &&
                        a.authorities?.filter(
                            auth => auth?.registers.length > 0
                        ).length > 0
                );
                this.authoritiesWithRegisters$.next(withRegisters);
            })
            .add(this.workTracker.createTracker());
    }

    refreshAllAuthorities() {
        this.refreshOwnedAuthorities();
        this.refreshSharedAuthorities();
    }

    refreshSharedAuthorities() {
        if (this.organisation.personalOrganisation) {
            return;
        }

        const today = moment().format('YYYY-MM-DD');

        this.organisationAuthorityService
            .findSharedAuthorities(this.organisation.id, today)

            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(sharedAuthorities => {
                this.sharedAuthorities = sharedAuthorities;
                this.sharedAuthoritiesSource.next(sharedAuthorities);
            })
            .add(this.workTracker.createTracker());
    }

    setup(entityId: number, managingOrganisationId: number) {
        this.managingOrganisationId = managingOrganisationId;
        this.organisationService
            .findByIdForUser(entityId, managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation;
                this.organisationSource.next(organisation);
            })
            .add(this.workTracker.createTracker());
    }

    refreshRegisterExpiries(authorityGroups: OrganisationAuthorityGroup[]) {
        // first extract all authorities that have registers from the authority groups
        const authorities: OrganisationAuthorityDto[] = authorityGroups.reduce(
            (acc, group) =>
                acc.concat(
                    group.authorities.filter(a => a?.registers.length > 0)
                ),
            []
        );

        forkJoin(
            authorities.reduce(
                (
                    acc: Observable<
                        | PersonnelRegisterExpirySummary
                        | RpaRegisterExpirySummary
                    >[],
                    auth
                ) =>
                    acc.concat(
                        auth.registers.map(r =>
                            r.registerEntityType ===
                            AuthorityRegisterSummaryDto.RegisterEntityType.PILOT
                                ? this.organisationAuthorityService.personnelRegisterExpiries(
                                      auth.id,
                                      r.id,
                                      this.managingOrganisationId
                                  )
                                : this.organisationAuthorityService.aircraftRegisterExpiries(
                                      auth.id,
                                      r.id,
                                      this.managingOrganisationId
                                  )
                        )
                    ),
                []
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                expiries => {
                    this.registryExpirySource.next(expiries);
                    this.registerExpiryLookupsSource.next(
                        toRegisterExpiriesStatusLookup(expiries)
                    );
                },
                (error: FlyFreelyError) => this.logging.error(error)
            )
            .add(this.workTracker.createTracker());
    }
}
