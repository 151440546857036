import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { emailValidator } from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormIdentifierDirective } from './form-identifier.directive';

@Directive({
    selector: 'form[formIdentifier]'
})
export class FormValidationDirective {
    @Input() formGroup: FormGroup;
    validators: any;

    constructor(
        private formIdentifier: FormIdentifierDirective,
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

    ngOnInit() {
        Object.keys(this.formGroup.controls).forEach(key => {
            const childControl = this.formGroup.get(key);
            const name = key.toString().toLowerCase();
            const field = this.formIdentifier.findField(name);

            // elField returns the DOM object so HTML limits and validators can be set (eg. hard limit an input to 50 characters)
            const elField = this.el.nativeElement.querySelector(
                `[formControlName="${key}"]`
            );
            if (elField == null) {
                return;
            } else if (field == null && elField != null) {
                // Set defaults
                if (elField.tagName === 'INPUT') {
                    if (elField.type === 'text') {
                        this.renderer.setAttribute(elField, 'maxLength', '255');
                    }
                }
            } else {
                childControl.setValidators([
                    Validators.compose([
                        childControl.validator,
                        ...this.findValidators(field, elField)
                    ])
                ]);
                childControl.updateValueAndValidity();
            }
        });
    }

    findValidators(formlyField: FormlyFieldConfig, elField: any) {
        // Setting FormControl validators allows validation on copy-paste or similar HTML limit overrides.
        this.validators = [];

        if (formlyField.props.required) {
            this.validators.push(Validators.required);
        }
        if (formlyField.props.maxLength) {
            this.validators.push(
                Validators.maxLength(formlyField.props.maxLength)
            );
            this.renderer.setAttribute(
                elField,
                'maxLength',
                formlyField.props.maxLength.toString()
            );
        }
        if (formlyField.props.minLength) {
            this.validators.push(
                Validators.minLength(formlyField.props.minLength)
            );
            this.renderer.setAttribute(
                elField,
                'minLength',
                formlyField.props.minLength.toString()
            );
        }
        if (formlyField.props.max) {
            this.validators.push(Validators.max(formlyField.props.max));
            this.renderer.setAttribute(
                elField,
                'max',
                formlyField.props.max.toString()
            );
        }
        if (formlyField.props.min) {
            this.validators.push(Validators.min(formlyField.props.min));
            this.renderer.setAttribute(
                elField,
                'min',
                formlyField.props.min.toString()
            );
        }
        if (formlyField.props.pattern) {
            this.validators.push(Validators.pattern(formlyField.props.pattern));
        }
        if (formlyField.props?.type === 'email') {
            this.validators.push(emailValidator);
        }

        return this.validators;
    }
}
