import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    AssignFlightLogCollectionSourceCommand,
    AutomaticMissionConfigurationDto,
    CreateApiFlightLogCollectionSourceCommand,
    CreateFlightLogCollectionSourceCommand,
    FlightLogCollectionAlertDto,
    FlightLogCollectionSourceDto,
    FlightLogCollectionSourceWithApiKeyDto,
    OrganisationAutomaticMissionConfigurationDto,
    ResultDto,
    UpdateAutomaticMissionConfigurationCommand,
    UpdateFlightLogCollectionAlertCommand,
    UpdateFlightLogCollectionSourceCommand,
    UpdateOrganisationAutomaticMissionConfigurationCommand
} from '../model/api';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class FlightLogCollectionService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<FlightLogCollectionSourceDto[]>(
            `${this.baseUrl}/webapi/flightLogs/collectionSources`,
            {
                params: httpParamSerializer({
                    organisationId
                })
            }
        );
    }

    create(command: CreateFlightLogCollectionSourceCommand) {
        return this.http
            .post<FlightLogCollectionSourceDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    createApiSource(command: CreateApiFlightLogCollectionSourceCommand) {
        return this.http
            .post<FlightLogCollectionSourceWithApiKeyDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/api`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateSource(command: UpdateFlightLogCollectionSourceCommand, id: number) {
        return this.http
            .put<FlightLogCollectionSourceDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/${id}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteSource(id: number) {
        return this.http
            .delete<void>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/${id}`
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    collectNow(id: number) {
        return this.http
            .put<FlightLogCollectionSourceDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/${id}/collectNow`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateAlertSettings(
        id: number,
        command: UpdateFlightLogCollectionAlertCommand
    ) {
        return this.http
            .put<FlightLogCollectionAlertDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/${id}/alerts`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    assignFlightLogCollectionSource(
        id: number,
        command: AssignFlightLogCollectionSourceCommand
    ) {
        return this.http
            .put<FlightLogCollectionAlertDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/${id}/assign`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findAutomaticMissionConfiguration(collectionSourceId: number) {
        return this.http.get<AutomaticMissionConfigurationDto>(
            `${this.baseUrl}/webapi/flightLogs/collectionSources/${collectionSourceId}/automaticMissionConfiguration`
        );
    }

    updateAutomaticMissionConfiguration(
        collectionSourceId: number,
        command: UpdateAutomaticMissionConfigurationCommand
    ) {
        return this.http
            .put<AutomaticMissionConfigurationDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/${collectionSourceId}/automaticMissionConfiguration`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findOrganisationAutomaticMissionConfiguration(organisationId: number) {
        return this.http.get<OrganisationAutomaticMissionConfigurationDto>(
            `${this.baseUrl}/webapi/flightLogs/collectionSources/automaticMissionConfiguration`,
            {
                params: httpParamSerializer({
                    organisationId
                })
            }
        );
    }

    updateOrganisationAutomaticMissionConfiguration(
        command: UpdateOrganisationAutomaticMissionConfigurationCommand
    ) {
        return this.http
            .put<OrganisationAutomaticMissionConfigurationDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/automaticMissionConfiguration`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    applyAutomaticMissionConfiguration(collectionSourceId: number) {
        return this.http
            .post<ResultDto>(
                `${this.baseUrl}/webapi/flightLogs/collectionSources/${collectionSourceId}/automaticMissionConfiguration/collect`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
