import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    fromTimestamp,
    MaintenanceActivityDto,
    MaintenanceFlightTestDto,
    MissionService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import {
    FormatMissionStatusPipe,
    FormatBooleanPipe,
    FormatDateTimePipe
} from '@flyfreely-portal-ui/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'test-flight-details',
    templateUrl: './test-flight-details.component.html'
})
export class TestFlightDetailsComponent {
    @Input() activity: MaintenanceActivityDto;
    @Input() form: FormGroup;
    @Input() isMaintenanceController: boolean;
    @Input() overallStatus: string;
    @Input() noOutstandingFlightTest: boolean;
    @Input() organisation: PersonsOrganisationDto;
    @Input() workTracker: WorkTracker;

    @Output() testFlightRequested = new EventEmitter<void>();
    @Output() linkTestFlight = new EventEmitter<void>();
    @Output() unlinkTestFlight = new EventEmitter<number>();

    testFlights: MaintenanceFlightTestDto[];
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;

    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionService: MissionService,
        private formatStatusPipe: FormatMissionStatusPipe,
        private formatBoolean: FormatBooleanPipe,
        private missionDialoguesService: MissionDialoguesService,
        formatDateTimePipe: FormatDateTimePipe
    ) {
        this.availableColumns = [
            {
                name: 'Flight Status',
                key: 'flightTestMissionStatus',
                defaultSelection: true,
                selectable: false,
                searchable: false,
                formatterFunction: s => this.formatStatusPipe.transform(s),
                linkFunction: (f: MaintenanceFlightTestDto) =>
                    this.showTestMission(f)
            },
            {
                name: 'Flight Date',
                key: 'flightTestMissionDate',
                defaultSelection: true,
                selectable: false,
                searchable: false,
                formatterFunction: (t, r) =>
                    formatDateTimePipe.transform(fromTimestamp(t, r.timeZone))
            },
            {
                name: 'Requires Further Maintenance?',
                key: 'furtherMaintenanceRequired',
                defaultSelection: true,
                selectable: false,
                searchable: false,
                formatterFunction: r => this.formatBoolean.transform(r)
            },
            {
                name: 'Reason for Flight Test',
                key: 'flightTestMissionObjective',
                defaultSelection: true,
                selectable: false,
                searchable: false
            },
            {
                name: 'Test Performed',
                key: 'flightTestPerformed',
                defaultSelection: true,
                selectable: false,
                searchable: false
            },
            {
                name: 'Remarks',
                key: 'flightTestRemarks',
                defaultSelection: true,
                selectable: false,
                searchable: false
            }
        ];
        this.selectedColumns = [];
        this.tableConfig = {
            actions: [
                {
                    tooltip: 'Unlink Test Flight',
                    icon: 'fal fa-unlink',
                    action: (item: MaintenanceFlightTestDto) =>
                        this.unlinkTestFlight.emit(item.flightTestMissionId)
                }
            ],
            limit: null
        };
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.testFlights = this.activity.flightTests;
        this.resetForm();
        this.missionService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.resetForm();
            });
    }

    resetForm() {
        if (this.testFlights.length > 0) {
            this.form.controls.flightTestGroup.patchValue({
                flightTestRequired: true
            });
        }
    }

    showLinkTestFlight() {
        this.linkTestFlight.emit();
    }

    showCreateTestFlight() {
        this.testFlightRequested.emit();
    }

    showTestMission(testFlight: MaintenanceFlightTestDto) {
        this.missionDialoguesService.showMissionDetails(
            testFlight.flightTestMissionId,
            true,
            this.organisation
        );
    }

    get flightTestGroup() {
        return this.form.get('flightTestGroup') as FormGroup;
    }
}
