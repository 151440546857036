import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    FEATURE_API_KEYS,
    FEATURE_APPROVAL_CONDITIONS_LIBRARY,
    FEATURE_BILLING,
    FEATURE_FLIGHT_CONFORMANCE,
    FEATURE_MISSION_OBJECTIVES,
    FEATURE_ORGANISATION_RELATIONSHIPS,
    FEATURE_REFERRALS,
    FEATURE_RPA_WORKFLOW,
    FEATURE_UPSELL_PLANS_OVERVIEW,
    LookupObject,
    PersonsOrganisationDto,
    WorkTracker,
    hasAnyPermission,
    hasFeatureFlag,
    FEATURE_WORKGROUPS,
    ExclusiveControlService
} from '@flyfreely-portal-ui/flyfreely';
import {
    OrganisationSubscriptionsService,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { FeaturesDialoguesService } from 'libs/features/src/lib/features-dialogues.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'organisation-admin-dialogue',
    templateUrl: './organisation-admin-dialogue.component.html',
    providers: [ExclusiveControlService]
})
export class OrganisationAdminDialogue implements OnInit, OnDestroy {
    organisation: PersonsOrganisationDto;
    organisationId: number;

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working = false;

    canUseObjectives: boolean;
    canViewBilling: boolean;
    canUseSharedAuthorities: boolean;
    canUseApprovalConditionsLibrary: boolean;
    canUseReferrals: boolean;
    canUseApiKeys: boolean;
    canUseFlightConformance: boolean;
    canManageFlightConfiguration: boolean;
    showMoreFeatures = false;
    canUseWorkgroups = false;

    featureFlags: LookupObject<string> = {};

    hasRpaWorkflow: boolean;

    locked = false;

    constructor(
        private modal: BsModalRef<OrganisationAdminDialogue>,
        private workspaceStateService: WorkspaceStateService,
        private organisationSubscriptionService: OrganisationSubscriptionsService,
        private featuresDialoguesService: FeaturesDialoguesService,
        protected exclusiveControlService: ExclusiveControlService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.exclusiveControlService.lock$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locked => {
                this.locked = locked;
            });
    }

    ngOnInit(): void {
        this.workspaceStateService.currentOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(event => {
                if (event.type === 'organisation_loaded') {
                    this.organisation = event.organisation;
                    this.refreshPermissions();
                } else {
                    this.organisation = null;
                    this.canUseObjectives = false;
                    this.canViewBilling = false;
                    this.canUseSharedAuthorities = false;
                    this.canUseApprovalConditionsLibrary = false;
                    this.canUseReferrals = false;
                    this.canUseApiKeys = false;
                    this.hasRpaWorkflow = false;
                    this.canUseFlightConformance = false;
                    this.canManageFlightConfiguration = false;
                    this.canUseWorkgroups = false;
                }
            });
        this.organisationSubscriptionService.currentSubscriptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(subscription => {
                this.showMoreFeatures =
                    (subscription?.type === 'no_subscriptions' ||
                        subscription?.type === 'personal_organisation') &&
                    hasFeatureFlag(
                        this.organisation,
                        FEATURE_UPSELL_PLANS_OVERVIEW
                    );
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        this.canUseObjectives = hasFeatureFlag(
            this.organisation,
            FEATURE_MISSION_OBJECTIVES
        );
        this.featureFlags['canUseObjectives'] = FEATURE_MISSION_OBJECTIVES;
        this.canUseSharedAuthorities = hasFeatureFlag(
            this.organisation,
            FEATURE_ORGANISATION_RELATIONSHIPS
        );
        this.featureFlags[
            'canUseSharedAuthorities'
        ] = FEATURE_ORGANISATION_RELATIONSHIPS;
        this.canUseApprovalConditionsLibrary = hasFeatureFlag(
            this.organisation,
            FEATURE_APPROVAL_CONDITIONS_LIBRARY
        );
        this.featureFlags[
            'canUseApprovalConditionsLibrary'
        ] = FEATURE_APPROVAL_CONDITIONS_LIBRARY;
        this.canUseReferrals = hasFeatureFlag(
            this.organisation,
            FEATURE_REFERRALS
        );
        this.featureFlags['canUseReferrals'] = FEATURE_REFERRALS;
        this.hasRpaWorkflow = hasFeatureFlag(
            this.organisation,
            FEATURE_RPA_WORKFLOW
        );
        this.featureFlags['hasRpaWorkflow'] = FEATURE_RPA_WORKFLOW;
        this.canUseApiKeys = hasFeatureFlag(
            this.organisation,
            FEATURE_API_KEYS
        );
        this.featureFlags['canUseApiKeys'] = FEATURE_API_KEYS;
        this.canUseFlightConformance = hasFeatureFlag(
            this.organisation,
            FEATURE_FLIGHT_CONFORMANCE
        );
        this.featureFlags[
            'canUseFlightConformance'
        ] = FEATURE_FLIGHT_CONFORMANCE;

        this.canUseWorkgroups = hasFeatureFlag(
            this.organisation,
            FEATURE_WORKGROUPS
        );

        this.canManageFlightConfiguration = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.FLIGHT_OPERATIONS_CONFIGURATION
        );

        this.canViewBilling =
            hasFeatureFlag(this.organisation, FEATURE_BILLING) &&
            hasAnyPermission(
                this.organisation,
                PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
            );
    }

    showMoreFeaturesDialogue() {
        const modal = this.featuresDialoguesService.showMoreFeatures(
            this.organisation.id,
            this.organisation.personalOrganisation
        );
    }

    close() {
        this.modal.hide();
    }
}
