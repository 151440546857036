import { Injectable } from '@angular/core';
import {
    LocationDetailsDto,
    LocationDto,
    LocationService,
    PersonsOrganisationDto,
    SimpleLocationDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { CloneMissionLocationDialogue } from './clone-mission-location/clone-mission-location-dialogue.component';
import { LocationDetailsDialogue } from './location-details/location-details-dialogue.component';
import {
    LocationAirspaceParameters,
    LocationEditV2Dialogue
} from './location-edit-v2/location-edit-dialogue.component';

@Injectable()
export class LocationDialoguesService {
    constructor(
        private modalService: BsModalService,
        private locationService: LocationService
    ) {}

    newLocation(
        organisationId: number,
        type: LocationDetailsDto.Type | LocationDto.Type,
        canCreateMission: boolean,
        defaultValues: Partial<LocationDetailsDto> = null,
        missionValues?: LocationAirspaceParameters
    ) {
        const modal = this.modalService.show(LocationEditV2Dialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                organisationId,
                type,
                defaultValues,
                missionValues,
                canCreateMission
            }
        });

        return modal;
    }

    cloneLocation(
        location: SimpleLocationDto,
        currentOrganisation: PersonsOrganisationDto
    ) {
        return this.locationService
            .findLocation(location.id)
            .pipe(take(1))
            .subscribe(loadedLocation =>
                this.newLocation(
                    currentOrganisation.id,
                    LocationDto.Type.TEMPLATE,
                    true,
                    loadedLocation
                )
            );
    }

    /**
     * Displays the location edit dialogue.
     *
     * @param location the location object to display
     * @returns the promise for the dialogue
     */
    editLocation(
        locationId: number,
        organisationId: number,
        canCreateMission: boolean,
        type?: LocationDto.Type,
        missionValues?: LocationAirspaceParameters
    ) {
        const modal = this.modalService.show(LocationEditV2Dialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                locationId: locationId,
                organisationId: organisationId,
                type,
                missionValues,
                canCreateMission
            }
        });

        return modal;
    }

    /**
     * Displays the location show dialogue.
     *
     * @param location the location object to display
     * @returns the promise for the dialgoue
     */
    showLocation(location: SimpleLocationDto, organisationId: number) {
        const modal = this.modalService.show(LocationDetailsDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                locationId: location.id,
                organisationId: organisationId
            }
        });

        return modal;
    }

    showMissionLocationCloneDialogue(organisationId: number) {
        return this.modalService.show(CloneMissionLocationDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: { organisationId }
        });
    }
}
