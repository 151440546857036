import { Component, Input } from '@angular/core';
import {
    FEATURE_SCHEDULED_MAINTENANCE,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameId,
    NameIdArchived,
    PersonsOrganisationDto,
    RPA_CATEGORIES,
    RpaTypeConfigDto,
    RpaTypeConfigService,
    RpaTypeDto,
    SimpleMaintenanceScheduleDto,
    SimpleWorkflowDto,
    UpdateRpaTypeConfigCommand,
    WorkTracker,
    hasFeatureFlag,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaTypePipe } from '@flyfreely-portal-ui/resource-ui';
import { fadeInExpandOnEnterAnimation } from 'angular-animations';
import deepmerge from 'deepmerge';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { rpaTypeSearch } from 'libs/flyfreely/src/lib/tools/searchFunctions';
import { compareRpaType } from 'libs/rpa/src/lib/helpers';
import { EMPTY, Subject, combineLatest, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import {
    NO_AUTHORITY_ID,
    ResourceMaintenanceDataService
} from '../resource-maintenance-data.service';

interface CustomisedRpaTypeLookup {
    [rpaModelId: number]: CustomisedRpaType;
}

interface CustomisedRpaType {
    name: string;
    model: RpaTypeDto;
    customisations: {
        [authorityId: number]: {
            workflowConfig: SimpleWorkflowDto;
            maintenanceSchedule: SimpleMaintenanceScheduleDto;
        };
    };
}

@Component({
    selector: 'rpa-type-customisation',
    templateUrl: './rpa-type-customisation.component.html',
    animations: [fadeInExpandOnEnterAnimation()],
    styles: [
        `
            .inner-tab-container {
                overflow-y: auto;
                max-height: calc(100vh - 242px);
                position: relative;
            }
        `
    ]
})
export class RpaTypeCustomisationComponent {
    @Input() organisation: PersonsOrganisationDto;

    allRpaTypes: RpaTypeDto[];
    availableRpaModels: RpaTypeDto[];
    customisedRpaModels: CustomisedRpaType[];
    rpaModelWorkflowConfigs: RpaTypeConfigDto[];

    rpaModelMaintenanceScheduleList: NameId[];
    rpaModelMaintenanceScheduleSelectionList: NameId[];

    workflowList: NameId[];
    workflowSelectionList: NameId[];

    authorities: NameIdArchived[] = [];

    rpaCategories = RPA_CATEGORIES;

    defaultConfigs: CustomisedRpaType;

    selectedRpaModel: RpaTypeDto;

    showArchived = false;

    hasScheduledMaintenance = false;

    public working: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    rpaSearchFn = rpaTypeSearch;

    constructor(
        private rpaTypeConfigService: RpaTypeConfigService,
        private resourceMaintenanceDataService: ResourceMaintenanceDataService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private formatRpaTypePipe: FormatRpaTypePipe,
        private workTracker: WorkTracker
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.setupData();

        this.hasScheduledMaintenance = hasFeatureFlag(
            this.organisation,
            FEATURE_SCHEDULED_MAINTENANCE
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupData() {
        const doneWorking = this.workTracker.createTracker();
        combineLatest([
            this.resourceMaintenanceDataService.rpaTypeList$,
            this.resourceMaintenanceDataService.rpaTypeConfigList$,
            this.resourceMaintenanceDataService.maintenanceScheduleList$.pipe(
                map(list =>
                    list.filter(
                        l =>
                            l.resourceCategory ===
                            SimpleMaintenanceScheduleDto.ResourceCategory.CRAFT
                    )
                )
            ),
            this.resourceMaintenanceDataService.craftModelWorkflowList$,
            this.resourceMaintenanceDataService.organisationAuthoritiesList$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    rpaTypeList,
                    rpaTypeConfigList,
                    maintenanceScheduleList,
                    craftModelWorkflowList,
                    authoritiesList
                ]) => {
                    this.allRpaTypes = [...rpaTypeList];
                    this.allRpaTypes.sort(compareRpaType);

                    this.rpaModelWorkflowConfigs = rpaTypeConfigList;

                    this.workflowList = craftModelWorkflowList;
                    this.workflowSelectionList = [
                        { id: null, name: 'Use default workflow' },
                        ...craftModelWorkflowList
                    ];

                    this.rpaModelMaintenanceScheduleList = maintenanceScheduleList;
                    this.rpaModelMaintenanceScheduleSelectionList = [
                        { id: null, name: 'Use default maintenance schedule' },
                        ...maintenanceScheduleList
                    ];
                    this.authorities = authoritiesList.filter(
                        auth => !auth?.archived
                    );
                    doneWorking();
                    this.refreshState();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while finding customisations: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }

    private refreshState() {
        if (this.rpaModelWorkflowConfigs == null || this.allRpaTypes == null) {
            return;
        }
        const modelLookup = this.allRpaTypes.reduce(toLookup, {});
        const workflowConfigLookup = this.rpaModelWorkflowConfigs.reduce(
            (acc: CustomisedRpaTypeLookup, w) => {
                const authId =
                    w.authorityId != null ? w.authorityId : NO_AUTHORITY_ID;
                return deepmerge(acc, {
                    [w.rpaTypeId || 0]: {
                        model: modelLookup[w.rpaTypeId],
                        name: this.formatRpaTypePipe.transform(
                            modelLookup[w.rpaTypeId]
                        ),
                        customisations: {
                            [authId]: {
                                [w.rpaCategory]: {
                                    workflowConfig: w.workflow,
                                    maintenanceSchedule: w.maintenanceSchedule
                                }
                            }
                        }
                    }
                });
            },
            {
                0: { model: null, name: 'default', customisations: {} }
            } as CustomisedRpaTypeLookup
        );

        this.defaultConfigs = workflowConfigLookup[0];

        this.customisedRpaModels = Object.keys(workflowConfigLookup)
            .filter(rpaModelId => rpaModelId !== '0')
            .map(rpaModelId => workflowConfigLookup[rpaModelId])
            .map(rpa => ({
                ...rpa,
                name: this.formatRpaTypePipe.transform(rpa.model)
            }));

        this.refreshRpaTypesInUse();
    }

    checkIfExisting(category: string, authId: string) {
        return (
            this.defaultConfigs?.customisations[authId] &&
            category in this.defaultConfigs?.customisations[authId]
        );
    }

    checkCustomIfExisting(
        rpaModel: CustomisedRpaType,
        category: string,
        authId: string
    ) {
        return (
            rpaModel?.customisations[authId] &&
            category in rpaModel?.customisations[authId]
        );
    }

    getInitialContext(
        category: string,
        authId: string,
        key: string,
        keyFlow: string
    ) {
        return this.checkIfExisting(category, authId)
            ? {
                  [key]: this.defaultConfigs.customisations[authId][category][
                      keyFlow
                  ]
              }
            : null;
    }

    getDisplayText(
        category: string,
        authId: string,
        key: string,
        keyFlow: string,
        defMes: string
    ) {
        const tempObj = this.getInitialContext(category, authId, key, keyFlow);

        if (tempObj) {
            return tempObj[key]?.name ? tempObj[key].name : defMes;
        }

        return defMes;
    }

    getCustomInitialContext(
        rpaModel: CustomisedRpaType,
        category: string,
        authId: string,
        key: string,
        keyFlow: string
    ) {
        return this.checkCustomIfExisting(rpaModel, category, authId)
            ? {
                  [key]: rpaModel.customisations[authId][category][keyFlow]
              }
            : null;
    }

    getCustomDisplayText(
        rpaModel: CustomisedRpaType,
        category: string,
        authId: string,
        key: string,
        keyFlow: string,
        defMes: string
    ) {
        const tempObj = this.getCustomInitialContext(
            rpaModel,
            category,
            authId,
            key,
            keyFlow
        );

        if (tempObj) {
            return tempObj[key]?.name ? tempObj[key].name : defMes;
        }

        return defMes;
    }

    private refreshRpaTypesInUse() {
        if (this.allRpaTypes == null || this.customisedRpaModels == null) {
            this.availableRpaModels = null;
            return;
        }

        const rpaTypesInUse = this.customisedRpaModels
            .filter(c => c.model != null)
            .map(c => c.model.id);

        this.availableRpaModels = this.allRpaTypes.filter(
            m => rpaTypesInUse.indexOf(m.id) === -1
        );
    }

    customiseRpaModel() {
        this.customisedRpaModels.push({
            model: this.selectedRpaModel,
            name: this.formatRpaTypePipe.transform(this.selectedRpaModel),
            customisations: {}
        });
        this.selectedRpaModel = null;
        this.refreshRpaTypesInUse();
    }

    /**
     * This methods works with onbeforesave of the xeditable, so needs to return a string on error
     * or false to indicate that the value has been saved.
     * @param rpaModel the model to update
     * @param workflow the new workflow
     */
    changeRpaModelWorkflow(
        rpaModel: CustomisedRpaType,
        authorityId: number,
        workflowId: number,
        category: string
    ) {
        const rpaTypeId = rpaModel?.model?.id;
        const realAuthorityId = authorityId !== 0 ? authorityId : null;
        const maintenanceScheduleId = rpaModel.customisations[authorityId]
            ? rpaModel.customisations[authorityId][category]
                  ?.maintenanceSchedule?.id
            : undefined;

        const command: UpdateRpaTypeConfigCommand = {
            rpaTypeId,
            workflowId,
            maintenanceScheduleId,
            authorityId: realAuthorityId,
            organisationId: this.organisation.id,
            rpaCategory:
                UpdateRpaTypeConfigCommand.RpaCategory[category.toUpperCase()]
        };

        this.previewAndChangeSettings(command)
            .subscribe(
                result => {
                    this.logging.success(`Successfully changed workflow`);
                    if (rpaModel && rpaModel.customisations[0]) {
                        rpaModel.customisations[0].workflowConfig = null;
                    }
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while changing Rpa model workflow: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    changeRpaModelMaintenanceSchedule(
        rpaModel: CustomisedRpaType,
        authorityId: number,
        maintenanceScheduleId: number,
        category: string
    ) {
        const rpaTypeId = rpaModel?.model?.id;
        const realAuthorityId = authorityId !== 0 ? authorityId : null;
        const workflowId =
            rpaModel.customisations[authorityId] &&
            category in rpaModel.customisations[authorityId]
                ? rpaModel.customisations[authorityId][category]?.workflowConfig
                      ?.id
                : undefined;

        const command: UpdateRpaTypeConfigCommand = {
            rpaTypeId,
            maintenanceScheduleId,
            workflowId,
            authorityId: realAuthorityId,
            organisationId: this.organisation.id,
            rpaCategory:
                UpdateRpaTypeConfigCommand.RpaCategory[category.toUpperCase()]
        };

        this.previewAndChangeSettings(command)
            .subscribe(
                result => {
                    this.logging.success(`Successfully changed workflow`);
                    if (rpaModel && rpaModel.customisations[0]) {
                        rpaModel.customisations[0].workflowConfig = null;
                    }
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while changing Rpa model workflow: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    private previewAndChangeSettings(command: UpdateRpaTypeConfigCommand) {
        return this.rpaTypeConfigService.preview(command).pipe(
            takeUntil(this.ngUnsubscribe$),
            switchMap(response => {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm change',
                    `This will affect ${response.count} RPA, with ${response.changed} being changed.`,
                    'Apply',
                    () => this.rpaTypeConfigService.update(command).toPromise()
                );
            }),
            catchError(e => {
                // Filter out undefined errors which occur when the dialogue is closed
                if (e != null) {
                    return throwError(e);
                }
                return EMPTY;
            })
        );
    }
}
