import { AircraftRegisterEntryDetailsDto } from '@flyfreely-portal-ui/flyfreely';
import { SelectableRpa } from 'libs/missions/src/lib/mission-edit-v2/mission-edit.service';

export function sortRpaByRegisterStatus(a: SelectableRpa, b: SelectableRpa) {
    const preferredOrder = [
        AircraftRegisterEntryDetailsDto.Status.NOT_ON_REGISTER,
        AircraftRegisterEntryDetailsDto.Status.NOT_AVAILABLE,
        AircraftRegisterEntryDetailsDto.Status.SUSPENDED,
        AircraftRegisterEntryDetailsDto.Status.EXPIRED,
        AircraftRegisterEntryDetailsDto.Status.PENDING,
        AircraftRegisterEntryDetailsDto.Status.ACTIVE,
        AircraftRegisterEntryDetailsDto.Status.AUTO
    ];
    if (
        preferredOrder.findIndex(x => x === a.registerStatus) >
        preferredOrder.findIndex(x => x === b.registerStatus)
    ) {
        return -1;
    } else if (
        preferredOrder.findIndex(x => x === a.registerStatus) <
        preferredOrder.findIndex(x => x === b.registerStatus)
    ) {
        return 1;
    } else {
        return 0;
    }
}
