import { Injectable } from '@angular/core';
import {
    BulkEditOptions,
    BulkImportErrorText,
    ColumnOptionTypes,
    ColumnOptions,
    CreateHistoricalFlightCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonHistoricalLogBookService,
    RpaTypeDto,
    WorkTracker,
    checkRequiredValidity,
    downloadCsv,
    parseCsvByHeadings,
    removeEmptyRows
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
    CHECK_DECIMAL,
    CHECK_IS_TIME_STRING,
    CHECK_IS_TIME_WITH_COLONS
} from 'libs/bulk-uploads/src/lib/checks';
import {
    convertDurationToTimestamp,
    convertTimestampToSeconds,
    dateFormats,
    invalidFieldText,
    parseBulkUploadDate,
    validateBulkUploadDate
} from 'libs/bulk-uploads/src/lib/helpers';
import { BulkImportDialogue } from 'libs/bulk-uploads/src/lib/import-dialogue/import-dialogue.component';
import { BulkUploadDialogue } from 'libs/bulk-uploads/src/lib/upload-dialogue/upload-dialogue.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Columns } from 'ngx-easy-table';
import { ReplaySubject, Subject, forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

interface ImportFlights {
    simulated: string;
    rpaModel: string;
    rpaType: string;
    maxTakeOffWeight: string;
    timeOfDay: string;
    visualLineOfSight: string;
    locationName: string;
    date: string;
    numberOfFlights: string;
    flightTime: string;
}

// A legacy interface that is used to support the legacy bulk upload format for older files.
interface ImportFlightOptions extends ImportFlights {
    flightTimeHours: string;
    flightTimeMinutes: string;
    flightTimeSeconds: string;
}

interface RpaModels {
    name: string;
    type?: RpaTypeDto.CraftType;
    value: number;
    mtow?: number;
}

const template: FormlyFieldConfig = {
    fieldArray: {
        fieldGroup: [
            {
                key: 'simulated',
                props: {
                    label: 'Real Or Simulated'
                }
            },
            {
                key: 'rpaModel',
                props: {
                    label: 'RPA Model'
                }
            },
            {
                key: 'rpaType',
                props: {
                    label: 'RPA Type'
                }
            },
            {
                key: 'maxTakeOffWeight',
                props: {
                    label: 'Maximum Takeoff Weight (g)'
                }
            },
            {
                key: 'timeOfDay',
                props: {
                    label: 'Time Of Day'
                }
            },
            {
                key: 'visualLineOfSight',
                props: {
                    label: 'Line Of Sight'
                }
            },
            {
                key: 'locationName',
                props: {
                    label: 'Location'
                }
            },
            {
                key: 'date',
                props: {
                    label: 'Date'
                }
            },
            {
                key: 'numberOfFlights',
                props: {
                    label: 'Number Of Flights'
                }
            },
            {
                key: 'flightTime',
                props: {
                    label: 'Total Flight Time'
                }
            }
        ]
    },
    props: {
        label: 'Bulk_Historical_Flight_Import'
    }
};

const templateData = [
    {
        simulated: 'Real',
        rpaModel: 'DJI Mavic Pro',
        rpaType: 'Multirotor',
        maxTakeOffWeight: '300',
        timeOfDay: 'Day',
        visualLineOfSight: 'VLOS',
        locationName: 'Main Campus',
        date: '31-01-2020',
        numberOfFlights: '4',
        flightTime: '1:14:34'
    }
];

const columns: Columns[] = [
    { key: 'simulated', title: 'Real Or Simulated' },
    { key: 'rpaModel', title: 'RPA Model' },
    { key: 'rpaType', title: 'RPA Type' },
    { key: 'maxTakeOffWeight', title: 'Maximum Takeoff Weight (g)' },
    { key: 'timeOfDay', title: 'Time Of Day' },
    { key: 'visualLineOfSight', title: 'Line Of Sight' },
    { key: 'locationName', title: 'Location' },
    { key: 'date', title: 'Date' },
    { key: 'numberOfFlights', title: 'Number Of Flights' },
    { key: 'flightTime', title: 'Total Flight Time' }
];

const bulkEditOptions: BulkEditOptions = {
    cannotBulkEdit: [],
    uniqueInSet: []
};

/**
 * Check if the imported file is using the legacy flight time columns
 * @param item a row from the imported CSV file
 * @returns a boolean determining if the file is using the legacy flight time columns
 */
function checkIsLegacyFlightTime(item: ImportFlightOptions) {
    return (
        (item.flightTimeHours != null ||
            item.flightTimeMinutes != null ||
            item.flightTimeSeconds != null) &&
        (item.flightTimeHours !== '' ||
            item.flightTimeMinutes !== '' ||
            item.flightTimeSeconds !== '')
    );
}

function convertLegacyFlightTime(item: ImportFlightOptions) {
    return `${item.flightTimeHours}:${item.flightTimeMinutes}:${item.flightTimeSeconds}`;
}

@Injectable()
export class FlightHistoryUploadService {
    pilotId: number;
    managingOrganisationId: number;
    rpaModels: RpaModels[];

    uploadDialogue: BsModalRef<BulkUploadDialogue>;
    importDialogue: BsModalRef<BulkImportDialogue>;

    template: CreateHistoricalFlightCommand;
    importedFile: ImportFlights[];

    columnOptions: ColumnOptions[];
    columnOptionsSubject = new ReplaySubject<ColumnOptions[]>();
    failedImportTextSubject = new ReplaySubject<BulkImportErrorText[]>();
    bulkEditOptionsSubject = new ReplaySubject<BulkEditOptions>();
    bulkEditOptions$ = this.bulkEditOptionsSubject.asObservable();
    columnOptions$ = this.columnOptionsSubject.asObservable();
    failedImportText$ = this.failedImportTextSubject.asObservable();
    failedImportText: BulkImportErrorText[] = [];

    workingSubject = new ReplaySubject<boolean>();
    doneImportingSubject = new Subject<void>();
    doneImporting$ = this.doneImportingSubject.asObservable();
    working$ = this.workingSubject.asObservable();
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private personHistoricalLogBookService: PersonHistoricalLogBookService,
        private modalService: BsModalService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSubject.next(working));

        this.bulkEditOptionsSubject.next(bulkEditOptions);
    }

    ngOnDestroy() {
        this.doneImportingSubject.complete();
        this.workingSubject.complete();
        this.columnOptionsSubject.complete();
        this.failedImportTextSubject.complete();
        this.bulkEditOptionsSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    showBulkUpload(
        pilotId: number,
        managingOrganistationId: number,
        rpaModels: RpaModels[]
    ) {
        this.pilotId = pilotId;
        this.managingOrganisationId = managingOrganistationId;
        this.rpaModels = rpaModels ?? [];

        this.uploadDialogue = this.modalService.show(BulkUploadDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                uploadService: this,
                hasTemplate: true,
                entity: 'Flight History'
            }
        });
    }

    importFile(file: File) {
        parseCsvByHeadings<ImportFlights>(file, {
            'Real Or Simulated': 'simulated',
            'RPA Model': 'rpaModel',
            'RPA Type': 'rpaType',
            'Maximum Takeoff Weight (g)': 'maxTakeOffWeight',
            'Time Of Day': 'timeOfDay',
            'Line Of Sight': 'visualLineOfSight',
            Location: 'locationName',
            Date: 'date',
            'Number Of Flights': 'numberOfFlights',
            'Total Flight Time Hours': 'flightTimeHours',
            'Total Flight Time Minutes': 'flightTimeMinutes',
            'Total Flight Time Seconds': 'flightTimeSeconds',
            'Total Flight Time': 'flightTime'
        }).then(result => {
            // Sometimes the csv parser doesn't filter out empty rows.
            // The filtered variable is a redundancy check to ensure empty rows are ignored.
            const filtered = removeEmptyRows<ImportFlightOptions[]>(result);
            this.importedFile = filtered.map(f => {
                const flightTime = checkIsLegacyFlightTime(f)
                    ? convertLegacyFlightTime(f)
                    : f.flightTime;
                return {
                    ...f,
                    date: checkRequiredValidity(f.date),
                    flightTime: checkRequiredValidity(flightTime),
                    locationName: checkRequiredValidity(f.locationName),
                    numberOfFlights: checkRequiredValidity(f.numberOfFlights),
                    simulated: checkRequiredValidity(f.simulated),
                    timeOfDay: checkRequiredValidity(f.timeOfDay),
                    visualLineOfSight: checkRequiredValidity(
                        f.visualLineOfSight
                    )
                };
            });
            this.setupColumnOptions();
            this.importedFile.forEach((row, i) => {
                row.flightTime = this.buildDurationValue(row.flightTime);
                this.validateRow(row, i, this.columnOptions);
            });
            this.showImportDialogue();
            this.uploadDialogue.hide();
        });
    }

    private buildDurationValue(duration: string) {
        let formattedDuration;
        // Check if the duration format might be MM:ss
        // If it is, add 00: to the start so the parsers work
        if (
            !CHECK_DECIMAL.test(duration) &&
            !CHECK_IS_TIME_WITH_COLONS.test(duration) &&
            CHECK_IS_TIME_STRING.test(duration) &&
            duration.match(CHECK_IS_TIME_STRING)[5] === '' &&
            duration.includes(':') &&
            duration.split(':').length === 2
        ) {
            formattedDuration = ['00']
                .concat(
                    duration.split(':').map(i => (i.length === 2 ? i : `0${i}`))
                )
                .join(':');
        } else {
            formattedDuration = duration;
        }
        return convertDurationToTimestamp(formattedDuration);
    }

    setupColumnOptions() {
        this.columnOptions = [
            {
                columnName: 'date',
                type: ColumnOptionTypes.SELECT,
                placeholder: 'Select the date format used',
                values: dateFormats
            },
            {
                columnName: 'simulated',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: [
                    { name: 'Simulated', value: 'Simulated' },
                    { name: 'Real', value: 'Real' }
                ]
            },
            {
                columnName: 'timeOfDay',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: [
                    { name: 'Day', value: 'Day' },
                    { name: 'Night', value: 'Night' }
                ]
            },
            {
                columnName: 'visualLineOfSight',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: [
                    { name: 'VLOS', value: 'VLOS' },
                    { name: 'EVLOS', value: 'EVLOS' },
                    { name: 'BVLOS', value: 'BVLOS' }
                ]
            },
            {
                columnName: 'rpaModel',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: this.rpaModels
                    .map(m => ({ name: m.name, value: m.name }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
            },
            {
                columnName: 'rpaType',
                type: ColumnOptionTypes.NONE,
                cellType: ColumnOptionTypes.SELECT,
                cellValues: [
                    { name: 'Aeroplane', value: 'Aeroplane' },
                    { name: 'Airship', value: 'Airship' },
                    { name: 'Helicopter', value: 'Helicopter' },
                    { name: 'Multirotor', value: 'Multirotor' },
                    { name: 'Powered Lift', value: 'Powered Lift' },
                    { name: 'Other', value: 'Other' }
                ]
            }
        ];
        this.columnOptionsSubject.next(this.columnOptions);
    }

    validateRow(
        row: ImportFlights,
        rowIndex: number,
        options: ColumnOptions[]
    ) {
        const dateFormat = options.find(
            option => option != null && option.columnName === 'date'
        ).result;
        // Validate each row and add ' *INVALID*' markers to any invalid
        if (
            row.date !== 'INVALID' &&
            row.date &&
            validateBulkUploadDate(row.date, dateFormat) === false
        ) {
            this.updateErrorText(
                rowIndex,
                'date',
                `The date: ${row.date} is invalid. Please ensure you have selected the correct format`
            );
            row.date = `${row.date} ${invalidFieldText}`;
        }
        if (
            row.rpaModel.length > 0 &&
            this.findRpaTypeId(row.rpaModel) == null
        ) {
            this.updateErrorText(
                rowIndex,
                'rpaModel',
                `${row.rpaModel} is not a known RPA model. Please ensure this model is correct and has been set up for your organisation`
            );
            row.rpaModel = `${row.rpaModel} ${invalidFieldText}`;
        }
        if (
            row.rpaType.length > 0 &&
            this.findRpaCategory(row.rpaType) == null
        ) {
            this.updateErrorText(
                rowIndex,
                'rpaType',
                `${row.rpaType} is not a valid RPA type`
            );
            row.rpaType = `${row.rpaType} ${invalidFieldText}`;
        }
        if (
            row.simulated !== 'INVALID' &&
            !(
                row.simulated.trim().toLowerCase() === 'simulated' ||
                row.simulated.trim().toLowerCase() === 'real'
            )
        ) {
            this.updateErrorText(
                rowIndex,
                'simulated',
                `${row.simulated} is invalid. Please ensure the value is either "Real" or "Simulated"`
            );
            row.simulated = `${row.simulated} ${invalidFieldText}`;
        }
        if (
            row.flightTime !== 'INVALID' &&
            row.flightTime.match(CHECK_IS_TIME_WITH_COLONS) == null
        ) {
            this.updateErrorText(
                rowIndex,
                'flightTime',
                `Please ensure flight time is in HH:MM:SS format and indicates the amount of hours flown.`
            );
            row.flightTime = `${row.flightTime} ${invalidFieldText}`;
        }
        if (
            row.timeOfDay !== 'INVALID' &&
            !(
                row.timeOfDay.trim().toLowerCase() === 'day' ||
                row.timeOfDay.trim().toLowerCase() === 'night'
            )
        ) {
            this.updateErrorText(
                rowIndex,
                'timeOfDay',
                `${row.timeOfDay} is not a valid time of day. Please ensure the value is either "Day" or "Night"`
            );
            row.timeOfDay = `${row.timeOfDay} ${invalidFieldText}`;
        }
        if (
            row.visualLineOfSight !== 'INVALID' &&
            this.findLineOfSight(row.visualLineOfSight) == null
        ) {
            this.updateErrorText(
                rowIndex,
                'visualLineOfSight',
                `${row.visualLineOfSight} is not a valid line of sight. Please ensure the value is either "VLOS", "EVLOS" or "BVLOS"`
            );
            row.visualLineOfSight = `${row.visualLineOfSight} ${invalidFieldText}`;
        }
        this.failedImportTextSubject.next(this.failedImportText);

        return row;
    }

    showImportDialogue() {
        this.importDialogue = this.modalService.show(BulkImportDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                uploadService: this,
                data: this.importedFile,
                columns: columns,
                entity: 'Flight History'
            }
        });
    }

    import(selected: number[], options: ColumnOptions[]) {
        const data = selected.map(n => this.importedFile[n]);
        const dateFormat = options.find(
            option => option != null && option.columnName === 'date'
        ).result;
        /**
         * Validity checks with individual feedback for clarity.
         */
        const failed: number[] = [];
        data.map((e, i) => {
            if (Object.keys(e).filter(key => e[key] === 'INVALID').length > 0) {
                failed.push(i);
            }
        });

        data.forEach((entry, i) => {
            const rowIndex = this.importedFile.findIndex(e => e === entry);
            if (
                Object.keys(entry).filter(key => entry[key] === 'INVALID')
                    .length > 0
            ) {
                return;
            }
            if (
                entry.date != null &&
                validateBulkUploadDate(entry.date, dateFormat) === false
            ) {
                this.updateErrorText(
                    rowIndex,
                    'date',
                    `The date: ${entry.date} is invalid. Please ensure you have selected the correct format`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.rpaModel.length > 0 &&
                this.findRpaTypeId(entry.rpaModel) == null
            ) {
                this.updateErrorText(
                    rowIndex,
                    'rpaModel',
                    `${entry.rpaModel} is not a known RPA model. Please ensure this model is correct and has been set up for your organisation`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                entry.rpaType.length > 0 &&
                this.findRpaCategory(entry.rpaType) == null
            ) {
                this.updateErrorText(
                    rowIndex,
                    'rpaType',
                    `${entry.rpaType} is not a valid RPA type`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                !(
                    entry.simulated.trim().toLowerCase() === 'simulated' ||
                    entry.simulated.trim().toLowerCase() === 'real'
                )
            ) {
                this.updateErrorText(
                    rowIndex,
                    'simulated',
                    `${entry.simulated} is invalid. Please ensure the value is either "Real" or "Simulated"`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (entry.flightTime.match(CHECK_IS_TIME_WITH_COLONS) == null) {
                this.updateErrorText(
                    rowIndex,
                    'flightTime',
                    `Please ensure flight time is in HH:MM:SS format and indicates the amount of hours flown.`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (
                !(
                    entry.timeOfDay.trim().toLowerCase() === 'day' ||
                    entry.timeOfDay.trim().toLowerCase() === 'night'
                )
            ) {
                this.updateErrorText(
                    rowIndex,
                    'timeOfDay',
                    `${entry.timeOfDay} is not a valid time of day. Please ensure the value is either "Day" or "Night"`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
            if (this.findLineOfSight(entry.visualLineOfSight) == null) {
                this.updateErrorText(
                    rowIndex,
                    'visualLineOfSight',
                    `${entry.visualLineOfSight} is not a valid line of sight. Please ensure the value is either "VLOS", "EVLOS" or "BVLOS"`
                );
                if (failed.includes(i) === false) {
                    failed.push(i);
                }
            }
        });

        const validEntries = data
            .map((e, i) => ({ ...e, position: i }))
            .filter((e, i) => failed.includes(i) === false);

        if (validEntries.length > 0) {
            const hist = CreateHistoricalFlightCommand;
            const command: CreateHistoricalFlightCommand[] = validEntries.map(
                entry => ({
                    flightDate:
                        parseBulkUploadDate(entry.date, dateFormat) ?? null,
                    duration: convertTimestampToSeconds(entry.flightTime),
                    locationName: entry.locationName,
                    managingOrganisationId: this.managingOrganisationId ?? null,
                    maxTakeOffWeight: entry.maxTakeOffWeight.length
                        ? parseInt(entry.maxTakeOffWeight.trim(), 10)
                        : null,
                    numberOfFlights: parseInt(entry.numberOfFlights.trim(), 10),
                    pilotId: this.pilotId,
                    rpaTypeId: this.findRpaTypeId(entry.rpaModel),
                    rpaCategory: this.findRpaCategory(entry.rpaType),
                    simulated:
                        entry.simulated.trim().toLowerCase() === 'simulated'
                            ? true
                            : false,
                    timeOfDay:
                        entry.timeOfDay.trim().toLowerCase() === 'day'
                            ? hist.TimeOfDay.DAY
                            : hist.TimeOfDay.NIGHT,
                    visualLineOfSight: this.findLineOfSight(
                        entry.visualLineOfSight
                    )
                })
            );

            let completed: boolean = true;
            const failedSubmit = [];
            forkJoin(
                command.map((cmd, i) =>
                    this.personHistoricalLogBookService
                        .createHistoricalFlight(cmd)
                        .pipe(
                            catchError(error => {
                                failedSubmit.push(validEntries[i].position);
                                this.logging.error(
                                    error,
                                    `Error creating log for flight on ${cmd.flightDate} @ ${cmd.locationName}: ${error.message}`
                                );
                                completed = false;
                                return of(null);
                            })
                        )
                )
            )
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    result =>
                        this.doPostImportCleanup(
                            validEntries,
                            command,
                            failed,
                            failedSubmit,
                            completed
                        ),
                    (error: FlyFreelyError) =>
                        this.doPostImportCleanup(
                            validEntries,
                            command,
                            failed,
                            failedSubmit,
                            completed
                        )
                )
                .add(this.workTracker.createTracker());
        } else {
            if (failed.length > 0) {
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
            }
        }
    }

    doPostImportCleanup(
        validEntries: ImportFlights[],
        command: CreateHistoricalFlightCommand[],
        failed: number[],
        failedSubmit: number[],
        completed: boolean
    ) {
        failed = failed.concat(failedSubmit);
        if (!completed) {
            this.importDialogue.content.onFailedRows();
            this.logging.warn(
                'Some imported rows have failed, please fix all errors and try again'
            );
            this.failedImportTextSubject.next(this.failedImportText);
            this.failedImportText = [];
            return;
        } else {
            this.logging.success(
                `Successfully imported ${command.length} items.`
            );
            if (failed.length > 0) {
                this.importDialogue.content.importedRowIndices =
                    this.importedFile.reduce(
                        (acc, row, i) =>
                            validEntries.includes(row) ? acc.concat(i) : acc,
                        []
                    );
                this.importedFile = this.importedFile.filter(
                    e => validEntries.includes(e) === false
                );
                this.importDialogue.content.onFailedRows();
                this.logging.warn(
                    'Some imported rows have failed, please fix all errors and try again'
                );
                this.failedImportTextSubject.next(this.failedImportText);
                this.failedImportText = [];
            } else {
                this.importDialogue.hide();
                this.doneImportingSubject.next();
            }
        }
    }

    calculateFlightTime(hours: string, minutes: string, seconds: string) {
        if (!hours || !minutes || !seconds) {
            return;
        }
        const h: number = parseInt(hours.trim(), 10) * 3600;
        const m: number = parseInt(minutes.trim(), 10) * 60;
        const s: number = parseInt(seconds.trim(), 10);
        return h + m + s;
    }

    findRpaTypeId(rpaModel: string) {
        const i = this.rpaModels.findIndex(
            m => m.name.toUpperCase() === rpaModel.trim().toUpperCase()
        );
        if (i !== -1) {
            return this.rpaModels[i].value;
        } else {
            return null;
        }
    }

    findRpaCategory(rpaType: string) {
        const type = CreateHistoricalFlightCommand.RpaCategory;

        switch (rpaType.trim().toUpperCase()) {
            case 'AEROPLANE':
                return type.AEROPLANE;
            case 'AIRSHIP':
                return type.AIRSHIP;
            case 'HELICOPTOR':
                return type.HELICOPTOR;
            case 'MULTIROTOR':
                return type.MULTIROTOR;
            case 'OTHER':
                return type.OTHER;
            case 'POWERED_LIFT':
            case 'POWERED LIFT':
                return type.POWERED_LIFT;

            default:
                return null;
        }
    }

    findLineOfSight(lineOfSight: string) {
        const line = CreateHistoricalFlightCommand.VisualLineOfSight;

        switch (lineOfSight.trim().toUpperCase()) {
            case 'BVLOS':
                return line.BVLOS;
            case 'EVLOS':
                return line.EVLOS;
            case 'VLOS':
                return line.VLOS;

            default:
                return null;
        }
    }

    downloadTemplate() {
        downloadCsv(template, templateData);
    }

    updateErrorText(rowIndex: number, key: string, text: string) {
        if (
            this.failedImportText.findIndex(
                t => t.key === key && t.rowIndex === rowIndex
            ) !== -1
        ) {
            return;
        } else {
            this.failedImportText.push({
                rowIndex: rowIndex,
                key: key,
                text: text
            });
        }
    }
}
