<div
    class="vertical-container"
    [ngClass]="{ 'airspace-check-spacing': !hideHeader }"
>
    <ng-container *ngIf="!hideHeader">
        <div class="horizontal-container vertical-align">
            <h5>Airspace Check</h5>
            <!-- TODO: this should be driven by jurisdiction for now -->
            <button
                *ngIf="result"
                type="button"
                class="btn btn-sm wrap-overflow btn-link"
                [disabled]="status == 'CHECKING' || status == 'NO_CHECK'"
                (click)="showAirspaceCheckDetails()"
            >
                View Details
            </button>
            <airspace-check-status class="pull-right"></airspace-check-status>
            <jurisdiction-links
                *ngIf="linkPosition === 'TOP'"
            ></jurisdiction-links>
        </div>
    </ng-container>
    <div
        style="position: relative"
        class="container-scroll airspace-check-scroll"
    >
        <ng-container *ngIf="!hideHeader">
            <div *ngIf="status == 'CHECKING'">
                <span><em>Checking...</em></span>
            </div>
            <ng-container *ngIf="status != 'CHECKING'">
                <div [ngSwitch]="jurisdictionName != null">
                    <span *ngSwitchCase="false"
                        ><em>Please select a location to get started.</em></span
                    >
                    <!-- <div class="vertical-container" *ngSwitchCase="true">
                        <span>
                            {{ location.airspaceJurisdiction?.name }}
                            {{ location.airspaceJurisdiction?.caa?.name != null ? ' - ' : '' }}
                            {{ location.airspaceJurisdiction?.caa?.name }}
                            {{ status != 'NO_CHECK' && workflowVersionId == null || result?.ruleset != null ? ' - ' : '' }}
                            <span [ngClass]="{ error: workflowVersionId == null}">
                                {{ workflowVersionId == null && status != 'NO_CHECK'
                                    ? 'Select a workflow'
                                    : result == null || status == 'NO_CHECK'
                                        ? ''
                                        : result?.ruleset
                                }}
                            </span>
                        </span>
                        <a
                            *ngIf="location.airspaceJurisdiction?.hasLibrary"
                            (click)="viewJurisdictionLibrary()"
                        >
                            View Jurisdiction Library
                        </a>
                    </div> -->
                </div>
            </ng-container>
        </ng-container>
        <div
            class="airspace-check-result-container top-buffer"
            *ngIf="status == 'NO_CHECK'"
        >
            <span class="error">Unavailable in this jurisdiction</span>
        </div>
        <airspace-check-results
            (ruleResultClick)="viewRuleDetails($event.code, $event.type)"
        ></airspace-check-results>
        <jurisdiction-links
            *ngIf="linkPosition === 'BOTTOM'"
        ></jurisdiction-links>
    </div>
</div>
