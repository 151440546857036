<ng-container *ngIf="!working" [ngSwitch]="type">
    <div *ngSwitchCase="'ACTION'">
        <h4>{{ checkName }}</h4>
        <p class="help-block">
            {{ description }}
        </p>
        <form [formGroup]="actionForm">
            <div class="vertical-container">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" formControlName="passed" />
                        <span class="cr"
                            ><i class="cr-icon glyphicon glyphicon-ok"></i>
                        </span>
                        Passed
                    </label>
                </div>
                <div class="form-group">
                    <label>Expiry (Optional)</label>
                    <div class="input-group input-group-sm">
                        <input
                            class="form-control"
                            #editExpiryDate="bsDatepicker"
                            bsDatepicker
                            formControlName="expiry"
                            name="expiryDate"
                        />
                        <span
                            class="input-group-addon btn-default"
                            (click)="editExpiryDate.toggle()"
                            [attr.aria-expanded]="editExpiryDate.isOpen"
                        >
                            <i
                                class="glyphicon glyphicon-calendar"
                                style="cursor: pointer"
                            ></i>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Notes (Optional)</label>
                    <textarea
                        class="form-control"
                        formControlName="notes"
                        name="notes"
                    ></textarea>
                </div>
                <button
                    type="button"
                    class="btn btn-xs btn-pill btn-primary"
                    (click)="saveAction(); $event.stopPropagation()"
                    [disabled]="working"
                >
                    Save
                </button>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="'ATTACHMENT_ACKNOWLEDGEMENT'">
        <ng-container *ngIf="acknowledgementTime != null">
            <h4>{{ checkName }}</h4>
            <div class="horizontal-container">
                <label class="right-buffer">Acknowledgement Time</label>
                <div class="vertical-contaier left-buffer">
                    <div>
                        {{ acknowledgementTime | formatDateTime }}
                    </div>
                </div>
            </div>
            <div
                class="horizontal-container"
                *ngIf="acknowledgedAttachment != null"
            >
                <div class="vertical-contaier">
                    <div>
                        <a [downloadUrl]="acknowledgedAttachment.downloadUrl">
                            Download Attachment
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="acknowledgementTime == null">
            <p class="help-block">
                No existing authority details found for this check.
            </p>
        </div>
    </div>

    <div *ngSwitchCase="'INTERNAL_LOOKUP'">
        <h4>{{ authority.authorityType.name }}</h4>
        <p class="help-block">
            Issued by {{ authority.authorityType.issuedBy.name }}
        </p>
        <div>
            <p>
                Start: {{ authority.startDate }}
                <span *ngIf="authority.authorityType.hasExpiry">
                    Expiry:
                    {{
                        authority.expiryDate != null
                            ? authority.expiryDate
                            : 'None'
                    }}
                </span>
            </p>
            <p>
                {{ authority.authorityType.identifierLabel }}
                {{ authority.identifier }}
            </p>
        </div>
        <div class="horizontal-container">
            <label class="right-buffer">Verified</label>
            <div
                class="vertical-contaier left-buffer"
                *ngIf="authority.verifications != null"
            >
                <span *ngIf="authority.verifications.length === 0"
                    >This authority is unverified.
                    <a (click)="verifyAuthority(); $event.stopPropagation()"
                        >Verify now</a
                    >
                </span>
                <div *ngFor="let verification of authority.verifications">
                    Verified by
                    {{ verification.verifiedBy | formatPerson }} on
                    {{ verification.verificationTime | formatDateTime }}
                </div>
            </div>
        </div>
        <div class="horizontal-container">
            <label class="right-buffer">Attachments</label>
            <div class="vertical-contaier left-buffer">
                <div *ngFor="let attachment of authority.attachments">
                    <a [downloadUrl]="attachment.downloadUrl">
                        {{ attachment.name }}
                    </a>
                    <p
                        *ngIf="
                            acknowledgements != null &&
                            acknowledgements[attachment.attachmentVersionId]
                        "
                    >
                        Acknowledged
                        {{
                            acknowledgements[attachment.attachmentVersionId]
                                | formatDateTime
                        }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'CURRENCY'">
        <h4>{{ checkName }}</h4>
        <ng-container *ngIf="currencies && currencies.length">
            <div *ngFor="let currency of currencies; let last = last">
                <div [ngClass]="{ 'bottom-buffer': !last }">
                    <div class="horizontal-container fill">
                        <label class="fcol-11">
                            <span
                                *ngFor="
                                    let conditionList of currency.currencyOperatingConditionList;
                                    let lastCondition = last
                                "
                            >
                                <ng-container
                                    *ngFor="
                                        let condition of conditionList.values;
                                        let lastValue = last
                                    "
                                >
                                    <ng-container
                                        *ngIf="condition.type !== 'RPA_MTOW'"
                                    >
                                        {{ operatingConditions[condition.value]
                                        }}{{
                                            lastValue && lastCondition
                                                ? ''
                                                : ', '
                                        }}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            conditionList.type === 'RPA_MTOW'
                                        "
                                    >
                                        {{ condition.value | formatMTOW
                                        }}{{
                                            lastValue && lastCondition
                                                ? ''
                                                : ', '
                                        }}
                                    </ng-container>
                                </ng-container></span
                            >
                            <span
                                *ngIf="
                                    currency.currencyOperatingConditionList
                                        .length === 0
                                "
                            >
                                Any
                            </span>
                        </label>
                        <span
                            *ngIf="currency.passed"
                            class="fa fa-check text-success pull-right"
                        >
                        </span>
                        <span
                            *ngIf="!currency.passed"
                            class="fa fa-times text-danger pull-right"
                        >
                        </span>
                    </div>
                    <div>
                        {{ currency.actualFlights }} flights&nbsp;
                        <em>
                            ({{
                                currency.requiredFlights
                                    ? currency.requiredFlights
                                    : 0
                            }}
                            required)
                        </em>
                    </div>
                    <div>
                        {{ currency.actualFlightsTimeSeconds | formatDuration  | defaultTo }}
                        flown&nbsp;
                        <em>
                            ({{
                                !currency.requiredFlightsTimesSeconds
                                    ? 'none'
                                    : (currency.requiredFlightsTimesSeconds
                                      | formatDuration)
                            }}
                            required)
                        </em>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="!currencies || !currencies.length">
            No currency details available
        </div>
    </div>
    <div *ngSwitchDefault>NOT SUPPORTED</div>
</ng-container>

<loading-indicator [isLoading]="working"></loading-indicator>
