import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    BatterySetDto,
    BatterySetService,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BatterySetDetails } from '../battery-set-details.component';

@Component({
    selector: 'battery-set-view',
    templateUrl: './battery-set-view.component.html'
})
export class BatterySetView implements OnInit {
    @Input() currentOrganisation: PersonsOrganisationDto;
    @Input() batterySet: BatterySetDto;
    @Input() modal: BsModalRef<BatterySetDetails>;
    @Output() edit = new EventEmitter<void>();
    @Output() deleted = new EventEmitter<void>();

    hasEdit = false;
    hasDelete = false;
    canEdit: boolean = true;
    canDelete: boolean;
    isOwner: boolean;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private batterySetService: BatterySetService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshPermissions();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        this.hasEdit = this.batterySet.availableActions.hasEdit;
        this.hasDelete = this.batterySet.availableActions.hasDelete;
        this.canEdit = this.batterySet.availableActions.canEdit;
        this.canDelete = this.batterySet.availableActions.canDelete;
        this.isOwner =
            this.batterySet.organisationId === this.currentOrganisation.id;
    }

    onEdit() {
        this.edit.emit();
    }

    delete() {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Battery',
                'Are you sure you wish to delete this battery set?',
                'Delete',
                () =>
                    this.batterySetService
                        .delete(this.batterySet.id)
                        .toPromise()
            )
            .then(battery => {
                this.logging.success(`Battery set has been deleted`);
                this.deleted.next();
                doneWorking();
            })
            .catch(() => doneWorking());
    }
}
