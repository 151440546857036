import {
    Component,
    forwardRef,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef
} from '@angular/core';
import {
    ControlValueAccessor,
    Validator,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { EquipmentDto } from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissionEditService } from '../mission-edit.service';
import { equipmentSearch } from 'libs/resource-ui/src/lib/searchFunctions';

export const MISSION_EQUIPMENT_SELECT_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MissionEquipmentSelection),
    multi: true
};

export const MISSION_EQUIPMENT_SELECT_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MissionEquipmentSelection),
    multi: true
};

@Component({
    selector: 'tr[mission-equipment-select]',
    templateUrl: './mission-equipment-select.component.html',
    providers: [
        MISSION_EQUIPMENT_SELECT_ACCESSOR,
        MISSION_EQUIPMENT_SELECT_VALIDATOR
    ]
})
export class MissionEquipmentSelection
    implements ControlValueAccessor, Validator {
    @Input() index: number;
    @Input() disable: boolean;
    @Input() readonly: boolean;
    @Input() setStep: (step: string) => any;
    @Output() showEquipmentDetails = new EventEmitter<void>();
    @Output() deleteEquipment = new EventEmitter<void>();

    editing = true;

    allOrganisationEquipment: EquipmentDto[];
    availableEquipment: EquipmentDto[];

    selectedEquipmentId: number;
    selectedEquipment: EquipmentDto;
    onChange = Function.prototype;
    onTouched = Function.prototype;

    equipmentSearch = equipmentSearch;

    get missionObjectivesGroup() {
        return this.missionEditService.missionObjectivesGroup;
    }

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private missionEditService: MissionEditService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.missionEditService.missionForm.controls.equipmentIds.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.setupAvailableEquipment());

        this.missionEditService.selectableEquipment$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(equipment => {
                this.allOrganisationEquipment = equipment;
                if (
                    this.selectedEquipmentId != null &&
                    this.selectedEquipment == null
                ) {
                    this.selectedEquipment = equipment.find(
                        e => e.id === this.selectedEquipmentId
                    );
                }
                this.setupAvailableEquipment();
            });

        this.setupAvailableEquipment();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('selectedEquipment' in changes) {
            this.setupAvailableEquipment();
        }
    }

    writeValue(value: any): void {
        this.selectedEquipment =
            value != null
                ? this.allOrganisationEquipment?.find(e => e.id === value)
                : null;
        this.selectedEquipmentId = value;
        this.editing = this.selectedEquipmentId == null;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(c: FormControl) {
        const isAllValid = this.selectedEquipmentId != null;
        return isAllValid
            ? null
            : {
                  length: {
                      valid: isAllValid
                  }
              };
    }

    onUpdate() {
        this.onChange(this.selectedEquipmentId);
    }

    deleteRow() {
        this.deleteEquipment.emit();
    }

    viewEquipment() {
        this.showEquipmentDetails.emit();
    }

    updateSelectedEquipment() {
        this.selectedEquipment = this.allOrganisationEquipment.find(
            e => e.id === this.selectedEquipmentId
        );
        this.onUpdate();
        this.editing = false;
    }

    setupAvailableEquipment() {
        if (this.allOrganisationEquipment == null) {
            return;
        }
        const selectedEquipment = this.missionEditService.missionForm.controls
            .equipmentIds.value;
        if (selectedEquipment == null || selectedEquipment.length === 0) {
            this.availableEquipment = this.allOrganisationEquipment;
            return;
        }
        const otherSelected = selectedEquipment.filter(
            v => v !== this.selectedEquipmentId
        );
        this.availableEquipment = this.allOrganisationEquipment.filter(
            e => otherSelected.find(v => v === e.id) == null
        );
        this.changeDetector.detectChanges();
    }
}
