<form
    [formGroup]="rpaForm"
    novalidate
    class="container-with-footer"
    (ngSubmit)="saveRPA()"
>
    <div class="container-fluid">
        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Name/Designation</label>
                <field-validation-indicator
                    validatorFormControlName="nickname"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>

                <input class="form-control" formControlName="nickname" />
            </div>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="nickname"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>RPA Type</label>
                <field-validation-indicator
                    validatorFormControlName="rpaTypeId"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <rpa-type-selector
                    [rpaTypeList]="rpaTypeList"
                    selectorFormControlName="rpaTypeId"
                ></rpa-type-selector>
            </div>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="rpaTypeId"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Manufacturer Serial Number</label>
                <field-validation-indicator
                    validatorFormControlName="manufacturerSerialNumber"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <input
                    class="form-control"
                    formControlName="manufacturerSerialNumber"
                />
            </div>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="manufacturerSerialNumber"
            [compact]="true"
        >
        </enhanced-help>

        <div *ngIf="canUseNfc" class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>NFC UID</label>
                <field-validation-indicator
                    validatorFormControlName="nfcUid"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <input class="form-control" formControlName="nfcUid" />
            </div>
        </div>
        <enhanced-help
            *ngIf="canUseNfc"
            screenName="rpa-edit"
            componentName="nfcUid"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Estimated Historical Flight Time</label>
                <field-validation-indicator
                    validatorFormControlName="initialFlightTime"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <div class="input-group">
                    <duration
                        formControlName="initialFlightTime"
                        [showHour]="true"
                        [showMinute]="true"
                        [showSecond]="true"
                        [clearable]="false"
                    >
                    </duration>
                </div>
            </div>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="initialFlightTime"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Call Sign</label>
                <field-validation-indicator
                    validatorFormControlName="callSign"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <input class="form-control" formControlName="callSign" />
            </div>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="callSign"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Estimated Procurement Date</label>
                <field-validation-indicator
                    validatorFormControlName="procurementDate"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        class="form-control"
                        #datepicker="bsDatepicker"
                        bsDatepicker
                        formControlName="procurementDate"
                    />
                    <span
                        class="input-group-addon btn-default"
                        (click)="datepicker.toggle()"
                        [attr.aria-expanded]="datepicker.isOpen"
                    >
                        <i class="glyphicon glyphicon-calendar"></i>
                    </span>
                </div>
            </div>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="procurementDate"
            [compact]="true"
        >
        </enhanced-help>

        <div class="row form-group top-buffer">
            <div class="col-xs-12">
                <label>Maximum Visual Distance</label>
                <field-validation-indicator
                    validatorFormControlName="maximumVisualDistance"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"
                        formControlName="maximumVisualDistance"
                    />
                    <span class="input-group-addon">m</span>
                </div>
                <p
                    *ngIf="
                        rpaType?.otherSpecifications
                            ?.defaultMaximumVisualDistance != null
                    "
                    class="help-text"
                >
                    The default maximum visual distance is
                    {{
                        rpaType?.otherSpecifications
                            ?.defaultMaximumVisualDistance
                    }}m as set by the RPA type
                </p>
                <p
                    *ngIf="
                        rpaType?.otherSpecifications
                            ?.defaultMaximumVisualDistance == null
                    "
                    class="help-text"
                >
                    There is no default maximum visual distance as there is none
                    set on the RPA type
                </p>
            </div>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="maximumVisualDistance"
            [compact]="true"
        >
        </enhanced-help>

        <div>
            <div>
                <label>RPA Classification</label>
                <field-validation-indicator
                    validatorFormControlName="isDummy"
                    [noun]="'field'"
                    [placement]="'left'"
                    class="pull-right"
                ></field-validation-indicator>
            </div>
            <lockable
                [isLocked]="lockedFields?.isDummy"
                message="This RPA has been used on a real mission so can't be made dummy"
            >
                <ng-template lockedMode>
                    {{ rpa.isDummy ? 'Dummy' : 'Real' }} RPA
                </ng-template>
                <ng-template unlockedMode>
                    <div
                        class="input-group"
                        btnRadioGroup
                        formControlName="isDummy"
                        style="padding-top: 5px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="false"
                            [disabled]="working"
                            >Real RPA</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="true"
                            [disabled]="working"
                            >Dummy RPA</label
                        >
                    </div>
                </ng-template>
            </lockable>
            <validation-messages [control]="rpaForm.controls.isDummy">
            </validation-messages>
        </div>
        <enhanced-help
            screenName="rpa-edit"
            componentName="isDummy"
            [compact]="true"
        >
        </enhanced-help>

        <div *ngIf="showComponentDetails" class="row form-group top-buffer">
            <div class="col-xs-12">
                <h4 class="section-heading top-buffer">
                    <span>Initial Components</span>
                    <div></div>
                </h4>
                <div formArrayName="components" class="horizontal-container">
                    <table class="fill">
                        <thead>
                            <tr>
                                <th style="width: 20%">Component</th>
                                <th style="width: 30%">Serial Number</th>
                                <th style="width: 30%">Firmware Version</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="
                                    let component of components.controls;
                                    let i = index;
                                    let even = even
                                "
                                [formGroupName]="i"
                                style="min-height: 35px; height: 35px"
                            >
                                <td>
                                    <span
                                        *ngIf="component.value.icon != null"
                                        [class]="component.value.icon"
                                    ></span>
                                    &nbsp;{{ component.value.name }}
                                </td>
                                <td>
                                    <input
                                        *ngIf="component.value.hasSerialNumber"
                                        [readonly]="working"
                                        type="text"
                                        class="form-control"
                                        formControlName="serialNumber"
                                        placeholder="Type a serial number"
                                    />
                                </td>
                                <td>
                                    <input
                                        *ngIf="component.value.hasFirmware"
                                        [readonly]="working"
                                        type="text"
                                        class="form-control"
                                        formControlName="firmwareVersion"
                                        placeholder="Type a firmware version"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="row">
                    <div
                        formArrayName="components"
                        class="col-xs-12"
                    >
                        <ng-container 
                            *ngFor="
                                let component of components.controls;
                                let i = index;
                                let even = even;
                            "
                            [formGroupName]="i"
                        >
                            <div
                                *ngIf="component.value.hasSerialNumber || component.value.hasFirmware"
                                class="row row-padding"
                            >
                                <div class="col-xs-4">
                                    <label>Component</label>
                                    <p>
                                        <span
                                            *ngIf="component.value.icon != null"
                                            [class]="component.value.icon"
                                        ></span>&nbsp;{{ component.value.name }}
                                    </p>
                                </div>
                                <div class="col-xs-4">
                                    <ng-container *ngIf="component.value.hasSerialNumber">
                                        <label>Serial Number</label>
                                        <input
                                            [readonly]="working"
                                            type="text"
                                            class="form-control"
                                            formControlName="serialNumber"
                                            placeholder="eg. SN12345"
                                        />
                                    </ng-container>
                                </div>
                                <div class="col-xs-4">
                                    <ng-container *ngIf="component.value.hasFirmware">
                                        <label>Firmware Version</label>
                                        <input
                                            [readonly]="working"
                                            type="text"
                                            class="form-control"
                                            formControlName="firmwareVersion"
                                            placeholder="eg. V1.5.6"
                                        />
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="row form-group top-buffer" *ngIf="rpa.id">
            <div class="col-xs-12">
                <h4 class="section-heading top-buffer">
                    <span>Attachments</span>
                    <div></div>
                </h4>
                <attachment-list
                    [attachmentsHandler]="attachmentsHandler"
                    [allowUploads]="true"
                    [allowDelete]="true"
                    [allowEdit]="true"
                    permissions
                ></attachment-list>
            </div>
        </div>
    </div>

    <div class="action-container">
        <button
            type="button"
            class="btn btn-default pull-left"
            style="margin-right: 0.5rem"
            (click)="cancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary pull-right"
            type="submit"
            [disabled]="rpaForm.invalid || working"
        >
            Save
        </button>
    </div>
</form>
