/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface FlightConformanceRuleResultDto { 
    id: number;
    ruleVersionId: number;
    description?: string;
    /**
     * The value that the rule had determined is the comparison point
     */
    comparisonValue?: number;
    /**
     * Where the comparison value came from
     */
    comparisonSource?: FlightConformanceRuleResultDto.ComparisonSource;
    /**
     * The value from the flight
     */
    actualValue?: number;
    acknowledgementMessage?: string;
    acknowledgementTime?: string;
    status: FlightConformanceRuleResultDto.Status;
    /**
     * The type of rule
     */
    type: FlightConformanceRuleResultDto.Type;
}
export namespace FlightConformanceRuleResultDto {
    export type ComparisonSource = 'DEFAULT' | 'MAXIMUM' | 'PLAN' | 'APPROVAL' | 'TYPE' | 'ENTITY';
    export const ComparisonSource = {
        DEFAULT: 'DEFAULT' as ComparisonSource,
        MAXIMUM: 'MAXIMUM' as ComparisonSource,
        PLAN: 'PLAN' as ComparisonSource,
        APPROVAL: 'APPROVAL' as ComparisonSource,
        TYPE: 'TYPE' as ComparisonSource,
        ENTITY: 'ENTITY' as ComparisonSource
    };
    export type Status = 'UNACKNOWLEDGED' | 'ACKNOWLEDGED' | 'NO_DATA';
    export const Status = {
        UNACKNOWLEDGED: 'UNACKNOWLEDGED' as Status,
        ACKNOWLEDGED: 'ACKNOWLEDGED' as Status,
        NO_DATA: 'NO_DATA' as Status
    };
    export type Type = 'VISUAL_DISTANCE' | 'MAXIMUM_HEIGHT' | 'FLIGHT_AREA' | 'LANDING_BATTERY_LEVEL';
    export const Type = {
        VISUAL_DISTANCE: 'VISUAL_DISTANCE' as Type,
        MAXIMUM_HEIGHT: 'MAXIMUM_HEIGHT' as Type,
        FLIGHT_AREA: 'FLIGHT_AREA' as Type,
        LANDING_BATTERY_LEVEL: 'LANDING_BATTERY_LEVEL' as Type
    };
}



