<div class="container-fluid top-buffer">
    <!-- Required Activity -->
    <h5 class="section-heading">Required Activity</h5>
    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Activity Status</div>
        </div>
        <div class="col-xs-6">
            {{ activity.status | formatMaintenanceActivityStatus }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Description of required Maintenance</div>
        </div>
        <div class="col-xs-6">
            {{ activity.requiredMaintenanceDescription }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">
                Assigned Maintenance Person or external maintenance organisation
            </div>
        </div>

        <ng-container *ngIf="!showingReassign">
            <div class="col-xs-6">
                <div class="display-label d-flex vertical-align">
                    <div *ngIf="maintainerId">
                        {{ activity.maintainer | formatPerson }}
                    </div>
                    <div *ngIf="!maintainerId">
                        {{ activity.maintenanceOrganisation }}
                    </div>
                    <button
                        type="button"
                        *ngIf="
                            isMaintenanceController &&
                            activity.status == 'ASSIGNED'
                        "
                        class="btn btn-tertiary"
                        (click)="toggleReassign()"
                    >
                        <span class="fal fa-pencil-alt"></span>
                    </button>
                </div>
            </div>
            <div class="col-xs-3"></div>
        </ng-container>

        <ng-container *ngIf="showingReassign">
            <div class="col-xs-3">
                <label [ngClass]="{ readonly: working }">
                    Assign Maintenance Person
                </label>
                <ng-select
                    [searchFn]="personSearch"
                    [(ngModel)]="maintainerId"
                    bindValue="id"
                    placeholder="Select the Person to complete the repair"
                    [items]="personnel$ | async"
                    [readonly]="working"
                    [clearable]="true"
                    appendTo="body"
                >
                    <ng-template ng-option-tmp let-item="item">
                        {{ item | formatPerson: true }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        {{ item | formatPerson: true }}
                    </ng-template>
                </ng-select>
            </div>

            <div class="col-xs-3">
                <label class="display-label" [ngClass]="{ readonly: working }">
                    or External Maintenance Organisation
                </label>
                <textarea
                    class="form-control"
                    [(ngModel)]="maintenanceOrganisation"
                    rows="1"
                    [readonly]="working"
                >
                </textarea>
            </div>
            <div class="col-xs-3">
                <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    (click)="reassignMaintenance()"
                    *ngIf="maintenanceOrganisation || maintainerId"
                >
                    Update
                </button>
                <button
                    class="btn btn-secondary btn-sm"
                    type="button"
                    (click)="toggleReassign()"
                >
                    Cancel
                </button>
            </div>
        </ng-container>
    </div>

    <div class="form-group input-content">
        <div class="col-xs-3 text-right">
            <div class="display-label">Scheduled Date</div>
        </div>
        <div class="col-xs-6">
            {{ activity.scheduledDate | formatDate }}
        </div>
        <div class="col-xs-3"></div>
    </div>

    <!-- Activity Details -->
    <div
        *ngIf="activity.status == 'SUBMITTED' || activity.status == 'COMPLETED'"
    >
        <h5 class="section-heading">Activity Details</h5>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <div class="display-label">Maintenance Performed</div>
            </div>
            <div class="col-xs-6">
                {{ activity.maintenancePerformed }}
            </div>
            <div class="col-xs-3"></div>
        </div>

        <div *ngIf="canUseMaintenanceTasks" class="form-group input-content">
            <div class="col-xs-3 text-right">
                <div class="display-label">Maintenance Tasks</div>
            </div>
            <div class="col-xs-6">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 15%">Component</th>
                            <th style="width: 15%">Task</th>
                            <th style="width: 15%">Status</th>
                            <th style="width: 55%">Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let task of activity.tasks">
                            <td>
                                {{ task.component.componentType.name }}
                                {{ task.component.designation }}
                            </td>
                            <td>{{ task.taskType.name }}</td>
                            <td>
                                {{
                                    task.completed == true
                                        ? 'Completed'
                                        : 'Not Completed'
                                }}
                            </td>
                            <td>{{ task.notes }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-xs-3"></div>
        </div>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <div class="display-label">Maintenance Remarks</div>
            </div>
            <div class="col-xs-6">
                {{ activity.maintenanceRemarks }}
            </div>
            <div class="col-xs-3"></div>
        </div>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <div class="display-label">Start Date</div>
            </div>
            <div class="col-xs-6">
                {{ activity.startDate | formatDate }}
            </div>
            <div class="col-xs-3"></div>
        </div>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <div class="display-label">End Time</div>
            </div>
            <div class="col-xs-6">
                {{ activity.endTime | formatDateTime: organisation.timeZone }}
            </div>
            <div class="col-xs-3"></div>
        </div>
        <button
            *ngIf="canEditActivity"
            type="button"
            class="btn btn-default pull-right"
            (click)="editActivity()"
            [disabled]="working"
        >
            Edit Activity
        </button>
    </div>

    <!-- Activity Review -->
    <div
        *ngIf="activity.status == 'COMPLETED' || activity.status == 'SUBMITTED'"
    >
        <h5 class="section-heading">Activity Review</h5>

        <div class="form-group input-content">
            <div class="col-xs-3 text-right">
                <div class="display-label">Attachments</div>
            </div>
            <div class="col-xs-6">
                <attachment-list
                    [attachmentsHandler]="attachmentsHandler"
                    [allowUploads]="
                        canEditActivity &&
                        (overallStatus === 'SUBMITTED_ACTIVITY' ||
                            overallStatus === 'COMPLETED_ACTIVITY')
                    "
                    [allowDelete]="
                        canEditActivity &&
                        (overallStatus === 'SUBMITTED_ACTIVITY' ||
                            overallStatus === 'COMPLETED_ACTIVITY')
                    "
                    [allowEdit]="
                        canEditActivity &&
                        (overallStatus === 'SUBMITTED_ACTIVITY' ||
                            overallStatus === 'COMPLETED_ACTIVITY')
                    "
                    [parentPromiseTracker]="workTracker"
                    permissions
                >
                </attachment-list>
            </div>
            <div class="col-xs-3"></div>
        </div>

        <div
            class="form-group input-content"
            *ngIf="overallStatus === 'COMPLETED_ACTIVITY'"
        >
            <div class="col-xs-3 text-right">
                <div class="display-label">
                    Additional Maintenance Controller Notes
                </div>
            </div>
            <div class="col-xs-6">
                {{ activity.maintenanceControllerNotes }}
            </div>
            <div class="col-xs-3"></div>
        </div>
    </div>
</div>
