import { Component } from '@angular/core';
import {
    CreateFlightLogCollectionSourceCommand,
    DO_NOTHING,
    FlightLogCollectionService,
    FlightLogCollectionSourceDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UpdateFlightLogCollectionSourceCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FlightLogSettingsService } from './flight-log-settings.service';
import { FlightLogCollectionStatusLookup } from './helpers';
import { FlightLogCollectionSourceWithEnabled } from './interfaces';

const ADD_SKYDIO_FIELDS = [
    {
        key: 'username',
        type: 'input',
        props: {
            label: 'Account Name',
            required: true
        }
    },
    {
        key: 'password',
        type: 'input',
        props: {
            label: 'Password',
            required: true,
            type: 'password'
        }
    }
] as FormlyFieldConfig[];

@Component({
    selector: 'skydio-settings',
    template: `<div class="horizontal-container">
        <button
            type="button"
            class="btn-circle btn-link"
            (click)="add()"
            [disabled]="working"
        >
            <span class="fa fa-plus"></span>
        </button>
        <div *ngIf="list$ | async as list" class="vertical-container fill">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 30%">Account Name</th>
                        <th style="width: 20%">Last Completion Time</th>
                        <th style="width: 20%">Last Completion Message</th>
                        <th style="width: 10%">Enabled</th>
                        <th
                            *ngIf="flightLogSettingsService.canUseAlerts && canManageAll"
                            style="width: 10%"
                        >
                            Alerts
                        </th>
                        <th style="width: 10%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let account of list">
                        <td>{{ account.username }}</td>
                        <td>
                            <ng-container
                                *ngIf="account.lastCompletionTime != null"
                            >
                                {{
                                    account.lastCompletionTime | formatDateTime
                                }}
                                -
                            </ng-container>
                            {{ flightLogStatus[account.lastCompletionStatus] }}
                        </td>
                        <td>
                            {{ account | formatFlightLogCompletionMessage }}
                        </td>
                        <td *ngIf="account.collectNow">
                            {{
                                account.collectNowQueue
                                    | formatFlightLogQueuePosition
                            }}
                        </td>
                        <td *ngIf="!account.collectNow">
                            <div mdcSwitch>
                                <div mdcSwitchThumb>
                                    <input
                                        type="checkbox"
                                        mdcSwitchInput
                                        [disabled]="working"
                                        [(ngModel)]="account.enabled"
                                        (change)="updateEnabled(account)"
                                    />
                                </div>
                            </div>
                        </td>
                        <td *ngIf="flightLogSettingsService.canUseAlerts && canManageAll">
                            <flight-log-alert
                                [alertSettings]="account.alertSettings"
                                (showSettings)="
                                    flightLogSettingsService.showAlertSettings(
                                        account
                                    )
                                "
                            ></flight-log-alert>
                        </td>
                        <td class="actions">
                            <button
                                type="button"
                                class="btn btn-tertiary"
                                (click)="collectNow(account)"
                                [disabled]="working || account.collectNow"
                                tooltip="Collect now"
                                placement="left"
                            >
                                <span class="fal fa-sync"></span>
                            </button>
                            <button
                                type="button"
                                class="btn btn-tertiary"
                                (click)="edit(account)"
                                [disabled]="working"
                                tooltip="Edit"
                                placement="left"
                            >
                                <span class="fal fa-pencil-alt"></span>
                            </button>
                            <button
                                class="btn btn-tertiary btn-delete"
                                type="button"
                                (click)="delete(account)"
                                [disabled]="working"
                                tooltip="Delete"
                                placement="left"
                            >
                                <span class="fal fa-trash-alt"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="bottom-buffer fill" *ngIf="list.length === 0">
                <empty componentName="empty-skydio-accounts">
                    <button
                        type="button"
                        class="btn btn-default top-buffer"
                        (click)="add()"
                    >
                        Add Skydio Account
                    </button>
                </empty>
            </div>
        </div>
    </div>`
})
export class SkydioSettings {
    private ngUnsubscribe$ = new Subject<void>();

    list$: Observable<FlightLogCollectionSourceWithEnabled[]>;

    working: boolean;

    collectionFrequency =
        UpdateFlightLogCollectionSourceCommand.CollectionFrequency;

    flightLogStatus = FlightLogCollectionStatusLookup;

    constructor(
        public flightLogSettingsService: FlightLogSettingsService,
        private workTracker: WorkTracker,
        private commonDialoguesService: CommonDialoguesService,
        private flightLogCollectionService: FlightLogCollectionService,
        private logging: FlyFreelyLoggingService
    ) {
        this.list$ =
            this.flightLogSettingsService.flightLogCollectionSourceList$.pipe(
                takeUntil(this.ngUnsubscribe$),
                map(sources =>
                    sources
                        .filter(
                            s =>
                                s.type ===
                                FlightLogCollectionSourceDto.Type.SKYDIO
                        )
                        .map(s => ({
                            ...s,
                            enabled: s.collectionFrequency !== 'NEVER'
                        }))
                )
            );
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    add() {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Collect from Skydio',
                'Save',
                true,
                true,
                ADD_SKYDIO_FIELDS,
                { username: null as string, password: null as string },
                data =>
                    this.flightLogCollectionService
                        .create({
                            organisationId:
                                this.flightLogSettingsService.organisationId,
                            type: CreateFlightLogCollectionSourceCommand.Type
                                .SKYDIO,
                            collectionFrequency:
                                CreateFlightLogCollectionSourceCommand
                                    .CollectionFrequency.DAILY,
                            username: data.username,
                            password: data.password
                        })
                        .toPromise(),
                'modal-task'
            )
            .then(() => this.logging.success('Skydio account setup'))
            .catch(DO_NOTHING);
    }

    collectNow(entry: FlightLogCollectionSourceDto) {
        this.flightLogCollectionService
            .collectNow(entry.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(updated =>
                this.logging.success('Skydio collection scheduled')
            )
            .add(this.workTracker.createTracker());
    }

    edit(entry: FlightLogCollectionSourceDto) {
        this.commonDialoguesService
            .showFormlyDialogue(
                'Update details',
                'Save',
                true,
                true,
                [
                    {
                        key: 'password',
                        type: 'input',
                        props: {
                            label: 'Password',
                            required: false,
                            type: 'password'
                        }
                    },
                    {
                        key: 'enabled',
                        type: 'radio',
                        defaultValue: true,
                        props: {
                            label: 'Enabled',
                            required: true,
                            options: [
                                { value: true, name: 'Enabled' },
                                { value: false, name: 'Disabled' }
                            ]
                        }
                    }
                ],
                {
                    password: null as string,
                    enabled:
                        entry.collectionFrequency ===
                        this.collectionFrequency.DAILY
                },
                data =>
                    this.flightLogCollectionService
                        .updateSource(
                            {
                                collectionFrequency:
                                    data.enabled === true
                                        ? this.collectionFrequency.DAILY
                                        : this.collectionFrequency.NEVER,
                                id: entry.id,
                                password: data.password ?? null
                            },
                            entry.id
                        )
                        .toPromise(),
                'modal-task'
            )
            .then(() => this.logging.success('Skydio account updated'))
            .catch(DO_NOTHING);
    }

    updateEnabled(entry: FlightLogCollectionSourceWithEnabled) {
        this.flightLogCollectionService
            .updateSource(
                {
                    collectionFrequency: entry.enabled
                        ? this.collectionFrequency.DAILY
                        : this.collectionFrequency.NEVER,
                    id: entry.id,
                    password: null
                },
                entry.id
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () =>
                    this.logging.success(
                        'Flight log collection settings updated'
                    ),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating flight log collection settings: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    delete(entry: FlightLogCollectionSourceDto) {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Skydio Collection',
                'Are you sure you want to delete this Skydio collection?',
                'Delete',
                () => Promise.resolve()
            )
            .then(() =>
                this.flightLogCollectionService
                    .deleteSource(entry.id)
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(() =>
                        this.logging.success('Skydio account deleted')
                    )
                    .add(this.workTracker.createTracker())
            )
            .catch(DO_NOTHING);
    }
}
