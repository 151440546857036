import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'mobile-device-warning',
    templateUrl: './mobile-warning.component.html',
    styles: [
        `
            .warning-image {
                margin-top: 20px;
            }
        `
    ]
})
export class MobileDeviceWarningDialogue {
    constructor(private modal: BsModalRef) {}

    close() {
        this.modal.hide();
    }
}
