import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ReportingService } from '../reporting.service';

interface Dashboard {
    render: (config: any) => void;
}

@Component({
    selector: 'report-view',
    templateUrl: './report-view.component.html',
    styles: [
        `
        :host {
            display: block;
            height: 100%;
            max-height: 100%;
            overflow-y: auto;
            padding-top: 50px;
        }

        :host > div {
            margin: 0 45px;
        }
        `
    ]
})
export class ReportViewComponent {
    private ngUnsubscribe$ = new Subject<void>();

    @ViewChildren('dashboard')
    dashboardElements: QueryList<ElementRef<Dashboard>>;

    /**
     * The loaded dashboard web component
     */
    dashboardElement$ = new ReplaySubject<Dashboard>(1);

    name: string;
    parentDashboardId: string;
    dashboardId: string;
    canEdit = true;
    canDelete = false;
    isParent = false;

    constructor(
        public reportingService: ReportingService,
        private toastr: ToastrService,
        private errorHandler: FlyFreelyLoggingService
    ) {
        combineLatest([
            reportingService.currentReport$,
            this.dashboardElement$,
            reportingService.token$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([dashboard, element, token]) => {
                if (dashboard == null) {
                    this.dashboardId = null;
                    this.name = null;
                    this.canEdit = false;
                    this.canDelete = false;
                    this.isParent = false;
                    return;
                }

                this.dashboardId = dashboard.dashboardId;
                this.name = dashboard.metadata.name;
                this.isParent = dashboard.isParent;
                this.parentDashboardId = dashboard.parentDashboardId;
                this.canDelete = dashboard.canDelete;
                this.canEdit = dashboard.canEdit;

                element.render({
                    dashboardId: dashboard.dashboardId,
                    queryEngineEndpoint: this.reportingService
                        .queryEngineEndpoint,
                    identity: async () => token
                });
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngAfterViewInit() {
        if (this.dashboardElements.length === 1) {
            this.dashboardElement$.next(
                this.dashboardElements.first.nativeElement
            );
            return;
        }
        this.dashboardElements.changes
            .pipe(
                filter(x => x.length > 0),
                map(x => x.first.nativeElement),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(element => this.dashboardElement$.next(element));
    }

    updateName(newName: string) {
        this.reportingService.updateName(this.dashboardId, newName);
    }

    copyReport() {
        this.reportingService
            .createDashboard(
                this.isParent ? this.name : `Copy of ${this.name}`,
                this.isParent ? this.dashboardId : this.parentDashboardId,
                this.dashboardId
            )
            .subscribe({
                complete: () => this.toastr.success('Report copied'),
                error: error =>
                    this.errorHandler.error(error, 'Error saving dashboard')
            });
    }

    deleteReport() {
        this.reportingService.deleteReport(this.dashboardId);
    }
}
