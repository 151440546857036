<enhanced-help
    componentName="referrals"
    [compact]="true"    
>
</enhanced-help>
<div class="modal-body-content container-scroll contents">
    <referral-source
        *ngFor="let referralSummary of referralSummaries"
        [referralSummary]="referralSummary"
    ></referral-source>
</div>
