/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FlightConformanceRuleResultDto } from './flightConformanceRuleResultDto';


/**
 * 
 */
export interface FlightConformanceResultDto { 
    id: number;
    flightLogFileId: number;
    policyId: number;
    pilotId?: number;
    flightId?: number;
    ruleResultList: Array<FlightConformanceRuleResultDto>;
    status: FlightConformanceResultDto.Status;
    /**
     * When the preliminary report is scheduled to be sent
     */
    sendTime?: string;
}
export namespace FlightConformanceResultDto {
    export type Status = 'NO_POLICY' | 'NO_BREACHES' | 'PRELIMINARY' | 'SENT' | 'CANCELLED' | 'COMPLETED' | 'FINALISED';
    export const Status = {
        NO_POLICY: 'NO_POLICY' as Status,
        NO_BREACHES: 'NO_BREACHES' as Status,
        PRELIMINARY: 'PRELIMINARY' as Status,
        SENT: 'SENT' as Status,
        CANCELLED: 'CANCELLED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        FINALISED: 'FINALISED' as Status
    };
}



