import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationSunriseSunset } from 'libs/airspace/src/lib/airspace-check/airspace-check.service';
import { FlyFreelyConstants } from '../constants';
import {
    AerodromeStatus,
    AirspaceCheckCommand,
    AirspaceCheckDto,
    PrdStatus
} from '../model/api';
import { ValidDatasetsDto } from '../model/api/validDatasetsDto';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class GeospatialService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findPrdStatus(
        prdList: string[],
        startTime: string,
        endTime: string,
        jurisdiction: string
    ) {
        return this.http.get<PrdStatus[]>(
            `${this.baseUrl}/webapi/geospatial/airspace/prdStatus`,
            {
                params: httpParamSerializer({
                    prd: prdList,
                    startTime,
                    endTime,
                    jurisdiction
                })
            }
        );
    }

    findAerodromeStatus(
        aerodromeList: string[],
        startTime: string,
        endTime: string,
        jurisdiction: string
    ) {
        return this.http.get<AerodromeStatus[]>(
            `${this.baseUrl}/webapi/geospatial/airspace/aerodromeStatus`,
            {
                params: httpParamSerializer({
                    aerodrome: aerodromeList,
                    startTime,
                    endTime,
                    jurisdiction
                })
            }
        );
    }

    findAlternativeNonMissionAccessToken() {
        return this.http.post<{
            accessToken: string;
        }>(`${this.baseUrl}/webapi/geospatial/airspace/token`, {});
    }

    checkAirspace(command: AirspaceCheckCommand) {
        return this.http.post<AirspaceCheckDto>(
            `${this.baseUrl}/webapi/geospatial/airspace/check`,
            command
        );
    }

    getSunriseSunset(latitude: any, longitude: any, date: string) {
        return this.http.get<LocationSunriseSunset>(
            `${this.baseUrl}/webapi/geospatial/sunriseSunset`,
            {
                params: httpParamSerializer({
                    latitude,
                    longitude,
                    date
                })
            }
        );
    }

    findValidDatasets(dateTime: string) {
        return this.http.get<ValidDatasetsDto>(
            `${this.baseUrl}/webapi/geospatial/airspace/validDatasets`,
            {
                params: httpParamSerializer({ dateTime })
            }
        );
    }
}
