import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'authorisation-agreement',
    template: `
        <div class="modal-header no-icon">
            <h3 class="modal-title pull-left">
                CASA Airspace Authorisation Declaration
            </h3>
            <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="close()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body inner-container-and-footer">
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="acknowledgeCrp"
                        [(ngModel)]="acknowledgeCrp"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                    I am authorised to make this application and hold the role
                    of Chief Remote Pilot for this ReOC.
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="acknowledgeCrp"
                        [(ngModel)]="acknowledgeWeight"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                    I acknowledge I must not submit an airspace authorisation
                    application for an RPA with a maximum take-off weight of
                    25kg or more.
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="acknowledgeCrp"
                        [(ngModel)]="acknowledgeTrue"
                    />
                    <span class="cr"
                        ><i class="cr-icon glyphicon glyphicon-ok"></i>
                    </span>
                    I declare that all statements in this application are true
                    and correct in every particular and that I have read and
                    understood all provisions of the Civil Aviation Safety
                    Regulations 1998 which are relevant to this application.
                </label>
            </div>
            <div class="action-container" style="text-align: center">
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="acknowledge()"
                    [disabled]="
                        !acknowledgeCrp ||
                        !acknowledgeWeight ||
                        !acknowledgeTrue
                    "
                >
                    Submit
                </button>
            </div>
        </div>
    `
})
export class AirspaceAuthorisationAcknowledgementDialogue {
    @Output() acknowledged = new EventEmitter<void>();
    acknowledgeCrp: boolean;
    acknowledgeWeight: boolean;
    acknowledgeTrue: boolean;

    constructor(private modal: BsModalRef) {}

    acknowledge() {
        this.acknowledged.emit();
        this.modal.hide();
    }

    close() {
        this.modal.hide();
    }
}
