/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.25-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnlistedBatterySetDto } from './unlistedBatterySetDto';
import { UpdateSortieCrewCommand } from './updateSortieCrewCommand';
import { UnlistedRpaDto } from './unlistedRpaDto';
import { UnlistedPersonDto } from './unlistedPersonDto';
import { Point } from 'geojson';
import { UnlistedEquipmentDto } from './unlistedEquipmentDto';


/**
 * The flight that the incident relates to
 */
export interface SortieDto { 
    id?: number;
    /**
     * The number of the sortie in the mission
     */
    number: number;
    /**
     * A name or description of the sortie purpose
     */
    name?: string;
    /**
     * The effective flight duration in seconds
     */
    duration?: number;
    /**
     * The manually entered flight duration in seconds
     */
    manualDuration?: number;
    /**
     * Which time source to use
     */
    durationSource: SortieDto.DurationSource;
    /**
     * The status of the sortie within its workflow
     */
    status: SortieDto.Status;
    /**
     * ${swagger.messages.manualStartTime}
     */
    manualStartTime?: string;
    /**
     * ${swagger.messages.startTime}
     */
    startTime?: string;
    /**
     * ${swagger.messages.endTime}
     */
    endTime?: string;
    /**
     * The reason the sortie was ended
     */
    reason?: string;
    /**
     * The pilot who flew this sortie
     */
    pilotId?: number;
    /**
     * The aircraft used in this sortie
     */
    craftId?: number;
    /**
     * The battery set used in this sortie
     */
    batterySetId?: number;
    manualTakeoffPoint?: Point;
    manualLandingPoint?: Point;
    takeoffPoint?: Point;
    landingPoint?: Point;
    equipmentIds?: Array<number>;
    /**
     * The flight notes
     */
    notes?: string;
    /**
     * The list of FlightInterruptions
     */
    interruptions?: Array<SortieDto.Interruptions>;
    /**
     * The flight issue status
     */
    flightIssueStatus?: SortieDto.FlightIssueStatus;
    unlistedRpa?: UnlistedRpaDto;
    unlistedBatterySet?: UnlistedBatterySetDto;
    unlistedPilot?: UnlistedPersonDto;
    unlistedEquipment?: Array<UnlistedEquipmentDto>;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    crew?: Array<UpdateSortieCrewCommand>;
    /**
     * ${swagger.Generic.recordedStartTime}
     */
    recordedStartTime?: string;
    /**
     * ${swagger.Generic.recordedEndTime}
     */
    recordedEndTime?: string;
    /**
     * The unsaved ID from the field app
     */
    unsavedId?: string;
}
export namespace SortieDto {
    export type DurationSource = 'TIMER' | 'MANUAL' | 'LOGS';
    export const DurationSource = {
        TIMER: 'TIMER' as DurationSource,
        MANUAL: 'MANUAL' as DurationSource,
        LOGS: 'LOGS' as DurationSource
    };
    export type Status = 'STARTED' | 'COMPLETED' | 'ABORTED' | 'NOT_STARTED';
    export const Status = {
        STARTED: 'STARTED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        ABORTED: 'ABORTED' as Status,
        NOT_STARTED: 'NOT_STARTED' as Status
    };
    export type Interruptions = 'BIRD_ACTIVITY' | 'PROJECTILES' | 'AIRCRAFT_PROXIMITY_GROUNDING' | 'PEOPLE_IN_AREA' | 'HARD_LANDING' | 'CHALLENGED_BY_AUTHORITIES_OR_PEOPLE' | 'CHANGE_IN_WEATHER' | 'OTHER' | 'CHANGE_IN_VISIBILITY';
    export const Interruptions = {
        BIRD_ACTIVITY: 'BIRD_ACTIVITY' as Interruptions,
        PROJECTILES: 'PROJECTILES' as Interruptions,
        AIRCRAFT_PROXIMITY_GROUNDING: 'AIRCRAFT_PROXIMITY_GROUNDING' as Interruptions,
        PEOPLE_IN_AREA: 'PEOPLE_IN_AREA' as Interruptions,
        HARD_LANDING: 'HARD_LANDING' as Interruptions,
        CHALLENGED_BY_AUTHORITIES_OR_PEOPLE: 'CHALLENGED_BY_AUTHORITIES_OR_PEOPLE' as Interruptions,
        CHANGE_IN_WEATHER: 'CHANGE_IN_WEATHER' as Interruptions,
        OTHER: 'OTHER' as Interruptions,
        CHANGE_IN_VISIBILITY: 'CHANGE_IN_VISIBILITY' as Interruptions
    };
    export type FlightIssueStatus = 'NO_ISSUES' | 'INTERRUPTIONS' | 'INCIDENTS_OR_ACCIDENTS';
    export const FlightIssueStatus = {
        NO_ISSUES: 'NO_ISSUES' as FlightIssueStatus,
        INTERRUPTIONS: 'INTERRUPTIONS' as FlightIssueStatus,
        INCIDENTS_OR_ACCIDENTS: 'INCIDENTS_OR_ACCIDENTS' as FlightIssueStatus
    };
}



