<div class="container-with-footer">
    <div class="checkbox" *ngFor="let item of jurisdictions">
        <label>
            <input
                type="checkbox"
                [(checklist)]="activeJurisdictionIds"
                [checklistValue]="item.id"
            />
            <span class="cr"
            ><i class="cr-icon glyphicon glyphicon-ok"></i>
                </span>
            {{ item.name }}
        </label>
    </div>
    <loading-indicator [isLoading]="working"></loading-indicator>
    <div>
        <button
            type="button"
            (click)="save()"
            [disabled]="working"
            [ngClass]="buttonClasses"
            class="btn btn-primary pull-right"
        >
            Save
        </button>
    </div>
</div>
