<ng-container screenAnalytics="general-task-add">
    <div class="modal-header no-icon">
        <h3 class="modal-title">New Task</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <button
            type="button"
            aria-label="Close"
            class="close"
            (click)="onCancel()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-horizontal container-fluid" [formGroup]="taskNewForm">
            <div class="form-group input-content">
                <div class="col-xs-3">
                    <label class="control-label">
                        <field-validation-indicator
                            [control]="taskNewForm.controls.taskName"
                            [noun]="'field'"
                            [placement]="'left'"
                        ></field-validation-indicator>
                        Task Name
                    </label>
                </div>
                <div class="col-xs-9">
                    <input
                        class="form-control"
                        formControlName="taskName"
                        placeholder="Type a name for this Task"
                    />
                    <validation-messages
                        validatorFormControlName="taskName"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help componentName="taskName"></enhanced-help>
            <div class="form-group input-content">
                <div class="col-xs-3">
                    <label class="control-label">
                        <field-validation-indicator
                            [control]="taskNewForm.controls.details"
                            [noun]="'field'"
                            [placement]="'left'"
                        ></field-validation-indicator>
                        Details
                    </label>
                </div>
                <div class="col-xs-9">
                    <textarea
                        class="form-control"
                        formControlName="details"
                    ></textarea>
                    <validation-messages
                        validatorFormControlName="details"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help componentName="taskDetails"></enhanced-help>
            <div class="form-group input-content">
                <div class="col-xs-3">
                    <label class="control-label">
                        <field-validation-indicator
                            [control]="taskNewForm.controls.dueDate"
                            [noun]="'field'"
                            [placement]="'left'"
                        ></field-validation-indicator>
                        Due Date
                    </label>
                </div>
                <div class="col-xs-9">
                    <div class="input-group">
                        <input
                            class="form-control"
                            #datepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="dueDate"
                        />
                        <span
                            class="input-group-addon btn-default"
                            (click)="datepicker.toggle()"
                            [attr.aria-expanded]="datepicker.isOpen"
                        >
                            <i
                                class="glyphicon glyphicon-calendar"
                                style="cursor: pointer"
                            ></i>
                        </span>
                    </div>
                    <validation-messages
                        validatorFormControlName="dueDate"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help componentName="taskDueDate"></enhanced-help>
            <div class="form-group input-content">
                <div class="col-xs-3">
                    <label class="control-label">
                        <field-validation-indicator
                            [control]="taskNewForm.controls.taskPriority"
                            [noun]="'field'"
                            [placement]="'left'"
                        ></field-validation-indicator>
                        Priority
                    </label>
                </div>
                <div class="col-xs-9">
                    <div class="input-group" style="width: 100%">
                        <ng-select
                            [items]="priorityOptions"
                            bindValue="value"
                            formControlName="taskPriority"
                            [clearable]="false"
                        ></ng-select>
                    </div>
                    <validation-messages
                        validatorFormControlName="Assignee"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help componentName="taskPriority"></enhanced-help>
            <div class="form-group input-content">
                <div class="col-xs-3">
                    <label class="control-label">
                        <field-validation-indicator
                            [control]="taskNewForm.controls.assignee"
                            [noun]="'field'"
                            [placement]="'left'"
                        ></field-validation-indicator>
                        Assignee
                    </label>
                </div>
                <div class="col-xs-9">
                    <div class="input-group" style="width: 100%">
                        <ng-select
                            [items]="personList$ | async"
                            bindValue="id"
                            formControlName="assignee"
                            [searchFn]="personSearch"
                        >
                            <ng-template ng-option-tmp let-item="item">
                                <person-avatar
                                    [person]="item"
                                    [managingOrganisationId]="organisation.id"
                                    [width]="25"
                                    [height]="25"
                                >
                                </person-avatar>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <person-avatar
                                    [person]="item"
                                    [managingOrganisationId]="organisation.id"
                                    [width]="20"
                                    [height]="20"
                                >
                                </person-avatar>
                            </ng-template>
                        </ng-select>
                    </div>
                    <validation-messages
                        validatorFormControlName="Assignee"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help componentName="taskAssignee"></enhanced-help>
            <div class="form-group input-content">
                <div class="col-xs-3">
                    <label class="control-label">
                        <field-validation-indicator
                            [control]="taskNewForm.controls.reporter"
                            [noun]="'field'"
                            [placement]="'left'"
                        ></field-validation-indicator>
                        Reporter
                    </label>
                </div>
                <div class="col-xs-9">
                    <div class="input-group" style="width: 100%">
                        <ng-select
                            [disabled]="true"
                            [items]="personList$ | async"
                            formControlName="reporter"
                            [searchFn]="personSearch"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                <person-avatar
                                    [person]="item"
                                    [managingOrganisationId]="organisation.id"
                                    [width]="20"
                                    [height]="20"
                                >
                                </person-avatar>
                            </ng-template>
                        </ng-select>
                    </div>
                    <validation-messages
                        validatorFormControlName="reporter"
                    ></validation-messages>
                </div>
            </div>
            <enhanced-help componentName="taskReporter"></enhanced-help>
            <div class="form-group input-content top-buffer">
                <label class="control-label"> Links & Attachments </label>
                <div class="horizontal-container top-buffer">
                    <div class="fcol-6">
                        <app-link-manager
                            *ngIf="taskId"
                            [sectionId]="taskId"
                            [linkAction]="LinkAction.generalTask"
                        >
                        </app-link-manager>
                    </div>
                    <div class="fcol-1"></div>
                    <div class="fcol-5 d-flex align-center">
                        <attachment-list
                            [attachmentsHandler]="attachmentsHandler"
                            [allowUploads]="true"
                            [newTemplate]="true"
                            [allowDelete]="true"
                            [allowEdit]="true"
                        >
                        </attachment-list>
                    </div>
                </div>
            </div>
            <enhanced-help componentName="LinksAttachments"></enhanced-help>
            <div class="form-group input-content">
                <div class="col-xs-12" style="margin-bottom: 10px">
                    <label class="control-label"> Comments </label>
                </div>
                <div class="col-xs-12" style="margin: 10px">
                    <ui-comments
                        *ngIf="organisation && taskId"
                        [hasSave]="false"
                        [comments]="comments"
                        [organisationId]="organisation.id"
                        (createComment)="onCreateComment($event)"
                    >
                    </ui-comments>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" type="button" (click)="onCancel()">
            Cancel
        </button>
        <button class="btn btn-primary" type="button" (click)="onAddTask()">
            + Add Task
        </button>
    </div>
</ng-container>
