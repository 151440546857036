import { Pipe, PipeTransform } from '@angular/core';
import { IncidentReportsService } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '../helpers';

@Pipe({ name: 'formatIncidentReportStatus' })
export class FormatIncidentReportStatusPipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor(incidentReportService: IncidentReportsService) {
        this.transform = setupStatusFormatter(
            incidentReportService.getStatuses()
        );
    }
}
