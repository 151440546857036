/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.13.4-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Valid check on authority Register Condition. The unique ID is the id+conditionType+entity+entityId
 */
export interface AuthorityConditionDto { 
    /**
     * The ID of the authority check. Null if not a stored check.
     */
    id?: number;
    /**
     * The value used to order the sections
     */
    order: number;
    /**
     * The description of how/why this check is important, or how to perform it
     */
    description?: string;
    /**
     * The name of the entity that is being checked against or the manual process that is being performed
     */
    name?: string;
    enabled: boolean;
    entity?: string;
    entityId?: number;
    /**
     * The type of check this is
     */
    conditionType: AuthorityConditionDto.ConditionType;
}
export namespace AuthorityConditionDto {
    export type ConditionType = 'ACTION' | 'INTERNAL_LOOKUP' | 'ATTACHMENT_ACKNOWLEDGEMENT' | 'CURRENCY';
    export const ConditionType = {
        ACTION: 'ACTION' as ConditionType,
        INTERNAL_LOOKUP: 'INTERNAL_LOOKUP' as ConditionType,
        ATTACHMENT_ACKNOWLEDGEMENT: 'ATTACHMENT_ACKNOWLEDGEMENT' as ConditionType,
        CURRENCY: 'CURRENCY' as ConditionType
    };
}



