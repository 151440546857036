import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { OrganisationJurisdictions } from './organisation-jurisdictions/organisation-jurisdictions.component';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { SharedUiModule } from '@flyfreely-portal-ui/ui';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ValidationModule,
        LoadingIndicatorModule,
        SharedUiModule,
        ReactiveFormsModule
    ],
    declarations: [OrganisationJurisdictions],
    exports: [OrganisationJurisdictions]
})
export class JurisdictionsModule {}
