import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
    selector: 'view-raw-notam-dialogue',
    template: `
        <div class="container-with-footer view-raw-notam-container">
            <div class="vertical-container fill">
                <h4 class="top-buffer bottom-buffer view-raw-title">
                    {{ notamTitle }}
                </h4>
                <div class="container-scroll">
                    <pre [innerHTML]="notamContent"></pre>
                </div>
            </div>

            <div class="action-container bottom-buffer">
                <button
                    type="button"
                    class="btn btn-primary btn-view-raw-notam"
                    (click)="done.emit()"
                >
                    Done
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./view-raw-notam-dialogue.scss']
})
export class ViewRawNotamDialogueComponent {
    @Input() notamTitle: string;
    @Input() notamContent: string;
    @Output() done = new EventEmitter<void>();
}
