import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { BulkUploadsModule } from 'libs/bulk-uploads/src/lib/bulk-uploads.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableModule } from 'ngx-easy-table';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { DetailedFlightHistory } from './detailed-flight-history/detailed-flight-history.component';
import { FlightHistoryEdit } from './detailed-flight-history/flight-history-edit.component';
import { LoggedFlights } from './logged-flights/logged-flights.component';
import { FlightHistorySummary } from './summary/flight-history-summary.component';
import { PilotFlightHistorySummary } from './summary/pilot-flight-history-summary.component';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { FlightHistoryDialoguesService } from './flight-history-dialogues.service';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedUiModule,
        ResourceUiModule,
        DocumentationModule,
        PaginationModule,
        AttachmentsModule,
        ValidationModule,
        BsDatepickerModule,
        TooltipModule,
        ToastrModule,
        NgSelectModule,
        NgxLoadingModule,
        ButtonsModule,
        BulkUploadsModule,
        TableModule,
        FlyFreelyTableModule,
        MaterialModule,
        EnhancedHelpModule,
        AnalyticsModule,
        LoadingIndicatorModule
    ],
    providers: [FlightHistoryDialoguesService],
    declarations: [
        FlightHistorySummary,
        LoggedFlights,
        DetailedFlightHistory,
        FlightHistoryEdit,
        PilotFlightHistorySummary
    ],
    exports: [
        FlightHistorySummary,
        LoggedFlights,
        DetailedFlightHistory,
        FlightHistoryEdit,
        PilotFlightHistorySummary
    ]
})
export class FlightHistoryModule {}
