import { Injectable } from '@angular/core';
import {
    CraftAuthorityDto,
    FlyFreelyError,
    GqlSortField,
    RpaAuthorityService,
    SimpleAuthorityTypeDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { ColumnSortPreferences } from '@flyfreely-portal-ui/flyfreely-table';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface WidgetRpaAuthority {
    authorityTypeName: string;
    rpaName: string;
    rpaId: number;
    authorityId: number;
    identifier: string;
    startDate: string;
    expiryDate: string;
    status: CraftAuthorityDto.ExpiryStatus;
    authority: CraftAuthorityDto;
    authorityType: SimpleAuthorityTypeDto;
}

@Injectable()
export class RpaAuthoritiesWidgetDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private authoritiesSource = new ReplaySubject<WidgetRpaAuthority[]>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);

    working$ = this.workingSource.asObservable();
    authorities$ = this.authoritiesSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private rpaAuthorityService: RpaAuthorityService) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.authoritiesSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findAuthorities(
        page: number,
        limit: number,
        organisationId: number,
        rpaId?: number,
        status?: CraftAuthorityDto.ExpiryStatus[],
        sorting?: ColumnSortPreferences
    ) {
        const sortFields: GqlSortField[] = [
            {
                field: sorting?.column ?? 'startDate',
                order: sorting?.ascending ? 'ASC' : 'DESC'
            }
        ];
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const doneWorking = this.workTracker.createTracker();

        this.rpaAuthorityService
            .findRpaAuthorities(
                organisationId,
                page,
                limit,
                status,
                rpaId,
                sortFields
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: authorities => {
                    const widgetAuthorities: WidgetRpaAuthority[] = authorities.results.map(
                        (authority, i) => ({
                            authorityTypeName: authority.authorityType.name,
                            identifier: authority.identifier,
                            startDate: authority.startDate,
                            expiryDate: authority.expiryDate,
                            status: authority.expiryStatus,
                            authority: {
                                ...authority
                            },
                            authorityType: authority.authorityType,
                            rpaId: authority.rpa.id,
                            rpaName: authority.rpa.nickname,
                            authorityId: authority.id
                        })
                    );
                    this.totalItemsSource.next(authorities.count);
                    this.currentPageSource.next(page);
                    this.authoritiesSource.next(widgetAuthorities);
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    console.error(error);
                    this.authoritiesSource.next([]);
                    this.totalItemsSource.next(0);
                    this.currentPageSource.next(0);
                    doneWorking();
                }
            });
    }
}
