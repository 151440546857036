import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import '../../../../../setup-sentry';
import { SessionExpired, Unauthorised } from './errors';

const IGNORED_ERRORS = [SessionExpired, Unauthorised];

@Injectable({
    providedIn: 'root'
})
export class FlyFreelyErrorHandler implements ErrorHandler {
    handleError(error: any) {
        if (
            !(
                IGNORED_ERRORS.findIndex(e => error instanceof e) !== -1 ||
                IGNORED_ERRORS.findIndex(
                    e => error.originalError instanceof e
                ) !== -1
            )
        ) {
            // @ts-ignore - type sense not available
            if (window.__DEV__) {
                window.console.error(error.originalError || error);
            }
            Sentry.captureException(error.originalError || error);
        }
    }

    warning(message: any, extra: Record<string, unknown> = undefined) {
        // @ts-ignore - type sense not available
        if (window.__DEV__) {
            window.console.warn(message);
        }
        Sentry.captureMessage(message, { level: 'warning', extra });
    }

    logErrorMessage(message: any, extra: Record<string, unknown> = undefined) {
        // @ts-ignore - type sense not available
        if (window.__DEV__) {
            window.console.error(message);
        }
        Sentry.captureMessage(message, { level: 'error', extra });
    }
}

export const flyFreelyErrorHandlerProvider = {
    provide: ErrorHandler,
    useClass: FlyFreelyErrorHandler
};
