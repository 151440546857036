import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    BatteryDto,
    BatterySetDto,
    CraftDto,
    EquipmentDto,
    FlyFreelyConstants
} from '@flyfreely-portal-ui/flyfreely';
import { FormatResourceStatusPipe } from '../pipes/formatResourceStatus.pipe';
import { FormatResourceStatusIconPipe } from '../pipes/formatResourceStatusIcon.pipe';

enum type {
    MISSION = 'Mission',
    RPA = 'RPA',
    BATTERY = 'Battery',
    BATTERY_SET = 'Battery Set',
    EQUIPMENT = 'Equipment'
}

@Component({
    selector: 'status-icon',
    templateUrl: './status-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusIconComponent {
    @Input() rpa: CraftDto;
    @Input() battery: BatteryDto;
    @Input() batterySet: BatterySetDto;
    @Input() equipment: EquipmentDto;

    @Input() clickable = true;

    @Output() nameClicked = new EventEmitter<void>();

    type: type;
    statusColour: string;
    name: string;
    toolTipText: string;

    isDummy: boolean;

    dummySource: string;

    constructor(
        private constants: FlyFreelyConstants,
        private resourceStatusIconPipe: FormatResourceStatusIconPipe,
        private resourceStatusPipe: FormatResourceStatusPipe
    ) {
        this.dummySource = `${this.constants.IMG_URL}/icons/dummy-icon.png`;
    }

    ngOnChanges() {
        this.findType();
    }

    findType() {
        if (this.rpa != null) {
            this.type = type.RPA;
            this.isDummy = this.rpa.isDummy ?? false;
        } else if (this.battery != null) {
            this.type = type.BATTERY;
            this.isDummy = this.battery.isDummy ?? false;
        } else if (this.batterySet != null) {
            this.type = type.BATTERY_SET;
            this.isDummy = this.batterySet.isDummy ?? false;
        } else if (this.equipment != null) {
            this.type = type.EQUIPMENT;
            this.isDummy = this.equipment.isDummy ?? false;
        }
        this.findDetails();
    }

    findDetails() {
        switch (this.type) {
            case type.RPA:
                this.name = this.rpa.nickname;
                this.statusColour = `status-icon-${this.resourceStatusIconPipe.findStatusColour(
                    this.rpa.status
                )}`;
                this.toolTipText = this.resourceStatusPipe.transform(
                    this.rpa.status
                );
                break;

            case type.BATTERY:
                this.name = this.battery.name;
                this.statusColour = `status-icon-${this.resourceStatusIconPipe.findStatusColour(
                    this.battery.status
                )}`;
                this.toolTipText = this.resourceStatusPipe.transform(
                    this.battery.status
                );
                break;

            case type.BATTERY_SET:
                this.name = this.batterySet.name;
                this.statusColour = `status-icon-${this.resourceStatusIconPipe.findStatusColour(
                    this.batterySet.status
                )}`;
                this.toolTipText = this.resourceStatusPipe.transform(
                    this.batterySet.status
                );
                break;

            case type.EQUIPMENT:
                this.name = this.equipment.name;
                this.statusColour = `status-icon-${this.resourceStatusIconPipe.findStatusColour(
                    this.equipment.status
                )}`;
                this.toolTipText = this.resourceStatusPipe.transform(
                    this.equipment.status
                );
                break;

            default:
                break;
        }
        if (this.isDummy === true) {
            this.statusColour = `${this.statusColour}-dummy`;
            const toolTip = `(Dummy) ${this.toolTipText}`;
            this.toolTipText = toolTip;
        }
    }
}
