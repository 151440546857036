import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';
import { NgSelectModule } from '@ng-select/ng-select';

import { RouterModule } from '@angular/router';
import { CardModule } from '@flyfreely-portal-ui/card';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { FeaturesModule } from '@flyfreely-portal-ui/features';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { WorkflowModule } from 'libs/workflows/src/lib/workflows.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { AirworthnessNoticesDialogue } from './airworthness-notice/airworthness-notices-dialogue.component';
import { AirworthnessNoticesEditDialogue } from './airworthness-notice/airworthness-notices-edit-dialogue/airworthness-notices-edit-dialogue.component';
import { AirworthinessNoticesDialogueService } from './airworthness-notice/airworthness-notices.service';
import { NoticeCardComponent } from './airworthness-notice/expandable-card/notice-card.component';
import { LinkNoticeToMaintenanceComponent } from './airworthness-notice/link-notice-to-maintenance-dialogue/link-notice-to-maintenance.component';
import { NoticesMaintenanceRequestList } from './airworthness-notice/notices-maintenance-request-list/notices-maintenance-request-list.component';
import { BulkMaintenance } from './bulk/bulk-maintenance-dialogue.component';
import { CompletedMaintenanceActivityEditDialogue } from './completed-maintenance-activity-edit-dialogue/completed-maintenance-activity-edit-dialogue.component';
import { TestFlightDetailsComponent } from './flight-test/flight-test-details/test-flight-details.component';
import { LinkTestFlightComponent } from './flight-test/link-flight-test/link-flight-test.component';
import { FormatInServiceTimePipe } from './formatInServiceTime.pipe';
import { ComponentServiceTime } from './history/component-service-time/component-service-time.component';
import { MaintenanceHistory } from './history/maintenance-history.component';
import { MaintenanceActivityDetails } from './maintenance-activity-details/maintenance-activity-details.component';
import { MaintenanceActivityEdit } from './maintenance-activity-edit/maintenance-activity-edit.component';
import { MaintenanceDetailsDialogue } from './maintenance-details-dialogue/maintenance-details-dialogue.component';
import { MaintenanceDialogues } from './maintenance-dialogues.service';
import { MaintenanceReportsDialogue } from './maintenance-reports-dialogue/maintenance-reports-dialogue.component';
import { MaintenanceRequestDetails } from './maintenance-request-details/maintenance-request-details.component';
import { MaintenanceRequestEdit } from './maintenance-request-edit/maintenance-request-edit.component';
import { MaintenanceScheduleConfig } from './maintenance-schedule-config/maintenance-schedule-config.component';
import { MaintenanceScheduleTasksEdit } from './maintenance-schedule-tasks/maintenance-schedule-tasks-edit.component';
import { MaintenanceScheduleDialogue } from './maintenance-schedule/maintenance-schedule-dialogue.component';
import { MaintenanceScheduleTasks } from './maintenance-schedule/maintenance-schedule-tasks.component';
import { MaintenanceScheduleTriggers } from './maintenance-schedule/maintenance-schedule-triggers.component';
import { MaintenanceTaskEdit } from './maintenance-tasks/maintenance-task-edit.component';
import { MaintenanceTaskList } from './maintenance-tasks/maintenance-task-list.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedUiModule,
        ToastrModule,
        TabsModule,
        ButtonsModule,
        NgSelectModule,
        BsDatepickerModule,
        TimepickerModule,
        PaginationModule,
        FlyFreelyTableModule,
        TooltipModule,
        NgSelectModule,
        ValidationModule,
        AttachmentsModule,
        LoadingIndicatorModule,
        NgxLoadingModule,
        EnhancedHelpModule,
        AnalyticsModule,
        EditableModule,
        WorkflowModule,
        ResourceUiModule,
        RouterModule.forChild([]),
        CardModule,
        FeaturesModule
    ],
    declarations: [
        MaintenanceHistory,
        MaintenanceDetailsDialogue,
        MaintenanceRequestEdit,
        MaintenanceRequestDetails,
        MaintenanceActivityEdit,
        MaintenanceActivityDetails,
        MaintenanceTaskList,
        MaintenanceTaskEdit,
        BulkMaintenance,
        TestFlightDetailsComponent,
        ComponentServiceTime,
        MaintenanceScheduleDialogue,
        MaintenanceScheduleTasks,
        FormatInServiceTimePipe,
        MaintenanceScheduleConfig,
        MaintenanceScheduleTasksEdit,
        MaintenanceScheduleTriggers,
        CompletedMaintenanceActivityEditDialogue,
        LinkTestFlightComponent,
        MaintenanceReportsDialogue,
        AirworthnessNoticesDialogue,
        NoticeCardComponent,
        AirworthnessNoticesEditDialogue,
        NoticesMaintenanceRequestList,
        LinkNoticeToMaintenanceComponent
    ],
    providers: [MaintenanceDialogues, AirworthinessNoticesDialogueService],
    entryComponents: [MaintenanceDetailsDialogue, BulkMaintenance],
    exports: [
        MaintenanceHistory,
        ComponentServiceTime,
        MaintenanceScheduleConfig
    ]
})
export class MaintenanceModule {}
