import { Injectable, Provider } from '@angular/core';
import {
    AssignMaintenanceScheduleCommand,
    EquipmentService,
    MaintenanceScheduleAssignmentDto
} from '@flyfreely-portal-ui/flyfreely';
import { ResourceMaintenanceService } from 'libs/maintenance/src/lib/resource-maintenance.service';
import { Observable } from 'rxjs';

@Injectable()
export class EquipmentMaintenanceService /* implements ResourceMaintenanceService */ {
    private equipmentId: number;

    constructor(private equipmentService: EquipmentService) {}

    setup(equipmentId: number) {
        this.equipmentId = equipmentId;
    }

    assign(
        command: AssignMaintenanceScheduleCommand
    ): Observable<MaintenanceScheduleAssignmentDto> {
        return this.equipmentService.assignMaintenanceSchedule(
            this.equipmentId,
            command
        );
    }
}

/**
 * Provider for the RPA maintenance service. Required because we need to "provide"
 * the base class.
 */
export const equipmentMaintenanceServiceProvider: Provider = {
    provide: ResourceMaintenanceService,
    useClass: EquipmentMaintenanceService
};
