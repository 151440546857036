<div class="setup_finished_container main_container">
    <!-- <div class="setupfinished_billingdetails notfinished">
        <button name="billing_details" (click)="setupBillingDetails()">Setup Billing Details</button>
        <div class="mul-div-cvr">
            <div class="rowone">14 Day Trial Subscription</div>
            <div class="rowtwo">- All Enterprise Features</div>
        </div>
    </div> -->
    <div
        class="setupfinished_request onboarding-frame"
        (click)="setupFinished()"
    >
        <h4>Setup Complete</h4>
        <h5>You are now ready to start using the FlyFreely platform</h5>
        <i class="far fa-check-circle setup-complete"></i>
        <h5>Click to get started</h5>
    </div>
    <!-- <div class="setupfinished_request notfinished">
        <div *ngIf="!requestSent" class="request-button">
            <button
                name="request_customer_success"
                (click)="requestCustomerSuccess()"
                class="btn btn-block"
            >
                Request Customer Success
            </button>
            <p class="help-block">
                Our customer success team can help you get up and running
                quickly
            </p>
        </div>
        <div *ngIf="!requestSent" class="request-button">
            <button
                name="request_dedicated_onboarding"
                (click)="requestDedicatedOnboarding()"
                class="btn btn-block"
            >
                Request Dedicated Onboarding
            </button>
            <p class="help-block">
                If your organisation is large or complex our dedicated
                onboarding service can shoulder the load of getting setup
            </p>
        </div>
        <div *ngIf="requestSent">
            Your message has been sent, and someone will be in touch shortly.
        </div>
        <ngx-loading [show]="working"></ngx-loading>
    </div> -->
    <!-- <div class="setupfinished_invite notfinished">
        <button name="invite_personnel" (click)="invitePersonnel()">Invite Personnel</button>
    </div> -->
    <div class="custom--btn">
        <!-- <button type="setupfinished_back" (click)="previousScreen()">
            Back
        </button> -->
    </div>
</div>
