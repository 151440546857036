import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentService } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '@flyfreely-portal-ui/ui';

@Pipe({ name: 'formatEquipmentCategory' })
export class FormatEquipmentCategoryPipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor(equipmentService: EquipmentService) {
        this.transform = setupStatusFormatter(
            equipmentService.getEquipmentCategories()
        );
    }
}
