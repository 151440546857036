import { Component, Input, Optional, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    EnhancedHelpDto,
    FlyFreelyError
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { EnhancedHelpTreeService } from 'libs/enhanced-help/src/lib/enhanced-help-tree.service';
import { EnhancedHelpService } from 'libs/enhanced-help/src/lib/enhanced-help.service';
import { take } from 'rxjs/operators';

/**
 * An upsell state component that displays if the organisation does not have the required feature turned on. This can be driven by enhanced help by nesting it in a `screenAnalytics` directive,
 * and setting a `componentName` input. Otherwise, any content is transcluded into the component.
 */
@Component({
    selector: 'upsell',
    templateUrl: './upsell.component.html',
    styleUrls: ['./upsell.component.scss']
})
export class UpsellComponent {
    @Input()
    screenName?: string;

    @Input()
    componentName?: string;

    // Is this a preview in the Enhanced Help editors?
    @Input()
    overrideHelpObject?: EnhancedHelpDto;

    @Input() layout: 'LARGE' | 'NORMAL' | 'NARROW' = 'NORMAL';

    copy: string;

    error?: string;

    helpObject: EnhancedHelpDto;

    featureFlag: string;
    featureName: string;

    hidden = false;

    constructor(
        private enhancedHelpService: EnhancedHelpService,
        private treeService: EnhancedHelpTreeService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.getHelpObject();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('overrideHelpObject' in changes) {
            if (this.overrideHelpObject != null) {
                this.setupHelpObject(this.overrideHelpObject);
            }
        }
    }
    navigate(tab: string, feature: string, featureName: string) {
        const url = `${window.location.pathname}/orgadmin/${tab}`;
        this.router.navigate([url], {
            relativeTo: this.activatedRoute,
            queryParams: { feature, featureName },
            queryParamsHandling: 'merge'
        });
    }

    getHelpObject() {
        if (this.componentName == null) {
            return;
        }
        if (this.overrideHelpObject != null) {
            this.setupHelpObject(this.overrideHelpObject);
            return;
        }

        const screenName =
            this.screenName ?? this.screenAnalytics?.screenAnalytics;

        if (screenName == null) {
            throw new Error(`screenName not specified and can't be inferred`);
        }

        this.enhancedHelpService
            .findByScreenAndComponent(screenName, this.componentName)
            .pipe(take(1))
            .subscribe({
                // This is only a fallback and should actually happen in the backend
                next: results => {
                    this.setupHelpObject(results);
                },
                error: (error: FlyFreelyError) => {
                    this.error = error.message;
                }
            });
    }

    setupHelpObject(helpObject: EnhancedHelpDto) {
        this.helpObject =
            helpObject.helpText != null && helpObject.helpText.length > 0
                ? helpObject
                : null;
        const component = this.treeService.findComponent(
            this.helpObject.screen,
            this.helpObject.component
        );
        this.featureFlag =
            component.type === 'upsell' ? component.featureFlag : null;
        this.featureName = component.name;
    }

    showVideo() {
        this.screenAnalytics.track('upsell', 'show-video', {
            component: this.componentName
        });
        this.enhancedHelpService.showVideo(
            this.helpObject.supportVideoUrl,
            this.helpObject.helpTitle
        );
    }

    trackKnowledgeBase() {
        this.screenAnalytics.track('upsell', 'show-knowledge-base', {
            component: this.componentName
        });
    }

    hide() {
        this.hidden = true;
    }
}
