import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FlyFreelyConstants,
    WidgetMission
} from '@flyfreely-portal-ui/flyfreely';
import { FormatMissionStatusPipe } from '../pipes/domain/formatMissionStatus.pipe';
import { FormatMissionStatusIconPipe } from '../pipes/domain/formatMissionStatusIcon.pipe';

enum type {
    MISSION = 'Mission'
}

@Component({
    selector: 'mission-status-icon',
    templateUrl: './mission-status-icon.component.html'
})
export class MissionStatusIconComponent {
    @Input() mission: WidgetMission;

    @Output() nameClicked = new EventEmitter<void>();

    type: type;
    statusColour: string;
    name: string;
    toolTipText: string;

    isDummy: boolean;

    dummySource: string;

    constructor(
        private constants: FlyFreelyConstants,
        private missionStatusIconPipe: FormatMissionStatusIconPipe,
        private missionStatusPipe: FormatMissionStatusPipe
    ) {
        this.dummySource = `${this.constants.IMG_URL}/icons/dummy-icon.png`;
    }

    ngOnInit() {
        this.findType();
    }

    findType() {
        if (this.mission != null) {
            this.type = type.MISSION;
            this.isDummy = this.mission.isDummy ?? false;
        }
        this.findDetails();
    }

    findDetails() {
        switch (this.type) {
            case type.MISSION:
                this.name = this.mission.name;
                this.statusColour = `status-icon-${this.missionStatusIconPipe.findStatusColour(
                    this.mission.status
                )}${this.isDummy ? '-dummy' : ''}`;
                this.toolTipText = this.missionStatusPipe.transform(
                    this.mission.status
                );
                break;

            default:
                break;
        }
        if (this.isDummy === true) {
            const toolTip = `(Dummy) ${this.toolTipText}`;
            this.toolTipText = toolTip;
        }
    }
}
