import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    BatteryTypeDto,
    BatteryTypeService,
    CreateCompatibleRpaTypeCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

interface RpaTypeWithCount extends RpaTypeDto {
    count: number;
}

interface FormValue {
    rpaTypeId: number;
    count: number;
}

@Component({
    selector: 'compatible-rpa-types',
    templateUrl: './compatible-rpa-types.component.html',
    styleUrls: ['./compatible-rpa-types.component.scss']
})
export class CompatibleRpaTypesComponent {
    @Input() batteryType: BatteryTypeDto;
    @Input() organisationId: number;
    @Input() disabled: boolean;

    availableRpaTypeList: BatteryTypeDto[];
    associatedRpaTypeList: RpaTypeWithCount[];

    compatibleRpaTypeRequirements$ = new BehaviorSubject<{
        [rpaTypeId: number]: number;
    }>(undefined);

    availableColumns: TableColumn[];
    tableConfig: TableConfig;
    selectedColumns: string[];

    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    newCompatibleRpaType = new FormGroup<{
        rpaTypeId: FormControl<number>;
        count: FormControl<number>;
    }>({
        rpaTypeId: new FormControl<number>(undefined, [Validators.required]),
        count: new FormControl<number>(undefined, [Validators.required])
    });

    constructor(
        private rpaTypesService: RpaTypesService,
        private batteryTypeService: BatteryTypeService,
        private logging: FlyFreelyLoggingService,
        private workTracker: WorkTracker
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.availableColumns = [
            {
                name: 'RPA Make',
                value: 'make',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                name: 'RPA Model',
                value: 'model',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                name: 'Battery Required Count',
                value: 'count',
                searchable: false,
                selectable: false,
                defaultSelection: true
            }
        ];
        this.selectedColumns = null;
    }

    ngOnInit() {
        this.setupRpaTypes();
        this.tableConfig = {
            actions: this.disabled
                ? []
                : [
                      {
                          action: (item: RpaTypeWithCount) =>
                              this.removeRpaType(item),
                          icon: 'fal fa-trash-alt',
                          tooltip: 'Remove RPA type'
                      }
                  ],
            limit: 25
        };
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupRpaTypes() {
        this.batteryTypeService
            .findCompatibleRpaTypes(this.batteryType.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(compatible =>
                this.compatibleRpaTypeRequirements$.next(
                    compatible.reduce(
                        (acc, entry) => ({
                            ...acc,
                            [entry.rpaTypeId]: entry.count
                        }),
                        {}
                    )
                )
            );

        combineLatest([
            // This should actually fall back on a search for the rpa by the owner of the battery type
            this.organisationId != null
                ? this.rpaTypesService.findRpaTypes(this.organisationId)
                : this.rpaTypesService.findAllRpaTypes(),
            this.compatibleRpaTypeRequirements$.pipe(
                filter(requirements => requirements != null)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: ([
                    availableRpaTypeList,
                    compatibleRpaTypeRequirements
                ]) => {
                    this.availableRpaTypeList = availableRpaTypeList;
                    this.associatedRpaTypeList = Object.keys(
                        compatibleRpaTypeRequirements
                    ).reduce((acc, typeId) => {
                        const count = compatibleRpaTypeRequirements[typeId];
                        const rpaType = availableRpaTypeList.find(
                            bt => bt.id === parseInt(typeId, 10)
                        );
                        if (rpaType == null) {
                            return acc;
                        }
                        return acc.concat({ ...rpaType, count });
                    }, [] as RpaTypeWithCount[]);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading resource types: ${error.message}`
                    );
                }
            });
    }

    addRpaType() {
        const createCommand: CreateCompatibleRpaTypeCommand = <FormValue>(
            this.newCompatibleRpaType.value
        );
        this.batteryTypeService
            .createCompatibleRpaType(this.batteryType.id, createCommand)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.compatibleRpaTypeRequirements$.next({
                        ...this.compatibleRpaTypeRequirements$.getValue(),
                        [result.rpaType.id]: result.count
                    });
                    this.logging.success('Successfully added RPA type');
                    this.newCompatibleRpaType.reset();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }

    // Done to allow use as a callback
    editRpaType = (item: { id: number; count: number }) => {
        this.batteryTypeService
            .updateCompatibleRpaType(this.batteryType.id, item.id, {
                count: item.count
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    const orig = this.compatibleRpaTypeRequirements$.getValue();
                    const updated = Object.keys(orig).reduce(
                        (acc, typeId) => ({
                            ...acc,
                            [typeId]:
                                parseInt(typeId, 10) === item.id
                                    ? item.count
                                    : orig[typeId]
                        }),
                        {}
                    );
                    this.compatibleRpaTypeRequirements$.next(updated);
                    this.logging.success('Successfully updated RPA type');
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    };

    removeRpaType(item: RpaTypeWithCount) {
        this.batteryTypeService
            .deleteCompatibleRpaType(this.batteryType.id, item.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    const { [item.id]: _, ...updated } =
                        this.compatibleRpaTypeRequirements$.getValue();
                    this.compatibleRpaTypeRequirements$.next(updated);
                    this.logging.success('Successfully removed RPA type');
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }
}
