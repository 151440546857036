import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { ngfModule } from 'angular-file';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxLoadingModule } from 'ngx-loading';

import { ChecklistGroupDirective } from 'libs/ui/src/lib/checklist/checklist-group.directive';
import { ActionRequired } from './action-required/action-required.component';
import { AuthenticatedSrcDirective } from './authenticated-src/authenticated-src.directive';
import { AuthenticatedGuard } from './authenticated.guard';
import { AutoGrowTextAreasDirective } from './auto-grow-text-areas.directive';
import { ChecklistDirective } from './checklist/checklist.directive';
import { ColorFadeOutDirective } from './color-fade-out.directive';
import { ConformanceStatusIcon } from './conformance-status-icon/conformance-status-icon.component';
import { CopyToClipboard } from './copy-to-clipboard/copy-to-clipboard.component';
import { DisableControlDirective } from './disable-control/disable-control.directive';
import { DurationComponent } from './duration/duration.component';
import { Empty } from './empty/empty.component';
import { EntityList } from './entity-list/entity-list.component';
import { FeatureFlagGuard } from './featureFlag.guard';
import { FileDownloadsDirective } from './file-downloads/file-downloads.directive';
import { DivImageSourceDirective } from './img-path/div-img-path';
import { ImageSourceDirective } from './img-path/img-path';
import { InlineEdit } from './inline-edit/inline-edit.component';
import { IntersectionSpyDirective } from './intersection-spy/intersection-spy.directive';
import { ListSelector } from './list-selector/list-selector.component';
import { LockableComponent } from './lockable/lockable.component';
import { LockedModeDirective } from './lockable/locked-mode.directive';
import { UnlockedModeDirective } from './lockable/unlocked-mode.directive';
import { MissionStatusIconComponent } from './mission-status-icon/mission-status-icon.component';
import { OrganisationLogo } from './organisation-logo/organisation-logo.component';
import { PersonAvatarComponent } from './person-avatar/person-avatar.component';
import { CalculateSortieDurationPipe } from './pipes/domain/calculateSortieDuration.pipe';
import { FormatEmptyTitlePipe } from './pipes/domain/format-empty-title.pipe';
import { FormatAddressPipe } from './pipes/domain/formatAddress.pipe';
import { FormatAirspaceAuthorisationTypePipe } from './pipes/domain/formatAirspaceAuthorisationType.pipe';
import { FormatApprovalStatusPipe } from './pipes/domain/formatApprovalStatus.pipe';
import { FormatAuthorityPipe } from './pipes/domain/formatAuthority.pipe';
import { FormatFlightConformanceTypePipe } from './pipes/domain/formatFlightConformanceType.pipe';
import { FormatFlightLogCompletionMessagePipe } from './pipes/domain/formatFlightLogCompletionMessage.pipe';
import { FormatFlightLogQueuePositionPipe } from './pipes/domain/formatFlightLogQueuePosition.pipe';
import { FormatIncidentReportStatusPipe } from './pipes/domain/formatIncidentReportStatus.pipe';
import { FormatJurisdictionAuthorityIdentifierPipe } from './pipes/domain/formatJursdictionAuthorityIdentifier';
import { FormatMaintenanceActivityStatusPipe } from './pipes/domain/formatMaintenanceActivityStatus.pipe';
import { FormatMaintenanceEquipmentCategoryPipe } from './pipes/domain/formatMaintenanceEquipmentType.pipe';
import { FormatMaintenanceLogTypePipe } from './pipes/domain/formatMaintenanceLogType.pipe';
import { FormatMaintenanceOutcomePipe } from './pipes/domain/formatMaintenanceOutcome.pipe';
import { FormatMaintenanceStatusPipe } from './pipes/domain/formatMaintenanceStatus.pipe';
import { FormatMaintenanceTriggerTypePipe } from './pipes/domain/formatMaintenanceTriggerType.pipe';
import { FormatMissionStatusPipe } from './pipes/domain/formatMissionStatus.pipe';
import { FormatMissionStatusIconPipe } from './pipes/domain/formatMissionStatusIcon.pipe';
import { FormatMissionTypePipe } from './pipes/domain/formatMissionType.pipe';
import { FormatMTOWPipe } from './pipes/domain/formatMTOW.pipe';
import { FormatObjectiveOutcomePipe } from './pipes/domain/formatObjectiveOutcome';
import { FormatPersonPipe } from './pipes/domain/formatPerson.pipe';
import { FormatWeather } from './pipes/domain/formatWeather';
import { FormatColumnArrayPipe } from './pipes/domain/formatWrappedArrays.pipe';
import { SortAuthoritiesPipe } from './pipes/domain/sort-authorities.pipe';
import { AutolinkPipe } from './pipes/generic/autolink.pipe';
import { DefaultToPipe } from './pipes/generic/defaultTo.pipe';
import { DynamicPipe } from './pipes/generic/dynamic.pipe';
import { FilterById } from './pipes/generic/filterById.pipe';
import { FilterOutById } from './pipes/generic/filterOutById.pipe';
import {
    FormatLocalDateTimeWithUTCPipe,
    FormatLocalDateTimeWithUTCProtractedPipe
} from './pipes/generic/format-local-date-time-with-utc.pipe';
import { FormatArrayPipe } from './pipes/generic/formatArray';
import { FormatBooleanPipe } from './pipes/generic/formatBoolean';
import { FormatDatePipe } from './pipes/generic/formatDate';
import { FormatDateTimePipe } from './pipes/generic/formatDateTime';
import { FormatDurationPipe } from './pipes/generic/formatDuration';
import { FormatLatitudePipe } from './pipes/generic/formatLatitude';
import { FormatLongitudePipe } from './pipes/generic/formatLongitude';
import { FormatTime } from './pipes/generic/formatTime';
import { GuardTypePipe } from './pipes/generic/guardType.pipe';
import { JoinPipe } from './pipes/generic/join.pipe';
import { SortByPipe } from './pipes/generic/sort-by.pipe';
import { UnsafeHtmlPipe } from './pipes/generic/unsafeHtml.pipe';
import { MissionStatusClassPipe } from './pipes/styling/missionStatusClass';
import { RegisterStatusClassPipe } from './pipes/styling/register-status-class.pipe';
import { ProfilePicture } from './profile-picture/profile-picture.component';
import { RoutingErrorComponent } from './routing-error/routing-error.component';
import { ScrollSpyDirective } from './scroll-spy/scroll-spy.directive';
import { ScrollToDirective } from './scrollTo.directive';
import { VersionComponent } from './version/version.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        // ngx-bootstrap
        TooltipModule.forRoot(),
        LoadingIndicatorModule,
        ngfModule,
        PopoverModule,
        ValidationModule,
        MaterialModule,
        ClipboardModule,
        NgxLoadingModule
    ],
    providers: [
        FormatDatePipe,
        FormatMTOWPipe,
        FormatBooleanPipe,
        FormatDateTimePipe,
        FormatDurationPipe,
        FormatMissionStatusIconPipe,
        FormatAuthorityPipe,
        DynamicPipe,
        FormatIncidentReportStatusPipe,
        FormatMissionStatusPipe,
        FormatArrayPipe,
        FormatMaintenanceStatusPipe,
        FormatMaintenanceLogTypePipe,
        FormatMaintenanceEquipmentCategoryPipe,
        FormatPersonPipe,
        FormatColumnArrayPipe,
        RegisterStatusClassPipe,
        FormatMaintenanceOutcomePipe,
        FormatFlightConformanceTypePipe,
        FormatMaintenanceTriggerTypePipe,
        FormatMissionTypePipe,
        AuthenticatedGuard,
        FeatureFlagGuard,
        SortAuthoritiesPipe
    ],
    bootstrap: [],
    declarations: [
        FormatMissionStatusIconPipe,
        FormatPersonPipe,
        FormatMTOWPipe,
        FormatDatePipe,
        FormatDateTimePipe,
        FormatAuthorityPipe,
        FormatApprovalStatusPipe,
        MissionStatusClassPipe,
        FormatAddressPipe,
        ListSelector,
        LockableComponent,
        LockedModeDirective,
        UnlockedModeDirective,
        ProfilePicture,
        FormatDurationPipe,
        FormatBooleanPipe,
        FormatTime,
        CalculateSortieDurationPipe,
        FormatWeather,
        OrganisationLogo,
        DurationComponent,
        InlineEdit,
        ScrollSpyDirective,
        DisableControlDirective,
        FormatArrayPipe,
        FormatMissionStatusPipe,
        FormatObjectiveOutcomePipe,
        DynamicPipe,
        ImageSourceDirective,
        DivImageSourceDirective,
        FormatLatitudePipe,
        FormatLongitudePipe,
        ScrollToDirective,
        AutoGrowTextAreasDirective,
        AutolinkPipe,
        FormatIncidentReportStatusPipe,
        FormatMissionStatusPipe,
        FormatArrayPipe,
        FormatMaintenanceStatusPipe,
        FormatMaintenanceLogTypePipe,
        FormatMaintenanceEquipmentCategoryPipe,
        FormatColumnArrayPipe,
        FormatMaintenanceActivityStatusPipe,
        JoinPipe,
        GuardTypePipe,
        UnsafeHtmlPipe,
        ActionRequired,
        EntityList,
        RegisterStatusClassPipe,
        FormatJurisdictionAuthorityIdentifierPipe,
        FileDownloadsDirective,
        AuthenticatedSrcDirective,
        FormatMaintenanceOutcomePipe,
        FormatFlightConformanceTypePipe,
        FormatMaintenanceTriggerTypePipe,
        FormatAirspaceAuthorisationTypePipe,
        Empty,
        CopyToClipboard,
        ConformanceStatusIcon,
        RoutingErrorComponent,
        FormatFlightLogQueuePositionPipe,
        FormatFlightLogCompletionMessagePipe,
        DefaultToPipe,
        MissionStatusIconComponent,
        FilterOutById,
        FilterById,
        FormatMissionTypePipe,
        VersionComponent,
        PersonAvatarComponent,
        FormatLocalDateTimeWithUTCPipe,
        FormatLocalDateTimeWithUTCProtractedPipe,
        ChecklistDirective,
        ChecklistGroupDirective,
        IntersectionSpyDirective,
        FormatEmptyTitlePipe,
        SortByPipe,
        SortAuthoritiesPipe,
        ColorFadeOutDirective
    ],
    exports: [
        FormatPersonPipe,
        FormatMissionStatusIconPipe,
        FormatMTOWPipe,
        FormatDatePipe,
        FormatDateTimePipe,
        FormatAuthorityPipe,
        FormatApprovalStatusPipe,
        MissionStatusClassPipe,
        FormatAddressPipe,
        ListSelector,
        LockableComponent,
        LockedModeDirective,
        UnlockedModeDirective,
        ProfilePicture,
        FormatDurationPipe,
        FormatBooleanPipe,
        FormatTime,
        CalculateSortieDurationPipe,
        FormatWeather,
        OrganisationLogo,
        DurationComponent,
        InlineEdit,
        ScrollSpyDirective,
        DisableControlDirective,
        FormatArrayPipe,
        FormatMissionStatusPipe,
        FormatObjectiveOutcomePipe,
        DynamicPipe,
        ImageSourceDirective,
        DivImageSourceDirective,
        FormatLatitudePipe,
        FormatLongitudePipe,
        ScrollToDirective,
        AutoGrowTextAreasDirective,
        AutolinkPipe,
        FormatIncidentReportStatusPipe,
        FormatMaintenanceStatusPipe,
        FormatMaintenanceLogTypePipe,
        FormatMaintenanceEquipmentCategoryPipe,
        FormatMaintenanceActivityStatusPipe,
        FormatColumnArrayPipe,
        JoinPipe,
        GuardTypePipe,
        UnsafeHtmlPipe,
        ActionRequired,
        EntityList,
        RegisterStatusClassPipe,
        FormatJurisdictionAuthorityIdentifierPipe,
        FileDownloadsDirective,
        AuthenticatedSrcDirective,
        FormatMaintenanceOutcomePipe,
        FormatFlightConformanceTypePipe,
        FormatMaintenanceTriggerTypePipe,
        FormatAirspaceAuthorisationTypePipe,
        Empty,
        CopyToClipboard,
        ConformanceStatusIcon,
        RoutingErrorComponent,
        FormatFlightLogQueuePositionPipe,
        FormatFlightLogCompletionMessagePipe,
        DefaultToPipe,
        MissionStatusIconComponent,
        FilterOutById,
        FilterById,
        FormatMissionTypePipe,
        VersionComponent,
        PersonAvatarComponent,
        FormatLocalDateTimeWithUTCPipe,
        FormatLocalDateTimeWithUTCProtractedPipe,
        ChecklistDirective,
        ChecklistGroupDirective,
        IntersectionSpyDirective,
        FormatEmptyTitlePipe,
        SortByPipe,
        SortAuthoritiesPipe,
        ColorFadeOutDirective
    ]
})
export class SharedUiModule {}

export {
    ActionRequired,
    AuthenticatedGuard,
    AuthenticatedSrcDirective,
    AutoGrowTextAreasDirective,
    AutolinkPipe,
    CalculateSortieDurationPipe,
    ColorFadeOutDirective,
    ConformanceStatusIcon,
    CopyToClipboard,
    DefaultToPipe,
    DisableControlDirective,
    DivImageSourceDirective,
    DurationComponent,
    DynamicPipe,
    Empty,
    EntityList,
    FileDownloadsDirective,
    FormatAddressPipe,
    FormatAirspaceAuthorisationTypePipe,
    FormatApprovalStatusPipe,
    FormatArrayPipe,
    FormatAuthorityPipe,
    FormatBooleanPipe,
    FormatColumnArrayPipe,
    FormatDatePipe,
    FormatDateTimePipe,
    FormatDurationPipe,
    FormatFlightConformanceTypePipe,
    FormatFlightLogCompletionMessagePipe,
    FormatFlightLogQueuePositionPipe,
    FormatIncidentReportStatusPipe,
    FormatJurisdictionAuthorityIdentifierPipe,
    FormatLatitudePipe,
    FormatLocalDateTimeWithUTCPipe as FormatLocalTimeWithUTCPipe,
    FormatLocalDateTimeWithUTCProtractedPipe as FormatLocalTimeWithUTCPipeProtracted,
    FormatLongitudePipe,
    FormatMaintenanceActivityStatusPipe,
    FormatMaintenanceEquipmentCategoryPipe,
    FormatMaintenanceLogTypePipe,
    FormatMaintenanceOutcomePipe,
    FormatMaintenanceStatusPipe,
    FormatMaintenanceTriggerTypePipe,
    FormatMissionStatusIconPipe,
    FormatMissionStatusPipe,
    FormatMTOWPipe,
    FormatObjectiveOutcomePipe,
    FormatPersonPipe,
    FormatTime,
    FormatWeather,
    GuardTypePipe,
    ImageSourceDirective,
    InlineEdit,
    JoinPipe,
    ListSelector,
    LockableComponent,
    LockedModeDirective,
    MissionStatusClassPipe,
    MissionStatusIconComponent,
    OrganisationLogo,
    PersonAvatarComponent,
    ProfilePicture,
    RegisterStatusClassPipe,
    RoutingErrorComponent,
    ScrollSpyDirective,
    ScrollToDirective,
    SortAuthoritiesPipe,
    UnlockedModeDirective,
    UnsafeHtmlPipe
};
