import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    BatterySetDto,
    BatterySetService,
    CompleteSortieCommand,
    CraftDto,
    CraftService,
    DisplayableMissionDto,
    EquipmentDto,
    EquipmentService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    fromTimestamp,
    InUseMissionWorkflowVersionDto,
    MissionRoleDto,
    MissionRoleService,
    MissionWorkflowService,
    PersonRolesDto,
    PersonService,
    RpaTypeDto,
    RpaTypesService,
    SortieDto,
    UpdateHistoricalMissionCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface MissionRecordFormModel {
    name: FormControl<string>;
    completionMessage: FormControl<string>;
    locationId: FormControl<number>;
    missionDate: FormControl<Date>;
    missionObjective: FormControl<string>;
    missionObjectiveTypeId: FormControl<number>;
    missionTypeId: FormControl<number>;
    missionWorkflowVersionId: FormControl<number>;
    rpicId: FormControl<number>;
    objectiveOutcomeNotes: FormControl<string>;
    outcome: FormControl<UpdateHistoricalMissionCommand.Outcome>;
    sorties: FormArray<FormControl<CompleteSortieCommand>>;
    timeOfDay: FormControl<UpdateHistoricalMissionCommand.TimeOfDay>;
    timeZone: FormControl<string>;
    visualLineOfSight: FormControl<UpdateHistoricalMissionCommand.VisualLineOfSight>;
}

export interface MissionRecordModel {
    name: string;
    completionMessage: string;
    locationId: number;
    missionDate: Date;
    missionObjective: string;
    missionObjectiveTypeId: number;
    missionTypeId: number;
    missionWorkflowVersionId: number;
    rpicId: number;
    objectiveOutcomeNotes: string;
    outcome: UpdateHistoricalMissionCommand.Outcome;
    sorties: CompleteSortieCommand[];
    timeOfDay: UpdateHistoricalMissionCommand.TimeOfDay;
    timeZone: string;
    visualLineOfSight: UpdateHistoricalMissionCommand.VisualLineOfSight;
}

export interface DisplayableResource {
    id: number;
    name: string;
}

@Injectable()
export class MissionRecordService {
    public form: FormGroup<MissionRecordFormModel>;

    workingSubject = new BehaviorSubject<boolean>(false);
    private availableRpaSubject = new ReplaySubject<CraftDto[]>();
    private allRpaTypesSubject = new ReplaySubject<RpaTypeDto[]>();
    private availablePersonnelSubject = new ReplaySubject<
        DisplayableResource[]
    >();
    private availableBatterySetsSubject = new ReplaySubject<BatterySetDto[]>();
    private availableEquipmentSubject = new ReplaySubject<EquipmentDto[]>();
    private missionWorkflowsSource = new ReplaySubject<
        InUseMissionWorkflowVersionDto[]
    >(1);
    private missionRolesSubject = new ReplaySubject<MissionRoleDto[]>(1);
    private allPersonnelSubject = new ReplaySubject<PersonRolesDto[]>(1);
    private allRpaSubject = new ReplaySubject<CraftDto[]>(1);
    private allBatterySetsSubject = new ReplaySubject<BatterySetDto[]>(1);
    missionWorkflows$ = this.missionWorkflowsSource.asObservable();
    working$ = this.workingSubject.asObservable();
    availableRpas$ = this.availableRpaSubject.asObservable();
    allRpaTypes$ = this.allRpaTypesSubject.asObservable();
    availablePersonnel$ = this.availablePersonnelSubject.asObservable();
    availableBatterySets$ = this.availableBatterySetsSubject.asObservable();
    availableEquipment$ = this.availableEquipmentSubject.asObservable();
    missionRoles$ = this.missionRolesSubject.asObservable();
    allPersonnel$ = this.allPersonnelSubject.asObservable();
    allRpas$ = this.allRpaSubject.asObservable();
    allBatterySets$ = this.allBatterySetsSubject.asObservable();

    crewRoles: MissionRoleDto[];
    rpicRole: MissionRoleDto;
    missionWorkflows: InUseMissionWorkflowVersionDto[];

    private savedMissionSubject = new ReplaySubject<DisplayableMissionDto>(1);
    savedMission$ = this.savedMissionSubject.asObservable();

    private get flights() {
        return this.form.controls.sorties;
    }

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionRoleService: MissionRoleService,
        private personService: PersonService,
        private craftService: CraftService,
        private rpaTypesService: RpaTypesService,
        private batterySetService: BatterySetService,
        private equipmentService: EquipmentService,
        private missionWorkflowService: MissionWorkflowService,
        private logging: FlyFreelyLoggingService
    ) {
        this.form = new FormGroup<MissionRecordFormModel>({
            name: new FormControl(
                undefined,
                Validators.compose([
                    Validators.minLength(3),
                    Validators.required
                ])
            ),
            missionObjectiveTypeId: new FormControl(undefined),
            missionObjective: new FormControl(undefined),
            locationId: new FormControl(undefined),
            missionDate: new FormControl(undefined),
            timeZone: new FormControl(undefined),
            timeOfDay: new FormControl(undefined),
            visualLineOfSight: new FormControl(undefined),
            completionMessage: new FormControl(undefined),
            missionTypeId: new FormControl(undefined),
            missionWorkflowVersionId: new FormControl(undefined),
            rpicId: new FormControl(undefined),
            objectiveOutcomeNotes: new FormControl(undefined),
            outcome: new FormControl(undefined, Validators.required),
            sorties: new FormArray([])
        });

        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                this.workingSubject.next(working);
            });
    }

    ngOnDestroy() {
        this.availablePersonnelSubject.complete();
        this.availableBatterySetsSubject.complete();
        this.availableEquipmentSubject.complete();
        this.availableRpaSubject.complete();
        this.missionWorkflowsSource.complete();
        this.missionRolesSubject.complete();
        this.allPersonnelSubject.complete();
        this.allRpaSubject.complete();
        this.allRpaTypesSubject.complete();
        this.allBatterySetsSubject.complete();
        this.workingSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    updateFlights(flights: SortieDto[]) {
        flights.forEach((f, ix) => {
            const value = this.flights.controls[ix].value;
            if (f.durationSource === SortieDto.DurationSource.LOGS) {
                this.flights.controls[ix].patchValue({
                    ...value,
                    durationSource: f.durationSource,
                    duration: f.duration
                });
            } else {
                this.flights.controls[ix].patchValue({
                    ...value,
                    durationSource: f.durationSource
                });
            }
        });
    }

    setupMissionForm(mission: DisplayableMissionDto, organisationId: number) {
        this.missionRoleService
            .findMissionRoles(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(
                roles => {
                    this.crewRoles = roles.filter(
                        r =>
                            r.coreRole !==
                            MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                    );
                    this.rpicRole = roles.filter(
                        r =>
                            r.coreRole ===
                            MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                    )[0];
                    this.missionRolesSubject.next(roles);
                },
                error => this.logging.error(error)
            )
            .then(() => {
                this.form.patchValue({
                    name: mission.name,
                    missionObjectiveTypeId:
                        mission.missionObjectiveType != null
                            ? mission.missionObjectiveType.id
                            : null,
                    missionObjective: mission.missionObjective,
                    locationId: mission.locationId,
                    timeZone: mission.timeZone,
                    missionDate:
                        mission.timeZone != null
                            ? mission.missionDate
                                ? fromTimestamp(
                                      mission.missionDate,
                                      mission.timeZone
                                  )
                                : moment()
                                      .hours(8)
                                      .minute(0)
                                      .seconds(0)
                                      .toDate()
                            : null,
                    timeOfDay: mission.timeOfDay,
                    visualLineOfSight: mission.visualLineOfSight,
                    completionMessage: mission.completionMessage,
                    missionTypeId:
                        mission.missionType != null
                            ? mission.missionType.id
                            : null,
                    missionWorkflowVersionId: mission.missionWorkflowVersionId,
                    rpicId: mission.missionCrewDetails.find(
                        c =>
                            c.role?.coreRole ===
                            MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                    )?.person?.id,
                    objectiveOutcomeNotes: mission.objectiveOutcomeNotes,
                    outcome: mission.outcome
                });
                while (this.form.controls.sorties.length > 0) {
                    this.form.controls.sorties.removeAt(0);
                }
                mission.sorties.forEach(sortie => {
                    this.addFlight(sortie);
                });
                this.form.updateValueAndValidity();
                this.refreshData(mission, organisationId);
                this.savedMissionSubject.next(mission);
            });

        this.form.controls.missionDate.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(date => {
                this.form.controls.missionDate.setValue(date, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });
            });
    }

    refreshData(mission: DisplayableMissionDto, organisationId: number) {
        forkJoin([
            this.personService.findPersonnel(organisationId),
            this.craftService.findCrafts(organisationId),
            this.rpaTypesService.findRpaTypes(organisationId),
            this.batterySetService.findBatterySets(
                organisationId,
                mission.missionDate
            ),
            this.equipmentService.find(organisationId),
            this.missionWorkflowService.findActiveWorkflows(
                mission.organisationId,
                mission.missionDate
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    personnel,
                    rpas,
                    rpaTypes,
                    batterySets,
                    equipment,
                    missionWorkflows
                ]) => {
                    this.allPersonnelSubject.next(personnel);
                    this.allRpaSubject.next(rpas);
                    this.allRpaTypesSubject.next(rpaTypes);
                    this.allBatterySetsSubject.next(batterySets);
                    this.availablePersonnelSubject.next(
                        personnel.map(p => ({
                            id: p.id,
                            name: `${p.firstName} ${p.lastName}`
                        }))
                    );
                    this.availableRpaSubject.next(rpas);
                    this.availableBatterySetsSubject.next(batterySets);
                    this.availableEquipmentSubject.next(equipment);
                    this.missionWorkflows = missionWorkflows;
                    this.missionWorkflowsSource.next(missionWorkflows);
                    this.form.controls.missionWorkflowVersionId.updateValueAndValidity(
                        {
                            onlySelf: true
                        }
                    );
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while fetching resources: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    private createFlightControl(s: SortieDto) {
        return new FormControl<CompleteSortieCommand>(
            // @ts-ignore - missing type overlap not needed
            s,
            [Validators.required]
        );
    }

    addFlight(s: SortieDto): void {
        const sorties = this.form.controls.sorties;
        sorties.push(this.createFlightControl(s));
    }

    updateFlight(flight: SortieDto) {
        if (
            flight == null ||
            this.flights == null ||
            this.flights.length === 0
        ) {
            return;
        }
        for (let ix = 0; ix < this.flights.length; ix++) {
            const currentValue = this.flights.at(ix).value;
            if (flight.id === currentValue.id) {
                this.flights.at(ix).setValue({
                    ...currentValue,
                    status: flight.status,
                    craftId: flight.craftId,
                    duration: flight.duration,
                    durationSource: flight.durationSource,
                    batterySetId: flight.batterySetId
                });
                return;
            }
        }
        // FIXME maybe create the flight if it doesn't exist?
    }

    deleteFlight(index: number) {
        this.form.controls.sorties.removeAt(index);
    }

    noObjectivesRequired() {
        this.form.controls.missionObjective.clearValidators();
        this.form.controls.missionObjectiveTypeId.clearValidators();
        this.form.updateValueAndValidity();
    }
}
