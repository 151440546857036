import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

/**
 * This is a popover component that can be inserted to highlight extra critical information and access blocks in a workflow.
 * @param nonCritical passing true shows a disclaimer that this doesn't prevent continuing. Defaults to false.
 * @param buttonText the text to display on an optional action button. Enables the button if not null. Defaults to null.
 * @emits buttonClick a void emitter that fires when the action button is clicked.
 */
@Component({
    selector: 'action-required',
    template: `
        <span
            *ngIf="noPopover"
            [tooltip]="tooltipText"
            placement="top"
            class="icon"
        >
            <span class="fad fa-exclamation-triangle"></span>
        </span>
        <span
            *ngIf="!noPopover"
            #pop="bs-popover"
            [popover]="popTemplate"
            [outsideClick]="true"
            [adaptivePosition]="true"
            container=""
            placement="bottom"
            class="icon"
            (click)="openPopover(pop)"
        >
            <span class="fad fa-exclamation-triangle"></span>
        </span>
        <ng-template #popTemplate>
            <div class="action-required-leading" scrollTo>
                <span class="fa fa-exclamation-triangle"></span>
            </div>
            <div class="action-required-content">
                <button
                    class="close"
                    type="button"
                    aria-label="Close"
                    (click)="hidePopover()"
                >
                    <span aria-hidden="true" class="fa fa-times"></span>
                </button>
                <div [ngStyle]="{ 'padding-bottom: 20px;': nonCritical }">
                    <div class="title">Action Required</div>
                    <div
                        class="horizontal-container"
                        style="align-items: center"
                    >
                        <ng-content></ng-content>
                        <button
                            *ngIf="buttonText.length > 0"
                            type="button"
                            class="btn btn-default action-required-button"
                            (click)="buttonClick.emit()"
                        >
                            {{ buttonText }}
                        </button>
                    </div>
                    <div *ngIf="nonCritical" class="action-required-condition">
                        *This will not prevent you from continuing.
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    styles: [
        `
            :host .icon {
                --fa-primary-color: #595974;
                --fa-secondary-color: #fecb1c;
                display: inline-block;
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
            }
            :host ::ng-deep .popover {
                color: black;
                background-color: #fecb1c;
                height: 120px;
                width: 560px;
                padding: 0;
            }
            :host ::ng-deep .popover > .arrow:after {
                border-top-color: #fecb1c;
                border-bottom-color: #fecb1c;
            }
            :host ::ng-deep .popover > .arrow {
                margin-left: -1px !important;
            }
            :host ::ng-deep .popover-body {
                display: flex;
                flex-direction: row;
                padding: 0;
                height: 100%;
                position: relative;
                overflow: hidden;
            }
            :host ::ng-deep .popover-body::before {
                content: '';
                position: absolute;
                background: black;
                height: 138%;
                width: 27%;
                top: -30px;
                left: -15px;
                transform: rotate(20deg);
            }
            .action-required-leading {
                color: #fecb1c;
                z-index: 1;
                width: 130px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 29px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                transform: translateX(-6px);
            }
            .action-required-content {
                flex: 1;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 15px;
                font-size: 14px;
                line-height: 16px;
            }
            .action-required-button {
                background-color: unset;
                border: 1px black solid;
                border-radius: 5px;
                margin-left: 10px;
                padding: 5px 10px;
                height: 35px;
            }
            .action-required-button:hover {
                background-color: #ffd96b;
            }
            .close {
                position: absolute;
                top: 0;
                right: 0;
                color: black;
                padding: 10px;
                opacity: 0.7;
                text-shadow: unset;
            }
            .close:hover {
                opacity: 1;
            }
            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
            }
            .action-required-condition {
                font-size: 0.8em;
                font-style: italic;
                position: absolute;
                bottom: 6px;
                right: 10px;
            }
            .scroll-to-button:hover {
                color: #b9930f;
            }
            :host .icon:hover {
                --fa-secondary-color: #b9930f !important;
            }
        `
    ]
})
export class ActionRequired {
    /**
     * Setting nonCritical to true shows a disclaimer that this doesn't prevent continuing. Defaults to false.
     */
    @Input() nonCritical: boolean = false;
    /**
     * Text to display on an optional action button. Enables the button if it has text. Defaults to an empty string.
     */
    @Input() buttonText: string = '';
    /**
     * Setting noPopover to true will not show the popover. Defaults to false.
     */
    @Input() noPopover: boolean = false;
    /**
     * Optional tooltip text. If left null no tooltip will be shown.
     */
    @Input() tooltipText: string;
    /**
     * Void emitter that fires when the action button is clicked.
     * Use this to hook into the required action in the parent component.
     */
    @Output() buttonClick = new EventEmitter<void>();

    popover: PopoverDirective;

    openPopover(pop: any) {
        this.popover = pop;
    }

    hidePopover() {
        this.popover.hide();
    }
}
