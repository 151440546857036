import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ReportSideBarComponent } from './report-side-bar/report-side-bar.component';
import { ReportViewComponent } from './report-view/report-view.component';
import { ReportingService } from './reporting.service';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        EditableModule,
        TooltipModule,
        MaterialModule,
        RouterModule.forChild([])
    ],
    declarations: [ReportViewComponent, ReportSideBarComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [ReportingService],
})
export class ReportingModule {}
