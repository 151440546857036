<ng-container *ngIf="currencies && currencies.length">
    <h5 class="section-heading">Currency</h5>
    <div class="table-container table-borderless">
        <table style="width: 100%">
            <thead>
                <tr>
                    <th style="width: 20%">Conditions</th>
                    <th style="width: 15%">Status</th>
                    <th style="width: 15%">Flights</th>
                    <th style="width: 15%">Flight Time</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let currency of currencies">
                    <td>
                        <ng-container
                            *ngFor="
                                let conditionList of currency.currencyOperatingConditionList;
                                let lastCondition = last
                            "
                        >
                            <ng-container
                                *ngFor="
                                    let condition of conditionList.values;
                                    let lastValue = last
                                "
                            >
                                <ng-container
                                    *ngIf="condition.type !== 'RPA_MTOW'"
                                >
                                    {{ operatingConditions[condition.value]
                                    }}{{
                                        lastValue && lastCondition ? '' : ', '
                                    }}
                                </ng-container>
                                <ng-container
                                    *ngIf="conditionList.type === 'RPA_MTOW'"
                                >
                                    <{{ condition.value | formatMTOW
                                    }}{{
                                        lastValue && lastCondition ? '' : ', '
                                    }}
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <span
                            *ngIf="currency.passed"
                            class="fa fa-check green-tick"
                        >
                        </span>
                        <span *ngIf="!currency.passed" class="red-cross">
                            X
                        </span>
                    </td>
                    <td>
                        {{ currency.actualFlights }} flights ({{
                            currency.requiredFlights
                                ? currency.requiredFlights
                                : 0
                        }}
                        required)
                    </td>
                    <td>
                        {{ currency.actualFlightsTimeSeconds | formatDuration  | defaultTo }}
                        flown ({{
                            !currency.requiredFlightsTimesSeconds
                                ? 'none'
                                : (currency.requiredFlightsTimesSeconds
                                  | formatDuration)
                        }}
                        required)
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>
<loading-indicator [isLoading]="working"></loading-indicator>
