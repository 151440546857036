import { PipeTransform, Pipe } from '@angular/core';
import { SubscriptionLimitDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatLicenceLimits' })
export class FormatLicenceLimitsPipe implements PipeTransform {
    transform(value: SubscriptionLimitDto[]) {
        if (value == null || value.length === 0) {
            return null;
        }

        return value[0].instances;
    }
}
