import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../../constants';
import { FeatureFlagDto } from '../../model/api';

export const FEATURE_PERSONNEL_BULK_UPLOADS = 'personnelBulkUpLoads';
export const FEATURE_MISSION_REPORTS = 'missionReports';
export const FEATURE_RPA_WORKFLOW = 'rpaWorkflow';
export const FEATURE_INCIDENT_REPORTS = 'incidentReports';
export const FEATURE_EQUIPMENT = 'equipment';
export const FEATURE_MAINTENANCE = 'maintenance';
export const FEATURE_API_KEYS = 'apiKeys';
export const FEATURE_BILLING = 'billing';
export const FEATURE_REFERRALS = 'referrals';
export const FEATURE_APPROVAL_CONDITIONS_LIBRARY = 'approvalConditionsLibrary';
export const FEATURE_ORGANISATION_RELATIONSHIPS = 'organisationRelationships';
export const FEATURE_MISSION_OBJECTIVES = 'missionObjectives';
export const FEATURE_MISSION_AERODROMES = 'missionAerodromes';
export const FEATURE_STUDENT_ROLE = 'studentRole';
export const FEATURE_READ_ONLY_ROLE = 'readOnlyRole';
export const FEATURE_INSTANT_MISSIONS = 'instantMissions';

export const FEATURE_SERVICEABILITY_SIGNOFF = 'serviceabilitySignoff';

export const FEATURE_RPA_LOG_COLLECTION = 'rpaLogCollection';

export const FEATURE_MAINTENANCE_TASKS = 'maintenanceTasks';
export const FEATURE_SCHEDULED_MAINTENANCE = 'scheduledMaintenance';
export const FEATURE_ADDITIONAL_MAINTENANCE = 'additionalMaintenance';

export const FEATURE_MISSION_CALENDAR = 'missionCalendar';
export const FEATURE_NOTAMS = 'notams';
export const FEATURE_UPLOAD_SORTIES = 'uploadSorties';

export const FEATURE_MAP_MEASUREMENT = 'mapMeasurement';

export const FEATURE_DJI_LOGS = 'djiLogs';
export const FEATURE_FLIGHT_LOG_ALERTS = 'rpaLogAlerts';

export const FEATURE_FLIGHT_CONFORMANCE = 'flightConformance';

export const FEATURE_SETUP_GUIDE = 'setupGuide';

export const FEATURE_ADDITIONAL_FLIGHT_FIELDS = 'additionalFlightFields';

export const FEATURE_CASA_AUTHORISATION = 'casaAuthorisation';

export const FEATURE_DRONE_DEPLOY_LOGS = 'droneDeployLogs';

export const FEATURE_SKYDIO_LOGS = 'skydioLogs';

export const FEATURE_FIELD_APP_CRP_TOOLS = 'fieldAppCrpTools';

export const FEATURE_DUMMY_FLIGHT_LOGS = 'dummy-log-files';

export const FEATURE_REPROCESS_FLIGHT_LOGS = 'reprocessFlightLogs';

export const FEATURE_CURRENCY = 'currency';

export const FEATURE_ENDORSEMENTS = 'endorsements';

export const FEATURE_FLIGHT_LOG_AUTOMATIC_MISSION_CONFIGURATION =
    'flightLogAutomaticMissionConfiguration';

export const FEATURE_UPSELL_PLANS_OVERVIEW = 'upsellPlansOverview';

export const FEATURE_RPA_RESOURCE_ASSOCIATION = 'rpaResourceAssociation';

export const FEATURE_ADDITIONAL_AUTHORITIES = 'additionalAuthorities';

export const POST_FLIGHT_DURATION_EDIT = 'postFlightDurationEdit';

export const FEATURE_BATTERY_HEALTH = 'batteryHealth';

export const LAYOUT_V2 = 'layoutV2';

export const FEATURE_GENERIC_TASKS = 'genericTasks';

export const FEATURE_OUTSTANDING_TASKS_VIEW = 'outstandingTasksView';

export const FEATURE_DYNAMIC_REPORTING = 'dynamicReporting';

export const FEATURE_FIELD_APP_MISSION_CLONING = 'fieldAppCloneMissions';

export const FEATURE_MAINTENANCE_NOTICES = 'maintenanceNotices';

export const FEATURE_WORKGROUPS = 'workgroups';
export const FEATURE_AIRWORTHINESS_NOTICES = 'airworthinessNotices';

export const FEATURE_EXTERNAL_MAINTENANCE_PERSON = 'externalMaintenancePerson';
export const FEATURE_PUSH_NOTIFICATIONS = 'pushNotifications';

export const FEATURE_HIDDEN_READ_ONLY_ROLE = "hidden-read-only-role";

export const FEATURE_DEMO_ORGANISATION = 'demoOrganisation';

const uiFeatureFlags: FeatureFlagDto[] = [
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_MISSION_AERODROMES,
        name: 'Aerodrome Data',
        appliesTo: 'BOTH'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: 'approvalConditionsLibrary',
        name: 'Approval Conditions Library',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_PERSONNEL_BULK_UPLOADS,
        name: 'Personnel Bulk Uploads',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_DEMO_ORGANISATION,
        name: 'Demo Organisation',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Workflow Management',
        theme: '',
        flag: POST_FLIGHT_DURATION_EDIT,
        name: 'Post Flight Duration Edit',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_MISSION_REPORTS,
        name: 'Mission List Reports',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Workflow Management',
        theme: '',
        flag: FEATURE_RPA_WORKFLOW,
        name: 'RPA Workflows',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_INCIDENT_REPORTS,
        name: 'Incident Reports',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_API_KEYS,
        name: 'API Keys',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_BILLING,
        name: 'Billing and Subscriptions',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'RPAS Management',
        theme: '',
        flag: FEATURE_MAINTENANCE_TASKS,
        name: 'Maintenance Tasks',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'RPAS Management',
        theme: '',
        flag: FEATURE_ADDITIONAL_MAINTENANCE,
        name: 'Battery and Equipment Maintenance (Beta)',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'RPAS Management',
        theme: '',
        flag: FEATURE_RPA_RESOURCE_ASSOCIATION,
        name: 'RPA Resource Association',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'RPAS Management',
        theme: '',
        flag: FEATURE_EXTERNAL_MAINTENANCE_PERSON,
        name: 'External Maintenance Person',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_MISSION_CALENDAR,
        name: 'Mission Calendar',
        appliesTo: 'BOTH'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_NOTAMS,
        name: 'NOTAM Data',
        appliesTo: 'BOTH'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_MAP_MEASUREMENT,
        name: 'Map Measurement Tool',
        appliesTo: 'BOTH'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_FIELD_APP_CRP_TOOLS,
        name: 'FA CRP Tools',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_UPLOAD_SORTIES,
        name: 'Bulk Flight Upload',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_DJI_LOGS,
        name: 'DJI Log Fetching',
        appliesTo: 'BOTH'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_DRONE_DEPLOY_LOGS,
        name: 'DroneDeploy Log Fetching',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_SKYDIO_LOGS,
        name: 'Skydio Log Fetching',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_REPROCESS_FLIGHT_LOGS,
        name: 'Reprocess Flight Logs',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_FLIGHT_LOG_ALERTS,
        name: 'RPA Log Alerting',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_FLIGHT_LOG_AUTOMATIC_MISSION_CONFIGURATION,
        name: 'Flight Log Automatic Mission Creation',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_SETUP_GUIDE,
        name: 'Onboarding Setup Guide Checklist',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_ADDITIONAL_AUTHORITIES,
        name: 'Additional Authorities on Missions',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_ADDITIONAL_FLIGHT_FIELDS,
        name: 'Additional Flight Fields',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_CASA_AUTHORISATION,
        name: 'CASA Authorisation',
        appliesTo: 'BOTH'
    },
    {
        capability: 'Mission Reconciliation',
        theme: '',
        flag: FEATURE_DUMMY_FLIGHT_LOGS,
        name: 'FA Dummy Flight Logs!!!!',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'RPAS Management',
        theme: '',
        flag: FEATURE_BATTERY_HEALTH,
        name: 'Battery Health',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_OUTSTANDING_TASKS_VIEW,
        name: 'Outstanding Tasks View',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_GENERIC_TASKS,
        name: 'Generic Tasks',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_DYNAMIC_REPORTING,
        name: 'Dynamic Reporting (dashboards)',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Mission Planning',
        theme: '',
        flag: FEATURE_FIELD_APP_MISSION_CLONING,
        name: 'FA Mission Cloning',
        appliesTo: 'BOTH'
    },
    {
        capability: 'RPAS Management',
        theme: '',
        flag: FEATURE_MAINTENANCE_NOTICES,
        name: 'Maintenance Notices',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_WORKGROUPS,
        name: 'Workgroups',
        appliesTo: 'COMPANY'
    },
    {
        capability: 'Organisation Management',
        theme: '',
        flag: FEATURE_PUSH_NOTIFICATIONS,
        name: 'FA Push Notifications',
        appliesTo: 'INDIVIDUAL'
    }
];

@Injectable({
    providedIn: 'root'
})
export class AdminFeatureFlagService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findFeatureFlags() {
        return this.http
            .get<FeatureFlagDto[]>(`${this.baseUrl}/adminapi/featureFlags`)
            .pipe(map(flags => this.refactorFlags(flags)));
    }

    refactorFlags(flags: FeatureFlagDto[]) {
        const fixedFlags = flags.map(item => {
            const newThemeCap = uiFeatureFlags.find(
                u => u.capability === item.capability
            );
            if (newThemeCap) {
                return {
                    ...item,
                    capability: newThemeCap.capability,
                    theme: newThemeCap.theme
                };
            } else {
                return {
                    ...item,

                    theme: ''
                };
            }
        });
        return fixedFlags
            .concat(
                uiFeatureFlags.filter(u => flags.every(y => y.flag !== u.flag))
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    findSystemFeatureFlags() {
        return this.http.get<string[]>(
            `${this.baseUrl}/adminapi/featureFlags/system`
        );
    }

    addSystemFeatureFlag(feature: string) {
        return this.http
            .post(`${this.baseUrl}/adminapi/featureFlags/system`, {
                flag: feature
            })
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteSystemFeatureFlag(feature: string) {
        return this.http.delete(
            `${this.baseUrl}/adminapi/featureFlags/system/${feature}`
        );
    }
}
