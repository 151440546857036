import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { Angulartics2Module } from 'angulartics2';
import { AddressesModule } from 'libs/addresses/src/lib/addresses.module';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { InlineModalModule } from 'libs/inline-modal/src/lib/inline-modal.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SubscriptionsModule } from 'libs/subscriptions/src/lib/subscriptions.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { BillingAddressAndTaxDetails } from './billing-address.component';
import { ConfirmPaymentDialogue } from './confirm-payment/confirm-payment.component';
import { CurrentSubscription } from './current-subscription/current-subscription.component';
import { OrganisationSubscriptions } from './organisation-subscriptions.component';
import { PlanSelection } from './plan-selection/plan-selection.component';
import { PurchaseSubscription } from './purchase-subscription/purchase-subscription.component';
import { UpdateSubscription } from './update-subscription/update-subscription.component';
import { UpgradeSubscriptionDialogue } from './upgrade-subscription/upgrade-subscription-dialogue.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TooltipModule,
        ReactiveFormsModule,
        NgSelectModule,
        ButtonsModule,
        NgxLoadingModule,
        ToastrModule,
        FormlyModule,
        FormlyBootstrapModule,
        Angulartics2Module,
        MaterialModule,
        FlyFreelyFormlyModule,
        EnhancedHelpModule,
        AnalyticsModule,
        LoadingIndicatorModule,
        ValidationModule,
        InlineModalModule,
        SharedUiModule,
        LoadingIndicatorModule,
        EditableModule,
        AddressesModule,
        SubscriptionsModule,
        NgxStripeModule.forChild()
    ],
    declarations: [
        OrganisationSubscriptions,
        PlanSelection,
        PurchaseSubscription,
        UpdateSubscription,
        CurrentSubscription,
        BillingAddressAndTaxDetails,
        ConfirmPaymentDialogue,
        UpgradeSubscriptionDialogue
    ],
    exports: [OrganisationSubscriptions]
})
export class OrganisationSubscriptionsModule {}
