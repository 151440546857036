<!-- <div class="row">
                    <div class="col-xs-12">
                        <div ng-if="!$ctrl.addresses" class="alert alert-info" role="alert">
                            You have no addresses recorded currently. Please add a new address using the button below.
                        </div>
                    </div>
                </div> -->
<div class="horizontal-container">
    <button
        type="button"
        class="btn-circle btn-link"
        (click)="newAddress()"
        [disabled]="working"
        data-cy="addNewAddressButton"
    >
        <span class="fa fa-plus"></span>
    </button>
    <div class="fill">
        <table class="table">
            <thead>
                <tr>
                    <th>Address</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="addresses != null">
                <tr *ngFor="let address of addresses">
                    <td>{{ address.street }}</td>
                    <!-- <td>{{address | formatAddress}}</td> -->
                    <td>{{ address.addressType }}</td>
                    <td>{{ address.name }}</td>
                    <td class="actions">
                        <button
                            type="button"
                            class="btn btn-tertiary"
                            (click)="editAddress(address)"
                            [disabled]="working"
                            tooltip="Edit"
                            placement="left"
                        >
                            <span class="fal fa-pencil-alt"></span>
                        </button>
                        <button
                            class="btn btn-tertiary btn-delete"
                            type="button"
                            (click)="deleteAddress(address)"
                            [disabled]="working"
                            tooltip="Delete"
                            placement="left"
                        >
                            <i class="fal fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="addresses.length === 0">
                    <td colspan="3">
                        <div class="alert alert-info" role="alert">
                            You have no addresses recorded currently. Please add
                            a new address using the button below.
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
