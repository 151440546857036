import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { center } from '@turf/center';

const DrawMeasurementRadiusSelect = { ...MapboxDraw.modes.simple_select };

class MeasurementLineSelectState {
    dragMoveLocation: any;
    boxSelectStartLocation: any;
    boxSelectElement: any;
    boxSelecting: boolean;
    canBoxSelect: boolean;
    dragMoving: boolean;
    canDragMove: boolean;
    featureId: string;
    centerCoordinates: number[];
    polygon: any; // DrawFeature
    line: any; // DrawFeature
}
DrawMeasurementRadiusSelect.onSetup = function (opts: any) {
    const state: MeasurementLineSelectState = {
        dragMoveLocation: null,
        boxSelectStartLocation: null,
        boxSelectElement: undefined,
        boxSelecting: false,
        canBoxSelect: false,
        dragMoving: false,
        canDragMove: true,
        featureId: opts.featureId,
        centerCoordinates: [],
        polygon: null,
        line: null
    };

    // @ts-ignore: not in the current type
    this.fireActionable();

    this.setActionableState({
        combineFeatures: true,
        uncombineFeatures: true,
        trash: true
    });

    state.polygon = this.getFeature(opts.featureId);
    state.line = this.getFeature(state.polygon.properties.relatedFeatureIds[0]);
    state.centerCoordinates = center(state.polygon).geometry.coordinates;
    this.setSelected([
        opts.featureId,
        state.polygon.properties.relatedFeatureIds[0]
    ]);
    return state;
};

DrawMeasurementRadiusSelect.onMouseMove = function (
    state: MeasurementLineSelectState,
    e: any
) {
    const isFeature = MapboxDraw.lib.CommonSelectors.isActiveFeature(e);
    if (isFeature) {
        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE });
    }
};

DrawMeasurementRadiusSelect.onDrag = function (
    state: MeasurementLineSelectState,
    e: any
) {
    // @ts-ignore: not in the current type
    return this.dragMove(state, e);
};

// @ts-ignore: not in the current type
DrawMeasurementRadiusSelect.dragMove = function (
    state: MeasurementLineSelectState,
    e: any
) {
    // Dragging when drag move is enabled
    state.dragMoving = true;
    e.originalEvent.stopPropagation();

    const delta = {
        lng: e.lngLat.lng - state.dragMoveLocation.lng,
        lat: e.lngLat.lat - state.dragMoveLocation.lat
    };

    MapboxDraw.lib.moveFeatures(this.getSelected(), delta);

    state.dragMoveLocation = e.lngLat;
};

/**
 * A copy of toDisplayFeatures from simple_select except we remove
 * the vertices
 */
DrawMeasurementRadiusSelect.toDisplayFeatures = function (
    state: MeasurementLineSelectState,
    geojson: GeoJSON.Feature,
    display: (feature: GeoJSON.GeoJSON) => void
) {
    geojson.properties.active = this.isSelected(geojson.properties.id)
        ? MapboxDraw.constants.activeStates.ACTIVE
        : MapboxDraw.constants.activeStates.INACTIVE;
    display(geojson);

    // @ts-ignore: not in the current type
    this.fireActionable();

    if (
        geojson.properties.active !==
            MapboxDraw.constants.activeStates.ACTIVE ||
        geojson.geometry.type === MapboxDraw.constants.geojsonTypes.POINT
    ) {
        return;
    }
    MapboxDraw.lib
        .createSupplementaryPoints(geojson)
        .filter(
            (f: any) => f.properties?.meta !== MapboxDraw.constants.meta.VERTEX
        )
        .forEach(display);
};

export default DrawMeasurementRadiusSelect;
