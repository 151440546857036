/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.16.5-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimpleOrganisationDto } from './simpleOrganisationDto';
import { RulesetDto } from './rulesetDto';
import { SimpleAirspaceJurisdictionDto } from './simpleAirspaceJurisdictionDto';


/**
 * The basic details of an authority type
 */
export interface SimpleAuthorityTypeDto { 
    /**
     * The ID of this authority type
     */
    id: number;
    /**
     * The name of the authority type
     */
    name: string;
    /**
     * The customised name for identifier
     */
    identifierLabel?: string;
    /**
     * Does the authority have an expiry date
     */
    hasExpiry: boolean;
    /**
     * This authority type will also record an identifier such as a serial number
     */
    hasIdentifier: boolean;
    /**
     * Does the authority type support multiple instances
     */
    hasMultipleInstances: boolean;
    /**
     * ${swagger.AuthorityType.isPrimary}
     */
    isPrimary: boolean;
    /**
     * Can the authority type have a workflow associated with it
     */
    hasWorkflow: boolean;
    /**
     * The customised name for authority type
     */
    abbreviation?: string;
    /**
     * Is this authority type requires approval
     */
    requiresApproval: boolean;
    issuedBy: SimpleOrganisationDto;
    /**
     * The type of organisation that this authority applies to
     */
    appliesTo: SimpleAuthorityTypeDto.AppliesTo;
    /**
     * Help text that describes how to get this authority type
     */
    helperText: string;
    /**
     * A short description of the authority type
     */
    description: string;
    /**
     * What kind of activity is this applicable to
     */
    activityType?: SimpleAuthorityTypeDto.ActivityType;
    /**
     * The date when the authority type was discontinued
     */
    discontinueDate?: string;
    /**
     * The message to display on the discontinued authority type
     */
    discontinueMessage?: string;
    jurisdiction?: SimpleAirspaceJurisdictionDto;
    ruleset?: RulesetDto;
    dependentOnAuthorityType?: SimpleAuthorityTypeDto;
}
export namespace SimpleAuthorityTypeDto {
    export type AppliesTo = 'PERSONAL' | 'COMMERCIAL' | 'RPA';
    export const AppliesTo = {
        PERSONAL: 'PERSONAL' as AppliesTo,
        COMMERCIAL: 'COMMERCIAL' as AppliesTo,
        RPA: 'RPA' as AppliesTo
    };
    export type ActivityType = 'COMMERCIAL' | 'NON_COMMERCIAL';
    export const ActivityType = {
        COMMERCIAL: 'COMMERCIAL' as ActivityType,
        NON_COMMERCIAL: 'NON_COMMERCIAL' as ActivityType
    };
}



