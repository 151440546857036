<form [formGroup]="formGroup">
    <div class="organisation_details_container main_container">
        <div
            formGroupName="accountDetails"
            class="onboarding-frame organisationdetails_frameone notfinished organisationdetails_frame"
        >
            <div class="organisationdetails_frametwo--container">
                <div
                    class="organisationdetails_frame--input"
                    *ngIf="accountDetails.controls['businessName']"
                >
                    <label>Business Name</label>
                    <div class="horizontal-container">
                        <input
                            style="flex: 1"
                            type="text"
                            class="form-control"
                            name="businessname"
                            placeholder="Business Name"
                            formControlName="businessName"
                        />
                        <field-validation-indicator
                            [valid]="
                                accountDetails.controls['businessName'].valid
                            "
                            [inline]="true"
                        ></field-validation-indicator>
                    </div>
                </div>

                <!-- <div class="organisationdetails_frame--input">
                    <label>Email of Organisation Owner</label>
                    <input
                        type="email"
                        class="marginbottomzero"
                        name="email_organisationowner"
                        placeholder="Email of Organisation Owner"
                        formControlName="emailOfOrganisationOwner"
                        #emailOfOrganisationOwner
                    />
                </div>

                <div
                    class="organisationdetails_frame--input checkbox_container2 checkboxfont1 forminputcheckbo_marginbottom"
                >
                    <input
                        type="checkbox"
                        name="assign_owner"
                        formControlName="assignMeAsOwner"
                        #assignMeAsOwner
                    />
                    <span class="organisationdetails_checkbox"
                        >Assign me as the Owner</span
                    >
                </div>

                <div class="organisationdetails_frame--input">
                    <label>Organisation Address</label>
                    <input
                        type="text"
                        class="marginbottomzero"
                        name="organisation_address"
                        placeholder="Organisation Address"
                        formControlName="organisationAddress"
                        #organisationAddress
                    />
                </div>

                <div
                    class="organisationdetails_frame--input checkbox_container2 checkboxfont1 forminputcheckbo_marginbottom"
                >
                    <input
                        type="checkbox"
                        name="set_mapstartpoing"
                        formControlName="setAsMapStartPoint"
                        #setAsMapStartPoint
                    />
                    <span class="organisationdetails_checkbox"
                        >Set as Map start point (Add Another)</span
                    >
                </div> -->

                <!-- <div class="organisationdetails_frame--input">
                    <label>Company ARN</label>
                    <input
                        type="text"
                        name="company_arn"
                        placeholder="Company ARN"
                        formControlName="companyARN"
                    />
                </div> -->
            </div>
        </div>

        <div
            formGroupName="jurisdictionSetup"
            class="onboarding-frame organisationdetails_frametwo notfinished organisationdetails_frame"
        >
            <div class="organisationdetails_frame--input">
                <label>Jurisdiction (Country of Operation)</label>
                <div class="horizontal-container">
                    <ng-select
                        style="flex: 1"
                        [items]="jurisdictions"
                        bindLabel="name"
                        placeholder="Jurisdiction"
                        [clearable]="false"
                        formControlName="jurisdiction"
                    >
                    </ng-select>
                    <field-validation-indicator
                        [valid]="jurisdictionSetup.controls.jurisdiction.valid"
                        [inline]="true"
                    >
                    </field-validation-indicator>
                </div>
            </div>
            <br />
        </div>

        <div
            class="onboarding-frame organisationdetails_framethree organisationdetails_frame"
            [ngClass]="{
                notfinished: formGroup.controls.profileImage.invalid
            }"
        >
            <div class="row_top">
                <logo-selection
                    formControlName="profileImage"
                    noun="company logo"
                    [hasLogo]="true"
                ></logo-selection>
            </div>
            <div class="img_seperator">OR CHOOSE</div>
            <div class="row_bottom">
                <img
                    src="/images/onboarding/FF-Onboarding-Commercial-ExampleLogo1.png"
                    data-srcImg="/images/onboarding/FF-Onboarding-Commercial-ExampleLogo1.png"
                    (click)="selectImg(imgPreview_first)"
                    #imgPreview_first
                />
                <img
                    src="/images/onboarding/FF-Onboarding-Commercial-ExampleLogo2.png"
                    data-srcImg="/images/onboarding/FF-Onboarding-Commercial-ExampleLogo2.png"
                    (click)="selectImg(imgPreview_second)"
                    #imgPreview_second
                />
                <img
                    src="/images/onboarding/FF-Onboarding-Commercial-ExampleLogo3.png"
                    data-srcImg="/images/onboarding/FF-Onboarding-Commercial-ExampleLogo3.png"
                    (click)="selectImg(imgPreview_third)"
                    #imgPreview_third
                />
            </div>
        </div>

        <div class="custom--btn">
            <button (click)="previousScreen()" [disabled]="working">
                Back
            </button>
            <button
                class="next"
                name="organisationdetails_next"
                [disabled]="!formGroup.valid || working"
                (click)="nextScreen()"
            >
                Next
            </button>
        </div>
    </div>
</form>
