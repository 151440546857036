import { Injectable } from '@angular/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { CurrencyConfigurationDialogue } from './currency/requirements/currency-configuration.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class CurrencyDialoguesService {
    constructor(private modalService: BsModalService) {}

    showCurrencyConfigurationDialogue(
        airspaceJurisdictionId: number,
        personnelRegisterId: number,
        managingOrganisationId?: number
    ) {
        return this.modalService.show<CurrencyConfigurationDialogue>(
            CurrencyConfigurationDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {
                    airspaceJurisdictionId,
                    personnelRegisterId,
                    managingOrganisationId
                }
            }
        );
    }
}
