<td>
    <ng-select
        [items]="roles"
        [(ngModel)]="missionCrew.missionRoleId"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="disable || readonly"
        [clearable]="false"
        bindValue="id"
        bindLabel="name"
        placeholder="Select a role"
        appendTo="body"
        class="wide-panel"
        (change)="onUpdate()"
    >
    </ng-select>
</td>
<td *ngIf="!selectPerson">
    <div class="horizontal-container vertical-align space-between">
        <a (click)="showPersonDetails.emit(missionCrew.personId)">{{
            crewLookup ? crewLookup[missionCrew.personId] : ''
        }}</a>
        <button
            type="button"
            class="btn btn-tertiary"
            tooltip="Select a different person"
            placement="top"
            (click)="selectPerson = !selectPerson"
            [disabled]="disable || readonly"
        >
            <span class="fal fa-pencil-alt"></span>
        </button>
    </div>
</td>
<td *ngIf="selectPerson">
    <div class="horizontal-container vertical-align">
        <ng-select
            *ngIf="!pilotRegisterDisabled && isPilotRoleSelected"
            [items]="pilots"
            [(ngModel)]="missionCrew.personId"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="disable || readonly"
            [clearable]="false"
            bindValue="id"
            placeholder="Select a person"
            (change)="onUpdate()"
            groupBy="registerStatus"
            appendTo="body"
            class="wide-panel fill"
        >
            <ng-template ng-optgroup-tmp let-item="item">
                {{ item.registerStatus | formatMissionEditRegisterStatus }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                {{ item | formatPerson }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item | formatPerson }}
            </ng-template>
        </ng-select>
        <!-- Use a select without option grouping if the register is disabled -->
        <!-- (this can't be done programmatically without replacing the whole select element) -->
        <ng-select
            *ngIf="pilotRegisterDisabled || !isPilotRoleSelected"
            [items]="pilots"
            [(ngModel)]="missionCrew.personId"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="disable || readonly"
            [clearable]="false"
            bindValue="id"
            placeholder="Select a person"
            (change)="onUpdate()"
            appendTo="body"
            class="wide-panel fill"
        >
            <ng-template ng-label-tmp let-item="item">
                {{ item | formatPerson }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item | formatPerson }}
            </ng-template>
        </ng-select>
        <button
            *ngIf="missionCrew.personId != null"
            type="button"
            class="btn btn-tertiary no-margin"
            tooltip="Cancel"
            placement="top"
            (click)="selectPerson = !selectPerson"
            [disabled]="disable || readonly"
        >
            <span class="fal fa-times"></span>
        </button>
    </div>
</td>
<ng-container
    *ngIf="
        !pilotRegisterDisabled &&
        getPilotRegisterStatus(missionCrew.personId) as status
    "
    [ngSwitch]="status"
>
    <td>
        <span *ngSwitchCase="null"></span>
        <span *ngIf="registerEntriesOnMissionLoading">Loading...</span>
        <span *ngSwitchDefault>
            <ng-container *ngIf="!registerEntriesOnMissionLoading && isPilot">
                <pilot-register-details-overall-status
                    [pilotId]="missionCrew.personId"
                    [status]="status"
                ></pilot-register-details-overall-status>
                <button
                    *ngIf="hasRpa"
                    type="button"
                    class="btn btn-tertiary"
                    (click)="toggleShowPilotRegisterDetails()"
                >
                    <span
                        class="fa"
                        [ngClass]="{
                            'fa-chevron-down': !showRpicRegisterDetails,
                            'fa-chevron-up': showRpicRegisterDetails
                        }"
                        [tooltip]="
                            (showRpicRegisterDetails ? 'Hide ' : 'Show more ') +
                            'register status information'
                        "
                        placement="top"
                    ></span>
                </button>
            </ng-container>
            <ng-container *ngIf="!registerEntriesOnMissionLoading && !isPilot">
                N/A
            </ng-container>
        </span>
    </td>
</ng-container>
<ng-container *ngIf="pilotRegisterDisabled || !missionCrew?.personId">
    <td>{{ pilotRegisterDisabled || missionCrew?.personId ? 'N/A' : '' }}</td>
</ng-container>
<td>
    <button
        type="button"
        class="btn btn-tertiary btn-delete"
        (click)="deleteRow()"
        [disabled]="disable || readonly"
    >
        <span class="fal fa-trash-alt"></span>
    </button>
</td>
