<ng-container screenAnalytics="airworthiness-notices-link-maintenance">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Link Existing Request</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <ngx-loading [show]="working"></ngx-loading>
    <div class="modal-body">
        <div class="panel-body">
            <enhanced-help componentName="screen" [compact]="true">
            </enhanced-help>
            <static-table
                *ngIf="(maintenanceLogs$ | async)?.length > 0"
                [availableColumns]="availableColumns"
                [selectedColumns]="selectedColumns"
                [tableData]="maintenanceLogs$ | async"
                [tableConfig]="tableConfig"
                [centeredColumns]="false"
                [columnSorting]="columnSorting"
                data-cy="maintenanceRequestTable"
            >
                <ng-template columnTemplate="checkbox" let-row="row">
                    <input
                        type="checkbox"
                        [checked]="
                            row.airworthinessNoticeId === airworthinessNoticeId
                        "
                        [disabled]="
                            row.airworthinessNoticeId &&
                            row.airworthinessNoticeId !== airworthinessNoticeId
                        "
                        (change)="onCheckboxChange($event, row)"
                    />
                </ng-template>
                <ng-template
                    columnTemplate="assignee"
                    let-row="row"
                    let-column="column"
                >
                    <div style="width: 150px">
                        <person-avatar [person]="row.assignee"></person-avatar>
                    </div>
                </ng-template>
            </static-table>
            <empty
                *ngIf="(maintenanceLogs$ | async)?.length === 0"
                componentName="no-maintenance-logs"
            >
            </empty>
        </div>
    </div>
    <div class="modal-footer"></div>
</ng-container>
