import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeDistanceClass'
})
export class TimeDistanceClassPipe implements PipeTransform {
    transform = timeDistanceClass;
}

/**
 * The service only returns missions up to a 3 days out
 */
export function timeDistanceClass(timeInSeconds: number) {
    if (timeInSeconds < 24 * 60 * 60) {
        return 'status-icon-green';
    } else if (timeInSeconds < 3 * 24 * 60 * 60) {
        return 'status-icon-yellow';
    }
    return 'status-icon-red';
}
