import { computePath, httpParamSerializer } from './service.helpers';
import {
    CurrentAttachmentVersionDto,
    AcknowledgementDto,
    AttachmentVersionDto,
    AttachmentDto
} from '../model/api';
import { Subject, ReplaySubject, Observable, Subscription } from 'rxjs';
import {
    HttpClient,
    HttpParams,
    HttpEventType,
    HttpEvent,
    HttpRequest
} from '@angular/common/http';
import { tap, last, map } from 'rxjs/operators';
import { Directive } from '@angular/core';

export interface DownloadableAttachmentVersionDto
    extends CurrentAttachmentVersionDto {
    downloadUrl?: string;
}

export interface DownloadableVersionHistoricalDto extends AttachmentVersionDto {
    downloadUrl?: string;
}

export interface IAttachmentHandler {
    findAttachments(): Promise<CurrentAttachmentVersionDto[]>;
    getCanRequireAcknowledgement(): boolean;
}

interface UploadProgress {
    loaded: number;
    total: number;
}

/**
 * This class provides the attachment handling for a collaborator based on a base URL.
 */
export class AttachmentHandler {
    private changeSource = new Subject<void>();
    private attachmentSource = new ReplaySubject<CurrentAttachmentVersionDto[]>(
        1
    );

    change$ = this.changeSource.asObservable();
    attachments$ = this.attachmentSource.asObservable();

    attachments: CurrentAttachmentVersionDto[];

    private destroyOnSubscription: Subscription;

    constructor(
        private http: HttpClient,
        private baseUrl: string,
        private canRequireAcknowledgement: boolean = false,
        private managingOrganisationId: number = null,
        public readonly hasVersionHistory = false
    ) {
        this.findAttachments();
    }

    destroy() {
        this.changeSource.complete();
        this.attachmentSource.complete();

        if (this.destroyOnSubscription != null) {
            this.destroyOnSubscription.unsubscribe();
        }
    }

    destroyOn(observable: Observable<void>) {
        this.destroyOnSubscription = observable.subscribe(() => this.destroy());
    }

    private buildDownloadUrl(
        attachmentId: number,
        attachmentVersionId: number
    ) {
        return computePath(`${this.baseUrl}/${attachmentId}`, {
            managingOrganisationId: this.managingOrganisationId,
            attachmentVersionId
        });
    }

    private findAttachments() {
        let params = new HttpParams();
        if (this.managingOrganisationId) {
            params = params.set(
                'managingOrganisationId',
                this.managingOrganisationId.toString()
            );
        }

        if (params.keys()) {
            this.http
                .get<DownloadableAttachmentVersionDto[]>(`${this.baseUrl}`, {
                    params: params
                })
                .toPromise()
                .then(documents => {
                    documents.forEach(
                        (d: DownloadableAttachmentVersionDto) =>
                            (d.downloadUrl = this.buildDownloadUrl(
                                d.id,
                                d.attachmentVersionId
                            ))
                    );
                    this.attachmentSource.next(documents);
                    this.attachments = documents;
                });
        } else {
            this.http
                .get<DownloadableAttachmentVersionDto[]>(`${this.baseUrl}`)
                .toPromise()
                .then(documents => {
                    documents.forEach(
                        (d: DownloadableAttachmentVersionDto) =>
                            (d.downloadUrl = this.buildDownloadUrl(
                                d.id,
                                d.attachmentVersionId
                            ))
                    );
                    this.attachmentSource.next(documents);
                    this.attachments = documents;
                });
        }
    }

    uploadNewAttachment(
        name: string,
        description: string,
        requirementId: number,
        file: File,
        purpose: string,
        requiresAcknowledgement = false,
        progressCallback: (progress: UploadProgress) => void
    ) {
        const formData = new FormData();
        formData.append('name', name);
        if (purpose) {
            formData.append('purpose', purpose);
        }
        if (description) {
            formData.append('description', description);
        }
        formData.append('file', file);
        formData.append(
            'requiresAcknowledgement',
            requiresAcknowledgement ? 'true' : 'false'
        );
        formData.append(
            'managingOrganisationId',
            this.managingOrganisationId != null
                ? this.managingOrganisationId.toString()
                : null
        );
        if (requirementId) {
            formData.append('requirementId', requirementId.toString());
        }

        const req = new HttpRequest('POST', this.baseUrl, formData, {
            reportProgress: true
        });
        return this.http
            .request(req)
            .pipe(
                map((event: HttpEvent<DownloadableAttachmentVersionDto>) => {
                    switch (event.type) {
                        case HttpEventType.Response:
                            return event.body;
                        case HttpEventType.UploadProgress:
                            progressCallback({
                                loaded: event.loaded,
                                total: event.total
                            });
                            return;
                        default:
                            return;
                    }
                }),
                last()
            )
            .toPromise()
            .then((attachment: DownloadableAttachmentVersionDto) => {
                this.changeSource.next();
                attachment.downloadUrl = this.buildDownloadUrl(
                    attachment.id,
                    attachment.attachmentVersionId
                );
                this.refreshAttachmentsSource(attachment);
                return attachment;
            });
    }

    reuploadAttachment(
        attachmentId: number,
        file: File,
        changes: string,
        progressCallback: (progress: UploadProgress) => void
    ) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('changes', changes);
        formData.append(
            'managingOrganisationId',
            this.managingOrganisationId != null
                ? this.managingOrganisationId.toString()
                : null
        );

        const req = new HttpRequest(
            'PUT',
            `${this.baseUrl}/${attachmentId}`,
            formData,
            { reportProgress: true }
        );
        return this.http
            .request(req)
            .pipe(
                map((event: HttpEvent<DownloadableAttachmentVersionDto>) => {
                    switch (event.type) {
                        case HttpEventType.Response:
                            return event.body;
                        case HttpEventType.UploadProgress:
                            progressCallback({
                                loaded: event.loaded,
                                total: event.total
                            });
                            return;
                        default:
                            return;
                    }
                }),
                last()
            )
            .toPromise()
            .then((attachment: DownloadableAttachmentVersionDto) => {
                this.changeSource.next();
                this.refreshAttachmentsSource(attachment);
                return attachment;
            });
    }

    deleteAttachment(attachmentId: number) {
        let params = new HttpParams();
        if (this.managingOrganisationId) {
            params = params.set(
                'managingOrganisationId',
                this.managingOrganisationId.toString()
            );
        }
        if (params.keys()) {
            return this.http
                .delete(`${this.baseUrl}/${attachmentId}`, { params: params })
                .pipe(
                    tap(() => {
                        this.changeSource.next();
                        this.removeAttachmentFromSource(attachmentId);
                    })
                );
        } else {
            return this.http.delete(`${this.baseUrl}/${attachmentId}`).pipe(
                tap(() => {
                    this.changeSource.next();
                    this.removeAttachmentFromSource(attachmentId);
                })
            );
        }
    }

    findAttachmentHistory(
        attachmentId: number,
        managingOrganisationId?: number
    ) {
        return this.http
            .get<AttachmentDto>(`${this.baseUrl}/${attachmentId}/history`, {
                params: httpParamSerializer({ managingOrganisationId })
            })
            .pipe(
                map(attachment => ({
                    ...attachment,
                    versions: attachment.versions.map(v => ({
                        ...v,
                        downloadUrl: this.buildDownloadUrl(attachment.id, v.id)
                    }))
                }))
            );
    }

    updateAttachmentDetails(
        attachmentId: number,
        command: any,
        requiresAcknowledgement = false
    ) {
        return this.http
            .put(`${this.baseUrl}/${attachmentId}/details`, command, {
                params: new HttpParams().set(
                    'requiresAcknowledgement',
                    requiresAcknowledgement.toString()
                )
            })
            .pipe(tap(() => this.changeSource.next()));
    }

    refreshAttachmentsSource(attachment: DownloadableAttachmentVersionDto) {
        const index = this.attachments.findIndex(a => a.id === attachment.id);
        if (index === -1) {
            if (this.attachments == null) {
                this.attachments = [];
            }
            this.attachments.push(attachment);
            this.attachmentSource.next(this.attachments);
        } else {
            this.attachments[index] = attachment;
            this.attachmentSource.next(this.attachments);
        }
    }

    removeAttachmentFromSource(attachmentId: number) {
        const removed = this.attachments.filter(a => a.id !== attachmentId);
        if (removed.length === this.attachments.length) {
            return;
        }
        this.attachments = removed;
        this.attachmentSource.next(this.attachments);
    }

    getDownloadAllUrl() {
        return computePath(`${this.baseUrl}/all`, {
            managingOrganisationId: this.managingOrganisationId
        });
    }

    getCanRequireAcknowledgement() {
        return this.canRequireAcknowledgement;
    }

    findAcknowledgements(personIds?: number[]) {
        if (
            !this.canRequireAcknowledgement ||
            (personIds != null && personIds.length === 0)
        ) {
            return Promise.resolve([]);
        }

        let params = new HttpParams();
        if (this.managingOrganisationId) {
            params = params.set(
                'managingOrganisationId',
                this.managingOrganisationId.toString()
            );
        }
        if (personIds) {
            params = params.set('personId', personIds.toString());
        }

        return this.http
            .get<AcknowledgementDto[]>(`${this.baseUrl}/acknowledgements`, {
                params: params
            })
            .toPromise();
    }

    acknowledgeAttachment(attachmentId: number, attachmentVersionId: number) {
        let params = new HttpParams();
        if (this.managingOrganisationId) {
            params = params.set(
                'managingOrganisationId',
                this.managingOrganisationId.toString()
            );
        }

        if (params.keys()) {
            return this.http
                .put<AcknowledgementDto>(
                    `${this.baseUrl}/${attachmentId}/${attachmentVersionId}/acknowledge`,
                    null,
                    { params: params }
                )
                .pipe(tap(() => this.changeSource.next()));
        } else {
            return this.http
                .put<AcknowledgementDto>(
                    `${this.baseUrl}/${attachmentId}/${attachmentVersionId}/acknowledge`,
                    null
                )
                .pipe(tap(() => this.changeSource.next()));
        }
    }
}

/**
 * This handler is meant to be a dummy handler when there is not yet anything to attach to.
 */
@Directive()
export class NullAttachmentHandler {
    private attachmentSource = new ReplaySubject<
        CurrentAttachmentVersionDto[]
    >();
    attachments$ = this.attachmentSource.asObservable();

    private destroyOnSubscription: Subscription;

    destroy() {
        this.attachmentSource.complete();

        if (this.destroyOnSubscription != null) {
            this.destroyOnSubscription.unsubscribe();
        }
    }

    destroyOn(observable: Observable<void>) {
        this.destroyOnSubscription = observable.subscribe(() => this.destroy());
    }

    findAttachments() {
        this.attachmentSource.next([]);
    }

    getCanRequireAcknowledgement() {
        return false;
    }

    findAcknowledgements() {
        return Promise.resolve([]);
    }
}
