import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { LinkFactoryService } from './link-manager-factory.service';
import {
    ILinksService,
    LinkAction,
    LinkDetails,
    LinkDto
} from './link-manager.interface';

@Component({
    selector: 'app-link-manager',
    templateUrl: './link-manager.component.html',
    styleUrls: ['./link-manager.component.scss']
})
export class LinkManagerComponent implements OnInit {
    @Input() sectionId: number = -1;
    @Input() showAddForm: boolean = true;
    @Input() allowDelete: boolean = true;

    mode: 'INITIAL' | 'ADD' | 'EDIT' = 'INITIAL';

    private _linkAction: LinkAction;

    get linkAction(): LinkAction {
        return this._linkAction;
    }

    @Input() set linkAction(newLinkAction: LinkAction) {
        this._linkAction = newLinkAction;
        this.linkService = this.linkFactoryService.getLinkService(
            this.linkAction
        );
    }

    // private _sectionId: number = -1;

    // get sectionId(): number {
    //     return this._sectionId;
    // }

    // @Input() set sectionId(newSectionId: number) {
    //          this._sectionId = newSectionId;
    // }

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    private linksSubject = new BehaviorSubject<LinkDto[]>([]);
    links$ = this.linksSubject.asObservable();

    linkForm: FormGroup;
    editingLink: LinkDto | null = null;
    private linkService: ILinksService;
    constructor(
        private linkFactoryService: LinkFactoryService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.refreshLinks();
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.linkService.loadLinks(this.sectionId); // Load the initial links

        this.linkForm = this.fb.group({
            title: ['', Validators.required],
            url: ['', [Validators.required, Validators.pattern('https?://.+')]]
        });
    }

    refreshLinks(): void {
        if (!this.linkService) {
            return; // Do nothing if no link service is set up yet
        }
        if (this.sectionId === -1) {
            return; // Do nothing if no link service is set up yet
        }
        this.linkService
            .loadLinks(this.sectionId)
            .subscribe(links => {
                this.linksSubject.next(links);
            })
            .add(this.workTracker.createTracker());
    }

    // onLinkClick(link: LinkDto): void {
    //         this.editingLink = link;
    //         this.linkForm.patchValue({
    //             title: link.title,
    //             url: link.url
    //         });
    // }
    onLinkClick(link: LinkDto): void {
        if (this.showAddForm) {
            this.editingLink = link;
            this.linkForm.patchValue({
                title: link.title,
                url: link.url
            });
        } else {
            window.open(link.url, '_blank'); // Open link in a new tab if not in edit mode
        }
    }

    saveLink(): void {
        if (this.linkForm.valid) {
            const details: LinkDetails = this.linkForm.value;

            if (this.editingLink) {
                // Update existing link
                this.linkService
                    .updateLink(this.sectionId, this.editingLink.id, details)
                    .subscribe(() => {
                        this.editingLink = null;
                        this.refreshLinks();
                        this.linkForm.reset();
                    })
                    .add(this.workTracker.createTracker());
            } else {
                // Create new link
                this.linkService
                    .createLink(this.sectionId, details)
                    .subscribe(() => {
                        this.linkForm.reset();
                        this.mode = 'INITIAL';
                        this.refreshLinks();
                    })
                    .add(this.workTracker.createTracker());
            }
        }
    }

    cancelEdit(): void {
        this.editingLink = null;
        this.linkForm.reset();
        this.mode = 'INITIAL';
    }

    deleteLink(linkId: number): void {
        this.linkService
            .deleteLink(this.sectionId, linkId)
            .subscribe(() => {
                this.refreshLinks(); // Reload the links after deletion;
            })
            .add(this.workTracker.createTracker());
    }
}
