<ng-container screenAnalytics="mission-reports">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-missions.png" />
        </div>
        <h3 id="dialog-child-name" class="modal-title pull-left">
            Mission Reports
        </h3>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <plan-highlight [featureFlags]="missionReportsFeature"></plan-highlight>
        <div class="bottom-buffer">
            <div class="form-group">
                <label *ngIf="canFilterByAuthority"
                    >Filter Reports By Authority
                </label>
                <ng-select
                    *ngIf="canFilterByAuthority"
                    [items]="missionAuthorityFilters"
                    [(ngModel)]="authorityFilter"
                    (ngModelChange)="updateMissionAuthorityFilter()"
                    [clearable]="false"
                    groupBy="children"
                    appendTo="body"
                    class="wide-panel"
                >
                    <ng-template ng-optgroup-tmp let-item="item">
                        {{ item.name }}
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        ng-label-tmp
                        let-item="item"
                        let-index="index"
                        ><span class="ng-option-label">
                            {{ item.name }}
                            <span
                                *ngIf="item.isPrimary"
                                class="status-block inverted custom"
                                >Primary</span
                            >
                        </span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <tabset>
            <tab heading="Mission Reports">
                <form
                    [formGroup]="missionReportsForm"
                    (submit)="runMissionReport()"
                >
                    <div class="form-group top-buffer">
                        <label>Start Date</label>
                        <field-validation-indicator
                            class="pull-right"
                            validatorFormControlName="startDate"
                        ></field-validation-indicator>
                        <div class="input-group">
                            <input
                                class="form-control"
                                #missionsStartDatePicker="bsDatepicker"
                                bsDatepicker
                                formControlName="startDate"
                            />
                            <span
                                class="input-group-addon btn-default"
                                (click)="missionsStartDatePicker.toggle()"
                                [attr.aria-expanded]="
                                    missionsStartDatePicker.isOpen
                                "
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>

                        <p class="help-block">The start date for the report</p>
                    </div>
                    <div class="form-group top-buffer">
                        <label>End Date</label>
                        <field-validation-indicator
                            class="pull-right"
                            validatorFormControlName="endDate"
                        ></field-validation-indicator>
                        <div class="input-group">
                            <input
                                class="form-control"
                                #missionsEndDatePicker="bsDatepicker"
                                bsDatepicker
                                formControlName="endDate"
                            />
                            <span
                                class="input-group-addon btn-default"
                                (click)="missionsEndDatePicker.toggle()"
                                [attr.aria-expanded]="
                                    missionsEndDatePicker.isOpen
                                "
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>

                        <p class="help-block">The end date for the report</p>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-primary"
                            type="submit"
                            [disabled]="working || missionReportsForm.invalid"
                        >
                            Download
                        </button>
                    </div>
                </form>
            </tab>

            <tab heading="Mission Form Reports">
                <form [formGroup]="formReportsForm" (submit)="runFormReport()">
                    <div class="form-group top-buffer">
                        <p class="help-block">
                            Use this report to collate the values entered into
                            forms during missions. Only the last version of the
                            form responses are used in this report.
                        </p>
                        <div class="form-group">
                            <label>Start Date</label>
                            <field-validation-indicator
                                class="pull-right"
                                validatorFormControlName="startDate"
                            ></field-validation-indicator>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    #formStartDatePicker="bsDatepicker"
                                    bsDatepicker
                                    formControlName="startDate"
                                    (ngModelChange)="refreshForms()"
                                />
                                <span
                                    class="input-group-addon btn-default"
                                    (click)="formStartDatePicker.toggle()"
                                    [attr.aria-expanded]="
                                        formStartDatePicker.isOpen
                                    "
                                >
                                    <i
                                        class="glyphicon glyphicon-calendar"
                                        style="cursor: pointer"
                                    ></i>
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>End Date</label>
                            <field-validation-indicator
                                class="pull-right"
                                validatorFormControlName="endDate"
                            ></field-validation-indicator>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    #formEndDatePicker="bsDatepicker"
                                    bsDatepicker
                                    formControlName="endDate"
                                    (ngModelChange)="refreshForms()"
                                />
                                <span
                                    class="input-group-addon btn-default"
                                    (click)="formEndDatePicker.toggle()"
                                    [attr.aria-expanded]="
                                        formEndDatePicker.isOpen
                                    "
                                >
                                    <i
                                        class="glyphicon glyphicon-calendar"
                                        style="cursor: pointer"
                                    ></i>
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label [ngClass]="{ readonly: working }"
                                >Form</label
                            >
                            <field-validation-indicator
                                class="pull-right"
                                [ngClass]="{ readonly: working }"
                                validatorFormControlName="formVersionId"
                            ></field-validation-indicator>
                            <ng-select
                                formControlName="formVersionId"
                                [items]="forms"
                                [readonly]="
                                    working ||
                                    formReportsForm.controls.startDate
                                        .invalid ||
                                    formReportsForm.controls.endDate.invalid
                                "
                                bindValue="id"
                                bindLabel="formName"
                                placeholder="Select a form"
                                appendTo="body"
                            >
                                <ng-template
                                    ng-label-tmp
                                    ng-option-tmp
                                    let-item="item"
                                >
                                    {{ item.formName }} #{{
                                        item.versionNumber
                                    }}
                                    (Count: {{ item.missionCount }})
                                </ng-template>
                            </ng-select>
                            <p class="help-block">
                                The form selection becomes available once a
                                valid date range is specified.
                            </p>
                        </div>
                        <loading-indicator
                            [isLoading]="working"
                        ></loading-indicator>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-primary"
                            [disabled]="working || formReportsForm.invalid"
                        >
                            Download
                        </button>
                    </div>
                </form>
            </tab>

            <tab heading="Flight Reports">
                <p class="help-block">
                    Use this report to download flight level information across
                    all missions.
                </p>
                <form
                    [formGroup]="flightReportsForm"
                    (submit)="runFlightReport()"
                >
                    <div class="form-group top-buffer">
                        <label>Start Date</label>
                        <field-validation-indicator
                            class="pull-right"
                            validatorFormControlName="startDate"
                        ></field-validation-indicator>
                        <div class="input-group">
                            <input
                                class="form-control"
                                #flightsStartDatePicker="bsDatepicker"
                                bsDatepicker
                                formControlName="startDate"
                            />
                            <span
                                class="input-group-addon btn-default"
                                (click)="flightsStartDatePicker.toggle()"
                                [attr.aria-expanded]="
                                    flightsStartDatePicker.isOpen
                                "
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>

                        <p class="help-block">The start date for the report</p>
                    </div>
                    <div class="form-group top-buffer">
                        <label>End Date</label>
                        <field-validation-indicator
                            class="pull-right"
                            validatorFormControlName="endDate"
                        ></field-validation-indicator>
                        <div class="input-group">
                            <input
                                class="form-control"
                                #flightsEndDatePicker="bsDatepicker"
                                bsDatepicker
                                formControlName="endDate"
                            />
                            <span
                                class="input-group-addon btn-default"
                                (click)="flightsEndDatePicker.toggle()"
                                [attr.aria-expanded]="
                                    flightsEndDatePicker.isOpen
                                "
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>

                        <p class="help-block">The end date for the report</p>
                    </div>

                    <div class="checkbox">
                        <label>
                            <input
                                type="checkbox"
                                formControlName="withFlightLogs"
                            />
                            <span class="cr"
                                ><i class="cr-icon glyphicon glyphicon-ok"></i>
                            </span>
                            Only include flight with flight logs
                        </label>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-primary"
                            [disabled]="working || flightReportsForm.invalid"
                        >
                            Download
                        </button>
                    </div>
                </form>
            </tab>
        </tabset>
    </div>
</ng-container>
