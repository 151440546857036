import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

@Pipe({ name: 'unsafeHtml' })
export class UnsafeHtmlPipe {
    constructor(private sanitizer: DomSanitizer) {}

    /**
     * This will bypass the DOM security so that the click interception can occur.
     * @param text the text or HTML to autolink
     */
    transform(text: string) {
        return this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(text));
    }
}
