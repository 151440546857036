import {
    TypedActiveMapStyleDto,
    TypedDataLayerDto
} from '@flyfreely-portal-ui/flyfreely';
import { Source } from 'graphql';
import { SourceType } from './interfaces';
import { FeatureCollection } from 'geojson';

/**
 * Filters out layers without sources and updates sources with dynamic urls and tiles to load accordingly
 * @param mapSourcesValues The values to use to filter dataLayers and update/disable sources.
 * @returns TypedActiveMapStyleDto
 */
export function updateSourceFilters(
    mapDefaultConfig: TypedActiveMapStyleDto,
    mapSourcesValues: {
        [mapProperty: string]: string;
    },
    mapData: {
        [source: string]: FeatureCollection;
    }
) {
    if (mapDefaultConfig == null) {
        return;
    }
    // Use the mapDefaultConfig here to ensure all dataLayers are available on re-filter.
    const config: TypedActiveMapStyleDto = { ...mapDefaultConfig };

    const reducedSources = config.dataLayers.map(dl =>
        dl.sources
            .map(s =>
                s.type === 'geojson' && s.id in mapData
                    ? { ...s, data: mapData[s.id] }
                    : s
            )
            .filter(
                s => s.type !== 'geojson' || s.data != null || s.url != null
            )
            .reduce(
                (acc, s) =>
                    acc.concat(updateCustomSourceValues(s, mapSourcesValues)),
                [] as SourceType[]
            )
            .filter(s => s != null)
    );
    const withoutBadSources = config.dataLayers.map((dl, i) => ({
        ...dl,
        sources: reducedSources[i]
    }));
    config.dataLayers = removeLayersWithoutSources(withoutBadSources);
    return config;
}

/**
 * Replaces sources url & tiles variables with correct values
 */
function updateCustomSourceValues(
    source: SourceType,
    mapSourcesValues: { [mapProperty: string]: string }
) {
    if (source == null) {
        return source;
    }

    const updatedSource = updateSourceUsingVariables(source, mapSourcesValues);

    /**
     * Removes any set mapbox-gl variables so they don't cause false positives when checking for remaining unassigned variables
     */
    const removeMapboxVariables = (value: string) => {
        return value.replace(`{x}`, '').replace('{y}', '').replace('{z}', '');
    };

    if (
        (updatedSource.type === 'raster' || updatedSource.type === 'vector') &&
        updatedSource.tiles != null &&
        updatedSource.tiles.length > 0
    ) {
        const strippedTiles =
            updatedSource.tiles != null
                ? updatedSource.tiles.map(t => removeMapboxVariables(t))
                : [];
        const remainingTiles = strippedTiles.filter(
            t => t.match(/[{](\w*\W*\/{0})[}]/g) != null
        );
        if (remainingTiles.length > 0) {
            return null;
        }
        return updatedSource;
    } else {
        // Strip fixed mapbox variables from any remaining urls and tiles
        const strippedUrl =
            updatedSource.url != null
                ? removeMapboxVariables(updatedSource.url)
                : '';

        // Then check that no other {} variables remain that don't have forward slashes between the brackets.
        // A slash between brackets could mean the brackets are intentional.
        const remainingUrl = strippedUrl.match(/[{](\w*\W*\/{0})[}]/g);

        // If any unassigned variables remain, make this source null. It will be filtered out after returning, deactivating it in the map.
        if (remainingUrl != null) {
            return null;
        }
        return updatedSource;
    }
}

/** Using the mapSourcesValues object, replace all url & tile variables accordingly in the airspaceSources data
 */
function updateSourceUsingVariables(
    source: SourceType,
    mapSourcesValues: { [mapProperty: string]: string }
) {
    if (mapSourcesValues == null) {
        return source;
    }
    const templateItems = Object.keys(mapSourcesValues).filter(
        key => mapSourcesValues[key] != null
    );

    const { url, tiles } = templateItems.reduce(
        (acc, key) => {
            if (acc.url != null && acc.url.includes(`{${key}}`)) {
                acc.url = acc.url.replace(`{${key}}`, mapSourcesValues[key]);
            }

            if (
                acc.tiles != null &&
                acc.tiles.find(t => t.includes(`{${key}}`)) != null
            ) {
                acc.tiles = acc.tiles.map(tile =>
                    tile.replace(`{${key}}`, mapSourcesValues[key])
                );
            }
            return acc;
        },
        {
            url: source.url,
            tiles:
                source.type === 'raster' || source.type === 'vector'
                    ? source.tiles
                    : undefined
        }
    );

    if (url != null && source.url != url) {
        return {
            ...source,
            url
        };
    }
    if (
        (source.type === 'raster' || source.type === 'vector') &&
        tiles != null
    ) {
        return {
            ...source,
            tiles
        };
    }
    return source;
}

/**
 * Removes any layers that require sources that aren't available in the current config
 * Addresses sources that have been removed by updateCustomSourceValues() causing errors in dependent layers.
 * @param dataLayers the data layers that make up the map config
 */
function removeLayersWithoutSources(dataLayers: TypedDataLayerDto[]) {
    const sourceIds = dataLayers
        .reduce(
            (acc, dl) => acc.concat(dl.sources.map((s: SourceType) => s.id)),
            []
        )
        .concat(['NAIPS_PRD', 'START_END_TIME']);
    const layers = dataLayers.map(dl => ({
        ...dl,
        layers: {
            groups: dl.layers.groups
                .map(g => ({
                    ...g,
                    layers: g.layers.filter(l => sourceIds.includes(l.source))
                }))
                .map((g, i) => {
                    if (g.layers.length === 0) {
                        console.log(
                            `ignoring layer group '${g.name}' since it has no layers with a valid source`,
                            dl.layers.groups[i]
                        );
                    }
                    return g;
                })
                .filter(g => g.layers.length > 0),
            ungrouped: dl.layers.ungrouped
                .map(ug => {
                    if (!sourceIds.includes(ug.source)) {
                        console.log(
                            `ignoring ungrouped layer '${ug.name}' since it has a missing source`,
                            ug
                        );
                    }
                    return ug;
                })
                .filter(ug => sourceIds.includes(ug.source))
        }
    }));

    return layers.filter(
        dl => dl.layers.groups.length > 0 || dl.layers.ungrouped.length > 0
    );
}

/**
 * Returns any non-mapbox tokens from the URLs and tiles.
 * @param dataLayerList all data layers to search
 */
export function uniqueUrlTokens(dataLayerList: TypedDataLayerDto[]) {
    return dataLayerList.reduce((acc, dl) => {
        dl.sources.reduce((acc2, s) => {
            if (
                s.url != null &&
                s.url
                    ?.replace(`{x}`, '')
                    .replace('{y}', '')
                    .replace('{z}', '')
                    .match(/[{](\w*\W*\/{0})[}]/g) != null
            ) {
                const items: string[] = s.url
                    .replace(`{x}`, '')
                    .replace('{y}', '')
                    .replace('{z}', '')
                    .match(/[{](\w*\W*\/{0})[}]/g);
                // strip the curly brackets
                const stripped = items.map(item => item.slice(1).slice(0, -1));
                stripped.forEach(s => acc2.add(s));
            }
            if (s.type !== 'geojson' && s.tiles != null) {
                s.tiles.forEach(t => {
                    if (
                        t != null &&
                        t
                            .replace(`{x}`, '')
                            .replace('{y}', '')
                            .replace('{z}', '')
                            .match(/[{](\w*\W*\/{0})[}]/g) != null
                    ) {
                        const items: string[] = t
                            .replace(`{x}`, '')
                            .replace('{y}', '')
                            .replace('{z}', '')
                            .match(/[{](\w*\W*\/{0})[}]/g);
                        // strip the curly brackets
                        const stripped = items.map(item =>
                            item.slice(1).slice(0, -1)
                        );
                        stripped.forEach(s => acc2.add(s));
                    }
                });
            }
            return acc2;
        }, acc);
        return acc;
    }, new Set<string>());
}
