<div class="container-fluid">
    <div
        *ngIf="selectedSections.generalTasks && generalTasksWidgets?.length"
        #generalTasksSection
        class="view-section"
        @expandOnEnter
        @collapseOnLeave
    >
        <h4 class="section-heading">
            <span class="fa fa-list-check"></span>&nbsp;General Tasks
            <div></div>
        </h4>
        <div *ngFor="let widget of generalTasksWidgets" class="widget-spacing">
            <ng-container [widget]="widget"></ng-container>
        </div>
    </div>
    <div
        *ngIf="selectedSections.operations && operationsWidgets?.length"
        #operationsSection
        class="view-section"
        @expandOnEnter
        @collapseOnLeave
    >
        <h4 class="section-heading">
            <span class="fa fa-industry-windows"></span>&nbsp;Operations
            <div></div>
        </h4>
        <div *ngFor="let widget of operationsWidgets" class="widget-spacing">
            <ng-container [widget]="widget"></ng-container>
        </div>
    </div>
    <div
        *ngIf="selectedSections.resources && resourcesWidgets?.length"
        #resourcesSection
        class="view-section"
        @expandOnEnter
        @collapseOnLeave
    >
        <h4 class="section-heading">
            <span class="fa fa-drone"></span>&nbsp;Resources
            <div></div>
        </h4>
        <div *ngFor="let widget of resourcesWidgets" class="widget-spacing">
            <ng-container [widget]="widget"></ng-container>
        </div>
    </div>
    <div
        *ngIf="selectedSections.organisation && organisationWidgets?.length"
        #organisationSection
        class="view-section"
        @expandOnEnter
        @collapseOnLeave
    >
        <h4 class="section-heading">
            <span class="fa fa-buildings"></span>&nbsp;Organisation
            <div></div>
        </h4>
        <div *ngFor="let widget of organisationWidgets" class="widget-spacing">
            <ng-container [widget]="widget"></ng-container>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
