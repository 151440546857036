import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';

import { filter } from 'rxjs/operators';
import { LoginEvent, UserService } from './system/user.service';

interface Target {
    to: string;
    params: Params;
}

/**
 * Manages the navigation state, specifically, the next state to transition to following login.
 */
@Injectable({
    providedIn: 'root'
})
export class NavigationState {
    private originalTarget: Target;

    private usingInviteFlow = false;

    BASE_URL: string;

    constructor(
        userService: UserService,
        constants: FlyFreelyConstants,
        private router: Router
    ) {
        this.BASE_URL = constants.SITE_URL;

        userService.loginEvent$
            .pipe(filter(e => e === LoginEvent.LOGIN))
            .subscribe(() => {
                if (!this.usingInviteFlow) {
                    // TODO Verify this Router
                    this.router.navigateByUrl(this.parseNextStateUrl());
                }
            });
    }

    ngOnDestroy() {
        console.log('destroyed');
    }

    parseNextStateUrl() {
        const { to, params } = this.nextStateAfterLogin();
        const paramsParsed =
            params != null
                ? Object.keys(params)
                      .map(p => `${p.toString()}=${params[p].toString()}`)
                      .join('&')
                : '';
        return `${this.BASE_URL}${to === 'dashboard' ? 'home' : to}${
            paramsParsed.length > 0 ? '?' : ''
        }${paramsParsed}`;
    }

    /**
     * Returns the next state to go to after login. This will either be the recorded state, or go to
     * the generic post-login landing page.
     */
    nextStateAfterLogin(): Target {
        if (this.originalTarget != null) {
            const originalTarget = this.originalTarget;
            this.originalTarget = null;
            return originalTarget;
        }

        return { to: 'landing', params: {} };
    }

    /**
     * Sets the state to go back to after login
     * @param to the state name to go to
     * @param params the params for this state
     */
    setNextStateAfterLogin(to: string, params: Params) {
        if (this.originalTarget != null) {
            return;
        }
        this.originalTarget = { to, params };
    }

    startInviteFlow() {
        this.usingInviteFlow = true;
    }

    finishInviteFlow() {
        this.usingInviteFlow = false;
    }
}
