import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightConformanceResultDto } from '@flyfreely-portal-ui/flyfreely';
import { flightConformanceStatusLookup } from '@flyfreely-portal-ui/flyfreely';

/**
 * FIXME move to a conformance module once Nx migration is done.
 */
@Component({
    template: `<span
            *ngIf="status === 'NO_BREACHES'"
            class="status-block active"
            >No Breaches</span
        >
        <span *ngIf="status === 'NO_POLICY'" class="status-block disabled"
            >No Policy</span
        >
        <span
            *ngIf="status === 'PRELIMINARY' || status === 'SENT'"
            class="status-block pending"
            >Breaches</span
        >`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'conformance-status-icon',
    styles: [
        `
            .breach {
                --fa-primary-color: #595974;
                --fa-secondary-color: #fecb1c;
            }
        `
    ]
})
export class ConformanceStatusIcon {
    @Input()
    status: FlightConformanceResultDto.Status;

    flightConformanceStatusLookup = flightConformanceStatusLookup;
}
