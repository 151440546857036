<div *ngIf="!simplifiedView" class="modal-header">
    <h3 class="modal-title">Apply Approval Conditions</h3>
    <button
        type="button"
        (click)="cancel.emit()"
        aria-label="Close"
        class="close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div [ngClass]="{ 'modal-body': !simplifiedView }">
    <table class="table">
        <tbody *ngFor="let library of conditionLibraries">
            <tr *ngIf="library.conditions.length != 0">
                <td>
                    <input
                        type="checkbox"
                        [(checklist)]="selectedLibraries"
                        [checklistValue]="library.id"
                        (checklistChange)="toggleSelectAll(library)"
                    />
                </td>
                <td class="wordwrap">
                    <h5>
                        {{ library.name }}
                    </h5>
                </td>
            </tr>
            <tr *ngFor="let c of library.conditions">
                <td>
                    <input
                        type="checkbox"
                        [(checklist)]="selectedConditions"
                        [checklistValue]="c.id"
                        (checklistChange)="
                            toggleConditionSelected(c.condition, library)
                        "
                    />
                </td>
                <td class="wordwrap">
                    {{ c.condition }}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div
    [ngClass]="{
        'modal-footer': !simplifiedView,
        'horizontal-container': simplifiedView
    }"
>
    <button class="btn" (click)="cancel.emit()">Cancel</button>
    <button
        class="btn btn-primary"
        (click)="addConditions()"
        [disabled]="selectedConditions.length === 0"
    >
        Add Selected
    </button>
</div>
