import { FormlyFieldConfig } from '@ngx-formly/core';
import * as FileSaver from 'file-saver';
import * as Papa from 'papaparse';

/**
 * Parse a CSV file into an array of objects.
 * @param file the CSV file
 * @param headingLookup the mapping from heading to key
 */
export function parseCsvByHeadings<T = any>(
    file: File,
    headingLookup: { [heading: string]: number | string }
) {
    return new Promise<T[]>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                const values: any = Papa.parse(reader.result, {
                    header: true,
                    skipEmptyLines: true
                });

                const expectedHeadings = Object.keys(headingLookup);
                resolve(
                    values.data.map(row =>
                        expectedHeadings.reduce(
                            (acc, h) => ({
                                ...acc,
                                [headingLookup[h]]: row[h]
                            }),
                            {} as T
                        )
                    )
                );
                return;
            }
            reject('File type not readable');
        };

        reader.onerror = () => reject('Could not read file');
        reader.onabort = () => reject('File read aborted');

        reader.readAsText(file);
    });
}

/**
 * Produce a CSV blob of the provided values according to the Formly config.
 * @param field fields to export
 * @param value data
 */
export function downloadCsv(field: FormlyFieldConfig, value: any[]) {
    const content = produceCsv(field, value);
    const blob = new Blob([content], {
        type: 'text/csv;charset=utf-8'
    });
    FileSaver.saveAs(blob, `${field.props.label}.csv`);
}

/**
 * Produce a CSV string output to template the given field group
 * @param field the formly field config for a repeating section
 */
export function produceCsv(field: FormlyFieldConfig, data: any[]) {
    const fieldIds = (<FormlyFieldConfig>field.fieldArray).fieldGroup.map(
        f => <string>f.key
    );
    const dataRows = data.map(row =>
        fieldIds.map(id => (row[id] != null ? row[id] : ''))
    );

    return Papa.unparse([extractHeadings(field), ...dataRows]);
}

// function extractHeadings(field: FormlyFieldConfig): string[] {
//     return field.fieldArray.fieldGroup.map(
//         f => f.props.heading || f.props.label
//     );
// }
function extractHeadings(field: FormlyFieldConfig): string[] {
    // Check if fieldArray is a function and call it if necessary
    const fieldArray = <FormlyFieldConfig>field.fieldArray;

    return (
        fieldArray?.fieldGroup?.map(f => f.props.heading || f.props.label) || []
    );
}

export function removeEmptyRows<T = any>(rows: any) {
    rows.forEach((row: any) =>
        Object.keys(row).forEach(key => {
            if (row[key] == null) {
                row[key] = '';
            }
        })
    );
    return rows.filter(
        (row: any) =>
            Object.keys(row).filter(key => row[key] !== '' && row[key] != null)
                .length > 0
    ) as T;
}
