import { Injectable } from '@angular/core';
import {
    FlightConformanceResultDto,
    FlyFreelyError,
    GqlFilterField,
    GqlSortField,
    MissionRoleDto,
    MissionService,
    MissionSummaryDto,
    PersonDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { ColumnSortPreferences } from '@flyfreely-portal-ui/flyfreely-table';
import moment, { Moment } from 'moment';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface OutstandingStatusMission {
    id: number;
    name: string;
    rpic: PersonDto;
    missionDate: string;
    timeZone: string;
    convertedMissionDate: Moment;
    locationName: string;
    rpaNicknames: string;
    workflowName: string;
}

@Injectable()
export class MissionOutstandingStatusDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private missionsSource = new ReplaySubject<OutstandingStatusMission[]>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);

    private searchCriteriaSubject = new ReplaySubject<{
        page: number;
        limit: number;
        sorting: ColumnSortPreferences;
        filters: GqlFilterField[];
        organisationId: number;
        conformanceResultStatus: FlightConformanceResultDto.Status[];
        authorityIds?: number[];
        orOrganisationId?: number;
        status?: MissionSummaryDto.Status[];
    }>(1);

    searchCriteria$ = this.searchCriteriaSubject.asObservable();

    working$ = this.workingSource.asObservable();
    missions$ = this.missionsSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private missionService: MissionService) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));

        this.searchCriteriaSubject.subscribe(criteria =>
            this.updateMissionTable(criteria)
        );
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.missionsSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.searchCriteriaSubject.complete();
    }

    findMissions(
        page: number,
        limit: number,
        sorting: ColumnSortPreferences,
        organisationId: number,
        requiredStatus: 'approval' | 'finalisation',
        authorityIds?: number[],
        orOrganisationId?: number
    ) {
        const filters: GqlFilterField[] =
            requiredStatus === 'approval'
                ? [
                      { field: 'isDummy', filter: false },
                      { field: 'outstandingApprovals', filter: true }
                  ]
                : [
                      { field: 'isDummy', filter: false },
                      { field: 'readyForFinalisation', filter: true }
                  ];
        const status: MissionSummaryDto.Status[] =
            requiredStatus === 'approval'
                ? [MissionSummaryDto.Status.DRAFT]
                : [MissionSummaryDto.Status.COMPLETED];
        const conformanceResultStatus: FlightConformanceResultDto.Status[] = null;
        this.searchCriteriaSubject.next({
            page,
            limit,
            sorting,
            filters,
            organisationId,
            conformanceResultStatus,
            authorityIds,
            orOrganisationId,
            status
        });
    }

    private updateMissionTable({
        page,
        limit,
        sorting,
        filters,
        organisationId,
        authorityIds,
        orOrganisationId,
        status,
        conformanceResultStatus
    }: {
        page: number;
        limit: number;
        sorting: ColumnSortPreferences;
        filters: GqlFilterField[];
        organisationId: number;
        authorityIds?: number[];
        orOrganisationId?: number;
        status?: MissionSummaryDto.Status[];
        conformanceResultStatus: FlightConformanceResultDto.Status[];
    }) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const doneWorking = this.workTracker.createTracker();

        // this.subscriptionTypes$ is used here to ensure it has a value and prevent a race condition.
        const sortFields: GqlSortField = {
            field: this.parseFilterFieldNames(sorting?.column ?? null),
            order: sorting?.ascending ? 'ASC' : 'DESC'
        };
        this.missionService
            .findMissionSummaries(
                organisationId,
                {
                    page: page,
                    pageSize: limit,
                    sortFields: sorting != null ? [sortFields] : [],
                    filters: filters
                },
                conformanceResultStatus,
                authorityIds,
                orOrganisationId,
                status
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: missions => {
                    const findRpic = mission => {
                        return mission.missionCrew.find(
                            p =>
                                p.missionRole.coreRole ===
                                MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                        ).person;
                    };
                    const findRpaNames = mission => {
                        const names = mission.crafts
                            ?.map((c, i) =>
                                i < 3 ? c.nickname : i === 3 ? '...' : null
                            )
                            .filter(c => c != null);
                        return names.join(', ');
                    };
                    const missionDetails: OutstandingStatusMission[] = missions.results.map(
                        (r, i) => ({
                            id: r.id,
                            name: r.name,
                            rpic: findRpic(r),
                            missionDate: r.missionDate,
                            timeZone: r.timeZone,
                            convertedMissionDate: moment(r.missionDate).tz(
                                r.timeZone
                            ),
                            locationName: r.location?.name,
                            rpaNicknames: findRpaNames(r),
                            workflowName: r.workflowVersion?.workflow?.name
                        })
                    );
                    this.totalItemsSource.next(missions.count);
                    this.currentPageSource.next(page);
                    this.missionsSource.next(missionDetails);
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    console.error(error);
                    this.missionsSource.next([]);
                    this.totalItemsSource.next(0);
                    this.currentPageSource.next(0);
                    doneWorking();
                }
            });
    }

    parseFilterFieldNames(name: string) {
        switch (name) {
            case 'missionCrewNames':
                return 'missionCrew';

            case 'missionType.name':
                return 'missionOperationType';

            case 'convertedMissionDate':
                return 'missionDate';

            default:
                return name;
        }
    }
}
