import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouterTabDirective } from './router-tab.directive';

/**
 * Provides a directive to redirecting to a specific tab on a screen. To achieve this
 * each tab needs the directive to specify the tab's identifier. As well, the routes
 * need to be configured to encode the tab in the URL, and to make the route dynamically
 * change its parameters.
 */
@NgModule({
    imports: [RouterModule],
    declarations: [RouterTabDirective],
    exports: [RouterTabDirective]
})
export class RouterTabModule {}
