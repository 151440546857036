<ng-container screenAnalytics="workgroups-setup">
    <enhanced-help componentName="screen" [compact]="true"> </enhanced-help>
    <div
        class="modal-body-content container-scroll"
        [formGroup]="workgroupFormGroup"
    >
        <div class="horizontal-container vertical-align bottom-buffer">
            <span class="display-label right-buffer">Name</span>
            <editable
                #workgroupEditorRef
                class="fcol-6"
                [confirmationButtons]="true"
                [editButton]="true"
                [cancelOnClickOutside]="false"
                [editOnly]="hasNoWorkgroups"
                [updateFn]="updateOrCreateName"
                (modeChange)="onWorkgroupNameModelChange($event)"
                [disabled]="
                    (!isAddingWorkgroup &&
                        workgroupFormGroup.controls.workgroupNameSelector
                            .invalid) ||
                    (isAddingWorkgroup &&
                        workgroupFormGroup.controls.workgroupNameInput
                            .invalid) ||
                    working
                "
                (clickCancel)="onClickCancel()"
            >
                <ng-template viewMode>
                    <ng-select
                        placeholder="Select"
                        class="mission-organisation-select"
                        [items]="workgroup$ | async"
                        formControlName="workgroupNameSelector"
                        [clearable]="false"
                        appendTo="body"
                    >
                        <ng-template
                            ng-option-tmp
                            ng-label-tmp
                            let-item="item"
                            let-index="index"
                            ><span class="ng-option-label" [title]="item.name">
                                {{ item.name }}
                            </span>
                        </ng-template>
                    </ng-select>
                </ng-template>
                <ng-template editMode let-state="state">
                    <input
                        type="text"
                        formControlName="workgroupNameInput"
                        class="form-control"
                        editableOnEnter
                    />
                    <div class="text-danger" *ngIf="state.error != null">
                        {{ state.error }}
                    </div>
                </ng-template>
            </editable>
            <button
                type="button"
                class="btn-circle btn-link"
                [tooltip]="'Create new workgroup'"
                placement="right"
                [disabled]="locked || isAddingWorkgroup"
                (click)="onClickAddWorkGroup(workgroupEditorRef)"
            >
                <span class="fa fa-plus"></span>
            </button>
        </div>
        <ng-container
            *ngIf="(workgroup$ | async)?.length === 0; else workgroupArea"
        >
            <empty componentName="workgroups-empty"></empty>
        </ng-container>
        <ng-template #workgroupArea>
            <div>
                <form [formGroup]="settingsForm">
                    <div class="form-group top-buffer">
                        <label>Default Operation Type</label>
                        <ng-select
                            [items]="operationTypesList$ | async"
                            bindLabel="name"
                            bindValue="id"
                            placeholder="The default mission operation type for new missions"
                            formControlName="defaultOperationTypeId"
                            appendTo="body"
                            [readonly]="locked || working"
                        >
                        </ng-select>
                    </div>
                    <div class="form-group top-buffer">
                        <label>Default Mission Workflow</label>
                        <ng-select
                            [items]="organisationWorkflowList$ | async"
                            bindLabel="name"
                            bindValue="id"
                            placeholder="The default mission workflow to use on new missions"
                            formControlName="defaultMissionWorkflowId"
                            appendTo="body"
                            [readonly]="locked || working"
                        >
                            <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-index="index"
                            >
                                <span
                                    [ngClass]="{
                                        error:
                                            item.delegatedAuthority?.status ===
                                            'EXPIRED'
                                    }"
                                >
                                    {{ item.name
                                    }}{{
                                        item.delegatedAuthority?.status ===
                                        'EXPIRED'
                                            ? ' (expired)'
                                            : ''
                                    }}
                                </span>
                            </ng-template>
                            <ng-template
                                ng-label-tmp
                                let-item="item"
                                let-index="index"
                            >
                                <span
                                    [ngClass]="{
                                        error:
                                            item.delegatedAuthority?.status ===
                                            'EXPIRED'
                                    }"
                                >
                                    {{ item.name
                                    }}{{
                                        item.delegatedAuthority?.status ===
                                        'EXPIRED'
                                            ? ' (expired)'
                                            : ''
                                    }}
                                </span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group top-buffer">
                        <label>Default Maximum Height (ft)</label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="defaultMaximumHeight"
                        />
                    </div>
                </form>
                <div class="fill">
                    <button
                        type="button"
                        class="btn btn-primary pull-right"
                        [disabled]="
                            settingsForm.invalid ||
                            settingsForm.pristine ||
                            working
                        "
                        (click)="updateSettings()"
                    >
                        Save Workgroup Defaults
                    </button>
                </div>
            </div>
            <div class="relative">
                <tabset>
                    <tab heading="Personnel" [disabled]="locked">
                        <static-table
                            [availableColumns]="personnelColumns"
                            [tableData]="
                                (workgroupNameSelectorValue$ | async)
                                    ?.personnelList
                            "
                            [tableConfig]="tableConfig"
                        >
                            <ng-template rowActions let-row="row">
                                <button
                                    class="btn btn-tertiary btn-delete"
                                    type="button"
                                    tooltip="Delete personnel"
                                    placement="left"
                                    (click)="
                                        onDeletePersonnelFromWorkgroup(row)
                                    "
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </ng-template>
                        </static-table>
                        <div class="input-group">
                            <ng-select
                                placeholder="Select"
                                [items]="personnelList$ | async"
                                formControlName="personnelSelector"
                                [clearable]="false"
                                appendTo="body"
                                [readonly]="locked || working"
                            >
                                <ng-template
                                    ng-option-tmp
                                    ng-label-tmp
                                    let-item="item"
                                    let-index="index"
                                    ><span
                                        class="ng-option-label"
                                        [title]="item.name"
                                    >
                                        {{ item.name }}
                                    </span>
                                </ng-template>
                            </ng-select>
                            <span class="input-group-btn">
                                <button
                                    class="btn btn-primary"
                                    [disabled]="
                                        locked ||
                                        working ||
                                        this.workgroupFormGroup.controls
                                            .personnelSelector.invalid
                                    "
                                    (click)="onAddPersonnelToWorkgroup()"
                                >
                                    Add
                                </button>
                            </span>
                        </div>
                    </tab>
                    <tab heading="RPA" [disabled]="locked">
                        <static-table
                            [availableColumns]="rpaColumns"
                            [tableData]="
                                (workgroupNameSelectorValue$ | async)?.rpaList
                            "
                            [tableConfig]="tableConfig"
                        >
                            <ng-template
                                columnTemplate="nickname"
                                let-row="row"
                                let-column="column"
                            >
                                <status-icon [rpa]="row"></status-icon>
                            </ng-template>
                            <ng-template rowActions let-row="row">
                                <button
                                    class="btn btn-tertiary btn-delete"
                                    type="button"
                                    tooltip="Delete RPA"
                                    placement="left"
                                    (click)="onDeleteRpaFromWorkgroup(row)"
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </ng-template>
                        </static-table>
                        <div class="input-group">
                            <ng-select
                                placeholder="Select"
                                [items]="rpaList$ | async"
                                formControlName="craftNameSelector"
                                [clearable]="false"
                                appendTo="body"
                                [readonly]="locked || working"
                            >
                                <ng-template
                                    ng-option-tmp
                                    ng-label-tmp
                                    let-item="item"
                                    let-index="index"
                                    ><span
                                        class="ng-option-label"
                                        [title]="item.nickname"
                                    >
                                        {{ item.nickname }}
                                    </span>
                                </ng-template>
                            </ng-select>
                            <span class="input-group-btn">
                                <button
                                    class="btn btn-primary"
                                    [disabled]="
                                        locked ||
                                        working ||
                                        this.workgroupFormGroup.controls
                                            .craftNameSelector.invalid
                                    "
                                    (click)="onAddRpaToWorkgroup()"
                                >
                                    Add
                                </button>
                            </span>
                        </div>
                    </tab>
                    <tab heading="Workflows" [disabled]="locked">
                        <static-table
                            [availableColumns]="workflowsColumns"
                            [tableData]="
                                (workgroupNameSelectorValue$ | async)
                                    ?.workflowList
                            "
                            [tableConfig]="tableConfig"
                        >
                            <ng-template rowActions let-row="row">
                                <button
                                    class="btn btn-tertiary btn-delete"
                                    type="button"
                                    tooltip="Delete personnel"
                                    placement="left"
                                    (click)="onDeleteWorkflowFromWorkgroup(row)"
                                >
                                    <span class="fal fa-trash-alt"></span>
                                </button>
                            </ng-template>
                        </static-table>
                        <div class="input-group">
                            <ng-select
                                placeholder="Select"
                                [items]="workflowList$ | async"
                                formControlName="workflowSelector"
                                [clearable]="false"
                                appendTo="body"
                                [readonly]="locked || working"
                            >
                                <ng-template
                                    ng-option-tmp
                                    ng-label-tmp
                                    let-item="item"
                                    let-index="index"
                                    ><span
                                        class="ng-option-label"
                                        [title]="item.name"
                                    >
                                        {{ item.name }}
                                    </span>
                                </ng-template>
                            </ng-select>
                            <span class="input-group-btn">
                                <button
                                    class="btn btn-primary"
                                    [disabled]="
                                        locked ||
                                        working ||
                                        this.workgroupFormGroup.controls
                                            .workflowSelector.invalid
                                    "
                                    (click)="onAddWorkflowToWorkgroup()"
                                >
                                    Add
                                </button>
                            </span>
                        </div>
                    </tab>
                </tabset>
            </div>
        </ng-template>
        <ngx-loading [show]="working"></ngx-loading>
    </div>
</ng-container>
