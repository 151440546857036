<div class="table-container no-actions fill">
    <table
        class="table"
        *ngIf="
            entrySummary != null &&
            entrySummary.registerStatusGroupList != null &&
            entrySummary.registerStatusGroupList.length > 0 &&
            statusSource == 'AUTO'
        "
    >
        <thead [ngClass]="{ 'row-even': rowEven }">
            <th style="width: 35%">RPA Type</th>
            <th style="width: 35%">RPA in type</th>
            <th style="width: 30%">Status</th>
        </thead>
        <tbody [ngClass]="{ 'row-even': rowEven }">
            <tr *ngFor="let rpa of entrySummary.registerStatusGroupList">
                <td style="white-space: normal">
                    {{ rpaTypes[rpa.rpaIdList[0]] }}
                </td>
                <td style="white-space: normal">
                    {{ rpas[rpa.rpaIdList[0]] }}
                </td>
                <td>
                    <span
                        class="status-block"
                        [ngClass]="rpa.registerStatus | registerStatusClass"
                    >
                        {{
                            rpa.registerStatus | formatMissionEditRegisterStatus
                        }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <span
        *ngIf="
            entrySummary != null &&
            statusSource !== 'AUTO' &&
            statusSource !== 'NONE'
        "
        style="max-width: 100%; white-space: normal"
    >
        <ng-container
            *ngIf="
                statusSource === 'ACTIVE' ||
                statusSource === 'PENDING' ||
                statusSource === 'SUSPENDED'
            "
        >
            This person's status on the register has manually been set to
            {{ statusSource | formatMissionEditRegisterStatus }}. Register
            status details are not applicable in this case.
        </ng-container>
        <ng-container
            *ngIf="
                statusSource !== 'ACTIVE' &&
                statusSource !== 'PENDING' &&
                statusSource !== 'SUSPENDED'
            "
        >
            This person is:&nbsp;{{
                statusSource | formatMissionEditRegisterStatus
            }}
        </ng-container>
    </span>
    <span
        *ngIf="
            (entrySummary != null && statusSource === 'NONE') ||
            entrySummary == null
        "
    >
        No register status details available for this person.
    </span>
</div>
