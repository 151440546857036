import { Injectable } from '@angular/core';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiKeyCreationDialogue } from './api-key-confirmation-dialogue/api-key-confirmation-dialogue.component';

@Injectable()
export class ApiKeysDialoguesService {
    constructor(private modalService: BsModalService) {}

    showKeyConfirmationDialogue(identifier: string, key: string) {
        return this.modalService.show(ApiKeyCreationDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                identifier,
                key
            }
        });
    }
}
