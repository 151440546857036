import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { FlightHistoryEntry } from 'libs/flight-history/src/lib/flight-history-data.service';
import { EMPTY, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GqlQueryResult } from '.';
import { FlyFreelyConstants } from '../constants';
import {
    AssignMaintenanceScheduleCommand,
    BatteryDto,
    BatteryTypeDto,
    CreateBatteryCommand,
    DisposeBatteryCommand,
    FlightHistorySummaryDto,
    MaintenanceScheduleAssignmentDto,
    UpdateBatteryCommand,
    UpdateNotesCommand
} from '../model/api';
import { BatterySetService } from './batteryset.service';
import { RESOURCE_STATUS_LIST } from './resources';

export const BATTERY_CHANGE_EVENT = 'battery-changed';

export interface BatteryWithBatteryType extends BatteryDto {
    batteryType?: BatteryTypeDto;
    convertedPurchaseDate: moment.Moment;
}
@Injectable({
    providedIn: 'root'
})
export class BatteryService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(
        private batterySetService: BatterySetService,
        private apollo: Apollo,
        constants: FlyFreelyConstants,
        private http: HttpClient
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    notifyBatteryChanged() {
        this.changeSource.next();
    }

    findBattery(batteryId: number) {
        return this.http.get<BatteryDto>(
            `${this.baseUrl}/webapi/batteries/${batteryId}`
        );
    }

    createBattery(command: CreateBatteryCommand) {
        return this.http
            .post<BatteryDto>(`${this.baseUrl}/webapi/batteries`, command)
            .pipe(
                tap(battery => {
                    if (command.makeBatterySet) {
                        this.batterySetService.notifyBatterySetChanged();
                    }
                    this.notifyBatteryChanged();
                })
            );
    }

    updateBattery(batteryId: number, command: UpdateBatteryCommand) {
        return this.http
            .put(`${this.baseUrl}/webapi/batteries/${batteryId}`, command)
            .pipe(
                tap(battery => {
                    this.notifyBatteryChanged();
                    return battery;
                })
            );
    }

    findBatteries(organisationId: number, status?: any) {
        if (isNaN(organisationId)) {
            return EMPTY;
        }
        if (status) {
            return this.http.get<BatteryDto[]>(
                `${this.baseUrl}/webapi/batteries`,
                {
                    params: new HttpParams()
                        .set('organisationId', organisationId.toString())
                        .set('status', status)
                }
            );
        } else {
            return this.http.get<BatteryDto[]>(
                `${this.baseUrl}/webapi/batteries`,
                {
                    params: new HttpParams().set(
                        'organisationId',
                        organisationId.toString()
                    )
                }
            );
        }
    }

    findFlightStatistics(batteryId: number) {
        return this.http.get<FlightHistorySummaryDto>(
            `${this.baseUrl}/webapi/batteries/${batteryId}/history/summary`
        );
    }

    findBatteryFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
            includeType: ('LOGGED' | 'HISTORICAL' | 'UNASSIGNED')[];
        },
        order: 'ASC' | 'DESC',
        page = 0,
        pageSize = 10
    ) {
        return this.apollo
            .query<{
                findBatteryFlightHistory: GqlQueryResult<FlightHistoryEntry>;
            }>({
                query: gql`
                    query find(
                        $criteria: ResourceFlightHistoryCriteria
                        $order: Direction
                        $pageSize: Int
                        $page: Int
                    ) {
                        findBatteryFlightHistory(
                            criteria: $criteria
                            order: $order
                            page: $page
                            pageSize: $pageSize
                        ) {
                            count
                            results {
                                id
                                type
                                missionId
                                flightNumber
                                startTime
                                endTime
                                duration
                                visualLineOfSight
                                timeOfDay
                                rpaId
                                rpaType {
                                    make
                                    model
                                    rpaCategory
                                    performanceSpecifications {
                                        minTakeOffWeight
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    order,
                    page,
                    pageSize
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findBatteryFlightHistory)
            );
    }

    markBatteryInTest(batteryId: number) {
        return this.http
            .put<BatteryDto>(
                `${this.baseUrl}/webapi/batteries/${batteryId}/inTest`,
                null
            )
            .pipe(tap(() => this.notifyBatteryChanged()));
    }

    retireBattery(batteryId: number) {
        return this.http
            .put<BatteryDto>(
                `${this.baseUrl}/webapi/batteries/${batteryId}/retired`,
                null
            )
            .pipe(tap(() => this.notifyBatteryChanged()));
    }

    markBatteryUnderMaintenance(batteryId: number) {
        return this.http
            .put<BatteryDto>(
                `${this.baseUrl}/webapi/batteries/${batteryId}/underMaintenance`,
                null
            )
            .pipe(tap(() => this.notifyBatteryChanged()));
    }

    disposeBattery(batteryId: number, command: DisposeBatteryCommand) {
        return this.http
            .put<BatteryDto>(
                `${this.baseUrl}/webapi/batteries/${batteryId}/dispose`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    delete(batteryId: number) {
        return this.http
            .delete<void>(`${this.baseUrl}/webapi/batteries/${batteryId}`)
            .pipe(tap(() => this.changeSource.next()));
    }

    assignMaintenanceSchedule(
        batteryId: number,
        command: AssignMaintenanceScheduleCommand
    ) {
        return this.http
            .put<MaintenanceScheduleAssignmentDto>(
                `${this.baseUrl}/webapi/batteries/${batteryId}/maintenanceSchedule`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    getStatuses() {
        return RESOURCE_STATUS_LIST;
    }

    onBatteryChange() {
        this.changeSource.next();
    }

    updateBatteryNotes(batteryId: number, command: UpdateNotesCommand) {
        return this.http
            .put<BatteryDto>(
                `${this.baseUrl}/webapi/batteries/${batteryId}/notes`,
                command
            )
            .pipe(tap(() => this.notifyBatteryChanged()));
    }
}
