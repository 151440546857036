import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AirspaceJurisdictionDto } from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { FlyFreelyMapComponent } from '../flyfreely-map/flyfreely-map.component';
import { MapService } from '../map.service';

@Component({
    selector: 'map-jurisdiction-selector',
    template: `
        <div class="horizontal-container" [formGroup]="formGroup">
            <span class="display-label right-buffer">Jurisdiction</span>
            <span
                *ngIf="jurisdictions && jurisdictions.length <= 1"
                tooltip="The jurisdiction for which this organisation has authorities"
                placement="left"
            >
                {{
                    jurisdiction != null
                        ? jurisdiction.name
                        : jurisdictions[0]?.name || 'None'
                }}
            </span>
            <ng-select
                *ngIf="jurisdictions && jurisdictions.length > 1"
                class="fill input-xs"
                [items]="jurisdictions"
                bindLabel="name"
                bindValue="id"
                formControlName="jurisdictionId"
                [clearable]="false"
                tooltip="Select a jurisdiction for which this organisation has authorities"
                placement="left"
                appendTo="body"
            ></ng-select>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JurisdictionSelector {
    private ngUnsubscribe$ = new Subject<void>();
    jurisdiction: AirspaceJurisdictionDto;
    jurisdictions: AirspaceJurisdictionDto[];

    formGroup = new FormGroup({ jurisdictionId: new FormControl(undefined) });

    constructor(
        private mapService: MapService,
        private flyfreelyMap: FlyFreelyMapComponent,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.formGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((v: { jurisdictionId: number }) => {
                // TODO: enable the dropdown to display "Global" if the jurisdiction is null without having a global option
                const matchedJurisdiction = this.jurisdictions.find(
                    j => j.id === v.jurisdictionId
                );
                this.mapService.setCurrentJurisdiction(matchedJurisdiction);
                this.flyfreelyMap.zoomToJurisdiction(matchedJurisdiction);
                this.changeDetector.detectChanges();
            });

        this.mapService.visibleJurisdictions$
            .pipe(
                map(v => v.selected),
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(jurisdiction => {
                this.formGroup.patchValue(
                    { jurisdictionId: jurisdiction?.id },
                    { emitEvent: false }
                );
                this.jurisdiction = jurisdiction;
                this.mapService.setCurrentJurisdiction(
                    this.jurisdiction || null
                );
                this.changeDetector.detectChanges();
            });

        this.mapService.availableJurisdictions$
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe$))
            .subscribe(jurisdictions => {
                this.jurisdictions = jurisdictions;
                this.formGroup.patchValue(
                    {
                        jurisdictionId:
                            this.jurisdiction?.id ||
                            this.jurisdictions[0]?.id ||
                            null
                    },
                    { emitEvent: false }
                );
                this.mapService.setCurrentJurisdiction(
                    this.jurisdiction || this.jurisdictions[0] || null
                );

                this.changeDetector.detectChanges();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
