import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
    AddSharedAuthorityCommand,
    AddSharedLicencesCommand,
    AuthorityTypeDto,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameId,
    OrganisationAuthorityService,
    OrganisationRelationshipDto,
    OrganisationRelationshipService,
    OrganisationService,
    OrganisationSubscriptionDto,
    PersonDto,
    PersonsOrganisationDto,
    RemoveSharedAuthorityCommand,
    RemoveSharedLicencesCommand,
    SimpleAuthorityDto,
    StartOrganisationRelationshipCommand,
    SubscriptionService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormatAuthorityPipe } from '@flyfreely-portal-ui/ui';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorityDialoguesService } from '../authority-dialogues.service';
import { toSimpleAuthority } from '../helpers';
import { OrganisationRelationshipsUiService } from './organisation-relationships-ui.service';

interface Authority extends NameId {
    isPrimary: boolean;
}

@Component({
    selector: 'shared-resources',
    templateUrl: './shared-resources.component.html',
    providers: [WorkTracker, OrganisationRelationshipsUiService]
})
export class SharedResources implements OnInit {
    @Input() organisation: PersonsOrganisationDto;

    person: PersonDto;

    newChild = new FormControl(undefined, [Validators.required]);
    addAuthority = new FormControl(undefined, [Validators.required]);
    editLicences: FormControl[] = [];

    children: OrganisationRelationshipDto[];
    additionalOrganisations: PersonsOrganisationDto[];
    allAuthorities: Authority[];
    addableAuthorities: Authority[] = [];

    currentSubscription: OrganisationSubscriptionDto;
    sharedSubscriptions: { [relationId: number]: OrganisationSubscriptionDto };
    editingLicence: number;
    availableLicences = 0;

    working = false;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private organisationService: OrganisationService,
        private organisationRelationshipService: OrganisationRelationshipService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private userService: UserService,
        private subscriptionService: SubscriptionService,
        private commonDialoguesService: CommonDialoguesService,
        private formatAuthorityPipe: FormatAuthorityPipe,
        private organisationRelationshipUiService: OrganisationRelationshipsUiService,
        private logging: FlyFreelyLoggingService,
        private angulartics2: Angulartics2,
        private workTracker: WorkTracker
    ) {}

    ngOnInit(): void {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.person = this.userService.getCurrentUser();

        combineLatest([
            this.organisationRelationshipUiService.subscription$,
            this.organisationRelationshipUiService.children$
        ]).subscribe(([currentSubscription, children]) => {
            this.currentSubscription = currentSubscription;
            this.children = children;
            this.parseSharedSubscriptions();
            this.findOtherOrganisations();
        });

        this.organisationRelationshipUiService.setup(this.organisation.id);
        this.findAuthorities();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    parseSharedSubscriptions() {
        this.editLicences = [];
        const findMin = (sub: OrganisationSubscriptionDto) => {
            return sub != null && sub.limits[0] != null
                ? sub.limits[0].instances -
                      (sub.limits[0].availableLicenceCount ?? 0)
                : 0;
        };
        const findMax = (sub: OrganisationSubscriptionDto) => {
            return this.currentSubscription != null &&
                this.currentSubscription?.limits[0] != null &&
                this.currentSubscription?.limits[0].availableLicenceCount !=
                    null &&
                sub != null &&
                sub.limits[0] != null
                ? (sub.limits[0].instances ?? 0) +
                      (this.currentSubscription.limits[0]
                          .availableLicenceCount ?? 0)
                : this.currentSubscription?.limits[0]?.availableLicenceCount !=
                  null
                ? this.currentSubscription?.limits[0]?.availableLicenceCount
                : 0;
        };
        if (this.children.length > 0) {
            this.sharedSubscriptions = this.children.reduce(
                (acc, s) => ({ ...acc, [s.id]: s.sharedSubscription }),
                {}
            );
            this.children.forEach(c =>
                this.editLicences.push(
                    new FormControl(
                        undefined,
                        Validators.compose([
                            Validators.min(
                                findMin(this.sharedSubscriptions[c.id])
                            ),
                            Validators.max(
                                findMax(this.sharedSubscriptions[c.id])
                            )
                        ])
                    )
                )
            );
        } else {
            this.sharedSubscriptions = null;
        }
    }

    showAuthority(authorityType: AuthorityTypeDto, authority: any) {
        this.organisationAuthorityService
            .findAuthority(authority.id, this.organisation.id)
            .subscribe(
                auth => {
                    this.authorityDialoguesService.showAuthorityDetails(
                        this.organisation.id,
                        authorityType,
                        auth
                    );
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error loading authority details: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    // finds options for adding new child organisations
    findOtherOrganisations() {
        const childrenIds = this.children.map(
            child => child.childOrganisation.id
        );

        this.organisationService
            .findOrganisationsForPerson(this.person.id)
            .subscribe(orgs => {
                this.additionalOrganisations = orgs
                    .filter(
                        org =>
                            org.permissions.indexOf(
                                PersonsOrganisationDto.Permissions
                                    .ORGANISATION_EDIT
                            ) !== -1
                    )
                    .filter(org => {
                        return (
                            !childrenIds.includes(org.id) &&
                            org.id !== this.organisation.id &&
                            !org.personalOrganisation
                        );
                    });
            })
            .add(this.workTracker.createTracker());
    }

    addChildOrganisation() {
        const doneWorking = this.workTracker.createTracker();
        const cmd: StartOrganisationRelationshipCommand = {
            childOrganisationId: this.newChild.value
        };
        this.organisationRelationshipService
            .addChildRelationship(this.organisation.id, cmd)
            .toPromise()
            .then(results => {
                this.newChild.setValue(undefined);

                this.angulartics2.eventTrack.next({
                    action: 'add-child-organisation',
                    properties: {
                        category: 'shared-resources',
                        label: results.id
                    }
                });
                doneWorking();
            });
    }

    shareAuthority(relationship: OrganisationRelationshipDto) {
        const doneWorking = this.workTracker.createTracker();
        const cmd: AddSharedAuthorityCommand = {
            authorityId: this.addAuthority.value
        };
        this.organisationRelationshipService
            .shareAuthorityWithChild(this.organisation.id, relationship.id, cmd)
            .toPromise()
            .then(results => {
                this.addAuthority.setValue(undefined);
                this.angulartics2.eventTrack.next({
                    action: 'share-authority',
                    properties: {
                        category: 'shared-resources',
                        label: results.id
                    }
                });
                doneWorking();
            });
    }

    editRelationLicences(relationship: OrganisationRelationshipDto, i: number) {
        this.editLicences[i].setValue(
            relationship.sharedSubscription.limits[0].instances
        );
        this.editLicences[i].markAsPristine();
        this.editingLicence = relationship.id;
    }

    editSharedLicences(relationship: OrganisationRelationshipDto, i: number) {
        if (
            this.editLicences[i].value >
            relationship.sharedSubscription.limits[0].instances
        ) {
            this.addSharedLicences(relationship, i);
        } else {
            this.removeSharedLicences(relationship, i);
        }
    }

    addSharedLicences(relationship: OrganisationRelationshipDto, i: number) {
        const command: AddSharedLicencesCommand = {
            licencedEntityType:
                relationship.sharedSubscription?.limits[0]
                    ?.licencedEntityType ??
                this.currentSubscription.limits[0].licencedEntityType,
            numberOfLicences:
                relationship.sharedSubscription != null
                    ? this.editLicences[i].value -
                      relationship.sharedSubscription?.limits[0]?.instances
                    : this.editLicences[i].value,
            organisationRelationshipId: relationship.id,
            subscriptionId: this.currentSubscription.id
        };
        this.subscriptionService
            .assignSharedLicences(
                this.organisation.id,
                relationship.id,
                command
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.logging.success(
                        `Updated shared licences for ${relationship.childOrganisation.name}`
                    );
                    this.editingLicence = null;
                    this.angulartics2.eventTrack.next({
                        action: relationship.sharedSubscription
                            ? 'update-shared-licence-count'
                            : 'share-subscription',
                        properties: {
                            category: 'shared-resources',
                            label: result.id,
                            value: result.sharedSubscription.limits[0].instances
                        }
                    });
                    this.editLicences[i].setValue(undefined);
                    this.editLicences[i].markAsPristine();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error sharing licences: ${error.message}`
                    );
                }
            );
    }

    removeSharedLicences(relationship: OrganisationRelationshipDto, i: number) {
        const command: RemoveSharedLicencesCommand = {
            licencedEntityType:
                relationship.sharedSubscription.limits[0].licencedEntityType,
            numberOfLicences:
                relationship.sharedSubscription.limits[0].instances -
                this.editLicences[i].value,
            subscriptionId: this.currentSubscription.id,
            removeSharedSubscription: false
        };
        this.subscriptionService
            .removeSharedLicences(
                this.organisation.id,
                relationship.id,
                command
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.logging.success(
                        `Updated shared licences for ${relationship.childOrganisation.name}`
                    );
                    this.editingLicence = null;
                    this.angulartics2.eventTrack.next({
                        action: 'update-shared-licence-count',
                        properties: {
                            category: 'shared-resources',
                            label: result.id,
                            value: result.sharedSubscription.limits[0].instances
                        }
                    });
                    this.editLicences[i].setValue(undefined);
                    this.editLicences[i].markAsPristine();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error sharing licences: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    stopSharingLicences(relationship: OrganisationRelationshipDto) {
        const command: RemoveSharedLicencesCommand = {
            licencedEntityType:
                relationship.sharedSubscription.limits[0].licencedEntityType,
            numberOfLicences:
                relationship.sharedSubscription.limits[0].instances,
            subscriptionId: this.currentSubscription.id,
            removeSharedSubscription: true
        };
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Stop sharing subscription',
                `By continuing you will stop sharing this organisation's current subscription with ${relationship.childOrganisation.name} and remove all shared licences, regardless of whether they have been assigned to any of ${relationship.childOrganisation.name}'s personnel. Are you sure you wish to stop sharing your subscription?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() =>
                this.subscriptionService
                    .removeSharedLicences(
                        this.organisation.id,
                        relationship.id,
                        command
                    )
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(
                        result => {
                            this.logging.success(
                                `Stopped sharing subscription and licences with ${relationship.childOrganisation.name}`
                            );
                            this.angulartics2.eventTrack.next({
                                action: 'unshare-subscription',
                                properties: {
                                    category: 'shared-resources',
                                    label: result.id
                                }
                            });
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error stopping subscription sharing: ${error.message}`
                            );
                        }
                    )
                    .add(this.workTracker.createTracker())
            )
            .catch(DO_NOTHING);
    }

    // Gets list of available authorities for the current user
    findAuthorities() {
        this.organisationAuthorityService
            .findAuthorities(this.organisation.id, this.organisation.id)
            .subscribe(authorities =>
                (this.allAuthorities = authorities
                    .filter(group => group.hasWorkflow)
                    .map(group =>
                        group.authorities.map(auth => ({
                            id: auth.id,
                            name: this.formatAuthorityPipe.transform(
                                toSimpleAuthority(
                                    auth,
                                    this.organisation,
                                    group
                                )
                            ),
                            isPrimary: group.isPrimary
                        }))
                    )
                    .reduce((acc, auths) => [...acc, ...auths], [])).sort(
                    (a, b) => a.name.localeCompare(b.name)
                )
            );
    }

    findAddableAuthorities(relationship: OrganisationRelationshipDto) {
        const added = relationship.sharedAuthorities.map(auth => auth.id);
        this.addableAuthorities = this.allAuthorities.filter(
            auth => !added.includes(auth.id)
        );
    }

    findMinLicenceCount(relation: OrganisationRelationshipDto) {
        if (relation?.sharedSubscription?.limits?.length > 0) {
            return (
                relation.sharedSubscription.limits[0].instances -
                relation.sharedSubscription.limits[0].availableLicenceCount
            );
        } else {
            return 0;
        }
    }

    findMaxLicenceCount(relation: OrganisationRelationshipDto) {
        if (
            this.currentSubscription?.limits == null ||
            this.currentSubscription?.limits?.length === 0
        ) {
            return null;
        } else if (
            this.currentSubscription?.limits[0]?.availableLicenceCount !=
                null &&
            relation.sharedSubscription?.limits?.length > 0
        ) {
            return (
                relation.sharedSubscription.limits[0].instances +
                this.currentSubscription.limits[0].availableLicenceCount
            );
        } else {
            return this.currentSubscription.limits[0].availableLicenceCount;
        }
    }

    removeAuthority(
        relationship: OrganisationRelationshipDto,
        authority: SimpleAuthorityDto
    ) {
        const cmd: RemoveSharedAuthorityCommand = { authorityId: authority.id };
        this.organisationRelationshipService
            .unshareAuthority(this.organisation.id, relationship.id, cmd)
            .subscribe(result => {
                this.angulartics2.eventTrack.next({
                    action: 'unshare-authority',
                    properties: {
                        category: 'shared-resources',
                        label: result.id
                    }
                });
            })
            .add(this.workTracker.createTracker());
    }

    removeOrganisation(relationship: OrganisationRelationshipDto) {
        this.organisationRelationshipService
            .removeChildRelationship(this.organisation.id, relationship.id)
            .subscribe(result => {
                this.angulartics2.eventTrack.next({
                    action: 'remove-child-organisation',
                    properties: {
                        category: 'shared-resources',
                        label: result.id
                    }
                });
            })
            .add(this.workTracker.createTracker());
    }
}
