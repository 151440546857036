import { Pipe, PipeTransform } from '@angular/core';
import { FlightConformanceResultDto } from '@flyfreely-portal-ui/flyfreely';

/**
 * FIXME move to a conformance module once Nx migration is done.
 */
@Pipe({ name: 'findCurrentConformanceResult' })
export class FindCurrentConformanceResultPipe implements PipeTransform {
    transform(value: FlightConformanceResultDto[]) {
        if (value == null || value.length === 0) {
            return null;
        }
        return value.find(c => c.status !== 'CANCELLED');
    }
}
