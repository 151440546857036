<enhanced-help
    *ngIf="batterySet != null"
    componentName="battery-set-details"
    [compact]="true"
></enhanced-help>
<div class="container-with-footer">
    <div class="contents" *ngIf="batterySet">
        <div class="data">
            <span class="display-label">Name</span>
            {{ batterySet.name }}
        </div>

        <div class="data">
            <span class="display-label">Assembly Date</span>
            {{ batterySet.assemblyDate | formatDate }}
        </div>

        <div class="data">
            <span class="display-label">Disassembly Date</span>
            {{ batterySet.disassemblyDate | formatDate }}
        </div>

        <div class="data" *ngIf="batterySet.batteries[0]">
            <span class="display-label">Battery Type</span>
            {{ batterySet.batteries[0].batteryTypeMake }}
            {{ batterySet.batteries[0].batteryTypeModel }}
        </div>
        <div class="data" *ngIf="!batterySet.batteries[0]">
            <span class="display-label">Battery Type</span>
        </div>

        <h4 class="section-heading">
            <span>Battery Set Contents</span>
            <div></div>
        </h4>

        <table *ngIf="batterySet.batteries.length > 0" class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Serial Number</th>
                    <th>Status</th>
                    <th>Purchase Date</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let battery of batterySet.batteries">
                    <td>{{ battery.name }}</td>
                    <td>{{ battery.manufacturerSerialNumber }}</td>
                    <td>{{ battery.status | formatResourceStatus }}</td>
                    <td>{{ battery.purchaseDate | formatDate }}</td>
                </tr>
            </tbody>
        </table>

        <h4 class="section-heading">
            <span>Compatible RPA</span>
            <div></div>
        </h4>

        <battery-set-compatible-rpa
            [batterySet]="batterySet"
            [organisationId]="currentOrganisation.id"
        ></battery-set-compatible-rpa>

        <!-- <table
            *ngIf="
                batterySet.craftBatterySetUsages &&
                batterySet.craftBatterySetUsages.length > 0
            "
            class="table"
        >
            <thead>
                <tr>
                    <th>Usage Date</th>
                    <th>RPA</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let setUsage of batterySet.craftBatterySetUsages">
                    <td>{{ setUsage.usageDate }}</td>
                    <td>{{ setUsage.craft }}</td>
                </tr>
            </tbody>
        </table> -->
    </div>
    <div class="action-container">
        <button
            *ngIf="hasDelete && isOwner"
            [disabled]="!canDelete || working"
            class="btn btn-danger pull-left"
            type="button"
            (click)="delete()"
        >
            <span class="fal fa-trash-alt"></span>
            Delete
        </button>

        <button
            *ngIf="hasEdit && isOwner"
            [disabled]="!canEdit || working"
            class="btn btn-primary pull-right"
            type="button"
            (click)="onEdit()"
        >
            Edit
        </button>
    </div>
</div>
