<ng-container *ngIf="comments && comments.length > 0">
    <ui-comment *ngFor="let comment of comments;"
                [allowEdit]="allowEdit"
                [comment]="comment"
                [organisationId]="organisationId"
                (updateComment)="onUpdateComment($event)"
                (deleteComment)="onDeleteComment($event)"
    >
    </ui-comment>
</ng-container>

<div *ngIf="allowEdit" class="d-flex align-items-start">
    <div style="margin-right: 1rem;">
        <person-avatar
            [person]="(currentUser$ | async)!"
            [managingOrganisationId]="organisationId"
            [width]="25"
            [height]="25"
        >
        </person-avatar>
    </div>
    <div style="flex-grow: 1">
        <rich-text-editor [hasCancel]="false"
                          [hasSave]="hasSave"
                          (saveEdit)="onCreateComment($event)"
        ></rich-text-editor>
    </div>
</div>

