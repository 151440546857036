import { Component, Input } from '@angular/core';
import { ChangeDto } from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'whats-new-dialogue',
    template: `
        <ng-container screenAnalytics="whats-new">
            <div class="modal-header no-icon">
                <h4 id="dialog-child-name" class="modal-title pull-left">
                    What's New
                </h4>
                <button
                    type="button"
                    class="close pull-right"
                    aria-label="Close"
                    (click)="hide()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body container-scroll">
                <div *ngFor="let change of changes">
                    <h4 class="section-heading">
                        {{ change.title }}
                        <div></div>
                    </h4>
                    <p>
                        <em>{{ change.releaseTime | formatDate }}</em>
                    </p>
                    <div [innerHtml]="change.body | autolink"></div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="horizontal-container vertical-align fill">
                    <div class="horizontal-container fill">
                        <span>For detailed release notes</span>
                        <a
                            href="https://knowledge.flyfreely.io/release-notes"
                            target="_blank"
                        >
                            click here
                        </a>
                    </div>
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="hide()"
                    >
                        Close
                    </button>
                </div>
            </div>
        </ng-container>
    `
})
export class WhatsNewDialogue {
    @Input() changes: ChangeDto[];

    constructor(private modal: BsModalRef<WhatsNewDialogue>) {}

    hide() {
        this.modal.hide();
    }
}
