import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FieldError } from '@flyfreely-portal-ui/flyfreely';

const NAMES = {
    startTime: 'Mission Time',
    'mission.missionCrew.rpic.jurisdiction': 'Pilot',
    'mission.visualLineOfSight': 'Visual Line of Sight'
};

@Component({
    selector: 'show-errors',
    templateUrl: './show-errors.component.html',
    styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnInit {
    @Input()
    errors: { [control: string]: FieldError };

    errorList: { name: string; message: string }[] = [];
    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if ('errors' in changes) {
            if (this.errors == null) {
                this.errorList = [];
                return;
            } else {
                this.errorList = Object.keys(this.errors).map(identifier => ({
                    name: NAMES[identifier],
                    message: this.errors[identifier].message ?? 'invalid'
                }));
            }
        }
    }
}
