import {
    BatteryTypeDto,
    UpdateBatteryTypeCommand,
    CreateBatteryTypeCommand,
    RpaTypeBatteryTypeDetailsDto,
    RpaTypeBatteryTypeDto,
    CreateCompatibleRpaTypeCommand,
    UpdateRpaTypeBatteryTypeCommand,
    ChangeBatteryTypeSchematicCommand
} from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export const batteryChemistries = [
    { value: 'LIPO', name: 'LiPo' },
    { value: 'LIION', name: 'LiIon' },
    { value: 'LIFE', name: 'LiFe' },
    { value: 'HVLIPO', name: 'HVLiPo' },
    { value: 'NIMH', name: 'NiMH' },
    { value: 'UNSPECIFIED', name: 'Unspecified' }
];

@Injectable({
    providedIn: 'root'
})
export class BatteryTypeService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<BatteryTypeDto[]>(
            `${this.baseUrl}/webapi/batteryTypes`,
            {
                params: new HttpParams().set(
                    'organisationId',
                    organisationId.toString()
                )
            }
        );
    }

    findCompatibleRpaTypes(batteryTypeId: number) {
        return this.http.get<RpaTypeBatteryTypeDto[]>(
            `${this.baseUrl}/webapi/batteryTypes/${batteryTypeId}/rpaTypes`
        );
    }

    createBatteryType(batteryType: CreateBatteryTypeCommand) {
        return this.http
            .post<BatteryTypeDto>(
                `${this.baseUrl}/webapi/batteryTypes`,
                batteryType
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateBatteryType(
        batteryTypeId: number,
        batteryType: UpdateBatteryTypeCommand
    ) {
        return this.http
            .put<BatteryTypeDto>(
                `${this.baseUrl}/webapi/batteryTypes/${batteryTypeId}`,
                batteryType
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    createCompatibleRpaType(
        batteryTypeId: number,
        command: CreateCompatibleRpaTypeCommand
    ) {
        return this.http
            .post<RpaTypeBatteryTypeDetailsDto>(
                `${this.baseUrl}/webapi/batteryTypes/${batteryTypeId}/rpaTypes`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateCompatibleRpaType(
        batteryTypeId: number,
        rpaTypeId: number,
        command: UpdateRpaTypeBatteryTypeCommand
    ) {
        return this.http
            .put<RpaTypeBatteryTypeDetailsDto>(
                `${this.baseUrl}/webapi/batteryTypes/${batteryTypeId}/rpaTypes/${rpaTypeId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }


    deleteCompatibleRpaType(batteryTypeId: number, craftId: number) {
        return this.http.delete(
            `${this.baseUrl}/webapi/batteryTypes/${batteryTypeId}/rpaTypes/${craftId}`
        );
    }


    changeBatteryTypeSchematic(
        batteryTypeId: number,
        command: ChangeBatteryTypeSchematicCommand
    ) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/batteryTypes/${batteryTypeId}/schematic`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    getSchematicAttachmentUrl(
        resourceSchematicId: number,
        attachmentId: number
    ) {
        if (resourceSchematicId == null || attachmentId == null) {
            return null;
        }
        return `${this.baseUrl}/webapi/resources/resourceSchematics/${resourceSchematicId}/attachments/${attachmentId}`;
    }
}
