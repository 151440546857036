import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'onboarding-help-dialogue',
    template: `
        <div class="modal-body">
            <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="hide()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body-content">
                <div class="help-title bottom-buffer vertical-align">
                    {{ helpTitle }}
                </div>
                <div class="help-text">
                    {{ helpText }}
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .help-title {
                font-size: 25px;
                font-weight: 100;
            }

            .help-text {
                font-size: 14px;
                margin-bottom: 10px;
            }
            @media only screen and (max-width: 767px) {
                .help-title {
                    font-size: 2em;
                }
                .help-text {
                    font-size: 1em;
                }
            }
        `
    ]
})
export class OnboardingHelpDialogue {
    @Input() helpText: string;
    @Input() helpTitle: string = '';
    constructor(private modal: BsModalRef) {}

    hide() {
        this.modal.hide();
    }
}
