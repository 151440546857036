/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.17.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from 'geojson';
import { Reason } from './reason';


/**
 * 
 */
export interface RuleOutcome { 
    /**
     * The rule code
     */
    code?: string;
    /**
     * The outcome for the rule
     */
    outcome?: RuleOutcome.Outcome;
    /**
     * The reasons this rule has been evaluated the way it has
     */
    reason?: Array<Reason>;
    /**
     * The message for this particular rule outcome. From the Rule description.
     */
    message?: string;
    intersectingGeometry?: Geometry;
}
export namespace RuleOutcome {
    export type Outcome = 'BLOCK' | 'AUTHORISATION_REQUIRED' | 'ADVISE' | 'PASS' | 'NA' | 'NONE';
    export const Outcome = {
        BLOCK: 'BLOCK' as Outcome,
        AUTHORISATION_REQUIRED: 'AUTHORISATION_REQUIRED' as Outcome,
        ADVISE: 'ADVISE' as Outcome,
        PASS: 'PASS' as Outcome,
        NA: 'NA' as Outcome,
        NONE: 'NONE' as Outcome
    };
}



