import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-range',
    template: `
        <div class="input-group">
            <input
                type="range"
                [formControl]="formControl"
                class="form-control"
                [formlyAttributes]="field"
                [class.is-invalid]="showError"
                min="{{ to.min }}"
                max="{{ to.max }}"
            /><span class="input-group-addon input-group-addon-range">{{
                formControl.value
            }}</span>
        </div>
    `
})
export class FormlyRange extends FieldType {}
