import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FeatureProperties, MapModes } from '../constants';

const DrawSingleMeasurementLineSelect = { ...MapboxDraw.modes.simple_select };

class MeasurementLineSelectState {
    dragMoveLocation: any;
    boxSelectStartLocation: any;
    boxSelectElement: any;
    boxSelecting: boolean;
    canBoxSelect: boolean;
    dragMoving: boolean;
    canDragMove: boolean;
    featureId: string;
    line: any; // DrawFeature

    measurement: any; // DrawFeature
}
DrawSingleMeasurementLineSelect.onSetup = function (opts: any) {
    const state: MeasurementLineSelectState = {
        dragMoveLocation: null,
        boxSelectStartLocation: null,
        boxSelectElement: undefined,
        boxSelecting: false,
        canBoxSelect: false,
        dragMoving: false,
        canDragMove: true,
        featureId: opts.featureId,
        measurement: null,
        line: null
    };

    // @ts-ignore: not in the current type
    this.fireActionable();

    this.setActionableState({
        combineFeatures: true,
        uncombineFeatures: true,
        trash: true
    });

    state.line = this.getFeature(opts.featureId);
    state.measurement = this.getFeature(
        state.line.properties.relatedFeatureIds[0]
    );

    this.setSelected([
        opts.featureId,
        state.line.properties.relatedFeatureIds[0]
    ]);

    return state;
};

DrawSingleMeasurementLineSelect.onClick = function (
    state: MeasurementLineSelectState,
    e: any
) {
    if (!e.featureTarget) {
        return;
    }

    const properties = e.featureTarget.properties;
    if (
        properties &&
        properties.user_meta === FeatureProperties.MEASUREMENT_LABEL
    ) {
        // clicked the measurement point, switch to "edit" mode
        const featureId = properties.user_parent;
        this.changeMode(MapModes.DRAW_SINGLE_LINE as MapboxDraw.DrawMode, { featureId });
    }

    // Click (with or without shift) on no feature
    if (MapboxDraw.lib.CommonSelectors.noTarget(e)) {
        // @ts-ignore: not in the current type
        return this.clickAnywhere(state, e);
    } // also tap
    if (MapboxDraw.lib.CommonSelectors.isOfMetaType(MapboxDraw.constants.meta.VERTEX)(e)) {
        // @ts-ignore: not in the current type
        return this.clickOnVertex(state, e);
    } // tap
    if (MapboxDraw.lib.CommonSelectors.isFeature(e)) {
        // @ts-ignore: not in the current type
        return this.clickOnFeature(state, e);
    }
};

// @ts-ignore: not in the current type
DrawSingleMeasurementLineSelect.clickOnVertex = function (
    state: MeasurementLineSelectState,
    e: any
) {
    // Enter custom direct select mode
    this.changeMode(MapModes.DRAW_SINGLE_LINE_POINT_SELECT, {
        featureId: e.featureTarget.properties.parent,
        coordPath: e.featureTarget.properties.coord_path,
        startPos: e.lngLat
    });
    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE });
};

export default DrawSingleMeasurementLineSelect;
