import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatBoolean' })
export class FormatBooleanPipe implements PipeTransform {
    transform(value: any, ...args: any[]): string | null {
        if (value === true) {
            return 'Yes';
        }
        if (value === false) {
            return 'No';
        }
        return null;
    }
}
