import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
    AuthorisationRuleOutcome,
    DisplayableMissionDto,
    LookupObject,
    RuleCodeDto,
    RuleOutcome,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';
import { AirspaceCheckService, AirspaceCheckStatus } from '..';
import { AirspaceDialoguesService } from '../../airspace-dialogues.service';
import { AirspaceAuthorisationManager } from '../../authorisation/airspace-authorisation-manager.service';

interface OutcomeDetails {
    code: string;
    name: string;
    message: string;
    relatedMissionIdList?: number[];
}

function convertToOutcomeDetails(
    outcome: RuleOutcome | AuthorisationRuleOutcome,
    messages: { [key: string]: RuleCodeDto }
) {
    const message = messages[outcome.code];
    return {
        code: outcome.code,
        name: message?.name ?? (<AuthorisationRuleOutcome>outcome)?.title,
        message: message
            ? outcome.outcome === RuleOutcome.Outcome.BLOCK
                ? message.blockMessage
                : message.adviseMessage
            : (<AuthorisationRuleOutcome>outcome).message,
        relatedMissionIdList: (<AuthorisationRuleOutcome>outcome)
            .relatedMissionIdList
    };
}

@Component({
    selector: 'inline-airspace-warnings-and-blockers',
    templateUrl: './inline-warnings-and-blockers.component.html',
    styles: [
        `
          .outcome-card {
            padding: 5px 15px;
            border-radius: 5px;
            box-shadow: 0 2px 2px #00000033;
            font-weight: 500;
          }
      
          .outcome-card.pad {
            margin-right: 15px;
          }
      
          .checking-container {
            padding: 5px 15px;
            font-weight: 500;
          }
      
          .airspace-authorisation-result {
            display: flex;
            height: 100%;
            padding-left: 5%;
            align-items: center;
          }
      
          .airspace-authorisation-result.pass {
            position: relative;
            background: #fff;
            color: #30d455;
          }
        `
      ],
    styleUrls: ['../../styles.scss']
})
export class InlineAirspaceWarningsAndBlockersComponent {
    airspaceCheckAdvises: OutcomeDetails[] = [];
    airspaceCheckBlocks: OutcomeDetails[] = [];
    authorisationPreviewAdvises: OutcomeDetails[] = [];
    authorisationPreviewBlocks: OutcomeDetails[] = [];

    airspaceStatus: AirspaceCheckStatus;

    relatedMissionLookup: LookupObject<DisplayableMissionDto>;

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private airspaceCheckService: AirspaceCheckService,
        private airspaceDialoguesService: AirspaceDialoguesService,
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        private router: Router
    ) {}

    ngOnInit() {
        combineLatest([
            this.airspaceCheckService.resultStatus$,
            this.airspaceAuthorisationManager.checkingAuthorisation$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([status, checkingAuthorisation]) =>
                    (this.airspaceStatus = checkingAuthorisation
                        ? 'CHECKING'
                        : status)
            );

        combineLatest([
            this.airspaceCheckService.result$,
            this.airspaceAuthorisationManager.airspaceAuthorisationPreview$
        ])
            .pipe(
                filter(
                    ([result, preview]) => result != null || preview != null
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(([result, preview]) => {
                this.airspaceCheckAdvises = (result?.ruleOutcomes ?? [])
                    .filter(o => o.outcome === RuleOutcome.Outcome.ADVISE)
                    .map(outcome =>
                        convertToOutcomeDetails(outcome, result?.ruleMessages)
                    )
                    .reduce(
                        (acc: OutcomeDetails[], outcome: OutcomeDetails) =>
                            acc.find(
                                o =>
                                    o.code === outcome.code &&
                                    o.name === outcome.name
                            ) != null
                                ? acc
                                : acc.concat(outcome),
                        []
                    );
                this.authorisationPreviewAdvises = (
                    preview?.authorisationRuleOutcomeList ?? []
                )
                    .filter(o => o.outcome === RuleOutcome.Outcome.ADVISE)
                    .map(outcome => convertToOutcomeDetails(outcome, {}))
                    .reduce(
                        (acc: OutcomeDetails[], outcome: OutcomeDetails) =>
                            acc.find(
                                o =>
                                    o.code === outcome.code &&
                                    o.name === outcome.name
                            ) != null
                                ? acc
                                : acc.concat(outcome),
                        []
                    );
                this.airspaceCheckBlocks = (result?.ruleOutcomes ?? [])
                    .filter(o => o.outcome === RuleOutcome.Outcome.BLOCK)
                    .map(outcome =>
                        convertToOutcomeDetails(outcome, result?.ruleMessages)
                    )
                    .reduce(
                        (acc: OutcomeDetails[], outcome: OutcomeDetails) =>
                            acc.find(
                                o =>
                                    o.code === outcome.code &&
                                    o.name === outcome.name
                            ) != null
                                ? acc
                                : acc.concat(outcome),
                        []
                    );
                this.authorisationPreviewBlocks = (
                    preview?.authorisationRuleOutcomeList ?? []
                )
                    .filter(o => o.outcome === RuleOutcome.Outcome.BLOCK)
                    .map(outcome => convertToOutcomeDetails(outcome, {}))
                    .reduce(
                        (acc: OutcomeDetails[], outcome: OutcomeDetails) =>
                            acc.find(
                                o =>
                                    o.code === outcome.code &&
                                    o.name === outcome.name
                            ) != null
                                ? acc
                                : acc.concat(outcome),
                        []
                    );
            });
        this.airspaceAuthorisationManager.relatedMissions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(relatedMissions => {
                this.relatedMissionLookup = relatedMissions.reduce(
                    toLookup,
                    {}
                );
            });
    }

    showAirspaceDetails() {
        this.airspaceDialoguesService.showAirspaceCheckDetailsDialogue(
            this.airspaceCheckService
        );
    }

    goToRelatedMission(id: number) {
        this.airspaceAuthorisationManager.goToRelatedMission(id);
    }
}
