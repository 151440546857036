import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    HubSpotNotificationPreference,
    NotificationDto,
    NotificationGroupSummary,
    NotificationResult,
    ResultDto,
    UpdateDisabledNotificationCommand
} from '../model/api';
import { httpParamSerializer } from './service.helpers';
import { GraphqlSubscriptionStateService } from '@flyfreely-portal-ui/graph-ql';

export interface NotificationChanges {
    type: 'notification' | 'preferences';
}
@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private changeSource = new Subject<NotificationChanges>();
    change$ = this.changeSource.asObservable();
    private connectionChanged = new BehaviorSubject<
        'connecting' | 'reconnected' | 'disconnected'
    >('connecting');
    connectionChange$ = this.connectionChanged.asObservable();
    private openNotificationStream = false;
    private baseUrl: string;
    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo,
        private graphqlSubscriptionState: GraphqlSubscriptionStateService
    ) {
        this.baseUrl = constants.SITE_URL;

        this.graphqlSubscriptionState.connectionChange$.subscribe(status => {
            this.connectionChanged.next(status);
        });
    }

    ngOnDestroy() {
        this.changeSource.complete();
        this.connectionChanged.complete();
    }

    setupNotificationStream(organisationId: number) {
        if (this.openNotificationStream) {
            // Close the websoket if already open
            return this.closeNotificationStream(true, organisationId);
        } else {
            return this.startNotificationStream(organisationId);
        }
    }

    startNotificationStream(organisationId: number) {
        this.openNotificationStream = true;
        return this.apollo
            .subscribe({
                query: gql`
                    subscription getNotificationUpdates($organisationId: Long) {
                        getNotificationUpdates(
                            organisationId: $organisationId
                        ) {
                            id
                            timestamp
                            subject
                            reference
                            read
                            action
                        }
                    }
                `,
                variables: {
                    organisationId: organisationId
                }
            })
            .pipe(
                map(
                    (notificationUpdates: any) =>
                        notificationUpdates.data
                            .getNotificationUpdates as NotificationDto
                )
            );
    }

    closeNotificationStream(reopen = false, organisationId?: number) {
        // this.graphqlSubscriptionState.websocketClient.unsubscribeAll();
        // this.graphqlSubscriptionState.websocketClient.close(false, false);
        this.openNotificationStream = false;
        if (reopen) {
            if (organisationId == null) {
                return throwError(() => new Error('No organisationId'));
            }
            return this.startNotificationStream(organisationId);
        }
    }

    findNotifications(page: number, organisationId?: number) {
        return this.http.get<NotificationResult>(
            `${this.baseUrl}/webapi/user/notifications`,
            {
                params: httpParamSerializer({ page, organisationId })
            }
        );
    }

    findNotificationIdentifiers() {
        return this.http.get<NotificationGroupSummary[]>(
            `${this.baseUrl}/webapi/user/notificationIdentifiers`
        );
    }

    findMarketingPreferences() {
        return this.http.get<HubSpotNotificationPreference[]>(
            `${this.baseUrl}/webapi/user/marketingPreferences`
        );
    }

    markNotificationRead(notificationId: number) {
        return this.http
            .post<ResultDto>(
                `${this.baseUrl}/webapi/user/notifications/${notificationId}`,
                null
            )
            .pipe(tap(() => this.changeSource.next({ type: 'notification' })));
    }

    updateNotificationPreferences(command: UpdateDisabledNotificationCommand) {
        return this.http
            .post<ResultDto>(
                `${this.baseUrl}/webapi/user/notificationPreferences`,
                command
            )
            .pipe(tap(() => this.changeSource.next({ type: 'preferences' })));
    }

    subscribeToMarketingPreference(subscriptionTypeId: string) {
        return this.http.post<HubSpotNotificationPreference>(
            `${this.baseUrl}/webapi/user/marketingPreferences/${subscriptionTypeId}`,
            null
        );
    }

    deleteNotification(notificationId: number) {
        return this.http
            .delete<ResultDto>(
                `${this.baseUrl}/webapi/user/notifications/${notificationId}`
            )
            .pipe(tap(() => this.changeSource.next({ type: 'notification' })));
    }

    unsubscribeFromMarketingPreference(subscriptionTypeId: string) {
        return this.http.delete<HubSpotNotificationPreference>(
            `${this.baseUrl}/webapi/user/marketingPreferences/${subscriptionTypeId}`
        );
    }
}
