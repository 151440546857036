import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    AttachmentHandler,
    Cancellable,
    CraftDetailsDto,
    CraftService,
    EntityUpdated,
    ExclusiveControlService,
    FEATURE_RPA_RESOURCE_ASSOCIATION,
    FEATURE_SCHEDULED_MAINTENANCE,
    LookupObject,
    MissionRegisterStatusDetails,
    PersonsOrganisationDto,
    RpaTypeDto,
    RpaTypesService,
    UserService,
    findOrganisation,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { FRONTEND_APP, Frontend } from 'libs/flyfreely/src/lib/app.config';
import { ResourceMaintenanceService } from 'libs/maintenance/src/lib/resource-maintenance.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { rpaMaintenanceServiceProvider } from '../rpa-maintenance.service';

enum Editing {
    NONE = 'NONE',
    DETAILS = 'DETAILS',
    AUTHORITIES = 'AUTHORITIES',
    NOTES = 'NOTES'
}

@Component({
    selector: 'rpa-dialogue',
    templateUrl: './rpa-dialogue.component.html',
    providers: [ExclusiveControlService, rpaMaintenanceServiceProvider]
})
export class RpaDialogue implements OnInit, OnDestroy {
    @Input() managingOrganisationId: number;
    @Input() rpaId: number;
    @Input() missionDetails: MissionRegisterStatusDetails;

    @ViewChild('rpaEdit', { static: false }) rpaEdit: Cancellable;

    rpa: CraftDetailsDto;

    isEditing: Editing = Editing.NONE;
    adminView = false;

    manualsAttachmentsHandler: AttachmentHandler;

    rpaType: RpaTypeDto;

    locked = false;

    organisation: PersonsOrganisationDto | undefined;

    canEdit: boolean;
    canAdminOrganisation: boolean;
    hasScheduledMaintenance: boolean;
    hasRpaResourceAssociation: boolean;
    featureFlags: LookupObject<string[]> = {};

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<RpaDialogue>,
        modalOptions: ModalOptions,
        private rpaTypesService: RpaTypesService,
        private craftService: CraftService,
        private exclusiveControlService: ExclusiveControlService,
        private rpaMaintenanceService: ResourceMaintenanceService,
        private userService: UserService,
        @Inject(FRONTEND_APP) private frontendApp: Frontend
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.locked) {
                this.exclusiveControlService.requestUnlock();
                return Promise.reject();
            }

            return Promise.resolve();
        };

        this.exclusiveControlService.lock$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locked => {
                this.locked = locked;
                if (!locked) {
                    if (this.isEditing === Editing.DETAILS) {
                        this.isEditing = Editing.NONE;
                    } else if (this.isEditing === Editing.AUTHORITIES) {
                        this.isEditing = Editing.NONE;
                    } else if (this.isEditing === Editing.NOTES) {
                        this.isEditing = Editing.NONE;
                    }
                }
            });

        this.adminView = this.frontendApp === 'admin';
    }

    ngOnInit() {
        this.rpaMaintenanceService.setup(this.rpaId);

        this.craftService
            .findById(this.rpaId, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpa => this.updateRpa(rpa));

        this.craftService.change$
            .pipe(
                filter(
                    (change): change is EntityUpdated<CraftDetailsDto> =>
                        change.type === 'UPDATE' &&
                        change.entity.id === this.rpa.id
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(rpa => this.updateRpa(rpa.entity));

        this.refreshPermissions();
    }

    ngOnDestroy() {
        if (this.manualsAttachmentsHandler != null) {
            this.manualsAttachmentsHandler.destroy();
        }
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private updateRpa(rpa: CraftDetailsDto) {
        this.rpa = rpa;
        this.rpaType = this.rpa.rpaType;
        if (this.rpa.rpaTypeId != null) {
            this.manualsAttachmentsHandler =
                this.rpaTypesService.attachmentHandler(
                    this.rpa.rpaTypeId,
                    this.managingOrganisationId
                );
        } else {
            this.manualsAttachmentsHandler = null;
        }
    }

    private refreshPermissions() {
        const maybeOrganisation = findOrganisation(
            this.userService.findUsersOrganisations(),
            this.managingOrganisationId
        );

        this.organisation = pipe(
            maybeOrganisation,
            getOrElse(() => undefined)
        );

        this.hasScheduledMaintenance = pipe(
            maybeOrganisation,
            map(organisation =>
                hasFeatureFlag(organisation, FEATURE_SCHEDULED_MAINTENANCE)
            ),
            getOrElse(() => false)
        );
        this.featureFlags['hasScheduledMaintenance'] = [
            FEATURE_SCHEDULED_MAINTENANCE
        ];

        this.hasRpaResourceAssociation = pipe(
            maybeOrganisation,
            map(organisation =>
                hasFeatureFlag(organisation, FEATURE_RPA_RESOURCE_ASSOCIATION)
            ),
            getOrElse(() => false)
        );
        this.featureFlags['hasRpaResourceAssociation'] = [
            FEATURE_RPA_RESOURCE_ASSOCIATION
        ];

        this.canAdminOrganisation = pipe(
            maybeOrganisation,
            map(
                organisation =>
                    this.managingOrganisationId &&
                    !organisation.personalOrganisation &&
                    (organisation.permissions.indexOf(
                        PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
                    ) !== -1 ||
                        organisation.ownerId ===
                            this.userService.getCurrentUser().id)
            ),
            getOrElse(() => false)
        );
    }

    editRpa() {
        this.isEditing = Editing.DETAILS;
        this.exclusiveControlService.lock(() => true);
    }

    editNotes() {
        this.isEditing = Editing.NOTES;
        this.exclusiveControlService.lock(() => true);
    }

    onEditCancelled() {
        this.exclusiveControlService.requestUnlock();
    }

    editAuthority() {
        this.isEditing = Editing.AUTHORITIES;
        this.exclusiveControlService.lock(() => true);
    }

    onRpaDeleted() {
        this.modal.hide();
    }

    cancel() {
        this.modal.hide();
    }
}
