import { NamedLayer, TypedDataLayerDto } from '@flyfreely-portal-ui/flyfreely';
import { Feature } from 'geojson';
import {
    CirclePaint,
    CustomLayerInterface,
    GeoJSONSourceRaw,
    Layer,
    SymbolLayout,
    SymbolPaint
} from 'mapbox-gl';
import { FeatureGroup, MapFeature, MapGeometry } from '../interfaces';

export function createGeoJsonSource(group: FeatureGroup): GeoJSONSourceRaw {
    if (group.type === 'Point') {
        return {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            },
            cluster: true,
            clusterMaxZoom: 16,
            clusterRadius: 32
        };
    }
    return {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: []
        }
    };
}

/**
 * Create the layers for this feature group. One of the layers must have the same ID as
 * the feature layer id so that we can easily detect if they are drawn or not.
 * @param featureLayerId the ID of the feature group
 * @param group the feature group
 */
export function createLayers(
    featureLayerId: string,
    group: FeatureGroup
): (Layer | CustomLayerInterface)[] {
    return group.type === 'Point'
        ? createPointLayers(featureLayerId, group)
        : createPolygonLayers(featureLayerId, group);
}

function createPolygonLayers(
    featureLayerId: string,
    group: FeatureGroup
): (Layer | CustomLayerInterface)[] {
    const fillStyles = group?.styles?.fill ?? [{}];
    const symbolStyles = group?.styles?.symbol ?? [{}];
    const lineStyles = group?.styles?.line ?? [{}];

    const result: (Layer | CustomLayerInterface)[] = [
        ...fillStyles.map(
            (p, index) =>
                ({
                    id: `${featureLayerId}_${index}`,
                    source: featureLayerId,
                    type: 'fill',
                    paint: {
                        'fill-color': '#900',
                        'fill-opacity': 0.4,
                        ...p?.paint
                    },
                    layout: { ...p?.layout },
                    filter: getFilter(
                        ['==', ['geometry-type'], 'Polygon'],
                        p.filter
                    )
                } as Layer)
        ),
        ...symbolStyles.map(
            (p, index) =>
                ({
                    id: `${featureLayerId}-point_${index}`,
                    source: featureLayerId,
                    type: 'symbol',
                    layout: {
                        ...p?.layout
                    } as SymbolLayout,
                    paint: { ...p?.paint },
                    filter: p.filter
                } as Layer)
        ),
        ...lineStyles.map(
            (p, index) =>
                ({
                    id: `${featureLayerId}-line_${index}`,
                    source: featureLayerId,
                    type: 'line',
                    layout: { ...p?.layout },
                    paint: {
                        'line-color': '#ff0000',
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'highlight'], false],
                            2,
                            1
                        ],
                        ...p?.paint
                    },
                    filter: getFilter(
                        ['!=', ['geometry-type'], 'Point'],
                        p.filter
                    )
                } as Layer)
        )
    ];

    return result;
}

function createPointLayers(
    featureLayerId: string,
    group: FeatureGroup
): (Layer | CustomLayerInterface)[] {
    const pointStyles = group?.styles?.symbol ?? [{}];

    const circlePaint: CirclePaint = {
        'circle-color': {
            property: 'point_count',
            type: 'interval',
            stops: [[0, '#005fa2']]
        },
        'circle-radius': {
            property: 'point_count',
            type: 'interval',
            stops: [
                [0, 15],
                [100, 20],
                [750, 25]
            ]
        }
    };

    const markerPaint: SymbolPaint = {};

    const result: (Layer | CustomLayerInterface)[] = [
        {
            paint: circlePaint,
            id: `${featureLayerId}-clustered`,
            source: featureLayerId,
            type: 'circle',
            filter: ['has', 'point_count']
        },
        {
            // paint: circlePaint,
            id: `${featureLayerId}-clustered-text`,
            source: featureLayerId,
            type: 'symbol',
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            } as SymbolLayout,
            paint: {
                'text-color': '#ffffff',
                'text-opacity': 1
            },
            filter: ['has', 'point_count']
        },
        ...pointStyles.map(
            (p, index) =>
                ({
                    id: `${featureLayerId}_${index}`,
                    source: featureLayerId,
                    type: 'symbol',
                    filter: getFilter(['!', ['has', 'point_count']], p.filter),
                    layout: {
                        'icon-image': 'flight-marker',
                        'icon-size': 0.75,
                        'icon-anchor': 'bottom',
                        'icon-allow-overlap': true,
                        ...p?.layout
                    } as SymbolLayout,
                    paint: markerPaint
                } as Layer)
        ),
        {
            // This is the generic label text formatting for points, including drawing points
            id: `${featureLayerId}-text`,
            source: featureLayerId,
            type: 'symbol',
            layout: {
                'text-field': '{name}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
                'text-offset': [0, 1]
            } as SymbolLayout,
            paint: {
                'text-color': '#ffffff',
                'text-opacity': 1
            },
            filter: ['!', ['has', 'point_count']]
        }
    ];

    return result;
}
/**
 * Given a default filter and custom filter, build a new filter
 * It assumes an `all`, thus an AND query. If need be, this can be made a parameter as well.
 */
function getFilter(defaultFilter: any[], customFilter: any[]) {
    if (!customFilter || customFilter.length === 0) {
        return defaultFilter;
    }
    return ['all', [...defaultFilter], [...customFilter]];
}

export function calculateClusterGroupAndFeatures(
    group: FeatureGroup,
    features: GeoJSON.Feature<GeoJSON.Geometry>[]
) {
    const clusterFeatureIds = features.map(f => f.id);
    const domainFeatures = group.existingFeatures.filter(
        f => clusterFeatureIds.indexOf(f.id) !== -1
    );

    return {
        groupId: group.id,
        features: domainFeatures
    };
}

export function calculateSourceId(group: FeatureGroup) {
    return calculateSourceIdFromId(group.id);
}

export function calculateSourceIdFromId(id: number | string) {
    return `feature_group_${id}`;
}

export function colourGenerator(ix: number) {
    const colours = [
        '#4c5916',
        '#40ffbf',
        '#005580',
        '#260d33',
        '#ff4400',
        '#664d1a',
        '#55f23d',
        '#00a69b',
        '#40a6ff',
        '#b630bf',
        '#7f2200',
        '#bfa330',
        '#00a642',
        '#165559',
        '#3056bf',
        '#f200a2',
        '#a65800',
        '#ffee00',
        '#0d331c',
        '#30a3bf',
        '#4040ff',
        '#800055',
        '#331b00',
        '#85a600',
        '#005930',
        '#0d2b33',
        '#110080',
        '#b22d3e'
    ];

    return colours[ix % colours.length];
}

/**
 * Generate a GeoJSON feature response
 * @param features the features
 * @param visibleFeatures the visible feature IDs
 * @param groupId the ID of the group
 */
export function getFeatures(
    features: MapFeature[],
    visibleFeatures: Set<number | string>,
    groupId: number
): Feature[] {
    const filteredFeatures = features.filter(
        f =>
            visibleFeatures.has(f.id) &&
            f.geom != null &&
            ((f.geom.type === 'GeometryCollection' &&
                f.geom.geometries != null) ||
                (f.geom.type !== 'GeometryCollection' &&
                    f.geom.coordinates != null))
    );
    const createFeature = (feat: MapFeature): Feature => {
        return {
            id: feat.id,
            type: 'Feature',
            geometry: feat.geom,
            properties: {
                ...feat.properties,
                name: feat.name,
                'category-id': feat.categoryId
            },
            data: groupId
        } as Feature;
    };
    const childFeatures: Feature[] = [].concat(
        ...filteredFeatures
            .filter(f => f.children != null)
            .map(f => f.children.map(createFeature))
    );
    const result = [...filteredFeatures.map(createFeature), ...childFeatures];
    return result;
}

export function isValidGeometry(geom: MapGeometry) {
    if (geom == null) {
        return false;
    }
    if (geom.type === 'GeometryCollection') {
        return geom.geometries != null;
    }

    return geom.coordinates != null;
}

/**
 * Flatten a list of data layers down to the concrete list of layers.
 * @param dataGroupList
 * @param enabledLayerGroups the layer group identifiers that should be included
 * @returns
 */
export function extractFlattenedLayers(
    dataGroupList: TypedDataLayerDto[],
    enabledLayerGroups: string[]
) {
    return dataGroupList.reduce(
        (acc, l) =>
            acc.concat(
                <NamedLayer[]>l.layers.groups.reduce(
                    (acc2, l2) =>
                        // Only pass airspace layer groups to the map for rendering if they have a null identifier,
                        // or if they are activated via the "enabledLayerGroups" input.
                        l2.identifier == null ||
                        enabledLayerGroups?.includes(l2.identifier)
                            ? acc2.concat(
                                  l2.layers.filter(layer => layer.id != null)
                              )
                            : acc2,
                    l.layers.ungrouped.filter(layer => layer.id != null)
                )
            ),
        []
    );
}

/**
 * Adjust the value to be within the min and max values.
 *
 * @param value the value to be clamped
 * @param range the max/min value of the range
 */
function clamp(value: number, range: number) {
    if (value > range) {
        while (value > range) {
            value = value - 2 * range;
        }
        return value;
    }
    if (value < -range) {
        while (value < -range) {
            value = value + 2 * range;
        }
        return value;
    }
    return value;
}

/**
 * Convert a mapbox bounds object to a GeoJSON polygon using the four corners, ignoring any geodesic lines.
 *
 * This takes account of the antimeridian for a map that is not rotated. If the map is going to be rotated, we need
 * to properly cut the polygon with a line string.
 * @param bound the latlong bounds
 */
export function asPolygon(bound: mapboxgl.LngLatBounds): GeoJSON.MultiPolygon {
    if (bound.isEmpty()) {
        return {
            type: 'MultiPolygon',
            coordinates: []
        };
    }

    const sw = bound.getSouthWest();
    const ne = bound.getNorthEast();

    if (sw.lng < -180 || ne.lng > 180) {
        return {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [clamp(sw.lng, 180), sw.lat],
                        [180, sw.lat],
                        [180, ne.lat],
                        [clamp(sw.lng, 180), ne.lat],
                        [clamp(sw.lng, 180), sw.lat]
                    ]
                ],
                [
                    [
                        [-180, sw.lat],
                        [clamp(ne.lng, 180), sw.lat],
                        [clamp(ne.lng, 180), ne.lat],
                        [-180, ne.lat],
                        [-180, sw.lat]
                    ]
                ]
            ]
        };
    } else {
        return {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [sw.lng, sw.lat],
                        [ne.lng, sw.lat],
                        [ne.lng, ne.lat],
                        [sw.lng, ne.lat],
                        [sw.lng, sw.lat]
                    ]
                ]
            ]
        };
    }
}

export function idListsDiffer(
    a: { id: number }[],
    b: { id: number }[]
): boolean {
    return (
        a.length !== b.length || !a.every(a_ => b.some(b_ => a_.id === b_.id))
    );
}

/**
 * Returns the elements in array a that are not in array b.
 * @param a The first array.
 * @param b The second array.
 * @returns The elements in array a that are not in array b.
 */
export function diff<T>(a: T[], b: T[]): T[] {
    return a.filter(x => !b.includes(x));
}
