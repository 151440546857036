import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    BatteryTypeDto,
    BatteryTypeService,
    CreateBatteryTypeCommand,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    Organisation,
    WorkTracker,
    batteryChemistries
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'battery-type-edit',
    templateUrl: './battery-type-dialogue.component.html',
    providers: [WorkTracker]
})
export class BatteryTypeDialogue {
    @Input() batteryType: BatteryTypeDto;
    @Input() organisation: Organisation;

    options: FormlyFormOptions;

    canEdit: boolean;

    isAdding: boolean;
    isEditing: boolean;

    chemistries = batteryChemistries;

    addEditFields: FormlyFieldConfig[];
    batteryTypeModel: BatteryTypeDto;

    working: boolean = false;
    IMG_URL: string;

    batteryTypeAddEditForm: FormGroup;
    private ngUnsubscribe$ = new Subject<void>();

    @ViewChild('batteryTabs', { static: true }) batteryTabs: TabsetComponent;

    constructor(
        private workTracker: WorkTracker,
        constants: FlyFreelyConstants,
        private batteryTypeService: BatteryTypeService,
        private modal: BsModalRef<BatteryTypeDialogue>,
        private logging: FlyFreelyLoggingService
    ) {
        this.IMG_URL = constants.IMG_URL;
        this.batteryTypeAddEditForm = new FormGroup({});
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
            });

        this.canEdit =
            this.organisation == null ||
            this.organisation.id === this.batteryType.organisationId;
        if (!this.canEdit) {
            this.batteryTypeAddEditForm.disable();
        }
        this.isAdding = this.batteryType.id ? false : true;
        this.isEditing = !this.isAdding;
        this.setupFormlyFields();

        this.batteryTypeModel = {
            ...this.batteryType
        };
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setupFormlyFields() {
        // Formly fields are down here in order to allow dynamic disabling
        this.addEditFields = [
            {
                key: 'make',
                type: 'input',
                focus: true,
                props: {
                    label: 'Make',
                    required: true,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'model',
                type: 'input',
                props: {
                    label: 'Model',
                    required: true,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'chemistry',
                type: 'ng-select',
                props: {
                    label: 'Chemistry',
                    valueProp: 'value',
                    labelProp: 'name',
                    options: this.chemistries,
                    required: true,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'cellsSeries',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Cells in series',
                    min: 1,
                    required: true,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'cellsParallel',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Cells in parallel',
                    min: 1,
                    required: true,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'intelligent',
                type: 'checkbox',
                props: {
                    label: 'Intelligent Battery?',
                    disabled: !this.canEdit
                }
            },
            {
                key: 'ratedCapacity',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Rated capacity (mAh)',
                    min: 0,
                    required: true,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'ratedCapacitynWh',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Rated capacity (mWh)',
                    min: 0,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'chargeRate',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Max charge rate (C)',
                    min: 0,
                    disabled: !this.canEdit
                }
            },
            {
                key: 'dischargeRate',
                type: 'input',
                props: {
                    type: 'number',
                    label: 'Max discharge rate (C)',
                    min: 0,
                    disabled: !this.canEdit
                }
            }
        ];
    }

    hide() {
        this.modal.hide();
    }

    save() {
        const values = this.batteryTypeAddEditForm.value;
        const toSave: CreateBatteryTypeCommand = {
            ...values,
            intelligent: values.intelligent || false,
            organisationId:
                this.organisation != null ? this.organisation.id : null,
            ratedCapacitymWh:
                this.batteryType.ratedCapacitymWh != null
                    ? this.batteryType.ratedCapacitymWh * 1000
                    : null
        };

        if (this.batteryType.id) {
            this.batteryTypeService
                .updateBatteryType(this.batteryType.id, toSave)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    () => {
                        this.logging.success('Battery type saved');
                        this.hide();
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(error, error.message);
                    }
                )
                .add(this.workTracker.createTracker());
        } else {
            this.batteryTypeService
                .createBatteryType(toSave)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    () => {
                        this.logging.success('Battery type saved');
                        this.hide();
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(error, error.message);
                    }
                )
                .add(this.workTracker.createTracker());
        }
    }
}
