import { Component, HostListener, Input, OnInit } from '@angular/core';
import { EnhancedHelpDto } from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'enhanced-help-dialogue',
    template: `
        <div class="modal-header no-icon">
            <h4 class="modal-title pull-left">{{ helpObject?.helpTitle }}</h4>
            <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="hide()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div
                class="bottom-buffer"
                [innerHTML]="helpObject?.helpText | autolink"
            ></div>
            <div
                *ngIf="helpObject?.knowledgeBaseUrl != null"
                class="bottom-buffer"
            >
                <a
                    class="btn btn-sm btn-default active"
                    target="_blank"
                    [href]="helpObject.knowledgeBaseUrl"
                    angulartics2On="click"
                    angularticsAction="show-knowledge-base"
                    angularticsCategory="help"
                    [angularticsProperties]="{
                        component: helpObject?.component,
                        screen: helpObject?.screen
                    }"
                    ><span class="fal fa-question-circle"></span> Knowledge
                    Base</a
                >
            </div>
            <embedded-video-player
                *ngIf="helpObject?.supportVideoUrl"
                [videoUrl]="helpObject?.supportVideoUrl"
                [displayWidth]="displayWidth"
                [displayHeight]="displayHeight"
            ></embedded-video-player>

            <div *ngIf="isDemo" class="fill"></div>
            <div *ngIf="isDemo" class="demo-help">
                <div class="horizontal-container vertical-align">
                    <div class="fcol-1 horizontal-container flex-centered">
                        <div class="help-icon vertical-align">
                            <span class="fal fa-question-circle"></span>
                        </div>
                    </div>
                    <div>
                        <label>Need Help in the Future?</label>
                        <div>
                            Click the help icon
                            <span class="fal fa-question-circle"></span> in the
                            top Navigation Bar to access the help tips again.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .demo-help {
                background-color: #ffb500;
                color: #000;
                border-radius: 10px;
                padding: 12px 0;
            }

            .help-icon {
                height: 34px;
                width: fit-content;
                padding: 10px;
                border-radius: 360px;
                background: white;
                color: black;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
            }
        `
    ]
})
export class EnhancedHelpDialogueComponent implements OnInit {
    @Input() helpObject?: EnhancedHelpDto;
    @Input() isDemo: boolean = false;
    // TODO: fix this isDemo flow, because it's not detecting

    innerWidth: number;
    displayWidth: number;
    displayHeight: number;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.findVideoDimentions();
    }

    constructor(private modal: BsModalRef) {}

    ngOnInit(): void {
        this.findVideoDimentions();
    }

    findVideoDimentions() {
        // Calculate the display size for the video player
        this.innerWidth = window.innerWidth;
        if (this.innerWidth > 992) {
            this.displayWidth = 850;
        } else {
            this.displayWidth =
                this.innerWidth > 200 ? Math.ceil(this.innerWidth - 70) : 200;
        }
        this.displayHeight = Math.ceil(this.displayWidth * 0.5625);
    }

    hide() {
        this.modal.hide();
    }
}
