import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { NameId } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'jurisdiction-selection',
    templateUrl: './jurisdiction-selection.component.html'
})
export class JurisdictionSelectionComponent {
    @Input() availableJurisdictions: NameId[];
    @Output() selectJurisdictionId = new EventEmitter<number>();

    jurisdictions: NameId[];
    activeId: number = null;

    ngOnChanges(changes: SimpleChanges) {
        if ('availableJurisdictions' in changes) {
            if (this.availableJurisdictions == null) {
                this.jurisdictions = [];
            } else {
                this.jurisdictions = [
                    { id: null, name: 'All' },
                    { id: -1, name: 'Global' }
                ].concat(
                    this.availableJurisdictions.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    })
                );
            }
        }
    }

    onSelectJurisdictionId(jurisdictionId: number) {
        this.activeId = jurisdictionId;
        this.selectJurisdictionId.emit(jurisdictionId);
    }
}
