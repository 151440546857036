import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-radio',
    template: `
        <div
            class="input-group"
            btnRadioGroup
            [formControl]="formControl"
            style="padding-top: 7px;"
        >
            <label
                *ngFor="let opt of to.options"
                class="btn btn-sm btn-pill"
                [btnRadio]="opt.value"
                >{{ opt.name }}</label
            >
        </div>
    `
})
export class FormlyRadio extends FieldType {}
