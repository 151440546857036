import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    AuthorityConditionDto,
    AuthorityRegisterConditionsDto,
    AuthorityTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UpdateRegisterSettingsCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { UiRegisterConditionsHandler } from '../interfaces';

function toCheck(check: UiCheck, order: number): AuthorityConditionDto {
    return {
        id: check.id,
        order,
        conditionType: check.conditionType,
        entity:
            check.conditionType === AuthorityConditionDto.ConditionType.ACTION
                ? null
                : 'AUTHORITY_TYPE',
        entityId: check.entityId,
        name:
            check.conditionType === AuthorityConditionDto.ConditionType.ACTION
                ? check.name
                : null,
        description:
            check.conditionType === AuthorityConditionDto.ConditionType.ACTION
                ? check.description
                : null,
        enabled: check.enabled
    };
}

export interface RegisterSettingsForm {
    registerMode: FormControl<AuthorityRegisterConditionsDto.RegisterMode>;
}

export interface UiCheck {
    id?: number;
    entityId: number;
    name: string;
    inCandidates: boolean;
    conditionType: AuthorityConditionDto.ConditionType;
    description?: string;
    enabled: boolean;
}

@Injectable()
export class RegisterSideSheetService {
    registerConditionsHandler: UiRegisterConditionsHandler;
    private candidatesSource = new ReplaySubject<AuthorityTypeDto[]>(1);
    private conditionsSource =
        new ReplaySubject<AuthorityRegisterConditionsDto>(1);
    private manualChecksSource = new BehaviorSubject<UiCheck[]>([]);
    private authorityChecksSource = new BehaviorSubject<UiCheck[]>([]);
    private conditionsChangedSource = new Subject<void>();
    candidates$ = this.candidatesSource.asObservable();
    conditions$ = this.conditionsSource.asObservable();
    manualChecks$ = this.manualChecksSource.asObservable();
    authorityChecks$ = this.authorityChecksSource.asObservable();
    conditionsChanged$ = this.conditionsChangedSource.asObservable();

    registerSettingsForm: FormGroup<RegisterSettingsForm>;

    private workTracker = new WorkTracker();
    working$ = this.workTracker.observable;
    private ngUnsubscribe$ = new Subject<void>();

    constructor(private logging: FlyFreelyLoggingService) {
        this.registerSettingsForm = new FormGroup({
            registerMode:
                new FormControl<AuthorityRegisterConditionsDto.RegisterMode>(
                    undefined
                )
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.candidatesSource.complete();
        this.conditionsSource.complete();
        this.manualChecksSource.complete();
        this.authorityChecksSource.complete();
        this.conditionsChangedSource.complete();
    }

    createWorkTracker() {
        return this.workTracker.createTracker();
    }

    updateManualChecks(manualChecks: UiCheck[]) {
        this.manualChecksSource.next(manualChecks);
    }

    updateAuthorityChecks(authorityChecks: UiCheck[]) {
        this.authorityChecksSource.next(authorityChecks);
    }

    setConditionHandler(
        registerConditionsHandler: UiRegisterConditionsHandler
    ) {
        this.registerConditionsHandler = registerConditionsHandler;
        this.refresh();
    }

    private refresh() {
        const doneWorking = this.workTracker.createTracker();

        Promise.all([
            this.registerConditionsHandler.findCandidateAuthorityTypes(),
            this.registerConditionsHandler.findRegisterConditions()
        ]).then(([candidates, conditions]) => {
            this.candidatesSource.next(candidates);
            this.conditionsSource.next(conditions);
            this.setupSettingsForm(conditions.registerMode);
            doneWorking();
        });
    }

    private setupSettingsForm(
        registerMode: AuthorityRegisterConditionsDto.RegisterMode
    ) {
        this.registerSettingsForm.patchValue({
            registerMode: registerMode
        });
    }

    save() {
        const doneWorking = this.workTracker.createTracker();
        const registerSettings: UpdateRegisterSettingsCommand.RegisterMode =
            this.registerSettingsForm.value.registerMode;

        const conditionsValues = this.manualChecksSource
            .getValue()
            .concat(this.authorityChecksSource.getValue())
            .map((c, ix) => toCheck(c, ix));
        this.registerConditionsHandler
            .updateRegisterSettings(registerSettings)
            .then(
                result => {
                    this.registerConditionsHandler
                        .updateRegisterConditions(conditionsValues)
                        .then(
                            conditions => {
                                this.conditionsSource.next(conditions);
                                this.conditionsChangedSource.next();

                                this.logging.success(
                                    'Register conditions updated'
                                );
                            },
                            (error: FlyFreelyError) =>
                                this.logging.error(
                                    error,
                                    `Error updating register conditions: ${error.message}`
                                )
                        );
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error updating register settings: ${error.message}`
                    )
            )
            .finally(() => doneWorking());
    }
}
