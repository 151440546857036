import { Pipe, PipeTransform } from '@angular/core';
import { OperationAuthorisation } from '@flyfreely-portal-ui/flyfreely';

const LOOKUP_TABLE = {
    [OperationAuthorisation.Status.APPROVED]: 'Approved',
    [OperationAuthorisation.Status.CANCELLED]: 'Cancelled',
    [OperationAuthorisation.Status.COMPLETED]: 'Completed',
    [OperationAuthorisation.Status.DECLARED]: 'Declared',
    [OperationAuthorisation.Status.ERROR]: 'Error',
    [OperationAuthorisation.Status.NOT_REQUIRED]: 'Not required',
    [OperationAuthorisation.Status.PENDING]: 'Pending',
    [OperationAuthorisation.Status.PROVISIONAL]: 'Provisional',
    [OperationAuthorisation.Status.REJECTED]: 'Rejected'
};

@Pipe({
    name: 'formatAuthorisationStatus'
})
export class FormatAuthorisationStatusPipe implements PipeTransform {
    transform(status: OperationAuthorisation.Status): string {
        if (LOOKUP_TABLE[status] != null) {
            return LOOKUP_TABLE[status];
        }
        // FIXME: the lookup should be updated so this isn't required
        // @ts-ignore - type name exists on API
        if (status === 'UNSUBMITED' || status === 'UNSUBMITTED') {
            return 'Unsubmitted';
        }
        // @ts-ignore - type name exists on API
        if (status === 'SUBMITTED') {
            return 'Submitted';
        }
        // @ts-ignore - type name exists on API
        if (status === 'RESCINDED') {
            return 'Rescinded';
        }
        // @ts-ignore - type name exists on API
        if (status === 'RESCINDED_AWAITING') {
            return 'Rescinded - Acknowledgement Required';
        }
        // @ts-ignore - type name exists on API
        if (status === 'INVALID') {
            return 'Invalid';
        }

        return status;
    }
}
