import {
    Input,
    Component,
    ContentChild,
    Output,
    EventEmitter
} from '@angular/core';
import { LockedModeDirective } from './locked-mode.directive';
import { UnlockedModeDirective } from './unlocked-mode.directive';

/**
 * This component manages the UI state for lockable fields. The calling template needs
 * to provide both view and edit mode templates, and this component will
 * handling placing relevant messaging about the locked field if it is provided.
 * ```html
   <lockable
        [isLocked]="lockedFields?.isDummy"
        message="This equipment has been used on a real mission so can't be made dummy"
    >
        <ng-template lockedMode>
            {{ equipment.isDummy | formatBoolean  | defaultTo }}
        </ng-template>
        <ng-template unlockedMode
            ><div
                class="input-group"
                btnRadioGroup
                formControlName="isDummy"
                style="padding-top: 5px;"
            >
                <label
                    class="btn btn-xs btn-pill"
                    [btnRadio]="false"
                    [disabled]="working"
                    >Real Equipment</label
                >
                <label
                    class="btn btn-xs btn-pill"
                    [btnRadio]="true"
                    [disabled]="working"
                    >Dummy Equipment</label
                >
            </div></ng-template
        >
    </lockable>
 * ```
 */
@Component({
    selector: 'lockable',
    templateUrl: './lockable.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `
    ]
})
export class LockableComponent {
    @ContentChild(LockedModeDirective, { static: true })
    lockedModeTpl: LockedModeDirective;

    @ContentChild(UnlockedModeDirective, { static: true })
    unlockedModeTpl: UnlockedModeDirective;

    @Output() modeChange = new EventEmitter<any>();

    @Input() isLocked: boolean;
    @Input() message: string;

    get currentView() {
        return this.isLocked
            ? this.lockedModeTpl.tpl
            : this.unlockedModeTpl.tpl;
    }
}
