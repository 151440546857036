/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.56-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompleteSortieCommand } from './completeSortieCommand';
import { MissionCrewDto } from './missionCrewDto';


/**
 * Command for creating a new historical mission
 */
export interface CreateHistoricalMissionCommand { 
    organisationId?: number;
    name?: string;
    missionDate?: string;
    timeZone?: string;
    missionTypeId?: number;
    /**
     * The mission workflow being used for this mission
     */
    missionWorkflowVersionId?: number;
    missionObjective?: string;
    /**
     * The mission object type ID
     */
    missionObjectiveTypeId?: number;
    craftIds: Array<number>;
    equipmentIds: Array<number>;
    missionCrew: Array<MissionCrewDto>;
    locationId?: number;
    /**
     * The visual line of sight option of mission
     */
    visualLineOfSight?: CreateHistoricalMissionCommand.VisualLineOfSight;
    /**
     * The time in which mission occur
     */
    timeOfDay?: CreateHistoricalMissionCommand.TimeOfDay;
    /**
     * The outcome of the objectives of this mission
     */
    outcome?: CreateHistoricalMissionCommand.Outcome;
    /**
     * Notes about the objective outcome that was achieved
     */
    objectiveOutcomeNotes?: string;
    /**
     * The message that is recorded about the entire mission
     */
    completionMessage: string;
    /**
     * The sorties that make up this mission
     */
    sorties: Array<CompleteSortieCommand>;
}
export namespace CreateHistoricalMissionCommand {
    export type VisualLineOfSight = 'VLOS' | 'EVLOS' | 'BVLOS';
    export const VisualLineOfSight = {
        VLOS: 'VLOS' as VisualLineOfSight,
        EVLOS: 'EVLOS' as VisualLineOfSight,
        BVLOS: 'BVLOS' as VisualLineOfSight
    };
    export type TimeOfDay = 'DAY' | 'NIGHT' | 'DAY_NIGHT';
    export const TimeOfDay = {
        DAY: 'DAY' as TimeOfDay,
        NIGHT: 'NIGHT' as TimeOfDay,
        DAY_NIGHT: 'DAY_NIGHT' as TimeOfDay
    };
    export type Outcome = 'COMPLETED' | 'PARTIALLY_COMPLETED' | 'INCOMPLETE';
    export const Outcome = {
        COMPLETED: 'COMPLETED' as Outcome,
        PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED' as Outcome,
        INCOMPLETE: 'INCOMPLETE' as Outcome
    };
}



