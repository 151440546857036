import { Pipe, PipeTransform } from '@angular/core';
import { CraftDto, CraftDetailsDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatRpa' })
export class FormatRpaPipe implements PipeTransform {
    transform(rpa: CraftDto | CraftDetailsDto) {
        if (!rpa) {
            return null;
        }
        if (rpa.isDummy) {
            return `${rpa.nickname} (dummy)`;
        }

        return rpa.nickname;
    }
}
