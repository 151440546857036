import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup,Validators } from '@angular/forms';
import {
    ChargesDto,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { expandOnEnterAnimation } from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SubscriptionPlanSelection } from '../purchase-subscription/purchase-subscription.service';
import { PlanSelectionService } from './plan-selection.service';

interface SubscriptionProduct {
    name: string;
    customerDescription: string;
    quantityLabel?: string;
}

interface PlanSelectionFormModel {
    licenceCount: FormControl<number>;
}

@Component({
    selector: 'plan-selection',
    templateUrl: './plan-selection.component.html',
    styleUrls: ['./plan-selection.component.scss'],
    animations: [expandOnEnterAnimation()]
})
export class PlanSelection {
    @Input()
    plan: SubscriptionPlanSelection;

    @Input()
    selected: boolean;

    @Input()
    current: boolean;

    @Input()
    upgrading: boolean;

    @Input()
    initialLicenceCount: number;

    /**
     * The number of licences the user currently has
     */
    @Input()
    currentLicenceCount: number;

    @Input()
    product: SubscriptionProduct;

    @Input()
    featuredPlan = false;

    @Input()
    selectedFeature: string;

    @Output()
    select = new EventEmitter<SubscriptionPlanSelection>();

    // formGroup: FormGroup<{ licenceCount: number }>;
    formGroup: FormGroup<PlanSelectionFormModel>;

    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    previewCharge: ChargesDto;

    /**
     * Used to disable the purchase when the value is invalid
     */
    disabled = false;

    constructor(
        private purchaseSubscriptionService: PlanSelectionService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private angulartics: Angulartics2
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.formGroup = new FormGroup({
            licenceCount: new FormControl<number>(undefined, [
                Validators.required,
                Validators.min(this.plan.minimumLicenceCount)
            ])
        });

        this.formGroup.valueChanges
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                tap(value => {
                    this.previewCharge = null;
                    this.disabled =
                        value.licenceCount === this.currentLicenceCount;
                }),
                debounceTime(200),
                switchMap(v => {
                    if (this.selected) {
                        this.angulartics.eventTrack.next({
                            action: 'purchase-plan-licence-count',
                            properties: {
                                category: 'subscriptions',
                                label: this.plan.renewalPeriod,
                                value: v.licenceCount
                            }
                        });
                    }
                    if (v.licenceCount == null) {
                        return;
                    }
                    return this.purchaseSubscriptionService.previewPurchaseSubscription(
                        this.plan,
                        v.licenceCount
                    );
                })
            )
            .subscribe(v => {
                this.previewCharge = v;
            });

        this.formGroup.setValue({ licenceCount: this.initialLicenceCount });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            'selected' in changes &&
            !changes.selected.firstChange &&
            changes.selected.currentValue
        ) {
            this.purchaseSubscriptionService.previewPurchaseSubscription(
                this.plan,
                this.formGroup.value.licenceCount
            );
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    selectThis() {
        this.angulartics.eventTrack.next({
            action: 'purchase-plan-select',
            properties: {
                category: 'subscriptions',
                label: this.plan.renewalPeriod
            }
        });
        this.select.next(this.plan);
    }

    purchase() {
        const properties = {
            category: 'subscriptions',
            label: this.plan.identifier,
            value: this.formGroup.value.licenceCount
        };

        this.angulartics.eventTrack.next({
            properties,
            action: this.upgrading
                ? 'upgrade-plan-purchase'
                : 'purchase-plan-purchase'
        });

        const purchase = this.upgrading
            ? this.commonDialoguesService.showConfirmationDialogue(
                  'Update Plan Selection',
                  `Are you sure you want to change your current subscription plan to the selected plan? Any changes will be instant and pricing differences will be pro-rated on your next bill.`,
                  'Continue',
                  () => Promise.resolve()
              )
            : Promise.resolve();

        purchase
            .then(() =>
                this.purchaseSubscriptionService
                    .purchaseSubscription(
                        this.plan.identifier,
                        this.formGroup.value.licenceCount,
                        this.upgrading
                    )
                    .subscribe({
                        next: DO_NOTHING,
                        error: (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `There was an error when ${
                                    this.upgrading ? 'upgrading' : 'purchasing'
                                } your subscription: ${error.message}`
                            );
                            this.angulartics.eventTrack.next({
                                properties,
                                action: this.upgrading
                                    ? 'upgrade-plan-purchase-failed'
                                    : 'purchase-plan-purchase-failed'
                            });
                        }
                    })
                    .add(this.workTracker.createTracker())
            )
            .catch(DO_NOTHING);
    }

    add() {
        const { licenceCount } = this.formGroup.value;
        if (licenceCount < this.plan.minimumLicenceCount) {
            this.formGroup.setValue({
                licenceCount: this.plan.minimumLicenceCount
            });
            return;
        }
        this.formGroup.setValue({
            licenceCount: this.formGroup.value.licenceCount + 1
        });
    }

    subtract() {
        const { licenceCount } = this.formGroup.value;
        if (licenceCount == this.plan.minimumLicenceCount) {
            return;
        }
        if (licenceCount < this.plan.minimumLicenceCount) {
            this.formGroup.setValue({
                licenceCount: this.plan.minimumLicenceCount
            });
            return;
        }
        this.formGroup.setValue({
            licenceCount: licenceCount - 1
        });
    }
}
