import { Pipe, PipeTransform } from '@angular/core';
import { ActiveOrganisationJurisdiction } from '@flyfreely-portal-ui/flyfreely';

/**
 * Find the active jurisdiction and print its authority information.
 * Used for displaying, for instance, the ARN if Australia is the active jurisdiction
 */
@Pipe({ name: 'formatJurisdictionAuthorityIdentifier' })
export class FormatJurisdictionAuthorityIdentifierPipe
    implements PipeTransform {
    transform(
        jurisdictions: ActiveOrganisationJurisdiction[],
        jurisdictionId: number
    ) {
        if (jurisdictions == null || jurisdictionId == null) {
            return null;
        }

        const activeJurisdiction = jurisdictions.find(
            j => j.id === jurisdictionId
        );

        if (
            activeJurisdiction == null ||
            activeJurisdiction.identifier == null
        ) {
            return '-';
        }

        const authority = activeJurisdiction.identifier;

        return `${authority.authorityType.identifierLabel} ${authority.identifier}`;
    }
}
