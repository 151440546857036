import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicator } from './loading-indicator.component';

@NgModule({
    imports: [CommonModule],
    providers: [],
    bootstrap: [],
    declarations: [LoadingIndicator],
    exports: [LoadingIndicator]
})
export class LoadingIndicatorModule {}
