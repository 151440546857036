import { Injectable } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceLogSummary,
    MaintenanceService,
    PersonsOrganisationDto,
    SimplePersonDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface UpcomingMaintenanceWidgetLog {
    resourceCategory: MaintenanceLogSummary.ResourceCategory;
    name: string;
    serialNumber: string;
    maintenanceType: MaintenanceLogSummary.MaintenanceLogType;
    status: string;
    requestTime: string;
    maintenanceLog: MaintenanceLogSummary;
}

export interface AssignedMaintenanceWidgetLog {
    resourceCategory: MaintenanceLogSummary.ResourceCategory;
    name: string;
    serialNumber: string;
    maintenanceType: MaintenanceLogSummary.MaintenanceLogType;
    status: string;
    requestTime: string;
    assignee: SimplePersonDto;
    maintenanceLog: MaintenanceLogSummary;
}

@Injectable()
export class MaintenanceTaskWidgetDataService {
    currentOrganisation: PersonsOrganisationDto;

    private maintenanceLogsSource = new BehaviorSubject<
        MaintenanceLogSummary[]
    >([]);
    private upcomingMaintenanceLogsSource = new BehaviorSubject<
        UpcomingMaintenanceWidgetLog[]
    >([]);
    private assignedMaintenanceLogsSource = new BehaviorSubject<
        AssignedMaintenanceWidgetLog[]
    >([]);
    private workingSource = new BehaviorSubject<boolean>(false);

    upcomingMaintenanceLogs$ = this.upcomingMaintenanceLogsSource.asObservable();
    assignedMaintenanceLogs$ = this.assignedMaintenanceLogsSource.asObservable();
    working$ = this.workingSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private maintenanceService: MaintenanceService,
        private workspaceStateService: WorkspaceStateService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));

        this.workspaceStateService.currentOrganisation$
            .pipe(
                filter(
                    organisation => organisation.type === 'organisation_loaded'
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe((organisation: CurrentOrganisation) => {
                this.currentOrganisation = organisation.organisation;
                if (this.currentOrganisation != null) {
                    this.refreshMaintenanceLogs();
                } else {
                    this.maintenanceLogsSource.next([]);
                }
            });
        this.maintenanceService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshMaintenanceLogs());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workingSource.complete();
        this.maintenanceLogsSource.complete();
        this.upcomingMaintenanceLogsSource.complete();
        this.assignedMaintenanceLogsSource.complete();
    }

    refreshMaintenanceLogs() {
        this.maintenanceService
            .findMaintenanceLogs(this.currentOrganisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: maintenanceLogs => {
                    this.maintenanceLogsSource.next(maintenanceLogs);
                    this.setupUpcomingMaintenance();
                    this.setupAssignedMaintenance();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error refreshing maintenance requests: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    setupUpcomingMaintenance() {
        const findIsUpcomingMaintenance = (
            maintenanceLog: MaintenanceLogSummary
        ) => {
            const combinedStatus = this.getCombinedMaintenanceStatus(
                maintenanceLog
            );
            return combinedStatus === 'DRAFT' || combinedStatus === 'REQUESTED';
        };
        const maintenanceLogs = this.maintenanceLogsSource.getValue();
        const upcomingLogs = maintenanceLogs.filter(m =>
            findIsUpcomingMaintenance(m)
        );
        this.upcomingMaintenanceLogsSource.next(
            upcomingLogs.map((log, i) => ({
                resourceCategory: log.resourceCategory,
                name: log.resource.name,
                serialNumber: log.resource.manufacturerSerialNumber,
                maintenanceType: log.maintenanceLogType,
                status: this.getCombinedMaintenanceStatus(log),
                requestTime: log.requestTime,
                maintenanceLog: log
            }))
        );
    }

    setupAssignedMaintenance() {
        const findAssignedMaintenance = (
            maintenanceLog: MaintenanceLogSummary
        ) => {
            const combinedStatus = this.getCombinedMaintenanceStatus(
                maintenanceLog
            );
            return (
                combinedStatus === 'ASSIGNED' ||
                combinedStatus === 'ACTIVITY_COMPLETED' ||
                combinedStatus === 'ACTIVITY_CANCELLED'
            );
        };
        const maintenanceLogs = this.maintenanceLogsSource.getValue();
        const assignedLogs = maintenanceLogs.filter(m =>
            findAssignedMaintenance(m)
        );
        this.assignedMaintenanceLogsSource.next(
            assignedLogs.map((log, i) => ({
                resourceCategory: log.resourceCategory,
                name: log.resource.name,
                serialNumber: log.resource.manufacturerSerialNumber,
                maintenanceType: log.maintenanceLogType,
                status: this.getCombinedMaintenanceStatus(log),
                requestTime: log.requestTime,
                assignee: log.assignee,
                maintenanceLog: log
            }))
        );
    }

    getCombinedMaintenanceStatus(maintenanceLog: MaintenanceLogSummary) {
        const latestActivity =
            maintenanceLog.activities.length > 0
                ? maintenanceLog.activities[
                      maintenanceLog.activities.length - 1
                  ]
                : null;
        return this.maintenanceService.getCombinedStatus(
            maintenanceLog.status,
            latestActivity != null ? latestActivity.status : null
        );
    }
}
