import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { DocumentationDialoguesService } from './documentation-dialogues.service';
import { DocumentationList } from './documentation-list/documentation-list.component';
import { DocumentationResponsesComponent } from './documentation-responses/documentation-responses.component';
import { FormControlEditDialogue } from './form-control-edit/form-control-edit-dialogue.component';
import { FormEdit } from './form-edit/form-edit.component';
import { FormInputDialogue } from './form-input/form-input-dialogue.component';
import { FormList } from './form-list/form-list.component';
import { FormResponseDialogue } from './form-response-dialogue/form-response-dialogue.component';
import { FormResponse } from './form-response/form-response.component';
import { FormCatalogue } from './form-catalogue/form-catalogue.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule,
        NgSelectModule,
        SharedUiModule,
        ValidationModule,
        LoadingIndicatorModule,
        AccordionModule,
        AttachmentsModule,
        PopoverModule,
        FlyFreelyFormlyModule,
        FormlyModule,
        TooltipModule,
        DragDropModule,
        NgSelectModule,
        EnhancedHelpModule,
        AnalyticsModule
    ],
    providers: [DocumentationDialoguesService],
    bootstrap: [],
    declarations: [
        FormControlEditDialogue,
        DocumentationList,
        FormInputDialogue,
        FormResponse,
        FormResponseDialogue,
        FormList,
        FormEdit,
        DocumentationResponsesComponent,
        FormCatalogue
    ],
    exports: [
        FormControlEditDialogue,
        DocumentationList,
        FormInputDialogue,
        FormResponse,
        FormResponseDialogue,
        FormList,
        DocumentationResponsesComponent,
        FormCatalogue
    ]
})
export class DocumentationModule {}
