/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A setup guide activity for a user
 */
export interface SetupGuideActivityDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The setup guide flow type of the activity
     */
    type: SetupGuideActivityDto.Type;
    /**
     * The status of the activity
     */
    status: SetupGuideActivityDto.Status;
    /**
     * The title of the setup guide details
     */
    title: string;
    /**
     * The sub title of the setup guide details
     */
    subtitle: string;
    /**
     * The url to the multimedia file
     */
    videoUrl: string;
    /**
     * The thumb nail of the multimedia file
     */
    videoThumbnail: string;
    /**
     * The order of this step in the setup guide flow
     */
    position: number;
    /**
     * The identifier of the setup guide details
     */
    stepIdentifier: SetupGuideActivityDto.StepIdentifier;
    /**
     * The identifier of the owner of this activity
     */
    personId: number;
}
export namespace SetupGuideActivityDto {
    export type Type = 'START_HERE' | 'GENERAL' | 'WORKFLOWS' | 'REGISTERS';
    export const Type = {
        START_HERE: 'START_HERE' as Type,
        GENERAL: 'GENERAL' as Type,
        WORKFLOWS: 'WORKFLOWS' as Type,
        REGISTERS: 'REGISTERS' as Type
    };
    export type Status = 'NEW' | 'VIEWED' | 'COMPLETE';
    export const Status = {
        NEW: 'NEW' as Status,
        VIEWED: 'VIEWED' as Status,
        COMPLETE: 'COMPLETE' as Status
    };
    export type StepIdentifier = 'WELCOME' | 'CHECK_ORG' | 'CHECK_DUMMY' | 'CREATE_LOCATION' | 'CREATE_MISSION' | 'SUBMIT_MISSION' | 'FIELD_APP' | 'COMPLETE_MISSION' | 'SUCCESS';
    export const StepIdentifier = {
        WELCOME: 'WELCOME' as StepIdentifier,
        CHECK_ORG: 'CHECK_ORG' as StepIdentifier,
        CHECK_DUMMY: 'CHECK_DUMMY' as StepIdentifier,
        CREATE_LOCATION: 'CREATE_LOCATION' as StepIdentifier,
        CREATE_MISSION: 'CREATE_MISSION' as StepIdentifier,
        SUBMIT_MISSION: 'SUBMIT_MISSION' as StepIdentifier,
        FIELD_APP: 'FIELD_APP' as StepIdentifier,
        COMPLETE_MISSION: 'COMPLETE_MISSION' as StepIdentifier,
        SUCCESS: 'SUCCESS' as StepIdentifier
    };
}


