import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AircraftRegisterEntryDto,
    AttachmentHandler,
    AuthorityConditionDto,
    AuthorityRegisterSummaryDto,
    AuthorityTypeDto,
    ChangeDetails,
    CheckExpiryDto,
    CraftAuthorityDto,
    CraftService,
    CreateAircraftRegisterEntryCommand,
    CurrentAttachmentVersionDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    NameId,
    OrganisationAuthorityService,
    OrganisationService,
    Register,
    RpaAuthorityService,
    RpaRegisterExpirySummary,
    UiOrganisationAuthorityDto,
    UpdateAircraftRegisterEntryCommand,
    UpdateRegisterSettingsCommand,
    WorkTracker,
    displayableRegisterModeLookup,
    fromLocalDate,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaPipe } from '@flyfreely-portal-ui/resource-ui';
import { FormatDatePipe, FormatDateTimePipe } from '@flyfreely-portal-ui/ui';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subject, forkJoin, firstValueFrom } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
    buildCheckContent,
    getPopoverValue,
    mapCheckExpiry,
    validateExistingEntityEndDate,
    validateExistingEntityStartDate,
    validateNewEntityEndDate,
    validateNewEntityStartDate
} from '../helpers';
import {
    CheckResults,
    RegisterCheck,
    RegisterEntry,
    UiRegisterConditionsHandler,
    mergeCheckResults
} from '../interfaces';

@Component({
    selector: 'rpa-register-dialogue',
    templateUrl: './rpa-register-dialogue.component.html'
})
export class RpaRegisterDialogue implements OnInit, OnDestroy {
    @Input() authority: CraftAuthorityDto;
    @Input() managingOrganisationId: number;
    @Input() organisationId: number;
    @Input() authorityType: AuthorityTypeDto;
    @Input() register: AuthorityRegisterSummaryDto;

    organisationAuthority: UiOrganisationAuthorityDto;

    candidates: NameId[] = [];

    sortList: { [key: string]: any };

    title = 'RPA';
    hasProfilePicture = false;
    checks: RegisterCheck[] = [];
    entries: RegisterEntry[] = [];
    entriesBuf: RegisterEntry[] = [];

    // the ID of the entry being edited
    editing: number;

    checkResults: CheckResults;
    registerConditionsHandler: UiRegisterConditionsHandler;

    expiry: RpaRegisterExpirySummary;
    mappedExpiries: {
        [entryId: number]: { [checkId: number]: CheckExpiryDto };
    } = {};

    rpaRegister: Register<
        CreateAircraftRegisterEntryCommand,
        UpdateAircraftRegisterEntryCommand,
        AircraftRegisterEntryDto
    >;

    displayableRegisterModeLookup = displayableRegisterModeLookup;

    attachmentHandler: AttachmentHandler;
    attachments: CurrentAttachmentVersionDto[];

    error: string;

    @ViewChild('popover', { static: false })
    popover: PopoverDirective;

    statuses = [
        { name: 'Pending', value: 'PENDING' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Suspended', value: 'SUSPENDED' },
        { name: 'Auto', value: 'AUTO' }
    ];

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    formGroup: FormGroup;

    expandedSideSheet: boolean = false;

    constructor(
        public modal: BsModalRef<RpaRegisterDialogue>,
        modalOptions: ModalOptions,
        private craftService: CraftService,
        private formatRpaPipe: FormatRpaPipe,
        private formatDatePipe: FormatDatePipe,
        private formatDateTimePipe: FormatDateTimePipe,
        private organisationAuthorityService: OrganisationAuthorityService,
        private rpaAuthorityService: RpaAuthorityService,
        private organisationService: OrganisationService,
        private rpaDialoguesService: RpaDialoguesService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.popover != null && this.popover.isOpen) {
                this.popover.hide();
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.rpaRegister = this.organisationAuthorityService.aircraftRegister(
            this.authority.id,
            this.register.id,
            this.managingOrganisationId
        );

        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.attachmentHandler = this.rpaAuthorityService.attachmentHandler(
            this.authority.id,
            this.organisationId
        );

        this.attachmentHandler.attachments$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(attachments => (this.attachments = attachments));

        this.formGroup = new FormGroup({
            add: new FormGroup({
                subjectId: new FormControl(undefined, [Validators.required]),
                startDate: new FormControl(undefined, [Validators.required]),
                endDate: new FormControl(undefined),
                statusSource: new FormControl('AUTO', [Validators.required])
            }),
            edit: new FormGroup({
                startDate: new FormControl(undefined, [Validators.required]),
                endDate: new FormControl(undefined),
                statusSource: new FormControl(undefined, [Validators.required])
            })
        });

        this.sortList = {};
        this.fetchData();
        this.registerConditionsHandler = this.getConditionsHandler();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    fetchData() {
        const doneWorking = this.workTracker.createTracker();
        forkJoin([
            this.refreshCandidates(),
            this.refreshExisting(),
            this.refreshExpiries()
        ])
            .toPromise()
            .then(([candidates, entries, expiries]) => {
                this.candidates = candidates;
                this.entries = this.entriesBuf = entries;
                this.add.controls.startDate.setValidators([
                    Validators.required,
                    validateNewEntityStartDate(
                        this.entries,
                        this.formGroup,
                        'RPA'
                    )
                ]);
                this.add.controls.endDate.setValidators([
                    validateNewEntityEndDate(
                        this.entries,
                        this.formGroup,
                        'RPA'
                    )
                ]);
            })
            .then(() => {
                this.refreshChecks()
                    .then(checks => (this.checks = checks))
                    .then(() => this.refreshCheckResults())
                    .then(checkResults => {
                        this.checkResults = checkResults;
                        doneWorking();
                    });
            });
    }

    refreshRegister() {
        this.organisationAuthorityService
            .findAuthority(this.authority.id, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                authority => {
                    this.organisationAuthority = authority;
                    const register = authority.registers.find(
                        r => r.id === this.register.id
                    );
                    this.register = register ?? this.register;
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing register details: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    getConditionsHandler() {
        return {
            findCandidateAuthorityTypes: () =>
                this.rpaAuthorityService
                    .findAvailableAuthorityTypes(this.organisationId)
                    .toPromise(),
            findRegisterConditions: () =>
                this.rpaRegister.findRegisterConditions(),
            updateRegisterConditions: (conditions: AuthorityConditionDto[]) =>
                this.rpaRegister.updateRegisterConditions(
                    {
                        managingOrganisationId: this.organisationId,
                        authorityRegisterId: this.register.id,
                        conditions
                    },
                    this.authority.id
                ),
            updateRegisterSettings: (
                registerMode: UpdateRegisterSettingsCommand.RegisterMode
            ) =>
                this.rpaRegister.updateRegisterSettings(
                    {
                        authorityRegisterId: this.register.id,
                        registerMode
                    },
                    this.authority.id
                )
        };
    }

    refreshCheckResults(): PromiseLike<CheckResults> {
        if (this.entries == null) {
            return Promise.resolve({});
        }

        return Promise.all(
            this.entries.map(e =>
                this.rpaRegister
                    .findCheckValues(e.id)
                    .toPromise()
                    .then(results => ({
                        status: results.status,
                        entryId: e.id,
                        checks: results.checks.map(r => {
                            if (this.mappedExpiries[e.id] != null) {
                                this.mappedExpiries = {
                                    ...this.mappedExpiries,
                                    [e.id]: {
                                        ...this.mappedExpiries[e.id],
                                        [r.authorityConditionId]: mapCheckExpiry(
                                            this.expiry,
                                            r,
                                            this.register.id
                                        )
                                    }
                                };
                            } else {
                                this.mappedExpiries = {
                                    ...this.mappedExpiries,
                                    [e.id]: {
                                        [r.authorityConditionId]: mapCheckExpiry(
                                            this.expiry,
                                            r,
                                            this.register.id
                                        )
                                    }
                                };
                            }
                            return {
                                checkId:
                                    r.authorityConditionId ??
                                    r.verificationEntityId ??
                                    r.entityId ??
                                    -1,
                                checkConditionType: r.conditionType,
                                entityId: e.subjectId,
                                content: buildCheckContent(
                                    r,
                                    this.formatDatePipe.transform,
                                    this.formatDateTimePipe.transform
                                ),
                                popover: getPopoverValue(r),
                                verificationEntityId: r.verificationEntityId,
                                details: {
                                    acknowledgementTime: r.updatedAt
                                },
                                activity: r
                            };
                        })
                    }))
            )
        ).then(results => results.reduce(mergeCheckResults, {}));
    }

    onCheckUpdated() {
        const doneWorking = this.workTracker.createTracker();
        this.refreshExpiries().then(() =>
            this.refreshCheckResults().then(checkResults => {
                this.checkResults = checkResults;
                doneWorking();
            })
        );
    }

    findCheckAttachment(checkName: string) {
        return this.attachments.find(a => a.name === checkName) ?? null;
    }

    refreshExpiries() {
        return this.organisationAuthorityService
            .aircraftRegisterExpiries(
                this.authority.id,
                this.register.id,
                this.managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(expiry => (this.expiry = expiry));
    }

    refreshCandidates(): PromiseLike<NameId[]> {
        return this.craftService
            .findCrafts(this.organisationId, { related: true })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(rpas =>
                rpas.map(r => ({
                    id: r.id,
                    name: this.formatRpaPipe.transform(r)
                }))
            );
    }

    refreshExisting(): PromiseLike<RegisterEntry[]> {
        return this.rpaRegister
            .find()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .toPromise()
            .then(candidates => candidates.map(c => this.toRegisterEntry(c)));
    }

    refreshChecks(): PromiseLike<RegisterCheck[]> {
        return this.rpaRegister.findRegisterConditions().then(conditions =>
            conditions.conditions
                .filter(c => c.enabled)
                .map(c => ({
                    id: c.id,
                    entity: c.entity,
                    entityId: c.entityId,
                    name: c.name,
                    popover: null,
                    conditionType: c.conditionType,
                    description: c.description
                }))
        );
    }

    findCheckResult(entry: RegisterEntry, check: RegisterCheck) {
        if (this.checkResults == null || this.checkResults[entry.id] == null) {
            return null;
        }

        const results = this.checkResults[entry.id];
        if (results[check.id] != null) {
            return results[check.id];
        } else if (results[check.entityId] != null) {
            return results[check.entityId];
        } else {
            const key = Object.keys(results)
                .filter(k => k != 'status')
                .find(
                    k => results[k]?.checkConditionType === check.conditionType
                );
            if (key != null) {
                return results[key];
            } else {
                return null;
            }
        }
    }

    private toRegisterEntry(c: AircraftRegisterEntryDto): RegisterEntry {
        return {
            id: c.id,
            startDate: fromLocalDate(c.startDate),
            endDate: fromLocalDate(c.endDate),
            subjectId: c.rpaId,
            subjectName: this.formatRpaPipe.transform(c.rpa),
            statusSource: c.statusSource
        };
    }

    showEntry(entry: RegisterEntry): void {
        this.organisationService
            .findByIdForUser(this.organisationId, this.organisationId)
            .toPromise()
            .then(organisation =>
                this.craftService
                    .findById(entry.subjectId, this.organisationId)
                    .toPromise()
                    .then(rpa =>
                        this.rpaDialoguesService.showCraftDetails(
                            rpa.id,
                            organisation.id,
                            false
                        )
                    )
            );
    }

    createConcreteEntry() {
        const addFormGroup = this.add;
        const entry = addFormGroup.value;

        if (addFormGroup.invalid) {
            addFormGroup.markAllAsTouched();
            this.logging.error(null, 'Not all fields have been completed');
            return;
        }

        const doneWorking = this.workTracker.createTracker();
        firstValueFrom(
            this.rpaRegister
                .createRegisterEntry({
                    aircraftId: entry.subjectId,
                    startDate: toLocalDate(entry.startDate),
                    endDate: toLocalDate(entry.endDate),
                    statusSource: entry.statusSource
                })
                .pipe(
                    takeUntil(this.ngUnsubscribe$),
                    tap(() =>
                        this.organisationAuthorityService.notifyUpdated(
                            this.authority.id
                        )
                    )
                )
        )
            .then(c => this.toRegisterEntry(c))
            .then(result => {
                this.entries.push(result);
                addFormGroup.reset();
                addFormGroup.controls.statusSource.patchValue('AUTO');
                this.logging.success('New entry added to register');
                return this.refreshExpiries().then(() =>
                    this.refreshCheckResults()
                );
            })
            .then(checkResults => (this.checkResults = checkResults))
            .catch(error => this.logging.error(error))
            .finally(() => doneWorking());
    }

    deleteConcreteEntry(entry: RegisterEntry) {
        const deleteRegisterEntry = () => {
            return firstValueFrom(
                this.rpaRegister
                    .deleteRegisterEntry(entry.id)
                    .pipe(
                        tap(() =>
                            this.organisationAuthorityService.notifyUpdated(
                                this.authority.id
                            )
                        )
                    )
            ).then(
                () => {
                    const index = this.entries.indexOf(entry);
                    this.entries.splice(index, 1);
                    this.logging.success('Register entry deleted');
                },
                error => this.logging.error(error)
            );
        };
        this.commonDialoguesService.showConfirmationDialogue(
            'Delete entry',
            'Are you sure you wish to delete this authority register entry?',
            'Delete',
            deleteRegisterEntry
        );
    }

    updateConcreteEntry() {
        const payload = this.formGroup.get('edit').value;

        const doneWorking = this.workTracker.createTracker();

        firstValueFrom(
            this.rpaRegister
                .updateRegisterEntry(this.editing, {
                    startDate: toLocalDate(payload.startDate),
                    endDate: toLocalDate(payload.endDate),
                    statusSource: payload.statusSource
                })
                .pipe(
                    tap(() =>
                        this.organisationAuthorityService.notifyUpdated(
                            this.authority.id
                        )
                    )
                )
        )
            .then(c => this.toRegisterEntry(c))
            .then(result => {
                const index = this.entries.findIndex(
                    e => e.id === this.editing
                );
                this.entries.splice(index, 1, result);
                this.editing = null;
                this.logging.success('Register entry updated');
                return this.refreshExpiries().then(() =>
                    this.refreshCheckResults()
                );
            })
            .then(checkResults => (this.checkResults = checkResults))
            .catch(error => this.logging.error(error))
            .finally(() => doneWorking());
    }

    onConditionsChanged() {
        this.refreshRegister();
        this.refreshConditions();
    }

    openPopover(pop: any) {
        this.popover = pop;
    }

    refreshConditions() {
        const doneWorking = this.workTracker.createTracker();
        this.refreshChecks()
            .then(checks => (this.checks = checks))
            .then(() =>
                this.refreshExpiries().then(() => this.refreshCheckResults())
            )
            .then(checkResults => {
                this.checkResults = checkResults;
                doneWorking();
            });
    }

    showEditMode(entry: RegisterEntry): void {
        this.editing = entry.id;

        this.edit.setValue({
            startDate: entry.startDate,
            endDate: entry.endDate,
            statusSource: entry.statusSource
        });
        this.edit.controls.startDate.setValidators([
            Validators.required,
            validateExistingEntityStartDate(entry.id, this.entries, 'RPA')
        ]);
        this.edit.controls.endDate.setValidators([
            validateExistingEntityEndDate(entry.id, this.entries, 'RPA')
        ]);
        entry.editable = true;
    }

    cancelEditMode(entry: RegisterEntry): void {
        entry.editable = false;
        this.editing = null;
    }

    sort(name: string) {
        if (this.sortList[name]) {
            this.sortList[name] = {
                ascending: !this.sortList[name].ascending
            };
        } else {
            this.sortList = {};
            this.sortList[name] = {
                ascending: true
            };
        }
        this.sortTable(name, this.sortList[name].ascending);
    }

    sortTable(name: string, ascending: boolean) {
        if (ascending) {
            this.entryAsc(name);
        } else {
            this.entryDes(name);
        }
    }

    entryAsc(name: string) {
        this.entries.sort((a, b) => {
            if (a[name] > b[name]) {
                return 1;
            } else if (a[name] < b[name]) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    entryDes(name: string) {
        this.entries.sort((a, b) => {
            if (a[name] > b[name]) {
                return -1;
            } else if (a[name] < b[name]) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    filterByStatus(value: string) {
        if (value === 'all') {
            this.entries = this.entriesBuf;
        } else {
            this.entries = this.entriesBuf.filter(entry => {
                return this.checkResults[entry.id]?.status === value;
            });
        }
    }

    hasError(controlName: string) {
        const control = this.formGroup.get(controlName);
        return (control.touched || control.dirty) && control.invalid;
    }

    get add() {
        return this.formGroup.get('add') as FormGroup;
    }

    get edit() {
        return this.formGroup.get('edit') as FormGroup;
    }
}
