/**
 * Common methods and types for all resources.
 */

import { NameValue } from "../model/ui";

export const RESOURCE_STATUS_LIST: NameValue[] = [
    { value: 'UNSERVICEABLE', name: 'Unserviceable' },
    { value: 'SERVICEABLE', name: 'Serviceable' },
    { value: 'UNDER_MAINTENANCE', name: 'Under maintenance' },
    { value: 'RETIRED', name: 'Retired' },
    { value: 'DISPOSED', name: 'Disposed' }
];
