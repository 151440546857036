import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceService } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '../helpers';

@Pipe({ name: 'formatMaintenanceActivityStatus' })
export class FormatMaintenanceActivityStatusPipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor(maintenanceService: MaintenanceService) {
        this.transform = setupStatusFormatter(
            maintenanceService.getMaintenanceActivityStatuses()
        );
    }
}
