/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.47-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EquipmentTypeDto } from './equipmentTypeDto';
import { InitialComponentDto } from './initialComponentDto';
import { LockedEquipmentFields } from './lockedEquipmentFields';
import { EquipmentActions } from './equipmentActions';


/**
 * ${swagger.Mission.equipment}
 */
export interface EquipmentDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The organisation that owns this entity
     */
    organisationId: number;
    equipmentType: EquipmentTypeDto;
    /**
     * The name of equipment
     */
    name: string;
    /**
     * ${swagger.Equipment.purchaseDate}
     */
    purchaseDate?: string;
    /**
     * ${swagger.Equipment.disposalDate}
     */
    disposalDate?: string;
    notes?: string;
    /**
     * The serial number of equipment
     */
    manufacturerSerialNumber: string;
    /**
     * The firmware that is currently on the equipment
     */
    currentFirmwareVersion?: string;
    /**
     * The ID of the NFC tag that is on the equipment
     */
    nfcId?: string;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    /**
     * Flag to indicate whether this Equipment is a dummy
     */
    isDummy: boolean;
    lockedFields: LockedEquipmentFields;
    /**
     * The status of the equipment
     */
    status: EquipmentDto.Status;
    availableActions: EquipmentActions;
    initialComponentList?: Array<InitialComponentDto>;
}
export namespace EquipmentDto {
    export type Status = 'SERVICEABLE' | 'UNSERVICEABLE' | 'UNDER_MAINTENANCE' | 'RETIRED' | 'DISPOSED' | 'DELETED';
    export const Status = {
        SERVICEABLE: 'SERVICEABLE' as Status,
        UNSERVICEABLE: 'UNSERVICEABLE' as Status,
        UNDER_MAINTENANCE: 'UNDER_MAINTENANCE' as Status,
        RETIRED: 'RETIRED' as Status,
        DISPOSED: 'DISPOSED' as Status,
        DELETED: 'DELETED' as Status
    };
}



