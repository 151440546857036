import {
    LocationDetailsDto,
    CreateLocationCommand,
    UpdateLocationCommand,
    DownloadKmlCommand,
    LocationDto
} from '../model/api';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlyFreelyConstants } from '../constants';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private baseUrl: string;

    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findLocations(organisationId: number) {
        return this.http.get<LocationDto[]>(
            `${this.baseUrl}/webapi/locations`,
            {
                params: new HttpParams().set(
                    'organisation',
                    organisationId.toString()
                )
            }
        );
    }

    findLocation(locationId: number) {
        return this.http.get<LocationDetailsDto>(
            `${this.baseUrl}/webapi/locations/${locationId}`
        );
    }

    createLocation(command: CreateLocationCommand) {
        return this.http
            .post<LocationDetailsDto>(
                `${this.baseUrl}/webapi/locations`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateLocation(locationId: number, command: UpdateLocationCommand) {
        return this.http
            .put<LocationDetailsDto>(
                `${this.baseUrl}/webapi/locations/${locationId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteLocation(locationId: number) {
        return this.http
            .delete<void>(`${this.baseUrl}/webapi/locations/${locationId}`)
            .pipe(tap(() => this.changeSource.next()));
    }

    uploadLocation(locationFile: File) {
        const formData = new FormData();
        formData.append('locationFile', locationFile);

        return this.http.post<GeoJSON.FeatureCollection>(
            `${this.baseUrl}/webapi/locations/convert`,
            formData
        );
        // return this.webapiService.post(
        //     '/webapi/locations/convert',
        //     formData,
        //     {},
        //     { headers: { 'Content-Type': undefined } }
        // );
    }

    downloadKml(locationId: number) {
        return `${this.baseUrl}/webapi/locations/${locationId}/kml`;
    }

    downloadKmlFromFeatures(features: DownloadKmlCommand) {
        return this.http.post(
            `${this.baseUrl}/webapi/locations/kml`,
            features,
            { responseType: 'blob', observe: 'response' }
        );
    }
}
