import { Pipe, PipeTransform } from '@angular/core';
import { setupStatusFormatter } from '../helpers';
import { maintenanceOutcomes } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatMaintenanceOutcome' })
export class FormatMaintenanceOutcomePipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor() {
        this.transform = setupStatusFormatter(maintenanceOutcomes);
    }
}
