import { firstValueFrom, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { KeyValue } from '@angular/common';
import moment from 'moment';
import { PreferencesService } from './preferences.service';
import { FlyFreelyConstants } from '../constants';
import {
    OnboardingAuthorityGroup,
    PersonalOnboardingPropertyOptions,
    PersonsOrganisationDto,
    UnlistedJurisdictionCommand,
    UpdatePersonalOnboardingProperties
} from '../model/api';
import { NameValue } from '../model/ui';

export enum OnboardingPhases {
    INDIVIDUAL_LICENCES = 'INDIVIDUAL_LICENCES',
    COMMERCIAL = 'COMMERCIAL',
    INDIVIDUAL_RULES = 'INDIVIDUAL_RULES'
}

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private preferencesService: PreferencesService
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findAuthoritiesForOnboarding(
        managingOrganisationId: number,
        organisationId: number,
        phase: OnboardingPhases,
        jurisdictionId: number
    ) {
        return this.http.get<OnboardingAuthorityGroup[]>(
            `${this.baseUrl}/webapi/onboarding/authorities?phase=${phase}&organisationId=${organisationId}&managingOrganisationId=${managingOrganisationId}&jurisdictionId=${jurisdictionId}`
        );
    }

    createDummyEquipment(organisationId: number) {
        return this.http
            .post<void>(
                `${this.baseUrl}/webapi/onboarding/dummyEquipment?organisationId=${organisationId}`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findPersonalPropertyOptions() {
        return this.http.get<PersonalOnboardingPropertyOptions>(
            `${this.baseUrl}/webapi/onboarding/personalPropertyOptions`
        );
    }

    setPersonalPropertyOptions(command: UpdatePersonalOnboardingProperties) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/onboarding/personalPropertyOptions`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findUnlistedJurisdictions() {
        return this.http.get<KeyValue<string, string>[]>(
            `${this.baseUrl}/webapi/onboarding/unlistedJurisdictions`
        );
    }

    setUnlistedJurisdictions(command: UnlistedJurisdictionCommand) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/onboarding/unlistedJurisdictions`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    requestOrganisationAccess() {
        return this.http
            .post<void>(`${this.baseUrl}/webapi/onboarding/requestAccess`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    findDemoOrganisations() {
        return this.http.get<NameValue<number>[]>(
            `${this.baseUrl}/webapi/onboarding/demoOrganisations`
        );
    }

    joinDemoOrganisation(organisationId: number) {
        return this.http.post<PersonsOrganisationDto>(
            `${this.baseUrl}/webapi/onboarding/demoOrganisations`,
            { organisationId }
        );
    }

    /**
     * Finds the user's onboarding preferences to determine whether the user has completed onboarding or not,
     * stores them against a variable and returns the result.
     *
     * The goal here is to limit the number of times this needs to be called since it used to get called each time
     * the state service transitioned to a new route. By storing the initial result the check only happens once from the dashboard.
     *
     * @returns A Promise containing a boolean of whether the user has completed onboarding or not
     */
    findUserOnboardingStatus() {
        return firstValueFrom(
            this.preferencesService.findPreferences('user-onboarding', null)
        );
    }

    markUserOnboardingAsDone() {
        return this.preferencesService.updatePreferences(
            'user-onboarding',
            null,
            {
                completionTime: moment().toISOString()
            }
        );
    }
}
