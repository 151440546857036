import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AirspaceJurisdictionDto,
    CreateLocationCommand,
    DO_NOTHING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LocationDetailsDto,
    LocationDto,
    LocationFeatureDto,
    LocationService,
    OrganisationService,
    PersonsOrganisationDto,
    PreferencesService,
    UpdateLocationCommand,
    WorkTracker,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import * as FileSaver from 'file-saver';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { GeoJsonProperties, GeometryCollection, LineString } from 'geojson';
import { AirspaceCheckService } from 'libs/airspace/src/lib/airspace-check/airspace-check.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { buildAreaLineFeature } from 'libs/map/src/lib/draw-modes/draw-area-line';
import { FlyFreelyMapComponent } from 'libs/map/src/lib/flyfreely-map/flyfreely-map.component';
import {
    FeatureAndGroup,
    FeatureGroup,
    MapFeature
} from 'libs/map/src/lib/interfaces';
import moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, combineLatest } from 'rxjs';
import {
    debounceTime,
    map as subjectMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import {
    flightAreaOfFeatures,
    getFeatureGroups,
    getFileName,
    toMapFeature
} from '../helpers';
import {
    KmlFeatureGroups,
    KmlUploadService,
    UploadedFeature,
    isSinglePolygon
} from '../kml-upload.service';
import { showKmlUploadDialogue } from '../kml-upload/kml-upload-dialogue.component';
import { LocationEditService } from '../location-edit.service';
import { AirspaceCheckerParametersWithStartEnd } from './airspace-checker/location-airspace-check.component';

// Mapbox requires a non-negative value for IDs
const NEW_FEATURE = 0;
const AIRSPACE_DISCLAIMER = 'location-edit-airspace-disclaimer-acknowledgement';

export interface LocationAirspaceParameters {
    date: Date;
    duration: number;
    ruleset: string;
    height: number;
}

@Component({
    selector: 'location-edit-v2-dialogue',
    templateUrl: './location-edit-dialogue.component.html',
    styleUrls: ['./location-edit-dialogue.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AirspaceCheckService, LocationEditService]
})
export class LocationEditV2Dialogue {
    @Input() locationId: number;
    @Input() organisationId: number;
    @Input() type: LocationDetailsDto.Type | LocationDto.Type =
        LocationDto.Type.TEMPLATE;
    @Input() missionValues: LocationAirspaceParameters;
    /**
     * These are the default values used to pre-populate a new location.
     * These are only used for new locations.
     */
    @Input() defaultValues: Partial<LocationDetailsDto>;
    @Input() canCreateMission: boolean;

    @Output() change = new EventEmitter<LocationDetailsDto>();
    @Output() updatedMissionValues =
        new EventEmitter<LocationAirspaceParameters>();

    mapReady = false;

    organisation: PersonsOrganisationDto;
    location: LocationDetailsDto;
    downloadUrl: string;

    featureGroups: FeatureGroup[];

    @ViewChild('map', { static: true }) map: FlyFreelyMapComponent;
    @ViewChild('drawingArea', { static: true }) drawingArea: ElementRef;

    formGroup: FormGroup;
    updateAirspaceCheckCommand$ = new Subject<void>();
    private featuresUpdated$ = new Subject<boolean>();
    private ngUnsubscribe$ = new Subject<void>();

    workTracker = new WorkTracker();

    isAddingFeature = false;
    working = false;

    hasRequiredFeatures = false;
    hasAnyFeatures = false;

    hoverFeatureId: number;
    editFeatureId: number;
    highlightFeatureId: number;

    isEditing = false;

    airspaceValue: AirspaceCheckerParametersWithStartEnd;

    private hasChanged = false;
    private wasNameSetByUser = false;

    private nextId = 1;

    selectedFeature: FeatureAndGroup;
    addedFeature: FeatureAndGroup;
    selectedFeatureGroup: FeatureGroup;


    // Airspace Disclaimer
    showAirspaceDisclaimer = false;
    shouldShowAirspaceDisclaimer = true;
    mapJurisdiction: AirspaceJurisdictionDto;

    flightArea: GeoJSON.Polygon;

    private isLoaded = false;

    constructor(
        private modal: BsModalRef<LocationEditV2Dialogue>,
        modalOptions: ModalOptions,
        private modalService: BsModalService,
        private locationService: LocationService,
        private kmlUploadService: KmlUploadService,
        private organisationService: OrganisationService,
        private locationEditService: LocationEditService,
        private preferencesService: PreferencesService,
        private commonDialoguesService: CommonDialoguesService,
        private fullscreenService: FullScreenService,
        private logging: FlyFreelyLoggingService,
        private changeDetector: ChangeDetectorRef,
        private angulartics2: Angulartics2
    ) {
        modalOptions.closeInterceptor = () => {
            this.handleEditOnClose();

            if (this.hasChanged || this.formGroup.dirty) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            }
            return Promise.resolve();
        };

        this.formGroup = new FormGroup({
            name: new FormControl(undefined, [Validators.required])
        });
    }

    ngOnInit() {
        combineLatest([
            this.workTracker.observable,
            this.locationEditService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([working, serviceWorking]) => {
                this.working = working || serviceWorking;
                this.changeDetector.markForCheck();
            });

        this.formGroup.controls.name.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(newValue => this.onFormChanges(newValue));
        this.refreshLocation();

        this.featuresUpdated$
            .pipe(
                // Debounce to allow the map to render the new features
                debounceTime(300),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(updated => {
                if (!this.isLoaded) {
                    this.isLoaded = this.map.zoomToAllFeatures(updated);
                } else {
                    this.map.zoomToAllFeatures(updated);
                }
            });

        this.locationEditService.missionCreated$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.hasChanged = false;
                this.formGroup.markAsPristine();
                this.modal.hide();
            });

        this.refreshPermissions();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.updateAirspaceCheckCommand$.complete();
        this.featuresUpdated$.complete();
    }

    @HostListener('document:keyup', ['$event'])
    handleDeleteKeyboardEvent(event: KeyboardEvent) {
        if (
            event.key === 'Delete' &&
            this.selectedFeatureGroup &&
            this.selectedFeature
        ) {
            this.deleteFeature(
                this.selectedFeatureGroup,
                this.selectedFeature.feature
            );
        }
    }

    private refreshLocation() {
        if (this.locationId != null) {
            this.locationService
                .findLocation(this.locationId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(location => this.setLocation(location))
                .add(this.workTracker.createTracker());
        } else {
            if (this.defaultValues != null) {
                this.formGroup.patchValue({
                    name: this.defaultValues.name
                });

                this.setFeatures(this.defaultValues.features ?? []);
            } else {
                this.setFeatures([]);
            }
        }
    }

    refreshPermissions() {
        this.organisationService
            .findByIdForUser(this.organisationId, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation;
            });
    }

    edit() {
        if (this.featureGroups[0].existingFeatures.length === 0) {
            this.map.editFeature({
                groupId: this.featureGroups[0].id,
                feature: null
            });
        } else {
            this.map.editFeature({
                groupId: this.featureGroups[0].id,
                feature: this.featureGroups[0].existingFeatures[0]
            });
        }
    }

    onFeatureNameChange(featureGroup: FeatureGroup, feature: MapFeature) {
        this.map.showFeature(
            {
                feature,
                groupId: featureGroup.id
            },
            true
        );
    }

    onSelectedFeatureUpdated(updated: FeatureAndGroup) {
        // for some reason, updated.feature.categoryId would still be the old value
        // however, when debugging and logging the updated object, it would show the new value.
        // tried many things, only option found was this ugly setTimeout
        window.setTimeout(() => {
            if (
                updated.feature.categoryId ===
                    LocationFeatureDto.Type.OFFSET_FLIGHT_AREA ||
                updated.feature.categoryId ===
                    LocationFeatureDto.Type.OFFSET_FLIGHT_AREA ||
                updated.feature.categoryId ===
                    LocationFeatureDto.Type.OFFSET_NO_FLY_AREA
            ) {
                this.handleOffsetFeatureChanged(updated);
            }

            this.setFeatureName(updated);
            this.setSelectedFeature(updated);
            this.onFeaturesUpdated(updated);
            this.changeDetector.detectChanges();
        });
    }

    onMapReady() {
        if (!this.mapReady) {
            this.mapReady = true;
            this.featuresUpdated$.next(true);
        }
    }

    onMapJurisdictionChanged(jurisdiction: AirspaceJurisdictionDto) {
        this.mapJurisdiction = jurisdiction;
        if (this.shouldShowAirspaceDisclaimer) {
            this.showAirspaceDisclaimerDialogue();
        }
    }

    onFeaturesUpdated(updated: FeatureAndGroup) {
        if (this.isAddingFeature) {
            return;
        }
        this.hasChanged = true;

        const isNew = updated.feature.id == null;

        const feature = !isNew
            ? updated
            : {
                  groupId: updated.groupId,
                  feature: {
                      ...updated.feature,
                      id: NEW_FEATURE,
                      name: this.formGroup.value.name
                  }
              };

        if (feature?.feature.children != null) {
            feature.feature.children.forEach(c => {
                c.categoryId = feature.feature.categoryId;
            });
        }

        const updateFeature = (existing: MapFeature[], toAdd: MapFeature) =>
            !isNew
                ? existing.map(f => (f.id === toAdd.id ? toAdd : f))
                : existing.concat(toAdd);

        this.featureGroups = this.featureGroups.map(g =>
            g.id === updated.groupId
                ? {
                      ...g,
                      existingFeatures: updateFeature(
                          g.existingFeatures,
                          feature.feature
                      )
                  }
                : g
        );

        this.refreshValidity();

        if (feature.feature.id === NEW_FEATURE) {
            this.map.showFeature(feature);
        }
    }

    onFeatureSelected(selectedFeature: FeatureAndGroup) {
        if (this.isAddingFeature) {
            return;
        }

        if (
            this.selectedFeature != null &&
            this.selectedFeature.feature.id === selectedFeature.feature.id
        ) {
            this.onFeatureEdit(selectedFeature);
        } else {
            this.setSelectedFeature(selectedFeature);
        }
    }

    onFeatureUnselected() {
        if (!this.isAddingFeature) {
            this.selectedFeature = null;
            this.selectedFeatureGroup = null;
        }
    }

    onFeatureEdit(feature: FeatureAndGroup) {
        this.map.editFeature(
            {
                feature: feature.feature,
                groupId: feature.groupId
            },
            this.findDrawProperties(feature)
        );
        this.setSelectedFeature(feature);
    }

    onFeatureDelete(feature: FeatureAndGroup) {
        const featureGroup = this.featureGroups.find(
            fg => fg.id === feature.groupId
        );
        this.deleteFeature(featureGroup, feature.feature);
        this.setSelectedFeature(null);
        // Lock the save button if no longer valid after delete
        this.refreshValidity();
        this.map.hidePopup();
    }

    onGeocodeResultSelected(feature: MapFeature) {
        if (!this.wasNameSetByUser) {
            this.formGroup.patchValue(
                { name: feature.name },
                { emitEvent: false }
            );
        }
    }

    onFormChanges(newValue: { name: string }) {
        this.wasNameSetByUser = true;
    }

    onFullscreenRequested() {
        this.fullscreenService.toggleFullScreen(this.drawingArea);
    }

    onEditModeChanged(isEditing: boolean) {
        this.hasChanged = true;

        if (this.isEditing && this.isAddingFeature && !isEditing) {
            this.isAddingFeature = false;
            this.refreshValidity();
            this.map.showPopup();
            this.setSelectedFeature(this.addedFeature);
        }
        this.isEditing = isEditing;

        this.changeDetector.detectChanges();
    }

    onFeatureHover(featureId: number) {
        this.hoverFeatureId = featureId;
    }

    onFeaturesLoaded() {
        this.featuresUpdated$.next(false);
    }

    onFeatureNameSelected(feature: MapFeature) {
        if (this.showAirspaceDisclaimer) {
            return;
        }
        const groupId = this.featureGroups.find(
            g => g.existingFeatures.filter(f => f.id === feature.id).length > 0
        ).id;
        const featureAndGroup: FeatureAndGroup = {
            groupId,
            feature
        };
        this.setSelectedFeature(featureAndGroup);
        this.map.zoomToFeature(feature, true, false);
    }

    private handleOffsetFeatureChanged(featureAndGroup: FeatureAndGroup) {
        const linestring = (<GeometryCollection>(
            featureAndGroup.feature.geom
        )).geometries.find(g => g.type === 'LineString');
        const updatedFeature = buildAreaLineFeature(
            <LineString>linestring,
            featureAndGroup.feature.properties
        );
        featureAndGroup.feature.geom = updatedFeature.geometry;
        featureAndGroup.feature.properties = updatedFeature.properties;
    }

    private refreshValidity() {
        const mergeAcc = (
            a: { hasAnyFeatures: boolean; hasRequiredFeatures: boolean },
            b: { hasAnyFeatures: boolean; hasRequiredFeatures: boolean }
        ) => ({
            hasAnyFeatures: a.hasAnyFeatures || b.hasAnyFeatures,
            hasRequiredFeatures: a.hasRequiredFeatures || b.hasRequiredFeatures
        });

        const { hasAnyFeatures, hasRequiredFeatures } =
            this.featureGroups.reduce(
                (acc, fg) =>
                    mergeAcc(
                        acc,
                        fg.existingFeatures.reduce(
                            (acc2, f) => ({
                                hasAnyFeatures: true,
                                hasRequiredFeatures:
                                    acc2.hasRequiredFeatures ||
                                    f.categoryId === 'FLIGHT_AREA' ||
                                    f.categoryId === 'OFFSET_FLIGHT_AREA'
                            }),
                            {
                                hasAnyFeatures: false,
                                hasRequiredFeatures: false
                            } as {
                                hasAnyFeatures: boolean;
                                hasRequiredFeatures: boolean;
                            }
                        )
                    ),
                { hasAnyFeatures: false, hasRequiredFeatures: false }
            );

        this.hasAnyFeatures = hasAnyFeatures;
        this.hasRequiredFeatures = hasRequiredFeatures;

        this.flightArea = pipe(
            flightAreaOfFeatures(this.getFeatures()),
            map<LocationFeatureDto, GeoJSON.Polygon>(f =>
                f.geometry.type !== 'GeometryCollection'
                    ? <GeoJSON.Polygon>f.geometry
                    : <GeoJSON.Polygon>(
                          (<GeometryCollection>f.geometry).geometries.find(
                              g => g.type === 'Polygon'
                          )
                      )
            ),
            getOrElse(() => null)
        );
        if (this.shouldShowAirspaceDisclaimer) {
            this.showAirspaceDisclaimerDialogue();
        }
    }

    private setLocation(location: LocationDetailsDto) {
        this.formGroup.patchValue(
            { name: location.name },
            { emitEvent: false }
        );
        this.wasNameSetByUser = true;

        this.location = location;

        this.setFeatures(location.features);
    }

    private setFeatures(locationFeatures: LocationFeatureDto[]) {
        const { features, nextId } = getFeatureGroups(
            locationFeatures,
            ++this.nextId
        );
        this.featureGroups = features;
        this.nextId = nextId + 1;

        this.refreshValidity();
    }

    private addFeature(locationFeature: LocationFeatureDto) {
        this.featureGroups = this.featureGroups.map(fg =>
            fg.categories.findIndex(c => c.id === locationFeature.type) !== -1
                ? {
                      ...fg,
                      existingFeatures: fg.existingFeatures.concat(
                          toMapFeature(locationFeature, this.nextId++)
                      )
                  }
                : fg
        );
        this.refreshValidity();
    }

    uploadLocation(locationFiles: File) {
        const locationFile = locationFiles;

        this.locationService
            .uploadLocation(locationFile)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                take(1),
                tap({
                    next: DO_NOTHING,
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error uploading KML file: ${error.message}`
                        )
                })
            )
            .subscribe(featureCollection => {
                const features =
                    this.kmlUploadService.parseKmlGroupings(featureCollection);
                if (features.length === 0) {
                    this.logging.error(
                        null,
                        `The uploaded KML does not contain any valid location geometry.`
                    );
                } else if (isSinglePolygon(features)) {
                    this.parseSingleKmlFeature(
                        features[0].features[0],
                        locationFile.name
                    );
                } else {
                    showKmlUploadDialogue(
                        this.modalService,
                        features,
                        locationFile.name
                    )
                        .content.selectedFeatures.pipe(
                            takeUntil(this.ngUnsubscribe$)
                        )
                        .subscribe(features =>
                            this.parseComplexKml(features, locationFile.name)
                        );
                }
            })
            .add(this.workTracker.createTracker());
    }

    parseSingleKmlFeature(feature: UploadedFeature, fileName: string) {
        this.addFeature({
            name: fileName,
            type: feature.featureType,
            geometry: <GeoJSON.Polygon>feature.geometry
        } as LocationFeatureDto);
        this.refreshValidity();
        this.changeDetector.markForCheck();
        this.featuresUpdated$.next(false);

        if (!this.wasNameSetByUser) {
            this.formGroup.patchValue(
                { name: feature.name },
                { emitEvent: false }
            );
        }

        this.angulartics2.eventTrack.next({
            action: 'kml-single-feature-import',
            properties: {
                category: `kml-upload`
            }
        });
    }

    parseComplexKml(features: KmlFeatureGroups[], name: string) {
        features.forEach(group => {
            group.features.forEach(f => {
                this.addFeature({
                    name: f.name,
                    type: f.featureType,
                    geometry: f.geometry
                } as LocationFeatureDto);
            });
        });
        this.refreshValidity();
        this.changeDetector.markForCheck();
        this.featuresUpdated$.next(false);

        if (!this.wasNameSetByUser) {
            this.formGroup.patchValue({ name: name }, { emitEvent: false });
        }
    }

    showAirspaceDisclaimerDialogue() {
        const disclaimer =
            this.location?.airspaceJurisdiction?.airspaceDisclaimer ??
            this.mapJurisdiction?.airspaceDisclaimer ??
            this.map?.currentlySelectedJurisdiction?.airspaceDisclaimer;
        if (disclaimer == null || disclaimer.length == 0) {
            this.showAirspaceDisclaimer = false;
            return;
        }
        this.mapJurisdiction =
            this.mapJurisdiction ?? this.location?.airspaceJurisdiction;
        if (!this.shouldShowAirspaceDisclaimer) {
            return;
        }
        this.preferencesService
            .findPreferencesAsOption(AIRSPACE_DISCLAIMER, null)
            .pipe(
                subjectMap(p =>
                    getOrElse(() => ({
                        date: moment(new Date()).subtract(1, 'day').toDate()
                    }))(p)
                ),
                takeUntil(this.ngUnsubscribe$),
                take(1)
            )
            .subscribe({
                next: preferences => {
                    if (preferences == null) {
                        this.showAirspaceDisclaimer = true;
                        return;
                    }
                    const today = moment(new Date()).format('YYYY-MM-DD');
                    const lastViewed = moment(preferences.date).format(
                        'YYYY-MM-DD'
                    );
                    // Only show the disclaimer once per account
                    this.showAirspaceDisclaimer =
                        moment(lastViewed).isBefore(today);
                    this.shouldShowAirspaceDisclaimer =
                        this.showAirspaceDisclaimer;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            });
    }

    onAirspaceDisclaimerAcknowledged() {
        this.showAirspaceDisclaimer = false;
        this.preferencesService
            .updatePreferences(AIRSPACE_DISCLAIMER, null, { date: new Date() })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => (this.shouldShowAirspaceDisclaimer = false))
            .add(this.workTracker.createTracker());
    }

    save() {
        if (this.isAddingFeature) {
            this.logging.warn(
                `Please finish adding the current feature before saving.`
            );
            return;
        }
        if (this.isEditing) {
            this.map.doneEditing();
            this.refreshValidity();
            if (this.hasRequiredFeatures) {
                this.save();
            } else {
                // Under normal use this toast should never fire, it's merely a fallback for in case.
                this.logging.error(
                    null,
                    `Location template does not meet all saving requirements. Please ensure there is a flight area and location name and try again.`
                );
            }
        }
        // Filter feature groups to all flight areas and corridors (offset flight areas)
        // Escape function if no required groups exist
        const requiredFeatureGroups = this.featureGroups.filter(g =>
            g.existingFeatures.reduce(
                (acc, f) =>
                    acc ||
                    ((f.categoryId === 'FLIGHT_AREA' ||
                        f.categoryId === 'OFFSET_FLIGHT_AREA') &&
                        f.geom != null),
                false
            )
        );
        if (requiredFeatureGroups.length == 0) {
            return;
        }

        // Find if the required feature groups contain a polygon, extracting any polygons from the corridors
        // Escape if the feature is not a polygon, or if the corridor doesn't contain a polygon
        let polygon;
        if (
            requiredFeatureGroups[0].existingFeatures[0].geom.type ===
            'GeometryCollection'
        ) {
            // Extract polygon from geometry collection, or exit function if there is no polygon in collection
            const collection = requiredFeatureGroups[0].existingFeatures[0]
                .geom as GeometryCollection;
            polygon = collection.geometries.find(g => g.type === 'Polygon');
        }
        const geom =
            polygon ?? requiredFeatureGroups[0].existingFeatures[0].geom;
        if (geom.type !== 'Polygon') {
            return;
        }

        const outputMissionValues: LocationAirspaceParameters = {
            date: this.airspaceValue?.airspaceDate,
            duration: this.airspaceValue?.airspaceDuration,
            ruleset: this.airspaceValue?.airspaceRuleset?.identifier,
            height: this.airspaceValue?.airspaceHeight
        };

        const newCopy =
            this.location != null &&
            this.location.id != null &&
            !this.location.availableActions.canEdit;

        const name = this.formGroup.value.name;

        const features = this.getFeatures();

        if (this.locationId != null && !newCopy) {
            const updateCommand: UpdateLocationCommand = {
                name,
                features,
                derivedFromId: this.location?.derivedFromId
            };
            this.locationService
                .updateLocation(this.locationId, updateCommand)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: result => {
                        this.hasChanged = false;
                        this.formGroup.markAsPristine();
                        this.logging.success(
                            `Location updated${
                                this.missionValues != null
                                    ? ' & mission details updated'
                                    : ''
                            }`
                        );
                        this.change.emit(result);
                        if (this.missionValues != null) {
                            this.updatedMissionValues.emit(outputMissionValues);
                        }
                        this.modal.hide();
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while updating location: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            const createCommand: CreateLocationCommand = {
                name,
                features,
                organisationId: this.organisationId,
                type: this.type,
                derivedFromId: this.defaultValues?.id ?? null
            };
            this.locationService
                .createLocation(createCommand)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: result => {
                        this.hasChanged = false;
                        this.formGroup.markAsPristine();
                        this.logging.success(
                            `Location created${
                                this.missionValues != null
                                    ? ' & mission details updated'
                                    : ''
                            }`
                        );
                        this.change.emit(result);
                        if (this.missionValues != null) {
                            this.updatedMissionValues.emit(outputMissionValues);
                        }
                        this.modal.hide();
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while saving location: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }

    createMission() {
        if (this.featureGroups[0].existingFeatures.length === 0) {
            return;
        }
        const geom = this.featureGroups[0].existingFeatures[0].geom;
        if (geom.type !== 'Polygon') {
            return;
        }

        const value = this.formGroup.value;

        const name = value.name;

        const features = this.getFeatures();

        const createCommand: CreateLocationCommand = {
            name,
            features,
            organisationId: this.organisationId,
            type: CreateLocationCommand.Type.MISSION,
            derivedFromId: this.defaultValues?.id ?? null
        };

        this.locationEditService.createMission(
            this.organisationId,
            this.airspaceValue,
            createCommand
        );
    }

    private getFeatures(): LocationFeatureDto[] {
        return this.featureGroups.reduce(
            (acc, fg) =>
                acc.concat(
                    fg.existingFeatures.reduce(
                        (acc2, f) =>
                            acc2.concat({
                                name: f.name,
                                geometry: f.geom,
                                type: f.categoryId,
                                properties: f.properties
                            }),
                        []
                    )
                ),
            /* fg.existingFeatures.find(
                f => f.geom.type === 'GeometryCollection'
            ) == null
                ? acc.concat(
                      fg.existingFeatures.reduce(
                          (acc2, f) =>
                              acc2.concat({
                                  name: f.name,
                                  geometry: f.geom,
                                  type: f.categoryId,
                                  properties: f.properties
                              }),
                          []
                      )
                  )
                : acc.concat(
                      fg.existingFeatures.reduce(
                          (acc2, f) =>
                              f.geom.type !== 'GeometryCollection'
                                  ? acc2.concat({
                                        name: f.name,
                                        geometry: f.geom,
                                        type: f.categoryId,
                                        properties: f.properties
                                    })
                                  : acc2.concat(
                                        (<GeometryCollection>(
                                            f.geom
                                        )).geometries.map(geom => ({
                                            name: f.name,
                                            geometry: geom,
                                            type: f.categoryId,
                                            properties: f.properties
                                        }))
                                    ),
                          []
                      )
                  ), */
            []
        );
    }

    addNewFeature(featureGroup: FeatureGroup) {
        this.cancelAddingFeature();

        this.isAddingFeature = true;
        const newFeature: MapFeature = {
            id: this.nextId++,
            name: this.isExistingFeaturesValid(featureGroup)
                ? this.numerateNameIfExist(
                      featureGroup.categories[0].name,
                      featureGroup.existingFeatures
                  )
                : featureGroup.categories[0].name,
            categoryId: featureGroup.categories[0].id,
            geom: {
                // @ts-ignore - bad type sensing
                type: featureGroup.type
            }
        };
        this.selectedFeature = {
            groupId: featureGroup.id,
            feature: newFeature
        };
        this.addedFeature = this.selectedFeature;

        this.featureGroups = this.featureGroups.map(g =>
            g.id === featureGroup.id
                ? {
                      ...g,
                      existingFeatures: g.existingFeatures.concat(newFeature)
                  }
                : g
        );

        this.map.editFeature({
            groupId: featureGroup.id,
            feature: newFeature
        });
    }

    numerateNameIfExist(name: string, existingFeatures: MapFeature[]) {
        const existNames = existingFeatures.map(e => e.name);
        const baseNamePattern = new RegExp(`^${name}( \\d+)?$`);

        // Filter existing names that match the base name pattern
        let matchingNames = existNames.filter(e => baseNamePattern.test(e));
        if (matchingNames.length > 0) {
            // When adding a second feature and the enumerations are added
            // make original feature starting at 1.
            if (matchingNames.length === 1 && matchingNames[0] === name) {
                const newDefault = `${name} 1`;
                existingFeatures[0].name = newDefault;
                matchingNames = [newDefault];
            }
            // Get the highest number suffix from matching names
            const numbers = matchingNames.map(n => {
                const match = n.match(/\d+$/);
                return match ? parseInt(match[0], 10) : 0;
            });
            const maxNumber = Math.max(...numbers);

            // Numerate name
            return `${name} ${maxNumber + 1}`;
        }
        return name;
    }

    isExistingFeaturesValid(featureGroup: FeatureGroup | null) {
        return (
            featureGroup != null && featureGroup.existingFeatures.length !== 0
        );
    }

    cancelAddingFeature() {
        if (this.isAddingFeature && this.selectedFeature != null) {
            // Cancel the existing add first
            this.featureGroups = this.featureGroups.map(g =>
                g.id === this.selectedFeature.groupId
                    ? {
                          ...g,
                          existingFeatures: g.existingFeatures.filter(
                              f => f.id !== this.selectedFeature.feature.id
                          )
                      }
                    : g
            );
        }
    }

    editFeature(featureGroup: FeatureGroup, feature: MapFeature) {
        this.editFeatureId = <number>feature.id;
        this.map.editFeature({
            feature,
            groupId: featureGroup.id
        });
    }

    deleteFeature(featureGroup: FeatureGroup, feature: MapFeature) {
        this.map.doneEditing();

        this.featureGroups = this.featureGroups.map(fg =>
            fg.id !== featureGroup.id
                ? fg
                : {
                      ...fg,
                      existingFeatures: fg.existingFeatures.filter(
                          f => f.id !== feature.id
                      )
                  }
        );
    }

    highlightFeature(featureGroup: FeatureGroup, highlightFeatureId: number) {
        this.map.highlightFeature(featureGroup.id, highlightFeatureId);
    }

    unhighlightFeature(featureGroup: FeatureGroup, highlightFeatureId: number) {
        this.map.unhighlightFeature(featureGroup.id, highlightFeatureId);
    }

    private setSelectedFeature(feature: FeatureAndGroup) {
        this.selectedFeature = feature;
        this.selectedFeatureGroup = feature
            ? this.featureGroups.find(fg => fg.id === feature.groupId)
            : null;
    }

    private setFeatureName(feature: FeatureAndGroup) {
        if (!!feature && this.isSelectedFeatureGroupValid()) {
            const categories = this.selectedFeatureGroup.categories;
            const presetCategoryNames = categories.map(
                category => category.name
            );
            const isFeatureNameDefault =
                presetCategoryNames.includes(feature.feature.name) ||
                presetCategoryNames.some(presetCategoryName =>
                    new RegExp(`^${presetCategoryName}( \\d+)?$`).test(
                        feature.feature.name
                    )
                );
            const featureGroup = this.featureGroups.find(
                fg => fg.id === feature.groupId
            );
            if (isFeatureNameDefault) {
                const newName = categories.find(
                    category => category.id === feature.feature.categoryId
                ).name;
                feature.feature.name =
                    this.isExistingFeaturesValid(featureGroup) &&
                    featureGroup.existingFeatures.filter(
                        ef => ef.id !== feature.feature.id
                    ).length > 0
                        ? this.numerateNameIfExist(
                              newName,
                              featureGroup.existingFeatures.filter(
                                  ef => ef.id !== feature.feature.id
                              )
                          )
                        : newName;
            }
        }
    }

    isSelectedFeatureGroupValid() {
        return (
            !!this.selectedFeatureGroup &&
            !!this.selectedFeatureGroup.categories &&
            Array.isArray(this.selectedFeatureGroup.categories) &&
            this.selectedFeatureGroup.categories.length !== 0
        );
    }

    /**
     * Find the layout details for passing into the draw function to make sure
     * the draw version of the symbols match the view versions.
     */
    private findDrawProperties(feature: FeatureAndGroup): GeoJsonProperties {
        const group = this.featureGroups.find(g => g.id === feature.groupId);
        if (group == null) {
            return undefined;
        }

        if (group.type !== 'Point' || feature.feature.categoryId == null) {
            return undefined;
        }

        const style = group.styles.symbol.find(
            s =>
                s.layout?.['icon-image'] != null &&
                s.filter[0] === '==' &&
                s.filter[2] === feature.feature.categoryId
        );

        return style.layout;
    }

    handleEditOnClose() {
        if (this.isAddingFeature) {
            this.cancelAddingFeature();
            this.map.doneEditing();
            this.onFeatureDelete(this.selectedFeature);
            this.hasChanged = true;
            this.isAddingFeature = false;
        }
        if (this.isEditing) {
            this.map.doneEditing();
            this.hasChanged = true;
        }
    }

    downloadKml() {
        const name = this.formGroup.value.name ?? 'Location';
        this.locationService
            .downloadKmlFromFeatures({ name, features: this.getFeatures() })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                download => {
                    const headers = download.headers;
                    const fileName =
                        getFileName(headers.get('content-disposition')) ??
                        'flyfreely.kml';

                    FileSaver.saveAs(download.body, fileName);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error downloading KML file: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    close() {
        this.handleEditOnClose();
        this.modal.hide();
    }
}
