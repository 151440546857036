import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    GeneralTask,
    TaskPriority,
    UpdateTaskCommand
} from 'libs/flyfreely/src/lib/services/generalTasks.service';
import {
    AttachmentHandler,
    CurrentPersonDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    fromLocalDate,
    Organisation,
    PersonRolesDto,
    personSearch,
    PersonService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
    formatGeneralTasksPriority,
    formatGeneralTasksStatuses,
    GeneralTasksDataService
} from 'libs/outstanding-tasks/src/lib/widgets/general-task/general-tasks-data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { LinkAction } from '../../../../../../attachments/src/lib/link-manager/link-manager.interface';
import {
    Comment,
    CommentDelete,
    CommentUpdate
} from 'libs/comments/src/lib/interfaces';

@Component({
    selector: 'general-task-edit',
    templateUrl: './general-task-edit.component.html',
    styleUrls: ['./general-task-edit.component.css']
})
export class GeneralTaskEditComponent implements OnInit {
    @Input() generalTask: GeneralTask;
    @Input() organisation: Organisation;
    @Output() cancel = new EventEmitter<void>();
    @Output() update = new EventEmitter<GeneralTask>();

    comments: Comment[];
    currentUser: CurrentPersonDto | undefined;
    attachmentsHandler: AttachmentHandler;
    editGeneralTaskForm: FormGroup;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    personList$: Observable<PersonRolesDto[]>;
    priorityOptions = Object.entries(formatGeneralTasksPriority).map(
        ([value, label]) => ({ label, value })
    );
    statusOptions = Object.entries(formatGeneralTasksStatuses).map(
        ([value, label]) => ({ label, value })
    );

    constructor(
        private personService: PersonService,
        private generalTasksDataService: GeneralTasksDataService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit(): void {
        this.personList$ = this.personService.findPersonnel(
            this.organisation.id
        );
        this.initEditForm();
        this.refreshComments();
        this.attachmentsHandler =
            this.generalTasksDataService.attachmentHandler(
                this.generalTask.id,
                this.organisation.id
            );
        this.generalTasksDataService.commentChange$.subscribe(() => {
            this.refreshComments();
        });
    }

    initEditForm(): void {
        this.editGeneralTaskForm = new FormGroup({
            summary: new FormControl(
                this.generalTask?.summary,
                Validators.required
            ),
            description: new FormControl(
                this.generalTask?.description,
                Validators.required
            ),
            dueDate: new FormControl(
                this.generalTask.dueDate
                    ? fromLocalDate(this.generalTask.dueDate)
                    : null
            ),
            priority: new FormControl(
                this.generalTask?.priority,
                Validators.required
            ),
            assignee: new FormControl(
                this.generalTask?.assignee?.id,
                Validators.required
            ),
            status: new FormControl(
                this.generalTask.status,
                Validators.required
            )
        });
    }

    onCancel() {
        if (this.editGeneralTaskForm?.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancelEdit() {
        this.cancel.emit();
    }

    onSave() {
        const value = this.editGeneralTaskForm.value;
        this.generalTasksDataService
            .updateTask({
                taskId: this.generalTask.id,
                summary: value.summary,
                description: value.description,
                dueDate: value.dueDate,
                assigneeId: value.assignee,
                priority: value.priority,
                status: value.status
            })
            .subscribe({
                next: newTask => {
                    this.logging.success(`Successfully update task`);
                    this.update.emit(newTask);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while update task: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    refreshComments() {
        this.generalTasksDataService
            .findComments(this.generalTask.id)
            .subscribe(res => {
                this.comments = res as unknown as Comment[];
            });
    }

    onCreateComment(content: Object) {
        this.generalTasksDataService
            .createComment(this.generalTask.id, content)
            .subscribe({
                next: () => {
                    this.logging.success(`Successfully create comment`);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while create comment: ${error.message}`
                    );
                }
            });
    }

    onUpdateComment($event: CommentUpdate) {
        this.generalTasksDataService
            .updateComment(
                this.generalTask.id,
                $event.commentId,
                $event.content
            )
            .subscribe({
                next: () => {
                    this.logging.success(`Successfully update comment`);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while update comment: ${error.message}`
                    );
                }
            });
    }

    onDeleteComment($event: CommentDelete) {
        this.generalTasksDataService
            .deleteComment(this.generalTask.id, $event.commentId)
            .subscribe({
                next: () => {
                    this.logging.success(`Successfully delete comment`);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while delete comment: ${error.message}`
                    );
                }
            });
    }

    protected readonly LinkAction = LinkAction;
    protected readonly personSearch = personSearch;
}
