<div class="modal-body" *ngIf="authorities != null">
    <div *ngFor="let authority of authorities; let index = i">
        <h4 class="section-heading">
            <span>{{ authority.name }}</span>
            <div></div>
        </h4>
        <div class="contents">
            <div class="data">
                <span class="display-label">Start Date:</span>
                {{ authority.startDate | formatDate }}
            </div>
            <div class="data">
                <span class="display-label">End Date:</span>
                {{ authority.endDate | formatDate }}
            </div>
            <div class="data">
                <span class="display-label"
                    >{{
                        authority.authorityType.identifierLabel || 'Identifier'
                    }}:</span
                >
                {{ authority.identifier }}
            </div>
        </div>

        <div class="row top-buffer"></div>
        <attachment-list
            [attachmentsHandler]="authority.attachmentHandler"
            [allowUploads]="false"
            [allowEdit]="false"
            [allowAcknowledgements]="mission.availableActions.canEdit"
            [hideHeadings]="true"
            [requestingOrganisationId]="organisationId"
            permissions
        >
            Attachments
        </attachment-list>
    </div>
</div>
