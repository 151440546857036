/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AirspaceJurisdictionDto } from './airspaceJurisdictionDto';
import { LocationActions } from './locationActions';
import { CoordinateDto } from './coordinateDto';
import { LocationFeatureDto } from './locationFeatureDto';


/**
 * The details of a location including coordinates
 */
export interface LocationDetailsDto { 
    /**
     * The ID of the saved location
     */
    id: number;
    /**
     * The user provided name for the location
     */
    name: string;
    availableActions: LocationActions;
    /**
     * The ID of the organisation that owns the locations
     */
    organisationId: number;
    /**
     * ${swagger.Location.type}
     */
    type: LocationDetailsDto.Type;
    /**
     * The ID of the location that this location is based on
     */
    derivedFromId?: number;
    /**
     * The feature areas of the location
     */
    features: Array<LocationFeatureDto>;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    airspaceJurisdiction?: AirspaceJurisdictionDto;
    /**
     * The time zone that the centroid of the location is in
     */
    timeZone?: string;
}
export namespace LocationDetailsDto {
    export type Type = 'MISSION' | 'TEMPLATE';
    export const Type = {
        MISSION: 'MISSION' as Type,
        TEMPLATE: 'TEMPLATE' as Type
    };
}


