import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ngfModule } from 'angular-file';
import { Angulartics2Module } from 'angulartics2';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { AttachmentHistory } from './attachment-history/attachment-history.component';
import { AttachmentList } from './attachments-list/attachments-list.component';
import { DocumentPreviewDialogueComponent } from './document-preview/document-preview-dialogue.component';
import { AttachmentViewService } from './documentPreview.service';
import { LinkManagerComponent } from './link-manager/link-manager.component';
import { LinksAndAttachmentsListComponent } from './links-and-attachments-list/links-and-attachments-list.component';
import { AttachmentPermissionsDirective } from './attachment-permissions.directive';

@NgModule({
    declarations: [
        AttachmentList,
        AttachmentHistory,
        DocumentPreviewDialogueComponent,
        LinkManagerComponent,
        LinksAndAttachmentsListComponent,
        AttachmentPermissionsDirective
    ],
    imports: [
        CommonModule,
        TooltipModule,
        SharedUiModule,
        ngfModule,
        NgxLoadingModule,
        PopoverModule,
        FormsModule,
        ReactiveFormsModule,
        Angulartics2Module
    ],
    providers: [AttachmentViewService],
    exports: [
        AttachmentList,
        LinksAndAttachmentsListComponent,
        AttachmentHistory,
        DocumentPreviewDialogueComponent,
        LinkManagerComponent,
        AttachmentPermissionsDirective
    ],
    entryComponents: [AttachmentList, AttachmentHistory]
})
export class AttachmentsModule {}
