import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { AnalyticsModule } from '@flyfreely-portal-ui/analytics';
import { NgSelectModule } from '@ng-select/ng-select';
import { ngfModule } from 'angular-file';
import { AirspaceModule } from 'libs/airspace/src/lib/airspace.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { InlineModalModule } from 'libs/inline-modal/src/lib/inline-modal.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { MapModule } from 'libs/map/src/lib/map.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { CloneMissionLocationDialogue } from './clone-mission-location/clone-mission-location-dialogue.component';
import { KmlUploadService } from './kml-upload.service';
import { KmlUploadDialogue } from './kml-upload/kml-upload-dialogue.component';
import { LocationDetailsDialogue } from './location-details/location-details-dialogue.component';
import { LocationDialoguesService } from './location-dialogues.service';
import { LocationAirspaceChecker } from './location-edit-v2/airspace-checker/location-airspace-check.component';
import { LocationEditV2Dialogue } from './location-edit-v2/location-edit-dialogue.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        ModalModule.forChild(),
        MapModule,
        LoadingIndicatorModule,
        SharedUiModule,
        ngfModule,
        TooltipModule,
        NgSelectModule,
        NgxLoadingModule,
        MaterialModule,
        InlineModalModule,
        AirspaceModule,
        BsDatepickerModule,
        TimepickerModule,
        CollapseModule,
        EnhancedHelpModule,
        AnalyticsModule
    ],
    providers: [LocationDialoguesService, KmlUploadService],
    bootstrap: [],
    declarations: [
        LocationDetailsDialogue,
        CloneMissionLocationDialogue,
        LocationEditV2Dialogue,
        KmlUploadDialogue,
        LocationAirspaceChecker
    ],
    exports: [LocationAirspaceChecker]
})
export class LocationsModule {}
