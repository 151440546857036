/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.16.13-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimpleFlightLogCollectionSourceDto } from './simpleFlightLogCollectionSourceDto';
import { SimplePersonDto } from './simplePersonDto';


/**
 * Basic information about a flight log file that has been uploaded into the system, including any assignment information.
 */
export interface FlightLogFileDto { 
    /**
     * The unique ID for this instance
     */
    id: number;
    collectionSource?: string;
    collectionDeviceIdentifier?: string;
    collectionDeviceName?: string;
    /**
     * The original file name of the flight log file. Null if it was from an API
     */
    fileName?: string;
    /**
     * The content type of the flight log. Null if it isn\'t known
     */
    contentType?: string;
    collectionTime?: string;
    creationTime?: string;
    latitude?: number;
    longitude?: number;
    /**
     * The ID of the organisation that owns this flight log file
     */
    organisationId: number;
    missionId?: number;
    missionName?: string;
    flightId?: number;
    pilot?: SimplePersonDto;
    rpaId?: number;
    batteryIds?: Array<number>;
    assignmentStatus: FlightLogFileDto.AssignmentStatus;
    processedStatus: FlightLogFileDto.ProcessedStatus;
    seen: boolean;
    creationBy: SimplePersonDto;
    flightLogCollectionSource?: SimpleFlightLogCollectionSourceDto;
    /**
     * Has an update to the extractor changed the values of some flight log fields
     */
    outOfDate: boolean;
}
export namespace FlightLogFileDto {
    export type AssignmentStatus = 'UNASSIGNED' | 'ARCHIVED' | 'ASSIGNED' | 'DELETED';
    export const AssignmentStatus = {
        UNASSIGNED: 'UNASSIGNED' as AssignmentStatus,
        ARCHIVED: 'ARCHIVED' as AssignmentStatus,
        ASSIGNED: 'ASSIGNED' as AssignmentStatus,
        DELETED: 'DELETED' as AssignmentStatus
    };
    export type ProcessedStatus = 'UNPROCESSED' | 'PROCESSING' | 'PROCESSED' | 'FAILED';
    export const ProcessedStatus = {
        UNPROCESSED: 'UNPROCESSED' as ProcessedStatus,
        PROCESSING: 'PROCESSING' as ProcessedStatus,
        PROCESSED: 'PROCESSED' as ProcessedStatus,
        FAILED: 'FAILED' as ProcessedStatus
    };
}



