import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import {
    AuthorityConditionDto,
    CheckExpiryDto,
    PersonnelRegisterExpirySummary,
    RegisterEntryVerificationActivityDto,
    RpaRegisterExpirySummary
} from '@flyfreely-portal-ui/flyfreely';
import { RegisterEntry } from './interfaces';
import moment from 'moment';

/**
 * Returns the correct HTML to display for any given check in a register based on its status.
 * @param entry the verification activity
 * @returns HTML as a string to be rendered in the checks component
 */
export function buildCheckContent(
    entry: RegisterEntryVerificationActivityDto,
    formatDate: (date: string) => string,
    formatTimestamp: (timestamp: string) => string
) {
    const expiryDate =
        entry.expiryDate != null ? formatDate(entry.expiryDate) : null;
    const updatedAt =
        entry.updatedAt != null ? formatTimestamp(entry.updatedAt) : null;
    switch (entry.status) {
        case 'PARTIAL':
            if (expiryDate == null) {
                return '<span class="far fa-list-check warning"></span>';
            } else {
                return `<span class="far fa-list-check warning"></span> Exp. ${expiryDate}`;
            }

        case 'PENDING':
            return '<span class="fa fa-times text-danger"></span>';

        default:
            if (entry.conditionType === 'ATTACHMENT_ACKNOWLEDGEMENT') {
                return `<span class="fa fa-check text-success"></span> ${updatedAt}`;
            }
            if (expiryDate == null) {
                return '<span class="fa fa-check text-success"></span>';
            } else {
                return `<span class="fa fa-check text-success"></span> Exp. ${expiryDate}`;
            }
    }
}

/**
 * Determines whether a register check should have a popover and returns any relevant string to trigger said popover
 * @param entry A register entry check
 * @returns A string to pass to the popover object
 */
export function getPopoverValue(entry: RegisterEntryVerificationActivityDto) {
    if (entry.conditionType === 'CURRENCY') {
        return 'currency';
    } else if (entry.conditionType === 'ACTION') {
        return 'action';
    } else if (entry.status === 'PENDING') {
        return null;
    }
    return 'organisation-authority';
}

/**
 * Returns any additional data needed by a register check's popover dialogue
 * @param entry A register entry check
 * @param authorityId The authority ID for this register
 * @returns An object with fields for any additional data required for that register check
 */
export function getCheckResultDetails(
    entry: RegisterEntryVerificationActivityDto,
    authorityId?: number
) {
    const defaultDetails = {
        acknowledgementTime: entry.updatedAt
    };
    if (entry.conditionType === 'CURRENCY') {
        return {
            ...defaultDetails,
            authorityId: authorityId
        };
    } else {
        return defaultDetails;
    }
}

export function matchConditionChecks(
    condition: AuthorityConditionDto,
    check: RegisterEntryVerificationActivityDto
) {
    return (
        condition.id === check.authorityConditionId &&
        condition.conditionType === check.conditionType &&
        condition.entityId === check.entityId &&
        condition.entity === check.entity
    );
}

export function mapCheckExpiry(
    expiries: PersonnelRegisterExpirySummary | RpaRegisterExpirySummary,
    check: RegisterEntryVerificationActivityDto,
    registerId: number
) {
    // @ts-ignore these types overlap enough for this find to work
    const registerExpiryEntry = expiries?.registerEntries?.find(
        entry =>
            entry.authorityRegisterId === registerId &&
            entry.id === check.registerEntryId
    );
    return registerExpiryEntry?.checkExpiryList?.find(expiry =>
        matchChecksToExpiry(expiry, check)
    );
}

export function matchChecksToExpiry(
    expiry: CheckExpiryDto,
    check: RegisterEntryVerificationActivityDto
) {
    return (
        expiry.registerConditionId === check.authorityConditionId &&
        expiry.expiryDate === check.expiryDate
    );
}

/**
 * A function to filter through a list of register entries and return entries related
 * to the provided subject and the start and end dates for each entry.
 * If an optional entry ID is supplied, the returned value includes all entries for that subject EXCEPT the provided entry
 * If no entry ID is passed all entries related to that subject are returned.
 * @param entries A list of RegisterEntry values for a given register
 * @param subjectId The ID of the selected entity to be referenced.
 * @param entryId (optional) a specific entry to exclude from the results
 * @returns A RegisterEntry array of the entries matching the subject ID AND
 * an array containing the start and end dates of each entry. Always returns two array values, even if passed all null parameters.
 */
function findRegisterEntitiesAndDates(
    entries: RegisterEntry[],
    subjectId?: number,
    entryId?: number
) {
    // Finding existing entries varies based off input variables
    // If an entryId and subjectId is supplied (edit existing), then find entries for that subject not equal to the ID as an array
    // if only a subjectId is supplied (new entry), then find all entries for that subject
    // If there's no subjectId, return an empty array.
    const existingEntries =
        entries == null
            ? []
            : entries.filter(e =>
                  subjectId == null
                      ? false
                      : entryId == null
                      ? e.subjectId === subjectId
                      : e.subjectId === subjectId && e.id !== entryId
              );

    // Find the date pairs for each existing entry
    const existingDates = existingEntries.map(e => ({
        startDate: e.startDate,
        endDate: e.endDate
    }));

    return {
        existingEntries,
        existingDates
    };
}

function findEarliestDate(dates: Date[]) {
    if (dates.length === 1) {
        return dates[0];
    }
    dates.sort(
        (a, b) =>
            moment(a).diff(
                moment(b)
            ) /* moment(a).isBefore(moment(b)) ? -1 : moment(a).isAfter(moment(b)) ? 1 : 0 */
    );
    return dates[0];
}

function findLatestDate(dates: Date[]) {
    if (dates.length === 1) {
        return dates[0];
    }
    dates.sort(
        (a, b) =>
            moment(a).diff(
                moment(b)
            ) /* moment(a).isBefore(moment(b)) ? -1 : moment(a).isAfter(moment(b)) ? 1 : 0 */
    );
    return dates[dates.length - 1];
}

/**
 * Parses whether a list of dates has a set start date and end date (i.e. neither type contains a null value in the list)
 * and returns the earlies start date and latest end date from the provided array
 * @param existingDates An array of start an end date pairs
 * @returns 4 variables determining whether there is a set start and end date (boolean)
 * as well as the earliest start date and latest end date (Date)
 */
function findDatesParameters(
    existingDates: { startDate: Date; endDate: Date }[]
) {
    const hasStartDate = existingDates.find(d => d.startDate == null) == null;
    const hasEndDate = existingDates.find(d => d.endDate == null) == null;
    const startDate = findEarliestDate(
        existingDates.reduce(
            (acc, d) => (d.startDate != null ? acc.concat(d.startDate) : acc),
            []
        )
    );
    const endDate = findLatestDate(
        existingDates.reduce(
            (acc, d) => (d.endDate != null ? acc.concat(d.endDate) : acc),
            []
        )
    );
    return { hasStartDate, hasEndDate, startDate, endDate };
}

/**
 * A validation function determining whether a chosen start date overlaps any existing active periods for the current entity
 * @param entryDate The selected entity's start date
 * @param existingDates A list of existing dates on the register pertaining to the entity
 * @param noun What the entity is called (eg. 'pilot' or 'RPA'). This is used in the return message to be rendered in the form
 * @param hasSubjectId Whether the selected entity already has an ID (applicable in "add" mode)
 * @returns A ValidationErrors object with a message detailing the error
 */
function evaluateStartDate(
    entryDate: Date,
    existingDates: { startDate: Date; endDate: Date }[],
    noun: string,
    hasSubjectId = true
) {
    const {
        hasStartDate,
        hasEndDate,
        startDate,
        endDate
    } = findDatesParameters(existingDates);

    if (entryDate == null && hasSubjectId) {
        // Indefinite start date always overlaps existing entries.
        return { startDateError: 'A start date is required' };
    } else if (
        existingDates.length === 0 ||
        (!hasStartDate &&
            hasEndDate &&
            moment(entryDate).isBefore(moment(endDate))) ||
        (hasStartDate &&
            !hasEndDate &&
            moment(entryDate).isAfter(moment(startDate))) ||
        (hasStartDate &&
            hasEndDate &&
            moment(entryDate).isBetween(moment(startDate), moment(endDate)))
    ) {
        // New start date overlaps current register status period
        return {
            startDateError: `This start date overlaps the current active period for this ${noun}`
        };
    }

    return null;
}

/**
 * A validation function determining whether a chosen end date overlaps any existing active periods for the current entity
 * @param entryDate The selected entity's end date
 * @param existingDates A list of existing dates on the register pertaining to the entity
 * @param noun What the entity is called (eg. 'pilot' or 'RPA'). This is used in the return message to be rendered in the form
 * @param hasSubjectId Whether the selected entity already has an ID (applicable in "add" mode)
 * @returns A ValidationErrors object with a message detailing the error
 */
function evaluateEndDate(
    entryDate: Date,
    existingDates: { startDate: Date; endDate: Date }[],
    noun: string,
    hasSubjectId = true
) {
    const {
        hasStartDate,
        hasEndDate,
        startDate,
        endDate
    } = findDatesParameters(existingDates);

    if (entryDate == null && hasSubjectId && !hasEndDate) {
        // Indefinite end date always overlaps existing entries with indefinite end dates.
        return {
            endDateError: `This end date overlaps the current active period for this ${noun}`
        };
    } else if (
        (hasStartDate &&
            !hasEndDate &&
            moment(entryDate).isAfter(moment(startDate))) ||
        (!hasStartDate &&
            hasEndDate &&
            moment(entryDate).isBefore(moment(endDate))) ||
        (hasStartDate &&
            hasEndDate &&
            moment(entryDate).isBetween(moment(startDate), moment(endDate)))
    ) {
        // New end date overlaps current register status period
        return {
            endDateError: `This end date overlaps the current active period for this ${noun}`
        };
    }

    return null;
}

/**
 * Form validator function to determine overlap between a new register entry's start date
 * and any existing entries in the register for that entity
 * @param entries A list of all the entries in the register
 * @param formGroup The formGroup which contains the "add" form
 * @param noun What the entity is called (eg. 'pilot' or 'RPA'). This is used in the return message to be rendered in the form
 * @returns A ValidationErrors object with a message detailing the error
 */
export function validateNewEntityStartDate(
    entries: RegisterEntry[],
    formGroup: FormGroup,
    noun: string = 'entity'
): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
        const entry = formGroup?.get('add').value;
        const entryDate = control?.value;
        const { existingEntries, existingDates } = findRegisterEntitiesAndDates(
            entries,
            entry?.subjectId
        );

        if (existingEntries.length === 0 || entry == null) {
            // No error if the new entity is not already on the register
            return null;
        }

        return evaluateStartDate(
            entryDate,
            existingDates,
            noun,
            entry?.subjectId != null
        );
    };
}

/**
 * Form validator function to determine overlap between a new register entry's end date
 * and any existing entries in the register for that entity
 * @param entries A list of all the entries in the register
 * @param formGroup The formGroup which contains the "add" form
 * @param noun What the entity is called (eg. 'pilot' or 'RPA'). This is used in the return message to be rendered in the form
 * @returns A ValidationErrors object with a message detailing the error
 */
export function validateNewEntityEndDate(
    entries: RegisterEntry[],
    formGroup: FormGroup,
    noun: string = 'entity'
): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
        const entry = formGroup?.get('add').value;
        const entryDate = control?.value;
        const { existingEntries, existingDates } = findRegisterEntitiesAndDates(
            entries,
            entry?.subjectId
        );

        if (
            existingEntries.length === 0 ||
            entry == null ||
            control.value == null
        ) {
            // No error if the new entity is not already on the register
            return null;
        }

        return evaluateEndDate(
            entryDate,
            existingDates,
            noun,
            entry?.subjectId != null
        );
    };
}

/**
 * Form validator function to determine overlap between an existing register entry's start date
 * and any existing entries in the register for that entity
 * @param entries A list of all the entries in the register
 * @param formGroup The formGroup which contains the "add" form
 * @param noun What the entity is called (eg. 'pilot' or 'RPA'). This is used in the return message to be rendered in the form
 * @returns A ValidationErrors object with a message detailing the error
 */
export function validateExistingEntityStartDate(
    entryId: number,
    entries: RegisterEntry[],
    noun: string = 'entity'
): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
        const entryDate = control.value;
        const entry = entries.find(e => e.id === entryId);
        const { existingEntries, existingDates } = findRegisterEntitiesAndDates(
            entries,
            entry.subjectId,
            entryId
        );

        if (entry == null || existingEntries.length === 0) {
            // No error if the user is only on the register once or null
            return null;
        }

        return evaluateStartDate(entryDate, existingDates, noun);
    };
}

/**
 * Form validator function to determine overlap between an existing register entry's end date
 * and any existing entries in the register for that entity
 * @param entries A list of all the entries in the register
 * @param formGroup The formGroup which contains the "add" form
 * @param noun What the entity is called (eg. 'pilot' or 'RPA'). This is used in the return message to be rendered in the form
 * @returns A ValidationErrors object with a message detailing the error
 */
export function validateExistingEntityEndDate(
    entryId: number,
    entries: RegisterEntry[],
    noun: string = 'entity'
): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
        const entryDate = control.value;
        const entry = entries.find(e => e.id === entryId);
        const { existingEntries, existingDates } = findRegisterEntitiesAndDates(
            entries,
            entry.subjectId,
            entryId
        );

        if (entry == null || existingEntries.length === 0) {
            // No error if the user is only on the register once or null
            return null;
        }

        return evaluateEndDate(entryDate, existingDates, noun);
    };
}
