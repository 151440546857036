import { Pipe, PipeTransform } from '@angular/core';
import { RpaTypeDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'filterRpaModels' })
export class FilterRpaModelsPipe implements PipeTransform {
    transform(rpaTypes: RpaTypeDto[], rpaCategory: string) {
        if (!rpaCategory || !rpaTypes) {
            return [];
        }

        const filteredModels: RpaTypeDto[] = rpaTypes.filter(
            m => m.rpaCategory === rpaCategory
        );

        return filteredModels;
    }
}
