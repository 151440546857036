import { Component, Input, OnInit } from '@angular/core';
import { BatteryDialoguesService } from 'libs/batteries/src/lib/battery-dialogues.service';
import { EquipmentDialoguesService } from 'libs/equipment/src/lib/equipment-dialogues.service';
import {
    BatteryDto,
    BatterySetDto,
    findResourceName,
    MaintenanceLogDto,
    MaintenanceService,
    PersonsOrganisationDto,
    WorkTracker,
    Resource,
    UpdateSubmittedMaintenanceLog,
    FlyFreelyError,
    FlyFreelyLoggingService
} from '@flyfreely-portal-ui/flyfreely';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import { combineLatest, firstValueFrom, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
    DisplayableMaintenanceLogDto,
    MaintenanceRequestService
} from '../maintenance-request.service';
import { FormGroup } from '@angular/forms';
import { EditableComponent } from 'libs/editable/src/lib/editable.component';

@Component({
    selector: 'maintenance-request-details',
    templateUrl: './maintenance-request-details.component.html'
})
export class MaintenanceRequestDetails implements OnInit {
    @Input() maintenanceLogId: number;
    @Input() organisation: PersonsOrganisationDto;
    @Input() form: FormGroup;
    @Input() overallStatus: string;
    @Input() hasMaintenanceNotices = false;

    maintenanceLog: DisplayableMaintenanceLogDto;
    combinedStatus: string;
    resource: Resource;
    resourceName: string;

    working: boolean = false;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private maintenanceService: MaintenanceService,
        private rpaDialoguesService: RpaDialoguesService,
        private batteryDialoguesService: BatteryDialoguesService,
        private equipmentDialoguesService: EquipmentDialoguesService,
        private maintenanceRequestService: MaintenanceRequestService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        combineLatest([
            this.maintenanceRequestService.resource$,
            this.maintenanceRequestService.maintenanceLog$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([resource, maintenanceLog]) => {
                this.resource = resource;
                this.maintenanceLog = maintenanceLog.log;
                if (this.maintenanceLog != null) {
                    this.findCombinedStatus();
                }
                if (this.resource != null && this.maintenanceLog != null) {
                    this.resourceName = findResourceName(
                        this.resource,
                        this.maintenanceLog.resourceCategory
                    );
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setEditOnlyTrue = (editorRef: EditableComponent) => async () => {
        const identifierFc = this.form.controls.identifier;
        const workInstructionsFc = this.form.controls.workInstructions;
        if (
            identifierFc.valid &&
            identifierFc.value !== '' &&
            workInstructionsFc.valid &&
            workInstructionsFc.value !== ''
        ) {
            editorRef.editOnly = false;
        }
    };

    revertIdentifier() {
        this.form.controls.identifier.reset(this.maintenanceLog.identifier);
    }

    revertWorkInstructions() {
        this.form.controls.workInstructions.reset(this.maintenanceLog.workInstructionUrl);
    }

    findCombinedStatus() {
        const latestActivity =
            this.maintenanceLog.activities.length > 0
                ? this.maintenanceLog.activities[
                      this.maintenanceLog.activities.length - 1
                  ]
                : null;
        this.combinedStatus = this.maintenanceService.getCombinedStatus(
            this.maintenanceLog.status,
            latestActivity.status
        );
    }

    showEquipment() {
        if (
            this.maintenanceLog?.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.CRAFT
        ) {
            this.rpaDialoguesService.showCraftDetails(
                this.resource.id,
                this.organisation.id,
                false
            );
        } else if (
            this.maintenanceLog?.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.BATTERY
        ) {
            this.batteryDialoguesService.showBatteryDetailsDialogue(
                <BatteryDto>this.resource,
                this.organisation
            );
        } else if (
            this.maintenanceLog?.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.BATTERY_SET
        ) {
            this.batteryDialoguesService.showBatterySetDetailsDialogue(
                <BatterySetDto>this.resource,
                this.organisation
            );
        } else if (
            this.maintenanceLog?.resourceCategory ===
            MaintenanceLogDto.ResourceCategory.EQUIPMENT
        ) {
            this.equipmentDialoguesService.showEquipmentDetails(
                this.resource.id,
                this.organisation,
                true
            );
        }
    }

    updateIdentifierAndWorkInstructions = () => {
        if (this.form.invalid) {
            return Promise.reject();
        }
        const maintenanceLogId = this.maintenanceLog.id;
        const command: UpdateSubmittedMaintenanceLog = {
            identifier:
                this.form.value.identifier === ''
                    ? null
                    : this.form.value.identifier,
            workInstructionsUrl:
                this.form.value.workInstructions === ''
                    ? null
                    : this.form.value.workInstructions,
            maintenanceLogId
        };
        return firstValueFrom(
            this.maintenanceRequestService
                .updateMaintenanceIdentifierAndWorkInstructionsUrl(
                    command
                )
                .pipe(
                    map(
                        result => {
                            this.logging.success(
                                `Maintenance identifier or work instructions updated`
                            );
                        },
                        (error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while updating maintenance identifier or work instructions ${error.message}`
                            );
                        }
                    )
                )
        );
    };
}
