import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {
    BatterySetDto,
    CompleteSortieCommand,
    CraftDto,
    DisplayableMissionDto,
    DO_NOTHING,
    EquipmentDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionRoleDto,
    MissionService,
    NameValue,
    PersonRolesDto,
    RpaTypeDto,
    SortieDto
} from '@flyfreely-portal-ui/flyfreely';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Point } from 'geojson';
import {
    batterySetSearch,
    equipmentSearch,
    rpaSearch
} from 'libs/resource-ui/src/lib/searchFunctions';
import moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { MissionDialoguesService } from '../../mission-dialogues.service';
import { MissionRecordService } from '../../mission-record-edit/mission-record.service';
import { completionFields } from '../fields';
import {
    DisplayableResource,
    MissionCompletionService
} from '../mission-completion.service';
import {
    FlightLogFileWithRpa,
    MissionFlightLogsDirective
} from '../mission-flight-logs.directive';
import { v4 as uuidv4 } from 'uuid';

interface GroupedResource extends DisplayableResource {
    missionStatus: 'From mission plan' | 'Others';
}
interface CraftWithStatus extends CraftDto {
    missionStatus: 'From mission plan' | 'Others';
}

interface BatterySetWithGrouping extends BatterySetDto {
    grouping?:
        | 'Associated with selected RPA'
        | 'Compatible with selected RPA'
        | 'Other';
}

interface EquipmentWithGrouping extends EquipmentDto {
    grouping?: 'Associated with selected RPA' | 'Other';
}
/**
 * The order in which we want association matches in select lists. Used in sorting functions.
 */
const associationSortPriority = [
    'Associated with selected RPA',
    'Compatible with selected RPA',
    'Other'
];

function coordinatesFormatValidator(control: FormControl) {
    const coordinatesPattern = /^-?\d+(\.\d+)?, -?\d+(\.\d+)?$/; // regex pattern for longitude, latitude

    if (!coordinatesPattern.test(control.value)) {
        return {
            invalidCoordinates:
                'Must be longitude, latitude, eg. "153.05110163, -27.49073772"'
        };
    }

    return null;
}

function findBatterySetRpaCompatibility(
    rpa: RpaTypeDto,
    batterySet: BatterySetWithGrouping
) {
    // create an object similar to the rpa.compatibleBatteryTypeRequirements for the battery set
    const setComposition = batterySet.batteries.reduce(
        (acc, battery) =>
            acc[battery.batteryTypeId] != null
                ? acc
                : {
                      ...acc,
                      [battery.batteryTypeId]: batterySet.batteries.filter(
                          b => b.batteryTypeId === battery.batteryTypeId
                      ).length
                  },
        {}
    );
    return rpa.compatibleBatteryTypeIdList.reduce((acc, batteryTypeId) => {
        return (
            acc &&
            rpa.compatibleBatteryTypeRequirements[batteryTypeId] ===
                setComposition[batteryTypeId]
        );
    }, true);
}

@Component({
    selector: 'flight-edit',
    templateUrl: './flight-edit.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FlightEdit),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FlightEdit),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightEdit implements ControlValueAccessor, Validator, OnDestroy {
    @Input() canUseEquipment: boolean;
    @Input() readonly: boolean;
    @Input() hasFlightLogging: boolean;
    @Input() hasAdditionalFlightFields: boolean;
    @Input() missionDate: FormControl;
    @Input() mission: DisplayableMissionDto;
    @Input() historicalMission: boolean = false;
    @Input() hasFlightDocumentation: boolean = false;

    @Output() remove = new EventEmitter<void>();
    @Output() showSortieDocumentation = new EventEmitter<void>();

    isNarrowResolution = false;

    allowAutomatic: boolean;
    isHistoricalMission = false;

    validationFields: FormlyFieldConfig[] = completionFields;

    unlistedPilot: string;
    unlistedRpa: string;
    unlistedBatterySet: string;
    unlistedEquipment: string;

    missionRoles: MissionRoleDto[];

    allRpaTypes: RpaTypeDto[];

    uniqueLogId: string;

    availableRpas: CraftWithStatus[];
    availablePersonnel: GroupedResource[];
    personnel: PersonRolesDto[];
    availableBatterySets: BatterySetWithGrouping[];
    availableEquipment: EquipmentWithGrouping[];
    DocButtonText: string = '';

    batterySetSearch = batterySetSearch;
    equipmentSearch = equipmentSearch;
    rpaSearch = rpaSearch;

    flight: SortieDto;
    flightLogsForFlight: FlightLogFileWithRpa[];

    duration: number;

    formGroup: FormGroup;
    takeOffLandingForm: FormGroup;
    onChange: (arg: any) => void = DO_NOTHING;
    onTouched: (arg: any) => void = DO_NOTHING;
    onValidate: (arg: any) => void = DO_NOTHING;

    private ngUnsubscribe$ = new Subject<void>();
    sortieStatuses: NameValue[];

    constructor(
        private missionCompletionService: MissionCompletionService,
        private missionRecordService: MissionRecordService,
        private missionDialoguesService: MissionDialoguesService,
        private flightLogs: MissionFlightLogsDirective,
        private changeDetector: ChangeDetectorRef,
        private logging: FlyFreelyLoggingService
    ) {
        this.uniqueLogId = uuidv4(); 
        this.formGroup = new FormGroup({
            id: new FormControl(undefined),
            number: new FormControl(undefined),
            durationSource: new FormControl(undefined),
            manualDuration: new FormControl(undefined),
            notes: new FormControl(undefined),
            status: new FormControl(undefined, [Validators.required]),
            reason: new FormControl(undefined),
            pilotId: new FormControl(undefined),
            craftId: new FormControl(undefined),
            batterySetId: new FormControl(undefined),
            startTime: new FormControl(undefined),
            endTime: new FormControl(undefined),
            equipmentIds: new FormControl(undefined),
            manualStartTime: new FormControl(undefined),
            crew: new FormArray([]),
            manualTakeoffPoint: new FormControl(undefined),
            manualLandingPoint: new FormControl(undefined)
        });
        this.takeOffLandingForm = new FormGroup({
            takeOffCoordinates: new FormControl(
                undefined,
                coordinatesFormatValidator
            ),
            landingCoordinates: new FormControl(
                undefined,
                coordinatesFormatValidator
            )
        });
    }

    ngOnInit() {
        this.setDocButtonText();
        this.sortieStatuses = MissionService.getSortieCompletionStatuses();

        this.formGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                this.onChange({
                    ...value
                });
            });

        this.formGroup.controls.status.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(status => {
                this.updateValidators(
                    status,
                    this.formGroup.controls.durationSource.value
                );
            });

        this.formGroup.controls.durationSource.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(durationSource => {
                this.updateValidators(
                    this.formGroup.controls.status.value,
                    durationSource
                );
            });

        this.formGroup.controls.manualStartTime.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                this.formGroup.controls.manualStartTime.setValue(value, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });
            });

        this.takeOffLandingForm.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                const toPoint = (value: string) => {
                    if (value == null) {
                        return null as Point;
                    }
                    const coords = value
                        .split(',')
                        .map(c => parseFloat(c.trim()));
                    return <Point>{
                        coordinates: coords,
                        type: 'Point'
                    };
                };
                this.formGroup.controls.manualTakeoffPoint.patchValue(
                    toPoint(value.takeOffCoordinates)
                );
                this.formGroup.controls.manualLandingPoint.patchValue(
                    toPoint(value.landingCoordinates)
                );
                this.formGroup.updateValueAndValidity();
                this.changeDetector.detectChanges();
            });

        this.isHistoricalMission =
            this.mission.type === DisplayableMissionDto.Type.RETROSPECTIVE;

        this.refreshMissionResources();

        this.setupFlightLogs();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setDocButtonText(); // Update button text on window resize
    }

    setDocButtonText() {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        switch (true) {
            case windowHeight < 720:
                this.DocButtonText = 'Documentation';
                break;
            case windowWidth < 1120:
                this.DocButtonText = 'Documentation & Attachments';
                break;
            default:
                this.DocButtonText = 'Documentation and Attachments';
                break;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Synchronise all the data from the mission completion service.
     * All the observables are combined in a combineLatest to ensure
     * everything gets synced up before firing dependent functions.
     * We can use rxjs tap within the individual observables to grab their values as they emit
     */
    refreshMissionResources() {
        const missionService = this.isHistoricalMission
            ? this.missionRecordService
            : this.missionCompletionService;

        // create separate Observables for the resources we need to combine
        const rpaValues = combineLatest([
            missionService.availableRpas$,
            missionService.allRpas$
        ]).pipe(
            tap(([availableRpas, rpas]) => {
                const rpasWithStatus: CraftWithStatus[] = rpas.map(c => ({
                    ...c,
                    missionStatus: 'Others'
                }));
                const missionRpas: CraftWithStatus[] = availableRpas.map(c => ({
                    ...c,
                    missionStatus: 'From mission plan'
                }));
                this.availableRpas = missionRpas.concat(rpasWithStatus);
            })
        );

        const personnelValues = combineLatest([
            missionService.availablePersonnel$,
            missionService.allPersonnel$
        ]).pipe(
            tap(([availablePersonnel, personnel]) => {
                this.personnel = personnel;
                const personnelWithStatus: GroupedResource[] = personnel.map(
                    p => ({
                        id: p.id,
                        name: `${p.firstName} ${p.lastName}`,
                        missionStatus: 'Others'
                    })
                );
                const missionPersonnel: GroupedResource[] =
                    availablePersonnel.map(p => ({
                        ...p,
                        missionStatus: 'From mission plan'
                    }));
                this.availablePersonnel =
                    missionPersonnel.concat(personnelWithStatus);
            })
        );

        // Combine all tapped observables into another combineLatest so we can synchronise output to dependent functions
        combineLatest([
            rpaValues,
            personnelValues,
            missionService.availableBatterySets$.pipe(
                tap(batterySets => (this.availableBatterySets = batterySets))
            ),
            missionService.availableEquipment$.pipe(
                tap(equipment => (this.availableEquipment = equipment))
            ),
            missionService.missionRoles$.pipe(
                tap(roles => (this.missionRoles = roles))
            ),
            missionService.allRpaTypes$.pipe(
                tap(types => (this.allRpaTypes = types))
            ),
            this.formGroup.controls.craftId.valueChanges.pipe(
                startWith(null as number)
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(values => {
                this.changeDetector.markForCheck();
                // Only update the battery grouping once everything completes and has values.
                this.updateBatteryGrouping();
                this.updateEquipmentGrouping();
            });
    }

    /**
     * Manage the validators for the controls based on the new state.
     */
    private updateValidators(
        status: CompleteSortieCommand.Status,
        durationSource: CompleteSortieCommand.DurationSource
    ) {
        if (status === 'ABORTED') {
            this.formGroup.controls.pilotId.clearValidators();
            this.formGroup.controls.craftId.clearValidators();
            this.formGroup.controls.manualDuration.clearValidators();
        } else {
            this.formGroup.controls.pilotId.setValidators(Validators.required);
            this.formGroup.controls.craftId.setValidators(Validators.required);

            if (durationSource === 'MANUAL') {
                this.formGroup.controls.manualDuration.setValidators(
                    Validators.compose([Validators.required, Validators.min(1)])
                );
                this.formGroup.controls.manualStartTime.setValidators(
                    Validators.required
                );
            } else {
                this.formGroup.controls.manualDuration.clearValidators();
                this.formGroup.controls.manualStartTime.clearValidators();
            }
        }
        this.formGroup.controls.pilotId.updateValueAndValidity({
            emitEvent: false
        });
        this.formGroup.controls.craftId.updateValueAndValidity({
            emitEvent: false
        });
        this.formGroup.controls.manualDuration.updateValueAndValidity({
            emitEvent: false
        });
        this.formGroup.updateValueAndValidity();
        this.changeDetector.detectChanges();
    }

    registerOnChange(fn: (arg: any) => void) {
        this.onChange = fn;
    }

    writeValue(flight: SortieDto) {
        if (flight) {
            this.flight = flight;
            this.allowAutomatic =
                flight.startTime != null && flight.endTime != null;

            const durationSource =
                flight.durationSource != null
                    ? flight.durationSource
                    : SortieDto.DurationSource.MANUAL;

            this.duration = flight.duration ?? 0;

            this.formGroup.patchValue({
                id: flight.id,
                number: flight.number,
                durationSource: durationSource,
                manualDuration: flight.manualDuration,
                notes: flight.notes,
                status: flight.status,
                reason: flight.reason ?? '',
                pilotId: flight.pilotId,
                craftId: flight.craftId,
                batterySetId: flight.batterySetId,
                equipmentIds: flight.equipmentIds,
                startTime: flight.startTime ?? null,
                endTime: flight.endTime ?? null,
                manualStartTime:
                    flight.manualStartTime ?? this.missionDate.value ?? null
            });

            // Only need to set the coordinates here and the other formGroup will auto-populate
            this.takeOffLandingForm.patchValue({
                takeOffCoordinates: (flight.durationSource ===
                SortieDto.DurationSource.LOGS
                    ? flight.takeoffPoint?.coordinates
                    : flight.manualTakeoffPoint?.coordinates
                )
                    ?.map(c => c.toString())
                    .join(', '),
                landingCoordinates: (flight.durationSource ===
                SortieDto.DurationSource.LOGS
                    ? flight.landingPoint?.coordinates
                    : flight.manualLandingPoint?.coordinates
                )
                    ?.map(c => c.toString())
                    .join(', ')
            });

            if (flight.crew != null && flight.crew.length > 0) {
                flight.crew.forEach(member => {
                    const item = new FormControl({
                        missionRoleId: member.missionRoleId,
                        notes: member.notes,
                        personId: member.personId
                    });
                    this.flightCrew.push(item);
                });
                this.changeDetector.markForCheck();
            }

            if (
                flight.unlistedPilot != null &&
                this.formGroup.controls.pilotId.value == null
            ) {
                this.unlistedPilot = `${flight.unlistedPilot.firstName} ${
                    flight.unlistedPilot.lastName ?? ''
                }`;
            }

            if (flight.unlistedRpa != null) {
                this.unlistedRpa = flight.unlistedRpa.name;
            }

            if (flight.unlistedBatterySet != null) {
                this.unlistedBatterySet = flight.unlistedBatterySet.name;
            }

            if (
                flight.unlistedEquipment != null &&
                flight.unlistedEquipment.length > 0
            ) {
                this.unlistedEquipment = flight.unlistedEquipment
                    .map(e => e.name)
                    .join(', ');
            }
        }

        if (
            flight.unlistedPilot != null &&
            this.formGroup.controls.pilotId.value != null
        ) {
            this.unlistedPilot = `${flight.unlistedPilot.firstName} ${
                flight.unlistedPilot.lastName ?? ''
            }`;
        }

        if (flight.unlistedRpa != null) {
            this.unlistedRpa = flight.unlistedRpa.name;
        }

        if (flight.unlistedBatterySet != null) {
            this.unlistedBatterySet = flight.unlistedBatterySet.name;
        }

        if (
            flight.unlistedEquipment != null &&
            flight.unlistedEquipment.length > 0
        ) {
            this.unlistedEquipment = flight.unlistedEquipment
                .map(e => e.name)
                .join(', ');
        }

        if (flight === null) {
            this.formGroup.reset();
        }
        this.changeDetector.detectChanges();
        this.onValidate(this.formGroup.valid);
    }

    addCrew() {
        const item = new FormControl({
            missionRoleId: null,
            notes: null,
            personId: null
        });
        this.flightCrew.push(item);
        this.changeDetector.detectChanges();
    }

    // TODO: limit available roles and people to keep crew valid. (eg. only one RPIC allowed)
    // TODO: allow saving flight crew
    canAddCrew() {
        if (this.flightCrew.controls.length === 0) {
            return true;
        }
        const last =
            this.flightCrew.controls[this.flightCrew.controls.length - 1].value;
        return last.missionRoleId != null && last.personId != null;
    }

    /**
     * A function that updates a list of associated batteries for the currently selected RPA type.
     * This is used to float relevant battery sets to the top in the battery set select
     */
    updateBatteryGrouping() {
        if (this.availableBatterySets == null) {
            return;
        }
        const selectedRpa = this.availableRpas?.find(
            r => r.id === this.formGroup.controls.craftId.value
        );
        const selectedRpaType = this.allRpaTypes.find(
            type => selectedRpa?.rpaTypeId === type.id
        );
        const updatedBatteryGroupings: BatterySetWithGrouping[] =
            this.availableBatterySets
                .map(
                    set =>
                        <BatterySetWithGrouping>{
                            ...set,
                            grouping:
                                ((selectedRpaType == null ||
                                    selectedRpaType.compatibleBatteryTypeIdList
                                        .length === 0) &&
                                    (selectedRpa == null ||
                                        selectedRpa.associatedBatterySetIdList ==
                                            null ||
                                        selectedRpa.associatedBatterySetIdList
                                            .length === 0)) ||
                                set.batteries == null ||
                                set.batteries.length === 0
                                    ? null
                                    : selectedRpa.associatedBatterySetIdList.includes(
                                          set.id
                                      )
                                    ? 'Associated with selected RPA'
                                    : findBatterySetRpaCompatibility(
                                          selectedRpaType,
                                          set
                                      )
                                    ? 'Compatible with selected RPA'
                                    : 'Other'
                        }
                )
                .sort((a, b) => {
                    // Float 'Associated with selected RPA' to top, then 'Compatible with selected RPA' then Other, then ungrouped and sort alphabetically
                    const aIndex =
                        a.grouping == null
                            ? associationSortPriority.length + 1
                            : associationSortPriority.indexOf(a.grouping);
                    const bIndex =
                        b.grouping == null
                            ? associationSortPriority.length + 1
                            : associationSortPriority.indexOf(b.grouping);
                    if (
                        aIndex < bIndex ||
                        (aIndex === bIndex && b.name > a.name)
                    ) {
                        return -1;
                    } else if (aIndex === bIndex && b.name == a.name) {
                        return 0;
                    }
                    return +1;
                });
        this.availableBatterySets = updatedBatteryGroupings;
    }

    /**
     * A function that updates a list of associated batteries for the currently selected RPA type.
     * This is used to float relevant battery sets to the top in the battery set select
     */
    updateEquipmentGrouping() {
        if (this.availableEquipment == null) {
            return;
        }
        const selectedRpa = this.availableRpas?.find(
            r => r.id === this.formGroup.controls.craftId.value
        );
        const updatedEquipmentGroupings: EquipmentWithGrouping[] =
            this.availableEquipment
                .map(
                    equipment =>
                        <EquipmentWithGrouping>{
                            ...equipment,
                            grouping:
                                selectedRpa == null ||
                                selectedRpa.associatedEquipmentIdList == null ||
                                selectedRpa.associatedEquipmentIdList.length ===
                                    0
                                    ? null
                                    : selectedRpa.associatedEquipmentIdList.includes(
                                          equipment.id
                                      )
                                    ? 'Associated with selected RPA'
                                    : 'Other'
                        }
                )
                .sort((a, b) => {
                    // Float 'Associated with selected RPA' to top, then 'Compatible with selected RPA' then Other, then ungrouped and sort alphabetically
                    const aIndex =
                        a.grouping == null
                            ? associationSortPriority.length + 1
                            : associationSortPriority.indexOf(a.grouping);
                    const bIndex =
                        b.grouping == null
                            ? associationSortPriority.length + 1
                            : associationSortPriority.indexOf(b.grouping);
                    if (
                        aIndex < bIndex ||
                        (aIndex === bIndex && b.name > a.name)
                    ) {
                        return -1;
                    } else if (aIndex === bIndex && b.name == a.name) {
                        return 0;
                    }
                    return +1;
                });
        this.availableEquipment = updatedEquipmentGroupings;
    }

    setupFlightLogs() {
        this.flightLogs.flightLogs$
            .pipe(
                startWith(null as FlightLogFileWithRpa[]),
                map(logs =>
                    logs != null && this.flight?.id != null
                        ? logs.filter(log => log.flightId === this.flight.id)
                        : []
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(
                logs => {
                    this.flightLogsForFlight = logs;
                    const startTime =
                        logs == null || logs.length === 0
                            ? this.startTime.value
                            : logs.reduce(
                                  (acc, l) =>
                                      moment(l.startTime).isBefore(moment(acc))
                                          ? l.startTime
                                          : acc,
                                  logs[0].startTime
                              );
                    this.formGroup.controls.startTime.patchValue(startTime);
                    this.changeDetector.detectChanges();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while finding flight log file for this flight`
                    );
                }
            );
    }

    showTakeoffLandingEdit() {
        const missionLocation = this.mission?.location;
        const organisationId = this.mission?.organisationId;
        const flight = this.flight;
        const takeoff = this.formGroup.value.manualTakeoffPoint?.coordinates;
        const landing = this.formGroup.value.manualLandingPoint?.coordinates;
        const modal =
            this.missionDialoguesService.showFlightTakeoffLandingEditDialogue(
                missionLocation,
                organisationId,
                flight,
                takeoff,
                landing,
                this.flightLogsForFlight.map(log => log.id)
            );

        modal.content.updatedTakeoffPoint
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(takeoff => {
                const floatArray = takeoff.map(t => t.toString()).join(', ');
                this.takeOffLandingForm.controls.takeOffCoordinates.patchValue(
                    floatArray
                );
            });

        modal.content.updatedLandingPoint
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(landing => {
                const floatArray = landing.map(l => l.toString()).join(', ');
                this.takeOffLandingForm.controls.landingCoordinates.patchValue(
                    floatArray
                );
            });
    }

    deleteCrew(i: number) {
        this.flightCrew.removeAt(i);
    }

    registerOnTouched(fn: (arg: any) => void) {
        this.onTouched = fn;
    }

    // communicate the inner form validation to the parent form
    validate(_: FormControl) {
        return this.formGroup.valid
            ? null
            : ({ flight: { valid: false } } as ValidationErrors);
    }

    registerOnValidatorChange(fn: () => void) {
        this.onValidate = fn;
    }

    get flightCrew() {
        return this.formGroup.get('crew') as FormArray;
    }

    get startTime() {
        return this.formGroup.get('startTime');
    }

    get endTime() {
        return this.formGroup.get('endTime');
    }
}
// function uuidv4(): string {
//     throw new Error('Function not implemented.');
// }

