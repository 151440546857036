<ng-container screenAnalytics="task-view">
    <div class="modal-header no-icon">
        <h3 class="modal-title">{{ isEdit ? 'Edit' : 'View' }} Task</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <button
            type="button"
            aria-label="Close"
            class="close"
            (click)="onCancel()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <general-task-details
        *ngIf="!isEdit"
        [generalTask]="task"
        [organisation]="organisation"
        (edit)="toggleViewEdit()"
        (deleted)="onTaskDeleted()"
    >
    </general-task-details>
    <general-task-edit
        #batteryEdit
        *ngIf="isEdit"
        [generalTask]="task"
        [organisation]="organisation"
        (cancel)="toggleViewEdit()"
        (update)="taskUpdated($event)"
    >
    </general-task-edit>
</ng-container>
