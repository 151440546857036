import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';
import { HttpClient } from '@angular/common/http';
import {
    MaintenanceTaskTypeDto,
    ComponentTypeDto,
    ComponentGroupDto,
    ActiveResourceSchematicDto
} from '@flyfreely-portal-ui/flyfreely';
import { httpParamSerializer } from '../service.helpers';

@Injectable({
    providedIn: 'root'
})
export class ResourceTypeComponentsService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findMaintenanceTaskTypes(resourceCategory: string) {
        return this.http.get<MaintenanceTaskTypeDto[]>(
            `${this.baseUrl}/webapi/resources/maintenanceTaskTypes?resourceCategory=${resourceCategory}`
        );
    }

    findComponentTypes(resourceCategory: string) {
        return this.http.get<ComponentTypeDto[]>(
            `${this.baseUrl}/webapi/resources/componentTypes?resourceCategory=${resourceCategory}`
        );
    }

    findComponentGroups(resourceCategory: string) {
        return this.http.get<ComponentGroupDto[]>(
            `${this.baseUrl}/webapi/resources/componentGroups?resourceCategory=${resourceCategory}`
        );
    }

    findResourceSchematicComponents(
        resourceCategory: string,
        resourceTypeId: number
    ) {
        return this.http.get<ActiveResourceSchematicDto>(
            `${this.baseUrl}/webapi/resources/resourceSchematics`,
            {
                params: httpParamSerializer({
                    resourceCategory,
                    resourceTypeId
                })
            }
        );
    }
}
