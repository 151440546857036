<ng-container screenAnalytics="rpa-view">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-rpa.png" />
        </div>
        <h3 class="modal-title">
            {{ isEditing !== 'NONE' ? 'Edit' : 'View' }} RPA
        </h3>
        <p class="modal-title padding">{{ rpa?.nickname }}</p>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="cancel()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body inner-container no-footer">
        <tabset
            *ngIf="rpa != null"
            vertical="true"
            type="pills"
            class="tab-set-vertical"
        >
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-list-ul tab-title-icon"></i>
                    <span>Details</span>
                </ng-template>

                <div>
                    <rpa-view
                        *ngIf="isEditing !== 'DETAILS'"
                        class="modal-body-content"
                        [organisation]="organisation"
                        [managingOrganisationId]="managingOrganisationId"
                        [rpa]="rpa"
                        [allowEdit]="true"
                        (edit)="editRpa()"
                        (deleted)="onRpaDeleted()"
                    >
                    </rpa-view>
                    <rpa-edit
                        #rpaEdit
                        *ngIf="isEditing === 'DETAILS'"
                        class="modal-body-content"
                        [rpa]="rpa"
                        [managingOrganisationId]="managingOrganisationId"
                        (cancelled)="onEditCancelled()"
                    >
                    </rpa-edit>
                </div>
            </tab>
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-memo-pad tab-title-icon"></i>
                    <span>Notes</span>
                </ng-template>
                <notes-view *ngIf="isEditing !== 'NOTES'"
                            class="modal-body-content"
                            [rpa]="rpa"
                            (edit)="editNotes()"
                ></notes-view>
                <notes-edit
                    *ngIf="isEditing === 'NOTES'"
                    class="modal-body-content"
                    [rpa]="rpa"
                    (cancelled)="onEditCancelled()"
                >
                </notes-edit>
            </tab>
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-plane tab-title-icon"></i>
                    <span>Flight History</span>
                </ng-template>
                <div class="modal-body-content container-scroll">
                    <flight-history-summary
                        [aircraft]="rpa"
                        [managingOrganisationId]="managingOrganisationId"
                    ></flight-history-summary>

                    <logged-flights
                        [aircraft]="rpa"
                        [managingOrganisationId]="managingOrganisationId"
                    ></logged-flights>
                </div>
            </tab>
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-wrench tab-title-icon"></i>
                    <span>Maintenance History</span>
                </ng-template>
                <div class="modal-body-content container-scroll">
                    <plan-highlight
                        *ngIf="!adminView"
                        [featureFlags]="featureFlags['hasScheduledMaintenance']"
                    ></plan-highlight>
                    <maintenance-schedule-config
                        *ngIf="hasScheduledMaintenance"
                        [resourceId]="rpa.id"
                        [resourceType]="'CRAFT'"
                        [subCategory]="rpaType?.craftType"
                        [managingOrganisationId]="managingOrganisationId"
                        [maintenanceScheduleAssignment]="
                            rpa.assignedMaintenanceSchedule
                        "
                        [canAssign]="rpa.availableActions.canEdit"
                        [organisationId]="rpa.organisationId"
                    ></maintenance-schedule-config>
                    <component-service-time
                        [resourceId]="rpa.id"
                        [resourceType]="'CRAFT'"
                        [managingOrganisationId]="managingOrganisationId"
                        [hasScheduledMaintenance]="hasScheduledMaintenance"
                    ></component-service-time>
                    <h4 class="section-heading">
                        <span>Maintenance History</span>
                        <div></div>
                    </h4>
                    <maintenance-history
                        [organisationId]="managingOrganisationId"
                        [resourceType]="'CRAFT'"
                        [resourceId]="rpa.id"
                    ></maintenance-history>
                </div>
            </tab>
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-file-lines tab-title-icon"></i>
                    <span>Associated Resources</span>
                    <new-feature
                        type="NEW"
                        name="Associated Resources"
                        description="Associate battery sets and equipment with this RPA to streamline operations."
                    ></new-feature
                    >
                </ng-template>
                <div class="modal-body-content container-scroll">
                    <plan-highlight
                        *ngIf="!adminView"
                        [featureFlags]="
                            featureFlags['hasRpaResourceAssociation']
                        "
                    ></plan-highlight>
                    <associated-resources
                        *ngIf="hasRpaResourceAssociation"
                        [rpa]="rpa"
                        [organisation]="organisation"
                    ></associated-resources>
                    <upsell
                        *ngIf="!hasRpaResourceAssociation"
                        layout="LARGE"
                        componentName="rpa-resource-association-upsell"
                    ></upsell>
                </div>
            </tab>
            <tab [disabled]="locked">
                <ng-template tabHeading>
                    <i class="far fa-credit-card-blank tab-title-icon"></i>
                    <span> Authorities &amp; Registrations </span>
                </ng-template>
                <authority-list
                    *ngIf="isEditing !== 'AUTHORITIES'"
                    class="modal-body-content"
                    [rpa]="rpa"
                    [managingOrganisationId]="managingOrganisationId"
                    [canEdit]="true"
                    (edit)="editAuthority()"
                >
                </authority-list>
                <authority-edit
                    *ngIf="isEditing === 'AUTHORITIES'"
                    class="modal-body-content"
                    [rpa]="rpa"
                    [managingOrganisationId]="managingOrganisationId"
                    (done)="onEditCancelled()"
                >
                </authority-edit>
            </tab>
            <tab [disabled]="isEditing !== 'NONE'">
                <ng-template tabHeading>
                    <i class="far fa-file tab-title-icon"></i>
                    <span> Registers </span>
                </ng-template>
                <rpa-registers-status
                    class="modal-body-content"
                    [managingOrganisationId]="managingOrganisationId"
                    [rpaId]="rpaId"
                    [canEdit]="canEdit"
                    [canAdminOrganisation]="canAdminOrganisation"
                    [missionDetails]="missionDetails"
                >
                </rpa-registers-status>
            </tab>
            <tab [disabled]="rpaType == null || locked">
                <ng-template tabHeading>
                    <i class="far fa-files tab-title-icon"></i>
                    <span> Manuals </span>
                </ng-template>
                <div
                    *ngIf="rpaType != null && manualsAttachmentsHandler != null"
                    class="modal-body-content container-scroll"
                >
                    <enhanced-help
                        componentName="rpa-manuals"
                        [compact]="true"
                    ></enhanced-help>
                    <h4 class="section-heading">
                        <span>Manuals</span>
                        <div></div>
                    </h4>
                    <attachment-list
                        [attachmentsHandler]="manualsAttachmentsHandler"
                        [allowUploads]="false"
                        [allowDelete]="false"
                        [allowEdit]="false"
                    ></attachment-list>
                </div>
            </tab>
            <tab [disabled]="rpaType == null || locked">
                <ng-template tabHeading>
                    <i class="fa-solid fa-ruler-combined"></i>
                    <span> Specifications </span>
                </ng-template>
                <div
                    *ngIf="rpaType != null"
                    class="modal-body-content container-scroll"
                >
                    <enhanced-help
                        componentName="rpa-specifications"
                        [compact]="true"
                    ></enhanced-help>
                    <model-specification
                        *ngIf="rpaType != null"
                        [rpaType]="rpaType"
                    >
                    </model-specification>
                </div>
            </tab>
        </tabset>
    </div>
</ng-container>
