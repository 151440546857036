import { Component, Input } from '@angular/core';
import {
    AuthorityWorkflowApproverCheck,
    FlyFreelyError,
    FlyFreelyLoggingService,
    FormService,
    FormSummaryDto,
    StepDescription,
    WorkflowAttachmentRequirement,
    WorkflowMappedEntity,
    WorkflowMappedEntityDetails
} from '@flyfreely-portal-ui/flyfreely';
import { DocumentationDialoguesService } from 'libs/documentation/src/lib/documentation-dialogues.service';
import { map } from 'rxjs/operators';
import { AuthorityDocumentationForms } from '../workflow-edit/workflow-edit-dialogue.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'workflow-step-details',
    templateUrl: './workflow-step-details.component.html'
})
export class WorkflowStepDetails {
    @Input() steps: StepDescription[];
    @Input() disabled: boolean;
    @Input() mappedForms: { [step: string]: WorkflowMappedEntityDetails[] };
    @Input() mappedAttachmentRequirements: {
        [step: string]: WorkflowAttachmentRequirement[];
    };
    @Input() mappedApproverChecks: {
        [step: string]: AuthorityWorkflowApproverCheck[];
    };
    @Input() forms: FormSummaryDto[];

    @Input() additionalDocumentation: {
        [step: string]: { forms: AuthorityDocumentationForms[] };
    };

    @Input() hasApproverChecks: boolean;
    @Input() organisationId: number;

    constructor(
        private documentationDialoguesService: DocumentationDialoguesService,
        private formService: FormService,
        private logging: FlyFreelyLoggingService
    ) {}

    deleteRow(array: any[], index: number) {
        array.splice(index, 1);
    }

    addRow(array: WorkflowMappedEntity[]) {
        if (array.find(item => !item.id)) {
            return;
        }

        array.push({
            id: null,
            required: false,
            prefill: true,
            onceOnly: false
        });
    }

    addAttachmentRow(array: WorkflowAttachmentRequirement[]) {
        if (array.find(item => item.name == null || item.name.length === 0)) {
            return;
        }

        array.push({
            id: null,
            name: '',
            instructions: '',
            minimumInstances: 0
        });
    }

    addApproverCheck(array: AuthorityWorkflowApproverCheck[]) {
        if (array.find(item => item.check == null || item.check.length === 0)) {
            return;
        }

        array.push({ id: null, check: '', description: '' });
    }

    previewForm(entry: WorkflowMappedEntityDetails) {
        this.formService
            .findConcreteForm(entry.id, this.organisationId)
            .pipe(map(concreteForm => ({ ...entry, entity: concreteForm })))
            .subscribe(
                form =>
                    this.documentationDialoguesService.showFormInputDialogue(
                        form,
                        'Preview',
                        [],
                        this.organisationId,
                        () => Promise.resolve()
                    ),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while generating form preview: ${error.message}`
                    )
            );
    }

    countOf(step: string) {
        return (
            this.mappedForms[step].length +
            this.mappedAttachmentRequirements[step].length
        );
    }

    get ready() {
        return (
            this.mappedAttachmentRequirements != null &&
            (!this.hasApproverChecks || this.mappedApproverChecks != null) &&
            this.mappedForms != null &&
            this.forms != null
        );
    }

    getForms(missionFormId: number) {
        const selectedForm = this.forms.find(form => form.id === missionFormId);

        if (!selectedForm?.organisation) {
            return this.forms;
        }

        return this.forms.filter(form => form?.organisation);
    }

    /**
     * Handles the drop event for drag-and-drop.
     * Reorders the array based on the result of the drag action.
     * This function is called for both forms, attachment requirements, and approver checks.
     * 
     * @param event Drag-and-drop event from Angular CDK
     * @param array The array to reorder after the drag
     */
    drop(event: CdkDragDrop<any[]>, array: any[]) {
        if (event.previousIndex !== event.currentIndex) {
            // Use Angular CDK's moveItemInArray to reorder the array
            moveItemInArray(array, event.previousIndex, event.currentIndex);
        }
    }
}
