<div class="mission-summary">
    <div class="horizontal-container" *ngIf="mission != null">
        <div class="profile-picture-container status-container">
            <profile-picture
                *ngIf="rpic != null"
                [personId]="rpic.id"
                [managingOrganisationId]="organisation.id"
                [width]="70"
                [height]="70"
            >
            </profile-picture>
        </div>

        <div class="fill">
            <div class="section space">
                <div>
                    <h4>
                        <span>MISSION APPROVAL DETAILS</span>
                        <div></div>
                    </h4>
                </div>

                <div *ngIf="mission.missionWorkflowVersion == null">
                    <span><strong>Workflow:</strong> Not selected</span>
                </div>

                <div *ngIf="mission.missionWorkflowVersion != null">
                    <span
                        ><strong>Workflow:</strong>
                        {{ mission.missionWorkflowVersion.workflowName }}</span
                    >
                </div>

                <div *ngIf="mission.missionWorkflowVersion != null">
                    <span
                        ><strong>Primary Authority:</strong>
                        {{
                            (mission.missionWorkflowVersion.delegatedAuthority
                                | formatAuthority) || 'None'
                        }}</span
                    >
                </div>
                <div
                    *ngIf="
                        (mission.missionWorkflowVersion != null &&
                            mission.missionWorkflowVersion
                                .additionalAuthorities != null &&
                            mission.missionWorkflowVersion.additionalAuthorities
                                .length > 0) ||
                        (mission.additionalAuthorities != null &&
                            mission.additionalAuthorities.length > 0)
                    "
                >
                    <span
                        ><strong>Additional Authorities:</strong>
                        <ng-container
                            *ngIf="
                                mission.missionWorkflowVersion != null &&
                                mission.missionWorkflowVersion
                                    .additionalAuthorities != null &&
                                mission.missionWorkflowVersion
                                    .additionalAuthorities.length > 0
                            "
                        >
                            <ng-container
                                *ngFor="
                                    let authority of mission
                                        .missionWorkflowVersion
                                        .additionalAuthorities;
                                    let last = last
                                "
                            >
                                {{ (authority | formatAuthority) || 'None' }}
                                <br
                                    *ngIf="
                                        !last &&
                                        !(
                                            mission.additionalAuthorities !=
                                                null &&
                                            mission.additionalAuthorities
                                                .length > 0
                                        )
                                    "
                                />
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="
                                mission.additionalAuthorities != null &&
                                mission.additionalAuthorities.length > 0
                            "
                        >
                            <ng-container
                                *ngFor="
                                    let authority of mission.additionalAuthorities;
                                    let last = last
                                "
                            >
                                {{ (authority | formatAuthority) || 'None' }}
                                <br *ngIf="!last" />
                            </ng-container>
                        </ng-container>
                    </span>
                </div>
            </div>

            <div class="section space" *ngIf="approval != null">
                <h5>Current Approval</h5>
                <div *ngIf="missionApproval != null" class="vertical-container">
                    <span *ngIf="missionApproval.authorityType?.ruleset"
                        ><strong>Ruleset:</strong>
                        {{
                            missionApproval.authorityType?.ruleset?.name != null
                                ? missionApproval.authorityType.ruleset.name
                                : 'None'
                        }}</span
                    >
                    <span>
                        <strong>Authority:</strong>
                        {{
                            getSimplifiedAuthorityFromApproval(
                                missionApproval,
                                organisation
                            ) | formatAuthority: 'None'
                        }}
                    </span>
                </div>
                <div>
                    <span
                        ><strong>Request by:</strong>
                        {{ approval.requestedBy | formatPerson }}
                        {{
                            approval.requestTime | date: 'dd/MM/yyyy H:mm'
                        }}</span
                    >
                </div>

                <div>
                    <span
                        ><strong>Approver Response:</strong>
                        {{ approval.approvedBy | formatPerson }}
                        {{ approval.resolutionTime | date: 'dd/MM/yyyy H:mm' }}
                    </span>
                </div>

                <div *ngIf="approval.acceptedBy != null">
                    <span
                        ><strong>Accepted by:</strong>
                        {{ approval.acceptedBy | formatPerson }}
                        {{ approval.acceptTime | date: 'dd/MM/yyyy H:mm' }}
                    </span>
                </div>

                <div>
                    <span
                        ><strong>Approval Status:</strong>
                        {{ approval.status | formatApprovalStatus }}
                    </span>
                </div>
            </div>

            <div
                class="section space"
                *ngIf="
                    approval == null &&
                    activeApprovals != null &&
                    activeApprovals.length > 1
                "
            >
                <div>
                    <span
                        ><strong>Approval Details:</strong>
                        This mission has more than one active approval.
                    </span>
                </div>
                <!-- <ng-container
                    *ngFor="
                        let missionApproval of activeApprovals;
                        let i = index;
                        let first = first
                    "
                >
                    <label
                        *ngIf="activeApprovals.length > 1"
                        [ngClass]="{ 'top-buffer': !first }"
                    >
                        Approval {{ i + 1 }}
                    </label>
                    <div>
                        <span
                            ><strong>Authority:</strong>
                            {{
                                getSimplifiedAuthorityFromApproval(
                                    missionApproval,
                                    organisation
                                ) | formatAuthority: 'None'
                            }}</span
                        >
                    </div>

                    <div>
                        <span
                            ><strong>Request by:</strong>
                            {{
                                missionApproval.approval.requestedBy
                                    | formatPerson
                            }}
                            {{
                                missionApproval.approval.requestTime
                                    | date: 'dd/MM/yyyy H:mm'
                            }}</span
                        >
                    </div>

                    <div>
                        <span
                            ><strong>Approver Response:</strong>
                            {{
                                missionApproval.approval.approvedBy
                                    | formatPerson
                            }}
                            {{
                                missionApproval.approval.resolutionTime
                                    | date: 'dd/MM/yyyy H:mm'
                            }}
                        </span>
                    </div>

                    <div *ngIf="missionApproval.approval.acceptedBy != null">
                        <span
                            ><strong>Accepted by:</strong>
                            {{
                                missionApproval.approval.acceptedBy
                                    | formatPerson
                            }}
                            {{
                                missionApproval.approval.acceptTime
                                    | date: 'dd/MM/yyyy H:mm'
                            }}
                        </span>
                    </div>

                    <div>
                        <span
                            ><strong>Approval Status:</strong>
                            {{
                                missionApproval.approval.status
                                    | formatApprovalStatus
                            }}
                        </span>
                    </div>
                </ng-container> -->
            </div>
        </div>

        <div class="fcol-7" *ngIf="hasChat">
            <h4>
                <span>APPROVAL COMMS</span>
                <button
                    type="button"
                    class="btn btn-inline"
                    (click)="refreshDiscussions()"
                >
                    <span
                        class="fa fa-sync"
                        [ngClass]="{ 'fa-spin': loadingChat }"
                    ></span>
                </button>
                <div></div>
            </h4>

            <!-- <div class="row">
                        <div class="col-xs-5 col-xs-offset-7">
                            <div class="checkbox">
                                <label>
                                    <input
                                           type="checkbox"
                                           id="weather"
                                           ng-value="true"
                                           ng-model="todo" />
                                    <span class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>
                                    <span>Add Approval Criteria Notes </span>
                                </label>
                            </div>

                            <div class="checkbox">
                                <label>
                                    <input
                                           type="checkbox"
                                           id="weather"
                                           ng-value="true"
                                           ng-model="todo2" />
                                    <span class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>
                                    <span>Add Approval Conditions Notes </span>
                                </label>
                            </div>
                        </div>
                    </div> -->

            <div class="row">
                <div class="col-xs-7">
                    <div class="discussion-container">
                        <div
                            class="discussion"
                            *ngFor="let discussion of discussions"
                        >
                            <div class="row">
                                <div class="message-sender">
                                    <profile-picture
                                        [personId]="discussion.sender.id"
                                        [managingOrganisationId]="
                                            organisation.id
                                        "
                                        [width]="30"
                                        [height]="30"
                                    >
                                    </profile-picture>
                                    <span class="header"
                                        >Communication Sent at
                                        {{
                                            discussion.updatedAt
                                                | date: "H:mm 'on' dd/MM/yyyy"
                                        }}
                                        -
                                        <span class="user-name"
                                            >by {{ getName(discussion) }}</span
                                        ></span
                                    >
                                </div>
                                <div class="message-body">
                                    {{ discussion.body }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-5">
                    <textarea
                        id="new-message"
                        class="form-control scrollbar"
                        [(ngModel)]="message"
                        rows="7"
                        placeholder="Message the {{ chatRecipient }}"
                    >
                    </textarea>
                    <button
                        type="button"
                        [disabled]="!message || working"
                        class="btn btn-primary btn-sm btn-send"
                        (click)="sendMessage()"
                    >
                        <span
                            [tooltip]="
                                !message ? 'Enter a message above' : null
                            "
                            placement="top"
                            >Send</span
                        >
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
