<div class="modal-header no-icon">
    <h3 class="modal-title">Operating Categories</h3>
    <button type="button" (click)="cancel()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group" *ngFor="let category of operatingCategories">
        <!-- <span
            ><button
                type="button"
                class="btn-circle btn-link"
                (click)="showAddCategories = category.value"
                [disabled]="working || showAddCategories != null"
            >
                <span class="fa fa-plus"></span></button></span
        > -->
        <label class="control-label">{{ category.name }}</label>
        <div class="input-group" *ngIf="category.value != 'RPA_MTOW'">
            <!-- <div
            class="input-group"
            *ngIf="
                showAddCategories == category.value &&
                category.value != 'RPA_MTOW'
            "
        > -->
            <ng-select
                [(ngModel)]="valueToAdd[category.value]"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="working"
                [clearable]="false"
                [items]="categoryValueOptions[category.value]"
                bindLabel="name"
                appendTo="body"
            >
                <ng-template
                    ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                >
                    {{ operatingConditionsValueLookup[item] }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    {{ operatingConditionsValueLookup[item] }}
                </ng-template>
            </ng-select>
            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working || valueToAdd[category.value] == null"
                    (click)="addCategory(category.value)"
                >
                    Add
                </button>
            </span>
            <!-- <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-default"
                    [disabled]="working"
                    (click)="showAddCategories = null"
                >
                    Cancel
                </button>
            </span> -->
        </div>
        <div class="input-group" *ngIf="category.value == 'RPA_MTOW'">
            <!-- <div
            class="input-group"
            *ngIf="
                showAddCategories == category.value &&
                category.value == 'RPA_MTOW'
            "
        > -->
            <input
                class="form-control"
                type="number"
                min="0"
                [disabled]="working"
                [ngModelOptions]="{
                    standalone: true
                }"
                [(ngModel)]="valueToAdd[category.value]"
            />
            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="working || (valueToAdd[category.value] == null && !hasMtowUnlimited)"
                    (click)="addCategory(category.value)"
                >
                    Add
                </button>
            </span>
            <!-- <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-default"
                    [disabled]="working"
                    (click)="showAddCategories = null"
                >
                    Cancel
                </button>
            </span> -->
        </div>
        <ng-container *ngFor="let cat of categoryValues$ | async">
            <ng-container *ngIf="cat.type == category.value">
                <div
                    *ngFor="let value of cat.values"
                    class="horizontal-container data"
                >
                    <ng-container *ngIf="value.type != 'RPA_MTOW'">{{
                        operatingConditionsValueLookup[value.value]
                    }}</ng-container>
                    <ng-container *ngIf="value.type == 'RPA_MTOW'"
                        ><{{ value.value | formatMTOW }}</ng-container
                    >
                    &nbsp;
                    <button
                        type="button"
                        class="btn btn-tertiary btn-delete pull-right"
                        [disabled]="working || showAddCategories != null"
                        (click)="deleteCategory(value)"
                    >
                        <span class="fal fa-trash-alt"></span>
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <loading-indicator [isLoading]="working"></loading-indicator>
</div>
