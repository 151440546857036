import {
    Component,
    ElementRef,
    forwardRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    AirspaceAuthorisationManager,
    airspaceAuthorisationTypesRequiringCurrentUserAsRpic,
    authorisationTypesWithMultipleAuthorisations,
    findLatestAirspaceAuthorisationInList,
    mapSourceFiltersForResponse
} from '@flyfreely-portal-ui/airspace';
import {
    AirspaceAuthorisationDto,
    AirspaceAuthorisationService,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    AttachmentHandler,
    BatterySetDto,
    BatterySetService,
    buildDisplayableMissionDocumentation,
    buildMissionRequiredDocumentation,
    calculateEditableSteps,
    calculateValidatedSteps,
    CombinedMissionStatus,
    combineFormResponses,
    CraftDto,
    CurrentAttachmentVersionDto,
    DEFAULT_MAX_HEIGHT,
    DisplayableMissionDocumentation,
    DisplayableMissionDto,
    displayableMissionDtoCombinedStatus,
    DownloadService,
    DO_NOTHING,
    FEATURE_CASA_AUTHORISATION,
    FEATURE_EQUIPMENT,
    FEATURE_INCIDENT_REPORTS,
    FEATURE_NOTAMS,
    FlightLogFileDto,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    FormResponseCommand,
    hasFeatureFlag,
    InvalidOperation,
    isApprovalResolved,
    LocationDetailsDto,
    LocationFeatureDto,
    LocationService,
    LookupObject,
    MissionApprovalService,
    MissionCrewDetailsDto,
    MissionCrewDto,
    MissionDocumentationDto,
    MissionReportService,
    MissionRoleDto,
    MissionService,
    NameValue,
    NotFound,
    OperationForbidden,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    PersonDto,
    PersonService,
    PersonsOrganisationDto,
    PrintOption,
    RequestersMissionApprovalDto,
    RequiringEntity,
    RpaTypeDto,
    RpaTypesService,
    RulesetDto,
    SimpleResourceDetails,
    SortieDto,
    StepDescription,
    StepEntityRequiredDocumentation,
    toLookup,
    unique,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormatRpaPipe } from '@flyfreely-portal-ui/resource-ui';
import { FormatDateTimePipe } from '@flyfreely-portal-ui/ui';
import { bearing } from '@turf/bearing';
import { center } from '@turf/center';
import { distance } from '@turf/distance';
import { bearingToAzimuth } from '@turf/helpers';
import { Angulartics2 } from 'angulartics2';
import { Feature, FeatureCollection, LineString } from 'geojson';
import {
    AirspaceCheckService,
    findLocationFlightAreaPolygon
} from 'libs/airspace/src/lib/airspace-check/airspace-check.service';
import { AirspaceDialoguesService } from 'libs/airspace/src/lib/airspace-dialogues.service';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { DocumentationActions } from 'libs/documentation/src/lib/interfaces';
import { EquipmentDialoguesService } from 'libs/equipment/src/lib/equipment-dialogues.service';
import { FlightLogDataService } from 'libs/flight-logs/src/lib/flight-log-data.service';
import { FlightLogWithDuration } from 'libs/flight-logs/src/lib/flight-log-list/flight-log-list-dialogue.component';
import { FlightLogsDialoguesService } from 'libs/flight-logs/src/lib/flight-logs-dialogues.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { getFeatureGroups, toMapFeature } from 'libs/locations/src/lib/helpers';
import { MapSourceFilters } from 'libs/map/src/lib/dynamic-data.service';
import { colourGenerator } from 'libs/map/src/lib/flyfreely-map/helpers';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { SummaryHeader } from 'libs/missions/src/lib/summary-header/summary-header.component';
import { PersonnelDialoguesService } from 'libs/personnel/src/lib/personnel-dialogues.service';
import { RpaDialoguesService } from 'libs/rpa/src/lib/rpa-dialogues.service';
import * as moment from 'moment';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import {
    BehaviorSubject,
    combineLatest,
    concat,
    firstValueFrom,
    forkJoin,
    Subject
} from 'rxjs';
import {
    map,
    mapTo,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    toArray
} from 'rxjs/operators';
import { LocationBearing } from '../bearing.pipe';
import {
    approvalRequiringEntities,
    findActiveApprovals,
    findActiveMissionApprovals,
    findMissionAirspaceJurisdictionId,
    notamToFeatureCollection
} from '../helpers';
import { MissionApprovalWithAuthority } from '../interfaces';
import { MissionDialoguesService } from '../mission-dialogues.service';
import { MissionDetailsStatus } from './mission-details-status/mission-details-status.component';
import { MissionDetailsService } from './mission-details.service';

/**
 * Houses the flight log start and end times in a pre-rendered string to minimise the use of functions and pipes in the HTML
 */
interface SortieWithLogTimes extends SortieDto {
    logTimes?: string;
}

@Component({
    selector: 'mission-details-v2-dialogue',
    templateUrl: './mission-details-v2-dialogue.component.html',
    providers: [
        MissionDetailsService,
        FlightLogDataService,
        AirspaceCheckService,
        AirspaceAuthorisationManager,
        WorkTracker
    ]
})
export class MissionDetailsV2Dialogue implements OnInit, OnDestroy {
    missionId: number;
    organisationId: number;
    organisation: PersonsOrganisationDto;
    // FIXME: while this works there needs to be a better solution to pass this through the routing
    // It only works if every case where this should be false is explicitly stated
    allowEditSubmit: boolean = true;

    mission: DisplayableMissionDto;

    working = false;
    loaded = false;

    private ngUnsubscribe$ = new Subject<void>();

    approvalSteps: NameValue[];
    missionDocumentationSteps: StepDescription[];
    location: LocationDetailsDto;
    actualLocation: LocationDetailsDto;
    missionDate: moment.Moment;

    locationFeatures: FeatureGroup[];

    // Holds filtered details of the mission
    missionDetails: {
        rpic: PersonDto;
        additionalCrew: MissionCrewDetailsDto[];
    };

    missionStartTime: string;
    missionEndTime: string;
    missionRuleset: RulesetDto;
    missionSorties: SortieWithLogTimes[] = [];
    airspaceJurisdictionId: number;

    missionApprovals: MissionApprovalWithAuthority[] = [];
    activeMissionApprovals: MissionApprovalWithAuthority[] = [];
    missionAuthorities: OrganisationAuthorityDto[] = [];
    missionAuthorityGroups: OrganisationAuthorityGroup[] = [];

    flightLogsByFlight: { [flightId: number]: FlightLogWithDuration[] };
    flightPathByFlight: { [flightId: number]: Feature<LineString> } = {};
    unassignedFlightLogs: FlightLogFileDto[];
    flightLogIds$ = new BehaviorSubject<string[]>([]);

    craftLookup: { [rpaId: number]: CraftDto };
    personLookup: { [personId: number]: PersonDto };
    batterySetLookup: { [batterySetId: number]: BatterySetDto };
    rpaTypes: RpaTypeDto[];
    documentation: DisplayableMissionDocumentation;
    approvalRequiredDocumentation: {
        [approvalId: number]: StepEntityRequiredDocumentation;
    };
    attachments: CurrentAttachmentVersionDto[];
    requiringEntities: RequiringEntity[];
    canCancel: boolean;
    canEdit: boolean;
    canComplete: boolean;
    canFinalise: boolean;
    canUnfinalise: boolean;
    canPrepareToFly: boolean;
    canApprove: boolean;
    canAcceptApproval: boolean;
    canDelete: boolean;
    canDoAirspaceAuthorisation: boolean;
    userCanViewAirspaceAuthorisation = false;

    checkingAirspace: boolean;
    checkingAuthorisation: boolean;
    modalStatus: 'ERROR' | 'LOADING' | 'NO_ACCESS' | 'NOT_FOUND' | 'LOADED' =
        'LOADING';
    approvedAirspaceAuthorisations: AirspaceAuthorisationDto[];
    enabledAuthorisationTypes = [
        AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE
    ];
    isUsingAlternativeAuthorisation = false;
    showAirspaceAuthorisationStatus = false;
    airspaceLoading = false;
    gcdVersion: string;

    shouldCheckAirspace$ = new Subject<void>();

    mapSourceFilters: MapSourceFilters;
    enabledMapLayerGroups: string[];

    hasFlightLogging: boolean;
    hasIncidentReports: boolean;
    canUseEquipment: boolean;
    canUseNotams: boolean;

    featureFlags: LookupObject<string> = {};

    attachmentsHandler: AttachmentHandler;
    documentationActions: DocumentationActions;
    printOptions: PrintOption[] = [];
    isCompleted: boolean;
    opened = false;

    combinedStatus: CombinedMissionStatus;

    mapData$ = new BehaviorSubject<{ [source: string]: FeatureCollection }>({});

    @ViewChild('map', { read: ElementRef, static: false }) map: ElementRef;
    @ViewChild('tabset') tabset: TabsetComponent;
    @ViewChild('missionRibbons') missionRibbons: MissionDetailsStatus;
    @ViewChild(SummaryHeader, { static: false }) summaryHeader: SummaryHeader;
    @ViewChild('approvalSelectionPopover', { static: false })
    approvalSelectionPopover: PopoverDirective;

    constructor(
        private missionService: MissionService,
        private missionApprovalService: MissionApprovalService,
        private locationService: LocationService,
        private userService: UserService,
        private missionReportService: MissionReportService,
        private batterySetService: BatterySetService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private formatRpaPipe: FormatRpaPipe,
        private formatDateTimePipe: FormatDateTimePipe,
        public modal: BsModalRef<MissionDetailsV2Dialogue>,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        private personService: PersonService,
        private downloadService: DownloadService,
        private equipmentDialoguesService: EquipmentDialoguesService,
        private personnelDialoguesService: PersonnelDialoguesService,
        private rpaDialoguesService: RpaDialoguesService,
        @Inject(forwardRef(() => MissionDialoguesService))
        private missionDialoguesService: MissionDialoguesService,
        private airspaceDialoguesService: AirspaceDialoguesService,
        private fullScreenService: FullScreenService,
        private flightLogsService: FlightLogsService,
        private flightLogsDataService: FlightLogDataService,
        private flightLogsDialoguesService: FlightLogsDialoguesService,
        public missionDetailsService: MissionDetailsService,
        private airspaceCheckService: AirspaceCheckService,
        private airspaceAuthorisationService: AirspaceAuthorisationService,
        private airspaceAuthorisationManager: AirspaceAuthorisationManager,
        private rpaTypesService: RpaTypesService,
        private angulartics2: Angulartics2,
        private activatedRoute: ActivatedRoute,
        private workTracker: WorkTracker,
        modalOptions: ModalOptions
    ) {
        this.approvalSteps = missionApprovalService.getApprovalSteps();
        this.setupDocumentationActions();
        this.setupSubscriptions();

        this.airspaceAuthorisationManager.checkingAuthorisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(checking => (this.airspaceLoading = checking));

        modalOptions.closeInterceptor = () => {
            if (
                this.summaryHeader.message &&
                this.summaryHeader.message !== ''
            ) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            }
            return Promise.resolve();
        };
    }

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(params => {
                const missionId = this.activatedRoute.snapshot.params.missionId;
                const organisationId = +params.organisationId;
                if (isNaN(missionId) || isNaN(organisationId)) {
                    return;
                }
                this.missionId = isNaN(missionId)
                    ? null
                    : this.activatedRoute.snapshot.params.missionId;
                this.organisationId = isNaN(organisationId)
                    ? null
                    : organisationId;
                if (this.organisationId == null && this.missionId == null) {
                    return;
                }
                this.modalStatus = 'LOADING';

                this.missionDetailsService.setup(
                    this.missionId,
                    this.organisationId
                );
            });

        if (this.missionId != null && this.organisationId != null) {
            // set from dialog
            this.modalStatus = 'LOADING';

            this.missionDetailsService.setup(
                this.missionId,
                this.organisationId
            );
        }

        this.airspaceAuthorisationManager.setup(
            [
                AirspaceAuthorisationDto.AuthorisationType.NZL_AIRSHARE,
                AirspaceAuthorisationDto.AuthorisationType.USA_LAANC,
                AirspaceAuthorisationDto.AuthorisationType
                    .AUS_CASA_AUTHORISATION
            ],
            this.missionId
        );

        combineLatest([
            this.airspaceAuthorisationManager.missionAirspaceAuthorisations$,
            this.airspaceCheckService.result$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([authorisations, airspaceCheck]) => {
                this.setupExistingAuthorisationData(
                    authorisations,
                    airspaceCheck
                );
                if (authorisations != null) {
                    const hasMultipleAuthorisations =
                        authorisations[0]?.authorisationType != null
                            ? authorisationTypesWithMultipleAuthorisations.includes(
                                  authorisations[0].authorisationType
                              )
                            : false;
                    this.approvedAirspaceAuthorisations =
                        hasMultipleAuthorisations
                            ? authorisations
                            : [
                                  findLatestAirspaceAuthorisationInList(
                                      authorisations
                                  )
                              ];
                    this.showAirspaceAuthorisationStatus =
                        authorisations != null && authorisations.length > 0;
                }
            });

        this.airspaceAuthorisationManager.checkingAuthorisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(checking => (this.checkingAuthorisation = checking));

        this.refreshAirspacePermissions();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.flightLogIds$.complete();
        this.mapData$.complete();
        this.shouldCheckAirspace$.complete();
    }

    private setupSubscriptions() {
        this.shouldCheckAirspace$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                if (this.mission != null && this.rpaTypes != null) {
                    this.runAirspaceCheck();
                }
            });
        combineLatest([
            this.workTracker.observable,
            this.flightLogsDataService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([working, gqlWorking]) =>
                    (this.working = working || gqlWorking)
            );

        this.missionDetailsService.error$.subscribe(error => {
            this.logging.error(error, error.message);
            if (error.fatal) {
                this.modalStatus = 'ERROR';
            }
        });

        combineLatest([
            this.missionService.change$.pipe(startWith(undefined)),
            this.missionDetailsService.change$.pipe(startWith(undefined)),
            this.missionApprovalService.change$.pipe(startWith(undefined)),
            this.airspaceAuthorisationService.change$.pipe(
                startWith(undefined)
            ),
            this.missionDetailsService.organisation$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([v1, v2, v3, v4, organisation]) =>
                this.refreshData(organisation)
            );

        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                const airspaceCheckSupport =
                    this.mission?.location?.airspaceJurisdiction
                        ?.airspaceCheckSupport;
                if (
                    this.canDoAirspaceAuthorisation ||
                    (airspaceCheckSupport != null &&
                        airspaceCheckSupport !==
                            AirspaceJurisdictionDto.AirspaceCheckSupport.NONE)
                ) {
                    this.refreshAirspaceAuthorisation(result);
                }
                this.setAirspaceLayers(result);
            });

        this.airspaceCheckService.resultStatus$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(status => {
                this.checkingAirspace = status === 'CHECKING';
            });

        this.flightLogsDataService.flightLogs$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(logs => this.setupFlightLogs(logs));

        // When the flight log IDs are passed in, analyse them in serial using concat.
        // switchMap is used on top of concat to ensure no flight logs are analysed unnecessarily.
        // This takes longer if done in serial, but having large numbers of complex flight logs was causing timeouts.
        // Due to how long this could take and the impact (it's only used for the map), it's not using the work tracker.
        this.flightLogIds$
            .pipe(
                switchMap(ids =>
                    concat(
                        ...ids.map((id, i) =>
                            this.flightLogsService
                                .findFlightLogSummaryById(
                                    this.flightLogsByFlight[id][0].id
                                )
                                .pipe(
                                    tap({
                                        next: flightSummary =>
                                            (this.flightPathByFlight[id] = {
                                                id: 'flightPath',
                                                type: 'Feature',
                                                properties: {},
                                                geometry:
                                                    flightSummary.summary
                                                        ?.flightPath
                                            }),
                                        error: (error: FlyFreelyError) =>
                                            this.logging.error(
                                                error,
                                                `Error while analysing flight log: ${error.message}`
                                            )
                                    })
                                )
                        )
                    ).pipe(toArray())
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(flightSummaries => this.setupMapFeatures());
        this.missionDetailsService.organisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => (this.organisation = organisation));
    }

    refreshData(organisation: PersonsOrganisationDto) {
        this.refreshMission(organisation);

        this.rpaTypesService
            .findRpaTypes(organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: types => {
                    this.rpaTypes = types;
                    this.shouldCheckAirspace$.next();
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error finding RPA types: ${error.message}`
                    )
            });
    }

    private setupPermissions(organisation: PersonsOrganisationDto) {
        const currentUserId = this.userService.getCurrentUser().id;
        const isSystemAdmin =
            this.userService.getCurrentUser().type === 'SYSTEM_ADMIN';
        this.canEdit = this.mission.availableActions.canEdit;
        this.canComplete = this.mission.availableActions.canComplete;
        this.canPrepareToFly = this.mission.availableActions.canPrepareToFly;
        this.canFinalise = this.mission.availableActions.canFinalise;
        this.canUnfinalise = this.mission.availableActions.canUnfinalise;
        this.canApprove = this.mission.availableActions.canApprove;
        this.canAcceptApproval =
            this.mission.approvals != null &&
            this.mission.approvals[this.mission.approvals.length - 1]
                ?.status === 'APPROVED' &&
            this.mission.missionCrewDetails.find(
                crew =>
                    crew.person.id === currentUserId &&
                    crew.role.coreRole ===
                        MissionRoleDto.CoreRole.PILOT_IN_COMMAND
            ) != null;

        this.canCancel = this.mission.availableActions.canCancel;

        this.canDelete = this.mission.availableActions.canDelete;

        this.hasFlightLogging =
            organisation.featureFlags.indexOf('rpaLogCollection') !== -1;

        this.hasIncidentReports =
            organisation.featureFlags.indexOf(FEATURE_INCIDENT_REPORTS) !== -1;
        this.featureFlags['hasIncidentReports'] = FEATURE_INCIDENT_REPORTS;

        this.canUseEquipment =
            organisation.featureFlags.indexOf(FEATURE_EQUIPMENT) !== -1;

        this.canUseNotams =
            organisation.featureFlags.indexOf(FEATURE_NOTAMS) !== -1;
        this.featureFlags['canUseNotams'] = FEATURE_NOTAMS;

        this.allowEditSubmit =
            this.allowEditSubmit &&
            (this.mission.status === DisplayableMissionDto.Status.DRAFT ||
                isSystemAdmin);

        const editableSteps =
            this.canEdit ||
            this.mission.status === DisplayableMissionDto.Status.READY_TO_FLY
                ? calculateEditableSteps(
                      this.mission.status,
                      this.canPrepareToFly,
                      this.canFinalise,
                      this.canEdit
                  )
                : [];

        const validatedSteps = calculateValidatedSteps(this.mission.status);

        this.missionDocumentationSteps = this.missionService.getMissionSteps(
            editableSteps,
            validatedSteps
        );

        this.canDoAirspaceAuthorisation =
            hasFeatureFlag(organisation, FEATURE_CASA_AUTHORISATION) &&
            hasFeatureFlag(
                this.userService.getPersonalOrganisation(),
                FEATURE_CASA_AUTHORISATION
            );
    }

    refreshAirspacePermissions() {
        // Check if the current user can use airspace authorisations, eg. LAANC requiring the current user to be an RPIC on the mission
        combineLatest([
            this.airspaceCheckService.completeCheck$,
            this.airspaceAuthorisationManager.missionAirspaceAuthorisations$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([completedCheck, authorisations]) => {
                // Check if the current airspace jurisdiction's authorisations require that the current user be the RPIC

                const requiresCurrentUserAsRpic =
                    completedCheck.result.availableAutomatedAuthorisationList.includes(
                        airspaceAuthorisationTypesRequiringCurrentUserAsRpic[
                            completedCheck.result.jurisdiction
                        ]
                    );

                // This assumes that any authorisations returned are for this person, but this logic can be changed here at a later date
                this.userCanViewAirspaceAuthorisation =
                    !requiresCurrentUserAsRpic ||
                    (authorisations != null && authorisations.length > 0);
            });
    }

    editMission() {
        this.missionDetailsService.organisation$
            .pipe(take(1))
            .subscribe(organisation => {
                this.missionDialoguesService.showMissionEditor(
                    this.mission,
                    organisation
                );
            });
    }

    private setupMission(
        mission: DisplayableMissionDto,
        organisation: PersonsOrganisationDto
    ): void {
        this.mission = mission;

        this.airspaceAuthorisationManager.updateMission(mission);

        this.isCompleted =
            [
                DisplayableMissionDto.Status.COMPLETED,
                DisplayableMissionDto.Status.FINALISED
            ].find(s => s === this.mission.status) != null;

        this.combinedStatus = displayableMissionDtoCombinedStatus(mission);

        this.missionDate = moment(this.mission.missionDate).tz(
            this.mission.timeZone
        );

        // This was being passed incorrect variables, it needs the timestamp, not the missionDate
        if (this.missionDate != null) {
            this.missionStartTime = this.mission.missionDate;
            this.missionEndTime = moment(this.mission.missionDate)
                .add(mission.missionEstimatedTime, 'seconds')
                .toISOString();
            this.missionRuleset =
                mission.missionWorkflowVersion?.delegatedAuthority?.authorityType?.ruleset;
        }

        if (this.mission.actualLocationId) {
            this.locationService
                .findLocation(this.mission.actualLocationId)
                .pipe(take(1), takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: location => {
                        this.actualLocation = location;
                    },
                    error: (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error finding mission location: ${error.message}`
                        );
                    }
                })
                .add(this.workTracker.createTracker());
        }

        this.craftLookup = this.mission.crafts.reduce(toLookup, {});
        this.personLookup = this.mission.missionCrewDetails
            .map(c => c.person)
            .reduce(toLookup, {});

        this.setupMissionApprovals();

        this.setupSorties();

        this.setupAttachments();

        this.setupPermissions(organisation);

        this.refreshAvailableReports();

        this.setupMapFeatures();

        const airspaceCheckSupport =
            this.mission?.location?.airspaceJurisdiction?.airspaceCheckSupport;
        if (
            airspaceCheckSupport !==
            AirspaceJurisdictionDto.AirspaceCheckSupport.NONE
        ) {
            this.shouldCheckAirspace$.next();
        }
        /* if (
            airspaceCheckSupport != null &&
            airspaceCheckSupport !==
                AirspaceJurisdictionDto.AirspaceCheckSupport.NONE &&
            this.mission.airspaceAuthorisationList != null &&
            this.mission.airspaceAuthorisationList.length > 0
        ) {
            this.showAirspaceAuthorisationStatus = true;
            this.refreshAirspaceAuthorisation(null);
        } */

        const rpic = mission.missionCrewDetails.find(
            c => c.role.coreRole === 'PILOT_IN_COMMAND'
        );
        this.missionDetails = {
            rpic: rpic != null ? rpic.person : null,
            additionalCrew: mission.missionCrewDetails.filter(
                c => c.role.coreRole !== 'PILOT_IN_COMMAND'
            )
        };

        this.airspaceJurisdictionId =
            findMissionAirspaceJurisdictionId(mission);

        this.refreshDependencies();

        this.mapData$.next({
            NOTAM: notamToFeatureCollection(this.mission.notams)
        });

        this.sortNotams();
    }

    setupMissionApprovals() {
        const approvals = this.mission.approvals;
        if (approvals?.length > 0) {
            const managingOrganisationId =
                this.mission.organisationId ?? this.organisation.id;
            const today = moment().format('YYYY-MM-DD');
            const approvalsWithoutAuthorityData = approvals.reduce(
                (acc, a) =>
                    acc.some(approval => a.authorityId === approval.authorityId)
                        ? acc
                        : acc.concat(a),
                []
            );
            combineLatest([
                forkJoin(
                    approvalsWithoutAuthorityData.map(approval =>
                        this.organisationAuthorityService.findAuthority(
                            approval.authorityId,
                            managingOrganisationId
                        )
                    )
                ),
                forkJoin([
                    this.organisationAuthorityService.findAuthorities(
                        this.organisation.id,
                        managingOrganisationId
                    ),
                    this.organisationAuthorityService.findSharedAuthorities(
                        this.organisation.id,
                        today
                    )
                ]).pipe(
                    map(([authorityGroups, sharedAuthorityGroups]) =>
                        authorityGroups.concat(sharedAuthorityGroups)
                    )
                )
            ])
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: ([authorities, authorityGroups]) => {
                        this.missionAuthorities = authorities;
                        this.missionAuthorityGroups = authorityGroups;
                        this.missionApprovals = approvals.map(approval => {
                            const authority = this.missionAuthorities.find(
                                auth => approval.authorityId === auth.id
                            );
                            const authorityGroup =
                                this.missionAuthorityGroups.find(
                                    group =>
                                        group.id === authority.authorityTypeId
                                );
                            return {
                                approval,
                                authority,
                                authorityType: authorityGroup
                            };
                        });
                        this.activeMissionApprovals =
                            findActiveMissionApprovals(
                                this.missionApprovals,
                                true
                            );
                    },
                    error: (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error fetching approval authorities: ${error.message}`
                        )
                })
                .add(this.workTracker.createTracker());
        } else {
            this.missionApprovals = [];
            this.activeMissionApprovals = [];
        }
    }

    private refreshDependencies() {
        this.refreshBatterySets();

        this.refreshDocumentation();

        this.refreshFlightLogs();
        this.modalStatus = 'LOADED';
    }

    private refreshBatterySets() {
        const doneWorking = this.workTracker.createTracker();
        Promise.all(
            this.mission.sorties
                .map(s => s.batterySetId)
                .filter(bsid => bsid !== null)
                .filter(unique)
                .map(bsid =>
                    this.batterySetService
                        .findBatterySet(bsid, this.mission.organisationId)
                        .toPromise()
                        .catch((error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                `Error finding battery sets: ${error.message}`
                            )
                        )
                )
        )
            .then((batterySets: BatterySetDto[]) => {
                this.batterySetLookup = batterySets.reduce(toLookup, {});
                doneWorking();
            })
            .catch((error: FlyFreelyError) => {
                this.logging.error(
                    error,
                    `Error setting up battery sets: ${error.message}`
                );
                doneWorking();
            });
    }

    // TODO: this seems dated. Refactor to the updated format if not needed
    private refreshDocumentation() {
        if (!this.missionId) {
            return;
        }
        this.missionService
            .findDocumentation(this.missionId)
            .subscribe({
                next: documentation => {
                    this.setupDocumentation(documentation);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while fetching mission documentation: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    private refreshFlightLogs() {
        if (this.missionId == null || !this.hasFlightLogging) {
            return;
        }
        this.flightLogsDataService.findFlightLogs(
            0,
            // Can't do pagination on this
            1000000,
            null,
            {
                organisationId: this.mission.organisationId,
                missionId: this.missionId
            },
            this.mission.organisationId
        );
    }

    setupFlightLogs(flights: FlightLogWithDuration[]) {
        this.flightLogsByFlight = flights
            .filter(f => f.flightId != null)
            .reduce(
                (acc, f) => ({
                    ...acc,
                    [f.flightId]:
                        f.flightId in acc ? acc[f.flightId].concat(f) : [f]
                }),
                {} as { [flightId: number]: FlightLogWithDuration[] }
            );

        this.unassignedFlightLogs = flights.filter(f => f.flightId == null);

        this.setupMapFeatures();

        const ids = Object.keys(this.flightLogsByFlight).filter(
            fid => this.flightLogsByFlight[fid].length !== 0
        );
        this.flightLogIds$.next(ids);
    }

    setupSorties() {
        this.missionSorties = this.mission.sorties.map(s =>
            s.durationSource !== SortieDto.DurationSource.LOGS
                ? { ...s }
                : this.buildSortieLogTimes(s)
        );
    }

    private buildSortieLogTimes(sortie: SortieDto): SortieWithLogTimes {
        if (sortie.recordedStartTime == null) {
            return { ...sortie };
        }
        const timeZone = this.mission.timeZone;
        const startTime = this.formatDateTimePipe.transform(
            sortie.recordedStartTime,
            timeZone
        );
        const endTime =
            sortie.recordedEndTime != null
                ? this.formatDateTimePipe.transform(
                      sortie.recordedEndTime,
                      timeZone
                  )
                : null;
        if (endTime != null) {
            return {
                ...sortie,
                logTimes: `${startTime} - ${endTime}`
            };
        } else {
            return {
                ...sortie,
                logTimes: `Start: ${startTime}`
            };
        }
    }

    setupAirspaceAuthorisationStatus(
        authorisation: AirspaceAuthorisationDto[]
    ) {
        if (authorisation == null) {
            this.showAirspaceAuthorisationStatus = false;
            return;
        }
        this.showAirspaceAuthorisationStatus = true;
        const hasMultipleAuthorisations =
            authorisation[0]?.authorisationType != null
                ? authorisationTypesWithMultipleAuthorisations.includes(
                      authorisation[0].authorisationType
                  )
                : false;
        this.approvedAirspaceAuthorisations = hasMultipleAuthorisations
            ? authorisation
            : [findLatestAirspaceAuthorisationInList(authorisation)];
    }

    refreshAirspaceAuthorisation(airspaceCheck: AirspaceCheckDto) {
        const findLatestAuthorisation = (): AirspaceAuthorisationDto =>
            this.mission?.airspaceAuthorisationList.reduce(
                (acc, a: any) =>
                    moment(a.createTime).isAfter(moment(acc.createTime))
                        ? a
                        : acc,
                this.mission?.airspaceAuthorisationList[0]
            );
        const authorisationType =
            this.mission.airspaceAuthorisationList != null &&
            this.mission.airspaceAuthorisationList.length > 0
                ? findLatestAuthorisation().authorisationType
                : null;
        if (
            ((authorisationType ===
                AirspaceAuthorisationDto.AuthorisationType
                    .AUS_CASA_AUTHORISATION &&
                this.canDoAirspaceAuthorisation) ||
                authorisationType != null) &&
            this.mission?.airspaceAuthorisationList != null &&
            this.mission?.airspaceAuthorisationList.length > 0 &&
            this.mission?.id != null
        ) {
            this.airspaceAuthorisationManager.findAuthorisationsByMissionId(
                this.mission.id
            );
        }
    }

    setupExistingAuthorisationData(
        authorisations: AirspaceAuthorisationDto[],
        airspaceCheck?: AirspaceCheckDto
    ) {
        if (
            authorisations?.filter(a => a.status != null) !== null ||
            authorisations?.length > 0
        ) {
            // FIXME: this needs to be either hard-coded better, or done programatically
            this.gcdVersion =
                airspaceCheck?.airspaceDatasetList?.find(
                    s => s.identifier === 'AUS_GCD'
                )?.version ?? null;
            this.setupAirspaceOperatingArea(authorisations);
            this.setupAirspaceAuthorisationStatus(authorisations);
        }
    }

    showAirspaceAuthorisation() {
        this.angulartics2.eventTrack.next({
            action: 'view-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });
        const command = this.buildAirspaceCheckCommand();
        this.airspaceDialoguesService.showAirspaceAuthorisationDialogue(
            this.airspaceAuthorisationManager,
            this.organisation,
            command,
            this.mission,
            null,
            null,
            this.rpaTypes,
            false
        );
    }

    buildAirspaceCheckCommand() {
        const rpicId = this.organisation.personalOrganisation
            ? this.organisation.ownerId
            : this.mission.missionCrewDetails
                  .filter(
                      c =>
                          c.role != null &&
                          c.person != null &&
                          c.role.coreRole ===
                              MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                  )
                  .map(c => c.person.id)[0];

        // @ts-ignore bad type overlapping
        const missionFormValues: MissionFormModel = {
            ...this.mission,
            missionDate: new Date(this.mission?.missionDate),
            missionObjectivesGroup: {
                missionObjective: this.mission?.missionObjective,
                isDummy: this.mission?.isDummy,
                location: this.mission?.location,
                name: this.mission?.name,
                missionObjectiveTypeId: this.mission?.missionObjectiveType?.id
            },
            rpicId: rpicId,
            missionTypeId: this.mission?.missionType?.id,
            documentation: null,
            equipmentIds: this.mission?.equipment?.map(e => e.id),
            missionCrew: this.mission?.missionCrewDetails as MissionCrewDto[],
            missionWorkflowVersionId: this.mission?.missionWorkflowVersionId,
            crewNotes: this.mission?.crewNotes,
            timeZone: this.mission?.timeZone,
            missionEstimatedTime: this.mission?.missionEstimatedTime,
            timeOfDay: this.mission?.timeOfDay
        };

        return this.airspaceCheckService.setupCheckCommand(
            this.mission.crafts,
            [this.mission?.missionWorkflowVersion],
            missionFormValues,
            this.location ?? this.mission?.location,
            this.rpaTypes?.reduce(toLookup, {}),
            true,
            false
        );
    }

    cancelAirspaceAuthorisation(authorisation: AirspaceAuthorisationDto) {
        this.angulartics2.eventTrack.next({
            action: 'cancel-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Cancel Airspace Authorisation?',
                `Are you sure you want to cancel this airspace authorisation`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => {
                this.airspaceAuthorisationManager
                    .cancelAuthorisation(
                        authorisation.id,
                        authorisation.authorisationType
                    )
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe({
                        next: DO_NOTHING,
                        error: (error: FlyFreelyError) =>
                            this.logging.error(
                                error,
                                `Error while cancelling authorisation: ${error.message}`
                            )
                    })
                    .add(this.workTracker.createTracker());
            }, DO_NOTHING);
    }

    closeAirspaceAuthorisation(authorisation: AirspaceAuthorisationDto) {
        this.angulartics2.eventTrack.next({
            action: 'close-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });

        this.commonDialoguesService
            .showConfirmationDialogue(
                'Mission Airspace Authorisation',
                'Do you wish to close this airspace authorisation request?',
                'Yes',
                () =>
                    firstValueFrom(
                        this.airspaceAuthorisationService.closeAuthorisation(
                            authorisation.id
                        )
                    )
            )
            .then(
                results => {
                    this.approvedAirspaceAuthorisations = null;
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while closing authorisation: ${error.message}`
                    )
            );
    }

    requestNewAirspaceAuthorisation() {
        this.approvedAirspaceAuthorisations = null;
        this.angulartics2.eventTrack.next({
            action: 'show-request-new-authorisation',
            properties: {
                category: 'airspace-authorisation'
            }
        });
        this.showAirspaceAuthorisation();
    }

    private setAirspaceLayers(airspaceCheck: AirspaceCheckDto) {
        if (airspaceCheck != null) {
            this.mapSourceFilters = mapSourceFiltersForResponse(airspaceCheck);
            const missionApprovalGcdVersion = this.gcdVersion;
            if (
                missionApprovalGcdVersion != null &&
                this.mapSourceFilters.AUS_GCD
            ) {
                this.mapSourceFilters.AUS_GCD = missionApprovalGcdVersion;
            }
            this.enabledMapLayerGroups = Object.keys(this.mapSourceFilters);
        }
    }

    setupAirspaceOperatingArea(
        approvedAirspaceAuthorisations: AirspaceAuthorisationDto[]
    ) {
        if (approvedAirspaceAuthorisations == null) {
            return;
        }
        approvedAirspaceAuthorisations.map(authorisation => {
            const area = authorisation.operatingArea;
            if (area != null) {
                const geometry = area != null ? <GeoJSON.Polygon>area : null;
                this.addFeature({
                    name: 'Operating Area',
                    type: LocationFeatureDto.Type.FLIGHT_AREA,
                    geometry: geometry
                } as LocationFeatureDto);
            }
        });
    }

    private addFeature(locationFeature: LocationFeatureDto) {
        if (this.locationFeatures == null) {
            this.locationFeatures = [];
        }
        let nextId =
            this.locationFeatures?.reduce(
                (acc, f) => (f.id > acc ? f.id : acc),
                0
            ) + 1;
        if (
            this.locationFeatures.length === 0 ||
            this.locationFeatures.filter(
                fg =>
                    fg?.categories?.findIndex(
                        c => c.id === locationFeature.type
                    ) !== -1
            ).length === 0
        ) {
            this.locationFeatures = this.locationFeatures.concat({
                id: nextId,
                name: locationFeature.name,
                type: 'Polygon',
                canAdd: false,
                categories: [
                    {
                        id: locationFeature.type,
                        name: locationFeature.name
                    }
                ],
                existingFeatures:
                    locationFeature != null
                        ? [toMapFeature(locationFeature, nextId++)]
                        : []
            });
        } else {
            this.locationFeatures = this.locationFeatures.map(fg =>
                fg.categories.findIndex(c => c.id === locationFeature.type) !==
                -1
                    ? {
                          ...fg,
                          existingFeatures: fg.existingFeatures.concat(
                              toMapFeature(locationFeature, nextId++)
                          )
                      }
                    : fg
            );
        }
    }

    private runAirspaceCheck() {
        if (this.mission == null || this.rpaTypes == null) {
            return;
        }
        const location = this.mission.location;
        if (location == null) {
            return;
        }

        const locationPolygon = findLocationFlightAreaPolygon(
            location.features
        );

        if (locationPolygon == null) {
            return;
        }

        const jurisdiction =
            this.mission.missionWorkflowVersion?.delegatedAuthority
                ?.authorityType?.jurisdiction?.identifier;
        const ruleset =
            this.mission.missionWorkflowVersion?.delegatedAuthority
                ?.authorityType?.ruleset?.identifier;

        if (jurisdiction == null || ruleset == null) {
            return;
        }

        const startTime = this.mission.missionDate;
        const endTime = moment(startTime)
            .add(this.mission.missionEstimatedTime, 'seconds')
            .toISOString();

        const airspaceCheckCommand = {
            startTime: startTime,
            endTime: endTime,
            jurisdiction: jurisdiction,
            location: locationPolygon,
            rpaList: this.mission.crafts
                .filter(
                    r =>
                        r.rpaTypeId != null &&
                        this.rpaTypes[r.rpaTypeId]?.performanceSpecifications
                            ?.maxTakeOffWeight != null
                )
                .map(r => ({
                    id: r.id,
                    mtow: this.rpaTypes[r.rpaTypeId].performanceSpecifications
                        .maxTakeOffWeight,
                    serialNumber: r.manufacturerSerialNumber
                })),
            ruleset: ruleset,
            nearestFeatureSearch: false,
            nearestFeatureSearchRadiusMeters: 400,
            maximumHeight: this.mission.maximumHeight ?? DEFAULT_MAX_HEIGHT
        };

        this.airspaceCheckService.startSingleCheck(
            airspaceCheckCommand,
            this.mission.missionWorkflowVersionId,
            this.mission.location?.airspaceJurisdiction
        );
    }

    private setupAttachments() {
        if (this.attachmentsHandler == null) {
            this.attachmentsHandler = this.missionService.attachmentHandler(
                this.missionId
            );
            this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);
        }
    }

    private setupDocumentationActions() {
        this.documentationActions = {
            getFormTemplatePrintUrl: (checklistId, step) =>
                this.missionService.getFormTemplatePrintUrl(
                    this.missionId,
                    checklistId,
                    step,
                    this.mission.organisationId
                ),
            getFormResponsePrintUrl: (formId, step) =>
                this.missionService.getFormResponsePrintUrl(
                    this.missionId,
                    formId,
                    step,
                    this.mission.organisationId
                ),
            storeFormResponse: (
                formId: number,
                step: string,
                response: FormResponseCommand
            ) =>
                firstValueFrom(
                    this.missionService
                        .updateDocumentation(this.missionId, {
                            [step]: [response]
                        })
                        .pipe(
                            tap(documentation =>
                                this.setupDocumentation(documentation)
                            ),
                            mapTo(undefined)
                        )
                )
        };
    }

    private setupDocumentation(documentation: MissionDocumentationDto) {
        this.documentation = buildDisplayableMissionDocumentation(
            documentation,
            findActiveApprovals(this.mission.approvals ?? []),
            this.mission.crafts,
            this.formatRpaPipe.transform
        );

        this.requiringEntities = documentation.missionRequiringEntities.concat(
            findActiveApprovals(this.mission.approvals ?? []).reduce(
                (acc, approval) =>
                    acc.concat(approvalRequiringEntities(approval)),
                []
            )
        );

        this.approvalRequiredDocumentation = Object.keys(
            documentation.missionApprovalRequiredDocumentation
        ).reduce(
            (acc, approvalId) => ({
                ...acc,
                [approvalId]: buildMissionRequiredDocumentation(
                    documentation.missionApprovalRequiredDocumentation[
                        approvalId
                    ]
                )
            }),
            {}
        );
    }

    private setupMapFeatures() {
        if (this.mission == null) {
            this.locationFeatures = [];
            return;
        }

        const flightFeatures =
            this.flightLogsByFlight != null
                ? [
                      {
                          id: 1,
                          name: 'Flights',
                          canAdd: false,
                          type: 'LineString',
                          styles: {
                              line: [
                                  {
                                      paint: { 'line-color': ['get', 'color'] }
                                  }
                              ]
                          },
                          existingFeatures: this.mission.sorties
                              .filter(
                                  s => this.flightLogsByFlight[s.id] != null
                              )
                              .map(s => ({
                                  // FIXME it is only because the sortie IDs are large that there is no conflict.
                                  // This should be made correct
                                  id: s.id,
                                  name: `Flight ${s.number}`,
                                  properties: {
                                      color: colourGenerator(s.number)
                                  },
                                  geom:
                                      this.flightPathByFlight[s.id] != null
                                          ? this.flightPathByFlight[s.id]
                                                .geometry
                                          : {
                                                type: 'LineString',
                                                coordinates:
                                                    this.flightLogsByFlight[
                                                        s.id
                                                    ]
                                                        .filter(
                                                            f =>
                                                                f.summary !=
                                                                    null &&
                                                                f.summary
                                                                    .startLongitude !=
                                                                    null &&
                                                                f.summary
                                                                    .startLatitude !=
                                                                    null &&
                                                                f.summary
                                                                    .endLongitude !=
                                                                    null &&
                                                                f.summary
                                                                    .endLatitude !=
                                                                    null
                                                        )
                                                        .reduce(
                                                            (acc, f) =>
                                                                acc.concat([
                                                                    [
                                                                        f
                                                                            .summary
                                                                            .startLongitude,
                                                                        f
                                                                            .summary
                                                                            .startLatitude
                                                                    ],
                                                                    [
                                                                        f
                                                                            .summary
                                                                            .endLongitude,
                                                                        f
                                                                            .summary
                                                                            .endLatitude
                                                                    ]
                                                                ]),
                                                            []
                                                        )
                                            }
                              }))
                      } as FeatureGroup
                  ]
                : [];

        const flightFeaturesWithData = flightFeatures.filter(
            f => f.existingFeatures.length > 0
        );

        if (this.mission.location == null) {
            this.locationFeatures = [...flightFeaturesWithData];
        } else {
            const { features } = getFeatureGroups(
                this.mission.location.features,
                0,
                2
            );
            this.locationFeatures = features.concat(flightFeaturesWithData);
        }
    }

    private sortNotams() {
        if (this.mission.location == null) {
            return;
        }
        const locationFeatures = this.mission.location.features.filter(
            f => f.type === LocationFeatureDto.Type.FLIGHT_AREA
        );
        if (locationFeatures == null || locationFeatures.length === 0) {
            return;
        }
        const locationGeom = <GeoJSON.Polygon | GeoJSON.Point>(
            locationFeatures[0].geometry
        );
        const centre = center(locationGeom);
        const getBearing = (pos: GeoJSON.Point) =>
            ({
                bearing: bearingToAzimuth(bearing(pos, centre)),
                distance: distance(centre, pos) * 0.539957
            } as LocationBearing);

        // sort notams to closest first
        this.mission.notams = this.mission.notams.sort(
            (a, b) =>
                getBearing(a.position).distance -
                getBearing(b.position).distance
        );
    }

    cancelMission() {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Cancel Mission',
                'Do you want to cancel this mission?',
                'Cancel Mission',
                () => this.missionService.cancelMission(this.missionId)
            )
            .then(
                () => {
                    doneWorking();
                    this.logging.success('Mission cancelled');
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while cancelling mission: ${error.message}`
                    );
                    doneWorking();
                }
            )
            .catch(() => doneWorking());
    }

    private refreshAvailableReports() {
        if (this.mission == null) {
            this.printOptions = [];
            return;
        }

        this.missionReportService
            .findAvailableTemplates(
                'MissionReport',
                this.missionId,
                this.organisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: reports => {
                    this.printOptions = reports.map(r => ({
                        name: r.name,
                        description: r.description,
                        print: () => {
                            const doneWorking =
                                this.workTracker.createTracker();
                            this.downloadService
                                .downloadFromUrl(
                                    this.missionReportService.getPdfReportUrl(
                                        r.reportTemplateId,
                                        this.missionId,
                                        this.organisationId
                                    )
                                )
                                .catch(() => doneWorking())
                                .then(() => doneWorking());
                        }
                    }));
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error fetching mission reports: ${error.message}`
                    );
                }
            });
    }

    unfinaliseMission() {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Unfinalise Mission',
                'Do you want to unfinalise this mission?',
                'Unfinalise',
                () => this.missionService.unfinaliseMission(this.missionId)
            )
            .then(
                result => {
                    this.logging.success('Mission unfinalised');
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while unfinalising mission: ${error.message}`
                    );
                    doneWorking();
                }
            )
            .catch(() => doneWorking());
    }

    submitForApproval(missionApproval: RequestersMissionApprovalDto) {
        this.missionApprovalService
            .submitForApproval(missionApproval.id, [])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success('Mission submitted for approval');
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while submitting for approval: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    cancelApproval(missionApproval: RequestersMissionApprovalDto) {
        const doneWorking = this.workTracker.createTracker();
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Cancel Mission Approval Request',
                'Do you want to cancel this mission approval request?',
                'Cancel Mission Approval Request',
                () =>
                    firstValueFrom(
                        this.missionApprovalService.cancelApproval(
                            missionApproval.id
                        )
                    )
            )
            .then(
                () => {
                    this.logging.success('Mission approval request cancelled');
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while cancelling approval: ${error.message}`
                    );
                    doneWorking();
                }
            )
            .catch(() => doneWorking());
    }

    hasPendingApproval() {
        let pendingApproval = false;
        if (this.mission.approvals && this.mission.approvals.length > 0) {
            const status = RequestersMissionApprovalDto.Status;
            const nonPendingStatuses = [
                status.APPROVED,
                status.ACCEPTED,
                status.CANCELLED,
                status.DRAFT,
                status.REVOKED,
                status.REJECTED
            ];
            pendingApproval = !this.mission.approvals.some(approval =>
                nonPendingStatuses.includes(approval.status)
            );
        }
        return pendingApproval;
    }

    openMissionRecordDialogue() {
        this.missionDetailsService.organisation$
            .pipe(take(1))
            .subscribe(organisation =>
                this.missionDialoguesService.showMissionRecordDialogue(
                    this.mission,
                    organisation
                )
            );
    }

    openMissionCompletionDialogue() {
        this.missionDetailsService.organisation$
            .pipe(take(1))
            .subscribe(organisation =>
                this.missionDialoguesService.showMissionCompletion(
                    this.mission,
                    organisation
                )
            );
    }

    private refreshMission(organisation: PersonsOrganisationDto) {
        this.missionService
            .findMission(this.missionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: mission => {
                    this.setupMission(mission, organisation);
                },
                error: (error: FlyFreelyError) => {
                    if (
                        error instanceof NotFound ||
                        error instanceof InvalidOperation
                    ) {
                        this.modalStatus = 'NOT_FOUND';
                    } else if (error instanceof OperationForbidden) {
                        this.modalStatus = 'NO_ACCESS';
                    } else {
                        this.logging.error(
                            error,
                            `Error while fetching mission: ${error.message}`
                        );
                        this.modalStatus = 'ERROR';
                    }
                }
            })
            .add(this.workTracker.createTracker());
    }

    showSortieDocumentation(flight: SortieDto) {
        if (flight.id == null) {
            return;
        }
        this.missionService
            .findSortieDocumentation(this.missionId, flight.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: documentation => {
                    const formResponses = combineFormResponses([
                        documentation ? documentation.formResponses : {}
                    ]);

                    this.missionDialoguesService.showFlightDocumentationDialogue(
                        this.mission,
                        flight,
                        this.documentation.requiredDocumentation,
                        formResponses
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while fetching sortie documentation: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    findApprovalAndApprove(approval: RequestersMissionApprovalDto) {
        const missionApproval = this.missionApprovals.find(
            a => a.approval.id === approval.id
        );
        this.approveMission(missionApproval);
    }

    approveMission(approval: MissionApprovalWithAuthority) {
        if (
            this.approvalSelectionPopover != null &&
            this.approvalSelectionPopover.isOpen
        ) {
            this.approvalSelectionPopover.hide();
        }
        this.missionDetailsService.organisation$
            .pipe(take(1))
            .subscribe(organisation => {
                const missionApprovalModal =
                    this.missionDialoguesService.showMissionApproval(
                        this.mission,
                        organisation,
                        approval.approval.id,
                        approval
                    );
            });
    }

    acceptApproval(approval: MissionApprovalWithAuthority) {
        this.missionDialoguesService.showAcceptMissionApproval(
            approval,
            this.organisation
        );
    }

    deleteMission() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Mission',
                'Do you want to delete this mission? This can not be recovered',
                'Delete',
                () =>
                    firstValueFrom(
                        this.missionService.deleteMission(this.missionId)
                    ).catch((error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error deleting: ${error.message}`
                        );
                        return Promise.reject(error);
                    })
            )
            .then(
                results => {
                    this.logging.success('Mission deleted');
                    this.modal.hide();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while deleting mission: ${error.message}`
                    );
                }
            );
    }

    isResolved(status: RequestersMissionApprovalDto.Status) {
        return isApprovalResolved(status);
    }

    showCraft(craft: CraftDto) {
        this.rpaDialoguesService.showCraftDetails(
            craft.id,
            this.mission.organisationId,
            false
        );
    }

    showEquipment(equipment: SimpleResourceDetails) {
        this.missionDetailsService.organisation$
            .pipe(take(1))
            .subscribe(organisation =>
                this.equipmentDialoguesService.showEquipmentDetails(
                    equipment.id,
                    organisation,
                    false
                )
            );
    }

    showPilot(pilot: PersonDto) {
        this.personService
            .findPerson(pilot.id, this.mission.organisationId)
            .subscribe({
                next: person =>
                    this.personnelDialoguesService.showPersonDetailsDialogue(
                        person,
                        this.mission.organisationId,
                        false
                    ),
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error finding pilot details: ${error.message}`
                    );
                }
            });
    }

    onFullscreenRequested() {
        this.fullScreenService.toggleFullScreen(this.map);
    }

    refreshRibbon() {
        this.missionRibbons.refreshData();
    }

    openConformanceTab() {
        this.tabset.tabs.forEach((val: TabDirective, i) => {
            if (val.heading === 'Conformance') {
                this.tabset.tabs[i].active = true;
            } else {
                this.tabset.tabs[i].active = false;
            }
        });
    }

    analyseFlightLog(flight: SortieDto, flightLog: FlightLogFileDto) {
        this.flightLogsDialoguesService.showFlightLog(
            flightLog.id,
            this.mission.organisationId,
            this.mission,
            flight
        );
    }
}
