import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalContainer } from '@flyfreely-portal-ui/ngx-bootstrap-customisation';
import { OrganisationAdminDialogue } from '../organisation-admin-dialogue.component';
import { OrganisationAdminModule } from '../organisation-admin.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        OrganisationAdminModule,
        RouterModule.forChild([
            {
                path: '',
                redirectTo: 'details',
                pathMatch: 'full'
            },
            {
                path: ':tab',
                component: ModalContainer,
                data: {
                    modalContent: OrganisationAdminDialogue,
                    modalClass: 'modal-lg',
                    relativeRoute: '../../'
                }
            }
        ])
    ],
    exports: [RouterModule]
})
export class OrganisationAdminRoutingModule {}
