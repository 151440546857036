import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { CraftDetailsDto, WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'notes-view',
    templateUrl: './notes-view.component.html',
    styleUrls: ['./notes-view.component.scss']
})
export class NotesViewComponent implements OnInit, OnDestroy {
    @Input() rpa: CraftDetailsDto;
    @Output() edit = new EventEmitter<void>();
    hasEdit = false;
    canEdit = true;

    workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    working = false;

    constructor() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit(): void {
        this.refreshPermissions();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        if (!this.rpa || !this.rpa.availableActions) {
            this.canEdit = false;
            return;
        }
        this.hasEdit = this.rpa.availableActions.hasEdit;
        this.canEdit = this.rpa.availableActions.canEdit;
    }

    editNotes() {
        this.edit.emit();
    }
}
