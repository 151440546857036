<ng-container screenAnalytics="authority-details">
    <div class="modal-header" [ngClass]="{ 'no-icon': icon == null }">
        <div *ngIf="icon != null" class="panel-heading-icon">
            <img [imgPath]="icon" />
        </div>
        <h3 class="modal-title">{{ authorityType.name }} Details</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="modal.hide()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <enhanced-help componentName="screen" [compact]="true"> </enhanced-help>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="data top-buffer">
                    <div class="display-label">Authority Type</div>
                    {{ authorityType?.name }}
                </div>
                <div class="data top-buffer">
                    <div class="display-label">Issued By</div>
                    {{ authorityType?.issuedBy?.name }}
                </div>
                <div class="top-buffer">
                    <div class="display-label">Description</div>
                    {{ authorityType?.description }}
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="data top-buffer">
                    <div class="display-label">Issued To</div>
                    {{ issuedTo }}
                </div>
                <div class="data top-buffer">
                    <div class="display-label">Start Date</div>
                    {{ authority.startDate | formatDate }}
                </div>

                <div *ngIf="authorityType.hasExpiry" class="data top-buffer">
                    <div class="display-label">Expiry Date</div>
                    {{ authority.expiryDate | formatDate }}
                </div>

                <div
                    *ngIf="authorityType.hasIdentifier"
                    class="data top-buffer"
                >
                    <div class="display-label">
                        {{ authorityType.identifierLabel || 'Identifier' }}
                    </div>
                    {{ authority.identifier }}
                </div>
            </div>
        </div>

        <h4 class="section-heading top-buffer">
            <span>Attachments</span>
            <div></div>
        </h4>
        <attachment-list
            [attachmentsHandler]="attachmentsHandler"
            [allowUploads]="false"
            [allowEdit]="false"
            [allowAcknowledgements]="authority.availableActions?.canEdit"
            [requestingOrganisationId]="managingOrganisationId"
            [parentPromiseTracker]="workTracker"
            permissions
        >
        </attachment-list>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
    <div class="modal-footer">
        <div
            *ngIf="
                !viewOnly &&
                isOrganisationAuthority &&
                organisationAuthority.availableActions?.canManage
            "
            class="bottom-buffer"
        >
            <ng-container *ngIf="canEdit">
                <button
                    *ngIf="
                        !organisationAuthority.archived &&
                        organisationAuthority.availableActions.canEdit
                    "
                    type="button"
                    class="btn btn-default pull-left"
                    (click)="
                        archiveAuthority.emit({
                            authorityType: authorityType,
                            authority: organisationAuthority
                        })
                    "
                    tooltip="Archive"
                    placement="top"
                >
                    Archive
                </button>

                <button
                    *ngIf="
                        organisationAuthority.archived &&
                        organisationAuthority.availableActions.canEdit
                    "
                    type="button"
                    class="btn btn-default pull-left"
                    (click)="
                        unarchiveAuthority.emit({
                            authorityType: authorityType,
                            authority: organisationAuthority
                        })
                    "
                    tooltip="Unarchive"
                    placement="top"
                >
                    Unarchive
                </button>
                <button
                    type="button"
                    *ngIf="organisationAuthority.availableActions.canDelete"
                    class="btn btn-danger pull-left"
                    (click)="
                        onDeleteAuthority.emit({
                            authorityType: authorityType,
                            authority: organisationAuthority
                        })
                    "
                >
                    Delete
                </button>
            </ng-container>
            <button
                *ngFor="let register of organisationAuthority.registers"
                type="button"
                class="btn"
                [ngClass]="{
                    'btn-primary': registerExpiries != null,
                    'btn-default': registerExpiries == null,
                    'btn-expired':
                        register.registerMode !== 'DISABLED' &&
                        registerExpiries &&
                        registerExpiries[register.id] === 'EXPIRED',
                    'btn-warning':
                        register.registerMode !== 'DISABLED' &&
                        registerExpiries &&
                        registerExpiries[register.id] === 'UPCOMING',
                    'btn-unavailable': register.registerMode === 'DISABLED'
                }"
                (click)="
                    onShowRegister.emit({
                        authorityType: authorityType,
                        authority: organisationAuthority,
                        register: register
                    })
                "
            >
                {{ register.name }}
                <span
                    *ngIf="
                        register.registerMode !== 'DISABLED' &&
                        registerExpiries &&
                        (registerExpiries[register.id] === 'UPCOMING' ||
                            registerExpiries[register.id] === 'EXPIRED')
                    "
                    class="fal fa-circle-exclamation"
                    [tooltip]="
                        registerExpiries[register.id] === 'EXPIRED'
                            ? 'Register has one or more expired conditions'
                            : 'Register has an upcoming expiry'
                    "
                    placement="top"
                ></span>
            </button>
            <ng-container *ngIf="canEdit">
                <button
                    type="button"
                    *ngIf="canUseWorkflow && authorityType.hasWorkflow"
                    class="btn btn-primary"
                    (click)="
                        onShowWorkflow.emit({
                            authorityType: authorityType,
                            authority: organisationAuthority
                        })
                    "
                >
                    Authority Workflow
                </button>
                <button
                    type="button"
                    *ngIf="
                        canUseWorkflow &&
                        authorityType.nominatedRoles.length > 0
                    "
                    class="btn btn-primary"
                    (click)="
                        onShowNominatedPersonnel.emit({
                            authorityType: authorityType,
                            authority: organisationAuthority
                        })
                    "
                >
                    Nominated Personnel
                </button>
                <button
                    type="button"
                    *ngFor="let option of printOptions"
                    class="btn btn-default"
                    (click)="option.print(organisationAuthority)"
                >
                    Print {{ option.name }}
                </button>
                <button
                    type="button"
                    *ngIf="
                        organisationAuthority.availableActions.canEdit ||
                        organisationAuthority.availableActions.canManage
                    "
                    class="btn btn-primary"
                    [disabled]="authorityType.discontinueDate != null"
                    (click)="
                        onEditAuthority.emit({
                            authorityType: authorityType,
                            authority: organisationAuthority
                        })
                    "
                    data-cy="authorityGroupEditButton"
                >
                    Edit
                </button>
            </ng-container>
        </div>
        <div
            *ngIf="
                !viewOnly &&
                !isOrganisationAuthority &&
                authority.availableActions?.canManage
            "
            class="bottom-buffer"
        >
            <ng-container *ngIf="canEdit">
                <button
                    *ngIf="
                        !authority.archived &&
                        authority.availableActions.canEdit
                    "
                    type="button"
                    class="btn btn-default pull-left"
                    (click)="
                        archiveAuthority.emit({
                            authorityType: authorityType,
                            authority: authority
                        })
                    "
                    tooltip="Archive"
                    placement="top"
                >
                    Archive
                </button>

                <button
                    *ngIf="
                        authority.archived && authority.availableActions.canEdit
                    "
                    type="button"
                    class="btn btn-default pull-left"
                    (click)="
                        unarchiveAuthority.emit({
                            authorityType: authorityType,
                            authority: authority
                        })
                    "
                    tooltip="Unarchive"
                    placement="top"
                >
                    Unarchive
                </button>
                <button
                    type="button"
                    *ngIf="authority.availableActions.canDelete"
                    class="btn btn-danger pull-left"
                    (click)="
                        onDeleteAuthority.emit({
                            authorityType: authorityType,
                            authority: authority
                        })
                    "
                >
                    Delete
                </button>
                <button
                    type="button"
                    *ngIf="canUseWorkflow && authorityType.hasWorkflow"
                    class="btn btn-primary"
                    (click)="
                        onShowWorkflow.emit({
                            authorityType: authorityType,
                            authority: authority
                        })
                    "
                >
                    Authority Workflow
                </button>
                <button
                    type="button"
                    *ngIf="
                        canUseWorkflow &&
                        authorityType.nominatedRoles.length > 0
                    "
                    class="btn btn-primary"
                    (click)="
                        onShowNominatedPersonnel.emit({
                            authorityType: authorityType,
                            authority: authority
                        })
                    "
                >
                    Nominated Personnel
                </button>
                <button
                    type="button"
                    *ngFor="let option of printOptions"
                    class="btn btn-default"
                    (click)="option.print(authority)"
                >
                    Print {{ option.name }}
                </button>
                <button
                    type="button"
                    *ngIf="
                        authority.availableActions.canEdit ||
                        authority.availableActions.canManage
                    "
                    class="btn btn-primary"
                    [disabled]="authorityType.discontinueDate != null"
                    (click)="
                        onEditAuthority.emit({
                            authorityType: authorityType,
                            authority: authority
                        })
                    "
                    data-cy="authorityGroupEditButton"
                >
                    Edit
                </button>
            </ng-container>
        </div>
    </div>
</ng-container>
