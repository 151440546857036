import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ChangeDetails,
    CraftDto,
    CraftService,
    ExclusiveControlService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionOperationTypeDto,
    MissionService,
    MissionWorkflowService,
    PersonService,
    SimpleMissionWorkflowDto,
    SimplePersonDto,
    SimpleWorkflowDto
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatPersonPipe } from '@flyfreely-portal-ui/ui';
import { EditableComponent } from 'libs/editable/src/lib/editable.component';
import { WorkGroup } from 'libs/flyfreely/src/lib/services/workgroups.service';
import { firstValueFrom, Observable, Subject, throwError } from 'rxjs';
import {
    catchError,
    combineLatestWith,
    distinctUntilKeyChanged,
    map,
    startWith,
    takeUntil,
    tap
} from 'rxjs/operators';
import {
    updateDefaultWorkgroupSettingsCommand,
    WorkgroupsDataService
} from './workgroups-data.service';

@Component({
    selector: 'workgroups-setup',
    templateUrl: './workgroups-setup.component.html',
    styleUrls: ['./workgroups-setup.component.scss']
})
export class WorkgroupsSetupComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input() organisationId: number;
    @ViewChild('workgroupEditorRef') workgroupNameRef: EditableComponent;

    public personnelColumns: TableColumn[];
    public rpaColumns: TableColumn[];
    public workflowsColumns: TableColumn[];
    tableConfig: TableConfig;

    workgroup$: Observable<WorkGroup[]>;
    workgroupFormGroup = new FormGroup({
        workgroupNameInput: new FormControl(undefined, Validators.required),
        workgroupNameSelector: new FormControl<WorkGroup>(
            undefined,
            Validators.required
        ),
        personnelSelector: new FormControl<{ id: number; name: string }>(
            undefined,
            Validators.required
        ),
        craftNameSelector: new FormControl<CraftDto>(
            undefined,
            Validators.required
        ),
        workflowSelector: new FormControl<SimpleMissionWorkflowDto>(
            undefined,
            Validators.required
        )
    });
    settingsForm = new FormGroup({
        workGroupId: new FormControl<number>(undefined),
        defaultMaximumHeight: new FormControl<number>(undefined),
        defaultMissionWorkflowId: new FormControl<number>(undefined),
        defaultOperationTypeId: new FormControl<number>(undefined)
    });
    workgroupNameSelectorValue$: Observable<WorkGroup | undefined>;
    personnelList$: Observable<{ id: number; name: string }[]>;
    rpaList$: Observable<CraftDto[]>;
    workflowList$: Observable<SimpleMissionWorkflowDto[]>;
    operationTypesList$: Observable<MissionOperationTypeDto[]>;

    organisationWorkflowListSource = new Subject<SimpleMissionWorkflowDto[]>();
    organisationWorkflowList$ =
        this.organisationWorkflowListSource.asObservable();

    private ngUnsubscribe$ = new Subject<void>();

    working: boolean;
    isAddingWorkgroup = false;

    hasNoWorkgroups = true;

    locked = false;

    constructor(
        private workgroupDataService: WorkgroupsDataService,
        private personService: PersonService,
        private craftService: CraftService,
        private missionWorkflowService: MissionWorkflowService,
        private missionService: MissionService,
        private exclusiveControlService: ExclusiveControlService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workgroup$ = this.workgroupDataService.workgroup$;
    }

    ngOnInit(): void {
        this.refreshWorkgroup();
        this.workgroupDataService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                if (
                    result.type === ChangeDetails.Type.CREATE ||
                    result.type === ChangeDetails.Type.UPDATE
                ) {
                    this.workgroupFormGroup.controls.workgroupNameSelector.setValue(
                        result.result
                    );
                } else if (result.type === ChangeDetails.Type.DELETE) {
                    this.workgroupFormGroup.controls.workgroupNameSelector.setValue(
                        undefined
                    );
                }
                this.refreshWorkgroup();
            });

        this.missionWorkflowService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(change => {
                this.refreshResources();
            });

        this.workgroupDataService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(workgroupWorking => (this.working = workgroupWorking));

        this.workgroupNameSelectorValue$ =
            this.workgroupFormGroup.controls.workgroupNameSelector.valueChanges;

        this.workgroupNameSelectorValue$
            .pipe(distinctUntilKeyChanged('id'), takeUntil(this.ngUnsubscribe$))
            .subscribe(workgroup => {
                if (workgroup) {
                    this.setupSettingsForm(workgroup);
                }
            });

        this.refreshResources();
        this.setUpResourceFilter();

        this.setUpTable();

        this.exclusiveControlService.lock$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locked => {
                this.locked = locked;
            });
    }

    ngAfterViewInit() {
        this.workgroup$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(workgroups => {
                if (workgroups.length === 0) {
                    this.isAddingWorkgroup = true;
                    this.hasNoWorkgroups = true;
                } else if (
                    this.workgroupFormGroup.controls.workgroupNameSelector
                        .value == null
                ) {
                    this.hasNoWorkgroups = false;
                    this.workgroupFormGroup.controls.workgroupNameSelector.setValue(
                        workgroups[0]
                    );
                } else {
                    this.hasNoWorkgroups = false;
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshWorkgroup() {
        this.workgroupDataService.findWorkgroups(this.organisationId);
    }

    refreshResources() {
        this.missionWorkflowService
            .findWorkflows(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: workflows =>
                    this.organisationWorkflowListSource.next(workflows),
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error fetching organisation workflows: ${error.message}`
                    )
            });
    }

    setupSettingsForm(workgroup: WorkGroup) {
        if (workgroup == null) {
            this.settingsForm.reset();
        } else {
            this.settingsForm.patchValue({
                workGroupId: workgroup.id,
                defaultMaximumHeight: workgroup.defaultMaximumHeight,
                defaultMissionWorkflowId: workgroup.defaultMissionWorkflow?.id,
                defaultOperationTypeId: workgroup.defaultOperationType?.id
            });
        }
        this.settingsForm.markAsPristine();
    }

    setUpResourceFilter() {
        this.personnelList$ = this.personService
            .findPersonnel(this.organisationId)
            .pipe(
                combineLatestWith(this.workgroupNameSelectorValue$),
                map(([personnelList, workgroup]) => {
                    if (workgroup == null) {
                        return [];
                    }
                    return personnelList
                        .filter(
                            personnel =>
                                !workgroup.personnelList.some(
                                    item => item.id === personnel.id
                                )
                        )
                        .map(personnel => ({
                            id: personnel.id,
                            name: new FormatPersonPipe().transform(personnel)
                        }));
                })
            );

        this.rpaList$ = this.craftService.findCrafts(this.organisationId).pipe(
            combineLatestWith(this.workgroupNameSelectorValue$),
            map(([rpaList, workgroup]) => {
                if (workgroup == null) {
                    return [];
                }
                return rpaList.filter(
                    rpa => !workgroup.rpaList.some(item => item.id === rpa.id)
                );
            })
        );

        this.workflowList$ = this.organisationWorkflowList$.pipe(
            combineLatestWith(
                this.workgroupNameSelectorValue$.pipe(
                    startWith(
                        this.workgroupFormGroup.controls.workgroupNameSelector
                            .value
                    )
                )
            ),
            map(([workflowList, workgroup]) => {
                if (workgroup == null) {
                    return [];
                }
                return workflowList
                    .filter(
                        workflow =>
                            !workflow.archived &&
                            !workgroup.workflowList.some(
                                item => item.id === workflow.id
                            )
                    )
                    .sort((a, b) => a.name.localeCompare(b.name));
            })
        );

        this.operationTypesList$ = this.missionService
            .findMissionTypes(this.organisationId)
            .pipe(
                combineLatestWith(this.workgroupNameSelectorValue$),
                map(([operationTypes, workgroup]) => {
                    if (workgroup == null) {
                        return [];
                    }
                    return operationTypes;
                })
            );
    }

    setUpTable() {
        this.personnelColumns = [
            {
                value: 'lastName',
                name: 'Last Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'firstName',
                name: 'First Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            }
        ];
        this.rpaColumns = [
            {
                value: 'nickname',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            }
        ];
        this.workflowsColumns = [
            {
                value: 'name',
                name: 'Name',
                searchable: true,
                selectable: false,
                defaultSelection: true
            }
        ];
        this.tableConfig = {
            limit: 10,
            actions: []
        };
    }

    onClickCancel() {
        this.isAddingWorkgroup = false;
    }

    onWorkgroupNameModelChange(isViewModel: boolean) {
        if (isViewModel) {
            this.exclusiveControlService.requestUnlock();
        } else {
            if (!this.isAddingWorkgroup) {
                this.workgroupFormGroup.controls.workgroupNameInput.setValue(
                    this.workgroupFormGroup.controls.workgroupNameSelector.value
                        .name
                );
            }
            this.exclusiveControlService.lock(null);
        }
    }

    onClickAddWorkGroup(edit: EditableComponent) {
        this.isAddingWorkgroup = true;
        this.workgroupFormGroup.controls.workgroupNameInput.reset();
        edit.enterEditMode();
    }

    updateOrCreateName = () => {
        const workgroupNameFc =
            this.workgroupFormGroup.controls.workgroupNameInput;
        if (this.isAddingWorkgroup) {
            return firstValueFrom(
                this.workgroupDataService
                    .createWorkgroup(this.organisationId, workgroupNameFc.value)
                    .pipe(
                        catchError((error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while create workgroup name: ${error.message}`
                            );
                            return throwError(
                                () => error.message ?? 'Entry is invalid'
                            );
                        }),
                        tap({
                            next: () => {
                                this.logging.success(
                                    `Successfully create workgroup name`
                                );
                            },
                            finalize: () => {
                                this.isAddingWorkgroup = false;
                            }
                        })
                    )
            );
        } else {
            return firstValueFrom(
                this.workgroupDataService
                    .renameWorkgroup(
                        this.workgroupFormGroup.controls.workgroupNameSelector
                            .value.id,
                        workgroupNameFc.value
                    )
                    .pipe(
                        catchError((error: FlyFreelyError) => {
                            this.logging.error(
                                error,
                                `Error while update workgroup name: ${error.message}`
                            );
                            return throwError(
                                () => error.message ?? 'Entry is invalid'
                            );
                        }),
                        tap({
                            next: () => {
                                this.logging.success(
                                    `Successfully rename workgroup name`
                                );
                            },
                            finalize: () => {
                                this.isAddingWorkgroup = false;
                            }
                        })
                    )
            );
        }
    };

    onAddPersonnelToWorkgroup() {
        const selectedWorkgroupId =
            this.workgroupFormGroup.controls.workgroupNameSelector.value.id;
        const selectedPersonnelId =
            this.workgroupFormGroup.controls.personnelSelector.value.id;
        this.workgroupDataService
            .addPersonnelToWorkGroup(selectedWorkgroupId, selectedPersonnelId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Successfully added personnel to workgroup`
                    );
                    this.workgroupFormGroup.controls.personnelSelector.reset();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while adding personnel to workgroup: ${error.message}`
                    );
                }
            });
    }

    onDeletePersonnelFromWorkgroup(personnel: SimplePersonDto) {
        const selectedWorkgroupId =
            this.workgroupFormGroup.controls.workgroupNameSelector.value.id;
        this.workgroupDataService
            .removePersonnelFromWorkgroup(selectedWorkgroupId, personnel.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Successfully removed personnel from workgroup`
                    );
                    this.workgroupFormGroup.controls.personnelSelector.reset();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while removing personnel from workgroup: ${error.message}`
                    );
                }
            });
    }

    onAddRpaToWorkgroup() {
        const selectedWorkgroupId =
            this.workgroupFormGroup.controls.workgroupNameSelector.value.id;
        const selectedRpaId =
            this.workgroupFormGroup.controls.craftNameSelector.value.id;
        this.workgroupDataService
            .addRpaToWorkGroup(selectedWorkgroupId, selectedRpaId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(`Successfully added RPA to workgroup`);
                    this.workgroupFormGroup.controls.craftNameSelector.reset();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while adding RPA to workgroup: ${error.message}`
                    );
                }
            });
    }

    onDeleteRpaFromWorkgroup(rpa: CraftDto) {
        const selectedWorkgroupId =
            this.workgroupFormGroup.controls.workgroupNameSelector.value.id;
        this.workgroupDataService
            .removeRpaFromWorkgroup(selectedWorkgroupId, rpa.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Successfully removed RPA from workgroup`
                    );
                    this.workgroupFormGroup.controls.craftNameSelector.reset();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while removing RPA from workgroup: ${error.message}`
                    );
                }
            });
    }

    onAddWorkflowToWorkgroup() {
        const selectedWorkgroupId =
            this.workgroupFormGroup.controls.workgroupNameSelector.value.id;
        const selectedWorkflowId =
            this.workgroupFormGroup.controls.workflowSelector.value.id;
        this.workgroupDataService
            .addWorkflowToWorkGroup(selectedWorkgroupId, selectedWorkflowId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Successfully added workflow to workgroup`
                    );
                    this.workgroupFormGroup.controls.workflowSelector.reset();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while adding workflow to workgroup: ${error.message}`
                    );
                }
            });
    }

    onDeleteWorkflowFromWorkgroup(workflow: SimpleWorkflowDto) {
        const selectedWorkgroupId =
            this.workgroupFormGroup.controls.workgroupNameSelector.value.id;
        this.workgroupDataService
            .removeWorkflowFromWorkgroup(selectedWorkgroupId, workflow.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(
                        `Successfully removed workflow from workgroup`
                    );
                    this.workgroupFormGroup.controls.workflowSelector.reset();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while removing workflow from workgroup: ${error.message}`
                    );
                }
            });
    }

    updateSettings() {
        const values = this.settingsForm.value;
        const command: updateDefaultWorkgroupSettingsCommand = {
            workgroupId: values.workGroupId,
            defaultMissionWorkflowId: values.defaultMissionWorkflowId,
            defaultOperationType: values.defaultOperationTypeId,
            defaultMaximumHeight: values.defaultMaximumHeight
        };
        this.workgroupDataService
            .updateDefaultWorkgroupSettings(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result => {
                    this.logging.success(`Updated workgroup settings`);
                    this.settingsForm.markAsPristine();
                    this.refreshWorkgroup();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while updating workgroup settings: ${error.message}`
                    );
                }
            });
    }
}
