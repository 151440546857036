import { ErrorHandler, Injectable } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {
    ContactDto,
    ContactWithLocationDto,
    CraftDto,
    CraftService,
    CreateLocationCommand,
    DisplayableMissionDto,
    EquipmentDto,
    EquipmentService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InUseMissionWorkflowVersionDto,
    LocationDetailsDto,
    LocationDto,
    LocationService,
    LockedMissionFields,
    MissionCrewDto,
    MissionRoleDto,
    MissionRoleService,
    MissionService,
    MissionWorkflowService,
    NotamDto,
    observeFormControl,
    OrganisationAuthorityService,
    patchableState,
    patchState,
    PersonnelRegisterEntryDetailsDto,
    PersonRolesDto,
    PersonService,
    prepareResponses,
    RegisterEntryOnMissionSummary,
    RpaTypeDto,
    RpaTypesService,
    SetupGuideActivityDto,
    StateUpdateCommand,
    toTimestamp,
    UpdateLocationCommand,
    UpdateMissionCommand
} from '@flyfreely-portal-ui/flyfreely';
import { WorkGroup } from 'libs/flyfreely/src/lib/services/workgroups.service';
import { SetupGuideChecklistService } from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import { notEmptyArrayValidator } from 'libs/validation/src/lib/formValidators';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    of,
    ReplaySubject,
    Subject
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    mergeMap,
    scan,
    startWith,
    takeUntil
} from 'rxjs/operators';
import { findActiveApprovals } from '../helpers';
import { MissionFormModel } from '../interfaces';
import { validateRpaNotDummy } from '../mission-validators';

export interface PersonWithRegisterStatus extends PersonRolesDto {
    registerStatus: PilotRegisterStatusUnion;
}

export type PilotRegisterStatusUnion =
    | null
    | 'LOADING'
    | PersonnelRegisterEntryDetailsDto.Status;

export interface SelectableRpa extends CraftDto {
    disabled: boolean;
    registerStatus: PilotRegisterStatusUnion;
}

export interface MissionObjectivesModel {
    name: FormControl<string>;
    workgroup?: FormControl<WorkGroup>;
    isDummy: FormControl<boolean>;
    missionObjectiveTypeId?: FormControl<number>;
    missionObjective: FormControl<string>;
    location: FormControl<LocationDetailsDto>;
}
export interface MissionObjectivesValue {
    name: string;
    workgroup?: WorkGroup;
    isDummy: boolean;
    missionObjectiveTypeId?: number;
    missionObjective: string;
    location: LocationDetailsDto;
}

@Injectable()
export class MissionEditService {
    private rpasSource = new ReplaySubject<CraftDto[]>();
    rpas$ = this.rpasSource.asObservable();

    private rpaTypesSource = new ReplaySubject<RpaTypeDto[]>();
    rpaTypes$ = this.rpaTypesSource.asObservable();

    private selectableRpasSource = new ReplaySubject<SelectableRpa[]>();
    selectableRpas$ = this.selectableRpasSource.asObservable();

    private equipmentSource = new ReplaySubject<EquipmentDto[]>();
    equipment$ = this.equipmentSource.asObservable();

    private selectableEquipmentSource = new ReplaySubject<EquipmentDto[]>();
    selectableEquipment$ = this.selectableEquipmentSource.asObservable();

    get organisationId() {
        return this._organisationId;
    }

    private _organisationId: number;

    private savedMissionSource = new ReplaySubject<DisplayableMissionDto>();
    savedMission$ = this.savedMissionSource.asObservable();

    private _savedMission: DisplayableMissionDto;

    missionForm: FormGroup<MissionFormModel>;
    missionObjectivesGroup: FormGroup<MissionObjectivesModel>;

    private missionWorkflowsSource = new ReplaySubject<
        InUseMissionWorkflowVersionDto[]
    >(1);
    missionWorkflows$ = this.missionWorkflowsSource.asObservable();
    private missionWorkflows: InUseMissionWorkflowVersionDto[];

    private missionLocationSource = new ReplaySubject<LocationDetailsDto>(1);
    missionLocation$ = this.missionLocationSource.asObservable();
    private missionLocation: LocationDetailsDto;

    private missionDateSource = new ReplaySubject<Date>(1);
    missionDate$ = this.missionDateSource.asObservable();

    private locationsSource = new ReplaySubject<LocationDto[]>(1);
    locations$ = this.locationsSource.asObservable();

    private missionRolesSource = new ReplaySubject<MissionRoleDto[]>(1);
    missionRoles$ = this.missionRolesSource.asObservable();

    private loadingWorkflowSource = new BehaviorSubject<boolean>(false);
    loadingWorkflow$ = this.loadingWorkflowSource.asObservable();

    private personnelSource = new ReplaySubject<PersonWithRegisterStatus[]>(1);
    personnel$ = this.personnelSource.asObservable();

    private registerEntriesOnMissionSource = new ReplaySubject<
        RegisterEntryOnMissionSummary[]
    >(1);
    registerEntriesOnMission$ =
        this.registerEntriesOnMissionSource.asObservable();

    private registerEntriesOnMissionLoadingSource =
        new BehaviorSubject<boolean>(false);
    registerEntriesOnMissionLoading$ =
        this.registerEntriesOnMissionLoadingSource.asObservable();

    private lockedFieldsSource = new ReplaySubject<
        StateUpdateCommand<LockedMissionFields>
    >(1);
    lockedFields$ = this.lockedFieldsSource.pipe<LockedMissionFields>(
        scan((acc, command) => patchableState(acc, command), {
            aircraft: false,
            isDummy: false,
            missionCrew: false,
            missionType: false,
            missionWorkflowVersion: false,
            timeZone: false
        })
    );

    private personnelRegisterStatus: {
        [personId: number]: Observable<PilotRegisterStatusUnion>;
    } = {};

    private rpicRoleId: number;

    get missionId(): number | undefined {
        return this._savedMission?.id;
    }

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private missionWorkflowService: MissionWorkflowService,
        private craftService: CraftService,
        private rpaTypeService: RpaTypesService,
        private locationService: LocationService,
        private equipmentService: EquipmentService,
        private setupGuideChecklistService: SetupGuideChecklistService,
        private missionService: MissionService,
        private missionRoleService: MissionRoleService,
        private personService: PersonService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private errorHandler: ErrorHandler,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnDestroy() {
        this.missionLocationSource.complete();
        this.missionWorkflowsSource.complete();
        this.savedMissionSource.complete();
        this.selectableRpasSource.complete();
        this.missionRolesSource.complete();
        this.loadingWorkflowSource.complete();
        this.missionDateSource.complete();
        this.selectableEquipmentSource.complete();
        this.equipmentSource.complete();
        this.personnelSource.complete();
        this.registerEntriesOnMissionSource.complete();
        this.registerEntriesOnMissionLoadingSource.complete();

        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /**
     * Kick off the feature
     * @param organisationId the organisation ID
     */
    setOrganisationId(organisationId: number) {
        this._organisationId = organisationId;
        this.craftService
            .findCrafts(this._organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(rpas => this.rpasSource.next(rpas));

        this.rpaTypeService
            .findRpaTypes(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(types => this.rpaTypesSource.next(types));

        this.locationService
            .findLocations(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locations => this.locationsSource.next(locations));

        this.missionRoleService
            .findMissionRoles(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(roles => {
                this.missionRolesSource.next(roles);
                this.rpicRoleId = roles.find(
                    r => r.coreRole === MissionRoleDto.CoreRole.PILOT_IN_COMMAND
                ).id;
            });

        this.equipmentService
            .find(this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(equipment => this.equipmentSource.next(equipment)),
            this.missionLocation$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(location => {
                    this.missionLocation = location;

                    const initial =
                        this.missionForm.controls.missionObjectivesGroup.value
                            .location?.id;

                    this.missionForm.controls.missionObjectivesGroup.patchValue(
                        {
                            location: location
                        }
                    );

                    if (initial !== location?.id) {
                        (<FormGroup<MissionObjectivesModel>>(
                            this.missionForm.controls.missionObjectivesGroup
                        )).controls.location.markAsDirty();
                    }
                });

        combineLatest([
            this.savedMission$.pipe(startWith(null as DisplayableMissionDto)),
            this.personService.findPersonnel(this.organisationId),
            this.organisationAuthorityService.change$.pipe(
                startWith({ type: null, changedId: null })
            )
        ])
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(([mission, personnel]) =>
                    mission != null
                        ? this.findPersonnelRegisterStatus(
                              mission,
                              personnel.map(p => p.id)
                          ).pipe(
                              catchError(() =>
                                  personnel.map(p => ({
                                      ...p,
                                      registerStatus: null
                                  }))
                              ),
                              map(registerStatuses =>
                                  personnel.map(p => ({
                                      ...p,
                                      registerStatus: registerStatuses[p.id]
                                  }))
                              ),
                              takeUntil(this.ngUnsubscribe$)
                          )
                        : of(
                              personnel.map(
                                  p =>
                                      ({
                                          ...p,
                                          registerStatus: null
                                      } as PersonWithRegisterStatus)
                              )
                          )
                )
            )
            .subscribe(personnel => this.personnelSource.next(personnel));
    }

    setup() {
        this.missionObjectivesGroup = this.fb.group<MissionObjectivesModel>({
            name: this.fb.control(undefined, [Validators.required]),
            workgroup: undefined,
            isDummy: this.fb.control(undefined, [Validators.required]),
            missionObjectiveTypeId: undefined,
            missionObjective: undefined,
            location: this.fb.control(undefined, [Validators.required])
        });

        this.missionForm = this.fb.group<MissionFormModel>({
            missionObjectivesGroup: this.missionObjectivesGroup,
            missionTypeId: this.fb.control(undefined, [Validators.required]),
            missionWorkflowVersionId: this.fb.control(undefined, [
                Validators.required,
                this.workflowConfirmed.bind(this),
                this.workflowApprovalStarted.bind(this)
            ]),
            additionalAuthorityIds: this.fb.array<number>([]),
            craftIds: this.fb.array<number>(
                [],
                [Validators.required, notEmptyArrayValidator()]
            ),
            equipmentIds: this.fb.array<number>([]),
            rpicId: this.fb.control(undefined, [Validators.required]),
            missionEstimatedTime: undefined,
            missionCrew: this.fb.array<MissionCrewDto>([]),
            crewNotes: undefined,
            missionDate: undefined,
            timeZone: undefined,
            maximumHeight: undefined,
            aerodromesOfInterest: this.fb.array<ContactWithLocationDto>([]),
            emergencyContacts: undefined,
            missionEmergencyContacts: this.fb.array<ContactDto>([]),
            radioFrequencies: undefined,
            missionRadioFrequencies: this.fb.array<ContactDto>([]),
            visualLineOfSight: this.fb.control(undefined, [
                Validators.required
            ]),
            timeOfDay: this.fb.control(undefined, [Validators.required]),
            documentation: undefined,
            notams: this.fb.array<NotamDto>([]),
            declareNoRelevantNotam: undefined
        });

        this.missionForm.controls.missionTypeId.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(() => this.refreshMissionWorkflows());

        this.missionForm.controls.missionDate.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(date => this.missionDateSource.next(date));

        const rpaWithStatus = combineLatest([
            this.savedMission$,
            this.rpas$,
            this.organisationAuthorityService.change$.pipe(
                startWith({ type: null, changedId: null })
            )
        ]).pipe(
            mergeMap(([mission, rpaList, changes]) =>
                mission != null
                    ? this.findRpaRegisterStatus(
                          mission,
                          rpaList.map(p => p.id)
                      ).pipe(
                          catchError(() =>
                              rpaList.map(p => ({
                                  ...p,
                                  disabled: true,
                                  registerStatus: null
                              }))
                          ),
                          map(registerStatuses =>
                              rpaList.map(p => ({
                                  ...p,
                                  disabled: true,
                                  registerStatus: registerStatuses[p.id]
                              }))
                          ),
                          takeUntil(this.ngUnsubscribe$)
                      )
                    : of(
                          rpaList.map(
                              p =>
                                  ({
                                      ...p,
                                      disabled: true,
                                      registerStatus: null
                                  } as SelectableRpa)
                          )
                      )
            )
        );

        combineLatest([
            this.missionObjectivesGroup.controls.isDummy.valueChanges,
            this.missionForm.controls.craftIds.valueChanges.pipe(
                distinctUntilChanged()
            ),
            rpaWithStatus
        ]).subscribe(([isDummy, rpaIds, rpas]) => {
            if (rpaIds == null || isDummy === true) {
                this.selectableRpasSource.next(
                    rpas.map(r => ({
                        ...r,
                        disabled: false
                    }))
                );
            } else {
                this.selectableRpasSource.next(
                    rpas
                        .filter(
                            r =>
                                rpaIds.indexOf(r.id) !== -1 ||
                                r.isDummy === false
                        )
                        .map(r => ({
                            ...r,
                            disabled: r.isDummy
                        }))
                );
            }
            this.missionForm.controls.craftIds.setValidators(
                this.getCraftIdsValidator(isDummy, rpas)
            );
        });

        combineLatest([
            this.missionObjectivesGroup.controls.isDummy.valueChanges,
            this.missionForm.controls.equipmentIds.valueChanges.pipe(
                distinctUntilChanged()
            ),
            this.equipment$
        ]).subscribe(([isDummy, equipmentIds, equipment]) => {
            if (equipmentIds == null || isDummy === true) {
                this.selectableEquipmentSource.next(
                    equipment.map(e => ({ ...e, disabled: false }))
                );
            } else {
                this.selectableEquipmentSource.next(
                    equipment
                        .filter(
                            e =>
                                equipmentIds.indexOf(e.id) !== -1 ||
                                e.isDummy === false
                        )
                        .map(e => ({ ...e, disabled: e.isDummy }))
                );
            }
        });

        combineLatest([
            observeFormControl<number>(this.missionForm.controls.rpicId),
            observeFormControl<MissionCrewDto[]>(
                this.missionForm.controls.missionCrew
            ),
            observeFormControl<number[]>(this.missionForm.controls.craftIds),
            this.savedMission$.pipe(startWith(null as DisplayableMissionDto)),
            this.missionRoles$.pipe(startWith([] as MissionRoleDto[])),
            this.organisationAuthorityService.change$.pipe(
                startWith({ type: null, changedId: null })
            )
        ])
            .pipe(
                // Debounce to limit number of initial calls as observables populate on load
                debounceTime(200),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(
                ([
                    rpicId,
                    missionCrew,
                    rpaIds,
                    savedMission,
                    missionRoles,
                    changes
                ]) => {
                    if (rpicId == null || savedMission == null) {
                        return;
                    }
                    const pilotRole =
                        missionRoles != null && missionRoles.length > 0
                            ? missionRoles.find(
                                  roll =>
                                      roll.coreRole ===
                                      MissionRoleDto.CoreRole.PILOT
                              )
                            : null;
                    const pilots =
                        pilotRole != null &&
                        missionCrew != null &&
                        missionCrew.length > 0
                            ? missionCrew?.filter(
                                  c => c.missionRoleId === pilotRole.id
                              )
                            : [];
                    const missionPilots: number[] = [rpicId].concat(
                        pilots?.map(p => p.personId) ?? []
                    );
                    this.findMissionRegisterStatusesForPerson(
                        savedMission,
                        missionPilots,
                        rpaIds
                    );
                }
            );
    }

    set savedMission(mission: DisplayableMissionDto) {
        this._savedMission = mission;
        this.savedMissionSource.next(mission);
        this.missionLocationSource.next(mission.location);
        this.lockedFieldsSource.next({
            type: 'SET',
            data: mission.lockedFields
        });
    }

    /**
     * Change the mission location to the one provided. This may update the mission's location,
     * or create a new one if required
     */
    updateLocation(location: LocationDetailsDto) {
        if (this.missionLocation?.id === location?.id) {
            this.setLocation(location);
        } else if (
            this.missionLocation != null &&
            this.missionLocation.id != null &&
            this.missionLocation.availableActions.canEdit
        ) {
            return this.updateMissionLocation(location);
        } else {
            return this.createMissionLocation(location);
        }
    }

    /**
     * Used to associate a new location with the mission, applying any
     * locking logic here, to mimic what the backend will do on save.
     * @param location the new location
     */
    setLocation(location: LocationDetailsDto) {
        this.missionLocationSource.next(location);

        if (location.timeZone != null) {
            this.lockedFieldsSource.next(patchState({ timeZone: true }));
        } else {
            this.lockedFieldsSource.next(patchState({ timeZone: false }));
        }
    }

    /**
     * Synchronises the workflow loading cycle across services and components.
     * Previously this was only housed in the mission edit component.
     * @param value the boolean value for "loadingWorkflow", indicating that a workflow change is in progress.
     */
    setLoadingWorkflowTracker(value: boolean) {
        this.loadingWorkflowSource.next(value);
    }

    /**
     * Validates that the workflow version is confirmed.
     * @param control the workflow version control
     */
    workflowConfirmed(control: AbstractControl<any>): ValidationErrors | null {
        return this._savedMission == null ||
            this._savedMission.missionWorkflowVersionId !== control.value
            ? { confirmed: false }
            : null;
    }

    /**
     * Validates that the workflow has a current approval.
     * @param control the workflow version control
     */
    workflowApprovalStarted(
        control: AbstractControl<any>
    ): ValidationErrors | null {
        const workflowVersionId = control.value;

        return workflowVersionId != null &&
            this.missionWorkflows != null &&
            (this.missionWorkflows.find(wf => wf.id === workflowVersionId)
                ?.delegatedAuthority?.authorityType?.requiresApproval ||
                this.missionWorkflows
                    .find(wf => wf.id === workflowVersionId)
                    ?.additionalAuthorities.filter(a => a.requiresApproval)
                    .length > 0) &&
            findActiveApprovals(this._savedMission.approvals, true).length === 0
            ? { approval: false }
            : null;
    }

    private refreshMissionWorkflows() {
        const missionDate = this.missionForm.controls.missionDate.value;
        const timeZone = this.missionForm.controls.timeZone.value;

        const dateStr = toTimestamp(missionDate, timeZone);

        this.missionWorkflowService
            .findActiveWorkflows(this._savedMission.organisationId, dateStr)
            .subscribe(
                missionWorkflows => {
                    this.missionWorkflows = missionWorkflows;
                    this.missionWorkflowsSource.next(missionWorkflows);
                    this.missionForm.controls.missionWorkflowVersionId.updateValueAndValidity(
                        {
                            onlySelf: true
                        }
                    );
                },
                error => {
                    this.logging.error(error);
                }
            );
    }

    private getCraftIdsValidator(isDummy: boolean, rpas: CraftDto[]) {
        return isDummy
            ? Validators.required
            : Validators.compose([
                  Validators.required,
                  validateRpaNotDummy(rpas)
              ]);
    }

    private createMissionLocation(newLocation: LocationDetailsDto) {
        const command: CreateLocationCommand = {
            name: newLocation.name,
            features: newLocation.features,
            organisationId: this.organisationId,
            type: CreateLocationCommand.Type.MISSION,
            derivedFromId: newLocation.id ?? null
        };
        return this.locationService
            .createLocation(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                location => this.setLocation(location),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while creating mission location: ${error.message}`
                    );
                }
            );
    }

    private updateMissionLocation(newLocation: LocationDetailsDto) {
        const command: UpdateLocationCommand = {
            name: newLocation.name,
            features: newLocation.features,
            derivedFromId:
                this.missionLocation.id !== newLocation.id
                    ? newLocation.id
                    : newLocation.derivedFromId
        };
        return this.locationService
            .updateLocation(this.missionLocation.id, command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                location => {
                    this.setLocation(location);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while updating mission location: ${error.message}`
                    );
                }
            );
    }

    private saveOrGetLocationId() {
        const location = (<FormGroup<MissionObjectivesModel>>(
            this.missionForm.controls.missionObjectivesGroup
        )).controls.location.value;
        if (location == null) {
            return of(null);
        }
        if (location?.id != null) {
            return of(location.id);
        }

        const command: CreateLocationCommand = {
            name: location.name,
            features: location.features,
            organisationId: this.organisationId,
            type: CreateLocationCommand.Type.MISSION,
            derivedFromId: location.id ?? null
        };
        return this.locationService
            .createLocation(command)
            .pipe(map(l => l.id));
    }

    saveMission(): Promise<DisplayableMissionDto> {
        const initialValues = this.prepareForSaving();

        const step1 = this.saveOrGetLocationId()
            .pipe(map(locationId => ({ ...initialValues, locationId })))
            .toPromise();

        if (this._savedMission.id != null) {
            return step1.then(forSaving =>
                this.missionService.updateMission(
                    this._savedMission.id,
                    forSaving
                )
            );
        } else {
            return step1
                .then(forSaving =>
                    this.missionService.createMission({
                        ...forSaving,
                        organisationId: this.organisationId,
                        modelVersion: this._savedMission?.modelVersion ?? 2,
                        declareNoRelevantNotam:
                            this.missionForm.controls.declareNoRelevantNotam
                                .value
                    })
                )
                .then(mission => {
                    this.setupGuideChecklistService.completeActivityTask(
                        SetupGuideActivityDto.StepIdentifier.CREATE_MISSION,
                        mission.id ?? null
                    );
                    return mission;
                });
        }
    }

    private prepareForSaving(): UpdateMissionCommand {
        // Validating mission crew. TODO this validation should be in a better place.
        const missionCrewValues = this.missionForm.controls.missionCrew.value;
        const missionCrew: MissionCrewDto[] =
            missionCrewValues != null
                ? missionCrewValues.filter(
                      pilot =>
                          pilot.personId !== null &&
                          pilot.missionRoleId !== null
                  )
                : [];

        // Strip out null rpa and equipment caused by adding an input row, but not selecting an RPA or equipment
        const missionRpaIds = this.missionForm.controls.craftIds.value.filter(
            id => id != null
        );
        const missionEquipmentIds =
            this.missionForm.controls.equipmentIds.value.filter(
                id => id != null
            );

        const rpicId = this.missionForm.controls.rpicId.value;
        if (rpicId != null) {
            missionCrew.push({
                missionRoleId: this.rpicRoleId,
                personId: rpicId
            });
        }

        // We have to do type conversions to pass the UI values to the service
        const value = this.missionForm.value;
        return {
            name: value.missionObjectivesGroup.name,
            missionObjectiveTypeId:
                value.missionObjectivesGroup.missionObjectiveTypeId,
            workgroupId: value.missionObjectivesGroup.workgroup?.id,
            missionObjective: value.missionObjectivesGroup.missionObjective,
            locationId: value.missionObjectivesGroup.location?.id,
            isDummy: value.missionObjectivesGroup.isDummy,
            missionDate: toTimestamp(value.missionDate, value.timeZone),
            timeZone: value.timeZone,
            missionTypeId: value.missionTypeId,
            missionEstimatedTime: value.missionEstimatedTime,
            craftIds: missionRpaIds,
            equipmentIds: missionEquipmentIds,
            missionWorkflowVersionId: value.missionWorkflowVersionId,
            additionalAuthorityIds: value.additionalAuthorityIds,
            maximumHeight: value.maximumHeight,
            crewNotes: value.crewNotes,
            missionCrew: missionCrew,
            emergencyContacts:
                this._savedMission.modelVersion === 1
                    ? value.emergencyContacts
                    : null,
            radioFrequencies:
                this._savedMission.modelVersion === 1
                    ? value.radioFrequencies
                    : null,
            missionContacts: value.missionEmergencyContacts,
            missionRadioFrequencies: value.missionRadioFrequencies,
            aerodromesOfInterest: value.aerodromesOfInterest.map(
                a => a.identifier
            ),
            visualLineOfSight: value.visualLineOfSight,
            timeOfDay: value.timeOfDay,
            formResponses:
                value.documentation != null
                    ? prepareResponses(value.documentation.formResponses)
                    : {},
            notams: value.notams.map(n => ({
                id: n.id,
                location: n.location,
                fir: n.fir
            }))
        };
    }

    /**
     * Resolve the register status for a person based on a concrete mission
     * @param savedMission
     * @param personIdList
     */
    private findPersonnelRegisterStatus(
        savedMission: DisplayableMissionDto,
        personIdList: number[]
    ): Observable<{ [personId: number]: PilotRegisterStatusUnion }> {
        const authId =
            savedMission.missionWorkflowVersion?.delegatedAuthority?.id;
        const time = savedMission.missionDate;

        if (authId == null || time == null) {
            return of(
                personIdList.reduce(
                    (acc, personId) => ({
                        ...acc,
                        [personId]: null
                    }),
                    {}
                )
            );
        }

        return this.organisationAuthorityService
            .findPersonnelRegisterStatuses(
                authId,
                personIdList,
                time,
                savedMission?.organisationId ?? this.organisationId
            )
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                map(results =>
                    results.reduce(
                        (acc, result) => ({
                            ...acc,
                            [result.entityId]: result.registerStatus
                        }),
                        {}
                    )
                )
            );
    }

    private findMissionRegisterStatusesForPerson(
        savedMission: DisplayableMissionDto,
        personIds: number[],
        rpaIds: number[]
    ) {
        const authorityIds =
            savedMission?.missionWorkflowVersion?.delegatedAuthority?.id;
        const at = toTimestamp(
            this.missionForm.controls.missionDate.value,
            this.missionForm.controls.timeZone.value
        );
        const timeOfDay = this.missionForm.controls.timeOfDay.value;
        const visualLineOfSight =
            this.missionForm.controls.visualLineOfSight.value;
        const managingOrganisationId =
            savedMission?.organisationId ?? this.organisationId;

        if (
            authorityIds == null ||
            at == null ||
            timeOfDay == null ||
            visualLineOfSight == null ||
            managingOrganisationId == null
        ) {
            return;
        }
        this.registerEntriesOnMissionLoadingSource.next(true);
        return this.organisationAuthorityService
            .findPersonnelRegisterStatusesForMission(
                [authorityIds],
                personIds,
                rpaIds,
                timeOfDay,
                visualLineOfSight,
                at,
                managingOrganisationId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => this.registerEntriesOnMissionSource.next(results),
                error => this.logging.error(error)
            )
            .add(() => this.registerEntriesOnMissionLoadingSource.next(false));
    }

    /**
     * Resolve the register status for an RPA based on a concrete mission
     * @param savedMission
     * @param rpaIdList
     */
    private findRpaRegisterStatus(
        savedMission: DisplayableMissionDto,
        rpaIdList: number[]
    ): Observable<{ [personId: number]: PilotRegisterStatusUnion }> {
        const authId =
            savedMission.missionWorkflowVersion?.delegatedAuthority?.id;
        const time = savedMission.missionDate;

        if (authId == null || time == null) {
            return of(
                rpaIdList.reduce(
                    (acc, personId) => ({
                        ...acc,
                        [personId]: null
                    }),
                    {}
                )
            );
        }

        return this.organisationAuthorityService
            .findRpaRegisterStatuses(
                authId,
                rpaIdList,
                time,
                savedMission?.organisationId ?? this.organisationId
            )
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                map(results =>
                    results.reduce(
                        (acc, result) => ({
                            ...acc,
                            [result.entityId]: result.registerStatus
                        }),
                        {}
                    )
                )
            );
    }

    addRpa(rpaId: number | null) {
        const missionCraft = this.missionForm.controls.craftIds;
        const newCraft = new FormControl<number>(rpaId, Validators.required);
        missionCraft.push(newCraft);
        // this.setupAvailableRpa();
        missionCraft.updateValueAndValidity();
        missionCraft.markAsDirty();
    }

    removeRpa(i: number) {
        const missionCraft = this.missionForm.controls.craftIds;
        missionCraft.removeAt(i);
    }

    addEquipment(equipmentId?: number) {
        const missionEquipment = this.missionForm.controls.equipmentIds;
        const newEquipment = new FormControl<number>(equipmentId);
        missionEquipment.push(newEquipment);
        // this.setupAvailableRpa();
        missionEquipment.updateValueAndValidity();
        missionEquipment.markAsDirty();
    }

    removeEquipment(ix: number) {
        const missionEquipment = this.missionForm.controls.equipmentIds;
        missionEquipment.removeAt(ix);
        if (missionEquipment.length === 0) {
            missionEquipment.setValidators(null);
            missionEquipment.updateValueAndValidity();
        }
    }

    addCrew() {
        const missionCrew = this.missionForm.controls.missionCrew;

        const crewValidator = (): ValidatorFn => {
            return (
                control: AbstractControl<MissionCrewDto>
            ): ValidationErrors | null => {
                if (
                    control.value.personId == null ||
                    control.value.missionRoleId == null
                ) {
                    return { invalidCrew: true };
                }
                return null;
            };
        };

        const newCrew = new FormControl<MissionCrewDto>(
            {
                personId: null,
                missionRoleId: null
            },
            [crewValidator()]
        );
        missionCrew.push(newCrew);
        missionCrew.updateValueAndValidity();
        missionCrew.markAsDirty();
    }

    removeCrew(ix: number) {
        const missionCrew = this.missionForm.controls.missionCrew;
        missionCrew.removeAt(ix);
        if (missionCrew.length === 0) {
            missionCrew.setValidators(null);
            missionCrew.updateValueAndValidity();
        }
    }
}
