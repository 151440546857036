import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { RpaTypeDto, rpaTypeSearch } from '@flyfreely-portal-ui/flyfreely';

/**
 * Provides an ng-select setup to format RPA types correctly, including searching.
 */
@Component({
    selector: 'rpa-type-selector',
    template: `
        <ng-select
            [items]="rpaTypeList"
            bindLabel="name"
            bindValue="id"
            [formControlName]="selectorFormControlName"
            placeholder="Select an RPA type"
            [searchFn]="rpaTypeSearch"
            [clearable]="clearable"
        >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <ng-container>
                    {{ item | formatRpaType }}
                    <span
                        *ngIf="item.organisationId != null"
                        class="status-block custom"
                        >Custom</span
                    >
                </ng-container>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <ng-container>
                    {{ item | formatRpaType }}
                    <span
                        *ngIf="item.organisationId != null"
                        class="status-block custom"
                        >Custom</span
                    >
                </ng-container>
            </ng-template>
        </ng-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective }
    ]
})
export class RpaTypeSelector {
    @Input()
    rpaTypeList: RpaTypeDto[];

    @Input()
    selectorFormControlName: string;

    @Input()
    clearable = true;

    rpaTypeSearch = rpaTypeSearch;
}
