import {
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    ViewChild
} from '@angular/core';
import {
    CreateLocationCommand,
    defaultTimezone,
    DO_NOTHING,
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LocationDetailsDto,
    LocationFeatureDto,
    LocationService,
    MissionService,
    OrganisationService,
    PersonsOrganisationDto,
    PreferencesService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import * as FileSaver from 'file-saver';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { GeometryCollection } from 'geojson';
import { AirspaceCheckService } from 'libs/airspace/src/lib/airspace-check';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { FullScreenService } from 'libs/fullscreen/src/lib/fullscreen.service';
import { FlyFreelyMapComponent } from 'libs/map/src/lib/flyfreely-map/flyfreely-map.component';
import { FeatureGroup } from 'libs/map/src/lib/interfaces';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Subject } from 'rxjs';
import { map as subjectMap, take, takeUntil } from 'rxjs/operators';
import {
    flightAreaOfFeatures,
    getFeatureGroups,
    getFileName
} from '../helpers';
import { LocationDialoguesService } from '../location-dialogues.service';
import { AirspaceCheckerParametersWithStartEnd } from '../location-edit-v2/airspace-checker/location-airspace-check.component';
import { LocationEditService } from '../location-edit.service';

const AIRSPACE_DISCLAIMER =
    'location-details-airspace-disclaimer-acknowledgement';

@Component({
    selector: 'location-details-dialogue',
    templateUrl: './location-details-dialogue.component.html',
    providers: [LocationEditService, AirspaceCheckService]
})
export class LocationDetailsDialogue {
    @Input() locationId: number;
    @Input() organisationId: number;

    location: LocationDetailsDto;

    organisation: PersonsOrganisationDto;

    features: FeatureGroup[];
    IMG_URL: string;

    // Airspace checker values
    flightArea: GeoJSON.Polygon;
    airspaceValue: AirspaceCheckerParametersWithStartEnd;

    // Airspace Disclaimer
    showAirspaceDisclaimer = false;

    private ngUnsubscribe$ = new Subject<void>();

    private workTracker = new WorkTracker();
    working: boolean = false;

    @ViewChild('map', { read: ElementRef, static: false })
    map: FlyFreelyMapComponent;
    @ViewChild('map', { read: ElementRef, static: false })
    mapElement: ElementRef;

    constructor(
        private modal: BsModalRef<LocationDetailsDialogue>,
        private locationService: LocationService,
        private organisationService: OrganisationService,
        private missionService: MissionService,
        private locationEditService: LocationEditService,
        private preferencesService: PreferencesService,
        private missionDialoguesService: MissionDialoguesService,
        private commonDialoguesService: CommonDialoguesService,
        constants: FlyFreelyConstants,
        // Seem to need to do this because the service is defined in this module
        @Inject(forwardRef(() => LocationDialoguesService))
        private locationDialoguesService: LocationDialoguesService,
        private fullScreenService: FullScreenService,
        private logging: FlyFreelyLoggingService
    ) {
        this.IMG_URL = constants.IMG_URL;
    }

    ngOnInit() {
        combineLatest([
            this.workTracker.observable,
            this.locationEditService.working$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([working, serviceWorking]) =>
                    (this.working = working || serviceWorking)
            );

        this.locationEditService.missionCreated$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.hide());

        this.refreshPermissions();
        this.refreshLocation();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPermissions() {
        this.organisationService
            .findByIdForUser(this.organisationId, this.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: organisation => {
                    this.organisation = organisation;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error checking airspace permissions: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    private refreshLocation() {
        const doneWorking = this.workTracker.createTracker();

        this.locationService
            .findLocation(this.locationId)
            .toPromise()
            .then(location => this.setLocation(location))
            .finally(() => doneWorking());
    }

    private setLocation(location: LocationDetailsDto) {
        this.location = location;

        const { features } = getFeatureGroups(location.features, 0);
        this.features = features;

        this.flightArea = pipe(
            flightAreaOfFeatures(location.features),
            map<LocationFeatureDto, GeoJSON.Polygon>(f =>
                f.geometry.type !== 'GeometryCollection'
                    ? <GeoJSON.Polygon>f.geometry
                    : <GeoJSON.Polygon>(
                          (<GeometryCollection>f.geometry).geometries.find(
                              g => g.type === 'Polygon'
                          )
                      )
            ),
            getOrElse(() => null)
        );
        this.showAirspaceDisclaimerDialogue();
    }

    showAirspaceDisclaimerDialogue() {
        const disclaimer =
            this.location?.airspaceJurisdiction?.airspaceDisclaimer ??
            this.map?.currentlySelectedJurisdiction?.airspaceDisclaimer;
        if (disclaimer == null || disclaimer.length == 0) {
            this.showAirspaceDisclaimer = false;
            return;
        }
        this.preferencesService
            .findPreferencesAsOption(AIRSPACE_DISCLAIMER, null)
            .pipe(
                subjectMap(p =>
                    getOrElse(() => ({
                        date: moment(new Date()).subtract(1, 'day').toDate()
                    }))(p)
                ),
                takeUntil(this.ngUnsubscribe$),
                take(1)
            )
            .subscribe({
                next: preferences => {
                    if (preferences == null) {
                        this.showAirspaceDisclaimer = true;
                        return;
                    }
                    const today = moment(new Date()).format('YYYY-MM-DD');
                    const lastViewed = moment(preferences.date).format(
                        'YYYY-MM-DD'
                    );
                    // Only show the disclaimer once per account
                    this.showAirspaceDisclaimer = moment(lastViewed).isBefore(
                        today
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            });
    }

    onAirspaceDisclaimerAcknowledged() {
        this.showAirspaceDisclaimer = false;
        this.preferencesService
            .updatePreferences(AIRSPACE_DISCLAIMER, null, { date: new Date() })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(DO_NOTHING)
            .add(this.workTracker.createTracker());
    }

    edit() {
        this.locationDialoguesService
            .editLocation(this.location.id, this.organisationId, true)
            .content.change.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(location => this.setLocation(location));
    }

    createMission() {
        const tz =
            this.airspaceValue.airspaceTimeZone ??
            this.location.timeZone ??
            defaultTimezone;

        const t = moment(
            moment(this.airspaceValue.airspaceDate ?? new Date()).format(
                'YYYY-MM-DD[T]HH:mm:ss'
            )
        ).tz(tz);

        const utc = t.toISOString();

        const createCommand: CreateLocationCommand = {
            name: this.location.name,
            features: this.location.features,
            organisationId: this.organisationId,
            type: CreateLocationCommand.Type.MISSION,
            derivedFromId: this.location.id
        };

        this.locationEditService.createMission(
            this.organisationId,
            this.airspaceValue,
            createCommand
        );
    }

    hide() {
        this.modal.hide();
    }

    deleteLocation() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Delete Location',
                'Deleting this location cannot be undone. Do you wish to continue?',
                'Delete',
                () =>
                    this.locationService
                        .deleteLocation(this.locationId)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .toPromise()
            )
            .then(
                () => {
                    this.logging.success('Location deleted');
                    this.hide();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error deleting location: ${error.message}`
                    )
            );
    }

    downloadKml() {
        const name = this.location.name ?? 'Location';
        this.locationService
            .downloadKmlFromFeatures({ name, features: this.location.features })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                download => {
                    const headers = download.headers;
                    const fileName =
                        getFileName(headers.get('content-disposition')) ??
                        'flyfreely.kml';

                    FileSaver.saveAs(download.body, fileName);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error downloading KML file: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    onFullscreenRequested() {
        this.fullScreenService.toggleFullScreen(this.mapElement);
    }
}
