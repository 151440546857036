<div
    [ngClass]="{ 'panel panel-default vertical-container': flightId == null }"
    style="overflow: hidden; margin-bottom: 10px"
>
    <!-- If no flightId, show panel heading with buttons -->
    <div class="panel-heading" *ngIf="flightId == null">
        <div class="horizontal-container text-centered">
            <span class="display-label">Unassigned Flight Logs</span>
        </div>
        <div class="horizontal-container">
            <!-- Attach logs button -->
            <button
                type="button"
                class="btn btn-pill btn-xs"
                (click)="attachLogFiles()"
                [disabled]="working || readonly"
            >
                Attach Logs
            </button>
            <!-- Create flights button if there are logs -->
            <button
                *ngIf="hasLogs"
                type="button"
                class="btn btn-pill btn-xs"
                [disabled]="working || readonly || mission.id == null"
                (click)="createFlightsFromLogs()"
            >
                Create Flights
            </button>
        </div>
    </div>

    <!-- Panel body for displaying flight logs with scrollable container -->
    <div
        [ngClass]="{
            'panel-body container-scroll modal-scrollbar': flightId == null
        }"
    >
        <!-- Assigned flight logs label if a flight is selected -->
        <div class="display-label" *ngIf="flightId != null">
            Assigned Flight Logs
        </div>

        <!-- Replacing sortablejs with Angular CDK drop list -->
        <!-- cdkDropList is used to enable drag-and-drop reordering -->
        <ng-container  *ngIf="flightLogs$ | async as flightLogs">
    
            <!-- Updated the cdkDropListDropped to use the template variable 'flightLogs' -->
            <div id="flightLogContainer{{log}}"
                cdkDropList
                (cdkDropListDropped)="drop($event, flightLogs)"  
                [cdkDropListData]="flightLogs" 
         
                [ngClass]="{
                    'well top-buffer': flightId != null
                }"
            >
        
                <!-- Using flightLogs (from async pipe) inside the *ngFor loop to iterate through the logs -->
                <div
                    *ngFor="
                        let log of flightLogs;
                        let i = index;
                        let first = first;
                        let last = last
                    " cdkDrag
                    [ngClass]="{
                        'top-buffer': !first
                    }"
                    [attr.data-id]="log.id"
                >
                    <div
                        class="btn btn-xs btn-pill"
                        style="cursor: default"
                        [ngStyle]="{
                            'white-space': flightId == null ? 'normal' : 'nowrap'
                        }"
                    >
                        <span [innerHtml]="log | formatFlightLog: log.rpa"></span>
        
                        <button
                            type="button"
                            class="btn btn-tertiary btn-sm"
                            [disabled]="working || readonly"
                            (click)="visualiseLog(log)"
                            tooltip="Visualise log file"
                            placement="right"
                        >
                            <span class="fal fa-map"></span>
                        </button>
        
                        <!-- Conditionally rendering 'Unassign' button if log.flightId is not null -->
                        <button
                            *ngIf="log.flightId != null"
                            type="button"
                            class="btn btn-link btn-sm"
                            [disabled]="working || readonly"
                            (click)="unassignFlightLog(log)"
                            tooltip="Unassign from flight"
                            placement="right"
                        >
                            Unassign
                        </button>
        
                        <div class="horizontal-container">
                            
                            <!-- Conditionally rendering 'Assign' button if log.flightId is null -->
                            <button
                                *ngIf="log.flightId == null"
                                type="button"
                                class="btn btn-link btn-xs"
                                [disabled]="working || readonly || log.rpa == null"
                                (click)="openPopover(pop)"
                                [popover]="popoverTemplate"
                                [popoverContext]="{ logId: log.id }"
                                popoverTitle="Select A Flight"
                                [tooltip]="log.rpa ? 'Assign log file to a flight' : 'Flight log needs to have an RPA assigned first'"
                                placement="right"
                            >
                                Assign
                            </button>
        
                            <!-- Conditionally rendering 'Remove' button if flightId is null -->
                            <button
                                *ngIf="flightId == null"
                                type="button"
                                class="btn btn-link btn-xs"
                                [disabled]="working || readonly"
                                (click)="unattachFlightLog(log)"
                                tooltip="Unattach log file"
                                placement="right"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Display message when there are no flight logs -->
                <div *ngIf="flightLogs.length === 0">No flight logs</div>
            </div>
        </ng-container>

        <!-- Loading template -->
        <ng-template #loading>
            <tr>
                <td colspan="4">Loading...</td>
            </tr>
        </ng-template>
    </div>
</div>

<!-- Popover for assigning a log to a flight -->
<ng-template #popoverTemplate let-logId="logId">
    <assign-to-flight-dialogue
        [historicalMission]="historicalMission"
        (assignToFlight)="assignLogFiles($event, logId)"
    >
    </assign-to-flight-dialogue>
</ng-template>
