<div
    *ngIf="printOptions != null && printOptions.length > 0"
    class="btn-group"
    dropdown
    [dropup]="true"
    style="margin-right: 0.5rem"
>
    <button
        id="button-container"
        dropdownToggle
        type="button"
        class="btn btn-default dropdown-toggle"
        aria-controls="dropdown-container"
        [disabled]="workTracker.observable | async"
    >
        Reports
        <span class="caret"></span>
    </button>
    <ul
        *dropdownMenu
        id="dropdown-container"
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-container"
    >
        <li role="menuitem" *ngFor="let report of printOptions">
            <a class="dropdown-item" (click)="report.print()">{{
                report.name
            }}</a>
        </li>
    </ul>
</div>
