import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';
import { AvailableReportDto } from '@flyfreely-portal-ui/flyfreely';
import { httpParamSerializer } from '../service.helpers';

@Injectable({
    providedIn: 'root'
})
export class PersonnelReportService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findAvailableListTemplates(
        reportEventIdentifier: string,
        organisationId: number,
        managingOrganisationId: number
    ) {
        return this.http.get<AvailableReportDto[]>(
            `${this.baseUrl}/reportsapi/personnel`,
            {
                params: httpParamSerializer({
                    reportEventIdentifier,
                    organisationId,
                    managingOrganisationId
                })
            }
        );
    }

    getPdfListUrl(
        reportTemplateId: number,
        organisationId: number,
        managingOrganisationId: number
    ) {
        const params = httpParamSerializer({
            organisationId,
            managingOrganisationId,
            format: 'pdf'
        }).toString();

        return `${this.baseUrl}/reportsapi/personnel/render/${reportTemplateId}?${params}`;
    }

    findAvailableTemplates(
        reportEventIdentifier: string,
        personId: number,
        managingOrganisationId: number
    ) {
        return this.http.get<AvailableReportDto[]>(
            `${this.baseUrl}/reportsapi/personnel/${personId}`,
            {
                params: httpParamSerializer({
                    reportEventIdentifier,
                    managingOrganisationId
                })
            }
        );
    }

    getHtmlReportUrl(
        reportTemplateId: number,
        personId: number,
        managingOrganisationId: number
    ) {
        const params = httpParamSerializer({
            managingOrganisationId
        }).toString();

        return `${this.baseUrl}/reportsapi/personnel/${personId}/render/${reportTemplateId}?${params}`;
    }

    getPdfReportUrl(
        reportTemplateId: number,
        personId: number,
        managingOrganisationId: number
    ) {
        const params = httpParamSerializer({
            managingOrganisationId,
            format: 'pdf'
        }).toString();

        return `${this.baseUrl}/reportsapi/personnel/${personId}/render/${reportTemplateId}?${params}`;
    }
}
