<!-- TODO: enhanced help name for component will need to change to shared-resources, but this may affect current help entries so will need to happen in phases -->
<enhanced-help componentName="shared-authorities" [compact]="true">
</enhanced-help>
<div *ngIf="children" class="container-fluid">
    <div *ngFor="let relation of children; let i = index" class="row">
        <div class="col-md-12">
            <organisation-logo
                style="height: 50px; width: 50px; float: right"
                class="relative-size"
                [showEdit]="false"
                [organisation]="relation.childOrganisation"
                [managingOrganisationId]="organisation.id"
            ></organisation-logo>
            <h3 class="section-heading">
                <span
                    >{{ relation.childOrganisation.name }}
                    <button
                        type="button"
                        class="btn btn-tertiary btn-delete"
                        tooltip="Remove sharing to this organisation"
                        placement="top"
                        (click)="removeOrganisation(relation)"
                    >
                        <span class="fal fa-trash-alt"></span></button
                ></span>
                <div></div>
            </h3>

            <!-- Ensure the current subscription actually has licences before allowing sharing -->
            <ng-container
                *ngIf="
                    relation &&
                    currentSubscription &&
                    currentSubscription.limits &&
                    currentSubscription.limits.length > 0
                "
            >
                <h5 [ngClass]="{ readonly: working }">Shared Licences</h5>
                <ng-container
                    *ngIf="
                        !relation.sharedSubscription ||
                        (editingLicence && editingLicence == relation.id)
                    "
                >
                    <label
                        class="control-label top-buffer"
                        *ngIf="editingLicence && editingLicence == relation.id"
                    >
                        Number of licences to share
                    </label>
                    <div class="input-group">
                        <input
                            name="maximumHeight"
                            type="number"
                            class="form-control"
                            placeholder="Select the number of licences to share with this organisation"
                            [formControl]="editLicences[i]"
                            [min]="findMinLicenceCount(relation)"
                            [max]="findMaxLicenceCount(relation)"
                            step="1"
                            [readonly]="
                                working ||
                                (editingLicence &&
                                    editingLicence != relation.id)
                            "
                        />
                        <span class="input-group-btn">
                            <button
                                *ngIf="!relation.sharedSubscription"
                                type="button"
                                class="btn btn-primary"
                                [disabled]="
                                    working ||
                                    !editLicences[i].valid ||
                                    editLicences[i].pristine ||
                                    editingLicence
                                "
                                (click)="addSharedLicences(relation, i)"
                            >
                                Share
                            </button>
                            <button
                                *ngIf="relation.sharedSubscription"
                                type="button"
                                class="btn btn-primary btn"
                                [disabled]="
                                    working ||
                                    !editLicences[i].valid ||
                                    editLicences[i].pristine ||
                                    (editingLicence &&
                                        editingLicence != relation.id)
                                "
                                (click)="editSharedLicences(relation, i)"
                            >
                                Update
                            </button>
                            <button
                                *ngIf="relation.sharedSubscription"
                                type="button"
                                class="btn btn-default btn"
                                [disabled]="working"
                                (click)="editingLicence = null"
                            >
                                Cancel
                            </button>
                        </span>
                    </div>
                </ng-container>
                <div
                    class="horizontal-container"
                    *ngIf="
                        !relation.sharedSubscription ||
                        (editingLicence && editingLicence == relation.id)
                    "
                >
                    <p
                        class="help-block"
                        *ngIf="
                            currentSubscription && currentSubscription.limits
                        "
                    >
                        <ng-container
                            *ngIf="currentSubscription.limits.length > 0"
                        >
                            {{
                                currentSubscription.limits[0]
                                    .availableLicenceCount
                            }}
                            of
                            {{ currentSubscription.limits[0].instances }}
                            licences available to share.
                        </ng-container>
                        <ng-container
                            *ngIf="currentSubscription.limits.length == 0"
                        >
                            unlimited licences available to share.
                        </ng-container>
                    </p>
                    <p
                        class="help-block"
                        *ngIf="
                            editingLicence &&
                            editingLicence == relation.id &&
                            relation.sharedSubscription &&
                            relation.sharedSubscription.limits &&
                            relation.sharedSubscription.limits.length > 0 &&
                            relation.sharedSubscription.limits[0].instances !=
                                relation.sharedSubscription.limits[0]
                                    .availableLicenceCount
                        "
                    >
                        &nbsp;{{ relation.childOrganisation?.name }} has already
                        assigned
                        {{
                            relation.sharedSubscription.limits[0].instances -
                                relation.sharedSubscription.limits[0]
                                    .availableLicenceCount
                        }}
                        licences >
                    </p>
                </div>
                <div
                    *ngIf="
                        relation.sharedSubscription &&
                        relation.sharedSubscription.limits &&
                        relation.sharedSubscription.limits.length > 0 &&
                        (!editingLicence || editingLicence != relation.id)
                    "
                    class="horizontal-container top-buffer"
                >
                    <span class="right-buffer">
                        Sharing
                        {{ relation.sharedSubscription.limits[0].instances }}
                        <span *ngIf="currentSubscription"
                            >of
                            {{ currentSubscription?.limits[0].instances }}</span
                        >
                        {{
                            relation.sharedSubscription.limits[0].instances ==
                                1 &&
                            currentSubscription?.limits[0].instances == null
                                ? 'licence'
                                : 'licences'
                        }}
                        ({{
                            relation.sharedSubscription.limits[0].instances -
                                relation.sharedSubscription.limits[0]
                                    .availableLicenceCount
                        }}
                        {{
                            relation.sharedSubscription.limits[0].instances -
                                relation.sharedSubscription.limits[0]
                                    .availableLicenceCount ==
                            1
                                ? 'has'
                                : 'have'
                        }}
                        been assigned)
                    </span>
                    <button
                        type="button"
                        class="btn btn-tertiary btn-delete"
                        tooltip="Edit the amount of shared licences"
                        placement="top"
                        [disabled]="editingLicence != null"
                        (click)="editRelationLicences(relation, i)"
                    >
                        <span class="fal fa-pencil-alt"></span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tertiary btn-delete"
                        tooltip="Stop sharing your subscription with this organisation"
                        placement="top"
                        [disabled]="editingLicence != null"
                        (click)="stopSharingLicences(relation)"
                    >
                        <span class="fal fa-trash-alt"></span>
                    </button>
                </div>
                <br />
            </ng-container>

            <h5 [ngClass]="{ readonly: working }">Shared Authorities</h5>
            <div class="input-group">
                <ng-select
                    [items]="addableAuthorities"
                    bindValue="id"
                    bindLabel="name"
                    appendTo="body"
                    [formControl]="addAuthority"
                    placeholder="Select authority to share with {{
                        relation.childOrganisation.name
                    }}"
                    [readonly]="working"
                    [clearable]="false"
                    (focus)="findAddableAuthorities(relation)"
                >
                    <ng-template
                        ng-option-tmp
                        ng-label-tmp
                        let-item="item"
                        let-index="index"
                    >
                        {{ item.name }}
                        <span
                            *ngIf="item.isPrimary"
                            class="status-block inverted custom"
                            >Primary</span
                        >
                    </ng-template>
                </ng-select>
                <span class="input-group-btn">
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="working || !addAuthority.valid"
                        (click)="shareAuthority(relation)"
                    >
                        Share
                    </button>
                </span>
            </div>

            <div
                *ngFor="
                    let authority of relation.sharedAuthorities
                        | sortAuthorities
                "
                class="top-buffer"
            >
                <a
                    (click)="showAuthority(authority.authorityType, authority)"
                    >{{ authority | formatAuthority }}</a
                >
                <button
                    type="button"
                    class="btn btn-tertiary btn-delete"
                    tooltip="Remove sharing of authority"
                    placement="top"
                    (click)="removeAuthority(relation, authority)"
                >
                    <span class="fal fa-trash-alt"></span>
                </button>

                <br />
            </div>
            <shared-rpa
                [organisation]="organisation"
                [relationship]="relation"
            ></shared-rpa>
            <hr />
            <shared-equipment
                [organisation]="organisation"
                [relationship]="relation"
            ></shared-equipment>
            <hr />
            <shared-battery-sets
                [organisation]="organisation"
                [relationship]="relation"
            ></shared-battery-sets>
            <hr />
        </div>
    </div>
    <h4 [ngClass]="{ readonly: working }">Add Child Organisation</h4>
    <div class="input-group">
        <ng-select
            [items]="additionalOrganisations"
            bindLabel="name"
            bindValue="id"
            appendTo="body"
            [formControl]="newChild"
            placeholder="Select organisation to add as child"
            [readonly]="working"
            [clearable]="false"
        >
        </ng-select>
        <span class="input-group-btn">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="working || !newChild.valid"
                (click)="addChildOrganisation()"
            >
                Add
            </button>
        </span>
    </div>
</div>
