import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { EnhancedHelpService } from './enhanced-help.service';
import { ScreenHelpDto } from '@flyfreely-portal-ui/flyfreely';
import { EnhancedHelpTreeService } from './enhanced-help-tree.service';

export const defaultKnowledgeBaseUrl = 'https://knowledge.flyfreely.io/';

@Injectable({
    providedIn: 'root'
})
export class KnowledgeBaseService {
    allLinksSubject = new ReplaySubject<ScreenHelpDto[]>();
    allLinks$ = this.allLinksSubject.asObservable();
    screenLookup: { [analyticsScreenName: string]: string };

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private enhancedHelpService: EnhancedHelpService,
        private enhancedHelpTreeService: EnhancedHelpTreeService
    ) {
        this.enhancedHelpService
            .findAllScreenHelp()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(links => {
                this.allLinksSubject.next(links);
            });
        this.screenLookup = this.enhancedHelpTreeService.screenTree.reduce(
            (acc, t) => ({
                ...acc,
                [t.analyticsIdentifier]: t.identifier
            }),
            {}
        );
    }

    ngOnDestroy() {
        this.allLinksSubject.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findKnowledgeBaseUrlByScreen(screen: string) {
        return this.allLinks$.pipe(
            map(links =>
                links.find(
                    link =>
                        link != null &&
                        link.screen === this.screenLookup[screen]
                )
            )
        );
    }
}
