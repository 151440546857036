import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BatteryListItemComponent } from './battery-list-item/battery-list-item.component';
import { BatterySetListItemComponent } from './battery-set-list-item/battery-set-list-item.component';
import { EquipmentListItemComponent } from './equipment-list-item/equipment-list-item.component';
import { RpaTypeSelector } from './form-components/rpa-type-selector.component';
import { EquipmentStatusBlockPipe } from './pipes/equipmentStatusBlock.pipe';
import { EquipmentStatusClassPipe } from './pipes/equipmentStatusClass.pipe';
import { FilterRpaModelsPipe } from './pipes/filterRpaModels';
import { FormatBatterySetPipe } from './pipes/formatBatterySet.pipe';
import { FormatEquipmentPipe } from './pipes/formatEquipment.pipe';
import { FormatEquipmentCategoryPipe } from './pipes/formatEquipmentCategory.pipe';
import { FormatResourceStatusPipe } from './pipes/formatResourceStatus.pipe';
import { FormatResourceStatusIconPipe } from './pipes/formatResourceStatusIcon.pipe';
import { FormatRpaPipe } from './pipes/formatRpa.pipe';
import { FormatRpaCategoryPipe } from './pipes/formatRpaCategory.pipe';
import { FormatRpaTypePipe } from './pipes/formatRpaType.pipe';
import { RpaStatusBlockPipe } from './pipes/rpaStatusBlock.pipe';
import { RpaStatusClassPipe } from './pipes/rpaStatusClass.pipe';
import { RpaListItemComponent } from './rpa-list-item/rpa-list-item.component';
import { StatusIconComponent } from './status-icons/status-icon.component';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        // ngx-bootstrap
        TooltipModule
    ],
    declarations: [
        FormatResourceStatusPipe,
        FormatResourceStatusIconPipe,
        FormatRpaPipe,
        FormatRpaCategoryPipe,
        FormatRpaTypePipe,
        FormatBatterySetPipe,
        FormatEquipmentPipe,
        RpaStatusClassPipe,
        EquipmentStatusClassPipe,
        FormatEquipmentCategoryPipe,
        EquipmentStatusBlockPipe,
        RpaStatusBlockPipe,
        StatusIconComponent,
        FilterRpaModelsPipe,
        EquipmentListItemComponent,
        RpaListItemComponent,
        BatteryListItemComponent,
        BatterySetListItemComponent,
        RpaTypeSelector
    ],
    providers: [
        FormatResourceStatusPipe,
        FormatResourceStatusIconPipe,
        FormatRpaPipe,
        FormatRpaCategoryPipe,
        FormatRpaTypePipe,
        FormatBatterySetPipe,
        RpaStatusClassPipe,
        EquipmentStatusClassPipe,
        FormatEquipmentCategoryPipe,
        EquipmentStatusBlockPipe,
        RpaStatusBlockPipe,
        FilterRpaModelsPipe
    ],
    exports: [
        FormatResourceStatusPipe,
        FormatResourceStatusIconPipe,
        FormatRpaPipe,
        FormatRpaCategoryPipe,
        FormatRpaTypePipe,
        FormatBatterySetPipe,
        FormatEquipmentPipe,
        RpaStatusClassPipe,
        EquipmentStatusClassPipe,
        FormatEquipmentCategoryPipe,
        StatusIconComponent,
        FilterRpaModelsPipe,
        EquipmentListItemComponent,
        RpaListItemComponent,
        BatteryListItemComponent,
        BatterySetListItemComponent,
        EquipmentStatusBlockPipe,
        RpaStatusBlockPipe,
        RpaTypeSelector
    ]
})
export class ResourceUiModule {}

export {
    EquipmentStatusBlockPipe,
    EquipmentStatusClassPipe,
    FilterRpaModelsPipe,
    FormatBatterySetPipe,
    FormatEquipmentCategoryPipe,
    FormatResourceStatusIconPipe,
    FormatResourceStatusPipe,
    FormatRpaCategoryPipe,
    FormatRpaPipe,
    FormatRpaTypePipe,
    RpaStatusBlockPipe,
    RpaStatusClassPipe,
    RpaTypeSelector
};
