<div class="container-fluid top-buffer">
    <div [formGroup]="form">
        <div
            formGroupName="maintenanceActivities"
            *ngIf="overallStatus == 'DRAFT_ACTIVITY' && isMaintenanceController"
        >
            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label class="display-label"> Status </label>
                </div>
                <div class="col-xs-6">Draft</div>
                <div class="col-xs-3"></div>
            </div>

            <div
                *ngIf="
                    canUseMaintenanceTasks &&
                    maintenanceSchedule &&
                    scheduledTasks
                "
                class="form-group input-content"
            >
                <div class="col-xs-3 text-right">
                    <button
                        class="btn-circle btn-link"
                        type="button"
                        (click)="showAddScheduledMaintenance()"
                        [disabled]="!canAddScheduledMaintenance || working"
                        placement="top"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Scheduled Tasks
                    </label>
                </div>
                <div class="col-xs-9">
                    <maintenance-schedule-tasks-edit
                        [tasks]="scheduledTasks"
                        [maintenanceSchedule]="maintenanceSchedule"
                        [activityNumber]="activityNumber"
                        [allActivities]="allActivities"
                        (maintenanceTaskAdded)="
                            canAddScheduledMaintenance = true
                        "
                        (editMaintenanceTask)="
                            canAddScheduledMaintenance = false
                        "
                    ></maintenance-schedule-tasks-edit>
                </div>
            </div>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Description of Required Maintenance
                        <field-validation-indicator
                            [control]="
                                maintenanceActivities.controls.description
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <textarea
                        class="form-control"
                        formControlName="description"
                        rows="3"
                        [readonly]="working"
                    >
                    </textarea>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="description"> </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Assigned Maintenance Person
                        <field-validation-indicator
                            [control]="
                                maintenanceActivities.controls.maintainerId
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <ng-select
                        name="personnel"
                        formControlName="maintainerId"
                        bindValue="id"
                        [searchFn]="personSearch"
                        placeholder="Select the person to complete the repair"
                        [items]="personnel$ | async"
                        [readonly]="working"
                        [clearable]="true"
                        appendTo="body"
                        data-cy="repairPersonSelect"
                    >
                        <ng-template ng-option-tmp let-item="item">
                            {{ item | formatPerson: true }}
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            {{ item | formatPerson: true }}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="maintainerId"> </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        or External Maintenance Organisation
                        <field-validation-indicator
                            [control]="
                                maintenanceActivities.controls
                                    .maintenanceOrganisation
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <textarea
                        class="form-control"
                        formControlName="maintenanceOrganisation"
                        rows="2"
                        [readonly]="working"
                    >
                    </textarea>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="maintenanceOrganisation">
            </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Scheduled Date
                        <field-validation-indicator
                            [control]="
                                maintenanceActivities.controls.scheduledDate
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <div class="input-group">
                        <input
                            class="form-control"
                            type="text"
                            #datepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="scheduledDate"
                            placement="bottom"
                            [readonly]="working"
                            [isDisabled]="working"
                        />
                        <span
                            *ngIf="!working"
                            class="input-group-addon btn-default"
                            (click)="datepicker.toggle()"
                            [attr.aria-expanded]="datepicker.isOpen"
                        >
                            <i class="glyphicon glyphicon-calendar"></i>
                        </span>
                    </div>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="scheduledDate"> </enhanced-help>
        </div>

        <div
            formGroupName="maintenanceDetailsGroup"
            *ngIf="
                overallStatus == 'ASSIGNED_ACTIVITY' &&
                (isMaintenancePerson ||
                    (isMaintenanceController && isExternalMaintenance) ||
                    canEditActivity)
            "
        >
            <h5 class="section-heading">Maintenance Details</h5>

            <div
                *ngIf="canUseMaintenanceTasks"
                class="form-group input-content"
            >
                <div class="col-xs-3 text-right">
                    <button
                        class="btn-circle btn-link"
                        type="button"
                        (click)="showAddComponentForm()"
                        [disabled]="!canAddMaintenanceTask || working"
                        placement="top"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Maintenance Tasks
                    </label>
                </div>
                <div class="col-xs-9">
                    <maintenance-task-list
                        [formArray]="maintenanceDetailsGroup.controls.tasks"
                        [activityNumber]="activityNumber"
                        (saveMaintenanceTask)="canAddMaintenanceTask = true"
                        (editMaintenanceTask)="canAddMaintenanceTask = false"
                    >
                    </maintenance-task-list>
                </div>
            </div>

            <enhanced-help componentName="tasks"> </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Maintenance Performed
                        <field-validation-indicator
                            [control]="
                                maintenanceDetailsGroup.controls
                                    .maintenancePerformed
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <textarea
                        class="form-control"
                        formControlName="maintenancePerformed"
                        rows="2"
                        [readonly]="working"
                    >
                    </textarea>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="maintenancePerformed">
            </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Maintenance Remarks
                        <field-validation-indicator
                            [control]="
                                maintenanceDetailsGroup.controls
                                    .maintenanceRemarks
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <textarea
                        class="form-control"
                        formControlName="maintenanceRemarks"
                        rows="2"
                        [readonly]="working"
                    >
                    </textarea>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="maintenanceRemarks"> </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Activity Start Date
                        <field-validation-indicator
                            [control]="
                                maintenanceDetailsGroup.controls.startDate
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <div class="input-group">
                        <input
                            class="form-control"
                            type="text"
                            #startDatepicker="bsDatepicker"
                            bsDatepicker
                            formControlName="startDate"
                            placement="bottom"
                            [readonly]="working"
                            [isDisabled]="working"
                        />
                        <span
                            *ngIf="!working"
                            class="input-group-addon btn-default"
                            (click)="startDatepicker.toggle()"
                            [attr.aria-expanded]="startDatepicker.isOpen"
                        >
                            <i class="glyphicon glyphicon-calendar"></i>
                        </span>
                    </div>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="startDate"> </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Activity End Time
                        <field-validation-indicator
                            [control]="maintenanceDetailsGroup.controls.endTime"
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <div class="horizontal-container pad-children">
                        <div class="input-group">
                            <input
                                class="form-control"
                                type="text"
                                #endDatepicker="bsDatepicker"
                                bsDatepicker
                                formControlName="endTime"
                                placement="bottom"
                                [minDate]="
                                    maintenanceDetailsGroup.controls.startDate
                                        .value
                                "
                                [readonly]="working"
                                [isDisabled]="working"
                            />
                            <span
                                *ngIf="!working"
                                class="input-group-addon btn-default"
                                (click)="endDatepicker.toggle()"
                                [attr.aria-expanded]="endDatepicker.isOpen"
                            >
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>
                        </div>
                        <timepicker
                            formControlName="endTime"
                            [hourStep]="1"
                            [minuteStep]="1"
                            [showMeridian]="false"
                            [showSpinners]="false"
                            [readonlyInput]="working"
                        >
                        </timepicker>
                        <span>{{ organisation.timeZone }}</span>
                        <button
                            type="button"
                            (click)="completedNow()"
                            class="btn btn-default btn-sm"
                        >
                            Now
                        </button>
                    </div>
                    <p class="help-block">
                        This time is used to order the activity against missions
                        to calculate the configuration version
                    </p>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="endDate"> </enhanced-help>

            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label class="display-label"> Activity Attachments </label>
                </div>
                <div class="col-xs-9">
                    <attachment-list
                        [attachmentsHandler]="attachmentsHandler"
                        [allowUploads]="true"
                        [allowDelete]="true"
                        [allowEdit]="true"
                        [parentPromiseTracker]="workTracker"
                        permissions
                    >
                    </attachment-list>
                </div>
            </div>
            <enhanced-help componentName="activityAttachments"> </enhanced-help>
        </div>
        <div
            formGroupName="maintenanceControllerGroup"
            *ngIf="
                overallStatus == 'SUBMITTED_ACTIVITY' && isMaintenanceController
            "
        >
            <h5 class="section-heading">Maintenance Controller Notes</h5>
            <div class="form-group input-content">
                <div class="col-xs-3 text-right">
                    <label
                        class="display-label"
                        [ngClass]="{ readonly: working }"
                    >
                        Additional Maintenance Controller Notes
                        <field-validation-indicator
                            [control]="
                                maintenanceControllerGroup.controls
                                    .maintenanceControllerNotes
                            "
                            [noun]="'field'"
                            [placement]="'right'"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div class="col-xs-6">
                    <textarea
                        class="form-control"
                        formControlName="maintenanceControllerNotes"
                        rows="3"
                        [readonly]="working"
                    >
                    </textarea>
                </div>
                <div class="col-xs-3"></div>
            </div>

            <enhanced-help componentName="maintenanceControllerNotes">
            </enhanced-help>
        </div>
    </div>
</div>
