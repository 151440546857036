/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from 'geojson';


/**
 * The feature areas of the location
 */
export interface LocationFeatureDto { 
    /**
     * The name of this feature
     */
    name: string;
    /**
     * What this feature represents
     */
    type: LocationFeatureDto.Type;
    geometry: Geometry;
    properties?: any;
}
export namespace LocationFeatureDto {
    export type Type = 'FLIGHT_AREA' | 'TAKEOFF' | 'LANDING' | 'TAKEOFF_LANDING' | 'RP' | 'OBSERVER' | 'DANGER' | 'NO_FLY' | 'AREA_OF_INTEREST' | 'POINT_OF_INTEREST' | 'FLIGHT_PATH_LINE' | 'GENERAL_LINE' | 'OFFSET_FLIGHT_AREA' | 'OFFSET_DANGER_AREA' | 'OFFSET_NO_FLY_AREA';
    export const Type = {
        FLIGHT_AREA: 'FLIGHT_AREA' as Type,
        TAKEOFF: 'TAKEOFF' as Type,
        LANDING: 'LANDING' as Type,
        TAKEOFF_LANDING: 'TAKEOFF_LANDING' as Type,
        RP: 'RP' as Type,
        OBSERVER: 'OBSERVER' as Type,
        DANGER: 'DANGER' as Type,
        NO_FLY: 'NO_FLY' as Type,
        AREA_OF_INTEREST: 'AREA_OF_INTEREST' as Type,
        POINT_OF_INTEREST: 'POINT_OF_INTEREST' as Type,
        FLIGHT_PATH_LINE: 'FLIGHT_PATH_LINE' as Type,
        GENERAL_LINE: 'GENERAL_LINE' as Type,
        OFFSET_FLIGHT_AREA: 'OFFSET_FLIGHT_AREA' as Type,
        OFFSET_DANGER_AREA: 'OFFSET_DANGER_AREA' as Type,
        OFFSET_NO_FLY_AREA: 'OFFSET_NO_FLY_AREA' as Type
    };
}


