import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedSrcService {
    private cachedImage$: { [key: string]: Observable<SafeUrl> } = {};
    constructor(
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer
    ) {}

    getImageUrl(src: string, fallbackUrl: string): Observable<SafeUrl> {
        if (this.cachedImage$[src]) {
            return this.cachedImage$[src];
        }

        // Create an observable to fetch the image
        const observable = this.httpClient
            .get(src, { responseType: 'blob' })
            .pipe(
                map(response => URL.createObjectURL(response)),
                map(url => this.domSanitizer.bypassSecurityTrustUrl(url)),
                shareReplay(1),
                catchError(() => {
                    // If an error occurs, do not cache the error result
                    return of(fallbackUrl);
                })
            );

        // Cache the observable, but only if it successfully emits a value other than fallbackUrl
        this.cachedImage$[src] = observable.pipe(
            map(url => {
                if (url !== fallbackUrl) {
                    return url;
                } else {
                    delete this.cachedImage$[src]; // Remove the cached observable if it emitted the fallbackUrl
                    throw new Error( // re-throw the error
                        'Image load failed and fallbackUrl returned'
                    );
                }
            }),
            catchError(() => of(fallbackUrl)) // This catchError is to handle the re-throwing error
        );

        return this.cachedImage$[src];
    }
}
