import { Component, ElementRef, OnInit } from '@angular/core';
import {
    ControlContainer,
    FormBuilder,
    FormGroupDirective
} from '@angular/forms';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'flight-preferences',
    templateUrl: './flight-preferences.component.html',
    styleUrls: ['./flight-preferences.component.scss'],
    viewProviders: [
        { provide: ControlContainer, useExisting: FormGroupDirective }
    ]
})
export class FlightPreferencesComponent implements OnInit {
    private ngUnsubscribe$: Subject<void> = new Subject();
    private workTracker = new WorkTracker();
    public working = false;
    constructor(
        private obsds: OnboardingData,
        private el: ElementRef,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    previousScreen() {
        this.obsds.backToPersonalDetails();
    }

    nextScreen(flightPreference: any) {
        if (flightPreference === 'flying') {
            this.obsds.personalFlightPreferencesCompleted(true);
        } else {
            this.obsds.personalFlightPreferencesCompleted(false);
        }
    }
}
