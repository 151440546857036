import { Injectable } from '@angular/core';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class NotificationSettingsService {
    private workingSource = new BehaviorSubject<boolean>(false);
    working$ = this.workingSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workingSource.complete();
    }

    createWorkTracker() {
        return this.workTracker.createTracker();
    }
}
