<ng-container screenAnalytics="location-edit-kml">
    <div class="modal-header">
        <h3 class="modal-title">KML Upload Feature Setup</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <enhanced-help class="no-min-height" componentName="screen">
        </enhanced-help>
        <div *ngIf="areas != null">
            <h5 class="section-heading">
                <span>Areas</span>
                <div></div>
            </h5>
            <div class="horizontal-container fill top-buffer bottom-buffer">
                <div class="fcol-6">
                    <label class="display-label">Name</label>
                </div>
                <div class="fcol-1"></div>
                <div class="fcol-5">
                    <label class="display-label">Type</label>
                </div>
            </div>
            <ng-container *ngFor="let feature of areas.features; let i = index">
                <div class="horizontal-container fill top-buffer">
                    <input
                        class="form-control fcol-6"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="feature.name"
                        placeholder="Feature name"
                    />
                    <div class="fcol-1"></div>
                    <ng-select
                        class="fcol-5"
                        [items]="areaFeatureTypes"
                        bindValue="value"
                        [(ngModel)]="feature.featureType"
                        appendTo="body"
                        [clearable]="false"
                        placeholder="Select an feature type"
                    >
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                        >
                            {{ item.name }}
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            {{ formatFeatureType(feature.featureType) }}
                        </ng-template>
                    </ng-select>
                </div>
            </ng-container>
        </div>
        <div *ngIf="markers != null">
            <h5 class="section-heading">
                <span>Markers</span>
                <div></div>
            </h5>
            <div class="horizontal-container fill top-buffer bottom-buffer">
                <div class="fcol-6">
                    <label class="display-label">Name</label>
                </div>
                <div class="fcol-1"></div>
                <div class="fcol-5">
                    <label class="display-label">Type</label>
                </div>
            </div>
            <ng-container
                *ngFor="let feature of markers.features; let i = index"
            >
                <div class="horizontal-container fill top-buffer">
                    <input
                        class="form-control fcol-6"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="feature.name"
                        placeholder="Feature name"
                    />
                    <div class="fcol-1"></div>
                    <ng-select
                        class="fcol-5"
                        [items]="markerFeatureTypes"
                        bindValue="value"
                        [(ngModel)]="feature.featureType"
                        appendTo="body"
                        [clearable]="false"
                        placeholder="Select an feature type"
                    >
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                        >
                            {{ item.name }}
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            {{ formatFeatureType(feature.featureType) }}
                        </ng-template>
                    </ng-select>
                </div>
            </ng-container>
        </div>
        <div *ngIf="lines != null">
            <h5 class="section-heading">
                <span>Lines</span>
                <div></div>
            </h5>
            <div class="horizontal-container fill top-buffer bottom-buffer">
                <div class="fcol-6">
                    <label class="display-label">Name</label>
                </div>
                <div class="fcol-1"></div>
                <div class="fcol-5">
                    <label class="display-label">Type</label>
                </div>
            </div>
            <ng-container *ngFor="let feature of lines.features; let i = index">
                <div class="horizontal-container fill top-buffer">
                    <input
                        class="form-control fcol-6"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="feature.name"
                        placeholder="Feature name"
                    />
                    <div class="fcol-1"></div>
                    <ng-select
                        class="fcol-5"
                        [items]="lineFeatureTypes"
                        bindValue="value"
                        [(ngModel)]="feature.featureType"
                        appendTo="body"
                        [clearable]="false"
                        placeholder="Select an feature type"
                    >
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                        >
                            {{ item.name }}
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            {{ formatFeatureType(feature.featureType) }}
                        </ng-template>
                    </ng-select>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="import()">
            Done
        </button>
    </div>
</ng-container>
