import { Component, Input, Optional, Host, SkipSelf } from '@angular/core';
import { FormControl, ControlContainer, AbstractControl } from '@angular/forms';
import { ValidationService } from './validation.service';

@Component({
    selector: 'validation-messages',
    template: `
        <div class="text-danger" *ngIf="errorMessage !== null">
            {{ errorMessage }}
        </div>
    `
})
export class ValidationMessagesComponent {
    /**
     * A direct reference to the control to be driven off
     */
    @Input() control: AbstractControl;
    /**
     * Try to automatically locate the control using its name
     */
    @Input() validatorFormControlName: string;

    @Input() customErrorMessage: (code: string) => string;

    /**
     * The control used within this component
     */
    _control: AbstractControl;

    constructor(
        @Optional() @Host() @SkipSelf() private parent: ControlContainer
    ) {}

    ngOnInit() {
        this.syncControlVariables;
    }

    syncControlVariables() {
        if (this.control != null) {
            this._control = this.control;
        } else if (this.validatorFormControlName != null) {
            this._control = <FormControl>(
                this.parent.control.get(this.validatorFormControlName)
            );
        } else {
            throw new Error('Either control or formControlName must be set');
        }
    }

    get errorMessage() {
        if (this.control != null && this._control == null) {
            this.syncControlVariables();
        }
        if (
            this._control == null ||
            this._control.errors == null ||
            this._control.pristine
        ) {
            return null;
        }

        for (const propertyName of Object.keys(this._control.errors)) {
            if (this.customErrorMessage != null) {
                const customMessage = this.customErrorMessage(
                    this._control.errors[propertyName]
                );
                if (customMessage != null) {
                    return customMessage;
                }
            }
            return ValidationService.getValidatorErrorMessage(
                propertyName,
                this._control.errors[propertyName]
            );
        }

        return null;
    }
}
