import { Component, HostBinding, Input } from '@angular/core';
import {
    AirspaceAuthorisationMessage,
    Message
} from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'airspace-authorisation-messaging',
    templateUrl: './airspace-authorisation-messaging.component.html',
    styleUrls: ['./styles.scss']
})
export class AirspaceAuthorisationMessagingComponent {
    @Input() messages: Message[] | AirspaceAuthorisationMessage[];

    @HostBinding('class.fill')
    private fill = true;
}
