import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * The check component sets its value to 'true' or '' depending on
 * its checked status.
 */
@Component({
    selector: 'formly-datetime',
    template: `
        <div class="input-group">
            <input
                [formControl]="formControl"
                class="form-control"
                [formlyAttributes]="field"
                #datepicker="bsDatepicker"
                bsDatepicker
            />
            <span
                class="input-group-addon btn-default"
                (click)="datepicker.toggle()"
                [attr.aria-expanded]="datepicker.isOpen"
            >
                <i
                    class="glyphicon glyphicon-calendar"
                    style="cursor: pointer"
                ></i>
            </span>
            <timepicker
                [formlyAttributes]="field"
                [formControl]="formControl"
                [hourStep]="1"
                [minuteStep]="1"
                [showMeridian]="false"
                [showSpinners]="false"
            >
            </timepicker>
        </div>
    `
})
export class FormlyDateTime extends FieldType {
    private ngUnsubscribe$ = new Subject<void>();

    constructor() {
        super();
    }

    ngOnInit() {
        this.setupControlSync();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupControlSync() {
        this.formControl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                this.formControl.setValue(value, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });
            });
    }
}
