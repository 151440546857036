<ng-container screenAnalytics="organisation-admin-form-list">
    <div>
        <enhanced-help
            componentName="overview"
            [compact]="true"
        ></enhanced-help>
        <div class="container-scroll">
            <entity-list
                [canAdd]="canEdit"
                [items]="forms"
                [working]="working"
                [canClone]="true"
                [hasCatalogue]="true"
                catalogueName="FlyFreely Form Library"
                type="Form"
                (create)="createForm()"
                (edit)="formDetails($event)"
                (archive)="archiveForm($event)"
                (unarchive)="unarchiveForm($event)"
                (clone)="cloneForm($event)"
                (browseCatalogue)="browseCatalogue()"
            >
            </entity-list>
        </div>
    </div>
</ng-container>
