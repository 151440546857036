<enhanced-help
    componentName="conditionChecks"
    [compact]="true"
    class="bottom-buffer"
>
</enhanced-help>
<div class="fill">
    <ng-container *ngIf="mode === 'AUTHORITY'">
        <div class="input-group bottom-buffer sticky-heading">
            <ng-select
                [items]="candidates"
                bindLabel="name"
                [groupBy]="issuers"
                [(ngModel)]="selectedAuthorityType"
                [clearable]="false"
                appendTo="body"
                (click)="$event.stopPropagation()"
            >
            </ng-select>

            <span class="input-group-btn">
                <button
                    class="btn btn-primary"
                    [disabled]="selectedAuthorityType == null || working"
                    (click)="addAuthorityTypeCheck()"
                >
                    Add
                </button>
            </span>
        </div>
        <div class="side-sheet-container fill">
            <div 
                cdkDropList
                (cdkDropListDropped)="dropAuthorityChecks($event)"
                [cdkDropListData]="authorityChecks"
            >
            <div
                *ngFor="let check of authorityChecks"
                cdkDrag
                class="horizontal-container"
            >
                    <span
                        class="fas fa-grip-vertical row-hover grab-handle" cdkDragHandle
                    ></span>
                    <div class="fill">
                        {{ check.name
                        }}{{ check.inCandidates ? '' : '(No longer addable)' }}
                    </div>
                    <button
                        type="button"
                        class="btn btn-tertiary btn-delete"
                        (click)="removeCheck($event, check)"
                        [disabled]="working"
                    >
                        <span class="fal fa-trash-alt"></span>
                    </button>
                </div>
                <ngx-loading [show]="working"></ngx-loading>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === 'MANUAL'">
        <div
            *ngIf="isEditingAction == null"
            class="sticky-heading"
            @collapseOnLeave
            @expandOnEnter
        >
            <div class="vertical-container">
                <label>Check Name</label>
                <input
                    class="form-control"
                    [(ngModel)]="newManualCheckName"
                    [ngModelOptions]="{
                        standalone: true
                    }"
                    placeholder="Name this action"
                    (click)="$event.stopPropagation()"
                />
                <label>Check Description</label>
                <textarea
                    class="form-control"
                    [(ngModel)]="newManualCheckDescription"
                    [ngModelOptions]="{
                        standalone: true
                    }"
                    placeholder="Describe this action"
                    (click)="$event.stopPropagation()"
                ></textarea>
            </div>
            <div class="fill top-buffer">
                <button
                    class="btn btn-primary btn-sm pull-right"
                    [disabled]="newManualCheckName == null || working"
                    (click)="addManualCheck()"
                >
                    Add
                </button>
            </div>
        </div>
        <div class="side-sheet-container fill top-buffer">
            <div 
                cdkDropList
                (cdkDropListDropped)="dropManualChecks($event)"
                [cdkDropListData]="manualChecks"
            >
                <div *ngFor="let action of manualChecks; let i = index" cdkDrag>
                    <ng-container
                        *ngIf="isEditingAction == null || isEditingAction === i"
                    >
                        <div class="horizontal-container">
                            <span
                                *ngIf="isEditingAction !== i"
                                class="fas fa-grip-vertical row-hover grab-handle" cdkDragHandle
                            ></span>
                            <div
                                class="fill name"
                                [ngClass]="{ disabled: !action.enabled }"
                            >
                                {{ action.name }}
                            </div>
                            <button
                                *ngIf="isEditingAction !== i"
                                type="button"
                                class="btn btn-tertiary"
                                [disabled]="
                                    isAddingAction || isEditingAction || working
                                "
                                (click)="editManualCheck(action, i)"
                                [disabled]="working"
                            >
                                <span class="fal fa-pencil-alt"></span>
                            </button>
                        </div>
                        <div
                            *ngIf="isEditingAction === i"
                            @expandOnEnter
                            @collapseOnLeave
                        >
                            <div class="vertical-container">
                                <label>Action Name</label>
                                <input
                                    class="form-control"
                                    [(ngModel)]="manualCheckName"
                                    [ngModelOptions]="{
                                        standalone: true
                                    }"
                                    placeholder="Name this action"
                                    (click)="$event.stopPropagation()"
                                />
                                <label>Action Description</label>
                                <textarea
                                    class="form-control"
                                    [(ngModel)]="manualCheckDescription"
                                    [ngModelOptions]="{
                                        standalone: true
                                    }"
                                    placeholder="Describe this action"
                                    (click)="$event.stopPropagation()"
                                ></textarea>
                                <div class="checkbox">
                                    <label>
                                        <input
                                            class="checkbox"
                                            [(ngModel)]="manualCheckEnabled"
                                            type="checkbox"
                                        />
                                        <span
                                            class="cr"
                                            style="background-color: white"
                                        >
                                            <i
                                                class="cr-icon glyphicon glyphicon-ok"
                                            ></i>
                                        </span>
                                        Enabled
                                    </label>
                                </div>
                            </div>
                            <div class="fill">
                                <button
                                    class="btn btn-default btn-sm pull-right"
                                    (click)="stopEditingManualCheck()"
                                >
                                    Cancel
                                </button>
                                <button
                                    class="btn btn-primary btn-sm pull-right right-buffer"
                                    [disabled]="
                                        manualCheckName == null || working
                                    "
                                    (click)="updateManualCheck(action, i)"
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ngx-loading [show]="working"></ngx-loading>
            </div>
        </div>
    </ng-container>
</div>
