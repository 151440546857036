<enhanced-help componentName="organisation-default-settings" [compact]="true">
</enhanced-help>
<div class="vertical-container container-scroll fill relative">
    <form [formGroup]="settingsForm">
        <div class="form-group top-buffer">
            <label>Default Operation Type</label>
            <ng-select
                [items]="availableOperationTypes"
                bindLabel="name"
                bindValue="id"
                placeholder="The default mission operation type for new missions"
                formControlName="defaultOperationTypeId"
                appendTo="body"
                [disabled]="working"
            >
            </ng-select>
        </div>
        <div class="form-group top-buffer">
            <label>Default Mission Workflow</label>
            <ng-select
                [items]="availableMissionWorkflows"
                bindLabel="workflowName"
                bindValue="workflowId"
                placeholder="The default mission workflow to use on new missions"
                formControlName="defaultMissionWorkflowId"
                appendTo="body"
                [disabled]="working"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span
                        [ngClass]="{
                            error: item.expired
                        }"
                    >
                        {{ item.workflowName
                        }}{{ item.expired ? ' (expired)' : '' }}
                    </span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-index="index">
                    <span
                        [ngClass]="{
                            error: item.expired
                        }"
                    >
                        {{ item.workflowName
                        }}{{ item.expired ? ' (expired)' : '' }}
                    </span>
                </ng-template>
            </ng-select>
        </div>
        <div class="form-group top-buffer">
            <label>Default Maximum Height (ft)</label>
            <input
                type="number"
                class="form-control"
                formControlName="defaultMaximumHeight"
            />
        </div>
        <div *ngIf="hasInstantMissions" class="form-group top-buffer">
            <label>Instant Mission Workflow</label>
            <ng-select
                [items]="availableInstantMissionWorkflows"
                bindLabel="workflowName"
                bindValue="workflowId"
                placeholder="The default mission workflow to use on instant missions"
                formControlName="instantMissionWorkflowId"
                appendTo="body"
                [disabled]="working"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span
                        [ngClass]="{
                            error: item.expired
                        }"
                    >
                        {{ item.workflowName
                        }}{{ item.expired ? ' (expired)' : '' }}
                    </span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-index="index">
                    <span
                        *ngIf="invalidInstantWorkflow == null"
                        [ngClass]="{
                            error: item.expired
                        }"
                    >
                        {{ item.workflowName
                        }}{{ item.expired ? ' (expired)' : '' }}
                    </span>
                    <span
                        *ngIf="invalidInstantWorkflow != null"
                        [ngClass]="{
                            error: invalidInstantWorkflow.expired
                        }"
                    >
                        {{ invalidInstantWorkflow.workflowName
                        }}{{
                            invalidInstantWorkflow.expired ? ' (expired)' : ''
                        }}
                    </span>
                </ng-template>
            </ng-select>
        </div>
        <div
            *ngIf="hasInstantMissions && invalidInstantWorkflow != null"
            class="alert alert-danger"
        >
            {{
                invalidInstantWorkflow.workflowName != null
                    ? invalidInstantWorkflow.workflowName
                    : 'The selected workflow'
            }}
            is not a valid instant mission workflow because it requires an
            approval. Please update with a workflow from the dropdown list.
        </div>
    </form>
    <ngx-loading [show]="working"></ngx-loading>
</div>
<div class="action-container">
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="settingsForm.invalid || settingsForm.pristine || working"
        (click)="updateSettings()"
    >
        Save
    </button>
</div>
