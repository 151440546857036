import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlyFreelyError, OnboardingService, UserService } from '@flyfreely-portal-ui/flyfreely';
import { mergeMap } from 'rxjs';

/**
 * This components will handle the connection of a user the demo organisation as a read only user.
 */
@Component({
    selector: 'signup-to-demo',
    templateUrl: './signup-to-demo.component.html',
    styleUrls: ['./signup-to-demo.component.scss']
})
export class SignupToDemoComponent implements OnInit {
    state: 'JOINING' | 'SUCCESS' | 'FAILURE' = 'JOINING';

    constructor(
        private onboardingService: OnboardingService,
        private userService: UserService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.onboardingService
            .findDemoOrganisations()
            .pipe(
                mergeMap(organisations => {
                    if (organisations.length === 0) {
                        throw new FlyFreelyError(
                            'No demo organisations available'
                        );
                    }
                    return this.onboardingService.joinDemoOrganisation(
                        organisations[0].value
                    );
                })
            )
            .subscribe({
                next: organisation => {
                    this.state = 'SUCCESS';
                    this.userService.refreshUsersOrganisations().add(() =>
                        this.router.navigate(['home'], {
                            queryParams: { organisationId: organisation.id }
                        })
                    );
                },
                error: e => {
                    this.state = 'FAILURE';
                    this.state = 'SUCCESS';
                    this.router.navigate(['/']);
                }
            });
    }
}
