import {
    AfterViewInit,
    Directive,
    Injectable,
    Input,
    OnDestroy
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class IntersectionSpyService {
    private visibilitySubj = new BehaviorSubject<boolean>(false);

    visibility$ = this.visibilitySubj.asObservable();

    setVisibility(isVisible: boolean) {
        this.visibilitySubj.next(isVisible);
    }
}

@Directive({
    selector: '[intersectionSpy]',
    providers: [IntersectionSpyService]
})
export class IntersectionSpyDirective implements AfterViewInit, OnDestroy {
    @Input() spyElement: HTMLElement;

    private observer: IntersectionObserver;

    constructor(public spyService: IntersectionSpyService) {}

    ngAfterViewInit(): void {
        console.log('this.spyElement', this.spyElement);
        this.observer = new IntersectionObserver(
            entries => {
                for (const entry of entries) {
                    const isVisible =
                        entry.isIntersecting &&
                        entry.intersectionRect.height + 20 >= 100;
                    this.spyService.setVisibility(isVisible);
                }
            },
            {
                threshold: [0, 0.5, 1]
            }
        );
        if (this.spyElement) {
            this.observer.observe(this.spyElement);
        }
    }

    ngOnDestroy(): void {
        if (this.observer && this.spyElement) {
            this.observer.unobserve(this.spyElement);
            this.observer.disconnect();
        }
    }
}
