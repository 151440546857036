import {
    Component,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DisplayableMaintenanceTask } from '../maintenance-request.service';
import { WorkTracker } from '@flyfreely-portal-ui/flyfreely';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
    ResourceSchematicComponentDto,
    MaintenanceTaskTypeDto,
    ComponentTypeDto,
    ResourceComponentInServiceTime
} from '@flyfreely-portal-ui/flyfreely';

const replaceTaskNames = ['Replace'];

@Component({
    selector: 'maintenance-task-edit',
    templateUrl: './maintenance-task-edit.component.html'
})
export class MaintenanceTaskEdit {
    formGroup: FormGroup;
    @Input() components: ResourceSchematicComponentDto[];
    @Input() taskTypes: MaintenanceTaskTypeDto[];
    @Input() selectedComponent: DisplayableMaintenanceTask;
    @Input() isRequired: boolean = false;
    @Input() inServiceComponents: ResourceComponentInServiceTime[];

    @Output() getAttachmentUrl = new EventEmitter<number>();
    @Output() done = new EventEmitter<DisplayableMaintenanceTask>();
    @Output() cancel = new EventEmitter<void>();

    hasFirmwareVersion: boolean;
    hasSerialNumber: boolean;
    isReplacing: boolean;

    working: boolean = false;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(private changeDetector: ChangeDetectorRef) {
        this.formGroup = new FormGroup({
            componentId: new FormControl(undefined, Validators.required),
            componentName: new FormControl(undefined),
            notes: new FormControl(undefined),
            taskTypeId: new FormControl(undefined, Validators.required),
            taskTypeName: new FormControl(undefined),
            serialNumber: new FormControl(undefined),
            firmwareVersion: new FormControl(undefined)
        });
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                this.changeDetector.detectChanges();
            });

        this.formGroup.controls.taskTypeId.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                const task = this.taskTypes.find(t => t.id === value);
                this.isReplacing = replaceTaskNames.includes(task.name);
                if (this.isReplacing) {
                    this.formGroup.controls.serialNumber.setValidators(
                        Validators.required
                    );
                    this.formGroup.controls.firmwareVersion.setValidators(
                        Validators.required
                    );
                    this.formGroup.updateValueAndValidity();
                    this.changeDetector.markForCheck();
                } else {
                    this.formGroup.controls.serialNumber.setValidators(null);
                    this.formGroup.controls.firmwareVersion.setValidators(null);
                    this.formGroup.updateValueAndValidity();
                    this.changeDetector.markForCheck();
                }
            });

        this.formGroup.controls.componentId.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                const component = this.components.find(
                    c => c.id === this.formGroup.controls.componentId.value
                );
                const componentDetails = this.inServiceComponents?.find(
                    c => c.id === this.formGroup.controls.componentId.value
                );
                this.formGroup.patchValue({
                    serialNumber: componentDetails?.serialNumber ?? undefined,
                    firmwareVersion:
                        componentDetails?.firmwareVersion ?? undefined
                });
                this.hasSerialNumber = component.hasSerialNumber;
                this.hasFirmwareVersion = component.hasFirmwareVersion;
                this.getAttachmentUrl.emit(value);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectedComponent != null) {
            const component = this.components.find(
                c => c.id === this.selectedComponent.componentId
            );
            this.hasSerialNumber = component.hasSerialNumber;
            this.hasFirmwareVersion = component.hasFirmwareVersion;
            const task = this.taskTypes.find(
                t => t.id === this.selectedComponent.taskTypeId
            );
            this.isReplacing = replaceTaskNames.includes(task.name);
            this.formGroup.patchValue({
                componentId: this.selectedComponent.componentId,
                componentName: this.selectedComponent.componentName,
                notes: this.selectedComponent.notes,
                taskTypeId: this.selectedComponent.taskTypeId,
                taskTypeName: this.selectedComponent.taskTypeName,
                firmwareVersion: this.selectedComponent.firmwareVersion,
                serialNumber: this.selectedComponent.serialNumber
            });
        } else {
            this.formGroup.reset();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    saveComponent() {
        const values = this.formGroup.value;
        const task: DisplayableMaintenanceTask = {
            required: values.required ?? false,
            completed: values.completed ?? false,
            componentId: values.componentId,
            componentName: this.components.find(
                c => c.id === values.componentId
            ).designation,
            notes: values.notes,
            taskTypeId: values.taskTypeId,
            taskTypeName: this.taskTypes.find(t => t.id === values.taskTypeId)
                .name,
            firmwareVersion: this.components.find(
                c => c.id === values.componentId
            ).hasFirmwareVersion
                ? values.firmwareVersion ?? ''
                : null,
            serialNumber: this.components.find(c => c.id === values.componentId)
                .hasSerialNumber
                ? values.serialNumber ?? ''
                : null
        };
        this.done.emit(task);
    }

    findComponentName() {
        const value = this.formGroup.value;
        const component = this.components.find(c => c.id === value.componentId);
        return `${component.componentType.name} ${component.designation}`;
    }

    findTaskTypeName() {
        const value = this.formGroup.value;
        return this.taskTypes.find(t => t.id === value.taskTypeId).name;
    }

    findComponentIcon() {
        const value = this.formGroup.value;
        const component = this.components.find(c => c.id === value.componentId);
        if (
            component.componentType.iconFamily !==
                ComponentTypeDto.IconFamily.FONT_AWESOME ||
            component.componentType.icon == null
        ) {
            return null;
        }
        const icon = `right-buffer fal fa-${component.componentType.icon}`;
        return icon;
    }

    findTaskIcon() {
        const value = this.formGroup.value;
        const taskType = this.taskTypes.find(t => t.id === value.taskTypeId);
        if (
            taskType.iconFamily !== ComponentTypeDto.IconFamily.FONT_AWESOME ||
            taskType.icon == null
        ) {
            return null;
        }
        const icon = `right-buffer fal fa-${taskType.icon}`;
        return icon;
    }

    hideForm() {
        this.cancel.emit();
    }
}
