import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxLoadingModule } from 'ngx-loading';
import { ApiKeyCreationDialogue } from './api-key-confirmation-dialogue/api-key-confirmation-dialogue.component';
import { ApiKeysDialoguesService } from './api-keys-dialogues.service';
import { CreateApiKeyForm } from './create-api-key-form/create-api-key-form.component';

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        SharedUiModule,
        NgxLoadingModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        ButtonsModule,
        FlyFreelyTableModule,
        ModalModule.forChild(),
        EnhancedHelpModule,
        AnalyticsModule
    ],
    providers: [ApiKeysDialoguesService],
    declarations: [CreateApiKeyForm, ApiKeyCreationDialogue],
    exports: [CreateApiKeyForm, ApiKeyCreationDialogue]
})
export class ApiKeysModule {}
