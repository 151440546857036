import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AttachmentHandler,
    BatteryTypeDto,
    CreateRpaTypeCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OtherSpecificationsDto,
    PerformanceSpecificationsDto,
    PhysicalSpecificationsDto,
    RpaTypeDto,
    RpaTypesService,
    UpdateRpaTypeCommand,
    WorkTracker,
    rpaTypeFields
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatRpaTypePipe } from '@flyfreely-portal-ui/resource-ui';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'rpa-type-edit',
    templateUrl: './rpa-type-edit.component.html'
})
export class RpaTypeEditDialogue {
    @Input() rpaTypeId: number;
    @Input() organisationId: number;

    rpaType: RpaTypeDto;
    rpaName: string;
    otherSpecifications: OtherSpecificationsDto;
    performanceSpecifications: PerformanceSpecificationsDto;
    physicalSpecifications: PhysicalSpecificationsDto;
    availableBatteries: BatteryTypeDto[];
    associatedBatteries: BatteryTypeDto[];

    attachmentsHandler: AttachmentHandler;
    manualMessage: any;

    isLocked: boolean;

    rpaTypeForm: FormGroup;
    basicInformationFields: FormlyFieldConfig[];
    physicalSpecificationsFields: FormlyFieldConfig[];
    performanceSpecificationsFields: FormlyFieldConfig[];
    otherSpecificationsFields: FormlyFieldConfig[];
    options: FormlyFormOptions;

    availableColumns: TableColumn[];
    tableConfig: TableConfig;
    selectedColumns: string[];

    currentBattery: number;

    working: boolean = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef,
        modalOptions: ModalOptions,
        private logging: FlyFreelyLoggingService,
        private rpaTypesService: RpaTypesService,
        private commonDialoguesService: CommonDialoguesService,
        private formatRpaTypePipe: FormatRpaTypePipe
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.rpaTypeForm.dirty) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            } else {
                return Promise.resolve();
            }
        };

        this.availableColumns = [
            {
                name: 'Battery Make',
                value: 'make',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                name: 'Battery Model',
                value: 'model',
                searchable: false,
                selectable: false,
                defaultSelection: true
            }
        ];
        this.selectedColumns = null;
        this.tableConfig = {
            actions: [
                {
                    action: (item: any) => this.removeBatteryType(item),
                    icon: 'fal fa-trash-alt',
                    tooltip: 'Remove battery type'
                }
            ],
            limit: 25
        };
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.rpaTypeForm = new FormGroup({
            basicInformation: new FormGroup({}),
            physicalSpecifications: new FormGroup({}),
            performanceSpecifications: new FormGroup({}),
            otherSpecifications: new FormGroup({})
        });

        if (this.rpaTypeId != null) {
            this.loadRpaType(this.rpaTypeId);
            this.attachmentsHandler = this.rpaTypesService.attachmentHandler(
                this.rpaTypeId
            );
        } else {
            this.setRpaType(null);
        }
        this.manualMessage = {};
    }

    ngOnDestroy() {
        if (this.attachmentsHandler != null) {
            this.attachmentsHandler.destroy();
        }
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    loadRpaType(rpaTypeId: number) {
        this.rpaTypesService
            .findRpaType(rpaTypeId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                rpa => {
                    this.setRpaType(rpa);
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            )
            .add(this.workTracker.createTracker());

        this.refreshBatteryTypes();
    }

    refreshBatteryTypes() {
        /* forkJoin(
            this.batteryTypeService.find(this.organisationId),
            this.rpaTypesService.findCompatibleBatteryTypes(
                this.rpaTypeId
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([availableBatteries, associatedBatteries]) => {
                this.associatedBatteries = associatedBatteries;
                this.availableBatteries = availableBatteries;
            })
            .add(this.workTracker.createTracker()); */
    }

    addBattery() {
        /* const createCommand: CreateRpaTypeBatteryTypeCommand = {
            batteryTypeId: this.currentBattery
        };
        this.rpaTypesService
            .createCompatibleBatteryTypes(this.rpaTypeId, createCommand)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.refreshBatteryTypes();
                    this.logging.success('Succesfully added battery type');
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error,error.message);
                }
            )
            .add(this.workTracker.createTracker()); */
    }

    removeBatteryType(item: any) {
        /* this.rpaTypesService
            .deleteCompatibleBatteryType(this.rpaTypeId, item.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.refreshBatteryTypes();
                    this.logging.success('Succesfully removed battery type');
                },
                (error: FlyFreelyError) => {
                    this.logging.error(error,error.message);
                }
            )
            .add(this.workTracker.createTracker()); */
    }

    setRpaType(rpaType: RpaTypeDto) {
        if (rpaType) {
            this.isLocked = rpaType.organisationId == null;
            this.rpaType = rpaType;
            this.rpaName = this.formatRpaTypePipe.transform(rpaType);
            this.performanceSpecifications = rpaType.performanceSpecifications
                ? rpaType.performanceSpecifications
                : {};
            this.physicalSpecifications = rpaType.physicalSpecifications
                ? rpaType.physicalSpecifications
                : {};
            this.otherSpecifications = rpaType.otherSpecifications
                ? rpaType.otherSpecifications
                : {};
        } else {
            this.isLocked = false;
            this.rpaType = {
                id: null,
                rpaCategory: null,
                compatibleBatteryTypeIdList: [],
                compatibleBatteryTypeRequirements: {}
            };
            this.performanceSpecifications = {};
            this.physicalSpecifications = {};
            this.otherSpecifications = {};
        }
        this.setupFields();
    }

    setupFields() {
        const fields = rpaTypeFields(this.isLocked);
        this.basicInformationFields = fields.fields;
        this.physicalSpecificationsFields = fields.physicalSpecificationFields;
        this.performanceSpecificationsFields =
            fields.performanceSpecificationFields;
        this.otherSpecificationsFields = fields.otherSpecificationFields;
    }

    isCurrentType(rpaType: RpaTypeDto.CraftType) {
        return rpaType === this.rpaType.craftType ? 'selected' : '';
    }

    isNewRpaType() {
        return !this.rpaType || !this.rpaType.id;
    }

    save() {
        const toSave: UpdateRpaTypeCommand = {
            make: this.rpaType.make,
            model: this.rpaType.model,
            craftType: this.rpaType.craftType,
            propulsionSystem: this.rpaType.propulsionSystem,
            performanceSpecifications: this.performanceSpecifications,
            physicalSpecifications: this.physicalSpecifications,
            otherSpecifications: this.otherSpecifications
        };

        if (this.isNewRpaType()) {
            const toCreate: CreateRpaTypeCommand = {
                ...toSave,
                organisationId: this.organisationId
            };
            this.rpaTypesService
                .createRpaType(toCreate)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    savedRpa => {
                        this.logging.success(
                            'Successfully created new RPA Model'
                        );
                        this.rpaTypeForm.markAsPristine();
                        this.cancel();
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(error, error.message);
                    }
                )
                .add(this.workTracker.createTracker());
        } else {
            this.rpaTypesService
                .updateRpaType(this.rpaType.id, toSave)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    result => {
                        this.logging.success('Successfully updated RPA Model');
                        this.rpaTypeForm.markAsPristine();
                        this.cancel();
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(error, error.message);
                    }
                )
                .add(this.workTracker.createTracker());
        }
    }
    cancel() {
        this.modal.hide();
    }
}
