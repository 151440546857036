<div
    class="sidebar"
    style="overflow: visible"
    (mouseover)="showToggleButton()"
    (mouseout)="hideToggleButton()"
    screenAnalytics="workspace-sidebar"
    [screenAnalyticsHideShow]="false"
>
    <div
        class="horizontal-container notification-button-container"
    >
        <button
            type="button"
            class="btn btn-default notification-bell"
            [tooltip]="
                showAlertBellTooltip
                    ? numberOfNew > 0 && !showActivityStream
                        ? 'New Notifications'
                        : showActivityStream
                        ? 'Close notifications'
                        : 'Open notifications'
                    : ''
            "
            placement="right"
            [ngClass]="{ 'notification-bell-active': newNotification }"
            (click)="toggleActivityStream()"
            [@tada]="newNotification == true"
        >
            <span class="fa fa-bell"></span>
        </button>
    </div>
    <div class="btn-navbar-toggle">
        <button
            type="button"
            (click)="toggleSidebar()"
            [ngClass]="{
                visible: showButton,
                'sidebar-collapsed': isSideBarCollapsed
            }"
            [@fadeIn]="hovering"
            (@fadeIn.done)="setShowButton(true)"
            [@fadeOut]="!hovering"
            (@fadeOut.done)="setShowButton(false)"
        >
            <span class="fa fa-chevron-left" *ngIf="!isSideBarCollapsed"></span>
            <span
                class="fa fa-chevron-right"
                style="margin-left: 3px"
                *ngIf="isSideBarCollapsed"
            ></span>
        </button>
        <div
            class="navbar-button-trigger"
            (mouseover)="showToggleButton()"
            (mouseout)="hideToggleButton()"
        ></div>
    </div>

    <nav
        id="navbar"
        class="navbar navbar-default"
        [ngClass]="{
            collapsed: isSideBarCollapsed,
            'activity-stream-open': showActivityStream
        }"
    >
        <div class="">
            <organisation-logo
                [organisation]="currentOrganisation.organisation"
                [showEdit]="!isSideBarCollapsed"
            >
            </organisation-logo>
        </div>
        <ng-select
            *ngIf="!isSideBarCollapsed && organisations != null"
            [ngModel]="currentOrganisation.organisation"
            [items]="organisations"
            bindLabel="name"
            appendTo="body"
            [clearable]="false"
            (change)="onOrganisationChanged($event)"
            (open)="onOrganisationDropdownOpened()"
            tooltip="Switch Organisation"
            placement="right"
        >
        </ng-select>

        <button
            *ngIf="
                !isSideBarCollapsed &&
                currentOrganisation.type === 'organisation_loaded' &&
                currentOrganisation.organisation.personalOrganisation
            "
            type="button"
            class="btn btn-primary btn-sm relative"
            angulartics2On="click"
            angularticsCategory="account"
            angularticsAction="create-commercial-start"
            [disabled]="!canCreateOrganisation"
            (click)="showCreateCommercialOrganisation()"
        >
            Create Organisation
            <span
                class="overlay"
                [tooltip]="createOrganisationTooltip"
                placement="right"
            ></span>
        </button>

        <div
            *ngIf="!isSideBarCollapsed"
            style="justify-content: center"
            mdcFormField
            tooltip="Show or hide dummy missions and resources"
            placement="right"
        >
            <label class="no-margin" mdcFormFieldLabel>Show Dummy Data</label>
            <div mdcSwitch>
                <div mdcSwitchThumb>
                    <input
                        type="checkbox"
                        mdcSwitchInput
                        [ngModel]="preferencesService.showDummyData$ | async"
                        (change)="toggleDummyData()"
                    />
                </div>
            </div>
        </div>
        <hr />
        <div class="sidebar-options">
            <router-outlet name="sidebar"></router-outlet>
            <div
                *ngIf="showMoreFeatures && hasShowMoreFeatures"
                class="horizontal-container top-buffer flex-centered"
            >
                <button
                    type="button"
                    class="btn btn-golden"
                    (click)="showMoreFeaturesDialogue()"
                >
                    More features
                </button>
            </div>

            <a
                *ngIf="isSideBarCollapsed"
                class="btn btn-default btn-sm"
                style="color: black"
                knowledgeBase="dashboard"
                tooltip="Knowledge Base"
                placement="right"
            >
                <span class="fas fa-question-circle"></span>
            </a>
            <div *ngIf="!isSideBarCollapsed" class="vertical-container">
                <button
                    *ngIf="isImpersonating"
                    type="button"
                    class="btn btn-golden btn-sm"
                    (click)="stopImpersonating()"
                >
                    Stop Impersonating
                </button>
            </div>
            <div class="fill"></div>
        </div>
        <div class="sidebar-bottom-options">
            <a
                *ngIf="!isSideBarCollapsed"
                class="btn btn-default btn-sm"
                style="color: black; width: 100%"
                knowledgeBase="dashboard"
            >
                <span class="fas fa-question-circle"></span> Knowledge Base
            </a>
            <div
                *ngIf="isSystemAdmin"
                style="justify-content: center"
                mdcFormField
            >
                <label class="no-margin" mdcFormFieldLabel>Edit Mode</label>
                <div mdcSwitch>
                    <div mdcSwitchThumb>
                        <input
                            type="checkbox"
                            mdcSwitchInput
                            [ngModel]="showDynamicHelpEdit"
                            (change)="toggleDynamicHelpEdit()"
                            [disabled]="working"
                        />
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <activity-stream
        *ngIf="
            showActivityStream &&
            currentOrganisation.organisation != null
        "
        [organisation]="currentOrganisation.organisation"
    ></activity-stream>
</div>
<div
    class="notification-banner-container"
    [ngClass]="{
        'both-bars-open': !isSideBarCollapsed && showActivityStream,
        'both-bars-collapsed': isSideBarCollapsed && !showActivityStream,
        'sidebar-collapsed': isSideBarCollapsed && showActivityStream
    }"
>
    <div
        *ngIf="showNotificationBanner"
        class="notification-banner"
        [@slideInLeftOnEnter]
        [@zoomOutLeftOnLeave]
    >
        New Notifications
    </div>
</div>
<mini-video-player></mini-video-player>
