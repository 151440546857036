import { Injectable, NgZone, Optional } from '@angular/core';
import {
    AirspaceJurisdictionDto,
    FEATURE_MAP_MEASUREMENT,
    FlyFreelyConstants,
    hasFeatureFlag,
    MapStyleService,
    notNullish,
    TypedActiveMapStyleDto,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon';
import { feature, featureCollection, point } from '@turf/helpers';
import { intersect } from '@turf/intersect';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { MultiPolygon, Polygon } from 'geojson';
import { Map } from 'mapbox-gl';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { MapModes } from './constants';
import {
    ActiveInteractionChangedMapEvent,
    MapDrawCreatedEvent,
    MapDrawDeletedEvent,
    MapDrawModeChangeEvent,
    MapDrawSelectionChangeEvent,
    MapDrawUpdatedEvent,
    MapEvent,
    MapEventType,
    MapInteractionState,
    MapLoadedMapEvent,
    MapMouseDownEvent,
    MouseClickMapEvent,
    MouseMoveMapEvent,
    ToolbarButton,
    ToolbarButtonClickedMapEvent
} from './events';
import { MapMoveEndEvent } from './events/map-move-end-event';
import { MapTouchDownEvent } from './events/map-touch-down-event';
import { MapTouchTapEvent } from './events/map-touch-tap-event';
import { MapZoomEndEvent } from './events/map-zoom-end-event';
import { asPolygon, idListsDiffer } from './flyfreely-map/helpers';

export const DEFAULT_BASE_STYLE = {
    name: 'Satellite',
    url: 'mapbox://styles/mapbox/satellite-streets-v11'
};

export interface AirspaceSource {
    sourceUrls: { id: string; url: string }[];
}

@Injectable()
export class MapService {
    _map: Map;
    _draw: any;

    set map(_map: Map) {
        this._map = _map;
        this.setupMap();
    }

    set draw(draw: any) {
        this._draw = draw;
    }

    get draw() {
        return this._draw;
    }

    mapEvent$ = new Subject<MapEvent>();
    private mapStyleSource = new ReplaySubject<TypedActiveMapStyleDto>(1);
    mapStyle$ = this.mapStyleSource.asObservable();

    // private selectedJurisdictionSource = new BehaviorSubject<
    //     AirspaceJurisdictionDto
    // >(null);
    // /**
    //  * The selected jurisdiction for the map. This could be manually set, or defaulted automatically
    //  */
    // selectedJurisdiction$ = this.selectedJurisdictionSource.asObservable();

    /**
     * This jurisdiction list overrides the list from the config
     */
    private overrideJurisdictionList?: AirspaceJurisdictionDto[];

    private configJurisdictionList?: AirspaceJurisdictionDto[];

    private visibleJurisdictionsSource = new BehaviorSubject<{
        visible: AirspaceJurisdictionDto[];
        selected?: AirspaceJurisdictionDto;
    }>({ visible: [], selected: null });
    /**
     * The jurisdictions visible in the viewport
     */
    visibleJurisdictions$ = this.visibleJurisdictionsSource.asObservable();

    private availableJurisdictionsSource = new BehaviorSubject<
        AirspaceJurisdictionDto[]
    >(undefined);
    /**
     * The available jurisdictions
     */
    availableJurisdictions$ = this.availableJurisdictionsSource
        .asObservable()
        .pipe(notNullish(), distinctUntilChanged());

    private availableToolsSource = new ReplaySubject<{
        hasMeasurementTools: boolean;
    }>(1);
    availableTools$ = this.availableToolsSource.asObservable();

    /**
     * Initial centre location tracked to allow faster initialisation
     */
    initialCentre?: mapboxgl.LngLat;

    private mapDefaultConfigSource = new ReplaySubject<TypedActiveMapStyleDto>(
        1
    );
    mapDefaultConfig$ = this.mapDefaultConfigSource.asObservable();

    private ngUnsubscribe$ = new Subject<void>();

    private _activeInteraction: MapInteractionState;

    get activeInteraction() {
        return this._activeInteraction;
    }

    constructor(
        private constants: FlyFreelyConstants,
        private zone: NgZone,
        private mapStyleService: MapStyleService,
        @Optional() private userService: UserService
    ) {}

    handleKeyUp(event: KeyboardEvent) {
        if (
            this._draw &&
            (event.key === 'Delete' || event.key === 'Backspace')
        ) {
            switch (this._draw.getMode()) {
                case MapModes.DRAW_RADIUS_SELECT:
                case MapModes.DRAW_SINGLE_LINE_POINT_SELECT:
                case MapModes.DRAW_SINGLE_LINE_SELECT:
                case MapModes.DRAW_AREA_LINE_SELECT:
                    // this should be handled out-of-the-box in the selection modes,
                    // as we override from the SIMPLE_SELECT but that's not working.
                    // also, the onKeyUp event in a custom mode is not triggered for the delete button
                    this.deleteSelection();
                    break;
                case MapModes.DRAW_LINE_STRING:
                case MapModes.DRAW_POLYGON:
                case MapModes.DRAW_AREA_LINE:
                    this.deleteLastPoint();
                    break;
                case MapModes.DIRECT_SELECT:
                    this.deleteSelectedPoint();
                    break;
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();

        this.mapStyleSource.complete();
        this.availableToolsSource.complete();
        this.mapDefaultConfigSource.complete();
        this.availableJurisdictionsSource.complete();
        this.visibleJurisdictionsSource.complete();
    }

    /**
     * Sets the organisation to configure the map for. This triggers all of the style loading,
     * feature selection, etc.
     * @param organisationId the organisation to load the map for
     */
    setupForOrganisation(organisationId: number) {
        if (organisationId == null) {
            this.availableToolsSource.next({
                hasMeasurementTools: false
            });
            this.mapDefaultConfigSource.next({
                dataLayers: [],
                jurisdictions: [],
                baseStyles: [DEFAULT_BASE_STYLE]
            });
            return;
        }
        this.mapStyleService
            .findDefaultMapStyle(organisationId)
            .pipe(take(1), takeUntil(this.ngUnsubscribe$))
            .subscribe(config => {
                this.setConfig(config);
            });

        if (this.userService != null) {
            this.availableToolsSource.next({
                hasMeasurementTools: pipe(
                    this.userService.findOrganisationForUser(organisationId),
                    map(organisation =>
                        hasFeatureFlag(organisation, FEATURE_MAP_MEASUREMENT)
                    ),
                    getOrElse(() => false)
                )
            });
        } else {
            this.availableToolsSource.next({
                hasMeasurementTools: false
            });
        }
    }

    /**
     * Sets the map config to the provided value. Initial emitting of the config will occur when the dynamic-data service
     * is ready and updates its values. See setActiveConfig.
     */
    setConfig(config: TypedActiveMapStyleDto) {
        // This function should only be passed source configs, so updating the default config here allows manual configs
        if (config.baseStyles.length === 0) {
            this.mapDefaultConfigSource.next({
                ...config,
                baseStyles: [DEFAULT_BASE_STYLE]
            });
        } else {
            this.mapDefaultConfigSource.next(config);
        }

        this.configJurisdictionList =
            config.jurisdictions?.filter(j => j.boundary != null) ?? [];

        this.notifyJurisdictionsChanged();

        this.onViewportChanged();
    }

    private notifyJurisdictionsChanged() {
        this.availableJurisdictionsSource.next(
            this.overrideJurisdictionList ?? this.configJurisdictionList ?? []
        );
    }

    setOverrideJurisdictionList(jurisdictionList?: AirspaceJurisdictionDto[]) {
        this.overrideJurisdictionList = jurisdictionList;
        this.notifyJurisdictionsChanged();
    }

    /**
     * Updates the active config used by the map and its collaborators. This should only be called from the dynamic data service.
     * @param config the updated config
     */
    setActiveConfig(config: TypedActiveMapStyleDto) {
        if (config == null) {
            return;
        }
        this.mapStyleSource.next(config);
    }

    private setupMap() {
        this.subscribeToMapEvents();
        this.mapEvent$.next(new MapLoadedMapEvent(this._map));
    }

    getImages() {
        return [
            {
                url: `${this.constants.IMG_URL}/map/marker.png`,
                id: 'flight-marker'
            },
            {
                url: `${this.constants.IMG_URL}/map/marker-home.png`,
                id: 'flight-home'
            },
            {
                url: `${this.constants.IMG_URL}/map/marker-rp.png`,
                id: 'flight-rp'
            },
            {
                url: `${this.constants.IMG_URL}/map/marker-takeoff.png`,
                id: 'flight-takeoff'
            },
            {
                url: `${this.constants.IMG_URL}/map/marker-landing.png`,
                id: 'flight-landing'
            },
            {
                url: `${this.constants.IMG_URL}/map/marker-observer.png`,
                id: 'flight-observer'
            },
            {
                url: `${this.constants.IMG_URL}/map/marker-poi.png`,
                id: 'flight-poi'
            },
            {
                url: `${this.constants.IMG_URL}/map/no-fly.png`,
                id: 'no-fly'
            },
            {
                url: `${this.constants.IMG_URL}/map/danger-area.png`,
                id: 'danger-area'
            }
        ];
    }

    private subscribeToMapEvents() {
        this.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event =>
                        event.type === MapEventType.activeInteractionChanged
                )
            )
            .subscribe((event: ActiveInteractionChangedMapEvent) => {
                this._activeInteraction = event.payload.interaction;
            });

        this.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event => event.type === MapEventType.toolbarButtonClicked
                )
            )
            .subscribe((event: ToolbarButtonClickedMapEvent) => {
                const buttonClicked = event.payload.button;

                switch (buttonClicked) {
                    case ToolbarButton.MeasurementLine:
                        this.mapEvent$.next(
                            new ActiveInteractionChangedMapEvent({
                                interaction: MapInteractionState.MeasurementLine
                            })
                        );
                        break;
                    case ToolbarButton.MeasurementRadius:
                        this.mapEvent$.next(
                            new ActiveInteractionChangedMapEvent({
                                interaction:
                                    MapInteractionState.MeasurementRadius
                            })
                        );
                        break;
                    case ToolbarButton.Delete:
                        this.deleteSelection(event.payload.featureId);
                }
            });

        this.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event =>
                        event.type === MapEventType.mapMoveEnd ||
                        event.type === MapEventType.mapZoomEnd
                )
            )
            .subscribe(() => this.onViewportChanged());

        this._map.on('draw.create', e => {
            this.zone.run(() =>
                this.mapEvent$.next(
                    new MapDrawCreatedEvent({
                        action: e.action,
                        features: e.features
                    })
                )
            );
        });

        this._map.on('draw.update', e => {
            this.zone.run(() =>
                this.mapEvent$.next(
                    new MapDrawUpdatedEvent({
                        action: e.action,
                        features: e.features
                    })
                )
            );
        });

        this._map.on('draw.modechange', e => {
            this.zone.run(() =>
                this.mapEvent$.next(
                    new MapDrawModeChangeEvent({
                        mode: e.mode
                    })
                )
            );
        });

        this._map.on('draw.delete', e => {
            this.zone.run(() =>
                this.mapEvent$.next(
                    new MapDrawDeletedEvent({
                        features: e.features
                    })
                )
            );
        });

        this._map.on('draw.selectionchange', e => {
            this.mapEvent$.next(
                new MapDrawSelectionChangeEvent({
                    features: e.features,
                    points: e.points
                })
            );
        });

        this._map.on('click', e => {
            this.zone.run(() => this.mapEvent$.next(new MouseClickMapEvent(e)));
        });

        this._map.on('touchstart', e => {
            const isSingleTouch = e.lngLats.length < 2;
            const location = e.lngLat;
            const touchStartTime = performance.now();
            if (isSingleTouch) {
                // First fire off the down event, then check on touch end if interaction was a tap
                // This would also be the place to check for a long-tap/hold
                this.zone.run(() =>
                    this.mapEvent$.next(new MapTouchDownEvent(e))
                );

                const emitTapOrHold = (
                    t: mapboxgl.MapTouchEvent & mapboxgl.EventData
                ) => {
                    const touchEndTime = performance.now();
                    const touchTiming = touchEndTime - touchStartTime;
                    if (
                        t.lngLat.lat === location.lat &&
                        t.lngLat.lng === location.lng &&
                        touchTiming < 500
                    ) {
                        this.zone.run(() =>
                            this.mapEvent$.next(new MapTouchTapEvent(e))
                        );
                    } else if (t.lngLat === location && touchTiming > 0.5) {
                        // Add hold code here
                    }
                };

                this._map.once('touchend', ev => {
                    emitTapOrHold(ev);
                });
                this._map.once('touchcancel', ev => {
                    emitTapOrHold(ev);
                });
            }
        });

        this._map.on('mousedown', e => {
            this.zone.run(() => this.mapEvent$.next(new MapMouseDownEvent(e)));
        });

        this._map.on('mousemove', e => {
            this.zone.run(() => this.mapEvent$.next(new MouseMoveMapEvent(e)));
        });

        this._map.on('moveend', e =>
            this.zone.run(() => this.mapEvent$.next(new MapMoveEndEvent(e)))
        );

        this._map.on('zoomend', e =>
            this.zone.run(() => this.mapEvent$.next(new MapZoomEndEvent(e)))
        );

        this._map.getCanvas().addEventListener('keyup', event => {
            this.handleKeyUp(event);
        });

        this.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(event => event.type === MapEventType.mapDrawModeChanged)
            )
            .subscribe((e: MapDrawModeChangeEvent) => {
                if (e.payload.mode === MapModes.SIMPLE_SELECT) {
                    this.mapEvent$.next(
                        new ActiveInteractionChangedMapEvent({
                            interaction: MapInteractionState.Select
                        })
                    );
                }
            });
    }

    private deleteSelection(featureId?: string) {
        // FIXME: if a measurement is still in draw mode when the trash button is clicked, this fails to delete all geometry.

        // This does a draw.trash() call that throws correctly
        this.deleteLastPoint();

        // even though the selection changes, the draw.selectionchange event is not fired
        // by Draw. So, we call it manually.
        this.mapEvent$.next(
            new MapDrawSelectionChangeEvent({
                features: [],
                points: []
            })
        );

        // Get draw in the correct mode and select the feature
        this._draw.changeMode(MapModes.SIMPLE_SELECT, { featureId });
        // Delete any remaining geometry for the selected feature, then check if anything remains and delete that
        // This is done because the delete may leave some leftover geometry, especially the last drawn point
        if (featureId != null) {
            this._draw.delete(featureId);
        }
        try {
            this._draw.get(featureId);
        } catch {
            return this.deleteLastPoint();
        }
        this.deleteSelectedPoint();

        // Get draw into the correct mode again without selecting the feature
        this._draw.changeMode(MapModes.SIMPLE_SELECT);
    }

    private deleteLastPoint() {
        try {
            this._draw.trash();
        } catch (e) {
            console.warn({ e });
            // Draw expects that the feature is deleted on Trash
            // does not impact functionality, but throws a console error
            // ignoring with an empty try/catch
        }
    }

    private deleteSelectedPoint() {
        let selectedFeatures = null;
        try {
            selectedFeatures = this._draw.getSelected();
        } catch {
            // Mapbox Draw throws a null ref expection on getSelected, looks that the Store variable
            // is null for some reason
            return;
        }
        if (
            selectedFeatures &&
            selectedFeatures.features &&
            selectedFeatures.features.length > 0
        ) {
            const selectedFeature = selectedFeatures.features[0];
            let minNumberOfCoordinates = 2; // for a line
            let numberOfCoordinates =
                selectedFeature.geometry.coordinates.length;
            if (selectedFeature.geometry.type === 'Polygon') {
                minNumberOfCoordinates = 4;
                // polygons store coordinates in rings, we assume there is only 1 ring.
                numberOfCoordinates =
                    selectedFeature.geometry.coordinates[0].length;
            }

            if (numberOfCoordinates > minNumberOfCoordinates) {
                try {
                    this._draw.trash();
                } catch (e) {
                    console.warn({ e });
                    // Draw expects that the feature is deleted on Trash
                    // does not impact functionality, but throws a console error
                    // ignoring with an empty try/catch
                }
            }
        }
    }

    /**
     * Return the jurisdiction at the point.
     *
     * Note, this doesn't use the mapbox queryRenderedFeatures because it does not return consistent results unless zoomed to approx
     * 5.6.
     * @param location the point to find
     */
    findJurisdictionAt(location: [number, number]) {
        if (
            this.availableJurisdictionsSource.value == null ||
            this._map == null
        ) {
            return null;
        }

        const jurisdiction = this.availableJurisdictionsSource.value
            .filter(j => j.boundary != null)
            .find(f =>
                booleanPointInPolygon(
                    point(location),
                    f.boundary as Polygon | MultiPolygon
                )
            );

        return jurisdiction;
    }

    /**
     * The map has been moved, so recalculate the jurisdiction, etc.
     * If the selected jurisdiction isn't in the visible jurisdictions then switch to another, or none.
     */
    private onViewportChanged() {
        if (this.availableJurisdictionsSource.value == null) {
            return;
        }
        if (this._map != null) {
            const boundary = asPolygon(this._map.getBounds());

            const currentValue = this.visibleJurisdictionsSource.value;

            const visibleJurisdictions =
                this.availableJurisdictionsSource.value.filter(j =>
                    intersect(
                        featureCollection([
                            feature(boundary),
                            feature(j.boundary as Polygon | MultiPolygon)
                        ])
                    )
                );

            if (visibleJurisdictions.length === 0) {
                this.visibleJurisdictionsSource.next({
                    visible: visibleJurisdictions,
                    selected: null
                });
                return;
            }

            if (
                currentValue.selected == null ||
                !visibleJurisdictions.some(
                    j => j.id === currentValue.selected.id
                )
            ) {
                const centre = this._map.getCenter();
                this.visibleJurisdictionsSource.next({
                    visible: visibleJurisdictions,
                    selected:
                        this.findJurisdictionAt([centre.lng, centre.lat]) ??
                        visibleJurisdictions[0]
                });
            } else if (
                idListsDiffer(currentValue.visible, visibleJurisdictions)
            ) {
                this.visibleJurisdictionsSource.next({
                    visible: visibleJurisdictions,
                    selected: currentValue.selected
                });
            }
        } else if (
            this.initialCentre != null &&
            this.visibleJurisdictionsSource.value.selected == null
        ) {
            this.visibleJurisdictionsSource.next({
                visible: [],
                selected: this.findJurisdictionAt([
                    this.initialCentre.lng,
                    this.initialCentre.lat
                ])
            });
        }
    }

    /**
     * Sets the selected jurisdiction, and notifies listeners. This is the system of record for this information.
     *
     * @param jurisdiction the jurisdiction to select
     */
    setCurrentJurisdiction(jurisdiction: AirspaceJurisdictionDto) {
        const currentJurisdiction = this.visibleJurisdictionsSource.value;
        if (
            (jurisdiction != null &&
                currentJurisdiction.selected?.id == jurisdiction.id) ||
            jurisdiction == currentJurisdiction.selected
        ) {
            return;
        }
        this.visibleJurisdictionsSource.next({
            visible: currentJurisdiction.visible,
            selected: jurisdiction
        });
    }

    getDefaultJurisdiction(): AirspaceJurisdictionDto | null {
        if (
            this.availableJurisdictionsSource.value == null ||
            this.availableJurisdictionsSource.value.length === 0
        ) {
            return null;
        }
        if (this.visibleJurisdictionsSource.value.selected != null) {
            return this.visibleJurisdictionsSource.value.selected;
        }
        const personsJurisdictionIds = this.userService
            .getCurrentUser()
            .activeJurisdictions.map(j => j.id);

        return this.availableJurisdictionsSource.value.find(
            j => personsJurisdictionIds.indexOf(j.id) !== -1
        );
    }

    /**
     * Change the map draw mode correctly.
     */
    changeDrawMode(mode: string, options?: any) {
        this.zone.run(() => this._draw.changeMode(mode, options));
    }
}
