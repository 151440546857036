import {
    Component,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    AttachmentHandler,
    defaultBuildFormAttachmentUrl,
    cloneFormSection,
    cloneFormVersion,
    ConcreteMappedEntityConcreteFormDto,
    ControlValues,
    deepCopy,
    DO_NOTHING,
    FlyFreelyLoggingService,
    FormControlDto,
    FormDto,
    FormResponseCommand,
    FormResponseDto,
    FormSectionDto,
    FormService,
    FormVersionDto,
    NameValue,
    nextControlId,
    nextSectionId,
    nextVersion,
    NullAttachmentHandler,
    toConcreteMappedEntity,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import * as FileSaver from 'file-saver';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentationDialoguesService } from '../documentation-dialogues.service';
import { ControlDescription } from '../interfaces';
import { CurrentAttachmentVersionDtoWithDownloadUrl } from '../internal-interfaces';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

function savableControl(control: FormControlDto): FormControlDto {
    return {
        ...control,
        prefill: control.type === 'calculated' ? [] : control.prefill,
        groupWithPreviousControl: control.groupWithPreviousControl ?? false
    };
}

function savableSection(section: FormSectionDto): FormSectionDto {
    return {
        ...section,
        controls: section.controls.map(savableControl)
    };
}

function copySectionForPrefill(section: FormSectionDto) {
    return {
        ...section,
        controls: section.controls.map(c => ({ ...c, required: false }))
    };
}

function cleanSections(sections: FormSectionDto[]) {
    for (let i = 0; i < sections.length; i++) {
        sections[i].id = sections[i].id > 0 ? sections[i].id : -i - 1;
        sections[i].order = i;
        sections[i].repeatingGroup = sections[i].repeatingGroup || false;
        cleanControls(sections[i].controls);
    }
}

function cleanControls(controls: FormControlDto[]) {
    for (let i = 0; i < controls.length; i++) {
        controls[i].order = i;
        if (
            controls[i].displayCondition != null &&
            controls[i].displayCondition.subjectControlId == null
        ) {
            controls[i].displayCondition = null;
        }
    }
}

function findControlsUpTo(
    sections: FormSectionDto[],
    section: FormSectionDto,
    control?: FormControlDto
) {
    const controls = [];
    let done = false;

    for (const s of sections) {
        for (const c of s.controls) {
            if (c === control) {
                done = true;
                break;
            }
            controls.push(buildControlDescription(s, c));
        }
        if (section === s || done) {
            break;
        }
    }
    return controls;
}

function buildControlDescription(
    section: FormSectionDto,
    control: FormControlDto
): ControlDescription {
    return {
        id: control.id,
        name: `${section.name}: ${control.label}`,
        control: control
    };
}

@Component({
    selector: 'form-edit',
    templateUrl: './form-edit.component.html',
    styles: [
        `
            accordion-group .btn-block {
                color: #000 !important;
            }

            accordion-group .btn-block:focus {
                outline: 0 solid;
            }
        `
    ]
})
export class FormEdit implements OnInit, OnDestroy {
    @Input() form: FormDto;
    @Input() managingOrganisationId: number;

    currentVersion: FormVersionDto;
    selectedVersionId: number;
    attachmentsHandler: AttachmentHandler | NullAttachmentHandler;
    attachments: CurrentAttachmentVersionDtoWithDownloadUrl[];
    sortableConfig: {
        disabled: boolean;
    };
    popover: any;
    loaded = false;
    changed = false;
    opened = false;
    nonRepeatingLayoutOptions: NameValue[] = [
        { name: 'Linear', value: 'LINEAR' }
    ];
    repeatingLayoutOptions: NameValue[] = [
        { name: 'Table', value: 'TABLE' },
        { name: '12 Column', value: 'TWELVE_COLUMN' }
    ];
    private workTracker = new WorkTracker();
    working = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private formService: FormService,
        private logging: FlyFreelyLoggingService,
        private commonDialoguesService: CommonDialoguesService,
        @Inject(forwardRef(() => DocumentationDialoguesService))
        private documentationDialoguesService: DocumentationDialoguesService,
        public modal: BsModalRef<FormEdit>,
        modalOptions: ModalOptions
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        modalOptions.closeInterceptor = () => {
            if (this.changed) {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Confirm Cancel',
                    `You have unsaved changes, are you sure you want to cancel?`,
                    'Yes',
                    () => Promise.resolve()
                );
            } else {
                return Promise.resolve();
            }
        };
    }

    
    // Method to update the order of sections
private updateSectionOrder() {
    this.currentVersion.sections.forEach((section, index) => {
        section.order = index; // Ensure the section order is updated
    });
}

// Method to update the order of controls within a section
private updateControlOrder(section: FormSectionDto) {
    section.controls.forEach((control, index) => {
        control.order = index; // Ensure the control order is updated
    });
}
    // Method to handle dropping sections
     dropSection(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.currentVersion.sections, event.previousIndex, event.currentIndex);
        this.updateSectionOrder(); // Update order after rearranging sections
        this.formChanged(); // Mark form as changed
    }


  // Method to handle dropping controls in a section
  dropControl(event: CdkDragDrop<any[]>, section: FormSectionDto) {
    moveItemInArray(section.controls, event.previousIndex, event.currentIndex);
    this.updateControlOrder(section); // Update order after rearranging controls
    this.formChanged(); // Mark form as changed
}
    ngOnInit() {
        this.setupForm(this.form);
    }

    ngOnDestroy() {
        if (this.attachmentsHandler != null) {
            this.attachmentsHandler.destroy();
        }
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupForm(form: FormDto) {
        this.loaded = false;
        // If this is a new form or there are no versions then initialise waiting for a new version.
        if (form == null || form.versions.length === 0) {
            this.form = form || {
                availableActions: { canDelete: false, canEdit: true },
                versions: [],
                id: null,
                name: null,
                archived: false
            };
            this.currentVersion = {
                id: null,
                sections: [],
                availableActions: { canEdit: true, canDelete: false },
                versionNumber: 1
            };
            this.selectedVersionId = null;
        } else {
            this.form = form;
            if (!this.currentVersion) {
                this.currentVersion = form.versions.find(
                    version => version.id === form.activeVersionId
                );
                this.selectedVersionId = this.currentVersion.id;
            } else if (!this.currentVersion.id) {
                this.currentVersion = form.versions.find(version =>
                    version.versionNumber === this.currentVersion.versionNumber
                        ? version.id
                        : null
                );
                this.selectedVersionId = this.currentVersion.id;
            }
        }

        if (this.attachmentsHandler != null) {
            this.attachmentsHandler.destroy();
        }

        this.attachmentsHandler =
            this.form.id != null
                ? this.formService.attachmentHandler(
                      this.form.id,
                      this.managingOrganisationId
                  )
                : new NullAttachmentHandler();

        this.updateSortableConfig();
        this.loaded = true;

        if (this.form.id != null) {
            this.attachmentsHandler.attachments$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    attachments =>
                        (this.attachments = attachments.map(a => ({
                            ...a,
                            downloadUrl: defaultBuildFormAttachmentUrl(
                                this.managingOrganisationId,
                                this.form.id,
                                a.id,
                                a.attachmentVersionId
                            )
                        })))
                );
        } else {
            this.attachments = [];
        }
    }

    private updateSortableConfig() {
        this.sortableConfig = {
            disabled: !(
                this.currentVersion != null &&
                this.currentVersion.availableActions.canEdit
            )
        };
    }

    changeSelectedVersion(formVersionId: number) {
        this.currentVersion = {
            id: null,
            versionNumber: null,
            sections: [],
            availableActions: { canEdit: true, canDelete: false }
        };
        this.selectedVersionId = null;

        if (formVersionId) {
            for (const formVersion of this.form.versions) {
                if (formVersion.id === formVersionId) {
                    this.currentVersion = formVersion;
                    this.selectedVersionId = this.currentVersion.id;
                    break;
                }
            }
        }
        this.updateSortableConfig();
        this.formChanged();
    }

    cloneVersion() {
        const formVersionId = this.currentVersion.id;

        this.formService
            .cloneFormVersion(this.form.id, {
                formVersionId
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(formVersion => {
                this.currentVersion = formVersion;
                this.selectedVersionId = formVersion.id;
                this.form.versions.push(formVersion);

                this.updateSortableConfig();
                this.formChanged();
            });
    }

    renameForm(name: string) {
        this.formService
            .renameForm(this.form.id, { name: name })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: savedform => {
                    this.setupForm(savedform);
                },
                error: error => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    setAsActiveVersion() {
        const toSave = {
            formVersionId: this.selectedVersionId
        };
        this.formService
            .changeActiveVersion(this.form.id, toSave)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: savedForm => {
                    this.logging.success('Active version changed');
                    this.setupForm(savedForm);
                },
                error: error => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    canEdit() {
        if (this.form != null && this.form.availableActions) {
            return this.form.availableActions.canEdit;
        } else {
            return false;
        }
    }

    canEditVersion() {
        if (
            this.currentVersion != null &&
            this.currentVersion.availableActions != null
        ) {
            return this.currentVersion.availableActions.canEdit;
        } else {
            return false;
        }
    }

    isNewForm() {
        return !this.form || !this.form.id;
    }

    formChanged() {
        if (this.loaded) {
            return (this.changed = true);
        }
    }

    createSection() {
        this.currentVersion.sections.push({
            id: nextSectionId(this.currentVersion),
            controls: [],
            name: null,
            layout: FormSectionDto.Layout.LINEAR,
            order: this.currentVersion.sections.length,
            repeatingGroup: false
        });
        this.formChanged();
    }

    deleteSection(index: number) {
        let hasDependentControls = false;
        const section = this.currentVersion.sections[index];
        section.controls.map(c => {
            if (this.checkDependentControls(c, section.id)) {
                hasDependentControls = true;
            }
        });
        if (!hasDependentControls) {
            this.currentVersion.sections.splice(index, 1);
            this.formChanged();
        } else {
            this.logging.error(
                null,
                `This section has controls that are required by controls in other sections. Please remove all dependencies and try again`
            );
            return;
        }
    }

    cloneSection(section: FormSectionDto) {
        const [newSection, _] = cloneFormSection(
            deepCopy(section),
            nextSectionId(this.currentVersion),
            { nextId: nextControlId(this.currentVersion), idLookup: {} },
            ' (Copy)'
        );
        this.currentVersion.sections.push(newSection);
        this.formChanged();
    }

    editPrefill(section: FormSectionDto) {
        const formDetails = {
            formId: this.form.id,
            formVersionId: this.currentVersion.id,
            description: '',
            formName: section.name,
            sections: [copySectionForPrefill(section)]
        };
        const concreteForm = toConcreteMappedEntity(formDetails);

        const currentPrefillValues = section.controls.reduce(
            (acc: { [key: number]: string[] }, c: FormControlDto) => {
                acc[c.id] = c.prefill;
                return acc;
            },
            {}
        );

        const currentPrefillResponse: FormResponseDto[] = [
            {
                ...formDetails,
                values: currentPrefillValues,
                formVersionId: null,
                completed: false
            }
        ];

        this.documentationDialoguesService.showFormInputDialogue(
            concreteForm,
            'Prefill',
            currentPrefillResponse,
            this.managingOrganisationId,
            response => {
                section.controls.forEach(control => {
                    if (control.type === 'calculated') {
                        control.prefill = [];
                    } else {
                        control.prefill = response.values[control.id];
                    }
                });
                return Promise.resolve();
            }
        );
        this.formChanged();
    }

    addControl(section: FormSectionDto) {
        const controlObject: FormControlDto = {
            id: nextControlId(this.currentVersion),
            order: section.controls.length,
            type: null,
            label: null,
            placeholder: null,
            description: null,
            required: null,
            config: null,
            options: null,
            displayCondition: null,
            prefill: [],
            inputControlIds: [],
            groupWithPreviousControl: false,
            derivedFromFormControlIdList: []
        };

        const modal = this.documentationDialoguesService.showFormControlEdit(
            controlObject,
            this.attachments,
            findControlsUpTo(this.currentVersion.sections, section)
        );
        modal.content.controlChanged
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(modal.onHide))
            .subscribe({
                next: control => {
                    if (control) {
                        section.controls.push(control);
                    }
                },
                error: DO_NOTHING
            });
        this.formChanged();
    }

    editControl(
        section: FormSectionDto,
        controlObject: FormControlDto,
        ix: number
    ) {
        const modal = this.documentationDialoguesService.showFormControlEdit(
            controlObject,
            this.attachments,
            findControlsUpTo(this.currentVersion.sections, section)
        );
        modal.content.controlChanged
            .pipe(takeUntil(this.ngUnsubscribe$), takeUntil(modal.onHide))
            .subscribe({
                next: control => {
                    if (control) {
                        section.controls.splice(ix, 1, control);
                    }
                },
                error: DO_NOTHING
            });
        this.formChanged();
    }

    deleteControl(controls: FormControlDto[], ix: number) {
        const control = controls[ix];

        if (!this.checkDependentControls(control)) {
            controls.splice(ix, 1);
            this.formChanged();
        }
    }

    checkDependentControls(control: FormControlDto, excludeSectionId?: number) {
        const allControls = this.currentVersion.sections.reduce(
            (acc: FormControlDto[], s: FormSectionDto) =>
                excludeSectionId != null && excludeSectionId === s.id
                    ? acc
                    : s.controls != null
                    ? acc.concat(s.controls)
                    : acc,
            []
        );
        const subject =
            allControls.find(
                c => c.displayCondition?.subjectControlId === control.id
            ) ?? null;
        if (subject != null) {
            this.logging.error(
                null,
                `"${
                    control.label ? control.label : control.description
                }" is used as a condition to display "${
                    subject.label ? subject.label : subject.description
                }" and cannot be deleted.
                Please unlink them and try again.`
            );
            return true;
        }

        const calc =
            allControls.find(c => c.inputControlIds?.includes(control.id)) ??
            null;
        if (calc != null) {
            this.logging.error(
                null,
                `"${
                    control.label ? control.label : control.description
                }" is an input for "${
                    calc.label ? calc.label : calc.description
                }" and cannot be deleted. Please unlink them and try again.`
            );
            return true;
        }
        return false;
    }

    previewForm() {
        cleanSections(this.currentVersion.sections);
        const concreteForm: ConcreteMappedEntityConcreteFormDto =
            toConcreteMappedEntity({
                formId: this.form.id,
                formVersionId: this.currentVersion.id,
                description: this.currentVersion.description,
                formName: this.form.name,
                sections: this.currentVersion.sections,
                archived: false
            });

        let savedData: FormResponseCommand;
        this.documentationDialoguesService
            .showFormInputDialogue(
                concreteForm,
                'Preview form',
                [],
                this.managingOrganisationId,
                (response: FormResponseCommand) => {
                    savedData = response;
                    return Promise.resolve();
                }
            )
            .onHide.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                if (savedData != null) {
                    this.documentationDialoguesService.showFormResponseDialogue(
                        [this.buildFormResponse(savedData.values)],
                        'Preview Responses',
                        this.managingOrganisationId
                    );
                }
            });
    }

    private buildFormResponse(values: ControlValues) {
        return {
            formId: this.form.id,
            formVersionId: this.currentVersion.id,
            isLocked: false,
            formName: this.form.name,
            sections: this.currentVersion.sections,
            values: values,
            completed: false
        };
    }

    openPopover(pop: any) {
        this.popover = pop;
    }

    onChangeFormName(formName: string) {
        if (typeof formName === 'string') {
            this.form.name = formName;
        }
        this.formChanged();
    }

    saveAndClose() {
        this.save().then(() => this.modal.hide());
    }

    save() {
        cleanSections(this.currentVersion.sections);

        if (this.isNewForm()) {
            const organisationId = this.form.organisationId;
            const toSave = {
                name: this.form.name,
                description: this.currentVersion.description,
                organisationId: organisationId,
                sections: this.currentVersion.sections.map(savableSection)
            };
            const doneWorking = this.workTracker.createTracker();
            return this.formService
                .createForm(toSave)
                .toPromise()
                .then(
                    savedForm => {
                        this.logging.success('Form created');
                        this.setupForm(savedForm);
                        this.changed = false;
                        doneWorking();
                    },
                    error => {
                        this.logging.error(error, 'Save failed');
                        doneWorking();
                    }
                );
        } else {
            const toSave = {
                formVersionId: this.currentVersion.id,
                name: this.form.name,
                description: this.currentVersion.description,
                sections: this.currentVersion.sections.map(savableSection)
            };
            const doneWorking = this.workTracker.createTracker();
            return this.formService
                .updateForm(this.form.id, toSave)
                .toPromise()
                .then(
                    savedForm => {
                        this.logging.success('Form saved');
                        this.setupForm(savedForm);
                        this.changed = false;
                        doneWorking();
                    },
                    error => {
                        this.logging.error(error, 'Save failed');
                        doneWorking();
                    }
                );
        }
    }

    importFormVersion(event: any) {
        const fileContent = new FileReader();
        fileContent.onload = () => {
            if (typeof fileContent.result === 'string') {
                this.currentVersion = {
                    ...cloneFormVersion(
                        JSON.parse(fileContent.result),
                        nextVersion(this.form.versions)
                    )
                };
            }
            this.selectedVersionId = null;
            this.form.versions.push(this.currentVersion);

            this.updateSortableConfig();
        };

        fileContent.readAsText(event.target.files[0]);
    }

    exportFormVersion() {
        const content = JSON.stringify(
            cloneFormVersion(this.currentVersion, null)
        );
        const blob = new Blob([content], {
            type: 'application/json;charset=utf-8'
        });
        FileSaver.saveAs(blob, `${this.form.name}.json`);
    }

    /**
     * @param attachmentId attachment id as a string as it comes from the config object
     */
    // isImageValid(attachmentId: string) {
    //     return (
    //         this.attachments?.findIndex(
    //             a => a.id.toString() === attachmentId
    //         ) !== -1 ?? false
    //     );
    // }
    isImageValid(attachmentId: string) {
        return this.attachments?.findIndex(a => a.id.toString() === attachmentId) !== -1;
    }
}
