<div class="vertical-container search-dialogue">
    <plan-highlight [featureFlags]="notamFeature"></plan-highlight>
    <div>
        <info-search
            placeholder="Search"
            [readonly]="working"
            (liveSearch)="findAirports($event)"
        ></info-search>
    </div>
    <div class="dropdown dropdown-padding">
        <div
            *ngIf="aerodromes?.length"
            class="dropdown-menu show open contents"
        >
            <nav class="nav">
                <li *ngFor="let aerodrome of aerodromes">
                    <a
                        class="nav-link dropdown-item"
                        (click)="selectAirport(aerodrome)"
                        >{{ aerodrome.name }} {{ aerodrome.code }}</a
                    >
                </li>
            </nav>
        </div>
    </div>
    <h4 class="section-heading" *ngIf="selectedFilters.length">
        <span>NOTAM Search Filters</span>
        <div></div>
    </h4>
    <div class="horizontal-container result-aerodrome">
        <div class="aerodrome-container">
            <span *ngFor="let aerodrome of selectedFilters">
                <button
                    type="button"
                    class="btn btn-pill btn-sm"
                    tooltip="{{ aerodrome.name }}"
                    placement="top"
                    [class.active]="aerodrome.selected"
                    (click)="toggleFilter(aerodrome)"
                    [disabled]="working"
                >
                    {{ aerodrome.identifier }}
                </button>
            </span>
        </div>
    </div>
    <div *ngIf="showNotams" class="vertical-container top-buffer">
        <h4 class="section-heading">
            <span>NOTAMS</span>
            <div></div>
        </h4>
        <ngx-loading [show]="working"></ngx-loading>
        <ng-container
            *ngIf="
                !loadingNotams && notams?.length > 0 && notamErrorCode == null
            "
        >
            <div *ngFor="let notam of locationBearings.notams">
                <div class="notamList">
                    <div style="flex: 1">
                        <notam-details
                            [notam]="notam"
                            [viewNotamDetailModal]="viewNotamDetailModal"
                        ></notam-details>
                    </div>
                    <div
                        class="horizontal-container vertical-align notam-actions"
                    >
                        <div class="text-right" *ngIf="notam.locationBearing">
                            <div>
                                <span
                                    [innerHtml]="
                                        notam.locationBearing | bearing
                                    "
                                ></span>
                            </div>
                        </div>
                        <div style="margin-left: 15px">
                            <button
                                *ngIf="notam.selected == false"
                                type="button"
                                class="btn btn-pill btn-primary btn-sm aerodrome-button"
                                (click)="submitNotam(notam)"
                            >
                                Add to mission
                            </button>
                            <button
                                *ngIf="!notam.selected == false"
                                type="button"
                                class="btn btn-pill btn-primary btn-sm aerodrome-button"
                                (click)="deleteNotam(notam)"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                !loadingNotams &&
                notams.length == 0 &&
                notamErrorCode == null &&
                selectedFilters.length
            "
        >
            <div class="alert alert-info" role="alert">
                No active NOTAMs for search parameters
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                !loadingNotams &&
                notams.length == 0 &&
                notamErrorCode == null &&
                !selectedFilters.length
            "
        >
            <div class="alert alert-info" role="alert">
                No search parameters selected
            </div>
        </ng-container>
        <ng-container *ngIf="!loadingNotams && notamErrorCode == 'NOT_SETUP'">
            <div class="text-left">
                <div class="alert alert-info" role="alert">
                    Air Services Australia Details Not Currently Setup
                </div>
                <button
                    class="btn btn-link left-buffer"
                    type="button"
                    (click)="updateAsaDetails()"
                >
                    Setup ASA Details
                </button>
            </div>
        </ng-container>
    </div>
</div>
<div class="action-container">
    <button
        class="btn btn-default pull-left"
        style="position: relative"
        type="button"
        [disabled]="
            working || missionHasNotams || hasAcknowledgedNoRelevantNotams
        "
        (click)="acknowledgeNoneRelevant()"
    >
        Acknowledge No Relevant NOTAMS
        <span
            class="overlay"
            [tooltip]="
                missionHasNotams
                    ? 'The mission already has at least one NOTAM selected'
                    : hasAcknowledgedNoRelevantNotams
                    ? 'There is already an acknowledgement of no relevant NOTAM for this mission'
                    : 'Indicate that there is no relevant NOTAM for this mission'
            "
            placement="top"
        ></span>
    </button>
    <button
        class="btn btn-default pull-right"
        type="button"
        (click)="closeModal()"
    >
        Done
    </button>
</div>

<inline-modal
    #viewNotamDetailModal
    [sizeSmall]="true"
    style="max-height: fit-content"
>
</inline-modal>
