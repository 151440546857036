import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    SimpleChanges
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    applyResponsesToEntityRequiredDocumentation,
    AttachmentHandler,
    ConcreteMappedEntityConcreteFormDto,
    ConcreteRequiredEntityWorkflowAttachmentRequirement,
    CurrentAttachmentVersionDto,
    DisplayableMissionDocumentation,
    existsAndHasValue,
    findRequiringEntities,
    FormResponseCommand,
    FormResponseDto,
    MissionDocumentationDto,
    PersonsOrganisationDto,
    RelatedEntityRequiredDocumentationAndResponses,
    updateOrAppendUnsubmittedResponse,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { DocumentationDialoguesService } from 'libs/documentation/src/lib/documentation-dialogues.service';
import { DocumentationStorage } from 'libs/documentation/src/lib/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    findActiveMissionApprovals,
    formAttachmentUrlFactory,
    getSimplifiedAuthorityFromApproval
} from '../../helpers';
import { MissionApprovalWithAuthority } from '../../interfaces';

interface InternalResponseLookup<T> {
    [entityKey: string]: { [responseEntityId: number]: T[] };
}

interface CompletableConcreteRequiredEntityWorkflowAttachmentRequirement
    extends ConcreteRequiredEntityWorkflowAttachmentRequirement {
    metMinimum?: boolean;
    metMaximum?: boolean;
    valid?: boolean;
    hasValue?: boolean;
}

interface CompletableRelatedEntityRequiredDocumentationAndResponses
    extends RelatedEntityRequiredDocumentationAndResponses {
    attachments: CompletableConcreteRequiredEntityWorkflowAttachmentRequirement[];
}

export const MISSION_DOCUMENTATION_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MissionDocumentation),
    multi: true
};

export const MISSION_DOCUMENTATION_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MissionDocumentation),
    multi: true
};

@Component({
    selector: 'mission-documentation',
    templateUrl: './mission-documentation.component.html',
    styleUrls: ['../../styles.scss'],
    providers: [MISSION_DOCUMENTATION_VALIDATOR, MISSION_DOCUMENTATION_ACCESSOR]
})
export class MissionDocumentation implements ControlValueAccessor, Validator {
    @Input() organisation: PersonsOrganisationDto;
    /**
     * The title that is prefixed to the documentation to make it clear which state this applies to.
     */
    @Input() groupTitle: string;

    /**
     * The step identifier for the step this documentation applies to
     */
    @Input() step: string;

    @Input() documentationStorage: DocumentationStorage;

    @Input() readonly: boolean = false;

    @Input() attachmentsHandler: AttachmentHandler;

    @Input() prefillErrors: { [formId: number]: boolean };

    @Input() missionApprovalList?: MissionApprovalWithAuthority[];

    @Input() missionDocumentation?: MissionDocumentationDto;

    working: boolean = false;

    workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    documentationResponses: CompletableRelatedEntityRequiredDocumentationAndResponses[];

    // These internal representations are used to do the actual rendering
    internalFormResponses: InternalResponseLookup<FormResponseDto>;

    activeApprovals: MissionApprovalWithAuthority[] = [];
    requiredFormsPerApproval: {
        [approvalId: number]: {
            [formId: number]: ConcreteMappedEntityConcreteFormDto;
        };
    } = {};

    getSimplifiedAuthorityFromApproval = getSimplifiedAuthorityFromApproval;

    private documentation: DisplayableMissionDocumentation;
    attachments: CurrentAttachmentVersionDto[];

    onChange: (value: any) => void;
    onTouched: () => void;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private documentationDialoguesService: DocumentationDialoguesService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                this.changeDetector.detectChanges();
            });

        if (this.missionApprovalList != null) {
            this.activeApprovals = findActiveMissionApprovals(
                this.missionApprovalList,
                true
            );
        }

        this.refresh(this.documentation);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('attachmentsHandler' in changes) {
            if (this.attachmentsHandler != null) {
                this.attachmentsHandler.attachments$
                    .pipe(takeUntil(this.ngUnsubscribe$))
                    .subscribe(attachments => {
                        this.attachments = attachments;
                        this.refresh(this.documentation);

                        // FIXME triggering validation
                        if (this.onChange != null) {
                            this.onChange(this.documentation);
                        }
                    });
            }
        }
        if (
            'missionApprovalList' in changes &&
            this.missionApprovalList != null
        ) {
            this.activeApprovals = findActiveMissionApprovals(
                this.missionApprovalList,
                true
            );
        }
    }

    private refresh(documentation: DisplayableMissionDocumentation) {
        this.documentation = documentation;

        if (documentation == null || this.step == null) {
            this.documentationResponses = [];
            return;
        }

        const requiringEntities = documentation.requiredDocumentation[
            this.step
        ].reduce((acc, docs) => findRequiringEntities([docs.forms, acc]), []);

        this.documentationResponses = documentation.requiredDocumentation[
            this.step
        ]
            .map(r =>
                applyResponsesToEntityRequiredDocumentation(
                    r,
                    documentation.formResponses,
                    this.step,
                    requiringEntities
                )
            )
            .map(r => this.applyAttachmentStatuses(r));

        // Build for lookups to easily group forms by approval.
        // An approval index of -1 indicates mission forms not required by an approval
        this.requiredFormsPerApproval = {};
        if (this.missionDocumentation == null) {
            return;
        }
        if (this.activeApprovals.length > 0) {
            this.activeApprovals.forEach(approval => {
                const formsFromApproval =
                    this.missionDocumentation
                        .missionApprovalRequiredDocumentation[
                        approval.approval.id
                    ];
                const forms = formsFromApproval
                    ? formsFromApproval[this.step].forms
                    : [];
                forms.forEach(form => {
                    if (
                        form.entity == null ||
                        approval?.approval == null ||
                        (this.requiredFormsPerApproval[approval.approval.id] !=
                            null &&
                            this.requiredFormsPerApproval[approval.approval.id][
                                form.entity.formId
                            ] != null)
                    ) {
                        return;
                    }
                    if (
                        this.requiredFormsPerApproval[approval.approval.id] ==
                        null
                    ) {
                        this.requiredFormsPerApproval[approval.approval.id] = {
                            [form.entity?.formId]: form
                        };
                    } else {
                        this.requiredFormsPerApproval[approval.approval.id][
                            form.entity?.formId
                        ] = form;
                    }
                });
            });
        }
        this.missionDocumentation.missionRequiredDocumentation[
            this.step
        ].forms.forEach(form => {
            if (
                form.entity == null ||
                (this.requiredFormsPerApproval[-1] != null &&
                    this.requiredFormsPerApproval[-1][form.entity.formId] !=
                        null) ||
                Object.keys(this.requiredFormsPerApproval).some(
                    key =>
                        this.requiredFormsPerApproval[key][
                            form.entity.formId
                        ] != null
                )
            ) {
                return;
            }
            if (this.requiredFormsPerApproval[-1] == null) {
                this.requiredFormsPerApproval[-1] = {
                    [form.entity?.formId]: form
                };
            } else {
                this.requiredFormsPerApproval[-1][form.entity?.formId] = form;
            }
        });
        Object.keys(
            this.missionDocumentation.rpaModelRequiredDocumentation
        ).forEach(rpaModelId => {
            this.missionDocumentation?.rpaModelRequiredDocumentation[
                rpaModelId
            ][this.step].forms.forEach(form => {
                if (
                    form.entity == null ||
                    this.requiredFormsPerApproval[-1][form.entity.formId] !=
                        null
                ) {
                    return;
                }
                if (this.requiredFormsPerApproval[-1] == null) {
                    this.requiredFormsPerApproval[-1] = {
                        [form.entity?.formId]: form
                    };
                } else {
                    this.requiredFormsPerApproval[-1][form.entity?.formId] =
                        form;
                }
            });
        });
    }

    private applyAttachmentStatuses(
        relatedEntity: RelatedEntityRequiredDocumentationAndResponses
    ): CompletableRelatedEntityRequiredDocumentationAndResponses {
        return {
            ...relatedEntity,
            attachments: relatedEntity.attachments.map(att => {
                const id = att.entity.id;
                const noAttached = this.attachments.filter(
                    a => a.requirementId === id
                ).length;
                return {
                    ...att,
                    metMinimum:
                        noAttached >= att.entity.minimumInstances
                            ? true
                            : false,
                    metMaximum:
                        noAttached === att.entity.maximumInstances
                            ? true
                            : false,
                    valid:
                        (att.entity.minimumInstances == null ||
                            noAttached >= att.entity.minimumInstances) &&
                        (att.entity.maximumInstances == null ||
                            noAttached <= att.entity.maximumInstances),
                    hasValue: noAttached > 0
                };
            })
        };
    }

    validate(control: FormControl): ValidationErrors | null {
        const formsValid = this.documentationResponses.reduce(
            (acc, r) =>
                acc &&
                r.forms.reduce(
                    (fAcc, f) => fAcc && (f.completed || !f.required),
                    true
                ) &&
                r.attachments.reduce(
                    (aAcc, a) => aAcc && a.valid === true,
                    true
                ),

            true
        );

        return formsValid ? null : { required: true };
    }

    writeValue(documentation: DisplayableMissionDocumentation): void {
        this.refresh(documentation);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    showForm(
        entity: RelatedEntityRequiredDocumentationAndResponses,
        form: ConcreteMappedEntityConcreteFormDto
    ) {
        this.onTouched();

        const originalResponses: FormResponseDto[] = existsAndHasValue(
            entity.formResponses[form.entity.formId]
        )
            ? [this.documentation.formResponses[form.entity.formId][0]]
            : [];

        const buildFormAttachmentUrl = formAttachmentUrlFactory(
            form,
            this.missionApprovalList.map(a => a.approval)
        );

        const modal = this.documentationDialoguesService.showFormInputDialogue(
            form,
            `${this.groupTitle} Form`,
            originalResponses,
            this.organisation.id,
            (response: FormResponseCommand) =>
                this.onFormSave(form.entity.formId, this.step, {
                    ...response,
                    relatedEntityType: entity.relatedEntityType,
                    relatedEntityId: entity.relatedEntityId
                }),
            buildFormAttachmentUrl
        );
    }

    private onFormSave(
        formId: number,
        step: string,
        response: FormResponseCommand
    ) {
        if (this.documentationStorage != null) {
            return this.documentationStorage.storeFormResponse(
                formId,
                step,
                response
            );
        }

        const newFormResponses = {
            ...this.documentation.formResponses,
            [formId]: updateOrAppendUnsubmittedResponse(
                this.documentation.formResponses[formId],
                // @ts-ignore FIXME
                { ...response, step, submittedTime: null }
            )
        };

        const newDocumentation: DisplayableMissionDocumentation = {
            ...this.documentation,
            formResponses: newFormResponses
        };

        this.refresh(newDocumentation);
        this.onChange(newDocumentation);

        return Promise.resolve();
    }
}
