import { Injectable } from '@angular/core';
import {
    DO_NOTHING,
    FlyFreelyLoggingService,
    UserService,
    WhatsNewDto
} from '@flyfreely-portal-ui/flyfreely';
import { MobileDeviceWarningDialogue } from 'libs/news-and-support/src/lib/support/mobile-warning/mobile-warning.component';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WhatsNewDialogue } from './whats-new/whats-new-dialogue.component';

@Injectable()
export class NotificationDialoguesService {
    constructor(
        private modalService: BsModalService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService
    ) {}

    showWhatsNew(whatsNew: WhatsNewDto) {
        return this.modalService.show(WhatsNewDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { changes: whatsNew.changes }
        });
    }

    showAllWhatsNew() {
        return this.userService.findAllWhatsNew().subscribe({
            next: whatsNew => {
                if (whatsNew.changes.length === 0) {
                    this.logging.info('No changes available to display');
                }
                this.showWhatsNew(whatsNew);
            },
            error: DO_NOTHING
        });
    }

    showMobileWarning() {
        return this.modalService.show(MobileDeviceWarningDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-warning',
            initialState: {}
        });
    }
}
