import { Injectable } from '@angular/core';
import {
    OrganisationIdentifierDto,
    PersonDto
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UpdateAirServicesDialogue } from './air-services/update-air-services-dialogue.component';

@Injectable()
export class ProfileDialoguesService {
    constructor(private modalService: BsModalService) {}

    showAirServicesDialogue(
        profile: PersonDto,
        userAsaIdentifiers: OrganisationIdentifierDto
    ) {
        return this.modalService.show(UpdateAirServicesDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                profile: profile,
                userAsaIdentifiers: userAsaIdentifiers
            }
        });
    }
}
