import { Component } from '@angular/core';
import {
    AcceptInviteCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InviteDto,
    InvitesService,
    OrganisationService,
    OnboardingService,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingData } from '../../onboarding-screens-data.service';
import {
    Invite,
    OnboardingRequirements,
    PersonalDetailsDataService
} from '../../personal-details-data.service';

@Component({
    selector: 'personal-details-complete',
    templateUrl: './personal-details-complete.component.html',
    styleUrls: ['./personal-details-complete.component.scss']
})
export class PersonalDetailsCompleteComponent {
    private ngUnsubscribe$ = new Subject<void>();

    invites: Invite[];
    inviteOrganisationName: string;
    hasAcceptedInvite = false;
    inExistingOrganisations = false;
    ownsAnOrganisation = false;
    organisationDetails: OnboardingRequirements;
    inviteDetails: AcceptInviteCommand = {
        allowManaging: true,
        defaultOrganisation: true
    };

    constructor(
        private obsds: OnboardingData,
        private organisationService: OrganisationService,
        private personalDetailsDataService: PersonalDetailsDataService,
        private onboardingService: OnboardingService,
        private logging: FlyFreelyLoggingService,
    ) {
        obsds.personAndPersonalOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(update => {
                this.inExistingOrganisations = update.inExistingOrganisation;
                this.ownsAnOrganisation = update.ownsAnOrganisation;
            });
    }

    ngOnInit() {
        this.findInvites();
    }

    private findInvites() {
        this.personalDetailsDataService.invites$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(invites => {
                this.invites = invites;
                this.hasAcceptedInvite = invites.some(
                    invite => invite.status === InviteDto.Status.ACCEPTED
                );
                if (
                    invites.length > 0 &&
                    invites[0].organisation.name != null
                ) {
                    this.inviteOrganisationName = invites[0].organisation.name;
                } else if (
                    invites.length > 0 &&
                    invites[0].organisation.name == null &&
                    invites[0].organisation.id != null
                ) {
                    this.findOrganisationById();
                }
            });
        this.personalDetailsDataService.createOrganisationsResult$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                organisationDetails =>
                    (this.organisationDetails = organisationDetails)
            );
    }

    findOrganisationById() {
        if (this.invites === null || this.invites.length === 0) {
            return;
        }
        this.organisationService
            .findById(
                this.invites[0].organisation.id,
                this.invites[0].organisation.id
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.inviteOrganisationName = result.name;
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    done() {
        this.obsds.flyRecreationally();
    }

    previousScreen() {
        this.obsds.backFromPersonalCompleted();
    }

    nextScreen() {
        this.obsds.flyCommercially();
    }

    requestAccess() {
        this.onboardingService
            .requestOrganisationAccess()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: () => {
                    this.logging.success('Organisation access requested');
                    this.done();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while requesting access: ${error.message}`
                    );
                }
            });
    }
}
