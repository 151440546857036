import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input
} from '@angular/core';
import { StaticTable } from './static-table.component';
import { Subject, takeUntil } from 'rxjs';

/**
 * Handles the selection of table rows by displaying a checkbox that represents the
 * presence of a row in a subject in the parent component.
 */
@Component({
    selector: 'row-selector',
    template: `<input
        type="checkbox"
        [checked]="checked"
        [disabled]="disabled"
        (change)="onCheckedChange($event)"
    />`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowSelector {
    @Input() row: any;

    checked = false;
    disabled = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private table: StaticTable,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.table.selectedRow$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(selectedRows => {
                this.checked = selectedRows.includes(this.row);
                this.changeDetector.detectChanges();
            });
    }

    onCheckedChange(event: Event) {
        const isChecked = (event.target as HTMLInputElement).checked;
        const currentSelection = this.table.selectedRow$.getValue();
        const currentlyChecked = currentSelection.includes(this.row);
        if (
            (!isChecked && !currentlyChecked) ||
            (isChecked && currentlyChecked)
        ) {
            return;
        }

        if (isChecked) {
            this.table.selectedRow$.next([...currentSelection, this.row]);
        } else {
            this.table.selectedRow$.next(
                currentSelection.filter(r => r !== this.row)
            );
        }
    }
}
