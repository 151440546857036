import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterById' })
export class FilterById implements PipeTransform {
    transform(
        items: { id: number }[],
        itemsToFilter: { id: number }[] | number[] | number | null
    ) {
        if (items == null || items.length === 0 || itemsToFilter == null) {
            return items;
        }

        const idsToFilter: number[] =
            typeof itemsToFilter === 'number'
                ? [itemsToFilter]
                : typeof itemsToFilter[0] === 'number'
                ? itemsToFilter
                : itemsToFilter.map(item => item.id);

        return items.filter(item => idsToFilter.some(id => id === item.id));
    }
}
