import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    CreateOrganisationByUserCommand,
    OrganisationDto,
    OrganisationIdentifierDto,
    OrganisationSettingsDto,
    PersonsOrganisationDto,
    UpdateOrganisationCommand,
    UpdateOrganisationIdentifierCommand,
    UpdateOrganisationSettingsCommand,
    UpdateOrganisationTimeZoneCommand
} from '../model/api';
import {
    ChangeDetails,
    computePath,
    httpParamSerializer
} from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService {
    private baseUrl: string;
    private changeSource = new Subject<ChangeDetails<OrganisationDto>>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findById(organisationId: number, managingOrganisationId: number) {
        return this.http.get<OrganisationDto>(
            `${this.baseUrl}/webapi/organisations/${organisationId}`,
            {
                params: httpParamSerializer({
                    managingOrganisationId
                })
            }
        );
    }

    findByIdForUser(organisationId: number, managingOrganisationId: number) {
        return this.http.get<PersonsOrganisationDto>(
            `${this.baseUrl}/webapi/organisations/${organisationId}/forUser`,
            {
                params: httpParamSerializer({
                    managingOrganisationId
                })
            }
        );
    }

    updateOrganisationSettings(organisationId: number, command: UpdateOrganisationSettingsCommand) {
        return this.http.put<OrganisationSettingsDto>(
            `${this.baseUrl}/webapi/organisations/${organisationId}/settings`,
            command
        ).pipe(
            tap(settings =>
                this.changeSource.next({
                    type: ChangeDetails.Type.UPDATE,
                    changedId: organisationId
                })
            )
        );
    }

    uploadLogo(logoFile: File, organisationId: number) {
        const formData = new FormData();
        formData.append('logoFile', logoFile);

        return this.http.post(
            `${this.baseUrl}/webapi/organisations/${organisationId}/logo`,
            formData
        );
    }

    getLogoUrl(organisationId: number, managingOrganisationId?: number) {
        const timestamp = new Date().getTime();
        return computePath(`/webapi/organisations/${organisationId}/logo`, {
            decache: timestamp,
            managingOrganisationId
        });
    }

    getLogo(organisationId: number, managingOrganisationId?: number) {
        return this.http.get(
            `${this.baseUrl}/webapi/organisations/${organisationId}/logo`,
            {
                responseType: 'blob',
                params: httpParamSerializer({
                    managingOrganisationId
                })
            }
        );
    }

    createCommercialOrganisation(command: CreateOrganisationByUserCommand) {
        return this.http
            .post<OrganisationDto>(
                `${this.baseUrl}/webapi/organisations`,
                command
            )
            .pipe(
                tap(organisation =>
                    this.changeSource.next({
                        type: ChangeDetails.Type.CREATE,
                        changedId: organisation.id,
                        result: organisation
                    })
                )
            );
    }

    updateJurisdictions(
        organisationId: number,
        activeJurisdictionIds: number[],
        managingOrganisationId?: number
    ) {
        return this.http
            .put<OrganisationDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/jurisdictions`,
                {
                    activeJurisdictionIds,
                    managingOrganisationId
                }
            )
            .pipe(
                tap(organisation =>
                    this.changeSource.next({
                        type: ChangeDetails.Type.UPDATE,
                        changedId: organisation.id,
                        result: organisation
                    })
                )
            );
    }

    updateOrganisationName(
        organisationId: number,
        command: UpdateOrganisationCommand
    ) {
        return this.http
            .put<OrganisationDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}`,
                command
            )
            .pipe(
                tap(organisation =>
                    this.changeSource.next({
                        type: ChangeDetails.Type.UPDATE,
                        changedId: organisation.id,
                        result: organisation
                    })
                )
            );
    }

    updateOrganisationTimeZone(
        organisationId: number,
        command: UpdateOrganisationTimeZoneCommand
    ) {
        return this.http
            .put<OrganisationDto>(
                `${this.baseUrl}/webapi/organisations/${organisationId}/timeZone`,
                command
            )
            .pipe(
                tap(organisation =>
                    this.changeSource.next({
                        type: ChangeDetails.Type.UPDATE,
                        changedId: organisation.id,
                        result: organisation
                    })
                )
            );
    }

    findOrganisationsForPerson(personId: number) {
        return this.http.get<PersonsOrganisationDto[]>(
            `${this.baseUrl}/webapi/organisations/forPerson`,
            {
                params: httpParamSerializer({
                    personId
                })
            }
        );
    }

    findOrganisationIdentifiers(organisationId: number, integration: string) {
        return this.http.get<OrganisationIdentifierDto>(
            `${this.baseUrl}/webapi/organisations/${organisationId}/identifiers`,
            {
                params: httpParamSerializer({
                    integration
                })
            }
        );
    }

    updateOrganisationIdentifiers(
        organisationId: number,
        command: UpdateOrganisationIdentifierCommand
    ) {
        return this.http.put(
            `${this.baseUrl}/webapi/organisations/${organisationId}/identifiers`,
            command
        );
    }
}
