import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    CraftDetailsDto,
    CraftDto,
    FEATURE_INCIDENT_REPORTS,
    FlyFreelyError,
    FlyFreelyLoggingService,
    IncidentReportDto,
    IncidentReportsService,
    PersonDto,
    PersonsOrganisationDto,
    PreferencesService,
    UserService,
    WorkTracker,
    compareDates,
    hasAnyPermission,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatRpaPipe } from '@flyfreely-portal-ui/resource-ui';
import {
    FormatIncidentReportStatusPipe,
    FormatPersonPipe
} from '@flyfreely-portal-ui/ui';
import { WorkspaceStateService } from '@flyfreely-portal-ui/workspace';
import { findDefaultColumnSelection } from 'libs/flyfreely-table/src/lib/helpers';
import { dateRange } from 'libs/flyfreely-table/src/lib/search';
import { IncidentReportDialoguesService } from 'libs/missions/src/lib/incident-report-dialogues.service';
import { FormatDateTimePipe } from 'libs/ui/src/lib/pipes/generic/formatDateTime';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import * as moment from 'moment';
import { EMPTY, Subject, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { WidgetsPreferencesService } from '../widget-preferences.service';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';

const INCIDENT_REPORT_IDENTIFIER = 'incidentReports';

interface WidgetIncidentReportDto extends IncidentReportDto {
    convertedIncidentTime: moment.Moment;
}

@Component({
    selector: 'incident-reports-widget',
    templateUrl: './incident-reports-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class IncidentReportsWidgetComponent
    implements OnInit, OnDestroy, Widget
{
    organisation: PersonsOrganisationDto;
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    userPreferences: TableSetupUserPreferences;

    canEdit: boolean;

    incidentReportsFeature = [FEATURE_INCIDENT_REPORTS];

    public working: boolean;
    private workTracker: WorkTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    incidentReports: WidgetIncidentReportDto[];

    public IMG_URL: string;

    constructor(
        private incidentReportsService: IncidentReportsService,
        private incidentReportDialoguesService: IncidentReportDialoguesService,
        private userService: UserService,
        private preferencesService: PreferencesService,
        private logging: FlyFreelyLoggingService,
        private workspaceStateService: WorkspaceStateService,
        formatIncidentReportStatus: FormatIncidentReportStatusPipe,
        formatDateTimePipe: FormatDateTimePipe,
        formatPersonPipe: FormatPersonPipe,
        formatRpaPipe: FormatRpaPipe,
        private preferences: WidgetsPreferencesService
    ) {
        this.availableColumns = [
            {
                value: 'convertedIncidentTime',
                key: 'incidentTime',
                name: 'Incident Time',
                searchable: 'daterange',
                selectable: true,
                defaultSelection: true,
                formatterFunction: t => formatDateTimePipe.transform(t),
                searchFunction: dateRange,
                linkFunction: null,
                compareFunction: (a, b) => compareDates(a.rawData, b.rawData)
            },
            {
                value: 'reporter',
                name: 'Reporter',
                searchable: true,
                selectable: true,
                defaultSelection: false,
                formatterFunction: p => formatPersonPipe.transform(p),
                linkFunction: null,
                searchFunction: (text: string) => (person: PersonDto) =>
                    formatPersonPipe
                        .transform(person)
                        .toLowerCase()
                        .includes(text.toLowerCase())
            },
            {
                value: 'pilot',
                name: 'Pilot',
                searchable: true,
                selectable: true,
                defaultSelection: false,
                formatterFunction: p => formatPersonPipe.transform(p),
                searchFunction: (text: string) => (person: PersonDto) =>
                    formatPersonPipe
                        .transform(person)
                        .toLowerCase()
                        .includes(text.toLowerCase())
            },
            {
                value: 'rpa',
                name: 'RPA',
                searchable: true,
                selectable: true,
                defaultSelection: false,
                formatterFunction: c => formatRpaPipe.transform(c),
                searchFunction:
                    (text: string) => (craft: CraftDto | CraftDetailsDto) =>
                        craft
                            ? formatRpaPipe
                                  .transform(craft)
                                  .toLowerCase()
                                  .includes(text.toLowerCase())
                            : false
            },
            {
                value: 'status',
                name: 'Status',
                searchable: true,
                selectable: true,
                defaultSelection: false,
                searchOptions: this.incidentReportsService.getStatuses(),
                formatterFunction: (status: IncidentReportDto.Status) =>
                    formatIncidentReportStatus.transform(status)
            }
        ];

        this.columnSorting = null;

        this.tableSearch = null;

        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };

        this.selectedColumns = null;
    }

    ngOnInit(): void {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.loadPreferences();
                this.refreshPermissions();
                this.refreshIncidentReports();
            });
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.incidentReportsService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshIncidentReports());
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private loadPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferences
            .findPreferences(
                'incidentReportsListWidgetTable',
                this.organisation.id
            )
            .pipe(
                switchMap(preferences => {
                    if (preferences == null) {
                        return this.preferences
                            .findLegacyPreferences(
                                'incidentReportsListWidgetColumns',
                                this.organisation.id
                            )
                            .pipe(
                                map(pref => ({
                                    selectedColumns: pref,
                                    columnSorting: null,
                                    tableSearch: null,
                                    itemLimit: this.tableConfig.limit
                                })),
                                catchError(() => {
                                    this.handleDefaultPreferences();
                                    return of(null);
                                })
                            );
                    } else {
                        return of(preferences);
                    }
                }),
                catchError(() => {
                    this.selectedColumns = findDefaultColumnSelection(
                        this.availableColumns
                    );
                    return EMPTY;
                })
            )
            .subscribe(preferences => {
                this.applyPreferences(preferences);
            })
            .add(this.workTracker.createTracker);
    }

    private handleDefaultPreferences() {
        this.applyPreferences({});
    }

    private applyPreferences(preferences: TableSetupUserPreferences) {
        this.userPreferences = this.preferences.applyPreferences(
            preferences,
            this.availableColumns,
            this.tableConfig
        );

        this.selectedColumns = this.userPreferences.selectedColumns;
        this.columnSorting = this.userPreferences.columnSorting;
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit = this.userPreferences.itemLimit;

        this.preferences.updateDateRange(this.tableSearch, 'incidentTime');
    }

    private refreshPermissions() {
        const isSystemAdmin =
            this.userService.getCurrentUser().type === 'SYSTEM_ADMIN';
        this.canEdit =
            isSystemAdmin ||
            hasFeatureFlag(this.organisation, FEATURE_INCIDENT_REPORTS);

        // Update table link functions here otherwise they don't render properly if set in the constructor
        // This is because canEdit will always be null when the column setup is done in the constructor
        if (this.canEdit) {
            const columns = this.availableColumns?.reduce(
                (acc, c) =>
                    c.value === 'reporter' ||
                    c.value === 'convertedIncidentTime'
                        ? acc.concat(c)
                        : acc,
                []
            );
            if (columns != null) {
                columns.forEach(column => {
                    column.linkFunction = (report: IncidentReportDto) =>
                        this.showIncidentReport(report);
                });
            }
        }
    }

    private refreshIncidentReports() {
        if (this.organisation == null) {
            this.incidentReports = [];
        }

        this.incidentReportsService
            .find({ organisationId: this.organisation.id })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: reports => {
                    this.incidentReports = reports.map(r => ({
                        ...r,
                        convertedIncidentTime: r.incidentTime
                            ? moment(r.incidentTime)
                            : null
                    }));
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error, error.message);
                }
            })
            .add(this.workTracker.createTracker());
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        this.preferencesService
            .updatePreferences(
                'incidentReportsListWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    private showIncidentReport(report: IncidentReportDto) {
        const modal =
            this.incidentReportDialoguesService.showEditIncidentReport(
                report.mission.id,
                report.id,
                report
            );

        modal.onHide
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshIncidentReports());
    }
}

export const incidentReportWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: INCIDENT_REPORT_IDENTIFIER,
    component: IncidentReportsWidgetComponent,
    isAvailable: organisation =>
        hasFeatureFlag(organisation, FEATURE_INCIDENT_REPORTS) &&
        hasAnyPermission(
            organisation,
            PersonsOrganisationDto.Permissions.ORGANISATION_VIEW
        )
};
