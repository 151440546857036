import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    LocationDetailsDto,
    LocationFeatureDto,
    NameValue
} from '@flyfreely-portal-ui/flyfreely';
import { Angulartics2 } from 'angulartics2';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import {
    KmlFeatureGroups,
    KmlUploadService,
    locationFeatureTypes
} from '../kml-upload.service';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';

@Component({
    selector: 'kml-upload-dialogue',
    templateUrl: './kml-upload-dialogue.component.html'
})
export class KmlUploadDialogue {
    @Input() features: KmlFeatureGroups[];
    @Input() name: string;

    @Output()
    selectedFeatures = new EventEmitter<KmlFeatureGroups[]>();

    areas: KmlFeatureGroups;
    markers: KmlFeatureGroups;
    lines: KmlFeatureGroups;

    areaFeatureTypes: NameValue[];
    markerFeatureTypes: NameValue[];
    lineFeatureTypes: NameValue[];

    locationFeatureTypes = locationFeatureTypes;

    private ngUnsubscribe$ = new Subject<void>();

    private imported = false;

    constructor(
        private kmlUploadService: KmlUploadService,
        private commonDialoguesService: CommonDialoguesService,
        private modal: BsModalRef,
        modalOptions: ModalOptions,
        private angulartics2: Angulartics2
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.imported) {
                return Promise.resolve();
            } else {
                return this.commonDialoguesService.showConfirmationDialogue(
                    'Unsaved Changes',
                    `You have not completed the import, are you sure you want to cancel?`,
                    'Cancel',
                    () => Promise.resolve()
                );
            }
        };
    }

    ngOnInit() {
        this.setupFeatures();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findCategoryName(category: any) {
        return this.kmlUploadService.findFeatureType(category);
    }

    formatFeatureType(type: LocationDetailsDto.Type) {
        return locationFeatureTypes.find(t => t.value === type).name;
    }

    setupFeatures() {
        this.areas = this.features.find(f => f.featureType === 'Areas');
        this.markers = this.features.find(f => f.featureType === 'Markers');
        this.lines = this.features.find(f => f.featureType === 'Lines');
        this.areaFeatureTypes = this.findSuitableTypes('Polygon');
        this.markerFeatureTypes = this.findSuitableTypes('Point');
        this.lineFeatureTypes = this.findSuitableTypes('LineString');
    }

    findSuitableTypes(
        type:
            | 'Point'
            | 'MultiPoint'
            | 'LineString'
            | 'MultiLineString'
            | 'Polygon'
            | 'MultiPolygon'
            | 'GeometryCollection'
    ) {
        switch (type) {
            case 'Point':
                return locationFeatureTypes.filter(
                    t =>
                        t.value === LocationFeatureDto.Type.RP ||
                        t.value === LocationFeatureDto.Type.OBSERVER ||
                        t.value === LocationFeatureDto.Type.TAKEOFF ||
                        t.value === LocationFeatureDto.Type.LANDING ||
                        t.value === LocationFeatureDto.Type.TAKEOFF_LANDING ||
                        t.value === LocationFeatureDto.Type.POINT_OF_INTEREST
                );
            case 'Polygon':
                return locationFeatureTypes.filter(
                    t =>
                        t.value === LocationFeatureDto.Type.FLIGHT_AREA ||
                        t.value === LocationFeatureDto.Type.DANGER ||
                        t.value === LocationFeatureDto.Type.NO_FLY ||
                        t.value === LocationFeatureDto.Type.AREA_OF_INTEREST
                );
            case 'LineString':
                return locationFeatureTypes.filter(
                    t =>
                        t.value === LocationFeatureDto.Type.FLIGHT_PATH_LINE ||
                        t.value === LocationFeatureDto.Type.GENERAL_LINE
                );
            case 'GeometryCollection':
                return locationFeatureTypes;
        }
    }

    close() {
        this.modal.hide();
    }

    import() {
        const combined: KmlFeatureGroups[] = [];
        if (this.areas != null) {
            combined.push(this.areas);
        }
        if (this.markers != null) {
            combined.push(this.markers);
        }
        if (this.lines != null) {
            combined.push(this.lines);
        }
        this.selectedFeatures.next(combined);

        this.angulartics2.eventTrack.next({
            action: 'complex-kml-import',
            properties: {
                category: `kml-upload`,
                areas_count: this.areas?.features?.length ?? 0,
                points_count: this.markers?.features?.length ?? 0,
                lines_count: this.lines?.features?.length ?? 0
            }
        });
        this.imported = true;
        this.modal.hide();
    }
}

export function showKmlUploadDialogue(
    modalService: BsModalService,
    features: KmlFeatureGroups[],
    name: string
) {
    return modalService.show(KmlUploadDialogue, {
        ...MODAL_OPTIONS,
        class: 'modal-task',
        initialState: { features, name }
    });
}
