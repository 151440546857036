import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    CreateCurrencyConfigurationCommand,
    LosOperatingCategory,
    OperatingCategoryValuesDtoObject,
    CurrencyConfigurationDto,
    RpaCategoryOperatingCategory,
    TimeOfDayOperatingCategory,
    UpdateCurrencyConfigurationCommand,
} from '../model/api';
import { NameValue } from '../model/ui';
import { httpParamSerializer } from './service.helpers';

export const operatingConditionsTypeLookup = {
    [OperatingCategoryValuesDtoObject.Type.LOS]: 'Line Of Sight',
    [OperatingCategoryValuesDtoObject.Type.TIME_OF_DAY]: 'Time Of Day',
    [OperatingCategoryValuesDtoObject.Type.RPA_CATEGORY]: 'RPA Category',
    [OperatingCategoryValuesDtoObject.Type.RPA_MTOW]: 'RPA MTOW'
};

export const operatingConditionsValueLookup = {
    [LosOperatingCategory.Value.BVLOS]: 'BVLOS',
    [LosOperatingCategory.Value.EVLOS]: 'EVLOS',
    [LosOperatingCategory.Value.VLOS]: 'VLOS',
    [TimeOfDayOperatingCategory.Value.DAY]: 'Day',
    [TimeOfDayOperatingCategory.Value.NIGHT]: 'Night',
    [RpaCategoryOperatingCategory.Value.AEROPLANE]: 'Aeroplane',
    [RpaCategoryOperatingCategory.Value.AIRSHIP]: 'Airship',
    [RpaCategoryOperatingCategory.Value.HELICOPTOR]: 'Helicopter',
    [RpaCategoryOperatingCategory.Value.MULTIROTOR]: 'Multirotor',
    [RpaCategoryOperatingCategory.Value.OTHER]: 'Other',
    [RpaCategoryOperatingCategory.Value.POWERED_LIFT]: 'Powered Lift'
};

export const currencyOperatingCategoriesNameValueArray: NameValue[] = [
    {
        name: 'Line Of Sight',
        value: OperatingCategoryValuesDtoObject.Type.LOS
    },
    {
        name: 'Time Of Day',
        value: OperatingCategoryValuesDtoObject.Type.TIME_OF_DAY
    },
    {
        name: 'RPA Maximum Weight',
        value: OperatingCategoryValuesDtoObject.Type.RPA_MTOW
    },
    {
        name: 'RPA Category',
        value: OperatingCategoryValuesDtoObject.Type.RPA_CATEGORY
    }
];

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    markAsChanged() {
        this.changeSource.next();
    }

    findCurrencyConfiguration(
        personnelRegisterId: number,
        managingOrganisationId?: number
    ) {
        return this.http.get<CurrencyConfigurationDto[]>(
            `${this.baseUrl}/webapi/currency/config`,
            {
                params: httpParamSerializer({
                    personnelRegisterId,
                    managingOrganisationId
                })
            }
        );
    }

    createCurrencyConfiguration(command: CreateCurrencyConfigurationCommand) {
        return this.http
            .post<CurrencyConfigurationDto>(
                `${this.baseUrl}/webapi/currency/config`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateCurrencyConfiguration(
        currencyConfigurationId: number,
        command: UpdateCurrencyConfigurationCommand
    ) {
        return this.http
            .put<CurrencyConfigurationDto>(
                `${this.baseUrl}/webapi/currency/config/${currencyConfigurationId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    deleteCurrencyConfiguration(currencyConfigurationId: number) {
        return this.http
            .delete<void>(
                `${this.baseUrl}/webapi/currency/config/${currencyConfigurationId}`
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
