<div
    class="logo-container"
    [ngClass]="{ 'profile-picture': isProfilePicture && logoUrl != null }"
    ngfDrop
    [(validDrag)]="baseDropValid"
    [class.invalid-drag]="baseDropValid === false"
    [class.valid-drag]="baseDropValid"
    [fileDropDisabled]="!canUpdateLogo"
    accept="image/*"
    (fileChange)="saveLogo($event)"
>
    <loading-indicator
        style="margin: auto"
        [isLoading]="logoUrl == null"
    ></loading-indicator>
    <img *ngIf="logoUrl != null" [src]="logoUrl" />
</div>
<div class="edit-logo" *ngIf="logoUrl != null">
    <button
        *ngIf="canUpdateLogo && showEdit"
        class="btn btn-overlay"
        ngfSelect
        accept="image/*"
        (fileChange)="saveLogo($event)"
    >
        <span class="fal fa-pencil-alt"></span> Upload your
        {{ isProfilePicture ? 'profile' : 'logo' }}
    </button>
</div>
