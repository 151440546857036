<p>
    <span
        >Version: {{ uiVersion }}{{ gitSha ? '-' + gitSha : '' }}+{{
            status?.version || '-'
        }}</span
    >
    <span *ngIf="status != null"
        >Airspace Checker:
        {{ status.componentVersionMap['airspace-checker-service'] }}</span
    >
    <span
        ><a href="https://flyfreely.io/privacy-policy" target="_blank"
            >Privacy Policy</a
        ></span
    >
    <span
        ><a href="https://flyfreely.io/terms-of-use" target="_blank"
            >Terms of Service</a
        ></span
    >
</p>
