import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AttachmentHandler,
    CraftDetailsDto,
    CraftService,
    CreateCraftCommand,
    FlyFreelyError,
    FlyFreelyLoggingService,
    LockedCraftFields,
    ResourceSchematicComponentDto,
    ResourceTypeComponentsService,
    RpaTypeDto,
    RpaTypesService,
    UpdateCraftCommand,
    WorkTracker,
    fromLocalDate,
    toLocalDate
} from '@flyfreely-portal-ui/flyfreely';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { rpaTypeSearch } from 'libs/flyfreely/src/lib/tools/searchFunctions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { compareRpaType } from '../../helpers';

export interface ModelItem {
    id: number;
    name: string;
}

@Component({
    selector: 'rpa-edit',
    templateUrl: './rpa-edit.component.html'
})
export class RpaEdit implements OnInit, OnDestroy {
    @Input() rpa: CraftDetailsDto;
    @Input() managingOrganisationId: number;
    @Output() cancelled = new EventEmitter();
    @Output() update = new EventEmitter<CraftDetailsDto>();

    rpaForm: FormGroup;
    private workTracker = new WorkTracker();
    working = false;

    enhancedHelpActive: boolean;

    canUseNfc = false;
    attachmentsHandler: AttachmentHandler;
    componentDetails: ResourceSchematicComponentDto[];
    showComponentDetails = false;

    rpaType: RpaTypeDto;
    rpaTypeList: RpaTypeDto[];
    isChanged = false;
    lockedFields: LockedCraftFields;

    private ngUnsubscribe$ = new Subject<void>();

    rpaTypeSearch = rpaTypeSearch;

    constructor(
        private craftService: CraftService,
        private rpaTypesService: RpaTypesService,
        private resourceTypeComponentsService: ResourceTypeComponentsService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.screenAnalytics.helpActive$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(active => {
                this.enhancedHelpActive = active;
            });

        this.rpaForm = new FormGroup({
            nickname: new FormControl(this.rpa.nickname, [Validators.required]),
            isDummy: new FormControl(this.rpa.isDummy),
            nfcUid: new FormControl(this.rpa.nfcUid),
            callSign: new FormControl(this.rpa.callSign),
            manufacturerSerialNumber: new FormControl(
                this.rpa.manufacturerSerialNumber
            ),
            maximumVisualDistance: new FormControl(
                this.rpa.maximumVisualDistance
            ),
            rpaTypeId: new FormControl(this.rpa.rpaTypeId, Validators.required),
            procurementDate: new FormControl(
                this.rpa.procurementDate
                    ? fromLocalDate(this.rpa.procurementDate)
                    : null
            ),
            initialFlightTime: new FormControl(this.rpa.initialFlightTime),
            components: new FormArray([])
        });

        this.rpaForm.controls.rpaTypeId.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(value => {
                this.showComponentDetails = false;
                while (this.components.controls.length > 0) {
                    this.components.removeAt(0);
                }
                if (value != null && value !== -1) {
                    this.refreshCraftComponents();
                }
            });

        this.refreshCraftComponents();

        this.lockedFields = this.rpa.lockedFields;

        this.refreshRpaType();
        this.attachmentsHandler = this.craftService.attachmentHandler(
            this.rpa.id,
            this.managingOrganisationId
        );
    }

    ngOnDestroy() {
        if (this.attachmentsHandler != null) {
            this.attachmentsHandler.destroy();
        }
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshRpaType() {
        this.rpaTypesService
            .findRpaTypes(this.rpa.organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(models => {
                this.rpaTypeList = [...models];
                this.rpaTypeList.sort(compareRpaType);

                this.rpaType = this.rpaTypeList.find(
                    m => m.id === this.rpa.rpaTypeId
                );
            })
            .add(this.workTracker.createTracker());
    }

    refreshCraftComponents() {
        this.resourceTypeComponentsService
            .findResourceSchematicComponents('CRAFT', this.rpa.rpaTypeId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                result => {
                    this.componentDetails = result.components;
                    this.parseComponents();
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while refreshing craft components: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    private parseComponents() {
        if (this.rpa.initialComponentList == null) {
            return;
        }
        if (
            this.rpa.initialComponentList.find(c => c.componentId != null) ==
            null
        ) {
            if (this.componentDetails.find(c => c.id != null) == null) {
                return;
            }
            // This will load the IDs for each component in case of an RPA that has none.
            // Applies mainly to legacy RPA.
            this.rpa.initialComponentList = this.componentDetails.map(c => ({
                componentId: c.id,
                firmwareVersion: null,
                serialNumber: null
            }));
        }
        this.rpa.initialComponentList.forEach(comp => {
            const component = this.componentDetails.find(
                c => c.id === comp.componentId
            );
            if (component == null) {
                return;
            }
            const name = `${
                component.componentGroup?.name ?? component.componentType.name
            } ${component.designation}`;
            const icon =
                component.componentType.icon != null &&
                component.componentType.iconFamily != null &&
                component.componentType.iconFamily === 'FONT_AWESOME'
                    ? `fal fa-${component.componentType.icon}`
                    : null;
            const group = new FormGroup({
                name: new FormControl(undefined),
                id: new FormControl(undefined),
                icon: new FormControl(undefined),
                serialNumber: new FormControl(undefined),
                firmwareVersion: new FormControl(undefined),
                hasSerialNumber: new FormControl(undefined),
                hasFirmware: new FormControl(undefined)
            });
            group.patchValue({
                name: name,
                id: component.id,
                icon: icon,
                serialNumber: comp.serialNumber ?? '',
                firmwareVersion: comp.firmwareVersion ?? '',
                hasSerialNumber: component.hasSerialNumber,
                hasFirmware: component.hasFirmwareVersion
            });
            this.components.push(group);
        });
        const values = this.components.value;
        values.sort((a: any, b: any) => {
            if (a.componentGroup > b.componentGroup) {
                return 1;
            }
            if (a.componentGroup < b.componentGroup) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        this.components.setValue(values);
        this.showComponentDetails = true;
    }

    saveRPA() {
        if (this.rpaForm.invalid) {
            return;
        }
        const doneWorking = this.workTracker.createTracker();

        const values = this.rpaForm.value;
        const componentList = values.components.map((c: any) => ({
            componentId: c.id,
            serialNumber:
                c.serialNumber != null && c.serialNumber.length > 0
                    ? c.serialNumber
                    : null,
            firmwareVersion:
                c.firmwareVersion != null && c.firmwareVersion.length > 0
                    ? c.firmwareVersion
                    : null
        }));

        this.rpaForm.disable();

        const payload = {
            nickname: values.nickname,
            nfcUid: values.nfcUid,
            procurementDate: toLocalDate(values.procurementDate),
            initialFlightTime: values.initialFlightTime,
            rpaTypeId: values.rpaTypeId,
            manufacturerSerialNumber: values.manufacturerSerialNumber,
            callSign: values.callSign,
            isDummy: values.isDummy || false,
            maximumVisualDistance: values.maximumVisualDistance,
            initialComponentList: componentList
        } as UpdateCraftCommand | CreateCraftCommand;

        if (this.rpa.id) {
            this.craftService
                .updateCraft(this.rpa.id, payload)
                .then(
                    rpa => {
                        this.logging.success('RPA Updated');
                        doneWorking();
                        this.update.emit(rpa);
                        this.rpaForm.markAsPristine();
                        this.cancel();
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while updating RPA: ${error.message}`
                        );
                        doneWorking();
                    }
                )
                .finally(() => this.rpaForm.enable());
        } else {
            this.craftService
                .createCraft({
                    ...payload,
                    organisationId: this.managingOrganisationId
                })
                .then(
                    rpa => {
                        this.logging.success('RPA Created');
                        doneWorking();
                        this.update.emit(rpa);
                        this.rpaForm.markAsPristine();
                        this.cancel();
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while creating RPA: ${error.message}`
                        );
                        doneWorking();
                    }
                )
                .finally(() => this.rpaForm.enable());
        }
    }

    confirmCancel() {
        this.commonDialoguesService
            .showConfirmationDialogue(
                'Confirm Cancel',
                `You have unsaved changes, are you sure you want to cancel?`,
                'Yes',
                () => Promise.resolve()
            )
            .then(() => this.cancelEdit());
    }

    cancel() {
        if (this.rpaForm.dirty) {
            this.confirmCancel();
        } else {
            this.cancelEdit();
        }
    }

    cancelEdit() {
        this.cancelled.emit(null);
    }

    get components() {
        return this.rpaForm.get('components') as FormArray;
    }
}
