import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EditableModule } from '@flyfreely-portal-ui/editable';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ngfModule } from 'angular-file';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableModule } from 'ngx-easy-table';
import { NgxLoadingModule } from 'ngx-loading';
import { BulkImportDialogue } from './import-dialogue/import-dialogue.component';
import { BulkUploadDialogue } from './upload-dialogue/upload-dialogue.component';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';

@NgModule({
    imports: [
        CommonModule,
        TooltipModule,
        SharedUiModule,
        ngfModule,
        TableModule,
        NgxLoadingModule,
        EditableModule,
        FormsModule,
        ValidationModule,
        NgSelectModule,
        PopoverModule,
        AnalyticsModule,
        EnhancedHelpModule
    ],
    declarations: [BulkUploadDialogue, BulkImportDialogue],
    exports: [BulkUploadDialogue, BulkImportDialogue]
})
export class BulkUploadsModule {}
