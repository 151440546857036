import { Injectable } from '@angular/core';
import {
    AerodromeDetailsDto,
    AirspaceAuthorisationDto,
    AirspaceCheckCommand,
    ApproversMissionApprovalDto,
    DisplayableMissionDto,
    Organisation,
    RegisterRemotePilotCommand,
    RpaTypeDto,
    RuleCodeDto,
    RuleOutcome
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AirspaceCheckService } from './airspace-check';
import { AirspaceCheckDetailsDialogue } from './airspace-check/airspace-check-details/airspace-check-details-dialogue.component';
import { AirspaceAuthorisationManager } from './authorisation/airspace-authorisation-manager.service';
import { AirspaceAuthorisationAcknowledgementDialogue } from './authorisation/authorisation-agreement/authorisation-agreement-dialogue.component';
import { AirspaceConsentForTrialDialogue } from './authorisation/authorisation-permissions/casa-consent-for-trial/airspace-consent-for-trial.component';
import { AirspaceAuthorisationPreviewDialogue } from './authorisation/authorisation-preview/airspace-authorisation-preview.component';
import { RemotePilotRegistrationDialogue } from './authorisation/remote-pilot-registration/remote-pilot-registration.component';
import { AirspaceRuleDetailsDialogue } from './rule-details-dialogue/airspace-rule-details-dialogue.component';

@Injectable()
export class AirspaceDialoguesService {
    constructor(private modalService: BsModalService) {}

    showRuleDetailsDialogue(
        aerodromes: { [code: string]: AerodromeDetailsDto },
        ruleOutcomes: RuleOutcome[],
        message: RuleCodeDto
    ) {
        this.modalService.show(AirspaceRuleDetailsDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-warning',
            initialState: { aerodromes, ruleOutcomes, message }
        });
    }

    showAirspaceAuthorisationDialogue(
        airspaceAuthorisationManager: AirspaceAuthorisationManager,
        organisation: Organisation,
        checkCommand: AirspaceCheckCommand,
        mission: DisplayableMissionDto,
        approval: ApproversMissionApprovalDto,
        authorisations: AirspaceAuthorisationDto[],
        rpaTypes: RpaTypeDto[],
        requestNew?: boolean
    ) {
        return this.modalService.show(AirspaceAuthorisationPreviewDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                organisation,
                checkCommand,
                mission,
                approval,
                authorisations,
                rpaTypes,
                requestNew
            },
            providers: [
                {
                    provide: AirspaceAuthorisationManager,
                    useValue: airspaceAuthorisationManager
                }
            ]
        });
    }

    showAirspaceCheckDetailsDialogue(
        airspaceCheckService: AirspaceCheckService
    ) {
        return this.modalService.show<AirspaceCheckDetailsDialogue>(
            AirspaceCheckDetailsDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-lg',
                initialState: {},
                providers: [
                    {
                        provide: AirspaceCheckService,
                        useValue: airspaceCheckService
                    }
                ]
            }
        );
    }

    showCrpOptInDialogue() {
        return this.modalService.show<AirspaceConsentForTrialDialogue>(
            AirspaceConsentForTrialDialogue,
            { ...MODAL_OPTIONS, class: 'modal-md', initialState: {} }
        );
    }

    showAuthorisationAcknowledgementDialogue() {
        return this.modalService.show<AirspaceAuthorisationAcknowledgementDialogue>(
            AirspaceAuthorisationAcknowledgementDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-md',
                initialState: {}
            }
        );
    }

    showRemotePilotRegistrationDialogue(
        authorisationType: RegisterRemotePilotCommand.AuthorisationType,
        personId: number,
        managingOrganisaitonId: number,
        remotePilotPhoneNumber?: string
    ) {
        return this.modalService.show<RemotePilotRegistrationDialogue>(
            RemotePilotRegistrationDialogue,
            {
                ...MODAL_OPTIONS,
                class: 'modal-md',
                initialState: {
                    authorisationType,
                    personId,
                    managingOrganisaitonId,
                    remotePilotPhoneNumber
                }
            }
        );
    }
}
