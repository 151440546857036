<ng-container screenAnalytics="maintenance">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-maintenance.png" />
        </div>
        <h3  class="modal-title">{{createBulk === 'bulk'? "Create Maintenance Request": "Maintenance"}}</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
            data-cy="maintenanceDetailsCloseBtn"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="maintenanceForm" class="relative">
        <ngx-loading
            [show]="modalStatus === 'LOADING' || working"
        ></ngx-loading>
        <routing-error
            [status]="modalStatus"
            [noun]="'maintenance request'"
            [show]="modalStatus !== 'LOADING' && modalStatus !== 'LOADED'"
        ></routing-error>
        <div class="modal-body maintenance inner-container-and-footer">
            <div
                class="modal-body-content container-with-navigation"
                *ngIf="modalStatus == 'LOADED'"
            >
                <!-- Menu left -->
                <div class="container-menu">
                    <div class="item">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['request']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button type="button" (click)="scrollTo('request')">
                            <i class="far fa-list-ul tab-title-icon"></i>
                            Request Details
                        </button>
                    </div>

                    <div class="item" *ngIf="createBulk !== 'bulk'">
                        <div class="indicator">
                            <span
                                *ngIf="isActive['activities']"
                                class="fa fa-arrow-right"
                            ></span>
                        </div>
                        <button
                            type="button"
                            (click)="scrollTo('activities')"
                            [disabled]="maintenanceLog?.status === 'DRAFT'"
                        >
                            <i class="far fa-wrench tab-title-icon"></i>
                            Activities
                        </button>
                    </div>
                </div>

                <!-- Content -->
                <div
                    class="form-horizontal container-content"
                    scrollSpy
                    [spiedTags]="['DIV']"
                    (sectionChange)="onSectionChange($event)"
                    tabindex="0"
                >
                    <div id="request" formGroupName="maintenanceRequestGroup">
                        <div class="vertical-container container-fluid">
                            <h4 class="section-heading">
                                <span>REQUEST DETAILS</span>
                                <div></div>
                            </h4>
                            <maintenance-request-edit
                                *ngIf="overallStatus === 'DRAFT_REQUEST'"
                                [form]="maintenanceRequestGroup"
                                [createBulk] = createBulk
                                [organisation]="organisation"
                                [showScheduledMaintenance]="
                                    hasScheduledMaintenance
                                "
                            ></maintenance-request-edit>
                            <maintenance-request-edit
                                *ngIf="overallStatus === 'BULK_REQUEST'"
                                [form]="maintenanceBulkRequest"
                                [createBulk] = createBulk
                                [organisation]="organisation"
                                [showScheduledMaintenance]="
                                    hasScheduledMaintenance
                                "
                            ></maintenance-request-edit>



                            <maintenance-request-details
                                *ngIf="overallStatus !== 'DRAFT_REQUEST' && overallStatus !== 'BULK_REQUEST'"
                                [form]="identifierWorkInstructionsGroup"
                                [overallStatus]="overallStatus"
                                [maintenanceLogId]="maintenanceLogId"
                                [organisation]="organisation"
                                [hasMaintenanceNotices]="hasMaintenanceNotices"
                            >
                            </maintenance-request-details>
                            <component-service-time
                                *ngIf="
                                    resource &&
                                    resource.id &&
                                    overallStatus !== 'DRAFT_REQUEST' && overallStatus !== 'BULK_REQUEST'
                                "
                                [resourceId]="resource.id"
                                [resourceType]="maintenanceLog.resourceCategory"
                                [managingOrganisationId]="
                                    resource.organisationId
                                "
                                [hasScheduledMaintenance]="
                                    hasScheduledMaintenance
                                "
                                timeInServiceMode="maintenance"
                                [until]="maintenanceLog.requestTime"
                            ></component-service-time>
                        </div>
                    </div>
                    <div
                        id="activities"
                        *ngIf="
                            maintenanceLog != null &&
                            overallStatus != 'DRAFT_REQUEST' && overallStatus !== 'BULK_REQUEST'
                        "
                    >
                        <div class="vertical-container">
                            <h4 class="section-heading">
                                <span>ACTIVITIES</span>
                                <div></div>
                            </h4>
                            <div
                                *ngIf="
                                    overallStatus == 'DRAFT_ACTIVITY' &&
                                        !isMaintenanceController;
                                    else showActivities
                                "
                                class="alert alert-info ng-star-inserted"
                                role="alert"
                            >
                                This request has no activity
                            </div>
                            <ng-template #showActivities>
                                <tabset>
                                    <tab
                                        *ngFor="
                                            let activity of maintenanceLog.activities
                                        "
                                        heading="Activity {{ activity.number }} (# {{ maintenanceLog.id }}-{{ activity.id }})"
                                        [active]="
                                            activity.number == activeActivityTab
                                        "
                                    >
                                        <plan-highlight
                                            [featureFlags]="
                                                maintenanceTaskFeature
                                            "
                                        ></plan-highlight>
                                        <maintenance-activity-details
                                            *ngIf="activity.status != 'DRAFT'"
                                            [activity]="activity"
                                            [organisation]="organisation"
                                            [overallStatus]="overallStatus"
                                            [isMaintenanceController]="
                                                isMaintenanceController
                                            "
                                            [canUseMaintenanceTasks]="
                                                canUseMaintenanceTasks
                                            "
                                            [maintenanceLogId]="
                                                maintenanceLog.id
                                            "
                                        >
                                        </maintenance-activity-details>
                                        <test-flight-details
                                            *ngIf="
                                                maintenanceLog.resourceCategory ==
                                                    'CRAFT' &&
                                                activity.flightTests != null
                                            "
                                            [activity]="activity"
                                            [isMaintenanceController]="
                                                isMaintenanceController
                                            "
                                            [overallStatus]="overallStatus"
                                            [form]="maintenanceForm"
                                            [noOutstandingFlightTest]="
                                                noOutstandingFlightTest
                                            "
                                            [organisation]="organisation"
                                            [workTracker]="workTracker"
                                            (linkTestFlight)="
                                                showLinkTestFlight()
                                            "
                                            (unlinkTestFlight)="
                                                onUnlinkTestFlight($event)
                                            "
                                            (testFlightRequested)="
                                                showCreateTestFlight()
                                            "
                                        ></test-flight-details>
                                        <maintenance-activity-edit
                                            *ngIf="
                                                activity.status != 'COMPLETED'
                                            "
                                            [activity]="activity"
                                            [allActivities]="
                                                maintenanceLog.activities
                                            "
                                            [form]="maintenanceForm"
                                            [organisation]="organisation"
                                            [overallStatus]="overallStatus"
                                            [isMaintenanceController]="
                                                isMaintenanceController
                                            "
                                            [isMaintenancePerson]="
                                                isMaintenancePerson
                                            "
                                            [isExternalMaintenance]="
                                                isExternalMaintenance
                                            "
                                            [activityNumber]="activity.number"
                                            [canUseMaintenanceTasks]="
                                                canUseMaintenanceTasks
                                            "
                                        ></maintenance-activity-edit>
                                    </tab>
                                </tabset>
                            </ng-template>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            (overallStatus === 'SUBMITTED_ACTIVITY' ||
                                overallStatus === 'COMPLETED_ACTIVITY') &&
                            isMaintenanceController
                        "
                    >
                        <div
                            class="vertical-container container-fluid"
                            [formGroup]="finalisationGroup"
                        >
                            <h4
                                [ngClass]="{
                                    disabled: maintenanceControllerGroup.invalid
                                }"
                                class="section-heading"
                            >
                                <span>FINALISATION DETAILS</span>
                                <div></div>
                            </h4>

                            <div
                                [ngClass]="{
                                    disabled: maintenanceControllerGroup.invalid
                                }"
                                class="form-group input-content"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Resource Serviceability Outcome
                                        <field-validation-indicator
                                            validatorFormControlName="outcome"
                                            [noun]="'field'"
                                            [placement]="'right'"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="outcome"
                                        style="padding-top: 7px"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="RELEASE_TO_SERVICE"
                                            [disabled]="working"
                                            >Serviceable</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            btnRadio="RETIRE_FROM_SERVICE"
                                            [disabled]="working"
                                            >Retire</label
                                        >
                                    </div>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="maintenance"
                                componentName="outcome"
                            >
                            </enhanced-help>

                            <div
                                [ngClass]="{
                                    disabled: maintenanceControllerGroup.invalid
                                }"
                                class="form-group input-content"
                            >
                                <div class="col-xs-3 text-right">
                                    <label class="control-label">
                                        Scheduled Maintenance Completed
                                        <field-validation-indicator
                                            validatorFormControlName="scheduledMaintenanceCompleted"
                                            [noun]="'field'"
                                            [placement]="'right'"
                                        ></field-validation-indicator>
                                    </label>
                                </div>
                                <div class="col-xs-6">
                                    <div
                                        class="input-group"
                                        btnRadioGroup
                                        formControlName="scheduledMaintenanceCompleted"
                                        style="padding-top: 7px"
                                    >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            [btnRadio]="true"
                                            [disabled]="working"
                                            data-cy="scheduledMaintenanceSatisfied"
                                            >Scheduled Maintenance
                                            Satisfied</label
                                        >
                                        <label
                                            class="btn btn-xs btn-pill"
                                            [btnRadio]="false"
                                            [disabled]="working"
                                            data-cy="scheduledMaintenanceNotSatisfied"
                                            >Scheduled Maintenance Not
                                            Satisfied</label
                                        >
                                    </div>
                                </div>
                                <div class="col-xs-3"></div>
                            </div>

                            <enhanced-help
                                screenName="maintenance"
                                componentName="scheduledMaintenanceCompleted"
                            >
                            </enhanced-help>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div *ngIf="modalStatus == 'LOADED'">
                <ng-container *ngIf="overallStatus === 'DRAFT_REQUEST'">
                    <button
                        class="btn btn-default"
                        type="button"
                        (click)="saveDraftRequest()"
                        [tooltip]="draftSaveTooltip()"
                        placement="left"
                        [disabled]="
                            working ||
                            maintenanceForm.pristine ||
                            maintenanceRequestGroup.controls.maintenanceType
                                .invalid ||
                            maintenanceRequestGroup.controls.resourceId.invalid
                        "
                        data-cy="saveDraftRequest"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="requestMaintenance()"
                        [disabled]="working || maintenanceRequestGroup.invalid"
                        data-cy="draftRequestMaintenance"
                    >
                        Request
                    </button>
                </ng-container>

                <ng-container *ngIf="overallStatus === 'BULK_REQUEST'">
                    <button
                        class="btn btn-default"
                        type="button"
                        (click)="cancel()"
                        placement="left"
                        
                    >
                        Cancel
                    </button>
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="requestBulkMaintenance()"
                        [disabled]="working || maintenanceBulkRequest.invalid"
                        data-cy="draftRequestMaintenance"
                    >
                        Request
                    </button>
                </ng-container>

                <ng-container
                    *ngIf="
                        overallStatus == 'DRAFT_ACTIVITY' &&
                        isMaintenanceController
                    "
                >
                    <button
                        class="btn btn-danger pull-left"
                        type="button"
                        (click)="cancelMaintenance()"
                        [disabled]="
                            working ||
                            !maintenanceLog.availableActions.canCancel
                        "
                        data-cy="cancelMaintenance"
                    >
                        Cancel Maintenance
                    </button>
                    <button
                        class="btn btn-default"
                        type="button"
                        (click)="saveDraftActivity()"
                        [disabled]="working || maintenanceForm.pristine"
                        data-cy="saveDraftActivity"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="assignActivity()"
                        [disabled]="
                            maintenanceActivityGroup.invalid ||
                            !(
                                maintenanceActivityGroup.controls.maintainerId
                                    .value ||
                                maintenanceActivityGroup.controls
                                    .maintenanceOrganisation.value
                            ) ||
                            working
                        "
                        data-cy="assignActivity"
                    >
                        Assign
                    </button>
                </ng-container>

                <ng-container
                    *ngIf="
                        overallStatus == 'ASSIGNED_ACTIVITY' &&
                        (isMaintenancePerson || isMaintenanceController)
                    "
                >
                    <button
                        class="btn btn-default"
                        type="button"
                        (click)="saveDraftMaintenance()"
                        [disabled]="working || maintenanceForm.pristine"
                        data-cy="saveDraftMaintenance"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="submitMaintenance()"
                        [disabled]="maintenanceDetailsGroup.invalid || working"
                        data-cy="submitMaintenance"
                    >
                        Submit
                    </button>
                </ng-container>

                <ng-container
                    *ngIf="
                        overallStatus == 'SUBMITTED_ACTIVITY' &&
                        isMaintenanceController
                    "
                >
                    <button
                        class="btn btn-default"
                        type="button"
                        (click)="saveDraftSubmittedActivity()"
                        [disabled]="working || maintenanceForm.pristine"
                        data-cy="saveDraftSubmittedActivity"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="requestFurtherMaintenance()"
                        [disabled]="
                            maintenanceControllerGroup.invalid ||
                            working ||
                            (requiresFlightTest == true &&
                                noOutstandingFlightTest == false)
                        "
                        data-cy="requestFurtherMaintenance"
                    >
                        Request Further Maintenance
                    </button>
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="finaliseMaintenance()"
                        [disabled]="
                            maintenanceControllerGroup.invalid ||
                            finalisationGroup.invalid ||
                            working ||
                            (requiresFlightTest == true &&
                                noOutstandingFlightTest == false)
                        "
                        data-cy="finaliseMaintenance"
                    >
                        Finalise Maintenance
                    </button>
                </ng-container>
                <ng-container
                    *ngIf="
                        overallStatus == 'COMPLETED_ACTIVITY' &&
                        isMaintenanceController
                    "
                >
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="finaliseMaintenanceLog()"
                        [disabled]="finalisationGroup.invalid || working"
                        data-cy="finaliseMaintenance"
                    >
                        Finalise Maintenance
                    </button>
                    <button
                        *ngIf="
                            maintenanceActivity?.availableActions
                                ?.canUnfinaliseActivity
                        "
                        class="btn btn-default"
                        type="button"
                        (click)="unfinaliseMaintenanceActivity()"
                        [disabled]="working"
                    >
                        Unfinalise Activity
                    </button>
                </ng-container>
                <ng-container
                    *ngIf="
                        overallStatus == 'COMPLETED' && isMaintenanceController
                    "
                >
                    <button
                        *ngIf="maintenanceLog?.availableActions?.canUnfinalise"
                        class="btn btn-primary"
                        type="button"
                        (click)="unfinaliseMaintenanceLog()"
                        [disabled]="working"
                        data-cy="unfinaliseMaintenance"
                    >
                        Unfinalise Maintenance
                    </button>
                </ng-container>
            </div>
        </div>
    </form>
</ng-container>
