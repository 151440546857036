<ng-container screenAnalytics="bulk-upload-review">
    <div class="modal-header no-icon">
        <h3 class="modal-title">Bulk Import {{ entity }}</h3>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="cancel()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="fluid-container">
            <div
                class="horizontal-container"
                style="justify-content: space-between"
            >
                <h5>Select which rows to import:</h5>
                <button
                    *ngIf="
                        selectedIndices.length > 0 &&
                        bulkEditOptions != null &&
                        bulkEditColumns != null
                    "
                    type="button"
                    class="btn btn-link"
                    #popover="bs-popover"
                    [popover]="bulkEdit"
                    container="body"
                    [outsideClick]="false"
                    placement="left"
                    (click)="openPopover(popover)"
                >
                    <span class="fal fa-pencil-alt"></span>&nbsp;Edit Selected
                </button>
                <ng-template #bulkEdit>
                    <div style="min-width: 175px; width: 175px"></div>
                    <ng-select
                        class="bottom-buffer"
                        [items]="bulkEditColumns"
                        [clearable]="false"
                        [(ngModel)]="bulkEditColumn"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Select Column To Edit"
                        appendTo="body"
                        bindLabel="title"
                    >
                    </ng-select>
                    <input
                        *ngIf="
                            bulkEditColumn != null &&
                            (rowTypeLookup == null ||
                                rowTypeLookup[bulkEditColumn.key] == null ||
                                findRowType(bulkEditColumn.key) ==
                                    columnOptionTypes.TEXT)
                        "
                        type="text"
                        class="form-control"
                        placeholder="Enter the new value"
                        [name]="bulkEditValue"
                        [(ngModel)]="bulkEditValue"
                        [ngModelOptions]="{ standalone: true }"
                    />
                    <ng-select
                        *ngIf="
                            bulkEditColumn != null &&
                            rowTypeLookup != null &&
                            rowTypeLookup[bulkEditColumn.key] != null &&
                            findRowType(bulkEditColumn.key) ==
                                columnOptionTypes.SELECT
                        "
                        [items]="rowValueLookup[bulkEditColumn.key]"
                        [clearable]="false"
                        [(ngModel)]="bulkEditValue"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Select the new value"
                        appendTo="body"
                        bindValue="value"
                        bindLabel="value"
                    >
                        <ng-template ng-label-tmp let-item="item">
                            <span [title]="item.value">{{ item.value }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span [title]="item.name">{{ item.name }}</span>
                        </ng-template>
                    </ng-select>
                    <div
                        class="horizontal-container fill top-buffer"
                        style="justify-content: space-between"
                    >
                        <button
                            *ngIf="bulkEditColumn != null"
                            type="button"
                            class="btn btn-primary btn-sm"
                            [disabled]="bulkEditValue == null"
                            (click)="editMultiple()"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            class="btn btn-default btn-sm"
                            (click)="closePopover()"
                        >
                            {{ bulkEditColumn != null ? 'Cancel' : 'Done' }}
                        </button>
                    </div>
                </ng-template>
            </div>
            <div class="columns">
                <div class="column col-12">
                    <ngx-table
                        *ngIf="data != null"
                        class="buffer-top"
                        [data]="data"
                        (event)="eventEmitted($event)"
                        [configuration]="configuration"
                        [columns]="columns"
                        [filtersTemplate]="filtersTemplate"
                    >
                        <ng-template let-row let-index="index">
                            <td>
                                <div class="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            (change)="onChange(row, index)"
                                            [checked]="
                                                selectedIndices.includes(index)
                                            "
                                        />
                                        <span class="cr">
                                            <i
                                                class="cr-icon glyphicon glyphicon-ok"
                                            ></i>
                                        </span>
                                    </label>
                                </div>
                            </td>
                            <ng-container *ngFor="let col of columns">
                                <td
                                    [ngClass]="{
                                        'invalid-cell': isInvalid(
                                            col.key,
                                            index,
                                            row
                                        ),
                                        'warning-cell': hasWarning(
                                            col.key,
                                            index,
                                            row
                                        )
                                    }"
                                >
                                    <div class="horizontal-container">
                                        <editable
                                            [confirmationButtons]="true"
                                            [cancelOnClickOutside]="false"
                                            (modeChange)="editing = !$event"
                                            (update)="validateRow(row, index)"
                                            class="fill"
                                        >
                                            <ng-template viewMode>
                                                <ng-container
                                                    *ngIf="!row[col.key].length"
                                                    ><em
                                                        >Click to edit</em
                                                    ></ng-container
                                                >
                                                <ng-container
                                                    *ngIf="row[col.key].length"
                                                    >{{
                                                        row[col.key]
                                                    }}</ng-container
                                                >
                                            </ng-template>
                                            <ng-template editMode>
                                                <input
                                                    *ngIf="
                                                        rowTypeLookup == null ||
                                                        rowTypeLookup[
                                                            col.key
                                                        ] == null ||
                                                        findRowType(col.key) ==
                                                            columnOptionTypes.TEXT
                                                    "
                                                    type="text"
                                                    class="form-control"
                                                    [name]="row[col.key]"
                                                    [(ngModel)]="row[col.key]"
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    editableOnEnter
                                                />
                                                <ng-select
                                                    *ngIf="
                                                        rowTypeLookup != null &&
                                                        rowTypeLookup[
                                                            col.key
                                                        ] != null &&
                                                        findRowType(col.key) ==
                                                            columnOptionTypes.SELECT
                                                    "
                                                    [items]="
                                                        rowValueLookup[col.key]
                                                    "
                                                    [clearable]="false"
                                                    [(ngModel)]="row[col.key]"
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    appendTo="body"
                                                    bindValue="value"
                                                >
                                                    <ng-template
                                                        ng-label-tmp
                                                        let-item="item"
                                                    >
                                                        <span
                                                            [title]="item.value"
                                                            >{{
                                                                item.value
                                                            }}</span
                                                        >
                                                    </ng-template>
                                                    <ng-template
                                                        ng-option-tmp
                                                        let-item="item"
                                                    >
                                                        <span
                                                            [title]="item.name"
                                                            >{{
                                                                item.name
                                                            }}</span
                                                        >
                                                    </ng-template>
                                                </ng-select>
                                            </ng-template>
                                        </editable>
                                        <action-required
                                            *ngIf="
                                                (isInvalid(
                                                    col.key,
                                                    index,
                                                    row
                                                ) ||
                                                    hasWarning(
                                                        col.key,
                                                        index,
                                                        row
                                                    )) &&
                                                findErrorText(col.key, index) !=
                                                    null
                                            "
                                        >
                                            {{ findErrorText(col.key, index) }}
                                        </action-required>
                                    </div>
                                </td>
                            </ng-container>
                        </ng-template>

                        <ng-template #filtersTemplate>
                            <th></th>
                            <ng-container *ngFor="let col of displayColumns">
                                <th>
                                    <label>
                                        <ng-select
                                            *ngIf="
                                                col.options != null &&
                                                col.options.type == 'SELECT'
                                            "
                                            [items]="col.options.values"
                                            bindLabel="name"
                                            bindValue="value"
                                            appendTo="body"
                                            [clearable]="false"
                                            [(ngModel)]="col.options.result"
                                            [placeholder]="
                                                col.options.placeholder
                                            "
                                            (change)="revalidateAll()"
                                        >
                                        </ng-select>
                                    </label>
                                </th>
                            </ng-container>
                        </ng-template>
                    </ngx-table>
                </div>
            </div>
        </div>
        <!-- div class="contents vertical-container container-scroll top-buffer">
            <ng-container *ngIf="failedImportText != null && !working">
                <span
                    *ngFor="let text of failedImportText"
                    class="import-error-text bottom-buffer"
                >
                    <span class="fal fa-exclamation-triangle"></span>&nbsp;{{ text }}
                </span>
            </ng-container>
        </div> -->
    </div>
    <ngx-loading [show]="working"></ngx-loading>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-primary"
            (click)="import()"
            [disabled]="working || selectedIndices.length == 0 || editing"
            [tooltip]="generateTooltip()"
            placement="left"
        >
            Import
        </button>
    </div>
</ng-container>
