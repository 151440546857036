import {
    AssignMaintenanceScheduleCommand,
    MaintenanceScheduleAssignmentDto
} from '@flyfreely-portal-ui/flyfreely';
import { Observable } from 'rxjs';

/**
 * This defines the interface that is used to provide resource specific service.
 */
export abstract class ResourceMaintenanceService {
    abstract setup(resourceId: number): void;
    abstract assign(
        command: AssignMaintenanceScheduleCommand
    ): Observable<MaintenanceScheduleAssignmentDto>;
}
