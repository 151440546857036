import { Component } from '@angular/core';
import { SetupGuideChecklistService } from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
    bounceInLeftOnEnterAnimation,
    bounceOutLeftOnLeaveAnimation
} from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { SetupGuideActivityDto } from '@flyfreely-portal-ui/flyfreely';

@Component({
    selector: 'mini-video-player',
    templateUrl: './mini-video-player.component.html',
    styleUrls: ['./styles.scss'],
    animations: [
        bounceInLeftOnEnterAnimation(),
        bounceOutLeftOnLeaveAnimation()
    ]
})
export class MiniVideoPlayerComponent {
    activityTitle: string;
    play: boolean;
    activityStepId: number;
    task: SetupGuideActivityDto.StepIdentifier;

    videoUrl: string;
    videoThumbnail: string;
    bottom = true;
    maximised = true;
    percentagePlayed = 0;

    loadInPip = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private setupGuideChecklistService: SetupGuideChecklistService,
        private angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        this.setupGuideChecklistService.activeVideo$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(video => {
                if (this.videoUrl != null && video.play === true) {
                    // Hot swap URL to enable video to simply update
                    this.videoUrl = null;
                    return new Promise(resolve =>
                        setTimeout(resolve, 100)
                    ).then(() => {
                        this.videoUrl = video.videoUrl;
                        this.videoThumbnail = video.videoThumbnail;
                        this.activityStepId = video.stepId;
                        this.activityTitle =
                            video.activitytitle === ''
                                ? null
                                : video.activitytitle;
                        this.maximised = video.play;
                        this.task = video.task;
                    });
                }
                this.activityTitle =
                    video.activitytitle === '' ? null : video.activitytitle;
                this.play = video.play;
                this.videoThumbnail = video.videoThumbnail;
                this.activityStepId = video.stepId;
                this.task = video.task;
                if (video.videoUrl != null || video.videoUrl !== '') {
                    this.videoUrl = video.videoUrl;
                    this.maximised = true;
                } else {
                    this.videoUrl = null;
                }
            });

        this.setupGuideChecklistService.enablePictureInPicture$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(enabled => {
                this.loadInPip = enabled;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    minimise() {
        this.maximised = !this.maximised;
    }

    minimiseForPip(maximised: boolean) {
        this.loadInPip = !maximised;
        this.setupGuideChecklistService.updatePictureInPicturePreferences(
            this.loadInPip
        );
        this.maximised = maximised;
    }

    moveAcross() {
        this.bottom = !this.bottom;
    }

    updatePercentage(percentage: number) {
        this.percentagePlayed = percentage;
    }

    close() {
        this.angulartics2.eventTrack.next({
            action: 'mini-video-closed',
            properties: {
                category: `setup-guide`,
                label: this.activityStepId.toString(),
                value: this.percentagePlayed.toString(),
                videoUrl: this.videoUrl,
                task: this.task
            }
        });
        this.percentagePlayed = 0;
        this.setupGuideChecklistService.hideVideo();
    }
}
