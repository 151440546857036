import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ActiveInteractionChangedMapEvent,
    MapEventType,
    MapInteractionState,
    ToolbarButton,
    ToolbarButtonClickedMapEvent
} from '../../events';
import { MapService } from '../../map.service';
@Component({
    selector: 'map-toolbar-measurement-button',
    templateUrl: './map-toolbar-measurement-button.component.html',
    styleUrls: ['./map-toolbar-measurement-button.component.scss', '../../flyfreely-map/flyfreely-map.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MapToolbarMeasurementButtonComponent implements OnInit {
    ToolbarButton = ToolbarButton;

    tooltipMessage: string;

    private ngUnsubscribe$ = new Subject<void>();
    @Input() enabled: boolean = false;
    @Input() buttonType: ToolbarButton;

    get mapInteraction(): MapInteractionState {
        switch (this.buttonType) {
            case ToolbarButton.MeasurementLine:
                return MapInteractionState.MeasurementLine;
            case ToolbarButton.MeasurementRadius:
                return MapInteractionState.MeasurementRadius;
        }
        return null;
    }
    isActive: boolean = false;

    constructor(private mapService: MapService) {}

    ngOnInit() {
        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event =>
                        event.type === MapEventType.activeInteractionChanged
                )
            )
            .subscribe((event: ActiveInteractionChangedMapEvent) => {
                this.isActive =
                    event.payload.interaction === this.mapInteraction;
            });

        this.tooltipMessage = this.getTooltipMessage();
    }

    ngOnDestroy(): any {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    rulerButtonClick() {
        if (!this.enabled) {
            return;
        }
        this.mapService.mapEvent$.next(
            new ToolbarButtonClickedMapEvent({
                button: this.buttonType
            })
        );
    }

    private getTooltipMessage() {
        switch (this.buttonType) {
            case ToolbarButton.MeasurementLine:
                return 'Distance measurement';
            case ToolbarButton.MeasurementRadius:
                return 'Radius measurement';
        }
    }
}
