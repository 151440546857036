<div class="modal-header">
    <div class="panel-heading-icon">
        <img [src]="IMG_URL + '/icons/icon-batteries.png'" />
    </div>
    <h3 class="modal-title">Battery Type</h3>
    <button type="button" (click)="hide()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body inner-container no-footer">
    <ng-container *ngIf="isAdding">
        <div class="container-scroll modal-body-content">
            <div class="container-with-footer">
                <formly-form
                    [fields]="addEditFields"
                    [model]="batteryTypeModel"
                    [options]="options"
                    [form]="batteryTypeAddEditForm"
                ></formly-form>
                <div class="action-container">
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="save()"
                        [disabled]="
                            batteryTypeAddEditForm.invalid ||
                            batteryTypeAddEditForm.pristine ||
                            working
                        "
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isAdding">
        <tabset
            #batteryTabs
            vertical="true"
            type="pills"
            class="tab-set-vertical"
        >
            <tab
                heading="Edit Battery"
                (selectTab)="isEditing = true"
                (deselect)="isEditing = false"
            >
                <div class="container-scroll modal-body-content">
                    <div class="container-with-footer">
                        <formly-form
                            [fields]="addEditFields"
                            [model]="batteryTypeModel"
                            [options]="options"
                            [form]="batteryTypeAddEditForm"
                        ></formly-form>
                        <div class="action-container">
                            <button
                                class="btn btn-primary"
                                type="button"
                                (click)="save()"
                                [disabled]="
                                    batteryTypeAddEditForm.invalid ||
                                    batteryTypeAddEditForm.pristine ||
                                    working ||
                                    !canEdit
                                "
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="Associated RPAs">
                <div class="container-scroll modal-body-content">
                    <compatible-rpa-types
                        [batteryType]="batteryType"
                        [organisationId]="organisation?.id"
                        [disabled]="!canEdit"
                    ></compatible-rpa-types>
                </div>
            </tab>
        </tabset>
    </ng-container>
</div>
