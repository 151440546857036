<div
    *ngIf="helpObject != null && !hidden"
    class="alert alert-info horizontal-container wrap relative"
    imgPath="/stars@3x.png"
    [ngClass]="{
        narrow: layout === 'NARROW',
        'upsell-large': layout === 'LARGE'
    }"
>
    <button
        *ngIf="layout !== 'LARGE'"
        type="button"
        class="close"
        data-dismiss="alert"
        (click)="hide()"
    >
        <span aria-hidden="true">×</span>
    </button>
    <div
        *ngIf="layout !== 'NARROW'"
        class="fcol-xs-12"
        [ngClass]="{
            'fcol-sm-4': layout === 'NORMAL',
            'fcol-sm-2': layout === 'LARGE'
        }"
    ></div>
    <div
        class="content fcol-xs-12"
        [ngClass]="{
            'fcol-sm-4': layout === 'NORMAL',
            'fcol-sm-8': layout === 'LARGE'
        }"
    >
        <embedded-video-player
            *ngIf="
                helpObject?.supportVideoUrl?.length > 0 && layout === 'LARGE'
            "
            [videoUrl]="helpObject.supportVideoUrl"
            [analyticsLabel]="featureName"
        ></embedded-video-player>
        <div
            *ngIf="helpObject?.helpText != null"
            [innerHTML]="helpObject?.helpText | autolink"
        ></div>
        <ng-content></ng-content>
        <!-- Using "{ reload: 'orgadmintab' }" below will force the route to refresh only for the orgadmintab state, which should allow internal routing within the state -->
        <button
            *ngIf="!isPreview && layout !== 'NARROW'"
            type="button"
            class="btn btn-default top-buffer"
            analyticsOn="click"
            analyticsAction="upgrade"
            analyticsCategory="upsell"
            [analyticsLabel]="featureName"
            (click)="navigate('subscriptions', feature, featureFlag)"
        >
            Upgrade Now
        </button>
        <button
            *ngIf="isPreview && layout !== 'NARROW'"
            type="button"
            class="btn btn-default top-buffer"
            (click)="(null)"
        >
            Upgrade Now
        </button>
    </div>
    <div
        *ngIf="layout !== 'NARROW'"
        class="links fcol-xs-12"
        [ngClass]="{
            'fcol-sm-4': layout !== 'LARGE',
            'fcol-sm-2': layout === 'LARGE'
        }"
    >
        <div>
            <a
                *ngIf="
                    helpObject?.supportVideoUrl?.length > 0 &&
                    layout !== 'LARGE'
                "
                (click)="showVideo()"
                ><span class="fa fa-clapperboard-play black-text"></span>
                Video</a
            >
        </div>
        <div>
            <a
                *ngIf="helpObject?.knowledgeBaseUrl?.length > 0"
                [href]="helpObject?.knowledgeBaseUrl"
                target="_blank"
                (click)="trackKnowledgeBase()"
                ><span class="fas fa-question-circle black-text"></span>
                Knowledge Base</a
            >
        </div>
    </div>
    <div
        *ngIf="layout === 'NARROW'"
        class="links fcol-xs-12 horizontal-container fill vertical-align top-buffer"
    >
        <button
            *ngIf="!isPreview && layout === 'NARROW'"
            type="button"
            class="btn btn-default right-buffer fill"
            analyticsOn="click"
            analyticsAction="upgrade"
            analyticsCategory="upsell"
            [analyticsLabel]="featureName"
            (click)="navigate('subscriptions', feature, featureFlag)"
        >
            Upgrade Now
        </button>
        <button
            *ngIf="isPreview && layout === 'NARROW'"
            type="button"
            class="btn btn-default right-buffer fill"
            (click)="(null)"
        >
            Upgrade Now
        </button>
        <div class="vertical-container text-right">
            <div>
                <a
                    *ngIf="
                        helpObject?.supportVideoUrl?.length > 0 &&
                        layout !== 'LARGE'
                    "
                    (click)="showVideo()"
                    ><span class="fa fa-clapperboard-play black-text"></span>
                    Video</a
                >
            </div>
            <div>
                <a
                    *ngIf="helpObject?.knowledgeBaseUrl?.length > 0"
                    [href]="helpObject?.knowledgeBaseUrl"
                    target="_blank"
                    (click)="trackKnowledgeBase()"
                    ><span class="fas fa-question-circle black-text"></span>
                    Knowledge Base</a
                >
            </div>
        </div>
    </div>
</div>
