<div>
    <button
        type="button"
        class="btn btn-default"
        [disabled]="working"
        (click)="downloadAllUsersAuthorisations()"
    >
        Download All
    </button>
    <p class="help-block top-buffer" [innerHTML]="disclaimer"></p>
</div>
