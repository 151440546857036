import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    FEATURE_BILLING,
    FlyFreelyError,
    FlyFreelyLoggingService,
    HistoricalPersonDto,
    InviteDto,
    InvitesService,
    NameValue,
    PersonRolesDto,
    PersonService,
    PersonsOrganisationDto,
    WorkTracker,
    hasAnyPermission,
    hasFeatureFlag,
    FEATURE_WORKGROUPS,
    toLookup,
    PreferencesService
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig,
    TableSetupUserPreferences
} from '@flyfreely-portal-ui/flyfreely-table';
import {
    OrganisationSubscriptionsService,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import { PersonnelDialoguesService } from 'libs/personnel/src/lib/personnel-dialogues.service';
import { isCurrentSubscription } from 'libs/subscriptions/src/lib/helpers';
import { OrganisationSubscriptionsState } from 'libs/subscriptions/src/lib/interfaces';
import { FormatColumnArrayPipe } from 'libs/ui/src/lib/pipes';
import { Widget } from 'libs/widget/src/lib/widget.directive';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    Subject,
    combineLatest,
    merge,
    of
} from 'rxjs';
import {
    catchError,
    concatMap,
    map,
    shareReplay,
    startWith,
    switchMap,
    takeUntil
} from 'rxjs/operators';
import { WidgetsPreferencesService } from '../widget-preferences.service';
import { WorkGroup } from 'libs/flyfreely/src/lib/services/workgroups.service';
import { WorkgroupsDataService } from '@flyfreely-portal-ui/organisation-admin';
import { WidgetRegistration } from '@flyfreely-portal-ui/widget';

interface TablePersonnel {
    lastName?: string;
    firstName?: string;
    email: string;
    phoneNumber?: string;
    status?: string;
    inviteId?: number;
    roles: PersonRolesDto.Roles[];
}

enum View {
    CURRENT = 'CURRENT',
    HISTORICAL = 'HISTORICAL',
    INVITED = 'INVITED'
}

const PERSONNEL_IDENTIFIER = 'personnelList';

@Component({
    selector: 'personnel-widget',
    templateUrl: './personnel-widget.component.html',
    styles: [
        `
            :host {
                scroll-margin-top: 60px;
            }
        `
    ]
})
export class PersonnelWidgetComponent implements OnInit, OnDestroy, Widget {
    organisation: PersonsOrganisationDto;

    availableColumns: TableColumn[];
    tableConfig: TableConfig;
    selectedColumns: string[];
    inviteTableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;

    canAddPersonnel: boolean;
    canListPersonnel: boolean;
    canReport = false;
    canViewBilling: boolean;
    canUseWorkgroups: boolean = false;

    licences: OrganisationSubscriptionsState;

    view$ = new BehaviorSubject(View.CURRENT);

    selectedPersonnelListOptions = [
        {
            value: View.CURRENT,
            name: 'Current Personnel'
        },
        {
            value: View.INVITED,
            name: 'Pending Invites'
        },
        {
            value: View.HISTORICAL,
            name: 'Archived Personnel'
        }
    ];
    selectedPersonnelList: NameValue = {
        value: View.CURRENT,
        name: 'Current Personnel'
    };

    tablePersonnel: TablePersonnel[];
    inviteColumns: string[];

    userPreferences: TableSetupUserPreferences;

    private refreshInvites = new Subject<void>();
    private refreshHistoricalPersonnel = new Subject<void>();

    personnel$ = new Subject<PersonRolesDto[]>();
    historicalPersonnel$: Observable<HistoricalPersonDto[]>;
    invites$: Observable<TablePersonnel[]>;

    public working: boolean;
    private workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private personService: PersonService,
        private personnelDialoguesService: PersonnelDialoguesService,
        private preferencesService: PreferencesService,
        private subscriptionsService: OrganisationSubscriptionsService,
        private workspaceStateService: WorkspaceStateService,
        private invitesService: InvitesService,
        private formatColumnArrayPipe: FormatColumnArrayPipe,
        private logging: FlyFreelyLoggingService,
        private preferences: WidgetsPreferencesService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private workgroupDataService: WorkgroupsDataService
    ) {
        this.selectedColumns = null;
        this.inviteColumns = ['email', 'roles'];

        this.columnSorting = null;

        this.tableSearch = null;

        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };
        this.inviteTableConfig = {
            limit: 10,
            actions: [
                {
                    action: (item: TablePersonnel) => this.revokeInvite(item),
                    tooltip: 'Revoke invite',
                    icon: 'fal fa-trash-alt'
                }
            ],
            limitSelection: [10, 25, 50, 100]
        };
    }

    ngOnInit() {
        this.workspaceStateService.currentLoadedOrganisation$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.organisation = organisation.organisation;
                this.refreshPermissions();
                this.refreshPersonnel();
                this.workgroupDataService.findWorkgroups(this.organisation.id);
                this.setupColumns();
                this.loadPreferences();
                this.refreshInvites.next();
                this.refreshHistoricalPersonnel.next();
            });

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        merge(
            this.workgroupDataService.personnelChange$,
            this.personService.change$
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.subscriptionsService.refresh();
                this.refreshPersonnel();
            });

        this.invites$ = merge(
            this.invitesService.change$,
            this.refreshInvites.pipe(startWith(undefined))
        ).pipe(
            takeUntil(this.ngUnsubscribe$),
            concatMap(() => {
                // This is doubled up here so the subscription service can react to the widget's internal changes too.
                // Removing this causes update issues in the subscription service under certain circumstances.
                this.subscriptionsService.refresh();
                return this.invitesService.findInvites(this.organisation.id);
            }),
            map(invites =>
                invites
                    .filter(
                        invite => invite.status === InviteDto.Status.PENDING
                    )
                    .map(invite => ({
                        email: invite.recipientEmail,
                        inviteId: invite.id,
                        roles: invite.roles
                    }))
            ),
            shareReplay()
        );

        this.historicalPersonnel$ = this.refreshHistoricalPersonnel
            .pipe(startWith(undefined))
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                concatMap(() => {
                    if (this.organisation != null) {
                        return this.personService.findHistoricalPersonnel(
                            this.organisation.id
                        );
                    }
                    return [];
                }),
                shareReplay()
            );

        combineLatest([
            this.workgroupDataService.workgroup$,
            this.view$,
            this.personnel$,
            this.historicalPersonnel$,
            this.canAddPersonnel ? this.invites$ : of([])
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    workgroups,
                    view,
                    personnel,
                    historicalPersonnel,
                    invites
                ]) => {
                    this.tablePersonnel = (() => {
                        switch (view) {
                            case View.CURRENT:
                                return this.processPersonnelData(
                                    workgroups,
                                    personnel
                                );
                            case View.HISTORICAL:
                                return historicalPersonnel;
                            case View.INVITED:
                                return invites;
                        }
                    })();
                }
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshPersonnel() {
        this.tablePersonnel = null;
        if (!this.organisation) {
            return [];
        }

        this.personService
            .findPersonnel(this.organisation.id)
            .subscribe({
                next: results => {
                    this.personnel$.next(results);
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error refreshing Personnel: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
    }

    private processPersonnelData(
        workgroups: WorkGroup[],
        personnel: PersonRolesDto[]
    ) {
        if (!this.canUseWorkgroups) {
            return personnel;
        }
        const workGroupMap = workgroups.reduce(
            toLookup,
            {} as { [workgroupId: number]: WorkGroup }
        );
        return personnel.map(personnel =>
            Object.assign({}, personnel, {
                workgroupNameList: personnel.workgroupIdList.map(
                    id => workGroupMap[id].name
                )
            })
        );
    }

    private setupColumns() {
        const roles = this.personService.getPersonRoles();
        const roleSearchOptions = Object.keys(roles).map(r => ({
            value: r,
            name: roles[r]
        }));
        this.availableColumns = [
            {
                value: 'lastName',
                name: 'Last Name',
                searchable: true,
                selectable: false,
                defaultSelection: false,
                linkFunction: (person: PersonRolesDto) =>
                    this.showPerson(person)
            },
            {
                value: 'firstName',
                name: 'First Name',
                searchable: true,
                selectable: false,
                defaultSelection: false,
                linkFunction: (person: PersonRolesDto) =>
                    this.showPerson(person)
            },
            {
                value: 'email',
                name: 'Email',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'phoneNumber',
                name: 'Phone Number',
                searchable: true,
                selectable: true,
                defaultSelection: false
            },
            {
                value: 'roles',
                name: 'Roles',
                searchable: true,
                selectable: true,
                defaultSelection: true,
                searchOptions: roleSearchOptions,
                searchFunction:
                    (roleSearch: PersonRolesDto.Roles) =>
                    (roles: PersonRolesDto.Roles[]) =>
                        roles.find(r => r === roleSearch) != null,
                formatterFunction: (a: PersonRolesDto.Roles[]) =>
                    a == null
                        ? null
                        : this.formatColumnArrayPipe.transform(
                              a.map(r => roles[r]),
                              2
                          )
            },
            ...(this.canUseWorkgroups
                ? [
                      {
                          value: 'workgroupNameList',
                          name: 'Workgroup',
                          searchable: true,
                          selectable: true,
                          defaultSelection: true,
                          formatterFunction: (a: string[]) =>
                              a == null
                                  ? null
                                  : this.formatColumnArrayPipe.transform(a, 2)
                      } as TableColumn
                  ]
                : [])
        ];
    }

    private loadPreferences() {
        if (!this.organisation) {
            return;
        }
        this.preferences
            .findPreferences('personnelListWidgetTable', this.organisation.id)
            .pipe(
                switchMap(preferences => {
                    if (preferences == null) {
                        return this.preferences
                            .findLegacyPreferences(
                                'personnelListWidgetColumns',
                                this.organisation.id
                            )
                            .pipe(
                                map(pref => ({
                                    selectedColumns: pref,
                                    columnSorting: null,
                                    tableSearch: null,
                                    itemLimit: this.tableConfig.limit
                                })),
                                catchError(() => {
                                    this.handleDefaultPreferences();
                                    return of(null);
                                })
                            );
                    } else {
                        return of(preferences);
                    }
                }),
                catchError(() => {
                    this.handleDefaultPreferences();
                    return EMPTY;
                })
            )
            .subscribe(preferences => {
                this.applyPreferences(preferences);
            })
            .add(this.workTracker.createTracker);
    }

    private handleDefaultPreferences() {
        this.applyPreferences({});
    }

    private applyPreferences(preferences: TableSetupUserPreferences) {
        this.userPreferences = this.preferences.applyPreferences(
            preferences,
            this.availableColumns,
            this.tableConfig
        );

        this.selectedColumns = this.userPreferences.selectedColumns;
        this.columnSorting = this.userPreferences.columnSorting;
        this.tableSearch = this.userPreferences.tableSearch;
        this.tableConfig.limit = this.userPreferences.itemLimit;
    }

    changeStatus() {
        this.view$.next(this.selectedPersonnelList.value as View);
    }

    revokeInvite(item: TablePersonnel) {
        this.invitesService
            .retractInvite(item.inviteId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result =>
                    this.logging.success(`Invite revoked for ${item.email}`),
                error: (error: FlyFreelyError) => this.logging.error(error)
            })
            .add(this.workTracker.createTracker());
    }

    private refreshPermissions() {
        this.canListPersonnel = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.PERSON_LIST
        );
        this.canAddPersonnel = hasAnyPermission(
            this.organisation,
            PersonsOrganisationDto.Permissions.PERSON_ADD
        );

        this.canViewBilling = hasFeatureFlag(
            this.organisation,
            FEATURE_BILLING
        );
        this.canUseWorkgroups = hasFeatureFlag(
            this.organisation,
            FEATURE_WORKGROUPS
        );
        this.refreshLicences();
    }

    private refreshLicences() {
        if (this.canAddPersonnel) {
            this.subscriptionsService.currentSubscriptions$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(state => (this.licences = state));
        }
    }

    showPerson(person: any) {
        this.personnelDialoguesService.showPersonDetailsDialogue(
            person,
            this.organisation.id,
            true
        );
    }

    newPerson() {
        this.personnelDialoguesService.showAddPersonDialogue(
            this.organisation.id
        );
    }

    showPersonnelReportsDialogue() {
        const rolesConverter = this.personService.getPersonRoles();
        const personnel = this.tablePersonnel.map((person: TablePersonnel) => ({
            ...person,
            roles: person.roles.map(a => rolesConverter[a])
        }));

        this.personnelDialoguesService.showPersonnelReportsDialogue(
            this.organisation.id,
            personnel
        );
    }

    showOrganisationSubscriptionSettings() {
        this.router.navigate(['orgadmin', 'subscriptions'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    }

    updateSelectedColumns(selectedColumns: string[]) {
        this.selectedColumns = selectedColumns;
        if (
            this.columnSorting != null &&
            !this.selectedColumns.includes(this.columnSorting.column)
        ) {
            this.columnSorting = null;
        }
        this.userPreferences = {
            ...this.userPreferences,
            selectedColumns: selectedColumns,
            columnSorting: this.columnSorting || null
        };
        this.updateUserPreferences();
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
        this.userPreferences = {
            ...this.userPreferences,
            columnSorting: sorting
        };
        this.updateUserPreferences();
    }

    updateSearchPreferences(search: any) {
        this.tableSearch = search;
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: search
        };
        this.updateUserPreferences();
    }

    updateItemLimit(limit: number) {
        this.tableConfig.limit = limit;
        this.inviteTableConfig.limit = limit;
        this.userPreferences = {
            ...this.userPreferences,
            itemLimit: limit
        };
        this.updateUserPreferences();
    }

    updateUserPreferences() {
        // remove status from preferences so table always defaults to current personnel
        this.userPreferences = {
            ...this.userPreferences,
            tableSearch: {
                ...this.userPreferences.tableSearch,
                status: null
            }
        };
        this.preferencesService
            .updatePreferences(
                'personnelListWidgetTable',
                this.organisation.id,
                this.userPreferences
            )
            .subscribe();
    }

    isCurrentSubscription = isCurrentSubscription;
}

export const personnelWidgetRegistration: WidgetRegistration = {
    widgetIdentifier: PERSONNEL_IDENTIFIER,
    component: PersonnelWidgetComponent,
    isAvailable: organisation =>
        hasAnyPermission(organisation, [
            PersonsOrganisationDto.Permissions.PERSON_LIST
        ])
};
