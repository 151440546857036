import { Injectable } from '@angular/core';
import { Organisation, PersonsOrganisationDto } from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EquipmentAddDialogue } from './add/equipment-add.component';
import { EquipmentDialogue } from './equipment-dialogue.component';

@Injectable()
export class EquipmentDialoguesService {
    constructor(private modalService: BsModalService) {}

    /**
     * Displays the equipment add dialogue.
     *
     * @param organisation the organisation in ownership of the equipment
     * @returns the promise for the dialogue
     */
    addEquipment(organisationId: number) {
        const modal = this.modalService.show(EquipmentAddDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                organisationId: organisationId
            }
        });

        return <BsModalRef<EquipmentAddDialogue>>modal;
    }

    /**
     * Displays the equipment details dialogue, which can be used to edit.
     *
     * @param equipment the equipment object to display
     * @param organisation the organisation in ownership of the equipment
     * @returns the promise for the dialogue
     */
    showEquipmentDetails(
        equipmentId: number,
        organisation: Organisation,
        canEdit: boolean
    ) {
        const modal = this.modalService.show(EquipmentDialogue, {
            ...MODAL_OPTIONS,
            class: 'modal-lg',
            initialState: {
                equipmentId: equipmentId,
                organisation: organisation,
                canEdit: canEdit
            }
        });

        return <BsModalRef<EquipmentDialogue>>modal;
    }
}
