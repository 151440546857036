/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.60-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveOrganisationJurisdiction } from './activeOrganisationJurisdiction';
import { PersonnelActions } from './personnelActions';

/**
 * A person and their roles within a specific organisation
 */
export interface PersonRolesDto {
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The person\'s first name
     */
    firstName: string;
    /**
     * The person\'s last name
     */
    lastName: string;
    /**
     * The email address used to contact this person
     */
    email: string;
    /**
     * The person\'s phone number, if provided
     */
    phoneNumber?: string;
    /**
     * The person\'s username, if they have one
     */
    username?: string;
    /**
     * The ID of the person\'s personal organisation
     */
    personalOrganisationId: number;
    /**
     * The ID of the person\'s default organisation
     */
    defaultOrganisationId: number;
    /**
     * The person\'s date of birth
     */
    dateOfBirth?: string;
    activeJurisdictions: Array<ActiveOrganisationJurisdiction>;
    availableActions: PersonnelActions;
    /**
     * Is this user the owner of the organisation
     */
    owner: boolean;
    /**
     * Has the user activated their account
     */
    active: boolean;
    /**
     * Flags if this account can be managed by the current organisation
     */
    canManage: boolean;
    /**
     * The currently held roles for this person within the organisation
     */
    roles: Array<PersonRolesDto.Roles>;
    /**
     * Does the user have a licence within the organisation
     */
    hasLicence: boolean;
    workgroupIdList: Array<number>;
}
export namespace PersonRolesDto {
    export type Roles =
        | 'PLANNER'
        | 'RCP'
        | 'ADMIN'
        | 'BILLING_ADMIN'
        | 'PILOT'
        | 'PILOT_PLANNER'
        | 'PILOT_SUBMITTER'
        | 'PILOT_CREW_PLANNER'
        | 'PILOT_INSTANT_MISSION'
        | 'FLIGHT_LOG_COLLECTION_ORGANISATION'
        | 'FLIGHT_LOG_COLLECTION_OWN'
        | 'MAINTENANCE_CONTROLLER'
        | 'MAINTENANCE_PERSON'
        | 'MAINTENANCE_PERSON_EXTERNAL'
        | 'STUDENT'
        | 'OBSERVER'
        | 'READ_ONLY'
        | 'READ_ONLY_HIDDEN';

    export const Roles = {
        PLANNER: 'PLANNER' as Roles,
        RCP: 'RCP' as Roles,
        ADMIN: 'ADMIN' as Roles,
        BILLING_ADMIN: 'BILLING_ADMIN' as Roles,
        PILOT: 'PILOT' as Roles,
        PILOT_PLANNER: 'PILOT_PLANNER' as Roles,
        PILOT_SUBMITTER: 'PILOT_SUBMITTER' as Roles,
        PILOT_CREW_PLANNER: 'PILOT_CREW_PLANNER' as Roles,
        PILOT_INSTANT_MISSION: 'PILOT_INSTANT_MISSION' as Roles,
        FLIGHT_LOG_COLLECTION_ORGANISATION:
            'FLIGHT_LOG_COLLECTION_ORGANISATION' as Roles,
        FLIGHT_LOG_COLLECTION_OWN: 'FLIGHT_LOG_COLLECTION_OWN' as Roles,
        MAINTENANCE_CONTROLLER: 'MAINTENANCE_CONTROLLER' as Roles,
        MAINTENANCE_PERSON: 'MAINTENANCE_PERSON' as Roles,
        MAINTENANCE_PERSON_EXTERNAL: 'MAINTENANCE_PERSON_EXTERNAL' as Roles,
        STUDENT: 'STUDENT' as Roles,
        OBSERVER: 'OBSERVER' as Roles,
        READ_ONLY: 'READ_ONLY' as Roles,
        READ_ONLY_HIDDEN: 'READ_ONLY_HIDDEN' as Roles
    };
}
