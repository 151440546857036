import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import {
    AerodromeDetailsDto,
    AirspaceJurisdictionDto,
    OperatingCategoryValuesDtoOperatingCategoryDto
} from '../model/api';
import { AttachmentHandler } from './attachments';
import { httpParamSerializer } from './service.helpers';

@Injectable({
    providedIn: 'root'
})
export class JurisdictionService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    findJurisdictions() {
        return this.http.get<AirspaceJurisdictionDto[]>(
            `${this.baseUrl}/webapi/jurisdictions`
        );
    }

    attachmentHandler(jurisdictionId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/jurisdictions/${jurisdictionId}/attachments`,
            false,
            null
        );
    }

    findAerodromes(search: string) {
        return this.http.get<AerodromeDetailsDto[]>(
            `${this.baseUrl}/webapi/jurisdictions/aerodromes`,
            {
                params: httpParamSerializer({ search })
            }
        );
    }

    findAerodromesByCode(code: string) {
        return this.http.get<AerodromeDetailsDto>(
            `${this.baseUrl}/webapi/jurisdictions/aerodromes/byCode`,
            {
                params: httpParamSerializer({ code })
            }
        );
    }

    findNearbyAerodromes(
        distance: number,
        longitude: number,
        latitude: number
    ) {
        return this.http.get<AerodromeDetailsDto[]>(
            `${this.baseUrl}/webapi/jurisdictions/aerodromes/near`,
            {
                params: httpParamSerializer({ distance, longitude, latitude })
            }
        );
    }

    findOperatingConditionCategories(jurisdictionId: number) {
        return this.http.get<OperatingCategoryValuesDtoOperatingCategoryDto[]>(
            `${this.baseUrl}/webapi/jurisdictions/${jurisdictionId}/operatingConditionCategories`
        );
    }
}
