import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ILinksService, LinkAction, LinkDetails, LinkDto } from '../link-manager.interface';


@Injectable({
  providedIn: 'root'
})
export class NoticeLinksService implements ILinksService  {
  constructor(private apollo: Apollo) {}
   
  loadLinks(sectionId: number): Observable<LinkDto[]>{
    return this.apollo
      .query<{ findAirworthinessNoticeLinks: LinkDto[] }>({
        query: gql`
          query findAirworthinessNoticeLinks($sectionId: Long!) {
            findAirworthinessNoticeLinks(
              airworthinessNoticeId: $sectionId
            ) {
              id
              title
              url
            }
          }
        `,
        variables: {
            sectionId
        }
      })
      .pipe(
        map(r => r.data.findAirworthinessNoticeLinks),
      )
  }

  createLink(
    sectionId: number,
    details: LinkDetails
  ): Observable<LinkDto> {
    return this.apollo
      .mutate<{ createAirworthinessNoticeLink: LinkDto }>({
        mutation: gql`
          mutation createAirworthinessNoticeLink(
            $sectionId: Long!,
            $details: LinkDetails
          ) {
            createAirworthinessNoticeLink(
              airworthinessNoticeId: $sectionId,
              details: $details
            ) {
              id
              title
              url
            }
          }
        `,
        variables: {
            sectionId,
          details
        }
      })
      .pipe(
        tap(() => this.loadLinks(sectionId)), // Reload links after adding
        map(r => r.data.createAirworthinessNoticeLink)
      );
  }

  updateLink(
    sectionId: number,
    linkId: number,
    details: LinkDetails
  ): Observable<LinkDto> {
    return this.apollo
      .mutate<{ updateAirworthinessNoticeLink: LinkDto }>({
        mutation: gql`
          mutation updateAirworthinessNoticeLink(
            $sectionId: Long!,
            $linkId: Long!,
            $details: LinkDetails
          ) {
            updateAirworthinessNoticeLink(
              airworthinessNoticeId: $sectionId,
              linkId: $linkId,
              details: $details
            ) {
              id
              title
              url
            }
          }
        `,
        variables: {
            sectionId,
          linkId,
          details
        }
      })
      .pipe(
        tap(() => this.loadLinks(sectionId)), // Reload links after updating
        map(response => response.data.updateAirworthinessNoticeLink)
      );
  }

  deleteLink(
    sectionId: number,
    linkId: number
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteAirworthinessNoticeLink: { success: boolean } }>({
        mutation: gql`
          mutation deleteAirworthinessNoticeLink(
            $sectionId: Long!,
            $linkId: Long!
          ) {
            deleteAirworthinessNoticeLink(
              airworthinessNoticeId: $sectionId,
              linkId: $linkId
            ) {
              success
            }
          }
        `,
        variables: {
            sectionId,
          linkId
        }
      })
      .pipe(
        tap(() => this.loadLinks(sectionId)), // Reload links after deleting
        map(response => response.data.deleteAirworthinessNoticeLink.success)
      );
  }
}
