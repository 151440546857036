import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { FlightHistoryEntry } from 'libs/flight-history/src/lib/flight-history-data.service';
import { Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GqlQueryResult } from '.';
import { FlyFreelyConstants } from '../constants';
import {
    BatteryDto,
    BatterySetDto,
    CraftDetailsDto,
    CreateBatterySetCommand,
    FlightHistorySummaryDto,
    UpdateBatterySetCommand,
    UpdateNotesCommand
} from '../model/api';
import { httpParamSerializer } from './service.helpers';

export interface BatterySetLastUse {
    rpa: {
        id: number;
        nickname: string;
        callSign: string;
        status: CraftDetailsDto.Status;
        isDummy: boolean;
    };
    lastUse: string;
}

@Injectable({
    providedIn: 'root'
})
export class BatterySetService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    notifyBatterySetChanged() {
        this.changeSource.next();
    }

    findBatterySet(batterySetId: number, managingOrganisationId?: number) {
        return this.http.get<BatterySetDto>(
            `${this.baseUrl}/webapi/batterysets/${batterySetId}`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    createBatterySet(command: CreateBatterySetCommand) {
        return this.http
            .post<BatterySetDto>(`${this.baseUrl}/webapi/batterysets`, command)
            .toPromise()
            .then(batterySet => {
                this.notifyBatterySetChanged();
                return batterySet;
            });
    }

    updateBatterySet(batterySetId: number, command: UpdateBatterySetCommand) {
        return this.http
            .put<BatterySetDto>(
                `${this.baseUrl}/webapi/batterysets/${batterySetId}`,
                command
            )
            .pipe(tap(() => this.notifyBatterySetChanged()));
    }

    findBatterySets(organisationId: number, at?: string, batteryId?: number) {
        return this.http.get<BatterySetDto[]>(
            `${this.baseUrl}/webapi/batterysets`,
            {
                params: httpParamSerializer({ organisationId, batteryId, at })
            }
        );
    }

    findFlightStatistics(batterySetId: number) {
        return this.http.get<FlightHistorySummaryDto>(
            `${this.baseUrl}/webapi/batterysets/${batterySetId}/history/summary`
        );
    }

    findBatterySetFlightHistory(
        criteria: {
            id: number;
            managingOrganisationId: number;
            includeType: ('LOGGED' | 'HISTORICAL' | 'UNASSIGNED')[];
        },
        order: 'ASC' | 'DESC',
        page = 0,
        pageSize = 10
    ) {
        return this.apollo
            .query<{
                findBatterySetFlightHistory: GqlQueryResult<FlightHistoryEntry>;
            }>({
                query: gql`
                    query find(
                        $criteria: ResourceFlightHistoryCriteria
                        $order: Direction
                        $pageSize: Int
                        $page: Int
                    ) {
                        findBatterySetFlightHistory(
                            criteria: $criteria
                            order: $order
                            page: $page
                            pageSize: $pageSize
                        ) {
                            count
                            results {
                                id
                                type
                                missionId
                                flightNumber
                                startTime
                                endTime
                                duration
                                visualLineOfSight
                                timeOfDay
                                rpaId
                                rpaType {
                                    make
                                    model
                                    rpaCategory
                                    performanceSpecifications {
                                        minTakeOffWeight
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    criteria,
                    order,
                    page,
                    pageSize
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findBatterySetFlightHistory)
            );
    }
    findLastBatterySetUse(batterySetId: number, organisationId: number) {
        return this.apollo
            .query<{
                findLastBatterySetUse: BatterySetLastUse[];
            }>({
                query: gql`
                    query find($batterySetId: Long!, $organisationId: Long!) {
                        findLastBatterySetUse(
                            batterySetId: $batterySetId
                            organisationId: $organisationId
                        ) {
                            rpa {
                                id
                                nickname
                                callSign
                                status
                                isDummy
                            }
                            lastUse
                        }
                    }
                `,
                variables: {
                    batterySetId,
                    organisationId
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findLastBatterySetUse)
            );
    }

    delete(batterySetId: number) {
        return this.http
            .delete<void>(`${this.baseUrl}/webapi/batterysets/${batterySetId}`)
            .pipe(tap(() => this.changeSource.next()));
    }

    onBatterySetChange() {
        this.changeSource.next();
    }

    updateBatterySetNotes(batteryId: number, command: UpdateNotesCommand) {
        return this.http
            .put<BatteryDto>(
                `${this.baseUrl}/webapi/batterySets/${batteryId}/notes`,
                command
            )
            .pipe(tap(() => this.notifyBatterySetChanged()));
    }
}
