<div class="modal-header">
    <div class="panel-heading-icon">
        <img [src]="IMG_URL + '/icons/icon-maintenance.png'" />
    </div>
    <h4 id="dialog-child-name" class="modal-title pull-left">
        Bulk Maintenance
    </h4>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hide()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-body vertical-container">
        <div class="form-group">
            <label>RPA Model</label>
            <ng-select
                name="rpaModelId"
                [items]="rpaModels$ | async"
                bindValue="id"
                appendTo="body"
                formControlName="rpaModelId"
                [disabled]="working"
            >
                <ng-template ng-option-tmp let-item="item">
                    {{ item | formatRpaType }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    {{ item | formatRpaType }}
                </ng-template>
            </ng-select>
        </div>
        <div class="container-scroll fill">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Designation</th>
                        <th>Serial Number</th>
                    </tr>
                </thead>
                <tbody formArrayName="rpaId">
                    <tr *ngFor="let rpa of availableRpa; let i = index">
                        <td>
                            <div class="checkbox">
                                <label
                                    ><input
                                        type="checkbox"
                                        [formControlName]="i"
                                        [disabled]="working" />
                                    <span class="cr"
                                        ><i
                                            class="cr-icon glyphicon glyphicon-ok"
                                        ></i> </span
                                ></label>
                            </div>
                        </td>
                        <td>{{ rpa.nickname }}</td>
                        <td>{{ rpa.manufacturerSerialNumber }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="form-group">
            <label>Reason for Request *</label>
            <textarea
                class="form-control"
                name="reasonForRequest"
                [disabled]="working"
                formControlName="reasonForRequest"
            ></textarea>
        </div>
        <div class="form-group">
            <label>Maintenance Type *</label>
            <ng-select
                name="maintenanceType"
                [items]="maintenanceLogTypes"
                [disabled]="working"
                bindValue="value"
                bindLabel="name"
                appendTo="body"
                formControlName="maintenanceType"
            ></ng-select>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            class="btn btn-primary"
            [disabled]="working || form.invalid"
        >
            Create
        </button>
    </div>
</form>
