import {
    FormGroup,
    FormControl,
    AbstractControl,
    ValidationErrors
} from '@angular/forms';
import { PersonRolesDto } from '@flyfreely-portal-ui/flyfreely';

export function toRoleList(values: any): PersonRolesDto.Roles[] {
    return Object.keys(values).reduce(
        (acc, v) => (values[v] ? acc.concat(v) : acc),
        []
    );
}

export function loadRoleValues(roles: PersonRolesDto.Roles[]) {
    const checkRole = (role: PersonRolesDto.Roles) =>
        roles.indexOf(role) !== -1;

    return Object.keys(PersonRolesDto.Roles).reduce(
        (acc, role) => ({
            ...acc,
            [role]: checkRole(role as PersonRolesDto.Roles)
        }),
        {}
    );
}

export function createRolesFormGroup(
    defaultValue: boolean = undefined,
    requireValueValidator = false
) {
    return new FormGroup(
        Object.keys(PersonRolesDto.Roles).reduce(
            (acc, role) => ({ ...acc, [role]: new FormControl(defaultValue) }),
            {}
        ),
        requireValueValidator ? [roleSelectedValidator] : []
    );
}

/**
 * A validator for a roles form group
 * @param control roles form group validation output
 */
export function roleSelectedValidator(
    control: AbstractControl
): ValidationErrors | null {
    const values = control.value;
    if (Object.keys(values).findIndex(c => values[c]) !== -1) {
        return null;
    }
    return {
        required: true
    };
}
