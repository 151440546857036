/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.12-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface RpaCategoryGroup { 
    /**
     * if present, the list of RPA that the status applies to
     */
    rpaIdList?: Array<number>;
    /**
     * The status for this combination of RPA
     */
    registerStatus: RpaCategoryGroup.RegisterStatus;
    /**
     * The status calculation source. Either AUTO or an override status.
     */
    registerStatusSource: RpaCategoryGroup.RegisterStatusSource;
}
export namespace RpaCategoryGroup {
    export type RegisterStatus = 'PENDING' | 'ACTIVE' | 'SUSPENDED' | 'EXPIRED' | 'NOT_ON_REGISTER' | 'NOT_AVAILABLE' | 'AUTO';
    export const RegisterStatus = {
        PENDING: 'PENDING' as RegisterStatus,
        ACTIVE: 'ACTIVE' as RegisterStatus,
        SUSPENDED: 'SUSPENDED' as RegisterStatus,
        EXPIRED: 'EXPIRED' as RegisterStatus,
        NOT_ON_REGISTER: 'NOT_ON_REGISTER' as RegisterStatus,
        NOT_AVAILABLE: 'NOT_AVAILABLE' as RegisterStatus,
        AUTO: 'AUTO' as RegisterStatus
    };
    export type RegisterStatusSource = 'PENDING' | 'ACTIVE' | 'SUSPENDED' | 'EXPIRED' | 'NOT_ON_REGISTER' | 'NOT_AVAILABLE' | 'AUTO';
    export const RegisterStatusSource = {
        PENDING: 'PENDING' as RegisterStatusSource,
        ACTIVE: 'ACTIVE' as RegisterStatusSource,
        SUSPENDED: 'SUSPENDED' as RegisterStatusSource,
        EXPIRED: 'EXPIRED' as RegisterStatusSource,
        NOT_ON_REGISTER: 'NOT_ON_REGISTER' as RegisterStatusSource,
        NOT_AVAILABLE: 'NOT_AVAILABLE' as RegisterStatusSource,
        AUTO: 'AUTO' as RegisterStatusSource
    };
}



