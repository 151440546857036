import { Pipe, PipeTransform } from '@angular/core';
import {
    CollectNowQueue,
    FlightLogCollectionSourceDto
} from '@flyfreely-portal-ui/flyfreely';
import { FlightLogCollectionTypeLookup } from 'libs/flight-logs/src/lib/settings/helpers';

@Pipe({ name: 'formatFlightLogCompletionMessage' })
export class FormatFlightLogCompletionMessagePipe implements PipeTransform {
    transform(flightLogCollection: FlightLogCollectionSourceDto) {
        if (flightLogCollection == null) {
            return '';
        }
        const status = FlightLogCollectionSourceDto.LastCompletionStatus;
        switch (flightLogCollection.lastCompletionStatus) {
            case status.NOT_YET_RUN:
                return 'Not Yet Run';

            case status.SUCCESS:
                return `Successfully synced logs`;

            case status.INVALID_PASSWORD:
                return `Invalid password provided for your ${
                    FlightLogCollectionTypeLookup[flightLogCollection.type]
                } account`;

            case status.FAILURE:
                return 'Failed to fetch logs';

            default:
                return '';
        }
    }
}
