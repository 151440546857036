import { Injectable } from '@angular/core';
import {
    FLIGHT_LOG_COLLECTION_TYPE_LIST,
    FlightConformanceResultDto,
    FlightConformanceRuleResultDto,
    FlightLogCriteria,
    FlightLogSummaryGQL,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    SuggestedMissionAssignmentDto,
    WorkTracker,
    calculateDuration
} from '@flyfreely-portal-ui/flyfreely';
import { ColumnSortPreferences } from '@flyfreely-portal-ui/flyfreely-table';
import { formatPerson, setupStatusFormatter } from 'libs/ui/src/lib/pipes';
import moment from 'moment';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlightLogWithDuration } from './flight-log-list/flight-log-list-dialogue.component';

export function toConformanceResult(
    flightLog: FlightLogSummaryGQL
): FlightConformanceResultDto[] {
    const c = flightLog.conformanceResult;
    if (c == null) {
        return null;
    }
    return [
        {
            id: c.id,
            policyId: c.policy?.id,
            flightId: c.flight?.id,
            pilotId: c.pilot?.id,
            flightLogFileId: flightLog.id,
            status: c.status,
            ruleResultList: c.ruleResultList.map<
                FlightConformanceRuleResultDto
            >(r => ({
                ...r,
                ruleVersionId: r.ruleVersion.id,
                type: r.ruleVersion.rule.type
            }))
        }
    ];
}

export function toSuggestedMissionAssignment(
    flightLog: FlightLogSummaryGQL
): SuggestedMissionAssignmentDto {
    const c = flightLog.suggestedMissionAssignment;
    if (c == null) {
        return null;
    }
    return {
        missionId: c.mission.id,
        missionName: c.mission.name,
        missionTime: c.mission.missionDate,
        missionTimeZone: c.mission.timeZone,
        locationDistance: c.locationDistance,
        timeDistance: c.timeDistance
    };
}

const formatFlightLogCollectionType = setupStatusFormatter(
    FLIGHT_LOG_COLLECTION_TYPE_LIST
);

export function toCollectedBy(fl: FlightLogSummaryGQL) {
    if (fl.flightLogCollectionSource != null) {
        return formatFlightLogCollectionType(fl.flightLogCollectionSource.type);
    }

    return formatPerson(fl.creationBy);
}

export function toFlightLogWithDuration(
    fl: FlightLogSummaryGQL
): FlightLogWithDuration {
    if (fl.summary == null) {
        return {
            ...fl,
            uploadTime: moment(fl.creationTime),
            startTime: null,
            collectionTime: fl.collectionTime,
            duration: null,
            collectedBy: toCollectedBy(fl),
            conformanceResultList: toConformanceResult(fl),
            missionId: fl.mission?.id,
            organisationId: fl.organisation?.id,
            missionName: fl.mission?.name,
            flightId: fl.flight?.id,
            rpaId: fl.rpa?.id,
            batteryIds: fl.batteries?.map(b => b.id),
            pilot: fl.flight?.pilot,
            fileName: fl.fileName,
            suggestedMissionAssignment:
                fl.suggestedMissionAssignment != null
                    ? toSuggestedMissionAssignment(fl)
                    : null
        };
    }
    return {
        ...fl,
        uploadTime: moment(fl.creationTime),
        startTime: fl.summary.startTime,
        collectionTime: fl.collectionTime,
        duration: calculateDuration(
            <string>fl.summary.startTime,
            <string>fl.summary.endTime
        ),
        collectedBy: toCollectedBy(fl),
        conformanceResultList: toConformanceResult(fl),
        missionId: fl.mission?.id,
        organisationId: fl.organisation?.id,
        missionName: fl.mission?.name,
        flightId: fl.flight?.id,
        rpaId: fl.rpa?.id,
        batteryIds: fl.batteries?.map(b => b.id),
        pilot: fl.flight?.pilot,
        fileName: fl.fileName,
        suggestedMissionAssignment:
            fl.suggestedMissionAssignment != null
                ? toSuggestedMissionAssignment(fl)
                : null
    };
}

const defaultSort: { field: string; order: 'ASC' | 'DESC' } = {
    field: 'startTime',
    order: 'DESC'
};

@Injectable()
export class FlightLogDataService {
    private workingSource = new ReplaySubject<boolean>(1);
    private flightLogsSource = new ReplaySubject<FlightLogWithDuration[]>(1);
    private currentPageSource = new BehaviorSubject<number>(0);
    private totalItemsSource = new ReplaySubject<number>(1);
    private hasFlightLogsSource = new ReplaySubject<boolean>(1);

    working$ = this.workingSource.asObservable();
    flightLogs$ = this.flightLogsSource.asObservable();
    currentPage$ = this.currentPageSource.asObservable();
    totalItems$ = this.totalItemsSource.asObservable();
    hasFlightLogs$ = this.hasFlightLogsSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightLogsService: FlightLogsService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.workingSource.complete();
        this.flightLogsSource.complete();
        this.currentPageSource.complete();
        this.totalItemsSource.complete();
        this.hasFlightLogsSource.complete();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findFlightLogs(
        page: number,
        limit: number,
        sorting: ColumnSortPreferences,
        filters: FlightLogCriteria,
        organisationId: number
    ) {
        // doneWorking is used for GraphQL to prevent the working state ending early.
        const sortFields: { field: string; order: 'ASC' | 'DESC' } =
            sorting != null
                ? {
                      field: sorting?.column ?? null,
                      order: sorting?.ascending ? 'ASC' : 'DESC'
                  }
                : defaultSort;
        this.flightLogsService
            .findFlightLogsSummaries(
                organisationId,
                filters,
                sortFields,
                page,
                limit
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: logs => {
                    const flightLogs: FlightLogWithDuration[] = logs.results.map(
                        log => toFlightLogWithDuration(log)
                    );
                    this.totalItemsSource.next(logs.count);
                    this.currentPageSource.next(page);
                    this.flightLogsSource.next(flightLogs);
                    this.findFlightLogCounts(organisationId);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    /**
     * Check if the organisation has any flight logs.
     * When getting a 0 count from the GQL fetch, this can be used to see if the 0 is only the result of the filtering.
     * @param organisationId the Organisation ID to check against
     */
    findFlightLogCounts(organisationId: number) {
        this.flightLogsService
            .findCounts(organisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: result =>
                    this.hasFlightLogsSource.next(
                        result.byStatus != null &&
                            Object.keys(result.byStatus).length > 0 &&
                            Object.keys(result.byStatus).filter(
                                k => result.byStatus[k] !== 0
                            ).length !== 0
                    ),
                error: (error: FlyFreelyError) => {
                    this.logging.error(error);
                    this.hasFlightLogsSource.next(null);
                }
            })
            .add(this.workTracker.createTracker());
    }
}
