import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-duration',
    template: `
        <div class="input-group">
            <duration
                [formControl]="formControl"
                [showHour]="true"
                [showMinute]="true"
                [showSecond]="true"
                [clearable]="true"
                [readonly]="false"
            >
            </duration>
        </div>
    `
})
export class FormlyDuration extends FieldType {}
