/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface LosOperatingCategory { 
    id: number;
    type: 'LOS';
    value?: LosOperatingCategory.Value;
}

export namespace LosOperatingCategory {
    export type Value = 'VLOS' | 'EVLOS' | 'BVLOS';
    export const Value = {
        VLOS: 'VLOS' as Value,
        EVLOS: 'EVLOS' as Value,
        BVLOS: 'BVLOS' as Value
    };
}


