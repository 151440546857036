import { Component, Input } from '@angular/core';
import {
    AttachmentHandler,
    FormResponseCommand,
    FormResponseFormGroup,
    MissionDetailsDto,
    MissionService,
    NameValue,
    SortieDetailsDto,
    SortieDto,
    StepEntityRequiredDocumentation,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { DocumentationActions } from 'libs/documentation/src/lib/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'flight-documentation-dialogue',
    template: `
        <div class="modal-header no-icon">
            <h4 id="dialog-child-name" class="modal-title pull-left">
                Flight Documentation
            </h4>
            <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="close()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <documentation-list
                    title="Flight #{{ flight.number }}"
                    [organisationId]="mission.organisationId"
                    [requiredDocumentation]="documentation"
                    [formResponses]="formResponses"
                    [steps]="steps"
                    [attachmentsHandler]="attachmentsHandler"
                    [documentationActions]="documentationActions"
                    [missionRequiredDocumentation]="documentation"
                ></documentation-list>

                <h4>Objective</h4>
                <div class="min-height-field">
                    {{ flight.reason }}
                </div>

                <h4>Notes</h4>
                <div class="min-height-field">
                    {{ flight.notes }}
                </div>

                <h4>Attachments</h4>
                <attachment-list
                    [attachmentsHandler]="attachmentsHandler"
                    [allowEdit]="false"
                    [allowDelete]="false"
                    [allowUploads]="false"
                    [parentPromiseTracker]="workTracker"
                    permissions
                ></attachment-list>

                <h4>Crew</h4>
                <table *ngIf="flightDetails != null" class="table">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Person</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let crewMember of flightDetails.crew">
                            <td>{{ crewMember?.missionRole?.name }}</td>
                            <td>{{ crewMember?.person | formatPerson }}</td>
                            <td>{{ crewMember?.notes }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    `,
    styles: [
        `
            .min-height-field {
                min-height: 2rem;
            }
        `
    ]
})
export class FlightDocumentationDialogue {
    @Input() mission: MissionDetailsDto;
    @Input() flight: SortieDto;
    @Input() documentation: StepEntityRequiredDocumentation;
    @Input() formResponses: FormResponseFormGroup;

    attachmentsHandler: AttachmentHandler;
    documentationActions: DocumentationActions;

    flightDetails: SortieDetailsDto;

    working: boolean = false;
    loaded: boolean = false;
    workTracker = new WorkTracker();

    private ngUnsubscribe$ = new Subject<void>();

    steps: NameValue[];

    constructor(
        private modal: BsModalRef,
        private missionService: MissionService
    ) {
        this.steps = missionService.getFlightSteps();
    }

    ngOnInit() {
        this.attachmentsHandler = this.missionService.flightAttachmentHandler(
            this.mission.id,
            this.flight.id
        );
        this.attachmentsHandler.destroyOn(this.ngUnsubscribe$);

        this.setupDocumentationActions();

        this.missionService
            .findFlight(this.mission.id, this.flight.id, null)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(flightDetails => (this.flightDetails = flightDetails));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    close() {
        this.modal.hide();
    }

    private setupDocumentationActions() {
        this.documentationActions = {
            getFormTemplatePrintUrl: (checklistId: number, step: string) =>
                this.missionService.getFlightFormTemplatePrintUrl(
                    this.mission.id,
                    this.flight.id,
                    checklistId,
                    step,
                    this.mission.organisationId
                ),
            getFormResponsePrintUrl: (formId: number, step: string) =>
                this.missionService.getFlightFormResponsePrintUrl(
                    this.mission.id,
                    this.flight.id,
                    formId,
                    step,
                    this.mission.organisationId
                ),
            storeFormResponse: (
                formId: number,
                step: string,
                response: FormResponseCommand
            ) => Promise.reject()
        };
    }
}
