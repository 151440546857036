import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    CraftDto,
    CraftService,
    FlightLogsService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionService,
    MissionSummaryDto,
    RpaTypeDto,
    RpaTypesService,
    SortieDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { TableColumn, TableConfig } from '@flyfreely-portal-ui/flyfreely-table';
import { FormatResourceStatusPipe } from '@flyfreely-portal-ui/resource-ui';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, combineLatest, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { FlightLogWithDuration } from '../flight-log-list/flight-log-list-dialogue.component';

enum RpaSource {
    MISSION = 'MISSION',
    ALL = 'ALL'
}

enum Step {
    LOADING = 'LOADING',
    ASSIGN = 'ASSIGN',
    ASSIGNING = 'ASSIGNING',
    ASSIGNED = 'ASSIGNED'
}

enum RpaLinkingStatus {
    NO_MATCH = 'NO_MATCH',
    MATCH = 'MATCH',
    CAN_ASSIGN_TO_LOG = 'CAN_ASSIGN_TO_LOG',
    CAN_ASSIGN_TO_RPA = 'CAN_ASSIGN_TO_RPA',
    NOTHING = 'NOTHING'
}

interface TableCraft extends CraftDto {
    make: string;
    model: string;
    assignedToMission: boolean;
}

@Component({
    selector: 'assign-rpa',
    templateUrl: './assign-rpa.component.html',
    styles: [
        `
            /* Allow extra spacing for the wider than normal buttons */
            .rpa-container {
                padding-right: 35px;
                position: relative;
            }
            /* Unique button styles */
            .assign {
                background: #4a4a4a !important;
                color: white !important;
                margin-left: 5px;
            }

            .assigned {
                background: #e8e8e8 !important;
                margin-left: 5px;
            }
        `
    ],
    host: {
        class: 'container-with-footer contents top-buffer bottom-buffer'
    }
})
export class AssignRpa {
    @Input() flightLog: FlightLogWithDuration;
    @Input() enhancedHelpActive: boolean = false;
    @Output() done = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    candidateMissions: MissionSummaryDto[];
    candidateFlights: SortieDto[];

    source: RpaSource;
    rpas: TableCraft[];

    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    tableSearch: any = {
        status: 'SERVICEABLE|UNDER_MAINTENANCE|UNSERVICEABLE'
    };

    rpaSerialNumber: string;
    rpaType: string;
    rpaName: string;
    step = Step.LOADING;

    rpaLinkingStatus: RpaLinkingStatus;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightLogsService: FlightLogsService,
        private missionService: MissionService,
        private craftService: CraftService,
        private rpaTypesService: RpaTypesService,
        private logging: FlyFreelyLoggingService,
        resourceStatusPipe: FormatResourceStatusPipe,
        public modal: BsModalRef
    ) {
        this.availableColumns = [
            {
                value: 'nickname',
                name: 'RPA Name',
                selectable: false,
                searchable: true,
                defaultSelection: true
            },
            {
                value: 'make',
                name: 'Make',
                selectable: false,
                searchable: true,
                defaultSelection: true
            },
            {
                value: 'model',
                name: 'Model',
                selectable: false,
                searchable: true,
                defaultSelection: true
            },
            {
                value: 'manufacturerSerialNumber',
                name: 'Serial Number',
                selectable: false,
                searchable: true,
                defaultSelection: true
            },
            {
                value: 'status',
                name: 'Status',
                selectable: false,
                searchable: true,
                defaultSelection: true,
                searchOptions: this.craftService.getStatuses(),
                defaultSearch: 'SERVICEABLE|UNDER_MAINTENANCE|UNSERVICEABLE',
                formatterFunction: (rpa: TableCraft) =>
                    resourceStatusPipe.transform(rpa)
            }
        ];

        this.selectedColumns = [];

        this.tableConfig = {
            limit: 15,
            actions: []
        };
    }

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.rpaName = this.flightLog?.summary?.rpaName;
        this.rpaSerialNumber = this.flightLog?.summary?.rpaSerialNumber;
        this.rpaType = this.flightLog?.summary?.rpaType;

        this.findCandidateRpa();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private findCandidateRpa() {
        this.step = Step.LOADING;
        this.rpas = [];

        if (this.flightLog.missionId != null) {
            this.source = RpaSource.MISSION;
            this.missionService
                .findMission(this.flightLog.missionId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    mission => {
                        const validRpas = mission.crafts.filter(
                            c => !c.isDummy
                        );
                        this.createRpas(validRpas);
                        this.step = Step.ASSIGN;
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error finding relevant missions for flight log: ${error.message}`
                        )
                )
                .add(this.workTracker.createTracker());
        } else {
            this.source = RpaSource.ALL;
            this.craftService
                .findCrafts(this.flightLog.organisationId)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    rpas => {
                        const validRpas = rpas.filter(c => !c.isDummy);
                        this.createRpas(validRpas);
                        this.step = Step.ASSIGN;
                    },
                    (error: FlyFreelyError) =>
                        this.logging.error(
                            error,
                            `Error finding relevant RPA for flight log: ${error.message}`
                        )
                )
                .add(this.workTracker.createTracker());
        }
    }

    createRpas(rpas: CraftDto[]) {
        const typesToFetch = rpas.reduce(
            (acc, r) =>
                acc.includes(r.rpaTypeId) ? acc : acc.concat(r.rpaTypeId),
            []
        );
        combineLatest(
            typesToFetch.map(t =>
                this.rpaTypesService.findRpaType(t).pipe(
                    catchError((error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error finding RPA type for ${
                                rpas.find(r => r.rpaTypeId === t).nickname
                            }: ${error.message}`
                        );
                        return of(null);
                    })
                )
            )
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    rpas.forEach(rpa => {
                        const rpaType = results.find(
                            t => t.id === rpa.rpaTypeId
                        );
                        this.setupRpa(rpa, rpaType);
                    });
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error finding RPA types: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    setupRpa(rpa: CraftDto, rpaType: RpaTypeDto) {
        this.rpas.push({
            ...rpa,
            make: rpaType?.make ?? '',
            model: rpaType?.model ?? '',
            assignedToMission: rpa.internalSerialNumber ? true : false
        });
    }

    onRpaSelected(rpa: CraftDto) {
        this.step = Step.ASSIGNING;
        if (
            this.flightLog.summary?.rpaSerialNumber != null &&
            this.flightLog.summary?.rpaSerialNumber !== 'null' &&
            this.flightLog.summary?.rpaSerialNumber.trim().length > 0
        ) {
            this.craftService
                .updateInternalSerialNumber(rpa.id, {
                    internalSerialNumber: this.rpaSerialNumber,
                    managingOrganisationId: this.flightLog.organisationId
                })
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    () => {
                        this.logging.success(
                            'RPA has been associated with the flight log'
                        );
                        this.rpaLinkingStatus = RpaLinkingStatus.MATCH;
                        this.close(true);
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while assigning RPA: ${error.message}`
                        );
                    }
                )
                .add(this.workTracker.createTracker());
        } else {
            this.flightLogsService
                .assignRpa(this.flightLog.id, rpa.id)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(
                    () => {
                        this.logging.success(
                            'RPA has been associated with the flight log'
                        );
                        this.rpaLinkingStatus = RpaLinkingStatus.MATCH;
                        this.close(true);
                    },
                    (error: FlyFreelyError) => {
                        this.logging.error(
                            error,
                            `Error while assigning RPA: ${error.message}`
                        );
                    }
                )
                .add(this.workTracker.createTracker());
        }
    }

    updateTableSearch(search: any) {
        this.tableSearch = search;
    }

    close(done: boolean) {
        if (done) {
            this.done.emit();
        } else {
            this.cancel.emit();
        }
        this.modal.hide();
    }
}
