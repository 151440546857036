import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * An modal that sits inline, centred in the parent component.
 *
 * As the containing component is responsible for handling keyboard navigation,
 * to achieve the required UX of closing on cancel, the parent should use its
 * `modalOptions.closeInterceptor` to check if the inline modal is visible, and close
 * it first.
 *
 * A more sophisticated version of this is to use the ExclusiveControlService to manage
 * which component has control, and back out of this. This gives the modal component
 * an opportunity to veto the close if required (i.e., dirty data).
 */
@Component({
    selector: 'inline-modal',
    template: `
        <div
            class="backdrop"
            [ngClass]="{ 'backdrop-top-spacing': topSpacing }"
            [@openClose]="show ? 'open' : 'closed'"
        >
            <div
                [ngClass]="{
                    'inline-modal-container': !sizeSmall,
                    'inline-modal-container-sm': sizeSmall
                }"
            >
                <ng-content></ng-content><ng-container #view></ng-container>
            </div>
        </div>
    `,
    styles: [
        `
            .backdrop {
                z-index: 1999;
                position: absolute;
                top: -20px;
                left: 0;
                right: 0;
                bottom: 0;
                padding-top: 20px;
                background-color: rgba(0, 0, 0, 0.3);
            }

            .backdrop-top-spacing {
                z-index: 999;
                padding-top: 80px;
            }

            .inline-modal-container,
            .inline-modal-container-sm {
                margin: 15px auto;
                padding: 15px;
                background: white;
                border-radius: 6px;
                display: flex;
                flex-direction: column;
            }

            .inline-modal-container {
                min-width: 600px;
            }

            .inline-modal-container-sm {
                min-width: 300px;
                height: 80%;
                height: fit-content;
            }
        `
    ],
    animations: [
        trigger('openClose', [
            // ...
            state(
                'open',
                style({
                    opacity: 1,
                    display: 'flex'
                })
            ),
            state(
                'closed',
                style({
                    opacity: 0,
                    display: 'none'
                })
            ),
            transition('open => closed', [animate('0.5s')]),
            transition('closed => open', [
                style({
                    display: 'flex'
                }),
                animate('0.5s')
            ])
        ])
    ]
})
export class InlineModal {
    @Input() show: boolean;
    @Input() sizeSmall?: boolean = false;
    @Input() topSpacing?: boolean = false;

    @ViewChild('view', { static: false, read: ViewContainerRef })
    viewContainerRef: ViewContainerRef;
    componentRef: ComponentRef<any>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetector: ChangeDetectorRef
    ) {}

    /**
     * Create a component in the inline modal. Return the component reference, and an observable
     * that emits when the component is destroyed.
     * @param component
     * @param params
     */
    showComponent<T>(
        component: Type<T>,
        params: { [input: string]: any }
    ): { component: T; onDestroy: Observable<void> } {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            component
        );
        this.viewContainerRef.clear();
        const componentRef = this.viewContainerRef.createComponent<T>(
            componentFactory
        );

        Object.assign(componentRef.instance, params);
        this.show = true;

        const onDestroy$ = new Subject<void>();

        componentRef.onDestroy(() => {
            componentFactory.outputs.forEach(o => {
                try {
                    componentRef.instance[o.propName].complete();
                } catch {
                    console.warn(`Output ${o.propName} is not setup correctly`);
                }
            });
            onDestroy$.next();
            onDestroy$.complete();
        });

        this.componentRef = componentRef;

        this.changeDetector.markForCheck();

        return {
            component: this.componentRef.instance,
            onDestroy: onDestroy$.asObservable()
        };
    }

    closeComponent() {
        this.show = false;
        this.viewContainerRef.clear();
        if (this.componentRef != null) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
        this.changeDetector.markForCheck();
    }

    ngOnDestroy() {
        if (this.viewContainerRef != null) {
            this.viewContainerRef.clear();
        }
        if (this.componentRef != null) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
