import { Directive, HostListener, Input, Optional } from '@angular/core';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { DynamicHelpEditService } from './dynamic-help-editor/dynamic-help-edit.service';
import { FormControl } from '@angular/forms';

/**
 *
 */
@Directive({
    selector: 'enhanced-help'
    // selector: '[tooltip][enhancedHelp], [tooltip], enhanced-help'
})
export class EditEnhancedHelpDirective {
    @Input()
    enhancedHelp: string;
    @Input() screenName: string;
    @Input() componentName: string;
    @Input() helpObjectOverride: FormControl;

    @HostListener('mouseenter')
    mouseenter() {
        if (this.ready) {
            this.onMouseOverItem();
        }
    }
    ready = false;

    constructor(
        private dynamicHelpEditService: DynamicHelpEditService,
        @Optional() private screenAnalytics: ScreenAnalyticsDirective
    ) {}

    ngOnInit() {
        this.ready = true;
    }

    onMouseOverItem() {
        // Ignore mouse over event for any help objects in editors
        if (this.helpObjectOverride == null) {
            this.dynamicHelpEditService.elementSelected(
                this.componentName,
                this.screenAnalytics?.screen ??
                    this.screenAnalytics?.screenAnalytics
            );
        }
    }
}
