<div class="vertical-container search-dialogue">
    <plan-highlight [featureFlags]="aerodromesFeature"></plan-highlight>
    <div>
        <info-search
            placeholder="Aerodrome Search"
            [readonly]="working"
            [showNearby]="!displayNearbyAerodromes"
            (nearby)="displayNearby()"
            (liveSearch)="findAirports($event)"
        ></info-search>
    </div>
    <div class="dropdown dropdown-padding">
        <div
            *ngIf="aerodromes?.length"
            class="dropdown-menu show open contents"
        >
            <nav class="nav">
                <li *ngFor="let aerodrome of aerodromes">
                    <a
                        class="nav-link dropdown-item"
                        (click)="selectAirport(aerodrome)"
                        >{{ aerodrome.name }} {{ aerodrome.code }}</a
                    >
                </li>
            </nav>
        </div>
    </div>
    <div *ngIf="locationCentre != null && displayNearbyAerodromes">
        <p class="text-center top-buffer">Or</p>
        <div class="vertical-container" style="justify-content: flex-start">
            <div class="result-container contents">
                <label class="control-label right-buffer"
                    >Nearby Locations Within
                </label>
                <ng-select
                    class="fill"
                    [items]="rangeOptions"
                    [clearable]="false"
                    [readonly]="working"
                    bindLabel="label"
                    bindValue="value"
                    appendTo="body"
                    [(ngModel)]="range"
                    tooltip="Select search radius around center of flight area"
                    (ngModelChange)="updateRange()"
                ></ng-select>
            </div>
            <ng-container *ngIf="nearbyAerodromes != null">
                <nav class="nav left-align">
                    <li *ngFor="let aerodrome of nearbyAerodromes">
                        <a
                            class="nav-link dropdown-item"
                            (click)="selectAirport(aerodrome)"
                        >
                            {{ aerodrome.name }} {{ aerodrome.code }}
                            {{ calculateDistance(aerodrome.location) }}
                        </a>
                    </li>
                </nav>
            </ng-container>
        </div>
    </div>
    <div *ngIf="aerodrome != null" class="result-aerodrome vertical-container">
        <h4 class="section-heading">
            <span>Aerodrome</span>
            <div></div>
        </h4>
        <div class="data result-container">
            <span>{{ aerodrome.name }} {{ aerodrome.code }}</span>
            <button
                *ngIf="aerodrome.selected == false"
                type="button"
                class="btn btn-pill btn-primary btn-sm aerodrome-button"
                (click)="submitAerodrome(aerodrome)"
            >
                Add to mission
            </button>
            <div class="input-group" *ngIf="aerodrome.selected == true">
                <span class="fa fa-check selected-item right-buffer"></span>
                <button
                    type="button"
                    class="btn btn-pill btn-primary btn-sm aerodrome-button"
                    (click)="deleteAerodrome(aerodrome)"
                >
                    Remove
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="contacts?.length" class="vertical-container top-buffer">
        <h4 class="section-heading">
            <span>Contact Details</span>
            <div></div>
        </h4>
        <div *ngFor="let contact of contacts" class="data result-container">
            <span>{{ contact.type }} {{ contact.contact }}</span>
            <button
                *ngIf="contact.selected == false"
                type="button"
                class="btn btn-pill btn-primary btn-sm aerodrome-button"
                (click)="submitContact(contact)"
            >
                Add to mission
            </button>
            <div class="input-group" *ngIf="contact.selected == true">
                <span class="fa fa-check selected-item"></span>
                <button
                    type="button"
                    class="btn btn-pill btn-primary btn-sm aerodrome-button"
                    (click)="deleteContact(contact)"
                >
                    Remove
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="frequencies?.length" class="vertical-container top-buffer">
        <h4 class="section-heading">
            <span>Radio Frequencies</span>
            <div></div>
        </h4>
        <div
            *ngFor="let frequency of frequencies"
            class="data result-container"
        >
            <span>{{ frequency.name }} {{ frequency.frequency }}</span>
            <button
                *ngIf="frequency.selected == false"
                type="button"
                class="btn btn-pill btn-primary btn-sm aerodrome-button"
                (click)="submitFrequency(frequency)"
            >
                Add to mission
            </button>
            <div class="input-group" *ngIf="frequency.selected == true">
                <span class="fa fa-check selected-item"></span>
                <button
                    type="button"
                    class="btn btn-pill btn-primary btn-sm aerodrome-button"
                    (click)="deleteFrequency(frequency)"
                >
                    Remove
                </button>
            </div>
        </div>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</div>
<div class="action-container">
    <button
        class="btn btn-default pull-right"
        type="button"
        (click)="closeModal()"
    >
        Done
    </button>
</div>
