import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AcceptInviteCommand,
    FlyFreelyConstants,
    FlyFreelyError,
    InvitesService,
    LoginManager,
    NavigationState,
    NotFound,
    OnboardingService,
    PersonDto,
    ResultDto,
    UserService,
    UserStatus,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';

import { KeycloakEventType } from 'keycloak-angular';
import { ScreenAnalyticsDirective } from 'libs/analytics/src/lib/screen-analytics.directive';
import { combineLatest, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

enum Status {
    LOGGED_IN = 'LOGGED_IN',
    LOGIN = 'LOGIN',
    SIGNUP = 'SIGNUP',
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    NONE = 'NONE'
}

@Component({
    selector: 'invites-landing',
    templateUrl: './invites-landing.component.html'
})
export class InvitesLandingComponent {
    public error: string;
    public inviteCode: string;

    recipientEmail: string;
    inviteOrganisation: string;
    isExistingUser: boolean;
    organisationId: number;
    inviteId: number;
    inviteDetails: AcceptInviteCommand = {
        allowManaging: true,
        defaultOrganisation: true
    };

    signupResult: ResultDto;

    user: PersonDto;

    status = Status;

    currentStatus: Status = Status.LOADING;

    loggedIn = false;

    isNewUser = true;

    pageBodyClasses: string[] = ['first-page', 'login'];

    public SITE_DESIGNATION: string;
    public username: string;
    public password: string;
    public working: boolean;

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    @ViewChild(ScreenAnalyticsDirective)
    private screenAnalytics: ScreenAnalyticsDirective;

    constructor(
        constants: FlyFreelyConstants,
        private onboardingService: OnboardingService,
        private userService: UserService,
        private router: Router,
        private invitesService: InvitesService,
        private navigationStateService: NavigationState,
        private loginManager: LoginManager,
        private route: ActivatedRoute
    ) {
        this.inviteCode = null;
        this.error = null;
        this.SITE_DESIGNATION = constants.SITE_DESIGNATION;

        this.inviteOrganisation = null;
    }

    ngOnInit() {
        this.addBodyClasses();

        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.inviteCode = this.route.snapshot.queryParams.inviteCode;
        if (this.inviteCode == null) {
            this.loggedIn =
                this.userService.getUserStatus() === UserStatus.LOGGED_IN;
            this.currentStatus = Status.LOGIN;
            this.error = 'Your invite link is missing a code.';
            return;
        }

        this.refreshUserLoginStatus();
    }

    ngOnDestroy() {
        this.removeBodyClasses();
        this.navigationStateService.finishInviteFlow();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshUserLoginStatus() {
        // Calling startInviteFlow prevents the navigation service from automatically rerouting on login
        // This is later completed by calling finishInviteFlow()
        this.navigationStateService.startInviteFlow();
        combineLatest([
            this.userService.userChange$,
            this.userService.keycloakStatus$,
            this.invitesService.findInviteDetails(this.inviteCode)
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([user, status, invite]) => {
                    this.inviteOrganisation = invite.organisationName;
                    this.recipientEmail = invite.recipientEmail;
                    this.isExistingUser = invite.existingUser;
                    // invites?inviteCode=6af0fb64-a4fc-4b1a-a223-255ad4d28c65
                    if (
                        user.type === UserStatus.LOADING ||
                        user.type === UserStatus.UNKNOWN
                    ) {
                        this.currentStatus = Status.LOADING;
                    } else if (user.type === UserStatus.LOGGED_IN) {
                        this.user = user?.currentUser;
                        // check if this is a new user
                        this.onboardingService
                            .findUserOnboardingStatus()
                            .then(onboarded => {
                                this.isNewUser = !onboarded;
                                this.verifyInvite();
                            });
                    } else {
                        if (
                            status !== KeycloakEventType.OnAuthError &&
                            status !== KeycloakEventType.OnAuthRefreshError
                        ) {
                            this.screenAnalytics.track(
                                'invite',
                                invite.existingUser
                                    ? 'existing-start'
                                    : 'new-user-start'
                            );
                            this.currentStatus = invite.existingUser
                                ? Status.LOGIN
                                : Status.SIGNUP;
                        }
                    }
                },
                (error: FlyFreelyError) => {
                    this.navigationStateService.finishInviteFlow();
                    if (error instanceof NotFound) {
                        this.screenAnalytics.track('invite', 'not-found');
                        this.loggedIn =
                            this.userService.getUserStatus() ===
                            UserStatus.LOGGED_IN;
                        this.currentStatus =
                            this.loggedIn && this.user != null
                                ? Status.ERROR
                                : Status.LOGIN;
                        this.error = 'Invalid invite code';
                    } else {
                        this.error = error.message;
                        this.currentStatus = Status.ERROR;
                    }
                }
            );
    }

    acceptInvite() {
        if (this.currentStatus === Status.LOGGED_IN) {
            this.navigationStateService.finishInviteFlow();
            this.invitesService
                .linkInvite(this.inviteCode)
                .pipe(
                    mergeMap(invite =>
                        this.invitesService.acceptInvite(
                            this.inviteId,
                            this.inviteDetails
                        )
                    ),
                    takeUntil(this.ngUnsubscribe$)
                )
                .subscribe({
                    next: () => {
                        this.screenAnalytics.track('invite', 'existing-accept');

                        this.userService.refreshUsersOrganisations().add(() => {
                            if (this.isNewUser) {
                                this.router.navigateByUrl('onboarding');
                            } else {
                                this.goToHome();
                            }
                        });

                        this.currentStatus = Status.LOADING;
                    },
                    error: (error: FlyFreelyError) => {
                        this.error = `There was an error with accepting your invite: "${error.message}"`;
                    }
                })
                .add(this.workTracker.createTracker());
        }
    }

    verifyInvite() {
        if (this.inviteCode != null) {
            this.invitesService
                .verifyInvite(this.inviteCode)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe({
                    next: invite => {
                        if (invite != null && this.inviteId == null) {
                            this.inviteId = invite.id;
                            this.inviteOrganisation = invite.organisationName;
                            this.organisationId = invite.organisationId;
                        }
                        this.currentStatus = Status.LOGGED_IN;
                    },
                    error: (error: FlyFreelyError) => {
                        this.currentStatus = Status.ERROR;
                        this.navigationStateService.finishInviteFlow();
                        if (this.inviteId == null) {
                            this.error = `There was an error with your invite: "${error.message}"`;
                        }
                    }
                })
                .add(this.workTracker.createTracker());
        } else {
            this.navigationStateService.finishInviteFlow();
            this.router.navigateByUrl('/');
        }
    }

    goToHome() {
        if (!this.loggedIn) {
            this.doLogin();
            return;
        }
        this.router.navigate(['/']);
    }

    doLogin() {
        if (this.isExistingUser) {
            this.loginManager.login(this.recipientEmail);
        } else {
            this.loginManager.register(this.recipientEmail);
        }
    }

    goToLogin() {
        this.navigationStateService.finishInviteFlow();
        this.router.navigateByUrl('login');
    }

    ignoreInvite() {
        this.navigationStateService.finishInviteFlow();

        if (this.isNewUser) {
            this.router.navigateByUrl('onboarding');
        } else {
            this.goToHome();
        }
    }

    removeBodyClasses() {
        this.pageBodyClasses.forEach(c => document.body.classList.remove(c));
    }
    addBodyClasses() {
        this.pageBodyClasses.forEach(c => document.body.classList.add(c));
    }
}
