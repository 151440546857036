/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.33-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from 'geojson';
import { ActiveGridCellDto } from './activeGridCellDto';
import { FieldError } from './fieldError';
import { Message } from './message';

/**
 *
 */
export interface OperationAuthorisation {
    id: string;
    controllingAuthority?: string;
    maximumHeight: number;
    activeGridCellList: Array<ActiveGridCellDto>;
    operatingArea: Geometry;
    createTime: string;
    cancelTime?: string;
    updateTime?: string;
    startTime: string;
    endTime: string;
    status: OperationAuthorisation.Status;
    rejectionReason?: string;
    authorisationEligibility: OperationAuthorisation.AuthorisationEligibility;
    authorisationType: OperationAuthorisation.AuthorisationType;
    conditionList: Array<string>;
    messageList: Array<Message>;
    errors: { [key: string]: FieldError };
    /**
     *
     */
    authorisationProviderResponse?: any;
    authorisationText?: string;
    /**
     *
     */
    additionalProperties?: any;
}
export namespace OperationAuthorisation {
    export type Status =
        | 'UNSUBMITTED'
        | 'PENDING'
        | 'EXPIRED'
        | 'APPROVED'
        | 'REJECTED'
        | 'RESCINDED'
        | 'RESCINDED_AWAITING'
        | 'CANCELLED'
        | 'COMPLETED'
        | 'PROVISIONAL'
        | 'DECLARED'
        | 'ERROR'
        | 'NOT_REQUIRED';
    export const Status = {
        UNSUBMITTED: 'UNSUBMITTED' as Status,
        PENDING: 'PENDING' as Status,
        EXPIRED: 'EXPIRED' as Status,
        APPROVED: 'APPROVED' as Status,
        REJECTED: 'REJECTED' as Status,
        RESCINDED: 'RESCINDED' as Status,
        RESCINDED_AWAITING: 'RESCINDED_AWAITING' as Status,
        CANCELLED: 'CANCELLED' as Status,
        COMPLETED: 'COMPLETED' as Status,
        PROVISIONAL: 'PROVISIONAL' as Status,
        DECLARED: 'DECLARED' as Status,
        ERROR: 'ERROR' as Status,
        NOT_REQUIRED: 'NOT_REQUIRED' as Status
    };
    export type AuthorisationEligibility =
        | 'NOT_REQUIRED'
        | 'AUTO'
        | 'MANUAL'
        | 'UNAVAILABLE'
        | 'NOT_ELIGIBLE'
        | 'UNKNOWN';
    export const AuthorisationEligibility = {
        NOT_REQUIRED: 'NOT_REQUIRED' as AuthorisationEligibility,
        AUTO: 'AUTO' as AuthorisationEligibility,
        MANUAL: 'MANUAL' as AuthorisationEligibility,
        UNAVAILABLE: 'UNAVAILABLE' as AuthorisationEligibility,
        NOT_ELIGIBLE: 'NOT_ELIGIBLE' as AuthorisationEligibility,
        UNKNOWN: 'UNKNOWN' as AuthorisationEligibility
    };
    export type AuthorisationType =
        | 'AUS_CASA_AUTHORISATION'
        | 'AUS_ASA_FIMS'
        | 'NZL_AIRSHARE'
        | 'USA_LAANC';
    export const AuthorisationType = {
        AUS_CASA_AUTHORISATION: 'AUS_CASA_AUTHORISATION' as AuthorisationType,
        AUS_ASA_FIMS: 'AUS_ASA_FIMS' as AuthorisationType,
        NZL_AIRSHARE: 'NZL_AIRSHARE' as AuthorisationType,
        USA_LAANC: 'USA_LAANC' as AuthorisationType
    };
}
