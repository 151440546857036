import {
    StepDescription,
    WorkflowMappedEntityDetails
} from '@flyfreely-portal-ui/flyfreely';

/**
 * Sets up a clean step entity map, including empty entries.
 * @param steps array of valid steps
 * @param stepListMap the step value object
 * @param filterNullIds should null objects be removed
 */
export function cleanSteps(
    steps: StepDescription[],
    stepListMap: { [step: string]: WorkflowMappedEntityDetails[] },
    filterNullIds = true
) {
    if (stepListMap == null) {
        return null;
    }
    return steps.reduce((acc, step) => {
        if (stepListMap[step.value] == null) {
            acc[step.value] = [];
        } else {
            acc[step.value] = filterNullIds
                ? stepListMap[step.value].filter(e => e.id)
                : stepListMap[step.value];
        }
        return acc;
    }, {});
}
