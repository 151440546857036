<div>
    <h4 class="section-heading">
        <span>Logged Flights</span>
        <div></div>
    </h4>

    <ngx-loading [show]="working"></ngx-loading>
    <div *ngIf="error" class="alert alert-danger">
        There was an error while loading the logged flights. Please try again
        later.
    </div>

    <enhanced-help
        componentName="logged-flights"
        [compact]="true"
    ></enhanced-help>

    <div class="columns">
        <div class="column col-12">
            <ngx-table
                #table
                [configuration]="configuration"
                [data]="returnedLogs"
                [columns]="columns"
                [pagination]="pagination"
                (event)="onTableEvent($event)"
            >
                <ng-template let-row let-index="index">
                    <td>{{ row.startTime | date: 'dd/MMM/yyyy' }}</td>
                    <td *ngIf="organisation != null">
                        <a
                            *ngIf="row.type === 'LOGGED'"
                            (click)="showMission(row.missionId)"
                            >{{ row.missionId }}</a
                        >
                        <a
                            *ngIf="row.type === 'UNASSIGNED'"
                            (click)="showFlightLog(row.id)"
                            >Unassigned Flight Log</a
                        >
                        {{ row.type === 'HISTORICAL' ? 'Historical' : '' }}
                    </td>
                    <td *ngIf="organisation == null">
                        {{
                            row.type === 'LOGGED'
                                ? row.missionId
                                : row.type === 'UNASSIGNED'
                                ? 'Unassigned Flight Log'
                                : 'Historical'
                        }}
                    </td>
                    <td>{{ row.flightNumber }}</td>
                    <td *ngIf="logType === 'PERSON'">
                        {{ row.visualLineOfSight }}
                    </td>
                    <td *ngIf="logType === 'PERSON'">{{ row.timeOfDay }}</td>
                    <td>
                        {{ row.duration | formatDuration  | defaultTo }}
                        <ng-container
                            *ngIf="
                                historyDocumentation &&
                                historyDocumentation[row.missionId]
                            "
                        >
                            <documentation-responses
                                [responses]="
                                    historyDocumentation[row.missionId][
                                        row.sortieId
                                    ]
                                "
                                [steps]="steps"
                            >
                            </documentation-responses>
                        </ng-container>
                    </td>
                    <td *ngIf="serviceabilitySignoffs">
                        <ng-container>
                            {{ signoffMessage(row.serviceabilitySignoff) }}
                        </ng-container>
                    </td>
                </ng-template>
            </ngx-table>
        </div>
    </div>

    <!-- <table class="table top-buffer">
        <thead>
            <tr>
                <th>Date</th>
                <th>Mission ID</th>
                <th>Flight ID</th>
                <th>Flight Number</th>
                <th *ngIf="logType === 'PERSON'">VLOS Type</th>
                <th *ngIf="logType === 'PERSON'">Time Type</th>
                <th>Flight Time</th>
                <th *ngIf="serviceabilitySignoffs">Serviceability Signoff</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let log of returnedLogs">
                <td>{{ log.date | date: 'dd/MMM/yyyy' }}</td>
                <td *ngIf="organisation != null">
                    <a (click)="showMission(log.missionId)">{{
                        log.missionId
                    }}</a>
                </td>
                <td *ngIf="organisation == null">{{ log.missionId }}</td>
                <td>{{ log.sortieId }}</td>
                <td>{{ log.number }}</td>
                <td *ngIf="logType === 'PERSON'">{{ log.visualLineOfSight }}</td>
                <td *ngIf="logType === 'PERSON'">{{ log.timeOfDay }}</td>
                <td>
                    {{ log.duration | formatDuration  | defaultTo }}
                    <ng-container
                        *ngIf="
                            historyDocumentation &&
                            historyDocumentation[log.missionId]
                        "
                    >
                        <documentation-responses
                            [responses]="
                                historyDocumentation[log.missionId][
                                    log.sortieId
                                ]
                            "
                            [steps]="steps"
                        >
                        </documentation-responses>
                    </ng-container>
                </td>
                <td *ngIf="serviceabilitySignoffs">
                    <ng-container>
                        {{
                            signoffMessage(
                                serviceabilitySignoffs[log.missionId]
                            )
                        }}
                    </ng-container>
                </td>
            </tr>
        </tbody>
        <tfoot *ngIf="logs && logs.length > 10">
            <tr>
                <td colspan="4" class="text-center">
                    <pagination
                        class="pagination-sm"
                        [totalItems]="logs.length"
                        (pageChanged)="pageChanged($event)"
                    >
                    </pagination>
                </td>
            </tr>
        </tfoot>
    </table> -->
</div>
