<div class="modal-header no-icon">
    <h3 class="modal-title">
        {{ title }}
    </h3>
    <button type="button" (click)="hide()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <formly-form
            *ngIf="addressFields != null"
            [form]="addressForm"
            [fields]="addressFields"
            [options]="options"
            [model]="address"
        ></formly-form>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            type="submit"
            [disabled]="addressForm?.invalid || working"
        >
            Save
        </button>
    </div>
</form>
