<div class="modal-content">
    <div class="modal-header no-icon">
        <h4 class="modal-title pull-left">{{ activityTitle }}</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div
        class="container-fluid top-buffer bottom-buffer"
        style="min-height: 330px"
    >
        <flyfreely-video
            [videoUrl]="videoUrl"
            [thumbnail]="thumbnail"
            videoType="video/mp4"
            [playerOptions]="videoOptions"
            (percentagePlayed)="updatePercentagePlayed($event)"
        ></flyfreely-video>
    </div>
</div>
