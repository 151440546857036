import { Pipe, PipeTransform } from '@angular/core';
import { RESOURCE_STATUS_LIST } from '@flyfreely-portal-ui/flyfreely';
import { setupStatusFormatter } from '@flyfreely-portal-ui/ui';

@Pipe({ name: 'formatResourceStatus' })
export class FormatResourceStatusPipe implements PipeTransform {
    transform: (value: any, ...args: any[]) => any;

    constructor() {
        this.transform = setupStatusFormatter(RESOURCE_STATUS_LIST);
    }
}
