<enhanced-help
    componentName="battery-type-list"
    [compact]="true"
>
</enhanced-help>
<div class="container-scroll">
    <div class="horizontal-container">
        <button
            type="button"
            class="btn-circle btn-link"
            [disabled]="working"
            (click)="createBatteryType()"
            tooltip="Create battery type"
            placement="left"
        >
            <span class="fa fa-plus"></span>
        </button>
        <div class="fill">
            <static-table
                *ngIf="batteryTypes != null"
                [availableColumns]="availableColumns"
                [selectedColumns]="selectedColumns"
                [tableData]="batteryTypes"
                [tableConfig]="tableConfig"
                [columnSorting]="columnSorting"
                (selectedColumnsChanged)="
                    updateSelectedColumns($event.selectedColumns)
                "
                (sortOrderChanged)="updateColumnSorting($event)"
            >
            </static-table>
            <loading-indicator
                class="pull-left"
                [isLoading]="working"
            ></loading-indicator>
        </div>
    </div>
</div>
