<ng-container screenAnalytics="airspace-authorisation-preview">
    <div class="modal-header no-icon">
        <h4 id="dialog-child-name" class="modal-title pull-left">
            <span
                *ngIf="
                    authorisations != null &&
                    authorisations.length > 0 &&
                    !requestNew
                "
            >
                {{
                    authorisations[0].authorisationType
                        | formatAuthorisationType
                }}
            </span>
            <span
                *ngIf="
                    (authorisations == null || authorisations.length == 0) &&
                    authorisationPreview != null
                "
            >
                {{
                    authorisationPreview.authorisationType
                        | formatAuthorisationType
                }}
            </span>
            <span
                *ngIf="
                    (authorisations == null || authorisations.length == 0) &&
                    authorisationPreview == null
                "
            >
                Airspace&nbsp;
            </span>
            Authorisation Request
        </h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div
        class="modal-body"
        [ngClass]="{
            'no-footer': operationFailed && authorisationServiceUnavailable,
            'max-height': !operationFailed && !authorisationServiceUnavailable
        }"
    >
        <form
            *ngIf="
                airspaceAuthorisationForm != null &&
                airspaceAuthorisationForm.value != null &&
                !authorisationLoading
            "
            [formGroup]="airspaceAuthorisationForm"
        >
            <div class="modal-body-content">
                <div
                    class="horizontal-container fill"
                    style="max-height: calc(100vh - 292px)"
                >
                    <!-- TODO: much of this won't work with the new form and needs to be revised -->
                    <div
                        *ngIf="
                            (authorisations == null ||
                                authorisations.length == 0) &&
                            authorisationPreview == null &&
                            working
                        "
                        class="fcol-6"
                    ></div>
                    <div
                        *ngIf="
                            (authorisations == null ||
                                authorisations.length == 0) &&
                            authorisationPreview == null &&
                            !working
                        "
                        class="fcol-6 vertical-container container-scroll modal-scrollbar"
                    >
                        <div
                            *ngIf="
                                !(
                                    missingRpicJurisdiction ||
                                    missingRpicJurisdictionIdentifier ||
                                    missingRpicPhone
                                ) &&
                                !operationFailed &&
                                !authorisationServiceUnavailable
                            "
                            class="alert alert-info"
                            role="alert"
                        >
                            <p>
                                No validation results for this automated check
                            </p>
                        </div>
                        <div
                            *ngIf="
                                missingRpicJurisdiction &&
                                !operationFailed &&
                                !authorisationServiceUnavailable
                            "
                            class="alert alert-warning"
                            role="alert"
                        >
                            <p>The RPIC has no active jurisdiction</p>
                        </div>
                        <div
                            *ngIf="
                                missingRpicJurisdictionIdentifier &&
                                !operationFailed &&
                                !authorisationServiceUnavailable
                            "
                            class="alert alert-warning"
                            role="alert"
                        >
                            <p>
                                The RPIC is missing a unique identifier for this
                                jurisdiction, eg. an ARN or licence number
                            </p>
                        </div>
                        <div
                            *ngIf="
                                missingRpicPhone &&
                                !operationFailed &&
                                !authorisationServiceUnavailable
                            "
                            class="alert alert-warning"
                            role="alert"
                        >
                            <p>
                                There is no phone number for the RPIC. This is
                                required for airspace authorisation.
                                <span *ngIf="rpic != null">
                                    Add a temporary phone number below.
                                    <br />
                                    NOTE: This will not update the phone number
                                    for this RPIC permanently, only for this
                                    authorisation application.
                                </span>
                            </p>
                        </div>
                        <div
                            *ngIf="rpic != null && missingRpicPhone"
                            class="horizontal-container"
                        >
                            <div class="fcol-5">
                                <formly-form
                                    [fields]="rpicDetailsFields"
                                    [form]="rpicDetailsForm"
                                ></formly-form>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary pull-right"
                                    (click)="updateRpicPhoneNumber(null)"
                                    [disabled]="working"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            (authorisations == null ||
                                authorisations.length == 0 ||
                                requestNew) &&
                            authorisationPreview != null
                        "
                        class="vertical-container fcol-6 contents container-scroll modal-scrollbar"
                    >
                        <enhanced-help
                            *ngIf="
                                authorisationPreview.authorisationType != null
                            "
                            helpTitle="Airspace Authorisation"
                            [componentName]="
                                'screen-' +
                                authorisationPreview.authorisationType
                            "
                            [compact]="true"
                            [alwaysActive]="true"
                        >
                        </enhanced-help>
                        <div
                            *ngIf="
                                authorisationPreview.authorisationType !=
                                    null &&
                                authorisationPreview.messages != null
                            "
                            class="horizontal-container top-buffer bottom-buffer"
                        >
                            <div class="fcol-12">
                                <span class="display-label"> Messages </span>
                                <div class="horizontal-container fill">
                                    <airspace-authorisation-messaging
                                        [messages]="
                                            authorisationPreview.messages
                                        "
                                    ></airspace-authorisation-messaging>
                                </div>
                            </div>
                        </div>
                        <h4>Controlling Authorities</h4>
                        <!-- <h4
                            *ngIf="
                                (visibleFormFields.includes(
                                    'activeGridCellList'
                                ) ||
                                    visibleFormFields.includes(
                                        'authorisationList'
                                    )) &&
                                ((formValues.activeGridCellList &&
                                    formValues.activeGridCellList.length) ||
                                    (formValues.authorisationList &&
                                        formValues.authorisationList.length))
                            "
                        >
                            Controlling Authorities
                        </h4> -->
                        <div
                            *ngIf="
                                visibleFormFields.includes('activeGridCellList')
                            "
                            class="horizontal-container"
                        >
                            <div class="fcol-12">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="activeGridCellList"
                                    >
                                        <!-- <field-validation-indicator
                                        class="right-buffer"
                                        [valid]="
                                            authorisationPreview.activeGridCellList ==
                                                null ||
                                            (authorisationPreview.activeGridCellList &&
                                                mission.maximumHeight <=
                                                    authorisationPreview.maximumHeight &&
                                                !validationResultMessages
                                                    ?.height?.length)
                                        "
                                    > -->
                                    </field-validation-indicator>
                                    Grid Cell Definition (GCD)
                                    <button
                                        type="button"
                                        class="btn btn-tertiary pull-right"
                                        tooltip="{{
                                            collapseGcdTable
                                                ? 'Show GCD value table'
                                                : 'Hide GCD value table'
                                        }}"
                                        placement="top"
                                        (click)="
                                            collapseGcdTable = !collapseGcdTable
                                        "
                                    >
                                        <span
                                            *ngIf="!collapseGcdTable"
                                            class="fal fa-chevron-up"
                                        ></span>
                                        <span
                                            *ngIf="collapseGcdTable"
                                            class="fal fa-chevron-down"
                                        ></span>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes('activeGridCellList')
                            "
                            class="horizontal-container bottom-buffer"
                            style="margin-top: -5px"
                        >
                            <div class="fcol-7">
                                <div
                                    style="width: 100%"
                                    [collapse]="collapseGcdTable"
                                    [isAnimated]="true"
                                >
                                    <table style="width: 100%">
                                        <thead>
                                            <tr>
                                                <th style="width: 70%">
                                                    Cell ID
                                                </th>
                                                <th style="width: 30%">
                                                    Max Height
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="
                                                    let cell of formValues.activeGridCellList
                                                "
                                                class="data"
                                            >
                                                <td style="width: 50%">
                                                    {{ cell.cellId }}
                                                </td>
                                                <td style="width: 50%">
                                                    {{
                                                        cell.ceiling.value
                                                    }}&nbsp;
                                                    <span
                                                        *ngIf="
                                                            !isFeet(
                                                                cell.ceiling
                                                                    .unit
                                                            )
                                                        "
                                                        style="
                                                            text-transform: lowercase;
                                                        "
                                                    >
                                                        {{
                                                            cell.ceiling.unit
                                                        }}&nbsp;{{
                                                            cell.ceiling.datum
                                                        }}
                                                    </span>
                                                    <span
                                                        *ngIf="
                                                            isFeet(
                                                                cell.ceiling
                                                                    .unit
                                                            )
                                                        "
                                                    >
                                                        ft AGL
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    *ngIf="
                                        formValues.authorisationMaxHeight !=
                                        null
                                    "
                                    [collapse]="!collapseGcdTable"
                                    [isAnimated]="true"
                                >
                                    Max Authorised Height:&nbsp;{{
                                        formValues.authorisationMaxHeight
                                    }}ft AGL
                                    <div
                                        *ngIf="
                                            airspaceAuthorisationForm.controls
                                                .activeGridCellList.invalid
                                        "
                                        class="airspace-result-parameter fail"
                                    >
                                        {{
                                            airspaceAuthorisationForm.controls
                                                .activeGridCellList.errors
                                                ?.activeGridCellList
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    formValues.authorisationMaxHeight != null
                                "
                                class="fcol-5 airspace-result-container"
                                style="justify-content: center"
                            >
                                <div
                                    [collapse]="collapseGcdTable"
                                    [isAnimated]="true"
                                >
                                    Max Authorised Height:&nbsp;{{
                                        formValues.authorisationMaxHeight
                                    }}ft AGL
                                    <div
                                        *ngIf="
                                            formValues.authorisationMaxHeight ==
                                            0
                                        "
                                        class="airspace-result-parameter fail"
                                        style="text-transform: capitalize"
                                    >
                                        GCD does not permit flying
                                    </div>
                                    <div
                                        *ngIf="
                                            airspaceAuthorisationForm.controls
                                                .activeGridCellList.invalid
                                        "
                                        class="airspace-result-parameter fail"
                                    >
                                        {{
                                            airspaceAuthorisationForm.controls
                                                .activeGridCellList.errors
                                                ?.activeGridCellList
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes('authorisationList')
                            "
                            class="horizontal-container"
                        >
                            <div class="fill">
                                <ng-container
                                    *ngIf="
                                        formValues.authorisationList as authorisationList
                                    "
                                >
                                    <table class="table">
                                        <thead>
                                            <tr class="row-even">
                                                <th class="wordwrap">
                                                    Authorisation ID
                                                </th>
                                                <th class="wordwrap">
                                                    Controlling Authority
                                                </th>
                                                <th class="wordwrap">
                                                    Maximum Height
                                                </th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th class="wordwrap">
                                                    Authorisation Eligibility
                                                </th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr
                                                *ngFor="
                                                    let authorisation of authorisationList
                                                "
                                            >
                                                <td>
                                                    {{ authorisation.id }}
                                                </td>
                                                <td>
                                                    {{
                                                        authorisation.controllingAuthority
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        authorisation.maximumHeight
                                                    }}
                                                </td>
                                                <td class="wordwrap">
                                                    {{
                                                        authorisation.startTime
                                                            | formatLocalDateTimeWithUTCProtracted
                                                                : formValues?.timeZone
                                                    }}
                                                </td>
                                                <td class="wordwrap">
                                                    {{
                                                        authorisation.endTime
                                                            | formatLocalDateTimeWithUTCProtracted
                                                                : formValues?.timeZone
                                                    }}
                                                </td>
                                                <td class="wordwrap">
                                                    {{
                                                        authorisation.authorisationEligibility
                                                            | formatAuthorisationEligibility
                                                                : airspaceAuthorisationType
                                                    }}
                                                </td>
                                                <td class="wordwrap">
                                                    {{
                                                        authorisation.status
                                                            | formatAuthorisationStatus
                                                    }}
                                                </td>
                                                <td class="wordwrap">
                                                    <show-errors
                                                        [errors]="
                                                            authorisation.errors
                                                        "
                                                    ></show-errors>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </div>
                        </div>
                        <h4>Mission Details</h4>
                        <div
                            *ngIf="
                                visibleFormFields.includes('publicMissionName')
                            "
                            class="horizontal-container bottom-buffer"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="publicMissionName"
                                    >
                                    </field-validation-indicator>
                                    Public Mission Name
                                </span>
                                <div class="horizontal-container fill">
                                    <input
                                        class="form-control"
                                        type="text"
                                        formControlName="publicMissionName"
                                        [readonly]="working"
                                    />
                                </div>
                                <p class="help-text">
                                    The name to be associated with this
                                    authorisation and which will be publicly
                                    visible when viewed on the relevant
                                    authority's systems.
                                </p>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    *ngIf="
                                        airspaceAuthorisationForm.controls
                                            .publicMissionName.invalid
                                    "
                                    class="airspace-result-parameter fail"
                                    style="text-transform: capitalize"
                                >
                                    {{
                                        airspaceAuthorisationForm.controls
                                            .publicMissionName.errors
                                            ?.publicMissionName
                                    }}
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes('plannedMaxHeight')
                            "
                            class="horizontal-container bottom-buffer"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="plannedMaxHeight"
                                    >
                                    </field-validation-indicator>
                                    Planned Max Height
                                </span>
                                <div class="horizontal-container fill">
                                    <div>
                                        {{
                                            formValues.plannedMaxHeight
                                        }}&nbsp;ft AGL
                                    </div>
                                </div>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    *ngIf="
                                        airspaceAuthorisationForm.controls
                                            .plannedMaxHeight.invalid
                                    "
                                    class="airspace-result-parameter fail"
                                    style="text-transform: capitalize"
                                >
                                    {{
                                        airspaceAuthorisationForm.controls
                                            .plannedMaxHeight.errors
                                            ?.plannedMaxHeight
                                    }}
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="visibleFormFields.includes('flightProfile')"
                            class="horizontal-container bottom-buffer"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="flightProfile"
                                    >
                                    </field-validation-indicator>
                                    Planned Flight Profile
                                </span>
                                <ng-select
                                    formControlName="flightProfile"
                                    [disabled]="working"
                                    [clearable]="false"
                                    [items]="casaFlightProfiles"
                                    placeholder="Select a planned flight profile"
                                    bindLabel="name"
                                    bindValue="value"
                                    appendTo="body"
                                ></ng-select>
                            </div>
                            <div class="fcol-2">
                                <!-- <button
                                class="btn-primary top-buffer bottom-buffer"
                                type="button"
                                (click)="editAdditionalFields()"
                            >
                                Edit
                            </button> -->
                            </div>
                            <div class="fcol-3 airspace-result-container"></div>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes(
                                    'civilTwilightStart'
                                ) &&
                                visibleFormFields.includes('civilTwilightEnd')
                            "
                            class="horizontal-container bottom-buffer"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="civilTwilight"
                                    >
                                    </field-validation-indicator>
                                    Civil Twilight
                                </span>
                                <div
                                    class="horizontal-container fill"
                                    style="justify-content: space-between"
                                >
                                    <div>
                                        {{ formValues.civilTwilight }}
                                    </div>
                                </div>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    *ngIf="
                                        !airspaceAuthorisationForm.controls
                                            .civilTwilight.valid
                                    "
                                    class="airspace-result-parameter fail"
                                >
                                    {{
                                        airspaceAuthorisationForm.controls
                                            .civilTwilight.errors?.civilTwilight
                                    }}
                                </div>
                            </div>
                        </div>

                        <div class="horizontal-container bottom-buffer">
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="startTime"
                                    >
                                    </field-validation-indicator>
                                    Mission Start
                                </span>
                                <div
                                    class="horizontal-container fill"
                                    style="justify-content: space-between"
                                >
                                    <div
                                        *ngIf="!editStart"
                                        class="horizontal-container"
                                    >
                                        <span>{{ formValues.startTime }}</span>
                                        <ng-container
                                            *ngIf="
                                                !airspaceAuthorisationForm
                                                    .controls.startTime.valid
                                            "
                                        >
                                            <span>&nbsp;</span>
                                            <button
                                                class="btn btn-xs btn-tertiary"
                                                type="button"
                                                tooltip="Edit mission start time"
                                                placement="top"
                                                [disabled]="working"
                                                (click)="editStart = true"
                                            >
                                                <span
                                                    class="fal fa-pencil"
                                                ></span>
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                                <form [formGroup]="updatedTimeForm">
                                    <div
                                        *ngIf="editStart"
                                        class="form-group input-content horizontal-container fill"
                                        [ngClass]="{
                                            disabled: working
                                        }"
                                    >
                                        <div class="fcol-4">
                                            <div class="input-group">
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    required
                                                    #datepicker="bsDatepicker"
                                                    bsDatepicker
                                                    formControlName="startTime"
                                                    [readonly]="working"
                                                    [isDisabled]="working"
                                                />
                                                <span
                                                    *ngIf="!working"
                                                    class="input-group-addon btn-default"
                                                    (click)="
                                                        datepicker.toggle()
                                                    "
                                                    [attr.aria-expanded]="
                                                        datepicker.isOpen
                                                    "
                                                >
                                                    <i
                                                        class="glyphicon glyphicon-calendar"
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="fcol-1"></div>

                                        <div
                                            class="fcol-3"
                                            style="width: fit-content"
                                        >
                                            <timepicker
                                                formControlName="startTime"
                                                [hourStep]="1"
                                                [minuteStep]="1"
                                                [showMeridian]="false"
                                                [showSpinners]="false"
                                                [readonlyInput]="working"
                                            >
                                            </timepicker>
                                        </div>
                                        <div class="fcol-3">
                                            <button
                                                class="btn btn-xs btn-tertiary"
                                                type="button"
                                                tooltip="Save"
                                                placement="top"
                                                [disabled]="working"
                                                (click)="updateMissionTime()"
                                            >
                                                <span
                                                    class="fa fa-check"
                                                ></span>
                                            </button>
                                            <button
                                                class="btn btn-xs btn-tertiary btn-delete"
                                                type="button"
                                                tooltip="Cancel edit"
                                                placement="top"
                                                [disabled]="working"
                                                (click)="cancelEditStart()"
                                            >
                                                <span
                                                    class="fa fa-times"
                                                ></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    class="airspace-result-parameter"
                                    [ngClass]="{
                                        pass: airspaceAuthorisationForm.controls
                                            .startTime.valid,
                                        fail: !airspaceAuthorisationForm
                                            .controls.startTime.valid
                                    }"
                                >
                                    {{ formValues.startRangeString }}
                                </div>
                                <div
                                    *ngIf="
                                        !airspaceAuthorisationForm.controls
                                            .startTime.valid
                                    "
                                    class="airspace-result-parameter fail"
                                >
                                    {{
                                        airspaceAuthorisationForm.controls
                                            .startTime.errors?.startTime
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="horizontal-container bottom-buffer">
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="endTime"
                                    >
                                    </field-validation-indicator>
                                    {{
                                        editEnd
                                            ? 'Mission Duration'
                                            : 'Mission End'
                                    }}
                                </span>
                                <div class="horizontal-container">
                                    <div
                                        *ngIf="!editEnd"
                                        class="horizontal-container"
                                    >
                                        <span>{{ formValues.endTime }}</span>
                                        <ng-container
                                            *ngIf="
                                                !airspaceAuthorisationForm
                                                    .controls.endTime.valid
                                            "
                                        >
                                            <span>&nbsp;</span>
                                            <button
                                                class="btn btn-xs btn-tertiary"
                                                type="button"
                                                tooltip="Edit mission duration"
                                                placement="top"
                                                [disabled]="working"
                                                (click)="editEnd = true"
                                            >
                                                <span
                                                    class="fal fa-pencil"
                                                ></span>
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                                <form [formGroup]="updatedTimeForm">
                                    <div
                                        *ngIf="editEnd"
                                        class="horizontal-container fill"
                                    >
                                        <duration
                                            formControlName="duration"
                                            [readonly]="working"
                                            [showHour]="true"
                                            [showMinute]="true"
                                            [showSecond]="false"
                                        >
                                        </duration>
                                        <button
                                            class="btn btn-xs btn-tertiary"
                                            type="button"
                                            tooltip="Cancel edit"
                                            placement="top"
                                            [disabled]="working"
                                            (click)="cancelEditDuration()"
                                        >
                                            <span class="fa fa-times"></span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    class="airspace-result-parameter"
                                    [ngClass]="{
                                        pass: airspaceAuthorisationForm.controls
                                            .endTime.valid,
                                        fail: !airspaceAuthorisationForm
                                            .controls.endTime.valid
                                    }"
                                >
                                    {{ formValues.endRangeString }}
                                </div>
                                <div
                                    *ngIf="
                                        !airspaceAuthorisationForm.controls
                                            .endTime.valid
                                    "
                                    class="airspace-result-parameter fail"
                                >
                                    {{
                                        airspaceAuthorisationForm.controls
                                            .endTime.errors?.endTime
                                    }}
                                </div>
                            </div>
                        </div>
                        <ng-container
                            *ngIf="
                                missionFlightAreas &&
                                missionFlightAreas.length > 1
                            "
                        >
                            <div>
                                <span class="display-label">
                                    Mission Flight Areas
                                </span>
                                <div>
                                    Only one flight area
                                    {{
                                        canRequestLivePreview
                                            ? 'of this type '
                                            : ''
                                    }}can be submitted for Authorisation. Please
                                    select which area to submit:
                                </div>
                            </div>
                            <div
                                class="horizontal-container bottom-buffer"
                                *ngIf="
                                    missionFlightAreas &&
                                    missionFlightAreas.length > 1
                                "
                            >
                                <div class="fcol-7">
                                    <div
                                        *ngFor="let area of missionFlightAreas"
                                    >
                                        <a
                                            type="button"
                                            class="btn btn-pill btn-xs"
                                            style="
                                                min-width: 50%;
                                                margin-top: 5px;
                                            "
                                            [ngClass]="{
                                                active:
                                                    selectedFlightArea.name ===
                                                    area.name
                                            }"
                                            (click)="selectFlightArea(area)"
                                        >
                                            {{ area.name }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="horizontal-container bottom-buffer">
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="rpic"
                                    >
                                    </field-validation-indicator>
                                    Remote Pilot
                                </span>
                                <div
                                    class="vertical-container"
                                    *ngIf="formValues.rpic != null"
                                >
                                    <a
                                        class="right-buffer"
                                        (click)="showPilot(formValues.rpic)"
                                        >{{ formValues.rpic.firstName }}&nbsp;{{
                                            formValues.rpic.lastName
                                        }}</a
                                    >
                                    <div class="horizontal-container">
                                        <span class="right-buffer"
                                            ><span
                                                ><label class="inline-label">{{
                                                    formValues.rpicAuthLabel
                                                }}</label></span
                                            >&nbsp;{{
                                                formValues.rpicArn
                                            }}</span
                                        >
                                        <inline-edits
                                            style="
                                                display: flex;
                                                align-items: flex-start;
                                            "
                                            [model]="formValues.rpicPhone"
                                            [isNewForm]="false"
                                            labelName="Ph"
                                            [inlineLabel]="true"
                                            popoverTitle="Edit Phone Number"
                                            [canEdit]="true"
                                            (save)="
                                                updateRpicPhoneNumber($event)
                                            "
                                        >
                                        </inline-edits>
                                        <span class="right-buffer"> </span>
                                    </div>
                                </div>
                            </div>
                            <div class="fcol-1 vertical-align"></div>
                            <div
                                class="fcol-4 airspace-result-container"
                                [ngClass]="{
                                    'vertical-align':
                                        formValues.rpic &&
                                        formValues.authorisationErrors
                                            ?.remotePilotRegistrationDetails &&
                                        formValues.authorisationErrors
                                            ?.remotePilotRegistrationDetails
                                            .code !== 'Invalid'
                                }"
                                style="justify-content: center"
                            >
                                <button
                                    *ngIf="
                                        formValues.rpic &&
                                        formValues.authorisationErrors
                                            ?.remotePilotRegistrationDetails &&
                                        formValues.authorisationErrors
                                            ?.remotePilotRegistrationDetails
                                            .code !== 'Invalid'
                                    "
                                    type="button"
                                    class="btn btn-primary btn-sm right-buffer"
                                    (click)="showRegisterPilot()"
                                    [tooltip]="
                                        formValues.authorisationErrors
                                            .remotePilotRegistrationDetails
                                            .message
                                    "
                                    [disabled]="working"
                                    placement="top"
                                >
                                    Register User
                                </button>
                                <ng-container
                                    *ngIf="authorisationPreview != null"
                                >
                                    <div
                                        *ngIf="
                                            airspaceAuthorisationForm.controls
                                                .rpic.invalid
                                        "
                                        class="airspace-result-parameter fail"
                                    >
                                        {{
                                            airspaceAuthorisationForm.controls
                                                .rpic.errors?.rpic
                                        }}
                                    </div>
                                    <div
                                        *ngIf="
                                            airspaceAuthorisationForm.controls
                                                .rpicPhone.invalid
                                        "
                                        class="airspace-result-parameter fail"
                                    >
                                        {{
                                            airspaceAuthorisationForm.controls
                                                .rpicPhone.errors
                                                ?.remotePilotPhoneNumber
                                        }}
                                    </div>
                                    <!-- TODO: figure out the missing registration error -->
                                    <!-- <div
                                        *ngIf="rpicInvalidRegistration"
                                        class="airspace-result-parameter fail"
                                    >
                                        {{ missingRpicMessages.registration }}
                                    </div> -->
                                </ng-container>
                            </div>
                        </div>
                        <div
                            *ngIf="visibleFormFields.includes('crp')"
                            class="horizontal-container bottom-buffer"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="crp"
                                    >
                                    </field-validation-indicator>
                                    Chief Remote Pilot
                                </span>
                                <div *ngIf="formValues.crp == null">
                                    <span>No CRP details available</span>
                                </div>
                                <div
                                    class="horizontal-container"
                                    *ngIf="formValues.crp != null"
                                >
                                    <a
                                        class="right-buffer"
                                        (click)="showPilot(formValues.crp)"
                                        >{{ formValues.crp.firstName }}&nbsp;{{
                                            formValues.crp.lastName
                                        }}</a
                                    >
                                    <span class="right-buffer"
                                        ><span
                                            ><label>{{
                                                formValues.crpAuthLabel
                                            }}</label></span
                                        >&nbsp;{{ formValues.crpArn }}</span
                                    >
                                </div>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    *ngIf="
                                        !airspaceAuthorisationForm.controls.crp
                                            .valid
                                    "
                                    class="airspace-result-parameter fail"
                                >
                                    No CRP for this mission
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes('additionalFields')
                            "
                            class="horizontal-container bottom-buffer"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        [valid]="
                                            additionalInformationForm.valid
                                        "
                                    >
                                    </field-validation-indicator>
                                    Additional Information
                                </span>
                                <div class="horizontal-container">
                                    {{
                                        authorisationPreview.authorisationType
                                            | formatAuthorisationType
                                    }}
                                    requires
                                    {{ additionalInformationFields?.length }}
                                    additional responses&nbsp;
                                    <button
                                        class="btn btn-xs btn-tertiary"
                                        type="button"
                                        tooltip="Edit additional fields"
                                        placement="top"
                                        [disabled]="working"
                                        (click)="editAdditionalFields()"
                                    >
                                        <span class="fal fa-pencil-alt"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="fcol-2"></div>
                            <div class="fcol-3 airspace-result-container"></div>
                        </div>
                        <div class="horizontal-container">
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="rpaList"
                                    >
                                    </field-validation-indicator>
                                    RPA
                                </span>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    *ngIf="
                                        !airspaceAuthorisationForm.controls
                                            .rpaList.valid
                                    "
                                    class="airspace-result-parameter fail"
                                    style="text-transform: capitalize"
                                >
                                    {{
                                        airspaceAuthorisationForm.controls
                                            .rpaList.errors?.rpaList
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="bottom-buffer">
                            <ng-container
                                *ngFor="let rpa of formValues.rpaList"
                            >
                                <div class="horizontal-container">
                                    <div class="fcol-7">
                                        <div class="horizontal-container">
                                            <a
                                                class="right-buffer"
                                                (click)="showRpa(rpa)"
                                                >{{ rpa.nickname }}</a
                                            >
                                            <span
                                                ><span
                                                    ><label
                                                        >Serial:</label
                                                    ></span
                                                >&nbsp;{{
                                                    rpa.manufacturerSerialNumber
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="fcol-1"></div>
                                    <div
                                        class="fcol-4 airspace-result-container"
                                    >
                                        <div
                                            *ngIf="rpa.missingMtow"
                                            class="airspace-result-parameter fail"
                                        >
                                            {{ rpa.nickname }} is missing a MTOW
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes('delegatedAuthority')
                            "
                            class="horizontal-container bottom-buffer"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="delegatedAuthority"
                                    >
                                    </field-validation-indicator>
                                    {{ delegatedAuthorityLabel }}
                                </span>
                                <div
                                    class="vertical-container"
                                    *ngIf="
                                        formValues.delegatedAuthority != null
                                    "
                                >
                                    <div
                                        *ngIf="
                                            formValues.delegatedAuthority
                                                .authorityType
                                                ?.identifierLabel ||
                                            formValues.delegatedAuthority
                                                .identifier
                                        "
                                    >
                                        {{
                                            formValues.delegatedAuthority
                                                .authorityType?.identifierLabel
                                                ? formValues.delegatedAuthority
                                                      .authorityType
                                                      ?.identifierLabel
                                                : 'Certificate'
                                        }}:&nbsp;{{
                                            formValues.delegatedAuthority
                                                .identifier
                                        }}
                                    </div>
                                    <div>
                                        {{
                                            formValues.delegatedAuthority
                                                .authorityType?.name
                                        }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        formValues.delegatedAuthority == null
                                    "
                                >
                                    No delegatedAuthority Attached to this
                                    mission
                                </div>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container">
                                <div
                                    *ngIf="
                                        !airspaceAuthorisationForm.controls
                                            .delegatedAuthority.valid
                                    "
                                    class="airspace-result-parameter fail"
                                >
                                    {{
                                        airspaceAuthorisationForm.controls
                                            .delegatedAuthority.errors
                                            ?.delegatedAuthority
                                    }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="horizontal-container bottom-buffer"
                            *ngIf="visibleFormFields.includes('airspace')"
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="airspaceValidation"
                                    >
                                    </field-validation-indicator>
                                    Airspace
                                </span>
                                <div
                                    *ngIf="
                                        airspaceAuthorisationForm.controls
                                            .airspaceValidation.valid
                                    "
                                    class="airspace-result-parameter pass"
                                >
                                    {{ formValues.airspaceValidation }}
                                </div>
                                <div
                                    *ngIf="
                                        !airspaceAuthorisationForm.controls
                                            .airspaceValidation.valid
                                    "
                                    class="airspace-result-parameter fail"
                                >
                                    <span
                                        [innerHTML]="
                                            airspaceAuthorisationForm.controls
                                                .airspaceValidation.errors
                                                ?.airspaceValidation
                                        "
                                    ></span>
                                </div>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container"></div>
                        </div>
                        <div
                            class="horizontal-container bottom-buffer"
                            *ngIf="
                                visibleFormFields.includes(
                                    'safetyJustification'
                                ) && justificationRequired
                            "
                        >
                            <div class="fcol-7">
                                <span class="display-label">
                                    <field-validation-indicator
                                        class="right-buffer"
                                        validatorFormControlName="safetyJustification"
                                    >
                                    </field-validation-indicator>
                                    {{ justificationLabel }}
                                </span>
                                <div class="horizontal-container">
                                    <textarea
                                        class="form-control"
                                        formControlName="safetyJustification"
                                        [readonly]="working"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="fcol-1"></div>
                            <div class="fcol-4 airspace-result-container"></div>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes(
                                    'warningsAndBlocks'
                                ) && formValues.warningsAndBlocks.length
                            "
                            formArrayName="warningsAndBlocks"
                        >
                            <!-- TODO: add warnings and blocks counter -->
                            <h4>
                                Warnings and Blocks<!-- &nbsp;<span>{{
                                    formValues.warningsAndBlocks.length
                                }}</span> -->
                            </h4>
                            <div
                                *ngFor="
                                    let item of airspaceAuthorisationForm
                                        .controls.warningsAndBlocks.controls;
                                    let i = index
                                "
                                [formGroupName]="i"
                                class="bottom-buffer"
                            >
                                <div
                                    class="horizontal-container vertical-align"
                                >
                                    <div
                                        class="fcol-8 horizontal-container vertical-align acknowledgement-top"
                                        [ngClass]="{
                                            'active-warning-acknowledgement':
                                                expandWarningsAndBlockersIndex ===
                                                i
                                        }"
                                    >
                                        <div class="fcol-2 vertical-align">
                                            <span
                                                *ngIf="
                                                    formValues
                                                        .warningsAndBlocks[i]
                                                        .status === 'BLOCK'
                                                "
                                                class="fa fa-times-circle"
                                                style="padding: 10px"
                                                tooltip="blocked"
                                                placement="top"
                                            ></span>
                                            <div
                                                *ngIf="
                                                    formValues
                                                        .warningsAndBlocks[i]
                                                        .status !== 'BLOCK'
                                                "
                                                class="checkbox"
                                                style="margin-left: 8px"
                                            >
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        formControlName="acknowledged"
                                                        [readonly]="working"
                                                        (change)="
                                                            expandWarningsAndBlockersIndex =
                                                                airspaceAuthorisationForm
                                                                    .controls
                                                                    .warningsAndBlocks
                                                                    .value[i]
                                                                    .acknowledged &&
                                                                expandWarningsAndBlockersIndex ===
                                                                    i
                                                                    ? i + 1
                                                                    : expandWarningsAndBlockersIndex
                                                        "
                                                    />
                                                    <span class="cr"
                                                        ><i
                                                            class="cr-icon glyphicon glyphicon-ok"
                                                        ></i
                                                    ></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="fcol-9">
                                            <h5>{{ item.value.name }}</h5>
                                        </div>
                                        <div class="fcol-1">
                                            <button
                                                type="button"
                                                class="btn btn-tertiary"
                                                (click)="
                                                    expandWarningsAndBlockersIndex =
                                                        expandWarningsAndBlockersIndex ===
                                                        i
                                                            ? -1
                                                            : i
                                                "
                                            >
                                                <span
                                                    class="fal"
                                                    [ngClass]="{
                                                        'fa-chevron-down':
                                                            expandWarningsAndBlockersIndex !==
                                                            i,
                                                        'fa-chevron-up':
                                                            expandWarningsAndBlockersIndex ===
                                                            i
                                                    }"
                                                ></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    [collapse]="
                                        expandWarningsAndBlockersIndex !== i
                                    "
                                    [isAnimated]="true"
                                >
                                    <div class="horizontal-container">
                                        <div
                                            class="fcol-8 active-warning-acknowledgement acknowledgement-bottom"
                                        >
                                            <p
                                                class="help-text"
                                                [innerHTML]="item.value.message"
                                            ></p>
                                            <span
                                                *ngIf="
                                                    item.value
                                                        .relatedMissionIdList &&
                                                    item.value
                                                        .relatedMissionIdList
                                                        .length > 0
                                                "
                                                class="left-buffer"
                                            >
                                                <a
                                                    [popover]="
                                                        responseMissionListTemplate
                                                    "
                                                    [popoverContext]="{
                                                        relatedMissionIdList:
                                                            item.value
                                                                .relatedMissionIdList,
                                                        popover: popover
                                                    }"
                                                    [outsideClick]="true"
                                                    #popover="bs-popover"
                                                    placement="bottom"
                                                >
                                                    Related Missions&nbsp;<span
                                                        class="fal fa-chevron-down"
                                                    ></span>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                visibleFormFields.includes('declarations') &&
                                formValues.declarations &&
                                formValues.declarations.length
                            "
                            formArrayName="declarations"
                        >
                            <h4>Airspace Authorisation Declarations</h4>
                            <div
                                *ngFor="
                                    let declaration of airspaceAuthorisationForm
                                        .controls.declarations.controls;
                                    let i = index
                                "
                                [formGroupName]="i"
                                class=""
                            >
                                <div
                                    class="horizontal-container vertical-align"
                                >
                                    <div class="fcol-1">
                                        <div
                                            class="checkbox"
                                            style="margin-left: 8px"
                                        >
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    formControlName="acknowledged"
                                                    [readonly]="working"
                                                />
                                                <span class="cr"
                                                    ><i
                                                        class="cr-icon glyphicon glyphicon-ok"
                                                    ></i
                                                ></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="fcol-7">
                                        {{ declaration.value.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- TODO: View existing auth can be its own component. -->
                    <div
                        *ngIf="
                            authorisations != null &&
                            authorisations.length > 0 &&
                            !requestNew &&
                            (airspaceAuthorisationType === 'USA_LAANC' ||
                                airspaceAuthorisationStatus === 'APPROVED' ||
                                airspaceAuthorisationStatus === 'COMPLETED' ||
                                airspaceAuthorisationStatus === 'CLOSED' ||
                                airspaceAuthorisationStatus === 'PENDING' ||
                                airspaceAuthorisationStatus === 'PROVISIONAL' ||
                                airspaceAuthorisationStatus ===
                                    'RESCINDED_AWAITING' ||
                                airspaceAuthorisationStatus === 'DECLARED')
                        "
                        class="fcol-6"
                    >
                        <div
                            class="vertical-container contents container-scroll modal-scrollbar"
                        >
                            <ng-container
                                *ngFor="
                                    let authorisation of authorisations;
                                    let i = index
                                "
                            >
                                <h4
                                    class="bottom-buffer"
                                    *ngIf="
                                        authorisation.conditionList.length > 0
                                    "
                                >
                                    Authorisation Conditions
                                </h4>
                                <div>
                                    <p
                                        *ngFor="
                                            let condition of authorisation.conditionList
                                        "
                                        [innerHtml]="condition"
                                    ></p>
                                </div>
                                <h4 class="bottom-buffer">
                                    Authorisation Details
                                </h4>
                                <div
                                    *ngIf="
                                        visibleFormFields.includes('messages')
                                    "
                                    class="horizontal-container bottom-buffer"
                                >
                                    <div class="fcol-12">
                                        <span class="display-label">
                                            Messages
                                        </span>
                                        <div class="horizontal-container fill">
                                            <airspace-authorisation-messaging
                                                [messages]="
                                                    authorisation.messageList
                                                "
                                            ></airspace-authorisation-messaging>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="authorisation?.errors != null"
                                    class="horizontal-container bottom-buffer"
                                >
                                    <div class="fcol-12">
                                        <span class="display-label">
                                            Authorisation Errors
                                        </span>
                                        <div class="horizontal-container fill">
                                            <show-errors
                                                [errors]="authorisation.errors"
                                            ></show-errors>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        authorisation?.activeGridCellList
                                            ?.length > 0
                                    "
                                    class="horizontal-container bottom-buffer"
                                >
                                    <div class="fcol-12">
                                        <span class="display-label">
                                            Grid Cell Definition (GCD)
                                            <button
                                                type="button"
                                                class="btn btn-tertiary pull-right"
                                                tooltip="{{
                                                    collapseGcdTable
                                                        ? 'Show GCD value table'
                                                        : 'Hide GCD value table'
                                                }}"
                                                placement="top"
                                                (click)="
                                                    collapseGcdTable =
                                                        !collapseGcdTable
                                                "
                                            >
                                                <span
                                                    *ngIf="!collapseGcdTable"
                                                    class="fal fa-chevron-up"
                                                ></span>
                                                <span
                                                    *ngIf="collapseGcdTable"
                                                    class="fal fa-chevron-down"
                                                ></span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        authorisation?.activeGridCellList
                                            ?.length > 0
                                    "
                                    class="horizontal-container bottom-buffer"
                                >
                                    <div
                                        class="fcol-6"
                                        style="margin-top: -5px"
                                    >
                                        <div
                                            style="width: 100%"
                                            [collapse]="collapseGcdTable"
                                            [isAnimated]="true"
                                        >
                                            <table style="width: 100%">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 70%">
                                                            Cell ID
                                                        </th>
                                                        <th style="width: 30%">
                                                            Max Height
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="
                                                            let cell of authorisation.activeGridCellList
                                                        "
                                                        class="data"
                                                    >
                                                        <td style="width: 50%">
                                                            {{ cell.cellId }}
                                                        </td>
                                                        <td style="width: 50%">
                                                            <ng-container
                                                                *ngIf="
                                                                    cell.ceiling
                                                                "
                                                            >
                                                                {{
                                                                    cell.ceiling
                                                                        ?.value
                                                                }}&nbsp;
                                                                <span
                                                                    *ngIf="
                                                                        !isFeet(
                                                                            cell
                                                                                .ceiling
                                                                                .unit
                                                                        )
                                                                    "
                                                                    style="
                                                                        text-transform: lowercase;
                                                                    "
                                                                >
                                                                    {{
                                                                        cell
                                                                            .ceiling
                                                                            .unit
                                                                    }}
                                                                </span>
                                                                <span
                                                                    *ngIf="
                                                                        isFeet(
                                                                            cell
                                                                                .ceiling
                                                                                .unit
                                                                        )
                                                                    "
                                                                >
                                                                    ft AGL
                                                                </span>
                                                            </ng-container>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            [collapse]="!collapseGcdTable"
                                            [isAnimated]="true"
                                        >
                                            Max Authorised Height:
                                            {{
                                                authorisation.maximumHeight
                                            }}&nbsp;ft AGL
                                        </div>
                                    </div>
                                    <div
                                        class="fcol-5 airspace-result-container"
                                        style="justify-content: center"
                                    >
                                        <div
                                            [collapse]="collapseGcdTable"
                                            [isAnimated]="true"
                                        >
                                            Max Authorised Height:&nbsp;{{
                                                authorisation.maximumHeight
                                            }}ft AGL
                                            <div
                                                *ngIf="
                                                    authorisation.maximumHeight ==
                                                    0
                                                "
                                                class="airspace-result-parameter fail"
                                                style="
                                                    text-transform: capitalize;
                                                "
                                            >
                                                GCD does not permit flying
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div
                            [collapse]="collapseGcdTable"
                            [isAnimated]="true"
                        >
                            <span class="display-label">
                                Max Authorised Height
                            </span>
                            <div class="horizontal-container fill">
                                <div>{{ authorisationResult.maximumHeight }}&nbsp;ft AGL</div>
                            </div>
                        </div> -->
                                <div>
                                    <h4 class="section-heading">
                                        <span
                                            >Authorisation
                                            {{
                                                authorisations.length > 1
                                                    ? i + 1
                                                    : ''
                                            }}</span
                                        >
                                        <div></div>
                                        <!-- TODO: the locking states for this button needs work -->
                                        <button
                                            *ngIf="
                                                authorisation.status ==
                                                    'INVALID' ||
                                                (!isInPast &&
                                                    authorisation.status !=
                                                        'CANCELLED' &&
                                                    authorisation.status !=
                                                        'REJECTED' &&
                                                    authorisation.status !=
                                                        'RESCINDED' &&
                                                    authorisation.status !=
                                                        'RESCINDED_AWAITING' &&
                                                    authorisation.status !=
                                                        'COMPLETED')
                                            "
                                            type="button"
                                            class="btn btn-danger btn-xs"
                                            [disabled]="working"
                                            (click)="
                                                cancelAuthorisation(
                                                    authorisation
                                                )
                                            "
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            *ngIf="
                                                isInPast &&
                                                authorisation.status !=
                                                    'CANCELLED' &&
                                                authorisation.status !=
                                                    'REJECTED' &&
                                                authorisation.status !=
                                                    'RESCINDED' &&
                                                authorisation.status !=
                                                    'RESCINDED_AWAITING' &&
                                                authorisation.status !=
                                                    'INVALID' &&
                                                authorisation.status !=
                                                    'COMPLETED'
                                            "
                                            type="button"
                                            class="btn btn-danger btn-xs"
                                            [disabled]="working"
                                            (click)="
                                                closeAuthorisation(
                                                    authorisation
                                                )
                                            "
                                        >
                                            Close
                                        </button>
                                        <!-- FIXME: get a better way to determine when this button and the LAANC-specific details should show -->
                                        <button
                                            *ngIf="
                                                airspaceAuthorisationType ===
                                                    'USA_LAANC' &&
                                                (authorisation.status ==
                                                    'CANCELLED' ||
                                                    authorisation.status ==
                                                        'REJECTED' ||
                                                    authorisation.status ==
                                                        'RESCINDED' ||
                                                    authorisation.status ==
                                                        'COMPLETED')
                                            "
                                            type="button"
                                            class="btn btn-danger btn-xs"
                                            [disabled]="working"
                                            (click)="
                                                deleteAuthorisation(
                                                    authorisation
                                                )
                                            "
                                        >
                                            Delete
                                        </button>
                                    </h4>
                                    <div class="bottom-buffer">
                                        <span class="display-label"> ID </span>
                                        <div class="horizontal-container fill">
                                            {{ authorisation.id }}
                                        </div>
                                    </div>
                                    <!-- <h5>{{ authorisation.authorisationId }}</h5> -->
                                    <div class="bottom-buffer">
                                        <span class="display-label">
                                            Status
                                        </span>
                                        <div
                                            class="horizontal-container fill"
                                            [ngClass]="{
                                                'space-between':
                                                    authorisation.status ==
                                                    'RESCINDED_AWAITING'
                                            }"
                                        >
                                            <div
                                                class="authorisation-status"
                                                [ngClass]="{
                                                    'authorisation-pass':
                                                        authorisation.status ==
                                                            'APPROVED' ||
                                                        authorisation.status ==
                                                            'DECLARED',
                                                    'authorisation-pending':
                                                        authorisation.status ==
                                                        'PENDING',
                                                    'authorisation-fail':
                                                        authorisation.status ==
                                                            'REJECTED' ||
                                                        authorisation.status ==
                                                            'CANCELLED' ||
                                                        authorisation.status ==
                                                            'RESCINDED' ||
                                                        authorisation.status ==
                                                            'RESCINDED_AWAITING',
                                                    'authorisation-closed':
                                                        authorisation.status ==
                                                        'COMPLETED'
                                                }"
                                            >
                                                {{
                                                    authorisation.status
                                                        | formatAuthorisationStatus
                                                }}
                                            </div>
                                            <button
                                                *ngIf="
                                                    authorisation.status ==
                                                    'RESCINDED_AWAITING'
                                                "
                                                type="button"
                                                class="btn btn-xs btn-pill btn-primary"
                                                [disabled]="working"
                                                (click)="
                                                    acknowledgeRescinded(
                                                        authorisation
                                                    )
                                                "
                                            >
                                                Acknowledge
                                            </button>
                                        </div>
                                        <div
                                            *ngIf="
                                                authorisation.status ==
                                                    'RESCINDED_AWAITING' &&
                                                authorisation.status ==
                                                    'PENDING' &&
                                                authorisation.authorisationEligibility ===
                                                    'MANUAL'
                                            "
                                            class="horizontal-container fill top-buffer"
                                        >
                                            <div
                                                class="alert alert-warning"
                                                style="
                                                    padding: 6px;
                                                    font-size: larger;
                                                    line-height: normal;
                                                "
                                            >
                                                <p>
                                                    This authorization is
                                                    pending further
                                                    coordination. This is a
                                                    manual approval process with
                                                    commensurate timelines. The
                                                    FAA's goal is to respond
                                                    with a decision regarding
                                                    this authorization within 30
                                                    days, but in some cases a
                                                    decision may take up to 90
                                                    days.
                                                </p>
                                                <br />
                                                <p>
                                                    <strong
                                                        >Please note:</strong
                                                    >
                                                    While this authorization is
                                                    pending please do not follow
                                                    up with Air Traffic by phone
                                                    as this may result in the
                                                    request being denied by the
                                                    FAA.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="
                                                airspaceAuthorisationType ===
                                                    'USA_LAANC' &&
                                                authorisation.status ==
                                                    'REJECTED' &&
                                                authorisation.authorisationEligibility ===
                                                    'MANUAL'
                                            "
                                            class="horizontal-container fill top-buffer"
                                        >
                                            <div
                                                class="alert alert-warning"
                                                style="
                                                    padding: 6px;
                                                    font-size: larger;
                                                    line-height: normal;
                                                "
                                            >
                                                <p>
                                                    Your request for Further
                                                    Coordination was denied by
                                                    the controlling facility.
                                                    The operation was denied for
                                                    reasons that may include any
                                                    of the following: altitude,
                                                    date, time, or duration of
                                                    the requested operation.
                                                    Re-submitting the same
                                                    request through LAANC will
                                                    result in a denial.
                                                </p>
                                                <br />
                                                <p>
                                                    To continue pursuit of an
                                                    authorization for the
                                                    operation, consider altering
                                                    part, or all, of the request
                                                    and re-submitting either
                                                    through LAANC or through FAA
                                                    DroneZone. Re-submission
                                                    through LAANC and/or FAA
                                                    DroneZone does not guarantee
                                                    the operation request will
                                                    be approved.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="
                                                airspaceAuthorisationType ===
                                                    'USA_LAANC' &&
                                                authorisation.status ==
                                                    'INVALID'
                                            "
                                            class="horizontal-container fill top-buffer"
                                        >
                                            <div
                                                class="alert alert-danger"
                                                style="
                                                    padding: 6px;
                                                    font-size: larger;
                                                    line-height: normal;
                                                "
                                            >
                                                <p>
                                                    This authorization is no
                                                    longer valid and must be
                                                    cancelled by you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="authorisation.rejectionReason"
                                        class="bottom-buffer"
                                    >
                                        <span class="display-label">
                                            Reason
                                        </span>
                                        <div class="horizontal-container fill">
                                            <div>
                                                {{
                                                    authorisation.rejectionReason
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="
                                            authorisation.controllingAuthority
                                        "
                                        class="bottom-buffer"
                                    >
                                        <span class="display-label">
                                            Controlling Authority
                                        </span>
                                        <div class="horizontal-container fill">
                                            <div>
                                                {{
                                                    authorisation.controllingAuthority
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-buffer">
                                        <span class="display-label">
                                            Start Time
                                        </span>
                                        <div class="horizontal-container fill">
                                            <div>
                                                {{
                                                    authorisation.startTime
                                                        | formatLocalDateTimeWithUTC
                                                            : formValues.timeZone
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-buffer">
                                        <span class="display-label">
                                            End Time
                                        </span>
                                        <div class="horizontal-container fill">
                                            <div>
                                                {{
                                                    authorisation.endTime
                                                        | formatLocalDateTimeWithUTC
                                                            : formValues.timeZone
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container
                                        *ngIf="authorisation.authorisationText"
                                    >
                                        <span class="display-label">
                                            Conditions
                                        </span>
                                        <div class="horizontal-container fill">
                                            <p
                                                [innerHTML]="
                                                    authorisation.authorisationText
                                                "
                                            ></p>
                                        </div>
                                    </ng-container>
                                </div>
                                <h4 class="top-buffer bottom-buffer">
                                    Mission Details
                                </h4>
                                <div
                                    *ngIf="
                                        visibleFormFields.includes(
                                            'publicMissionName'
                                        ) && authorisation?.publicMissionName
                                    "
                                    class="bottom-buffer"
                                >
                                    <span class="display-label">
                                        Public Mission Name
                                    </span>
                                    <div class="horizontal-container fill">
                                        <div>
                                            {{
                                                authorisation.publicMissionName
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        formValues.casaFlightProfile &&
                                        visibleFormFields.includes(
                                            'flightProfile'
                                        )
                                    "
                                    class="bottom-buffer"
                                >
                                    <span class="display-label">
                                        Planned Flight Profile
                                    </span>
                                    <div class="horizontal-container fill">
                                        <div>
                                            {{ flightProfileName }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="mission.maximumHeight"
                                    class="bottom-buffer"
                                >
                                    <span class="display-label">
                                        Planned Max Height
                                    </span>
                                    <div class="horizontal-container fill">
                                        <div>
                                            {{ mission.maximumHeight }}&nbsp;ft
                                            AGL
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        formValues.casaFlightProfile &&
                                        visibleFormFields.includes(
                                            'flightProfile'
                                        )
                                    "
                                    class="bottom-buffer"
                                >
                                    <span class="display-label">
                                        Planned Flight Profile
                                    </span>
                                    <div class="horizontal-container fill">
                                        <div>
                                            {{ flightProfileName }}
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-buffer">
                                    <span class="display-label">
                                        Start Time
                                    </span>
                                    <div class="horizontal-container fill">
                                        <div>
                                            {{
                                                authorisation.startTime
                                                    | formatLocalDateTimeWithUTC
                                                        : formValues.timeZone
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-buffer">
                                    <span class="display-label">
                                        End Time
                                    </span>
                                    <div class="horizontal-container fill">
                                        <div>
                                            {{
                                                authorisation.endTime
                                                    | formatLocalDateTimeWithUTC
                                                        : formValues.timeZone
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-buffer">
                                    <span class="display-label">
                                        Remote Pilot
                                    </span>
                                    <div
                                        *ngIf="formValues.rpic != null"
                                        class="horizontal-container fill"
                                    >
                                        <span class="right-buffer"
                                            >{{
                                                formValues.rpic.firstName
                                            }}&nbsp;{{
                                                formValues.rpic.lastName
                                            }}</span
                                        >
                                        <span class="right-buffer"
                                            ><span
                                                ><label>{{
                                                    formValues.rpicAuthLabel
                                                }}</label></span
                                            >&nbsp;{{
                                                formValues.rpicArn
                                            }}</span
                                        >
                                        <span class="right-buffer"
                                            ><span><label>Ph:</label></span
                                            >&nbsp;{{
                                                formValues.rpicPhone
                                            }}</span
                                        >
                                    </div>
                                </div>
                                <div
                                    *ngIf="visibleFormFields.includes('crp')"
                                    class="bottom-buffer"
                                >
                                    <span class="display-label">
                                        Chief Remote Pilot
                                    </span>
                                    <div *ngIf="formValues.crp == null">
                                        <span>No CRP details available</span>
                                    </div>
                                    <div
                                        class="horizontal-container"
                                        *ngIf="formValues.crp != null"
                                    >
                                        <span class="right-buffer"
                                            >{{
                                                formValues.crp.firstName
                                            }}&nbsp;{{
                                                formValues.crp.lastName
                                            }}</span
                                        >
                                        <span class="right-buffer"
                                            ><span
                                                ><label>{{
                                                    formValues.crpAuthLabel
                                                }}</label></span
                                            >&nbsp;{{ formValues.crpArn }}</span
                                        >
                                    </div>
                                </div>
                                <div class="bottom-buffer">
                                    <span class="display-label"> RPA </span>
                                    <ng-container
                                        *ngFor="let rpa of formValues.rpaList"
                                    >
                                        <div class="horizontal-container">
                                            <span class="right-buffer">{{
                                                rpa.nickname
                                            }}</span>
                                            <span
                                                ><span
                                                    ><label
                                                        >Serial:</label
                                                    ></span
                                                >&nbsp;{{
                                                    rpa.manufacturerSerialNumber
                                                }}</span
                                            >
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="
                                        visibleFormFields.includes(
                                            'delegatedAuthority'
                                        ) && formValues.delegatedAuthority
                                    "
                                    class="bottom-buffer"
                                >
                                    <span class="display-label">
                                        {{
                                            formValues.delegatedAuthority
                                                ?.authorityType?.abbreviation
                                        }}
                                    </span>
                                    <div
                                        class="vertical-container"
                                        *ngIf="
                                            formValues.delegatedAuthority !=
                                            null
                                        "
                                    >
                                        <div
                                            *ngIf="
                                                formValues.delegatedAuthority
                                                    .authorityType
                                                    ?.identifierLabel ||
                                                formValues.delegatedAuthority
                                                    .identifier
                                            "
                                        >
                                            {{
                                                formValues.delegatedAuthority
                                                    .authorityType
                                                    ?.identifierLabel
                                                    ? formValues
                                                          .delegatedAuthority
                                                          .authorityType
                                                          ?.identifierLabel
                                                    : 'Certificate'
                                            }}:&nbsp;{{
                                                formValues.delegatedAuthority
                                                    .identifier
                                            }}
                                        </div>
                                        <div>
                                            {{
                                                formValues.delegatedAuthority
                                                    .authorityType?.name
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="
                                            formValues.delegatedAuthority ==
                                            null
                                        "
                                    >
                                        No
                                        {{
                                            formValues.delegatedAuthority
                                                .authorityType?.abbreviation
                                        }}
                                        Attached to this mission
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <flyfreely-map
                        #map
                        airspaceAuthorisations
                        class="fill"
                        style="height: calc(100vh - 300px)"
                        [organisationId]="organisation.id"
                        [features]="features$ | async"
                        [startTime]="checkCommand?.startTime"
                        [endTime]="checkCommand?.endTime"
                        [ruleset]="delegatedAuthority?.authorityType?.ruleset"
                        [showFeatureSelection]="false"
                        [requireCtrlToZoom]="true"
                        [mapSourceFilters]="mapSourceFilters"
                        [enabledLayerGroups]="enabledMapLayerGroups"
                        (fullscreenRequested)="onFullscreenRequested()"
                        (featuresLoaded)="map.zoomToAllFeatures(true)"
                    ></flyfreely-map>
                </div>
            </div>
        </form>

        <airspace-unavailable-alert
            [show]="operationFailed"
        ></airspace-unavailable-alert>
        <airspace-unavailable-alert
            [show]="authorisationServiceUnavailable"
            customStatusMessage="LAANC is not currently available."
            [customStatusText]="authorisationUnavailableMessage"
        ></airspace-unavailable-alert>
    </div>
    <div
        class="modal-footer"
        *ngIf="
            (authorisations == null ||
                authorisations.length == 0 ||
                requestNew) &&
            airspaceAuthorisationForm &&
            airspaceAuthorisationForm.valid &&
            errorMessage == null &&
            !formValues.hasRemainingValidationErrors &&
            (additionalInformationForm == null ||
                additionalInformationForm.valid) &&
            authorisationPreview != null &&
            !working
        "
    >
        <!-- <div
        class="modal-footer"
        *ngIf="
            !authorisationPreview?.failedValues?.length &&
            validRequest &&
            (additionalInformationForm == null ||
                additionalInformationForm.valid) &&
            authorisationPreview != null &&
            !working &&
            !operationFailed
        "
    > -->
        <button
            type="button"
            class="btn btn-primary"
            (click)="requestAuthorisation()"
            [disabled]="
                working ||
                !airspaceAuthorisationForm.valid ||
                errorMessage != null ||
                mission.isDummy ||
                formValues.hasRemainingValidationErrors
            "
        >
            Request
            {{
                authorisationPreview?.authorisationType
                    | formatAuthorisationType
            }}
            Airspace Authorisation
        </button>
    </div>
    <div
        class="modal-footer"
        *ngIf="
            (authorisations == null ||
                authorisations.length == 0 ||
                requestNew) &&
            airspaceAuthorisationForm &&
            (!airspaceAuthorisationForm.valid ||
                errorMessage != null ||
                formValues.hasRemainingValidationErrors) &&
            authorisationPreview != null
        "
    >
        <!-- <div
        class="modal-footer"
        *ngIf="
            airspaceAuthorisationForm &&
            (!airspaceAuthorisationForm.valid ||
                formValues.hasRemainingValidationErrors) &&
            authorisationPreview != null
        "
    > -->
        <!-- <div
        class="modal-footer"
        *ngIf="
            (authorisationPreview?.failedValues?.length || !validRequest) &&
            authorisationPreview != null &&
            !operationFailed
        "
    > -->
        <div
            *ngIf="!working"
            class="alert alert-danger footer-alert pull-left"
            role="alert"
        >
            <div
                *ngIf="
                    airspaceAuthorisationForm.valid &&
                    (errorMessage != null ||
                        formValues.hasRemainingValidationErrors)
                "
                class="alert alert-danger footer-alert pull-left"
                role="alert"
            >
                <p>
                    <span class="fa fa-times right-buffer"></span> The Automated
                    Authorisation Request is invalid: {{ errorMessage }}
                </p>
            </div>
            <div
                *ngIf="!airspaceAuthorisationForm.valid"
                class="alert alert-danger footer-alert pull-left"
                role="alert"
            >
                <p>
                    <span class="fa fa-times right-buffer"></span> The Automated
                    Authorisation Request is incomplete and cannot be submitted
                    {{
                        formValues.hasRemainingValidationErrors ||
                        errorMessage != null
                            ? ': ' + errorMessage
                            : '.'
                    }}
                </p>
            </div>

            <!-- <p
                *ngIf="
                    airspaceAuthorisationForm.valid && formValues.hasRemainingValidationErrors
                "
            >
                <span class="fa fa-times right-buffer"></span> The Automated
                Authorisation Request is invalid and cannot be submitted{{
                    !authorisationPreview?.validRequest &&
                    !authorisationPreview?.operatingArea &&
                    selectedFlightArea
                        ? ': Selected flight area is outside of authorisation zone'
                        : ''
                }}
            </p> -->
        </div>
        <button
            *ngIf="canEditMission"
            type="button"
            class="btn btn-default"
            (click)="showMissionEditDialogue()"
            [disabled]="working || editStart || editEnd"
        >
            Edit Mission
        </button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="close()"
            [disabled]="working || editStart || editEnd"
        >
            Close
        </button>
    </div>
    <div
        class="modal-footer"
        *ngIf="
            airspaceAuthorisationForm &&
            authorisationPreview == null &&
            (authorisations == null || authorisations.length == 0)
        "
    >
        <!-- <div
        class="modal-footer"
        *ngIf="
            !authorisationPreview?.failedValues?.length &&
            !validRequest &&
            authorisationPreview == null &&
            authorisation == null &&
            !operationFailed
        "
    > -->
        <p *ngIf="!working">
            <span class="fa fa-times right-buffer"></span> Automated
            Authorisation Request could not be generated
        </p>

        <button
            *ngIf="canEditMission"
            type="button"
            class="btn btn-default"
            (click)="showMissionEditDialogue()"
            [disabled]="working || editStart || editEnd"
        >
            Edit Mission
        </button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="close()"
            [disabled]="working || editStart || editEnd"
        >
            Close
        </button>
    </div>
    <div
        class="modal-footer"
        *ngIf="
            (authorisations != null &&
                authorisations.length > 0 &&
                airspaceAuthorisationType === 'USA_LAANC' &&
                !requestNew) ||
            ((airspaceAuthorisationStatus === 'APPROVED' ||
                airspaceAuthorisationStatus === 'PENDING' ||
                airspaceAuthorisationStatus === 'PROVISIONAL' ||
                airspaceAuthorisationStatus === 'RESCINDED_AWAITING' ||
                airspaceAuthorisationStatus === 'DECLARED') &&
                !operationFailed &&
                !authorisationServiceUnavailable)
        "
    >
        <!-- TODO: This delete button should delete the entire operation -->
        <!-- <button
            *ngIf="authorisation?.authorisationType === 'USA_LAANC'"
            type="button"
            class="btn btn-danger pull-left"
            (click)="deleteAuthorisation()"
            [disabled]="working"
        >
            Delete
        </button> -->
        <button
            type="button"
            class="btn btn-primary"
            (click)="close()"
            [disabled]="working"
        >
            Acknowledge
        </button>
        <!-- FIXME: we need a better system of managing the different requirements for different authorisation authorities -->
        <div
            *ngIf="
                !working &&
                airspaceAuthorisationType === 'AUS_CASA_AUTHORISATION'
            "
            class="alert alert-success footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-check right-buffer"></span> CASA Automated
                Authorisation Instrument Created ({{ approvedId }})
            </p>
        </div>
        <div
            *ngIf="
                (!working &&
                    airspaceAuthorisationType === 'NZL_AIRSHARE' &&
                    airspaceAuthorisationStatus === 'APPROVED') ||
                airspaceAuthorisationStatus === 'DECLARED'
            "
            class="alert alert-success footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-check right-buffer"></span> AirShare
                Authorisation Request Approved ({{ approvedId }})
            </p>
        </div>
        <div
            *ngIf="
                !working &&
                airspaceAuthorisationType === 'NZL_AIRSHARE' &&
                airspaceAuthorisationStatus === 'PENDING'
            "
            class="alert alert-success footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-check right-buffer"></span> AirShare
                Authorisation Request Pending Decision ({{ approvedId }})
            </p>
        </div>
        <div
            *ngIf="
                !working &&
                airspaceAuthorisationType === 'NZL_AIRSHARE' &&
                airspaceAuthorisationStatus === 'PROVISIONAL'
            "
            class="alert alert-success footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-check right-buffer"></span> AirShare
                Authorisation Request Approved Provisionally ({{ approvedId }})
            </p>
        </div>
    </div>
    <div
        class="modal-footer"
        *ngIf="
            authorisations != null &&
            airspaceAuthorisationType !== 'USA_LAANC' &&
            (airspaceAuthorisationStatus === 'COMPLETED' ||
                airspaceAuthorisationStatus === 'CLOSED') &&
            !operationFailed &&
            !authorisationServiceUnavailable &&
            !requestNew
        "
    >
        <div
            *ngIf="
                !working &&
                airspaceAuthorisationType === 'AUS_CASA_AUTHORISATION'
            "
            class="alert alert-success footer-alert pull-left"
            role="alert"
        >
            <p>
                CASA Automated Authorisation Instrument {{ approvedId }} Closed
            </p>
        </div>
        <div
            *ngIf="!working && airspaceAuthorisationType === 'NZL_AIRSHARE'"
            class="alert alert-success footer-alert pull-left"
            role="alert"
        >
            <p>Airshare Authorisation Request {{ approvedId }} Closed</p>
        </div>
        <button
            type="button"
            class="btn btn-primary"
            (click)="close()"
            [disabled]="working"
        >
            OK
        </button>
    </div>
    <div
        class="modal-footer"
        *ngIf="
            authorisations != null &&
            airspaceAuthorisationType !== 'USA_LAANC' &&
            airspaceAuthorisationStatus === 'CANCELLED' &&
            !operationFailed &&
            !authorisationServiceUnavailable &&
            !requestNew
        "
    >
        <div
            *ngIf="
                !working &&
                airspaceAuthorisationType === 'AUS_CASA_AUTHORISATION'
            "
            class="alert alert-danger footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-times right-buffer"></span> Automated
                Authorisation Request Cancelled
                {{ approvedId ? '(' + approvedId + ')' : '' }}
            </p>
        </div>
        <div
            *ngIf="!working && airspaceAuthorisationType === 'NZL_AIRSHARE'"
            class="alert alert-danger footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-times right-buffer"></span> Authorisation
                Request Cancelled {{ approvedId ? '(' + approvedId + ')' : '' }}
            </p>
        </div>
    </div>
    <div
        class="modal-footer"
        *ngIf="
            authorisations != null &&
            airspaceAuthorisationType !== 'USA_LAANC' &&
            airspaceAuthorisationStatus === 'REJECTED' &&
            !requestNew
        "
    >
        <div
            *ngIf="
                !working &&
                airspaceAuthorisationType === 'AUS_CASA_AUTHORISATION'
            "
            class="alert alert-danger footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-times right-buffer"></span> Automated
                Authorisation Request Rejected
                {{ approvedId ? '(' + approvedId + ')' : '' }}
            </p>
        </div>
        <div
            *ngIf="!working && airspaceAuthorisationType === 'NZL_AIRSHARE'"
            class="alert alert-danger footer-alert pull-left"
            role="alert"
        >
            <p>
                <span class="fa fa-times right-buffer"></span> Authorisation
                Request Rejected {{ approvedId ? '(' + approvedId + ')' : '' }}
            </p>
        </div>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</ng-container>

<ng-template
    #responseMissionListTemplate
    let-relatedMissionIdList="relatedMissionIdList"
    let-popover="popover"
>
    <div *ngFor="let missionId of relatedMissionIdList">
        <a (click)="goToRelatedMission(missionId)">{{
            relatedMissionLookup[missionId] != null
                ? relatedMissionLookup[missionId].name
                : 'Loading...'
        }}</a>
    </div>
</ng-template>
