import { Component, Input } from '@angular/core';
import {
    DisplayableMissionDto,
    FlightConformanceResultDto,
    FlightConformanceResultsService,
    FlightConformanceRuleResultDto,
    flightConformanceStatusLookup,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UserService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

interface DisplayableConformanceResult extends FlightConformanceResultDto {
    flightNumber: number;
    pilotName: string;
    parsedStatus: string;
}

@Component({
    selector: 'flight-conformance-details',
    templateUrl: './flight-conformance-details.component.html',
    styleUrls: ['../styles.scss']
})
export class FlightConformanceDetails {
    @Input() mission: DisplayableMissionDto;
    @Input() organisationId: number;

    conformanceResults = new BehaviorSubject<DisplayableConformanceResult[]>(
        null
    );
    currentUserId: number;

    ruleStatus = FlightConformanceRuleResultDto.Status;

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private flightConformanceResultsService: FlightConformanceResultsService,
        private commonDialoguesService: CommonDialoguesService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.flightConformanceResultsService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshConformance());

        this.refreshConformance();

        this.currentUserId = this.userService.getCurrentUser().id;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshConformance() {
        this.flightConformanceResultsService
            .find(this.mission.id, this.organisationId)
            .pipe(
                map(results =>
                    results.filter(
                        r =>
                            r.status !==
                            FlightConformanceResultDto.Status.CANCELLED
                    )
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(results => {
                const tempConformanceResults = results
                    .map(result => {
                        const flightNumber = this.mission.sorties.find(
                            s => s.id === result.flightId
                        )?.number;
                        const pilot = this.mission.missionCrewDetails.find(
                            c => c.person.id === result.pilotId
                        );
                        const pilotName =
                            pilot == null
                                ? 'Unlisted Pilot'
                                : `${pilot.person.firstName} ${pilot.person.lastName}`;
                        return {
                            ...result,
                            flightNumber: flightNumber,
                            pilotName: pilotName,
                            parsedStatus:
                                flightConformanceStatusLookup[result.status]
                        };
                    })
                    .sort((a, b) => a.flightNumber - b.flightNumber);

                this.conformanceResults.next(tempConformanceResults);
            })
            .add(this.workTracker.createTracker());
    }

    acknowledgeBreach(conformanceRule: FlightConformanceRuleResultDto) {
        const saveMessage = (message: string) =>
            firstValueFrom(
                this.flightConformanceResultsService
                    .acknowledgeRuleResult(conformanceRule.id, {
                        acknowledgementMessage: message
                    })
                    .pipe(
                        takeUntil(this.ngUnsubscribe$),
                        tap({
                            next: result => {
                                this.logging.success(
                                    `Flight conformance result acknowledged`
                                );
                                const tempConformanceResults =
                                    this.conformanceResults
                                        .getValue()
                                        .map(c => ({
                                            ...c,
                                            ruleResultList:
                                                c.ruleResultList.map(
                                                    (rule: any) =>
                                                        rule.id === result.id
                                                            ? result
                                                            : rule
                                                )
                                        }));

                                this.conformanceResults.next(
                                    tempConformanceResults
                                );
                            },
                            error: (error: FlyFreelyError) =>
                                this.logging.error(
                                    error,
                                    `Error acknowledging flight conformance result: ${error.message}`
                                )
                        })
                    )
            );

        this.commonDialoguesService.showFormlyDialogue(
            'Acknowledge Breach',
            'Acknowledge',
            true,
            true,
            [
                {
                    key: 'message',
                    type: 'textarea',
                    props: {
                        required: true,
                        minLength: 10,
                        description:
                            'A description of what happened. Minimum length of 10 chars.',
                        label: 'Remote Pilot Acknowledgement Message'
                    }
                }
            ],
            { message: null },
            data => saveMessage(data.message)
        );
    }

    finalise(result: FlightConformanceResultDto) {
        this.flightConformanceResultsService
            .finaliseResult(result.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: val => {
                    this.logging.success(
                        `Flight conformance result finalised (${val.status})`
                    );
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,

                        `Error finalising flight conformance result: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());
        this.refreshConformance();
    }

    unfinalise(result: FlightConformanceResultDto) {
        this.flightConformanceResultsService
            .unfinaliseResult(result.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: val => {
                    this.logging.success(
                        `Flight conformance result unfinalised (${val.status})`
                    );
                },
                error: (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error unfinalising flight conformance result: ${error.message}`
                    )
            })
            .add(this.workTracker.createTracker());

        this.refreshConformance();
    }

    cancel(conformanceRule: FlightConformanceResultDto) {
        this.commonDialoguesService.showConfirmationDialogue(
            'Cancel Conformance Result',
            `Are you sure you want to cancel this result? This can not be undone.`,
            'Cancel',
            () =>
                firstValueFrom(
                    this.flightConformanceResultsService
                        .cancelResult(conformanceRule.id)
                        .pipe(
                            takeUntil(this.ngUnsubscribe$),
                            tap({
                                next: result =>
                                    this.logging.success(
                                        `Flight conformance result cancelled`
                                    ),
                                error: (error: FlyFreelyError) =>
                                    this.logging.error(
                                        error,
                                        `Error cancelling conformance result: ${error.message}`
                                    )
                            })
                        )
                ).finally(this.workTracker.createTracker())
        );
    }
}
