<div class="main-content" [ngSwitch]="state">
    <div>
        <ng-container *ngSwitchCase="'JOINING'"
            ><ngx-loading
                [show]="true"
                [config]="{
                    backdropBackgroundColour: 'rgba(0,125,213,0.8)',
                    primaryColour: '#005fa2',
                    secondaryColour: '#005fa2',
                    tertiaryColour: '#005fa2'
                }"
            ></ngx-loading>
            </ng-container
        >
        <ng-container *ngSwitchCase="'SUCCESS'"></ng-container>
        <ng-container *ngSwitchCase="'FAILURE'"></ng-container>
    </div>
</div>
