/**
 * An interaction is an ongoing action on the map.
 * The current interaction state of the @see FlyFreelyMapComponent
 * This way a component can keep track off which interaction is currently active.
 * Interactions can be started by e.g. clicking a measurement button or by selecting something.
 */

export enum MapInteractionState {
    MeasurementLine,
    MeasurementRadius,
    Select
}
