<div class="modal-header no-icon">
    <h3 class="modal-title">
        {{ rpaTypeId == null ? 'Add ' : 'Edit ' }}RPA Type
    </h3>
    <button type="button" (click)="cancel()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="rpaTypeForm">
    <div class="modal-body">
        <tabset>
            <tab heading="Basic Information">
                <formly-form
                    [fields]="basicInformationFields"
                    [model]="rpaType"
                    [options]="options"
                    [form]="rpaTypeForm.controls.basicInformation"
                ></formly-form>
            </tab>
            <tab heading="Physical Specifications">
                <formly-form
                    [model]="physicalSpecifications"
                    [fields]="physicalSpecificationsFields"
                    [options]="options"
                    [form]="rpaTypeForm.controls.physicalSpecifications"
                ></formly-form>
            </tab>
            <tab heading="Performance Specifications">
                <formly-form
                    [model]="performanceSpecifications"
                    [fields]="performanceSpecificationsFields"
                    [options]="options"
                    [form]="rpaTypeForm.controls.performanceSpecifications"
                ></formly-form>
            </tab>
            <tab heading="Other Specifications">
                <formly-form
                    [model]="otherSpecifications"
                    [fields]="otherSpecificationsFields"
                    [options]="options"
                    [form]="rpaTypeForm.controls.otherSpecifications"
                ></formly-form>
            </tab>
            <tab *ngIf="rpaTypeId != null" heading="Manuals">
                <attachment-list
                    [attachmentsHandler]="attachmentsHandler"
                    [allowUploads]="!isLocked"
                    [allowEdit]="!isLocked"
                    permissions
                >
                </attachment-list>
            </tab>

            <!-- <tab *ngIf="rpaTypeId != null && rpaType != null" heading="RPA Schematic">
                <resource-type-edit 
                    [resourceTypeId]="rpaTypeId" 
                    [resourceCategory]="'CRAFT'"
                    [currentSchematicId]="rpaType.schematicId"
                    [resourceName]="rpaName"
                >
                </resource-type-edit>
            </tab> -->

            <!-- <tab *ngIf="rpaTypeId != null" heading="Associated Battery Types">
                <div class="top-buffer" *ngIf="availableBatteries != null">
                    <label>Add Associated Battery Types</label>
                    <div class="input-group">
                        <ng-select
                            [items]="availableBatteries"
                            [clearable]="false"
                            [(ngModel)]="currentBattery"
                            [ngModelOptions]="{ standalone: true }"
                            bindLabel="model"
                            bindValue="id"
                            appendTo="body"
                            placeholder="Select Battery Type"
                            [disabled]="!availableBatteries || working"
                        >
                        </ng-select>
                        <span class="input-group-btn">
                            <button
                                type="button"
                                class="btn btn-primary pull-right"
                                (click)="addBattery()"
                            >
                                Add
                            </button>
                        </span>
                    </div>
                </div>
                <div class="top-buffer">
                    <static-table
                        [availableColumns]="availableColumns"
                        [selectedColumns]="selectedColumns"
                        [tableData]="associatedBatteries"
                        [tableConfig]="tableConfig"
                    >
                    </static-table>
                </div>
            </tab> -->
        </tabset>
    </div>
    <div class="modal-footer">
        <button
            *ngIf="!isLocked"
            (click)="save()"
            class="btn btn-primary submit-button"
            [disabled]="rpaTypeForm.invalid || working"
        >
            Save
        </button>
        <button
            *ngIf="isLocked"
            (click)="cancel()"
            class="btn btn-default"
            [disabled]="working"
        >
            Close
        </button>
    </div>
</form>
