import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
    OnboardingData,
    OnboardingTab
} from '../onboarding-screens-data.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'onboarding-screens',
    templateUrl: './onboarding-screens.component.html',
    styleUrls: ['./onboarding-screens.component.scss']
})
export class OnboardingScreensComponent implements OnInit {
    private ngUnsubscribe$: Subject<void> = new Subject();
    public currentTab: OnboardingTab;

    constructor(private obds: OnboardingData) {}

    ngOnInit() {
        this.obds.currentScreenAndTab$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(({ tab }) => (this.currentTab = tab));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
