import { Injectable } from '@angular/core';
import {
    CreateLocationCommand,
    LocationFeatureDto,
    LocationService,
    MissionService,
    OrganisationService,
    WorkTracker,
    defaultTimezone
} from '@flyfreely-portal-ui/flyfreely';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AirspaceCheckerParametersWithStartEnd } from './location-edit-v2/airspace-checker/location-airspace-check.component';
import moment from 'moment';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';

@Injectable()
export class LocationEditService {
    private workingSource = new ReplaySubject<boolean>(1);
    private missionCreatedSource = new Subject<void>();

    working$ = this.workingSource.asObservable();
    missionCreated$ = this.missionCreatedSource.asObservable();

    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private locationService: LocationService,
        private organisationService: OrganisationService,
        private missionService: MissionService,
        private missionDialoguesService: MissionDialoguesService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => this.workingSource.next(working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.workingSource.complete();
        this.missionCreatedSource.complete();
    }

    createMission(
        organisationId: number,
        airspaceValue: AirspaceCheckerParametersWithStartEnd,
        createCommand: CreateLocationCommand
    ) {
        const tz = airspaceValue.airspaceTimeZone ?? defaultTimezone;

        const t = moment(
            moment(airspaceValue.airspaceDate ?? new Date()).format(
                'YYYY-MM-DD[T]HH:mm:ss'
            )
        ).tz(tz);
        const utc = t.toISOString();

        combineLatest([
            this.locationService.createLocation(createCommand),
            this.organisationService.findByIdForUser(
                organisationId,
                organisationId
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([location, organisation]) => {
                const mission = this.missionService.newMission(organisation);
                mission.location = location;
                mission.name = createCommand.name;
                mission.missionDate = utc;
                mission.timeZone = airspaceValue.airspaceTimeZone;
                mission.missionEstimatedTime = airspaceValue.airspaceDuration;
                mission.maximumHeight = airspaceValue.airspaceHeight;
                mission.timeZone = location.timeZone;
                mission.lockedFields = {
                    aircraft: false,
                    isDummy: false,
                    missionCrew: false,
                    missionType: false,
                    missionWorkflowVersion: false,
                    timeZone: false
                };
                this.missionDialoguesService.showMissionEditor(
                    mission,
                    organisation
                );
                this.missionCreatedSource.next();
            })
            .add(this.workTracker.createTracker());
    }
}
