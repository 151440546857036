<div [ngClass]="{ 'inline-indicator': inline }">
    <span
        *ngIf="disabled"
        class="completion-indicator incomplete-optional"
        [ngClass]="{
            'glyphicon glyphicon-ok-circle': !inline,
            'fal fa-check-circle': inline
        }"
        [tooltip]="'This ' + noun + ' is disabled'"
        placement="{{ placement }}"
        container="body"
    ></span>
    <ng-container *ngIf="!disabled">
        <span
            *ngIf="!valid && required"
            class="completion-indicator incomplete-required"
            [ngClass]="{
                'glyphicon glyphicon-ok-circle': !inline,
                'fal fa-check-circle': inline
            }"
            [tooltip]="'An incomplete compulsory ' + noun"
            placement="{{ placement }}"
            container="body"
        ></span>
        <span
            *ngIf="valid && required"
            class="completion-indicator complete-required"
            [ngClass]="{
                'glyphicon glyphicon-ok-circle': !inline,
                'fal fa-check-circle': inline
            }"
            [tooltip]="'A completed compulsory ' + noun"
            placement="{{ placement }}"
            container="body"
        ></span>

        <span
            *ngIf="!required && hasValue === false"
            class="completion-indicator incomplete-optional"
            [ngClass]="{
                'glyphicon glyphicon-ok-circle': !inline,
                'fal fa-check-circle': inline
            }"
            [tooltip]="'An empty optional ' + noun"
            placement="{{ placement }}"
            container="body"
        ></span>
        <span
            *ngIf="valid && !required && hasValue !== false"
            class="completion-indicator complete-optional"
            [ngClass]="{
                'glyphicon glyphicon-ok-circle': !inline,
                'fal fa-check-circle': inline
            }"
            [tooltip]="'A completed optional ' + noun"
            placement="{{ placement }}"
            container="body"
        ></span>
        <span
            *ngIf="!valid && !required && hasValue !== false"
            class="completion-indicator incomplete-required"
            [ngClass]="{
                'glyphicon glyphicon-ok-circle': !inline,
                'fal fa-check-circle': inline
            }"
            [tooltip]="'An invalid optional ' + noun"
            placement="{{ placement }}"
            container="body"
        ></span>
    </ng-container>
</div>
