<ng-container *ngIf="canRender" screenAnalytics="battery-add">
    <div class="modal-header">
        <div class="panel-heading-icon">
            <img imgPath="/icons/icon-batteries.png" />
        </div>
        <h3 class="modal-title">Add Battery</h3>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            (click)="close()"
            aria-label="Close"
            class="close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form
        [formGroup]="batteryForm"
        novalidate
        [formIdentifier]="validationFields"
        (ngSubmit)="save(false)"
    >
        <div class="modal-body">
            <enhanced-help
                screenName="battery-edit"
                componentName="screen"
                [compact]="true"
            >
            </enhanced-help>
            <div class="form-group top-buffer">
                <label>Battery Type</label>
                <field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="batteryTypeId"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <ng-select
                    *ngIf="batteryTypeItems.length > 0"
                    [items]="batteryTypeItems"
                    bindLabel="name"
                    bindValue="id"
                    formControlName="batteryTypeId"
                >
                </ng-select>
            </div>
            <enhanced-help
                screenName="battery-edit"
                componentName="batteryTypeId"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <label>Name/Designation</label>
                <field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="name"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input class="form-control" formControlName="name" />
            </div>
            <enhanced-help
                screenName="battery-edit"
                componentName="name"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <label>Purchase Date</label>
                <field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="purchaseDate"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        class="form-control"
                        #datepicker="bsDatepicker"
                        bsDatepicker
                        formControlName="purchaseDate"
                    />
                    <span
                        class="input-group-addon btn-default"
                        (click)="datepicker.toggle()"
                        [attr.aria-expanded]="datepicker.isOpen"
                    >
                        <i class="glyphicon glyphicon-calendar"></i>
                    </span>
                </div>
            </div>
            <enhanced-help
                screenName="battery-edit"
                componentName="purchaseDate"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <div>
                    <label>Flight Battery Set </label>
                    <field-validation-indicator
                        validatorFormControlName="makeBatterySet"
                        [noun]="'field'"
                        [placement]="'left'"
                        class="pull-right"
                    ></field-validation-indicator>
                </div>
                <div>
                    <div
                        class="input-group"
                        btnRadioGroup
                        formControlName="makeBatterySet"
                        style="padding-top: 5px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="false"
                            [disabled]="working"
                            >Add this battery to a battery set later</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="true"
                            [disabled]="working"
                            >Create a battery set with just this battery
                            now</label
                        >
                    </div>
                    <validation-messages
                        validatorFormControlName="makeBatterySet"
                    >
                    </validation-messages>
                </div>
            </div>
            <enhanced-help
                screenName="battery-edit"
                componentName="makeBatterySet"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <label>Estimated Historical Flight Time</label>
                <field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="initialFlightTime"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <duration
                    formControlName="initialFlightTime"
                    [showHour]="true"
                    [showMinute]="true"
                    [showSecond]="true"
                    [clearable]="false"
                >
                </duration>
            </div>
            <enhanced-help
                screenName="battery-edit"
                componentName="initialFlightTime"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <label>Initial Cycles</label>
                <field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="initialCycles"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <div class="input-group">
                    <input
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"
                        formControlName="initialCycles"
                    />
                    <span class="input-group-addon">cycles</span>
                </div>
            </div>
            <enhanced-help
                screenName="battery-edit"
                componentName="initialCycles"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <label>Manufacturer Serial Number </label
                ><field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="manufacturerSerialNumber"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input
                    class="form-control"
                    formControlName="manufacturerSerialNumber"
                />

                <p class="help-block" *ngIf="batterySerialNumber">
                    The serial number from the {{ serialNumberOrigin }}.
                </p>
            </div>
            <enhanced-help
                screenName="battery-edit"
                componentName="manufacturerSerialNumber"
                [compact]="true"
            >
            </enhanced-help>

            <div *ngIf="canUseNfc" class="form-group top-buffer">
                <label>NFC UID </label
                ><field-validation-indicator
                    class="pull-right"
                    validatorFormControlName="nfcUid"
                    [noun]="'field'"
                    [placement]="'left'"
                ></field-validation-indicator>
                <input class="form-control" formControlName="nfcUid" />
            </div>
            <enhanced-help
                *ngIf="canUseNfc"
                screenName="battery-edit"
                componentName="nfcUid"
                [compact]="true"
            >
            </enhanced-help>

            <div class="form-group top-buffer">
                <div>
                    <label
                        >Battery Classification
                        <field-validation-indicator
                            validatorFormControlName="isDummy"
                            [noun]="'field'"
                            [placement]="'left'"
                            class="pull-right"
                        ></field-validation-indicator>
                    </label>
                </div>
                <div>
                    <div
                        class="input-group"
                        btnRadioGroup
                        formControlName="isDummy"
                        style="padding-top: 5px"
                    >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="false"
                            [disabled]="working"
                            >Real Battery</label
                        >
                        <label
                            class="btn btn-xs btn-pill"
                            [btnRadio]="true"
                            [disabled]="working"
                            >Dummy Battery</label
                        >
                    </div>
                    <validation-messages [control]="isDummy">
                    </validation-messages>
                </div>
            </div>

            <enhanced-help
                screenName="battery-edit"
                componentName="isDummy"
                [compact]="true"
            >
            </enhanced-help>
        </div>
    </form>
    <div class="modal-footer">
        <button
            class="btn btn-default pull-left"
            type="button"
            [disabled]="working"
            (click)="bulkUpload()"
        >
            Bulk Upload
        </button>
        <button
            class="btn btn-default"
            type="button"
            (click)="save(true)"
            [disabled]="batteryForm?.invalid || working"
        >
            Add Another
        </button>
        <button
            class="btn btn-primary"
            type="button"
            [disabled]="batteryForm?.invalid || working"
            (click)="save(false)"
        >
            OK
        </button>
    </div>
</ng-container>
