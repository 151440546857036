import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    ITreeOptions,
    TreeComponent,
    TreeNode
} from '@circlon/angular-tree-component';
import { NamedLayer } from '@flyfreely-portal-ui/flyfreely';
import {
    FeatureAndGroup,
    FeatureGroup,
    LayerSection,
    SelectedFeatures
} from '../interfaces';

enum FeatureListType {
    LAYERS = 'LAYERS',
    MARKERS = 'MARKERS'
}

interface LayerNodeChild {
    id: string;
    name: string;
    groupedLayers: NamedLayer[];
    isSelected: boolean;
}

/**
 * This component displays 2 lists of map features: layers and markers
 *
 * It supports two kinds of interaction:
 * 1. Selecting a layer or marker, which can be used to highlight a feature on the map
 * 2. Enabling a layer or marker, which can be used to hide/show a feature on the map
 *
 *
 * Because we need consistent ways of communicating between the child components when referencing these
 * layers and features we sometime generate internal IDs, to ensure that the correct feature it targeted.
 *
 * Feature IDs are computed as a composite of the feature group ID and the feature ID.
 */
@Component({
    selector: 'map-feature-selector',
    templateUrl: './map-feature-selector.component.html',
    styleUrls: ['./map-feature-selector.component.scss']
})
export class MapFeatureSelectorComponent {
    featureList: FeatureListType = FeatureListType.LAYERS;
    currentFeatureList: FeatureListType = FeatureListType.LAYERS;
    isCollapsed = true;

    @Input() featuresTitle: string;
    @Input() features: FeatureGroup[];
    @Input() selectedFeatures: SelectedFeatures;

    @Input() layers: LayerSection[] = [];
    @Input() selectedLayerIds: string[];

    @Output() onActivateFeature = new EventEmitter<FeatureAndGroup>();
    @Output() onShowFeature = new EventEmitter<FeatureAndGroup>();
    @Output() onHideFeature = new EventEmitter<FeatureAndGroup>();

    @Output() onActivateLayer = new EventEmitter<string>();
    @Output() onShowLayer = new EventEmitter<string>();
    @Output() onHideLayer = new EventEmitter<string>();

    isOpen = true;
    options: ITreeOptions = {
        // actionMapping: this.actionMapping,
        useCheckbox: true
    };

    @ViewChild('featureTree', { static: true }) featureTree: TreeComponent;
    @ViewChild('layerTree', { static: true }) layerTree: TreeComponent;

    // These are any because the underlying library uses any
    layerNodes: any[] = [];
    featureNodes: any[] = [];

    ngOnChanges(changes: SimpleChanges) {
        if ('layers' in changes) {
            this.setLayerNodes();
        }
        if ('layers' in changes || 'selectedLayers' in changes) {
            this.refreshSelectedLayers();
        }

        if ('features' in changes) {
            if (this.features == null) {
                this.featureNodes = [];
            } else {
                this.featureNodes = this.features.map(group => {
                    return {
                        name: group.name,
                        hasChildren: true,
                        isExpanded: true,
                        children: group.existingFeatures.map(f => ({
                            id: f.id,
                            name: f.name,
                            featureAndGroup: { feature: f, groupId: group.id },
                            hasChildren: false,
                            properties: f.properties
                        })) as any
                    };
                });
            }
        }
        if ('features' in changes || 'selectedFeatures' in changes) {
            this.refreshSelectedFeatures();
        }
    }

    private setLayerNodes() {
        this.layerNodes = this.layers.map(ls => {
            const children: LayerNodeChild[] = ls.layers.map(l => ({
                id: l.id,
                name: l.name,
                groupedLayers: [l],
                isSelected: this.selectedLayerIds.indexOf(l.id) !== -1
            }));
            ls.layerGroups.forEach(lg => {
                children.push({
                    id: lg.id,
                    name: lg.name,
                    groupedLayers: lg.layers,
                    isSelected: lg.layers.some(
                        l => this.selectedLayerIds.indexOf(l.id) !== -1
                    )
                });
            });
            return {
                name: ls.name,
                isExpanded: true,
                hasChildren: false,
                children
            };
        });
    }

    private refreshSelectedFeatures() {
        if (this.features == null || this.selectedFeatures == null) {
            return;
        }
        const selectedLeafNodeIds = this.features.reduce(
            (acc, g) =>
                g.existingFeatures.reduce(
                    (fAcc, f) => ({
                        ...fAcc,
                        [f.id]:
                            g.id in this.selectedFeatures &&
                            this.selectedFeatures[g.id].has(f.id)
                    }),
                    acc
                ),
            {}
        );
        this.featureTree.state = {
            ...this.featureTree.treeModel.getState(),
            selectedLeafNodeIds
        };
    }

    private refreshSelectedLayers() {
        if (this.layers == null || this.selectedLayerIds == null) {
            return;
        }

        const selectedLeafNodeIds: { [key: string]: any } = {};
        this.layers.forEach(ls => {
            ls.layerGroups.forEach(lg => {
                selectedLeafNodeIds[lg.id] = lg.layers.some(layer =>
                    this.selectedLayerIds.some(id => layer.id === id)
                );
            });
            ls.layers.forEach(layer => {
                selectedLeafNodeIds[layer.id] = this.selectedLayerIds.some(
                    id => layer.id === id
                );
            });
        });
        this.layerTree.state = {
            ...this.layerTree.treeModel.getState(),
            selectedLeafNodeIds
        };
    }

    showLayerFilter() {
        this.isCollapsed = false;
    }

    toggleLayerFilter() {
        this.isCollapsed = !this.isCollapsed;
    }

    manageLayerFilter() {
        if (this.isCollapsed) {
            this.isCollapsed = false;
            this.currentFeatureList = this.featureList;
        } else if (this.currentFeatureList === this.featureList) {
            this.isCollapsed = true;
        } else {
            this.currentFeatureList = this.featureList;
        }
    }

    activateTree(event: any) {
        if (
            this.featureList === FeatureListType.MARKERS &&
            event.node.data.featureAndGroup != null
        ) {
            this.onActivateFeature.next(event.node.data.featureAndGroup);
        }
    }

    focusTree(event: any) {
        // do-nothing
    }

    blurTree(event: any) {
        // do-nothing
    }

    clickTree(event: any) {
        // do-nothing
    }

    onSelect(event: { node: TreeNode }) {
        if (this.featureList === FeatureListType.MARKERS) {
            this.onShowFeature.next(event.node.data.featureAndGroup);
        } else {
            const data = event.node.data as LayerNodeChild;
            const layers = data!.groupedLayers;
            layers.forEach(layer => {
                this.onShowLayer.next(layer.id);
            });
        }
    }

    onDeselect(event: { node: TreeNode }) {
        if (this.featureList === FeatureListType.MARKERS) {
            this.onHideFeature.next(event.node.data.featureAndGroup);
        } else {
            const data = event.node.data as LayerNodeChild;
            const layers = data!.groupedLayers;
            layers.forEach(layer => {
                this.onHideLayer.next(layer.id);
            });
        }
    }

    onUpdateTree(event: any) {
        // do-nothing
    }
}
