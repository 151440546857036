import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'formly-inline-field-wrapper',
    template: `
        <div class="form-group" [class.has-error]="showError">
            <label *ngIf="to.label && to.hideLabel !== true" [attr.for]="id">
                {{ to.label }}
            </label>
            <div class="horizontal-container">
                <div style="flex: 1;">
                    <ng-template #fieldComponent></ng-template>
                </div>
                <field-validation-indicator
                    [control]="formControl"
                    [placement]="'left'"
                    [inline]="true"
                ></field-validation-indicator>
            </div>
            <div
                *ngIf="showError"
                class="invalid-feedback"
                [style.display]="'block'"
            >
                <validation-messages
                    [control]="formControl"
                ></validation-messages>
            </div>
            <p *ngIf="to.description" class="help-block">
                {{ to.description }}
            </p>
        </div>
    `
})
export class FormlyWrapperInlineFormField extends FieldWrapper {
    @ViewChild('fieldComponent', { read: ViewContainerRef, static: true })
    fieldComponent!: ViewContainerRef;
}
