<div>
    <button
        type="button"
        routerLink="/missions"
        routerLinkActive="active"
        queryParamsHandling="merge"
    >
        <span
            class="far fa-plane fa-rotate-by"
            style="--fa-rotate-angle: -45deg"
        ></span>
        Missions
    </button>
    <button
        type="button"
        routerLink="/home"
        routerLinkActive="active"
        queryParamsHandling="merge"
    >
        <span class="far fa-people-roof"></span> Manage
    </button>
    <button
        *ngIf="hasDynamicReporting$ | async"
        type="button"
        routerLink="/reporting"
        routerLinkActive="active"
        queryParamsHandling="merge"
    >
        <span
            class="far fa-objects-column fa-rotate-by"
            style="--fa-rotate-angle: 90deg"
        ></span>
        Reporting
    </button>
    <button
        *ngIf="hasOutstandingTasts$ | async"
        type="button"
        routerLink="/tasks"
        routerLinkActive="active"
        queryParamsHandling="merge"
    >
        <span class="far fa-list-check"></span>
        Tasks
    </button>
    <button
        *ngIf="!(isDemoOrganisation$ | async)"
        type="button"
        showEnhancedHelp
        screenName="workspace"
        [componentName]="helpComponentIdentifier$ | async"
        tooltip
        placement="bottom"
    >
        <span class="far fa-question-circle"></span>
    </button>
    <button
        *ngIf="isDemoOrganisation$ | async"
        type="button"
        class="btn-question-mark"
        [class.show-explore-btn]="(!isInPreference$ | async) || !hideExploreBtn"
        showEnhancedHelp
        screenName="workspace"
        [componentName]="helpComponentIdentifier$ | async"
        [isDemoHelp]="!hideExploreBtn"
        colorFadeOut
        [fadeTrigger]="(isInPreference$ | async) || modalClose"
        tooltip
        placement="left"
        (modalClosed)="onModalClosed()"
        (click)="updatePreferences()"
    >
        <span class="far fa-question-circle"></span>
    </button>
    <button
        *ngIf="canAdminOrganisation$ | async"
        type="button"
        routerLink="orgadmin"
        queryParamsHandling="merge"
        tooltip="Organisation Admin"
        placement="bottom"
    >
        <span class="far fa-gear"></span>
    </button>
</div>
<button
    *ngIf="isDemoOrganisation$ | async"
    class="btn btn-start-free-trail"
    (click)="goToOnboarding()"
>
    Start Free Trial
</button>
