/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.47-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InitialComponentDto } from './initialComponentDto';
import { LockedBatteryFields } from './lockedBatteryFields';
import { BatteryAvailableActions } from './batteryAvailableActions';


/**
 * The details of the battery
 */
export interface BatteryDto { 
    /**
     * The ID of the battery in the system
     */
    id?: number;
    /**
     * The name or designation of the battery
     */
    name: string;
    /**
     * The UID of the NFC tag on the battery
     */
    nfcUid?: string;
    /**
     * When was the battery purchased
     */
    purchaseDate?: string;
    notes?: string;
    /**
     * The serial number printed on the battery
     */
    manufacturerSerialNumber?: string;
    /**
     * The serial number returned by smart batteries
     */
    internalSerialNumber?: string;
    /**
     * The ID of the battery type
     */
    batteryTypeId: number;
    /**
     * The organisation that owns the battery
     */
    organisationId: number;
    /**
     * The current status of the battery
     */
    status: BatteryDto.Status;
    /**
     * ${swagger.Battery.disposalDate}
     */
    disposalDate?: string;
    /**
     * The make of the battery type
     */
    batteryTypeMake?: string;
    /**
     * The model of the battery type
     */
    batteryTypeModel?: string;
    /**
     * Is this a dummy battery
     */
    isDummy: boolean;
    /**
     * The initial usage of the battery in seconds
     */
    initialFlightTime: number;
    /**
     * The initial number of cycles the battery had
     */
    initialCycles: number;
    /**
     * The timestamp when the entity was first stored
     */
    creationTime?: string;
    /**
     * The timestamp when the entity was last stored
     */
    modificationTime?: string;
    availableActions: BatteryAvailableActions;
    lockedFields: LockedBatteryFields;
    initialComponentList?: Array<InitialComponentDto>;
}
export namespace BatteryDto {
    export type Status = 'SERVICEABLE' | 'UNSERVICEABLE' | 'UNDER_MAINTENANCE' | 'RETIRED' | 'DISPOSED' | 'DELETED';
    export const Status = {
        SERVICEABLE: 'SERVICEABLE' as Status,
        UNSERVICEABLE: 'UNSERVICEABLE' as Status,
        UNDER_MAINTENANCE: 'UNDER_MAINTENANCE' as Status,
        RETIRED: 'RETIRED' as Status,
        DISPOSED: 'DISPOSED' as Status,
        DELETED: 'DELETED' as Status
    };
}



