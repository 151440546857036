import { Component, OnDestroy, OnInit } from '@angular/core';
import { AirworthinessNoticesDialogueService } from '../airworthness-notices.service';
import {
    MaintenanceLogSummary,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { Observable, Subject } from 'rxjs';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { takeUntil } from 'rxjs/operators';
import {
    FormatDatePipe,
    FormatMaintenanceEquipmentCategoryPipe,
    FormatMaintenanceLogTypePipe,
    FormatMaintenanceStatusPipe
} from '@flyfreely-portal-ui/ui';
import { MaintenanceDialogues } from '../../maintenance-dialogues.service';

@Component({
    selector: 'notices-maintenance-request-list',
    templateUrl: './notices-maintenance-request-list.component.html'
})
export class NoticesMaintenanceRequestList implements OnInit, OnDestroy {
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;
    tableSearch: any;
    selectedCardId: number | null;
    organizationId: number;

    public working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    maintenanceLogs$: Observable<MaintenanceLogSummary[]>;
    maintenanceLogs: MaintenanceLogSummary[];

    constructor(
        private AirworthinessNoticesDialogueService: AirworthinessNoticesDialogueService,
        formatDatePipe: FormatDatePipe,
        maintenanceStatusPipe: FormatMaintenanceStatusPipe,
        maintenanceEquipmentTypePipe: FormatMaintenanceEquipmentCategoryPipe,
        maintenanceLogTypePipe: FormatMaintenanceLogTypePipe,
        private maintenanceDialogues: MaintenanceDialogues
    ) {
        this.availableColumns = [
            {
                value: 'resource.name',
                name: 'Name',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                linkFunction: (maintenanceLog: any) =>
                    this.updateMaintenanceRequest(maintenanceLog.id)
            },
            {
                value: 'resourceCategory',
                name: 'Category',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: et =>
                    maintenanceEquipmentTypePipe.transform(et)
            },
            {
                value: 'maintenanceLogType',
                name: 'Type',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: t => maintenanceLogTypePipe.transform(t)
            },
            {
                value: 'resource.initialComponentList.serialNumber',
                name: 'Serial Number',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'requestTime',
                name: 'Due Date',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: d => formatDatePipe.transform(d)
            },
            {
                value: 'assignee',
                name: 'Assignee',
                searchable: false,
                selectable: false,
                defaultSelection: true
            },
            {
                value: 'status',
                name: 'Status',
                searchable: false,
                selectable: false,
                defaultSelection: true,
                formatterFunction: s => maintenanceStatusPipe.transform(s)
            }
        ];
        this.selectedColumns = null;
        this.columnSorting = null;
        this.tableSearch = null;
        this.tableConfig = {
            limit: 10,
            actions: [],
            limitSelection: [10, 25, 50, 100]
        };
    }
    ngOnInit(): void {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
        this.AirworthinessNoticesDialogueService.getSelectedCardId().subscribe(
            cardId => (this.selectedCardId = cardId)
        );
        this.AirworthinessNoticesDialogueService.organisationId$.subscribe(
            id => (this.organizationId = id)
        );
        console.log('this is organizationId:', this.organizationId);
        this.maintenanceLogs$ =
            this.AirworthinessNoticesDialogueService.maintenanceNoticeLog$;
        this.maintenanceLogs$.subscribe().add(this.workTracker.createTracker());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    updateMaintenanceRequest(maintenanceLogId: number) {
        this.maintenanceDialogues.showMaintenanceDetails(
            this.organizationId,
            maintenanceLogId
        );
    }
}
