import { Component, Input } from '@angular/core';
import {
    AuthorityConditionDto,
    AuthorityRegisterConditionsDto,
    AuthorityTypeDto,
    toLookup
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    RegisterSideSheetService,
    UiCheck
} from '../side-sheet/register-side-sheet.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'register-conditions',
    templateUrl: './register-conditions.component.html',
    styles: [
        `
            .name.disabled {
                text-decoration: line-through;
            }
        `
    ],
    host: {
        class: 'vertical-container fill'
    },
    animations: [collapseOnLeaveAnimation(), expandOnEnterAnimation()]
})
export class RegisterConditions {
    @Input() mode: 'AUTHORITY' | 'MANUAL';
    authorityChecks: UiCheck[];
    manualChecks: UiCheck[];
    allCandidates: AuthorityTypeDto[];
    candidates: AuthorityTypeDto[];

    selectedAuthorityType: AuthorityTypeDto;
    newManualCheckName: string;
    newManualCheckDescription: string;

    manualCheckName: string;
    manualCheckDescription: string;
    manualCheckEnabled: boolean;

    isAddingAction = false;
    isEditingAction: number;

    private ngUnsubscribe$ = new Subject<void>();

    working: boolean = false;

    constructor(private registerSideSheetService: RegisterSideSheetService) {}

    ngOnInit() {
        this.registerSideSheetService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        this.refresh();
    }

    // dropAuthorityChecks(event: CdkDragDrop<any[]>) {
    //     moveItemInArray(this.authorityChecks, event.previousIndex, event.currentIndex);
    // }

    // // This function handles the drag-drop event for manual checks
    // dropManualChecks(event: CdkDragDrop<any[]>) {
    //     moveItemInArray(this.manualChecks, event.previousIndex, event.currentIndex);
    // }

    dropAuthorityChecks(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.authorityChecks, event.previousIndex, event.currentIndex);
        this.saveUpdatedAuthorityChecks(); // Call save function after reordering
    }
    
    dropManualChecks(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.manualChecks, event.previousIndex, event.currentIndex);
        this.saveUpdatedManualChecks(); // Call save function after reordering
    }

    private saveUpdatedAuthorityChecks() {
        this.registerSideSheetService.updateAuthorityChecks(this.authorityChecks);
        // You can also show a success message or log the update
    }
    
    private saveUpdatedManualChecks() {
        this.registerSideSheetService.updateManualChecks(this.manualChecks);
        // You can also show a success message or log the update
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refresh() {
        combineLatest([
            this.registerSideSheetService.candidates$,
            this.registerSideSheetService.conditions$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([candidates, conditions]) => {
                this.allCandidates = candidates;
                this.updateChecks(conditions);
            });
    }

    private updateChecks(conditions: AuthorityRegisterConditionsDto) {
        const lookup = this.allCandidates.reduce(toLookup, {});

        this.authorityChecks = conditions.conditions
            .filter(c => c.id > 0 && c.conditionType === 'INTERNAL_LOOKUP')
            .map(c => ({
                id: c.id,
                entityId: c.entityId,
                name: c.name,
                inCandidates: c.entityId in lookup,
                conditionType: c.conditionType,
                enabled: c.enabled
            }));

        this.manualChecks = conditions.conditions
            .filter(c => c.id > 0 && c.conditionType === 'ACTION')
            .map(c => ({
                id: c.id,
                entityId: c.entityId,
                name: c.name,
                inCandidates: null,
                conditionType: c.conditionType,
                description: c.description,
                enabled: c.enabled
            }));
        this.registerSideSheetService.updateAuthorityChecks(
            this.authorityChecks
        );
        this.registerSideSheetService.updateManualChecks(this.manualChecks);

        this.refreshCandidates();
    }

    addAuthorityTypeCheck() {
        this.authorityChecks = this.authorityChecks.concat({
            entityId: this.selectedAuthorityType.id,
            inCandidates: true,
            name: this.selectedAuthorityType.name,
            conditionType: AuthorityConditionDto.ConditionType.INTERNAL_LOOKUP,
            enabled: true
        });
        this.registerSideSheetService.updateAuthorityChecks(
            this.authorityChecks
        );

        this.refreshCandidates();

        this.selectedAuthorityType = null;
    }

    addManualCheck() {
        if (this.manualChecks == null) {
            this.manualChecks = [];
        }
        this.manualChecks = this.manualChecks.concat({
            entityId: null,
            inCandidates: false,
            name: this.newManualCheckName,
            // FIXME: this requires an actual description field
            description: this.newManualCheckDescription,
            conditionType: AuthorityConditionDto.ConditionType.ACTION,
            enabled: true
        });

        this.newManualCheckName = null;
        this.newManualCheckDescription = null;
        this.isAddingAction = false;
        this.registerSideSheetService.updateManualChecks(this.manualChecks);
    }

    updateManualCheck(action: UiCheck, index: number) {
        this.manualChecks[index].name = this.manualCheckName;
        this.manualChecks[index].description = this.manualCheckDescription;
        this.manualChecks[index].enabled = this.manualCheckEnabled;
        this.registerSideSheetService.updateManualChecks(this.manualChecks);

        this.stopEditingManualCheck();
    }

    editManualCheck(action: UiCheck, index: number) {
        this.manualCheckName = action.name;
        this.manualCheckDescription = action.description;
        this.manualCheckEnabled = action.enabled;
        this.isEditingAction = index;
    }

    stopEditingManualCheck() {
        this.manualCheckName = null;
        this.manualCheckDescription = null;
        this.manualCheckEnabled = null;
        this.isEditingAction = null;
    }

    removeCheck(event: Event, check: UiCheck) {
        this.authorityChecks = this.authorityChecks.filter(a => a !== check);
        this.registerSideSheetService.updateAuthorityChecks(
            this.authorityChecks
        );

        this.refreshCandidates();

        event.stopPropagation();
    }

    removeAction(event: Event, action: UiCheck) {
        this.manualChecks = this.manualChecks.filter(a => a !== action);
        this.registerSideSheetService.updateManualChecks(this.manualChecks);

        event.stopPropagation();
    }

    private refreshCandidates() {
        const inUse = this.authorityChecks.map(c => c.entityId);
        this.candidates = this.allCandidates.filter(
            c => inUse.indexOf(c.id) === -1
        );
    }

    issuers(item: AuthorityTypeDto) {
        return item.issuedBy?.name ?? '';
    }
}
