/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.18.60-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An invite object Database object
 */
export interface InviteDto {
    /**
     * The unique ID for this instance
     */
    id: number;
    /**
     * The email address of the invite recipient\'s email address
     */
    recipientEmail?: string;
    /**
     * The user ID of the invite recipient
     */
    recipientId?: number;
    /**
     * The ID of the organisation that sent this invite
     */
    organisationId: number;
    /**
     * The name of the organisation that sent this invite
     */
    organisationName: string;
    /**
     * The status of the invite
     */
    status: InviteDto.Status;
    /**
     * The ID of the person who sent the invite
     */
    senderId: number;
    /**
     * The roles to give the invitee
     */
    roles: Array<InviteDto.Roles>;
    /**
     * Does this invite consume a licence
     */
    requiresLicence: boolean;
}
export namespace InviteDto {
    export type Status =
        | 'PENDING'
        | 'RETRACTED'
        | 'ACCEPTING'
        | 'ACCEPTED'
        | 'IGNORED';
    export const Status = {
        PENDING: 'PENDING' as Status,
        RETRACTED: 'RETRACTED' as Status,
        ACCEPTING: 'ACCEPTING' as Status,
        ACCEPTED: 'ACCEPTED' as Status,
        IGNORED: 'IGNORED' as Status
    };
    export type Roles =
        | 'PLANNER'
        | 'RCP'
        | 'ADMIN'
        | 'BILLING_ADMIN'
        | 'PILOT'
        | 'PILOT_PLANNER'
        | 'PILOT_SUBMITTER'
        | 'PILOT_CREW_PLANNER'
        | 'PILOT_INSTANT_MISSION'
        | 'FLIGHT_LOG_COLLECTION_ORGANISATION'
        | 'FLIGHT_LOG_COLLECTION_OWN'
        | 'MAINTENANCE_CONTROLLER'
        | 'MAINTENANCE_PERSON'
        | 'MAINTENANCE_PERSON_EXTERNAL'
        | 'STUDENT'
        | 'OBSERVER'
        | 'READ_ONLY'
        | 'READ_ONLY_HIDDEN';
        
    export const Roles = {
        PLANNER: 'PLANNER' as Roles,
        RCP: 'RCP' as Roles,
        ADMIN: 'ADMIN' as Roles,
        BILLING_ADMIN: 'BILLING_ADMIN' as Roles,
        PILOT: 'PILOT' as Roles,
        PILOT_PLANNER: 'PILOT_PLANNER' as Roles,
        PILOT_SUBMITTER: 'PILOT_SUBMITTER' as Roles,
        PILOT_CREW_PLANNER: 'PILOT_CREW_PLANNER' as Roles,
        PILOT_INSTANT_MISSION: 'PILOT_INSTANT_MISSION' as Roles,
        FLIGHT_LOG_COLLECTION_ORGANISATION:
            'FLIGHT_LOG_COLLECTION_ORGANISATION' as Roles,
        FLIGHT_LOG_COLLECTION_OWN: 'FLIGHT_LOG_COLLECTION_OWN' as Roles,
        MAINTENANCE_CONTROLLER: 'MAINTENANCE_CONTROLLER' as Roles,
        MAINTENANCE_PERSON: 'MAINTENANCE_PERSON' as Roles,
        MAINTENANCE_PERSON_EXTERNAL: 'MAINTENANCE_PERSON_EXTERNAL' as Roles,
        STUDENT: 'STUDENT' as Roles,
        OBSERVER: 'OBSERVER' as Roles,
        READ_ONLY: 'READ_ONLY' as Roles,
        READ_ONLY_HIDDEN: 'READ_ONLY_HIDDEN' as Roles
    };
}
