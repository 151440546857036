import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import {
    ComponentGroupDto,
    ComponentTypeDto,
    MaintenanceScheduleDto,
    MaintenanceScheduleTaskDto,
    MaintenanceTaskDto,
    MaintenanceTaskTypeDto,
    observeImage,
    ResourceComponentInServiceTime,
    RpaTypesService,
    TotalInServiceTime,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import * as moment from 'moment';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ScheduledMaintenanceActivity } from '../maintenance-form.service';
import {
    DisplayableMaintenanceActivity,
    MaintenanceRequestService
} from '../maintenance-request.service';
import { MaintenanceScheduleDialogueService } from '../maintenance-schedule/maintenance-schedule-dialogue.service';

interface CompletedActivityTask extends MaintenanceTaskDto {
    activityNumber: number;
}

enum Editing {
    NONE = 'NONE',
    ADD = 'ADD',
    EDIT = 'EDIT'
}
@Component({
    selector: 'maintenance-schedule-tasks-edit',
    templateUrl: './maintenance-schedule-tasks-edit.component.html',
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()]
})
export class MaintenanceScheduleTasksEdit {
    @Input() tasks: FormArray<FormControl<ScheduledMaintenanceActivity>>;
    @Input() maintenanceSchedule: MaintenanceScheduleDto;
    @Input() allActivities: DisplayableMaintenanceActivity[];
    @Input() activityNumber: number;

    @Output() maintenanceTaskAdded = new EventEmitter<void>();
    @Output() editMaintenanceTask = new EventEmitter<void>();

    private totalInServiceTime$ = new ReplaySubject<TotalInServiceTime>(1);
    currentlyEditingId: number;
    editing = Editing.NONE;

    diagramUrl: string;
    taskList: MaintenanceScheduleTaskDto[];

    completedTasks: CompletedActivityTask[];

    hiddenGroups: number[] = [];

    schematicId: number;
    components: ResourceComponentInServiceTime[];

    availableComponents: ComponentGroupDto[];
    taskTypes: MaintenanceTaskTypeDto[];
    hasComponents: boolean;
    hasTypes: boolean;

    activity: DisplayableMaintenanceActivity;
    selectedComponent: ResourceComponentInServiceTime;
    selectedTaskType: MaintenanceTaskTypeDto;

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private maintenanceRequestService: MaintenanceRequestService,
        private maintenanceScheduleDialogueService: MaintenanceScheduleDialogueService,
        private rpaTypesService: RpaTypesService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        const currentVersion = this.maintenanceSchedule.versions?.find(
            v => v.id === this.maintenanceSchedule.activeVersionId
        );
        if (currentVersion != null) {
            this.taskList = currentVersion.taskList;
            this.maintenanceRequestService.totalTimeInService$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(total => {
                    this.schematicId = total.schematicId;
                    this.components = total.components;
                    this.totalInServiceTime$.next(total);
                });
        }
        this.maintenanceRequestService.addScheduledMaintenanceTask$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => (this.editing = Editing.ADD));

        combineLatest([
            this.maintenanceRequestService.taskTypes$,
            this.maintenanceScheduleDialogueService.componentGroup$,
            this.maintenanceRequestService.maintenanceLog$.pipe(
                filter(u => u.changes === 'INITIAL' || u.changes === 'SAVE')
            ),
            this.totalInServiceTime$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([taskTypes, componentGroups, update, total]) => {
                this.availableComponents = componentGroups;
                this.taskTypes = taskTypes;
                this.hasTypes =
                    taskTypes != null && taskTypes.length > 0 ? true : false;
                this.hasComponents =
                    componentGroups != null && componentGroups.length > 0;
                this.activity = update.log.activities.find(
                    a => a.number === this.activityNumber
                );
                this.refreshScheduledMaintenance(total);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshScheduledMaintenance(total: TotalInServiceTime) {
        if (this.allActivities != null) {
            this.completedTasks = this.allActivities.reduce((acc, activity) => {
                const completed = activity.tasks.filter(t => t.completed);
                if (completed.length > 0) {
                    return acc.concat(
                        completed.map(c => ({
                            ...c,
                            activityNumber: activity.number
                        }))
                    );
                }
                return acc;
            }, []);
        } else {
            this.completedTasks = [];
        }
        const tasks =
            this.activity != null && this.activity.tasks != null
                ? this.activity.tasks.map(t => ({
                      canEdit: true,
                      componentId: t.component.id,
                      componentName: `${t.component.componentType.name} ${t.component.designation}`,
                      taskTypeId: t.taskType.id,
                      taskTypeName: t.taskType.name,
                      selected: true,
                      status: null,
                      timeSinceLast: null
                  }))
                : [];
        this.taskList.sort((a, b) => {
            if (a.taskType < b.taskType) {
                return -1;
            } else if (a.taskType > b.taskType) {
                return 1;
            } else {
                if (a.componentGroup.name < b.componentGroup.name) {
                    return -1;
                } else if (a.componentGroup.name > b.componentGroup.name) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        this.taskList.forEach(task => {
            const components = total.components.filter(
                c => c.componentGroup?.id === task.componentGroup.id
            );
            components.forEach(component => {
                const times = this.calculateTimeSinceLast(component, task);
                const i = this.tasks.value.findIndex(
                    item =>
                        item.componentId === component.id &&
                        item.taskTypeId === task.taskType.id
                );
                const t = tasks.find(
                    item =>
                        item.componentId === component.id &&
                        item.taskTypeId === task.taskType.id
                );
                if (i !== -1) {
                    this.tasks.controls[i].patchValue({
                        ...this.tasks.controls[i].value,
                        canEdit: false,
                        timeSinceLast: this.hasBeenCompleted(
                            task.taskType.id,
                            component.id
                        )
                            ? `Done in activity ${this.findActivityNumber(
                                  task.taskType.id,
                                  component.id
                              )}`
                            : times != null
                            ? times.sinceLast
                            : 'Unknown',
                        status:
                            times != null &&
                            this.hasBeenCompleted(
                                task.taskType.id,
                                component.id
                            ) === false
                                ? times.status
                                : 'Not Due',
                        selected: this.findSelectionStatus(
                            component,
                            task,
                            times
                        )
                    });
                } else {
                    this.tasks.push(
                        new FormControl<ScheduledMaintenanceActivity>({
                            canEdit: false,
                            selected: this.findSelectionStatus(
                                component,
                                task,
                                times
                            ),
                            componentId: component.id,
                            componentName: `${component.componentType.name} ${component.designation}`,
                            taskTypeId: task.taskType.id,
                            taskTypeName: task.taskType.name,
                            timeSinceLast: this.hasBeenCompleted(
                                task.taskType.id,
                                component.id
                            )
                                ? `Done in activity ${this.findActivityNumber(
                                      task.taskType.id,
                                      component.id
                                  )}`
                                : times != null
                                ? times.sinceLast
                                : 'Unknown',
                            status:
                                times != null &&
                                this.hasBeenCompleted(
                                    task.taskType.id,
                                    component.id
                                ) === false
                                    ? times.status
                                    : 'Not Due'
                        })
                    );
                }
            });
        });
        const done = this.tasks.value;
        const remaining = tasks.filter(
            t =>
                done.find(
                    d =>
                        d.componentId === t.componentId &&
                        d.taskTypeId === t.taskTypeId
                ) == null
        );
        if (remaining.length > 0) {
            remaining.forEach(task => {
                const component = total.components.find(
                    c => c.id === task.componentId
                );
                const times = this.calculateTimeSinceLast(component, null);
                this.tasks.push(
                    new FormControl<ScheduledMaintenanceActivity>({
                        canEdit: true,
                        selected: true,
                        componentId: component.id,
                        componentName: `${component.componentType.name} ${component.designation}`,
                        taskTypeId: task.taskTypeId,
                        taskTypeName: task.taskTypeName,
                        timeSinceLast: this.hasBeenCompleted(
                            task.taskTypeId,
                            component.id
                        )
                            ? `Done in activity ${this.findActivityNumber(
                                  task.taskTypeId,
                                  component.id
                              )}`
                            : times != null
                            ? times.sinceLast
                            : 'Unknown',
                        status:
                            times != null &&
                            this.hasBeenCompleted(
                                task.taskTypeId,
                                component.id
                            ) === false
                                ? times.status
                                : 'Not Due'
                    })
                );
            });
        }
    }

    hasBeenCompleted(taskTypeId: number, componentId: number) {
        return (
            this.completedTasks.find(
                t =>
                    t.component.id === componentId &&
                    t.taskType.id === taskTypeId
            ) != null
        );
    }

    findActivityNumber(taskTypeId: number, componentId: number) {
        return this.completedTasks.find(
            t => t.component.id === componentId && t.taskType.id === taskTypeId
        ).activityNumber;
    }

    findSelectionStatus(
        component: ResourceComponentInServiceTime,
        task: MaintenanceScheduleTaskDto,
        times: any
    ) {
        if (task == null || times == null) {
            return false;
        }
        const shouldBeCompleted =
            times.status != null &&
            (times.status === 'Overdue' ||
                times.status === 'Due Now' ||
                times.status === 'Due Soon');
        if (
            shouldBeCompleted &&
            this.hasBeenCompleted(task.taskType.id, component.id) === true
        ) {
            return false;
        }
        return shouldBeCompleted;
    }

    calculateTimeSinceLast(
        component: ResourceComponentInServiceTime,
        task: MaintenanceScheduleTaskDto
    ) {
        const nextCyclesTaskDue = this.taskList.reduce(
            (acc, t) => (acc == null ? t : t.cycles <= acc.cycles ? t : acc),
            null
        );
        const nextTimeTaskDue = this.taskList.reduce(
            (acc, t) =>
                acc == null
                    ? t
                    : t.inServiceTime <= acc.inServiceTime
                    ? t
                    : acc,
            null
        );
        const nextTimeStampTaskDue = this.taskList.reduce(
            (acc, t) =>
                acc == null
                    ? t
                    : moment(t.calendarTime).isBefore(moment(acc.calendarTime))
                    ? t
                    : acc,
            null
        );
        if (component == null || task == null) {
            return {
                sinceLast: 'Unknown',
                status: 'Not Due'
            };
        }
        const cycles =
            component.untilMaintenanceSchedule?.cycles != null &&
            nextCyclesTaskDue.cycles != null
                ? nextCyclesTaskDue.cycles -
                  component.untilMaintenanceSchedule?.cycles
                : component.total?.cycles != null
                ? component.total?.cycles
                : null;
        const time =
            component.untilMaintenanceSchedule?.time != null &&
            nextTimeTaskDue.inServiceTime != null
                ? nextTimeTaskDue.inServiceTime / 60 / 60 -
                  component.untilMaintenanceSchedule?.time / 60 / 60
                : component.total?.time != null
                ? component.total?.time / 60 / 60
                : null;
        const timestamp =
            component.untilMaintenanceSchedule?.timestamp != null &&
            nextTimeStampTaskDue.calendarTime != null
                ? nextTimeStampTaskDue.calendarTime -
                  moment(component.untilMaintenanceSchedule?.timestamp).diff(
                      new Date(),
                      'days'
                  )
                : component.total?.timestamp != null
                ? moment(component.total?.timestamp).days()
                : null;

        if (cycles != null && task.cycles != null) {
            return {
                sinceLast: `${cycles} Flights/Cycles`,
                status:
                    cycles === task.cycles
                        ? 'Due Now'
                        : cycles > task.cycles
                        ? 'Overdue'
                        : cycles > task.cycles * 0.6
                        ? 'Due Soon'
                        : 'Not Due'
            };
        } else if (time != null && task.inServiceTime != null) {
            return {
                sinceLast: `${Math.round(time)} hours`,
                status:
                    time === task.inServiceTime
                        ? 'Due Now'
                        : time > task.inServiceTime / 60 / 60
                        ? 'Overdue'
                        : time > (task.inServiceTime / 60 / 60) * 0.6
                        ? 'Due Soon'
                        : 'Not Due'
            };
        } else if (timestamp != null && task.calendarTime != null) {
            return {
                sinceLast: `${timestamp} Days`,
                status:
                    timestamp === task.calendarTime
                        ? 'Due Now'
                        : timestamp >= task.calendarTime
                        ? 'Overdue'
                        : timestamp > task.calendarTime * 0.6
                        ? 'Due Soon'
                        : 'Not Due'
            };
        } else {
            return {
                sinceLast: 'Unknown',
                status: 'Not Due'
            };
        }
    }

    getAttachmentUrl(componentId: number) {
        if (this.editing !== Editing.NONE) {
            return;
        }
        this.diagramUrl = null;
        const component: ResourceComponentInServiceTime =
            componentId != null && this.components != null
                ? this.components.find(c => c.id === componentId)
                : null;
        if (componentId == null || component == null) {
            this.diagramUrl = null;
            return;
        }
        this.diagramUrl = this.rpaTypesService.getSchematicAttachmentUrl(
            this.schematicId,
            component.attachmentId
        );
        observeImage(this.diagramUrl).subscribe(
            loadedImage => (this.diagramUrl = loadedImage.src),
            () => (this.diagramUrl = null)
        );
    }

    findComponentIcon(taskControl: FormControl<ScheduledMaintenanceActivity>) {
        const task = taskControl.value;
        const component = this.components.find(c => c.id === task.componentId);
        const iconFamily = component.componentType.iconFamily;
        const icon = component.componentType.icon;
        if (
            iconFamily !== ComponentTypeDto.IconFamily.FONT_AWESOME ||
            icon == null
        ) {
            return null;
        }
        const iconClass = `right-buffer fal fa-${icon}`;
        return iconClass;
    }

    findTaskIcon(taskControl: FormControl<ScheduledMaintenanceActivity>) {
        if (this.taskTypes == null) {
            return;
        }
        const task = taskControl.value;
        const taskType = this.taskTypes.find(t => t.id === task.taskTypeId);
        const iconFamily = taskType.iconFamily;
        const icon = taskType.icon;
        if (
            iconFamily !== ComponentTypeDto.IconFamily.FONT_AWESOME ||
            icon == null
        ) {
            return null;
        }
        const iconClass = `right-buffer fal fa-${icon}`;
        return iconClass;
    }

    calculateSchedule(taskControl: FormControl<ScheduledMaintenanceActivity>) {
        const component = this.components.find(
            c => c.id === taskControl.value.componentId
        );
        const task = this.taskList.find(
            t =>
                t.componentGroup.id === component.componentGroup?.id &&
                t.taskType.id === taskControl.value.taskTypeId
        );
        if (task == null) {
            return 'Not Scheduled';
        }
        const cycles =
            task.cycles != null ? `${task.cycles} Flights/Cycles` : null;
        const hours =
            task.inServiceTime != null
                ? `${(task.inServiceTime / 60 / 60).toString()} Hours`
                : null;
        const days =
            hours == null && task.calendarTime != null
                ? `${task.calendarTime.toString()} Days`
                : null;
        return cycles ?? hours ?? days ?? 'No Schedule';
    }

    isUnique() {
        const item = this.tasks.value.find(
            task =>
                task.componentId === this.selectedComponent.id &&
                task.taskTypeId === this.selectedTaskType.id
        );
        if (item == null) {
            return true;
        }
        return false;
    }

    isInGroup(task: ScheduledMaintenanceActivity, group: ComponentGroupDto) {
        if (group == null) {
            return (
                this.components.find(c => c.id === task.componentId)
                    .componentGroup == null
            );
        }
        return (
            this.components.find(c => c.id === task.componentId).componentGroup
                ?.id === group.id
        );
    }

    hasAnyInGroup(group: ComponentGroupDto) {
        return (
            this.tasks.value.filter(t => this.isInGroup(t, group)).length > 0
        );
    }

    toggleGroup(groupId: number) {
        if (this.hiddenGroups.includes(groupId)) {
            this.hiddenGroups = this.hiddenGroups.filter(g => g !== groupId);
        } else {
            this.hiddenGroups.push(groupId);
        }
    }

    isHidden(groupId: number) {
        return this.hiddenGroups.includes(groupId);
    }

    addTask() {
        this.tasks.push(
            new FormControl<ScheduledMaintenanceActivity>({
                canEdit: true,
                selected: true,
                componentId: this.selectedComponent.id,
                componentName: `${this.selectedComponent.componentType.name} ${this.selectedComponent.designation}`,
                taskTypeId: this.selectedTaskType.id,
                taskTypeName: this.selectedTaskType.name,
                timeSinceLast: 'Unknown',
                status: 'NotDue'
            })
        );
        // this is to ensure the modal's save button unlocks
        this.tasks.markAsDirty();
        this.selectedComponent = null;
        this.selectedTaskType = null;
        this.editing = Editing.NONE;
        this.maintenanceTaskAdded.emit();
    }

    editTask(index: number) {
        this.currentlyEditingId = index;
        this.selectedComponent = this.components.find(
            c => c.id === this.tasks.controls[index].value.componentId
        );
        this.selectedTaskType = this.taskTypes.find(
            t => t.id === this.tasks.controls[index].value.taskTypeId
        );
        this.editMaintenanceTask.emit();
        this.editing = Editing.EDIT;
    }

    saveTask(index: number) {
        const currentValue = this.tasks.controls[index].value;
        this.tasks.controls[index].patchValue({
            ...currentValue,
            componentId: this.selectedComponent.id,
            componentName: `${this.selectedComponent.componentType.name} ${this.selectedComponent.designation}`,
            taskTypeId: this.selectedTaskType.id,
            taskTypeName: this.selectedTaskType.name
        });
        this.tasks.markAsDirty();
        this.currentlyEditingId = null;
        this.selectedComponent = null;
        this.selectedTaskType = null;
        this.editing = Editing.NONE;
        this.maintenanceTaskAdded.emit();
    }

    cancelAction() {
        this.currentlyEditingId = null;
        this.selectedComponent = null;
        this.selectedTaskType = null;
        this.editing = Editing.NONE;
        this.maintenanceTaskAdded.emit();
    }

    deleteTask(index: number) {
        this.editMaintenanceTask.emit();
        this.tasks.removeAt(index);
        this.maintenanceTaskAdded.emit();
    }

    onSelectionUpdated() {
        this.tasks.markAsDirty();
    }
}
