<div class="panel widget utilities">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img src="{{ IMG_URL }}/icons/icon-risk-register.png" />
        </div>
        <div class="panel-title">Risk Register</div>

        <div class="panel-content"></div>

        <div class="panel-buttons">
            <button
                *ngIf="canEdit"
                class="btn btn-inline btn-title-action"
                (click)="newRiskEntry()"
                tooltip="Add a new risk entry"
                placement="left"
            >
                <i class="fa-plus fa"></i>
            </button>
        </div>
    </div>

    <div class="panel-body">
        <static-table
            *ngIf="risks?.length > 0"
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            [tableData]="risks"
            [tableConfig]="tableConfig"
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (sortOrderChanged)="updateColumnSorting($event)"
            (itemLimitUpdated)="updateItemLimit($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
        >
        </static-table>
        <div
            class="alert alert-info"
            role="alert"
            *ngIf="canEdit && risks && risks.length == 0"
        >
            You have no risks in your risk register. Some will need to be added.
        </div>
        <div
            class="alert alert-info"
            role="alert"
            *ngIf="!canEdit && risks && risks.length == 0"
        >
            There are currently no risks in the risk register.
        </div>
        <loading-indicator [isLoading]="working"></loading-indicator>
    </div>
</div>
