import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AirspaceCheckService } from '../airspace-check.service';

@Component({
    selector: 'airspace-check-status',
    template: `
        <div *ngIf="started" class="airspace-check-container">
            <i
                *ngIf="isChecking"
                class="fa fa-spinner fa-pulse fa-fw"
                [tooltip]="tooltip"
                placement="top"
            ></i>
            <span
                *ngIf="!isChecking"
                class="fal"
                [ngClass]="{
                    'airspace-incomplete':
                        status === 'INCOMPLETE' ||
                        status === 'NO_CHECK' ||
                        status === 'NO_RULESET',
                    'airspace-cannot-fly': status === 'CANNOT_FLY',
                    'fa-times-circle': status === 'CANNOT_FLY',
                    'airspace-can-fly-conditions':
                        status === 'CAN_FLY_CONDITIONS',
                    'fa-exclamation-circle': status === 'CAN_FLY_CONDITIONS',
                    'airspace-can-fly': status === 'CAN_FLY',
                    'fa-check-circle':
                        status === 'CAN_FLY' ||
                        status === 'INCOMPLETE' ||
                        status === 'NO_CHECK' ||
                        status === 'NO_RULESET'
                }"
                [tooltip]="tooltip"
                placement="top"
            ></span>
        </div>
    `,
    styles: [
        `
            .airspace-check-container {
                font-size: 18px;
                width: max-content;
                height: 100%;
                margin-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .airspace-incomplete {
                color: #cacad8;
            }
            .airspace-cannot-fly {
                color: #dc3545;
            }
            .airspace-can-fly-conditions {
                color: #fecb1c;
            }
            .airspace-can-fly {
                color: #00de7a;
            }
        `
    ]
})
export class AirspaceCheckStatusIndicator {
    status: string;
    previousStatus: string;
    isChecking = false;

    started = false;

    tooltip: string;

    private ngUnsubscribe$ = new Subject<void>();
    constructor(private airspaceCheckService: AirspaceCheckService) {}

    ngOnInit() {
        // previousStatus helps fill a loading gap where the status is briefly null without hiding the loading indicator
        this.previousStatus = '';
        this.airspaceCheckService.resultStatus$
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(status => {
                this.started = true;
                this.status = status;
                this.isChecking =
                    status === 'CHECKING' ||
                    (status == null && this.previousStatus === 'CHECKING');
                this.tooltip = this.setTooltip();
                if (status != null) {
                    this.previousStatus = status;
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setTooltip() {
        switch (this.status) {
            case 'CHECKING':
                return 'Checking status';

            case 'INCOMPLETE':
                return 'Airspace check conditions incomplete';

            case 'NO_CHECK':
                return 'Checking unavailable';

            case 'NO_RULESET':
                return 'No ruleset to check against';

            case 'CANNOT_FLY':
                return 'Cannot fly';

            case 'CAN_FLY_CONDITIONS':
                return 'Can fly conditionally';

            case 'CAN_FLY':
                return 'Can fly';

            default:
                return '';
        }
    }
}
