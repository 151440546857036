<div class="panel widget pilot">
    <div class="panel-heading-flex">
        <div class="panel-icon">
            <img imgPath="/icons/icon-personnel.png" />
        </div>
        <div class="panel-title">Personnel</div>

        <div class="panel-content" style="flex-grow: 1">
            <label>Status: </label>
            <ng-select
                [items]="selectedPersonnelListOptions"
                [(ngModel)]="selectedPersonnelList"
                (ngModelChange)="changeStatus()"
                [clearable]="false"
                appendTo="#dashboard"
            >
                <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                </ng-template>
                <ng-template
                    ng-option-tmp
                    ng-label-tmp
                    let-item="item"
                    let-index="index"
                ><span class="ng-option-label">
                        {{ item.name }}
                    </span>
                </ng-template>
            </ng-select>
        </div>

        <div class="panel-buttons" style="flex: 0.7">
            <ng-container
                *ngIf="
                    licences != null && licences
                        | guardType
                            : isCurrentSubscription as currentSubscription
                "
            >
                <a
                    *ngIf="
                        canViewBilling &&
                        canAddPersonnel &&
                        currentSubscription.totalPersonnel != null
                    "
                    (click)="showOrganisationSubscriptionSettings()"
                    angularticsAction="personnel-widget-licences"
                    angularticsCategory="subscriptions"
                ><span class="display-label">Licences </span>
                    {{ currentSubscription.assignedPersonnel }} /
                    {{ currentSubscription.totalPersonnel }}</a
                >
            </ng-container>

            <button
                *ngIf="canViewBilling && licences?.type === 'no_subscriptions'"
                type="button"
                class="btn btn-golden"
                (click)="showOrganisationSubscriptionSettings()"
                angulartics2On="click"
                angularticsAction="personnel-widget-upgrade"
                angularticsCategory="subscriptions"
            >
                Upgrade to add personnel
            </button>
            <button
                class="btn btn-inline"
                (click)="showPersonnelReportsDialogue()"
                tooltip="Personnel reports"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-personnel-reports"
            >
                <span class="fa-print fa"></span>
            </button>
            <button
                *ngIf="canAddPersonnel"
                class="btn btn-inline"
                (click)="newPerson()"
                [disabled]="!licences?.hasLicencesAvailable"
                tooltip="Add or invite someone to your organisation"
                placement="left"
                angulartics2On="click"
                angularticsAction="dashboard-personnel-add"
            >
                <span class="fa-plus fa"></span>
            </button>
        </div>
    </div>
    <div class="panel-body">
        <loading-indicator [isLoading]="working"></loading-indicator>
        <static-table
            *ngIf="tablePersonnel?.length > 0 && view$ | async as view"
            [availableColumns]="availableColumns"
            [selectedColumns]="
                view === 'CURRENT' ? selectedColumns : inviteColumns
            "
            [tableData]="tablePersonnel"
            [tableConfig]="
                view === 'CURRENT' || view === 'HISTORICAL'
                    ? tableConfig
                    : inviteTableConfig
            "
            (onSelectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            [columnSorting]="columnSorting"
            [tableSearch]="tableSearch"
            (selectedColumnsChanged)="
                updateSelectedColumns($event.selectedColumns)
            "
            (itemLimitUpdated)="updateItemLimit($event)"
            (sortOrderChanged)="updateColumnSorting($event)"
            (search)="updateSearchPreferences($event)"
            [centeredHeadings]="true"
            [borderless]="true"
            data-cy="personnelListTable"
        >
        </static-table>
    </div>
</div>
