import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    AssociateAuthorityCommand,
    FlyFreelyError,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'associate-authorities-dialogue',
    template: `
        <div class="modal-header no-icon">
            <h3 class="modal-title">Link Authority</h3>
            <button
                type="button"
                (click)="close()"
                aria-label="Close"
                class="close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="bottom-buffer">
                Link {{ name }} to
                {{ dependentOnAuthorityType.name }}&nbsp;&nbsp;<i
                    class="fal fa-info-circle info-tooltip"
                    [tooltip]="
                        'This authority is dependent on a ' +
                        dependentOnAuthorityType.name +
                        ' and should be linked to it. Once linked will no longer appear as a separate authority on the list, but will be able to find it under the Linked Authorities section of the ' +
                        dependentOnAuthorityType.name +
                        '.'
                    "
                    container="certification-content"
                ></i>
            </div>
            <!-- <p class="bottom-buffer">
                This authority is dependent on a
                {{ dependentOnAuthorityType.name }} and should be linked to it.
                Once linked you won't see this authority on the list anymore,
                but will be able to find it under the "Linked Authorities"
                section of the {{ dependentOnAuthorityType.name }}.
            </p> -->
            <div
                class="top-buffer bottom-buffer"
                *ngIf="dependentOnAuthorityType.authorities.length > 1"
            >
                There is more than one authority of the required type, select
                which one to link to:

                <ng-select
                    placeholder="Select Authority To Link To"
                    [items]="dependentOnAuthorityType.authorities"
                    bindLabel="startDate"
                    [(ngModel)]="selectedAuthority"
                    appendTo="body"
                >
                    <ng-template ng-option-tmp let-item="item">
                        {{ dependentOnAuthorityType.name }} -
                        {{ item.startDate | formatDate
                        }}{{ item.endDate ? ' - ' : ''
                        }}{{ item.endDate | formatDate }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        {{ dependentOnAuthorityType.name }} -
                        {{ item.startDate | formatDate
                        }}{{ item.endDate ? ' - ' : ''
                        }}{{ item.endDate | formatDate }}
                    </ng-template>
                </ng-select>
            </div>
            <ng-container *ngIf="selectedAuthority != null">
                <h4>
                    {{ dependentOnAuthorityType.name }}
                </h4>
                <p class="authority-sub-title">
                    Issued by {{ dependentOnAuthorityType.issuedBy.name }}
                </p>
                <div style="margin-top: 25px">
                    <div class="horizontal-container fill bottom-buffer">
                        <div class="fcol-5">
                            <label>Start</label>
                            <p>{{ selectedAuthority.startDate }}</p>
                        </div>
                        <div *ngIf="authority?.expiryDate != null">
                            <label>Expiry</label>
                            <p>{{ selectedAuthority.expiryDate }}</p>
                        </div>
                    </div>
                    <div class="horizontal-container fill">
                        <div class="fcol-5">
                            <label>{{
                                dependentOnAuthorityType.identifierLabel
                            }}</label>
                        </div>
                        {{ selectedAuthority.identifier }}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="modal-footer">
            <loading-indicator
                class="pull-left"
                [isLoading]="working"
            ></loading-indicator>
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="working || selectedAuthority == null"
                (click)="linkAuthority()"
            >
                Link
            </button>
        </div>
    `
})
export class AssociateAuthoritiesDialogueComponent {
    @Input() authority: OrganisationAuthorityDto;
    @Input() managingOrganisationId: number;
    @Input() dependentOnAuthorityType: OrganisationAuthorityGroup;
    @Input() name: string;
    // FIXME: This is a temporary fix until we have a better way of doing this for "top-level" authorities.
    // Is this link happening when adding the authority type?
    @Input() selectForCreate: boolean = false;
    @Output() selectedLinkedAuthority = new EventEmitter<
        OrganisationAuthorityDto
    >();

    selectedAuthority: OrganisationAuthorityDto;
    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private modal: BsModalRef,
        private toastr: ToastrService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        if (this.dependentOnAuthorityType.authorities.length === 1) {
            this.selectedAuthority = this.dependentOnAuthorityType.authorities[0];
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    linkAuthority() {
        // FIXME: This is a temporary fix until we have a better way of doing this for "top-level" authorities.
        if (this.selectForCreate) {
            this.selectedLinkedAuthority.emit(this.selectedAuthority);
            this.close();
            return;
        }
        const command: AssociateAuthorityCommand = {
            dependentOnAuthorityId: this.selectedAuthority.id,
            managingOrganisationId: this.managingOrganisationId
        };
        this.organisationAuthorityService
            .linkAuthority(this.authority.id, command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: linked => {
                    this.toastr.success('Authority linked');
                    this.close();
                },
                error: (error: FlyFreelyError) => {
                    this.toastr.error(
                        `Error linking authority: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    close() {
        this.modal.hide();
    }
}
