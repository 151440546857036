import { CurrentAttachmentVersionDto } from './currentAttachmentVersionDto';

export interface AirworthnessNoticesDto {
    id: number;
    name: string;
    description?: string;
    issuedBy?: string;
    dueBy?: string;
    type: AirworthinessNoticeType;
    status: string;
    applicability: AirworthinessNoticeApplicability;
    creationTime?: string;
    modificationTime?: string;
    attachmentList?: [CurrentAttachmentVersionDto];
    linkList?: [LinkDto];
}

export enum AirworthinessNoticeApplicability {
    APPLICABLE = 'APPLICABLE',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    UNKNOWN = 'UNKNOWN'
}

export enum AirworthinessNoticeType {
    AIRWORTHINESS_DIRECTIVE = 'AIRWORTHINESS_DIRECTIVE',
    SERVICE_BULLETIN = 'SERVICE_BULLETIN',
    ENGINEERING_ORDER = 'ENGINEERING_ORDER',
    OTHER = 'OTHER'
}

export interface LinkDto {
    id: number;
    title: string;
    url: string;
}
export function getApplicabilityDisplayValue(value: string): string {
    switch (value) {
        case 'APPLICABLE':
            return 'Applicable';
        case 'NOT_APPLICABLE':
            return 'Not Applicable';
        case 'UNKNOWN':
            return 'Unknown';
        default:
            return value;
    }
}

export function getTypeDisplayValue(value: string): string {
    switch (value) {
        case 'AIRWORTHINESS_DIRECTIVE':
            return 'Airworthiness Directive';
        case 'SERVICE_BULLETIN':
            return 'Service Bulletin';
        case 'ENGINEERING_ORDER':
            return 'Engineering Order';
        case 'OTHER':
            return 'Other';
        default:
            return value;
    }
}
