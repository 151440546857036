import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    CONFORMANCE_RULE_TYPE,
    CreateFlightConformanceRuleCommand,
    DO_NOTHING,
    FlightConformancePoliciesService,
    FlightConformancePolicyDto,
    FlightConformanceRuleDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    UpdateFlightConformanceRuleCommand,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { FormatFlightConformanceTypePipe } from '@flyfreely-portal-ui/ui';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import { EnhancedHelpService } from 'libs/enhanced-help/src/lib/enhanced-help.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

const editRuleFields: FormlyFieldConfig[] = [
    {
        key: 'description',
        type: 'textarea',
        props: {
            label: 'Description',
            description:
                'A description of what this rule is enforcing so the RP can understand the reason for the alert',
            required: true
        }
    },
    {
        key: 'defaultValue',
        type: 'input',
        props: {
            label: 'Default Value',
            description:
                'The value that is used if another value can not be derived from the mission or related configuration',
            type: 'number',
            min: 0
        },
        hideExpression: 'model.type === "FLIGHT_AREA"'
    },
    {
        key: 'maximumValue',
        type: 'input',
        props: {
            label: 'Maximum Value',
            description:
                'The hard maximum value to be used, even if the mission or related configuration has a different value',
            type: 'number',
            min: 0
        },
        hideExpression:
            'model.type === "FLIGHT_AREA" || model.type === "LANDING_BATTERY_LEVEL"'
    }
];

const selectRuleFields: FormlyFieldConfig[] = [
    {
        key: 'type',
        type: 'ng-select',
        props: {
            label: 'Rule Type',
            required: true,
            labelProp: 'name',
            valueProp: 'value',
            options: CONFORMANCE_RULE_TYPE
        }
    }
];

@Component({
    selector: 'flight-conformance-policy',
    template: ` <div
            class="horizontal-container pad-children center-children mdc-children"
        >
            <div mdcFormField>
                <div mdcSwitch>
                    <div mdcSwitchThumb>
                        <input
                            type="checkbox"
                            mdcSwitchInput
                            [disabled]="working"
                            [checked]="policy.enabled"
                            (change)="
                                updatePolicyActions(
                                    policy,
                                    $event.target.checked,
                                    policy.notifyRp,
                                    policy.notifyRpic,
                                    policy.notifyApprover
                                )
                            "
                        />
                    </div>
                </div>
                <label mdcFormFieldLabel>Enabled</label>
            </div>

            <div mdcFormField>
                <div mdcSwitch>
                    <div mdcSwitchThumb>
                        <input
                            type="checkbox"
                            mdcSwitchInput
                            [disabled]="working"
                            [checked]="policy.notifyRp"
                            (change)="
                                updatePolicyActions(
                                    policy,
                                    policy.enabled,
                                    $event.target.checked,
                                    policy.notifyRpic,
                                    policy.notifyApprover
                                )
                            "
                        />
                    </div>
                </div>
                <label mdcFormFieldLabel>Notify RP</label>
            </div>
            <div mdcFormField>
                <div mdcSwitch>
                    <div mdcSwitchThumb>
                        <input
                            type="checkbox"
                            mdcSwitchInput
                            [disabled]="working"
                            [checked]="policy.notifyRpic"
                            (change)="
                                updatePolicyActions(
                                    policy,
                                    policy.enabled,
                                    policy.notifyRp,
                                    $event.target.checked,
                                    policy.notifyApprover
                                )
                            "
                        />
                    </div>
                </div>
                <label mdcFormFieldLabel>Notify RPIC</label>
            </div>
            <div mdcFormField>
                <div mdcSwitch>
                    <div mdcSwitchThumb>
                        <input
                            type="checkbox"
                            mdcSwitchInput
                            [disabled]="working"
                            [checked]="policy.notifyApprover"
                            (change)="
                                updatePolicyActions(
                                    policy,
                                    policy.enabled,
                                    policy.notifyRp,
                                    policy.notifyRpic,
                                    $event.target.checked
                                )
                            "
                        />
                    </div>
                </div>
                <label mdcFormFieldLabel>Notify Approver</label>
            </div>
        </div>

        <div class="horizontal-container">
            <button
                type="button"
                class="btn-circle btn-link"
                (click)="newRule(policy)"
                [disabled]="working"
            >
                <span class="fa fa-plus"></span>
            </button>
            <div class="fill">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Check Type</th>
                            <th>Enabled</th>
                            <th>Settings</th>
                            <th>Message</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let rule of policy.ruleList"
                            class="mdc-children"
                        >
                            <td>
                                {{ rule.type | formatFlightConformanceType }}
                            </td>
                            <td>
                                <div mdcFormField>
                                    <div mdcSwitch>
                                        <div mdcSwitchThumb>
                                            <input
                                                type="checkbox"
                                                mdcSwitchInput
                                                [disabled]="working"
                                                [checked]="rule.enabled"
                                                (change)="
                                                    updateRuleActions(
                                                        policy,
                                                        rule,
                                                        $event.target.checked
                                                    )
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{ rule | formatConformanceRuleSettings }}</td>
                            <td>{{ rule.description }}</td>
                            <td>
                                <button
                                    class="btn btn-sm btn-tertiary"
                                    (click)="editRule(policy, rule)"
                                    [disabled]="working"
                                    tooltip="Edit"
                                >
                                    <span class="fal fa-pencil-alt"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>`
})
export class FlightConformancePolicy {
    @Input()
    policy: FlightConformancePolicyDto;

    @Output()
    updatePolicy = new EventEmitter<FlightConformancePolicyDto>();

    @Output()
    updateRule = new EventEmitter<[number, FlightConformanceRuleDto]>();

    public working: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private workTracker: WorkTracker,
        private commonDialoguesService: CommonDialoguesService,
        private enhancedHelpService: EnhancedHelpService,
        private formatFlightConformanceType: FormatFlightConformanceTypePipe,
        private logging: FlyFreelyLoggingService,
        private flightConformancePoliciesService: FlightConformancePoliciesService
    ) {}

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    newRule(policy: FlightConformancePolicyDto) {
        this.enhancedHelpService
            .findByScreen('flight-conformance-policy')
            .pipe(takeUntil(this.ngUnsubscribe$), take(1))
            .subscribe(help => {
                const addRuleFields = selectRuleFields
                    .concat({
                        type: 'help',
                        props: {
                            lookupProp: 'type',
                            messageLookup: help.reduce(
                                (acc, v) => ({
                                    ...acc,
                                    [v.component]: v.helpText
                                }),
                                {}
                            )
                        }
                    })
                    .concat(editRuleFields);

                this.commonDialoguesService
                    .showFormlyDialogue(
                        'New Rule',
                        'Add',
                        true,
                        true,
                        addRuleFields,
                        {} as CreateFlightConformanceRuleCommand,
                        data =>
                            this.flightConformancePoliciesService
                                .createRule(policy.id, data)
                                .pipe(takeUntil(this.ngUnsubscribe$))
                                .toPromise(),
                        'modal-task',
                        'flight-conformance-policy'
                    )
                    .then(newRule => policy.ruleList.push(newRule));
            }, DO_NOTHING);
    }

    editRule(
        policy: FlightConformancePolicyDto,
        rule: FlightConformanceRuleDto
    ) {
        this.enhancedHelpService
            .findByScreenAndComponent('flight-conformance-policy', rule.type)
            .pipe(takeUntil(this.ngUnsubscribe$), take(1))
            .subscribe(help => {
                const fields = [
                    {
                        type: 'help',
                        props: {
                            message: help.helpText
                        }
                    } as FormlyFieldConfig
                ].concat(editRuleFields);

                this.commonDialoguesService
                    .showFormlyDialogue(
                        `Edit Rule - ${this.formatFlightConformanceType.transform(
                            rule.type
                        )}`,
                        'Save',
                        true,
                        true,
                        fields,
                        {
                            description: rule.description,
                            defaultValue: rule.defaultValue,
                            maximumValue: rule.maximumValue
                        } as UpdateFlightConformanceRuleCommand,
                        data =>
                            this.flightConformancePoliciesService
                                .updateRule(policy.id, rule.ruleId, data)
                                .pipe(takeUntil(this.ngUnsubscribe$))
                                .toPromise(),
                        'modal-task',
                        'flight-conformance-policy'
                    )
                    .then(
                        newRule =>
                            (policy.ruleList = policy.ruleList.map(r =>
                                r.ruleId === newRule.ruleId ? newRule : r
                            )),
                        DO_NOTHING
                    );
            }, DO_NOTHING);
    }

    updatePolicyActions(
        policy: FlightConformancePolicyDto,
        enabled: boolean,
        notifyRp: boolean,
        notifyRpic: boolean,
        notifyApprover: boolean
    ) {
        this.flightConformancePoliciesService
            .update(policy.id, {
                enabled,
                notifyRp,
                notifyRpic,
                notifyApprover
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                saved => this.updatePolicy.emit(saved),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while updating policy: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    updateRuleActions(
        policy: FlightConformancePolicyDto,
        rule: FlightConformanceRuleDto,
        enabled: boolean
    ) {
        this.flightConformancePoliciesService
            .updateRuleActions(policy.id, rule.ruleId, {
                enabled
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                saved => this.updateRule.emit([policy.id, saved]),
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while updating rule: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }
}
