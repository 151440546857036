import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineModal } from './inline-modal.component';

@NgModule({
    imports: [CommonModule],
    declarations: [InlineModal],
    exports: [InlineModal]
})
export class InlineModalModule {}
