import { Component, Input } from '@angular/core';
import {
    RegisterEntryOnMissionSummary,
    RpaCategoryGroup
} from '@flyfreely-portal-ui/flyfreely';
import {
    MissionEditService,
    PilotRegisterStatusUnion
} from '../../mission-edit.service';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

/**
 * A function that compares two register statuses and returns the most pressing/important status
 * It can be used in a reduce function to find the highest priority status from a list of statuses
 * @param status The current status to compare
 * @param currentMostPressing The currently most pressing status already determined, or a second status to compare against
 * @returns The more pressing/important of the two supplied statuses
 */
function findMostPressingStatus(
    status: RpaCategoryGroup.RegisterStatus,
    currentMostPressing: RpaCategoryGroup.RegisterStatus
) {
    const type = RpaCategoryGroup.RegisterStatus;
    // Descending order of importance for status displays. Higher up = more important
    const order = [
        type.NOT_ON_REGISTER,
        type.NOT_AVAILABLE,
        type.SUSPENDED,
        type.EXPIRED,
        type.PENDING,
        type.ACTIVE,
        type.AUTO
    ];
    // Use the order of importance above to return the most important of the two.
    if (order.indexOf(status) <= order.indexOf(currentMostPressing)) {
        return status;
    } else {
        return currentMostPressing;
    }
}

/**
 * An inline status indicator component that displays an overall status from a list of register statuses.
 * It receives the list of pilot mission register statuses from the mission edit service.
 * It displays the most pressing of these statuses.
 * Is used in the mission editor to show a status summary for each pilot's mission register statuses.
 */
@Component({
    selector: 'pilot-register-details-overall-status',
    template: `
        <span
            *ngIf="overallStatus !== 'NONE'"
            class="status-block"
            [ngClass]="overallStatus | registerStatusClass"
        >
            {{ overallStatus | formatMissionEditRegisterStatus }}
        </span>
        <span *ngIf="overallStatus == 'NONE'" class="status-block pending">
            None
        </span>
    `
})
export class PilotRegisterDetailsOverallStatusIndicatorComponent {
    @Input() pilotId: number;
    @Input() status: PilotRegisterStatusUnion;

    overallStatus: RpaCategoryGroup.RegisterStatus | 'NONE' = 'NONE';

    private ngUnsubscribe$ = new Subject<void>();
    constructor(private missionEditService: MissionEditService) {}

    ngOnInit() {
        combineLatest([
            this.missionEditService.registerEntriesOnMission$.pipe(
                startWith(null as RegisterEntryOnMissionSummary[])
            ),
            this.missionEditService.missionForm.controls.craftIds.valueChanges.pipe(
                startWith([] as number[])
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([entries, rpaIds]) => {
                const entry =
                    entries != null
                        ? entries.find(e => e.entityId === this.pilotId)
                        : null;
                this.findOverallRegisterStatus(entry, rpaIds ?? []);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findOverallRegisterStatus(
        entry: RegisterEntryOnMissionSummary,
        rpaIds: number[]
    ) {
        if (
            entry == null ||
            entry.registerStatusGroupList == null ||
            entry.registerStatusGroupList.length === 0
        ) {
            if (this.status != null && rpaIds.length === 0) {
                // Fall back on the overall register status if available and there's no mission specific status due to no RPA on the mission
                this.overallStatus = this.status as
                    | RpaCategoryGroup.RegisterStatus
                    | 'NONE';
                return;
            }
            this.overallStatus = 'NONE';
            return;
        }
        this.overallStatus = entry.registerStatusGroupList.reduce(
            (acc, group) => findMostPressingStatus(group.registerStatus, acc),
            RpaCategoryGroup.RegisterStatus.AUTO
        );
    }
}
