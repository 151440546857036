import { Injectable } from '@angular/core';
import {
    DashboardService,
    LoggedInUser,
    PersonDto,
    PersonsOrganisationDto,
    OnboardingService,
    UserService,
    UserStatus
} from '@flyfreely-portal-ui/flyfreely';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Router } from '@angular/router';

export enum OnboardingScreens {
    PERSONAL_DETAILS = 'PERSONAL_DETAILS',
    ORGANISATION_DETAILS = 'ORGANISATION_DETAILS',
    COMPLETE = 'COMPLETE'
}

export enum OnboardingTab {
    PERSONAL_DETAILS = 'PERSONAL_DETAILS',
    PERSONAL_FLIGHT_PREFERENCES = 'PERSONAL_FLIGHT_PREFERENCES',
    PERSONAL_NONPRIMARY = 'PERSONAL_NONPRIMARY',
    PERSONAL_PRIMARY = 'PERSONAL_PRIMARY',
    FLIGHT_HISTORY = 'FLIGHT_HISTORY',
    PERSONAL_SETUP_COMPLETE = 'PERSONAL_SETUP_COMPLETE',
    ORGANISATION_DETAILS = 'ORGANISATION_DETAILS',
    ORGANISATION_CERTIFICATION = 'ORGANISATION_CERTIFICATION',
    ORGANISATION_SETUP_COMPLETE = 'ORGANISATION_SETUP_COMPLETE',
    FIELD_APP_DOWNLOAD = 'FIELD_APP_DOWNLOAD',
    SWITCH_TO_DESKTOP = 'SWITCH_TO_DESKTOP'
}

export const headings: (name: string) => {
    [screen: string]: {
        [tab: string]: {
            title: string;
            heading: string;
            helpText: string;
            helpTitle: string;
            hasHelp: boolean;
        };
    };
} = (name: string) => ({
    [OnboardingScreens.PERSONAL_DETAILS]: {
        [OnboardingTab.PERSONAL_DETAILS]: {
            title: `Welcome ${name}`,
            heading: `Let's begin setting up your <strong>Personal Profile</strong>`,
            hasHelp: true,
            helpText:
                'Every individual on the FlyFreely platform has a Personal Organisation which stores your Profile, Authorities and Flight History.',
            helpTitle: 'Personal Profile'
        },
        [OnboardingTab.PERSONAL_FLIGHT_PREFERENCES]: {
            title: `Flying Decision`,
            heading:
                'Will you be involved in <strong>flight operations and maintenance</strong> or not?',
            hasHelp: true,
            helpText:
                'If you will not be involved in flight operations and maintenance we will skip some steps now. You can always add the flight related information if your role changes.',
            helpTitle: 'Flight Operations and Maintenance'
        },
        [OnboardingTab.PERSONAL_NONPRIMARY]: {
            title: `Personal Authorities`,
            heading:
                'Lets setup any existing <strong>Personal Authorities</strong>',
            hasHelp: true,
            helpText: `FlyFreely calls things like Licenses, Certificates and Inductions Personal Authorities.  You do not need to setup all Authorities now if you do not have them at hand.`,
            helpTitle: 'Personal Authorities'
        },
        [OnboardingTab.PERSONAL_PRIMARY]: {
            title: `Personal Authorities`,
            heading:
                'Select any <strong>Authorities</strong> you will utilise for flying <strong>under your own name</strong>',
            hasHelp: true,
            helpText: `If you will be flying under a Company you will not need to setup any of these Authorities.`,
            helpTitle: 'Personal Authorities'
        },
        [OnboardingTab.FLIGHT_HISTORY]: {
            title: `Flight History`,
            heading:
                'Are you a <strong>Veteran of the skies</strong> or as <strong>Green as grass</strong>?',
            hasHelp: true,
            helpText:
                'If you have your flight history details nearby or easily accessible, great! If not we can add them later.',
            helpTitle: 'Flight History'
        },
        [OnboardingTab.PERSONAL_SETUP_COMPLETE]: {
            title: `Company Decision`,
            heading:
                'Do you need to create a <strong>Company Organisation</strong>?',
            hasHelp: true,
            helpText:
                'Company organisations are any non-individual legal entities.',
            helpTitle: 'Company Organisations'
        }
    },
    [OnboardingScreens.ORGANISATION_DETAILS]: {
        [OnboardingTab.ORGANISATION_DETAILS]: {
            title: `Company Profile`,
            heading:
                'Lets setup your <strong>Company Profile</strong> in your Company Organisation',
            hasHelp: true,
            helpText:
                'If your Company Organisation already exists ask an administor for an invitation.',
            helpTitle: 'Company Profile'
        },
        [OnboardingTab.ORGANISATION_CERTIFICATION]: {
            title: `Company Authorities`,
            heading: `Setup any existing <strong>Company Authorities</strong>`,
            hasHelp: true,
            helpText:
                'Add in any Certificates, Numbers or Licences the Company has. You do not need to setup all Authorities now if you do not have them at hand.',
            helpTitle: 'Company Authorities'
        }
    },
    [OnboardingScreens.COMPLETE]: {
        [OnboardingTab.ORGANISATION_SETUP_COMPLETE]: {
            title: `Well Done!`,
            heading: 'You are now all setup on the FlyFreely platform',
            hasHelp: false,
            helpText: '',
            helpTitle: ''
        },
        [OnboardingTab.FIELD_APP_DOWNLOAD]: {
            title: `Download Our Field App`,
            heading: '',
            hasHelp: false,
            helpText: '',
            helpTitle: ''
        },
        [OnboardingTab.SWITCH_TO_DESKTOP]: {
            title: `Switch to desktop`,
            heading: 'You are about to view an unoptimised experience',
            hasHelp: false,
            helpText: '',
            helpTitle: ''
        }
    }
});

export interface ScreenAndTab {
    screen: OnboardingScreens;
    tab: OnboardingTab;
}

export interface IOnboardingPerson {
    person: PersonDto;
    personalOrganisation: PersonsOrganisationDto;
    inExistingOrganisation: boolean;
    ownsAnOrganisation: boolean;
}

@Injectable()
export class OnboardingData {
    isFlying: boolean;
    organisationId: number;
    hasCreatedPersonalDummyData: boolean = false;
    hasCreatedCommercialDummyData: boolean = false;
    personalJurisdictionId: number;
    commercialJurisdictionId: number;
    requestedPersonalSales: number[] = [];
    requestedCommercialSales: number[] = [];
    hasAcceptedInvite = false;
    private currentScreenAndTabSource = new BehaviorSubject<ScreenAndTab>({
        screen: OnboardingScreens.PERSONAL_DETAILS,
        tab: OnboardingTab.PERSONAL_DETAILS
    });

    currentScreenAndTab$ = this.currentScreenAndTabSource.asObservable();

    private headingsSource = new BehaviorSubject({
        title: '',
        heading: '',
        helpText: '',
        hasHelp: false,
        helpTitle: ''
    });
    headings$ = this.headingsSource.asObservable();

    personAndPersonalOrganisation$: Observable<IOnboardingPerson>;

    private businessNameSource = new BehaviorSubject<string>(null);

    private shouldReturnToDashboard = false;
    private initialOrganisaionId: number;

    private headings: {
        [screen: string]: {
            [tab: string]: {
                title: string;
                heading: string;
                helpText: string;
                hasHelp: boolean;
                helpTitle: string;
            };
        };
    };

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private onboardingService: OnboardingService,
        private dashboardService: DashboardService,
        private router: Router,
        private deviceDetectorService: DeviceDetectorService
    ) {
        this.personAndPersonalOrganisation$ = this.userService.userChange$.pipe(
            filter(
                c =>
                    c.type === UserStatus.LOGGED_IN &&
                    c.currentUsersOrganisations != null
            ),
            map((change: LoggedInUser) => ({
                person: change.currentUser,
                personalOrganisation: change.currentUsersOrganisations.find(
                    o => o.personalOrganisation
                ),
                inExistingOrganisation:
                    change.currentUsersOrganisations.find(
                        o => !o.personalOrganisation
                    ) != null,
                ownsAnOrganisation:
                    change.currentUsersOrganisations.find(
                        o =>
                            o.ownerId === change.currentUser.id &&
                            !o.personalOrganisation
                    ) != null
            }))
        );
        this.personAndPersonalOrganisation$.subscribe(change => {
            this.headings = headings(change.person.firstName);
            this.setTitles();
        });

        combineLatest([
            this.currentScreenAndTab$,
            this.dashboardService.returnToDashboard$
        ]).subscribe(([screenTab, dashboardOptions]) => {
            this.setTitles();
            this.shouldReturnToDashboard = dashboardOptions.shouldReturn;
            this.initialOrganisaionId = dashboardOptions.initialOrganisationId;
            if (
                dashboardOptions.shouldReturn &&
                !(
                    screenTab.tab === OnboardingTab.ORGANISATION_DETAILS ||
                    screenTab.tab === OnboardingTab.ORGANISATION_CERTIFICATION
                )
            ) {
                this.currentScreenAndTabSource.next({
                    screen: OnboardingScreens.ORGANISATION_DETAILS,
                    tab: OnboardingTab.ORGANISATION_DETAILS
                });
            }
            this.router.navigate([
                'onboarding',
                screenTab.screen.toLocaleLowerCase(),
                screenTab.tab.toLowerCase()
            ]);
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.currentScreenAndTabSource.complete();
        this.headingsSource.complete();
        this.businessNameSource.complete();
    }

    setTitles() {
        if (this.headings == null) {
            return;
        }
        const { screen, tab } = this.currentScreenAndTabSource.getValue();
        this.headingsSource.next(this.headings[screen][tab]);
    }

    setBusinessName(businessName: string) {
        this.businessNameSource.next(businessName);
    }

    personalDetailsCompleted(jurisdictionId: number) {
        this.personalJurisdictionId = jurisdictionId;

        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_FLIGHT_PREFERENCES
        });
    }

    personalFlightPreferencesCompleted(isFlying: boolean) {
        this.isFlying = isFlying;
        if (isFlying) {
            this.currentScreenAndTabSource.next({
                screen: OnboardingScreens.PERSONAL_DETAILS,
                tab: OnboardingTab.PERSONAL_NONPRIMARY
            });
        } else {
            this.currentScreenAndTabSource.next({
                screen: OnboardingScreens.PERSONAL_DETAILS,
                tab: OnboardingTab.PERSONAL_SETUP_COMPLETE
            });
        }
    }

    personalNonPrimaryCompleted() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_SETUP_COMPLETE
        });
    }

    personalPrimaryCompleted() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_SETUP_COMPLETE
        });
    }

    flightHistoryCompleted() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_SETUP_COMPLETE
        });
    }

    flyRecreationally() {
        this.markOnboardingAsDone().subscribe(() =>
            this.currentScreenAndTabSource.next({
                screen: OnboardingScreens.COMPLETE,
                tab: OnboardingTab.ORGANISATION_SETUP_COMPLETE
            })
        );
    }

    flyCommercially() {
        this.markOnboardingAsDone().subscribe(() =>
            this.currentScreenAndTabSource.next({
                screen: OnboardingScreens.ORGANISATION_DETAILS,
                tab: OnboardingTab.ORGANISATION_DETAILS
            })
        );
    }

    acceptInvite() {
        this.hasAcceptedInvite = true;
    }

    organisationDetailsCompleted(
        organisationId: number,
        jurisdictionId: number
    ) {
        this.organisationId = organisationId;
        this.commercialJurisdictionId = jurisdictionId;

        this.userService.updateDefaultOrganisation(organisationId);
        this.userService.refreshUsersOrganisations();

        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.ORGANISATION_DETAILS,
            tab: OnboardingTab.ORGANISATION_CERTIFICATION
        });
    }

    organisationCertificationCompleted() {
        if (this.shouldReturnToDashboard) {
            this.dashboardService.shouldReturnToDashboard(false);
            const organisationId =
                this.organisationId ??
                this.userService.getCurrentUser().defaultOrganisationId;
            this.router.navigate(['home'], { queryParams: { organisationId } });
        } else {
            this.currentScreenAndTabSource.next({
                screen: OnboardingScreens.COMPLETE,
                tab: OnboardingTab.ORGANISATION_SETUP_COMPLETE
            });
        }
    }

    backToPersonalDetails() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_DETAILS
        });
    }

    backToPersonalFlyingPreference() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_FLIGHT_PREFERENCES
        });
    }

    backToPersonalNonPrimary() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_NONPRIMARY
        });
    }

    backToPersonalPrimary() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.PERSONAL_PRIMARY
        });
    }

    backToFlightHistory() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.PERSONAL_DETAILS,
            tab: OnboardingTab.FLIGHT_HISTORY
        });
    }

    backFromPersonalCompleted() {
        if (this.isFlying) {
            this.backToPersonalNonPrimary();
        } else {
            this.backToPersonalFlyingPreference();
        }
    }

    backToPersonalSetupCompletion() {
        if (this.shouldReturnToDashboard) {
            const id = this.initialOrganisaionId;
            this.dashboardService.shouldReturnToDashboard(false);
            if (id != null) {
                this.router.navigate(['home'], {
                    queryParams: { organisationId: id }
                });
            } else {
                this.router.navigate(['']);
            }
        } else {
            this.currentScreenAndTabSource.next({
                screen: OnboardingScreens.PERSONAL_DETAILS,
                tab: OnboardingTab.PERSONAL_SETUP_COMPLETE
            });
        }
    }

    backToOrganisationDetails() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.ORGANISATION_DETAILS,
            tab: OnboardingTab.ORGANISATION_DETAILS
        });
    }

    backToOrganisationCertification() {
        this.currentScreenAndTabSource.next({
            screen: OnboardingScreens.ORGANISATION_DETAILS,
            tab: OnboardingTab.ORGANISATION_CERTIFICATION
        });
    }

    createdPersonalDummyData() {
        this.hasCreatedPersonalDummyData = true;
    }

    checkPersonalDummyData() {
        return this.hasCreatedPersonalDummyData;
    }

    createdCommercialDummyData() {
        this.hasCreatedCommercialDummyData = true;
    }

    checkCommercialDummyData() {
        return this.hasCreatedCommercialDummyData;
    }

    getBusinessName() {
        return this.businessNameSource.getValue();
    }

    organisationSetupCompleted() {
        if (!this.deviceDetectorService.isMobile()) {
            this.router.navigate(['home'], {
                queryParams: { organisationId: this.organisationId }
            });
        } else {
            if (this.hasAcceptedInvite) {
                this.currentScreenAndTabSource.next({
                    screen: OnboardingScreens.COMPLETE,
                    tab: OnboardingTab.FIELD_APP_DOWNLOAD
                });
            } else {
                this.currentScreenAndTabSource.next({
                    screen: OnboardingScreens.COMPLETE,
                    tab: OnboardingTab.SWITCH_TO_DESKTOP
                });
            }
        }
    }

    acceptMobileWarning() {
        this.router.navigate(['']);
    }

    private markOnboardingAsDone() {
        return this.onboardingService.markUserOnboardingAsDone();
    }
}
