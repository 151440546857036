<div *ngIf="notification != null">
    <div class="notification-card">
        <div class="horizontal-container fill">
            <div
                class="fcol-2 notification-icon"
                [ngClass]="{ 'icon-hover-states': !notification.read }"
            >
                <div class="vertical-container notification-date-icon">
                    <div class="notification-day">{{ day }}</div>
                    <div class="notification-month">{{ month }}</div>
                </div>
                <a
                    *ngIf="!notification.read"
                    class="notification-archive-button"
                    (click)="archiveNotification()"
                    tooltip="Archive"
                    placement="top"
                >
                    <span class="fal fa-check"></span>
                </a>
            </div>
            <div class="vertical-container fill" style="cursor: pointer">
                <div class="notification-heading" (click)="goToSubject()">
                    <span class="notification-title">
                        {{ notification.action }}
                    </span>
                    <span class="notification-subtitle">
                        {{ notification.subject }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
