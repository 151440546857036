import { Injectable } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    BatterySetDto,
    BatterySetService,
    CompleteMissionCommand,
    CompleteSortieCommand,
    CraftDto,
    CraftService,
    DisplayableMissionDto,
    EquipmentDto,
    EquipmentService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    fromTimestamp,
    MaintenanceLogDto,
    MaintenanceService,
    MissionRoleDto,
    MissionRoleService,
    PersonRolesDto,
    PersonService,
    RpaTypeDto,
    RpaTypesService,
    ServiceabilitySignoffCommand,
    ServiceabilitySignoffDto,
    SimplePersonDto,
    SortieDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { CommonDialoguesService } from 'libs/common-dialogues/src/lib/common-dialogues.service';
import * as moment from 'moment';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface MissionCompletionFormModel {
    outcome: FormControl<CompleteMissionCommand.Outcome>;
    objectiveOutcomeNotes: FormControl<string>;
    actualStartTime: FormControl<Date>;
    actualEndTime: FormControl<Date>;
    actualLocationId: FormControl<number>;
    journal: FormControl<string>;
    documentation: FormControl<any>;
    flights: FormArray<FormControl<CompleteSortieCommand>>;
    serviceabilitySignoffs: FormArray<FormGroup<ServiceabilitySignoffForm>>;
    maintenanceLogs: FormControl<MaintenanceLogDto[]>;
    serviceability: FormControl<ServiceabilitySignoffDto[]>;
}

export interface MissionCompletionFormValue {
    outcome: CompleteMissionCommand.Outcome;
    objectiveOutcomeNotes: string;
    actualStartTime: Date;
    actualEndTime: Date;
    actualLocationId: number;
    journal: string;
    documentation: any;
    flights: CompleteSortieCommand[];
    serviceabilitySignoffs: ServiceabilitySignoffFields[];
    maintenanceLogs: MaintenanceLogDto[];
    serviceability: ServiceabilitySignoffDto[];
}

export interface DisplayableResource {
    id: number;
    name: string;
}

export interface ServiceabilitySignoffForm {
    rpaId: FormControl<number>;
    unlistedRpaId: FormControl<string>;
    name: FormControl<string>;
    serviceability: FormControl<ServiceabilitySignoffCommand.Serviceability>;
    reason: FormControl<string>;
    signoffPerson: FormControl<SimplePersonDto>;
    signoffTime: FormControl<string>;
    flown: FormControl<boolean>;
    maintenanceLogId: FormControl<number>;
}

export interface ServiceabilitySignoffFields {
    rpaId: number;
    unlistedRpaId: string;
    name: string;
    serviceability: ServiceabilitySignoffCommand.Serviceability;
    reason: string;
    signoffPerson: SimplePersonDto;
    signoffTime: string;
    flown: boolean;
    maintenanceLogId: number;
}

@Injectable()
export class MissionCompletionService {
    public form: FormGroup<MissionCompletionFormModel>;

    private allRpasSubject = new ReplaySubject<CraftDto[]>();
    private allRpaTypesSubject = new ReplaySubject<RpaTypeDto[]>();
    private allBatterySetsSubject = new ReplaySubject<BatterySetDto[]>();
    private allPersonnelSubject = new ReplaySubject<PersonRolesDto[]>();
    private allEquipmentSubject = new ReplaySubject<EquipmentDto[]>();
    private availableRpaSubject = new ReplaySubject<CraftDto[]>();
    private availablePersonnelSubject = new ReplaySubject<
        DisplayableResource[]
    >();
    private availableBatterySetsSubject = new ReplaySubject<BatterySetDto[]>();
    private availableEquipmentSubject = new ReplaySubject<EquipmentDto[]>();
    private allUnlistedLinkedSubject = new ReplaySubject<boolean>();
    private allMaintenanceSubmittedSubject = new ReplaySubject<boolean>();
    private missionRolesSubject = new ReplaySubject<MissionRoleDto[]>(1);

    allRpas$ = this.allRpasSubject.asObservable();
    allRpaTypes$ = this.allRpaTypesSubject.asObservable();
    allBatterySets$ = this.allBatterySetsSubject.asObservable();
    allPersonnel$ = this.allPersonnelSubject.asObservable();
    allEquipment$ = this.allEquipmentSubject.asObservable();

    availableRpas$ = this.availableRpaSubject.asObservable();
    availablePersonnel$ = this.availablePersonnelSubject.asObservable();
    availableBatterySets$ = this.availableBatterySetsSubject.asObservable();
    availableEquipment$ = this.availableEquipmentSubject.asObservable();

    allUnlistedLinked$ = this.allUnlistedLinkedSubject.asObservable();
    allMaintenanceSubmitted$ =
        this.allMaintenanceSubmittedSubject.asObservable();
    missionRoles$ = this.missionRolesSubject.asObservable();

    private savedMissionSubject = new ReplaySubject<DisplayableMissionDto>(1);
    savedMission$ = this.savedMissionSubject.asObservable();

    private get flights() {
        return this.form.controls.flights;
    }

    working: boolean;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        fb: FormBuilder,
        private craftService: CraftService,
        private rpaTypeService: RpaTypesService,
        private batterySetService: BatterySetService,
        private personService: PersonService,
        private equipmentService: EquipmentService,
        private maintenanceService: MaintenanceService,
        private missionRoleService: MissionRoleService,
        private commonDialoguesService: CommonDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.form = new FormGroup<MissionCompletionFormModel>({
            outcome: new FormControl(undefined, [Validators.required]),
            objectiveOutcomeNotes: new FormControl(undefined),
            actualStartTime: new FormControl(undefined),
            actualEndTime: new FormControl(undefined),
            journal: new FormControl(undefined),
            actualLocationId: new FormControl(undefined),
            documentation: new FormControl(undefined, Validators.required),
            flights: new FormArray([]),
            serviceabilitySignoffs: new FormArray([]),
            maintenanceLogs: new FormControl(undefined),
            serviceability: new FormControl(undefined)
        });

        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.allRpasSubject.complete();
        this.allRpaTypesSubject.complete();
        this.allBatterySetsSubject.complete();
        this.allPersonnelSubject.complete();
        this.availableRpaSubject.complete();
        this.availablePersonnelSubject.complete();
        this.availableBatterySetsSubject.complete();
        this.allUnlistedLinkedSubject.complete();
        this.allMaintenanceSubmittedSubject.complete();
        this.missionRolesSubject.complete();
        this.savedMissionSubject.complete();
    }

    setupMission(mission: DisplayableMissionDto, organisationId: number) {
        this.refreshLists(mission, organisationId);
        this.form.patchValue({
            outcome: mission.outcome,
            journal: mission.completionMessage,
            objectiveOutcomeNotes: mission.objectiveOutcomeNotes,
            actualLocationId: mission.actualLocationId || mission.locationId,
            actualStartTime: fromTimestamp(
                mission.actualStartTime ?? mission.missionDate,
                mission.timeZone
            ),
            actualEndTime: fromTimestamp(
                mission.actualEndTime ??
                    moment(mission.missionDate)
                        .add(mission.missionEstimatedTime, 'seconds')
                        .toISOString(),
                mission.timeZone
            ),
            maintenanceLogs: mission.maintenanceLogs,
            serviceability: mission.serviceabilitySignoffs
        });

        while (this.form.controls.flights.length > 0) {
            this.form.controls.flights.removeAt(0);
        }

        mission.sorties.forEach(f => this.addFlight(f));

        this.allUnlistedLinkedSubject.next(
            mission.unlistedBatterySets.reduce(
                (acc, u) => (acc && u.batterySetId != null) || u.ignored,
                true
            ) &&
                mission.unlistedPersonnel.reduce(
                    (acc, u) => (acc && u.personId != null) || u.ignored,
                    true
                ) &&
                mission.unlistedRpas.reduce(
                    (acc, u) => (acc && u.rpaId != null) || u.ignored,
                    true
                ) &&
                mission.unlistedEquipment.reduce(
                    (acc, u) => (acc && u.equipmentId != null) || u.ignored,
                    true
                )
        );

        this.refreshMaintenanceSubmitted();

        this.savedMissionSubject.next(mission);
    }

    refreshLists(mission: DisplayableMissionDto, organisationId: number) {
        forkJoin([
            this.personService.findPersonnel(organisationId),
            this.craftService.findCrafts(organisationId),
            this.rpaTypeService.findRpaTypes(organisationId),
            this.batterySetService.findBatterySets(
                organisationId,
                mission.missionDate
            ),
            this.equipmentService.find(organisationId),
            this.missionRoleService.findMissionRoles(organisationId)
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    personnel,
                    craft,
                    rpaTypes,
                    batterySets,
                    equipment,
                    roles
                ]) => {
                    this.allPersonnelSubject.next(personnel);
                    this.allRpasSubject.next(craft);
                    this.allRpaTypesSubject.next(rpaTypes);
                    this.allBatterySetsSubject.next(batterySets);
                    this.allEquipmentSubject.next(equipment);
                    this.missionRolesSubject.next(roles);
                    this.updateAvailableRpas(mission, craft);
                    this.updateAvailablePersonnel(mission, personnel);
                    this.updateAvailableBatterySets(batterySets);
                    this.updateAvailableEquipment(mission, equipment);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while fetching resources: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    refreshServiceabilitySignoffs(mission: DisplayableMissionDto) {
        while (this.form.controls.serviceabilitySignoffs.length > 0) {
            this.form.controls.serviceabilitySignoffs.removeAt(0);
        }

        const formSignoffs = this.form.controls.serviceabilitySignoffs;
        const sorties = this.form.controls.flights.value;
        mission.crafts.map(craft => {
            const signoff =
                mission.serviceabilitySignoffs?.find(
                    s => s.rpaId != null && s.rpaId === craft.id
                ) ?? null;
            const flown = this.checkIfFlown(sorties, craft.id);
            if (signoff == null) {
                const command = new FormGroup<ServiceabilitySignoffForm>({
                    name: new FormControl(craft.nickname),
                    rpaId: new FormControl(craft.id, Validators.required),
                    reason: new FormControl(),
                    serviceability: new FormControl(),
                    unlistedRpaId: new FormControl(),
                    signoffPerson: new FormControl(),
                    signoffTime: new FormControl(),
                    flown: new FormControl(flown),
                    maintenanceLogId: new FormControl()
                });
                if (flown === true) {
                    command.controls.serviceability.setValidators(
                        Validators.required
                    );
                    command.controls.serviceability.updateValueAndValidity();
                }
                formSignoffs.push(command);
            } else {
                const reason = this.findServiceabilityReason(
                    signoff.maintenanceLogId,
                    mission.maintenanceLogs
                );
                const serviceability = signoff.serviceability ?? null;
                const command = new FormGroup<ServiceabilitySignoffForm>({
                    name: new FormControl(craft.nickname),
                    rpaId: new FormControl(craft.id, Validators.required),
                    reason: new FormControl(reason),
                    serviceability: new FormControl(serviceability),
                    unlistedRpaId: new FormControl(),
                    signoffPerson: new FormControl(signoff.signoffPerson),
                    signoffTime: new FormControl(signoff.signoffTime),
                    flown: new FormControl(flown),
                    maintenanceLogId: new FormControl(signoff.maintenanceLogId)
                });
                if (flown === true) {
                    command.controls.serviceability.setValidators(
                        Validators.required
                    );
                    command.controls.serviceability.updateValueAndValidity();
                }
                formSignoffs.push(command);
            }
        });
        mission.unlistedRpas.map(craft => {
            if (
                craft.rpaId != null &&
                mission.crafts.findIndex(r => r.id === craft.rpaId) === -1
            ) {
                const signoff =
                    mission.serviceabilitySignoffs.find(
                        s =>
                            s.rpaId != null &&
                            (s.unlistedRpaId === craft.id ||
                                s.rpaId === craft.rpaId)
                    ) ?? null;
                const flown = this.checkIfFlown(
                    sorties,
                    craft.rpaId ?? null,
                    craft.id
                );
                if (signoff == null) {
                    const command = new FormGroup<ServiceabilitySignoffForm>({
                        name: new FormControl(craft.name),
                        rpaId: new FormControl(
                            craft.rpaId ?? null,
                            Validators.required
                        ),
                        reason: new FormControl(),
                        serviceability: new FormControl(),
                        unlistedRpaId: new FormControl(craft.id),
                        signoffPerson: new FormControl(),
                        signoffTime: new FormControl(),
                        flown: new FormControl(flown),
                        maintenanceLogId: new FormControl()
                    });
                    if (flown === true) {
                        command.controls.serviceability.setValidators(
                            Validators.required
                        );
                        command.controls.serviceability.updateValueAndValidity();
                    }
                    formSignoffs.push(command);
                } else {
                    const reason = this.findServiceabilityReason(
                        signoff.maintenanceLogId,
                        mission.maintenanceLogs
                    );
                    const serviceability = signoff.serviceability ?? null;
                    const command = new FormGroup<ServiceabilitySignoffForm>({
                        name: new FormControl(craft.name),
                        rpaId: new FormControl(
                            craft.rpaId ?? null,
                            Validators.required
                        ),
                        reason: new FormControl(reason),
                        serviceability: new FormControl(serviceability),
                        unlistedRpaId: new FormControl(craft.id),
                        signoffPerson: new FormControl(signoff.signoffPerson),
                        signoffTime: new FormControl(signoff.signoffTime),
                        flown: new FormControl(flown),
                        maintenanceLogId: new FormControl(
                            signoff.maintenanceLogId
                        )
                    });
                    if (flown === true) {
                        command.controls.serviceability.setValidators(
                            Validators.required
                        );
                        command.controls.serviceability.updateValueAndValidity();
                    }
                    formSignoffs.push(command);
                }
            }
        });
    }

    checkIfFlown(
        sorties: SortieDto[] | CompleteSortieCommand[],
        craftId: number,
        unlistedCraftId?: string
    ) {
        const flown: boolean =
            sorties.findIndex(s => {
                if (s.craftId != null && craftId != null) {
                    return s.craftId === craftId;
                } else if (
                    s.unlistedRpa?.id != null &&
                    unlistedCraftId != null
                ) {
                    return s.unlistedRpa.id === unlistedCraftId;
                } else {
                    return -1;
                }
            }) !== -1;
        return flown;
    }

    refreshMaintenanceSubmitted() {
        if (this.form.controls.maintenanceLogs.value != null) {
            this.allMaintenanceSubmittedSubject.next(
                this.form.controls.maintenanceLogs.value.reduce(
                    (acc, l) =>
                        acc &&
                        (l.status === MaintenanceLogDto.Status.COMPLETED ||
                            l.status === MaintenanceLogDto.Status.CANCELLED ||
                            l.status === MaintenanceLogDto.Status.REQUESTED),
                    true
                )
            );
        } else {
            this.allMaintenanceSubmittedSubject.next(true);
        }
    }

    findServiceabilityReason(
        maintenanceId?: number,
        maintenanceLogs?: MaintenanceLogDto[]
    ) {
        if (
            maintenanceId == null ||
            maintenanceLogs == null ||
            maintenanceLogs.length === 0
        ) {
            return null;
        }
        const log = maintenanceLogs.find(l => l.id === maintenanceId);
        return log.reasonForRequest;
    }

    updateAvailableRpas(mission: DisplayableMissionDto, allRpas: CraftDto[]) {
        const assignedRpas = mission.unlistedRpas.filter(
            r =>
                r.rpaId != null &&
                mission.crafts.findIndex(c => c.id === r.rpaId) === -1
        );

        const linked = assignedRpas
            .map(c => allRpas.find(r => r.id === c.rpaId))
            .filter(c => c != null);

        const available = mission.crafts.concat(linked);
        this.availableRpaSubject.next(available);
    }

    updateAvailablePersonnel(
        mission: DisplayableMissionDto,
        personnel: PersonRolesDto[]
    ) {
        const assignedPersonnel = mission.unlistedPersonnel.filter(
            p =>
                p.personId != null &&
                mission.missionCrewDetails.findIndex(
                    pers => pers.person.id === p.personId
                ) === -1
        );
        const linked = assignedPersonnel
            .map(p => personnel.find(pers => pers.id === p.personId))
            .filter(p => p != null);

        const available = mission.missionCrewDetails
            .map(person => ({
                id: person.person.id,
                name: `${person.person.firstName} ${person.person.lastName}`
            }))
            .concat(
                linked.map(person => ({
                    id: person.id,
                    name: `${person.firstName} ${person.lastName}`
                }))
            );
        this.availablePersonnelSubject.next(available);
    }

    updateAvailableBatterySets(batterySets: BatterySetDto[]) {
        this.availableBatterySetsSubject.next(batterySets);
    }

    updateAvailableEquipment(
        mission: DisplayableMissionDto,
        allEquipment: EquipmentDto[]
    ) {
        const assigned = mission.unlistedEquipment.filter(
            r =>
                r.equipmentId != null &&
                mission.equipment.findIndex(eq => eq.id === r.equipmentId) ===
                    -1
        );

        const linked = assigned
            .map(c => allEquipment.find(eq => eq.id === c.equipmentId))
            .filter(c => c != null);

        const available = mission.equipment.concat(linked);
        this.availableEquipmentSubject.next(available);
    }

    updateFlights(flights: SortieDto[]) {
        flights.forEach((f, ix) => {
            const value = this.flights.controls[ix].value;
            if (f.durationSource === SortieDto.DurationSource.LOGS) {
                this.flights.controls[ix].patchValue({
                    ...value,
                    durationSource: f.durationSource,
                    duration: f.duration
                });
            } else {
                this.flights.controls[ix].patchValue({
                    ...value,
                    durationSource: f.durationSource
                });
            }
        });
    }

    updateFlight(flight: SortieDto) {
        if (
            flight == null ||
            this.flights == null ||
            this.flights.length === 0
        ) {
            return;
        }
        for (let ix = 0; ix < this.flights.length; ix++) {
            const currentValue = this.flights.at(ix).value;
            if (flight.id === currentValue.id) {
                this.flights.at(ix).setValue({
                    ...currentValue,
                    status: flight.status,
                    craftId: flight.craftId,
                    duration: flight.duration,
                    durationSource: flight.durationSource,
                    batterySetId: flight.batterySetId
                });
                return;
            }
        }
        // FIXME maybe create the flight if it doesn't exist?
    }

    updateMaintenanceLog(log: MaintenanceLogDto) {
        const logs = this.form.controls.maintenanceLogs.value;
        const index = logs.findIndex(l => l.id === log.id);
        logs[index] = log;
        this.form.patchValue({
            maintenanceLogs: logs
        });
        this.refreshMaintenanceSubmitted();
    }

    private createFlightControl(s: SortieDto) {
        return new FormControl<CompleteSortieCommand>(
            // @ts-ignore - missing overlap is non-breaking for this call
            s,
            [Validators.required]
        );
    }

    addFlight(s: SortieDto): void {
        const flights = this.form.controls.flights;
        flights.push(this.createFlightControl(s));
    }

    deleteFlight(index: number) {
        this.form.controls.flights.removeAt(index);
    }

    deleteAllFlights() {
        while (this.form.controls.flights.length > 0) {
            this.form.controls.flights.removeAt(0);
        }
    }

    submitMaintenanceRequest(id: number) {
        this.commonDialoguesService.showConfirmationDialogue(
            'Submit Maintenance Request',
            'Are you sure you wish to submit this maintenance request?',
            'Submit',
            () =>
                new Promise<void>(res => {
                    this.maintenanceService
                        .submitMaintenanceLog(id)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe(
                            log => {
                                this.updateMaintenanceLog(log);
                                this.logging.success(
                                    'Maintenance Request Submitted'
                                );
                            },
                            (error: FlyFreelyError) =>
                                this.logging.error(
                                    error,
                                    `Error submitting maintenance: ${error.message}`
                                )
                        )
                        .add(this.workTracker.createTracker());
                    res();
                })
        );
    }

    cancelMaintenanceRequest(id: number) {
        this.commonDialoguesService.showConfirmationDialogue(
            'Cancel Maintenance Request',
            'Are you sure you wish to cancel this maintenance request?',
            'Cancel',
            () =>
                new Promise<void>(res => {
                    this.maintenanceService
                        .cancelMaintenanceLog(id)
                        .pipe(takeUntil(this.ngUnsubscribe$))
                        .subscribe(
                            log => {
                                this.updateMaintenanceLog(log);
                                this.logging.success(
                                    'Maintenance Request Cancelled'
                                );
                            },
                            (error: FlyFreelyError) =>
                                this.logging.error(
                                    error,
                                    `Error cancelling maintenance: ${error.message}`
                                )
                        )
                        .add(this.workTracker.createTracker());
                    res();
                })
        );
    }
}
