import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AuthorityTypeDto,
    CraftAuthorityDto,
    CraftAuthorityGroup,
    CraftDto,
    NameId,
    RpaAuthorityService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import {
    findNewJurisdictions,
    reduceToGroupWithOneAuthority
} from 'libs/authorities/src/lib/helpers';
import { AuthorityGroupEditService } from 'libs/authorities/src/lib/registers/interfaces';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RpaAuthorityEditService } from '../rpa-authority-edit/authority-edit.service';

const GLOBAL_JURISDICTION_ID = -1;

@Component({
    selector: 'authority-list',
    templateUrl: './authority-list.component.html',
    providers: [
        {
            provide: AuthorityGroupEditService,
            useClass: RpaAuthorityEditService
        }
    ]
})
export class AuthorityListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() rpa: CraftDto;
    @Input() managingOrganisationId: number;
    @Input() canEdit: boolean;
    @Output() edit = new EventEmitter<void>();

    visibleAuthorities: {
        [jurisdictionId: number]: CraftAuthorityGroup[];
    } = {};

    allArchived = false;

    authorities: CraftAuthorityGroup[];
    inUseJurisdictions: NameId[];
    canEditAny: boolean;
    entityType = 'RPA';

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private rpaAuthorityService: RpaAuthorityService,
        private authorityDialoguesService: AuthorityDialoguesService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.authorities = [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('rpa' in changes) {
            this.refreshAuthorities();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshAuthorities() {
        if (this.rpa == null) {
            return;
        }
        this.rpaAuthorityService
            .findAuthorities(this.rpa.id, this.managingOrganisationId)
            .subscribe(results => {
                this.loadAuthorities(results);
            })
            .add(this.workTracker.createTracker());
    }

    private loadAuthorities(authorities: CraftAuthorityGroup[]) {
        const sortedAuthorities = authorities.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        this.authorities = sortedAuthorities.filter(
            e => e.authorities != null && e.authorities.length > 0
        );
        this.inUseJurisdictions = this.authorities.reduce(
            (acc, e) => findNewJurisdictions(acc, e.jurisdiction, GLOBAL_JURISDICTION_ID),
            []
        );
        this.canEditAny =
            authorities.findIndex(e => e.availableActions.canCreate) !== -1;

        this.updateVisibleAuthorities();
    }

    updateVisibleAuthorities() {
        this.visibleAuthorities = {};
        this.inUseJurisdictions.forEach(j => {
            const visibleInJurisdiction = this.authorities
                .filter(a => this.isInJurisdiction(a, j.id))
                .filter(
                    a =>
                        a.availableActions.canCreate ||
                        a.authorities?.length > 0
                )
                .filter(a => !this.areAllExpiredOrArchived(a));
            if (this.visibleAuthorities == null) {
                this.visibleAuthorities = {
                    [j.id]: visibleInJurisdiction.reduce(
                        (acc, g) =>
                            acc.concat(reduceToGroupWithOneAuthority(g)),
                        []
                    )
                };
            } else {
                this.visibleAuthorities[j.id] = visibleInJurisdiction.reduce(
                    (acc, g) => acc.concat(reduceToGroupWithOneAuthority(g)),
                    []
                );
            }
        });
        this.allArchived = Object.keys(this.visibleAuthorities).reduce(
            (acc, j) =>
                acc &&
                (this.visibleAuthorities[j].length === 0 ||
                    this.visibleAuthorities[j].filter(
                        a => a.authorities[0].archived
                    ).length === this.visibleAuthorities[j].length),
            true
        );
    }

    areAllExpiredOrArchived(authorityGroup: CraftAuthorityGroup) {
        const expired = authorityGroup.authorities.filter(
            a => this.hasExpired(a) || a.archived
        );

        return expired.length === authorityGroup.authorities.length;
    }

    hasExpired(authority: CraftAuthorityDto) {
        return (
            authority.expiryDate != null &&
            moment(authority.expiryDate).isBefore(
                moment(moment().format('YYYY-MM-DD'))
            )
        );
    }

    isInJurisdiction(authority: CraftAuthorityGroup, jurisdictionId: number) {
        if (jurisdictionId === GLOBAL_JURISDICTION_ID) {
            return authority.jurisdiction == null;
        }
        return (
            authority.jurisdiction != null &&
            authority.jurisdiction.id === jurisdictionId
        );
    }

    showAuthority(
        authorityType: AuthorityTypeDto,
        authority: CraftAuthorityDto
    ) {
        const modal = this.authorityDialoguesService.showAuthorityDetails(
            this.managingOrganisationId,
            authorityType,
            authority,
            '/icons/icon-rpa.png',
            false,
            false,
            null,
            true
        );
    }

    onEdit() {
        this.edit.emit();
    }
}
