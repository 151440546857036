import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { AttachmentsModule } from 'libs/attachments/src/lib/attachments.module';
import { AuthorityModule } from 'libs/authorities/src/lib/authorities.module';
import { BulkUploadsModule } from 'libs/bulk-uploads/src/lib/bulk-uploads.module';
import { CommonDialoguesModule } from 'libs/common-dialogues/src/lib/common-dialogues.module';
import { DocumentationModule } from 'libs/documentation/src/lib/documentation.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { FlightHistoryModule } from 'libs/flight-history/src/lib/flight-history.module';
import { EquipmentService } from '@flyfreely-portal-ui/flyfreely';
import { FlyFreelyTableModule } from '@flyfreely-portal-ui/flyfreely-table';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { MaintenanceModule } from 'libs/maintenance/src/lib/maintenance.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { WorkflowModule } from 'libs/workflows/src/lib/workflows.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ngfModule } from 'angular-file';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { CreateEquipmentTypeDialogue } from './add/create-equipment-type-dialogue.component';
import { EquipmentAddDialogue } from './add/equipment-add.component';
import { EquipmentEdit } from './edit/equipment-edit.component';
import { EquipmentDialogue } from './equipment-dialogue.component';
import { EquipmentDialoguesService } from './equipment-dialogues.service';
import { EquipmentView } from './view/equipment-view.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ResourceUiModule } from '@flyfreely-portal-ui/resource-ui';
import { NotesEditComponent } from 'libs/equipment/src/lib/equipment-notes/notes-edit/notes-edit.component';
import { NotesViewComponent } from 'libs/equipment/src/lib/equipment-notes/notes-view/notes-view.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // ngx-bootstrap
        TooltipModule,
        ModalModule.forChild(),
        TabsModule,
        BsDropdownModule,
        BsDatepickerModule,
        ButtonsModule,
        // ngx-formly
        ReactiveFormsModule,
        FormlyModule,
        FormlyBootstrapModule,
        // ng-select
        NgSelectModule,
        ngfModule,
        ToastrModule,
        SharedUiModule,
        DocumentationModule,
        WorkflowModule,
        FlightHistoryModule,
        AttachmentsModule,
        LoadingIndicatorModule,
        AuthorityModule,
        MaintenanceModule,
        CommonDialoguesModule,
        ValidationModule,
        FlyFreelyTableModule,
        EnhancedHelpModule,
        AnalyticsModule,
        MaterialModule,
        BulkUploadsModule,
        ResourceUiModule
    ],
    providers: [EquipmentService, EquipmentDialoguesService],
    bootstrap: [],
    declarations: [
        EquipmentDialogue,
        EquipmentEdit,
        EquipmentView,
        EquipmentAddDialogue,
        CreateEquipmentTypeDialogue,
        NotesEditComponent,
        NotesViewComponent
    ],
    exports: []
})
export class EquipmentModule {}
