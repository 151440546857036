import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Organisation } from '@flyfreely-portal-ui/flyfreely';
import { GeneralTask } from 'libs/flyfreely/src/lib/services/generalTasks.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { GeneralTaskEditComponent } from '../general-task-edit/general-task-edit.component';

@Component({
    selector: 'general-task-details-dialogue',
    templateUrl: './general-task-details-dialogue.component.html',
    styleUrls: ['./general-task-details-dialogue.component.css']
})
export class GeneralTaskDetailsDialogueComponent implements OnInit {
    @Input() organisation: Organisation;
    @Input() task: GeneralTask;
    @ViewChild('batteryEdit', { static: false })
    batteryEdit: GeneralTaskEditComponent;

    isEdit = false;

    constructor(
        private modal: BsModalRef<GeneralTaskDetailsDialogueComponent>,
        modalOptions: ModalOptions
    ) {
        modalOptions.closeInterceptor = () => {
            if (this.isEdit) {
                this.batteryEdit.onCancel();
                return Promise.reject();
            }
            return Promise.resolve();
        };
    }

    ngOnInit(): void {}

    onCancel() {
        this.modal.hide();
    }

    toggleViewEdit() {
        this.isEdit = !this.isEdit;
    }

    onTaskDeleted() {
        this.modal.hide();
    }

    taskUpdated(task: GeneralTask) {
        this.task = Object.assign({}, task);
        this.toggleViewEdit();
    }
}
