<tabset vertical="true" type="pills" class="tab-set-vertical" *ngIf="ready">
    <tab *ngFor="let step of steps">
        <ng-template tabHeading>
            {{ step.name }}
            <span class="badge pull-right">
                {{ countOf(step.value) }}
            </span>
        </ng-template>
        <h5>
            <button
                type="button"
                *ngIf="step.editable"
                (click)="addRow(mappedForms[step.value])"
                [disabled]="disabled"
                class="btn-circle text-center"
            >
                <span class="fa fa-plus"></span>
            </button>
            Forms
        </h5>
        <ng-container
            *ngIf="
                additionalDocumentation && additionalDocumentation[step.value]
            "
        >
            <p
                *ngFor="
                    let authorityForm of additionalDocumentation[step.value]
                        .forms
                "
            >
                * {{ authorityForm.mappedEntity.name }} from
                {{ authorityForm.from }}
                <button
                    type="button"
                    class="btn-tertiary btn-sm"
                    (click)="previewForm(authorityForm.mappedEntity)"
                >
                    <span class="fal fa-eye"></span>
                </button>
            </p>
        </ng-container>
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>Required</th>
                    <th>Pre-fill</th>
                    <th style="width: 60%">Form</th>
                </tr>
            </thead>
            <tbody cdkDropList [cdkDropListData]="mappedForms[step.value]" (cdkDropListDropped)="drop($event, mappedForms[step.value])">
                <tr
                    *ngFor="
                        let missionForm of mappedForms[step.value];
                        let index = index
                    "
                    cdkDrag
                >
                    <td class="grab-handle small">
                        <span
                            class="fas fa-grip-vertical fa-2x row-hover grab-handle" cdkDragHandle
                        ></span>
                    </td>
                    <td class="select">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    [ngModelOptions]="{ standalone: true }"
                                    [(ngModel)]="missionForm.required"
                                    [disabled]="disabled"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                            </label>
                        </div>
                    </td>
                    <td class="select">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    [ngModelOptions]="{ standalone: true }"
                                    [(ngModel)]="missionForm.prefill"
                                    [disabled]="disabled"
                                />
                                <span class="cr"
                                    ><i
                                        class="cr-icon glyphicon glyphicon-ok"
                                    ></i
                                ></span>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                            <ng-select
                                [(ngModel)]="missionForm.id"
                                [disabled]="disabled"
                                [ngModelOptions]="{ standalone: true }"
                                [clearable]="false"
                            >
                                <ng-option [value]="null">
                                    Choose Form
                                </ng-option>
                                <ng-option
                                    *ngFor="
                                        let form of getForms(missionForm.id)
                                    "
                                    [value]="form.id"
                                    >{{ form.name }}</ng-option
                                >
                            </ng-select>
                            <span class="input-group-btn">
                                <button
                                    type="button"
                                    class="btn-tertiary btn-sm"
                                    (click)="previewForm(missionForm)"
                                    [disabled]="missionForm.id == null"
                                >
                                    <span class="fal fa-eye"></span>
                                </button>
                            </span>
                        </div>
                    </td>
                    <td>
                        <button
                            type="button"
                            (click)="deleteRow(mappedForms[step.value], index)"
                            class="btn btn-tertiary btn-delete pull-right"
                            [disabled]="disabled"
                        >
                            <span class="fal fa-trash-alt"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <h5>
            <button
                type="button"
                *ngIf="step.editable"
                (click)="
                    addAttachmentRow(mappedAttachmentRequirements[step.value])
                "
                [disabled]="disabled"
                class="btn-circle text-center"
            >
                <span class="fa fa-plus"></span>
            </button>
            Attachments
        </h5>

        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Instructions</th>
                    <th>Min. Instances</th>
                    <th>Max. Instances</th>
                </tr>
            </thead>
            <tbody cdkDropList [cdkDropListData]="mappedAttachmentRequirements[step.value]" (cdkDropListDropped)="drop($event, mappedAttachmentRequirements[step.value])">
                <tr
                    *ngFor="
                        let requiredAttachment of mappedAttachmentRequirements[step.value];
                        let index = index
                    "
                     cdkDrag
                >
                    <td class="grab-handle small">
                        <span
                            class="fas fa-grip-vertical fa-2x row-hover grab-handle" cdkDragHandle
                        ></span>
                    </td>
                    <td>
                        <input
                            type="text"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            required
                            [disabled]="disabled"
                            [(ngModel)]="requiredAttachment.name"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            required
                            [disabled]="disabled"
                            [(ngModel)]="requiredAttachment.instructions"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            required
                            [disabled]="disabled"
                            min="0"
                            step="1"
                            [(ngModel)]="requiredAttachment.minimumInstances"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            [disabled]="disabled"
                            min="0"
                            step="1"
                            [(ngModel)]="requiredAttachment.maximumInstances"
                        />
                    </td>
                    <td>
                        <button
                            type="button"
                            [disabled]="disabled"
                            (click)="
                                deleteRow(
                                    mappedAttachmentRequirements[step.value],
                                    index
                                )
                            "
                            class="btn btn-tertiary btn-delete pull-right"
                        >
                            <span class="fal fa-trash-alt"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="hasApproverChecks && step.value == 'pre-submit'">
            <h5>
                <button
                    type="button"
                    *ngIf="step.editable"
                    (click)="addApproverCheck(mappedApproverChecks[step.value])"
                    [disabled]="disabled"
                    class="btn-circle text-center"
                >
                    <span class="fa fa-plus"></span>
                </button>
                Approver Checks
            </h5>

            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Check</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody cdkDropList [cdkDropListData]="mappedApproverChecks[step.value]" (cdkDropListDropped)="drop($event, mappedApproverChecks[step.value])">
                    <tr
                        *ngFor="
                            let approverCheck of mappedApproverChecks[
                                step.value
                            ];
                            let index = index
                        "
                        cdkDrag
                    >
                        <td class="grab-handle small">
                            <span
                                class="fas fa-grip-vertical fa-2x row-hover grab-handle" cdkDragHandle
                            ></span>
                        </td>
                        <td>
                            <input
                                type="text"
                                [ngModelOptions]="{ standalone: true }"
                                class="form-control"
                                required
                                [disabled]="disabled"
                                [(ngModel)]="approverCheck.check"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                [ngModelOptions]="{ standalone: true }"
                                class="form-control"
                                [disabled]="disabled"
                                [(ngModel)]="approverCheck.description"
                            />
                        </td>
                        <td>
                            <button
                                type="button"
                                [disabled]="disabled"
                                (click)="
                                    deleteRow(
                                        mappedApproverChecks[step.value],
                                        index
                                    )
                                "
                                class="btn btn-tertiary btn-delete pull-right"
                            >
                                <span class="fal fa-trash-alt"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </tab>
</tabset>
