import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { findActiveApprovals } from 'libs/missions/src/lib/helpers';
import { Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GqlQueryResult, Pagination } from '.';
import { FlyFreelyConstants } from '../constants';
import {
    AirspaceAuthorisationDto,
    CompletedDocumentationDto,
    CompleteMissionCommand,
    CreateHistoricalMissionCommand,
    CreateMissionCommand,
    DisplayableMissionDto,
    FinaliseMissionCommand,
    FlightConformanceResultDto,
    FormResponseCommand,
    LinkUnlistedEntityCommand,
    MissionDocumentationDto,
    MissionMessageDto,
    MissionOperationTypeDto,
    MissionSummaryDto,
    PersonsOrganisationDto,
    RequestersMissionApprovalDto,
    ServiceabilitySignoffCommand,
    SortieDetailsDto,
    SortieDto,
    UpdateHistoricalMissionCommand,
    UpdateMissionCommand,
    UpdateMissionDocumentationCommand
} from '../model/api';
import { NameValue } from '../model/ui';
import { StepDescription } from '../model/workflow/stepDescription';
import { AttachmentHandler } from './attachments';
import { computePath, httpParamSerializer } from './service.helpers';

export interface WidgetMission extends MissionSummaryDto {
    missionCrewNames?: string[];
    craftNames?: string[];
    combinedStatus?: CombinedMissionStatus;
    convertedDate: moment.Moment;
    convertedRequestTime?: moment.Moment;
    convertedResolutionTime?: moment.Moment;
}
export interface MissionRegisterStatusDetails {
    missionDate: string;
    missionName: string;
    delegatedAuthorityId: number;
}

export interface DeconstructedMissionStatus {
    missionStatus: MissionSummaryDto.Status[];
    approvalStatus: RequestersMissionApprovalDto.Status[];
    readyForFinalisation: boolean;
    outstandingApprovals: boolean;
}

export interface AuthorityFilter {
    name: string;
    isPrimary?: boolean;
    authorityIds: number[];
    orOrganisationId: number;
    organisationId: number;
    filterType: 'SINGLE_AUTHORITY' | 'ALL_AUTHORITIES';
}

export enum CombinedMissionStatus {
    NEW = 'NEW',
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    READY_TO_FLY = 'READY_TO_FLY',
    ON_SITE = 'ON_SITE',
    FLYING = 'FLYING',
    COMPLETED = 'COMPLETED',
    READY_FOR_FINALISATION = 'READY_FOR_FINALISATION',
    FINALISED = 'FINALISED',
    CANCELLED = 'CANCELLED',
    OUTSTANDING = 'OUTSTANDING',
    AWAITING_SYNC = 'AWAITING_SYNC'
}

export const combinedStatuses = [
    { value: 'DRAFT', name: 'Draft' },
    { value: 'SUBMITTED', name: 'Submitted' },
    { value: 'READY_TO_FLY', name: 'Ready To Fly' },
    { value: 'COMPLETED', name: 'Completed' },
    { value: 'CANCELLED', name: 'Cancelled' },
    { value: 'ON_SITE', name: 'On Site' },
    { value: 'FLYING', name: 'Flying' },
    { value: 'AWAITING_SYNC', name: 'Awaiting Sync' },
    { value: 'READY_FOR_FINALISATION', name: 'Ready For Finalisation' },
    { value: 'FINALISED', name: 'Finalised' },
    { value: 'INSTANT_MISSION', name: 'Instant Mission' }
];

export const MISSION_TYPES = [
    {
        value: 'STANDARD',
        name: 'Standard'
    },
    {
        value: 'RETROSPECTIVE',
        name: 'Retrospective'
    },
    {
        value: 'OFFLINE',
        name: 'Offline'
    },
    {
        value: 'FIELD_APP_BACKUP',
        name: 'Field App Backup'
    },
    {
        value: 'IMPORTED',
        name: 'Imported'
    }
];

export function isFinalised(status: string): boolean {
    return (
        status === 'COMPLETED' ||
        status === 'CANCELLED' ||
        status === 'FINALISED'
    );
}

export const MISSION_CHANGE_EVENT = 'mission-changed';

function cloneDateToToday(originalDate: string): string {
    if (originalDate == null) {
        return null;
    }

    const now = new Date();
    const newDate = new Date(originalDate);
    newDate.setFullYear(now.getFullYear());
    newDate.setMonth(now.getMonth(), now.getDate());
    return newDate.toISOString();
}

/**
 * This function assumes that the user can edit the mission at all.
 *
 * @param status mission status
 * @param canPrepareToFly does the user have permission to prepare to fly
 * @param canFinalise does the user have permission to finalise
 */
export function calculateEditableSteps(
    status: DisplayableMissionDto.Status,
    canPrepareToFly: boolean,
    canFinalise: boolean,
    canEdit: boolean
) {
    switch (status) {
        case DisplayableMissionDto.Status.DRAFT:
        case DisplayableMissionDto.Status.READY_TO_FLY:
            // Only allow editing pre-submit if the mission is editable
            return canEdit
                ? ['pre-submit', 'pre-departure']
                : ['pre-departure'];
        case DisplayableMissionDto.Status.FLYING:
        case DisplayableMissionDto.Status.COMPLETED:
            return [
                'pre-submit',
                'pre-departure',
                'pre-mission',
                'post-mission'
            ];
        default:
            return [];
    }
}

export function calculateValidatedSteps(status: DisplayableMissionDto.Status) {
    switch (status) {
        case DisplayableMissionDto.Status.DRAFT:
        case DisplayableMissionDto.Status.READY_TO_FLY:
            return ['pre-submit', 'pre-departure'];
        case DisplayableMissionDto.Status.FLYING:
        case DisplayableMissionDto.Status.COMPLETED:
            return ['pre-mission', 'pre-departure', 'post-mission'];
        default:
            return [];
    }
}

export function calculateCombinedStatus(
    missionStatus: MissionSummaryDto.Status,
    approvalStatuses: RequestersMissionApprovalDto.Status[],
    readyForFinalisation: boolean
): CombinedMissionStatus {
    if (missionStatus == null) {
        return CombinedMissionStatus.NEW;
    }
    switch (missionStatus) {
        case MissionSummaryDto.Status.DRAFT:
            if (
                approvalStatuses.some(
                    status =>
                        status ===
                            RequestersMissionApprovalDto.Status.PENDING ||
                        status ===
                            RequestersMissionApprovalDto.Status.BEING_REVIEWED
                )
            ) {
                return CombinedMissionStatus.SUBMITTED;
            }
            return CombinedMissionStatus.DRAFT;

        case MissionSummaryDto.Status.READY_TO_FLY:
            return CombinedMissionStatus.READY_TO_FLY;

        case MissionSummaryDto.Status.ON_SITE:
            return CombinedMissionStatus.ON_SITE;

        case MissionSummaryDto.Status.PREPARED:
        case MissionSummaryDto.Status.ACTIVATED:
        case MissionSummaryDto.Status.FLYING:
        case MissionSummaryDto.Status.DONE_FLYING:
            return CombinedMissionStatus.FLYING;

        case MissionSummaryDto.Status.AWAITING_SYNC:
            return CombinedMissionStatus.AWAITING_SYNC;

        case MissionSummaryDto.Status.COMPLETED:
            return readyForFinalisation
                ? CombinedMissionStatus.READY_FOR_FINALISATION
                : CombinedMissionStatus.COMPLETED;

        case MissionSummaryDto.Status.FINALISED:
            return CombinedMissionStatus.FINALISED;

        case MissionSummaryDto.Status.CANCELLED:
            return CombinedMissionStatus.CANCELLED;
    }
}

export function revertCombinedStatus(
    combinedStatus: CombinedMissionStatus
): DeconstructedMissionStatus {
    const baseValue: DeconstructedMissionStatus = {
        approvalStatus: null,
        missionStatus: null,
        readyForFinalisation: null,
        outstandingApprovals: null
    };
    switch (combinedStatus) {
        case CombinedMissionStatus.NEW:
            return baseValue;
        case CombinedMissionStatus.SUBMITTED:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.DRAFT],
                outstandingApprovals: true
            };
        case CombinedMissionStatus.DRAFT:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.DRAFT],
                outstandingApprovals: false
            };
        case CombinedMissionStatus.OUTSTANDING:
            return {
                ...baseValue,
                missionStatus: [
                    MissionSummaryDto.Status.DRAFT,
                    MissionSummaryDto.Status.READY_TO_FLY,
                    MissionSummaryDto.Status.PREPARED,
                    MissionSummaryDto.Status.FLYING,
                    MissionSummaryDto.Status.ON_SITE,
                    MissionSummaryDto.Status.DONE_FLYING,
                    MissionSummaryDto.Status.COMPLETED
                ]
            };
        case CombinedMissionStatus.READY_TO_FLY:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.READY_TO_FLY]
            };
        case CombinedMissionStatus.ON_SITE:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.ON_SITE]
            };
        case CombinedMissionStatus.FLYING:
            return {
                ...baseValue,
                missionStatus: [
                    MissionSummaryDto.Status.PREPARED,
                    MissionSummaryDto.Status.ACTIVATED,
                    MissionSummaryDto.Status.FLYING,
                    MissionSummaryDto.Status.DONE_FLYING
                ]
            };
        case CombinedMissionStatus.READY_FOR_FINALISATION:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.COMPLETED],
                readyForFinalisation: true
            };
        case CombinedMissionStatus.COMPLETED:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.COMPLETED],
                readyForFinalisation: false
            };
        case CombinedMissionStatus.FINALISED:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.FINALISED]
            };
        case CombinedMissionStatus.CANCELLED:
            return {
                ...baseValue,
                missionStatus: [MissionSummaryDto.Status.CANCELLED]
            };
        default:
            return baseValue;
    }
}

export function displayableMissionDtoCombinedStatus(
    mission: DisplayableMissionDto
): CombinedMissionStatus {
    return calculateCombinedStatus(
        mission.status,
        findActiveApprovals(mission.approvals, true).map(a => a.status),
        mission.readyForFinalisation
    );
}

export interface GraphQlMissionFilters {
    conformanceResultStatus: FlightConformanceResultDto.Status[];
}

/**
 * This service is a facade for many of the mission related services,
 * including approvals.
 * @ngInject
 */
@Injectable({
    providedIn: 'root'
})
export class MissionService {
    private baseUrl: string;
    private commsChangesSource = new Subject<void>();
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();
    commsChanges$ = this.commsChangesSource.asObservable();

    constructor(
        constants: FlyFreelyConstants,
        private http: HttpClient,
        private apollo: Apollo
    ) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
        this.commsChangesSource.complete();
    }

    notifyMissionChanged() {
        this.changeSource.next();
    }

    findMissionSummaries(
        organisationId: number,
        pagination: Pagination,
        conformanceResultStatus?: FlightConformanceResultDto.Status[],
        authorityIds?: number[],
        orOrganisationId?: number,
        status?: MissionSummaryDto.Status[],
        startTime?: string,
        endTime?: string
    ) {
        return this.apollo
            .query<{
                findMissions: GqlQueryResult<any>;
            }>({
                query: gql`
                    query find(
                        $organisationId: Long
                        $authorityIds: [Long]
                        $orOrganisationId: Long
                        $status: [MissionStatus]
                        $conformanceResultStatus: [FlightConformanceResultStatus]
                        $page: Int
                        $pageSize: Int
                        $sortFields: [GqlSortField]
                        $filters: [GqlFilterField]
                        $startTime: Instant
                        $endTime: Instant
                    ) {
                        findMissions(
                            criteria: {
                                organisationId: $organisationId
                                authorityIds: $authorityIds
                                orOrganisationId: $orOrganisationId
                                status: $status
                                conformanceResultStatus: $conformanceResultStatus
                                startTime: $startTime
                                endTime: $endTime
                            }
                            sortFields: $sortFields
                            page: $page
                            pageSize: $pageSize
                            filters: $filters
                        ) {
                            count
                            results {
                                id
                                name
                                uid
                                missionDate
                                timeZone
                                location {
                                    id
                                    name
                                }
                                status
                                organisation {
                                    id
                                    name
                                }
                                isDummy
                                missionCrew {
                                    person {
                                        id
                                        firstName
                                        lastName
                                    }
                                    missionRole {
                                        id
                                        name
                                        coreRole
                                    }
                                }
                                missionApprovals {
                                    id
                                    requestTime
                                    resolutionTime
                                    status
                                }
                                missionOperationType {
                                    id
                                    name
                                    coreType
                                }
                                readyForFinalisation
                                crafts {
                                    id
                                    nickname
                                }
                                type
                                workflowVersion {
                                    workflow {
                                        id
                                        name
                                    }
                                }
                                flightConformanceResultList {
                                    status
                                    sendTime
                                }
                            }
                        }
                    }
                `,
                variables: {
                    ...pagination,
                    organisationId,
                    authorityIds,
                    orOrganisationId,
                    status,
                    conformanceResultStatus,
                    startTime,
                    endTime
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                filter(r => !r.loading),
                map(r => r.data.findMissions)
            );
    }

    findMissions(
        organisationId: number,
        criteria?: {
            startTime?: string;
            endTime?: string;
            status?: MissionSummaryDto.Status[];
            authorityIds?: number[];
            orOrganisationId?: number;
        }
    ) {
        return this.http
            .get<MissionSummaryDto[]>(`${this.baseUrl}/webapi/missions`, {
                params: httpParamSerializer({ organisationId, ...criteria })
            })
            .pipe(
                map(missions =>
                    missions.map(mission => ({
                        date:
                            mission.missionDate != null
                                ? new Date(mission.missionDate).getTime()
                                : null,
                        mission
                    }))
                ),
                map(missions => {
                    missions.sort((a, b) => {
                        if (a.date == null) {
                            if (b.date == null) {
                                return 0;
                            }
                            return 1;
                        }
                        if (b.date == null) {
                            return -1;
                        }
                        return b.date - a.date;
                    });
                    return missions;
                }),
                map(missions => missions.map(m => m.mission))
            );
    }

    findMissionsApprovedBy(organisationId: number) {
        return this.http
            .get<MissionSummaryDto[]>(
                `${this.baseUrl}/webapi/missions/approvals`,
                {
                    params: httpParamSerializer({ organisationId })
                }
            )
            .toPromise();
    }

    findByApprovingAuthority(authorityId: number) {
        return this.http
            .get<MissionSummaryDto[]>(
                `${this.baseUrl}/webapi/missions/byAuthority`,
                {
                    params: httpParamSerializer({ authorityId })
                }
            )
            .toPromise();
    }

    findMission(missionId: number) {
        return this.http.get<DisplayableMissionDto>(
            `${this.baseUrl}/webapi/missions/${missionId}`
        );
    }

    findFlight(
        missionId: number,
        flightId: number,
        managingOrganisationId?: number
    ) {
        return this.http.get<SortieDetailsDto>(
            `${this.baseUrl}/webapi/missions/${missionId}/flights/${flightId}`,
            {
                params: httpParamSerializer({ managingOrganisationId })
            }
        );
    }

    findMissionAirspaceAuthorisations(missionId: number) {
        return this.http.get<AirspaceAuthorisationDto[]>(
            `${this.baseUrl}/webapi/missions/${missionId}/airspaceAuthorisations`,
            {}
        );
    }

    /**
     * Creates a new mission object for populating by the user
     */
    newMission(organisation: PersonsOrganisationDto): DisplayableMissionDto {
        return {
            id: null,
            name: null,
            status: 'DRAFT',
            airspaceAuthorisationList: [],
            missionEstimatedTime: 3600,
            missionCrewDetails: [],
            organisationId: organisation.id,
            availableActions: {
                hasFinalise: false,
                hasEdit: true,
                canEdit: true,
                canCancel: false,
                canComplete: false,
                canFinalise: false,
                canPrepareToFly: false,
                canApprove: false,
                canRequestFinalisation: false,
                canUnfinalise: false,
                canDelete: false,
                hasDelete: false
            },
            insuranceCoverage: [],
            crafts: [],
            equipment: [],
            sorties: [],
            craftIds: [],
            craftNicknames: [],
            additionalAuthorities: [],
            approvals: [],
            visualLineOfSight: DisplayableMissionDto.VisualLineOfSight.VLOS,
            timeOfDay: DisplayableMissionDto.TimeOfDay.DAY,
            missionContacts: [],
            missionRadioFrequencies: [],
            modelVersion: 2,
            maintenanceLogs: [],
            aerodromesOfInterest: [],
            notams: [],
            attachments: [],
            flightAttachments: {},
            serviceabilitySignoffs: [],
            unlistedBatterySets: [],
            unlistedPersonnel: [],
            unlistedRpas: [],
            unlistedEquipment: [],
            checksumModificationTime: null,
            featureLevel: 0
        };
    }

    cloneMission(originalMission: DisplayableMissionDto) {
        const newMission: DisplayableMissionDto = {
            ...originalMission,
            id: null,
            status: DisplayableMissionDto.Status.DRAFT,
            modelVersion: 2,
            uid: null,
            missionDate: cloneDateToToday(originalMission.missionDate),
            lockedFields: {
                aircraft: false,
                isDummy: false,
                missionCrew: false,
                missionType: false,
                missionWorkflowVersion: false,
                timeZone: false
            },
            approvals: [],
            airspaceAuthorisationList: [],
            emergencyContacts: null,
            radioFrequencies: null,
            locationId: null,
            location: {
                ...originalMission.location,
                id: null,
                derivedFromId: originalMission.location?.id
            },
            noNotamDeclarationPerson: null,
            noNotamDeclarationTime: null
        };

        return newMission;
    }

    newWeather(mission: any) {
        return { mission: mission.id };
    }

    newFeasibility(mission: any) {
        return { isLegal: false, mission: mission.id };
    }

    createMission(mission: CreateMissionCommand) {
        return this.http
            .post<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions`,
                mission
            )
            .pipe(tap(() => this.notifyMissionChanged()))
            .toPromise();
    }

    createHistoricalMission(command: CreateHistoricalMissionCommand) {
        return this.http
            .post<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/historical`,
                command
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    updateMission(missionId: number, mission: UpdateMissionCommand) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}`,
                mission
            )
            .pipe(tap(() => this.notifyMissionChanged()))
            .toPromise();
    }

    updateHistoricalMission(
        missionId: number,
        command: UpdateHistoricalMissionCommand
    ) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/historical/${missionId}`,
                command
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    completeMission(missionId: number, command: CompleteMissionCommand) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/complete`,
                command
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    cancelMission(missionId: number, message?: string) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/cancel`,
                { message }
            )
            .pipe(tap(() => this.notifyMissionChanged()))
            .toPromise();
    }

    readyForFinalisation(missionId: number) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/readyForFinalisation`,
                null
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    clearReadyForFinalisation(missionId: number) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/clearReadyForFinalisation`,
                null
            )
            .pipe(
                tap(() => this.notifyMissionChanged()),
                map(m => m)
            );
    }

    newSortie(missionId: number) {
        return this.http
            .post<SortieDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/flights`,
                null
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    updateSortieDocumentation(
        flightId: number,
        missionId: number,
        cmd: UpdateMissionDocumentationCommand
    ) {
        return this.http
            .put<CompletedDocumentationDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/flights/${flightId}/documentation`,
                cmd
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    createDiscussion(missionId: number, body: string) {
        return this.http
            .post(`${this.baseUrl}/webapi/missions/${missionId}/discussion`, {
                body,
                missionId
            })
            .pipe(tap(() => this.commsChangesSource.next()));
    }

    findDiscussions(missionId: number) {
        return this.http.get<MissionMessageDto[]>(
            `${this.baseUrl}/webapi/missions/${missionId}/discussions`
        );
    }

    /**
     * find weather for an mission
     */
    findWeather(missionId: number) {
        if (missionId === undefined) {
            return Promise.resolve({ windSpeed: 0 });
        }
        return this.http
            .get(`${this.baseUrl}/webapi/missions/${missionId}/weather`)
            .toPromise();
    }

    /**
     * update weather for an mission
     */
    saveWeather(missionId: number, update: any) {
        return this.http
            .put(`${this.baseUrl}/webapi/missions/${missionId}/weather`, update)
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    /**
     * Saves a user and time against the mission to acknowledge there are no relevant NOTAM messages for the mission.
     */
    acknowledgeNoRelevantNotam(missionId: number, personId: number) {
        return this.http
            .put(
                `${this.baseUrl}/webapi/missions/${missionId}/${personId}/declareNoNotam`,
                {}
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    /**
     * Finds messages associated with a mission.
     */
    findMissionMessage(missionId: number) {
        return this.http.get(
            `${this.baseUrl}/webapi/missions/${missionId}/messages`
        );
    }

    updateDocumentation(
        missionId: number,
        formResponses: { [step: string]: FormResponseCommand[] }
    ) {
        return this.http
            .put<MissionDocumentationDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/documentation`,
                { formResponses }
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    findDocumentation(missionId: number) {
        return this.http.get<MissionDocumentationDto>(
            `${this.baseUrl}/webapi/missions/${missionId}/documentation`
        );
    }

    findSortieDocumentation(missionId: number, sortieId: number) {
        return this.http.get<CompletedDocumentationDto>(
            `${this.baseUrl}/webapi/missions/${missionId}/${sortieId}/sortieDocumentation`
        );
    }

    prepareToFly(missionId: number) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/ready`,
                null
            )
            .pipe(tap(() => this.notifyMissionChanged()))
            .toPromise();
    }

    finaliseMission(missionId: number, skipChecks: boolean) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/finalise`,
                { skipChecks } as FinaliseMissionCommand
            )
            .pipe(tap(() => this.notifyMissionChanged()))
            .toPromise();
    }

    unfinaliseMission(missionId: number) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/unfinalise`,
                null
            )
            .pipe(tap(() => this.notifyMissionChanged()))
            .toPromise();
    }

    findMissionTypes(organisationId: number) {
        return this.http.get<MissionOperationTypeDto[]>(
            `${this.baseUrl}/webapi/missionTypes`,
            {
                params: httpParamSerializer({ organisationId })
            }
        );
    }

    deleteMission(missionId: number) {
        return this.http
            .delete(`${this.baseUrl}/webapi/missions/${missionId}`)
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    findPostMissionForms(missionId: number) {
        return this.http.get(
            `${this.baseUrl}/webapi/missions/${missionId}/postmission`
        );
    }

    attachmentHandler(missionId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/missions/${missionId}/attachments`
        );
    }

    flightAttachmentHandler(missionId: number, sortieId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/missions/${missionId}/flights/${sortieId}/attachments`
        );
    }

    linkUnlistedBatterySet(
        command: LinkUnlistedEntityCommand,
        missionId: number
    ) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/unlistedBatterySet`,
                command
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    linkUnlistedRpa(command: LinkUnlistedEntityCommand, missionId: number) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/unlistedRpa`,
                command
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    linkUnlistedPerson(command: LinkUnlistedEntityCommand, missionId: number) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/unlistedPerson`,
                command
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    linkUnlistedEquipment(
        command: LinkUnlistedEntityCommand,
        missionId: number
    ) {
        return this.http
            .put<DisplayableMissionDto>(
                `${this.baseUrl}/webapi/missions/${missionId}/unlistedEquipment`,
                command
            )
            .pipe(tap(() => this.notifyMissionChanged()));
    }

    storeServiceabilitySignoff(
        command: ServiceabilitySignoffCommand,
        missionId: number
    ) {
        return this.http.put<DisplayableMissionDto>(
            `${this.baseUrl}/webapi/missions/${missionId}/serviceabilitySignoff`,
            command
        );
    }

    getFormTemplatePrintUrl(
        missionId: number,
        formId: number,
        step: string,
        managingOrganisationId: number
    ) {
        return computePath(`/reportsapi/missions/${missionId}/formTemplate`, {
            formId,
            step,
            managingOrganisationId,
            format: 'pdf'
        });
    }

    getFormResponsePrintUrl(
        missionId: number,
        formId: number,
        step: string,
        managingOrganisationId: number
    ) {
        return computePath(`/reportsapi/missions/${missionId}/formResponse`, {
            formId,
            step,
            managingOrganisationId,
            format: 'pdf'
        });
    }

    getFlightFormTemplatePrintUrl(
        missionId: number,
        flightId: number,
        formId: number,
        step: string,
        managingOrganisationId: number
    ) {
        return computePath(`/reportsapi/missions/${missionId}/formTemplate`, {
            flightId,
            formId,
            step,
            managingOrganisationId,
            format: 'pdf'
        });
    }

    getFlightFormResponsePrintUrl(
        missionId: number,
        flightId: number,
        formId: number,
        step: string,
        managingOrganisationId: number
    ) {
        return computePath(`/reportsapi/missions/${missionId}/formResponse`, {
            flightId,
            formId,
            step,
            managingOrganisationId,
            format: 'pdf'
        });
    }

    getBasicStatusFilters(): NameValue[] {
        return [
            { value: 'DRAFT', name: 'Draft' },
            { value: 'READY_TO_FLY', name: 'Ready To Fly' },
            { value: 'COMPLETED', name: 'Completed' },
            { value: 'CANCELLED', name: 'Cancelled' },
            { value: 'ON_SITE', name: 'On Site' },
            { value: 'FLYING', name: 'Flying' },
            { value: 'AWAITING_SYNC', name: 'Awaiting Sync' },
            { value: 'FINALISED', name: 'Finalised' },
            { value: 'INSTANT_MISSION', name: 'Instant Mission' }
        ];
    }

    getStatusFilters(): NameValue[] {
        return [
            {
                value: 'DRAFT|SUBMITTED|READY_TO_FLY|PREPARED|FLYING|ON_SITE|DONE_FLYING|COMPLETED|READY_FOR_FINALISATION',
                name: 'Outstanding'
            },
            { value: 'DRAFT', name: 'Draft' },
            { value: 'READY_TO_FLY', name: 'Ready To Fly' },
            { value: 'COMPLETED', name: 'Completed' },
            { value: 'CANCELLED', name: 'Cancelled' },
            { value: 'ON_SITE', name: 'On Site' },
            { value: 'FLYING', name: 'Flying' },
            { value: 'AWAITING_SYNC', name: 'Awaiting Sync' },
            { value: 'FINALISED', name: 'Finalised' },
            { value: 'INSTANT_MISSION', name: 'Instant Mission' }
        ];
    }

    getCombinedStatusFilters(): NameValue[] {
        return [
            {
                value: 'DRAFT|SUBMITTED|READY_TO_FLY|PREPARED|FLYING|ON_SITE|DONE_FLYING|COMPLETED|READY_FOR_FINALISATION',
                name: 'All'
            },
            {
                value: 'OUTSTANDING',
                name: 'Outstanding'
            },
            { value: 'READY_FOR_FINALISATION', name: 'Ready For Finalisation' },
            { value: 'DRAFT', name: 'Draft' },
            { value: 'SUBMITTED', name: 'Submitted' },
            { value: 'READY_TO_FLY', name: 'Ready To Fly' },
            { value: 'COMPLETED', name: 'Completed' },
            { value: 'CANCELLED', name: 'Cancelled' },
            { value: 'ON_SITE', name: 'On Site' },
            { value: 'FLYING', name: 'Flying' },
            { value: 'AWAITING_SYNC', name: 'Awaiting Sync' },
            { value: 'FINALISED', name: 'Finalised' },
            { value: 'INSTANT_MISSION', name: 'Instant Mission' }
        ];
    }

    getCombinedStatuses(): NameValue[] {
        return combinedStatuses;
    }

    getCombinedStatus(mission: MissionSummaryDto): CombinedMissionStatus {
        return calculateCombinedStatus(
            mission.status,
            mission.missionApproval != null
                ? [mission.missionApproval.status]
                : [],
            mission.readyForFinalisation
        );
    }

    static getObjectiveOutcomes(): NameValue[] {
        return [
            {
                name: 'Completed',
                value: DisplayableMissionDto.Outcome.COMPLETED
            },
            {
                name: 'Incomplete',
                value: DisplayableMissionDto.Outcome.INCOMPLETE
            },
            {
                name: 'Partially Completed',
                value: DisplayableMissionDto.Outcome.PARTIALLY_COMPLETED
            }
        ];
    }

    static getSortieCompletionStatuses(): NameValue[] {
        return [
            { value: 'COMPLETED', name: 'Completed' },
            { value: 'ABORTED', name: 'Aborted' }
        ];
    }

    getCloudCoverageValues(): NameValue[] {
        return [
            { value: 'CLEAR', name: 'Clear' },
            { value: 'FEW', name: 'Few' },
            { value: 'SCT', name: 'Scattered' },
            { value: 'BKM', name: 'Broken' },
            { value: 'OVC', name: 'Overcast' }
        ];
    }

    getWeatherConditionValues(): NameValue[] {
        return [
            { value: null, name: 'Nil' },
            { value: 'SH', name: 'Showers' },
            { value: 'TS', name: 'Thunderstorms' },
            { value: 'RA', name: 'Rain' },
            { value: 'SHRA', name: 'Showers of Rain' },
            { value: 'DZ', name: 'Drizzle' },
            { value: 'FG', name: 'Fog' },
            { value: 'GR', name: 'Hail' }
        ];
    }

    getMissionSteps(
        editable: string[] = [],
        validated: string[] = []
    ): StepDescription[] {
        return [
            {
                value: 'pre-submit',
                name: 'Mission Planning',
                editable: editable.indexOf('pre-submit') !== -1,
                validate: validated.indexOf('pre-submit') !== -1
            },
            {
                value: 'pre-departure',
                name: 'Pre-departure',
                editable: editable.indexOf('pre-departure') !== -1,
                validate: validated.indexOf('pre-departure') !== -1
            },
            {
                value: 'pre-mission',
                name: 'Pre-mission',
                editable: editable.indexOf('pre-mission') !== -1,
                validate: validated.indexOf('pre-mission') !== -1
            },
            {
                value: 'post-mission',
                name: 'Post-mission',
                editable: editable.indexOf('post-mission') !== -1,
                validate: validated.indexOf('post-mission') !== -1
            }
        ];
    }

    getFlightSteps(): NameValue[] {
        return [
            { value: 'pre-flight', name: 'Pre-flight' },
            { value: 'post-flight', name: 'Post-flight' }
        ];
    }
}
