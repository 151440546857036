import {
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    Output
} from '@angular/core';
import {
    AttachmentHandler,
    AuthorityRegisterSummaryDto,
    AuthorityReportService,
    AuthorityTypeDto,
    ChangeDetails,
    CraftAuthorityDto,
    DownloadService,
    FlyFreelyLoggingService,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    PersonnelRegisterEntryExpiryDto,
    PrintOption,
    RpaAuthorityService,
    SimpleAuthorityTypeDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthorityDialoguesService } from '../authority-dialogues.service';
import {
    AuthorityActionPayload,
    RegisterExpirySummary
} from '../organisation-authority/organisation-authority-edit.service';

@Component({
    selector: 'authority-details-dialogue',
    templateUrl: './authority-details-dialogue.component.html'
})
export class AuthorityDetailsDialogue {
    @Input() authority: OrganisationAuthorityDto | CraftAuthorityDto;
    @Input() authorityType: SimpleAuthorityTypeDto;
    @Input() managingOrganisationId: number;
    @Input() icon: string;
    @Input() canEdit: boolean;
    @Input() canUseWorkflow: boolean;
    @Input() registerSummaries: RegisterExpirySummary[];
    @Input() viewOnly: boolean = false;

    @Output() onEditAuthority = new EventEmitter<AuthorityActionPayload>();
    @Output() onShowWorkflow = new EventEmitter<AuthorityActionPayload>();
    @Output() onShowRegister = new EventEmitter<AuthorityActionPayload>();
    @Output() onPrintAuthorityRegister = new EventEmitter<
        AuthorityActionPayload
    >();
    @Output() onDeleteAuthority = new EventEmitter<AuthorityActionPayload>();
    @Output() onShowNominatedPersonnel = new EventEmitter<
        AuthorityActionPayload
    >();
    @Output() archiveAuthority = new EventEmitter<AuthorityActionPayload>();
    @Output() unarchiveAuthority = new EventEmitter<AuthorityActionPayload>();

    attachmentsHandler: AttachmentHandler;
    organisationAuthority: OrganisationAuthorityDto;

    isOrganisationAuthority = false;

    issuedTo: string;

    registerExpiries: {
        [registerId: number]: PersonnelRegisterEntryExpiryDto.ExpiryStatus;
    };

    printOptions: PrintOption[] = [];

    working: boolean;
    workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private organisationAuthorityService: OrganisationAuthorityService,
        private rpaAuthorityService: RpaAuthorityService,
        private authorityReportService: AuthorityReportService,
        private downloadService: DownloadService,
        public modal: BsModalRef,
        @Inject(forwardRef(() => AuthorityDialoguesService))
        private authorityDialoguesService: AuthorityDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.organisationAuthorityService.change$
            .pipe(
                filter(
                    result =>
                        result != null &&
                        result.changedId === this.authority.id &&
                        result.type === ChangeDetails.Type.UPDATE
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(() => this.refreshAuthority());

        this.rpaAuthorityService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshRpaAuthority());

        if (this.registerSummaries != null) {
            const authority: OrganisationAuthorityDto = this
                .authority as OrganisationAuthorityDto;
            this.registerExpiries =
                authority.registers != null
                    ? authority.registers.reduce(
                          (acc, register) => ({
                              ...acc,
                              [register.id]: this.registerSummaries.find(
                                  r => r.registerId === register.id
                              )?.overallStatus
                          }),
                          {}
                      )
                    : null;
        }

        this.setupAuthorities();
        this.refreshAvailableReports();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshAuthority() {
        this.organisationAuthorityService
            .findAuthority(this.authority.id, this.managingOrganisationId)
            .subscribe({
                next: authority => {
                    this.authority = authority;
                    this.setupAuthorities();
                },
                error: error => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }
    refreshRpaAuthority() {
        this.rpaAuthorityService
            .findAuthority(this.managingOrganisationId, this.authority.id)
            .subscribe({
                next: authority => {
                    this.authority = authority;
                    this.setupAuthorities();
                },
                error: error => {
                    this.logging.error(error);
                }
            })
            .add(this.workTracker.createTracker());
    }

    setupAuthorities() {
        this.isOrganisationAuthority =
            this.authorityType.appliesTo ===
            SimpleAuthorityTypeDto.AppliesTo.COMMERCIAL;
        if (this.isOrganisationAuthority) {
            // This is needed for the HTML since Ivy doesn't do unique fields on multiple types well
            this.organisationAuthority = <OrganisationAuthorityDto>(
                this.authority
            );
        }

        switch (this.authorityType.appliesTo) {
            case SimpleAuthorityTypeDto.AppliesTo.RPA:
                this.attachmentsHandler = this.rpaAuthorityService.attachmentHandler(
                    this.authority.id,
                    this.managingOrganisationId
                );

                this.rpaAuthorityService
                    .findDisplayableAuthority(
                        this.authority.id,
                        this.managingOrganisationId
                    )
                    .subscribe(
                        authority =>
                            (this.issuedTo =
                                authority.rpa?.nickname ??
                                authority.craft?.nickname)
                    );
                break;

            case SimpleAuthorityTypeDto.AppliesTo.COMMERCIAL:
            case SimpleAuthorityTypeDto.AppliesTo.PERSONAL:
                this.attachmentsHandler = this.organisationAuthorityService.attachmentHandler(
                    this.authority.id,
                    this.managingOrganisationId
                );

                this.organisationAuthorityService
                    .findDisplayableAuthority(
                        this.authority.id,
                        this.managingOrganisationId
                    )
                    .subscribe(
                        authority =>
                            (this.issuedTo = authority.organisation.name)
                    );
                break;
        }
    }

    showRegister(register: AuthorityRegisterSummaryDto) {
        if (
            register.registerEntityType ===
            AuthorityRegisterSummaryDto.RegisterEntityType.RPA
        ) {
            this.authorityDialoguesService.showRpaRegisterDialogue(
                this.managingOrganisationId,
                this.authorityType as AuthorityTypeDto,
                <CraftAuthorityDto>this.authority,
                register,
                this.managingOrganisationId
            );
        } else {
            this.authorityDialoguesService.showPersonnelRegisterDialogue(
                this.managingOrganisationId,
                this.authorityType as AuthorityTypeDto,
                <OrganisationAuthorityDto>this.authority,
                register,
                this.managingOrganisationId
            );
        }
    }

    private refreshAvailableReports() {
        if (this.managingOrganisationId == null || this.authorityType == null) {
            this.printOptions = [];
            return;
        }

        const subscription = this.authorityReportService
            .findAvailableTemplates(
                '',
                this.authorityType.id,
                this.managingOrganisationId
            )
            .subscribe(reports => {
                this.printOptions = reports.map(r => ({
                    description: r.description ? r.description : '',
                    name: r.name,
                    print: (authority: OrganisationAuthorityDto) =>
                        this.downloadService.downloadFromUrl(
                            this.authorityReportService.getPdfReportUrl(
                                r.reportTemplateId,
                                authority.id,
                                this.managingOrganisationId
                            )
                        )
                }));
                subscription.unsubscribe();
            });
    }
}
