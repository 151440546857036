import { NgModule } from '@angular/core';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { LibraryDialoguesService } from './library-dialogue.service';

@NgModule({
    imports: [FlyFreelyFormlyModule],
    declarations: [],
    providers: [LibraryDialoguesService],
    exports: []
})
export class LibrariesModule {}
