/**
 * FlyFreely API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.15.8-SNAPSHOT
 * Contact: support@flyfreely.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface AddressDto { 
    id: number;
    addressType: AddressDto.AddressType;
    name?: string;
    phoneNumber?: string;
    email?: string;
    postcode: string;
    state: string;
    street: string;
    street2: string;
    suburb: string;
    /**
     * The country code
     */
    country: string;
    /**
     * The full country name
     */
    countryName: string;
    organisationId: number;
}
export namespace AddressDto {
    export type AddressType = 'RESIDENTIAL' | 'WORK' | 'POSTAL' | 'BILLING';
    export const AddressType = {
        RESIDENTIAL: 'RESIDENTIAL' as AddressType,
        WORK: 'WORK' as AddressType,
        POSTAL: 'POSTAL' as AddressType,
        BILLING: 'BILLING' as AddressType
    };
}



