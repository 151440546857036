<div class="modal-header no-icon">
    <h3 class="modal-title" *ngIf="savedIncidentReport">
        <ng-container *ngIf="savedIncidentReport.status == 'NEW'">
            New
        </ng-container>
        <ng-container *ngIf="savedIncidentReport.status == 'SUBMITTED'">
            Edit
        </ng-container>
        <ng-container *ngIf="savedIncidentReport.status == 'RESOLVED'">
            View
        </ng-container>
        Incident Report
    </h3>
    <button type="button" (click)="close()" aria-label="Close" class="close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="inputForm" (ngSubmit)="submit()" *ngIf="savedIncidentReport">
    <inline-modal #inlineModal></inline-modal>
    <div class="modal-body">
        <formly-form
            [form]="inputForm"
            [fields]="fields"
            [options]="options"
            [model]="initialValues"
        >
        </formly-form>
        <attachment-list
            [attachmentsHandler]="attachmentsHandler"
            [allowUploads]="savedIncidentReport.availableActions.canSave"
            [allowEdit]="savedIncidentReport.availableActions.canSave"
            permissions
        >
        </attachment-list>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-default pull-left"
            [disabled]="working"
            (click)="cancelIncidentReport()"
            *ngIf="
                savedIncidentReport.status === 'NEW' ||
                savedIncidentReport.status === 'SUBMITTED'
            "
        >
            Cancel Report
        </button>
        <button
            *ngIf="
                savedIncidentReport.status !== 'NEW' &&
                savedIncidentReport.availableActions.canSave
            "
            type="button"
            class="btn btn-default"
            (click)="saveAndClose()"
            [disabled]="working || !validForSave"
        >
            Save and Close
        </button>
        <button
            *ngIf="
                savedIncidentReport.status !== 'NEW' &&
                savedIncidentReport.availableActions.canSave
            "
            class="btn btn-default"
            [disabled]="working || !validForSave"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-primary"
            [disabled]="working || inputForm.invalid"
            (click)="resolveIncidentReport()"
            *ngIf="
                savedIncidentReport.availableActions.canResolve &&
                savedIncidentReport.status === 'SUBMITTED'
            "
        >
            Resolve
        </button>
        <button
            *ngIf="savedIncidentReport.status === 'NEW'"
            class="btn btn-primary"
            [disabled]="working || inputForm.invalid"
        >
            Submit
        </button>
    </div>
    <ngx-loading [show]="working"></ngx-loading>
</form>
