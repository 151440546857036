import { Component, Input } from '@angular/core';
import {
    AircraftRegisterEntryDetailsDto,
    AuthorityTypeDto,
    CraftService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionRegisterStatusDetails,
    OrganisationAuthorityDto,
    OrganisationAuthorityService,
    RegisterEntryVerificationActivityDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import { matchConditionChecks } from 'libs/authorities/src/lib/registers/helpers';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Columns {
    heading: string;
    check: boolean;
}

interface Row {
    status: AircraftRegisterEntryDetailsDto.Status;
    columns: Columns[];
}

interface Register {
    heading: string;
    subtitle: string;
    isMissionRegister: boolean;
    id: number;
    rows: Row[];
    canView: boolean;
}
@Component({
    selector: 'rpa-registers-status',
    templateUrl: './rpa-registers-status.component.html',
    styles: [
        `
            .status-column {
                width: 10%;
                min-width: 60px;
            }
        `
    ],
    styleUrls: ['./styles.scss']
})
export class RpaRegistersStatusComponent {
    @Input() managingOrganisationId: number;
    @Input() rpaId: number;
    @Input() canEdit: boolean;
    @Input() canAdminOrganisation: boolean;
    @Input() missionDetails: MissionRegisterStatusDetails;

    time: string;
    missionName: string;
    registers: Register[];
    allRegisters: AircraftRegisterEntryDetailsDto[];
    allAuthorities: OrganisationAuthorityDto[];

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;

    constructor(
        private craftService: CraftService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        if (this.missionDetails == null) {
            this.time = moment().toISOString();
        } else {
            this.time = this.missionDetails.missionDate;
            this.missionName = this.missionDetails.missionName;
        }
        this.registers = [];
        this.organisationAuthorityService
            .findAuthorities(
                this.managingOrganisationId,
                this.managingOrganisationId
            )
            .subscribe({
                next: authorities => {
                    this.allAuthorities = authorities.reduce(
                        (acc, a) => acc.concat(a.authorities ?? []),
                        []
                    );
                    this.refreshData();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error fetching authorities for RPA: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshData() {
        this.craftService
            .findAircraftRegisterEntryByAircraftId(
                this.rpaId,
                this.managingOrganisationId,
                this.time.toString()
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => this.mapRegisters(result))
            .add(this.workTracker.createTracker());
    }

    mapRegisters(registers: AircraftRegisterEntryDetailsDto[]) {
        if (registers == null) {
            this.allRegisters = [];
            return;
        }
        this.allRegisters = registers;
        registers.forEach(r => {
            const authorityRegisterName = this.allAuthorities
                .find(a => a.id === r.authority.id)
                .registers.find(reg => reg.id === r.authorityRegisterId).name;
            const isMissionRegister =
                this.missionDetails != null
                    ? r.authority.id ===
                      this.missionDetails.delegatedAuthorityId
                    : false;
            const title = isMissionRegister
                ? `${r.authority.organisation.name} ${
                      r.authority.identifier ?? ''
                  } ${authorityRegisterName} (Mission Register)`
                : `${r.authority.organisation.name} ${
                      r.authority.identifier ?? ''
                  } ${authorityRegisterName}`;
            const subtitle =
                this.missionName != null
                    ? isMissionRegister
                        ? `Relevant mission register status as at ${moment(
                              this.time
                          ).format('hh:mm DD/MM/YYYY')} as required by ${
                              this.missionName
                          }`
                        : `Showing register status as at ${moment(
                              this.time
                          ).format('hh:mm DD/MM/YYYY')} as required by ${
                              this.missionName
                          }`
                    : '';
            const cols: Columns[] = r.conditions.map(c => ({
                heading: c.name,
                check:
                    r.checks.find(check => matchConditionChecks(c, check))
                        .status ===
                    RegisterEntryVerificationActivityDto.Status.PASSED
            }));
            const index = this.registers.findIndex(
                reg => reg.heading === title
            );
            if (index === -1) {
                this.registers.push({
                    heading: title,
                    subtitle: subtitle,
                    id: r.id,
                    isMissionRegister: isMissionRegister,
                    rows: [
                        {
                            status: r.status,
                            columns: cols
                        }
                    ],
                    canView:
                        r.authority.organisation.id ===
                        this.managingOrganisationId
                });
            } else {
                this.registers[index].rows.push({
                    status: r.status,
                    columns: cols
                });
            }
        });
        // Sort relevant mission registers to the top
        this.registers.sort((a, b) =>
            a.isMissionRegister
                ? -1
                : b.isMissionRegister
                ? 1
                : a.heading.localeCompare(b.heading)
        );
    }

    showRegister(register: Register) {
        const reg = this.allRegisters.find(r => register.id === r.id);
        this.organisationAuthorityService
            .findAuthority(reg.authority.id, this.managingOrganisationId)
            .subscribe({
                next: authority => {
                    this.authorityDialoguesService.showRpaRegisterDialogue(
                        reg.authority.organisation.id,
                        <AuthorityTypeDto>reg.authority.authorityType,
                        {
                            ...authority,
                            craftId: this.rpaId
                        },
                        authority.registers.find(
                            r => r.id === reg.authorityRegisterId
                        ),
                        this.managingOrganisationId
                    );
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error viewing register: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }
}
