import { Injectable } from '@angular/core';
import { FlyFreelyConstants } from '../constants';
import { httpParamSerializer } from './service.helpers';
import { HttpClient } from '@angular/common/http';
import { NotamDto } from '../model/api';

@Injectable({
    providedIn: 'root'
})
export class NotamService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findNotams(location: string[], missionId?: number) {
        return this.http.get<NotamDto[]>(`${this.baseUrl}/webapi/notams`, {
            params: httpParamSerializer({ location, missionId })
        });
    }
}
