import { Injectable } from '@angular/core';

export const DEFAULT_MAX_HEIGHT = 400;

/**
 * Holds the FlyFreely constants. This is setup based on what is on the window,
 * unless another source is provided.
 */
@Injectable({
    providedIn: 'root'
})
export class FlyFreelyConstants {
    public SITE_URL: string;
    public IMG_URL: string;
    public MAPBOX_ACCESS_TOKEN: string;
    public AIRMAP_ACCESS_TOKEN: string;
    public SITE_DESIGNATION: string;
    public TINYMCE: string;
    public LOG_ANALYTICS: boolean;
    public STRIPE_PK: string;
    // The dynamic url for the KeyCloak SSO login flows
    public KEYCLOAK_URL: string;
    // The maximum age of a cached KC token, in seconds
    public KC_MAX_AGE: number;
    public ALTERNATIVE_SUPPORT_URL: string;
    public FORCE_FEATURE_FLAGS: string[];

    // @ts-ignore @Optional constants?: any = window.appConfig
    constructor() {
        // @ts-ignore - no type sensing available
        const constants = window.appConfig;
        this.SITE_URL = constants.SITE_URL;
        this.IMG_URL = constants.IMG_URL;
        this.MAPBOX_ACCESS_TOKEN = constants.MAPBOX_ACCESS_TOKEN;
        this.AIRMAP_ACCESS_TOKEN = constants.AIRMAP_ACCESS_TOKEN;
        this.SITE_DESIGNATION = constants.SITE_DESIGNATION;
        this.TINYMCE = constants.TINYMCE;
        this.LOG_ANALYTICS = constants.LOG_ANALYTICS || false;
        this.STRIPE_PK = constants.STRIPE_PK;
        this.ALTERNATIVE_SUPPORT_URL = constants.ALTERNATIVE_SUPPORT_URL;
        // FIXME: this fallback URL needs to be removed once fully configured.
        this.KEYCLOAK_URL =
            constants.KEYCLOAK_URL ?? 'https://dev-id.flyfreely.io';
        this.KC_MAX_AGE = constants.KC_MAX_AGE ?? null;
        this.FORCE_FEATURE_FLAGS =
            constants.FORCE_FEATURE_FLAGS == null ||
            constants.FORCE_FEATURE_FLAGS.length === 0
                ? []
                : constants.FORCE_FEATURE_FLAGS.split(',').map(flag =>
                      flag.trim()
                  );
    }
}
