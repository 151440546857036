import { Directive, TemplateRef, Input } from '@angular/core';

/**
 * The column template provides an alternative rendering for a given column,
 * in addition to a formatter. They receive 2 properties:
 * * column - the column definition
 * * row - the original row data
 */
@Directive({
    selector: '[columnTemplate]'
})
export class ColumnTemplateDirective {
    @Input('columnTemplate')
    column: string;

    constructor(public tpl: TemplateRef<any>) {}
}
