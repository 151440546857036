import { Injectable } from '@angular/core';
import { ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error'; // Import onError
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {
    FlyFreelyConstants,
    LoginManager
} from '@flyfreely-portal-ui/flyfreely';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind } from 'graphql';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionClient } from 'subscriptions-transport-ws';

@Injectable()
export class GraphqlSubscriptionStateService {
    private connectionChanged = new BehaviorSubject<
        'connecting' | 'reconnected' | 'disconnected'
    >('connecting');

    connectionChange$ = this.connectionChanged
        .asObservable()
        .pipe(distinctUntilChanged());

    private wsClient?: SubscriptionClient;
    private latestToken: string | null = null;

    constructor(
        apollo: Apollo,
        httpLink: HttpLink,
        constants: FlyFreelyConstants,
        loginManager: LoginManager
    ) {
        // Subscribe to the loginManager.bearerToken$ and store the token
        loginManager.bearerToken$.subscribe(token => {
            this.latestToken = token; // Store the latest token

            if (token == null) {
                this.closeAll();
                return;
            }

            // Create Apollo link and WebSocket client with token
            const auth = setContext((operation, context) => {
                return {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            });

            const http = httpLink.create({
                uri: `${constants.SITE_URL}/graphql`
            });

            const websocketUri =
                constants?.SITE_URL == null || constants.SITE_URL === ''
                    ? window.location.origin.replace('http', 'ws')
                    : constants.SITE_URL.replace('http', 'ws');

            const wsClient = new SubscriptionClient(
                `${websocketUri}/subscriptions`,
                {
                    lazy: true,
                    timeout: 30000,
                    reconnect: true,
                    reconnectionAttempts: 100,
                    connectionParams: async () => {
                        // Use the latest token stored from the subscription
                        return {
                            Authorization: `Bearer ${this.latestToken}` // Use the stored token
                        };
                    }
                }
            );

            this.wsClient = wsClient;

            wsClient.onReconnected(() => {
                this.connectionChanged.next('reconnected');
            });
            wsClient.onDisconnected(() => {
                this.connectionChanged.next('disconnected');
            });

            const ws = new WebSocketLink(wsClient);

            const errorLink = onError(({ networkError, graphQLErrors }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach(({ message, locations, path }) => {
                        console.error(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                        );
                    });
                }

                if (networkError) {
                    console.error(`[Network error]:`, networkError);
                }
            });

            const _split = split(
                ({ query }) => {
                    const data = getMainDefinition(query);
                    return (
                        data.kind === Kind.OPERATION_DEFINITION &&
                        data.operation === 'subscription'
                    );
                },
                ws,
                auth.concat(http)
            );

            const basic = setContext((operation, context) => ({
                headers: {
                    Accept: 'charset=utf-8'
                }
            }));

            const link = ApolloLink.from([basic, errorLink, _split]);

            // Replace the existing client
            apollo.removeClient();
            apollo.create({
                link: link,
                cache: new InMemoryCache(),
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'network-only'
                    },
                    query: {
                        fetchPolicy: 'network-only'
                    },
                    mutate: {}
                }
            });
        });
    }

    /**
     * Forcefully close the websocket connection.
     */
    closeAll() {
        if (this.wsClient != null) {
            this.wsClient.unsubscribeAll();
            this.wsClient.close();
        }
    }
}
