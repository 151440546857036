import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularResizeEventModule } from 'angular-resize-event';
import { FullScreenModule } from 'libs/fullscreen/src/lib/fullscreen.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MAPBOX_API_KEY, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { FlyFreelyMapComponent } from './flyfreely-map/flyfreely-map.component';
import { MapSidebarDirective } from './flyfreely-map/map-sidebar-template.directive';
import { JurisdictionSelector } from './jurisdiction-selector/jurisdiction-selector.component';
import { MapFeatureSelectorComponent } from './map-feature-selector/map-feature-selector.component';
import { MapMarkerToolbarComponent } from './map-marker-toolbar/map-marker-toolbar.component';
import { MapMeasurementInteractionDirective } from './map-measurement-interaction.directive';
import { MapToolbarDeleteButtonComponent } from './map-toolbar/map-toolbar-delete-button.component';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { MapToolbarMeasurementButtonComponent } from './map-toolbar/measurement-button/map-toolbar-measurement-button.component';
import { GeocoderDirective } from './geocoder/geocoder.directive';

export const MAPBOX_GEOCODER_API_KEY = new InjectionToken<string>(
    'MAPBOX_GEOCODER_API_KEY'
);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        TreeModule,
        ButtonsModule,
        CollapseModule,
        NgxMapboxGLModule,
        TooltipModule,
        NgSelectModule,

        SharedUiModule,
        FullScreenModule,
        AngularResizeEventModule
    ],
    exports: [
        FlyFreelyMapComponent,
        MapMarkerToolbarComponent,
        MapSidebarDirective
    ],
    declarations: [
        FlyFreelyMapComponent,
        MapFeatureSelectorComponent,
        MapToolbarComponent,
        MapToolbarMeasurementButtonComponent,
        MapToolbarDeleteButtonComponent,
        MapMarkerToolbarComponent,
        JurisdictionSelector,
        MapSidebarDirective,
        MapMeasurementInteractionDirective,
        GeocoderDirective
    ],
    providers: [
        {
            provide: MAPBOX_GEOCODER_API_KEY,
            useValue:
                'pk.eyJ1IjoibnNpbSIsImEiOiJjamRvNmZ1b3gwajNkMndwcXBvb2J5NnhxIn0.Nm6YB1avHyif3piBnfWSeA'
        },
        {
            provide: MAPBOX_API_KEY,
            useValue:
                'pk.eyJ1IjoibnNpbSIsImEiOiJjamRvNmZ1b3gwajNkMndwcXBvb2J5NnhxIn0.Nm6YB1avHyif3piBnfWSeA'
        }
    ]
})
export class MapModule {}
