import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatDuration' })
export class FormatDurationPipe implements PipeTransform {
    transform(seconds: number): string | null {
        if (seconds == null) {
            return null;
        }
        const isNegative = seconds < 0;
        if (isNegative) {
            seconds = seconds * -1;
        }
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.round(seconds % 60);

        if (hrs > 0) {
            return `${isNegative ? '-' : ''}${hrs}h ${mins}m ${secs}s`;
        } else if (mins > 0) {
            return `${isNegative ? '-' : ''}${mins}m ${secs}s`;
        } else {
            return `${isNegative ? '-' : ''}${secs}s`;
        }
    }
}
