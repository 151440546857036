import { Component, Input } from '@angular/core';
import { PersonsOrganisationDto } from '@flyfreely-portal-ui/flyfreely';
import {
    OrganisationSubscriptionState,
    SubscriptionAndDetails
} from 'libs/subscriptions/src/lib/organisation-subscription-state.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'upgrade-subscription-dialogue',
    template: `
        <div class="modal-header">
            <h3 class="modal-title">Upgrade current subscription</h3>
            <button
                type="button"
                (click)="hide()"
                aria-label="Close"
                class="close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body inner-container" style="align-items: center">
            <div class="modal-body-content container-scroll">
                <purchase-subscription
                    [organisation]="organisation"
                    [subscriptionId]="subscriptionId"
                    [showUpgrade]="showUpgrade"
                    [subscriptionDetails]="subscriptionDetails"
                    (uptrialSelected)="hide()"
                ></purchase-subscription>
            </div>
        </div>
    `
})
export class UpgradeSubscriptionDialogue {
    @Input() organisation: PersonsOrganisationDto;

    @Input() subscriptionId?: number;

    @Input() showUpgrade: boolean = false;

    @Input() subscriptionDetails: SubscriptionAndDetails;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private modal: BsModalRef<UpgradeSubscriptionDialogue>,
        private state: OrganisationSubscriptionState
    ) {}

    ngOnInit() {
        this.state.subscriptionUpdated$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(updated => this.hide());
    }

    hide() {
        this.modal.hide();
    }
}
