import { MissionRoleDto, CraftDto } from '@flyfreely-portal-ui/flyfreely';
import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { toLookup } from '@flyfreely-portal-ui/flyfreely';

export function validateCrew(crewRoles: MissionRoleDto[]): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (control.errors && !control.errors.allRequired) {
            return null;
        }

        const countByRole =
            control != null
                ? control.value.reduce(
                      (acc: any, r: any) => ({
                          ...acc,
                          [r.missionRoleId]:
                              r.missionRoleId in acc
                                  ? acc[r.missionRoleId] + 1
                                  : 1
                      }),
                      {}
                  )
                : {};

        const allRequired =
            crewRoles != null
                ? crewRoles.reduce(
                      (acc, r) => acc && (!r.required || countByRole[r.id] > 0),
                      true
                  )
                : false;

        if (!allRequired) {
            return { allRequired: true };
        } else {
            return null;
        }
    };
}

export function validateRpaNotDummy(rpas: CraftDto[]): ValidatorFn {
    const rpaLookup = rpas.reduce(toLookup, {});
    return (control: FormControl): ValidationErrors => {
        const value: number[] = control.value;
        if (value == null) {
            return null;
        }
        const anyDummy = value.reduce(
            (acc, v) => acc || (rpaLookup[v] != null && rpaLookup[v].isDummy),
            false
        );
        if (anyDummy) {
            return { isDummy: true };
        }
        return null;
    };
}
