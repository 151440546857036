<div
    id="dashboard"
    class="container-fluid"
    screenAnalytics="dashboard"
    *ngIf="currentOrganisation"
>
    <div class="row">
        <div class="col-xs-12 col-lg-12 topbar bottom-buffer">
            <div class="fill">
                <div *ngIf="systemNotice != null" class="alert alert-warning">
                    <span class="fal fa-info-circle right-buffer"></span
                    ><span [innerHtml]="systemNotice"></span>
                </div>
                <subscription-banner
                    *ngIf="systemNotice == null"
                    (createOrganisation)="showCreateCommercialOrganisation()"
                    (requestAccess)="requestAccess()"
                ></subscription-banner>
            </div>
        </div>
        <div
            class="col-xs-12 col-lg-12 top-buffer"
            *ngIf="currentOrganisation.type === 'no_access'"
        >
            <div class="alert alert-warning">
                You do not have access to this organisation. Please select from
                the available organisations in the left hand navigation bar.
            </div>
        </div>
        <div
            class="col-xs-12 col-lg-12 top-buffer"
            *ngIf="
                currentOrganisation.type === 'no_licence' &&
                !currentOrganisation.organisation.owner
            "
        >
            <div class="alert alert-warning">
                You do not have an active licence to use FlyFreely in this
                organisation. Please contact the organisation administrators to
                be assigned a licence.
            </div>
        </div>

        <div
            class="col-xs-12 col-lg-12"
            *ngIf="
                currentOrganisation.type === 'no_licence' &&
                currentOrganisation.organisation.owner
            "
        >
            <div class="alert alert-warning">
                You do not have an active licence to use FlyFreely in this
                organisation. Please setup a subscription to gain access to this
                organisation.
            </div>
        </div>
    </div>

    <div class="row" *ngIf="showingWidgets$ | async as showingWidgets">
        <div
            class="col-xs-12 col-lg-12"
            *ngFor="let widget of showingWidgets"
            @fadeInExpandOnEnter
            @fadeOutCollapseOnLeave
        >
            <ng-container [widget]="widget"></ng-container>
        </div>
    </div>
</div>
<!-- Required to show dialogues -->
<router-outlet></router-outlet>
