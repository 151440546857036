<div class="footer-main--container">
    <div class="footer_container personal-footer">
        <ng-container *ngIf="!isMobile">
            <!-- TAB 1 -->
            <div
                class="footer_personaldetails footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 1,
                    settick: tabId > 1
                }"
            >
                <!-- The mouse actions allow the footer to animate on mouse events, but it still needs a click option for tablets. -->
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container" (click)="titles = !titles">
                        <img imgPath="/onboarding/personal-icon.png" />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Personal Profile
                    </div>
                </div>
            </div>
            <!-- TAB 2 -->
            <div
                class="footer_personalisflying footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 2,
                    settick: tabId > 2
                }"
            >
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container" (click)="titles = !titles">
                        <img imgPath="/onboarding/flight-icon.png" />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Flying Decision
                    </div>
                </div>
            </div>

            <!-- TAB 3 -->
            <div
                class="footer_personalcertification footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 3,
                    settick: tabId > 3
                }"
            >
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container" (click)="titles = !titles">
                        <img
                            imgPath="/onboarding/personal-certificate-icon.png"
                        />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Personal Authorities
                    </div>
                </div>
            </div>
            <!-- TAB 4 -->
            <!-- <div
                class="footer_personalprimary footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 4,
                    settick: tabId>4
                }"
            >
                <div class="footer_skew-position">
                    <div class="img_container">
                        <img imgPath="/onboarding/personal-certificate-icon.png" />
                    </div>
                    <div class="footer_title">Personal Authorities II</div>
                </div>
            </div> -->
            <!-- TAB 5 -->
            <div
                class="footer_personaldetails_complete footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 4
                }"
            >
                <div
                    class="footer_skew-position"
                    (mouseenter)="titles = true"
                    (mouseleave)="titles = false"
                >
                    <div class="img_container" (click)="titles = !titles">
                        <img imgPath="/onboarding/setup-icon.png" />
                    </div>
                    <div class="footer_title" [@collapse]="!titles">
                        Company Decision
                    </div>
                    <!-- <div class="footer_subtitle">
                        Personal Account Setup and Next Steps
                    </div> -->
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <!-- TAB 1 -->
            <div
                class="footer_personaldetails footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 1,
                    settick: tabId > 1
                }"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Personal Profile
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img imgPath="/onboarding/personal-icon.png" />
                    </div>
                </div>
            </div>
            <!-- TAB 2 -->
            <div
                class="footer_personalisflying footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 2,
                    settick: tabId > 2
                }"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Flying Decision
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img imgPath="/onboarding/flight-icon.png" />
                    </div>
                </div>
            </div>

            <!-- TAB 3 -->
            <div
                class="footer_personalcertification footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 3,
                    settick: tabId > 3
                }"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Personal Authorities
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img
                            imgPath="/onboarding/personal-certificate-icon.png"
                        />
                    </div>
                </div>
            </div>
            <!-- TAB 4 -->
            <!-- <div
                class="footer_personalprimary footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 4,
                    settick: tabId>4
                }"
            >
                <div class="footer_skew-position">
                    <div class="img_container">
                        <img imgPath="/onboarding/personal-certificate-icon.png" />
                    </div>
                    <div class="footer_title">Personal Authorities II</div>
                </div>
            </div> -->
            <!-- TAB 5 -->
            <div
                class="footer_personaldetails_complete footer_icon--container"
                [ngClass]="{
                    setfinished: tabId >= 4
                }"
            >
                <div class="footer_skew-position" (click)="titles = !titles">
                    <div class="footer_title" [@collapse]="!titles">
                        Company Decision
                    </div>
                    <div class="img_container" [@collapse]="titles">
                        <img imgPath="/onboarding/setup-icon.png" />
                    </div>
                    <!-- <div class="footer_subtitle">
                        Personal Account Setup and Next Steps
                    </div> -->
                </div>
            </div>
        </ng-container>
    </div>
</div>
