import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { FlyFreelyVideoModule } from 'libs/flyfreely-video/src/lib/flyfreely-video.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { ActivityStreamDialoguesService } from './activity-stream-dialogues.service';
import { ActivityStreamComponent } from './activity-stream.component';
import { ActivityVideoPlayerDialogue } from './activity-video/activity-video.component';
import { MiniVideoPlayerComponent } from './activity-video/mini-video-player.component';
import { ActivityCardComponent } from './cards/activity-card.component';
import { NotificationCard } from './cards/notification-card.component';

@NgModule({
    imports: [
        CommonModule,
        FlyFreelyVideoModule,
        LoadingIndicatorModule,
        AnalyticsModule,
        Angulartics2Module
    ],
    providers: [ActivityStreamDialoguesService],
    declarations: [
        ActivityStreamComponent,
        ActivityCardComponent,
        ActivityVideoPlayerDialogue,
        MiniVideoPlayerComponent,
        NotificationCard
    ],
    exports: [
        ActivityStreamComponent,
        ActivityCardComponent,
        ActivityVideoPlayerDialogue,
        MiniVideoPlayerComponent,
        NotificationCard
    ]
})
export class SidebarActivityStreamModule {}
