import { Component, EventEmitter, Output } from '@angular/core';
import { AirspaceCheckService } from '.';
import { Subject, takeUntil } from 'rxjs';
import {
    AirspaceCheckDto,
    RuleCodeDto,
    RuleOutcome
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';

interface AirspaceRuleOutcomeLookup {
    [ruleCode: string]: RuleOutcome[];
}

/**
 * This is a component that displays just the results, and provides callbacks to for all UI
 * interactions.
 */
@Component({
    selector: 'airspace-check-results',
    template: `<div
        *ngIf="status != 'NO_CHECK'"
        class="vertical-container airspace-check-result-container"
    >
        <div
            class="airspace-section"
            [ngClass]="{
                blocks:
                    status != 'CHECKING' &&
                    status != 'NO_CHECK' &&
                    blockCodes.length,
                'blocks-disabled':
                    status == 'CHECKING' ||
                    status == 'NO_CHECK' ||
                    !blockCodes.length
            }"
        >
            <button
                type="button"
                class="airspace-check-dropdown"
                [disabled]="
                    result == null ||
                    status == 'CHECKING' ||
                    status == 'NO_CHECK' ||
                    !blockCodes.length
                "
                (click)="
                    blockCodes?.length
                        ? (showBlockers = !showBlockers)
                        : (showBlockers = showBlockers)
                "
            >
                <span class="block result-section">
                    <span class="icon fad fa-times-circle"></span>
                    &nbsp;&nbsp;Block
                </span>
                <div class="horizontal-container vertical-align">
                    <span class="block-count">{{
                        blockCodes?.length ? blockCodes.length : ' '
                    }}</span>
                    <span
                        class="fa"
                        [ngClass]="{
                            'fa-caret-down': !showBlockers,
                            'fa-caret-up': showBlockers
                        }"
                    ></span>
                </div>
            </button>
            <div
                @expandOnEnter
                @collapseOnLeave
                *ngIf="
                    showBlockers &&
                    result != null &&
                    blockCodes.length &&
                    status != 'CHECKING' &&
                    status != 'NO_CHECK'
                "
            >
                <ng-container *ngFor="let blockCode of blockCodes">
                    <button
                        type="button"
                        class="btn btn-default btn-xs rule-button"
                        (click)="viewRuleDetails(blockCode, 'BLOCK')"
                    >
                        <div class="rule-count blocks">
                            {{
                                blockRuleLookup[blockCode] != null
                                    ? blockRuleLookup[blockCode].length
                                    : 0
                            }}
                        </div>
                        <span
                            *ngIf="messages[blockCode] == null"
                            class="rule-name"
                        >
                            {{
                                blockCode != null ? blockCode : 'Blocking Rule'
                            }}
                        </span>
                        <span
                            *ngIf="messages[blockCode] != null"
                            class="rule-name"
                            >{{ messages[blockCode].name }}</span
                        >
                    </button>
                </ng-container>
            </div>
        </div>
        <div
            class="airspace-section"
            [ngClass]="{
                advises:
                    status != 'CHECKING' &&
                    status != 'NO_CHECK' &&
                    adviseCodes.length,
                'advises-disabled':
                    status == 'CHECKING' ||
                    status == 'NO_CHECK' ||
                    !adviseCodes.length
            }"
        >
            <button
                type="button"
                class="airspace-check-dropdown"
                [disabled]="
                    result == null ||
                    status == 'CHECKING' ||
                    status == 'NO_CHECK' ||
                    !adviseCodes.length
                "
                (click)="
                    adviseCodes?.length
                        ? (showAdvises = !showAdvises)
                        : (showAdvises = showAdvises)
                "
            >
                <div class="advise result-section">
                    <span class="icon fad fa-exclamation-circle"></span>
                    <span>&nbsp;&nbsp;Advise</span>
                </div>
                <div class="horizontal-container vertical-align">
                    <span class="advise-count">{{
                        adviseCodes?.length ? adviseCodes.length : ' '
                    }}</span>
                    <span
                        class="left-buffer fa"
                        [ngClass]="{
                            'fa-caret-down': !showAdvises,
                            'fa-caret-up': showAdvises
                        }"
                    ></span>
                </div>
            </button>
            <div
                @expandOnEnter
                @collapseOnLeave
                *ngIf="
                    showAdvises &&
                    result != null &&
                    adviseCodes.length &&
                    status != 'CHECKING' &&
                    status != 'NO_CHECK'
                "
            >
                <ng-container *ngFor="let adviseCode of adviseCodes">
                    <button
                        type="button"
                        class="btn btn-default btn-xs rule-button"
                        (click)="viewRuleDetails(adviseCode, 'ADVISE')"
                    >
                        <div class="rule-count advises">
                            {{
                                adviseRuleLookup[adviseCode] != null
                                    ? adviseRuleLookup[adviseCode].length
                                    : 0
                            }}
                        </div>
                        <span
                            *ngIf="messages[adviseCode] == null"
                            class="rule-name"
                        >
                            {{
                                adviseCode != null
                                    ? adviseCode
                                    : 'Advisory Rule'
                            }}
                        </span>
                        <span
                            *ngIf="messages[adviseCode] != null"
                            class="rule-name"
                            >{{ messages[adviseCode].name }}</span
                        >
                    </button>
                </ng-container>
            </div>
        </div>
        <div
            class="airspace-section"
            [ngClass]="{
                passes:
                    status != 'CHECKING' &&
                    status != 'NO_CHECK' &&
                    passCodes.length,
                'passes-disabled':
                    status == 'CHECKING' ||
                    status == 'NO_CHECK' ||
                    !passCodes.length
            }"
        >
            <button
                type="button"
                class="airspace-check-dropdown"
                [disabled]="
                    result == null ||
                    status == 'CHECKING' ||
                    status == 'NO_CHECK' ||
                    !passCodes.length
                "
                (click)="
                    passCodes?.length
                        ? (showPasses = !showPasses)
                        : (showPasses = showPasses)
                "
            >
                <div class="pass result-section">
                    <span class="icon fad fa-check-circle"></span>
                    &nbsp;&nbsp;Pass
                </div>
                <div class="horizontal-container vertical-align">
                    <span class="pass-count">{{
                        passCodes.length ? passCodes?.length : ' '
                    }}</span>
                    <span
                        class="left-buffer fa"
                        [ngClass]="{
                            'fa-caret-down': !showPasses,
                            'fa-caret-up': showPasses
                        }"
                    ></span>
                </div>
            </button>
            <div
                @expandOnEnter
                @collapseOnLeave
                *ngIf="
                    showPasses &&
                    result != null &&
                    passCodes.length &&
                    status != 'CHECKING' &&
                    status != 'NO_CHECK'
                "
            >
                <ng-container *ngFor="let passCode of passCodes">
                    <button
                        type="button"
                        class="btn btn-default btn-xs rule-button"
                        (click)="viewRuleDetails(passCode, 'PASS')"
                    >
                        <div
                            class="rule-count passes"
                            *ngIf="hasPassReasons(passCode)"
                        >
                            {{
                                passRuleLookup[passCode] != null
                                    ? passRuleLookup[passCode].length
                                    : 0
                            }}
                        </div>
                        <div
                            class="rule-count passes"
                            *ngIf="!hasPassReasons(passCode)"
                        >
                            <span class="fa fa-check"></span>
                        </div>
                        <span
                            *ngIf="messages[passCode] == null"
                            class="rule-name"
                        >
                            {{ passCode != null ? passCode : 'Passing Rule' }}
                        </span>
                        <span
                            *ngIf="messages[passCode] != null"
                            class="rule-name"
                            >{{ messages[passCode].name }}</span
                        >
                    </button>
                </ng-container>
            </div>
        </div>
        <airspace-unavailable-alert
            [show]="operationFailed"
        ></airspace-unavailable-alert>
        <airspace-unavailable-alert
            [show]="
                externalAirspaceError != null &&
                externalAirspaceError.code != null &&
                !operationFailed
            "
            [customStatusMessage]="
                externalAirspaceError?.message != null
                    ? 'An error occurred:'
                    : 'An error occurred while checking the airspace.'
            "
            [customStatusText]="
                externalAirspaceError?.message != null
                    ? externalAirspaceError.message
                    : ''
            "
        ></airspace-unavailable-alert>
    </div>`,
    styleUrls: ['./airspace-check-results.component.scss', '../styles.scss'],
    animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()]
})
export class AirspaceCheckResults {
    @Output() ruleResultClick = new EventEmitter<{
        code: string;
        type: 'BLOCK' | 'ADVISE' | 'PASS';
    }>();
    status: string;
    result: AirspaceCheckDto;

    blockCodes: string[] = [];
    adviseCodes: string[] = [];
    passCodes: string[] = [];

    showBlockers: boolean;
    showAdvises: boolean;
    showPasses: boolean;

    messages: { [code: string]: RuleCodeDto };

    blockRuleLookup: AirspaceRuleOutcomeLookup;
    adviseRuleLookup: AirspaceRuleOutcomeLookup;
    passRuleLookup: AirspaceRuleOutcomeLookup;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(private airspaceCheckService: AirspaceCheckService) {
        this.airspaceCheckService.resultStatus$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => (this.status = result));

        this.airspaceCheckService.result$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => this.refreshResult(result));
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshResult(result: AirspaceCheckDto) {
        this.result = result;

        // Reset data and break if nothing to display.
        this.blockRuleLookup = {};
        this.adviseRuleLookup = {};
        this.passRuleLookup = {};
        this.blockCodes = [];
        this.adviseCodes = [];
        this.passCodes = [];

        if (result == null) {
            this.showBlockers = false;
            return;
        }

        this.messages = result.ruleMessages;
        this.blockRuleLookup = result?.ruleOutcomes
            .filter(o => o.outcome === RuleOutcome.Outcome.BLOCK)
            .reduce((acc, o) => {
                const ruleCode = o.code;
                if (Object.keys(acc).includes(ruleCode)) {
                    acc[ruleCode].push(o);
                    return acc;
                } else {
                    return { ...acc, [ruleCode]: [o] };
                }
            }, {}) as AirspaceRuleOutcomeLookup;
        this.adviseRuleLookup = result?.ruleOutcomes
            .filter(
                o =>
                    o.outcome === RuleOutcome.Outcome.ADVISE ||
                    o.outcome === RuleOutcome.Outcome.AUTHORISATION_REQUIRED
            )
            .reduce((acc, o) => {
                const ruleCode = o.code;
                if (Object.keys(acc).includes(ruleCode)) {
                    acc[ruleCode].push(o);
                    return acc;
                } else {
                    return { ...acc, [ruleCode]: [o] };
                }
            }, {}) as AirspaceRuleOutcomeLookup;
        this.passRuleLookup = result?.ruleOutcomes
            .filter(o => o.outcome === RuleOutcome.Outcome.PASS)
            .reduce((acc, o) => {
                const ruleCode = o.code;
                if (Object.keys(acc).includes(ruleCode)) {
                    acc[ruleCode].push(o);
                    return acc;
                } else {
                    return { ...acc, [ruleCode]: [o] };
                }
            }, {}) as AirspaceRuleOutcomeLookup;

        this.blockCodes = Object.keys(this.blockRuleLookup);
        this.adviseCodes = Object.keys(this.adviseRuleLookup);
        this.passCodes = Object.keys(this.passRuleLookup);

        this.showBlockers = this.blockCodes.length > 0;
    }

    hasPassReasons(passCode: string) {
        const codes = this.passRuleLookup[passCode].filter(
            c => c.reason != null
        );
        return codes.length > 0;
    }

    viewRuleDetails(code: string, type: 'BLOCK' | 'ADVISE' | 'PASS') {
        this.ruleResultClick.emit({ code, type });
    }
}
