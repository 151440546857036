<div class="notam-details">
    <div class="data result-container">
        <div class="notam-selector">
            <div>
                {{ notam.id }} {{ notam.location }} {{ entity }}
                <!-- <span *ngIf="!selected == false && !listOnly" class="fa fa-check selected-item left-buffer"></span> -->
            </div>
            <button
                *ngIf="!isExpanded"
                type="button"
                (click)="isExpanded = !isExpanded"
                class="btn btn-tertiary"
            >
                <span class="fa fa-chevron-down"></span>
            </button>
            <button
                *ngIf="isExpanded"
                type="button"
                (click)="isExpanded = !isExpanded"
                class="btn btn-tertiary"
            >
                <span class="fa fa-chevron-up"></span>
            </button>
        </div>
        <button
            type="button"
            class="btn btn-tertiary"
            tooltip="View full NOTAM"
            placement="top"
            (click)="onViewNotamDetail()"
        >
            <span class="fal fa-square-list"></span>
        </button>
        <button
            *ngIf="listOnly && !disableActions"
            type="button"
            class="btn btn-tertiary btn-delete"
            (click)="deleteNotam.emit()"
        >
            <span class="fal fa-trash-alt"></span>
        </button>
    </div>
    <div class="contents top-buffer" @collapse *ngIf="isExpanded">
        <div class="horizontal-container notam-information-container">
            <div class="notam-dates">
                <span class="display-label">Start Time</span>
                <div class="left-buffer">
                    {{ startTime }}
                </div>
            </div>
            <div class="notam-dates">
                <span class="display-label">End Time</span>
                <div class="left-buffer">
                    {{ endTime }}
                </div>
            </div>
            <div *ngIf="status != ''" class="notam-dates">
                <span class="display-label">Subject</span>
                <div class="left-buffer">{{ entity }} {{ status }}</div>
            </div>
        </div>
        <span class="display-label top-buffer">Message</span>
        <div class="notam-data">
            {{ notam.message }}
        </div>
    </div>
</div>
