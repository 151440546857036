import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FormDto,
    FormService,
    FormSummaryDto,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import {
    Entity
} from 'libs/ui/src/lib/entity-list/entity-list.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentationDialoguesService } from '../documentation-dialogues.service';
import { FormCatalogue } from '../form-catalogue/form-catalogue.component';
@Component({
    selector: 'form-list',
    templateUrl: './form-list.component.html',
    host: {
        class: 'container-with-footer'
    }
})
export class FormList implements OnInit, OnDestroy {
    @Input() organisation: PersonsOrganisationDto;

    forms: Entity[];
    formsWithGlobal: Entity[];
    canEdit: boolean;
    showArchived = false;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private formService: FormService,
        private documentationDialoguesService: DocumentationDialoguesService,
        private modalService: BsModalService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshForms();

        this.formService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshForms());

        this.canEdit =
            this.organisation &&
            this.organisation.permissions.indexOf(
                PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
            ) !== -1;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshForms() {
        this.formService
            .findForms(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(forms => {
                this.formsWithGlobal = forms
                    .map(f => ({ ...f, organisationId: f.organisation?.id }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.forms = this.formsWithGlobal.filter(
                    f => !!f.organisationId
                );
            })
            .add(this.workTracker.createTracker());
    }

    createForm() {
        const organisationId = this.organisation.id;
        const form: FormDto = {
            organisationId,
            availableActions: { canEdit: true, canDelete: true },
            versions: [],
            name: null,
            id: null,
            archived: false
        };
        this.documentationDialoguesService.showFormEdit(form, organisationId);
    }

    archiveForm(form: FormSummaryDto) {
        this.formService
            .archiveForm(form.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => (form.archived = results.archived))
            .add(this.workTracker.createTracker());
    }

    unarchiveForm(form: FormSummaryDto) {
        this.formService
            .unarchiveForm(form.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(results => (form.archived = results.archived))
            .add(this.workTracker.createTracker());
    }

    formDetails(form: FormSummaryDto) {
        this.formService
            .findForm(form.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(_form =>
                this.documentationDialoguesService.showFormEdit(
                    _form,
                    this.organisation.id
                )
            );
    }

    cloneForm(form: FormSummaryDto) {
        const command = {
            formId: form.id,
            organisationId: this.organisation.id
        };
        this.formService
            .cloneForm(command)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(formRet => {
                this.documentationDialoguesService.showFormEdit(
                    formRet,
                    this.organisation.id
                );
            });
    }

    browseCatalogue() {
        const modal = this.modalService.show(FormCatalogue, {
            ...MODAL_OPTIONS,
            initialState: {
                canAdd: false,
                items: this.formsWithGlobal.filter(f => !f.organisationId),
                working: this.working,
                type: 'Form',
                canClone: true,
                canArchive: false,
                organisationId: this.organisation?.id
            }
        });

        return modal;
    }
}
