import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    MissionWorkflowService,
    PersonsOrganisationDto,
    SimpleMissionWorkflowDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { MissionDialoguesService } from 'libs/missions/src/lib/mission-dialogues.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type ItemStatus = 'PENDING' | 'ACTIVE' | 'DISABLED' | 'EXPIRED' | 'UNAVAILABLE';

export interface WorkflowListItem {
    id: number;
    name: string;
    availableActions: {
        canEdit: boolean;
    };
    archived: boolean;
    authorityName: string;
    ruleset: string;
    jurisdiction: string;
    status: ItemStatus;
    activeVersionId: number;
}

function toStatus(w: SimpleMissionWorkflowDto): ItemStatus {
    if (w.activeVersionId == null) {
        return 'DISABLED';
    }
    if (
        w.delegatedAuthority == null ||
        w.delegatedAuthority?.status === 'ACTIVE'
    ) {
        return 'ACTIVE';
    }

    return w.delegatedAuthority?.status;
}

@Component({
    selector: 'mission-workflow-list',
    templateUrl: './mission-workflow-list.component.html'
})
export class MissionWorkflowList implements OnInit, OnDestroy {
    @Input() organisation: PersonsOrganisationDto;
    workflows: WorkflowListItem[];

    showArchived = false;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private missionWorkflowService: MissionWorkflowService,
        private missionDialoguesService: MissionDialoguesService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.missionWorkflowService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshWorkflows());

        this.refreshWorkflows();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshWorkflows() {
        this.missionWorkflowService
            .findWorkflows(this.organisation.id)
            .subscribe({
                next: workflows => {
                    this.workflows = workflows
                        .map(w => ({
                            id: w.id,
                            name: w.name,
                            availableActions: {
                                canEdit: w.availableActions.canEdit
                            },
                            archived: w.archived,
                            authorityName:
                                w.delegatedAuthority?.authorityType?.name,
                            ruleset:
                                w.delegatedAuthority?.authorityType?.ruleset
                                    ?.name,
                            jurisdiction:
                                w.delegatedAuthority?.authorityType
                                    ?.jurisdiction?.name,
                            status: toStatus(w),
                            activeVersionId: w.activeVersionId
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name));
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while fetching workflows: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    doEditWorkflow(workflow: WorkflowListItem) {
        this.editWorkflow(workflow);
        // .then(() => this.refreshWorkflows(), () => this.refreshWorkflows());
    }

    editWorkflow(workflow: WorkflowListItem) {
        this.missionWorkflowService
            .findWorkflowById(workflow.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missionWorkflow => {
                return this.missionDialoguesService.showMissionWorkflowDialogue(
                    missionWorkflow
                );
            });
    }

    doCreateWorkflow() {
        this.createWorkflow();
        // .then(() => this.refreshWorkflows(), () => angular.noop());
    }

    createWorkflow() {
        return this.missionDialoguesService.showMissionWorkflowDialogue(
            {
                organisationId: this.organisation.id,
                id: null,
                archived: false,
                versions: [],
                availableActions: { canEdit: true },
                workgroupIdList: []
            }
        );
    }

    archiveWorkflow(workflow: WorkflowListItem) {
        this.missionWorkflowService
            .archiveWorkflow(workflow.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    workflow.archived = results.archived;
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while archiving workflow: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    unarchiveWorkflow(workflow: WorkflowListItem) {
        this.missionWorkflowService
            .unarchiveWorkflow(workflow.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                results => {
                    workflow.archived = results.archived;
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while unarchiving workflow: ${error.message}`
                    );
                }
            )
            .add(this.workTracker.createTracker());
    }

    displayableStatus(status: ItemStatus) {
        switch (status) {
            case 'ACTIVE':
                return 'Active';
            case 'DISABLED':
                return 'Disabled';
            case 'EXPIRED':
                return 'Expired';
            case 'UNAVAILABLE':
                return 'Unavailable';
            case 'PENDING':
                return 'Not Yet Active';
        }
    }
}
