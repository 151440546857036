import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AuthorityTypeDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OnboardingAuthorityGroup,
    OrganisationAuthorityDto,
    OrganisationAuthorityGroup,
    OrganisationAuthorityService,
    OrganisationService,
    SimpleAirspaceJurisdictionDto,
    UserService,
    WorkTracker,
    OnboardingPhases,
    OnboardingService
} from '@flyfreely-portal-ui/flyfreely';
import { AuthorityDialoguesService } from 'libs/authorities/src/lib/authority-dialogues.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { OnboardingData } from '../../onboarding-screens-data.service';

@Component({
    selector: 'organisation-certification',
    templateUrl: './organisation-certification.component.html',
    styleUrls: ['./organisation-certification.component.scss']
})
export class OrganisationCertificationComponent implements OnInit {
    organisationCertificationForm: FormGroup;
    availableJurisdictions: SimpleAirspaceJurisdictionDto[];
    authorityGroups: OnboardingAuthorityGroup[];
    organisationId: number;
    hasRequestedSales: number[];
    hasAddedAllRequired = true;

    private workTracker: WorkTracker = new WorkTracker();
    working: boolean = false;
    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private obsds: OnboardingData,
        private organisationService: OrganisationService,
        private organisationAuthorityService: OrganisationAuthorityService,
        private authorityDialoguesService: AuthorityDialoguesService,
        private onboardingService: OnboardingService,
        private userService: UserService,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.organisationAuthorityService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.refreshAuthorities();
            });

        this.organisationId = this.obsds.organisationId;

        this.findJurisdictions();
        this.refreshAuthorities();
        this.hasRequestedSales = this.obsds.requestedCommercialSales;
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    findJurisdictions() {
        const doneWorking = this.workTracker.createTracker();

        this.organisationService
            .findById(this.organisationId, this.organisationId)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                mergeMap(
                    org =>
                        (this.availableJurisdictions = org.activeJurisdictions)
                )
            )
            .subscribe({
                next: () => doneWorking(),
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while finding jurisdictions: ${error.message}`
                    );
                    doneWorking();
                }
            })
            .add(this.workTracker.createTracker());
    }

    refreshAuthorities() {
        const doneWorking = this.workTracker.createTracker();

        this.authorityGroups = [];
        this.onboardingService
            .findAuthoritiesForOnboarding(
                this.organisationId,
                this.organisationId,
                OnboardingPhases.COMMERCIAL,
                this.obsds.commercialJurisdictionId
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: authorities => {
                    this.authorityGroups = authorities.sort((a, b) =>
                        this.requiredByAuthority(a) &&
                        this.requiredByAuthority(a).authorities.filter(
                            auth => auth.id === b.id
                        ).length === 0
                            ? 0
                            : this.requiredByAuthority(a) &&
                              this.requiredByAuthority(a).authorities.filter(
                                  auth => auth.id === b.id
                              ).length > 0
                            ? 1
                            : -1
                    );
                    this.hasAddedAllRequired = this.authorityGroups.reduce(
                        (acc, a) =>
                            acc &&
                            this.requiredByAuthority(a) != null &&
                            this.requiredByAuthority(a).authorities?.length > 0
                                ? this.hasAuthority(a)
                                : true,
                        true
                    );
                    doneWorking();
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while refreshing authorities: ${error.message}`
                    );
                    doneWorking();
                }
            })
            .add(this.workTracker.createTracker());
    }

    contactSales(authorityGroup: OnboardingAuthorityGroup) {
        const doneWorking = this.workTracker.createTracker();

        this.userService
            .contactSales({
                message: `I would like more information on the following authority: ${authorityGroup.name}`
            })
            .subscribe(
                () => {
                    this.hasRequestedSales.push(authorityGroup.id);
                    this.obsds.requestedCommercialSales =
                        this.hasRequestedSales;
                    this.logging.success(
                        `A message has been sent to our sales team. They will be in contact soon.`
                    );
                    doneWorking();
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while contacting sales: ${error.message}`
                    );
                    doneWorking();
                }
            );
    }
    hasContactedSales(authorityGroup: OnboardingAuthorityGroup) {
        return this.hasRequestedSales.find(id =>
            id === authorityGroup.id ? true : false
        );
    }

    previousScreen() {
        this.obsds.backToOrganisationDetails();
    }

    nextScreen() {
        this.obsds.organisationCertificationCompleted();
    }

    hasAuthority(authorityGroup: OrganisationAuthorityGroup) {
        if (authorityGroup.authorities.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    requiredAuthorityAdded(
        authorityGroup: OrganisationAuthorityGroup | AuthorityTypeDto
    ) {
        if (authorityGroup.dependentOnAuthorityType == null) {
            return true;
        }
        const requiredAuthorityGroup = this.authorityGroups.find(
            a => a.id === authorityGroup.dependentOnAuthorityType.id
        );
        return (
            requiredAuthorityGroup != null &&
            requiredAuthorityGroup.authorities.length > 0
        );
    }

    requiredByAuthority(authorityGroup: OrganisationAuthorityGroup) {
        return this.authorityGroups.find(
            a =>
                a.dependentOnAuthorityType != null &&
                a.dependentOnAuthorityType.id === authorityGroup.id
        );
    }
    addAuthority(authorityGroup: AuthorityTypeDto) {
        if (
            authorityGroup.issuingMechanism !== 'SELF_REPORTED' ||
            !this.requiredAuthorityAdded(authorityGroup)
        ) {
            return;
        }
        const dependentOnAuthorityGroup =
            authorityGroup.dependentOnAuthorityType != null
                ? this.authorityGroups.find(
                      a => a.id === authorityGroup.dependentOnAuthorityType.id
                  )
                : null;

        const activeDependentAuthority =
            dependentOnAuthorityGroup == null
                ? null
                : dependentOnAuthorityGroup.authorities.length === 1
                ? dependentOnAuthorityGroup.authorities[0]
                : dependentOnAuthorityGroup.authorities.find(
                      a =>
                          (moment(a.startDate).isBefore(moment()) &&
                              a.expiryDate == null) ||
                          moment(a.expiryDate).isAfter(moment())
                  );

        this.authorityDialoguesService.showAuthorityEdit(
            this.organisationId,
            authorityGroup,
            null,
            this.organisationId,
            this.organisationAuthorityService,
            activeDependentAuthority
        );
    }
    editAuthority(
        authorityGroup: AuthorityTypeDto,
        authority: OrganisationAuthorityDto
    ) {
        if (authorityGroup.issuingMechanism !== 'SELF_REPORTED') {
            return;
        }
        this.authorityDialoguesService.showAuthorityEdit(
            this.organisationId,
            authorityGroup,
            authority,
            this.organisationId,
            this.organisationAuthorityService
        );
    }
}
