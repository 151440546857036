import {
    CreateIncidentReportCommand,
    UpdateIncidentReportCommand,
    IncidentReportDto,
    AdminIncidentReportDto,
    NewIncidentReportCommand
} from '../model/api';
import { AttachmentHandler } from './attachments';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

interface IncidentReportsCriteria {
    organisationId: number;
    missionId?: number;
    rpaId?: number;
    pilotId?: number;
    resolved?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class IncidentReportsService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(criteria: IncidentReportsCriteria) {
        let params = new HttpParams().set(
            'organisationId',
            criteria.organisationId.toString()
        );
        if (criteria) {
            if (criteria.missionId) {
                params = params.set('missionId', criteria.missionId.toString());
            }
            if (criteria.rpaId) {
                params = params.set('rpaId', criteria.rpaId.toString());
            }
            if (criteria.pilotId) {
                params = params.set('related', criteria.pilotId.toString());
            }
            if (criteria.resolved) {
                params = params.set('resolved', criteria.resolved.toString());
            }
        }

        return this.http.get<IncidentReportDto[]>(
            `${this.baseUrl}/webapi/incidentReports`,
            { params: params }
        );
    }

    findById(incidentReportId: number) {
        return this.http.get<IncidentReportDto>(
            `${this.baseUrl}/webapi/incidentReports/${incidentReportId}`
        );
    }

    newIncidentReport(command: NewIncidentReportCommand) {
        return this.http
            .post<IncidentReportDto>(
                `${this.baseUrl}/webapi/incidentReports`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    createIncidentReport(
        incidentReportId: number,
        command: CreateIncidentReportCommand
    ) {
        return this.http
            .put<IncidentReportDto>(
                `${this.baseUrl}/webapi/incidentReports/${incidentReportId}/create`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    cancelIncidentReport(incidentReportId: number) {
        return this.http
            .put<void>(
                `${this.baseUrl}/webapi/incidentReports/${incidentReportId}/cancel`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateIncidentReport(
        incidentReportId: number,
        command: UpdateIncidentReportCommand
    ) {
        return this.http
            .put<AdminIncidentReportDto>(
                `${this.baseUrl}/webapi/incidentReports/${incidentReportId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    resolveIncidentReport(incidentReportId: number) {
        return this.http
            .put<AdminIncidentReportDto>(
                `${this.baseUrl}/webapi/incidentReports/${incidentReportId}/resolve`,
                null
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    attachmentsHandler(incidentReportId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/incidentReports/${incidentReportId}/attachments`,
            false,
            null
        );
    }

    getStatuses() {
        return [
            { name: 'New', value: 'NEW' },
            { name: 'Submitted', value: 'SUBMITTED' },
            { name: 'Resolved', value: 'RESOLVED' },
            { name: 'Cancelled', value: 'CANCELLED' }
        ];
    }
}
