import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentDto } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatEquipment' })
export class FormatEquipmentPipe implements PipeTransform {
    transform(equipment: EquipmentDto) {
        if (!equipment) {
            return null;
        }
        if (equipment.isDummy) {
            return `${equipment.name} (dummy)`;
        }

        return equipment.name;
    }
}
