import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@blox/material';
import { FlyFreelyFormlyModule } from '@flyfreely-portal-ui/flyfreely-formly';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { AnalyticsModule } from 'libs/analytics/src/lib/analytics.module';
import { EnhancedHelpModule } from 'libs/enhanced-help/src/lib/enhanced-help.module';
import { LoadingIndicatorModule } from 'libs/loading-indicator/src/lib/loading-indicator.module';
import { SharedUiModule } from 'libs/ui/src/lib/ui.module';
import { ValidationModule } from 'libs/validation/src/lib/validation.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { FlightConformanceSetup } from './flight-conformance-setup.component';
import { FlightConformancePolicy } from './flight-conformance-policy.component';
import { FormatConformanceRuleSettingsPipe } from './formatConformanceRuleSettings.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TooltipModule,
        ReactiveFormsModule,
        NgSelectModule,
        ButtonsModule,
        NgxLoadingModule,
        ToastrModule,
        FormlyModule,
        FormlyBootstrapModule,
        MaterialModule,
        FlyFreelyFormlyModule,
        EnhancedHelpModule,
        AnalyticsModule,
        LoadingIndicatorModule,
        ValidationModule,
        SharedUiModule
    ],
    declarations: [
        FlightConformanceSetup,
        FormatConformanceRuleSettingsPipe,
        FlightConformancePolicy
    ],
    exports: [FlightConformanceSetup]
})
export class FlightConformanceModule {}
