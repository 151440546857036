import { Injectable } from '@angular/core';
import {
    AirspaceJurisdictionDto,
    FlyFreelyLoggingService,
    GeospatialService,
    PrdStatus,
    RulesetDto,
    TypedActiveMapStyleDto,
    ValidDatasetsDto
} from '@flyfreely-portal-ui/flyfreely';
import { FeatureCollection } from 'geojson';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import * as moment from 'moment';
import {
    BehaviorSubject,
    combineLatest,
    EMPTY,
    Observable,
    of,
    Subject
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    shareReplay,
    startWith,
    takeUntil
} from 'rxjs/operators';
import { updateSourceFilters } from './dynamic-data.helper';
import { MapEventType } from './events';
import { ReadOnlyFeatureGroup } from './interfaces';
import { MapService } from './map.service';

interface StartEndTime {
    startTime: string;
    endTime: string;
}

export interface MapSourceFilters {
    [mapProperty: string]: string;
}
interface DynamicFeatureData {
    identifier: string;
    state: { [property: string]: string | boolean };
}

interface DynamicLayer {
    layer: string;
    type: 'IDENTIFIER' | 'PROPERTIES' | 'PROGRAMMATIC';
    source: string;
    identifierField: string;
}

const EMPTY_VALID_DATASETS = { datasets: {} } as ValidDatasetsDto;

interface ProgrammaticallyControlledConfig {
    mapSourceFilters: MapSourceFilters;
    enabledLayerGroups: string[];
    featureGroupList: ReadOnlyFeatureGroup[];
}

/**
 * This service is responsible for fetching dynamic data that is associated with map features
 * or map layers. The layer will hold the config of which data source to use, and the features
 * will contain the identifiers required to look up the appropriate feature.
 *
 * This service is expected to batch and cache the requests.
 */
@Injectable()
export class MapDynamicData {
    dynamicLayers: {
        [layer: string]: DynamicLayer;
    };
    dynamicLayerIds: string[];

    dynamicData: {
        [source: string]: {
            [identifier: string]: Observable<DynamicFeatureData>;
        };
    };

    private startEndTime = new BehaviorSubject<StartEndTime | undefined>(
        undefined
    );
    /**
     * Map source filters provided to the component
     */
    private customMapSourceFilters = new BehaviorSubject<MapSourceFilters>(
        undefined
    );
    /**
     * Map source filters derived from the dataset map source
     */
    private datasetMapSourceFilters: Observable<MapSourceFilters>;

    private mapSourceFilters: Observable<MapSourceFilters>;

    private currentRuleset = new BehaviorSubject<RulesetDto | undefined>(
        undefined
    );
    private featuresInView = new Subject<MapboxGeoJSONFeature[]>();

    currentJurisdiction: AirspaceJurisdictionDto;
    private enableJurisdictionLayerFiltering$ = new BehaviorSubject<boolean>(
        true
    );

    private programmaticMapConfigurationSource = new BehaviorSubject<
        ({ key: object } & ProgrammaticallyControlledConfig)[]
    >([]);
    programmaticMapConfiguration$ =
        this.programmaticMapConfigurationSource.pipe(
            map(
                configList =>
                    ({
                        mapSourceFilters: configList.reduce(
                            (acc, v) => ({ ...acc, ...v.mapSourceFilters }),
                            {}
                        ),
                        enabledLayerGroups: configList.reduce(
                            (acc, v) => acc.concat(v.enabledLayerGroups),
                            []
                        ),
                        featureGroupList: configList.reduce(
                            (acc, v) => acc.concat(v.featureGroupList),
                            []
                        )
                    } as ProgrammaticallyControlledConfig)
            )
        );

    /**
     * Source for custom map data, which is initialised with an empty object
     * to ensure that the sources are made available as soon as possible
     */
    private mapDataSource = new BehaviorSubject<{
        [source: string]: FeatureCollection;
    }>({});

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private mapService: MapService,
        private geospatialService: GeospatialService,
        private loggingService: FlyFreelyLoggingService
    ) {
        // Lookup the current datasets for a given timestamp
        this.datasetMapSourceFilters = this.startEndTime
            .pipe(distinctUntilChanged())
            .pipe(
                mergeMap(time =>
                    time?.startTime != null
                        ? this.geospatialService
                              .findValidDatasets(time.startTime)
                              .pipe(
                                  catchError(err => {
                                      this.loggingService.error(err);
                                      return of(EMPTY_VALID_DATASETS);
                                  })
                              )
                        : of(EMPTY_VALID_DATASETS)
                ),
                map(datasets =>
                    Object.keys(datasets.datasets).reduce(
                        (acc, jurisdiction) => ({
                            ...acc,
                            ...Object.keys(
                                datasets.datasets[jurisdiction]
                            ).reduce(
                                (jurisdictionDatasets, dataset) => ({
                                    ...jurisdictionDatasets,
                                    [dataset]:
                                        datasets.datasets[jurisdiction][dataset]
                                }),
                                {}
                            )
                        }),
                        {} as MapSourceFilters
                    )
                )
            );

        // Combine the map source filters, giving precedence to the custom ones, then programmatic ones, then map defaults. Using starting values so there is less loading delay.
        this.mapSourceFilters = combineLatest([
            this.customMapSourceFilters,
            this.programmaticMapConfiguration$.pipe(
                map(config => config.mapSourceFilters)
            ),
            this.datasetMapSourceFilters.pipe(startWith({}))
        ]).pipe(map(([a, b, c]) => ({ ...c, ...b, ...a })));

        this.mapService.mapEvent$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(
                    event =>
                        // Run when the map loads, and then every time it moves after that
                        event.type === MapEventType.mapLoaded ||
                        event.type === MapEventType.mapMoveEnd ||
                        event.type === MapEventType.mapZoomEnd
                )
            )
            .subscribe(() => this.onViewportChanged());

        this.mapService.visibleJurisdictions$
            .pipe(
                map(j => j.selected),
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(jurisdiction => {
                this.currentJurisdiction = jurisdiction;
            });

        combineLatest([
            this.startEndTime.pipe(distinctUntilChanged()),
            this.currentRuleset.pipe(distinctUntilChanged()),
            this.mapSourceFilters.pipe(distinctUntilChanged()),
            this.mapService.mapDefaultConfig$,
            this.mapDataSource
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    startEndTime,
                    ruleset,
                    mapSourceFilters,
                    defaultConfig,
                    mapData
                ]) => {
                    const activeConfig = updateSourceFilters(
                        defaultConfig,
                        {
                            ...mapSourceFilters,
                            start_time: startEndTime?.startTime,
                            end_time: startEndTime?.endTime,
                            ruleset: ruleset?.identifier
                        },
                        mapData
                    );
                    this.mapService.setActiveConfig(activeConfig);

                    this.onViewportChanged();
                }
            );

        combineLatest([
            this.startEndTime,
            this.currentRuleset,
            this.featuresInView
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([startEndTime, currentRuleset, featuresInView]) =>
                this.registerForDynamicData(
                    startEndTime,
                    currentRuleset,
                    featuresInView
                )
            );

        // This is receiving the map style from above, via the mapService, to avoid unnecessary
        // duplicate observables containing the same information, while keeping the dependency
        // graph one directional.
        combineLatest([
            this.enableJurisdictionLayerFiltering$,
            this.mapService.mapStyle$,
            this.mapService.visibleJurisdictions$.pipe(
                map(j => j.selected),
                distinctUntilChanged()
            )
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([
                    enableJurisdictionLayerFiltering,
                    mapStyles,
                    mapJurisdiction
                ]) =>
                    this.refreshDynamicLayers(
                        enableJurisdictionLayerFiltering,
                        mapStyles,
                        mapJurisdiction
                    )
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.startEndTime.complete();
        this.currentRuleset.complete();
        this.featuresInView.complete();
    }

    /**
     * Sets or updates the programmatic map configuration, so that it can be merged with the other sources of configuration.
     * @param key a reference to the calling instance
     * @param value any of the programmaticly controllable configuration
     */
    setProgrammaticMapConfig(
        key: object,
        value: Partial<ProgrammaticallyControlledConfig>
    ) {
        const remainingValues =
            this.programmaticMapConfigurationSource.value.filter(
                e => e.key !== key
            );
        this.programmaticMapConfigurationSource.next([
            ...remainingValues,
            {
                key,
                mapSourceFilters: value.mapSourceFilters ?? {},
                enabledLayerGroups: value.enabledLayerGroups ?? [],
                featureGroupList: value.featureGroupList ?? []
            }
        ]);
    }

    /**
     * Clears the single key from the list
     * @param key a reference to the calling instance
     */
    clearProgrammaticMapConfig(key: object) {
        const remainingValues =
            this.programmaticMapConfigurationSource.value.filter(
                e => e.key !== key
            );
        this.programmaticMapConfigurationSource.next(remainingValues);
    }

    setMapData(data: { [source: string]: FeatureCollection }) {
        this.mapDataSource.next(data);
    }

    /**
     * Sets the start and end time of the data to be displayed. It will default to now if `null` is provided,
     * and the `endTime` will default to 1 hour later if not provided.
     *
     * @param startTime the start time string
     * @param endTime the end time string
     */
    setStartEndTime(startTime: string, endTime: string) {
        if (startTime == null && endTime == null) {
            startTime = moment().utc().toISOString();
        }
        if (startTime == null && endTime != null) {
            startTime = moment(endTime).subtract(1, 'hour').utc().toISOString();
        }
        const start = moment(startTime);
        const end = endTime ?? start.add(1, 'hours').utc().toISOString();
        this.startEndTime.next({ startTime, endTime: end });
    }

    setRuleset(ruleset: RulesetDto) {
        this.currentRuleset.next(ruleset);
    }

    setSourceFilters(mapSourceFilters: MapSourceFilters) {
        this.customMapSourceFilters.next(mapSourceFilters);
    }

    setJurisdictionLayerFiltering(filtering: boolean) {
        this.enableJurisdictionLayerFiltering$.next(filtering);
    }

    refreshDynamicLayers(
        enableJurisdictionLayerFiltering: boolean,
        mapStyles: TypedActiveMapStyleDto,
        mapJurisdiction: AirspaceJurisdictionDto
    ) {
        const isAvailableInJurisdiction = (ids: number[]) => {
            if (enableJurisdictionLayerFiltering === false) {
                return true;
            }
            const isGlobal = ids == null || ids.length === 0;
            if (mapJurisdiction == null) {
                return isGlobal;
            } else {
                return isGlobal || ids.includes(mapJurisdiction.id);
            }
        };

        const dynamicLayers =
            mapStyles != null
                ? mapStyles.dataLayers
                      .filter(dl =>
                          isAvailableInJurisdiction(dl.airspaceJurisdictionIds)
                      )
                      .reduce(
                          (acc, l) => [
                              ...acc,
                              ...l.layers.groups
                                  .filter(
                                      g =>
                                          g.dynamicData != null &&
                                          g.dynamicData.length !== 0
                                  )
                                  .reduce(
                                      (acc2, lg) => [
                                          ...acc2,
                                          ...lg.dynamicData.reduce(
                                              (acc3, dd) => [
                                                  ...acc3,
                                                  ...lg.layers.map(layer => ({
                                                      layer: layer.id,
                                                      ...dd
                                                  }))
                                              ],
                                              [] as DynamicLayer[]
                                          )
                                      ],
                                      []
                                  )
                          ],
                          []
                      )
                : [];

        this.dynamicLayers = dynamicLayers.reduce(
            (acc, l) => ({ ...acc, [l.layer]: l }),
            {}
        );
        this.dynamicLayerIds = Object.keys(this.dynamicLayers);
        this.dynamicData = {};
        // Give the map time to load the updated features before calling onViewPortChanged,
        // otherwise it can result in race condition errors
        setTimeout(() => {
            this.onViewportChanged();
        }, 200);
    }

    onLayersUpdated() {
        this.onViewportChanged();
    }

    /**
     * Used to refresh the state of UI based feature state.
     */
    private onViewportChanged() {
        if (
            this.dynamicLayerIds == null ||
            this.dynamicData == null ||
            this.mapService._map == null ||
            this.mapService._map.isStyleLoaded() === false
        ) {
            return;
        }
        const toBeDynamicallyLoaded =
            this.mapService._map.queryRenderedFeatures(undefined, {
                layers: this.dynamicLayerIds
            });
        this.featuresInView.next(toBeDynamicallyLoaded);
    }

    private registerForDynamicData(
        startEndTime: StartEndTime,
        currentRuleset: RulesetDto,
        features: MapboxGeoJSONFeature[]
    ) {
        if (this.dynamicLayers == null) {
            return;
        }
        const newDataFetch: {
            [source: string]: Subject<DynamicFeatureData[]>;
        } = {};
        const toFetch: { [source: string]: string[] } = {};

        features.forEach(f => {
            const conf = this.dynamicLayers[f.layer.id];
            if (conf == null) {
                return;
            }
            if (
                conf.type === 'IDENTIFIER' &&
                this.currentJurisdiction != null
            ) {
                this.setupIdentifierSource(f, conf, toFetch, newDataFetch);
            } else if (conf.type === 'PROPERTIES') {
                this.calculateFeatureStateFromProperties(
                    f,
                    startEndTime,
                    currentRuleset
                );
            } else if (conf.type === 'PROGRAMMATIC') {
                this.calculateFeatureStateFromProgrammatic(f, conf);
            }
        });

        Object.keys(toFetch)
            .filter(source => toFetch[source].length > 0)
            .forEach(source => {
                this.subscribeForData(
                    source,
                    toFetch[source],
                    startEndTime
                ).subscribe({
                    next: results => {
                        newDataFetch[source].next(results);
                    },
                    complete: () => newDataFetch[source].complete()
                });
            });
    }

    /**
     * Setup a dynamic data source based on identifiers. Requires the current jurisdiction to be set.
     * @param feature the mapbox feature
     * @param toFetch  FIXME should not pass mutable object
     */
    private setupIdentifierSource(
        feature: MapboxGeoJSONFeature,
        conf: DynamicLayer,
        toFetch: { [source: string]: string[] },
        newDataFetch: {
            [source: string]: Subject<DynamicFeatureData[]>;
        }
    ) {
        const ident = feature.properties[conf.identifierField];

        if (!(conf.source in this.dynamicData)) {
            this.dynamicData[conf.source] = {};
        }
        if (!(conf.source in toFetch)) {
            toFetch[conf.source] = [];
            newDataFetch[conf.source] = new Subject<DynamicFeatureData[]>();
        }

        if (!(ident in this.dynamicData[conf.source])) {
            this.dynamicData[conf.source][ident] = newDataFetch[
                conf.source
            ].pipe(
                takeUntil(this.ngUnsubscribe$),
                map(prds => prds.find(prd => prd.identifier === ident)),
                shareReplay()
            );
            toFetch[conf.source].push(ident);
        }

        this.dynamicData[conf.source][ident]
            .pipe(filter(v => v != null))
            .subscribe(d => {
                this.mapService._map.setFeatureState(feature, d.state);
            });
    }

    private subscribeForData(
        source: string,
        idents: string[],
        startEndTime: StartEndTime
    ) {
        const now = new Date().toISOString();
        if (source === 'NAIPS_PRD') {
            return this.geospatialService
                .findPrdStatus(
                    idents,
                    startEndTime?.startTime ?? now,
                    startEndTime?.endTime ?? now,
                    this.currentJurisdiction.identifier
                )
                .pipe(
                    map(prds =>
                        prds.map(
                            prd =>
                                ({
                                    identifier: prd.prd,
                                    state: {
                                        active: prd.status
                                    }
                                } as DynamicFeatureData)
                        )
                    ),
                    startWith(
                        idents.map(
                            prd =>
                                ({
                                    identifier: prd,
                                    state: {
                                        active: 'LOADING'
                                    }
                                } as DynamicFeatureData)
                        )
                    )
                );
        } else if (source === 'AERODROME') {
            return this.geospatialService
                .findAerodromeStatus(
                    idents,
                    startEndTime?.startTime ?? now,
                    startEndTime?.endTime ?? now,
                    this.currentJurisdiction.identifier
                )
                .pipe(
                    map(aerodromes =>
                        aerodromes.map(
                            aerodrome =>
                                ({
                                    identifier: aerodrome.aerodromeCode,
                                    state: {
                                        controlled: aerodrome.controlledStatus
                                    }
                                } as DynamicFeatureData)
                        )
                    ),
                    startWith(
                        idents.map(
                            aerodrome =>
                                ({
                                    identifier: aerodrome,
                                    state: {
                                        controlled: 'LOADING'
                                    }
                                } as DynamicFeatureData)
                        )
                    )
                );
        }
        return EMPTY;
    }

    /**
     * Calculates and sets the feature state of a feature based on its properties.
     * @param feature feature to check and set
     * @param conf the configuration (if required)
     */
    private calculateFeatureStateFromProperties(
        feature: MapboxGeoJSONFeature,
        startEndTime: StartEndTime,
        currentRuleset: RulesetDto
    ) {
        if (
            feature.properties.start_time != null &&
            feature.properties.end_time != null &&
            startEndTime.startTime != null &&
            startEndTime.endTime != null
        ) {
            const startTime = moment(startEndTime.startTime);
            const endTime = moment(startEndTime.endTime);
            const overlapStart = startTime.isBetween(
                feature.properties.start_time,
                feature.properties.end_time
            );
            const overlapEnd = endTime.isBetween(
                feature.properties.start_time,
                feature.properties.end_time
            );
            const overlapAll =
                startTime.isSameOrBefore(feature.properties.start_time) &&
                endTime.isSameOrAfter(feature.properties.end_time);
            const isInActiveTime = overlapStart || overlapEnd || overlapAll;

            // If the feature has a ruleset value (such as CASA advisories), include that in the active check;
            const isRulesetActive =
                feature.properties.ruleset != null
                    ? feature.properties.ruleset === currentRuleset?.identifier
                    : true;

            const status =
                isInActiveTime && isRulesetActive
                    ? PrdStatus.Status.ACTIVE
                    : PrdStatus.Status.INACTIVE;

            this.mapService._map.setFeatureState(feature, {
                active: status
            });
        }
    }

    private calculateFeatureStateFromProgrammatic(
        feature: MapboxGeoJSONFeature,
        conf: DynamicLayer
    ) {
        const status = this.getStatus(feature, conf);

        this.mapService._map.setFeatureState(feature, {
            active: status
        });
    }

    private getStatus(feature: MapboxGeoJSONFeature, conf: DynamicLayer) {
        if (
            this.mapSourceFilters == null ||
            !(conf.source in this.mapSourceFilters)
        ) {
            // Don't show the filter if not programmatic
            return PrdStatus.Status.UNKNOWN;
        } else {
            return feature.properties[conf.identifierField] ===
                this.mapSourceFilters[conf.source]
                ? PrdStatus.Status.ACTIVE
                : PrdStatus.Status.INACTIVE;
        }
    }
}
