<div class="container-with-footer">
    <div class="container-fluid" data-cy="personnelDetails">
        <div class="data">
            <span class="display-label">Name</span>{{ person | formatPerson }}
        </div>

        <div class="data">
            <span class="display-label">Email</span>{{ person.email }}
        </div>

        <div class="data">
            <span class="display-label">Phone Number</span
            >{{ person.phoneNumber }}
        </div>

        <ng-container *ngFor="let identifier of jurisdictionIdentifiers">
            <div class="data">
                <span class="display-label">{{ identifier.name }}</span
                >{{ identifier.value }}
            </div>
        </ng-container>

        <div class="data" *ngIf="roles">
            <span class="display-label">Roles</span>{{ roles }}
        </div>

        <div class="data">
            <span class="display-label">Assigned Licences</span
            >{{ assignedLicences }}
        </div>
        <div class="data" *ngIf="canUseWorkgroups">
            <span class="display-label">Workgroups</span>
            {{ workgroups || 'None' }}
        </div>
    </div>
    <div class="action-container">
        <print-personnel-reports
            [person]="person"
            [managingOrganisationId]="managingOrganisationId"
        >
        </print-personnel-reports>
        <button
            data-cy="editPersonnelDetails"
            *ngIf="canEdit"
            class="btn btn-primary"
            type="button"
            (click)="onEdit()"
        >
            Edit
        </button>
    </div>
</div>
