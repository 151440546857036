import {
    HttpBackend,
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    AirspaceAuthorisationDto,
    AirspaceAuthorisationResponse,
    AirspaceAuthorisationService,
    AirspaceCheckCommand,
    AirspaceCheckDto,
    AirspaceJurisdictionDto,
    DisplayableMissionDto,
    DO_NOTHING,
    FieldError,
    FlyFreelyError,
    FlyFreelyLoggingService,
    InvalidOperation,
    MissionService,
    OperationAuthorisation,
    parseErrorBody,
    RequestAirspaceAuthorisationCommand,
    SessionExpired,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { deepEqual } from 'fast-equals';
import { Geometry } from 'geojson';
import { findRpic } from 'libs/missions/src/lib/helpers';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    forkJoin,
    Observable,
    of,
    ReplaySubject,
    Subject,
    throwError
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import { AirspaceCheckService } from '../airspace-check';
import { AirspaceAuthorisationFormValue } from './authorisation-preview/airspace-authorisation-preview.service';
import { mapAuthorisationResult } from './helpers';
import {
    AlternativeAirspaceAuthorisationRequestCommand,
    AuthorisationError
} from './interfaces';

export interface AuthorisationAvailabilityState {
    status: 'AVAILABLE' | 'UNAVAILABLE';
    unavailableMessage?: string;
}

/**
 * A scoped service that coordinates the management of airspace authorisations.
 * This service handles:
 * - Fetching the current airspace jurisdiction's airspace check support. - y
 * - Managing the current available authorisation types based on the airspace check support. - y
 * - Housing which jurisdiction's authorisations are allowed in the current screen - y
 * - accept additional authorisation fields
 * - Do authorisation preview and emit to observable
 * - accept ahd emit current existing authorisation
 * - apply, cancel and reapply for an authorisation
 */
@Injectable()
export class AirspaceAuthorisationManager {
    /**
     * Are airspace authorisations enabled for the current airspace jurisdiction?
     */
    private availableAirspaceAuthorisationsSubject = new BehaviorSubject<
        AirspaceCheckDto.AvailableAutomatedAuthorisationList[]
    >([]);
    availableAirspaceAuthorisations$ =
        this.availableAirspaceAuthorisationsSubject.asObservable();

    airspaceCheckResult$ = this.airspaceCheckService.completeCheck$.pipe(
        shareReplay()
    );

    airspaceAuthorisationEnabled$ =
        this.airspaceCheckService.airspaceJurisdiction$.pipe(
            map(j => j?.airspaceCheckSupport !== 'NONE'),
            shareReplay()
        );

    private enabledAuthorisationTypesSubject = new ReplaySubject<
        AirspaceAuthorisationDto.AuthorisationType[]
    >(1);
    enabledAuthorisationTypes$ =
        this.enabledAuthorisationTypesSubject.asObservable();

    private missionIdSubject = new BehaviorSubject<number>(null);
    missionId$ = this.missionIdSubject.asObservable();

    private currentMissionSubject = new BehaviorSubject<DisplayableMissionDto>(
        null
    );
    currentMission$ = this.currentMissionSubject.asObservable();

    private airspaceAuthorisationPreviewSubject =
        new BehaviorSubject<AirspaceAuthorisationResponse>(null);
    airspaceAuthorisationPreview$ =
        this.airspaceAuthorisationPreviewSubject.asObservable();

    private missionAirspaceAuthorisationsSubject = new ReplaySubject<
        AirspaceAuthorisationDto[]
    >(1);
    missionAirspaceAuthorisations$ =
        this.missionAirspaceAuthorisationsSubject.asObservable();

    private authorisationRequestErrorSubject =
        new ReplaySubject<AuthorisationError>(1);
    authorisationRequestError$ =
        this.authorisationRequestErrorSubject.asObservable();

    private authorisationStateErrorsSubject = new BehaviorSubject<{
        [field: string]: FieldError;
    }>(null);
    authorisationStateErrors$ =
        this.authorisationStateErrorsSubject.asObservable();

    private authorisationServiceUnavailableSubject =
        new BehaviorSubject<AuthorisationAvailabilityState>({
            status: 'AVAILABLE'
        });
    authorisationServiceUnavailable$ =
        this.authorisationServiceUnavailableSubject.asObservable();
    private airspaceAuthorisationSubject =
        new ReplaySubject<AirspaceAuthorisationResponse>(1);
    airspaceAuthorisation$ = this.airspaceAuthorisationSubject.asObservable();

    private airspaceAuthorisationPreviewCommand$ =
        new BehaviorSubject<RequestAirspaceAuthorisationCommand>(null);

    private checkingAuthorisationSource = new BehaviorSubject<boolean>(false);
    checkingAuthorisation$ = this.checkingAuthorisationSource.asObservable();

    private externalAuthorisationBearerToken$ = new BehaviorSubject<{
        accessToken: string;
    }>(null);

    private relatedMissionsSubject = new BehaviorSubject<
        DisplayableMissionDto[]
    >([]);
    relatedMissions$ = this.relatedMissionsSubject.asObservable();

    private authorisationUrlSource = new BehaviorSubject<string>(null);
    authorisationUrl$ = this.authorisationUrlSource.asObservable();
    airspaceCheckCommand: AirspaceCheckCommand;
    savedMission: DisplayableMissionDto;

    private alternativeAirspaceAuthorisationRequestCommand$ =
        new BehaviorSubject<AlternativeAirspaceAuthorisationRequestCommand>(
            null
        );

    // Using advice on bypassing the HTTP interceptor to use a different Bearer token from:
    // https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module
    private http: HttpClient;
    // private stopPollingAuthorisation$ = new Subject<void>();
    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private airspaceCheckService: AirspaceCheckService,
        private airspaceAuthorisationService: AirspaceAuthorisationService,
        private missionService: MissionService,
        private logging: FlyFreelyLoggingService,
        private httpHandler: HttpBackend,
        private router: Router
    ) {
        this.http = new HttpClient(httpHandler);

        combineLatest([
            airspaceCheckService.externalAuthorisationBearerToken$,
            airspaceCheckService.alternativeCheckerUrl$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(([token, url]) => {
                this.externalAuthorisationBearerToken$.next(token);
                this.authorisationUrlSource.next(url);
            });

        this.airspaceCheckService.savedMission$
            .pipe(
                distinctUntilChanged(deepEqual),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(mission => {
                this.savedMission = mission;
                this.currentMissionSubject.next(mission);
            });

        combineLatest([
            airspaceCheckService.completeCheck$.pipe(distinctUntilChanged()),
            this.airspaceCheckService.airspaceJurisdiction$
                .pipe(
                    filter(
                        j =>
                            j?.airspaceCheckSupport !==
                            AirspaceJurisdictionDto.AirspaceCheckSupport.NONE
                    )
                )
                .pipe(distinctUntilChanged()),
            this.enabledAuthorisationTypes$.pipe(distinctUntilChanged())
        ])
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                ([checkResult, checksEnabled, enabledAuthorisationTypes]) => {
                    const checkAuthorisationTypes =
                        checkResult?.result
                            ?.availableAutomatedAuthorisationList ?? [];
                    const availableAuthorisationTypes: AirspaceCheckDto.AvailableAutomatedAuthorisationList[] =
                        checkAuthorisationTypes.reduce(
                            (acc, type) =>
                                !acc.includes(type) &&
                                enabledAuthorisationTypes?.includes(type)
                                    ? acc.concat(type)
                                    : acc,
                            []
                        );
                    if (
                        availableAuthorisationTypes.length > 0 &&
                        checksEnabled
                    ) {
                        this.availableAirspaceAuthorisationsSubject.next(
                            availableAuthorisationTypes
                        );
                        this.processCheckResults(
                            checkResult,
                            enabledAuthorisationTypes
                        );
                    } else {
                        this.availableAirspaceAuthorisationsSubject.next([]);
                    }
                }
            );

        combineLatest([
            airspaceCheckService.completeCheck$.pipe(
                distinctUntilChanged(),
                filter(checkResult => checkResult != null)
            ),
            this.externalAuthorisationBearerToken$.pipe(
                distinctUntilChanged(deepEqual),
                filter(token => token != null)
            ),
            this.authorisationUrl$.pipe(
                distinctUntilChanged(),
                filter(url => url != null)
            )
        ])
            .pipe(
                switchMap(
                    ([checkResult, token, alternativeUrl]) =>
                        <Observable<AirspaceAuthorisationDto[]>>(
                            this.refreshExistingMissionAuthorisations(
                                AirspaceAuthorisationDto.AuthorisationType
                                    .USA_LAANC
                            )
                        )
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(() => {
                DO_NOTHING;
            });

        // Map the preview call in a switchmap since it can fire several times during a flow
        combineLatest([
            this.alternativeAirspaceAuthorisationRequestCommand$.pipe(
                startWith(null),
                distinctUntilChanged(deepEqual)
            ),
            this.externalAuthorisationBearerToken$.pipe(
                startWith(null),
                distinctUntilChanged(deepEqual)
            ),
            this.airspaceAuthorisationPreviewCommand$.pipe(
                startWith(null),
                distinctUntilChanged(deepEqual)
            )
        ])
            .pipe(
                tap(() => this.checkingAuthorisationSource.next(true)),
                switchMap(([laancCommand, token, command]) =>
                    this.authorisationUrlSource.getValue() != null &&
                    laancCommand != null &&
                    token != null
                        ? this.getAuthorisationPreviewWithUrl()
                        : this.authorisationUrlSource.getValue() == null &&
                          command != null
                        ? this.airspaceAuthorisationService.getAuthorisationPreview(
                              command
                          )
                        : of(null)
                ),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe({
                next: (result: AirspaceAuthorisationResponse) => {
                    this.checkingAuthorisationSource.next(false);
                    if (result != null) {
                        this.airspaceAuthorisationPreviewSubject.next(result);
                    }
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while fetching authorisation preview: ${error.message}`
                    );
                    this.checkingAuthorisationSource.next(false);
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.airspaceAuthorisationPreviewSubject.complete();
        this.airspaceAuthorisationSubject.complete();
        this.availableAirspaceAuthorisationsSubject.complete();
        this.enabledAuthorisationTypesSubject.complete();
        this.missionIdSubject.complete();
        this.airspaceAuthorisationPreviewCommand$.complete();
        this.checkingAuthorisationSource.next(false);
        this.checkingAuthorisationSource.complete();
        this.alternativeAirspaceAuthorisationRequestCommand$.complete();
        this.externalAuthorisationBearerToken$.complete();
        this.authorisationRequestErrorSubject.complete();
        this.missionAirspaceAuthorisationsSubject.complete();
        this.authorisationServiceUnavailableSubject.complete();
        this.currentMissionSubject.complete();
        this.relatedMissionsSubject.complete();
        this.authorisationUrlSource.complete();
        this.authorisationStateErrorsSubject.complete();
    }

    setup(
        enabledAuthorisationTypes: AirspaceAuthorisationDto.AuthorisationType[],
        missionId?: number
    ) {
        this.enabledAuthorisationTypesSubject.next(enabledAuthorisationTypes);
        if (missionId !== this.missionIdSubject.getValue()) {
            this.missionIdSubject.next(missionId);
        }
    }

    updateMission(mission: DisplayableMissionDto) {
        this.savedMission = mission;
        this.missionIdSubject.next(mission.id);
        this.airspaceCheckService.updateSavedMission(mission);
    }

    private processCheckResults(
        results: {
            check: AirspaceCheckCommand;
            result: AirspaceCheckDto;
            missionId?: number;
        },
        enabledAuthorisationTypes: AirspaceAuthorisationDto.AuthorisationType[]
    ) {
        const availableAuthorisationType = enabledAuthorisationTypes.find(at =>
            (
                results.result?.availableAutomatedAuthorisationList ?? []
            ).includes(at)
        );
        this.airspaceCheckCommand = results.check;

        if (
            results.missionId != null &&
            results.missionId !== this.missionIdSubject.getValue()
        ) {
            this.missionIdSubject.next(results.missionId);
        }

        if (results.missionId != null) {
            this.missionIdSubject.next(results.missionId);
        }

        const flightArea = results.check.location;

        this.startAuthorisationCheck(availableAuthorisationType, flightArea);
    }

    private startAuthorisationCheck(
        availableAuthorisationType: AirspaceAuthorisationDto.AuthorisationType,
        flightArea?: Geometry
    ) {
        const missionId = this.missionIdSubject.getValue();
        if (missionId == null || availableAuthorisationType == null) {
            return;
        }
        const command: RequestAirspaceAuthorisationCommand = {
            missionId,
            authorisationType: availableAuthorisationType,
            flightArea: flightArea
        };
        this.refreshAuthorisationPreview(command);
    }

    refreshAuthorisationPreview(
        command: RequestAirspaceAuthorisationCommand,
        authorisationFormValues?: AirspaceAuthorisationFormValue
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            this.buildAlternativeAuthorisationCommand(
                command,
                authorisationFormValues
            );
        } else {
            this.airspaceAuthorisationPreviewCommand$.next(command);
        }
    }

    refreshExistingMissionAuthorisations(
        authorisationType: AirspaceAuthorisationDto.AuthorisationType
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            return this.findMissionAuthorisationsWithUrl(authorisationType)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(authorisations => {
                    this.missionAirspaceAuthorisationsSubject.next(
                        authorisations
                    );
                });
        } else {
            return of(null as AirspaceAuthorisationDto[]);
        }
    }

    findAuthorisationsByMissionId(missionId: number) {
        this.checkingAuthorisationSource.next(true);
        this.missionService
            .findMissionAirspaceAuthorisations(missionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: authorisations => {
                    this.missionAirspaceAuthorisationsSubject.next(
                        authorisations
                    );
                    this.checkingAuthorisationSource.next(false);
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while finding authorisations for mission: ${error.message}`
                    );
                    this.checkingAuthorisationSource.next(false);
                }
            });
    }

    // Determine whether to use the alternative authorisation URL or the standard service
    applyForAuthorisation(
        command: RequestAirspaceAuthorisationCommand,
        formValues?: AirspaceAuthorisationFormValue
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            const alternativeCommand =
                this.buildAlternativeAuthorisationCommand(command, formValues);
            return this.applyForAuthorisationWithUrl(alternativeCommand);
        } else {
            return this.airspaceAuthorisationService
                .applyForAuthorisation(command)
                .pipe(
                    tap(missionAuthorisations =>
                        this.missionAirspaceAuthorisationsSubject.next(
                            missionAuthorisations
                        )
                    )
                );
        }
    }

    findAuthorisation(
        id: string,
        authorisationType?: AirspaceAuthorisationDto.AuthorisationType
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            return this.findExistingAuthorisationWithUrl(id, authorisationType);
        } else {
            return this.airspaceAuthorisationService.findAuthorisation(
                id,
                authorisationType
            );
        }
    }

    cancelAuthorisation(
        id: string,
        authorisationType?: AirspaceAuthorisationDto.AuthorisationType
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            return this.cancelAuthorisationAuthorisationWithUrl(
                id,
                authorisationType
            );
        } else {
            return this.airspaceAuthorisationService.cancelAuthorisation(
                id,
                authorisationType
            );
        }
    }

    deleteAuthorisation(
        id: string,
        authorisationType?: AirspaceAuthorisationDto.AuthorisationType
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            return this.deleteAuthorisationWithUrl(id, authorisationType);
        } else {
            return null;
            // return this.airspaceAuthorisationService.deleteForAuthorisation(
            //     id,
            //     authorisationType
            // );
        }
    }

    closeAuthorisation(
        id: string,
        authorisationType?: AirspaceAuthorisationDto.AuthorisationType
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            return this.closeAuthorisationWithUrl(id, authorisationType);
        } else {
            return null;
            // return this.airspaceAuthorisationService.deleteForAuthorisation(
            //     id,
            //     authorisationType
            // );
        }
    }

    acknowledgeRescinded(
        id: string,
        authorisationType?: AirspaceAuthorisationDto.AuthorisationType
    ) {
        if (this.authorisationUrlSource.getValue() != null) {
            return this.acknowledgeRescindedWithUrl(id, authorisationType);
        } else {
            return null;
            // return this.airspaceAuthorisationService.deleteForAuthorisation(
            //     id,
            //     authorisationType
            // );
        }
    }

    buildAlternativeAuthorisationCommand(
        command: RequestAirspaceAuthorisationCommand,
        authorisationFormValues: AirspaceAuthorisationFormValue
    ) {
        const rpic =
            authorisationFormValues?.rpic != null
                ? authorisationFormValues.rpic
                : this.savedMission != null &&
                  this.savedMission.missionCrewDetails.length > 0
                ? findRpic(this.savedMission.missionCrewDetails)
                : null;
        const missionDetails = {
            ...this.airspaceCheckCommand,
            location: command.flightArea ?? this.airspaceCheckCommand.location
        };
        this.alternativeAirspaceAuthorisationRequestCommand$.next({
            missionId: command.missionId,
            remotePilotPhoneNumber: rpic?.phoneNumber,
            authorisationType: command.authorisationType,
            missionDetails: missionDetails,
            operatorId: this.userService.getCurrentUser().id,
            remotePilotEmail: rpic?.email,
            remotePilotFirstName: rpic?.firstName,
            remotePilotLastName: rpic?.lastName,
            // FIXME: LAANC model constraints
            additionalProviderProperties: command.additionalProviderProperties
        });
        return this.alternativeAirspaceAuthorisationRequestCommand$.getValue();
    }

    private findRelatedMissions(ids: number[]) {
        forkJoin(ids.map(id => this.missionService.findMission(id)))
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(missions => {
                const fetchedMissions = this.relatedMissionsSubject.getValue();
                const relatedMissions = fetchedMissions.concat(
                    missions.filter(
                        m =>
                            fetchedMissions.find(
                                mission => mission.id === m.id
                            ) == null
                    )
                );
                this.relatedMissionsSubject.next(relatedMissions);
            });
    }

    goToRelatedMission(missionId: number) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/missions/mission/${missionId}`], {
                queryParamsHandling: 'merge'
            })
        );
        window.open(url, '_blank');
    }

    private getAuthorisationPreviewWithUrl() {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        return this.http
            .post<AirspaceAuthorisationResponse>(
                `${this.authorisationUrlSource.getValue()}/authorisations/preview`,
                this.alternativeAirspaceAuthorisationRequestCommand$.getValue(),
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    // Handle this in here because this call is outside the HTTP interceptor
                    // FIXME: a different solution might be required for requests using the default API since the interceptor will retype those errors.
                    if (err.status === 500) {
                        const returnError: AuthorisationError = {
                            code: 500,
                            message: err.message
                        };
                        this.authorisationRequestErrorSubject.next(returnError);
                        return of(null);
                    }
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    return throwError(() => parseErrorBody(err));
                }),
                tap((result: AirspaceAuthorisationResponse) => {
                    // FIXME: LAANC model constraints - remove ts-ignores below
                    this.authorisationServiceUnavailableSubject.next({
                        // @ts-ignore - LAANC
                        status: result?.authorisationServiceStatus,
                        // @ts-ignore - LAANC
                        unavailableMessage: result?.authorisationServiceMessage
                    });
                })
            );
    }

    private applyForAuthorisationWithUrl(
        command: RequestAirspaceAuthorisationCommand
    ) {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        return this.http
            .post<OperationAuthorisation[]>(
                `${this.authorisationUrlSource.getValue()}/authorisations`,
                command,
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    if (err.status === 400) {
                        if (err instanceof InvalidOperation) {
                            // Handle new errors
                            if (
                                err.stateErrors != null &&
                                Object.keys(err.stateErrors).length > 0
                            ) {
                                this.authorisationStateErrorsSubject.next(
                                    err.stateErrors
                                );
                            }
                            if (
                                err.fieldErrors != null &&
                                Object.keys(err.fieldErrors).length > 0
                            ) {
                                // Push the field errors to both the preview and return a bad authorisation object
                                const preview =
                                    this.airspaceAuthorisationPreviewSubject.getValue();
                                const uniquePreviewErrors = Object.keys(
                                    preview.errors
                                ).reduce(
                                    (acc, k) =>
                                        err.fieldErrors[k]
                                            ? acc
                                            : {
                                                  ...acc,
                                                  [k]: preview.errors[k]
                                              },
                                    {}
                                );
                                this.airspaceAuthorisationPreviewSubject.next({
                                    ...preview,
                                    errors: {
                                        ...uniquePreviewErrors,
                                        ...err.fieldErrors
                                    }
                                });

                                const errorObject: OperationAuthorisation = {
                                    id: null,
                                    activeGridCellList: null,
                                    authorisationEligibility: null,
                                    authorisationType:
                                        command.authorisationType,
                                    conditionList: null,
                                    createTime: null,
                                    endTime: null,
                                    errors: err.fieldErrors,
                                    messageList: null,
                                    maximumHeight: null,
                                    operatingArea: null,
                                    rejectionReason: null,
                                    startTime: null,
                                    status: null,
                                    updateTime: null
                                };
                                return of([errorObject]);
                            }
                        }
                    }
                    return throwError(() => parseErrorBody(err));
                }),
                tap(authorisation =>
                    this.refreshExistingMissionAuthorisations(
                        command.authorisationType
                    )
                ),
                // FIXME: this is only a temporary solution and will need to go once the API is finalised
                map((result: OperationAuthorisation[]) =>
                    mapAuthorisationResult(result)
                )
            );
    }

    findMissionAuthorisationsWithUrl(
        authorisationType: AirspaceAuthorisationDto.AuthorisationType
    ) {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        const url = this.authorisationUrlSource.getValue();
        return this.http
            .get<OperationAuthorisation[]>(
                `${url}/authorisations/${authorisationType}/operations`,
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 500) {
                        const returnError: AuthorisationError = {
                            code: 500,
                            message: err.message
                        };
                        this.authorisationRequestErrorSubject.next(returnError);
                        return of(null);
                    }
                    if (err.status === 404) {
                        return of(null as OperationAuthorisation[]);
                    }
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    if (err.status === 400) {
                        if (err instanceof InvalidOperation) {
                            // Handle new errors
                            if (
                                err.stateErrors != null &&
                                Object.keys(err.stateErrors).length > 0
                            ) {
                                this.authorisationStateErrorsSubject.next(
                                    err.stateErrors
                                );
                            }
                            if (
                                err.fieldErrors != null &&
                                Object.keys(err.fieldErrors).length > 0
                            ) {
                                const errorObject: OperationAuthorisation = {
                                    id: null,
                                    activeGridCellList: null,
                                    authorisationEligibility: null,
                                    authorisationType: authorisationType,
                                    conditionList: null,
                                    createTime: null,
                                    endTime: null,
                                    errors: err.fieldErrors,
                                    messageList: null,
                                    maximumHeight: null,
                                    operatingArea: null,
                                    rejectionReason: null,
                                    startTime: null,
                                    status: null,
                                    updateTime: null
                                };
                                return of(errorObject);
                            }
                        }
                    }
                    return throwError(() => parseErrorBody(err));
                }),
                // FIXME: this is only a temporary solution and will need to go once the API is finalised
                map((result: OperationAuthorisation[]) =>
                    mapAuthorisationResult(result)
                )
            );
    }

    private findExistingAuthorisationWithUrl(
        id: string,
        authorisationType: AirspaceAuthorisationDto.AuthorisationType = AirspaceAuthorisationDto
            .AuthorisationType.USA_LAANC
    ) {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        return this.http
            .get<OperationAuthorisation>(
                `${this.authorisationUrlSource.getValue()}/authorisations/${authorisationType}/${id}`,
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    if (err.status === 400) {
                        if (err instanceof InvalidOperation) {
                            // Handle new errors
                            if (
                                err.stateErrors != null &&
                                Object.keys(err.stateErrors).length > 0
                            ) {
                                this.authorisationStateErrorsSubject.next(
                                    err.stateErrors
                                );
                            }
                            if (
                                err.fieldErrors != null &&
                                Object.keys(err.fieldErrors).length > 0
                            ) {
                                const errorObject: OperationAuthorisation = {
                                    id: null,
                                    activeGridCellList: null,
                                    authorisationEligibility: null,
                                    authorisationType: authorisationType,
                                    conditionList: null,
                                    createTime: null,
                                    endTime: null,
                                    errors: err.fieldErrors,
                                    messageList: null,
                                    maximumHeight: null,
                                    operatingArea: null,
                                    rejectionReason: null,
                                    startTime: null,
                                    status: null,
                                    updateTime: null
                                };
                                return of(errorObject);
                            }
                        }
                    }
                    return throwError(() => parseErrorBody(err));
                }),
                // FIXME: this is only a temporary solution and will need to go once the API is finalised
                map((result: OperationAuthorisation) =>
                    mapAuthorisationResult([result]) != null
                        ? mapAuthorisationResult([result])[0]
                        : null
                )
                // tap(authorisation =>
                //     this.missionAirspaceAuthorisationsSubject.next(
                //         authorisation
                //     )
                // )
            );
    }

    private cancelAuthorisationAuthorisationWithUrl(
        id: string,
        authorisationType: AirspaceAuthorisationDto.AuthorisationType = AirspaceAuthorisationDto
            .AuthorisationType.USA_LAANC
    ) {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        return this.http
            .post<OperationAuthorisation>(
                `${this.authorisationUrlSource.getValue()}/authorisations/${authorisationType}/${id}/cancel`,
                null,
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    return throwError(() => parseErrorBody(err));
                }),
                // FIXME: this is only a temporary solution and will need to go once the API is finalised
                map((result: OperationAuthorisation) =>
                    mapAuthorisationResult([result]) != null
                        ? mapAuthorisationResult([result])[0]
                        : null
                )
            );
    }

    private deleteAuthorisationWithUrl(
        id: string,
        authorisationType: AirspaceAuthorisationDto.AuthorisationType = AirspaceAuthorisationDto
            .AuthorisationType.USA_LAANC
    ) {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        return this.http
            .post<void>(
                `${this.authorisationUrlSource.getValue()}/authorisations/${authorisationType}/${id}/delete`,
                null,
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    return throwError(() => parseErrorBody(err));
                })
            );
    }
    private closeAuthorisationWithUrl(
        id: string,
        authorisationType: AirspaceAuthorisationDto.AuthorisationType = AirspaceAuthorisationDto
            .AuthorisationType.USA_LAANC
    ) {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        return this.http
            .post<OperationAuthorisation>(
                `${this.authorisationUrlSource.getValue()}/authorisations/${authorisationType}/${id}/close`,
                {},
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    return throwError(() => parseErrorBody(err));
                }),
                // FIXME: this is only a temporary solution and will need to go once the API is finalised
                map((result: OperationAuthorisation) =>
                    mapAuthorisationResult([result]) != null
                        ? mapAuthorisationResult([result])[0]
                        : null
                )
            );
    }

    private acknowledgeRescindedWithUrl(
        id: string,
        authorisationType: AirspaceAuthorisationDto.AuthorisationType = AirspaceAuthorisationDto
            .AuthorisationType.USA_LAANC
    ) {
        if (this.externalAuthorisationBearerToken$.getValue() == null) {
            // If there's no bearer token, restart the polling and throw an error
            this.airspaceCheckService.restartExternalBearerTokenPolling();
            return throwError(() => new SessionExpired());
        }
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${
                this.externalAuthorisationBearerToken$.getValue().accessToken
            }`
        );
        return this.http
            .post<OperationAuthorisation>(
                `${this.authorisationUrlSource.getValue()}/authorisations/${authorisationType}/${id}/acknowledgeRescinded`,
                null,
                { headers }
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        return throwError(() => new SessionExpired());
                    }
                    return throwError(() => parseErrorBody(err));
                }),
                // FIXME: this is only a temporary solution and will need to go once the API is finalised
                map((result: OperationAuthorisation) =>
                    mapAuthorisationResult([result]) != null
                        ? mapAuthorisationResult([result])[0]
                        : null
                )
            );
    }
}
