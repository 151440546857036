import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlyFreelyConstants } from '../constants';
import {
    CompleteMaintenanceLogCommand,
    CreateFlightTestMissionCommand,
    CreateMaintenanceActivityCommand,
    CreateMaintenanceLogCommand,
    LinkMissionAsFlightTestCommand,
    MaintenanceActivityDto,
    MaintenanceLogDto,
    MaintenanceLogSummary,
    ReassignMaintenanceActivityCommand,
    TriggerActivationDto,
    UnlinkMissionAsFlightTestCommand,
    UpdateUnsubmittedMaintenanceLogCommand,
    UpdateSubmittedMaintenanceLog,
    ResultDto,

} from '../model/api';
import { NameValue } from '../model/ui';
import { AttachmentHandler } from './attachments';
import { httpParamSerializer } from './service.helpers';
import { BulkCreateMaintenanceLogCommand } from '../model/api/bulkCreateMaintenanceLogCommand';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    attachmentHandler(maintenanceLogId: number, maintenanceActivityId: number) {
        return new AttachmentHandler(
            this.http,
            `/webapi/maintenance/${maintenanceLogId}` +
                `/activities/${maintenanceActivityId}/attachments`
        );
    }

    findMaintenanceLogs(
        organisationId: number,
        textSearch?: string | null,
        resourceCategory?: string,
        resourceId?: number,
        airworthinessNoticeId?: number
        
    ) {
        return this.http.get<MaintenanceLogSummary[]>(
            `${this.baseUrl}/webapi/maintenance`,
            {
                params: httpParamSerializer({
                    organisationId,
                    textSearch,
                    resourceCategory,
                    resourceId,
                    airworthinessNoticeId
                    
                })
            }
        );
    }

    createMaintenanceLog(command: CreateMaintenanceLogCommand) {
        return this.http
            .post<MaintenanceLogDto>(
                `${this.baseUrl}/webapi/maintenance`,
                command
            )
            // .pipe(tap(() => this.changeSource.next()));
    }
    createBulkMaintenanceRequest(command: BulkCreateMaintenanceLogCommand) {
        return this.http
            .post<BulkCreateMaintenanceLogCommand>(
                `${this.baseUrl}/webapi/maintenance/bulk`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findMaintenanceLogById(maintenanceLogId: number) {
        return this.http.get<MaintenanceLogDto>(
            `${this.baseUrl}/webapi/maintenance/${maintenanceLogId}`
        );
    }

    updateMaintenanceLog(
        maintenanceLogId: number,
        command: UpdateUnsubmittedMaintenanceLogCommand
    ) {
        return this.http
            .put<MaintenanceLogDto>(
                `${this.baseUrl}/webapi/maintenance/${maintenanceLogId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    updateMaintenanceIdentifierAndWorkInstructionsUrl(
        maintenanceLogId: number,
        command: UpdateSubmittedMaintenanceLog
    ) {
        return this.http
            .put<MaintenanceLogDto>(
                `${this.baseUrl}/webapi/maintenance/${maintenanceLogId}/details`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    createMaintenanceActivity(
        maintenanceLogId: number,
        command: CreateMaintenanceActivityCommand
    ) {
        return this.http
            .post<MaintenanceActivityDto>(
                `${this.baseUrl}/webapi/maintenance/${maintenanceLogId}/activities`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }

    findMaintenanceActivityById(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}`;
        return this.http.get<MaintenanceActivityDto>(`${this.baseUrl}${url}`);
    }

    updateUnassignedMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number,
        command: any
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    assignMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/assign`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    submitMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/submit`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    updateAssignedMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number,
        command: any
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/work`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    updateSubmittedMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number,
        command: any
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/review`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    completeSubmittedMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/complete`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    createFlightTest(
        maintenanceLogId: number,
        maintenanceActivityId: number,
        command: CreateFlightTestMissionCommand
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/flightTest`;
        return this.http.post<MaintenanceActivityDto>(
            `${this.baseUrl}${url}`,
            command
        );
    }

    linkFlightTest(
        maintenanceLogId: number,
        maintenanceActivityId: number,
        command: LinkMissionAsFlightTestCommand
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/linkFlightTest`;
        return this.http.post<MaintenanceActivityDto>(
            `${this.baseUrl}${url}`,
            command
        );
    }

    unlinkFlightTest(
        maintenanceLogId: number,
        maintenanceActivityId: number,
        command: UnlinkMissionAsFlightTestCommand
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/unlinkFlightTest`;
        return this.http.post<MaintenanceActivityDto>(
            `${this.baseUrl}${url}`,
            command
        );
    }

    checkIdentifierUnique(organisationId: number, identifier: string) {
        const url = `/webapi/maintenance/checkIdentifier`;
        return this.http.get<ResultDto>(`${this.baseUrl}${url}`, {
            params: httpParamSerializer({
                organisationId: organisationId,
                identifier: identifier
            })
        });
    }

    submitMaintenanceLog(maintenanceLogId: number) {
        const url = `/webapi/maintenance/${maintenanceLogId}/submit`;
        return this.http
            .put<MaintenanceLogDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    finaliseMaintenanceLog(
        maintenanceLogId: number,
        command: CompleteMaintenanceLogCommand
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/finalise`;
        return this.http
            .put<MaintenanceLogDto>(`${this.baseUrl}${url}`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    unfinaliseMaintenanceLog(maintenanceLogId: number) {
        const url = `/webapi/maintenance/${maintenanceLogId}/unfinalise`;
        return this.http
            .put<MaintenanceLogDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    startMaintenanceActivityProgress(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/start`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    stopMaintenanceActivityProgress(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/stop`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    cancelMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/cancel`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    cancelMaintenanceLog(maintenanceLogId: number) {
        const url = `/webapi/maintenance/${maintenanceLogId}/cancel`;
        return this.http
            .put<MaintenanceLogDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    reassignMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number,
        command: ReassignMaintenanceActivityCommand
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/reassign`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    unfinaliseMaintenanceActivity(
        maintenanceLogId: number,
        maintenanceActivityId: number
    ) {
        const url = `/webapi/maintenance/${maintenanceLogId}/activities/${maintenanceActivityId}/unfinalise`;
        return this.http
            .put<MaintenanceActivityDto>(`${this.baseUrl}${url}`, null)
            .pipe(tap(() => this.changeSource.next()));
    }

    exportOrganisationMaintenance(organisationId: number) {
        const headers = new HttpHeaders({
            'Content-type': 'text/csv;charset=utf-8',
            Accept: 'text/csv;charset=utf-8'
        });

        return this.http.get(`${this.baseUrl}/webapi/maintenance/export`, {
            params: httpParamSerializer({
                organisationId
            }),
            responseType: 'blob',
            headers: headers
        });
    }

    getEquipmentTypes(): NameValue[] {
        return [
            {
                value: 'CRAFT',
                name: 'RPA'
            },
            {
                value: 'BATTERY',
                name: 'Battery'
            },
            {
                value: 'BATTERY_SET',
                name: 'Battery Set'
            },
            { value: 'EQUIPMENT', name: 'Equipment' }
        ];
    }

    getMaintenanceLogStatuses(): NameValue[] {
        return [
            { value: 'DRAFT', name: 'Draft' },
            { value: 'REQUESTED', name: 'Requested' },
            { value: 'COMPLETED', name: 'Completed' },
            { value: 'CANCELLED', name: 'Cancelled' }
        ];
    }

    getMaintenanceActivityStatuses(): NameValue[] {
        return [
            { value: 'ASSIGNED', name: 'Assigned' },
            { value: 'CANCELLED', name: 'Cancelled' },
            { value: 'COMPLETED', name: 'Completed' },
            { value: 'DRAFT', name: 'Draft' },
            { value: 'IN_PROGRESS', name: 'In Progress' },
            { value: 'SUBMITTED', name: 'Submitted' }
        ];
    }

    getCombinedStatusFilters(): NameValue[] {
        return [
            { value: 'REQUESTED|ASSIGNED|ACTIVITY_COMPLETED', name: 'Pending' },
            { value: 'DRAFT', name: 'Draft' },
            { value: 'REQUESTED', name: 'Requested' },
            { value: 'COMPLETED', name: 'Finalised' },
            { value: 'ASSIGNED', name: 'Assigned' },
            { value: 'CANCELLED', name: 'Cancelled' },
            { value: 'ACTIVITY_COMPLETED', name: 'Activity Completed' },
            { value: 'ACTIVITY_CANCELLED', name: 'Activity Cancelled' }
        ];
    }

    getCombinedStatuses(): NameValue[] {
        return [
            { value: 'DRAFT', name: 'Draft' },
            { value: 'REQUESTED', name: 'Requested' },
            { value: 'COMPLETED', name: 'Finalised' },
            { value: 'ASSIGNED', name: 'Assigned' },
            { value: 'CANCELLED', name: 'Cancelled' },
            { value: 'ACTIVITY_COMPLETED', name: 'Activity Completed' },
            { value: 'ACTIVITY_CANCELLED', name: 'Activity Cancelled' }
        ];
    }

    getCombinedStatus(logStatus: string, activityStatus: string): string {
        if (logStatus === 'DRAFT') {
            return 'DRAFT';
        } else if (logStatus === 'COMPLETED') {
            return 'COMPLETED';
        } else if (logStatus === 'CANCELLED') {
            return 'CANCELLED';
        } else if (logStatus === 'REQUESTED') {
            if (activityStatus === 'DRAFT') {
                return 'REQUESTED';
            } else if (
                activityStatus === 'ASSIGNED' ||
                activityStatus === 'IN_PROGRESS'
            ) {
                return 'ASSIGNED';
            } else if (activityStatus === 'SUBMITTED') {
                return 'ACTIVITY_COMPLETED';
            } else if (activityStatus === 'COMPLETED') {
                return 'REQUESTED';
            } else if (
                activityStatus === 'REQUESTED' ||
                activityStatus === 'CANCELLED'
            ) {
                return 'ACTIVITY_CANCELLED';
            } else {
                return 'REQUESTED';
            }
        }
    }

    getMaintenanceLogTypes(): NameValue[] {
        return [
            {
                name: 'Defect',
                value: 'DEFECT'
            },
            {
                name: 'Scheduled',
                value: 'SCHEDULED'
            },
            {
                name: 'In Field',
                value: 'IN_FIELD'
            },
            {
                name: 'Inspection',
                value: 'INSPECTION'
            },
            {
                name: 'Configuration Change',
                value: 'CONFIGURATION_CHANGE'
            },
            {
                name: 'Other',
                value: 'OTHER'
            }
        ];
    }
}

export const maintenanceOutcomes: NameValue[] = [
    {
        value: MaintenanceLogDto.Outcome.RELEASE_TO_SERVICE,
        name: 'Release to Service'
    },
    {
        value: MaintenanceLogDto.Outcome.RETIRE_FROM_SERVICE,
        name: 'Retire from Service'
    }
];

export const triggerActivationTypes: NameValue[] = [
    { name: 'Due', value: TriggerActivationDto.Type.DUE },
    { name: 'Upcoming', value: TriggerActivationDto.Type.UPCOMING },
    { name: 'Overdue', value: TriggerActivationDto.Type.OVERDUE }
];
