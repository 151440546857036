import { CreateRiskCommand, UpdateRiskCommand, RiskDto } from '../model/api';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlyFreelyConstants } from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RiskRegisterService {
    private baseUrl: string;
    private changeSource = new Subject<void>();
    change$ = this.changeSource.asObservable();

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    ngOnDestroy() {
        this.changeSource.complete();
    }

    find(organisationId: number) {
        return this.http.get<RiskDto[]>(`${this.baseUrl}/webapi/riskRegister`, {
            params: new HttpParams().set(
                'organisationId',
                organisationId.toString()
            )
        });
    }

    create(command: CreateRiskCommand) {
        return this.http
            .post<RiskDto>(`${this.baseUrl}/webapi/riskRegister`, command)
            .pipe(tap(() => this.changeSource.next()));
    }

    update(riskId: number, command: UpdateRiskCommand) {
        return this.http
            .put<RiskDto>(
                `${this.baseUrl}/webapi/riskRegister/${riskId}`,
                command
            )
            .pipe(tap(() => this.changeSource.next()));
    }
}
