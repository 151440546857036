import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    AdminJurisdictionService,
    CreateOperatingCategoryCommand,
    LosOperatingCategory,
    NameValue,
    OperatingCategoryDto,
    OperatingCategoryValuesDtoObject,
    operatingConditionsValueLookup,
    currencyOperatingCategoriesNameValueArray,
    RpaCategoryOperatingCategory,
    TimeOfDayOperatingCategory,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlyFreelyLoggingService } from '@flyfreely-portal-ui/flyfreely';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const categoryValueOptions: { [name: string]: any } = {
    [CreateOperatingCategoryCommand.Type.LOS]: Object.keys(
        LosOperatingCategory.Value
    ).map(k => LosOperatingCategory.Value[k]),
    [CreateOperatingCategoryCommand.Type.TIME_OF_DAY]: Object.keys(
        TimeOfDayOperatingCategory.Value
    ).map(k => TimeOfDayOperatingCategory.Value[k]),
    [CreateOperatingCategoryCommand.Type.RPA_CATEGORY]: Object.keys(
        RpaCategoryOperatingCategory.Value
    ).map(k => RpaCategoryOperatingCategory.Value[k])
};

@Component({
    selector: 'edit-operating-categories-dialogue',
    templateUrl: './edit-operating-categories-dialogue.component.html'
})
export class EditOperatingCategoriesDialogue {
    @Input() airspaceJurisdictionId: number;
    @Output() update = new EventEmitter<OperatingCategoryValuesDtoObject[]>();

    operatingCategories = currencyOperatingCategoriesNameValueArray;
    operatingConditionsValueLookup = operatingConditionsValueLookup;
    categoryValueOptions = categoryValueOptions;
    // TODO: this would be better as a formgroup
    showAddCategories: string;
    valueToAdd = {
        [CreateOperatingCategoryCommand.Type.LOS]: null,
        [CreateOperatingCategoryCommand.Type.TIME_OF_DAY]: null,
        [CreateOperatingCategoryCommand.Type.RPA_CATEGORY]: null,
        [CreateOperatingCategoryCommand.Type.RPA_MTOW]: null
    };
    private categoryValuesSource = new ReplaySubject<OperatingCategoryDto[]>(1);
    categoryValues$ = this.categoryValuesSource.asObservable();

    private ngUnsubscribe$ = new Subject<void>();
    private workTracker = new WorkTracker();
    working: boolean;
    hasMtowUnlimited = true;

    constructor(
        private airspaceJurisdictionService: AdminJurisdictionService,
        private modal: BsModalRef<EditOperatingCategoriesDialogue>,
        private logging: FlyFreelyLoggingService
    ) {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.refreshOperatingCategoryValues();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.categoryValuesSource.complete();
    }

    private refreshOperatingCategoryValues() {
        this.airspaceJurisdictionService
            .findOperatingConditionCategories(this.airspaceJurisdictionId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(operatingCategoryValues => {
                this.hasMtowUnlimited =
                    operatingCategoryValues
                        .find(v => v.type === 'RPA_MTOW')
                        ?.values?.findIndex(v => v.value == null) === -1 ??
                    false;
                this.categoryValuesSource.next(
                    operatingCategoryValues.reduce(
                        (acc, v) =>
                            acc.concat({
                                type: v.type,
                                values: v.values
                            }),
                        []
                    )
                );
            })
            .add(this.workTracker.createTracker());
    }

    addCategory(category: CreateOperatingCategoryCommand.Type) {
        this.showAddCategories = null;
        this.airspaceJurisdictionService
            .createOperatingConditionCategories(this.airspaceJurisdictionId, {
                type: category,
                value: this.valueToAdd[category]
            })
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.valueToAdd[category] = null;
                this.refreshOperatingCategoryValues();
            })
            .add(this.workTracker.createTracker());
    }

    deleteCategory(category: OperatingCategoryDto) {
        this.airspaceJurisdictionService
            .deleteOperatingConditionCategories(
                this.airspaceJurisdictionId,
                category.id
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(result => {
                this.refreshOperatingCategoryValues();
            })
            .add(this.workTracker.createTracker());
    }

    cancel() {
        this.modal.hide();
    }
}
