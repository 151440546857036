import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    DisplayableMissionDto,
    FlyFreelyError,
    FlyFreelyLoggingService,
    MaintenanceLogDto,
    MissionService,
    ServiceabilitySignoffCommand,
    ServiceabilitySignoffDto
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import {
    debounceTime,
    filter,
    map,
    switchMap,
    takeUntil
} from 'rxjs/operators';
import {
    MissionCompletionService,
    ServiceabilitySignoffFields,
    ServiceabilitySignoffForm
} from '../../mission-completion.service';

@Component({
    selector: 'serviceability-signoff',
    templateUrl: './serviceability-signoff.component.html'
})
export class ServiceabilitySignoffComponent {
    @Input() form: FormGroup<ServiceabilitySignoffForm>;
    @Input() maintenanceLogs: FormControl<MaintenanceLogDto[]>;
    @Input() missionId: number;

    @Output() serviceabilityUpdated = new EventEmitter<DisplayableMissionDto>();

    assignedMaintenanceLog: MaintenanceLogDto;
    currentResult: {
        serviceability: ServiceabilitySignoffCommand.Serviceability;
        reason: string;
    };

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private missionService: MissionService,
        private missionCompletionService: MissionCompletionService,
        private logging: FlyFreelyLoggingService
    ) {}

    ngOnInit() {
        this.refreshValues();

        this.form.valueChanges
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                map(() => this.updateRpa()),
                // Manually filter validity due to changing validity rules depending on serviceability state.
                filter(
                    result =>
                        (result.serviceability ===
                            ServiceabilitySignoffCommand.Serviceability
                                .SERVICEABLE ||
                            (result.serviceability ===
                                ServiceabilitySignoffCommand.Serviceability
                                    .UNSERVICEABLE &&
                                result.reason != null &&
                                result.reason.length > 2)) &&
                        // distinctUntilChanged cannot see all the nested values, so a custom filter is needed to prevent a loop
                        (this.currentResult == null ||
                            result.serviceability !==
                                this.currentResult.serviceability ||
                            result.reason !== this.currentResult.reason)
                ),
                debounceTime(700),
                switchMap(result =>
                    this.missionService.storeServiceabilitySignoff(
                        result,
                        this.missionId
                    )
                )
            )
            .subscribe(
                result => {
                    this.refreshValues();
                    this.serviceabilityUpdated.emit(result);
                    this.updateSignedOff(result.serviceabilitySignoffs);
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `There was an issue with updating the craft's serviceabililty: ${error.message}`
                    )
            );

        this.maintenanceLogs.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshValues());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    checkFormValidity() {
        if (
            this.form.controls.serviceability.value ===
            ServiceabilitySignoffDto.Serviceability.UNSERVICEABLE
        ) {
            this.form.controls.reason.setValidators(Validators.required);
            this.form.controls.reason.updateValueAndValidity();
        } else {
            this.form.controls.reason.clearValidators();
            this.form.controls.reason.updateValueAndValidity();
        }
    }

    updateSignedOff(serviceabilitySignoffs: ServiceabilitySignoffDto[]) {
        const rpaId = this.form.value.rpaId;
        if (rpaId != null) {
            const signoff = serviceabilitySignoffs.find(s => s.rpaId === rpaId);
            if (signoff != null) {
                this.form.controls.signoffPerson.patchValue(
                    signoff.signoffPerson
                );
                this.form.controls.signoffTime.patchValue(signoff.signoffTime);
            }
        }
    }

    updateRpa() {
        const value = this.form.value;
        const command: ServiceabilitySignoffCommand = {
            serviceability: value.serviceability,
            reason: value.reason ?? null,
            rpaId: value.rpaId ?? null
        };
        return command;
    }

    refreshValues() {
        this.currentResult = <ServiceabilitySignoffFields>this.form.value;
        this.assignedMaintenanceLog = this.maintenanceLogs.value.find(
            l => l.id === this.form.value.maintenanceLogId
        );
        this.missionCompletionService.refreshMaintenanceSubmitted();
    }

    submitMaintenance() {
        this.missionCompletionService.submitMaintenanceRequest(
            this.assignedMaintenanceLog.id
        );
    }

    requestSubmitted() {
        if (
            this.assignedMaintenanceLog != null &&
            (this.assignedMaintenanceLog.status ===
                MaintenanceLogDto.Status.REQUESTED ||
                this.assignedMaintenanceLog.status ===
                    MaintenanceLogDto.Status.COMPLETED)
        ) {
            return true;
        }
        return false;
    }
}
