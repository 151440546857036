import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    BatteryDto,
    BatteryService,
    BatterySetDto,
    BatterySetService,
    CraftDto,
    CraftService,
    EquipmentDto,
    EquipmentService,
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    batterySearch,
    batterySetSearch,
    equipmentSearch,
    rpaSearch
} from 'libs/resource-ui/src/lib/searchFunctions';
import { Subject } from 'rxjs';
import { takeUntil, delay } from 'rxjs/operators';
import { ComponentServiceTime } from 'libs/maintenance/src/lib/history/component-service-time/component-service-time.component';

@Component({
    selector: 'maintenance-request-edit',
    templateUrl: './maintenance-request-edit.component.html'
})
export class MaintenanceRequestEdit implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() createBulk: string;
    @Input() organisation: PersonsOrganisationDto;
    @Input() showScheduledMaintenance: boolean;
    allRpa: CraftDto[];
    allBatteries: BatteryDto[];
    allBatterySets: BatterySetDto[];
    allEquipment: EquipmentDto[];
    @ViewChild(ComponentServiceTime, { static: false })
    componentServiceTime: ComponentServiceTime;
    isMultipleSelect: boolean = false;
    resourceIdControlName: string = 'resourceId';
    resourceIdvalidity:any;

    batterySearch = batterySearch;
    batterySetSearch = batterySetSearch;
    equipmentSearch = equipmentSearch;
    rpaSearch = rpaSearch;

    showInServiceTime: boolean;

    working: boolean = false;
    private workTracker: WorkTracker = new WorkTracker();

    private ngUnsubscribe$: Subject<void> = new Subject();

    constructor(
        private logging: FlyFreelyLoggingService,
        private craftService: CraftService,
        private batteryService: BatteryService,
        private batterySetService: BatterySetService,
        private equipmentService: EquipmentService
    ) {}

    ngOnInit() {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => {
                this.working = working;
                if (this.createBulk !== 'bulk' ) {
                    this.updateShowInServiceTime();
                }
                
            });
            if (this.createBulk === 'bulk' ) {
                this.isMultipleSelect = true;
                this.resourceIdvalidity = this.form.controls.resourceIdList
                this.resourceIdControlName = 'resourceIdList';
            } else {
                this.isMultipleSelect = false;
                this.resourceIdvalidity = this.form.controls.resourceId
                this.resourceIdControlName = 'resourceId';
            }
        this.refreshRpa();
        this.refreshBatteries();
        this.refreshBatterySets();
        this.refreshEquipment();

        this.form.valueChanges
            .pipe(delay(200), takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                if (this.createBulk !== 'bulk') {
                    this.updateShowInServiceTime();
                    if (
                        this.form.controls.resourceId.valid &&
                        this.form.controls.resourceCategory.valid &&
                        this.componentServiceTime
                    ) {
                        this.componentServiceTime.refreshServiceTimes();
                    }
                } else {
                    return;
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshRpa() {
        this.craftService
            .findCrafts(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: rpas => {
                    this.allRpa = rpas;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading RPAs: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    refreshBatteries() {
        this.batteryService
            .findBatteries(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteries => {
                    this.allBatteries = batteries;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading batteries: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    refreshBatterySets() {
        this.batterySetService
            .findBatterySets(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: batteries => {
                    this.allBatterySets = batteries;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading batteries: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    refreshEquipment() {
        this.equipmentService
            .find(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe({
                next: equipment => {
                    this.allEquipment = equipment;
                },
                error: (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error while loading batteries: ${error.message}`
                    );
                }
            })
            .add(this.workTracker.createTracker());
    }

    selectedOrganisationId(resourceType: any, id: number) {
        if (
            !resourceType ||
            !id ||
            !this.allRpa ||
            !this.allBatteries ||
            !this.allBatterySets ||
            !this.allEquipment
        ) {
            return;
        }
        let resource: CraftDto | BatteryDto | BatterySetDto | EquipmentDto;
        switch (resourceType) {
            case 'CRAFT':
                resource = this.allRpa.find(i => i.id === id);
                return resource && resource.organisationId;
            case 'BATTERY':
                resource = this.allBatteries.find(i => i.id === id);
                return resource && resource.organisationId;
            case 'BATTERY_SET':
                resource = this.allBatterySets.find(i => i.id === id);
                return resource && resource.organisationId;
            case 'EQUIPMENT':
                resource = this.allEquipment.find(i => i.id === id);
                return resource && resource.organisationId;
        }
    }

    updateShowInServiceTime() {
        this.showInServiceTime =
            this.form.controls.resourceId.value &&
            this.form.controls.resourceCategory.value &&
            (this.form.controls.resourceCategory.value === 'CRAFT' ||
                this.form.controls.resourceCategory.value === 'EQUIPMENT') &&
            !!this.selectedOrganisationId(
                this.form.controls.resourceCategory.value,
                this.form.controls.resourceId.value
            );
    }
}
