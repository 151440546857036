<ng-container screenAnalytics="flight-log-overview">
    <div class="modal-header no-icon">
        <h4 class="modal-title">Flight Logs</h4>
        <enhanced-help-switch></enhanced-help-switch>
        <knowledge-base-link></knowledge-base-link>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div
        ngfDrop
        [(validDrag)]="baseDropValid"
        [class.invalid-drag]="baseDropValid === false"
        [class.valid-drag]="baseDropValid"
        accept="*/*, .log"
        [(files)]="uploadingFlightLogs"
        (filesChange)="uploadLogs($event)"
        [fileDropDisabled]="working || isUploading || !canViewAllLogs"
    >
        <div #flightLogList class="modal-body max-height">
            <div class="modal-body-content">
                <div class="horizontal-container">
                    <div
                        class="input-group no-wrap"
                        btnRadioGroup
                        [formControl]="filterSelection"
                    >
                        <label
                            class="btn btn-sm btn-pill"
                            btnRadio="INCOMING"
                            tooltip
                            placement="top"
                            enhancedHelp="incoming-filter"
                            >Incoming</label
                        >
                        <label
                            class="btn btn-sm btn-pill"
                            btnRadio="ASSIGNED"
                            tooltip
                            placement="top"
                            enhancedHelp="assigned-filter"
                            >Assigned</label
                        >
                        <label
                            class="btn btn-sm btn-pill"
                            btnRadio="ARCHIVED"
                            tooltip
                            placement="top"
                            enhancedHelp="archived-filter"
                            >Archived</label
                        >
                        <label
                            class="btn btn-sm btn-pill"
                            btnRadio="FAILED"
                            tooltip
                            placement="top"
                            enhancedHelp="failed-filter"
                            >Failed</label
                        >
                    </div>
                    <div class="fill"></div>
                    <div *ngIf="!canViewAllLogs" class="alert alert-info">
                        To upload and assign flight logs, edit the mission
                        record of the appropriate mission. Only logs collected
                        via automatic sync will show up in this list.
                    </div>
                    <div *ngIf="hasOutOfDateLogs" class="alert alert-warning">
                        Some flight logs in these results need reprocessing for
                        accuracy due to updates made to the log extractor by the
                        manufacturer since the original processing. To reprocess
                        press the
                        <span class="fal fa-business-time"></span> button.
                    </div>
                </div>
                <enhanced-help componentName="flight-log-list" [compact]="true">
                </enhanced-help>
                <!-- specifically checking that hasFlightLogs is not false will ignore if it is null -->
                <static-table
                    *ngIf="hasFlightLogs != false || tableConfig.totalItems > 0"
                    [availableColumns]="availableColumns"
                    [tableData]="flightLogs$ | async"
                    [tableConfig]="tableConfig"
                    [tableSearch]="tableSearch"
                    [columnSorting]="tableSorting"
                    [templateActionsWidth]="tableActionCount"
                    (pageChanged)="onTablePageChanged($event)"
                    (search)="onTableSearch($event)"
                    (sortOrderChanged)="updateColumnSorting($event)"
                >
                    <ng-template
                        columnTemplate="startTime"
                        let-row="row"
                        let-column="column"
                    >
                        <div
                            *ngIf="
                                row.startTime == null &&
                                row.collectionTime != null
                            "
                        >
                            <span
                                class="fal fa-circle-info"
                                tooltip="Flight log start time is unavailable for this log, so this is the date and time that the flight log was collected"
                                placement="top"
                            ></span
                            >&nbsp;
                            <span
                                tooltip="Flight log start time is unavailable for this log, so this is the date and time that the flight log was collected"
                                placement="top"
                            >
                                <em
                                    >Collected
                                    {{
                                        row.collectionTime | formatDateTime
                                    }}</em
                                >
                            </span>
                        </div>
                        <div *ngIf="row.startTime != null">
                            {{ row.startTime | formatDateTime }}
                        </div>
                        <div
                            *ngIf="
                                row.startTime != null &&
                                row.collectionTime == null
                            "
                        >
                            <span>&#8211;</span>
                        </div>
                    </ng-template>
                    <ng-template
                        columnTemplate="batteryIds"
                        let-row="row"
                        let-column="column"
                    >
                        <div>
                            <ng-container
                                *ngIf="
                                    row.batteryIds == null ||
                                    row.batteryIds.length === 0
                                "
                            >
                                <ng-container
                                    *ngIf="
                                        row.summary == null ||
                                        row.summary.batterySerialNumber == null
                                    "
                                >
                                    -
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        row.summary != null &&
                                        row.summary.batterySerialNumber != null
                                    "
                                >
                                    <div
                                        style="
                                            position: relative;
                                            display: flex;
                                            align-items: center;
                                        "
                                    >
                                        <span
                                            >{{
                                                row.summary.batterySerialNumber
                                            }}
                                        </span>
                                        <!-- If the user can't view batteries in the org and has had no battery return as a result, they will always see this summary serial number value, making the tooltip inaccurate and confusing -->
                                        <span
                                            *ngIf="canViewBatteries"
                                            class="fal fa-circle-info left-buffer"
                                            tooltip
                                            placement="top"
                                            enhancedHelp="log-battery-not-in-org"
                                            [enhancedHelpShowOnActivate]="false"
                                        ></span>
                                        <button
                                            *ngIf="canAddBattery"
                                            [disabled]="working"
                                            class="btn btn-inline btn-link"
                                            type="button"
                                            (click)="addBattery(row)"
                                            tooltip="Add this battery to your organisation"
                                            placement="left"
                                            analyticsOn
                                            analyticsAction="add-battery"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    row.batteryIds != null &&
                                    row.batteryIds.length > 0
                                "
                            >
                                <ng-container
                                    *ngFor="
                                        let battery of row.batteryIds;
                                        let first = first;
                                        let last = last
                                    "
                                >
                                    <!-- Using left-buffer because adding spaces after the comma isn't being respected on render -->
                                    <a
                                        (click)="showBattery(battery)"
                                        [ngClass]="{ 'left-buffer': !first }"
                                        >{{ batteriesLookup[battery]?.name }}</a
                                    >
                                    {{ last ? '' : ',' }}
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-template>
                    <ng-template
                        columnTemplate="matchedRpaName"
                        let-row="row"
                        let-column="column"
                    >
                        <div>
                            <ng-container *ngIf="row.rpa?.id == null">
                                <ng-container
                                    *ngIf="row.processedStatus === 'FAILED'"
                                >
                                    File does not appear to be a valid flight
                                    log.
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        row.summary == null &&
                                        row.processedStatus !== 'FAILED'
                                    "
                                >
                                    Flight log needs to be processed.
                                    <button
                                        [disabled]="working"
                                        class="btn btn-tertiary"
                                        style="position: absolute; top: 0"
                                        type="button"
                                        (click)="doReprocess([row.id])"
                                        tooltip="Reprocess flight log"
                                        placement="left"
                                        analyticsOn
                                        analyticsAction="reprocess"
                                    >
                                        <span
                                            class="fal fa-business-time"
                                        ></span>
                                    </button>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        row.summary != null &&
                                        row.processedStatus == 'PROCESSED'
                                    "
                                >
                                    <a (click)="showAssignRpa(row)">Assign</a>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="row.rpa?.id != null">
                                <div style="position: relative">
                                    <a (click)="showRpa(row.rpa?.id)">{{
                                        row.rpa.nickname
                                    }}</a>
                                    <button
                                        *ngIf="
                                            row.processedStatus ===
                                                'PROCESSED' &&
                                            !row.outOfDate &&
                                            row.summary != null &&
                                            (row.summary?.rpaSerialNumber ==
                                                null ||
                                                row.summary?.rpaSerialNumber ==
                                                    '')
                                        "
                                        type="button"
                                        class="btn btn-tertiary left-buffer"
                                        style="position: absolute; top: -6px"
                                        (click)="showAssignRpa(row)"
                                        [disabled]="working"
                                        placement="top"
                                        tooltip="Assign a different RPA"
                                    >
                                        <span class="fal fa-pencil-alt"></span>
                                    </button>
                                    <button
                                        *ngIf="
                                            row.processedStatus ===
                                                'PROCESSED' &&
                                            !row.outOfDate &&
                                            row.incorrectRpa
                                        "
                                        type="button"
                                        class="btn btn-tertiary left-buffer"
                                        style="position: absolute; top: -6px"
                                        [disabled]="working"
                                        placement="top"
                                        tooltip="Re-assign automatically"
                                        (click)="reassignRpa(row)"
                                    >
                                        <span
                                            class="fal fa-wand-magic-sparkles"
                                        ></span>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>

                    <ng-template
                        columnTemplate="missionName"
                        let-row="row"
                        let-column="column"
                    >
                        <ng-container
                            *ngIf="
                                row.mission?.id == null &&
                                row.rpa?.id == null &&
                                row.summary?.rpaSerialNumber != null
                            "
                        >
                            Assign an RPA first
                        </ng-container>

                        <ng-container
                            *ngIf="
                                row.mission?.id == null &&
                                row.rpa?.id != null &&
                                row.suggestedMissionAssignment == null
                            "
                        >
                            <a
                                *ngIf="row.duration != null && row.duration > 0"
                                (click)="showAssignMission(row)"
                                analyticsOn
                                analyticsAction="assign-mission-no-suggestion"
                                >Assign to a mission</a
                            >
                            <span
                                *ngIf="
                                    row.duration == null || row.duration <= 0
                                "
                                >No flying logged</span
                            >
                        </ng-container>

                        <ng-container
                            *ngIf="
                                row.mission?.id == null &&
                                row.rpa?.id != null &&
                                row.suggestedMissionAssignment != null
                            "
                        >
                            <div class="status-icon-block">
                                <div class="status-icon">
                                    <div
                                        [class]="
                                            row.suggestedMissionAssignment
                                                | suggestedMissionMatchClass
                                        "
                                    ></div>
                                </div>
                                <div class="name-with-icon">
                                    <a
                                        [popover]="suggestedMissionTemplate"
                                        [popoverContext]="{
                                            flightLog: row,
                                            popover: popover
                                        }"
                                        [outsideClick]="true"
                                        #popover="bs-popover"
                                        placement="bottom"
                                        analyticsOn
                                        analyticsAction="show-mission-suggestion"
                                    >
                                        {{
                                            row.suggestedMissionAssignment
                                                .missionName
                                        }}
                                    </a>
                                    <ng-container
                                        *ngIf="
                                            row.duration != null &&
                                            row.duration > 0
                                        "
                                    >
                                        or
                                        <a
                                            (click)="showAssignMission(row)"
                                            analyticsOn
                                            analyticsAction="assign-mission-not-suggestion"
                                            >Select Another</a
                                        >
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="row.mission?.id != null">
                            <a (click)="showMission(row.mission?.id)">{{
                                row.mission?.name
                            }}</a>

                            <button
                                *ngIf="row.flight?.id == null"
                                type="button"
                                class="btn btn-tertiary btn-delete"
                                (click)="unassignFlightLog(row)"
                                tooltip="Unassign log file"
                                placement="left"
                                analyticsOn
                                analyticsAction="unassign-mission"
                            >
                                <span class="fal fa-unlink"></span>
                            </button>
                        </ng-container>
                    </ng-template>

                    <ng-template
                        columnTemplate="flightId"
                        let-row="row"
                        let-column="column"
                    >
                        <ng-container *ngIf="row.mission?.id == null">
                            Assign a mission first
                        </ng-container>

                        <ng-container
                            *ngIf="
                                row.mission?.id != null &&
                                row.flight?.id != null
                            "
                        >
                            <span class="fa fa-check"></span>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                row.mission?.id != null &&
                                row.flight?.id == null
                            "
                        >
                            <a (click)="showMissionRecord(row.mission?.id)"
                                >Assign to a flight</a
                            >
                        </ng-container>
                    </ng-template>

                    <ng-template
                        columnTemplate="conformance"
                        let-row="row"
                        let-column="column"
                    >
                        <ng-container
                            *ngIf="
                                row.conformanceResultList != null &&
                                row.conformanceResultList.length > 0
                            "
                        >
                            <conformance-icon
                                [result]="
                                    row.conformanceResultList
                                        | findCurrentConformanceResult
                                "
                            ></conformance-icon>
                        </ng-container>
                    </ng-template>

                    <ng-template rowActions let-row="row">
                        <ng-container
                            *ngIf="filterSelection.value == 'INCOMING'"
                        >
                            <button
                                [disabled]="working"
                                class="btn btn-tertiary"
                                type="button"
                                (click)="archiveLog(row)"
                                tooltip="Archive flight log"
                                placement="left"
                                analyticsOn
                                analyticsAction="archive-log-file"
                            >
                                <span class="fal fa-archive"></span>
                            </button>
                        </ng-container>

                        <ng-container
                            *ngIf="filterSelection.value == 'ARCHIVED'"
                        >
                            <button
                                [disabled]="working"
                                class="btn btn-tertiary"
                                type="button"
                                (click)="unarchiveLog(row)"
                                tooltip="Unarchive flight log"
                                placement="left"
                                analyticsOn
                                analyticsAction="unarchive-log-file"
                            >
                                <span class="fal fa-box-open"></span>
                            </button>
                        </ng-container>

                        <ng-container *ngIf="filterSelection.value == 'FAILED'">
                            <button
                                [disabled]="working"
                                class="btn btn-tertiary"
                                type="button"
                                (click)="downloadLog(row)"
                                tooltip="Download flight log"
                                placement="left"
                                analyticsOn
                                analyticsAction="download-log-file"
                            >
                                <span class="fal fa-download"></span>
                            </button>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                filterSelection.value == 'ASSIGNED' ||
                                filterSelection.value == 'INCOMING' ||
                                filterSelection.value == 'ARCHIVED'
                            "
                        >
                            <button
                                [disabled]="working"
                                class="btn btn-tertiary"
                                type="button"
                                (click)="showFlightAnalyser(row)"
                                tooltip="Flight Analyser"
                                placement="left"
                                analyticsOn
                                analyticsAction="analyse-log-file"
                            >
                                <span class="fal fa-map"></span>
                            </button>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                filterSelection.value == 'INCOMING' ||
                                filterSelection.value == 'ARCHIVED' ||
                                filterSelection.value == 'FAILED'
                            "
                        >
                            <button
                                [disabled]="working"
                                class="btn btn-tertiary"
                                type="button"
                                (click)="deleteLog(row)"
                                tooltip="Delete Flight Log"
                                placement="left"
                                analyticsOn
                                analyticsAction="delete-log-file"
                            >
                                <span class="fal fa-trash-alt"></span>
                            </button>
                        </ng-container>
                        <button
                            *ngIf="
                                isSystemAdmin ||
                                canReprocessLogs ||
                                (row.outOfDate &&
                                    row.processedStatus === 'PROCESSED')
                            "
                            [disabled]="working"
                            class="btn btn-tertiary"
                            type="button"
                            (click)="doReprocess([row.id])"
                            tooltip="Reprocess flight log"
                            placement="left"
                        >
                            <span class="fal fa-business-time"></span>
                        </button>
                    </ng-template>
                </static-table>
                <!-- specifically checking that hasFlightLogs is not false will ignore if it is null -->
                <div class="vertical-container top-buffer fill">
                    <empty
                        *ngIf="
                            hasFlightLogs == false &&
                            tableConfig.totalItems == 0 &&
                            !working
                        "
                        componentName="empty-flight-logs"
                    >
                    </empty>
                </div>
            </div>
        </div>
        <div class="modal-footer horizontal-container wrap gap center-children">
            <button
                type="button"
                class="btn btn-default fill-xs-12 fill-md-8 relative"
                ngfSelect
                [disabled]="working || isUploading || !canViewAllLogs"
                accept="*/*,.log,.txt"
                multiple="1"
                [(files)]="uploadingFlightLogs"
                (filesChange)="uploadLogs($event)"
                tooltip
                placement="top"
                enhancedHelp="upload-button"
            >
                <span
                    dynamicText
                    defaultText="Select or Drop Flight Log Here"
                    enhancedHelp="upload-button-text"
                ></span>
                <span
                    *ngIf="!canViewAllLogs"
                    class="overlay"
                    placement="top"
                    tooltip="Upload flight logs directly onto the appropriate missions"
                ></span>
            </button>
            <button
                *ngIf="canUseSettings"
                type="button"
                class="btn btn-default fill-xs-12 fill-md-4"
                (click)="showSettings()"
                tooltip
                placement="top"
                enhancedHelp="settings-button"
            >
                Setup Cloud Flight Log Sync
            </button>
        </div>
        <inline-modal></inline-modal>
        <ngx-loading [show]="working"></ngx-loading>
    </div>
</ng-container>
<ng-template
    #suggestedMissionTemplate
    let-flightLog="flightLog"
    let-popover="popover"
>
    <suggested-mission-assignment
        [flightLog]="flightLog"
        (showFlightAnalyser)="showFlightAnalyser(flightLog, true)"
        (hide)="popover.hide()"
    ></suggested-mission-assignment>
</ng-template>
