<div class="horizontal-container within-parent">
    <div class="container-scroll">
        <div>
            <h5>Screens</h5>
            <tree-root
                #layerTree
                [nodes]="screenTree"
                [options]="enhancedHelpTreeOptions"
                (activate)="activateTreeNode($event)"
            >
                <ng-template #treeNodeTemplate let-node let-index="index">
                    {{ node.data.name }}
                    <span
                        class="label label-warning label-enhanced-help-warning"
                        *ngIf="countItemsWithWarning(node.data)"
                        >{{ countItemsWithWarning(node.data) }}</span
                    >
                </ng-template>
                ></tree-root
            >
            <div class="contents">
                <button
                    (click)="export()"
                    class="btn btn-default left-buffer"
                    type="button"
                    [disabled]="working"
                >
                    Export
                </button>
                <button
                    type="button"
                    class="btn btn-default"
                    ngfSelect
                    accept="text/csv,application/vnd.ms-excel"
                    [(file)]="file"
                    (fileChange)="import($event)"
                >
                    Import
                </button>
            </div>
        </div>
    </div>
    <div class="fill container-scroll top-buffer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="content-title">Enhanced Help</h2>
                    <p class="help-block">
                        Enhanced Help blocks provide additional help and
                        assistance to users by means of embedded help components
                        that are bound to user interface elements. Below are
                        listed each element that implements Enhanced Help.
                    </p>
                </div>
            </div>
            <form
                *ngIf="selectedForm != null && showSelection"
                [formGroup]="selectedForm"
            >
                <div
                    class="top-buffer"
                    [attr.id]="selectedForm.controls?.id.value"
                >
                    <h3 class="section-heading">
                        <span>{{ selectedForm.controls?.name.value }}</span>
                        <div></div>
                    </h3>

                    <!-- Don't show the table on the first entry, which is the enhanced help help -->
                    <table class="table">
                        <tbody>
                            <tr>
                                <td style="width: 5%"></td>
                                <td style="width: 15%">Knowledge Base Link</td>
                                <td style="width: 70%">
                                    <input
                                        type="text"
                                        placeholder="The link to this screen's knowledge base article"
                                        formControlName="knowledgeBaseUrl"
                                        class="form-control"
                                        minlength="3"
                                        [readonly]="working"
                                    />
                                </td>
                                <td rowspan="2" style="width: 10%">
                                    <button
                                        class="btn btn-primary"
                                        type="button"
                                        (click)="
                                            saveScreenHelp(
                                                selectedForm.controls?.id.value,
                                                selectedForm.controls
                                                    ?.knowledgeBaseUrl,
                                                selectedForm.controls
                                                    ?.supportVideoUrl
                                            )
                                        "
                                        [disabled]="
                                            working ||
                                            (selectedForm.controls
                                                ?.knowledgeBaseUrl.pristine &&
                                                selectedForm.controls
                                                    ?.supportVideoUrl.pristine)
                                        "
                                    >
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 5%"></td>
                                <td style="width: 15%">Support Video Link</td>
                                <td style="width: 70%">
                                    <input
                                        type="text"
                                        placeholder="The link to this screen's support video"
                                        formControlName="supportVideoUrl"
                                        class="form-control"
                                        minlength="3"
                                        [readonly]="working"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table
                        class="table"
                        style="table-layout: fixed; width: 100%"
                    >
                        <thead>
                            <tr>
                                <th style="width: 5%"></th>
                                <th style="width: 15%">Help Component</th>
                                <th style="width: 70%">Help Text</th>
                                <th style="width: 10%"></th>
                            </tr>
                        </thead>
                        <tbody formArrayName="forms">
                            <ng-container
                                *ngFor="
                                    let formItem of selectedForm.controls?.forms
                                        .controls;
                                    let j = index
                                "
                                [formGroupName]="j"
                            >
                                <tr>
                                    <td>
                                        {{ formItem.controls.type.value }}
                                    </td>
                                    <td>
                                        {{ formItem.controls.name.value }}
                                    </td>
                                    <td>
                                        <textarea
                                            *ngIf="
                                                formItem.controls.type.value ===
                                                    'inline' ||
                                                formItem.controls.type.value ===
                                                    'empty' ||
                                                formItem.controls.type.value ===
                                                    'upsell'
                                            "
                                            class="form-control"
                                            rows="4"
                                            formControlName="helpText"
                                            placeholder="{{
                                                formItem.controls.helpText.value
                                            }}"
                                        ></textarea>
                                        <input
                                            *ngIf="
                                                formItem.controls.type.value ===
                                                    'tooltip' ||
                                                formItem.controls.type.value ===
                                                    'text'
                                            "
                                            class="form-control"
                                            formControlName="helpText"
                                            placeholder="{{
                                                formItem.controls.helpText.value
                                            }}"
                                        />
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-primary"
                                            [disabled]="formItem.pristine"
                                            (click)="onSaveChanges(formItem)"
                                        >
                                            Save Changes
                                        </button>
                                    </td>
                                </tr>
                                <tr
                                    *ngIf="
                                        formItem.controls.type.value ===
                                            'empty' ||
                                        formItem.controls.type.value ===
                                            'inline'
                                    "
                                >
                                    <td colspan="2"></td>
                                    <td
                                        class="horizontal-container center-children"
                                    >
                                        <label class="right-buffer"
                                            >Title</label
                                        >
                                        <input
                                            type="text"
                                            placeholder="The title of the feature which is used on the modals"
                                            formControlName="helpTitle"
                                            class="form-control"
                                            minlength="3"
                                            [readonly]="working"
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr
                                    *ngIf="
                                        formItem.controls.type.value ===
                                            'empty' ||
                                        formItem.controls.type.value ===
                                            'inline' ||
                                        formItem.controls.type.value ===
                                            'upsell'
                                    "
                                >
                                    <td colspan="2"></td>
                                    <td
                                        class="horizontal-container center-children"
                                    >
                                        <label class="right-buffer"
                                            >Knowledge Base Link</label
                                        >
                                        <input
                                            type="text"
                                            placeholder="The link to this enhanced helps's knowledge base article"
                                            formControlName="knowledgeBaseUrl"
                                            class="form-control"
                                            minlength="3"
                                            [readonly]="working"
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr
                                    *ngIf="
                                        formItem.controls.type.value ===
                                            'empty' ||
                                        formItem.controls.type.value ===
                                            'inline' ||
                                        formItem.controls.type.value ===
                                            'upsell'
                                    "
                                >
                                    <td colspan="2"></td>
                                    <td
                                        class="horizontal-container center-children"
                                    >
                                        <label class="right-buffer"
                                            >Support/Demo Video</label
                                        >
                                        <input
                                            type="text"
                                            placeholder="The link to this enhanced helps's support video"
                                            formControlName="supportVideoUrl"
                                            class="form-control"
                                            minlength="3"
                                            [readonly]="working"
                                        />
                                    </td>
                                    <td></td>
                                </tr>

                                <tr
                                    *ngIf="
                                        formItem.controls.type.value ===
                                        'inline'
                                    "
                                >
                                    <td colspan="2"></td>
                                    <td
                                        class="horizontal-container center-children"
                                    >
                                        <label class="right-buffer"
                                            >Always On Until</label
                                        >
                                        <div class="input-group fill">
                                            <input
                                                type="text"
                                                placeholder="Turn this help entry on until this date"
                                                formControlName="alwaysOnUntil"
                                                class="form-control"
                                                #datepicker="bsDatepicker"
                                                bsDatepicker
                                                [readonly]="working"
                                            />
                                            <span
                                                *ngIf="!working"
                                                class="input-group-addon btn-default"
                                                (click)="datepicker.toggle()"
                                                [attr.aria-expanded]="
                                                    datepicker.isOpen
                                                "
                                            >
                                                <i
                                                    class="glyphicon glyphicon-calendar"
                                                ></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                <div
                                    style="
                                        border-bottom: 1px solid grey;
                                        display: flex;
                                        flex-direction: column;
                                        width: 55vw;
                                        padding: 25px;
                                    "
                                    *ngIf="formItem.controls.helpText.value"
                                >
                                    <div
                                        *ngIf="
                                            formItem.controls.type.value ===
                                            'inline'
                                        "
                                    >
                                        <h5>Non-Compact</h5>
                                        <enhanced-help
                                            [screenName]="
                                                formItem.controls.screen.value
                                            "
                                            [componentName]="
                                                formItem.controls.component
                                                    .value
                                            "
                                            [helpObjectOverride]="
                                                formItem.value
                                            "
                                            [alwaysActive]="true"
                                        >
                                        </enhanced-help>
                                    </div>
                                    <div
                                        *ngIf="
                                            formItem.controls.type.value ===
                                            'inline'
                                        "
                                    >
                                        <h5 style="margin-bottom: 16px">
                                            Compact
                                        </h5>
                                        <enhanced-help
                                            *ngIf="
                                                formItem.controls.type.value ===
                                                'inline'
                                            "
                                            [screenName]="
                                                formItem.controls.screen.value
                                            "
                                            [componentName]="
                                                formItem.controls.component
                                                    .value
                                            "
                                            [helpObjectOverride]="
                                                formItem.value
                                            "
                                            [alwaysActive]="true"
                                            [compact]="true"
                                        >
                                        </enhanced-help>
                                    </div>

                                    <p
                                        class="label label-primary"
                                        [tooltip]="
                                            formItem.controls.helpText.value ||
                                            'Help Text is Empty'
                                        "
                                        placement="top"
                                        *ngIf="
                                            formItem.controls.type.value ===
                                            'tooltip'
                                        "
                                    >
                                        Hover on this button to test the Tooltip
                                    </p>

                                    <empty
                                        *ngIf="
                                            formItem.controls.type.value ===
                                            'empty'
                                        "
                                        [componentName]="
                                            formItem.controls.component.value
                                        "
                                        [screenName]="
                                            formItem.controls.screen.value
                                        "
                                        [helpObjectOverride]="formItem.value"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                        >
                                            Example Transcluded Action Button
                                        </button>
                                    </empty>
                                    <!-- TODO: implement some selector to toggle the various upsell component layouts -->
                                    <upsell
                                        *ngIf="
                                            formItem.controls.type.value ===
                                            'upsell'
                                        "
                                        [componentName]="
                                            formItem.controls.component.value
                                        "
                                        [screenName]="
                                            formItem.controls.screen.value
                                        "
                                        [overrideHelpObject]="formItem.value"
                                        layout="NORMAL"
                                    ></upsell>

                                    <button
                                        *ngIf="
                                            formItem.controls.type.value ===
                                            'text'
                                        "
                                        type="button"
                                        class="btn btn-default"
                                    >
                                        {{ formItem.controls.helpText.value }}
                                    </button>
                                </div>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-loading [show]="working"></ngx-loading>
