import { Component, Input } from '@angular/core';
import {
    DashboardService,
    NotificationDto,
    PersonsOrganisationDto,
    SetupGuideActivityDto
} from '@flyfreely-portal-ui/flyfreely';
import {
    collapseOnLeaveAnimation,
    expandOnEnterAnimation
} from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { NotificationFeedService } from 'libs/notifications/src/lib/notification-feed.service';
import { NotificationDialoguesService } from 'libs/notifications/src/lib/notifications-dialogues.service';
import {
    ActivityCardDto,
    checklistTypes,
    SetupGuideChecklistService
} from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

enum ActivityStreamTabs {
    CURRENT = 'CURRENT',
    HISTORY = 'HISTORY'
}

@Component({
    selector: 'activity-stream',
    templateUrl: './activity-stream.component.html',
    styleUrls: ['./styles.scss'],
    animations: [collapseOnLeaveAnimation(), expandOnEnterAnimation()]
})
export class ActivityStreamComponent {
    @Input() organisation: PersonsOrganisationDto;

    allActivities: ActivityCardDto[] = [];
    activities: ActivityCardDto[];
    completedActivities: ActivityCardDto[];
    allNotifications: NotificationDto[] = [];
    activeNotifications: NotificationDto[] = [];
    completedNotifications: NotificationDto[] = [];
    completingCards: ActivityCardDto[] = [];
    numberOfNew: number;
    notificationsLoading = true;

    autoCompleted: SetupGuideActivityDto.StepIdentifier[] = [];
    alertCardIndex: number = 0;

    tabs = ActivityStreamTabs;

    currentTypes: SetupGuideActivityDto.Type[] = [];
    checklists: {
        type: SetupGuideActivityDto.Type;
        label: string;
        archived: boolean;
        cards: ActivityCardDto[];
    }[] = [];
    collapsedChecklists: SetupGuideActivityDto.Type[];

    showCurrent = true;
    showHistory = false;
    allStartHereComplete = false;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        private setupGuideChecklistService: SetupGuideChecklistService,
        private notificationFeedService: NotificationFeedService,
        private notificationDialoguesService: NotificationDialoguesService,
        private dashboardService: DashboardService,
        private angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        this.setupGuideChecklistService.checklistType$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(types => {
                if (types != null) {
                    this.currentTypes = types;
                    this.refreshLists();
                }
            });

        this.setupGuideChecklistService.completeCard$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(completed => {
                this.autoCompleted = completed;
                this.refreshLists();
            });

        this.setupGuideChecklistService.collapsedChecklists$
            .pipe(take(2))
            .subscribe(collapsedChecklists => {
                if (collapsedChecklists != null) {
                    this.collapsedChecklists = collapsedChecklists;
                }
            });

        this.setupGuideChecklistService.setupGuideCards$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(cards => {
                this.allActivities = cards;
                this.refreshLists();
            });

        this.notificationFeedService.notificationsList$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(notifications => {
                if (notifications.type === 'LOADING') {
                    this.notificationsLoading = true;
                    this.allNotifications = [];
                    this.activeNotifications = [];
                    this.completedNotifications = [];
                } else if (notifications.type === 'LOADED') {
                    this.notificationsLoading = false;
                    this.allNotifications = notifications.notifications.sort(
                        (a, b) => moment(b.timestamp).diff(a.timestamp)
                    );
                    this.updateNotifications();
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    refreshLists() {
        if (this.allActivities == null || this.allActivities.length === 0) {
            this.activities = [];
            this.completedActivities = [];
            this.checklists = [];
            this.findNumberOfNew();
            return;
        }
        this.currentTypes.forEach(type => {
            const listIndex = this.checklists.findIndex(l => l.type === type);
            const activityList = this.allActivities.filter(
                a => a.type === type
            );
            const activityListArchived = activityList.reduce(
                (acc, a) => acc && a.completed,
                true
            );
            if (listIndex === -1) {
                this.checklists.push({
                    type: type,
                    label: checklistTypes.find(t => t.value === type).name,
                    archived: activityListArchived,
                    cards: activityList
                });
            } else {
                this.checklists[listIndex].archived = activityListArchived;
                this.checklists[listIndex].cards = activityList;
            }
        });
        // If preferences haven't loaded yet, collapse completed checklists until the preferences load
        if (this.collapsedChecklists == null) {
            this.collapsedChecklists = this.checklists
                .filter(c => c.archived === true)
                .map(c => c.type);
        }
        this.activities = this.allActivities.filter(
            a => a.completed == null || a.completed === false || a.new === true
        );
        let alertIndex = this.activities.length;
        this.activities.forEach((activity, i) => {
            if (
                this.autoCompleted.includes(activity.task) === false &&
                i < alertIndex
            ) {
                alertIndex = i;
            }
        });
        this.alertCardIndex =
            alertIndex === this.activities.length ? 0 : alertIndex;
        this.completedActivities = this.allActivities.filter(
            a => a.completed === true && a.new === false
        );
        this.findNumberOfNew();
    }

    updateNotifications() {
        this.activeNotifications = this.allNotifications.filter(
            n => n.read === false
        );
        this.completedNotifications = this.allNotifications.filter(
            n => n.read === true
        );
    }

    checklistComplete(cards: ActivityCardDto[]) {
        return cards.reduce(
            (acc, a) => acc && this.autoCompleted.includes(a.task),
            true
        );
    }

    findNumberOfNew() {
        this.numberOfNew =
            (this.allActivities != null
                ? this.allActivities.filter(a => a.new === true).length
                : 0) +
            (this.allNotifications != null
                ? this.allNotifications.filter(n => n.read === false).length
                : 0);
    }

    onNotificationRead(notification: NotificationDto) {
        this.notificationFeedService.archiveNotification(notification.id);
    }

    onVideoViewed(activity: ActivityCardDto) {
        this.setupGuideChecklistService.markActivityViewed(activity);
        this.numberOfNew = this.activities.filter(a => a.new === true).length;
    }

    completeCard(activity: ActivityCardDto) {
        if (activity.completed === true) {
            this.activities = this.activities.filter(
                a => a.task !== activity.task
            );
        }
    }

    toggleChecklist(type: SetupGuideActivityDto.Type) {
        if (this.collapsedChecklists.includes(type)) {
            this.collapsedChecklists = this.collapsedChecklists.filter(
                t => t !== type
            );
        } else {
            this.collapsedChecklists.push(type);
        }
        this.setupGuideChecklistService.updateCollapsedChecklists(
            this.collapsedChecklists
        );
    }

    archiveGroup(type: SetupGuideActivityDto.Type) {
        this.setupGuideChecklistService.archiveGroup(type);
        this.angulartics2.eventTrack.next({
            action: 'archive',
            properties: {
                category: 'setup-guide',
                label: type
            }
        });
    }

    unarchiveGroup(type: SetupGuideActivityDto.Type) {
        this.setupGuideChecklistService.unarchiveGroup(type);
        this.angulartics2.eventTrack.next({
            action: 'unarchive',
            properties: {
                category: 'setup-guide',
                label: type
            }
        });
    }

    changeTab(tab: ActivityStreamTabs) {
        this.showCurrent = tab === this.tabs.CURRENT;
        this.showHistory = tab === this.tabs.HISTORY;
        this.angulartics2.eventTrack.next({
            action: tab === this.tabs.CURRENT ? 'current' : 'history',
            properties: {
                category: 'activity-stream'
            }
        });
    }

    close() {
        this.dashboardService.toggleNotificationBar();
    }

    showAllWhatsNew() {
        this.notificationDialoguesService.showAllWhatsNew();
    }
}
