import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-image',
    template: `
    <div>
        <img [authenticatedSrc]="to.imageUrl" />
    </div>
    `
})
export class FormlyImage extends FieldType { }
