<enhanced-help
    componentName="organisation-subscriptions"
    [compact]="true"
>
</enhanced-help>
<ng-container [ngSwitch]="status">
    <div *ngSwitchCase="'LOADING'" style="position: relative; height: 300px">
        <ngx-loading [show]="true"></ngx-loading>
    </div>
    <ng-container *ngSwitchCase="'NO_SUBSCRIPTION'">
        <div class="alert alert-info">
            <span class="fal fa-info-circle right-buffer"></span> You are
            currently on a Free plan which has limited features. To upgrade to a
            paid plan select on one of the options below or contact sales
            directly for a customised subscription.
        </div>
        <purchase-subscription
            [organisation]="organisation"
        ></purchase-subscription>
    </ng-container>

    <ng-container *ngSwitchCase="'TRIALING'">
        <div class="alert alert-info">
            <span class="fal fa-info-circle right-buffer"></span>Your
            <strong>{{ subscriptionName }}</strong> Trial ends
            {{
                daysToExpiry != null
                    ? 'in ' +
                      daysToExpiry.toString() +
                      (daysToExpiry === 1 ? ' day' : ' days')
                    : 'soon'
            }}. Your account will revert to the
            <strong>{{
                freePlanName != null ? freePlanName : 'Free Plan'
            }}</strong>
            when the trial ends. Start a paid subscription today or contact
            sales to discuss extending your trial.
        </div>
        <purchase-subscription
            [organisation]="organisation"
            [subscriptionId]="subscriptionId"
        ></purchase-subscription>
    </ng-container>

    <current-subscription
        *ngSwitchCase="'LOADED'"
        [organisation]="organisation"
    ></current-subscription>
</ng-container>
