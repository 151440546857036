import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ToastrService } from 'ngx-toastr';
import '../../../../../setup-sentry';
import { SessionExpired, Unauthorised } from './errors';

const IGNORED_ERRORS = [SessionExpired, Unauthorised];

/**
 * A centralised service to handle errors and all toasts.
 * Ensures caught errors are sent to Sentry and displayed to the user as needed.
 */
@Injectable({
    providedIn: 'root'
})
export class FlyFreelyLoggingService {
    constructor(private toastr: ToastrService) {}

    success(message: string) {
        this.toastr.success(message);
    }

    warn(message: string) {
        this.toastr.warning(message);
    }

    info(message: string) {
        this.toastr.info(message);
    }

    /**
     * Logs an error to the console and Sentry if it is not an expected error.
     * Toasts any message passed in for the user.
     * @param error The entire error as thrown and caught. Leave null if only requiring a toast.
     * @param message The message to display to the user in a toast. Leave null if not requiring a toast. Will be displayed as-is with no further formatting.
     * @param extra Additional context to send to Sentry in the form of key-value pairs.
     */
    error(
        error: any,
        message: string = null,
        extra: Record<string, unknown> = null
    ) {
        if (message != null && message !== '') {
            this.toastr.error(message);
        }
        if (
            error != null &&
            (IGNORED_ERRORS.findIndex(e => error instanceof e) !== -1 ||
                IGNORED_ERRORS.findIndex(
                    e => error.originalError instanceof e
                ) !== -1)
        ) {
            // @ts-ignore - type sense not available
            if (window.__DEV__) {
                window.console.error(message, error);
            }
            Sentry.captureException(error.originalError || error, {
                extra: extra
            });
        }
    }
}
