import { Pipe, PipeTransform } from '@angular/core';
import { SuggestedMissionAssignmentDto } from '@flyfreely-portal-ui/flyfreely';
import { timeDistanceClass } from './time-distance-class.pipe';
import { locationDistanceClass } from './location-distance-class.pipe';

/**
 * The service only returns missions up to 1000m away.
 */
@Pipe({
    name: 'suggestedMissionMatchClass'
})
export class SuggestedMissionMatchClassPipe implements PipeTransform {
    transform(value: SuggestedMissionAssignmentDto) {
        const time = timeDistanceClass(value.timeDistance);
        const location = locationDistanceClass(value.locationDistance);

        if (time === 'status-icon-red' || location === 'status-icon-red') {
            return 'status-icon-red';
        } else if (
            time === 'status-icon-yellow' ||
            location === 'status-icon-yellow'
        ) {
            return 'status-icon-yellow';
        }
        return 'status-icon-green';
    }
}
