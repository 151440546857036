import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    Cancellable,
    ExclusiveControlService,
    FEATURE_CURRENCY,
    findOrganisation,
    hasAnyPermission,
    hasAnyRole,
    hasFeatureFlag,
    MissionRegisterStatusDetails,
    PersonRolesDto,
    PersonService,
    PersonsOrganisationDto,
    SubscriptionService,
    UserService
} from '@flyfreely-portal-ui/flyfreely';
import { getOrElse, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { OrganisationSubscriptionsState } from 'libs/subscriptions/src/lib/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { OrganisationSubscriptionsService } from '@flyfreely-portal-ui/workspace';

enum Editing {
    NONE = 'NONE',
    DETAILS = 'DETAILS',
    AUTHORITIES = 'AUTHORITIES',
    FLIGHT_HISTORY = 'FLIGHT_HISTORY'
}

@Component({
    selector: 'personnel-dialogue',
    templateUrl: './personnel-dialogue.component.html',
    providers: [ExclusiveControlService]
})
export class PersonnelDialogue implements OnInit {
    @Input() organisationId: number;
    @Input() person: PersonRolesDto;
    @Input() missionDetails: MissionRegisterStatusDetails;

    @ViewChild('personEdit', { static: false }) personEdit: Cancellable;

    /**
     * Can we edit personnel in this organisation.
     */
    canEdit: boolean;
    /**
     * Can this organisation manage this person.
     */
    canManage: boolean;
    /**
     * Is the current logged in user an organisation admin
     */
    canAdminOrganisation: boolean;
    hasCurrency: boolean;
    hasFlightHistory: boolean;
    isEditing: Editing = Editing.NONE;
    locked: boolean;

    licences: OrganisationSubscriptionsState;

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        public modal: BsModalRef<PersonnelDialogue>,
        modalOptions: ModalOptions,
        private exclusiveControlService: ExclusiveControlService,
        private userService: UserService,
        private personService: PersonService,
        private organisationSubscriptionsService: OrganisationSubscriptionsService,
        private subscriptionService: SubscriptionService
    ) {
        // FIXME not sure this is correct
        modalOptions.closeInterceptor = () => {
            if (this.locked) {
                this.exclusiveControlService.requestUnlock();
                return Promise.reject();
            }
            if (!this.locked && this.isEditing === Editing.NONE) {
                return Promise.resolve();
            }
        };

        this.exclusiveControlService.lock$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(locked => {
                this.locked = locked;
                if (!locked) {
                    if (this.isEditing === Editing.DETAILS) {
                        this.personEdit.cancel();
                    }
                    this.isEditing = Editing.NONE;
                }
            });
    }

    ngOnInit() {
        this.personService.values$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(values => {
                const person = values.personnel.find(
                    p => p.id === this.person.id
                );

                if (
                    person == null ||
                    person.roles == null ||
                    person.roles.length === 0
                ) {
                    return this.modal.hide();
                }
                this.person = person;
            });

        this.organisationSubscriptionsService.currentSubscriptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(licences => (this.licences = licences));

        this.setupTabs();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private setupTabs() {
        this.hasFlightHistory = hasAnyRole(this.person.roles, [
            PersonRolesDto.Roles.PILOT,
            PersonRolesDto.Roles.STUDENT
        ]);

        this.canEdit = pipe(
            findOrganisation(
                this.userService.findUsersOrganisations(),
                this.organisationId
            ),
            map(o =>
                hasAnyPermission(
                    o,
                    PersonsOrganisationDto.Permissions.PERSON_EDIT
                )
            ),
            getOrElse(() => false)
        );

        this.hasCurrency = pipe(
            findOrganisation(
                this.userService.findUsersOrganisations(),
                this.organisationId
            ),
            map(o => hasFeatureFlag(o, FEATURE_CURRENCY)),
            getOrElse(() => false)
        );

        this.canManage = this.person.availableActions?.canEdit ?? false;

        this.canAdminOrganisation = pipe(
            findOrganisation(
                this.userService.findUsersOrganisations(),
                this.organisationId
            ),
            map(
                organisation =>
                    this.organisationId &&
                    !organisation.personalOrganisation &&
                    (organisation.permissions.indexOf(
                        PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
                    ) !== -1 ||
                        organisation.ownerId ===
                            this.userService.getCurrentUser().id)
            ),
            getOrElse(() => false)
        );
    }

    editDetails() {
        this.isEditing = Editing.DETAILS;
        this.exclusiveControlService.lock(() => true);
    }

    stopEditing() {
        if (this.isEditing === Editing.AUTHORITIES) {
            // update the personnel list in the widget to reflect any changes, such as any new ARN changes.
            this.personService.markAsChanged();
        }
        this.exclusiveControlService.requestUnlock();
    }

    editAuthority() {
        this.isEditing = Editing.AUTHORITIES;
        this.exclusiveControlService.lock(() => true);
    }

    editFlightHistory() {
        this.isEditing = Editing.FLIGHT_HISTORY;
        this.exclusiveControlService.lock(() => true);
    }

    onPersonUpdated(person: PersonRolesDto) {
        if (person.roles.length === 0) {
            return;
        }
        this.subscriptionService.refreshLicences();
        this.person = person;
        this.setupTabs();
    }
}
