import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EnhancedHelpDto,
    FlyFreelyConstants,
    ScreenHelpDto,
    UpdateEnhancedHelpCommand,
    UpdateScreenHelpCommand
} from '@flyfreely-portal-ui/flyfreely';

@Injectable({
    providedIn: 'root'
})
export class EnhancedHelpAdminService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    findAll() {
        return this.http.get<EnhancedHelpDto[]>(
            `${this.baseUrl}/adminapi/enhancedHelp`
        );
    }

    update(command: UpdateEnhancedHelpCommand) {
        return this.http.post<EnhancedHelpDto>(
            `${this.baseUrl}/adminapi/enhancedHelp`,
            command
        );
    }

    updateScreenHelp(command: UpdateScreenHelpCommand, screen: string) {
        return this.http.post<ScreenHelpDto>(
            `${this.baseUrl}/adminapi/screenHelp/${screen}`,
            command
        );
    }
}
