import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CraftDto } from '@flyfreely-portal-ui/flyfreely';
import { FlyFreelyConstants } from '@flyfreely-portal-ui/flyfreely';

@Pipe({ name: 'formatResourceStatusIcon' })
export class FormatResourceStatusIconPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
        private constants: FlyFreelyConstants
    ) {}

    transform(craft: CraftDto, returnSafeHtml = true) {
        if (craft === null) {
            return null;
        }

        const statusHtml = this.createHtml(craft);

        // if (returnSafeHtml) {
        //     return this.sanitizer.bypassSecurityTrustHtml(statusHtml);
        // } else {
        return statusHtml;
        // }
    }

    createHtml(craft: CraftDto) {
        const statusColour = this.findStatusColour(craft.status);
        if (craft.isDummy) {
            return `
            <div class="status-icon-block">
                <div class="status-icon">
                    <div>
                        <img src="${this.constants.IMG_URL}/icons/dummy-icon.png" />
                    </div>
                </div>
                <div class="name-with-icon">
                ${craft.nickname}
                </div>
            </div>`;
        } else {
            return `
            <div class="status-icon-block">
                <div class="status-icon">
                    <div class="status-icon-${statusColour}"></div>
                </div>
                <div class="name-with-icon">
                ${craft.nickname}
                </div>
            </div>`;
        }
    }

    findStatusColour(status: CraftDto.Status) {
        switch (status) {
            case CraftDto.Status.SERVICEABLE:
                return 'green';

            case CraftDto.Status.UNSERVICEABLE:
            // fallthrough
            case CraftDto.Status.RETIRED:
            // fallthrough
            case CraftDto.Status.UNDER_MAINTENANCE:
                return 'red';
        }
    }
}
