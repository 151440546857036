import { Component, Input } from '@angular/core';
import {
    AnalysedFlightLogDto,
    BatteryDto,
    FlightLogsService,
    FlightPoint,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import { BatteryHealthDataService } from './battery-health-data.service';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import moment from 'moment';
import {
    NgSelectPaginationValues,
    NgSelectSearch,
    ngSelectPreSearchConfigure
} from 'libs/ng-select/src/lib/helpers';

@Component({
    selector: 'battery-health',
    templateUrl: './battery-health.component.html',
    providers: [BatteryHealthDataService]
})
export class BatteryHealthComponent {
    @Input() battery: BatteryDto;
    @Input() organisationId: number;

    flightLogs$ = new BehaviorSubject<any[]>([]);
    selectedFlightLogId: number;
    analysedFlightLog: AnalysedFlightLogDto;
    flightPoints: FlightPoint[];

    batteryCharge: any[];
    batteryTemperature: any[];
    batteryVoltages: any[];
    selectedDisplayData: 'charge' | 'temperature' | 'voltage' = 'charge';

    currentDisplayData: any[];

    voltageYLimits: number[];
    temperatureYLimits: number[];

    // options
    legend = true;
    showLabels = true;
    animations = true;
    xAxis = true;
    yAxis = true;
    showYAxisLabel = true;
    showXAxisLabel = true;
    xAxisLabel = 'Flight Time';
    yAxisLabel = 'Percentage %';
    timeline = true;

    // TODO: fix up colour scheme to be more of a gradient instead of random blues
    colorScheme = {
        domain: [
            '#007DD5',
            '#31708f',
            '#bce8f1',
            '#7aa3e5',
            '#384ba8',
            '#aae3f5'
        ]
    };

    // ng-select GQL variables
    loading = false;
    flightLogSelectConfig: NgSelectPaginationValues = {
        currentPage: 0,
        preSearchPage: null,
        searchValue: null,
        totalIdems: null
    };
    private flightLogSearchSource = new Subject<NgSelectSearch>();

    working = false;
    private workTracker = new WorkTracker();
    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private flightLogsService: FlightLogsService,
        private batteryHealthDataService: BatteryHealthDataService
    ) {
        this.workTracker.observable
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));
    }

    ngOnInit() {
        this.setupDataSubscriptions();
        this.refreshFlightLogs();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.flightLogSearchSource.complete();
    }

    setupDataSubscriptions() {
        this.batteryHealthDataService.flightLogs$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(logs =>
                this.flightLogs$.next(this.flightLogs$.getValue().concat(logs))
            );
        this.batteryHealthDataService.working$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.loading = working));

        this.flightLogSearchSource
            .pipe(
                filter(s => s != null),
                debounceTime(500),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(search => {
                this.flightLogSelectConfig = ngSelectPreSearchConfigure(
                    search,
                    this.flightLogSelectConfig
                );
                this.refreshFlightLogs();
            });
    }

    refreshFlightLogs() {
        this.batteryHealthDataService.findFlightLogs(
            this.battery.id,
            this.battery.manufacturerSerialNumber,
            this.organisationId,
            this.flightLogSelectConfig.currentPage,
            25,
            null
            // TODO: add search criteria to fetch
        );
    }

    analyseFlightLog() {
        this.flightLogsService
            .analyseFlightLog(this.selectedFlightLogId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(log => {
                this.analysedFlightLog = log;
                this.buildFlightData();
            });
    }

    buildFlightData() {
        const flights = this.analysedFlightLog.flight;
        if (flights.length === 0) {
            return;
        }
        // TODO: figure out the best interval, or a way to set interval from the UI
        const totalLength = flights.length;
        const interval = Math.floor(totalLength / 30);
        const filtered = [];
        for (let i = 0; i < flights.length; i = i + interval) {
            filtered.push(flights[i]);
            if (i + interval >= flights.length && i !== flights.length - 1) {
                // the next loop won't run and this isn't the last flight point, so insert the last point too
                filtered.push(flights[flights.length - 1]);
            }
        }
        this.flightPoints = filtered;

        // Build the display data for the various graphs/graph layers
        this.batteryCharge = [
            {
                name: 'Charge level',
                series: this.flightPoints.map((p, i) => ({
                    name: moment()
                        .set('hours', 0)
                        .set('minutes', 0)
                        .set('seconds', 0)
                        .milliseconds(p.motorTime)
                        .format('HH:mm:ss'),
                    value:
                        p.batteries[0]?.batteryLevel ??
                        this.analysedFlightLog.batteries[0]
                            ?.startBatteryLevel ??
                        this.flightPoints[i + 1].batteries[0]?.batteryLevel ??
                        0
                }))
            }
        ];
        this.batteryTemperature = [
            {
                name: 'Temperature',
                series: this.flightPoints.map((p, i) => ({
                    name: moment()
                        .set('hours', 0)
                        .set('minutes', 0)
                        .set('seconds', 0)
                        .milliseconds(p.motorTime)
                        .format('HH:mm:ss'),
                    value:
                        p.batteries[0]?.batteryTemperature ??
                        this.analysedFlightLog.batteries[0]
                            ?.startBatteryTemperature ??
                        this.flightPoints[i + 1].batteries[0]
                            ?.batteryTemperature ??
                        0
                }))
            }
        ];

        // Filter out any unused cell voltage fields
        const voltages = this.flightPoints.reduce(
            (acc, p) =>
                p.batteries[0]
                    ? acc.concat(
                          Object.keys(p.batteries[0])
                              .filter(k => k.includes('cellVoltage'))
                              .reduce(
                                  (acc2, b) =>
                                      !acc2.includes(b) &&
                                      !acc.includes(b) &&
                                      p.batteries[0][b] != null &&
                                      p.batteries[0][b] !== 0
                                          ? acc2.concat(b)
                                          : acc2,
                                  []
                              )
                      )
                    : acc,
            []
        );
        this.batteryVoltages = voltages.map(v => ({
            name: v,
            series: this.flightPoints.map((p, i) => ({
                name: moment()
                    .set('hours', 0)
                    .set('minutes', 0)
                    .set('seconds', 0)
                    .milliseconds(p.motorTime)
                    .format('HH:mm:ss'),
                value: p.batteries[0]
                    ? p.batteries[0][v] ??
                      this.flightPoints[i + 1].batteries[0][v]
                    : 0
            }))
        }));
        this.setupYLimits();
    }

    /**
     * A function to determine what the maximum and minimum values for the y-axes of the various graphs are
     * and whether to add buffers to the y-axis max and min values passed to the graph for display.
     * If the graph will come too close to the top or bottom of the graph,
     * then a buffer is applied to the y-axis max and min values to add a margin.
     */
    setupYLimits() {
        const createBuffer = (
            number: number,
            rounded: number,
            min: boolean
        ) => {
            if (number >= rounded && number - rounded <= 2) {
                return min ? rounded - 5 : rounded + 5;
            }
            if (number < rounded && rounded - number <= 2) {
                return rounded + 5;
            }
            return rounded;
        };
        const minVoltage = Math.min(
            ...this.batteryVoltages.map(v =>
                Math.min(...v.series.map(i => i.value))
            )
        );
        const maxVoltage = Math.max(
            ...this.batteryVoltages.map(v =>
                Math.max(...v.series.map(i => i.value))
            )
        );
        this.voltageYLimits = [
            createBuffer(minVoltage, Math.floor(minVoltage / 10) * 10, true),
            createBuffer(maxVoltage, Math.ceil(maxVoltage / 10) * 10, false)
        ];

        const minTemperature = Math.min(
            ...this.batteryTemperature.map(v =>
                Math.min(...v.series.map(i => i.value))
            )
        );
        const maxTemperature = Math.max(
            ...this.batteryTemperature.map(v =>
                Math.max(...v.series.map(i => i.value))
            )
        );
        this.temperatureYLimits = [
            createBuffer(
                minTemperature,
                Math.floor(minTemperature / 10) * 10,
                true
            ),
            createBuffer(
                maxTemperature,
                Math.ceil(maxTemperature / 10) * 10,
                false
            )
        ];
    }

    onFlightLogSelectScroll() {
        this.flightLogSelectConfig.currentPage++;
        this.refreshFlightLogs();
    }

    onFlightLogSelectSearch(search: NgSelectSearch) {
        this.flightLogSearchSource.next(search);
    }

    onFlightLogSelectClose() {
        this.flightLogSelectConfig.searchValue = null;
        this.flightLogSearchSource.next(null);
    }
}
