import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import {
    CurrentPersonDto,
    DashboardService,
    FEATURE_SETUP_GUIDE,
    FEATURE_UPSELL_PLANS_OVERVIEW,
    FlyFreelyConstants,
    LoggedInUser,
    LoginManager,
    PersonsOrganisationDto,
    PreferencesService,
    SetupGuideActivityDto,
    UserService,
    UserStatus,
    hasFeatureFlag
} from '@flyfreely-portal-ui/flyfreely';
import {
    CurrentOrganisation,
    OrganisationSubscriptionsService,
    WorkspaceStateService
} from '@flyfreely-portal-ui/workspace';
import {
    fadeInAnimation,
    fadeOutAnimation,
    slideInLeftOnEnterAnimation,
    slideInRightOnEnterAnimation,
    tadaAnimation,
    tadaOnEnterAnimation,
    zoomOutLeftOnLeaveAnimation,
    zoomOutRightOnLeaveAnimation
} from 'angular-animations';
import { Angulartics2 } from 'angulartics2';
import { DynamicHelpEditService } from 'libs/enhanced-help/src/lib/dynamic-help-editor/dynamic-help-edit.service';
import { FeaturesDialoguesService } from 'libs/features/src/lib/features-dialogues.service';
import { NotificationFeedService } from 'libs/notifications/src/lib/notification-feed.service';
import { SetupGuideChecklistService } from 'libs/onboarding/src/lib/setup-guide/setup-guide-checklist.service';
import { hasExistingCommercialOrganisations } from 'libs/subscriptions/src/lib/helpers';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'flyfreely-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./styles.scss'],
    animations: [
        fadeInAnimation(),
        fadeOutAnimation(),
        tadaAnimation(),
        slideInRightOnEnterAnimation(),
        zoomOutRightOnLeaveAnimation(),
        slideInLeftOnEnterAnimation(),
        zoomOutLeftOnLeaveAnimation(),
        tadaOnEnterAnimation()
    ]
})
export class SidebarComponent implements OnInit, OnDestroy {
    private ngUnsubscribe$ = new Subject<void>();

    currentOrganisation: CurrentOrganisation = {
        organisation: null,
        type: null
    };
    get showWidgetSelection() {
        return this.router.url.startsWith('/dashboard');
    }

    organisations: PersonsOrganisationDto[];
    personalOrganisationId: number;

    currentUser?: CurrentPersonDto;

    isImpersonating = true;
    isSystemAdmin = true;

    showDynamicHelpEdit = false;

    hovering = false;
    showButton = false;
    mouseOver = false;

    canUseSetupGuideSource = new ReplaySubject<boolean>(1);
    canUseSetupGuide$ = this.canUseSetupGuideSource.pipe(
        takeUntil(this.ngUnsubscribe$)
    );

    newNotification = false;
    numberOfNew = 0;
    showNotificationBanner = false;
    showAlertBellTooltip = true;
    freshRefresh = true;
    hasShowMoreFeatures = false;
    showMoreFeatures = false;

    @HostBinding('class.collapsed')
    public isSideBarCollapsed: boolean;
    public IMG_URL: string;
    public SITE_DESIGNATION: string;
    @HostBinding('class.activity-stream-open')
    public showActivityStream = false;

    isCurrentUserVisible: boolean;
    isEquipmentMenuCollapsed: boolean;
    isCraftMenuCollapsed: boolean;
    isBatterySetsMenuCollapsed: boolean;
    oneAtATime: boolean;
    status: {
        isFirstOpen: boolean;
        isFirstDisabled: boolean;
    };

    canCreateOrganisation: boolean;
    createOrganisationTooltip: string;

    private refreshFeatureFlags = new Subject<void>();

    constructor(
        private userService: UserService,
        constants: FlyFreelyConstants,
        private sharedService: WorkspaceStateService,
        private featuresDialoguesService: FeaturesDialoguesService,
        private organisationSubscriptionService: OrganisationSubscriptionsService,
        private router: Router,
        private angulartics2: Angulartics2,
        private setupGuideChecklistService: SetupGuideChecklistService,
        private notificationFeedService: NotificationFeedService,
        private dashboardService: DashboardService,
        public preferencesService: PreferencesService,
        private loginManager: LoginManager,
        private dynamicHelpEditService: DynamicHelpEditService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.IMG_URL = constants.IMG_URL;
        this.SITE_DESIGNATION = constants.SITE_DESIGNATION;
        // this.$element = $element;

        this.isSideBarCollapsed = false;
        this.isCurrentUserVisible = true;
        this.isEquipmentMenuCollapsed = true;
        this.isCraftMenuCollapsed = true;
        this.isBatterySetsMenuCollapsed = true;
        this.oneAtATime = true;

        this.status = {
            isFirstOpen: true,
            isFirstDisabled: false
        };
    }

    ngOnInit(): void {
        this.userService.userChange$
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                filter(c => c.type === UserStatus.LOGGED_IN)
            )
            .subscribe((changes: LoggedInUser) => {
                this.updateUser(
                    changes.currentUser,
                    changes.currentUsersOrganisations
                );

                const canUseSetupGuide = hasFeatureFlag(
                    this.userService.getPersonalOrganisation(),
                    FEATURE_SETUP_GUIDE
                );
                this.canUseSetupGuideSource.next(canUseSetupGuide);
                this.refreshSetupGuide(canUseSetupGuide);

                this.canCreateOrganisation =
                    !hasExistingCommercialOrganisations(
                        this.userService.findUsersOrganisations(),
                        this.currentUser.id
                    );

                this.createOrganisationTooltip = this.canCreateOrganisation
                    ? 'Create a free commercial organisation'
                    : 'You have already created an organisation';
            });

        this.organisationSubscriptionService.currentSubscriptions$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(subscription => {
                this.showMoreFeatures =
                    subscription?.type === 'no_subscriptions' ||
                    subscription?.type === 'personal_organisation';
            });

        this.updateUser(
            this.userService.getCurrentUser(),
            this.userService.findUsersOrganisations()
        );

        this.sharedService.currentOrganisation$
            .pipe(
                filter<CurrentOrganisation>(
                    o => o.type === 'organisation_loaded'
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(organisation => {
                this.currentOrganisation = organisation;
                this.setupGuideChecklistService.setOrganisation(
                    organisation.organisation
                );
                this.notificationFeedService.setCurrentOrganisation(
                    organisation.organisation
                );
                this.hasShowMoreFeatures = hasFeatureFlag(
                    organisation.organisation,
                    FEATURE_UPSELL_PLANS_OVERVIEW
                );
                this.notificationFeedService.refreshNotifications(true);
            });

        combineLatest([
            this.notificationFeedService.numberOfNew$,
            this.setupGuideChecklistService.numberOfNew$
        ])
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(([newNotifications, newActivities]) => {
                const numberOfNew = newNotifications + newActivities;
                this.showNewNotifications(numberOfNew);
            });

        this.dashboardService.sideBarState$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(state => {
                this.isSideBarCollapsed = state.isSideBarCollapsed;
                this.showActivityStream = state.showNotificationBar;
            });

        this.isImpersonating = this.loginManager.isImpersonating();
        this.isSystemAdmin =
            this.userService.getCurrentUser()?.type ===
            CurrentPersonDto.Type.SYSTEM_ADMIN;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.refreshFeatureFlags.complete();
    }

    refreshSetupGuide(canUseSetupGuide: boolean) {
        if (canUseSetupGuide && this.freshRefresh) {
            // This gets the notification system going.
            this.setupGuideChecklistService.refreshCurrentList();
            this.setupGuideChecklistService.scrollTo$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(destination => this.scrollTo(destination));
            this.setupGuideChecklistService.changeOrganisationId$
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(id => {
                    const org = this.organisations.find(o => o.id === id);
                    if (org != null) {
                        this.onOrganisationChanged(org);
                    }
                });

            new Promise(resolve => setTimeout(resolve, 2000)).then(
                () => (this.freshRefresh = false)
            );
        }
    }

    showToggleButton() {
        this.mouseOver = true;
        if (this.hovering !== true) {
            this.hovering = true;
        }
    }

    hideToggleButton() {
        this.mouseOver = false;
        return new Promise(resolve => setTimeout(resolve, 100)).then(() => {
            if (this.hovering !== false && this.mouseOver === false) {
                this.hovering = false;
            }
        });
    }

    toggleDummyData() {
        const result = this.preferencesService.toggleDummyDataPreference();
        this.setupGuideChecklistService.completeActivityTask(
            SetupGuideActivityDto.StepIdentifier.CHECK_DUMMY
        );
    }

    private updateUser(
        currentUser: CurrentPersonDto,
        currentUsersOrganisations: PersonsOrganisationDto[]
    ) {
        this.currentUser = currentUser;
        if (currentUsersOrganisations == null) {
            this.organisations = [];
            return;
        }
        if (currentUser == null) {
            return;
        }
        this.personalOrganisationId = currentUser.personalOrganisationId;
        const organisations = [...currentUsersOrganisations];
        organisations.sort((a, b) => {
            if (a.personalOrganisation) {
                return -1;
            } else if (b.personalOrganisation) {
                return 1;
            }
            return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
        });
        this.organisations = organisations;
    }

    onOrganisationChanged(selectedOrgranisation: PersonsOrganisationDto) {
        this.notificationFeedService.onSwitchOrganisation();
        this.router.navigate([], {
            queryParams: {
                organisationId: selectedOrgranisation.id
            },
            queryParamsHandling: 'merge'
        });
    }

    onOrganisationDropdownOpened() {
        if (this.currentOrganisation.organisation == null) {
            return;
        }
        this.setupGuideChecklistService.completeActivityTask(
            SetupGuideActivityDto.StepIdentifier.CHECK_ORG,
            this.currentOrganisation.organisation.id
        );
    }

    setShowButton(showButton: boolean) {
        this.showButton = showButton;
        this.changeDetector.detectChanges();
    }

    public toggleSidebar() {
        this.dashboardService.toggleSidebar();
        this.hideToggleButton();
    }

    showMoreFeaturesDialogue() {
        this.featuresDialoguesService.showMoreFeatures(
            this.currentOrganisation.organisation?.id,
            this.currentOrganisation.organisation?.personalOrganisation
        );
    }

    showCreateCommercialOrganisation() {
        this.dashboardService.shouldReturnToDashboard(
            true,
            this.currentOrganisation.organisation.id
        );
        this.router.navigate(['onboarding']);
    }

    toggleActivityStream() {
        this.dashboardService.toggleNotificationBar();
    }

    // Leaving this scrollTo here for the first mission checklist functionality since the rest of it is in here too
    // This function is duplicated in the widget-selection component, including the analytics
    public scrollTo(widgetId: string) {
        this.sharedService.scrollTo(widgetId);

        this.angulartics2.eventTrack.next({
            action: 'scrollTo',
            properties: {
                category: 'sidebar',
                label: widgetId
            }
        });
    }

    showNewNotifications(numberOfNew: number) {
        const alertTimer = this.freshRefresh === true ? 2000 : 0;
        const showBanner = numberOfNew > this.numberOfNew;
        if (numberOfNew > 0) {
            new Promise(resolve => setTimeout(resolve, alertTimer))
                .then(() => {
                    this.newNotification = true;
                    this.showNotificationBanner = showBanner;
                    this.showAlertBellTooltip = !showBanner;
                })
                .then(() =>
                    new Promise(resolve => setTimeout(resolve, 5000)).then(
                        () => {
                            if (this.showNotificationBanner !== false) {
                                this.showNotificationBanner = false;
                                // This is to prevent the tooltip blocking the banner's exit animation
                                new Promise(resolve =>
                                    setTimeout(resolve, 1000)
                                ).then(
                                    () => (this.showAlertBellTooltip = true)
                                );
                            }
                        }
                    )
                );
            this.numberOfNew = numberOfNew;
        }
        if (numberOfNew === 0) {
            this.numberOfNew = numberOfNew;
            this.newNotification = false;
            new Promise(resolve => setTimeout(resolve, 5000)).then(() => {
                if (this.showNotificationBanner !== false) {
                    this.showNotificationBanner = false;
                    // This is to prevent the tooltip blocking the banner's exit animation
                    new Promise(resolve => setTimeout(resolve, 1000)).then(
                        () => (this.showAlertBellTooltip = true)
                    );
                }
            });
        }
    }

    stopImpersonating() {
        localStorage.removeItem('kcImpersonationToken');
        localStorage.removeItem('kcImpersonationIdToken');
        // refreshing the tab will dump the impersonated login session and revert to the logged in admin
        window.location.reload();
    }

    toggleDynamicHelpEdit() {
        this.showDynamicHelpEdit = !this.showDynamicHelpEdit;
        this.dynamicHelpEditService.setEditMode(this.showDynamicHelpEdit);
    }
}
