import { Component, Input } from '@angular/core';
import {
    FlyFreelyError,
    FlyFreelyLoggingService,
    PersonsOrganisationDto,
    RpaTypeDto,
    RpaTypesService,
    WorkTracker
} from '@flyfreely-portal-ui/flyfreely';
import {
    ColumnSortPreferences,
    TableColumn,
    TableConfig
} from '@flyfreely-portal-ui/flyfreely-table';
import { FormatRpaCategoryPipe } from '@flyfreely-portal-ui/resource-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RpaDialoguesService } from '../../rpa-dialogues.service';

interface DisplayableRpaType extends RpaTypeDto {
    isGlobal: boolean;
}

@Component({
    selector: 'rpa-type-list',
    templateUrl: './rpa-type-list.component.html'
})
export class RpaTypeList {
    @Input() organisation: PersonsOrganisationDto;
    @Input() canEdit: boolean;

    private workTracker = new WorkTracker();
    working: boolean = false;

    private ngUnsubscribe$ = new Subject<void>();

    rpaTypes: DisplayableRpaType[];
    availableColumns: TableColumn[];
    selectedColumns: string[];
    tableConfig: TableConfig;
    columnSorting: ColumnSortPreferences;

    constructor(
        private rpaTypeService: RpaTypesService,
        private rpaDialoguesService: RpaDialoguesService,
        private logging: FlyFreelyLoggingService,
        private formatRpaCategoryPipe: FormatRpaCategoryPipe
    ) {
        this.selectedColumns = null;
        this.columnSorting = {
            column: 'make',
            ascending: true
        };
        this.tableConfig = {
            limit: 25,
            actions: []
        };
    }

    ngOnInit() {
        this.workTracker
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(working => (this.working = working));

        // Disabling linkfunctions this way prevents the text still looking like a link but doing nothing
        this.availableColumns = this.canEdit
            ? [
                  {
                      value: 'make',
                      name: 'Make',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true,
                      linkFunction: row => this.showRpaTypeDetails(row)
                  },
                  {
                      value: 'model',
                      name: 'Model',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true,
                      linkFunction: row => this.showRpaTypeDetails(row)
                  },
                  {
                      value: 'craftType',
                      name: 'Type',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true,
                      linkFunction: row => this.showRpaTypeDetails(row),
                      formatterFunction: item =>
                          this.formatRpaCategoryPipe.transform(item)
                  },
                  {
                      value: 'isGlobal',
                      name: 'Custom',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true,
                      linkFunction: row => this.showRpaTypeDetails(row),
                      formatterFunction: item =>
                          item === true ? 'Global' : 'Custom'
                  }
              ]
            : [
                  {
                      value: 'make',
                      name: 'Make',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true
                  },
                  {
                      value: 'model',
                      name: 'Model',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true
                  },
                  {
                      value: 'craftType',
                      name: 'Type',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true,
                      formatterFunction: item =>
                          this.formatRpaCategoryPipe.transform(item)
                  },
                  {
                      value: 'isGlobal',
                      name: 'Custom',
                      searchable: false,
                      selectable: false,
                      defaultSelection: true,
                      formatterFunction: item =>
                          item === true ? 'Global' : 'Custom'
                  }
              ];

        this.refreshRpaTypes();

        this.rpaTypeService.change$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.refreshRpaTypes());
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshRpaTypes() {
        this.rpaTypeService
            .findRpaTypes(this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                rpaTypes => {
                    this.rpaTypes = rpaTypes.map(t => ({
                        ...t,
                        isGlobal: t.organisationId == null
                    }));
                },
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while loading RPA types: ${error.message}`
                    )
            )
            .add(this.workTracker.createTracker());
    }

    showRpaTypeDetails(rpaType: RpaTypeDto) {
        this.rpaDialoguesService.showRpaTypeEditDialogue(
            rpaType.id,
            this.organisation.id
        );
    }

    createRpaType() {
        this.rpaDialoguesService.showRpaTypeEditDialogue(
            null,
            this.organisation.id
        );
    }

    updateSelectedColumns(selectedColumns: any) {
        this.selectedColumns = selectedColumns;
    }

    updateColumnSorting(sorting: ColumnSortPreferences) {
        this.columnSorting = sorting;
    }
}
