import { Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
    FlyFreelyConstants,
    FlyFreelyError,
    FlyFreelyLoggingService,
    OrganisationService,
    PersonsOrganisationDto
} from '@flyfreely-portal-ui/flyfreely';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'organisation-logo',
    templateUrl: './organisation-logo.component.html',
    styleUrls: [
        './organisation-logo.scss',
        '../profile-picture/profile-picture.component.scss'
    ]
})
export class OrganisationLogo {
    @Input() organisation: PersonsOrganisationDto;
    @Input() managingOrganisationId: number;
    @Input() showEdit: boolean;

    canUpdateLogo: boolean;
    baseDropValid: boolean;

    sanitizer: DomSanitizer;

    isProfilePicture: boolean;
    baseUrl: string;
    logoUrl: string | SafeUrl;

    private ngUnsubscribe$ = new Subject<void>();
    constructor(
        private organisationService: OrganisationService,
        private constants: FlyFreelyConstants,
        private domSanitizer: DomSanitizer,
        private logging: FlyFreelyLoggingService
    ) {
        this.sanitizer = this.domSanitizer;
        this.baseUrl = this.constants.IMG_URL;
    }

    ngOnChanges(changes: SimpleChanges) {
        // check for changes to the bindings
        if ('organisation' in changes) {
            this.refreshLogo();
            this.refreshPermissions();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private refreshLogo() {
        if (!this.organisation) {
            return;
        }

        this.isProfilePicture = this.organisation.personalOrganisation;
        this.logoUrl = null;

        this.organisationService
            .getLogo(this.organisation.id, this.managingOrganisationId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                (logo: Blob) => {
                    if (logo != null) {
                        const reader = new FileReader();
                        reader.onload = () => {
                            this.logoUrl = reader.result;
                        };
                        reader.readAsDataURL(logo);
                    } else {
                        this.logoUrl = `${this.baseUrl}/default-profile.png`;
                    }
                },
                (error: FlyFreelyError) => {
                    this.logging.error(
                        error,
                        `Error fetching organisation logo: ${error.message}`
                    );
                    this.logoUrl = `${this.baseUrl}/default-profile.png`;
                }
            );
    }

    private refreshPermissions() {
        this.canUpdateLogo =
            this.organisation != null &&
            this.organisation.permissions != null &&
            this.organisation.permissions.indexOf(
                PersonsOrganisationDto.Permissions.ORGANISATION_EDIT
            ) !== -1;
    }

    saveLogo(file: File) {
        this.organisationService
            .uploadLogo(file, this.organisation.id)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                () => this.refreshLogo(),
                (error: FlyFreelyError) =>
                    this.logging.error(
                        error,
                        `Error while saving logo: ${error}`
                    )
            );
    }
}
