import { Injectable } from '@angular/core';
import { IncidentReportDto } from '@flyfreely-portal-ui/flyfreely';
import { MODAL_OPTIONS } from 'libs/ngx-bootstrap-customisation/src/lib/ngx-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IncidentReportEdit } from './incident-report-edit/incident-report-edit.component';

@Injectable()
export class IncidentReportDialoguesService {
    constructor(private modalService: BsModalService) {}

    showCreateIncidentReport(missionId: number) {
        return this.modalService.show(IncidentReportEdit, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: { missionId: missionId }
        });
    }

    showEditIncidentReport(
        missionId: number,
        incidentId: number,
        incidentReport: IncidentReportDto | null
    ) {
        return this.modalService.show(IncidentReportEdit, {
            ...MODAL_OPTIONS,
            class: 'modal-md',
            initialState: {
                missionId: missionId,
                incidentReport: incidentReport,
                id: incidentId
            }
        });
    }
}
