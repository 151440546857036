import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlyFreelyConstants } from '../constants';
import { StatusResponse } from '../model/api';


@Injectable({
    providedIn: 'root'
})
export class StatusService {
    private baseUrl: string;

    constructor(constants: FlyFreelyConstants, private http: HttpClient) {
        this.baseUrl = constants.SITE_URL;
    }

    getStatus() {
        return this.http
           .get<StatusResponse>(`${this.baseUrl}/publicapi/status`);
    }
}
